module.exports={
    ForgotPasswordHint:"If you don't remember the username or password kindly contact us on 9843684235, (+91)422 4953110 or mail us at customersupport@svastanibhanda.com",
    Forgot2PasswordHint:"If you don't remember the username kindly contact us on 9843684235, (+91)422 4953110 or mail us at customersupport@svastanibhanda.com",

    ChangePasswordHint:"Password must be between 6 - 12 characters long contaning alpha numeric with at least one Upper case, Lower case, Special Characters (!,@,#,$,%,^,&,*,_,-,+,=)",
    NoBills:"Please Enter The Bills.. ",    // Billing
    NoServiceType:"Enter the details to proceed ", // Billing
    SonographyReport: "Enter all fields to proceed", // Report
    UltraSoundEmptyField:"Enter all fields to proceed", // Report
    GynaecologicalEmptyField:"Enter any fields to proceed",
    BirthControlMethodsEmptyField:"Enter any fields to proceed",
    MenstrualHistoryEmptyField:"Enter Required fields to proceed",
    AntenatalChartEmptyField:"Select required fields to proceed",
    AssessmentICDCode:"Please Select ICD 10 Codes",
    GeneralPatientAdvice:'Please Enter the General Patient Advice',
    DoctorNotePrescription:'Please Enter the Prescription',
    SelectExamination:'Select Examination to proceed',
    InvalidFamilyDetails:"Please enter the details",
    SelectHospitalizationYear:"Please select the Year",
    MedicalHistoryEmptyField:"Please enter the details",
    PlanNewEmptyField:"Please enter the details to proceed",
    PrescriptionNewEmptyField:"Please enter the details",
    SocialHistoryEmptyField:"Please enter the details",
    InvalidSymptoms:"Please select all fields to proceed",
    TestEmptyField:"Select all fields to proceed",
    VitalsEmptyField:"Enter details to proceed",
    TreatmentTempSuccess:"Template added successfully",
    InvalidPatientAge:"Please Select Age",
    InvalidVaccinationName:"Please Select Vaccination Name",
    InvalidTransactionId:"Please Enter The Transaction ID",
    InvalidClaimAmount:"Please Enter The Claim Amount",
    InvalidAdmissionDate:"Please Enter The Admision Date",
    InvalidAdmissionTime:"Please Enter The Admision Time",
    InvalidBedNumber:"Please Enter The Bed Number",
    InvalidVisitingConsultantName:"Please Enter The Visiting Consultant Name",
    InvalidComplaints:"Please Enter The Complaints",
    InvalidRoomName:"Please Select Room Name",
    InvalidBedName:"Please Select Bed Name",
    InvalidWardName:"Please Select Ward Name",
    InvalidDischargeDate:"Please Select The Valid Discharge Date",
    InvalidFoodType:"Please select the Instruction",
    InvalidDuration:"Please select the Duration",
    InvalidBrand:"Please select the Brand name",
    InvalidMAEN:"Please select M-A-E-N values ",
    InvalidQMSToken:"Please select the QMS Token",
    InvalidQMSCounter:"Please select the Counter",
    InvalidAdmittingComment: "Please enter the admitting comment",
    InvalidGeneric: "Please select the Generic name",

    //DB History
    HistorySectionValitation:"Please select any values to proceed",
    HospitalizationSelectType:"Please select either Hospitalised or Surgery",
    HospitalizationSelectIllness:"Please select illness/surgery name", 
    HospitalizationSelectYear:"Please select the year",
    HospitalNameDescription:"Please enter hospital name",
   
    // FR
    FRNotRecognizeSearchOtherRoutes: "Oops! Can't able to recognize, could you please try searching Name or Mobile number?",
    QMSCheckinValidation: "Please check-in to continue",
    RatingScaleInvalidTotalScore:"Select any values to proceed",

    //PSY
    HistoryFieldFilledBy:"Please enter the data to proceed",
    HOPIValidation:"Please enter values to proceed",
    MentalStatusExaminationField:"Please enter the examination data to proceed ",

    HistoryOfUsageEditErrorMessage:"History of Usage already added please click on edit icon to update the values",
    SelectEyeType:"Please Select the Eye Type",
    FundusAllFieldsAreEmpty:"Please Select the Values ",
    SubjectiveRefractionSaveErrorMessageWhenNUll:"Please Enter The Subjective Refraction Details"
}