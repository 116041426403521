//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, Dimensions ,ScrollView} from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from "../../../../styles/Color";
// import Style from "../../../../styles/Style";
import {
    LineChart,

} from "react-native-chart-kit";
// import PureChart from 'react-native-pure-chart';
import moment from "moment";

// create a component
const pageName = "isHBA1CChart"

export class Hba1cChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {}
        }
    }

    componentDidMount() {
        this.getHba1cValue()
    }

    getHba1cValue() {
        var service_url =
            Constants.HBA1C_CHART_SAVE +
            "?patient_id=" +
            this.state.patientAppointment.patient_id

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHba1cValueSuccess,
            this.getHba1cValueFailure
        );
    }

    getHba1cValueSuccess = (response) => {
        if (response.status === "success") {
            var field = this.state;
            
            var listOfDatas=[];
            var data=response.data || [];

            // default data
            listOfDatas.push({ "x":".", "y": 0 })

            var listOfLabels=[' '];
            var listOfValues=[0];

            for(var i=0;i<data.length;i++){

                let chart_date="";
                chart_date=data[i].chart_date?moment(data[i].chart_date).format("DD-MM-YYYY"):data[i].chart_date;

                listOfDatas.push({ "x":chart_date, "y":parseInt(data[i].chart_value) || 0 })

                listOfLabels.push(chart_date);
                listOfValues.push(data[i].chart_value);
            }

            field["listOfDatas"] = listOfDatas;
            field["listOfLabels"] = listOfLabels;
            field["listOfValues"] = listOfValues;
            
            this.setState({
                field,
            });
        }
    };
    getHba1cValueFailure = (error) => {

    }

  

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getHba1cValue()
            this.props.refreshRighSideComponent("");

        }
    }

  

    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            this.editHistory()
        }, 100);
    }
    editHistory() {
        this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
    }

    render() {
        let { listOfDatas }=this.state;
        // alert(JSON.stringify(listOfDatas))
        // let sample = [
        //     {
        //       seriesName: 'series1',
        //       data: [
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: '.', y: 0},
        //         {x: ' ', y: 0}
        //       ],
        //       color: 'gray'
        //     },
        //     {
        //         seriesName: 'series2',
        //         data: [
        //           {x: ' ', y: 20}
        //         ],
        //         color: 'gray'
        //     },
        //     {
        //         seriesName: 'series2',
        //         data: [
        //           {x: ' ', y: 40}
        //         ],
        //         color: 'gray'
        //     },
        //     {
        //         seriesName: 'series2',
        //         data: [
        //           {x: ' ', y: 60}
        //         ],
        //         color: 'gray'
        //     },
        //     {
        //         seriesName: 'series2',
        //         data: [
        //           {x: ' ', y: 80}
        //         ],
        //         color: 'gray'
        //     },
        //     {
        //       seriesName: 'Patient Data',
        //       data: listOfDatas,
        //       color: 'black'
        //     }
        //   ];
        return (
            <View style={[styles.container, { marginTop: 20}]}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"HbA1c Chart"}
                    editImage={""}
                />
                <View style={{ alignSelf: "center", marginTop: 20 }}>
                    {/* <Text>Bezier Line Chart</Text> */}
                    <ScrollView horizontal={true}>
                    <LineChart
                        data={{
                            labels: this.state.listOfLabels || [], //["January", "February", "March", "April", "May", "June"],
                            datasets: [
                                {
                                    data: this.state.listOfValues || []
                                    // data: [
                                    //     Math.random() * 100,
                                    //     Math.random() * 100,
                                    //     Math.random() * 100,
                                    //     Math.random() * 100,
                                    //     Math.random() * 100,
                                    //     Math.random() * 100
                                    // ]
                                }
                            ]
                        }}
                        width={700} //{Dimensions.get("window").width} // from react-native
                        height={300}
                        yAxisLabel=""
                        yAxisSuffix=""
                        yAxisInterval={10} // optional, defaults to 1
                        chartConfig={{
                            backgroundColor: "blue",
                            backgroundGradientFrom: color.themeBackgroud, //"#03fcba",  //"#fb8c00",
                            backgroundGradientTo: color.themeBackgroud, //"#43e8bc",   //"#ffa726",
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                            style: {
                                borderRadius: 16
                            },
                            propsForDots: {
                                r: "6",
                                strokeWidth: "2",
                                stroke: "#ffa726"
                            }
                        }}
                        style={{
                            marginVertical: 8,
                            borderRadius: 16
                        }}
                    /></ScrollView>
                </View>
                {/*
                <View style={{ marginTop: 100, alignSelf: "center" }}>
                    <PureChart
                        data={sample} 
                        type='line'
                        color={"red"}
                        height={300} 
                        width={400}
                        customValueRenderer={(index, point) => {
                            // alert(JSON.stringify(point))
                            if (index === 0 || (point.x===0)) return null
                            return (
                              <Text style={{textAlign: 'center', marginLeft: 30, marginTop: '-20'}}>{point.y}</Text>
                            )
                        }}
                        
                    />
                </View>
                */}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
