import React, { Component } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
} from "react-native";
import { color } from "../../../styles/Color";
import { NavigationTopHeader } from "../BaseComponent";
import { Switch, Tooltip } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';



export class UserConfig extends Component {
    constructor(props) {
        super(props);

        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {}: JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
        this.state = {
            UserDataList: [],
            activeStatus: "",
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic == false ?  false  : true,
            userType:this.props.userType
        };

    }
    componentDidMount() {
        this.getLoginUserData()
    }

    getLoginUserData = () => {
        var serviceUrl = Constants.LOGIN_USERS_GET;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getLoginUserDataSuccess,
            this.getLoginUserDataFailure
        );
    }

    getLoginUserDataSuccess = (success) => {
        this.setState({
            UserDataList: success.data
        })
    }
    getLoginUserDataFailure = (error) => {
        console.log(JSON.stringify(error));
    }

    lockandUnlockUser = (id) => {
        var states = this.state
        var url = Constants.LOGIN_USERS_GET
        var data =
        {
            login_id: id,
            active_status: states.activeStatus
        }

        OpthamologyService.getInstance().postComplaints(
            url,
            data,
            this,
            this.lockandUnlockUserSuccess,
            this.lockandUnlockUserFailure,
        );
    }
    lockandUnlockUserSuccess = (response) => {
        if (response.status == "success") {
            this.getLoginUserData()
        } else {
            console.log("error", response.message)
        }
    }


    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                    selectedTab={"userConfig"}
                    isNavigationBorder={true} />

                <View>
                    <View style={styles.TableContainer}>
                        <View>
                            <View style={{ width: "100%" }}>
                                <View style={[styles.TableTitleContainer]}>
                                    <View style={{ alignItems: "center", width: '4%' }} >
                                        <Text style={styles.TableHeadText}>S No</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '14%' }} >
                                        <Text style={styles.TableHeadText}>Role</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '16%' }} >
                                        <Text style={styles.TableHeadText}>Mail ID</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '12%' }} >
                                        <Text style={styles.TableHeadText}>Mobile Number</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '18%' }} >
                                        <Text style={styles.TableHeadText}>Username</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '16%' }} >
                                        <Text style={styles.TableHeadText}>Reset Password</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '8%' }} >
                                        <Text style={styles.TableHeadText}>Lock / Unlock</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '10%' }} >
                                        <Text style={styles.TableHeadText}>Action</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <ScrollView>
                            {this.state.UserDataList && this.state.UserDataList.length > 0 ? this.state.UserDataList.map((item, index) => {
                                return (
                                    <View key={index} style={styles.TableDataWrapper} >
                                        <View style={{ width: '4%', alignItems: 'center' }}><Text style={styles.TableDataText}>{index + 1}</Text></View>
                                        <View style={{ width: '14%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.role}</Text></View>
                                        <View style={{ width: '16%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.mailid == null || item.mailid == "" ? "-" : item.mailid}</Text></View>
                                        <View style={{ width: '12%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.mobile == null || item.mobile == "" ? "-" : item.mobile}</Text></View>
                                        <View style={{ width: '18%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.username}</Text></View>
                                        <View style={{ width: '16%', alignItems: 'center' }}>
                                            {/* <TouchableOpacity
                                                style={styles.BtnTouch}>
                                                <Text style={styles.ButtonText}>{"Sent Password"}</Text>
                                            </TouchableOpacity> */}
                                            <Text>Nil</Text>
                                        </View>
                                        <View style={{ width: '8%', alignItems: 'center' }}>
                                            <Switch checked={item.login_count != 3 ? true : false} disabled={false} />
                                        </View>
                                        <View style={{ width: '10%', alignItems: 'center' }}>
                                            <Tooltip placement='top' title={item.login_count != 3 ? "Lock user" : "Unlock User"}>
                                                <TouchableOpacity onPress={() => this.setState({
                                                    activeStatus: item.login_count != 3 ? "False" : "True"
                                                }, () => { this.lockandUnlockUser(item.id) })}>
                                                    <View style={styles.UnlockBtn}>
                                                        <Icon size={'1.3vw'} name={"unlock-alt"} color={color.white} />
                                                    </View>
                                                </TouchableOpacity>
                                            </Tooltip>
                                        </View>
                                    </View>
                                )
                            }) :
                                <View style={styles.NoRecordsView}>
                                    <Text style={styles.NoRecordsText}>No Records to show...</Text>
                                </View>
                            }
                        </ScrollView>
                    </View>

                </View>
            </View>
        )
    }
}

export default UserConfig

const styles = StyleSheet.create({
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw'
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2.8vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "4vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    Icon: {
        height: '1.62vw',
        width: '1.62vw',
        tintColor: color.themeDark
    },
    fontSizeMedium: {
        fontSize: ".85vw"
    },
    BtnTouch: {
        // marginRight: '2vw', 
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw"
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw"
    },
    UnlockBtn: {
        boxShadow: '0px 3px 6px #00000049',
        opacity: 1,
        borderRadius: "50vw",
        alignItems: 'center',
        justifyContent: 'center',
        width: "2.5vw",
        height: "2.5vw",
        backgroundColor: color.themeDark
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },

})
