import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import './PatientType.css'
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import { NumberFormat } from '../../../BaseComponent';
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';
import { Tooltip } from 'antd';

class PatientType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientType: {},
      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
    }
  };

  componentDidMount() {
    this.getPatientTypeChart()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getPatientTypeChart()
      })
    }
  }

  /* get Patient Type API start */
  getPatientTypeChart() {
    var serviceUrl = Constants.PATIENT_TYPE_CHART + "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPatientTypeChartSuccess,
      this.getPatientTypeChartFailure
    )
  }
  /* get Patient Type API end */

  /* get Patient Type Sucess */
  getPatientTypeChartSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        patientType: success.Data
      })
    }else{
      this.props.Response(success.message)
    }
  }
  /* get Patient Type Success */
  /* get Patient Type Fail */
  getPatientTypeChartFailure = (error) => {
    this.props.Response(error.message)
  }
  /* get Patient Type Fail */

  /* Export Excel API start*/
  getPatientTypeExcel = () => {
    var serviceUrl =
      Constants.PATIENT_TYPE_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getPatientTypeSuccess,
      this.getPatientTypeFailure,
      "excel"
    );
  }
  getPatientTypeSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getPatientTypeFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/
  render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1vw' }}>
          <TouchableOpacity onPress={this.getPatientTypeExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3vw' }}>
          <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #EB3649 , #FA7886 )' }} >
            <h3 id='circleText'>{this.state.patientType.new_patient_count ? NumberFormat(this.state.patientType.new_patient_count) : 0}</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #EB3649 , #FA7886 )', marginLeft: '2.5vw' }}>
            </div>
            <p style={{ marginLeft: '1vw', fontSize: '1vw' }}>New <br />Patients</p>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3vw' }}>
          <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #2680ED  , #6AA7F0  )' }} >
            <h3 id='circleText'>{this.state.patientType.review_patient_count ? NumberFormat(this.state.patientType.review_patient_count) : 0}</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #2680ED  , #6AA7F0  )', "marginLeft": '2.5vw' }}>
            </div>
            <p style={{ marginLeft: '1vw', fontSize: '1vw' }}>Review <br /> Patients</p>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3vw' }}>
          <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #52AF20  , #72B76E  )' }} >
            <h3 id='circleText'>{this.state.patientType.established_patient_count ? NumberFormat(this.state.patientType.established_patient_count) : 0}</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #52AF20  , #72B74E  )', "marginLeft": '2.5vw' }}>
            </div>
            <p style={{ marginLeft: '1vw', fontSize: '1vw' }}>Established <br />Patients</p>
          </div>
        </div>
        <div>
          <p style={{
            color: 'rgb(4, 183, 177)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: '1vw',
            fontWeight: 'bold'
          }}>Patients Type</p>
        </div>
      </div>
    )
  }
}

export default PatientType;