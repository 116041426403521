import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  TouchableWithoutFeedback, Picker,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton, CommonSectionHeader } from '../BaseComponent'

import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "symptoms";

var Messages = require('../../../utils/InfoMessages')

const toastConfig = {
  'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{ color: "white", textAlign: "center", justifyContent: "center", marginTop: "9px" }}>Complaint Created Successfully</Text>
    </View>
  ),
  'error': () => { },
  'info': () => { },
  'any_custom_type': () => { }
}


export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      userType: this.props.userType,
      isLocked: this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedSymptom: "",
      favoriteSymptom: [],
      selectedDurationButton: {},
      complaints: "",
      isEdit: false,
      isEditDuration: false,
      newItem: '',
      newDuration: '',
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      durationMode: "1",
      presentingComplients: "",
      historyofpresent: "",
      symtomSuggestionList: [],
      isSymtomSuggestionView: false,
      SelectedValue: []
    };
  }




  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getSymptomsFrequent()
  }

  componentWillReceiveProps(props) {

    let { id, favoriteSymptom, selectedSymptom } = this.state;
    var states = this.state

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {
          //  let selectedSymptom = "";

          var selectDuration = "1";
          var selectedDurationButton = {};
         

          if(item.duration_mode){
            if(item.duration_mode === '1'){
              selectDuration = '1';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else if(item.duration_mode === '2'){
              selectDuration = '2';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else if(item.duration_mode === '3'){
              selectDuration = '3';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else {
              selectDuration = '4';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }
          }

          favoriteSymptom.map((favItem) => {
            if (favItem.label === item.symptom) {
              selectedSymptom = favItem.value;            }
          })

          var symptomsName = item && item.symptom ? item.symptom : "";

          for (let i = 0; i < favoriteSymptom.length; i++) {
            if (favoriteSymptom[i].value == symptomsName) {
              var prepareChildren = { value: symptomsName, label: symptomsName }
              selectedSymptom = symptomsName;
            } else {
              var prepareChildren = { value: symptomsName, label: symptomsName }
              favoriteSymptom.push(prepareChildren);
              selectedSymptom = symptomsName;
            }

          }
          favoriteSymptom = this.removeDuplicate(favoriteSymptom)

          this.setState({
            selectedDurationButton: selectedDurationButton,
            durationMode: selectDuration,
            id: item.id,
            selectedSymptom,
            favoriteSymptom,
            selectedDuration: item.duration,
            comments: item.symptom_desc,
            presentingComplients: item.presenting_complaints ? item.presenting_complaints : "",
            historyofpresent: item.presenting_complaints_history ? item.presenting_complaints_history : ""
          }, () => {
            // this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
    if(props.Deletecorporate){
      this.setState({
        ConfirmDelete : props.Deletecorporate
      })
    }else{
      this.setState({
        ConfirmDelete : false
      })
    }
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }


  getSymptomsFrequent = () => {

    var service_url = Constants.SYMPTOMS_FREQUANT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSymptomsFrequentSuccess,
      this.getSymptomsFrequentFailure
    );
  };

  getSymptomsFrequentSuccess = response => {
    if (response.status === "success") {

      let drugs = [];

      response.data.map((item) => {

        (item) ? drugs.push({ value: item, label: item }) : null;
      });

      var field = this.state;
      field["favoriteSymptom"] = drugs;
      this.setState({ field });

    }
  };

  getSymptomsFrequentFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;
    this.setState({ states });
  }

  triggerDurationAdd = () => {
    let states = this.state;
    states['isEditDuration'] = true;
    this.setState({ states });
  }

  addMedicationToList = () => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteSymptom } = this.state;
    let field = this.state;

    if (newItem) {

      favoriteSymptom.push({ value: newItem, label: newItem });

      field["favoriteSymptom"] = favoriteSymptom;
      field["selectedSymptom"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }


  addDurationToList = (event) => {

    // console.log(" addMedicationToList " + this.state.newDuration)

    let { newDuration, selectedDuration } = this.state;
    let field = this.state;

    if (newDuration) {

      selectedDuration.push({ value: newDuration, label: newDuration });

      // field["duration"] = duration;
      field["selectedDuration"] = newDuration;
      field["newDuration"] = "";
      field["isEditDuration"] = false;
      field["durationMode"] = '1'

      this.setState({ field });
    }
  }





  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    if (item.value) {
      return (
        <SelectedButton
          //   {...otherProps}
          disable={this.state.isLocked}
          item={item}
          fill={fill}
          borderNeeded={borderNeeded}
          onPressItem={this.onPressAction.bind(this, selectedKey)}
          selected={selectedValue}
          closeable={selectedKey === "selectedSymptom" ? true : false}
          onPressClose={this.onPressClose.bind(this)}
        />
      );
    }
  }

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states
    })
  }

  onPressClose = (value) => {
    this.setState({
      SelectedValue : value
    })
    this.props.DeletePopup(value, "SymptomsNew")
  }

  addSymptom = event => {

    // console.log(" add complaint ");
    // console.log(" add complaint " + this.state.selectedSymptom);
    let states = this.state;
    var service_url = Constants.SYMPTOMS_GP_POST;

    let symptoms_name = "";

    states.favoriteSymptom.map((item) => {

      if (item.value === states.selectedSymptom) {

        symptoms_name = item.label;
      }
    });


    let data = {
      "id": states.id ? states.id : null,
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "symptom": symptoms_name.trim(),
      "duration": states.selectedDurationButton.value,
      "duration_mode":this.state.durationMode,
      "symptom_desc": states.comments ? states.comments : "",
      "presenting_complaints": states.presentingComplients ? states.presentingComplients : "",
      "presenting_complaints_history": states.historyofpresent ? states.historyofpresent : ""

    };

    if (Object.keys(this.state.selectedDurationButton).length > 0 && this.state.selectedSymptom) {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    } else {
      this.props.showResposeValue("error", Messages.InvalidSymptoms)
    }
  };


  getSymptomSuccess = response => {

    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)

      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message)

    }

  };

  getSymptomFailure = error => {
    this.props.showResposeValue("error", error.message)

    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  clearComplaint = event => {

    let states = this.state;
    states['selectedSymptom'] = "";
    states['complaints'] = "";
    // "duration_type": 3,
    states['selectedDuration'] = "";
    states['comments'] = "";
    states["durationMode"] = "1"
    states["selectedDurationButton"] = {}
    states["id"] = ""

    this.setState({ states }, () => {
      this.props.clearEditedItem(pageName, {})
    });

  }

  // calculateDays() {
  //   // let getDurationType = parseInt(this.state.durationMode)
  //   let getSelectedDurationButton = parseInt(this.state.selectedDurationButton.value)

  //   // let getSelectedDuration = getDurationType * getSelectedDurationButton

  //   this.setState({
  //     // selectedDuration: getSelectedDuration
  //     selectedDuration:getSelectedDurationButton
  //   })

  // }


  onPressButton(key, selectedvalue) {
    
    if (key == "selectedDurationButton") {
      this.setState({
        selectedDurationButton: selectedvalue
      })
    }
  }

  symptomSuggestion = (search) => {

    if ((search).trim() !== "") {
      var service_url = Constants.SYMPTOMS_FREQUANT_GET + "?symptom=" + search;

      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.symptomSuggestionSuccess,
        this.symptomSuggestionFailure
      );
    }else{
      var fields = this.state;
      fields["symtomSuggestionList"] = [];
      fields["isSymtomSuggestionView"] = false;

      this.setState({
        fields
      });
    }
  }

  symptomSuggestionSuccess = (response) => {
    if(response.status == "success"){
      var fields = this.state;
      fields["symtomSuggestionList"] = response.data;
      fields["isSymtomSuggestionView"] = true

      this.setState({
        fields,
      });
    }
  }

  deleteSymptoms = () => {
    var serviceUrl = Constants.SYMPTOMS_DELETE + "?symptom=" + this.state.SelectedValue;
    var data = {}
    OpthamologyService.getInstance().deleteComplaints(
        serviceUrl,
        data,
        this,
        this.deleteSymptomsSuccess,
        this.deleteSymptomsFailure
    )
}

deleteSymptomsSuccess = (response) => {
    if (response.status == "success") {
        this.setState({
          selectedAllergyName: {}
        }, () => { this.getSymptomsFrequent(), this.props.ClosePopup() ,this.props.showResposeValue("success", response.message) })
    }
    else {
      this.props.showResposeValue("error", response.message)
    }

}
deleteSymptomsFailure = (error) => {
  this.props.showResposeValue("error", error.message)
}



  render() {

    var durationButton = []
    durationButton = this.state.durationMode ==='1'? Constants.DURATION_DAY.slice(0, -1) : this.state.durationMode ==='2'? Constants.DURATION_WEEKS.slice(0, -1) : this.state.durationMode === "3" ? Constants.DURATION_MONTHS.slice(0, -1) : this.state.durationMode === "4" ? Constants.DURATION_YEAR : []

    return (

      <TouchableWithoutFeedback>

        <View>
          {this.renderSubHeadingBorder("Symptoms", false, true)}
          <View style={styles.TableHead1}>
            <CommonSectionHeader heading={"Symptom's Name"} />
            {/* {this.renderTitleWithMultipleBtn("Symptom's Name", false, 1, false, 'selectedApplanation',)} */}
          </View>
          {/* <FlatList
          data={this.state.favoriteSymptom}
          numColumns={4}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedSymptom', this.state.selectedSymptom )}
        //   extraData={this.state.selectedSymptom}
        /> */}

          <View style={{flexWrap:'wrap'}}>

          <View style={styles.selectedSymptoms}>
            {
              this.state.favoriteSymptom && this.state.favoriteSymptom.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedSymptom', this.state.selectedSymptom)
              })
            }
          </View>

          {this.state.isEdit ?
            <View
              style={[{
                marginLeft: '1.2vw',
                height: '2vw',
                marginTop: '0.6vw',
              }, Style.allButtonBorderRadius]}
            >
              <View style={{flexDirection: "row", flex: 1}}>
              <View style={{flex: 0.8}}>
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.SelectSymptomsTextInput, {color: color.black, borderColor: color.black }]}
                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newItem}
                onChangeText={text =>{
                  this.symptomSuggestion(text)
                  this.setState({ selectedSymptom: '', newItem: (text).toUpperCase() })}}
              />
              </View>
              <TouchableOpacity 
            style={[styles.SelectSymptomsSaveBtn, {backgroundColor: color.themeDark, borderColor: color.black }]}
            onPress={() => this.addMedicationToList()}
            >
              <Text style={styles.SelectSymptomsSaveBtnText}>{"Save"}</Text>
            </TouchableOpacity>
              </View>
              {(this.state.isSymtomSuggestionView && this.state.symtomSuggestionList && (this.state.symtomSuggestionList).length > 0) ? 
                <View style={[styles.AddSymptoms, {backgroundColor: color.whiteSmoke, borderColor: color.white}]}>
                  <FlatList
                  data={this.state.symtomSuggestionList}
                  renderItem={({item}) => 
                  <TouchableOpacity 
                  style={{paddingVertical: '0.2vw'}}
                  onPress={() =>{
                    this.setState({newItem: (item).toUpperCase(), symtomSuggestionList: [] },() => {
                      this.addMedicationToList()
                    })
                  } }
                  >
                    <Text style={styles.SelectSymptomsSaveBtnText}>{item}</Text>
                  </TouchableOpacity>
                  }
                  />
                </View>
                : null
              }
              </View> :
            <View style={styles.AddNewBtnView}>{
              this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this), this.state.isLocked)
            }</View>
          }
            </View>

          {/* {this.renderIconBtn("plus", "New", true)} */}





          <View style={styles.DurationView}>
            <View style={{ marginTop: '0.6vw' }}>
            <CommonSectionHeader heading={"Duration"} />
            </View>
            <View style={styles.DurationContainer}>
              <Picker
                enabled={!this.state.isLocked}
                selectedValue={this.state.durationMode}
                style={[Style.pickerRoundCornerView, Style.allButtonBorderRadius, { backgroundColor: "#E2E2E2", borderColor: "#E2E2E2" }]}
                itemStyle={Style.pickerFont}
                onValueChange={(itemvalue) => {

                  var states = this.state;
                  states["durationMode"] = itemvalue;
                  states["selectedDurationButton"] = {};
                  this.setState({ states });
                }}
              >

                <Picker.Item label="Days" value="1" />
                <Picker.Item label="Weeks" value="2" />
                <Picker.Item label="Months" value="3" /> 
                <Picker.Item label="Years" value="4" />
                
              </Picker>
            </View>
          </View>


          <View style={styles.DurationBtnView}>
            {
             durationButton && durationButton.map((item, index) => {
              return (
                <View style={[styles.DurationBtnreturnView, {borderColor:color.lightGray }]} key={index}>
                  <CommonButton
                    disable={this.state.isLocked}
                    item={item}
                    selectedvalue={this.state.selectedDurationButton}
                    butttonText={item.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedDurationButton"} />
                </View>
              )
            })
            }
          </View>

          {/* <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 20, color: color.black }}>Duration</Text>
        </View>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
              justifyContent: "space-between"
            }}
          >
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 1, label: "01" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 2, label: "02" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 3, label: "03" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 4, label: "04" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 5, label: "05" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
              justifyContent: "space-between"
            }}
          >
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 6, label: "06" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 7, label: "07" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 8, label: "08" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 9, label: "09" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 10, label: "10" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
          </View>
        </View>
        {this.state.isEditDuration?
        <View
        style={{
          height: 30,
          marginTop: 10,
          // backgroundColor: color.white,
          borderRadius: 20,
          borderWidth: 1,
          borderColor: "#B3D9FE",
          width: 150
        }}
      ><TextInput
        underlineColorAndroid="transparent"
        placeholder="type here"
        placeholderTextColor={color.black}
        style={{
          color: color.black,
          height: 30,
          paddingLeft: 5,
          marginRight: 0,
          flex: 1,
          textAlign: "center"
        }}
        ref={text => (this.nameInput = text)}
        // defaultValue=""
        autoCapitalize="none"
        value={this.state.newDuration}
        onChangeText={text => this.setState({ selectedSymptom:'',newDuration: text })}          
        onSubmitEditing={this.addDurationToList.bind(this)}
      /></View>:this.renderIconNewBtn("plus", "New", true, true, this.triggerDurationAdd.bind(this))} */}
          <View style={{ marginBottom: '0.6vw' }}>
            <CommonSectionHeader heading={"Description (Allow only 50 characters)"} />
            {/* {this.renderTitleWithMultipleBtn("Symptom's Name", false, 1, false, 'selectedApplanation',)} */}
          </View>

          <View key="symtoms" style={styles.DescriptionView}>
            {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4, '', {}, '', false, this.state.isLocked,50)}
          </View>

          {
            this.state.userType == "PD" ?
              <View style={{ marginBottom: '0.6vw' }}>
                <CommonSectionHeader heading={"Presenting Complaints"} />
              </View>
              : null
          }

          {
            this.state.userType == "PD" ?
              <View style={styles.DescriptionView}>
                {this.renderTextFieldView("", "presentingComplients", this.state.presentingComplients, true, 4, '', {}, '', false, this.state.isLocked)}
              </View>
              : null
          }


          {
            this.state.userType == "PD" ?
              <View style={{ marginBottom: '0.6vw' }}>
                <CommonSectionHeader heading={"History of Presenting Complaints"} />
              </View>
              : null
          }

          {
            this.state.userType == "PD" ?
              <View style={styles.DescriptionView}>
                {this.renderTextFieldView("", "historyofpresent", this.state.historyofpresent, true, 4, '', {}, '', false, this.state.isLocked)}
              </View>
              : null
          }



          <View style={{ marginTop: '1.2vw', alignSelf: "center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addSymptom.bind(this)} >
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View >
          {this.state.ConfirmDelete ? this.deleteSymptoms() : null}
          {/* <View>
              {this.state.show ? (
                <View style={{ height:40, width: '100%', backgroundColor: 'green',alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>Symptom Created Successfully</Text>
                </View>
              ) :null}
        </View > */}
          {/* <View>
        {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Symptom Created Successfully' : 
                    this.state.errorMessageFlag ?'Symptom History Failed' : null}</Text>
                </View>
              ) :null}
        </View > */}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
const styles = StyleSheet.create({
      TableHead1: { 
        marginBottom: '0.6vw' 
      },
      selectedSymptoms: { 
        flex: 1, 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        paddingHorizontal: '0.6vw' 
      },
      SelectSymptomsTextInput: {
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        borderWidth: 1,
        borderRadius: 4,
        fontSize: '1vw'
      },
      SelectSymptomsSaveBtn:{
        flex:0.2,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderRadius: 4,
        marginLeft: '0.5vw'
      },
      SelectSymptomsSaveBtnText: {
        fontSize: '1vw'
      },
      AddSymptoms: { 
        position:'absolute', 
        width: "80%", 
        marginTop: '2vw', 
        padding: '0.6vw', 
        maxHeight: '11.5vw', 
        borderRadius: 4,
        borderWidth: 2
      },
      AddNewBtnView: { 
        marginLeft: '1.2vw' 
      },
      DurationView: { 
        flexDirection: "row", 
        marginTop: '0.6vw', 
        marginBottom: '1.2vw', 
        justifyContent: "space-between", 
        zIndex: -1, 
        backgroundColor: "#E2E2E2" 
      },
      DurationContainer: { 
        width: "30%", 
        borderWidth: 1, 
        borderRadius: 5, 
        margin: '0.6vw' 
      },
      DurationBtnView: { 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        zIndex: -1, 
        marginLeft: '1.2vw'
      },
      DurationBtnreturnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw'
      },
      DescriptionView: { 
        marginTop: '0.6vw', 
        marginHorizontal: '1.2vw', 
      }
})