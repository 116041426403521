import React, { Component } from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import AsyncStorage from "../../../AsyncStorage";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const platform = Platform.OS;
const pageTitle = "Auto Refractometry";
const pageName = "autorefractometry";
const isPageName = "isAutoRefractometry";

export default class AutoRefroctometry extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,

      patientAppointment: this.props.patientAppointment,
      itemList: [],
      itemListAfter: [],
      isComplaint: isPageName === this.props.selectedView?true:false,
    
      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],
      comments:"",

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null
    };
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    },()=>{
      setTimeout(()=>{
        this.getItemList()
      },2000)
      // this.getComments()
    })
  }

  getComments = () => {
    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=Auto Refractometry";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCommentsSuccess,
      this.getCommentsFailure
    );
  };

  getCommentsSuccess = success => {
    if (success.status == "success") {
      // alert(success.data.comments)
      this.setState({
        comments: success.data.comments
      })
    }
  }

  getCommentsFailure = err => { alert(JSON.stringify(err)) }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getItemList();
      // this.getComments()
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getItemList = () => {

    let { userType, patientAppointment, selectedDoctorId } = this.state;
    var service_url = Constants.OPTHALMOLOGY_AUTOREFROCTOMETRY + "?appointment_id=" + patientAppointment.appointment_id;

    if( userType == Constants.ROLE_OPTOMETRY ) {

      service_url = service_url + "&doctor_id=" + selectedDoctorId
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }, 2000);
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
     
      let { itemList, itemListAfter } = this.state;
      itemList = response.data && response.data.auto_refractometry?response.data.auto_refractometry:[];
      // this.setState({ field });
      
      var data =  itemList;
      var availableEye=[]
      var availableEyeId=[]
      
      for(var i=0; i<data.length; i++){
          availableEye.push(data[i].eye_type);
          var prepareData={
            "eye_type":data[i].eye_type,
            "id":data[i].id
          }
          availableEyeId.push(prepareData);
      }

      var BeforeDilation = []
      var AfterDilation = []
      for(var i=0; i<data.length; i++){
        if(data[i].is_before_dilation == true){
          BeforeDilation.push(data[i])
        }else if(data[i].is_before_dilation == false){
          AfterDilation.push(data[i])
        }
      }

      this.setState({
        itemList: BeforeDilation,
        itemListAfter : AfterDilation,
        getAvailableEyeTypes: availableEye,
        getAvailableEyeTypesId: availableEyeId
      },()=>{
        this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)
      })
    }
  };

  getComplaintsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, eye_type, event) => {
    let { itemList, itemListAfter } = this.state

    let tempItemList = item.is_before_dilation ? itemList.map((item)=>{
      item['selected'] = item.eye_type === eye_type?true:false;
      return item;
    }) : itemListAfter.map((item)=>{
      item['selected'] = item.eye_type === eye_type?true:false;
      return item;
    })

    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, tempItemList,"",item.comments ? item.comments : "");
  }
  
  renderItem = (item, index) => {
   
    return (
      <View>
        
          <View
            style={[styles.ItemView, {width: platform === "web" ? "100%" : "75%"}]}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <View style={{ flex: 1, minWidth: '8.5vw' }}>
                <Text style={{ fontSize: '1vw', color: color.notesTableTitle }}>
                  {item.eye_type==="RE"?`Right Eye (${item.eye_type})`:null}
                  {item.eye_type==="LE"?`Left Eye (${item.eye_type})`:null}
                  {item.eye_type==="BE"?`Both Eyes (${item.eye_type})`:null}
                  {item.eye_type==="NA"?`Not Applicable (${item.eye_type})`:null}
                </Text>
              </View>
              <View style={{ flex: 2, marginLeft: '0.5vw' }}>
                <TouchableOpacity   disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item, item.eye_type)}>
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
              </View>
            </View>
            <View
              style={[styles.ItemTextView, {width: platform === "web" ? "100%" : "75%"}]}
            >
              
              {this.renderText('SPH', item.SPH || "-")}
              {this.renderText('CYL', item.CYL || "-")}
              {this.renderText('AXIS', item.AXIS || "-")}
              
              {/* {this.renderText('Occupation', item.occupation)}
              {this.renderText('Chart Used', item.chart_used)}
              {this.renderText('Muscle', item.muscle)} */}
              
            </View>
          </View>
        
        {/* <View style={{ height: 0.5, backgroundColor: "#E0E0E0",marginTop: 15 }} />  */}
      </View>
    );
  };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)

  }

  render() {

    let { itemList, itemListAfter } = this.state;
    let itemCountBefore = itemList ? itemList.length : 0;
    let itemCountAfter = itemListAfter ? itemListAfter.length : 0;
    
    if(!itemCountBefore){ itemCountBefore = 0; }
    if(!itemCountAfter) {itemCountAfter = 0;}

    return (
        <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
      <View style={{flexDirection: 'row'}}>
        {itemCountBefore>0?
        <View>
          <Text>Before Dilation</Text>
        <FlatList
          data={this.state.itemList ? this.state.itemList : []}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderItem(item, index)
          } 
          numColumns={2}
        />
        <View style={{marginTop: '1vw', flexDirection: 'row'}}>

          <Text
            style={{
              fontSize: '1vw',
              color: color.notesTableTitle,
              paddingLeft: 0,
              paddingRight: '2vw',
              marginRight: '1vw' //isIndividual ? 30 : 0
            }}
          >
            {"Comments:"}
          </Text>
          <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: '0.1vw' }}>
          {this.state.itemList && this.state.itemList.length>0?this.state.itemList[0].comments:'-'}
            </Text>
          </View>
          </View>
        :
        // <View style={{ marginTop: 20 }}>
        // <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
        //   {itemCount === 0 ? this.renderIconBtn("plus", "New", false, isPageName) : null}
        //   </TouchableOpacity>
        // </View>
        null
        
        }
        {itemCountAfter>0?
        <View>
          <Text>After Dilation</Text>
        <FlatList
          data={this.state.itemListAfter ? this.state.itemListAfter : []}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderItem(item, index)
          } 
          numColumns={2}
        />
        <View style={{marginTop: '1vw', flexDirection: 'row'}}>

          <Text
            style={{
              fontSize: '1vw',
              color: color.notesTableTitle,
              paddingLeft: 0,
              paddingRight: '2vw',
              marginRight: '1vw' //isIndividual ? 30 : 0
            }}
          >
            {"Comments:"}
          </Text>
          <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: '0.1vw' }}>
          {this.state.itemListAfter && this.state.itemListAfter.length>0?this.state.itemListAfter[0].comments:'-'}
            </Text>
          </View>
          </View>
        :
        // <View style={{ marginTop: 20 }}>
        // <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
        //   {itemCount === 0 ? this.renderIconBtn("plus", "New", false, isPageName) : null}
        //   </TouchableOpacity>
        // </View>
        null
        
        }
        </View>
        
      </View>
    );
  }
}
const styles = StyleSheet.create({
      ItemView: {
        marginLeft: '0.3vw',
        paddingTop: '0.6vw',
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
      },
      ItemTextView: {
        marginLeft: '0.3vw',
        paddingTop: '1.2vw',
        flexDirection: "row",
        justifyContent: "flex-start",
        // height: 50,
        alignItems: "center"
        // paddingBottom: 10
      }
})