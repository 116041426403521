import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "sexualAndMaritalHistory"


export class SexualAndMaritalHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",
            detailsOfAgepuberty: "",
            detailsOfTimemarriage: "",
            detailsOfknowledgesex: "",
            detailsmutualPartners: "",
            detailsOfMasturbation: "",
            detailsOfpairinteractions: "",
            detailsOffanxietypractices: "",
            detailsOfpartnesAge: "",
            detailsOfHeterosexual: "",
            detailsOfpartnerpersnolity: "",
            detailsOfInclinationspractices: "",
            detailsOfchildrensAge: "",
            selectemenstrual: "",
            detailsOfpartnersHealth: "",
            selectemarriage: "",
            detailsOfmaritalrelationship: "",
            detailsOfEmotional: "",
            detailsOfExtramaritalrelationship: "",
            detailsOfhomosexual: "",
            detailsOfpartnereducation: "",

        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states['id'] = data.id,
                    states['detailsOfAgepuberty'] = data.puberty_age
                states['detailsOfTimemarriage'] = data.age_during_marriage
                states['detailsOfknowledgesex'] = data.sex_knowledge_level
                states['detailsmutualPartners'] = data.mutual_consent_partner
                states['detailsOfMasturbation'] = data.masturbation_practice
                states['detailsOfpairinteractions'] = data.pair_interactions
                states['detailsOffanxietypractices'] = data.anxiety_fantasy
                states['detailsOfpartnesAge'] = data.partner_age
                states['detailsOfHeterosexual'] = data.hetero_fantasy
                states['detailsOfpartnerpersnolity'] = data.partner_personality
                states['detailsOfInclinationspractices'] = data.inclination_practice
                states['detailsOfchildrensAge'] = data.children_age
                states['selectemenstrual'] = data.menstraul_history
                states['selectemarriage'] = data.marriage_type
                states['detailsOfmaritalrelationship'] = data.martial_relation_quality
                states['detailsOfEmotional'] = data.emotional_disturbance
                states['detailsOfExtramaritalrelationship'] = data.extra_martial_relation
                states['detailsOfhomosexual'] = data.homo_fantasy
                states['detailsOfpartnereducation'] = data.partner_education
                states['detailsOfpartnersHealth'] = data.partner_health



                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })


            }
        }
    }

    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        dropDownList, selectedDropDownlistKey, selectedDropDownlist,
    ) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // drop down list 
                dropDownList={
                    dropDownList
                }
                selectedDropDownlist={selectedDropDownlist}
                dropDownListKey={selectedDropDownlistKey}
                onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // label with button select 


            />
        )
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    onChangeDropDownVallue(selectedDropDownValue, dropDownKey) {
        var states = this.state;
        states[dropDownKey] = selectedDropDownValue;
        this.setState({ states })

    }


    clearAllData() {
        var states = this.state;

        states['id'] = ""
        states['detailsOfAgepuberty'] = ""
        states['detailsOfTimemarriage'] = ""
        states['detailsOfknowledgesex'] = ""
        states['detailsmutualPartners'] = ""
        states['detailsOfMasturbation'] = ""
        states['detailsOfpairinteractions'] = ""
        states['detailsOffanxietypractices'] = ""
        states['detailsOfpartnesAge'] = ""
        states['detailsOfHeterosexual'] = ""
        states['detailsOfpartnerpersnolity'] = ""
        states['detailsOfInclinationspractices'] = ""
        states['detailsOfchildrensAge'] = ""
        states['selectemenstrual'] = ""
        states['selectemarriage'] = ""
        states['detailsOfpartnersHealth'] = ""
        states['detailsOfmaritalrelationship'] = ""
        states['detailsOfEmotional'] = ""
        states['detailsOfExtramaritalrelationship'] = ""
        states['detailsOfhomosexual'] = ""
        states['detailsOfpartnereducation'] = ""



        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "puberty_age": states.detailsOfAgepuberty ? states.detailsOfAgepuberty : null,
                "sex_knowledge_level": states.detailsOfknowledgesex,
                "masturbation_practice": states.detailsOfMasturbation,
                "anxiety_fantasy": states.detailsOffanxietypractices,
                "homo_fantasy": states.detailsOfhomosexual,
                "hetero_fantasy": states.detailsOfHeterosexual,
                "inclination_practice": states.detailsOfInclinationspractices,
                "menstraul_history": states.selectemenstrual ? states.selectemenstrual : 0,
                "marriage_type": states.selectemarriage ? states.selectemarriage : 0,
                "emotional_disturbance": states.detailsOfEmotional,
                "age_during_marriage": states.detailsOfTimemarriage ? states.detailsOfTimemarriage:null,
                "mutual_consent_partner": states.detailsmutualPartners,
                "pair_interactions": states.detailsOfpairinteractions,
                "partner_age": states.detailsOfpartnesAge ? states.detailsOfpartnesAge:null,
                "partner_education": states.detailsOfpartnereducation,
                "partner_personality": states.detailsOfpartnerpersnolity,
                "children_age": states.detailsOfchildrensAge ? states.detailsOfchildrensAge:null,
                "partner_health": states.detailsOfpartnersHealth,
                "martial_relation_quality": states.detailsOfmaritalrelationship,
                "extra_martial_relation": states.detailsOfExtramaritalrelationship
            }



            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_SEXUAL_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savesexualhistorySuccess,
                this.savesexualhistoryFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Sexual History already added please click on edit icon to update the values")

        }
    }

    savesexualhistorySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savesexualhistoryFailure = error => { }

    render() {


        const historyDropDownList = [
            { label: "Select", value: "" },
            { label: "Menarche", value: 1 },
            { label: "Regularity", value: 2 },
            { label: "Dysmenorechoea", value: 3 },
            { label: "Monorehajinal otigonerchea", value: 4 },
        ]

        const marriageType = [
            { label: "Select", value: "" },
            { label: "Arranged", value: 1 },
            { label: "Love", value: 2 },

        ]

        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Sexual & Marital History"} clearAllData={this.clearAllData.bind(this)} />

                {this.renderHistoryComponent(
                    "Age of onset of puberty", "Enter details",
                    "", false,
                    "detailsOfAgepuberty", this.state.detailsOfAgepuberty
                )}
                {this.renderHistoryComponent(
                    "Age at time of marriage", "Enter details",
                    "", false,
                    "detailsOfTimemarriage", this.state.detailsOfTimemarriage
                )}
                {this.renderHistoryComponent(
                    "Level of knowledge regarding sex & mode of gaining the same", "Enter details",
                    "", false,
                    "detailsOfknowledgesex", this.state.detailsOfknowledgesex
                )}
                {this.renderHistoryComponent(
                    "Mutual consent of partners", "Enter details",
                    "", false,
                    "detailsmutualPartners", this.state.detailsmutualPartners
                )}

                {this.renderHistoryComponent(
                    "Masturbation practices", "Enter details",
                    "", false,
                    "detailsOfMasturbation", this.state.detailsOfMasturbation
                )}

                {this.renderHistoryComponent(
                    "Nature of pair interactions", "Enter details",
                    "", false,
                    "detailsOfpairinteractions", this.state.detailsOfpairinteractions
                )}

                {this.renderHistoryComponent(
                    "Anxiety related to fantasy practices", "Enter details",
                    "", false,
                    "detailsOffanxietypractices", this.state.detailsOffanxietypractices
                )}

                {this.renderHistoryComponent(
                    "Partner's Age", "Enter details",
                    "", false,
                    "detailsOfpartnesAge", this.state.detailsOfpartnesAge
                )}

                {this.renderHistoryComponent(
                    "Homosexual fantasies", "Enter details",
                    "", false,
                    "detailsOfhomosexual", this.state.detailsOfhomosexual
                )}

                {this.renderHistoryComponent(
                    "Partner's Education", "Enter details",
                    "", false,
                    "detailsOfpartnereducation", this.state.detailsOfpartnereducation
                )}

                {this.renderHistoryComponent(
                    "Heterosexual fantasies", "Enter details",
                    "", false,
                    "detailsOfHeterosexual", this.state.detailsOfHeterosexual
                )}

                {this.renderHistoryComponent(
                    "Partner's Personality", "Enter details",
                    "", false,
                    "detailsOfpartnerpersnolity", this.state.detailsOfpartnerpersnolity
                )}

                {this.renderHistoryComponent(
                    "Inclinations and Practices", "Enter details",
                    "", false,
                    "detailsOfInclinationspractices", this.state.detailsOfInclinationspractices
                )}

                {this.renderHistoryComponent(
                    "Children's Age", "Enter details",
                    "", false,
                    "detailsOfchildrensAge", this.state.detailsOfchildrensAge
                )}



                {this.renderHistoryComponent(
                    "Menstrual History", "Enter details",
                    "", false,
                    "", "",
                    historyDropDownList, "selectemenstrual", this.state.selectemenstrual

                )}

                {this.renderHistoryComponent(
                    "Partner's Health", "Enter details",
                    "", false,
                    "detailsOfpartnersHealth", this.state.detailsOfpartnersHealth
                )}

                {this.renderHistoryComponent(
                    "Marriage Type", "Enter details",
                    "", false,
                    "", "",
                    marriageType, "selectemarriage", this.state.selectemarriage

                )}

                {this.renderHistoryComponent(
                    "Quality of marital relationship", "Enter details",
                    "", false,
                    "detailsOfmaritalrelationship", this.state.detailsOfmaritalrelationship
                )}


                {this.renderHistoryComponent(
                    "Emotional Disturbance", "Enter details",
                    "", false,
                    "detailsOfEmotional", this.state.detailsOfEmotional
                )}


                {this.renderHistoryComponent(
                    "Extra marital relationship", "Enter details",
                    "", false,
                    "detailsOfExtramaritalrelationship", this.state.detailsOfExtramaritalrelationship
                )}



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


            </View>
        )
    }
}
