import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { OvulationChartSectionHeading, CommonPrintButton,CommonHistorySectionHeader } from "../../BaseComponent";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";













import { color } from "../../../../styles/Color";
import BaseComponentStyle from "../../BaseComponentStyle";
import Icon from "react-native-vector-icons/FontAwesome";
// import Text from '../../CommonText';
const isPageName = "isAntenatalChart";

export class AntenatalChart extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      getDataByDateValues: {},
      isLocked: this.props.patientAppointment.is_locked,
      selectedPageName: this.props.selectedPageName,
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      lastMenstrualDate: "",
      therapy: "",
      procedure: "",
      AntenatalData: {},
      nilValue: "-",
      hideRemoveIcon: false,
      antenatal_chart_id: ""
    };
  }

  componentDidMount() {
    this.getAntenatal();
  }

  visitReport = () => {

    let states = this.state;
    if (this.state.antenatal_chart_id) {
      var serviceUrl =
        Constants.GET_ANTENATAL_VISITREPORT + "?antenatal_chart_id="+this.state.antenatal_chart_id+"&patient_id=" + this.state.patientAppointment.patient_id;
    } else {
      var serviceUrl =
        Constants.GET_ANTENATAL_VISITREPORT + "?patient_id=" + this.state.patientAppointment.patient_id;
    }
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );
  };

  getPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
     // console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
    //console.log("Get clinic list error response");
    console.log(error);
  };

  getAntenatal() {
    var service_url =
      Constants.GET_ANTENATAL +
      "?patient_id=" +
      this.state.patientAppointment.patient_id +
      "&antenatal_chart_id=";

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAntenatalSuccess,
      this.getAntenatalFailure
    );
  }

  // getAntenatalFailure = (response) => {};
  getAntenatalSuccess = (response) => {
    if (response.status === "success") {
     // console.log("getAntenatalSuccess ++ " + JSON.stringify(response.data));
      var field = this.state;
      field["AntenatalData"] = response.data;
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  componentWillReceiveProps(props) {
    // getUpdatedChartValue
    this.state.hideRemoveIcon = props.hideRemoveIcon
    this.state.selectedPageName = props.selectedPageName;
    if (props && Object.keys(props.getUpdatedChartValue).length > 0) {
      this.setState({
        AntenatalData: props.getUpdatedChartValue,
        antenatal_chart_id: props.getUpdatedChartValue.antenatal_chart_header.id
      });
      // props.getUpdatedChartValue("");
    }

    if (props.refreshRighSideComponentName == isPageName) {
      this.getAntenatal();
      this.fourHeading();
      this.props.refreshRighSideComponent("");
    }
  }

  selectedRightSideView(name) {
    this.props.selectedRightSideView(name);
  }

  antenatalChart() {
    return (
      <View>
        {this.antenatalChartHeader()}
        {this.antenatalChartList()}
      </View>
    );
  }

  deleteItem = (item) => {
    //this.props.editItem(pageName, item);
    var service_url = Constants.DELETE_ANTENTAL;
    let data = {
      antenatal_chart_values_id: item.id,
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  // getSymptomsFailure = (response) = {};

  getDeleteSuccess = (response) => {
    if (response.status === "success") {
      this.getAntenatal();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  antenatalChartHeader() {
    return (
      <View
        style={{
          flexDirection: "row",
          height: 50,
          borderWidth: 0.5,
          borderColor: "#888888",
          alignContent: "center",
          backgroundColor:"#F0F0F0",
    
        }}
      >

        <View style={[styles.headerStyle, { flex: 0.1 }]}>
          {this.tabelHeader("Date")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.09 }]}>
          {this.tabelHeader("Weeks")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.1 }]}>
          {this.tabelHeader("Weight (gm)")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.17 }]}>
          {this.tabelHeader("Blood Pressure (mm/Hg)")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.17 }]}>
          {this.tabelHeader("Height of the Fetus (cm)")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.15 }]}>
          {this.tabelHeader("Presentation")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.08 }]}>
          {this.tabelHeader("Remarks")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.14 }]}>
          {this.tabelHeader("Action")}
        </View>
      </View>
    );
  }

  antenatalChartList() {
    var today = new Date();
    var getTodayDate = moment(today).format("DD-MM-YYYY")


    return this.state.AntenatalData &&
      this.state.AntenatalData.antenatal_chart_values
      ? this.state.AntenatalData.antenatal_chart_values.map((item) => {
        var getItemDate = moment(item.date_of_visit).format("DD-MM-YYYY");
        var hideDeleteButton = true
        if (getItemDate == getTodayDate) {

          hideDeleteButton = false
        }
        return (
          
          <View
            style={{
              flexDirection: "row",
              // borderWidth: 0.5,
              borderLeftColor: "#888888",
              borderRightColor: "#888888",
              borderBottomColor: "#888888",
              borderLeftWidth: 0.5,
              borderRightWidth: 0.5,
              borderBottomWidth: 0.5,
              alignContent: "center",
            }}
          >
            <View style={[styles.headerStyle, { flex: 0.1 }]}>
              {this.tabelHeader(
                moment(item.date_of_visit).format("DD-MM-YYYY"),
                item
              )}
            </View>
            <View style={[styles.headerStyle, { flex: 0.09 }]}>
              {this.tabelHeader(item.weeks + " Weeks", item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.1 }]}>
              {this.tabelHeader(item.weight, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.17 }]}>
              {this.tabelHeader(item.blood_pressure, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.17 }]}>
              {this.tabelHeader(item.height_of_uterus, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.15 }]}>
              {this.tabelHeader(item.presentation, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.08 }]}>
              {this.tabelHeader(item.remarks, item)}
            </View>

            <View style={[styles.headerStyle, { flex: 0.14 }]}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginLeft: 10, width: "50%" }}>
                  <TouchableOpacity
                    disabled={this.state.isLocked}
                    onPress={() =>
                      this.editHistory(
                        item,
                        this.state.AntenatalData &&
                          this.state.AntenatalData.antenatal_chart_header
                          ? this.state.AntenatalData.antenatal_chart_header
                            .last_menstrual_period
                          : null,
                        this.state.AntenatalData.antenatal_chart_header
                      )
                    }
                  >
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
                </View>
                <View style={{ width: "50%", marginRight: 10 }}>
                  {/* <TouchableOpacity
                    disabled={this.state.isLocked}
                    onPress={() => {
                      this.deleteItem(item);
                    }}
                  >
                    {this.renderIdButton("trash")}
                  </TouchableOpacity> */}

                  {
                    this.state.hideRemoveIcon || hideDeleteButton ?
                      null
                      :
                      <TouchableOpacity
                        disabled={this.state.isLocked}
                        onPress={() => {
                          this.deleteItem(item);
                        }}
                      >
                        {this.renderIdButton("trash")}
                      </TouchableOpacity>
                  }

                </View>
              </View>
            </View>
          </View>
        );
      })
      : null;
  }

  tabelHeader(title, black) {
    return (
      <View style={{ padding: 3 }}>
        <Text
          style={{
            fontSize: 13,
            color: black ? "#000" : "black",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            textAlignVertical: "center",
            textAlign: "center",
          }}
          numberOfLines={100}
        >
          {title}
        </Text>
      </View>
    );
  }

  fourHeading() {
    var converted_date = moment(
      this.state.AntenatalData &&
        this.state.AntenatalData.antenatal_chart_header
        ? this.state.AntenatalData.antenatal_chart_header.last_menstrual_period
        : null
    ).format("DD-MM-YYYY");

    var expected_date_converted_date = moment(
      this.state.AntenatalData &&
        this.state.AntenatalData.antenatal_chart_header
        ? this.state.AntenatalData.antenatal_chart_header
          .expected_date_of_delivery
        : null
    ).format("DD-MM-YYYY");

    return (
      <View>
        {this.renderFourHeadingFields(
          "Gravida",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header &&
            this.state.AntenatalData.antenatal_chart_header.gravida
            ? this.state.AntenatalData.antenatal_chart_header.gravida
            : "0",
          "Para",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header
            ? this.state.AntenatalData.antenatal_chart_header.para
            : "0",
          "Live births",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header
            ? this.state.AntenatalData.antenatal_chart_header.live_births
            : "0",
          "Abortions",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header
            ? this.state.AntenatalData.antenatal_chart_header.abortions
            : "0",
          "Last menstrual period",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header
            ? converted_date
            : "",
          "Expected date of delivery",
          this.state.AntenatalData &&
            this.state.AntenatalData.antenatal_chart_header
            ? expected_date_converted_date
            : "-"
        )}
      </View>
    );
  }

  renderFourHeadingFields(
    gravidaTitle,
    gravidaValue,
    paraTitle,
    paraValue,
    liveOfBirthTitle,
    liveOfBirthValues,
    abortionsTitle,
    abortionsValue,
    lastMenstrualPeriodTitle,
    lastMenstrualPeriodValue,
    expectedDateTitle,
    expectedDateValue
  ) {
    return (
      <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
        <View style={{ flex: 0.15 }}>
          {this.renderContent(gravidaTitle, gravidaValue)}
        </View>
        <View style={{ flex: 0.15 }}>
          {this.renderContent(paraTitle, paraValue)}
        </View>
        <View style={{ flex: 0.15 }}>
          {this.renderContent(liveOfBirthTitle, liveOfBirthValues)}
        </View>

        <View style={{ flex: 0.10 }}>
          {this.renderContent(abortionsTitle, abortionsValue)}
        </View>

        <View style={{ flex: 0.20 }}>
          {this.renderContent(
            lastMenstrualPeriodTitle,
            lastMenstrualPeriodValue
          )}
        </View>

        <View style={{ flex: 0.25 }}>
          {this.renderContent(expectedDateTitle, expectedDateValue)}
        </View>

        {/* <View style={{ flex: 0.12 }}>
          {this.renderContent(therapyTitle, therapyValue)}
        </View>
        <View style={{ flex: 0.12 }}>
          {this.renderContent(procedureTitle, procedureValue)}
        </View>
         */}
      </View>
    );
  }

  renderContent(heading, value) {
    return (
      <View>
        <Text style={{ fontSize: 12, color: "black", marginBottom: 8,backgroundColor:"#F0F0F0",paddingVertical:15,paddingHorizontal:5 }}>
          {heading}
        </Text>
        <Text style={{ fontSize: 14,paddingHorizontal:10 }}>{value}</Text>
      </View>
    );
  }

  editHistory(item, lmd, updateHead) {
    // alert(JSON.stringify(item))
    // alert(JSON.stringify(lmd))

    // alert(JSON.stringify(updateHead))


    this.props.editHistory(item, isPageName, lmd, updateHead);
  }

  render() {
    return (
      <ScrollView>
        <View style={styles.container}>
          <View style={{ flexDirection: "row" }}>

            <View style={{ width: "45%" }}>
              <OvulationChartSectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={isPageName}
                heading={"Antenatal Chart"}
              />
            </View>

            <View style={{ width: "45%", alignItems: "flex-end",marginLeft:15 }}>
              <TouchableOpacity
                onPress={() =>
                  this.setState({
                    AntenatalData: {},
                  },()=>{
                    this.props.renderNewItemClear("AntenentalClear",true)
                  })
                }
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    flex: 0.2,
                    backgroundColor: color.themeDark,
                    borderRadius: 5,
                    minHeight: 30,
                    paddingHorizontal: 10,
                    paddingLeft: 30,
                    paddingRight: 30,

                  }}
                >
                  <Icon name="plus" size={16} color={color.white} />
                  <Text
                    style={{
                      fontSize: 11,
                      color: color.white,
                      fontWeight: "600",
                      marginLeft: 5,
                    }}
                  >
                    {"New Antenatal Chart"}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ width: "4%", alignItems: "flex-end", marginLeft: 25 }}>
              <CommonPrintButton onPress={() => { this.visitReport() }} /></View>
          </View>

          {this.fourHeading()}
          {this.antenatalChart()}
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    flex: 1,
  },
  headerStyle: {
    borderRightColor: "#888888",
    borderRightWidth: 0.5,
    justifyContent: "center",
  },
});
