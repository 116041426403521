import * as React from 'react';
import { View, TouchableOpacity, StyleSheet,Text} from 'react-native';
import { TabView, SceneMap,TabBar } from 'react-native-tab-view';
import DoctorVisitReport from "../common/DoctorVisitReport"
import TestReport from "../common/TestReport"
import { color } from '../../../styles/Color';


const FirstRoute = () => (
  <View>
    <DoctorVisitReport/>
    </View>
);
const SecondRoute = () => (
 <TestReport/>
);

export default class RebortsTab extends React.Component {
  state = {
    index: 0,
    routes: [
      { key: 'first', title: 'Doctor Visit Report' },
      { key: 'second', title: 'Test Report' },
      
      
    ],
  };

  _handleIndexChange = index => this.setState({ index });



  _renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
    
  });

  renderLabel = ({ route, focused, color }) => {
    return (
      <View>
        <Text
          style={[focused ? styles.activeTabTextColor : styles.tabTextColor]}
        >
          {route.title}
        </Text>
      </View>
    )
    }

  renderTabBar = props => {
    return(
    <TabBar
      {...props}
      renderLabel={this.renderLabel}
      indicatorStyle={{ backgroundColor: color.themeDark,borderRadius:20 }}
      style={{ backgroundColor: 'white',width:'60%' }}
    />

  );
}
render() {
  return (
    <TabView
      navigationState={this.state}
      renderScene={this._renderScene}
      renderTabBar={this.renderTabBar}
      onIndexChange={this._handleIndexChange}
    />
  );
}
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  activeTabTextColor: {
    color: 'Black',
    fontSize:20,
    
  },
  tabTextColor: {
    color: '#ccc',
    fontSize:20
    
  }
});
