import React, { Component } from 'react';
import { View, Text, TextInput,Dimensions, Picker,TouchableOpacity,Platform,StyleSheet, CheckBox} from 'react-native';
import { EditiAndDeleteForLineItem, OBGYNHistorySectionHeading, CommonHistorySectionHeader, HistoryYesOrNoSection, LabelWithTextBoxDR, CommonButton, LabelWithCalender, CommonAddButton } from '../BaseComponent'
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import Style from "../../../styles/Style";
import { color } from "./../../../styles/Color";
import Cancel from "react-native-vector-icons/Entypo";
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
const screenHeight = Dimensions.get("window").height;

export class PatientCheckout extends Component {
  constructor(props) {
    super(props);
    this.reference=React.createRef(),
    this.state = {
      isIpPatientCheckouted:this.props.isIpPatientCheckouted,
      DischargeTime: "",
      DischargeDate: moment(new Date()).format("YYYY-MM-DD"),

      patient_name: " ",
      patient_account_number: " ",
      admission_date: " ",
      admission_time: " ",
      hcid: " ",
      ip_number: "",
      ward_id: "",
      ward_name: " ",
      floor_id: " ",
      floor_number: " ",
      block_id: " ",
      block_name: " ",
      room_id: " ",
      room_number: " ",
      bed_number: "",
      visiting_consultant_1: " ",
      visiting_consultant_2: " ",
      visiting_consultant_3: " ",
      visiting_consultant_4: " ",
      complaints: " ",
      insurance_settlement_status: " ",
      selectedIpPatientDetails:this.props.selectedIpPatientDetails,

      reportCollectedFlag:false,
      discharge_date: "",
      discharge_time: "",
      bill_settlement: this.props.selectedIpPatientDetails.bill_settlement ? this.props.selectedIpPatientDetails.bill_settlement : "Partial",
      isAmaCheck: false,
      amaComment: "",
      isSaveDisable: false,
    };
  }
componentDidMount(){
  this.getPatientCheckout();
}
postPatientCheckout = () => {
  this.setState({
    isSaveDisable: true
  })
  var discharge_time = moment(this.state.DischargeTime, ["h:mm A"]).format("HH:mm");
  var data = {
    // ip_admission_id: this.state.ip_admission_id,
    // patient_id: this.state.patient_id,
    // doctor_id: this.state.doctor_id,
    // discharge_date: this.state.discharge_date,
    // discharge_time: this.state.discharge_time,
    // bill_settlement: this.state.bill_settlement,
    // reports_collected: null,

    "ip_admission_id": this.state.selectedIpPatientDetails.id,
    "patient_id": this.state.selectedIpPatientDetails.patient_id,
    "doctor_id": this.state.selectedIpPatientDetails.doctor_id,
    
    "discharge_date": this.state.DischargeDate,
    "discharge_time": discharge_time,
    "bill_settlement": this.state.bill_settlement,
    "reports_collected": this.state.reportCollectedFlag,
    "is_ama_patient": this.state.isAmaCheck,
    "ama_comment": this.state.isAmaCheck ? this.state.amaComment : ""
  }
  var service_url = Constants.IP_PATIENT_CHECKOUT_GET
    OpthamologyService.getInstance().postComplaints(
    service_url,
    data,
    this,
    this.postPatientCheckoutSuccess,
    this.postPatientCheckoutFailure
  );
}
postPatientCheckoutSuccess = success =>{
  if (success.status == "success"){
    this.props.showResposeValue("success", success.message)
  }else{
    this.setState({
      isSaveDisable: false
    })
    this.props.showResposeValue("error", success.message)

  }
}
postPatientCheckoutFailure = error => {
  this.setState({
    isSaveDisable: false
  })
  // console.log("opthamology complaints GET error response");
  console.log(error);
};


getPatientCheckout = () => {

  var service_url = Constants.IP_PATIENT_CHECKOUT_GET+ "?patient_id="+this.state.selectedIpPatientDetails.patient_id+"&doctor_id="+this.state.selectedIpPatientDetails.doctor_id+"&ip_admission_id="+this.state.selectedIpPatientDetails.id
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getPatientCheckoutSuccess,
    this.getPatientCheckoutFailure
  );
};


getPatientCheckoutSuccess = response => {
  // alert(JSON.stringify(response.data))
 
  if (response.status === "success") {
// alert(JSON.stringify(response.data))
    this.setState({ 
      // getCompanyName:response.data.insurance_name,
      // getBillAMount:response.data.bill_amount,
      // getPolicyNumber:response.data.policy_number,
      // getClaimAmount:response.data.claim_amount,
      // getTransactionId:response.data.transaction_id,
      // getBalanceAmount:response.data.balance_amount

      
      patient_name:response.data.patient_name,
      patient_account_number:response.data.patient_account_number,
      hcid:response.data.hcid,
      ip_number:response.data.ip_number,
      ward_id:response.data.ward_id,
      ward_name:response.data.ward_name,
      floor_id:response.data.floor_id,
      floor_number:response.data.floor_number,
      block_name:response.data.block_name,
      room_id:response.data.room_id,
      room_number:response.data.room_number,
      bed_number:response.data.bed_number,
      visiting_consultant_1:response.data.visiting_consultant_1,
      visiting_consultant_2:response.data.visiting_consultant_2,
      visiting_consultant_3:response.data.visiting_consultant_3,
      visiting_consultant_4:response.data.visiting_consultant_4,
      complaints:response.data.complaints,
      insurance_settlement_status:response.data.insurance_settlement_status,
      isAmaCheck: response.data.is_ama_patient,
      amaComment: response.data.ama_comment,
      reportCollectedFlag : response.data.reports_collected,
      admission_date: response.data.admission_date

    });
  }
};

getPatientCheckoutFailure = error => {
  // console.log("opthamology complaints GET error response");
  console.log(error);
};

  renderDisableTextBox(placeholder, width, center, type, value, error = false, disable = false) {
    return (
      <View>
        <Text style={{ zIndex: 2, fontSize: 10, position: "absolute", marginLeft: 10, backgroundColor: type == "IDP" ? color.themeShade : "white", paddingLeft: 5, paddingRight: 5, color: "#000000" }}>{placeholder}</Text>

        {
          disable ?
            <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: 8, color: "#888888", paddingTop: 10 }, Style.allButtonBorderRadius]} >
              {value}
            </Text> :
            <TextInput
          editable={false}
              style={{
                borderColor: error ? "red" : "#CDD1D5",
                borderRadius: 4,
                borderWidth: 0.5,
                backgroundColor: "#D1D1D1",
                width: "100%",
                height: 35,
                marginTop: 8,
                paddingLeft:10,
                borderColor: "rgb(4, 183, 177)"
              }} value={value}
            // onChangeText={(text) => {
            //     // value = text;
            //     // props.callBack(text, props.textKey);
            //     this.createPatientDetails(text, placeholder, type);
            // }}
            // onSubmitEditing={() => {
            //     props.enterTosubmit ?
            //         props.callBack(value, props.textKey, true)
            //         : null
            // }
            // }
            />



        }

      </View>
    )
  }
  renderTimePicker() {
    return (
      <View style={{ flex: 0.31 }}>
        <Text style={{ zIndex: 2, fontSize: 10, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
          {"Discharge Time"}
        </Text>
        <TimePicker
          use12Hours
          format="h:mm a"
          onFocus={()=>{
            var states = this.state;
            states["DischargeTime"] = ""
            this.setState({states})
            
          }}
          onOk={()=>{
              this.reference.current.blur()
          }}
          onChange={(time, timeString) => {
            var states = this.state;
            states["DischargeTime"] = timeString
            this.setState({ states })
            //  console.log("time, timeString"+timeString);
          }}
          ref={this.reference? this.reference: null}
          value={this.state.DischargeTime ? moment(this.state.DischargeTime, "hh:mm a") : ""}
          //value={value} 

          style={{
            textAlignVertical: 'top',
            // borderColor: error ? "red" : "#CDD1D5",
            borderColor: "#CDD1D5",
            borderRadius: 4,
            borderWidth: 1,
            width: "100%",
            height: 35,
            // padding: 5,
            paddingLeft: 10,
            marginTop: 8
          }}
        />

      </View>
    )
  }

  renderDatePicker() {
    return (
      <View style={{ flex: 0.31 }}>
        <Text style={{ zIndex: 2, fontSize: 10, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
          {"Discharge Date"}
        </Text>

        <DatePicker
          disabledDate={current => {
            return current && current < moment(this.state.admission_date).add(0, "days")
          }}
          value={
            this.state.DischargeDate
              ? moment(this.state.DischargeDate)
              : this.state.DischargeDate
          }
          clearIcon={false}
          onChange={(date) => {
            var fulldate = new Date(date);
            var converted_date = moment(fulldate).format("YYYY-MM-DD");

            this.setState({ DischargeDate: converted_date });
          }}

          format={"DD-MM-YYYY"}
          style={{
            textAlignVertical: 'top',
            // borderColor: error ? "red" : "#CDD1D5",
            borderColor: "#CDD1D5",
            borderRadius: 4,
            borderWidth: 1,
            width: "100%",
            height: 35,
            // padding: 5,
            paddingLeft: 10,
            marginTop: 8
          }}
        /></View>
    )
  }

  renderPicker() {
    return (
      <Picker
        enabled={this.state.isIpPatientCheckouted ? false :true}
        style={[Style.pickerView, {
          marginTop: 5, borderColor: '#CDD1D5'
        }, Style.allButtonBorderRadius]}
        itemStyle={{}}
        selectedValue={this.state.bill_settlement}
        onValueChange={(itemvalue) => {
          var states = this.state;
          states["bill_settlement"] = itemvalue;
          this.setState({ states });
        }}

      >
        {/* <Picker.Item label="" value="" /> */}
        <Picker.Item label="Partial" value="Partial" />
        <Picker.Item label="Completed" value="Completed" />
        <Picker.Item label="Not Paid" value="Not Paid" />
        {/* {
                    this.state.stateList.map((item, index) => {
                      return <Picker.Item key={index} label={item} value={item} />
                    })
                  } */}
      </Picker>
    )
  }


  renderButton() {
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignContent: "center", marginVertical: 1   }}>
            <TouchableOpacity
                disabled={this.state.isIpPatientCheckouted || this.state.isSaveDisable}
                // onPress={() => this.saveAdmissionButton()}
                onPress={()=>this.postPatientCheckout()}
                style={[Style.ipcancelButton, {backgroundColor: this.state.isSaveDisable ? "#888888" : color.themeDark,}]} >
                <Icon name={"save"} size={Platform === "web" ? 15 : 20} color={color.white} />
                <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Save"}</Text>
            </TouchableOpacity>

            <TouchableOpacity
                disabled={this.state.isIpPatientCheckouted}
                // onPress={() => props.createMedicalCertificate()}
                style={Style.ipAdmissonCancelButton} >
                <Cancel name={"cross"} size={Platform === "web" ? 15 : 20} color={color.themeDark} />
                <Text style={[Style.certificatetext, { marginLeft: 10, color: color.themeDark }]}>{"Cancel"}</Text>
            </TouchableOpacity>
        </View>
    )
}
  render() {
    return (
      <View style={{ height: screenHeight- 65, flex: 1, }}>
        <View style={{ marginTop: 20, marginHorizontal: 20 }}>
          <CommonHistorySectionHeader
            heading1={"Patient Check Out "}

            columnSize={[1]}  //total value is == of 1
            noOfColumn={1}
          /></View>
        <View style={{ flexDirection: "row", marginHorizontal: 35, marginTop: 10, justifyContent: "space-between" }}>
          <View style={{ width: 250, justifyContent: 'center', marginBottom: 18 }}>
            {this.renderDatePicker()}

            {/* {this.renderDisableTextBox("Discharge Date ")} */}
          </View>
          <View style={ styles.textBox}>
            {this.renderTimePicker()}

            {/* {this.renderDisableTextBox("Discharge Time ")} */}
          </View>
          <View style={styles.textBox}>

            {this.renderDisableTextBox("Patient Name ","","","",this.state.patient_name)}
          </View>
          <View style={styles.textBox}>

            {this.renderDisableTextBox("Patient Account Number ","","","",this.state.patient_account_number)}
          </View>
        </View>
        <View style={{ flexDirection: "row", marginHorizontal: 35, marginTop: 10, justifyContent: "space-between" }}>
          <View style={{ width: 250, justifyContent: 'center', marginBottom: 18 }}>

            {this.renderDisableTextBox("HCID ","","","",this.state.hcid)}
          </View>
          <View style={ styles.textBox}>

            {this.renderDisableTextBox(" IP Number ","","","",this.state.ip_number)}
          </View>
          <View style={ styles.textBox}>

            {this.renderDisableTextBox("Ward Name ","","","",this.state.ward_name)}
          </View>
          <View style={ styles.textBox}>

            {this.renderDisableTextBox("Floor Number ","","","",this.state.floor_number)}
          </View>
        </View>
        <View style={{ flexDirection: "row", marginHorizontal: 35, marginTop: 10, justifyContent: "space-between" }}>
          <View style={{ width: 250, justifyContent: 'center', marginBottom: 18 }}>

            {this.renderDisableTextBox("Block Name ","","","",this.state.block_name)}
          </View>
          <View style={styles.textBox}>

            {this.renderDisableTextBox("Room Number ","","","",this.state.room_number)}
          </View>
          <View style={ styles.textBox}>

            {this.renderDisableTextBox("Bed Number ","","","",this.state.bed_number)}
          </View>
          <View style={styles.textBox}>

            {this.renderDisableTextBox("Visiting Consultant 1","","","",this.state.visiting_consultant_1)}
          </View>
        </View>
        <View style={{ flexDirection: "row", marginHorizontal: 35, marginTop: 10, justifyContent: "space-between" }}>
          <View style={{ width: 250, justifyContent: 'center', marginBottom: 18 }}>

            {this.renderDisableTextBox("Visiting Consultant 2","","","",this.state.visiting_consultant_2)}
          </View>
          <View style={ styles.textBox}>

            {this.renderDisableTextBox("Visiting Consultant 3","","","",this.state.visiting_consultant_3)}
          </View>
          <View style={styles.textBox}>

            {this.renderDisableTextBox("Visiting Consultant 4","","","",this.state.visiting_consultant_4)}
          </View>
          <View style={styles.textBox}>

            {/* {this.renderDisableTextBox("Visiting Consultant 5","","","",visiting_consultant_5)} */}
          </View>
        </View>
        <View style={{flexDirection: "row", flex: 1, marginTop: 20}}>
          <View style={{flexDirection:"column", flex: 0.27}}>
            <View style={{ flexDirection: "row" ,marginTop: 20, marginHorizontal: 20, flex: 0.25 }}>
              <View style={{flexDirection:"row",width:250,marginLeft:15}}>
                <View style={{justifyContent:"center", marginTop: 10}}>
                <Text >Bill Settlement</Text>
                </View>
                <View style={{marginLeft:10}}>
                {this.renderPicker()}
                </View>
              </View>
            </View>
            <View style={{marginTop: 50, marginHorizontal: 20 , flex:0.25}}>
          <View style={{flexDirection:"row", marginLeft: 15}}>
            <View style={{justifyContent:"center"}}>
            <Text >Insurance Settlement Status</Text>
            </View>
            <View style={{marginLeft:10}}>
            <TextInput 
            editable={this.state.isIpPatientCheckouted ? false :true}
            style={{borderWidth:0.5,width:100,height:30,borderRadius:4,justifyContent:"center",paddingLeft:10}}
            value={this.state.insurance_settlement_status}
            />
                  </View>

          </View>
        </View>
        </View>
            
        
     {/* //////////// */}
     {/* <View style={{ flexDirection: "row" ,marginTop: 20, marginHorizontal: 20 }}>
          <View style={{flexDirection:"row",width:250,marginLeft:15}}>
            <View style={{justifyContent:"center"}}>
            <Text >Reports Collected</Text>
            </View>
            <View style={{marginLeft:10}}>
        
            </View>
          </View>
        </View> */}
        {/* ///////////////// */}
        <View style={{flexDirection: "column", flex: 0.2}}>
        {this.renderReportCollectedView()}
        <View style={{ flexDirection: "row" ,marginTop: 50, marginHorizontal: 20, alignItems:'center', flex:0.25 }}>
          <CheckBox
            value={this.state.isAmaCheck}
            disabled={this.props.selectedIpPatientDetails.bill_settlement ? true : false}
            onValueChange={() => {
              this.setState({
                isAmaCheck : !this.state.isAmaCheck,
                amaComment : ""
              })
            }}
          />
          <Text style={{marginLeft: 5}}>AMA Patient</Text>
        </View>
        </View>
        

        <View style={{flexDirection: "column", flex: 0.53}}>
        {this.state.isAmaCheck ? 
        
        <View style={{ flexDirection: "row" ,marginTop: 20, marginHorizontal: 20 }}>
          <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#000000" }}>{"AMA Comments"}</Text>
          <TextInput
            // maxLength={100}
            multiline
            textAlignVertical={"top"}
            disabled={this.props.selectedIpPatientDetails.bill_settlement ? true : false }
            style={{
                backgroundColor:(this.state.isIpPatientCheckouted)? color.disableComponentColor :color.white,
                textAlignVertical: 'top',
                // borderColor: error ? "red" : "#CDD1D5",
                borderColor: "#000000",
                borderRadius: 4,
                borderWidth: 1,
                width: 350,
                height: 70,
                padding: 5,
                paddingLeft: 10,
                marginTop: 8,
            }} value={this.state.amaComment}
            onChangeText={(text) => {
                var states = this.state;
                states["amaComment"] = text;
                this.setState({ states })
            }}
          />
        </View>
        : null }
        </View>

        </View>
        <View style={{flexDirection:"row",justifyContent:"center",marginTop:10}}>
        {this.renderButton()}


        </View>
      </View>
    );
  }

  onChangeReportCollectedValue(flag){
    this.setState({
      reportCollectedFlag:flag
    })
  }
  // 
  renderReportCollectedView() {
    return (
      <View style={{ flexDirection: "row" ,alignItems:'center',marginTop: 20, marginHorizontal: 20, flex: 0.25}}>
        <Text>{"Reports Collected"}</Text>
        <View style={styles.reportCollectedYesOrNo}>
          <TouchableOpacity onPress={() => { this.onChangeReportCollectedValue(true) }}
            disabled={this.state.isIpPatientCheckouted}
            style={[this.state.reportCollectedFlag ? styles.reportCollectedYes : null, { paddingHorizontal: 8, justifyContent: "center" }]}>
            <Text style={[this.state.reportCollectedFlag ? { color: color.white } : {}]}>{"Yes"}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { this.onChangeReportCollectedValue(false) }}
            disabled={this.state.isIpPatientCheckouted}
            style={[!this.state.reportCollectedFlag ? styles.reportCollectedNo : {}, { paddingHorizontal: 8, justifyContent: "center" }]}
          >
            <Text style={[!this.state.reportCollectedFlag ? { color: color.white } : {}]}>{"No"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

}
const styles = StyleSheet.create({
  buttonrowView: {
      flexDirection: 'row', justifyContent: 'center', alignContent: "center", marginVertical: 10
  },textBox:{
    marginLeft: 15, width: 250, justifyContent: 'center', marginBottom: 18 
  },
  reportCollectedYes:{
    backgroundColor:color.themeDark,
    borderRightColor:"#888888",
    borderTopEndRadius:4,
    borderBottomRightRadius:4,
  },
  reportCollectedNo: {
    backgroundColor:color.themeDark,
    borderLeftColor:"#888888",
    borderTopLeftRadius:4,
    borderBottomLeftRadius:4      
  },
  reportCollectedYesOrNo:{ height:30,
    flexDirection: "row", borderRadius: 4, 
    borderWidth: 1, borderColor: "#888888",marginLeft:10 }
});