import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";

// create a component
const pageName = "isPhysicalActivity"

export class PhysicalActivity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {},
            hideEditIcon:true
        }
    }

    componentDidMount() {
        this.getPhysicalActivity()



    }

    getPhysicalActivity() {
        var service_url = Constants.GET_PHYSICAL_ACTIVITY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPhysicalActivitySuccess,
            this.getPhysicalActivityFailure
        );
    }


    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    getPhysicalActivitySuccess = success => {
      //  console.log("success" + JSON.stringify(success))
        if (success.status == "success") {
            var data = success.data

            var booleanValues = []
            var stringVaues = []
            var hideEditIcon = true
            Object.keys(data).forEach((key) => {
                if(data[key]){
                    hideEditIcon = false
                }
                if (typeof data[key] == "boolean") {
                    let prepareData = {}
                    if (key == "exercise_regularly") {
                        prepareData = {
                            key: "Do you exercise regularly?",
                            value: data[key] ? "Yes" : "No",
                        }
                    } 
                    // else if (key == "blood_glucose_check") {
                    //     prepareData = {
                    //         key: "Do you check your blood glucose?",
                    //         value: data[key] ? "Yes" : "NO",
                    //         details: data.bg_check_frequency
                    //     }
                    // } else if (key == "low_blood_glucose") {
                    //     prepareData = {
                    //         key: "Do you get low blood glucoes?",
                    //         value: data[key] ? "Yes" : "NO",
                    //         details: data.low_bg_date_time
                    //     }
                    // }

                    booleanValues.push(prepareData)
                } else {
                    let prepareData = {}
                    var dataName=""

                    if (key == "current_activity_level" && data[key]) {
                        prepareData = {
                            key: "Please rate your current daily activity level",
                            value: data[key],
                        }
                    } else if (key == "exercise_frequency_weeks" && data[key]) {
                        var days=""
                        if(data[key]==1){
                            days=" day"
                        }else{
                            days=" days"
                        }
                        prepareData = {
                            key: "if yes,How many days a week?",
                            value:data[key]? data[key]+days:"",
                        }
                    } else if (key == "exercise_duration_minutes" && data[key]) {
                        prepareData = {
                            key: "if yes,How many minutes per session?",
                            value: data[key]?data[key]+" mins":"",
                        }
                    } else if (key == "exercise_types" && data[key]) {
                        prepareData = {
                            key: "Type of exercise",
                            value: data[key],
                            details:data.exercise_types_desc

                        }
                    } else if (key == "exercise_intensity" && data[key]) {
                        if(data[key]==1){
                            dataName="Low Intensity: you can talk easily while exercising"  
                        }else if(data[key]==2){
                            dataName="Moderate Intensity: You can breathe a little harder, but you can still talk"  
                        } else if(data[key]==3){
                            dataName="High Intensity: You breathe hard, and its difficult to talk while exercising"  
                        }
                        prepareData = {
                            key: "Intensity level",
                            value: dataName,
                        }
                    } else if (key == "exercise_skip_reason" && data[key]) {
                        prepareData = {
                            key: "Reason or problems that prevent you from exercising?",
                            value: data[key],
                        }
                    } 
                    stringVaues.push(prepareData)
                }
            })



            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

           // console.log("success _________> " + JSON.stringify(removeEmptyObj))
            this.setState({
                getListofDiabeticHistory: data,
                listOfDatas: removeEmptyObj,
                hideEditIcon:hideEditIcon
            })


        }
    }
    getPhysicalActivityFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getPhysicalActivity();
            this.props.refreshRighSideComponent("");
        }
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // setTimeout(() => {
        //     this.editHistory()
        // }, 100);
    }
    editHistory() {
   this.props.selectedRightSideView(pageName);
    setTimeout(() => {
        this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
    }, 100);    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Physical Activity"}
                    editImage={this.state.hideEditIcon ? "" :"pencil"}
                />

                {this.renderContent()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
