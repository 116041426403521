import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "EffectsOnFamily"


export class EffectsOnFamilyNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            fulfilmentofResponsiblitesFlag: false,
            detailsOffulfilmentofResponsiblites: "",

            timeSpentWithFamilyFlag: false,
            detailsOfTimeSpentWithFamily: "",

            aggressionFlag: false,
            detailsOfAggression: "",

            maritalProblemsFlag: false,
            detailsOfMaritalProblems: "",

            othersFlag: false,
            detailsOfOthers: "",



        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states["fulfilmentofResponsiblitesFlag"] = data.fulfilment;
                states["detailsOffulfilmentofResponsiblites"] = data.fulfilment_desc;

                states["timeSpentWithFamilyFlag"] = data.time_spent_family;
                states["detailsOfTimeSpentWithFamily"] = data.time_spent_family_desc;


                states["aggressionFlag"] = data.aggression;
                states["detailsOfAggression"] = data.aggression_desc;


                states["maritalProblemsFlag"] = data.martital_problems;
                states["detailsOfMaritalProblems"] = data.martital_problems_desc;

                states["othersFlag"] = data.other;
                states["detailsOfOthers"] = data.other_desc;

                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["fulfilmentofResponsiblitesFlag"] = false;
        states["detailsOffulfilmentofResponsiblites"] = "";

        states["timeSpentWithFamilyFlag"] = false;
        states["detailsOfTimeSpentWithFamily"] = "";


        states["aggressionFlag"] = false;
        states["detailsOfAggression"] = "";


        states["maritalProblemsFlag"] = false;
        states["detailsOfMaritalProblems"] = "";

        states["othersFlag"] = false;
        states["detailsOfOthers"] = "";

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "fulfilment": states.fulfilmentofResponsiblitesFlag,
                "fulfilment_desc": states.detailsOffulfilmentofResponsiblites,
                "time_spent_family": states.timeSpentWithFamilyFlag,
                "time_spent_family_desc": states.detailsOfTimeSpentWithFamily,
                "aggression": states.aggressionFlag,
                "aggression_desc": states.detailsOfAggression,
                "martital_problems": states.maritalProblemsFlag,
                "martital_problems_desc": states.detailsOfMaritalProblems,
                "other": states.othersFlag,
                "other_desc": states.detailsOfOthers,
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_FAMILY_EFFECTS_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveEffectsOnFamilySuccess,
                this.saveEffectsOnFamilyFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Effects on family already added please click on edit icon to update the values")
        }
    }

    saveEffectsOnFamilySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveEffectsOnFamilyFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Effects on family"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Fulfilment of responsibilities", "Enter details",
                    "fulfilmentofResponsiblitesFlag", this.state.fulfilmentofResponsiblitesFlag,
                    "detailsOffulfilmentofResponsiblites", this.state.detailsOffulfilmentofResponsiblites
                )}

                {this.renderHistoryComponent(
                    "Time spent with the family", "Enter details",
                    "timeSpentWithFamilyFlag", this.state.timeSpentWithFamilyFlag,
                    "detailsOfTimeSpentWithFamily", this.state.detailsOfTimeSpentWithFamily
                )}
                {this.renderHistoryComponent(
                    "Aggression", "Enter details",
                    "aggressionFlag", this.state.aggressionFlag,
                    "detailsOfAggression", this.state.detailsOfAggression
                )}
                {this.renderHistoryComponent(
                    "Marital Problems", "Enter details",
                    "maritalProblemsFlag", this.state.maritalProblemsFlag,
                    "detailsOfMaritalProblems", this.state.detailsOfMaritalProblems
                )}
                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "othersFlag", this.state.othersFlag,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
