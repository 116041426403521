import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet, Picker } from "react-native";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader, NavigationTopHeader } from "../BaseComponent";
import AddIcon from '../../../../assets/images/BillConfig/Add.svg'
import CancelIcon from '../../../../assets/images/BillConfig/Cancel.svg'
import EditIcon from '../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../assets/images/BillConfig/delete.svg'
import DropDownIcon from '../../../../assets/images/BillConfig/Down_Arrow.svg'

const screenHeight = Dimensions.get("window").height;

let defaultPatientTagEntry = {
    "tag_name": "",
}

export default class TagConfiguration extends Component {
    constructor(props) {
      super(props);
      const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {}: JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};

      this.state = {
        tagSearchKey: "",
        userType: this.props.userType,
        selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        // patientTagNewEntry: [
        //     defaultPatientTagEntry
        // ]
        tagName: "",
        selectedEditId: null,
        PatientTagList: [],
        deletedId : null,
        isDeleteClick: false,
        confirmDeletedId: null,
        isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ?  false  : true
      }
    }

    componentDidMount(){
        this.getPatientTags()
    }

    getPatientTags = () => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_PATIENT_TAG + "?patient_tag_name=" + this.state.tagSearchKey
        }else{
            url = Constants.CLINIC_PATIENT_TAG + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&patient_tag_name=" + this.state.tagSearchKey
        }
        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getPtTagSuccess,
            this.getPtTagFailure
        );
    }

    getPtTagSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                PatientTagList: response.data,
            })
        }
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }

    renderTagConfigLeft = () => {
        var states = this.state
        return(
            <View style={{maxHeight: "90%", height: '-webkit-fill-available' }}>
                <View style={{marginTop: 5, backgroundColor: color.sectionHeadingDarkColor, minHeight: 40, maxHeight: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.70}}>
                    <Text style={styles.headerFontStyle}>{"Patient's Tag"}</Text>
                </View>
                <View style={{flex: 0.30}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {states.PatientTagList.length > 0 && states.PatientTagList.map((item, index) => (
                    <View style={{maxHeight: 60, marginHorizontal: 5, paddingHorizontal: 15, minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center"}}>
                        <View style={{ flexDirection: 'row', flex: 1, alignItems: "center"}}>
                            <View style={{flex: 0.70}}>
                                <Text style={{fontSize: 14,}}>{item.patient_tag_name}</Text>
                            </View>
                            <View style={{flex: 0.30, flexDirection: "row"}}>
                            <TouchableOpacity
                            onPress={() => {
                                this.editPatientTag(item)
                            }}
                            >
                                <Image
                                    source={EditIcon}
                                    style={{ height: 25, width: 25 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                            style={{marginLeft: 30, width: "fit-content"}}
                            onPress={() => {
                                this.deleteTag(item.id)
                            }}
                            >
                                <Image
                                    source={DeleteIcon}
                                    style={{ height: 25, width: 30}}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                ))}
                </ScrollView>
            </View>
        )
    }

    renderTagConfigRight = () => {
        return(
            <View style={{paddingHorizontal: 10, paddingVertical: 20, width: "100%"}}>
            <View style={{flexDirection: "row"}}>
                <View style={{ width: "90%"}}>
                <CommonHistorySectionHeader
                heading1={"Add Patient Tag"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                </View>
                <View style={{height: 35,width: "10%", backgroundColor: color.sectionHeadingDarkColor, justifyContent: "center", alignItems: "center"}}>
                    {/* <TouchableOpacity style={{}} onPress={() => {this.onPressNewPatientTag()}} >
                    <Image
                        source={AddIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity> */}
                </View>
            </View>
            <TextInput 
                value={this.state.tagName}
                style={{
                    borderColor: color.black,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "100%",
                    height: 35,
                    padding: 15,
                    marginTop: 10,
                }}
                onChangeText = {(text) => {
                    this.setState({
                        tagName: text
                    })
                }}
                />
            {/* <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: screenHeight - 420,}} >
                
            </ScrollView> */}
            <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 20 }}>
                <View style={{marginRight: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.postPatientTag()}}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.onPressClear()
                }}
                buttonKey={"clear"} />
                </View>
                </View>
            </View>
        )
    }

    editPatientTag = (data) => {
        this.setState({
            tagName: data.patient_tag_name,
            selectedEditId: data.id
        })
    }

    deleteTag = (id) => {
        this.setState({
            deletedId : id,
            isDeleteClick: true,
        })
    }

    deletePatientTag = () => {
        var states = this.state
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_PATIENT_TAG 
        }else{
            url = Constants.CLINIC_PATIENT_TAG + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
        }

        var data = {
            id: states.confirmDeletedId
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            data,
            this,
            this.deletePatientTagSuccess,
            this.deletePatientTagFailure
        )
    }

    deletePatientTagSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getPatientTags()
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    postPatientTag = () => {
        var states = this.state

        var url = ""
        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_PATIENT_TAG
        }else{
            url = Constants.CLINIC_PATIENT_TAG + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        if((states.tagName).trim() === ""){
            this.props.showResposeValue("error", "Patient Tag Should be Mandatory")
        }else{
            var data = {
                patient_tag_name: states.tagName
            }
            if(states.selectedEditId){
                data["id"] = states.selectedEditId
            }
            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.PtTagPostSuccess,
                this.PtTagPostFailure
            );
        }
    }

    PtTagPostSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getPatientTags()
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    onPressClear = () => {
        this.setState({
            selectedEditId: null,
            tagName: "",
            confirmDeletedId: null,
            deletedId: null,
            isDeleteClick: false,
        })
    }

    // renderpatientTagNewEntryView = (newEntry, index) => {
    //     let tagName = null
    //     let id = null
    //     let {patientTagNewEntry} = this.state

    //     tagName = patientTagNewEntry[index] ? patientTagNewEntry[index].tag_name : ""
    //     id = patientTagNewEntry[index] && patientTagNewEntry[index].id ? patientTagNewEntry[index].id : null
    // }

    // onPressNewPatientTag = () => {

    // }
 
    render(){
        return(
            <View style={{height: screenHeight-65,}}>
                <NavigationTopHeader
                changeTabSection={this.changeTabSection.bind(this)}
                navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                    { label: "Service Configuration", value: "adminConfig" },
                    { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                    { label: "Tag Configuration", value: "tagConfig" },
                    { label: "Package Configuration", value: "packageConfig" },
                    { label: "User Configuration", value: "userConfig" },
                    { label: "Corporate Details", value: "corpConfig" },
                    { label: "QMS", value: "adminQmsConfiq" },
                    { label: "Referred Doctors", value: "labRefferedDoctors"},
                    { label: "Expense", value: "expenseconfig" },
                    { label: "Settings", value: "settingConfig" },
                    { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                    { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                    ] : [
                    { label: "Service Configuration", value: "adminConfig" },
                    { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                    { label: "Tag Configuration", value: "tagConfig" },
                    { label: "User Configuration", value: "userConfig" },
                    { label: "Corporate Details", value: "corpConfig" },
                    { label: "Referred Doctors", value: "labRefferedDoctors"},
                    { label: "Expense", value: "expenseconfig" },
                    { label: "Settings", value: "settingConfig" }
                    ]}
                selectedTab={"tagConfig"} />
                <View style={{ flex: 1, flexDirection: "row", height: screenHeight-120,}}>
                <View style={{ flex: 0.60, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                <View style={{alignItems: "flex-end", height: 60, paddingVertical: 10, width: "100%"}}>
                <TextInput 
                style={{height: 40, borderWidth: 1, borderColor: color.lightGray, borderRadius: 4, width: "30%", marginRight: 10, paddingHorizontal: 8, color: color.black}} 
                placeholder={"Search Tag..."}
                value={this.state.tagSearchKey}
                onChangeText={(text) => {
                    this.setState({
                        tagSearchKey: text
                    }, () => {
                        this.getPatientTags()
                    })
                }}
                />
                </View>
                <View style={{ height: screenHeight - 180}}>
                {this.renderTagConfigLeft()}
                </View>
                </View>
                <View style={{flex: 0.40, backgroundColor: color.themeShade, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                {this.renderTagConfigRight()}
                </View>
                </View>
                {this.state.isDeleteClick ?
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white,  width: 250, height: 150, borderRadius: 8, alignItems: "center", borderWidth: 1, }}>
                    <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 50, }}>{"Are you sure ?"}</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => {
                            var states = this.state
                            this.setState({
                                confirmDeletedId: states.deletedId
                            }, () => {
                                this.deletePatientTag()
                            })
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => { 
                            this.setState({
                                deletedId : null,
                                isDeleteClick: false,
                            })
                         }}>
                        <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                    </TouchableOpacity>
                    </View>
                    </View>
                </View>
                : null }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 14,
        color: "black",
        fontWeight: '500'
    },
});