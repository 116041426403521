import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI, CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "HistoryOfUsage"


export class HistoryOfUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofHistoryOfUsage: {},


            ageAtFirstUser: [],
            circumstancesofFirstUse: [],
            frequencyofUse: [],

            quantityofUse: [],
            historyofconsumptiontocurrentstate: [],
            timing: [],

            brand: [],
            craving: [],
            tolerance: [],

            blackouts: [],
            lastUsage: [],
            additionalNotes: [],

            hideEditIcon: false
        }
    }

    componentDidMount() {
        this.getHistoryOfUsage()
    }

    getHistoryOfUsage() {
        var service_url = Constants.PSY_PHYSICAL_HISTORY_OF_USAGE + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHistoryOfUsageSuccess,
            this.getHistoryOfUsageFailure
        );
    }

    prepareResponceValue(data, index, key, type) {

        var getPrepareData = {}
        if (data[index].value[0][key]) {
            var prepareDate = {
                type: type,
                value: data[index].value[0][key]
            }
            getPrepareData = prepareDate
        }
        return getPrepareData
    }

    getHistoryOfUsageSuccess = success => {
        if (success.status == "success") {
            if (success.data.length > 0) {
                
                this.props.updateHistoryOfUsageResponceFlag(true)
                

                var ageAtFirstUser = [];
                var circumstancesofFirstUse = [];
                var frequencyofUse = [];

                var quantityofUse = [];
                var historyofconsumptiontocurrentstate = [];
                var timing = [];

                var brand = [];
                var craving = [];
                var tolerance = [];

                var blackouts = [];
                var lastUsage = [];
                var additionalNotes = [];



                var data = success.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].substance_id == 1) {

                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "Alcohol"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "Alcohol"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "Alcohol"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "Alcohol"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "Alcohol"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "Alcohol"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "Alcohol"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "Alcohol"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "Alcohol"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "Alcohol"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "Alcohol"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "Alcohol"))

                    } else if (data[i].substance_id == 2) {
                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "Nicotine"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "Nicotine"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "Nicotine"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "Nicotine"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "Nicotine"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "Nicotine"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "Nicotine"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "Nicotine"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "Nicotine"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "Nicotine"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "Nicotine"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "Nicotine"))
                    } else if (data[i].substance_id == 3) {
                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "Smoke Cannabis"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "Smoke Cannabis"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "Smoke Cannabis"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "Smoke Cannabis"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "Smoke Cannabis"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "Smoke Cannabis"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "Smoke Cannabis"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "Smoke Cannabis"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "Smoke Cannabis"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "Smoke Cannabis"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "Smoke Cannabis"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "Smoke Cannabis"))
                    } else if (data[i].substance_id == 4) {
                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "Cocaine"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "Cocaine"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "Cocaine"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "Cocaine"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "Cocaine"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "Cocaine"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "Cocaine"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "Cocaine"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "Cocaine"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "Cocaine"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "Cocaine"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "Cocaine"))
                    } else if (data[i].substance_id == 5) {
                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "LSD"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "LSD"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "LSD"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "LSD"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "LSD"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "LSD"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "LSD"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "LSD"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "LSD"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "LSD"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "LSD"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "LSD"))
                    } else if (data[i].substance_id == 6) {
                        ageAtFirstUser.push(this.prepareResponceValue(data, i, "Age at First Use", "Intravenous Drugs"))
                        circumstancesofFirstUse.push(this.prepareResponceValue(data, i, "Circumstances of First Use", "Intravenous Drugs"))
                        frequencyofUse.push(this.prepareResponceValue(data, i, "Frequency of Use", "Intravenous Drugs"))
                        quantityofUse.push(this.prepareResponceValue(data, i, "Quantity of Use", "Intravenous Drugs"))
                        historyofconsumptiontocurrentstate.push(this.prepareResponceValue(data, i, "History of consumption to current state", "Intravenous Drugs"))
                        timing.push(this.prepareResponceValue(data, i, "Timing", "Intravenous Drugs"))
                        brand.push(this.prepareResponceValue(data, i, "Brand", "Intravenous Drugs"))
                        craving.push(this.prepareResponceValue(data, i, "Craving", "Intravenous Drugs"))
                        tolerance.push(this.prepareResponceValue(data, i, "Tolerance", "Intravenous Drugs"))
                        blackouts.push(this.prepareResponceValue(data, i, "Blackouts", "Intravenous Drugs"))
                        lastUsage.push(this.prepareResponceValue(data, i, "Last Usage", "Intravenous Drugs"))
                        additionalNotes.push(this.prepareResponceValue(data, i, "Additional Notes", "Intravenous Drugs"))
                    }

                }



                var prepareDataForEdit=[];

                for (let j = 0; j < data.length; j++) {
                    var datas  ={
                        "id": data[j].id,
                        "substance_id":data[j].substance_id,
                        "value":data[j].value
                    }       
                    prepareDataForEdit.push(datas)           
                }

                this.setState({
                    hideEditIcon:success.data.length>0?true:false,
                    ageAtFirstUser: ageAtFirstUser,
                    circumstancesofFirstUse: circumstancesofFirstUse,
                    frequencyofUse: frequencyofUse,

                    quantityofUse: quantityofUse,
                    historyofconsumptiontocurrentstate: historyofconsumptiontocurrentstate,
                    timing: timing,

                    brand: brand,
                    craving: craving,
                    tolerance: tolerance,

                    blackouts: blackouts,
                    lastUsage: lastUsage,
                    additionalNotes: additionalNotes,

                    getListofHistoryOfUsage:prepareDataForEdit
                })
            }else{
                this.props.updateHistoryOfUsageResponceFlag(false)
            }
        }
    }
    getHistoryOfUsageFailure = error => { }

    // removeEmptyObject(obj) {
    //     if (obj.length > 0) {
    //         var data = []
    //         for (let i = 0; i < obj.length; i++) {
    //             if (Object.keys(obj[i]).length > 0) {
    //                 data.push(obj[i])
    //             }
    //         }
    //         return data
    //     }
    // }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getHistoryOfUsage();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName);
        this.props.updateHistoryOfUsageResponceFlag(false)
        setTimeout(() => {
            this.props.editHistory(this.state.getListofHistoryOfUsage, pageName)
        }, 100);

    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"History Of Usage"}
                editImage={this.state.hideEditIcon ? "pencil" : ""}
            />
        )
    }

    renderNewContent( item,index) {
        console.log(item)
        return (
            <View key={index} style={{}}>

                    <View style={{flex:1,marginHorizontal:20}}>
                {
                    // data && data.length > 0 ?
                    // data.map((item,index)=>{
                        // if(item.value){
                        //     return(
                                <View key={index} style={{flexDirection:'row',marginVertical:4}}>
                                    <Text style={{flex:0.35,fontSize:12,}}>{item.type}</Text>
                                    <Text style={{flex:0.05,fontSize:12,}}>{"-"}</Text>
                                    <Text style={{flex:0.6,fontSize:12,}}>{item.value}</Text>
                                </View>
                        //     )
                        // }else{
                        //     <View key={index}/>
                        // }
                    // })
                    // : null
                }
                </View>
           </View>
        )
    }

    renderContent(heading1, heading2, leftSideData, rightSideData) {
        return (
            <View style={{}}>
                  { Object.keys(this.state.getListofHistoryOfUsage).length>0?
                    <CommonHistorySectionHeader
                        heading1={heading1}
                        heading2={heading2}
                        noOfColumn={2}
                        columnSize={[0.52, 0.48]}
                    />:null
                }
               
                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                    <View style={{flex:0.5,marginHorizontal:20}}>
                {
                    leftSideData && leftSideData.length > 0 ?
                    leftSideData.map((item,index)=>{
                        if(item.value){
                            return(
                                <View key={index} style={{flexDirection:'row',marginVertical:4}}>
                                    <Text style={{flex:0.35,fontSize:12,}}>{item.type}</Text>
                                    <Text style={{flex:0.05,fontSize:12,}}>{"-"}</Text>
                                    <Text style={{flex:0.6,fontSize:12,}}>{item.value}</Text>
                                </View>
                            )
                        }else{
                            <View key={index}/>
                        }
                    })
                    : null
                }
                </View>
                <View style={{flex:0.5,marginHorizontal:20}}>
                {
                    rightSideData && rightSideData.length > 0 ?
                    rightSideData.map((item,index)=>{
                        if(item.value){
                            return(
                                <View key={index} style={{flexDirection:'row',marginVertical:4}}>
                                    <Text style={{flex:0.35,fontSize:12, textAlign:'left'}}>{item.type}</Text>
                                    <Text style={{flex:0.05,fontSize:12,}}>{"-"}</Text>
                                    <Text style={{flex:0.6,fontSize:12,}}>{item.value}</Text>
                                </View>
                            )
                        }else{
                            <View key={index}/>
                        }
                        
                    })
                    : null
                }
                </View>

</View>
            </View>
        )
    }


    renderListOfData(item) {
        if (item.value) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }

   


    render() {
        
        var { ageAtFirstUser, circumstancesofFirstUse, frequencyofUse ,quantityofUse,historyofconsumptiontocurrentstate,timing,brand,craving,
            tolerance,blackouts,lastUsage,additionalNotes
        } = this.state;

        var getAllDatas = {
            "Age at First Use":ageAtFirstUser, "Circumstances of First Use":circumstancesofFirstUse, "Frequency of Use":frequencyofUse ,
            "Quantity of Use":quantityofUse,"History of consumption to current state":historyofconsumptiontocurrentstate,"Timing":timing,
            "Brand":brand,"Craving":craving, "Tolerance":tolerance,
            "Blackouts":blackouts,"Last Usage":lastUsage,"Additional Notes":additionalNotes
        }

        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {/* <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                {
                    Object.keys(getAllDatas).map((key)=>{
                        if(getAllDatas[key].length > 0 && Object.keys(getAllDatas[key][0]).length > 0){
                            return(
                            <View style={{marginVertical:10,flex:0.5}}>

                            <CommonHistorySectionHeader
                                heading1={key}
                                // heading2={heading2}
                                noOfColumn={1}
                                columnSize={[1]}
                            />
                            {
                                getAllDatas[key].map((item,index)=>{
                                    return this.renderNewContent(item,index)
                                })
                            }
                            </View>
                            )
                           
                        }else{
                            return <View/>
                        }
                    })
                }
                </View>  */}

                {this.renderContent("Age at First Use", "Circumstances of First Use", ageAtFirstUser, circumstancesofFirstUse)}
                {this.renderContent("Frequency of Use", "Quantity of Use", frequencyofUse, quantityofUse)}
                {this.renderContent("History of consumption to current state", "Timing", historyofconsumptiontocurrentstate, timing)}
                {this.renderContent("Brand", "Craving", brand, craving)}
                {this.renderContent("Tolerance", "Blackouts", tolerance, blackouts)}
                {this.renderContent("Last Usage", "Additional Notes", lastUsage, additionalNotes)}
                
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
