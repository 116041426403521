import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  Platform,
  Picker,
  TouchableOpacity,
  ScrollView,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity, ScrollView } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import Icon from "react-native-vector-icons/FontAwesome";
// import Success from "react-native-vector-icons/FontAwesome5";
import Style from "../../../styles/Style";
// import { TextInput } from 'react-native-paper';

// close   FontAwesome   close
// ban     FontAwesome  error
// check  FontAwesome    success
// import AsyncStorage from "../../../AsyncStorage";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'

const platform = Platform.OS;
const pageName = "complaints";

const toastConfig = {
 'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{color:"white",textAlign:"center",justifyContent:"center",marginTop:"9px"}}>Complaint Created Successfully</Text>
    </View>  
  ),
  'error': () => {},
  'info': () => {},
  'any_custom_type': () => {} 
}



export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      complaints: "",
      selectedDurationButton:{},
      selectedDuration: "1",
      comments: "",
      successOrErrorMessage:false,
        successMessageFlag:false,
        errorMessageFlag:false,
        disable:true,
        isDisable:false,

        autosuggestionValues:[],
        durationMode:"1"
    };
  }



  
  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
  }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {
          // alert(JSON.stringify(item))

          var data
          if(item.complaints == "1"){
            data ={ value: 1, label: "Right Eye (OD)" }
          }else if(item.complaints == "2"){
            data ={ value: 2, label: "Left Eye (OS)" }
          }else if(item.complaints == "3"){
            data ={ value: 3, label: "Both Eyes (OU)" }
          }else if(item.complaints == "4"){
            data = { value: 4, label: "Not Applicable (NA)" }
          }


          var selectDuration = "1";
          var selectedDurationButton = {};

          if(item.duration_type){
            if(item.duration_type === 1){
              selectDuration = '1';
              selectedDurationButton = { label: item.duration_time, value: item.duration_time };
            }else if(item.duration_type === 2){
              selectDuration = '2';
              selectedDurationButton = { label: item.duration_time, value: item.duration_time };
            }else if(item.duration_type === 3){
              selectDuration = '3';
              selectedDurationButton = { label: item.duration_time, value: item.duration_time };
            }else if(item.duration_type === 4){
              if(item.duration_time === 25){
                selectDuration = '4';
                selectedDurationButton = { label: "Chronic", value: item.duration_time };
              }else{
                selectDuration = '4';
                selectedDurationButton = { label: item.duration_time, value: item.duration_time };
              }
             
            }
          }
        
          
          // var selectedDuration = "";
          // if(parseInt(item.duration_time) < 14){
          //   selectDuration = "1";
          //   selectedDurationButton = {label:item.duration_time,value:item.duration_time};
          //   selectedDuration = item.duration_time;
          // }else if(parseInt(item.duration_time) >= 14 && parseInt(item.duration_time) <= 91 ){
          //   selectDuration = "2";
          //   var getVal = item.duration_time / 7
          //   selectedDurationButton = {label:getVal,value:getVal};
          //   selectedDuration = item.duration_time;
          // }else if(parseInt(item.duration_time) > 91 ){
          //   selectDuration = "3";
          //   var getVal = item.duration_time / 30
          //   selectedDurationButton = {label:getVal,value:getVal};
          //   selectedDuration = item.duration_time;
          // }
   


          // var data={label:item.duration_time,value:item.duration_time}

          this.setState({
            selectedDurationButton:selectedDurationButton,
            durationMode:selectDuration,
            id: item.id,
            selectedEye: data,
            complaints: item.complaint_description,
            duration_type: item.duration_type,
            selectedDuration: item.duration_time,
            comments: item.comments
          },()=>{
            // this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
  }


 

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  addComplaint = event => {

    let errorMessage = "";

    this.setState({isDisable:true})
    
    // if(this.state.disable === true){
    // this.setState({disable:false})
    // console.log(" add complaint "+JSON.stringify(this.state.selectedEye));
    
    let states = this.state;
    var service_url = Constants.OPTHAMOLOGY_COMPLAINTS_POST;

    let complaints = states.selectedEye ?  states.selectedEye.value : 4;
    // let duration = states.selectedDurationButton.value;

    let data = {
      "id": states.id ? states.id : null,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "complaints": complaints,
      "complaint_description": states.complaints,
      "duration_type": Object.keys(this.state.selectedDurationButton).length == 0 ? 1 : parseInt(states.durationMode),
      "duration_time": Object.keys(this.state.selectedDurationButton).length == 0 ? 0 : parseInt(states.selectedDurationButton.value),
      "comments": states.comments
    };

    // if(!duration) {    
    //   data["duration_type"] = null
    // } 
    // if(Object.keys(this.state.selectedDurationButton).length == 0){
    //   errorMessage = "Please select the duration";
    // }
    if(errorMessage)
    {
      this.setState({isDisable:false})
      this.props.showResposeValue("error", errorMessage);
    }else{

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }
  };

  showSuccessMessage = () => {
   
    this.setState({ successOrErrorMessage: true,successMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true,errorMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , errorMessageFlag: false });
    }, 2000);
    
  };

  getComplaintsSuccess = response => {
   // console.log('success ----->' + JSON.stringify(response))
    if (response.status === "success") {
      this.setState({
        isDisable:false
      })
      this.props.clearEditedItem(pageName,{})
      var field = this.state;
      field["complaintsData"] = response.data;
      // this.setState({ field });

      this.setState({disable:true})

      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData("complaints");
      this.props.showResposeValue("success",response.message)
    }else if(response.status === "failure"){
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error",response.message)
    }


  };

  getComplaintsFailure = error => {
    this.setState({
      isDisable:false
    })
    this.setState({disable:true})
   this.showErrorMessage();
   // console.log("opthamology complaints GET error response");
    console.log(error);
    this.props.showResposeValue("error",error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = {};
    states['complaints'] = ""; 
    states['autosuggestionValues'] = [];
    // "duration_type": 3,
    states['selectedDuration'] = "";
    states['comments'] = "";
    states["id"] = "";
    states["durationMode"]="1";
    states["selectedDurationButton"]={};

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  // calculateDays(){
    
  //   let getDurationType = parseInt(this.state.durationMode)
  //   let getSelectedDurationButton = parseInt(this.state.selectedDurationButton.value) || 0

  //   let getSelectedDuration = 0;
    
  //   getSelectedDuration = getDurationType=="1"?getSelectedDurationButton:getDurationType=="2"?7*getSelectedDurationButton:getDurationType=="3"?30*getSelectedDurationButton:0;
    
  //   this.setState({
  //     selectedDuration:getSelectedDuration
  //   })

  // }

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" ) {
      if(this.state.selectedEye  && this.state.selectedEye.value && selectedvalue.value == this.state.selectedEye.value){
        this.setState({
          selectedEye:{}
        })
      }else{
        this.setState({
          selectedEye:selectedvalue
        })
      }
    }else if(key == "selectedDurationButton"){
      this.setState({
        selectedDurationButton:selectedvalue
      })
    }
  }


  getComplaintsValues(searchKey){
    var service_url = Constants.COMPLAINT_AUTOSUGGESTION_GET+"?search_key="+searchKey;
    if(searchKey){
    OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.complaintsValuesSuccess,
        this.complaintsValuesFailure
      );
    }
}

complaintsValuesSuccess = success =>{
    if(success.status == "success"){
       // console.log("success ---------> "+JSON.stringify(success))
        this.setState({autosuggestionValues : success.data})
    }
}
complaintsValuesFailure = error =>{
    console.log("error ---------> "+JSON.stringify(error))
}


  renderComplaintsTextField(placeholder,key,value){
    return (
      <View>
        {/* {
           showLabel ? */}
           <Text style={styles.ComplaintsLabelText}>{placeholder}</Text>
           {/* :null
         } */}
      
       <View
         style={[{
          //  minHeight: 35,
           // marginTop: 10,
           marginBottom: '1.2vw',
           backgroundColor: this.state.isLocked ? color.disableComponentColor : color.white,
          //  borderRadius: 10,
           zIndex: -3
         },Style.allButtonBorderRadius]}
       >
         
         
         <TextInput
          editable={!this.state.isLocked} 
          underlineColorAndroid="transparent"
          //  placeholder={}
           style={[styles.ComplaintsTextInput, {borderColor: color.rightPanelInputBorder},Style.allButtonBorderRadius]}
           // ref={text => (this.nameInput = text)}
          //  ref={reference ? reference : null}
           // defaultValue=""
          //  autoFocus={autofocus}
           autoCapitalize="none"
           value={value}
           onChangeText={text => {
            var states =this.state;
            states[key]=text;
            this.setState({states});
            this.getComplaintsValues(text)
           }} 
         />
       </View>
       {
                    this.state.autosuggestionValues.length > 0 && this.state.complaints.length > 0?
                    <View style={{position:'absolute',width: "80%",marginTop: '5.5vw',}}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.ScrollView}>
                        {
                            this.state.autosuggestionValues.map((item,index)=>{
                                return(
                                <TouchableOpacity key={index}  onPress={()=>{
                                    // this.updateState(item, key,fieldName);
                                    var states =this.state;
                                    states[key]=item;
                                    // this.setState({states});
                                    this.setState({
                                      states,
                                        autosuggestionValues:[]
                                    })
                                }}>
                                        <Text style={{color:"white",padding:'0.3vw', fontSize: '0.9vw'}}>{item}</Text>
                                </TouchableOpacity>
                                )
                            })
                        }
                        </ScrollView>
                     </View>
                    :null
                }
       </View>
     );
      
  }

  updateTextBoxValue(text, key){
    var states = this.state;
    states[key]=text;
    this.setState({states})
  }




  render() {
    var durationButton = []
    durationButton = this.state.durationMode ==='1'? Constants.DURATION_DAY.slice(0, -1) : this.state.durationMode ==='2'? Constants.DURATION_WEEKS.slice(0, -1) : this.state.durationMode === "3" ? Constants.DURATION_MONTHS.slice(0, -1) : this.state.durationMode === "4" ? Constants.DURATION_YEAR : []

    return (
      <View>

          {/* <View style={{flex:1,zIndex:3,width:"100%",position:'absolute',alignItems:'center',justifyContent:'space-between',flexDirection:"row",backgroundColor:"#DEF2D6",borderRadius:5,borderColor:"#B8C7B2",borderWidth:1,height:70}}>
            <View style={{flexDirection:'row',alignItems:'center',marginLeft:10,flex:0.95}}>
            <Success size={25} name={"check"} color={"green"}/>
            <Text style={{fontWeight:"700",color:"green",fontSize:12,marginLeft:15}}>{"Success: "}
            <Text style={{fontWeight:"400",color:"green",fontSize:12}}>{"Profile Updated Successfully Pr00we ofi le Updated Successfully "}</Text>
            </Text> 
            </View>
            <TouchableOpacity style={{flex:0.05,marginRight:15,alignItems:'center',alignContent:'center',alignSelf:'center',justifyContent:"center"}}>
              <Icon size={25} name={"close"} color={"#B2C6AA"}/>
            </TouchableOpacity>
          </View> */}

          {/* <View style={{flex:1,zIndex:3,width:"100%",position:'absolute',alignItems:'center',justifyContent:'space-between',flexDirection:"row",backgroundColor:"#EBC8C4",borderRadius:5,borderColor:"#A58B8A",borderWidth:1,height:70}}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
            <Icon size={25} name={"ban"} color={"#D26560"} />
            <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
              <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{"Profile Updated Successfully Pr00we ofi le Updated Successfully "}</Text>
            </Text>
          </View>
            <TouchableOpacity style={{flex:0.05,marginRight:15,alignItems:'center',alignContent:'center',alignSelf:'center',justifyContent:"center"}}>
              <Icon size={25} name={"close"} color={"#C69A99"}/>
            </TouchableOpacity>
          </View> */}

        {/* {this.renderSubHeadingBorder("Complaints", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Complaints"} clearAllData={this.clearComplaint.bind(this)}/>
        <CommonSectionHeader heading={"Problem"}  />

        {/* <FlatList
          data={Constants.eye_values}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)}
        //   extraData={this.state.selectedEye}
        /> */}

        {/* {this.renderTitleWithMultipleBtn("Complaints", Constants.eye_values, 2, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true)} */}


        <View style={styles.ProblemView}>
        {
          Constants.eye_values.map((item, index) => {
            // var data
            // if(this.state.id){
            //   data = this.state.selectedEye
            // }else{
            //   data = this.state.systamicAllergySelectedItems
            // }
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  item={item}
                  disable={this.state.isLocked}
                  selectedvalue={this.state.selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
        }
        </View>

        {/* {this.renderIconBtn("plus", "New", true)} */}

         
        <CommonSectionHeader heading={"Complaint"}  />
        {/* {this.renderTextFieldView("Complaints", "complaints", this.state.complaints, false, 0,"","","",true)} */}
        {this.renderComplaintsTextField("", "complaints", this.state.complaints || "")}
        
        {/* <View>
          <ScrollView style={{maxHeight:400}}></ScrollView>
        </View> */}

        {/* <View
          style={{
            height: 60,
            marginTop: 20,
            marginBottom: 20,
            backgroundColor: color.white,
            borderRadius: 10
          }}
        >
          <TextInput
            underlineColorAndroid="transparent"
            placeholder="Complaints"
            style={{
              color: "#525252",
              height: 60,
              paddingLeft: 5,
              marginRight: 0,
              flex: 1
            }}
            ref={text => (this.nameInput = text)}
            // defaultValue=""
            autoCapitalize="none"
            value={this.state.complaints}
            onChangeText={text => this.setState({ complaints: text })}
          />
        </View> */}
        {/* <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 20, color: color.white }}>Duration</Text>
        </View> */}
        {/* <View style={{zIndex:-1}}>
        {this.renderSubHeadingBorder("Duration (Days)", false, true)}
        </View> */}
        {/* <View style={{ flexDirection: "column",zIndex:-1 }}>
          <View
            style={{
              flexDirection: "row",
              marginTop: 0,
              justifyContent: "space-between"
            }}
          >
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 1, label: "01" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 2, label: "02" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 3, label: "03" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 4, label: "04" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 5, label: "05" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
          </View>

          <View
            style={{
              zIndex:-1,
              flexDirection: "row",
              marginTop: 0,
              justifyContent: "space-between"
            }}
          >
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 6, label: "06" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 7, label: "07" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 8, label: "08" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 9, label: "09" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
            <TouchableOpacity>
              {this.renderSelectEyes({ value: 10, label: "10" }, false, false, 'selectedDuration', this.state.selectedDuration)}
            </TouchableOpacity>
          </View>
        </View> */}


        <View style={styles.ComplaintsContainer}>
          <View style={styles.DurationView}>
            <Text style={{ fontSize: '1vw', color: color.themeBlue }}>Duration</Text>
          </View>
          <View style={styles.DurationPickerView}>
            <Picker
              enabled={!this.state.isLocked}
              selectedValue={this.state.durationMode}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["durationMode"] = itemvalue;
                states["selectedDurationButton"] = {};
                this.setState({ states }); //,()=>{this.calculateDays()}
              }}
            >
              <Picker.Item label="Days" value="1" />
              <Picker.Item label="Weeks" value="2" />
              <Picker.Item label="Months" value="3" />
              <Picker.Item label="Years" value="4" />
            </Picker>
          </View>
        </View>


        <View style={{flexDirection:'row',flexWrap:'wrap',zIndex:-1,}}>
        {
          durationButton && durationButton.map((item, index) => {
            // var data
            // if(this.state.id){
            //   data = this.state.selectedEye
            // }else{
            //   data = this.state.systamicAllergySelectedItems
            // }
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedDurationButton}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedDurationButton"} />
              </View>
            )
          })
        }
        </View>

        {/* {this.renderTextFieldView("Comments")} */}
        <View style={{zIndex:-1}}>
        <CommonSectionHeader heading={"Comments"}  />  
        {this.renderTextFieldView("", "comments", this.state.comments, true, 4,"","","",true,this.state.isLocked)}
        </View>
        {/* <View
          style={{
            height: 60,
            marginTop: 20,
            backgroundColor: color.white
          }}
        >
          <TextInput
            // underlineColorAndroid="transparent"
            placeholder={"Comments"}
            style={{
              color: "#525252",
              height: 60,
              paddingLeft: 5,
              marginRight: 0,
              flex: 1,
              borderWidth: 0.5,
              borderRadius: 10,
              borderColor: color.rightPanelInputBorder
            }}
            // ref={text => (this.nameInput = text)}
            // defaultValue=""
            autoCapitalize="none"
            value={this.state.comments}
            // onChangeText={text => this.setState({ comments: text })} 
            onChange={this.updateStatebyElement.bind(this, "comments")} 
            numberOfLines={3}
            multiline={true}
          />
        </View> */}
 
        <View style={{ marginTop: '1.2vw',alignSelf:"center"}}>
          <TouchableOpacity disabled = {(this.state.isDisable || this.state.isLocked) ? true : false} onPress={this.addComplaint.bind(this)} >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View >
        <View>
        {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Complaints Created Successfully' : 
                    this.state.errorMessageFlag ?'Complaints Failed' : null}</Text>
                </View>
              ) :null} */}
        </View >
      
      </View>
    );
  }
}

const styles = StyleSheet.create({
    ProblemView: {
      flexDirection:'row',
      flexWrap:'wrap',
      marginTop:'0.6vw'
    },
    CommonBtnView: { 
      marginBottom: '0.6vw', 
      marginRight: '0.6vw' 
    },
    ComplaintsContainer: {
      flexDirection: "row", 
      marginBottom: '1.2vw', 
      justifyContent: "space-between",
      zIndex:-1,
      backgroundColor:"#E2E2E2"
    },
    DurationView: { 
      marginTop: '0.6vw',
      marginLeft:'1.2vw' 
    },
    DurationPickerView: { 
      width: "30%", 
      marginTop: '0.6vw',
      marginBottom:'0.6vw',
      marginRight:'0.6vw' 
    },
    ComplaintsLabelText: {
      fontSize:'1vw',
      marginTop:'1.2vw',
      marginBottom:'0.6vw',
      paddingLeft:'0.3vw',
      paddingRight:'0.3vw',
      color:"black"
    },
    ComplaintsTextInput: {
      color: "#525252",
    //  minHeight: 35,
     padding: '0.3vw',
     marginRight: 0,
     flex: 1,
     height:'3.3vw',
     borderWidth: 0.5,
    //  borderRadius: 10,
    fontSize:'0.9vw'
    },
    ScrollView: {
      maxHeight:'14.3vw',
      backgroundColor:'black',
      borderRadius:10,
      width: "80%",
    }

})