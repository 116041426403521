import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "EffectsOnOccupation"


export class EffectsOnOccupationNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            drinkingWhileonWorkFlag: false,
            detailsOfDrinkingWhileOnWork: "",

            absenteismFlag: false,
            detailsOfAbsenteism: "",

            lossOfJobFlag: false,
            detailsOfLossOfJob: "",

            othersFlag: false,
            detailsOfOthers: "",



        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states["drinkingWhileonWorkFlag"] = data.drinking_while_work;
                states["detailsOfDrinkingWhileOnWork"] = data.drinking_while_work_detail;

                states["absenteismFlag"] = data.absenteism;
                states["detailsOfAbsenteism"] = data.absenteism_detail;


                states["lossOfJobFlag"] = data.loss_of_job;
                states["detailsOfLossOfJob"] = data.loss_of_job_detail;

                states["othersFlag"] = data.others;
                states["detailsOfOthers"] = data.others_detail;

                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""

        states["drinkingWhileonWorkFlag"] = false;
        states["detailsOfDrinkingWhileOnWork"] = "";


        states["absenteismFlag"] = false;
        states["detailsOfAbsenteism"] = "";


        states["lossOfJobFlag"] = false;
        states["detailsOfLossOfJob"] = "";

        states["othersFlag"] = false;
        states["detailsOfOthers"] = "";

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "drinking_while_work": states.drinkingWhileonWorkFlag,
                "drinking_while_work_detail": states.detailsOfDrinkingWhileOnWork,
                "absenteism": states.absenteismFlag,
                "absenteism_detail": states.detailsOfAbsenteism,
                "loss_of_job": states.lossOfJobFlag,
                "loss_of_job_detail": states.detailsOfLossOfJob,
                "others": states.othersFlag,
                "others_detail": states.detailsOfOthers,
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_FAMILY_OCCUPATIONAL_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveEffectsOnOccupationSuccess,
                this.saveEffectsOnOccupationFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Effects on occupation already added please click on edit icon to update the values")
        }
    }

    saveEffectsOnOccupationSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveEffectsOnOccupationFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Effects on occupation"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Drinking While on work", "Enter details",
                    "drinkingWhileonWorkFlag", this.state.drinkingWhileonWorkFlag,
                    "detailsOfDrinkingWhileOnWork", this.state.detailsOfDrinkingWhileOnWork
                )}

                {this.renderHistoryComponent(
                    "Absenteeism", "Enter details",
                    "absenteismFlag", this.state.absenteismFlag,
                    "detailsOfAbsenteism", this.state.detailsOfAbsenteism
                )}
                {this.renderHistoryComponent(
                    "Loss of job", "Enter details",
                    "lossOfJobFlag", this.state.lossOfJobFlag,
                    "detailsOfLossOfJob", this.state.detailsOfLossOfJob
                )}

                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "othersFlag", this.state.othersFlag,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
