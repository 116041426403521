
const intialState = {
    dateFilterFlag : false,
    dropDownFlag : false,
    multiAxialDropDownList:[]
}

export function updateDateFilter(state =  intialState,action) {
    switch(action.type) {
        case "DATE_FILTER" :
            return {...state,dateFilterFlag:action.payload};
            default:
                return state;
    }
    

}



export function updateDropDown(state =  intialState,action) {
    switch(action.type) {
        case "DROP_DOWN" :
            return {...state,multiAxialDropDownList:action.payload};
            default:
                return state;
    }
    

}

// export default updateDropDown
// export default updateDateFilter