import React, { Component } from 'react';
import { View, Text, StyleSheet, ScrollView, TextInput, TouchableOpacity, Image } from 'react-native';
import { NavigationTopHeader } from '../BaseComponent';
import { Constants } from '../../../utils/Constants';
import {color} from '../../../styles/Color';
import OpthamologyService from '../../../network/OpthamologyService';
import { Tooltip } from 'antd';
import ImagePath from '../../../utils/ImagePaths';
export default class SurgeryNotesTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOPT: false,
            userType: this.props.userType,
            templateName: "",
            reason: "",
            patientDenies: "",
            preopDiagnosis: "",
            postopDiagnosis: "",
            procedure: "",
            surgeon: "",
            staffNurse: "",
            assistant: "",
            anesthesia: "",
            fluids: "",
            urineOutput: "",
            eBL: "",
            specimen: "",
            drains: "",
            plan: '',
            patientAdmits: "",
            TemplateList: [],
            selectedTemplate: {},
            isEditClicked: false,
            isDeleteClicked: false

        };
    }
    componentDidMount() {
        this.getSurgeryNotes()
    }
    getSurgeryNotes = () => {
        var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getSurgeryNotesTemplateSuccess,
            this.getSurgeryNotesTemplateFailure
        );
    }
    getSurgeryNotesTemplateSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                TemplateList: response.data,
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    getSurgeryNotesTemplateFailure = (error) => {
         this.props.showResposeValue("error", error.message)
    }
    getSurgeryNotesDetails = (item) => {
        var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE + "?template_id=" + item.id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getSurgeryNotesDetailSuccess,
            this.getSurgeryNotesDetailFailure
        );
    }
    getSurgeryNotesDetailSuccess = (response) => {
        if (response.status === "success") {
            var data = response.data
            this.setState({
                templateName: data.template_name,
                reason: data.reason_for_hospital_stay,
                patientDenies: data.patient_denies_having,
                preopDiagnosis: data.preop_diagnosis,
                postopDiagnosis: data.postop_diagnosis,
                procedure: data.procedure,
                surgeon: data.surgen,
                staffNurse: data.staff_name,
                assistant: data.assistent,
                anesthesia: data.type_of_anesthesia,
                fluids: data.fluids,
                urineOutput: data.urine_output,
                eBL: data.estimated_blood_loss,
                specimen: data.speciman,
                drains: data.drains,
                patientAdmits: data.patient_admit_to_having,
                plan: data.plan
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    getSurgeryNotesDetailFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }
    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "");
    }
    clearData = () => {
        var states = this.state
        states["templateName"] = "",
        states["reason"] = "",
        states["patientDenies"] = "",
        states["preopDiagnosis"] = "",
        states["postopDiagnosis"] = "",
        states["procedure"] = "",
        states["surgeon"] = "",
        states["staffNurse"] = "",
        states["assistant"] = "",
        states["anesthesia"] = "",
        states["fluids"] = "",
        states["urineOutput"] = "",
        states["eBL"] = "",
        states["specimen"] ="",
        states["drains"] = "",
        states["isEditClicked"] = false
        states["selectedTemplate"] = {},
        states['plan'] = ""
        states['patientAdmits'] = ""
        this.setState({
            states
        })
    }
    addTemplate = () => {
        let SameTemplateName = []
        var states = this.state
        SameTemplateName = states.selectedTemplate && Object.keys(states.selectedTemplate).length == 0 ? this.state.TemplateList.filter(list => list['template_name'].toLowerCase() === this.state.templateName.toLowerCase()) : []
        var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE;
        var templateName = states.templateName ? (states.templateName).trim() : ""
        var data = {
            "template_name": templateName,
            "reason_for_hospital_stay":states.reason ,
            "patient_denies_having": states.patientDenies,
            "preop_diagnosis": states.preopDiagnosis,
            "postop_diagnosis": states.postopDiagnosis,
            "procedure": states.procedure,
            "surgen": states.surgeon,
            "staff_name": states.staffNurse,
            "assistent": states.assistant,
            "type_of_anesthesia": states.anesthesia,
            "fluids": states.fluids,
            "urine_output": states.urineOutput,
            "estimated_blood_loss":states.eBL,
            "speciman": states.specimen,
            "drains": states.drains,
            "patient_admit_to_having": states.patientAdmits,
            "plan": states.plan
        }
        if(states.selectedTemplate && Object.keys(states.selectedTemplate).length > 0 ) {
            data["template_id"] = states.selectedTemplate.id
        }
        if( (this.state.templateName).length == 0) {
             this.setState({
               ErrorText: "Invalid template name"
             })
             setTimeout(() => {
                this.setState({
                    ErrorText: ""
                })
            }, 3000);
        } else if ((this.state.reason).length == 0 ) {
            this.setState({
                ErrorText: "Please enter the reason for hospital stay"
            })
            setTimeout(() => {
                this.setState({
                    ErrorText: ""
                })
            }, 3000);

        }  else if (SameTemplateName && SameTemplateName.length > 0 ) {
            this.setState({
                ErrorText: "Template Name Already Exists"
            })
            setTimeout(() => {
                this.setState({
                    ErrorText: ""
                })
            }, 3000);
        } else {
         OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.AddTemplateSuccess,
            this.AddTemplateFailure
         );
        }
    }
    AddTemplateSuccess = (response) => {
        if (response.status == "success") {
            this.props.showResposeValue("success", response.message)
            this.clearData()
            this.getSurgeryNotes()
        }
        else {
            this.props.showResposeValue("error", response.message)
        }    

    }
    AddTemplateFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }
    templateDelete = (id) => {
            var serviceUrl = Constants.IP_SURGERY_NOTES_TEMPLATE + "?id=" + id;
            OpthamologyService.getInstance().deleteComplaints(
                serviceUrl,
                {},
                this,
                this.templateDeleteSuccess,
                this.templateDeleteFailure
            )
    }
    templateDeleteSuccess = (response) => {
        if (response.status == "success") {
            this.props.showResposeValue("success", response.message)
            this.getSurgeryNotes()
            this.setState({
                isDeleteClicked: false,
                selectedTemplate: {}
            })
        }   
        else {
            this.props.showResposeValue("error", response.message)
        }
    }
    templateDeleteFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    renderDeletePopup = () => {
        let { selectedTemplate } = this.state
        return (
            <View style={styles.deleteMainContainer}>
                <View style={styles.deleteContainer}>
                    <Text style={styles.deleteText}>{"Are you sure to delete the Template "}<Text style={{ color: 'red' }}>{` ${this.state.selectedTemplate.template_name} `}</Text>?</Text>
                    <View style={styles.deleteSubContainer}>

                        <TouchableOpacity
                            style={styles.deleteButton}
                            onPress={() => {
                                this.templateDelete(selectedTemplate.id)
                            }}
                        >
                            <Text style={styles.YesBuutonText}>{"Yes"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.NoButtonText}
                            onPress={() => {
                                this.setState({
                                    isDeleteClicked: false,
                                    selectedTemplate: {}
                                })
                            }}
                        >
                            <Text style={[ styles.YesBuutonText, { color: color.red }]}>{"No"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }
    renderTextInputBox(placehold, key) {
        var states = this.state
        return (
            <View>
                <View style={{ marginTop: '1.3vw', marginLeft: '3vw', width: '70vw' }}>
                    <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', marginTop: '-0.5vw', color: key == color.black, fontSize: '0.8vw' }}>{placehold}</Text>
                    <TextInput
                        value={states[key]}
                        onChangeText={(text) => {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }}
                        maxLength={key == "templateName" ? 250 : 1000}
                        multiline={key == "templateName" ? false : true}
                        style={[styles.TextInputStyles, { height: (key == "templateName") ? "1.5vw" : '4vw', padding: "1vw" }]}
                    />
                </View>

            </View>

        );
    }
    renderTextInput = () => {
        return(
            <ScrollView style={styles.SummaryDetailsInputView}>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Reason for hospital stay *", "reason")}
            </View>
            <Text style={[styles.headerStyle]}>{"History of present illness "}</Text>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Patient admits to having", "patientAdmits")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Patient denies having", "patientDenies")}
            </View>

            <Text style={[styles.headerStyle]}>{"Procedure details "}</Text>

            <View style={styles.InputView}>
                {this.renderTextInputBox("Preop Diagnosis", "preopDiagnosis")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Postop Diagnosis", "postopDiagnosis")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Procedure", "procedure")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Surgeon", "surgeon")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Staff Nurse", "staffNurse")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Assistant(s)", "assistant")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Type of anesthesia", "anesthesia")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Fluids given", "fluids")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Urine output", "urineOutput")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Estimated Blood Loss (EBL)", "eBL")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Specimen", "specimen")}
            </View>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Drains", "drains")}
            </View>
            <Text style={[styles.headerStyle]}>{"Operative Findings"}</Text>
            <View style={styles.InputView}>
                {this.renderTextInputBox("Plan", "plan")}
            </View>
        </ScrollView>   
        )
    }
    renderBottomView = () => {
        return(
            <View>
            <View style={{ height: '1.5vw', marginTop: '0.3vw' }}>
                <Text style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '.9vw', fontWeight: '500', justifyContent: 'center' }}>
                    {this.state.ErrorText}
                </Text>
            </View>
            <View>
                <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'center' }}>
                    <TouchableOpacity
                        onPress={() => {this.addTemplate()}}
                        style={styles.BtnTouch}>
                        <Text style={styles.ButtonText}>{this.state.isEditClicked ? "Update Template" : "Add Template"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {this.clearData()}}
                        style={[styles.BtnTouch, { marginLeft: "2vw", backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1 }]}>
                        <Text style={[styles.ButtonText, { color: color.themeDark }]}>{"Clear"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
        )
    }
    renderTemplateList = () => {
        return (
            <View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                <View style={{ alignItems: "left", width: '75%' }} >
                                    <Text style={styles.TableHeadText}>Template Name</Text>
                                </View>
                                <View style={{ alignItems: "left", width: '25%' }} >
                                    <Text style={[styles.TableHeadText, { paddingLeft: '1.2vw' }]}>Actions</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={true} style={styles.MainTable}>
                        {this.state.TemplateList && this.state.TemplateList.length > 0 ? this.state.TemplateList.map((item, index) => {
                            return (
                                <View key={index} style={[styles.TableDataWrapper]} >
                                    <View style={{ width: "75%", alignItems: 'left' }}>
                                        {item.template_name.length > 45 ?
                                            <Tooltip placement="top" title={item.template_name}>
                                                <Text style={[styles.TableDataText]}> {item.template_name.slice(0, 45) + ".."}</Text>
                                            </Tooltip> :
                                            <Text style={[styles.TableDataText]}> {item.template_name}</Text>}
                                    </View>
                                    <View style={{ flexDirection: "row", width: '25%' }}>
                                        <Tooltip placement="top" title={"Edit Template"}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setState({ isEditClicked: true, selectedTemplate: item }, () => { this.getSurgeryNotesDetails(item) })
                                                }}
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    width: "fit-content"
                                                }}
                                            >
                                                <Image
                                                    source={ImagePath.Edit}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity></Tooltip>
                                        <Tooltip placement="top" title={"Delete Template"}>
                                            <TouchableOpacity
                                                onPress={
                                                    () => {
                                                        this.setState({
                                                            isDeleteClicked: true,
                                                            selectedTemplate: item
                                                        })
                                                    }
                                                }
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    marginLeft: "2vw",
                                                    width: "fit-content"
                                                }}>
                                                <Image
                                                    source={ImagePath.Delete}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity></Tooltip>
                                    </View>
                                </View>
                            )
                        }) :
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NoRecordsText}>No Records to show...</Text>
                            </View>}
                    </ScrollView>
                </View>

            </View>
        )
    }
    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.isOPT ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors" },
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                    ] : this.state.userType === Constants.ROLE_FO ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors" },
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                    ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors" },
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                    ]}
                    selectedTab={"surgeryNotesTemplate"}
                    isNavigationBorder={true} />
                <View style={styles.mainContainer}>
                    <View style={styles.labRefDoc_LeftSide}>
                        {this.renderTemplateList()}
                    </View>
                    <View style={styles.labRefDoc_RightSide} >
                    <ScrollView style={styles.RightScrollView} showsVerticalScrollIndicator={false}>
                            <View style={[styles.HightLightView, { marginTop: "0.8vw", marginBottom: 0 }]}>
                                <Text style={styles.HighLightText}>Template Name</Text>
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Template Name *", "templateName")}
                            </View>
                            <View style={styles.HightLightView}>
                                <Text style={styles.HighLightText}>Surgery Notes Details</Text>
                            </View>
                            {this.renderTextInput()}    
                        </ScrollView>
                        {this.renderBottomView()}
                    </View>

                </View>
                {this.state.isDeleteClicked ? this.renderDeletePopup() : null}
            </View>
        );
    }
}
const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: '1vw',
        color: "black",
        fontWeight: '500'
    },
    docListFontStyle: {
        fontSize: '1vw',
        color: "black"
    },
    labRefDoc_LeftSide: {
        width: '30%',
        height: '36.5vw',
        paddingVertical: '0.8vw',
        borderRadius: 4,
        margin: '0.5vw',
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        backgroundColor: color.themeShade,
        shadowOpacity: 1,
        shadowRadius: 2
    },
    labRefDoc_RightSide: {
        width: '67%',
        height: '36.5vw',
        borderRadius: 4,
        shadowColor: color.grayShade,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 2,
        margin: '0.5vw',
        zIndex: -1
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "80%",
        height: "2.5vw",
        fontSize: ".9vw",
        paddingLeft: '1vw'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '3vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    mainContainer: { flex: 1, flexDirection: "row", zIndex: -1 },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '30vw'
    },
    SummaryDetailsInputView: {
        height: '22vw',
        zIndex: -1
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw",
        fontWeight: '500'
    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        zIndex: -2
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    MainTable: {
        height: '34vw'
    },
    headerStyle: {
        fontSize: 15,
        fontWeight: "500",
        marginLeft: '2vw',
        marginTop: "1vw"
    },
    deleteMainContainer: { height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" },
    deleteContainer: { paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "25vw", height: '11vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, },
    deleteText: { fontSize: '1.17vw', fontWeight: "bold", marginBottom: '3.25vw', textAlign: 'center' },
    deleteSubContainer: { backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" },
    deleteButton: { alignItems: 'center', justifyContent: 'center', backgroundColor: color.red, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' },
    YesBuutonText: { color: 'white', fontSize: '0.78vw',fontWeight:'500' },
    NoButtonText: { alignItems: 'center', justifyContent: 'center', backgroundColor: color.white,borderColor:color.red,borderWidth:1, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }
})
