//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput,ScrollView, TouchableOpacity } from 'react-native';
// import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { color } from '../../../../styles/Color';
import { OBGYNHistorySectionHeading, CommonButton,LabelWithTextSingleRow,CommonAddButton,HistoryYesOrNoSection } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName="isMenstrualHistory"
var Messages=require('../../../../utils/InfoMessages')
// create a component
export default class MenstrualHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment:this.props.patientAppointment,
            patientInfo:this.props.patientInfo,

            checkPeriodRegular: false,
            checkBleedingOrSpotting: false,
            isPainAssociated: false,
            anyCurrentTreatment: false,

            periodsStartsEvery: "0",
            daysOfperiodsLast: "0",


            currentTreatmentCommentBox: "",
            bleedingAndSpottingCommentBox: ""
        }
    }

    checResponceIsFlag(type){
        if(type || type=="yes")
            return {label:"Yes",value:"Yes"}
        else
            return {label:"No",value:"No"}
    }

    componentWillReceiveProps(props){

        if(props && props.editHistoryItem && props.editHistoryItem.pageName == pageName ){
        if(props && props.editHistoryItem && Object.keys(props.editHistoryItem).length > 0){

            var states = this.state;
            var getMenstrualHistory = props.editHistoryItem.editItem


            // states["checkPeriodRegular"] = this.checResponceIsFlag(getMenstrualHistory.is_period_regular);

            // states["checkBleedingOrSpotting"] = this.checResponceIsFlag(getMenstrualHistory.is_bleeding_btw_periods);
            // states["anyCurrentTreatment"] = this.checResponceIsFlag(getMenstrualHistory.on_current_treatment);

            // states["isPainAssociated"] = this.checResponceIsFlag(getMenstrualHistory.pain_with_periods == 'yes' ? true : false);

            states["checkPeriodRegular"] = getMenstrualHistory.is_period_regular
            states["checkBleedingOrSpotting"] = getMenstrualHistory.is_bleeding_btw_periods
            states["anyCurrentTreatment"] = getMenstrualHistory.on_current_treatment;
            states["isPainAssociated"] = getMenstrualHistory.pain_with_periods == 'yes' ? true : false;


//             periodsStartsEvery
// daysOfperiodsLast
            states["periodsStartsEvery"] = getMenstrualHistory.period_start_duration;
            states["daysOfperiodsLast"] = getMenstrualHistory.period_last_duration;

            states["currentTreatmentCommentBox"] = getMenstrualHistory.current_treatment_desc;
            states["bleedingAndSpottingCommentBox"] = getMenstrualHistory.bleeding_btw_periods_desc;

        this.setState({states},()=>{
            this.props.editHistory({})
        })
            
        }
    }
        
    }


    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
        if (key == "checkPeriodRegular") {
            states[key] = flag;
        } else if (key == "checkBleedingOrSpotting") {
            states["bleedingAndSpottingCommentBox"] = value;
            states[key] = flag;
        } else if (key == "isPainAssociated") {
            states[key] = flag;
        } else if (key == "anyCurrentTreatment") {
            states["currentTreatmentCommentBox"] = value;
            states[key] = flag;
        }
        this.setState({ states })
    }

    checkMenstrualHistory(label, key, selectedValue,detailsValue) {
        var periods = [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ]
        return (

            
            <View style={{marginTop:8}}>
                <HistoryYesOrNoSection
                    selectedValue={selectedValue}
                    label={label}
                    detailsOfYesValue={detailsValue}
                    onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                    sectionKey={key}
                    showDetailsText={(key == "checkBleedingOrSpotting"||key == "anyCurrentTreatment")?true:false}
                />



                {/* <Text style={{ fontSize: 15, fontWeight: '500',marginTop:20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        periods.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View> */}
            </View>
        )
    }


    onPressButton(key, selectedValue) {
        if (key == "checkPeriodRegular") {
            this.setState({
                checkPeriodRegular: selectedValue
            })
        } else if (key == "checkBleedingOrSpotting") {
            this.setState({
                checkBleedingOrSpotting: selectedValue
            })
        } else if (key == "isPainAssociated") {
            this.setState({
                isPainAssociated: selectedValue
            })
        } else if (key == "anyCurrentTreatment") {
            this.setState({
                anyCurrentTreatment: selectedValue
            })
        }
    }
    onChangeTextBoxValue=(value,key)=>{
        var states =this.state;
        states[key] = value;
    
        this.setState({ states })
    }

    renderReriodsDaysTextBox(label, placeholder, textBoxKey, values) {
        return (
            <View style={{ marginTop: 20, marginBottom: 10 }}>
                {/* <Text style={{ fontSize: 15, fontWeight: '500' }}>{label}</Text>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                    <TextInput
                        value={values}
                        placeholder={placeholder}
                        maxLength={4}
                        style={{ fontSize: 12, paddingLeft: 10, backgroundColor: color.white, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 35 }}
                        onChangeText={(text) => {
                            var statets = this.state;
                            statets[key] = text
                            this.setState({ statets })
                        }}
                    />
                    <View style={{ paddingHorizontal: 20, justifyContent: 'center', height: 35, borderColor: color.rightPanelInputBorder, borderTopRightRadius: 8, borderBottomRightRadius: 8, borderWidth: 1 }}>
                        <Text>{"Days"}</Text>
                    </View>
                </View> */}
               <LabelWithTextSingleRow label={label}    
               values={values}
               textBoxKey={textBoxKey}                
               onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)}
/>
            </View>
        )
    }
    

    renderRichTextBox(key, values, placeholder) {
        return (
            <TextInput
                value={values}
                placeholder={placeholder}
                multiline={true}
                maxLength={100}
                textAlignVertical="top"
                style={{ marginTop:5,marginBottom:20,textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 65 }}
                onChangeText={(text) => {
                    var statets = this.state;
                    statets[key] = text
                    this.setState({ statets })
                }}
            />
        )
    }

    onPressAddButton(){

        var states = this.state;

        // var is_period_regular = states.checkPeriodRegular.value == "Yes" ? true : false
        // var is_bleeding_btw_periods = states.checkBleedingOrSpotting.value == "Yes" ? true : false
        // var on_current_treatment = states.anyCurrentTreatment.value == "Yes" ? true : false
        // var pain_with_periods = states.isPainAssociated.value == "Yes" ? "yes" : "no"

        var is_period_regular = states.checkPeriodRegular
        var is_bleeding_btw_periods = states.checkBleedingOrSpotting
        var on_current_treatment = states.anyCurrentTreatment
        var pain_with_periods = states.isPainAssociated ?  "yes" : "no"

        var data = {
            "patient_id":this.state.patientAppointment.patient_id,
            "appointment_id":this.state.patientAppointment.appointment_id,
            "menstrual_history": {
                "is_period_regular": is_period_regular,
                "period_start_duration": states.periodsStartsEvery,
                "period_last_duration": states.daysOfperiodsLast,
                "is_bleeding_btw_periods": is_bleeding_btw_periods,
                "bleeding_btw_periods_desc": states.bleedingAndSpottingCommentBox,
                "pain_with_periods": pain_with_periods,
                "on_current_treatment": on_current_treatment,
                "current_treatment_desc": states.currentTreatmentCommentBox,
                "is_menstural_history_frozen": false,
                "menstural_history_frozen_date":""
            },
            "additional_notes": {
            "additional_notes": ""
            }
        }

        // console.log("MENSTRUAL_HISTORY -************> "+JSON.stringify(this.state.patientAppointment.patient_id));
        // if(this.state.checkPeriodRegular){
        var service_url=Constants.MENSTRUAL_HISTORY
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveMenstrualHistorySuccess,
            this.saveMenstrualHistoryFailure
          );
        // }else {
        //     this.props.showResposeValue('error',Messages.MenstrualHistoryEmptyField )
        // }
    }

    saveMenstrualHistorySuccess = success =>{
        if(success.status == "success"){
            this.props.showResposeValue('success',success.message);
            this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        }else{
            this.props.showResposeValue('error',success.message)
        }

        
    }
    saveMenstrualHistoryFailure = error =>{
        this.props.showResposeValue('error',error.message)
    }

    clearAllValues(){
        var states = this.state;
        states["checkPeriodRegular"]={};
        states["checkBleedingOrSpotting"]={};
        states["isPainAssociated"]={};
        states["anyCurrentTreatment"]={};
        states["periodsStartsEvery"]="0";
        states["daysOfperiodsLast"]="0";
        states["currentTreatmentCommentBox"]="";
        states["bleedingAndSpottingCommentBox"]="";
        this.setState({states})
    }

    render() {
        return (
            <ScrollView>
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true}  heading={"Menstrual History"} />
                {this.checkMenstrualHistory("Are your periods regular?", "checkPeriodRegular", this.state.checkPeriodRegular)}

                {this.renderReriodsDaysTextBox("Your periods starts every", "Enter the Periods starts every", "periodsStartsEvery", this.state.periodsStartsEvery)}
                {this.renderReriodsDaysTextBox("How many days does your periods last?", "Enter the days of periods last", "daysOfperiodsLast", this.state.daysOfperiodsLast)}

                {this.checkMenstrualHistory("Does bleeding / spotting occur between periods?", "checkBleedingOrSpotting", this.state.checkBleedingOrSpotting,this.state.bleedingAndSpottingCommentBox)}
                {/* {this.renderRichTextBox("bleedingAndSpottingCommentBox", this.state.bleedingAndSpottingCommentBox,"If yes, please enter the details")} */}
                {this.checkMenstrualHistory("Is the pain associated with periods?", "isPainAssociated", this.state.isPainAssociated)}
                {this.checkMenstrualHistory("Are you on any current treatment?", "anyCurrentTreatment", this.state.anyCurrentTreatment,this.state.currentTreatmentCommentBox)}
                {/* {this.renderRichTextBox("currentTreatmentCommentBox", this.state.currentTreatmentCommentBox,"If yes, please enter the details")} */}
            
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)}/>
            
            </View>
            </ScrollView>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop:15,
        flex: 1,
    },
});

//make this component available to the app

