import React from "react";
import {
  View,
   Text,
  // FlatList,
  TextInput,
  // ScrollView,
  // Image,
  CheckBox,
  Picker,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader, RightSectionSPH, RightPanelEyeSelection } from '../BaseComponent'
// import { TextInput } from "react-native-paper";

import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "autorefractometry";
const isPageName = "isAutoRefractometry";

export default class AutoRefroctometryNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.inputSPH = React.createRef();
    this.inputCYL = React.createRef();
    this.inputAXIS = React.createRef();

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      selectedSPH: "",
      selectedCYL: "",
      selectedAXIS: "",
      comments: "",
      LEselectedSPH: '',
      LEselectedCYL:"",
      LEselectedAXIS:'',
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,

      occupation: "",
      chartUsed: "",
      muscle: "",
      Flagged: '',
      copyRightToLeft:false,
      copyLeftToRight:false,
      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],

      SPH_options: Constants.SPH_OPTIONS,
      CYL_options: Constants.CYL_OPTIONS,
      AXIS_options: Constants.AXIS_OPTIONS,
      
      isNewSPH: false,
      isNewCYL: false,
      isNewAXIS: false,
      // signs
      selectedSign: { label: "+", value: "+"},
      selectedCYLSign: { label: "+", value: "+"},
      selectedSignLE: { label: "+", value: "+"},
      selectedCYLSignLE: { label: "+", value: "+"},

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null,

      itemListLoaded: false,
      flaggedEye: "",
      selectedTab: "BeforeDilation",
      isEdit : false
    };

    this.successMesage = "";
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    })
  }

  componentWillReceiveProps(props) {

    // this.state.getAvailableEyeTypes = props.getAvailableEyeTypes;
    // this.state.getAvailableEyeTypesId = props.getAvailableEyeTypesId

    let { id, itemListLoaded } = this.state;
    if (props.editItem) {

      if (props.editItem.title === pageName) {
        
        let itemList = props.editItem.item?props.editItem.item:[];
        let comments = props.editItem.comments
        
        if (itemList.length>0) {

          let selectedTab = ""

          let { SPH_options, CYL_options, AXIS_options,LESPH_options } = this.state;

          let positiveSign = { value: "+", label: "+" };
          let negativeSign = { value: "-", label: "-" };

          let selectedEye_id = null;
          let selectedEyeLE_id = null;

          let selectedEye = {}
          let selectedSPH = {}
          let selectedCYL = {}
          let selectedAXIS = {}

          let LEselectedSPH = {}
          let LEselectedCYL = {}
          let LEselectedAXIS = {}

          let selectedSign = {...positiveSign}
          let selectedCYLSign = {...positiveSign}
          let selectedSignLE = {...positiveSign}
          let selectedCYLSignLE = {...positiveSign}

          let occupation = ""
          let chartUsed = ""
          let muscle = ""

          // if data not exist with default list, the data will add to list
          for( let i=0; i<itemList.length; i++ ) {

            // response[i]['id'] = itemList[i].id
            // response[i]['eye_type'] = itemList[i].eye_type  
            // response[i]['SPH'] = {label: itemList[i].UCVA, value: itemList[i].SPH}
            // response[i]['CYL'] = {label: itemList[i].PH, value: itemList[i].CYL}
            // response[i]['AXIS'] = {label: itemList[i].PGCL, value: itemList[i].AXIS}

            let eye_id = itemList[i].id
            let eye_type = { label: itemList[i].eye_type, value: itemList[i].eye_type }  
            let eye_SPH = { label: itemList[i].SPH, value: itemList[i].SPH }
            let eye_CYL = { label: itemList[i].CYL, value: itemList[i].CYL }
            let eye_AXIS = { label: itemList[i].AXIS, value: itemList[i].AXIS }

            // set default 
            if(itemList[i].selected == true)
            {
              selectedEye = eye_type;
            }

            if(itemList[i].is_before_dilation == true){
              selectedTab = "BeforeDilation"
            }else if(itemList[i].is_before_dilation == false){
              selectedTab = "AfterDilation"
            }

            if( itemList[i].eye_type == "RE")
            {
              selectedEye_id = eye_id
              selectedSPH = eye_SPH
              selectedCYL = eye_CYL
              selectedAXIS = eye_AXIS

              selectedSign = itemList[i].SPH?(itemList[i].SPH).charAt(0)=="-"?negativeSign:positiveSign:positiveSign,
              selectedCYLSign = itemList[i].CYL?(itemList[i].CYL).charAt(0)=="-"?negativeSign:positiveSign:positiveSign

            }
            else  if( itemList[i].eye_type == "LE")
            {
              selectedEyeLE_id = eye_id;
              LEselectedSPH = eye_SPH
              LEselectedCYL = eye_CYL
              LEselectedAXIS = eye_AXIS

              selectedSignLE = itemList[i].SPH?(itemList[i].SPH).charAt(0)=="-"?negativeSign:positiveSign:positiveSign,
              selectedCYLSignLE = itemList[i].CYL?(itemList[i].CYL).charAt(0)=="-"?negativeSign:positiveSign:positiveSign
            }
          }

          this.setState({

            itemListLoaded: true,
            selectedEye_id: selectedEye_id, 
            selectedEyeLE_id: selectedEyeLE_id,

            selectedEye: selectedEye,
            selectedSPH: selectedSPH,
            selectedCYL: selectedCYL,
            selectedAXIS: selectedAXIS,

            LEselectedSPH: LEselectedSPH,
            LEselectedCYL: LEselectedCYL,
            LEselectedAXIS: LEselectedAXIS,

            // occupation: occupation,
            // chartUsed: chart_used,
            // muscle: muscle,
            comments:comments,

            // update sign
            selectedSign: selectedSign,
            selectedCYLSign: selectedCYLSign,
            selectedSignLE: selectedSignLE,
            selectedCYLSignLE: selectedCYLSignLE,
            selectedTab : selectedTab,
            isEdit : true

          },() => {
            this.props.clearEditedItem(pageName,{})
            // this.getComments()
          });
        
        }
      }

    }
    /*
    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
        let comments = props.editItem.comments
          
          
        if (id !== item.id) {

          var eye_type = {label:item.eye_type,value:item.eye_type}
          let { SPH_options, CYL_options, AXIS_options,LESPH_options } = this.state;

          let positiveSign = { value: "+", label: "+" };
          let negativeSign = { value: "-", label: "-" };
          
          // add item if not in list 
          let SPH_list = [];
          let CYL_list = [];
          let AXIS_list = [];
          let LESPH_list = [];
          let LECYL_list = [];
          let LEAXIS_list = []
          SPH_list = this.addItemInArray(SPH_options, item.SPH);
          CYL_list = this.addItemInArray(CYL_options, item.CYL);
          AXIS_list = this.addItemInArray(AXIS_options, item.AXIS);
          LESPH_list = this.addItemInArray(LESPH_options,item.SPH)
          LECYL_list = this.addItemInArray(LESPH_options,item.CYL)
          LEAXIS_list = this.addItemInArray(LESPH_options,item.AXIS)
            this.setState({
              LEselectedSPH : item.SPH?{ value: item.SPH, label: item.SPH }:null,
              LEselectedCYL:  item.CYL?{ value: item.CYL, label: item.CYL }:null,
              LEselectedAXIS: item.AXIS,
              selectedSPH: item.SPH?{ value: item.SPH, label: item.SPH }:null,
              selectedCYL: item.CYL?{ value: item.CYL, label: item.CYL }:null,
              selectedAXIS: item.AXIS,
            })
          
          
          this.setState({
            id: item.id,
            selectedEye: eye_type,
            occupation: item.occupation,
            chartUsed: item.chart_used,
            muscle: item.muscle,
            comments:comments,
            SPH_options: JSON.parse(JSON.stringify(SPH_list)),
            CYL_options: JSON.parse(JSON.stringify(CYL_list)),
            AXIS_options: JSON.parse(JSON.stringify(AXIS_list)),
            LESPH_options: JSON.parse(JSON.stringify(LESPH_list)),
            
            LECYL_options: JSON.parse(JSON.stringify(LECYL_list)),
            LEAXIS_options : JSON.parse(JSON.stringify(LEAXIS_list)),
            // update sign
            selectedSign: item.SPH?(item.SPH).charAt(0)=="-"?negativeSign:positiveSign:positiveSign,
            selectedCYLSign: item.CYL?(item.CYL).charAt(0)=="-"?negativeSign:positiveSign:positiveSign

          }
          
          , () => {
             this.getComments()
          });
        }
      }
    }
    */
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  addComplaint (eyeType='',id){

    let errorMessage = "";
    let states = this.state;
    let { selectedEye_id, selectedEyeLE_id, selectedSPH, selectedCYL, selectedAXIS, LEselectedSPH, LEselectedCYL, LEselectedAXIS } = this.state;

    var service_url = Constants.OPTHALMOLOGY_AUTOREFROCTOMETRY;
    let data = null;
    let IsHaveRightEyeValues = false; 
    let IsHaveLeftEyeValues = false; 
    

    // For optometrist
    if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
    {
      // data[0]['doctor_id'] = this.state.selectedDoctorId;
      
    } 

    // For error message
    // if(!states.selectedSPH && !states.selectedCYL && !states.selectedAXIS && !states.comments) {

    if((this.validateObjData(selectedSPH) || this.validateObjData(selectedCYL) || this.validateObjData(selectedAXIS)) &&
     (this.validateObjData(LEselectedSPH) || this.validateObjData(LEselectedCYL) || this.validateObjData(LEselectedAXIS)))
    {
      IsHaveRightEyeValues = true; 
      IsHaveLeftEyeValues = true;
      data = [{
        "appointment_id": this.state.patientAppointment.appointment_id,
        "eye_type": 'RE',
        "SPH": states.selectedSPH?states.selectedSPH.value:"",
        "CYL": states.selectedCYL?states.selectedCYL.value:"",
        "AXIS": states.selectedAXIS?states.selectedAXIS.value:"",
        "comments": states.comments,
        "is_before_dilation" : this.state.selectedTab === "BeforeDilation" ? true : false
      },
      {
        "appointment_id": this.state.patientAppointment.appointment_id,
        "eye_type": 'LE',
         'SPH': states.LEselectedSPH? states.LEselectedSPH.value : '',
         'CYL': states.LEselectedCYL? states.LEselectedCYL.value : '',
         'AXIS' : states.LEselectedAXIS? states.LEselectedAXIS.value : '',
         "comments": states.comments,
         "is_before_dilation" : this.state.selectedTab === "BeforeDilation" ? true : false
         
      }
    ];
  
      //For edit
      if (selectedEye_id) { data[0]["id"] = selectedEye_id; }
      if (selectedEyeLE_id) { data[1]["id"] = selectedEyeLE_id; }
    }
    else if((this.validateObjData(selectedSPH) || this.validateObjData(selectedCYL) || this.validateObjData(selectedAXIS))){
      IsHaveRightEyeValues = true; 
      data = [{
        "appointment_id": this.state.patientAppointment.appointment_id,
        "eye_type": 'RE',
        "SPH": states.selectedSPH?states.selectedSPH.value:"",
        "CYL": states.selectedCYL?states.selectedCYL.value:"",
        "AXIS": states.selectedAXIS?states.selectedAXIS.value:"",
        "comments": states.comments,
        "is_before_dilation" : this.state.selectedTab === "BeforeDilation" ? true : false
      }
    ];
  
      //For edit
      if (selectedEye_id) { data[0]["id"] = selectedEye_id; }
    }
    else if((this.validateObjData(LEselectedSPH) || this.validateObjData(LEselectedCYL) || this.validateObjData(LEselectedAXIS))){
      IsHaveLeftEyeValues = true;
      data = [
      {
        "appointment_id": this.state.patientAppointment.appointment_id,
        "eye_type": 'LE',
         'SPH': states.LEselectedSPH? states.LEselectedSPH.value : '',
         'CYL': states.LEselectedCYL? states.LEselectedCYL.value : '',
         'AXIS' : states.LEselectedAXIS? states.LEselectedAXIS.value : '',
         "comments": states.comments,
         "is_before_dilation" : this.state.selectedTab === "BeforeDilation" ? true : false
         
      }
    ];
  
      //For edit
      if (selectedEyeLE_id) { data[1]["id"] = selectedEyeLE_id; }
    }
    
    if(IsHaveLeftEyeValues || IsHaveRightEyeValues){
      
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
     
    } else {
      this.props.showResposeValue("error", "Please Select Any Eye Values")
      }
  };

  getComments = () => {
    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=Auto Refractometry" + "&patient_id="+ this.state.patientAppointment.patient_id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCommentsSuccess,
      this.getCommentsFailure
    );
  };

  getCommentsSuccess = success => {
    if (success.status == "success") {
      // alert(JSON.stringify(success))
      this.setState({
        comments: success.data.comments
      })
    }
  }
  getCommentsFailure = err => { alert(JSON.stringify(err)) }



  addComments = () => {
    let states = this.state;
    // alert("shoe typed cmd"+states.comments)

    var service_url = Constants.OPTHALMOLOGT_COMMENTS;
    var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "section": "Auto Refractometry",
      "comments": states.comments
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addCommentsSuccess,
      this.addCommentsFailure
    );
  };

  addCommentsSuccess = success => { 
     
      // update parent
      this.props.refreshData(pageName);
      this.props.showResposeValue("success", this.successMesage || success.message); 

      // clear data
      this.clearComplaint();    
  }

  addCommentsFailure = error => { }


  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getComplaintsSuccess = response => {

    if (response.status === "success") {
      
      this.successMesage = response.message;
      this.addComments()
         
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getComplaintsFailure = error => {
    
    this.props.showResposeValue("error", error.message)

  };

  clearComplaint = (Label, event) => {

    // reload data
    this.setState({
      selectedEye: {},
      selectedSPH: "",
      selectedCYL: "",
      selectedAXIS: "",
      comments: "",
      LEselectedSPH: '',
      LEselectedCYL:"",
      LEselectedAXIS:'',
      copyRightToLeft:false,
      copyLeftToRight:false,    
      itemListLoaded:false,
      selectedSign: { label: "+", value: "+"},
      selectedCYLSign: { label: "+", value: "+"},
      selectedEye_id: undefined, 
      selectedEyeLE_id: undefined,
      selectedTab : Label ? Label : "BeforeDilation",
      isEdit : false
    },()=>{
      this.props.clearEditedItem(pageName,{})
      this.props.refreshData(pageName);
    })
  }

  renderDropDown(state, values, text) {
    // var getDefaultValues=Constants.DEFAULT_OCCUPATIONS_SR
    var getDefaultValues = values;
    var states = this.state

    return (
      <View style={{ width: "90%" }}>
        <Picker
          enabled={!this.state.isLocked}
          selectedValue={states[state]}
          style={styles.DropDownView}
          // itemStyle={{fontSize:10}}
          onValueChange={(itemvalue) => {
            states[state] = itemvalue
            this.setState({
              states
            })
          }}
        >
          <Picker.Item key={-1} label={text} value={""} />
          {getDefaultValues.map((i, index) => (
            <Picker.Item key={index} label={i.value} value={i.value} />
          ))}
        </Picker>
      </View>
    )
  }

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" ) {
       
        this.setState({
          selectedEye:selectedvalue,
          
        })
      this.props.clearEditedItem(pageName,{})
    } else{
      
      if(this.state.selectedEye.value != undefined){
      let states = this.state;
      states[key] = selectedvalue;
      
      this.setState({ states });
      this.props.clearEditedItem(pageName,{})
    }
    else {
      this.props.showResposeValue("error", "Select eye type first")
    }
  }}

  triggerNewItemAdd = (key, refInput) => {

    let states = this.state;

    // clear previous selected data
    states[key] = true;

    this.setState({ states });

    refInput.current.focus();
  }

  changeTab = (tab) => {
    this.setState({
      selectedTab: tab,
    })
  }

  render() {
    const {selectedTab} = this.state;
    return (
      <View>
        {/* {this.renderSubHeadingBorder("Auto Refractometry", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Auto Refractometry"} clearAllData={this.clearComplaint.bind(this)}/>
        <View style={styles.MedicationView}>
        <TouchableOpacity 
        style={{flex: 0.35}}
        onPress={() => {
          this.state.isEdit ? this.clearComplaint("BeforeDilation") :this.changeTab("BeforeDilation")
        }}
        >
          <Text style={{color: selectedTab === "BeforeDilation" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }}>Before Dilation</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        style={{flex: 0.35}}
        onPress={() => {
          this.state.isEdit ? this.clearComplaint("AfterDilation") : this.changeTab("AfterDilation")
        }}
        >
          <Text style={{color: selectedTab === "AfterDilation" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }} >After Dilation</Text>
        </TouchableOpacity>
        </View>
        <View style={{}}>
          <CommonSectionHeader heading={"Eye Type"}  />
          <View style={{flex:0.5,flexDirection:'row'}}>
            {this.renderSubHeadingBorder("", false, true)}
          </View>

          <RightPanelEyeSelection 
            // getAvailableEyeTypes={this.props.getAvailableEyeTypes} 
            // getAvailableEyeTypesId={this.props.getAvailableEyeTypesId} 
            id={this.state.id} 
            isLocked={this.state.isLocked} 
            selectedEye={this.state.selectedEye} 
            onPressButton={this.onPressButton.bind(this)} />

        </View>
        

        <View style={{ height: '1vw' }}></View>

        <RightSectionSPH heading={"SPH"} 
          stateSignKey={ this.state.selectedEye.value == 'LE' ?"selectedSignLE":"selectedSign" } 
          selectedSign={ this.state.selectedEye.value == 'LE' ?this.state.selectedSignLE:this.state.selectedSign} 
          disable={this.state.isLocked} 
          stateOptionKey={ this.state.selectedEye.value == 'LE' ?"LEselectedSPH" : 'selectedSPH'} 
          options={ this.state.SPH_options} 
          selectedvalue={ this.state.selectedEye.value == 'LE'? this.state.LEselectedSPH :this.state.selectedSPH } 
          onPressButton={this.onPressButton.bind(this)} />
         
        <View style={{ height: '1vw' }}></View>

        <RightSectionSPH heading={'CYL'} 
          stateSignKey={ this.state.selectedEye.value == 'LE' ?"selectedCYLSignLE":"selectedCYLSign" } 
          selectedSign={ this.state.selectedEye.value == 'LE' ?this.state.selectedCYLSignLE:this.state.selectedCYLSign } 
          disable={this.state.isLocked} 
          stateOptionKey={this.state.selectedEye.value == 'LE' ? 'LEselectedCYL':"selectedCYL"} 
          options={ this.state.CYL_options } 
          selectedvalue={ this.state.selectedEye.value == 'LE'? this.state.LEselectedCYL : this.state.selectedCYL} 
          onPressButton={this.onPressButton.bind(this)} />
                
    
         {/* Axis Rendering */}
        <View style={{ height: '1vw' }}></View>

        <CommonSectionHeader heading={"AXIS"}  />      
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.AXIS_options && this.state.AXIS_options.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedEye.value == 'LE' ?  this.state.LEselectedAXIS:this.state.selectedAXIS}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={this.state.selectedEye.value == 'LE' ? 'LEselectedAXIS':'selectedAXIS'} />
              </View>
            )
          })
        }
        </View>


        {/* {this.renderTitleWithMultipleBtn("AXIS",  this.state.AXIS_options, 7, false, 
        this.state.selectedEye.value == 'LE' ? 'LEselectedAXIS':'selectedAXIS', this.state.selectedEye.value == 'LE' ?  this.state.LEselectedAXIS:this.state.selectedAXIS, this.updateStateAxis.bind(this), false, true,"","",false,this.state.isLocked)} */}

        {/* new block 
        {this.state.isNewAXIS ?
          <View style={{ marginTop: 10 }}> 
          {this.renderTextFieldViewDrug('AXIS Value', 'newAXIS', this.state.newAXIS, false, 1, this.inputAXIS,
            { flag: 'isNewAXIS', newState: 'newAXIS', forState: 'selectedAXIS', stateList: 'AXIS_options' })}
          </View>: null
        }
        {/* <TouchableOpacity onPress={() => { this.triggerNewItemAdd('isNewAXIS', this.inputAXIS) }} style={{width:110,marginBottom:10}}>
            {this.renderIconBtn("plus", "New", false, "key")}
        </TouchableOpacity> */}
        {/* new block end */}
        
     
        <CommonSectionHeader heading={"Comments"}  />
     
        <TextInput
        editable={!this.state.isLocked}
        multiline={true}
        style={[styles.CommentsTextInput, {
            backgroundColor:this.state.isLocked ? color.disableComponentColor : color.white,
            borderColor: color.rightPanelInputBorder
        }]}
        placeholder={"Comments"}
        value={this.state.comments}
        onChangeText={(text)=>{

          if(text.length<300)
          {
            this.setState({
              comments:text
            })
          }
        }}
        />

       {/* CheckBox */}

        {
          this.state.selectedEye.value == "RE" ?
          this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
          : this.state.selectedEye.value == "LE" ?
          this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
          :null
        }

        <View style={{ marginTop: '1.2vw',alignSelf:"center" }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={()=>this.addComplaint(this.state.selectedEye.value,this.state.id)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
        <View>
          {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Auto Refractometry Created SuccessFully' : 
                    this.state.errorMessageFlag ?'Auto Refractometry Failed' : null}</Text>
                </View>
              ) :null} */}
        </View >
      </View>
    );
  }

  renderCheckBox=(label, value, key)=>{
    return (
        <View style={{ flexDirection: 'row', alignSelf:'flex-end', marginTop: '0.6vw' }}>
            <CheckBox value={value}
                style={{marginRight: '0.8vw', width: '1.2vw', height: '1.2vw'}}
                onValueChange={() => {

                  var states =this.state;

                  if( key == "copyRightToLeft" && !value)
                  {
                    states["copyLeftToRight"] = false
                  }
                  if( key == "copyLeftToRight" && !value)
                  {
                    states["copyRightToLeft"] = false
                  }

                  states[key]=!value

                  this.setState({
                    states
                  },() => {

                    console.log(" value ", value)

                      if(!value)
                      {
                        this.copyData()
                      }else{
                        this.clearData( key == "copyRightToLeft"?"LE":"RE")
                      }
                  })
                }}

            />
            <Text style={{ fontSize: '0.8vw', color: "#888888" }}>{label}</Text>
        </View>
    );
  }

  validateObjData=(data)=>{

    return data && (typeof data === "object") && (Object.keys(data).length>0) && (data.value=="0" || data.value )?true:false
  }

  copyData=()=>{
    
    let { selectedEye, selectedSPH, selectedCYL, selectedAXIS, LEselectedSPH, LEselectedCYL, LEselectedAXIS } = this.state;
    console.log(" selectedEye.value ", selectedEye.value)
    if( selectedEye && selectedEye.value=="RE" ) 
    {
      LEselectedSPH = { ...selectedSPH }
      LEselectedCYL = { ...selectedCYL }
      LEselectedAXIS = { ...selectedAXIS }

      this.setState({ 
        LEselectedSPH: LEselectedSPH, LEselectedCYL:LEselectedCYL, LEselectedAXIS:LEselectedAXIS
      }, ()=>{
        console.log(" states ", this.state)
      })
    }
    else if( selectedEye && selectedEye.value=="LE" ) {
      selectedSPH = { ...LEselectedSPH }
      selectedCYL = { ...LEselectedCYL }
      selectedAXIS = { ...LEselectedAXIS }

      this.setState({
        selectedSPH:selectedSPH, selectedCYL:selectedCYL, selectedAXIS:selectedAXIS
      })
    }

  }
  clearData(ClearableEyeType){
    if(ClearableEyeType=="RE"){
      this.setState({ 
        selectedSPH: "", 
        selectedCYL:"", 
        selectedAXIS:""
      })
    }
    else if(ClearableEyeType=="LE"){
      this.setState({ 
        LEselectedSPH: "", 
        LEselectedCYL:"", 
        LEselectedAXIS:""
      })
    }
  }

  updateStateAxis = (key, value, keyInObject) => {
 
     let states = this.state;
 
     if (keyInObject) {
 
       states[keyInObject][key] = value;
     }
  }

}

const styles = StyleSheet.create({
      DropDownView: { 
        height: '2vw', 
        borderRadius: '1.2vw', 
        backgroundColor: 'white', 
        fontSize: '0.8vw' 
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      CommentsTextInput: { 
      height:'5.6w',
      color: "#525252",
      minHeight: '5.6vw',
      padding: '0.5vw',
      marginTop:'0.6vw',
      marginRight: 0,
      flex: 1,
      borderWidth: 0.5,
      borderRadius: 10
    },
    MedicationView: {
      flexDirection: "row", 
      flex: 1, 
      marginBottom: '1vw', 
      marginTop: '0.3vw'
    },
})
