import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, TextInput, ScrollView, Image } from "react-native";
import OpthamologyService from "../../../../../network/OpthamologyService";
import { color } from "../../../../../styles/Color";
import { Constants } from "../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader, NavigationTopHeader } from "../../../BaseComponent";
import IOLIndex from "./IOL/IOLIndex";
import {DrugIndex} from "./Drug/DrugIndex";
import AsyncStorage from "../../../../../AsyncStorage";
const screenHeight = Dimensions.get("window").height;
// const navigationHeaderList = [
//     { label: "Intraocular lens (IOL)", value: "iol" },
//     { label: "OT Drug", value: "otdrug" },
//   ]

export default class InventoryIndex extends Component {
constructor(props) {
    super(props);

    this.state = {
        selectedTab: "",
        is_OPT : ""
    }
}

changeTabSection(data) {

    this.setState({
        selectedTab: data
    })
}
changeScreen = (data) =>{
  this.props.changeScreen(data)
}
showResposeValue = (title, message) => {
    this.props.showResposeValue(title, message)
}

async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        is_OPT : loggedInDataObj.is_OPT
    },() => {
        this.setState({
            selectedTab : this.state.is_OPT ? "iol" :"otdrug"
        })
    })
}

render(){
    let navigationHeaderList = []
    this.state.is_OPT ? 
     navigationHeaderList = [
        { label: "Intraocular lens (IOL)", value: "iol" },
        { label: "OT Drug", value: "otdrug" },
      ] : 
      navigationHeaderList = [
        { label: "OT Drug", value: "otdrug" },
      ]
    return(
        <View style={{height: screenHeight-65,}}>
            <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={navigationHeaderList}
            selectedTab={this.state.selectedTab} 
            isNavigationBorder = {true}
            />
        <View style={{height: screenHeight-120}}>
        {this.state.selectedTab === "iol" ? 
        <IOLIndex 
        showResposeValue={this.showResposeValue.bind(this)}
        />
        : this.state.selectedTab === "otdrug" ?
          <DrugIndex
           showResposeValue={this.showResposeValue.bind(this)}
           changeScreen = {this.changeScreen.bind(this)}
           selectedTab = {this.state.selectedTab}
          />
        :null
        }
        </View>
 
        </View>
    )
}
}
