import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";

import { StyleSheet } from "react-native";
import { TouchableOpacity, Text, View } from "react-native";
import AppointmentType from "./AppointmentType/AppointmentType";
import DocPayments from "./DoctorPayments/DocPayments";
import './Index.css'
import PatientType from "./PatientType/PatientType";
import TotalPatients from "./TotalPatients/TotalPatients";
import TotalRevenue from "./TotalRevenue/TotalRevenue";
import CommonDateFilter from '../../BaseComponent';
import moment from "moment";
import { ScrollView } from "react-native";
import DocBasePatientVisit from "./DoctorBasedPatientVisit/DocBasePatientVisit";
import LocationAnalysis from "./LocationAnalysis/LocationAnalysis";
import PaymentType from "./PaymentType/PaymentType";
import SpecializationVisit from "./SpecializationVisit/SpecializationVisit";
import RefByDoctor from "./ReferredByDoctor/RefByDoctor";
import ReferalSource from './ReferalSource/ReferalSource';
import TotalBills from "./TotalBills/TotalBills";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */
export default class ResponsiveLocalStorageLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");

        this.state = {
            layouts: JSON.parse(JSON.stringify(getFromLS("layouts") || {})),
            filterDate: {},
            filterData: {
                fromDate: converted_date,
                toDate: converted_date
            },
        };
    }

    static get defaultProps() {
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 2, xs: 2, xxs: 2 },
            rowHeight: 30
        };
    }
    response = (response) => {
        this.props.showResposeValue("error", response)
    }

    resetLayout = () => {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterData } = this.state;
        states["filterData"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate
        this.setState({ states, filterData })
    }

    render() {
        return (
            <View >
                <View style={styles.MainView}>
                    <View>
                        <TouchableOpacity style={styles.ResetButton} onPress={this.resetLayout}>
                            <Text style={styles.ButtonText}>Reset Layout</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.DatePicker}>
                        <CommonDateFilter
                            defaultSelectedDateFilter={"Today"}
                            startDate={this.state.filterDate.fromDate}
                            endDate={this.state.filterDate.toDate}
                            clearPress={this.state.isclear}
                            showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                            filterDate={this.state.filterDate}
                            futureDateDisable={true} />
                    </View>
                </View>

                <ScrollView showsHorizontalScrollIndicator={false} style={styles.MainLayout}>
                    <ResponsiveReactGridLayout
                        className="layout"
                        cols={{ lg: 12, md: 10, sm: 2, xs: 2, xxs: 2 }}
                        rowHeight={30}
                        layouts={this.state.layouts}
                        onLayoutChange={(layout, layouts) =>
                            this.onLayoutChange(layout, layouts)
                        }
                    >
                        <div key="1" data-grid={{ w: 3, h: 20, x: 0, y: 0, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <PatientType Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="2" data-grid={{ w: 5, h: 10, x: 3, y: 0, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <TotalPatients Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="3" data-grid={{ w: 4, h: 10, x: 8, y: 0, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <TotalRevenue Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="4" data-grid={{ w: 4, h: 10, x: 3, y: 10, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <DocPayments Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="5" data-grid={{ w: 5, h: 10, x: 7, y: 10, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <DocBasePatientVisit Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="6" data-grid={{ w: 3, h: 10, x: 0, y: 20, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <LocationAnalysis Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="7" data-grid={{ w: 4, h: 10, x: 3, y: 20, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <PaymentType Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="8" data-grid={{ w: 5, h: 10, x: 7, y: 20, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <SpecializationVisit Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="9" data-grid={{ w: 3, h: 10, x: 0, y: 30, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <ReferalSource Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="10" data-grid={{ w: 4, h: 10, x: 3, y: 30, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <RefByDoctor Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="11" data-grid={{ w: 5, h: 10, x: 7, y: 30, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <AppointmentType Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                        <div key="12" data-grid={{ w: 5, h: 10, x: 0, y: 40, minW: 2, minH: 3 }} id='ChartsDiv'>
                            <TotalBills Response={this.response.bind(this)} FilterDate={this.state.filterData} />
                        </div>
                    </ResponsiveReactGridLayout>
                </ScrollView>
            </View>
        );
    }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("AdminDashboard_Layout")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            "AdminDashboard_Layout",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const styles = StyleSheet.create({
    MainView: {
        height: '4vw',
        width: "100%",
        boxShadow: "0px 3px 6px #00000029",
        borderBottomEndRadius: "15vw",
        borderBottomLeftRadius: "15vw",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ResetButton: {
        marginLeft: '3vw',
        backgroundColor: 'rgb(4, 183, 177)',
        borderRadius: '0.25vw',
        marginTop: '1vw',
        padding: '0.5vw',
        width: '8vw',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    ButtonText: {
        fontSize: '0.91vw',
        color: 'white'
    },
    DatePicker: {
        marginRight: '3vw',
        marginTop: '0.5vw'
    },
    MainLayout: {
        height: '80vh',
        overflow: 'scroll',
        zIndex: -10
    }
})