import React,{Component} from 'react';
import {View,Text,StyleSheet,FlatList} from 'react-native';
import {OBGYNHistorySectionHeading,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "pastHistory"


export class PastHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofPastHistory: {},

            hideEditIcon:false,
            isheading:false
        }
    }

    componentDidMount() {
        this.getPastHistory()
    }

    getPastHistory(){
        var service_url = Constants.PSY_PAST_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id+
        "&appointment_id="+this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPastHistorySuccess,
            this.getPastHistoryFailure
        );
    }

    getPastHistorySuccess = success =>{
        if(success.status == "success"){
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "psychiatic_problems" ) {
                        prepareData = {
                            key: "Psychiatry Problems",
                            value: data[key] ? "Yes" : "No",
                            details: data.psychiatic_details
                        }
                    }else if (key == "neurotic_behavior") {
                        prepareData = {
                            key: "Neurotic traits/odd/deviant behaviours",
                            value: data[key] ? "Yes" : "No",
                            details: data.neurotic_details
                        }
                    }
                    booleanValues.push(prepareData)
                }else if (key !== "neurotic_details" || key !== "psychiatic_details") {
                    var prepareData={};
                    if (key == "treatment_history") {
                        prepareData = {
                            key: "Treatment History",
                            value: data[key],
                        }
                    }else if(key == "other_details"){
                        prepareData = {
                            key: "Others",
                            value: data[key],
                        }
                    }
                    stringVaues.push(prepareData)
                }else{

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues,...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofPastHistory: data,
                listOfDatas: removeEmptyObj
            },()=>{
                this.selectedRightSideView(pageName)
            })
        }
    }
    getPastHistoryFailure = error =>{}

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getPastHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name){
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofPastHistory, pageName)
        }, 100);
        
    }

    renderHeader(){
        return(
            <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Past History"}
                    editImage={this.state.hideEditIcon || Object.keys(this.state.getListofPastHistory).length == 0   ? "" :"pencil"}
                />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if(item.value ){
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        }else{
            return (<View></View>)
        }
        
    }
    

    render(){
        return(
            <View style={styles.container}>
            {this.renderHeader()}

            {this.renderContent()}
        </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
