import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet } from "react-native";
import { DatePicker, Tooltip } from 'antd';
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import Pagination from '../../../doctor/common/Pagination';
import DropDownIcon from '../../../../../assets/images/BillConfig/Down_Arrow.svg';
import ImagePath from '../../../../utils/ImagePaths';

const { RangePicker } = DatePicker
export default class EmployerBilling extends Component {

    constructor(props) {
        super(props);


        this.state = {
            corpCompanyList: [],
            corpCompanyListAfterPagination: [],
            BillingSplitUps: [],
            AllBillingSplitUps: [],
            ErrorText: "",
            SelectedItem: {},
            SearchCompanyKey: "",
            SelectedIndex: "",
            isRestClicked: false,
            SelectedId: "",

            RegisterName: "",
            igst: "",
            cgst: "",
            sgst: "",
            description: "",
            value: "",
            date: {},
            dateValue: [],
            BillDateSort: "desc",

            CompletebillingDate: moment(new Date()).format("YYYY-MM-DD"),
            CompletePaymentDate: moment(new Date()).format("YYYY-MM-DD"),
            BillMadeBy: "",
            SelectedCompleteBill: {},
            SelectedCompleteBillPayment: 1,
            SplitBillsType: 'patient',
            TempDate: {},
            CorpPaidAmount: 0,
            CorpPendingAmount: 0,
            CorpTotalAmount: 0,
        };

    }

    componentDidMount() {
        this.getCorporateList()
    }

    getCorporateList = () => {
        var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION + "?search_key=" + this.state.SearchCompanyKey + "&d_sort=" + this.state.BillDateSort;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getCorporateListSuccess,
            this.getCorporateListFailure
        );

    }

    getCorporateListSuccess = (success) => {
        this.setState({
            corpCompanyList: success.data.emp_details,
            CorpPaidAmount: success.data.card_values.total_paid ? success.data.card_values.total_paid.toFixed() : 0,
            CorpPendingAmount: success.data.card_values.total_pending ? success.data.card_values.total_pending.toFixed() : 0,
            CorpTotalAmount: success.data.card_values.total ? success.data.card_values.total.toFixed() : 0,
        })
    }
    getCorporateListFailure = (error) => {
        this.props.ErrorAlt(error.message)
    }


    numberValidationWithDot(num) {
        var rgx = /^(\d{0,3})((\.(\d{0,2})?)?)$/;
        return num.match(rgx);
    }
    AmountvalueValidation(num) {
        var rgx = /^(\d{0,7})$/;
        return num.match(rgx);
    }
    gstValidate(num) {
        var rgx = /^(([a-z\d]+\s)?[a-z\d]+)?$/i;
        return num.match(rgx);
    }



    renderTextBox = (key) => {
        var states = this.state
        return (
            <View style={styles.renderTextBoxContainer}>
                <TextInput
                    placeholder={key == 'igst' ? "IGST" : key == 'cgst' ? "CGST" : key == 'sgst' ? "SGST" : ""}
                    editable={(key == "RegisterName" ? false : true)}
                    multiline={(key == "description") ? true : false}
                    maxLength={(key == "description") ? 100 : null}        
                    value={states[key]}
                    style={[styles.TextInputStyles, { height: (key == "description") ? "6vw" : '1.5vw', padding: (key == "description") ? "0" : "1vw", backgroundColor: (key == "RegisterName" ? "#F9F9F9" : "") }]}
                    onChangeText={(text) => {
                        if ((key == "gstin")) {
                            var isValid = this.gstValidate(text)
                            if (isValid || text === "") {
                                states[key] = text.toUpperCase()
                                this.setState({ states })
                            }
                        }
                        else if ((key == "igst")) {
                            var isValid = this.numberValidationWithDot(text)

                            if ((isValid || text === "") && (parseFloat(text) <= 100 || text === "")) {
                                states[key] = text
                                text != "" ? states["cgst"] = parseFloat(text) / 2 : states["cgst"] = ""
                                text != "" ? states["sgst"] = parseFloat(text) / 2 : states["sgst"] = ""
                                this.setState({ states })
                            }
                        }
                        else if ((key == "cgst") || (key == "sgst")) {
                            var isValid = this.numberValidationWithDot(text)

                            if ((isValid || text === "") && (parseFloat(text) <= 50 || text === "")) {
                                states[key] = text
                                if ((key == "cgst")) {
                                    states["sgst"] = text
                                    states["igst"] = Number.isNaN((parseFloat(states["cgst"])) * 2) ? "" : (parseFloat(states["cgst"])) * 2
                                } else {
                                    states["cgst"] = text
                                    states["igst"] = Number.isNaN((parseFloat(states["sgst"])) * 2) ? "" : (parseFloat(states["sgst"])) * 2
                                }
                                this.setState({ states })
                            }
                        }
                        else if ((key == "value")) {
                            var isValid = this.AmountvalueValidation(text)
                            if (isValid || text === "") {
                                states[key] = text
                                this.setState({ states })
                            }
                        }
                        else {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </View>
        )
    }

    renderPaymentBtn = (img, title, id) => {
        return (
            <TouchableOpacity
                onPress={() => {
                    this.setState({
                        SelectedCompleteBillPayment: id
                    })
                }}
            >
                <View style={styles.PaymentButtonView}>
                    <Text style={[{ color: id === this.state.SelectedCompleteBillPayment ? color.themeDark : color.notesTableTitle }, styles.PaymentButtonText]}>
                        {title}
                    </Text>
                    <Image
                        source={img}
                        style={[styles.paymentIconImg, { tintColor: id === this.state.SelectedCompleteBillPayment ? color.themeDark : color.notesTableTitle }]}
                        resizeMode="contain"
                    />

                </View>
            </TouchableOpacity>
        );
    };

    AddBilling = () => {
        var states = this.state
        if (
            states.RegisterName != "" &&
            (states.date.fromDate != undefined) &&
            (states.date.fromDate != 'Invalid date') &&
            (states.date.toDate != undefined) &&
            (states.date.toDate != 'Invalid date') &&
            states.value != "" &&
            states.description != ""
        ) {
            // if (parseInt(states.igst) > 100 || parseInt(states.cgst) > 100 || parseInt(states.sgst) > 100) {
            //     this.setState({
            //         ErrorText: "Invalid GST % Value - (Must Less Than 100)"
            //     })
            // }

            // else {
            this.setState({
                ErrorText: ""
            })

            var service_url = Constants.CORPORATE_BILLING_POST;
            var data = {
                invoice_from_date: states.date.fromDate,
                invoice_to_date: states.date.toDate,
                igst_percent: isNaN(parseFloat(states.igst)) ? 0 : parseFloat(states.igst),
                sgst_percent: isNaN(parseFloat(states.sgst)) ? 0 : parseFloat(states.sgst),
                cgst_percent: isNaN(parseFloat(states.cgst)) ? 0 : parseFloat(states.cgst),
                employer_id: states.SelectedId,
                amount: parseFloat(states.value),
                description: states.description
            }

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.PostBillingSuccess,
                this.PostBillingFailure
            );
            // }

        }
        else {
            this.setState({
                ErrorText: "Enter All Mandatory * Fields"
            })
            setTimeout(()=>this.setState({ErrorText:""}), 2500)
        }

    }

    PostBillingSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                corpCompanyList: []
            }, () => {
                this.getCorporateList()
                this.resetClicked()
                this.props.SuccessAlt(response.message)
            })

        }
        else {
            this.props.ErrorAlert(response.message)
        }
    }
    PostBillingFailure = (response) => {
        this.props.ErrorAlert(response.message)
    }

    CreateBillClicked = (item) => {
        this.setState({
            ErrorText: "",
            RegisterName: item.company_name,
            SelectedId: item.id
        })
    }

    resetClicked = () => {
        this.setState({
            SelectedItem: {},
            RegisterName: "",
            igst: "",
            cgst: "",
            sgst: "",
            description: "",
            value: "",
            date: {},
            ErrorText: "",
            dateValue: [],
            SelectedId: '',
            SelectedIndex: "",
        })
    }

    printBill = (invoice_number, emp_id) => {
        var serviceUrl = Constants.CORPORATE_BILLING_POST + "?employer_id=" + emp_id + "&invoice_number=" + invoice_number + "&export=pdf";
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    }

    getPrintSuccess = (response) => {
        if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    };


    getPrintFailure = error => {
        this.props.ErrorAlert("Something went wrong !")
    };

    CompleteBillPopup = () => {
        return (
            <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "flex-start", alignItems: "center" }}>
                <View style={{ paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "52vw", height: '29vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, marginTop: '2vw' }}>
                    <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '1vw', textAlign: 'center' }}>{"Complete Bill"}</Text>

                    <View style={{ display: 'flex', flexDirection: "row" }}>
                        <View style={{ marginTop: "2vw" }}>
                            <Text style={styles.TextBoxLableStyle}>{"Company Name"}</Text>
                            <TextInput
                                editable={false}
                                value={this.state.SelectedCompleteBill.company_name}
                                style={styles.LableTextBox}
                            />
                        </View>
                        <View style={{ marginTop: "2vw", marginLeft: '2vw' }}>
                            <Text style={styles.TextBoxLableStyle}>{"Billing Period"}</Text>
                            <TextInput
                                editable={false}
                                value={`${this.state.SelectedCompleteBill.invoice_from_date} - ${this.state.SelectedCompleteBill.invoice_to_date}`}
                                style={styles.LableTextBox}
                            />
                        </View>
                        <View style={{ marginTop: "2vw", marginLeft: '2vw' }}>
                            <Text style={styles.TextBoxLableStyle}>{"Billing Value"}</Text>
                            <TextInput
                                editable={false}
                                value={this.state.SelectedCompleteBill.amount}
                                style={styles.LableTextBox}
                            />
                        </View>
                    </View>
                    <View style={{ marginTop: "2vw" ,width:'88%' }}>
                        <Text style={styles.TextBoxLableStyle}>{"Description"}</Text>
                        <TextInput
                            editable={false}
                            value={this.state.SelectedCompleteBill.description == "" ? "-" : this.state.SelectedCompleteBill.description}
                            style={styles.LableTextBox}
                        />
                    </View>
                    <View style={{ display: 'flex', flexDirection: "row" }}>
                        <View style={{ marginTop: "2vw" }}>
                            <Text style={styles.TextBoxLableStyle}>{"Billing Date"}</Text>
                            <DatePicker
                            disabled={true}
                                // disabledDate={current => {
                                //     return current > new Date() || moment(this.state.SelectedCompleteBill.bill_date__date).add(0, "days") > current;
                                // }}
                                // onChange={date => {
                                //     var converted_date = moment(date).format("YYYY-MM-DD");
                                //     this.setState({ CompletebillingDate: converted_date },()=>{
                                //         if(this.state.CompletebillingDate > this.state.CompletePaymentDate ){
                                //             this.setState({
                                //                 CompletePaymentDate : converted_date
                                //             })
                                //         }
                                //     })
                                // }}
                                value={moment(this.state.SelectedCompleteBill.bill_date__date)}
                                // defaultValue={moment()}
                                format={"DD-MM-YYYY"}
                                placeholder={"Billing Date"}
                                clearIcon={true}
                                style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '2.5vw', marginTop: '0.5vw', width: "13vw" }}
                            />
                        </View>
                        <View style={{ marginTop: "2vw", marginLeft: '2vw' }}>
                            <Text style={styles.TextBoxLableStyle}>{"Payment Date"}</Text>
                            <DatePicker
                                disabledDate={current => {
                                    return current > new Date() || moment(this.state.SelectedCompleteBill.bill_date__date).add(0, "days") > current;
                                }}
                                onChange={date => {
                                    var converted_date = moment(date).format("YYYY-MM-DD");
                                    this.setState({ CompletePaymentDate: converted_date })
                                }}
                                value={moment(this.state.CompletePaymentDate)}
                                format={"DD-MM-YYYY"}
                                placeholder={"Payment Date"}
                                clearIcon={false}
                                style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '2.5vw', marginTop: '0.5vw', width: "13vw" }}
                            />
                        </View>
                        <View style={{ marginTop: "2vw", marginLeft: '2vw' }}>
                            <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.5vw', backgroundColor: "white", paddingLeft: '0.3', paddingRight: '0.3', color: color.black }}>{"Payment Made By"}</Text>
                            <TextInput
                                onChangeText={(text) => { this.setState({ BillMadeBy: text }) }}
                                value={this.state.BillMadeBy}
                                style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '2.5vw', marginTop: '0.5vw' }}
                            />
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: "row" }}>
                        <View style={{ display: 'flex', flexDirection: "row", alignItems: 'center', paddingTop: "1vw" }}>
                            {/* Payment Type */}
                            <View style={styles.paymentview}><Text style={styles.PaymentTypeHeading}>{"Payment Type"}</Text></View>
                            <View style={styles.PaymentView}>
                                {/* <View style={{ flexDirection: 'row', flex: 0.4, justifyContent: 'space-between' }}> */}
                                {this.renderPaymentBtn(
                                    ImagePath.Cash,
                                    "Cash", 1
                                )}
                                {this.renderPaymentBtn(
                                    ImagePath.Card,
                                    "Card", 2)}

                                {this.renderPaymentBtn(
                                    ImagePath.CashandCard,
                                    "Cash and Card", 3
                                )}
                                {this.renderPaymentBtn(
                                    ImagePath.UPI,
                                    "UPI", 4
                                )}
                                {this.renderPaymentBtn(
                                    ImagePath.BankTransfer,
                                    "Bank Transfer", 5
                                )}
                            </View>
                        </View>
                    </View>


                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                        <TouchableOpacity
                            style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw' }}
                            onPress={() => {
                                var service_url = Constants.CORPORATE_BILLING_POST;
                                var data = {
                                    "id": this.state.SelectedCompleteBill.id,
                                    "invoice_number": this.state.SelectedCompleteBill.invoice_number,
                                    "bill_date": this.state.SelectedCompleteBill.bill_date__date,
                                    "payment_date": this.state.CompletePaymentDate,
                                    "payment_made_by": this.state.BillMadeBy,
                                    "payment_type": this.state.SelectedCompleteBillPayment
                                }

                                OpthamologyService.getInstance().putMethod(
                                    service_url,
                                    data,
                                    this,
                                    this.CompleteBillSuccess,
                                    this.CompleteBillFailure
                                )
                            }}
                        >
                            <Text style={{ color: 'white', fontSize: '0.78vw', fontWeight: '500' }}>{"Complete Bill"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw' }}
                            onPress={() => {
                                this.setState({
                                    IsCompleteBillPopUp: false,
                                    SelectedCompleteBill: {},
                                    SelectedCompleteBillPayment: 1,
                                    BillMadeBy: "",
                                    CompletebillingDate: moment(new Date()).format("YYYY-MM-DD"),
                                    CompletePaymentDate: moment(new Date()).format("YYYY-MM-DD"),
                                })
                            }}
                        >
                            <Text style={{ color: 'white', fontSize: '0.78vw', fontWeight: '500' }}>{"Cancel"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    CompleteBillSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                IsCompleteBillPopUp: false,
                SelectedCompleteBill: {},
                SelectedCompleteBillPayment: 1,
                BillMadeBy: "",
                CompletebillingDate: moment(new Date()).format("YYYY-MM-DD"),
                CompletePaymentDate: moment(new Date()).format("YYYY-MM-DD"),
                SelectedIndex: "",
                corpCompanyList: []
            }, () => {
                this.props.SuccessAlt(response.message)
                this.getCorporateList()
            })

        }
        else {
            this.props.ErrorAlert(response.message)
        }

    }
    CompleteBillFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }

    renderBillingSplitups = (emp_id) => {
        return (
            <View >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1vw' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <TouchableOpacity
                            onPress={() => { this.setState({ SplitBillsType: "patient", BillingSplitUps: this.state.AllBillingSplitUps.patient_bills }) }}
                        ><Text style={[styles.SpiltBtnText, { color: this.state.SplitBillsType == "patient" ? color.themeDark : color.black }]} >Patient Bills</Text></TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { this.setState({ SplitBillsType: "service", BillingSplitUps: this.state.AllBillingSplitUps.service_bills }) }}
                        ><Text style={[styles.SpiltBtnText, { color: this.state.SplitBillsType == "service" ? color.themeDark : color.black }]}>Service Bills</Text></TouchableOpacity>
                    </View>
                    <View>
                        <TouchableOpacity style={{ marginRight: '1vw' }} onPress={() => { this.setState({ SelectedIndex: "", SplitBillsType: 'patient' }) }}>
                            <Icon name="close" size={'1.3vw'} color={color.red} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ maxHeight: '20vw', width: '100%', borderWidth: 1, borderRadius: '0.26vw', borderColor: color.black, marginVertical: "1vw" }}>

                    <View>

                        <View>
                            <View style={{ width: "100%" }}>
                                <View style={[styles.TableTitleContainerSub, { backgroundColor: color.sectionHeadingLiteColor }]}>
                                    <View style={{ flex: 0.1 }} >
                                        <Text style={styles.TableHeadText}>Bill Date</Text>
                                    </View>
                                    <View style={{ flex: 0.12 }} >
                                        <Text style={styles.TableHeadText}>Bill No</Text>
                                    </View>
                                    <View style={{ flex: 0.22 }} >
                                        <Text style={styles.TableHeadText}>Billing period</Text>
                                    </View>
                                    <View style={{ flex: 0.18 }} >
                                        <Text style={styles.TableHeadText}>Description</Text>
                                    </View>
                                    <View style={{ flex: 0.14 }} >
                                        <Text style={styles.TableHeadText}>Bill Amount (₹)</Text>
                                    </View>
                                    <View style={{ flex: 0.14 }} >
                                        <Text style={styles.TableHeadText}>Payment Status</Text>
                                    </View>
                                    <View style={{ flex: 0.08 }} >
                                        <Text style={styles.TableHeadText}>Action</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <ScrollView style={styles.SpiltUP} showsVerticalScrollIndicator={true}>
                            {this.state.BillingSplitUps && this.state.BillingSplitUps.length > 0 ? this.state.BillingSplitUps.map((item, index) => {
                                 return (
                                    <View>
                                        <View key={index} style={[styles.TableDataWrapperSub]} >
                                            <View style={{ flex: 0.1 }}><Text style={[styles.TableDataText]}>{((item.bill_date == null) || (item.bill_date == "")) ? "-" : item.bill_date.split("T")[0]}</Text></View>
                                            <View style={{ flex: 0.12 }}><Text style={[styles.TableDataText, { flexWrap: "wrap" }]}>{item.invoice_number}</Text></View>
                                            <View style={{ flex: this.state.BillingSplitUps.length > 5 ? 0.24 : 0.22, alignItems: 'center' }}><Text style={styles.TableDataText}>{`${item.invoice_from_date} - ${item.invoice_to_date}`}</Text></View>
                                            <View style={{ flex: 0.18 }}>
                                                {item.description.length > 20 ?
                                                    <Tooltip placement="top" title={item.description}>
                                                        <Text style={[styles.TableDataText]}> {item.description.slice(0, 20) + ".."}</Text>
                                                    </Tooltip> :
                                                    <Text style={[styles.TableDataText]}>{item.description == "" ? "-" : item.description}</Text>}
                                            </View>
                                            <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.amount}</Text></View>
                                            <View style={{ flex: 0.14 }}>
                                                {item.status == "Payment Pending" ?
                                                    <Text style={[styles.TableDataText, { color: color.cardOrange }]}>Pending</Text> :
                                                    <Text style={[styles.TableDataText, { color: color.cardGreen }]}>Completed</Text>}
                                            </View>
                                            <View style={{ flex: 0.08  }}>
                                                <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', marginLeft: '1vw' }}>

                                                    {
                                                        item.status == "Payment Pending" ?
                                                            <Tooltip title="Complete Bill">
                                                                <TouchableOpacity
                                                                    onPress={() => {
                                                                        this.setState({
                                                                            IsCompleteBillPopUp: true, SelectedCompleteBill: item
                                                                        })
                                                                    }}
                                                                    style={[styles.PrintBtn, { marginLeft: '0.5vw' }]}>
                                                                    <Icon name="check-square-o" size={"1.2vw"} color={color.cardOrange} />
                                                                </TouchableOpacity>

                                                            </Tooltip> : null
                                                    }
                                                    <Tooltip title="Print Bill">
                                                        <TouchableOpacity
                                                            onPress={() => { this.printBill(item.invoice_number, emp_id) }}
                                                            style={[styles.PrintBtn, { paddingRight: '0.7vw' }]}>
                                                            <Icon name="print" size={"1.2vw"} color={color.themeDark} />
                                                        </TouchableOpacity></Tooltip>

                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                )
                            }) :
                                <View style={[styles.NoRecordsView, { marginTop: 0, padding: '2vw' }]}>
                                    <Text style={{ fontSize: '0.9vw' }}>No Bills to show...</Text>
                                </View>}
                        </ScrollView>
                    </View>

                </View>
            </View>
        )
    }

    paginationChangedValues(data) {
        this.setState({
            corpCompanyListAfterPagination: data,
            SelectedIndex: ''
        })
    }

    getSplitUpBills = (emp_id) => {
        var serviceUrl = Constants.CORPORATE_BILLING_POST + "?employer_id=" + emp_id;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getSplitBillSuccess,
            this.getSplitBillFailure
        );
    }

    getSplitBillSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                AllBillingSplitUps: response.data,
                BillingSplitUps: response.data.patient_bills
            })
        }
        else {
            this.props.ErrorAlert(response.message)
        }
    }

    returnSortIcon = (order) => {
        if (order == "") {
            return <Icon name="sort" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "asc") {
            return <Icon name="sort-up" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "desc") {
            return <Icon name="sort-down" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
    }

    renderCorporateLeft = () => {
        return (
            <View>
                <View style={styles.SearchBoxView}>
                    <View style={{ flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", width: "75%", marginTop: 0 }}>
                        <View style={[styles.CardView, { backgroundColor: "#F8F8F8", }]}>
                            <View>
                                {this.state.CorpTotalAmount.toString().length > 14 ?
                                    <Tooltip placement="top" title={"₹ " + this.state.CorpTotalAmount}>
                                        <Text style={[styles.CardAmountText, { color: color.themeDark }]}>{"₹ "} {this.state.CorpTotalAmount.toString().slice(0, 12) + ".."}</Text>
                                    </Tooltip> :
                                    <Text style={[styles.CardAmountText, { color: color.themeDark }]}>{"₹ "}{this.state.CorpTotalAmount}</Text>}
                                <Text style={{ fontSize: '0.8vw', fontWeight: '500', textAlign: 'center', paddingVertical: 2 }}>{"Total Corporate Bill Amount"}</Text></View>
                        </View>
                        <View style={[styles.CardView, { backgroundColor: "#E1F7F4", }]}>
                            <View>
                                {this.state.CorpPaidAmount.toString().length > 14 ?
                                    <Tooltip placement="top" title={"₹ " + this.state.CorpPaidAmount}>
                                        <Text style={[styles.CardAmountText, { color: color.cardGreen }]}>{"₹ "} {this.state.CorpPaidAmount.toString().slice(0, 12) + ".."}</Text>
                                    </Tooltip> :
                                    <Text style={[styles.CardAmountText, { color: color.cardGreen }]}>{"₹ "}{this.state.CorpPaidAmount}</Text>}
                                <Text style={{ fontSize: '0.8vw', fontWeight: '500', textAlign: 'center', paddingVertical: 2 }}>{"Total Corporate Paid Amount"}</Text></View>
                        </View>
                        <View style={[styles.CardView, { backgroundColor: "#FFEFEF", }]}>
                            <View>
                                {this.state.CorpPendingAmount.toString().length > 14 ?
                                    <Tooltip placement="top" title={"₹ " + this.state.CorpPendingAmount}>
                                        <Text style={[styles.CardAmountText, { color: color.red }]}>{"₹ "} {this.state.CorpPendingAmount.toString().slice(0, 12) + ".."}</Text>
                                    </Tooltip> :
                                    <Text style={[styles.CardAmountText, { color: color.red }]}>{"₹ "}{this.state.CorpPendingAmount}</Text>}
                                <Text style={{ fontSize: '0.8vw', fontWeight: '500', textAlign: 'center', paddingVertical: 2 }}>{"Total Corporate Pending Amount"}</Text></View>
                        </View>
                    </View>
                    <TextInput
                        style={styles.SearchInput}
                        placeholder={"Search By Company Name..."}
                        value={this.state.SearchCompanyKey}
                        onChangeText={(text) => {
                            this.setState({
                                SearchCompanyKey: text
                            }, () => {
                                this.getCorporateList()
                            })
                        }}
                    />
                </View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                {/* <View style={{ alignItems: "center", width: '6%' }} >
                                    <Text style={styles.TableHeadText}>S No</Text>
                                </View> */}
                                <View style={{ alignItems: "left", width: '24%' }} >
                                    <Text style={[styles.TableHeadText, { marginLeft: '2.5vw' }]}>Company</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '18%' }} >
                                    <Text style={styles.TableHeadText}>GSTIN</Text>
                                </View>
                                <TouchableOpacity
                                    style={{ alignItems: "center", width: '22%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                                    onPress={() => {
                                        if (this.state.BillDateSort == "asc") {
                                            this.setState({
                                                BillDateSort: "desc",
                                                corpCompanyList: []
                                            }, () => { this.getCorporateList() })
                                        }
                                        else if (this.state.BillDateSort == "desc") {
                                            this.setState({
                                                BillDateSort: "asc",
                                                corpCompanyList: []
                                            }, () => { this.getCorporateList() })
                                        }
                                    }}>
                                    <Text style={styles.TableHeadText}>Last Billed Date</Text>
                                    {this.returnSortIcon(this.state.BillDateSort)}
                                </TouchableOpacity>
                                {/* <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Last Billed Value (₹)</Text>
                                </View> */}
                                <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Total Outstandings (₹)</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Action</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <ScrollView style={styles.MainTable} showsVerticalScrollIndicator={false}>
                        {this.state.corpCompanyList && this.state.corpCompanyList.length > 0 && this.state.corpCompanyListAfterPagination && this.state.corpCompanyListAfterPagination.length > 0 ? this.state.corpCompanyListAfterPagination.map((item, index) => {
                            return (
                                <View >
                                    <TouchableOpacity onPress={() => { this.setState({ SelectedIndex: this.state.SelectedIndex === index ? "" : index, SplitBillsType: 'patient' }, () => { this.getSplitUpBills(item.id) }) }} key={index} style={[styles.TableDataWrapper, { backgroundColor: this.state.SelectedIndex === index ? '#E1F7F4' : color.white }]} >
                                        {/* <View style={{ width: '6%', alignItems: 'center' }}><Text style={styles.TableDataText}>{index + 1}</Text></View> */}
                                        <View style={{ width: '24%', alignItems: 'left', }}>
                                            {item.company_name.length > 30 ?
                                                <Tooltip placement="top" title={item.company_name}>
                                                    <Text style={[styles.TableDataText, { marginLeft: '0.5vw' }]}> {item.company_name.slice(0, 30) + ".."}</Text>
                                                </Tooltip> :
                                                <Text style={[styles.TableDataText, { marginLeft: '0.5vw' }]}> {item.company_name}</Text>}
                                        </View>
                                        <View style={{ width: '18%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.gst_no}</Text></View>
                                        <View style={{ width: '22%', alignItems: 'center' }}><Text style={styles.TableDataText}>{((item.last_billed_date == null) || (item.last_billed_date == "")) ? "-" : item.last_billed_date.split("T")[0]}</Text></View>
                                        {/* <View style={{ width: '16%', alignItems: 'center' }}><Text style={styles.TableDataText}>{((item.last_billed_value == null) || (item.last_billed_value == "")) ? "-" : item.last_billed_value}</Text></View> */}
                                        <View style={{ width: '16%', alignItems: 'center' }}><Text style={styles.TableDataText}>{((item.total_pending == null) || (item.total_pending == "")) ? 0 : item.total_pending}</Text></View>
                                        <View style={{ width: '16%', alignItems: 'center' }}>
                                            <View style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'space-around' }}>
                                                <TouchableOpacity
                                                    onPress={() => { this.CreateBillClicked(item) }}
                                                    style={[styles.PrintBtn, { borderWidth: 1 }]}>
                                                    <Text style={{ color: color.themeDark, fontSize: '0.91vw' }}>{"Create Bill"}</Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.setState({
                                                            SelectedIndex: this.state.SelectedIndex === index ? "" : index, SplitBillsType: 'patient'
                                                        }, () => { this.getSplitUpBills(item.id) })
                                                    }}
                                                    style={{ transform: this.state.SelectedIndex === index ? "rotate(180deg)" : null, marginLeft: '1vw', }}
                                                >
                                                    <Image
                                                        source={DropDownIcon}
                                                        style={{ height: '1.5vw', width: '1.5vw' }}
                                                        resizeMode="contain"
                                                    />
                                                </TouchableOpacity>
                                            </View>
                                        </View>

                                    </TouchableOpacity>
                                    {
                                        this.state.SelectedIndex === index ?
                                            this.renderBillingSplitups(item.id) : null
                                    }
                                </View>
                            )
                        }) :
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NoRecordsText}>No Records to show...</Text>
                            </View>}
                    </ScrollView>
                    <View>
                        {
                            <View style={styles.PaginationView}>
                                {

                                    this.state.corpCompanyList && this.state.corpCompanyList.length > 0 ?

                                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.corpCompanyList} />
                                        : null
                                }
                            </View>
                        }
                    </View>
                </View>

            </View>
        )
    }

    onChange = (value, dateString) => {
        var dateFormet = "YYYY-MM-DD"
        var a = dateString[0].substring(0, 10)
        var b = dateString[1].substring(0, 10)
        var a_format = moment(a).format(dateFormet);
        var b_format = moment(b).format(dateFormet);
        var filterDate = { fromDate: a_format, toDate: b_format }
        this.setState({
            date: filterDate,
            dateValue: value
        })

    }

    renderRangePicker() {
        return (
            <RangePicker
                bordered={true}
                value={this.state.dateValue}
                onChange={this.onChange}
                // **** Future Date Disable
                disabledDate={current => {
                    return current > moment()
                }}
            //  ****
            />
        )
    }


    renderCorporateRight = () => {
        return (
            <View style={{ height: '70vh' }}>
                <ScrollView style={styles.RightScrollView} showsVerticalScrollIndicator={false}>
                    <Text style={styles.RightHeadTitle}>Bill To Corporate</Text>

                    <View style={styles.HightLightView}>
                        <Text style={styles.HighLightText}>Details</Text>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Registered Name<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("RegisterName")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Date<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderRangePicker()}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Description<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("description")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Value<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("value")}
                        </View>
                    </View>
                    <Text style={styles.GSTTextLabel}>* Inclusive of GST</Text>
                    <View style={styles.InputView}>
                        <View style={[styles.InputView, { borderRightWidth: 2, borderColor: "#CDD1D5" }]}>
                            <Text style={styles.InputText}>GST %</Text>
                            <View style={{ width: '6vw', marginRight: '.5vw', marginLeft: '3vw' }}>
                                {this.renderTextBox('igst')}
                            </View>
                        </View>
                        <View style={styles.InputView}>
                            <View style={{ width: '7vw', marginLeft: '.5vw' }}>
                                {this.renderTextBox('cgst')}
                            </View>
                        </View>
                        <View style={styles.InputView}>
                            <View style={{ width: '7vw', marginRight: '.5vw', }}>
                                {this.renderTextBox('sgst')}
                            </View>
                        </View>
                    </View>


                </ScrollView>
                <View>
                    <View style={{ height: '1.5vw', marginTop: '0.3vw' }}>
                        <Text style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '.9vw', fontWeight: '500', justifyContent: 'center' }}>
                            {this.state.ErrorText}
                        </Text>
                    </View>
                    <View>
                        <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'space-around' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.AddBilling()
                                }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{"Save"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => { this.resetClicked() }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{"Clear"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    render() {
        return (
            <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={styles.LeftMainView}>{this.renderCorporateLeft()}</View>
                    <View style={styles.RightMainView}>{this.renderCorporateRight()}</View>
                </View>
                {
                    this.state.IsCompleteBillPopUp ? this.CompleteBillPopup() : null
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    LeftMainView: {
        width: '65%',
        height: '70vh',
        margin: '0.2vw',
        marginLeft: '0.7vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },

    RightMainView: {
        width: '33%',
        height: '70vh',
        backgroundColor: color.themeShade,
        margin: '0.2vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },
    SpiltBtnText: {
        color: color.themeDark,
        fontWeight: '500',
        marginLeft: "1vw",
        fontSize: '0.8vw'
    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw',
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableTitleContainerSub: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw',
        flex: 1
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    TableDataWrapperSub: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw",
        flex: 1,
        paddingVertical: "0.8vw"
    },
    MainTable: {
        height: '45vh',
        overflow: 'hidden'
    },
    SpiltUP: {
        maxHeight: '15vw'
    },
    paymentview: {
        marginRight: '4vw',
        zIndex: -1
    },

    PaymentTypeHeading: {
        fontSize: '0.91vw',
        fontWeight: '500'
    },
    PaymentView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '1.95vw',
        zIndex: -1,
        width: "35vw"
    },
    RightHeadTitle: {
        marginLeft: '1vw',
        marginTop: '0.3vw',
        fontSize: '1vw',
        fontWeight: 'bold',
        color: color.themeDark
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "100%",
        height: "1.5vw",
        fontSize: ".9vw"
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '2vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '0.6vw'
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw"
    },
    MandatoryText: {
        color: 'red',
        fontWeight: '500',
        marginLeft: '0.2vw',
        fontSize: '1vw'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.lightGray,
        borderRadius: '0.26vw',
        width: "25%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    TabView: {
        marginLeft: '2vw'
    },
    PrintBtn: {
        paddingTop: '0.2vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.2vw',
        backgroundColor: color.white,
        borderRadius: ".25vw",
        // width: '10vw',
        // borderWidth: 1,
        borderColor: color.themeDark
    },
    SearchBoxView: {
        margin: '0.5vw', marginLeft: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '10vh', alignItems: 'center'
    },
    PaymentButtonView: {
        alignItems: "center",
        justifyContent: "center"
    },
    PaymentButtonText: {
        marginTop: '0.32vw',
        fontSize: '0.84vw',
        marginBottom: '0.52vw'
    },
    paymentIconImg: {
        width: '1.95vw',
        height: '1.95vw'
    },
    CardView: {
        justifyContent: 'center',
        alignSelf: 'center',
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5,
        backgroundColor: color.white,
        borderRadius: 4,
        paddingHorizontal: 15,
        minWidth: '12vw',
        height: '4vw',
        marginRight: '2vw'
    },
    PageHeadText: {
        fontSize: '1vw',
        fontWeight: '500',
        marginRight: '1vw'
    },
    TextBoxLableStyle: {
        zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.5vw', backgroundColor: "white", paddingLeft: '0.3', paddingRight: '0.3', color: color.black
    },
    LableTextBox: {
        fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '2.5vw', marginTop: '0.5vw', backgroundColor: "#F9F9F9"
    },
    CardAmountText: {
        fontSize: '1vw', fontWeight: '500', marginTop: 10,
    },
    GSTTextLabel:{
        display:'flex',
        justifyContent:'flex-end',
        marginRight:'1.7vw',
        fontSize:'0.7vw',
        color:"#ABABAB"
    }

})

