//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, Picker,TouchableWithoutFeedback, ActivityIndicator ,CheckBox,TextInput} from 'react-native';
import Pagination from '../../doctor/common/Pagination';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { Tooltip } from 'antd';
const screenHeight = Dimensions.get("window").height;
import moment from "moment";
import { DatePicker } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";

import Style from "../../../styles/Style";
import { color } from '../../../styles/Color'
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../BaseComponent'
import { Calender } from '../common/calender';
import ImagePath from '../../../utils/ImagePaths';
// const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
// create a component
export  class LaboratoryPatientList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      getSelectedDate: moment(new Date()).format("YYYY-MM-DD"),
      paginationCurrentPage: 1,
      paginationSize: 100,
      selectedAction: "",
      showStatus: false,

      labPatientList: [],
      searchPatientData: [],
      patientSearchTextValue: "",
      isAction:false,
      pageLoading:true,

      
      other_specimen:this.props.specimen_Data.other_specimen,
      specimen:this.props.specimen_Data.specimen,
      specimen_collected_by:this.props.specimen_Data.specimen_collected_by,

      specimen_tooltip:this.props.specimen_tooltip,
      specimen_item:this.props.specimen_item,
      specimen_key:this.props.specimen_key,

      sortValue: "",

      specimen_modal:false
    }
  }

 componentDidUpdate(prevProps) {
    if( prevProps.isLabSavedBillDelete != this.props.isLabSavedBillDelete ){
      this.getLabBillingPatientList()
    }
 }
  componentWillReceiveProps(nextProps) {
    if (nextProps && (nextProps.patientSearchTextValue !== this.state.patientSearchTextValue)) {
      this.setState({
        patientSearchTextValue: nextProps.patientSearchTextValue
      })
    }else if(nextProps && nextProps.patientSearchTextValue === ""){
      this.setState({
        patientSearchTextValue: nextProps.patientSearchTextValue
      })
    }
    if(nextProps && nextProps.isSearchClicked){
      this.searchLabPatient()
    }
  }

  componentDidMount() {
    this.getLabBillingPatientList()
  }

  onPressButton(key, value) {
    if (key) {
      this.props.changeScreen(key, {}, "", {}, "", {})
    }
  }

  renderButton(label, buttonKey) {
    var item = { label: label, value: label }
    return (
      <View style={{ marginLeft: 15 }}>
        <CommonButton
          item={item}
          selectedvalue={{}}
          butttonText={item.label}
          buttonType={"theme"}
          buttonIcon={require("../../../../assets/images/PlusIcon.png")}
          rightIcon={true}
          buttonAction={this.onPressButton.bind(this)}
          buttonKey={buttonKey} />
      </View>
    )
  }

  getSelectedDate(date) {
    // alert(date)
    this.setState({
      getSelectedDate: date
    }, () => { this.getLabBillingPatientList() })
  }

  renderHeader() {
    return (
      <View style={{ marginVertical: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontWeight: "bold", fontSize: 20, marginLeft:18 }}>{"Laboratory"}</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <CommonDateFilter showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}/> */}
          {this.renderPicker()}
          {/* <Calender getSelectedDate={this.getSelectedDate.bind(this)} /> */}
          <View>
            <Text style={{fontWeight: "500"}}>Select Date</Text>
            <DatePicker
              clearIcon={false}
              defaultValue={moment(this.state.selectedDate)}
              format={"YYYY-MM-DD"}  
              style={{borderColor: color.themeDark, borderWidth: 2}}   
              onChange={(date) => {
                var fulldate = new Date(date);
                var converted_date = moment(fulldate).format("YYYY-MM-DD");
                this.getSelectedDate(converted_date)
              }}          
            />
          </View>

          {
            this.props.labUserAccessType !== "isReport" ?
              this.renderButton("New Patient", "demographics")
              : null
          }
        </View>
      </View>
    )
  }

  renderLabPatientListHeader() {
    return (
      <View style={{ zIndex: -1, marginTop: 5, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, paddingLeft: 20, paddingRight: 15, }}>
        <View style={{ paddingLeft:5, flex: this.state.showStatus ? 0.7: 0.85  , flexDirection: 'row', alignItems: 'center',}}>
        <View style={{ flex: 0.25, flexDirection: "row" }}>
            
            {
            this.state.sortValue === "" ? 
            <TouchableOpacity style= {{ flexDirection: "row" }} onClick={() => {
              this.setState({
                sortValue: "asc",
                pageLoading: true,
              }, () => {
                this.getLabBillingPatientList()
              })
            }}>
            <Text style={[styles.headerFontStyle, {marginRight: 10}]}>{"Patient's Details"}</Text>
            <Icon name="sort" size={18} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "asc" ? 
            <TouchableOpacity style= {{ flexDirection: "row" }} onClick={() => {
              this.setState({
                sortValue: "des",
                pageLoading: true,
              }, () => {
                this.getLabBillingPatientList()
              })
            }}>
            <Text style={[styles.headerFontStyle, {marginRight: 10}]}>{"Patient's Details"}</Text>
            <Icon name="sort-up" size={18} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "des" ? 
            <TouchableOpacity style= {{ flexDirection: "row" }} onClick={() => {
              this.setState({
                sortValue: "",
                pageLoading: true,
              }, () => {
                this.getLabBillingPatientList()
              })
            }}>
            <Text style={[styles.headerFontStyle, {marginRight: 10}]}>{"Patient's Details"}</Text>
            <Icon name="sort-down" size={18} color={color.black} />
            </TouchableOpacity> : null
            }
          
          </View>
          <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Age"}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Gender"}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Test Name"}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Mobile No"}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Referred by"}</Text></View>
          </View>
          { this.state.showStatus ? <View style={{ flex:0.15, alignItems: 'center' }}>
          <Text style={Style.appointmentTableHeadText}>STATUS</Text>
          </View>:null
        }
        <View style={{ flex: 0.15, alignItems: "center" }}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
      </View>
    )
  }

  renderPicker() {
    return (
      <View>
        <Picker
          selectedValue={this.state.selectedAction}
          style={[Style.pickerView, {
            marginTop: 5, borderColor:
              this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5'
          }, Style.allButtonBorderRadius]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedAction"] = itemvalue;
            this.setState({ states }, () => { this.getLabBillingPatientList() });
          }}
        >
          <Picker.Item label="Select Action" value="" />
          <Picker.Item label="Specimen Collected" value="Specimen Collected" />
          <Picker.Item label="Result Inprogress" value="Result Inprogress" />
          <Picker.Item label="Result Completed" value="Result Completed" />
          <Picker.Item label="Result Delivered" value="Result Delivered" />
        </Picker>
      </View>
    )
  }

  renderTextBox(placeholder, width, center, type, value, error = false, disable = false) {
    return (
      <View>
        <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: type == "IDP" ? color.themeShade : "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>

        {
          disable || this.state.isIpPatientCheckouted?
            <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: 8, color: "#888888", paddingTop: 10 }, Style.allButtonBorderRadius,
            this.state.isIpPatientCheckouted ? {backgroundColor:"#F0F0F0"}:{}
            ]} >
              {value}
            </Text> :
            <TextInput

              style={{
                borderColor: error ? "red" : "#CDD1D5",
                borderRadius: 4,
                borderWidth: 1,
                width: "100%",
                height: 35,
                padding: 15,
                marginTop: 8
              }} value={value}
              onChangeText={(text) => {
                // value = text;
                // props.callBack(text, props.textKey);
                if(placeholder == 'Source Name'){
                  this.createPatientDetails(text, placeholder, type);
                  this.searchReferrals(text);
                }else {
                  this.createPatientDetails(text, placeholder, type);
                }

              }}
            // onSubmitEditing={() => {
            //     props.enterTosubmit ?
            //         props.callBack(value, props.textKey, true)
            //         : null
            // }
            // }
            />



        }

      </View>
    )
  }

  

  ChangeStatus(flag,tooltip,item,key,specimen_Data) {

    this.setState({
      isAction:flag,
      specimen_modal:false
    })
    let data={}

    if(tooltip == "Specimen Collected"){
     data = {
      "bill_summary_id": item.bill_summary_id,
      "status": tooltip,
      "specimen":specimen_Data.specimen,
      "collected_by":specimen_Data.specimen_collected_by
       }
      }else{
        data = {
        "bill_summary_id": item.bill_summary_id,
        "status": tooltip
      }}
       
    var service_url = Constants.LAB_PATIENT_STATUS_CHANGE;

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.ChangeStatusSuccess,
      this.ChangeStatusFailure
    );
  }

  ChangeStatusSuccess = success => {
    this.setState({isAction:false})
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);
      this.getLabBillingPatientList()
    } else {
      this.props.showResposeValue('error', success.message);
    }
  }
  ChangeStatusFailure = error => {
    this.setState({isAction:false})
    this.props.showResposeValue('error', error.message);
  }

  renderStatusIcons(image, key, tooltip, item) {
    return (

      <TouchableOpacity
        disabled={this.state.isAction}
        style={{
          padding: 5, borderRadius: 30, height: 35, width: 35, backgroundColor:tooltip==item.appointment_status?color.themeDark: color.white, shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.4, shadowRadius: 3,alignItems:"center",justifyContent:"center",
          elevation: 5,
        }}>
        <Tooltip placement="top" title={tooltip=="Specimen Collected"?
        <View>
        <Text style={{color:color.white}}>
        {tooltip}
        </Text>
        <Text style={{color:color.white}}>
         { item.specimen_collect_time}
        </Text></View>:tooltip
        }>
          <Image source={image} style={[
            {
              height: 28, width: 28, tintColor:tooltip==item.appointment_status? color.white :color.black
            }]} />
        </Tooltip>
      </TouchableOpacity>
    )
  }

  showSpecimen=(tooltip, item,key)=>{
    this.props.showSpecimen(true,tooltip, item,key)
  }

  renderActionIcons(image, key, tooltip, item) {
    return (

      <TouchableOpacity
        disabled={this.state.isAction}
        onPress={()=>{
          if (key == 'specimenCollected' && item.status != 'canceled'){
          this.setState({specimen_modal:true})
          this.showSpecimen(tooltip,item,key)
        }else{
          this.ChangeStatus(true,tooltip,item,key)
        }
      }}
         style={{
          padding: 5, borderRadius: 30, height: 35, width: 35, backgroundColor:tooltip==item.status?color.themeDark: color.white, shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.4, shadowRadius: 3,alignItems:"center",justifyContent:"center",
          elevation: 5,
        }}>
        <Tooltip placement="top" title={tooltip=="Specimen Collected"?
        <View>
        <Text style={{color:color.white}}>
        {tooltip}
        </Text>
        <Text style={{color:color.white}}>
         { item.specimen_collect_time}
        </Text></View>:tooltip
        }>
          <Image source={image} style={[
            {
              height: 28, width: 28, tintColor:tooltip==item.status? color.white :color.black
            }]} />
        </Tooltip>
      </TouchableOpacity>
    )
  }

 

  renderLabPatientList(item, index) {
    return (
      // <TouchableOpacity key={index} style={{ zIndex: -1 }} onPress={() => {
      //   this.getPatientInfo(item)
      //   this.props.clearSearchTextBoxValue()
      //   // this.props.changeScreen("laboratoryResult", {}, "", {}, "", {}, "", {}, "", item)
      // }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', borderRadius: 4, paddingVertical: 8, borderBottomColor: "#888888", borderBottomWidth: 1 }}>
            
        <TouchableOpacity key={index} style={{ flex:this.state.showStatus ? 0.7: 0.85 ,zIndex: -1 ,flexDirection: 'row',alignItems: 'center',}} onPress={() => {
        this.getPatientInfo(item)
        this.props.clearSearchTextBoxValue()
      }}>
          
          <View style={{ flex: 0.25, flexDirection: 'row', alignItems: 'center', }}>
            <Image style={{ height: 35, width: 35, paddingRight: 8, borderRadius: 30 }} source={
              item.patient_photo_url ? { uri: item.patient_photo_url } :
                require('../../../../assets/images/sample_profile.png')} />
            <View>
              {
                item && item.patient_name ?
                  <Tooltip placement="topLeft" title={item.patient_name}>
                    <Text style={{ fontSize: 15, fontWeight: '500' }}>{item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}</Text>
                  </Tooltip> 
                  : null
              }
              {item && item.patient_account_number ?
                <Text
              style={{
                fontSize: 11,
                // color:
                //   this.state.selectedIndex === index
                //     ? color.white
                //     : 
                //     "#888888",
                // color.lightGray,
                marginTop: 5,
                marginBottom: 5
              }}
            >
              {item.patient_account_number}
            </Text>
            :null
            }
            </View>
          </View>
          <View style={{ flex: 0.15 }}><Text style={styles.patientListFontStyle}>{item.patient_age}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.patientListFontStyle}>{item.patient_gender}</Text></View>
          <View style={{ flex: 0.15 }}>
            <Tooltip placement="topLeft" title={item.test_name + "  "}>
              <Text style={[styles.patientListFontStyle,{width:'70%'}]}
              numberOfLines={2}
              ellipsizeMode = "tail"
              >
                {item.test_name + " "}
                </Text>
            </Tooltip>
            </View>
          <View style={{ flex: 0.15 }}><Text style={styles.patientListFontStyle}>{item.patient_mobile_number}</Text></View>
          <View style={{ flex: 0.15 }}><Text style={styles.patientListFontStyle}>{item.reffered_by}</Text></View>
          </TouchableOpacity>

          { this.state.showStatus ? <View style={{ flex: 0.15, flexDirection: 'row', justifyContent: "center" }}>
            {this.renderStatusIcons(require("../../../../assets/images/statusIcon/NewStatusIcons/Arrived.png"), "Arrived", "Arrived", item)}
            {this.renderStatusIcons(require("../../../../assets/images/statusIcon/NewStatusIcons/Optometry.png"), "Optometry", "Optometry", item)}
            {this.renderStatusIcons(require("../../../../assets/images/statusIcon/NewStatusIcons/With_Doctor.png"), "With Doctor", "With Doctor", item)}
            {this.renderStatusIcons(require("../../../../assets/images/statusIcon/NewStatusIcons/Scan_or_Surgery.png"), "Scan/Surgery", "Scan/Surgery", item)}
            {this.renderStatusIcons(require("../../../../assets/images/statusIcon/NewStatusIcons/Completed.png"), "Completed", "Completed", item)}

            {/* <Text style={styles.patientListFontStyle}>{""}</Text> */}
          </View>:null
          }

          <View style={{ flex: 0.15, flexDirection: 'row', justifyContent: "center" }}>
            {this.renderActionIcons(require("../../../../assets/images/Active/Testconfig.png"), "specimenCollected", "Specimen Collected", item)}
            {this.renderActionIcons(require("../../../../assets/images/Active/INPROgress.png"), "resultInprogress", "Result Inprogress", item)}
            {this.renderActionIcons(require("../../../../assets/images/Active/Completed.png"), "resultCompleted", "Result Completed", item)}
            {this.renderActionIcons(require("../../../../assets/images/Active/Delivered.png"), "resultDelivered", "Result Delivered", item)}
          </View>
        </View>
    )
  }


  renderLabPatientSearchList() {
    return (

      <View style={{ zIndex: 2 }}>
        {
          this.state.searchPatientData && this.state.searchPatientData.length > 0 ?

            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: 'flex-start', paddingLeft: 150, paddingBottom: 30 }}>

              <View style={{ width: "30%", backgroundColor: 'white', borderBottomRightRadius: 10, borderBottomLeftRadius: 10, paddingBottom: 10 }}>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.5 }}>
                  {
                    this.state.searchPatientData.map((item, index) => {
                      return (
                        <TouchableWithoutFeedback>
                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, borderBottomColor: "#E4E8F1", borderBottomWidth: 1 }}>
                          <View style={{flexDirection: "column"}}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <Image source={item.patient_photo_url ? item.patient_photo_url : require('../../../../assets/images/sample_profile.png')}
                                style={Style.appointmentTableRowImg}
                                resizeMode='stretch' />
                              <Text style={{ color: 'black', marginLeft: 10 }} >{item.patient_name}</Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                              <Text style={{ color: '#ADB4C1', marginLeft: 10 }}> {item.patient_mobile_number}</Text>
                              <Text style={{ color: '#ADB4C1', marginLeft: 10 }}>{"-"}</Text>
                              <Text style={{ color: '#ADB4C1', marginLeft: 10 }}>{item.patient_account_number ? item.patient_account_number : "N/A"}</Text>
                            </View>
                          </View>
                          {
                            item.admission_date && item.doctor_id ?

                              <TouchableOpacity style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", }}>
                                <Text style={{ color: "black", fontSize: 13, fontWeight: "500" }}>{item.admission_date}</Text>
                              </TouchableOpacity> :
                              <TouchableOpacity onPress={() => {
                                var prepareData = {
                                patientDetails:item,
                                isSearchedPatient:true,
                                patient_id:item.patient_id
                            }
                            // alert(JSON.stringify(prepareData));
                            console.log(JSON.stringify(prepareData));
                            // this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData,"",true)
                            this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData,"",true)

                                // this.props.changeScreen("demographics", {}, "", {}, "", item)
                              }} style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", backgroundColor: color.themeDark, height: 25, width: 25, borderRadius: 50 }}>
                                <Text style={{ color: "white", fontSize: 20, fontWeight: "500" }}>+</Text>
                              </TouchableOpacity>
                          }

                        </View>
                        </TouchableWithoutFeedback>
                      );
                    })

                  }
                </ScrollView>
              </View>

            </View>
            : null
        }
      </View>
    )
  }

  getPatientInfo(item){

    var service_url = Constants.GET_PATIENT_DETAILS + "?patient_id=" + item.patient_id ;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientInfoSuccess,
      this.getPatientInfoFailure,
      item
    );
  };
  getPatientInfoSuccess = (response,item) => {
    if (response.status === "success") {
          this.props.changeStateValues(response.data);
          this.props.changeScreen("laboratoryResult", {}, "LAB", {}, "", {}, "", {}, "", item,false,true)
  }
 }
 getPatientInfoFailure = error => {
  // console.log(" get Patient Info GET error response");
  console.log(error); Continue
};

  render() {
    return (
     <View>
      <View style={{ flex: 1 }}>
        {this.renderLabPatientSearchList()}
        {this.renderHeader()}
        {this.renderLabPatientListHeader()}
        <ScrollView style={{ height: screenHeight - 230, paddingLeft: 20, paddingRight: 15, zIndex: -1 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          {
            this.state.labPatientList && this.state.labPatientList.length > 0 ?

              this.state.labPatientList.map((item, index) => {
                return this.renderLabPatientList(item, index)
              })

              :
              <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, marginTop: 50 }}>
               { this.state.pageLoading ? <ActivityIndicator size="large" color={color.themeDark} /> :
                 <Text style={{ color: color.appointmentTableHead }}>{"No records to be shown"}</Text>
               }  
              </View>
          }

          
          {this.state.specimen_modal && this.props.specimen_Data.specimen_collected_by ? 
          (this.ChangeStatus(true,this.props.specimen_tooltip,this.props.specimen_item,this.props.specimen_key,this.props.specimen_Data,))  
          :null}


          {/* {
                    // this.state.labPatientList && this.state.labPatientList.length > 0 ?
                    <Pagination isCustome={true} paginationChangedValues={this.paginationChangedValues.bind(this)} />
                    // : null
                } */}

        </ScrollView>
      </View >
          {/* <View style={{flexDirection:"row",bottom:0,position:"absolute",marginTop:20}}>
            <Image source={require("../../../../assets/images/HomeScreenImages/Copyright.png")} style={{height:15,width:15,color:color.lightGray,tintColor:color.lightGray}} />
            <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
          </View>    */}
          {/* <View style={{width:500,backgroundColor:"powderblue"}}>  */}
          
          {/* </View> */}
        </View>
    );
  }



  paginationChangedValues(a) {
    // console.log(a)
  }


  getLabBillingPatientList() {
    var states = this.state;
    var service_url = Constants.LAB_BILL_PATIENT_LIST_GET +
      "?date=" + moment(states.getSelectedDate).format("YYYY-MM-DD") +
      "&action=" + states.selectedAction + "&sort=" + this.state.sortValue
      ;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getLabBillingPatientListSuccess,
      this.getLabBillingPatientListFailure
    );
  }
  getLabBillingPatientListSuccess = (success) => {

    // var data = [
    //     {
    //         "bill_summary_id": 1,
    //         "patient_id": 14,
    //         "reffered_by": "sathiya",
    //         "status": "Result Completed",
    //         "patient_name": "Mr Jung Kook",
    //         "patient_photo_url": "https://s3-ap-south-1.amazonaws.com/emedhub-stage/patient/14/PTPHOTO_20201217125500220452_pexels-photo-279467.jpeg",
    //         "patient_age": "23 years",
    //         "patient_gender": "Male",
    //         "patient_mobile_number": "9566825938"
    //     },
    //     {
    //         "bill_summary_id": 1,
    //         "patient_id": 14,
    //         "reffered_by": "sathiya",
    //         "status": "Result Completed",
    //         "patient_name": "Mr Jung Kook",
    //         "patient_photo_url": "https://s3-ap-south-1.amazonaws.com/emedhub-stage/patient/14/PTPHOTO_20201217125500220452_pexels-photo-279467.jpeg",
    //         "patient_age": "23 years",
    //         "patient_gender": "Male",
    //         "patient_mobile_number": "9566825938"
    //     },
    //     {
    //         "bill_summary_id": 1,
    //         "patient_id": 14,
    //         "reffered_by": "sathiya",
    //         "status": "Result Completed",
    //         "patient_name": "Mr Jung Kook",
    //         "patient_photo_url": "https://s3-ap-south-1.amazonaws.com/emedhub-stage/patient/14/PTPHOTO_20201217125500220452_pexels-photo-279467.jpeg",
    //         "patient_age": "23 years",
    //         "patient_gender": "Male",
    //         "patient_mobile_number": "9566825938"
    //     },
    //     {
    //         "bill_summary_id": 1,
    //         "patient_id": 14,
    //         "reffered_by": "sathiya",
    //         "status": "Result Completed",
    //         "patient_name": "Mr Jung Kook",
    //         "patient_photo_url": "https://s3-ap-south-1.amazonaws.com/emedhub-stage/patient/14/PTPHOTO_20201217125500220452_pexels-photo-279467.jpeg",
    //         "patient_age": "23 years",
    //         "patient_gender": "Male",
    //         "patient_mobile_number": "9566825938"
    //     },
    //     {
    //         "bill_summary_id": 1,
    //         "patient_id": 14,
    //         "reffered_by": "sathiya",
    //         "status": "Result Completed",
    //         "patient_name": "Mr Jung Kook",
    //         "patient_photo_url": "https://s3-ap-south-1.amazonaws.com/emedhub-stage/patient/14/PTPHOTO_20201217125500220452_pexels-photo-279467.jpeg",
    //         "patient_age": "23 years",
    //         "patient_gender": "Male",
    //         "patient_mobile_number": "9566825938"
    //     },

    // ]
    if (success.status == "success") {
      let{ showStatus } = this.state
      if( success.data.result.length > 0 ){
        for( var i = 0 ; i < success.data.result.length ; i++ ){
          console.log('Check Log Before-> ', showStatus);
          showStatus = success.data.result[i].has_status;
          console.log('Check Log After-> ', showStatus);
          break;
        }
      }
      this.setState({
        labPatientList: success.data.result,
        showStatus,
        pageLoading:false
      })
    }
    // alert("getLabBillingPatientList ********************> "+JSON.stringify(success))
    // console.log("getLabBillingPatientList ********************> "+JSON.stringify(success))
  }
  getLabBillingPatientListFailure = error => {
    console.log("getLabBillingPatientList ********************> " + JSON.stringify(success))
  }



  searchLabPatient = () => {

    // alert(query)

    if (this.state.patientSearchTextValue !== "") {
      var serviceUrl =
        Constants.LAB_PATIENT_SEARCH +  // 
        "?search_key=" + this.state.patientSearchTextValue;
      // +
      // "&home_page="+true ;

      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.searchLabPatientSuccess,
        this.searchLabPatientFailure
      );
    } else {
      var fields = this.state;
      fields["searchPatientData"] = [];

      this.setState({
        fields
      });
    }
  };

  searchLabPatientSuccess = response => {

    this.setState({
      //   fields
      searchPatientData: response.data,
    });
  };

  searchLabPatientFailure = error => {
    // console.log("search patient error response");
    console.log(error);
  };

}

// define your styles
const styles = StyleSheet.create({
  headerFontStyle: {
    fontSize: 13,
    color: "black",
    fontWeight: '500'
  },
  patientListFontStyle: {
    fontSize: 11,
    color: "black"
  },
});
