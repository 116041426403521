import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, TouchableWithoutFeedback, ActivityIndicator } from "react-native";
import { Tooltip, DatePicker } from 'antd';
import { color } from "../../../../styles/Color";
import Style from '../../../../styles/Style';
import ImagePath from '../../../../utils/ImagePaths';
import Pagination from '../../common/Pagination';
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService"
import OTMReschedule from './OTMReschedule';
import OTMHistory from './OTMHistory';
import moment from "moment";
import AsyncStorage from '../../../../AsyncStorage';
import Service from '../../../../network/Service';

export default class OTAppointmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isApproved: false,
            isCancelled: false,
            isRescheduled: false,
            CancellComments: "",
            ErrorCancellComment: "",
            showCancelPopup: false,
            showReschedulePopup: false,
            showHistoryPopup: false,
            otAppointmentList: [],
            totalPatientCount: 0,
            approved: 0,
            reschedule: 0,
            noShow: 0,
            cancelled: 0,
            otAppointmentListPaginationValue: [],
            rescheduledData: "",
            selectedDate: "",
            datevalue: "",
            AppointmentStatus: "",
            isServiceType: "",
            RescheduledDate: "",
            RescheduledTime: "",
            RescheduledReason: "",
            OPappointmentID: "",
            selectedNurseTag: "",
            selectedpatientTag: "",
            PatientData: {},
            packageName: "",
            EyeType: "",
            IOLPower: "",
            pageLoading: false,
            is_OPT:"",
            drType:"",
            Approval_setting : [],
            isOT : true,
            isDoctor : true,
            isFO : true,
            consellorApproved : true
        }
    }

    async componentDidMount() {
        this.getAppointmentDetails()
        const service_type = await AsyncStorage.getItem("service_type");
        var loggedInData = await AsyncStorage.getItem("loggedInData");
        var OPT = JSON.parse(loggedInData)
        const userType = await AsyncStorage.getItem("DR_TYPE");
        this.setState({
            isServiceType: service_type,
            is_OPT: OPT.is_OPT || OPT.clinic_type?.is_opt_clinic || OPT.service_type == "OPT" ? true : false,
            drType: userType
        })
        const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            Approval_setting: FO_admin_setting
        },() => {
            this.setOTApprovalLevel(this.state.Approval_setting)
        })
    }

    ClosePopup = () => {
        this.setState({
            showReschedulePopup: false,
            showHistoryPopup: false
        })
    }

    onChangeDate = (data) => {
        this.setState({
            RescheduledDate: data
        })
    }

    onChangeTime = (data) => {
        this.setState({
            RescheduledTime: data
        })
    }

    RescheduleAppointment = (item, reason, package_name, eye_type, iol_power) => {
        if (item.id !== "") {
            this.setState({
                isRescheduled: true,
                RescheduledReason: reason,
                packageName: package_name,
                EyeType: eye_type,
                IOLPower: iol_power
            }, () => { this.UpdateAppointmentStatus(item) })
        }
    }

    paginationChangedValues(data) {
        // alert(JSON.stringify(data))
        this.setState({
            otAppointmentListPaginationValue: data
        })
    }

    setOTApprovalLevel = (settings) => {
        var Approve_Config = []
        if(settings && settings?.ot_approve_config){
        Approve_Config = settings?.ot_approve_config
        var OT_Approve = (Approve_Config.indexOf("ot") > -1);
        var FO_Approve = (Approve_Config.indexOf("fo") > -1);
        var Doctor_Approve = (Approve_Config.indexOf("doctor") > -1);
        this.setState({
          isOT: OT_Approve ? true : false,
          isDoctor: Doctor_Approve ?  true : false,
          isFO: FO_Approve ? true : false
        })
    }
      }

    // Get Appointment Details API Start //

    getAppointmentDetails = () => {
        var url = Constants.GET_APPOINTMENT_LIST + "?surgery_date=" + this.state.selectedDate + "&status=" + this.state.AppointmentStatus

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getAppointmentDetailsSuccess,
            this.getAppointmentDetailsFailure
        );
    }

    getAppointmentDetailsSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                otAppointmentList: response.data.ot_list,
                totalPatientCount: response.data.total_patients,
                approved: response.data.approved,
                reschedule: response.data.reschedule,
                noShow: response.data.no_show,
                cancelled: response.data.cancelled,
                pageLoading: false
            })
        } else {
            console.log("error")
        }
    }

    // Get Appointment Details API Ends // 

    // Update Appointment Status API Starts //
    UpdateAppointmentStatus = (item) => {

        var url = Constants.GET_APPOINTMENT_LIST + "status/"

        var surgeryStatus = "";

        if (this.state.isApproved == true) {
            surgeryStatus = "Approved"
        }
        else if (this.state.isCancelled == true) {
            surgeryStatus = "Cancelled"
        }
        else if (this.state.isRescheduled == true) {
            surgeryStatus = "Reschedule"
        }
        else if (item.appointment_status == null) {
            surgeryStatus = "Arrived";
        }
        else if (item.appointment_status === "Arrived") {
            surgeryStatus = "In Surgery";
        }
        else if (item.appointment_status === "In Surgery") {
            surgeryStatus = "Completed";
        }

        var data =
        {
            "id": item.id,
            "status": surgeryStatus,
            "reschedule_reason": this.state.RescheduledReason,
            "cancel_reason": this.state.CancellComments,
            "reschedule_date": this.state.RescheduledDate,
            "reschedule_time": this.state.RescheduledTime,
            "package_name": this.state.packageName,
            "eye_type": this.state.EyeType,
            "iol_power": this.state.IOLPower
        }
        OpthamologyService.getInstance().postComplaints(
            url,
            data,
            this,
            this.UpdateAppointmentStatusSuccess,
            this.UpdateAppointmentStatusFailure,
        );
    }

    UpdateAppointmentStatusSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                pageLoading: true,
                otAppointmentList: [],
                CancellComments: "",
                ErrorCancellComment: "",
            }, () => { this.getAppointmentDetails() })
            this.props.showResposeValue("success", response.message)
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    // Update Appointment Status API Ends //

    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours == '12') {
            hours = '00';
        }

        if (modifier == 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${"00"}`;
    }

    // Create Appointment API Starts //

    CheckAppointment = (item) => {
        var serviceUrl = ""
        this.setState({
            PatientData: item
        })

        serviceUrl = Constants.HOME_SEARCH_PATIENT + "?search_key=" + item.uhid
        Service.getInstance().appointmentComments(
            serviceUrl,
            this,
            this.checkAppointmentSuccess,
            this.checkAppointmentFailure
        );
    }

    checkAppointmentSuccess = response => {
        if (response.status === "success") {
            this.setState({
                OPappointmentID: response.data.patient[0].appointment
            }, () => { this.CreateAppointments() })
        }
        else {
            console.log("error")
        }
    }

    CreateAppointments = () => {
        var service_url = ""
        var data = {}
        var today = new Date();
        var today_Date = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
        var time = this.convertTime12to24(new Date().toLocaleTimeString())

        if (!this.state.OPappointmentID.hasOwnProperty("appointment_id")) {

            service_url = Constants.FO_CREATE_APPOINTMENT;
            data = {
                "patient_id": this.state.PatientData.patient_id,
                "doctor_id": this.state.PatientData.doctor_id,
                "purpose_of_visit": this.state.PatientData.surgery_name,
                "appointment_patient_type": "Walk-in Appointment",
                "appointment_date": today_Date,
                "appointment_start_time": time,
                "create_without_schedule": true,
                "patient_tags": this.state.selectedpatientTag
            }

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.getNextAppointmentSuccess,
                this.getNextAppointmentFailure
            );

        }
        else {
            this.setState({
                OPappointmentID: this.state.OPappointmentID.appointment_id
            }, () => { this.props.PatientDetail(this.state.PatientData, "admissionDetails", this.state.OPappointmentID) })
        }
    }

    getNextAppointmentSuccess = response => {
        if (response.status === "success") {
            this.setState({
                OPappointmentID: response.data.appointment_id
            }, () => { this.props.PatientDetail(this.state.PatientData, "admissionDetails", this.state.OPappointmentID) })
        }
        else {
            this.props.showResposeValue("error", response.message)
        }
    }

    // Create Appointment API Ends //

    errorBox = (item, Action) => {
        
        if (item.status === "Cancelled" && (Action === "Complete" || Action === "Cancelled" || Action === "Surgery Status" || Action === "Reschedule" || Action === "MovetoIP")) {
            this.props.showResposeValue("error", "Appointment has been Cancelled")
        } else if (item.appointment_status === "Completed" && (Action === "Complete" || Action === "Cancelled" || Action === "Surgery Status")) {
            this.props.showResposeValue("error", "Appointment has been Completed")
        } else if (item.status === "Reschedule" && (Action === "Complete" || Action === "Cancelled" || Action === "Surgery Status" || Action === "Reschedule" || Action === "MovetoIP")) {
            this.props.showResposeValue("error", "Appointment has been Rescheduled")
        } else if (item.status === "No Show" && (Action === "Complete" || Action === "Cancelled" || Action === "Surgery Status" || Action === "Reschedule" || Action === "MovetoIP")) {
            this.props.showResposeValue("error", "This Appointment is No Show")
        } else if (item.status === "Approved" && Action === "Reschedule") {
            this.props.showResposeValue("error", "Appointment has been Approved so unable to Reschedule")
        } else if (item.appointment_status === "Arrived" && Action === "Cancelled") {
            this.props.showResposeValue("error", "Patient Already Arrived so unable to Cancel Appointment")
        } else if (item.appointment_status === "In Surgery" && Action === "Cancelled") {
            this.props.showResposeValue("error", "Patient Already In Surgery so unable to Cancel Appointment")
        }  else if (item.status !== "Approved" && Action === "Surgery Status") {
            this.props.showResposeValue("error", "Patient Not Approved")
        } else if((item.appointment_status !== "Arrived" && item.appointment_status !== "In Surgery" && item.appointment_status !== "Completed") && Action === "MovetoIP"){
            this.props.showResposeValue("error", "Patient not Arrived")
        }
        else {
            if (Action === "Complete") {
                this.setState({ isApproved: true }, () => { this.UpdateAppointmentStatus(item) })
            } else if (Action === "Cancelled") {
                this.setState({ showCancelPopup: true, rescheduledData: item })
            } else if (Action === "Surgery Status") {
                this.setState({ isApproved: false }, () => { this.UpdateAppointmentStatus(item) })
            } else if (Action === "Reschedule") {
                this.setState({ showReschedulePopup: true, rescheduledData: item })
            } else if(Action === "MovetoIP") {
                this.CheckAppointment(item)
            }
        }
    }

    renderActionValue = (image, title, value, colorValue) => {
        return (
            <View style={styles.StatValContainer}>
                <View style={styles.statValWrapper}>
                    <View style={styles.ValueView}>
                        <Text style={[styles.valueText, { color: colorValue }]}>
                            {value}
                        </Text>
                    </View>
                </View>
                <View style={styles.TitleView}>
                    <Text style={[styles.TitleText, { color: colorValue }]}>
                        {title}
                    </Text>
                </View>
            </View>
        );
    };

    renderCancelPopup = (data) => {
        return (
            <View>
                <View style={styles.CancelPopupContainer}>
                    <TouchableWithoutFeedback>
                        <View style={styles.PopupHeaderConatiner}>
                            <View style={{ flex: 1 }}>
                                <View style={styles.PopupHeaderWrapper}>
                                    <Text style={styles.HeaderTextStyle}>
                                        Enter Your Reason
                                    </Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({ showCancelPopup: false })
                                        }}
                                        style={{ fontSize: "1.95vw" }}
                                    >
                                        <Icon
                                            name="window-close-o"
                                            size={"1.10vw"}
                                            color={color.themeDark}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <TextInput
                                    multiline={true}
                                    placeholder={"Reason"}
                                    underlineColorAndroid="transparent"
                                    style={styles.TextInputStyle}
                                    placeholderTextColor={"grey"}
                                    autoCapitalize="none"
                                    value={this.state.CancellComments}
                                    onChangeText={(text) => {
                                        const Testvalue = text.match(/^ *$/) ? "" : text;
                                        this.setState({
                                            CancellComments: Testvalue,
                                        })
                                    }}
                                />
                                <Text
                                    style={styles.ErrorCancellComment}
                                >
                                    {this.state.ErrorCancellComment}
                                </Text>
                                <View style={styles.PopupBottomContainer}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            {
                                                this.state.CancellComments === '' ?
                                                    this.setState({
                                                        ErrorCancellComment:
                                                            "Please Enter The Reason",
                                                    }) : this.setState({
                                                        showCancelPopup: false, isCancelled: true
                                                    }, () => { this.UpdateAppointmentStatus(data) })
                                            }
                                        }}
                                        style={styles.ConfirmButtonView}
                                    >
                                        <Text style={styles.ConfirmButtonText}>
                                            Confirm
                                        </Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={styles.ClearButtonView}
                                        onPress={() => {
                                            this.setState({ CancellComments: "" })
                                        }}
                                    >
                                        <Text style={styles.ClearbuttonText}>Clear</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </View>
        )
    }

    renderHeaderView = () => {
        return (
            <View>
                <View style={[styles.titleContainer]}>
                    <View style={[styles.titleHeader]}>
                        <Text style={styles.appointmentDiv}>
                            {"OT Appointments"}
                        </Text>
                        <View style={[styles.datePickerComponent]}>
                            <Text style={styles.dateLable}>Select Date</Text>
                            <DatePicker
                                clearIcon={false}
                                format={"YYYY-MM-DD"}
                                value={this.state.datevalue}
                                style={{ borderColor: color.themeDark, borderWidth: 2, width: "10.1vw", fontSize: '1.1vw', height: "2.1vw" }}
                                onChange={(date) => {
                                    var fulldate = new Date(date);
                                    var converted_date = moment(fulldate).format("YYYY-MM-DD");
                                    this.setState({
                                        selectedDate: converted_date,
                                        datevalue: date
                                    }, () => {
                                        this.getAppointmentDetails()
                                    })
                                }}
                            />
                            <TouchableOpacity onPress={() => { this.setState({ datevalue: "", selectedDate: "" }, () => { this.getAppointmentDetails() }) }} style={{ backgroundColor: color.themeDark, height: "2.1vw", width: "1.8vw" }}>
                                <Image style={[styles.Image, { marginTop: ".2vw" }]} source={ImagePath.Close} />
                            </TouchableOpacity>
                            <View style={styles.printContainer} >
                                <TouchableOpacity
                                    style={styles.printTouchableStyle}>
                                    <Image style={styles.Image}
                                        source={ImagePath.Print} />
                                </TouchableOpacity>
                            </View>

                        </View>
                    </View>
                </View>
            </View >
        )
    }

    renderSubHeaderView = () => {
        return (
            <View style={styles.subHeaderContainer}>
                <TouchableOpacity style={{ display: "flex", flexDirection: "row" }} onPress={() => { this.setState({ AppointmentStatus: "" }, () => { this.getAppointmentDetails() }) }}>
                    <View style={[styles.todayPatientstyle]}>
                        <Text style={[styles.TodaysPatientText, { color: this.state.AppointmentStatus === "" ? color.themeDark : color.black }]}>Total Patients</Text>
                    </View>
                    <View style={{ paddingLeft: 5 }}>
                        <Text style={[styles.HeaderValueText, { color: this.state.AppointmentStatus === "" ? color.themeDark : color.black }]}>{this.state.totalPatientCount}</Text>
                    </View>
                </TouchableOpacity>
                <View style={[styles.arrivedStyle]}>
                    <TouchableOpacity onPress={() => { this.setState({ AppointmentStatus: "Approved" }, () => { this.getAppointmentDetails() }) }}>
                        {this.renderActionValue("", "Approved Patients", this.state.approved, this.state.AppointmentStatus === "Approved" ? color.themeDark : color.black)}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.setState({ AppointmentStatus: "Reschedule" }, () => { this.getAppointmentDetails() }) }}>
                        {this.renderActionValue("", "Rescheduled Patients", this.state.reschedule, this.state.AppointmentStatus === "Reschedule" ? color.themeDark : color.black)}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.setState({ AppointmentStatus: "No Show" }, () => { this.getAppointmentDetails() }) }}>
                        {this.renderActionValue("", "No Show", this.state.noShow, this.state.AppointmentStatus === "No Show" ? color.themeDark : color.black)}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.setState({ AppointmentStatus: "Cancelled" }, () => { this.getAppointmentDetails() }) }}>
                        {this.renderActionValue("", "Cancelled Patients", this.state.cancelled, this.state.AppointmentStatus === "Cancelled" ? color.themeDark : color.black)}
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderHeaderstart = () => {
        return (
            <View style={styles.headerStartContainer}>
                <View style={[styles.AppointmentTitleContainer]}>
                    <View style={{ alignItems: "flex-start", width: '100%' }} >
                        <Text style={[styles.appointmentTableHeadText, { paddingLeft: "1.80vw" }]}>Patient's Details</Text>
                    </View>
                </View>
            </View>
        )
    }
    renderHeader = () => {
        return (
            <View>
                <View style={{ width: '180%' }}>
                    <View style={[styles.AppointmentTitleContainer]}>
                        <View style={{ alignItems: "flex-start", width: '17%' }} >
                            <Text style={styles.appointmentTableHeadText}>Surgeon Doctor</Text>
                        </View>
                        <View style={{ alignItems: "flex-start", width: '13%' }} >
                            <Text style={styles.appointmentTableHeadText}>Surgery</Text>
                        </View>
                        <View style={{ alignItems: "flex-start", width: '25%' }} >
                            <Text style={styles.appointmentTableHeadText}>Package Details</Text>
                        </View>
                        {this.state.is_OPT || this.state.drType == "Ophthalmology"  ?<View style={{ alignItems: "center", width: '10%' }} >
                            <Text style={styles.appointmentTableHeadText}>Power</Text>
                        </View>: null}
                        <View style={{ alignItems: "center", width: '17%' }} >
                            <Text style={styles.appointmentTableHeadText}>Surgery Date/Time</Text>
                        </View>
                        <View style={{ alignItems: "flex-start", width: '18%' }} >
                            <Text style={styles.appointmentTableHeadText}>Anaesthesiatist</Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    renderHeaderend = () => {
        return (
            <View style={{ paddingRight: '1vw' }}>
                <View style={styles.AppointmentTitleContainer}>
                    <View style={{ alignItems: "center", width: '100%' }} >
                        <Text style={styles.appointmentTableHeadText}>Action</Text>
                    </View>
                </View>
            </View>
        )
    }

    renderScrollDatastart = () => {
        return (
            <View style={{ paddingLeft: '1vw' }}>
                {this.state.otAppointmentList.length > 0 && this.state.otAppointmentListPaginationValue ? this.state.otAppointmentListPaginationValue.map(item => {
                    return (
                        <View
                            style={[styles.Tableprofile,
                            {
                                borderBottomLeftRadius: 4, borderTopLeftRadius: 4, justifyContent: 'flex-start'
                            }]}>
                            <TouchableOpacity
                                style={styles.StartDataTouchstyle}
                                onPress={() => {
                                    this.props.PatientDetail(item, "oTVitals")
                                }}
                            >
                                <Image
                                    source={ImagePath.DefaultProfile}
                                    style={[Style.appointmentTableRowImg, { borderWidth: 2 }]}
                                />
                                <View style={{ marginLeft: 5 }}>

                                    <Tooltip placement="topLeft" title={item.patient_name}>
                                        <Text style={{ fontSize: "0.9vw", fontWeight: "500" }}>
                                            {item.patient_name.length > 15 ? item.patient_name.slice(0, 15) + ".." : item.patient_name}
                                        </Text>
                                    </Tooltip>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <Text style={{ fontSize: ".75vw", marginTop: ".3vw", marginBottom: ".3vw" }}>
                                            {item.uhid}
                                        </Text>
                                        <Text style={{ fontSize: ".75vw", fontWeight: "500", marginLeft: ".2vw" }}>
                                            {"(" + (item.age == null ? "-" : item.age) + "/" + (item.gender == null || item.gender === "" ? "-" : item.gender) + ")"}
                                        </Text>
                                    </View>
                                </View>

                            </TouchableOpacity>
                        </View>
                    )
                }) : null}
            </View>
        )
    }

    renderScrollData = () => {
        return (
            <View style={{ width: "100%" }}>
                {this.state.otAppointmentList.length > 0 && this.state.otAppointmentListPaginationValue ? this.state.otAppointmentListPaginationValue.map(item => {
                    return (
                        <Tooltip placement="top" title={item.reschedule_reason !== null ? item.reschedule_reason + "  Rescheduled-On: " + item.rescheduled_date.split("T")[0].split('-').reverse().join('-') : item.cancel_reason !== "" || item.cancel_reason !== null ? item.cancel_reason : null}>
                            <View
                                style={[styles.Tableprofile, { width: '180%' }]}>
                                <TouchableOpacity onPress={() => { this.props.PatientDetail(item, "oTVitals") }}
                                    style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: "center" }}>
                                    <View style={{ width: "17%", alignItems: "flex-start" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.surgeon_name === "" || item.surgeon_name === null ? "Nill" : item.surgeon_name}
                                        </Text>
                                    </View>
                                    <View style={{ width: "13%", alignItems: "flex-start" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.surgery_name === "" || item.surgery_name === null ? "Nill" : item.surgery_name}
                                        </Text>
                                    </View>
                                    <View style={{ width: "25%", alignItems: "flex-start" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.package_name === "" || item.package_name === null ? "Nill" : item.package_name}
                                        </Text>
                                    </View>
                                    {this.state.is_OPT || this.state.drType == "Ophthalmology"  ?<View style={{ width: "10%", alignItems: "center" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.iol_power === "" || item.iol_power === null ? "Nill" : item.iol_power}
                                        </Text>
                                    </View>:null}
                                    <View style={{ width: "17%", alignItems: "center" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.surgery_date === "" || item.surgery_date === null ? "Nill" : moment(item.surgery_date).format("DD/MM/YYYY")}
                                        </Text>
                                        <Text style={{ fontSize: ".85vw" }} >
                                            {item.surgery_time === "" || item.surgery_time === null ? "Nill" : item.surgery_time}
                                        </Text>
                                    </View>
                                    <View style={{ width: "18%", alignItems: "flex-start" }}>
                                        <Text style={styles.TableBodyText} >
                                            {item.anaesthetist === "" || item.anaesthetist === null ? "Nill" : item.anaesthetist}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </Tooltip>
                    )
                }) : <View style={[styles.loaderStyle]}>
                    {this.state.pageLoading ?
                        <ActivityIndicator size="large" color={color.themeDark} /> : <Text style={{ fontSize: "2vw" }}>{"No Records.."}</Text>
                    }
                </View>}
            </View>
        )
    }

    renderScrollDataend = () => {
        return (
            <View style={{ paddingRight: '1vw' }}>
                {this.state.otAppointmentList.length > 0 && this.state.otAppointmentListPaginationValue ? this.state.otAppointmentListPaginationValue.map(item => {
                    return (
                        <View>
                            <View style={[styles.Tableprofile,
                            {
                                borderBottomRightRadius: 4, borderTopRightRadius: 4
                            }]}>
                                <View style={styles.ScrollDataEndWrapper}>
                                    <TouchableOpacity
                                        onPress={ () => { this.errorBox(item, "Complete") } }
                                        style={[styles.homeScreenSelectedStates, { backgroundColor: item.status === "Approved" ? "#34B704" : this.state.isServiceType === "OT" ? item.is_ot_approved == true ? "rgb(0, 255, 0)" : color.themeDark : this.state.isServiceType === "FO" ? item.is_fo_approved == true ? "rgb(0, 255, 0)" : color.themeDark : (this.state.isServiceType === "OPT" || this.state.isServiceType == "DB" || this.state.isServiceType == "DOCTOR" ||  this.state.isServiceType == "PD" ||  this.state.isServiceType == "OBGYN") ? item.is_doctor_approved == true ? "rgb(0, 255, 0)" : color.themeDark : this.state.isServiceType === "COUNSELLOR" ? this.state.consellorApproved ? "rgb(0, 255, 0)" : color.themeDark : color.themeDark }]}>
                                        <Tooltip placement="top" title={ item.status === "Approved" ? "Approved" : this.state.isServiceType === "OT" ? item.is_ot_approved == true ? "OT Approved" : "Approve" : this.state.isServiceType === "FO" ? item.is_fo_approved == true ? "FO Approved" : "Approve" : (this.state.isServiceType === "OPT" || this.state.isServiceType == "DB" || this.state.isServiceType == "DOCTOR" ||  this.state.isServiceType == "PD" ||  this.state.isServiceType == "OBGYN") ? item.is_doctor_approved == true ? "Doctor Approved" : "Approve" : this.state.isServiceType === "COUNSELLOR" ? this.state.consellorApproved ? "Counsellor Approved" : "Approve" : "Approve"}>
                                            <Image
                                                source={ImagePath.Completed}
                                                style={[styles.appointmentTableStatusView]} />
                                        </Tooltip>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => { this.errorBox(item, "Cancelled") }}
                                        style={[styles.homeScreenSelectedStates, { backgroundColor: item.status === "Cancelled" ? "#E20000" : color.themeDark }]}>
                                        <Tooltip placement="top" title={"Cancelled"}>
                                            <Image
                                                source={ImagePath.Close}
                                                style={[styles.appointmentTableStatusView]} />
                                        </Tooltip>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => { this.errorBox(item, "Surgery Status") } }
                                        style={[styles.homeScreenSelectedStates, { backgroundColor: item.appointment_status === "Arrived" ? "#F8DB00" : item.appointment_status === "In Surgery" ? "rgb(0, 255, 0)" : item.appointment_status === "Completed" ? "#34B704" : color.themeDark }]}>
                                        <Tooltip placement="top" title={"Surgery Status" + (item.appointment_status == null ? "" : "-" + item.appointment_status)}>
                                            <Image
                                                source={ImagePath.Arrived}
                                                style={[styles.appointmentTableStatusView]} />
                                        </Tooltip>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => { this.errorBox(item, "Reschedule") }}
                                        style={[styles.homeScreenSelectedStates, { backgroundColor: item.status === "Reschedule" ? "#FF9300" : color.themeDark }]}>
                                        <Tooltip placement="top" title={item.status === "Reschedule" ? "Rescheduled" : "Reschedule"}>
                                            <Image
                                                source={ImagePath.Reschedule}
                                                style={[styles.appointmentTableStatusView]} />
                                        </Tooltip>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => { this.setState({ showHistoryPopup: true, rescheduledData: item }) }}
                                        style={[styles.homeScreenSelectedStates]}>
                                        <Tooltip placement="top" title={"History"}>
                                            <Image
                                                source={ImagePath.HistoryIcon}
                                                style={[styles.appointmentTableStatusView]} />
                                        </Tooltip>
                                    </TouchableOpacity>
                                    {this.state.isServiceType === "FO" ?
                                        <TouchableOpacity
                                            onPress={() => { this.errorBox(item, "MovetoIP") }}
                                            style={[styles.homeScreenSelectedStates, { backgroundColor: item.ip_admission > 0 ? "#F8DB00" : color.themeDark }]}>
                                            <Tooltip placement="top" title={item.ip_admission > 0 ? "In IP" : "Move to IP"}>
                                                <Image
                                                    source={ImagePath.MoveToIP}
                                                    style={{ height: '1.4vw', width: '1.4vw' }} />
                                            </Tooltip>
                                        </TouchableOpacity>
                                        : null}
                                </View>
                            </View>
                        </View>
                    )
                }) : null}
            </View>
        )
    }

    render() {
        return (
            <View style={{ zIndex: -5 }}>
                <View style={{ zIndex: -1 }}>
                    {this.renderHeaderView()}
                </View>
                <View>
                    {this.renderSubHeaderView()}
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: "78.5vh" }}>
                    <View style={{ display: "flex", flexDirection: 'row', width: '100%' }}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "20%" }}>
                            <View style={{ flexDirection: "column", width: '100%', zIndex: 1 }}>
                                {this.renderHeaderstart()}
                                {this.renderScrollDatastart()}
                            </View>
                        </ScrollView>
                        <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} horizontal={true} style={{ width: "60%" }}>
                            <View style={{ flexDirection: "column", width: '130%', zIndex: -1 }}>
                                {this.renderHeader()}
                                {this.renderScrollData()}
                            </View>
                        </ScrollView>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "20%" }}>
                            <View style={{ flexDirection: "column", width: '100%', zIndex: 1 }}>
                                {this.renderHeaderend()}
                                {this.renderScrollDataend()}
                            </View>
                        </ScrollView>
                    </View>
                </ScrollView>
                <View style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                    {this.state.otAppointmentList.length > 0 ?
                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.otAppointmentList}
                        /> : null}
                </View>
                <View style={{ position: "absolute", zIndex: 5 }}>
                    {this.state.showCancelPopup == true ?
                        this.renderCancelPopup(this.state.rescheduledData)
                        : null}
                </View>
                <View style={{ position: "absolute", zIndex: 5 }}>
                    {this.state.showReschedulePopup == true ?
                        <OTMReschedule PopupClose={this.ClosePopup} PatientData={this.state.rescheduledData} ServiceType={this.state.isServiceType} ChangedDate={this.onChangeDate} ChangedTime={this.onChangeTime} IsReschedule={this.RescheduleAppointment} isOPT={this.state.is_OPT} DrType={this.state.drType} />
                        : null}
                </View>
                <View style={{ position: "absolute", zIndex: 5 }}>
                    {this.state.showHistoryPopup == true ?
                        <OTMHistory PopupClose={this.ClosePopup} PatientData={this.state.rescheduledData} />
                        : null}
                </View>
            </View>
        )
    }
}
const styles = StyleSheet.create({
    titleContainer: {
        width: "100%",
        height: "3.3vw",
        boxShadow: "0px 3px 6px #00000029",
        borderBottomEndRadius: "15vw",
        borderBottomLeftRadius: "15vw",
        justifyContent: 'center'
    },
    titleHeader: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginHorizontal: "5vw"
    },
    appointmentDiv: {
        fontWeight: "500",
        fontSize: '1.2vw',
        minWidth: "fit-content"
    },
    datePickerComponent: {
        marginRight: '3vw',
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'center'
    },
    dateLable: {
        fontWeight: "500",
        fontSize: '.95vw',
        marginRight: '.2vw'
    },
    Image: {
        width: "1.7vw",
        height: "1.75vw",
    },
    subHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "flex-end",
        height: '5vw',
        alignItems: 'center'
    },
    todayPatientstyle: {
        width: "4vw",
        marginTop: ".65vw"
    },
    arrivedStyle: {
        flexDirection: "row",
        marginLeft: "2.5vw"
    },
    StatValContainer: {
        flexDirection: "column",
        marginRight: "1.3vw"
    },
    statValWrapper: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    ValueView: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    TitleView: {
        justifyContent: "center",
        alignItems: "center"
    },
    printContainer: {
        marginLeft: "3vw",
        alignSelf: "center",
        alignItems: "center"
    },
    printTouchableStyle: {
        display: 'flex',
        width: "2.2vw",
        height: "2.2vw",
        borderRadius: ".25vw",
        backgroundColor: color.themeBackgroud,
        alignItems: "center",
        justifyContent: 'center'
    },
    TodaysPatientText: {
        fontSize: "0.91vw",
        fontWeight: "500",
        color: color.black
    },
    HeaderValueText: {
        fontSize: "2.6vw",
        fontWeight: "500",
        color: color.black
    },
    AppointmentTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: color.appointmentTableHead,
        height: "3.25vw",
        marginTop: ".32vw"
    },
    ActionIcon: {
        height: "1.3vw",
        width: "1.3vw"
    },
    homeScreenSelectedStates: {
        padding: ".6vw",
        backgroundColor: color.themeDark,
        borderRadius: "10vw",
        borderColor: "white",
        borderWidth: ".2vw",
        width: '2.5vw',
        height: '2.5vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    Tableprofile: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        height: '3.3vw',
        justifyContent: 'center'
    },
    valueText: {
        fontSize: "1.8vw", fontWeight: "500",
    },
    TitleText: {
        textAlign: "center", fontWeight: "500", fontSize: ".75vw"
    },
    headerStartContainer: {
        paddingLeft: '1vw',
        width: "100%",
        borderTopStartRadius: 4,
        borderBottomLeftRadius: 4
    },
    StartDataTouchstyle: {
        paddingLeft: "1.70vw",
        flexDirection: "row",
        alignItems: "flex-start"
    },
    TableBodyText: { fontSize: ".9vw" },
    ScrollDataEndWrapper: {
        width: '100%',
        backgroundColor: "transprant",
        paddingLeft: "1.2vw",
        zIndex: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appointmentTableStatusView: {
        height: "1.3vw",
        width: "1.3vw",
        tintColor: color.white
    },
    DisabledActionCancelled: {
        fontSize: '1vw',
        fontWeight: "500",
        color: "white",
        backgroundColor: "#ff0000",
        width: '8vw',
        height: '2.3vw',
        borderRadius: 5,
        marginLeft: '1vw',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center'
    },
    DisabledActionNoshow: {
        fontSize: '1vw',
        fontWeight: "500",
        color: "white",
        backgroundColor: "#ff8080",
        width: '8vw',
        height: '2.3vw',
        borderRadius: 5,
        marginLeft: '1vw',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center'
    },
    appointmentTableHeadText: {
        fontSize: ".9vw",
        fontWeight: "500",
        textAlign: "center"
    },
    CancelPopupContainer: {
        height: "48.15vw",
        width: "92.95vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    PopupHeaderConatiner: {
        backgroundColor: "white",
        width: "40%",
        borderRadius: "0.65vw",
        padding: "1.30vw",
        marginBottom: "6.83vw"
    },
    PopupHeaderWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    HeaderTextStyle: {
        color: "#505050",
        fontSize: "1.23vw",
        marginTop: "1vw",
        marginLeft: "12vw",
        fontWeight: '500'
    },
    TextInputStyle: {
        alignSelf: "center",
        padding: "0.65vw",
        color: color.black,
        width: "100%",
        marginTop: "1.30vw",
        marginBottom: "1vw",
        height: "10vw",
        borderRadius: "0.65vw",
        borderColor: "grey",
        borderWidth: 1, fontSize: "1.04vw"
    },
    PopupBottomContainer: { flexDirection: "row", justifyContent: "center" },
    ConfirmButtonView: {
        backgroundColor: color.themeDark,
        padding: "0.45vw",
        width: "7.81vw",
        justifyContent: "center",
        marginRight: ".32vw",
        borderRadius: "0.25vw",
    },
    ConfirmButtonText: {
        textAlign: "center",
        color: "white",
        fontSize: ".91vw"
    },
    ClearButtonView: {
        backgroundColor: "white",
        padding: "0.45vw",
        width: "7.81vw",
        justifyContent: "center",
        borderWidth: 1,
        marginLeft: ".32vw",
        borderColor: "grey",
        borderRadius: "0.25vw",
    },
    ClearbuttonText: {
        textAlign: "center",
        fontSize: ".91vw"
    },
    ErrorCancellComment: {
        marginBottom: "1vw",
        fontWeight: "600",
        color: "red",
        textAlign: "center",
    },
    NoRecordsText: {
        fontSize: "1.5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30vw",
        marginLeft: "16vw"
    },
    loaderStyle: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        height: "75vh",
        marginLeft: "10vw",
        marginTop: "-5vw"
    }
});
