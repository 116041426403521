//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component
const pageName = "isBirthHistoryNew"

class BirthHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,


            listOfDatas: [],
            getListofBirthHistory: {},
            hideEditIcon: true

        }
    }

    componentDidMount() {
        this.getBirthHistory()
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getBirthHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofBirthHistory, pageName)
        }, 100);
        
    }


    getBirthHistory() {
        var service_url = Constants.BIRTH_History_PD + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getBirthHistorySuccess,
            this.getBirthHistoryFailure
        );
    }

    getBirthHistorySuccess = success => {
        if (success.status == "success") {
            var data = success.data

            var booleanValues = []
            var stringVaues = []
            var hideEditIcon = true
            Object.keys(data).forEach((key) => {
                if (data[key]) {
                    hideEditIcon = false
                }
                if (typeof data[key] == "boolean" && key !== "pediatric_history_frozen") {
                    let prepareData = {}
                    if (key == "pregnancy_complication") {
                        prepareData = {
                            key: "Any complications during pregnancy?",
                            value: data[key] ? "Yes" : "No",
                            details: data.pregnancy_comp_desc
                        }
                    } else if (key == "after_birth_complication") {
                        prepareData = {
                            key: "Complications after birth?",
                            value: data[key] ? "Yes" : "No",
                            details: data.after_birth_comp_desc
                        }
                    } else if (key == "neonatal_period") {
                        prepareData = {
                            key: "Neonatal Period (Days)?",
                            value: data[key] ? "Yes" : "No",
                            details: data.neonatal_period_reason
                        }
                    }

                    booleanValues.push(prepareData)



                } else if (key !== "pregnancy_comp_desc" || key !== "after_birth_comp_desc" || key !== "neonatal_period_reason" ||
                    key !== "pediatric_history_frozen" || key !== "additional_notes" || key !== "created_date" || key !== "frozen_date" || key !== "entered_date"
                ) {
                    let prepareData = {}
                    if (key == "development" && data[key]) {
                        prepareData = {
                            key: "Development",
                            value: data[key],
                        }
                    } else if (key == "feeding_history" && data[key]) {
                        prepareData = {
                            key: "Feeding History",
                            value: data[key],
                        }
                    } else if (key == "mode_of_delivery" && data[key]) {
                        var type = ""
                        if (data[key] == 1) {
                            type = "Normal"
                        } else if (data[key] == 2) {
                            type = "C-Section"
                        } else if (data[key] == 3) {
                            type = "Assisted"
                        }

                        prepareData = {
                            key: "Mode of Delivery",
                            value: type,
                        }
                    } else if (key == "gestational_age" && data[key]) {
                        var type = ""
                        if (data[key] == 1) {
                            type = "Pre-Term"
                        } else if (data[key] == 2) {
                            type = "Full-Term"
                        } else if (data[key] == 3) {
                            type = "Post-Term"
                        }

                        prepareData = {
                            key: "Gestational Age",
                            value: type,
                        }
                    } else if (key == "birth_weight" && data[key]) {
                        prepareData = {
                            key: "Birth Weight (kgs)",
                            value:data[key]? data[key] + " kgs":"",
                        }
                    }
                    stringVaues.push(prepareData)
                }
            })



            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

            this.setState({
                getListofBirthHistory: data,
                listOfDatas: removeEmptyObj,
                hideEditIcon: hideEditIcon
            })


        }






    }
    getBirthHistoryFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={3}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }
    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }
    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} 
                    heading={"Birth History"}
                    editImage={this.state.listOfDatas?"pencil":""}
                // deleteImage={"trash"} 
                />
                {this.renderContent()}







            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default BirthHistory;
