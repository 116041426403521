import React, { Component } from "react";
import { View, ScrollView, Dimensions, Text, TextInput,TouchableOpacity,StyleSheet } from "react-native"
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import { CommonHistorySectionHeader, CommonButton } from "../BaseComponent"
import CommonDateFilter from "../BaseComponent"
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import Icon from "react-native-vector-icons/AntDesign";
import Pagination from './../common/Pagination';

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

export default class FollowUps extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterDate: {
                fromDate: "",
                toDate: ""
            },
            doctorId:"",
            patientName:"",
            followUpData: [],
            followUpListAfterPagination: [],
            isFillter: false,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor
        }
    }
    componentDidMount = () => {
        { this.searchFilterData() }
    }
    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterDate } = this.state;
        states["filterDate"] = date
        filterDate["fromDate"] = date.fromDate;
        filterDate["toDate"] = date.toDate;
        this.setState({ states, filterDate })

    }
    renderHeader = () => {
        return (
            <View style={{}}>
                {/* <CommonHistorySectionHeader
                    heading1={"Patient's Details"}
                    heading2={"Mobile No"}
                    heading3={"Appointment-Date"}
                    heading4={"Followup-Date"}
                    heading5={"Comments"}
                    noOfColumn={5}
                    columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}
                /> */}
              <View style={[styles.headingBackGround, { flexDirection: 'row'}]} >
                <View style={{ flex: 0.2 }}>
                  <Text style={{ fontSize: 15 }}>{"Patient's Details"}</Text>
                </View>
                <View style={{ flex: 0.2  }}>
                  <Text style={{ fontSize: 15 }}>{"Mobile No"}</Text>
                </View>
                <View style={{ flex: 0.2}}>
                  <Text style={{ fontSize: 15}}>{"Appointment Date"}</Text>
                </View>

                <View style={{ flex: 0.2}}>
                  <Text style={{ fontSize: 15}}>{"Follow-Up Date"}</Text>
                </View>

                <View style={{ flex: 0.2}}>
                  <Text style={{ fontSize: 15}}>{"Comments"}</Text>
                </View>
              </View>
            </View>
        )
    }
    renderData = (item,index) => {
        return (
            <View style={{flex:1,flexDirection:"row", flexWrap: 'wrap', paddingVertical:10,paddingHorizontal:25 }}>
                <Text style={{ flex: 0.2, fontSize: 13, color: '#000000', borderBottomWidth:1,borderBottomColor:color.lightGray }}>{item.patient_fname}</Text>
                <Text style={{ flex: 0.2, fontSize: 13, color: '#000000',borderBottomWidth:1,borderBottomColor:color.lightGray}}>{item.patient_Mobile_no}</Text>
                <Text style={{ flex: 0.2, fontSize: 13, color: '#000000', borderBottomWidth:1,borderBottomColor:color.lightGray}}>{item.appointment_date}</Text>
                <Text style={{ flex: 0.2, fontSize: 13, color: '#000000',borderBottomWidth:1,borderBottomColor:color.lightGray}}>{item.follow_up_date}</Text>
                <Text style={{ flex: 0.2, fontSize: 13, color: '#000000', justifyContent:'center', borderBottomWidth:1,borderBottomColor:color.lightGray}}>{item.comments}</Text>

            </View>
        )
    }
    render() {
        console.log(this.props.selectedClinicOrDoctor.id)
        return (
            <View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                    <View style={{ width: "65%", height: screenHeight - 65, backgroundColor: color.white }}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "100%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 }} bounces={false} bouncesZoom={false} >
                            {this.renderHeader()}
                            {this.showFollowUpList()}
                            {/* {this.state.followUpData.length > 0 && this.state.followUpData.map((item,index)=>{
                                return <View>{this.renderData(item,index)}</View>
                            })
                            } */}
                           

                        </ScrollView>
                        <View style={{ marginLeft: 30 , marginTop:10}}>

{
  this.state.followUpData && this.state.followUpData.length > 0 ?
    <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.followUpData} />
    :null
}
</View>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={false}
                        style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, }} >
                        <View style={[Style.rightView, { width: "100%" }]}>
                            <Text style={{ color: color.grey, fontSize: 20, fontWeight: "500", paddingVertical: 10 }}>{"Filter"}</Text>
                            <CommonHistorySectionHeader
                                heading1={"Follow-Up Date"}
                                heading2={"Patient Name"}
                                heading3={""}
                                noOfColumn={3}
                                columnSize={[0.5, 0.4, 0.1]}
                            />
                            <View style={{ marginTop: 10, flexDirection: "row", width: "100%" }}>
                                <View style={{ flex:0.5}}>
                                <CommonDateFilter defaultSelectedDateFilter={"all"} startDate={this.state.filterDate.fromDate} endDate={this.state.filterDate.toDate} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} filterDate={this.state.filterDate} />
                                </View>
                                <View style={{ flex:0.4  }}>
                                <TextInput
                                    // placeholder={"Patient Name"}
                                    underlineColorAndroid="transparent"
                                    style={{ height:30, borderWidth: 1, borderColor: color.grey, borderRadius: 4, maxWidth: 200 }}
                                    placeholderTextColor={color.lightGray}
                                    autoCapitalize="none"
                                    value={this.state.patientName}
                                    onChangeText={text => {
                                        this.setState({
                                            patientName: text
                                        })
                                    }}
                                />
                                </View>
                                

                            </View>
                            <View style={{  flexDirection: "row", marginTop: 50,alignSelf:"center",zIndex:-1 }}>
                                <TouchableOpacity onPress={()=>{
                                    
                                    this.setState({
                                        isFillter: true
                                    },()=>{
                                        
                                        this.searchFilterData();
                                     })
                                 }}
                                 style={{backgroundColor:color.themeDark,padding:10,borderRadius:4 }}>
                                    <Text style={{color:color.white}}>{"Search"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={()=>{
                                    
                                    this.setState({
                                        isFillter: true
                                    },()=>{
                                    
                                        this.searchFilterPrintData('print');
                                     })
                                 }}
                                 style={{backgroundColor:color.themeDark, padding:10,borderRadius:4 , marginLeft:20}}>
                                    <Text style={{color:color.white}}>{"Print"}</Text>
                                </TouchableOpacity>
                                {/* <TouchableOpacity onPress={()=>{
                                    
                                    this.setState({
                                        isFillter: true
                                    },()=>{
                                    
                                        this.searchFilterPrintData('export');
                                     })
                                 }}
                                 style={{backgroundColor:color.themeDark, padding:10,borderRadius:4 , marginLeft:20}}>
                                    <Icon name={"export"} size={ 20 } color={color.white} />
                                </TouchableOpacity> */}
                                {/* <CommonButton
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={"Search"}
                                    buttonType={"theme"}
                                    buttonIcon={""}
                                    rightIcon={true}
                                    buttonAction={  this.setState({
                                        isFillter: true
                                    },()=>{this.searchFilterData.bind(this)})}
                                    buttonKey={"searchFollowUp"} /> */}
                            </View>


                        </View>
                    </ScrollView>
                </View>
            </View>
        )
    }
    showFollowUpList(){

        if (this.state.followUpData && this.state.followUpData.length > 0 && this.state.followUpData.length <11) {
            return this.state.followUpData && this.state.followUpData.map((item, index) => {
      
              return this.renderData(item, index);
            })
          } 
         
          else if (this.state.followUpData && this.state.followUpData.length > 0) {
            return this.state.followUpListAfterPagination && this.state.followUpListAfterPagination.map((item, index) => {
      
              return this.renderData(item, index);
            })
          } else {
            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
              <Text style={{ color: color.placeholder, marginTop: 50 }}>{"No records to be shown"}</Text>
            </View>
          }
    }
    paginationChangedValues(data) {
        this.setState({
          followUpListAfterPagination: data
        })
      }
    searchFilterData = () => {
      
        if (this.state.isFillter) {
            var serviceUrl = Constants.OPTHALMOLOGY_SEARCH_FILTER + "?doctor_id=" + this.state.doctorId + "&follow_up_date=" + this.state.filterDate.fromDate + "&follow_up_to_date=" + this.state.filterDate.toDate + "&patient_name=" + this.state.patientName;
        } else {
            var serviceUrl = Constants.OPTHALMOLOGY_SEARCH_FILTER + "?clinic_id=" + this.state.selectedClinicOrDoctor.id;
        }

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getFollowUpSuccess,
            this.getFollowUpFailure
        );
    }

    searchFilterPrintData = (value) => {
        
        if( value === 'print' ){
            var serviceUrl = Constants.OPTHALMOLOGY_SEARCH_FILTER + "?clinic_id=" + this.state.selectedClinicOrDoctor.id+"&doctor_id=" + this.state.doctorId + "&follow_up_date=" + this.state.filterDate.fromDate + "&follow_up_to_date=" + this.state.filterDate.toDate + "&patient_name=" + this.state.patientName +"&export_type=pdf";
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                // this,
                this.getPrintSuccess,
                this.getPrintFailure,
                "pdf"
            );
        }
        else{
            var serviceUrl = Constants.OPTHALMOLOGY_SEARCH_FILTER  +"?export_type=excel&doctor_id=" + this.state.doctorId + "&follow_up_date=" + this.state.filterDate.fromDate + "&follow_up_to_date=" + this.state.filterDate.toDate + "&patient_name=" + this.state.patientName + "&clinic_id=" + this.state.selectedClinicOrDoctor.id;
            //var serviceUrl = Constants.OPTHALMOLOGY_SEARCH_FILTER + "?export_type=excel";
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                // this,
                this.exportCSVSuccessCallBack,
                this.exportCSVFailed,
                "excel"
            );
        }
        
    }

    getPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // console.log(file)
            window.open(fileURL);
        }
    };

    getPrintFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };

    exportCSVSuccessCallBack = response => {
        if (response) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }

    exportCSVFailed = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };

    getFollowUpSuccess = success => {
        if (success.status == "success") {
            this.setState({
                followUpData: success.data.follow_up
            });
            console.log('Suceesdata', this.state.followUpData)

        }
    }

    getFollowUpFailure = error => {
        console.log("err getFollowUpError" + JSON.stringify(error))

    }
    

}
const styles = StyleSheet.create({
    calendarView: {
      // borderColor: "#525252",
      borderColor: color.lightGray,
      borderWidth: 1,
      marginTop: 5,
      height: 35,
      borderWidth: 1,
      borderRadius: 10,
      alignItems: "center",
      flexDirection: "row",
      paddingLeft: 10,
      paddingLeft: 10,
      paddingRight: 10,
      width: "50%",
      marginRight: 10
    },
    calendarText: {
      color: color.lightGray,
      width: "90%"
    },
    headingBackGround: {
      backgroundColor: color.appointmentTableHead,
      paddingHorizontal: 25,
      paddingVertical: 10
    },
    dasboardcardcontentstyle: {
      flexDirection: "row",
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      backgroundColor: "white",
      shadowColor: "#090F36",
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 3,
      elevation: 5,
      borderRadius: 4,
      height: 60,
      minWidth: 120,
      paddingVertical: 7.5,
      paddingHorizontal: 10
    }
  })