import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { StyleSheet, Text, View, CheckBox, ScrollView, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { Select, Tooltip } from 'antd';
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';

class LocationAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
      selectedArea: [],
      selectedValue: [],
      LocationAnaData: [],
      dropDownValue: []
    }
  };
  componentDidMount() {
    this.getLocationAnalysis()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate,
        datavalue: [],
        selectedArea: [],
        selectedValue: [],
      }, () => {
        document.getElementById('citynames').value = null;
        this.getLocationAnalysis()
      })
    }
  }

  /* Location Analysis API Start */
  getLocationAnalysis = () => {
    var serviceUrl =
      Constants.LOCATION_ANALYSIS_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getLocationAnalysisSuccess,
      this.getLocationAnalysisFailure
    );

  }

  getLocationAnalysisSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        LocationAnaData: success.data
      })
    }else{
      this.props.Response(success.message)
    }
  };

  getLocationAnalysisFailure = (error) => {
    this.props.Response(error.message)
  };
  /* Location Analysis API end */

  /* Select Location Handle Change Function start */
  handleChange = (itemvalue) => {
    var selectedAreas = []
    var selectedValues = []
    for (let j = 0; j < itemvalue.length; j++) {
      for (let i = 0; i < this.state.LocationAnaData.length; i++) {
        if (this.state.LocationAnaData[i].city_id == itemvalue[j] && this.state.LocationAnaData[i].patient_count != 0) {
          selectedAreas.push(this.state.LocationAnaData[i].city_name)
          selectedValues.push(this.state.LocationAnaData[i].patient_count)
        }
      }
    }
    this.setState({
      selectedArea: selectedAreas,
      selectedValue: selectedValues,
      datavalue: itemvalue
    })
  }

  /* Select Location Handle Change Function end */

  /* Export Excel API start*/
  getLocationAnalysisExcel = () => {
    var serviceUrl =
      Constants.LOCATION_ANALYSIS_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getLocAnalysisSuccess,
      this.getLocAnalysisFailure,
      "excel"
    );
  }
  getLocAnalysisSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getLocAnalysisFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/


  render() {
    const data = {
      datasets: [
        {
          data: this.state.selectedValue,

          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(25, 25, 86)',
            'rgb(25, 99, 13)',
            'rgb(32, 76, 90)',
            'rgb(132, 77, 366)',
            'rgb(455, 99, 21)',
            'rgb(99, 255, 132)',
            'rgb(21, 43,222)',
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(25, 25, 86)',
            'rgb(25, 99, 13)',
            'rgb(32, 76, 90)',
            'rgb(132, 77, 366)',
            'rgb(455, 99, 21)',

          ],
        },
      ],
      labels: this.state.selectedArea,
    };

    return (
      <View>

        <View style={styles.TopView}>
          <Select
            id='citynames'
            mode="multiple"
            placeholder="Select Area"
            allowClear
            value={this.state.datavalue}
            maxTagTextLength={2}
            maxTagCount={1}
            onChange={this.handleChange}
            style={{
              marginLeft: '2vw',
              margin: '0.32vw',
              backgroundColor: "transparent",
              borderRadius: '0.26vw',
              flex: 0.5,
              width: '8vw',
              fontSize: '0.91vw',
              marginTop: '1vw'
            }}
          >
            {this.state.LocationAnaData.length > 0 ? this.state.LocationAnaData.map((list) => (
              list.patient_count != 0 ?

                <Option value={list.city_id} >{list.city_name}</Option>
                : null
            )) : null}
          </Select>
          <View style={{ margin: '1vw' }}>
            <TouchableOpacity onPress={this.getLocationAnalysisExcel}>
              <Tooltip title={'Export to Excel'}>
                <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
              </Tooltip>
            </TouchableOpacity>
          </View>
        </View>

        {this.state.LocationAnaData.length > 0 && this.state.LocationAnaData.patient_count != 0 ?
          this.state.selectedValue.length && this.state.selectedArea.length > 0 ?
            <View style={{
              // marginTop: '1.5vw'
            }}>
              <Pie
                width={'20vw'}
                height={'15vw'}
                data={data}
                options={
                  {
                    title: {
                      position: "bottom",
                      display: true,
                      text: ['Location Analysis'],
                      fontColor: ['rgb(4, 183, 177)']
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'right',
                        labels: {
                          font: {
                            size: 5
                          }
                        },
                      }
                    },
                  }}

              />
            </View>
            :
            <View style={styles.NoRecView}>
              <Text style={styles.NoRecText1}>Laocation Analysis</Text>
              <Text style={styles.NoRecText2}>Please select city to view data</Text>
            </View>

          : <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Laocation Analysis</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }


      </View>

    )
  }
}

export default LocationAnalysis;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '9vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PieChart: {
    width: '30vw',
    height: '40vh'
  },
  TopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5vw'
  }
})