//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import { OBGYNHistorySectionHeading } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from '../../../../styles/Color';
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";

// create a component
const pageName = "isObstetricalHistory";

class ObstetricalHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,


            obstetricalHistoryList: []

        }
    }

    componentWillReceiveProps(props){
        this.state.selectedPageName = props.selectedPageName;

        if(props.refreshRighSideComponentName == pageName ){
            this.getObstetricalHistory()
            this.props.refreshRighSideComponent("");
        }
    }


    selectedRightSideView(pageName) {
        this.props.selectedRightSideView(pageName);
    }
    editHistory() {
        this.props.editHistory(this.state.birthControlMethodList, pageName)
    }

    componentDidMount() {
        this.getObstetricalHistory()
    }

    tabelHeader(title, colorChange = false) {
        return (
            <View>
                <Text style={{
                    fontSize: colorChange ? 12 : 11, color: colorChange ? "black" : "black",
                    alignContent: "center", justifyContent: 'center',
                    alignItems: 'center', alignSelf: 'center',
                    textAlignVertical: 'center', textAlign: 'center'
                }}
                numberOfLines={10}>{title}</Text>
            </View>
        )
    }

    obstetricalHistoryHeader() {
        return (
            <View style={{ flexDirection: 'row', height: 50, borderWidth: 0.5, borderColor: "#888888", alignContent: "center",backgroundColor:"#E2E2E2" }}>
                <View style={[styles.headerStyle, { flex: 0.06 }]}>
                    {this.tabelHeader("Year")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.15 }]}>
                    {this.tabelHeader("Place Of Delivery / Abortion")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.12 }]}>
                    {this.tabelHeader("Duration of Pregnancy")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.09 }]}>
                    {this.tabelHeader("Hours of Labor")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.11 }]}>
                    {this.tabelHeader("Type Of Delivery")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.18 }]}>
                    {this.tabelHeader("Complications for Mother / Baby")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.07 }]}>
                    {this.tabelHeader("Gender")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.08 }]}>
                    {this.tabelHeader("Birth Weight")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.16 }]}>
                    {this.tabelHeader("Present Health")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.1 }]}>
                    {this.tabelHeader("Action")}
                </View>

            </View>
        )
    }

    renderHistoryList(item, index) {
        return (
            <View key={index} style={{
                flexDirection: 'row',// borderWidth: 0.5, 
                borderLeftColor: "#888888", borderRightColor: "#888888", borderBottomColor: "#888888",
                borderLeftWidth: 0.5, borderRightWidth: 0.5, borderBottomWidth: 0.5,
                alignContent: "center"
            }}>
                <View style={[styles.headerStyle, { flex: 0.06 }]}>
                    {this.tabelHeader(item.year_of_delivery_or_abortion, true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.15 }]}>
                    {this.tabelHeader(item.place_of_delivery_or_abortion, true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.12 }]}>
                    {this.tabelHeader(item.duration_of_pregnancy +" weeks", true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.09 }]}>
                    {this.tabelHeader(item.hours_of_Labor, true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.11 }]}>
                    {this.tabelHeader(
                        item.type_of_delivery=="VBAC" ?
                        "VBAC (Vaginal birth after cesarean)":item.type_of_delivery
                        , true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.18 }]}>
                    {this.tabelHeader(item.complications_for_mother_or_baby, true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.07 }]}>
                    {this.tabelHeader(
                        
                        
            item.child_gender == "m" ? "Male" : item.child_gender == "f" ? "Female" : item.child_gender == "t" ? "Trans" : "", true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.08 }]}>
                    {this.tabelHeader( item.child_birth_weight ? item.child_birth_weight + "kgs" : '', true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.16 }]}>
                    {this.tabelHeader(item.child_present_health, true)}
                </View>
                <View style={[styles.headerStyle, { flex: 0.1, flexDirection: 'row' ,alignItems:'center'}]}>
                    {this.renderIconButton("pencil", "edit",item)}
                    {this.renderIconButton("trash", "delete",item)}

                    {/* {this.tabelHeader("Action")} */}
                </View>
            </View>
        )
    }

    deleteHistory(item){
        var data = {
            "obstetrical_id":item.id
        }
        var service_url = Constants.OBSTETRICAL_HISTORY_DELETE

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.obstetricalHistoryDeleteSuccess,
            this.obstetricalHistoryDeleteFailure
        );
    }

    obstetricalHistoryDeleteSuccess = success =>{
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.getObstetricalHistory()
            // this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    obstetricalHistoryDeleteFailure = error =>{
        this.props.showResposeValue('error', error.message)
    }

    iconButtonAction(key,item){
        if(key == "edit"){
            this.props.editHistory(item, pageName)
        }else{
            this.deleteHistory(item)
        }
        
    }

    renderIconButton(name, key,item) {
        return (
            <TouchableOpacity onPress={() => { this.iconButtonAction(key,item) }}>
                <View style={Style.obgynHistoryEditIcon}>
                <Icon name={name} color={name == "pencil" ? color.newPencilColor : name == "trash" ? color.newTrashColor :color.themeDark} size={15} />
      
                    {/* <Icon name={name} color={color.themeDark} size={15} /> */}
                </View>
            </TouchableOpacity>
        )
    }


    obstetricalHistoryList() {
        return (


            <View>
                {
                    this.state.obstetricalHistoryList.map((item, index) => {
                        return this.renderHistoryList(item, index)
                    })
                }

            </View>


        )
    }

    obstetricalHistory() {
        return (
            <View style={{marginTop:15}}>
                {this.obstetricalHistoryHeader()}
                {this.obstetricalHistoryList()}
            </View>
        )
    }

    getObstetricalHistory() {
        var service_url = Constants.OBSTETRICAL_HISTORY_GET + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getObstetricalHistorySuccess,
            this.getObstetricalHistoryFailure
        );
    }

    getObstetricalHistorySuccess = success => {

        if (success.status == "success") {
            this.setState({
                obstetricalHistoryList: success.data.obstetrical_history
            })
        }

        // console.log(" <<~~~~~~>> " + JSON.stringify(success))
    }
    getObstetricalHistoryFailure = error => {
        console.log(" <<~~~~~~>>   ee " + JSON.stringify(success))

    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading

                    editHistory={this.editHistory.bind(this)}

                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                    heading={"Obstetrical History"}
                // editImage={"pencil"}
                // deleteImage={"trash"}
                />
                {this.obstetricalHistory()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop: 10,
    
    },
    headerStyle: {
        borderRightColor: "#888888", borderRightWidth: 0.5, justifyContent: 'center'
    }
});

//make this component available to the app
export default ObstetricalHistory;
