import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;

export const ASSI = (props) => {
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{ maxHeight: '70vh' }}
        >
            <View style={{ padding: 7 }}>
                <View style={{ alignSelf: 'center', alignContent: 'center' }}>
                    <Text style={styles.ASSIHead}>Informed Consent</Text>

                    <Text style={styles.FormHelperText}>(Provision for consent from relative if patient is unable to consent himself)</Text>

                </View>
                <View style={styles.FormsandCertificateTextView}>

                    <p style={{ color: 'black', fontSize: '0.9vw' }}>
                        I, <TextInput
                            style={styles.FormsTextBox}
                            value={props.clinicdetails.patient_name}
                            underlineColorAndroid="transparent"
                        />	wife/son/daughter of
                        <TextInput
                            style={styles.FormsTextBox}
                            value={props.assiFatherHusbandName}
                            onChangeText={(text) => {
                                props.onChangedDatas("assiFatherHusbandName", text)
                            }}
                            placeholder="Father/Husband name"
                            underlineColorAndroid="transparent"
                        />	,aged
                    <TextInput
                            style={styles.CertificateTextBox}
                            placeholder="Age"
                            value={props.clinicdetails.patient_age}

                            onChangeText={(text) => {
                                props.onChangedDatas("assiage", text)
                            }}
                            underlineColorAndroid="transparent"
                        />	yrs, residing at the
                 <TextInput
                            style={styles.FormsTextBox}
                            value={props.clinicdetails.patient_address}
                            placeholder="Home address"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiHomeAddress", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
	, acknowledge that I am admitting/submitting myself to the
                    <TextInput
                            value={props.hospitalName}
                            style={styles.FormsTextBox}
                            placeholder=" Hospital Name"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiHospitalName", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
    	Hospital for all necessary treatment, under the care and supervision of
        <TextInput
                            value={props.selectedValueClinic_Droctor.value}
                            style={styles.FormsTextBox}
                            placeholder="Doctor name"
                            onChangeText={(text) => {
                                // assisecondDoctorName
                                props.onChangedDatas("assiDoctorName", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
        	voluntarily without any coercion, misrepresentation, mistake, fraud or under influence.

            <br></br>
                        <br></br>

I have been advised to read, understand and consider this consent for spine surgery in a language I understand. I have been given the opportunity to ask for clarifications as I read and understand each section. I shall place my signature at the end to indicate that I have understood and accepted the same.

<br></br>
                        <br></br>

I fully understand my spine problem and the spine surgery is being performed with the aim of improving my condition. I authorize my surgeon, his associates and assistants – “the surgical team” to perform my surgery. I hereby also consent and agree to the performance of
<br></br>
                        <TextInput
                            style={styles.FormsandCertificateTextBox1}
                            placeholder="Operation/procedure name"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiOperationProcedure", text)
                            }}
                            underlineColorAndroid="transparent"
                        />		procedure upon myself by
                         <TextInput
                            style={styles.FormsandCertificateTextBox2}
                            placeholder="Doc name- performing surgery"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiDocnamePerforming", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
                        I understand that “the surgical team” may be able to comprehensively evaluate the problem within my spine during the surgical procedure. I consent to the alternate/ modified surgical procedure that the surgical team may decide to perform instead of originally planned procedure.
                        <br />
                        <br />

I understand that medical and non-medical personnel may be present to observe, assist or facilitate the surgery. I also consent to their attendance and participation.
<br />
                        <br />
I hereby consent and agree to the administration of General/Regional/Local Anaesthesia and or Sedation by
<br /> Dr.
<TextInput
                            // value={props.selectedValueClinic_Droctor.value}
                            style={styles.FormsTextBox}
                            placeholder="DoctorName"
                            onChangeText={(text) => {
                                props.onChangedDatas("assisecondDoctorName", text)
                            }}
                            underlineColorAndroid="transparent"
                        />	for the performance of Surgical Operations, Diagnostic or Therapeutic procedures, Investigations etc., and also to be supplemented with any other mode of anesthesia if necessary. I say that I have been explained the risks of consuming solids/ liquids including water eight hours prior to the indication of anesthesia.

                        <br />
                        <br />

I further say that I have had an opportunity, to ask all questions regarding my illness/treatment and the same have been satisfactorily answered by the attending Doctors. Other alternative methods of treatment have also been discussed. No warranty or guarantee has been given to me with respect to the results of the treatment by my attending Doctors, the Hospital/Nursing Home/Clinic or its Staff.
<br />
                        <br />
I confirm that I have checked the facilities available at this hospital/nursing home along with my family members and/or family doctor and found the same to be satisfactory.
<br />
                        <br />
I further consent and agree to the disposal by the hospital authority of any tissues or parts that may be removed in the course of my treatment at this hospital/ nursing home/ clinic.
<br />
                        <br />

I further consent and agree to the publication of my treatment for medical, scientific or educational purposes provided the pictures, videos or the descriptive text accompanying them do not reveal my identity.
<br />
                        <br />

I further consent and agree to being transferred to ICU/any other hospital/nursing home/clinic as considered appropriate by my doctor during anytime of the treatment, if my doctor feels it is beneficial for my recovery.
<br />
                        <br />

I further say that I have informed the doctor of all my previous illness, allergies, drug reaction, surgical procedures and all other facts relevant to the treatment. I shall not hold the hospital or the doctor responsible for the consequences that may arise due to non-disclosure of the same.
<br />
                        <br />
I have completely understood the aim of Surgery <TextInput
                            style={styles.FormsandCertificateTextBox3}
                            placeholder="Surgery name"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiSurgeryname", text)
                            }}
                            underlineColorAndroid="transparent"
                        /><br /><br />
I have also completely understood the possible complications of spine surgery which may result in immediate or delayed consequences. These are not limited to:
<br />
                        <br />
                        <p style={{ paddingLeft: '1.2vw', fontSize: '0.9vw' }}>
                            (a)  Risk of anesthesia (also consented separately) including drug reactions, prolonged ICU stay and risk to life as there are chances of Pulmonary Embolism, Cardiac arrest, Anaphylaxis.
    <br />
                            <br />
    (b)  Risk of catching a hospital acquired infection in any part of the body like chest infection, pneumonia.
    <br />
                            <br />
    (c)  Risk of wound discharge/ infection post-operatively which may need multiple dressings or multiple
   debridements and long term antibiotic therapy. I understand the risks and consequences associated with them
   including increased cost of stay and side effects of long-term infection and/or antibiotic therapy.
   <br />
                            <br />
   (d)  Risk of hypertrophic scar, keloid formation and excessive pigmentation at the operative site.The brain and spinal
  cord are covered by cerebro-spinal fluid, within a dural sac. Planned or unplanned opening of this dural sac may
  result in spinal fluid leakage, arachnoiditis, meningitis, abscess, weakness or paralysis and risk to life. Persistent
  fluid leak may require multiple procedures including dural repair surgeries ,lumbar drain placement, shunts,
  re-explorations and long-term antibiotic therapy.
   <br />
                            <br />
    (e) Risk of appearance of new neurological symptoms like numbness, weakness in limbs/whole body that may or may not recover.These may include the loss of bladder (urinary) and bowel control, sexual dysfunction
   (loss of erection, retrograde ejaculation, genital numbness, failure to achieve orgasm etc...).
    <br />
                            <br />
    (f) I understand there still may be neurological injury resulting in complete or partial paralysis, numbness, burning,
        paraesthesia with or without the use of neuro physiological spinal cord monitoring.
    <br />
                            <br />
    (g) Risk of excessive blood loss requiring single or multiple transfusions of blood and and/or its products. I also
   understand the risk of transmission of diseases by transfusing blood. Even though the screening tests may be
   negative,but still it doesn‟t eliminate the risk of transmission of same/other diseases.<br /><br />

    (h) I also understand that intraoperative fluoroscopy or X-rays may be used to mark levels and guide implant
   placement. In addition I know and understand the associated radiation exposure and its risks. Minimal Invasive
   Spine Surgeries may result in significantly higher radiation than routine (open)procedures.
    <br />
                            <br />

    (i) I understand there might be need for usage of implant for stabilization if need arises due to intraoperative findings
   like instability, infection. Implant related complications like allergic reaction, mal-placement, painful bursa
   formation, loosening, breakage, cut-out, migration and neurological injury. Thismayresult in loss of correction or
   failure of surgery. In addition, they may injure the surrounding muscular, tendons, ligaments, visceral and/or
   vascular structures. Any fixation device may fail, disintegrate or break including hooks, wires, rods, graft, cables,
   spacers,cages and  plates. Some of the implants may need to be removed because of various reasons; however,
   the surgeons may not be able to remove the implants despite best of results. The implants may also migrate to
   different parts of body by various routes like vascular and it may not be possible/ very risky to attempt the retrieval
   of the same. The latest technologies like O-arm, Robotics, CT based navigation, Piezoelectric pedicle probes may
   be used to aid the correct or near perfect placement of screws, however, this doesn‟t guarantee that the implant
   related complications as mentioned will not happen. I also understand the additional radiation exposure and its
   risks associated with such procedures.
    <br />
                            <br />
    (j) I understand that healing of the graft and fusion (union of two bones) is a biological process and depends upon the
   healing capacity of my body. There may be failure to achieve fusion despite best of efforts. This may result in
   implant failure and I may have to go subsequent revision surgeries which may or may not lead to fusion
   A successful fusion is aimed to reduce movement between the intended "fusionsegments/spinallevels" and without
   a fusion taking place the implants can not hold the bones permanently. A successful fusion on the other hand may
   result in excessive loading of the other spinal levels adjacent or remote from the fusion area and therefore may go
   into accelerated degeneration causing pain and sometimes need for revision surgery or extension of fusion. This is
   more common in diseases causing muscle imbalance like Parkinsonism and other diseases like osteoporosis
   I have been informed that during surgery it may be required to harvest bones from various part ofmybody like iliac
   crest. I have also been informed that the persistent pain at the donor bone area may or may not recover. I have
   also been informed that smoking, drug abuse, cigarettes, cigars, tobacco (in all forms), betel nuts, alcohol, various
   medicines like steroids, immunosuppressive & anti cancer drugs lead to decreased rates of fusion and eventually
   failure of surgery. I take the responsibility of not consuming any of such products before or after surgery. There
   may be a fracture at the donor site needing to operate and fix the fracture. In addition, there may be numbness,
   tingling persistent pain and visceral, vascular and neural damage at the operative site.
<br />
                            <br />
    (k) I also understand that bone graft can be taken from my body or from some other person (cadaver/ allograft) or
   artificially manufactured. I also have been explained the risk of disease transmission associated with
   allogenic bone graft/bone substitutes.
    <br />
                            <br />
    (l) I understand that I may need a prolonged hospital or ICU stay and admission as compare to the estimated number
   of days.
    <br />
                            <br />
    (m) I have been informed about the use of various orthotic devices that I need to wear as an immobilization device for
   a prescribed period after my surgery. I understand the restrictions that may be there post operatively for a short
   period of time/permanent like sitting on floor, using western toilet and modification of activities of daily living. I
   consent to follow the post-operative regime which is very essential for my recovery.
    <br />
                            <br />
    (n) I further agree for periodic follow-up as requested by the Doctors and will continue to inform them of my progree
   with X-rays taken periodically.
    <br />
                            <br />
    (o) I have been explained regarding recurrence of symptoms after surgery. This may occur immediately after surgery
   or in future.
    <br />
                            <br />
                        </p>
I also understand that because of unforeseen circumstances or because of any of the above complications, either singly or in combination, the hospital bill may exceed beyond a given estimate. In such cases I will be responsible for complete payment.
<br />
                        <br />

I have been explained the alternate treatment options and risks-benefits for the same. I voluntarily choose surgical option without any influence from anyone. I choose this surgical treatment/procedure without any pressure from the treating doctors/ surgical team. I state that I have also obtained the consent of my spouse/relatives for the said operations, procedures and treatment.
<br />
                        <br />

I hereby release
<TextInput
                            value={props.hospitalName}
                            style={styles.FormsTextBox}
                            placeholder="Hospital Name"
                            onChangeText={(text) => {
                                props.onChangedDatas("assiHospitalNames", text)
                            }}

                            underlineColorAndroid="transparent"
                        />
                        Hospital, the attending doctors and my surgeons from any liability whatsoever for any untoward of unfavorable consequence or results that may arise out of, or in the course of my treatment (including surgery and anesthesia).
                        <br />
                        <br />
The above has been explained to me and I have fully understood the same. I am signing this consent by my own free will and in a fully alert state of mind.
                    </p>
                    <Text style={styles.AdditionalView}>Additional Risks (if any):</Text>
                    <TextInput
                        multiline={true}
                        placeholder={"Enter Text"}
                        style={styles.AdditionalTextBox}
                        onChangeText={(text) => {
                            props.onChangedDatas("assiRisks", text)
                        }}
                        underlineColorAndroid="transparent"
                    />
                </View>

                <View>

                </View>

                <View style={{ alignSelf: 'flex-end', alignContent: 'flex-end' }}>

                    <p style={{ color: "#727272", fontSize: '1vw', fontWeight: '400', textAlign: 'center' }}>
                        Signature of the Patient
                        <TextInput
                            style={styles.SignViewTextBox}

                            underlineColorAndroid="transparent"
                        /></p>
                    <p style={{ color: "#727272", fontSize: '1vw', fontWeight: '400', textAlign: 'center' }}>
                        Signature of guardian if patient is minor, mentally unsound/unconscious  </p>
                </View>

                <View style={styles.SurgenDetailsView}>
                    <Text style={styles.SurgenText1}>Surgeon's Signature</Text>
                    <Text style={styles.SurgenText1}>Surgeon's Name</Text>
                    <p style={{color: 'black', marginBottom: '1.2vw', fontSize: '0.9vw'}}>Date Time</p>
                    <Text style={styles.SurgenText2}>Name and Signature of Witness</Text>
                    <Text style={[ styles.SurgenText1, {textAlign: 'center'}]}>Please note that the date of consent and surgery may vary because of unavoidable circumstances</Text>

                </View>
                <View style={styles.ButtonsView}>

                    <TouchableOpacity onPress={() => props.createASSIForm()} style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={{marginRight: '1vw'}} />
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => props.assipreviewAndPrint()}

                        disabled={props.formId ? false : true} style={[Style.certificateButton1, { backgroundColor: props.formId ? color.themeDark : color.disableComponentColor }]} >
                        <Icon name={"print"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={{marginRight: '1vw'}} />
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>


            </View>
        </ScrollView>
    )
}
const styles = StyleSheet.create({
        ASSIHead: { 
            fontSize: '1.17vw', 
            fontWeight: '400', 
            textAlign: 'center' 
        },
        FormHelperText: { 
            fontSize: '1vw', 
            fontWeight: '500', 
            textAlign: 'center', 
            color: 'red', 
            marginTop: '1vw' 
        },
        FormsandCertificateTextView: { 
            padding: '1.2vw', 
            marginTop: '2vw' 
        },
        FormsTextBox: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            marginLeft: '0.6vw',
            marginRight: '0.6vw'
        },
        CertificateTextBox: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            width: '7vw',
            marginLeft: '0.6vw',
            marginRight: '0.6vw'
        },
        FormsandCertificateTextBox1: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            marginRight: '0.6vw',
            width: '39vw'
        },
        FormsandCertificateTextBox2: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            marginRight: '0.6vw',
            marginLeft: '0.6vw',
            width: '39vw'
        },
        FormsandCertificateTextBox3: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            marginLeft: '0.6vw',
            marginRight: '0.6vw',
            width: '12vw'
        },
        AdditionalTextBox: {
            fontSize: '1vw',
            color: 'black',
            marginLeft: '0.6vw',
            marginRight: '0.6vw',
            height: '7vw',
            marginTop: '1.2vw'
        },
        SignViewTextBox: {
            fontSize: '1vw',
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            textAlign: 'center',
            marginLeft: '0.6vw',
            width: '18vw'
        },
        SurgenDetailsView: { 
            padding: '1.1vw', 
            marginTop: '2vw' 
        },
        SurgenText1: { 
            marginBottom: '1.2vw', 
            fontSize: '0.9vw' 
        },
        SurgenText2: { 
            marginBottom: '5.5vw',
            fontSize: '0.9vw' 
        },
        ButtonsView: { 
            flexDirection: 'row', 
            alignSelf: 'center', 
            alignContent: 'center', 
            alignItems: 'center', 
            marginTop:  '4.5vw' 
        },
        AdditionalView: {
            fontSize: '0.9vw'
        }
})