import React, { Component } from 'react';
import { View, Text, StyleSheet, Switch, ScrollView, Dimensions, TextInput, TouchableOpacity } from 'react-native';
import { NavigationTopHeader } from '../../../BaseComponent';
import { CommonHistorySectionHeader, CommonSectionHeader } from '../../../BaseComponent';
import { color } from '../../../../../styles/Color';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import CommonDateFilter from './../../../BaseComponent';
import moment from "moment";
import Pagination from '../../../../doctor/common/Pagination';
import { Tooltip } from 'antd';
import AsyncStorage from '../../../../../AsyncStorage';

const screenHeight = Dimensions.get("window").height;
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
const navigationHeader = [
  { label: "OT Stock Usage", value: "stockUsage" },
  { label: "OT Stock Usage Transactions", value: "stockUsageTrans" }
];
export default class StockUsageTrans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drugUsageTransaction: [],
      IOLUsageTransaction: [],
      drugUsageAfterPagination: [],
      IOLUsageAfterPagination: [],
      brandName: "",
      genericName: "",
      IOLBrandName: "",
      IOLModelName: "",
      filterDataDrug: {
        fromDate: converted_date,
        toDate: converted_date
      },
      filterDataIOL: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isClearInt: 0,
      isClear: false
    };
  }
  async componentDidMount() {
    this.getStockTransaction()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        is_OPT : loggedInDataObj.is_OPT
    },() => {
        this.setState({
          toggleSwitchFlag : this.state.is_OPT ? false : true
        })
    })
}
  getStockTransaction = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      drugUsageTransaction: [],
      IOLUsageTransaction: [],
      drugUsageAfterPagination: [],
      IOLUsageAfterPagination: [],
    })
    var brand_name = this.state.brandName ? this.state.brandName : ""
    var IOLBrandName = this.state.IOLBrandName ? this.state.IOLBrandName : ""

    if(this.state.toggleSwitchFlag) {
      var url = Constants.STOCK_DRUG_USAGE_TRANSACTION + "?brand_name=" + brand_name + "&generic_name=" + this.state.genericName + "&from_date=" + this.state.filterDataDrug.fromDate + "&to_date=" + this.state.filterDataDrug.toDate;
    } else {
      var url = Constants.STOCK_IOL_USAGE_TRANSACTION + "?brand_name=" + IOLBrandName + "&model_name=" + this.state.IOLModelName + "&from_date=" + this.state.filterDataIOL.fromDate + "&to_date=" + this.state.filterDataIOL.toDate;
    }
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getStockTransactionSuccess,
        this.getStockTransactionFailure
      );
  }

  getStockTransactionSuccess = (response) => {
      if (response && response.status === "success") {
        if(this.state.toggleSwitchFlag) {
          this.setState({
            drugUsageTransaction: response.data,
            isClear: false
          })
        } else {
          this.setState({
            IOLUsageTransaction: response.data,
            isClear: false
          })
        }
      }
  }
  getStockTransactionFailure = response => {
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize }}>{title}</Text>
      </View>)
  }
  renderHeaderView = () =>{
    return(
      <View>
        {this.state.toggleSwitchFlag ? 
         <View style={{
          flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
          backgroundColor: color.sectionHeadingDarkColor, padding: "0.8vw", borderRadius: 4
        }}>
          {this.getBillTableTitle('Date', 0.125, "0.9vw")}
          {this.getBillTableTitle('Brand Name', 0.15, "0.9vw")}
          {this.getBillTableTitle('Generic Name', 0.15, "0.9vw")}
          {this.getBillTableTitle('Batch No', 0.15, "0.9vw")}
          {this.getBillTableTitle('Dos Type', 0.125, "0.9vw")}
          {this.getBillTableTitle('Dos Strength', 0.125, "0.9vw")}
          {this.getBillTableTitle('Used Qty', 0.1, "0.9vw")}
          {this.getBillTableTitle('Avl Qty', 0.075, "0.9vw")}
       
        
        </View> : 
          <View style={{
            flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
            backgroundColor: color.sectionHeadingDarkColor, padding: "0.8vw", borderRadius: 4
          }}>
            {this.getBillTableTitle('Date', 0.15, "0.9vw")}
            {this.getBillTableTitle('Brand Name', 0.15, "0.9vw")}
            {this.getBillTableTitle('Model Name', 0.15, "0.9vw")}
            {this.getBillTableTitle('Batch No', 0.15, "0.9vw")}
            {this.getBillTableTitle('IOL Type', 0.125, "0.9vw")}
            {this.getBillTableTitle('IOL Power', 0.1, "0.9vw")}
            {this.getBillTableTitle('Used Qty', 0.1, "0.9vw")}
            {this.getBillTableTitle('Avl Qty', 0.075, "0.9vw")}
          </View>
        }
      </View>
    )
  }
  renderToggleView = () => {
    return (
      <View
        style={styles.toggleContainer}
      >
        <Text style={[styles.toggleTextStyle, {color: !this.state.toggleSwitchFlag ? color.themeDark : color.black}]}>{"IOL"}</Text>
        <Switch
          style={styles.switchStyle}
          thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
          onValueChange={() => {
            var filterDataDrug = {
              fromDate: converted_date,
              toDate: converted_date
            }
           var filterDataIOL = {
              fromDate: converted_date,
              toDate: converted_date
            }
            this.setState({
              toggleSwitchFlag: this.state.is_OPT ? !this.state.toggleSwitchFlag : true,
              brandName: "",
              genericName: "",
              IOLBrandName: "",
              IOLModelName: "",
              isClear: true,
              filterDataDrug: filterDataDrug,
              filterDataIOL: filterDataIOL
            }, () => {
                this.getStockTransaction()
                this.setState({
                  isClearInt: 1
                }, () => { this.commonDatePicker()
                })
              })
          }}
          value={this.state.toggleSwitchFlag}
        />
        <Text style={[ styles.toggleTextStyle, {color: this.state.toggleSwitchFlag ? color.themeDark : color.black} ]}>{"Drug"}</Text>
      </View>
    );
  };
  renderDataView = () => {
    return(
    <View>
     { this.state.toggleSwitchFlag ? 
      <View>
       { this.state.drugUsageAfterPagination && this.state.drugUsageAfterPagination.length > 0 ? 
          this.state.drugUsageAfterPagination && this.state.drugUsageAfterPagination.map((item, index) => {
           let date = moment(item.date).format("DD-MM-YYYY")
          return (
            <View style={styles.dataContainer}>
              <View style={{ flex: 0.125 }}><Text style={styles.TableDataText}>{date}</Text></View>
              <View style={{ flex: 0.15 }}>
                <Tooltip placement="topLeft" title={item.brand_name}>   
                 <Text style={styles.TableDataText}>{item.brand_name.length > 10 ? item.brand_name.slice(0,10) + ".." : item.brand_name}</Text>
                 </Tooltip> 
              </View>
              <View style={{ flex: 0.15 }}>
                <Tooltip placement="topLeft" title={item.generic_name}>   
                 <Text style={styles.TableDataText}>{item.generic_name.length > 10 ? item.generic_name.slice(0,10) + ".." : item.generic_name}</Text>
                 </Tooltip> 
              </View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.batch_no}</Text></View>
              <View style={{ flex: 0.125 }}><Text style={styles.TableDataText}>{item.dosage_type}</Text></View>
              <View style={{ flex: 0.125 }}><Text style={styles.TableDataText}>{item.dosage_strength}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.usage_quantity}</Text></View>
              <View style={{ flex: 0.075 }}>
                 <Tooltip placement="topLeft" title={item.avaliable_quantity}>   
                  <Text style={styles.TableDataText}>{item.avaliable_quantity.length > 4 ? item.avaliable_quantity.slice(0,4) + ".." : item.avaliable_quantity}</Text>
                 </Tooltip> 
              </View>
            </View>

          )
        }) :
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
          <Text style={{ color: color.placeholder, marginTop: 50 }}>{"No records to be shown"}</Text>
        </View>
        }
      </View> :
      <View>
       { this.state.IOLUsageAfterPagination && this.state.IOLUsageAfterPagination.length > 0 ?  
         this.state.IOLUsageAfterPagination && this.state.IOLUsageAfterPagination.map((item, index) => {
          let date = moment(item.date).format("DD-MM-YYYY")
          return (
            <View style={styles.dataContainer}>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{date}</Text></View>
              <View style={{ flex: 0.15 }}>
                 <Tooltip placement="topLeft" title={item.brand_name}>   
                  <Text style={styles.TableDataText}>{item.brand_name.length > 10 ? item.brand_name.slice(0,10) + ".." : item.brand_name}</Text>
                 </Tooltip> 
              </View>
              <View style={{ flex: 0.15 }}>
                 <Tooltip placement="topLeft" title={item.model_name}>   
                  <Text style={styles.TableDataText}>{item.model_name.length > 10 ? item.model_name.slice(0,10) + ".." : item.brand_name}</Text>
                 </Tooltip> 
              </View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.batch_number}</Text></View>
              <View style={{ flex: 0.125 }}>
                 <Tooltip placement="topLeft" title={item.iol_type}>   
                  <Text style={styles.TableDataText}>{item.iol_type.length > 10 ? item.iol_type.slice(0,10) + ".." : item.iol_type}</Text>
                 </Tooltip> 
              </View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.iol_power}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.usage_quantity}</Text></View>
              <View style={{ flex: 0.075 }}><Text style={styles.TableDataText}>{item.avil_quantity}</Text></View>

            </View>
          )
        }) : 
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
        <Text style={{ color: color.placeholder, marginTop: 50 }}>{"No records to be shown"}</Text>
       </View> }
      </View>
     }
      </View>  
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
  }
  paginationChangedValues(data) {
   if(this.state.toggleSwitchFlag) { 
    this.setState({
      drugUsageAfterPagination: data
    })
   } else {
    this.setState({
      IOLUsageAfterPagination: data
    })
   }
   
  }
  renderPaginationView = () => {
    return (
      <View style={styles.paginationContainer}>
        { this.state.toggleSwitchFlag ? 
         <View>
          {this.state.drugUsageTransaction && this.state.drugUsageTransaction.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalItems={this.state.drugUsageTransaction}
              paginationCurrentPage={this.paginationCurrentPage.bind(this)}
            /> : null
          }
         </View> : 
         <View>
         {this.state.IOLUsageTransaction && this.state.IOLUsageTransaction.length > 0 ?
           <Pagination
             paginationChangedValues={this.paginationChangedValues.bind(this)}
             totalItems={this.state.IOLUsageTransaction}
             paginationCurrentPage={this.paginationCurrentPage.bind(this)}
           /> : null
         }
        </View>
       }
      </View>
    )
  }
  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var {filterData} = this.state;
    this.setState({ 
      states,
      filterDataDrug: date,
      filterDataIOL: date,
      isClearInt: 0
    })

}

  commonDatePicker = () => {
    let startDate = this.state.toggleSwitchFlag ? this.state.filterDataDrug.fromDate : this.state.filterDataIOL.fromDate;
    let endDate = this.state.toggleSwitchFlag ? this.state.filterDataDrug.toDate : this.state.filterDataIOL.toDate;

    return(
     <View style = {{ zIndex: 1 }}>
     { this.state.isClear && this.state.isClearInt === 0 ? null :
      this.state.isClear && this.state.isClearInt === 1 ?   
      <CommonDateFilter 
      removeObject={"all"} 
      defaultSelectedDateFilter={"Today"} 
      startDate={startDate} 
      endDate={endDate} 
      // clearPress={this.state.isclear}
      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
      filterDate={this.state.filterDataDrug} /> : 
      <CommonDateFilter 
      removeObject={"all"} 
      defaultSelectedDateFilter={"Today"} 
      startDate={startDate} 
      endDate={endDate} 
      // clearPress={this.state.isclear}
      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
      filterDate={this.state.filterDataDrug} /> }
    </View>   
    )  
  }
  clearData = () => {

    var filterDataDrug = {
      fromDate: converted_date,
      toDate: converted_date
    }
   var filterDataIOL = {
      fromDate: converted_date,
      toDate: converted_date
    }
    this.setState({
      isClear: true,
      filterDataDrug: filterDataDrug,
      filterDataIOL: filterDataIOL,
      brandName: "",
      genericName: "",
      IOLBrandName: "",
      IOLModelName: ""
    }, () => {
      this.getStockTransaction()
      this.setState({
        isClearInt: 1
      }, () => { this.commonDatePicker()
      })
    })

  }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"stockUsageTrans"}
          isNavigationBorder={true}
        />
         {this.state.is_OPT ? this.renderToggleView() : null}
        <View style={[styles.container,{ flexDirection: "row"}]}> 
         <View style={styles.rightContainer}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.rightContainerScroll} bounces={false} bouncesZoom={false} >
          {this.renderHeaderView()}
          {this.renderDataView()}   
          {this.renderPaginationView()}
          </ScrollView>
        </View>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.leftContainer} bounces={false} bouncesZoom={false} >
             <CommonSectionHeader heading={"Filter"}  />
             { this.state.toggleSwitchFlag ? 
            <View style= {{ margin: "1vw" }}>
             {this.commonDatePicker()}  
             <View style={{ flexDirection: "row", flex: 1, zIndex: -1 }}>
             {this.renderSearchTextBox("Brand Name", "brandName", this.state.brandName, 0.5)}
             {this.renderSearchTextBox("Generic Name", "genericName", this.state.genericName, 0.5)}
             </View>
             </View> :
             <View style= {{ margin: "1vw" }}>
              {this.commonDatePicker()}  
             <View style={{ flexDirection: "row", flex: 1 }}>
             {this.renderSearchTextBox("Brand Name", "IOLBrandName", this.state.IOLBrandName, 0.5)}
             {this.renderSearchTextBox("Model Name", "IOLModelName", this.state.IOLModelName, 0.5)}
            </View>
            </View>
            }
            <View style={styles.ButtonContainer}>
            <TouchableOpacity style={[styles.addButton,{paddingHorizontal: "1vw"}]} onPress={() => {this.getStockTransaction() }}>
             <Text style={styles.addText}>Search</Text>
            </TouchableOpacity> 
            <TouchableOpacity style={[styles.addButton,{paddingHorizontal: "1vw", marginLeft: "1vw"}]} onPress={() => { this.clearData() }}>
             <Text style={styles.addText}>Clear</Text>
            </TouchableOpacity> 
            </View>
          </ScrollView>
        </View>  
      </View>
    );
  }
  changeTextBoxValues = (text, key) => {
    var states = this.state
    states[key] = text
    this.setState({
      states
    })
  }
  renderSearchTextBox = (placehold, stateKey, value, flex ) => {
    return(
      <View style={{ flex: flex, margin: "1vw" }}>
         <Text style={styles.searchBoxText}>{placehold}</Text>
          <TextInput
            value={value}
            onChangeText={(text) => { this.changeTextBoxValues(text, stateKey) }}
            style={styles.textBox}
          />
      </View>
    )
  }
} 
const styles = StyleSheet.create({
  container : {
    margin: "1vw",
    height: screenHeight - 65
  },
  textInput: {
   padding: "0.5vw",
   margin: "1vw",
   backgroundColor: color.white,
   borderColor: "#CDD1D5",
   borderRadius: 4,
   borderWidth: 1,
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingVertical: "0.75vw",
    justifyContent: 'center',
    flex: 1,
    padding:'0.8vw'
    
  },
  TableDataText:{
    fontSize:'1vw'
  },
  addText: {
    color: color.white,
    fontSize: "1vw"
   },
   addButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.3vw"
   },
   rightContainer: { width: "65%", height: screenHeight-65, backgroundColor: color.white, margin: "0.5vw" },
   rightContainerScroll: { width: "100%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 },
   paginationContainer: { marginHorizontal: 10 },
   leftContainer: { width: "35%", backgroundColor: color.aliceBlue, height: screenHeight - 65, zIndex: -1, padding: "1vw" },
   searchBoxText: { position: 'absolute', backgroundColor: color.aliceBlue, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize:"0.8vw" },
   textBox: { paddingLeft: "0.5vw", borderColor: color.lightGray, borderWidth: 1, marginTop: "0.5vw", borderRadius: "0.3vw" , padding:"0.5vw"},
   ButtonContainer: { flexDirection: "row", flex: 1, marginTop: "2vw",alignItems: "center", alignSelf: "center", justifyContent: "center", zIndex: -1 },
   toggleContainer: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    margin: "0.5vw",
  },
  switchStyle: {
    width: "1vw",
    height: "1.2vw",
    marginTop: "0.5vw",
    marginHorizontal: "0.5vw",
  },
  toggleTextStyle: {
    fontSize: "1.2vw"
  }
})

