import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,Picker,TextInput,ScrollView
} from "react-native";
import { CommonHistorySectionHeader, NavigationTopHeader } from "../BaseComponent";
import { color } from "../../../styles/Color";
import { Radio, Checkbox } from "antd";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import AsyncStorage from "../../../AsyncStorage";
import ImagePath from "../../../utils/ImagePaths";
import { ScreenHeight } from "react-native-elements/dist/helpers";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import EditIcon from "../../../../assets/images/BillConfig/Edit.svg";
let patientAppointment = null;
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export class SettingsConfig extends Component {
  constructor(props) {
    super(props);
    const ClinicType = JSON.parse(localStorage.getItem("loggedInData"))
      ? JSON.parse(localStorage.getItem("loggedInData")).clinic_type ==
        undefined
        ? {}
        : JSON.parse(localStorage.getItem("loggedInData")).clinic_type
      : {};
    this.state = {
      isOPT:
        ClinicType.is_opt_clinic == undefined ||
        ClinicType.is_opt_clinic == false
          ? false
          : true,
      AdmissionTimeEdit: true,
      AdmissionHoursInput: "",
      AdmissionHoursInputOk: false,
      AdmissionDateEdit: true,
      AdmissionDateInput: "",
      AdmissionDaysInputOk: false,
      LabResult: false,
      IPBillingTemplate: false,
      SavedBillEditAccess: false,
      DemographicsEditAccess: false,
      userType: this.props.userType,
      IPCreditLimitSetting: false,
      IPCreditLimitPopup: false,
      IPCreditSettupData: {
        isCreditLabSelected: false,
        labCreditLimit: null,
        isLabCreditExceedBlock: true,
        isCreditPharmacySelected: false,
        pharmacyCreditLimit : null,
        isPharmacyCreditExceedBlock: true,
        isIPCreditExceedBlock: true,
        isCreditIPSelected: false,
        ipCreditLimit: null,
      },
      userEditAccess:false,

      // user report view access
      userViewReportAccess: false,
      isUserAccessEdit: false,
      isServiceReport: false,
      isPatientReport: false,
      isIPReports: false,
      isServiceDetailed: false,
      isServiceOverview: false,
      isDayEndOverview: false,
      isPatientTypeReprt: false,
      isAppointmentReport: false,
      isReffralReport: false,
      isReferredSourceReport: false,
      isIpDoctorReport: false,
      isIpCurrentBillStatusReport: false,
      isCorporateBillsReport: false,
      isOpticalCRMSales: false,
      selectedAccessDuration: "",
      selectedAccessFromDate: converted_date,
      selectedAccessToDate: "",

      DemographicsMandatoryConfig:false,
      OtApprovalConfig: false,
      AprovalLevel:"all",
      isConsellor: true,
      isDoctor: true,
      isFO: true,
      isOT: true,
      ot_approve_config : [],

      Demo_MobileNumber : true,
      Demo_City : true,
      Demo_MobileNumber2 : false,
      Demo_Address1: false,
      Demo_FatherHusName : false,
      Demo_EmailID : false,
      Demo_Gender : true,
      Demo_Country : true,
      Demo_Title : false,
      Demo_Address2 : false,
      Demo_Occupation : false,
      Demo_EmergencyContactName : false,
      Demo_FirstName : true,
      Demo_State : true,
      Demo_Surname : false,
      Demo_Pincode : false,
      Demo_CorporateName : false,
      Demo_EmergencyContactNo : false,
      Demo_Age : true,
      Demo_DateofBirth : false,
      Demo_MartialStatus : false,
      Demo_EmergencyRelationship : false,
      Demo_Guardian: false,
      FO_admin_setting:{},
      isUserCancelBill: false,
      isPrintConfigration: false
    };
  }
  async componentDidMount() {
    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
    const FO_admin_setting = JSON.parse(FO_admin);
    this.setState({
      FO_admin_setting: FO_admin_setting,
      AdmissionTimeEdit:
        FO_admin_setting.allow_ip_time_upto != null ? true : false,
      AdmissionHoursInput: FO_admin_setting.allow_ip_time_upto,
      AdmissionHoursInputOk: false,
      AdmissionDateEdit:
        FO_admin_setting.allow_ip_days_upto != null ? true : false,
      AdmissionDateInput: FO_admin_setting.allow_ip_days_upto,
      AdmissionDaysInputOk: false,
      LabResult: FO_admin_setting.allow_lab_result_config,
      IPBillingTemplate: FO_admin_setting.allow_ip_units_change,
      SavedBillEditAccess: FO_admin_setting.allow_saved_bill_change,
      isUserCancelBill: FO_admin_setting.allow_user_to_cancel_bill,
      DemographicsEditAccess: FO_admin_setting.allow_user_demographics_edit,
      IPCreditLimitSetting:FO_admin_setting.allow_ip_credit_limit,
      IPCreditLimitSetting:FO_admin_setting.allow_ip_credit_limit,
      isCreditLabSelected: FO_admin_setting.ip_credit_limit_setup.allow_ip_lab_credit_limit,
      labCreditLimit: FO_admin_setting.ip_credit_limit_setup.lab_credit_limit_upto,
      isLabCreditExceedBlock: !FO_admin_setting.ip_credit_limit_setup.allow_lab_credit_overdue,
      isCreditPharmacySelected: FO_admin_setting.ip_credit_limit_setup.allow_ip_pharma_credit_limit,
      pharmacyCreditLimit: FO_admin_setting.ip_credit_limit_setup.pharmacy_credit_limit_upto,
      isPharmacyCreditExceedBlock: !FO_admin_setting.ip_credit_limit_setup.allow_pharma_credit_overdue,
      isPrintConfigration: FO_admin_setting.customize_discharge_summary_view,

      isIPCreditExceedBlock:  !FO_admin_setting.ip_credit_limit_setup.allow_ip_credit_overdue
    });
    var states = this.state;
    states["IPCreditSettupData"].isCreditLabSelected = FO_admin_setting.ip_credit_limit_setup.allow_ip_lab_credit_limit ? FO_admin_setting.ip_credit_limit_setup.allow_ip_lab_credit_limit : false;
    states["IPCreditSettupData"].labCreditLimit = FO_admin_setting.ip_credit_limit_setup.lab_credit_limit_upto != null && FO_admin_setting.ip_credit_limit_setup.lab_credit_limit_upto != "" ? FO_admin_setting.ip_credit_limit_setup.lab_credit_limit_upto : null;
    states["IPCreditSettupData"].isLabCreditExceedBlock = FO_admin_setting.ip_credit_limit_setup.allow_lab_credit_overdue ? !FO_admin_setting.ip_credit_limit_setup.allow_lab_credit_overdue : true;
    states["IPCreditSettupData"].isCreditPharmacySelected = FO_admin_setting.ip_credit_limit_setup.allow_ip_pharma_credit_limit ? FO_admin_setting.ip_credit_limit_setup.allow_ip_pharma_credit_limit : false;
    states["IPCreditSettupData"].pharmacyCreditLimit = FO_admin_setting.ip_credit_limit_setup.pharmacy_credit_limit_upto !=null && FO_admin_setting.ip_credit_limit_setup.pharmacy_credit_limit_upto !="" ? FO_admin_setting.ip_credit_limit_setup.pharmacy_credit_limit_upto : null;
    states["IPCreditSettupData"].isIPCreditExceedBlock = FO_admin_setting.ip_credit_limit_setup.allow_ip_credit_overdue ? !FO_admin_setting.ip_credit_limit_setup.allow_ip_credit_overdue : true;
    states["IPCreditSettupData"].isCreditIPSelected = FO_admin_setting.ip_credit_limit_setup.allow_inpatient_credit_limit? FO_admin_setting.ip_credit_limit_setup.allow_inpatient_credit_limit : false,
    states["IPCreditSettupData"].ipCreditLimit = FO_admin_setting.ip_credit_limit_setup.ip_credit_limit_upto != null ? FO_admin_setting.ip_credit_limit_setup.ip_credit_limit_upto : null,
    this.setState({states});
    this.getAdminUserAccess();
    this.getUserReportStatus();
    Object.keys(FO_admin_setting.demographics_mandatory_fields).length != 0 ? this.setDemographMandatoryFields(FO_admin_setting) : null
    Object.keys(FO_admin_setting.ot_approve_config).length != 0 ? this.setOTApprovalLevel(FO_admin_setting) : null
  }

  setOTApprovalLevel = (settings) => {
    var Approve_Config = []
    Approve_Config = settings.ot_approve_config
    var OT_Approve = (Approve_Config.indexOf("ot") > -1);
    var FO_Approve = (Approve_Config.indexOf("fo") > -1);
    var Doctor_Approve = (Approve_Config.indexOf("doctor") > -1);
    this.setState({
      isOT: OT_Approve ? true : false,
      isDoctor: Doctor_Approve ?  true : false,
      isFO: FO_Approve ? true : false,
      AprovalLevel : Approve_Config.length == 4 ? "all" : "specific"
    })
  }

  setDemographMandatoryFields = (settings) =>{
    this.setState({
       Demo_MobileNumber : settings.demographics_mandatory_fields.mobile_number,
       Demo_Gender: settings.demographics_mandatory_fields.gender,
       Demo_FirstName: settings.demographics_mandatory_fields.first_name,
       Demo_Age: settings.demographics_mandatory_fields.age,
       Demo_City: settings.demographics_mandatory_fields.city_name,
       Demo_Country: settings.demographics_mandatory_fields.country,
       Demo_State: settings.demographics_mandatory_fields.state_name,
       Demo_DateofBirth: settings.demographics_mandatory_fields.dob,
       Demo_MobileNumber2: settings.demographics_mandatory_fields.mobile_number_2,
       Demo_Title: settings.demographics_mandatory_fields.title,
       Demo_Surname: settings.demographics_mandatory_fields.last_name,
       Demo_MartialStatus: settings.demographics_mandatory_fields.marital_status,
       Demo_Address1: settings.demographics_mandatory_fields.address_line_1,
       Demo_Address2: settings.demographics_mandatory_fields.address_line_2,
       Demo_Pincode: settings.demographics_mandatory_fields.pincode,
       Demo_CorporateName: settings.demographics_mandatory_fields.employer_id,
       Demo_FatherHusName: settings.demographics_mandatory_fields.father_husband_name,
       Demo_Guardian: settings.demographics_mandatory_fields.guardian,
       Demo_Occupation: settings.demographics_mandatory_fields.occupation,
       Demo_EmailID: settings.demographics_mandatory_fields.email,
       Demo_EmergencyContactName: settings.demographics_mandatory_fields.emergency_name,
       Demo_EmergencyContactNo: settings.demographics_mandatory_fields.emergency_number,
       Demo_EmergencyRelationship: settings.demographics_mandatory_fields.emergency_relationship,
    })
  }

  getAdminUserAccess = () =>{
    let url = Constants.GET_FO_ADMIN_CONFIG_SETTINGS;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getAdminUserAccessSuccess,
      this.getAdminUserAccessFailure
    )
  }
  getAdminUserAccessSuccess = (response) =>{
      if(response.status === "success"){
        this.setState({
          userEditAccess:response.data.allow_service_amount_edit
        })
      }
  }
  getAdminUserAccessFailure = () =>{
    this.ErroralertValue(error.message)
    console.log(error.message)
  }
  getUserReportStatus = () => {
    var url = Constants.UPDATE_FO_ADMIN_SETTINGS 
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getUserReportStatusSuccess,
        this.getUserReportStatusFailure
      );
}
getUserReportStatusSuccess = (response) => {
   if(response && response.status === "success") {
      var data = response.data
      var userServiceReport = JSON.parse(data.report_config_views)
      this.setState({
        userViewReportAccess: data.allow_view_report_config,
        isServiceDetailed: userServiceReport.service_detailed,
        isServiceOverview: userServiceReport.service_overview,
        isDayEndOverview: userServiceReport.day_end_overview,
        isPatientTypeReprt: userServiceReport.patient_type,
        isAppointmentReport: userServiceReport.appointment_type,
        isReffralReport: userServiceReport.referral_report,
        isIpDoctorReport: userServiceReport.ip_doctor,
        isIpCurrentBillStatusReport: userServiceReport.ip_current_bill_status,
        isCorporateBillsReport: userServiceReport.corporate_bills_report,
        isOpticalCRMSales: userServiceReport.optical_crm_sales,
        isReferredSourceReport : userServiceReport.referred_source_report,
        selectedAccessFromDate: data.report_view_access_from,
        selectedAccessToDate: data.report_view_access_to,
        isUserCancelBill : data.allow_user_to_cancel_bill,
        isPrintConfigration: data.customize_discharge_summary_view,
        isCreditIPSelected: data.ip_credit_limit_setup.allow_inpatient_credit_limit,
        ipCreditLimit: data.ip_credit_limit_setup.ip_credit_limit_upto,
        isIPCreditExceedBlock: data.ip_credit_limit_setup.allow_ip_credit_overdue
      })
      var dateFirst = new Date(data.report_view_access_from);
      var dateSecond = new Date(data.report_view_access_to);
      var selectedDate = ""
      // time difference
      var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if( diffDays == 7) {
        selectedDate = "Last 7 days"
      } else if( diffDays == 30 ) {
        selectedDate = "Last 30 days"
      } else if( diffDays == 60 ) {
        selectedDate = "Last 60 days"
      } else if( diffDays > 360) {
       selectedDate = "Last 12 month"
      } else { 
        selectedDate = "All"
      }
      this.setState({
        selectedAccessDuration: selectedDate
      })

   }
}
getUserReportStatusFailure = (error) => {
    this.props.showResposeValue("error", error.message)
}
getDatesDiff = (start_date, end_date, date_format = "YYYY-MM-DD") => {
  const getDateAsArray = date => {
    return moment(date.split(/\D+/), date_format);
  };
  const diff = (end_date).diff((start_date), "days") + 1;
  console.log(diff, "diff")
  this.setState({
    // DateList: dates
  })
};
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  SuccessalertValue = (msg) => {
    this.props.showResposeValue("success", msg);
  };
  ErroralertValue = (msg) => {
    this.props.showResposeValue("error", msg);
  };

  handleChange = (activeKey) => {
    this.setState({ activeKey });
  };

  numberValidation(num) {
    return /^\d+$/.test(num);
  }
  updateAdminSetting = () => {
    try {
      var ip_credit_limit_setup = {};
      ip_credit_limit_setup['allow_ip_lab_credit_limit'] = this.state.isCreditLabSelected;
      ip_credit_limit_setup['lab_credit_limit_upto'] = this.state.labCreditLimit;
      ip_credit_limit_setup['allow_lab_credit_overdue'] = this.state.isLabCreditExceedBlock ? !this.state.isLabCreditExceedBlock : true;
      ip_credit_limit_setup['allow_ip_pharma_credit_limit'] = this.state.isCreditPharmacySelected;
      ip_credit_limit_setup['pharmacy_credit_limit_upto'] = this.state.pharmacyCreditLimit;
      ip_credit_limit_setup['allow_pharma_credit_overdue'] = this.state.isPharmacyCreditExceedBlock ? !this.state.isPharmacyCreditExceedBlock : true;
      ip_credit_limit_setup['allow_inpatient_credit_limit'] = this.state.isCreditIPSelected;
      ip_credit_limit_setup["ip_credit_limit_upto"] = this.state.ipCreditLimit,
      ip_credit_limit_setup["allow_ip_credit_overdue"] =  this.state.isIPCreditExceedBlock 
      var ot_approve_level = []
      ot_approve_level = this.returnApprovalStatus()
      var data = {
        allow_ip_time_upto:
          this.state.AdmissionTimeEdit == true
            ? this.state.AdmissionHoursInput
            : null,
        allow_ip_days_upto:
          this.state.AdmissionDateEdit == true
            ? this.state.AdmissionDateInput
            : null,
        allow_lab_result_config: this.state.LabResult,
        allow_ip_units_change: this.state.IPBillingTemplate,
        allow_saved_bill_change: this.state.SavedBillEditAccess,
        allow_user_demographics_edit: this.state.DemographicsEditAccess,
        allow_ip_credit_limit : this.state.IPCreditLimitSetting,
        ip_credit_limit_setup: ip_credit_limit_setup,
        allow_service_amount_edit:this.state.userEditAccess,
        allow_user_to_cancel_bill: this.state.isUserCancelBill,
        "user_report_view": {
          "allow_view_report_config": this.state.userViewReportAccess,
          "report_config_views": {
            'service_detailed': this.state.isServiceDetailed,
            'service_overview': this.state.isServiceOverview,
            'day_end_overview': this.state.isDayEndOverview,
            'patient_type': this.state.isPatientReport,
            'appointment_type': this.state.isAppointmentReport,
            'referral_report': this.state.isReffralReport,
            'ip_doctor': this.state.isIPReports,
            'ip_current_bill_status': this.state.isIpCurrentBillStatusReport,
            'corporate_bills_report' : this.state.isCorporateBillsReport,
            'optical_crm_sales': this.state.isOpticalCRMSales,
            'referred_source_report' : this.state.isReferredSourceReport,
          },
          "report_view_access_from": this.state.selectedAccessFromDate,
          "report_view_access_to": this.state.selectedAccessToDate
        },
        "demographics_mandatory_fields": {
            'mobile_number': this.state.Demo_MobileNumber,
            'gender': this.state.Demo_Gender,
            'first_name': this.state.Demo_FirstName,
            'age': this.state.Demo_Age,
            'city_name': this.state.Demo_City,
            'country': this.state.Demo_Country,
            'state_name': this.state.Demo_State,
            'dob': this.state.Demo_DateofBirth,
            'mobile_number_2' : this.state.Demo_MobileNumber2,
            'title' : this.state.Demo_Title,
            'last_name' : this.state.Demo_Surname,
            'marital_status' : this.state.Demo_MartialStatus,
            'address_line_1' : this.state.Demo_Address1,
            'address_line_2' : this.state.Demo_Address2,
            'pincode' : this.state.Demo_Pincode,
            'employer_id' : this.state.Demo_CorporateName,
            'father_husband_name' : this.state.Demo_FatherHusName,
            'guardian': this.state.Demo_Guardian,
            'occupation' : this.state.Demo_Occupation,
            'email' : this.state.Demo_EmailID,
            'emergency_name' : this.state.Demo_EmergencyContactName,
            'emergency_number' : this.state.Demo_EmergencyContactNo,
            'emergency_relationship' : this.state.Demo_EmergencyRelationship,
          },
          "ot_approve_config": ot_approve_level,
          "customize_discharge_summary_view": this.state.isPrintConfigration
      };
      var service_url = Constants.UPDATE_FO_ADMIN_SETTINGS;
      OpthamologyService.getInstance().putMethod(
        service_url,
        data,
        this,
        this.UpdateSuccess,
        this.UpdateFailure
      );
    } catch (e) {
      console.log(e.message);
    }
  };
  UpdateSuccess = (response) => {
    if (response) {
      if (response.status == "success") {
        this.SuccessalertValue(response.message);
        AsyncStorage.setItem(
          "FO_ADMIN_SETTINGS",
          JSON.stringify(response.data)
        );
        var data = response.data
        var user_access = JSON.parse(data.report_config_views)
        this.setState({
          FO_admin_setting:data,
          userViewReportAccess: data.allow_view_report_config,             
          isServiceDetailed: user_access.service_detailed,
          isServiceOverview: user_access.service_overview,
          isDayEndOverview: user_access.day_end_overview,
          isPatientTypeReprt: user_access.patient_type,
          isAppointmentReport: user_access.appointment_type,
          isReffralReport: user_access.referral_report,
          isIpDoctorReport: user_access.ip_doctor,
          isIpCurrentBillStatusReport: user_access.ip_current_bill_status,
          isCorporateBillsReport: user_access.corporate_bills_report,
          isOpticalCRMSales: user_access.optical_crm_sales,
          isReferredSourceReport: user_access.referred_source_report,
          isUserAccessEdit: false,
          selectedAccessFromDate: data.report_view_access_from,
          selectedAccessToDate: data.report_view_access_to,
          DemographicsMandatoryConfig:false,
          OtApprovalConfig: false,
          isUserCancelBill: data.allow_user_to_cancel_bill,
          isPrintConfigration: data.customize_discharge_summary_view,
          isCreditIPSelected: data.ip_credit_limit_setup.allow_inpatient_credit_limit,
          ipCreditLimit: data.ip_credit_limit_setup.ip_credit_limit_upto,
          isIPCreditExceedBlock: data.ip_credit_limit_setup.allow_ip_credit_overdue
        })
        var dateFirst = new Date(data.report_view_access_from);
        var dateSecond = new Date(data.report_view_access_to);
        var selectedDate = ""
        // time difference
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if( diffDays == 7) {
          selectedDate = "Last 7 days"
        } else if( diffDays == 30 ) {
          selectedDate = "Last 30 days"
        } else if( diffDays == 60 ) {
          selectedDate = "Last 60 days"
        } else if( diffDays > 360) {
         selectedDate = "Last 12 month"
        } else { 
          selectedDate = "All"
        }
        this.setState({
          selectedAccessDuration: selectedDate
        })
        this.getAdminUserAccess()
        
      }
    }
  };
  UpdateFailure = (error) => {
    this.ErroralertValue(error.message);
    console.log(error.message);
  };
  IPCreditPopup = () => {
    try {
      return (
        <View style={styles.FilterPopUpView}>
          <View style={styles.FiterTop}>
            <Text style={styles.FilterHeadTxt}>Select the Fields</Text>
            <TouchableOpacity onPress={()=>{this.setState({IPCreditLimitPopup: false})}}>
              <Icon name="close" size={"1.2vw"} color={color.red}/>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: "row" }}>
          <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isCreditIPSelected}
                  onChange={() => {
                    this.setState(
                      { isCreditIPSelected: !this.state.isCreditIPSelected },
                      () => {
                        if (!this.state.isCreditIPSelected) {
                          this.setState({
                            ipCreditLimit: null,
                          });
                        }
                      }
                    );
                  }}
                >
                  IP{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <TextInput
                  disabled={!this.state.isCreditIPSelected}
                  style={styles.SearchInput}
                  placeholder="Enter Credit Limit"
                  value={this.state.ipCreditLimit}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                          ipCreditLimit: text,
                        });
                      }else{
                        this.setState({
                          ipCreditLimit: "",
                        });
                      }
                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditIPSelected}
                  checked={this.state.isIPCreditExceedBlock ? true : false}
                  onChange={() => {
                    this.setState(
                      { isIPCreditExceedBlock: !this.state.isIPCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditIPSelected}
                  checked={this.state.isIPCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isIPCreditExceedBlock: !this.state.isIPCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
            </View>
            <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isCreditLabSelected}
                  onChange={() => {
                    this.setState(
                      { isCreditLabSelected: !this.state.isCreditLabSelected },
                      () => {
                        if (!this.state.isCreditLabSelected) {
                          this.setState({
                            labCreditLimit: null,
                          });
                        }
                      }
                    );
                  }}
                >
                  Laboratory{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <TextInput
                  disabled={!this.state.isCreditLabSelected}
                  style={styles.SearchInput}
                  placeholder="Enter Credit Limit"
                  value={this.state.labCreditLimit}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                          labCreditLimit: text,
                        });
                      }else{
                        this.setState({
                          labCreditLimit: "",
                        });
                      }
                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditLabSelected}
                  checked={this.state.isLabCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isLabCreditExceedBlock: !this.state.isLabCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditLabSelected}
                  checked={this.state.isLabCreditExceedBlock ? true : false}
                  onChange={() => {
                    this.setState(
                      { isLabCreditExceedBlock: !this.state.isLabCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
            </View>

            <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isCreditPharmacySelected}
                  onChange={() => {
                    this.setState(
                      { isCreditPharmacySelected: !this.state.isCreditPharmacySelected },
                      () => {
                        if (!this.state.isCreditPharmacySelected) {
                          this.setState({
                            pharmacyCreditLimit: null,
                          });
                        }
                      }
                    );
                  }}
                >
                  Pharmacy{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <TextInput
                  disabled={!this.state.isCreditPharmacySelected}
                  style={styles.SearchInput}
                  placeholder="Enter Credit Limit"
                  value={this.state.pharmacyCreditLimit}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                    if (isValid || text === "") {
                      this.setState({
                        pharmacyCreditLimit: text,
                      });
                    }else{
                      this.setState({
                        pharmacyCreditLimit: "",
                      });
                    }

                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditPharmacySelected}
                  checked={this.state.isPharmacyCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isPharmacyCreditExceedBlock: !this.state.isPharmacyCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  disabled={!this.state.isCreditPharmacySelected}
                  checked={this.state.isPharmacyCreditExceedBlock ? true : false}
                  onChange={() => {
                    this.setState(
                      { isPharmacyCreditExceedBlock: !this.state.isPharmacyCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
            <TouchableOpacity
            onPress={() => {this.setState({IPCreditLimitPopup : false}, ()=>{this.updateAdminSetting()})}}
            style={[styles.BtnTouch, {backgroundColor: color.themeDark, borderColor: color.themeDark, borderWidth:1}]}>
            <Text style={styles.ButtonText}>{"Save"}</Text>
          </TouchableOpacity>
            </View>
            </View>
          </View>
          {/*<TouchableOpacity
            onPress={() => { this.ClearFilters() }}
            style={[styles.BtnTouch, {backgroundColor: color.white, borderColor: color.themeDark, borderWidth:1}]}>
            <Text style={[styles.ButtonText, { color: color.themeDark}]}>{"Clear"}</Text>
          </TouchableOpacity>
        </View> */}
        </View>
      );
    } catch (e) {}
  };

  returnApprovalStatus = () => {
    let data = []
    if ((this.state.isOT && this.state.isFO && this.state.isDoctor) || this.state.AprovalLevel === "all") {
      data.push("counsellor", "doctor", "fo", "ot")
    } else if (this.state.isOT && !this.state.isFO && !this.state.isDoctor) {
      data.push("counsellor", "ot")
    } else if (!this.state.isOT && this.state.isFO && !this.state.isDoctor) {
      data.push("counsellor", "fo")
    } else if (!this.state.isOT && !this.state.isFO && this.state.isDoctor) {
      data.push("counsellor", "doctor")
    } else if (this.state.isOT && this.state.isFO && !this.state.isDoctor) {
      data.push("counsellor", "fo", "ot")
    } else if (this.state.isOT && !this.state.isFO && this.state.isDoctor) {
      data.push("counsellor", "doctor", "ot")
    } else if (!this.state.isOT && this.state.isFO && this.state.isDoctor) {
      data.push("counsellor", "doctor", "fo")
    } else {
      data.push("counsellor")
    }
    return data
  }

  ResetOTApprovalDatas(){
    this.setState({
      AprovalLevel:"all",
      isConsellor: true,
      isDoctor: true,
      isFO: true,
      isOT: true,
    })
  }

  ResetDemographicsDatas(){
    this.setState({
      Demo_MobileNumber: true,
      Demo_City: true,
      Demo_MobileNumber2: false,
      Demo_Address1: false,
      Demo_FatherHusName: false,
      Demo_EmailID: false,
      Demo_Gender: true,
      Demo_Country: true,
      Demo_Title: false,
      Demo_Address2: false,
      Demo_Occupation: false,
      Demo_EmergencyContactName: false,
      Demo_FirstName: true,
      Demo_State: true,
      Demo_Surname: false,
      Demo_Pincode: false,
      Demo_CorporateName: false,
      Demo_EmergencyContactNo: false,
      Demo_Age: true,
      Demo_DateofBirth: false,
      Demo_MartialStatus: false,
      Demo_EmployeeIDNumber: false,
      Demo_EmergencyRelationship: false,
      Demo_Guardian: false
})
  }
  render() {
    console.log(this.state.isUserAccessEdit,"isUserAccessEdit")
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
            { label: "Service Configuration", value: "adminConfig" },
            { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
            { label: "Tag Configuration", value: "tagConfig" },
            { label: "Package Configuration", value: "packageConfig" },
            { label: "User Configuration", value: "userConfig" },
            { label: "Corporate Details", value: "corpConfig" },
            { label: "QMS", value: "adminQmsConfiq" },
            { label: "Referred Doctors", value: "labRefferedDoctors"},
            { label: "Expense", value: "expenseconfig" },
            { label: "Settings", value: "settingConfig" },
            { label: "Discharge Summary Templates", value: "DisSumTemplate" },
            { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
            ] : [
            { label: "Service Configuration", value: "adminConfig" },
            { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
            { label: "Tag Configuration", value: "tagConfig" },
            { label: "User Configuration", value: "userConfig" },
            { label: "Corporate Details", value: "corpConfig" },
            { label: "Referred Doctors", value: "labRefferedDoctors"},
            { label: "Expense", value: "expenseconfig" },
            { label: "Settings", value: "settingConfig" }
            ]}
          selectedTab={"settingConfig"}
          isNavigationBorder={true}
        />

        <ScrollView style={styles.MainView}>
          {/* <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>Admission Time Edit Option</Text>
            <View style={styles.OptionsActionView}>
              {this.state.AdmissionTimeEdit ? (
                <View style={styles.InputView}>
                  <TextInput
                    placeholder="Enter Hours"
                    style={[
                      styles.TextInputStyles,
                      {
                        borderColor:
                          this.state.AdmissionHoursInput != ""
                            ? color.lightGray
                            : color.red,
                      },
                    ]}
                    value={this.state.AdmissionHoursInput}
                    onChangeText={(text) => {
                      var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                          AdmissionHoursInput: text,
                        });
                      }
                    }}
                  />
                  <TouchableOpacity
                    disabled={
                      this.state.AdmissionHoursInput != "" ? false : true
                    }
                    onPress={() => {
                      this.setState({
                        AdmissionHoursInputOk: true,
                      });
                    }}
                    style={[
                      styles.BtnTouch,
                      {
                        backgroundColor:
                          this.state.AdmissionHoursInput != ""
                            ? color.themeDark
                            : "#D0D0D0",
                      },
                    ]}
                  >
                    <Text style={styles.ButtonText}>OK</Text>
                  </TouchableOpacity>
                </View>
              ) : null}
              <Radio.Group
                onChange={(e) => {
                  this.setState({ AdmissionTimeEdit: e.target.value });
                }}
                value={this.state.AdmissionTimeEdit}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>

          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>Admission Days Edit Option</Text>
            <View style={styles.OptionsActionView}>
              {this.state.AdmissionDateEdit ? (
                <View style={styles.InputView}>
                  <TextInput
                    placeholder="Enter Days"
                    style={[
                      styles.TextInputStyles,
                      {
                        borderColor:
                          this.state.AdmissionDateInput != ""
                            ? color.lightGray
                            : color.red,
                      },
                    ]}
                    value={this.state.AdmissionDateInput}
                    onChangeText={(text) => {
                      var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                          AdmissionDateInput: text,
                        });
                      }
                    }}
                  />
                  <TouchableOpacity
                    disabled={
                      this.state.AdmissionDateInput != "" ? false : true
                    }
                    onPress={() => {
                      this.setState({
                        AdmissionDaysInputOk: true,
                      });
                    }}
                    style={[
                      styles.BtnTouch,
                      {
                        backgroundColor:
                          this.state.AdmissionDateInput != ""
                            ? color.themeDark
                            : "#D0D0D0",
                      },
                    ]}
                  >
                    <Text style={styles.ButtonText}>OK</Text>
                  </TouchableOpacity>
                </View>
              ) : null}
              <Radio.Group
                onChange={(e) => {
                  this.setState({ AdmissionDateEdit: e.target.value });
                }}
                value={this.state.AdmissionDateEdit}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View> */}

          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              Should The Lab Result Be Added in the Investigation Section of
              Discharge Summary ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ LabResult: e.target.value });
                }}
                value={this.state.LabResult}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>

          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
            Should be able to edit units in IP Bills ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ IPBillingTemplate: e.target.value });
                }}
                value={this.state.IPBillingTemplate}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>

          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              Should User can Modify Bills ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ SavedBillEditAccess: e.target.value });
                }}
                value={this.state.SavedBillEditAccess}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>
          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              IP Credit Configuration for IP, Lab & Pharmacy
            </Text>
            <View style={styles.OptionsActionView}>
              {this.state.IPCreditLimitSetting ? (
                <TouchableOpacity style={{paddingRight: 4}}
                  onPress={() => {
                    this.setState({
                      IPCreditLimitPopup: !this.state.IPCreditLimitPopup,
                    });
                  }}
                >
                  <Image
                    source={EditIcon}
                    style={styles.ImageStyle}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              ) : null}
              <Radio.Group
                onChange={(e) => {
                  this.setState({ IPCreditLimitSetting: e.target.value, IPCreditLimitPopup: e.target.value == false ? false : false });
                }}
                value={this.state.IPCreditLimitSetting}
                >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>

          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              Should User Edit the Service Amount ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ userEditAccess: e.target.value });
                }}
                value={this.state.userEditAccess}
                >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
            </View>
            <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              User view reports configuration?
            </Text>
            <View style={styles.OptionsActionView}>
              <TouchableOpacity
                style={{ marginLeft: 30, width: "fit-content",paddingRight: 4 }}
                onPress={() => {
                  this.setState({
                    isUserAccessEdit: true
                  })
                }}
              >
                <Image
                  source={ImagePath.Edit}
                  style={styles.ImageStyle}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ userViewReportAccess: e.target.value });
                }}
                value={this.state.userViewReportAccess}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>
          <View style={styles.OptionsWrapper}>
              <Text style={styles.OptionsText}>
              Should User be able to edit Demographics as like Admin?
              </Text>
              <View style={styles.OptionsActionView}>
                <Radio.Group
                  onChange={(e) => {
                    this.setState({ DemographicsEditAccess: e.target.value });
                  }}
                  value={this.state.DemographicsEditAccess}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </View>
            </View>
            <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              Demographics Mandatory Fields Configuration ?
            </Text>
            <View style={styles.OptionsActionView}>
              <TouchableOpacity
                style={{ marginLeft: 30, width: "fit-content" }}
                onPress={() => {
                  this.setState({
                    DemographicsMandatoryConfig: true
                  })
                }}
              >
                <Image
                  source={ImagePath.Edit}
                  style={{ height: "1.5vw", width: '1.8vw', marginRight:'1vw' }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              OT Approval Level Configuration ?
            </Text>
            <View style={styles.OptionsActionView}>
              <TouchableOpacity
                style={{ marginLeft: 30, width: "fit-content" }}
                onPress={() => {
                  this.setState({
                    OtApprovalConfig: true
                  })
                }}
              >
                <Image
                  source={ImagePath.Edit}
                  style={{ height: "1.5vw", width: '1.8vw', marginRight: '1vw' }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              User can cancel the bill after 24 hours of billing time ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ isUserCancelBill: e.target.value });
                }}
                value={this.state.isUserCancelBill}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>
          <View style={styles.OptionsWrapper}>
            <Text style={styles.OptionsText}>
              User can edit print configuration ?
            </Text>
            <View style={styles.OptionsActionView}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ isPrintConfigration: e.target.value });
                }}
                value={this.state.isPrintConfigration}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </View>
          </View>
        </ScrollView>
        {this.state.IPCreditLimitPopup ? this.IPCreditPopup() : null}
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              this.updateAdminSetting();
            }}
            style={[
              styles.BtnTouch,
              {
                backgroundColor: color.themeDark,
                width: "6vw",
              },
            ]}
          >
            <Text style={styles.ButtonText}>Update</Text>
          </TouchableOpacity>
        </View>
        {this.state.isUserAccessEdit && this.state.userViewReportAccess ?
          <View style={{ height: ScreenHeight-65, zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "55%", borderRadius: 8, borderWidth: 1, height : "70%" }}>
             <View  style={{ }}> 
              <View style= {{ flexDirection: 'row', backgroundColor: color.sectionHeadingDarkColor, padding: "0.3vw", borderRadius: "0.3vw"}}>
                {this.getBillTableTitle('Service Reports', 0.3,"1vw", "isServiceReport", this.state.isServiceReport)}
                {this.getBillTableTitle('Patient Reports', 0.3,"1vw", "isPatientReport", this.state.isPatientReport)}
                {this.getBillTableTitle('IP Reports', 0.3,"1vw", "isIPReports", this.state.isIPReports)}
                <TouchableOpacity style={{ flex: 0.1, display: 'flex', flexDirection: 'row-reverse', alignSelf: "center" }} onPress={() => { this.setState({ isUserAccessEdit: false }) }}>
                  <Icon name="window-close-o" size={'1.5vw'} color={color.themeDark} />
                </TouchableOpacity>
              </View>  
                <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 0.3, marginTop: "1vw" }}>
                 { this.renderCheckBox("isServiceDetailed", this.state.isServiceDetailed, "Service Detailed")}
                 { this.renderCheckBox("isServiceOverview", this.state.isServiceOverview, "Service Overview")}
                 { this.renderCheckBox("isDayEndOverview", this.state.isDayEndOverview, "Day End Overview")}
                 { this.renderCheckBox("isCorporateBillsReport", this.state.isCorporateBillsReport, "Corporate Bills Report")}
                 { this.renderCheckBox("isOpticalCRMSales", this.state.isOpticalCRMSales, "Optical CRM Sales")}
                </View>
                <View style={{ flex: 0.3, marginTop: "1vw" }}>
                 { this.renderCheckBox("isPatientTypeReprt", this.state.isPatientTypeReprt, "Patient Type Report")}
                 { this.renderCheckBox("isAppointmentReport", this.state.isAppointmentReport, "Appointment Type Report")}
                 { this.renderCheckBox("isReffralReport", this.state.isReffralReport, "Refferal Report")}
                 { this.renderCheckBox("isReferredSourceReport", this.state.isReferredSourceReport, "Referred Source Report")}

                </View>
                <View style={{ flex: 0.3, marginTop: "1vw" }}>
                 { this.renderCheckBox("isIpDoctorReport", this.state.isIpDoctorReport, "IP Doctor Report")}
                 { this.renderCheckBox("isIpCurrentBillStatusReport", this.state.isIpCurrentBillStatusReport, "IP Current Bill Status Report")}
                </View>
                <View style={{ flex: 0.1 }}></View>
              </View> 
              <View>
             </View> 
             <View style={{ marginTop: "1vw" }}>  
               <CommonHistorySectionHeader
                    heading1={"User Access Period"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <Picker
                  selectedValue={this.state.selectedAccessDuration}
                  style={[styles.pickerView, { marginTop: '0.5vw' }]}
                  itemStyle={{ fontSize: '0.9vw' }}
                  onValueChange={(itemvalue) => {                    
                    this.setState({ 
                      selectedAccessDuration: itemvalue
                    },() =>{
                        var selectedDate = ""
                        if(this.state.selectedAccessDuration === "Last 7 days") {
                          selectedDate =  moment(new Date()).add(7, "days").format("YYYY-MM-DD");
                        } else  if(this.state.selectedAccessDuration === "Last 30 days") {
                          selectedDate =  moment(new Date()).add(30, "days").format("YYYY-MM-DD");
                        } else if(this.state.selectedAccessDuration === "Last 90 days") {
                          selectedDate =  moment(new Date()).add(90, "days").format("YYYY-MM-DD");
                        } else if(this.state.selectedAccessDuration === "Last 12 months") {
                          selectedDate =  moment(new Date()).add(12, "months").format("YYYY-MM-DD");
                        } 
                        this.setState({ selectedAccessToDate: selectedDate })
                    });
                  }}
                >
                  <Picker.Item label="Select Access Period" value="" />
                  <Picker.Item label="All" value="" />
                  <Picker.Item label="Last 7 days" value="Last 7 days" />
                  <Picker.Item label="Last 30 days" value="Last 30 days" />
                  <Picker.Item label="Last 90 days" value="Last 90 days" />
                  <Picker.Item label="Last 12 months" value="Last 12 months" />
                </Picker>
                <Text style= {{ fontSize: 12, color: color.lightGray, margin: "0.5vw" }}>{"Kindly select access period"}</Text>
              </View>
              </View>
              <View style={{ justifyContent: "flex-end", alignItems: "flex-end", marginTop: "3vw" }}>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAdminSetting()
                    this.setState({
                      isUserAccessEdit: false
                    })
                  }}
                  style={[
                    styles.BtnTouch,
                    {
                      backgroundColor: color.themeDark,
                      width: "6vw",
                    },
                  ]}
                >
                  <Text style={styles.ButtonText}>Update</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View> : null }  

          {
            this.state.DemographicsMandatoryConfig ?
            <View style={{ height: ScreenHeight-65, zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "60%", borderRadius: 8, borderWidth: 1, height : "60%" }}>
             <View  style={{ }}> 
              <View style= {{ flexDirection: 'row', backgroundColor: color.sectionHeadingDarkColor, padding: "0.3vw", borderRadius: "0.3vw", justifyContent:'space-between',alignItems:'center'}}>
                <Text style={{fontSize:'0.9vw', fontWeight:'500',paddingLeft:'0.5vw'}}>Select Mandatory Fields For Patient Demographics</Text>
                <TouchableOpacity style={{ flex: 0.1, display: 'flex', flexDirection: 'row-reverse', alignSelf: "center" }} onPress={() => { 
                  this.setState({ 
                    DemographicsMandatoryConfig: false,
                     }, ()=>{Object.keys(this.state.FO_admin_setting.demographics_mandatory_fields).length != 0 ? this.setDemographMandatoryFields(this.state.FO_admin_setting) : this.ResetDemographicsDatas()}) }}>
                  <Icon name="window-close-o" size={'1.5vw'} color={color.themeDark} />
                </TouchableOpacity>
              </View>  
                <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 0.25, marginTop: "1vw" }}>
                 { this.renderCheckBox("Demo_MobileNumber", this.state.Demo_MobileNumber, "Mobile Number", true)}
                 { this.renderCheckBox("Demo_City", this.state.Demo_City, "City", true)}
                 { this.renderCheckBox("Demo_MobileNumber2", this.state.Demo_MobileNumber2, "Mobile Number 2")}
                 { this.renderCheckBox("Demo_Address1", this.state.Demo_Address1, "Address 1")}
                 { this.renderCheckBox("Demo_FatherHusName", this.state.Demo_FatherHusName, "Father/Husband Name")}
                 { this.renderCheckBox("Demo_EmergencyContactNo", this.state.Demo_EmergencyContactNo, "Emergency - Contact No")}
                </View>
                <View style={{ flex: 0.25, marginTop: "1vw" }}>
                 { this.renderCheckBox("Demo_Gender", this.state.Demo_Gender, "Gender", true)}
                 { this.renderCheckBox("Demo_Country", this.state.Demo_Country, "Country", true)}
                 { this.renderCheckBox("Demo_Title", this.state.Demo_Title, "Title")}
                 { this.renderCheckBox("Demo_Address2", this.state.Demo_Address2, "Address 2")}
                 { this.renderCheckBox("Demo_Guardian", this.state.Demo_Guardian, "Guardian")}
                 { this.renderCheckBox("Demo_EmergencyRelationship", this.state.Demo_EmergencyRelationship, "Emergency - Relationship")}
                </View>
                <View style={{ flex: 0.25, marginTop: "1vw" }}>
                 { this.renderCheckBox("Demo_FirstName", this.state.Demo_FirstName, "First Name", true)}
                 { this.renderCheckBox("Demo_State", this.state.Demo_State, "State", true)}
                 { this.renderCheckBox("Demo_Surname", this.state.Demo_Surname, "Surname")}
                 { this.renderCheckBox("Demo_Pincode", this.state.Demo_Pincode, "Pincode")}
                 { this.renderCheckBox("Demo_Occupation", this.state.Demo_Occupation, "Occupation")}

                </View>
                <View style={{ flex: 0.25, marginTop: "1vw" }}>
                 { this.renderCheckBox("Demo_Age", this.state.Demo_Age, "Age", true)}
                 { this.renderCheckBox("Demo_DateofBirth", this.state.Demo_DateofBirth, "Date of Birth")}
                 { this.renderCheckBox("Demo_MartialStatus", this.state.Demo_MartialStatus, "Martial Status")}
                 { this.renderCheckBox("Demo_EmailID", this.state.Demo_EmailID, "Email ID")}
                 { this.renderCheckBox("Demo_EmergencyContactName", this.state.Demo_EmergencyContactName, "Emergency - Contact Name")}
                </View>
              </View> 
              <View>
             </View> 
              </View>
              <View style={{display:'flex', flexDirection:'row', justifyContent: "space-between", alignItems: "flex-end", marginTop: "3vw" }}>
                <Text style={{color:color.red, fontWeight:'500', fontSize:"0.8vw"}}>Note : Disabled Fields are Always Mandatory , Can't Update those Fields.</Text>
                <View style={{display:'flex', flexDirection:'row', justifyContent: "space-between", alignItems:"center", width:"13vw"}}>
                <TouchableOpacity
                      onPress={() => {this.ResetDemographicsDatas()}}
                  style={[
                    styles.BtnTouch,
                    {
                      backgroundColor: color.white,
                      width: "6vw",
                      borderColor:color.themeDark,
                      borderRadius:'0.26vw',
                      borderWidth:1
                    },
                  ]}
                >
                  <Text style={[styles.ButtonText,{color:color.themeDark}]}>Clear</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.updateAdminSetting()
                    this.setState({
                      isUserAccessEdit: false
                    })
                  }}
                  style={[
                    styles.BtnTouch,
                    {
                      backgroundColor: color.themeDark,
                      width: "6vw",
                    },
                  ]}
                >
                  <Text style={styles.ButtonText}>Update</Text>
                </TouchableOpacity>
                </View>
              </View>
            </View>
          </View> : null
          } 
        {
          this.state.OtApprovalConfig ?
            <View style={{ height: ScreenHeight - 65, zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
              <View style={{ paddingHorizontal: 25, paddingVertical: 20, backgroundColor: color.white, width: "30%", borderRadius: 8, borderWidth: 1, height: "40%" }}>
                <View style={{ flexDirection: 'row', backgroundColor: color.sectionHeadingDarkColor, padding: "0.3vw", borderRadius: "0.3vw", justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={{ fontSize: '0.9vw', fontWeight: '500', paddingLeft: '0.5vw' }}>Select OT Approval Level</Text>
                  <TouchableOpacity style={{ flex: 0.1, display: 'flex', flexDirection: 'row-reverse', alignSelf: "center" }} onPress={() => {
                    this.setState({
                      OtApprovalConfig: false,
                    }, () => {Object.keys(this.state.FO_admin_setting.ot_approve_config).length != 0 ? this.setOTApprovalLevel(this.state.FO_admin_setting) : this.ResetOTApprovalDatas() })
                  }}>
                    <Icon name="window-close-o" size={'1.5vw'} color={color.themeDark} />
                  </TouchableOpacity>
                </View>
                <View>
                  <View style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop:"1vw" }}>
                    <Radio.Group
                      onChange={(e) => {
                        this.setState({ AprovalLevel: e.target.value });
                      }}
                      value={this.state.AprovalLevel}
                    >
                      <Radio value={"all"}>All</Radio>
                      <Radio value={"specific"}>Specific</Radio>
                    </Radio.Group>
                  </View>
                  <View style={{marginTop:"2vw", width:"100%", alignItems:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <Checkbox
                      disabled={true}
                      checked={this.state.isConsellor ? true : false}
                      onChange={() => {
                        this.setState(
                          { isConsellor: !this.state.isConsellor });
                      }}>Counsellor</Checkbox>
                    <Checkbox
                      disabled={this.state.AprovalLevel == "all" ? true : false}
                      checked={this.state.AprovalLevel == "all" ? true : this.state.isDoctor ? true : false}
                      onChange={() => {
                        this.setState(
                          { isDoctor: !this.state.isDoctor });
                      }}>Doctor</Checkbox>
                      <Checkbox
                      disabled={this.state.AprovalLevel == "all" ? true : false}
                      checked={this.state.AprovalLevel == "all" ? true : this.state.isOT ? true : false}
                      onChange={() => {
                        this.setState(
                          { isOT: !this.state.isOT });
                      }}>OT</Checkbox>
                      <Checkbox
                      disabled={this.state.AprovalLevel == "all" ? true : false}
                      checked={ this.state.AprovalLevel == "all" ? true : this.state.isFO ? true : false}
                      onChange={() => {
                        this.setState(
                          { isFO: !this.state.isFO });
                      }}>FO</Checkbox>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginTop: "3vw" }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <TouchableOpacity
                      onPress={() => { this.ResetOTApprovalDatas() }}
                      style={[
                        styles.BtnTouch,
                        {
                          backgroundColor: color.white,
                          width: "6vw",
                          borderColor: color.themeDark,
                          borderRadius: '0.26vw',
                          borderWidth: 1
                        },
                      ]}
                    >
                      <Text style={[styles.ButtonText, { color: color.themeDark }]}>Clear</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        this.updateAdminSetting()
                        this.setState({
                          isUserAccessEdit: false
                        })
                      }}
                      style={[
                        styles.BtnTouch,
                        {
                          backgroundColor: color.themeDark,
                          width: "6vw",
                        },
                      ]}
                    >
                      <Text style={styles.ButtonText}>Update</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View> : null
        }
      </View>
    );
  }
  renderCheckBox = (key, value, title, disable = false) => {
    return(
     <View style={{ flexDirection: "row", marginTop: "1vw" }}>
      <Checkbox checked={value} value={value} style={{ alignSelf: "center" }} disabled={disable} onChange={() => {
        var states = this.state
        states[key] = !value
        this.setState({
          states
        }, () => {
          if( !this.state.isServiceDetailed || !this.state.isServiceOverview || !this.state.isDayEndOverview || !this.state.isCorporateBillsReport || !this.state.isOpticalCRMSales ) {
            this.setState({
              isServiceReport: false,
            })
          }
          if( !this.state.isPatientTypeReprt || !this.state.isAppointmentReport || !this.state.isReffralReport || !this.state.isReferredSourceReport ) {
            this.setState({
              isPatientReport: false
            })
          } 
          if( !this.state.isIpDoctorReport || !this.state.isIpCurrentBillStatusReport ) {
            this.setState({
              isIPReports: false,
            })
          }
          if( this.state.isServiceDetailed && this.state.isServiceOverview && this.state.isDayEndOverview && this.state.isCorporateBillsReport && this.state.isOpticalCRMSales ) {
            this.setState({
              isServiceReport: true,
            })
          } 
          if( this.state.isPatientTypeReprt && this.state.isAppointmentReport && this.state.isReffralReport && this.state.isReferredSourceReport ) {
            this.setState({
              isPatientReport: true
            })
          }
          if( this.state.isIpDoctorReport && this.state.isIpCurrentBillStatusReport ) {
            this.setState({
              isIPReports: true,
            })
          }
        })
      }} >
        <Text style={{ fontSize: '0.8vw', marginLeft: "0.5vw", alignSelf: "center" }}>{title}</Text></Checkbox>
     </View>    
    )
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw", key, value) {
    return (
      <View style={{ flexDirection:"row", flex: flexWidth, height: '3vh'}}>
        <Checkbox checked={value} value={value} style={{ alignSelf: "center",  }} onChange={() => {
          var states = this.state
          states[key] = !value
          this.setState({
            states
          }, () => {
            this.setState({
              isServiceDetailed: this.state.isServiceReport,
              isServiceOverview: this.state.isServiceReport,
              isDayEndOverview: this.state.isServiceReport,
              isCorporateBillsReport: this.state.isServiceReport,
              isOpticalCRMSales: this.state.isServiceReport,
              isAppointmentReport: this.state.isPatientReport,
              isPatientTypeReprt: this.state.isPatientReport,
              isReffralReport: this.state.isPatientReport,
              isReferredSourceReport: this.state.isPatientReport,
              isIpDoctorReport: this.state.isIPReports,
              isIpCurrentBillStatusReport: this.state.isIPReports
            })
          })
        }} />
          <Text style={{ fontSize: fontsize, marginLeft: "0.5vw", alignSelf: "center" }}>{title}</Text>
      </View>
    )
  }
}

export default SettingsConfig;

const styles = StyleSheet.create({
  MainView: {
    margin: "1vw",
    height:'33vw'
  },
  OptionsWrapper: {
    width: "100%",
    height: "3.2vw",
    borderWidth: 1,
    borderColor: color.lightGray,
    borderRadius: "0.26vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1vw",
  },
  OptionsText: {
    fontSize: "1vw",
    fontWeight: "500",
    marginLeft: "1vw",
  },
  OptionsActionView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "2vw",
  },
  CheckBoxText: {
    fontSize: "0.84vw",
    color: "#888888",
    marginRight: "0.65vw",
  },
  TextInputStyles: {
    height: "2vw",
    width: "10vw",
    borderWidth: "0.065vw",
    borderRadius: "0.26vw",
    marginRight: "0.65vw",
    paddingHorizontal: "0.52vw",
    color: color.black,
    fontSize: "0.91vw",
  },
  InputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  BtnTouch: {
    paddingTop: "0.2vw",
    paddingLeft: "0.5vw",
    paddingRight: "0.5vw",
    paddingBottom: "0.2vw",
    borderRadius: ".25vw",
    width: "3vw",
    marginRight: "1vw",
    height: "2vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ButtonText: {
    color: color.white,
    fontSize: ".85vw",
    fontWeight: "500",
  },
  FilterPopUpView: {
    borderRadius: "0.5vw",
    width: "55%",
    height: "43vh",
    // justifyContent: 'center',
    paddingHorizontal: "0.65vw",
    position: "absolute",
    backgroundColor: "white",
    borderColor: color.themeDark,
    borderWidth: 1,
    right: 10,
    display: "flex",
    flexDirection: "column",
  },
  FiterTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "2vw",
  },
  FilterRows: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginVertical: "1vw",
    height: "1.5vw",
  },
  ImageStyle: {
    height: "1.5vw",
    width: "1.5vw",
    position: "relative",
    top: "0.2vw",
  },
  FilterHeadTxt: {
    fontSize: "1vw",
    width: "90%",
    textAlign: "center",
    marginTop: "0.2vw",
    fontWeight: "500",
  },
  SearchInput: {
    height: "2vw",
    borderWidth: "0.065vw",
    borderColor: color.themeDark,
    borderRadius: "0.26vw",
    width: "15%",
    marginRight: "0.65vw",
    width: "12vw",
    marginLeft: "0.65vw",
    paddingHorizontal: "0.52vw",
    color: color.black,
    fontSize: "0.91vw",
    zIndex: -2,
  },
  subContainer: {
    flexDirection: "row", 
    justifyContent: "flex-start", 
    alignContent: "center",
     backgroundColor: color.red, 
    //  padding: 8, 
     borderRadius: 4,
     marginTop:'0.5vw', 
     flex:1
  },
  pickerView: {
    backgroundColor: color.white,
    borderRadius: "4px",
    height: "2.2vw",
    fontSize: ".85vw",
    paddingLeft: ".3vw",
    width: "10vw"
  },
});
