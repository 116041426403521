//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection, CommonHistorySectionHeader, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Descriptions } from 'antd';
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
var Messages = require('../../../../utils/InfoMessages')
// create a component
const pageName = "isFamilyHistory"

const familyMemberList = [
    { label: "Father", value: "Father" },
    { label:"Mother", value:"Mother"},
    {label:"Brother", value:"Brother"},
    {label:"Sister",value:"Sister"}
];

const familyHistoryList = [
    {label: "Joint Pain", value: "Joint Pain" },
    {label: "Asthma", value:"Asthma"},
    {label:"Seizures", value:"Seizures"},
    {label:"Stroke", value:"Stroke"},
    {label:"Blood Problems", value:"Blood Problems"},
    {label:"Cancer",value:"Cancer"},
    {label:"Heart Disease", value:"Heart Disease"},
    {label:"Thyroid Disorder" , value:"Thyroid Disorder"},
    {label:"High BP", value:"High BP"},
    {label:"Diabetes / Blood Sugar",value:"Diabetes / Blood Sugar"},
    {label:"Mental Illness", value:"Mental Illness"}
]
export class FamilyHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            newButtonValue: "",

            // selectedProbloms: {},
            // problomsList: problomsList,
            // showProblomsNewButtonFlag: true,

            // selectedBloodGroup: {},
            // bloodGroupList: bloodGroupList,
            // showBloodGroupNewButtonFlag: true,

            selectedFamilyMember:{},
            showFamilyMemberNewButtonFlag:true,
            familyMemberList:familyMemberList,

            selectedFamilyHistory:[],
            showFamilyHistoryNewButtonFlag:true,
            familyHistoryList:familyHistoryList,

            commentTextBoxValue: "",
            comment:"",

            DescriptionTextBoxValue:"",
            description:"",
            familyMember:[],
            deletedDiseaseNames:[],
            comments:"",
            diseaseNames:[],
            
            id:"",
            disableButton:false

        }
    }
    
    componentWillReceiveProps(props){
        console.log("item--------------> "+JSON.stringify(props.editHistoryItem))

        if(props.editHistoryItem && props.editHistoryItem.pageName == pageName){
            if(props.editHistoryItem.editItem){
                var  item = props.editHistoryItem.editItem;
                var states = this.state;
                var familyDetails =item.item
                var prepareFamilyMember = {label:familyDetails.family_member,value:familyDetails.family_member}
                var {familyMemberList,familyHistoryList}= this.state;

                
                states["comment"]= item.comment;

                states["selectedFamilyMember"]=prepareFamilyMember
                familyMemberList.push(prepareFamilyMember);
                familyMemberList = this.removeDuplicate(familyMemberList);

                states["commentTextBoxValue"]= familyDetails.description;
                states["id"]= familyDetails.id;

                if(familyDetails.disease_names.length > 0){
                    var selecetedHistory=[]
                    for (let i = 0; i < familyDetails.disease_names.length; i++) {
                        var prepareData = {label:familyDetails.disease_names[i],value:familyDetails.disease_names[i]}
                        selecetedHistory.push(prepareData);
                        familyHistoryList.push(prepareData)
                    }
                    states["selectedFamilyHistory"]=selecetedHistory
                    familyHistoryList = this.removeDuplicate(familyHistoryList);
                }

                // states["selectedFamilyMember"]=prepareFamilyMember
                // selectedFamilyHistory.push(prepareFamilyMember);
                // familyMemberList = this.removeDuplicate(familyMemberList);

                this.setState({
                    states ,familyMemberList,familyHistoryList,                                  disableButton: true,
                    disableButton: true,

                },()=>{
                    this.props.editHistory("",pageName)
                })
            }
        }
    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        
        if (key == "showFamilyHistoryNewButtonFlag") {
            states[key] = false;
            states["showFamilyMemberNewButtonFlag"] = true
            
        }else if (key == "showFamilyMemberNewButtonFlag") {
            states[key] = false;
            states["showFamilyHistoryNewButtonFlag"] = true
            
        }
        
        else if (key == "selectedFamilyHistory") {
              // start the multi select button function 
              var { selectedFamilyHistory } = this.state;
              var getSelectedValue;
              var spliceData;
  
              if (selectedFamilyHistory.length > 0) {
                  for (var i = 0; i < selectedFamilyHistory.length; i++) {
                      if (selectedFamilyHistory[i].value == selectedValue.value) {
                          spliceData = selectedFamilyHistory[i];
                          selectedFamilyHistory.splice(i, 1)
                          var data = this.removeDuplicate(selectedFamilyHistory); // pls add the remove duplicate function in this file 
                          this.setState({ selectedFamilyHistory: data })
                          return
                      } else {
                          if (spliceData && spliceData.value !== selectedValue.value) {
                          } else {
                              getSelectedValue = selectedValue
                          }
                      }
                  }
  
                  if (getSelectedValue) { selectedFamilyHistory.push(selectedValue); }
              } else {
                selectedFamilyHistory.push(selectedValue)
              }
  
              var data = this.removeDuplicate(selectedFamilyHistory);
              this.setState({ selectedFamilyHistory: data })
              // end the multi select button function
            states[key] = false;
            states["showFamilyHistoryNewButtonFlag"] = true
        }  else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var { familyMemberList,familyHistoryList,selectedFamilyHistory } = this.state;
        var states = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showFamilyHistoryNewButtonFlag) {
            
            familyHistoryList.push(preparData);
            familyHistoryList = this.removeDuplicate(familyHistoryList);

            selectedFamilyHistory.push(preparData)
            selectedFamilyHistory = this.removeDuplicate(selectedFamilyHistory);

            states["newButtonValue"] = "";
            states["showFamilyHistoryNewButtonFlag"] = true;
            // states["selectedFamilyHistory"] = preparData;
        }

        if (!this.state.showFamilyMemberNewButtonFlag) {
            familyMemberList.push(preparData);
            familyMemberList = this.removeDuplicate(familyMemberList);
            states["newButtonValue"] = "";
            states["showFamilyMemberNewButtonFlag"] = true;
            states["selectedFamilyMember"] = preparData;
        }

        this.setState({
            familyMemberList,familyHistoryList, states
        })

    }


    richTextBox(key,value) {
        return (
            <View>
                <TextInput
                    multiline={true}
                    style={{paddingTop:10,paddingLeft:10,width:"85%",alignSelf:'center',marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, height: 60 }}
                    value={value}
                    onChangeText={(text) => {
                        var states = this.state;
                        states[key]= text
                        this.setState({
                            states
                        })
                    }}
                />
            </View>
        )
    }


    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData,disableFlag) {
        // console.log(JSON.stringify(selectedValue))
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[{ marginTop: 10, paddingLeft: 20, }]}>

                {/* <View>
                    <CommonHistorySectionHeader heading1={question} noOfColumn={1} />
                </View> */}

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                    {
                        data.map((item, index) => {
                            if(item.value&&item.label){
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        disable= {disableFlag? true : false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                            }
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        disable= {disableFlag? true : false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ paddingLeft: 15, width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    clearAllData() {
        var states=this.state
        states["selectedFamilyMember"]=[]
        states["selectedFamilyHistory"]=[]
        states["comment"]=""
        states["commentTextBoxValue"]=""
        states["disableButton"]=false
        
        this.setState({
            states
        })
     }

    onPressAddButton() {
        console.log(
            "patientAppointment --- > " +
            JSON.stringify(this.state.patientAppointment)
        );
        var diseaseName = [];
        var states = this.state;
        if(states.selectedFamilyHistory.length > 0){
            for (let i = 0; i < states.selectedFamilyHistory.length; i++) {
                diseaseName.push(states.selectedFamilyHistory[i].value) 
            }
        }
        console.log(
          "diseaseName --- > " +
            JSON.stringify(diseaseName)
        );
        var data = {
            patient_id: this.state.patientAppointment.patient_id,
            appointment_id: this.state.patientAppointment.appointment_id,
            disease_names:diseaseName,
            description:this.state.commentTextBoxValue,
            comments:this.state.comment,
            family_member:this.state.selectedFamilyMember.value,
            deleted_disease_names: [],
          
        };

    //     "patient_id": 1,
    // "appointment_id":1,
    // "family_member":"Father",
    // "deleted_disease_names": [],
    // "disease_names":["Mental Illness", "Joint Pain", "Stroke"],
    // "description": "desc",
    // "comments": "comments"

        if(this.state.id){
            data["id"]=this.state.id
        }
        if(diseaseName.length==0 && Object.keys(this.state.selectedFamilyMember).length==0){
            this.props.showResposeValue("error",Messages.HistorySectionValitation)
        }else{
        var service_url = Constants.POST_FAMILY_HISTORY;
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.FamilyHistorySaveSuccess,
            this.FamilyHistorySaveFailure
        );
    } 
    }
    FamilyHistorySaveSuccess = (success) => {
        // alert(JSON.stringify(success));
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.props.refreshRighSideComponent(pageName)
            
        } else {
            this.props.showResposeValue("error", success.message)
        }
        {this.clearAllData()}
    };
    FamilyHistorySaveFailure = (error) => {
      //  alert(JSON.stringify(error));
        this.props.showResposeValue("error", error.message)
    };


    render() {
        return (
            <View style={styles.container}>

                <DoctorNotesCommonRightSideHeader title={"Family History"} clearAllData={this.clearAllData.bind(this)} />
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Select Family Member"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedFamilyMember",
                        this.state.selectedFamilyMember,
                        "showFamilyMemberNewButtonFlag",
                        this.state.showFamilyMemberNewButtonFlag,
                        this.state.familyMemberList,
                        this.state.disableButton)}
                       
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Family History"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedFamilyHistory",
                        this.state.selectedFamilyHistory,
                        "showFamilyHistoryNewButtonFlag",
                        this.state.showFamilyHistoryNewButtonFlag,
                        this.state.familyHistoryList,
                        this.state.disableButton)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Description"} noOfColumn={1} />
                    {this.richTextBox("commentTextBoxValue",this.state.commentTextBoxValue)}
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Comment"} noOfColumn={1} />
                    {this.richTextBox("comment",this.state.comment)}
                </View>
                <View style={{width:100,alignSelf:"center"}}>
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
