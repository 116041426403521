import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "natureOfDelivery"

const PlaceofDelivery = [
    { label: "Home", value: "Home" },
    { label: "Hospital", value: "Hospital" },
];
const GestationalAge = [
    { label: "Pre Term", value: "Pre Term" },
    { label: "Full Term", value: "Full Term" },
    { label: "Post Term", value: "Post Term" },
];

const TypeofDelivery = [
    { label: "Normal Vaginal Delivery", value: "Normal Vaginal Delivery" },
    { label: "Cesarean", value: "Cesarean" },
    { label: "Vaccum", value: "Vaccum" },
    { label: "Vaginal birth after cesarean(VBAC)", value: "Vaginal birth after cesarean(VBAC)" },
    { label: "Forceps", value: "Forceps" },
];

const topSubDropDownList = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
]

export class NatureOfDeliveryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",


            selectedPlaceofDelivery: {},
            selectedGestationalAge: {},
            selectedTypeofDelivery: {},

            birthWight: "",

            detailsOfAPGARScore: "",
            selectedTopSubDropDown: "0",

            jaundiceFlag: false,
            detailsOfJaundice: "",

            congenitalAnomaliesFlag: false,
            detailsOfCongenitalAnomalies: "",


            selectedFirstTwoWeeks: [],
            firstTwoWeeksList: [],
            firstTwoWeeksNewbuttonFlag: true,

            detailsOfOthers: ""

        }
    }

    componentDidMount() {
        this.getNatureOfDeliveryFirstTwoWeek()
    }

    getNatureOfDeliveryFirstTwoWeek() {
        var service_url = Constants.PSY_NATURE_DELIVERY + "?search_key=template";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getNatureOfDeliveryFirstTwoWeekSuccess,
            this.getNatureOfDeliveryFirstTwoWeekFailure
        );
    }

    getNatureOfDeliveryFirstTwoWeekSuccess = success => {
        if (success.status == "success") {
            var datas = []
            if (success.data.length > 0) {
                for (let i = 0; i < success.data.length; i++) {
                    var prepareData = { label: success.data[i], value: success.data[i], }
                    datas.push(prepareData);
                }
            }

            this.setState({
                firstTwoWeeksList: datas
            })

        }
    }

    validateButtons(data) {
        var prepareData = {}
        if (data) {
            prepareData = { label: data, value: data };
        }
        return prepareData
    }


    preparingForList(infrction) {
        var data = []
        if (infrction) {
            var getPrepareDatas = []
            var converToArray = infrction.split(",");
            for (let i = 0; i < converToArray.length; i++) {
                var prepareData = { label: converToArray[i], value: converToArray[i] }
                getPrepareDatas.push(prepareData);
            }
            data = getPrepareDatas;
        } else {
            data = []
        }
        return data
    }


    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                // if()
                states["selectedPlaceofDelivery"] = this.validateButtons(data.nature_of_delivery_place);
                states["selectedGestationalAge"] = this.validateButtons(data.nature_of_delivery_term);
                states["selectedTypeofDelivery"] = this.validateButtons(data.nature_of_delivery_type);

                states["birthWight"] = data.birth_weight;

                states["detailsOfAPGARScore"] = data.apgar_score_details;
                states["selectedTopSubDropDown"] = data.apgar_score;

                states["jaundiceFlag"] = data.jaundice;
                states["detailsOfJaundice"] = data.jaundice_detail;

                states["congenitalAnomaliesFlag"] = data.congenital_anomalies;
                states["detailsOfCongenitalAnomalies"] = data.congenital_anomalies_detail;

                states["selectedFirstTwoWeeks"] = this.preparingForList(data.first_two_weeks);
                states["detailsOfOthers"] = data.others;


                states["id"] = data.id;



                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(
        cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        selectBtnKey, selectedBtnValue, buttonList, showNewButton = false, newButtonKey = "", newbuttonFlag = false,
        topSubTextBoxKey = "", topSubTextBoxValue = "", showTopSubTextBox = false, topSubTextBoxLabel = "",
        topSubDropDownKey = "", topSubDropDownValue = "", showTopSubDropDown = false, topSubDropDownList = []
    ) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // // drop down list 
                // dropDownList = {
                //   ["mother","father","son","mom"]
                // }
                // selectedDropDownlist={this.state.selectedDropDownlist}
                // dropDownListKey={"selectedDropDownlist"}
                // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
                // // label with button select 

                selectedBtnValue={selectedBtnValue}
                selectBtnKey={selectBtnKey}
                buttonList={buttonList}

                showNewButton={showNewButton}
                newButtonKey={newButtonKey}
                newbuttonFlag={newbuttonFlag}
                onPressNewButton={this.onPressNewButton.bind(this)}
                onPressInfections={this.onPressButton.bind(this)}
                onSubmitToAddNewButtonValue={this.onSubmitToAddNewButtonValue.bind(this)}


                // sub textbox in top view
                topSubTextBoxKey={topSubTextBoxKey}
                topSubTextBoxLabel={topSubTextBoxLabel}
                topSubTextBoxValue={topSubTextBoxValue}
                showTopSubTextBox={showTopSubTextBox}
                onChangeSubTextBoxValue={this.onChangeSubTextBoxValue.bind(this)}

                // sub Dropdown in top view
                topSubDropDownKey={topSubDropDownKey}
                topSubDropDownValue={topSubDropDownValue}
                topSubDropDownList={topSubDropDownList}
                showTopSubDropDown={showTopSubDropDown}
                onChangeSubDropDownValue={this.onChangeSubDropDownValue.bind(this)}
            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeSubDropDownValue(key, value) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    onChangeSubTextBoxValue(key, value) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    onSubmitToAddNewButtonValue(value) {
        var prepareData = { label: value, value: value };
        var { firstTwoWeeksList, selectedFirstTwoWeeks } = this.state;
        selectedFirstTwoWeeks.push(prepareData);
        selectedFirstTwoWeeks = this.removeDuplicate(selectedFirstTwoWeeks);

        firstTwoWeeksList.push(prepareData)
        firstTwoWeeksList = this.removeDuplicate(firstTwoWeeksList);

        this.setState({
            selectedFirstTwoWeeks, firstTwoWeeksList,
            firstTwoWeeksNewbuttonFlag: true
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onPressNewButton(key) {
        var states = this.state;
        states[key] = false;
        this.setState({ states })
    }


    onPressButton(key, selectedValue) {

        if (key == "selectedFirstTwoWeeks") {

            var { selectedFirstTwoWeeks } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedFirstTwoWeeks.length > 0) {
                for (var i = 0; i < selectedFirstTwoWeeks.length; i++) {
                    if (selectedFirstTwoWeeks[i].value == selectedValue.value) {
                        spliceData = selectedFirstTwoWeeks[i];
                        selectedFirstTwoWeeks.splice(i, 1)
                        var data = this.removeDuplicate(selectedFirstTwoWeeks);
                        this.setState({ selectedFirstTwoWeeks: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedFirstTwoWeeks.push(selectedValue); }
            } else {
                selectedFirstTwoWeeks.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedFirstTwoWeeks);
            this.setState({ selectedFirstTwoWeeks: data })

        } else {
            var states = this.state;
            states[key] = selectedValue
            this.setState({ states })
        }
        this.setState({
            firstTwoWeeksNewbuttonFlag: true
        })
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""

        states["selectedPlaceofDelivery"] = {};
        states["selectedGestationalAge"] = {};
        states["selectedTypeofDelivery"] = {};

        states["birthWight"] = "";

        states["detailsOfAPGARScore"] = "";
        states["selectedTopSubDropDown"] = "0";

        states["selectedTopSubDropDown"] = false;

        states["jaundiceFlag"] = false;
        states["detailsOfJaundice"] = "";

        states["congenitalAnomaliesFlag"] = false;
        states["detailsOfCongenitalAnomalies"] = "";


        states["selectedFirstTwoWeeks"] = [];
        states["firstTwoWeeksNewbuttonFlag"] = true;

        states["detailsOfOthers"] = "";


        this.setState({ states })
    }

    getListOfTrimesterValue(list) {
        var data;
        if (list.length > 0) {
            var getList = []
            for (let i = 0; i < list.length; i++) {
                getList.push(list[i].value)
            }
            data = getList.toString()
        } else {
            data = ""
        }
        return data
    }
    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "nature_of_delivery_place": states.selectedPlaceofDelivery && states.selectedPlaceofDelivery.value ? states.selectedPlaceofDelivery.value : "",
                "nature_of_delivery_term": states.selectedGestationalAge && states.selectedGestationalAge.value ? states.selectedGestationalAge.value : "",
                "nature_of_delivery_type": states.selectedTypeofDelivery && states.selectedTypeofDelivery.value ? states.selectedTypeofDelivery.value : "",
                "birth_weight": states.birthWight,
                "apgar_score": states.selectedTopSubDropDown,
                "apgar_score_details": states.detailsOfAPGARScore,
                "jaundice": states.jaundiceFlag,
                "jaundice_detail": states.detailsOfJaundice,
                "congenital_anomalies": states.congenitalAnomaliesFlag,
                "congenital_anomalies_detail": states.detailsOfCongenitalAnomalies,
                "first_two_weeks": this.getListOfTrimesterValue(states.selectedFirstTwoWeeks),
                "others": states.detailsOfOthers
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_NATURE_DELIVERY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePerinatalFactorsSuccess,
                this.savePerinatalFactorsFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Natuer of delivery already added please click on edit icon to update the values")
        }
    }

    savePerinatalFactorsSuccess = success => {
        if (success.status == "success") {
            this.clearAllData();
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePerinatalFactorsFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Nature Of Delivery"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Place of Delivery", "",
                    "", true,
                    "", "",
                    "selectedPlaceofDelivery", this.state.selectedPlaceofDelivery, PlaceofDelivery
                )}
                {this.renderHistoryComponent(
                    "Gestational Age", "",
                    "", true,
                    "", "",
                    "selectedGestationalAge", this.state.selectedGestationalAge, GestationalAge
                )}

                {this.renderHistoryComponent(
                    "Type of Delivery", "",
                    "", true,
                    "", "",
                    "selectedTypeofDelivery", this.state.selectedTypeofDelivery, TypeofDelivery
                )}


                {this.renderHistoryComponent(
                    "Birth Weight", "",
                    "", true,
                    "", "",
                    "", "", "", false,
                    "", false,
                    "birthWight", this.state.birthWight, true, "Kgs"
                )}

                {this.renderHistoryComponent(
                    "APGAR Score", "Enter details",
                    "", false,
                    (this.state.selectedTopSubDropDown !== "0") ? "detailsOfAPGARScore" : "", this.state.detailsOfAPGARScore,
                    "", "", "", false,
                    "", false,
                    "", "", false, "",
                    "selectedTopSubDropDown", this.state.selectedTopSubDropDown, true, topSubDropDownList
                )}


                {this.renderHistoryComponent(
                    "Jaundice", "Enter details",
                    "jaundiceFlag", this.state.jaundiceFlag,
                    "detailsOfJaundice", this.state.detailsOfJaundice
                )}

                {this.renderHistoryComponent(
                    "Congenital Anomalies", "Enter details",
                    "congenitalAnomaliesFlag", this.state.congenitalAnomaliesFlag,
                    "detailsOfCongenitalAnomalies", this.state.detailsOfCongenitalAnomalies
                )}

                {this.renderHistoryComponent(
                    "First two weeks", "",
                    "", true,
                    "", "",
                    "selectedFirstTwoWeeks", this.state.selectedFirstTwoWeeks, this.state.firstTwoWeeksList, true,
                    "firstTwoWeeksNewbuttonFlag", this.state.firstTwoWeeksNewbuttonFlag
                )}

                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "", false,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
