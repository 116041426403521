import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
const navigationHeader = [
  { label: "Statistics", value: "superAdminStatistics" },
  { label: "Billing Transaction", value: "superAdminBillingTransaction" },
];
const navigationSubHeader = [
  { label: "All Billing Statistics", value: "allBillingStatistics" },
  { label: "InPatient Billing Statistics", value: "inPatientBilling" },
  { label: "OutPatient Billing Statistics", value: "outPatientBilling" },
  { label: "Refferal Billing Statistics", value: "refferalBilling" },
  { label: "Pharmacy Billing Statistics", value: "pharmacyBilling" },
  { label: "Laboratory Billing Statistics", value: "laboratoryBilling" },
];
export default class RefferalBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  render() {
    return (
      <View>
          <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"superAdminStatistics"}
          isNavigationBorder={true}
        />
        <View style={styles.mainContainer}>
          <View style={{ width: "100%" }}>
            <NavigationTopHeader
              changeTabSection={this.changeTabSection.bind(this)}
              navigationHeaderList={navigationSubHeader}
              selectedTab={"refferalBilling"}
              // isNavigationBorder={true}
            />
          </View>
          <View style={styles.subContainer}></View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  mainContainer: {
    margin: "0.7vw",
    marginVertical: "0vw",
    backgroundColor: color.themeShade,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowColor: "#171717",
    shadowOffset: { width: "0.07vw", height: "0.7vh" },
    height: "81vh",
  },
  subContainer: {
    width: "100%",
    height: "20vh",
    backgroundColor: color.white,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowColor: "#171717",
    shadowOffset: { width: "0.07vw", height: "0.7vh" },
    marginTop: "0.7vw",
  },
});
