import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput } from "react-native";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { color } from "../../../../../../styles/Color";
import { Constants } from "../../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader } from "../../../../BaseComponent";
import Icon from "react-native-vector-icons/FontAwesome";


const tempRows = {
    "brand_name": "",
    "model_name": "",
    "iol_type": "",
    "iol_type_id": "",
    "iol_power": "",
}

export default class AddNewIOL extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            iolRows: [],
            responseMessage: "",
            showErrorResponse: false,
            iolTypeSuggestion:[],
            iolTypeShow: false,
            selectedTypeIndex: null
        }
    }

    componentDidMount(){
        this.getIolTypeSuggestion()
    }

    getIolTypeSuggestion = () => {
        var url = Constants.OT_INVENTORY_IOL_TYPE_SUGGESTION
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIolTypeSuggestionSuccess,
            this.getIolTypeSuggestionFailure
        );
    }

    getIolTypeSuggestionSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
              iolTypeSuggestion: response.data,
            })
        }
    }

    newIolPost = () => {
        var { iolRows } = this.state
        var url = Constants.OT_INVENTORY_IOL_NEW_IOL

        let current_row = iolRows.length;
        let bill_line_items = [];
        var isManitory = "";

        for (let i = 0; i < current_row; i++) {
            if (iolRows[i].brand_name || iolRows[i].model_name || iolRows[i].iol_type_id || iolRows[i].iol_power) {
                bill_line_items.push(iolRows[i]);
            } 
        }

         // repeated drug restriction

        var isDuplicate = false
        var brand_name = bill_line_items && bill_line_items.length > 0 && bill_line_items.map(item => { return item.brand_name })
        isDuplicate = brand_name && brand_name.length > 0 && brand_name.some((item, index)=> (
        brand_name.indexOf(item) != index
        ))

        for ( let i=0; i < bill_line_items.length; i++ ) {
            if(!bill_line_items[i].brand_name) {
                isManitory = "Please enter the brand name";
            } else if(!bill_line_items[i].model_name) {
                isManitory = "Please enter the modal name"
            } else if(!bill_line_items[i].iol_type_id) {
                isManitory = "Please enter the IOL type"
            } else if(!bill_line_items[i].iol_power || parseFloat(bill_line_items[i].iol_power) == 0 ) {
                isManitory = "Please enter the IOL power"
            }
        }

        if (isDuplicate) {
            this.props.showResposeValue("error", "Same drug repeated multiple times")
        } else if(isManitory) {
            this.props.showResposeValue("error", isManitory)
        } else if(bill_line_items && bill_line_items.length == 0 ) {
            this.props.showResposeValue("error", "Please enter the details to proceed")
        }else {
            for ( let i=0; i < bill_line_items.length; i++ ) {
   
              var brand_name = (bill_line_items[i].brand_name).trim();
              bill_line_items[i]["brand_name"] = brand_name;
   
              var model_name = (bill_line_items[i].model_name).trim()
              bill_line_items[i]["model_name"] = model_name;
   
              var iol_type_id = (bill_line_items[i].iol_type_id)
              bill_line_items[i]["iol_type_id"] = parseInt(iol_type_id);
   
              var iol_power = (bill_line_items[i].iol_power).trim()
              bill_line_items[i]["iol_power"] = iol_power;
   
            }

            var data = {
                "products": bill_line_items
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.newIolPostSuccess,
                this.newIolPostFailure
              );
        }

    }

    newIolPostSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.setState({
                iolRows: []
            }, () => {
                this.props.changePage("newIolRequest")
            })
        }else{
            this.props.showResposeValue("error", response.message)
        }
    }

    newIolPostFailure = (response) => {

    }

    renderTextInput = (stateKey, value, flex, index) => {
        var { iolRows, iolTypeShow, iolTypeSuggestion, selectedTypeIndex } = this.state
        return (
            <View style={{ flex: flex, height: "8vh" }}>
                <TextInput
                    style={{
                        padding: 6,
                        marginRight: 20,
                        marginTop: 10,
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.9vw'
                    }}
                    value={value}
                    onFocus={() => {
                        if(stateKey === "iol_type"){
                            this.setState({
                                iolTypeShow: true,
                                selectedTypeIndex: index,
                            })
                        }
                    }}
                    onBlur={() => {
                        this.setState({
                            iolTypeShow: false,
                            selectedTypeIndex: null
                        })
                    }}
                    editable={stateKey === "iol_type" ? false : true}
                    onChangeText={(text) => {
                        iolRows[index][stateKey] = text
                        this.setState({
                            iolRows
                        })
                    }}
                />
                {(iolTypeShow && iolTypeSuggestion.length > 0 && selectedTypeIndex === index && stateKey === "iol_type") ? 
                <View style={{ zIndex: 1, position: 'absolute', marginTop: "7vh", width: "80%"}}>
                <ScrollView
                style={{
                    maxHeight: '25vh', marginBottom: 10, flex: 1,
                    backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                {iolTypeSuggestion.map((value, index) =>
                <View style={{ height: 30 }}>
                <TouchableOpacity key={index}
                    style={{ marginLeft: 5, marginTop: 5 }}
                    onPress={ () => {
                        iolRows[selectedTypeIndex]["iol_type_id"] = value.iol_type_id
                        iolRows[selectedTypeIndex]["iol_type"] = value.iol_type
                        this.setState({
                            iolRows,
                            iolTypeShow: false,
                        })
                    }}
                >
                    <Text style={{ color: color.white,}}>{value.iol_type}</Text>

                </TouchableOpacity>
                </View>
                )} 
                </ScrollView>
                </View>
                : null }
            </View>
        )
    }

    clearTableData = () => {
        this.setState({
            iolRows: [],
            iolTypeShow: false,
            selectedTypeIndex: null,
        })
    }

    renderTableView = () => {
        let { iolRows } = this.state
        if (iolRows.length < 10) {
            for (let i = iolRows.length; i < 10; i++) {
                iolRows.push(JSON.parse(JSON.stringify(tempRows)))

            }
        }
        return(
            <View>
                <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Model Name"}
                heading4={"IOL Type"}
                heading5={"IOL Power"}
                columnSize={[0.05, 0.3, 0.3, 0.18, 0.17]}  //total value is == of 1
                noOfColumn={5}
                />
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '55vh' }}>
                {iolRows && iolRows.map((item, index) => {
                    var sno = index + 1
                    var zIndexData = (index + 1) * (-100);
                    return (
                        <View style={{ flexDirection: "row", flex: 1,  zIndex: zIndexData }} >
                            <View  style={{ flex: 0.05, marginTop: 10, alignItems: "flex-start", marginHorizontal: 20}}><Text>{sno}</Text></View>
                            {this.renderTextInput('brand_name', item.brand_name, 0.3, index)}
                            {this.renderTextInput('model_name', item.model_name, 0.3, index)}
                            {this.renderTextInput('iol_type', item.iol_type, 0.18, index)}
                            {this.renderTextInput('iol_power', item.iol_power, 0.14, index)}
                            <TouchableOpacity 
                            style={{flex: 0.03, alignItems: 'center', justifyContent: 'center'}}
                            onPress={() => {
                                let iolAddRows = iolRows.slice(0, index).concat(iolRows.slice(index + 1, iolRows.length))
                                this.setState({
                                    iolRows: iolAddRows
                                })
                            }}
                            >
                            <Icon size={'1.5vw'} name={"close"} color={"#000"} />
                            </TouchableOpacity>
                        </View>
                    )
                })}  
                <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 10, marginRight: 20 }}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add Item"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => this._addExtraIolAddRows()}
                buttonKey={"addIolNewRows"} />
                </View>    
                </ScrollView>
                <View style={{ flexDirection: "row", justifyContent: "center", alignSelf: "center", marginTop: '5vh' }}>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Save"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.newIolPost()}}
                buttonKey={"saveIolNewRows"} />
                </View>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.clearTableData()}}
                buttonKey={"clearIolNewRows"} />
                </View>
                </View>
            </View>
        )
    }

    _addExtraIolAddRows = () => {
        let { iolRows } = this.state

        let current_row = iolRows.length;
        let bill_line_items = [];
        let iolRows_no_manitory_fields = [];
        let add_flag = false;

        for (let i = 0; i < current_row; i++) {

            if (iolRows[i].brand_name && iolRows[i].model_name && iolRows[i].iol_type_id && iolRows[i].iol_power) {
                    bill_line_items.push(iolRows[i]);
            }
            else if (!iolRows[i].brand_name || !iolRows[i].model_name || !iolRows[i].iol_type_id || !iolRows[i].iol_power) {
                iolRows_no_manitory_fields.push(iolRows[i])
            }
        }

        if ((current_row - bill_line_items.length) === 0) {
            add_flag = true;
        }

        if (add_flag) {

            let new_row = iolRows.length + 5

            for (let i = current_row; i < new_row; i++) {
                iolRows.push(JSON.parse(JSON.stringify(tempRows)));
            }

            this.setState({
                iolRows,
            })
        }else if (iolRows_no_manitory_fields.length > 0) {
            this.props.showResposeValue("error", "Invalid action")
        }
    }

    render(){
        return(
            <View>
                <View style={{flexDirection: "row", justifyContent: "flex-start", paddingHorizontal: 20, paddingVertical: 20}}>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("iolStockList")
                }}
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>IOL Stock List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>Add New IOL</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("newIolRequest")
                }}
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>Request</Text>
                </TouchableOpacity>
            </View>
            <View style={{padding: 20}}>
                {this.renderTableView()}
            </View>
            </View>
        )
    }
}
