import { StyleSheet, Dimensions, Platform } from "react-native";
import { color } from "./Color";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
const platform = Platform.OS;

const contentColumnBGColor = color.white;
var themePrimaryColor = "#04c6bf";
var themeBorderColor = "#CDD1D5"
export default StyleSheet.create({
    PSYRatingScaleRadioButtonSize: {
        fontSize: 24
    },
    ipBedChargeHeader: {
        fontSize: 12,
        fontWeight: "500", width: 100, paddingVertical: 5, paddingLeft: 8, backgroundColor: color.sectionHeadingDarkColor
    },

    ipAdmissionSectionView: {
        // marginTop:10,
        backgroundColor: color.sectionBackgroundColor, marginHorizontal: 8, borderRadius: 4, paddingHorizontal: 8, paddingVertical: 10
    },

    doctorNotesSectionView: {
        marginTop: '0.6vw',
        backgroundColor: color.sectionBackgroundColor, marginHorizontal: '0.5vw', borderRadius: 4, paddingHorizontal: '2vw', paddingVertical: '0.6vw'
    },
    historyHeaderFontStyle: { fontWeight: '500', fontSize: '0.9vw' },
    historyHeaderFontStyleStartText: { fontWeight: '500', fontSize: '0.9vw', marginHorizontal: 20 },
    valueFontStyle: { fontSize: 14, color: color.black, fontWeight: '500' },

    historyYesButtonSelectedValue: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6, shadowRadius: 3,
        elevation: 5,
    },
    historyNoButtonSelectedValue: {
        backgroundColor: color.noButtonColor,
        borderRadius: 4,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6, shadowRadius: 3,
        elevation: 5,
    },
    historyNoButtonView: {
        height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
    },
    historyYoButtonView: {
        height: 30, width: 50, justifyContent: 'center', alignItems: 'center',
    },

    historyYesOrNOSectionRowView: {
        marginTop: 7,
        marginBottom: 7,
        height: 30, backgroundColor: color.white,
        alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between',
    },

    psyHistoryYesOrNOSectionRowView: {
        marginTop: 7,
        marginBottom: 7,

        backgroundColor: color.white,
        alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between',
    },

    historyYesOrNoSectionShadow: {
        backgroundColor: color.white,
        shadowColor: "#090F36",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5,
    },

    NutrionSectionShadow: {
        backgroundColor: color.white,
        shadowColor: "#090F36",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5,
    },
    NutrionSectionSectionRowView: {
        height: 30, backgroundColor: color.white,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6, shadowRadius: 3,
        elevation: 5,

        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5,

        backgroundColor: color.white,
        shadowColor: "#090F36",
        // shadowOffset: { width: 0, height: 2},
        // shadowOpacity: 0.6, shadowRadius: 3,
        // elevation: 5, 
    },

    NutritionButtontype:
    {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        alignSelf: 'flex-start',
        paddingLeft: 20,
        marginTop: 10
    },

    allButtonBorderRadius: {
        borderRadius: ".25vw"
    },

    fontSizeMedium: {
        fontSize: ".85vw"
    },

    componentBtnDDPadding: {
        borderWidth: 1,
        // borderRadius: 30,
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        // alignItems: "center",
        borderRadius: 4,
        alignSelf: "flex-start",
    },
    // componentBtnDDPadding:{
    //     borderWidth: 1,
    //     borderRadius: 30,
    //     paddingTop: 8,
    //     paddingLeft: 18,
    //     paddingRight: 18,
    //     paddingBottom: 8,
    //     // alignItems: "center",
    //     borderRadius:4,
    //     alignSelf: "flex-start",
    // },

    errorMessageView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5
    },
    errorMessageText: {
        color: "red",
        fontSize: 12,
        marginLeft: 15
    },
    exclamationIcon: {
        color: 'red',
        marginLeft: 10
    },
    commonTextBoxStyles: {
        borderColor: "#CDD1D5",
        borderRadius: 4,
        borderWidth: 1,
        width: "100%",
        height: "2.2vw",
        padding: "1vw",
    },

    rootView: {
        width: screenWidth,
        height: screenHeight,
        flex: 1,
        flexDirection: "row"
    },
    leftBarView: {
        width: platform === "web" ? "6%" : "10%",
        height: screenHeight,
        backgroundColor: color.themeBackgroud,
        flexDirection: "row",
        // alignItems: "center"
    },
    leftView: {
        width: platform === "web" ? "62.5%" : "58.5%",
        // height: screenHeight,
        backgroundColor: contentColumnBGColor,
        // flexDirection: "row"
    },
    leftReportView: {
        width: platform === "web" ? "93%" : "65%",
        height: screenHeight,
        backgroundColor: contentColumnBGColor,
        flexDirection: "row"
    },
    rightView: {
        padding: "0.65vw",
        // width: "31.5%",
        height: screenHeight,
        backgroundColor: color.themeShade,
        // alignItems: "center"
    },
    appointmentTableHead: {
        marginLeft: 25,
        marginRight: 25,
        height: 50,
        backgroundColor: color.appointmentTableHead,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
    appointmentTableHeadText: {
        fontSize: ".78vw",
        fontWeight: "700",
        color: color.appointmentTableHeadText,
        // alignSelf: "center",
        textAlign: "center"
    },
    appointmentTableRow: {
        height: 50,
        borderRadius: 20,
        marginLeft: 25,
        marginRight: 25,
        marginBottom: 10,
        // paddingLeft: 20,
        paddingRight: 20
    },
    appointmentTableStatusView: {
        // hjustifyContent:'center',
        // alignSelf:'center',
        // alignContent:'center',
        // alignItems:'center'   ,
        // backgroundColor:"white",
        // borderColor:"#888888",
        // borderRadius:50,
        // borderWidth:1,
        height: "3vw",
        width: "3vw",

    },
    appointmentTableRowImg: {
        height: platform === "web" ? "2.6vw" : "3.5vw",
        width: platform === "web" ? "2.6vw" : "3.5vw",
        borderRadius: "10vw",
    },

    pickerForScanReport: {
        backgroundColor: "#45B7B1",
        borderRadius: 20,
        height: 32,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 11
    },

    pickerRoundCornerView: {
        backgroundColor: color.white,
        borderRadius: '1.2vw',
        height: '2.1vw',
        paddingLeft: '0.6vw',
        fontSize: '0.8vw'
    },
    pickerView: {
        backgroundColor: color.white,
        borderRadius: "4px",
        height: "2vw",
        fontSize: ".85vw",
        paddingLeft: ".3vw",
        marginRight: "1vw"
    },

    pickerViewInOBGYN: {
        borderColor: color.themeDark,
        backgroundColor: color.themeDark,
        borderRadius: 5,
        height: 35,
        fontSize: 12,
        paddingLeft: 8,
        marginRight: 10,
        color: color.white,
    },
    pickerFont: {
        backgroundColor: color.white,
        paddingLeft: '0.6vw',
        paddingRight: '0.6vw'
        //fontSize: 12
    },
    formTextBox: {
        color: "grey",
        borderRadius: 10,
        borderColor: "grey",
        borderWidth: 1,
        height: 35,
        paddingLeft: 10,
        fontSize: 14
    },
    contentPageTitle: {
        fontSize: 23,
        color: "black",
        fontWeight: "500",
        marginTop: 40,
        marginLeft: 40
    },
    doctorNotesFooterButton: {

        borderRadius: 4,
        // borderWidth: 1,
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        borderRadius: 4,
        backgroundColor: color.themeDark,
    },
    contentPageLine: {
        marginTop: '0.3vw',
        marginBottom: '0.3vw',
        width: "100%",
        height: '0.03vw',
        backgroundColor: "#E4E8F1"  // "#707070"
    },
    contentSectionTitle: {
        fontSize: '1.2vw',
        fontWeight: "600"
    },
    contentSectionSubTitle: {
        fontSize: '1vw',
        color: color.notesTableTitle
    },
    LoaderStyle: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentTableTitle: {
        // table headings
        fontSize: '0.9vw',
        color: color.notesTableTitle
    },

    contentModalTitle: {
        // table headings
        fontSize: 16,
        color: color.black
    },
    contentTableDataCell: {
        padding: '0.6vw'
    },
    contentTableDataLead: {
        // lead also data - like Right Eye (OD)
        fontSize: "0.85vw",
        color: color.notesTableData
    },
    contentTableData: {
        fontSize: '0.8vw',
        color: color.notesTableData,
        marginTop: '0.3vw'
    },
    contentActive: {
        fontSize: 12,
        color: '#E86261',
        marginTop: 5
    },
    contentInActive: {
        fontSize: '0.8vw',
        color: '#EB982B',
        marginTop: '0.3vw'
    },
    sectionItemLine: {
        height: '0.03vw',
        backgroundColor: color.sectionItemLine
    },
    SearchBarContainer: {
        width: "100%",
        height: 60,
        borderTopColor: "transparent",
        borderBottomColor: "transparent",
        backgroundColor: 'none'
    },
    SearchBarInnerContainer: {
        paddingHorizontal: '0.32vw',
        backgroundColor: "#e8e9eb", // color.lightGray
    },
    SearchBarInput: {
        fontSize: '0.91vw',
        color: color.black
    },

    // home screen 
    homeScreenStatusView: {
        marginBottom: ".4vw",
        width: "18%",
        backgroundColor: "white"
    },
    homeScreenSelectedStates: {
        marginTop: ".5vw",
        padding: ".6vw",
        backgroundColor: color.themeDark,
        borderRadius: "10vw",
        borderColor: "white",
        borderWidth: ".2vw"
    },
    homeScreenStatusIconBlock: {
        marginVertical: 4,
        padding: 8,
        backgroundColor: color.themeDark,
        borderRadius: 50,
        borderColor: "white",
        borderWidth: 1
    },
    certificateButton:
    {
        // height: 30,
        paddingVertical: '0.5vw', paddingHorizontal: '1.1vw', borderRadius: 4,
        // width: 103,
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 8,
        flexDirection: "row",
        backgroundColor: color.themeDark,
        justifyContent: "space-evenly",
        borderWidth: 0,
        borderColor: color.white,
        marginRight: '1.2vw'
    },
    certificateButton1:
    {
        paddingVertical: '0.5vw', paddingHorizontal: '1.1vw', borderRadius: 4,
        // height: 30,
        // width: 180,
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 8,
        flexDirection: "row",
        backgroundColor: color.themeDark,
        justifyContent: "space-evenly",
        borderWidth: 0,
        borderColor: color.white,
        marginRight: '1.2vw'

    },
    certificatetext:
    {
        fontSize: '0.8vw',
        color: color.white,
        fontWeight: "700"
    },
    editIcon: {
        width: 30, height: 30, borderRadius: 20, shadowOpacity: 0.2, shadowRadius: 4,
        shadowOffset: { height: 2, width: 2 },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 20
    },
    obgynHistoryEditIcon: {
        marginTop: 5,
        width: 25, height: 25, borderRadius: 20, shadowOpacity: 0.2, shadowRadius: 4,
        shadowOffset: { height: 2, width: 2 },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 5
    },

    gulcoseLogRightView: {
        justifyContent: 'center',
        height: 30,
        backgroundColor: "#E2E2E2",
        marginBottom: 10
    },



    certificatetext:
    {
        fontSize: '0.8vw',
        color: color.white,
        fontWeight: "700"
    },
    editIcon: {
        width: 30, height: 30, borderRadius: 20, shadowOpacity: 0.2, shadowRadius: 4,
        shadowOffset: { height: 2, width: 2 },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 20
    },
    obgynHistoryEditIcon: {
        marginTop: 5,
        width: 25, height: 25, borderRadius: 20, shadowOpacity: 0.2, shadowRadius: 4,
        shadowOffset: { height: 2, width: 2 },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 5
    },
    inputPutFieldIcons: {
        borderLeftColor: themeBorderColor,
        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        height: 50,
        justifyContent: 'center'

    },
    rememberMe_User_Password_Icon: {
        marginLeft: 10,
        color: "grey",
    },
    loginPageTextBoxRightIcon: {
        // borderColor: themeBorderColor,
        // borderWidth:1,
        // borderRadius:10,
        width: "61%",
        height: 50,
        // marginBottom:20,

        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        // borderRightColor: themeBorderColor,
        // borderRightWidth: themeBorderColor,
        // borderRightWidth: 1,
        // borderBottomRightRadius: 10,
        // borderTopRightRadius: 10,
        paddingLeft: 20,
        fontSize: '1vw'
    },
    loginPageTextBox: {
        // borderColor: themeBorderColor,
        // borderWidth:1,
        // borderRadius:10,
        // width: "70%",
        height: 50,
        // marginBottom:20,

        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightColor: themeBorderColor,
        borderRightWidth: themeBorderColor,
        borderRightWidth: 1,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        paddingLeft: 20,
        fontSize: '1vw'
    },

    inputPutFieldRightIcons: {
        borderRightColor: themeBorderColor,
        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        height: 50,

        paddingRight: 15

    },

    rememberAndForgotPasswordView: {
        width: "30vw",
        // marginTop:1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    rememberMeText: {
        color: "grey",
        marginLeft: 15,
        fontSize: '1vw'
    },
    forgotPasswordText: {
        color: "grey",
        fontSize: '1vw'
    },
    signinView: {
        width: "30vw",
        marginTop: 15,
        marginBottom: 15,
    },
    signinAndBackButtonView: {
        width: "30vw",
        marginTop: 15,
        marginBottom: 15,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
    },
    singinTouchableView: {
        alignSelf: 'center',
        backgroundColor: themePrimaryColor,
        borderRadius: 4,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 45,
        paddingRight: 45
    },
    signinText: {
        color: "white",
        fontSize: '1vw'
    },

    ipAdmissonCancelButton:
    {
        // height: 30,
        paddingVertical: '0.13vw',
        paddingHorizontal: '1.17vw',
        borderRadius: '0.26vw',
        // width: 103,
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 8,
        flexDirection: "row",
        backgroundColor: color.white,
        justifyContent: "space-evenly",
        borderWidth: 1,
        borderColor: color.themeDark,
        marginRight: '1.3vw'
    },

    ipcancelButton:
    {
        // height: 30,
        paddingVertical: '0.39vw',
        paddingHorizontal: '1.17vw',
        borderRadius: '0.26vw',
        // width: 103,
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 8,
        flexDirection: "row",
        justifyContent: "space-evenly",
        borderWidth: 0,
        borderColor: color.white,
        marginRight: '1.3vw',
        backgroundColor: color.themeDark,
    },

});
