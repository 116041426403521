import React, { Component } from 'react';
import { View, ScrollView, Dimensions, StyleSheet, Text, Image } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
// import {Demographics} from './registryComponents'
import { RegistryDemographic } from './RegistryDemographic';
import { RegistryDignosis } from './RegistryDignosis';
import { RegistryVitals } from './RegistryVitals';
import { RegistryDrugs } from './RegistryDrugs';
import { RegistryAllergeys } from './RegistryAllergeys';
import { RegistryImmunization } from './RegistryImmunization';
import OpthamologyService from "../../../../network/OpthamologyService"
import { Constants } from "../../../../utils/Constants";
import moment from "moment";
import Pagination from '../../../doctor/common/Pagination';
import { RegistryContext } from './RegistryContext';

const screenHeight = Dimensions.get("window").height;

// const navigationHeaderList = [
//     { label: "Business Intelligence", value: "businessIntelligence" },
//     { label: "Registry", value: "registry" },
// ];

const categoryList = [
    { label: "Demographics", value: "Demographics" },
    { label: "Diagnosis", value: "Diagnosis" },
    { label: "Vitals", value: "Vitals" },
    { label: "Drug Name", value: "DrugName" },
    { label: "Allergies", value: "Allergies" },
    { label: "Immunization", value: "Immunization" },
]

const selectedCategoryList = [
    { label: "Demographics", value: "Demographics" },
    { label: "Diagnosis", value: "Diagnosis" },
    { label: "Vitals", value: "Vitals" },
    { label: "Drug Name", value: "DrugName" },
    { label: "Allergies", value: "Allergies" },
    { label: "Immunization", value: "Immunization" },
]



export class Registry extends Component {

    constructor(props) {
        super(props);
        this.state = {

            navigationHeaderList : this.props.isPrimaryDoctor ? [
                // { label: "Business Intelligence", value: "businessIntelligence" },
                { label: "Registry", value: "registry" },
            ] : [
                { label: "Registry", value: "registry" },
            ],

            selectedCategory: { label: "Demographics", value: "Demographics" },
            multipleselectedCategory: {},
            listofPatient: [],
            demographicsFilterData: {
                selectedGender: [],
                dobFromDate: "",
                dobToDate: "",
                AppointmentFromDate: "",
                AppointmentToDate: "",
                ageFrom: '',
                ageTo: '',
                selectedArea: [],
                selectedCity: []
            },

            vitalsData: {
                weightFrom: "",
                weightTo: "",
                heightFrom: "",
                heightTo: "",
                temperatureFrom: "",
                temperatureTo: "",
                bloodPressureFrom: "",
                bloodPressureTo: "",
                pulseRateTo: "",
                pulseRateFrom: "",
                pulseTo: "",
                pulseFrom: "",
                bloodFrom: "",
                bloodTo: "",
                CircumferenceFrom: "",
                CircumferenceTo: "",
                DiastolicFrom: "",
                DiastolicTo: "",
                SystolicFrom: "",
                SystolicTo: "",
            },

            ICDCode: [],
            selectedICDList: [],
            Drugs: [],
            selectedMedical: [],
            selectedNonMedical: [],
            selectedImmuniZation: [],
            billingListAfterPagination: [],

            userType:this.props.userType,

        }

    }


    componentDidMount() {

        this.getCommonList()
    }

    getCommonList = (key, name) => {
        
        var { demographicsFilterData, vitalsData } = this.state;
        var getTreatment = []
        if (demographicsFilterData.selectedGender.length > 0) {
            for (var i = 0; i < demographicsFilterData.selectedGender.length; i++) {
                getTreatment.push(demographicsFilterData.selectedGender[i].value)
            }
        }

        var fromdate = demographicsFilterData.dobFromDate ? moment(demographicsFilterData.dobFromDate).format('YYYY-MM-DD') : ""
        var toDate = demographicsFilterData.dobToDate ? moment(demographicsFilterData.dobToDate).format('YYYY-MM-DD') : ""

        var appointmentfromdate = demographicsFilterData.AppointmentFromDate ? moment(demographicsFilterData.AppointmentFromDate).format('YYYY-MM-DD') : ""
        var appointmenttoDate = demographicsFilterData.AppointmentToDate ? moment(demographicsFilterData.AppointmentToDate).format('YYYY-MM-DD') : ""

        var gender =  getTreatment.length>0 ? getTreatment.toString() : []

        var selectedCity = demographicsFilterData.selectedCity.length > 0 ? demographicsFilterData.selectedCity.toString() : []
        var selectedArea = demographicsFilterData.selectedArea.length > 0 ? demographicsFilterData.selectedArea.toString() : []
        var ICDCode = this.state.ICDCode.length > 0 ? this.state.ICDCode.toString() : []

        var Drugs = this.state.Drugs.length > 0 ? this.state.Drugs.toString() : []

        var Medicalallergy = this.state.selectedMedical.length > 0 ? this.state.selectedMedical.toString() : []
        var NonMedicalallergy = this.state.selectedNonMedical.length > 0 ? this.state.selectedNonMedical.toString() : []
        var Immunization = this.state.selectedImmuniZation.length > 0 ? this.state.selectedImmuniZation.toString() : []

        if (key == "Apply" && fromdate == "" && toDate == '' && demographicsFilterData.ageFrom == "" && demographicsFilterData.ageTo == "" && appointmentfromdate == "" && appointmenttoDate == "" &&
            gender == "" && selectedArea == "" && selectedCity == "" && ICDCode == "" && Drugs == "" && Medicalallergy == "" && NonMedicalallergy == "" && Immunization == "" &&
            vitalsData.heightFrom == "" && vitalsData.heightTo == "" && vitalsData.weightFrom == "" && vitalsData.weightTo == "" && vitalsData.SystolicFrom == "" &&
            vitalsData.SystolicTo == "" && vitalsData.DiastolicFrom == "" && vitalsData.DiastolicTo == "" && vitalsData.temperatureFrom == "" && vitalsData.temperatureTo == "" &&
            vitalsData.pulseFrom == "" && vitalsData.pulseTo == "" && vitalsData.pulseRateFrom == "" && vitalsData.pulseRateTo == "" && vitalsData.bloodFrom == "" && vitalsData.bloodTo == "" &&
            vitalsData.CircumferenceFrom == "" && vitalsData.CircumferenceTo == "") {
            this.props.showResposeValue("error", "Please Enter The Values")

        } else if (key == "Apply" && name == "Dignosis" && ICDCode == "") {
            this.props.showResposeValue("error", "Please enter dignosis the values")

        } else if (key == "Apply" && name == "Demographic" && fromdate == "" && toDate == '' && demographicsFilterData.ageFrom == "" && demographicsFilterData.ageTo == "" && appointmentfromdate == "" && appointmenttoDate == "" &&
            gender == "" && selectedArea == "" && selectedCity == "") {
            this.props.showResposeValue("error", "Please enter the demographics values")

        } else if (key == "Apply" && name == "Drugs" && Drugs == "") {
            this.props.showResposeValue("error", "Please enter the drugs values")

        } else if (key == "Apply" && name == "Allergy" && Medicalallergy == "" && NonMedicalallergy == "") {
            this.props.showResposeValue("error", "Please enter the allergy values")

        } else if (key == "Apply" && name == "Immunization" && Immunization == "") {
            this.props.showResposeValue("error", "Please enter the immunization values")

        } else if (key == "Apply" && name == "Vitals" && Immunization == "" && vitalsData.heightFrom == "" && vitalsData.heightTo == "" && vitalsData.weightFrom == "" && vitalsData.weightTo == "" && vitalsData.SystolicFrom == "" &&
            vitalsData.SystolicTo == "" && vitalsData.DiastolicFrom == "" && vitalsData.DiastolicTo == "" && vitalsData.temperatureFrom == "" && vitalsData.temperatureTo == "" &&
            vitalsData.pulseFrom == "" && vitalsData.pulseTo == "" && vitalsData.pulseRateFrom == "" && vitalsData.pulseRateTo == "" && vitalsData.bloodFrom == "" && vitalsData.bloodTo == "" &&
            vitalsData.CircumferenceFrom == "" && vitalsData.CircumferenceTo == "") {
            this.props.showResposeValue("error", "Please enter the Vitals values")

        } else {

            var serviceUrl = Constants.REGISTRY_COMMON_LIST +
                "?dob_start_date=" + fromdate +
                "&dob_end_date=" + toDate +
                "&age_from=" + demographicsFilterData.ageFrom +
                "&age_to=" + demographicsFilterData.ageTo +
                "&appointment_from=" + appointmentfromdate +
                "&appointment_to=" + appointmenttoDate +
                "&gender=" + gender +
                "&area=" + selectedArea +
                "&city=" + selectedCity +
                "&icd_code=" + ICDCode +
                "&drug_name=" + Drugs +
                "&medication_allergy=" + Medicalallergy +
                "&non_medication_allergy=" + NonMedicalallergy +
                "&vaccination_name=" + Immunization +
                "&height_from=" + vitalsData.heightFrom +
                "&height_to=" + vitalsData.heightTo +
                "&weight_from=" + vitalsData.weightFrom +
                "&weight_to=" + vitalsData.weightTo +
                "&bp_systolic_from=" + vitalsData.SystolicFrom +
                "&bp_systolic_to=" + vitalsData.SystolicTo +
                "&bp_diastolic_from=" + vitalsData.DiastolicFrom +
                "&bp_diastolic_to=" + vitalsData.DiastolicTo +
                "&temperature_from=" + vitalsData.temperatureFrom +
                "&temperature_to=" + vitalsData.temperatureTo +
                "&pulse_oximetry_from=" + vitalsData.pulseFrom +
                "&pulse_oximetry_to=" + vitalsData.pulseTo +
                "&pulse_rate_from=" + vitalsData.pulseRateFrom +
                "&pulse_rate_to=" + vitalsData.pulseRateTo +
                "&blood_sugar_from=" + vitalsData.bloodFrom +
                "&blood_sugar_to=" + vitalsData.bloodTo +
                "&head_circumference_from=" + vitalsData.CircumferenceFrom +
                "&head_circumference_to=" + vitalsData.CircumferenceTo;


            OpthamologyService.getInstance().getComplaints(
                serviceUrl,
                this,
                this.getCommonListSuccess,
                this.getCommonListFailure
            );
        }

    }



    getCommonListSuccess = response => {
        if (response.status == "success") {
            this.setState({
                listofPatient: response.data
            })
        } else {
            this.props.showResposeValue("error", response.message)

        }


    };

    getCommonListFailure = error => {
        this.props.showResposeValue("error", response.message)

        console.log(error);
    }

    changeTabSection(screen) { 
        // alert(screen)
        this.props.biAndRegistry(screen,this.props.isPrimaryDoctor)
     }
    renderTopNavigation() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.navigationHeaderList}
                    selectedTab={"registry"} />
            </View>
        )
    }

    onChangeSelectedCategory(key, selectedValue) {
        var states = this.state;
        states[key] = selectedValue;
        this.setState({ states });
    }

    renderCategorys() {
        return (
            <View style={{ marginVertical: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Category"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: "row", flexWrap: 'wrap' }}>
                    {
                        categoryList.map((item, index) => {
                            return (
                                <View key={index} style={{ marginTop: 8, marginRight: 8 }}>
                                    <CommonButton
                                        item={item} butttonText={item.label}
                                        selectedvalue={this.state.selectedCategory}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""} rightIcon={true}
                                        buttonAction={this.onChangeSelectedCategory.bind(this)}
                                        buttonKey={"selectedCategory"} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    renderRightSideHeader() {
        return (
            <Text style={{ fontSize: 17, color: color.themeDark, fontWeight: '500' }}>{"Registry"}</Text>
        )
    }


    renderComponent() {

        var selectedComponent = this.state.selectedCategory.value;

        var componentLookup = {
            Demographics: (<RegistryDemographic />),
            Diagnosis: (<RegistryDignosis />),
            Vitals: (<RegistryVitals userType={this.state.userType} />),
            DrugName: (<RegistryDrugs />),
            Allergies: (<RegistryAllergeys />),
            Immunization: (<RegistryImmunization />),


        }
        return (<View>
            {componentLookup[selectedComponent]}

        </View>);
    }


    validateCategory(key) {
        // var {demographicsFilterData} = this.state;
        // console.log("key -------------------> "+key)
        if (key == "Demographics") {
            var returnVAlue = false
            Object.keys(this.state.demographicsFilterData).forEach((keys) => {
                if (keys == "selectedGender" && Object.keys(this.state.demographicsFilterData[keys]).length > 0) {
                    returnVAlue = true
                } else if (keys == "selectedArea" && this.state.demographicsFilterData[keys].length > 0) {
                    returnVAlue = true
                } else if (keys == "selectedCity" && this.state.demographicsFilterData[keys].length > 0) {
                    returnVAlue = true
                } else if (keys !== "selectedGender" && keys !== "selectedArea" && keys !== "selectedCity" && this.state.demographicsFilterData[keys]) {
                    returnVAlue = true
                }
            })
            return returnVAlue
        }
        if (key == "Vitals") {
            var returnVAlue = false
            Object.keys(this.state.vitalsData).forEach((keys) => {
                if (this.state.vitalsData[keys]) {
                    // if(key == "selectedGender" && Object.keys(this.state.demographicsFilterData[key]).length > 0 ){
                    //     returnVAlue = true 
                    // } else if(key !== "selectedGender"){
                    returnVAlue = true
                    // }                    
                }
            })
            return returnVAlue
        }

        if (key == "Diagnosis") {
            var returnVAlue = false
            if (this.state.ICDCode.length > 0) {
                returnVAlue = true
            }
            // Object.keys(this.state.vitalsData).forEach((keys) => {
            //     if(this.state.vitalsData[keys]){
            //         // if(key == "selectedGender" && Object.keys(this.state.demographicsFilterData[key]).length > 0 ){
            //         //     returnVAlue = true 
            //         // } else if(key !== "selectedGender"){
            //             returnVAlue = true
            //         // }                    
            //     }
            // })
            return returnVAlue
        }

        if (key == "DrugName") {
            var returnVAlue = false
            if (this.state.Drugs.length > 0) {
                returnVAlue = true
            }
            return returnVAlue
        }

        if (key == "Allergies") {
            var returnVAlue = false
            if (this.state.selectedMedical.length > 0 || this.state.selectedNonMedical.length > 0) {
                returnVAlue = true
            }
            return returnVAlue
        }
        if (key == "Immunization") {
            var returnVAlue = false
            if (this.state.selectedImmuniZation.length > 0) {
                returnVAlue = true
            }
            return returnVAlue
        }
    }


    renderSelectedField() {
        return (
            <View style={{ marginVertical: 15 }}>

                <View style={{ flexDirection: "row", flexWrap: 'wrap' }}>
                    {
                        selectedCategoryList.map((item, index) => {
                            var isSelectedButton = this.validateCategory(item.value)
                            return (
                                <View key={index} style={{ marginTop: 8, marginRight: 8 }}>
                                    <CommonButton
                                        item={item} butttonText={item.label}
                                        selectedvalue={{}}
                                        buttonType={isSelectedButton ? "theme" : "outlineNonTheme"}
                                        buttonIcon={""} rightIcon={true}
                                        buttonAction={this.onChangeSelectedCategory.bind(this)}
                                        buttonKey={"multipleselectedCategory"} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    paginationChangedValues(data) {
        this.setState({
            billingListAfterPagination: data
        })
    }

    renderPatientList() {
        if (this.state.listofPatient && this.state.listofPatient.length > 0 && this.state.listofPatient.length < 11) {
            return (
                <View>
                    {
                        this.state.listofPatient.map((item, index) => {
                            return (
                                <View style={{ flexDirection: 'row', marginTop: '0.97vw' }}>
                                    <View style={{ width:"40%" }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ flex: 0.2 }}>
                                                <Image
                                                    source={item.photo_url ? { uri: item.photo_url } : require("../../../../../assets/images/sample_profile.png")}
                                                    // source={require("../../../../../assets/images/sample_profile.png")}

                                                    style={{ backgroundColor: color.white, marginHorizontal: '0.78vw', height: '2.6vw', width: '2.6vw', borderRadius: '3.25vw', borderWidth: 3, borderColor: color.white }} />
                                            </View>
                                            <View style={{ flex: 0.8 }}>
                                                <Text style={{ paddingBottom: 5 , fontSize:'0.9vw'}}>{item.name}</Text>
                                                <Text style={{ color: '#717171', fontSize: '0.9vw' }}>{(item.gender) + (item.age && item.age !== "undefined" ? " | " : "") + (item.age && item.age !== "undefined" ? item.age : "") + (item.dob && item.dob !== "undefined" ? " | " : "") + (item.dob ? moment(item.dob).format("DD-MM-YYYY") : "")}</Text>

                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ width:"60%", marginLeft: '0.97vw' }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, flexWrap: 'wrap' }}>

                                            {/* <Text style={{paddingBottom:5,fontSize:14,fontWeight:300}}>{}</Text> */}
                                            <Text style={{ fontSize: '0.9vw', fontWeight: 300 }} numberOfLines={5}>{item.mobile_number + (item.email ? " | " : "") + (item.email ? item.email : "") + (item.patient_address ? " | " : "") + item.patient_address}</Text>
                                        </View>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
            )

        }

        else if (this.state.listofPatient && this.state.listofPatient.length > 0) {
            return (
                <View>
                    {
                        this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
                            return (
                                <View style={{ flexDirection: 'row', marginTop: '0.97vw' }}>
                                    <View style={{ width:"40%" }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ flex: 0.2 }}>
                                                <Image
                                                    source={item.photo_url ? { uri: item.photo_url } : require("../../../../../assets/images/sample_profile.png")}
                                                    // source={require("../../../../../assets/images/sample_profile.png")}

                                                    style={{ backgroundColor: color.white, marginHorizontal: '0.78vw', height: '2.6vw', width: '2.6vw', borderRadius: '3.25vw', borderWidth: 3, borderColor: color.white }} />
                                            </View>
                                            <View style={{ flex: 0.8 }}>
                                                <Text style={{ paddingBottom: 5, fontSize:'0.9vw' }}>{item.name}</Text>
                                                <Text style={{ color: '#717171', fontSize: '0.9vw' }}>{(item.gender) + (item.age && item.age !== "undefined" ? " | " : "") + (item.age && item.age !== "undefined" ? item.age : "") + (item.dob && item.dob !== "undefined" ? " | " : "") + (item.dob ? moment(item.dob).format("DD-MM-YYYY") : "")}</Text>

                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ width:"60%" , marginLeft: 15 }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, flexWrap: 'wrap' }}>

                                            {/* <Text style={{paddingBottom:5,fontSize:14,fontWeight:300}}>{}</Text> */}
                                            <Text style={{ fontSize: '0.9vw', fontWeight: 300 }} numberOfLines={5}>{item.mobile_number + (item.email ? " | " : "") + (item.email ? item.email : "") + (item.patient_address ? " | " : "") + item.patient_address}</Text>
                                        </View>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
            )

        }
        else {

            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                <Text style={{ color: color.disableLabelColor, marginTop: '4vw', fontSize: '1.17vw' }}>{"No Records to be shown"}</Text>
            </View>
        }

    }

    demographicsDatas = (key, value) => {

        var { demographicsFilterData } = this.state;
        if (key == "clear") {
            demographicsFilterData["selectedGender"] = [];
            demographicsFilterData["dobFromDate"] = "";
            demographicsFilterData["dobToDate"] = "";
            demographicsFilterData["AppointmentFromDate"] = "";
            demographicsFilterData["AppointmentToDate"] = "";
            demographicsFilterData["age_from"] = "";
            demographicsFilterData["age_to"] = "";
            demographicsFilterData["selectedCity"] = [];
            demographicsFilterData["selectedArea"] = [];
            demographicsFilterData["ageFrom"] = "";
            demographicsFilterData["ageTo"] = "";
            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)


        } else if (key == "Apply") {
            this.getCommonList(key, "Demographic")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)
        } else {
            if (key == "selectedGender") {
                var { selectedGender } = this.state.demographicsFilterData;
                // selectedGender=value
                // alert(JSON.stringify(value))
                var getSelectedValue;
                var spliceData;

                if (selectedGender.length > 0) {
                    for (var i = 0; i < selectedGender.length; i++) {
                        if (selectedGender[i].value == value.value) {
                            spliceData = selectedGender[i];
                            selectedGender.splice(i, 1)
                            var data = this.removeDuplicate(selectedGender);
                            this.setState({ selectedGender: data })
                            return
                        } else {
                            if (spliceData && spliceData.value !== value.value) {
                            } else {
                                getSelectedValue = value
                            }
                        }
                    }

                    if (getSelectedValue) { selectedGender.push(value); }
                } else {
                    selectedGender.push(value)
                }

                var data = this.removeDuplicate(selectedGender);
                this.setState({ selectedGender: data })
            } else {
                demographicsFilterData[key] = value;
            }
        }

        if(key!=="selectedGender"){
            this.setState({ demographicsFilterData })

        }


    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    diagnosisdata = (key, value) => {
        var states = this.state


        if (key == "clear") {
            states['ICDCode'] = []
            states['selectedICDList'] = []

            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else if (key == "Apply") {
            this.getCommonList(key, "Dignosis")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else {
            var getICDCodes = [];

            var icdCodes = key;

            if (icdCodes.length > 0) {
                for (var icd of icdCodes) {
                    getICDCodes.push(icd.icd_code)
                }
            }

            states['ICDCode'] = getICDCodes
            states['selectedICDList'] = key

        }



        this.setState({ states })

    }



    drugsDatas = (key, value) => {

        var states = this.state


        if (key == "clear") {
            states['Drugs'] = []
            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else if (key == "Apply") {
            this.getCommonList(key, "Drugs")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else {


            states[key] = value
        }



        this.setState({ states })

    }

    allergiesData = (key, value) => {

        var states = this.state;
        if (key == "clear") {
            states['selectedMedical'] = []
            states['selectedNonMedical'] = []
            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else if (key == "Apply") {
            this.getCommonList(key, "Allergy")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else {
            states[key] = value
        }

        this.setState({ states })

    }


    immunizationData = (key, value) => {
        var states = this.state
        if (key == "clear") {
            states['selectedImmuniZation'] = []
            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else if (key == "Apply") {
            this.getCommonList(key, "Immunization")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else {
            states[key] = value
        }

        this.setState({ states })

    }

    vitalsData = (key, value) => {
        var { vitalsData } = this.state
        if (key == "clear") {
            vitalsData["weightFrom"] = "";
            vitalsData["weightTo"] = "";
            vitalsData['heightFrom'] = "";
            vitalsData['heightTo'] = "";
            vitalsData['temperatureFrom'] = "";
            vitalsData['temperatureTo'] = "";
            vitalsData['bloodPressureFrom'] = "";
            vitalsData['bloodPressureTo'] = "";
            vitalsData['pulseRateTo'] = "";
            vitalsData['pulseRateFrom'] = "";
            vitalsData['pulseTo'] = "";
            vitalsData['pulseFrom'] = "";
            vitalsData['CircumferenceFrom'] = "";
            vitalsData['CircumferenceTo'] = "";
            vitalsData['bloodFrom'] = "";
            vitalsData['bloodTo'] = "";
            vitalsData['DiastolicFrom'] = "";
            vitalsData['DiastolicTo'] = "";
            vitalsData['SystolicFrom'] = "";
            vitalsData['SystolicTo'] = "";

            this.getCommonList(key)
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)


        }
        else if (key == "Apply") {
            this.getCommonList(key, "Vitals")
            this.props.exportRegistrydata(this.state.demographicsFilterData, this.state.ICDCode, this.state.Drugs, this.state.selectedMedical, this.state.selectedNonMedical, this.state.selectedImmuniZation, this.state.vitalsData)

        }
        else {
            vitalsData[key] = value
        }

        this.setState({ vitalsData })

    }

    render() {
        return (
            <RegistryContext.Provider value={{
                demographicsDatas: this.demographicsDatas,
                diagnosisdata: this.diagnosisdata,
                drugsDatas: this.drugsDatas,
                allergiesData: this.allergiesData,
                immunizationData: this.immunizationData,
                vitalsData: this.vitalsData,

                demographicsDetails: this.state.demographicsFilterData,
                diagnosisDetials: this.state.selectedICDList,
                vitalsDetials: this.state.vitalsData,
                drugdetails: this.state.Drugs,
                allergiesdetails: this.state,
                immunizationdetails: this.state.selectedImmuniZation

            }}>
                <View style={styles.container}>
                    <View style={styles.leftSideView}>

                        {/* {this.renderTopNavigation()} */}
                        <View style={styles.leftSideBody}>
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                                style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                                <View style={{ marginTop: 15, paddingLeft: 15 }}>
                                    {this.renderSelectedField()}

                                    <CommonHistorySectionHeader
                                        heading1={"Patient's Name"}
                                        heading2={"Contact Details"}
                                        noOfColumn={2}
                                        columnSize={[0.4, 0.6]}
                                    />

                                    <ScrollView style={styles.TableMainView}>{this.renderPatientList()}</ScrollView>

                                    {
                                        this.state.listofPatient.length > 10 ?
                                            <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.listofPatient} />
                                            : null}
                                </View>
                            </ScrollView>
                        </View>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={false} style={styles.rightSideScrollView} >
                        {this.renderRightSideHeader()}
                        {this.renderCategorys()}
                        {this.renderComponent()}

                    </ScrollView>

                </View>
            </RegistryContext.Provider>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: "87vh", backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height:"87vh", backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: '87vh', backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    },
    TableMainView:{
        height:'63vh'
    }

})