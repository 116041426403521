import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
} from "react-native";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import Remove from "react-native-vector-icons/Entypo";
import { DatePicker, Checkbox} from 'antd';
import moment from "moment";
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import { CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton, NavigationTopHeader, returnPaymentMode, paymentReturnType } from '../../BaseComponent';
import CommonDateFilter from '../../BaseComponent';
import Cancel from "react-native-vector-icons/Entypo";
import 'antd/dist/antd.css';
import OpthamologyService from "../../../../network/OpthamologyService";
import Success from "react-native-vector-icons/FontAwesome5";
import AsyncStorage from "../../../../AsyncStorage";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const navigationHeaderList = [
  { label: "Billing Transaction", value: "ipallTransactions" },
]

const navigationHeaderListIfIPCreateBill = [
  { label: "Create Bill", value: "ipPatientCreateBill" },
  { label: "Pending Bill", value: "ipPatientSavedBill" },
  { label: "Billing Transaction", value: "ipallTransactions" },
  { label: "OT Bills", value: "otbills" },
  // { label: "Summary Bill", value: "summaryBill" },
  { label: "Estimation Bill", value: "estimationBill" },
]

// create a component
export class IPPatientCreateBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      isCurrentPageIsIPPatientAppointment: this.props.isCurrentPageIsIPPatientAppointment,
      userType: this.props.userType,
      selectedDescription: {},
      showDescriptionNewButtonFlag: true,
      billingDescriptionList: [],
      newButtonValue: "",

      searchDescription: "",
      searchDescriptionList: [],
      searchDescriptionOpen: false,
      showDescriptionBottomLayout: true,

      enterUnits: 1,
      enterRatePerUnits: "",
      enterTotalAmount: "",

      discountPersentage: 0,
      discountAmount: 0.00,
      discount: 0.00,
      roundOff: 0.00,
      selectedPaymentType: "",
      paymentTypeTotalAmount: 0.00,
      paymentTypeCashAmount: 0.00,
      paymentTypeCardAmount: 0.00,
      paymentTypeCardNumber: "",

      companyName: "",
      policyNumber: "",
      validity: "",
      transactionNumber: "",

      selectedBillType: "",
      billTypeCompanyName: "",
      billTypeEmployeeID: "",
      billingList: [],
      bedChargeTotalAmount: 0.00,
      billingType: "",

      invoiceDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedLabSet: {},
      showLabSetNewButtonFlag: true,
      listOfLabSet: [],
      cardNumber: "",
      receviedAmount: 0.00,
      balance: 0.00,
      amount: 0.00,
      isDisabled: false,

      totalAmount: 0.00,
      netAmount: 0.00,
      PaymentTaotalAmount: 0.00,

      upiTransactionID: "",
      bankTransactionID: "",
      AdvancePayment: 0.00,
      overallTotalamount: "",
      overallPaidamount: "",
      overallBalance: "",

      billingDescriptionListBackup: [],

      is_disc_percent_changed: true,
      can_detect_from_advance: false,
      advance_check_Selected: false,
      advance_Touched: true,
      hasSavedPartial: false,
      labSavedBillList: this.props.labSavedBillList.billing_details ? this.props.labSavedBillList.billing_details : [],
      amountDetails: this.props.labSavedBillList.amount_details ? this.props.labSavedBillList.amount_details : {},
      paidAmount: 0.00,
      balanceAmount: 0.00,
      id: "",
      removableIds: [],
      billId: this.props.billID ? this.props.billID : null,
      idIpNumber: this.props.labSavedBillList.patient_details ? this.props.labSavedBillList.patient_details.invoice_number : null,
      // Bill Template
      billTemplateList: [],
      selectedTemplateName: "",
      isOpthamalogyClinic: false,

      //bill modify
      invoiceID: "",
      isBillModification: false,
      ipAdmissionID: "",
      modifyBillList: [],
      patinetDetails: {},
      isAdmin: false,
      isUpdate: false,
      modifyedBillID: "",

      disableDiscountPercent: false,
      EmployerID:"",

      isSavedBill : false,
      userEditAccess:false,
      SummaryId : "",

      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bedChargeTotalAmount) {
      let { billingList } = this.state;
      this.state.bedChargeTotalAmount = nextProps.bedChargeTotalAmount;
      billingList.push(
        {
          Description: "Bed Charge",
          RatePerUnits: "0",
          Units: "0",
          TotalAmount: nextProps.bedChargeTotalAmount
        }
      );
      billingList = this.removeDuplicate(billingList)
      this.setState({ billingList }, () => {
        var amount = this.state.billingList.reduce(function (sum, current) {
          return parseInt(sum) + parseInt(current.TotalAmount);
        }, 0);


        this.setState({
          totalAmount: amount
        }, () => {
          this.netAmountCalculation()
        })

        this.props.sendBedChargeTotalAmount("")
      })

    }
  }

  async componentDidMount() {

    this.getTemplate()
    this.getIpAmountUserAccess()
    if (this.state.billingType == "laboratory billing") {

    } else {
      this.getBillingDescription();
      this.getOverbillstatus()

    }
    if ((this.props.ipModifyBillDetail && Object.keys(this.props.ipModifyBillDetail).length > 0 && (!this.props.isModifyBillUpdate)) || (this.props.invoiceID && !this.props.isModifyBillUpdate) || (this.props.ipAdmissionID && !this.props.isModifyBillUpdate)) {

      this.setState({
        invoiceID: this.props.invoiceID ? this.props.invoiceID : this.props.ipModifyBillDetail.id,
        isBillModification: true,
        ipAdmissionID: this.props.ipAdmissionID ? this.props.ipAdmissionID : this.props.ipModifyBillDetail.ip_number
      }, () => {
        this.getIPPatientList()
        this.getOverbillstatus()
      })
    }
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdmin: loggedInDataObj.is_admin_access
    })
    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            FO_admin_setting : FO_admin_setting
        })


    var tempBillingList = []
    var payment_type = paymentReturnType(this.state.amountDetails.payment_type)

    if (this.state.labSavedBillList.length > 0) {

      for (let i = 0; i < this.state.labSavedBillList.length; i++) {
        tempBillingList.push({
          "id": this.state.labSavedBillList[i].id,
          "Description": this.state.labSavedBillList[i].service_name,
          "RatePerUnits": this.state.labSavedBillList[i].service_rate_per_unit,
          "TotalAmount": this.state.labSavedBillList[i].service_amount,
          "Units": this.state.labSavedBillList[i].service_units
        })
      }
      this.setState({
        billingList: tempBillingList,
        receviedAmount: this.state.amountDetails.total_amount,
        totalAmount: this.state.amountDetails.sub_amount,
        discountPersentage: this.state.amountDetails.discount_percentage,
        discount: this.state.amountDetails.discount_amount,
        roundOff: this.state.amountDetails.round_off,
        // selectedPaymentType:  this.state.amountDetails.payment_type,
        firstPayment : payment_type.paymentType1,
        secondPayment : payment_type.paymentType2,
        firstAmount : this.state.amountDetails.amount_1,
        secondAmount : this.state.amountDetails.amount_2,
        PaymentTaotalAmount: this.state.amountDetails.total_amount,
        balance: this.state.amountDetails.balance_amount,
        netAmount: this.state.amountDetails.sub_amount,
        selectedBillType: this.state.amountDetails.bill_type,
        billTypeCompanyName: this.state.amountDetails.company_name,
        billTypeEmployeeID: this.state.amountDetails.employee_id,
        companyName: this.props.labSavedBillList.amount_details.insurance_company_name,
        policyNumber: this.props.labSavedBillList.amount_details.policy_number,
        validity: this.props.labSavedBillList.amount_details.validity,
        transactionNumber: this.props.labSavedBillList.amount_details.transaction_number,
        paidAmount: this.props.labSavedBillList.amount_details.paid_amount,
        balanceAmount: this.props.labSavedBillList.amount_details.balance_amount,
        id: this.props.labSavedBillList.billing_details.id,
        paymentTypeCashAmount: this.state.amountDetails.cash_amount,
        paymentTypeCardAmount: this.state.amountDetails.card_amount,
        isSavedBill:true
      })
    }
  }


  getIpAmountUserAccess = () =>{
    let url = Constants.GET_FO_ADMIN_CONFIG_SETTINGS;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getAdminUserAccessSuccess,
      this.getAdminUserAccessFailure
    )
  }

  getAdminUserAccessSuccess = (response) =>{
    if(response.status === "success"){
      this.setState({
        userEditAccess:response.data.allow_service_amount_edit
      })
    }
}
getAdminUserAccessFailure = (err) =>{
  console.log(err)
}

  getIPPatientList = () => {
    var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + this.state.invoiceID;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getIPPatientListSuccess,
      this.getIPPatientListFailure
    );
  }
  getIPPatientListSuccess = (response) => {
    if (response.status == "success") {
      console.log(response.data)
      this.setState({
        modifyBillList: response.data.billing_details,
        amountDetails: response.data.amount_details,
        patinetDetails: response.data.patient_details
      }, () => {
        var tempBillingList = [];
        var payment_type = paymentReturnType(this.state.amountDetails.payment_type)

        if (this.state.modifyBillList && this.state.modifyBillList.length > 0) {
          for (let i = 0; i < this.state.modifyBillList.length; i++) {
            tempBillingList.push({
              "id": this.state.modifyBillList[i].id,
              "Description": this.state.modifyBillList[i].service_name,
              "RatePerUnits": this.state.modifyBillList[i].service_rate_per_unit,
              "TotalAmount": this.state.modifyBillList[i].service_amount,
              "Units": this.state.modifyBillList[i].service_units
            })
          }
          this.setState({
            billingList: tempBillingList,
            receviedAmount: this.state.amountDetails.received_amount,
            totalAmount: this.state.amountDetails.sub_amount,
            discountPersentage: this.state.amountDetails.discount_percentage,
            discount: this.state.amountDetails.discount_amount,
            roundOff: this.state.amountDetails.round_off,
            // selectedPaymentType: this.state.amountDetails.payment_type,
            firstPayment : payment_type.paymentType1,
            secondPayment : payment_type.paymentType2,
            firstAmount : this.state.amountDetails.amount_1,
            secondAmount : this.state.amountDetails.amount_2,
            PaymentTaotalAmount: this.state.amountDetails.total_amount,
            balance: this.state.amountDetails.balance_amount,
            netAmount: this.state.amountDetails.sub_amount,
            selectedBillType: this.state.amountDetails.bill_type,
            companyName: this.state.amountDetails.insurance_company_name,
            policyNumber: this.state.amountDetails.policy_number,
            validity: this.state.amountDetails.validity,
            // upiTransactionID: this.state.amountDetails.upi_transaction_number,
            // bankTransactionID: this.state.amountDetails.bank_transaction_number,
            paidAmount: this.state.amountDetails.paid_amount,
            balanceAmount: this.state.amountDetails.balance_amount,
            id: this.state.amountDetails.id,
            paymentTypeCashAmount: this.state.amountDetails.cash_amount,
            paymentTypeCardAmount: this.state.amountDetails.card_amount,
            paymentTypeCardNumber: this.state.amountDetails.card_number,
            invoiceDate: this.state.patinetDetails.invoice_date,
            idIpNumber: this.state.patinetDetails.invoice_number,
            AdvancePayment: this.state.amountDetails.detected_advance_amount,
            // cardNumber: this.state.amountDetails.card_number
          },()=>{
            if(this.state.selectedBillType ==  "Corporate"){
              this.getPatientCorporateDetails()
            }
            this.setState({
              cardNumber : this.state.firstPayment == "Card" ? this.state.amountDetails.transaction_id_1 : this.state.secondPayment == "Card" ? this.state.amountDetails.transaction_id_2 : "",
              upiTransactionID: this.state.firstPayment == "UPI" ? this.state.amountDetails.transaction_id_1 : this.state.secondPayment == "UPI" ? this.state.amountDetails.transaction_id_2 : "",
              bankTransactionID: this.state.firstPayment == "Bank Transfer" ? this.state.amountDetails.transaction_id_1 : this.state.secondPayment == "Bank Transfer" ? this.state.amountDetails.transaction_id_2 : "",
              Cheque : this.state.firstPayment == "Cheque" ? this.state.amountDetails.transaction_id_1 : this.state.secondPayment == "Cheque" ? this.state.amountDetails.transaction_id_2 : ""
            })
          })
        }
      })
    }
  }
  getIPPatientListFailure = (error) => {
    this.props.showResposeValue('error', error.message);
  }
  getTemplate = async () => {

    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isOpthamalogyClinic: loggedInDataObj.clinic_type.is_opt_clinic
    }, () => {
        this.getBilltemplateDescription()
    })
  }
  getBilltemplateDescription = () => {
    let Service_url = "";
    if(this.state.isOpthamalogyClinic){
      Service_url = Constants.IP_BILL_TEMPLATE_DESCRIPTION
    }else{
      Service_url = Constants.CLINIC_IP_TEMPLATE_GET
    }
    
    OpthamologyService.getInstance().getComplaints(
      Service_url,
      this,
      this.getBillTemplateSuccess,
      this.getBillTemplateFailure
    );

  }
  getBillTemplateSuccess = success => {
    if (success.status == "success") {
      this.setState({
        billTemplateList: success.template_data
      })
    }
  }
  getBillTemplateFailure = error => {
  }
  getBillingDescription = async () => {
    let states = this.state;
    var serviceUrl = Constants.CLINIC_IP_SERVICE;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getBillingListSuccess,
      this.getBillingListFailure
    );
  }
  getBillingListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        searchDescriptionList: success.data,
        billingDescriptionList: success.data,
        billingDescriptionListBackup: success.data,
      })
    }
  }
  getBillingListFailure = error => {
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  numberValidationWithoutDot(num) {
    return /^[0-9]*$/.test(num);
}

  ratePerUnitTxtBoxValidation(selectedValue, arrayList, defaultValue) {
    var status = defaultValue;
    if (arrayList.length > 0 && selectedValue && selectedValue.value) {
      for (var i = 0; i < arrayList.length; i++) {
        var name = arrayList[i].service_name;
        if (name == selectedValue.value) {
          status = true;
        } else {
          status = false;
        }
      }
    }
    return status;
  }

  renderTextBox(placeholder, key, value) {
    var { billingDescriptionListBackup, selectedDescription } = this.state;
    var checkBoxIsEditable = (
      (key == "enterTotalAmount" &&
        this.state.selectedDescription.value == "Advance payment"
      ) || (key === "enterRatePerUnits" && this.state.selectedDescription.value !== "Advance payment") ||
      (key == "enterUnits" && this.state.selectedDescription && this.state.selectedDescription.value !== "Advance payment")
    ) ? true : false

    return (
      <View>
        <TextInput
          style={[{ backgroundColor: checkBoxIsEditable ? null : "#F0F0F0" }, styles.emed_input_box]}
          value={value}
          editable={checkBoxIsEditable}
          placeholder={placeholder}
          onChangeText={(text) => {
            if (this.numberValidation(text)) {
              var states = this.state;
              states[key] = text
              this.setState({
                states
              }, () => {
                if (key == "enterUnits" && this.state.selectedDescription.value !== "Advance payment" || key == "enterRatePerUnits") {
                  this.calculateTotalAmount()
                }
              })
            }
          }}
        />
      </View>
    )
  }

  renderRatePerUnits() {
    return (
      <View style={{ zIndex: -1, marginTop: 5 }}>
        <CommonHistorySectionHeader
          heading1={"Rate Per Unit"}
          heading2={"Units"}
          noOfColumn={2}
          columnSize={[0.5, 0.5]}
        />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 0.5 }}>
            {this.renderTextBox("Enter Value", "enterRatePerUnits", this.state.enterRatePerUnits)}
          </View>
          <View style={{ flex: 0.5 }}>
            {this.renderTextBox("Enter Value", "enterUnits", this.state.enterUnits)}
          </View>
        </View>
      </View>
    )
  }

  renderTotalAmount() {
    return (
      <View>
        <CommonHistorySectionHeader
          heading1={"Total Amount"}
          noOfColumn={1}
          columnSize={[1]}
        />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            {this.renderTextBox("", "enterTotalAmount", this.state.enterTotalAmount)}
          </View>
        </View>
      </View>
    )
  }

  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  renderAmountValueInCard(amount, amounttype) {
    return (
      <View style={[styles.emed_billstatus_card]}>
        <Text style={[styles.emed_billstatus_card_amttxt]}>{"₹ "}{amount ? amount : 0}</Text>
        <Text style={[styles.emed_billstatus_card_amttypetxt]}>{amounttype}</Text>
      </View>
    )
  }

  renderOverAllBillStatus() {
    return (
      <View>
        <Text style={[styles.emed_billstatus_header]}>{"Overall Bill Status"}</Text>
        <View style={{ flexDirection: 'row' }}>
          {this.renderAmountValueInCard(this.state.overallTotalamount, "Total Bill")}
          {this.renderAmountValueInCard(this.state.overallPaidamount, "Total Paid")}
          {this.renderAmountValueInCard(this.state.overallBalance, "Balance")}
        </View>
      </View>
    )
  }

  onPressButton(key, selectedValue) {
    var states = this.state;
    if (selectedValue.value === "Advance payment") {
      this.setState({
        can_detect_from_advance: false
      })
    }
    if (key == "showDescriptionNewButtonFlag") {
      states[key] = false;
      states["showLabSetNewButtonFlag"] = true

    } else if (key == "selectedDescription") {
      if (selectedValue.value == "Advance payment" || selectedValue.value == "Bed Charges") {
        states["enterRatePerUnits"] = 0;
        states["enterUnits"] = 0
      } else {
        states["enterRatePerUnits"] = selectedValue.label;
        states["enterUnits"] = this.state.enterUnits
      }
      if (selectedValue.value == "Bed Charges") {
        this.getRoomDetails()
      } else {
        states[key] = selectedValue;
      }
    } else if (key == "showLabSetNewButtonFlag") {
      states[key] = false;
    }
    else if (key == "selectedDescription") {
      states[key] = selectedValue;
    }
    this.setState({ states }, () => {
      this.calculateTotalAmount()
    })
  }

  calculateTotalAmount() {
    var { enterUnits, enterRatePerUnits } = this.state;
    var totalAmount = enterRatePerUnits * enterUnits;
    this.setState({ enterTotalAmount: totalAmount ,selectedTemplateName:""})
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onSubmitToAddValue() {
    var { searchDescriptionList, billingDescriptionList, listOfLabSet } = this.state;
    var states = this.state;
    var preparData = {}
    var selectedData = {}
    if (!this.state.showDescriptionNewButtonFlag) {
      preparData = { service_name: this.state.newButtonValue, rate_per_unit: 0, type: "isNew" }
      selectedData = { value: this.state.newButtonValue, label: "", type: "isNew" }
    } else {
      preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
      selectedData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
    }
    if (!this.state.showDescriptionNewButtonFlag) {
      searchDescriptionList.push(preparData);
      searchDescriptionList = this.removeDuplicate(searchDescriptionList);
      billingDescriptionList.push(preparData);
      billingDescriptionList = this.removeDuplicate(billingDescriptionList);
      states["newButtonValue"] = "";
      states["showDescriptionNewButtonFlag"] = true;
      states["selectedDescription"] = selectedData;
    }
    if (!this.state.showLabSetNewButtonFlag) {
      listOfLabSet.push(preparData);
      listOfLabSet = this.removeDuplicate(listOfLabSet);
      states["newButtonValue"] = "";
      states["showLabSetNewButtonFlag"] = true;
      states["selectedLabSet"] = selectedData;
    }
    this.setState({
      billingDescriptionList, states, listOfLabSet,
      searchDescriptionList
    })
  }


  descriptionMultiButtons(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
    var data = this.removeDuplicate(setOfData)
    return (
      <View style={{ flexDirection: 'column' }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
          {
            data.map((item, index) => {
              var listData = {}
              if (key == "selectedDescription") {
                listData = { value: item.service_name, label: item.rate_per_unit, type: item.type }
              } else {
                listData = item
              }
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    item={listData}
                    selectedvalue={selectedValue}
                    butttonText={listData.value}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={key} />
                </View>
              )
            })
          }
        </View>
      </View>

    )
  }

  renderBillingLabSet() {
    return (
      <View style={{ marginVertical: 15 }}>
        <CommonHistorySectionHeader
          heading1={"Lab Set"}
          noOfColumn={1}
          columnSize={[1]}
        />
        {this.descriptionMultiButtons(
          "",
          "selectedLabSet",
          this.state.selectedLabSet,
          "showLabSetNewButtonFlag",
          this.state.showLabSetNewButtonFlag,
          this.state.listOfLabSet
        )}
      </View>
    )
  }

  renderBillingDescriptionSuggesstion() {
    return (
      this.state.searchDescriptionOpen ? <View style={{ zIndex: 1, position: 'absolute', marginTop: 100, marginLeft: 10, maxHeight: 250, minWidth: 300, maxWidth: 350 }}>

        <ScrollView
          style={{
            height: 195, marginBottom: 10, flex: 1,
            backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
          }}
        >
          {this.state.searchDescriptionList.map((value, index) =>
            <View style={{ height: 30 }}>
              <TouchableOpacity key={index}
                style={{ marginLeft: 5, marginTop: 5 }}
                onPress={
                  event => {

                    let { enterRatePerUnits, enterUnits, searchDescription, searchDescriptionOpen, selectedDescription, showDescriptionBottomLayout } = this.state;
                    searchDescription = value.service_name;
                    searchDescriptionOpen = false;
                    showDescriptionBottomLayout = false;
                    this.state.selectedTemplateName = ""
                    var selectedData = {}
                    selectedData = { value: value.service_name, label: value.service_name, type: "" }
                    if (value.service_name == "Advance payment" || value.service_name == "Bed Charges") {
                      enterRatePerUnits = 0;
                      enterUnits = 0
                    } else {
                      enterRatePerUnits = value.rate_per_unit;
                      enterUnits = this.state.enterUnits
                    }
                    selectedDescription = selectedData;
                    this.setState({
                      selectedDescription,
                      searchDescription,
                      searchDescriptionOpen,
                      showDescriptionBottomLayout,
                      enterRatePerUnits,
                      enterUnits,
                      selectedTemplateName:""
                    }, () => {
                      this.calculateTotalAmount()
                    })

                  }
                }
              >
                <Text>{value.service_name}</Text>

              </TouchableOpacity>
            </View>
          )}

        </ScrollView>

      </View> : null

    )
  }

  renderBillingDescription() {
    let { searchDescription, searchDescriptionOpen, showDescriptionBottomLayout, selectedDescription } = this.state;
    return (
      <View style={{ marginVertical: 15 }}>
        <CommonHistorySectionHeader
          heading1={"Description"}
          noOfColumn={1}
          columnSize={[1]}
        />
        <View style={{ marginTop: 10 }}>
          <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: color.themeShade, paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{'Search Description'}</Text>

          <TextInput

            style={{
              borderColor: "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "100%",
              height: 35,
              padding: 15,
              marginTop: 8
            }} value={
              searchDescription
            }
            onFocus={() => {
              searchDescriptionOpen = true;
              showDescriptionBottomLayout = true;
              this.setState({
                searchDescriptionOpen,
                showDescriptionBottomLayout
              })
            }}
            onChangeText={(text) => {
              if (text != "") {
                searchDescriptionOpen = true;
                showDescriptionBottomLayout = true;
                searchDescription = text
                this.setState({
                  searchDescription,
                  showDescriptionBottomLayout,
                  searchDescriptionOpen
                }, () => {
                  this.searchDescriptionMapping(searchDescription);
                })
              } else {
                searchDescriptionOpen = false;
                searchDescription = text;
                selectedDescription = {};
                this.setState({
                  searchDescriptionOpen,
                  searchDescription,
                  selectedDescription
                }, () => {
                  this.searchDescriptionMapping(searchDescription);
                })
              }
            }}
          />

        </View>
        {this.state.showDescriptionBottomLayout ? this.descriptionMultiButtons(
          "",
          "selectedDescription",
          this.state.selectedDescription,
          "showDescriptionNewButtonFlag",
          this.state.showDescriptionNewButtonFlag,
          this.state.billingDescriptionList
        ) : null}
      </View>
    )
  }

  searchDescriptionMapping(value) {

    let { searchDescriptionList } = this.state;
    let tempDescList = this.state.billingDescriptionList
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].service_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchDescriptionList = searchListTemp;
    } else {
      searchDescriptionList = this.state.billingDescriptionList;
    }
    this.setState({
      searchDescriptionList
    })
  }

  renderIdButton = image => {
    return (
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 20,
          shadowOpacity: 0.2,
          shadowRadius: 4,
          shadowOffset: {
            height: 2,
            width: 2
          },
          elevation: 4,
          backgroundColor: "#FCFEFF",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20
        }}
      >
        <Icon name={image} color={"#DD1717"} size={15} />
      </View>
    );
  };
  ModifyUnits = (operation, index) =>{
    try{
      let {billingList} = this.state;
      if(operation == "plus" && billingList[index].Units >=1){
        var item = billingList[index];
        item.Units = item.Units +1;
        item.TotalAmount = item.Units * item.RatePerUnits
        billingList[index] = item;
        this.setState({
          billingList : billingList
        }, () => {
          var amount = this.state.billingList.reduce(function (sum, current) {
            return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
          }, 0);
          this.setState({
            totalAmount: amount,
            selectedDescription: {},
            enterRatePerUnits: "",
            enterUnits: 1,
            enterTotalAmount: ""
          }, () => {
            this.calculateDiscountAmount()
            this.calculateCashRoundOffAmount()
          })
        })
      }
      else if(operation == "minus" && billingList[index].Units >1){
        var item = billingList[index];
        item.Units = item.Units -1;
        item.TotalAmount = item.Units * item.RatePerUnits
        billingList[index] = item;
        this.setState({
          billingList : billingList
        }, () => {
          var amount = this.state.billingList.reduce(function (sum, current) {
            return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
          }, 0);
          this.setState({
            totalAmount: amount,
            selectedDescription: {},
            enterRatePerUnits: "",
            enterUnits: 1,
            enterTotalAmount: ""
          }, () => {
            this.calculateDiscountAmount()
            this.calculateCashRoundOffAmount()
          })
        })
      }
    }catch(e){

    }
  }

  renderListofBills() {
    return (
      <View>
        {
          this.state.billingList && this.state.billingList.length > 0 ?
            this.state.billingList.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#91939F", borderBottomWidth: 1, paddingVertical: 13, alignItems: 'center' }}>
                  <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.3 }]}>{item.Description}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>{this.state.FO_admin_setting && this.state.FO_admin_setting.allow_ip_units_change && (this.state.isAdmin || !this.state.isSavedBill || (this.state?.userEditAccess)) === true ?<TouchableOpacity style={{backgroundColor: color.themeDark, padding: 5}} onPress={() => {this.ModifyUnits("plus", index)}}><Text style={{color:color.white}}>+</Text></TouchableOpacity> : null }{item.Units}{this.state.FO_admin_setting && this.state.FO_admin_setting.allow_ip_units_change && (this.state.isAdmin || !this.state.isSavedBill || (this.state?.userEditAccess)) === true ?<TouchableOpacity style={{backgroundColor: color.themeDark, padding:5}} onPress={() => {this.ModifyUnits("minus", index)}}><Text style={{color:color.white}}>-</Text></TouchableOpacity> : null }</Text>
                  <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.25 }]}>{item.RatePerUnits}</Text>
                  {
                    (this.state?.isAdmin && !this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess) ? 
                    (
                      <View style={{ flex: 0.25 }}>
                        <TextInput
                          style={{ fontSize: '0.91vw', border: "1px solid gray", width: "30%", height: "5vh", borderRadius: "5px", paddingLeft: "0.5vw" }}
                          editable={(this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state?.userEditAccess))}
                          keyboardType="numeric"
                          value={item.TotalAmount ? item.TotalAmount : null}
                          placeholder={0}

                          onChangeText={text => {
                            let { billingList } = this.state
                            billingList[index].TotalAmount = +text;
                            
                            this.setState({ billingList }, () => {
                              var amount = this.state.billingList.reduce(function (sum, current) {
                                return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
                              }, 0);
                              this.setState({
                                totalAmount: amount,
                                selectedDescription: {},
                                enterRatePerUnits: "",
                                enterUnits: 1,
                                enterTotalAmount: ""
                              }, () => {
                                this.calculateTotalAmount()
                                this.calculateDiscountAmount()
                                this.calculateCashRoundOffAmount()
                              })
                            })
                          }}
                        /> 
                        </View> 
                    ):(
                    <View style={{ flex: 0.25 }}><Text >{item.TotalAmount}</Text></View>
                    )
                  }
                  
                  { (this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state?.userEditAccess)) ?
                  <TouchableOpacity
                    onPress={() => {
                      let { billingList, removableIds } = this.state;
                      if (billingList[index].id) {
                        var deleteId = billingList[index].id
                        removableIds.push(deleteId)
                        this.setState({
                          removableIds
                        })
                      }
                      billingList.splice(index, 1)
                      this.setState({ billingList }, () => {
                        var amount = this.state.billingList.reduce(function (sum, current) {
                          return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
                        }, 0);
                        this.setState({
                          totalAmount: amount,
                          selectedDescription: {},
                          enterRatePerUnits: "",
                          enterUnits: 1,
                          enterTotalAmount: ""
                        }, () => {
                          this.calculateDiscountAmount()
                          this.calculateCashRoundOffAmount()
                        })
                      })

                    }}
                    style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>
                    {this.renderIdButton("trash")}
                  </TouchableOpacity> : null}
                </View>
              )
            })
            : null
        }
      </View>

    )
  }
  savedBillPrint = () => {
    let billID = this.state.invoiceID ? this.state.invoiceID : this.state.SummaryId ? this.state.SummaryId : this.props.billID
    var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + billID + "&export_type=pdf";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.savedBillPrintSuccess,
      this.savedBillPrintFailure,
      "pdf"
    );
  }

  savedBillPrintSuccess = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  savedBillPrintFailure = (error) => {
    console.log(error);
  }
  
  onPressTemplateName = (items) => {
    this.setState({
      selectedTemplateName: items.template_name,
      templateServiceList: items.service_data,
      selectedDescription:"",
      enterRatePerUnits: 0,
      enterUnits:0
    })
  }
  renderTemplateData = (item, index) => {
    return (
      <TouchableOpacity onPress={() => {
        this.onPressTemplateName(item)
      }} key={index} style={[{
        marginBottom: 15,
        backgroundColor: this.state.selectedTemplateName === item.template_name ? color.themeDark : null,
        borderColor: this.state.selectedTemplateName == item.template_name ? color.themeDark : "gray",
        borderWidth: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingTop: 8,
        paddingBottom: 8,
        marginLeft: 10
      }, Style.allButtonBorderRadius]}>
        <Text style={{
          fontSize: 11,
          color: this.state.selectedTemplateName === item.template_name ? color.white : color.black
        }}>{item.template_name}</Text>
      </TouchableOpacity>
    )
  }

  render() {
    return (
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
        <View style={{ width: "65%", height: screenHeight - 65 }}>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={this.state.isCurrentPageIsIPPatientAppointment ? navigationHeaderListIfIPCreateBill : navigationHeaderList}
            selectedTab={"ipPatientCreateBill"} />
          <ScrollView
            style={{ width: "100%", height: screenHeight - 65, }}
            bounces={false}
            bouncesZoom={false}
          >
            <View style={[Style.leftView, { width: "100%", backgroundColor: color.applicationBackgroundColor }]}>
              <View style={{ marginTop: 20, width: "100%", backgroundColor: color.white, paddingVertical: 20, paddingHorizontal: 10, marginHorizontal: 10 }}  >
                <View style={{ marginBottom: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", marginLeft: 10 }}>
                  <Text style={{ color: color.themeDark, fontSize: 18 }}>Billing</Text>
                  <View style={{ flexDirection: "row" }}>
                    {/* {this.props.billID || this.state.isBillModification ?
                      <TouchableOpacity
                        onPress={() => { this.savedBillPrint() }}
                        style={{ borderWidth: 1, borderColor: color.themeDark, borderRadius: 4, backgroundColor: color.themeDark, marginRight: 10 }} >
                        <Icon name={"print"} size={20} style={{ padding: 5 }} color={color.white} />
                      </TouchableOpacity> : null
                    } */}

                    <DatePicker
                      disabledDate={current => {
                        return current && current < moment().add(-1, "month") ||
                          current && current > moment().add(0, "days");
                        ;
                      }}
                      value={ this.state.isBillModification ? moment(this.state.invoiceDate,"DD-MM-YYYY") : moment(this.state.invoiceDate) }
                      onChange={(date) => {
                        if(this.state.isBillModification){
                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate , "DD-MM-YYYY").format("DD-MM-YYYY");

                        this.setState({ invoiceDate: converted_date });
                        }else{
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                        this.setState({ invoiceDate: converted_date });
                        }
                      }
                      }
                      format={"DD-MM-YYYY"}
                      placeholder={"Select"}
                      disabled={this.state.isAdmin || (!this.state?.isAdmin && this.state?.userEditAccess) ? false : true}
                      style={{
                        height: 30,
                        borderWidth: 1,
                        borderColor: color.rightPanelInputBorder,
                        borderRadius: 5,

                      }}
                      allowClear={false}
                    />
                    <TouchableOpacity onPress={() => {
                      var prepareData = {
                        patientDetails: {
                          "patient_id": this.state.selectedIpPatientDetails.patient_id,
                          // "patient_photo_url": this.state.patientInfo.photo_url,
                          // "patient_name": this.state.patientInfo.name,
                          // "patient_mobile_number": this.state.patientInfo.mobile_number,
                          // "invoice_date": this.state.patientInfo
                        },
                        // isSearchedPatient: false,
                        // patient_id: this.state.patientAppointment.patient_id
                      }
                      this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData, "", true)
                    }}
                      disabled={false}>
                      <Text style={[styles.LabBillingButton, { backgroundColor: color.themeDark }]}>{"Laboratory Billing"}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <CommonHistorySectionHeader
                  heading1={"Description"}
                  heading2={"Units"}
                  heading3={"Rate per unit"}
                  heading4={"Total Amount"}
                  heading5={"Action"}
                  noOfColumn={5}
                  columnSize={[0.3, 0.1, 0.25, 0.25, 0.1]}
                />
                {/* Write your code here  */}
                {this.renderListofBills()}
                {this.renderFooterView()}
                {this.renderNetAmountRow()}
                {this.renderBillTypeView()}
                {this.renderPaymentTypeRadioButton()}

                {this.renderButton()}
              </View>

            </View>
          </ScrollView>
        </View>



        <ScrollView showsVerticalScrollIndicator={false}
          style={{ width: "35%", height: screenHeight - 65 }} >
          <View style={[Style.rightView, { padding: 20, width: "100%", height: this.state.isOpthamalogyClinic ? "78%" : "70%" }]}>
            {/* Write your code here  */}

            {
              this.state.billingType == "laboratory billing" ?
                <View>
                  {this.renderBillingLabSet()}
                </View>
                :
                <View>
                  {this.renderOverAllBillStatus()}
                  {
                    <View style={{ marginTop: 10 }}>
                      <CommonHistorySectionHeader
                        heading1={"Template"}
                        noOfColumn={1}
                        columnSize={[1]}
                      />
                      <View style={styles.templateContainer}>
                        {this.state.billTemplateList.length > 0 ?
                          this.state.billTemplateList.map((item, index) => {
                            return this.renderTemplateData(item, index)
                          }) : null
                        }
                      </View>
                    </View>
                  }
                  <View>
                    <View>
                      {this.renderBillingDescription()}
                    </View>
                    {this.renderBillingDescriptionSuggesstion()}
                  </View>

                  {this.renderRatePerUnits()}
                  {this.renderTotalAmount()}
                </View>
            }

            {(this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state?.userEditAccess)) ?
              <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} /> : null }
          </View>
        </ScrollView>

      </View>
    );
  }

  Cancelbilling() {
    var states = this.state
    states["invoiceDate"] = moment(new Date()).format("YYYY-MM-DD"),
      states["totalAmount"] = 0.00,
      states["netAmount"] = 0.00,
      states["firstPayment"] = "",
      states["secondPayment"] = "",
      states["firstAmount"] = "",
      states["secondAmount"] = "",
      states["Cheque"] = "",
      states["roundOff"] = 0.00,
      states["paymentTypeCashAmount"] = "",
      states["paymentTypeCardAmount"] = "",
      states["paymentTypeCardNumber"] = "",
      states["receviedAmount"] = "",
      states["balance"] = "",
      states["PaymentTaotalAmount"] = 0.00,
      states["cardNumber"] = "",
      states["discountPersentage"] = 0.00,
      states["discount"] = 0
    states["upiTransactionID"] = ""
    states["bankTransactionID"] = ""
    states["advance_check_Selected"] = false
    states["billingList"] = [],
      states["companyName"] = "",
      states["policyNumber"] = "",
      states["validity"] = "",
      states["transactionNumber"] = "",
      states["selectedBillType"] = "",
      states["billTypeCompanyName"] = "",
      states["billTypeEmployeeID"] = "",
      states["EmployerID"] = "",
      states["billId"] = null,
      states["idIpNumber"] = null
      states["isBillModification"] = false
    this.setState({
      states
    })


  }

  renderBillTypeTextBoxRow(label, key, value) {
    return (
      <View style={{ marginVertical: '0.52vw', marginRight: '0.97vw' }}>
        <View style={{ flex: 0.4, justifyContent: "center" }}>
          <Text style={{ fontSize: '0.8vw', fontWeight: "500", marginBottom: '0.65vw' }}>{label}</Text>
        </View>
        <View style={{}}>
          {
            key ?
              <TextInput
                editable={false}
                style={{ textAlign: 'center', height: '1.82vw', width: '14vw', borderRadius: '0.26vw', borderWidth: 1, borderColor: "#91939F", fontSize:'0.9vw' }}
                value={value}
                onChangeText={(text) => {
                  if (key != "") {
                    var state = this.state;
                    state[key] = text;
                    this.setState({
                      state
                    })
                  }
                }}
              />
              : null
          }

        </View>

      </View>
    )
  }

  renderPaymentTypeTextBoxRow(label, key, value) {
    return (
      <View style={{ marginVertical: 8, marginRight: 15 }}>
        <View style={{ flex: 0.4, justifyContent: "center" }}>
          <Text style={{ fontSize: 12, fontWeight: "500", marginBottom: 10 }}>{label}</Text>
        </View>
        <View>
          {
            key ?
              <TextInput
                style={{ textAlign: 'center', height: 28, width: key === "companyName" ? 200 : 95, borderRadius: 4, borderWidth: 1, borderColor: "#91939F" }}
                value={value}
                maxLength={key == "cardNumber" ? 50 : key == "Cheque" ? 50 : null}
                onChangeText={(text) => {
                  if (  (key == "upiTransactionID" || key == "bankTransactionID" || key == "Cheque" || key == "cardNumber") && key !== "firstAmount") {
                    if(this.numberValidation(text) && this.numberValidationWithoutDot(text)){
                    var state = this.state;
                    state[key] = text;
                    this.setState({
                      state
                    })
                  }
                  } else if(key === "PaymentTaotalAmount"){
                    this.setState({
                      PaymentTaotalAmount : this.state.PaymentTaotalAmount
                    })
                  } else if (this.numberValidation(text) && (this.state.firstPayment != "Insurance" || this.state.secondPayment != "Insurance") && key !== "firstAmount" && key !== "secondAmount") {
                    
                      if (key === "paymentTypeCashAmount") {
                        this.setState({
                          paymentTypeCardAmount: this.state.PaymentTaotalAmount
                        })
                      }
                      else if (key === "paymentTypeCardAmount") {
                        this.setState({
                          paymentTypeCashAmount: this.state.PaymentTaotalAmount
                        })
                      }
                    var states = this.state;
                    states[key] = text;
                    this.setState({ states }, () => {
                      if (this.state.selectedPaymentType == "Cash" && this.state.receviedAmount && this.state.selectedPaymentType != "Cash & Card") {
                        var states = this.state.receviedAmount - this.state.PaymentTaotalAmount
                        this.setState({
                          paymentTypeCashAmount: parseFloat(this.state.receviedAmount),
                          balance: states
                        })
                      } else if (this.state.selectedPaymentType == "Card" && this.state.selectedPaymentType != "Cash & Card") {

                        this.setState({
                          paymentTypeCardAmount: parseFloat(this.state.PaymentTaotalAmount)
                        })
                      } else if (this.state.selectedPaymentType == "Cash & Card") {
                        if (key === "paymentTypeCashAmount") {
                          var cardAmount = parseFloat(this.state.PaymentTaotalAmount) - parseFloat(this.state.paymentTypeCashAmount)
                          this.setState({
                            paymentTypeCardAmount: cardAmount
                          })
                        }
                        else if (key === "paymentTypeCardAmount") {
                          var cashAmount = parseFloat(this.state.PaymentTaotalAmount) - parseFloat(this.state.paymentTypeCardAmount)
                          this.setState({
                            paymentTypeCashAmount: cashAmount,
                          })
                        }
                      }
                    })
                  } else if ((this.state.firstPayment === "Insurance" || this.state.secondPayment === "Insurance") && key == "companyName" && key !== "firstAmount" && key !== "secondAmount") {
                    var state = this.state
                    state[key] = text;
                    this.setState({
                      state
                    })
                  } else if((this.numberValidationWithoutDot(text) || text == "") && key === "firstAmount"){
                    if(parseFloat(this.state.PaymentTaotalAmount) >= text){
                      var secondAmount = this.state.PaymentTaotalAmount - text
                      this.setState({
                        firstAmount : text,
                        secondAmount : secondAmount
                      })
                    }
                  } else if((this.numberValidationWithoutDot(text) || text == "") && key === "secondAmount"){
                    if(parseFloat(this.state.PaymentTaotalAmount) >= text){
                      var firstAmount = this.state.PaymentTaotalAmount - text
                      this.setState({
                        secondAmount : text,
                        firstAmount : firstAmount
                      })
                    }
                  }
                }}
              />
              : null
          }

        </View>

      </View>
    )
  }

  renderNetAmountRow() {
    return (
      <View>

        {this.props.billID ?
          <View>
            <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1, borderBottomWidth: 1, borderBottomColor: "#91939F" }}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                {this.renderAmountCalculationRow("Payable Amount", "", "", parseFloat(this.state.PaymentTaotalAmount) || 0)}
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                {this.renderAmountCalculationRow("Balance Amount", "", "", parseFloat(this.state.balanceAmount) || 0)}
              </View>
            </View>
          </View> :
          <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1, borderBottomWidth: 1, borderBottomColor: "#91939F" }}>
            <View style={{ flex: 0.6 }}></View>
            <View style={{ flex: 0.4 }}>
              {this.renderAmountCalculationRow("Payable Amount", "", "", parseFloat(this.state.PaymentTaotalAmount) || 0)}
            </View>
          </View>
        }
      </View>
    )
  }

  advance_check_Selected = () => {

    let { advance_check_Selected } = this.state
    this.setState({ advance_check_Selected: !advance_check_Selected }, () => {

      if (!advance_check_Selected) // selected
      {

        this.setState({
          // selectedPaymentType: "",
          firstPayment : "",
          secondPayment : "",
          cheque_number : "",
          firstAmount : "",
          secondAmount : "",
          cardNumber: "",
          receviedAmount: "",
          balance: "",
          paymentTypeCashAmount: "",
          paymentTypeCardAmount: ""
        }, () => {

          this.calculateCashRoundOffAmount()
          this.netAmountCalculation()

        })

      } else {
        this.netAmountCalculation()
      }

    })

    // )
  }

  renderAdvancePayment(name, amount) {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between", marginVertical: 10 }}>

        <Text style={{ color: "#91939F", fontSize: 14 }}>{name}</Text>
        <Text style={{ fontWeight: "500", fontSize: 14, marginRight: 10 }}>{"₹ "}{-amount}</Text>
      </View>
    )
  }
  netAmountCalculation() {

    var states = this.state;

    var netAmountAfterDeduction = 0
    var netAmount = (parseFloat(states.totalAmount) || 0) - (parseFloat(states.discount) || 0) - (parseFloat(states.roundOff) || 0)

    if (states.advance_check_Selected) {
      //  200 -500 = -300<0 = 0
      //  700 -500 =  200>0 = 200
      let total = netAmount + (parseFloat(states.overallBalance) || 0)
      netAmountAfterDeduction = total < 0 ? 0 : total

    } else {
      netAmountAfterDeduction = netAmount
    }
    this.setState({
      // states 
      netAmount: netAmount,
      PaymentTaotalAmount: netAmountAfterDeduction,
      receviedAmount : netAmountAfterDeduction,
      firstAmount : netAmountAfterDeduction,
      secondAmount : ""
    }, () => {
      this.calculateCashRoundOffAmount()
    })

  }

  calculateDiscountAmount(amount) {

    var states = this.state;
    let { is_disc_percent_changed, discount, discountPersentage } = this.state;

    if ((states.totalAmount && states.discountPersentage) || (states.totalAmount && discount)) {

      var amount = parseFloat(discount);

      if (is_disc_percent_changed) {
        let tem_discountPersentage = parseFloat(states.discountPersentage) || 0;
        amount = ((parseFloat(states.totalAmount) / 100) * tem_discountPersentage);
      } else {
        discountPersentage = ((amount / this.state.totalAmount) * 100).toFixed(2);
      }

      this.setState({
        discount: amount.toFixed(2),
        discountPersentage: discountPersentage
      }, () => {

        var { firstPayment, secondPayment } = this.state;
        var states = this.state;

        if (firstPayment == "Card" || secondPayment == "Card") {
          states["roundOff"] = 0.00
        } else if (firstPayment == "Cash" || secondPayment == "Cash") {
          this.calculateCashRoundOffAmount()
        }

        this.setState({ states }, () => {
          this.netAmountCalculation()

        })
      })
    } else {
      this.netAmountCalculation()
    }

  }

  validateAdvancePayment(list) {

    var isAdvance = true;
    for (var i = 0; i < list.length; i++) {
      if (list[i].Description == "Advance payment") {
        var isAdvance = false
        return isAdvance
      }
    }
    return isAdvance
  }

  renderAmountCalculationRow(label, key, value, amount, amountKey) {
    var textBoxDisabele = true
    if (key == "discountPersentage") {
      if(this.state.disableDiscountPercent){
        textBoxDisabele = false
      }
      else{
        textBoxDisabele = this.validateAdvancePayment(this.state.billingList)
      }
      

    }

    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-end", marginVertical: 8 }}>
        <View style={{ flex: 0.4, justifyContent: "center" }}>
          <Text style={{ color: key === "payableAmount" ? color.black : "#91939F", fontSize: 14 }}>{label}</Text>
        </View>

        <View style={{ flex: 0.25, alignItems: "center", justifyContent: "center" }}>

          {
            key ?
              <TextInput
                editable={textBoxDisabele}
                style={{ textAlign: 'center', height: 28, width: 65, borderRadius: 4, borderWidth: 1, borderColor: "#888888", backgroundColor: !textBoxDisabele ? "#F0F0F0" : "" }}
                value={value}
                onChangeText={(text) => {
                  // var text = val ? val : key == "discountPersentage" ? 0 : ""
                  if (this.numberValidation(text)) {
                    if(key == "discountPersentage"){
                      this.setState({
                        discountPersentage : text <= 100 ? text : this.state.discountPersentage
                      }, () => {
                        this.calculateDiscountAmount()
                      })
                    }
                    var states = this.state;

                    states[key] = text;
                    states['is_disc_percent_changed'] = true

                    this.setState({ states }, () => {
                      if (key == "discountPersentage") {
                        this.calculateDiscountAmount()
                      }
                    })
                  }
                }}
              />
              : null
          }

        </View>
        <View style={{ flex: 0.35, alignItems: "flex-end", marginRight: 15, justifyContent: "center" }}>

          {
            amountKey == "discount" ?
              <TextInput
                editable={textBoxDisabele}
                style={{ textAlign: 'center', height: 28, width: 65, borderRadius: 4, borderWidth: 1, borderColor: "#888888", backgroundColor: !textBoxDisabele ? "#F0F0F0" : "" }}
                value={amount}
                onChangeText={(text) => {
                  if (this.numberValidation(text)) {
                    // alert(text)
                    var states = this.state;
                    states["discount"] = text ? text < this.state.totalAmount ? text : this.state.discount : 0;
                    states['is_disc_percent_changed'] = false

                    if (text) {
                      if(text <= 100 ){
                      states["discount"] = text
                      states["discountPersentage"] = (text / this.state.totalAmount) * 100;
                      }
                    } else {
                      states["discount"] = 0
                      states["discountPersentage"] = 0;
                    }
                    this.setState({
                      states
                    }, () => {
                      if (amountKey == "discount") {
                        this.calculateDiscountAmount()
                      }

                    })
                  } else {
                    this.calculateDiscountAmount()
                  }
                }}
              />
              :
              <Text style={{ fontWeight: "500", fontSize: 14 }}>{"₹ "}{amount}</Text>
          }
        </View>
      </View>
    )
  }
  calculateCashRoundOffAmount() {

    var states = this.state;

    var roundOffAmount = states.netAmount % 1;
    var netAmount = Math.round(states.netAmount)

    states["netAmount"] = netAmount;

    /* calc based on advance amount */
    var netAmountAfterDeduction = 0

    if (states.advance_check_Selected) {

      let total = netAmount + (parseFloat(states.overallBalance) || 0)
      netAmountAfterDeduction = total < 0 ? 0 : total

    } else {
      netAmountAfterDeduction = netAmount
    }
    /* calc adv end */
    // states["PaymentTaotalAmount"] = Math.round(states.netAmount);
    states["PaymentTaotalAmount"] = netAmountAfterDeduction;
    states["receviedAmount"] = netAmountAfterDeduction,
    states["firstAmount"] = netAmountAfterDeduction

    if (parseFloat(roundOffAmount) >= 0.5) {
      // states["netAmount"] = parseInt(states.totalAmount) + 1;;
      // amount_net = parseInt(states.totalAmount) + 1;
      states["roundOff"] = (1 - parseFloat(roundOffAmount))

      // round_off= "+" +(1-parseFloat(round_off))
    } else if (parseFloat(roundOffAmount) < 0.5) {
      // states["netAmount"] = parseInt(states.totalAmount);
      states["roundOff"] = "-" + (roundOffAmount)

    }
    this.setState({ states })
  }

  getPatientCorporateDetails (){
    var service_url = Constants.GET_PATIENT_CORPORATE_DETAILS + "?patient_id=" + this.state.selectedIpPatientDetails.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientCorporateDetailsSuccess,
      this.getPatientCorporateDetailsFail
    );
    
  }

  getPatientCorporateDetailsSuccess = (response) => {
    try{
    if(response.status == "success"){
      if (response.data.company_name && response.data.company_name != "" && response.data.company_name != null) {
        this.setState({
          selectedBillType: "Corporate",
          billTypeCompanyName: response.data.company_name,
          billTypeEmployeeID: response.data.employee_id,
          discountPersentage: response.data.employer_discount,
          EmployerID:response.data.employer_id,
          disableDiscountPercent: true
        }, ()=>{ this.calculateDiscountAmount()})
      }
      else {
        this.props.showResposeValue("error", 'This Patient is not Linked with Any Corporates. Please Add the Corporate in Patient Demographics')
      }
    }
  }
  catch (error){
    console.log(error)
  }
  }
  getPatientCorporateDetailsFail =  (error)=>{
    this.props.showResposeValue("error", error.message)
  }

  CorporateUnchecked() {
    this.setState({
      billTypeCompanyName: "",
      billTypeEmployeeID: "",
      EmployerID: "",
      discountPersentage: 0,
      disableDiscountPercent: false
    }, () => { this.calculateDiscountAmount() })
  }
  renderBillTypeView() {
    var listOfBillType = [
      { label: "Corporate", value: "Corporate" },
      { label: "Credit", value: "Credit" }
    ]
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <View>
          <Text style={[styles.emed_IPbill_sideheader]}>{"Bill Type"}</Text>
        </View>
        <View style={[styles.emed_content_container]}>
          {
            listOfBillType.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                  if (this.state.selectedBillType == item.value) {
                    this.setState({
                      selectedBillType: ""
                    }, () => { this.CorporateUnchecked() })
                  } else {
                      if (item.value == "Corporate") {
                        this.getPatientCorporateDetails()
                      } else if (item.value == "Credit") {
                        this.setState({
                          selectedBillType: "Credit",
                        }, () => { this.CorporateUnchecked() })
                      }

                    // })
                  }
                }} style={[styles.emed_radio_btn_touchablearea]} key={index}>
                  <View style={[{
                    backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                  }, styles.emed_radio_btn]}></View>
                  <Text style={[styles.emed_radio_btn_txt]}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
        {this.state.selectedBillType == "Corporate" ?
          <View style={{ flexDirection: "row" }}>
            {this.renderBillTypeTextBoxRow("Company Name", "billTypeCompanyName", this.state.billTypeCompanyName)}
            {this.renderBillTypeTextBoxRow("Employee ID", "billTypeEmployeeID", this.state.billTypeEmployeeID)}
          </View>
          :
          null
        }
      </View>
    )
  }

  clearDetails = () =>{
    this.setState({
      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : "",
      cardNumber : "",
      companyName: "",
      policyNumber: "",
      validity: "",
      transactionNumber: "",
      upiTransactionID: "",
      bankTransactionID: "",
    })
  }

  clearSinglePayment = (item) =>{
    if(item === "Card"){
      this.setState({
        cardNumber : "",
      })
    }else if(item === "UPI"){
      this.setState({
        upiTransactionID: ""
      })
    }else if(item === "Bank Transfer"){
      this.setState({
        bankTransactionID : ""
      })
    }else if(item === "Insurance"){
      this.setState({
        companyName: "",
        policyNumber: "",
        validity: "",
        transactionNumber: ""
      })
    } else if(item === "Cheque"){
      this.setState({
        Cheque : ""
      })
    }
    this.setState({
      secondAmount : ""
    })
  }

  onChangePaymentType = (item) => {
    if(this.state.netAmount < parseFloat(-this.state.AdvancePayment) && this.state.advance_check_Selected){
      this.setState({
        firstPayment:"",
        secondPayment:""
      })
    }else{
    if (this.state.firstPayment == "" && this.state.secondPayment == "") {
      if (this.state.secondPayment != item.value) {
        this.setState({
          firstPayment: item.value
        })
      } else {
        this.setState({
          secondPayment: "",
        }, () => {
          this.clearSinglePayment(item.value)
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment == "") {
      if (this.state.firstPayment != item.value) {
        this.setState({
          secondPayment: item.value
        })
      } else {
        this.setState({
          firstPayment: ""
        }, () =>{
          this.clearDetails()
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment != "") {
      if (this.state.firstPayment == item.value) {
        this.setState({
          firstPayment: this.state.secondPayment,
          secondPayment:""
        }, () => {
          this.clearSinglePayment(item.value)
        })
      } else if (this.state.secondPayment == item.value) {
        this.setState({
          secondPayment: ""
        }, () => {
          this.clearSinglePayment(item.value)
        })
      } else {
        var tem_Type = this.state.secondPayment
        this.setState({
          secondPayment : this.state.firstPayment,
          firstPayment: item.value
        },() => {
          this.clearSinglePayment(tem_Type)
        })
      }
    }
    this.setState({
      firstAmount : this.state.PaymentTaotalAmount,
      secondAmount : ""
    })
  }
  }

  renderPayment = (paymentType) => {
    if (paymentType === "Card") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow("Card Transaction ID", "cardNumber", this.state.cardNumber)}
        </View>
      )
    }
    else if (paymentType === "UPI") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow("UPI Transaction ID", "upiTransactionID", this.state.upiTransactionID)}
        </View>
      )
    }
    else if (paymentType === "Bank Transfer") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow("Bank Transaction ID", "bankTransactionID", this.state.bankTransactionID)}
        </View>
      )
    }
    else if (paymentType === "Insurance") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow("Company Name", "companyName", this.state.companyName)}
          {this.renderPaymentTypeTextBoxRow("Policy Number", "policyNumber", this.state.policyNumber)}
          {this.renderPaymentTypeTextBoxRow("Validity", "validity", this.state.validity)}
          {this.renderPaymentTypeTextBoxRow("Transaction Number", "transactionNumber", this.state.transactionNumber)}
        </View>
      )
    }
    else if(paymentType === "Cheque"){
      return(
        <View>
          {this.renderPaymentTypeTextBoxRow("Cheque Number", "Cheque", this.state.Cheque)}
        </View>
      )
    } else {
      return null
    }
  }
  
  renderPaymentTypeRadioButton() {
    var listOfPaymentType = [
      { label: "Cash", value: "Cash" },
      { label: "Card", value: "Card" },
      { label: "UPI", value: "UPI" },
      { label: "Bank Transfer", value: "Bank Transfer" },
      { label: "Insurance", value: "Insurance" },
      // { label: "Cheque", value: "Cheque" }
    ]
    return (
      <View>
        <Text style={[styles.emed_IPbill_sideheader]}>{"Payment Type"}</Text>
        <View style={[styles.emed_content_container]}>
          {
            listOfPaymentType.map((item, index) => {
              return (
                <View style={{ display: "flex", flexDirection: "row", marginLeft: ".5vw" }}>
                  <Checkbox onChange={() => { this.onChangePaymentType(item) }} checked={this.state.firstPayment == item.label || this.state.secondPayment == item.label ? true : false}>{item.label}</Checkbox>
                </View>
              )
            })
          }
        </View>
        {this.state.firstPayment != "" || this.state.secondPayment != "" ?
          <View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{  marginRight: this.state.firstPayment === "Cash" || this.state.secondPayment === "Cash" ? "0vw" : "1vw" }}>{this.renderPayment(this.state.firstPayment)}</View>
              {this.renderPayment(this.state.secondPayment)}
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ marginRight: "1vw" }}>{this.state.firstPayment === "Cash" || this.state.secondPayment === "Cash" ?
                this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", parseFloat(this.state.PaymentTaotalAmount)) :
                this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", this.state.PaymentTaotalAmount)}
              </View>
              {this.state.firstPayment != "" ?
                this.renderPaymentTypeTextBoxRow(`${this.state.firstPayment} Amount`, "firstAmount", this.state.firstAmount) : null}
              {this.state.secondPayment != "" ?
                this.renderPaymentTypeTextBoxRow(`${this.state.secondPayment} Amount`, "secondAmount", this.state.secondAmount) : null}
            </View>
          </View>
          : <View style={{ height: "4.5vw", width: "100%" }}></View>}
      </View>
    )
  }

  renderAdvancePaymentCheckBox() {
    let { can_detect_from_advance } = this.state
    return (
      <>
        <Text style={[styles.emed_IPbill_sideheader]}>Add Advance</Text>
        <TouchableOpacity style={{ marginRight: 20, flexDirection: "row", alignItems: "center", marginBottom: 10 }} onPress={() => can_detect_from_advance ? this.advance_check_Selected() : null}>
          <View style={{ backgroundColor: this.state.advance_check_Selected ? color.themeDark : '', marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F" }} />
          <Text>Advance Amount</Text>
        </TouchableOpacity>

      </>
    )
  }

  renderFooterView() {
    return (
      <View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 0.6, justifyContent: "flex-end" }}>

            {/* {this.renderPaymentTypeRadioButton()} */}
            {this.renderAdvancePaymentCheckBox()}
          </View>
          <View style={{ flex: 0.4 }}>
            {/* Billing  Total calculation Section */}
            {this.renderAmountCalculationRow("Sub Amount", "", "", parseFloat(this.state.totalAmount))}
            {this.renderAmountCalculationRow("Discount %", "discountPersentage", this.state.discountPersentage, parseFloat(this.state.discount), "discount")}
            {/* {this.renderAmountCalculationRow("Discount Amount", "discountAmount", this.state.discountAmount, amount)} */}

            {this.renderAmountCalculationRow("Round Off", "", "", parseFloat(this.state.roundOff).toFixed(2))}
            {/* {this.renderAdvancePayment("Advance Amount" ,parseFloat(this.state.overallBalance))} */}
            {this.renderAdvancePayment("Advance Amount", parseFloat(this.state.AdvancePayment))}
          </View>
        </View>
      </View>
    )
  }

  renderButton() {
    return (
      <View style={styles.buttonrowView}>
        <TouchableOpacity
          onPress={() => {
            this.setState({
              hasSavedPartial: true,
            }, () => {
              this.CreateBill()
            })
          }}
          disabled={this.state.isDisabled || this.state.firstPayment || this.state.secondPayment || this.state.advance_check_Selected || (this.state.isAdmin && this.state.isBillModification)}
          style={[styles.ipcancelButton, { backgroundColor: (this.state.firstPayment || this.state.secondPayment || this.state.advance_check_Selected) || (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? "#cccccc" : color.themeDark }]} >
          <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Save"}</Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            this.setState({
              hasSavedPartial: false,
            }, () => {
              this.CreateBill()
            })
          }}
          disabled={this.state.isDisabled || (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? true : false}
          style={[styles.ipcancelButton, { backgroundColor: (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? '#cccccc' : color.themeDark }]} >
          <Icon name={"save"} size={Platform === "web" ? 15 : 20} color={color.white} />
          <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Payment Received"}</Text>
        </TouchableOpacity>
        <TouchableOpacity disabled={(this.props.billID || !this.state.isBillModification) && (this.state.SummaryId < 0 || this.state.SummaryId == "")} onPress={() => { this.savedBillPrint() }}
          style={[{ backgroundColor: (this.props.billID || this.state.isBillModification) || this.state.SummaryId ? color.themeDark : '#cccccc' }, styles.ButtonView]}>
          <Text style={styles.ButtonText}>{"Print"}</Text>
        </TouchableOpacity>
        {this.state.isAdmin || (!this.state?.isAdmin && this.state?.userEditAccess) ?
          <TouchableOpacity
            onPress={() => {
              this.setState({
                isUpdate: true
              }, () =>{
                this.CreateBill()
              })
            }}
            disabled={(this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? false : true}
            style={[styles.ipcancelButton, { backgroundColor: (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? color.themeDark : '#cccccc' }]} >
            {/* <Icon name={"save"} size={Platform === "web" ? 15 : 20} color={color.white} /> */}
            <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Update"}</Text>
          </TouchableOpacity> : null
        }
        <TouchableOpacity
          onPress={() => this.Cancelbilling()}
          style={Style.ipAdmissonCancelButton} >
          <Cancel name={"cross"} size={Platform === "web" ? 15 : 20} color={color.themeDark} />
          <Text style={[Style.certificatetext, { marginLeft: 10, color: color.themeDark }]}>{"Cancel"}</Text>
        </TouchableOpacity>

      </View>
    )
  }

  getRoomDetails() {

    if (this.state.selectedIpPatientDetails.id) {
      var service_url = Constants.IP_ROOMS_DETAILS + "?patient_id=" + this.state.selectedIpPatientDetails.patient_id + "&ip_admission_id=" + this.state.selectedIpPatientDetails.id;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getRoomDetailsSuccess,
      this.getRoomDetailsFailure
    );
  }

  getRoomDetailsSuccess = success => {
    if (success.status == "success") {

      var data = success.data
      this.props.showRoomDetailsPopup(data, true);
    }
  }

  getRoomDetailsFailure = error => {
    console.log("error -----------> " + JSON.stringify(error))
  }

  getOverbillstatus = () => {
    var admissionID = this.state.selectedIpPatientDetails.id ? this.state.selectedIpPatientDetails.id : this.state.selectedIpPatientDetails.admit_id
    var serviceUrl = Constants.IP_OVERALL_BILL_STATUS + "?admission_id=" + admissionID;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getOverbillstatusSuccess,
      this.getOverbillstatusFailure
    );
  };
  getOverbillstatusSuccess = (response) => {

    if (response.status === "success") {

      let total_balance = parseFloat(response.data && response.data.total_balance ? response.data.total_balance : 0) || 0

      this.setState({
        overallTotalamount: response.data.total_bill_amount,
        overallPaidamount: response.data.total_paid_amount,
        overallBalance: total_balance

      })

      // if(response.data.total_balance < 0 && this.state.advance_check_Selected ){
      if (total_balance < 0) {
        this.setState({
          can_detect_from_advance: true,
          AdvancePayment: total_balance

        })
      } else {
        this.setState({
          can_detect_from_advance: false,
          AdvancePayment: 0
        })
      }
    }

  };
  getOverbillstatusFailure = error => { }
  
  CreateBill() {
    var datas = {}
    var states = this.state;
    var { billingList, firstPayment, secondPayment, advance_check_Selected, hasSavedPartial } = this.state
    var getPrepareData = [];
    var cardAmount = 0;
    var message = ""
    for (var i = 0; i < billingList.length; i++) {
      if(billingList[i].TotalAmount == 0) {
        message = "Enter the service amount greater then 0"
      }
    }
    if (!firstPayment && !advance_check_Selected && !hasSavedPartial) {
      this.props.showResposeValue('error', "Choose payment mode");
    } else if ((states.firstPayment == "Card" || states.secondPayment == "Card") && (!states.cardNumber || (states.cardNumber).length == 0) && !hasSavedPartial) {
      this.props.showResposeValue('error', "Enter your Card Transaction ID");
    } else if ((states.firstPayment == "Cheque" || states.secondPayment == "Cheque") && (!states.Cheque || (states.Cheque).length == 0) && !hasSavedPartial){
      this.props.showResposeValue('error', "Enter your Cheque Number");
    }
    // else if (states.selectedPaymentType == "Cash" && states.paymentTypeCashAmount < states.netAmount && advance_check_Selected === false && !hasSavedPartial) {
    //   this.props.showResposeValue('error', "Please Enter the correct Received Amount")}
    else if(message != ""){
      this.props.showResposeValue("error", message)
    }
    else if((this.state.firstPayment == "Insurance" || this.state.secondPayment == "Insurance") && (this.state.companyName == "" || this.state.policyNumber == "" || this.state.transactionNumber == "")){
      this.props.showResposeValue("error", this.state.companyName == "" ? "Enter the Insurance Company Name" : this.state.policyNumber =="" ? "Enter the Insurance Policy Number" : "Enter the Insurance Transaction Number")
    }
    // else if (advance_check_Selected && states.paymentTypeCashAmount < states.PaymentTaotalAmount && states.selectedPaymentType == "Cash" && !hasSavedPartial) {
    //   this.props.showResposeValue('error', "Please Enter the correct Received Amount")}
    else if(this.state.firstAmount == "" && this.state.secondAmount == "" && !hasSavedPartial && (parseFloat(states.discountPersentage) != 100) && !advance_check_Selected){
      this.props.showResposeValue("error", "Enter the amount")
    }
    else if((this.state.firstPayment != "" && this.state.secondPayment == "") && (this.state.firstAmount === "") && !advance_check_Selected){
      this.props.showResposeValue("error", "Amount Field Should not be Empty")
    }
    else if((this.state.firstPayment != "" && this.state.secondPayment == "") && (parseInt(this.state.PaymentTaotalAmount).toFixed() != this.state.firstAmount) && !advance_check_Selected){
      this.props.showResposeValue("error", "Amount Not Matched")
    }
    else if((this.state.firstPayment != "" && this.state.secondPayment != "") && (parseInt(this.state.PaymentTaotalAmount).toFixed() != (parseInt(this.state.firstAmount) + parseInt(this.state.secondAmount)))){
      this.props.showResposeValue("error", "Amount Not Matched")
    }else if(this.state.firstAmount < 0 || this.state.secondAmount < 0 && !hasSavedPartial){
      this.props.showResposeValue("error", "Amount Not Matched")
    }else if(this.state.netAmount > parseFloat(-this.state.AdvancePayment) && this.state.firstPayment == "" && !hasSavedPartial){
      this.props.showResposeValue('error', "Choose payment mode");
    }
    else {
      this.setState({
        isDisabled: true
      })

      for (var i = 0; i < billingList.length; i++) {
        
        datas = {
          service_name: billingList[i].Description,
          units: parseInt(billingList[i].Units),
          rate_per_unit: parseFloat(billingList[i].RatePerUnits),
          amount: parseFloat(billingList[i].TotalAmount),
          id: billingList[i].id ? billingList[i].id : ""
        }

        getPrepareData.push(datas)
      }

      for (var i = 0; i < getPrepareData.length; i++) {
        if (getPrepareData[i]["id"] == "") {
          delete getPrepareData[i]["id"]
          // getPrepareData.push(datas)
        }
      }

      if (advance_check_Selected) {
        var amount_received = 0;
        if (states.receviedAmount > states.PaymentTaotalAmount) {
          amount_received = parseFloat(states.PaymentTaotalAmount)
        } else {
          amount_received = parseFloat(states.receviedAmount)
        }
      } else if (!advance_check_Selected) {
        if (states.receviedAmount > states.netAmount) {
          amount_received = parseFloat(states.netAmount)
        } else if (states.firstPayment == "Insurance" || states.secondPayment == "Insurance") {
          amount_received = parseFloat(states.netAmount)
        }
        else {
          amount_received = parseFloat(states.receviedAmount)
        }
      }

      if (advance_check_Selected) {
        var amount_cash = 0;
        if (states.paymentTypeCashAmount > states.PaymentTaotalAmount && states.selectedPaymentType != "Cash & Card") {
          amount_cash = parseFloat(states.PaymentTaotalAmount)
        } else if (states.selectedPaymentType == "Cash & Card") {
          amount_cash = parseFloat(states.paymentTypeCashAmount)
        } else {
          amount_cash = states.firstPayment == "Insurance" || states.secondPayment == "Insurance" ? 0 : parseFloat(states.receviedAmount)
        }
      } else if (!advance_check_Selected) {
        if (states.paymentTypeCashAmount > states.netAmount && states.selectedPaymentType != "Cash & Card") {
          amount_cash = parseFloat(states.netAmount)
        } else if (states.selectedPaymentType == "Cash & Card") {
          amount_cash = parseFloat(states.paymentTypeCashAmount)
        } else {
          amount_cash = states.firstPayment == "Insurance" || states.secondPayment == "Insurance" ? 0 : parseFloat(states.receviedAmount)
        }
      }
      if (this.state.isBillModification && this.state.selectedPaymentType == "Card") {
        cardAmount = this.state.netAmount;
      } else {
        cardAmount = this.state.paymentTypeCardAmount;
      }

      var transaction_num_1 = ""
      var transaction_num_2 = ""
      transaction_num_1 = states.firstPayment === "Card" ? states.cardNumber : states.firstPayment === "UPI" ? states.upiTransactionID : states.firstPayment === "Bank Transfer" ? states.bankTransactionID : states.firstPayment === "Cheque" ? states.Cheque : ""
      transaction_num_2 = states.secondPayment === "Card" ? states.cardNumber : states.secondPayment === "UPI" ? states.upiTransactionID : states.secondPayment === "Bank Transfer" ? states.bankTransactionID : states.secondPayment === "Cheque" ? states.Cheque : ""

      var PaymentTypeData = returnPaymentMode(this.state.firstPayment + this.state.secondPayment, this.state.firstAmount, this.state.secondAmount, transaction_num_1, transaction_num_2)
      var formatedDate = "";
      if(this.state.isBillModification){
        formatedDate = moment(this.state.invoiceDate, "DD-MM-YYYY");
        formatedDate = formatedDate.format("YYYY-MM-DD")
      }else{
        formatedDate = moment(this.state.invoiceDate).format("YYYY-MM-DD")
      }

      var data = {
        "status": this.state.hasSavedPartial ? 'partial' : "",
        "patient_id": this.state.patinetDetails && Object.keys(this.state.patinetDetails).length > 0 ? this.state.patinetDetails.patient_id : this.state.selectedIpPatientDetails.patient_id,
        "doctor_id": this.state.selectedIpPatientDetails.doctor_id,
        "patient_account_number": this.state.selectedIpPatientDetails.patient_account_number,
        "ip_admission_id": this.state.patinetDetails && Object.keys(this.state.patinetDetails).length > 0 ? this.state.patinetDetails.admit_id : this.state.selectedIpPatientDetails.id,
        // "invoice_date": moment(this.state.invoiceDate).format("YYYY-MM-DD"),
        "invoice_date": formatedDate,
        "total_amount": parseFloat(states.totalAmount),
        "round_off": parseFloat(states.roundOff),
        "net_amount": parseFloat(this.state.netAmount),
        "payment_type": !advance_check_Selected ? hasSavedPartial ? null : PaymentTypeData.paymentMode : this.state.netAmount > parseFloat(-this.state.AdvancePayment) ? PaymentTypeData.paymentMode : null,
        "amount_1":parseFloat(PaymentTypeData.paymentAmt1),
        "amount_2" :parseFloat(PaymentTypeData.paymentAmt2),
        "transaction_id_1" : PaymentTypeData.trans_Num1,
        "transaction_id_2" : PaymentTypeData.trans_Num2,
        "line_items": getPrepareData,
        "can_detect_from_advance": this.state.advance_check_Selected, //can_detect_from_advance,
        "discount_percentage": parseFloat(states.discountPersentage) || 0,
        "discount_amount": parseFloat(states.discount) || 0,
        "bill_type": this.state.selectedBillType,
        "company_name": this.state.billTypeCompanyName,
        "employee_id": this.state.billTypeEmployeeID,
        "employer_id": this.state.EmployerID,
        "insurance_company_name": this.state.companyName,
        "policy_number": this.state.policyNumber,
        "validity": this.state.validity,
        "transaction_number": this.state.transactionNumber,
        "removable_ids": this.state.removableIds,
        "payable_amount": this.state.PaymentTaotalAmount,
        "paid_amount": (
          this.state.hasSavedPartial && (states.firstPayment != "UPI" || states.secondPayment != "UPI") && (states.firstPayment != "Bank Transfer" || states.secondPayment != "Bank Transfer")) ? parseFloat(states.receviedAmount) || 0 :
          ((states.firstPayment || states.secondPayment) && states.paymentTypeCashAmount) || ((states.firstPayment || states.secondPayment) && states.cardNumber) || (states.firstPayment == "Cash" || states.secondPayment == "Cash") ? parseFloat(this.state.PaymentTaotalAmount) :
          ((states.firstPayment == "UPI" || states.secondPayment == "UPI") || (states.firstPayment == "Bank Transfer" || states.secondPayment == "Bank Transfer")) ? parseFloat(this.state.PaymentTaotalAmount) : 
          (!this.state.hasSavedPartial && (states.firstPayment == "Insurance" || states.secondPayment == "Insurance")) ? amount_received : 0, // netAmount
        "received_amount": amount_received,
        // "upi_transaction_id": this.state.upiTransactionID,
        // "cash_amount": amount_cash ? amount_cash : (this.state.receviedAmount && states.selectedPaymentType != "Insurance") ? this.state.receviedAmount : 0,
        // "card_amount": parseFloat(cardAmount),
        // "card_amount": states.paymentTypeCardAmount ? parseFloat(states.paymentTypeCardAmount) : 0,
        // "card_number": states.cardNumber, //paymentTypeCardNumber,  // not using
        // "balance": (states.receviedAmount > states.totalAmount || states.receviedAmount > states.netAmount || states.receviedAmount > states.PaymentTaotalAmount) ? 0 : parseFloat(this.state.balance) || 0,
        // "cash_amount": states.paymentTypeCashAmount ? parseFloat(states.paymentTypeCashAmount) : 0,
      }

      if (this.state.billId) {
        data["id"] = this.state.billId
      } else if (this.state.id) {
        data["id"] = this.state.id
      }

      if (this.state.idIpNumber) {
        data["id_ip_number"] = this.state.idIpNumber
      }

      if (advance_check_Selected) {
        var calculation = parseFloat(this.state.netAmount) - parseFloat(this.state.PaymentTaotalAmount)
        var advanceAmt = Math.abs(calculation)
        data["deductable_advance_amount"] = advanceAmt
      }
      var service_url = Constants.CREATE_BILL;
      console.log("MODIFY BILL DETAILS:::", data);
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.CreateBillSuccess,
        this.CreateBillFailure
      );
    }
  }

  CreateBillSuccess = success => {

    if (success.status == "success") {
      if (this.state.isUpdate) {
        this.props.renderModifyClick(true)
      }
      this.props.showResposeValue('success', success.message);

      this.Cancelbilling();
      this.setState({
        isDisabled: false,
        isBillModification: false,
        SummaryId: success.data ? success.data.summary_id : null
      }, () => {
        this.getOverbillstatus()
      })
    } else {
      this.props.showResposeValue('error', success.message);
      this.setState({
        isDisabled: false
      })
    }
  }
  CreateBillFailure = error => {
    this.props.showResposeValue('error', error.message);
    this.setState({
      isDisabled: false
    })
  }

  onPressAddButton() {
    var { billingList } = this.state;
    if (this.state.selectedDescription.value) {
      billingList.push(
        {
          Description: this.state.selectedDescription.value,
          RatePerUnits: this.state.enterRatePerUnits,
          Units: this.state.enterUnits,
          TotalAmount: this.state.enterTotalAmount
        });
      this.setState({ billingList }, () => {
        var amount = this.state.billingList.reduce(function (sum, current) {
          return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
        }, 0);
        this.setState({
          totalAmount: amount,
          selectedDescription: {},
          searchDescription: "",
          showDescriptionBottomLayout: true,
          enterRatePerUnits: "",
          enterUnits: 1,
          enterTotalAmount: "",
          paymentTypeCardAmount: this.state.isBillModification && (this.state.firstPayment == "Card" || this.state.secondPayment == "Card") ? amount : 0,
          paymentTypeCashAmount: 0,
          selectedTemplateName:""

        }, () => {
          this.calculateDiscountAmount()
          this.calculateCashRoundOffAmount()
        })
      })
    }
    else if (this.state.selectedTemplateName) {

      for (let i = 0; i < this.state.templateServiceList.length; i++) {
        if(this.state.isOpthamalogyClinic){
          billingList.push(
            {
              Description: this.state.templateServiceList[i].name,
              RatePerUnits: this.state.templateServiceList[i].rate_per_unit,
              Units: this.state.templateServiceList[i].unit,
              TotalAmount: this.state.templateServiceList[i].total_amount
            });
        }else{
          billingList.push({
            Description: this.state.templateServiceList[i].name,
              RatePerUnits: this.state.templateServiceList[i].rate_per_unit,
              Units: 1,
              TotalAmount: 1 * (+this.state.templateServiceList[i].rate_per_unit)
          })
        }
       
      }
      this.setState({ billingList }, () => {
        var amount = this.state.billingList.reduce(function (sum, current) {
          return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
        }, 0);
        this.setState({
          totalAmount: amount,
          selectedDescription: {},
          searchDescription: "",
          showDescriptionBottomLayout: true,
          enterRatePerUnits: "",
          enterUnits: 1,
          enterTotalAmount: "",
          selectedTemplateName: "",
          paymentTypeCardAmount: 0,
          paymentTypeCashAmount: 0,
          selectedTemplateName:""
        }, () => {
          this.calculateDiscountAmount()
          this.calculateCashRoundOffAmount()
        })
      })
    }
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2c3e50',
  },
  textboxRowView: {
    flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10
  },
  buttonrowView: {
    flexDirection: 'row', justifyContent: 'center', alignContent: "center", marginVertical: 10
  },
  emed_IPbill_sideheader: {
    fontWeight: "500", fontSize: 14, marginVertical: 10
  },
  emed_content_container: {
    flexDirection: "row", marginVertical: 15
  },
  emed_radio_btn_txt: {
    fontWeight: "500", fontSize: 13
  },
  emed_radio_btn: {
    marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
  },
  emed_radio_btn_touchablearea: {
    marginRight: 20, flexDirection: "row", alignItems: "center"
  },
  emed_input_box: {
    paddingLeft: 10, marginLeft: 15, borderWidth: 1, height: 35, width: 150, marginVertical: 20, borderRadius: 4, borderColor: "#888888",
  },
  emed_billstatus_card: {
    justifyContent: 'center',
    alignSelf: 'center',
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: color.white, height: 50,
    borderRadius: 4,
    paddingHorizontal: 15,
    minWidth: 100,
    marginRight: 20
  },
  emed_billstatus_card_amttxt: {
    fontSize: 14, fontWeight: '500'
  },
  emed_billstatus_card_amttypetxt: {
    fontSize: 12, fontWeight: '400', textAlign: 'right', marginTop: 10
  },
  emed_billstatus_header: {
    fontSize: 16, fontWeight: '500', marginBottom: 15, marginTop: 20
  },
  templateContainer: {
    flexDirection: "row", flexWrap: "wrap", marginTop: 10
  },
  ipcancelButton:
  {
    // height: 30,
    paddingVertical: 6,
    paddingHorizontal: 18,
    borderRadius: 4,
    // width: 103,
    justifyContent: "center",
    alignItems: "center",
    // borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-evenly",
    borderWidth: 0,
    borderColor: color.white,
    marginRight: 20,
  },
  LabBillingButton: {
    marginLeft: '1.3vw',
    paddingHorizontal: '1.17vw',
    paddingVertical: '0.52vw',
    backgroundColor: color.themeDark,
    justifyContent: "center",
    borderRadius: ".25vw",
    color: color.white,
    fontSize: '0.85vw'
  },
  ButtonView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: '1.17vw',
    paddingVertical: '0.52vw',
    borderRadius: ".25vw",
    marginRight: '0.65vw',
  },
  ButtonText: {
    color: color.white,
    textAlign: 'center',
    fontSize: "0.85vw"
  },
});
