//import liraries
import React, { Component } from 'react';
import {
    View,
    Text,
    // Button,
    Dimensions,
    FlatList,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    TextInput,
    CheckBox,
    // TouchableWithoutFeedback
} from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import {  CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton, NavigationTopHeader } from '../BaseComponent'
import  CommonDateFilter  from '../BaseComponent';
import moment from "moment";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import Pagination from './../../doctor/common/Pagination';
import AsyncStorage from '../../../AsyncStorage';


const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const navigationHeaderListIfIPCreateBill = [
    { label: "Create Bill", value: "laboratoryCreateBill" },
    { label: "Billing Transaction", value: "laboratoryBillingTransaction" },
]
// const navigationHeaderList = [
//     { label: "Result", value: "laboratoryResult" },
//     { label: "Previous Reports ", value: "laboratoryPreviousResult" },
//     { label: "Create Bill", value: "laboratoryCreateBill" },
//     { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
//   ]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

// create a component
export class LaboratoryBillingTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {


            userType: this.props.userType,

            AllLabBillingTransactionList: [],
            billingListAfterPagination: [],
            fromDate: this.props.selectedLabPatientDetails.patient_id ?  "" : converted_date,
            toDate: this.props.selectedLabPatientDetails.patient_id ? "" : converted_date,
            patientName: "",
            testName: "",
            billNumber: "",
            billAmount: "",
            payment_type_list:[],

            totalAmount: "",
            totalCashAmount: "",
            totalCardAmount: "",
            totalUPIAmount: "",
            totalBankTransferAmount:"",

            filterDate: {

            },

            renderPagination: true,
            labBillingPatientTransection: (Object.keys(this.props.selectedLabPatientDetails).length >  0 || Object.keys(this.props.selectedIpPatientDetails).length > 0) ? true : false,
            patienID: this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.isSearchedPatient ?

                this.props.selectedLabPatientDetails.patientDetails.patient_id :

                this.props.selectedLabPatientDetails.patient_id ? this.props.selectedLabPatientDetails.patient_id :  this.props.selectedIpPatientDetails.patient_id ? this.props.selectedIpPatientDetails.patient_id : "",
            billingSummaryID: "",
            printVisible:false,
            selectedBillType:"",
            isAcNumClicked: false,
            totalInsuranceAmount: "",
            patientAccno: "",
            isAdminAccess: false

        };
    }

   async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        isAdminAccess: loggedInDataObj.is_admin_access
      })
        // alert(this.state.userType)
        this.getBillingList ()
    }
    componentWillReceiveProps(props){
        if(props.refreshAllBills) {
            this.getBillingList();
            this.props.stopRefreshAllBills()
          }
    } 

    getBillingList = () => {
        let states = this.state;
        var serviceUrl = "";
        var payment_type_value = "";
        var patient_name = "";
        var mobile_number = "";
        var account_number = "";
        for( var i = 0 ; i < states.payment_type_list.length ; i++){
            if( i == 0 )
                payment_type_value += "&payment_type="+states.payment_type_list[i];
            else if( i == 1 )
                payment_type_value += "&payment_type1="+states.payment_type_list[i];        
            else 
                payment_type_value += "&payment_type2="+states.payment_type_list[i];    
        }

        if (states.patientName) {
            if(this.state.isAcNumClicked){
                account_number = states.patientName
            }else if(!Constants.REG_EX_MOBILENO.test(states.patientName)){
              mobile_number = states.patientName
            }else{
              patient_name = states.patientName
            }
          }
    
        if (states.labBillingPatientTransection && states.patienID) {
            serviceUrl = Constants.LAB_PATIENT_BILLING_TRANSACTION_LIST +
                "?patient_id=" + states.patienID +
                "&from_date=" + states.fromDate +
                "&to_date=" + states.toDate +
                "&bill_number=" + states.billNumber +
                "&amount=" + states.billAmount +
                "&test_name=" + states.testName+
                "&bill_type=" + states.selectedBillType +
                payment_type_value;

        } else {
            serviceUrl = Constants.LAB_ALL_BILLING_TRANSACTION_LIST +
                "?from_date=" + states.fromDate +
                "&to_date=" + states.toDate +
                "&patient_name=" + patient_name +
                "&mobile_number=" + mobile_number +
                "&patient_account_number=" + account_number +
                "&bill_number=" + states.billNumber +
                "&amount=" + states.billAmount +
                "&test_name=" + states.testName+
                "&bill_type=" + states.selectedBillType +
                // "&account_number=" + states.patientAccno +
                payment_type_value;
        }






        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getBillingListSuccess,
            this.getBillingListFailure
        );
    }

    getBillingListSuccess = success => {
        if (success.status == "success") {
            this.setState({
                AllLabBillingTransactionList : []
            })
            this.setState({
                AllLabBillingTransactionList: success.data.result ? success.data.result : [],
                totalAmount: success.data.total_amount ? success.data.total_amount : 0,
                totalCashAmount: success.data.cash_amount ? success.data.cash_amount : 0,
                totalCardAmount: success.data.card_amount ? success.data.card_amount : 0,
                totalUPIAmount:success.data.upi ? success.data.upi: 0,
                totalBankTransferAmount:success.data.bank_transfer ? success.data.bank_transfer : 0,
                totalInsuranceAmount:success.data.insurance ? success.data.insurance : 0,
                printVisible:success.data.is_print_visible,

            })

        }
    }

    getBillingListFailure = error => {
        console.log("err getBillingListSuccess" + JSON.stringify(error))

    }


    showSelectedFilteredDate = (date) => {
        var states = this.state;
        states["fromDate"] = date.fromDate;
        states["toDate"] = date.toDate;
        this.setState({ states })

    }

    renderAmountValueInCard(amount, bill = "", billingType) {
        return (
            <View style={[{backgroundColor:billingType === 'Total' && amount > 0? color.themeBackgroud: color.white}, styles.AmtCardView]}>
                <Text style={{ fontSize: '0.91vw', fontWeight: '500', color:billingType === 'Total' && amount > 0?color.white:color.black }}>{amount ? "₹ " : null}{amount}</Text>
                <Text style={{ fontSize: '0.8vw', fontWeight: '400', textAlign: 'right', marginTop: '0.65vw', color:billingType === 'Total' && amount > 0?color.white:color.black }}>{billingType}</Text>

            </View>
        )
    }


    renderOverAllBillStatus() {
        var billingtypeTotal = ""
        var billingtypePaid = ""
        var billingtypeBalance = ""
        var billingTypeUPI =""
        var billingTypeBank = ""
        var billingTypeInsurance = ""
        billingtypeTotal = "Total"
        billingtypePaid = "Cash"
        billingtypeBalance = "Card"
        billingTypeUPI = "UPI"
        billingTypeBank = "Bank Transfer"
        billingTypeInsurance = "Insurance"
        return (
            <View>
                <View style={{ flexDirection: 'row',flexWrap:"wrap" }}>
                <Text style={styles.OverAllText}>{"Overall Bill Status"}</Text>
                {this.renderAmountValueInCard(this.state.totalAmount, "totalAmount", billingtypeTotal)}
                </View>
                
                <View style={{ flexDirection: 'row',flexWrap:"wrap" }}>


                    {this.renderAmountValueInCard(this.state.totalCashAmount, "totalCashAmount", billingtypePaid)}
                    {this.renderAmountValueInCard(this.state.totalCardAmount, "totalCardAmount", billingtypeBalance)}
                    {this.renderAmountValueInCard(this.state.totalUPIAmount, "totalUPIAmount", billingTypeUPI)}
                    {this.renderAmountValueInCard(this.state.totalBankTransferAmount, "totalBankTransferAmount", billingTypeBank)}
                    {this.renderAmountValueInCard(this.state.totalInsuranceAmount, "totalInsuranceAmount", billingTypeInsurance)}
                </View>
            </View>
        )
    }

    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    renderTextBox(label, key, value, placehold) {
        return (
            <View>
                <Text style={{ marginBottom: '0.52vw', fontSize:'0.8vw' }}>{label}</Text>
                <TextInput
                    onChangeText={(text) => {
                        var states = this.state;

                        if (key == "refundAmount") {
                            if (this.numberValidation(text))
                                states[key] = text;

                        }
                        else {
                            states[key] = text;

                        }
                        this.setState({
                            states
                        })
                    }}
                    placeholder = {placehold} placeholderTextColor = {color.lightGray} value={value} style={styles.RenderTxtBoxInput} />
            </View>
        )
    }

    renderPaymentIcons(image, label, key) {
        return (
            <View style={{}}>
                <TouchableOpacity onPress={() => { 
                    var fields = this.state;
                    var tempList = fields.payment_type_list;
                    if( tempList.indexOf(key) > -1 ){
                      tempList.splice(tempList.indexOf(key) , 1 ) 
                    }else{
                      if( tempList.length > 2 )
                        tempList.splice(0 , 1 )  
                      tempList.push(key)      
                    }
          
                    fields["payment_type_list"] =  tempList;   
                    this.setState({ fields }) 
                    }} style={{ alignItems: "center" }}>
                    <Image source={image} style={[styles.PayTypeImg, {  tintColor: (this.state.payment_type_list.indexOf(key) > -1) ? color.themeDark : "#888888" }]} />
                    <Text style={{ color: (this.state.payment_type_list.indexOf(key) > -1)  ? color.themeDark : "black", fontSize:'0.91vw' }}>{label}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    renderPaymentType() {
        return (
            <View style={{ zIndex: -1 }}>
                {/* <Text style={{ fontSize: 16, fontWeight: '500', marginBottom: 15, marginTop: 20 }}>{"Payment Type"}</Text> */}
                <View style={[styles.FilterHeadView,{marginTop:'1vw'}]}><Text style={styles.filterText}>Payment Type</Text></View>
                <View style={{ flexDirection: 'row',justifyContent:"space-between"}}>
                  {/* <View style={{flex:0.1}}> */}
                    {this.renderPaymentIcons(
                        require('../../../../assets/images/IPActions/Cash.png'),
                        "Cash",
                        "Cash",
                    )}
                  {/* </View>    
                  <View style={{flex:0.5}}> */}
                    {this.renderPaymentIcons(
                        require('../../../../assets/images/IPActions/Card.png'),
                        "Card",
                        "Card"
                    )}
                  {/* </View>  
                  <View style={{flex:0.3}}> */}

                    {this.renderPaymentIcons(
                        require('../../../../assets/images/IPActions/Cash&Card .png'),
                        "Cash & Card",
                        "Cash and Card"
                    )}

                    {this.renderPaymentIcons(
                        require("../../../../assets/images/IPActions/upi.png"),
                        "UPI","UPI"
                    )}

                    {this.renderPaymentIcons(
                        require("../../../../assets/images/IPActions/bank-transfer.png"),
                        "Bank Transfer","Bank Transfer"
                    )}
                    
                    {this.renderPaymentIcons(
                        require("../../../../assets/images/IPActions/Insurance.png"),
                        "Insurance","Insurance"
                    )}
                 {/* </View>    */}
                </View>
            </View>
        )
    }

    renderFooterAction() {

        var actions = [
            { label: "Search", value: "search" },
            { label: this.state.printVisible ? "Print": "", value: "print" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={styles.FootBtnView}>
                {
                    actions.map((item, index) => {
                        return (
                            <View>
                                {item.label ?

                                    <CommonButton
                                        // disable={this.state.isLocked}
                                        item={{}}
                                        selectedvalue={{}}
                                        butttonText={item.label}
                                        buttonType={item.value == "search" ? "theme" : "outlineTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={item.value} /> : null
                                }
                            </View>
                        )
                    })
                }
            </View>
        )
    }

    onPressButton(key, value) {
        if (key == "search") {
            this.getBillingList()
        }
        else if (key == "clear") {
            this.clearSearchData()  
        }
        else if (key == "print") {
            {this.state.printVisible ? 
              this.printBillingList() :null
            } 
        }
    }
    printBillingList(){
        let states = this.state;
        var payment_type_value = "";
        var patient_name = "";
        var mobile_number = "";
        var account_number = "";
        for( var i = 0 ; i < states.payment_type_list.length ; i++){
            if( i == 0 )
                payment_type_value += "&payment_type="+states.payment_type_list[i];
            else if( i == 1 )
                payment_type_value += "&payment_type1="+states.payment_type_list[i];        
            else 
                payment_type_value += "&payment_type2="+states.payment_type_list[i];    
        }

        if (states.patientName) {
            if(this.state.isAcNumClicked){
                account_number = states.patientName
            }else if(!Constants.REG_EX_MOBILENO.test(states.patientName)){
              mobile_number = states.patientName
            }else{
              patient_name = states.patientName
            }
        }

        var serviceUrl = Constants.LAB_BILLING_TRANSACTION_PRINT + "?from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate + "&patient_name=" + patient_name + "&mobile_number=" + mobile_number + "&patient_account_number=" + account_number + "&bill_number=" + this.state.billNumber + "&amount=" +this.state.billAmount +payment_type_value+ "&test_name=" +this.state.testName +"&export_type=pdf"
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    }

    getPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // console.log(file)
            window.open(fileURL);
        }
    };

    getPrintFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    }
    clearSearchData() {
        var states = this.state
        states["billAmount"] = ""
        states["billNumber"] = ""
        states["patientName"] = ""
        states["testName"] = ""
        states['payment_type_list'] = [];
        states["fromDate"] = ""
        states["toDate"] = "",
        states["selectedBillType"] = ""
        states["patientAccno"] = "",
            this.setState({
                states
            }, () => {
                this.getBillingList()
            })
    }

    showBillingList() {

        if (this.state.AllLabBillingTransactionList && this.state.AllLabBillingTransactionList.length < 11) {
            return this.state.AllLabBillingTransactionList && this.state.AllLabBillingTransactionList.map((bill, index) => {
                if (this.state.labBillingPatientTransection && this.state.patienID) {
                    return this.showPatientBills(bill, index);
                } else {
                    return this.showBills(bill, index);

                }


            })
        } else if (this.state.AllLabBillingTransactionList && this.state.AllLabBillingTransactionList.length > 0) {
            return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {

                if (this.state.labBillingPatientTransection && this.state.patienID) {
                    return this.showPatientBills(bill, index);

                } else {
                    return this.showBills(bill, index);

                }


            })

        } else {
            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
            </View>
        }
    }


    showBills(bill, index) {

        return (
            <TouchableOpacity
            disabled={bill.status != "canceled" ? false : true}
            onPress={() => {
                    this.setState({
                        billingSummaryID: bill.billing_id
                    }, () => {
                        this.getPatientBillIndudvalBillingTransection()
                    })
                }}
            >
                <View key={index} style={styles.TableDataWrapper}>

                    <View style={{ flex: 0.4, marginHorizontal: '1.3vw' }}>
                        <Text style={{ marginBottom: '0.32vw', fontSize: '0.97vw' }}>{bill.invoice_date}</Text>
                        <Text style={{ fontSize: '0.97vw' }}>{"#" + bill.invoice_number}</Text>
                    </View>
                    <View style={{flexDirection:'column', flex: 0.4}}>
                        <Text style={[Style.historyHeaderFontStyle]}>{bill.patient_name}</Text>
                        <Text style={[Style.historyHeaderFontStyle]}>{bill.patient_accNo}</Text>
                    </View>
                    <View style={{ flex: 0.2 }}>
                    {bill.status && bill.status != 'canceled' ?
                        <View style={{ flex: 0.33, justifyContent: "center" }}>
                            <Text style={{ marginBottom: '0.32vw', fontSize: '0.91vw' }}>{bill.amount}</Text>
                            <Text style={{ fontSize: '0.8vw' }}>{bill.payment_type}</Text>
                        </View>
                        : (bill.status && bill.status.toLowerCase() == 'canceled') ?
                            <View style={{ flex: 0.33, justifyContent: "center" }}>
                                <Text style={{ marginBottom: '0.32vw', fontSize: '0.91vw' }}>{bill.amount}</Text>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{"Cancelled"}</Text>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{bill.cancel_reason}</Text>
                            </View>
                            : <View style={{ flex: 0.33, justifyContent: "center" }}>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{"Unpaid"}</Text>
                            </View>}
                    </View>
                </View>
            </TouchableOpacity>

        );
    }



    showPatientBills(bill, index) {

        return (
            <TouchableOpacity
            disabled={bill.status != "canceled" ? false : true}
            onPress={() => {
                    this.setState({
                        billingSummaryID: bill.bill_summary_id
                    }, () => {
                        this.getPatientBillIndudvalBillingTransection()
                    })
                }}
            >
                <View key={index} style={styles.TableDataWrapper}>

                    <View style={{ flex: 0.4, marginHorizontal: '1.3vw' }}>
                        <Text style={{ marginBottom: '0.32vw', fontSize: '0.97vw' }}>{bill.invoice_date}</Text>
                    </View>
                    <Text style={[Style.historyHeaderFontStyle, { flex: 0.4 }]}>{bill.invoice_number}</Text>
                    <View style={{ flex: 0.2 }}>
                    {bill.status && bill.status != 'canceled' ?
                        <View style={{ flex: 0.33, justifyContent: "center" }}>
                            <Text style={{ marginBottom: '0.32vw', fontSize: '0.91vw' }}>{bill.amount}</Text>
                            <Text style={{ fontSize: '0.8vw' }}>{bill.payment_type}</Text>
                        </View>
                        : (bill.status && bill.status.toLowerCase() == 'canceled') ?
                            <View style={{ flex: 0.33, justifyContent: "center" }}>
                                <Text style={{ marginBottom: '0.32vw', fontSize: '0.91vw' }}>{bill.amount}</Text>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{"Cancelled"}</Text>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{bill.cancel_reason}</Text>
                            </View>
                            : <View style={{ flex: 0.33, justifyContent: "center" }}>
                                <Text style={{ fontSize: '0.8vw', color: '#FF0000' }}>{"Unpaid"}</Text>
                            </View>}
                    </View>
                </View>
            </TouchableOpacity>

        );
    }


    getPatientBillIndudvalBillingTransection = () => {


        var serviceUrl = Constants.LAB_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?bill_summary_id=" + this.state.billingSummaryID
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getPatientBillIndudvalBillingTransectionSuccess,
            this.getPatientBillIndudvalBillingTransectionFailure
        );
    };

    getPatientBillIndudvalBillingTransectionSuccess = response => {
        if (response.status === "success") {


            this.props.showLabBillingTransactionPopup(true, response.data, this.state.billingSummaryID)

        }
    };

    getPatientBillIndudvalBillingTransectionFailure = error => { }

    paginationChangedValues(data) {
        this.setState({
            billingListAfterPagination: data
        })
    }



    changeTabSection(data) {
        // this.props.changeScreen(data, {}, "", {}, "", "")
        this.props.changeScreen(data, {}, this.state.userType, {}, "", {}, "", {}, "", {}, false, true);

    }

    renderBillType = () =>{
        var billTypeList =[
           { label: "Corporate", value: "Corporate" },
           { label: "Credit", value: "Credit" }
        ]
        return(
            <View>
          <View style={{ flex: 1, flexDirection: "column" }}>
           <View>
             {/* <Text style={[styles.billTypeStyle]}>{"Bill Type"}</Text> */}
             <View style={[styles.FilterHeadView,{marginTop:'1vw'}]}><Text style={styles.filterText}>Bill Type</Text></View>
           </View>
           <View style={[styles.containerStyles]}>
             {
               billTypeList.map((item, index) => {
                 return (
                   <TouchableOpacity onPress={() => {
                     this.setState({
                       selectedBillType: item.value,
                     }, () => {
                       var { selectedBillType } = this.state;
                       var states = this.state;
                       if (selectedBillType == "Corporate") {
                         this.setState({
                           selectedBillType: "Corporate"
                         })
                       } else if (selectedBillType == "Credit") {
                         this.setState({
                           selectedBillType: "Credit"
                         })
                       }
                     })
                   }} style={[styles.radioButtonStyleContainer]} key={index}>
                     <View style={[{
                       backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                     }, styles.radioButtonStyle]}></View>
                     <Text style={[styles.radioButtonText]}>{item.label}</Text>
                   </TouchableOpacity>
                 )
               })
             }
           </View>
          </View> 
          </View>
         )
       }     
    render() {

        return (
            <View style={{ backgroundColor: color.applicationBackgroundColor, }}>
                <View style={{ backgroundColor: color.applicationBackgroundColor, }}>
                    {
                        this.state.labBillingPatientTransection && this.state.patienID ?
                            <NavigationTopHeader
                                changeTabSection={this.changeTabSection.bind(this)}
                                navigationHeaderList={
                                    (this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.isSearchedPatient) || (this.state.userType == "FO") ? 
                                    navigationHeaderListIfIPCreateBill : this.props.labNavigationHeaderList}
                                selectedTab={"laboratoryBillingTransaction"} />
                            : null
                    }
                </View>
                <View style={styles.MainView}>
                    <View style={styles.LeftMainView}>

                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.LeftScrollView} bounces={false} bouncesZoom={false} >
                            <View
                                style={styles.LeftWrapper}
                            >
                                <View style={styles.BillTransTxtView}>
                                    <Text style={styles.BilltransText}>
                                        Billing Transaction
                                </Text>
                                </View>
                                <View >
                                    {
                                        this.state.labBillingPatientTransection && this.state.patienID ?


                                            <CommonHistorySectionHeader
                                                heading1={"Date"}
                                                heading2={"Bill Number"}
                                                heading3={"Amount"}
                                                noOfColumn={3}
                                                columnSize={[0.4, 0.4, 0.2]}
                                            /> : <CommonHistorySectionHeader
                                                heading1={"Date & Bill Number"}
                                                heading2={"Patient Name"}
                                                // heading3={"IP Number"}
                                                heading3={"Amount"}
                                                noOfColumn={3}
                                                columnSize={[0.4, 0.4, 0.2]}
                                            />}
                                </View>

                                {this.showBillingList()}

                                {
                                    this.state.AllLabBillingTransactionList.length > 10 ?
                                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.AllLabBillingTransactionList} />
                                        : null
                                }
                            </View>
                        </ScrollView>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={false}
                        style={{ width: "35%", height: screenHeight-135 }} >
                        <View style={[Style.rightView, { padding: 20, width: "100%", height:"100%"}]}>
                            {this.renderOverAllBillStatus()}

                            {/* <Text style={{ fontSize: 16, fontWeight: '500', marginBottom: 15, marginTop: 20 }}>{"Filters"}</Text> */}
                            <View style={[styles.FilterHeadView,{marginTop:'1vw'}]}><Text style={styles.filterText}>Filter</Text></View>
                            <View style={{ flexDirection: "row", zIndex: 1 }}>
                                <View style={{ width: '13vw', justifyContent: "space-between"}}>
                                    <Text style={{ marginBottom: '0.52vw', fontSize:'0.91vw' }}>{"Date"}</Text>
                                    {this.state.labBillingPatientTransection && this.state.patienID ?
                                    
                                       <CommonDateFilter removeObject={this.state.patienID ? "" : !this.state.isAdminAccess ? Constants.remove_Obj_user : "" } startDate={this.state.startDate} endDate={this.state.toDate} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} /> :

                                       <CommonDateFilter removeObject={this.state.patienID ? "" : !this.state.isAdminAccess ? Constants.remove_Obj_user : "all" } startDate={this.state.startDate} endDate={this.state.toDate} defaultSelectedDateFilter={"Today"} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} /> 
                                    }
                                </View>
                                {/* <View style={{ width: 200, marginLeft: 20 }}>
                                    {this.renderTextBox("Patient Acc.No", "patientAccno", this.state.patientAccno)}
                                </View> */}
                            </View>
                            <View style={styles.TextBoxRow1}>
                                <View style={{ width: '13vw' }}>
                                    {this.renderTextBox("Search", "patientName", this.state.patientName,"Pt Name/Acc.no/Mob no")}
                                </View>
                                <View style={{ marginLeft: '1.3vw' }}>
                                    {this.renderTextBox("Test Name", "testName", this.state.testName, "Test Name")}
                                </View>
                            </View>
                            <View style={{marginTop:'0.32vw', flexDirection: "row", alignItems:"center"}}>
                                <CheckBox 
                                value={this.state.isAcNumClicked}
                                style={styles.CheckBoxStyle}
                                onValueChange={() => {
                                    this.setState({
                                    isAcNumClicked: !this.state.isAcNumClicked,
                                    patientName: ""
                                    })
                                }}
                                />
                                <Text style={{ fontSize:'0.8vw', color: color.grayShade, marginLeft: 5 }}>{"Acc.no search"}</Text>
                            </View>
                            <View style={styles.TextBoxRow2}>
                                <View style={{ width: '13vw' }}>
                                    {this.renderTextBox("Bill Number", "billNumber", this.state.billNumber, "Bill Number")}
                                </View>
                                <View style={{ marginLeft: '1.3vw' }}>
                                    {this.renderTextBox("Amount", "billAmount", this.state.billAmount, "Amount")}
                                </View>
                            </View>
                            {this.renderPaymentType()}
                            {this.renderBillType()}
                            {this.renderFooterAction()}




                        </View>

                    </ScrollView>
                    {/* <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 10 }}>
                        <Image source={require("../../../../assets/images/HomeScreenImages/Copyright.png")} style={{ height: 15, width: 15, color: color.lightGray, tintColor: color.lightGray }} />
                        <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
                    </View>  */}
                </View>
            </View>

        );
    }
}

// define your styles
const styles = StyleSheet.create({
    calendarView: {
        // borderColor: "#525252",
        borderColor: color.lightGray,
        borderWidth: 1,
        marginTop: 5,
        height: 35,
        borderWidth: 1,
        borderRadius: 10,
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 10,
        paddingLeft: 10,
        paddingRight: 10,
        width: "50%",
        marginRight: 10
    },
    calendarText: {
        color: color.lightGray,
        width: "90%"
    },
    headingBackGround: {
        backgroundColor: color.appointmentTableHead,
        paddingHorizontal: 25,
        paddingVertical: 10
    },
    dasboardcardcontentstyle: {
        flexDirection: "row",
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: "white",
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 5,
        borderRadius: 4,
        height: 60,
        minWidth: 120,
        paddingVertical: 7.5,
        paddingHorizontal: 10
    },
    billTypeStyle:{
        fontWeight: "500", fontSize: 14, marginVertical: 10
    },
    containerStyles :{
        flexDirection: "row", marginVertical: 15
    },
    radioButtonStyleContainer :{
        marginRight: '1.3vw', 
        flexDirection: "row", 
        alignItems: "center"
    },
    radioButtonStyle :{
        marginRight: '0.52vw', 
        height: '1vw', 
        width: '1vw', 
        borderRadius: '3.25vw', 
        borderWidth: 1, 
        borderColor: "#91939F"
    },
    radioButtonText : {
        fontWeight: "500", 
        fontSize: '0.85vw'
    },
    MainView:{
        flex: 1, 
        flexDirection: "row", 
        backgroundColor: color.applicationBackgroundColor, 
        paddingTop: '0.97vw'
    },
    LeftMainView:{
        width: "65%", 
        height: screenHeight - 135, 
        backgroundColor: color.white
    },
    LeftScrollView:{
        width: "100%", 
        height: screenHeight - 125, 
        backgroundColor: color.white, 
        zIndex: -1
    },
    LeftWrapper:{
        marginTop: platform === "web" ? 0 : '1.3vw',
        width: "100%",
        marginLeft: '1.95vw'
    },
    BillTransTxtView:{
        justifyContent: "center", 
        height: '1.95vw', 
        marginTop: '1.3vw' ,
        marginBottom:'0.65vw'
    },
    BilltransText:{
        fontSize: '1.43vw', 
        fontWeight: "500"
    },
    TableDataWrapper:{
        alignItems: 'center', 
        flexDirection: 'row', 
        paddingVertical: '0.65vw', 
        borderBottomColor: "#888888", 
        borderBottomWidth: 0.5
    },
    OverAllText:{
        alignSelf:'center', 
        fontSize: '1vw', 
        fontWeight: '500', 
        marginBottom: '0.97vw', 
        marginTop: '1.3vw', 
        marginRight:'0.65vw'
    },
    AmtCardView:{
        justifyContent: 'center',
        alignSelf: 'center',
        // alignItems: 'center',
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: '0.13vw' },
        shadowOpacity: 0.6,
        shadowRadius: '0.19vw',
        elevation: '0.32vw',
        height: '3.25vw',
        borderRadius: '0.26vw',
        paddingHorizontal: '0.97vw',
        minWidth: '6.51vw',
        marginRight: '1.3vw',
        marginTop:'0.65vw'
    },
    filterText:{
        color: "black",
        fontSize: '0.8vw',
        fontWeight:'500'
      },
      FilterHeadView: {
        backgroundColor: color.appointmentTableHead,
        paddingHorizontal: '1.62vw',
        paddingVertical:  '0.3vw',
        flexDirection: 'row', 
        justifyContent: 'flex-start',
        marginBottom: '1vw', 
        zIndex: -1, 
        borderRadius:'0.4vw',
      },
      TextBoxRow1:{
        flexDirection: "row", 
        marginTop: '1.3vw', 
        zIndex: -20 
      },
      CheckBoxStyle:{
        width:'0.85vw',
        height:'0.85vw'
      },
      RenderTxtBoxInput:{
        height: '2.28vw', 
        width: '9.76vw', 
        borderWidth: 0.1, 
        borderColor: "#888888", 
        borderRadius: '0.26vw', 
        paddingLeft: '0.65vw',
        fontSize:'0.91vw'
      },
      TextBoxRow2:{
        flexDirection: "row", 
        marginTop: '1.3vw', 
        zIndex: -1
      },
      PayTypeImg:{
        height: '1.63vw', 
        width: '1.63vw',
        marginBottom: '0.5vw',
      },
      FootBtnView:{
        flexDirection: "row", 
        justifyContent: "space-evenly", 
        marginVertical: '1.3vw'
      }
      
      


})


  

