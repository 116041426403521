import React, { Component } from "react";
import {
  View,
  Text,
  Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Switch
} from "react-native";
import Base from "../../Base";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
// import GmailInput from "react-native-gmailtype-textinput";
// import { Switch } from "react-native-gesture-handler";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

export default class CreatePatient extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderRightBtn = (name, outline) => {
    return (
      <View style={{ alignItems: "center", marginBottom: 10 }}>
        <TouchableOpacity
          onPress={() => {
            name === "Create" ? null : this.props.navigation.goBack();
          }}
        >
          <View
            style={{
              height: 45,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              flexDirection: "row",
              borderColor: outline ? color.white : color.themeBlue,
              borderWidth: 1,
              backgroundColor: outline ? color.themeBlue : color.themePink,
              padding: 5,
              // paddingBottom: 0,
              justifyContent: "space-evenly"
            }}
          >
            <Text
              style={{
                fontSize: 13,
                textAlign: "center",
                color: "#ffffff",
                fontWeight: "700"
              }}
            >
              {name}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  renderGmailTextField = title => {
    // return (
      // <GmailInput
      //   label={title}
      //   // onChangeText={text => {
      //   //   this.setState({ email_or_phone: text });
      //   // }}
      //   // hideLabel={this.state.email_or_phone !== ""}
      // />
    // );
  };

  renderHeadingWithBorder = 
  title => {
    return (
      <View
        style={{
          flexDirection: "row",
          marginTop: 30,
          marginLeft: 10,
          marginBottom: 0,
          alignItems: "center"
        }}
      >
        <Text style={{ fontSize: 13, color: "#383838" }}>{title}</Text>
        {/* <View style = {{ width: "65%", height: 1, backgroundColor: "#CCCCCC", marginLeft: 20}}/> */}
        {/* <View
              style={{
                height: 0.5,
                backgroundColor: "#CCCCCC",
                // marginTop: 5,
                // marginBottom: 5
              }}
            ></View> */}
      </View>
    );
  };

  renderHeaderView = () => {
    return (
      <View
        style={{
          marginTop: 30,
          marginBottom: 30,
          paddingLeft: 0,
          paddingRight: 30,
          height: 100,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
          <Text style={{ fontSize: 30, color: "#262941", fontWeight: "500" }}>
            Create Patient
          </Text>
        </View>
        <Image
          source={require("../../../../assets/images/sample_profile.png")}
        />
      </View>
    );
  };

  renderMandatoryFields = () => {
    return (
      <View style={{ flexDirection: "column", paddingRight: 30 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "10%" }}>
            {this.renderGmailTextField("Title")}
          </View>
          <View style={{ width: "20%" }}>
            {this.renderGmailTextField("Gender")}
          </View>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("First Name")}
          </View>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("Sur Name")}
          </View>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "20%" }}>
            {this.renderGmailTextField("DOB")}
          </View>
          <View style={{ width: "20%" }}>
            {this.renderGmailTextField("Age")}
          </View>
          <View style={{ width: "20%" }}>
            {this.renderGmailTextField("Marital Status")}
          </View>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("Occupation")}
          </View>
        </View>

        <View style={{ width: "30%" }}>
          {this.renderGmailTextField("Mobile No")}
        </View>
        <View
          style={{ flexDirection: "row", alignItems: "center", margin: 20, marginLeft: 10 }}
        >
          <Switch />
          <Text style = {{ fontSize: 14, color: "#343434", marginLeft: 10}}>Receive SMS</Text>
        </View>
      </View>
    );
  };

  renderOtherFields = () => {
    return (
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "40%", marginRight: 10 }}>
            {this.renderGmailTextField("Email-id")}
          </View>
          <View style={{ width: "50%" }}>
            {this.renderGmailTextField("Address")}
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "30%", marginRight: 10 }}>
            {this.renderGmailTextField("City")}
          </View>
          <View style={{ width: "20%" }}>
            {this.renderGmailTextField("Pincode")}
          </View>
        </View>
      </View>
    );
  };

  renderIdButton = (image, title) => {
    return (
      <TouchableOpacity>
        <View
          style={{
            width: 100,
            height: 100,
            // borderRadius: 20,
            shadowOpacity: 0.2,
            shadowRadius: 4,
            shadowOffset: {
              height: 2,
              width: 2
            },
            elevation: 4,
            // margin: 8,
            backgroundColor: "#FCFEFF",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginRight: 20
          }}
        >
          <Image
            source={image}
            style={{ width: 40, height: 40 }}
            resizeMode="contain"
          />
          <Text style = {{ fontSize: 12, color: "#000000"}}>{title}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderEachIdValue = () => {
    return (
      <View
        style={{
          height: 60,
          flexDirection: "row",
          backgroundColor: color.themeBlue,
          alignItems: "flex-end",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50
        }}
      >
        <View style={{ width: 180 }}>
          {this.renderGmailTextField("Voter Id no")}
        </View>
        <View style={{ width: 150, paddingTop: 45 }}>
          {this.renderRightBtn("Select File", false)}
        </View>
        <Text style={{ color: "#AAAAAA", fontSize: 12 }}>
          No Files Selected
        </Text>
      </View>
    );
  };

  renderIdFields = () => {
    return (
      <View style = {{ margin: 10}}>
        <Text style={{ color: "#8E8E8E", fontSize: 12 }}>
          * Either Aadhar Number or two other government id proofs are mandatory
        </Text>

        <View style={{ flexDirection: "row", marginTop: 20 }}>
          {this.renderIdButton(
            require("../../../../assets/images/aadhar_card_color.png"),
            "Aadhar Card"
          )}
          {this.renderIdButton(
            require("../../../../assets/images/driving_license_color.png"),
            "Driving License"
          )}
          {this.renderIdButton(
            require("../../../../assets/images/voter_id_color.png"),
            "Voter Id"
          )}
          {this.renderIdButton(
            require("../../../../assets/images/passport_color.png"),
            "Passport"
          )}
          {this.renderIdButton(
            require("../../../../assets/images/ration_card_color.png"),
            "Ration Card"
          )}
          {this.renderIdButton(
            require("../../../../assets/images/bank_id_color.png"),
            "Bank Id"
          )}
        </View>

        {/* <View>
          {this.renderEachIdValue()}
        </View> */}
      </View>
    );
  };

  renderCareTakerFields = () => {
    return (
      <View
        style={{ flexDirection: "column", marginLeft: 0, paddingRight: 20 }}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("First Name")}
          </View>
          <View style={{ width: "30%", marginLeft: 10 }}>
            {this.renderGmailTextField("Mobile No")}
          </View>
          <View style={{ width: "30%", marginLeft: 10 }}>
            {this.renderGmailTextField("Relationship")}
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("Email ID")}
          </View>
          <View style={{ width: "50%", marginLeft: 10 }}>
            {this.renderGmailTextField("Address")}
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "30%" }}>
            {this.renderGmailTextField("City")}
          </View>
          <View style={{ width: "20%", marginLeft: 10 }}>
            {this.renderGmailTextField("Pincode")}
          </View>
        </View>
      </View>
    );
  };

  renderProfileInfo = () => {
    return (
      <View style={{ marginTop: 30, marginBottom: 30 }}>
        <View style={{ alignItems: "center" }}>
          <View
            style={{
              width: 140,
              height: 140,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              backgroundColor: color.white,
              marginBottom: 30
            }}
          >
            <Image
              source={require("../../../../assets/images/sample_profile.png")}
              style={{ width: 140, height: 140, borderRadius: 0 }}
              resizeMode="contain"
            />
          </View>
          <Text style={{ color: color.white, fontSize: 22 }}>
            Mr. Roger George
          </Text>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              SN78347878387
            </Text>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              {" "}
              |{" "}
            </Text>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              03-10-2019
            </Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5, marginBottom: 5 }}>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              3rd Oct 1995
            </Text>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              {" "}
              |{" "}
            </Text>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              24Y 3M 4D
            </Text>
          </View>
          <Text style={{ color: color.white, fontSize: 15, fontWeight: "600" }}>
            Single
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../../../assets/images/mobile_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                +91 98765 - 465456
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 15
            }}
          >
            <Image
              source={require("../../../../assets/images/mail_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                Roger@mail.com
              </Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../../../assets/images/location_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ flexDirection: "column", paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                12, New Street, Place
              </Text>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                New City
              </Text>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                000998
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 15
            }}
          >
            <Image
              // source={require("../../../../assets/images/mail_icon.png")}
              style={{ width: 30, height: 30 }}
              // resizeMode="contain"
            />
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                Business
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderIdInfo = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop: 30,
          width: "100%"
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              backgroundColor: "#707070",
              width: 100,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15
            }}
          >
            <Image
              source={require("../../../../assets/images/camera_icon.png")}
              style={{ width: 50, height: 50 }}
              resizeMode="contain"
            />
          </View>
          <View
            style={{
              // width: 100,
              alignItems: "center"
            }}
          >
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                Voted Id
              </Text>
            </View>
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                GH9876543
              </Text>
            </View>
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                voterID.jpeg
              </Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              backgroundColor: "#707070",
              width: 100,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15
            }}
          >
            <Image
              source={require("../../../../assets/images/camera_icon.png")}
              style={{ width: 50, height: 50 }}
              resizeMode="contain"
            />
          </View>
          <View
            style={{
              // width: 100,
              alignItems: "center"
            }}
          >
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                Voted Id
              </Text>
            </View>
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                GH9876543
              </Text>
            </View>
            <View style={{ marginTop: 5 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                voterID.jpeg
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };
  
  renderCareTakerInfo = () => {
    return (
      <View style={{ marginTop: 30 }}>
        <View style={{ alignItems: "center" }}>
          <Text style={{ color: color.white, fontSize: 25 }}>Mr. Roger</Text>

          <View style={{ marginTop: 10 }}>
            <Text
              style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
            >
              Father of Mr. Roger George
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../../../assets/images/mobile_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                +91 98765 - 465456
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 15
            }}
          >
            <Image
              source={require("../../../../assets/images/mail_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                Roger@mail.com
              </Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              source={require("../../../../assets/images/location_icon.png")}
              style={{ width: 30, height: 30 }}
              resizeMode="contain"
            />
            <View style={{ flexDirection: "column", paddingLeft: 10 }}>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                12, New Street, Place
              </Text>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                New City
              </Text>
              <Text
                style={{ color: color.white, fontSize: 15, fontWeight: "600" }}
              >
                000998
              </Text>
            </View>
          </View>
        </View>
        <View></View>
      </View>
    );
  };

  render() {
    return (
      <View style={Style.rootView}>
        {/* first view */}
        <View style={Style.leftView}>
          <View style={Style.leftBarView}></View>
          <ScrollView 
          showsVerticalScrollIndicator={false}
          style = {{ marginTop: 40, marginBottom: 40}}
          >
            <View
              style={{ flexDirection: "column", margin: 10, marginLeft: 20 }}
            >
              {this.renderHeaderView()}
              {this.renderHeadingWithBorder("MANDATORY INFORMATION")}
              {this.renderMandatoryFields()}
              {this.renderHeadingWithBorder("OTHER INFORMATION")}
          {this.renderOtherFields()}
              {this.renderHeadingWithBorder("IDENTITY PROOFS")}
              {this.renderIdFields()}
              {this.renderHeadingWithBorder("CARE TAKER INFORMATION")}
          {this.renderCareTakerFields()}
            </View>
          </ScrollView>
        </View>
        {/* second view */}
        <View style={[Style.rightView, { padding: 20 }]}>
          <View style={{}}>
            <Text style={{ fontSize: 13, color: "#ffffff" }}>
              7th February 2020, Friday, 17:02:30
            </Text>
          </View>

          <ScrollView showsVerticalScrollIndicator={false}>
            {this.renderProfileInfo()}
            {this.renderIdInfo()}
            {this.renderCareTakerInfo()}
          </ScrollView>

          <View style={{ position: "absolute", bottom: 0 }}>
            {this.renderRightBtn("Create", false)}
            {this.renderRightBtn("Cancel", true)}
          </View>
        </View>
      </View>
    );
  }
}

