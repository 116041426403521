import { DatePicker } from "antd";
import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput } from "react-native";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { color } from "../../../../../../styles/Color";
import { Constants } from "../../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader } from "../../../../BaseComponent";
import moment from "moment";

const dateFormat = 'YYYY-MM-DD';

export default class IolDirectPO extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            brand_name: '',
            model_name: '',
            iol_product_id: null,
            iol_type: '',
            iol_power: '',
            batch_number: '',
            expiry_date: '',
            product_code: '',
            hsn_sac_code: '',
            bar_code: '',
            quantity: '',
            buying_price_per_qty: '',
            discount_percentage: '',
            discount_amount: '',
            gst_percentage: '',
            cgst_percentage: 0,
            sgst_percentage: 0,
            cgst_amount: 0,
            sgst_amount: 0,
            total_buying_price: 0,
            mrp_price_per_qty: '',
            total_mrp: 0,
            gst_amount: 0,
            expiry_alert_period: '',
            reorder_threshold: '',
            physical_box_number: '',
            instructions: '',
            brandSuggestionData: [],
            modelSuggestionData: [],
            StockDetailsList: [],
            vendorList: [],
            vendorOriginalList: [],
            isVendorSuggestion: false,
            vendor_name: '',
            vendor_id: null,
            po_number: this.props.poNumber ? this.props.poNumber : null,
            delivery_date: '',
            bill_no: '',
            bill_date: '',
            total_cgst_amount: '',
            total_sgst_amount: '',
            total_bill_amount: '',
            editedStockIndex: '',
            showPONumber: this.props.poNumber? true : false,
            isPoStock: false,
            isDirectPOStock: false,
            showExpriryDropdown: false,
            expiryAlertData:[
                {label: "1 month", value: 1},
                {label: "2 months", value: 2},
                {label: "3 months", value: 3},
                {label: "6 months", value: 6},
            ]
        }
    }

    componentDidMount(){
        this.getVendorDetails()
        if(this.props.poNumber){
            this.getPoDetailList(this.props.poNumber)
        }
    }

    getVendorDetails = () => {
        var url = Constants.ADD_VENDOR
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getVendorDetailsSuccess,
            this.getVendorDetailsFailure
          );
    }

    getVendorDetailsSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
              vendorList: response.data,
              vendorOriginalList: response.data,
            })
        }
    }

    vendorSuggestionList = () => {
        let {vendorList, vendor_name} = this.state
        let tempDescList = this.state.vendorOriginalList
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(vendor_name.toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendorList = searchListTemp;
          } else {
            vendorList = this.state.vendorOriginalList;
          }
          this.setState({
            vendorList
          })
    }

    getPoDetailList = (poNumber) => {
        var url = Constants.OT_INVENTORY_IOL_DIRECT_PO + "?po_number=" + poNumber
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getPoDetailListSuccess,
            this.getPoDetailListFailure
        )
    }

    getPoDetailListSuccess = (response) => {
        if(response.status === "success"){
            var data = response.data
            var stockList = data.stock_line_items
            var preparedCompletedData = []

            var poDetails = data.po_line_items
            var preparedPOData = []
            
            if(stockList.length > 0){
                
                for(let i = 0; i < stockList.length; i++){
                    var TotalMrp = parseInt(stockList[i].mrp_price_per_qty) * parseInt(stockList[i].quantity)
                    var halfGSTPer = stockList[i].gst_percentage ? parseInt(stockList[i].gst_percentage) / 2 : 0
                    var halfGSTAmt = stockList[i].gst_amount ? parseInt(stockList[i].gst_amount) / 2 : 0
                    var stockLineData = {
                        brand_name: stockList[i].iol_product__brand_name,
                        model_name: stockList[i].iol_product__model_name,
                        iol_product_id: stockList[i].iol_product, 
                        iol_type: stockList[i].iol_product__iol_type__iol_type,
                        iol_power: stockList[i].iol_product__iol_power,
                        batch_number: stockList[i].batch_number ? stockList[i].batch_number : '',
                        expiry_date: stockList[i].expiry_date ? moment(stockList[i].expiry_date).format("YYYY-MM-DD") : '',
                        product_code: stockList[i].product_code ? stockList[i].product_code : '',
                        hsn_sac_code: stockList[i].hsn_sac_code ? stockList[i].hsn_sac_code : '',
                        bar_code: stockList[i].bar_code ? stockList[i].bar_code : '',
                        quantity: parseInt(stockList[i].quantity),
                        buying_price_per_qty: stockList[i].buying_price_per_qty ? parseInt(stockList[i].buying_price_per_qty) : '',
                        discount_percentage: stockList[i].discount_percentage ? parseInt(stockList[i].discount_percentage) : 0,
                        discount_amount: stockList[i].discount_amount ? parseInt(stockList[i].discount_amount) : 0,
                        gst_percentage: stockList[i].gst_percentage ? parseInt(stockList[i].gst_percentage) : 0,
                        cgst_percentage: halfGSTPer,
                        sgst_percentage: halfGSTPer,
                        cgst_amount: halfGSTAmt,
                        sgst_amount: halfGSTAmt,
                        total_buying_price: stockList[i].total_buying_price ? parseInt(stockList[i].total_buying_price) : 0,
                        mrp_price_per_qty: stockList[i].mrp_price_per_qty ? stockList[i].mrp_price_per_qty : '' ,
                        total_mrp: TotalMrp ? TotalMrp : 0,
                        gst_amount: stockList[i].gst_amount ? parseInt(stockList[i].gst_amount) : 0,
                        expiry_alert_period: stockList[i].iol_stock_moster__expiry_alert_period ? parseInt(stockList[i].iol_stock_moster__expiry_alert_period) : '',
                        reorder_threshold: stockList[i].iol_stock_moster__reorder_threshold ? parseInt(stockList[i].iol_stock_moster__reorder_threshold) : '',
                        physical_box_number: stockList[i].iol_stock_moster__physical_box_number ? parseInt(stockList[i].iol_stock_moster__physical_box_number) : '',
                        instructions: stockList[i].instructions ? stockList[i].instructions : '',
                    } 

                    preparedCompletedData.push(stockLineData)
                }
            }

            if(poDetails.length > 0){
            
                for(let i = 0; i < poDetails.length; i++){
                    var poLineData = {
                        brand_name: poDetails[i].iol_product__brand_name,
                        model_name: poDetails[i].iol_product__model_name,
                        iol_product_id: poDetails[i].iol_product, 
                        iol_type: poDetails[i].iol_product__iol_type__iol_type,
                        iol_power: poDetails[i].iol_product__iol_power,
                        quantity: parseInt(poDetails[i].quantity),
                        batch_number: '',
                        expiry_date: '',
                        product_code: '',
                        hsn_sac_code: '',
                        bar_code: '',
                        buying_price_per_qty: '',
                        discount_percentage: '',
                        discount_amount: '',
                        gst_percentage: '',
                        cgst_percentage: 0,
                        sgst_percentage: 0,
                        cgst_amount: 0,
                        sgst_amount: 0,
                        total_buying_price: 0,
                        mrp_price_per_qty: '',
                        total_mrp: 0,
                        gst_amount: 0,
                        expiry_alert_period: '',
                        reorder_threshold: '',
                        physical_box_number: '',
                        instructions: '', 
                    }
                    preparedPOData.push(poLineData)
                }
            }

            this.setState({
                vendor_name: data.vendor,
                vendor_id: data.vendor_id,
                delivery_date: data.delivery_date ? moment(data.delivery_date).format("YYYY-MM-DD") : '',
                bill_date: data.bill_date ?  moment(data.bill_date).format("YYYY-MM-DD") : '',
                bill_no: data.bill_no ? data.bill_no : '',
                total_cgst_amount: data.total_cgst_amount,
                total_sgst_amount: data.total_sgst_amount,
                total_bill_amount: data.total_bill_amount,
                StockDetailsList: preparedCompletedData.length > 0 ? preparedCompletedData : preparedPOData.length > 0 ? preparedPOData : [],
                isDirectPOStock: preparedCompletedData.length > 0 ? true : false,
                isPoStock: preparedPOData.length > 0 ? true : false,
            })
        }

    }

    brandSuggestionList = (value) => {
        var url = Constants.OT_INVENTORY_IOL_PO_IOL_SUGGESTION + "?brand_name=" + value
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getBrandSuggestionsSuccess,
            this.getBrandSuggestionsFailure
        );
    }

    getBrandSuggestionsSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
              brandSuggestionData: response.data,
            })
        }
    }

    getIolModelSuggestionSuccess = (response) => {
        if (response.status == "success") {
            var States = this.state
            var data = response.data
            if(data.length === 1){
                States["iol_product_id"] = data[0].id
                States["brand_name"] = data[0].brand_name
                States["model_name"] = data[0].model_name
                States["iol_type"] = data[0].iol_type
                States["iol_power"] = data[0].iol_power

                this.setState({
                    States,
                    modelSuggestionData: []
                })
            }else if (data.length > 1){
                this.setState({
                    modelSuggestionData: data,
                })
            }
            
        }
    }

    getIolModelSuggestion = (value) => {
        var url = Constants.OT_INVENTORY_IOL_PO_IOL_MODEL_SUGGESTION + "?brand_name=" + value
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIolModelSuggestionSuccess,
            this.getIolModelSuggestionFailure
        );
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    renderTexBox = (label, width, key) => {
        var states = this.state
        return(
            <View style={{width: width, zIndex: (key === "brand_name" || key === "expiry_alert_period") ? 2 :  (key === "model_name" || key === "vendor_name") ? 1 : -1}}>
            {(key != "expiry_date" && key != "delivery_date" &&  key != "bill_date") ?
            <View style={{width: "100%", marginTop: 10,}}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', fontWeight: "bold", position: "absolute", marginLeft: 10, backgroundColor: color.lightGreenBG, paddingLeft: 5, paddingRight: 5, color: color.grayShade }}>{label}</Text>
                <TextInput 
                value={states[key]}
                style={{
                    borderColor: color.lightGray,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "100%",
                    height: '3vw',
                    padding: '1vw',
                    marginTop: 8,
                    fontSize: '0.9vw'
                }}
                onFocus={() => {
                    if( key === "expiry_alert_period" ){
                        this.setState({
                            showExpriryDropdown: true
                        })
                    }
                }}
                onBlur={() => {
                    this.setState({
                        showExpriryDropdown: false
                    })
                }}
                editable={(key === "model_name" || key === "iol_type" || key === "iol_power" || key === "discount_amount" || key === "po_number" || key === "total_cgst_amount" || key === "total_sgst_amount" || key === "total_bill_amount" || key === "expiry_alert_period" || states.isDirectPOStock) ? false : true}
                onChangeText = {(text) => {
                    if(key === "quantity" || key === "buying_price_per_qty" || key === "discount_percentage" || key === "discount_amount" || key === "gst_percentage" || key === "mrp_price_per_qty" || key === "reorder_threshold" || key === "physical_box_number"){
                        var isNum = this.numberValidation(text)
                        if (isNum || text === "") {
                            states[key] = text
                        }

                        if(states['mrp_price_per_qty'] && states['quantity']){
                            var mrpPerQty = parseInt(states['mrp_price_per_qty']) * parseInt(states['quantity'])
                            states['total_mrp'] = mrpPerQty
                        }else{
                            states['total_mrp'] = 0
                        }

                        if (states["quantity"] && states["buying_price_per_qty"] && states['discount_percentage']){
                            var totalBuyingPrice = parseInt(states["quantity"]) * parseInt(states["buying_price_per_qty"])
                            var disAmt = (totalBuyingPrice * parseInt(states['discount_percentage'])) / 100
                            states['discount_amount'] = states["discount_percentage"] ? disAmt : ''
                            var totalBuyingPriceDis = parseInt(totalBuyingPrice) - parseInt(disAmt) 
                            var gstAmt = 0
                            var halfGstPer = 0
                            var halfGstAmt = 0
                            if(states['gst_percentage']){
                                gstAmt = (totalBuyingPrice * parseInt(states['gst_percentage'])) / 100
                                halfGstAmt = gstAmt / 2
                                halfGstPer = parseInt(states['gst_percentage']) / 2
                            }
                            states['cgst_amount'] = halfGstAmt
                            states['cgst_percentage'] = halfGstPer
                            states['sgst_amount'] = halfGstAmt
                            states['sgst_percentage'] = halfGstPer
                            states['gst_amount'] = gstAmt
                            states['total_buying_price'] = (totalBuyingPriceDis ? totalBuyingPriceDis : totalBuyingPrice) + gstAmt
                        }else if(states["quantity"] && states["buying_price_per_qty"] && !states['discount_percentage']){
                            var totalBuyingPrice = parseInt(states["quantity"]) * parseInt(states["buying_price_per_qty"])
                            var gstAmt = 0
                            var halfGstPer = 0
                            var halfGstAmt = 0
                            if(states['gst_percentage']){
                                gstAmt = (totalBuyingPrice * parseInt(states['gst_percentage'])) / 100
                                halfGstAmt = gstAmt / 2
                                halfGstPer = parseInt(states['gst_percentage']) / 2
                            }
                            states['cgst_amount'] = halfGstAmt
                            states['cgst_percentage'] = halfGstPer
                            states['sgst_amount'] = halfGstAmt
                            states['sgst_percentage'] = halfGstPer
                            states['gst_amount'] = gstAmt
                            states['total_buying_price'] = totalBuyingPrice + gstAmt
                            states['discount_amount'] = ''
                        }else{
                            states['total_buying_price'] = 0
                            states['discount_amount'] = ''
                            states['gst_amount'] = 0
                            states['cgst_amount'] = 0
                            states['cgst_percentage'] = 0
                            states['sgst_amount'] = 0
                            states['sgst_percentage'] = 0
                        }
                        }
                    else{
                        states[key] = text
                    }
                    if(key === "vendor_name"){
                        if((text).trim() != ""){
                            states["isVendorSuggestion"] = true
                        }else{
                            states["isVendorSuggestion"] = false
                            states["vendor_id"] = null
                        }
                    }
                    this.setState({
                        states
                    }, () => {
                        if(key === "vendor_name" && (text).trim() != ""){
                            this.vendorSuggestionList()
                        }
                        if(key === "brand_name"){
                            this.brandSuggestionList(text)
                            this.setState({
                                model_name: '',
                                iol_power: '',
                                iol_type: '',
                                iol_product_id: null,
                            })
                        }else{
                            this.setState({
                                brandSuggestionData: []
                            })
                        }
                    })
                }}
                />
            </View>
            :
            <View style={{width: "100%", marginTop: 10,}}>
            <Text style={{ zIndex: 2, fontSize: '0.8vw', fontWeight: "bold", position: "absolute", marginLeft: 10, backgroundColor: color.lightGreenBG, paddingLeft: 5, paddingRight: 5, color: color.grayShade }}>{label}</Text>
            <DatePicker 
            clearIcon={false}
            disabledDate={
                key == "bill_date"  ?  current => {return current && current < moment().add(-6, "days")} :
                key == "delivery_date" ? current => { return current && current < moment(states['bill_date'])}:
                null   
            }
            disabled={states.isDirectPOStock ? true : key == "delivery_date" ? states['bill_date']==""? true : false : false}
            onChange={date => {
            
                let exp_dt = "";
                if (date) 
                {
                  var fulldate = new Date(date);
                  // for expiry date, last date of month
                  exp_dt = new Date(fulldate.getFullYear(), fulldate.getMonth()+1, 0)
                }
                states[key] = key === "expiry_date" ? exp_dt : date
                this.setState({
                    states
                }, ()=>{
                    if(key == "bill_date" && states["delivery_date"] != ""){
                        if((moment(states["delivery_date"]).format(dateFormat)) < (moment(states["bill_date"]).format(dateFormat))){
                            this.setState({
                                delivery_date : ""
                            })
                        }
                    }
                }) 
                
            }}
            value={
                states[key] ? moment(states[key], dateFormat) : ""
              }
            style={{
                width: '100%',
                borderColor: color.lightGray,
                borderRadius: 4,
                borderWidth: 1,
                height: '3vw',
                padding: '1vw',
                marginTop: 8,           
            }} 
            defaultValue={""}
            placeholder={key === "expiry_date" ? 'MM-YYYY' : ''}
            format={key === "expiry_date" ? 'MM-YYYY' : "YYYY-MM-DD"}
            picker={key === "expiry_date" ? "month" : "date"}
            />
            <Text style={{fontSize:'0.7vw', color:color.red}}>
                { key == "delivery_date" ? states['bill_date']==""? "Please Select a Bill Date" : " ":null}
                </Text>
            </View>
            }
            {this.state.showExpriryDropdown &&  key === "expiry_alert_period" ? 
            <View 
            style={{ zIndex: 3, position: 'absolute', marginTop: '7vh', width: "80%"}}
            >
                <ScrollView
                style={{
                    maxHeight: '10vh', marginBottom: 10, flex: 1,
                    backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                {states.expiryAlertData.map((item, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={
                        () => {
                            this.setState({
                                expiry_alert_period: item.value,
                        })
                        }}
                    >
                        <Text style={{ color: color.white,}}>{item.label}</Text>

                    </TouchableOpacity>
                    </View>
                )}
                </ScrollView>
            </View>
            : null
            }
            {states.brandSuggestionData && states.brandSuggestionData.length > 0 &&  key === "brand_name"?
            <View style={{ zIndex: 3, position: 'absolute', marginTop: '7vh', width: "80%"}}>
                <ScrollView
                style={{
                    maxHeight: '25vh', marginBottom: 10, flex: 1,
                    backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                   {states.brandSuggestionData.map((value, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={
                        () => {
                            this.setState({
                                brand_name: value,
                                brandSuggestionData: [],
                            }, () => {
                                this.getIolModelSuggestion(value)
                            })
                        }
                        }
                    >
                        <Text style={{ color: color.white,}}>{value}</Text>

                    </TouchableOpacity>
                    </View>
                )} 
                </ScrollView>
            </View>
            : null
            }
            {states.modelSuggestionData && states.modelSuggestionData.length > 0 &&  key === "model_name"?
            <View style={{ zIndex: 3, position: 'absolute', marginTop: '7vh', width: "80%"}}>
                <ScrollView
                style={{
                    maxHeight: '25vh', marginBottom: 10, flex: 1,
                    backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                   {states.modelSuggestionData.map((item, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={
                        () => {
                            this.setState({
                                iol_product_id: item.id,
                                brand_name: item.brand_name,
                                model_name: item.model_name,
                                iol_type: item.iol_type,
                                iol_power: item.iol_power,
                                modelSuggestionData: [],
                            })
                        }
                        }
                    >
                        <Text style={{ color: color.white,}}>{item.display_name}</Text>

                    </TouchableOpacity>
                    </View>
                )} 
                </ScrollView>
            </View>
            : null
            }
            { states.isVendorSuggestion && states.vendorList && states.vendorList.length > 0 &&  key === "vendor_name"?
            <View style={{ zIndex: 1, position: 'absolute', marginTop: '7vh', width: "90%"}}>
                <ScrollView
                style={{
                    maxHeight: '25vh', marginBottom: 10, flex: 1,
                    backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                   {this.state.vendorList.map((value, index) =>
                    <View style={{ height: '4vh' }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={
                        () => {
                            this.setState({
                                vendor_name: value.name,
                                vendor_id: value.vendor_id,
                                isVendorSuggestion: false
                            })
                        }
                        }
                    >
                        <Text style={{fontSize: '0.9vw'}}>{value.name}</Text>

                    </TouchableOpacity>
                    </View>
                )} 
                </ScrollView>
            </View>
            : null
            }
            </View>
        )
    }

    renderLabelBox = (label, value) => {
        return(
            <View style={{height: "10vh", backgroundColor: color.white, shadowColor:color.grayShade, shadowOpacity: 0.5, shadowOffset: {width: 1, height: -1}, shadowRadius: 4, marginTop: 10, width: '45%', paddingHorizontal: 20, paddingVertical: 10, alignItems: 'flex-end'}}>
                <Text style={{fontSize: "2vw", color: color.grayShade, fontWeight: 'bold'}}>{value}</Text>
                <Text style={{fontSize: "0.7vw", color: color.grayShade}}>{label}</Text>
            </View>
        )
    }

    clearLeftData = () => {
        this.setState({
            brand_name: '',
            model_name: '',
            iol_product_id: null,
            iol_type: '',
            iol_power: '',
            batch_number: '',
            expiry_date: '',
            product_code: '',
            hsn_sac_code: '',
            bar_code: '',
            quantity: '',
            buying_price_per_qty: '',
            discount_percentage: '',
            discount_amount: '',
            gst_percentage: '',
            cgst_percentage: 0,
            sgst_percentage: 0,
            cgst_amount: 0,
            sgst_amount: 0,
            total_buying_price: 0,
            mrp_price_per_qty: '',
            total_mrp: 0,
            gst_amount: 0,
            expiry_alert_period: 0,
            reorder_threshold: 0,
            physical_box_number: '',
            instructions: '',
            brandSuggestionData: [],
            modelSuggestionData: [],
            editedStockIndex: '',
        })
    }

    saveStockRightToLeft = () => {
        var states = this.state
        if(states.iol_product_id && states.batch_number && states.expiry_date && states.quantity && states.buying_price_per_qty && states.total_mrp){
            var data = {
            brand_name: states.brand_name,
            model_name: states.model_name,
            iol_product_id: states.iol_product_id,
            iol_type: states.iol_type,
            iol_power: states.iol_power,
            batch_number: states.batch_number,
            expiry_date: moment(states.expiry_date).format("YYYY-MM-DD"),
            product_code: states.product_code,
            hsn_sac_code: states.hsn_sac_code,
            bar_code: states.bar_code,
            quantity: parseInt(states.quantity),
            buying_price_per_qty: parseInt(states.buying_price_per_qty),
            discount_percentage: states.discount_percentage ? parseInt(states.discount_percentage) : 0,
            discount_amount: states.discount_amount ? parseInt(states.discount_amount) : 0,
            gst_percentage: states.gst_percentage ? parseInt(states.gst_percentage) : 0,
            cgst_percentage: parseInt(states.cgst_percentage),
            sgst_percentage: parseInt(states.sgst_percentage),
            cgst_amount: parseInt(states.cgst_amount),
            sgst_amount: parseInt(states.sgst_amount),
            total_buying_price: parseInt(states.total_buying_price),
            mrp_price_per_qty: states.mrp_price_per_qty ? parseInt(states.mrp_price_per_qty) : 0,
            total_mrp: states.total_mrp ? parseInt(states.total_mrp) : 0,
            gst_amount: parseInt(states.gst_amount),
            expiry_alert_period: parseInt(states.expiry_alert_period),
            reorder_threshold: states.reorder_threshold ? parseInt(states.reorder_threshold) : 0,
            physical_box_number:  parseInt(states.physical_box_number),
            instructions: states.instructions,
            }
            if(states.editedStockIndex || (states.editedStockIndex).toString() === '0'){
                states['StockDetailsList'][states.editedStockIndex] = data
            }else{
                states['StockDetailsList'].push(data)
            }
            
            this.setState({
                states
            }, () => {
                this.clearLeftData()
                if(states.StockDetailsList.length > 0){
                    var stockData = states.StockDetailsList
                    var cgstTotal = 0
                    var sgstTotal = 0
                    var totalBuyingAmt = 0
                    for(let i = 0; i < stockData.length; i++ ){
                        cgstTotal += stockData[i].cgst_amount ? parseInt(stockData[i].cgst_amount) : 0
                        sgstTotal += stockData[i].sgst_amount ? parseInt(stockData[i].sgst_amount) : 0
                        totalBuyingAmt += stockData[i].total_buying_price ? parseInt(stockData[i].total_buying_price) : 0
                    }

                    this.setState({
                        total_cgst_amount: cgstTotal,
                        total_sgst_amount: sgstTotal,
                        total_bill_amount: totalBuyingAmt
                    })
                }
            })
        }else {
            this.props.showResposeValue("error", "Mandatory fields can't be empty")
        }
    }

    renderHeaderDataView = (data = [], flex = [], type = "" ) => {
        var viewData = []
        if(data.length > 0 && flex.length > 0 && data.length === flex.length ){
            for(let i = 0; i < data.length; i++){
                viewData.push({key: data[i], flex: flex[i]})
            }
        }
        return(
            <View style={{ backgroundColor: type === "header" ? color.white : color.sectionHeadingLiteColor, height: type === "header" ? '3vh' : '5vh', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5 }}>
               {viewData && viewData.map((item, index) => (
                <Text style={{flex: item.flex, fontSize: '0.8vw', fontWeight: type === "data" ? '600' : '300' }}>{(item.key) ? item.key : "-"}</Text>
               ))} 
            </View>
        )
    }

    postStockDetails = () => {
        var url = Constants.OT_INVENTORY_IOL_DIRECT_PO
        var states = this.state
        if(states.vendor_id && states.delivery_date && states.bill_no && states.bill_date && states.total_bill_amount && (states.StockDetailsList).length != 0){
            var data = {
                "vendor_id":states.vendor_id,
                "delivery_date": moment(states.delivery_date).format("YYYY-MM-DD"),
                "bill_no": states.bill_no,
                "bill_date":  moment(states.bill_date).format("YYYY-MM-DD"),
                "total_bill_amount": parseInt(states.total_bill_amount),
                "total_cgst_amount": parseInt(states.total_cgst_amount),
                "total_sgst_amount": parseInt(states.total_sgst_amount),
                "line_items": states.StockDetailsList
            }

            if(states.po_number){
                data["purchase_order_number"] = states.po_number
            }
        


            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.postStockDetailsSuccess,
                this.postStockDetailsFailure
            );
        }else{
            this.props.showResposeValue("error", "Mandatory fields can't be empty") 
        }
    }

    postStockDetailsSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.setState({
                StockDetailsList: []
            }, () => {
                this.props.changePage("iolStockList")
            })
        }else{
            this.props.showResposeValue("error", response.message)
        }
    }

    editStockDetail = (item) => {
        if(item.iol_product_id && item.iol_product_id != this.state.iol_product_id){
            this.setState({
                brand_name: item.brand_name,
                model_name: item.model_name,
                iol_product_id: item.iol_product_id,
                iol_type: item.iol_type,
                iol_power: item.iol_power,
                batch_number: item.batch_number,
                expiry_date: item.expiry_date ? moment(item.expiry_date).format("YYYY-MM-DD") : '',
                product_code: item.product_code,
                hsn_sac_code: item.hsn_sac_code,
                bar_code: item.bar_code,
                quantity: item.quantity,
                buying_price_per_qty: item.buying_price_per_qty,
                discount_percentage: (item.discount_percentage) ? item.discount_percentage : '',
                discount_amount: (item.discount_amount) ? item.discount_amount : '',
                gst_percentage: (item.gst_percentage) ? item.gst_percentage : '',
                cgst_percentage: item.cgst_percentage,
                sgst_percentage: item.sgst_percentage,
                cgst_amount: item.cgst_amount,
                sgst_amount: item.sgst_amount,
                total_buying_price: item.total_buying_price,
                mrp_price_per_qty: item.mrp_price_per_qty,
                total_mrp: item.total_mrp,
                gst_amount: item.gst_amount,
                expiry_alert_period: item.expiry_alert_period,
                reorder_threshold: item.reorder_threshold,
                physical_box_number: (item.physical_box_number) ? item.physical_box_number : '',
                instructions: item.instructions, 
            })
        }
    }
    

    render(){
        var states = this.state
        return(
            <View style={{width: "100%", flexDirection: "row", height: "100%"}}>
                <View style={{width: "65%", height: "100%", paddingHorizontal: 20, paddingVertical: 10}}>
                <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 20}}>
                <View style={{flexDirection: "row"}}>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("iolStockList")
                }}
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>IOL Stock List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>Add to Stock</Text>
                </TouchableOpacity>
                </View>
                <View style={{flexDirection: 'row'}}>
                <View>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Save"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.postStockDetails()
                }}
                buttonKey={"SaveDirectPO"} />
                </View>
                <View style={{marginLeft: '2vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Print"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    // this.props.changePage("addIolDirectPO")
                }}
                buttonKey={"PrintDirectPO"} />
                </View>
                </View>
                </View>
                <View  style={{flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap', marginBottom: 10}}>
                {this.renderTexBox("Vendor Name", '35%', "vendor_name")}
                {states.showPONumber ? this.renderTexBox("PO Number", '25%', "po_number") : null}
                {this.renderTexBox("Delivery Date", '25%', "delivery_date")}
                </View>
                <Text style={{fontWeight: '500', fontSize: '1.2vw'}}>{"Payment Details"}</Text>
                <View  style={{flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap', marginBottom: 10, zIndex: -1}}>
                {this.renderTexBox("Bill No", '18%', "bill_no")}
                {this.renderTexBox("Bill Date", '18%', "bill_date")}
                {this.renderTexBox("CGST", '15%', "total_cgst_amount")}
                {this.renderTexBox("SGST", '15%', "total_sgst_amount")}
                {this.renderTexBox("Amount", '20%', "total_bill_amount")}
                </View>
                {states.StockDetailsList && states.StockDetailsList.length > 0 ?
                <View style={{maxHeight: '60%', zIndex: -1}}>
                    <ScrollView
                    style={{
                        maxHeight: '100%', backgroundColor: color.white
                    }}
                    showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    >
                    {states.StockDetailsList.map((item,  index) => (
                        <TouchableOpacity
                        onPress={() => {
                            if(!states.isDirectPOStock){
                                this.setState({
                                    editedStockIndex: index
                                }, () => {
                                this.editStockDetail(item)
                                })
                            }
                        }}
                        >
                        <View style={{backgroundColor: color.sectionHeadingLiteColor, padding: 10, marginVertical: 20, borderRadius: 4, borderWidth: 1, borderColor: color.lightGray}}>
                            {this.renderHeaderDataView(
                            ["Brand Name", "Model Name", "IOL Type", "IOL Power", "Batch No"], 
                            [0.25, 0.26, 0.18, 0.15, 0.16], 
                            "header"
                            )}
                            {this.renderHeaderDataView(
                            [item.brand_name, item.model_name, item.iol_type, item.iol_power, item.batch_number], 
                            [0.25, 0.26, 0.18, 0.15, 0.16], 
                            "data"
                            )}
                            {this.renderHeaderDataView(
                            ["Expiry Date", "HSN/SAC", "Product Code", "Bar Code", "Quantity", "Buying Price/Qty"], 
                            [0.15, 0.18, 0.18, 0.18, 0.15, 0.16], 
                            "header"
                            )}
                            {this.renderHeaderDataView(
                            [item.expiry_date ? moment(item.expiry_date).format("MM-YYYY") : '', item.hsn_sac_code, item.product_code, item.bar_code, item.quantity, item.buying_price_per_qty], 
                            [0.15, 0.18, 0.18, 0.18, 0.15, 0.16], 
                            "data"
                            )}
                            {this.renderHeaderDataView(
                            ["Discount %", "Discount Amt", "Total Buying Price", "MRP/Qty", "CGST %", "SGST %"], 
                            [0.15, 0.18, 0.18, 0.18, 0.15, 0.16], 
                            "header"
                            )}
                            {this.renderHeaderDataView(
                            [item.discount_percentage, item.discount_amount, item.total_buying_price, item.mrp_price_per_qty, item.cgst_percentage, item.sgst_percentage], 
                            [0.15, 0.18, 0.18, 0.18, 0.15, 0.16], 
                            "data"
                            )}
                            {this.renderHeaderDataView(
                            ["Total GST Amount", "Expiry Date Alert Period", "Reorder Threshold", "Physical Box No", "Instructions"], 
                            [0.15, 0.18, 0.18, 0.18, 0.31], 
                            "header"
                            )}
                            {this.renderHeaderDataView(
                            [item.gst_amount, item.expiry_alert_period, item.reorder_threshold, item.physical_box_number, item.instructions], 
                            [0.15, 0.18, 0.18, 0.18, 0.31], 
                            "data"
                            )}
                        </View>
                        </TouchableOpacity>
                    ))}
                    </ScrollView>
                </View>
                : null
                }
                </View>
                <View style={{width: "35%", backgroundColor: color.lightGreenBG, height: "100%", padding:10, shadowColor: color.lightGray, shadowOpacity: 0.6, shadowOffset:{width: -1, height: -1}, shadowRadius: 2}}>
                <ScrollView style={{maxHeight: "100%" }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                <CommonHistorySectionHeader
                heading1={"IOL Details"}
                heading2={""}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                <View style={{flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap', marginBottom: 10}}>
                {this.renderTexBox("Brand Name *", '100%', "brand_name")}
                {this.renderTexBox("Model Name *", '100%', 'model_name')}
                {this.renderTexBox("IOL Type *", "45%", 'iol_type')}
                {this.renderTexBox("IOL Power *", "45%", 'iol_power')}
                {this.renderTexBox("Batch Number *", "45%", 'batch_number')}
                {this.renderTexBox("Expiry Date *", "45%", 'expiry_date')}
                {this.renderTexBox("Product Code", "45%", 'product_code')}
                {this.renderTexBox("HSN/SAC", "45%", 'hsn_sac_code')}
                {this.renderTexBox("Bar Code", "45%", 'bar_code')}
                </View>
                <CommonHistorySectionHeader
                heading1={"Price Details"}
                heading2={""}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                <View style={{flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap', marginBottom: 10}}>
                {this.renderTexBox("Quantity *", "45%", 'quantity')}
                {this.renderTexBox("Buying Price/Quantity *", "45%", 'buying_price_per_qty')}
                {this.renderTexBox("Discount %", "45%", 'discount_percentage')}
                {this.renderTexBox("Discount Amount", "45%", 'discount_amount')}
                {this.renderTexBox("GST %", "45%", 'gst_percentage')}
                {this.renderTexBox("MRP/Qty *", "45%", 'mrp_price_per_qty')}
                </View>
                <View style={{height: '10vh', backgroundColor: color.white, shadowColor:color.grayShade, shadowOpacity: 0.5, shadowOffset: {width: 1, height: -1}, shadowRadius: 4, flexDirection: 'row', paddingHorizontal: 20, paddingVertical: 10, alignItems: 'center'}}>
                <View style={{flex: 0.2}}>
                    <Text style={{fontSize: "0.9vw", color: color.grayShade, fontWeight:'500'}}>{"CGST"}</Text>
                    <Text style={{fontSize: "0.8vw", color: color.grayShade, fontWeight:'500'}}>{states.cgst_percentage + "%"}</Text>
                </View>
                <View style={{flex: 0.2}}>
                    <Text style={{fontSize: "0.9vw", color: color.grayShade, fontWeight:'500'}}>{"SGST"}</Text>
                    <Text style={{fontSize: "0.8vw", color: color.grayShade, fontWeight:'500'}}>{states.sgst_percentage + "%"}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={{fontSize: "0.9vw", color: color.grayShade, fontWeight:'500'}}>{"CGST Amount"}</Text>
                    <Text style={{fontSize: "0.8vw", color: color.grayShade, fontWeight:'500'}}>{states.cgst_amount}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={{fontSize: "0.9vw", color: color.grayShade, fontWeight:'500'}}>{"SGST Amount"}</Text>
                    <Text style={{fontSize: "0.8vw", color: color.grayShade, fontWeight:'500'}}>{states.sgst_amount}</Text>
                </View>
                </View>
                <View style={{flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                {this.renderLabelBox("Total Buying Price", (states.total_buying_price) != "" ? (states.total_buying_price) : 0)}
                {this.renderLabelBox("Total MRP", states.total_mrp)}
                {this.renderLabelBox("Quantity", states.quantity != "" ? states.quantity : 0)}
                {this.renderLabelBox("Total GST Amount", states.gst_amount)}
                </View>
                <View style={{flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap', marginBottom: 10}}>
                {this.renderTexBox("Expiry Date Alert Period", "45%", 'expiry_alert_period')}
                {this.renderTexBox("Reorder Threshold", "45%", 'reorder_threshold')}
                {this.renderTexBox("Physical Box No", "45%", 'physical_box_number')}
                {this.renderTexBox("Instructions", "45%", 'instructions')}
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Save"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    if((states.isDirectPOStock || states.isPoStock) && (!states.editedStockIndex && (states.editedStockIndex).toString() !== '0')){
                        this.props.showResposeValue("error", "Extra Items can't be added")
                        this.clearLeftData()
                    } else{
                        this.saveStockRightToLeft()
                    }
                }}
                buttonKey={"saveIolNewRows"} />
                </View>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.clearLeftData()}}
                buttonKey={"clearIolNewRows"} />
                </View>
                </View>
                </ScrollView>
                </View>
            </View>
        )
    }
}