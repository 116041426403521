//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput, Linking, Image, TouchableOpacity } from 'react-native';
const screenHeight = Dimensions.get("window").height;
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import AsyncStorage from "../../../AsyncStorage";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { OBGYNHistorySectionHeading, CommonButton, EditAndDeleteIcon, CommonAddButton, CommonSectionHeader } from '../BaseComponent'
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import { Calendar } from 'react-native-calendars';
import * as ImagePicker from 'expo-image-picker';

// import Pagination from './Pagination'
import Calender from './calender'
// import { TouchableOpacity } from 'react-native-gesture-handler';

import { MultipleSelectPicker } from 'react-native-multi-select-picker'

// create a component
class DoctorProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0,

            doctorDetails: {},
            drKnownLanguages: [],

            drMobileNo: "",
            drEmailID: "",
            doctorProfilrPhoto: "",
            telemedicineFee: "",
            aboutMySelfTextBoxValue: "",

            drMobileNoAndMailIdEdit: false,
            // selectedLanguage: {},

            allLanguagesList: [],
            languagesList: [],
            selectedLanguages: [],
            showNewButtonFlag: true,
            languageTextValue: "",
            defaultLanguages: [
                { label: "Tamil", value: 1 },
                { label: "English", value: 2 },
                { label: "Malayalam", value: 11 },
                { label: "Kannada", value: 7 },
                { label: "Hindi", value: 3 },
            ],

            // selectectedItems: [],
            // isShownPicker: false

            getAppointmentCountDetails: {}

        }
    }
    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };


    convertedDate(date) {
        var fulldate = new Date(date);
        var converted_date = moment(fulldate).format("YYYY-MM-DD");
        return converted_date
    }


    componentDidMount() {
        var fulldate = new Date();
        var converted_date = moment(fulldate).format("YYYY-MM-DD");
        this.getAppointmentCount(converted_date)
        this.getDoctorProfile()
        this.getLanguagesList();

    }
    getLanguagesList() {
        var service_url = Constants.LANGUAGES;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getLanguagesListSuccessCallBack,
            this.getLanguagesListErrorCallBack
        );
    }
    getLanguagesListSuccessCallBack = success => {
        if (success.status == "success") {
            // alert(JSON.stringify(success.data))
            this.setState({
                allLanguagesList: success.data,
            })
        }
    }
    getLanguagesListErrorCallBack = error => { }



    filterAddressBook = (search) => {
       // console.log(search)
        let getlanguagesList = this.state
        let { allLanguagesList } = this.state

        let filteredList;

        filteredList = allLanguagesList.filter(item => item.language.toLowerCase().includes(search.toLowerCase()))

        getlanguagesList["languagesList"] = filteredList;

        this.setState({ getlanguagesList })
    }





    componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.modelCloseStatus && nextProps.modelCloseStatus == "success") {
            // this.rerenderAppointmentStatus();
            this.props.modelClose(false, "")
        }

    }

    getAppointmentCount(date) {

        var service_url = Constants.PROFILE_APPOINTMENT_COUNT + "?search_date=" + date;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getAppointmentCountSuccess,
            this.getAppointmentCountFailure
        );
    }
    getAppointmentCountSuccess = success => {
     //   console.log("success &&&& >" + JSON.stringify(success))
        if (success.status == "success") {
            // var data = {
            //     "telemedicine": {
            //         "s1_count": 1,
            //         "s1_start_time": " 7:00 AM",
            //         "s1_end_time": " 8:00 AM",
            //         "s2_count": 1,
            //         "s2_start_time": "10:00 AM",
            //         "s2_end_time": "11:00 AM",
            //         "s3_count": 0,
            //         "s3_start_time": null,
            //         "s3_end_time": null
            //     },
            //     "op": [
            //         {
            //             "clinic_name": "Clinic A",
            //             "appointment_count": 1
            //         },
            //         {
            //             "clinic_name": "Clinic B",
            //             "appointment_count": 1
            //         },
            //         {
            //             "clinic_name": "Clinic C",
            //             "appointment_count": 1
            //         }
            //     ]
            // }
            this.setState({
                getAppointmentCountDetails: success.data
                // getAppointmentCountDetails: data
            })
        }
    }
    getAppointmentCountFailure = error => { }

    async getDoctorProfile() {
        var service_url = Constants.DOCTOR_PROFILE;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDoctorProfileSuccess,
            this.getDoctorProfileFailure
        );
    }

    getDoctorProfileSuccess = success => {
        if (success.status == "success") {
         //   console.log("success.data.languages---------> " + JSON.stringify(success.data.languages))
            let language = success.data.languages
            let getLanguage = [];

            if (language.length > 0) {
                for (let i = 0; i < language.length; i++) {
                    let prepareData = {
                        label: language[i].language,
                        value: language[i].id
                    }
                    getLanguage.push(prepareData)
                }
            }


            this.setState({

                aboutMySelfTextBoxValue: success.data.about_myself,
                doctorProfilrPhoto: success.data.photo_url,
                doctorDetails: success.data,
                selectedLanguages: getLanguage,
                drMobileNo: success.data.mobile_number,
                drEmailID: success.data.email,
                telemedicineFee: success.data.telemedicine_fees
            })
        }
       // console.log("success ------> " + JSON.stringify(success))
    }
    getDoctorProfileFailure = error => {
        console.log("succerroress ------> " + JSON.stringify(error))
    }
    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    selectedLanguage(value, id) {
        if (value) {
            var { selectedLanguages } = this.state;
            var data = { value: id, "label": value }
            selectedLanguages.push(data);
            selectedLanguages = this.removeDuplicate(selectedLanguages)

            this.setState({
                selectedLanguages,

            })
        }
    }

    getCommonTextBoxValue(language, id) {
        var { defaultLanguages, selectedLanguages } = this.state
        var states = this.state;
        var prepareData = { label: language, value: id }
        defaultLanguages.push(prepareData);
        selectedLanguages.push(prepareData);
        defaultLanguages = this.removeDuplicate(defaultLanguages);
        selectedLanguages = this.removeDuplicate(selectedLanguages);

        this.setState({
            defaultLanguages, selectedLanguages,
            languageTextValue: "",
            languagesList: [],
            showNewButtonFlag: true
        })


    }

    renderLanguageList() {
        return (
            <View>
                <View>
                    <TextInput
                        style={{ paddingLeft: 15, borderColor: "#888888", borderWidth: 1, borderRadius: 4, height: 32, width: 200 }}
                        onChangeText={(text) => {
                            this.setState({ languageTextValue: text }, () => {
                                this.filterAddressBook(this.state.languageTextValue)
                            })
                        }}
                    // onSubmitEditing={()=>{
                    //     if(this.state.languageTextValue){
                    //         this.selectedLanguage(this.state.languageTextValue)
                    //     }
                    // }}
                    />
                </View>
                {
                    this.state.languagesList.length > 0 ?
                        <View style={{ position: 'absolute', width: 200, borderRadius: 5, borderWidth: 1, borderColor: "#888888", backgroundColor: "white", maxHeight: 200, marginTop: 40, }}>
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                {
                                    this.state.languagesList.length > 0 && this.state.languagesList.map((item, index) => {
                                        return (
                                            // item.language,true, item.id
                                            <TouchableOpacity onPress={() => {
                                                this.getCommonTextBoxValue(item.language, item.id)
                                            }} key={index} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 15 }}>
                                                <Text>{item.language}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </ScrollView>
                        </View>
                        : null
                }
            </View>
        )
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    doctorProfileUpdate() {
        // alert("Clicked")

        const data = new FormData();
        var states = this.state;
        var getList = []
        if (this.state.selectedLanguages.length > 0) {
            for (let i = 0; i < this.state.selectedLanguages.length; i++) {
                getList.push(this.state.selectedLanguages[i].value)
            }
        }

        if (this.state.doctorProfilrPhoto) {
            if (this.state.doctorProfilrPhoto.length > 2000) {
                data.append('photo', this.dataURItoBlob(this.state.doctorProfilrPhoto));
            } else {
                // data.append('photo', this.state.doctorProfilrPhoto);
            }
        }

        data.append('telemedicine_fees', states.telemedicineFee);
        data.append('about_myself', states.aboutMySelfTextBoxValue);
        data.append('languages', getList.toString());
        data.append('email', states.drEmailID);
        data.append('mobile_number', states.drMobileNo);

        // var data ={

        // }

        // alert(data)

        var service_url = Constants.DOCTOR_PROFILE_UPDATE;

        OpthamologyService.getInstance().documentUploadPost(
            service_url,
            data,
            this,
            this.doctorProfileUpdateSuccess,
            this.doctorProfileUpdateFailure,
            'multipart/form-data'
        );
    }

    doctorProfileUpdateSuccess = async success => {
        if (success.data.status == "success") {
            if (success.data.data && success.data.data.photo_url) {
                const doctorProfile = await AsyncStorage.setItem("doctorProfile", success.data.data.photo_url);

            }
            this.props.showResposeValue("success", success.data.message)
        } else {
            this.props.showResposeValue("error", success.data.message)
        }
    }
    doctorProfileUpdateFailure = error => {
        this.props.showResposeValue("error", error.data.message)

        // console.log("error ---------------------------> " + JSON.stringify(error.data.message));
    }


    renderCommonText(text, type) {
        // alert(type)
        return (
            <View style={{ marginTop: 5, marginBottom: 3 }}>

                <Text style={[
                    type == "title" ?
                        { color: "black", fontSize: 15, fontWeight: '500' }
                        : type == "specialist" ?
                            { color: "#4C4E4E", fontSize: 15 }
                            : type == "subheadingValue" ?
                                { fontSize: 12 }
                                : type == "id" ?
                                    { color: "#717373", fontSize: 13 }
                                    : type == "blueColorSmallText" ?
                                        { color: "#338EDD", fontSize: 10 }
                                        : type == "smallText" ?
                                            { color: "#717373", fontSize: 10 }
                                            : type == "subheading" ?
                                                { color: "black", fontSize: 14, fontWeight: '500' }
                                                : type == "red" ?
                                                    { color: "red", fontSize: 10 }
                                                    : null
                    , { flex: 1 }]} numberOfLines={2}>{text}</Text>
            </View>
        )
    }

    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }
    changeText(key, value) {
        var states = this.state;


        if (key == "drMobileNo") {
            var isNum = this.numberValidation(value)
            if (isNum) {
                if (value.length <= 10) {
                    states[key] = value;
                }
            }
        } else if (key == "telemedicineFee") {
            var isNum = this.numberValidation(value)
            if (isNum) {
                states[key] = value;
            }
        }
        else {
            states[key] = value;
        }

        this.setState({ states })
    }

    renderIconWithTextBox(iconName, key, value, width, type) {
        return (
            <View style={{ flexDirection: 'row' }}>

                {
                    iconName ?
                        <Image style={{ height: 20, width: 20, alignItems: 'flex-end', alignSelf: 'flex-end', marginRight: 20, tintColor: type == "rupee" ? color.black : color.themeDark }} source={iconName} />
                        : null
                }

                <TextInput

                    style={{
                        height: 30, marginBottom: 2,
                        width: width, borderBottomColor: "#888888", borderBottomWidth: 1, textAlign: 'center'
                    }}
                    value={value}
                    placeholder={""}
                    onChangeText={(text) => {
                        this.changeText(key, text);
                    }}
                />
            </View>
        )
    }

    richTextBox(key, value) {
        return (
            <View>
                <TextInput
                    style={{
                        padding: 8,
                        height: 80, borderRadius: 5, borderWidth: 1, borderColor: color.themeDark, width: "80%"
                    }}
                    multiline={true}
                    textAlignVertical={"top"}
                    value={value}
                    onChangeText={(text) => {
                        this.changeText(key, text);
                    }}
                />
            </View>
        )
    }

    onPressButton(key, selectedvalue) {
        if (key == "selectedLanguages") {

            var { selectedLanguages } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedLanguages.length > 0) {
                for (var i = 0; i < selectedLanguages.length; i++) {
                    if (selectedLanguages[i].value == selectedvalue.value) {
                        spliceData = selectedLanguages[i];
                        selectedLanguages.splice(i, 1)
                        var data = this.removeDuplicate(selectedLanguages);
                        this.setState({ selectedLanguages: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedvalue.value) {
                        } else {
                            getSelectedValue = selectedvalue
                        }
                    }
                }

                if (getSelectedValue) { selectedLanguages.push(selectedvalue); }
            } else {
                selectedLanguages.push(selectedvalue)
            }

            var data = this.removeDuplicate(selectedLanguages);
            selectedLanguages = data
            this.setState({ selectedLanguages }, () => {
            })



            // this.setState({
            //     selectedLanguage: selectedvalue
            // })
        } else if (key == "newButtonKey") {
            this.setState({
                showNewButtonFlag: false
            })
        } else if (key == "changePassword") {
            this.props.changePasswordOpenAndClose(true)
        }
    }


    changePasswordButton() {
        var data = [
            { label: "Change Password", value: "Change Password" }
        ]
        return (
            <CommonButton
                item={data}
                selectedvalue={{}}
                butttonText={"Change Password"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={"changePassword"} />
        )
    }


    saveButton() {
        var data = [
            { label: "Change Password", value: "Change Password" }
        ]
        return (

            <TouchableOpacity onPress={() => {
                this.doctorProfileUpdate()
            }} style={[{ backgroundColor: color.themeDark, paddingHorizontal: 50, paddingVertical: 8 }, Style.allButtonBorderRadius]}>
                <Text style={{ fontSize: 14, color: color.white, fontWeight: '500' }}>{"Save"}</Text>
            </TouchableOpacity>
            // <CommonButton
            //     item={data}
            //     selectedvalue={{}}
            //     butttonText={"Save"}
            //     buttonType={"theme"}
            //     buttonIcon={""}
            //     rightIcon={false}
            //     buttonAction={this.onPressButton.bind(this)}
            //     buttonKey={"saveButton"} />
        )
    }





    languageList() {
        var data = this.state.defaultLanguages;



        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 25, marginRight: 25, }}>
                {
                    data.map((item, index) => {

                        return (
                            <View style={{ marginRight: 8, marginBottom: 8 }}>
                                <CommonButton
                                    item={item}
                                    selectedvalue={this.state.selectedLanguages}
                                    butttonText={item.label}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this)}
                                    buttonKey={"selectedLanguages"} />
                            </View>
                        )
                    }
                    )}


                {

                    this.state.showNewButtonFlag ?
                        <CommonButton
                            item={{ label: "New", value: "New" }}
                            selectedValue={{}}
                            butttonText={"New"}
                            buttonType={"outlineNonTheme"}
                            buttonIcon={require('../../../../assets/images/PlusIcon.png')}
                            rightIcon={true}
                            buttonAction={this.onPressButton.bind(this)}
                            buttonKey={"newButtonKey"} />
                        :
                        this.renderLanguageList()
                }

            </View>




        )
    }

    paginationChangedValues(data) {

    }

    labelTextBox() {

    }

    onPressEditOrdelete(key) {
        if (key == "edit") {
            this.setState({
                drMobileNoAndMailIdEdit: !this.state.drMobileNoAndMailIdEdit
            })
        }
    }


    headerAndValueRowComponent(leftValue, rightValue, type, height, leftValueKey = "", rightValueKey = "", showEditIcon = false) {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', height: height, backgroundColor: type == "subheading" ? color.sectionHeadingLiteColor : color.white }}>
                <View style={{ flex: 0.5, marginLeft: 13 }}>
                    {/* {this.renderCommonText(leftValue, type)} */}

                    {
                        leftValueKey && this.state.drMobileNoAndMailIdEdit ?
                            <TextInput
                                editable={this.state.drMobileNoAndMailIdEdit}
                                style={{}}
                                value={leftValue}
                                onChangeText={(text) => {
                                    this.changeText(leftValueKey, text);
                                }}
                            /> :
                            this.renderCommonText(leftValue, type)
                    }


                </View>


                <View style={{ flex: 0.5, flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>


                    {/* {
                       this.state.drMobileNoAndMailIdEdit ? 


                   }
                   
                   
                    {this.renderCommonText(rightValue, type)} */}



                    {/* <TextInput 
                    editable ={this.state.drMobileNoAndMailIdEdit}
                    style={{                    }}
                    value={rightValue}
                    onChangeText={(text) => {
                        // this.changeText(key, text);
                    }}
                /> */}

                    {
                        rightValueKey && this.state.drMobileNoAndMailIdEdit ?
                            <TextInput
                                editable={this.state.drMobileNoAndMailIdEdit}
                                style={{}}
                                value={rightValue}
                                onChangeText={(text) => {
                                    this.changeText(rightValueKey, text);
                                }}
                            /> :
                            this.renderCommonText(rightValue, type)
                    }


                    {
                        showEditIcon ?
                            <EditAndDeleteIcon iconKey={"edit"} image={this.state.drMobileNoAndMailIdEdit ? "check" : "pencil"} onPressEditOrdelete={this.onPressEditOrdelete.bind(this)} /> :
                            <View />
                    }

                </View>
            </View>
        )
    }


    renderAppointmentCountCardColumnView(sesssion, count, startTime, endTime) {
        return (
            <View style={{ alignItems: 'center', marginHorizontal: 8 }}>
                <Text style={{ color: color.white, marginTop: 10 }}>
                    {sesssion}
                </Text>
                <Text style={{ color: color.white, marginTop: 10 }}>
                    {count}
                </Text>
                <Text style={{ color: color.white, marginTop: 10, fontSize: 8 }}>
                    {startTime}
                    {startTime && endTime ? " - " : ""}
                    {endTime}
                </Text>
            </View>
        )
    }


    openImageFolder = async () => {
        // this.setState({
        //   documentUploadSelectedButton:data
        // })
        try {
            let getImage;
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
            });
            if (!result.cancelled) {

                getImage = result.uri;
                this.setState({ doctorProfilrPhoto: getImage })

                // this.setState({ image: result.uri });

            }



        } catch (E) {
            console.log(E);
        }
    }


    render() {
        var states = this.state;
        var telemedicine = states.getAppointmentCountDetails.telemedicine
        var op = states.getAppointmentCountDetails.op
        return (
            <View style={{ height: screenHeight-65, flex: 1, flexDirection: "row" }}>
                <View style={[Style.leftView, { width: "65%" }]}>
                    <View style={{ paddingLeft:20,paddingRight:20,paddingTop:20 }}>

                        {this.headerAndValueRowComponent("Quick action", "", "subheading", 35)}
                    </View>
                    <View style={{ padding: 20 }}>

                        <View style={{ flexDirection: 'row', width: '100%' }}>
                            <View style={{ flexDirection: 'row', width: '48%', borderWidth: 1, borderRadius: 4, borderColor: '#45B7B1',padding:40,marginLeft:10,marginRight:10 }}>

                                <View style={{ width: '70%' }}>
                                    <TouchableOpacity onPress={() => {
                                        this.props.changeScreen("scheduleScreen")
                                    }} >
                                        <Text style={{ marginTop: 20, fontSize: 19, color: '#45B7B1', fontFamily: 300 }}>Set Schedule</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: '30%' }}>
                                    <TouchableOpacity onPress={() => {
                                        this.props.changeScreen("scheduleScreen")
                                    }} >
                                        <Image
                                            source={require('../../../../assets/images/Set_Schedule_icon.png')}
                                            style={{
                                                height: 50,
                                                width: 50
                                            }}
                                        />
                                    </TouchableOpacity>
                                </View>

                            </View>

                            <View style={{ width: '48%', borderWidth: 1, borderRadius: 4, borderColor: '#45B7B1', padding:40 }}>
                                <TouchableOpacity onPress={() => {
                                    this.props.markOutofOfficeModalFlag(true)
                                }} >
                                    <View style={{ flexDirection: 'row', }}>
                                        <View style={{ width: '70%', }}>
                                            <Text style={{ marginTop: 20, fontSize: 19, color: '#45B7B1', fontFamily: 300 }}>Mark Out of Office</Text>
                                        </View>
                                        <View style={{ width: '30%' }}>

                                            <Image
                                                source={require('../../../../assets/images/Mark_Out_of_Office.png')}
                                                style={{
                                                    height: 50,
                                                    width: 50
                                                }}
                                            />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </View>

                        </View>
                        <View style={{marginTop:20}}>
                        {this.headerAndValueRowComponent("Scheduled Appoinments", "", "subheading", 35)}
                        </View>
                        <View style={{ flexDirection: 'row' }}>

                            <View style={{ width: '50%', marginTop: 20, flexDirection: 'column' }}>
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                                    <View style={{ marginBottom: 20, borderRadius: 4, backgroundColor: color.themeDark, paddingHorizontal: 8, paddingVertical: 8 }}>
                                        <Text style={{ color: color.white, marginTop: 10 }}>{"Telemedicine"}</Text>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                                            {this.renderAppointmentCountCardColumnView(
                                                "S1",
                                                telemedicine && telemedicine.s1_count ? telemedicine.s1_count : "0",
                                                telemedicine && telemedicine.s1_start_time ? telemedicine.s1_start_time : "",
                                                telemedicine && telemedicine.s1_end_time ? telemedicine.s1_end_time : ""

                                            )}
                                            {this.renderAppointmentCountCardColumnView(
                                                "S2",
                                                telemedicine && telemedicine.s2_count ? telemedicine.s2_count : "0",
                                                telemedicine && telemedicine.s2_start_time ? telemedicine.s2_start_time : "",
                                                telemedicine && telemedicine.s2_end_time ? telemedicine.s2_end_time : ""

                                            )}
                                            {this.renderAppointmentCountCardColumnView(
                                                "S3",
                                                telemedicine && telemedicine.s3_count ? telemedicine.s3_count : "0",
                                                telemedicine && telemedicine.s3_start_time ? telemedicine.s3_start_time : "",
                                                telemedicine && telemedicine.s3_end_time ? telemedicine.s3_end_time : ""

                                            )}
                                        </View>

                                    </View>

                                    {
                                        op && op.length > 0 && op.map((op, index) => {
                                            return (
                                                <View key={index} style={{ marginBottom: 20, marginLeft: 20, borderRadius: 4, backgroundColor: color.themeDark, paddingHorizontal: 8, paddingVertical: 8, width: 150 }}>
                                                    <Text style={{ color: color.white, marginTop: 10 }}>{"OP"}</Text>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>

                                                        {this.renderAppointmentCountCardColumnView(
                                                            // "",
                                                            op.clinic_name,
                                                            op.appointment_count,
                                                            ""
                                                            // telemedicine && telemedicine.s1_end_time? telemedicine.s1_end_time :""

                                                        )}
                                                    </View>

                                                </View>
                                            )
                                        })
                                    }
                                </View>

                            </View>
                            <View style={{ marginLeft: 40, width: '45%', marginTop: 20 }}>
                                {/* <Calendar /> */}
                                <Calendar
                                    style={{
                                        borderWidth: 1,
                                        borderColor: 'gray',
                                        width: '100%'
                                    }}
                                    theme={{
                                        backgroundColor: '#ffffff',
                                        calendarBackground: color.white,
                                        textSectionTitleColor: 'black',  // day (sun,mon,tue,ect..)
                                        selectedDayBackgroundColor: 'yellow',
                                        selectedDayTextColor: "red",
                                        todayTextColor: color.themeDark,

                                        dayTextColor: 'black',
                                        // textDisabledColor: '#d9e1e8',
                                        // dotColor: '#00adf5',
                                        selectedDotColor: 'pink',
                                        // arrowColor: 'red',
                                        monthTextColor: 'black',
                                        textDayFontFamily: 'monospace',
                                        textMonthFontFamily: 'monospace',
                                        textDayHeaderFontFamily: 'monospace',
                                        textMonthFontWeight: 'bold',
                                        textDayFontSize: 13,
                                        textMonthFontSize: 13,
                                        textDayHeaderFontSize: 13,
                                    }}
                                    // current={'2020-05-23'}
                                    //   minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
                                    //   maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate} 
                                    onDayPress={(day) => {
                                        var getFilterDate = day.dateString
                                        // this.setState({showCalenderModal:false});
                                        // if(this.state.showCalenderModalFrom=== "From"){
                                        this.getAppointmentCount(this.convertedDate(getFilterDate))
                                        //   this.setState({filterFromDate :getFilterDate},()=>{this.updateState("fromDate", this.state.filterFromDate)})
                                        // }else{
                                        //   this.setState({filterToDate :getFilterDate},()=>{this.updateState("toDate", this.state.filterToDate)})
                                        // }
                                    }}
                                    onDayLongPress={(day) => { console.log('selected day is', day) }}
                                    // monthFormat={'yyyy MM'}
                                    onMonthChange={(month) => { }}
                                    hideArrows={false}
                                    renderArrow={(direction) => {
                                        if (direction === "left") {
                                            return (
                                                <Icon
                                                    size={30}
                                                    name={"angle-left"}
                                                />
                                            )
                                        } else if (direction === "right") {
                                            return (
                                                <Icon
                                                    size={30}
                                                    name={"angle-right"}
                                                />
                                            )
                                        }
                                    }}
                                    hideExtraDays={false}
                                    disableMonthChange={false}
                                    // firstDay={1}
                                    // hideDayNames={true}
                                    // showWeekNumbers={true}
                                    onPressArrowLeft={substractMonth => substractMonth()}
                                    onPressArrowRight={addMonth => addMonth()}

                                />
                            </View>

                        </View>

                    </View>
                </View>
                <View style={[{
                    // padding: 10,
                    // width: "31.5%",
                    // height: screenHeight,
                    backgroundColor: color.themeShade,
                    // alignItems: "center",
                    padding: 20, width: "35%",

                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.6,
                    shadowRadius: 4,
                    elevation: 5,
                    paddingHorizontal: 20
                }]}>
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ zIndex: -1 }}
                    // contentContainerStyle={{ flex: 1 }}
                    >

                        <View style={{ alignItems: 'center' }}>
                            <View>

                                <TouchableOpacity onPress={() => this.openImageFolder()}>
                                    <Image source={this.state.doctorProfilrPhoto ? { uri: this.state.doctorProfilrPhoto } : require("../../../../assets/images/sample_profile.png")}
                                        style={{ height: 80, width: 80, borderRadius: 50, marginRight: 20 }} />
                                </TouchableOpacity>

                                {/* <TouchableOpacity>
                                <Image style={{ height: 80, width: 80, borderRadius: 50, marginRight: 20 }} source={{ uri: states.doctorDetails.photo_url }} />
                                </TouchableOpacity> */}
                            </View>

                            <View style={{
                                // flex:0.;
                                width: "100%",
                                marginTop: 40,
                                zIndex: -1, position: 'absolute', backgroundColor: color.white, paddingTop: 30, shadowOffset: { width: 0.5, height: 0.5 },
                                shadowOpacity: 0.2,
                                shadowRadius: 4,
                                elevation: 5,
                            }}>
                                <View style={{ alignItems: 'center', marginTop: 20 }}>
                                    {this.renderCommonText(states.doctorDetails.doctor_name, "title")}
                                </View>
                                {/* <View style={{ flexDirection: 'row', alignItems: 'center', height: 35, backgroundColor: color.sectionHeadingLiteColor }}>
                                    <View style={{ flex: 0.5, marginLeft: 13 }}>
                                        {this.renderCommonText("Specilazation", "subheading")}
                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        {this.renderCommonText("Registration Number", "subheading")}
                                    </View>
                                </View> */}
                                {this.headerAndValueRowComponent("Specialization", "Registration Number", "subheading", 35)}
                                {this.headerAndValueRowComponent(states.doctorDetails.speciality, states.doctorDetails.doctor_account_number, "subheadingValue", 50)}

                                {this.headerAndValueRowComponent("Phone Number", "Email ID", "subheading", 35, "", "", true)}
                                {this.headerAndValueRowComponent(this.state.drMobileNo, this.state.drEmailID, "subheadingValue", 50, "drMobileNo", "drEmailID")}
                                <View style={{ alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                                    {this.changePasswordButton()}
                                </View>
                                <View>
                                    {this.headerAndValueRowComponent("Language that I speak", "", "subheading", 35)}
                                    {this.languageList()}
                                </View>
                                <View style={{ marginTop: 8, zIndex: -1 }}>

                                    {this.headerAndValueRowComponent("Telemedicine Fees", "", "subheading", 35)}
                                    <View style={{ marginLeft: 20 }}>
                                        {this.renderIconWithTextBox(require('../../../../assets/images/doctorProfileIcons/Rupee.png'), "telemedicineFee", this.state.telemedicineFee, 100, "rupee")}

                                        <View style={{ flexDirection: 'row', }}>
                                            {this.renderCommonText("*", "red")}
                                            {this.renderCommonText("Incl. of eMedHub Charge", "smallText")}
                                            {this.renderCommonText("  |  ", "smallText")}
                                            <TouchableOpacity

                                                onPress={() => {
                                                    Linking.openURL("https://www.emedhub.in/terms-and-conditions/")
                                                        .catch(err => {
                                                            console.error("Failed opening page because: ", err)
                                                          //  alert('Failed to open page')
                                                        })
                                                }}

                                            >
                                                {this.renderCommonText("Terms and conditions", "blueColorSmallText")}

                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginTop: 8, zIndex: -1 }}>
                                    {this.headerAndValueRowComponent("About Myself", "", "subheading", 35)}
                                    <View style={{ marginLeft: 20 }}>
                                        <View style={{ flexDirection: 'row' }}>
                                            {this.renderCommonText("*", "red")}
                                            {this.renderCommonText("This will be displayed in patient app", "smallText")}
                                        </View>
                                        {this.richTextBox("aboutMySelfTextBoxValue", this.state.aboutMySelfTextBoxValue)}
                                    </View>
                                </View>
                                <View style={{ zIndex: -1, alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                                    {this.saveButton()}
                                </View>


                                {/* <View style={{ flexDirection: 'row', height: 50, alignItems: 'center' }}>
                                    <View style={{ flex: 0.5, marginLeft: 13 }}>
                                        {this.renderCommonText(states.doctorDetails.speciality, "subheadingValue")}
                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        {this.renderCommonText(states.doctorDetails.doctor_account_number, "subheadingValue")}
                                    </View>
                                </View> */}
                            </View>


                        </View>


                        {/* <View>
                            <CommonSectionHeader />
                        </View> */}

                        {/* <View style={{ alignItems: 'center' }}>
                            {this.renderCommonText(states.doctorDetails.doctor_name, "title")}
                            {this.renderCommonText(states.doctorDetails.speciality, "specialist")}
                            {this.renderCommonText(states.doctorDetails.doctor_account_number, "id")}
                        </View>

                        <View style={{ alignItems: 'center' }}>
                            {this.renderIconWithTextBox(require('../../../../assets/images/doctorProfileIcons/Phone.png'), "drMobileNo", this.state.drMobileNo, 200)}
                            {this.renderIconWithTextBox(require('../../../../assets/images/doctorProfileIcons/Mail.png'), "drEmailID", this.state.drEmailID, 200)}
                        </View>

                        <View style={{ alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                            {this.changePasswordButton()}
                        </View>

                        <View>
                            {this.renderCommonText("Language that I speak", "subheading")}
                            {this.languageList()}
                        </View>

                        <View style={{ marginTop: 8}}>
                            {this.renderCommonText("Telemedicine Fees", "subheading")}
                            {this.renderIconWithTextBox(require('../../../../assets/images/doctorProfileIcons/Rupee.png'), "telemedicineFee", this.state.telemedicineFee, 100, "rupee")}

                            <View style={{ flexDirection: 'row' }}>
                                {this.renderCommonText("*", "red")}
                                {this.renderCommonText("Incl. of eMedHub Charge", "smallText")}
                                {this.renderCommonText("  |  ", "smallText")}
                                {this.renderCommonText("Terms and conditions", "blueColorSmallText")}
                            </View>
                        </View>


                        <View style={{ marginTop: 8}}>
                            {this.renderCommonText("About Myself", "subheading")}
                            <View style={{ flexDirection: 'row' }}>
                                {this.renderCommonText("*", "red")}
                                {this.renderCommonText("This will be displayed in patients", "smallText")}
                            </View>
                            {this.richTextBox("aboutMySelfTextBoxValue",this.state.aboutMySelfTextBoxValue)}

                        </View>

                        <View style={{ alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                            {this.saveButton()}
                        </View> */}


                    </ScrollView>
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

//make this component available to the app
export default DoctorProfile;
