import React, { Component } from 'react';
import { View, Text, Platform ,TouchableOpacity,Picker,Image, StyleSheet} from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { CommonButton,CommonHistorySectionHeader } from '../BaseComponent'
// import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from "moment";

const platform = Platform.OS;
// const IS_OPTHALMOLOGY_TEST = "isopthalmologyTest";
const pageTitle = "Test";
const pageName = "OpthalmologyTest";
const isPageName = "isOpthalmologyTest";

export default class OpthalmologyTest extends BaseComponentStyle {
  constructor(props) {
    super(props);
    this.state = {
      isLocked:this.props.patientAppointment.is_locked,

      listOfEyeTest: [],

      patientAppointment: this.props.patientAppointment,
      isComplaint: isPageName === this.props.selectedView ? true : false,


      selectedClinicOrDoctor : this.props.selectedClinicOrDoctor,
      laboratoryList:[],
      selectLaboratory:"",
      testSummaryID : "",
      currentDate: moment(new Date()).format('YYYY-MM-DD')

    };
  }


  renderTransmitView(){
    return(
        <View style={styles.TransmitMainView}>

      {/* <Picker
        // enabled={!this.state.isLocked}
        selectedValue={this.state.testCategory}
        style={{ height: 30, width: 150, marginRight: 10,borderRadius:4,backgroundColor:color.white }}
        itemStyle={{}}
        onValueChange={(itemvalue) => {
          // var states = this.state.mandatoryInformation;
          // states["state"] = itemvalue;
          this.setState({ testCategory: itemvalue },()=>{
            this.getLaboratoryList()
          });
        }}
      >
        <Picker.Item label="Test Category" value={0} />
        <Picker.Item label="Laboratory Test" value="Laboratory Test" />
        <Picker.Item label="Radiology Test" value="Radiology Test" />
        <Picker.Item label="Special Test" value="Special Test" />
      </Picker> */}
      <Picker
        // enabled={!this.state.isLocked}
        selectedValue={this.state.selectLaboratory}
        style={{ height: '1.95vw', width: '9.76vw', marginRight: '0.65vw',borderRadius:'0.26vw',backgroundColor:color.white, fontSize:'0.91vw' }}
        itemStyle={{}}
        onValueChange={(itemvalue) => {
          // var states = this.state.mandatoryInformation;
          // states["state"] = itemvalue;
          this.setState({ selectLaboratory: itemvalue });
        }}
      >
        <Picker.Item label={"Select"} value={""} />
        {
          this.state.laboratoryList.map((item, index) => {
            return <Picker.Item key={index} label={item.laboratory_name} value={item.laboratory_id} />
          })
        }
      </Picker>
      {/* <View style={{paddingHorizontal:10,flexDirection:"row",paddingVertical:10}}> */}
      {/* <View style={{flexDirection:"row",paddingHorizontal:10, alignItems:'center'}}> */}
      <CommonButton
        item={{}}
        selectedvalue={{}}
        butttonText={"Transmit"}
        buttonType={"theme"}
        rightIcon={true}
        buttonAction={this.transmitionData.bind(this)}
        buttonKey={"testCategory"}
      />
      <TouchableOpacity
        onPress={() => {
          this.onPressPrint()
        }} style={styles.PrintBtn}
      >
        <Image source={require("../../../../assets/images/Active/Print.png")} style={styles.PrintImg} />
      </TouchableOpacity>

      {/* </View>  
           
        </View>   */}
    </View>
    )
}



transmitionData() {
var states = this.state
var id=[];
for(let i=0;i<states.listOfEyeTest.length;i++)
{
  id.push(states.listOfEyeTest[i].id)
}
let data = {
"test_order_id":id,
"patient_id": states.patientAppointment.patient_id,
"laboratory_id": states.selectLaboratory,
"appointment_id":  states.patientAppointment.appointment_id,
"test_summary_id": states.testSummaryID,
"is_transmitted": true,
"transmitted_date": this.state.currentDate,
  
};
//alert(JSON.stringify(id))
var service_url = Constants.OPT_IMG_TRANSMIT;


  OpthamologyService.getInstance().postComplaints(
    service_url,
    data,
    this,
    this.  transmitionDataSuccess,
    this.  transmitionDataFailure
  );
}

transmitionDataSuccess = success => {
if (success.status == "success") {
  this.props.showResposeValue('success', success.message);
  // this.clearAllData();
  // this.props.changeScreen("iPPatientList")
  // this.props.refreshRighSideComponent(pageName);
} else {
  this.props.showResposeValue('error', success.message);

}

}
transmitionDataFailure = error => {
this.props.showResposeValue('error', error.message);
}

onPressPrint(){
  let states = this.state;
  var serviceUrl ;
  serviceUrl = Constants. LABORATORY_TEST_PRINT+ 
  "?appointment_id=" + this.state.patientAppointment.appointment_id + 
  "&patient_id=" +this.state.patientAppointment.patient_id +
  "&is_opth="+true

   OpthamologyService.getInstance().documentUploadGet(
    serviceUrl,
    // this,
    this.printTestSuccess,
    this.printTestFailure,
    "pdf"
  );
};

printTestSuccess = response => {
  if (response) {
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    // console.log(file)
    window.open(fileURL);
  }
};

printTestFailure = error => {
  // console.log("Get clinic list error response");
  console.log(error);
};



getLaboratoryList = () => {
var service_url =
  Constants.LABORATORY_LIST + "?clinic_id=" + this.state.selectedClinicOrDoctor.id +"&test_category=" +"Laboratory Test"

OpthamologyService.getInstance().getComplaints(
  service_url,
  this,
  this.getLaboratoryListSuccess,
  this.getLaboratoryListFailure
);
};

getLaboratoryListSuccess = (response) => {
if (response.status === "success") {
 var states = this.state;
  if(response.data.length > 0 ){
    states["selectLaboratory"] = response.data[0].laboratory_id
  }

  this.setState({
    laboratoryList:response.data,
    states
  })
  // alert(JSON.stringify(response.data))
}
};

getLaboratoryListFailure = (error) => {
// console.log("opthamology complaints GET error response");
console.log(error);
};




//     Laboratory list.   get
// Request URL: https://uat.v3.api.emedhub.in/lab/laboratory/list/?clinic_id=10&test_category=Laboratory%20Test

// transmit post
// Request URL: https://uat.v3.api.emedhub.in/lab/transmit/test/

// print 
// Request URL: https://uat.v3.api.emedhub.in/lab/test/print/?appointment_id=35486&patient_id=40278



  
  componentDidMount() {
    this.getTestDetails()
    this.getLaboratoryList()
  }

  getTestDetails = () => {
    var { patientAppointment } = this.state
    var service_url = Constants.EYE_TEST_POST_API + "?appointment_id=" + patientAppointment.appointment_id + "&patient_id=" + patientAppointment.patient_id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTestDetailsSuccess,
      this.getTestDetailsFailure
    );
  };

  getTestDetailsSuccess = success => {
    if (success.status == "success") {
      var { listOfEyeTest } = this.state

      // var data = success.data;
      // for(var i =0; i<data.length ; i++){
      //   var prepareData = {value:data[i].test_id,label:data[i].test_name,id:data[i].id}
      //   listOfEyeTest.push(prepareData)
      // }


      this.setState({
        // listOfEyeTest,
        listOfEyeTest: success.test_order_details,
        testSummaryID : success.test_summary_id
      })
      // console.log("success---+++---> " + JSON.stringify(success.data))
    }
  }
  getTestDetailsFailure = error => { }




  editComplaints = () => {
    this.props.editvitals(true, "")
  }
  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
    // this.editComplaints()
  }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  
    if (props.reloadData && props.reloadData === pageName) {
      this.getTestDetails()
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isComplaint) {

      this.setState({ isComplaint: updateFontColor });
    }
  }

  onPressButton(key, selectedvalue) {
    // alert(JSON.stringify(selectedvalue))
    if (key == "selectedEyeTest") {
      this.props.triggerNewItem(isPageName);
      this.props.editItem(pageName, selectedvalue);

      // this.setState({
      //   selectedEyeTest:selectedvalue
      // })
    }
  }

  deleteEyeTest(data) {

    var service_url = Constants.DELETE_EYE_TEST + "?test_details_id=" + data.id + "&test_summary_id=" + this.state.testSummaryID

    OpthamologyService.getInstance().deleteComplaints(
      service_url,
      data,
      this,
      this.deleteEyeTestSuccess,
      this.deleteEyeTestFailure
    );
  }

  deleteEyeTestSuccess = success => {
    if (success.status == "success") {
      this.getTestDetails()
      this.props.showResposeValue("success", success.message);
    } else {
      this.props.showResposeValue("error", success.message)
    }
  }
  deleteEyeTestFailure = error => {
    this.props.showResposeValue("error", error.message)
  }

  render() {
    return (
      <View>
        <View style={{display:"flex",flexDirection:"row"}}>
        {this.renderTitleBorder(this.state.isComplaint, 'Investigation', isPageName, this.triggerNewItem)}
        <TouchableOpacity
        onPress={() => {
          this.onPressPrint()
        }} style={styles.PrintBtn}
      >
        <Image source={require("../../../../assets/images/Active/Print.png")} style={styles.PrintImg} />
      </TouchableOpacity>
      </View>
        {

this.state.listOfEyeTest.length > 0 ?

      <View style={{marginTop:'0.65vw'}}>
        <CommonHistorySectionHeader
              heading1={"Eye Test"}
              heading2={"Comments"}
              heading3={"Action"}
              columnSize={[0.45, 0.45,0.10]}
              noOfColumn={3}
            />
          {
            this.state.listOfEyeTest.map((item, index) => {
              var prepareData = { value: item.test_name, label: item.test_name, id: item.id }
              return (
                // <View style={{ marginBottom: 10, marginRight: 10,flexDirection:"row" }} key={index}>

                //   <View
                //     style={{
                //       borderWidth: 1,
                //       borderRadius: 30,
                //       paddingTop: 8,
                //       paddingLeft: 18,
                //       paddingRight: 18,
                //       paddingBottom: 8,
                //       flexDirection: 'row',
                //       alignItems: 'center',
                //       alignSelf: 'center',
                //       borderColor: color.themeDark,
                //       flex:0.5
                //     }}

                //   >
                //     <TouchableOpacity  disabled={this.state.isLocked} onPress={() => {
                //       this.onPressButton("selectedEyeTest", prepareData)
                //     }}>
                //       <Text style={{ color: "#04c6bf", fontSize: 11 }}>{prepareData.label}</Text>
                //     </TouchableOpacity>
                //     <TouchableOpacity  disabled={this.state.isLocked} onPress={() => {
                //       this.deleteEyeTest(prepareData)
                //     }}>
                //       <Text style={{ color: "#04c6bf", fontSize: 11, marginLeft: 12, fontWeight: 'bold' }}>X</Text>
                //     </TouchableOpacity>
                //   </View>

                //   <View style={{flex:0.5}}>
                //     <Text>{item.comments}</Text>
                //   </View>
                //   {/* <CommonButton
                //   item={prepareData}
                //   selectedvalue={{}}
                //   butttonText={prepareData.label}
                //   buttonType={"outlineTheme"}
                //   buttonIcon={""}
                //   rightIcon={false}
                //   buttonAction={this.onPressButton.bind(this)}
                //   buttonKey={"selectedEyeTest"} /> */}
                // </View>

                <View style={styles.TableDataView}>
                  <Text style={[{ flex: 0.45,marginHorizontal: '1.3vw', fontSize:'0.91vw'  }]}>{item.test_name ? item.test_name : item.special_test_name}</Text>
                  <Text style={[{ flex: 0.45, fontSize:'0.91vw' }]}>{item.comments}</Text>
                  <View style={{ flex: 0.10 }}>
                    <TouchableOpacity disabled={this.state.isLocked} onPress={()=>this.deleteEyeTest(prepareData)}>
                      {this.renderIdButton("trash")}
                    </TouchableOpacity>
                  </View>

                </View>
              )
            })
          }

          {/* {this.renderTransmitView()} */}
        </View>
          : null
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
TableDataView:{
  flexDirection: 'row' ,
  marginVertical:'0.32vw'
},
TransmitMainView:{
  alignSelf: "flex-end", 
  flexDirection: "row", 
  alignItems: "center", 
  marginTop: '0.97vw' 
},
PrintBtn:{
  backgroundColor: color.themeBackgroud, 
  borderRadius: '0.26vw', 
  marginLeft: '0.65vw',
  height:'1.76vw',
  width:'1.76vw',
  justifyContent:"center"
},
PrintImg:{
  height: '1.3vw', 
  width: '1.3vw',
  alignSelf:"center"
}
})
