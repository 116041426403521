import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from "../../../AsyncStorage";
import { color } from '../../../styles/Color';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isIPAvailable: "true",
            isAdmin: false,
            userServiceReport: {},
            HoverPage : "",
            isOPTClinic: false
        }
    }
    async componentDidMount(){
        var isIPAvailable =await AsyncStorage.getItem("IsIpAvailable");
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
        var isOPT = false
        if (loggedInDataObj.clinic_type) {
            isOPT = loggedInDataObj.clinic_type.is_opt_clinic
        } else {
            isOPT = false;
        }
        this.setState({
            isOPTClinic: isOPT,
            isIPAvailable: isIPAvailable,
            isAdmin: loggedInDataObj.is_admin_access
        })
        this.getUserReportStatus()
    }
    getUserReportStatus = () => {
        var url = Constants.UPDATE_FO_ADMIN_SETTINGS 
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getUserReportStatusSuccess,
            this.getUserReportStatusFailure
          );
    }
    getUserReportStatusSuccess = (response) => {
       if(response && response.status === "success") {
          var data = response.data
          this.setState({
              userServiceReport: JSON.parse(data.report_config_views)
          })

       }
    }
    getUserReportStatusFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    renderReportView = (title, pageName, isUserAccess) => {
     return(
         <View style={[styles.Row, { paddingVertical:  pageName === "" ? "2.18vw" : "1.5vw"}]}>
             <TouchableOpacity disabled = {!isUserAccess}
              onMouseEnter={() => {
                this.setState({HoverPage : pageName})
              }}
              onMouseLeave={() => {
                this.setState({HoverPage : ''})
              }}
              onPress={() => {
                 this.props.changeScreen(pageName)
              }}
             >
                 <Text  style={[styles.TextHead, 
                    { 
                        color : isUserAccess ? this.state.HoverPage == pageName ? color.themeDark : color.black : color.lightGray,
                        fontSize: isUserAccess ? this.state.HoverPage == pageName ? '1.01vw' : '1vw' : '1vw',
                         }]}>{title}</Text>
             </TouchableOpacity>
         </View>
     )
    }
    render() {
        var { userServiceReport } = this.state
        return (
            <View style={{ padding: 20 }}>
                <Text style={{ fontSize: '1.5vw', fontWeight: "500" }}>
                    Reports
                </Text>

                <View style={styles.header}>
                <Text style={[styles.TextHead, {flex: 0.33}]}>
                        Patient Reports
                    </Text>
                    <Text style={[styles.TextHead, {flex: 0.33}]}>
                        Service Reports
                    </Text>
                    {this.state.isIPAvailable == "true" ? 
                    <Text style={[styles.TextHead, {flex: 0.32}]}>
                        IP Reports
                    </Text> : null }

                </View>
                <View>
                { this.state.isAdmin ?
                <View style={{ flex: 1, flexDirection: "row" }}>
                 <View style={{ flex: 0.33 }}>
                   {this.renderReportView("Patient Type Report", "PatientTypeReport", true)}
                   {this.renderReportView("Appointment Type Report", "AppointmentTypeReport", true)}
                   {this.renderReportView("Referral Report", "RefferalReport", true)}
                   {this.renderReportView("Referred Source Report ", "RefferenceReport", true)}
                   {this.renderReportView("Pending Bill Report", "PendingBillReport", true)}
                 </View>
                 <View style={{ flex: 0.33 }}>
                   {this.renderReportView("Service Detailed", "ServiceDetailed", true)}
                   {this.renderReportView("Service Overview", "ServiceOverview", true)}
                   {this.renderReportView("Day End Report", "DayEndOverview", true)}
                   {this.renderReportView("Corporate Patient Bills", "CorporatebillsReport", true)}
                   {this.state.isOPTClinic ? 
                   this.renderReportView("Optical CRM Sales", "OpticalCRMSales", true)
                   : this.renderReportView("", "") }
                 </View>
                 {this.state.isIPAvailable == "true" ? 
                 <View style={{ flex: 0.32 }}>
                   {this.renderReportView("IP Doctor Report", "IPDoctorReport", true)}
                   {this.renderReportView("IP Current Bill Status Report", "IPbillStatusReport", true)}
                   {this.renderReportView("IP Advance Payment Report", "ipAdvanceReport", true)}
                   {this.renderReportView("", "")}
                   {this.renderReportView("", "")}
                 </View> : null }
                 </View>:
                 <View style={{ flex: 1, flexDirection: "row" }}>
                   <View style={{ flex: 0.33 }}>
                    {this.renderReportView("Patient Type Report", "PatientTypeReport", userServiceReport.patient_type)}
                    {this.renderReportView("Appointment Type Report", "AppointmentTypeReport", userServiceReport.appointment_type)}
                    {this.renderReportView("Referral Report", "RefferalReport", userServiceReport.referral_report)}
                    {this.renderReportView("Referred Source Report ", "RefferenceReport", userServiceReport.referred_source_report)}
                    {this.renderReportView("Pending Bill Report", "PendingBillReport", userServiceReport.pending_bills_report)}
                   </View>
                   <View style={{ flex: 0.33 }}> 
                    {this.renderReportView("Service Detailed", "ServiceDetailed", userServiceReport.service_detailed)}
                    {this.renderReportView("Service Overview", "ServiceOverview", userServiceReport.service_overview)}
                    {this.renderReportView("Day End Report", "DayEndOverview", userServiceReport.day_end_overview)}
                    {this.renderReportView("Corporate Patient Bills", "CorporatebillsReport", userServiceReport.corporate_bills_report)}
                    {this.state.isOPTClinic ? 
                   this.renderReportView("Optical CRM Sales", "OpticalCRMSales", true)
                   : this.renderReportView("", "") }
                   </View>
                   {this.state.isIPAvailable == "true" ? 
                   <View style={{ flex: 0.32 }}>
                    {this.renderReportView("IP Doctor Report", "IPDoctorReport", userServiceReport.ip_doctor)}
                    {this.renderReportView("IP Current Bill Status Report", "IPbillStatusReport", userServiceReport.ip_current_bill_status)}
                    {this.renderReportView("IP Advanve Payment Report", "ipAdvanceReport", )}
                    {this.renderReportView("", "")}
                    {this.renderReportView("", "")}
                   </View> : null }
                 </View>
               }
               </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    topHeader: {
       backgroundColor: "#E0E0E0", marginTop: 15, display: 'flex', flexDirection: 'row', borderRadius: '0.5vw', flex: 1, padding: "0.3vw"
    },
    TextHead: {
        fontSize: '1vw', fontWeight: "500"
    },
    Row: {
        borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: "1.5vw"
    },
    header: {height: "5vh", flexDirection: "row", backgroundColor: color.sectionHeadingDarkColor, padding: "0.5vw", marginTop: "1vw", borderRadius:'0.26vw' }
});
