import React, { Component } from "react";
import {
    View,
    Text,
    Dimensions,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    TextInput,
} from "react-native";
import { color } from "../../../../styles/Color";
import moment from "moment";
import { NavigationTopHeader } from "../../BaseComponent";
import "antd/dist/antd.css";
import { DatePicker, Tooltip } from "antd";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import AsyncStorage from "../../../../AsyncStorage"

const screenHeight = Dimensions.get("window").height;


const navigationHeaderList = [
    { label: "Billing Transaction", value: "ipallTransactions" },
];

const navigationHeaderListIfIPCreateBill = [
    { label: "Create Bill", value: "ipPatientCreateBill" },
    { label: "Pending Bill", value: "ipPatientSavedBill" },
    { label: "Billing Transaction", value: "ipallTransactions" },
    { label: "OT Bills", value: "otbills" },
    // { label: "Summary Bill", value: "summaryBill" },
    { label: "Estimation Bill", value: "estimationBill" },
];

export default class EstimateBill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCurrentPageIsIPPatientAppointment:
                this.props.isCurrentPageIsIPPatientAppointment,
            patientProfileDetails: this.props.selectedIpPatientDetails,
            MobileNo: this.props.selectedIpPatientDetails.patient_mobile_number,
            PatientName: this.props.selectedIpPatientDetails.patient_name,
            UHID: this.props.selectedIpPatientDetails.patient_account_number,
            ProvDiag: "",
            EstimatePeriod: "",
            selectedDate: moment(new Date()).format("YYYY-MM-DD"),
            BedDays: "",
            InvestigationDays: "",
            ConsultDays: "",
            BedCharges: "",
            OperationCharges: "",
            InvestigationCharges: "",
            ConsultCharges: "",
            OthersCharges: "",
            BedTotal: 0,
            InvestTotal: 0,
            ConsultTotal: 0,
            OpreationTotal: 0,
            OthersTotal: 0,
            ClinicName: "",
            // IsDaysCountValid: false
        };
    }

    async componentDidMount() {
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
        this.setState({
            ClinicName: loggedInDataObj.name
        })
    }

    changeTabSection(data) {
        // do nothing
        this.props.changeScreen(data, {}, "", {}, "", "");
    }


    TextBox = (lable, stateKey, editable, width = "10vw") => {

        var states = this.state
        return (
            <View style={{ marginTop: "2vh" }}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.5vw', backgroundColor: "white", paddingLeft: '0.3', paddingRight: '0.3', color: color.black }}>{lable}</Text>
                {
                    lable != "Estimation Creation Date" ?
                        <TextInput
                            value={states[stateKey]}
                            editable={editable}
                            maxLength={stateKey === "EstimatePeriod" ? 3 : ''}
                            style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: stateKey === "EstimatePeriod" ? ((this.state.IsDaysCountValid === undefined ? true : this.state.IsDaysCountValid) || this.state.EstimatePeriod == "") ? color.boxColor : color.red : color.boxColor, borderWidth: 1, borderRadius: '0.26vw', height: '2.2vw', marginTop: '0.5vw', width: width, marginRight: '2vw', backgroundColor : editable ? "white" : "#F0F0F0" }}
                            onChangeText={(text) => {
                                if (stateKey === "EstimatePeriod") {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        states[stateKey] = text
                                        this.setState({
                                            states
                                        }, () => {
                                            this.setState({
                                                IsDaysCountValid: this.CalulateDaysCount()
                                            })
                                        })
                                    }
                                }
                                else {
                                    states[stateKey] = text
                                    this.setState({
                                        states
                                    })
                                }
                            }}
                        />
                        :
                        <DatePicker
                            style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: '0.26vw', height: '2.2vw', marginTop: '0.5vw', width: width, marginRight: '2vw' }}
                            defaultValue={moment(this.state.selectedDate)}
                            disabled={true} />
                }
            </View>)
    }

    ClearClicked = () => {
        this.setState({
            BedDays: "",
            InvestigationDays: "",
            ConsultDays: "",
            BedCharges: "",
            OperationCharges: "",
            InvestigationCharges: "",
            ConsultCharges: "",
            OthersCharges: "",
            BedTotal: 0,
            InvestTotal: 0,
            ConsultTotal: 0,
            OpreationTotal: 0,
            OthersTotal: 0,
            ProvDiag: "",
            EstimatePeriod: "",
        })
    }

    renderTextFields = () => {
        return (
            <View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    {this.TextBox("Mobile No", "MobileNo", false)}
                    {this.TextBox("Patient Name", "PatientName", false)}
                    {this.TextBox("UHID", "UHID", false)}
                    {this.TextBox("Provisional Diagnosis * ", "ProvDiag", true, "25vw")}
                    {this.TextBox("Estimation Period (Days) * ", "EstimatePeriod", true)}
                    {this.TextBox("Estimation Creation Date")}
                </View>
                <View>
                    <Text style={{ width: '100%', color: 'red', fontWeight: '500', paddingTop: '0.3vw', paddingLeft: '65vw', fontSize: '0.8vw' }}>
                        {
                            ((this.state.IsDaysCountValid === undefined ? true : this.state.IsDaysCountValid) || this.state.EstimatePeriod == "") ?
                                " " : "Actual days can't exist then estimation days"
                        }
                    </Text>
                </View>
            </View>
        )
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }
    numberValidationWithDot(num) {
        var rgx = /^(\d{0,3})((\.(\d{0,1})?)?)$/;
        return num.match(rgx);
    }

    CalulateDaysCount = () => {
        if ((isNaN(parseFloat(this.state.BedDays)) ? 0 : parseFloat(this.state.BedDays)) + (isNaN(parseFloat(this.state.InvestigationDays)) ? 0 : parseFloat(this.state.InvestigationDays)) + (isNaN(parseFloat(this.state.ConsultDays)) ? 0 : parseFloat(this.state.ConsultDays)) <= (isNaN(parseFloat(this.state.EstimatePeriod)) ? 0 : parseFloat(this.state.EstimatePeriod))) {
            return true
        }
        else {
            return false
        }
    }

    CalculateAmounts = () => {
        this.setState({
            BedTotal: isNaN(parseFloat(this.state.BedDays) * parseFloat(this.state.BedCharges)) ? 0 : ((parseFloat(this.state.BedDays) * parseFloat(this.state.BedCharges))).toFixed(2),
            InvestTotal: isNaN(parseFloat(this.state.InvestigationDays) * parseFloat(this.state.InvestigationCharges)) ? 0 : (parseFloat(this.state.InvestigationDays) * parseFloat(this.state.InvestigationCharges)).toFixed(2),
            ConsultTotal: isNaN(parseFloat(this.state.ConsultDays) * parseFloat(this.state.ConsultCharges)) ? 0 : (parseFloat(this.state.ConsultDays) * parseFloat(this.state.ConsultCharges)).toFixed(2),
            OpreationTotal: this.state.OperationCharges == "" ? 0 : this.state.OperationCharges,
            OthersTotal: this.state.OthersCharges == "" ? 0 : this.state.OthersCharges,
        }, () => {
            this.setState({
                IsDaysCountValid: this.CalulateDaysCount()
            })
        })
    }

    PrintBill = () => {
        if ((parseFloat(this.state.BedTotal) + parseFloat(this.state.InvestTotal) + parseFloat(this.state.ConsultTotal) + parseFloat(this.state.OpreationTotal) + parseFloat(this.state.OthersTotal)) != 0 &&
            !(this.state.BedDays == "" && this.state.BedCharges != "") &&
            !(this.state.BedCharges == "" && this.state.BedDays != "") &&
            !(this.state.ConsultDays == "" && this.state.ConsultCharges != "") &&
            !(this.state.ConsultCharges == "" && this.state.ConsultDays != "") &&
            !(this.state.InvestigationDays == "" && this.state.InvestigationCharges != "") &&
            !(this.state.InvestigationCharges == "" && this.state.InvestigationDays != "") && (this.state.ProvDiag != "") &&
            (this.state.EstimatePeriod != "") &&
            (this.state.IsDaysCountValid === undefined ? true : this.state.IsDaysCountValid)
        ) {
            var service_url = Constants.ESTIMATION_BILLING;
            var data = {
                "created_date": moment(new Date()).format("DD-MM-YYYY"),
                "patient_name": this.state.PatientName,
                "patient_id":this.state.patientProfileDetails.patient_id,
                "doctor_name": this.state.patientProfileDetails.admitting_consultant,
                "gender": this.state.patientProfileDetails.patient_gender,
                "age": this.state.patientProfileDetails.patient_age,
                "clinic": this.state.ClinicName,
                "est_period": this.state.EstimatePeriod,
                "pro_diagnosis": this.state.ProvDiag,
                "uhid": this.state.UHID,
                "bed_charge": isNaN(parseFloat(this.state.BedCharges)) ? 0 : parseFloat(this.state.BedCharges),
                "bed_days": isNaN(parseFloat(this.state.BedDays)) ? 0 : parseFloat(this.state.BedDays),
                "op_charge": isNaN(parseFloat(this.state.OperationCharges)) ? 0 : parseFloat(this.state.OperationCharges),
                "inv_charge": isNaN(parseFloat(this.state.InvestigationCharges)) ? 0 : parseFloat(this.state.InvestigationCharges),
                "inv_days": isNaN(parseFloat(this.state.InvestigationDays)) ? 0 : parseFloat(this.state.InvestigationDays),
                "con_fee": isNaN(parseFloat(this.state.ConsultCharges)) ? 0 : parseFloat(this.state.ConsultCharges),
                "con_days": isNaN(parseFloat(this.state.ConsultDays)) ? 0 : parseFloat(this.state.ConsultDays),
                "other_charge": isNaN(parseFloat(this.state.OthersCharges)) ? 0 : parseFloat(this.state.OthersCharges),
                "total": this.returnTotal(),
            }
            OpthamologyService.getInstance().documentUploadPostAPI(
                service_url,
                data,
                this.getPrintSuccess,
                this.getPrintFailure,
                "pdf"
            );
        }
        else {
            if ((parseFloat(this.state.BedTotal) + parseFloat(this.state.InvestTotal) + parseFloat(this.state.ConsultTotal) + parseFloat(this.state.OpreationTotal) + parseFloat(this.state.OthersTotal)) == 0) {
                this.props.showResposeValue('error', "Enter Atleast one Charges !");
            }
            else if (this.state.ProvDiag == "") {
                this.props.showResposeValue('error', "Enter Provissional Diagnosis !");
            }
            else if (this.state.EstimatePeriod == "") {
                this.props.showResposeValue('error', "Enter Estimation Period in Days !");
            }
            else {
                this.props.showResposeValue('error', "Enter Correct Charges or Days !");
            }

        }
    }

    getPrintSuccess = (response) => {
        if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    };


    getPrintFailure = error => {
        this.props.showResposeValue('error', "Something Went Wrong !");
    };

    returnTotal = ()=>{
        return (((this.state.BedTotal == 0 ? 0 : parseFloat(this.state.BedTotal)) + 
        (this.state.InvestTotal == 0 ? 0 :parseFloat(this.state.InvestTotal)) + 
        (this.state.ConsultTotal == 0 ? 0 :parseFloat(this.state.ConsultTotal)) + 
        (this.state.OpreationTotal == 0 ? 0 :parseFloat(this.state.OpreationTotal)) + 
        (this.state.OthersTotal == 0 ? 0 :parseFloat(this.state.OthersTotal))).toFixed(2))
    }
    renderTable = () => {
        return (
            <View>
                <View style={styles.tableMainView}>
                    <View style={[styles.TableTitleContainer]}>
                        <View style={{ width: '60%' }} >
                            <Text style={[styles.TableHeadText, { paddingLeft: '1vw' }]}>Provisionals</Text>
                        </View>
                        <View style={{ width: '10%' }} >
                            <Text style={styles.TableHeadText}>Day(s)</Text>
                        </View>
                        <View style={{ width: '15%' }} >
                            <Text style={styles.TableHeadText}>Charges Per Day (₹)</Text>
                        </View>
                        <View style={{ width: '15%' }} >
                            <Text style={[styles.TableHeadText, { paddingLeft: '1vw' }]}>Amount (₹)</Text>
                        </View>
                    </View>

                    <View style={styles.ItemRowsView}>
                        <View style={{ height: '3vw', width: '60%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsText}>Bed Charges</Text>
                        </View>
                        <View style={{ height: '3vw', width: '10%' }}>
                            <TextInput
                                value={this.state.BedDays}
                                maxLength={3}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ BedDays: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.BedDays == "" && this.state.BedCharges != "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%' }}>
                            <TextInput
                                maxLength={7}
                                value={this.state.BedCharges}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ BedCharges: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.BedCharges == "" && this.state.BedDays != "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsAmount}>{this.state.BedTotal == 0 ? "-" : this.state.BedTotal}</Text>
                        </View>
                    </View>

                    <View style={styles.ItemRowsView}>
                        <View style={{ height: '3vw', width: '60%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsText}>Operation Charges</Text>
                            <Text style={styles.itemsmallText}>( Inclusive of OT Charge, Gas, Sundry, Sergon's Team, Consumables, Implants etc... )</Text>
                        </View>
                        <View style={{ height: '3vw', width: '10%' }}>

                        </View>
                        <View style={{ height: '3vw', width: '15%' }}>
                            <TextInput
                                style={[styles.TextInputSmall]}
                                value={this.state.OperationCharges}
                                maxLength={7}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ OperationCharges: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsAmount}>{this.state.OpreationTotal== 0 ? "-": this.state.OpreationTotal}</Text>
                        </View>
                    </View>

                    <View style={styles.ItemRowsView}>
                        <View style={{ height: '3vw', width: '60%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsText}>Investigation Charges</Text>
                        </View>
                        <View style={{ height: '3vw', width: '10%' }}>
                            <TextInput
                                value={this.state.InvestigationDays}
                                maxLength={3}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ InvestigationDays: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.InvestigationDays == "" && this.state.InvestigationCharges != "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%' }}>
                            <TextInput
                                value={this.state.InvestigationCharges}
                                maxLength={7}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ InvestigationCharges: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.InvestigationCharges == "" && this.state.InvestigationDays != "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsAmount}>{this.state.InvestTotal == 0 ? "-" : this.state.InvestTotal}</Text>
                        </View>
                    </View>

                    <View style={styles.ItemRowsView}>
                        <View style={{ height: '3vw', width: '60%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsText}>Consultation Fee</Text>
                        </View>
                        <View style={{ height: '3vw', width: '10%' }}>
                            <TextInput
                                value={this.state.ConsultDays}
                                maxLength={3}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ ConsultDays: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.ConsultDays == "" && this.state.ConsultCharges != "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%' }}>
                            <TextInput
                                value={this.state.ConsultCharges}
                                maxLength={7}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ ConsultCharges: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: ((this.state.ConsultDays != "" && this.state.ConsultCharges == "")) ? color.red : color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsAmount}>{this.state.ConsultTotal == 0 ? "-" : this.state.ConsultTotal}</Text>
                        </View>
                    </View>

                    <View style={[styles.ItemRowsView, { borderBottomWidth: 0 }]}>
                        <View style={{ height: '3vw', width: '60%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsText}>Other Charges</Text>
                            <Text style={styles.itemsmallText}>( eg. Oxygen, Private Sister, Ventilator, Syringe Pump, Medicine, Iv, Injections, Disposables, Physho Therapy, Treatment Other Equipments. )</Text>
                        </View>
                        <View style={{ height: '3vw', width: '10%' }}>

                        </View>
                        <View style={{ height: '3vw', width: '15%' }}>
                            <TextInput
                                value={this.state.OthersCharges}
                                maxLength={7}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        this.setState({ OthersCharges: text }, () => { this.CalculateAmounts() })
                                    }
                                }}
                                style={[styles.TextInputSmall, { borderColor: color.boxColor }]}
                            />
                        </View>
                        <View style={{ height: '3vw', width: '15%', display: 'flex', justifyContent: 'center' }}>
                            <Text style={styles.ItemsAmount}>{this.state.OthersTotal == 0 ? "-" : this.state.OthersTotal}</Text>
                        </View>
                    </View>

                </View>
                <Text style={styles.TotalAmount}>{`Total Amount :  ₹ ${this.returnTotal()}`}</Text>
                <View >
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "1.5vw",
                            flexDirection: 'row-reverse'
                        }}
                    >
                        <TouchableOpacity

                            backgroundColor={color.themeDark}
                            onPress={() => {
                                this.PrintBill();
                            }}
                            style={[styles.BtnTouch, { backgroundColor: color.themeDark }]}
                        >
                            <Text style={styles.ButtonText}>{"Print Bill"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { this.ClearClicked() }}
                            style={styles.BtnTouchClear}
                        >
                            <Text style={styles.ButtonTextClear}>{"Clear"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }


    render() {
        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: "row",
                    backgroundColor: color.applicationBackgroundColor,
                }}
            >
                <View style={{ width: "100%", height: screenHeight - 65 }}>
                    <NavigationTopHeader
                        changeTabSection={this.changeTabSection.bind(this)}
                        navigationHeaderList={
                            this.props.isIpPatientCheckouted
                                ? navigationHeaderList
                                : this.state.isCurrentPageIsIPPatientAppointment
                                    ? navigationHeaderListIfIPCreateBill
                                    : navigationHeaderList
                        }
                        selectedTab={"estimationBill"}
                    />
                    <ScrollView
                        style={{ width: "100%" }}
                        bounces={false}
                        bouncesZoom={false}
                    >
                        <View style={styles.LeftMainView}>
                            {this.renderTextFields()}
                            {this.renderTable()}
                        </View>
                    </ScrollView>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    LeftMainView: {
        height: "38vw",
        margin: "0.2vw",
        marginTop: "1vw",
        marginLeft: "0.7vw",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2,
        borderRadius: "0.25vw",
        backgroundColor: "white",
    },
    tableMainView: {
        height: "25vw",
        width: '97%',
        margin: "0.2vw",
        marginTop: "0.5vw",
        marginLeft: "1.4vw",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2,
        borderRadius: "0.25vw",
        backgroundColor: "white",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TextInputSmall: {
        fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: '0.26vw', height: '2.2vw', marginTop: '0.5vw', width: "5vw", marginRight: '2vw'
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    ItemsText: {
        fontSize: '1vw',
        fontWeight: '500',
        paddingLeft: '1vw'
    },
    itemsmallText: {
        fontSize: '0.8vw',
        paddingLeft: '1vw',

    },
    ItemRowsView: {
        height: '4.5vw', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottomColor: "#888888", borderBottomWidth: "1px",
    },
    ItemsAmount: {
        fontSize: '0.9vw',
        fontWeight: '500',
        paddingLeft: '1vw',
    },
    TotalAmount: {
        fontSize: '1vw',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: '70vw',
        paddingTop: '1vw',
    },
    TableHeadText: {
        fontSize: "0.91vw",
        color: "black",
        fontWeight: "500",
    },
    TableDataText: {
        fontSize: "0.91vw",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: "0.4vw",
    },
    BtnTouch: {
        paddingTop: "0.5vw",
        paddingLeft: "1.1vw",
        paddingRight: "1.1vw",
        paddingBottom: "0.5vw",
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: "10vw",
        marginRight: '1vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: "center",
        fontSize: ".85vw",
        fontWeight: '500'
    },
    BtnTouchClear: {
        paddingTop: "0.5vw",
        paddingLeft: "1.1vw",
        paddingRight: "1.1vw",
        paddingBottom: "0.5vw",
        borderColor: color.themeDark,
        borderWidth: 1,
        borderRadius: ".25vw",
        width: "10vw",
        marginRight: '2vw'
    },
    ButtonTextClear: {
        color: color.themeDark,
        textAlign: "center",
        fontSize: ".85vw",
        fontWeight: '500'
    }
});

