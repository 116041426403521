//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, ScrollView, Dimensions, TouchableOpacity } from "react-native";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import BaseComponentStyle from "../../BaseComponentStyle";
import moment from "moment";
import { color } from '../../../../styles/Color';
import Icon from "react-native-vector-icons/FontAwesome";
const screenHeight = Dimensions.get("window").height;

var fulldate = new Date();
var converted_date = moment(fulldate).format("YYYY-MM-DD");
// create a component
class PrintOrder extends BaseComponentStyle {

  constructor(props) {
    super(props);

    this.state = {
      startDate: converted_date,
      endDate: converted_date,
      selectedSearch: {},


      patient_id: this.props.patient_id ? this.props.patient_id : this.props.modificationBillDetails.patient_id ? this.props.modificationBillDetails.patient_id : this.props.otPatientDetails.patient_id,
      appointment_id: this.props.appointment_id ? this.props.appointment_id : this.props.modificationBillDetails.appointment_id,
      patientAppointment: this.props.patientDetails.doctor_id ? this.props.patientDetails.doctor_id : this.props.modificationBillDetails.doctor_id,
      patientDetails: this.props.patientDetails,
      modificationBillDetails: this.props.modificationBillDetails,

      // patient_id: "14218",
      allData: [],
      selectedOrderType: "All",
      userType: this.props.userType,
      selectedClinic: this.props.selectedClinic,
      printOrdersList: [],
      otPatientDetails: this.props.otPatientDetails
    };
  }

  componentDidMount() {
    this.getPrintOrders();
  }
  componentWillReceiveProps(props){
    if(props && props.searchType == "isPrintOrder" &&(( props.startDate !== this.state.startDate ||  this.state.endDate !== props.endDate)|| (this.state.selectedOrderType != props.selectedOrderType)) ){
      this.setState({ 
        startDate: props.startDate,
        endDate: props.endDate,
        selectedOrderType: props.selectedOrderType  
      },()=>{
        this.props.clearSearchType("")
        this.getPrintOrders();
      })
    }
  }
  getPrintOrders = () => {


    var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : converted_date
    var LastDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : converted_date
    let doctor_id = this.state.patientDetails.doctor_id ? this.state.patientDetails.doctor_id : this.state.modificationBillDetails.doctor_id ?  this.state.modificationBillDetails.doctor_id : this.state.otPatientDetails.doctor_id

    if(Constants.ROLE_FO === this.state.userType) {

      var service_url = Constants.PRINT_ORDER + this.state.patient_id + "/test-orders/" + "?from_date=" + startDate + "&to_date=" + LastDate + "&test="+ this.state.selectedOrderType;

    } else if(this.state.userType === "OT") {
      
      let patient_id = this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : ""
      let doctor_id = this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : ""

      var service_url = Constants.PRINT_ORDER + patient_id + "/test-orders/" + "?doctor_id=" + doctor_id + "&from_date=" + startDate + "&to_date=" + LastDate + "&test="+ this.state.selectedOrderType;

    } else {

      var service_url = Constants.PRINT_ORDER_DO + "?patient_id=" + this.state.patient_id   + "&clinic_id=" + this.state.selectedClinic.id + "&from_date=" + startDate + "&to_date=" + LastDate + "&test="+ this.state.selectedOrderType;

    }
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getReportOrdersSuccess,
      this.getReportOrdersFailure
    );
  }

  getReportOrdersSuccess = (response) => {
    if (response.status == "success") {
      // alert(JSON.stringify(response.data))
      this.setState({
        printOrdersList: response.data
      })
    }
  }

  getReportOrdersFailure = error => { }

  onPressButton(key, selectedvalue) {
    if (key == "Search") {
      this.getPrintOrders();
    } else if (key == "clear") {
      this.setState({
        startDate: "",
        endDate: ""
      },()=>{
        this.getPrintOrders();

      })
    }
    else if(key=="Print"){
      this.printOrderPrint()
    }
  }


  renderHistoryItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_name}
              </Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>

        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };


  printOrderPrint = (test_order) => {

    var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : converted_date
    var LastDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : converted_date
    let doctor_id = this.state.patientDetails.doctor_id ? this.state.patientDetails.doctor_id : this.state.modificationBillDetails.doctor_id

    if(Constants.ROLE_FO === this.state.userType) {

      var service_url = Constants.PRINT_ORDER + this.state.patient_id + "/test-orders/" + "?from_date=" + startDate + "&to_date=" + LastDate + "&test="+ test_order + '&export_type=pdf';

    } else {

    var service_url = Constants.PRINT_ORDER_DO + "?patient_id=" + this.state.patient_id  + "&clinic_id=" + this.state.selectedClinic.id + '&appointment_id=' + this.state.appointment_id +  "&from_date=" + startDate + "&to_date=" + LastDate + "&test="+ test_order +"&export_type=pdf";

    }
    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      this.printOrderPrintSuccess,
      this.printOrderPrintFailure,
      "pdf"
    );
  };

  printOrderPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      // console.log(file)
      window.open(fileURL);
    }
  };

  printOrderPrintFailure = error => {
    // console.log("Get clinic list error response");
    console.log(error);
  };
  renderPrintOrdersHeader = () => {
    return(
      <View>
          <CommonHistorySectionHeader
                heading1={"Date"}
                heading2={"Doctor Name"}
                heading3={"Order Type"}
                heading4={"Action"}
                noOfColumn={4}
                columnSize={[0.25, 0.25, 0.25, 0.25]}
        /> 
      </View>
    )
  }
  renderPrintOrdersData = () => {
    return(
     <View>
      { this.state.printOrdersList && this.state.printOrdersList.length > 0 && this.state.printOrdersList.map((item,index)=>{
        return(
          <View style= {[styles.TableContent, { borderBottomColor: color.lightGray }]}>
           <Text style={[styles.TableContentText, {flex: 0.25, marginLeft: '1.2vw'}]}>{item.appointment_date}</Text>
           <Text style={styles.TableContentText}>{item.doctor_name}</Text>
           <Text style={styles.TableContentText}>{item.order}</Text>
           <TouchableOpacity 
             style={{ flex: 0.25 }}
             onPress = {()=>{
              this.printOrderPrint(item.order)
             }}
           >
              <Text style={{color: color.themeDark, fontSize: '1vw'}}>Print</Text>
           </TouchableOpacity>
          </View>
        )
       })
      }
     </View>
    )          
  }
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.PrintOrderContainer}>
          <View>
            <Text style={[styles.PrintOrderHead, { color: color.themeDark }]}>
              Print Orders
            </Text>
          </View>
          {this.renderPrintOrdersHeader()}
          {this.renderPrintOrdersData()}
        </View>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    // marginTop: 20,
    backgroundColor: color.sectionHeadingDarkColor,
    minHeight : '70vh',
    padding: (5,10,10,5)
  },
  PrintOrderContainer: { 
    flex: 1, 
    // marginTop: '1.5vw', 
    // marginLeft: '1.2vw', 
    backgroundColor: "#fff", 
    padding: '1.2vw' 
  },
  PrintOrderHead: {
    fontSize: '1.2vw', 
    fontWeight: '500', 
    margin: '0.6vw' 
  },
  TableContent: { 
    flex: 1, 
    flexDirection: "row", 
    paddingVertical: '1vw', 
    borderBottomWidth: 1
  },
  TableContentText: {
    flex: 0.25,
    fontSize: '1vw'
  }
  
  
});
export default PrintOrder;
