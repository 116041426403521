//import liraries
import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
const screenHeight = Dimensions.get("window").height;

import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import { OvulationChart } from "./OvulationChart";
import { OvulationChartNew } from "./OvulationChartNew";
import Arrow from "react-native-vector-icons/FontAwesome5";
import {NavigationTopHeader} from "../../BaseComponent"
const navigationHeaderList=[
  {label:"Ovulation chart",value:"ovulationChart"}
]
// create a component
export class Ovulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,

      scrollPosition: 0,
      selectedSection: "ovulationChart",
      refreshRighSideComponentName: "",
      getDataByDateValues: {},
      editHistoryItem: {},
      responseType: "",
      responseMeaasge: "",
      hideRemoveIcon:false,
      isShowDelete: false,
      newItemclear:false
    };
  }

  _onContentSizeChange() {
    let initialYScroll = this.state.scrollPosition;
    this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
  }

  selectedRightSideView(pageName) {
    this.setState({
      selectedSection: pageName,
    });
  }

  getDataByDateValues(data,hideRemoveIcon = false) {
    this.setState({
      getDataByDateValues: data,
      hideRemoveIcon:hideRemoveIcon
    });
  }

  refreshRighSideComponent(name) {
    this.setState({
      refreshRighSideComponentName: name,
    });
  }

  editHistory(item, pageName, lmd, dov) {
    var data = {
      pageName: pageName,
      editItem: item,
      lmd: lmd,
      dov: dov,
    };

    this.setState(
      {
        editHistoryItem: data,
      }
    );
  }

  renderNewItemClear(flag) {
    this.setState({
      newItemclear:flag
    });
  }

//   renderHeaderView = () => {
//     let { patientInfo, patientAppointment } = this.state;
//     return (
//       <View
//         style={{
//           width: "100%",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           borderBottomColor: "#E4E8F1",
//           borderBottomWidth: 1,
//           paddingHorizontal: 10,
//           paddingVertical: 15
//         }}
//       >
//         <View style={{ flexDirection: "row" }}>
//           <View style={{ alignSelf: "center", marginLeft: 5 }}>
//             <Arrow
//               name="arrow-left"
//               size={21}
//               color={color.black}
//               onPress={() => {
//                 this.props.changeScreen('optDoctorNotes')
//               }}
//             />
//           </View>
//           <View style={{ marginLeft: 20, flexDirection: 'row' }}>
            
//             <Text style={{ fontSize: 16, alignSelf: "center" }}>{patientInfo.name || ""}</Text>
//             {/* <View style={{marginLeft:10}}> */}
//             <Text
//               style={{
//                 fontSize: 10,
//                 color: "#ADB4C1",
//                 marginTop: 5,
//                 marginBottom: 5,
//                 marginLeft: 10,
//                 alignSelf: "center"
                
//               }}
//             >
//               {patientInfo.age || ""} {patientInfo.age ? "|" : null} {patientInfo.dob || "" } {patientInfo.dob ? "|"  : null}
//               &nbsp;{ patientInfo.mobile_number || "" } { patientInfo.mobile_number ? "|" : null}{patientInfo.name ?patientAppointment.appointment_start_time || "" : null}
//             </Text>
//             <Text style={{ fontSize: 10, color: "#ADB4C1", marginLeft: 10, alignSelf: "center" }}>
//               {patientInfo.last_visit_date ? "Last Visit Date :" : null} {patientInfo.last_visit_date || ""}
//             </Text>
//           </View>
//         </View>
//       </View>
//     );
// }


  renderRightSideContent() {
    return (
      <View>
                            <View style={Style.doctorNotesSectionView}>
        <OvulationChart
          //  editHistoryItem={this.state.editHistoryItem}
          //  patientAppointment = {this.state.patientAppointment}
          //  patientInfo = {this.state.patientInfo}
          //  selectedPageName={this.state.selectedSection}
          //  selectedRightSideView={this.selectedRightSideView.bind(this)}
          //  editHistory={this.editHistory.bind(this)}
          //  refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
          //  refreshRighSideComponentName={this.state.refreshRighSideComponentName}
          selectedPageName={this.state.selectedSection}
          patientAppointment={this.state.patientAppointment}
          patientInfo={this.state.patientInfo}
          selectedRightSideView={this.selectedRightSideView.bind(this)}
          getUpdatedChartValue={this.state.getDataByDateValues}
          getUpdatedChartValueChange={this.getDataByDateValues.bind(this)}
          isShowDelete = {this.state.isShowDelete}
          editHistory={this.editHistory.bind(this)}
          refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
          refreshRighSideComponentName={this.state.refreshRighSideComponentName}
          renderNewItemClear={this.renderNewItemClear.bind(this)}
          hideRemoveIcon={this.state.hideRemoveIcon}
        />
        </View>
      </View>
    );
  }

  successAlert() {
    return (
      <View
        style={{
          flex: 1,
          zIndex: 3,
          width: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          backgroundColor: "#DEF2D6",
          borderRadius: 5,
          borderColor: "#B8C7B2",
          borderWidth: 1,
          height: 70,
          marginRight: 10,
          marginTop: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
            flex: 0.95,
          }}
        >
          <Success size={25} name={"check"} color={"green"} />
          <Text
            style={{
              fontWeight: "700",
              color: "green",
              fontSize: 12,
              marginLeft: 15,
            }}
          >
            {"Success: "}
            <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>
              {this.state.responseMeaasge}
            </Text>
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.setState({ responseType: "" });
          }}
          style={{
            flex: 0.05,
            marginRight: 15,
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <Icon size={25} name={"close"} color={"#B2C6AA"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {
    return (
      <View
        style={{
          flex: 1,
          zIndex: 3,
          width: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          backgroundColor: "#EBC8C4",
          borderRadius: 5,
          borderColor: "#A58B8A",
          borderWidth: 1,
          height: 70,
          marginRight: 10,
          marginTop: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
            flex: 0.95,
          }}
        >
          <Icon size={25} name={"ban"} color={"#D26560"} />
          <Text
            style={{
              fontWeight: "700",
              color: "#D26560",
              fontSize: 12,
              marginLeft: 15,
            }}
          >
            {"Error: "}
            <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>
              {this.state.responseMeaasge}
            </Text>
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.setState({ responseType: "" });
          }}
          style={{
            flex: 0.05,
            marginRight: 15,
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <Icon size={25} name={"close"} color={"#C69A99"} />
        </TouchableOpacity>
      </View>
    );
  }

  showResposeValue(type, meaasge) {
    this.setState(
      {
        responseType: type,
        responseMeaasge: meaasge,
      },
      () => {
        setTimeout(() => {
          this.setState({ responseType: "", responseMeaasge: "" });
        }, 3000);
      }
    );
  }
  changeTabSection(selectedTab){
    this.setState({
        selectedSection:selectedTab
    })
  }

  render() {
    return (
      <View style={{height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor}}>
      <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>
        {/* {this.renderHeaderView()} */}
          <View>
            <NavigationTopHeader
              changeTabSection={this.changeTabSection.bind(this)}
              navigationHeaderList={navigationHeaderList}
              selectedTab={this.state.selectedSection} />
          </View>
          <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{ width: "100%", marginBottom: 50 }}
            contentContainerStyle={{ flex: 1 }}
            bounces={false}
            bouncesZoom={false}
          >
            {this.renderRightSideContent()}
          </ScrollView>
        </View>
        <View
          style={{paddingVertical:20,paddingLeft:20,  width: "35%", backgroundColor: color.themeShade, }}
        >
          {this.state.responseType == "success"
            ? this.successAlert()
            : this.state.responseType == "error"
            ? this.errorAlert()
            : null}
          <ScrollView
            ref={(scrollView) => (this.scrollView = scrollView)}
            onContentSizeChange={() => {
              this._onContentSizeChange();
            }}
            showsVerticalScrollIndicator={false}
            style={{ width: "100%",paddingRight: 10, }}
            contentContainerStyle={{ height:screenHeight-65 }}
          >
            <OvulationChartNew
              showResposeValue={this.showResposeValue.bind(this)}
              editHistoryItem={this.state.editHistoryItem}
              patientAppointment={this.state.patientAppointment}
              patientInfo={this.state.patientInfo}
              editHistory={this.editHistory.bind(this)}
              refreshRighSideComponent={this.refreshRighSideComponent.bind(
                this
              )}
              getDataByDateValues={this.getDataByDateValues.bind(this)}
              renderNewItemClear={this.renderNewItemClear.bind(this)}
              newItemclear={this.state.newItemclear}

            />
          </ScrollView>
        </View>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});

//make this component available to the app
