//import liraries
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, ScrollView, TextInput, Image, TouchableWithoutFeedback } from 'react-native';
import { CheckBox } from 'react-native-elements';
import Style from "../../../../styles/Style";
import { color } from '../../../../styles/Color';
import { CommonButton, EditiAndDeleteForLineItem, CommonHistorySectionHeader, NavigationTopHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import * as ImagePicker from 'expo-image-picker';
import { Touchable } from 'react-native';
import { Tooltip } from 'antd';

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

// create a component

// const navigationHeaderList = [
//     { label: "Result", value: "laboratoryResult" },
//     { label: "Previous Reports ", value: "laboratoryPreviousResult" },
//     { label: "Create Bill", value: "laboratoryCreateBill" },
//     { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
//   ]
// const images=[
//     {uri:require("../../../../../assets/images/HomeScreenImages.Down.png")},
//     {uri:require("../../../../../assets/images/HomeScreenImages/Delibered.png")}

// ]

export class LaboratoryResult extends Component {

    constructor(props) {
        super(props);
        this.state = {


            selectedLabPatientDetails: this.props.selectedLabPatientDetails,

            patientID: this.props.selectedLabPatientDetails.patient_id,
            billSummaryID: this.props.selectedLabPatientDetails.bill_summary_id,

            getListOFLabSet: [],
            getListOFTestConfiguration: {},
            isDisable: false,
            isSave: false,
            isSaveClicked:false,

            isResult: false,

            userType: this.props.userType,

            uploadedImages:[],
            showTestUploadPopupFlag:false,

            isTransmitted: false,

            modificationBillDetails: this.props.modificationBillDetails,
            selectedIpPatientDetails: this.props.selectedIpPatientDetails
        }
    }

    renderHeader() {
        return (
            <Text style={{ fontSize: 17, color: color.themeDark, fontWeight: '900' }}>Result</Text>
        )
    }

    componentDidMount() {

        this.getLaboratoryResults()
        
    }

    renderResultHeader() {
        return (
            <View style={{ zIndex: -1, marginTop: 5, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.3, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Lab Set"}</Text></View>
                <View style={{ flex: 0.3 }}><Text style={styles.headerFontStyle}>{"Test Name"}</Text></View>
                <View style={{ flex: 0.15, }}><Text style={styles.headerFontStyle}>{"Result"}</Text></View>
                <View style={{ flex: 0.20 }}><Text style={styles.headerFontStyle}>{"Normal Range"}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.headerFontStyle}>{"Unit"}</Text></View>
            </View>
        )
    }

    // renderResultList(item, labSetName, index, setName) {
    //     return (
    //         <View style={{ zIndex: -1, marginTop: 5, marginBottom: 5, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
    //             <View style={{ flex: 0.3, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{labSetName}</Text></View>
    //             {/* //----- change name */}
    //             <View style={{ flex: 0.3 }}><Text style={styles.headerFontStyle}>{item.labtestname ? item.labtestname : item.name}</Text></View>
    //             <View style={{ flex: 0.15 }}>
    //                 <TextInput
    //                     editable={this.state.isResult}
    //                     onChangeText={(text) => {

    //                     }}
    //                     value={item.result ? item.result : ""} style={{ textAlignVertical: "center", textAlign: 'center', height: 30, width: 70, borderRadius: 4, borderWidth: 1, borderColor: "#888888" }} />
    //             </View>
    //             <View style={{ flex: 0.20 }}><Text style={styles.headerFontStyle}>{item.normal_range}</Text></View>
    //             <View style={{ flex: 0.1 }}><Text style={styles.headerFontStyle}>{item.unit}</Text></View>
    //         </View>
    //     )
    // }


    renderResultList=(item, lab_index, enableLab=true)=>{

        let { getListOFTestConfiguration, outsourcedLabs, isResult } = this.state
        // let enableLab = outsourcedLabs[lab_index]?outsourcedLabs[lab_index]:true
        let isEditable = isResult?false:enableLab;

        let formattedData = []
        
        if( getListOFTestConfiguration.length > 0 ){
            formattedData = getListOFTestConfiguration.filter((group)=>{
                console.log("formatted data Loop -> ", group)
            
                return group.data && group.data.length>0 && group.data[0].lab_set_id==item.lab_set_id
            })
    
            
        }

        console.log("formatted data", formattedData)
        console.log("formatted data Test ", getListOFTestConfiguration)

        return(
            
            <View style={{ zIndex: -1, marginTop: 5, marginBottom: 5, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>

                <View style={{ flex: 0.3, paddingLeft: 15, flexDirection: 'row', justifyContent: 'flex-start', alignContent: "center" }}>
                    <View style={{ alignItems: 'flex-start'}} >
                        <CheckBox
                            title=""
                            checked={outsourcedLabs[lab_index]}
                            onPress={() => 
                                {
                                    outsourcedLabs[lab_index] = !outsourcedLabs[lab_index]
                                    this.setState({ outsourcedLabs: outsourcedLabs })
                                }
                            } 
                            style={{ width: 20 }}
                        />
                    </View>
                    <View style={{ justifyContent: "center" }} >
                        <Text style={[styles.headerFontStyle, { color: isEditable?"black":"#888888" }]}>{item.name || item.lab_set__name}</Text>
                    </View>
                </View>
                <View style={{ flex: 0.7, flexDirection: "column" }}>
                    
                    {formattedData && formattedData.length>0 && formattedData.map((eachGroup, group_index)=>{
                        return(
                        <>
                            <View style={{ flex: 1, flexDirection: "row" }}>
                                <Text style={{ fontWeight: "bold", marginVertical: 5, color: isEditable?"black":"#888888" }}>{eachGroup.group_name}</Text>
                            </View>

                            {eachGroup.data && eachGroup.data.map((data, lineIndex)=>{

                                let ranges = data.ranges && data.ranges.length>0?data.ranges[0]:{}
                                let range_first_val = ranges && ranges.range_value && ranges.range_value.length>0?ranges.range_value[0]:{}

                                return(
                                    
                                    <View key={lineIndex} style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginVertical: 5 }}>
                                        <View style={{ flex: 0.4 }}><Text style={[styles.headerFontStyle, { color: isEditable?"black":"#888888" }]}>{data.labtestname || data.name}</Text></View>
                                        <View style={{ flex: 0.21 }}>
                                            <TextInput
                                                editable={isEditable}
                                                onChangeText={(text) => {
                                                    
                                                    formattedData[group_index]['data'][lineIndex]['result'] = text
                                                    this.setState({ formattedData })
                                                }}
                                                value={data.result ? data.result : ""} 
                                                style={{ textAlignVertical: "center", textAlign: 'center', height: 30, width: 70, borderRadius: 4, borderWidth: 1, borderColor: isEditable ?"black":"#888888" }} />
                                        </View>
                                        <View style={{ flex: 0.21 }}>
                                            <Tooltip title= {data.normal_range?data.normal_range : "-" }><Text multiline={2} ellipsizeMode="tail" style={[styles.headerFontStyle, { color: isEditable?"black":"#888888" }]}>
                                                {data.normal_range?data.normal_range : "-" }
                                                {/* {range_first_val?range_first_val.min_range+" - "+range_first_val.max_range:"-"} */}
                                            </Text></Tooltip>
                                        </View>
                                        <View style={{ flex: 0.18 }}><Text style={[styles.headerFontStyle, { color: isEditable?"black":"#888888" }] }>{data.unit}</Text></View>
                                    </View>
                                )
                            })}

                        </>)}
                    )}
                                            
                </View>
            </View>
        )
    }

    onPressProduceResult() {
        this.setState({
            isResult: true
        })
        this.printBillingList()
    }

     onPressTransmitResult() {
        this.setState({
            isTransmitted: true
        },() => this.onPressSave())
     }

    renderFoterButton() {
        return (
            <View style={{ flexDirection: 'row', justifyContent: "center", marginBottom: 20 }}>
                    {!this.state.isSave?
                <View style={{ marginRight: 20 }}>
                    <CommonButton
                        item={{}}
                        disable={this.state.isSaveClicked}
                        selectedvalue={{}}
                        butttonText={"Save"}
                        buttonType={this.state.isSaveClicked ? "outlineNonTheme" : "theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressSave.bind(this)}
                        buttonKey={"saveLabResult"} />
                </View>:null}
                {this.state.isDisable?
                <View style={{flexDirection:'row'}}>
                <View key={"ProduceResult"} style={{ marginRight: 20 }}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Produce Result"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressProduceResult.bind(this)}
                        buttonKey={"produceLabResult"} />
                </View>
                <View key={"TransmitResult"} style={{display: 'none'}}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Transmit Result"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressTransmitResult.bind(this)}
                        buttonKey={"transmitLabResult"} />
                </View>
                </View>:null}
            </View>
        )
    }


    renderListofResultRows(labSet, index) {

        let { outsourcedLabs, isResult } = this.state;
        let enableLab = outsourcedLabs[index]==undefined ? true:outsourcedLabs[index]
        let isEditable = isResult?false:enableLab

        return (

            <View key={"labset"+index} >
                {/*
                    everySingleResults && everySingleResults.length > 0 ?
                        everySingleResults.map((test, index) => {
                            // console.log("getListOFLabSet -----4> "+JSON.stringify(test))

                            // return this.renderResultList(test, index == 0 ? labSet.lab_set_name : "", index, labSet.lab_set_name)

                            return this.renderResultList(test, index == 0 ?
                                labSet.lab_set_name ? //----- change name
                                    labSet.lab_set_name    //----- change name
                                    : labSet.name           //----- change name

                                : "", index,

                                labSet.lab_set_name ? //----- change name
                                    labSet.lab_set_name    //----- change name
                                    : labSet.name           //----- change name
                            )

                        }) : null
                */}
                {this.renderResultList(labSet, index, enableLab)}
                <TextInput
                    editable={isEditable}
                    value={labSet.comments ? labSet.comments : ""}
                    maxLength={300}
                    onChangeText={(text) => {
                        var { getListOFLabSet } = this.state;
                        var labsetComments = getListOFLabSet[index]
                        labsetComments["comments"] = text;
                        this.setState({ getListOFLabSet })
                    }}
                    multiline={true}
                    numberOfLines={3}
                    style={{ marginHorizontal: 2, marginBottom: 15, padding: 10, height: 50, borderRadius: 4, borderWidth: 1, borderColor: "#888888" }} />
            </View>
        )
    }


    renderListOfLabResults() {
        let { getListOFLabSet } = this.state;
    
        return (
            <View>
                {
                    getListOFLabSet && getListOFLabSet.length > 0 ?
                        getListOFLabSet.map((item, index) => {
                            
                            return this.renderListofResultRows(item, index)
                        }):
                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                            <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
                        </View>
                }
            </View>
        )
    }

    changeTabSection(subtab) {
        // this.props.changeScreen(subtab,);
        this.props.changeScreen(subtab, {}, "LAB", {}, "", {}, "", {}, "", {}, false, true);

    }


    render() {
        return (
            <View style={{ backgroundColor: color.applicationBackgroundColor }}>
                <View style={{ backgroundColor: color.applicationBackgroundColor, marginBottom: 8 }}>
                    <NavigationTopHeader
                        changeTabSection={this.changeTabSection.bind(this)}
                        navigationHeaderList={this.props.labNavigationHeaderList}
                        selectedTab={"laboratoryResult"}
                    />
                </View>
                <ScrollView style={{ backgroundColor: color.applicationBackgroundColor, height: screenHeight - 132, paddingLeft: 20, paddingRight: 15 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

                    {/* {this.renderOPTResults()} */}
                    
                        <View style={{ borderRadius: 7, backgroundColor: color.white, marginHorizontal: 10, marginVertical: 15, paddingHorizontal: 15, paddingVertical: 30 }}>
                            {this.renderHeader()}
                            {this.renderResultHeader()}
                            {this.renderListOfLabResults()}
                            {this.renderFoterButton()}

                        </View>

                </ScrollView>
                {/* <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 10 }}>
                    <Image source={require("../../../../../assets/images/HomeScreenImages/Copyright.png")} style={{ height: 15, width: 15, color: color.lightGray, tintColor: color.lightGray }} />
                    <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
                </View>  */}

                {
                    this.state.showTestUploadPopupFlag ?
                        this.renderImageUploadView()
                        : null
                }
                {this.state.isImageViewPopup ?
                    this.renderImageViewPopup()
                    : null
                }


            </View>
        );
    }


    printBillingList = () => {
        let states = this.state;
        var service_url = Constants.LAB_RESULTS +
            "?patient_id=" + ( Object.keys(this.state.selectedLabPatientDetails).length > 0  ? this.state.selectedLabPatientDetails.patient_id : this.state.selectedIpPatientDetails.patient_id ) +
            "&bill_summary_id=" + ( Object.keys(this.state.selectedLabPatientDetails).length > 0 ? this.state.selectedLabPatientDetails.bill_summary_id : this.state.modificationBillDetails.bill_summary_id ) + "&export_type=pdf"


        OpthamologyService.getInstance().documentUploadGet(
            service_url,
            this.successprintBillingList,
            this.failureprintBillingList,
            "pdf"
        );
    }

    successprintBillingList = (response) => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            //   console.log(file)
            window.open(fileURL);
        }
    }

    failureprintBillingList = (error) => {

        console.log(error);
    }

    getLaboratoryResults() {
        var service_url = Constants.LAB_RESULTS +
            "?patient_id=" + ( Object.keys(this.state.selectedLabPatientDetails).length > 0  ? this.state.selectedLabPatientDetails.patient_id : this.state.selectedIpPatientDetails.patient_id ) +
            "&bill_summary_id=" + (Object.keys(this.state.selectedLabPatientDetails).length > 0 ? this.state.selectedLabPatientDetails.bill_summary_id : this.state.modificationBillDetails.bill_summary_id)

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getLaboratoryResultsSuccess,
            this.getLaboratoryResultsFailure
        );
    }
    getLaboratoryResultsSuccess = success => {
        if (success.status == "success") {

            let outsourcedLabs = []

            outsourcedLabs = success.lab_set && success.lab_set.length && success.lab_set.map((item)=>{return true})
            console.log("Test Cong -> ", success.test_configuration);
            this.setState({
                getListOFLabSet: success.lab_set,
                getListOFTestConfiguration: success.test_configuration,
                isDisable: success.show,
                isSave: success.delivered,

                outsourcedLabs: outsourcedLabs
            })
            console.log("Test Cong After-> ", this.state.getListOFTestConfiguration);
        } else {
            this.setState({
                getListOFLabSet: [],
                getListOFTestConfiguration: {}
            })
        }
    }
    getLaboratoryResultsFailure = error => {
        // alert(JSON.stringify(error))
    }


    onPressSave() {

        var service_url = Constants.LAB_RESULTS;

        var { getListOFLabSet, getListOFTestConfiguration, outsourcedLabs } = this.state;

        var getLabsetList = []
        var getLabConfigurationList = []

        /*
        for (const labSet of getListOFLabSet) {
            var prepareLabset = {
                "id": labSet.id ? labSet.id : "",
                "lab_set_id": labSet.lab_set_id,
                "comments": labSet.comments ? labSet.comments : ""
            }
            getLabsetList.push(prepareLabset)
        }
        */

        // for (const isOutsourced of outsourcedLabs) {
        outsourcedLabs.map((isOutsourced, index)=>{
            
            if( isOutsourced )
            {
            
                var prepareLabset = {
                    "id": getListOFLabSet[index].id ? getListOFLabSet[index].id : "",
                    "lab_set_id": getListOFLabSet[index].lab_set_id,
                    "comments": getListOFLabSet[index].comments ? getListOFLabSet[index].comments : ""
                }
                
                getLabsetList.push(prepareLabset)

                for (const groupItem of getListOFTestConfiguration) {
                    
                    for (const groupLineItem of groupItem.data) {

                        if ( getListOFLabSet[index].lab_set_id == groupLineItem.lab_set_id ) {

                            var prepareConfig = {
                                "id": groupLineItem.id ? groupLineItem.id : "",
                                "lab_set_id": groupLineItem.lab_set_id,
                                "lab_test_id": groupLineItem.lab_test_id,
                                "result": groupLineItem.result ? groupLineItem.result : ""
                            }
                            getLabConfigurationList.push(prepareConfig)
                        }
                    }
                }

            }
        })

        if( getLabsetList.length>0 && getLabConfigurationList.length>0)
        {
            this.setState({
                isSaveClicked: true
            })
            var data = {
                "patient_id": this.state.patientID,
                "bill_summary_id": this.state.billSummaryID,
                "is_transmit": this.state.isTransmitted,
                "lab_set": getLabsetList,
                "lab_test": getLabConfigurationList
            }

            // alert("test ");
            // console.log("test ==========> "+JSON.stringify(data));


            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveLaboratoryResultsSuccess,
                this.saveLaboratoryResultsFailure
            );
            this.setState({isTransmitted: false})
        } else {
            // alert("Enter lab results")
            this.props.showResposeValue("error", "Enter lab results")
        }
    }
    saveLaboratoryResultsSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.getLaboratoryResults()
            this.setState({
                isDisable: true
            })
        } else {
            this.setState({
                isSaveClicked: false
            })
            this.props.showResposeValue("error", success.message)
        }
    }

    saveLaboratoryResultsFailure = error => {
        this.setState({
            isSaveClicked: false
        })
        this.props.showResposeValue("error", error.message)
    }


    renderOPTResults=()=>{
        
        // console.log("Full Result", this.state.getFullResult)
        return (

            // </View>
            <View style={{ borderRadius: 7, backgroundColor: color.white, marginHorizontal: 10, marginVertical: 15, paddingHorizontal: 15, paddingVertical: 30 }}>
                {this.renderHeader()}
                {this.renderResultHeader()}
                {this.state.getListOFLabSet.length > 0 && this.state.getListOFLabSet.map((item, index) => {
                    return (
                        <View>
                            {this.renderResultList(item, index)}

                        </View>
                    )
                })

                }
                {this.renderFoterButton()}

            </View>
        )
    }

    renderOPTResultHeader() {
        return (
            <View style={{ marginTop: 5, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.2, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Lab Set"}</Text></View>
                <View style={{ flex: 0.25 }}><Text style={styles.headerFontStyle}>{"Test Name"}</Text></View>
                <View style={{ flex: 0.46, }}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
                {/* <View style={{ flex: 0. }}><Text style={styles.headerFontStyle}>{"Comment"}</Text></View> */}
            </View>
        )
    }
    renderListOfOPTLabResults() {
        let { getListOFLabSet } = this.state;
        // alert("getListOFLabSet -----> "+JSON.stringify(item))
        return (
            <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 0.33, fontSize: 12 }}>{"Lab set"}</Text>
                <Text style={{ flex: 0.33 }}>{"Blood Test"}</Text>

                <View>
                    {this.state.isImageUploadSave == false ?
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    showTestUploadPopupFlag: true

                                })
                            }}
                            style={{ flex: 0.33 }}
                        >
                            <Text style={{ flex: 0.46, color: color.themeDark }}>{"Upload"}</Text>
                        </TouchableOpacity> :
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    isImageViewPopup: true

                                })
                            }}
                        >
                            <Text style={{ flex: 0.46, color: color.themeDark }}>{"View"}</Text>
                        </TouchableOpacity>
                    }
                    {/* {
                    getListOFLabSet && getListOFLabSet.length > 0 ?
                        getListOFLabSet.map((item, index) => {
                            // console.log("getListOFLabSet -----2> "+JSON.stringify(item))
                            // return this.renderListofResultRows(item, index);
                        }) :
                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                            <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
                        </View>
                } */}
                </View>
            </View>
        )
    }

    renderAddNewImageView() {
        return (
            <TouchableOpacity onPress={() => { this.openImgeFolders() }} style={{ marginHorizontal: 5, height: 80, width: 80, borderRadius: 4, backgroundColor: "#F1F0EF", justifyContent: "center", alignContent: 'center', alignItems: "center", alignSelf: "center" }}>
                {/* <Image source={}/> */}

                <Text style={{ fontSize: 20 }}>+</Text>
            </TouchableOpacity>
        )
    }
    renderShowImage() {
        // console.log("Upload Image"+JSON.stringify(this.state.selectedImage.image))
        // console.log("SelectedImage"+JSON.stringify(this.state.selectedImage))
        return (
            <View>
                {Object.keys(this.state.selectedImage).length > 0 ?
                    <View>
                        <View style={{ height: 200, width: 200, borderWidth: 4, borderColor: color.themeDark, marginTop: 20, justifyContent: "center", alignSelf: "center" }}>
                            <Image source={{ uri: this.state.selectedImage.image }} style={{ height: 190, width: 190 }} />
                        </View>
                        <View>
                            <TextInput
                                placeholder={"Enter Your Comments"} value={this.state.comment} onChangeText={(text) => { this.setState({ comment: text }) }}
                                style={{ minWidth: 500, height: 45, borderWidth: 1, borderColor: color.lightGray, marginTop: 20, alignSelf: "center" }}
                            />
                        </View>
                    </View> : null


                }
            </View>


        )
    }

    renderAddedImages(image, index) {
        alert(JSON.stringify(this.state.uploadedImages))
        return (
            <View key={index} onPress={() => { }} style={{ backgroundColor: "#F1F0EF", marginHorizontal: 5, marginVertical: 5, height: 95, width: 95, borderRadius: 4, alignItems: "flex-end", borderColor: color.themeDark, borderWidth: 1 }}>
                <View style={{ zIndex: -1 }}>
                    <TouchableOpacity
                        onPress={() => {
                            var { uploadedImages } = this.state;
                            uploadedImages.splice(index, 1);
                            this.setState({
                                uploadedImages
                            })
                        }}

                        style={{ borderRadius: 50, backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center", zIndex: -2 }}>
                        <Text style={{ color: color.white, fontSize: 12, fontWeight: "500" }}>{"X"}</Text>
                    </TouchableOpacity>
                </View>

                <View>

                    <TouchableOpacity onPress={() => {
                        this.setState({
                            selectedImage: image,
                            selectedImageIndex: index,
                            // comment:imageComment
                        }
                            //    ,()=>{this.renderShowImage()}
                        )

                    }} style={{ justifyContent: "center", alignSelf: "center", alignItems: "center", flex: 1, zIndex: 1 }}
                    >

                        <Image source={{ uri: image.image }} style={{ height: 80, width: 80 }} />

                    </TouchableOpacity>
                </View>
            </View>
        )

    }

    async openImgeFolders() {
        try {
            var { uploadedImages } = this.state;
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
            });
            if (!result.cancelled) {

                //   getImage = result.uri

                var prepareData = {
                    image: result.uri,
                    imageComment: ""
                }
                uploadedImages.push(prepareData)
                // alert("photo uri"+JSON.stringify(uploadedImages))
                // this.setState({ image: result.uri });
                // console.log('photo uri', getImage);
            }
            alert(JSON.stringify(result.uri))
            this.setState({
                uploadedImages
            })

            console.log(result.uri)

        } catch (E) {
            console.log(E);
        }
    }
    onPressSaveButton(items) {
        // alert("Image"+JSON.stringify( this.state.uploadedImages))
        // alert("Comment"+JSON.stringify(this.state.comment))
        // alert("Lab set id"+JSON.stringify(this.state.getListOFLabSet))

        var service_url = Constants.LAB_TEST_UPLOAD
        var { getListOFTestConfiguration } = this.state
        var labSetId = getListOFTestConfiguration['test one'].lab_set_id

        var data = {
            "patient_id": this.state.patientID,
            "lab_result_image_set": [
                {
                    // "id":this.state.getListOFLabSet.id,
                    "lab_set_id": labSetId,
                    "comment": this.state.comment,
                    "lab_test_id": labSetId,
                    "result_image": this.state.uploadedImages
                }

            ]
        }


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveLabTestUploadSuccess,
            this.saveLabTestUploadFailure
        );
    }
    saveLabTestUploadSuccess = response => {
        if (response.status == "success") {

            console.log(">>>>>>>>>>>Success" + JSON.stringify(response))
        }
    }
    saveLabTestUploadFailure = (error) => {

    }

    renderUploadAndCancelOption(items, index) {
        return (
            <View style={{ flexDirection: "row", justifyContent: "center", alignSelf: "center", bottom: 20, position: "absolute" }}>
                <TouchableOpacity
                    onPress={() => {
                        { this.onPressSaveButton(items) }
                        // this.setState({
                        //     isImageUploadSave: true,
                        //     showTestUploadPopupFlag: false

                        // })
                    }}
                    style={{ backgroundColor: color.themeDark, paddingHorizontal: 15, paddingVertical: 5, borderRadius: 4, justifyContent: "center", alignContent: 'center', alignItems: "center" }}>
                    <Text style={{ fontSize: 15, color: color.white, fontWeight: "500" }}>{"Save"}</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity  onPress={()=>{
                this.setState({
                    uploadedImages:[],
                    showTestUploadPopupFlag:false
                })

            }} style={{backgroundColor:color.themeDark,paddingHorizontal:15,paddingVertical:5,borderRadius:4,justifyContent:"center",alignContent:'center',alignItems:"center"}}>
                    <Text style={{fontSize:15,color:color.white,fontWeight:"500"}}>{"Cancel"}</Text>
            </TouchableOpacity> */}
            </View>
        )
    }

    renderImageUploadView() {
        return (
            <View style={{ position: "absolute" }}>
                <View
                    style={{
                        position: "absolute",
                        zIndex: -1,
                        height: screenHeight - 68,
                        width: screenWidth - 100,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                >
                    <View
                        style={{
                            backgroundColor: "white",
                            width: "50%", //"48%",
                            // height: 150, //"60%",
                            minHeight: "85%",
                            borderRadius: 4,
                            // paddingVertical: 20,
                            paddingHorizontal: 10
                        }}
                    >

                        {this.renderShowImage()}

                        <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            {this.state.uploadedImages.length > 0 ?
                                this.state.uploadedImages.map((img, index) => {
                                    return this.renderAddedImages(img, index)
                                })
                                : null
                            }
                            {this.renderAddNewImageView()}
                        </View>
                        {Object.keys(this.state.getListOFTestConfiguration).length > 0 ?
                            this.state.getListOFTestConfiguration && this.state.getListOFTestConfiguration.map((item, index) => {
                                return this.renderUploadAndCancelOption(item, index)
                            })
                            : null
                        }
                    </View>
                </View>
            </View>
        )
    }

    renderEditIcon = () => {
        this.setState({ isEdit: true })
    }

    renderImageViewBottom() {
        return (
            <View style={{ bottom: 0, position: "absolute", width: "95%" }}>
                <View style={{ flexDirection: "row", backgroundColor: color.sectionHeadingDarkColor }}>
                    <View style={{ flex: 0.9 }}>
                        {/* {this.renderHeader("Test Name", "Comments")} */}
                        <CommonHistorySectionHeader
                            heading1={"Test Name"}
                            heading2={"Comments"}
                            noOfColumn={2}
                            columnSize={[0.6, 0.45]}
                        />
                    </View>
                    <View style={{ flex: 0.1 }}>
                        <EditiAndDeleteForLineItem
                            editHistory={this.renderEditIcon.bind(this)}
                            editImage={"pencil"}
                        />
                    </View>
                </View>
                <View style={{ flexDirection: "row", marginLeft: 20, paddingVertical: 10 }}>
                    <Text style={{ flex: 0.5 }}>{"Blood Test"}</Text>
                    <TextInput
                        editable={this.state.isEdit ? true : false}
                        placeholder={"Enter Your Comments"} value={this.state.comment} key={"comment"} onChangeText={(text) => { this.setState({ comment: text }) }}
                        style={{ flex: 0.5, minWidth: 280, borderRadius: 4, height: 30, borderWidth: 1, borderColor: color.lightGray, alignSelf: "center" }}
                    />
                </View>
                <View>
                    <CommonHistorySectionHeader
                        heading1={"Date"}
                        heading2={"Uploaded By"}
                        noOfColumn={2}
                        columnSize={[0.5, 0.5]}
                    />
                    <View style={{ flexDirection: "row", marginLeft: 20, marginTop: 10 }}>
                        <Text style={{ flex: 0.5 }}>{"12-05-2021"}</Text>
                        <Text style={{ flex: 0.5 }}>{"John kumble"}</Text>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", padding: 10 }}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    isImageUploadSave: true,
                                    showTestUploadPopupFlag: false
                                })
                            }}
                            style={{ backgroundColor: color.themeDark, paddingHorizontal: 15, paddingVertical: 5, borderRadius: 4, justifyContent: "center", alignContent: 'center', alignItems: "center" }}>
                            <Text style={{ fontSize: 15, color: color.white, fontWeight: "500" }}>{"Save"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            // this.setState({
                            //     // uploadedImages: [],
                            //     // showTestUploadPopupFlag: false
                            // })

                        }} style={{ backgroundColor: color.themeDark, paddingHorizontal: 15, paddingVertical: 5, borderRadius: 4, justifyContent: "center", alignContent: 'center', alignItems: "center", marginLeft: 10 }}>
                            <Text style={{ fontSize: 15, color: color.white, fontWeight: "500" }}>{"Cancel"}</Text>
                        </TouchableOpacity>
                    </View>


                </View>
            </View>
        )
    }
    renderShowSelectedImage() {
        return (
            <View style={{ backgroundColor: color.applicationBackgroundColor }}>
                <Image source={require("../../../../../assets/images/HomeScreenImages/Testconfig.png")} style={{ height: 260, width: "100%" }}></Image>
            </View>

        )
    }
    renderImageView(uri, index) {
        return (
            <View>
                <View onPress={() => { }} style={{ backgroundColor: "#F1F0EF", marginHorizontal: 5, marginVertical: 5, height: 65, width: 95, borderRadius: 4, alignItems: "flex-end", borderColor: color.themeDark, borderWidth: 1 }}>
                    <View style={{ zIndex: 1 }}>
                        <TouchableOpacity
                            onPress={() => {
                                var { viwedImage } = this.state;
                                viwedImage.splice(index, 1);
                                this.setState({
                                    viwedImage,

                                })
                            }}

                            style={{ borderRadius: 50, backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center", zIndex: -2 }}>
                            <Text style={{ color: color.white, fontSize: 12, fontWeight: "500" }}>{"X"}</Text>
                        </TouchableOpacity>
                    </View>

                    <View>


                        <TouchableOpacity onPress={() => {
                            // this.setState({
                            // selectedValues:{
                            //     selectedIndex:index,
                            //     viewedImages:uri
                            // }

                            // })
                        }} style={{ justifyContent: "center", alignSelf: "center", alignItems: "center", flex: 1, zIndex: 1 }}>
                            <Image source={require("../../../../../assets/images/HomeScreenImages/Testconfig.png")} style={{ height: 45, width: 80 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }
    renderImageViewPopup() {
        return (
            <View style={{ position: "absolute" }}>
                <View
                    style={{
                        position: "absolute",
                        zIndex: -1,
                        height: screenHeight - 78,
                        width: screenWidth - 100,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                >
                    <View
                        style={{
                            backgroundColor: "white",
                            width: "50%", //"48%",
                            // height: 150, //"60%",
                            minHeight: "95%",
                            borderRadius: 4,
                            // paddingVertical: 20,
                            paddingHorizontal: 10
                        }}
                    >
                        {this.renderShowSelectedImage()}
                        {this.renderImageView()}
                        {this.renderImageViewBottom()}
                    </View>

                </View>
            </View>
        )
    }
}

// define your styles
const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 13,
        color: "black",
        fontWeight: '500'
    },
    patientListFontStyle: {
        fontSize: 11,
        color: "black"
    },
});



//----- change name
