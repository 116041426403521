//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Image } from 'react-native';
import { HistoryYesOrNoSection, CommonButton,EditAndDeleteIcon, CommonAddButton, DoctorNotesCommonRightSideHeader } from '../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from "moment";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import ImagePath from '../../../../utils/ImagePaths';
import { TouchableOpacity } from 'react-native-gesture-handler';
// create a component
const pageName = "isHBA1CChart";

// var yearsOfDiabetic = [
//     { label: "1 year", value: "1" },
//     { label: "2 year", value: "2" },
//     { label: "3 year", value: "3" },
//     { label: "4 year", value: "4" },
//     { label: "5 year", value: "5" },
//     { label: "6 year", value: "6" },
// ];

// var familyDiabeticList = [
//     { label: "Father", value: "Father" },
//     { label: "Mother", value: "Mother" },
//     { label: "Brother", value: "Brother" },
// ];

// var typesDiabeticList = [
//     { label: "Type 1", value: "1" },
//     { label: "Type 2", value: "2" },
//     { label: "Not Sure", value: "Not Sure" },
// ];


// var treatedOfDiabeticList = [
//     { label: "Blood Glucose Pills", value: "Bloodglucosepills" },
//     { label: "Insulin Injuctions", value: "Insulininjections" },
//     { label: "Diet Exercies", value: "Dietexceriseonly" },
// ];

// var nameOfBGMeter = [
//     { label: "ACCU-CHEK AVIVA NANO", value: "ACCU-CHEK AVIVA NANO" },
//     { label: "ACCU-CHEK MOBILE", value: "ACCU-CHEK MOBILE" },
//     { label: "ABBOTTI DIABETES CARE", value: "ABBOTTI DIABETES CARE" },
// ];

export class Hba1cChartNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedValue: true,
            // details: '',

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            chartDate: "",
            hba1cvalue: "",
            listData: [],

            dateEditFlag:false
        }
    }


    componentDidMount() {
        this.getHba1cValue()
    }

    componentWillReceiveProps(props) {


        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;

              
                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }







    onPressAddButton() {

        var data = {

            "patient_id": this.state.patientAppointment.patient_id,
            "chart_date": this.state.chartDate,
            "chart_value": this.state.hba1cvalue

        }

        var service_url = Constants.HBA1C_CHART_SAVE;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDiabeticHistorySuccess,
            this.saveDiabeticHistoryFailure
        );

    }

    saveDiabeticHistorySuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.props.refreshRighSideComponent(pageName)
            this.getHba1cValue()
            this.clearAllData()
        } else {
            this.props.showResposeValue("error", success.message)
        }
        // console.log("success ++++++++++++++++>> " + JSON.stringify(success))
    }
    saveDiabeticHistoryFailure = error => {
        this.props.showResposeValue("error", error.message)
    }



    clearAllData() {
        var states = this.state;

        states["hba1cvalue"] = ""
        states["chartDate"] = ""
        states["dateEditFlag"]=false

        this.setState({ states })
    }

    getHba1cValue() {
        var service_url =
            Constants.HBA1C_CHART_SAVE +
            "?patient_id=" +
            this.state.patientAppointment.patient_id

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHba1cValueSuccess,
            this.getHba1cValueFailure
        );
    }

    getHba1cValueSuccess = (response) => {
        if (response.status === "success") {
            var field = this.state;
            field["listData"] = response.data;
            this.setState({
                field,
            });
        }
    };
    getHba1cValueFailure = (error) => {

    }

    numberValidation(num){
        if(num <=14){
            var rgx = /^(\d{0,3})((\.(\d{0,2})?)?)$/;
            return num.match(rgx);
        }
      }

      onPressEditOrdelete(item,editFlag,key) {
        if (key == "edit") {
            this.setState({
                dateEditFlag:editFlag,
                hba1cvalue:item.chart_value,
                chartDate:item.chart_date
            })
        }
        else if(key == "delete"){
            var service_url = Constants.HBA1C_CHART_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id + "&id=" + item.id;
            OpthamologyService.getInstance().deleteComplaints(
                service_url,
                {},
                this,
                this.deleteSuccess,
                this.deleteFailure
            )
        }
    }
    deleteSuccess = (response) =>{
        if(response && response.status == "success"){
            this.props.showResposeValue("success", response.message)
            this.props.refreshRighSideComponent(pageName)
            this.getHba1cValue()
            this.clearAllData()
        }
    }
    deleteFailure = error =>{
        this.props.showResposeValue("error", error.message)
    }
    printHba1c = (item) =>{
        try{
            var serviceUrl = Constants.HBA1C_CHART_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id + "&id=" + item.id + "&export=pdf";
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                this.getPrintSuccess,
                this.getPrintFailure,
                "pdf"
            );
        }catch(e){

        }
    }
    getPrintSuccess = (response) => {
        if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    };


    getPrintFailure = error => {

        console.log(error);
    };

    renderDataList() {

        return (
            <View>
                <View style={{ justifyContent: 'center', height: 30, backgroundColor: color.sectionHeadingDarkColor,marginTop:30}}>

                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '20%', marginLeft: 20 }}>Date</Text>
                        <Text style={{ fontSize: 13, width: '30%', marginLeft: 20 }}>Blood Glucose </Text>
                        <Text style={{ fontSize: 13, width: '20%', marginLeft: 20 }}>HbA1c Value </Text>
                        <Text style={{ fontSize: 13, width: '30%', marginLeft: 20 }}>Action </Text>
                    </View>
                </View>
                {

                    this.state.listData.map((item, index) => {

                        var fulldate = new Date();
                        var converted_date = moment(fulldate).format("DD-MM-YYYY");
                        
                        var getItemDate = moment(item.chart_date).format("DD-MM-YYYY");

                        var editIconFlag=false

                        if(converted_date == getItemDate){
                            editIconFlag= true
                        }

                        return (
                            <View key = {index} style={{ flexDirection: 'row',alignItems:"center", alignContent: 'center', justifyContent: 'space-evenly', paddingBottom: 10, paddingTop: 10, borderBottomColor: '#CDD1D5', borderBottomWidth: 1 }}>
                             <Text style={{ fontSize: 13, width: '20%', marginLeft: 20 }}>{item.chart_date?moment(item.chart_date).format("DD-MM-YYYY"):item.chart_date}</Text>
                             <Text style={{ fontSize: 13, width: '30%', marginLeft: 20 }}>{item.mean_blood_glucose}</Text>
                             <Text style={{ fontSize: 13, marginLeft: 20, width: '20%' }}>{item.chart_value || 0}</Text>
                             <View style={{flexDirection:"row",justifyContent:'space-between',width: '30%', alignItems:'center'}}>
                                {
                                    <View style={{flexDirection: 'row', marginLeft: 17}}>
                                    {editIconFlag ?<EditAndDeleteIcon iconKey={"edit"} image={"pencil"} onPressEditOrdelete={this.onPressEditOrdelete.bind(this,item,true)} /> : null }
                                    <TouchableOpacity onPress={() => this.printHba1c(item)}>
                                    <Image source={ImagePath.Print} style={{width:20, height: 20, backgroundColor: color.themeDark, marginTop: 5, marginRight: 15}}/>
                                    </TouchableOpacity>
                                    {editIconFlag ?<EditAndDeleteIcon iconKey={"delete"} image={"trash"} onPressEditOrdelete={this.onPressEditOrdelete.bind(this,item,true)} /> : null }
                                    </View>
                                }
                             </View>
                             
                            </View>
                        );
                    })

                }
            </View>
        );
    }

    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"HbA1c Chart"} clearAllData={this.clearAllData.bind(this)} />
                <View style={{ justifyContent: 'center', height: 30, backgroundColor: color.sectionHeadingDarkColor }}>
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Date</Text>
                        <Text style={{ fontSize: 13, width: '50%' }}>HbA1c Value</Text>

                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                    <View style={{ width: '50%' }}>
                        <DatePicker
                        disabledDate={current => {
                            return current && current > moment().add(0, "days");
                          }}
                            disabled={this.state.dateEditFlag}
                            value={
                                this.state.chartDate
                                    ? moment(this.state.chartDate)
                                    : this.state.chartDate
                            }
                            onChange={(date) => {
                                var fulldate = new Date(date);
                                var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                this.setState({ chartDate: converted_date });
                            }
                            }
                            format={"DD-MM-YYYY"}
                            placeholder={"Select"}
                            style={{
                                marginTop: 5,
                                marginLeft: 20,
                                height: 30,
                                borderWidth: 1,
                                borderColor: color.rightPanelInputBorder,
                                borderRadius: 5,
                                alignItems: "center",
                                flexDirection: "row",
                                width: '80%',
                            }}
                        /></View>
                    <View style={{ width: '50%' }}>
                        <TextInput
                            value={this.state.hba1cvalue}
                            maxLength={4}
                            onChangeText={(text) => {
                                var isValid = this.numberValidation(text);
                                if(text!= "" && isValid){
                                this.setState({
                                    hba1cvalue: text
                                })
                            }else{
                                this.setState({
                                    hba1cvalue: ""
                                })
                            }}}
                            style={[{
                                width: "90%",
                                marginLeft: 10,
                                marginTop: 5,
                                paddingHorizontal: 10,
                                color: "#525252",
                                height: 30,
                                padding: 8,
                                marginRight: 0,
                                borderWidth: 0.5,
                                borderColor: color.rightPanelInputBorder
                            }, Style.allButtonBorderRadius]}
                        />

                    </View>
                </View>

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

                <View>
                    {this.renderDataList()}
                </View>

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({

    smallViewStyle: {
        flexDirection: 'row', borderWidth: 0.1, borderColor: 'white', shadowColor: "#090F36",
        shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5, height: 22
    },

    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
});
