//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity,TextInput } from 'react-native';
import { OBGYNHistorySectionHeading, CommonHistorySectionHeader, CommonButton, CommonAddButton, HistorySectionRightSideUI, EditAndDeleteIcon } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants"
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import BaseComponentStyle from "../../BaseComponentStyle";

// create a component
const pageName = "isMedicalHistory"

export class MedicalHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            listOfDatas: [],
            getListofMedical: {}

        }
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getMedicalHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount() {
        this.getMedicalHistory()
    }

    getMedicalHistory() {
        var service_url = Constants.POST_MEDICAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMedicalHistorySuccess,
            this.getMedicalHistoryFailure
        );
    }
    getMedicalHistorySuccess = success => {
        // alert(JSON.stringify(success))

        if (success.status == "success") {

            // var data =success.data.patient_medical_history[0]

            // var prepareData=[]
            //     Object.keys(data).forEach((keys)=>{



            //         if(keys =="disease_name"){
            //             prepareData.push({ label:"Alcohol", value:data[keys]  })
            //         }


            //     })
            var field = this.state;
            field["listOfDatas"] = success.data.patient_medical_history;



            this.setState({
                field,
                getListofMedical: success.data
            },
            ()=>{this.props.MedicalData(this.state.getListofMedical)})
        }
        //console.log("success" + JSON.stringify(success))
    }


    getMedicalHistoryFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            this.editHistory()
        }, 100);
    }
    editHistory() {
        this.props.editHistory(this.state.getListofMedical, pageName)
    }

    renderContent(item, indx) {
        return (
            <View key={indx} style={{ marginTop: 15, flexDirection: 'row', justifyContent: 'space-between', }}>
                <Text style={Style.historyHeaderFontStyle}>{item.label}</Text>
                <Text style={Style.historyHeaderFontStyle}>{item.value}</Text>
            </View>
        )
    }



    renderMedicalListItem = (item, index) => {
        return (

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 8, borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>
                <Text style={[Style.contentTableDataLead, { flex: 0.85, marginHorizontal: 20 }]}>{item.disease_name}</Text>

                <View style={{ flex: 0.15, flexDirection: 'row', justifyContent: 'space-between' }}>
                    {/* <TouchableOpacity disabled={this.state.isLocked} onPress={this.editHistory.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity> */}
                    {/* <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}> */}
                    {/* <EditAndDeleteIcon iconKey={"edit"} image={"pencil"} onPressEditOrdelete={this.editHistory.bind(this,item)} />               

                <EditAndDeleteIcon iconKey={"edit"} image={"trash"} onPressEditOrdelete={this.deleteItem.bind(this,item)} />                */}
                </View>
            </View>

        );
    };

    render() {
        return (
            <View style={styles.container}>
                <View style={{ flexDirection: '' }}>
                    <View style={{ flex: 1,justifyContent:'flex-end' }}>
                        <OBGYNHistorySectionHeading
                            selectedRightSideView={this.selectedRightSideView.bind(this)}
                            editHistory={this.editHistory.bind(this)}
                            selectedPageName={this.state.selectedPageName}
                            pageName={pageName} heading={"Medical History"}
                            bloodGroup={this.state.getListofMedical.blood_group}
                            editImage={this.state.listOfDatas.length > 0 ? "pencil" : ""}
                        />
                    </View>
                    {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, color: "#888888" }}>{"Blood group :"}</Text>
                        <Text style={{ fontSize: 14, fontWeight: "500", color: "black" }}>{this.state.getListofMedical.blood_group}</Text>
                    </View> */}
                </View>
                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Problems"}
                        noOfColumn={1}
                        columnSize={[0.98, 0.02]}  //total value is == of 1
                    />
                </View>
                <FlatList
                    data={this.state.listOfDatas}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderMedicalListItem(item, index)
                    }
                />
                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Comments"}
                        noOfColumn={1}
                    />
                    <TextInput

                        editable={true}
                        multiline={true}
                        placeholder=""
                        style={{ paddingTop: 10, paddingLeft: 10, backgroundColor: this.state.isLocked ? color.disableComponentColor : null, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5,marginTop:15 }}
                        value={this.state.getListofMedical.comments}
                    />
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop: 20
    },
});

//make this component available to the app
