//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection } from '../../BaseComponent'
import { Constants } from "../../../../utils/Constants";
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../../../ui/doctor/BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";

// create a component
const pageName = "isNuturitionHistory"

export class NutritionHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            selectedValue: true,
            counselling_date: '',
            NutritionType: {},
            NutritionTypeValue: '',
            foodType: {},
            foodTypeValue: '',

            getFoodRegularType: {},
            selectgetFoodRegularType: {},
            getFoodRegularTypeValue: "",

            nutritionNewButtonFlag: true,
            newButtonValue: "",

            selectedMorning: [],
            selectedBreakfast: [],
            selectedMidMorning: [],
            selectedLunch: [],
            selectedEvening: [],
            selectedDinner: [],
            selectedLateNight: [],
            selectedWaterConsumption: {},

            listOfWaterConsumption: Constants.WaterConsumption,

            earlyMorningFoods:[],
            breakFastFoods:[],
            midMorningFoods:[],
            lunchFoods:[],
            eveningFoods:[],
            dinnerFoods:[],
            lateNightFoods:[],
            // waterConsumptionTypes:[]
        }
    }

    convertStringToList(listKey,selectedValueKey ,data){

        let states = this.state;
        let getListOfValues = states[listKey];
        let getListOfSelectedValue = states[selectedValueKey];

       let getList =  data.split(',');
       if(getList.length > 0){
           for (let i = 0; i < getList.length; i++) {
               if(getList[i]){
                getListOfValues.push(getList[i])
                var prepareData = {label:getList[i],value:getList[i]}
                getListOfSelectedValue.push(prepareData)
               }
           }
       }

    }

    componentWillReceiveProps(props) {

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;
                states["NutritionType"] = data.nutrition_type ? { label: data.nutrition_type, value: data.nutrition_type } : null
                states["NutritionTypeValue"] = data.nutrition_type
                states["selectedValue"] = data.nutritional_counselling
                states["counselling_date"] = data.nutrition_counselling_date

                let {selectedMorning,earlyMorningFoods,selectedBreakfast,breakFastFoods,selectedMidMorning,midMorningFoods,
                    selectedLunch,lunchFoods,selectedEvening,eveningFoods,dinnerFoods,selectedDinner, lateNightFoods,selectedLateNight,foodType
                } = this.state;

                // morning food start 
                let morning =  data.food_early_morning_amount.split(',');
                if(morning.length > 0){
                    for (let i = 0; i < morning.length; i++) {
                        if(morning[i]){
                            var prepareData = {label:morning[i],value:morning[i]}
                            earlyMorningFoods.push(morning[i])
                            selectedMorning.push(prepareData)
                        }
                    }
                }
                // morning ends 

                // breakFastFoods
                let breakFast =  data.food_breakfast_amount.split(',');
                if(breakFast.length > 0){
                    for (let i = 0; i < breakFast.length; i++) {
                        if(breakFast[i]){
                            var prepareData = {label:breakFast[i],value:breakFast[i]}
                            breakFastFoods.push(breakFast[i])
                            selectedBreakfast.push(prepareData)
                        }
                    }
                }
                // breakFastFoods

                // midMorning
                let midMorning =  data.food_mid_morning_amount.split(',');
                if(midMorning.length > 0){
                    for (let i = 0; i < midMorning.length; i++) {
                        if(midMorning[i]){
                            var prepareData = {label:midMorning[i],value:midMorning[i]}
                            midMorningFoods.push(midMorning[i])
                            selectedMidMorning.push(prepareData)
                        }
                    }
                }
                // midMorning

                // lunch
                let lunch =  data.food_lunch_amount.split(',');
                if(lunch.length > 0){
                    for (let i = 0; i < lunch.length; i++) {
                        if(lunch[i]){
                            var prepareData = {label:lunch[i],value:lunch[i]}
                            lunchFoods.push(lunch[i])
                            selectedLunch.push(prepareData)
                        }
                    }
                }
                // lunch

                // evening
                let evening =  data.food_evening_amount.split(',');
                if(evening.length > 0){
                    for (let i = 0; i < evening.length; i++) {
                        if(evening[i]){
                            var prepareData = {label:evening[i],value:evening[i]}
                            eveningFoods.push(evening[i])
                            selectedEvening.push(prepareData)
                        }
                    }
                }
                // evening


                // dinner
                let dinner =  data.food_dinner_amount.split(',');
                if(dinner.length > 0){
                    for (let i = 0; i < dinner.length; i++) {
                        if(dinner[i]){
                            var prepareData = {label:dinner[i],value:dinner[i]}
                            dinnerFoods.push(dinner[i])
                            selectedDinner.push(prepareData)
                        }
                    }
                }
                // dinner


                // lateNight
                let lateNight =  data.food_late_night_amount.split(',');
                if(lateNight.length > 0){
                    for (let i = 0; i < lateNight.length; i++) {
                        if(lateNight[i]){
                            var prepareData = {label:lateNight[i],value:lateNight[i]}
                            lateNightFoods.push(lateNight[i])
                            selectedLateNight.push(prepareData)
                        }
                    }
                }
                // lateNight


                states["selectedWaterConsumption"] = data.water_consumption ? { label: data.water_consumption, value: data.water_consumption } : null

                selectedMorning = this.removeDuplicate(selectedMorning);
                earlyMorningFoods = this.removeDuplicate(earlyMorningFoods);

                breakFastFoods = this.removeDuplicate(breakFastFoods);
                selectedBreakfast = this.removeDuplicate(selectedBreakfast);

                midMorningFoods = this.removeDuplicate(midMorningFoods);
                selectedMidMorning = this.removeDuplicate(selectedMidMorning);


                selectedLunch = this.removeDuplicate(selectedLunch);
                lunchFoods = this.removeDuplicate(lunchFoods);

                selectedDinner = this.removeDuplicate(selectedDinner);
                dinnerFoods = this.removeDuplicate(dinnerFoods);

                lateNightFoods = this.removeDuplicate(lateNightFoods);
                selectedLateNight = this.removeDuplicate(selectedLateNight);


                eveningFoods = this.removeDuplicate(eveningFoods);
                selectedEvening = this.removeDuplicate(selectedEvening);

                foodType= { value: "1", label: "Early Morning" }

                this.setState({ 
                    states ,
                    selectedMorning,earlyMorningFoods,
                    selectedBreakfast,breakFastFoods,
                    selectedMidMorning,midMorningFoods,
                    selectedLunch,lunchFoods,
                    selectedEvening,eveningFoods,
                    dinnerFoods,selectedDinner,
                    lateNightFoods,selectedLateNight,foodType
                
                }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }

    componentDidMount() {
        this.getFoods()
    }

    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
        states["selectedValue"] = flag;
        states[key] = value;
        this.setState({ states })
    }

    removeDuplicate(data) {
        if(data.length > 0){
            var nameListJsonObject = data.map(JSON.stringify);
            var nameListUniqueSet = new Set(nameListJsonObject);
            var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
            return nameListUniqueArray;
        }else{
            return []
        }
    }

    onPressButton(key, selectedvalue) {
        if (key == "nutritionType") {
            this.setState({
                NutritionType: selectedvalue,
                NutritionTypeValue: selectedvalue.value
            })
        } else if (key == "nutritionFoodType") {
            this.setState({
                foodType: selectedvalue,
                foodTypeValue: selectedvalue.value
            })
        } else if (key == "selectgetFoodRegularType") {
            this.setState({
                selectgetFoodRegularType: selectedvalue,
                getFoodRegularTypeValue: selectedvalue.value
            })
        } else if(key=="selectedMorning" || key == "selectedBreakfast" || key == "selectedMidMorning" ||
        key == "selectedLunch" || key== "selectedEvening" || key =="selectedDinner" || key=="selectedLateNight"){
            this.multiSelectCommonFunction(key,selectedvalue)
        }else {
            var states = this.state;
            states[key] = selectedvalue;
            this.setState({ states })
        }
    }
    renderNutritionType() {
        return (
            <View>
                <Text style={{ marginLeft: 20, marginTop: 10 }}>{"Type of Nutrition"}</Text>
                <View style={Style.NutritionButtontype}>

                    {
                        Constants.Nutrition.map((item, index) => {

                            return (

                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={this.state.NutritionType}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={"nutritionType"} />
                                </View>
                            )
                        })
                    }</View>
            </View>
        )
    }

    renderNutritionFoodType() {
        return (
            <View>
                <Text style={{ marginLeft: 20, marginTop: 10 }}>{"Please list the typical foods and amounts that you eat and drink on a typical day ."}</Text>
                <View style={Style.NutritionButtontype}>

                    {
                        Constants.NutritionFood.map((item, index) => {

                            return (

                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={this.state.foodType}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={"nutritionFoodType"} />
                                </View>
                            )
                        })
                    }</View>
            </View>
        )
    }

    changeFlag() {
        this.setState({
            nutritionNewButtonFlag: false
        })
    }

    setOnSubmitTextBoxValue(listKey,selectedValueKey,selectedValue){
        let states = this.state;
        let getListOfValues = states[listKey];
        let getListOfSelectedValue = states[selectedValueKey];

        var prepareData = { label: selectedValue, value: selectedValue }


        getListOfValues.push(selectedValue);
        getListOfSelectedValue.push(prepareData);

        getListOfValues = this.removeDuplicate(getListOfValues);
        getListOfSelectedValue = this.removeDuplicate(getListOfSelectedValue);

       
        states = getListOfValues;
        states = getListOfSelectedValue;

        this.setState({ states})
    }

    onSubmitToAddValue() {
        var { getFoodRegularType } = this.state;
        var states = this.state
        var prepareData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (this.state.foodType.value == 1) {
            this.setOnSubmitTextBoxValue("earlyMorningFoods","selectedMorning",this.state.newButtonValue)
            // var data = getFoodRegularType["early_morning"];
            // data.push(this.state.newButtonValue)
            // states["selectedMorning"] = prepareData
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 2) {
            this.setOnSubmitTextBoxValue("breakFastFoods","selectedBreakfast",this.state.newButtonValue)

            // var data = getFoodRegularType["breakfast"];
            // data.push(this.state.newButtonValue)
            // states["selectedBreakfast"] = prepareData
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 3) {
            this.setOnSubmitTextBoxValue("midMorningFoods","selectedMidMorning",this.state.newButtonValue)

            // var data = getFoodRegularType["mid_morning"];
            // data.push(this.state.newButtonValue)
            // states["selectedMidMorning"] = prepareData
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 4) {
            this.setOnSubmitTextBoxValue("lunchFoods","selectedLunch",this.state.newButtonValue)

            // var data = getFoodRegularType["lunch"];
            // states["selectedLunch"] = prepareData
            // data.push(this.state.newButtonValue)
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 5) {
            this.setOnSubmitTextBoxValue("eveningFoods","selectedEvening",this.state.newButtonValue)
            // var data = getFoodRegularType["evening"];
            // states["selectedEvening"] = prepareData
            // data.push(this.state.newButtonValue)
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 6) {
            this.setOnSubmitTextBoxValue("dinnerFoods","selectedDinner",this.state.newButtonValue)

            // var data = getFoodRegularType["dinner"];
            // data.push(this.state.newButtonValue)
            // states["selectedDinner"] = prepareData
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 7) {
            this.setOnSubmitTextBoxValue("lateNightFoods","selectedLateNight",this.state.newButtonValue)

            // var data = getFoodRegularType["late_night"];
            // states["selectedLateNight"] = prepareData
            // data.push(this.state.newButtonValue)
            // getFoodRegularType = getFoodRegularType
        } else if (this.state.foodType.value == 8) {
            var data = this.state.listOfWaterConsumption;
            states["selectedWaterConsumption"] = prepareData
            data.push(this.state.newButtonValue)
            getFoodRegularType = getFoodRegularType
        }

        this.setState({ states, getFoodRegularType, nutritionNewButtonFlag: true, newButtonValue: "" }, () => {

        })
    }


    multiSelectCommonFunction(key,selectedvalue){
        // var { selectedBreakfast } = this.state;
        var states = this.state;
        var selectedListOfValues = states[key];
            var getSelectedValue;
            var spliceData;

            if (selectedListOfValues.length > 0) {
                for (var i = 0; i < selectedListOfValues.length; i++) {
                    if (selectedListOfValues[i].value == selectedvalue.value) {
                        spliceData = selectedListOfValues[i];
                        selectedListOfValues.splice(i, 1)
                        var data = this.removeDuplicate(selectedListOfValues);
                        this.setState({ selectedListOfValues: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedvalue.value) {
                        } else {
                            getSelectedValue = selectedvalue
                        }
                    }
                }

                if (selectedvalue) { selectedListOfValues.push(selectedvalue); }
            } else {
                selectedListOfValues.push(selectedvalue)
            }

            var data = this.removeDuplicate(selectedListOfValues);
            states=data
            // this.setState({ selectedBreakfast: data })
            this.setState({ states })
    }


    renderNutritionFoods(type) {


        var data = []

        var buttonKey = ""
        var selectedValue = {}
        if (type == 1) {
            data = this.state.earlyMorningFoods;
            buttonKey = "selectedMorning";
            selectedValue = this.state.selectedMorning;
        }
        else if (type == 2) {
            data = this.state.breakFastFoods;
            buttonKey = "selectedBreakfast";
            selectedValue = this.state.selectedBreakfast;
        } else if (type == 3) {
            data = this.state.midMorningFoods;
            buttonKey = "selectedMidMorning";
            selectedValue = this.state.selectedMidMorning;
        } else if (type == 4) {
            data = this.state.lunchFoods;
            buttonKey = "selectedLunch";
            selectedValue = this.state.selectedLunch;
        } else if (type == 5) {
            data = this.state.eveningFoods;
            buttonKey = "selectedEvening";
            selectedValue = this.state.selectedEvening;
        } else if (type == 6) {
            data = this.state.dinnerFoods;
            buttonKey = "selectedDinner";
            selectedValue = this.state.selectedDinner;
        } else if (type == 7) {
            data = this.state.lateNightFoods;
            buttonKey = "selectedLateNight";
            selectedValue = this.state.selectedLateNight;

        } else if (type == 8) {
            data = this.state.listOfWaterConsumption;
            buttonKey = "selectedWaterConsumption";
            selectedValue = this.state.selectedWaterConsumption;
        }

        return (
            <View>{
                this.state.foodTypeValue == 8 ?
                    <Text style={{ marginLeft: 20, marginTop: 10 }}>{"Please select the amount of water consumption on a typical day (in Litres)."}
                    </Text>
                    :
                    <Text style={{ marginLeft: 20, marginTop: 10 }}>{"Please list the typical foods and amounts that you eat and drink on a typical day."}</Text>}
                <View style={Style.NutritionButtontype}>

                    {
                        data && data.length > 0 && data.map((item, index) => {
                            var prepareData = { label: item, value: item }
                            if(prepareData.value){
                            return (

                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={prepareData}
                                        selectedvalue={selectedValue}
                                        butttonText={item}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={buttonKey} />
                                </View>
                            )
                            }
                        })
                    }

                    {
                        this.state.nutritionNewButtonFlag ?
                            <CommonButton
                                item={{ label: "New", value: "New" }}
                                selectedvalue={{}}
                                butttonText={"New"}
                                buttonType={"outlineNonTheme"}
                                buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                rightIcon={true}
                                buttonAction={this.changeFlag.bind(this)}
                                buttonKey={"nutritionNewButtonFlag"} />
                            :
                            <TextInput
                                style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                onChangeText={(text) => {
                                    this.setState({ newButtonValue: text })
                                }}
                                onSubmitEditing={() => {
                                    this.onSubmitToAddValue()
                                }}
                            />
                    }

                </View>
            </View>
        )
    }

    getArraytoSitingValue(list){
        let data =[];
        if(list.length>0){
            for (let i = 0; i < list.length; i++) {
                data.push(list[i].value)
            }
        }
        // alert(JSON.stringify(data))
        return data.toString();
    }

    addNutrition() {

        var states = this.state;

        


        var data = {
            "patient_id": states.patientAppointment.patient_id,
            "nutrition_type": states.NutritionTypeValue,
            "nutritional_counselling": states.selectedValue,
            "nutrition_counselling_date": states.counselling_date,
 
            "food_early_morning_amount": this.getArraytoSitingValue(states.selectedMorning),
            "food_breakfast_amount":this.getArraytoSitingValue(states.selectedBreakfast)  ,
            "food_mid_morning_amount":this.getArraytoSitingValue(states.selectedMidMorning) ,
            "food_lunch_amount":this.getArraytoSitingValue(states.selectedLunch) ,
            "food_evening_amount":this.getArraytoSitingValue(states.selectedEvening) ,
            "food_dinner_amount":this.getArraytoSitingValue(states.selectedDinner) ,
            "food_late_night_amount":this.getArraytoSitingValue(states.selectedLateNight),



            "water_consumption": states.selectedWaterConsumption && states.selectedWaterConsumption.value ? states.selectedWaterConsumption.value : null
        }



        var service_url = Constants.NUTRITION_SAVE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.NutritionSaveSuccess,
            this.NutritionFailure
        );

    }



    NutritionSaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            // this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
        this.clearAllData()
    }
    NutritionFailure = error => {
        this.props.showResposeValue('error', error.message)
      //  console.log("obstetricalHistorySaveFailure <<~~~~~>> " + JSON.stringify(error))
    }

    getFoods() {
        var service_url =
            Constants.NUTRITIONS_FOODS_HISTORY +
            "?patient_id=" + this.state.patientAppointment.patient_id


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getFoodsSuccess,
            this.getFoodsFailure
        );
    }

    getFoodsSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                getFoodRegularType: response.data,
                earlyMorningFoods:response.data.early_morning,
                breakFastFoods:response.data.breakfast,
                midMorningFoods:response.data.mid_morning,
                lunchFoods:response.data.lunch,
                eveningFoods:response.data.evening,
                dinnerFoods:response.data.dinner,
                lateNightFoods:response.data.late_night
            })
            // alert(JSON.stringify(response.data))
        }
    };

    getFoodsFailure = (error) => {

    }

    clearAllData() {
        var states = this.state;
        states["NutritionType"] = {}
        states["foodType"] = {}
        states["selectedBreakfast"] = []
        states["selectgetFoodRegularType"] = {}
        states["selectedMorning"] = []
        states["selectedMidMorning"] = []
        states["selectedLunch"] = []
        states["selectedEvening"] = []
        states["selectedDinner"] = []
        states["selectedLateNight"] = []
        states["selectedWaterConsumption"] = {}
        states["counselling_date"] = ""
        this.setState({
            states
        })
    }

    render() {
        return (
            <View style={styles.container}>
                <DoctorNotesCommonRightSideHeader title={"Nutrition"} clearAllData={this.clearAllData.bind(this)} />

                <View style={{ marginTop: 15, marginBottom: 15 }}>
                    <View style={[Style.NutrionSectionShadow, Style.allButtonBorderRadius]}>
                        {this.renderNutritionType()}
                    </View>
                </View>


                <HistoryYesOrNoSection
                    selectedValue={this.state.selectedValue}
                    label={"Have you ever had Nutritional counselling?"}
                    detailsOfYesValue={this.state.counselling_date}
                    onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                    sectionKey={"counselling_date"}
                    placeholder={"How long ago?"}
                    showDetailsText={true}
                    maxLength={100}

                />

                <View style={{ marginTop: 15, marginBottom: 15 }}>
                    <View style={[Style.NutrionSectionShadow, Style.allButtonBorderRadius]}>
                        {this.renderNutritionFoodType()}
                    </View>
                </View>
                {this.state.foodType && this.state.foodType.value ?
                    <View style={{ marginTop: 15, marginBottom: 15 }}>
                        <View style={[Style.NutrionSectionShadow, Style.allButtonBorderRadius]}>
                            {this.renderNutritionFoods(this.state.foodType.value)}
                        </View>
                    </View> : null
                }

                <View>
                    <CommonAddButton
                        onPressAddButton={this.addNutrition.bind(this)}
                    />
                </View>

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
});
