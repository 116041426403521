import React, { Component } from "react";
import {
  // Alert,
  View,
  Text,
  TextInput,
  // Button,
  Picker,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  // Modal,
  TouchableWithoutFeedback,
  ActivityIndicator,

  // ColorPropType,
} from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import Service from '../../../network/Service';

import moment from "moment";
import { DatePicker, TimePicker } from 'antd';

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

var cancelOption = [
  { label: "Cancel Appointment", value: "Cancel Appointment" },
  // { label: "Reschedule", value: "Reschedule" },
]

export default class CancelAppointment extends Component {
  constructor(props) {
    super(props);
    this.reference=React.createRef(),

    this.state = {
      modelvisible: this.props.modelvisible,
      ErrorCancelAppointment: "",
      cancelAppointmentReason: "",
      selectedCancelOption: "Cancel Appointment",
      doctorList: [],
      reSchuAppointmentDate : moment(new Date()).format("YYYY-MM-DD"),
      reScheduleReason: "",
      patientAppointment: this.props.patientAppointment,
      selectedDoctorID: this.props.selectedClinicOrDoctor ? this.props.selectedClinicOrDoctor.id : "",
      userType: this.props.userType,
      reScheduleTime : "",
      errorMsg:"",
      selectedAppointmentStatus: this.props.selectedAppointmentStatus ? this.props.selectedAppointmentStatus : ""
    };
  }
  componentDidMount() {
    this.getDoctors()
  }
 
  getDoctors = () => {
    var serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getClinicSuccess,
      this.getClinicFailure
    );
  };

  getClinicSuccess = success => {
    if (success.status == "success") {
      this.setState({
        doctorList: success.data
      },()=>{
        const isNursing = this.state.doctorList.find(item => item?.first_name == "Nursing")
        if(isNursing?.first_name == "Nursing") { 
          this.state.doctorList.pop()
        }
      })
    }
  }
  getClinicFailure = error => {

  }
  CloseappointmentModel = () => {
    this.props.CancelAppointmentVisible(false)
  };
  renderRadioButtonView = () => {
    return (
      <View>
        <View style={{ flexDirection: 'row', paddingTop: 10, marginLeft: 30 }}>
          {
            cancelOption.map((item, index) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      selectedCancelOption: item.value,
                      reScheduleReason: "",
                      cancelAppointmentReason: ""
                    })
                  }}
                  style={{ marginRight: 20, flexDirection: "row", alignItems: "center" }}
                  key={index}
                >
                  <View style={{
                    backgroundColor: this.state.selectedCancelOption == item.value ? color.themeDark : "",
                    marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
                  }}></View>
                  <Text style={{ fontWeight: "500", fontSize: 13 }}>{item.label}</Text>

                </TouchableOpacity>
              )
            })
          }

        </View>
      </View>
    )
  }
  RescheduleValidation = (item) =>{
    if(this.state.reScheduleTime == ""){
      this.setState({
        errorMsg:"Please Select Reschedule Time"
      })
    }else if(this.state.reScheduleReason == ""){
      this.setState({
        errorMsg:"Please Enter Reschedule Reason"
      })
    }else {
      this.setState({
        errorMsg:""
      }, () => {this.onPressReschedule(item)})
    }
  }
  onPressReschedule = (item) => {
    if( this.state.userType == Constants.ROLE_FO ) {
      var serviceUrl = Constants.HOME_APPOINTMENT_RESCHEDULE_FO;
    } else {
      var serviceUrl = Constants.HOME_APPOINTMENT_RESCHEDULE_DOCTOR;

    }
    var reScheduleTime = moment(this.state.reScheduleTime, ["h:mm:ss A"]).format("HH:mm:ss");

    var data = {
      "appointment_id": item,
      "appointment_date": this.state.reSchuAppointmentDate,
      "appointment_time": reScheduleTime,
      "reschedule_reason": this.state.reScheduleReason,
      "appointment_status":"Reschedule"
    }
    if( this.state.userType == Constants.ROLE_FO ) {
       data['doctor_id'] = parseInt(this.state.selectedDoctorID);
    }
    OpthamologyService.getInstance().postComplaints(
      serviceUrl,
      data,
      this,
      this.onPressRescheduleSuccess,
      this.onPressRescheduleFailure
    );

  }
  onPressRescheduleSuccess = (response) => {
     if(response.status == "Success") {
       this.props.showResposeValue("success", response.message)
       this.props.CancelAppointmentVisible(false)

     } else {
      this.props.showResposeValue("error", response.message)
      this.props.CancelAppointmentVisible(false)

     }
  }
  onPressRescheduleFailure = (error) => {
     this.props.showResposeValue("error", error.message)
     this.props.CancelAppointmentVisible(false)
  }
  disabledDate = (current) => {
    return current && current < moment().endOf('day')
  }
  ShowCancelAppointmentModel = (type, item) => {

    return (
      <View style={{ position: "absolute" }}>
        <View
          style={{
            height: screenHeight,
            width: screenWidth,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                backgroundColor: "white",
                width: "40%",
                borderRadius: 10,
                padding: 20,
              }}
            >
              <View style={{ flex: 1 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <Text style={{ color: "black", fontSize: 19, marginTop: 15 }}>
                    Cancel Appointment
                  </Text> */}
                  {this.renderRadioButtonView()}
                  <TouchableOpacity
                    onPress={() => {
                      this.CloseappointmentModel();
                    }}
                    style={{ fontSize: 30 }}
                  >
                    <Icon
                      name="window-close-o"
                      size={17}
                      color={color.themeDark}
                    />
                  </TouchableOpacity>
                </View>
              { this.state.selectedCancelOption === "Cancel Appointment" ?
               <View>
                <TextInput
                  multiline={true}
                  placeholder={"Reason for Cancellation"}
                  underlineColorAndroid="transparent"
                  style={{
                    alignSelf: "left",
                    padding: 10,
                    color: color.black,
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 15,
                    height: 80,
                    borderRadius: 10,
                    borderColor: "grey",
                    borderWidth: 1,fontSize: 16
                  }}
                  placeholderTextColor={"grey"}
                  autoCapitalize="none"
                  value={this.state.cancelAppointmentReason}
                  onChangeText={(Testvalue) => {
                    const text = Testvalue.match(/^ *$/)? "" : Testvalue;
                    this.setState({
                      cancelAppointmentReason: text,
                    });
                  }}
                />
                <Text
                  style={{
                    marginBottom: 15,
                    fontFamily: 13,
                    fontWeight: "600",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  {this.state.ErrorCancelAppointment}
                </Text>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <TouchableOpacity
                    style={{
                      backgroundColor: this.state.isCreateClicked
                        ? "#888888"
                        : color.themeDark,
                      padding: 7,
                      borderRadius: 4,
                      width: 120,
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      {
                        this.state.cancelAppointmentReason ?
                          (
                            this.props.CancelAppointmentVisible(false, type, item, this.state.cancelAppointmentReason)
                          )
                          : this.setState({
                            ErrorCancelAppointment:
                              "Enter Reason for Cancellation",
                          });
                      }
                    }}
                  >
                    <Text style={{ textAlign: "center", color: "white" }}>
                      Confirm
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "white",
                      padding: 7,
                      borderRadius: 4,
                      width: 120,
                      justifyContent: "center",
                      borderWidth: 1,
                      marginLeft: 5,
                      borderColor: "grey",
                    }}
                    onPress={() => {
                      this.CloseappointmentModel();
                    }}
                  >
                    <Text style={{ textAlign: "center" }}>Close</Text>
                  </TouchableOpacity>
                </View>  
                </View> : 
                  <View>
                    <View style={{ marginTop: 10, flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    { this.state.userType == Constants.ROLE_FO && this.state.selectedAppointmentStatus && this.state.selectedAppointmentStatus != "On Queue" ?
                     <View> 
                      <Picker
                        selectedValue={this.state.selectedDoctorID}
                        style={{ borderRadius: 20, height: 30, paddingLeft: 10, fontSize: 12, width: "100%" }}
                        onValueChange={(itemvalue) => {

                          this.setState({
                            selectedDoctorID: itemvalue,
                          })
                         }}
                      >
                        {this.state.doctorList.map((i, index) => (
                          <Picker.Item key={index} label={i.first_name} value={i.doctor_id} />
                        ))}
                      </Picker> 
                      </View>
                     : null }
                      <TimePicker
                    // disabled={this.state.selectedIpPatientDetails&& this.state.selectedIpPatientDetails.id ? true: false}
                    use12Hours
                    format="h:mm a"
                    onOk={()=>{
                        this.reference.current.blur()
                    }}
                    onChange={(time, timeString) => {
                       
                        var states = this.state;
                        states["reScheduleTime"] = timeString
                        states["errorMsg"] = ""
                        this.setState({ states })
                         
                    }}
                    clearIcon = {false}
                    ref={this.reference? this.reference: null}

                    // placeholder=""
                    // value={ this.state.reScheduleTime ? moment(this.state.reScheduleTime,"hh:mm a"):this.state.reScheduleTime}
                    style={{
                        textAlignVertical: 'top',
                        // borderColor: error ? "red" : "#CDD1D5",
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "23%",
                        height: 30,
                        // padding: 5,
                        paddingLeft: 10,
                    }}
                />
                      <DatePicker
                        disabledDate={this.disabledDate}
                        value={moment(this.state.reSchuAppointmentDate)}
                        onChange={(date) => {
                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate).format("YYYY-MM-DD");

                          this.setState({ reSchuAppointmentDate: converted_date });
                        }
                        }
                        clearIcon={false}
                        format={"DD-MM-YYYY"}
                        placeholder={"Select"}
                        style={{
                          height: 30,
                          borderWidth: 1,
                          borderColor: color.rightPanelInputBorder,
                          borderRadius: 5,
                          width: "35%"

                        }}
                      /> 
                    </View>
                    <TextInput
                      multiline={true}
                      placeholder={"Reschedule Reason"}
                      underlineColorAndroid="transparent"
                      style={{
                        alignSelf: "left",
                        padding: 10,
                        color: color.black,
                        width: "100%",
                        marginTop: 20,
                        marginBottom: 15,
                        height: 80,
                        borderRadius: 10,
                        borderColor: "grey",
                        borderWidth: 1, fontSize: 16
                      }}
                      placeholderTextColor={"grey"}
                      autoCapitalize="none"
                      value={this.state.reScheduleReason}
                      onChangeText={(Testvalue) => {
                        const text = Testvalue.match(/^ *$/) ? "" : Testvalue;
                        this.setState({
                          reScheduleReason: text, errorMsg: ""
                        });
                      }}
                    />
                    <View style={{display:"flex",height:"1.3vw", alignItems:"center", justifyContent:"center", marginBottom:".75vw"}}><Text style={{fontSize:"1vw", fontWeight:"500", color:"red"}}>{this.state.errorMsg}</Text></View>
                    <View style={{ flexDirection: "row", justifyContent: "right" }}>
                  <TouchableOpacity
                    style={{
                      backgroundColor: this.state.isCreateClicked
                        ? "#888888"
                        : color.themeDark,
                      padding: 7,
                      borderRadius: 4,
                      width: 120,
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                        this.RescheduleValidation(item)
                    }}
                  >
                    <Text style={{ textAlign: "center", color: "white" }}>
                      Save
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "white",
                      padding: 7,
                      borderRadius: 4,
                      width: 120,
                      justifyContent: "center",
                      borderWidth: 1,
                      marginLeft: 5,
                      borderColor: "grey",
                    }}
                    onPress={() => {
                      this.CloseappointmentModel();
                    }}
                  >
                    <Text style={{ textAlign: "center" }}>Close</Text>
                  </TouchableOpacity>
                </View>
                  </View>
                }
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  };

  render() {
    return (
      <View style={{ position: "absolute", zIndex: 1 }}>
        <TouchableWithoutFeedback
          onPress={() => { this.CloseappointmentModel() }}
        >
          {this.state.modelvisible ? (this.ShowCancelAppointmentModel(
            this.props.CancelAppointment_type,
            this.props.CancelAppointment_item
          )) : null}

        </TouchableWithoutFeedback>
      </View>
    );
  }
}
