//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, TextInput, Picker,ScrollView, TouchableWithoutFeedback } from 'react-native';
import { CommonButton, NavigationTopHeader, CommonHistorySectionHeader ,CommonDropDownPicker} from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import  CommonDateFilter  from '../../BaseComponent';

// import { ScrollView, TouchableWithoutFeedback } from 'react-native-gesture-handler';
const screenHeight = Dimensions.get("window").height;
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
import moment from "moment";
import Style from '../../../../styles/Style';

// create a component

const navigationHeaderList = [
    { label: "Token - Service Time", value: "serviceTime" },
    { label: "Token - Counter Active Time", value: "counterActiveTime" }
]

export class Token extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "serviceTime",
            // selectedDepartment: "",
            counterActiveTimeList: [],
            counterActiveTimeDetails: {},

            serviceTimeList: [],

            selectedSlugName: "",
            startTime: "",
            endTime: "",

            prepareCounterArrayList: [],
            selectedDepartment: {},
            selectedDepartmentName: "",

            listOfCountersResult: [],

            showPickerDropDownFlag:false

        }
    }

    componentDidMount() {
        this.getCliniLinkedUserDetails();
        this.getCounterActiveTimeListFilter();
        // this.getCounterActiveTimeList();       // colunter active Time 
        this.getServiceTimeListFilter(); // service Time
    }



    // getCounterActiveTimeList() {

    //     var getCounterID = "";
    //     var clintID = "";
    //     if (this.props.counterID) {
    //         getCounterID = this.props.counterID
    //     }

    //     if (this.props.clintID) {  
    //         clintID = this.props.clintID
    //     }


    //     var counterID = clintID.toString()+getCounterID.toString()
    //     // var service_url = Constants.QMS_COUNTER_ACTIVETIME+"?r="+counterID;
    //     // var service_url = Constants.QMS_COUNTER_ACTIVETIME + counterID + "/";
    //     var service_url = Constants.QMS_CLIENT_ACTIVETIME + clintID + "/";

    //     OpthamologyService.getInstance().getComplaints(
    //         service_url,
    //         this,
    //         this.getCounterActiveTimeListSuccess,
    //         this.getCounterActiveTimeListFailure,
    //         "",
    //         "qms"
    //     );
    // }
    // getCounterActiveTimeListSuccess = success => {
    //     console.log("success ---------> "+JSON.stringify(success))
    //     if (success.status == "success") {
    //         this.setState({
    //             // counterActiveTimeList: success.data.history,
    //             // counterActiveTimeDetails: success.data

    //         })
    //     }

    // }
    // getCounterActiveTimeListFailure = error => {
    //     alert("error *******************> " + JSON.stringify(error))

    // }



    renderTopNavigation() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={this.state.selectedTab} />
            </View>
        )
    }

    changeTabSection(tokenType) {
        this.onPressClearButton()
        this.setState({ selectedTab: tokenType }, () => { })
        if (tokenType == "serviceTime") {
            this.getServiceTimeListFilter(); // service Time
        } else if (tokenType == "counterActiveTime") {
            // this.getCounterActiveTimeList();       // colunter active Time 
            this.getCounterActiveTimeListFilter()       // colunter active Time 
        }
    }

    renderServiceTimeHeader() {
        return (
            <CommonHistorySectionHeader
                heading1={"Date"}
                heading2={"Department Name"}
                heading3={"Room No"}
                heading4={"Token No"}
                heading5={"Start Time"}
                heading6={"End Time"}
                columnSize={[0.13, 0.26, 0.16, 0.14, 0.15, 0.15]}
                noOfColumn={6}
            />
        )
    }

    getTime(dateAndTime) {
        // var fullTime="2021-01-20T11:43:54.622524";
        var fullTime = dateAndTime;
        var getTime = ""
        for (var i = 1; i < fullTime.length; i++) {
            if (fullTime[i - 1] == "T") {
                getTime = fullTime.substring(i, (fullTime.length) - 7);
                return getTime
            }
        }
    }

    renderServiceTimeList() {



        return (
            <View>
                {
                    this.state.serviceTimeList.length > 0 ?
                        this.state.serviceTimeList.map((item, index) => {
                            return (

                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 10 }}>
                                    <Text style={[styles.recordFontStyle, { marginHorizontal: 20, flex: 0.13 }]}>{moment(item.start_time).format("DD-MM-YYYY")}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.26 }]}>{item.counter_name}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.16 }]}>{item.room_number}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.14 }]}>{item.token_number}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.15 }]}>{item.start_time ? this.getTime(item.start_time) : "-"}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.15 }]}>{item.end_time ? this.getTime(item.end_time) : "-"}</Text>
                                </View>
                            )
                        }) : null
                }
            </View>
        );
    }

    renderCounterActiveTimeHeader() {
        return (
            <CommonHistorySectionHeader
                heading1={"Date"}
                heading2={"Department Name"}
                heading3={"Room No"}
                heading4={"Start Time"}
                heading5={"End Time"}
                columnSize={[0.16, 0.29, 0.19, 0.18, 0.18]}
                noOfColumn={5}
            />
        )
    }

    // renderCounterActiveLists() {
    //     if (this.state.listOfCountersResult.length > 0) {
    //         this.state.listOfCountersResult.map((item, index) => {
    //             return this.renderCounterActiveTimeList(item, index);
    //         })
    //     }
    // }

    renderCounterActiveTimeList(counter, index) {
        // console.log(counter)

        return (
            <View key={index}>
                {
                    counter.history.length > 0 ?
                        counter.history.map((item, index) => {
                            // console.log("counter" + JSON.stringify(item))

                            return (
                                <View key={index} style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 10 }}>
                                    <Text style={[styles.recordFontStyle, { marginHorizontal: 20, flex: 0.16 }]}>{moment(item.start_time).format("DD-MM-YYYY")}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.29 }]}>{counter.name}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.19 }]}>{counter.room_number}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.18 }]}>{item.start_time ? this.getTime(item.start_time) : "-"}</Text>
                                    <Text style={[styles.recordFontStyle, { flex: 0.18 }]}>{item.end_time ? this.getTime(item.end_time) : "-"}</Text>
                                </View>
                            )
                        }) : null
                }
            </View>
        )



    }

    renderContainer() {
        return (
            <View style={{ flex: 1, flexDirection: 'row', marginHorizontal: 15, marginVertical: 10, borderRadius: 4, backgroundColor: color.white }}>
                <View style={{ flex: 0.65, paddingHorizontal: 15, paddingVertical: 10 }}>

                    {
                        this.state.selectedTab == "serviceTime" ?
                            this.renderServiceTimeHeader() :
                            this.renderCounterActiveTimeHeader()

                    }

                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height:screenHeight-180 }}>
                        {/* 150 is total space for top contents like 65+45+20+35 = 165  will round 150 */}
                        {
                            this.state.selectedTab == "serviceTime" ?
                                this.renderServiceTimeList()
                                :


                                this.state.listOfCountersResult.length > 0 ?
                                    this.state.listOfCountersResult.map((item, index) => {
                                        return this.renderCounterActiveTimeList(item, index);
                                    })
                                : null


                                // this.renderCounterActiveLists()
                        }



                    </ScrollView>
                </View>
                <View style={{
                    flex: 0.35, backgroundColor: color.themeShade,
                    borderLeftColor: "#E8E8E8", borderLeftWidth: 0.5,
                    paddingHorizontal: 15, paddingVertical: 20
                }}>

                    {this.renderRightSideHeader()}
                    {/* {this.renderDepartmentName()} */}
                    {/* {this.renderDepartmentDropDown("Department Name", "selectedDepartment", this.state.selectedDepartment)} */}
                    {this.renderDepartmentDropDown("Department Name", "selectedDepartment", this.state.selectedDepartment)}
                    <View style={{zIndex:-1}}>
                        {this.renderDateFilter()}
                        {this.renderFilterActionButton()}
                    </View>
                </View>
            </View>
        )
    }



    renderDepartmentDropDown(label, key, selectedValue) {
        // if(selectedValue && Object.keys(selectedValue).length > 0){
        //     selectedValue=JSON.parse(selectedValue)
        // }
        return (
            <View style={{ marginTop: 15 }}>
                <CommonHistorySectionHeader
                    heading1={label}
                    columnSize={[1]}
                    noOfColumn={1}
                // sub
                />
                {/* <Picker
                    // disabled={this.state.isEditFlag}

                    selectedValue={this.state.selectedDepartmentName}
                    style={[Style.pickerView, {
                        marginLeft: 20, marginTop: 10, borderColor: '#888888', backgroundColor: color.themeShade
                    }, Style.allButtonBorderRadius]}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {


                        
                        var states = this.state;

                        var getSelectedItem = this.state.prepareCounterArrayList[itemvalue]
                        // states["selectedDepartment"]=getSelectedItem;
                        // states["selectedDepartmentName"]=getSelectedItem.name;

                        this.setState({
                            selectedSlugName: this.props.clintID.toString() + getSelectedItem.id.toString(),
                            selectedDepartmentName: JSON.stringify(getSelectedItem.name)
                        }, () => {  }
                        );
                    }}
                >
                    <Picker.Item label="Select Counter " value="" />
                    {
                        this.state.prepareCounterArrayList.length > 0 ?
                            this.state.prepareCounterArrayList.map((item, index) => {
                                // let temp = JSON.stringify(item)
                                return (
                                    <Picker.Item label={item.name} value={index} key={index} />
                                )
                            })
                            : null
                    }
                </Picker> */}
            <View style={{marginTop:15}}>
                <CommonDropDownPicker
                showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                selectedValue={this.state.selectedDepartment}
                arrayOfData={this.state.prepareCounterArrayList}
                // pickerKey="FO"
                />
                </View>
                
            </View>
        )
    }


    changePickerDropDownValue(flag, selectedValue){

        this.setState({
            showPickerDropDownFlag:flag,
            selectedDepartment:Object.keys(selectedValue).length > 0 ? selectedValue :this.state.selectedDepartment,
            selectedSlugName: selectedValue && selectedValue.id ?  this.props.clintID.toString() + selectedValue.id.toString() : this.state.selectedSlugName,

        })
    } 


    renderRightSideHeader() {
        return (
            <Text style={{ fontSize: 17, color: color.themeDark, fontWeight: '500' }}>{"Filter"}</Text>
        )
    }

    showSelectedFilteredDate(date) {
        this.setState({
            startTime: date.fromDate,
            endTime: date.toDate,
        })
    }

    renderDateFilter() {
        return (
            <View style={{ marginTop: 20 }}>
                <CommonHistorySectionHeader
                    heading1={"Date"}
                    columnSize={[1]}
                    noOfColumn={1}
                // sub
                />
                <View style={{ marginLeft: 20, marginTop: 10 }}>
                    <CommonDateFilter  startDate={this.state.startTime} endDate={this.state.endTime} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
                </View>

            </View>
        )
    }

    renderDepartmentName() {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Department"}
                    columnSize={[1]}
                    noOfColumn={1}
                // sub
                />
                <TextInput
                    style={{ marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, paddingLeft: 10, height: 35, width: "90%", marginLeft: 20 }}
                    value={this.state.departmentName}
                    onChangeText={(text) => {
                        var states = this.state;
                        states["departmentName"] = text;
                        this.setState({ states })
                    }}
                />
            </View>
        )
    }


    onPressClearButton() {
        var states = this.state;
        states["selectedDepartmentName"] = "";
        states["selectedDepartment"] = {};
        states["startTime"] = "";
        states["endTime"] = "";
        states["selectedSlugName"] = "";

        this.setState({
            states
        }, () => {
            if (this.state.selectedTab == "serviceTime") {
                this.getServiceTimeListFilter;
            } else {
                this.getCounterActiveTimeListFilter;
            }
        })
    }
    onPressSearchButton() {

    }

    // const navigationHeaderList = [
    //     { label: "Token - Service Time", value: "serviceTime" },
    //     { label: "Token - Counter Active Time", value: "counterActiveTime" }
    // ]

    renderFilterActionButton() {
        var states = this.state;
        var isServiceTime = true
        if (states.selectedTab == "serviceTime") {
            isServiceTime = true
        } else {
            isServiceTime = false
        }
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 20, zIndex: -1 }}>
                <View style={{}}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Search"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={isServiceTime ? this.getServiceTimeListFilter.bind(this) : this.getCounterActiveTimeListFilter.bind(this)}
                        buttonKey={"Search"} />
                </View>
                <View style={{ marginLeft: 15 }}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Clear"}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressClearButton.bind(this)}
                        buttonKey={"clear"} />
                </View>
            </View>
        )
    }

    render() {
        return (
            <TouchableWithoutFeedback onPress={()=>{
                this.setState({
                    showPickerDropDownFlag:false
                })
            }}>
            <View>
                {this.renderTopNavigation()}
                {this.renderContainer()}
            </View>
            </TouchableWithoutFeedback>
        );
    }


    // 


    getServiceTimeListFilter() {
        // var counterID = ""
        // if (this.props.counterID) {
        //     counterID = this.props.counterID
        // }
        var service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/";

        // var data ={}

        // selectedSlugName:"",
        // startTime:"",
        // endTime:"",
        var states = this.state;


        if (states.selectedSlugName && states.startTime && states.endTime) {
            service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&start_date=" + states.startTime + "&end_date=" + states.endTime
        } else if (states.selectedSlugName) {
            if (states.startTime) {
                service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&start_date=" + states.startTime;
            } else if (states.endTime) {
                service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&end_date=" + states.endTime
            } else {
                service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName;
            }
        } else if (states.startTime) {
            if (states.endTime) {
                service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?start_date=" + states.startTime + "&end_date=" + states.endTime
            } else {
                service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?start_date=" + states.startTime;
            }
        } else if (states.endTime) {
            service_url = Constants.QMS_TOKEN_FILTERS + this.props.clintID + "/" + "?end_date=" + states.endTime
        }


        // if (states.selectedSlugName || states.startTime || states.endTime) {
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getServiceTimeListFilterSuccess,
            this.getServiceTimeListFilterFailure,
            "",
            "qms"
        );
        // }
    }
    getServiceTimeListFilterSuccess = success => {
        if (success.status == "success") {
            this.setState({
                serviceTimeList: success.data,
                // counterActiveTimeDetails: success.data
            })
        }
    }
    getServiceTimeListFilterFailure = error => {
      //  alert("error *******************> " + JSON.stringify(error))

    }

    // 


    // get connected client 

    getCliniLinkedUserDetails() {
        // var clintID = ""
        // if (this.props.clintID) {
        //     clintID = this.props.clintID
        // }

        // var service_url = Constants.QMS_DEPARTMENT_DETAILS+"?client_id="+clintID;
        var service_url = Constants.QMS_GET_CLINIC_LINKED_USER_FROM_EHR;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getCliniLinkedUserDetailsSuccess,
            this.getCliniLinkedUserDetailsFailure,
            "",
        );
    }
    getCliniLinkedUserDetailsSuccess = success => {
        // console.log("************************>> " + JSON.stringify(success))
        if (success.status == "success") {
            if (success.data && success.data && success.data) {
                this.prepareingCounterList(success.data)
            }
        }
    }
    getCliniLinkedUserDetailsFailure = error => { }

    prepareingCounterList(list) {
        var getDoctorList = list.doctors;
        var getPharmacyList = list.pharmacy;
        var getLaboratoryList = list.laboratory;
        var getUsersList = list.users;

        var prepareCounterArrayList = []
        if (getDoctorList.length > 0) {
            for (let i = 0; i < getDoctorList.length; i++) {
                let prepareDate = getDoctorList[i];
                prepareDate["userType"] = "Doctor";
                prepareDate["id"] = prepareDate.id;
                prepareDate["name"] = prepareDate.name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        if (getPharmacyList.length > 0) {
            for (let i = 0; i < getPharmacyList.length; i++) {
                let prepareDate = getPharmacyList[i];
                prepareDate["userType"] = "Pharmacy";
                prepareDate["id"] = prepareDate.pharmacy_id;
                prepareDate["name"] = prepareDate.pharmacy_name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        if (getLaboratoryList.length > 0) {
            for (let i = 0; i < getLaboratoryList.length; i++) {
                let prepareDate = getLaboratoryList[i];
                prepareDate["userType"] = "Laboratory";
                prepareDate["id"] = prepareDate.laboratory_id;
                prepareDate["name"] = prepareDate.laboratory_name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        // if (getUsersList.length > 0) {
        //     for (let i = 0; i < getUsersList.length; i++) {
        //         let prepareDate = getUsersList[i];
        //         prepareDate["userType"] = "User";
        //         prepareDate["id"] = prepareDate.id;
        //         prepareDate["name"] = prepareDate.employee_name;
        //         prepareCounterArrayList.push(prepareDate);
        //     }
        // }

        this.setState({
            prepareCounterArrayList: prepareCounterArrayList
        })
        // console.log(prepareCounterArrayList)

    }
    // get connected clients 




    // filter for counter active time 

    getCounterActiveTimeListFilter() {
        // var counterID = ""
        // if (this.props.counterID) {
        //     counterID = this.props.counterID
        // }
        var service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/";

        // var data ={}

        // selectedSlugName:"",
        // startTime:"",
        // endTime:"",
        var states = this.state;


        if (states.selectedSlugName && states.startTime && states.endTime) {
            service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&start_date=" + states.startTime + "&end_date=" + states.endTime
        } else if (states.selectedSlugName) {
            if (states.startTime) {
                service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&start_date=" + states.startTime;
            } else if (states.endTime) {
                service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName + "&end_date=" + states.endTime
            } else {
                service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?counter_id=" + states.selectedSlugName;
            }
        } else if (states.startTime) {
            if (states.endTime) {
                service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?start_date=" + states.startTime + "&end_date=" + states.endTime
            } else {
                service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?start_date=" + states.startTime;
            }
        } else if (states.endTime) {
            service_url = Constants.QMS_CLIENT_ACTIVETIME + this.props.clintID + "/" + "?end_date=" + states.endTime
        }


        // if (states.selectedSlugName || states.startTime || states.endTime) {
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getCounterActiveTimeListFilterSuccess,
            this.getCounterActiveTimeListFilterFailure,
            "",
            "qms"
        );
        // }
    }
    getCounterActiveTimeListFilterSuccess = success => {
        if (success.status == "success") {
            // if(success.data[0].history){
            this.setState({
                // counterActiveTimeList: success.data[0].history,
                // counterActiveTimeDetails: success.data
                listOfCountersResult: success.data
            })
            // }
        }
    }
    getCounterActiveTimeListFilterFailure = error => {
      //  alert("error *******************> " + JSON.stringify(error))

    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
    recordFontStyle: {
        fontSize: 12
    }
});

//make this component available to the app

