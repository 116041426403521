import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "sexualAndMaritalHistory"


export class SexualHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofsexualHistory: {},

            hideEditIcon: false,
            isheading:false

        }
    }

    componentDidMount() {
        this.getsexualHistory()
    }

    getsexualHistory() {
        var service_url = Constants.PSY_SEXUAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getsexualHistorySuccess,
            this.getsexualHistoryFailure
        );
    }

    getsexualHistorySuccess = success => {
        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};

                    booleanValues.push(prepareData)
                } else if (key !== "neurotic_details" || key !== "psychiatic_details") {
                    var prepareData = {};
                    if (key == "puberty_age") {
                        prepareData = {
                            key: "Age of onset of puberty",
                            value: data[key],
                        }
                    } else if (key == "age_during_marriage") {
                        prepareData = {
                            key: "Age at time of marriage",
                            value: data[key],
                        }
                    } else if (key == "sex_knowledge_level") {
                        prepareData = {
                            key: "Knowledge regarding sex & mode of gaining the same",
                            value: data[key],
                        }
                    } else if (key == "mutual_consent_partner") {
                        prepareData = {
                            key: "Mutual consent of partners",
                            value: data[key],
                        }
                    } else if (key == "masturbation_practice") {
                        prepareData = {
                            key: "Masturbation practices",
                            value: data[key],
                        }
                    } else if (key == "pair_interactions") {
                        prepareData = {
                            key: "Nature of pair interactions",
                            value: data[key],
                        }
                    } else if (key == "anxiety_fantasy") {
                        prepareData = {
                            key: "Anxiety related to fantasy practices",
                            value: data[key],
                        }
                    } else if (key == "partner_age") {
                        prepareData = {
                            key: "Partner's Age",
                            value: data[key],
                        }
                    } else if (key == "homo_fantasy") {
                        prepareData = {
                            key: "Homosexual fantasies",
                            value: data[key],
                        }
                    } else if (key == "partner_education") {
                        prepareData = {
                            key: "Partner's Education",
                            value: data[key],
                        }
                    } else if (key == "hetero_fantasy") {
                        prepareData = {
                            key: "Heterosexual fantasies",
                            value: data[key],
                        }
                    } else if (key == "partner_personality") {
                        prepareData = {
                            key: "Partner's Personality",
                            value: data[key],
                        }
                    } else if (key == "inclination_practice") {
                        prepareData = {
                            key: "Inclinations and Practices",
                            value: data[key],
                        }
                    } else if (key == "children_age") {
                        prepareData = {
                            key: "Children's Age",
                            value: data[key],
                        }
                    } else if (key == "menstraul_history") {
                        prepareData = {
                            key: "Menstrual History",
                            value: data[key] && data[key] == "1" ? "Menarche" : data[key] && data[key] == "2" ? "Regularity" : data[key] && data[key] == "3"?"Dysmenorechoea": data[key] && data[key] == "4"?"Monorehajinal otigonerchea":"",
                        }
                    } else if (key == "partner_health") {
                        prepareData = {
                            key: "Partner's Health",
                            value: data[key],
                        }
                    } else if (key == "marriage_type") {
                        prepareData = {
                            key: "Marriage Type",
                            value: data[key] && data[key] == "1" ? "Arranged" : data[key] && data[key] == "2" ? "Love" :"",
                        }
                    }  else if (key == "martial_relation_quality") {
                        prepareData = {
                            key: "Quality of marital relationship",
                            value: data[key],
                        }
                    } else if (key == "emotional_disturbance") {
                        prepareData = {
                            key: "Emotional Disturbance",
                            value: data[key],
                        }
                    } else if (key == "extra_martial_relation") {
                        prepareData = {
                            key: "Extra marital relationship",
                            value: data[key],
                        }
                    }
                    stringVaues.push(prepareData)
                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofsexualHistory: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getsexualHistoryFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getsexualHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofsexualHistory, pageName)
        }, 100);

    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Sexual & Marital History"}
                editImage={this.state.hideEditIcon || Object.keys(this.state.getListofsexualHistory).length == 0  ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if (item.value ) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
