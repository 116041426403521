import React, { Component } from 'react';
import { View, Text, Switch, StyleSheet, TextInput, TouchableOpacity, ScrollView, Image, Dimensions } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import moment from "moment";
import AsyncStorage from '../../../../AsyncStorage';
const screenHeight = Dimensions.get("window").height;

const navigationHeader = [
  { label: "Vitals", value: "oTVitals" },
  { label: "Reports", value: "reportsScreen" },
  { label: "Surgery Notes", value: "oTSurgeryNotes" },
  { label: "Billing", value: "oTBilling" }
]
const tempRowsDrugs = {
  "brand_name": "",
  "dosage_type": "",
  "dosage_strength": "",
  // flags
  "isDrugSelected": false,
  "isDosageSelected": false,
  "isStrengthSelected": false,
  // needed
  "line_item_id": "",
  "drug_id": "",
  "hsn_code": "",
  "batch_no": "",
  "quantity": "",
  "expiry_date": "",
  "physical_box_no": "",
  "cost_per_quantity": 0,
  "total_cost": "",
  "discount_percentage": "",
  "discount_amount": "",
  "gst_percentage": "",
  "net_total_amount": "",
  // extra
  "batch_details": "select",
  "stock_in_quantity": "0",
  "id": "",
  "batchAvilDisplay": ""
};
const tempRowsIOL = {
  "brand_name": "",
  "type": "",
  "power": "",
  // flags
  "isDrugSelected": false,
  "isDosageSelected": false,
  "isStrengthSelected": false,
  // needed
  "line_item_id": "",
  "drug_id": "",
  "hsn_code": "",
  "batch_no": "",
  "quantity": "",
  "expiry_date": "",
  "physical_box_no": "",
  "cost_per_quantity": "",
  "total_cost": "",
  "discount_percentage": "",
  "gst_percentage": "",
  "net_total_amount": "",
  // extra
  "batch_details": "select",
  "stock_in_quantity": "0",
  "id": "",
  "batchAvilDisplay": ""

};

export default class OTBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSwitchFlag: false,
      drugBillingRow: [],
      IOLBillingRow: [],
      typeIndex: "",
      discountAmount: 0,
      discountPercentage: 0,
      drugSugessionList: [],
      iolSugessionList: [],
      subAmount: 0.00,
      general_discount_percentage: 0,
      general_discount: 0.00,
      roundOff: 0.00,
      netAmount: 0,
      isOnFocusPer: false,
      isOnFocus: false,
      PatientDetail: this.props.PatientDetail,
      ipNumber: this.props.PatientDetail.ip_number,
      savedBill: [],
      isOPT: "",
      ipAdmissionID: this.props.PatientDetail.ip_admission,
    };
  }
  async componentDidMount() {

   this.getSavedBillList()
   var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isOPT : loggedInDataObj.clinic_type?.is_opt_clinic || loggedInDataObj.service_type == "OPT" || loggedInDataObj.is_OPT ? true : false,
      toggleSwitchFlag : loggedInDataObj.service_type == "OPT" ? false : true
    })

  }

  getSavedBillDetails = (bill_id) => {
    var url = Constants.OT_BILLING_POST + "?id=" + bill_id
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSavedBillSuccess,
      this.getSavedBillFailure
    );
  }

  getSavedBillSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        savedBill: response.data
      })
    }
  }

  getSavedBillFailure = response => {
  }

  getSavedBillList = () => {
    var bill_type = this.state.toggleSwitchFlag ? "Drug" : "IOL"
    var url = Constants.OT_SAVED_BILL + "?bill_type=" + bill_type
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSavedBillSuccess,
      this.getSavedBillFailure
    );
  }
  getSavedBillSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        savedBill: response.data
      },() => {
        var { savedBill } = this.state
        var bill_id = ""
        if(this.state.savedBill && this.state.savedBill.length > 0 ){
         for ( let i=0; i < savedBill.length; i++){
          if(savedBill[i].bill_type === "IOL" && !this.state.toggleSwitchFlag) {
            bill_id = savedBill[i].id
          } else {
            bill_id = savedBill[i].id
          }
         }
          this.getSavedBillDetails(bill_id)
        } 
      })
    }
  }
  getSavedBillFailure = error => {
  }
  getDrugSugession = (brand_name) => {
    var url = Constants.BILLING_DRUG_SUGESSION + "?brand_name=" + brand_name
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getDrugSugessionSuccess,
      this.getDrugSugessionFailure
    );
  }
  getDrugSugessionSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        drugSugessionList: response.data
      })
    }
  }
  getDrugSugessionFailure = response => {
  }
  getIOLSugession = (brand_name) => {
    var url = Constants.BILLING_IOL_SUGESSION + "?brand_name=" + brand_name
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getIOLSugessionSuccess,
      this.getIOLSugessionFailure
    );
  }

  getIOLSugessionSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        iolSugessionList: response.data
      })
    }
  }

  getIOLSugessionFailure = (error) => {

  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  renderSwitchToggle = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {this.state.isOPT ? <View style={{ flexDirection: "row", marginTop: "0.5vw" }}>
          <Text style={{ fontSize: "1.2vw", color: !this.state.toggleSwitchFlag ? color.themeDark : color.black }}>{"IOL"}</Text>
          <Switch
            style={{
              width: "1vw",
              height: "1vw",
              marginTop: "0.3vw",
              marginHorizontal: "0.8vw"
            }}
            thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
            onValueChange={() => {
              this.setState({
                toggleSwitchFlag: !this.state.toggleSwitchFlag,
                drugBillingRow: [],
                IOLBillingRow: [],
                subAmount: 0.00,
                general_discount_percentage: 0,
                general_discount: 0.00,
                roundOff: 0.00,
                netAmount: 0
              },() => {
                this.getSavedBillList()
              });
            }}
            value={this.state.toggleSwitchFlag}
          />
          <Text style={{ fontSize: "1.2vw", color: this.state.toggleSwitchFlag ? color.themeDark : color.black }}>{"Drug"}</Text>
        </View> : null }
        <View>
          <Text style={styles.searchBoxText}>IP No</Text>
          <TextInput
            value={this.state.ipNumber}
            onChangeText={(text) => {
             
            }}
            style={styles.textBox}
          />
        </View>
        <View style={{ marginLeft: "0.5vw", marginTop: "0.8vw" }}>{ 
        !this.state.ipNumber ? <Text style= {{ fontSize: "0.8vw", fontWeight: "500", color: color.red }}>{"Note: Kindly create IP Admission"}</Text> :
        this.state.PatientDetail.ip_status == "Checked Out" ? <Text  style= {{ fontSize: "0.8vw", fontWeight: "500", color: color.red }}>{"Note :  Patient is Already Checked-Out. So, Can't Bill for this Patient"}</Text> : null
          }</View>
      </View>

    )
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize }}>{title}</Text>
      </View>)
  }
  renderDrugSugession = () => {
    var { typeIndex, drugBillingRow } = this.state
    return (
      <View style={{ position: "absolute", width: "13vw", maxHeight: "10vw", zIndex: 10 }}>
        <ScrollView showsHorizontalScrollIndicator = {false} showsVerticalScrollIndicator = {false}
          style={{
            flex: 1,
            backgroundColor: color.black,
            borderRadius: 4,
            paddingHorizontal: '1vw',
          }}
        >
          { this.state.drugSugessionList && (this.state.drugSugessionList).map((item) => (
            <View>
              <TouchableOpacity
                key={typeIndex}
                style={{ marginTop: "0.5vw", marginBottom: "0.2vw" }}
                onPress={() => {
                  drugBillingRow[typeIndex]["brand_name"] = item.brand_name
                  drugBillingRow[typeIndex]["dosage_type"] = item.dosage_type
                  drugBillingRow[typeIndex]["dosage_strength"] = item.dosage_strength
                  drugBillingRow[typeIndex]["avaliable_quantity"] = item.avaliable_quantity
                  drugBillingRow[typeIndex]["expiry_date"] = item.expiry_date
                  drugBillingRow[typeIndex]["id"] = item.id
                  drugBillingRow[typeIndex]["batch_number"] = item.batch_no
                  drugBillingRow[typeIndex]["hsn_code"] = item.hsn_code
                  drugBillingRow[typeIndex]["cost_per_quantity"] = item.mrp_per_quantity
                  drugBillingRow[typeIndex]["physical_box_no"] = item.physical_box_number
                  drugBillingRow[typeIndex]["batchAvilDisplay"] = item.batch_no + " - " + item.avaliable_quantity

                  this.setState({
                    drugBillingRow,
                    drugSugessionList: []
                  })
                }}
              >
                <Text style={{ color: color.white, fontSize: "0.8vw" }}>{item.display_name}</Text>
              </TouchableOpacity>
            </View>

          ))}

        </ScrollView>

      </View>
    )
  }
  renderIOLSugessionView = () => {
    var { typeIndex, IOLBillingRow } = this.state
    return (
      <View style={{ position: "absolute", width: "13vw", maxHeight: "10vw", zIndex: 10 }}>
        <ScrollView showsHorizontalScrollIndicator = {false} showsVerticalScrollIndicator = {false}
          style={{
            flex: 1,
            backgroundColor: color.black,
            borderRadius: 4,
            paddingHorizontal: '1vw',
          }}
        >
          { this.state.IOLBillingRow["brand_name"] != "" && this.state.iolSugessionList && this.state.iolSugessionList.length > 0 && (this.state.iolSugessionList).map((item) => (
            <View>
              <TouchableOpacity
                key={typeIndex}
                style={{ marginTop: "0.5vw", marginBottom: "0.2vw" }}
                onPress={() => {
                  IOLBillingRow[typeIndex]["brand_name"] = item.brand_name
                  IOLBillingRow[typeIndex]["type"] = item.iol_type
                  IOLBillingRow[typeIndex]["power"] = item.iol_power
                  IOLBillingRow[typeIndex]["avaliable_quantity"] = item.avaliable_quantity
                  IOLBillingRow[typeIndex]["expiry_date"] = item.expiry_date
                  IOLBillingRow[typeIndex]["batch_number"] = item.batch_number
                  IOLBillingRow[typeIndex]["hsn_code"] = item.hsn_sac_code
                  IOLBillingRow[typeIndex]["cost_per_quantity"] = item.mrp_price_per_qty
                  IOLBillingRow[typeIndex]["physical_box_no"] = item.physical_box_number
                  IOLBillingRow[typeIndex]["id"] = item.id
                  IOLBillingRow[typeIndex]["batchAvilDisplay"] = item.batch_number + " - " + item.avaliable_quantity

                  this.setState({
                    IOLBillingRow,
                    iolSugessionList: []
                  })
                }}
              >
                <Text style={{ color: color.white, fontSize: "0.8vw" }}>{item.display_name}</Text>
              </TouchableOpacity>
            </View>

          ))}

        </ScrollView>

      </View>

    )
  }
  renderTextInput = (stateKey, title, index) => {
    var { drugBillingRow, IOLBillingRow, typeIndex } = this.state
    return (
      <View style={{ flexDirection: "column" }}>

        <TextInput
          style={{
            // flex: 1,
            height: '2vw',
            padding: "0.6vw",
            marginRight: "1vw",
            backgroundColor: color.white,
            borderColor: "#CDD1D5",
            borderRadius: "0.3vw",
            borderWidth: 1,
            fontSize: '0.71vw',
            zIndex: -1
          }}
          editable={stateKey === "quantity" || stateKey === "discount_percentage" || stateKey === "brand_name" ? true : false}
          value={title || ""}
          // keyboardType = {props.keyboardType}
          onChangeText={(text) => {
            var { states } = this.state
            if (this.state.toggleSwitchFlag) {
              var currentIndex = null
              if (stateKey === "brand_name") {
                currentIndex = index
                drugBillingRow[index]['id'] = ''
                drugBillingRow[index]['generic_name'] = ''
                drugBillingRow[index]['dosage_type'] = ''
                drugBillingRow[index]['dosage_strength'] = ''
                drugBillingRow[index]['batchAvilDisplay'] = ''
                drugBillingRow[index]['hsn_code'] = '' 
                drugBillingRow[index]['expiry_date'] = ''
                drugBillingRow[index]['physical_box_no'] = '' 
                drugBillingRow[index]['cost_per_quantity'] = '' 
                drugBillingRow[index]['quantity'] = ""
                drugBillingRow[index]['discount_percentage'] = ""
                drugBillingRow[index]['net_total_amount'] = ""
                drugBillingRow[index]['id'] = ""
                drugBillingRow[index][stateKey] = text;
                this.getDrugSugession(text)
              }

              this.updateDrugCalculation(stateKey, text, index)
              this.setState({
                states,
                typeIndex: currentIndex,
                subAmount: 0.00,
                general_discount_percentage: 0,
                general_discount: 0.00,
                roundOff: 0.00,
                netAmount: 0
              })
            } else {
              var currentIndex = null
              if (stateKey === "brand_name") {
                currentIndex = index
                IOLBillingRow[index]['id'] = ''
                IOLBillingRow[index]['power'] = ''
                IOLBillingRow[index]['type'] = ''
                IOLBillingRow[index]['batch_number'] = ''
                IOLBillingRow[index]['batchAvilDisplay'] = ''
                IOLBillingRow[index]['hsn_code'] = '' 
                IOLBillingRow[index]['expiry_date'] = ''
                IOLBillingRow[index]['physical_box_no'] = '' 
                IOLBillingRow[index]['cost_per_quantity'] = '' 
                IOLBillingRow[index]['quantity'] = ""
                IOLBillingRow[index]['discount_percentage'] = ""
                IOLBillingRow[index]['net_total_amount'] = ""
                IOLBillingRow[index]['id'] = ""
                IOLBillingRow[index][stateKey] = text;
                this.getIOLSugession(text)
              }
              this.updateIOLCalculation(stateKey, text, index)
              this.setState({
                states,
                typeIndex: currentIndex,
               
              },() => {
                this._updateDrugBillAmounts()
              })
            }

          }}
        />
        {typeIndex === index && stateKey === "brand_name" && this.state.drugBillingRow[index]["brand_name"] != "" && this.state.toggleSwitchFlag && this.state.drugSugessionList && this.state.drugSugessionList.length > 0 ?
          <View style={{ zIndex: 1 }}>{this.renderDrugSugession()}</View>
          : null}
        <View>
          {index === typeIndex && stateKey === "brand_name" && stateKey != "quantity" && this.state.IOLBillingRow[index]["brand_name"] != "" && !this.state.toggleSwitchFlag && this.state.iolSugessionList ?
            <View style={{ zIndex: 1 }}>{this.renderIOLSugessionView()}</View>
            : null
          }
        </View>
      </View>
    )
  }
  _handleItemRemove = (i) => {
    let { drugBillingRow, IOLBillingRow } = this.state;
    var billRows = []
    if (this.state.toggleSwitchFlag) {
      billRows = drugBillingRow.slice(0, i).concat(drugBillingRow.slice(i + 1, drugBillingRow.length))
    } else {
      billRows = IOLBillingRow.slice(0, i).concat(IOLBillingRow.slice(i + 1, IOLBillingRow.length))
    }
    this.setState({
      drugBillingRow: billRows,
      IOLBillingRow: billRows
    }, () => {
      this._updateDrugBillAmounts()
    })
  }
  _updateDrugBillAmounts = () => {
    var { drugBillingRow, IOLBillingRow } = this.state
    let taxable_amount = 0;
    let row_total = 0;
   if(this.state.toggleSwitchFlag) { 
    for (let i = 0; i < drugBillingRow.length; i++) {
      row_total = parseFloat(drugBillingRow[i]['net_total_amount']) || 0;
      taxable_amount = taxable_amount + row_total;
    }
   } else {
    for (let i = 0; i < IOLBillingRow.length; i++) {
      row_total = parseFloat(IOLBillingRow[i]['net_total_amount']) || 0;
      taxable_amount = taxable_amount + row_total;
    }
   }
    this.setState({
      subAmount: taxable_amount
    },() => {
      this.payableAmountCalculation()
    })
  }
  updateDrugCalculation = (stateKey, value, index) => {
    var { drugBillingRow } = this.state

    if (stateKey === "discount_percentage" && value.length < 3) {

      drugBillingRow[index]["discount_percentage"] = value;

    } else if (stateKey != "discount_percentage") {

      drugBillingRow[index][stateKey] = value;

    }

    let total_cost = 0;
    let cost = parseFloat(drugBillingRow[index]["cost_per_quantity"]);
    let qty = parseInt(drugBillingRow[index]["quantity"]) || 0;

    // total
    total_cost = parseFloat((cost * qty).toFixed(2)) || 0;
    drugBillingRow[index]['total_cost'] = total_cost

    // discount
    let discount_percentage = parseFloat(drugBillingRow[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
    let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
    drugBillingRow[index]['discount_amount'] = discount_amount
    // assign
    drugBillingRow[index]['total_cost'] = total_cost
    drugBillingRow[index]['net_total_amount'] = (total_cost - discount_amount).toFixed(2);

    this.setState({
      drugBillingRow
    }, () => {
      // this.getDrugSugession(value)
      if (stateKey === "quantity" || stateKey === "discount_percentage") {
        this._updateDrugBillAmounts()
        this.payableAmountCalculation()
    }
    })

  }
  updateIOLCalculation = (stateKey, value, index) => {
    var { IOLBillingRow } = this.state

    if (stateKey === "discount_percentage" && value.length < 3) {

      IOLBillingRow[index]["discount_percentage"] = value;

    } else if (stateKey != "discount_percentage") {

      IOLBillingRow[index][stateKey] = value;

    }

    let total_cost = 0;
    let cost = parseFloat(IOLBillingRow[index]["cost_per_quantity"]);
    let qty = parseInt(IOLBillingRow[index]["quantity"]) || 0;

    // total
    total_cost = parseFloat((cost * qty).toFixed(2)) || 0;
    IOLBillingRow[index]['total_cost'] = total_cost

    // discount
    let discount_percentage = parseFloat(IOLBillingRow[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
    let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
    IOLBillingRow[index]["discount_amount"] = discount_amount
    // assign
    IOLBillingRow[index]['total_cost'] = total_cost
    IOLBillingRow[index]['net_total_amount'] = (total_cost - discount_amount).toFixed(2);

    this.setState({
      IOLBillingRow
    }, () => {
      // this.getIOLSugession(value)
      if (stateKey === "quantity" || stateKey === "discount_percentage") {
        this._updateDrugBillAmounts()
        this.payableAmountCalculation()
      }
    })
  }
  getBillTableData(stateKey, title, flexWidth = 0.05, type = 'textBox', index = null) {
    var { drugBillingRow, IOLBillingRow } = this.state
    return (
      <View style={{ flex: flexWidth }}>
        {type === 'label' ? <Text style={{ fontSize: '1vw' }}>{title}</Text>
          : type === 'icon' ?
            (<TouchableOpacity onPress={() => { drugBillingRow[index]["brand_name"] || IOLBillingRow[index]["brand_name"] ? this._handleItemRemove(index) : null }} style={{ alignSelf: "center" }}>
              <Icon name="close" size={'1.17vw'} color={"#000000"} />
            </TouchableOpacity>)
            : stateKey === "quantity" ?
              <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: 60 }}>

                <View style={{ flex: 1 }}>
                  <TextInput
                    style={styles.qtyTextBox}
                    keyboardType="numeric"
                    value={title || ""}
                    onChangeText={(text) => {
                      if (this.state.toggleSwitchFlag) {
                        this.updateDrugCalculation(stateKey, text, index)
                      } else {
                        this.updateIOLCalculation(stateKey, text, index)
                      }
                      this.setState({
                        drugBillingRow,
                        IOLBillingRow
                      },() => {
                        this.payableAmountCalculation()
                      })
                    }}
                  /></View>

              </View>
              : stateKey === "net_total_amount" ?
                <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: 60 }}>

                  <View style={{ flex: 1 }}>
                    <TextInput
                      style={styles.netAmountContainer}
                      editable={false}
                      value={title || ""}
                      onChangeText={(text) => {
                        if (this.state.toggleSwitchFlag) {
                          drugBillingRow[index][stateKey] = text;
                        } else {
                          IOLBillingRow[index][stateKey] = text;
                        }
                        this.setState({
                          drugBillingRow,
                          IOLBillingRow
                        })

                      }}
                    /></View>

                </View> :
                <View style={{ zIndex: -1 }}>
                  {this.renderTextInput(stateKey, title, index)}
                </View>
        }
      </View>
    )
  }
  renderHeaderView = () => {
    return (
      <View style={{ flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center" }}>
        {this.state.toggleSwitchFlag ?
          <View style={{
            flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
            backgroundColor: color.sectionHeadingDarkColor, padding: 8, borderRadius: 4, marginTop: '1vw'
          }}>
            {this.getBillTableTitle('S.No', 0.03, "0.8vw")}
            {this.getBillTableTitle('Brand Name', 0.16, "0.8vw")}
            {this.getBillTableTitle('Type', 0.07, "0.8vw")}
            {this.getBillTableTitle('Strength', 0.08, "0.8vw")}
            {this.getBillTableTitle('Batch No - Avl Qty', 0.1, "0.8vw")}
            {this.getBillTableTitle('HSN Code', 0.07, "0.8vw")}
            {this.getBillTableTitle('Expiry Date', 0.06, "0.8vw")}
            {this.getBillTableTitle('Box No', 0.06, "0.8vw")}
            {this.getBillTableTitle('Cost/Qty', 0.06, "0.8vw")}
            {this.getBillTableTitle('Quantity', 0.07, "0.8vw")}
            {this.getBillTableTitle('Total Cost', 0.07, "0.8vw")}
            {this.getBillTableTitle('Discount %', 0.06, "0.8vw")}
            {this.getBillTableTitle('Total', 0.07, "0.8vw")}
            {this.getBillTableTitle('Action', 0.04, "0.8vw")}
          </View> :
          <View style={{
            flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
            backgroundColor: color.sectionHeadingDarkColor, padding: 8, borderRadius: 4, marginTop: '1vw'
          }}>
            {this.getBillTableTitle('S.No', 0.03, "0.8vw")}
            {this.getBillTableTitle('Brand Name', 0.16, "0.8vw")}
            {this.getBillTableTitle('Type', 0.07, "0.8vw")}
            {this.getBillTableTitle('Power', 0.08, "0.8vw")}
            {this.getBillTableTitle('Batch No - Avl Qty', 0.1, "0.8vw")}
            {this.getBillTableTitle('HSN Code', 0.07, "0.8vw")}
            {this.getBillTableTitle('Expiry Date', 0.06, "0.8vw")}
            {this.getBillTableTitle('Box No', 0.06, "0.8vw")}
            {this.getBillTableTitle('Cost/Qty', 0.06, "0.8vw")}
            {this.getBillTableTitle('Quantity', 0.07, "0.8vw")}
            {this.getBillTableTitle('Total Cost', 0.07, "0.8vw")}
            {this.getBillTableTitle('Discount %', 0.06, "0.8vw")}
            {this.getBillTableTitle('Total', 0.07, "0.8vw")}
            {this.getBillTableTitle('Action', 0.04, "0.8vw")}
          </View>
        }
      </View>
    )
  }
  renderDrugAndIOLTable = () => {
    var { drugBillingRow, IOLBillingRow } = this.state
    if (drugBillingRow.length < 10) {
      for (let i = drugBillingRow.length; i < 10; i++) {
        drugBillingRow.push(JSON.parse(JSON.stringify(tempRowsDrugs)))
      }
    }
    if (IOLBillingRow.length < 1) {
      for (let i = IOLBillingRow.length; i < 10; i++) {
        IOLBillingRow.push(JSON.parse(JSON.stringify(tempRowsIOL)))
      }
    }
    return (
      <View>
        {this.state.toggleSwitchFlag ?
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: "480px" }}>
            {drugBillingRow.map((item, index) => {
              let sno = index + 1;
              let rowLen = drugBillingRow.length || 0;

              let zIndex = (index + 1) * -10;
              // zIndexData = (index + 1) * (-100);
              return (

                <View key={index} style={{
                  flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                  backgroundColor: "#F9F9F9", padding: 8, borderRadius: 4, marginTop: 5,
                  borderBottomWidth: 1, borderColor: color.lightGray, zIndex: zIndex
                }}>
                  {this.getBillTableData('', sno, 0.03, 'label')}
                  {this.getBillTableData('brand_name', item.brand_name, 0.16, '', index)}
                  {this.getBillTableData('dosage_type', item.dosage_type, 0.07, '', index)}
                  {this.getBillTableData('dosage_strength', item.dosage_strength, 0.08, '', index)}
                  {this.getBillTableData('batch_no', item.batchAvilDisplay, 0.1, 'select', index)}
                  {this.getBillTableData('hsn_code', item.hsn_code, 0.07, '', index)}
                  {this.getBillTableData('expiry_date', item.expiry_date ? moment(item.expiry_date).format('YYYY-MM') : null, 0.06, '', index)}
                  {this.getBillTableData('physical_box_no', item.physical_box_no, 0.06, '', index)}
                  {this.getBillTableData('cost_per_quantity', item.cost_per_quantity, 0.06, '', index)}
                  {this.getBillTableData('quantity', item.quantity, 0.07, '', index)}
                  {this.getBillTableData('total_cost', item.total_cost, 0.07, '', index)}
                  {this.getBillTableData('discount_percentage', item.discount_percentage, 0.06, '', index)}
                  {this.getBillTableData('net_total_amount', item.net_total_amount, 0.07, '', index)}
                  {this.getBillTableData('', 'X', 0.04, 'icon', index)}
                </View>
              )
            })}
            <View style = {{ zIndex: -120 }}>{this.renderBottomView()}</View>
          </ScrollView> :
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '480px' }}>
            {IOLBillingRow.map((item, index) => {
              let sno = index + 1;
              let zIndex = (index + 1) * -10;
              return (

                <View key={index} style={{
                  flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                  backgroundColor: "#F9F9F9", padding: 8, borderRadius: 4, marginTop: 5,
                  borderBottomWidth: 1, borderColor: color.lightGray, zIndex: zIndex
                }}>
                  {this.getBillTableData('', sno, 0.03, 'label')}
                  {this.getBillTableData('brand_name', item.brand_name, 0.16, '', index)}
                  {this.getBillTableData('type', item.type, 0.07, '', index)}
                  {this.getBillTableData('power', item.power, 0.08, '', index)}
                  {this.getBillTableData('batch_no', item.batchAvilDisplay, 0.1, 'select', index)}
                  {this.getBillTableData('hsn_code', item.hsn_code, 0.07, '', index)}
                  {this.getBillTableData('expiry_date', item.expiry_date ? moment(item.expiry_date).format('YYYY-MM') : null, 0.06, '', index)}
                  {this.getBillTableData('physical_box_no', item.physical_box_no, 0.06, '', index)}
                  {this.getBillTableData('cost_per_quantity', item.cost_per_quantity, 0.06, '', index)}
                  {this.getBillTableData('quantity', item.quantity, 0.07, '', index)}
                  {this.getBillTableData('total_cost', item.total_cost, 0.07, '', index)}
                  {this.getBillTableData('discount_percentage', item.discount_percentage, 0.06, '', index)}
                  {this.getBillTableData('net_total_amount', item.net_total_amount, 0.07, '', index)}
                  {this.getBillTableData('', 'X', 0.04, 'icon', index)}
                </View>
              )
            })}
            <View style = {{ zIndex: -120 }}>{this.renderBottomView()}</View>
          </ScrollView>
        }
      </View>
    )
  }
  _addNextRows = () => {
    let { drugBillingRow, IOLBillingRow } = this.state

    if (this.state.toggleSwitchFlag) {

      let current_row_drug = drugBillingRow.length;
      let bill_line_items_drug = [];
      let drugRows_no_manitory_drug = [];
      let add_flag = false;
      for (let i = 0; i < current_row_drug; i++) {

        if (drugBillingRow[i].brand_name && drugBillingRow[i].dosage_strength && drugBillingRow[i].dosage_type && drugBillingRow[i].quantity) {
          bill_line_items_drug.push(drugBillingRow[i]);
        }
        else if (!drugBillingRow[i].brand_name || !drugBillingRow[i].dosage_strength || !drugBillingRow[i].dosage_type || drugBillingRow[i].quantity) {
          drugRows_no_manitory_drug.push(drugBillingRow[i])
        }
      }

      if ((current_row_drug - bill_line_items_drug.length) === 0) {
        add_flag = true;
      }

      if (add_flag) {

        let new_row = drugBillingRow.length + 5

        for (let i = current_row_drug; i < new_row; i++) {
          drugBillingRow.push(JSON.parse(JSON.stringify(tempRowsDrugs)));
        }

        this.setState({
          drugBillingRow,
          drugLineItems: bill_line_items_drug
        })
      }
      else if (drugRows_no_manitory_drug.length > 0) {
        console.log("error")
      }
    } else {
      let current_row_IOL = IOLBillingRow.length;
      let bill_line_items_IOL = [];
      let drugRows_no_manitory_IOL = [];
      let add_flags = false;

      for (let i = 0; i < current_row_IOL; i++) {

        if (IOLBillingRow[i].brand_name && IOLBillingRow[i].type && IOLBillingRow[i].power && IOLBillingRow[i].quantity) {
          bill_line_items_IOL.push(IOLBillingRow[i]);
        }
        else if (!IOLBillingRow[i].brand_name || !IOLBillingRow[i].type || !IOLBillingRow[i].power || IOLBillingRow[i].quantity) {
          drugRows_no_manitory_IOL.push(IOLBillingRow[i])
        }
      }

      if ((current_row_IOL - bill_line_items_IOL.length) === 0) {
        add_flags = true;
      }

      if (add_flags) {

        let new_row = IOLBillingRow.length + 5

        for (let i = current_row_IOL; i < new_row; i++) {
          IOLBillingRow.push(JSON.parse(JSON.stringify(tempRowsIOL)));
        }

        this.setState({
          IOLBillingRow,
        })
      }
      else if (drugRows_no_manitory_IOL.length > 0) {
        console.log("error")

      }
    }
  }
  renderBillingView = (placehold, value, key) => {
    return (
      <View style={{ flexDirection: "row", flex: 1, alignSelf: "stretch", marginTop: "1vw" }}>
        <View style = {{ flex: 0.1}}></View>
        <View style={{ flex: 0.4 }}>
          <Text style={{ fontSize: "1vw" }}>{placehold}</Text>
        </View>
        <View
          style={{
            flex: 0.35,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Image
            style={{
              width: "0.97vw",
              height: "0.65vw",
              marginRight: 5,
              tintColor: "grey",
            }}
            source={require("./../../../../../assets/images/Active/Rupee.png")}
            resizeMode={"cover"}
          />
          <Text style={{ textAlign: "right", fontSize: "1vw" }}>{value}</Text>
        </View>
        <View style = {{ flex: 0.15}}></View>
      </View>
    )

  }
  checkFloat = (num) => {
    return Number(num) === num && num % 1 != 0
  }  
  onFocusPer = () => {
    this.setState({
      isOnFocusPer: true
    })
  }
  onBlurPer = () => {
    this.setState({
      isOnFocusPer: false
    })
  } 
  onFocus = () =>{
    this.setState({
        isOnFocus : true
    })
  }  
  onBlur = () =>{
    this.setState({
        isOnFocus : false
    })
  }
  payableAmountCalculation() {
    var states = this.state
    let payableAmounts = 0
    payableAmounts = this.state.subAmount
    if(this.state.general_discount){
      payableAmounts = this.state.subAmount - parseFloat(this.state.general_discount)
    } 
    var roundOffAmount = payableAmounts % 1;

    if (parseFloat(roundOffAmount) >= 0.5) {
      states["roundOff"] = (1 - parseFloat(roundOffAmount)).toFixed(2)
    } else if (parseFloat(roundOffAmount) < 0.5) {
      states["roundOff"] = "-" + parseFloat(roundOffAmount).toFixed(2)
    }
    this.setState({
      states,
      netAmount: payableAmounts ? Math.round(payableAmounts) : 0

    })
  }
  renderDiscountView = () => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '1vw' }}>
        <View style = {{ flex: 0.1}}></View>
        <View style={{ flex: 0.4, alignSelf: "center" }}>
          <Text style={{ fontSize: "1vw" }}>{"Discount %"}</Text>
        </View>
        <View style={{ flex: 0.25, alignSelf: "center" }}>
          <TextInput
            style={{
              borderColor: "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "80%",
              height: '1.62vw',
              paddingVertical: 3,
              paddingHorizontal: 8,
              textAlign: "right",
              fontSize: '0.9vw'
            }}
            onFocus={() => { this.onFocusPer() }}
            onBlur={() => { this.onBlurPer() }}
            value={(this.checkFloat(parseFloat(this.state.general_discount_percentage)) && !this.state.isOnFocusPer ) ? parseFloat(this.state.general_discount_percentage).toFixed(2) : parseFloat(this.state.general_discount_percentage)}
            keyboardType="numeric"
            onChangeText={(text) => {
              if (text.length < 3) {
                var states = this.state
                let taxable_amount = parseFloat(this.state.subAmount) || 0
                let general_discount_percentage = parseFloat(text) || 0
                var general_discount = 0
                states['general_discount_percentage'] = text
                general_discount = ((taxable_amount * general_discount_percentage) / 100 || 0)
                states['general_discount'] = general_discount

                // data = this._updateRoundOff(data)

                this.setState({
                  states
                }, () => {
                  this._updateDrugBillAmounts()
                  this.payableAmountCalculation()
                  // this._updateRoundOff(data)

                })
              }
            }}
          />
        </View>
        <View style={{ flex: 0.2, flexDirection: "row", justifyContent: "flex-end", alignSelf: "center" }}>
          {/* {this.getBillAmountColumns(data.general_discount_amount || 0)} */}
          <Image
            style={{ width: '0.97vw', height: '0.65vw', marginRight: 5, tintColor: "grey" }}
            source={require("../../../../../assets/images/Active/Rupee.png")}
            resizeMode={"cover"}
          />
          <TextInput
            style={{
              borderColor: "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "100%",
              height: '1.62vw',
              paddingVertical: 3,
              paddingHorizontal: 8,
              textAlign: "right",
              fontSize: '0.9vw'
            }}
            keyboardType="numeric"
            onFocus={ ()=>{ this.onFocus()}}
            onBlur = {() => {this.onBlur()}}
            value={(this.checkFloat(parseFloat(this.state.general_discount)) && !this.state.isOnFocus) ? parseFloat(this.state.general_discount).toFixed(2) : parseFloat(this.state.general_discount)}
            onChangeText={(text) => {
              if (parseFloat(text) > parseFloat(this.state.subAmount)) {
                this.props.showResposeValue("error", "The discount amount is greater than total amount")
                this.setState({
                  general_discount: 0.00,
                  general_discount_percentage: 0
                })
              } else {
                var states = this.state
                let general_discount = parseFloat(text) || 0;
                let taxable_amount = parseFloat(this.state.subAmount) || 0;
                let general_discount_percentage = 0
                states['general_discount'] = text
                general_discount_percentage = parseFloat((general_discount * 100 / taxable_amount) || 0).toFixed(2)
                states['general_discount_percentage'] = general_discount_percentage

                // data = this._updateRoundOff(data)

                this.setState({
                  states
                }, () => {
                  this.payableAmountCalculation()
                  this._updateDrugBillAmounts()
                })
              }
            }}
          />
        </View>
        <View style={{ flex: 0.13 }}></View>

      </View>
    )
  }
  clearData = () => {
    this.setState({
      drugBillingRow: [],
      IOLBillingRow: [],
      subAmount: 0.00,
      general_discount_percentage: 0,
      general_discount: 0.00,
      roundOff: 0.00,
      netAmount: 0
    })
  }
  _createBill = (status) => {
    var { drugBillingRow, IOLBillingRow } = this.state
    var service_url = ""
    let current_row = drugBillingRow.length;
    let current_IOL_row = IOLBillingRow.length
    let bill_line_items = [];

    if(this.state.toggleSwitchFlag) { 

    for (let i = 0; i < current_row; i++) {

      if (drugBillingRow[i].brand_name && drugBillingRow[i].quantity ) {
        let total_cost = parseFloat(drugBillingRow[i].total_cost).toFixed(2)
        let discountAmount = parseFloat(drugBillingRow[i].discount_amount).toFixed(2)
        let discountPercentage = drugBillingRow[i].discount_percentage ? parseFloat(drugBillingRow[i].discount_percentage).toFixed(2) : 0

        bill_line_items.push({"id": drugBillingRow[i].id, "quantity": parseInt(drugBillingRow[i].quantity), "total_amount": parseFloat(total_cost), "discount_amount": parseFloat(discountAmount), "discount_percentage": parseFloat(discountPercentage), "net_amount": parseInt(drugBillingRow[i].net_total_amount)} );

      }
    }
   } else {
    for (let i = 0; i < current_IOL_row; i++) {

      if (IOLBillingRow[i].brand_name && IOLBillingRow[i].quantity) {
        let total_cost = parseFloat(IOLBillingRow[i].total_cost).toFixed(2)
        let discount_amount =  parseFloat(IOLBillingRow[i].discount_amount).toFixed(2)
        let discount_percentage = IOLBillingRow[i].discount_percentage ? parseFloat(IOLBillingRow[i].discount_percentage).toFixed(2) : 0

        bill_line_items.push({"id": IOLBillingRow[i].id, "quantity": parseInt(IOLBillingRow[i].quantity), "total_amount": parseFloat(total_cost), "discount_amount": parseFloat(discount_amount), "discount_percentage": parseFloat(discount_percentage), "net_amount":parseInt(IOLBillingRow[i].net_total_amount)} );

      }
    }
   }
    service_url = Constants.OT_BILLING_POST 
    let sub_amount = parseFloat(this.state.subAmount).toFixed(2)
    let discount_amount = parseFloat(this.state.general_discount).toFixed(2)
    let discount_percentage = this.state.general_discount_percentage ? parseFloat(this.state.general_discount_percentage).toFixed(2) : 0
    var data =  {
      "ip_number": this.state.ipAdmissionID,
      "ot_admission": this.state.PatientDetail.id,
      "patient_id": this.state.PatientDetail.patient_id,
      "bill_type": this.state.toggleSwitchFlag ? "Drug" : "IOL",
      "bill_status": status, 
      "total_bill_amount": parseFloat(sub_amount),
      "total_discount_amount": parseFloat(discount_amount),
      "total_discount_percentage": parseFloat(discount_percentage),
      "total_round_off_amount": parseFloat(this.state.roundOff),
      "total_net_amount": parseInt(this.state.netAmount),
      "bill_line_items": bill_line_items
  }
  if (bill_line_items && bill_line_items.length == 0) {
    this.props.showResposeValue("error", "Please enter the details to proceed")
  } else {
    OpthamologyService.getInstance().postComplaints(
      service_url,

      data,
      this,
      this._createBillSuccess,
      this._createBillFailure
    );
  }
  }
  _createBillSuccess = (response) => {
    if(response.status === "success") {
      this.clearData()
      this.props.showResposeValue("success", response.message)
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  _createBillFailure = (error) => {
    this.props.showResposeValue("error", error.message)
  }
  renderBottomView = () => {
    return (
      <View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.AddButton} onPress={() => { this._addNextRows() }}>
            <Text style={styles.addText}> Add Item</Text>
          </TouchableOpacity>
        </View>
        <View style={{ alignItems: "flex-end", alignSelf: "flex-end" }}>
          {this.renderBillingView("Sub Amount", this.state.subAmount, "subAmount")}
          {this.renderDiscountView()}
          {this.renderBillingView("Round Off", this.state.roundOff, "roundOff")}
          {this.renderBillingView("Net Amount", this.state.netAmount, "netAmount")}
        </View>
        <View style={{ alignSelf: "center", alignItems: "center", flexDirection: "row" }}>
          {/* <TouchableOpacity style={[styles.AddButton, { backgroundColor: this.state.ipNumber ? color.themeDark : color.lightGray }]} disabled = { this.state.ipNumber ? false : true } onPress={() => { this._createBill("Payment saved")}}>
            <Text style={styles.addText}> Save </Text>
          </TouchableOpacity> */}
          <TouchableOpacity style={[styles.Button, { backgroundColor: this.state.ipNumber &&  this.state.PatientDetail.ip_status != "Checked Out" ? color.themeDark : color.lightGray }]} disabled = { this.state.ipNumber && this.state.PatientDetail.ip_status != "Checked Out" ? false :  true } onPress={() => {this._createBill("Payment pending")}}>
            <Text style={styles.addText}> Move to OT Billing</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  render() {
    return (
      <View style = {{ height: screenHeight-85 }}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"oTBilling"}
          isNavigationBorder={true}
        />
        <View style={styles.container}>
          {this.renderSwitchToggle()}
          {this.renderHeaderView()}
          {this.renderDrugAndIOLTable()}
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    margin: "0.7vw",
  },
  buttonContainer: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    margin: "0.5vw",
  },
  addText: {
    color: color.white,
    fontSize: "1vw"
  },
  AddButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.3vw"
  },
  Button: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.3vw",
    marginLeft: "1.2vw"
  },
  textBox: { paddingLeft: "1vw", borderColor: color.lightGray, borderWidth: 1, margin: "0.8vw", borderRadius: "0.3vw", padding: "0.8vw", height: "0.8vw" },
  searchBoxText: { position: 'absolute', marginTop: "0.5vw", backgroundColor: color.white, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize: "0.6vw" },
  qtyTextBox: {
    height: '2vw', // 35
    padding: 6,
    marginRight: 5,
    backgroundColor: color.white,
    borderColor: "#CDD1D5",
    borderRadius: 4,
    borderWidth: 1,
    fontSize: '0.71vw',
  },
  netAmountContainer: {
      height: '2vw', // 35
      padding: 6,
      marginRight: 5,
      backgroundColor: color.tableHeaderBG,
      borderColor: "#CDD1D5",
      borderRadius: 4,
      borderWidth: 1,
      fontSize: '0.71vw'
  }
}) 
