import React, { Component } from "react";
import { Text, View, CheckBox, TouchableOpacity, TextInput } from "react-native";
import BaseComponentStyle from "../../BaseComponentStyle";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Arrow from "react-native-vector-icons/FontAwesome5";
import Success from "react-native-vector-icons/FontAwesome5";
import Style from "../../../../styles/Style";
import AsyncStorage from "../../../../AsyncStorage";

import { StyleSheet } from "react-native";
var Messages = require("../../../../utils/InfoMessages")

export class UltraSoundAbdomenReport extends BaseComponentStyle {

  constructor(props) {
    super(props)

    this.state = {
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      selectedIpPatientDetails:this.props.selectedIpPatientDetails,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      liver: "",
      gallblader: "",
      commonBileDuct: "",
      portalvein: "",
      pancreas: "",
      kidneys: "",
      spleen: "",
      ascites: "",
      nodes: "",
      urinarybladder: "",
      uterus: "",
      ovaries: "",
      prostate: "",
      impression: "",
      is_finished: false,
      enableFinish: false,
      responseType: "",
      responseMeaasge: "",
      isNavigate: "",
      report_id: this.props.reportID,
      otPatienDetils: this.props.otPatientDetail,
      userType: this.props.userType,
      clinicId: ""
    }
  }


 async componentDidMount() {
    this.getPartialValues()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      clinicId: loggedInDataObj.clinic_id
    })
  }


  getPartialValues = () => {

    if(this.state.report_id){
    if(this.state.isCurrentPageIsIP&&this.state.selectedIpPatientDetails){
      var service_url = Constants.POST_ULTRA_SOUND + "?appointment_id=" + this.state.selectedIpPatientDetails.appointment_id + "&report_id=" + this.state.report_id;
    }else{
    var service_url = Constants.POST_ULTRA_SOUND + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&report_id=" + this.state.report_id;
    }
  }
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getReportOrdersSuccess,
      this.getReportOrdersFailure
    );
  }

  getReportOrdersSuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        patient_id: response.data.patient_id,
        liver: response.data.liver,
        gallblader: response.data.gall_bladder,
        commonBileDuct: response.data.common_bile_duct,
        portalvein: response.data.portal_vein,
        pancreas: response.data.pancreas,
        kidneys: response.data.kidneys,
        spleen: response.data.spleen,
        ascites: response.data.ascites,
        nodes: response.data.nodes,
        urinarybladder: response.data.urinary_bladder,
        uterus: response.data.uterus,
        ovaries: response.data.ovaries,
        prostate: response.data.prostate,
        impression: response.data.impression,
        is_finished: response.data.is_finished
      })
    }
  }

  getReportOrdersFailure = error => { }


  showResposeValue(type, meaasge) {
    // alert(meaasge)
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }


  successAlert() {
    return (
      <View style={styles.SuccessAlrtmainView}>
        <View style={styles.SuccessAlrtContent}>
          <Success size={'1.62vw'} name={"check"} color={"green"} />
          <Text style={styles.SuccesText}>{"Success: "}
            <Text style={styles.SuccessMsg}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertCloseIcon}>
          <Icon size={'1.62vw'} name={"close"} color={"#B2C6AA"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {

    let errorList = this.state.responseMeaasge && this.state.responseMeaasge.length > 0 && (this.state.responseMeaasge).split(",")
    return (
      <View style={styles.errorAlertMainView}>
        <View style={styles.ErrorContentView}>
          <View style={{}}><Icon size={'1.62vw'} name={"ban"} color={"#D26560"} /></View>
          <View style={{ flexDirection: "column", justifyContent: "flex-start" }} >
            <Text style={styles.ErrorText}>{"Error: "}
              {errorList && errorList.map((item, index) => {
                return <Text style={styles.ErrorMsg}>{item}</Text>
              })}
            </Text>
          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertCloseIcon}>
          <Icon size={'1.62vw'} name={"close"} color={"#C69A99"} />
        </TouchableOpacity>
      </View>
    );
  }




  addUltraSound(key) {

    this.setState({
      is_finished: key,
      // /enableFinish: true
    })

    let data = {
      clinic_id: this.state.clinicId ? this.state.clinicId : "",
      patient_id: this.state.userType === "OT" ? this.state.otPatienDetils.patient_id : this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP  ?this.state.selectedIpPatientDetails.patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.otPatienDetils.appointment ? this.state.otPatienDetils.appointment : this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP ? this.state.selectedIpPatientDetails.appointment_id: this.state.otPatienDetils ? this.state.otPatienDetils.id : this.state.patientAppointment.appointment_id,
      ultra_sound_abdomen_report: {
        liver: this.state.liver,
        gall_bladder: this.state.gallblader,
        common_bile_duct: this.state.commonBileDuct,
        portal_vein: this.state.portalvein,
        pancreas: this.state.pancreas,
        kidneys: this.state.kidneys,
        spleen: this.state.spleen,
        ascites: this.state.ascites,
        nodes: this.state.nodes,
        urinary_bladder: this.state.urinarybladder,
        uterus: this.state.uterus,
        ovaries: this.state.ovaries,
        prostate: this.state.prostate,
        impression: this.state.impression,
        is_finished: key
      }
    }
    var service_url = Constants.POST_ULTRA_SOUND;
    if (this.state.liver || this.state.gallblader || this.state.commonBileDuct || this.state.portalvein
      || this.state.pancreas || this.state.kidneys || this.state.spleen || this.state.ascites || this.state.nodes
      || this.state.urinarybladder || this.state.uterus || this.state.ovaries || this.state.prostate || this.state.impression
    ) {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.addUltraSoundSuccess,
        this.addUltraSoundlFailure
      );
    } else {
      this.showResposeValue('error', Messages.UltraSoundEmptyField)
    }
  }

  clearUltraSound = () => {

    let states = this.state;

    states["liver"] = ""
    states["gallblader"] = ""
    states["commonBileDuct"] = ""
    states["portalvein"] = ""
    states["pancreas"] = ""

    states["kidneys"] = ""
    states["spleen"] = ""
    states["ascites"] = ""
    states["nodes"] = ""
    states["urinarybladder"] = ""

    states["uterus"] = ""
    states["ovaries"] = ""
    states["prostate"] = ""
    states["impression"] = ""
    this.setState({ states });
  }


  addUltraSoundSuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        enableFinish: true,
        isNavigate: true
      })
      this.showResposeValue('success', response.message)
    } else {
      this.showResposeValue('error', response.message)
    }

    if (this.state.is_finished == true) {
      this.props.changeScreen("reportsScreen", {}, "", {}, "isScanReport");
    }

  }

  addUltraSoundlFailure = (response) => {
    if (response.status == "fail") {

      this.showResposeValue("error", response.message);
    }
  }

  saveObstetricSonoGraphy = () => {

  }


  renderHeaderView = () => {
    return (
      <View
        style={styles.MainView}
      >
        <View style={{ flexDirection: "row" }}>
          <View>

            <Arrow
              name="arrow-left"
              size={'1.3vw'}
              color={color.black}
              onPress={() => {
                this.props.changeScreen("reportsScreen");
              }}
            />
          </View>
          <View style={{ marginLeft: '1.3vw' }}>
            <Text style={{ fontSize: '1vw' }}>{this.state.patientInfo.name?this.state.patientInfo.name:this.state.selectedIpPatientDetails.patient_name?this.state.selectedIpPatientDetails.patient_name : ""}

              <Text
                style={styles.PatientInfo}
              >
                {this.state.patientInfo.dob ? this.state.patientInfo.dob || "" :this.state.selectedIpPatientDetails.patient_dob?this.state.selectedIpPatientDetails.patient_dob||"" :""}
                {this.state.patientInfo.dob || this.state.selectedIpPatientDetails.patient_dob ? "  |  " : ''}
                {this.state.patientAppointment.appointment_date ? this.state.patientAppointment.appointment_date || "" :this.state.selectedIpPatientDetails.admission_date?this.state.selectedIpPatientDetails.admission_date||"" :""}
                {this.state.patientAppointment.appointment_date || this.state.selectedIpPatientDetails.admission_date ? "  |  " : ''}
                {this.state.patientAppointment.appointment_start_time ? this.state.patientAppointment.appointment_start_time || "" :this.state.selectedIpPatientDetails.admission_time?this.state.selectedIpPatientDetails.admission_time||"" : ""}
                {this.state.patientAppointment.appointment_start_time || this.state.selectedIpPatientDetails.admission_time ? "  |  " : ''}
                {this.state.patientInfo.mobile_number ? this.state.patientInfo.mobile_number || "" :this.state.selectedIpPatientDetails.patient_mobile_number?this.state.selectedIpPatientDetails.patient_mobile_number||"": ""}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  };


  createPatientDetails(value, key) {
    var states = this.state
    states[key] = value;
    this.setState({ states })
  }

  renderTextBox(placeholder, width, center, state, value, disable = false) {
    return (
      <View>
        <Text style={styles.TextBoxPlaceHolder}>{placeholder}</Text>
        {
          disable ?
            <Text style={[Style.commonTextBoxStyles, { marginTop: '0.52vw', color: "#888888", paddingTop: '0.65vw', fontSize:'1vw' }]} >
              {value}
            </Text> :
            <TextInput

              style={[Style.commonTextBoxStyles, { marginTop: '0.52vw', fontSize:'1vw' }]} 
              value={value}
              onChangeText={(text) => {
                // value = text;
                // props.callBack(text, props.textKey);
                this.createPatientDetails(text, state);
              }}
            // onSubmitEditing={() => {
            //     props.enterTosubmit ?
            //         props.callBack(value, props.textKey, true)
            //         : null
            // }
            // }
            />

        }

      </View>
    )
  }



  render() {

    var otAppointment = this.state.otPatienDetils.appointment ? this.state.otPatienDetils.appointment : null
    console.log(otAppointment,"test--")
    return (
      <View style={{ margin: '1.95vw' }}>
        {this.state.responseType ?
          <View style={{ alignSelf: 'flex-end', padding: '1.3vw', width: "35%" }}>
            {
              this.state.responseType == "success" ?
                this.successAlert() :
                this.state.responseType == "error" ?
                  this.errorAlert() : null
            }
          </View> : null}
        <View>
          {
              this.state.patientAppointment.patient_id || this.state.selectedIpPatientDetails.patient_id  ? this.renderHeaderView() : null
          }

        </View>
        <View style={{ marginTop: '1.3vw' }}>
          <Text style={styles.PageHeader}>
            Ultra Sound Abdomen Report
          </Text>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxStyle1}>

            {this.state.liver ?

              this.renderTextBox("Liver", 10, false, "liver", this.state.liver) : this.renderTextBox("Liver", 10, false, "liver", this.state.liver)}
            {/* {this.renderTextFieldView(
              "Liver",
              "liver",
              this.state.liver,
              true,
              1
            )} */}
          </View>
          <View style={styles.TextBoxStyle1}>

            {this.state.gallblader ?
              this.renderTextBox("Gall Blader", 10, false, "gallblader", this.state.gallblader)
              : this.renderTextBox("Gall Blader", 10, false, "gallblader", this.state.gallblader)
            }


          </View>
          <View style={styles.TextBoxStyle1}>

            {this.state.commonBileDuct ?
              this.renderTextBox("Common Bile Duct", 10, false, "commonBileDuct", this.state.commonBileDuct)
              : this.renderTextBox("Common Bile Duct", 10, false, "commonBileDuct", this.state.commonBileDuct)
            }


          </View>

          <View style={styles.PortalVein}>

            {this.state.portalvein ?
              this.renderTextBox("Portal Vein", 10, false, "portalvein", this.state.portalvein)
              : this.renderTextBox("Portal Vein", 10, false, "portalvein", this.state.portalvein)
            }

          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxStyle2}>

            {this.state.pancreas ?
              this.renderTextBox("Pancreas", 10, false, "pancreas", this.state.pancreas)
              : this.renderTextBox("Pancreas", 10, false, "pancreas", this.state.pancreas)
            }

          </View>
          <View style={styles.Kidneys}>


            {this.state.kidneys ?
              this.renderTextBox("Kidneys", 10, false, "kidneys", this.state.kidneys)
              : this.renderTextBox("Kidneys", 10, false, "kidneys", this.state.kidneys)
            }


          </View>
        </View>

        <View style={styles.Spleen}>

          {this.state.spleen ?
            this.renderTextBox("Spleen", 10, false, "spleen", this.state.spleen)
            : this.renderTextBox("Spleen", 10, false, "spleen", this.state.spleen)
          }


        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxStyle2}>

            {this.state.ascites ?
              this.renderTextBox("Ascites", 10, false, "ascites", this.state.ascites)
              : this.renderTextBox("Ascites", 10, false, "ascites", this.state.ascites)
            }


          </View>
          <View style={styles.TextBoxStyle2}>

            {this.state.nodes ?
              this.renderTextBox("Nodes", 10, false, "nodes", this.state.nodes)
              : this.renderTextBox("Nodes", 10, false, "nodes", this.state.nodes)
            }

          </View>
          <View style={styles.UrinaryBladder}>

            {this.state.urinarybladder ?
              this.renderTextBox("Urinary Bladder", 10, false, "urinarybladder", this.state.urinarybladder)
              : this.renderTextBox("Urinary Bladder", 10, false, "urinarybladder", this.state.urinarybladder)
            }


          </View>
        </View>

        <View style={styles.Uterus}>

          {this.state.uterus ?
            this.renderTextBox("Uterus", 10, false, "uterus", this.state.uterus)
            : this.renderTextBox("Uterus", 10, false, "uterus", this.state.uterus)
          }

        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxStyle2}>
            {this.state.ovaries ?
              this.renderTextBox("Ovaries", 10, false, "ovaries", this.state.ovaries)
              : this.renderTextBox("Ovaries", 10, false, "ovaries", this.state.ovaries)
            }

          </View>
          <View style={styles.Prostate}>

            {this.state.prostate ?
              this.renderTextBox("Prostate", 10, false, "prostate", this.state.prostate)
              : this.renderTextBox("Prostate", 10, false, "prostate", this.state.prostate)
            }

          </View>
        </View>

        <View style={styles.Spleen}>
          {this.state.impression ?
            this.renderTextBox("Impression", 10, false, "impression", this.state.impression)
            : this.renderTextBox("Impression", 10, false, "impression", this.state.impression)
          }

        </View>



        <View style={styles.ButtonView}>
          <View style={styles.ButtonStyle3}>
            <TouchableOpacity
              disabled = {otAppointment === null ? true : false}
              style={{  marginLeft: '0.32vw'  }}
              onPress={() => this.addUltraSound(false)

              }
            >
              <View
                style={[styles.ButtonStyle1,{backgroundColor: otAppointment === null ? color.disableComponentColor : color.themeDark}]}
              >
                <Icon name="save" size={'1vw'} color={color.white} />
                <Text
                  style={styles.ButtonStyle2}
                >
                  {"Save"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>


          {this.state.enableFinish == true ?
            <View style={styles.ButtonStyle3}>
              <TouchableOpacity
                style={{ marginLeft: '0.32vw' }}
                onPress={() => this.addUltraSound(true)}
              >
                <View
                  style={styles.ButtonStyle1}
                >
                  <Icon name="check" size={'1vw'} color={color.white} />
                  <Text
                    style={styles.ButtonStyle2}
                  >
                    {"Finish"}
                  </Text>
                </View>
              </TouchableOpacity>
            </View> : null
          }
          <View style={[styles.ButtonStyle3, {marginRight:'13vw'}]}>
            <TouchableOpacity
              style={{ marginLeft: '0.32vw' }}
              onPress={this.clearUltraSound.bind(this)}
            >
              <View
                style={styles.ButtonStyle1}
              >
                <Icon name="close" size={'1vw'} color={color.white} />
                <Text
                  style={styles.ButtonStyle2}
                >
                  {"Clear"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: "0.4vw" }}>{  otAppointment === null ? <Text style= {{ fontSize: "0.8vw", fontWeight: "500", color: color.red }}>{"Note: Kindly create IP Admission"}</Text> : null }</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView:{
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  PatientInfo:{
    fontSize: '0.7vw',
    color: "#ADB4C1",
    marginTop: '0.32vw',
    marginBottom: '0.32vw',
    marginLeft: '0.65vw',
    alignSelf: "center"
  },
  TextBoxStyle1:{
    marginTop: '1.95vw', 
    width: "20%", 
    marginRight: '0.65vw'   
  },
  TextBoxStyle2:{
    marginTop: '0.65vw', 
    width: "20%", 
    marginRight: '1.3vw'
  },
  ButtonStyle1:{
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 0.2,
    backgroundColor: color.themeDark,
    borderRadius: '0.32vw',
    minHeight: '2.27vw',
  },
  ButtonStyle2:{
    fontSize: '1vw',
    color: color.white,
    fontWeight: "700",
    marginLeft: '0.32vw'
  },
  PortalVein:{
    marginTop: '1.95vw', 
    width: "20%"
  },
  Kidneys:{
    marginTop: '0.65vw', 
    width: "60.5%"
  },
  Prostate:{
    marginTop: '0.65vw', 
    width: "60.5%"
  },
  Spleen:{
    marginTop: '0.65vw', 
    width: "82%"
  },
  UrinaryBladder:{
    marginTop: '0.65vw', 
    width: "39%" 
  },
  Uterus:{
    marginTop: '0.65vw', 
    width: "82%"
  },
  ButtonStyle3:{
    marginTop: '1.3vw', 
    width: "7%"
  },
  ButtonView:{
    flexDirection: "row", 
    justifyContent: "center", 
    alignItems: "center"
  },
  PageHeader:{
    fontSize: '1.3vw', 
    color: color.black
  },
  TextBoxPlaceHolder:{
    fontSize: '0.8vw', 
    position: "absolute", 
    marginLeft: '0.65vw', 
    backgroundColor: "white", 
    paddingLeft: '0.32vw', 
    paddingRight: '0.32vw', 
    color: "#888888"
  },
  SuccessAlrtmainView:{
    flex: 1, 
    zIndex: 3, 
    width: "100%", 
    position: 'absolute', 
    left: 0, 
    top: 0, 
    bottom: 0, 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection: "row", 
    backgroundColor: "#DEF2D6", 
    borderRadius: '0.32vw', 
    borderColor: "#B8C7B2", 
    borderWidth: 1, 
    height: '4.55vw', 
    marginRight: '0.65vw', 
    marginTop: '0.65vw'
  },
  SuccessAlrtContent:{
    flexDirection: 'row', 
    alignItems: 'center', 
    marginLeft: '0.65vw', 
    flex: 0.95
  },
  SuccesText:{
    fontWeight: "700", 
    color: "green", 
    fontSize: '0.8vw', 
    marginLeft: '0.97vw' 
  },
  SuccessMsg:{
    fontWeight: "400", 
    color: "green", 
    fontSize: '0.8vw'
  },
  AlertCloseIcon:{
    flex: 0.05, 
    marginRight: '0.97vw', 
    alignItems: 'center', 
    alignContent: 'center', 
    alignSelf: 'center', 
    justifyContent: "center"
  },
  errorAlertMainView:{
    flex: 1, 
    zIndex: 3, 
    width: "100%", 
    position: 'absolute', 
    left: 0, 
    top: 0, 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection: "row", 
    backgroundColor: "#EBC8C4", 
    borderRadius: '0.32vw', 
    borderColor: "#A58B8A", 
    borderWidth: 1, 
    minHeight: '4.55vw', 
    marginRight: '0.65vw', 
    marginTop: '0.65vw'
  },
  ErrorContentView:{
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: '0.97vw', 
    marginLeft: '0.65vw', 
    flex: 0.95
  },
  ErrorText:{
    fontWeight: "700", 
    color: "#D26560", 
    fontSize: '0.65vw', 
    marginLeft: '0.97vw',
    marginBottom: '0.97vw'
  },
  ErrorMsg:{
    fontWeight: "400", 
    color: "#D26560", 
    fontSize: '0.65vw', 
    marginLeft: '0.32vw', 
    marginBottom: '0.52vw'
  }

})