import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, Platform, Image, FlatList, TouchableWithoutFeedback, TouchableOpacity, ScrollView, TextInput } from 'react-native';
// import SideMenu from '../../../../ui/SideMenu'
// import Style from '../../../../styles/Style';
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";
import ADD from "react-native-vector-icons/Ionicons";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import Service from "../../../../network/Service";

import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
// import { TouchableOpacity, ScrollView } from 'react-native-gesture-handler';
// import { Calendar } from 'react-native-calendars';
const platform = Platform.OS;
// import { TextInput } from "react-native-gesture-handler";
// import Calendar from 'react-calendar';
import AsyncStorage from "../../../../AsyncStorage";
// import Service from "../../../../network/Service";
import BaseComponentStyle from "../../BaseComponentStyle";
import { NavigationTopHeader, returnPaymentMode, paymentReturnType } from '../../BaseComponent';
import 'antd/dist/antd.css';
import { DatePicker, Checkbox } from 'antd';
import Arrow from "react-native-vector-icons/MaterialCommunityIcons";
import ImagePath from '../../../../utils/ImagePaths';

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
const navigationTransHeader = [
  { label: "Billing Transaction", value: "allBillingTransactions" },
]
const navigationHeader = [
  { label: "Create Bill", value: "billingScreen" },
  { label: "Pending Bill", value:"opPatientSavedBill" },
  { label: "Billing Transaction", value: "allBillingTransactions" },
];

var Messages = require('../../../../utils/InfoMessages');

export default class BillingScreen extends BaseComponentStyle {
  constructor(props) {
    super(props);


    let patientAppointment = null;
    let userType = '';
    let selectedClinicOrDoctor = {}
    let invoiceId = ""
    try {

      patientAppointment = this.props.patientAppointment;
      userType = this.props.userType;
      selectedClinicOrDoctor = this.props.selectedClinicOrDoctor && this.props.selectedClinicOrDoctor.id ? this.props.selectedClinicOrDoctor : { id: this.props.patientAppointment.doctor_id, value: "" };
      invoiceId = this.props.invoiceID

    } catch (error) { }

    this.state = {
      // currentTime: "",

      // billingHistory: [],
      // showCalenderModal: false,
      // showCalenderModalFrom: "",
      // filterFromDate: moment().format("YYYY-MM-DD"),
      // filterToDate: moment().format("YYYY-MM-DD"),
      // filterData: {
      //   fromDate: "",
      //   toDate: "",
      //   patientName: "",
      //   billNo: "",
      //   amount: "",

      // },
      // isDisabled: false,
      Billing: [],
      service: "",
      types: "",
      amounts: "",


      totalAmount: 0.00,    //amount_gross
      paymentType: "",
      discount_percent: 0,
      discount: 0.00,
      amount_invoice: 0.00, //After Discount
      amount_net: 0.00,     //After Round Off
      amount_cash: 0.00,
      amount_card: 0.00,
      round_off: 0.00,
      invoice_date: "",
      invoice_id: "",
      enablePrint: false,

      cashTextBoxEnable: false,
      cardTextBoxEnable: true,

      showListofServiceName: [],
      showListofServiceNameFlag: false,
      showListofServiceNameIndex: 0,

      // selectedButton: { value: 'Date', label: "Date" }, // initial value it will may be change

      // appendUrl: '',
      // filterDataFields: [],

      // filterStartDate: "",
      // filterEndDate: "",
      patientAppointment: patientAppointment,
      patientInfo: {},
      userType: userType,
      selectedClinicOrDoctor: selectedClinicOrDoctor,
      // billingTnxID: "SVN981643677",
      billingDate: new Date(),

      selectedService: {},
      showServiceNameNewButton: true,
      billingService: [],
      billingServiceData: [],
      billingServiceName: [],
      billingServiceType: [],
      billingServiceAmount: [],
      selectedBillingServiceName: "",
      selectedBillingServiceId: "",
      selectedBillingServiceType: {},
      selectedBillingServiceAmount: null,

      searchServiceType: "",
      searchServiceTypeList: [],
      searchServiceTypeOpen: false,
      showServiceTypeBottomLayout: true,
      searchServiceTypeViewShow: false,


      favoriteServiceName: [],

      showServiceTypeNewButton: true,
      showServiceAmountNewButton: true,

      editBillingIndex: '',

      responseType: "",
      responseMeaasge: "",
      isDisabled: false,

      upiTransactionID: "",
      bank_transaction_id : "",

      is_disc_percent_changed: true,
      showServiceNameAndTypeNewOption: 'true',
      invoiceID: invoiceId,
      savedBillType: "",
      isPaymentReceived: false,
      removableSavedBillID: [],
      savedBillList: [],
      invoiceNumber: "",
      invoiceSequence: "",
      serviceDescription: "",
      // Admin
      isAdmin: false,
      isBillModification: false,
      modifyDataList: {},
      isUpdate: false,

      selectedBillType: "",
      SelectedCorporateName: "",
      SelectedEmployeeID: "",
      CorporateErrorText: "",
      disableDiscountPercent: false,
      EmployerID:"",

      FO_admin_setting : {},
      isSavedBill : false,
      refDoctor: '',
      refDoc_ID: '',
      refDoc_List: [],
      referrdDoc: [],
      role_Type: this.props.role_Type,
      userEditAccess: false,
      opList:[],
      opServiceName:[],
      selectedTemplate: "",
      selectedTemplateName:"",
      templateServiceList:[],

      ServiceNameSearchKey :"",

      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : "",
      CardNumber : ""
    };

  }
  getBillingFrequentlyUsedService() {
    var service_url
    if (this.state.userType == Constants.ROLE_FO) {
      service_url = Constants.CLINIC_OP_SERVICE + "?service_name=" + this.state.ServiceNameSearchKey ;
    } else {
      service_url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&service_name=" + this.state.ServiceNameSearchKey;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getBillingFrequentlyUsedServiceSuccess,
      this.getBillingFrequentlyUsedServiceFailure
    );
  }

  getBillingFrequentlyUsedServiceSuccess = success => {
    success.data.sort((a, b) => {
      if (a.priority && b.priority) {
        if (a.priority < b.priority) {
          return -1;
        }
        if (a.priority > b.priority) {
          return 1;
        }
      } else {
        if (a.priority)
          return -1;
        else
          return 1;
      }

      return 0;
    });
    this.setState({
      showServiceNameAndTypeNewOption: success.data.new_button_visible,
      billingService: success.data,
    })
  }
  getBillingFrequentlyUsedServiceFailure = error => { }

  // getBillingFrequentlyUsedService() {
  //   var service_url
  //   if(this.state.userType == Constants.ROLE_FO){
  //     service_url = Constants.BILLING_FREQUENTLYUSED_SERVICE+"?doctor_id="+this.state.selectedClinicOrDoctor.id;   // clinic is a doctor id
  //   }else{
  //     service_url = Constants.BILLING_FREQUENTLYUSED_SERVICE;
  //   }

  //   OpthamologyService.getInstance().documentUploadGet(
  //     service_url,
  //     this.getBillingFrequentlyUsedServiceSuccess,
  //     this.getBillingFrequentlyUsedServiceFailure
  //   );
  // }

  // getBillingFrequentlyUsedServiceSuccess = success => {

  //   var billingService = success.data.data;

  //   if (billingService.length > 0) {

  //     var { billingServiceAmount, billingServiceType, billingServiceName } = this.state;

  //     var getFavoriteServiceName = []

  //     for (var i = 0; i < billingService.length; i++) {

  //       var data = {
  //         "id": billingService[i].service_id,
  //         "name": billingService[i].service_name,
  //         "type": billingService[i].service_type,
  //         "amount": billingService[i].service_amount
  //       }

  //       // billingServiceName.push(getBillingService)
  //       getFavoriteServiceName.push(data)
  //       if (billingService[i].service_name)
  //         billingServiceName.push(billingService[i].service_name)
  //       if (billingService[i].service_type)
  //         billingServiceType.push(billingService[i].service_type)
  //       if (billingService[i].service_amount)
  //         billingServiceAmount.push(billingService[i].service_amount)
  //     }
  //     var name = this.removeDuplicate(billingServiceName);
  //     var type = this.removeDuplicate(billingServiceType);
  //     var amount = this.removeDuplicate(billingServiceAmount);
  //     this.setState({
  //       billingServiceName: name,
  //       billingServiceType: type,
  //       billingServiceAmount: amount,
  //       favoriteServiceName: getFavoriteServiceName
  //     })
  //   }


  // }
  // getBillingFrequentlyUsedServiceFailure = error => { }


  addBillingList() {      // Right side clear the All selected value 
    this.setState({
      selectedBillingServiceName: "",
      selectedBillingServiceId: "",
      selectedBillingServiceType: {},
      selectedBillingServiceAmount: null,
      searchServiceType: "",
      selectedTemplateName:"",
      ServiceNameSearchKey :"",
      billingServiceType: [],
      searchServiceTypeViewShow : false
    }, ()=> this.getBillingFrequentlyUsedService())
  }

  renderBillingDescriptionSuggesstion() {
    return (
      this.state.searchServiceTypeOpen ? <View style={styles.ServiceTypeSuggestMainView}>
        {this.state.searchServiceTypeList.length > 0 ?
          <ScrollView
            style={styles.ServiceTypeSuggestScrollView}
          >
            {this.state.searchServiceTypeList.map((items, index) =>
              <View style={styles.ServiceTypeSuggestView}>
                <TouchableOpacity key={index}
                  style={styles.ServiceTypeSuggestButton}
                  onPress={
                    event => {

                      var selectedAmount = null
                      var selectedType = ""
                      for (let i = 0; i < this.state.searchServiceTypeList.length; i++) {
                        if (this.state.searchServiceTypeList[i].id === items.id) {
                          selectedAmount = items.amount
                          selectedType = items
                          break;
                          // amount.push(serviceType[i].amount)
                        }
                      }
                      this.setState({
                        selectedBillingServiceAmount: selectedAmount,
                        selectedBillingServiceType: selectedType,
                        searchServiceTypeOpen: false,
                        searchServiceType: selectedType.type,
                        showServiceTypeBottomLayout: false,
                      })

                      // let { enterRatePerUnits, enterUnits, searchDescription, searchDescriptionOpen, selectedDescription, showDescriptionBottomLayout } = this.state;
                      // searchDescription = value.service_name;
                      // searchDescriptionOpen = false;
                      // showDescriptionBottomLayout = false;
                      // var selectedData = {}
                      // selectedData = { value: value.service_name, label: value.service_name, type: "" }
                      // if (value.service_name == "Advance payment" || value.service_name == "Bed Charges") {
                      //   enterRatePerUnits = 0;
                      //   enterUnits = 0
                      // } else {
                      //   enterRatePerUnits = value.rate_per_unit;
                      //   enterUnits = this.state.enterUnits
                      // }
                      // selectedDescription = selectedData;
                      // this.setState({
                      //   selectedDescription,
                      //   searchDescription,
                      //   searchDescriptionOpen,
                      //   showDescriptionBottomLayout,
                      //   enterRatePerUnits,
                      //   enterUnits
                      // }, () => {
                      //   this.calculateTotalAmount()
                      // })

                    }
                  }
                >
                  <Text style={styles.ServiceTypeSuggestText}>{items.type}</Text>

                </TouchableOpacity>
              </View>
            )}

          </ScrollView> : null

        }

      </View> : null

    )
  }


  getPatientInfo = () => {

    let { patientAppointment, userType } = this.state;
    var serviceUrl = "";
    if (Constants.ROLE_FO == userType) {
      if (patientAppointment.doctor_id) {
        serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=" + patientAppointment.doctor_id;
      } else {
        serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=Nursing";
      }
    } else {
      serviceUrl = Constants.DOCTOR_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getComplaintsSuccess,
      this.getPatientInfoFailure
    );
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;

      field["patientInfo"] = response.data;
      this.props.changeStateValues(response.data, this.state.patientAppointment, this.state.userType, this.state.selectedClinicOrDoctor)
      this.setState({ field });
    }
  };

  getPatientInfoFailure = error => { }


  async componentDidMount() {
    if (this.state.invoiceID) {
      this.getSavedBillServices()
    }
    this.getPatientInfo()
    this.getBillingFrequentlyUsedService();
    this.getRefDoctorList();
    this.getAdminUserAccess();
    this.getOpServiceList();

    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)

    this.setState({
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      isAdmin: loggedInDataObj.is_admin_access

    })
    if (this.props.modificationBillDetails && Object.keys(this.props.modificationBillDetails).length > 0 && !this.props.isModifyBillUpdate) {
      this.setState({
        invoiceID: this.props.modificationBillDetails.id,
        isBillModification: true,
        modificationBillDetails: this.props.modificationBillDetails,
        enablePrint: true
      }, () => {
        this.getSavedBillServices()
      })
    }
    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            FO_admin_setting : FO_admin_setting
        })
  }
  getAdminUserAccess = () =>{
    let url = Constants.GET_FO_ADMIN_CONFIG_SETTINGS;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getAdminUserAccessSuccess,
      this.getAdminUserAccessFailure
    )
  }
  getAdminUserAccessSuccess = (response) =>{
      if(response.status === "success"){
        this.setState({
          userEditAccess:response.data.allow_service_amount_edit
        })
      }
  }
  getAdminUserAccessFailure = () =>{
    this.props.showResposeValue("error", msg);
    console.log(error.message)
  }
  getSavedBillServices() {
    var serviceUrl = Constants.BILLING_INVOICE_GET + "?invoice_id=" + this.state.invoiceID;

    Service.getInstance().billingList(
      serviceUrl,
      this,
      this.showBillingListSuccess,
      this.showBillingListError,
    );
  }

  showBillingListSuccess = (success) => {
    if (success.status == "success") {
      var payment_type = paymentReturnType(success.data.payment_type)
      this.setState({
        savedBillList: success.data.services,
        discount_percent: success.data.discount_percent,
        discount: success.data.discount,
        amount_net: success.data.amount_net,
        amount_invoice : success.data.amount_invoice,
        invoiceNumber: success.data.invoice_number,
        invoiceSequence: success.data.invoice_sequence,
        modifyDataList: success.data,
        paymentType: success.data.payment_type,
        firstPayment : payment_type.paymentType1,
        secondPayment : payment_type.paymentType2,
        firstAmount : success.data.amount_1,
        secondAmount : success.data.amount_2,
        billingDate: success.data.invoice_date,
        amount_cash: success.data.amount_cash,
        amount_card: success.data.amount_card,
        selectedBillType: success.data.bill_type,
        amount_net: success.data.amount_net,
        amount_invoice: success.data.amount_invoice,
        totalAmount: success.data.amount_gross,
        round_off: success.data.round_off,
        isSavedBill : true
      }, ()=>{
        if(this.state.selectedBillType == "Corporate"){
          this.getPatientCorporateDetails();
        }
        var transaction_id_1 = success.data.transaction_id_1 ? (success.data.transaction_id_1).length > 0 ? success.data.transaction_id_1 : "" : ""
        var transaction_id_2 = success.data.transaction_id_2 ? (success.data.transaction_id_2).length > 0 ? success.data.transaction_id_2 : "" : ""

        this.setState({
          CardNumber : this.state.firstPayment == "Card" ? transaction_id_1 : this.state.secondPayment == "Card" ? transaction_id_2 : "",
          upiTransactionID: this.state.firstPayment == "UPI" ? transaction_id_1 : this.state.secondPayment == "UPI" ? transaction_id_2 : "",
          bank_transaction_id: this.state.firstPayment == "Bank Transfer" ? transaction_id_1 : this.state.secondPayment == "Bank Transfer" ? transaction_id_2 : "",
          Cheque : this.state.firstPayment == "Cheque" ? transaction_id_1 : this.state.secondPayment == "Cheque" ? transaction_id_2 : ""
        })
        })
      if (this.state.invoiceID) {
        for (let i = 0; i < this.state.savedBillList.length; i++) {
          var id = this.state.savedBillList[i].id
          var serviceID = this.state.savedBillList[i].service_id
          var serviceAmount = this.state.savedBillList[i].amount_net
          var serviceName = this.state.savedBillList[i].service_name
          var serviceType = this.state.savedBillList[i].service_type

          var bill = {
            "id": id,
            "service_id": serviceID,
            "amount": serviceAmount,
            "name": serviceName,
            "description": serviceType,
          }
          if ((bill.amount || bill.amount == 0) && bill.name && bill.description) {
            var { Billing } = this.state;
            Billing.push(bill);
            this.setState({
              Billing
            }, () => {
              this.addBillingList()         // for Clear the right side selected values
            })
          } else {
            this.showResposeValue("error", Messages.NoServiceType)
          }
        }

      }
    }

  }

  getRefDoctorList = () => {
    var states = this.state
    if(this.state.userType === Constants.ROLE_FO){
      var serviceurl = Constants.REFERRED_BY_DEMOGRAPHIC_GET
    }else{
    var serviceurl = Constants.REFERRED_BY_DEMOGRAPHIC_GET + "?clinic_id=" + states.selectedClinicOrDoctor.id
  }
    
    OpthamologyService.getInstance().getComplaints(
      serviceurl,
      this,
      this.getRefDoctorListSuccess,
      this.getRefDoctorListError,
    );
  }
  getRefDoctorListSuccess = (success) => {
    if(success.status == "success"){
      this.setState({
        refDoc_List: success.data,
        referrdDoc: success.data
      })
    }
  }
  getRefDoctorListError = (error) => {
    if (error.status == "error") {
      this.props.showResposeValue('error', error.message)
  }
  }
  showBillingListError = error => { }
  renderIdButton = image => {       // Delete Icon button
    return (
      <View
        style={styles.IconView}
      >
        <Icon name={image} color={"#DD1717"} size={'0.97vw'} />
      </View>
    );
  };


  renderItem = (item, index) => {
    return (

      <View style={styles.TableDataView} key={index}>
        <View style={{ flex: 0.35, zIndex: -10 }}>
          {/* <View style={{ width: "85%", paddingLeft: 15 }}> */}
          <Text style={styles.TableDataText}>{item.name || item.service_name}</Text>
          {/* </View> */}
        </View>
        <View style={{ flex: 0.25 }}>
          {/* <View style={{ width: "85%" }}> */}
          <Text style={styles.TableDataText}>{item.description || item.service_type}</Text>
          {/* </View> */}
        </View>
        <View style={{ flex: 0.23 }}>
          {/* <View style={{ width: "60%" }}> */}
{
  (this.state?.isAdmin && !this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && !this.state.isBillModification ) ?  
  <TextInput
            style={[styles.TableDataInput,{border:"1px solid gray",width:"7vw",height:"5vh",borderRadius:"5px",paddingLeft:"0.5vw"}]}
            editable={(this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state.userEditAccess))}
            keyboardType="numeric"
            value={item.amount ? item.amount : null}
            placeholder={0}
            
            onChangeText={text => {
              let { Billing } = this.state
                Billing[index].amount = +text;
              this.setState({ Billing }, () => {
                this.calculateTotalAmount();
              })
            }
            } /> :
            <Text>{item.amount}</Text>     
}
          

          {/* <Text>{item.amount}</Text> */}
          {/* </View> */}
        </View>
        <View style={styles.TableIcon}>
          {/* <TouchableOpacity onPress={() => { 
           this.editBilling(item, index);
          }}>
            {this.renderIdButton("pencil")}
          </TouchableOpacity> */}
          {(this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state.userEditAccess)) ?
          <TouchableOpacity onPress={() => {
            let { removableSavedBillID, Billing } = this.state
            if (Billing[index].id) {
              var deletedIds = Billing[index].id
              removableSavedBillID.push(deletedIds)
            }
            this.setState({
              removableSavedBillID
            })
            this.removeBillingList(index)
          }}>
            {this.renderIdButton("trash")}
          </TouchableOpacity> : null}
        </View>
      </View>
    )
  }

  // editBilling(item, index){
  //   this.setState({
  //     selectedBillingServiceName: item.name,
  //     selectedBillingServiceType: item.description,
  //     selectedBillingServiceAmount: item.amount,
  //     editBillingIndex:index
  //   })
  // }

  removeBillingList(index) {
    var { Billing } = this.state
    Billing.splice(index, 1);
    this.setState({
      Billing,
      amount_cash: 0,
      amount_card: 0
    }, () => {
      if (this.state.Billing.length == 0) {
        this.clearBillDeleteDetails()
      }
      this.calculateTotalAmount()
    })
  }

  selectBillingButton(items) {
    if (items.data != null && items.data.length > 0) {
      var serviceType = this.removeDuplicate(items.data)
      var type = []
      var amount = []


      if (serviceType.length > 0) {
        for (let i = 0; i < serviceType.length; i++) {
          if (serviceType[i].type != null) {
            //type.push({'type': serviceType[i].type, 'id': serviceType[i].s_no, 'amount' : serviceType[i].amount})
            type.push(serviceType[i])
            amount.push(serviceType[i].amount)
          }

        }
        var billingServiceType = this.removeDuplicate(type);
        var billingServiceAmount = this.removeDuplicate(amount)
        var hasServiceTypeView = billingServiceType.length > 4;
      }

      this.setState({
        billingServiceType: billingServiceType,
        searchServiceTypeList: billingServiceType,
        billingServiceAmount: billingServiceAmount,
        selectedBillingServiceName: items.service_name,
        selectedBillingServiceAmount: null,
        selectedBillingServiceType: {},
        searchServiceTypeOpen: false,
        searchServiceType: "",
        searchServiceTypeViewShow: hasServiceTypeView,
        selectedTemplateName:""
      }, () => {
        var { patientInfo } = this.state
        if (this.state.selectedBillingServiceName === "Consultation") {
          var serviceType = {}
          if (patientInfo.patient_type === "new_patient") {
            serviceTypeIndex = this.state.billingServiceType.findIndex((item) => {
              return item.type == "NEW";
            });
            serviceType = (serviceTypeIndex == undefined || serviceTypeIndex == null || serviceTypeIndex == '-1') ? {} : this.state.billingServiceType[serviceTypeIndex]
          } else if (patientInfo.patient_type === "review_patient") {
            var serviceTypeIndex = this.state.billingServiceType.findIndex((item) => {
              return item.type == "REVIEW";
            });
            serviceType = (serviceTypeIndex == undefined || serviceTypeIndex == null || serviceTypeIndex == '-1') ? {} : this.state.billingServiceType[serviceTypeIndex]
          } else if (patientInfo.patient_type === "old_patient") {
            var serviceTypeIndex = this.state.billingServiceType.findIndex((item) => {
              return item.type == "ESTABLISHED";
            });
            serviceType = (serviceTypeIndex == undefined || serviceTypeIndex == null || serviceTypeIndex == '-1') ? {} : this.state.billingServiceType[serviceTypeIndex]
          }
          this.setState({
            selectedBillingServiceType: serviceType,
            selectedBillingServiceAmount: serviceType && Object.keys(serviceType).length > 0 ? serviceType.amount : null
          })
        }
      })
    } else {
      this.setState({
        selectedBillingServiceName: items.service_name,
        selectedBillingServiceId: items.service_id,
      })
    }
  }

  // show  right side ServiceName buttons
  renderServiceNameButtons = (items, index) => {
    return (
      <TouchableOpacity onPress={() => {
        this.selectBillingButton(items)
      }} key={index} style={[{
        backgroundColor: this.state.selectedBillingServiceName === items.service_name ? color.themeDark : null,
        borderColor: this.state.selectedBillingServiceName == items.service_name ? color.themeDark : color.textBoxBorderColor,
      }, Style.allButtonBorderRadius, styles.ServiceButtons]}>
        <Text style={[{
          color: this.state.selectedBillingServiceName === items.service_name ? color.white : color.black
        }, styles.ServiceButtonsText]}>{items.priority ? items.priority === 1 ? items.service_name : items.priority === 2 ? items.service_name : items.priority === 3 ? items.service_name : items.service_name : items.service_name}</Text>
      </TouchableOpacity>
    );
  }
  selectBillingTypeButton(items) {
    var selectedAmount = 0
    var selectedType = ""
    for (let i = 0; i < this.state.billingServiceType.length; i++) {
      if (this.state.billingServiceType[i].id != null) {
        if (this.state.billingServiceType[i].id === items.id) {
          selectedAmount = items.amount
          selectedType = items
          // amount.push(serviceType[i].amount)
        }
      }
    }

    this.setState({
      selectedBillingServiceAmount: selectedAmount,
      selectedBillingServiceType: selectedType,
      selectedBillingServiceId: items.id
    })
  }
  // show  right side ServiceType buttons
  renderServiceTypeButtons(items, index) {

    return (
      <TouchableOpacity onPress={() => {
        this.selectBillingTypeButton(items);
      }} key={index} style={[{
        backgroundColor: this.state.selectedBillingServiceType && this.state.selectedBillingServiceType.id === items.id ? color.themeDark : null,
        borderColor: color.textBoxBorderColor
      }, Style.allButtonBorderRadius, styles.ServiceButtons]}>
        <Text style={[{
          color: this.state.selectedBillingServiceType && this.state.selectedBillingServiceType.id === items.id ? color.white : color.black
        }, styles.ServiceButtonsText]}>{items.type}</Text>
      </TouchableOpacity>
    );
  }
  selectBillingAmountButton(items) {
    for (let i = 0; i < this.state.billingServiceAmount.length; i++) {
      if (this.state.billingServiceAmount[i].id === items.id) {
        this.setState({
          selectedBillingServiceAmount: items
        })
      }
    }
  }
  // show  right side ServiceAmount buttons
  renderServiceAmountButtons(items, index) {
    return (
      <TouchableOpacity onPress={() => {
        // this.selectBillingAmountButton(items);
      }} key={index} style={[{
        backgroundColor: this.state.selectedBillingServiceAmount == items ? color.themeDark : null,
        borderColor: this.state.selectedBillingServiceAmount == items ? color.themeDark : color.textBoxBorderColor
      }, Style.allButtonBorderRadius, styles.ServiceButtons]}>
        <Text style={[{
          color: this.state.selectedBillingServiceAmount == items ? color.white : null
        }, styles.ServiceButtonsText]}>{items}</Text>
      </TouchableOpacity>
    );
  }

  title(Title) {
    return (
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{Title}</Text>
      </View>
    );
  }
  subtitle(Title) {
    return (
      <View style={[styles.headingBackGround, styles.subtitleView]}>
        <Text style={styles.subTitleText}>{Title}</Text>
      </View>
    );
  }

  // Add Billing For Left Side View
  addBilling() {
    var bill = {
      "service_id": this.state.selectedBillingServiceId,
      "amount": this.state.selectedBillingServiceAmount,
      "name": this.state.selectedBillingServiceName,
      "description": this.state.selectedBillingServiceType.type,
    }

    var { Billing, selectedBillingServiceName, selectedBillingServiceAmount, selectedBillingServiceType } = this.state;


    if ((selectedBillingServiceAmount || selectedBillingServiceAmount == 0) && selectedBillingServiceName && selectedBillingServiceType.type) {
      Billing.push(bill);
      this.setState({
        Billing,
        amount_cash: 0,
        amount_card: 0
      }, () => {
        this.addBillingList()         // for Clear the right side selected values
        this.calculateTotalAmount();
      })
    }else if(this.state.selectedTemplateName){
      for (let i = 0; i < this.state?.templateServiceList?.length; i++) {
        Billing.push(
          {
            "service_name": this.state.templateServiceList[i].service_name,
            "service_type": this.state.templateServiceList[i].service_type,
            "amount":this.state.templateServiceList[i].amount
          });
      }

      this.setState({Billing},()=>{
        // var amount = this.state.billingList.reduce(function (sum, current) {
        //   return (parseFloat(sum) + parseFloat(current.TotalAmount)).toFixed(2);
        // }, 0);
        this.setState({
          Billing,
        amount_cash: 0,
        amount_card: 0
        }, () => {
          this.addBillingList()         // for Clear the right side selected values
          this.calculateTotalAmount();
        })
      })
    } 
    else {
      this.showResposeValue("error", Messages.NoServiceType)
    }



  }


  // Add Button View
  renderAddButton = (btnName, type) => {
    return (
      <TouchableOpacity onPress={() => { this.addBilling() }}
        style={[styles.RightAddButton, Style.allButtonBorderRadius]}
      >
        <Text
          style={styles.RightAddButtonText}
        >
          {btnName}
        </Text>
      </TouchableOpacity>
      // </View>
    );
  };


  clearBillingServiceDetails() {
    var states = this.state;
    states["selectedBillingServiceName"] = "";
    states["selectedBillingServiceId"] = "";
    states["selectedBillingServiceType"] = {};
    states["selectedBillingID"] = {};
    states["selectedBillingServiceAmount"] = null
    states["selectedTemplateName"] = ""

    states["ServiceNameSearchKey"] = ""
    states["billingServiceType"] = []
    states["searchServiceTypeViewShow"] = false

    this.setState({ states }, ()=> this.getBillingFrequentlyUsedService())
  }
  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  searchDescriptionMapping(value) {

    let { searchServiceTypeList } = this.state;
    let tempDescList = this.state.billingServiceType
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].type.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchServiceTypeList = searchListTemp;
    } else {
      searchServiceTypeList = this.state.billingServiceType;
    }
    this.setState({
      searchServiceTypeList
    })
  }

  getPatientCorporateDetails (){
    var service_url = Constants.GET_PATIENT_CORPORATE_DETAILS + "?patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientCorporateDetailsSuccess,
      this.getPatientCorporateDetailsFail
    );
    
  }
  getPatientCorporateDetailsSuccess = (response) => {
    try{
      if(response.status == "success"){
        if (response.data.company_name && response.data.company_name != "" && response.data.company_name != null) {
          this.setState({
            selectedBillType: "Corporate",
            SelectedCorporateName: response.data.company_name,
            SelectedEmployeeID: response.data.employee_id,
            discount_percent: response.data.employer_discount,
            EmployerID:response.data.employer_id,
            disableDiscountPercent: true
          }, ()=>{ this.calculateDiscountAmount()})
        }
        else {
          this.showResposeValue("error", 'This Patient is not Linked with Any Corporates. Please Add the Corporate in Patient Demographics')
        }
      }
    }
    catch (error){
      console.log(error)
    }
  }
  getPatientCorporateDetailsFail =  (error)=>{
    this.showResposeValue("error", error.message)
  }

  renderBillTypeView() {
    var listOfBillType = [
      { label: "Corporate", value: "Corporate" }
    ]
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <View>
          <Text style={[styles.emed_IPbill_sideheader]}>{"Bill Type"}</Text>
        </View>
        <View style={[styles.emed_content_container]}>
          {
            listOfBillType.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                  if (this.state.selectedBillType == item.value) {
                    this.setState({
                      selectedBillType: "",
                      SelectedCorporateName: "",
                      SelectedEmployeeID: "",
                      EmployerID: "",
                      discount_percent: 0,
                      disableDiscountPercent: false
                    }, ()=>{ this.calculateDiscountAmount()})
                  } else {
                    if (item.value == "Corporate") {

                      this.getPatientCorporateDetails()

                    } else {
                      this.setState({
                        selectedBillType: item.value,
                      })
                    }
                  }
                }} style={[styles.emed_radio_btn_touchablearea]} key={index}>
                  <View style={[{
                    backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                  }, styles.emed_radio_btn]}></View>
                  <Text style={[styles.emed_radio_btn_txt]}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
        {this.state.selectedBillType == "Corporate" ?
          <View style={{ flexDirection: "row" }}>
            {this.renderBillTypeTextBoxRow("Company Name", "billTypeCompanyName", this.state.SelectedCorporateName)}
            {this.renderBillTypeTextBoxRow("Employee ID", "billTypeEmployeeID", this.state.SelectedEmployeeID)}
          </View>
          :
          null
        }
        <Text>{this.state.CorporateErrorText}</Text>
      </View>
    )
  }

  renderBillTypeTextBoxRow(label, key, value) {
    return (
      <View style={{ marginVertical: '0.52vw', marginRight: '0.97vw' }}>
        <View style={{ flex: 0.4, justifyContent: "center" }}>
          <Text style={{ fontSize: '0.8vw', fontWeight: "500", marginBottom: '0.65vw' }}>{label}</Text>
        </View>
        <View style={{}}>
          {
            key ?
              <TextInput
               editable={false}
                style={{ textAlign: 'center', height: '1.82vw', width: '14vw', borderRadius: '0.26vw', borderWidth: 1, borderColor: "#91939F", fontSize:'0.9vw' }}
                value={value}
                onChangeText={(text) => {
                  if (key != "") {
                    var state = this.state;
                    state[key] = text;
                    this.setState({
                      state
                    })
                  }
                }}
              />
              : null
          }

        </View>

      </View>
    )
  }
  filterDocName = (docName) => {
    let filteredDocList = [];
    const serach = this.state.referrdDoc.filter(doctor=>{
        if(doctor.name.toLowerCase().includes(docName.toLowerCase())){
            filteredDocList.push(doctor)
        }
    })
    this.setState({refDoc_List: filteredDocList})
  }

  getOpServiceList = () =>{
    var url = ""
    if (this.state.userType == Constants.ROLE_FO) {
    url = Constants.CLINIC_OP_TEMPLATE_GET
    } else {
    url = Constants.CLINIC_OP_TEMPLATE_GET + "?clinic_id=" + this.state.selectedClinicOrDoctor.id;
    }

    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSuccess,
      this.getFailure
    )
  }

  getSuccess = (response) =>{
    if(response.status === "success"){
      this.setState({opList:response.data})
    }
  }

  getFailure = (response) =>{
    this.props.showResposeValue("error",response.message)
  }

  render() {
    // console.log(this.state.amount_card, this.state.amount_cash, "check===>")
    // console.log(this.state.patientAppointment, "apppp check===>")
    let navigationHeaderQuick = [];
    if (localStorage.getItem('isQuickBillingScreenSuccess') != null && localStorage.getItem('isQuickBillingScreenSuccess') == '') {
      navigationHeaderQuick = [
        { label: "Create Bill", value: "billingScreen" }]
    }
    let { enablePrint, amount_cash, amount_card, searchServiceTypeOpen, showServiceTypeBottomLayout } = this.state;
    // var amount_cash = this.state.amount_cash;
    // var amount_card = this.state.amount_card

    let billingItem = this.state.Billing ? JSON.parse(JSON.stringify(this.state.Billing)) : [];
    return (
      <TouchableWithoutFeedback onPress={() => {
        this.setState({
          searchServiceTypeList: []
        })
      }}>
        <View style={styles.MainView}>

          <View style={styles.MaivView2}>
            {
              this.state.responseType == "success" ?
                this.successAlert() :
                this.state.responseType == "error" ?
                  this.errorAlert() : null
            }
            <NavigationTopHeader
              changeTabSection={this.changeTabSection.bind(this)}
              navigationHeaderList={navigationHeaderQuick.length > 0 ? navigationHeaderQuick : navigationHeader}
              //navigationHeaderList={(localStorage.getItem('isQuickBillingScreenSuccess')!=null && localStorage.getItem('isQuickBillingScreenSuccess')=="")?{navigationHeaderQuick}:{navigationHeader}}
              selectedTab={"billingScreen"} />
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.ScrollViewLeft} bounces={false} bouncesZoom={false} >
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', zIndex: 2 }}>

                <View style={{ flexDirection: 'column' }}>
                  <Text style={styles.billingTextLeft}>{"Billing"}</Text>
                  <Text style={styles.patientAccNo}>{this.state.patientInfo ? this.state.patientInfo.patient_account_number : "#"}</Text>
                </View>
                <View style={{ justifyContent: "center" }}>
                  <DatePicker
                    disabledDate={current => {
                      return current && current < moment().add(-1, "month") ||
                        current && current > moment().add(0, "days");

                      ;
                    }}
                    size={20}
                    onChange={date => {
                      // var fulldate = new Date(date);
                      var converted_date = moment(date).format("YYYY-MM-DD");
                      this.setState({ billingDate: converted_date })
                    }}
                    value={moment(this.state.billingDate)}
                    defaultValue={moment(this.state.billingDate, "DD-MM-YYYY")}
                    format={"DD-MM-YYYY"}
                    placeholder={"Billing Date"}
                    clearIcon={false}
                    disabled={this.state.isAdmin || (!this.state?.isAdmin && this.state.userEditAccess) ? false : true}
                    style={{
                      borderColor: "transparent", borderWidth: 0, alignItems: "center", flexDirection: "row",
                      backgroundColor: "transparent", fontSize: '1.3vw', width: "80%", marginRight: '0.65vw'
                    }}
                  />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                  {this.state.userType === "FO" || this.state.role_Type === "Doctor"? 
                    <View style={styles.RefDoctorView}>
                      <Text style={styles.refDoctorText}>Referred Doctor</Text>
                        <TextInput
                            value={this.state.refDoctor}
                            style={styles.refDoctorTextInput}
                            onChangeText={(text) => {
                              if(text.length > 0){
                              this.filterDocName(text)
                            }else{
                              this.setState({refDoc_List: this.state.referrdDoc})
                            }
                              this.setState({
                                refDoctor: text
                              })
                          }}
                          onFocus= {()=> {this.setState({isRefDocFocus: true})}}
                          onBlur= {()=> {this.setState({isRefDocFocus: false})}} 
                        />
                      {this.state.isRefDocFocus ? 
                       <View style={styles.DocListWrapper}>
                       <ScrollView 
                       showsHorizontalScrollIndicator={true} 
                       showsVerticalScrollIndicator={false} 
                       style={{ maxHeight: '8vw' }}>
                                   
                                    {(this.state.refDoc_List).length > 0 && (this.state.refDoc_List).map((item, index) =>
                                    <View style={styles.DocsuggList}
                                    >
                                       <View style={{ width: '100%' }}>
                                           <TouchableOpacity
                                           key= {index}
                                              onPress={() => {
                                                var Doc = this.state
                                                Doc["refDoctor"] = item.name
                                                Doc["refDoc_ID"] = item.id
                                               this.setState({
                                                Doc
                                               })
                                               console.log(this.state.refDoctor)
                                              }}>
                                               <Text style={{ color: color.black,fontWeight: 'bold', fontSize:'0.91vw' }}>{item.name} - {item.specialization}</Text>
                                           </TouchableOpacity>
                                       </View>
                                       </View>
                                       )}          
                       </ScrollView>
                       </View>
                       : null

                      }
                    </View>
                    :null}

                  {!this.state.invoiceID || this.state.isBillModification ?
                    <TouchableOpacity onPress={() => {
                      this.clearBillingDetails()
                    }}>
                      <Text style={styles.ClearButtonLeft}>{"Clear"}</Text>
                    </TouchableOpacity>
                    : null}
                  {(this.props.userType == "FO" && navigationHeaderQuick.length == 0 && !this.state.isBillModification) ?
                    <TouchableOpacity onPress={() => {
                      var prepareData = {
                        patientDetails: {
                          "patient_id": this.state.patientAppointment.patient_id,
                          "patient_photo_url": this.state.patientInfo.photo_url,
                          "patient_name": this.state.patientInfo.name,
                          "patient_mobile_number": this.state.patientInfo.mobile_number,
                          // "invoice_date": this.state.patientInfo
                        },
                        isSearchedPatient: false,
                        patient_id: this.state.patientAppointment.patient_id
                      }
                      this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData, "", true)
                    }}
                      // disabled={!this.state.patientAppointment.appointment_id ? true : false}
                      >
                      <Text style={[styles.LabBillingButton, { backgroundColor: color.themeDark }]}>{"Laboratory Billing"}</Text>
                    </TouchableOpacity> : null
                  }
                </View>
              </View>
              <View style={styles.TableHeadWrapper}>
                <View style={[styles.headingBackGround, { flexDirection: 'row', marginBottom: '1.95vw', borderRadius: '0.4vw' }]}>
                  <View style={{ flex: 0.35 }}>
                    <Text style={styles.TableHeaderText}>Service Name</Text>
                  </View>
                  <View style={{ flex: 0.25 }}>
                    <Text style={styles.TableHeaderText}>Service Type</Text>
                  </View>
                  <View style={{ flex: 0.23 }}>
                    <Text style={styles.TableHeaderText}>Service Amount</Text>
                  </View>
                  <View style={{ flex: 0.17 }}>
                    <Text style={[styles.TableHeaderText, { textAlign: 'center' }]}>Action</Text>
                  </View>
                </View>
                <View style={styles.TableDataWrapper}>
                  {
                    billingItem.map((bills, index) => {
                      return this.renderItem(bills, index)
                    })
                  }
                  {billingItem.length === 0 ? <View style={styles.TableNoData}>
                    <Text style={styles.TableNoDataText}>Add service</Text>
                  </View> : null}
                </View>
              </View>

              {this.renderBillTypeView()}

              <View style={styles.PaymentTypeWrapper}>
                <View style={{ flex: 0.55, flexDirection: "column" }}>
                  {/* Payment Type */}
                  <View style={styles.paymentview}><Text style={styles.PaymentTypeHeading}>{"Payment Type"}</Text></View>
                  <View style={styles.PaymentView}>
                    {/* <View style={{ flexDirection: 'row', flex: 0.4, justifyContent: 'space-between' }}> */}
                    {this.renderPaymentBtn(
                      ImagePath.Cash,
                      "Cash"
                    )}
                    {this.renderPaymentBtn(
                      ImagePath.Card,
                      "Card",)}
                    {this.renderPaymentBtn(
                      ImagePath.UPI,
                      "UPI"
                    )}
                    {this.renderPaymentBtn(
                      ImagePath.BankTransfer,
                      "Bank Transfer"
                    )}
                    {/* {this.renderPaymentBtn(
                      ImagePath.Cheque,
                      "Cheque"
                    )} */}
                  </View>
                  {/* </View> */}

                </View>
                <View style={styles.PaymentRightView}>

                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.7 }}><Text style={styles.AmmountText}>{"Total Amount"}</Text></View>
                    <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={styles.AmountData}>{(this.state.totalAmount).toFixed(2)}</Text></View>
                  </View>
                  <View style={styles.AmountView}>
                    <View style={{ flex: 0.3 }}><Text style={styles.AmmountText}>{"Discount %"}</Text></View>

                    {/* <View style={{ width: '13%', marginTop: 25,marginRight: 10 }}> */}
                    {this.renderTextBoxAlone("Discount %", 0.3, false, "discouont_percent", this.state.discount_percent, -1, "70%")}
                    {/* </View>
                <View style={{ width: '19%', marginTop: 25 }}> */}
                    {this.renderTextBoxAlone("Discount Amount", 0.4, false, "discountAmount", this.state.discount, -1, "100%")}
                    {/* </View> */}
                  </View>
                  <View style={styles.AmountView}>
                    <View style={{ flex: 0.7 }}><Text style={styles.AmmountText}>{"Round Off"}</Text></View>
                    <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={styles.AmountData}>{(this.state.round_off).toFixed(2)}</Text></View>
                  </View>
                </View>
              </View>

              {/* <View style={{ alignItems: "center", justifyContent: "center", marginTop: 30, zIndex: -1 }}>
              <TouchableOpacity
                onPress={() =>
                  this.addBillingList()
                }
              >
                <ADD
                  name="md-add"
                  color={"white"}
                  size={20}
                  style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingTop: 8, paddingBottom: 8, paddingRight: 13, paddingLeft: 13 }}
                />
              </TouchableOpacity>
            </View> */}

              {/* Amount calculation  View (Discount ect..) */}
              {/*
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row', borderBottomColor: color.lightGray, borderBottomWidth: 1, padding: 10, zIndex: -1 }}>

              <View style={{ width: '13%', marginTop: 25,marginRight: 10 }}>
                {this.renderBillinTextBox("Discount %", "100%", false, "discouont_percent", this.state.discount_percent, -1)}
              </View>
              <View style={{ width: '19%', marginTop: 25 }}>
                {this.renderBillinTextBox("Discount Amount", "95%", false, "discountAmount", this.state.discount, -1)}
              </View>


              <View style={{ width: '30%' }}>
                <View style={{ flexDirection: 'row', width: "65%" }}>
                  <Text style={{ fontSize: 16, color: color.notesTableTitle }}>{"Total Amount"}</Text>
                  <Text style={{ marginLeft: 8, fontSize: 18, textAlign: 'right' }}>{(this.state.totalAmount).toFixed(2)}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 15, width: "65%" }}>
                  <Text style={{ fontSize: 16, color: color.notesTableTitle }}>{"Round Off"}</Text>
                  <Text style={{ marginLeft: 28, fontSize: 18, textAlign: 'right', justifyContent: 'flex-end', paddingRight: 10 }}>
                    {this.state.round_off > 0.5 ? "+" : this.state.round_off == 0 ? "" : "-"}{(this.state.round_off).toFixed(2)}</Text>
                </View>
              </View>
            </View>
            */}
              <View style={styles.PaymentInputBoxView}>
                {/* <View style={{ flex: 1, height: 1, borderColor: color.lightGray }}></View> */}

                {this.state.firstPayment != "" || this.state.firstPayment != undefined || this.state.secondPayment != "" ?
                  <View style={{ width: "55%" }}>
                    <View style={{ display: "flex", flexDirection: "row", marginBottom:".5vw" }}>
                      <View>
                        {this.renderPayment(this.state.firstPayment)}
                      </View>
                      {this.renderPayment(this.state.secondPayment)}
                    </View>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      {this.state.firstPayment != "" ?
                        this.renderBillinTextBox(`${this.state.firstPayment} Amount`, "90%", false, "firstAmount", this.state.firstAmount, -1,): null}
                      {this.state.secondPayment != "" ?
                        this.renderBillinTextBox(`${this.state.secondPayment} Amount`, "90%", false, "secondAmount", this.state.secondAmount, -1,): null}
                    </View>
                  </View>
                  : <View style={{ height: "4.5vw", width: "55%" }}></View>}

                {/* Net Amount View */}
                <View style={styles.NetAmtWrapper}>
                  <Text style={styles.NetAmtText}>Net Amount</Text>
                  <Text style={styles.NetAmtData}>{(this.state.amount_invoice).toFixed(2)}</Text>
                </View>
              </View>



              {/* <View style={{ marginTop: 30 }}>
              <TouchableOpacity style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 8, width: "20%", height: 35 }} onPress={() => { this.saveBillingDetails() }}>
                <Text style={{ color: "white", textAlign: 'center' }}>Save</Text>
              </TouchableOpacity>
            </View> */}

              <View style={styles.ButtonWrapper}>
                <TouchableOpacity onPress={() => {
                  this.saveBillingDetails()
                }} disabled={this.state.isDisabled || this.state.firstPayment  || this.state.secondPayment || (this.state.isAdmin && this.state.isBillModification) || !this.state.patientAppointment.appointment_id || (!this.state?.isAdmin && this.state.userEditAccess && this.state.isBillModification) ? true : false} 
                style={[{ backgroundColor: this.state.isDisabled || this.state.firstPayment  || this.state.secondPayment || (this.state.isAdmin && this.state.isBillModification) || !this.state.patientAppointment.appointment_id || (!this.state?.isAdmin && this.state.userEditAccess && this.state.isBillModification) ? color.lightGray : color.themeDark, }, styles.ButtonView]}>
                  <Text style={styles.ButtonText}>{"Save"}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  this.setState({
                    isPaymentReceived: true
                  }, () => {
                    this.setState({
                      isPaymentReceived: false
                    })
                    this.saveBillingDetails()
                  })
                }} disabled={this.state.isDisabled || (this.state.isAdmin && this.state.isBillModification) || !this.state.patientAppointment.appointment_id ||(!this.state?.isAdmin &&  this.state.userEditAccess && this.state.isBillModification) ? true : false} 
                style={[{ backgroundColor: (this.state.isAdmin && this.state.isBillModification) || (!this.state.patientAppointment.appointment_id) || (!this.state?.isAdmin && this.state.userEditAccess && this.state.isBillModification) ? color.lightGray : color.themeDark, }, styles.ButtonView]}>
                  <Text style={styles.ButtonText}>{"Payment Received"}</Text>
                </TouchableOpacity>


                {/* <TouchableOpacity style={{ width: 100, marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: color.themeDark, borderRadius: 8, height: 35 }} onPress={() => { this.saveBillingDetails() }}>
                  <Text style={{ color: "white", textAlign: 'center' }}>
                  <Icon name={"save"} size={platform === "web" ? 15 : 20} color={color.white} /> Save</Text>
                </TouchableOpacity> */}


                <TouchableOpacity disabled={!enablePrint} onPress={() => {
                  this.printBillingDetails()
                }} style={[{ backgroundColor: enablePrint ? color.themeDark : color.lightGray }, styles.ButtonView]}>
                  <Text style={styles.ButtonText}>{"Print"}</Text>
                </TouchableOpacity>
                  <TouchableOpacity disabled={this.state.isBillModification ? false : true} onPress={() => {
                    this.setState({
                      isUpdate: true
                    }, () => {
                      this.saveBillingDetails()
                    })
                  }} style={[{ backgroundColor: this.state.isBillModification ? color.themeDark : color.lightGray }, styles.ButtonView]}>
                    <Text style={styles.ButtonText}>{"Update"}</Text>
                  </TouchableOpacity> 
                {/* <TouchableOpacity disabled={!enablePrint} style={{ width: 100, marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: enablePrint?color.themeDark:color.lightGray, borderRadius: 8, height: 35 }} onPress={() => { this.printBillingDetails() }}>
                  <Text style={{ color: "white", textAlign: 'center' }}>
                  <Icon name={"print"} size={platform === "web" ? 15 : 20} color={color.white} /> Print</Text>
                </TouchableOpacity> */}
              </View>
            </ScrollView>
          </View>

          <ScrollView showsVerticalScrollIndicator={false}
            style={styles.ScrollViewRight} >
            <View style={[Style.rightView, { padding: '1.3vw', width: "100%" }]}>
              <TouchableWithoutFeedback onPress={() => { this.onSubmitEditing() }}>
                <View>

                  <View style={styles.RightTopView} >
                    <View style={{ width: '60%' }}>
                      {this.title("Billing")}
                    </View>
                    <View style={styles.RightClearButtonView}>
                      <TouchableOpacity style={styles.RightClearButton}
                        onPress={() => {
                          this.clearBillingServiceDetails();
                        }}
                      ><Text style={styles.RightClearButtonText}>{"Clear"}</Text></TouchableOpacity>
                    </View>
                  </View>
                  <View>
                    {this.subtitle("OP Template")}
                    <View style={styles.ServiceNameData}>
                      {this.state.opList.map((item, index) => {
                        return (
                          <View>
                            <TouchableOpacity key={index}
                              style={[{
                                backgroundColor: this.state.selectedTemplateName === item.template_name ? color.themeDark : null,
                                borderColor: this.state.selectedTemplateName == item.template_name ? color.themeDark : color.textBoxBorderColor
                              }, Style.allButtonBorderRadius, styles.ServiceButtons]} 
                              onPress = {() => {
                                this.setState({
                                  selectedTemplateName: item.template_name,
                                  templateServiceList: item.service_data,
                                  selectedBillingServiceName:"",
                                  billingServiceType : []
                                })
                              }}
                            >
                              <Text style={[{
                                color: this.state.selectedTemplateName === item.template_name ? color.white : color.black
                              }, styles.ServiceButtonsText]}>{item.template_name}</Text>
                            </TouchableOpacity>
                          </View>
                        )
                      })
                      }

                    </View>
                  </View>
                  {this.subtitle("Service Name")}
                  <View style={styles.SearchBoxView}>
                    <TextInput
                        style={styles.SearchInput}
                        placeholder={"Search by Service Name (Minimum 3 Characters to Search)"}
                        value={this.state.ServiceNameSearchKey}
                        onChangeText={(text) => {
                            this.setState({
                              ServiceNameSearchKey: text
                            }, () => {
                                if(this.state.ServiceNameSearchKey.length > 2){
                                  this.getBillingFrequentlyUsedService()
                                }
                                else if (this.state.ServiceNameSearchKey.length == 0){
                                  this.getBillingFrequentlyUsedService()
                                }
                            })
                        }}
                    />
                </View>
                  <View style={styles.ServiceNameData}>
                    {this.state.billingService.length > 0 ?
                      this.state.billingService.map((items, index) => {
                        return this.renderServiceNameButtons(items, index);
                      }) : null
                    }
                    {/* <View  > */}
                    {/* <View style={{marginBottom:20}} > */}



                  </View>


                  <View style={styles.ServiceTypeHeadView}>
                    {this.subtitle("Service Type")}
                  </View>
                  <View style={{ zIndex: 1 }}>
                    {this.renderBillingDescriptionSuggesstion()}
                  </View>
                  {this.state.searchServiceTypeViewShow ? <View style={{ marginTop: 0, zIndex: -1 }}>
                    <Text style={styles.ServiceTypeNameText}>{'Search Description'}</Text>

                    <TextInput

                      style={styles.ServiceTypeInput}
                      value={
                        this.state.searchServiceType
                      }
                      onFocus={() => {
                        searchServiceTypeOpen = true;
                        showServiceTypeBottomLayout = true;
                        this.setState({
                          searchServiceTypeOpen,
                          showServiceTypeBottomLayout
                        })
                      }}
                      onChangeText={(text) => {
                        var { searchServiceType, searchServiceTypeOpen, showServiceTypeBottomLayout, selectedBillingServiceType } = this.state;
                        if (text != "") {
                          searchServiceTypeOpen = true;
                          showServiceTypeBottomLayout = true;
                          searchServiceType = text
                          this.setState({
                            searchServiceType,
                            showServiceTypeBottomLayout,
                            searchServiceTypeOpen
                          }, () => {
                            this.searchDescriptionMapping(searchServiceType);
                          })
                        } else {
                          searchServiceTypeOpen = false;
                          searchServiceType = text;
                          selectedBillingServiceType = {};
                          this.setState({
                            searchServiceTypeOpen,
                            searchServiceType,
                            selectedBillingServiceType
                          }, () => {
                            this.searchDescriptionMapping(searchServiceType);
                          })
                        }
                      }}

                    />

                  </View> : null
                  }
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', zIndex: -1, marginTop: '0.65vw' }}>
                    {this.state.billingServiceType.length > 0 ?
                      this.state.billingServiceType.map((items, index) => {
                        return this.renderServiceTypeButtons(items, index);
                      }) : null
                    }

                    {/* </View> */}
                    {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
                    {/* <View>*/}

                  </View>

                  {this.subtitle("Service Amount")}
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 1, zIndex: -1 }}>
                    {this.state.selectedBillingServiceAmount != null ?
                      this.renderServiceAmountButtons(this.state.selectedBillingServiceAmount, 0)
                      : null
                    }
                    {/* </View> */}
                    {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
                  </View>

                 {(this.state.isAdmin || !this.state.isSavedBill || (!this.state?.isAdmin && this.state.userEditAccess)) ?
                  <View style={styles.RightAddbuttonView}>
                    {this.renderAddButton("Add", "add")}
                  </View> : null}
                </View>
              </TouchableWithoutFeedback>
            </View>
          </ScrollView>
        </View>
      </TouchableWithoutFeedback>

      // <View style={{ height: screenHeight, flex: 1, flexDirection: "row", width: "100%" }}>
      //   <View style={[styles.leftView, { width: "65%",marginTop:40 }]}>


      // {
      //     this.state.responseType == "success" ?
      //       this.successAlert() :
      //       this.state.responseType == "error" ?
      //         this.errorAlert() : null
      //   }

      //     {/* {this.renderHeaderView()} */}

      //     <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
      // <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      //   <View style={{ flexDirection: 'column' }}>
      //     <Text style={{ fontSize: 20, fontWeight: '500', color: color.themeDark }}>{"Billing"}</Text>
      //     <Text style={{ fontSize: 13, color: color.themeDark }}>{this.state.patientInfo?this.state.patientInfo.patient_account_number:"#"}</Text>
      //   </View>
      //   <View style={{ justifyContent: "center" }}>
      //     <DatePicker
      //        disabledDate={current => {
      //         return current && current < moment().add(-1, "month") ||
      //         current && current > moment().add(0, "days");

      //         ; }}
      //       size={20}
      //       onChange={date => {
      //         var fulldate = new Date(date);
      //         var converted_date = moment(fulldate).format("DD-MM-YYYY");
      //         this.setState({ billingDate: converted_date })
      //       }}
      //       defaultValue={moment(this.state.billingDate, "DD-MM-YYYY")}
      //       format={"DD-MM-YYYY"}
      //       placeholder={"Billing Date"}
      //       style={{
      //         borderColor: "transparent", borderWidth: 0, alignItems: "center", flexDirection: "row",
      //         backgroundColor: "transparent", fontSize: 20, width: "80%", marginRight:10
      //       }}
      //     />
      //   </View>
      //   <View style={{ justifyContent: "center" }}>
      //     {/* <TouchableOpacity style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5 }}
      //       onPress={() => {
      //         this.clearBillingDetails();
      //       }}
      //     ><Text style={{ fontSize: 15, color: 'white' }}>{"Clear"}</Text></TouchableOpacity> */}


      //     <TouchableOpacity onPress={() => {
      //       this.clearBillingDetails()
      //     }} style={[{ marginLeft: 20, paddingHorizontal: 18, paddingVertical: 8, backgroundColor: color.themeDark, }, Style.allButtonBorderRadius]}>
      //       <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Clear"}</Text>
      //     </TouchableOpacity>
      //   </View>
      // </View>
      // <View style={{ marginTop: 15, }}>
      //   <View style={[ styles.headingBackGround, { flexDirection: 'row', marginBottom: 30 }]}>
      //     <View style={{ flex: 0.35 }}>
      //       <Text style={{ fontSize: 17 }}>Service Name</Text>
      //     </View>
      //     <View style={{ flex: 0.25 }}>
      //       <Text style={{ fontSize: 17 }}>Service Type</Text>
      //     </View>
      //     <View style={{ flex: 0.23 }}>
      //       <Text style={{ fontSize: 17, textAlign: 'center' }}>Service Amount</Text>
      //     </View>
      //     <View style={{ flex: 0.17 }}>
      //       <Text style={{ fontSize: 17, textAlign: 'center' }}>Action</Text>
      //     </View>
      //   </View>
      //   <View style={{ minHeight: 150 }}>
      //     {
      //       billingItem.map((bills, index) => {
      //         return this.renderItem(bills, index)
      //       })
      //     }
      //     {billingItem.length===0?<View style={{ height: 150, flexDirection: "row", justifyContent: "center", alignContent: "center",
      //        }}>
      //       <Text style={{ color: color.notesTableTitle }}>Add service</Text>
      //     </View>:null}
      //   </View>
      // </View>

      // <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-end", 
      //   borderBottomColor: color.lightGray, borderBottomWidth: 1, paddingBottom: 15 }}>
      //   <View style={{ flex: 0.55, flexDirection: "column" }}>
      //     {/* Payment Type */}
      //     <View style={{ marginBottom: 20, zIndex: -1 }}><Text>{"Payment Type"}</Text></View>
      //     <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingRight: 30, zIndex: -1 }}>
      //       {/* <View style={{ flexDirection: 'row', flex: 0.4, justifyContent: 'space-between' }}> */}
      //         {this.renderPaymentBtn(
      //           require("../../../../../assets/images/IPActions/Card.png"),
      //           "Card"
      //         )}
      //         {this.renderPaymentBtn(
      //           require(".././../../../../assets/images/IPActions/Cash.png"),
      //           "Cash"
      //         )}
      //         {this.renderPaymentBtn(
      //              require("../../../../../assets/images/IPActions/Cash&Card .png"),
      //           "Cash and Card"
      //         )}
      //     </View>
      //       {/* </View> */}

      //   </View>
      //   <View style={{ flex: 0.45, flexDirection: "column" }}>

      //     <View style={{ flexDirection: 'row' }}>
      //       <View style={{ flex: 0.7 }}><Text style={{ color: color.notesTableTitle }}>{"Total Amount"}</Text></View>
      //       <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={{ marginLeft: 8, fontSize: 18, textAlign: 'right' }}>{(this.state.totalAmount).toFixed(2)}</Text></View>
      //     </View>
      //     <View style={{ flexDirection: 'row', marginTop: 15 }}>
      //       <View style={{ flex: 0.3 }}><Text style={{ color: color.notesTableTitle }}>{"Discount %"}</Text></View>

      //     {/* <View style={{ width: '13%', marginTop: 25,marginRight: 10 }}> */}
      //       {this.renderTextBoxAlone("Discount %", 0.3, false, "discouont_percent", this.state.discount_percent, -1, "70%")}
      //     {/* </View>
      //     <View style={{ width: '19%', marginTop: 25 }}> */}
      //       {this.renderTextBoxAlone("Discount Amount", 0.4, false, "discountAmount", this.state.discount, -1, "100%")}
      //     {/* </View> */}
      //     </View>
      //     <View style={{ flexDirection: 'row', marginTop: 15 }}>
      //       <View style={{ flex: 0.7 }}><Text style={{ color: color.notesTableTitle }}>{"Round Off"}</Text></View>
      //       <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={{ marginLeft: 8, fontSize: 18, textAlign: 'right' }}>{(this.state.round_off).toFixed(2)}</Text></View>
      //     </View>
      //   </View>
      // </View>

      // {/* <View style={{ alignItems: "center", justifyContent: "center", marginTop: 30, zIndex: -1 }}>
      //   <TouchableOpacity
      //     onPress={() =>
      //       this.addBillingList()
      //     }
      //   >
      //     <ADD
      //       name="md-add"
      //       color={"white"}
      //       size={20}
      //       style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingTop: 8, paddingBottom: 8, paddingRight: 13, paddingLeft: 13 }}
      //     />
      //   </TouchableOpacity>
      // </View> */}

      // {/* Amount calculation  View (Discount ect..) */}
      // {/*
      // <View style={{ justifyContent: 'flex-end', flexDirection: 'row', borderBottomColor: color.lightGray, borderBottomWidth: 1, padding: 10, zIndex: -1 }}>

      //   <View style={{ width: '13%', marginTop: 25,marginRight: 10 }}>
      //     {this.renderBillinTextBox("Discount %", "100%", false, "discouont_percent", this.state.discount_percent, -1)}
      //   </View>
      //   <View style={{ width: '19%', marginTop: 25 }}>
      //     {this.renderBillinTextBox("Discount Amount", "95%", false, "discountAmount", this.state.discount, -1)}
      //   </View>


      //   <View style={{ width: '30%' }}>
      //     <View style={{ flexDirection: 'row', width: "65%" }}>
      //       <Text style={{ fontSize: 16, color: color.notesTableTitle }}>{"Total Amount"}</Text>
      //       <Text style={{ marginLeft: 8, fontSize: 18, textAlign: 'right' }}>{(this.state.totalAmount).toFixed(2)}</Text>
      //     </View>
      //     <View style={{ flexDirection: 'row', marginTop: 15, width: "65%" }}>
      //       <Text style={{ fontSize: 16, color: color.notesTableTitle }}>{"Round Off"}</Text>
      //       <Text style={{ marginLeft: 28, fontSize: 18, textAlign: 'right', justifyContent: 'flex-end', paddingRight: 10 }}>
      //         {this.state.round_off > 0.5 ? "+" : this.state.round_off == 0 ? "" : "-"}{(this.state.round_off).toFixed(2)}</Text>
      //     </View>
      //   </View>
      // </View>
      // */}
      // <View style={{ flexDirection: 'row', paddingTop: 15 }}>
      //     {/* <View style={{ flex: 1, height: 1, borderColor: color.lightGray }}></View> */}
      //     <View style={{ flexDirection: 'row', flex: 0.55, justifyContent: "flex-end" }}>
      //         <View style={{ width: 75 }}>
      //         <Text style={{ position: 'absolute', marginLeft: 8, backgroundColor: "white", fontSize: 12, paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{"Cash"}</Text>
      //           <View style={{marginTop:7,zIndex:-1}}>
      //           {this.renderBillinTextBox("", "90%", false, "Cash", amount_cash, -1,)}
      //           </View>
      //         </View>
      //         <View style={{ width: 75, marginHorizontal: 15 }}>
      //           <Text style={{ position: 'absolute', marginLeft: 8, backgroundColor: "white", fontSize: 12, paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{"Card"}</Text>
      //           <View style={{marginTop:7,zIndex:-1}}>
      //             {this.renderBillinTextBox("", "90%", false, "Card", amount_card, -1)}
      //           </View>
      //         </View>
      //       {/* </View> */}
      //     </View>

      //     {/* Net Amount View */}
      //     <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 0.45, marginTop: 15, zIndex: -1 }}>
      //       <Text style={{ fontSize: 16, color: color.notesTableTitle, flex: 0.7 }}>Net Amount</Text>
      //       <Text style={{ fontSize: 18, textAlign: 'right', flex: 0.3 }}>{(this.state.amount_net).toFixed(2)}</Text>
      //     </View>
      // </View>



      // {/* <View style={{ marginTop: 30 }}>
      //   <TouchableOpacity style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 8, width: "20%", height: 35 }} onPress={() => { this.saveBillingDetails() }}>
      //     <Text style={{ color: "white", textAlign: 'center' }}>Save</Text>
      //   </TouchableOpacity>
      // </View> */}

      // <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 30 }}>



      // <TouchableOpacity onPress={() => {
      //       this.saveBillingDetails()
      //     }} disabled={this.state.isDisabled} style={[{  marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: color.themeDark, paddingHorizontal: 18, paddingVertical: 8, }, Style.allButtonBorderRadius]}>
      //       <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Save"}</Text>
      //     </TouchableOpacity>


      //     {/* <TouchableOpacity style={{ width: 100, marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: color.themeDark, borderRadius: 8, height: 35 }} onPress={() => { this.saveBillingDetails() }}>
      //       <Text style={{ color: "white", textAlign: 'center' }}>
      //       <Icon name={"save"} size={platform === "web" ? 15 : 20} color={color.white} /> Save</Text>
      //     </TouchableOpacity> */}


      //   <TouchableOpacity  disabled={!enablePrint} onPress={() => {
      //     this.printBillingDetails()
      //   }} style={[{ flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: enablePrint?color.themeDark:color.lightGray, paddingHorizontal: 18, paddingVertical: 8, }, Style.allButtonBorderRadius]}>
      //     <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Print"}</Text>
      //   </TouchableOpacity>

      //     {/* <TouchableOpacity disabled={!enablePrint} style={{ width: 100, marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: enablePrint?color.themeDark:color.lightGray, borderRadius: 8, height: 35 }} onPress={() => { this.printBillingDetails() }}>
      //       <Text style={{ color: "white", textAlign: 'center' }}>
      //       <Icon name={"print"} size={platform === "web" ? 15 : 20} color={color.white} /> Print</Text>
      //     </TouchableOpacity> */}
      // </View>
      //     </ScrollView>
      //   </View>



      //   <View style={[styles.rightView, { width: "35%" }]}>

      //     <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      // <TouchableWithoutFeedback onPress={()=>{ this.onSubmitEditing()}}>
      //   <View>

      // <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 20}} >
      //   <View style={{ width: '60%' }}>
      //   {this.title("Billing")}
      //   </View>
      //   <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center", width: "40%" }}>
      //       <TouchableOpacity style={{ maxWidth: 95, backgroundColor: color.themeDark, borderRadius: 4, paddingHorizontal: 10, paddingVertical: 5 }}
      //         onPress={() => {
      //           this.clearBillingServiceDetails();
      //         }}
      //       ><Text style={{ fontSize: 15, color: 'white' }}>{"New"}</Text></TouchableOpacity>
      //   </View>
      // </View>
      // {this.subtitle("Service Name")}
      // <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      //   {
      //     this.state.billingServiceName.map((items, index) => {
      //       return this.renderServiceNameButtons(items, index, "serviceName");
      //     })
      //   }
      // <View  >
      //   {/* <View style={{marginBottom:20}} > */}
      //       {
      //         this.state.showServiceNameNewButton ?
      //           <View>
      //             <TouchableOpacity onPress={() => {
      //               this.setState({
      //                 showServiceNameNewButton: false,
      //                 showServiceAmountNewButton: true,
      //                 showServiceTypeNewButton: true,
      //               })
      //             }} style={[{
      //               // height: 30,
      //               flexDirection:'row',
      //               justifyContent: "space-evenly",
      //               alignSelf:'flex-start',
      //               backgroundColor:color.white,
      //               marginBottom: 15,
      //               // borderRadius: 20,
      //               borderColor: color.textBoxBorderColor,
      //               borderWidth: 1,
      //               paddingHorizontal: 18, paddingVertical: 6.5,
      //               // width:  80, 
      //               // paddingLeft: 18, paddingRight: 18, paddingTop: 8, paddingBottom: 8, 
      //               marginLeft: 10
      //             },Style.allButtonBorderRadius]}>
      //             <Icon name={"plus"} size={ 15} color={"#848484" } />
      //             <Text style={{color:"#848484"}}> {"New"} </Text>
      //             </TouchableOpacity>
      //           </View>
      //           :
      //           <View style={{ marginLeft: 10 , alignSelf:'flex-start'}}>
      //             {this.renderBillinTextBox("", 120, true, 'serviceName', this.state.service, "", 30)}
      //           </View>
      //       }
      //     </View>
      //     </View>

      //   {this.subtitle("Service Type")}
      //   <View style={{ flexDirection: 'row', flexWrap: 'wrap', zIndex: -1 }}>
      //     {
      //       this.state.billingServiceType.map((items, index) => {
      //         return this.renderServiceTypeButtons(items, index, "serviceType");
      //       })
      //     }
      //   {/* </View> */}
      //   {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
      //       <View>
      //       {
      //         this.state.showServiceTypeNewButton ?

      //           <View>
      //             <TouchableOpacity onPress={() => {
      //               this.setState({
      //                 showServiceTypeNewButton: false,
      //                 showServiceAmountNewButton: true,
      //                 showServiceNameNewButton: true,

      //               })
      //             }} style={[{
      //               // height: 30,
      //               flexDirection:'row',
      //               justifyContent: "space-evenly",
      //               // alignItems: "center",
      //               backgroundColor:color.white,
      //               marginBottom: 15,
      //               alignSelf:'flex-start',
      //               paddingHorizontal: 18, paddingVertical: 6.5,
      //               // borderRadius: 20,
      //               borderColor: color.textBoxBorderColor,
      //               borderWidth: 1,
      //               // width:  80, 
      //               // paddingLeft: 18, paddingRight: 18, paddingTop: 8, paddingBottom: 8, 
      //               marginLeft: 10  
      //             },Style.allButtonBorderRadius]}> 
      //             <Icon name={"plus"} size={ 15} color={"#848484" } />
      //             <Text style={{color:"#848484"}}> {"New"} </Text>
      //             </TouchableOpacity>
      //           </View>
      //           :
      //           <View style={{ marginLeft: 10,alignSelf:'flex-start' }}>
      //             {this.renderBillinTextBox("", 120, true, 'serviceType', this.state.types, "", 30)}
      //           </View>
      //       }
      //       </View>
      //     </View>



      //   {this.subtitle("Service Amount")}
      //   <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 1, zIndex: -1 }}>
      //     {
      //       this.state.billingServiceAmount.map((items, index) => {
      //         return this.renderServiceAmountButtons(items, index, "serviceAmount");
      //       })
      //     }
      //   {/* </View> */}
      //   {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
      //   <View>
      //       {
      //         this.state.showServiceAmountNewButton ?

      //           <View>
      //             <TouchableOpacity onPress={() => {
      //               this.setState({
      //                 showServiceAmountNewButton: false,
      //                 showServiceNameNewButton: true,
      //                 showServiceTypeNewButton: true,
      //               })
      //             }} style={[{
      //               // height: 30,
      //               flexDirection:'row',
      //               justifyContent: "space-evenly",
      //               alignSelf:'flex-start',
      //               backgroundColor:color.white,
      //               marginBottom: 15,
      //               // borderRadius: 20,

      //               borderColor: color.textBoxBorderColor,
      //               borderWidth: 1,
      //               paddingHorizontal: 18, paddingVertical: 6.5,
      //               // width:  80, 
      //               // paddingLeft: 18, paddingRight: 18, paddingTop: 8, paddingBottom: 8, 
      //               marginLeft: 10
      //             },Style.allButtonBorderRadius]}>

      //             <Icon name={"plus"} size={ 15} color={"#848484" } />
      //             <Text style={{color:"#848484"}}> {"New"} </Text>
      //             </TouchableOpacity>
      //           </View>
      //           :
      //           <View style={{ marginLeft: 10,alignSelf:'flex-start' }}>
      //             {this.renderBillinTextBox("", 120, true, 'serviceAmount', this.state.amounts, "", 30)}
      //           </View>
      //       }
      //       </View>
      //     </View>


      //   <View style={{ zIndex: -1, marginTop: 30, marginBottom:15 }}>
      //     {this.renderAddButton("Add", "add")}
      //   </View>
      //   </View>
      //   </TouchableWithoutFeedback>
      //     </ScrollView>

      //   </View>
      // </View>
    );
  }

  clearDetails = () =>{
    this.setState({
      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : "",
      CardNumber : "",
      upiTransactionID: "",
      bank_transaction_id: "",
    })
  }

  clearSinglePayment = (item) =>{
    if(item === "Card"){
      this.setState({
        CardNumber : "",
      })
    }else if(item === "UPI"){
      this.setState({
        upiTransactionID: ""
      })
    }else if(item === "Bank Transfer"){
      this.setState({
        bank_transaction_id : ""
      })
    }else if(item === "Cheque"){
      this.setState({
        Cheque : ""
      })
    }
    this.setState({
      secondAmount : ""
    })
  }

  ChangePaymentType = (item) => {
    if (this.state.firstPayment == "" && this.state.secondPayment == "") {
      if (this.state.secondPayment != item) {
        this.setState({
          firstPayment: item
        })
      } else {
        this.setState({
          secondPayment: ""
        }, () => {
          this.clearSinglePayment(item)
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment == "") {
      if (this.state.firstPayment != item) {
        this.setState({
          secondPayment: item
        })
      } else {
        this.setState({
          firstPayment: ""
        }, () =>{
          this.clearDetails()
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment != "") {
      if (this.state.firstPayment == item) {
        this.setState({
          firstPayment: this.state.secondPayment,
          secondPayment:""
        }, () => {
          this.clearSinglePayment(item)
        })
      } else if (this.state.secondPayment == item) {
        this.setState({
          secondPayment: ""
        }, () => {
          this.clearSinglePayment(item)
        })
      } else {
        var tem_Type = this.state.secondPayment
        this.setState({
          secondPayment : this.state.firstPayment,
          firstPayment: item
        }, () => {
          this.clearSinglePayment(tem_Type)
        })
      }
    }
    this.setState({
      firstAmount : this.state.amount_net,
      secondAmount : ""
    })
  }

  renderPayment = (paymentType) => {
    if (paymentType === "Card") {
      return (
        <View>
          {this.renderBillinTextBox("Card Transaction ID", "90%", false, "CardNumber", this.state.CardNumber, -1)}
        </View>
      )
    }
    else if (paymentType === "UPI") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderBillinTextBox("UPI Transaction ID", "90%", false, "upiTransactionID", this.state.upiTransactionID, -1,)}
        </View>
      )
    }
    else if (paymentType === "Bank Transfer") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderBillinTextBox("Bank Transaction ID", "90%", false, "bank_transaction_id", this.state.bank_transaction_id, -1,)}
        </View>
      )
    }
    else if(paymentType === "Cheque"){
      return(
        <View>
          {this.renderBillinTextBox("Cheque Number", "90%", false, "Cheque", this.state.Cheque, -1,)}
        </View>
      )
    } else {
      return null
    }
  }

  renderPaymentBtn = (img, title) => {
    var paymentFlag = false
    if (this.state.Billing && this.state.Billing.length == 1) {
      if (this.state.Billing[0].description == "Free Consultation") {
        paymentFlag = true
      }
    } else {
      paymentFlag = false
    }
    return (
      <View style={styles.PaymentButtonView}>
        <TouchableOpacity
          disabled={this.state.firstPayment == "" && this.state.secondPayment == "" ? paymentFlag : null}
          // onPress={() => {
          //   this.paymentType(title)
          // }}
          >
          <Checkbox onChange={() => { this.ChangePaymentType(title) }} style={{ color: this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : color.notesTableTitle }} checked={this.state.firstPayment == title || this.state.secondPayment == title ? true : false}>{title}</Checkbox>
        </TouchableOpacity>
        <Image
          source={img}
          style={[styles.paymentIconImg, { tintColor: this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : color.notesTableTitle }]}
          resizeMode="contain"
        />
      </View>
    );
  };

  onSubmitEditing() {
    // if (key === "serviceType") {
    //   var { billingServiceType } = this.state;
    //   this.state.billingServiceType.push(value);
    //   var serviceType = this.removeDuplicate(billingServiceType);
    //   this.setState({
    //     selectedBillingServiceType: value,
    //     showServiceTypeNewButton: true,
    //     billingServiceType: serviceType,
    //     types: ""
    //   })
    // } else if (key === "serviceAmount") {
    //   var { billingServiceAmount } = this.state;
    //   this.state.billingServiceAmount.push(value);
    //   var serviceAmount = this.removeDuplicate(billingServiceAmount);
    //   this.setState({
    //     selectedBillingServiceAmount: value,
    //     showServiceAmountNewButton: true,
    //     billingServiceAmount: serviceAmount,
    //     amounts: ""
    //   })
    // } else if (key === "serviceName") {
    //   var { billingServiceName } = this.state;
    //   this.state.billingServiceName.push(value);
    //   var serviceName = this.removeDuplicate(billingServiceName);
    //   this.setState({
    //     selectedBillingServiceName: value,
    //     showServiceAmountNewButton: true,
    //     billingServiceAmount: serviceName,
    //     service: ""
    //   })
    // }



    if (this.state.types) {
      var { billingServiceType } = this.state;

      billingServiceType.push({ "type": this.state.types, "amount": 0, "s_no": "" });
      var serviceType = this.removeDuplicate(billingServiceType);
      this.setState({
        selectedBillingServiceType: { "type": this.state.types, "amount": "", "s_no": "" },
        showServiceTypeNewButton: true,
        billingServiceType: serviceType,
        types: ""
      })
      // } else if(!this.state.showServiceTypeNewButton){
      //   this.setState({
      //     showServiceTypeNewButton: true,
      //   })
      // }else{
      //   this.setState({
      //     showServiceTypeNewButton: true,
      //   })
    }
    // else 
    if (this.state.amounts) {
      var { billingServiceAmount } = this.state;
      this.state.billingServiceAmount.push(this.state.amounts);
      var serviceAmount = this.removeDuplicate(billingServiceAmount);
      this.setState({
        selectedBillingServiceAmount: this.state.amounts,
        showServiceAmountNewButton: true,
        billingServiceAmount: serviceAmount,
        amounts: 0
      })
      // } else if(!this.state.showServiceAmountNewButton){
      //   this.setState({
      //     showServiceAmountNewButton: true,
      //   })
      // }else {
      //   this.setState({
      //     showServiceAmountNewButton: true,
      //   })
    }
    // else 
    if (this.state.service) {
      var { billingService } = this.state;
      billingService.push({ 'service_name': this.state.service, 'service_id': '', data: [] });
      var serviceName = this.removeDuplicate(billingService);
      this.setState({
        selectedBillingServiceName: this.state.service,
        selectedBillingServiceId: "",
        showServiceNameNewButton: true,
        billingService: serviceName,
        service: ""
      })
      // } else if(!this.state.showServiceNameNewButton){
      //   this.setState({
      //     showServiceNameNewButton: true,
      //   })
      // }else {
      //   this.setState({
      //     showServiceNameNewButton: true,
      //   })
    }



  }


  renderBillinTextBox(placeholder, width, center, key, value = '', index, border = '', icon) {

    return (
      <View>
        {placeholder ? <Text style={styles.BillTextInputPlaceholder}>{placeholder}</Text> : null}
        <View>
          {/* <Image source={img} style={{height:10,width:10}}/> */}

          <TextInput
            editable={
              key == "upiTransactionID" ||key == "bank_transaction_id" || key === "firstAmount" || key === "secondAmount" || key === "CardNumber" || key === "Cheque" ? true :
                key === "discountAmount" || ( key !== "serviceName" && key !== "serviceType" && key !== "serviceAmount") ? false : true}
            // multiline={true}
            // placeholder={key === "discountAmount" && value === 0 ? placeholder : key === "discouont_percent" && value === 0 ? placeholder : placeholder}
            underlineColorAndroid="transparent"
            maxLength={key === "CardNumber" ? 50 : key === "Cheque" ? 15 : null}
            style={[{
              zIndex: -1,
              color: "grey",
              borderRadius: border ? border : '0.65vw',
              borderColor: "grey",
              borderWidth: 1,
              width: width,
              height: (placeholder == "Card" || placeholder == "Cash") ? '2.28vw' : '1.95vw',
              paddingLeft: '0.65vw',
              alignSelf: center ? 'center' : null,
              marginLeft: placeholder == "Card" ? '0.97vw' : null,
              marginRight: placeholder == "Cash" ? '0.97vw' : null,
              backgroundColor:
                key === "discountAmount" || ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ?
                  "#F2F2F2" :
                  key === "serviceName" || key === "serviceType" || key === "serviceAmount" ?
                    color.white :
                    null,
              fontSize: '0.91vw'
            }, Style.allButtonBorderRadius]}
            placeholderTextColor={"grey"}
            autoCapitalize="none"
            value={
              key === "discountAmount" && value == 0 ?
                "" :
                key === "discouont_percent" && value == 0 ?
                  "" :
                  key === "Cash" && value == 0 ?
                    "" :
                    key === "Card" && value == 0 ?
                      "" : value}
            onChangeText={text => {

              if (key === "serviceName") {
                this.setState({
                  showListofServiceNameFlag: true
                })
              }

              this.onChangeText(text, index, key)
            }
            }
            onSubmitEditing={() => {
              // this.onSubmitEditing.bind(this, key, this.state.service)
              this.onSubmitEditing()
            }

            }
          // }}
          />
        </View>
        <View style={{ backgroundColor: "black", borderRadius: 4, position: 'absolute', zIndex: 1, marginTop: 38, maxHeight: 250, maxWidth: 250, minWidth: 200 }}>
          <ScrollView>
            {
              key === "serviceName" && this.state.showListofServiceNameFlag ?
                this.state.showListofServiceName.map((service, inde) => {
                  return (<TouchableOpacity
                    key={inde}
                    style={{ padding: 10 }}
                    onPress={() => {

                      var { billingService } = this.state;


                      this.state.favoriteServiceName.push(service);

                      billingService.push({ 'service_name': service.service_name, 'service_id': service.service_id, data: service.data })

                      var name = this.removeDuplicate(billingService);

                      this.setState({
                        selectedBillingServiceName: service.service_name,
                        selectedBillingServiceId: service.service_id,
                        billingService: name,

                        showListofServiceNameFlag: false,
                        showServiceNameNewButton: true,
                        service: "",
                        // selectedService:service
                      }),
                        this.selectBillingButton(service)

                    }}
                  ><Text style={{ color: color.white }}>{service.service_name}</Text></TouchableOpacity>)
                })
                : null
            }
          </ScrollView>
        </View>
      </View>
    );
  }

  renderTextBoxAlone(placeholder, flexWidth = 1, center, key, value = '', index, width = '100%', border = '') {

    return (
      <View style={{ flex: flexWidth }} >

        <TextInput
          editable={
            // key === "discountAmount" || 
            ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ? false : (placeholder === "Discount %" && this.state.disableDiscountPercent) ? false : (placeholder === "Discount Amount" && this.state.disableDiscountPercent) ? false :  true}
          underlineColorAndroid="transparent"
          style={[{
            zIndex: -1,
            color: "grey",
            borderRadius: border ? border : '0.65vw',
            borderColor: "grey",
            borderWidth: 1,
            width: width,
            height: (placeholder == "Card" || placeholder == "Cash") ? '2.28vw' : '1.95vw',
            paddingLeft: '0.65vw',
            alignSelf: center ? 'center' : null,
            marginLeft: placeholder == "Card" ? '0.97vw' : null,
            marginRight: placeholder == "Cash" ? '0.97vw' : null,
            backgroundColor:
              // key === "discountAmount" ||
              ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ?
                "#F2F2F2" :
                key === "serviceName" || key === "serviceType" || key === "serviceAmount" ?
                  color.white :
                  null,
            textAlign: "right",
            paddingRight: '0.65vw',
            fontSize: '0.91vw'
          }, Style.allButtonBorderRadius]}
          placeholderTextColor={"grey"}
          autoCapitalize="none"
          value={
            key === "discountAmount" && value == 0 ?
              "" :
              key === "discouont_percent" && value == 0 ?
                "" :
                key === "Cash" && value == 0 ?
                  "" :
                  key === "Card" && value == 0 ?
                    "" : value}
          onChangeText={text => {

            if (key === "serviceName") {
              this.setState({
                showListofServiceNameFlag: true
              })
            }

            this.onChangeText(text, index, key)
          }
          }
          onSubmitEditing={() => {
            this.onSubmitEditing()
          }

          }
        />
      </View>
    );
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }


  getListOfBillingTypes(searchKey) {
    if (searchKey) {
      var service_url = Constants.FO_DOCTOR_SERVICE + "?search_key=" + searchKey + "&doctor_id=" + this.state.selectedClinicOrDoctor.id;
      OpthamologyService.getInstance().documentUploadGet(
        service_url,
        this.getTypesOfBillingSuccess,
        this.getTypesOfBillingFailure
      );
    } else {
      this.setState({
        showListofServiceName: []
      })
    }
  }

  getTypesOfBillingSuccess = success => {
    // showListofServiceName
    if (success.data.success && success.data.success === "success");
    if (success.data.data) {
      this.setState({
        showListofServiceName: success.data.data
      })
    }
  }
  getTypesOfBillingFailure = error => {
    console.log("getTypesOfBillingSuccess ====================>error " + JSON.stringify(error));
  }

  numberValidationWithoutDot(num) {
    return /^[0-9]*$/.test(num);
}


  onChangeText = (value, index, key) => {

    if (key === "name" || key === "description" || key === "amount") {
      if (key === "name") {
        this.setState({
          showListofServiceNameIndex: index
        })
        this.getListOfBillingTypes(value)
      }
      let { Billing } = this.state;
      Billing[index][key] = value;

      this.setState({
        Billing: JSON.parse(JSON.stringify(Billing))
      },
        () => {
          if (key === "amount") {
            this.calculateTotalAmount();
          }
        }
      )


    } else if((key == "upiTransactionID" || key == "bank_transaction_id" || key == "Cheque" || key == "CardNumber")){
      var states = this.state;
      if(this.numberValidation(value) && this.numberValidationWithoutDot(value)){
      states[key] = value;
      this.setState({ states })
      } else if (value == "") {
        states[key] = value;
        this.setState({ states })
      }
    } else if (key === "discouont_percent") {
      var NewValue = value == "" ? 0 : value
      this.setState({
        discount_percent: parseInt(NewValue) <= 100 ? parseInt(NewValue) : this.state.discount_percent,
        is_disc_percent_changed: true,
        amount_cash: 0,
        amount_card: 0
      }, () => {
        this.calculateDiscountAmount()
      })
    } else if (key === "Cash") {


      if (parseFloat(this.state.amount_net) >= value) {

        var amount_Card = this.state.amount_net - value
        this.setState({
          amount_cash: value,
          amount_card: amount_Card,
        }, () => {
          // this.calculateDiscountAmount() 
        })
      }

    } else if (key === "Card") {

      // var amount_net = this.state.amount_net
      if (parseFloat(this.state.amount_net) >= value) {
        var amount_Cash = this.state.amount_net - value
        this.setState({
          amount_card: value,
          amount_cash: amount_Cash
        }, () => {
          // this.calculateDiscountAmount() 
        })
      }
    } else if (key === "serviceName") {
      this.setState({
        service: value
      });
      this.getListOfBillingTypes(value)
    } else if (key === "serviceType") {
      this.setState({
        types: value
      });
    } else if (key === "serviceAmount") {
      if (isNaN(value)) {
        // do nothing
      } else {
        this.setState({
          amounts: value
        });
      }

    }
    else if (key === "discountAmount") {
      var data = 0
      if (value) {
        data = ((value / this.state.totalAmount) * 100).toFixed(2);
      } else {
        data = 0;
      }
      this.setState({
        discount: value ? value <= this.state.totalAmount ? value : this.state.discount : 0,
        discount_percent: data,
        is_disc_percent_changed: false
      }, () => {
        this.calculateDiscountAmount()
      })
    } else if( key === "firstAmount"){
      if(this.state.amount_net >= value && (this.numberValidationWithoutDot(value)|| value == "")){
        var secondAmount = this.state.amount_net - value
        this.setState({
          firstAmount : value,
          secondAmount : secondAmount
        })
      }
    }else if(key === "secondAmount"){
      if(this.state.amount_net >= value && (this.numberValidationWithoutDot(value) || value == "")){
        var firstAmount = this.state.amount_net - value
        this.setState({
          secondAmount : value,
          firstAmount : firstAmount
        })
      }
    }
    else {
      var states = this.state;
      states[key] = value;
      this.setState({ states })
    }

  }


  renderHeaderView = () => {
    let { patientInfo, patientAppointment } = this.state;
    return (
      <View
        style={{
          // padding: 20,
          paddingRight: 20,
          paddingBottom: 20,
          paddingTop: 20,
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View>
            <Arrow
              name="arrow-left"
              size={20}
              color={color.black}
              onPress={() => {
                this.props.changeScreen("optDoctorNotes")
                // this.props.navigation.goBack()
              }
              }
            />
          </View>
          {/* <View style={{ marginLeft: 20 }}> */}
          <Text style={{ fontSize: 16, marginLeft: 10 }}>{patientInfo.name || ""}</Text>
          <Text
            style={{
              fontSize: 10,
              color: "#ADB4C1",
              marginTop: 5,
              marginLeft: 10,
              marginBottom: 5
            }}
          >
            {patientInfo.age || ""} | {patientInfo.dob || ""} | {patientInfo.mobile_number || ""} | {patientAppointment.appointment_start_time || ""}
          </Text>
          <Text style={{ marginLeft: 10, fontSize: 10, color: "#ADB4C1", marginTop: 5, }}>
            Last Visit Date : {patientInfo.last_visit_date || ""}
          </Text>
        </View>
        {/* </View> */}
        {/* {this.renderIconBtn("eye", "View Report", false)} */}
      </View>
    );
  };



  // BILLING_SCREEN_SAVE
  saveBillingDetails() {

    var service = []
    let { Billing } = this.state
    var message = ""
    var states = this.state

    for (var i = 0; i < Billing.length; i++) {
      if(Billing[i].amount == 0) {
        message = "Enter the service amount greater then 0"
      }

      var amount = parseFloat(Billing[i].amount).toFixed(2)
      var data2 = {
        "service_id": Billing[i].service_id,
        "name": Billing[i].name || Billing[i].service_name,
        "type": Billing[i].description || Billing[i].service_type,
        "amount": parseFloat(amount)
      }
      if (Billing[i].id) {
        data2["item_id"] = Billing[i].id
      }
      service.push(data2);
    }

    // var dt = moment(this.state.billingDate.YYYY-MM-DD).format("YYYY-MM-DD");
    // var dt = moment(this.state.billingDate, "DD-MM-YYYY");
    var dt1 = moment(this.state.billingDate).format("YYYY-MM-DD");
    let amountGross = parseFloat(this.state.totalAmount).toFixed(2)
    var transaction_num_1 = ""
    var transaction_num_2 = ""
    transaction_num_1 = states.firstPayment === "Card" ? states.CardNumber : states.firstPayment === "UPI" ? states.upiTransactionID : states.firstPayment === "Bank Transfer" ? states.bank_transaction_id : states.firstPayment === "Cheque" ? states.Cheque : ""
    transaction_num_2 = states.secondPayment === "Card" ? states.CardNumber : states.secondPayment === "UPI" ? states.upiTransactionID : states.secondPayment === "Bank Transfer" ? states.bank_transaction_id : states.secondPayment === "Cheque" ? states.Cheque : ""
    var PaymentTypeData = returnPaymentMode(this.state.firstPayment + this.state.secondPayment, this.state.firstAmount, this.state.secondAmount, transaction_num_1, transaction_num_2)
    var data = {
      "invoice":
      {
        "appointment_id": this.state.modificationBillDetails && Object.keys(this.state.modificationBillDetails).length > 0 ? this.state.modificationBillDetails.appointment_id : this.state.patientAppointment.appointment_id,
        "amount_gross": parseFloat(amountGross), //-- Total Amount
        "discount_percent": this.state.discount_percent ? (parseFloat(this.state.discount_percent) || 0) : 0,
        "discount": parseFloat(this.state.discount) || 0,
        "amount_invoice": this.state.amount_invoice ? this.state.amount_net : 0, // -- After Discount
        "amount_net": this.state.amount_net,// -- After Round Off
        "payment_type": this.state.isPaymentReceived || (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state?.userEditAccess && this.state.isBillModification) ? parseInt(PaymentTypeData.paymentMode) : null,
        "amount_1":parseFloat(PaymentTypeData.paymentAmt1),
        "amount_2" :parseFloat(PaymentTypeData.paymentAmt2),
        "transaction_id_1" : PaymentTypeData.trans_Num1,
        "transaction_id_2" : PaymentTypeData.trans_Num2,
        "round_off": (this.state.round_off.toFixed(2)),
        "invoice_date": dt1,
        // "invoice_date":this.state.billingDate,
        // "amount_cash": this.state.paymentType == "UPI" || this.state.paymentType == "Bank Transfer" ?
        //   0
        //   : this.state.amount_cash ? this.state.amount_cash : 0,
        // "amount_card": this.state.paymentType == "UPI" || this.state.paymentType == "Bank Transfer" ?
        //   0
        //   : this.state.amount_card ? this.state.amount_card : 0,
      },
      "services": service,
      "status": this.state.isPaymentReceived || (this.state.isAdmin && this.state.isBillModification) || (!this.state?.isAdmin && this.state.userEditAccess && this.state.isBillModification) ? "saved" : "partial",
      "bill_id": this.state.invoiceID ? this.state.invoiceID : "",
      "removable_ids": this.state.invoiceID ? this.state.removableSavedBillID : [],
      "invoice_number": this.state.invoiceNumber,
      "invoice_sequence" : this.state.invoiceSequence,
      "bill_type": this.state.selectedBillType,
      "employer_id": this.state.EmployerID,
      "referral_doctor": parseInt(this.state.refDoc_ID)


    }
    var states = this.state
    if ((states.firstPayment == "Card" || states.secondPayment == "Card") && (!states.CardNumber || (states.CardNumber).length == 0)) {
      this.props.showResposeValue('error', "Enter your Card Transaction ID");
    }else if((states.firstPayment == "Cheque" || states.secondPayment == "Cheque") && (!states.Cheque || (states.Cheque).length == 0 )){
      this.props.showResposeValue('error', "Enter your Cheque Number");
    } else if((this.state.firstPayment != "" && this.state.secondPayment == "") && (parseInt(this.state.amount_net).toFixed() != this.state.firstAmount)){
      this.props.showResposeValue("error", "Amount Field Should not be Empty")
    }
    else if((this.state.firstPayment != "" && this.state.secondPayment != "") && (parseInt(this.state.amount_net).toFixed() != (parseInt(this.state.firstAmount) + parseInt(this.state.secondAmount)))){
      this.props.showResposeValue("error", "Amount Not Matched")
    }else if (this.state.Billing.length > 0 && !this.state.firstPayment && ((this.state.isAdmin && this.state.isBillModification) || this.state.isPaymentReceived)) {
      this.showResposeValue("error", "Please select payment type")
    }else if(message != "") {
      this.showResposeValue("error", message)
    }
    else if (this.state.Billing.length > 0 ) {
      this.setState({ isDisabled: true })
      var service_url = Constants.BILLING_INVOICE;
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveBillingDetailsSuccess,
        this.saveBillingDetailsFailure
      );
    } else {
      this.showResposeValue("error", Messages.NoBills)
      this.setState({ isDisabled: false })
    }
  }
  saveBillingDetailsSuccess = response => {

    // "data":{"invoice_number":"SNRARA2000106"}
    if (response.status == "success") {
      if (!localStorage.getItem('isQuickBillingScreenSuccess')) {
        localStorage.setItem('isQuickBillingScreenSuccess', "success");
      }
      this.showResposeValue("success", response.message)
      if (response.data && response.data.invoice_id) {

        this.setState({
          invoice_id: response.data.invoice_id,
          enablePrint: true,
          isBillModification: false
        })
      }
      this.clearBillingDetails()
      this.setState({ isDisabled: false })
      if (this.state.isUpdate) {
        this.props.renderModifyClick(true)
      }
    } else {
      this.showResposeValue("error", response.message)
      this.setState({ isDisabled: false })
    }
    // this.getBillingList();
  }

  saveBillingDetailsFailure = error => {
    this.showResposeValue("error", error.message)
    this.setState({ isDisabled: false })
  }
  billModificationUpdate = () => {

  }
  printBillingDetails() {

    let { invoice_id, patientAppointment, userType } = this.state;
    let serviceUrl = "";
    let patientID = patientAppointment.patient_id ? patientAppointment.patient_id : this.state.modifyDataList.patient_id
    let invoiceID = invoice_id ? invoice_id : this.state.modifyDataList.id
    if (userType === Constants.ROLE_FO) {
      serviceUrl = Constants.FO_PRINT_BILL + "?invoice_id=" + invoiceID + "&patient_id=" + patientID;
    } else {
      serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + invoice_id + "&patient_id=" + patientAppointment.patient_id + "&clinic_id=" + this.state.selectedClinicOrDoctor.id;
    }

    if (serviceUrl) {

      // serviceUrl = serviceUrl + "?invoice_id=" + invoice_id + "&patient_id=" + patientAppointment.patient_id;

      OpthamologyService.getInstance().documentUploadGet(
        serviceUrl,
        // this,
        this.printBillSuccess,
        this.printBillFailure,
        "pdf"
      );
    }
  };

  printBillSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }
  printBillFailure = error => {
    //alert(JSON.stringify(error))
  }

  clearBillingDetails() {

    var states = this.state;

    states["firstPayment"] = "",
    states["secondPayment"] = "",
    states["firstAmount"] = "",
    states["secondAmount"] = "",
    states["Cheque"] = "",
    states["CardNumber"] = "",
    states["discount_percent"] = 0;
    states["discount"] = 0;
    states["round_off"] = 0;
    states["Billing"] = [];
    states["paymentType"] = ""
    states["upiTransactionID"] = ""
    states["bank_transaction_id"] = ""
    states["amount_cash"] = 0;
    states["amount_card"] = 0;
    states["amount_invoice"] = 0;
    states["invoiceNumber"] = ""
    states["invoiceSequence"] = ""
    states["invoiceID"] = ""
    states["billingDate"] = new Date()
    states["selectedBillType"] = ""
    states["SelectedCorporateName"] = ""
    states["SelectedEmployeeID"] = ""
    states["EmployerID"] = ""
    states["CorporateErrorText"] = ""
    states["disableDiscountPercent"] = false
    states["refDoctor"] = ""
    this.setState({
      // Billing: [],
      states
      // totalAmount: 0,    //amount_gross
      // paymentType: "Card",
      // discount_percent: "",
      // discount: "",
      // amount_invoice: 0, //After Discount
      // amount_net: 0,     //After Round Off
      // amount_cash: "",
      // amount_card: "",
      // round_off: 0,
      // invoice_date: "",

    }, () => {
      this.calculateTotalAmount()
    })
  }

  clearBillDeleteDetails() {

    var states = this.state;

    states["discount_percent"] = 0;
    states["discount"] = 0;
    states["round_off"] = 0;
    states["Billing"] = [];
    states["paymentType"] = ""
    states["firstPayment"] = ""
    states["secondPayment"] = ""
    states["firstAmount"] = ""
    states["secondAmount"] = ""
    states["upiTransactionID"] = ""
    states["bank_transaction_id"] = ""
    states["Cheque"] = ""
    states["CardNumber"] = ""
    states["amount_cash"] = 0;
    states["amount_card"] = 0;
    states["amount_invoice"] = 0;
    this.setState({
      states
    }, () => {
      this.calculateTotalAmount()
      if(this.state.selectedBillType == "Corporate"){
        this.getPatientCorporateDetails();
      }
    })
  }

  paymentType(type) {
    if (this.state.paymentType == type) {
      this.setState({
        paymentType: ""
      })
    } else if (type === "Card") {
      this.setState({
        paymentType: "Card",
      }, () => {
        this.calculateDiscountAmount()
        // this.onChangePaymentType() 
      })
    } else if (type === "Cash") {

      this.setState({
        paymentType: "Cash",
      }, () => {
        this.calculateDiscountAmount()
        // this.onChangePaymentType() 
      })
    } else if (type === "UPI") {
      this.setState({
        paymentType: "UPI"
      })
    } else if (type === "Bank Transfer") {
      this.setState({
        paymentType: "Bank Transfer"
      })
    } else if (type === "Cheque") {
      this.setState({
        paymentType: "Cheque"
      })
    }
  }

  // onChangePaymentType() {
  //   // alert("payment type"+this.state.paymentType);

  //   let states = this.state;
  //   // let statesObj = JSON.parse(JSON.stringify(states));

  //   if (this.state.paymentType == "Card" ||
  //     this.state.paymentType === "UPI" || this.state.paymentType === "Bank Transfer") {
  //     var amount_net = this.state.amount_net > 0 ? this.state.amount_net : 0;

  //     this.setState({
  //       cardTextBoxEnable: true,
  //       cashTextBoxEnable: true,
  //       amount_card: amount_net,
  //       amount_cash: 0
  //     }, () => {
  //       //  this.calculateDiscountAmount()
  //     })
  //   } else if (this.state.paymentType == "Cash" || this.state.paymentType == "Cash and Card") {
  //     // alert("net amount"+this.state.amount_net);
  //     var amount_net = parseFloat(this.state.amount_net) > 0 ? this.state.amount_net : 0;

  //     // statesObj['cardTextBoxEnable'] = true;
  //     // statesObj['cashTextBoxEnable'] = true;
  //     // statesObj['amount_card'] = 0;
  //     // statesObj['amount_cash'] = amount_net;

  //     this.setState({
  //       // statesObj
  //       cardTextBoxEnable: true,
  //       cashTextBoxEnable: true,
  //       amount_cash: this.state.amount_cash != 0 ? this.state.amount_cash : amount_net,
  //       amount_card: this.state.amount_card != 0 ? this.state.amount_card : 0
  //     }, () => {
  //       // this.calculateDiscountAmount() 
  //     })
  //   } else if (this.state.paymentType == "Cash and Card") {

  //     this.setState({
  //       cardTextBoxEnable: false,
  //       cashTextBoxEnable: false,

  //       // amount_card: "",
  //       // amount_cash: "",
  //     }, () => {
  //       // this.calculateDiscountAmount() 
  //     })
  //   }
  // }

  calculateDiscountAmount = () => {

    let { is_disc_percent_changed, discount, discount_percent } = this.state;

    if ((this.state.discount_percent > 0 || this.state.discount_percent == "") && this.state.totalAmount > 0) {
      var discount_per = this.state.discount_percent == "" ? 0 : this.state.discount_percent

      var amount = parseFloat(discount);

      if (is_disc_percent_changed) {
        let temp_discount_percent = parseFloat(discount_per)

        amount = ((this.state.totalAmount / 100) * temp_discount_percent).toFixed(2);
        amount = parseFloat(amount)
      } else {
        discount_percent = ((amount / this.state.totalAmount) * 100).toFixed(2);
      }

      // this.state.discount = amount.toFixed(2)
      this.setState({
        // discount: amount.toFixed(2)
        discount: amount,
        discount_percent: discount_percent
      }, () => {


        var amount_invoice = this.state.totalAmount - amount;
        var round_off = (amount_invoice % 1).toFixed(2);
        var round = amount_invoice / 1;

        var paymentType = paymentReturnType(this.state.paymentType)

        if ((paymentType.paymentType1 === "Cash" || paymentType.paymentType2 === "Cash") || (paymentType.paymentType1 === "Card" || paymentType.paymentType2 === "Card") || (paymentType.paymentType1 === "Cheque" || paymentType.paymentType2 === "Cheque") ||
          (paymentType.paymentType1 === "UPI" || paymentType.paymentType2 === " UPI") || (paymentType.paymentType1 === "Bank Transfer" || paymentType.paymentType2 === "Bank Transfer") || (paymentType.paymentType1 === "" || paymentType.paymentType2 === "")) {
          var amount_invoice = this.state.totalAmount - amount;
          var round_off = (amount_invoice % 1).toFixed(2);
          var round = amount_invoice / 1;
          var amount_invoice;
          if (parseFloat(round_off) >= 0.5) {
            amount_invoice = parseInt(round) + 1;
            round_off = "+" + (1 - parseFloat(round_off))
          } else if (parseFloat(round_off) < 0.5) {
            amount_invoice = parseInt(round);
            round_off = "-" + (round_off)
          }
          this.setState({
            round_off: parseFloat(round_off),
            amount_net: amount_invoice,
            amount_invoice: amount_invoice
            // amount_net :this.state.totalAmount

          })
        }

        // this.state.amount_invoice = amount_invoice

        this.setState({
          amount_invoice: amount_invoice,
          firstAmount : amount_invoice,
          secondAmount : ""
          // amount_net :this.state.totalAmount
        })
      })
    } else {

      // alert("dis amount is 0---- total amount is " +this.state.totalAmount)
      // this.state.discount = 0,
      this.setState({
        round_off: 0,
        discount: 0,
        amount_net: this.state.totalAmount
      })
    }

  }

  calculateTotalAmount() {
    var totalAmount = this.state.Billing.reduce((sum, tax) => sum + (parseFloat(tax.amount) || 0), 0)

    this.setState({ totalAmount: totalAmount, amount_invoice : totalAmount, firstAmount : totalAmount, discount_percent : 0, secondAmount : "" }, () => {
      this.calculateDiscountAmount()
      // this.onChangePaymentType()
    });

  }



  showResposeValue(type, meaasge) {
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }


  successAlert() {
    return (
      <View style={{ flex: 1, zIndex: 3, width: "40%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
          <Success size={25} name={"check"} color={"green"} />
          <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
            <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icon size={25} name={"close"} color={"green"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {
    return (
      <View style={{ flex: 1, zIndex: 3, width: "55%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, minHeight: 70, marginRight: 10, marginTop: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
          <Icon size={25} name={"ban"} color={"#fff"} />
          <Text style={{ fontWeight: "700", color: "#fff", fontSize: 12, marginLeft: 15 }}>{"ERROR: "}
            <Text style={{ fontWeight: "400", color: "#fff", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icon size={25} name={"close"} color={"#FFF"} />
        </TouchableOpacity>
      </View>
    );
  }





}
const styles = StyleSheet.create({
  rightView: {
    padding: 10,
    width: "31.5%",
    height: screenHeight,
    backgroundColor: color.themeShade,
  },
  rightViewTimer: {
    fontSize: 15,
    color: "#8596A8",
    textAlign: 'center'
  },
  rightViewHeader: {
    color: "white",
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 21
  },
  // calender styles
  calendarView: {
    borderColor: "#525252",
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 150,
    marginRight: 10,
    // justifyContent:'center' // to cente the text 
  },
  calendarText: {
    color: color.white,
    //  width: "90%" 
  },
  calendarButton: {
    flexDirection: "row",
    marginBottom: 20,
    marginTop: 20,
    alignItems: "center",
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
  },
  leftView: {
    width: platform === "web" ? "62.5%" : "58.5%",
    height: screenHeight,
    backgroundColor: "#ffffff",
    // flexDirection: "row",
    paddingLeft: 30,
    paddingRight: 30
  },


  headingBackGround: {
    backgroundColor: color.appointmentTableHead,
    paddingHorizontal: '1.62vw',
    paddingVertical: '0.65vw',
    borderRadius: '0.4vw'
  },
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: color.applicationBackgroundColor
  },
  MaivView2: {
    width: "65%",
    height: screenHeight - 70,
    backgroundColor: color.white
  },
  ScrollViewLeft: {
    width: "100%",
    height: screenHeight - 70,
    backgroundColor: color.white,
    zIndex: -1,
    paddingHorizontal: '0.97vw',
    paddingVertical: '0.65vw'
  },
  billingTextLeft: {
    fontSize: '1.3vw',
    fontWeight: '500',
    color: color.themeDark
  },
  patientAccNo: {
    fontSize: '0.84vw',
    color: color.themeDark
  },
  ClearButtonLeft: {
    paddingHorizontal: '1.17vw',
    paddingVertical: '0.52vw',
    backgroundColor: color.themeDark,
    justifyContent: "center",
    borderRadius: ".25vw",
    color: color.white,
    fontSize: '0.85vw'
  },
  LabBillingButton: {
    marginLeft: '1.3vw',
    paddingHorizontal: '1.17vw',
    paddingVertical: '0.52vw',
    backgroundColor: color.themeDark,
    justifyContent: "center",
    borderRadius: ".25vw",
    color: color.white,
    fontSize: '0.85vw'
  },
  TableHeadWrapper: {
    marginTop: '0.97vw'
  },
  TableHeaderText: {
    fontSize: '1vw'
  },
  TableDataWrapper: {
    minHeight: '9.76vw'
  },
  TableDataView: {
    paddingHorizontal: '1.62vw',
    flexDirection: 'row',
    marginBottom: '1.3vw',
    zIndex: -2,
    borderBottomColor: color.textBoxBorderColor,
    borderBottomWidth: 1,
    paddingBottom: '0.97vw'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableDataInput: {
    fontSize: '0.91vw'
  },
  TableIcon: {
    flex: 0.17,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignSelf: 'center'
  },
  IconView: {
    width: '1.95vw',
    height: '1.95vw',
    borderRadius: '1.3vw',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    // shadowOffset: {
    //   height: 2,
    //   width: 2
    // },
    elevation: 4,
    backgroundColor: "#FCFEFF",
    alignItems: "center",
    justifyContent: "center",
  },
  TableNoData: {
    height: '9.76vw',
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  TableNoDataText: {
    color: color.notesTableTitle,
    fontSize: '0.91vw'
  },
  PaymentTypeWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "flex-end",
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingBottom: '0.97vw'
  },
  paymentview: {
    marginBottom: '1.3vw',
    zIndex: -1
  },
  PaymentTypeHeading: {
    fontSize: '0.91vw'
  },
  PaymentView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '1.95vw',
    zIndex: -1
  },
  PaymentButtonView: {
    alignItems: "center",
    justifyContent: "center"
  },
  PaymentButtonText: {
    marginTop: '0.32vw',
    fontSize: '0.84vw',
    marginBottom: '0.52vw'
  },
  paymentIconImg: {
    width: '1.95vw',
    height: '1.95vw'
  },
  PaymentRightView: {
    flex: 0.45,
    flexDirection: "column"
  },
  AmmountText: {
    color: color.notesTableTitle,
    fontSize: '0.91vw'
  },
  AmountData: {
    marginLeft: '0.52vw',
    fontSize: '1.17vw',
    textAlign: 'right'
  },
  AmountView: {
    flexDirection: 'row',
    marginTop: '0.97vw'
  },
  BillTextInputPlaceholder: {
    zIndex: 100,
    marginBottom: '0.65vw',
    fontSize: '0.78vw',
    paddingLeft: '0.32vw',
    paddingRight: '0.32vw',
    color: "#888888"
  },
  PaymentInputBoxView: {
    flexDirection: 'row',
    paddingTop: '0.97vw'
  },
  PaymentInputView: {
    flexDirection: 'row',
    flex: 0.55,
    justifyContent: "flex-end"
  },
  PaymentInputLabel: {
    position: 'absolute',
    marginLeft: '0.52vw',
    backgroundColor: "white",
    fontSize: '0.78vw',
    paddingLeft: '0.32vw',
    paddingRight: '0.32vw',
    color: "#888888"
  },
  InputBoxView: {
    marginTop: '0.45vw',
    zIndex: -1
  },
  InputMainView: {
    width: '4.88vw',
  },
  NetAmtWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 0.45,
    marginTop: '0.97vw',
    zIndex: -1
  },
  NetAmtText: {
    fontSize: '1vw',
    color: color.notesTableTitle,
    flex: 0.7
  },
  NetAmtData: {
    fontSize: '1.17vw',
    textAlign: 'right',
    flex: 0.3
  },
  ButtonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '1.95vw'
  },
  ButtonView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: '1.17vw',
    paddingVertical: '0.52vw',
    borderRadius: ".25vw",
    marginRight: '0.65vw',
  },
  ButtonText: {
    color: color.white,
    textAlign: 'center',
    fontSize: "0.85vw"
  },


  ScrollViewRight: {
    width: "35%",
    height: screenHeight - 70,
    backgroundColor: color.themeShade,
  },
  RightTopView: {
    flexDirection: 'row',
    marginTop: '0.65vw',
    marginBottom: '1.3vw'
  },
  titleView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '1.3vw',
    zIndex: -1,
    marginBottom: '0.65vw',
  },
  titleText: {
    color: color.themeDark,
    fontSize: '1.3vw'
  },
  RightClearButtonView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center",
    width: "40%"
  },
  RightClearButton: {
    maxWidth: '6.18vw',
    backgroundColor: color.themeDark,
    borderRadius: '0.26vw',
    paddingHorizontal: '0.65vw',
    paddingVertical: '0.32vw'
  },
  RightClearButtonText: {
    fontSize: '0.97vw',
    color: 'white'
  },
  subtitleView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '0.65vw',
    zIndex: -1
  },
  subTitleText: {
    color: "black",
    fontSize: '1vw',
    fontWeight: '500'
  },
  ServiceButtons: {
    marginBottom: '0.97vw',
    borderWidth: 1,
    paddingLeft: '1.17vw',
    paddingRight: '1.17vw',
    paddingTop: '0.52vw',
    paddingBottom: '0.52vw',
    marginLeft: '0.65vw',
    // borderRadius: 30,
  },
  ServiceNameData: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  ServiceButtonsText: {
    fontSize: "0.72vw"
  },
  ServiceTypeHeadView: {
    marginTop: '0.65vw'
  },
  ServiceTypeNameText: {
    zIndex: 2,
    fontSize: '0.8vw',
    position: "absolute",
    marginLeft: '0.65vw',
    backgroundColor: color.themeShade,
    paddingLeft: '0.32vw',
    paddingRight: '0.32vw',
    color: "#888888"
  },
  ServiceTypeInput: {
    borderColor: "#CDD1D5",
    borderRadius: '0.26vw',
    borderWidth: 1,
    width: "100%",
    height: '2.28vw',
    padding: '0.97vw',
    marginTop: '0.52vw',
    fontSize: '1vw'
  },
  RightAddbuttonView: {
    zIndex: -1,
    marginTop: '1vw',
    marginBottom: '0.97vw'
  },
  RightAddButton: {
    backgroundColor: color.themeDark,
    width: '9.76vw',
    alignSelf: 'center',
    alignContent: 'center',
    paddingVertical: '0.32vw'
  },
  RightAddButtonText: {
    fontSize: '1.3vw',
    textAlign: "center",
    color: color.white,
    fontWeight: "500"
  },
  ServiceTypeSuggestMainView: {
    zIndex: -1,
    position: 'absolute',
    marginTop: '3.25vw',
    marginLeft: '0.65vw',
    maxHeight: '16.27vw',
    minWidth: '19.53vw',
    maxWidth: '22.78vw'
  },
  ServiceTypeSuggestScrollView: {
    height: '12.69vw',
    marginBottom: '0.65vw',
    flex: 1,
    backgroundColor: 'white',
    borderColor: color.lightGray,
    borderWidth: 1,
    borderRadius: '0.26vw'
  },
  ServiceTypeSuggestView: {
    height: '1.95vw'
  },
  ServiceTypeSuggestButton: {
    marginLeft: '0.32vw',
    marginTop: '0.32vw'
  },
  ServiceTypeSuggestText: {
    fontSize: '0.91vw'
  },
  emed_IPbill_sideheader: {
    fontWeight: "500", fontSize: '0.9vw', marginVertical: '0.65vw'
  },
  emed_content_container: {
    flexDirection: "row", marginVertical: '0.97vw'
  },
  emed_radio_btn_touchablearea: {
    marginRight: '1.3vw', flexDirection: "row", alignItems: "center"
  },
  emed_radio_btn: {
    marginRight: '0.52vw', height: '1vw', width: '1vw', borderRadius: '3.25vw', borderWidth: 1, borderColor: "#91939F"
  },
  emed_radio_btn_txt: {
    fontWeight: "500", fontSize: '0.85vw'
  },
  refDoctorText: { 
    zIndex: 2, 
    fontSize: '0.8vw', 
    position: "absolute", 
    marginLeft: '0.8vw', 
    backgroundColor: color.white, 
    paddingLeft: '0.3', 
    paddingRight: '0.3', 
    color: color.black,
    marginTop: '-0.5vw'
    },
    refDoctorTextInput: { 
      fontSize: '1vw', 
      borderColor: color.boxColor, 
      borderWidth: 1, 
      paddingHorizontal:'1vw',
      borderRadius: 4, 
      height: '4.5vh'
    },
    RefDoctorView: {
      marginRight: '1.3vw'
    },
    DocListWrapper:{
      borderRadius: '0.65vw',
      width: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      padding: '0.65vw',
      marginTop: '1.9vw',
      position: 'absolute',
      backgroundColor: color.themeShade,
      color: color.red
  },
  DocsuggList:{
    flexDirection: "row",
    bottom: '0.65vw',
    borderBottomColor: color.lightGray,
    marginVertical: '0.32vw',
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
    zIndex: '0.65vw',
},
  SearchBoxView:{
        margin: '0.5vw', marginLeft: '0.7vw' , display:'flex',flexDirection:'row',
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.textBoxBorderColor,
        borderRadius: '0.26vw',
        width: "100%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },



});
