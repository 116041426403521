//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { OBGYNHistorySectionHeading ,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component

const pageName = "isMenstrualHistory"

export default class MenstrualHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            checkPeriodRegular: {},
            checkBleedingOrSpotting: {},

            periodsStartsEvery: "",
            daysOfperiodsLast: "",
            isPainAssociated: {},
            anyCurrentTreatment: {},

            currentTreatmentCommentBox: "",
            bleedingAndSpottingCommentBox: "",

            getMenstrualHistoryList: {}
        }
    }

    renderContent(heading, value, secondValue = "", addDaysText = false) {
        // console.log(">>>>>>>>>"+secondValue)
        return (
            <View>
                <HistorySectionRightSideUI heading={heading} value={value} details={secondValue}  addDaysText={addDaysText} />
                {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}{secondValue ? "," : ""} {secondValue ? secondValue : ""} {addDaysText ? "Days" : ""}</Text> */}
            </View>
        );
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getMenstrualHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount() {
        this.getMenstrualHistory()
    }
    getMenstrualHistory() {
        var service_url = Constants.MENSTRUAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMenstrualHistorySuccess,
            this.getMenstrualHistoryFailure
        );
    }

    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }

    getMenstrualHistorySuccess = success => {
        var notApplicable = ""
        // alert("*************** success  +++> "+JSON.stringify(success))
        var getMenstrualHistory = success.data.menstrual_history;
        var states = this.state;
        if (Object.keys(getMenstrualHistory).length > 0) {
            states["checkPeriodRegular"] = this.checResponceIsFlag(getMenstrualHistory.is_period_regular);

            states["checkBleedingOrSpotting"] = this.checResponceIsFlag(getMenstrualHistory.is_bleeding_btw_periods);
            states["anyCurrentTreatment"] = this.checResponceIsFlag(getMenstrualHistory.on_current_treatment);

            states["isPainAssociated"] = getMenstrualHistory.pain_with_periods == 'yes' ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" };


            states["periodsStartsEvery"] = getMenstrualHistory.period_start_duration;
            states["daysOfperiodsLast"] = getMenstrualHistory.period_last_duration;

            states["currentTreatmentCommentBox"] = getMenstrualHistory.current_treatment_desc;
            states["bleedingAndSpottingCommentBox"] = getMenstrualHistory.bleeding_btw_periods_desc;
            states["getMenstrualHistoryList"] = getMenstrualHistory

        } else {

            states["checkPeriodRegular"] = notApplicable
            states["checkBleedingOrSpotting"] = notApplicable
            states["anyCurrentTreatment"] = notApplicable
            states["isPainAssociated"] = notApplicable

            states["periodsStartsEvery"] = notApplicable
            states["daysOfperiodsLast"] = notApplicable

            states["currentTreatmentCommentBox"] = notApplicable
            states["bleedingAndSpottingCommentBox"] = notApplicable
            states["getMenstrualHistoryList"] = getMenstrualHistory
        }


        this.setState({ states })


    }
    getMenstrualHistoryFailure = error => { }

    renderHistorySection() {
        var states = this.state;
       
        return (
            <View style={{marginTop:10}}>
                <View style={{ flexDirection: 'row',marginBottom:10}}>
                    <View style={{ flex: 0.32 }}>
                        {this.renderContent("Are your periods regular?", states.checkPeriodRegular.value, states.periodsStartsEvery, states.periodsStartsEvery ? true : false)}
                    </View>
                    <View style={{ flex: 0.33 }}>
                        {this.renderContent("How many days does your periods last?", states.daysOfperiodsLast, "", states.daysOfperiodsLast ? true : false)}
                    </View>
                    <View style={{ flex: 0.35 }}>
                        {this.renderContent("Does bleeding / spotting occur between periods?", states.checkBleedingOrSpotting.value, states.bleedingAndSpottingCommentBox)}
                    </View>
                </View>
                <View style={{ flexDirection: 'row'}}>
                    <View style={{ flex: 0.32 }}>
                        {this.renderContent("Is the pain associated with periods?", states.isPainAssociated.value)}
                    </View>
                    <View style={{ flex: 0.33 }}>
                        {this.renderContent("Are you on any current treatment?", states.anyCurrentTreatment.value, states.currentTreatmentCommentBox)}
                    </View>
                    <View style={{ flex: 0.35 }}>
                        {/* {this.renderContent("Dose bleeding / spotting occur between periods?", "Yes Sometimes")} */}
                    </View>
                </View>
            </View>
        )
    }



    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // this.props.editHistory(this.state.getMenstrualHistoryList,pageName)
    }



    editHistory() {
        this.props.editHistory(this.state.getMenstrualHistoryList, pageName)
    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Menstrual History"}
                    editImage={this.state.checkPeriodRegular ? "pencil" : ""}
                // deleteImage={"trash"} 
                />
                {this.renderHistorySection()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // marginTop: 20
        // flex: 1,

    },
});
