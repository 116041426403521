import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;


export const SubHeading = (props) => {
    return (
        <Text style={[styles.BloodSubHeadText, { color: props.showBold ? "#000000" : "#707070"}]}>{props.subHeading}</Text>
    )
}

export const Heading = (props) => {
    return (
        <Text style={styles.BloodHeadText}>{props.heading}</Text>
    )
}

export const LabelWithTextBox = (props) => {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
            {
                props.label ?
                    <Text style={styles.BloodLabelText}>{props.label}</Text>
                    : null
            }

            <TextInput
               onChangeText={(text)=>{
                props.onChangeBloodTextValue(props.stateName,text);
            }}
                value={props.textBoxValue}
                style={styles.BloodLabelTextBox}
            />
        </View>
    )
}

export const TextBoxonly = (props) => {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
            

            <TextInput
               onChangeText={(text)=>{
                props.onChangeBloodTextValue(props.stateName,text);
            }}
                value={props.textBoxValue}
                style={styles.TextBoxonly}
            />
        </View>
    )
}

export const LabelBoldWithTextBox = (props) => {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
            <Text style={styles.BloodLabelBoldWithText}>{props.label}</Text>
            <TextInput
                onChangeText={(text)=>{
                    props.onChangeBloodTextValue(props.stateName,text);
                }}
                value={props.textBoxValue}
                style={styles.BloodLabelTextBox}
            />
        </View>
    )
}



export const ParagraphContent = (props) => {
    return (
        <Text style={styles.ParagraphContent}>{props.content}</Text>
    )
}

export const ParagraphContentWithHighlight = (props) => {
    const highlight = string =>
        string.split(' ').map((word, i) => (
            <Text key={i}>
                <Text style={styles.BloodLabelBoldWithText}>{word} </Text>
            </Text>
        ));

    return (
        <Text style={styles.ParagraphContent}>
            {props.content1}
            {highlight(props.highlight1)}
            {props.content2}
            {highlight(props.highlight2)}
            {props.content3}
            {highlight(props.highlight3)}
            {props.content4}
        </Text>
    )
}


export const ParagraphContentWithTextBox = (props) => {
    const TextInput = string =>
        string.split(' ').map((word, i) => (
            <TextInput
            onChangeText={(text)=>{
                props.onChangeBloodTextValue(props.stateName,text);
            }}
            value={props.textBoxValue}
            style={styles.BloodLabelTextBox}
        />
        ));

    return (
        <Text style={styles.ParagraphContent}>
            {props.content1}
            {highlight(props.highlight1)}
            {props.content2}
            {highlight(props.highlight2)}
            {props.content3}
            {highlight(props.highlight3)}
            {props.content4}
        </Text>
    )
}





export const BloodTransfusion = (props) => {
   
    const data=props.bloodTransfusionFormDatas
   // console.log("clinicdetails --------------> "+JSON.stringify(props.clinicdetails))
    // console.log("bloodTransfusionFormDatas --------------> "+JSON.stringify(props.bloodTransfusionFormDatas.relationshipWithPatient))
    
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{ maxHeight: '70vh' }}
        >
            <View style={{ paddingLeft: '1vw', paddingRight: '1vw' }}>


                <View style={styles.witnessesView}>
                    <LabelWithTextBox label={"Hopital Name:"} textBoxValue={props.clinicdetails.clinic_name} />
                    <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                    <LabelWithTextBox label={"Name:"} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={props.selectedValueClinic_Droctor.value} stateName={"drName"} />
                    <LabelWithTextBox label={"Qualification:"} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={data.drQualification} stateName={"drQualification"}/>
                    <LabelWithTextBox label={"Address:"} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={data.drAddress} stateName={"drAddress"}/>
                    <LabelWithTextBox label={"Phone:"} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={data.drPhone} stateName={"drPhone"}/>
                    <LabelWithTextBox label={"Email:"} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={data.drEmail} stateName={"drEmail"}/>

                    <Heading heading={"Consent For Blood Transfusion"} />


                    <SubHeading subHeading={"Information about the patient:"} />

                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={props.clinicdetails.patient_name} />
                    <LabelWithTextBox label={"Age:"} textBoxValue={props.clinicdetails.patient_age} />
                    <LabelWithTextBox label={"Address:"} textBoxValue={props.clinicdetails.patient_address} />

                    <SubHeading subHeading={"Information about the patient’s guardian (proxy consent) :"} />

                    <ParagraphContent content={"(This clause should be filled and the guardian should sign this consent only in case of incompetent patients i.e. minors, old aged, unconscious, mentally unfit, disoriented patients)"} />
                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={data.guardianName}  stateName={"guardianName"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>
                    <LabelWithTextBox label={"Address:"} textBoxValue={data.guardianAge}  stateName={"guardianAge"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>
                    <LabelWithTextBox label={"Phone:"}  textBoxValue={data.guardianPhoneNo}  stateName={"guardianPhoneNo"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                    <LabelWithTextBox label={"Email:"}  textBoxValue={data.guardianEmail}  stateName={"guardianEmail"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                    <LabelWithTextBox label={"Relationship with the patient, if any:"}  textBoxValue={data.relationshipWithPatient}  stateName={"relationshipWithPatient"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>


                    <ParagraphContentWithHighlight
                        content1={"[A person accompanying an unrelated patient should write "}
                        content2={" and when consent is given by higher authorities of a hospital, designation such as "}
                        content3={" or "}
                        content4={" must be written.]"}
                        highlight1={"‘Unrelated-accompanying’"}
                        highlight2={"‘Medical Superintendent’"}
                        highlight3={"‘Medical Director’"}
                    />

                    <SubHeading subHeading={"Scheduled date for the proposed intervention / procedure / surgery:"} />
                    <LabelWithTextBox label={""} onChangeBloodTextValue={props.onChangeBloodTextValue} textBoxValue={data.scheduledSurgeryDate} stateName={"scheduledSurgeryDate"}/>

                    <View style={styles.SignView}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.witnessesView}>
                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                <LabelWithTextBox label={"Name:"} textBoxValue={data.drInChargeName}  stateName={"drInChargeName"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>
                <LabelWithTextBox label={"Qualification:"} textBoxValue={data.drInChargeQualification}  stateName={"drInChargeQualification"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>

                <SubHeading subHeading={"Name/s of the proposed treatment / intervention / procedure / surgery:"} />
                <LabelWithTextBox label={"a. "} textBoxValue={data.nameOfTheProposedTreatmentA}  stateName={"nameOfTheProposedTreatmentA"} onChangeBloodTextValue={props.onChangeBloodTextValue}/>
                <LabelWithTextBox label={"b. "} textBoxValue={data.nameOfTheProposedTreatmentB}  stateName={"nameOfTheProposedTreatmentB"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                <LabelWithTextBox label={"c. "} textBoxValue={data.nameOfTheProposedTreatmentC}  stateName={"nameOfTheProposedTreatmentC"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                <LabelWithTextBox label={"d. "} textBoxValue={data.nameOfTheProposedTreatmentD}  stateName={"nameOfTheProposedTreatmentD"} onChangeBloodTextValue={props.onChangeBloodTextValue} />


                <SubHeading subHeading={"I, the undersigned, do hereby state and confirm as follows:"} />
                <ParagraphContent content={""} />

                <Text style={{flexDirection:'row',lineHeight: '0.5vw', color: "#707070", fontSize: '0.8vw', paddingVertical: 5}}>
                    
                <Text>1.I have been explained the following in terms and language that I understand. I have been explained the following in
                <TextBoxonly label={""} textBoxValue={data.language}  stateName={"language"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
               (name of the language or dialect) that is spoken and understood by me.</Text></Text>
                <ParagraphContent content={"2.I have been explained; I have been provided with the requisite information; I have understood; and thereafter I consent, authorize and direct the above named doctor-in-charge / principal surgeon / principal interventionist and his / her team with associates or assistants of his / her choice to perform the proposed treatment / intervention / procedure / surgery mentioned hereinabove."} />
                <ParagraphContent content={"3.I have been explained and have understood that transfusion of blood / blood components has certain material risks / complications which include acquiring Hepatitis, HIV, Syphilis and malarial parasites and I have been provided with the requisite information about the same. I have also been explained and have understood that there are other undefined, unanticipated, unexplainable risks / complications that may occur during or after transfusion of blood / blood components."} />
                <ParagraphContent content={"4.I have been explained and have understood that transfusion of blood / blood components always has the possibility of reaction even after proper cross matching and checking compatibility."} />
                <ParagraphContent content={"5.I state that the doctor-in-charge / principal surgeon / principal interventionist has answered all my questions to my satisfaction regarding transfusion of blood / blood components."} />
                <ParagraphContent content={"6.I have signed this consent voluntarily out of my free will and without any kind of pressure or coercion."} />

                {/* <View style={{ alignItems: 'flex-end', marginTop: 55, marginRight: 20 }}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound/ unconscious"} />
                </View>

               

                */}<br/><br/>
            

                    <LabelBoldWithTextBox label={"Date & Time of giving consent:"}  textBoxValue={data.dateAndTimeGivenConsent}  stateName={"dateAndTimeGivenConsent"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />
                    <SubHeading subHeading={"Patient’s / Guardian’s Signature / Thumb"} />
                    <LabelBoldWithTextBox label={"impression:"}  textBoxValue={data.impression}  stateName={"impression"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />
                    <LabelBoldWithTextBox label={"Patient’s / Guardian’s Name:"}  textBoxValue={data.patientOrGuardianName}  stateName={"patientOrGuardianName"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />

                    <View style={styles.SignView}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"}  textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.witnessesView}>

                <SubHeading subHeading={"Witnesses:"} showBold={true} />
                <ParagraphContent content={"(Not compulsory. This part should be filled only in high risk cases; or when the patient / patient’s guardian is illiterate or not conversant with English; or when the patient has been unable to personally sign this consent for any reason.)"} />
                <ParagraphContent content={"We confirm that the aforesaid has been explained to the patient / patient’s guardian in the terms and language that the patient / patient’s guardian understand in our presence. We further confirm that the patient / patient’s guardian has put his / her signature / thumb impression on this consent in our presence."} />


                <LabelWithTextBox label={"Witnesses No. 1’s Signature:"} textBoxValue={data.witnessName1}  stateName={"witnessName1"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 1’s Name:"} textBoxValue={data.witnessSignature1}  stateName={"witnessSignature1"} onChangeBloodTextValue={props.onChangeBloodTextValue} />


                <LabelWithTextBox label={"Witnesses No. 2’s Signature:"} textBoxValue={data.witnessName2}  stateName={"witnessName2"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 2’s Name:"} textBoxValue={data.witnessSignature2}  stateName={"witnessSignature2"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />



                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist’s Signature:"} showBold={true} />
                <LabelWithTextBox label={""} textBoxValue={data.drInChargeSignature}  stateName={"drInChargeSignature"} onChangeBloodTextValue={props.onChangeBloodTextValue}  />


                <View style={styles.SignView}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeBloodTextValue={props.onChangeBloodTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.BtnView}>

                    <TouchableOpacity
                        onPress={() => props.createBloodTransfusionForm()} 
                        style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={styles.BtnIcon} />
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=>{props.bloodpreviewAndPrint()}} disabled={props.formId ? false : true}
                        style={[Style.certificateButton1,{backgroundColor:props.formId ? color.themeDark : color.disableComponentColor}]} >
                        <Icon name={"print"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={styles.BtnIcon}/>
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        </ScrollView>
    )
}
const styles = StyleSheet.create({
        BloodSubHeadText: {
            fontSize: '0.9vw', 
            fontWeight: '600', 
            paddingVertical: '0.3vw'
        },
        BloodHeadText: { 
            color: "#000000", 
            alignSelf: 'center', 
            fontSize: '1.1vw', 
            fontWeight: '600', 
            paddingVertical: '0.3vw' 
        },
        BloodLabelText: { 
            color: "#707070", 
            fontSize: '0.8vw' 
        },
        BloodLabelTextBox: {
            marginLeft: '1vw',
            height: '1.2vw',
            alignself: '#4E4E4E',
            color: 'grey',
            borderStyle: 'dotted',
            borderBottomWidth: 1,
            fontSize: '1vw'
        },
        TextBoxonly: {
            marginLeft: 0,
            height: '1.2vw',
            alignself: '#4E4E4E',
            color: 'grey',
            borderStyle: 'dotted',
            borderBottomWidth: 1,
            fontSize: '1vw'
        },
        BloodLabelBoldWithText: { 
            color: "#707070", 
            fontSize: '0.9vw', 
            fontWeight: '600' 
        },
        ParagraphContent: { 
            lineHeight: '1.2vw', 
            color: "#707070", 
            fontSize: '0.8vw', 
            paddingVertical: '0.3vw' 
        },
        BtnIcon: {marginRight: '1vw'},
        witnessesView: { 
            marginBottom:'2.5vw', 
            backgroundColor: "#F9FDFD", 
            paddingLeft: '2vw', 
            paddingRight: '2vw', 
            paddingTop: '1.2vw', 
            paddingBottom: '1.2vw' 
        },
        SignView: { 
            alignItems: 'flex-end', 
            marginTop: '3.6vw', 
            marginRight: '1.2vw' 
        },
        BtnView: { 
            flexDirection: 'row', 
            alignSelf: 'center', 
            alignContent: 'center', 
            alignItems: 'center', 
            marginTop: '5vw' 
        },

})