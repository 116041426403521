import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Picker,
  Platform,
  CheckBox,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
// import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import { color } from "../../../styles/Color";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "glassPrescription";

export default class GlassPrescriptionNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      
      comments: "",

      chartUsed: "NIL",
      muscle: "NIL",
      Occupation: "NIL",
      wearingInstruction: "",
      continueWithSameContactLens: "NIL",

      lens_type: [],
      lensCoating: [],
      lensCoatingCommend: "",
      wearingInstructionCommend: "", 
      same_glass: false,

      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      continueWithSameGlass:this.props.continueWithSameGlass
    };

    this.renderCheckBox = this.renderCheckBox.bind(this)
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
  }

  componentWillReceiveProps(props) {

    if(props.continueWithSameGlass !== this.state.continueWithSameGlass){
      this.setState({
        continueWithSameGlass : props.continueWithSameGlass
      })
    }

    let { id } = this.state;

    if (props.editItem) {
      
      if (props.editItem.item && props.editItem.item.id && (id !== props.editItem.item.id)) {

        // console.log(" props.editItem " + JSON.stringify(props.editItem))  
        /*
        var states = this.state;
        states["id"]=props.editItem.item.id
        states["chartUsed"]=props.editItem.item.chart_used
        states["muscle"]=props.editItem.item.muscle
        states["Occupation"]=props.editItem.item.occupation
        states["wearingInstruction"]=props.editItem.item.wearing_instructions
        states["continueWithSameContactLens"]=props.editItem.item.continue_with_same_lense
        states["lens_type"]=props.editItem.item.lens_type
        states["lensCoating"]=props.editItem.item.lens_coating
        states["lensCoatingCommend"]=props.editItem.item.coating
        states["wearingInstructionCommend"]=props.editItem.item.comment
        states["same_glass"]=props.editItem.item.same_glass
        
        let state = JSON.parse(JSON.stringify(states));
        */
        this.setState({
          "id": props.editItem.item.id,
          "chartUsed": props.editItem.item.chart_used,
          "muscle": props.editItem.item.muscle,
          "Occupation": props.editItem.item.occupation,
          "wearingInstruction": props.editItem.item.wearing_instructions,
          "continueWithSameContactLens": props.editItem.item.continue_with_same_lense,
          "lens_type": props.editItem.item.lens_type,
          "lensCoating": props.editItem.item.lens_coating,
          "lensCoatingCommend": props.editItem.item.coating,
          "wearingInstructionCommend": props.editItem.item.comment,
          "same_glass": props.editItem.item.same_glass
        })
      }
    }

    // if(props.editItem) {

    //   if(props.editItem.title === pageName) {

    //     let item = props.editItem.item;

    //     if(id !== item.id) {

    //       this.setState({
    //         id: item.id,
    //         selectedEye: item.complaints,
    //         complaints: item.complaint_description,
    //         duration_type: item.duration_type,
    //         selectedDuration: item.duration_time,
    //         comments: item.comments
    //       });
    //     }
    //   }
    // }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {
    if (key == "lensCoating") {
      this.setState({
        lensCoating: value
      })
    } else if (key == "lens_type") {
      this.setState({
        lens_type: value
      })
    }
    // let states = this.state;
    // states[key] = value;

    // this.setState({
    //     states
    // })
  }

  addGlassPrescription = event => {

    // var data ={
    //   "appointment_id": this.state.patientAppointment.appointment_id,
    //   "chart_used":this.state.chartUsed,
    //   "muscle":this.state.muscle,
    //   "occupation":this.state.Occupation,
    //   "wearing_instructions":this.state.wearingInstruction,
    //   "continue_with_same_lense":this.state.continueWithSameContactLens,

    //   "lens_type":this.state.lens_type,
    //   "lens_coating":this.state.lensCoating,
    //   "coating":this.state.lensCoatingCommend,
    //   "comment":this.state.wearingInstructionCommend,
    // }
    var data = {}

    data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "chart_used": this.state.chartUsed?this.state.chartUsed:"NIL",
      "muscle": this.state.muscle?this.state.muscle:"NIL",
      "occupation": this.state.Occupation?this.state.Occupation:"NIL",
      "wearing_instructions": this.state.wearingInstruction,
      "continue_with_same_lense": this.state.continueWithSameContactLens?this.state.continueWithSameContactLens:"NIL",

      "lens_type": this.state.lens_type?this.state.lens_type:[],
      "lens_coating": this.state.lensCoating?this.state.lensCoating:"NIL",
      "coating": this.state.lensCoatingCommend,
      "comment": this.state.wearingInstructionCommend,
      "same_glass": this.state.same_glass
    }

    if (this.state.id ) {
      data["id"]= this.state.id
    } 


    var service_url = Constants.GLASS_PRESCRIPTION + "lens/";

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getGlassPrescriptionSuccess,
      this.getGlassPrescriptionFailure
    );
    // }
  };

  showSuccessMessage = () => {
   
    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getGlassPrescriptionSuccess = response => {
   //console.log("Glass Prescription Created Successfully----------->"+ response)
 
    if (response.status === "success") {
      //alert('Glass Prescription Created Successfully')
      // this.showSuccessMessage();
      // clear data
      // this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
      this.props.showResposeValue("success",response.message)
    }else{
      this.props.showResposeValue("error",response.message)
    }
  };

  getGlassPrescriptionFailure = error => {
    // alert("complaint created unsuccessfully")
    // this.showErrorMessage();
   // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error",error.message)

  };

  clearComplaint = event => {

    let states = this.state;
   
    states['lens_type'] = [];
    states['lensCoating'] = "";
    // "duration_type": 3,
    states['comments'] = "";

    states["chartUsed"]="",
    states["muscle"]="";
    states["occupation"]=""
    states["wearingInstruction"]=""
    states["continueWithSameContactLens"]=""
    states["lensCoatingCommend"]=""
    states["wearingInstructionCommend"]=""
  

    this.setState({ states },()=>{
      // this.props.clearEditedItem(pageName,{})
    });

  }

  render() {
    return (
      <View>
        {/* {this.renderSubHeadingBorder("Glass Prescription", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Glass Prescription"} clearAllData={this.clearComplaint.bind(this)}/>

        {/* {this.renderSubHeadingBorder("Chart Used",false)} */}
        {/* <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: 15 }}>
          {this.renderCheckBox("Continue with same glass", this.state.same_glass, "same_glass")}
        </View> */}
        
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '1vw' }}>
          <View style={{flex:0.32}}>
            <Picker
              enabled={this.state.isLocked || this.state.continueWithSameGlass?false:true}
              selectedValue={this.state.chartUsed}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,
              //paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  chartUsed: itemvalue
                })
              }}
            >
              <Picker.Item label="Select Chart Used" value="" />
              {/* <Picker.Item label="SNELLEN" value="SNELLEN" />
              <Picker.Item label="TUMBLING E" value="TUMBLING E" />
              <Picker.Item label="LANDOLT C" value="LANDOLT C" />
              <Picker.Item label="ETDRS" value="ETDRS" /> */}
              {Constants.DEFAULT_CHARTUSED_SR.map((i, index) => (
                <Picker.Item key={index} label={i.value} value={i.value} />
              ))}
            </Picker>
          </View>
          <View style={{flex:0.3}}>

            <Picker
              enabled={this.state.isLocked || this.state.continueWithSameGlass?false:true}
              selectedValue={this.state.muscle}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  muscle: itemvalue
                })
              }}
            >
              <Picker.Item label="Select Muscles" value="NIL" />
              <Picker.Item label="Ciliary" value="Ciliary" />
              <Picker.Item label="Ocular" value="Ocular" />
              <Picker.Item label="Extra-Ocular" value="Extra-Ocular" />
              {/* <Picker.Item label="NIL" value="NIL" /> */}
             
             
            </Picker>
          </View>
          <View style={{flex:0.3}}>

            <Picker
              enabled={this.state.isLocked || this.state.continueWithSameGlass?false:true}
              selectedValue={this.state.Occupation}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  Occupation: itemvalue
                })
              }}
            >
              <Picker.Item label="Select Occupation" value="" />
              <Picker.Item label="Professional" value="Professional" />
              <Picker.Item label="Goldsmith" value="Goldsmith" />
              <Picker.Item label="Farmer" value="Farmer" />
              <Picker.Item label="Self-employed" value="Self-employed" />
              <Picker.Item label="Businessman" value="Businessman" />
              <Picker.Item label="Tailor" value="Tailor" />
              <Picker.Item label="Student" value="Student" />
              <Picker.Item label="Others" value="Others" />

            </Picker>
          </View>
        </View>
        
        {this.renderNewSubHeader("Lens Type")}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              Constants.LENS_TYPE_OPTIONS.map((item, index) => {
                
                var data = this.state.lens_type
                
                return (
                  <View style={styles.CommonBtnView} key={index}>
                    <CommonButton
                      disable={(this.state.isLocked || this.state.continueWithSameGlass) ?  true : false}
                      item={item}
                      selectedvalue={data}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.updateState.bind(this)}
                      buttonKey={"lens_type"} />
                  </View>
                )
              })
            }
        </View>

        <View>
          {/* {this.renderTitleWithMultipleBtn("Lens Type", Constants.LENS_TYPE_OPTIONS, 3, false, 
          'lens_type', this.state.lens_type, this.updateState.bind(this), false, true,"","",true,this.state.isLocked)}  */}
        </View>

        {/* {this.renderNewSubHeader("Lens Coating")}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              Constants.LENS_COATING_OPTIONS.map((item, index) => {
                
                var data = this.state.lensCoating
                
                return (
                  <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                    <CommonButton
                      disable={this.state.isLocked}
                      item={item}
                      selectedvalue={data}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.updateState.bind(this)}
                      buttonKey={"lensCoating"} />
                  </View>
                )
              })
            }
        </View>
         */}
        <View>
          {this.renderTitleWithMultipleBtn("Lens Coating", Constants.LENS_COATING_OPTIONS, 3, false, 
            'lensCoating', this.state.lensCoating, this.updateState.bind(this), false, true,"","",false,(this.state.isLocked || this.state.continueWithSameGlass) ? true : false)}
        </View>

        <CommonSectionHeader heading={"Coating Comments"}  />
        <View style={{marginTop:'1.2vw'}}>
         {this.renderTextFieldView("Only 50 characters are allowed", "lensCoatingCommend", this.state.lensCoatingCommend, true, 4,'',{},'',false,(this.state.isLocked || this.state.continueWithSameGlass) ? true : false,50)}
        </View>
       
        
        <CommonSectionHeader heading={"Wearing Instruction"}  />
        <View style={{marginTop:'1.2vw'}}>  
          {this.renderTextFieldView("Only 250 characters are allowed", "wearingInstruction", this.state.wearingInstruction, true, 4,'',{},'',false,(this.state.isLocked || this.state.continueWithSameGlass) ? true : false,250)}
        </View>
        
        <View style={{ 
          // height: "70%" 
          marginBottom: '1.2vw'
          }}>

          {/* <TextInput
            underlineColorAndroid="transparent"
            placeholder="Comments"
            style={{
              color: "#525252",
              height: 40,
              paddingLeft: 5,
              marginRight: 0,
              // flex: 1,
              fontSize: 11,
              marginTop: 20,
              backgroundColor: 'white',
              borderRadius: 10,

            }}
            // ref={text => (this.nameInput = text)}
            // defaultValue=""
            autoCapitalize="none"
            value={this.state.wearingInstructionCommend}
            onChangeText={text => this.setState({ wearingInstructionCommend: text })}
          />
           */}
          <View style={{ width: '82%', height: '2vw' }}>
            {/* <TouchableOpacity style={{borderRadius:20,borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center'}}>
              <Text style={{color:'white',fontSize:11}}>{"Continue with the same contact lens"}</Text>
            </TouchableOpacity> */}
            <Picker
              enabled={this.state.isLocked || this.state.continueWithSameGlass?false:true}
              selectedValue={this.state.continueWithSameContactLens}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  continueWithSameContactLens: itemvalue
                })
              }}
            >
              <Picker.Item label="Select Continue with the same contact lens" value="" />
              <Picker.Item label="Disposable Soft" value="Disposable Soft" />
              <Picker.Item label="Daily" value="Daily" />
              <Picker.Item label="Extended Wear" value="Extended Wear" />
              <Picker.Item label="Spherical" value="Spherical" />
              <Picker.Item label="Toric" value="Toric" />
              <Picker.Item label="Monovision" value="Monovision" />
              <Picker.Item label="Multifocal / Bifocal" value="Multifocal / Bifocal" />
              <Picker.Item label="Conventional" value="Conventional" />
              <Picker.Item label="Rigid Gas Permeable" value="Rigid Gas Permeable" />
              <Picker.Item label="Hard" value="Hard" />
           
            </Picker>
          </View>
        </View>

        <View style={{ marginTop: '1.2vw',alignSelf:"center" }}>
          <TouchableOpacity disabled={(this.state.isLocked || this.state.continueWithSameGlass) ? true : false} onPress={this.addGlassPrescription.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View >
          {/* <View  style={{ marginTop: 20 }}>
            {this.state.successOrErrorMessage ? (
              <View style={{ height: 40, width: '100%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{
                  color: "white", textAlign: "center", justifyContent: "center"
                }}>
                  {
                    this.state.successMessageFlag ? 'Glass Prescription Created Successfully' :
                      this.state.errorMessageFlag ? 'Glass Prescription Failed' : null}</Text>
              </View>
            ) : null}
          </View > */}
      </View>
    );
  }

  renderCheckBox(label, value, key) {
    return (
        <View style={{ flexDirection: 'row', alignSelf:'flex-end', marginTop: '0.6vw' }}>
            <CheckBox value={value}
                style={styles.CheckBoxView}
                onValueChange={() => {
                  var states =this.state;

                  states[key]=!value
                  this.setState({
                    states,
                    continueWithSameGlass:true
                  }, ()=>{
                    
                    if(key==="same_glass") {
                  
                      if(!value){
                      
                        this.setState({
                          'chartUsed': "NIL",
                          'muscle': "NIL",
                          'Occupation': "NIL",
                          'continueWithSameContactLens': "NIL",
                          'lens_type': [],
                          'lensCoating': [],
      
                          'wearing_instructions': "",
                          'lensCoatingCommend': ""
                        });
                      }
                    }
                  })
                }}

            />
            <Text style={styles.CheckBoxText}>{label}</Text>
        </View>
    );
  }
}

{/* <View style={{ marginBottom: 20 }}>
        {this.renderSubHeadingBorder("Wearing Instruction",false)}
        <Picker
              selectedValue={this.state.wearingInstruction}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={Style.pickerRoundCornerView}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  wearingInstruction: itemvalue
                })
              }}
            >
              <Picker.Item label="Select Instruction" value="" />
              <Picker.Item label="INSTRUCTIONS" value="INSTRUCTIONS" />
              
            </Picker>
        </View> */}

        {/* <View style={{ height: "70%" }}>

          <TextInput
            underlineColorAndroid="transparent"
            placeholder="Coating"
            style={{
              color: "#525252",
              height: 40,
              paddingLeft: 5,
              marginRight: 0,
              // flex: 1,,
              fontSize: 11,
              marginTop: 20,
              backgroundColor: 'white',
              borderRadius: 10,

            }}
            // ref={text => (this.nameInput = text)}
            // defaultValue=""
            autoCapitalize="none"
            value={this.state.lensCoatingCommend}
            onChangeText={text => this.setState({ lensCoatingCommend: text })}
          />

          <View style={{ width: '52%', marginTop: 30, marginBottom: 10, height: 30 }}>

            <Picker
              selectedValue={this.state.wearingInstruction}
              // style={{ borderRadius: 20, borderColor:'white',borderWidth:1,paddingLeft:10,paddingRight:10,height:30,justifyContent:'center' }}
              // itemStyle={{ fontSize: 11 }}
              style={Style.pickerRoundCornerView}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                this.setState({
                  wearingInstruction: itemvalue
                })
              }}
            >
              <Picker.Item label="INSTRUCTIONS" value="INSTRUCTIONS" />
              <Picker.Item label="INSTRUCTIONS" value="INSTRUCTIONS" />
              <Picker.Item label="INSTRUCTION" value="INSTRUCTION" />
            </Picker>
          </View>
        </View> */}
const styles = StyleSheet.create({
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      CheckBoxView: {
        marginRight: '0.8vw',
        width: '1.2vw',
        height: '1.2vw'
      },
      CheckBoxText: { 
        fontSize: '0.8vw', 
        color: "#888888" 
      }
})