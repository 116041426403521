//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Picker, Dimensions } from "react-native";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { CommonButton, CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants"
import Style from "../../../../styles/Style";
import moment from "moment";
import { color } from "../../../../styles/Color";
import AsyncStorage from "../../../../AsyncStorage";
const screenHeight = Dimensions.get("window").height;

// create a component
class ScanReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: "",
      endDate: "",
      selectedSearch: {},
      // patient_id: "20865",
      // appointment_id:"22913",

      patient_id: this.props.patient_id,
      appointment_id: this.props.appointment_id,

      getListOfScanReports: [],

      selectedScanReport: "",
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      isCurrentPageIsIP: this.props.isCurrentPageIsIP,
      modificationBillDetails: this.props.modificationBillDetails, 
      otPatientDetails: this.props.otPatientDetails,
      userType: this.props.userType,
      clinicId: ""
    };
  }


  btnClickAction = key => {
    var ultrasound = false;
    var obstetricsonography = false;

    if (key === "ultrasound") {
      home = true;

      this.props.changeComponent("ultrasound")

      // this.props.navigation.navigate("ultrasound");

    } else if (key == "obstetricsonography") {
      registry = true;
      this.props.navigation.navigate("obstetricsonography");
    }

    var fields = this.state;
    fields["isUltrasound"] = ultrasound;
    fields["isObstetricsonography"] = obstetricsonography;


    // this.setState({ fields });
  };

  async componentDidMount() {
    this.getScanReport();
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      clinicId: loggedInDataObj.clinic_id
    })
  }

  componentWillReceiveProps(props) {
    if (props && props.searchType == "isScanReport" && (props.startDate !== this.state.startDate || this.state.endDate !== props.endDate)) {
      // if(){
      var { startDate, endDate } = this.state

      // if(props.startDate)
      startDate = props.startDate;
      // if(props.endDate)
      endDate = props.endDate;

      this.setState({ startDate, endDate }, () => {
        this.props.clearSearchType("")
        this.getScanReport();
      })
      // }
    }
  }

  getScanReport = () => {

    var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : ""
    var LastDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : ""
    if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {
      
      let appointment_id = this.state.selectedIpPatientDetails.appointment_id ? this.state.selectedIpPatientDetails.appointment_id : this.state.modificationBillDetails.appointment_id ? this.state.modificationBillDetails.appointment_id : ""
      var service_url = Constants.SCAN_REPORT + "?appointment_id=" + appointment_id +
      "&patient_id="+ this.state.selectedIpPatientDetails.patient_id  + "&from_date=" + startDate + "&to_date=" + LastDate;

    } else if(this.state.userType === "OT") {

        let patient_id = this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : ""
        let appointment_id = this.state.otPatientDetails.appointment ? this.state.otPatientDetails.appointment : ""
  
        var service_url = Constants.OT_SCAN_REPORT + "?appointment_id=" + appointment_id + "&patient_id="+ patient_id  + "&from_date=" + startDate + "&to_date=" + LastDate;

    } else {
      let appointment_id = this.state.appointment_id ? this.state.appointment_id : this.state.modificationBillDetails.appointment_id
      var service_url = Constants.SCAN_REPORT + "?appointment_id=" + this.state.appointment_id +
        "&patient_id=" + this.state.patient_id + "&from_date=" + startDate + "&to_date=" + LastDate;
    }
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getScanReportSuccess,
      this.getScanReportailure
    );
  }
  getScanReportailure = error => { }

  getScanReportSuccess = (response) => {
    if (response.status == "success") {
      // alert(JSON.stringify(response.data.scan_reports))
      this.setState({
        getListOfScanReports: response.data.scan_reports
      })
    }
  }


  onPressButton(key, selectedvalue) {
    if (key == "Search") {
      this.setState({
        selectedSearch: selectedvalue
      })
      this.getScanReport();
    }

  }

  openView(item) {
    var appointment_id = this.state.userType == "OT" ? this.state.otPatientDetails.appointment : item.appointment_id
    var service_url = Constants.OPEN_PDF + "?report_id=" + item.report_id + "&appointment_id=" + appointment_id + "&clinic_id=" + this.state.clinicId +"&report_type=" + item.report_type;

    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      // this,
      this.openViewSuccess,
      this.openViewFailure,
      "pdf"
    );
  }

  openViewSuccess = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  openViewFailure = (error) => {
    // console.log("PDF error response");
    console.log(error);
  }


  renderHistoryItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_name}
              </Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>

        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };

  rendercontinue(item) {
    if (item.report_type == "Obstetric Sonography Report") {
      this.props.changeComponent("obstetricSonographyReport", item.report_id)
    } else if (item.report_type == "Ultra Sound Abdomen Report") {
      this.props.changeComponent("ultraSoundAbdomenReport", item.report_id)

    }

  }

  render() {
    console.log(this.state.otPatientDetails,"check-->s")
    return (
      <View style={styles.container}>


        <View style={styles.ScanReportContainer}>
          <View>
            <Text style={[styles.ScanReportHead, {color: color.themeDark }]}>
              Scan Report
            </Text>
          </View>
          <CommonHistorySectionHeader
              heading1={"Date"}
              heading2={"Report Type"}
              heading3={"Action"}
              columnSize={[0.30, 0.50, 0.20]}  //total value is == of 1
              noOfColumn={3} />

          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.5 }}>

            {this.state.getListOfScanReports && this.state.getListOfScanReports.map((item, index) => {

              // console.log("List +++++" + JSON.stringify(item))
              

              return (
                <View>

                  <View style={styles.TableContentView} key={index} >
                    <Text style={{ flex: 0.30, marginLeft: '2vw', fontSize: '1vw' }}> {item.date}</Text>
                    <Text style={{ flex: 0.50, fontSize: '1vw' }}>{item.report_type}</Text>
                    {item.is_finished ?
                      <View style={{ flex: 0.20 }}>
                        <TouchableOpacity onPress={() => this.openView(item)} >

                          <Text style={{ fontSize: '1vw', color: "#45B7B1" }}> View  </Text></TouchableOpacity>
                      </View> :
                      <TouchableOpacity onPress={() => this.rendercontinue(item)} disabled = {!this.state.appointment_id ? true : false}
                      style={{ flex: 0.20 }}>
                        <Text style={{ fontSize: '1vw', color: !this.state.appointment_id ? "black": "blue"}}> Continue </Text></TouchableOpacity>}
                  </View>
                  <View style={{
                    borderWidth: 0.5,
                    borderColor: "#EFEFEF"
                  }} />
                </View>
              )
            })}
          </ScrollView>

        </View>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    // marginTop: 20,
    backgroundColor: color.sectionHeadingDarkColor,
    minHeight: '70vh',
    padding: (5, 10, 10, 5)
  },
  ScanReportContainer: { 
    flex: 1, 
    // marginTop: '1.5vw', 
    // marginLeft: '1.2vw', 
    backgroundColor: "#fff", 
    padding: '1.2vw' 
  },
  ScanReportHead: { 
    fontSize: '1.2vw', 
    fontWeight: '500', 
    margin: '0.6vw' 
  },
  TableContentView: { 
    flexDirection: "row", 
    marginTop: '1vw', 
    alignItems: 'center' 
  }

});

//make this component available to the app
export default ScanReport;
