// need to chenge clander and set the calander date ref(getCreatedPatientDetailSuccess)

import React, { Component } from 'react';
import { View, Text, Switch, TextInput, TouchableWithoutFeedback, FlatList, Image, TouchableOpacity, Dimensions, StyleSheet, Platform, Picker, ScrollView } from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from '../../../styles/Color';
import Style from "../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import 'antd/dist/antd.css';
import { Constants } from "../../../utils/Constants"
import moment from "moment";
const screenHeight = Dimensions.get("window").height;
import OpthamologyService from "../../../network/OpthamologyService"
import Service from "../../../network/Service";
import AsyncStorage from "../../../AsyncStorage"
import axios from "axios"
import { CommonPicker, returnPaymentMode } from './../BaseComponent'
import { Tooltip, Checkbox } from 'antd';
import ImagePath from '../../../utils/ImagePaths';
// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

var Messages = require('../../../utils/InfoMessages');

// for camera
import * as Permissions from 'expo-permissions';

var Messages = require('../../../utils/InfoMessages');

export default class QuickBilling extends BaseComponentStyle {
    constructor(props) {
        super(props);
        let userType = '';
        let selectedClinicOrDoctor = JSON.parse(localStorage.getItem("Selected_Clinic_Doctor"))
        try {
            userType = this.props.userType;
            selectedClinicOrDoctor = selectedClinicOrDoctor && selectedClinicOrDoctor.id ? selectedClinicOrDoctor : { id: selectedClinicOrDoctor.id, value: selectedClinicOrDoctor.value };
        } catch (error) { }

        this.state = {
            userType: userType,
            currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss "),
            mandatoryInformation: {
                type: "Mr",
                gender: "",
                firstName: "",
                surName: "",
                dob: "",
                age: "",
                approx_dob: "",
                maritalStatus: "",
                mobileNo: "",
                receiveSMSFlag: true,
                country: "India",
                state: "",
                fatherOrHusband: ""
            },
            apmtCreationData: {
                patient_id: "",
                clinic_id: "",
                purpose_of_visit: "",
                appointment_date: moment(new Date()).format("YYYY-MM-DD"),
                appointment_start_time: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss "),
                create_without_schedule: true,
                patient_tags: ""
            },

            otherInformation: {
                city: ""
            },
            patientId: "",
            patientDetails: {},

            isDatePickerVisible: true,
            setDatePickerVisibility: true,

            successOrErrorMessage: "",
            successMessageFlag: false,
            errorMessageFlag: false,

            documentUploadSelectedButton: "",

            patientInfo: {},
            isExistingPatient: false,
            patientAppointment: {},
            alreadyCreatedPatient: false,
            selectedClinicOrDoctor: selectedClinicOrDoctor,
            doctorData: [],
            selectedDoctor: selectedClinicOrDoctor,

            totalAmount: 0.00,    //amount_gross
            paymentType: "",
            discount_percent: 0,
            discount: 0.00,
            amount_invoice: 0.00, //After Discount
            amount_net: 0.00,     //After Round Off
            amount_cash: 0.00,
            amount_card: 0.00,
            round_off: 0.00,
            invoice_date: "",
            invoice_id: "",
            enablePrint: false,
            upiTransactionID: "",
            bank_transaction_id : "",
            Cheque: "",
            firstPayment: "",
            secondPayment: "",
            firstAmount: "",
            secondAmount: "",
            CardNumber: "",

            //Billing details
            Billing: [],
            selectedService: {},
            showServiceNameNewButton: true,
            billingService: [],
            billingServiceData: [],
            billingServiceName: [],
            billingServiceType: [],
            billingServiceAmount: [],
            selectedBillingServiceName: "",
            selectedBillingServiceId: "",
            selectedBillingServiceType: {},
            selectedBillingServiceAmount: null,
            responseType: "",
            responseMeaasge: "",
            isDisabled: false,
            showNursedata: false,
            selectedNurseTag: '',
            staffData: [],

            ServiceNameSearchKey :"",
        };
    }

    getCreatedPatientDetail(patient_id) {
        var service_url = Constants.DR_CREATE_PATIENT + patient_id + "/";
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getCreatedPatientDetailSuccess,
            this.getCreatedPatientDetailFailure
        );
    }
    getCreatedPatientDetailSuccess = success => {
        if (success.status == "success") {
            if (success.data) {
                var patientInfo = success.data;
                var { mandatoryInformation } = this.state;
                var { otherInformation } = this.state;
                var states = this.state
                var gender = ""
                if (patientInfo.gender == "m") {
                    gender = "Male"
                } else if (patientInfo.gender == "f") {
                    gender = "Female"
                } else {
                    gender = "Trans"
                }
                let age = "";
                let approx_dob = "";

                if (patientInfo.dob) {
                    age = this.handleAgeCalculation(patientInfo.dob);
                } else if (patientInfo.approx_dob) {
                    age = this.handleAgeCalculation(patientInfo.approx_dob);
                    // format
                    approx_dob = moment(patientInfo.approx_dob, 'YYYY-MM-DD').format("DD-MM-YYYY");
                }

                mandatoryInformation["profilePhoto"] = patientInfo.photo_url;
                mandatoryInformation["type"] = patientInfo.title;
                mandatoryInformation["gender"] = gender;
                mandatoryInformation["firstName"] = patientInfo.first_name;
                mandatoryInformation["surName"] = patientInfo.last_name;
                mandatoryInformation["dob"] = patientInfo.dob;
                mandatoryInformation["age"] = age;
                mandatoryInformation["approx_dob"] = approx_dob;
                mandatoryInformation["maritalStatus"] = patientInfo.marital_status;
                mandatoryInformation["occupation"] = patientInfo.occupation;
                mandatoryInformation["mobileNo"] = patientInfo.mobile_number;
                mandatoryInformation["receiveSMSFlag"] = patientInfo.receive_sms;
                // mandatoryInformation["refferedBy"]=patientInfo.reffered_by;

                mandatoryInformation["country"] = patientInfo.country;
                mandatoryInformation["fatherOrHusband"] = patientInfo.father_husband_name;
                mandatoryInformation["state"] = patientInfo.state_name;
                mandatoryInformation["mobileNo2"] = patientInfo.mobile_number_2;
                mandatoryInformation["isAmaPatient"] = patientInfo.is_ama_patient;

                otherInformation["emailID"] = patientInfo.email;
                otherInformation["address"] = patientInfo.address_line_1;
                otherInformation["address2"] = patientInfo.address_line_2;

                otherInformation["city"] = patientInfo.city_name;
                otherInformation["pincode"] = patientInfo.pincode;

                this.setState({
                    mandatoryInformation,
                    otherInformation,
                    toggleSwitchFlag: patientInfo.receive_sms,
                    patientInfo: patientInfo
                }, () => {
                })
                if (this.state.isCreatePatientClicked) {
                    this.createNextAppoinment();
                }
            }
        }

    }
    getCreatedPatientDetailFailure = error => {
        console.log("error------->>>>> " + JSON.stringify(error))
    }

    // Appointment Creation API Starts here
    createNextAppoinment() {

        this.setState({
            isCreateClicked: true
        })
        // 2019-11-16

        var checkAMPM;
        var today = new Date();
        var hours = parseInt(today.getHours());
        var minutes = today.getMinutes()

        checkAMPM = hours >= 12 ? 'pm' : 'am';

        if (hours > 12) {
            this.state.appointmentTime = hours - 12;
            this.state.appointmentTime = this.state.appointmentTime < 10 ? '0' + this.state.appointmentTime : this.state.appointmentTime;
        } else {
            this.state.appointmentTime = hours;
        }

        var tempTime = (this.state.appointmentTime) + ":" + minutes + " " + checkAMPM;
        var appointmentTime = this.convertTime12to24(tempTime);
        var appointmentScheduleTime = this.convertTime12to24(this.state.selectedDocorScheduleTimes && this.state.selectedDocorScheduleTimes.value ? this.state.selectedDocorScheduleTimes.value : "");

        var data = {};
        var service_url = "";

        if (this.state.userType === Constants.ROLE_FO) {
            if (this.state.selectedDoctor.id == "Nursing") {
                if (this.state.selectedNurseTag.id == undefined) {
                    this.setState({
                        showErrorResponce: "Please Select Nurse",
                        isCreateClicked: false
                    });
                    return;
                }
            }
            service_url = Constants.FO_CREATE_APPOINTMENT;
            data = {
                "patient_id": parseInt(this.state.patientId),
                "doctor_id": this.state.selectedDoctor.id == "Nursing" ? null : parseInt(this.state.selectedDoctor.id),
                "purpose_of_visit": "Check up",
                "appointment_date": this.state.apmtCreationData.appointment_date.toString(),
                "appointment_start_time": appointmentTime,
                "create_without_schedule": true,
                "patient_tags": null,
                "nurse_id": this.state.selectedNurseTag.id,
            };

        } else {
            service_url = Constants.DOCTOR_CREATE_APPOINTMENT;
            data = {
                "patient_id": parseInt(this.state.patientId),
                "clinic_id": parseInt(this.state.selectedClinic.id),
                "purpose_of_visit": this.state.purposeOfVisitsInNextAppoinment,
                "appointment_date": this.state.apmtCreationData.appointment_date.toString(),
                "appointment_start_time": appointmentTime,
                "create_without_schedule": true,
                "patient_tags": null
            };
        }

        if (this.state.request_id) {
            data["request_id"] = this.state.request_id
        }
        if (data.doctor_id) {
            delete data.nurse_id
        }
        console.log(data)
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getNextAppointmentSuccess,
            this.getNextAppointmentFailure
        );
    }

    getNextAppointmentSuccess = response => {
        if (response.status === "success") {
            let { patientAppointment } = this.state;
            patientAppointment['appointment_id'] = response.data.appointment_id;
            this.setState({ patientAppointment })
            this.setState({
                purposeOfVisitsInNextAppoinment: "",
                modalVisible: false
            })
            if (this.state.Billing.length > 0) {
                this.saveBillingDetails()
            } else {
                this.setState({
                    showErrorResponce: response.message,
                    isCreateClicked: false
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            showErrorResponce: ""
                        })
                    }, 3000)
                })
                this.ResetScreen();
            }
            // if (!localStorage.getItem('isQuickBilling')) {
            //     this.props.modelClose(false, "success", "", {}, "home")
            // } else {
            //     localStorage.setItem('isQuickBilling', false);
            //     localStorage.setItem('QuickBillingAppointmentId', response.data.appointment_id)
            // }
        } else {
            this.setState({
                showErrorResponce: response.message,
                isCreateClicked: false
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showErrorResponce: ""
                    })
                }, 3000)
            })
        }

    }
    getNextAppointmentFailure = error => {
        console.log("response -----------------> error   " + JSON.stringify(error));
        this.setState({
            isCreateClicked: false
        })
    }

    // Appointment Creation API Ends Here
    handleAgeCalculation = date => {
        let age = "";
        let dob = new Date(date);
        let m = moment(dob, "DD-MM-YYYY");
        var end_date = moment();

        var year_age = end_date.diff(m, 'year') || 0;
        // reset(year) year with difference year
        m = moment(m).add(year_age, 'years');

        var month_age = end_date.diff(m, 'month') || 0;
        // reset(month) month with difference month
        m = moment(m).add(month_age, 'months');

        var day_age = end_date.diff(m, 'days') || 0;

        if (year_age >= 15) {
            age = year_age || 0;
        } else {
            age = year_age + "/" + month_age + "/" + day_age;
        }
        return age;
    }

    async componentDidMount() {
        let status = ""
        try {
            let objStatus = await Permissions.askAsync(Permissions.CAMERA);
            if (objStatus && objStatus.status) {
                status = objStatus.status
            }
        } catch (error) {
        }
        var fields = this.state;
        const userType = await AsyncStorage.getItem("service_type");
        const clinicId = await AsyncStorage.getItem("UserID");
        let restrictCreateAppointmentModal = this.props.patientAppointment && this.props.patientAppointment.patient_id ? false : true;

        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var selectedValue = localStorage.getItem("Selected_Clinic_Doctor");
        if (selectedValue) {
            fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
            fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
        } else {
            await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(getListOfClinicOrDoctor[0]));
            fields["selectedClinicOrDoctor"] = getListOfClinicOrDoctor[0];
            fields["selectedClinicOrDoctor"] = getListOfClinicOrDoctor[0];
        }
        var loggedInDataObj = JSON.parse(loggedInData)
        this.setState({
            defaultSelection: loggedInDataObj.default_value
        }, () => {
            var defaultCountry = this.state.defaultSelection.default_country
            var defaultCountries = defaultCountry.charAt(0).toUpperCase() + defaultCountry.slice(1);

            var defaultState = this.state.defaultSelection.default_state
            var defaultStates = defaultState.charAt(0).toUpperCase() + defaultState.slice(1);

            var defaultCity = this.state.defaultSelection.default_city
            var defaultCities = defaultCity.charAt(0).toUpperCase() + defaultCity.slice(1);
            var { otherInformation, mandatoryInformation } = this.state;
            mandatoryInformation["state"] = defaultStates;
            otherInformation["city"] = defaultCities;
        })
        this.setState({
            userType: userType,
            clinicId: clinicId,
            hasPermission: status === 'granted' ? true : false,
            restrictCreateAppointmentModal: restrictCreateAppointmentModal,
        })
        if (userType == Constants.ROLE_FO) {
            this.getDoctor();
            this.renderNurseList();
            this.getStaffList();
            this.getBillingFrequentlyUsedService();
        }
        if ((this.props.patientAppointment && this.props.patientAppointment.patient_id) || (this.props.selectedIpPatientDetails && this.props.selectedIpPatientDetails.patient_id) || (this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.patient_id)) {
            var patient_id = "";
            if (this.props.patientAppointment.patient_id) {
                patient_id = this.props.patientAppointment.patient_id;
            } else if (this.props.selectedIpPatientDetails.patient_id) {
                patient_id = this.props.selectedIpPatientDetails.patient_id;
            } else if (this.props.selectedLabPatientDetails.patient_id) {
                patient_id = this.props.selectedLabPatientDetails.patient_id;

            }
            this.setState({
                checkPatientId: patient_id
            }, () => {
            }
            )
            this.getCreatedPatientDetail(patient_id);

            this.setState({
                alreadyCreatedPatient: true,
                patientId: patient_id,
                patientAppointment: this.props.patientAppointment,
                patientInfo: this.props.patientInfo,
            })
        }

        // 
        // this.chegeAppoinmentModalTime()
        this.getClinic();
        // 
        setInterval(() => {
            this.setState({
                currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
            });
        }, 1000);
    }

    componentWillReceiveProps(props) {

        let { mandatoryInformation } = this.state;

        if (props.cameraCaptureImage) {
            mandatoryInformation["profilePhoto"] = props.cameraCaptureImage;

            this.setState({ mandatoryInformation })
        }

        if (props.cameraCaptureFaces) {

            if ((props.cameraCaptureFaces).length > 0) {

                mandatoryInformation["facialPhotos"] = props.cameraCaptureFaces;
                this.setState({ mandatoryInformation })

                if (props.cameraCaptureFaces.length === 5) {
                    // save image 
                    this.saveUserImages();
                }
                this.props.clearPatientPhotos();
            }
        }
    }
    getAppointmentDetails(appointment_Id) {
        if (appointment_Id != "" && this.state.appointmentId != appointment_Id) {
            var appointmentId = appointment_Id;
            this.setState({ appointmentId: appointment_Id });
            this.props.modelClose(false)
            localStorage.setItem('QuickBillingAppointmentId', "");
            var serviceUrl = "";
            if (this.state.selectedClinicOrDoctor.id) {
                serviceUrl = Constants.FO_PATIENT_INFO + this.state.patientId + "/?appointment_id=" + appointmentId + "&doctor_id=" + this.state.selectedClinicOrDoctor.id;
            } else {
                serviceUrl = Constants.FO_PATIENT_INFO + this.state.patientId + "/?appointment_id=" + appointmentId + "&doctor_id=Nursing";
            }
            //this.getAppointmentDetails(serviceUrl);
            OpthamologyService.getInstance().getComplaints(
                serviceUrl,
                this,
                this.getAppointmentsDetailsSuccess,
                this.getAppointmentsDetailsFailure
            );
        };
    }

    getAppointmentsDetailsSuccess = response => {
        if (response.status === "success") {
            var field = this.state;
            //field["patientAppointment"] = response.data;
            // field['patient_id']=this.state.patientId;
            // field['appointment_id'] = this.state.appointmentId;
            field["patientInfo"] = response.data;
            //field['appointment_date'] = "";

            this.setState({ field });
            var fields = this.state;
            let appointmentData = fields["patientAppointment"];
            appointmentData['patient_id'] = this.state.patientId;
            appointmentData['appointment_id'] = this.state.appointmentId;
            appointmentData['doctor_id'] = this.state.selectedClinicOrDoctor.id;
            this.setState({ fields })
            localStorage.setItem('isQuickBillingScreenSuccess', "");
            this.props.changeStateValues(response.data, this.state.patientAppointment, this.state.userType, this.state.selectedClinicOrDoctor)
        }
    };
    ResetScreen() {
        console.log("reset screen");
        localStorage.removeItem("isQuickBilling");
        localStorage.removeItem("QuickBillingAppointmentId");
        localStorage.removeItem("isQuickBillingScreenSuccess");
        localStorage.removeItem("QuickBillingAppointmentClosed");
        this.props.modelClose(false);
        this.props.changeStateValues({}, {}, this.state.userType, this.state.selectedClinicOrDoctor)

        this.setState({
            patientDetails: {},
            patientInfo: {},
            patientAppointment: {},
            patientId: "",
            modalVisible: false,
            isCreatePatientClicked: false,
            currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss "),
            mandatoryInformation: {
                type: "",
                gender: "",
                firstName: "",
                surName: "",
                dob: "",
                age: "",
                approx_dob: "",
                maritalStatus: "",
                mobileNo: "",
                receiveSMSFlag: true,
                country: "India",
                state: this.state.defaultSelection.default_state,
                fatherOrHusband: ""
            },
            apmtCreationData: {
                patient_id: "",
                clinic_id: "",
                purpose_of_visit: "",
                appointment_date: moment(new Date()).format("YYYY-MM-DD"),
                appointment_start_time: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss "),
                create_without_schedule: true,
                patient_tags: ""
            },

            otherInformation: {
                city: this.state.defaultSelection.default_city
            },

            isDatePickerVisible: true,
            setDatePickerVisibility: true,

            successOrErrorMessage: "",
            successMessageFlag: false,
            errorMessageFlag: false,

            documentUploadSelectedButton: "",

            isExistingPatient: false,
            patientAppointment: {},
            alreadyCreatedPatient: false,
            selectedClinicOrDoctor: this.state.selectedClinicOrDoctor,
            paymentType: "Cash",
            invoice_id : "",
            enablePrint : false,
            //doctorData: [],
            //selectedDoctor: {},

            //Billing details
            // Billing: [],
            // selectedService: {},
            // showServiceNameNewButton: true,
            // billingService: [],
            // billingServiceData: [],
            // billingServiceName: [],
            // billingServiceType: [],
            // billingServiceAmount: [],
            // selectedBillingServiceName: "",
            // selectedBillingServiceId: "",
            // selectedBillingServiceType: {},
            // selectedBillingServiceAmount: 0,

        })
        // let state = { ...this.state };
        // delete state.states;
        // this.setState(state);
    }

    renderSubHeadingBorder = (title, line = false) => {
        return (
            <View style={[styles.subHeadingBorderContainer, { marginLeft: title == "IDENTITY PROOF" ? null : "2.60vw" }]}>
                <View>
                    <Text style={styles.subHeadingTitleText}>{title}</Text>
                </View>
                {(line) ?
                    <View
                        style={styles.subHeadingTitleBody}
                    />
                    : null}
            </View>
        );
    };
    renderTextBox(placeholder, width, center, type, value, error = false, disable = false) {
        return (
            <View>
                <Text style={[styles.renderTextBoxPlaceHolder, { backgroundColor: type == "IDP" ? color.themeShade : "white" }]}>{placeholder}</Text>

                {
                    disable || this.state.isIpPatientCheckouted ?
                        <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: ".5vw", color: "#888888", paddingTop: "0.65vw" }, Style.allButtonBorderRadius,
                        this.state.isIpPatientCheckouted ? { backgroundColor: "#F0F0F0" } : {}
                        ]} >
                            {value}
                        </Text> :
                        <TextInput

                            style={[styles.renderTextBoxTextInput, {
                                borderColor: error ? "red" : "#CDD1D5",
                            }]} value={value}
                            maxLength={placeholder === "Age *" ? 3 : null}
                            onChangeText={(text) => {
                                // value = text;
                                // props.callBack(text, props.textKey);
                                if (placeholder == 'Source Name') {
                                    this.createPatientDetails(text, placeholder, type);
                                    //this.searchReferrals(text);
                                } else {
                                    this.createPatientDetails(text, placeholder, type);
                                }

                            }}

                        />
                }

            </View>
        )
    }
    renderPaymentBtn = (img, title) => {
        var paymentFlag = false
        if (this.state.Billing && this.state.Billing.length == 1) {
            if (this.state.Billing[0].description == "Free Consultation") {
                paymentFlag = true
            }
        } else {
            paymentFlag = false
        }
        return (
            <View style={{ alignItems: "center", justifyContent: "center" }}>
                <TouchableOpacity
                    disabled={this.state.firstPayment == "" && this.state.secondPayment == "" ? paymentFlag : null}
                >
                    <Checkbox onChange={() => { this.ChangePaymentType(title) }} style={{ color: this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : color.notesTableTitle }} checked={this.state.firstPayment == title || this.state.secondPayment == title ? true : false}>{title}</Checkbox>
                </TouchableOpacity>
                <Image
                    source={img}
                    style={{ width: "2.3vw", height: "2.3vw", tintColor: this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : color.notesTableTitle  }}
                    resizeMode="contain"
                />
            </View>
        );
    };
    renderTextBoxAlone(placeholder, flexWidth = 1, center, key, value = '', index, width = '100%', border = '') {

        return (
            <View style={{ flex: flexWidth }} >

                <TextInput
                    editable={
                        // key === "discountAmount" || 
                        ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ? false : true}
                    underlineColorAndroid="transparent"
                    style={[{
                        zIndex: -1, color: "grey", borderRadius: border ? border : 10, borderColor: "grey", borderWidth: 1, width: width, fontSize: '.85vw',
                        height: (placeholder == "Card" || placeholder == "Cash") ? "2.27vw" : "1.95vw",
                        paddingLeft: 10, alignSelf: center ? 'center' : null,
                        marginLeft: placeholder == "Card" ? "1vw" : null,
                        marginRight: placeholder == "Cash" ? "1vw" : null,
                        backgroundColor:
                            // key === "discountAmount" ||
                            ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ?
                                "#F2F2F2" :
                                key === "serviceName" || key === "serviceType" || key === "serviceAmount" ?
                                    color.white :
                                    null,
                        textAlign: "right", paddingRight: "0.65vw"
                    }, Style.allButtonBorderRadius]}
                    placeholderTextColor={"grey"}
                    autoCapitalize="none"
                    value={
                        key === "discountAmount" && value == 0 ?
                            "" :
                            key === "discouont_percent" && value == 0 ?
                                "" :
                                key === "Cash" && value == 0 ?
                                    "" :
                                    key === "Card" && value == 0 ?
                                        "" : value}
                    maxLength={key === "discouont_percent" && value < 100 ? 4 : key === "discouont_percent" && value >= 100 ? 3 : null}
                    onChangeText={text => {

                        if (key === "serviceName") {
                            this.setState({
                                showListofServiceNameFlag: true
                            })
                        }

                        this.onChangeText(text, index, key)
                    }
                    }
                    onSubmitEditing={() => {
                        this.onSubmitEditing()
                    }

                    }
                />
            </View>
        );
    }

    calculate_age = (birthDate) => {
        var states = this.state.mandatoryInformation;
        var age = Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
        states['age'] = age;
        this.setState({ states })
    }

    pickerView(placeholder, width, key, value) {
        return (
            <View style={styles.pickerViewContainer}>
                <Text style={styles.pickerViewTextStyle}>{"Title"}</Text>
                <Picker
                    selectedValue={this.state.mandatoryInformation.type}
                    style={[Style.pickerView, { marginTop: ".32vw", width: "6vw" }]}
                    itemStyle={{ fontSize: "0.91vw" }}
                    onValueChange={(itemvalue) => {
                        var states = this.state.mandatoryInformation;
                        states["type"] = itemvalue;

                        if (itemvalue == "Mr") {
                            states["gender"] = "Male";
                        } else if (itemvalue == "Ms" || itemvalue == "Mrs") {
                            states["gender"] = "Female";
                        }

                        this.setState({ states });
                    }}
                >
                    <Picker.Item label="" value="" />
                    <Picker.Item label="Mr" value="Mr" />
                    <Picker.Item label="Ms" value="Ms" />
                    <Picker.Item label="Mrs" value="Mrs" />
                </Picker>
            </View>
        );
    }

    handleFindApproxDob = ageEntered => {

        var year = 0;
        var month = 0;
        var days = 0;
        var tempDate;
        var approximate_date = moment();

        // split data
        var enteredDaySplit = ageEntered.split("/");

        let yearsplit = enteredDaySplit[0] !== undefined ? enteredDaySplit[0] : ""
        // year = yearsplit.length == 0 ? undefined : (parseInt(yearsplit) || 0);
        year = parseInt(yearsplit) || 0;

        let monthsplit = enteredDaySplit[1] !== undefined ? enteredDaySplit[1] : ""
        // month = monthsplit.length == 0 ? undefined : (parseInt(monthsplit) || 0);
        month = parseInt(monthsplit) || 0;

        let daysplit = enteredDaySplit[2] !== undefined ? enteredDaySplit[2] : ""
        // days = daysplit.length == 0 ? undefined : (parseInt(daysplit) || 0); 
        days = parseInt(daysplit) || 0;

        if (!year && !month && !days) {
            tempDate = "";
        } else {

            // reset(year) year with difference year
            approximate_date = moment(approximate_date).subtract(year, 'years');

            // reset(month) month with difference month
            approximate_date = moment(approximate_date).subtract(month, 'months');

            // reset(days) days with difference days
            approximate_date = moment(approximate_date).subtract(days, 'days');

            tempDate = moment(approximate_date).format("DD-MM-YYYY");

        }

        return tempDate;
    }

    getDoctor = () => {
        var serviceUrl =
            Constants.HOME_GET_CLINIC_DOCTORS;

        Service.getInstance().getClinicList(
            serviceUrl,
            this,
            this.getDoctorSuccess,
            this.getDoctorFailure
        );
    };
    getBillingFrequentlyUsedService() {
        var service_url
        if (this.state.userType == Constants.ROLE_FO) {
            service_url = Constants.CLINIC_OP_SERVICE + "?service_name=" + this.state.ServiceNameSearchKey ;
        } else {
            service_url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id  + "&service_name=" + this.state.ServiceNameSearchKey;
        }
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getBillingFrequentlyUsedServiceSuccess,
            this.getBillingFrequentlyUsedServiceFailure
        );
    }
    getBillingFrequentlyUsedServiceSuccess = success => {
        success.data.sort((a, b) => {
            if (a.priority && b.priority) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
            } else {
                if (a.priority)
                    return -1;
                else
                    return 1;
            }

            return 0;
        });
        this.setState({
            showServiceNameAndTypeNewOption: success.data.new_button_visible,
            billingService: success.data,
        })
    }
    getBillingFrequentlyUsedServiceFailure = error => {
        console.log("FREQUENT ERROR");
     }

    // show  right side ServiceName buttons
    renderServiceNameButtons = (items, index) => {
        return (
            <TouchableOpacity onPress={() => {
                this.selectBillingButton(items)
            }} key={index} style={[{
                marginBottom: '1vw',
                // borderRadius: 30,
                backgroundColor: this.state.selectedBillingServiceName === items.service_name ? color.themeDark : null,
                borderColor: this.state.selectedBillingServiceName == items.service_name ? color.themeDark : color.textBoxBorderColor,
                borderWidth: '.1vw',
                paddingLeft: '1vw',
                paddingRight: '1vw',
                paddingTop: '.3vw',
                paddingBottom: '.3vw',
                marginLeft: '.65vw'
            }, Style.allButtonBorderRadius]}>
                <Text style={{
                    fontSize: ".75vw",
                    color: this.state.selectedBillingServiceName === items.service_name ? color.white : color.black
                }}>{items.priority ? items.priority === 1 ? items.service_name : items.priority === 2 ? items.service_name : items.priority === 3 ? items.service_name : items.service_name : items.service_name}</Text>
            </TouchableOpacity>
        );
    }
    selectBillingTypeButton(items) {
        var selectedAmount = 0
        var selectedType = ""
        for (let i = 0; i < this.state.billingServiceType.length; i++) {
            if (this.state.billingServiceType[i].id != null) {
                if (this.state.billingServiceType[i].id === items.id) {
                    selectedAmount = items.amount
                    selectedType = items
                    // amount.push(serviceType[i].amount)
                }
            }
        }

        this.setState({
            selectedBillingServiceAmount: selectedAmount,
            selectedBillingServiceType: selectedType
        })
    }
    selectBillingButton(items) {
        if (items.data != null && items.data.length > 0) {
            var serviceType = this.removeDuplicate(items.data)
            var type = []
            var amount = []


            if (serviceType.length > 0) {
                for (let i = 0; i < serviceType.length; i++) {
                    if (serviceType[i].type != null) {
                        //type.push({'type': serviceType[i].type, 'id': serviceType[i].s_no, 'amount' : serviceType[i].amount})
                        type.push(serviceType[i])
                        amount.push(serviceType[i].amount)
                    }

                }
                var billingServiceType = this.removeDuplicate(type);
                var billingServiceAmount = this.removeDuplicate(amount)
                var hasServiceTypeView = billingServiceType.length > 4;
            }

            this.setState({
                billingServiceType: billingServiceType,
                searchServiceTypeList: billingServiceType,
                billingServiceAmount: billingServiceAmount,
                selectedBillingServiceName: items.service_name,
                // selectedBillingServiceId: items.service_id,
                selectedBillingServiceAmount: null,
                selectedBillingServiceType: {},
                searchServiceTypeOpen: false,
                searchServiceType: "",
                searchServiceTypeViewShow: hasServiceTypeView,
            })
        } else {
            this.setState({
                selectedBillingServiceName: items.service_name,
                selectedBillingServiceId: items.service_id,
            })
        }
    }
    // show  right side ServiceType buttons
    renderServiceTypeButtons(items, index) {

        return (
            <TouchableOpacity onPress={() => {
                this.selectBillingTypeButton(items);
            }} key={index} style={[{
                marginBottom: "0.97vw",
                // borderRadius: 30,
                backgroundColor: this.state.selectedBillingServiceType.id === items.id ? color.themeDark : null,
                borderColor: color.textBoxBorderColor,
                borderWidth: 1, paddingLeft: "1.17vw", paddingRight: "1.17vw", paddingTop: "0.52vw", paddingBottom: "0.52vw", marginLeft: "0.65vw"
            }, Style.allButtonBorderRadius]}>
                <Text style={{
                    fontSize: "0.72vw",
                    color: this.state.selectedBillingServiceType.id === items.id ? color.white : color.black
                }}>{items.type}</Text>
            </TouchableOpacity>
        );
    }
    selectBillingAmountButton(items) {
        for (let i = 0; i < this.state.billingServiceAmount.length; i++) {
            if (this.state.billingServiceAmount[i].id === items.id) {
                this.setState({
                    selectedBillingServiceAmount: items
                })
            }
        }
    }
    // show  right side ServiceAmount buttons
    renderServiceAmountButtons(items, index) {
        return (
            <TouchableOpacity onPress={() => {
                // this.selectBillingAmountButton(items);
            }} key={index} style={[{
                marginBottom: "1vw",
                // borderRadius: 30,
                backgroundColor: this.state.selectedBillingServiceAmount == items ? color.themeDark : null,
                borderColor: this.state.selectedBillingServiceAmount == items ? color.themeDark : color.textBoxBorderColor,
                borderWidth: 1, paddingLeft: "1.17vw", paddingRight: "1.17vw", paddingTop: "0.52vw", paddingBottom: "0.52vw", marginLeft: "0.65vw"
            }, Style.allButtonBorderRadius]}>
                <Text style={{
                    fontSize: "0.72vw",
                    color: this.state.selectedBillingServiceAmount == items ? color.white : null
                }}>{items}</Text>
            </TouchableOpacity>
        );
    }
    // Add Billing For Left Side View
    addBilling() {
        var bill = {
            "service_id": this.state.selectedBillingServiceId,
            "amount": this.state.selectedBillingServiceAmount,
            "name": this.state.selectedBillingServiceName,
            "description": this.state.selectedBillingServiceType.type,
        }
        var { Billing, selectedBillingServiceName, selectedBillingServiceAmount, selectedBillingServiceType } = this.state;


        if ((selectedBillingServiceAmount || selectedBillingServiceAmount == 0) && selectedBillingServiceName && selectedBillingServiceType.type) {
            Billing.push(bill);
            this.setState({
                Billing
            }, () => {
                this.addBillingList()         // for Clear the right side selected values
                this.calculateTotalAmount();
            })
        } else {
            this.showResposeValue("error", Messages.NoServiceType)
        }



    }
    addBillingList() {      // Right side clear the All selected value 
        this.setState({
            selectedBillingServiceName: "",
            selectedBillingServiceId: "",
            selectedBillingServiceType: {},
            selectedBillingServiceAmount: null,
            searchServiceType: "",
            ServiceNameSearchKey :"",
            billingServiceType: [],
            searchServiceTypeViewShow : false
        }, ()=> this.getBillingFrequentlyUsedService())
    }
    calculateTotalAmount() {
        var totalAmount = this.state.Billing.reduce((sum, tax) => sum + (parseFloat(tax.amount) || 0), 0)
        if (this.state.discount_percent == "0.00") {
            this.setState({
                discount_percent: 0
            })
        }

        this.setState({ totalAmount: totalAmount, firstAmount: totalAmount, amount_invoice : totalAmount, discount_percent : 0, secondAmount : "" }, () => {
            this.calculateDiscountAmount()
            // this.onChangePaymentType()
        });

    }
    calculateDiscountAmount = () => {

        let { is_disc_percent_changed, discount, discount_percent } = this.state;

        if ((this.state.discount_percent > 0 || this.state.discount_percent == "") && this.state.totalAmount > 0) {
            var discount_per = this.state.discount_percent == "" ? 0 : this.state.discount_percent

            var amount = parseFloat(discount);

            if (is_disc_percent_changed) {
                let temp_discount_percent = parseFloat(discount_per)

                amount = ((this.state.totalAmount / 100) * temp_discount_percent).toFixed(2);
                amount = parseFloat(amount)
            } else {
                discount_percent = ((amount / this.state.totalAmount) * 100).toFixed(2);
            }

            // this.state.discount = amount.toFixed(2)
            this.setState({
                // discount: amount.toFixed(2)
                discount: amount,
                discount_percent: discount_percent
            }, () => {



                var amount_invoice = this.state.totalAmount - amount;
                var round_off = (amount_invoice % 1).toFixed(2);
                var round = amount_invoice / 1;

                if (this.state.paymentType === "Cash" || this.state.paymentType === "Card" || this.state.paymentType === "Cash and Card" ||
                    this.state.paymentType === "UPI" || this.state.paymentType === "Bank Transfer" || this.state.paymentType === "") {
                    var amount_invoice = this.state.totalAmount - amount;
                    var round_off = (amount_invoice % 1).toFixed(2);
                    var round = amount_invoice / 1;
                    var amount_invoice;
                    if (parseFloat(round_off) >= 0.5) {
                        amount_invoice = parseInt(round) + 1;
                        round_off = "+" + (1 - parseFloat(round_off))
                    } else if (parseFloat(round_off) < 0.5) {
                        amount_invoice = parseInt(round);
                        round_off = "-" + (round_off)
                    }
                    this.setState({
                        round_off: parseFloat(round_off),
                        amount_net: amount_invoice,
                        amount_invoice: amount_invoice
                        // amount_net :this.state.totalAmount

                    }, () => {
                        this.onChangePaymentType()
                    })
                }

                // this.state.amount_invoice = amount_invoice

                this.setState({
                    amount_invoice: amount_invoice,
                    firstAmount : amount_invoice,
                    secondAmount : ""
                    // amount_net :this.state.totalAmount
                }, () => {
                    this.onChangePaymentType()
                })
            })
        } else {

            // alert("dis amount is 0---- total amount is " +this.state.totalAmount)
            // this.state.discount = 0,
            this.setState({
                round_off: 0,
                discount: 0,
                amount_net: this.state.totalAmount
            }, () => {
                this.onChangePaymentType()
            })
        }

    }
    onChangePaymentType() {
        // alert("payment type"+this.state.paymentType);

        let states = this.state;
        // let statesObj = JSON.parse(JSON.stringify(states));

        if (this.state.paymentType == "Card" ||
            this.state.paymentType === "UPI" || this.state.paymentType === "Bank Transfer") {
            var amount_net = this.state.amount_net > 0 ? this.state.amount_net : 0;

            this.setState({
                cardTextBoxEnable: true,
                cashTextBoxEnable: true,
                amount_card: amount_net,
                amount_cash: 0
            }, () => {
                //  this.calculateDiscountAmount()
            })
        } else if (this.state.paymentType == "Cash" || this.state.paymentType == "Cash and Card") {
            // alert("net amount"+this.state.amount_net);
            var amount_net = parseFloat(this.state.amount_net) > 0 ? this.state.amount_net : 0;

            // statesObj['cardTextBoxEnable'] = true;
            // statesObj['cashTextBoxEnable'] = true;
            // statesObj['amount_card'] = 0;
            // statesObj['amount_cash'] = amount_net;

            this.setState({
                // statesObj
                cardTextBoxEnable: true,
                cashTextBoxEnable: true,
                amount_card: 0,
                amount_cash: amount_net,
            }, () => {
                // this.calculateDiscountAmount() 
            })
        } else if (this.state.paymentType == "Cash and Card") {

            this.setState({
                cardTextBoxEnable: false,
                cashTextBoxEnable: false,

                // amount_card: "",
                // amount_cash: "",
            }, () => {
                // this.calculateDiscountAmount() 
            })
        }
    }
    getDoctorSuccess = response => {
        if (response.detail) {
            this._logoutFunction();
        } else {

            var tempList = [];

            response.data && response.data.length > 0 && response.data.map((item) => {
                tempList.push({ id: item.doctor_id, value: item.first_name + " " + item.initial })
            });

            var fields = this.state;
            fields["doctorData"] = tempList;

            if (response.data.length > 0) {
                if (this.state.selectedDoctor.id) {
                    fields["selectedDoctor"] = this.state.selectedDoctor;
                } else {
                    fields["selectedDoctor"] = tempList[0];
                }
            }

            this.setState({
                fields
            });
        }
    };

    getDoctorFailure = error => {
        console.log(error);
    };

    numberValidationWithoutDot(num) {
        return /^[0-9]*$/.test(num);
    }

    onChangeText = (value, index, key) => {

        if (key === "name" || key === "description" || key === "amount") {
            if (key === "name") {
                this.setState({
                    showListofServiceNameIndex: index
                })
                this.getListOfBillingTypes(value)
            }
            let { Billing } = this.state;
            Billing[index][key] = value;

            this.setState({
                Billing: JSON.parse(JSON.stringify(Billing))
            },
                () => {
                    if (key === "amount") {
                        this.calculateTotalAmount();
                    }
                }
            )


        }else if(key == "upiTransactionID" || key == "bank_transaction_id" || key == "Cheque" || key == "CardNumber"){
            var states = this.state;
            if(this.numberValidation(value) && this.numberValidationWithoutDot(value)){
            states[key] = value;
            this.setState({ states })
            }
          }
           else if (key === "discouont_percent") {
            this.setState({
                discount_percent: parseInt(value) <= 100 ?  parseInt(value) : value == "" ? "" : this.state.discount_percent,
                is_disc_percent_changed: true
            }, () => {
                this.calculateDiscountAmount()
            })
        } else if (key === "Cash") {


            if (parseFloat(this.state.amount_net) >= parseFloat(value)) {

                var amount_Card = this.state.amount_net - value
                this.setState({
                    amount_cash: value,
                    amount_card: amount_Card,
                }, () => {
                    // this.calculateDiscountAmount() 
                })
            }

        } else if (key === "Card") {

            // var amount_net = this.state.amount_net
            if (parseFloat(this.state.amount_net) >= value) {
                var amount_Cash = this.state.amount_net - value
                this.setState({
                    amount_card: value,
                    amount_cash: amount_Cash
                }, () => {
                    // this.calculateDiscountAmount() 
                })
            }
        } else if (key === "serviceName") {
            this.setState({
                service: value
            });
            this.getListOfBillingTypes(value)
        } else if (key === "serviceType") {
            this.setState({
                types: value
            });
        } else if (key === "serviceAmount") {
            if (isNaN(value)) {
                // do nothing
            } else {
                this.setState({
                    amounts: value
                });
            }

        }
        else if (key === "discountAmount") {
            var data = 0
            if (value) {
                data = ((value / this.state.totalAmount) * 100).toFixed(2);
            } else {
                data = 0;
            }
            this.setState({
                discount: value ? value <= this.state.totalAmount ? value : this.state.discount : 0,
                discount_percent: data,
                is_disc_percent_changed: false
            }, () => {
                this.calculateDiscountAmount()
            })
        } else if( key === "firstAmount"){
            if(this.state.amount_net >= value && (this.numberValidationWithoutDot(value)|| value == "")){
                var secondAmount = this.state.amount_net - value
                this.setState({
                  firstAmount : value,
                  secondAmount : secondAmount
                })
              }
          }else if(key === "secondAmount"){
            if(this.state.amount_net >= value && (this.numberValidationWithoutDot(value) || value == "")){
                var firstAmount = this.state.amount_net - value
                this.setState({
                  secondAmount : value,
                  firstAmount : firstAmount
                })
              }
          }
        else {
            var states = this.state;
            states[key] = value;
            this.setState({ states })
        }

    }
    paymentType(type) {
        if (this.state.paymentType == type) {
            this.setState({
                paymentType: ""
            })
        } else if (type === "Card") {
            this.setState({
                paymentType: "Card",
            }, () => {
                this.calculateDiscountAmount()
                // this.onChangePaymentType() 
            })
        } else if (type === "Cash") {

            this.setState({
                paymentType: "Cash",
            }, () => {
                this.calculateDiscountAmount()
                // this.onChangePaymentType() 
            })
        } else if (type === "Cash and Card") {

            this.setState({
                paymentType: "Cash and Card"
            }, () => {
                this.calculateDiscountAmount()
                // this.onChangePaymentType() 
            })
        } else if (type === "UPI") {
            this.setState({
                paymentType: "UPI"
            })
        } else if (type === "Bank Transfer") {
            this.setState({
                paymentType: "Bank Transfer"
            })
        }
    }
    // BILLING_SCREEN_SAVE
    saveBillingDetails() {

        var service = []
        let { Billing } = this.state

        for (var i = 0; i < Billing.length; i++) {
            var amount = parseFloat(Billing[i].amount).toFixed(2)
            var data2 = {
                "service_id": Billing[i].service_id,
                "name": Billing[i].name,
                "type": Billing[i].description,
                "amount": parseFloat(amount)
            }
            if (Billing[i].id) {
                data2["item_id"] = Billing[i].id
            }
            service.push(data2);
        }

        // var dt = moment(this.state.billingDate.YYYY-MM-DD).format("YYYY-MM-DD");
        // var dt = moment(this.state.billingDate, "DD-MM-YYYY");
        // var dt1 = moment(dt).format("YYYY-MM-DD");
        var states = this.state
        let amountGross = parseFloat(this.state.totalAmount).toFixed(2)
        var transaction_num_1 = ""
        var transaction_num_2 = ""
        transaction_num_1 = states.firstPayment === "Card" ? states.CardNumber : states.firstPayment === "UPI" ? states.upiTransactionID : states.firstPayment === "Bank Transfer" ? states.bank_transaction_id : states.firstPayment === "Cheque" ? states.Cheque : ""
        transaction_num_2 = states.secondPayment === "Card" ? states.CardNumber : states.secondPayment === "UPI" ? states.upiTransactionID : states.secondPayment === "Bank Transfer" ? states.bank_transaction_id : states.secondPayment === "Cheque" ? states.Cheque : ""
        var PaymentTypeData = returnPaymentMode(this.state.firstPayment + this.state.secondPayment, this.state.firstAmount, this.state.secondAmount, transaction_num_1, transaction_num_2)
        var data = {
            "invoice":
            {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "amount_gross": parseFloat(amountGross), //-- Total Amount
                "discount_percent": this.state.discount_percent ? (parseFloat(this.state.discount_percent) || 0) : 0,
                "discount": parseFloat(this.state.discount) || 0,
                "amount_invoice": this.state.amount_invoice ? this.state.amount_net : 0, // -- After Discount
                "amount_net": this.state.amount_net,// -- After Round Off
                "payment_type": PaymentTypeData.paymentMode,
                "amount_1":parseFloat(PaymentTypeData.paymentAmt1),
                "amount_2" :parseFloat(PaymentTypeData.paymentAmt2),
                "transaction_id_1" : PaymentTypeData.trans_Num1,
                "transaction_id_2" : PaymentTypeData.trans_Num2,
                "round_off": (this.state.round_off.toFixed(2)),
                "invoice_date": this.state.apmtCreationData.appointment_date.toString(),
                // "invoice_date":this.state.billingDate,
            },
            "services": service,
            "status": "saved",
            "bill_id": this.state.invoiceID ? this.state.invoiceID : "",
            "removable_ids": this.state.invoiceID ? this.state.removableSavedBillID : [],
            "invoice_number": this.state.invoiceNumber,
            "invoice_sequence": this.state.invoiceSequence,
            // "amount_cash": this.state.paymentType == "UPI" || this.state.paymentType == "Bank Transfer" ?
            //         0
            //         : this.state.amount_cash ? this.state.amount_cash : 0,
            // "amount_card": this.state.paymentType == "UPI" || this.state.paymentType == "Bank Transfer" ?
            //         0
            //         : this.state.amount_card ? this.state.amount_card : 0,
        }

        if (this.state.Billing.length > 0) {
            this.setState({ isDisabled: true })
            var service_url = Constants.BILLING_INVOICE;
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveBillingDetailsSuccess,
                this.saveBillingDetailsFailure
            );
        } else {
            this.showResposeValue("error", Messages.NoBills)
            this.setState({ isCreatePatientClicked: false })
        }
    }
    saveBillingDetailsSuccess = response => {

        // "data":{"invoice_number":"SNRARA2000106"}
        if (response.status == "success") {
            if (!localStorage.getItem('isQuickBillingScreenSuccess')) {
                localStorage.setItem('isQuickBillingScreenSuccess', "success");
            }
            if (response.data && response.data.invoice_id) {

                this.setState({
                    invoice_id: response.data.invoice_id,
                    enablePrint: true,

                })
            }
            this.clearBillingDetails()
            this.setState({ isDisabled: false })
            this.setState({ successOrErrorMessage: response.message, successMessageFlag: true, errorMessageFlag: false });

            setTimeout(() => {
                this.setState({ successOrErrorMessage: "", successMessageFlag: false, errorMessageFlag: false });
            }, 2000);
        } else {
            this.showResposeValue("error", response.message)
            this.setState({ isDisabled: false })
        }
        // this.getBillingList();
    }

    saveBillingDetailsFailure = error => {
        this.showResposeValue("error", error.message)
        this.setState({ isDisabled: false })
    }

    printBillingDetails() {

        let { invoice_id, patientId, userType } = this.state;
        let serviceUrl = "";

        if (userType === Constants.ROLE_FO) {
            serviceUrl = Constants.FO_PRINT_BILL + "?invoice_id=" + invoice_id + "&patient_id=" + patientId;
        } else {
            serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + invoice_id + "&patient_id=" + patientId + "&clinic_id=" + this.state.selectedClinicOrDoctor.id;
        }

        if (serviceUrl) {

            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                // this,
                this.printBillSuccess,
                this.printBillFailure,
                "pdf"
            );
        }
    };

    printBillSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    }
    printBillFailure = error => {
        //alert(JSON.stringify(error))
    }
    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }
    
    successAlert() {
        return (
            <View style={styles.alertContainer}>
                <View style={styles.alertWrapper}>
                    <Success size={"1.62vw"} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: ".8vw", marginLeft: "1vw" }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: ".8vw" }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: "1vw", alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={"1.62vw"} name={"close"} color={"green"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={[styles.alertContainer, { width: "35%", backgroundColor: "#CD3A34", borderColor: "#A58B8A" }]}>
                <View style={styles.alertWrapper}>
                    <Icon size={"1.62vw"} name={"ban"} color={"#fff"} />
                    <Text style={{ fontWeight: "700", color: "#fff", fontSize: "0.78vw", marginLeft: "1vw" }}>{"ERROR: "}
                        <Text style={{ fontWeight: "400", color: "#fff", fontSize: "0.78vw" }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: "1vw", alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={"1.62vw"} name={"close"} color={"#FFF"} />
                </TouchableOpacity>
            </View>
        );
    }

    clearBillingDetails() {

        var states = this.state;

        states["firstPayment"] = "",
        states["secondPayment"] = "",
        states["firstAmount"] = "",
        states["secondAmount"] = "",
        states["Cheque"] = "",
        states["CardNumber"] = "",
        states["discount_percent"] = 0;
        states["discount"] = 0;
        states["round_off"] = 0;
        states["Billing"] = [];
        states["paymentType"] = ""
        states["upiTransactionID"] = ""
        states["bank_transaction_id"] = ""
        states["amount_cash"] = 0;
        states["amount_card"] = 0;
        states["amount_invoice"] = 0;
        states["invoiceNumber"] = ""
        states["invoiceSequence"] = ""
        states["invoiceID"] = ""

        this.setState({
            // Billing: [],
            states
            // totalAmount: 0,    //amount_gross
            // paymentType: "Card",
            // discount_percent: "",
            // discount: "",
            // amount_invoice: 0, //After Discount
            // amount_net: 0,     //After Round Off
            // amount_cash: "",
            // amount_card: "",
            // round_off: 0,
            // invoice_date: "",

        }, () => {
            this.calculateTotalAmount()
        })
    }
    onSubmitEditing() {
        // if (key === "serviceType") {
        //   var { billingServiceType } = this.state;
        //   this.state.billingServiceType.push(value);
        //   var serviceType = this.removeDuplicate(billingServiceType);
        //   this.setState({
        //     selectedBillingServiceType: value,
        //     showServiceTypeNewButton: true,
        //     billingServiceType: serviceType,
        //     types: ""
        //   })
        // } else if (key === "serviceAmount") {
        //   var { billingServiceAmount } = this.state;
        //   this.state.billingServiceAmount.push(value);
        //   var serviceAmount = this.removeDuplicate(billingServiceAmount);
        //   this.setState({
        //     selectedBillingServiceAmount: value,
        //     showServiceAmountNewButton: true,
        //     billingServiceAmount: serviceAmount,
        //     amounts: ""
        //   })
        // } else if (key === "serviceName") {
        //   var { billingServiceName } = this.state;
        //   this.state.billingServiceName.push(value);
        //   var serviceName = this.removeDuplicate(billingServiceName);
        //   this.setState({
        //     selectedBillingServiceName: value,
        //     showServiceAmountNewButton: true,
        //     billingServiceAmount: serviceName,
        //     service: ""
        //   })
        // }



        if (this.state.types) {
            var { billingServiceType } = this.state;

            billingServiceType.push({ "type": this.state.types, "amount": 0, "s_no": "" });
            var serviceType = this.removeDuplicate(billingServiceType);
            this.setState({
                selectedBillingServiceType: { "type": this.state.types, "amount": "", "s_no": "" },
                showServiceTypeNewButton: true,
                billingServiceType: serviceType,
                types: ""
            })
            // } else if(!this.state.showServiceTypeNewButton){
            //   this.setState({
            //     showServiceTypeNewButton: true,
            //   })
            // }else{
            //   this.setState({
            //     showServiceTypeNewButton: true,
            //   })
        }
        // else 
        if (this.state.amounts) {
            var { billingServiceAmount } = this.state;
            this.state.billingServiceAmount.push(this.state.amounts);
            var serviceAmount = this.removeDuplicate(billingServiceAmount);
            this.setState({
                selectedBillingServiceAmount: this.state.amounts,
                showServiceAmountNewButton: true,
                billingServiceAmount: serviceAmount,
                amounts: 0
            })
            // } else if(!this.state.showServiceAmountNewButton){
            //   this.setState({
            //     showServiceAmountNewButton: true,
            //   })
            // }else {
            //   this.setState({
            //     showServiceAmountNewButton: true,
            //   })
        }
        // else 
        if (this.state.service) {
            var { billingService } = this.state;
            billingService.push({ 'service_name': this.state.service, 'service_id': '', data: [] });
            var serviceName = this.removeDuplicate(billingService);
            this.setState({
                selectedBillingServiceName: this.state.service,
                selectedBillingServiceId: "",
                showServiceNameNewButton: true,
                billingService: serviceName,
                service: ""
            })
            // } else if(!this.state.showServiceNameNewButton){
            //   this.setState({
            //     showServiceNameNewButton: true,
            //   })
            // }else {
            //   this.setState({
            //     showServiceNameNewButton: true,
            //   })
        }



    }

    async changePickerDropDownValue(flag, selectedValue, pickerKey) {
        var states = this.state;

        if (pickerKey == "FO") {
            states["showPickerDropDownFlag"] = flag
            if (selectedValue) {
                await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
                states['selectedDoctor'] = selectedValue
                states['selectedClinicOrDoctor'].id = selectedValue.id;
                states['selectedClinicOrDoctor'].value = selectedValue.value
            }
        } else if (pickerKey == "Nurse") {
            var states = this.state;
            states["showNursedata"] = flag

            if (selectedValue) {
                states['selectedNurseTag'] = selectedValue
            }
        }
        this.setState({
            states
        }, () => {
            this.getBillingFrequentlyUsedService()
        })
    }
    renderNurseList() {
        return (
            <View style={{ marginTop: ".65vw", zIndex: 1 }}>
                <CommonPicker
                    showPickerDropDownFlag={this.state.showNursedata}
                    changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                    selectedValue={this.state.selectedNurseTag}
                    arrayOfData={this.state.staffData}
                    pickerKey="Nurse"
                />
            </View>
        )
    }
    getStaffList = () => {
        var serviceUrl =
            Constants.HOME_GET_CLINIC_STAFFS;

        Service.getInstance().getClinicList(
            serviceUrl,
            this,
            this.getStaffListSuccess,
            this.getStaffListFailure
        );
    };

    getStaffListSuccess = async (response) => {
        let tempList = [];
        response.data && response.data.length > 0 && response.data.map((item) => {
            tempList.push({ id: item.staff_id, value: item.first_name })
        });

        this.setState({
            ["staffData"]: tempList
        });
    };

    getStaffListFailure = error => {
        //console.log("Get doctor list error response");
        console.log(error);
    };
    changeTextBoxValues(value, key) {
        var states = this.state
        states[key] = value
        this.setState({ states })
    }
    renderReferredTextBox(placehold, value, key) {
        return (
            <View style={styles.referredTextBoxContainer}>
                <Text style={styles.referredTextBoxText}>{placehold}</Text>
                <TextInput
                    placeholder={""} value={value}
                    onChangeText={(text) => { this.changeTextBoxValues(text, key) }}
                    style={styles.referredTextBoxTextInput}
                />
            </View>
        )
    }

    renderPayment = (paymentType) => {
        if (paymentType === "Card") {
          return (
            <View>
              {this.renderBillinTextBox("Card Transaction ID", "90%", false, "CardNumber", this.state.CardNumber, -1)}
            </View>
          )
        }
        else if (paymentType === "UPI") {
          return (
            <View style={{ flexDirection: "row" }}>
              {this.renderBillinTextBox("UPI Transaction ID", "90%", false, "upiTransactionID", this.state.upiTransactionID, -1,)}
            </View>
          )
        }
        else if (paymentType === "Bank Transfer") {
          return (
            <View style={{ flexDirection: "row" }}>
              {this.renderBillinTextBox("Bank Transaction ID", "90%", false, "bank_transaction_id", this.state.bank_transaction_id, -1,)}
            </View>
          )
        }
        else if(paymentType === "Cheque"){
          return(
            <View>
              {this.renderBillinTextBox("Cheque Number", "90%", false, "Cheque", this.state.Cheque, -1,)}
            </View>
          )
        } else {
          return null
        }
      }

      clearDetails = () =>{
        this.setState({
          Cheque:"",
          firstPayment : "",
          secondPayment : "",
          firstAmount : "",
          secondAmount : "",
          CardNumber : "",
          upiTransactionID: "",
          bank_transaction_id: "",
        })
      }
    
      clearSinglePayment = (item) =>{
        if(item === "Card"){
          this.setState({
            CardNumber : "",
          })
        }else if(item === "UPI"){
          this.setState({
            upiTransactionID: ""
          })
        }else if(item === "Bank Transfer"){
          this.setState({
            bank_transaction_id : ""
          })
        }else if(item === "Cheque"){
          this.setState({
            Cheque : ""
          })
        }
        this.setState({
          secondAmount : ""
        })
      }

      ChangePaymentType = (item) => {
        if (this.state.firstPayment == "" && this.state.secondPayment == "") {
          if (this.state.secondPayment != item) {
            this.setState({
              firstPayment: item
            })
          } else {
            this.setState({
              secondPayment: ""
            }, () => {
              this.clearSinglePayment(item)
            })
          }
        } else if (this.state.firstPayment != "" && this.state.secondPayment == "") {
          if (this.state.firstPayment != item) {
            this.setState({
              secondPayment: item
            })
          } else {
            this.setState({
              firstPayment: ""
            }, () =>{
              this.clearDetails()
            })
          }
        } else if (this.state.firstPayment != "" && this.state.secondPayment != "") {
          if (this.state.firstPayment == item) {
            this.setState({
              firstPayment: this.state.secondPayment,
              secondPayment:""
            }, () => {
              this.clearSinglePayment(item)
            })
          } else if (this.state.secondPayment == item) {
            this.setState({
              secondPayment: ""
            }, () => {
              this.clearSinglePayment(item)
            })
          } else {
            var tem_Type = this.state.secondPayment
            this.setState({
              secondPayment : this.state.firstPayment,
              firstPayment: item
            }, () => {
              this.clearSinglePayment(tem_Type)
            })
          }
        }
        this.setState({
          firstAmount : this.state.amount_net,
          secondAmount : ""
        })
      }

    renderBillinTextBox(placeholder, width, center, key, value = '', index, border = '', icon) {

        return (
            <View>
                {placeholder ? <Text style={styles.BillingTextBoxPlaceHolder}>{placeholder}</Text> : null}
                <View>
                    {/* <Image source={img} style={{height:10,width:10}}/> */}

                    <TextInput
                        editable={
                            key == "upiTransactionID" || key == "bank_transaction_id" || key === "firstAmount" || key === "secondAmount" || key === "CardNumber" || key === "Cheque"  ? true :
                                key === "discountAmount" ||
                                    ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ||
                                    (this.state.paymentType !== "Cash and Card" && key !== "serviceName" && key !== "serviceType" && key !== "serviceAmount")


                                    ? false : true}
                        maxLength={key === "CardNumber" ? 50 : key === "Cheque" ? 15 : null}
                        // multiline={true}
                        // placeholder={key === "discountAmount" && value === 0 ? placeholder : key === "discouont_percent" && value === 0 ? placeholder : placeholder}
                        underlineColorAndroid="transparent"
                        style={[{
                            zIndex: -1, color: "grey", fontSize: '.85vw', borderRadius: border ? border : 10, borderColor: "grey", borderWidth: 1, width: width, height: (placeholder == "Card" || placeholder == "Cash") ? "2.25vw" : "1.95vw", paddingLeft: "0.65vw", alignSelf: center ? 'center' : null, marginLeft: placeholder == "Card" ? "1vw" : null, marginRight: placeholder == "Cash" ? "1vw" : null,
                            backgroundColor:
                                key === "discountAmount" || ((this.state.paymentType === "Card" || this.state.paymentType === "Cash") && (placeholder === "Card" || placeholder === "Cash")) ?
                                    "#F2F2F2" :
                                    key === "serviceName" || key === "serviceType" || key === "serviceAmount" ?
                                        color.white :
                                        null
                        }, Style.allButtonBorderRadius]}
                        placeholderTextColor={"grey"}
                        autoCapitalize="none"
                        value={
                            key === "discountAmount" && value == 0 ?
                                "" :
                                key === "discouont_percent" && value == 0 ?
                                    "" :
                                    key === "Cash" && value == 0 ?
                                        "" :
                                        key === "Card" && value == 0 ?
                                            "" : value}
                        onChangeText={text => {

                            if (key === "serviceName") {
                                this.setState({
                                    showListofServiceNameFlag: true
                                })
                            }

                            this.onChangeText(text, index, key)
                        }
                        }
                        onSubmitEditing={() => {
                            // this.onSubmitEditing.bind(this, key, this.state.service)
                            this.onSubmitEditing()
                        }

                        }
                    // }}
                    />
                </View>
                <View style={styles.billingTextBoxServiceName}>
                    <ScrollView>
                        {
                            key === "serviceName" && this.state.showListofServiceNameFlag ?
                                this.state.showListofServiceName.map((service, inde) => {
                                    return (<TouchableOpacity
                                        key={inde}
                                        style={{ padding: ".65vw" }}
                                        onPress={() => {

                                            var { billingService } = this.state;


                                            this.state.favoriteServiceName.push(service);

                                            billingService.push({ 'service_name': service.service_name, 'service_id': service.service_id, data: service.data })

                                            var name = this.removeDuplicate(billingService);

                                            this.setState({
                                                selectedBillingServiceName: service.service_name,
                                                selectedBillingServiceId: service.service_id,
                                                billingService: name,

                                                showListofServiceNameFlag: false,
                                                showServiceNameNewButton: true,
                                                service: "",
                                                // selectedService:service
                                            }),
                                                this.selectBillingButton(service)

                                        }}
                                    ><Text style={{ color: color.white }}>{service.service_name}</Text></TouchableOpacity>)
                                })
                                : null
                        }
                    </ScrollView>
                </View>
            </View>
        );
    }
    renderBillingDescriptionSuggesstion() {
        return (
            this.state.searchServiceTypeOpen ? <View style={styles.searchServiceTypeView}>
                {this.state.searchServiceTypeList.length > 0 ?
                    <ScrollView
                        style={styles.searchServiceTypeScrollView}
                    >
                        {this.state.searchServiceTypeList.map((items, index) =>
                            <View style={{ height: "1.95vw" }}>
                                <TouchableOpacity key={index}
                                    style={{ marginLeft: ".32vw", marginTop: ".32vw" }}
                                    onPress={
                                        event => {

                                            var selectedAmount = 0
                                            var selectedType = ""
                                            for (let i = 0; i < this.state.searchServiceTypeList.length; i++) {
                                                if (this.state.searchServiceTypeList[i].id === items.id) {
                                                    selectedAmount = items.amount
                                                    selectedType = items
                                                    break;
                                                    // amount.push(serviceType[i].amount)
                                                }
                                            }
                                            this.setState({
                                                selectedBillingServiceAmount: selectedAmount,
                                                selectedBillingServiceType: selectedType,
                                                searchServiceTypeOpen: false,
                                                searchServiceType: selectedType.type,
                                                showServiceTypeBottomLayout: false,
                                            })

                                            // let { enterRatePerUnits, enterUnits, searchDescription, searchDescriptionOpen, selectedDescription, showDescriptionBottomLayout } = this.state;
                                            // searchDescription = value.service_name;
                                            // searchDescriptionOpen = false;
                                            // showDescriptionBottomLayout = false;
                                            // var selectedData = {}
                                            // selectedData = { value: value.service_name, label: value.service_name, type: "" }
                                            // if (value.service_name == "Advance payment" || value.service_name == "Bed Charges") {
                                            //   enterRatePerUnits = 0;
                                            //   enterUnits = 0
                                            // } else {
                                            //   enterRatePerUnits = value.rate_per_unit;
                                            //   enterUnits = this.state.enterUnits
                                            // }
                                            // selectedDescription = selectedData;
                                            // this.setState({
                                            //   selectedDescription,
                                            //   searchDescription,
                                            //   searchDescriptionOpen,
                                            //   showDescriptionBottomLayout,
                                            //   enterRatePerUnits,
                                            //   enterUnits
                                            // }, () => {
                                            //   this.calculateTotalAmount()
                                            // })

                                        }
                                    }
                                >
                                    <Text>{items.type}</Text>

                                </TouchableOpacity>
                            </View>
                        )}

                    </ScrollView> : null

                }

            </View> : null

        )
    }
    // Add Button View
    renderAddButton = (btnName, type) => {
        return (
            <TouchableOpacity onPress={() => { this.addBilling() }}
                style={[{ backgroundColor: color.themeDark, width: "9.75vw", alignSelf: 'center', alignContent: 'center', paddingVertical: ".3vw" }, Style.allButtonBorderRadius]}
            >
                <Text
                    style={styles.addButtonTextStyle}
                >
                    {btnName}
                </Text>
            </TouchableOpacity>
            // </View>
        );
    };
    renderItem = (item, index) => {
        return (

            <View style={styles.renderItemContainer} key={index}>
                <View style={{ flex: 0.35, zIndex: -10 }}>
                    {/* <View style={{ width: "85%", paddingLeft: 15 }}> */}
                    <Text style={{ fontSize: ".9vw" }}>{item.name}</Text>
                    {/* </View> */}
                </View>
                <View style={{ flex: 0.25 }}>
                    {/* <View style={{ width: "85%" }}> */}
                    <Text style={{ fontSize: ".9vw" }}>{item.description}</Text>
                    {/* </View> */}
                </View>
                <View style={{ flex: 0.23 }}>
                    {/* <View style={{ width: "60%" }}> */}

                    {/* <TextInput
                        style={{ marginHorizontal: 25, }}
                        keyboardType="numeric"
                        value={item.amount}
                        onChangeText={text => {
                            var { Billing } = this.state
                            Billing[index].amount = text;
                            this.setState({ Billing }, () => {
                                this.calculateTotalAmount();
                            })
                        }
                        } /> */}

                    <Text style={{ fontSize: ".9vw" }}>{item.amount}</Text>
                    {/* </View> */}
                </View>
                <View style={{ flex: 0.17, flexDirection: 'row', justifyContent: 'space-around', alignSelf: 'center' }}>
                    {/* <TouchableOpacity onPress={() => { 
               this.editBilling(item, index);
              }}>
                {this.renderIdButton("pencil")}
              </TouchableOpacity> */}
                    <TouchableOpacity onPress={() => {
                        let { removableSavedBillID, Billing } = this.state
                        if (Billing[index].id) {
                            var deletedIds = Billing[index].id
                            removableSavedBillID.push(deletedIds)
                        }
                        this.setState({
                            removableSavedBillID
                        })
                        this.removeBillingList(index)
                    }}>
                        {this.renderIdButton("trash")}
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
    searchDescriptionMapping(value) {

        let { searchServiceTypeList } = this.state;
        let tempDescList = this.state.billingServiceType
        if (tempDescList.length > 0) {
          var searchListTemp = [];
          for (let i = 0; i < tempDescList.length; i++) {
    
            if (tempDescList[i].type.toLowerCase().indexOf(value.toLowerCase()) > -1) {
              searchListTemp.push(tempDescList[i]);
            }
          }
          searchServiceTypeList = searchListTemp;
        } else {
          searchServiceTypeList = this.state.billingServiceType;
        }
        this.setState({
          searchServiceTypeList
        })
      }
    render() {
        // const { country, state, enablePrint, amount_cash, amount_card, searchServiceTypeOpen, showServiceTypeBottomLayout } = this.state.mandatoryInformation;
        let { enablePrint, amount_cash, amount_card, searchServiceTypeOpen, showServiceTypeBottomLayout } = this.state;
        const dateFormat = 'YYYY-MM-DD';
        const dateFormatDisplay = 'DD-MM-YYYY';
        let billingItem = this.state.Billing ? JSON.parse(JSON.stringify(this.state.Billing)) : [];
        return (
            <View style={styles.screenBodyContainer}>
                {
                    this.state.responseType == "success" ?
                        this.successAlert() :
                        this.state.responseType == "error" ?
                            this.errorAlert() : null
                }
                <View style={{ width: "100%", height: screenHeight - 65, backgroundColor: color.white }}>
                    {/* <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "100%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 }} bounces={false} bouncesZoom={false} > */}

                    <View style={[Style.leftView, { width: "100%", backgroundColor: color.white }]}>
                        {/* {this.renderSubHeadingBorder("Quick Billing")} */}
                        <View style={{ marginLeft: "0.65vw", marginTop: "0.65vw" }}>
                            <Text style={{ fontSize: "1.3vw", fontWeight: '500', color: color.themeDark }}>{"Patient Information"}</Text>
                        </View>

                        {this.state.selectedDoctor.id == "Nursing" ?
                            <View style={{ flexDirection: "row", width: "15%" }}>
                                <Text style={{ color: "#888888", flex: 0.4, marginTop: "1vw", fontSize: ".9vw" }}>Select Nurse</Text>
                                <View style={{ flex: 0.6 }} >{this.renderNurseList()}</View>
                            </View> : null
                        }
                        {/* <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 40, marginTop: 20 }}>
                                <View style={{ width: 150, marginBottom: 18, justifyContent: 'center', marginRight: 15 }}>
                                    {this.renderTextBox("Mobile No *", 10, false, "MI", this.state.mandatoryInformation.mobileNo, this.state.mandatoryInformation.mobileNo ? null : this.state.errorColor)}
                                </View>
                            </View> */}
                        

                    </View>
                    {/* Billing Screen View */}
                    {
              this.state.responseType == "success" ?
                this.successAlert() :
                this.state.responseType == "error" ?
                  this.errorAlert() : null
            }
                    <View style={{ flexDirection: "row", zIndex: -1 }}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "60%", height: screenHeight - 165, backgroundColor: color.white, zIndex: -1, paddingHorizontal: ".85vw", paddingVertical: ".65vw", marginTop: "-.5vw" }} bounces={false} bouncesZoom={false} >
                            <View style={{ flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingLeft: "2.6vw", marginTop: "0.65vw" }}>
                            <View style={{ marginTop: ".65vw", marginRight: "1vw", zIndex: 3 }}>
                                <CommonPicker
                                    showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                                    changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                                    selectedValue={this.state.selectedDoctor}
                                    arrayOfData={this.state.doctorData}
                                    pickerKey="FO"
                                />
                            </View>
                            <View style={{ width: "9.75vw", marginBottom: "1.5vw", justifyContent: 'center', marginRight: "1vw" }}>
                                {this.renderTextBox("Mobile No *", 10, false, "MI", this.state.mandatoryInformation.mobileNo, this.state.mandatoryInformation.mobileNo ? null : this.state.errorColor)}
                            </View>
                            <View style={{ width: "5.2vw", marginBottom: "1.17vw", marginRight: ".3vw", marginTop: ".3vw", display: 'none' }}>
                                <Text style={{ position: 'absolute', marginLeft: ".65vw", backgroundColor: "white", fontSize: ".75vw", paddingLeft: ".3vw", paddingRight: ".3vw", color: "#888888" }}>{"Title"}</Text>
                                <Picker
                                    enabled={this.state.isIpPatientCheckouted ? false : true}
                                    selectedValue={this.state.mandatoryInformation.type}
                                    style={[Style.pickerView, {
                                        marginTop: ".3vw", borderColor: '#CDD1D5', width: "6vw"
                                        // this.state.errorColor && this.state.mandatoryInformation.type == "" ? "red" : '#CDD1D5'  
                                    }, Style.allButtonBorderRadius, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                                    itemStyle={{ fontSize: ".9vw" }}
                                    onValueChange={(itemvalue) => {
                                        var states = this.state.mandatoryInformation;
                                        states["type"] = itemvalue;

                                        // .dropDownEditFlag

                                        if (itemvalue == "Baby of") {
                                            states["maritalStatus"] = "Single"
                                        } else {
                                            if (itemvalue == "Mr") {
                                                states["gender"] = "Male";
                                            } else if (itemvalue == "Ms" || itemvalue == "Mrs") {
                                                if (itemvalue == "Mrs") {
                                                    states["gender"] = "Female";
                                                    states["maritalStatus"] = "Married"
                                                } else {
                                                    states["gender"] = "Female";
                                                }
                                            }
                                        }


                                        this.setState({ states });
                                    }}
                                >
                                    <Picker.Item label="" value="" />
                                    <Picker.Item label="Dr" value="Dr" />
                                    <Picker.Item label="Mr" value="Mr" />
                                    <Picker.Item label="Ms" value="Ms" />
                                    <Picker.Item label="Mrs" value="Mrs" />
                                    {
                                        this.state.userType == "OBGYN" ? null :
                                            <Picker.Item label="Baby of" value="Baby of" />
                                    }

                                    <Picker.Item label="Master" value="Master" />
                                    <Picker.Item label="Baby" value="Baby" />
                                </Picker>
                            </View>
                            <View style={{ width: "5.20vw", marginBottom: "1.5vw", marginTop: ".3vw", marginRight: ".3vw" }}>
                                <Text style={{ position: 'absolute', zIndex: 100, marginLeft: ".65vw", backgroundColor: "#FFFFFF", fontSize: ".75vw", paddingLeft: ".3vw", paddingRight: ".3vw", color: "#888888", marginTop: "-.1vw" }}>{"Gender *"}</Text>
                                {/* {this.state.mandatoryInformation.type ? */}
                                <Picker
                                    enabled={
                                        this.state.isIpPatientCheckouted ? false :
                                            this.state.mandatoryInformation.type == "Baby of" ? true :
                                                this.state.patientId && this.state.mandatoryInformation.type !== "Baby of" ? true :
                                                    true}
                                    selectedValue={this.state.mandatoryInformation.gender}
                                    style={[Style.pickerView, {
                                        marginTop: ".3vw", borderColor:
                                            this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5', width: "6vw"
                                    }, Style.allButtonBorderRadius, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                                    itemStyle={{}}
                                    onValueChange={(itemvalue) => {
                                        var states = this.state.mandatoryInformation;
                                        if (states.type != "" && (states.type === "Mr" || states.type === "Ms" || states.type === "Mrs")) {
                                            states["gender"] = itemvalue;
                                            states["type"] = ""
                                            this.setState({ states });
                                        } else {
                                            states["gender"] = itemvalue;
                                            this.setState({ states });
                                        }

                                    }}

                                >
                                    <Picker.Item label="" value="" />
                                    <Picker.Item label="Male" value="Male" />
                                    <Picker.Item label="Female" value="Female" />
                                    <Picker.Item label="Trans" value="Trans" />
                                </Picker>

                            </View>
                            <View style={{ width: "13vw", marginRight: "1vw", justifyContent: 'center', marginBottom: "1.4vw", marginLeft: "1.1vw" }}>
                                {this.state.mandatoryInformation.firstName && this.state.mandatoryInformation.type !== 'Baby of' ?
                                    this.renderTextBox("Name *", 10, false, "MI", this.state.mandatoryInformation.firstName, this.state.mandatoryInformation.firstName ? null : this.state.errorColor,
                                        this.state.patientId ? false : false,
                                    ) : this.renderTextBox("Name *", 10, false, "MI", this.state.mandatoryInformation.firstName, this.state.mandatoryInformation.firstName ? null : this.state.errorColor, this.state.patientId ? false : false,)}
                            </View>
                            <View style={{ width: "10vw", marginRight: "1vw", justifyContent: 'center', marginBottom: "1.5vw", display: 'none' }}>
                                {this.renderTextBox("Surname", 10, false, "MI", this.state.mandatoryInformation.surName)}
                            </View>
                            <View style={{ width: "5.3vw", marginRight: "1vw", justifyContent: 'center', marginBottom: "1.5vw" }}>

                                {this.renderTextBox("Age *", 10, false, "MI", this.state.mandatoryInformation.age, this.state.mandatoryInformation.age ? null : this.state.errorColor)}
                            </View>


                        </View>
                                <View style={{ flexDirection: 'column', zIndex:-2 }}>
                                    <Text style={{ fontSize: "1.3vw", fontWeight: '500', color: color.themeDark }}>{"Billing"}</Text>
                                    <Text style={{ fontSize: "0.84vw", color: color.themeDark }}>{this.state.patientInfo ? this.state.patientInfo.patient_account_number : "#"}</Text>
                                </View>

                                <View style={{ flexDirection: "row", justifyContent: "flex-end" , zIndex:-2}}>

                                    {!this.state.invoiceID ?
                                        <TouchableOpacity onPress={() => {
                                            this.clearBillingDetails()
                                        }} style={[{ paddingHorizontal: "1.2vw", paddingVertical: ".5vw", backgroundColor: color.themeDark, justifyContent: "center" }, Style.allButtonBorderRadius]}>
                                            <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Clear"}</Text>
                                        </TouchableOpacity>
                                        : null}
                                </View>
                            </View>
                            <View style={{ marginTop: "0.97vw", zIndex:-2 }}>
                                <View style={[styles.headingBackGround, { flexDirection: 'row', marginBottom: "1.95vw" }]}>
                                    <View style={{ flex: 0.35 }}>
                                        <Text style={{ fontSize: "1.1vw", fontWeight: 500 }}>Service Name</Text>
                                    </View>
                                    <View style={{ flex: 0.25 }}>
                                        <Text style={{ fontSize: "1.1vw", fontWeight: 500 }}>Service Type</Text>
                                    </View>
                                    <View style={{ flex: 0.23 }}>
                                        <Text style={{ fontSize: "1.1vw", fontWeight: 500, textAlign: 'center' }}>Service Amount</Text>
                                    </View>
                                    <View style={{ flex: 0.17 }}>
                                        <Text style={{ fontSize: "1.1vw", fontWeight: 500, textAlign: 'center' }}>Action</Text>
                                    </View>
                                </View>
                                <ScrollView style={{ height: "10.5vw" }}>
                                    {
                                        billingItem.map((bills, index) => {
                                            return this.renderItem(bills, index)
                                        })
                                    }
                                    {billingItem.length === 0 ? <View style={{
                                        height: "10.5vw", flexDirection: "row", justifyContent: "center", alignContent: "center",
                                    }}>
                                        <Text style={{ color: color.notesTableTitle, fontSize: '.9vw' }}>Add service</Text>
                                    </View> : null}
                                </ScrollView>
                            </View>

                            <View style={{
                                flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-end",
                                borderBottomColor: color.lightGray, borderBottomWidth: 1, paddingBottom: "1vw"
                            }}>
                                <View style={{ flex: 0.55, flexDirection: "column" }}>
                                    {/* Payment Type */}
                                    <View style={{ marginBottom: "1.3vw", zIndex: -1 }}><Text style={{ fontSize: '.9vw', fontWeight: 500 }}>{"Payment Type"}</Text></View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingRight: "1.95vw", zIndex: -1 }}>
                                        {/* <View style={{ flexDirection: 'row', flex: 0.4, justifyContent: 'space-between' }}> */}
                                        {this.renderPaymentBtn(
                                            ImagePath.Cash,
                                            "Cash"
                                        )}
                                        {this.renderPaymentBtn(
                                            ImagePath.Card,
                                            "Card")}
                                        {this.renderPaymentBtn(
                                            ImagePath.UPI,
                                            "UPI"
                                        )}
                                        {this.renderPaymentBtn(
                                            ImagePath.BankTransfer,
                                            "Bank Transfer"
                                        )}
                                        {/* {this.renderPaymentBtn(
                                            ImagePath.Cheque,
                                            "Cheque"
                                        )} */}
                                    </View>
                                    {/* </View> */}

                                </View>
                                <View style={{ flex: 0.45, flexDirection: "column" }}>

                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ flex: 0.7 }}><Text style={{ color: color.notesTableTitle, fontSize: "1vw" }}>{"Total Amount"}</Text></View>
                                        <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={{ marginLeft: "0.52vw", fontSize: "1.2vw", textAlign: 'right' }}>{(this.state.totalAmount)}</Text></View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: "1vw" }}>
                                        <View style={{ flex: 0.3 }}><Text style={{ color: color.notesTableTitle, fontSize: "1vw", marginTop: ".2vw" }}>{"Discount %"}</Text></View>

                                        {/* <View style={{ width: '13%', marginTop: 25,marginRight: 10 }}> */}
                                        {this.renderTextBoxAlone("Discount %", 0.3, false, "discouont_percent", this.state.discount_percent, -1, "70%")}
                                        {/* </View>
                                            <View style={{ width: '19%', marginTop: 25 }}> */}
                                        {this.renderTextBoxAlone("Discount Amount", 0.4, false, "discountAmount", this.state.discount, -1, "100%")}
                                        {/* </View> */}
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: "1vw" }}>
                                        <View style={{ flex: 0.7 }}><Text style={{ color: color.notesTableTitle, fontSize: "1vw" }}>{"Round Off"}</Text></View>
                                        <View style={{ flex: 0.3, justifyContent: "flex-end" }}><Text style={{ marginLeft: ".5vw", fontSize: "1.2vw", textAlign: 'right' }}>{(this.state.round_off.toFixed(2))}</Text></View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', paddingTop: "1vw" }}>
                                {/* <View style={{ flex: 1, height: 1, borderColor: color.lightGray }}></View> */}

                                {this.state.firstPayment != "" || this.state.firstPayment != undefined || this.state.secondPayment != "" ?
                                    <View style={{ width: "55%" }}>
                                        <View style={{ display: "flex", flexDirection: "row", marginBottom: ".5vw" }}>
                                            <View>
                                                {this.renderPayment(this.state.firstPayment)}
                                            </View>
                                            {this.renderPayment(this.state.secondPayment)}
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            {this.state.firstPayment != "" ?
                                                this.renderBillinTextBox(`${this.state.firstPayment} Amount`, "90%", false, "firstAmount", this.state.firstAmount, -1,) : null}
                                            {this.state.secondPayment != "" ?
                                                this.renderBillinTextBox(`${this.state.secondPayment} Amount`, "90%", false, "secondAmount", this.state.secondAmount, -1,) : null}
                                        </View>
                                    </View>
                                    : <View style={{ height: "4.5vw", width: "55%" }}></View>}

                                {/* Net Amount View */}
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 0.45, marginTop: "1.1vw", zIndex: -1 }}>
                                    <Text style={{ fontSize: ".95vw", color: color.notesTableTitle, flex: 0.7 }}>Net Amount</Text>
                                    <Text style={{ fontSize: "1.2vw", textAlign: 'right', flex: 0.3 }}>{(this.state.amount_invoice)}</Text>
                                </View>
                            </View>
                            {/* Button View */}
                            <View style={{ flexDirection: 'column', marginTop: "2.6vw", justifyContent: 'center', alignItems: 'center', marginBottom: "2.6vw" }}>
                                {
                                    this.state.mandatoryInformation.isAmaPatient ?
                                        <Text style={{ color: color.brightRed, marginLeft: "1.3vw", marginBottom: "1.3vw", fontWeight: "bold", fontSize: ".85vw" }}>{"This patient is under AMA"}</Text>
                                        : null
                                }
                                {/* Create Patient button action */}
                                <View style={{ flexDirection: 'row' }}>
                                    <TouchableOpacity
                                        disabled={this.state.isCreatePatientClicked ? true : false}
                                        onPress={() => {
                                            this.state.userType != Constants.ROLE_OPTOMETRY ? this.createPatient() : null
                                        }} style={[{
                                            marginLeft: "1.3vw", paddingTop: ".5vw", paddingLeft: "1.17vw",
                                            paddingRight: "1.17vw", paddingBottom: ".5vw", backgroundColor: this.state.isCreatePatientClicked ? "#888888" : color.themeDark,
                                        }, Style.allButtonBorderRadius]}>
                                        <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{this.state.alreadyCreatedPatient ? "Save Patient Details" : "Create Appointment"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.ResetScreen()
                                        }} style={[{
                                            marginLeft: "1.3vw", paddingTop: ".5vw", paddingLeft: "1.2vw",
                                            paddingRight: "1.2vw", paddingBottom: ".5vw", backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1,
                                        }, Style.allButtonBorderRadius]}>
                                        <Text style={[{ color: color.themeDark, textAlign: 'center' }, Style.fontSizeMedium]}>{this.state.invoice_id ? "Next Bill" : "Clear"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity disabled={!enablePrint} onPress={() => { this.printBillingDetails() }}
                                        style={[{ backgroundColor: enablePrint ? color.themeDark : '#cccccc' }, styles.ButtonView]}>
                                        <Text style={styles.ButtonText}>{"Print"}</Text>
                                    </TouchableOpacity>
                                </View>
                                {this.state.successOrErrorMessage ? (
                                    <View style={{ height: "2.7vw", width: '40%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center', marginTop: "0.65vw" }}>
                                        <Text style={{
                                            color: "white", textAlign: "center", justifyContent: "center", fontSize: ".9vw"
                                        }}>
                                            {this.state.successOrErrorMessage || null}</Text>
                                    </View>
                                ) : null}

                            </View>

                            {/* <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: 30 }}>
                                    <TouchableOpacity onPress={() => {
                                        this.saveBillingDetails()
                                    }} disabled={this.state.isDisabled || this.state.paymentType ? true : false} style={[{ marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: this.state.paymentType ? color.lightGray : color.themeDark, paddingHorizontal: 18, paddingVertical: 8, }, Style.allButtonBorderRadius]}>
                                        <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Save"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        this.setState({
                                            isPaymentReceived: true
                                        }, () => {
                                            this.setState({
                                                isPaymentReceived: false
                                            })
                                            this.saveBillingDetails()
                                        })
                                    }} disabled={this.state.isDisabled} style={[{ marginRight: 10, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: color.themeDark, paddingHorizontal: 18, paddingVertical: 8, }, Style.allButtonBorderRadius]}>
                                        <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Payment Received"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity disabled={!enablePrint} onPress={() => {
                                        this.printBillingDetails()
                                    }} style={[{ flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: enablePrint ? color.themeDark : color.lightGray, paddingHorizontal: 18, paddingVertical: 8, }, Style.allButtonBorderRadius]}>
                                        <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Print"}</Text>
                                    </TouchableOpacity>
                                </View> */}
                        </ScrollView>
                        <ScrollView showsVerticalScrollIndicator={false}
                            style={{ width: "35%", height: screenHeight - 170, backgroundColor: color.themeShade, }} >
                            <View style={[Style.rightView, { padding: "1.2vw", width: "100%" }]}>
                                <TouchableWithoutFeedback onPress={() => { this.onSubmitEditing() }}>
                                    <View>

                                        <View style={{ flexDirection: 'row', marginTop: "0.65vw", marginBottom: "1.5vw" }} >
                                            <View style={{ width: '60%' }}>
                                                <Text style={{ fontSize: "1.3vw", fontWeight: '500', color: color.themeDark }}>Billing</Text>
                                            </View>
                                            <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center", width: "40%" }}>
                                                <TouchableOpacity style={{ maxWidth: "6.2vw", backgroundColor: color.themeDark, borderRadius: ".3vw", paddingHorizontal: "0.65vw", paddingVertical: ".3vw" }}
                                                    onPress={() => {
                                                        this.clearBillingServiceDetails();
                                                    }}
                                                ><Text style={{ fontSize: "1vw", color: 'white' }}>{"Clear"}</Text></TouchableOpacity>
                                            </View>
                                        </View>
                                        {this.subtitle("Service Name")}
                                        <View style={styles.SearchBoxView}>
                                            <TextInput
                                                style={styles.SearchInput}
                                                placeholder={"Search by Service Name (Minimum 3 Characters to Search)"}
                                                value={this.state.ServiceNameSearchKey}
                                                onChangeText={(text) => {
                                                    this.setState({
                                                        ServiceNameSearchKey: text
                                                    }, () => {
                                                        if (this.state.ServiceNameSearchKey.length > 2) {
                                                            this.getBillingFrequentlyUsedService()
                                                        }
                                                        else if (this.state.ServiceNameSearchKey.length == 0) {
                                                            this.getBillingFrequentlyUsedService()
                                                        }
                                                    })
                                                }}
                                            />
                                        </View>
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                            {this.state.billingService.length > 0 ?
                                                this.state.billingService.map((items, index) => {
                                                    return this.renderServiceNameButtons(items, index);
                                                }) : null
                                            }
                                            {/* <View  > */}
                                            {/* <View style={{marginBottom:20}} > */}



                                        </View>

                                        {this.subtitle("Service Type")}
                                        <View style={{ zIndex: 1 }}>
                                            {this.renderBillingDescriptionSuggesstion()}
                                        </View>
                                        {this.state.searchServiceTypeViewShow ? <View style={{ marginTop: 0, zIndex: -1 }}>
                                                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: color.themeShade, paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{'Search Description'}</Text>

                                                <TextInput

                                                    style={{
                                                        borderColor: "#CDD1D5",
                                                        borderRadius: 4,
                                                        borderWidth: 1,
                                                        width: "100%",
                                                    height: '2.28vw',
                                                    padding: '0.97vw',
                                                    marginTop: '0.52vw',
                                                    fontSize: '1vw'
                                                    }} value={
                                                        this.state.searchServiceType
                                                    }
                                                    onFocus={() => {
                                                        searchServiceTypeOpen = true;
                                                        showServiceTypeBottomLayout = true;
                                                        this.setState({
                                                            searchServiceTypeOpen,
                                                            showServiceTypeBottomLayout
                                                        })
                                                    }}
                                                    onChangeText={(text) => {
                                                        var { searchServiceType, searchServiceTypeOpen, showServiceTypeBottomLayout, selectedBillingServiceType } = this.state;
                                                        if (text != "") {
                                                            searchServiceTypeOpen = true;
                                                            showServiceTypeBottomLayout = true;
                                                            searchServiceType = text
                                                            this.setState({
                                                                searchServiceType,
                                                                showServiceTypeBottomLayout,
                                                                searchServiceTypeOpen
                                                            }, () => {
                                                                this.searchDescriptionMapping(searchServiceType);
                                                            })
                                                        } else {
                                                            searchServiceTypeOpen = false;
                                                            searchServiceType = text;
                                                            selectedBillingServiceType = {};
                                                            this.setState({
                                                                searchServiceTypeOpen,
                                                                searchServiceType,
                                                                selectedBillingServiceType
                                                            }, () => {
                                                                this.searchDescriptionMapping(searchServiceType);
                                                            })
                                                        }
                                                    }}

                                                />

                                            </View> : null
                                            }
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', zIndex: -1, marginTop: "0.65vw" }}>
                                            {this.state.billingServiceType.length > 0 ?
                                                this.state.billingServiceType.map((items, index) => {
                                                    return this.renderServiceTypeButtons(items, index);
                                                }) : null
                                            }

                                            {/* </View> */}
                                            {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
                                            {/* <View>*/}

                                        </View>


                                        {this.subtitle("Service Amount")}
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 1, zIndex: -1 }}>
                                            {this.state.selectedBillingServiceAmount != null ?
                                                this.renderServiceAmountButtons(this.state.selectedBillingServiceAmount, 0)
                                                : null
                                            }
                                            {/* </View> */}
                                            {/* <View style={{marginBottom: 20,zIndex:-1}}> */}
                                        </View>


                                        <View style={{ zIndex: -1, marginTop: "2vw", marginBottom: ".65vw" }}>
                                            {this.renderAddButton("Add", "add")}
                                        </View>
                                    </View>
                                </TouchableWithoutFeedback>
                            </View>
                        </ScrollView>

                    </View>

                    {/* </ScrollView> */}
                </View>
                {/* Mobile number matched users */}
                <View style={{ zIndex: 1, position: 'absolute', width: '100%' }}>
                    {
                        this.state.searchPatientData && this.state.searchPatientData.length > 0 ?

                            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: 'flex-start', paddingLeft: "0.65vw" }}>

                                <View style={styles.rightScreenWrapper}>
                                    <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: screenHeight / 2 }}>
                                        {
                                            this.state.searchPatientData.map((item, index) => {
                                                return (
                                                    <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 0, paddingRight: "0.65vw", paddingTop: ".3vw", paddingBottom: ".3vw", borderBottomColor: "#E4E8F1", borderBottomWidth: 1 }}>
                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                            <Image source={item.photo_url ? item.photo_url : require('../../../../assets/images/sample_profile.png')}
                                                                style={Style.appointmentTableRowImg}
                                                                resizeMode='stretch' />
                                                            <Text style={{ color: 'black', marginLeft: "0.65vw" }} >{item.first_name}</Text>
                                                            <Text style={{ color: '#ADB4C1', marginLeft: "0.65vw" }}> {item.patient_account_number}{item.patient_account_number ? " | " : null}{item.mobile_number}</Text>
                                                        </View>


                                                        {
                                                        // this.state.searchPatientData && Object.keys(item.appointment).length > 0 ?
                                                        //     <View style={{ justifyContent: 'center', marginRight: "0.65vw" }}>
                                                        //         <Text style={{}}>{item.appointment.appointment_date}</Text>
                                                        //     </View>
                                                        //     :
                                                            <TouchableOpacity style={{ paddingLeft: "1.3vw", paddingRight: "1.3vw", alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: "0.65vw", marginLeft: "1.3vw" }}
                                                                onPress={() => {
                                                                    this.getCreatedPatientDetail(item.id);
                                                                    this.setState({
                                                                        searchPatientData: item,
                                                                        patientId: item.id,
                                                                        isExistingPatient: true
                                                                        // ShowCreateappointment: true,
                                                                    }, () => {
                                                                        //this.setModalVisible(true);
                                                                        localStorage.setItem('isQuickBilling', true);
                                                                        localStorage.setItem('QuickBillingAppointmentId', "");
                                                                    })

                                                                }}
                                                            >
                                                                <Text style={{ color: 'white', fontWeight: 'bold', fontSize: "0.85vw", }}>{"Select Patient"}</Text>
                                                            </TouchableOpacity>
                                                        }

                                                    </View>
                                                );
                                            })

                                        }
                                        <View style={{ alignItems: 'center' }}>
                                            <TouchableOpacity style={{ paddingLeft: "1.95vw", paddingRight: "1.95vw", alignItems: 'center', justifyContent: 'center', backgroundColor: '#313131', borderRadius: "0.65vw", paddingBottom: "0.65vw", paddingTop: "0.3vw", marginTop: "0.65vw" }}
                                                onPress={() => {
                                                    this.setState({
                                                        searchPatientData: []
                                                    })
                                                }}
                                            >
                                                <Text style={{ color: 'white', fontWeight: 'bold', fontSize: "1.2vw", }}>{"Skip"}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </ScrollView>
                                </View>

                            </View>
                            : null
                    }
                </View>
                {/* </ScrollView> */}
            </View>
            // </TouchableWithoutFeedback>
        );
    }

    modelClose(flag, status = "") {
        this.setState({
            modalVisible: flag,             // Next AppoinmentModal Close
            // showNextAppoinmentTimer:false   // AppoinmentModal timer drop down close
        }, () => {
            if (status == "success") {
                // this.props.changeScreen("home");
                this.props.modelClose(false, "success")
                // this.props.navigation.navigate('home',{appointmentReload:true})
            }

        }
        )
    }

    // setModalVisible = status => {

    //     let { userType } = this.state;

    //     // var service_url = Constants.VITALS_DETAILS
    //     let service_url = '';

    //     if (userType === Constants.ROLE_FO) {

    //         service_url = Constants.FO_DOCTOR_PATIENT_INFO + this.state.patientId;
    //     } else {

    //         service_url = Constants.DOCTOR_PATIENT_INFO + this.state.patientId;
    //     }


    //     OpthamologyService.getInstance().getComplaints(
    //         service_url,
    //         this,
    //         this.getPatientInfoSuccess,
    //         this.getPatientInfoFailure
    //     );
    // }

    getPatientInfoSuccess = response => {
        if (response.status === "success") {

            let states = this.state;
            states["modalVisible"] = true;
            this.props.modelClose(true, "", this.state.patientId, response)
        }
    };

    getPatientInfoFailure = error => {
        // console.log("opthamology complaints GET error response");
        console.log(error);
    }
    getClinic = () => {
        var serviceUrl = Constants.HOME_GET_CLINICS;
        // console.log("this.state.patientDetails ---------> new")

        Service.getInstance().getClinicList(
            serviceUrl,
            this,
            this.getClinicSuccess,
            this.getClinicFailure
        );
    };

    getClinicSuccess = success => {
        // console.log("this.state.patientDetails ---------> success"+JSON.stringify(success))
        if (success.status == "success") {
            // console.log("this.state.patientDetails ---------> success11"+JSON.stringify(success.data[0]))
            this.setState({
                clinicList: success.data,
                selectedClinic: success.data[0]
            })
        }
    }
    getClinicFailure = error => { }


    numberValidation(num) {
        // return /^\d+$/.test(num);
        return !/[^.[0-9]]*/.test(num);
    }

    alphabetvalidation(num) {
        return !/[^.[A-Z a-z]]*/.test(num);
    }


    createPatientDetails(text, placeholder, type) {
        if (type == "MI") {
            if (placeholder == "Source Name") {
                var isNum = this.alphabetvalidation(text)
                if (isNum && text.length < 100) {
                    let states = this.state.mandatoryInformation
                    states["referralsource"] = text
                }
                this.setState({ states })
            } else if (placeholder == "Doctor Name") {
                var isNum = this.alphabetvalidation(text)
                if (isNum) {
                    let states = this.state.mandatoryInformation
                    states["refferedBy"] = text
                }
                this.setState({ states })
            } else if (placeholder == "Name *") {
                var isNum = this.alphabetvalidation(text)
                if (isNum) {
                    var states = this.state.mandatoryInformation
                    states["firstName"] = text
                }
                this.setState({ states })
            } else if (placeholder == "Surname") {
                let states = this.state.mandatoryInformation
                states["surName"] = text
                this.setState({ states })
            } else if (placeholder == "Occupation") {
                let states = this.state.mandatoryInformation
                states["occupation"] = text
                this.setState({ states })
            } else if (placeholder == "Mobile No *") {

                var isNum = this.numberValidation(text)
                if (isNum) {
                    if (text.length <= 10) {
                        let states = this.state.mandatoryInformation
                        states["mobileNo"] = text
                        this.setState({ states })
                        if (text.length == 10) {
                            // console.log("searchNumber")
                            this.searchPatient(text)
                        } else {
                            this.setState({ searchPatientData: [] })
                        }
                    }
                }
            } else if (placeholder == "Mobile No2") {

                var isNum = this.numberValidation(text)
                if (isNum) {
                    if (text.length <= 10) {
                        let states = this.state.mandatoryInformation
                        states["mobileNo2"] = text
                        this.setState({ states })
                    }
                }
            } else if (placeholder == "Father/Husband name") {
                let states = this.state.mandatoryInformation
                states["fatherOrHusband"] = text
                this.setState({ states })
            } else if (placeholder == "state *") {
                let states = this.state.mandatoryInformation
                states["state"] = text
                this.setState({ states })
            } else if (placeholder == "Age *") {
                var isNum = this.numberValidation(text)
                if (isNum || text === "") {
                    let states = this.state.mandatoryInformation
                    states["age"] = text;
                    var approxDob = this.handleFindApproxDob(text);
                    states["dob"] = "";
                    states["approx_dob"] = approxDob;
                    this.setState({ states })
                }


                // let states = this.state.mandatoryInformation
                // states["age"] = text;
                // var approxDob = this.handleFindApproxDob(text);
                // states["dob"] = "";
                // states["approx_dob"] = approxDob;
                // this.setState({ states })
            } else if (placeholder == "Approx DOB") {
                let states = this.state.mandatoryInformation
                states["approx_dob"] = text;
                this.setState({ states })
            }
        } else if (type == "OI") {
            if (placeholder == "Email Id") {
                let states = this.state.otherInformation
                states["emailID"] = text
                this.setState({ states })
            } else if (placeholder == "Address 1") {
                let states = this.state.otherInformation
                states["address"] = text
                this.setState({ states })
            } else if (placeholder == "Address 2") {
                let states = this.state.otherInformation
                states["address2"] = text
                this.setState({ states })
            } else if (placeholder == "City *") {
                let states = this.state.otherInformation
                var isNum = this.alphabetvalidation(text)
                if (isNum) {
                    states["city"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "Pincode") {
                let states = this.state.otherInformation
                states["pincode"] = text
                this.setState({ states })
            }
        } else if (type == "CTI") {
            if (placeholder == "Email Id") {
                let states = this.state.careTakerInformation
                states["emailID"] = text
                this.setState({ states })
            } else if (placeholder == "Address") {
                let states = this.state.careTakerInformation
                states["address"] = text
                this.setState({ states })
            } else if (placeholder == "City") {
                let states = this.state.careTakerInformation
                states["city"] = text
                this.setState({ states })
            } else if (placeholder == "Pincode") {
                let states = this.state.careTakerInformation
                states["pincode"] = text
                this.setState({ states })
            } else if (placeholder == "First Name") {
                let states = this.state.careTakerInformation
                states["firstName"] = text
                this.setState({ states })
            } else if (placeholder == "Mobile No") {
                let states = this.state.careTakerInformation
                states["mobileNo"] = text
                this.setState({ states })
            } else if (placeholder == "Relationship") {
                let states = this.state.careTakerInformation
                states["relationship"] = text
                this.setState({ states })
            }
        } else if (type == "IDP") {
            if (placeholder == "Aadhaar no") {

                var isNum = this.numberValidation(text)
                if (isNum) {
                    if (text.length <= 12) {
                        let states = this.state.idProof;
                        states["aadharCard"] = text
                        this.setState({ states })
                    }
                }
            } else if (placeholder == "Driving Lic no") {
                if (text.length <= 16) {
                    let states = this.state.idProof;
                    states["drivingLicence"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "Voter ID no") {
                if (text.length <= 10) {
                    let states = this.state.idProof;
                    states["voterId"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "Passport no") {
                if (text.length <= 12) {
                    let states = this.state.idProof;
                    states["passport"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "VISA no") {
                if (text.length <= 12) {
                    let states = this.state.idProof;
                    states["visa"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "VISA Validity") {
                if (text.length <= 12) {
                    let states = this.state.idProof;
                    states["visaValidity"] = text
                    this.setState({ states })
                }
            } else if (placeholder == "Ration Card no") {
                let states = this.state.idProof;
                states["rationCard"] = text
                this.setState({ states })
            } else if (placeholder == "Bank Id") {
                let states = this.state.idProof;
                states["bankId"] = text
                this.setState({ states })
            }
        }
    }

    searchPatient = (text) => {
        // console.log("searchNumber:",text)
        if (text !== "") {
            var serviceUrl =
                Constants.HOME_SEARCH_PATIENT +
                "?search_key=" +
                text;

            Service.getInstance().appointmentComments(
                serviceUrl,
                this,
                this.searchPatientSuccess,
                this.searchPatientFailure
            );
        }
    }

    searchPatientSuccess = (response) => {
        if (response.data.patient.length > 0) {
            this.setState({
                searchPatientData: response.data.patient
            })
        }

    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });


    }
    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours == '12') {
            hours = '00';
        }

        if (modifier == 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${"00"}`;
    }
    createPatient() {
        var datas = this.state.mandatoryInformation;
        var mandatoryInformationIsEmpty = false;
        var message = ""
        console.log(this.state);
        this.setState({
            isCreatePatientClicked: true
        })
        //const getClinicID = await AsyncStorage.getItem("Clinic_Id");
        if (this.state.isExistingPatient) {
            //Go To Create Appointment
            if(this.state.firstPayment !== ""){
                if ((this.state.firstPayment == "Card" || this.state.secondPayment == "Card") && (!this.state.CardNumber || (this.state.CardNumber).length == 0)) {
                    this.showResposeValue('error', "Enter your Card Transaction ID");
                    this.setState({
                        isCreatePatientClicked: false
                    })
                  } else if((this.state.firstPayment == "Cheque" || this.state.secondPayment == "Cheque") && (!this.state.Cheque || (this.state.Cheque).length == 0 )){
                    this.showResposeValue('error', "Enter your Cheque Number");
                    this.setState({
                        isCreatePatientClicked: false
                    })
                  } else if((this.state.firstPayment != "" && this.state.secondPayment == "") && (parseInt(this.state.amount_net).toFixed() != this.state.firstAmount)){
                    this.showResposeValue("error", "Amount Field Should not be Empty");
                    this.setState({
                        isCreatePatientClicked: false
                    })
                  }
                  else if((this.state.firstPayment != "" && this.state.secondPayment != "") && (parseInt(this.state.amount_net).toFixed() != (parseInt(this.state.firstAmount) + parseInt(this.state.secondAmount)))){
                    this.showResposeValue("error", "Amount Not Matched")
                    this.setState({
                        isCreatePatientClicked: false
                    })
                  }else{
                    this.createNextAppoinment();
                  }
            }else{
            this.createNextAppoinment();
            }
            return;
        }
        else {
            const data = new FormData();
            console.log("Gender", this.state.mandatoryInformation.gender);
            var gender;
            if (this.state.mandatoryInformation.gender == "Male") {
                console.log("logg", this.state.mandatoryInformation);
                gender = "m"
            } else if (this.state.mandatoryInformation.gender == "Female") {
                gender = "f"
            } else if (this.state.mandatoryInformation.gender == "Trans") {
                gender = "t"
            }
            if (this.state.patientId) {
                data.append('patient_id', this.state.patientId);
            }
            if (this.props.selectedIpPatientDetails.id || this.props.isCurrentPageIsIP) {
                data.append('ip', true);
                data.append('doctor_id', this.state.selectedDoctor.id);
            }
            data.append('reffered_by', "");
            data.append('referral_source', "");
            data.append('title', this.state.mandatoryInformation.type);
            data.append('first_name', this.state.mandatoryInformation.firstName);
            data.append('last_name', this.state.mandatoryInformation.surName);
            data.append('gender', gender);
            if (this.state.userType === 'LAB') {

            } else {
                data.append('clinic_id', this.state.clinicId);
            }

            data.append('approx_dob', this.state.mandatoryInformation.approx_dob ? moment(this.state.mandatoryInformation.approx_dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : "");
            data.append('dob', this.state.mandatoryInformation.dob);
            data.append('age', this.state.mandatoryInformation.age);
            if (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) {
                data.append('photo', (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) ? this.dataURItoBlob(this.state.mandatoryInformation.profilePhoto) : "")
                data.append('face_data', (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) ? this.dataURItoBlob(this.state.mandatoryInformation.profilePhoto) : "")
            } else if (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto != "") {
                data.append('photo_url', this.state.mandatoryInformation.profilePhoto)
            }
            data.append('marital_status', this.state.mandatoryInformation.maritalStatus);
            data.append('mobile_number', this.state.mandatoryInformation.mobileNo);
            data.append('occupation', "");
            data.append('receive_sms', this.state.mandatoryInformation.receiveSMSFlag);
            data.append('father_husband_name', this.state.mandatoryInformation.fatherOrHusband);
            data.append('country', this.state.mandatoryInformation.country);
            data.append('mobile_number_2', "");
            data.append("guardian", "")
            data.append('pincode', "");
            data.append('email', "");
            data.append('city_name', this.state.otherInformation.city);
            data.append('address_line_1', "");
            data.append('address_line_2', "");

            // data.append('address_line_2', ""); // not showing
            data.append('state_name', this.state.mandatoryInformation.state);
            data.append('home_phone', "");
            data.append('area_name', "");



            //   for(var checkData of data.entries()) {
            //  }
            if (
                //this.state.mandatoryInformation.type &&
                this.state.mandatoryInformation.gender &&
                this.state.mandatoryInformation.firstName &&
                this.state.mandatoryInformation.age &&
                this.state.mandatoryInformation.mobileNo &&
                this.state.otherInformation.city &&
                this.state.mandatoryInformation.state &&
                this.state.mandatoryInformation.country) {

                let service = ""
                let { userType } = this.state;
                // alert("userType"+userType)

                if (userType === Constants.ROLE_FO) {

                    service = Constants.FO_CREATE_PATIENT
                }
                // else if (this.state.userType === 'LAB') {

                //     service = Constants.CREATE_LAB_PATIENT
                // } else {

                //     service = Constants.DR_CREATE_PATIENT
                // }

                if (this.state.mandatoryInformation.country === "India") {
                    this.setState({
                        isCreatePatientClicked: true
                    })
                    OpthamologyService.getInstance().documentUploadPost(
                        service,
                        data,
                        this,
                        this.createPatientSuccess,
                        this.createPatientFailure,
                        'multipart/form-data'
                    );
                }
            } else {
                this.setState({ errorColor: true, successOrErrorMessage: "Enter all required fields to proceed", successMessageFlag: false, errorMessageFlag: true, isCreatePatientClicked: false });
                setTimeout(() => {
                    this.setState({ errorColor: true, successOrErrorMessage: "Enter all required fields to proceed", successMessageFlag: false, errorMessageFlag: false, isCreatePatientClicked: false });
                }, 2000);
            }
        }

    }

    createPatientSuccess = success => {

        var result = success.data;
        if (result && result.status === "success") {
            this.setState({ successOrErrorMessage: result.message, successMessageFlag: true, errorMessageFlag: false });

            setTimeout(() => {
                this.setState({ successOrErrorMessage: "", successMessageFlag: false, errorMessageFlag: false });
            }, 2000);
            this.setState({ patientId: result.data.patient_id });
            this.getCreatedPatientDetail(result.data.patient_id);
            //this.setModalVisible();
            localStorage.setItem('isQuickBilling', true);
            //this.showNextAppoinmentView(result.datapatient_id);
            //this.createNextAppoinment();
        }
    }

    createPatientFailure = error => {
        this.setState({
            isCreatePatientClicked: false
        })

        let response = JSON.parse(JSON.stringify(error));
        if (response) {

            this.setState({ successOrErrorMessage: response.data.message, successMessageFlag: false, errorMessageFlag: true });
            setTimeout(() => {
                this.setState({ successOrErrorMessage: response.data.message, successMessageFlag: false, errorMessageFlag: false });
            }, 2000);
        }
    }

    showNextAppoinmentView(patientId) {
        let { userType } = this.state;
        let service_url = '';
        if (userType === Constants.ROLE_FO) {
            service_url = Constants.FO_DOCTOR_PATIENT_INFO + patientId + "/";
        } else if (userType === "LAB" && this.props.selectedLabPatientDetails.patient_id) {

            service_url = Constants.DR_CREATE_PATIENT + patientId + "/";
        } else {

            service_url = Constants.DOCTOR_PATIENT_INFO + patientId + "/";
        }
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPatientDetailsSuccess,
            this.getPatientDetailsFailure
        );
    }

    getPatientDetailsSuccess = success => {
        var states = this.state.mandatoryInformation
        states["refferedBy"] = success.data.reffered_by
        states["referralsource"] = success.data.referral_source
        this.setState({
            states,
            patientDetails: success.data,
            showNextAppoinmentView: true
        })
    }

    getPatientDetailsFailure = error => {
        this.setState({
            patientDetails: error.data,
            showNextAppoinmentView: true
        })
    }

    renderErrorMessageView(key) {
        return (
            <View>
                {
                    this.state.showErrorMessage && data == "" ?
                        <View style={Style.errorMessageView}>
                            <Text style={[Style.errorMessageText, { marginLeft: 10 }]}>{"This field is Mandatory"}</Text>
                        </View>
                        : null
                }
            </View>

        );
    }

    openCameraModal(route) {

        this.props.openCameraModal(route);
    }
    PrintBarcode() {
        var serviceUrl = Constants.BARCODE_PRINT_FO + "?patient_id=" + this.state.patientId + "&doctor_id=" + this.state.patientAppointment.doctor_id + "&export_type=pdf";
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.BarcodePrintSuccess,
            this.BarcodePrintFailure,
            "pdf"
        );
    }
    BarcodePrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    PrintOPCard() {
        var serviceUrl = Constants.OPCARD_PRINT_FO + "?patient_id=" + this.state.patientId + "&doctor_id=" + this.state.patientAppointment.doctor_id + "&export_type=pdf";
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.OPCardPrintSuccess,
            this.BarcodePrintFailure,
            "pdf"
        );
    }
    OPCardPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' }
            );
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    saveUserImages() {
        let { mandatoryInformation } = this.state;
        let url = Constants.FR_SAVE_PHOTO;
        const data = new FormData();
        if (this.state.patientId && (mandatoryInformation.facialPhotos && mandatoryInformation.facialPhotos.length > 0)) {
            data.append('patient_id', this.state.patientId);
            data.append('patient_photo', this.dataURItoBlob(mandatoryInformation.facialPhotos[0]))
            data.append('patient_photo1', this.dataURItoBlob(mandatoryInformation.facialPhotos[1]))
            data.append('patient_photo2', this.dataURItoBlob(mandatoryInformation.facialPhotos[2]))
            data.append('patient_photo3', this.dataURItoBlob(mandatoryInformation.facialPhotos[3]))
            data.append('patient_photo4', this.dataURItoBlob(mandatoryInformation.facialPhotos[4]))
            axios.post(
                url,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization:
                            "Token d59ec004ad05db76748d7a16679cf0e9c616b117"
                    }
                }
            )
                .then((response) => {
                })
                .catch((error) => {
                    console.log(" face photos error" + JSON.stringify(error))
                });
        }
        //this.setModalVisible();
    }
    clearBillingServiceDetails() {
        var states = this.state;
        states["selectedBillingServiceName"] = "";
        states["selectedBillingServiceId"] = "";
        states["selectedBillingServiceType"] = {};
        states["selectedBillingID"] = {};

        states["ServiceNameSearchKey"] = ""
        states["billingServiceType"] = []
        states["searchServiceTypeViewShow"] = false
        states["selectedBillingServiceAmount"] = null

        this.setState({ states }, ()=> this.getBillingFrequentlyUsedService())
    }
    removeBillingList(index) {
        var { Billing } = this.state
        Billing.splice(index, 1);
        this.setState({
            Billing
        }, () => {
            this.calculateTotalAmount()
        })
    }
    subtitle(Title) {
        return (
            <View style={[styles.headingBackGround, styles.subtitleView]}>
        <Text style={styles.subTitleText}>{Title}</Text>
      </View>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    timeAndDateScroll: {
        height: "13.02vw", width: "6.51vw"
    },
    subtitleView: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '0.65vw',
        zIndex: -1
      },
      subTitleText: {
        color: "black",
        fontSize: '1vw',
        fontWeight: '500'
      },
    rightView: {
        padding: ".65vw",
        width: "31.5%",
        height: screenHeight,
        backgroundColor: color.themeShade,
    },
    headingBackGround: {
        backgroundColor: color.appointmentTableHead,
    paddingHorizontal: '1.62vw',
    paddingVertical: '0.65vw',
    borderRadius: '0.4vw'
    },
    subHeadingBorderContainer: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginRight: "2.60vw",
    },
    subHeadingTitleText: {
        fontSize: "1.30vw",
        color: "#000000",
        fontWeight: "bold"
    },
    subHeadingTitleBody: {
        marginLeft: "0.65vw",
        width: "80%",
        height: 1,
        backgroundColor: "grey"
    },
    renderTextBoxPlaceHolder: {
        zIndex: 2,
        fontSize: ".78vw",
        position: "absolute",
        marginLeft: ".65vw",
        paddingLeft: ".3vw",
        paddingRight: ".3vw",
        color: "#888888"
    },
    renderTextBoxTextInput: {
        borderRadius: 4,
        borderWidth: 1,
        width: "100%",
        height: "2.27vw",
        padding: "1vw",
        marginTop: ".5vw",
        fontSize: ".85vw"
    },
    pickerViewContainer: {
        width: "5.20vw",
        marginBottom: "1.17vw",
        marginRight: "0.32vw",
        marginTop: "0.32vw"
    },
    pickerViewTextStyle: {
        position: 'absolute',
        marginLeft: "0.65vw",
        backgroundColor: "white",
        fontSize: "0.8vw",
        paddingLeft: ".32vw",
        paddingRight: ".32vw",
        color: "#888888"
    },
    alertContainer: {
        flex: 1,
        zIndex: 3,
        width: "40%",
        position: 'absolute',
        left: ".65vw",
        bottom: "0.32vw",
        right: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: "row",
        backgroundColor: "#DEF2D6",
        borderRadius: ".32vw",
        borderColor: "#B8C7B2",
        borderWidth: 1,
        height: "4.55vw",
        marginRight: "0.65vw",
        marginTop: ".65vw"
    },
    alertWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: ".65vw",
        flex: 0.95
    },
    referredTextBoxContainer: {
        marginTop: ".65vw",
        marginLeft: "2.60vw"
    },
    referredTextBoxText: {
        position: 'absolute',
        backgroundColor: 'white',
        marginLeft: "0.26vw",
        paddingHorizontal: "0.32vw",
        fontSize: ".85vw",
        color: "#888888"
    },
    referredTextBoxTextInput: {
        paddingLeft: ".65vw",
        borderColor: color.lightGray,
        minWidth: "100%",
        height: "2.27vw",
        borderWidth: 1,
        marginTop: "0.52vw",
        borderRadius: 4
    },
    BillingTextBoxPlaceHolder: {
        zIndex: 100,
        marginBottom: "0.65vw",
        fontSize: ".75vw",
        paddingLeft: ".3vw",
        paddingRight: ".3vw",
        color: "#888888"
    },
    billingTextBoxServiceName: {
        backgroundColor: "black",
        borderRadius: 4,
        position: 'absolute',
        zIndex: 1,
        marginTop: "2.47vw",
        maxHeight: "16.27vw",
        maxWidth: "16.27vw",
        minWidth: "13.02vw"
    },
    searchServiceTypeView: {
        zIndex: -1,
        position: 'absolute',
        marginTop: "3.25vw",
        marginLeft: ".65vw",
        maxHeight: "16.27vw",
        minWidth: "19.53vw",
        maxWidth: "22.78vw"
    },
    searchServiceTypeScrollView: {
        height: "12.69vw",
        marginBottom: ".65vw",
        flex: 1,
        backgroundColor: 'white',
        borderColor: color.lightGray,
        borderWidth: 1,
        borderRadius: "0.26vw"
    },
    addButtonTextStyle: {
        fontSize: "1.2vw", textAlign: "center",
        color: color.white, fontWeight: "500"
    },
    renderItemContainer: {
        paddingHorizontal: "1.62vw",
        flexDirection: 'row',
        marginBottom: "1.30vw",
        zIndex: -2,
        borderBottomColor: color.textBoxBorderColor,
        borderBottomWidth: 1,
        paddingBottom: "1vw"
    },
    screenBodyContainer: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: color.applicationBackgroundColor
    },
    rightScreenWrapper: {
        position: "absolute",
        marginTop: "6.5vw",
        // width: "70%",
        // height:"48%",
        backgroundColor: color.white,
        // borderBottomRightRadius: 10, 
        // borderBottomLeftRadius: 10, 
        // paddingBottom: 10, 
        border: "1px solid lightgrey",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        alignContent: "center",
        flex: 1,
        borderRadius: "0.65vw",
        padding: "1.95vw"
    },
    SearchBoxView:{
        margin: '0.5vw', marginLeft: '0.7vw' , display:'flex',flexDirection:'row',
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.textBoxBorderColor,
        borderRadius: '0.26vw',
        width: "100%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    ButtonView: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: '1.17vw',
        paddingVertical: '0.52vw',
        borderRadius: ".25vw",
        marginLeft: '0.75vw',
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: "0.85vw"
    }
});
