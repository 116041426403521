import React, { Component } from 'react';
import { View, Text, Dimensions, StyleSheet, Image, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import CommonDateFilter, { CommonHistorySectionHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import Pagination  from '../../../doctor/common/Pagination';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import {Tooltip} from 'antd';
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class IPAdvanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isCurrentPageIsIPPatientAppointment: this.props.isCurrentPageIsIPPatientAppointment,
        IPAdvanceAmountList: [],
        afterPaginationIPAdvanceList: [],
        current_page: 1,
        records_per_page: 10,
        fromDate: converted_date,
        toDate: converted_date,
        filterDate: {},
        searchPatient: "",
        paidAmount: 0,
        pendingAmount: 0,
        totalAmount: 0
    };
  }
  componentDidMount() {
     this.getIPAdvanceReport()
  }
  getIPAdvanceReport = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      afterPaginationIPAdvanceList: [],
      IPAdvanceAmountList: [],
    })
    var url = Constants.IP_ADVANCE_AMOUNT_REPORT + "?start_date=" + this.state.fromDate + "&end_date=" + this.state.toDate + "&patient_name=" + this.state.searchPatient 
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getIPAdvanceReportSuccess,
        this.getIPAdvanceReportFailure
      );
  }
  getIPAdvanceReportSuccess = response => {
      if (response && response.status == "success") {
          this.setState({
            IPAdvanceAmountList: response.data,
            paidAmount: response.total_paid_amount,
            pendingAmount: response.total_balance_amount,
            totalAmount: response.total_credit_amount
          })
      } else {
        this.props.showResponseValue("error", response.message)
      }
  }
  getIPAdvanceReportFailure = error => {
    this.props.showResponseValue("error", error.message)

  }
  printReport = () => {
    var url = Constants.IP_ADVANCE_AMOUNT_REPORT + "?start_date=" + this.state.fromDate + "&end_date=" + this.state.toDate + "&patient_name=" + this.state.searchPatient + "&export_type=excel"   
    OpthamologyService.getInstance().documentUploadGet(
      url,
      this.PrintPatienTokenSuccess,
      this.PrintPatienTokenFailure,
      "excel"
    );
  };

  PrintPatienTokenSuccess = (success) => {
    if (success) {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'IPDoctorReport.xlsx');
      pom.click();
    }
  };

  PrintPatienTokenFailure = (error) => {
    console.log(error);
  };
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  renderHeader = () => {
     return(  
      <View style = {styles.HeaderMain}>
         <CommonHistorySectionHeader
          heading1={"Patient Name"}
          heading2={"Date"}
          heading3={"Total Credited"}
          heading4={"Total Debited"}
          heading5={"Balance"}
          noOfColumn={5}
          columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}
        />
      </View>
     )  
  }
  renderIPAdvanceDataList = () => {
    return(
      <ScrollView showsHorizontalScrollIndicator = {false} showsVerticalScrollIndicator = {false} style={{ zIndex: -1, height: "61vh" }}>
         { this.state.afterPaginationIPAdvanceList && this.state.afterPaginationIPAdvanceList.length > 0 ?
           this.state.afterPaginationIPAdvanceList && this.state.afterPaginationIPAdvanceList.map((item, index) => {
          return (
            <View style={styles.dataContainer} >   
              <View style={{ flex: 0.2, marginHorizontal: "1.5vw" }}>
                  <Text style={[styles.TableDataText, {fontWeight: "400"}]}>{item.patient_name}</Text>
                  <Text style={styles.TableSubText}>{item.mobile_number}</Text>
                  <Text style={styles.TableSubText}>{item.uhid}</Text>
               </View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{moment(item.admission_date).format("DD-MM-YYYY")}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.total_credit}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.total_paid}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.total_balance}</Text></View>
            </View>
          )
        }):
         <View style={styles.NoRecordContainer}>
          <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
         </View>
        }
     </ScrollView>
    )
  }
 
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
  }

renderPaginationView() {
 return (
   <View style={styles.paginationContainer}>
     {
       this.state.IPAdvanceAmountList && this.state.IPAdvanceAmountList.length > 0 ?
         <Pagination
           paginationChangedValues={this.paginationChangedValues.bind(this)}
           totalItems={this.state.IPAdvanceAmountList}
           paginationCurrentPage={this.paginationCurrentPage.bind(this)}
         /> : null
     }
   </View>
 )
}
paginationChangedValues(data) {
 this.setState({
    afterPaginationIPAdvanceList: data
 })
}
DecimalAmount = (Useramount) => {
  var amount = String(Useramount).split(".")[0];
  var AfterDecimal = String(Useramount).split(".")[1]
  amount = amount.toString();
  var lastThree = amount.substring(amount.length - 3);
  var otherNumbers = amount.substring(0, amount.length - 3);
  if (otherNumbers != '')
    lastThree = ',' + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
}
WholeAmount = (Useramount) => {
  var amount = Useramount;
  amount = amount.toString();
  var lastThree = amount.substring(amount.length - 3);
  var otherNumbers = amount.substring(0, amount.length - 3);
  if (otherNumbers != '')
    lastThree = ',' + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
}
TooltipChange = (value) => {
  if (value % 1 != 0) {
    return this.DecimalAmount(value)
  }
  else if (value % 1 == 0) {
    return this.WholeAmount(value)
  }
}
renderTotalAmounts = (color, value, placehold) => {
  return (
    <View style={[styles.CardContainer, { backgroundColor: color }]}>
      <View style={styles.SubContainer}>
        <View style={{ marginRight: ".3vw" }}>
          <Image
            style={styles.ImageStyles}
            source={require("../../../../../assets/images/IPActions/Rupee.png")}
          />
        </View>
        <View style={styles.cardTooltipDiv} >
          {value && (value.toString().length) > 10 ?
            <Tooltip placement="topLeft" title={this.TooltipChange(value)}>
              <Text style={styles.TooltipText}>
                {value && value.toString().length > 10 ? value && this.TooltipChange((value.toString()).slice(0, 10)) + ".." : ''}
              </Text>
            </Tooltip> :
            <Text style={{ fontSize: value && value.toString().length > 8 ? ".8vw" : "1.01vw", fontWeight: "bold" }}>{value ? this.TooltipChange(value) : 0}</Text>
          }
        </View>
      </View>
      <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
        <Text style={{ fontSize: ".6vw" }}>{placehold}</Text>
      </View>
    </View>
  )
}
showSelectedFilteredDate = (date) => {
  var states = this.state;
  states["filterDate"] = date
  states["fromDate"] = date.fromDate;
  states["toDate"] = date.toDate;
  this.setState({ states }, () => {
    this.getIPAdvanceReport()
  })
}
renderFilterOPtion = () => {
  return(
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
    <View style={{ margin: "0.4vw", flexDirection: "row", alignItems: "center" }}>
      <Text style = {styles.headerText}>Statistics</Text>
      {this.renderTotalAmounts(color.cardLightGreen, this.state.paidAmount, "Paid Amount")}
      {this.renderTotalAmounts(color.cardLightOrange, this.state.pendingAmount, "Pending Amount")}
      {this.renderTotalAmounts(color.cardLightGrey, this.state.totalAmount, "Total Amount")}  
    </View> 
      <View style={{ flexDirection: "row", alignItems:"center", marginRight: "1vw", zIndex: 1 }}>
        <View style={{ flexDirection: "row", zIndex: 0, marginRight: "2vw" }}>
          <TextInput
            value={this.state.searchPatient}
            onChangeText={(text) => {
              this.setState({
                searchPatient: text
              })
            }}
            style={{ borderWidth: 1, fontSize: "0.9vw", height: "5vh", width: "11vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray }}
            placeholder="Search Patinet Name..."
          />
          <TouchableOpacity
            style={{ height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
            onPress={() => {
               this.getIPAdvanceReport()
            }}>
            <Icon name='search' size={"1.2vw"} color={color.white}></Icon>
          </TouchableOpacity>
        </View>
        <CommonDateFilter
          removeObject={"all"}
          startDate={this.state.fromDate}
          clearPress={this.state.isclear}
          endDate={this.state.toDate}
          filterDate={this.state.filterDate}
          defaultSelectedDateFilter={"Today"}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
          <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "20%", addingVertical: 8, paddingHorizontal: 8, height: "2vw", marginLeft: "0.5vw" }} onPress={()=> this.printReport()}>
             <Text style={{ color: 'white', fontSize: 12, }}>{"Export as Excel"}</Text>
          </TouchableOpacity>
      </View>
    </View>   
  )
}

render() {
    return (
          <View style= {styles.mainContainer}> 
              {this.renderFilterOPtion()}
              {this.renderHeader()} 
              {this.renderIPAdvanceDataList()} 
              {this.renderPaginationView()}
          </View>
    );
  }
}
const styles = StyleSheet.create({
    container: {
        margin: "0.4vw"
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.8vw",
        justifyContent: 'center',
        flex: 1,
        alignItems: "center",
      },
      TableDataText:{
        fontSize:'1vw'
      },
      paginationContainer: {
        marginHorizontal: "1.2vw"
      },
      TableSubText: {
          fontSize: "0.8vw"
      },
      HeaderMain: {
        marginTop: "1vw" ,
        zIndex: -1
      },
      mainContainer: { width: "98%", height: "100%", backgroundColor: color.white, margin: "1vw" },
      NoRecordText: { color: color.placeholder, marginTop: "10vw" },
      NoRecordContainer: { flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" },
      headerText: { fontSize: 18, fontWeight: "500" },
      CardContainer: { marginRight: ".65vw", borderRadius: ".25vw", padding: "1vw", height: "3.5vw", marginLeft: "0.5vw", 
      shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5, },
      SubContainer: { flexDirection: "row", justifyContent: "flex-end", minWidth: "7.2vw" },
      cardValue: { color: "white", fontSize: "1.05vw", fontWeight: "bold" },
      ImageStyles:{ 
          width : "1.25vw", 
          height : "1.25vw",
          marginTop: "0.2vw",
          tintColor: color.black  
      },
      TooltipText:{ 
        // color: "white", 
        fontSize: "1vw", 
        fontWeight: "bold" 
      },
    })      
