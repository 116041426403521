import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
// import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { color } from "../../../styles/Color";
import { NavigationTopHeader } from '../BaseComponent'
import Style from "../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';
// var AWS = require('aws-sdk/dist/aws-sdk-react-native');
var AWS = require('aws-sdk');
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});

const platform = Platform.OS;
const pageTitle = "Documents";
const pageName = "documents";
const isPageName = "isDocuments";
const navigationHeaderList = [
  { label: "Uploaded Images", value: "uploadedImages" },
  { label: "Transmitted Images", value: "transmittedImages" }

]
export default class Documents extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      isComplaint: isPageName === this.props.selectedView?true:false,

      showDocuments:[],
      covertedToImageData:[],

      selectedTab : "Uploaded Images",
      transmittedImages : [],
      convertedTransmittedImage : [],
      selectedTransImageId : "",
      selectedImage : ""
    };
  }

  componentDidMount() {
      this.getDocumentDetails()
      this.getTransmittedList()
  }

  getTransmittedList = () => {
    let service_url = Constants.OPT_TRANSMITED_IMAGE_LIST + "?appointment_id=" + this.state.patientAppointment.appointment_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTransmittedListSuccess,
      this.getTransmittedListFailure
    );
  }
  getTransmittedListSuccess = (response) => {
      if(response.status == "success"){
        this.setState({
          transmittedImages: response.transmitted_items
        }, ()=>{
          this._renderTransmitedImage(this.state.transmittedImages);
        })
      }
  }
  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    } else if( props && props.selectedTransImageId ){
         this.setState({
          selectedTransImageId : this.props.selectedTransmitID
         })
    }
  
    if(props.reloadData && props.reloadData === pageName) {
      // call once
      // this.getComplaints();
      // refresh false
      this.getDocumentDetails()
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  async _renderTransmitedImage(showTransmittedImage){

    let tempArr = [];

    for(var i=0;i<showTransmittedImage.length; i++){      
      var bill_date =showTransmittedImage[i].bill_date
      var lab_img_billing_id = showTransmittedImage[i].lab_img_billing_id
      var lab_img_result_id = showTransmittedImage[i].lab_img_result_id
      var result_img_url = showTransmittedImage[i].result_img_url
      var test_name = showTransmittedImage[i].test_name
      var getData = await this.getS3Image(bill_date, lab_img_billing_id, lab_img_result_id, result_img_url, test_name)
      tempArr.push(getData)
    }
    this.setState({ 
      convertedTransmittedImage : tempArr
  })
  
  } 
  getS3Image(bill_date, lab_img_billing_id, lab_img_result_id, result_img_url, test_name) {


    return new Promise(resolve => {
      var parms = { 
        // Bucket: Constants.s3BUCKET_NAME, 
        Bucket: "emedhub",
        Key: result_img_url, Expires: 300 };
      s3.getSignedUrl("getObject", parms, (err, result_img_url) => {
        if (err) {
          console.log(err);
          resolve(err)
        } else {
          var data = {
            "bill_date": bill_date,
            "lab_img_billing_id": lab_img_billing_id,
            "lab_img_result_id": lab_img_result_id,
            "result_img_url": result_img_url,
            "test_name": test_name,
          }
          resolve(data)
        }
      });
    });
  }
  editComplaints = (item, event) => {
    // console.log("editComplaints");
    this.props.editItem('complaints', item);
  }
  
  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }
  renderTransmittedImages() {
    return(
        <View style={styles.TransmittedImgView}>
          { this.state.convertedTransmittedImage && this.state.convertedTransmittedImage.length > 0 && this.state.convertedTransmittedImage.map((item,index) => {
          return (   
          <View style = {{ backgroundColor : this.state.selectedImage === item.lab_img_billing_id ? color.themeDark : null, margin: '1.2vw' }}>
          <TouchableOpacity 
             style={[styles.TransmittedImgTouch, {borderColor: color.whiteSmoke}]}
             onPress={()=>{
                 this.setState({
                   selectedImage : item.lab_img_billing_id
                 })
                 this.props.selectedTransmittedImage(item.lab_img_billing_id)
             }}
          >
            <Image source={item.result_img_url} style = {styles.ImgView} />
           <View style={{ justifyContent: 'center', alignItems : "center" }}>
            <Tooltip placement="topLeft" title={item.test_name}>               
              <Text style={{fontSize: '0.8vw'}}>
                {item.test_name.length > 14 ? item.test_name.slice(0,12) + ".." : item.test_name}
              </Text>
            </Tooltip> 
            <Text style={ Style.fontSizeMedium }>{item.bill_date}</Text>
           </View>   
          </TouchableOpacity> 
          </View>
          )
        }) }
        </View>
    )
  }

  renderDocumentItem = item => {
    // alert(JSON.stringify(item))
    return (
      <View style={{ alignItems: "center", marginRight: '1.2vw' }}>
        <View>
         <TouchableOpacity   disabled={this.state.isLocked} onPress={()=>{this.deleteItem(item.id)}} style={[styles.DocumentItemTouch, {backgroundColor:color.themeDark}]}>
          <Text style={styles.DocumentItemText}>X</Text>
        </TouchableOpacity>

        <TouchableOpacity
        style={{zIndex:-10}}
        onPress={()=>{
          this.props.triggerNewItem(isPageName);
          this.props.showDocumentImage(true,item.doc_key, this.state.covertedToImageData)}}>
        <Image
        
          // source={require("../../../../assets/images/opthamology/document_sample.png")}
          source={item.doc_key ? {uri:item.doc_key} : require("../../../../assets/images/opthamology/document_sample.png")}
          style={styles.DocumentImgView}
          resizeMode="contain"
        />
        </TouchableOpacity>
        </View>
        <Text style={styles.DocumentItemText1}>
          {item.doc_name}
        </Text>
      </View>
    );
  };
 
  onChangeTab = (value) => {
    if (value) {

      this.setState({
        selectedTab : value
      },()=>{
        this.props.transmittedImages(this.state.selectedTab)
      })
    }
  }
  renderNavigationSubHeader() {
    return (
      <View style={[styles.NavigationSubHeaderView, {backgroundColor: color.sectionHeadingDarkColor}]}>
        {
          navigationHeaderList && navigationHeaderList.length > 0 && navigationHeaderList.map((item, index) => {
            return (
              <TouchableOpacity onPress={() => { this.onChangeTab(item.label) }} style={{ paddingHorizontal: '0.6vw', justifyContent: "center"}} key={index}>
                <Text style={[{ fontSize: '0.9vw', fontWeight: "500" }, { color: this.state.selectedTab == item.label  ?  color.themeDark : color.black } ]}>{item.label}</Text>
              </TouchableOpacity>
            )
          })
        }
      </View>
    )
  }
  render() {
    return (
        <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
        {this.renderNavigationSubHeader()}
        <View>
       
        { this.state.selectedTab === "Uploaded Images" ? 
        <View style = {{ marginTop : '1.2vw' }}>     
         <FlatList
          data={this.state.covertedToImageData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          horizontal={true}
          renderItem={({ item, index }) =>
            this.renderDocumentItem(item, index)
          }
         />
        </View>  :   
         <View style={{ flexDirection: "row" }}>{this.renderTransmittedImages()}</View>
        }
     
      </View>
      </View>
    );
  }


  getS3Key(doc_key, doc_name, id) {


    return new Promise(resolve => {
      // alert("getS3Key  1")
      var parms = { 
        //  Bucket: Constants.s3BUCKET_NAME, 
        Bucket: "emedhub",
        Key: doc_key, Expires: 300 };
      s3.getSignedUrl("getObject", parms, (err, url) => {
        if (err) {
          console.log(err);
          // return err
          resolve(err)
        } else {
          // console.log(url);
          var data = {
            "doc_key": url,
            "doc_name": doc_name,
            "id": id
          }
          // alert("getS3Key   222 ")
          resolve(data)
        }
      });
    });
  }

  async _renderImage(showDocuments){
    // alert(JSON.stringify("_renderImage"))
    var {covertedToImageData} =this.state
    let tempArr = [];

    for(var i=0;i<showDocuments.length; i++){
    // alert(JSON.stringify("FOR LOOP"))
      
      var doc_key =showDocuments[i].doc_key
      var doc_name = showDocuments[i].doc_name
      var id = showDocuments[i].id
      
    //   var parms = { Bucket: Constants.s3BUCKET_NAME, Key: doc_key, Expires: 300 };
    //   s3.getSignedUrl("getObject", parms, (err, url)=> {
    //   if (err) {
    //     console.log(err);
    //     return err
    //   } else {
    //     console.log(url);
    //     var data={
    //       "doc_key":url,
    //       "doc_name":doc_name,
    //       "id":id
    //     }
    //     tempArr.push(data)
    //     return true
    //   }
    // });

      var getData = await this.getS3Key(doc_key,doc_name,id)
      // alert("getData  last 4")
      // alert(JSON.stringify(getData))

      tempArr.push(getData)
    }

    this.setState({ 
        covertedToImageData : tempArr
    },()=>{})
    // alert(JSON.stringify(this.state.covertedToImageData))
    
  }

  deleteItem (id) {
    // alert(id)
    //this.props.editItem(pageName, item);
    var service_url = Constants.DELETE_UPLOADED_DOCUMENTS+id+"/";

    OpthamologyService.getInstance().postComplaints(
      service_url,
      {},
      this,
      this.deleteItemSuccess,
      this.deleteItemFailure
    );
  };

  deleteItemSuccess=success=>{
    if(success.status=="success"){
      // alert("success --- > "+JSON.stringify(success))
      this.getDocumentDetails()
    this.props.showResposeValue("success",success.message)
    // console.log(JSON.stringify(success))
  }else{
    // alert("success --- >2 "+JSON.stringify(success))
    // console.log(JSON.stringify(success))

    // alert(JSON.stringify(success))
    this.props.showResposeValue("error",success.message)
  }
  }
  deleteItemFailure=error=>{
    // alert("success --- >err "+JSON.stringify(success))
    console.log(JSON.stringify(error))

    this.props.showResposeValue("error",error.message)

  }



  getDocumentDetails(){
    var service_url = Constants.DOCUMENT_UPLOAD +"?appointment_id="+this.props.patientAppointment.appointment_id + "&patient_id=" + this.props.patientAppointment.patient_id
    // var service_url = Constants.DOMAIN+"ophthalmology/subjectiverefraction/?appointment_id="+this.props.patientAppointment.appointment_id
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getDocumentDetailsSuccess,
      this.getDocumentDetailsFailure
    );
  }
  getDocumentDetailsSuccess= (response)=>{
    if(response && response.data && response.status === "success"){
      
      if(response.data.documents){
        // alert(JSON.stringify(response))
        this.setState({
          showDocuments:response.data.documents
        },()=>{
          this._renderImage(this.state.showDocuments);
        })
      }
    }
  }

  getDocumentDetailsFailure= (error)=>{
    console.log("getDocumentDetailserror --------------------->>> "+JSON.stringify(error));
  }
}

const styles = StyleSheet.create({
      NavigationSubHeaderView: { 
        height: '3vw', 
        flexDirection: "row", 
        borderRadius :4, 
        marginTop : '0.6vw' 
      },
      TransmittedImgView: { 
        marginTop : '0.6vw', 
        alignItems : "flex-start", 
        zIndex : 1, 
        flex: 1, 
        flexDirection: "row", 
        flexWrap : "wrap", 
        width: '10vw'
      },
      TransmittedImgTouch: { 
        borderWidth : 2, 
        elevation : 5, 
        padding: '0.3vw' , 
        zIndex: 1, 
        borderRadius : 12 
      },
      ImgView: { 
        width : '8.5vw', 
        height : '4.8vw'  
      },
      DocumentItemTouch: {
        justifyContent:'center',
        height:'1.2vw',
        width:'1.2vw',
        borderRadius:30,
        alignSelf:'flex-end'
      },
      DocumentItemText: {
        fontSize:'0.8vw',
        color:'white',
        textAlign:'center'
      },
      DocumentImgView: {
        zIndex:-1, 
        width: '3.3vw', 
        height: '3.3vw' 
      },
      DocumentItemText1: { 
        marginTop: '0.6vw', 
        fontSize: '0.6vw', 
        color: "#000000" 
      }
})