import React, { Component } from 'react';
import { View, Text, ScrollView, StyleSheet, TouchableOpacity, Image, TextInput } from 'react-native';
import CommonDateFilter, { NavigationTopHeader } from '../../BaseComponent';
import Pagination from '../../common/Pagination';
import { color } from '../../../../styles/Color';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';
import ImagePath from '../../../../utils/ImagePaths';

const navigationHeader = [
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "InBound", value: "inBound" },
    { label: "OutBound", value: "outBound" },
    { label: "Stock Transfer Report", value: "stockTransferReport" }
]
const navigationAdmin = [
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "Approval Request", value: "approvalRequest"},
    { label: "Stock Transfer Report", value: "stockTransferReport" }
]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class StockTransferReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockReportList: [],
            stockReportListAfterPagination: [],
            current_page: 1,
            records_per_page: 10,
            userType: this.props.userType,
            filterDate: {
                fromDate: converted_date,
                toDate: converted_date
            },
            searchBranch: "",
            outBoundPopupList: [],
            headerDetails: {}
        };
    }
    componentDidMount() {
        this.getStockReportList()
    }
      getStockReportList = () => {
        this.setState({
            stockReportList: [],
            stockReportListAfterPagination: [],
            current_page: 1,
            records_per_page: 10
        })
        var url = Constants.STOCK_TRANSFER_REPORT + "?brand_name=" + this.state.searchBranch + "&from_date=" + this.state.filterDate.fromDate+ "&to_date=" + this.state.filterDate.toDate
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getStockReportListSuccess,
            this.getStockReportListFailure
        );
    }
    
    getStockReportListSuccess = response => {
        if (response && response.status == "success") {
            var data  = response.data
            this.setState({
                stockReportList: data
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    getStockReportListFailure = error => {
        this.props.showResposeValue("error", error.message)
    }
    getPopupDataList = (item) => {
        var url = Constants.STOCK_TRANSFER_INBOUND + "?summary_list=" + "&id=" + item.id
        OpthamologyService.getInstance().getComplaints(
          url,
          this,
          this.getPopupDataListSuccess,
          this.getPopupDataListFailure
        );
      }
    
      getPopupDataListSuccess = response => {
        if (response && response.status == "success") {
          this.setState({
            headerDetails: response.header_details,
            outBoundPopupList: response.line_items
          })
        } else {
          this.props.showResposeValue("error", response.message)
        }
      }
      getPopupDataListFailure = error => {
        this.props.showResposeValue("error", error.message)
      }
    changeTabSection(data) {
        // do nothing
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
        return (
            <View style={{ flex: flexWidth }}>
                <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
            </View>)
    }
    renderHeaderView = () => {
        return (
            // <View style={styles.drugHeaderContainer}>
            <View style={styles.subContainer}>
                {this.getBillTableTitle('ST.No', 0.1, "0.95vw")}
                {this.getBillTableTitle('ST Date', 0.15, "0.95vw")}
                {this.getBillTableTitle('From Branch', 0.15, "0.95vw")}
                {this.getBillTableTitle('To Branch', 0.15, "0.95vw")}
                {this.getBillTableTitle('Total Value', 0.15, "0.95vw")}
                {this.getBillTableTitle('No.Of Product', 0.1, "0.95vw")}
                {this.getBillTableTitle('Transfer Type', 0.1, "0.95vw")}
                {this.getBillTableTitle('Action', 0.05, "0.95vw")}

            </View>
            // </View>
        )
    }
    renderTableData = () => {
        var { current_page, records_per_page } = this.state
        return (
            <ScrollView showsVerticalScrollIndicator={false} style={{ height: "32vw" }}>
                {this.state.stockReportListAfterPagination && this.state.stockReportListAfterPagination.length > 0 ?
                    this.state.stockReportListAfterPagination && this.state.stockReportListAfterPagination.map((item, index) => {
                        let sno = (current_page - 1) * records_per_page + index + 1;
                        return (
                            <View style={[styles.dataContainer, { marginLeft: "0.5vw" }]}>
                                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.st_no}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.st_date}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.from_name}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.to_branch}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.total_amount}</Text></View>
                                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.no_of_product}</Text></View>
                                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.corrier_type}</Text></View>
                                <View style={{ flex: 0.1 }}>
                                    <TouchableOpacity style={[styles.addButton, { width: "60%" }]} onPress={() => {
                                        this.setState({
                                            isUpdateClick: true
                                        },() =>{
                                            this.getPopupDataList(item)
                                        })
                                    }}>
                                        <View style={{ flexDirection: "row" }}>
                                            <Image source={ImagePath.EyeIcon} style={styles.PrintIconImg} />
                                            <Text style={styles.addText}>View</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>

                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
                    </View>}
            </ScrollView>
        )
    }
    paginationChangedValues(data) {
        this.setState({
            stockReportListAfterPagination: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }
    renderPaginationView() {
        return (
            <View style={styles.paginationContainer}>
                {
                    this.state.stockReportList && this.state.stockReportList.length > 0 ?
                        <Pagination
                            paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalItems={this.state.stockReportList}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        /> : null
                }
            </View>
        )
    }
    showSelectedFilteredDate(date) {
        var states = this.state.filterDate;
        states["filterDate"] = date
        states["fromDate"] = date.fromDate;
        states["toDate"] = date.toDate;
        this.setState({ states }, () => {
            this.getStockReportList()
        })
      }
    renderSubHeaderView = () => {
      return(
          <View style = {{ zIndex: 1, flexDirection: "row", justifyContent: "space-between" }}>
             <View style={{ flexDirection: "row", zIndex: 0 }}>
              <TextInput
                value={this.state.searchIol}
                onChangeText={(text) => {
                  this.setState({
                    searchBranch: text
                  })
                }}
                style={{ borderWidth: 1, fontSize: "0.9vw", height: "5vh", width: "11vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray }}
                placeholder="Search Branch Name..."
              />
              <TouchableOpacity
                style={{ height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                  this.getStockReportList()
                }}>
                <Icon name='search' size={"1.2vw"} color={color.white}></Icon>
              </TouchableOpacity>
            </View>
            <View> 
             
             <CommonDateFilter
              startDate={this.state.filterDate.fromDate}
              endDate={this.state.filterDate.toDate}
              filterDate={this.state.filterDate}
              defaultSelectedDateFilter={"Today"}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
            //   removeObject={"all"}
             />
            </View>
           
          </View>
      )
    }
    renderPopupTextView = (label, value, key) => {
        return (
    
          <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 0.25 }}>
            <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{label + " : "}</Text>
            <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{value}</Text>
          </View>
        )
      }
    renderHeaderViews = () => {
        var { headerDetails } = this.state
        let stock_transfer_date = headerDetails.stock_transfer_date ? moment(headerDetails.stock_transfer_date).format("DD-MM-YYYY") : "-"
        let date = headerDetails.delivery_date ? moment(headerDetails.delivery_date).format("DD-MM-YYYY") : "-"

        return (
          <View>
            <TouchableOpacity style={{flex: 1, alignSelf: "flex-end" }} onPress={() => { this.setState({ isUpdateClick: false })}}>
             <Icon name="window-close-o" size={'1vw'} color={color.themeDark} />
            </TouchableOpacity>  
            <View style={{ flexDirection: "row" }}> 
              {this.renderPopupTextView("From Branch", headerDetails.from_branch)}
              {this.renderPopupTextView("To Branch", headerDetails.to_branch)}
              {this.renderPopupTextView("Stock Transfer Value", headerDetails.stock_transfer_value)}
              {this.renderPopupTextView("Stock Trans Date", stock_transfer_date)}
            </View>
            <View style={{ flexDirection: "row" }}>
              {this.renderPopupTextView("Contact Details", headerDetails.from_branch_contact)}
              {this.renderPopupTextView("Contact Details", headerDetails.to_branch_contact)}
              {this.renderPopupTextView("Delivery Date", date)}
              {this.renderPopupTextView("Stock Transfer No", headerDetails.stock_transfer_no)}
            </View>
          </View>
        )
      }
      renderPopupHeader = () => {
        return (
          <View style={styles.subContainer}>
            {this.getBillTableTitle('S.No', 0.05, "0.8vw")}
            {this.getBillTableTitle('Brand', 0.15, "0.8vw")}
            {this.getBillTableTitle('Generic / Combo', 0.15, "0.8vw")}
            {this.getBillTableTitle('Dosage Type / Strength', 0.12, "0.8vw")}
            {this.getBillTableTitle('Batch No', 0.13, "0.8vw")}
            {this.getBillTableTitle('Exp On', 0.1, "0.8vw")}
            {this.getBillTableTitle('Selected Quan', 0.1, "0.8vw")}
            {this.getBillTableTitle('Transfer Quantity', 0.1, "0.8vw")}
            {this.getBillTableTitle('Total', 0.1, "0.8vw")}
          </View>
        )
      }
      renderTableDataView = () => {
        var { current_page, records_per_page, stockDetails, outBoundPopupList, typedIndex } = this.state
        return (
          <ScrollView showsVerticalScrollIndicator={false} style={{ height: "15vw" }}>
            {this.state.outBoundPopupList && this.state.outBoundPopupList.length > 0 ?
              this.state.outBoundPopupList && this.state.outBoundPopupList.map((item, index) => {
                let sno = (current_page - 1) * records_per_page + index + 1;
                let zIndex = (index + 1) * -10;
                var stock_details = JSON.parse(item.stock_details) ? JSON.parse(item.stock_details) : []            
                var batch_no = []
                var exp_on = []
                var total = 0
                var quantity = 0
                for ( let i =0 ; i < stock_details.length ; i++ ) {
                //  if(stock_details[i].isSelected) {
                  total = total + (stock_details[i].quantity * stock_details[i].mrp_per_quantity)
                  batch_no.push(stock_details[i].batch_no)
                  exp_on.push(stock_details[i].expiry_date)
                  quantity = quantity + item.request_quantity
                //  }
                }
                return (
                  <View style={[styles.dataContainer, { marginLeft: "0.2vw", zIndex: zIndex }]}>
                    <View style={{ flex: 0.05 }}><Text style={styles.TableDataText}>{sno}</Text></View>
                    <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.drug_name}</Text></View>
                    <View style={{ flex: 0.15 }}>
                     <Tooltip placement="topLeft" title={item.generic_name ? item.generic_name : ""}>
                        <Text style={styles.TableDataText}>{item.generic_name.length > 14 ? item.generic_name.slice(0, 14) + ".." : item.generic_name}</Text>
                     </Tooltip>
                    </View>  
                    <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.dosage_type + " / " + item.drug_strength}</Text></View>
                    <View style={{ flex: 0.13 }}>
                      <TextInput
                        value={batch_no}
                        style={[styles.textBox, {width: "95%", height: "4vh"}]}
                      />
                      <Text style={styles.TableDataText}>{(item.total)}</Text>
                    </View>
                    <View style={{ flex: 0.1 }}> 
                    <TextInput
                        value={exp_on }
                        onChangeText={(text) => { 
                          
                        }}
                        style={[styles.textBox, {width: "95%", height: "4vh"}]}
                    /></View>
                   <View style={{ flex: 0.1 }}> 
                      <TextInput
                        value={ outBoundPopupList[index].selected_qty}
                        onChangeText={(text) => { 
                          outBoundPopupList[index].selected_qty
                          this.setState({
                            outBoundPopupList
                          })
                        }}
                        style={[styles.textBox, {width: "95%", height: "4vh"}]}
                      /></View>
                    <View style={{ flex: 0.1 }}>
                    <TextInput
                        value={outBoundPopupList[index].request_quantity}
                        onChangeText={(text) => { 
                          outBoundPopupList[index].request_quantity = text
                          this.setState({
                            outBoundPopupList
                          })
                        }}
                        style={[styles.textBox, {width: "50%", height: "4vh"}]}
                    />
                    </View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{total? parseFloat(total).toFixed(2) : 0}</Text></View>
    
                  </View>
                )
              }) :
              <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
              </View>}
            
          </ScrollView>
        )
      }
    render() {
        return (
            <View style={styles.container}>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={ this.state.userType === "SUPER_ADMIN" ? navigationAdmin : navigationHeader}
                    selectedTab={"stockTransferReport"}
                    isNavigationBorder={true}
                />
                <View style = {{ margin: "0.5vw" }}>
                    {this.renderSubHeaderView()}
                    {this.renderHeaderView()}
                    {this.renderTableData()}
                    {this.renderPaginationView()}
                </View>
                {this.state.isUpdateClick ?
                    <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
                        <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "75%", height: "85%", borderRadius: 8, borderWidth: 1, }}>
                            <View>
                                {this.renderHeaderViews()}
                                <View style={{ height: "45vh", }}>
                                    {this.renderPopupHeader()}
                                    {this.renderTableDataView()}
                                </View>
                            </View>
                        </View>
                    </View> : null}
            </View>

        );
    }
}
const styles = StyleSheet.create({
    paginationContainer: {
        marginHorizontal: "1.2vw"
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.6vw",
        justifyContent: 'center',
        flex: 1,
        justifyContent: "flex-start",
        alignContent: "center"
    },
    subContainer: {
        flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: "0.4vw", borderRadius: 4, marginTop: '0.5vw'
    },
    container: {
        // margin: "0.5vw"
    },
    addButton: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        paddingVertical: "0.4vw",
        paddingHorizontal: "0.7vw",
        justifyContent: "center",
        alignItems: "center"
    },
    addText: {
        color: color.white,
        fontSize: "0.8vw",
        marginLeft: "0.4vw"
    },
    PrintIconImg: {
        height: '0.87vw',
        width: '0.87vw',
        alignSelf: "center"
    }
     

})

