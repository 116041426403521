import React, { Component } from 'react';
import { ScrollView, TouchableOpacity, View, Image, Text, StyleSheet } from 'react-native'
import { CommonHistorySectionHeader } from '../../BaseComponent';
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../../assets/images/BillConfig/delete.svg'
import DropDownIcon from '../../../../../assets/images/BillConfig/Down_Arrow.svg'
import { color } from '../../../../styles/Color'
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';


export default class IpTemplateConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropDownIndex: null,
      ipServiceList: [],
      ipListId: "",
      selectedIptemplate: [],
      ipTemplateRefresh: this.props.ipTemplateRefresh,
      searchKey: this.props.searchKey,
      userType: this.props.userType,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
    }
  }

  componentDidMount() {
    this.getIpServiceList()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ipTemplateRefresh !== this.state.ipTemplateRefresh || nextProps.searchKey !== this.state.searchKey) {
      this.setState({
        searchKey: nextProps.searchKey
      }, () => this.getIpServiceList())

    }
  }

  getIpServiceList = () => {
    var url= ""
    var states = this.state
    if(states.userType === Constants.ROLE_FO){
      url = Constants.CLINIC_IP_TEMPLATE_GET + this.props.searchKey;
    }else{
      url = Constants.CLINIC_IP_TEMPLATE_GET + this.props.searchKey + "&clinic_id=" + states.selectedClinicOrDoctor.id;
    }

    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSuccess,
      this.getFaliure
    )
  }

  getSuccess = (response) => {
    this.setState({
      ipServiceList: []
    })
    if (response.status === "success") {
      this.setState({ ipServiceList: response.template_data })
    }
  }

  getfailure = (response) => {
    this.props.showResposeValue("error", response.message)
  }

  deleteIpTemplate = () => {
    let url = Constants.CLINIC_IP_TEMPLATE_DELETE + this.state.ipListId;

    OpthamologyService.getInstance().deleteComplaints(
      url,
      {},
      this,
      this.deleteSuccess,
      this.deleteFailure
    )
  }

  deleteSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)
      let { ipServiceList } = this.state;
      this.setState({ ipServiceList },
        () => this.getIpServiceList())
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }

  deleteFailure = (response) => {
    this.props.showResposeValue("error", response.message)
  }

  render() {
    let { ipServiceList, dropDownIndex } = this.state
    return (
      <View style={{ maxHeight: "90%" }}>
        <View style={Styles.TableHeader}>
          <CommonHistorySectionHeader
            heading1={"Service Name"}
            heading2={"Action"}
            noOfColumn={2}
            columnSize={[0.715, 0.235]}
          />
        </View>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={Styles.TableBody}>
          {ipServiceList && ipServiceList?.length > 0 ? (ipServiceList?.map((item, index) => (
            <ScrollView>
              <View key={index}>
                <TouchableOpacity
                  style={[Styles.TableBodyView, {
                    backgroundColor: index % 2 === 0 ?
                      color.white : color.appointmentTableHead
                  }]}
                  onPress={() => {
                    this.setState({
                      dropDownIndex: index,
                      selectedIptemplate: ipServiceList[index].service_data
                    })
                  }}>
                  <View style={Styles.ColumnLeft}>
                    <Text style={Styles.ColumnLeftText}>{item.template_name}</Text>
                  </View>
                  <View style={Styles.ColumnRight}>
                    <View style={Styles.FlexOne}>
                      <TouchableOpacity
                        onPress={
                          ()=> {
                          this.getIpServiceList()
                          this.setState({dropDownIndex:null},()=>{
                              this.props.editIpTemplate(item) 
                          })}
                      } 
                      >
                        <Image
                          source={EditIcon}
                          style={{ height: 25, width: 25 }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                    <View style={Styles.FlexTwo}>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ ipListId: item.id },
                            () => { this.deleteIpTemplate() })
                        }}
                      >
                        <Image
                          source={DeleteIcon}
                          style={{ height: 25, width: 30 }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                    <View style={Styles.FlexThree}>
                      <TouchableOpacity
                        onPress={() => {
                          let Index = null;
                          if (dropDownIndex === index) {
                            Index
                          } else {
                            Index = index
                          }
                          this.setState({
                            dropDownIndex: Index,
                            selectedIptemplate: ipServiceList[index].service_data
                          })
                        }}>
                        <Image
                          source={DropDownIcon}
                          style={{ height: 25, width: 25 }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableOpacity>
                {dropDownIndex === index ? (
                  <ScrollView>
                    <View style={[Styles.DropDownCard, { border: "1px solid black" }]}>
                      <View>
                        <CommonHistorySectionHeader
                          heading1={"service Type"}
                          heading2={"Amount"}
                          noOfColumn={2}
                          columnSize={[0.5, 0.5]}
                        />
                      </View>
                      {
                        this.state?.selectedIptemplate && this.state?.selectedIptemplate?.length > 0 ?
                          (
                            this.state.selectedIptemplate?.map((item, index) => {
                              return (
                                <View style={[Styles.dropDownTableBody, { backgroundColor: index % 2 === 0 ? color.white : color.applicationBackgroundColor }]}>
                                  <View style={Styles.TableBodyOne}>
                                    <Text>{item.name}</Text>
                                  </View>
                                  <View style={Styles.TableBodyTwo}>
                                    <Text>{item.rate_per_unit}</Text>
                                  </View>
                                </View>

                              )
                            })
                          ) : (null)
                      }
                    </View>
                  </ScrollView>
                ) : (null)
                }
              </View>
            </ScrollView>
          ))
          ) : (
            <View style={{ margin: "auto", position: "relative", top: "25vh" }}>
              <Text style={{ color: color.themeDark, fontSize: "1.5vw" }}>No Data To Be Shown</Text>
            </View>
          )
          }
        </ScrollView>
      </View>
    )
  }
}


const Styles = StyleSheet.create({
  TableHeader: {
    marginHorizontal: "0.5vw",
    marginTop: "0.5vw"
  },
  TableBody: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw"
  },
  TableBodyView: {
    flexDirection: "row",
    height: "6vh",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.2vw"
  },
  ColumnLeft: {
    flex: 0.7
  },
  ColumnRight: {
    flexDirection: "row",
    flex: 0.3,
    marginLeft: "0.5vw"
  },
  ColumnLeftText: {
    fontSize: "1vw",
    paddingLeft: "1.45vw"
  },
  FlexOne: {
    flex: 0.3
  },
  FlexTwo: {
    flex: 0.35
  },
  FlexThree: {
    flex: 0.35
  },
  DropDownCard: {
    marginVertical: "0.5vw",
    marginHorizontal: "8vw"
  },
  dropDownTableBody: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

  },
  TableBodyOne: {
    flex: 0.5,
    fontSize: "1vw",
    marginVertical: "0.6vw",
    paddingLeft: "1.45vw"
  },
  TableBodyTwo: {
    flex: 0.5,
    fontSize: "1vw",
    paddingRight: "0.8vw"
  }
})
