import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "EffectsOnPhysicalHealth"


export class EffectsOnPhysicalHealthNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            hematemesisFlag: false,
            detailsOfHematemesis: "",

            peripheralNeuropathyFlag: false,
            detailsOfPeripheralNeuropathy: "",

            malenaFlag: false,
            detailsOfMalena: "",

            accidentFlag: false,
            detailsOfAccident: "",

            jaundiceFlag: false,
            detailsOfJaundice: "",

            headInjuryFlag: false,
            detailsOfHeadInjury: "",

            pedalOedemaFlag: false,
            detailsOfPedalOedema: "",

            fallsFlag: false,
            detailsOfFalls: "",

            abdominalDistensionFlag: false,
            detailsOfAbdominalDistension: "",

            congnitiveDeflictsFlag: false,
            detailsOfCongnitiveDeflicts: "",

            unsteadyGaitFlag: false,
            detailsOfUnsteadyGait: "",




        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;


                states["hematemesisFlag"] = data.hematemesis;
                states["detailsOfHematemesis"] = data.hematemesis_desc;

                states["peripheralNeuropathyFlag"] = data.peripheral_neuropathy;
                states["detailsOfPeripheralNeuropathy"] = data.peripheral_neuropathy_desc;

                states["malenaFlag"] = data.malena;
                states["detailsOfMalena"] = data.malena_desc;

                states["accidentFlag"] = data.accident;
                states["detailsOfAccident"] = data.accident_desc;

                states["jaundiceFlag"] = data.jaundice;
                states["detailsOfJaundice"] = data.jaundice_desc;

                states["headInjuryFlag"] = data.head_injury;
                states["detailsOfHeadInjury"] = data.head_injury_desc;

                states["pedalOedemaFlag"] = data.paedal_oedema;
                states["detailsOfPedalOedema"] = data.paedal_oedema_desc;

                states["fallsFlag"] = data.falls;
                states["detailsOfFalls"] = data.falls_desc;

                states["abdominalDistensionFlag"] = data.abdominal_destination;
                states["detailsOfAbdominalDistension"] = data.abdominal_destination_desc;

                states["congnitiveDeflictsFlag"] = data.cognitive_deflicts;
                states["detailsOfCongnitiveDeflicts"] = data.cognitive_deflicts_desc

                states["unsteadyGaitFlag"] = data.unsteady_gait;
                states["detailsOfUnsteadyGait"] = data.unsteady_gait_desc;

                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""

        states["hematemesisFlag"] = false;
        states["detailsOfHematemesis"] = "";

        states["peripheralNeuropathyFlag"] = false;
        states["detailsOfPeripheralNeuropathy"] = "";

        states["malenaFlag"] = false;
        states["detailsOfMalena"] = "";

        states["accidentFlag"] = false;
        states["detailsOfAccident"] = "";

        states["jaundiceFlag"] = false;
        states["detailsOfJaundice"] = "";

        states["headInjuryFlag"] = false;
        states["detailsOfHeadInjury"] = "";

        states["pedalOedemaFlag"] = false;
        states["detailsOfPedalOedema"] = "";

        states["fallsFlag"] = false;
        states["detailsOfFalls"] = "";

        states["abdominalDistensionFlag"] = false;
        states["detailsOfAbdominalDistension"] = "";

        states["congnitiveDeflictsFlag"] = false;
        states["detailsOfCongnitiveDeflicts"] = "";

        states["unsteadyGaitFlag"] = false;
        states["detailsOfUnsteadyGait"] = "";

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,

                "hematemesis": states.hematemesisFlag,
                "hematemesis_desc": states.detailsOfHematemesis,

                "malena": states.malenaFlag,
                "malena_desc": states.detailsOfMalena,

                "jaundice": states.jaundiceFlag,
                "jaundice_desc": states.detailsOfJaundice,

                "paedal_oedema": states.pedalOedemaFlag,
                "paedal_oedema_desc": states.detailsOfPedalOedema,

                "abdominal_destination": states.abdominalDistensionFlag,
                "abdominal_destination_desc": states.detailsOfAbdominalDistension,

                "unsteady_gait": states.unsteadyGaitFlag,
                "unsteady_gait_desc": states.detailsOfUnsteadyGait,

                "peripheral_neuropathy": states.peripheralNeuropathyFlag,
                "peripheral_neuropathy_desc": states.detailsOfPeripheralNeuropathy,

                "accident": states.accidentFlag,
                "accident_desc": states.detailsOfAccident,

                "head_injury": states.headInjuryFlag,
                "head_injury_desc": states.detailsOfHeadInjury,

                "falls": states.fallsFlag,
                "falls_desc": states.detailsOfFalls,

                "cognitive_deflicts": states.congnitiveDeflictsFlag,
                "cognitive_deflicts_desc": states.detailsOfCongnitiveDeflicts,
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PHYSICAL_HEALTH_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveEffectsOnPhysicalHealthSuccess,
                this.saveEffectsOnPhysicalHealthFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Effects on physical health already added please click on edit icon to update the values")
        }
    }

    saveEffectsOnPhysicalHealthSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveEffectsOnPhysicalHealthFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Effects on Physical Health"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Hematemesis", "Enter details",
                    "hematemesisFlag", this.state.hematemesisFlag,
                    "detailsOfHematemesis", this.state.detailsOfHematemesis
                )}

                {this.renderHistoryComponent(
                    "Peripheral Neuropathy", "Enter details",
                    "peripheralNeuropathyFlag", this.state.peripheralNeuropathyFlag,
                    "detailsOfPeripheralNeuropathy", this.state.detailsOfPeripheralNeuropathy
                )}
                {this.renderHistoryComponent(
                    "Malena", "Enter details",
                    "malenaFlag", this.state.malenaFlag,
                    "detailsOfMalena", this.state.detailsOfMalena
                )}

                {this.renderHistoryComponent(
                    "Accident", "Enter details",
                    "accidentFlag", this.state.accidentFlag,
                    "detailsOfAccident", this.state.detailsOfAccident
                )}

                {this.renderHistoryComponent(
                    "Jaundice", "Enter details",
                    "jaundiceFlag", this.state.jaundiceFlag,
                    "detailsOfJaundice", this.state.detailsOfJaundice
                )}


                {this.renderHistoryComponent(
                    "Head Injury", "Enter details",
                    "headInjuryFlag", this.state.headInjuryFlag,
                    "detailsOfHeadInjury", this.state.detailsOfHeadInjury
                )}

                {this.renderHistoryComponent(
                    "Pedal Oedema", "Enter details",
                    "pedalOedemaFlag", this.state.pedalOedemaFlag,
                    "detailsOfPedalOedema", this.state.detailsOfPedalOedema
                )}

                {this.renderHistoryComponent(
                    "Falls", "Enter details",
                    "fallsFlag", this.state.fallsFlag,
                    "detailsOfFalls", this.state.detailsOfFalls
                )}

                {this.renderHistoryComponent(
                    "Abdominal Distension", "Enter details",
                    "abdominalDistensionFlag", this.state.abdominalDistensionFlag,
                    "detailsOfAbdominalDistension", this.state.detailsOfAbdominalDistension
                )}

                {this.renderHistoryComponent(
                    "Cognitive Deflects", "Enter details",
                    "congnitiveDeflictsFlag", this.state.congnitiveDeflictsFlag,
                    "detailsOfCongnitiveDeflicts", this.state.detailsOfCongnitiveDeflicts
                )}


                {this.renderHistoryComponent(
                    "Unsteady Gait", "Enter details",
                    "unsteadyGaitFlag", this.state.unsteadyGaitFlag,
                    "detailsOfUnsteadyGait", this.state.detailsOfUnsteadyGait
                )}





                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
