import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "familyHistory"


export class FamilyHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            detailsOfFather: "",
            detailsOfMother: "",
            detailsOfSiblings: "",
            detailsOfOthers: "",
            detailsOfFamilyIntractionPattern: "",
            detailsOfCommunicationStyle: "",
            detailsOfLeadeshipPattern: "",
            detailsOfChildRearingPractices: "",

            mentalDisodersDetails: "",
            mentalDisodersFlag: false,

            consanguintyDetails: "",
            consanguintyFlag: false,

            cohesivenessDetails: "",
            cohesivenessFlag: false,

            familyRitualsDetails: "",
            familyRitualsFlag: false,

            familySupportSystemDetails: "",
            familySupportSystemFlag: false,

            selectedFamilyType: "",
            detailsOfFamilyEnvironmentAndRelationship: "",

            isheading:false,

        }
    }

    componentWillReceiveProps(props) {
        this.state.isheading = props.isheading
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states['id']= data.id,
                states['detailsOfFather']=data.father_details
                states['detailsOfMother']= data.mother_details
                states['detailsOfSiblings']= data.siblings_details
                states['detailsOfOthers']=data.others_details
                states['detailsOfFamilyIntractionPattern']= data.family_interact_pattern
                states['detailsOfCommunicationStyle']= data.communication_style
                states['detailsOfLeadeshipPattern']=data.leadership_pattern
                states['detailsOfChildRearingPractices']= data.child_rear_practice
                states['mentalDisodersDetails']= data.mental_disorder_details
                states['mentalDisodersFlag']=data.mental_disorder
                states['consanguintyDetails']= data.consanguinty_details
                states['consanguintyFlag']= data.consanguinty


                states['cohesivenessDetails']= data.cohesiveness_details
                states['cohesivenessFlag']=data.cohesiveness
                states['familyRitualsDetails']= data.family_rituals_details
                states['familyRitualsFlag']= data.family_rituals
                states['familySupportSystemDetails']=data.family_support_system_details
                states['familySupportSystemFlag']= data.family_support_system
                states['selectedFamilyType']= data.family_type
                states['detailsOfFamilyEnvironmentAndRelationship']= data.family_env_relationship

                this.setState({ states },()=>{
                    this.props.editHistory({}, pageName)
                })

               
            }
        }
    }

    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        dropDownList, selectedDropDownlistKey, selectedDropDownlist,
    ) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // drop down list 
                dropDownList={
                    dropDownList
                }
                selectedDropDownlist={selectedDropDownlist}
                dropDownListKey={selectedDropDownlistKey}
                onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // label with button select 


            />
        )
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    onChangeDropDownVallue(selectedDropDownValue, dropDownKey) {
        var states = this.state;
        states[dropDownKey] = selectedDropDownValue;
        this.setState({ states })

    }


    clearAllData() {
        var states = this.state;

        states['id']= ""
        states['detailsOfFather']=""
        states['detailsOfMother']= ""
        states['detailsOfSiblings']= ""
        states['detailsOfOthers']=""
        states['detailsOfFamilyIntractionPattern']= ""
        states['detailsOfCommunicationStyle']= ""
        states['detailsOfLeadeshipPattern']=""
        states['detailsOfChildRearingPractices']= ""
        states['mentalDisodersDetails']= ""
        states['mentalDisodersFlag']=false
        states['consanguintyDetails']= ""
        states['consanguintyFlag']= false
        states['cohesivenessDetails']= ""
        states['cohesivenessFlag']=false
        states['familyRitualsDetails']= ""
        states['familyRitualsFlag']= false
        states['familySupportSystemDetails']=""
        states['familySupportSystemFlag']=  false
        states['selectedFamilyType']= ""
        states['detailsOfFamilyEnvironmentAndRelationship']= ""

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
        var data = {
            "appointment_id": this.state.patientAppointment.appointment_id,
            "patient_id": this.state.patientAppointment.patient_id,
            "father_details": states.detailsOfFather,
            "mother_details": states.detailsOfMother,
            "siblings_details": states.detailsOfSiblings,
            "others_details": states.detailsOfOthers,
            "family_interact_pattern": states.detailsOfFamilyIntractionPattern,
            "communication_style": states.detailsOfCommunicationStyle,
            "leadership_pattern": states.detailsOfLeadeshipPattern,
            "child_rear_practice": states.detailsOfChildRearingPractices,
            "mental_disorder": states.mentalDisodersFlag,
            "mental_disorder_details": states.mentalDisodersDetails,
            "consanguinty": states.consanguintyFlag,
            "consanguinty_details": states.consanguintyDetails,
            "cohesiveness": states.cohesivenessFlag,
            "cohesiveness_details": states.cohesivenessDetails,
            "family_rituals": states.familyRitualsFlag,
            "family_rituals_details": states.familyRitualsDetails,
            "family_support_system": states.familySupportSystemFlag,
            "family_support_system_details": states.familySupportSystemDetails,
            "family_type": states.selectedFamilyType?states.selectedFamilyType:0,
            "family_env_relationship": states.detailsOfFamilyEnvironmentAndRelationship
        }

        if (this.state.id) {
            data["id"] = this.state.id;
        }

        var service_url = Constants.PSY_FAMILY_HISTORY;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.savePastHistorySuccess,
            this.savePastHistoryFailure
        );
        }
        else{
            this.props.showResposeValue("error", "Family History already added please click on edit icon to update the values")

        }
    }

    savePastHistorySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePastHistoryFailure = error => { }

    render() {


        const historyDropDownList = [
            { label: "Nil", value: 0 },
            { label: "Joint", value: 1 },
            { label: "Extended", value: 2 },
            { label: "Nuclear", value: 3 }
        ]

        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Family History"} clearAllData={this.clearAllData.bind(this)} />

                {this.renderHistoryComponent(
                    "Father", "Enter details",
                    "", false,
                    "detailsOfFather", this.state.detailsOfFather
                )}
                {this.renderHistoryComponent(
                    "Mother", "Enter details",
                    "", false,
                    "detailsOfMother", this.state.detailsOfMother
                )}
                {this.renderHistoryComponent(
                    "Siblings", "Enter details",
                    "", false,
                    "detailsOfSiblings", this.state.detailsOfSiblings
                )}
                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "", false,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}
                {this.renderHistoryComponent(
                    "Family Interaction Pattern", "Enter details",
                    "", false,
                    "detailsOfFamilyIntractionPattern", this.state.detailsOfFamilyIntractionPattern
                )}
                {this.renderHistoryComponent(
                    "Communication Style", "Enter details",
                    "", false,
                    "detailsOfCommunicationStyle", this.state.detailsOfCommunicationStyle
                )}
                {this.renderHistoryComponent(
                    "Leadership Pattern", "Enter details",
                    "", false,
                    "detailsOfLeadeshipPattern", this.state.detailsOfLeadeshipPattern
                )}

                {this.renderHistoryComponent(
                    "Child Rearing Practices", "Enter details",
                    "", false,
                    "detailsOfChildRearingPractices", this.state.detailsOfChildRearingPractices
                )}

                {this.renderHistoryComponent(
                    "Mental Disoders", "Enter details",
                    "mentalDisodersFlag", this.state.mentalDisodersFlag,
                    "mentalDisodersDetails", this.state.mentalDisodersDetails
                )}
                {this.renderHistoryComponent(
                    "Consanguinity", "Enter details",
                    "consanguintyFlag", this.state.consanguintyFlag,
                    "consanguintyDetails", this.state.consanguintyDetails
                )}
                {this.renderHistoryComponent(
                    "Cohesiveness", "Enter details",
                    "cohesivenessFlag", this.state.cohesivenessFlag,
                    "cohesivenessDetails", this.state.cohesivenessDetails
                )}
                {this.renderHistoryComponent(
                    "Family Rituals", "Enter details",
                    "familyRitualsFlag", this.state.familyRitualsFlag,
                    "familyRitualsDetails", this.state.familyRitualsDetails
                )}
                {this.renderHistoryComponent(
                    "Family Support System", "Enter details",
                    "familySupportSystemFlag", this.state.familySupportSystemFlag,
                    "familySupportSystemDetails", this.state.familySupportSystemDetails
                )}
                {this.renderHistoryComponent(
                    "Family Type", "",
                    "", false,
                    "", "",
                    historyDropDownList, "selectedFamilyType", this.state.selectedFamilyType

                )}
                {this.renderHistoryComponent(
                    "Family Environment & Relationship", "Enter details",
                    "", false,
                    "detailsOfFamilyEnvironmentAndRelationship", this.state.detailsOfFamilyEnvironmentAndRelationship
                )}


                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


            </View>
        )
    }
}
