import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const platform = Platform.OS;
const pageTitle = "Treatment Plan";
const pageName = "treatmentandadvice";
const isPageName = "isTreatmentAndAdvice";

export default class TreatmentAdvice extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      data: {},
      isComplaint: isPageName === this.props.selectedView?true:false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getComplaints();
    }, 5000);

  }

  componentWillReceiveProps(props) {
    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      // refresh false
      this.props.refreshData("Treatment");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {

    var service_url = Constants.OPTHAMOLOGY_TREATMENT_POST + "?appointment_id=" + this.state.patientAppointment.appointment_id;
   setTimeout(() => {
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
   }, 5000);

  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["data"] = response.data;
      this.setState({ field });
    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };



  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }

  deleteItem = (item, event) => {
    this.props.triggerNewItem(isPageName);
    //this.props.editItem(pageName, item);
    var service_url = Constants.OPTHAMOLOGY_DELETE_TREATMENT + `${item.id}/`;
    
    OpthamologyService.getInstance().postComplaints(
      service_url,
      {},
      this,
      this.getDeleteSuccess,
      this.getComplaintsFailure
    );
  }

  getDeleteSuccess = response => {
    if (response.status === "success") {
      
      this.getComplaints();
    }
  };

  getEyeType(type){

    let itemType = "";

    var getObservatios= Constants.Observation_values;

// alert(type)
    // let temp = Constants.Observation_values.map((item,index)=>{

    //   if(type == item.value) { itemType = item.label; }
    //   return (
    //     null
    //   )
    // })
    // return itemType;
    for(var i = 0; i<getObservatios.length;i++){
      if(getObservatios[i].value == type){
      
        itemType=getObservatios[i].label;
        return itemType
      }
    }

  }
  
  renderComplaintItem = (item, index) => {
    return (
      <View>
        <View style={styles.ComplaintItemView}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              // height: 50,
              alignItems: "center"
              // paddingBottom: 10
            }}
          >
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={styles.ComplaintItemText}>
                {item.date}
              </Text>
            </View>

            <View
              style={{
                width: "20%"
                // alignItems: "center"
              }}
            >
              <Text style={styles.ComplaintItemText}>
              {/* {this.getEyeType(item.disease_name)} */}
              {item.disease_name}
              </Text>
            </View>
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={styles.ComplaintItemText}>
                {item.comments}
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                alignItems: "center",
                // height: 60,
                flexDirection: "row"
              }}
            >
              <View
                style={[styles.ComplaintItemIconBtn, {backgroundColor: color.white}]}
              >
                <TouchableOpacity   disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                 <TouchableOpacity   disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={{ height: '0.03vw', backgroundColor: "#E0E0E0" }} />
      </View>
    );
  };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  render() {
    return (
        <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

        {
          this.state.data.treatment_advice && (this.state.data.treatment_advice.date || this.state.data.treatment_advice.eye_type || this.state.data.treatment_advice.comments) ?

            <View style={{ marginTop: '0.6vw' }}>
              <View
                style={{
                  marginLeft: '0.3vw',
                  width: platform === "web" ? "100%" : "75%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <View
                  style={{
                    width: "30%"
                  }}
                >
                  <Text style={styles.TableHeaderText}>Date</Text>
                </View>

                <View
                  style={{
                    width: "20%"
                  }}
                >
                  <Text style={styles.TableHeaderText}>Disease Name</Text>
                </View>
                <View
                  style={{
                    width: "30%"
                  }}
                >
                  <Text style={styles.TableHeaderText}>Comments</Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    alignItems: "center",
                    backgroundColor: color.white,
                    // height: 50
                  }}
                />
              </View>
            </View>
            : null
        }
        {/* <FlatList
          data={this.state.data.treatment_advice}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderComplaintItem(item)
          }
        />
   */}

   {this.state.data.treatment_advice?this.renderComplaintItem(this.state.data.treatment_advice):null}

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }
}
const styles = StyleSheet.create({
      ComplaintItemView: { 
        paddingTop: '0.6vw', 
        paddingBottom:'0.6vw'
      },
      ComplaintItemText: { 
        fontSize: '0.8vw', 
        color: "#2F2F2F" 
      },
      ComplaintItemIconBtn: {
        flexDirection: "row",
        paddingTop: '0.3vw',
        paddingBottom: '0.3vw',
        // height: 50
      },
      TableHeaderText: { 
        fontSize: '0.9vw', 
        color: "#848484" 
      }
})