import React from 'react';
// import { View, Text } from 'react-native';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import SignInScreen from "./src/ui/SignIn";
import HomeScreen from "./src/ui/doctor/common/Home";
import AllBillingTransactions from "./src/ui/doctor/common/billing/AllTransactions";
// import ReportsScreen from "./src/ui/doctor/common/Reports";
import ReportsScreen from "./src/ui/doctor/common/reports";
import Registry from  "./src/ui/doctor/common/Registry";
import CreatePatient from "./src/ui/doctor/common/CreatePatient";
import AuthLoadingScreen from "./src/ui/AuthLoading";
import DoctorNotes from "./src/ui/doctor/opthamology/DoctorNotes";
import SymptomsScreen from "./src/ui/doctor/common/Symptoms";
import MessageScreen from "./src/ui/doctor/common/Message";
import SendMessageScreen from "./src/ui/doctor/common/SendMessage";
import BillingScreen from "./src/ui/doctor/common/billing/BillingScreen";
import Demographics from "./src/ui/doctor/common/Demographics"
import FormsAndCertificate from './src/Forms&Certificate'
import RootComponent from './src/RootComponent'
import {ShowToken} from './src/ui/doctor/QMS/ShowToken'

import QMSDashBoard from './src/ui/doctor/QMS'

import {Provider} from 'react-redux'
import store from "./src/Redux/Store"


//import Base from './src/ui/Base';
const LoginNavigator = createStackNavigator(
  {
    login: {
      screen: SignInScreen,
      navigationOptions: () => ({
        header: false
      })
    }
  },
  {
    initialRouteName: 'login'
  }
);

const HomeNavigator = createStackNavigator(
  {

    rootComponent:{
      screen: RootComponent,
      navigationOptions: () => ({
        header: false
      })
    },

    // base: {
    //   screen: Base,
    //   navigationOptions: () => ({
    //     header: false
    //   })
    // },
    home: {
      screen: HomeScreen,
      navigationOptions: () => ({
        header: false
      })
    },
    billingScreen: {
      screen: BillingScreen,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    reports: {
      screen: ReportsScreen,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    symptoms: {
      screen:SymptomsScreen ,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    registry: {
      screen:Registry,
      navigationOptions: () => ({
        header: false
      })
    },
    message: {
      screen:MessageScreen ,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    formsAndCertificate: {
      screen:FormsAndCertificate ,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    sendmessage: {
      screen:SendMessageScreen ,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    createPatient: {
      screen: CreatePatient,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    allBillingTransactions: {
      screen: AllBillingTransactions,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },

    // demographics
    demographics: {
      screen: Demographics,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
  // },
  // {
    optDoctorNotes: {
      screen: DoctorNotes,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },
    qmsDashBoard: {
      screen: QMSDashBoard,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    },

    showToken: {
      screen: ShowToken,
      navigationOptions: () => ({
        header: false,
        gesturesEnabled: false
      })
    }
  },
  {
    initialRouteName: 'rootComponent'
  }
);

// const OpthamologyNavigator = createStackNavigator(
//   {
//     optDoctorNotes: {
//       screen: DoctorNotes,
//       navigationOptions: () => ({
//         header: false
//       })
//     }
//   },
//   {
//     initialRouteName: 'optDoctorNotes'
//   }
// );

const AuthNavigator = createStackNavigator(
  {
    auth: {
      screen: AuthLoadingScreen,
      navigationOptions: () => ({
        header: false
      })
    }
  },
  {
    initialRouteName: 'auth'
  }
);

const AppContainer = createAppContainer(
  createSwitchNavigator(
    {
      authNavigator: AuthNavigator,
      homeNavigator: HomeNavigator,
      loginNavigator: LoginNavigator,
      // opthamologyNavigator: OpthamologyNavigator
    },{
      initialRouteName: "authNavigator"
    }
  )
)

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    console.disableYellowBox = true;
    return (
    <Provider store={store}> 
      <AppContainer/>
     </Provider>
    );
  }
}


import { Platform } from 'react-native'

const noGlow = `
textarea, select, input, button {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}
`

export const injectWebCss = f => {

	// Only on web
	if ( !Platform.OS == 'web' ) return

	// Inject style
	const style = document.createElement('style')
	style.textContent = `textarea, select, input, button { outline: none!important; }`
	return document.head.append(style)

}

// 👉 And this in the App.js file
injectWebCss()