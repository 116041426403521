

export const updateDateFilterFlag = (data) => {
  console.log(data)

  return {
    type: "DATE_FILTER",
    payload: data
  }
};

export const updateMultiAxialDropDownFlag = (data) => {
  console.log(data)

  return {
    type: "DROP_DOWN",
    payload: data
  }
};