//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from '../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from "moment";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
// create a component
const pageName = "isGulcoseLog";



export class GulcoseLogNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedValue: true,
            // details: '',

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            chartDate: "",
            hba1cvalue: "",
            listData: [],
            fastingTextValue: "",
            number_2_hrs_post_bfast_Value:"",
            lunchValue:"",
            number_2_hrs_post_lunch_Value:"",
            pre_dinner_Value:"",
            number_2_hrs_post_dinner_Value:"",

            id:""
        }
    }


    componentDidMount() {
        this.getHba1cValue()
    }

    componentWillReceiveProps(props) {


        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;

                states["chartDate"]=data.activity_date
                states["id"]=data.id
                states["fastingTextValue"]=data.fasting
                states["number_2_hrs_post_bfast_Value"]=data.number_2_hrs_post_bfast
                states["lunchValue"]=data.lunch
                states["number_2_hrs_post_lunch_Value"]=data.number_2_hrs_post_lunch
                states["pre_dinner_Value"]=data.pre_dinner
                states["number_2_hrs_post_dinner_Value"]=data.number_2_hrs_post_dinner

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }







    onPressAddButton() {
      
        var states = this.state
        var data = {

            "patient_id": this.state.patientAppointment.patient_id,
            "activity_date": states.chartDate,
            "fasting": states.fastingTextValue?states.fastingTextValue:null,
            "number_2_hrs_post_bfast": states.number_2_hrs_post_bfast_Value?states.number_2_hrs_post_bfast_Value:null,
            "lunch": states.lunchValue? states.lunchValue:null,
            "number_2_hrs_post_lunch":states.number_2_hrs_post_lunch_Value?states.number_2_hrs_post_lunch_Value:null,
            "pre_dinner": states.pre_dinner_Value?states.pre_dinner_Value:null,
            "number_2_hrs_post_dinner": states.number_2_hrs_post_dinner_Value?states.number_2_hrs_post_dinner_Value:null

        }

        if(this.state.id){
            data["id"]=this.state.id
        }

        var service_url = Constants.GLUCOSE_LOG_SAVE;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDiabeticHistorySuccess,
            this.saveDiabeticHistoryFailure
        );

    }

    saveDiabeticHistorySuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.props.refreshRighSideComponent(pageName)
            this.clearAllData()
        } else {
            this.props.showResposeValue("error", success.message)
        }
        // console.log("success ++++++++++++++++>> " + JSON.stringify(success))
    }
    saveDiabeticHistoryFailure = error => {
        this.props.showResposeValue("error", error.message)
    }



    clearAllData() {
        var states = this.state;
        states["chartDate"]=""
        states["fastingTextValue"] = ""
        states["number_2_hrs_post_bfast_Value"] = ""
        states["lunchValue"] = ""
        states["number_2_hrs_post_lunch_Value"] = ""
        states["pre_dinner_Value"] = ""
        states["number_2_hrs_post_dinner_Value"] = ""
        states["id"] = ""
        this.setState({ states })
    }

    getHba1cValue() {
        var service_url =
            Constants.HBA1C_CHART_SAVE +
            "?patient_id=" +
            this.state.patientAppointment.patient_id

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHba1cValueSuccess,
            this.getHba1cValueFailure
        );
    }

    getHba1cValueSuccess = (response) => {
        if (response.status === "success") {
            var field = this.state;
            field["listData"] = response.data;
            this.setState({
                field,
            });
        }
    };
    getHba1cValueFailure = (error) => {

    }

    // renderDataList() {

    //     return (
    //         <View>
    //             <View style={{ justifyContent: 'center', height: 30, backgroundColor: color.sectionHeadingDarkColor, marginTop: 10 }}>

    //                 <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
    //                     <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Date</Text>
    //                     <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>HbA1C Value </Text>
    //                 </View>
    //             </View>
    //             {

    //                 this.state.listData.map((item, index) => {
    //                     return (
    //                         <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', paddingBottom: 10, paddingTop: 10, borderBottomColor: '#CDD1D5', borderBottomWidth: 1 }}>
    //                             <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>{item.chart_date}</Text>
    //                             <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>{item.chart_value || 0}</Text>
    //                         </View>
    //                     );
    //                 })

    //             }
    //         </View>
    //     );
    // }
    numberValidation(num){
        return !/[^.[0-9]]*/.test(num);
      }

    renderTextInput(label, key, stateValue, type) {
        return (
            <View>
                <Text style={{ fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{label}</Text>

                <View style={{ marginTop: 5, marginRight: 5, zIndex: -1 }}>

                    <View style={[{
                        flexDirection: 'row', borderWidth: 0.1,
                        borderColor: '#CDD1D5',

                    }, Style.allButtonBorderRadius]}>
                        <TextInput
                            value={stateValue}
                            onChangeText={(text) => {
                                var states = this.state;
                                if(this.numberValidation(text)){
                                states[key] = text;
                                this.setState({ states })}
                                // this.setState({ exerciseTime: text })
                            }}
                            style={[
                                // Style.historyYesOrNoSectionShadow,
                                // Style.allButtonBorderRadius, 
                                {
                                    backgroundColor: "transparent", height: 35,
                                    width: 100, padding: 3
                                }]}

                        />
                        <View style={{ height: 31, borderLeftColor: "#888888", borderLeftWidth: 1, justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center', paddingRight: 10 }}>
                            <Text style={{ fontSize: 13, marginLeft: 5 }}>{type}</Text>
                        </View>



                    </View>
                </View></View>
        )
    }

    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Glucose Log"} clearAllData={this.clearAllData.bind(this)} />
                <View style={Style.gulcoseLogRightView}>
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Date</Text>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Fasting</Text>

                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', marginBottom: 15 }}>
                    <View style={{ width: '50%' }}>
                        <DatePicker
                        disabledDate={current => {
                            return current && current > moment().add(0, "days");
                          }}
                            value={
                                this.state.chartDate
                                    ? moment(this.state.chartDate)
                                    : this.state.chartDate
                            }
                            onChange={(date) => {
                                var fulldate = new Date(date);
                                var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                this.setState({ chartDate: converted_date });
                            }
                            }
                            format={"DD-MM-YYYY"}
                            placeholder={"Select"}
                            style={{
                                marginTop: 5,
                                marginLeft: 6,
                                height: 38,
                                borderWidth: 1,
                                borderColor: '#CDD1D5',
                                borderRadius: 5,
                                alignItems: "center",
                                flexDirection: "row",
                                width: '90%',
                                backgroundColor:'transparent'
                            }}
                        /></View>
                    <View>
                

                        {this.renderTextInput("Enter Value", "fastingTextValue", this.state.fastingTextValue, "mg/dl")}
                    </View>
                </View>


                <View style={Style.gulcoseLogRightView}>
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>2hours post Breakfast</Text>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Pre Lunch</Text>

                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', marginBottom: 15 }}>
                    <View style={{ width: '45%' }}>
                        {this.renderTextInput("Enter Value", "number_2_hrs_post_bfast_Value", this.state.number_2_hrs_post_bfast_Value, "mg/dl")}

                    </View>
                    <View>
                        {this.renderTextInput("Enter Value", "lunchValue", this.state.lunchValue, "mg/dl")}

                    </View>

                </View>

                <View style={Style.gulcoseLogRightView}>
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>2 hours post Lunch</Text>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>Pre Dinner</Text>

                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' , marginBottom: 15}}>
                    <View style={{ width: '45%' }}>
                        {this.renderTextInput("Enter Value", "number_2_hrs_post_lunch_Value", this.state.number_2_hrs_post_lunch_Value, "mg/dl")}

                    </View>
                    <View>
                        {this.renderTextInput("Enter Value", "pre_dinner_Value", this.state.pre_dinner_Value, "mg/dl")}

                    </View>

                </View>


                <View style={Style.gulcoseLogRightView}>
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly' }}>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}>2 hours post Dinner</Text>
                        <Text style={{ fontSize: 13, width: '50%', marginLeft: 20 }}></Text>

                    </View>  
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', marginBottom: 15 }}>
                    <View style={{ width: '45%' }}>
                        {this.renderTextInput("Enter Value", "number_2_hrs_post_dinner_Value", this.state.number_2_hrs_post_dinner_Value, "mg/dl")}

                    </View>
                    <View style={{width:'42%'}}>
                        {/* {this.renderTextInput("Enter Value", "fastingTextValue", this.state.fastingTextValue, "mg/dl")} */}

                    </View>

                </View>


                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />



            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({

    smallViewStyle: {
        flexDirection: 'row', borderWidth: 0.1, borderColor: 'white', shadowColor: "#090F36",
        shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5, height: 22
    },

    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
});
