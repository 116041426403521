//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";

import Arrow from "react-native-vector-icons/FontAwesome5";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import MenstrualHistory from './MenstrualHistory';
import MenstrualHistoryNew from './MenstrualHistoryNew';

import GynaecologicalHistory from './GynaecologicalHistory'
import GynaecologicalHistoryNew from './GynaecologicalHistoryNew'

import PapSmearMammogramHistory from './PapSmearMammogramHistory'
import PapSmearMammogramHistoryNew from './PapSmearMammogramHistoryNew'

import BirthControlMethod from './BirthControlMethod'
import BirthControlMethodNew from './BirthControlMethodNew'

import OBGYNSurgeries from './OBGYNSurgeries'
import OBGYNSurgeriesNew from './OBGYNSurgeriesNew'

import ObstetricalHistory from './ObstetricalHistory'
import ObstetricalHistoryNew from './ObstetricalHistoryNew'

import { NavigationTopHeader } from "../../BaseComponent"

const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
    { label: "Menstrual History", value: "isMenstrualHistory" },
    { label: "Obstetrical History", value: "isObstetricalHistory" },
    { label: "Birth Control Methods", value: "isBirthControlMethods" },
    { label: "Gynaecological History", value: "isGynaecologicalHistory" },
    { label: "Pap Smear/Mammogram History", value: "isPapSmearMammogramHistoryNew" },
    { label: "OBGYN Surgeries", value: "isOBGYNSurgeries" },

]
// create a component
export class OBGYNHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            scrollPosition: 0,
            selectedSection: "isMenstrualHistory",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
        }
    }

    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            isMenstrualHistory: <MenstrualHistoryNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isGynaecologicalHistory: <GynaecologicalHistoryNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isPapSmearMammogramHistoryNew: <PapSmearMammogramHistoryNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isBirthControlMethods: <BirthControlMethodNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isOBGYNSurgeries: <OBGYNSurgeriesNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                obgynResponseFlag={this.state.obgynResponseFlag} />,
            isObstetricalHistory: <ObstetricalHistoryNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />

        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }


    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }

    renderRightSideContent() {
        return (
            <View>
                <MenstrualHistory selectedPageName={this.state.selectedSection}
                    patientAppointment={this.state.patientAppointment}
                    patientInfo={this.state.patientInfo}
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                />
                <GynaecologicalHistory selectedPageName={this.state.selectedSection}
                    patientAppointment={this.state.patientAppointment}
                    patientInfo={this.state.patientInfo}
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                    refreshRighSideComponentName={this.state.refreshRighSideComponentName} />
                <PapSmearMammogramHistory selectedPageName={this.state.selectedSection}
                    patientAppointment={this.state.patientAppointment}
                    patientInfo={this.state.patientInfo}
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                    refreshRighSideComponentName={this.state.refreshRighSideComponentName} />

            </View>
        )
    }


    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "40%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"SUCCESS: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"green"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {

        let errorList = (this.state.responseMeaasge).split(",")
        return (
            <View style={{ flex: 1, zIndex: 3, width: "35%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, minHeight: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, marginLeft: 10, flex: 0.95 }}>
                    <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: 10 }}>
                        <Icon size={25} name={"ban"} color={"#FFF"} /></View>
                    <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
                        <View>
                            <Text style={{ fontWeight: "700", color: "#FFF", fontSize: 12 }}>{"ERROR: "}
                                {errorList && errorList.map((item, index) => {
                                    return <View key={index}><Text style={{ fontWeight: "400", color: "#FFF", fontSize: 12, marginBottom: 8 }}>{item}</Text></View>
                                })}
                            </Text>
                        </View>

                    </View>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#fff"} />
                </TouchableOpacity>
            </View>
        );
    }



    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }

    renderHeaderView = () => {
        let { patientInfo, patientAppointment } = this.state;
        return (
            <View
                style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomColor: "#E4E8F1",
                    borderBottomWidth: 1,
                    paddingHorizontal: 10,
                    paddingVertical: 15
                }}
            >
                <View style={{ flexDirection: "row" }}>
                    <View style={{ alignSelf: "center", marginLeft: 5 }}>
                        <Arrow
                            name="arrow-left"
                            size={21}
                            color={color.black}
                            onPress={() => {
                                this.props.changeScreen('optDoctorNotes')
                            }}
                        />
                    </View>
                    <View style={{ marginLeft: 20, flexDirection: 'row' }}>

                        <Text style={{ fontSize: 16, alignSelf: "center" }}>{patientInfo.name || ""}</Text>
                        {/* <View style={{marginLeft:10}}> */}
                        <Text
                            style={{
                                fontSize: 10,
                                color: "#ADB4C1",
                                marginTop: 5,
                                marginBottom: 5,
                                marginLeft: 10,
                                alignSelf: "center"

                            }}
                        >
                            {patientInfo.age || ""} {patientInfo.age ? "|" : null} {patientInfo.dob || ""} {patientInfo.dob ? "|" : null}
              &nbsp;{patientInfo.mobile_number || ""} {patientInfo.mobile_number ? "|" : null}{patientInfo.name ? patientAppointment.appointment_start_time || "" : null}
                        </Text>
                        <Text style={{ fontSize: 10, color: "#ADB4C1", marginLeft: 10, alignSelf: "center" }}>
                            {patientInfo.last_visit_date ? "Last Visit Date :" : null} {patientInfo.last_visit_date || ""}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };
    changeTabSection(selectedTab) {
        this.setState({
            selectedSection: selectedTab
        })
    }
    updateOBGYNResonseFlag(flag){
        this.setState({
            obgynResponseFlag:flag
        })
    }

    render() {
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>


                {
                    this.state.responseType == "success" ?
                        this.successAlert() :
                        this.state.responseType == "error" ?
                            this.errorAlert() : null
                }
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor,height: screenHeight-65 }]}>

                    {/* {this.renderHeaderView()} */}
                    <View>
                        <NavigationTopHeader
                            changeTabSection={this.changeTabSection.bind(this)}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={this.state.selectedSection} />
                    </View>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%"}}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        {/* {this.renderRightSideContent()} */}
                        <View style={Style.doctorNotesSectionView}>
                            <MenstrualHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <ObstetricalHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                editHistory={this.editHistory.bind(this)}
                                showResposeValue={this.showResposeValue.bind(this)}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName} />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <BirthControlMethod selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                editHistory={this.editHistory.bind(this)}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName} />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <GynaecologicalHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                editHistory={this.editHistory.bind(this)}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName} />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <PapSmearMammogramHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                editHistory={this.editHistory.bind(this)}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName} />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <OBGYNSurgeries selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                editHistory={this.editHistory.bind(this)}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                updateOBGYNResonseFlag={this.updateOBGYNResonseFlag.bind(this)} />
                        </View>



                    </ScrollView>
                </View>
                <View style={{ padding: 20, paddingRight: 10, width: "35%", backgroundColor: color.themeShade,height: screenHeight-65 }}>

                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                    >
                        {this.renderComponent()}
                    </ScrollView>
                </View>

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
});

//make this component available to the app
