import React, { Component } from 'react';
import { View, Text, Image, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ImageBackground, CheckBox } from 'react-native';
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
// import Arrow from "react-native-vector-icons/FontAwesome5";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

// import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { HistoryYesOrNoSection, CommonButton, LabelWithTextBoxDR, CommonAddButton, DoctorNotesCommonRightSideHeader, CommonHistorySectionHeader,OBGYNHistorySectionHeading } from './../../BaseComponent'

import {NavigationTopHeader} from "../../BaseComponent"
const screenHeight = Dimensions.get("window").height;
const pageName = "isDevelopMentalAssessment"

const navigationHeaderList=[
    {label:"Development Assessment",value:"Development Assessment"}
]

var selectAges = [
    { label: "2 Months", value: "2 MONTHS" },
    { label: "4 Months", value: "4 MONTHS" },
    { label: "6 Months", value: "6 MONTHS" },
    { label: "9 Months", value: "9 MONTHS" },
    { label: "12 Months", value: "12 MONTHS" },
    { label: "15 Months", value: "15 MONTHS" },
    { label: "16-18 Months", value: "16-18 MONTHS" },
    { label: "18-24 Months", value: "18-24 MONTHS" },
    { label: "2-3 Years", value: "2-3 YEARS" },
    { label: "3-4 Years", value: "3-4 YEARS" },
    { label: "4-5 Years", value: "4-5 YEARS" },

];
export class DevlopmentAssessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            scrollPosition: 0,
            selectedSection: "",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            selectedPageName: "",
            selectAge: {},
            selectAgeList: selectAges,
            comments: "",
            saveComments:"",
            personalSocial: [],
            gross_Motor: [],
            language: [],
            fineMotor: [],
            selectAgeLabel: "",
            ageDisable: false,
            selectAgevalue: "",
            abnormalities:[],
            defaultAge: ""


        }
    }


    componentDidMount() {
        this.getDenvorAssessment();




        //   var patientAge=this.state.patientInfo.age
        //   var agesplit=patientAge.slice(0, 7)
        //   var convert=parseInt(agesplit)
        //    if(convert<=5){

        //     this.setState({
        //         defaultAge:agesplit
        //     },()=>{
        //         if(defaultAge == )
        //     })

        //     //   var babyage=agesplit

        //   }


    }

    getDenvorAssessment() {
        var service_url = ""
        service_url = Constants.GET_DENVER_ASSESSMENT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&age_range=" + this.state.selectAgevalue;


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDenvorAssessmentSuccess,
            this.getDenvorAssessmentFailure
        );
    }

    getDenvorAssessmentSuccess = success => {
        if (success.status == "success") {

            if (Object.keys(success.data).length !== 0) { }
            else {
                this.setState({
                    ageDisable: true
                })
            }
            if (success.data) {
                var data = success.data.developmental_assessment
                var states = this.state
                //  alert(JSON.stringify(data["abnormalities"]))
                states["comments"] = success.data.comments
                states["saveComments"] = success.data.comments
                states["personalSocial"] = data['Personal - Social']
                states["gross_Motor"] = data['Gross Motor']
                states["language"] = data['Language']
                states["fineMotor"] = data['Fine Motor - Adaptive']
                states["abnormalities"]= data["abnormalities"]
                states["selectAge"] = success.data.age_range ? { label: success.data.age_range, value: success.data.age_range } : null
                this.setState({
                    states
                })
            }


        }
    }

    getDenvorAssessmentFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }

    onPressAddButton() {
        var states = this.state;
        var { gross_Motor, fineMotor, personalSocial, language } = this.state
        var developmental_assessment = gross_Motor.concat(fineMotor ? fineMotor : {}, personalSocial ? personalSocial : {}, language ? language : {})

        var data = {
            "appointment_id": states.patientAppointment.appointment_id,
            "abnormalies": "",
            "age_range": states.selectAge.value,
            "comments": states.comments,
            "developmental_assessment": developmental_assessment

        }


        var service_url = Constants.POST_DENVER_ASSESSMENT;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDenverSuccess,
            this.saveDenverFailure
        );

    }

    saveDenverSuccess = success => {
        if (success.status == "success") {
            this.showResposeValue('success', success.message);
            this.refreshRighSideComponent(pageName);
            this.getDenvorAssessment();
        }else{
            this.showResposeValue('error', success.message);
 
        }
    }
    saveDenverFailure = error => { 

    }


    onSubmitToAddValue() {
        var states = this.state;
        var { birthWeightList } = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showSelectedBirthWeightListNewButtonFlag) {
            birthWeightList.push(preparData);
            birthWeightList = this.removeDuplicate(birthWeightList);
            states["newButtonValue"] = "";
            states["showSelectedBirthWeightListNewButtonFlag"] = true;
            states["selectedBirthWeight"] = preparData;
        }
        this.setState({
            birthWeightList, states
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }



      checkIsAbnormalities(age){
        var {abnormalities} = this.state;
        if(abnormalities && abnormalities.length > 0){
            var checkAge = abnormalities.includes(age)
            return checkAge
        }else{
            return false
        }
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        
        var data = this.removeDuplicate(setOfData)
        
        // var checkIsAbnormalities = data

        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var checkIsAbnormalitiesFlag= false
                            if(key == "selectAge"){
                                checkIsAbnormalitiesFlag = this.checkIsAbnormalities(item.value)
                            }
                            var changedItem = {}
                            if (key == "selectedBirthWeight") {
                                changedItem = { label: item.label + " kgs", value: item.value }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        buttonBorderColor={ checkIsAbnormalitiesFlag ? "red" : ""}
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        disable={this.state.ageDisable}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }
    renderComponent() {


        return (
            <View>
                <Text style={{ fontSize: 18, color: color.themeDark, fontWeight: "600", marginBottom: 10 }}>{"Developmental Assessment"}</Text>
                {
                    this.renderLabelWithButtonGroup(
                        "Select Age",
                        "selectAge",
                        this.state.selectAge,
                        "",
                        false,
                        this.state.selectAgeList)
                }
                <LabelWithTextBoxDR label={"Comments"} value={this.state.comments} textBoxKey={"comments"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }



    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Icon size={25} name={"ban"} color={"#D26560"} />
                    <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                        <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }


    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "selectAge") {
            states[key] = selectedValue
            this.setState({
                states,
                selectAgeLabel: selectedValue.label,
                selectAgevalue: selectedValue.value,
                ageDisable: false
            }, () => {
                this.getDenvorAssessment()
            })

        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    renderComments() {
        return (
            <View style={{ marginTop: 20 }}>
                <CommonHistorySectionHeader
                    heading1={"Comments"}
                    noOfColumn={1}
                />
                <Text style={[Style.historyHeaderFontStyle,{margin:10,marginLeft:20}]}>{this.state.saveComments}</Text>

            </View>
        )
    }

    renderPersnolSocial() {
        return (
            <View>
                {
                    this.state.personalSocial && this.state.personalSocial.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { personalSocial } = this.state;
                                        personalSocial[index].assessment_value = !item.assessment_value

                                        this.setState({ personalSocial })
                                    }}
                                />
                                <Text style={{ marginLeft: 10, fontSize: 12 }}>{item.assessment}</Text>
                            </View>
                        )
                    })

                }
            </View>
        )

    }


    renderGrossMotor() {
        return (
            <View>
                {
                    this.state.gross_Motor && this.state.gross_Motor.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { gross_Motor } = this.state;
                                        gross_Motor[index].assessment_value = !item.assessment_value

                                        this.setState({ gross_Motor })
                                    }}
                                />
                                <Text style={{ marginLeft: 10, fontSize: 12 }}>{item.assessment}</Text>
                            </View>
                        )
                    })

                }
            </View>
        )

    }

    renderLanguage() {
        return (
            <View>
                {
                    this.state.language && this.state.language.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4, paddingRight: 5 }}>
                                <View style={{ width: '93%', flexWrap: 'nowrap' }}>
                                    <Text style={{ marginRight: 10, fontSize: 12 }}>{item.assessment}</Text>
                                </View>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { language } = this.state;
                                        language[index].assessment_value = !item.assessment_value

                                        this.setState({ language })
                                    }}
                                />
                            </View>
                        )
                    })

                }
            </View>
        )

    }

    renderFinemotor() {
        return (
            <View>
                {
                    this.state.fineMotor && this.state.fineMotor.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4, paddingRight: 5 }}>
                                <View style={{ width: '93%', flexWrap: 'nowrap' }}>
                                    <Text style={{ marginRight: 10, fontSize: 12, textAlign: 'right' }}>{item.assessment}</Text>

                                </View>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { fineMotor } = this.state;
                                        fineMotor[index].assessment_value = !item.assessment_value

                                        this.setState({ fineMotor })
                                    }}
                                />
                            </View>
                        )
                    })

                }
            </View >
        )

    }


    renderDevelopmentalImage() {

        return (
            <View>
                <ImageBackground style={{ height: 500, width: "100%" }} source={require('../../../../../assets/images/Developmental Assessment Chart.png')}>
                    <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'space-between', padding: 10 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            {this.renderGrossMotor()}
                            {this.renderLanguage()}

                        </View>
                        <View style={{ position: 'absolute', zIndex: -1, height: 500, width: "100%", alignContent: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <View style={{ justifyContent: "center",marginBottom:20 }} >
                                <View style={{}}>
                                    <Text style={{ fontSize: 15, fontWeight: 400, marginBottom: 5, textAlign: 'center' }}>Age</Text>
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>{this.state.selectAge && this.state.selectAge.label ? this.state.selectAge.label : null}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {this.renderPersnolSocial()}
                            {this.renderFinemotor()}
                        </View>
                    </View>
                </ImageBackground>
            </View>
        )
    }
    changeTabSection(selectedTab){
        this.setState({
            selectedSection:selectedTab
        })
      }


    render() {
        
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>
                    <View style={{ width: "100%" }}>
                        <NavigationTopHeader
                            changeTabSection={this.changeTabSection.bind(this)}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={"Development Assessment"} />
                    </View>    
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 50 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >

                        <View style={{
                            marginTop: 10,
                            backgroundColor: color.sectionBackgroundColor, marginHorizontal: 8, borderRadius: 4, padding: 10, paddingVertical: 10, paddingLeft: 20
                        }}>
                            <TouchableOpacity style={{ marginBottom: 20 }} >
                                <Text style={{ fontSize: 18, color: color.themeDark, fontWeight: "600" }}>{"Developmental Assessment"}</Text>
                            </TouchableOpacity>
                            <View style={{ height: 600, width: "100%" }}>
                                {this.renderDevelopmentalImage()}
                                {this.renderComments()}

                            </View>


                        </View>

                    </ScrollView>
                </View>


                <View style={{ padding: 20, paddingRight: 10, width: "35%", backgroundColor: color.themeShade, }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    {this.renderComponent()}
                </View>


            </View>
        )
    }

}