//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { OBGYNHistorySectionHeading ,CommonHistorySectionHeader,CommonSectionHeader,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from '../../../../styles/Style'
// create a component
const pageName = "isBirthControlMethods"

class BirthControlMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            detailsOfBirthControlInPast:"",
            depressionOfPregnancy:{},
            previousInfertilityTherapy:"",

            birthControlMethodList: {}
        }
    }

    componentWillReceiveProps(props){
        this.state.selectedPageName = props.selectedPageName;
        if(props.refreshRighSideComponentName == pageName ){
            this.getBirthControlHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount(){
        this.getBirthControlHistory()
    }


    getBirthControlHistory() {
        var service_url = Constants.BIRTH_CONTROL_GET + "?patient_id=" + this.state.patientAppointment.patient_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getBirthControlHistorySuccess,
            this.getBirthControlHistoryFailure
        );
    }

    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }


    getBirthControlHistorySuccess = success =>{
        // alert(JSON.stringify(success))
        if (success.status == "success") {
            // alert("COPYY" + JSON.stringify(success))
            var data = success.data;
            if(Object.keys(data).length > 0){
            this.setState({
                depressionOfPregnancy:this.checResponceIsFlag(data.is_depression_before_after_pregnancy),
                birthControlMethodList:data,
                detailsOfBirthControlInPast:data.past_birth_control_methods,
                previousInfertilityTherapy:data.previous_infertility_therapy_desc,
            })
        }else{
            this.setState({
                depressionOfPregnancy:"",
                birthControlMethodList:data,
                detailsOfBirthControlInPast:"",
                previousInfertilityTherapy:"",
            })
        }
        }else{
            this.setState({
                depressionOfPregnancy:"",
                birthControlMethodList:data,
                detailsOfBirthControlInPast:"",
                previousInfertilityTherapy:"",
            })
        }
    }
    getBirthControlHistoryFailure = error =>{}

    selectedRightSideView(pageName) {
        this.props.selectedRightSideView(pageName);
    }
    editHistory() {
        this.props.editHistory(this.state.birthControlMethodList, pageName)
    }

    renderContent(heading, value) {
        return (
            <View>
                <HistorySectionRightSideUI heading={heading} value={value} />
                {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}</Text> */}
            </View>
        );
    }

    renderHistorySectionFields(pregnancyDepressionTitle, pregnancyDepressionValue, pastBirthControlTitle, pastBirthControlValue, previousInfertilityTherapytitle, previousInfertilityTherapyValue) {
        return (
            <View style={{ flexDirection: 'row'}}>
                <View style={{ flex: 0.30 }}>
                    {this.renderContent(pregnancyDepressionTitle, pregnancyDepressionValue)}
                </View>
                <View style={{ flex: 0.30 }}>
                    {this.renderContent(pastBirthControlTitle, pastBirthControlValue)}
                </View>
                <View style={{ flex: 0.40 }}>
                    {this.renderContent(previousInfertilityTherapytitle, previousInfertilityTherapyValue)}
                </View>
            </View>
        )
    }


    birthControlSection() {
        return (
            <View style={{marginTop:10}} >
                <View style={{ flexDirection: 'row',marginBottom:10}}>
               
                <HistorySectionRightSideUI heading={"Did you experience depression before or after pregnancy?"} value={this.state.depressionOfPregnancy.value} details={""}  addDaysText={""} />
                <HistorySectionRightSideUI heading={"Have you used any birth control in the past?"} value={this.state.detailsOfBirthControlInPast} details={""}  addDaysText={""} />
                <HistorySectionRightSideUI heading={"Have you used any Infertility Therapy previously?"} value={this.state.previousInfertilityTherapy} details={""}  addDaysText={""} />
                </View>
            {/* <CommonHistorySectionHeader
              heading1={"Depression before or after pregnancy?"}
              heading2={"Used any birth control in the past?"}
              heading3={"Previous Infertility Therapy"}
              columnSize={[0.25,0.40,0.35]}  //total value is == of 1
              noOfColumn={3}
            />
            
            
            <View style={{ flexDirection: 'row',paddingVertical:7 }}>
                <Text style={[Style.historyHeaderFontStyleStartText, { flex:0.25 }]}>{this.state.depressionOfPregnancy.value}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex:0.40 }]}>{this.state.detailsOfBirthControlInPast}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex:0.35 }]}>{this.state.previousInfertilityTherapy}</Text>
              </View> */}
            {/* <CommonHistorySectionHeader
              heading1={"Did you experience depression before or after pregnancy?"}
              heading2={"Have you used any Infertility Therapy previously?"}
              columnSize={[0.50,0.50]}  //total value is == of 1
              noOfColumn={2}
            /> */}
           
                {/* {
                    this.renderHistorySectionFields(
                        "Did you experience depression before or after pregnancy?",
                        this.state.depressionOfPregnancy.value,
                        "",
                        this.state.detailsOfBirthControlInPast,
                        "Have you used any Infertility Therapy previously?",
                        this.state.previousInfertilityTherapy
                    )
                } */}
            {/* </View> */}
            </View>
        );
    }


    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading

                    editHistory={this.editHistory.bind(this)}

                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                    heading={"Birth Control Methods"}
                    editImage={this.state.depressionOfPregnancy  ? "pencil" : ""}
                // deleteImage={"trash"}
                />
                {this.birthControlSection()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop: 20
    },
});

//make this component available to the app
export default BirthControlMethod;
