import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { NumberFormat } from '../../../BaseComponent'
import { Tooltip } from 'antd';
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';

class PaymentType extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
      PaymentDatas: [],
      TotalPayment: 0,
      SpecList: [],
      SelectedDep: '',
      paymentTypeLabels: '',
      paymentTypeData: ''

    }
  };
  componentDidMount() {
    this.getPaymentTypeData()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getPaymentTypeData()
      })
    }
  }
  /* get PaymentType data API start*/
  getPaymentTypeData() {
    var serviceUrl =
      Constants.PAYMENT_TYPE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPaymentTypeSuccess,
      this.getPaymentTypeFailure
    );

  }

  getPaymentTypeSuccess = (success) => {
    /* Dropdown Value select*/
    if (success.status === "success") {
      if (this.state.SelectedDep === "") {
        this.setState({
          SelectedDep: success.data.department[0].department_name
        })
      }

      /* find a index value */
      const indexOfObject = success.data.department.findIndex(object => {
        return object.department_name === this.state.SelectedDep;
      });

      /* set value to state */
      this.setState({
        PaymentDatas: success.data.department,
        selectedPayment: success.data.department[indexOfObject].payments,
        selectedTotalPayment: success.data.department[indexOfObject].payments.total_payment
      });

      /* separate the objects to key */
      var paymentLabels = [];
      var paymentData = [];
      Object.keys(this.state.selectedPayment).forEach((key) => {
        if (key != 'total_payment') {
          if(key == "cash"){
            paymentLabels.push("Cash");
          }else if(key == "card"){
            paymentLabels.push("Card");
          }else if(key == "upi"){
            paymentLabels.push("UPI");
          }else if(key == "bank_transfer"){
            paymentLabels.push("Bank Transfer");
          }else if(key == "insurance"){
            paymentLabels.push("Insurance");
          }
          paymentData.push(this.state.selectedPayment[key] === null ? 0 : this.state.selectedPayment[key]);
        }
      });
      this.setState({
        paymentTypeLabels: paymentLabels,
        paymentTypeData: paymentData
      })
    } else {
      this.props.Response(success.message)
    }
  };

  getPaymentTypeFailure = (error) => {
    this.props.Response(error.message)
  };
  /* get PaymentType data API end*/

  /* Export Excel API start*/
  getPaymentTypeExcel = () => {
    var serviceUrl =
      Constants.PAYMENT_TYPE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getPayTypeSuccess,
      this.getPayTypeFailure,
      "excel"
    );
  }
  getPayTypeSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }else{
      this.props.Response(success.message)
    }
  }
  getPayTypeFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/
  render() {
    const data = {
      datasets: [
        {
          data: this.state.paymentTypeData.length > 0 ? this.state.paymentTypeData : [],
          backgroundColor: [
            'rgb(168, 50, 155)',
            'rgb(97, 207, 126)',
            'rgb(255, 99, 132)',
            'rgb(204, 255, 153)',
            'rgb(54, 162, 235)',

          ],
        },
      ],
      labels: this.state.paymentTypeLabels.length > 0 ? this.state.paymentTypeLabels : [],
    };
    const Department = (Dep) => {
      if (Dep == "IP") {
        return "In Patient"
      }
      else if (Dep == "OP") {
        return "Out Patient"
      }
      else if (Dep == "PHARMACY") {
        return "Pharmacy"
      }
      else if (Dep == "LAB") {
        return "Lab"
      }
      else if (Dep == "LAB IMAGE") {
        return "Lab Image"
      }
      else {
        return Dep
      }
    }


    return (
      <View>
        <View style={styles.TopView}>
          <View style={styles.RoleNameView}>
            <Text style={styles.RoleNameText}>{Department(this.state.SelectedDep)}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Picker
              selectedValue={this.state.SelectedDep}
              style={styles.PickerStyle}
              onValueChange={(itemvalue) => {
                this.setState({
                  SelectedDep: itemvalue
                }, () => { this.getPaymentTypeData() }
                )
              }}
            >
              {this.state.PaymentDatas.length > 0 ? this.state.PaymentDatas.map((list) => (
                <Picker.Item label={list.department_name} value={list.department_name} />
              )) : null}
            </Picker>
            <View style={{ margin: '1vw' }}>
              <TouchableOpacity onPress={this.getPaymentTypeExcel}>
                <Tooltip title={'Export to Excel'}>
                  <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
                </Tooltip>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {this.state.selectedTotalPayment > 0 && this.state.selectedTotalPayment != null ?
          <View >
            <Doughnut
              width={'22vw'}
              height={'14vw'}
              data={data}
              options={
                {
                  title: {
                    position: "bottom",
                    display: true,
                    text: ['Payment Type'],
                    fontColor: ['rgb(4, 183, 177)']
                  },
                  responsive: true
                }}

            />
            <View style={styles.TotalAmountView}>
              <Text style={styles.TotalAmountText}>Total : ₹ </Text>
              <Text style={styles.TotalAmountValue}>{NumberFormat(this.state.selectedTotalPayment === null ? 0 : this.state.selectedTotalPayment)}</Text>
            </View>
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Payment Type</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

      </View>

    )
  }
}

export default PaymentType;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '7vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PickerStyle: {
    height: "2.27vw",
    marginRight: '2vw',
    margin: '0.32vw',
    backgroundColor: "transparent",
    borderRadius: '0.26vw',
    flex: 0.5,
    borderColor: 'rgb(4, 183, 177)',
    width: '7vw',
    fontSize: '0.91vw'
  },
  RoleNameText: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    width: "10vw"
  },
  RoleNameView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10vw',
  },
  TopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5vw'
  }
})