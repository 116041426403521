import React, { Component } from "react";
import {
    View,
    Text,
    FlatList,
    TextInput,
    ScrollView,
    Image,
    Platform,
    StyleSheet,
    Picker,
    TouchableOpacity
} from "react-native";
import { CheckBox } from 'react-native-elements';
import BaseClass from "../../BaseClass";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
// import GmailInput from "react-native-gmailtype-textinput";
// import SideMenu from "../../SideMenu";
import BaseComponentStyle from "../BaseComponentStyle";
import SelectedButton from "../../../components/SelectedButton";




const dummySymptoms = [
    {
        name: "Head Ache",
        Duration: "1W",
        Description: "Presistent headache"
    },
    {
        name: "Head Ache",
        Duration: "1W",
        Description: "Presistent headache"
    },
    {
        name: "Head Ache",
        Duration: "1W",
        Description: "Presistent headache"
    },
];


const dummyAssesment = [
    {
        Name: "A001",
        Description: "Cholera due to vibrio cholerae 01"
    },
    {
        Name: "A001",
        Description: "Cholera due to vibrio cholerae 01"
    },
    {
        Name: "A001",
        Description: "Cholera due to vibrio cholerae 01"
    },
];

const dummyAllergies = [
    {
        Name: "Skin Allergy",
        Type: "Medication Allergy",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
    {
        Name: "Skin Allergy",
        Type: "Medication Allergy",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
    {
        Name: "Skin Allergy",
        Type: "Medication Allergy",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
];

const dummyPrescription = [
    {
        image: require("../../../../assets/images/Prescription/injection.svg"),
        TabletName: "CLOFERT",
        Weight: "25mg",
        BrandName: "CLOFERT",
        Session: "0-1-0-1",
        Duration: "10 days",
        Food: "Before food",
        Tablettype: "Pain Killer...",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
    {
        image: require("../../../../assets/images/Prescription/pill.svg"),
        TabletName: "CLOFERT",
        Weight: "25mg",
        BrandName: "CLOFERT",
        Session: "0-1-0-1",
        Duration: "10 days",
        Food: "Before food",
        Tablettype: "Pain Killer...",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
    {
        image: require("../../../../assets/images/Prescription/syrup.svg"),
        TabletName: "CLOFERT",
        Weight: "25mg",
        BrandName: "CLOFERT",
        Session: "0-1-0-1",
        Duration: "10 days",
        Food: "Before food",
        Tablettype: "Pain Killer...",
        Date: '23/01/2020',
        Status: "Ative",
        Comments: "For the past 2 months"
    },
];

const dummyMedical = [
    {
        name: "Joint Pain",
        Description: "Presistent headache"
    },
    {
        name: "Head Ache",
        Description: "Presistent headache"
    },
    {
        name: "Head Ache",
        Description: "Presistent headache"
    },
];

const SHOWDate = [
    { value: 1, label: "23.07.2019" },
    { value: 2, label: "20.08.2019" },
    { value: 3, label: "20.12.2019" },
    { value: 4, label: "02.01.2020" },
    { value: 5, label: "03.02.2020" },
    { value: 6, label: "03.03.2020" },
    { value: 7, label: "03.03.2020" },

]

const today = moment(new Date()).format("DD/MM/YYYY");
const platform = Platform.OS;

export default class Symptoms extends BaseClass {
    constructor(props) {
        super(props);


        this.state = {
            complaintsData: [],
            isSymptoms: true,
            isAllergy: false,
            isVitals: false,
            isAssessment: false,
            isPrescription: false,
            isPreviousPrescription:false,
            isTest: false,
            isPlan: false,
            selectedView: "isSymptoms",
            currentTime: "",
            isedit: "false",
            withfood: "false",
            afterfood: "false",
            beforefood: "false",
            isChecked: false,
            isMedical: false,
            isHospital: false,
            isSocial: false,
            isFamily: false,
            isMedicalHistory: false,
            isHospitalHistory: false,
            isSocialHistory: false,
            isFamilyHistory: false,
            isHospitalizsed: false,
            isSurgery: false,
            isHospitalDiscription: false,
            isAlchol: false,
            isSmoking: false,
            isDrug: false,
            isMarried: false,
            selectedEye: "",
            showDate: "",

            selectedButton:""

        };



    }





    componentDidMount() {
        // this.getComplaints();

        setInterval(() => {
            this.setState({
                currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
            });
        }, 1000);
    } j
    renderImgBtn = (img, title, menuItem) => {
        return (
            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: "center", justifyContent: "space-around", marginTop: 20 }}>
                <Image
                    source={img}
                    style={{
                        height: this.state.selectMenu === menuItem ? 40 : 30,
                        width: this.state.selectMenu === menuItem ? 40 : 30
                    }}
                    resizeMode="contain"
                />
                <Text style={{ fontSize: 10, color: "#7C7C7C", paddingLeft: 0, textAlign: "center" }} numberOfLines={2}>{title}</Text>
            </View>
        );
    };

    renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

        return (
            <SelectedButton
                //   {...otherProps}
                item={item}
                fill={fill}
                borderNeeded={borderNeeded}
                onPressItem={this.onPressAction.bind(this, selectedKey)}
                selected={selectedValue}
            />
        );
    }

    onPressAction = (key, value) => {

        let states = this.state;
        states[key] = value;
        if (value === 1) {
            this.state.isAlchol = true
            this.state.isSmoking = false
            this.state.isDrug = false
            this.state.isMarried = false

        }
        else if (value === 2) {
            this.state.isSmoking = true
            this.state.isAlchol = false
            this.state.isDrug = false
            this.state.isMarried = false

        }
        else if (value === 3) {
            this.state.isDrug = true
            this.state.isSmoking = false
            this.state.isAlchol = false
            this.state.isMarried = false

        }
        else if (value === 4) {
            this.state.isMarried = true
            this.state.isDrug = false
            this.state.isSmoking = false
            this.state.isAlchol = false
        }

        this.setState({
            states
        })
    }


    renderTextBtn = (name, fill, borderNeeded) => {
        return (
            <TouchableOpacity onPress={() => this._showAlert(name)}>
                <View
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: fill || borderNeeded ? 10 : 20,
                        flexDirection: "row",
                        borderColor: fill || borderNeeded ? color.themePink : color.white,
                        borderWidth: 1,
                        backgroundColor: fill
                            ? borderNeeded
                                ? color.themeBlue
                                : color.themePink
                            : color.themeBlue,
                        padding: fill ? 10 : 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 10,
                        marginRight: 2
                    }}
                >
                    <Text
                        style={{
                            fontSize: fill ? 21 : 12,
                            textAlign: "center",
                            color: borderNeeded ? color.themePink : color.white,
                            fontWeight: "500"
                        }}
                    >
                        {name}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    onChangeSelected(item){
        this.changeSelected("isPreviousPrescription")
        this.setState({
            selectedButton:item.value,
        })
        
    }

    alertFunc = value => {
        this._showAlert(value, "Ok");
    };

    renderShowDate = (item, fill, borderNeeded, showDateKey, showDateValue) => {
        return (
            <TouchableOpacity onPress={()=>this.onChangeSelected(item)}>
            <View
            style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: fill || borderNeeded ? 10 : 20,
                flexDirection: "row",
                borderColor: this.state.selectedButton === item.value ? color.themePink : "#D9D9D9" ,
                borderWidth: 1,
                backgroundColor: "white",
                padding: fill ? 10 : 16,
                paddingTop: 8,
                paddingBottom: 8,
                marginBottom: 10,
                marginRight: 2,
                marginLeft:30
            }}
            >
            <Text
                style={{
                fontSize: fill ? 21 : 11, //12
                textAlign: "center",
                color: this.state.selectedButton === item.value ? color.themePink : "#848484" ,
                
                fontWeight: "400" //500
                }}
            >
                {item.label}
            </Text>
            </View>
            </TouchableOpacity>
        );
    }

    onPressAction = (key, value) => {
        let states = this.state;
        states[key] = value;

        this.setState({
            states
        })
    }


    renderTextFieldView = placeholder => {
        return (
            <View
                style={{
                    height: 80,
                    marginTop: 20,
                    backgroundColor: color.white,
                    borderRadius: 10
                }}
            >
                <TextInput
                    underlineColorAndroid="transparent"
                    placeholder={placeholder}
                    style={{
                        color: "#525252",
                        height: 50,
                        paddingLeft: 5,
                        marginRight: 0,
                        flex: 1
                    }}
                    ref={text => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                // value={this.state.userName}
                // onChangeText={text => this.setState({ userName: text })}
                />
            </View>
        );
    };

    renderTitleWithMultipleBtn = (title, dataSet, columnNos, isNewBtnNeeded) => {
        return (
            <View style={{ marginBottom: 20 }}>
                {this.renderSubHeadingBorder(title)}

                <FlatList
                    data={dataSet}
                    numColumns={columnNos}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderTextBtn(item, false)}
                />

                {this.renderIconBtn("plus", "New", true)}
            </View>
        );
    };



    renderTitleWithAllergyMultipleBtn = (title, dataSet, columnNos, isNewBtnNeeded) => {
        return (
            <View style={{ marginBottom: 20 }}>
                {this.renderSubHeadingBorder(title)}

                <FlatList
                    data={dataSet}
                    numColumns={columnNos}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderTextBtn(item, false)}
                />


            </View>
        );
    };




    renderNewSymptoms = () => {
        return (
            <View>
                <View>
                    {this.renderTitleWithMultipleBtn(
                        "Symptom's Name",
                        [
                            "Head Ache",
                            "Fever",
                            "Stomach Pain",

                        ],
                        3,
                        true
                    )}
                </View>


                <View style={{ marginTop: 20 }}>
                    <Text style={{ fontSize: 25, color: color.white }}>Duration</Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                    <View
                        style={{
                            flexDirection: "row",
                            marginTop: 20,
                            justifyContent: "space-between"
                        }}
                    >
                        <TouchableOpacity>
                            {this.renderTextBtn("01D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("02D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("03D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("04D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("05D", false, false)}
                        </TouchableOpacity>
                    </View>

                    <View
                        style={{
                            flexDirection: "row",
                            marginTop: 20,
                            justifyContent: "space-between"
                        }}
                    >
                        <TouchableOpacity>
                            {this.renderTextBtn("06D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("07D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("08D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("09D", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("10D", false, false)}
                        </TouchableOpacity>
                    </View>
                </View>

                {this.renderTextFieldView("Comments")}
                <View style={{ marginTop: 20 }}>
                    <TouchableOpacity>
                        {this.renderTextBtn("Add", true, false)}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    renderNewAllergy = () => {
        return (
            <View>
                <View>
                    {this.renderTitleWithAllergyMultipleBtn(
                        "Allergy's Name",
                        [
                            "Drug Allergy",
                            "Fever",
                            "Stomach Pain",

                        ],
                        3,
                        true
                    )}
                    {this.renderTitleWithAllergyMultipleBtn(
                        "Allergy Type",
                        [
                            "Medical Allergy",
                            "Non - Medical Allergy",

                        ],
                        2,
                        true
                    )}
                    {this.renderTitleWithAllergyMultipleBtn(
                        "Active/Inactive",
                        [
                            "Active",
                            "Inactive",

                        ],
                        3,
                        true
                    )}

                </View>




                {this.renderTextFieldView("Comments")}
                <View style={{ marginTop: 20 }}>
                    <TouchableOpacity>
                        {this.renderTextBtn("Add", true, false)}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };


    renderNewAssessment = () => {
        return (
            <View>
                <View style={{ justifyContent: "space-evenly    " }} >
                    {this.renderTitleWithMultipleBtn(
                        "ICD 10 Codes",
                        [
                            "A001",
                            "A001",
                            "A001",
                            "A001",
                            "A001",
                            "A001",
                            "A001",
                            "A001",
                            "A001",

                        ],
                        3,
                        true
                    )}
                </View>


                <View style={{ marginTop: 3 }}>
                    <Text style={{ fontSize: 25, color: color.white }}>Short Description</Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                    <View
                        style={{
                            flexDirection: "row",
                            marginTop: 10,
                        }}
                    >
                        <TouchableOpacity >
                            {this.renderTextBtn("Cholera due Vibrio cholerae 01,biovar eltor", false, false)}
                        </TouchableOpacity>

                    </View>

                    <View
                        style={{
                            flexDirection: "row",
                            marginTop: 2,

                        }}
                    >
                        <TouchableOpacity>
                            {this.renderTextBtn("cholera, unspecified", false, false)}
                        </TouchableOpacity>
                        <TouchableOpacity>
                            {this.renderTextBtn("Typhoid meningits", false, false)}
                        </TouchableOpacity>

                    </View>

                    <View
                        style={{
                            flexDirection: "row",
                            marginTop: 2,


                        }}
                    >
                        <TouchableOpacity>
                            {this.renderTextBtn("cholera, unspecified", false, false)}
                        </TouchableOpacity>
                        <View style={{ marginTop: '-5%' }}>
                            {this.renderTitleWithMultipleBtn()}
                        </View>

                    </View>




                </View>
                <View style={{ marginTop: '-5%' }}>
                    {this.renderTextFieldView("Description")}</View>
                <View style={{ marginTop: 10 }}>
                    <TouchableOpacity>
                        {this.renderTextBtn("Add", true, false)}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    renderNewTests = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>

                <View>
                    <View style={{ justifyContent: "space-between" }} >
                        {this.renderTitleWithMultipleBtn(
                            "Laboratory Test",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>

                    <View style={{ justifyContent: "space-between" }} >
                        {this.renderTitleWithMultipleBtn(
                            "Radiology Test",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>

                    <View style={{ justifyContent: "space-between" }} >
                        {this.renderTitleWithMultipleBtn(
                            "Special Test",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>



                    <View style={{ marginTop: 10 }}>
                        <TouchableOpacity>
                            {this.renderTextBtn("Add", true, false)}
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        );
    };

    renderNewPlan = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>

                <View>
                    <View style={{ justifyContent: "space-between" }} >
                        {this.renderTitleWithMultipleBtn(
                            "In-House Treatment",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>

                    <View style={{ marginTop: 20 }}>
                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 12, color: "#848484" }}>Dosage</Text>
                            </View>

                            <View
                                style={{
                                    width: "35%"
                                }}
                            >
                                <Text style={{ fontSize: 12, color: "#848484" }}>Route</Text>
                            </View>


                        </View>

                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 5
                            }}
                        >
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "white" }}>50 mg</Text>
                            </View>

                            <View
                                style={{
                                    width: "35%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "white" }}>Vein near wrist</Text>
                            </View>


                        </View>

                    </View>

                    <View >
                        {this.renderTextFieldView("Comments")}</View>

                    <View style={{ justifyContent: "space-between", marginTop: 20 }} >
                        {this.renderTitleWithMultipleBtn(
                            "Procedure",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>
                    <View >
                        {this.renderTextFieldView("Description")}</View>


                    <View style={{ marginTop: 30 }}>
                        <TouchableOpacity>
                            {this.renderTextBtn("Add", true, false)}
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        );
    };

    renderHospitalIconBtn = (text, icon, createBtn) => {
        return (
            <View
                style={{
                    height: 35,
                    width: text === "New" ? 70 : "80%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: createBtn || text === "New" ? 20 : 20,
                    flexDirection: "row",
                    backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.transparent : color.transparent,
                    justifyContent: "space-evenly",
                    borderWidth: createBtn || text === "New" ? 1 : 1,
                    borderColor: "white"
                }}
            >
                <Text
                    style={{
                        fontSize: platform === "web" ? 12 : 14,
                        color: text === "New" ? "white" : "white",
                        fontWeight: "500"
                    }}
                >
                    {text}
                </Text>


            </View>
        );
    };

    renderNewMedical = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>

                <View>
                    <View>
                        {this.renderTitleWithMultipleBtn(
                            "Medical History",
                            [
                                "Joint Pain",
                                "Asthma",
                                "Seizures",
                                "Stroke",
                                "Blood Problems",
                                "Cancer",
                                "Heart Disease",
                                "Thyroid Disorder",
                                "High BP",
                                "Diabetes/Blood Sugar",
                                "Mental Illness",


                            ],
                            3,
                            true
                        )}

                    </View>

                    <FlatList
                        data={Constants.eye_values}
                        numColumns={2}
                        scrollEnabled={false}
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                        containerStyle={{ alignItems: "center", alignContent: "center" }}
                        renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)}
                    //   extraData={this.state.selectedEye}
                    />


                    <View style={{ marginTop: 20 }}>
                        <Text style={{ fontSize: 25, color: color.white }}>Description</Text>
                    </View>

                    {this.renderTextFieldView("Description")}
                    <View style={{ marginTop: 20 }}>
                        <TouchableOpacity>
                            {this.renderTextBtn("Add", true, false)}
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        );
    }


    renderNewHospital = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>


                <View>
                    <View>
                        <Text style={{ color: 'white', fontSize: 15, }}>Have you been hospitalized under any surgeries ?</Text>
                    </View>
                    <View style={{
                        marginLeft: 5,
                        width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20
                    }}>
                        <View style={{ width: '30%' }}>
                            <TouchableOpacity
                                onPress={() => this.setState({
                                    isHospitalizsed: true,
                                    isSurgery: false,
                                    isHospitalDiscription: true
                                })}
                            >
                                {this.renderHospitalIconBtn("Hospitalized", "", false, "")}
                            </TouchableOpacity>


                        </View>
                        <View style={{ width: '30%' }}>
                            <TouchableOpacity
                                onPress={() => this.setState({
                                    isSurgery: true,
                                    isHospitalizsed: false,
                                    isHospitalDiscription: true
                                })}
                            >
                                {this.renderHospitalIconBtn("Surgery", "", false, "")}
                            </TouchableOpacity>


                        </View>
                    </View>
                    {this.state.isHospitalizsed === true ?
                        <View style={{ marginTop: 20 }}>
                            {this.renderTitleWithMultipleBtn(
                                "Hospital for",
                                [
                                    "Joint Pain",
                                    "Asthma",
                                    "Seizures",
                                    "Stroke",
                                    "Blood Problems",
                                    "Cancer",
                                    "Heart Disease",
                                    "Thyroid Disorder",
                                    "High BP",
                                    "Diabetes/Blood Sugar",
                                    "Mental Illness",


                                ],
                                3,
                                true
                            )}
                        </View>
                        : null}

                    {this.state.isSurgery === true ?
                        <View style={{ marginTop: 20 }}>
                            {this.renderTitleWithMultipleBtn(
                                "Surgery",
                                [
                                    "Joint Pain",
                                    "Asthma",
                                    "Seizures",
                                    "Stroke",
                                    "Blood Problems",
                                    "Cancer",
                                    "Heart Disease",
                                    "Thyroid Disorder",
                                    "High BP",
                                    "Diabetes/Blood Sugar",
                                    "Mental Illness",


                                ],
                                3,
                                true
                            )}
                        </View>
                        : null}

                    {this.state.isHospitalDiscription === true ?
                        <View>
                            <View style={{ marginTop: 20 }}>
                                <Text style={{ fontSize: 25, color: color.white }}>Description</Text>
                            </View>

                            {this.renderTextFieldView("Description")}
                            <View style={{ marginTop: 20 }}>
                                <TouchableOpacity>
                                    {this.renderTextBtn("Add", true, false)}
                                </TouchableOpacity>
                            </View>
                        </View>
                        : null}

                </View>
            </ScrollView>
        );
    }


    renderNewSocial = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>
                <View>
                    {this.renderSubHeadingBorder("Social History")}

                    <FlatList
                        data={Constants.Social_history}
                        numColumns={4}
                        scrollEnabled={false}
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                        containerStyle={{ alignItems: "center", alignContent: "center" }}
                        renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)}
                    //   extraData={this.state.selectedEye}
                    />
                </View>
                <View>


                    {this.state.isAlchol === true ?
                        <View style={{ marginTop: 20 }}>
                            {this.renderTitleWithMultipleBtn(
                                "Alcohol",
                                [
                                    "Occasionally",
                                    "Social Only",
                                    "Frequently",



                                ],
                                3,
                                true
                            )}
                        </View>
                        : null}
                    {this.state.isSmoking === true ?
                        <View style={{ marginTop: 20 }}>
                            {this.renderTitleWithMultipleBtn(
                                "Smoking",
                                [
                                    "Occasionally",
                                    "1-2 Cigarettes/Day",
                                    "1/2 Pack/Day",
                                    "1 Pack/Day or more",


                                ],
                                2,
                                true
                            )}
                        </View>
                        : null}

                    {this.state.isDrug === true ?
                        <View style={{ marginTop: 20 }}>
                            {this.renderTitleWithMultipleBtn(
                                "Drugs",
                                [
                                    "Smokes Marijuana",
                                    "Cocaine",
                                    "LSD",
                                    "Intravenous Drugs",



                                ],
                                3,
                                true
                            )}
                        </View>
                        : null}

                    {this.state.isMarried === true ?
                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: 5,
                                zIndex: 100000
                            }}
                        >
                            <View style={{ width: '30%' }}>
                                <Text style={{ color: 'white', fontSize: '15' }}>Year</Text>
                            </View>
                            <View style={{ width: '10%' }}>
                            </View>
                            {/* <View style={{width:'40%'}}>

                        <YearPicker value={this.state.year}  onChange={this.handleChange}/>
                        </View> */}
                        </View>

                        : null}



                    <View>
                        <View style={{ marginTop: 20, zIndex: -1 }}>
                            <Text style={{ fontSize: 25, color: color.white }}>Description</Text>
                        </View>

                        {this.renderTextFieldView("Description")}
                        <View style={{ marginTop: 20, zIndex: -10 }}>
                            <TouchableOpacity>
                                {this.renderTextBtn("Add", true, false)}
                            </TouchableOpacity>
                        </View>
                    </View>


                </View>
            </ScrollView>
        );
    }


    renderNewFamilyHistory = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>

                <View>

                    <View>
                        {this.renderTitleWithMultipleBtn(
                            "Select Family Member",
                            [
                                "Father",
                                "Mother",
                                "Brother",
                                "Sister",


                            ],
                            3,
                            true
                        )}
                    </View>

                    <View>
                        {this.renderTitleWithMultipleBtn(
                            "Family History",
                            [
                                "Joint Pain",
                                "Asthma",
                                "Seizures",
                                "Stroke",
                                "Blood Problems",
                                "Cancer",
                                "Heart Disease",
                                "Thyroid Disorder",
                                "High BP",
                                "Diabetes/Blood Sugar",
                                "Mental Illness",


                            ],
                            3,
                            true
                        )}
                    </View>


                    <View style={{ marginTop: 20 }}>
                        <Text style={{ fontSize: 25, color: color.white }}>Description</Text>
                    </View>

                    {this.renderTextFieldView("Description")}
                    <View style={{ marginTop: 20 }}>
                        <TouchableOpacity>
                            {this.renderTextBtn("Add", true, false)}
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        );
    }



    renderImgBtn = (img, title, menuItem) => {
        return (
            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: "center", justifyContent: "space-around", marginTop: 20 }}>
                <Image
                    source={img}
                    style={{
                        height: this.state.selectMenu === menuItem ? 40 : 30,
                        width: this.state.selectMenu === menuItem ? 40 : 30
                    }}
                    resizeMode="contain"
                />
                <Text style={{ fontSize: 10, color: "#7C7C7C", paddingLeft: 0, textAlign: "center" }} numberOfLines={2}>{title}</Text>
            </View>
        );
    };

    btnClickImage = () => {
        this.setState({
            isedit: true
        })
    }

    btnClickBeforeFood = () => {
        this.setState({
            beforefood: true
        })
    }

    btnClickWithFood = () => {
        this.setState({
            withfood: true
        })
    }

    btnClickAfterFood = () => {
        this.setState({
            afterfood: true
        })
    }

    renderNewPrescription = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 550 }}>
                 <View> 

                    <View>
                        {this.renderTitleWithMultipleBtn(
                            "Previous Prescription",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                        {this.renderTitleWithMultipleBtn(
                            "Brand Name",
                            [
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",
                                "GLOFERT",

                            ],
                            3,
                            true
                        )}
                    </View>

                    <View style={{ marginTop: 20 }}>
                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 12, color: "#848484" }}>Genric Name</Text>
                            </View>

                            <View
                                style={{
                                    width: "35%"
                                }}
                            >
                                <Text style={{ fontSize: 12, color: "#848484" }}>Dosage Strength</Text>
                            </View>
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 12, color: "#848484" }}>Dosage Type</Text>
                            </View>

                        </View>

                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: 5
                            }}
                        >
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "white" }}>CLOFERT</Text>
                            </View>

                            <View
                                style={{
                                    width: "35%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "white" }}>25mg</Text>
                            </View>
                            <View
                                style={{
                                    width: "30%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "white" }}>Tablet</Text>
                            </View>

                        </View>

                    </View>

                    <View style={{ marginTop: 20 }}>
                        <View
                            style={{
                                marginLeft: 5,
                                width: platform === "web" ? "100%" : "75%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <View
                                style={{
                                    width: "25%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "#848484" }}>M</Text>
                            </View>

                            <View
                                style={{
                                    width: "25%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "#848484" }}>A</Text>
                            </View>
                            <View
                                style={{
                                    width: "25%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "#848484" }}>E</Text>
                            </View>
                            <View
                                style={{
                                    width: "25%"
                                }}
                            >
                                <Text style={{ fontSize: 14, color: "#848484" }}>N</Text>
                            </View>

                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                marginTop: 20,
                                justifyContent: "space-between"
                            }}
                        >
                            <TouchableOpacity>
                                {this.renderTextBtn("0", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("0.5", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("1", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("1.5", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("2", false, false)}
                            </TouchableOpacity>
                        </View>

                    </View>



                    <View style={{ marginTop: 20 }}>
                        <Text style={{ fontSize: 25, color: color.white }}>Duration</Text>
                    </View>
                    <View style={{ flexDirection: "column" }}>
                        <View
                            style={{
                                flexDirection: "row",
                                marginTop: 20,
                                justifyContent: "space-between"
                            }}
                        >
                            <TouchableOpacity>
                                {this.renderTextBtn("01D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("02D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("03D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("04D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("05D", false, false)}
                            </TouchableOpacity>

                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                marginTop: 20,
                                justifyContent: "space-between"
                            }}
                        >
                            <TouchableOpacity>
                                {this.renderTextBtn("06D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("07D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("08D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("09D", false, false)}
                            </TouchableOpacity>
                            <TouchableOpacity>
                                {this.renderTextBtn("10D", false, false)}
                            </TouchableOpacity>
                        </View>
                        <View>
                            {this.renderTitleWithMultipleBtn()}
                        </View>
                    </View>

                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <TouchableOpacity onPress={() => this.btnClickImage()}>
                                {this.state.isedit === true ?
                                    <Image
                                        source={require("../../../../assets/images/Prescription/Foods/stomach (-1.svg")}
                                        style={{ width: 35, height: 35 }}
                                    /> : <Image
                                        source={require("../../../../assets/images/Prescription/Foods/stomach (1).svg")}
                                        style={{ width: 35, height: 35 }}
                                    />}
                            </TouchableOpacity>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <TouchableOpacity onPress={() => this.btnClickBeforeFood()}>
                                {this.state.beforefood === true ?
                                    <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1562.svg")}
                                        style={{ width: 50, height: 30 }}
                                    /> : <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1559.svg")}
                                        style={{ width: 50, height: 30 }}
                                    />}
                            </TouchableOpacity>
                        </View>
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <TouchableOpacity onPress={() => this.btnClickWithFood()}>
                                {this.state.withfood === true ?
                                    <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1563.svg")}
                                        style={{ width: 50, height: 30 }}
                                    /> : <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1560.svg")}
                                        style={{ width: 50, height: 30 }}
                                    />}
                            </TouchableOpacity>
                        </View>
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <TouchableOpacity onPress={() => this.btnClickAfterFood()}>
                                {this.state.afterfood === true ?
                                    <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1564.svg")}
                                        style={{ width: 50, height: 30 }}
                                    /> : <Image
                                        source={require("../../../../assets/images/Prescription/Foods/Group 1561.svg")}
                                        style={{ width: 50, height: 30 }}
                                    />}
                            </TouchableOpacity>

                        </View>

                    </View>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 10, color: "#848484" }}>Empty Stomach</Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 10, color: "#848484" }}>Before Food</Text>
                        </View>
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 10, color: "#848484" }}>With Food</Text>
                        </View>
                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 10, color: "#848484" }}>After Food</Text>
                        </View>

                    </View>

                {this.renderTextFieldView("Comments")}
                    <View style={{ marginTop: 20 }}>
                        <TouchableOpacity>
                            {this.renderTextBtn("Add", true, false)}
                        </TouchableOpacity>
                    </View>

                </View>
                </ScrollView>
                /* <View>
                    <View style={{
                        flexDirection: "row", borderBottomColor: '#9B9B9B',
                        borderBottomWidth: 1, paddingTop: 2
                    }}>
                        {this.renderSubHeadingBorder(" Previous Prescription")}
                        <View style={{ marginLeft: 15 }}>
                            {this.renderSubHeadingBorder("10-11-2019")}
                        </View>
                    </View>
                    <FlatList
                        data={dummyPrescription}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderPrescriptionItemadd(item, index)
                        }
                    />
                </View> */
            
        );
    };

    renderPrevoiusPrescription = () =>{
      return  <ScrollView>
                <View>
                    <View style={{
                        flexDirection: "row", borderBottomColor: '#9B9B9B',
                        borderBottomWidth: 1, paddingTop: 2
                    }}>
                        {this.renderSubHeadingBorder(" Previous Prescription")}
                        <View style={{ marginLeft: 15 }}>
                            {this.renderSubHeadingBorder("10-11-2019")}
                        </View>
                    </View>
                    <FlatList
                        data={dummyPrescription}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderPrescriptionItemadd(item, index)
                        }
                    />
                </View>
        </ScrollView>
    }

    renderSubHeadingBorder = title => {
        return (
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 20
                }}
            >
                <Text style={{ fontSize: 25, color: color.white }}>{title}</Text>

            </View>
        );
    };

    renderNewVital = () => {
        return (
            // <ScrollView showsVerticalScrollIndicator={false}>
            <View>
                {this.renderTitleWithMultipleBtn(
                    "Vitals",
                    [
                        "Height",
                        "Weight",
                        "Temperature",
                        "BP",
                        "Pulse Oximetry",
                        "Pulse Rate",
                        "Random Blood Sugar"
                    ],
                    3,
                    true
                )}
            </View>
            // </ScrollView>
        );
    };


    showPreviousGlassModal = () => { };

    changeSelected = value => {

        var Symptoms = false;
        var Allergy = false;
        var vitals = false;
        var Assesment = false;
        var Prescription = false;
        var Test = false;
        var Plan = false;
        var MedicalHistory = false;
        var HospitalHistory = false;
        var SocialHistory = false;
        var FamilyHistory = false;
        var PreviousPrescription = false;
        var fields = this.state;
        var selected = "";

        switch (value) {


            case "isSymptoms":
                Symptoms = true;
                selected = "isSymptoms";
                break;
            case "isAllergy":
                Allergy = true;
                selected = "isAllergy";
                break;
            case "isVitals":
                selected = "isVitals";
                vitals = true;
                break;
            case "isAssessment":
                selected = "isAssessment";
                Assesment = true;
                break;
            case "isPrescription":
                selected = "isPrescription";
                Prescription = true;
                break;
            case "isTest":
                selected = "isTest";
                Test = true;
                break;
            case "isPlan":
                selected = "isPlan";
                Plan = true;
                break;
            case "isMedical":
                selected = "isMedical";
                MedicalHistory = true;
                break;
            case "isHospital":
                selected = "isHospital";
                HospitalHistory = true;
                break;
            case "isSocial":
                selected = "isSocial";
                SocialHistory = true;
                break;
            case "isFamily":
                selected = "isFamily";
                FamilyHistory = true;
                break;
            case "isPreviousPrescription":
                selected = "isPreviousPrescription";
                PreviousPrescription = true;
                break;

        }

        fields["selectedView"] = selected;
        fields["isSymptoms"] = Symptoms;
        fields["isAllergy"] = Allergy;
        fields["isVitals"] = vitals;
        fields["isAssessment"] = Assesment;
        fields["isPrescription"] = Prescription;
        fields["isTest"] = Test;
        fields["isPlan"] = Plan;
        fields["isMedical"] = MedicalHistory;
        fields["isHospital"] = HospitalHistory
        fields["isSocial"] = SocialHistory
        fields["isFamily"] = FamilyHistory
        fields["isPreviousPrescription"] = PreviousPrescription




        this.setState({ fields });
    };

    checkRightView = value => {

        switch (value) {
            case "isSymptoms":
                return this.renderNewSymptoms();
            case "isAllergy":
                return this.renderNewAllergy();
            case "isVitals":
                return this.renderNewVital();
            case "isAssessment":
                return this.renderNewAssessment();
            case "isPrescription":
                return this.renderNewPrescription();
            case "isTest":
                return this.renderNewTests();
            case "isPlan":
                return this.renderNewPlan();
            case "isMedical":
                return this.renderNewMedical();
            case "isHospital":
                return this.renderNewHospital();
            case "isSocial":
                return this.renderNewSocial();
            case "isFamily":
                return this.renderNewFamilyHistory();
            case "isPreviousPrescription":
                return this.renderPrevoiusPrescription();
        }
    };

    renderTitleBorder = (selected, title, key) => {
        return (
            <TouchableOpacity onPress={() => this.changeSelected(key)}>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10
                    }}
                >
                    <Text
                        style={{
                            fontSize: 25,
                            color: selected ? color.themePink : "#D9D9D9",
                            fontWeight: "600"
                        }}
                        numberOfLines={1}
                    >
                        {title}
                    </Text>
                    <View
                        style={{
                            marginLeft: 10,
                            width: platform === "web" ? "10%" : "100%",
                            height: 0.5,
                            backgroundColor: "#707070"
                        }}
                    />
                </View>
            </TouchableOpacity>
        );
    };





    renderSymptomsItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.name}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                                // alignItems: "center"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Duration}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "20%",
                                alignItems: "center",
                                height: 60,
                                flexDirection: "row"
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50
                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderSymptoms = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isSymptoms, title, "isSymptoms")}

                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop:20
                        }}
                    >
                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>Symptom's Name</Text>
                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>Duration</Text>
                        </View>
                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>Description</Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 50
                            }}
                        />
                    </View>
                </View>

                <FlatList
                    data={dummySymptoms}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderSymptomsItem(item, index)
                    }
                />

                <View style={{ marginTop: 20 }}>
                    <TouchableOpacity onPress={() => this.changeSelected("isSymptoms")}>
                        {this.renderIconBtn("plus", "New", false, "isSymptoms")}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    renderAllergyItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "95%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.Name}
                            </Text>
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Date}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "25%"
                                // alignItems: "center"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Type}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Status}
                            </Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Comments}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "10%",
                                alignItems: "center",
                                height: 60,
                                flexDirection: "row"
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50
                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderAllergy = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isAllergy, title, "isAllergy")}

                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "15%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>Comments</Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 10
                            }}
                        />
                    </View>
                </View>

                <FlatList
                    data={dummyAllergies}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderAllergyItem(item, index)
                    }
                />

                <View style={{ marginTop: 20 }}>
                    <TouchableOpacity onPress={() => this.changeSelected("isAllergy")}>
                        {this.renderIconBtn("plus", "New", false, "isAllergy")}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    renderAssesmentItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Name}
                            </Text>
                        </View>

                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>

                        <View
                            style={{
                                width: "30%"
                            }}
                        >

                        </View>
                        <View
                            style={{
                                width: "20%",
                                alignItems: "center",
                                height: 60,
                                flexDirection: "row"
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50
                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderAssessment = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isAssessment, title, "isAssessment")}
                <View style={{ marginTop: 20 }}>

                    <View
                        style={{
                            width: "92%"
                        }}
                    >
                        <TextInput
                            placeholder="Provisional Data"
                            style={{ height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5 }}

                        />
                    </View>
                </View>
                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>ICD 10 Code</Text>
                        </View>


                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}>Description</Text>
                        </View>
                        <View
                            style={{
                                width: "30%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 50
                            }}
                        />
                    </View>
                </View>

                <FlatList
                    data={dummyAssesment}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderAssesmentItem(item, index)
                    }
                />

                <View style={{ marginTop: 20 }}>
                    <TouchableOpacity onPress={() => this.changeSelected("isAssessment")}>
                        {this.renderIconBtn("plus", "New", false, "isAssessment")}
                    </TouchableOpacity>
                </View>

                <View style={{ marginTop: 20 }}>

                    <View
                        style={{
                            width: "92%"
                        }}
                    >
                        <TextInput
                            placeholder="Provisional Data"
                            style={{ height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5 }}

                        />
                    </View>
                </View>
            </View>
        );
    };


    renderPrescriptionItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "95%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >

                        <View
                            style={{
                                width: "10%"
                            }}
                        >

                            <View>
                                <Image source={item.image}
                                    style={{ height: 30, width: 30 }}
                                    resizeMode='contain' />
                            </View>

                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.TabletName}
                            </Text>
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Weight}|  {item.BrandName}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.Session}
                            </Text>
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Duration}
                            </Text>

                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.Food}
                            </Text>
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Tablettype}
                            </Text>

                        </View>


                        <View
                            style={{
                                width: "20%",
                                alignItems: "center",
                                height: 60,
                                flexDirection: "row"
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50
                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>
                                <TouchableOpacity>
                                    {this.renderIdButton("heart-o")}
                                </TouchableOpacity>
                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "94%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderPrescriptionItemadd = (item, index) => {
        return (
            <View style={{ padding:10 , borderBottomColor: '#9B9B9B',
            borderBottomWidth: 1,
           }}>
                <View style={{ flexDirection: "row" }}>
                    <View>
                        <Image source={item.image}
                            style={{ height: 50, width: 50,tintColor:"white" }}
                            resizeMode='contain' />
                    </View>
                    <View style={{ marginLeft: 40 }}>
                        <Text style={{ fontSize: 14, color: "white" }}>
                            {item.TabletName}
                        </Text>
                        <Text style={{ fontSize: 12, color: "white" }}>
                            {item.Weight}|  {item.BrandName}
                        </Text>
                    </View>
                    <View style={{ marginLeft: 40 }}>
                        <Text style={{ fontSize: 14, color: "white" }}>
                            {item.Session}
                        </Text>
                        <Text style={{ fontSize: 12, color: "white" }}>
                            {item.Duration}
                        </Text>

                    </View>
                    <View style={{ marginLeft: 40 }}>
                        {this.renderTextBtn("Add")}
                    </View>
                </View>

                <View style={{ marginLeft: 90 }}>
                    <Text style={{ fontSize: 14, color: "white" }}>
                        {item.Food}
                    </Text>
                    <Text style={{ fontSize: 12, color: "white" }}>
                        {item.Tablettype}
                    </Text>

        

                </View>


            </View>
        );
    };




    renderPrescription = (title, isMedication) => {

        return (
            <View>
                <View
                    style={{
                        marginLeft: 5,
                        width: platform === "web" ? "95%" : "75%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <View
                        style={{
                            width: "25%"
                        }}
                    >

                        {this.renderTitleBorder(this.state.isPrescription, title, "isPrescription")}

                    </View>
                    
                    {/* <View
                        style={{
                            width: "20%"
                        }}
                    >


                        <TouchableOpacity
                            style={styles.button}>
                            <Text style={{ color: "white", fontFamily: platform === "web" ? "12" : "12" }}>Old Prescription</Text>
                        </TouchableOpacity>
                    </View> */}


                    <View
                        style={{
                            width: "20%"
                        }}
                    >

                        <TouchableOpacity>


                            {this.renderIconBtn("print", "Print", false, "")}
                        </TouchableOpacity>
                    </View>

                </View>

                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}>
                    <View>
                        <FlatList
                            style={{ marginTop: 10, marginBottom: 10, marginLeft: 20 }}
                            data={SHOWDate}
                            numColumns={7}
                            showsVerticalScrollIndicator={false}
                            scrollEnabled={false}
                            showsHorizontalScrollIndicator={false}
                            containerStyle={{ alignItems: "center", alignContent: "center" }}
                            renderItem={({ item }) => this.renderShowDate( item, false, false, 'showDate', this.state.showDate)}
                        />

                    </View>
                </ScrollView>



                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "10%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "15%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 10
                            }}
                        />
                    </View>
                </View>

                <FlatList
                    data={dummyPrescription}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderPrescriptionItem(item, index)
                    }
                />
                <View
                    style={{
                        marginLeft: 5,
                        width: platform === "web" ? "95%" : "75%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 20
                    }}
                >
                    <View style={{ width: "30%" }}>
                        <TouchableOpacity onPress={() => this.changeSelected("isPrescription")}>
                            {this.renderIconBtn("plus", "New", false, "isPrescription")}
                        </TouchableOpacity>
                    </View>

                    <View style={{ width: "28%" }}>
                        <View
                            style={{
                                flexDirection: "row",

                                alignItems: "center",

                            }}
                        >
                            <View style={{ width: "50%",paddingLeft:5 }}>
                                <Text style={{ fontSize: 12, color: "#848484" }}>
                                    Follow Up Date
                            </Text>

                                <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                    03/10/2020
                            </Text>
                            </View>
                            <View style={{ width: "60%" }}>
                                <Image
                                    source={require("../../../../assets/images/calendar_icon.png")}
                                    style={{ width: 35, height: 35 }}
                                />

                            </View>
                        </View>
                    </View>

                    <View
                        style={{
                            width: "20%"
                        }}
                    >
                        <Picker style={styles.pickerStyle}
                            selectedValue={this.state.language}
                            onValueChange={(itemValue, itemPosition) =>
                                this.setState({ language: itemValue, choosenIndex: itemPosition })}
                        >
                            <Picker.Item label="Select Pharmachy" value="Select pharmachy" />
                            <Picker.Item label="Pharmachy" value="Pharmachy" />

                        </Picker>
                    </View>



                    <View style={{ width: "10%", marginRight: 20 }}>
                        <TouchableOpacity
                            style={styles.button}>
                            <Text style={{ color: "white", fontFamily: platform === "web" ? "12" : "12" }}>Transmit</Text>
                        </TouchableOpacity>
                    </View>


                </View>
            </View>
        );
    };

    renderTestItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "95%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >

                        <View
                            style={{
                                width: "30%"
                            }}
                        >

                            <TouchableOpacity
                            //onPress={() => this.changeSelected("")}
                            >
                                {this.renderTestIconBtn("Albumin-serum", "close", false, "")}
                            </TouchableOpacity>


                        </View>
                        <View
                            style={{
                                width: "30%"
                            }}
                        >

                            <TouchableOpacity
                            //onPress={() => this.changeSelected("")}

                            >
                                {this.renderTestIconBtn("Typhoid meningitis", "close", false, "")}
                            </TouchableOpacity>


                        </View>
                        <View style={{ width: "30%" }}>
                            <TouchableOpacity onPress={() => this.changeSelected("isTest")}>
                                {this.renderIconBtn("plus", "New", false, "isTest")}
                            </TouchableOpacity>
                        </View>

                    </View>



                </View>

            </View>
        );
    };

    renderTest = (title, isMedication) => {
        return (
            <View>
                <View
                    style={{
                        marginLeft: 5,
                        width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        //justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <View
                        style={{
                            width: "20%"
                        }}
                    >
                        {this.renderTitleBorder(this.state.isTest, title, "isTest")}</View>
                    <View
                        style={{
                            width: "2%"
                        }}
                    >
                        <View style={styles.container}>
                            <View style={styles.rectangle}></View>
                        </View></View>

                    <View
                        style={{
                            width: "10%",

                        }}
                    >
                        <Text style={{ color: "#848484", paddingTop: 10, fontSize: platform === "web" ? 14 : 12 }}>Lab Test</Text></View>

                    <View
                        style={{
                            width: "2%"
                        }}
                    >
                        <View style={styles.container}>
                            <View style={styles.Radiotest}></View>
                        </View></View>
                    <View
                        style={{
                            width: "10%",

                        }}
                    >
                        <Text style={{ color: "#848484", paddingTop: 10, fontSize: platform === "web" ? 14 : 12 }}>Radio Test</Text></View>
                    <View
                        style={{
                            width: "2%"
                        }}
                    >
                        <View style={styles.container}>
                            <View style={styles.Spltest}></View>
                        </View></View>
                    <View
                        style={{
                            width: "10%",

                        }}
                    >
                        <Text style={{ color: "#848484", paddingTop: 10, fontSize: platform === "web" ? 14 : 12 }}>Special Test</Text></View>
                </View>

                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "10%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "15%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 10
                            }}
                        />
                    </View>
                </View>

                {this.renderTestItem()}


            </View>
        );
    };

    renderPlanItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "95%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >

                        <View
                            style={{
                                width: "30%"
                            }}
                        >

                            <TouchableOpacity
                            //onPress={() => this.changeSelected("")}
                            >
                                {this.renderTestIconBtn("Albumin-serum", "close", false, "")}
                            </TouchableOpacity>


                        </View>
                        <View
                            style={{
                                width: "30%"
                            }}
                        >

                            <TouchableOpacity
                            //onPress={() => this.changeSelected("")}

                            >
                                {this.renderTestIconBtn("Typhoid meningitis", "close", false, "")}
                            </TouchableOpacity>


                        </View>
                        <View style={{ width: "30%" }}>
                            <TouchableOpacity onPress={() => this.changeSelected(isTest)}>
                                {this.renderIconBtn("plus", "New", false, "isTest")}
                            </TouchableOpacity>
                        </View>

                    </View>



                </View>

            </View>
        );
    };
    renderplan = (title, isMedication) => {
        return (
            <View>
                <View
                    style={{
                        marginLeft: 5,
                        width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        //justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <View
                        style={{
                            width: "20%"
                        }}
                    >
                        {this.renderTitleBorder(this.state.isPlan, title, "isPlan")}</View>
                    <View
                        style={{
                            width: "2%"
                        }}
                    >
                        <View style={styles.container}>
                            <View style={styles.Inhouse}></View>
                        </View></View>

                    <View
                        style={{
                            width: "10%",

                        }}
                    >
                        <Text style={{ color: "#848484", paddingTop: 10, fontSize: platform === "web" ? 14 : 12 }}>InHouse</Text></View>

                    <View
                        style={{
                            width: "2%"
                        }}
                    >
                        <View style={styles.container}>
                            <View style={styles.Radiotest}></View>
                        </View></View>
                    <View
                        style={{
                            width: "10%",

                        }}
                    >
                        <Text style={{ color: "#848484", paddingTop: 10, fontSize: platform === "web" ? 14 : 12 }}>Procedure</Text></View>

                </View>
                <View style={{ marginTop: 20 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: "10%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>

                        <View
                            style={{
                                width: "25%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "15%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                        </View>
                        <View
                            style={{
                                width: 160,
                                alignItems: "center",
                                backgroundColor: color.white,
                                height: 10
                            }}
                        />
                        <View style={{ marginTop: 20 }}>
                            <View
                                style={{
                                    marginLeft: 5,
                                    width: platform === "web" ? "100%" : "75%",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <View
                                    style={{
                                        width: "30%"
                                    }}
                                >
                                    <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                                </View>

                                <View
                                    style={{
                                        width: "20%"
                                    }}
                                >
                                    <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                                </View>
                                <View
                                    style={{
                                        width: "30%"
                                    }}
                                >
                                    <Text style={{ fontSize: 14, color: "#848484" }}></Text>
                                </View>
                                <View
                                    style={{
                                        width: 160,
                                        alignItems: "center",
                                        backgroundColor: color.white,
                                        height: 50
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>

                {this.renderPlanItem()}


            </View>
        );
    };




    renderMedicalItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.name}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "30%",
                                height: 60,
                                flexDirection: "row",
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50,

                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderMedical = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isMedical, title, "isMedical")}

                {this.state.isMedicalHistory === true ?
                    <FlatList
                        data={dummyMedical}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderMedicalItem(item, index)
                        }
                    />
                    : null}
            </View>
        );
    };



    renderHospitalItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.name}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "30%",
                                height: 60,
                                flexDirection: "row",
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50,

                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderHospital = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isHospital, title, "isHospital")}

                {this.state.isHospitalHistory === true ?
                    <FlatList
                        data={dummyMedical}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderHospitalItem(item, index)
                        }
                    />
                    : null}
            </View>
        );
    };

    renderSocialItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.name}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "30%",
                                height: 60,
                                flexDirection: "row",
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50,

                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderSocial = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isSocial, title, "isSocial")}

                {this.state.isSocialHistory === true ?
                    <FlatList
                        data={dummyMedical}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderSocialItem(item, index)
                        }
                    />
                    : null}
            </View>
        );
    };


    renderFamilyHistoryItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 1 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            //  height: 50,
                            alignItems: "center"
                            // paddingBottom: 10
                        }}
                    >
                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                                {item.name}
                            </Text>

                        </View>

                        <View
                            style={{
                                width: "20%"
                            }}
                        >
                            <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                                {item.Description}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "30%",
                                height: 60,
                                flexDirection: "row",
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50,

                                }}
                            >
                                <TouchableOpacity>
                                    {this.renderIdButton("pencil")}
                                </TouchableOpacity>

                                <TouchableOpacity>
                                    {this.renderIdButton("trash")}
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            width: "92%",


                        }}
                    />

                </View>

            </View>
        );
    };

    renderFamilyHistory = (title, isMedication) => {
        return (
            <View>
                {this.renderTitleBorder(this.state.isFamily, title, "isFamily")}

                {this.state.isFamilyHistory === true ?
                    <FlatList
                        data={dummyMedical}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderFamilyHistoryItem(item, index)
                        }
                    />
                    : null}
            </View>
        );
    };


    renderIdButton = image => {
        return (
            <View
                style={{
                    width: 30,
                    height: 30,
                    borderRadius: 20,
                    shadowOpacity: 0.2,
                    shadowRadius: 4,
                    shadowOffset: {
                        height: 2,
                        width: 2
                    },
                    elevation: 4,
                    backgroundColor: "#FCFEFF",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 20
                }}
            >
                <Icon name={image} color={color.themePink} size={15} />
                {/* <Image
            source={image}
            style={{ width: 40, height: 40 }}
            resizeMode="contain"
          /> */}
            </View>
        );
    };

    renderHeaderView = () => {
        return (
            <View
                style={{
                    padding: 20,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <View style={{ flexDirection: "row" }}>
                    <View>
                        <Icon
                            name="arrow-left"
                            size={20}
                            color={color.black}
                        // onPress = {() => this.props.navigation.goBack()}
                        />
                    </View>
                    <View style={{ marginLeft: 20 }}>
                        <Text style={{ fontSize: 20 }}>Roger George</Text>
                        <Text
                            style={{
                                fontSize: 12,
                                color: "#ADB4C1",
                                marginTop: 5,
                                marginBottom: 5
                            }}
                        >
                            27 years | 25 Apr 1992 | +91 9876543212 | 7:15 AM
            </Text>
                        <Text style={{ fontSize: 12, color: "#ADB4C1" }}>
                            Last Visit Date : 12 Jan 2020
            </Text>
                    </View>
                </View>
                {this.renderIconBtn("eye", "View Report", false)}
            </View>
        );
    };


    renderGmailTextField = title => {
        if (platform === "web") {
            return (
                <View
                    style={{
                        borderColor: "#525252",
                        borderWidth: 1,
                        marginTop: 20,
                        height: 35,
                        width: "90%",
                        borderWidth: 1,
                        borderBottomWidth: 1,
                        borderRadius: 20,
                        alignItems: "center",
                        flexDirection: "row",
                        paddingLeft: 10,
                        paddingLeft: 10,
                        paddingRight: 10
                    }}
                >
                    <TextInput
                        placeholder={title}
                        underlineColorAndroid="transparent"
                        style={{ width: "90%" }}
                        autoCapitalize="none"
                    // value = ""
                    // onChangeText = {text => //methodName}
                    />
                </View>
            )
        } else {
            // return (
            //   <GmailInput
            //     label={title}
            //     // onChangeText={text => {
            //     //   this.setState({ email_or_phone: text });
            //     // }}
            //     // hideLabel={this.state.email_or_phone !== ""}
            //   />
            // );
        }
    };


    renderSubHeader = (title, dataSet, isBtnNeeded) => {
        return (
            <View style={{ marginTop: 20, marginBottom: 10 }}>
                <View style={{ flexDirection: "row", height: 50 }}>
                    <Text style={{ fontSize: 18, color: "#848484" }}>{title}</Text>
                    {isBtnNeeded ? (
                        <TouchableOpacity style={{ marginLeft: 20 }}>
                            {this.renderIdButton("pencil")}
                        </TouchableOpacity>
                    ) : null}
                </View>

                <FlatList
                    style={{ marginTop: 10 }}
                    data={dataSet}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderText(item.name, item.value, false)
                    }
                />
            </View>
        );
    };

    renderIconBtn = (icon, text, createBtn) => {
        return (
            <View
                style={{
                    height: 35,
                    width:text=='Print'?"50%": text === "New" ? 100 : 130,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: createBtn || text === "New" ? 20 : 8,
                    flexDirection: "row",
                    backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themePink,
                    justifyContent: "space-evenly",
                    borderWidth: createBtn || text === "New" ? 1 : 0,
                    borderColor: text === "New" ? "#848484" : color.white
                }}
            >
                <Icon name={icon} size={platform === "web" ? 15 : 20} color={text === "New" ? "#848484" : "#ffffff"} />
                <Text
                    style={{
                        fontSize: platform === "web" ? 10 : 13,
                        color: text === "New" ? "#848484" : color.white,
                        fontWeight: "700"
                    }}
                >
                    {text}
                </Text>
            </View>
        );
    };

    renderTestIconBtn = (text, icon, createBtn) => {
        return (
            <View
                style={{
                    height: 35,
                    width: text === "New" ? 100 : "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: createBtn || text === "New" ? 20 : 20,
                    flexDirection: "row",
                    backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.white,
                    justifyContent: "space-evenly",
                    borderWidth: createBtn || text === "New" ? 1 : 1,
                    borderColor: text === "Albumin-serum" ? "#36BA31" : "#0280FF"
                }}
            >
                <Text
                    style={{
                        fontSize: platform === "web" ? 12 : 14,
                        color: text === "New" ? "#848484" : "#848484",
                        fontWeight: "500"
                    }}
                >
                    {text}
                </Text>
                <Icon name={icon} size={platform === "web" ? 15 : 20} color={text === "New" ? "#848484" : "#848484"} />

            </View>
        );
    };

    render() {
        return (
            <View style={Style.rootView}>
                {/* side menu */}
                <View style={[Style.leftBarView, { paddingTop: 30 }]}>
                    <SideMenu navigation={this.props.navigation} />
                </View>
                {/* first view */}
                <View style={Style.leftView}>
                    {/* <View style={Style.leftBarView} /> */}

                    <View
                        style={{ marginTop: platform === "web" ? 0 : 20, width: "100%" }}
                    >
                        {this.renderHeaderView()}
                        <View
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                width: "100%",
                                height: 0.5,
                                backgroundColor: "#707070"
                            }}
                        />
                        <ScrollView style={{ width: "100%", marginBottom: 50, height: 500 }}>

                            {/* Symptoms  view */}
                            <View style={{ margin: 20 }}>

                                {this.renderSymptoms("Symptoms")}

                                <View style={{ height: 30 }} />
                                {/* ocular history view */}

                            </View>
                            {/* Allergy  view */}
                            <View style={{ margin: 20 }}>

                                {this.renderAllergy("Allergies")}

                                <View style={{ height: 30 }} />
                                {/* ocular history view */}

                            </View>


                            {/* vitals view */}
                            <View style={{ margin: 20, marginTop: 0 }}>
                                <View>
                                    {this.renderTitleBorder(
                                        this.state.isVitals,
                                        "Vitals",
                                        "isVitals"
                                    )}

                                    <View
                                        style={{
                                            justifyContent: "flex-end",
                                            width: "90%",
                                            alignItems: "flex-end"
                                        }}
                                    >
                                        {this.renderIconBtn("save", "Save", false, "")}
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <View style={{ width: 150 }}>
                                            {this.renderGmailTextField("Height in cms")}
                                        </View>
                                        <View style={{ width: 150, marginLeft: 10 }}>
                                            {this.renderGmailTextField("Weight in kg")}
                                        </View>
                                        <Text
                                            style={{
                                                fontSize: 17,
                                                color: color.themePink,
                                                paddingTop: 10,
                                                marginLeft: 30
                                            }}
                                        >
                                            23.4 BMI
                    </Text>
                                    </View>

                                    <View style={{ flexDirection: "row" }}>
                                        <View style={{ width: 150 }}>
                                            {this.renderGmailTextField("Blood Pressure")}
                                        </View>
                                        <View style={{ width: 130, marginLeft: 10 }}>
                                            {this.renderGmailTextField("Temperature")}
                                        </View>
                                        <View style={{ width: 150, marginLeft: 10 }}>
                                            {this.renderGmailTextField("Pulse Oximetry")}
                                        </View>
                                        <View style={{ width: 130, marginLeft: 10 }}>
                                            {this.renderGmailTextField("Pulse Rate")}
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {/* Assesment  view */}
                            <View style={{ margin: 20 }}>

                                {this.renderAssessment("Assessment")}

                                <View style={{ height: 30 }} />
                                {/* ocular history view */}

                            </View>
                            <View style={{ margin: 20 }}>

                                {this.renderPrescription("Prescription")}

                                <View style={{ height: 30 }} />
                                {/* ocular history view */}

                            </View>

                            <View style={{ margin: 20 }}>

                                {this.renderTest("Tests")}

                            </View>

                            <View style={{ margin: 20 }}>

                                {this.renderplan("Plan")}

                            </View>

                            <View style={{ margin: 20 }}>
                                <TouchableOpacity onPress={() => this.setState({
                                    isMedicalHistory: true,
                                    isHospitalHistory: false,
                                    isFamilyHistory: false,
                                })}>

                                    {this.renderMedical("Medical History")}
                                </TouchableOpacity>
                            </View>
                            <View style={{ margin: 20 }}>
                                <TouchableOpacity onPress={() => this.setState({
                                    isHospitalHistory: true,
                                    isMedicalHistory: false,
                                    isFamilyHistory: false,

                                })}>

                                    {this.renderHospital("Hospitalization")}
                                </TouchableOpacity>
                            </View>
                            <View style={{ margin: 20 }}>
                                <TouchableOpacity onPress={() => this.setState({
                                    isHospitalHistory: false,
                                    isMedicalHistory: false,
                                    isFamilyHistory: false,
                                    isSocialHistory: true

                                })}>

                                    {this.renderSocial("Social History")}
                                </TouchableOpacity>
                            </View>
                            <View style={{ margin: 20 }}>
                                <TouchableOpacity onPress={() => this.setState({
                                    isHospitalHistory: false,
                                    isMedicalHistory: false,
                                    isFamilyHistory: true,
                                })}>

                                    {this.renderFamilyHistory("Family History")}
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                    </View>
                </View>
                {/* second view */}
                <View style={[Style.rightView, { padding: 20 }]}>
                    <View style={{ width: "100%" }}>
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 13, color: "#ffffff" }}>
                                {this.state.currentTime}
                            </Text>
                        </View>

                        <View style={{ margin: 5, marginTop: 20, marginBottom: 20 }}>
                            {this.checkRightView(this.state.selectedView)}
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}
const styles = StyleSheet.create({

    rectangle: {
        marginTop: 10,
        width: 12,
        height: 12,
        backgroundColor: '#36BA31',
    },
    Radiotest: {
        marginTop: 10,
        width: 12,
        height: 12,
        backgroundColor: '#0280FF',
    },
    Spltest: {
        marginTop: 10,
        width: 12,
        height: 12,
        backgroundColor: '#E5BF1F',
    },
    Inhouse: {
        marginTop: 10,
        width: 12,
        height: 12,
        backgroundColor: '#ED1E86',
    },
    textStyle: {
        margin: 24,
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    pickerStyle: {
        height: 30,
        borderRadius: 20,

        justifyContent: 'center',
        backgroundColor: 'white',
        color: '#848484',
        fontSize: platform === "web" ? 14 : 12
    },
    button: {
        alignItems: "center",
        backgroundColor: color.themePink,
        color: "white",
        padding: 10,
        borderRadius: 10
    },

});