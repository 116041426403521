import React, { Component } from 'react';
import {
    View,
    Text,
    StyleSheet,
    ScrollView,
} from "react-native";
import { color } from "../../../styles/Color";

export default class LabReportDisSummery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            LabReports: this.props.LabReportsData
        }
    }
    render() {
        console.log(this.props.LabReportsData)
        return (
            <View style={styles.MainScrollView}>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.5vw' }}>
                    <View style={styles.TableHeadView}>
                        <View style={{ width: '20%', paddingLeft: '0.9vw' }}><Text style={styles.headerFontStyle}>{"Lab Set"}</Text></View>
                        <View style={{ width: '30%', }}><Text style={styles.headerFontStyle}>{"Test Name"}</Text></View>
                        <View style={{ width: '15%', }}><Text style={styles.headerFontStyle}>{"Result"}</Text></View>
                        <View style={{ width: '30%', }}><Text style={styles.headerFontStyle}>{"Normal Range"}</Text></View>
                        <View style={{ width: '10%', }}><Text style={styles.headerFontStyle}>{"Unit"}</Text></View>
                    </View>
                    <ScrollView style={styles.MainTable}>
                        {
                            this.props.LabReportsData && this.props.LabReportsData.length > 0 ?
                                this.props.LabReportsData.map((item, index) => (
                                    <View>
                                        <View style={[styles.TableDataWrapper]}>
                                        <View style={{ width: '20%', paddingLeft: '1vw', display:'flex', justifyContent:"center" }}><Text style={[styles.TableDataText, {fontWeight:'500', fontSize:'1vw'}]}>{item.labsetname}</Text></View>
                                        <View style={{ width: '30%' }}>
                                            {
                                                item.data.map((list) => (
                                                    <Text style={styles.TableDataTextMap}>{list.labtestname == "" ? " - " : list.labtestname}</Text>)
                                                )
                                            }
                                        </View>
                                        <View style={{ width: '15%', paddingLeft: '2.5vw' }}>
                                            {
                                                item.data.map((list) => (
                                                    <Text style={styles.TableDataTextMap}>{list.result == "" ? " - " : list.result}</Text>)
                                                )
                                            }
                                        </View>
                                        <View style={{ width: '30%', paddingLeft: '3.5vw' }}>

                                            {
                                                item && item.data && item.data.map((list) => (
                                                    <Text style={[styles.TableDataTextMap, {display:'flex', flexDirection:'row'}]}>{list.normal_range == "" ? " - " : list.normal_range && list.normal_range.replace(/\n/g, ', ')}</Text>
                                                    )
                                                    
                                                )
                                            }
                                        </View>
                                        <View style={{ width: '10%',  paddingLeft: '4vw' }}>
                                            {
                                                item && item.data && item.data.map((list) => (
                                                    <Text style={styles.TableDataTextMap}>{list.unit == "" ? " - " : list.unit}</Text>)
                                                )
                                            }
                                        </View>
                                    </View>
                                    <View style={styles.CommentsView}>
                                        <Text style={[styles.CommentsTxt, {fontWeight:'500'}]}>Comments  :  </Text>
                                        <Text style={[styles.CommentsTxt, {width:'90%'}]}>{item.data[0].comment}</Text>
                                    </View>
                                    </View>
                                ))
                                :
                                <View style={styles.NoRecordsView}>
                                    <Text style={styles.NoRecordsText}>No Records to show...</Text>
                                </View>
                        }
                    </ScrollView>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    MainScrollView: {
        height: '28vw',
    },
    headerFontStyle: {
        fontSize: '0.8vw',
        color: "black",
        fontWeight: '500'
    },
    TableHeadView: {
        zIndex: -1, marginTop: 5, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: '2vw', flexDirection: 'row', alignItems: 'center', borderRadius: 4, width: '97%'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataTextMap: {
        fontSize: '0.91vw',
        paddingVertical:'0.5vw',
        // height:'7vw',
        // minHeight:'2vw',
        // borderBottomWidth:1
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '97%',
        minHeight: "3vw",
        // alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    CommentsView:{
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        display:'flex',
        flexDirection:'row',
        paddingVertical:'0.5vw',
        paddingLeft:'1vw'
    },
    CommentsTxt:{
   fontSize:'0.9vw'
    },
    MainTable: {
        height: '26vw',
        width: '98%'
    },
})

