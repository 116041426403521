import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;

export const Anaesthesia = (props) => {
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{ maxHeight: '70vh' }}
        >
            <View style={{ paddingLeft: 40 }}>
                <View>
                    <View style={{ flexDirection: "row", alignItems: 'flex-end' }}>
                        <View style={{ width: '30%' }}>
                            <Text style={styles.AnesthesiaHead}>Hospital Name</Text>
                        </View>
                        <View style={{ width: '60%' }}>
                            <TextInput
                                value={props.hospitalName}
                                style={{
                                    fontSize: '1vw',
                                    color: 'black',
                                    // borderBottomColor: 'grey',
                                    // borderBottomWidth: 1,
                                }}
                                placeholder="Enter Hospital Name"
                                underlineColorAndroid="transparent"
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaHospitalName", text)
                                }} 
                            />
                        </View>
                    </View>
                    <View style={styles.AnesthesiaSubHeadView}>
                        <Text style={styles.SubHead}>Doctor-in-charge / Principal Surgeon / Principal Interventionist:</Text>
                    </View>
                    <View style={styles.TextBoxView}>
                        <View style={{ width: '33%' }}>
                            <Text style={styles.AnesthesiaLabelText}>Name:</Text>
                            <Text style={styles.AnesthesiaLabelText}>Qualification</Text>
                            <Text style={styles.AnesthesiaLabelText}>Address</Text>
                            <Text style={styles.AnesthesiaLabelText}>Phone</Text>
                            <Text style={styles.AnesthesiaLabelText}>Email</Text>

                        </View>
                        <View style={{ width: '61%' }}>
                            <TextInput
                                value={props.selectedValueClinic_Droctor.value}
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaDoctorName", text)
                                }} 
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaQualification", text)
                                }} 
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaDoctorAddress", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaphone", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaEmail", text)
                                }}
                                underlineColorAndroid="transparent"
                            />

                        </View>
                    </View>

                    <View style={styles.AnesthesiaSubHeadView}>
                        <Text style={styles.SubHead}>Information about the patient</Text>
                    </View>
                    <View style={styles.TextBoxView}>
                        <View style={{ width: '33%' }}>
                            <Text style={styles.AnesthesiaLabelText}>Name: Mr./Ms./Mrs</Text>
                            <Text style={styles.AnesthesiaLabelText}>Age</Text>
                            <Text style={styles.AnesthesiaLabelText}>Address</Text>
                        </View>
                        <View style={{ width: '61%' }}>
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                value={props.clinicdetails.patient_name}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                value={props.clinicdetails.patient_age}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                value={props.anaesthesiaAddress}
                                underlineColorAndroid="transparent"
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaAddress", text)
                                }}
                            />


                        </View>
                    </View>


                    <View style={styles.SubHeadView}>
                        <Text style={styles.SubHead1}>Information about the patient's guardian(proxy consent)</Text>

                        <Text style={styles.SubHeadHelperText}>(This clause should be filled and the guardian should sign this consent only in case of incompetent patients i.e. minors, old aged, unconscious, mentally unfit, disoriented patients)</Text>

                    </View>
                    <View style={styles.TextBoxView}>
                        <View style={{ width: '33%' }}>
                            <Text style={styles.AnesthesiaLabelText}>Name: Mr./Ms./Mrs</Text>
                            <Text style={styles.AnesthesiaLabelText}>Address</Text>
                            <Text style={styles.AnesthesiaLabelText}>Phone Number</Text>
                            <Text style={styles.AnesthesiaLabelText}>Relationship with the patient, if any</Text>
                        </View>
                        <View style={{ width: '61%' }}>
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaGurdianName", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaGurdianAddress", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaGurdianPhoneNo", text)
                                }}
                                underlineColorAndroid="transparent"
                            />

                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaGurdianRelationShip", text)
                                }}
                                underlineColorAndroid="transparent"
                            />


                        </View>
                    </View>
                    <View style={{ marginTop: '0.6vw', width: "80%" }}>
                        <Text style={styles.SubHeadHelperText}>(A person accompanying an unrelated patient should write <b> ‘Unrelated-accompanying’ </b> and when consent is given by higher authorities of a hospital, designation such as <b>‘Medical Superintendent’</b> or <b>‘Medical Director’</b> must be written)</Text>
                    </View>

                    <View style={{ marginTop: '2vw', width: "80%" }}>
                        <Text style={styles.SubHead1}>Scheduled date for the proposed intervention / procedure / surgery</Text>
                    </View>
                    <View>
                    <TextInput
                                style={styles.AnesthesiaFormTextBox }
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaDate", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                    </View>
                    <View style={styles.TextBoxView}>
                        <View style={{ width: '33%' }}>
                            <Text style={styles.AnesthesiaLabelText}>Principal Anesthetist</Text>
                            <Text style={styles.AnesthesiaLabelText}>Name: Dr.</Text>
                            <Text style={styles.AnesthesiaLabelText}>Qualification</Text>
                        </View>
                        <View style={{ width: '61%' }}>
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaPrinciple", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaPrincipleDoctorName", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                            <TextInput
                                style={styles.AnesthesiaTextBox}
                                onChangeText={(text) => {
                                    props.onChangedDatas("anaesthesiaPrincipleQualification", text)
                                }}
                                underlineColorAndroid="transparent"
                            />




                        </View>
                    </View>


                    {/* <View style={{ flexDirection: "row", alignItems: 'flex-end', marginTop: 15 }}>
                        <View style={{ width: '33%' }}>

                        </View>
                        <View style={{ width: '61%' }}>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ fontSize: 15, fontWeight: '300', marginBottom: 15 }}>Patient’s / Guardian’s Signature / Thumbimpression</Text>
                                <TextInput
                                    style={{
                                        alignself: '#4E4E4E',
                                        color: 'grey',
                                        borderBottomColor: 'grey',
                                        borderBottomWidth: 1,
                                        marginBottom: 15
                                    }}

                                    underlineColorAndroid="transparent"
                                /></View>

                            <View style={{ flexDirection: "row", marginTop: 10 }}>
                                <View style={{ width: '55%' }}>
                                    <Text style={{ fontSize: 15, fontWeight: '300', marginBottom: 15 }}>Patient’s / Guardian’s Signature  </Text>
                                </View>
                                <View style={{ width: '45%' }}>

                                    <TextInput
                                        style={{
                                            alignself: '#4E4E4E',
                                            color: 'grey',
                                            borderBottomColor: 'grey',
                                            borderBottomWidth: 1,
                                            marginBottom: 15,

                                        }}

                                        underlineColorAndroid="transparent"
                                    /></View></View>
                        </View>
                    </View> */}



                </View>


                <View style={{ marginTop: '1.2vw', width: '94%' }}>

                    <Text style={styles.SubHead1}> Type/s of anesthesia proposed to be induced:</Text>

                    <TextInput
                        style={styles.AnesthesiaCertificateTextBox}
                        onChangeText={(text) => {
                            props.onChangedDatas("anaesthesiaType1", text)
                        }}
                        placeholder="Enter Text"
                        underlineColorAndroid="transparent"
                    />

                    <TextInput
                        style={styles.AnesthesiaCertificateTextBox}
                        onChangeText={(text) => {
                            props.onChangedDatas("anaesthesiaType2", text)
                        }}
                        placeholder="Enter Text"
                        underlineColorAndroid="transparent"
                    />

                    <p style={{ color: 'black', marginTop: '1.2vw', fontSize: '0.9vw' }}>
                        I, the undersigned, do hereby state and confirm as follows:<br /><br />
                        1. I have been explained the following in terms and language that I understand. I have been explained the following in  
                        <TextInput
                                    style={styles.TextBoxAnesthesia}
                                    onChangeText={(text) => {
                                        props.onChangedDatas("anaesthesiaLanguage", text)
                                    }}
                                    underlineColorAndroid="transparent"
                                />. (name of the language or dialect) that is spoken and understood by me.<br /><br />
                        2. I have been explained; I have been provided with the requisite information; I have understood; and thereafter I consent, authorize and direct the above named principal anesthetist and his / her team with associates or assistants of his / her choice to induce anesthesia mentioned hereinabove during the course of the proposed intervention / procedure / surgery and also to administer the requisite drugs and medications.<br /><br />
                        3. I have been explained and have understood the importance of preoperative fasting and the risks of consuming solids / liquids prior to the induction of anesthesia.<br /><br />
                        4. I have been explained and have understood that inducing anesthesia has certain material risks / complications and I have been provided with the requisite information about the same. I have also been explained and have understood that there are other undefined, unanticipated, unexplainable risks / complications that may occur during or after inducing anesthesia.<br /><br />
                        5. I have been explained and have understood that despite all precautions complications may occur that may even result in death or serious disability.<br /><br />
                        6. I have signed this consent voluntarily out of my free will and without any kind of pressure or coercion.<br />
                    </p>

                </View>

                <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'flex-end' }}>
                    {/* <View style={{ width: '10%' }}></View> */}
                    <View style={{ width: '45%' }}>
                        <Text style={styles.EndOfLabel}>Date & Time of giving consent</Text>
                        <Text style={styles.EndOfLabel}>Patient’s / Guardian’s Signature / Thumb impression</Text>
                        <Text style={styles.EndOfLabel}>Patient’s / Guardian’s Name</Text>

                    </View>
                    <View style={{ width: '35%' }}>
                        <TextInput
                            style={styles.EndOfTextBox}
                            onChangeText={(text) => {
                                props.onChangedDatas("anaesthesiacurrentDate", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
                        <TextInput
                            style={styles.EndOfTextBox}

                            underlineColorAndroid="transparent"
                        />
                        <TextInput
                            style={styles.EndOfTextBox}

                            underlineColorAndroid="transparent"
                        />
                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginTop: 70 }}>

                    <TouchableOpacity
                         onPress={() => props.createAnaesthesiaForm()} 
                        style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={{marginRight: '1vw'}} />
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity 
                    disabled={props.formId ? false : true}
                     onPress={() => props.anaesthesiapreviewAndPrint()} 
                    style={[Style.certificateButton1,{backgroundColor:props.formId ? color.themeDark : color.disableComponentColor}]} >
                        <Icon name={"print"} size={platform === "web" ? '1vw' : '1.2vw'} color={color.white} style={{marginRight: '1vw'}}/>
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        </ScrollView>
    )
}
const styles = StyleSheet.create({
        AnesthesiaHead: { 
            fontSize: '1vw', 
            fontWeight: '400' 
        },
        AnesthesiaSubHeadView: { marginTop: '1.2vw' },
        SubHead: { 
            fontSize: '1vw', 
            fontWeight: '400' 
        },
        TextBoxView: { 
            flexDirection: "row", 
            alignItems: 'flex-end', 
            marginTop: '1vw' 
        },
        AnesthesiaLabelText: { 
            fontSize: '1vw', 
            fontWeight: '300', 
            marginBottom: '1vw' 
        },
        AnesthesiaTextBox: {
            color: 'grey',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            marginBottom: '1vw',
            fontSize: '1vw'
        },
        SubHeadView: { 
            marginTop: '1vw', 
            width: "80%" 
        },
        SubHead1: { 
            fontSize: '1vw', 
            fontWeight: '400', 
            marginBottom: '0.6vw' 
        },
        SubHeadHelperText: { 
            fontSize: '0.8vw', 
            fontWeight: '300' 
        },
        AnesthesiaFormTextBox: {
            color: 'grey',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            marginBottom: '1vw',
            fontSize: '1vw',
            width:'16vw'
        },
        AnesthesiaCertificateTextBox: {
            color: 'black',
            // borderBottomColor: 'grey',
            // borderBottomWidth: 1,
            paddingBottom:'0.65vw',
            paddingLeft: '0.65vw',
            fontSize: '1vw'
        },
        TextBoxAnesthesia: {
            color: 'grey',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            fontSize: '1vw'
        },
        EndOfLabel: { 
            marginBottom: '0.6vw', 
            fontSize: '1vw' 
        },
        EndOfTextBox: {
            color: 'black',
            borderBottomColor: 'grey',
            borderBottomWidth: 1,
            marginLeft: '1.2vw',
            marginBottom: '0.6vw',
            fontSize: '1vw'
        }
})