//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { CommonSectionHeader, CommonButton } from '../BaseComponent';
import BaseComponentStyle from '../BaseComponentStyle'
import SelectedButton from '../../../components/SelectedButton';


import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const pageName = "MentalStatusExamination"
var Messages = require("../../../utils/InfoMessages")

// create a component
export default class MentalStatusExaminationNew extends BaseComponentStyle {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: this.props.patientAppointment.is_locked,
            patientAppointment: this.props.patientAppointment,
            selectedTemplate: {},
            comment: "",
            select: "",
            favoriteId: "",
            templateData: [],
            mentalStatedata: [],
            examinationId: "",
            getFavorite: [],
            id:null,
            examinationName:"",
            isDisable:false,
            disableButton:false
        }
    }


    componentDidMount() {
        this.getMentalStatusTemplate()
        this.getMentalStatusFavorite()
    }


    componentWillReceiveProps(props) {
        // console.log(JSON.stringify(props))
        let {id}=this.state

        if (props.editItem && props.editItem.title === pageName) {
        //    alert(JSON.stringify( props.editItem.isDisable))
      
              let item = props.editItem.item;

             if (id!== item.id && item.id) {  
                // let isDisable = props.editItem.isDisable;
               
                this.setState({
                  id:item.id,
                  select:item.examination_id,
                  comment:item.examination_desc,
                //   isDisable:isDisable
                },()=>{
                    this.props.clearEditedItem(pageName,{})
                });
              }
          }  
        if (props.reloadTemplate && props.reloadTemplate === pageName) {
            // call once
            this.getMentalStatusFavorite();
            // refresh false
            this.props.clearReloadTemplate();
        }
    }

    // renderSelectTemplate = (item, fill, borderNeeded, selectedKey, selectedValue,isDisable) => {
    //     return (
    //         <View style={{ marginTop: 20 }}>
    //             <SelectedButton
    //                 isDisable={isDisable}
    //                 disable={this.state.isLocked}
    //                 item={item}
    //                 fill={fill}
    //                 borderNeeded={borderNeeded}
    //                 onPressItem={this.onPressTemplate.bind(this, selectedKey)}
    //                 selectedValue={selectedValue}

    //             />
    //         </View>
    //     )
    // }
    onPressTemplate = (key, value) => {
        // alert(JSON.stringify(value))
        let states = this.state;
        states[key] = value;
        this.setState({
            states
        },()=>{
            this.getMentalStatusFavorite(value.value)
        })
    }



    getMentalStatusTemplate() {
        var service_url = Constants.PSY_MENTAL_STATUS_TEMPLATE_GET
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMentalStatusTemplateSuccess,
            this.getMentalStatusTemplateFailure
        )
    }
    getMentalStatusTemplateSuccess = (response) => {
        if (response.status == "success") {
            // alert(JSON.stringify(response.data))

            this.setState({
                templateData: response.data
            }, () => {
                var examinationArr = []
                for (var i = 0; i < this.state.templateData.length; i++) {
                    var data = {
                        "label": this.state.templateData[i].examination_name,
                        "value": this.state.templateData[i].id,

                    }
                    examinationArr.push(data)
                }
                this.setState({
                    mentalStatedata: examinationArr,
                })
            })
        }
    }
    getMentalStatusTemplateFailure = (error) => {
        console.log("error", error)
    }
    renderSelect = (item, fill, borderNeeded, selectedKey, selectedValue, index,isDisable) => {
        // alert(JSON.stringify(item))
        return (
            <SelectedButton
                //   {...otherProps}
                isDisable={isDisable}
                disable={this.state.isLocked}
                item={item}
                fill={fill}
                borderNeeded={borderNeeded}
                onPressItem={this.onPressAction.bind(this, selectedKey)}
                selected={selectedValue}

            />
        );
    }

    onPressAction = (key, value, label) => {
        let states = this.state;
        states[key] = value;
        this.setState({
            states
        })
    }
    addMentalStatusExamination() {
        // alert(this.state.select)
        var data = {

            "appointment_id": this.state.patientAppointment.appointment_id,
            "examination_id": this.state.select,
            "examination_desc": this.state.comment
        }
       if(this.state.select){
        var service_url = Constants.PSY_MENTAL_STATUS_EXAMINATION_POST
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.addMentalStatusExaminationSuccess,
            this.addMentalStatusExaminationFailure
        )
      }else{
          this.props.showResposeValue("error",Messages.MentalStatusExaminationField)
      }

    }

    clearComplaint() {
        var states = this.state;
        states["comment"] = "";
        states["disableButton"] = false;
        states["select"] = "";
        states["id"] = "";
        this.setState({
            states
        },()=>{this.props.clearEditedItem(pageName,{})})
    }

    addMentalStatusExaminationSuccess = (response) => {
        if (response.status == 'success') {
            this.clearComplaint();

            // reload data
            this.props.refreshData(pageName);
            this.props.showResposeValue("success", response.message)
        }
    }
    addMentalStatusExaminationFailure = (error) => {
        console.log("error", error)
    }


    getMentalStatusFavorite(favorite_id="") {
        var service_url = Constants.PSY_MENTAL_STATUS_FAVORITE_GET+
        "?favorite_id="+favorite_id
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMentalStatusFavoriteSuccess,
            this.getMentalStatusFavoriteFailure,
            favorite_id
        )
    }
    getMentalStatusFavoriteSuccess = (response,favorite_id) => {
        if (response.status == "success") {

            if(favorite_id){
                // alert("response -----> "+JSON.stringify(response))
                this.mentalStatusExaminationBlukSave(response.data)
            }else{

            var getData = [];

            if (response.data.length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                    var preparedata = {
                        label: response.data[i].favorite_name,
                        value: response.data[i].id,
                    }
                    getData.push(preparedata)

                }
            }

            this.setState({
                getFavorite: getData
            }, () => {
                
            })
        }
        }
    }
    getMentalStatusFavoriteFailure = (error) => {
        console.log(error);
    }
    mentalStatusExaminationBlukSave(tempdatas){
        var getPrepareDatas=[]

        if(tempdatas.length > 0){

            for (let i = 0; i < tempdatas.length; i++) {

                var prepareData = {
                    "examination_id": tempdatas[i].mental_status_examination_id,
                    "examination_desc": tempdatas[i].value
                };
                getPrepareDatas.push(prepareData)
            }
        }

        var data={
            "appointment_id":this.state.patientAppointment.appointment_id,
            "mental_status_examinations": getPrepareDatas
        }

        var service_url = Constants.PSY_MENTAL_STATUS_EXAMINATION_BULK_SAVE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.mentalStatusExaminationBlukSaveSuccess,
            this.mentalStatusExaminationBlukSaveFailure
        )
    }
    mentalStatusExaminationBlukSaveSuccess=(response)=>{
      if(response.status=="success"){
        this.props.showResposeValue("success", response.message)
        this.props.refreshData(pageName);
    }else{
        this.props.showResposeValue("error", response.message)    
    }
    }
    mentalStatusExaminationBlukSaveFailure=(error)=>{
        console.log("error", error)
        this.props.showResposeValue("error", error.message)    

    }

    render() {
        return (
            <View style={{}}>
                {this.renderSubHeadingBorder("Mental Status Examination", false, true)}
                <View style={{ marginBottom: 15 }}>
                    <CommonSectionHeader heading={"Templates"} />


                    {
                        this.state.getFavorite.length > 0 ?
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>{
                                this.state.getFavorite.map((item, index) => {
                                    return (
                                        <View style={{ marginRight: 8, marginBottom: 8 }} key={index}>
                                            <CommonButton
                                                item={item}
                                                selectedvalue={this.state.selectedTemplate}
                                                butttonText={item.label}
                                                buttonType={"outlineNonTheme"}
                                                rightIcon={true}
                                                buttonAction={this.onPressTemplate.bind(this)}
                                                buttonKey={"selectedTemplate"}
                                            />
                                        </View>
                                    )
                                })
                            }
                            </View>

                            : <View />
                    }

                    {/* {this.renderSelectTemplate("", false, false, 'selectedTemplate', this.state.selectedTemplate)} */}
                </View>
                <View>
                    <CommonSectionHeader heading={"Select"} />
                    <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginHorizontal: 20 }}>
                        {
                            this.state.mentalStatedata && this.state.mentalStatedata.map((item, index) => {
                                return this.renderSelect(item, false, false, 'select', this.state.select, index, this.state.id ? true : false)

                            })

                        }

                    </View>
                </View>
                <View style={{ marginTop: 20 }}>
                    <CommonSectionHeader heading={"Description "} />
                    <TextInput
                        placeholder=""
                        
                        maxLength={100}
                        multiline={true}
                        value={this.state.comment}
                        onChangeText={(text) => { this.setState({ comment: text }) }}
                        style={{ marginTop: 15, paddingTop: 10, paddingLeft: 10, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 4 }}

                    />

                </View>

                <View style={{ marginTop: 20, alignSelf: "center" }}>
                    <TouchableOpacity onPress={this.addMentalStatusExamination.bind(this)} >
                        {this.renderTextBtn("Add", true, false)}
                    </TouchableOpacity>
                </View >

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',

        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

