import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "PsychologicalSymptoms"


export class PsychologicalSymptoms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofPsychologicalSymptoms: {},

            hideEditIcon: false,
            isheading:false
        }
    }

    componentDidMount() {
        this.getPsychologicalSymptoms()
    }

    getPsychologicalSymptoms() {
        var service_url = Constants.PSY_PSYCHOLOGICAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPsychologicalSymptomsSuccess,
            this.getPsychologicalSymptomsFailure
        );
    }

    getPsychologicalSymptomsSuccess = success => {
        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    var prepareData = {};
                    if (key == "hallucinations") {
                        prepareData = {
                            key: "Hallucinations",
                            value: data[key]? "Yes" : "No",
                            details: data.hallucinations_detail
                        }
                    } else if (key == "anxiety") {
                        prepareData = {
                            key: "Anxiety",
                            value: data[key]? "Yes" : "No",
                            details: data.anxiety_detail
                        }
                    }
                    else if (key == "sexual_problem") {
                        prepareData = {
                            key: "Sexual Problems",
                            value: data[key]? "Yes" : "No",
                            details: data.sexual_problem_detail
                        }
                    }
                    else if (key == "suspicions") {
                        prepareData = {
                            key: "Suspicions",
                            value: data[key]? "Yes" : "No",
                            details: data.suspicions_detail
                        }
                    } else if (key == "depressive") {
                        prepareData = {
                            key: "Depressive",
                            value: data[key]? "Yes" : "No",
                            details: data.depressive_detail
                        }
                    } else if (key == "additional_notes") {
                        prepareData = {
                            key: "Additional Notes",
                            value: data[key]? "Yes" : "No",
                            details: data.additional_notes_detail
                        }
                    } 
                    // }else if (key == "nature_treatment" || key == "duration_abstinence" || key == "reason_relapses" || key == "additional_notes") {

                    booleanValues.push(prepareData)
                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofPsychologicalSymptoms: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getPsychologicalSymptomsFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getPsychologicalSymptoms();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofPsychologicalSymptoms, pageName)
        }, 100);

    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Psychological Symptoms"}
                editImage={this.state.hideEditIcon || Object.keys(this.state.getListofPsychologicalSymptoms).length == 0  ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if (item.value ) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
