import React from "react";
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity } from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
import Item from "antd/lib/list/Item";
import { CommonHistorySectionHeader } from '../BaseComponent'
const platform = Platform.OS;
const pageTitle = "Plan";
const pageName = "Plan";
const isPageName = "isPlan";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      inHouse: [],
      procedures: [],
      plan_type: "Inhouse Treatment",
      patientAppointment: this.props.patientAppointment,
      data: {},
      isPlan: isPageName === this.props.selectedView ? true : false,
      intervention: "",
      lineOfManagement: "",
      provisionalDiagnosis: "",

      userType: this.props.userType,
      id: "",
      psyPlanTextBoxDatas: {},
    };
  }

  componentDidMount() {
    this.getInHouse();

    if (this.state.userType === "PSY") {
      this.getPsychiatryPlan();
    }
  }

  renderTestIconBtn = (text, icon, createBtn, flag, borderColor) => {
    return (
      <View
        style={{
          height: 35,
          width: text === "New" ? 100 : "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 20 : 20,
          flexDirection: "row",
          backgroundColor: createBtn
            ? color.themeBlue
            : text === "New"
              ? color.white
              : color.white,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 1,
          borderColor: borderColor == "inhouse" ? "#ED1E86" : "#0280FF",
        }}
      >
        <Text
          style={{
            fontSize: platform === "web" ? 12 : 14,
            color: text === "New" ? "#848484" : "#848484",
            fontWeight: "500",
          }}
        >
          {text}
        </Text>
        <Icon
          name={icon}
          size={platform === "web" ? 15 : 20}
          color={text === "New" ? "#848484" : "#848484"}
        />
      </View>
    );
  };

  componentWillReceiveProps(props) {
    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getInHouse();
      if (this.state.userType === "PSY") {
        this.getPsychiatryPlan();
      }
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isPlan) {
      this.setState({ isPlan: updateFontColor });
    }
  }

  renderSymptomsItem = (item, index) => {
    return (
      <View style={[styles.TableData, {borderBottomColor: color.sectionItemLine}]} >
        <Text style={[Style.contentTableDataLead, { marginHorizontal: '1.2vw', flex: 0.25 }]}>{item.name}</Text>

        <Text style={[Style.contentTableDataLead, { flex: 0.25 }]}>{item.dosage}</Text>

        <Text style={[Style.contentTableDataLead, { flex: 0.25 }]}>{item.route}</Text>
        <View style={{ flex: 0.25, flexDirection: "row" }}>
          {/* <TouchableOpacity onPress={this.editComplaints.bind(this, item,"isInHouse")}>
                 {this.renderIdButton("pencil")}
             </TouchableOpacity> */}
          <TouchableOpacity
            disabled={this.state.isLocked}
            onPress={() => {
              this.deleteItem(item, "Inhouse Treatment");
            }}
          >
            {this.renderIdButton("trash")}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderSymptomsItem2 = (item, index) => {
    return (


      <View style={[styles.TableData, {borderBottomColor: color.sectionItemLine }]} >
        <Text style={[Style.contentTableDataLead, { marginHorizontal: '1.2vw', flex: 0.20 }]}>{item.name}</Text>

        <Text style={[Style.contentTableDataLead, { flex: 0.55,flexWrap:"wrap" }]} numberOfLines={5}>{item.description}</Text>

        <View style={{ flex: 0.25, flexDirection: "row" }}>
          {/* <TouchableOpacity onPress={this.editComplaints.bind(this, item,"isProcedure")}>
                 {this.renderIdButton("pencil")}
             </TouchableOpacity> */}
          <TouchableOpacity
            disabled={this.state.isLocked}
            onPress={() => {
              this.deleteItem(item, "Procedure");
            }}
          >
            {this.renderIdButton("trash")}
          </TouchableOpacity>
        </View>
      </View>


      // <View>
      //   <View style={{ paddingTop: 0 }}>
      //     <View
      //       style={{
      //         // width: platform === "web" ? "100%" : "75%",
      //         flexDirection: "row",
      //         // justifyContent: "space-between",
      //         // alignItems: "center",
      //       }}
      //     >
      //       <View
      //         style={{
      //           flex: 0.4
      //         }}
      //       >
      //         <Text style={Style.contentTableDataLead}>{item.name}</Text>
      //       </View>

      //       <View
      //         style={{
      //           flex: 0.6
      //         }}
      //       >
      //         <Text style={Style.contentTableDataLead}>{item.description}</Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "10%",
      //           alignItems: "center",
      //           height: 60,
      //           flexDirection: "row",
      //         }}
      //       >
      //         <View
      //           style={{
      //             flexDirection: "row",
      //             paddingTop: 5,
      //             paddingBottom: 5,
      //             backgroundColor: color.white,
      //             height: 50,
      //           }}
      //         >
      //           {/* <TouchableOpacity onPress={this.editComplaints.bind(this, item,"isProcedure")}>
      //             {this.renderIdButton("pencil")}
      //           </TouchableOpacity> */}

      //           <TouchableOpacity
      //             disabled={this.state.isLocked}
      //             onPress={() => {
      //               this.deleteItem(item, "Procedure");
      //             }}
      //           >
      //             {this.renderIdButton("trash")}
      //           </TouchableOpacity>
      //         </View>
      //       </View>
      //     </View>
      //   </View>
      //   {/* <View style={Style.sectionItemLine} /> */}
      //   <View
      //     style={{
      //       borderBottomWidth: 1,
      //       borderBottomColor: "#E0E0E0",
      //       width: 350,
      //     }}
      //   />
      // </View>
    );
  };

  getInHouse = () => {
    var service_url =
      Constants.INHOUSE_TREATMENT_GET +"?patient_id=" + 
      this.state.patientAppointment.patient_id +
      "&appointment_id=" +
      this.state.patientAppointment.appointment_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getInHouseSuccess,
      this.getInHouseFailure
    );
  };

  getInHouseSuccess = (response) => {
    if (response.status === "success") {
      //  alert(JSON.stringify(response.data.treatments))
      var field = this.state;
      field["data"] = response.data;
      // this.getPsychiatryPlan()

      this.setState({
        field,
        inHouse: response.data.treatments,
        procedures: response.data.procedures,
      });
    }
  };

  getInHouseFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderTestItem = (item, index, borderColor) => {
    return (
      <View style={{ marginRight: 10 }}>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <TouchableOpacity>
                {this.renderTestIconBtn(
                  item.name,
                  "close",
                  false,
                  "",
                  borderColor
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  };

  // getComplaintsType (type) {

  //   let itemType = "";

  //   let temp = Constants.eye_values.map((item,index)=>{

  //     if(type === item.value) { itemType = item.label; }
  //     return (
  //       null
  //     )
  //   })
  //   return itemType;
  // }

  editComplaints = (item, type, event) => {

    var data = {};
    if (type == "isInHouse") {

      data = {
        isInHouseID: item.id,
        inHouseName: item.name,
        dosage: item.dosage,
        route: item.route,
        comments: item.comments,
      };
    } else if (type == "isProcedure") {
      data = {
        isProcedureID: item.id,
        procedure: item.name,
        description: item.description,
      };
    }
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, data);
  };

  deleteItem = (item, plan) => {
    //this.props.editItem(pageName, item);
    var service_url = Constants.PLAN_DELETE;
    let data = {
      appointment_id: this.state.patientAppointment.appointment_id,
      plan_type: plan,
      plans_id: item.id,
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  getDeleteSuccess = (response) => {
    if (response.status === "success") {
      this.getInHouse();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
    setTimeout(() => {
      this.props.editItem(isPageName, this.state.psyPlanTextBoxDatas)
    }, 500);


  };
  renderPsyPlan =   (value, key) => {
    return (
      <View style={{ marginTop: '1.2vw' }}>
        <TextInput
          editable={false}
          multiline={true}
          placeholder=""
          style={[styles.TextInputView, {marginLeft:key == "intervention"  || key == "provisionalDiagnosis" ? '1.2vw' : null}]}
          value={value}
          onChangeText={(text) => {
            var states = this.state
            states[key] = text
            this.setState({
              states
            })
          }}
        />
      </View>
    )
  }
  clearPsychiatryData() {
    var states = this.state
    states["intervention"] = ""
    states["lineOfManagement"] = ""
    states["provisionalDiagnosis"] = ""

    this.setState({
      states
    })
  }
  getPsychiatryPlan() {
    var service_url = Constants.PSY_PLAN_GET + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPsychiatryPlanSuccess,
      this.getPsychiatryPlanFailure
    )
  }
  getPsychiatryPlanSuccess = (response) => {
    if (response.status == "success") {
      // alert(JSON.stringify(response.data))
      this.setState({
        psyPlanTextBoxDatas: response.data,
        // psyPlanID:response.data.id,
        intervention: response.data.intervention,
        lineOfManagement: response.data.line_of_management,
        provisionalDiagnosis: response.data.provisional_diagnosis
      })
    }



    // clear data
    //  this.clearPsychiatryData();

    // reload data
    //  this.props.refreshData(pageName);
  }
  getPsychiatryPlanFailure() {
    this.props.showResposeValue("error", error.message)

  }

  render() {
    return (
      <View>
        <View
          style={{
            marginLeft: '0.3vw',
            width: platform === "web" ? "100%" : "75%",
            flexDirection: "row",
            //justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "30%",
            }}
          >
            {this.renderTitleBorder(
              this.state.isPlan,
              pageTitle,
              isPageName,
              this.triggerNewItem
            )}
          </View>
        </View>


        {/* <View
          style={{
            marginTop: 20,
            // marginLeft: 5,
            width: platform === "web" ? "100%" : "45%",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <View
            style={{
              width: "50%",
              marginLeft: 40,
            }}
          >
            <Text
              style={
                (Style.contentTableTitle, { marginLeft: 20, color: "#848484" })
              }
            >
              Inhouse
            </Text>
          </View>

          <View
            style={{
              width: "50%",
              marginLeft: 30,
            }}
          >
            <Text style={Style.contentTableTitle}>Procedure</Text>
          </View>
        </View> */}
        <View style={{ marginTop: '0.6vw' }}>
          <CommonHistorySectionHeader
            heading1={"In-House Treatment"}
            heading2={"Procedure"}
            columnSize={[0.5, 0.5]}  //total value is == of 1
            noOfColumn={2}
          />
        </View>
        <View style={{ marginTop: '0.6vw', flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flex: 0.49 }}>
            <CommonHistorySectionHeader
              heading1={"Name"}
              heading2={"Dosage"}
              heading3={"Route"}
              heading4={"Action"}
              columnSize={[0.25, 0.25, 0.25, 0.25]}
              noOfColumn={4}
            />
          </View>
          <View style={{ flex: 0.02 }}></View>
          <View style={{ flex: 0.49 }}>
            <CommonHistorySectionHeader
              heading1={"Name"}
              heading2={"Description"}
              heading3={"Action"}
              columnSize={[0.20, 0.60, 0.20]}
              noOfColumn={3}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", }}>
          <View style={{ flex: 0.49 }}>
            {this.state.inHouse.map((item, index) => {
              return this.renderSymptomsItem(item, index);
            })}
          </View>
          <View style={{ flex: 0.02 }}></View>

          <View style={{ flex: 0.49 }}>
            {this.state.procedures.map((item, index) => {
              return this.renderSymptomsItem2(item, index);
            })}
          </View>

        </View>
        {
          this.state.userType === "PSY" ?

            <View style={{marginTop:'0.6vw'}}>
              {/* Psychiatry TextInputs */}

              <CommonHistorySectionHeader
                heading1={"Intervention"}
                heading2={"Line of Management"}

                columnSize={[0.50, 0.50]}  //total value is == of 1
                noOfColumn={2}
              />

              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.50, }}>
                  {this.renderPsyPlan(this.state.intervention, "intervention")}
                </View>
                <View style={{ flex: 0.50 }}>
                  {this.renderPsyPlan(this.state.lineOfManagement, "lineOfManagement")}
                </View>
              </View>

              <View>
                <CommonHistorySectionHeader
                  heading1={"Provisional diagnosis"}

                  columnSize={[1]}  //total value is == of 1
                  noOfColumn={1}
                />
                {this.renderPsyPlan(this.state.provisionalDiagnosis, "provisionalDiagnosis")}
              </View>
            </View> : null
        }
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Radiotest: {
    marginTop: 10,
    width: 12,
    height: 12,
    backgroundColor: "#0280FF",
  },

  Inhouse: {
    marginTop: 10,
    width: 12,
    height: 12,
    backgroundColor: "#ED1E86",
  },
  textStyle: {
    margin: 24,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  pickerStyle: {
    height: 30,
    borderRadius: 20,

    justifyContent: "center",
    backgroundColor: "white",
    color: "#848484",
    fontSize: platform === "web" ? 14 : 12,
  },
  button: {
    alignItems: "center",
    backgroundColor: color.themePink,
    color: "white",
    padding: 10,
    borderRadius: 10,
  },
  TableData: { 
    flexDirection: "row", 
    alignItems: 'center', 
    paddingVertical: '0.5vw', 
    borderBottomWidth: 1 
  },
  TextInputView: {
    marginBottom:'0.5vw',
    paddingTop: '0.6vw', 
    paddingHorizontal: '0.6vw', 
    paddingVertical: '0.6vw', 
    height: '3.3vw', 
    borderColor: '#D9D9D9', 
    borderWidth: 1, 
    borderRadius: 4,
    width:"80%" 
  }
});
