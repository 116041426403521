import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
} from "react-native";
import { color } from "../../../../styles/Color";
import moment from "moment";
import Pagination from "../../common/Pagination";
import { NavigationTopHeader, returnPaymentMode, paymentReturnType } from "../../BaseComponent";
import "antd/dist/antd.css";
import { DatePicker, Tooltip, Checkbox } from "antd";
import OpthamologyService from "../../../../network/OpthamologyService";
import ImagePath from "../../../../utils/ImagePaths";
import { yellow } from "@material-ui/core/colors";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const navigationHeaderList = [
  { label: "Billing Transaction", value: "ipallTransactions" },
];

const navigationHeaderListIfIPCreateBill = [
  { label: "Create Bill", value: "ipPatientCreateBill" },
  { label: "Pending Bill", value: "ipPatientSavedBill" },
  { label: "Billing Transaction", value: "ipallTransactions" },
  { label: "OT Bills", value: "otbills" },
  // { label: "Summary Bill", value: "summaryBill" },
  { label: "Estimation Bill", value: "estimationBill" },
];

export default class IPOTBill extends Component {
  constructor(props) {
    super(props);

    let fulldate = new Date();
    // let converted_date = moment(fulldate).format("YYYY-MM-DD");

    this.state = {
      isCurrentPageIsIPPatientAppointment:
        this.props.isCurrentPageIsIPPatientAppointment,
      OtBillsDataList: [],
      OtBillsListAfterPagination: [],
      patientProfileDetails: this.props.selectedIpPatientDetails,
      paymentType: "",
      paymentCash: "",
      paymentCard: "",
      paymentCash_Card: "",
      paymentUpi: "",
      paymentBankTrans: "",
      paymentInsurance: "",
      Payment_Date: fulldate,
      selectedOTBill: {},
      companyName: "",
      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : "",
      paymentMode : 0,
      paymentAmt1 : 0,
      paymentAmt2 : 0,
      cardNumber : "",
      upiTransactionID : "",
      bank_transaction_id : "",
      transactionNumber : ""
    };
  }

  componentDidMount() {
    this.getOtBillsData();
  }

  getOtBillsData = () => {
    console.log(this.state.patientProfileDetails);
    var serviceurl =
      Constants.OT_BILLS_IP +
      "?patient_id=" +
      this.state.patientProfileDetails.patient_id +
      "&ip_number=" +
      this.state.patientProfileDetails.id;
    OpthamologyService.getInstance().getComplaints(
      serviceurl,
      this,
      this.getOtBillsSuccess,
      this.getOtBillsFailure
    );
  };
  getOtBillsSuccess = (success) => {
    this.setState({
      OtBillsDataList: success.data,
    });
  };
  getOtBillsFailure = (error) => {
    console.log(error);
  };

  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "");
  }

  PrintBill = (id) => {
    var serviceurl =
      Constants.OT_BILLS_IP +
      "?patient_id=" +
      this.state.patientProfileDetails.patient_id +
      "&ip_number=" +
      this.state.patientProfileDetails.id +
      "&id=" + id + "&export=pdf";
    OpthamologyService.getInstance().documentUploadGet(
        serviceurl,
        this.getPrintSuccess,
        this.getPrintFailure,
        "pdf"
    );
}

getPrintSuccess = (response) => {
    if (response) {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }
};


getPrintFailure = error => {
    console.log(error);
};

  renderOTBillsLeft = () => {
    return (
      <View>
        <View style={styles.TableContainer}>
          <View style={{ width: "100%" }}>
            <View style={[styles.TableTitleContainer]}>
              <View style={{ alignItems: "center", width: "20%" }}>
                <Text style={styles.TableHeadText}>Date</Text>
              </View>
              <View style={{ alignItems: "center", width: "20%" }}>
                <Text style={styles.TableHeadText}>Bill Number</Text>
              </View>
              <View style={{ alignItems: "center", width: "20%" }}>
                <Text style={[styles.TableHeadText]}>
                  Bill Type
                </Text>
              </View>
              <View style={{ alignItems: "center", width: "10%" }}>
                <Text style={styles.TableHeadText}>Amount</Text>
              </View>
              <View style={{ alignItems: "center", width: "20%" }}>
                <Text style={styles.TableHeadText}>Payment Status</Text>
              </View>
              <View style={{ alignItems: "center", width: "10%" }}>
              </View>
            </View>
          </View>

          <ScrollView style={styles.MainTable}>
            {this.state.OtBillsDataList &&
              this.state.OtBillsDataList.length > 0 &&
              this.state.OtBillsListAfterPagination.length > 0 ? (
              this.state.OtBillsListAfterPagination.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        selectedOTBill: item,
                      },() => {
                        {this.renderPaymentDetails(this.state.selectedOTBill)}
                      });
                    }}
                  >
                    <View key={index} style={[styles.TableDataWrapper]}>
                      <View style={{ width: "20%", alignItems: "center" }}>
                        <Text style={[styles.TableDataText]}>
                          {item.invoice_date.split("T")[0]}
                        </Text>
                      </View>
                      <View style={{ width: "20%", alignItems: "center" }}>
                        <Text style={styles.TableDataText}>
                          {item.bill_status === "Payment pending"
                            ? item.temp_invoice_number != null
                              ? item.temp_invoice_number
                              : "-"
                            : item.bill_status === "Payment completed"
                              ? item.invoice_number != null
                                ? item.invoice_number
                                : "-"
                              : "-"}
                        </Text>
                      </View>
                      <View style={{ width: "20%", alignItems: "center" }}>
                        <Text style={styles.TableDataText}>
                          {item.bill_type}
                        </Text>
                      </View>
                      <View style={{ width: "10%", alignItems: "center" }}>
                        <Text style={styles.TableDataText}>
                          {item.total_net_amount}
                        </Text>
                      </View>
                      <View style={{ width: "20%", alignItems: "center" }}>
                        <Text style={styles.TableDataText}>
                          {item.bill_status ? item.bill_status : "-"}
                        </Text>
                      </View>
                      <View style={{ width: "10%", alignItems: "center" }}>
                        {item.bill_status === "Payment completed" ?
                          <TouchableOpacity onPress={()=>{this.PrintBill(item.id)}}>
                            <Tooltip title={'Print Bill'}>
                              <Icon name={"print"} size={'1.2vw'} color={color.themeDark} />
                            </Tooltip>

                          </TouchableOpacity> : null}
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })
            ) : (
              <View style={styles.NoRecordsView}>
                <Text style={styles.NoRecordsText}>No Records to show...</Text>
              </View>
            )}
          </ScrollView>
          {
            <View style={styles.PaginationView}>
              {this.state.OtBillsDataList &&
                this.state.OtBillsDataList.length > 0 ? (
                <Pagination
                  paginationChangedValues={this.paginationChangedValues.bind(
                    this
                  )}
                  totalItems={this.state.OtBillsDataList}
                />
              ) : null}
            </View>
          }
        </View>
      </View>
    );
  };
  paginationChangedValues(data) {
    this.setState({
      OtBillsListAfterPagination: data,
    });
  }

  resetClicked = ()=> {
    this.setState({
      selectedOTBill: {"invoice_number":""},
      paymentType: "",
      paymentCash: "",
      paymentCard: "",
      paymentCash_Card: "",
      paymentUpi: "",
      paymentBankTrans: "",
      paymentInsurance: "",
      Cheque: "",
      firstPayment: "",
      secondPayment: "",
      firstAmount: "",
      secondAmount: "",
      cardNumber: "",
      upiTransactionID: "",
      bank_transaction_id: "",
      companyName: "",
      policyNumber: "",
      validity: "",
      transactionNumber: ""
    })
  }
  renderOTBillsRight = () => {
    return (
      <View style={{ height: "80vh" }}>
        <ScrollView
          style={styles.RightScrollView}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.RightSubHead}>
            <Text style={styles.RightSubHeadText}>Bill Details</Text>
          </View>
          <View>
            <View style={styles.BillDetailView}>
              <Text style={styles.TextInputLabel}>Bill Number</Text>
              <Text style={styles.TextInputLabelDot}>:</Text>
              <View>
                {this.renderTextBox(
                  "BillNumber",
                  this.state.selectedOTBill.bill_status == "Payment pending"
                    ? this.state.selectedOTBill.temp_invoice_number
                    : this.state.selectedOTBill.invoice_number
                )}
              </View>
            </View>
            <View style={styles.BillDetailView}>
              <Text style={styles.TextInputLabel}>Bill Amount</Text>
              <Text style={styles.TextInputLabelDot}>:</Text>
              <View>
                {this.renderTextBox(
                  "BillAmount",
                  this.state.selectedOTBill.total_net_amount
                    ? this.state.selectedOTBill.total_net_amount
                    : 0.0
                )}
              </View>
            </View>
            <View style={styles.BillDetailViewDate}>
              <Text style={styles.TextInputLabel}>Payment Date</Text>
              <Text style={[styles.TextInputLabelDot]}>:</Text>
              <View>
                <DatePicker
                  value={moment(this.state.Payment_Date)}
                  format={"YYYY-MM-DD"}
                  clearIcon={false}
                  style={{
                    height: "2vw",
                    width: "13vw",
                    borderWidth: 0.1,
                    borderColor: "#888888",
                    borderRadius: 4,
                    paddingLeft: "0.5vw",
                    backgroundColor: color.themeShade,
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.RightSubHeadPayment}>
            <Text style={styles.RightSubHeadText}>Payment Type</Text>
          </View>
          <View style={styles.CardView}>
            {this.renderPaymentType(ImagePath.Cash, "Cash")}
            {this.renderPaymentType(ImagePath.Card, "Card")}
            {/* {this.renderPaymentType(ImagePath.CashandCard,"Cash and Card")} */}
            {this.renderPaymentType(ImagePath.UPI, "UPI")}
            {this.renderPaymentType(ImagePath.BankTransfer, "Bank Transfer")}
            {this.renderPaymentType(ImagePath.Insurance, "Insurance")}
            {/* {this.renderPaymentType( ImagePath.Cheque, "Cheque")} */}
          </View>
          {this.state.firstPayment != "" || this.state.firstPayment != undefined || this.state.secondPayment != "" ?
            <View style={{ width: "100%" }}>
              <View style={{ display: "flex", flexDirection: "row", marginBottom: ".35vw", marginLeft:"1vw" }}>
                <View>
                  {this.renderPayment(this.state.firstPayment)}
                </View>
                {this.renderPayment(this.state.secondPayment)}
              </View>
              <View style={{ display: "flex", flexDirection: "row", marginLeft:"1vw" }}>
                {this.state.firstPayment != "" ?
                  this.renderPaymentTypeTextBoxRow(`${this.state.firstPayment} Amount`, "firstAmount", this.state.firstAmount) : null}
                {this.state.secondPayment != "" ?
                  this.renderPaymentTypeTextBoxRow(`${this.state.secondPayment} Amount`, "secondAmount", this.state.secondAmount) : null}
              </View>
            </View>
            : <View style={{width: "100%" }}></View>}

          <View style={styles.RightSubHeadPayment}>
            <Text style={styles.RightSubHeadText}>Billing Details</Text>
          </View>
          <View style={styles.BillingDetailsView}>
            <View style={styles.BillingDetailsTextView}>
              <Text style={styles.NoRecordsText}>Total Bill Amount</Text>
              <Text style={styles.BillingDetailsText}>
                ₹{" "}
                {this.state.selectedOTBill.total_bill_amount
                  ? this.state.selectedOTBill.total_bill_amount
                  : 0.0}
              </Text>
            </View>
            <View style={styles.BillingDetailsTextView}>
              <Text style={styles.NoRecordsText}>Discount Amount</Text>
              <Text style={styles.BillingDetailsText}>₹{" "}{this.state.selectedOTBill.total_discount_amount?this.state.selectedOTBill.total_discount_amount:0.0}</Text>
            </View>
            <View style={styles.BillingDetailsTextView}>
              <Text style={styles.NoRecordsText}>Net Amount</Text>
              <Text style={styles.BillingDetailsText}>
                ₹{" "}
                {this.state.selectedOTBill.total_net_amount
                  ? this.state.selectedOTBill.total_net_amount
                  : 0.0}
              </Text>
            </View>
          </View>
          <View style={styles.HrLine} />
          <View style={styles.BillingDetailsView}>
            <View style={styles.BillingDetailsTextView}>
              <Text style={styles.NoRecordsText}>Amount Payable</Text>
              <Text style={styles.BillingDetailsText}>
                ₹{" "}
                {this.state.selectedOTBill.total_net_amount
                  ? this.state.selectedOTBill.total_net_amount
                  : 0.0}
              </Text>
            </View>
          </View>
        </ScrollView>
        <View>
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5vw",
                justifyContent: "space-around",
              }}
            >
              <TouchableOpacity
                disabled={
                  this.state.selectedOTBill.bill_status == "Payment pending"
                    ? false
                    : true
                }
                backgroundColor={
                  this.state.selectedOTBill.bill_status == "Payment pending"
                    ? color.grayShade
                    : color.themeDark
                }
                onPress={() => {
                  this.complateOTBillPayment();
                  //console.log("STATES:::", this.state);
                }}
                style={[styles.BtnTouch, {backgroundColor : this.state.selectedOTBill.bill_status == "Payment pending" ? color.themeDark : color.grayShade }]}
              >
                <Text style={styles.ButtonText}>{"Payment Received"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { this.resetClicked() }}
                style={styles.BtnTouchClear}
              >
                <Text style={styles.ButtonTextClear}>{"Clear"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderPayment = (paymentType) => {
    if (paymentType === "Card") {
      return (
        <View>
          {this.renderPaymentTypeTextBoxRow("Card Number", "cardNumber", this.state.cardNumber)}
        </View>
      )
    }
    else if (paymentType === "UPI") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow( "UPI Transaction ID", "upiTransactionID", this.state.upiTransactionID)}
        </View>
      )
    }
    else if (paymentType === "Bank Transfer") {
      return (
        <View style={{ flexDirection: "row" }}>
          {this.renderPaymentTypeTextBoxRow( "Bank Transaction ID", "bank_transaction_id", this.state.bank_transaction_id)}
        </View>
      )
    }
    else if(paymentType === "Insurance"){
      return(
        <View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "10vw" }}>
              <View style={{ zIndex: -1 }}>
                {this.renderPaymentTypeTextBoxRow(
                  "Company Name",
                  "companyName",
                  this.state.companyName
                )}
              </View>
            </View>
            <View style={{ width: "10vw", marginLeft: "1vw" }}>
              <View style={{ zIndex: -1 }}>
                {this.renderPaymentTypeTextBoxRow(
                  "Policy Number",
                  "policyNumber",
                  this.state.policyNumber
                )}
              </View>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "10vw"}}>
              <View style={{ zIndex: -1 }}>
                {this.renderPaymentTypeTextBoxRow(
                  "Validity",
                  "validity",
                  this.state.validity
                )}
              </View>
            </View>
            <View style={{ width: "10vw", marginLeft: "1vw" }}>
              <View style={{ zIndex: -1 }}>
                {this.renderPaymentTypeTextBoxRow(
                  "Transaction Number",
                  "transactionNumber",
                  this.state.transactionNumber
                )}
              </View>
            </View>
          </View>
        </View>
      )
    }
    else if(paymentType === "Cheque"){
      return(
        <View>
          {this.renderPaymentTypeTextBoxRow( "Cheque Number", "Cheque", this.state.Cheque)}
        </View>
      )
    } else {
      return null
    }
  }

  renderPaymentDetails = (selectedOTBill) => {
    var paymentMode = paymentReturnType(selectedOTBill.payment_mode)
    this.setState({
      firstPayment: paymentMode.paymentType1,
      secondPayment: paymentMode.paymentType2,
      firstAmount: selectedOTBill.amount_1,
      secondAmount: selectedOTBill.amount_2,
      companyName: selectedOTBill.insurance_company_name,
      policyNumber: selectedOTBill.policy_number,
      validity: selectedOTBill.validity,
      transactionNumber: selectedOTBill.transaction_number
    }, () => {
      this.setState({
        cardNumber: this.state.firstPayment == "Card" ? selectedOTBill.transaction_id_1 : this.state.secondPayment == "Card" ? selectedOTBill.transaction_id_2 : "",
        upiTransactionID: this.state.firstPayment == "UPI" ? selectedOTBill.transaction_id_1 : this.state.secondPayment == "UPI" ? selectedOTBill.transaction_id_2 : "",
        bank_transaction_id: this.state.firstPayment == "Bank Transfer" ? selectedOTBill.transaction_id_1 : this.state.secondPayment == "Bank Transfer" ? selectedOTBill.transaction_id_2 : ""
      })
    })
  }

  renderPaymentType = (img, title) => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.PaymentSelect(title);
        }}
      >
        <View style={{ alignItems: "center", justifyContent: "center" }}>
        {/* <Text
            style={{
              color:
                title === this.state.paymentType ? color.themeDark : "black",
              marginTop: "0.3vw",
              fontSize: "0.7vw",
            }}
          >
            {title}
          </Text> */}
          <Checkbox style={{ color: this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : "black", marginTop: "0.3vw", fontSize: "0.7vw" }}
            checked={this.state.firstPayment == title || this.state.secondPayment == title ? true : false}>{title}
          </Checkbox>
          <Image
            source={img}
            style={{
              tintColor:
              this.state.firstPayment == title || this.state.secondPayment == title ? color.themeDark : "black",
              width: "2vw",
              height: "2vw",
            }}
            resizeMode="contain"
          />
        </View>
      </TouchableOpacity>
    );
  };

  complateOTBillPayment = () => {
    var states = this.state
    var transaction_num_1 = ""
    var transaction_num_2 = ""
    transaction_num_1 = states.firstPayment === "Card" ? states.cardNumber : states.firstPayment === "UPI" ? states.upiTransactionID : states.firstPayment === "Bank Transfer" ? states.bank_transaction_id : states.firstPayment === "Cheque" ? states.Cheque : ""
    transaction_num_2 = states.secondPayment === "Card" ? states.cardNumber : states.secondPayment === "UPI" ? states.upiTransactionID : states.secondPayment === "Bank Transfer" ? states.bank_transaction_id : states.secondPayment === "Cheque" ? states.Cheque : ""
    var PaymentTypeData = returnPaymentMode(this.state.firstPayment + this.state.secondPayment, this.state.firstAmount, this.state.secondAmount, transaction_num_1, transaction_num_2)
    if (this.state.selectedOTBill && this.state.firstPayment != "") {
      var data = {
        bill_id: this.state.selectedOTBill.id,
        invoice_date: moment(this.state.Payment_Date).format("YYYY-MM-DD"),
        payable_amount: this.state.selectedOTBill.total_net_amount,
        total_bill_amount: this.state.selectedOTBill.total_net_amount,
        amount_1: parseFloat(PaymentTypeData.paymentAmt1),
        amount_2: parseFloat(PaymentTypeData.paymentAmt2),
        payment_mode: parseInt(PaymentTypeData.paymentMode),
        transaction_id_1: PaymentTypeData.trans_Num1,
        transaction_id_2: PaymentTypeData.trans_Num2,
        company_name: "",
        employee_id: "",
        insurance_company_name: this.state.companyName,
        policy_number: this.state.policyNumber ? this.state.policyNumber : "",
        validity: this.state.validity ? this.state.validity : "",
        transaction_number: this.state.transactionNumber ? this.state.transactionNumber : ""
        // transaction_id: this.state.paymentType == "UPI" ? this.state.upiTransactionID : "",
        // card_number: this.state.cardNumber ? this.state.cardNumber : null,
      };
      var service_url = Constants.OT_BILLS_COMPLETE_PAYMENT;
      var states = this.state
      if ((states.firstPayment == "Card" || states.secondPayment == "Card") && (!states.cardNumber || (states.cardNumber).length == 0)) {
        this.props.showResposeValue('error', "Enter your Card Transaction ID");
      } else if ((states.firstPayment == "Cheque" || states.secondPayment == "Cheque") && (!states.Cheque || (states.Cheque).length == 0)) {
        this.props.showResposeValue('error', "Enter your Cheque Number");
      } else if ((this.state.firstPayment != "" && this.state.secondPayment == "") && (parseInt( this.state.selectedOTBill.total_net_amount).toFixed() != this.state.firstAmount)) {
        this.props.showResposeValue("error", "Amount Field Should not be Empty")}
      else if ((this.state.firstPayment != "" && this.state.secondPayment != "") && (parseInt( this.state.selectedOTBill.total_net_amount).toFixed() != (parseInt(this.state.firstAmount) + parseInt(this.state.secondAmount)))) {
        this.props.showResposeValue("error", "Amount Not Matched")
      }else if((this.state.firstPayment == "Insurance" || this.state.secondPayment == "Insurance") && (this.state.companyName == "" || this.state.policyNumber == "" || this.state.transactionNumber == "")){
        this.props.showResposeValue("error", this.state.companyName == "" ? "Enter the Insurance Company Name" : this.state.policyNumber =="" ? "Enter the Insurance Policy Number" : "Enter the Insurance Transaction Number")
      }else {
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.OTBillPaymentSuccess,
          this.OTBillPaymentFailure
        );
      }
    } else {
      this.props.showResposeValue("error", "Please select Payment Type");
    }
  };
  OTBillPaymentSuccess = (success) => {
    if (success.status == "success") {
      this.setState({ selectedOTBill: {}, OtBillsDataList:[] });
      this.props.showResposeValue("success", success.message);
      this.getOtBillsData();
      this.resetClicked()
    }
  };
  OTBillPaymentFailure = (error) => {
    this.props.showResposeValue("error", error.message);
  };

  renderTextBox = (key, value) => {
    var states = this.state;
    return (
      <View style={styles.renderTextBoxContainer}>
        <TextInput
          onChangeText={this.handlechange}
          value={value}
          style={styles.TextInputStyle}
        />
      </View>
    );
  };
  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }
  numberValidationWithoutDot(num) {
    return /^[0-9]*$/.test(num);
  }

  renderPaymentTypeTextBoxRow(label, key, value) {
    return (
      <View style={{ marginVertical: 8, marginRight: 15 }}>
        <View style={{ flex: 0.4, justifyContent: "center" }}>
          <Text style={{ fontSize: 12, fontWeight: "500", marginBottom: 10 }}>
            {label}
          </Text>
        </View>
        <View style={{}}>
          {key ? (
            <TextInput
              style={{
                textAlign: "center",
                height: 28,
                width: key === "companyName" ? 130 : 100,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: "#91939F",
              }}
              value={value}
              maxLength={key == "cardNumber" ? 50 : key === "Cheque" ? 15 : null}
              onChangeText={(text) => {
                if (key == "upiTransactionID" || key == "bank_transaction_id") {
                  var state = this.state;
                  state[key] = text;
                  this.setState({
                    state,
                  });
                } else if (
                  this.numberValidation(text) &&
                  (this.state.firstPayment != "Insurance" || this.state.secondPayment != "Insurance") && 
                  key !== "firstAmount" && key !== "secondAmount"
                ) {
                  if (text === "") {
                    text = 0;
                    if (key === "paymentTypeCashAmount") {
                      this.setState({
                        paymentTypeCardAmount: this.state.PaymentTaotalAmount,
                      });
                    } else if (key === "paymentTypeCardAmount") {
                      this.setState({
                        paymentTypeCashAmount: this.state.PaymentTaotalAmount,
                      });
                    }
                  }
                  var states = this.state;
                  states[key] = text;
                  this.setState({ states }, () => {
                    if (
                      (this.state.firstPayment == "Cash" || this.state.secondPayment == "Cash") &&
                      this.state.receviedAmount
                    ) {
                      var states =
                        this.state.receviedAmount -
                        this.state.PaymentTaotalAmount;
                      this.setState({
                        paymentTypeCashAmount: parseFloat(
                          this.state.receviedAmount
                        ),
                        balance: states,
                      });
                    } else if (this.state.firstPayment == "Card" || this.state.secondPayment == "Card") {
                      this.setState({
                        paymentTypeCardAmount: parseFloat(
                          this.state.PaymentTaotalAmount
                        ),
                      });
                    } else if (
                      this.state.paymentType == "Cash & Card"
                    ) {
                      if (key === "paymentTypeCashAmount") {
                        var cardAmount =
                          parseFloat(this.state.PaymentTaotalAmount) -
                          parseFloat(this.state.paymentTypeCashAmount);
                        this.setState({
                          paymentTypeCardAmount: cardAmount,
                        });
                      } else if (key === "paymentTypeCardAmount") {
                        var cashAmount =
                          parseFloat(this.state.PaymentTaotalAmount) -
                          parseFloat(this.state.paymentTypeCardAmount);
                        this.setState({
                          paymentTypeCashAmount: cashAmount,
                        });
                      }
                    }
                  });
                } 
                else if (key == "companyName") {
                  var state = this.state;
                  state[key] = text;
                  this.setState({state,});}
                 else if((this.numberValidationWithoutDot(text)|| text == "") && key === "firstAmount"){
                  if(this.state.selectedOTBill.total_net_amount >= text){
                    var secondAmount = this.state.selectedOTBill.total_net_amount - text
                    this.setState({
                      firstAmount : text,
                      secondAmount : secondAmount
                    })
                  }
                }else if((this.numberValidationWithoutDot(text) || text == "") && key === "secondAmount"){
                  if(this.state.selectedOTBill.total_net_amount >= text){
                    var firstAmount = this.state.selectedOTBill.total_net_amount - text
                    this.setState({
                      secondAmount : text,
                      firstAmount : firstAmount
                    })
                  }
                }
              }}
            />
          ) : null}
        </View>
      </View>
    );
  }

  clearDetails = () =>{
    this.setState({
      Cheque:"",
      firstPayment : "",
      secondPayment : "",
      firstAmount : "",
      secondAmount : "",
      CardNumber : "",
      upiTransactionID: "",
      bank_transaction_id: "",
    })
  }

  clearSinglePayment = (item) =>{
    if(item === "Card"){
      this.setState({
        CardNumber : "",
      })
    }else if(item === "UPI"){
      this.setState({
        upiTransactionID: ""
      })
    }else if(item === "Bank Transfer"){
      this.setState({
        bank_transaction_id : ""
      })
    }else if(item === "Cheque"){
      this.setState({
        Cheque : ""
      })
    }
    this.setState({
      secondAmount : ""
    })
  }

  PaymentSelect = (item) => {
    if (this.state.firstPayment == "" && this.state.secondPayment == "") {
      if (this.state.secondPayment != item) {
        this.setState({
          firstPayment: item
        })
      } else {
        this.setState({
          secondPayment: ""
        }, () => {
          this.clearSinglePayment(item)
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment == "") {
      if (this.state.firstPayment != item) {
        this.setState({
          secondPayment: item
        })
      } else {
        this.setState({
          firstPayment: ""
        }, () =>{
          this.clearDetails()
        })
      }
    } else if (this.state.firstPayment != "" && this.state.secondPayment != "") {
      if (this.state.firstPayment == item) {
        this.setState({
          firstPayment: this.state.secondPayment,
          secondPayment:""
        }, () => {
          this.clearSinglePayment(item)
        })
      } else if (this.state.secondPayment == item) {
        this.setState({
          secondPayment: ""
        }, () => {
          this.clearSinglePayment(item)
        })
      } else {
        var tem_Type = this.state.secondPayment
        this.setState({
          secondPayment : this.state.firstPayment,
          firstPayment: item
        }, () => {
          this.clearSinglePayment(tem_Type)
        })
      }
    }
    this.setState({
      firstAmount : this.state.selectedOTBill.total_net_amount,
      secondAmount : ""
    })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: color.applicationBackgroundColor,
        }}
      >
        <View style={{ width: "65%", height: screenHeight - 65 }}>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={
              this.props.isIpPatientCheckouted
                ? navigationHeaderList
                : this.state.isCurrentPageIsIPPatientAppointment
                  ? navigationHeaderListIfIPCreateBill
                  : navigationHeaderList
            }
            selectedTab={"otbills"}
          />
          <ScrollView
            style={{ width: "100%" }}
            bounces={false}
            bouncesZoom={false}
          >
            <View style={styles.LeftMainView}>{this.renderOTBillsLeft()}</View>
          </ScrollView>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: "100%" }}
        >
          <View style={styles.RightMainView}>{this.renderOTBillsRight()}</View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  LeftMainView: {
    height: "75vh",
    margin: "0.2vw",
    marginTop: "1vw",
    marginLeft: "0.7vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    borderRadius: "0.25vw",
    backgroundColor: "white",
  },
  RightMainView: {
    height: "87vh",
    marginTop: "1vw",
    backgroundColor: color.themeShade,
    borderRadius: "0.25vw",
    margin: "0.2vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  TableContainer: {
    marginLeft: "1vw",
    marginRight: "1vw",
    marginTop: "0.5vw",
  },
  TableHeadText: {
    fontSize: "0.91vw",
    color: "black",
    fontWeight: "500",
  },
  TableDataText: {
    fontSize: "0.91vw",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderRadius: "0.4vw",
  },
  TableDataWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw",
  },
  MainTable: {
    height: "50vh",
  },
  TextInputStyles: {
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: "0.065vw",
    width: "100%",
    height: "1.5vw",
    fontSize: ".9vw",
  },
  renderTextBoxContainer: {
    width: "100%",
    marginTop: "0.65vw",
  },
  renderPaymentTextBoxContainer: {
    width: "70%",
    marginTop: "0.65vw",
  },
  BtnTouch: {
    paddingTop: "0.5vw",
    paddingLeft: "1.1vw",
    paddingRight: "1.1vw",
    paddingBottom: "0.5vw",
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: "10vw",
  },
  ButtonText: {
    color: color.white,
    textAlign: "center",
    fontSize: ".85vw",
  },
  BtnTouchClear: {
    paddingTop: "0.5vw",
    paddingLeft: "1.1vw",
    paddingRight: "1.1vw",
    paddingBottom: "0.5vw",
    borderColor: color.themeDark,
    borderWidth: 1,
    borderRadius: ".25vw",
    width: "10vw",
  },
  ButtonTextClear: {
    color: color.themeDark,
    textAlign: "center",
    fontSize: ".85vw",
  },
  NoRecordsView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5vw",
  },
  NoRecordsText: {
    fontSize: "1vw",
  },
  RightSubHead: {
    backgroundColor: color.sectionHeadingLiteColor,
    alignContent: "flex-start",
    borderRadius: "0.4vw",
    margin: "1vw",
  },
  RightSubHeadPayment: {
    backgroundColor: color.sectionHeadingLiteColor,
    alignContent: "flex-start",
    borderRadius: "0.4vw",
    marginTop: "1vw",
    marginLeft: "1vw",
    marginRight: "1vw",
  },
  RightSubHeadText: {
    marginLeft: "1.3vw",
    margin: "0.5vw",
    fontSize: "1vw",
    fontWeight: "600",
  },
  TextInputStyle: {
    height: "2vw",
    width: "13vw",
    borderWidth: 0.1,
    borderColor: "#888888",
    borderRadius: 4,
    paddingLeft: "0.5vw",
    fontSize: "0.9vw",
  },
  BillDetailView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "stretch",
  },
  BillDetailViewDate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "stretch",
    marginTop: "0.65vw",
  },
  TextInputLabel: {
    fontSize: "0.9vw",
    width: "7vw",
  },
  TextInputLabelDot: {
    fontSize: "0.9vw",
  },
  CardView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    margin: "1vw",
    marginLeft: "1.5vw",
  },
  BillingDetailsView: {
    justifyContent: "center",
    marginLeft: "3.5vw",
    width: "80%",
  },
  BillingDetailsTextView: {
    display: "flex",
    marginTop: "1vw",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  BillingDetailsText: {
    fontSize: "1vw",
    fontWeight: "600",
  },
  HrLine: {
    borderBottomColor: "#888888",
    borderBottomWidth: StyleSheet.hairlineWidth,
    width: "85%",
    marginLeft: "3vw",
    marginTop: "1.5vw",
  },
});
