import React, { Component } from "react";
import {
  View,
  Text,
  // Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  CheckBox,
  Picker,
  TouchableWithoutFeedback
} from "react-native";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
// import SideMenu from "../../../SideMenu";
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import { Calendar } from 'react-native-calendars';
// import RNCalendarEvents from 'react-native-calendar-events';
// import DatePicker from 'react-native-datepicker'
import Pagination from './../Pagination';

import AsyncStorage from "../../../../AsyncStorage";
// import TimerLog from "../../../TimerLog";
import 'antd/dist/antd.css';
import { DatePicker, Radio } from 'antd';
// import ShowPatientBilling from './ShowPatientBilling'
import OpthamologyService from "../../../../network/OpthamologyService";
import Success from "react-native-vector-icons/FontAwesome5";
import { red } from "@material-ui/core/colors";
import { NavigationTopHeader, paymentReturnType, returnPaymentMode } from '../../BaseComponent';
import  CommonDateFilter  from '../../BaseComponent';
import { colors } from "react-native-elements";
import ImagePath from "../../../../utils/ImagePaths";

const navigationHeader = [
  { label: "Create Bill", value: "billingScreen" },
  { label: "Pending Bill", value:"opPatientSavedBill"},
  { label: "Billing Transaction", value: "allBillingTransactions" },
]
const navigationbillingTransHeader =[
  { label: "Billing Transaction", value: "allBillingTransactions" },
]

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

export default class AllTransactions extends Component {
  constructor(props) {
    super(props);

    let patientAppointment = null;
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    try{
      patientAppointment = this.props.patientAppointment;
    }catch(e){

    }

    this.state = {
      patientAppointment: patientAppointment,
      doctorName:'',
      doctorData:[],
      currentTime: "",
      filterData: {
        fromDate: converted_date,
        toDate: converted_date,
        patientName: "",
        billNo: "",
        amount: "",
        payment_type_list: [],
        doctor_id:"",
      },
      totalCashAmt: "",
      totalCardAmt: "",
      filterArray: [],
      // date: "2016-05-15",

      getBillingList: {},
      // appendUrl:Constants.DOCTOR_BILLING + "?clinic_id=" + 10,
      appendUrl: "",
      totalAmountInCash: 0,
      totalAmountInCard: 0,
      totalAmountCashAndCard: 0,
      totalAmountInUpi: 0,
      totalAmountInBankTransfer: 0,
      showCalenderModal: false,
      showCalenderModalFrom: "",
      filterFromDate: converted_date,
      filterToDate: "",

      invoiceNo: "",
      patientId: "",
      patientDetails: {},
      getAllBillingList: {},
      showBillinListPopUpFlag: false,

      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,

      userType: this.props.userType,
      responseType: "",
      responseMeaasge: "",
      isClear: false,

      isOPTclinic: false,


      billingListAfterPagination: [],

      outpatients:true,
      inpatients:false,
      pharmacy:false,
      laboratory:false,
      OT:false,
      ImageLab: false,
      isAcNumClicked: false,


      // common date filter 
      filterDate:{},
      totalAmountInsurance: 0,
      totalAmountInCheque: 0,
      isBillingListSuccess: false,
      isclearInt: 0,
      isAdminAccess: false,

      filterType: "",
      isDisable : true,
      islab: false,
      BillStatus:""
    };
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var {filterData} = this.state;
    states["filterDate"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate;
    this.setState({ states,filterData })

}


  dateFilter = () => {
    return(
     <View> 
      { this.state.isClear && this.state.isClearInt === 0 ? null :
      this.state.isClear && this.state.isClearInt === 1 ?
      <CommonDateFilter 
      removeObject={this.state.patientAppointment.patient_id ? "all" : !this.state.isAdminAccess ? Constants.remove_Obj_user : "all" } 
      defaultSelectedDateFilter={"Today"}   
      startDate={this.state.filterData.fromDate} 
      endDate={this.state.filterData.toDate} 
      clearPress={this.state.isclear}
      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
      filterDate={this.state.filterDate} /> : 
      <CommonDateFilter 
      removeObject={this.state.patientAppointment.patient_id ? "all" : !this.state.isAdminAccess ? Constants.remove_Obj_user : "all"} 
      defaultSelectedDateFilter={"Today"} 
      startDate={this.state.filterData.fromDate} 
      endDate={this.state.filterData.toDate} 
      clearPress={this.state.isclear}
      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
      filterDate={this.state.filterDate} /> 
     }
     </View>
      
    )
  }


  async componentDidMount() {

    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    var isOPT = false
    if(loggedInDataObj.clinic_type){
      isOPT = loggedInDataObj.clinic_type.is_opt_clinic
    }else{
      isOPT = false;
    }

    this.setState({
      isOPTclinic: isOPT,
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    // alert("clinic ***********> "+JSON.stringify(this.state.selectedClinicOrDoctor))

    // this.getBillingList();
    // setInterval(() => {
    //   this.setState({
    //     currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
    //   });
    // }, 1000);

    this.getBillingList();
    this.getPatientInfo();
    if (Constants.ROLE_FO == this.state.userType){
    var service_url = Constants.HOME_GET_CLINIC_DOCTORS;

  fetch(service_url,{
    method:"GET",
    headers:{
      Authorization:"Token " + (localStorage.getItem("token")).toString(),
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(data => {
    this.setState({
      doctorData:data.data
    })
    // console.log("DoctorData:",data.data)
  });

}

    this.createFilterTag();
    

    // this.getLabBillingTransaction()
  }

  getPatientInfo = () => {

    let { patientAppointment, userType } = this.state;

    var serviceUrl = "";
    if( Constants.ROLE_FO == userType ) {
      if(patientAppointment.doctor_id){
        serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=" + patientAppointment.doctor_id;
        }else{
          serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=Nursing";
        }
    } else {
      serviceUrl = Constants.DOCTOR_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getComplaintsSuccess,
      this.getPatientInfoFailure
    );
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      
      this.props.changeStateValues(response.data,this.state.patientAppointment,this.state.userType,this.state.selectedClinicOrDoctor)
    
    }
  };

  getPatientInfoFailure = error=>{}

  removeIcon(item, index) {
    // alert("remove item "+JSON.stringify(index))
    var { filterArray, filterData, filterFromDate, filterToDate } = this.state
    filterArray.splice(index, 1);

    if (item.key == "patient_name")
      filterData["patientName"] = "";
    else if (item.key == "invoice_number")
      filterData["billNo"] = "";
    else if (item.key == "amount")
      filterData["amount"] = "";
    else if (item.key == "payment_type")
      filterData["paymentType"] = "";
    else if (item.key == "payment_type_2")
      filterData["paymentType_2"] = "";  
   else if (item.key == "payment_type_3")
      filterData["paymentType_3"] = "";   
    else if (item.key == "start_date")
      filterFromDate = "";
    else if (item.key == "end_date")
      filterToDate = "";


    this.setState({
      filterData,
      filterArray,
      filterFromDate,
      filterToDate
    }, () => {
      this.getBillingList();
    })
  }

  componentWillReceiveProps(props) {
   // console.log("showResponceMessage----------------> " + JSON.stringify(props))

    if (props && props.showResponceMessage && props.showResponceMessage.showRessponceFlag) {
      this.getBillingList();
      this.showResposeValue(props.showResponceMessage.type, props.showResponceMessage.message);
      this.props.clearResponceMessage(false, "", "", "home")
    }
    // showResponceMessage
    // start and stop refresh
    if(props.refreshAllBills) {

      this.getBillingList();
      this.props.stopRefreshAllBills()
    }
  }

  createFilterTag = () => {
    var temp = [];
    var fields = this.state;

    // if (this.state.filterData.fromDate && this.state.filterData.toDate) {
    // temp.push({
    //   key: "bothDate",
    //   value:
    //     this.state.filterData.fromDate + " - " + this.state.filterData.toDate
    // });
    // }

    if (this.state.filterData.fromDate === "") { // && this.state.filterData.toDate === "") {

      this.props.showResposeValue("error", "Enter invoice date")
      return
    } else {
      /*
      if (this.state.filterData.fromDate === "") {
        if (this.state.filterData.toDate === "") {
        } else {
          temp.push({ key: "end_date", value: this.state.filterData.toDate });
        }
      } else {
        if (this.state.filterData.toDate === "") {
          temp.push({ key: "start_date", value: this.state.filterData.fromDate });
        } else {
          temp.push({ key: "start_date", value: this.state.filterData.fromDate });
          temp.push({ key: "end_date", value: this.state.filterData.toDate });
        }
      }
      */

     if (this.state.filterData.fromDate !== "") {

      temp.push({ key: "invoice_date", value: this.state.filterData.fromDate,label:"From Date" });
     }

    }

    // if (this.state.filterData.fromDate ) {
    //   // if (this.state.filterData.fromDate) {
    //     temp.push({ key: "start_date", value: this.state.filterData.fromDate });
    //   // } else {
    //   //   temp.push({ key: "end_date", value: this.state.filterData.toDate });
    //   // }
    // }

    // if (this.state.filterData.toDate) {
    //   // if (this.state.filterData.fromDate) {
    //   //   temp.push({ key: "start_date", value: this.state.filterData.fromDate });
    //   // } else {
    //     temp.push({ key: "end_date", value: this.state.filterData.toDate });
    //   // }
    // }

    if (this.state.filterData.toDate) {
      temp.push({
        key: "invoice_to_date",
        value: this.state.filterData.toDate,
        label:"To Date" 
      });
    }
    if (this.state.filterData.patientName) {
      if(this.state.isAcNumClicked){
        temp.push({
          key: "patient_account_number",
          value: this.state.filterData.patientName,
          label:"Account Number" 
        });
      }else if(!Constants.REG_EX_MOBILENO.test(this.state.filterData.patientName)){
        temp.push({
          key: "mobile_number",
          value: this.state.filterData.patientName,
          label:"Mobile Number" 
        });
      }else{
        temp.push({
          key: "patient_name",
          value: this.state.filterData.patientName,
          label:"Patient Name"
        });
      }
    }
    if (this.state.filterData.doctor_id) {
      temp.push({
        key: "doctor_id",
        value: this.state.filterData.doctor_id,
        label:"Doctor Id" 
      });
    }
    if (this.state.filterData.billNo) {
      temp.push({ key: "invoice_number", value: this.state.filterData.billNo ,label:"Invoice Number" });
    }
    if (this.state.filterData.amount) {
      temp.push({ key: "amount", value: this.state.filterData.amount,label:"Amount" });
    }

    if (this.state.laboratory == true) {
      for (var i = 0; i < this.state.filterData.payment_type_list.length; i++) {
        temp.push({
          key: (i === 0) ? "payment_type" : "payment_type" + i,
          value: this.state.filterData.payment_type_list[i],
          label: "Payment Type"
        });
      }
    } else {
      if (this.state.filterData.payment_type_list.length == 1) {
        var paymentValue = returnPaymentMode(this.state.filterData.payment_type_list[0])
        temp.push({
          key: "payment_type",
          value: paymentValue.paymentMode,
          label: "Payment Type",
          FlagValue: this.state.filterData.payment_type_list[0]
        });
      }
      else if (this.state.filterData.payment_type_list.length == 2) {
        if (this.state.filterType == false) {
          var paymentValue = returnPaymentMode(this.state.filterData.payment_type_list[0] + this.state.filterData.payment_type_list[1])
          temp.push({
            key: "payment_type",
            value: paymentValue.paymentMode,
            label: "Payment Type",
            FlagValue: this.state.filterData.payment_type_list[0] + " & " + this.state.filterData.payment_type_list[1]
          });
        } else {
          var paymentValue1 = returnPaymentMode(this.state.filterData.payment_type_list[0])
          var paymentValue2 = returnPaymentMode(this.state.filterData.payment_type_list[1])
          temp.push({
            key: "payment_type",
            value: paymentValue1.paymentMode,
            label: "Payment Type",
            FlagValue: this.state.filterData.payment_type_list[0]
          });
          temp.push({
            key: "payment_type1",
            value: paymentValue2.paymentMode,
            label: "Payment Type",
            FlagValue: this.state.filterData.payment_type_list[1]
          });
        }
      }
    }
    
    if(this.state.outpatients) {
      temp.push({
        key: "bill_type",
        value: "OP",
        label:"Bill Type"

      });
    }
    if(this.state.inpatients) {
      temp.push({
        key: "bill_type",
        value: "IP",
        label:"Bill Type"
      });
    }
    if(this.state.pharmacy) {
      temp.push({
        key: "bill_type",
        value: "Pharmacy",
        label:"Bill Type"
      });
    }
    if(this.state.laboratory) {
      temp.push({
        key: "bill_type",
        value: "Lab",
        label:"Bill Type"
      });
    }
    if(this.state.OT) {
      temp.push({
        key: "bill_type",
        value: "OT",
        label:"Bill Type"
      });
    }

    if(this.state.ImageLab) {
      temp.push({
        key: "bill_type",
        value: "Img_Lab",
        label:"Bill Type"
      })
    }

    if( !this.state.outpatients && !this.state.inpatients && !this.state.pharmacy && !this.state.laboratory && !this.state.OT && !this.state.ImageLab ) {
      this.props.showResposeValue("error", "Select bill type")
    }

    if(this.state.laboratory){
      this.setState({
        islab : true
      })
    }else{
      this.setState({
        islab : false
      })
    }
    
    fields["filterArray"] = temp;

    this.setState({ fields }
      , () => {
        if(this.state.userType==='LAB')
         this.getLaboratoryBillingTransaction();
        else 
         this.getBillingList();
      }
    )
  };

  renderTotalItem = (img, title, amount) => {
    return (
      <View style={[styles.dasboardcardcontentstyle, { backgroundColor:title === 'Total' && amount > 0.0 ?color.themeDark: colors.white }]}>
        <Text style={{ fontSize: '0.84vw',  color:title === 'Total' && amount > 0.0 ? colors.white:colors.black }}>{amount}</Text>
        <View style={styles.RenderTotalItemView}>
          {img ?
            <Image
              source={img}
              style={styles.RenderTotalItemImg}
              resizeMode="contain"
            /> : null}
          <Text style={{ fontSize: '0.65vw', color: "#91939F", color:title === 'Total' && amount > 0.0 ? colors.white:colors.black }}>{title}</Text>
        </View>

      </View>
    );
  };

  renderIconBtn = (text, key, item, index) => {
    if( item.key === 'doctor_id'  ){
      return null;
    }
    return (
      <View>
      <Text style={styles.RenderIconBtnLabel}>{item.label}</Text>

      <View
        style={styles.RenderIconBtnContentView}
      >
        <Icon
          onPress={() => {
            // alert("item---> "+JSON.stringify(item))
            this.removeIcon(item, index)
          }}
          name="times"
          size={platform === "web" ? '0.97vw' : '1.3vw'}
          color={color.themePink}
        />
        <Text style={styles.RenderIconBtnText}>
          {text}
        </Text>
      </View>
      </View>
    );
  };

  clearFilter = key => {

    var fields = this.state;

    if (key === "bothDate") {

      fields["filterData"]["fromDate"] = null;
      fields["filterData"]["toDate"] = null;
    } else if (key === "fromDate" || key === "toDate") {
      fields["filterData"]["fromDate"] = null;
      fields["filterData"]["toDate"] = null;
      this.state.filterFromDate = null;
      this.state.filterToDate = null;
      fields["filterData"][key] = null;
    } else if(key) {
      fields["filterData"][key] = "";
    }

    // when click the clear button to clear the fav button
    if (key === "") {
      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      fields["filterData"]["fromDate"] = converted_date;
      fields["filterData"]["toDate"] = converted_date;
      fields["filterData"]["patientName"] = "";
      fields["filterData"]["billNo"] = "";
      fields["filterData"]["amount"] = "";
      fields["filterData"]["payment_type_list"] = [];
      fields["filterType"] = "";
      fields["isDisable"] = true
      
      fields["filterFromDate"] = "";
      fields["inpatients"] = false;
      fields["outpatients"] = true;
      fields["pharmacy"] = false;
      fields["laboratory"] = false;
      fields["OT"] = false;
      fields["ImageLab"] = false;

      fields['appendUrl'] = "";
      fields["filterArray"] = [];
      fields['getBillingList'] = [];
      fields['totalAmountInCash'] = "0.0";
      fields['totalAmountInCard'] = "0.0";
      fields['totalAmountInUpi'] = "0.0";
      fields['totalAmountInBankTransfer'] = "0.0";
      fields['totalAmountCashAndCard'] = "0.0";
      fields['totalAmountInsurance'] = "0.0";
      fields['totalAmountInCheque'] = "0.0";
      /*
      // text box values clear 
      this.state.filterData.fromDate = "";
      this.state.filterData.toDate = "";
      this.state.filterData.patientName = "";
      this.state.filterData.billNo = "";
      this.state.filterData.amount = "";
      this.state.filterData.paymentType = "";
      this.state.filterFromDate = "";
      this.state.filterToDate = "";
      this.state.paymentType = "";
      */

      // after run the billing list


      // setInterval(() => {
      //   this.getBillingList()
      // }, 300);
    }

    this.setState({
      fields,
      isClear: true
    }, ()=>{
        this.createFilterTag()
        this.setState({
          isClearInt: 1,
        },()=>{
          this.dateFilter() 
        })
    });
    
    //this.getBillingList();

  };

  renderRightBtn = (name, outline) => {
    return (
      <View
        style={[{
          borderColor: outline ? color.themeDark : color.themeDark,
          backgroundColor: outline ? null : color.themeDark,
        }, styles.RenderRightBtnView]}
      >
        <Text
          style={[{
            color: outline ? color.themeDark : color.white
          }, styles.RenderRightBtnText]}
        >
          {name}
        </Text>
      </View>
    );
  };

  renderTextField = (title, key, value, halfSize) => {
    return (
      <View
        style={[styles.RenderTextFieldView,{width: halfSize ? "50%" : "80%", marginRight: halfSize ? '0.65vw' : 0,}]}
      >
        <TextInput
          placeholder={title}
          underlineColorAndroid="transparent"
          style={styles.RenderTextFieldInput}
          placeholderTextColor={color.lightGray}
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            this.updateState(key, text);
          }}
        />


      </View>
    );
  };

  renderPaymentBtn = (img, title) => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (this.state.laboratory == true) {
            var fields = this.state;
            var tempList = fields.filterData.payment_type_list;
            if (tempList.indexOf(title) > -1) {
              tempList.splice(tempList.indexOf(title), 1)
            } else {
              if (tempList.length > 2)
                tempList.splice(0, 1)
              tempList.push(title)
            }
            fields["filterData"]["payment_type_list"] = tempList
            this.setState({ fields })
          }
          else {
            var fields = this.state;
            var tempList = fields.filterData.payment_type_list;
            if (tempList.indexOf(title) > -1) {
              tempList.splice(tempList.indexOf(title), 1)
            } else {
              if (tempList.length > 1)
                tempList.splice(0, 1)
              tempList.push(title)
            }

            if (tempList.length == 1) {
              this.setState({ filterType: true, isDisable: true })
            } else if (tempList.length > 1) {
              this.setState({ filterType: this.state.filterType, isDisable: false })
            } else {
              this.setState({ filterType: "", isDisable: true })
            }

            fields["filterData"]["payment_type_list"] = tempList
            this.setState({ fields })
          }
          //    this.updateState("payment_type2", title);    

        }}
      >
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <Image
            source={img}
            style={[styles.PaymentTypeImg, {tintColor:  (this.state.filterData.payment_type_list.indexOf(title) > -1) ? color.themeDark : "black" }]}
            resizeMode="contain"
          />
          <Text style={[{ color: (this.state.filterData.payment_type_list.indexOf(title) > -1) ? color.themeDark : "black"}, styles.PaymentTypeText]}>
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  updateState = (key, value) => {
    // if (key == "paymentType_1") {
    //   var fields = this.state;
    //   fields[key] = value
    //   this.setState({
    //     fields
    //   })
    // }
    var fields = this.state;
    fields["filterData"][key] = value;

    this.setState({ fields });

    // this.createFilterTag();
  };

  closeBillingModal(flag) {
    this.setState({
      showBillinListPopUpFlag: flag
    })
    this.getBillingList();
  }


  showResposeValue(type, meaasge) {
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }


  // successAlert() {
  //   return (
  //     <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10, flexDirection: "column-reverse" }}>
  //       <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
  //         <Success size={25} name={"check"} color={"green"} />
  //         <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
  //           <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
  //         </Text>
  //       </View>
  //       <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
  //         <Icon size={25} name={"close"} color={"#B2C6AA"} />
  //       </TouchableOpacity>
  //     </View>
  //   );
  // }

  successAlert() {
    return (
        <View style={[{ backgroundColor: "#DEF2D6",  borderColor: "#B8C7B2", height: '4.55vw'  }, styles.AlertWrapper]}>
            <View style={styles.AlertContentView}>
                <Success size={'1.62vw'} name={"check"} color={"green"} />
                <Text style={styles.SuccessTitle}>{"SUCCESS: "}
                    <Text style={styles.SuccessText}>{this.state.responseMeaasge}</Text>
                </Text>
            </View>
            <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertIcon}>
                <Icon size={'1.62vw'} name={"close"} color={"green"} />
            </TouchableOpacity>
        </View>
    );
}


  errorAlert() {

    let errorList = (this.state.responseMeaasge).split(",")
    return (
      <View style={[{ backgroundColor: "#EBC8C4", borderColor: "#A58B8A", minHeight: '4.55vw'}, styles.AlertWrapper]}>
        <View style={[{marginTop: 15 }, styles.AlertContentView]}>
          <View style={{}}><Icon size={'1.62vw'} name={"ban"} color={"#D26560"} /></View>
          <View style={styles.ErrorView} >
            <Text style={styles.ErrorTitle}>{"Error: "}
              {errorList && errorList.map((item, index) => {
                return <Text style={styles.ErrorText}>{item}</Text>
              })}
            </Text>
          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertIcon}>
          <Icon size={'1.62vw'} name={"close"} color={"#C69A99"} />
        </TouchableOpacity>
      </View>
    );
  }
  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  renderCheckBox(label, value, key) {
    return (
      <View style={{ flexDirection: 'row', alignSelf: 'flex-end', marginBottom: 15 }}>
        <CheckBox 
        value={value}
          style={{ marginRight: '0.8vw', width:'0.84vw',height:'0.84vw'}}
          onValueChange={() => {

            var states = this.state; 
            states[key] = !value

            this.setState({
              states
            },() => {
              if(key === "laboratory"){
                states["filterData"]["payment_type_list"] = []
                this.setState({
                  outpatients : false,
                  inpatients : false,
                  pharmacy : false,
                  OT : false,
                  ImageLab : false,
                  laboratory: !value,
                  states
                })
              }else if(key !== "laboratory"){
                states["filterData"]["payment_type_list"] = []
                this.setState({
                  laboratory : false,
                  states
                })
              }
            })
          }}

        />
        <Text style={styles.CheckBoxText}>{label}</Text>
      </View>
    );
  }

  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }


  render() {
    // console.log("Selected doctor:",this.state.filterData.doctor_name)

    //console.log("Invoice ID" + this.state.invoiceNo)
   // console.log(" this.state.filterFromDate " + this.state.filterFromDate)
    return (
      // <TouchableWithoutFeedback onPress={()=>{
      //   this.props.updateDateFilterFlag(false)
      //   }}>

     <View>
        <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={Object.keys(this.state.patientAppointment).length>0 ? 
              navigationHeader :
              navigationbillingTransHeader
            }
            selectedTab={"allBillingTransactions"} />
      <View style={styles.MainView}>
        <View style={styles.LeftMainView}>
          {
            this.state.responseType == "success" ?
              this.successAlert() :
              this.state.responseType == "error" ?
                this.errorAlert() : null
          }
         
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.LeftScrollView} bounces={false} bouncesZoom={false} >
            <View style={styles.RenderIconBtnView}>

              {/* search tag view */}
              <View style={styles.RenderIconBtnWrapper}>
               { Object.keys(this.state.patientAppointment).length === 0? 
                <FlatList
                  data={this.state.filterArray}
                  numColumns={4}
                  showsVerticalScrollIndicator={false}
                  scrollEnabled={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{
                    alignItems: "center",
                    alignContent: "center"
                  }}
                  renderItem={({ item, index })=>
                  this.state.laboratory ? this.renderIconBtn(item.value, item.key, item, index) :item.key === "payment_type" || item.key === "payment_type1" ? this.renderIconBtn(item.FlagValue, item.key, item, index) : this.renderIconBtn(item.value, item.key, item, index)
                  }
                />:null}
              </View>
             <View>
               {Object.keys(this.state.patientAppointment).length > 0 ? 
               <View style={[styles.headingBackGround, { flexDirection: 'row'}]}>
               <View style={{ flex: 0.33}}>
                 <Text style={styles.TableHeadText}>{"Date"}</Text>
               </View>
               <View style={{ flex: 0.33}}>
                 <Text style={styles.TableHeadText}>{"Bill Number"}</Text>
               </View>
               <View style={{ flex: 0.33,marginLeft:15}}>
                 <Text style={styles.TableHeadText}>{"Paid Amount"}</Text>
               </View>
             </View>:
              <View style={[styles.headingBackGround, { flexDirection: 'row', justifyContent: "flex-start" }]} >
                <View style={{ flex: 0.25, justifyContent: "flex-start" }}>
                  <Text style={[styles.TableHeadText, { marginRight: '2.6vw' }]}>{"Date & Bill Number"}</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.TableHeadText,{ marginRight: '1.3vw' }]}>{"Bill Type"}</Text>
                </View>
                <View style={{ flex: 0.4, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.TableHeadText,{ marginRight: '1.3vw' }]}>{"Name"}</Text>
                </View>

                <View style={{ flex: 0.3, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.TableHeadText,{ marginRight: '1.3vw' }]}>{"Doctor Name"}</Text>
                </View>

                <View style={{ flex: 0.2, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={[styles.TableHeadText,{ marginLeft: '2.27vw' }]}>{"Paid Amount"}</Text>
                </View>
              </View>
             }  
            </View>
              {/* <FlatList
              style={{ marginTop: 20 }}
              data={dummyBillData}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => this.renderBill(item)}
            /> */}

              {this.showBillingList()}
              {/* total amount view */}

            </View>
            <View style={styles.PaginationView}>

              {
                this.state.getBillingList && this.state.getBillingList.length > 0 ?
                  <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.getBillingList} />
                  // : this.state.getBillingList && this.state.getBillingList.result && this.state.getBillingList.result.length > 0 ?
                  //   <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.getBillingList.result} /> : null
                  :null
              }
            </View>
          </ScrollView>
        </View>

        <ScrollView showsVerticalScrollIndicator={false}
          style={styles.RightScrollView} >
          <View style={styles.RightView}>

            <View style={styles.StatHeadView}>
              <Text style={styles.StatHeadText}
              >{"Statistics"}</Text>
              <View style={styles.StatCard}>
              {this.renderTotalItem(
                  ImagePath.Total,
                  "Total",
                  this.state.totalAmountCashAndCard ? parseFloat(this.state.totalAmountCashAndCard).toFixed(2) : "0.0"
                 )}
              </View>
            </View>
            <View
              style={styles.CardWrapper}
            >
              {this.renderTotalItem(
                ImagePath.Card,
                "Card",
                this.state.totalAmountInCard?parseFloat(this.state.totalAmountInCard).toFixed(2):"0.0"
              )}
              {this.renderTotalItem(
                ImagePath.Cash,
                "Cash",
                this.state.totalAmountInCash?parseFloat(this.state.totalAmountInCash).toFixed(2):"0.0"
              )}
              {/* <View>
                <Text style={{ fontSize: 16, color: color.themePink }}>
                  Total
                </Text>
                <Text style={{ fontSize: 20, paddingTop: 2 }}>{this.state.totalAmountCashAndCard}</Text>
              </View> */}
               {this.renderTotalItem(
                 ImagePath.UPI,
                "UPI",
                this.state.totalAmountInUpi?parseFloat(this.state.totalAmountInUpi).toFixed(2):"0.0"
              )}
                {this.renderTotalItem(
                  ImagePath.BankTransfer,
                  "Bank Transfer",
                  this.state.totalAmountInBankTransfer ? parseFloat(this.state.totalAmountInBankTransfer).toFixed(2) : "0.0"
                )}
                { Object.keys(this.state.patientAppointment).length === 0 && this.state.userType == Constants.ROLE_FO ? this.renderTotalItem(
                  require("../../../../../assets/images/IPActions/Insurance.png"),
                  "Insurance",
                  this.state.totalAmountInsurance ? parseFloat(this.state.totalAmountInsurance).toFixed(2) : "0.0"
                ):null} 
                {/* {this.renderTotalItem(
                  ImagePath.Cheque,
                  "Cheque",
                  this.state.totalAmountInCheque ? parseFloat(this.state.totalAmountInCheque).toFixed(2) : "0.0"
                )} */}
               
             </View>
            <View style={{ width: "100%" }}>

              {/* <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text style={{ fontSize: 13, color: "#ffffff" }}>
                {this.state.currentTime}
              </Text>
            </View> */}
              {/* <TimerLog /> */}

              {/* <Text
                style={styles.FilterText}
              >
                Filter
              </Text> */}
             
             <View style={[styles.FilterHeadView,{marginTop:'1vw'}]}><Text style={styles.filterText}>Filter</Text></View>
              {/* <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                <View style={{ minWidth: '3.25' }}> */}
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center"
                    }}>

                    {/* <DatePicker
                      disabledDate={current => {
                        return current && current >= moment().add(0, "days");
                      }}

                      value={this.state.filterFromDate !== "" ? moment(this.state.filterFromDate) : null}
                      // value={this.state.filterFromDate.toString ? this.state.filterFromDate :""}
                      // clearIcon={false}
                      onChange={date => {
                        // alert(date)
                        var converted_date = null;
                        var { filterData } = this.state;
                        if (date) {
                          var fulldate = new Date(date);
                          converted_date = moment(fulldate).format("YYYY-MM-DD");
                        }
                        filterData["fromDate"] = converted_date;
                        this.setState({ filterData, filterFromDate: converted_date })
                        // this.setState({filterFromDate :converted_date},()=>{this.updateState("fromDate", this.state.filterFromDate)})
                      }}
                      defaultValue={this.state.filterFromDate ? this.state.filterFromDate : null}
                      format={"DD-MM-YYYY"}
                      placeholder={"From"}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: color.lightGray,
                        borderWidth: 1,
                        marginTop: 5,
                        height: 35,
                        borderWidth: 1,
                        borderRadius: 10,
                        alignItems: "center",
                        flexDirection: "row",
                        paddingLeft: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        // width: "50%",
                        marginRight: 10
                      }}
                    /> */}

                    {this.dateFilter()}

                  
                  </View>
                  {this.popUpCalender()}
                {/* </View>
              </View> */}
              { Object.keys(this.state.patientAppointment).length === 0 ? 

              <View style={styles.PatientNameBoxView}>
                      {this.renderTextField("Pt Name/Acc.no/Mob no","patientName",this.state.filterData.patientName,true)}
                 
                  {Constants.ROLE_FO == this.state.userType ?
                  <Picker
                  style={{
                    borderColor: color.lightGray,
                    borderWidth: 1,
                    marginTop: '0.32vw',
                    height: '2.28vw',
                    borderWidth: 1,
                    borderRadius: '0.65vw',
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: '0.65vw',
                    paddingLeft: '0.65vw',
                    paddingRight: '0.65vw',
                    width: "50%",
                    marginRight: 0 ,
                    zIndex: -1,
                    fontSize:'0.91vw'
                  }}
          selectedValue={this.state.filterData.doctor_id}
          onValueChange={(itemValue) => {
            let { filterData } = this.state
            filterData['doctor_id'] = itemValue;
            this.setState({
              filterData: JSON.parse(JSON.stringify(filterData))
              })
        }}
        >
          <Picker.Item label={"All Doctors"}  value={""} />
          {this.state.doctorData.map((doc) => {
                    return(
          <Picker.Item label={doc.first_name + " " + doc.initial}  value={doc.doctor_id} />
          )})}
          {/* <Picker.Item label="JavaScript" value="js" />
          <Picker.Item label="Python" value="python" />
          <Picker.Item label="Haxe" value="haxe" /> */}
        </Picker>
        :null
        }
     </View>:null
      } 
      { Object.keys(this.state.patientAppointment).length === 0 ?
      <View style={styles.AccNoCkeckView}>
        <CheckBox 
        style={{ width:'0.84vw', height:'0.84vw' }}
        value={this.state.isAcNumClicked}
        onValueChange={() => {
          var states = this.state.filterData
          states["patientName"] = ""
          this.setState({
          isAcNumClicked: !this.state.isAcNumClicked,
          states
          })
        }}
        />
        <Text style={styles.AccNoCkeckText}>{"Acc.no search"}</Text>
      </View>:null}

              <View
                style={styles.BillNoAmtBoxView}
              >
                {this.renderTextField(
                  "Bill No",
                  "billNo",
                  this.state.filterData.billNo,
                  true
                )}
                {this.renderTextField(
                  "Paid Amount",
                  "amount",
                  this.state.filterData.amount,
                  true
                )}
              </View>
            </View>
                {Object.keys(this.state.patientAppointment).length === 0 ?
                 <View style={{ zIndex: -1 }}>
                  {/* <View style={{ width: "95%", zIndex: -1 }}>
                    <Text style={{ color: "black", textAlign: "left" }}>
                      Bill Type
                    </Text>
                  </View> */}
                  <View style={styles.FilterHeadView}><Text style={styles.filterText}>Bill Type</Text></View>
                 {Constants.ROLE_FO == this.state.userType ?
                  <View style={styles.BillTypeContentView}>
                    {this.renderCheckBox("Out Patients", this.state.outpatients, "outpatients")}
                    {this.renderCheckBox("In Patients", this.state.inpatients, "inpatients")}
                    {this.renderCheckBox("Pharmacy", this.state.pharmacy, "pharmacy")}
                    {this.renderCheckBox("Laboratory", this.state.laboratory, "laboratory")}
                    {this.renderCheckBox("OT", this.state.OT, "OT")}
                    {this.state.isOPTclinic ?  this.renderCheckBox("ImageLab", this.state.ImageLab, "ImageLab") : null}
                  </View>
                  : <View style={styles.BillTypeContentView}>
                    {this.renderCheckBox("Out Patients", this.state.outpatients, "outpatients")}
                  </View>}


                {/* <View style={{ width: "95%", zIndex: -1 }}>
                  <Text style={{ color: "black", textAlign: "left" }}>
                    Payment Method
                  </Text>
                </View> */}
                <View style={styles.FilterHeadView}><Text style={styles.filterText}>Payment Method</Text></View>
                <View
                  style={styles.PaymentTypeContentView}
                >
                  {this.renderPaymentBtn(
                    ImagePath.Card,
                    "Card"
                  )}
                  {this.renderPaymentBtn(
                    ImagePath.Cash,
                    "Cash"
                  )}  
                  {this.state.laboratory ? this.renderPaymentBtn(
                    ImagePath.CashandCard ,
                    // "Card & Cash"
                    "Cash and Card"
                  ) : null}
                  {this.renderPaymentBtn(
                    ImagePath.UPI,
                    // "Card & Cash"
                    "UPI"
                  )}
                  {this.renderPaymentBtn(
                    ImagePath.BankTransfer,
                    // "Card & Cash"
                    "Bank Transfer"
                  )}
                 { this.state.userType === "FO"? this.renderPaymentBtn(
                    ImagePath.Insurance,
                    "Insurance"
                   ):null }
                   {/* {this.renderPaymentBtn(
                    ImagePath.Cheque,
                    "Cheque"
                  )} */}
                </View>
                 {this.state.laboratory ? null :
                  <View style={[styles.PaymentTypeContentView,{width:"100%"}]}>
                    <Radio.Group disabled={this.state.isDisable ? true : false}
                      onChange={(e) => {
                        this.setState({ filterType: e.target.value });
                      }}
                      value={this.state.filterType}
                    >
                      <Radio value={true}>Selected Payment only</Radio>
                      <Radio value={false}>Combine Selected Payment</Radio>
                    </Radio.Group>
                  </View>}
              </View> : null}
             
            
            <View style={styles.ButtonMainView}>
              <TouchableOpacity
                // onPress={() => this.props.navigation.navigate("optDoctorNotes")}
                onPress={() => {
                  // this.getBillingList();
                  this.createFilterTag()
                }}
              >
                {this.renderRightBtn("Search", false)}
              </TouchableOpacity>
                {Object.keys(this.state.patientAppointment).length === 0 ? 
                  <TouchableOpacity
                    // onPress={() => this.props.navigation.navigate("optDoctorNotes")}
                    onPress={() => {
                      this.printBillingList();
                    }}
                  >
                    {this.renderRightBtn("Print", false)}
                  </TouchableOpacity> : null
                }
             

              <TouchableOpacity
                // onPress={() => this.changeAppointmentStatus("Cancelled")}
                onPress={() => {
                  this.clearFilter("");
                }}
              >
                {this.renderRightBtn("Clear", true)}
              </TouchableOpacity>
              {/*
            <DatePicker
              style={{ width: 200 }}
              date={this.state.date}
              mode="date"
              placeholder="select date"
              format="YYYY-MM-DD"
              minDate="2016-05-01"
              maxDate="2016-06-01"
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              customStyles={{
                dateIcon: {
                  position: 'absolute',
                  left: 0,
                  top: 4,
                  marginLeft: 0
                },
                dateInput: {
                  marginLeft: 36
                }
                // ... You can check the source to find the other keys.
              }}
              onDateChange={(date) => { this.setState({ date: date }) }}
            />
*/}
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
      // </TouchableWithoutFeedback>

    );
  }

  printBillingList = () => {
    /*
    var paymentType = ""
    if (this.state.paymentType == "cash") {
      paymentType = "Cash"
    } else if (this.state.paymentType == "card") {
      paymentType = "Card"
    } else if (this.state.paymentType == "card & cash") {
      paymentType = "Cash and Card"
    }

    var service_url = Constants.PRINT_BILLING + "?start_date=" + this.state.filterData.fromDate
      + "&end_date=" + this.state.filterData.toDate + "&invoice_number=" + this.state.filterData.billNo +
      "&amount=" + this.state.filterData.amount +
      "&payment_type=" + paymentType + "&patient_name=" + this.state.filterData.patientName +
      "&clinic_id=" + this.state.selectedClinicOrDoctor.id
    */

    let { appendUrl } = this.state;

    if(appendUrl) 
    {
      appendUrl = appendUrl + "&export_type=pdf" 
      
      OpthamologyService.getInstance().documentUploadGet(
        appendUrl,
        // service_url,
        // this,
        this.successprintBillingList,
        this.failureprintBillingList,
        "pdf"
      );
    }
    
  }

  successprintBillingList = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
    //  console.log(file)
      window.open(fileURL);
    }
  }

  failureprintBillingList = (error) => {

    console.log(error);
  }
  getLabBillingTransaction() {
    if (this.state.userType === 'LAB') {
      { this.getLaboratoryBillingTransaction() }
    }
  }
  getLaboratoryBillingTransaction() {
    let states = this.state;
    var payment_type_value = "";
    for( var i = 0 ; i < states.filterData.payment_type_list.length ; i++){
      var paymentValue = "&payment_type"+ (i === 0)?"": i +"="+states.filterData.payment_type_list[i]
      payment_type_value += paymentValue;
    }  
        
    var service_url = Constants.LAB_BILLING_TRANSACTION + "?from_date=" + this.state.filterData.fromDate + "&to_date" + this.state.filterData.toDate + "&patient_name" + this.state.filterData.patientName + "&bill_number" + this.state.filterData.billNo + "&amount" + this.state.filterData.amount + payment_type_value;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getLaboratoryBillingTransactionSuccess,
      this.getLaboratoryBillingTransactionFailure
    );

  }
  getLaboratoryBillingTransactionSuccess=response=> {

    if (response.status === "success") {
     // alert(JSON.stringify(response.data))
      this.setState({
        getBillingList: response.data,
        isBillingListSuccess: true
      }, () => {
        // alert(JSON.stringify(this.state.getBillingList))
      })
    }
  }
  getLaboratoryBillingTransactionFailure = error => {
    //console.log("Laboratory Billing Transaction GET error response");
    console.log(error); Continue
  };
  getLaboratoryBillingTransactionSearch() {


  }
  getBillingList = async () => {

    // var selectedValue = await AsyncStorage.getItem("Selected_Clinic_Doctor");
    // var selectedClinicId = JSON.parse(selectedValue)
    // if(selectedClinicId!==0){ 
    //   alert(selectedClinicId)
    // }
    this.setState({
      isClear: false,
      isClearInt: 0
    })
    let { appendUrl } = this.state;
    var getFilterData = []

    // appendUrl = Constants.DOCTOR_BILLING + "?clinic_id=" + this.state.selectedClinicOrDoctor.id";
    appendUrl = Constants.DOCTOR_BILLING_NEW;
    
    if (this.state.fields) {
      getFilterData = this.state.fields["filterArray"]
    }

    // var serviceUrl= Constants.DOCTOR_BILLING + "?clinic_id=" + 10;
    var pateintId = "";
    if( this.state.patientAppointment.patient_id ){
      pateintId = "&patient_id="+this.state.patientAppointment.patient_id ;
    }

    var clinicId = ""
    if(this.state.userType != Constants.ROLE_FO){
      clinicId = "&clinic_id=" + this.state.selectedClinicOrDoctor.id
    }

    if (getFilterData.length > 0) {
      for (var i = 0; i < getFilterData.length; i++) {
        if( i==0 ) {
          appendUrl = appendUrl + "?";
        } else {
          appendUrl = appendUrl + "&";
        }
        appendUrl = appendUrl + getFilterData[i].key + "=" + getFilterData[i].value ;
      }
      appendUrl = appendUrl+ clinicId + pateintId  + "&status=saved" + "&selected_paymenttype_only=" + this.state.filterType
    // } else {
    //   this.state.appendUrl = Constants.DOCTOR_BILLING + "?clinic_id=" + this.state.selectedClinicOrDoctor.id;
    }
    
    this.setState({
      appendUrl: appendUrl //Constants.DOCTOR_BILLING + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
    }, ()=>{

      Service.getInstance().billingList(
        // serviceUrl,
        appendUrl,
        this,
        this.successBillingList,
        this.failureBillingList
      );
    })
  }

  successBillingList = response => {

    if(response && response.status == "success") 
    {
      if(response.data && response.data.data) {

        let list = [];
        let opBill = response.data.data.op?response.data.data.op:[]
        let ipBill = response.data.data.ip?response.data.data.ip:[]
        let pharmacyBill = response.data.data.pharmacy?response.data.data.pharmacy:[]
        let labBill = response.data.data.lab?response.data.data.lab:[]
        let otBill = response.data.data.ot?response.data.data.ot:[]
        let imageLabBill = response.data.data.lab?response.data.data.img_lab:[]

        for( let i=0; i<opBill.length; i++ ) {

          // update type
          // opBill['type'] = 'op'

          let temp = opBill[i]
          // temp['invoice_date'] = temp.invoice_date?(temp.invoice_date).split("-").reverse().join("-"):null
          list.push(temp)
          
        }

        for( let i=0; i<ipBill.length; i++ ) {

          let temp = ipBill[i]
          // update column name
          temp['amount_invoice'] = ipBill[i]['net_amount']

          list.push(temp)
          
        }

        for( let i=0; i<imageLabBill.length; i++ ) {

          let temp = imageLabBill[i]
          // update column name
          temp['amount_invoice'] = imageLabBill[i]['net_amount']

          list.push(temp)
          
        }

        for( let i=0; i<pharmacyBill.length; i++ ) {

          let temp = pharmacyBill[i]
          // update column name
          temp['amount_invoice'] = pharmacyBill[i]['grand_total']

          list.push(temp)
        }  
        
        for( let i=0; i<labBill.length; i++ ) {

          let temp = labBill[i]
          // update column name
          temp['amount_invoice'] = labBill[i]['net_amount']
          
          list.push(temp)
        }
        for( let i=0; i<otBill.length; i++ ) {

          let temp = otBill[i]
          // update column name
          // temp['amount_invoice'] = otBill[i]['net_amount']
          
          list.push(temp)
        }
       
        this.setState({
          getBillingList: list, 
          totalAmountInCash: response.data.cash_amount?response.data.cash_amount:0,
          totalAmountInCard: response.data.card_total?response.data.card_total:0,
          totalAmountCashAndCard: response.data.total_amount?response.data.total_amount:0,
          totalAmountInUpi:response.data.upi_amount ? response.data.upi_amount : 0,
          totalAmountInBankTransfer:response.data.bank_transfer_amount ? response.data.bank_transfer_amount:0,
          totalAmountInsurance:response.data.insurance_amount ? response.data.insurance_amount : 0,
          // totalAmountInCheque:response.data.cheque_amount ? response.data.cheque_amount : 0,
          isBillingListSuccess: true
        }, () => {
          // this.cashCalculation();
          // console.log(" getBillingList length ---" + JSON.stringify(this.state.getBillingList.length))
        })
      }
      else{

      }
    }
  }
  failureBillingList = response => {
    // console.log("responce ======================> error " + JSON.stringify(response));
  }

  showBillingList() {
   
    if (this.state.getBillingList && this.state.getBillingList.length > 0 && this.state.getBillingList.length <11) {
      return this.state.getBillingList && this.state.getBillingList.map((bill, index) => {

        return this.showBills(bill, index);
      })
    } 
   
    else if (this.state.getBillingList && this.state.getBillingList.length > 0) {
      return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {

        return this.showBills(bill, index);
      })
    // } else if (this.state.getBillingList && this.state.getBillingList.result && this.state.getBillingList.result.length > 0) {
    //   return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {
   
    //     return this.showLaboratoryBills(bill, index);
    //   })
    } else if(this.state.getBillingList.length > 0 && this.state.isBillingListSuccess) {
      return <View style={styles.NoRecordsView}>
        <Text style={styles.NoRecordsText}>{"No records to be shown"}</Text>
      </View>
    }
  }

  showBills(bill, index) {
    
    // let amount = bill.bill_type=="Out Patient"?bill.amount_net:bill.bill_type=="In Patient"?bill.net_amount:""
    var payment_type =  paymentReturnType(bill.payment_type)
    let bill_status = bill.status?bill.status:bill.billing_status?bill.billing_status: bill.bill_status ?  bill.bill_status : ""
    return (
      <TouchableOpacity onPress={() => {
        // alert(JSON.stringify(bill))
          // this.showBillingListPopUp(bill.id)
        (bill.bill_type=="Out Patient")?this.getPatientINFO(bill.patient_id, bill.id) :
        (bill.bill_type=="Lab" ) ? this.getPatientBillIndudvalBillingTransection(bill, bill_status):
        (bill.bill_type=="In Patient") ? this.getIPPatientBillIndudvalBillingTransection(bill, bill_status):
        (bill.bill_type=="Pharmacy")? this.getPharmacyPatientBillIndudvalBillingTransection(bill):
        (bill.bill_type=="Img_Lab")? this.getImageLabBillTxn(bill) :
        
        null
      }}>
        
        <View style={styles.TableDataWrapper}>
          { Object.keys(this.state.patientAppointment).length > 0 ?

            <View style={styles.PatientTableDataWrapper}>
              <View style={{ flex: 0.33 }}>
                <Text style={styles.TableDataText}> {bill.invoice_date}</Text>
              </View>
              <View style={{ flex: 0.33 }}>
                <Text style={styles.TableDataText}>{"#" + bill.invoice_number}</Text>
              </View>
              {bill_status && bill_status != 'canceled' ?
                <View style={{ flex: 0.33, justifyContent:"center" }}>
                  <Text style={styles.PatientTableBillNoText}>{bill.amount_invoice}</Text>
                  <Text style={styles.PatientTablePayTypeText}>{this.state.islab == true ? bill.payment_type : payment_type.paymentType2 ? payment_type.paymentType1 + " and " + payment_type.paymentType2 : payment_type.paymentType1}</Text>
                </View>
                : (bill_status && bill_status.toLowerCase() == 'canceled') ?
                  <View style={{ flex: 0.33, justifyContent:"center" }}>
                    <Text style={styles.PatientTableAmount}>{bill.amount_invoice}</Text>
                    <Text style={styles.PatientDataRedText}>{"Cancelled"}</Text>
                    <Text style={styles.PatientDataRedText}>{bill.cancel_reason ? bill.cancel_reason : bill.cancellation_reason}</Text>
                  </View>
                  : <View style={{ flex: 0.33, justifyContent:"center" }}>
                    <Text style={styles.PatientDataRedText}>{"Unpaid"}</Text>
                  </View>}
            </View> :

            <View style={styles.OverAllTblDataWrapper} key={index}>
              <View style={styles.OverAllTblDataBillNoCol}>
                <Text style={styles.OverAllBillDteTxt}>
                  {/* {bill.invoice_date ? moment(bill.invoice_date).format("DD-MM-YYYY") : null} */}
                  {bill.invoice_date}
                </Text>
                <Text style={styles.OverAllBillAmtTxt}>{"#" + bill.invoice_number}</Text>
              </View>
              <View style={styles.OverAllBillTypeView}>
                <Text style={styles.OverAllBillTypeText}>{bill.bill_type}</Text>
              </View>
              <View style={styles.OverAllBillNameView}>
                <Text style={styles.OverAllBillNameTxt}>{bill.patient_name}</Text>
              </View>
              <View style={styles.OverAllBillDocNameView}>
                <Text style={styles.OverAllBillDocNameTxt}>{bill.doctor_name ? bill.doctor_name : bill.nurse_id ? "Nursing" :  ""}</Text>
              </View>
              {bill_status && bill_status != 'canceled' ?
                <View style={styles.OverAllCancelView}>
                  <Text style={styles.OverAllCancelamtText}>{bill.amount_invoice}</Text>
                  <Text style={styles.OverAllCancelPayTypeTxt}>{this.state.islab == true ? bill.payment_type : payment_type.paymentType2 ? payment_type.paymentType1 + " and " + payment_type.paymentType2 : payment_type.paymentType1}</Text>
                </View>
                : (bill_status && bill_status.toLowerCase() == 'canceled') ?
                  <View style={styles.OverAllCancelView}>
                    <Text style={styles.PatientTableAmount}>{bill.amount_invoice}</Text>
                    <Text style={styles.PatientDataRedText}>{"Cancelled"}</Text>
                    <Text style={styles.PatientDataRedText}>{bill.cancel_reason ? bill.cancel_reason : bill.cancellation_reason}</Text>
                  </View>
                  : <View style={styles.OverAllCancelView}>
                    <Text style={styles.PatientDataRedText}>{"Unpaid"}</Text>
                  </View>}
           </View>}
                </View>

      
         
      </TouchableOpacity>
    );
  }
  showLaboratoryBills(bill, index) {
    // console.log("******************************> "+JSON.stringify(bill))
    var payment_type = paymentReturnType(bill.payment_type)
    return (
      <TouchableOpacity onPress={() => {
        //  this.showBillingListPopUp(bill.id)
        //this.getPatientINFO(bill.patient_id, bill.id)
      }}>
        <View style={{ flexDirection: 'row', borderBottomWidth: 1, marginTop: 10, borderBottomColor: '#f2efee', width: '93%', paddingBottom: 20 }} key={index}>
          <View style={{ flex: 0.3, justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 30 }}>
            <Text style={{ marginBottom: 5, fontSize: 15 }}>{bill.invoice_date ? moment(bill.invoice_date).format("DD-MM-YYYY") : null}</Text>
            <Text style={{ fontSize: 15 }}>{"#" + bill.invoice_number}</Text>
          </View>

          <View style={{ flex: 0.4, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 15, color: '#000000', marginLeft: 15 }}>{bill.patient_first_name}</Text></View>

          <View style={{ flex: 0.3, justifyContent: 'center', alignItems: 'flex-end' }}>
            <Text style={{ marginBottom: 5, fontSize: 18 }}>{bill.amount_invoice}</Text>
            <Text style={{ fontSize: 15 }}>{"Paid | "}{this.state.islab == true ? bill.payment_type : payment_type.paymentType2 ? payment_type.paymentType1 + " and " + payment_type.paymentType2 : payment_type.paymentType1}</Text>
          </View>

        </View>
      </TouchableOpacity>
    );
  }

  showCalender(date) {
    this.setState({ showCalenderModal: !this.state.showCalenderModal, showCalenderModalFrom: date });
  }

  popUpCalender() {
    if (this.state.showCalenderModal) {

      return (
        <View style={{ width: '80%', zIndex: 1, position: 'absolute', marginTop: 60, marginLeft: 40 }}>
          <Calendar
            style={{
              borderWidth: 1,
              borderColor: 'gray',
            }}
            theme={{
              backgroundColor: '#ffffff',
              calendarBackground: 'grey',
              // textSectionTitleColor: 'red',
              selectedDayBackgroundColor: 'yellow',
              selectedDayTextColor: 'yellow',
              todayTextColor: '#099ef9',

              dayTextColor: 'black',
              // textDisabledColor: '#d9e1e8',
              // dotColor: '#00adf5',
              // selectedDotColor: '#ffffff',
              // arrowColor: 'red',
              monthTextColor: 'black',
              textDayFontFamily: 'monospace',
              textMonthFontFamily: 'monospace',
              textDayHeaderFontFamily: 'monospace',
              textMonthFontWeight: 'bold',
              textDayFontSize: 13,
              textMonthFontSize: 13,
              textDayHeaderFontSize: 13
            }}
            // current={'2020-05-23'}
            minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
            maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate}
            onDayPress={(day) => {
              var getFilterDate = day.dateString
              this.setState({ showCalenderModal: false });
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: getFilterDate }, () => { this.updateState("fromDate", this.state.filterFromDate) })
              } else {
                this.setState({ filterToDate: getFilterDate }, () => { this.updateState("toDate", this.state.filterToDate) })
              }
            }}
            onDayLongPress={(day) => { console.log('selected day is', day) }}
            // monthFormat={'yyyy MM'}
            onMonthChange={(month) => { }}
            hideArrows={false}
            renderArrow={(direction) => {
              if (direction === "left") {
                return (
                  <Icon
                    size={30}
                    name={"angle-left"}
                  />
                )
              } else if (direction === "right") {
                return (
                  <Icon
                    size={30}
                    name={"angle-right"}
                  />
                )
              }
            }}
            hideExtraDays={false}
            disableMonthChange={false}
            // firstDay={1}
            // hideDayNames={true}
            // showWeekNumbers={true}
            onPressArrowLeft={substractMonth => substractMonth()}
            onPressArrowRight={addMonth => addMonth()}

          />
          <View style={{ justifyContent: 'space-evenly', backgroundColor: 'grey', borderTopWidth: 1, borderTopColor: '#b6b6b6', padding: 10, flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => {
              this.showTodayDateInFilter()
            }}>
              <Text>{"Today"}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: "", showCalenderModal: false })
              } else {
                this.setState({ filterToDate: "", showCalenderModal: false })
              }
            }}>
              <Text>{"Clear"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View />
      )
    }
  }

  showTodayDateInFilter() {
    if (this.state.showCalenderModalFrom === "From") {
      var toYear = parseInt(this.state.filterToDate.substring(0, 4));
      var toMonth = parseInt(this.state.filterToDate.substring(5, 7));
      var toDate = parseInt(this.state.filterToDate.substring(8, 10));

      var fromYear = parseInt(moment().format("YYYY"));
      var fromMonth = parseInt(moment().format("MM"));
      var fromDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      } else {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterToDate: "" },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      }
    } else {
      var fromYear = parseInt(this.state.filterFromDate.substring(0, 4));
      var fromMonth = parseInt(this.state.filterFromDate.substring(5, 7));
      var fromDate = parseInt(this.state.filterFromDate.substring(8, 10));

      var toYear = parseInt(moment().format("YYYY"));
      var toMonth = parseInt(moment().format("MM"));
      var toDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      } else {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterFromDate: "" },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      }
    }
  }

  getPatientINFO(patientId, invoiceNo) {
    this.setState({
      invoiceNo: invoiceNo,
      patientId: patientId
    })

    var service_url = ""
    if (this.state.userType == Constants.ROLE_FO)
      service_url = Constants.FO_DOCTOR_PATIENT_INFO + patientId;
    else
      service_url = Constants.DOCTOR_PATIENT_INFO + patientId;

    Service.getInstance().billingList(
      service_url,
      this,
      this.getPatientINFOSuccess,
      this.getPatientINFOError
    );
  }

  getPatientINFOSuccess = success => {
    if (success.status == "success") {
      this.setState({
        patientDetails: success.data
      }, () => {
        this.showBillingListPopUp(this.state.invoiceNo)
      })
    }
  }
  getPatientINFOError = error => { }

  showBillingListPopUp(invouceNumber) {
    var serviceUrl = Constants.BILLING_INVOICE_GET + "?invoice_id=" + invouceNumber;

    Service.getInstance().billingList(
      serviceUrl,
      this,
      this.showBillingListSuccess,
      this.showBillingListError,
      invouceNumber
    );
  }

  showBillingListSuccess = (success,invouceNumber) => {
    if (success.status == "success") {
      this.setState({
        getAllBillingList: success.data
      }, () => {
        this.props.showAlllBillingScreenModulePopup(true,success.data,this.state.patientDetails,this.state.invoiceNo,this.state.patientId)
      })
    }

   // console.log("** success ** " + JSON.stringify(success))
  }
  showBillingListError = error => { }

  cashCalculation() {

    var states = this.state;
    /*
    var totalAmountInCash = this.state.getBillingList && this.state.getBillingList.data ? this.state.getBillingList.data.reduce((sum, cash) => sum + parseFloat(cash.amount_cash), 0) : 0
    var totalAmountInCard = this.state.getBillingList && this.state.getBillingList.data ? this.state.getBillingList.data.reduce((sum, card) => sum + parseFloat(card.amount_card), 0) : 0
    var totalAmountCashAndCard = totalAmountInCash + totalAmountInCard;
    states["totalAmountInCash"] = totalAmountInCash
    states["totalAmountInCard"] = totalAmountInCard
    states["totalAmountCashAndCard"] = totalAmountCashAndCard
  
    this.setState({ states })
    */
  
  }

  getIPPatientBillIndudvalBillingTransection=(bill, status)=>{
    this.setState({
      BillStatus:status
    })
    var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + bill.id
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getIPPatientBillIndudvalBillingTransectionSuccess,
      this.getIPPatientBillIndudvalBillingTransectionFailure,
      bill.id,
    );
  }


  getIPPatientBillIndudvalBillingTransectionSuccess = (response,billId) => {
    if (response.status === "success") {


      this.props.showBillingScreenPopup(true, response.data, billId, false,this.state.BillStatus)

    }
  };

  getIPPatientBillIndudvalBillingTransectionFailure = error => { }


  getPatientBillIndudvalBillingTransection = (bill, status) => {
    this.setState({
      BillStatus:status
    })
    // alert(JSON.stringify(bill.invoice_number))
    var serviceUrl = Constants.LAB_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?bill_summary_id=" +bill.id //bill.invoice_number
    OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.getPatientBillIndudvalBillingTransectionSuccess,
        this.getPatientBillIndudvalBillingTransectionFailure,
        bill.id
    );
};
getPatientBillIndudvalBillingTransectionSuccess = (response,summaryId) => {
  if (response.status === "success") {
      this.props.showLabBillingTransactionPopup(true, response.data, summaryId, false, this.state.BillStatus)
  }
};

getPatientBillIndudvalBillingTransectionFailure = error => { }




getPharmacyPatientBillIndudvalBillingTransection = (bill) => {
  var serviceUrl = Constants.PHARMACY_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?id=" +bill.id //bill.invoice_number
  OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPharmacyPatientBillIndudvalBillingTransectionSuccess,
      this.getPharmacyPatientBillIndudvalBillingTransectionFailure,
      bill.id
  );
};
getPharmacyPatientBillIndudvalBillingTransectionSuccess = (response,summaryId) => {
if (response.status === "success") {
    this.props.showPharmacyBillingTransactionPopup(true, response.data, summaryId)
}
};

getPharmacyPatientBillIndudvalBillingTransectionFailure = error => { }

getImageLabBillTxn = (bill) => {
  console.log("insise")
  var serviceUrl = Constants.IMAGE_LAB_INDIVIDUAL_BILL + "?bill_id=" + bill.id //bill.invoice_number
  OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getImageLabBillTxnSuccess,
      this.getImageLabBillTxnFailure,
      bill.id
  );
}

getImageLabBillTxnSuccess = (response,summaryId) => {
  if (response.status === "success") {
    this.props.showImageLabBillingTxnPopup(true, response.data, summaryId )
}
}

}

const styles = StyleSheet.create({
  calendarView: {
    // borderColor: "#525252",
    borderColor: color.lightGray,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  calendarText: {
    color: color.lightGray,
    width: "90%"
  },
  RightView:{
    padding: "1.3vw",
    height: screenHeight-150,
    backgroundColor: color.themeShade,
    width: "100%"
  },
  headingBackGround: {
    backgroundColor: color.appointmentTableHead,
    paddingHorizontal: '1.62vw',
    paddingVertical: '0.65vw'
  },
  dasboardcardcontentstyle: {
    flexDirection: "row",
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: "white",
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: '0.26vw',
    height: '3.9vw',
    minWidth: '7.8vw',
    paddingVertical: '0.488vw',
    paddingHorizontal: '0.65vw',
    marginTop:'0.65vw',
    marginLeft:'0.65vw'
  },
  RenderTotalItemView:{
    flexDirection: "column", 
    alignItems: "center", 
    marginLeft: '0.32vw'
  },
  RenderTotalItemImg:{
    height: '1.627vw', 
    width: '1.627vw',
  },
  MainView:{
    flex: 1, 
    flexDirection: "row", 
    backgroundColor: color.applicationBackgroundColor
  },
  LeftMainView:{
    width: "65%", 
    height: screenHeight - 120, 
    backgroundColor: color.white
  },
  LeftScrollView:{
    width: "100%", 
    height: screenHeight - 120, 
    backgroundColor: color.white, 
    zIndex: -1 
  },
  RenderIconBtnView:{
    marginTop: platform === "web" ? 0 : '1.3vw',
    width: "100%",
    marginLeft: '1.95vw'
  },
  RenderIconBtnWrapper:{
    marginTop: '1.3vw', 
    marginBottom: '1.3vw'
  },
  RenderIconBtnLabel:{
    position: 'absolute', 
    zIndex: 100, 
    marginLeft: '0.65vw', 
    backgroundColor: color.white, 
    fontSize: '0.8vw', 
    paddingLeft: '0.32vw', 
    paddingRight: '0.32vw', 
    color: color.themePink
  },
  RenderIconBtnContentView:{
    marginTop:'0.32vw',
    height: '2.28vw',
    minWidth: '7.81vw',
    // padding: 10,
    paddingHorizontal:'0.8vw',
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '0.26vw',
    flexDirection: "row",
    backgroundColor: color.white,
    borderWidth: 1,
    borderColor: color.themePink,
    marginRight: '0.32vw',
    marginBottom: '0.32vw'
  },
  RenderIconBtnText:{
    fontSize: '0.65vw',
    color: color.themePink,
    fontWeight: "700",
    paddingLeft: '0.32vw'
  },
  TableHeadText:{
    fontSize:'0.97vw'
  },
  NoRecordsView:{
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    minHeight: '6.51vw'
  },
  NoRecordsText:{
    color: color.placeholder, 
    marginTop: '3.25vw',
    fontSize:'0.91vw'
  },
  TableDataText:{
    fontSize:'0.91vw'
  },
  PaginationView:{
    marginLeft: '1.95vw'
  },
  RightScrollView:{
    width: "35%", 
    height: screenHeight - 120, 
    backgroundColor: color.themeShade,
  },
  StatHeadView:{
    flexDirection: "row", 
    justifyContent: "flex-start",
    width: "100%"
  },
  StatHeadText:{
    color: "black",
    fontSize: '1.3vw',
    alignSelf:'center',
    fontWeight:'500'
  },
  StatCard:{
    marginLeft:'3.2vw'
  },
  CardWrapper:{
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:"space-evenly",
    width: "100%",
    marginTop: '1.3vw',
    flexWrap:"wrap"
  },
  FilterText:{
    color: "black",
    textAlign: "left",
    fontSize: '1.3vw', 
    marginTop: '1.3vw', 
    marginBottom: '0.97vw'
  },
  RenderTextFieldView:{
   // borderColor: "#525252",
   borderColor: color.lightGray,
   borderWidth: 1,
   marginTop: '0.32vw',
   height: '2.27vw',
   borderWidth: 1,
   borderRadius: '0.65vw',
   alignItems: "center",
   flexDirection: "row",
   paddingLeft: '0.65vw',
   paddingLeft: '0.65vw',
   paddingRight: '0.65vw',
   zIndex: -1
  },
  RenderTextFieldInput:{
    color: '#000', 
    width: "90%",
    fontSize:'0.91vw'
  },
  RenderRightBtnView:{
    height: '2.28vw',
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '0.26vw',
    flexDirection: "row",
    padding: "0.32vw",
    marginBottom: '0.65vw',
    marginHorizontal: '0.16vw',
    borderWidth: 1,
  },
  RenderRightBtnText:{
    fontSize: '0.85vw',
    textAlign: "center",
    fontWeight: "700",
    paddingHorizontal: '0.97vw'
  },
  TableDataWrapper:{
    borderBottomWidth:1,
    borderBottomColor:color.lightGray,
    marginTop:'0.65vw',
    marginBottom:'0.32vw'
  },
  PatientTableDataWrapper:{
    flexDirection: "row", 
    marginLeft: '0.65vw' ,
    marginBottom:'0.32vw'
  },
  PatientTableBillNoText:{
    
    marginBottom: '0.32vw', 
    fontSize: '0.91vw'
  },
  PatientTablePayTypeText:{
    fontSize: '0.8vw'
  },
  PatientTableAmount:{
    marginBottom: '0.32vw', 
    fontSize: '0.91vw'
  },
  PatientDataRedText:{
    fontSize: '0.8vw', 
    color: '#FF0000'
  },
  OverAllTblDataWrapper:{
    flexDirection: 'row', 
    borderBottomWidth: 1, 
    marginTop: '0.65vw', 
    borderBottomColor: '#f2efee', 
    width: '93%', 
    paddingBottom: '1.3vw'
  },
  OverAllTblDataBillNoCol:{
    flex: 0.2, 
    justifyContent: 'center', 
    alignItems: 'flex-start', 
    paddingLeft: '1.95vw'
  },
  OverAllBillDteTxt:{
    marginBottom: '0.32vw', 
    fontSize: '0.97vw' 
  },
  OverAllBillAmtTxt:{
    fontSize: '0.84vw'
  },
  OverAllBillTypeView:{
    flex: 0.2, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  OverAllBillTypeText:{
    fontSize: '0.84vw', 
    color: '#000000', 
    marginLeft: '0.97vw'
  },
    OverAllBillNameView:{
    flex: 0.4, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  OverAllBillNameTxt:{
    fontSize: '0.84vw', 
    color: '#000000', 
    marginLeft: '0.97vw'},
    OverAllBillDocNameView:{
    flex: 0.3, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
    OverAllBillDocNameTxt:{
    fontSize: '0.84vw', 
    color: '#000000', 
    marginLeft: '0.97vw'
  },
    OverAllCancelView:{
    flex: 0.2, 
    justifyContent: 'center', 
    alignItems: 'flex-end'
  },
    OverAllCancelamtText:{ 
    marginBottom: '0.32vw', 
    fontSize: '0.91vw' 
  },
    OverAllCancelPayTypeTxt:{
    fontSize: '0.8vw' 
  },
  AlertWrapper:{
    flex: 1, 
    zIndex: 3,
    position: 'absolute', 
    top:0,
    left: 0, 
    bottom: 0, 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection:  "row",
    borderRadius: '0.32vw', 
    borderWidth: 1,  
    marginRight: '0.65vw', 
    marginTop: '0.65vw',
    width: "100%"
},
AlertContentView:{
    marginLeft: '0.65vw', 
    flex: 0.95, 
    flexDirection: 'row', 
    alignItems: 'center',
},
SuccessTitle:{
    fontWeight: "700", 
    color: "green", 
    fontSize: '0.8vw', 
    marginLeft: '0.97vw'
},
SuccessText:{
    fontWeight: "400", 
    color: "green", 
    fontSize: '0.8vw'
},
ErrorView:{
  flexDirection: "column", 
  justifyContent: "flex-start"
},
ErrorTitle:{
    fontWeight: "700", 
    color: "#D26560", 
    fontSize: '0.8vw',
    marginLeft: '0.97vw', 
    marginBottom: '0.97vw'
},
ErrorText:{
    fontWeight: "400", 
    color: "#D26560", 
    fontSize: '0.8vw', 
    marginBottom: '0.52vw',
    marginLeft: '0.32vw',
},
AlertIcon:{
    flex: 0.05, 
    marginRight: '0.97vw', 
    alignItems: 'center', 
    alignContent: 'center', 
    alignSelf: 'center', 
    justifyContent: "center"
},
FilterHeadView: {
  backgroundColor: color.appointmentTableHead,
  paddingHorizontal: '1.62vw',
  paddingVertical:  '0.3vw',
  flexDirection: 'row', 
  justifyContent: 'flex-start',
  marginBottom: '1vw', 
  zIndex: -1, 
  borderRadius:'0.4vw',
},
filterText:{
  color: "black",
  fontSize: '0.8vw',
  fontWeight:'500'
},
PatientNameBoxView:{
  flexDirection: "row",
  marginBottom: 0,
  marginTop: '1.3vw',
  alignItems: "center",
  zIndex: -1
},
AccNoCkeckView:{
  marginTop:'0.32vw', flexDirection: "row", alignItems:"center", zIndex:-1
},
AccNoCkeckText:{
  fontSize:'0.8vw', color: color.grayShade, marginLeft: '0.32vw'
},
BillNoAmtBoxView:{
  flexDirection: "row",
  marginBottom: '1.3vw',
  marginTop: '1.3vw',
  alignItems: "center",
  zIndex: -1
},
BillTypeContentView:{
  width: "100%", 
  zIndex: -1, 
  flexDirection: 'row', 
  justifyContent: 'flex-start', 
  paddingLeft: '0.84vw', 
  marginVertical: '1.3vw', 
  flexWrap: "wrap"
},
CheckBoxText:{
  fontSize: '0.84vw', 
  color: "#888888",
  marginRight:'0.65vw'
},
PaymentTypeContentView:{
  flexDirection: "row",
  marginLeft: '0.65vw',
  marginBottom: '1.3vw',
  marginTop: '.5vw',
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  zIndex: -1
},
PaymentTypeImg:{
  width: '1.95vw', 
  height: '1.95vw', 
},
PaymentTypeText:{
  marginTop: '0.32vw', 
  fontSize: '0.65vw' 
},
ButtonMainView:{
  flexDirection: "row",
  justifyContent: "space-evenly",
  width: "90%",
  zIndex: -1
},









  

})

 {/*
                  <DatePicker
                    disabledDate={current => {
                      return current && current < moment(this.state.filterFromDate ? this.state.filterFromDate : null)
                        .add(0, 'days')
                        ||
                        current && current > moment().add(0, "days");
                    }}
                    value={this.state.filterToDate !== "" ? moment(this.state.filterToDate) : null}

                    // clearIcon={false}
                    onChange={date => {
                      var converted_date = null;
                      var { filterData } = this.state;
                      if (date) {
                        var fulldate = new Date(date);
                        converted_date = moment(fulldate).format("YYYY-MM-DD");
                      }
                      filterData["toDate"] = converted_date;
                      this.setState({ filterData, filterToDate: converted_date })
                      // this.setState({filterToDate :converted_date},()=>{this.updateState("toDate", this.state.filterToDate)})
                    }}
                    // defaultValue={"From"}
                    format={"DD-MM-YYYY"}
                    placeholder={"To"}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: color.lightGray,
                      borderWidth: 1,
                      marginTop: 5,
                      height: 35,
                      borderWidth: 1,
                      borderRadius: 10,
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                      width: "50%",
                      marginRight: 10
                    }}
                  />
                  */}


                  {/* <TouchableOpacity style={styles.calendarView} onPress={()=>{this.showCalender("From")}}><Text style={styles.calendarText}>{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.calendarView} onPress={()=>{this.showCalender("TO")}}><Text style={styles.calendarText}>{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text></TouchableOpacity> */}

  // successBillingList = response => {

  //     this.setState({
  //       getBillingList: response, 
  //     }, () => {
  //       this.cashCalculation();
  //     })

  // }

  // showBillingList() {
   
  //   if (this.state.getBillingList && this.state.getBillingList.data && this.state.getBillingList.data.length <11) {
  //     return this.state.getBillingList.data && this.state.getBillingList.data.map((bill, index) => {

  //       return this.showBills(bill, index);
  //     })
  //   } 
   
  //   else if (this.state.getBillingList && this.state.getBillingList.data && this.state.getBillingList.data.length > 0) {
  //     return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {

  //       return this.showBills(bill, index);
  //     })
  //   } else if (this.state.getBillingList && this.state.getBillingList.result && this.state.getBillingList.result.length > 0) {
  //     return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {
   
  //       return this.showLaboratoryBills(bill, index);
  //     })
  //   } else {
  //     return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
  //       <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
  //     </View>
  //   }
  // }


  // renderBill(item) {
  //   return (
  //     <View>
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           justifyContent: "space-evenly",
  //           marginLeft: 0,
  //           marginRight: 30,
  //           marginBottom: 10,
  //           height: 60,
  //           borderRadius: 20,
  //           paddingLeft: 10,
  //           paddingRight: 10,
  //           alignItems: "center",
  //           backgroundColor: color.white
  //         }}
  //       >
  //         <View
  //           style={{
  //             width: "30%",
  //             flexDirection: "row"
  //           }}
  //         >
  //           <View style={{ marginLeft: 5 }}>
  //             <Text
  //               style={{
  //                 fontSize: 15,
  //                 fontWeight: "500",
  //                 color: color.black
  //               }}
  //             >
  //               {item.date}
  //             </Text>
  //             <Text
  //               style={{
  //                 fontSize: 12,
  //                 color: color.black,
  //                 marginTop: 5,
  //                 marginBottom: 5
  //               }}
  //             >
  //               {item.id}
  //             </Text>
  //           </View>
  //         </View>

  //         <View
  //           style={{
  //             width: "35%",
  //             alignItems: "center",
  //             justifyContent: "center"
  //           }}
  //         >
  //           <Text
  //             style={{
  //               fontSize: 20,
  //               color: "#9B9B9B"
  //             }}
  //           >
  //             {item.name}
  //           </Text>
  //         </View>

  //         <View
  //           style={{
  //             width: "20%",
  //             alignItems: "center"
  //           }}
  //         >
  //           <Text
  //             style={{
  //               fontSize: platform === "web" ? 15 : 20,
  //               fontWeight: "500",
  //               color: color.black
  //             }}
  //           >
  //             {item.billAmount}
  //           </Text>

  //           <View style={{ flexDirection: "row", alignItems: "center" }}>
  //             <Text
  //               style={{
  //                 fontSize: platform === "web" ? 13 : 15,
  //                 fontWeight: "500",
  //                 color: color.black
  //               }}
  //             >
  //               {item.billStatus}
  //             </Text>
  //             <Text>{" | "}</Text>
  //             <Text
  //               style={{
  //                 fontSize: platform === "web" ? 13 : 15,
  //                 fontWeight: "500",
  //                 color: color.black,
  //                 textAlign: "center"
  //               }}
  //             >
  //               {item.paymentType}
  //             </Text>
  //           </View>
  //         </View>
  //       </View>
  //       {/* <View style = {{ height: 1, backgroundColor: color.red, paddingLeft: 30}}/> */}
  //     </View>
  //   );
  // }

  // calculateTotalAmt = () => {
  //   var fields = this.state;
  //   var cardTotal = 0;
  //   var cashTotal = 0;

  //   dummyBillData &&
  //     dummyBillData.length > 0 &&
  //     dummyBillData.map(item => {
  //       if (item.paymentType === "card") {
  //         cardTotal = cardTotal + parseInt(item.billAmount);
  //       } else if (item.paymentType === "cash") {
  //         cashTotal = cashTotal + parseInt(item.billAmount);
  //       }
  //     });

  //   fields["totalCardAmt"] = cardTotal;
  //   fields["totalCashAmt"] = cashTotal;

  //   this.setState({ fields });
  // };




      //       <View style={{ flexDirection: "row", width: "100%" }}>

      //         {/* {
      //           this.state.showBillinListPopUpFlag ? 
      //           <ShowPatientBilling patientId={this.state.patientId} invoiceNo = {this.state.invoiceNo} closeBillingModal ={this.closeBillingModal.bind(this)} patientInfo = {this.state.patientDetails} billingDetails ={this.state.getAllBillingList}/> : null
      //         } */}
      //         <View style={[Style.leftView, { zIndex: -1, width: "65%", justifyContent: 'space-between' }]}>
      //         {
      //             this.state.responseType == "success" ?
      //               this.successAlert() :
      //               this.state.responseType == "error" ?
      //                 this.errorAlert() : null
      //           }
      //           <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

      //           </ScrollView>

      //         </View>
      //         {/* second view */}
      //         <View style={[Style.rightView, { paddinghorizontal:20, zIndex: -1, width: "35%" }]}>


      //           <View style={{
      //             flexDirection: "row", justifyContent: "flex-start",
      //             marginTop: 20, width: "100%"
      //           }}>
      //             <Text style={{
      //               color: "black",
      //               fontSize: 20
      //             }}
      //             >{"Statistics"}</Text>
      //           </View>
      //           <View
      //             style={{
      //               flexDirection: "row",
      //               justifyContent: "space-between",
      //               alignItems: "center",
      //               width: "100%",
      //               marginTop: 20
      //             }}
      //           >
      //             {this.renderTotalItem(
      //               require("../../../../../assets/images/IPActions/Card.png"),
      //               "Card",
      //               this.state.totalAmountInCard.toFixed(2)
      //             )}
      //             {this.renderTotalItem(
      //               require("../../../../../assets/images/IPActions/Cash.png"),
      //               "Cash",
      //               this.state.totalAmountInCash
      //             )}
      //             {/* <View>
      //                 <Text style={{ fontSize: 16, color: color.themePink }}>
      //                   Total
      //                 </Text>
      //                 <Text style={{ fontSize: 20, paddingTop: 2 }}>{this.state.totalAmountCashAndCard}</Text>
      //               </View> */}
      //             {this.renderTotalItem(
      //               require("../../../../../assets/images/IPActions/Total.png"),
      //               "Total",
      //               this.state.totalAmountCashAndCard
      //             )}
      //           </View>
      //           <View style={{ width: "100%" }}>

      //             {/* <View style={{ justifyContent: "center", alignItems: "center" }}>
      //               <Text style={{ fontSize: 13, color: "#ffffff" }}>
      //                 {this.state.currentTime}
      //               </Text>
      //             </View> */}
      //             {/* <TimerLog /> */}

      //             <Text
      //               style={{
      //                 color: "black",
      //                 textAlign: "left",
      //                 marginTop: 20,
      //                 fontSize: 20
      //               }}
      //             >
      //               Filter
      //             </Text>
      //             {/* <View
      //               style={{
      //                 flexDirection: "row",
      //                 marginBottom: 20,
      //                 marginTop: 20,
      //                 alignItems: "center"
      //               }}
      //             >
      //               {this.renderTextField(
      //                 "From",
      //                 "fromDate",
      //                 this.state.filterData.fromDate,
      //                 true
      //               )}
      //               {this.renderTextField(
      //                 "To",
      //                 "toDate",
      //                 this.state.filterData.toDate,
      //                 true
      //               )}
      //             </View> */}
      //             <View>
      //               <View
      //                 style={{
      //                   flexDirection: "row",
      //                   marginBottom: 20,
      //                   marginTop: 20,
      //                   alignItems: "center"
      //                 }}>

      //                 <DatePicker
      //                   disabledDate={current => {
      //                     return current && current > moment().add(0, "days");
      //                   }}

      //                   value={this.state.filterFromDate !== "" ? moment(this.state.filterFromDate) : null}
      //                   // value={this.state.filterFromDate.toString ? this.state.filterFromDate :""}
      //                   // clearIcon={false}
      //                   onChange={date => {
      //                     // alert(date)
      //                     var converted_date = null;
      //                     var { filterData } = this.state;
      //                     if (date) {
      //                       var fulldate = new Date(date);
      //                       converted_date = moment(fulldate).format("YYYY-MM-DD");
      //                     }
      //                     filterData["fromDate"] = converted_date;
      //                     this.setState({ filterData, filterFromDate: converted_date })
      //                     // this.setState({filterFromDate :converted_date},()=>{this.updateState("fromDate", this.state.filterFromDate)})
      //                   }}
      //                   defaultValue={this.state.filterFromDate ? this.state.filterFromDate : null}
      //                   format={"DD-MM-YYYY"}
      //                   placeholder={"From"}
      //                   style={{
      //                     backgroundColor: "transparent",
      //                     borderColor: color.lightGray,
      //                     borderWidth: 1,
      //                     marginTop: 5,
      //                     height: 35,
      //                     borderWidth: 1,
      //                     borderRadius: 10,
      //                     alignItems: "center",
      //                     flexDirection: "row",
      //                     paddingLeft: 10,
      //                     paddingLeft: 10,
      //                     paddingRight: 10,
      //                     width: "50%",
      //                     marginRight: 10
      //                   }}
      //                 />

      //                 <DatePicker
      //                   disabledDate={current => {
      //                     return current && current < moment(this.state.filterFromDate ? this.state.filterFromDate : null)
      //                       .add(0, 'days')
      //                       ||
      //                       current && current > moment().add(0, "days");
      //                   }}
      //                   value={this.state.filterToDate !== "" ? moment(this.state.filterToDate) : null}

      //                   // clearIcon={false}
      //                   onChange={date => {
      //                     var converted_date = null;
      //                     var { filterData } = this.state;
      //                     if (date) {
      //                       var fulldate = new Date(date);
      //                       converted_date = moment(fulldate).format("YYYY-MM-DD");
      //                     }
      //                     filterData["toDate"] = converted_date;
      //                     this.setState({ filterData, filterToDate: converted_date })
      //                     // this.setState({filterToDate :converted_date},()=>{this.updateState("toDate", this.state.filterToDate)})
      //                   }}
      //                   // defaultValue={"From"}
      //                   format={"DD-MM-YYYY"}
      //                   placeholder={"To"}
      //                   style={{
      //                     backgroundColor: "transparent",
      //                     borderColor: color.lightGray,
      //                     borderWidth: 1,
      //                     marginTop: 5,
      //                     height: 35,
      //                     borderWidth: 1,
      //                     borderRadius: 10,
      //                     alignItems: "center",
      //                     flexDirection: "row",
      //                     paddingLeft: 10,
      //                     paddingLeft: 10,
      //                     paddingRight: 10,
      //                     width: "50%",
      //                     marginRight: 10
      //                   }}
      //                 />


      //                 {/* <TouchableOpacity style={styles.calendarView} onPress={()=>{this.showCalender("From")}}><Text style={styles.calendarText}>{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
      //                   </TouchableOpacity>
      //                   <TouchableOpacity style={styles.calendarView} onPress={()=>{this.showCalender("TO")}}><Text style={styles.calendarText}>{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text></TouchableOpacity> */}
      //               </View>
      //               {this.popUpCalender()}
      //             </View>
      //             {this.renderTextField(
      //               "Patient's Name",
      //               "patientName",
      //               this.state.filterData.patientName,
      //               false
      //             )}

      //             <View
      //               style={{
      //                 flexDirection: "row",
      //                 marginBottom: 20,
      //                 marginTop: 20,
      //                 alignItems: "center",
      //                 zIndex: -1
      //               }}
      //             >
      //               {this.renderTextField(
      //                 "Bill No",
      //                 "billNo",
      //                 this.state.filterData.billNo,
      //                 true
      //               )}
      //               {this.renderTextField(
      //                 "Amount",
      //                 "amount",
      //                 this.state.filterData.amount,
      //                 true
      //               )}
      //             </View>
      //           </View>
      //           <View style={{ width: "95%", zIndex: -1 }}>
      //             <Text style={{ color: "black", textAlign: "left" }}>
      //               Payment Method
      //             </Text>
      //           </View>
      //           <View
      //             style={{
      //               flexDirection: "row",
      //               marginLeft: 10,
      //               marginBottom: 20,
      //               marginTop: 20,
      //               justifyContent: "space-between",
      //               alignItems: "center",
      //               width: "90%",
      //               zIndex: -1
      //             }}
      //           >
      //             {this.renderPaymentBtn(
      //               require("../../../../../assets/images/IPActions/Card.png"),
      //               "Card"
      //             )}
      //             {this.renderPaymentBtn(
      //               require("../../../../../assets/images/IPActions/Cash.png"),
      //               "Cash"
      //             )}
      //             {this.renderPaymentBtn(
      //               require("../../../../../assets/images/IPActions/Cash&Card .png"),
      //               // "Card & Cash"
      //               "Cash and Card"
      //             )}
      //           </View>
      //           <View
      //             style={{
      //               flexDirection: "row",
      //               justifyContent: "space-evenly",
      //               width: "90%",
      //               zIndex: -1
      //             }}
      //           >
      //             <TouchableOpacity
      //               // onPress={() => this.props.navigation.navigate("optDoctorNotes")}
      //               onPress={() => {
      //                 // this.getBillingList();
      //                 this.createFilterTag()
      //               }}
      //             >
      //               {this.renderRightBtn("Search", false)}
      //             </TouchableOpacity>

      //             <TouchableOpacity
      //               // onPress={() => this.props.navigation.navigate("optDoctorNotes")}
      //               onPress={() => {
      //                 this.printBillingList();
      //               }}
      //             >
      //               {this.renderRightBtn("Print", false)}
      //             </TouchableOpacity>

      //             <TouchableOpacity
      //               // onPress={() => this.changeAppointmentStatus("Cancelled")}
      //               onPress={() => {
      //                 this.clearFilter("");
      //               }}
      //             >
      //               {this.renderRightBtn("Clear", true)}
      //             </TouchableOpacity>
      //             {/*
      //             <DatePicker
      //               style={{ width: 200 }}
      //               date={this.state.date}
      //               mode="date"
      //               placeholder="select date"
      //               format="YYYY-MM-DD"
      //               minDate="2016-05-01"
      //               maxDate="2016-06-01"
      //               confirmBtnText="Confirm"
      //               cancelBtnText="Cancel"
      //               customStyles={{
      //                 dateIcon: {
      //                   position: 'absolute',
      //                   left: 0,
      //                   top: 4,
      //                   marginLeft: 0
      //                 },
      //                 dateInput: {
      //                   marginLeft: 36
      //                 }
      //                 // ... You can check the source to find the other keys.
      //               }}
      //               onDateChange={(date) => { this.setState({ date: date }) }}
      //             />
      // */}
      //           </View>
      //         </View>
      //       </View>
      // {/* </TouchableWithoutFeedback> */}
