import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  TextInput,
  FlatList,
  ScrollView,
  Picker,
  TouchableWithoutFeedback,
  CheckBox,
  StyleSheet,
} from "react-native";
import { color } from "../../styles/Color";
import Style from "../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import Save from "react-native-vector-icons/Feather"
import { SearchBar } from 'react-native-elements';
import AsyncStorage from "../../AsyncStorage";
import { DatePicker, Tooltip } from 'antd';
import { MultipleSelectPicker } from 'react-native-multi-select-picker'
import moment from "moment";
import { Constants } from "../../utils/Constants";

// import { Camera } from 'expo-camera';
import TakePicture from "../camera/TakePicture";
// import Arrow from "react-native-vector-icons/MaterialCommunityIcons";
import Add from "react-native-vector-icons/MaterialCommunityIcons";
import Cart from "react-native-vector-icons/FontAwesome";
// const platform = Platform.OS;
const { RangePicker } = DatePicker
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

import InfoMessages from "../../utils/InfoMessages";
import ImagePath from "../../utils/ImagePaths";

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from './ScreenResponsive'
import { connect } from "react-redux";
import { updateDateFilterFlag } from '../../Redux/appDropdownActions';
import { yellow } from "@material-ui/core/colors";
import { Report } from "@material-ui/icons";
import Arrow from "react-native-vector-icons/MaterialCommunityIcons";
var AWS = require("aws-sdk");
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1",
  params: { Bucket: "emedhub" },
});


export const ShowCommonButton = (props) => {
  var minDosageCount = (props.item && (props.item.value == "DROPS" || props.item.value == "CAPSULE") && props.buttonKey == "selectedDosage") ? 1 : (props.item && props.item.value == "TABLET" && props.buttonKey == "selectedDosage") ? 0.5 : (props.item && props.item.value == "SYRUP" && props.buttonKey == "selectedDosage") ? 2.5 : 1
  return (
    <View>
      {(props.item &&
        props.item.value == "DROP" &&
        props.selectedvalue &&
        props.selectedvalue.value == "DROP" &&
        props.buttonKey == "selectedDosage" && props.prescriptionType != "TaperMed") || (props.item &&
        props.item.value == "CAPSULE" &&
        props.selectedvalue &&
        props.selectedvalue.value == "CAPSULE" &&
        props.buttonKey == "selectedDosage" && props.prescriptionType != "TaperMed") || (props.item &&
        props.item.value == "TABLET" &&
        props.selectedvalue &&
        props.selectedvalue.value == "TABLET" &&
        props.buttonKey == "selectedDosage" && props.prescriptionType != "TaperMed") || (props.item &&
        props.item.value == "SYRUP" &&
        props.selectedvalue &&
        props.selectedvalue.value == "SYRUP" &&
        props.buttonKey == "selectedDosage" && props.prescriptionType != "TaperMed") ? (
        <View
          style={{
            flexDirection: "row",
            width: '9.11vw',
            borderRadius: '1.95vw',
            height: '1.95vw',
            paddingLeft: '0.32vw',
            paddingRight: '0.32vw',
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "center",
            backgroundColor: color.red,
          }}
        >
          <TouchableOpacity
            style={{
              alignSelf: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: '1.3vw',
              width: props.currentPage == "laboratoryBillingTransaction" ? '6.51vw' : '1.3vw',
              borderRadius: '2.60vw',
              backgroundColor: color.white,
            }}
            onPress={() => {
              props.selectedDosageCount > minDosageCount ? props.buttonAction(props.buttonKey, props.item, "sub") : null;
            }}
          >
            <Text
              style={{
                color: color.themeBlue,
              }}
            >
              {"-"}
            </Text>
          </TouchableOpacity>
          <View>
            <Text
              style={{
                fontSize: '0.91vw',
                textAlign: "center",
                color: color.white,
                fontWeight: "400",
              }}
            >
              {props.selectedDosageCount}{props.selectedvalue && props.selectedvalue.value == "SYRUP" ? "ml" : null} {" "} 
              {props.selectedDosageCount == 1 ||  props.selectedDosageCount == 0.5 || (props.selectedvalue && props.selectedvalue.value == "SYRUP")  ? props.item.label : props.item.label + "s"}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              alignSelf: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: '1.3vw',
              width: '1.3vw',
              borderRadius: '2.60vw',
              backgroundColor: color.white,
            }}
            onPress={() => {
              props.buttonAction(props.buttonKey, props.item, "add");
            }}
          >
            <Text style={{ color: color.themeBlue }}>{"+"}</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <TouchableOpacity
          style={[
            {
              maxWidth: "16.92vw",
              borderWidth: 1,
              borderRadius: "1.95vw",
              paddingTop: "0.52vw",
              paddingHorizontal: props.buttonKey == "selectedSPH" || props.buttonKey == "selectedCYL" ? "0.65vw" : '1.17vw',
              paddingBottom: "0.52vw",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: props.disable
                ? color.disableComponentColor
                : color.themeShadeBackground,
              alignSelf: "center",
            },
            props.item &&
              props.item.value &&
              props.selectedvalue &&
              props.selectedvalue.value &&
              props.item.value == props.selectedvalue.value
              ? {
                backgroundColor: color.themeDark,
                borderColor: color.themeDark,
              }
              : props.buttonType == "outlineTheme"
                ? { borderColor: color.themeDark }
                : props.buttonType == "outlineNonTheme"
                  ? { borderColor: "#888888" }
                  : props.buttonType == "theme"
                    ? {
                      backgroundColor: color.themeDark,
                      borderColor: color.themeDark,
                    }
                    : props.buttonType == "darkBlue"
                      ? { backgroundColor: "#2A65D5", borderColor: "#2A65D5" }
                      : null,

            Style.allButtonBorderRadius,

            props.buttonBorderColor ? { borderColor: props.buttonBorderColor, borderWidth: 2 } : null
          ]}
          onPress={() => {
            if (props.buttonKey == "markOutOfOfficeModalCancel") {
              props.buttonAction(false);
            } else {
              props.buttonAction(props.buttonKey, props.item);
            }
          }}
          disabled={props.disable ? true : false}
        >
          {props.buttonIcon && props.rightIcon ? (
            <Image
              style={[{ height: props.buttonKey == "motivationSaveButton" ? '1.17vw' : '0.78vw', width: '1.3vw', marginRight: '0.976vw' }, props.buttonKey == "demographics" ? { tintColor: color.white } : {}]}
              source={props.buttonIcon}
            />
          ) : null}

          <Text
            style={[
              props.item &&
                props.item.value &&
                props.selectedvalue &&
                props.selectedvalue.value &&
                props.item.value == props.selectedvalue.value
                ? { color: "white", fontSize: "0.8vw" }
                : props.buttonType == "outlineTheme"
                  ? { color: "#04c6bf", fontSize: '0.8vw' }
                  : props.buttonType == "outlineNonTheme"
                    ? { color: "black", fontSize: '0.8vw' }
                    : props.buttonType == "theme"
                      ? { color: "white", fontSize: '0.8vw' }
                      : props.buttonType == "darkBlue"
                        ? { color: "white", fontSize: '0.8vw' }
                        : null,
            ]}
          >
            {props.butttonText}
          </Text>

          {props.buttonIcon && !props.rightIcon ? (
            props.buttonKey == "selectedAllergyName" ? 
            <TouchableOpacity onPress={() => {props.IconAction(props.buttonKey, props.item)}}>
              <Image
              style={{ height:".5vw", width:".5vw", marginLeft: '0.976vw', marginTop: ".1vw" }}
              source={props.buttonIcon}
              />
            </TouchableOpacity> :
            <Image
              style={{ height: '0.976vw', width: '0.976vw', marginLeft: '0.976vw' }}
              source={props.buttonIcon}
            />
          ) : null}
        </TouchableOpacity>
      )}
    </View>
  );
};

export const CommonButton = (props) => {
  var selectedDosageCount = 0;
  if (props.selectedDosageCount) {
    selectedDosageCount = props.selectedDosageCount;
  }

  var prescriptionType = props.prescriptionType ? props.prescriptionType : ""

  if (
    props.buttonKey == "ocularsurgerySelectedValue" ||
    props.buttonKey == "ocularHistorySelectedItems" ||
    props.buttonKey == "systamicAllergySelectedItems" ||
    props.buttonKey == "systamicIllnessSelectedItems" ||
    props.buttonKey == "selectedHistory" ||
    props.buttonKey == "selectedDrug" ||
    props.buttonKey == "checkGynaecologicalHistory" ||
    props.buttonKey == "detailsOfBirthControlInPast" ||
    props.buttonKey == "selectedbirthControl" ||
    props.buttonKey == "treatedOfDiabetic" ||
    props.buttonKey == "selectedEHRWeeks" ||
    props.buttonKey == "selectedTelemedicineWeeks" ||
    props.buttonKey == "selectedDrugs" ||
    props.buttonKey == "selectedMedicalHistoryProbloms" ||
    props.buttonKey == "selectedFamilyHistory" ||
    props.buttonKey == "selectedSessionValue" ||
    props.buttonKey == "selectedhighBloodGulcose" ||
    props.buttonKey == "selectedBloodGulcosesymptoms" ||
    props.buttonKey == "selectedLanguages" ||

    props.buttonKey == "selectedMorning" ||
    props.buttonKey == "selectedBreakfast" ||
    props.buttonKey == "selectedMidMorning" ||
    props.buttonKey == "selectedLunch" ||
    props.buttonKey == "selectedEvening" ||
    props.buttonKey == "selectedDinner" ||
    props.buttonKey == "selectedLateNight" ||
    props.buttonKey == "lensType" || props.buttonKey == "lens_type" || props.buttonKey == "lens.lens_type" ||
    props.buttonKey == "selectedTrimester1" || props.buttonKey == "selectedTrimester2" || props.buttonKey == "selectedTrimester3" ||
    props.buttonKey == "selectedFirstTwoWeeks" || props.buttonKey == "selectedSocial" ||

    props.buttonKey == "selectedCategorysInAgeAtFirstUse" || props.buttonKey == "selectedCategorysInCircumstancesofFirstUse" || props.buttonKey == "selectedCategorysInAFrequencyofUse" ||
    props.buttonKey == "selectedCategorysInQuantityofUse" || props.buttonKey == "selectedCategorysInHistoryofconsumption" || props.buttonKey == "selectedCategorysInTiming" ||
    props.buttonKey == "selectedCategorysInBrand" || props.buttonKey == "selectedCategorysInCraving" || props.buttonKey == "selectedCategorysInTolerance" ||
    props.buttonKey == "selectedCategorysInBlackouts" || props.buttonKey == "selectedCategorysInLastUsage" || props.buttonKey == "selectedCategorysInAdditionalNotes" || props.buttonKey == "selectedGender"

    // props.buttonKey == "selectedBreakfast" 

  ) {
    if (props.selectedvalue && props.selectedvalue.length > 0) {
      const found = props.selectedvalue.some(
        (el) => el.label === props.butttonText
      );
      if (!found) {
        return (
          <ShowCommonButton
            buttonBorderColor={props.buttonBorderColor}

            selectedDosageCount={selectedDosageCount}
            buttonAction={props.buttonAction}
            item={props.item}
            buttonType={props.buttonType}
            disable={props.disable}
            buttonKey={props.buttonKey}
            buttonIcon={props.buttonIcon}
            rightIcon={props.rightIcon}
            butttonText={props.butttonText}
            prescriptionType={prescriptionType}
            IconAction={props.IconAction}
          />
        );
      } else {
        return (
          <ShowCommonButton
            buttonBorderColor={props.buttonBorderColor}
            selectedDosageCount={selectedDosageCount}
            buttonAction={props.buttonAction}
            item={props.item}
            selectedvalue={props.item}
            buttonType={props.buttonType}
            disable={props.disable}
            buttonKey={props.buttonKey}
            buttonIcon={props.buttonIcon}
            rightIcon={props.rightIcon}
            butttonText={props.butttonText}
            prescriptionType={prescriptionType}
            IconAction={props.IconAction}
          />
        );
      }
    } else {
      return (
        <ShowCommonButton
          buttonBorderColor={props.buttonBorderColor}
          selectedDosageCount={selectedDosageCount}
          buttonAction={props.buttonAction}
          item={props.item}
          selectedvalue={props.selectedvalue}
          buttonType={props.buttonType}
          disable={props.disable}
          buttonKey={props.buttonKey}
          buttonIcon={props.buttonIcon}
          rightIcon={props.rightIcon}
          butttonText={props.butttonText}
          prescriptionType={prescriptionType}
          IconAction={props.IconAction}
        />
      );
    }
  } else {
    return (
      <ShowCommonButton
        buttonBorderColor={props.buttonBorderColor}
        selectedDosageCount={selectedDosageCount}
        buttonAction={props.buttonAction}
        item={props.item}
        selectedvalue={props.selectedvalue}
        buttonType={props.buttonType}
        disable={props.disable}
        buttonKey={props.buttonKey}
        buttonIcon={props.buttonIcon}
        rightIcon={props.rightIcon}
        butttonText={props.butttonText}
        prescriptionType={prescriptionType}
        IconAction={props.IconAction}
      />
    );
  }
};

export const DoctorNotesCommonRightSideHeader = (props) => {
  return (
    <View
      style={styles.CommomRightSideHeaderView}
    >
      <View>
        <Text style={{ fontSize: props.title == "History of Abstinence & De-Addiction Treatment" ? '1vw' : '1.2vw', color: color.themeDark }}>{props.title}</Text>
      </View>
      {props.hideClearButton == true ?
        <TouchableOpacity
          onPress={() => {
            props.clearAllData();
          }}
          style={[{
            backgroundColor: color.themeDark,
            // padding: 20,
            paddingTop: '0.5vw',
            paddingBottom: '0.5vw',
            paddingLeft: '1.1vw',
            paddingRight: '1.1vw',
            // borderRadius: 10,
          }, Style.allButtonBorderRadius,]}
        >
          <Text style={{ color: color.white, fontWeight: "800", fontSize: '0.8vw' }}>{"Clear"}</Text>

        </TouchableOpacity> : null
      }
    </View>
  );
};

export const AppointmentRequest = (props) => {
  const userType = props.userType
  var RecievedAppointmentRequest = props.appointmentRequest
  var onchage = true;
  var selectedDate = "";
  //console.log("Check++++++++" + JSON.stringify(RecievedAppointmentRequest))
  function RenderList (item) {
    return(
      <View style={{
        marginTop: 20,
        marginLeft: 5,
        //   width: platform === "web" ? "100%" : "75%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
          <View
            style={{
              width: "8vw",
            }}
          >
            <Text style={styles.contentTableTitle}>{item.patient_name}/{item.age ? item.age+" Y" : ""}</Text>
          </View>

          <View
            style={{
              width: "8vw",
            }}
          >
            <Text style={styles.contentTableTitle}>{item.mobile_number}</Text>
          </View>
          <View
            style={{
              width: "10vw",
            }}
          >
            <Text style={styles.contentTableTitle}>{item.message}</Text>
          </View>
          <View
            style={{
              width: "10vw",
            }}
          >
            <Text style={styles.contentTableTitle}>{item.appointment_date}</Text>
            <Text style={styles.contentTableTitle}>{item.appointment_time}</Text>
          </View>
          <View
            style={{
              width: "8vw",
            }}
          >
            <Text style={styles.contentTableTitle}>{item.booked_through ? item.booked_through : "-"}</Text>
          </View>


          {
            userType == "FO" ?
              <View
                style={{
                  width: "8vw",
                  backgroundColor: color.white,
                }}
              >
                <Text style={styles.contentTableTitle}>{item.doctor_name}</Text>
              </View>
              :
              <View
                style={{
                  width: "8vw",
                  backgroundColor: color.white,
                }}
              >
                <Text style={styles.contentTableTitle}>{item.clinic_name}</Text>
              </View>
          }
          <View
            style={{
              width: "10vw",
            }}
          >
            <View style={{ flexDirection: "row" }}>
              {item.patient_id ?
              <TouchableOpacity onPress={() => {
                props.AcceptRequest(item, false)
              }}
                style={{ marginRight: 20 }}>
                <Icon name="check" style={{fontSize:'1.5vw'}} color={color.themeDark} />
              </TouchableOpacity> :
              <View style={{flexDirection: 'row'}}>
              <TouchableOpacity onPress={() => {
                navigator.clipboard.writeText(item.mobile_number)
              }}
                style={{ marginRight: 20 }}>
                <Tooltip placement="top" title="Copy Mobile Number">
                <Icon name="copy" style={{fontSize:'1.5vw'}} color={color.themeDark} />
                </Tooltip>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                props.cancelRequest(item, "Completed")
              }}
                style={{ marginRight: 20 }}>
                <Tooltip placement="top" title="Complete Request">
                <Icon name="check" style={{fontSize:'1.5vw'}} color={color.themeDark} />
                </Tooltip>
              </TouchableOpacity>
              </View>
              }
              <TouchableOpacity onPress={() => {
                props.cancelRequest(item, "Cancelled")
              }}>
                <Icon name="remove" style={{fontSize:'1.5vw'}} color="red" />
              </TouchableOpacity>
            </View>
          </View>
      </View>
    )
  }
  function RenderView() {
    if(selectedDate!=""){
      var newRecievedAppointmentRequest = props.appointmentRequest.filter(function (filreq){
        return filreq.appointment_date === selectedDate;
      })
      RecievedAppointmentRequest = newRecievedAppointmentRequest;
    }
    return(
    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: 330 }}>
                {RecievedAppointmentRequest && RecievedAppointmentRequest.length >= 0 && RecievedAppointmentRequest.map((item) => {
                  return (
                    <View>
                      {RecievedAppointmentRequest.length >0 ?
                      <View>
                      {RenderList(item)}
                      </View>
                      :
                      <View><Text>No Records to Show</Text></View>
                    }
                    </View>
                  )
                })}
              </ScrollView>
    )
  }
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            width: "62vw",
            height: "30vw",
            borderRadius: 15,
          }}
        >
          <View style={{ margin: 20, backgroundColor: color.white, borderRadius: 15, }}>
            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
              <Text style={{ marginTop: '0.30vw', fontSize: '1.5vw' }}>
                Appointment Requests
              </Text>
              {/* <DatePicker
                        //value={selectedDate}
                        onChange={(date) => {
                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate).format("YYYY-MM-DD");
                          selectedDate = converted_date;
                          var newRecievedAppointmentRequest = props.appointmentRequest.filter(function (filreq){
                            return filreq.appointment_date === converted_date;
                          })
                          RecievedAppointmentRequest = newRecievedAppointmentRequest;
                          onchage = true;
                          {RenderView()}
                        }
                        }
                        clearIcon={false}
                        format={"DD-MM-YYYY"}
                        placeholder={"Select"}
                        style={{
                          height: 30,
                          borderWidth: 1,
                          borderColor: color.rightPanelInputBorder,
                          borderRadius: 5,
                          width: "35%"

                        }}
                      />  */}
              <TouchableOpacity onPress={() => {
                props.appointmentRequestModalFlag(false)
              }} style={{ marginTop: 10, fontSize: 30 }}>
               <Image
                    source={ImagePath.Close}
                     style={{ height: "1.5vw", width: "1.5vw", tintColor: color.themeDark }} />
              </TouchableOpacity>

            </View>
            <View style={{ marginTop: 5 }}>
              <View
                style={{
                  marginTop: '1vw',
                  marginLeft: 5,
                  //   width: platform === "web" ? "100%" : "75%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: "8vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Patients Name</Text>
                </View>

                <View
                  style={{
                    width: "8vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Mobile Number</Text>
                </View>
                <View
                  style={{
                    width: "10vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Reason</Text>
                </View>
                <View
                  style={{
                    width: "10vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Appointment Date</Text>
                </View>
                <View
                  style={{
                    width: "8vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Medium</Text>
                </View>

                {
                  userType == "FO" ?
                    <View
                      style={{
                        width: "8vw",
                        backgroundColor: color.white,
                      }}
                    >
                      <Text style={styles.contentModalTitle}>Doctor Name</Text>
                    </View> :

                    <View
                      style={{
                        width: "8vw",
                        backgroundColor: color.white,
                      }}
                    >
                      <Text style={styles.contentModalTitle}>Clinic Name</Text>
                    </View>

                }
                {/*                 
                 <View
                  style={{
                    width: "20%",
                    backgroundColor: color.white,
                  }}
                >
                  <Text style={Style.contentModalTitle}>Clinic Name</Text>
                </View> */}

                <View
                  style={{
                    width: "10vw",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Action</Text>
                </View>
              </View>
              {RenderView()}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};





export const UnlockDoctorNotes = (props) => {
  // console.log("Yessssssssss" +JSON.stringify(props.getUnLockDoctor))
  const getUnLockDoctor = props.getUnLockDoctor
  // console.log(getUnLockDoctor)
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: '100vw',
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            width: "40vw",
            // height: "60vh",
            borderRadius: 15,
          }}
        >
          <View style={{ margin: 20 }}>
            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
              <Text style={{ marginTop: 10, fontSize: "1.5vw" }}>
                Unlocked Doctor Notes
              </Text>
              <TouchableOpacity onPress={() => {
                props.unLockDoctorNotesModalFlag(false)
              }} style={{ marginTop: 10, fontSize: 30 }}>
                <Image
                    source={ImagePath.Close}
                     style={{ height: "1.5vw", width: "1.5vw", tintColor: color.themeDark }} />
              </TouchableOpacity>

            </View>
            <View style={{ marginTop: 5 }}>
              <View
                style={{
                  marginTop: 20,
                  marginLeft: 5,
                  //   width: platform === "web" ? "100%" : "75%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text style={styles.contentModalTitle}>Date</Text>
                </View>

                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text style={styles.contentModalTitle}>No. of Patients</Text>
                </View>

              </View>
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: "20vw" }}>
                {getUnLockDoctor && getUnLockDoctor.length > 0 && getUnLockDoctor.map((item) => {
                  return (
                    <View
                      style={{
                        marginTop: 20,
                        marginLeft: 5,
                        //   width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >

                      <View
                        style={{
                          width: "50%",
                        }}
                      >
                        <TouchableOpacity onPress={() => props.getAppointments(item.appointment_date)}>
                          <Text style={styles.contentTableTitle}>{item.appointment_date}</Text>
                        </TouchableOpacity>
                      </View>

                      <View
                        style={{
                          width: "50%",
                        }}
                      >
                        <Text style={styles.contentTableTitle}>{item.total}</Text>
                      </View>
                    </View>
                  )
                })}
              </ScrollView>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};




export const MarkOutofOfficeModal = (props) => {
  const items = props.listOfClinicDetails
  // console.log("items ----------------------------> " + JSON.stringify(items))
  var getDatas = []
  if (props.listOfClinicDetails.length > 0) {
    for (let i = 0; i < props.listOfClinicDetails.length; i++) {
      // const element = array[i];
      var prepareDate = { label: props.listOfClinicDetails[i].value, value: props.listOfClinicDetails[i].id }
      getDatas.push(prepareDate)
    }
  }


  const sessionTypes = [
    { label: "Session 1", value: 1 },
    { label: "Session 2", value: 2 },
    { label: "Session 3", value: 3 },
    { label: "All Session", value: "All" },
  ]

  // console.log("Yessssssssss" +JSON.stringify(props.getUnLockDoctor))
  const getUnLockDoctor = props.getUnLockDoctor
  // console.log(getUnLockDoctor)
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            width: "50%",
            height: "50%",
            borderRadius: 15,
          }}
        >
          <View style={{ paddingHorizontal: 20, paddingVertical: 15, backgroundColor: color.white, borderRadius: 15, }}>
            <TouchableWithoutFeedback onPress={() => {
              props.multiSelecteDropDownShowHide(false)
            }}>
              <View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                  <View style={{ flexDirection: 'row', width: "100%", height: 35, backgroundColor: props.type == "sub" ? color.sectionHeadingLiteColor : color.sectionHeadingDarkColor, alignItems: 'center' }}>

                    <TouchableOpacity onPress={() => {
                      props.markOutofOfficeModalFlag(false)
                    }} style={{ fontSize: 30, alignContent: 'center', marginLeft: 10 }}>
                      <Icon name="long-arrow-left" size={20} color={color.black} />
                    </TouchableOpacity>
                    <Text style={{ fontWeight: '500', fontSize: 15, marginLeft: 20 }}>Mark out of office</Text>

                  </View>


                </View>
                <View style={{}}>
                  <View
                    style={{
                      // marginTop: 20,
                      marginLeft: 5,
                      //   width: platform === "web" ? "100%" : "75%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        marginTop: 18,
                        width: "30%",
                      }}
                    >
                      <DatePicker
                        disabledDate={current => {
                          return current && current <= moment().add(-1, "days");
                        }}
                        value={
                          props.sessionStateDateAndTime ?
                            moment(props.sessionStateDateAndTime, "YYYY-MM-DD") :
                            ""
                        }
                        clearIcon={false}

                        onChange={date => {

                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate).format("YYYY-MM-DD HH:mm:ss");
                          // this.setState({ filterFromDate: converted_date })
                          props.changeSessionDateAndTime(converted_date, props.sessionEndDateAndTime, props.detailsTextBoxValue)

                          // this.setState({ filterFromDate: converted_date }, () => { this.updateState("fromDate", this.state.filterFromDate) })
                        }}
                        // defaultValue={"From"}
                        format={"DD-MM-YYYY"}
                        placeholder={"DD-MM-YYYY"}
                        style={{
                          borderColor: color.themeDark, borderWidth: 1, marginTop: 5, height: 32, borderWidth: 1, borderColor: color.themeDark, borderRadius: 4,
                          alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "100%", marginRight: 10
                        }}
                      />
                    </View>

                    <View
                      style={{
                        marginTop: 18,
                        width: "30%",
                      }}
                    >
                      <DatePicker
                        disabledDate={current => {
                          return current && current <= moment().add(-1, "days");
                        }}
                        value={
                          props.sessionEndDateAndTime ?
                            moment(props.sessionEndDateAndTime, "YYYY-MM-DD ") :
                            ""
                        }
                        onChange={date => {
                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate).format("YYYY-MM-DD HH:mm:ss");
                          // this.setState({ filterFromDate: converted_date })

                          props.changeSessionDateAndTime(props.sessionStateDateAndTime, converted_date, props.detailsTextBoxValue)

                          // this.setState({ filterFromDate: converted_date }, () => { this.updateState("fromDate", this.state.filterFromDate) })
                        }}
                        // defaultValue={"From"}
                        format={"DD-MM-YYYY"}
                        placeholder={"DD-MM-YYYY"}
                        style={{
                          borderColor: color.themeDark, borderWidth: 1, marginTop: 5, height: 32, borderWidth: 1, borderColor: color.themeDark, borderRadius: 4,
                          alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "100%", marginRight: 10
                        }}
                      />
                    </View>

                    <View
                      style={{
                        width: "30%",
                      }}
                    >
                      <ScrollView style={{ position: 'absolute', zIndex: 1, marginBottom: 20, paddingBottom: 20 }}>
                        <TouchableOpacity
                          onPress={() => {
                            //  console.log("items ----------------------------> " + JSON.stringify(items))

                            props.multiSelecteDropDownShowHide(!props.isShownPicker)
                            // this.setState({ isShownPicker: !this.state.isShownPicker })
                          }}
                          style={{ borderRadius: 4, paddingHorizontal: 20, height: 30, backgroundColor: '#dadde3', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <Text>Select Clinic</Text>
                        </TouchableOpacity>
                        {props.isShownPicker ?


                          <ScrollView style={{ borderRadius: 6, maxHeight: 250 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

                            <MultipleSelectPicker
                              items={getDatas}
                              onSelectionsChange={(ele) => {
                                props.onSelectClinicDatas(ele)
                                // this.setState({ selectectedItems: ele }) 
                              }}
                              selectedItems={props.selectectedItems}
                              buttonStyle={{ justifyContent: 'center', alignItems: 'center', height: 20 }}
                              buttonText='hello'
                              checkboxStyle={{ height: 15, width: 15 }}
                            />
                          </ScrollView>
                          : null
                        }

                        {/* {(props.selectectedItems || []).map((item, index) => {
                      return <Text key={index}>
                        {item.label}
                      </Text>
                    })} */}

                      </ScrollView >




                      {/* <Picker
                      // disabled
                    
                      // selectedValue={this.state.mandatoryInformation.gender}
                      style={[Style.pickerView, { marginTop: 5, borderColor:'#CDD1D5'  }]}
                      itemStyle={{}}
                    
                    >
                      <Picker.Item label="Clinic Name" value="" />
                     
                    </Picker>  */}
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    {(props.selectectedItems || []).map((item, index) => {
                      return (
                        <View style={{ marginTop: 15, marginLeft: 15, }}>
                          <Text key={index}>
                            {item.label}
                          </Text>
                        </View>
                      )
                    })}
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 20, zIndex: -2, justifyContent: 'space-between' }} >

                    {
                      sessionTypes.map((item, index) => {
                        return (
                          <CommonButton
                            item={item}
                            selectedvalue={props.selectedSessionValue}
                            butttonText={item.label}
                            buttonType={"outlineNonTheme"}
                            buttonIcon={""}
                            rightIcon={false}
                            buttonAction={props.onPressSessionAction}
                            buttonKey={"selectedSessionValue"} />
                        )
                      })
                    }


                  </View>
                  <View style={{ marginTop: 10, zIndex: -1 }}>
                    <TextInput
                      value={props.detailsTextBoxValue}
                      placeholder={"Reason"}
                      multiline={true}
                      textAlignVertical="top"
                      style={{
                        marginTop: 5,
                        marginBottom: 20,
                        textAlignVertical: "top",
                        paddingLeft: 10,
                        backgroundColor: color.white,
                        borderRadius: 8,
                        borderColor: color.lightGray,
                        borderWidth: 1,
                        height: 65,
                      }}
                      onChangeText={(text) => {
                        props.changeSessionDateAndTime(props.sessionStateDateAndTime, props.sessionEndDateAndTime, text)

                      }}
                    />
                  </View>

                  {
                    props.markOutOffLocationErrorMessage ?
                      <View style={{ marginBottom: 5 }}>
                        <Text style={{ fontSize: 13, fontWeight: '500', color: 'red', textAlign: 'center' }}>{props.markOutOffLocationErrorMessage}</Text>
                      </View> : null
                  }


                  <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: -1 }}>
                    <View style={{ marginRight: 20 }}>
                      <CommonButton
                        // item={data}
                        selectedvalue={{}}
                        butttonText={"Mark"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={props.markOutOfOfficeSaveAction}
                        buttonKey={"outlineTheme"} />
                    </View>
                    <View style={{}}>
                      <CommonButton
                        // item={data}
                        selectedvalue={{}}
                        butttonText={"Cancel"}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={props.markOutofOfficeModalFlag}
                        buttonKey={"markOutOfOfficeModalCancel"} />
                    </View>
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    </View>
  );
};



export const OvulationChartSectionHeading = (props) => {
  // alert(props.selectedPageName)
  // alert(props.pageName)
  // : color.drNotesUnSelectedTitle
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <View>
        <TouchableOpacity disabled={props.disabled} onPress={() => { props.selectedRightSideView(props.pageName) }}>
          <Text style={{ fontSize: 18, color: props.selectedPageName == props.pageName ? color.themeDark : color.drNotesUnSelectedTitle, fontWeight: "600" }}>{props.heading}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}




export const OBGYNHistorySectionHeading = (props) => {
  // : color.drNotesUnSelectedTitle
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <View>
        <TouchableOpacity disabled={props.disabled} onPress={() => { props.selectedRightSideView(props.pageName) }}>
          <Text style={{ fontSize: 18, color: props.selectedPageName == props.pageName ? color.themeDark : color.drNotesUnSelectedTitle, fontWeight: "600" }}>{props.heading}</Text>
        </TouchableOpacity>
      </View>
      {props.bloodGroup ?
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginLeft: 20 }}>
          <Text style={{ fontSize: 12, color: "#888888" }}>{"Blood group :"}</Text>
          <Text style={{ fontSize: 14, fontWeight: "500", color: "black" }}>{props.bloodGroup}</Text>
        </View> : null
      }

      <View style={{ marginLeft: 10, flexDirection: 'row' }}>

        {
          props.editImage ?
            <TouchableOpacity onPress={() => { props.editHistory() }}>
              <View style={Style.editIcon}>
                <Icon name={props.editImage} color={color.newPencilColor} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }


        {
          props.deleteImage ?
            <TouchableOpacity onPress={() => { }}>
              <View style={Style.editIcon}>
                <Icon name={props.deleteImage} color={color.themeDark} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }
      </View>
    </View>
  )
}



export const DoctorNotesSectionHeading = (props) => {
  // : color.drNotesUnSelectedTitle
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <View>
        <TouchableOpacity disabled={props.disabled} onPress={() => { props.selectedRightSideView(props.pageName) }}>
          <Text style={{ fontSize: 18, color: props.selectedPageName == props.pageName ? color.themeDark : color.drNotesUnSelectedTitle, fontWeight: "600" }}>{props.heading}</Text>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row' }}>

        {
          props.editImage ?
            <TouchableOpacity onPress={() => { props.editHistory() }}>
              <View style={Style.editIcon}>
                <Icon name={props.editImage} color={color.themeDark} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }


        {
          props.deleteImage ?
            <TouchableOpacity onPress={() => { }}>
              <View style={Style.editIcon}>
                <Icon name={props.deleteImage} color={color.themeDark} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }
      </View>
    </View>
  )
}

export const CommonAddButton = (props) => {
  return (
    <TouchableOpacity disabled={props.disable ? true : false} onPress={() => { props.onPressAddButton() }} style={AddButtonStyle.Btn}>
      <Text style={AddButtonStyle.BtnText}>{"Add"}</Text>
    </TouchableOpacity>
  )
}

export const CommonPicker = (props) => {
  var selectedClinicName = ""
  var getIndex;
  if ((props.multiSelect && props.selectedValue.length > 0)) {
    // alert(JSON.stringify(props.selectedValue))
    // alert(JSON.stringify(props.selectedValue[0].value))
    getIndex = props.multiSelect.length;
    selectedClinicName = props.selectedValue[0].value
  }
  if(props.arrayOfData && props.arrayOfData.length > 0 && props.pickerKey === "Doctor") {
    var selectedClinic = props.arrayOfData[0].value
    // props.changePickerDropDownValue(false, selectedClinic, props.pickerKey)
  }
  console.log(props,props.selectedValue, "selectedValue")
  return (
    <View >
      {/* <View style={{flexDirection:'row',alignSelf:'center',justifyContent:'center',alignContent:'center',alignItems:'center'}}> */}
      <TouchableOpacity onPress={() => {
        props.changePickerDropDownValue(!props.showPickerDropDownFlag, props.pickerKey === "Doctor" ? selectedClinic : "", props.pickerKey)
      }} style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', paddingHorizontal: ".65vw",
        height: "2vw",
        backgroundColor: color.white,
        borderRadius: "1.3vw",
        borderWidth: 1,
        width: props.pickerKey === "Doctor" || props.pickerKey === "PatientTypeDoctor" || props.pickerKey === "NotQMSFO" || props.pickerKey === "NotQMSPatientType"   ? null : props.pickerKey === "IPDoctor"  ? "14vw" : "10vw",
        borderColor: color.textBoxBorderColor,

        // }}>

        //   <Text style={{ color: "#888888", fontSize: 11 }}>{props.selectedValue && props.selectedValue.value ? props.selectedValue.value : ""}</Text>

      }}>



        <Text style={{ color: "#888888", fontSize: props.pickerKey === "FO" || props.pickerKey === "PatientType" || props.pickerKey === "PatientTypeDoctor" || props.pickerKey === "departmentType" ? '0.75vw' : "0.90vw" }}>
          {
          props.multiSelect && getIndex ?
              selectedClinicName : (props.pickerKey === "Doctor" && (props.selectedValue.id == "")) ? selectedClinic : props.selectedValue ? props.selectedValue.value : props.pickerKey === "FO" || props.pickerKey === "NotQMSFO"  ? "Select Doctor" : props.pickerKey === "PatientType" ||
              props.pickerKey === "PatientTypeDoctor" || props.pickerKey === "NotQMSPatientType" ? "Select Patient Type" : props.pickerKey == "IPDoctor" ? "Select Doctor" : props.pickerKey === "departmentType" ? "Select Specialization" : props.selectedValue.value
          }</Text>
        <Icon name="angle-down" size={".85vw"} color={"#888888"} style={{marginLeft:".5vw"}} />
      </TouchableOpacity>
      {/* </View> */}
      {
        props.showPickerDropDownFlag ?
          <View style={{ position: 'absolute', marginTop: "2.5vw" }}>
            <ScrollView
              showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
              style={{ width: props.pickerKey === "Doctor" || props.pickerKey === "FO" || props.pickerKey === "PatientType" || props.pickerKey === "PatientTypeDoctor" || props.pickerKey === "departmentType" || props.pickerKey === "NotQMSPatientType" || props.pickerKey === "NotQMSFO" || props.pickerKey == "PatientTag" ? "10vw" : props.pickerKey === "IPDoctor" ? "14vw" : null, borderColor: color.black, borderRadius: ".3vw", borderWidth: 1, backgroundColor: color.white, maxHeight: "20vw" }}>
              {
                props.arrayOfData && props.arrayOfData.length > 0 && props.arrayOfData.map((item, index) => {
                  var checkIsAvailableInSelectedArray = false
                  if (props.multiSelect) {
                    checkIsAvailableInSelectedArray = props.selectedValue.includes(item)
                  }
                  return (
                    <TouchableOpacity
                      key={index} onPress={() => {
                        props.changePickerDropDownValue(!props.showPickerDropDownFlag, item, props.pickerKey)
                      }} >
                      <Text style={{
                        fontSize: props.pickerKey === "Doctor" || props.pickerKey === "FO" || props.pickerKey === "PatientType" || props.pickerKey === "PatientTypeDoctor" || props.pickerKey === "departmentType" ? ".65vw" : ".85vw",
                        // color: props.selectedValue.id == item.id ? color.white : "#888888",
                        // backgroundColor: props.selectedValue.id == item.id ? color.themeDark : null,
                        color: (props.multiSelect && checkIsAvailableInSelectedArray) || (props.selectedValue.id == item.id)
                          ? color.white : "#888888",

                        // props.selectedValue.id == item.id ? color.white : "#888888",
                        backgroundColor: (props.multiSelect && checkIsAvailableInSelectedArray) || (props.selectedValue.id == item.id)
                          ? color.themeDark : null,

                        // props.selectedValue.id == item.id ? color.themeDark : null,
                        paddingHorizontal: "1vw", paddingVertical: ".3vw"
                      }}>{item.value}</Text>
                    </TouchableOpacity>
                  )
                })
              }

            </ScrollView>
          </View> : null
      }
    </View>
  )
}

export const CommonSectionHeader = (props) => {
  return (
    <View style={[styles.SectionHeaderView, {backgroundColor: props.type == "sub" ? color.sectionHeadingLiteColor : color.sectionHeadingDarkColor}]}>
      {
        props.multiSelection ?
          props.multiSelectionValue.length > 0 && props.multiSelectionValue.map((item, index) => {
            return (
              <TouchableOpacity onPress={() => { props.onPressHeader(item) }}>
                <Text style={{
                  fontWeight: '500', fontSize: '1vw', marginLeft: '1.2vw', borderBottomColor: props.selectedSession == item ? color.themeDark : null,
                  borderBottomWidth: props.selectedSession == item ? 2 : null, color: props.selectedSession == item ? color.themeDark : color.black, paddingBottom: '0.3vw',

                }}>{item}</Text>
              </TouchableOpacity>
            )
          })
          :
          (
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
              <View style={{ flex: props.signOptions ? 0.5 : 1, alignContent: "center" }}>
                <Text style={styles.SectionHeaderText}>{props.heading}</Text>
              </View>
              {props.signOptions ? <View style={{ flex: 0.5, flexDirection: "row", justifyContent: "flex-end" }}><SignsInSubHeader {...props} onPressButton={props.onPressButton} /></View> : null}
            </View>
          )
      }
    </View>

  )
}



// export const CommonSectionHeader = (props) =>{
//   return(
//     <View style={{flexDirection:'row',height:35,backgroundColor:props.type == "sub" ?color.sectionHeadingLiteColor :color.sectionHeadingDarkColor,alignItems:'center'}}>
//       {
//         props.multiSelection ?
//         props.multiSelectionValue.length > 0 && props.multiSelectionValue.map((item,index)=>{
//           return(
//             <TouchableOpacity onPress={()=>{props.onPressHeader(item)}}>
//               <Text style={{fontWeight:'500',fontSize:15,marginLeft:20,color:props.selectedSession == item ? color.themeDark : color.black}}>{item}</Text>
//             </TouchableOpacity>
//           )
//         })

//         :
//         <Text style={{fontWeight:'500',fontSize:15,marginLeft:20}}>{props.heading}</Text>
//       }
//     </View>

//   )
// }

// export const CommonTextBox = (props) =>{
//   const isLocked = props.isLocked;
//   const multiline = props.multiline;
//   const placeholder = props.placeholder;
//   const placeholder = props.placeholder;
//   const placeholder = props.placeholder;
//   const textBoxkey = props.textBoxkey

//   return(
//     <TextInput
//         editable={isLocked}
//         multiline={key == "comments" ? true : false}
//         placeholder={placeholder}
//         underlineColorAndroid="transparent"
//         style={{
//           backgroundColor:isLocked ? color.disableComponentColor: "white",
//           marginRight: 20, fontSize: 14, borderRadius: 10, borderColor: color.rightPanelInputBorder, borderWidth: 1, width: width, height: key == "comments" ? 90 : 40, paddingTop: key == "comments" ? 10 : null, paddingLeft: "comments" ? 10 : null
//         }}
//         placeholderTextColor={color.textBoxBorderColor}
//         autoCapitalize="none"
//         value={value}
//         onChangeText={text => {
//           this.onChangeText(text, key)
//         }
//         }
//         onSubmitEditing={() => {
//           if (key == "brandName")
//             this.setState({ showNewButton: true },()=>{
//               this.setDrugnameAndChangeFlags(this.state.brandName)
//               // this.showDropDownDrugNameList(this.state.brandName);
//             })
//         }}
//       />
//   )
// }



export const CommonHeaderPicker = (props) => {
  // showPickerDropDownFlag={props.showPickerDropDownFlag}
  // pickerKey={props.}
  // selectedValue={props.selectedValue}
  // arrayOfData={props.}
  // console.log("1test  +++++++  2  " + JSON.stringify(props.showPickerDropDownFlag))
  // console.log("2test  +++++++  2  " + JSON.stringify(props.pickerKey))
  // console.log("3test  +++++++  2  " + JSON.stringify(props.selectedValue))
  // console.log("4test  +++++++  2  " + JSON.stringify(props.arrayOfData))
  return (
    <View >
      {/* <View style={{flexDirection:'row',alignSelf:'center',justifyContent:'center',alignContent:'center',alignItems:'center'}}> */}
      <TouchableOpacity onPress={() => {
        props.changePickerDropDownValue(!props.showPickerDropDownFlag, "", props.pickerKey)
      }} style={styles.Header_Filter_Box}>

        <Text style={styles.Header_Selected_DocName}>{props.selectedValue.value}</Text>


        <Image style={styles.DropDownIcon}
        source={ImagePath.Down} />

        {/* <Icon name="angle-down" size={14} color={"#888888"} /> */}
      </TouchableOpacity>
      {/* </View> */}
      {
        props.showPickerDropDownFlag ?
          <View style={{ position: 'absolute', marginTop: 34 }}>
            <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true}
              style={{ width: "11vw", borderColor: color.black, borderRadius: 8, borderWidth: 1, backgroundColor: color.white, maxHeight: 200 }}>
              {
                props.userType == "FO" || props.userType == Constants.ROLE_OPTOMETRY ?
                  <TouchableOpacity
                    onPress={() => {
                      var allOption = { "id": "", "value": "All", "serviceType": "" }
                      props.changePickerDropDownValue(!props.showPickerDropDownFlag, allOption, props.pickerKey)
                    }}>
                    <Text style={{
                      fontSize: "0.8vw",
                      color: props.selectedValue.value == "All" ? color.white : "#888888",
                      backgroundColor: props.selectedValue.value == "All" ? color.themeDark : null,
                      paddingHorizontal: 10, paddingVertical: 4
                    }}>{"All"}</Text>
                  </TouchableOpacity>
                  : null
              }
              {
                props.arrayOfData && props.arrayOfData.length > 0 && props.arrayOfData.map((item, index) => {
                  console.log(item,"item")
                  return (
                    <TouchableOpacity
                      key={index} onPress={() => {
                        props.changePickerDropDownValue(!props.showPickerDropDownFlag, item, props.pickerKey)
                      }} >
                      <Text style={{
                        fontSize: "0.8vw",
                        color: props.selectedValue.id == item.id ? color.white : "#888888",
                        backgroundColor: props.selectedValue.id == item.id ? color.themeDark : null,
                        paddingHorizontal: 10, paddingVertical: 4
                      }}>{item.value}</Text>
                    </TouchableOpacity>
                  )
                })
              }

            </ScrollView>
          </View> : null
      }
    </View>
  )
}

export const CommonHeaderPatientDetails = (props) => {
  console.log(">>>>>>>>>>>>>" +  props.currentPage)
  
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
      <TouchableOpacity onPress={() => {

        if (!props.isIpPatientCheckouted && (props.currentPage == "insurance" || props.currentPage == "dischargeSummary" ||
          props.currentPage == "admissionDetails" || props.currentPage == "patientCheckout" ||
          props.currentPage == "ipPatientCreateBill" || props.currentPage == "ipPatientSavedBill" || (props.isCurrentPageIsIP &&
            (props.currentPage == "demographics" || props.currentPage == "optDoctorNotes" ||
              props.currentPage == "reportsScreen" || props.currentPage == "ultraSoundAbdomenReport" ||
              props.currentPage == "obstetricSonographyReport" || (props.currentPage == "ipallTransactions")
            )
          ))

        ) {
          props.changePageOrNavigateTOAnotherPage("iPPatientList", true, "", true)
        }  else if (props.isIpPatientCheckouted && (props.currentPage == "insurance" || props.currentPage == "dischargeSummary" ||
        props.currentPage == "admissionDetails" || props.currentPage == "patientCheckout" ||
        props.currentPage == "ipPatientCreateBill"|| props.currentPage == "ipPatientSavedBill" || (props.isCurrentPageIsIP &&
          (props.currentPage == "demographics" || props.currentPage == "optDoctorNotes" ||
            props.currentPage == "reportsScreen" || props.currentPage == "ultraSoundAbdomenReport" ||
            props.currentPage == "obstetricSonographyReport" || (props.currentPage == "ipallTransactions")
          )
        ))

        ) {
          props.changePageOrNavigateTOAnotherPage("ipPatientCheckoutList", true, "", true)
        } else if (props.currentPage == "scheduleScreen") {
          props.changePageOrNavigateTOAnotherPage("doctorProfile", true)
        } else if (props.currentPage == "ipallTransactions") {
          props.changePageOrNavigateTOAnotherPage("ipallTransactions", true, "isCheckIpNumber")
        } else if (
          props.userType == "FO" && (props.currentPage == "laboratoryBillingTransaction" || props.currentPage == "laboratoryCreateBill")
        ) {
          // alert("billingScreen")
          props.changePageOrNavigateTOAnotherPage("billingScreen")
        } else if (
          props.currentPage == "laboratoryBillingTransaction" || props.currentPage == "laboratoryCreateBill" ||
          props.currentPage == "laboratoryPreviousResult" || props.currentPage == "laboratoryResult" || props.currentPage == "testConfig" || props.currentPage == "labRefferedDoctors" || props.currentPage == "referredReport" 
        ) {
          if(props.userType == "LAB"){
            props.changePageOrNavigateTOAnotherPage("laboratoryPatientList")
          }else {
            props.changePageOrNavigateTOAnotherPage("home", true)
          }
        } else if (props.currentPage == "demographics" && props.userType == "LAB") {
          props.changePageOrNavigateTOAnotherPage("laboratoryPatientList")
        } else if (props.currentPage == "ServiceDetailed" || props.currentPage == "PatientTypeReport" || props.currentPage == "ServiceOverview" || props.currentPage == "AppointmentTypeReport" || props.currentPage == "DayEndOverview"|| props.currentPage == "RefferalReport"  || props.currentPage == "RefferenceReport" || props.currentPage == "IPDoctorReport" || props.currentPage == "IPbillStatusReport" || props.currentPage === "ipAdvanceReport" || props.currentPage == "CorporatebillsReport" || props.currentPage == "PendingBillReport" || props.currentPage == "OpticalCRMSales") {
          props.changePageOrNavigateTOAnotherPage("Result")
        } else if(props.userType === "OT") {
          props.changePageOrNavigateTOAnotherPage("otAppointmentList")
        }
        else {
          props.changePageOrNavigateTOAnotherPage("home", true)

        }
      }}>
        {/* <Arrow
          name="arrow-left"
          size={24}
          color={color.white}
          onPress={() => {
            // this.props.navigation.goBack()
          }}
        /> */}
        <Image 
        source={ImagePath.BackArrow}
        style={styles.Header_BackIcon} />
      </TouchableOpacity>
      {
    
    (( props.currentPage === "ipallTransactions" && props.isCurrentPageIsIPPatientAppointment != true && Object.keys(props.modificationBillDetails).length === 0) || (props.currentPage == "businessIntelligence" ||  props.currentPage == "registry" || props.currentPage == "patientCheckout" || props.currentPage == "admissionDetails"|| props.currentPage == "insurance" || props.currentPage == "doctorProfile" || props.currentPage == "scheduleScreen" || props.currentPage == "demographics" || props.currentPage == "iPPatientList" || props.currentPage == "dischargeSummary" || props.currentPage == "testConfig" || props.currentPage == "labRefferedDoctors" || props.currentPage == "Result" || props.currentPage == "followUp" || props.currentPage == "referredReport" || props.currentPage == "otAppointmentList") || props.currentPage == "oTSurgeryNotesReport") ?
          <Text style={{ color: color.white, fontSize: "1.5vw", fontWeight: '500', marginHorizontal: 12 }}>{
            // props.currentPage == "scheduleScreen" ? "Set Schedule" :
            // props.currentPage == "doctorProfile" ? "Profile" :
            // props.currentPage == "Result"? "Report":
            (props.currentPage == "demographics" && props.patientInfo.name) || (props.currentPage == "demographics" && props.isCurrentPageIsIPPatientAppointment) ? "Demographics" :
              props.currentPage == "demographics" ? "Create Patient" :

                (props.currentPage == "businessIntelligence" || props.currentPage == "registry") ? "Registry" :
                  // props.currentPage == "iPPatientList" ? "IP" :
                  // props.currentPage == "dischargeSummary" ? "" :
                  // props.currentPage == "insurance" ? "" :
                  // props.currentPage == "admissionDetails" ? "" :
                  // props.currentPage == "patientCheckout" ? "" :
                  props.currentPage == "ipallTransactions" ? "" :
                  // props.currentPage == "allBillingTransactions" ? "" :
                  props.currentPage == "testConfig" ? "Test Config" :

                  props.currentPage == "labRefferedDoctors" ? "Referred Doctors" :
                    // props.currentPage == "ipPatientCreateBill" ? "" :
                  props.foReportType == "ServiceDetailed" ? "Service Detailed" :
                  props.currentPage == "followUp" ? "Follow-up" :
                  props.currentPage == "referredReport" ? "Report" :
                  props.currentPage == "oTSurgeryNotesReport" ? "Surgery Report" : 
                  ""}</Text>
          :
          Object.keys(props.patientInfo).length > 0 ?
            <Image source={props.patientInfo.photo_url ? { uri: props.patientInfo.photo_url } :ImagePath.DefaultProfile}
              style={styles.Header_Patient_Avatar} />
            : null
      }


      {
        (props.currentPage == "doctorProfile" || props.currentPage == "demographics" || props.currentPage == "testConfig" || props.currentPage == "labRefferedDoctors" || props.currentPage == "referredReport" || props.currentPage == "otAppointmentList") ?
          null :
          Object.keys(props.patientInfo).length > 0 ?
            <View>
              <Text  style={styles.Header_Patient_Name}>{props.patientInfo.name ? props.patientInfo.name:  props.patientInfo.patient_name }</Text>
              <View style={{ flexDirection: 'row' }}>
                {
                  props.patientInfo.age ?
                    <Text style={styles.Header_Patient_Age}>{props.patientInfo.age}</Text> : null
                }
                {console.log(props.patientInfo.age === "null" + props.patientInfo.age + "hello")}
                {
                  props.patientInfo.dob ?
                    <Text style={styles.Header_Patient_DOB}>{moment(props.patientInfo.dob).format("DD/MM/YYYY")}</Text> : null
                }
                {
                  props.patientInfo.patient_account_number || props.patientInfo.uhid ?
                    <Text style={styles.Header_Patient_Mobile}>{props.patientInfo.patient_account_number ? props.patientInfo.patient_account_number:props.patientInfo.uhid}</Text> : null
                }
                {
                  props.patientInfo.mobile_number ?
                    <Text style={styles.Header_Patient_Mobile}>{props.patientInfo.mobile_number}</Text> : null
                }
                {
                  props.patientInfo.last_visit_date ?
                    <Text style={styles.Header_Patient_LastVisit_Date}>{"Last Visited Date -"} {props.patientInfo.last_visit_date}</Text> : null
                }
              </View>
            </View> : null
      }
      {
        props.currentPage === "QuickBilling" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}> Quick Billing</Text> : 
        props.currentPage === "adminConfig" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}> Configuration</Text> :
        props.currentPage === "ServiceDetailed" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Service Detailed</Text> : 
        props.currentPage === "ServiceOverview" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Service Overview</Text> : 
        props.currentPage === "DayEndOverview" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Day End Overview</Text> : 
        props.currentPage === "PatientTypeReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Patient Type Report</Text> : 
        props.currentPage === "AppointmentTypeReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}> Appointment Type Report</Text> : 
        props.currentPage === "adminDashboard" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}> Dashboard</Text> :
        props.currentPage === "RefferalReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Referral Report</Text> : 
        props.currentPage === "RefferenceReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Referred Source Report</Text> :
        props.currentPage === "adminQmsConfiq" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Configuration</Text>:  
        props.currentPage === "IPDoctorReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>IP Doctor Report</Text> : 
        props.currentPage === "IPbillStatusReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>IP Current Bill Status Report</Text> :
        props.currentPage === "CorporatebillsReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Corporate Patient Bills Report</Text> :  
        props.currentPage === "OpticalCRMSales" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>OpticalCRMSales</Text> :
        props.currentPage === "ipAdvanceReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>IP Advance Payment Report</Text> : 
        props.currentPage === "PendingBillReport" ? <Text style={{ color: color.white, fontSize: '1.17vw', fontWeight: '500', marginHorizontal: 12 }}>Pending Bill Report</Text> : null

      }
    </View>
  )
}
export const EditAndDeleteIcon = (props) => {
  return (
    <TouchableOpacity onPress={() => { props.onPressEditOrdelete(props.iconKey) }}>
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 20,
          shadowOpacity: 0.2,
          shadowRadius: 4,
          shadowOffset: {
            height: 2,
            width: 2
          },
          elevation: 4,
          backgroundColor: "#FCFEFF",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20
        }}
      >
        <Icon name={props.image} color={props.image == "pencil" ? color.newPencilColor : props.image == "trash" ? color.newTrashColor : color.themeDark} size={15} />
      </View>
    </TouchableOpacity>
  );
};
export const HistoryYesOrNoSection = (props) => {
  return (
    // noTextBoxLabel
    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius]}>

      <View style={[Style.historyYesOrNOSectionRowView, Style.allButtonBorderRadius]}>
        <Text style={{ marginLeft: 20, fontSize: 14 }}>{props.label}</Text>
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => { props.onChangeYesOrNoValue(true, props.detailsOfYesValue, props.sectionKey) }}
            style={[Style.historyYoButtonView, props.selectedValue ? Style.historyYesButtonSelectedValue : null
            ]}>
            <Text style={{ fontSize: 14, color: props.selectedValue ? color.white : color.black }}>{"Yes"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.onChangeYesOrNoValue(false, "", props.sectionKey)
            }}
            style={[Style.historyNoButtonView, !props.selectedValue ? Style.historyNoButtonSelectedValue : null
            ]}>
            <Text style={{ fontSize: 14, color: !props.selectedValue ? color.white : color.black }}>{"No"}</Text>
          </TouchableOpacity>

        </View>
      </View>

      {
        (props.selectedValue && props.showDetailsText) || (!props.selectedValue && props.showNoTextBox && props.noTextBoxLabel) ?

          <View style={{ marginTop: 5, marginBottom: 20 }}>
            {
              !props.selectedValue && props.showNoTextBox && props.noTextBoxLabel ?
                <Text style={{ fontSize: 14, marginLeft: 20, }}>{props.noTextBoxLabel}</Text> : null
            }
            <TextInput
              value={props.detailsOfYesValue}
              style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { backgroundColor: color.white, height: 40, width: "85%", alignSelf: 'center', marginTop: 10, padding: 10, }]}
              multiline={true}
              maxLength={props.maxLength ? props.maxLength : 100}
              placeholder={props.placeholder}
              onChangeText={(text) => {
                props.onChangeYesOrNoValue(props.selectedValue, text, props.sectionKey)
              }}
            />
          </View>

          : null
      }


    </View>
  )
}


export const CommonHeader = (props) => {

var loggedInDataObj = null;
console.log(props.currentPage,"");
AsyncStorage.getItem("UserID",(err, response) => {
  if(response){
    loggedInDataObj = JSON.parse(response)
  }else{
    console.log("Error")
  }
})


  return (
    <View style={styles.Header_MainBar}>
      <View style={{ width: "5.85%", borderRadius: 4 }}>
        <Image style={{ height: "90%", width: "90%", justifyContent: 'center' }} resizeMode='contain' 
          source = {ImagePath.Logo}
         />
      </View>
      <View style={{ width: "94.15%", backgroundColor: color.themeDark, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>



        {
          props.currentPage == "home"  && props.userType != "COUNSELLOR"?
            <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: color.white, borderRadius: 4, marginLeft: "1.30vw" }}>
              < CommonHeaderPicker   //CommonPicker
                showPickerDropDownFlag={props.showPickerDropDownFlag}
                pickerKey={props.pickerKey}
                selectedValue={props.selectedValue}
                arrayOfData={props.arrayOfData}
                changePickerDropDownValue={props.changePickerDropDownValue}
                {...props}
              />
              <CommonHeaderSearch
                searchValue={props.searchValue}
                searchPatient={props.searchPatient}
                searchPatientTextChange={props.searchPatientTextChange}
                userType={props.userType}
              />
              <SearchFilter FilterSearch={props.FilterSearch}
              userType = {props.userType} />
              <HomeScreenCameraIcon openCameraModal={props.openCameraModal}
              userType = {props.userType} />
            </View>
            :
            props.currentPage == "iPPatientList" || props.currentPage == "laboratoryPatientList" || props.currentPage == "ipPatientCheckoutList" ?
              <View>

                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: "0.65vw" }}>
                  {
                    props.currentPage == "laboratoryPatientList" || props.currentPage == "otAppointment"?
                      null
                      :
                      <TouchableOpacity onPress={() => { props.changePageOrNavigateTOAnotherPage("home") }}>
                        {/* <Arrow
                      name="arrow-left"
                      size={24}
                      color={color.white}
                      onPress={() => {
                        // this.props.navigation.goBack()
                      }}
                    /> */}
                        <Image
                        source={ImagePath.BackArrow}
                         style={styles.Header_BackIcon}/>
                      </TouchableOpacity>
                  }


                  <Text style={{ color: color.white, fontSize: "1.5vw", fontWeight: '500', marginHorizontal: "0.8vw" }}>{props.currentPage == "iPPatientList" || props.currentPage == "ipPatientCheckoutList" ? "IP" : props.currentPage == "laboratoryPatientList" ? "Laboratory" : ""}</Text>




                  <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: color.white, borderRadius: 4, marginLeft: "1.30vw", height: "2vw", }}>

                    <CommonHeaderSearch
                      {...props}
                      searchValue={props.searchValue}
                      searchPatient={props.searchPatient}
                      searchPatientTextChange={props.searchPatientTextChange}
                    />
                    {/* <HomeScreenCameraIcon /> */}
                  </View>
                </View>
              </View>
              // null

              :
              props.userType != "COUNSELLOR" && props.userType != "SUPER_ADMIN" && props.currentPage != "otAppointmentList" && props.currentPage != "otVendor" && props.currentPage != "stockUsage" && props.currentPage != "otInventory"  && props.userType != "BRANCH_ADMIN"  ?
                <CommonHeaderPatientDetails
                {...props}
                currentPage={props.currentPage}
                modificationBillDetails = {props.modificationBillDetails}
                changePageOrNavigateTOAnotherPage={props.changePageOrNavigateTOAnotherPage}
                patientAppointment={props.patientAppointment}
                patientInfo={props.patientInfo} />
                : 
              <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 30 }}></View>

        }



        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          {
            props.currentPage == "home" ?
              <View style={{ flexDirection: 'row', }}>

                {
                  props.userType == "FO" && props.isFoAdmin == "true" ?
                    <HomeScreenHeaderOption  {...props} buttonKey="qmsSettingsButton" qmsSettingsButton={props.qmsSettingsButton} 
                    image={ImagePath.UnlockedDoctorNotes}
                    label={"Settings"} subLabel={""} />
                    : null
                }

                {
                  props.userType == "FO" || props.userType == "OPTOMETRY" || props.userType == "COUNSELLOR" ? null : <HomeScreenHeaderOption buttonKey="unLockDoctorNotes" unLockDoctorNotes={props.unLockDoctorNotes} 
                  image={ImagePath.UnlockedDoctorNotes}
                   label={"Unlocked"} subLabel={"Doctor Notes"} />}
                {
                  props.userType == "OPTOMETRY" || props.userType == "COUNSELLOR" ? null : <HomeScreenHeaderOption buttonKey="apppointmentRequests" apppointmentRequests={props.apppointmentRequests} 
                  image={ImagePath.AppointmentRequest}
                   label={"Appointment"} subLabel={"Requests"} />}
                {
                  props.userType == "OPTOMETRY" || props.userType == "COUNSELLOR" ? null : <HomeScreenHeaderOption buttonKey="print" printAppointmentListToken={props.printAppointmentListToken}
                   image={ImagePath.Print}
                    label={"Print"} subLabel={"Appointments"} userType={props.userType} isOptClinic={props.isOptClinic} isPrintAppointment = {props.isPrintAppointment} />}
                {/* {
                  props.userType == "FO" || props.userType == "OPTOMETRY" || props.userType == "COUNSELLOR" ? null :
                    <HomeScreenHeaderOption buttonKey="biAndRegistry" biAndRegistry={props.biAndRegistry} 
                    image={ImagePath.BIRegistry}
                    label={""} subLabel={"Registry"} />
                } */}

              </View>
              :
              props.currentPage == "registry" && (props.userType !== "FO" && props.userType !== "OPTOMETRY") ?
              <HomeScreenHeaderOption buttonKey="exportCSV" exportRegistry={props.exportRegistry}
               image={ImagePath.Export}
               label={"Export"} subLabel={""} />
              :
              (props.currentPage == "optDoctorNotes" || props.currentPage == "CollabSummary") && (props.userType !== "FO" && props.userType !== "OPTOMETRY") ?
              <View style={{ flexDirection: 'row' }}>
                { (props.role_Type == "DOCTOR" && props.currentPage == "optDoctorNotes" || props.currentPage =="CollabSummary" )?
              <HomeScreenHeaderOption buttonKey="CollabSummary" currentPage={props.currentPage} CollabReportScreen={props.CollabReportScreen} image={require(props.currentPage =="CollabSummary" ?  '../../../assets/images/Medical_Report_Outline.svg' : '../../../assets/images/Medical_Report_Fill.svg')} label={"Collaborative "} subLabel={"Summary"} />: null}
              <HomeScreenHeaderOption buttonKey="viewReport" currentPage={props.currentPage} userType={props.userType} viewReportDRnotesRequests={props.viewReportDRnotesRequests} image={require('../../../assets/images/HomeScreenImages/Print.png')} label={"Print Report"} subLabel={""} />
              { props.role_Type == "DOCTOR" ?
              <HomeScreenHeaderOption buttonKey="referalNotes" referalNotes={props.referalNotes} image={require('../../../assets/images/HomeScreenImages/ApppointmentRequests.png')} label={"Referral Notes"} subLabel={""} />: null}
              
              </View>
              :
              props.currentPage == "antenatalChart" && (props.userType === "OBGYN" ) ?
              <View style={{ flexDirection: 'row' }}>
              <HomeScreenHeaderOption buttonKey="OBGYNPRINT" userType={props.userType} obgynCommonPrint={props.obgynCommonPrint} image={require('../../../assets/images/HomeScreenImages/Print.png')} label={"Print"} subLabel={""} />
              </View> :
              props.currentPage == "dbCommonHistory" && (props.userType !== "FO" && props.userType !== "OPTOMETRY") ?
              <View>
              <HomeScreenHeaderOption buttonKey="viewReport" currentPage={props.currentPage} userType={props.userType} viewReportDRnotesRequests={props.viewReportDRnotesRequests} image={require('../../../assets/images/HomeScreenImages/Print.png')} label={"Print Report"} subLabel={""} />              
              </View>
              :
              props.currentPage === "immunization"?
                <HomeScreenHeaderOption buttonKey="immunizationPrint" immunizationPrint={props.immunizationPrint}
                 image={ImagePath.Print}
                 label={"Print"} subLabel={""} /> 
              :
                props.currentPage == "ipallTransactions" ?
                  <HomeScreenHeaderOption
                    buttonKey="ipBillingTxnPrint"
                    {...props}
                    printAppointmentListToken={props.printAppointmentListToken}
                    // image={require('../../../assets/images/HomeScreenImages/Print.png')}
                    // label={"Print"}
                    subLabel={""} />
                  :
                  props.currentPage == "ipallTransactions" ?
                    <HomeScreenHeaderOption
                      buttonKey="ipBillingTxnPrint"
                      {...props}
                      printAppointmentListToken={props.printAppointmentListToken}
                      // image={require('../../../assets/images/HomeScreenImages/Print.png')}
                      // label={"Print"}
                      subLabel={""} />
                    :
                    props.currentPage == "dischargeSummary" ?
                      <HomeScreenHeaderOption
                        buttonKey="dischargeSummaryPrint"
                        {...props}
                      // printAppointmentListToken={props.printAppointmentListToken} 
                      //image={require('../../../assets/images/HomeScreenImages/Print.png')}
                      // label={"Print"}
                      // subLabel={""} 
                      />
                      :

                      props.userType == "LAB" ?
                        <View style={{ flexDirection: 'row' }}>
                          {/* labUserAccessType */}
                          {/* {
                          props.labUserAccessType == "isAdmin" ?
                            <HomeScreenHeaderOption
                              buttonKey="labReportHomePage"
                              labReportHomePage={props.labReportHomePage}
                              image={require('../../../assets/images/HomeScreenImages/View_Profile.png')}
                              label={"Report"}
                              subLabel={""} />
                            : null
                        } */}

                          {
                            props.labUserAccessType == "isAdmin" ?
                              <HomeScreenHeaderOption
                                buttonKey="labRefferedDoctors"
                                labRefferedDoctors={props.labRefferedDoctors}
                                image={ImagePath.ReferredDocConfig}
                                label={"Referred"}
                                subLabel={"Doctors"} />
                              : null
                          }

                          {
                            props.labUserAccessType == "isAdmin" ?
                              <HomeScreenHeaderOption
                                buttonKey="labTestConfig"
                                labTestConfig={props.labTestConfig}
                                image={ImagePath.TestConfig}
                                label={"Test Config"}
                                subLabel={""} />
                              : null
                          }

                          {
                            props.labUserAccessType == "isAdmin" || props.labUserAccessType == "isBillingAndReport" || props.labUserAccessType == "isBilling" ?
                              <View style={{ flexDirection: "row" }} >
                                <HomeScreenHeaderOption
                                  buttonKey="labSavedBills"
                                  labSavedBills={props.labSavedBills}
                                  image={ImagePath.SavedBill}
                                  label={"Pending Bills"}
                                  subLabel={""} />
                                <View style={{ position: "absolute", marginLeft: 15, borderRadius: 10 }}>
                                  <Text style={{ color: color.white, fontSize: 10, backgroundColor: color.red }}>{props.showLabSavedBillList.length}</Text>
                                </View>
                              </View>
                              : null
                          }
                          {
                            props.currentPage == "laboratoryCreateBill" ?
                              <HomeScreenHeaderOption
                                buttonKey="Print"

                                image={ImagePath.PrintActive}
                                label={"Print"}
                                subLabel={""} />
                              : null
                          }
                          {
                            props.labUserAccessType == "isAdmin" || props.labUserAccessType == "isBillingAndReport" || props.labUserAccessType == "isBilling" ?
                              <HomeScreenHeaderOption
                                buttonKey="labTransmitedChanges"
                                labTransmitedChanges={props.labTransmitedChanges}
                                image={ImagePath.TransmittedOrders}
                                label={"Orders &"}
                                subLabel={"Appointments"} />
                              : null
                          }

                        </View>
                        :
                        null
          }
          {
            props.userType == "FO" && props.currentPage == "home"?
              <HomeScreenHeaderOption
                buttonKey="labTransmitedChanges"
                labTransmitedChanges={props.labTransmitedChanges}
                image={ImagePath.TransmittedOrders}
                label={"Orders &"}
                subLabel={"Appointment"} />

              :
              null
          }

          <View style={{ flexDirection: "row" }}>
            {/* {
              props.currentPage == "dischargeSummary" || props.currentPage == "insurance"||props.currentPage == "ipPatientCreateBill"||props.currentPage == "ipallTransactions"||props.currentPage == "patientCheckout" ?
                <CommonHeaderIPPatientDetails {...props} />
                :
                null
            } */}


            {
              props.currentPage == "psychiatryHistory" || props.currentPage == "peadiatricMentalHealth" || props.currentPage == "substanceAbuse" ?
                <View style={{ marginLeft: 50 }}>
                  <TouchableOpacity
                    onPress={() => {


                    }} style={{ backgroundColor: color.themeBackgroud, borderRadius: 4, marginLeft: 10 }}
                  >
                    <Image 
                    source={ImagePath.Print}
                     style={{ height: 24, width: 24 }} />
                    <Text style={{ color: color.white, textAlign: 'center', fontSize: 10, fontWeight: '500' }}>{"Print"}</Text>
                  </TouchableOpacity>
                </View> : null
            }

            {
              props.currentPage == "ipPatientCheckoutList" ?
                <HomeScreenHeaderOption
                  buttonKey="ipPatientCheckoutListPrint"
                  printIpPatientCheckoutList={props.printIpPatientCheckoutList}
                  image={ImagePath.Print}
                  label={"Print"}
                  subLabel={""} />

             
                : null
            }
             { props.userType == "FO" && (props.currentPage == "ipPatientCreateBill" || props.currentPage == "ipPatientSavedBill" || props.currentPage == "ipallTransactions" || props.currentPage == "otbills" || props.currentPage == "estimationBill") && props.isAdminAccess ?
                  <HomeScreenHeaderOption buttonKey="creditBillLimit" key = "creditBillLimit"
                   image={ImagePath.Setting}
                   label={"Credit Limit"} subLabel={""} creditBillLimit = {props.creditBillLimit} />
              : null}
            {
              props.currentPage == "iPPatientList" ?
                <HomeScreenHeaderOption
                  buttonKey="ipPatientListPrint"
                  ipPatientListPrint={props.ipPatientListPrint}
                  image={ImagePath.Print}
                  label={"Print"}
                  subLabel={""} /> : null
            }

            <CommonHeaderProfileField
              logoutFunction={props.logoutFunction}
              profileDropDownFlagChange={props.profileDropDownFlagChange}
              showProfileDropDownFlag={props.showProfileDropDownFlag}
              doctorName={props.doctorName}
              doctorProfilePhoto={props.doctorProfilePhoto}
              userType={props.userType}
              {...props}

              changePageOrNavigateTOAnotherPage={props.changePageOrNavigateTOAnotherPage}

              defaultImage={ImagePath.DefaultProfile}/>
          </View>
        </View>
      </View>
    </View>
  )
};


export const CommonHeaderSearch = (props) => {

  // searchValue
  // this.state.searchBar

  return (
    // <View>
    props.userType == "OPTOMETRY" ? null:
    <View style={[{
      paddingLeft: props.currentPage == "laboratoryPatientList" ? "0.65vw" : "1vw",
      paddingRight: props.currentPage == "laboratoryPatientList" ? "0.65vw" : "1.62vw",
      // height: 20,
      flex: 1,
      borderRightColor: props.currentPage == "laboratoryPatientList" || props.currentPage == "iPPatientList" || props.currentPage == "ipPatientCheckoutList" ? color.white : "#888888",
      borderRightWidth: 0.5,
      flexDirection: props.currentPage == "laboratoryPatientList" ? "row" : null
    }, (props.currentPage == "iPPatientList" || props.currentPage == "ipPatientCheckoutList" || props.currentPage == "laboratoryPatientList") ? null : { borderLeftColor: "#888888", borderLeftWidth: 0.5, }]}>

      <View style={{  flexDirection:"row", marginRight:"0.32vw" }}>
        <TextInput
          value={props.searchValue}
          placeholder="Search Patient..."
          onChangeText={(text) => { props.searchPatientTextChange(text) }}
          style={styles.Header_Search_Text}
          onKeyPress={(e) => {e.key === "Enter" ? props.searchPatient() : null}}
        />
        <TouchableOpacity
          onPress={() => {
            props.searchPatient()
          }} 
          style={{
            backgroundColor: color.white
          }}>
          <Icon name="search" style={styles.SearchIcon} color={color.themeDark} />

        </TouchableOpacity>
      </View>
    </View>
  
  )
}


export const HomeScreenCameraIcon = (props) => {
  return (
    props.userType =="OPTOMETRY" ? null : 
    <TouchableOpacity style={{ borderTopRightRadius: 4, borderBottomRightRadius: 5, paddingHorizontal: 10 }}
      onPress={() => props.openCameraModal("detect")}>
      <Image style={styles.CameraIcon} 
      source={ImagePath.Camera}
      />
    </TouchableOpacity>
  )
}
export const SearchFilter = (props) => {
  return (
    <TouchableOpacity style={{ borderRightColor: "#888888",borderRightWidth: 0.5 , paddingHorizontal: 10 }}
      onPress={() => props.FilterSearch()}
      >
      <Icon name="filter" style={{fontSize:"1.2vw"}} color={color.themeDark} />
    </TouchableOpacity>
  )
}


export const HomeScreenHeaderOption = (props) => {
 
  const [isPrintClick,setPrintClick] = useState(false)
  const [isAll,setAll] = useState(true)
  const [isArrived,setArrived] = useState(false)
  const [isWithNurse,setWithNurse] = useState(false)
  const [isWithDoctor,setWithDoctor] = useState(false)
  const [isCompleted,setCompleted] = useState(false)
  const [isCancelled,setCancelled] = useState(false)
  const [isBooked, setBooked] = useState(false)
  const [isOptometry, setOptometry] = useState(false)
  const [isScan, setScan] = useState(false)

  const [isviewReportClick,setviewReportClick] = useState(false)
  const [isReportAll,setReportAll] = useState(false)
  const [isComplaints, setComplaints] = useState(true)
  const [isOcularHistory, setOcularHistory] = useState(true)
  const [isSystemicIllness, setSystemicIllness] = useState(true)
  const [isVitals, setVitals] = useState(false)
  const [isVisionAssesment, setVisionAssesment] = useState(true)
  const [isGonioscopy, setGonioscopy] = useState(true)
  const [isAutoRefract, setAutoRefract] = useState(false)
  const [isPGP, setPGP] = useState(false)
  const [isSubjectiveRefraction, setSubjectiveRefraction] = useState(false)
  const [isASR, setASR] = useState(false)
  const [isIOP, setIOP] = useState(true)
  const [isAnteriorSeg, setAnteriorSeg] = useState(true)
  const [isPosteriorSeg, setPosteriorSeg] = useState(true)
  const [isFundus, setFundus] = useState(false)
  const [isDiagnosis, setDiagnosis] = useState(true)
  const [isMedication, setMedication] = useState(true)
  const [isGlassPrescription, setGlassPrescription] = useState(true)
  const [isReviewPlan, setReviewPlan] = useState(true)
  const [isTreatmentPlan, setTreatmentPlan] = useState(true)
  const [isConselling, setConselling] = useState(true)
  const [isInvestigation, setInvestigation] = useState(true)

  const [isSymptoms, setSymptoms] = useState(true)
  const [isAllergy, setAllergy] = useState(false)
  const [isHOPI, setHOPI] = useState(false)
  const [isExamination, setExamination] = useState(true)
  const [isMentalStatusExamination, setMentalStatusExamination] = useState(false)
  const [isAssessment, setAssessment] = useState(true)
  const [isPrescription, setPrescription] = useState(true)
  const [isPreviousPrescription, setPreviousPrescription] = useState(false)
  const [isTest, setTest] = useState(false)
  const [isPlan, setPlan] = useState(false)
  const [isGeneralPatientAdvice, setGeneralPatientAdvice] = useState(true)
  const [isCreditBillLimit, setCreditBillLimit] = useState(false)

  var checkBoxList = []
  if (props.buttonKey == "print") {
  if(props.userType === "OPT" || props.isOptClinic){
    checkBoxList = [
      { label: "All", value: isAll },
      { label: "Booked", value: isBooked },
      { label: "Arrived", value: isArrived },
      { label: "Optometry", value: isOptometry },
      { label: "With Nurse" , value: isWithNurse},
      { label: "With Doctor", value: isWithDoctor },
      { label: "Scan/Surgery", value: isScan },
      { label: "Completed", value: isCompleted },
      { label: "Cancelled", value: isCancelled },
  
    ]
  }else{
    checkBoxList = [
      { label: "All", value: isAll },
      { label: "Booked", value: isBooked },
      { label: "Arrived", value: isArrived },
      { label: "With Nurse" , value: isWithNurse},
      { label: "With Doctor", value: isWithDoctor },
      { label: "Completed", value: isCompleted },
      { label: "Cancelled", value: isCancelled },
  
    ]
  }
  }else if(props.buttonKey == "viewReport"){
    if(props.userType === "OPT"){
    checkBoxList = [
      { label: "Full Report", value: isReportAll },
      { label: "Complaints", value: isComplaints },
      { label: "Ocular History", value: isOcularHistory },
      { label: "Systemic Illness", value: isSystemicIllness},
      { label: "Vitals", value: isVitals },
      { label: "Vision Assessment", value: isVisionAssesment },
      { label: "Gonioscopy", value: isGonioscopy },
      { label: "Auto Refractometry", value: isAutoRefract },
      { label: "Previous Glass Power", value: isPGP },
      { label: "Subjective Refraction", value: isSubjectiveRefraction },
      { label: "Retinoscopic Refraction", value: isASR },
      { label: "Intraocular Pressure", value: isIOP },
      { label: "Anterior Segment", value: isAnteriorSeg },
      { label: "Posterior Segment", value: isPosteriorSeg },
      { label: "Fundus", value: isFundus },
      { label: "Diagnosis", value: isDiagnosis },
      { label: "Medication", value: isMedication },
      { label: "Glass Prescription", value: isGlassPrescription },
      { label: "Review Plan", value: isReviewPlan },
      { label: "Treatment Plan", value: isTreatmentPlan },
      { label: "Counselling", value: isConselling },
      { label: "Investigation", value: isInvestigation },
    ]}
    else if (props.userType === "PSY"){
      
      checkBoxList = [
        { label: "Full Report", value: isReportAll },
        { label: "Symptoms", value: isSymptoms },
        { label: "Allergies", value: isAllergy },
        { label: "HOPI", value: isHOPI},
        { label: "Vitals", value: isVitals },
        { label: "Examination", value: isExamination },
        { label: "Mental Status Examination", value: isMentalStatusExamination},
        { label: "Diagnosis", value: isDiagnosis },
        { label: "Prescription", value: isPrescription },
        { label: "Previous Prescription",value: isPreviousPrescription},
        { label: "Test", value: isTest },
        { label: "Plan", value: isPlan },
        { label: "General-Patient Advice", value: isGeneralPatientAdvice }
      ]
    }
    else {
      checkBoxList = [
        { label: "Full Report", value: isReportAll },
        { label: "Symptoms", value: isSymptoms },
        { label: "Allergies", value: isAllergy },
        { label: "Vitals", value: isVitals },
        { label: "Examination", value: isExamination },
        { label: "Diagnosis", value: isDiagnosis },
        { label: "Prescription", value: isPrescription },
        { label: "Previous Prescription", value: isPreviousPrescription },
        { label: "Test", value: isTest },
        { label: "Plan", value: isPlan },
        { label: "General-Patient Advice", value: isGeneralPatientAdvice },
        { label: "Counselling", value: isConselling },
      ]
    }
  }

  const renderCheckBoxView = () =>{
    return(
      <View>
        { checkBoxList && checkBoxList.map((item,index)=>{
          return(
            <View style={{flexDirection:"row",  padding: 5 }}>
             <CheckBox  style={{ marginRight: ".8vw", width: ".95vw", height: ".95vw",alignSelf: "center", fontSize:"1vw" }} text={ item.label } value={ item.value }
            //  <CheckBox style={{ alignSelf: "center", fontSize:"1vw"}} text={ item.label } value={ item.value }
              onChange={() =>
                {onChangeValue(item.label)}
              } />
              <Text style={{marginLeft:10, fontSize:"0.9vw"}}>{item.label}</Text>
            </View>
          )
          
            })
          }     
      </View>
    )
  }
  // useEffect({
  // },[ isArrived, isWithDoctor, isCompleted, isCancelled, isBooked])
  const onChangeValue = (key) => {
    if(key == "All"){
      setAll(!isAll)
    }else if(key == "Arrived"){
      setArrived(!isArrived)
      setAll(false)
    }else if(key == "With Nurse"){
      setWithNurse(!isWithNurse)
      setAll(false)
    }else if(key == "With Doctor"){
      setWithDoctor(!isWithDoctor)
      setAll(false)
    }else if(key == "Completed"){
      setCompleted(!isCompleted)
      setAll(false)
    }else if(key == "Cancelled"){
      setCancelled(!isCancelled)
      setAll(false)
    }else if(key == "Booked"){
      setBooked(!isBooked)
      setAll(false)
    }else if(key == "Optometry"){
      setOptometry(!isOptometry)
      setAll(false)
    }else if(key == "Scan/Surgery"){
      setScan(!isScan)
      setAll(false)
    }else if(key == "Full Report"){
      if(isReportAll){
        setReportAll(!isReportAll)
        setComplaints(false)
        setOcularHistory(false)
        setSystemicIllness(false)
        setVitals(false)
        setVisionAssesment(false)
        setGonioscopy(false)
        setAutoRefract(false)
        setPGP(false)
        setSubjectiveRefraction(false)
        setASR(false)
        setIOP(false)
        setAnteriorSeg(false)
        setPosteriorSeg(false)
        setFundus(false)
        setDiagnosis(false)
        setMedication(false)
        setGlassPrescription(false)
        setReviewPlan(false)
        setTreatmentPlan(false)
        setConselling(false)
        setInvestigation(false)

        setVitals(false)
        setSymptoms(false)
        setAllergy(false)
        setHOPI(false)
        setExamination(false)
        setMentalStatusExamination(false)
        setPrescription(false)
        setPreviousPrescription(false)
        setTest(false)
        setPlan(false)
        setGeneralPatientAdvice(false)
      }else{
        setReportAll(!isReportAll)
        setComplaints(true)
        setOcularHistory(true)
        setSystemicIllness(true)
        setVitals(true)
        setVisionAssesment(true)
        setGonioscopy(true)
        setAutoRefract(true)
        setPGP(true)
        setSubjectiveRefraction(true)
        setASR(true)
        setIOP(true)
        setAnteriorSeg(true)
        setPosteriorSeg(true)
        setFundus(true)
        setDiagnosis(true)
        setMedication(true)
        setGlassPrescription(true)
        setReviewPlan(true)
        setTreatmentPlan(true)
        setConselling(true)
        setInvestigation(true)

        setVitals(true)
        setSymptoms(true)
        setAllergy(true)
        setHOPI(true)
        setExamination(true)
        setMentalStatusExamination(true)
        setPrescription(true)
        setPreviousPrescription(true)
        setTest(true)
        setPlan(true)
        setGeneralPatientAdvice(true)
      }
    }else if(key == "Complaints"){
      setComplaints(!isComplaints)
      setReportAll(false)
    }else if(key == "Ocular History"){
      setOcularHistory(!isOcularHistory)
      setReportAll(false)
    }else if(key == "Systemic Illness"){
      setSystemicIllness(!isSystemicIllness)
      setReportAll(false)
    }else if(key == "Vitals"){
      setVitals(!isVitals)
      setReportAll(false)
    }else if(key == "Vision Assessment"){
      setVisionAssesment(!isVisionAssesment)
      setReportAll(false)
    }else if(key == "Gonioscopy"){
      setGonioscopy(!isGonioscopy)
      setReportAll(false)
    }
    else if(key == "Auto Refractometry"){
      setAutoRefract(!isAutoRefract)
      setReportAll(false)
    }else if(key == "Previous Glass Power"){
      setPGP(!isPGP)
      setReportAll(false)
    }else if(key == "Subjective Refraction"){
      setSubjectiveRefraction(!isSubjectiveRefraction)
      setReportAll(false)
    }else if(key == "Retinoscopic Refraction"){
      setASR(!isASR)
      setReportAll(false)
    }else if(key == "Intraocular Pressure"){
      setIOP(!isIOP)
      setReportAll(false)
    }else if(key == "Anterior Segment"){
      setAnteriorSeg(!isAnteriorSeg)
      setReportAll(false)
    }else if(key == "Posterior Segment"){
      setPosteriorSeg(!isPosteriorSeg)
      setReportAll(false)
    }else if(key == "Fundus"){
      setFundus(!isFundus)
      setReportAll(false)
    }else if(key == "Diagnosis"){
      setDiagnosis(!isDiagnosis)
      setReportAll(false)
    }else if(key == "Medication"){
      setMedication(!isMedication)
      setReportAll(false)
    }else if(key == "Glass Prescription"){
      setGlassPrescription(!isGlassPrescription)
      setReportAll(false)
    }else if(key == "Review Plan"){
      setReviewPlan(!isReviewPlan)
      setReportAll(false)
    }else if(key == "Treatment Plan"){
      setTreatmentPlan(!isTreatmentPlan)
      setReportAll(false)
    }else if(key == "Counselling"){
      setConselling(!isConselling)
      setReportAll(false)
    }else if(key == "Investigation"){
      setInvestigation(!isInvestigation)
      setReportAll(false)
    }

    else if(key == "Symptoms"){
      setSymptoms(!isSymptoms)
      setReportAll(false)
    }
    else if(key == "Allergies"){
      setAllergy(!isAllergy)
      setReportAll(false)
    }
    else if(key == "HOPI"){
      setHOPI(!isHOPI)
      setReportAll(false)
    }
    else if(key == "Examination"){
      setExamination(!isExamination)
      setReportAll(false)
    }
    else if(key == "Mental Status Examination"){
      setMentalStatusExamination(!isMentalStatusExamination)
      setReportAll(false)
    }
    // else if(key == "Diagnosis"){
    //   setAssessment(!isAssessment)
    //   setReportAll(false)
    // }
    else if(key == "Prescription"){
      setPrescription(!isPrescription)
      setReportAll(false)
    }
    else if(key == "Previous Prescription"){
      setPreviousPrescription(!isPreviousPrescription)
      setReportAll(false)
    }
    else if(key == "Test"){
      setTest(!isTest)
      setReportAll(false)
    }
    else if(key == "Plan"){
      setPlan(!isPlan)
      setReportAll(false)
    }
    else if(key == "General-Patient Advice"){
      setGeneralPatientAdvice(!isGeneralPatientAdvice)
      setReportAll(false)
    } 
  }

  const defaultReportCheckCheckBox = () => {
    setReportAll(false)
    setComplaints(true)
    setOcularHistory(true)
    setSystemicIllness(true)
    setVitals(false)
    setVisionAssesment(true)
    setAutoRefract(false)
    setPGP(false)
    setSubjectiveRefraction(false)
    setASR(false)
    setIOP(true)
    setAnteriorSeg(true)
    setPosteriorSeg(true)
    setFundus(false)
    setDiagnosis(true)
    setMedication(true)
    setGlassPrescription(true)
    setReviewPlan(true)
    setTreatmentPlan(true)
    setConselling(true)
    setInvestigation(true)

    setVitals(true)
    setSymptoms(true)
    setAllergy(false)
    setHOPI(false)
    setExamination(true)
    setMentalStatusExamination(false)
    setPrescription(true)
    setPreviousPrescription(false)
    setTest(false)
    setPlan(false)
    setGeneralPatientAdvice(true)
  }

  const clearReportCheckCheckBox = () => {
    setviewReportClick(false)
    setReportAll(false)
    setComplaints(true)
    setOcularHistory(true)
    setSystemicIllness(true)
    setVitals(false)
    setVisionAssesment(true)
    setAutoRefract(false)
    setPGP(false)
    setSubjectiveRefraction(false)
    setASR(false)
    setIOP(true)
    setAnteriorSeg(true)
    setPosteriorSeg(true)
    setFundus(false)
    setDiagnosis(true)
    setMedication(true)
    setGlassPrescription(true)
    setReviewPlan(true)
    setTreatmentPlan(true)
    setConselling(true)
    setInvestigation(true)

    setVitals(true)
    setSymptoms(true)
    setAllergy(false)
    setHOPI(false)
    setExamination(true)
    setMentalStatusExamination(false)
    setPrescription(true)
    setPreviousPrescription(false)
    setTest(false)
    setPlan(false)
    setGeneralPatientAdvice(true)
  }
 
  const clearCheckBox = () => {
    setPrintClick(false)
    setArrived(false)
    setBooked(false)
    setWithNurse(false)
    setWithDoctor(false)
    setCompleted(false)
    setCancelled(false)
    setOptometry(false)
    setScan(false)
  }

  const renderReportCheckView = () => {
    return(
      <ScrollView showsVerticalScrollIndicator={true} style={{maxHeight: "50vh"}}>
        { checkBoxList && checkBoxList.map((item,index)=>{
          return(
            <View style={{flexDirection:"row",  padding: 5 }}>
             <CheckBox  style={{ marginRight: ".8vw", width: ".95vw", height: ".95vw",alignSelf: "center", fontSize:"1vw" }} text={ item.label } value={ item.value }
            //  <CheckBox style={{ alignSelf: "center", fontSize:"1vw"}} text={ item.label } value={ item.value }
              onChange={() =>
                {onChangeValue(item.label)}
              } />
              <Text style={{marginLeft:10, fontSize:"0.9vw"}}>{item.label}</Text>
            </View>
          )
          
            })
          }     
      </ScrollView>
    )
  }

  return (
    <View>
      <TouchableOpacity style={{ alignItems: 'center', marginLeft: 8, marginRight: 8 }}

        onPress={() => {

          if (props.buttonKey == "unLockDoctorNotes")
            props.unLockDoctorNotes(true)
          else if (props.buttonKey == "apppointmentRequests") {
            props.apppointmentRequests(true)
          }
          else if (props.buttonKey == "print") {
            setPrintClick(!isPrintClick)
          }
          else if (props.buttonKey == "viewReport" && props.userType === "OPT") {
            setviewReportClick(!isviewReportClick)
          } else if (props.buttonKey == "viewReport" && props.userType != "OPT") {
            if(props.currentPage == "dbCommonHistory"){
              props.viewReportDRnotesRequests()
            }
            else{
              setviewReportClick(!isviewReportClick)
            }
          } else if (props.buttonKey == "ipBillingTxnPrint") {
            props.ipBillingTxnPrint()
          } else if (props.buttonKey == "dischargeSummaryPrint") {
            props.dischargeSummaryPrint()
          } else if (props.buttonKey == "qmsSettingsButton") {
            props.navigation.navigate("qmsDashBoard", { selectedCounter: props.selectedValue })
          } else if (props.buttonKey == "labTestConfig") {
            props.labTestConfig()
          }else if (props.buttonKey == "labRefferedDoctors") {
            props.labRefferedDoctors()
          }else if (props.buttonKey == "labReportHomePage") {
            props.labReportHomePage()
          } else if (props.buttonKey == "labSavedBills") {
            props.labSavedBills(true)
          } else if (props.buttonKey == "labTransmitedChanges") {
            props.labTransmitedChanges(true)
          } else if (props.buttonKey == "biAndRegistry") {
            props.biAndRegistry()
          } else if (props.buttonKey == "exportCSV") {
            props.exportRegistry()
          } else if (props.buttonKey == "ipPatientCheckoutListPrint") {
            props.printIpPatientCheckoutList()
          } else if (props.buttonKey == "immunizationPrint") {
            props.immunizationPrint()
          } else if(props.buttonKey == "referalNotes"){
            props.referalNotes();
          } else if(props.buttonKey == "ipPatientListPrint" ) {
            props.ipPatientListPrint()
          } else if(props.buttonKey == "OBGYNPRINT") {
            props.obgynCommonPrint()
          } else if(props.buttonKey == "CollabSummary") {
            props.CollabReportScreen()
          } else if(props.buttonKey == "creditBillLimit") {
            props.creditBillLimit(!isCreditBillLimit)
          }

        }}


      >
        <View style={{backgroundColor : props.label == "Collaborative " && props.currentPage == "CollabSummary" ? color.white : null, paddingHorizontal:props.label == "Collaborative " && props.currentPage == "CollabSummary" ? ".5vw" : null}}>
          <Image style={[styles.Header_Icons,{tintColor: props.label == "Collaborative " ? null : color.white }]} source={props.image} />
        </View>
        <Text style={styles.Header_Icon_Lables}>{props.label}</Text>
        {
          props.subLabel ?
            <Text style={styles.Header_Icon_Lables}>{props.subLabel}</Text> : null
        }
      </TouchableOpacity>
      {isPrintClick ?
        <View >
          <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 10, position:'absolute', width:'12vw' }}>
            <View>
               {renderCheckBoxView()}
            </View>
            <View style={{ backgroundColor: color.white, flexDirection: "row", justifyContent: "space-around", width: "50%",marginTop: 10 }}>

              <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "2.5vw", height:'2vw', paddingHorizontal: 15, paddingVertical:8 }}
                onPress={() => {
                  if(props.userType === "OPT" || props.isOptClinic){
                    props.printAppointmentListToken({"All":isAll,"Booked":isBooked,"Arrived": isArrived,"WithNurse":isWithNurse,"WithDoctor":isWithDoctor,"Completed":isCompleted,"Cancelled":isCancelled, "Optometry":isOptometry, "Scan":isScan})
                  }else{
                    props.printAppointmentListToken({"All":isAll,"Booked":isBooked,"Arrived": isArrived,"WithNurse":isWithNurse,"WithDoctor":isWithDoctor,"Completed":isCompleted,"Cancelled":isCancelled})
                  }
                   clearCheckBox()
                  }}
              >
                <Text style={{ color: 'white', fontSize: "0.8vw", }}>{"Ok"}</Text>
             </TouchableOpacity>
             <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "4vw",  height:'2vw',paddingHorizontal: 18, paddingVertical: 8,marginLeft:20 }}
                onPress={() => 
                  clearCheckBox()
                }
              >
                <Text style={{ color: 'white', fontSize:  "0.8vw" }}>{"Cancel"}</Text>
             </TouchableOpacity>
            </View>
          </View>
        </View> : null
      }

    {isviewReportClick ?
    <View >
    <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 10, position:'absolute', width:props.userType === "OPT"?"20vw":'15vw', shadowColor: color.lightGray, shadowOffset: {width: -2, height: 2}, shadowOpacity:0.6, shadowRadius: 4 }}>
      <View>
         {renderReportCheckView()}
      </View>
      <View style={{ backgroundColor: color.white, flexDirection: "row", justifyContent: "space-around", width: "80%",marginTop: 10 }}>

        <TouchableOpacity
          style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "2.5vw", height:'2vw', paddingHorizontal: 15, paddingVertical:8 }}
          onPress={() => {
            props.viewReportDRnotesRequests({"isComplaints":isComplaints,"isOcularHistory":isOcularHistory,"isSystemicIllness": isSystemicIllness,"isVitals":isVitals,"isVisionAssesment":isVisionAssesment,"isGonioscopy":isGonioscopy,"isAutoRefract":isAutoRefract, "isPGP":isPGP, "isSubjectiveRefraction":isSubjectiveRefraction,"isASR":isASR,"isIOP":isIOP,"isAnteriorSeg":isAnteriorSeg,"isPosteriorSeg": isPosteriorSeg,"isFundus":isFundus,"isDiagnosis":isDiagnosis,"isMedication":isMedication,"isGlassPrescription":isGlassPrescription,"isReviewPlan": isReviewPlan,"isTreatmentPlan":isTreatmentPlan,"isConselling":isConselling,"isInvestigation":isInvestigation,"isSymptoms":isSymptoms,"isAllergy": isAllergy,"isHOPI": isHOPI,"isExamination": isExamination,"isMentalStatusExamination":isMentalStatusExamination, "isAssessment":isAssessment, "isPrescription":isPrescription,"isPreviousPrescription": isPreviousPrescription,"isTest":isTest,"isPlan": isPlan, "isGeneralPatientAdvice": isGeneralPatientAdvice, "all"  : isReportAll     })
             clearReportCheckCheckBox()
            }}
        >
          <Text style={{ color: 'white', fontSize: "0.8vw", }}>{"Ok"}</Text>
       </TouchableOpacity>
       <TouchableOpacity
          style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "4vw",  height:'2vw',paddingHorizontal: 18, paddingVertical: 8,marginLeft:20 }}
          onPress={() => 
            defaultReportCheckCheckBox()
          }
        >
          <Text style={{ color: 'white', fontSize:  "0.8vw" }}>{"Default"}</Text>
       </TouchableOpacity>
       <TouchableOpacity
          style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "4vw",  height:'2vw',paddingHorizontal: 18, paddingVertical: 8,marginLeft:20 }}
          onPress={() => 
            clearReportCheckCheckBox()
          }
        >
          <Text style={{ color: 'white', fontSize:  "0.8vw" }}>{"Cancel"}</Text>
       </TouchableOpacity>
      </View>
    </View>
    </View> : null
    }

      {/* {
        <QMSSettingsDropDown/>
      } */}

    </View>
  )
}

export const QMSSettingsDropDown = (props) => {

  return (
    <View style={{ height: 500, width: 450 }}>
      <View style={{ position: 'absolute' }}>
        <View style={{ height: 35, marginTop: 15, backgroundColor: color.sectionHeadingDarkColor, alignItems: 'center', justifyContent: "center" }}>
          <Text style={{ fontSize: '18', fontWeight: '500' }}>{"Settings"}</Text>
        </View>

      </View>
    </View>
  )
}

export const 
CommonHeaderProfileField = (props) => {

  // showProfileDropDownFlag={props.showProfileDropDownFlag}
  // let showDropDown = props.showProfileDropDownFlag;
  var ProfileName = ""
  if (props.userType) {
    if (props.userType == "FO") {
      ProfileName = "Front Office"
    }
  }
  return (
    <View>
      <TouchableOpacity onPress={() => {
        props.profileDropDownFlagChange(!props.showProfileDropDownFlag)

      }} style={{ flexDirection: 'row', justifyContent: 'flex-end', marginLeft: 8, paddingRight: 15, marginRight: 8, paddingHorizontal: 30, borderLeftColor: color.white, borderLeftWidth: 0.5 }}>
        <View>
          <Text style={styles.Header_Profile_Name}>{props.doctorName ? props.doctorName : ""}</Text>
          <Text style={styles.Header_Profile_Type}>{props.drType ? props.drType : ""}</Text>
        </View>
        <Image style={styles.Header_Profile_Icon} source={props.doctorProfilePhoto ? props.doctorProfilePhoto : props.defaultImage} />
      </TouchableOpacity>
      {
        props.showProfileDropDownFlag ?
          <View style={{ marginLeft: 70 }}>
            {/* <View style={{marginRight:80}}> */}
            <View style={{
              position: "absolute",
              right:10,
              backgroundColor: color.white,
              paddingHorizontal: 10,
              borderRadius: 4,
              borderColor: color.white,
              borderWidth: 1,
              width:'8vw'
            }}>

              <View style={{ justifyContent: "center", alignItems: "center" }}>

                <Image style={styles.Header_Profile_Popup_Icon} source={props.doctorProfilePhoto ? props.doctorProfilePhoto : props.defaultImage} />
                <Text style={styles.Header_Profile_Popup_Name}>{props.doctorName ? props.doctorName : ""}</Text>
                <Text style={styles.Header_Profile_Popup_Type}>{props.drType ? props.drType : ""}</Text>
              </View>


              {
                (props.userType == "FO" || props.userType == "LAB" || props.userType == "OPTOMETRY" || props.userType == "OT") ?
                  null :
                  <TouchableOpacity onPress={() => {
                    props.changePageOrNavigateTOAnotherPage("doctorProfile")
                  }}>


                    <View style={{ flexDirection: "row", marginTop: 15 }}>
                      <Image 
                      source={ImagePath.ViewProfile}
                      style={styles.Header_PopUp_Menu_Icon} />
                      <Text style={styles.Header_PopUp_Menu_Text}>{"View Profile"}</Text>
                    </View>
                  </TouchableOpacity>
              }




              <TouchableOpacity onPress={() => {
                props.logoutFunction()
              }}>
                <View style={{ flexDirection: "row", marginTop: 15 }}>
                  <Image
                  source={ImagePath.Logout}
                  style={styles.Header_PopUp_Menu_Icon} />
                  <Text style={styles.Header_PopUp_Menu_Text}>{"Logout"}</Text>
                </View>
              </TouchableOpacity>
            </View>
            {/* </View> */}
          </View>
          : null
      }

    </View>
  )
}

export const HistorySectionRightSideUI = (props) => {
  return (
    <View style={{ flex: 1 }}>
      <View style={{ paddingLeft: 15, justifyContent: 'center', height: 32, backgroundColor: color.sectionHeadingDarkColor }}>
        <Text style={{ fontSize: 13, fontWeight: '500' }}>{props.heading}</Text>
      </View>
      <View style={{
        paddingVertical: 10, backgroundColor: color.white, justifyContent: "center", paddingLeft: 15
        , borderWidth: 0.5, borderColor: "#888888", marginHorizontal: 5, marginVertical: 5, borderRadius: 5
      }}>
        <Text style={Style.valueFontStyle}>
          {props.value ? props.value : null}
          {(props.value && props.details) ? " , " : null}
          {props.details ? props.details : null}
          {props.addDaysText ? " Days" : ""}
        </Text>



        {/* {props.value ? <Text style={Style.valueFontStyle}>{props.value}</Text> : null}
        {props.value && props.details ? <Text style={Style.valueFontStyle}>{" , "}</Text> : null}
        {props.details ? <Text style={Style.valueFontStyle}>{props.details}</Text> : null} */}
      </View>
    </View>
  )
}




export const ChangePassword = (props) => {
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "40%", borderRadius: 15,
          }}
        >
          <View style={{ margin: 20 }}>
            <View style={{ marginBottom: 15, justifyContent: 'center', flexDirection: 'row', width: "100%", height: 35, backgroundColor: props.type == "sub" ? color.sectionHeadingLiteColor : color.sectionHeadingDarkColor, alignItems: 'center' }}>
              <Text style={{ fontWeight: '500', fontSize: 15, textAlign: 'center' }}>Change Password</Text>
            </View>

            <View style={{ paddingHorizontal: 100, marginBottom: 15, }}>
              <Text style={{ textAlign: 'center', fontWeight: '500', fontSize: 12, color: color.themeDark }}>Password must be between 6 and 12 characters long containing alpha numeric with at least one Upper case. Lower Case. Special Characters</Text>
              <Text style={{ textAlign: 'center', fontWeight: '500', fontSize: 12, color: color.themeDark }}>
                {"(!,@,#,$,%,^,&,*,_,-,+,=)"}
              </Text>
            </View>


            <TextInput style={{ marginBottom: 15, alignSelf: 'center', width: "80%", height: 32, borderColor: "#888888", borderWidth: 1, paddingLeft: 15, borderRadius: 4 }}
              value={props.oldPassword}
              placeholder={"Old Password"}
              onChangeText={(text) => {
                props.changePasswordValues(text, props.newPassword, props.confirmPassword)
              }}
            />

            <TextInput style={{ marginBottom: 15, alignSelf: 'center', width: "80%", height: 32, borderColor: "#888888", borderWidth: 1, paddingLeft: 15, borderRadius: 4 }}
              value={props.newPassword}
              placeholder={"New Password"}
              onChangeText={(text) => {
                props.changePasswordValues(props.oldPassword, text, props.confirmPassword)
              }}
            />
            <TextInput style={{ marginBottom: 15, alignSelf: 'center', width: "80%", height: 32, borderColor: "#888888", borderWidth: 1, paddingLeft: 15, borderRadius: 4 }}
              value={props.confirmPassword}
              onChangeText={(text) => {
                props.changePasswordValues(props.oldPassword, props.newPassword, text)
              }}
              placeholder={"Confirm Password"}
            />

            <Text style={{ fontSize: 13, fontWeight: '500', color: "red", textAlign: 'center' }}>{props.changePasswordValidationMessage}</Text>

            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <TouchableOpacity onPress={() => { props.changePasswordSaveAction() }} style={[{ marginLeft: 99, paddingHorizontal: 20, marginTop: 10, backgroundColor: color.themeDark, paddingVertical: 10, alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }, Style.allButtonBorderRadius]}>
                <Text style={{ color: color.white, fontWeight: "700", fontSize: 14 }}>{"Save"}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { props.changePasswordOpenAndClose(false) }} style={[{ marginRight: 99, paddingHorizontal: 20, marginTop: 10, backgroundColor: color.white, paddingVertical: 10, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', borderColor: color.themeDark, borderWidth: 1 }, Style.allButtonBorderRadius]}>
                <Text style={{ color: color.black, fontWeight: "700", fontSize: 14 }}>{"Cancel"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}



export const CommonHistorySectionHeader = (props) => {
  let columnSizeH1 = "";
  let columnSizeH2 = "";
  let columnSizeH3 = "";
  let columnSizeH4 = "";
  let columnSizeH5 = "";
  let columnSizeH6 = "";
  let columnSizeH7 = "";
  let columnSizeH8 = "";

  let columnSizeH9 = "";
  let columnSizeH10 = "";
  let columnSizeH11 = "";
  let columnSizeH12 = "";
  if (props.columnSize && props.columnSize.length > 1) {
    if (props.noOfColumn == 2) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1];
    } else if (props.noOfColumn == 3) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2];
    } else if (props.noOfColumn == 4) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
    } else if (props.noOfColumn == 5) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4];
    } else if (props.noOfColumn == 6) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5];
    } else if (props.noOfColumn == 7) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6];
    } else if (props.noOfColumn == 8) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
    } else if (props.noOfColumn == 9) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
      columnSizeH9 = props.columnSize[8];;
    } else if (props.noOfColumn == 10) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
      columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9];
    } else if (props.noOfColumn == 11) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
      columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; 
    }
    else if (props.noOfColumn == 12) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
      columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]
    }
  }

  return (
    <View style={{ justifyContent: 'center', height: '2.27vw', backgroundColor: props.type == "sub" ? color.sectionHeadingLiteColor : color.sectionHeadingDarkColor, zIndex: -1 ,borderRadius:'0.26vw'}}>

      {

        props.noOfColumn == 1 ?
          <Text style={[Style.historyHeaderFontStyleStartText, (props.headerKey == "roomsDetails" || props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading1}</Text> :
          props.noOfColumn == 2 ?
            <View style={[{ flexDirection: 'row' }, columnSizeH1 && columnSizeH2 ? {} : { justifyContent: "space-between" }]}>
              <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 }]}>{props.heading1}</Text>
              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, paddingRight: 30 }]}>{props.heading2}</Text>
            </View> :

            props.noOfColumn == 3 ?
              <View style={{ flexDirection: 'row' }}>
                <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.33 }]}>{props.heading1}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.33 }]}>{props.heading2}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.33 }]}>{props.heading3}</Text>
              </View> :
              props.noOfColumn == 4 ?
                <View style={{ flexDirection: 'row' }}>
                  <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3 }]}>{props.heading1}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3 }]}>{props.heading2}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3 }]}>{props.heading3}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.1 }]}>{props.heading4}</Text>

                </View> :
                props.noOfColumn == 5 ?
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3 }]}>{props.heading1}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3 }]}>{props.heading2}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3 }]}>{props.heading3}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3 }]}>{props.heading4}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3 }]}>{props.heading5}</Text>

                  </View> :
                  props.noOfColumn == 6 ?
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3 }, (props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading1}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading2}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading3}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading4}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading5}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading6}</Text>

                    </View> : props.noOfColumn == 7 ?
                      <View style={[{ flexDirection: 'row', paddingHorizontal:'1.5vw' }, props.headerKey == "glucoseLog" ? { paddingHorizontal: 10 } : {}]}>

                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1 ? columnSizeH1 : 0.14, fontSize: props.heading1 == "Date" ? '0.85vw' : '0.87vw' }]}>{props.heading1}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.1, fontSize: props.heading2 == "Fasting" ? '0.85vw' : '0.87vw' }]}>{props.heading2}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.15, fontSize: props.heading3 == "2hours post Breakfast" ? '0.85vw' : '0.87vw' }]}>{props.heading3}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.13, fontSize: props.heading4 == "Pre Lunch" ? '0.85vw' : '0.87vw' }]}>{props.heading4}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.15, fontSize: props.heading5 == "2hours post Lunch" ? '0.85vw' : '0.87vw' }]}>{props.heading5}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.13, fontSize: props.heading6 == "Pre Dinner" ? '0.85vw' : '0.87vw' }]}>{props.heading6}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.2, fontSize: props.heading7 == "2hours post Dinner" ? '0.85vw' : '0.87vw' }]}>{props.heading7}</Text>

                      </View> :

                      props.noOfColumn == 8 && props.headerKey == "Immunization" ?
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={[Style.historyHeaderFontStyle, { flex: 0.07 }]}>{props.heading1}</Text>
                          <View style={{ flexDirection: 'row', flex: 0.93 }}>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.21 }]}>{props.heading2}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.11 }]}>{props.heading3}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.17 }]}>{props.heading4}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.11 }]}>{props.heading5}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.11 }]}>{props.heading6}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.21 }]}>{props.heading7}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: 0.08 }]}>{props.heading8}</Text>
                          </View>
                        </View> 
                        : props.noOfColumn == 8 && props.headerKey != "Immunization" ?
                         <View style={[{ flexDirection: 'row', paddingHorizontal:'1.5vw' }, props.headerKey == "glucoseLog" ? { paddingHorizontal: 10 } : {}]}>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1 ? columnSizeH1 : 0.14, fontSize: props.heading1 == "Date" ? '0.85vw' : '0.87vw' }]}>{props.heading1}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.1, fontSize: props.heading2 == "Fasting" ? '0.85vw' : '0.87vw' }]}>{props.heading2}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.15, fontSize: props.heading3 == "2hours post Breakfast" ? '0.85vw' : '0.87vw' }]}>{props.heading3}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.13, fontSize: props.heading4 == "Pre Lunch" ? '0.85vw' : '0.87vw' }]}>{props.heading4}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.15, fontSize: props.heading5 == "2hours post Lunch" ? '0.85vw' : '0.87vw' }]}>{props.heading5}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.13, fontSize: props.heading6 == "Pre Dinner" ? '0.85vw' : '0.87vw' }]}>{props.heading6}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.1, fontSize: props.heading7 == "2hours post Dinner" ? '0.85vw' : '0.87vw' }]}>{props.heading7}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 ? columnSizeH8 : 0.1, fontSize: props.heading8 == "2hours post Dinner" ? '0.85vw' : '0.87vw' }]}>{props.heading8}</Text>

                         </View> :
                        props.noOfColumn == 9 ?
                          <View style={{ flexDirection: 'row' }}>
                            <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.15 }]}>{props.heading1}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.1 }]}>{props.heading2}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.1 }]}>{props.heading3}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.1 }]}>{props.heading4}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.1 }]}>{props.heading5}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.1 }]}>{props.heading6}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.1 }]}>{props.heading7}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 ? columnSizeH8 : 0.1 }]}>{props.heading8}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9 ? columnSizeH9 : 0.15 }]}>{props.heading9}</Text>

                          </View> :
                          props.noOfColumn == 10 ?
                        <View style={{ flexDirection: 'row'}}>
                          <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.1 } ]}>{props.heading1}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.1 } ]}>{props.heading2}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.1 } ]}>{props.heading3}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.1 } ]}>{props.heading4}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.1 } ]}>{props.heading5}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.1 } ]}>{props.heading6}</Text>


                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.1 } ]}>{props.heading7}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 ? columnSizeH8 : 0.1 } ]}>{props.heading8}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9 ? columnSizeH9 : 0.1 } ]}>{props.heading9}</Text>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10 ? columnSizeH10 : 0.1 }]}>{props.heading10}</Text>

                        </View> :
                         props.noOfColumn == 11 ?
                         <View style={{ flexDirection: 'row', alignItems: "center", height: "3vh" }}>
                           <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3, padding: "0.3vw" }, (props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading1}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading2}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading3}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading4}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading5}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading6}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.3, padding: "0.3vw" }, (props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading7}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 ? columnSizeH8 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading8}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9 ? columnSizeH9 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading9}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10 ? columnSizeH10 : 0.3, padding: "0.3vw"}, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading10}</Text>
                           <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11 ? columnSizeH11 : 0.3, padding: "0.3vw" }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading11}</Text>

                         </View> :
                        props.noOfColumn == 12 ?
                          <View style={{ flexDirection: 'row', alignItems: "center" }}>
                            <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3 }, (props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading1}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading2}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading3}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading4}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading5}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading6}</Text>


                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 ? columnSizeH7 : 0.3 }, (props.headerKey == "RatingScale") ? { fontSize: 15 } : {}]}>{props.heading7}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 ? columnSizeH8 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading8}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9 ? columnSizeH9 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading9}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10 ? columnSizeH10 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading10}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11 ? columnSizeH11 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading11}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12 ? columnSizeH12 : 0.3 }, props.headerKey == "RatingScale" ? { textAlign: 'center' } : {}]}>{props.heading12}</Text>


                          </View> :

                          null
      }
    </View>

  )
}

export const GlucoseLogRadioButton = (props) => {
  var data = [
    { label: "2 Week", value: "2 weeks" },
    { label: "1 month", value: "1 month" },
    { label: "2 month", value: "2 months" },
    { label: "3 month", value: "3 months" },
  ]
  return (
    <View style={{ flexDirection: 'row' }}>
      {
        data.map((item, index) => {
          return (
            <View key={index}>
              <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', marginRight: 15 }} onPress={() => {
                props.selectedDateSpanFilter(item)
              }}>
                <View style={{ height: 13, width: 13, borderRadius: 30, backgroundColor: props.selectedDateSpanFilterValue.value == item.value ? color.themeDark : "#CECECE" }}></View>
                <Text style={{ color: props.selectedDateSpanFilterValue.value == item.value ? color.themeDark : "#CECECE", marginLeft: 10 }}>{item.label}</Text>
              </TouchableOpacity>
            </View>
          )
        })
      }
    </View>
  )

}

export const TakePickerConst = (props) => {
  return (
    <View style={{ position: "absolute", minHeight: '29.5vw' }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: '100vh',
          width: '100vw',
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "40%", borderRadius: '1vw',
          }}
        >
          <TakePicture updateProfilePhoto={props.updateProfilePhoto}
            cameraFor={props.cameraFor}
            closeCameraModal={props.closeCameraModal}
            recognizePatientPhoto={props.recognizePatientPhoto} />

        </View>
      </View>
    </View>
  );
}
export const CommonPrintButton = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress} style={{ backgroundColor: color.themeDark, paddingVertical: 8, paddingHorizontal: 5, borderRadius: 4 }}>
      <Image
      source={ImagePath.Print}
        style={{ height: 15, width: 20, tintColor: color.white }} />
    </TouchableOpacity>
  )
}

export const LabelWithTextBoxDR = (props) => {
  return (
    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
      <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 10 }}>
        {props.label}
      </Text>
      <TextInput
        multiline={true}
        numberOfLines={20}
        maxLength={props.maxTextLength ? props.maxTextLength : props.textBoxKey == "developmentValue" ? 100 : props.textBoxKey == "feedingHistory" ? 100 : 300}
        style={[{ borderRadius: 4, paddingLeft: 8, paddingTop: 4, height: 60, width: "80%", marginBottom: 10 }, Style.historyYesOrNoSectionShadow]}
        value={props.value}
        placeholder={props.placeholder}
        onChangeText={(text) => {
          props.onChangeTextBoxValue(text, props.textBoxKey)
        }}
      />
    </View>
  )
}


export const LabelWithCalender = (props) => {
  return (
    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, flexDirection: 'row', justifyContent: 'space-between' }]}>
      <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 10 }}>
        {props.label}
      </Text>


      <DatePicker
        disabledDate={current => {
          if (props.label == "Given Date") {
            return current && current >= moment().add(-1, "days");

          } else {
            return current && current >= moment().add(0, "days");
          }
        }}
        value={
          props.calenderDate ?
            moment(props.calenderDate, "YYYY-MM-DD ") :
            ""
        }
        showToday={true}
        onChange={date => {
          var fulldate = new Date(date);
          var converted_date = moment(fulldate).format("YYYY-MM-DD");
          props.onChangeTextBoxValue(converted_date, props.calenderKey)
        }}
        placeholder={moment(new Date()).format("YYYY-MM-DD")}
        style={{
          borderRadius: 4, borderColor: "#888888", height: 30, borderWidth: 0.5, width: 150, marginBottom: 5, marginTop: 5, marginRight: 10,
        }}
      />
    </View>
  )
}



export const EditiAndDeleteForLineItem = (props) => {
  // : color.drNotesUnSelectedTitle
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: props.headerKey == "testConfig" ? color.white : color.sectionHeadingDarkColor, height: 40 }}>
      <View>
        {/* <TouchableOpacity disabled={props.disabled} onPress={() => { props.selectedRightSideView(props.pageName) }}> */}
        <Text style={props.headerKey == "insurance" ? { fontSize: 16, fontWeight: '500', marginLeft: 9 } : { fontSize: 13, marginLeft: 9 }}>{props.heading}</Text>
        {/* </TouchableOpacity> */}
      </View>
      <View style={{ flexDirection: 'row' }}>

        {
          props.editImage ?
            <TouchableOpacity onPress={() => { props.editHistory() }}>
              <View style={Style.editIcon}>
                <Icon name={props.editImage} color={color.newPencilColor} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }


        {
          props.deleteImage ?
            <TouchableOpacity onPress={() => { props.deleteHistory() }}>
              <View style={Style.editIcon}>
                <Icon name={props.deleteImage} color={color.themePink} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }


        {
          props.saveImage ?
            <TouchableOpacity onPress={() => { props.saveHistory() }}>
              <View style={Style.editIcon}>
                <Save name={props.saveImage} color={color.themeDark} size={15} />
              </View>
            </TouchableOpacity>
            : null
        }


      </View>
    </View>
  )
}


export const LabelWithTextSingleRow = (props) => {
  return (
    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }]}>
      <Text style={{ fontSize: 14, flex: 0.7 }}>
        {props.label}
      </Text>
      <View style={{ flex: 0.3, flexDirection: "row", alignItems: 'center', paddingRight: 5 }}>
        <TextInput
          style={{
            justifyContent: 'center', textAlignVertical: 'center',
            borderColor: "#888888", paddingLeft: 8, height: 30,
            // borderWidth:0.5,
            // borderRadius:4,

            borderTopLeftRadius: 4, borderWidth: 1,
            borderBottomLeftRadius: 4,
            width: "80%"
          }}
          value={props.values}
          // multiline={true}
          // numberOfLines={20}
          onChangeText={(text) => {
            props.onChangeTextBoxValue(text, props.textBoxKey)
          }}
        />
        <View style={{
          justifyContent: "center", alignItems: "center", height: 30, backgroundColor: color.themeDark, borderTopRightRadius: 4, borderWidth: 1,
          borderBottomRightadius: 4,
        }}>
          <Text style={{ fontSize: 12, color: color.white }}>{"Days"}</Text>
        </View>

      </View>

    </View>
  )
}


export const NavigationTopHeader = (props) => {
  var data = props.navigationHeaderList
  return (

    <View style={[{ backgroundColor: color.white, borderBottomLeftRadius: '2.5vw', borderBottomRightRadius: '2.5vw', height: '3.2vw' }, (props.isNavigationBorder && props.isNavigationBorder === true) ? { marginBottom: '0.6vw', shadowColor: color.grayShade, shadowOffset: { width: '0.06vw', height: '0.06vw' }, shadowOpacity: 1, shadowRadius: 2 } : null]}>
      <ScrollView style={styles.NaviTopHeaderScrollView} horizontal={true}>
        {
          data && data.length > 0 && data.map((item, index) => {
            return (
              <TouchableOpacity onPress={() => { props.changeTabSection(item.value, index) }} style={styles.NaviTopHeaderTouch} key={index}>
                <Text style={[{ fontSize: '0.9vw', fontWeight: "500" }, props.selectedTab == item.value ? { color: color.themeDark } : {}]}>{item.label}</Text>
              </TouchableOpacity>
            )
          })
        }
      </ScrollView>
    </View>
  )
}
export const DocNotesVerticalNavigation = (props) => {
  var data = props.navigationHeaderList
  const [hoverItem, sethoverItem] = useState("")
  return (

    <View style={[{ backgroundColor: color.white}, (props.isNavigationBorder && props.isNavigationBorder === true) ? { marginBottom: '0.6vw', shadowColor: color.grayShade, shadowOffset: { width: '0.06vw', height: '0.06vw' }, shadowOpacity: 1, shadowRadius: 2 } : null]}>
      <ScrollView style={[{display:'flex', flexDirection:'column', height:'44vw' }]}>
        {
          data && data.length > 0 && data.map((item, index) => {
            return (
              <TouchableOpacity 
              onMouseEnter={() => {
                sethoverItem(item.value)
              }}
              onMouseLeave={() => {
                sethoverItem("")
              }}

               onPress={() => { props.changeTabSection(item.value, index) }} style={[styles.DocNotesNavTouchView, {paddingVertical:'0.35vw',backgroundColor: props.selectedTab == item.value ? color.themelite : color.white,  }]} key={index}>
                <Text style={[{ fontSize: '0.85vw', fontWeight: "500", marginLeft:'0.5vw' }, props.selectedTab == item.value || hoverItem == item.value ? { color: color.themeDark } : {}, ]}>{item.label}</Text>
              </TouchableOpacity>
            )
          })
        }
      </ScrollView>
    </View>
  )
}


export const CommonHeaderIPPatientDetails = (props) => {
  const patientDetails = props.ipPatientProfileDetails;
  return (
    // <View style={{flexDirection:"row"}}>
    <View style={{ flexDirection: "row", alignItems: 'center', borderLeftColor: color.white, borderLeftWidth: 1.5, paddingLeft: 5 }}>
      <View style={{}}>

        <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: 'center', marginBottom: 5 }}>
          <Text style={{ color: "white", fontSize: 14, fontWeight: '500', marginRight: 3 }}>{patientDetails.patient_name}</Text>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>{patientDetails.patient_age}</Text>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>|</Text>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>{patientDetails.phone_number}</Text>
        </View>

        <View style={{ flexDirection: "row", flexWrap: "wrap", }}>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>{patientDetails.admission_date}</Text>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>{patientDetails.ward_name}</Text>
          <Text style={{ color: "white", fontSize: 12, marginRight: 3 }}>{patientDetails.doctor_name}</Text>
        </View>

      </View>
      {/* <View style={{}}>
        <Image style={{ height: 35, width: 35, marginLeft: 13, borderRadius: 35 }} source={
          patientDetails.photo_url ? { uri: patientDetails.photo_url } :
            require("../../../assets/images/Developmental_Assessment_Chart1.png")} />

        <View style={{ flexDirection: "row", flex: 1, flexWrap: "wrap", }}>
          <Text style={{ color: "white", fontSize: 12 }}>20-12-2020</Text>
          <Text style={{ color: "white", fontSize: 12 }}>ICU Ward Num #103</Text>
          <Text style={{ color: "white", fontSize: 12 }}>Dr.Sudha kumar j</Text>
        </View>

      </View> */}
      <View style={{ flex: 0.2 }}>
        <Image
          style={{ height: 35, width: 35, marginLeft: 13, borderRadius: 35 }} source={
            patientDetails.photo_url ? { uri: patientDetails.photo_url } :ImagePath.DefaultProfile}/>
      </View >

    </View>

    // </View>
  )
}
const CommonDateFilter = (props) => {
  // const [showDD, changeDD] = useState(props.dateFilterFlag)
  const [selectedDateFilter, changeSelectedDateFilter] = useState(props.defaultSelectedDateFilter ? props.defaultSelectedDateFilter : "")
  const [month,setMonth] = useState(month)
  // const [selectedPreviousDateFilter, changeSelectedPreviousDateFilter] = useState("")

  // const myRef = useRef();

  function calculateDate(item) {
    var getKey = item.key;
    var today = new Date();
    var filterDate = {};
    var dateFormet = "YYYY-MM-DD";
    if (getKey == "today") {
      var date = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      filterDate = { fromDate: date, toDate: date,  lable:item.label }
    } else if (getKey == "yesterday") {

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      var date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-' + yesterday.getDate();
      filterDate = { fromDate: date, toDate: date, lable:item.label }
    } else if (getKey == "last7days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();

      var lastDate = new Date();
      lastDate.setDate(lastDate.getDate() - 7);
      var finalDate = lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + lastDate.getDate();
      filterDate = { fromDate: finalDate, toDate: todaydate, lable:item.label }

    } else if (getKey == "last30days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate, lable:item.label }

    } else if (getKey == "last90days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate, lable:item.label }

    } else if (getKey == "last12Months") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (365 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate, lable:item.label }
    } else if (getKey == "all") {
      filterDate = { fromDate: "", toDate: "", lable:item.label }
    }
    
    // changeDD(false);
    props.updateDateFilterFlag(false)

    changeSelectedDateFilter(item.label);
    // if(item.label == "Custom"){
    //   changeSelectedPreviousDateFilter(selectedDateFilter)
    //   changeSelectedDateFilter(item.label);
    // }else{
    //   changeSelectedDateFilter(item.label);
    // }

    props.updateDateFilterFlag(false)

    if (getKey == "custom") {
      // changeDD(false);
      props.updateDateFilterFlag(false)
    }else if(getKey == "monthly"){
      props.updateDateFilterFlag(false)
    }else {
      props.showSelectedFilteredDate(filterDate)
    }
    // return filterDate
  }


  function onChange(value, dateString) {
    var dateFormet = "YYYY-MM-DD"
    var a = dateString[0].substring(0, 10)
    var b = dateString[1].substring(0, 10)
    // var { selecteDateValue } = this.state
    var a_format = moment(a).format(dateFormet);
    var b_format = moment(b).format(dateFormet);

    var selectedCustom = "(" + a_format + ")" + "-" + "(" + b_format + ")"
    // changeDD(false);
    props.updateDateFilterFlag(false)
    changeSelectedDateFilter(selectedCustom);

    // alert(a_format+"-----"+b_format)
    var filterDate = { fromDate: a_format, toDate: b_format , lable : selectedCustom}
    props.showSelectedFilteredDate(filterDate)

  }

  const onDateChangeHandler = (month) => {
    let date = moment(month)._d;
    let y = date.getFullYear()
    let m = date.getMonth();
    let firstDay = new Date(y, m, 1)
    let lastDay = new Date(y, m + 1, 0)
    let start_date = moment(firstDay).format("YYYY-MM-DD")
    let end_date = moment(lastDay).format("YYYY-MM-DD")

    let selectedMonth = moment(month).format("MM-YYYY")
    setMonth(selectedMonth)
    changeSelectedDateFilter(selectedMonth)
    props.updateDateFilterFlag(false)

    let monthStartAndEndDate = { fromDate: start_date, toDate: end_date , lable: selectedMonth }
    props.showSelectedFilteredDate(monthStartAndEndDate)
  }

  const listOfFilters = [
    { label: "All", key: "all" },
    { label: "Today", key: "today" },
    { label: "Yesterday", key: "yesterday" },
    { label: "Last 7 days", key: "last7days" },
    { label: "Last 30 days", key: "last30days" },
    { label: "Last 90 days", key: "last90days" },
    { label: "Last 12 Months", key: "last12Months" },
    { label: "Monthly", key:"monthly" },
    { label: "Custom", key: "custom" },
  ]


  // if (props.startDate || props.endDate) {
  //   // do nothing
  // } else {
  //   if (selectedDateFilter == "") {
  //     // do nothing
  //   } else {
  //     changeSelectedDateFilter("")
  //   }

  // }

  useEffect(() => {
    if (props.clearPress){
    changeSelectedDateFilter("Today")
    }
  },[props.clearPress]);


  // const handleClickOutside = e => {
  //   // if (!myRef.current.contains(e.target)) {
  //     alert("test"+JSON.stringify(e));
  //   // }
  // };

  return (
    // <TouchableWithoutFeedback 
    // onPressIn={()=>{alert("onpres IN")}}
    // onPressOut={()=>{alert("onpres out")}}
    // onPress={()=>{alert("onpres")}}>

    <View style={{}}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <Add name={"calendar-range-outline"} color={"grey"} size={"1vw"} />

        <View style={{ marginLeft: 10 }}>

          <TouchableOpacity
            onPress={() => {
              // changeDD(!showDD)
              props.updateDateFilterFlag(!props.dateFilterFlag)
            }}
          >
            <Text style={{fontSize:'.9vw'}}>{"Select Filter"}   <Cart name={"sort-down"} color={"grey"} size={"1vw"} style={{ marginLeft: "3.25vw" }} /></Text>
          </TouchableOpacity>
          {
            selectedDateFilter == "Custom" ?

              <RangePicker
                open={true}
                onChange={onChange}
                // **** Future Date Disable
                disabledDate={props.futureDateDisable ? current => {
                  return current > moment()
               }: null}
                //  ****
              />
              : null
          }
          {
            selectedDateFilter === "Monthly" ?
              <DatePicker
                open={true}
                clearIcon={false}
                disabledDate={current => {
                  return current && current > moment().add(0, "days");
                }}
                //defaultValue={month ? moment(month, "MMM-YYYY") : moment()}
                //value={month ? moment(month, "MMM-YYYY") : moment()}
                onChange={(month) => {
                  onDateChangeHandler(month)
                }}
                picker={"month"}
                style={{
                  zIndex: -1,
                  width: "10vw",
                  borderColor: "#CDD1D5",
                  height: '2vw',
                  borderRadius: '.7vw',
                  borderWidth: selectedDateFilter === "Monthly" ? null : 1,
                  marginTop: '.3vw',
                  fontSize: '1.2vw'
                }} /> : null
          }
          <View
            style={{
              borderBottomColor: '#CDD1D5',
              borderBottomWidth: 1,
              width: '100%',
              marginTop: ".3vw",
              marginBottom: ".3vw"
            }} />
          <Text style={{fontSize:'.9vw'}}>{selectedDateFilter}</Text>
        </View>
      </View>
      {




        props.dateFilterFlag ?
          <View style={{ position: 'absolute', backgroundColor: "pink", width: "10.5vw", borderRadius: ".5vw", paddingLeft: "1vw", paddingVertical: ".5vw" }}>
            {
              listOfFilters.map((item, index) => {

                if ((props.removeObject && props.removeObject == item.key) || (props.removeObject && Array.isArray(props.removeObject) && (props.removeObject).includes(item.key) )) {
                  // do nothing 
                } else {
                  return (
                    <TouchableOpacity 
                     onPress={() => { 
                      calculateDate(item) 
                      props.getLabelStatus ? props.getLabelStatus(item.label) : () => {}
                     
                     }} 
                     key={index} style={item.key == "custom" ? { marginTop: "1vw" } : { marginTop: ".85vw" }} 
                    >
                      <Text style={[{ color: color.black, fontSize: ".85vw", fontWeight: '500' }]}>{item.label}</Text>
                    </TouchableOpacity>
                  )
                }
              })
            }
          </View>

          : null
      }
    </View>
    // </TouchableWithoutFeedback>
  )
}


// export default CommonDateFilter


const mapStateToProps = (state) => {
  return {
    dateFilterFlag: state.updateDateFilter.dateFilterFlag
  }

};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDateFilterFlag: (data) => dispatch(updateDateFilterFlag(data))
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonDateFilter);






export const BedChargeModel = (props) => {
  var data = props.roomDetailsPopupData;
  // console.log("roomDetailsPopupData -----------> " + JSON.stringify(data))
  var amount = data.reduce(function (sum, current) {
    return sum + current.amount;
  }, 0);

  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "60%", borderRadius: 5,
          }}
        >


          <View >
            <View style={{ flexDirection: 'row', justifyContent: "flex-end" }}>
              <TouchableOpacity style={{ height: 30, width: 30, backgroundColor: color.themeDark, borderRadius: 50, justifyContent: "center", alignItems: "center" }} onPress={() => { props.showRoomDetailsPopup([], false) }}>
                <Text style={{ color: color.white }}>X</Text>
              </TouchableOpacity>
            </View>
            <View style={{ margin: 20 }}>
              <CommonHistorySectionHeader headerKey={"roomsDetails"} heading1={"Rooms Details"} noOfColumn={1} columnSize={[1]} />

              <View style={{ flexDirection: 'row', marginTop: 25, marginBottom: 15 }}>
                <View style={{ flex: 0.2, }}>
                  <Text style={Style.ipBedChargeHeader}>{"Room Details"}</Text>
                </View>
                <View style={{ flex: 0.2, }}>
                  <Text style={Style.ipBedChargeHeader}>{"From"}</Text>
                </View>
                <View style={{ flex: 0.2, }}>
                  <Text style={Style.ipBedChargeHeader}>{"To"}</Text>
                </View>
                <View style={{ flex: 0.2, }}>
                  <Text style={Style.ipBedChargeHeader}>{"Duration"}</Text>
                </View>
                <View style={{ flex: 0.2, }}>
                  <Text style={Style.ipBedChargeHeader}>{"Amount"}</Text>
                </View>
              </View>
              {
                data && data.length > 0 && data.map((item, index) => {

                  return (
                    <View style={{ flexDirection: 'row' }} key={index}>
                      <Text style={{ flex: 0.2, }}>{item.room_details}</Text>
                      <Text style={{ flex: 0.2, }}>{item.from}</Text>
                      <Text style={{ flex: 0.2, }}>{item.to}</Text>
                      <Text style={{ flex: 0.2, }}>{item.duration}</Text>
                      <Text style={{ flex: 0.2, }}>{item.amount}</Text>
                    </View>
                  )
                })
              }

              {
                data && data.length > 0 ?
                  <View style={{ flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center' }}>
                    <Text style={{ fontSize: 15, fontWeight: "500", marginRight: 15 }}>{"Total Amount = "}</Text>
                    <View style={{
                      justifyContent: 'center',
                      alignSelf: 'center',
                      // alignItems: 'center',
                      shadowColor: "#090F36",
                      shadowOffset: { width: 0, height: 2 },
                      shadowOpacity: 0.6,
                      shadowRadius: 3,
                      elevation: 5,
                      backgroundColor: color.white, height: 30,
                      borderRadius: 4,
                      paddingHorizontal: 15,
                      minWidth: 100,
                      marginRight: 20
                    }}>
                      <Text style={{ fontSize: 14, fontWeight: '500' }}>{amount}</Text>

                    </View>
                  </View> : null
              }
              <View style={{ width: '20%', alignSelf: 'center', alignContent: 'center' }}>
                <TouchableOpacity
                  style={Style.ipcancelButton}
                  onPress={() => {
                    props.sendBedChargeTotalAmount(amount)
                  }}>
                  <Text style={[Style.certificatetext, { marginLeft: 10 }]}>Add to Bill</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}




export const BillingTransactionPopup = (props) => {
  var billingList = props.billingTransactionPopupList.billing_details
  var patientDetails = props.billingTransactionPopupList.patient_details
  var amountDetails = props.billingTransactionPopupList.amount_details
  var paidAmount = amountDetails.sub_amount - amountDetails.detected_advance_amount
  const [cancelBillPress, setCancelBillPress] = useState(false);
  const [selectedReason, setSelectedReason] = useState("")
  const [responseType, setresponseType] = useState("")
  const [responseMessage,setresponseMessage] = useState("")
  const [isAdminAccess, setAdminAccess ] = useState(props.isAdminAccess)

 const showResposeValue = (type, message) => {
    setresponseMessage(message);
    setresponseType(type);
    setTimeout(() => {
      setresponseMessage('');
      setresponseType('');
    }, 3000)
  }

  const onSubmitCancel = (reason) => {
    if (reason) {
      props.ipBillCancel(reason)
    } else {
      showResposeValue('error', 'Please enter the reason for cancellation')
    }
  }

  const errorAlert = () => {

    // let errorList = responseMessage
    return (
      <View style={{ flex: 1, zIndex: 3, width: "35%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, minHeight: 70, marginRight: 10, marginBottom: 100 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, marginLeft: 10, flex: 0.95 }}>
          <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: 10 }}>
            <Icon size={25} name={"ban"} color={"#FFF"} /></View>
          <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
            <View>
              <Text style={{ fontWeight: "700", color: "#FFF", fontSize: 12 }}>{"ERROR: "}
                {responseMessage}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { setresponseType('') }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icon size={25} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }

  var reasonsList = [
    { label: "Double Entry", value: "Double Entry" },
    { label: "Wrongly Billed", value: "Wrongly Billed" },
  ]
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "60%", borderRadius: 5,
          }}
        >

          <View style={{ paddingHorizontal: 20, paddingVertical: 13 }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flex: 0.92, flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Name"} value={patientDetails.patient_name} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Mobile Number"} value={patientDetails.mobile_number} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Date"} value={patientDetails.invoice_date ? patientDetails.invoice_date : null} />
                </View>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Age"} value={patientDetails.age} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"IP Number"} value={patientDetails.ip_number} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Bill Number"} value={patientDetails.invoice_number} />
                </View>
              </View>
              <View style={{ flex: 0.04 }}>
                <TouchableOpacity onPress={() => {
                  props.showBillingScreenPopup(false, [])
                }} style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: "center", paddingVertical: 8, backgroundColor: color.themeDark, borderRadius: 50 }}>
                  <Icon size={26} name={"close"} color={"#fff"} />
                </TouchableOpacity>
              </View>
            </View>



            <View style={{ marginTop: 20 }}>
              <CommonHistorySectionHeader
                heading1={"Description"}
                heading2={"Units"}
                heading3={"Rate per unit"}
                heading4={"Total Cost"}
                noOfColumn={4}
                columnSize={[0.3, 0.2, 0.25, 0.25]}
              />
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 3 }}>
                {billingList && billingList.map((item, index) => {
                  return (
                    <View style={{ flexDirection: 'row', borderBottomColor: "#91939F", borderBottomWidth: 1, paddingVertical: 13 }}>

                      <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.3 }]}>{item.service_name}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.2 }]}>{item.service_units}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.25 }]}>{item.service_rate_per_unit}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.25 }]}>{item.service_amount}</Text>
                    </View>

                  )
                })}
              </ScrollView>
            </View>



            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                  <RenderPaymentTypeRadioButton amountDetails={amountDetails} />
                </View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Sub Amount"} textKey={""} value={""} amount={amountDetails.sub_amount} />
                  <RenderAmountCalculationRow label={"Discount %"} textKey={""} value={""} amount={amountDetails.discount_percentage} />
                  <RenderAmountCalculationRow label={"Discount Amount"} textKey={""} value={""} amount={amountDetails.discount_amount} />
                  <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={amountDetails.round_off} />
                  <RenderAmountCalculationRow label={"Advance Amount"} textKey={""} value={""} amount={amountDetails.detected_advance_amount} />
                </View>
              </View>
            </View>



            <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1 }}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                <RenderAmountCalculationRow label={"Paid Amount"} textKey={""} value={""} amount={amountDetails.paid_amount} />
              </View>
            </View>

            {amountDetails.balance_amount ? 
              <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1  }}>
                <View style={{ flex: 0.6 }}></View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Balance Amount"} textKey={""} value={""} amount={amountDetails.balance_amount ? amountDetails.balance_amount : 0} />
                </View>
              </View>:null
             }

            {
              props.BillStatus != "canceled" ?
              <View style={{flexDirection:"row",justifyContent: "center",alignItems: "center",alignContent: 'center', alignSelf: 'center'}}>
             {isAdminAccess || props.UserEditAccess ?  
              <TouchableOpacity
                onPress={() => {
                  props.showBillingScreenPopup(false, [])
                  props.changeScreen("ipPatientCreateBill", {}, "", {}, "", patientDetails, "", {}, "", {}, false, false, false, false, amountDetails)
                }}                   
                style={{
                  width: 100,
                  height: 35, backgroundColor: color.themeDark, borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 10,
                  alignContent: 'center',
                  alignSelf: 'center'
                }} >
                <Text style={{ color: "#FFF" }}>{"Modify Bill"}</Text>
              </TouchableOpacity> : null }
            <TouchableOpacity
                onPress={() => setCancelBillPress(true)}
                disabled = {amountDetails.is_advance ? true : false}
                style={{
                  width: 100,
                  height: 35, backgroundColor: amountDetails.is_advance ? "#cccccc" : color.themeDark, borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 10,
                  alignContent: 'center',
                  alignSelf: 'center'
                }} >
                <Text style={{ color: "#FFF" }}>{"Cancel Bill"}</Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  // props.showBillingScreenPopup(false, []) 
                  props.billingTransectionPrint()
                }}                    // disabled={this.state.saveDisable}
                style={{
                  width: 100,
                  height: 35, backgroundColor: color.themeDark, borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 10,
                  alignContent: 'center',
                  alignSelf: 'center'
                }} >
                <Text style={{ color: "#FFF" }}>{"Print"}</Text>
              </TouchableOpacity>
            </View>
            :
            <View>
              <Text style={BillPopUpstyle.CancelledBillText}>Bill Cancelled !</Text>
            </View>
            }


          </View>
          {cancelBillPress ?
        <View
          style={{
            position: "absolute",
            zIndex: 0,
            height: screenHeight,
            width: screenWidth,
            justifyContent: "center",
            alignItems: "center",
            alignSelf:"center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white", width: "30%", borderRadius: 5,
            }}
          >
            <View style={{ paddingHorizontal: 20, paddingVertical: 13 }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ flex: 0.92, flexDirection: "row", justifyContent: "space-between" }}>

                </View>
                <View style={{ flex: 0.08 }}>
                  <TouchableOpacity onPress={() => setCancelBillPress(false)} style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: "center", paddingVertical: 8, backgroundColor: color.themeDark, borderRadius: 50 }}>
                    <Icon size={14} name={"close"} color={"#fff"} />
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                <Text style={{ color: "#00b4ae", fontSize: 20, fontWeight: 'bold' }}>Reason:</Text>
                <View style={{ flexDirection: 'row', paddingTop: 10, marginLeft: 30 }}>
                  {
                    reasonsList.map((item, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() => { setSelectedReason(item.value) }}
                          style={{ marginRight: 20, flexDirection: "row", alignItems: "center" }}
                          key={index}
                        >
                          <View style={{
                            backgroundColor: selectedReason == item.value ? color.themeDark : "",
                            marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
                          }}></View>
                          <Text style={{ fontWeight: "500", fontSize: 13 }}>{item.label}</Text>

                        </TouchableOpacity>
                      )
                    })
                  }

                </View>
                <Text style={{ color: "#00b4ae", fontSize: 15, fontWeight: 'bold', paddingTop: 15 }}>Others</Text>
                <View style={{ paddingTop: 10, marginLeft: 30 }}>
                  <TextInput
                    style={{ border: '1px solid #e3e5e6', width: '90%', height: 30, paddingTop: 10, padding: 5 }}
                    maxLength={20}
                    onChange={(text) => { setSelectedReason(text.target.value) }}
                  />

                </View>
                <TouchableOpacity onPress={() => {
                  // props.printCancel(selectedReason)
                  onSubmitCancel(selectedReason)
                }} style={{ alignSelf: "center", backgroundColor: color.themeDark, borderRadius: 4, marginTop: 20, width: '15%', height: 30, justifyContent: 'center' }}>
                  <Text style={{ color: 'white', fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>OK</Text>
                </TouchableOpacity>
              </View>
            </View>

          </View>
          <View style={{ position: 'revert' }}>
            {
              responseType == "error" ?
                errorAlert()
                : null
            }
          </View>

        </View>
        : null
      }


        </View>
      </View>
    </View>
  )
}


export const RenderAmountCalculationRow = (props) => {
  console.log(">>>>>>>>>>>" + JSON.stringify(props))
  return (
    <View style={BillPopUpstyle.AmtCalcWrapper}>
      <View style={BillPopUpstyle.AmtCalcTextView}>
        <Text style={BillPopUpstyle.AmtCalcText}>{props.label}</Text>
      </View>

      <View style={BillPopUpstyle.AmtCalcEmptyView}></View>
      <View style={BillPopUpstyle.AmtCalcDataView}>
        <Text style={BillPopUpstyle.AmtCalcAmount}>{props.label == "Discount %" ? "" : "₹ "}{props.amount}</Text>
      </View>
    </View>
  )
}

export const RenderPaymentTypeRadioButton = (props) => {
  var listOfPaymentType = []
  var payment_type = typeof(props.amountDetails.payment_type ? props.amountDetails.payment_type : props.amountDetails.payment_mode) == "number" ? paymentReturnType(props.amountDetails.payment_type ? props.amountDetails.payment_type : props.amountDetails.payment_mode) : null
  var payment_type_lab = typeof(props.amountDetails.payment_type) == "string" ? props.amountDetails.payment_type : null
  payment_type ? 
  listOfPaymentType = [
    { label: "Cash", value: "Cash" },
    { label: "Card", value: "Card" },
    { label: "UPI", value: "UPI" },
    { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "Insurance", value: "Insurance" },
   //  { label: "Cheque", value: "Cheque" }
 ] : 
 listOfPaymentType = [
  { label: "Cash", value: "Cash" },
  { label: "Card", value: "Card" },
  { label: "Cash and Card", value: "Cash and Card" },
  { label: "UPI", value: "UPI" },
  { label: "Bank Transfer", value: "Bank Transfer" },
  { label: "Insurance", value: "Insurance" },
 //  { label: "Cheque", value: "Cheque" }
]
  return (
    <View>
      <Text style={BillPopUpstyle.PaymentTypeText}>{"Payment Type"}</Text>

      <View style={BillPopUpstyle.PaymtRadioButWrapper}>
        {
          listOfPaymentType.map((item, index) => {
            return (
              <TouchableOpacity onPress={() => {
                // this.setState({
                //   selectedPaymentType: item.value
                // })
              }} style={BillPopUpstyle.PaymentTypeRadio} key={index}>
                <View style={[{
                  backgroundColor: payment_type ? payment_type.paymentType1 == item.value || payment_type.paymentType2 == item.value ? color.themeDark : null : payment_type_lab == item.value ? color.themeDark : null,
                }, BillPopUpstyle.PaymentTypeRadioButton]}></View>
                <Text style={BillPopUpstyle.PatientTypeRadioLabelTxt}>{item.label}</Text>
              </TouchableOpacity>
            )
          })
        }
      </View>
    </View>
  )
}

export const BillingTxnPopupDetails = (props) => {
  return (
    <View style={BillPopUpstyle.BillingTxnPopupDetailsMainView}>
      <Text style={[{ width: props.detailsViewKey == "left" ? '7.16vw' : '6.51vw'}, BillPopUpstyle.BillingTxnPopupDetailsLabel]}>{props.label}</Text>
      <Text style={BillPopUpstyle.BillingTxnPopupDetailsColon}>{":"}</Text>
      <Text style={BillPopUpstyle.BillingTxnPopupDetailsData}>{props.value}</Text>
    </View>
  )
}

export const QMSHeader = (props) => {
  return (
    <View style={{ flexDirection: "row", height: 65 }}>
      <View style={{ flex: 0.18, backgroundColor: color.white }}>
        <Image
        source={ImagePath.LogoWithText}
        style={{ height: "100%", width: '100%' }} />
      </View>
      <View style={{ flex: 0.82, backgroundColor: color.themeDark, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
          <TouchableOpacity onPress={() => { props.navigation.navigate('rootComponent') }}>
            {/* <Arrow
              name="arrow-left"
              size={24}
              color={color.white}
              onPress={() => {
                // this.props.navigation.goBack()
              }}
            /> */}
            <Image 
            source={ImagePath.BackArrow}
            style={{ height: 25, width: 25 }} />
          </TouchableOpacity>

          <Text style={{ color: color.white, fontSize: 20, fontWeight: '500', marginHorizontal: 12 }}>{"Q Management System"}</Text>
        </View>
      </View>
    </View>
  )
}

export const ShowShiftCounterList = (props) => {
  var shiftcounterList = props.shiftcounterList;
  var selectedCounter = props.selectedCounter;
  return (

    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >

        <View style={{ backgroundColor: color.sectionHeadingDarkColor, paddingHorizontal: 5, paddingVertical: 20, width: 400, borderRadius: 4 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 40, backgroundColor: color.white }}>
            <Text style={{ fontSize: 15, fontWeight: '500' }}>{"Shift Counter"}</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 30, borderRadius: 4 }}>
            <Text style={{ fontSize: 12, fontWeight: '500' }}>{"Room No"}</Text>
            <Text style={{ fontSize: 12, fontWeight: '500' }}>{"Department No"}</Text>
            <Text style={{ fontSize: 12, fontWeight: '500' }}>{"In / Out"}</Text>
          </View>
          <ScrollView style={{ maxHeight: 500 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            {
              shiftcounterList.map((item, index) => {
                return (
                  <TouchableOpacity onPress={() => {
                    props.showSelectedShiftcounterList(true, shiftcounterList, item)
                  }} style={[{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 30, borderRadius: 4 },
                  selectedCounter && (selectedCounter.id == item.id) ? { backgroundColor: color.white, paddingHorizontal: 5, borderRadius: 4 } : {}
                  ]}>
                    <Text style={{ fontSize: 12, marginLeft: 10 }}>{item.room_number}</Text>
                    <Text style={{ fontSize: 12 }}>{item.name}</Text>
                    <View style={{ height: 10, width: 10, backgroundColor: item.is_active ? "green" : "red", borderRadius: 30, marginRight: 10 }} />
                  </TouchableOpacity>
                )
              })
            }
          </ScrollView>

          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 10 }}>
            <CommonButton
              item={{}}
              selectedvalue={{}}
              butttonText={"Send"}
              buttonType={"theme"}
              buttonIcon={""}
              rightIcon={false}
              buttonAction={props.saveShiftCounter}
              buttonKey={"selectedSessionValue"} />
            <CommonButton
              item={{}}
              selectedvalue={{}}
              butttonText={"Close"}
              buttonType={"outlineTheme"}
              buttonIcon={""}
              rightIcon={false}
              buttonAction={props.hideSelectedShiftcounterListFlag}
              buttonKey={"closeShiftCounterModal"} />
          </View>
        </View>
        {/* <ScrollView style={{maxHeight:500}} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
        {
          shiftcounterList.map((item,index)=>{
            return(
              <TouchableOpacity onPress={()=>{
                props.showSelectedShiftcounterList(true,shiftcounterList,item)
              }} style={[{flexDirection:'row',justifyContent:'space-between',alignItems:'center',height:30,borderRadius:4},
              selectedCounter && (selectedCounter.id ==item.id)?{backgroundColor:color.white,paddingHorizontal:5,borderRadius:4}:{}
              ]}>
                <Text style={{fontSize:12}}>{item.slug}</Text>
                <Text style={{fontSize:12}}>{item.name}</Text>
              </TouchableOpacity>
            )
          })
        }
      </ScrollView>
      <CommonButton
        item={selectedCounter}
        selectedvalue={{}}
        butttonText={"Send"}
        buttonType={"theme"}
        buttonIcon={""}
        rightIcon={false}
        buttonAction={props.saveShiftCounter}
        buttonKey={"selectedSessionValue"} /> */}
      </View>
    </View>
  )
}

export const SignsInSubHeader = (props) => {

  return (

    <View style={{ flex: 0.5, flexDirection: "row", justifyContent: "flex-end" }} >

      <View style={{ marginRight: 10 }} >
        <CommonButton
          item={{ label: "+", value: "+" }}
          selectedvalue={props.selectedValue}
          butttonText={"+"}
          buttonType={"outlineNonTheme"}
          buttonIcon={""}
          rightIcon={false}
          buttonAction={props.onPressButton}
          buttonKey={props.stateKey} />
      </View>
      <View style={{}} >
        <CommonButton
          item={{ label: "-", value: "-" }}
          selectedvalue={props.selectedValue}
          butttonText={"-"}
          buttonType={"outlineNonTheme"}
          buttonIcon={""}
          rightIcon={false}
          buttonAction={props.onPressButton}
          buttonKey={props.stateKey} />
      </View>
    </View>
  )
}

export const RightSectionSPH = (props) => {

  return (
    <View>
      <CommonSectionHeader heading={props.heading} signOptions={true} stateKey={props.stateSignKey} selectedValue={props.selectedSign} onPressButton={props.onPressButton} />
      {/* {this.renderSubHeadingBorder("", false, true)} */}
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: '1vw' }}>
        {
          props.options && props.options.map((item, index) => {

            let firstChar = (item.value).toString().substring(0, 1);
            let loopthru = props.selectedSign.value == firstChar ? true : false

            if (item.value === "0") {
              loopthru = true
            }
            // console.log(" firstChar " + firstChar)
            // console.log(" loopthru " + loopthru)

            return (
              loopthru ? <View style={{ marginBottom: '0.6vw', marginRight: '0.6vw' }} key={index}>
                <CommonButton
                  disable={props.disable}
                  item={item}
                  selectedvalue={props.selectedvalue}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={props.onPressButton}
                  buttonKey={props.stateOptionKey} />
              </View> : null
            )
          })
        }
      </View>
    </View>
  )
}


export const LabTransmitedChanges = (props) => {
  // console.log("Noooooo" + JSON.stringify(props.showLabTransmitedList))
  var showLabTransmitedList = props.showLabTransmitedList
  var fullDate = new Date()
  const [nameSearch, setNameSearch] = useState("");
  const [datefilter, setDateFilter] = useState(moment(fullDate));

  useEffect(() => {
    props.getTransmittedChangeList(nameSearch, moment(datefilter)) 

  },[datefilter]);
  return (
    <TouchableWithoutFeedback onPress={() => { props.showLabtransmittedPopup(false) }}>
      <View style={{ position: "absolute" }}>
        <View
          style={{
            position: "absolute",
            zIndex: -1,
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <TouchableWithoutFeedback onPress={() => { props.showLabtransmittedPopup(true) }}>
            <View
              style={{
                backgroundColor: "white",
                width: "50vw" ,
                height: "30vw",
                borderRadius: 4,
              }}
            >
              <View style={{ margin: 20 }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: '1.5vw' }}>
                    {"Orders & Appointments "}
                  </Text>
                  <TouchableOpacity onPress={() => {
                    props.showLabtransmittedPopup(false)
                  }} style={{  fontSize: '2vw' }}>
                    {/* <Icon name="window-close-o" size={25} color={color.themeDark} /> */}
                    <Image
                    source={ImagePath.Close}
                     style={{ height: "1.5vw", width: "1.5vw", tintColor: color.themeDark }} />

                  </TouchableOpacity>
                 
                </View>
                <View style={{ marginTop: 5 }}>
                  <View>
                    <View style={{ marginBottom: 15, width: "95%", flexDirection: "row" }}>
                      <TextInput
                        value={ nameSearch }
                        placeholder="Search Patient..."
                        onChangeText={(text) => { 
                          setNameSearch(text)
                          props.getTransmittedChangeList(text, moment(datefilter)) 
                        }}
                        style={{
                          backgroundColor: color.themeShade,
                          color: "black",
                          height: '2.5vw',
                          flexDirection: "row",
                          width: "20vw",
                          fontSize: '1vw'
                        }}
                      />
                      <View style={{ marginBottom: '2vw', width: "2.5vw" }}>

                     <TouchableOpacity
                        // onPress={() => {
                        //   this.createPatient()
                       // }} 
                      style={[{
                         backgroundColor: color.themeDark,
                         padding: '0.6vw',
                         height: '2vw'
                         }, Style.allButtonBorderRadius]}>
                         <Icon name="search" size={'1vw'} color={color.white} />

                     </TouchableOpacity>
                    </View>
                      <DatePicker
                        clearIcon={false}
                        // size="small"
                        defaultValue={moment(datefilter)}
                        format={"YYYY-MM-DD"}
                        // popupStyle={{ fontSize: '1vw'}}
                        style={{
                          zIndex: -1, width: '13vw', marginBottom: 10, marginLeft: 10,
                          borderColor: '#CDD1D5',
                          height: '2vw', borderRadius: 4, borderWidth: 1,
                        }}
                        onChange={(date) => {
                          var fulldate = new Date(date);
                          var converted_date = moment(fulldate);
                          setDateFilter(converted_date)
                          props.getTransmittedChangeList(nameSearch, converted_date);
                        }}
                      />
                    </View>
                    
                  </View>
                  <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{height: "15.63vw" }}>
                  { showLabTransmitedList && showLabTransmitedList.length > 0 ?
                   <View>
                    {showLabTransmitedList && showLabTransmitedList.length > 0 && showLabTransmitedList.map((item, index) => {
                      return (
                        <View style={{ flexDirection: 'row', width: "48vw", backgroundColor: item.transmit_date ? "#f4f5fc" : "#f5f5f5", padding: 8, alignItems: 'center', borderRadius: 4, marginBottom: 10 }}>
                          <View style={{ width: "5vw" }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <Text style={{ fontSize: '1vw' }}>{index + 1}</Text>
                              <Image
                                source={item.patient_photo_url ? { uri: item.patient_photo_url } : ImagePath.DefaultProfile}
                                style={{ backgroundColor: color.white, marginHorizontal: '1vw', height: '3vw', width: '3vw', borderRadius: 50, borderWidth: 1, borderColor: color.white }}
                              />
                            </View>
                          </View>

                          <View style={{ width: "10vw" }}>
                            <Text style={{ fontSize: '1vw', marginBottom: 4 }}>{item.patient_name}</Text>
                            <Text style={{ fontSize: '0.8vw' }}>{item.patient_mobile_number}</Text>
                          </View>
                          <View style={{ width: "18vw", flexDirection: 'row', justifyContent: "space-between" }}>
                            <View>
                              <Text style={{ fontSize: '1vw', marginBottom: 4 }}>{item.doctor_name}</Text>
                              <Text style={{ fontSize: '0.8vw' }}>{item.doctor_name}</Text>
                            </View>
                            {
                              item.transmit_date ?
                                <Image 
                                source={ImagePath.TransmittedOrders}
                                style={{ height: '2vw', width: '2vw', tintColor: color.themeDark, marginRight: 10 }} />
                                : null
                            }
                          </View>
                          <View style={{ width: "10vw"}}>
                            <TouchableOpacity
                              onPress={() => {
                                props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", item)
                                if (item.transmit_date) {
                                  props.labTransmitPrint(item)
                                }
                                props.showLabtransmittedPopup(false)

                              }}
                              style={[{
                                backgroundColor: color.themeDark,
                                padding: '0.8vw'
                              }, Style.allButtonBorderRadius]}>
                              <Text style={{ color: color.white, textAlign: 'center', fontSize: '1vw' }}>{"Proceed to Billing"}</Text>
                            </TouchableOpacity>
                          </View>

                        </View>
                      )
                    })}
                    </View> : <Text style={{ color: color.grayShade, alignSelf: "center", fontSize: '1vw' }} >{"No records to be shown"}</Text>
                   }
                  </ScrollView>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export const ImageLabBillingTxnPopup = (props) => {

  var billingList = props.billingImageLabPopupList.billing_details
  var patientDetails = props.billingImageLabPopupList.patient_details
  var amountDetails = props.billingImageLabPopupList.bill_summary_details
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "60%", borderRadius: 5,
          }}
        >

          <View style={{ paddingHorizontal: 20, paddingVertical: 13 }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flex: 0.92, flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Name"} value={patientDetails.patient_name} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Mobile Number"} value={patientDetails.mobile_number} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Bill Number"} value={amountDetails.invoice_number} />

                </View>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Age"} value={patientDetails.age} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Date"} value={amountDetails.bill_date} />
                </View>
              </View>
              <View style={{ flex: 0.04 }}>
                <TouchableOpacity onPress={() => {
                  props.showImageLabBillingTxnPopup(false, [])
                }} style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: "center", paddingVertical: 8, backgroundColor: color.themeDark, borderRadius: 50 }}>
                  <Icon size={26} name={"close"} color={"#fff"} />
                </TouchableOpacity>
              </View>
            </View>



            <View style={{ marginTop: 20 }}>
              <CommonHistorySectionHeader
                heading1={"Description"}
                heading2={"Units"}
                heading3={"Rate per unit"}
                heading4={"Total Cost"}
                noOfColumn={4}
                columnSize={[0.4, 0.1, 0.2, 0.3]}
              />
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 3 }}>
                {billingList && billingList.map((item, index) => {
                  return (
                    <View style={{ flexDirection: 'row', borderBottomColor: "#91939F", borderBottomWidth: 1, paddingVertical: 13 }}>

                      <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.4 }]}>{item.test_name}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>{item.rate_per_unit}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.2 }]}>{parseInt(item.rate_per_unit) * parseFloat(item.test_amount)}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.3 }]}>{item.test_amount}</Text>
                    </View>

                  )
                })}
              </ScrollView>
            </View>



            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                  <RenderPaymentTypeRadioButton amountDetails={amountDetails} />
                </View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Sub Amount"} textKey={""} value={""} amount={amountDetails.total_amount} />
                  <RenderAmountCalculationRow label={"Discount %"} textKey={""} value={""} amount={amountDetails.discount_percentage} />
                  <RenderAmountCalculationRow label={"Discount Amount"} textKey={""} value={""} amount={amountDetails.discount_amount} />
                  <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={parseFloat(amountDetails.round_off).toFixed(2)} />
                </View>
              </View>
            </View>



            <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1 }}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                <RenderAmountCalculationRow label={"Net Amount"} textKey={""} value={""} amount={amountDetails.net_amount} />
              </View>
            </View>

            <View style={{flexDirection:"row",justifyContent:"center",alignSelf:"center"}}>
              <TouchableOpacity
                onPress={() => {
                  props.imageLabTxnPrint()
                }}
                style={{
                  width: 100,
                  height: 35, backgroundColor: color.themeDark, borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  alignContent: 'center',
                  alignSelf: 'center'
                }} >
                <Text style={{ color: "#FFF" }}>{"Print"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export const paymentReturnType = (type) =>{
  var Data = {
    paymentType1 : "",
    paymentType2 : ""
  }
  switch(type){
    case parseInt("1") : return Data = {paymentType1 : "Cash" , paymentType2 : ""} 
    case parseInt("2") : return Data = {paymentType1 : "Card" , paymentType2 : ""}
    case parseInt("3") : return Data = {paymentType1 : "Cash" , paymentType2 : "Card"}
    case parseInt("4") : return Data = {paymentType1 : "UPI" , paymentType2 : ""}
    case parseInt("5") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : ""}
    case parseInt("6") : return Data = {paymentType1 : "Insurance" , paymentType2 : ""}
    case parseInt("7") : return Data = {paymentType1 : "Cheque" , paymentType2 : ""}
    case parseInt("8") : return Data = {paymentType1 : "Cash" , paymentType2 : "UPI"}
    case parseInt("9") : return Data = {paymentType1 : "Cash" , paymentType2 : "Bank Transfer"}
    case parseInt("10") : return Data = {paymentType1 : "Cash" , paymentType2 : "Insurance"}
    case parseInt("11") : return Data = {paymentType1 : "Cash" , paymentType2 : "Cheque"}
    case parseInt("12") : return Data = {paymentType1 : "Card" , paymentType2 : "UPI"}
    case parseInt("13") : return Data = {paymentType1 : "Card" , paymentType2 : "Bank Transfer"}
    case parseInt("14") : return Data = {paymentType1 : "Card" , paymentType2 : "Insurance"} 
    case parseInt("15") : return Data = {paymentType1 : "Card" , paymentType2 : "Cheque"}
    case parseInt("16") : return Data = {paymentType1 : "UPI" , paymentType2 : "Bank Transfer"}
    case parseInt("17") : return Data = {paymentType1 : "UPI" , paymentType2 : "Insurance"}
    case parseInt("18") : return Data = {paymentType1 : "UPI" , paymentType2 : "Cheque"}
    case parseInt("19") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : "Insurance"} 
    case parseInt("20") : return Data = {paymentType1 : "Bank Transfer" , paymentType2 : "Cheque"} 
    case parseInt("21") : return Data = {paymentType1 : "Insurance" , paymentType2 : "Cheque"}
    default : return Data
  }
}

export const returnPaymentMode = (type , amount_1, amount_2, trans_1, trans_2) =>{
  var Data = {
    "paymentMode" : "",
    "paymentAmt1" : 0,
    "paymentAmt2" : 0,
    "trans_Num1" :  "",
    "trans_Num2" : ""
  }
  switch (type) {
    case "Cash":
     return Data = {
        paymentMode : 1,
        paymentAmt1 : amount_1,
        paymentAmt2 : amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
    }
    case "Card":
      return Data ={
        paymentMode: 2,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "CardCash":
      return Data ={
        paymentMode: 3,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CashCard":
      return Data ={
        paymentMode: 3,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "UPI":
      return Data ={
        paymentMode: 4,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Bank Transfer":
      return Data ={
        paymentMode: 5,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Insurance":
      return Data ={
        paymentMode: 6,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Cheque":
      return Data ={
        paymentMode: 7,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "CashUPI":
      return Data ={
        paymentMode: 8,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "UPICash":
      return Data ={
        paymentMode: 8,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CashBank Transfer":
      return Data ={
        paymentMode: 9,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Bank TransferCash":
      return Data ={
        paymentMode: 9,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CashInsurance":
      return Data ={
        paymentMode: 10,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "InsuranceCash":
      return Data ={
        paymentMode: 10,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CashCheque":
      return Data ={
        paymentMode: 11,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "ChequeCash":
      return Data ={
        paymentMode: 11,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CardUPI":
      return Data ={
        paymentMode: 12,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "UPICard":
      return Data ={
        paymentMode: 12,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CardBank Transfer":
      return Data ={
        paymentMode: 13,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Bank TransferCard":
      return Data ={
        paymentMode: 13,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CardInsurance":
      return Data ={
        paymentMode: 14,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "InsuranceCard":
      return Data ={
        paymentMode: 14,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "CardCheque":
      return Data ={
        paymentMode: 15,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "ChequeCard":
      return Data ={
        paymentMode: 15,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "UPIBank Transfer":
      return Data ={
        paymentMode: 16,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Bank TransferUPI":
      return Data ={
        paymentMode: 16,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "InsuranceUPI":
      return Data ={
        paymentMode: 17,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "UPIInsurance":
      return Data ={
        paymentMode: 17,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "ChequeUPI":
      return Data ={
        paymentMode: 18,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "UPICheque":
      return Data ={
        paymentMode: 18,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "InsuranceBank Transfer":
      return Data ={
        paymentMode: 19,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "Bank TransferInsurance":
      return Data ={
        paymentMode: 19,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "Bank TransferCheque":
      return Data ={
        paymentMode: 20,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "ChequeBank Transfer":
      return Data ={
        paymentMode: 20,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    case "InsuranceCheque":
      return Data ={
        paymentMode: 21,
        paymentAmt1: amount_1,
        paymentAmt2: amount_2,
        trans_Num1 : trans_1,
        trans_Num2 : trans_2
      }
    case "ChequeInsurance":
      return Data ={
        paymentMode: 21,
        paymentAmt1: amount_2,
        paymentAmt2: amount_1,
        trans_Num1 : trans_2,
        trans_Num2 : trans_1
      }
    default:
      return Data 
  }
}


export const LabBillingTransactionPopup = (props) => {
  const [cancelBillPress, setCancelBillPress] = useState(false);
  const [selectedReason, setSelectedReason] = useState("")
  const [responseMessage, setresponseMessage] = useState("")
  const [responseType, setresponseType] = useState("")
  const [isAdminAccess, setAdminAccess ] = useState(props.isAdminAccess)
  var billingList = props.billingLabTransactionPopupList.billing_details
  var patientDetails = props.billingLabTransactionPopupList.patient_details
  var amountDetails = props.billingLabTransactionPopupList.bill_summary_details

  const showResposeValue = (type, message) => {
    setresponseMessage(message);
    setresponseType(type);
    setTimeout(() => {
      setresponseMessage('');
      setresponseType('');
    }, 3000)
  }

  const onSubmitCancel = (reason) => {
    if (reason) {
      props.labBillCancel(reason)
    } else {
      showResposeValue('error', 'Please enter the reason for cancellation')
    }
  }

  const errorAlert = () => {

    // let errorList = responseMessage
    return (
      <View style={[BillPopUpstyle.ErrorAlrtMainView, {marginBottom:'6.51vw'}]}>
        <View style={BillPopUpstyle.ErrAlrtContentView}>
          <View style={BillPopUpstyle.ErrIconView}>
            <Icon size={'1.63vw'} name={"ban"} color={"#FFF"} /></View>
          <View style={BillPopUpstyle.ErrAlrtMsgView} >
            <View>
              <Text style={BillPopUpstyle.ErrAlrtMsgTxt}>{"ERROR: "}
                {responseMessage}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { setresponseType('') }} style={BillPopUpstyle.ErrAlrtCloseIconView}>
          <Icon size={'1.63vw'} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }

  var reasonsList = [
    { label: "Double Entry", value: "Double Entry" },
    { label: "Wrongly Billed", value: "Wrongly Billed" },
  ]
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={BillPopUpstyle.MainView}
      >
        <View
          style={BillPopUpstyle.MainWrapper}
        >

          <View style={BillPopUpstyle.Wrapper2}>
            <View style={BillPopUpstyle.TopView}>
              <View style={BillPopUpstyle.PatientDetailsView}>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Name"} value={patientDetails.patient_name} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Mobile Number"} value={patientDetails.mobile_number} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Bill Number"} value={amountDetails.invoice_number} />

                </View>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Age"} value={patientDetails.age} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Date"} value={amountDetails.invoice_date ? moment(amountDetails.invoice_date).format("DD-MM-YYYY") : null} />
                </View>
              </View>
              <View style={{ flex: 0.04 }}>
                <TouchableOpacity onPress={() => {
                  props.showLabBillingTransactionPopup(false, [])
                  // props.labBillingTransectionPrint() 
                }} style={BillPopUpstyle.CloseIconWrapper}>
                  {/* <Image source={require('../../../assets/images/Printicon.png')} style={{ height: 30, width: 30 }} /> */}
                  <Icon size={'1.69vw'} name={"close"} color={"#fff"} />
                </TouchableOpacity>
              </View>
            </View>



            <View style={BillPopUpstyle.ListHeaderView}>
              <CommonHistorySectionHeader
                heading1={"Description"}
                heading2={"Units"}
                heading3={"Rate per unit"}
                heading4={"Total Cost"}
                noOfColumn={4}
                columnSize={[0.4, 0.1, 0.2, 0.3]}
              />
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 3 }}>
                {billingList && billingList.map((item, index) => {
                  return (
                    <View style={BillPopUpstyle.ListDataView}>

                      <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.4 }]}>{item.test_name}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>{item.test_units}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.2 }]}>{item.test_rate_per_unit}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.3 }]}>{item.amount}</Text>
                    </View>

                  )
                })}
              </ScrollView>
            </View>



            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                  <RenderPaymentTypeRadioButton amountDetails={amountDetails} />
                </View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Sub Amount"} textKey={""} value={""} amount={amountDetails.total_amount} />
                  <RenderAmountCalculationRow label={"Discount %"} textKey={""} value={""} amount={amountDetails.discount_percentage} />
                  <RenderAmountCalculationRow label={"Discount Amount"} textKey={""} value={""} amount={amountDetails.discount_amount} />
                  <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={parseFloat(amountDetails.round_off).toFixed(2)} />
                </View>
              </View>
            </View>



            <View style={BillPopUpstyle.NetAmountView}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                <RenderAmountCalculationRow label={"Net Amount"} textKey={""} value={""} amount={amountDetails.net_amount} />
              </View>
            </View>

            {
              props.BillStatus != "canceled" ?
              <View style={BillPopUpstyle.ButtonWrapper}>
             { isAdminAccess || props.UserEditAccess ? 
              <TouchableOpacity
                onPress={() => {
                  props.showLabBillingTransactionPopup(false, [])
                  props.changeScreen("laboratoryCreateBill", {}, "", {}, "", patientDetails, "", {}, "", patientDetails, false, false, false, false, amountDetails)
                }}                   
                style={BillPopUpstyle.ButtonStyle} >
                <Text style={BillPopUpstyle.ButtonText}>{"Modify Bill"}</Text>
              </TouchableOpacity> : null }
            <TouchableOpacity
                // onPress={() => { props.printCancel() }}                    // disabled={this.state.saveDisable}
                onPress={() => setCancelBillPress(true)}
                style={BillPopUpstyle.ButtonStyle} >
                <Text style={BillPopUpstyle.ButtonText}>{"Cancel Bill"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  props.labBillingTransectionPrint()
                  // props.showLabBillingTransactionPopup(false, []) 
                }}
                style={BillPopUpstyle.ButtonStyle} >
                <Text style={BillPopUpstyle.ButtonText}>{"Print"}</Text>
              </TouchableOpacity>
            </View> :
            <View>
            <Text style={BillPopUpstyle.CancelledBillText}>Bill Cancelled !</Text>
          </View>
            }


          </View>
          </View>
          </View>
          {cancelBillPress ?
        <View
          style={BillPopUpstyle.MainView}
        >
          <View
            style={BillPopUpstyle.CloseBillPopupMainView}
          >
            <View style={BillPopUpstyle.ClosePopUpWrapper}>
              <View style={BillPopUpstyle.TopView}>
                <View style={BillPopUpstyle.PatientDetailsView}>

                </View>
                <View style={{ flex: 0.08 }}>
                  <TouchableOpacity onPress={() => setCancelBillPress(false)} style={BillPopUpstyle.CloseIconWrapper}>
                    <Icon size={'0.91vw'} name={"close"} color={"#fff"} />
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                <Text style={BillPopUpstyle.ClosingReasonText}>Reason:</Text>
                <View style={BillPopUpstyle.ClosingReasonListView}>
                  {
                    reasonsList.map((item, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() => { setSelectedReason(item.value) }}
                          style={BillPopUpstyle.ClosingReasonListRadioBtn}
                          key={index}
                        >
                          <View style={[{
                            backgroundColor: selectedReason == item.value ? color.themeDark : ""
                          }, BillPopUpstyle.PaymentTypeRadioButton]}></View>
                          <Text style={BillPopUpstyle.PatientTypeRadioLabelTxt}>{item.label}</Text>

                        </TouchableOpacity>
                      )
                    })
                  }

                </View>
                <Text style={BillPopUpstyle.ClosePopUpOthersTxt}>Others</Text>
                <View style={BillPopUpstyle.ReasonInputView}>
                  <TextInput
                    style={BillPopUpstyle.ReasonInput}
                    maxLength={20}
                    onChange={(text) => { setSelectedReason(text.target.value) }}
                  />

                </View>
                <TouchableOpacity onPress={() => {
                  // props.printCancel(selectedReason)
                  onSubmitCancel(selectedReason)
                }} style={BillPopUpstyle.ClosePopUpOkBtn}>
                  <Text style={BillPopUpstyle.ClosePopupOkBtnText}>OK</Text>
                </TouchableOpacity>
              </View>
            </View>

          </View>
          <View style={{ position: 'revert' }}>
            {
              responseType == "error" ?
                errorAlert()
                : null
            }
          </View>

        </View>
        : null
      }


        </View>
  )
}

export const LabSavedBill = (props) => {
  // useEffect({

  // },[props.showLabSavedBillList])

  // console.log("Noooooo" + JSON.stringify(props.showLabTransmitedList))
  var showLabSavedBillList = props.showLabSavedBillList
  const [conformationPopUp, setConformation] = useState(false)
  // console.log(getUnLockDoctor)
  return (
    <TouchableWithoutFeedback onPress={() => { props.showLabSavedBillPopup(false) }}>
      <View style={{ position: "absolute" }}>
        <View
          style={{
            position: "absolute",
            zIndex: -1,
            height: screenHeight,
            width: screenWidth,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <TouchableWithoutFeedback onPress={() => { props.showLabSavedBillPopup(true) }}>
            <View
              style={{
                backgroundColor: "white",
                width: "48%",
                // height: "60%",
                borderRadius: 4,
              }}
            >
              <View style={{ margin: 20 }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                  <Text style={{ marginTop: 10, fontSize: 18 }}>
                  Pending Bills
                  </Text>
                  <TouchableOpacity onPress={() => {
                    props.showLabSavedBillPopup(false)
                  }} style={{ marginTop: 10, fontSize: 30 }}>
                    {/* <Icon name="window-close-o" size={25} color={color.themeDark} /> */}
                    <Image
                    source={ImagePath.Close}
                     style={{ height: 25, width: 25, tintColor: color.themeDark }} />
                  </TouchableOpacity>

                </View>
                <View style={{ flexDirection: "row", marginTop: 5, marginBottom: 15 }}>

                  <View style={{ width: "95%" }}>
                    <TextInput
                      value={props.searchValue}
                      placeholder="Search Patient..."
                      onChangeText={(text) => { props.getLabSavedList(text) }}
                      style={{
                        backgroundColor: color.themeShade,
                        color: "black",
                        height: 33

                      }}
                    />
                  </View>

                  <View style={{ width: "5%" }}>

                    <TouchableOpacity
                      // onPress={() => {
                      //   this.createPatient()
                      // }} 
                      style={[{
                        backgroundColor: color.themeDark,
                        padding: 9
                      }, Style.allButtonBorderRadius]}>
                      <Icon name="search" size={15} color={color.white} />

                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ marginTop: 5 }}>
                  <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: 200 }}>



                    {showLabSavedBillList && showLabSavedBillList.length > 0 && showLabSavedBillList.map((item, index) => {
                      return (
                        <View style={{ flexDirection: 'row', width: "100%", backgroundColor: color.themeShade, padding: 8, alignItems: 'center', borderRadius: 4, marginBottom: 10 }}>
                          <View style={{ width: "14%" }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <Text style={{ fontSize: 16 }}>{index + 1}</Text>
                              <Image
                                source={item.patient_photo_url ? { uri: item.photo_url } :ImagePath.DefaultProfile                              
                              }
                                style={{ backgroundColor: color.white, marginHorizontal: 12, height: 40, width: 40, borderRadius: 50, borderWidth: 3, borderColor: color.white }}
                              />
                            </View>
                          </View>

                          <View style={{ width: "25%" }}>
                            <Text style={{ fontSize: 13, marginBottom: 4 }}>{item.patient_name}</Text>
                            <Text style={{ fontSize: 11 }}>{item.phone_number}</Text>
                          </View>
                          <View style={{ width: "25%" }}>
                            <Text style={{ fontSize: 13, marginBottom: 4 }}>{item.invoice_date}</Text>
                            {/* <Text style={{ fontSize: 11 }}>{item.doctor_name}</Text> */}
                          </View>
                          <View style={{ width: "25%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", item, true)

                                props.showLabSavedBillPopup(false)

                              }}
                              style={[{
                                backgroundColor: color.themeDark,
                                padding: 10
                              }, Style.allButtonBorderRadius]}>
                              <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Proceed to Billing"}</Text>
                            </TouchableOpacity>
                         
                          </View>
                          <TouchableOpacity onPress={()=>{ 
                              setConformation(true)
                              props.showLabSavedBillPopup( true)
                             }} 
                             style={{ width: "10%", alignSelf: "center", marginLeft: 20,   }}>
                             <Icon name="close" size={24} color={"#000000"} />
                         </TouchableOpacity>
                        </View>
                      )
                    })}
                  </ScrollView>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
        { conformationPopUp ?
       <View style={{justifyContent: "center", alignItems: "center", height: screenHeight, width: screenWidth, backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
       { showLabSavedBillList && showLabSavedBillList.length > 0 && showLabSavedBillList.map((item, index) => {
        return(
         <View style={{  position: 'absolute', width: "100%", height: "100%", alignItems: "center", flex: 1, justifyContent: "center", paddingLeft: 10, zIndex: 10 }}>
          <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 20 }}>
            <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to discard ? "}</Text>

            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", flexWrap: "wrap-reverse" }}>

              <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                onPress={() => {
                   props.deleteSavedBill(item.bill_summary_id)
                   setConformation(false)
                  }}
              //  disabled = {this.state.isYesButton}
              >
                <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                onPress={() => { setConformation(false) }}>
                <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
              </TouchableOpacity>

            </View>

          </View>
        </View> )
       })}
        </View>  : null
      }     
      </View>
    </TouchableWithoutFeedback>
  );
};


export const CommonDropDownPicker = (props) => {
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          props.changePickerDropDownValue(!props.showPickerDropDownFlag, {})
        }}
        style={[styles.CommonDropDownPickerTouch, {backgroundColor: color.white}]}>
        <Text style={styles.SelectDepartmentText}>{props.selectedValue.name ? props.selectedValue.name : "Select Department"}</Text>
        <Text style={styles.SelectDepartmentIcon}>{"▼"}</Text>
      </TouchableOpacity>
      {
        props.showPickerDropDownFlag ?

          <ScrollView style={[styles.ScrollViewDropDown, {backgroundColor: color.white}]} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

            {
              props.arrayOfData && props.arrayOfData.length > 0 ?
                props.arrayOfData.map((item, index) => {

                  return (
                    <TouchableOpacity key={index} style={[
                      props.selectedValue.id == item.id ?
                        { backgroundColor: color.themeDark } :
                        { backgroundColor: color.white }
                      ,
                      { paddingHorizontal: '1vw', paddingVertical: '0.3vw', zIndex: 2 + index }
                    ]
                    }
                      onPress={() => {
                        props.changePickerDropDownValue(false, item)
                      }}
                    >
                      <Text style={[
                        props.selectedValue.id === item.id ?
                          { color: color.white, fontSize: '0.8vw' } :
                          { color: color.black, fontSize: '0.8vw' }
                      ]
                      }>{item.name}</Text>
                    </TouchableOpacity>
                  )
                })

                : null
            }
          </ScrollView>
          : null
      }

    </View>
  );

}



export const PSYHistorySectionCommonComponent = (props) => {
  function numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }
  const [newButtonValue, updateNewButtonValue] = useState("")

  return (
    // noTextBoxLabel
    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginVertical: 5 }]}>

      <View style={[Style.psyHistoryYesOrNOSectionRowView, Style.allButtonBorderRadius]}>
        <Text style={{ marginLeft: 15, fontSize: 14 }}>{props.cardLabel}</Text>

        {

          props.showTopSubDropDown ?

            <Picker
              selectedValue={props.topSubDropDownValue}
              style={[
                Style.historyYesOrNoSectionShadow,
                Style.allButtonBorderRadius,
                { backgroundColor: color.white, height: 30, marginRight: 15 }
              ]}
              // itemStyle={{ fontSize: 14 }}
              onValueChange={(itemvalue) => {
                props.onChangeSubDropDownValue(props.topSubDropDownKey, itemvalue,)
              }}
            >

              {
                props.topSubDropDownList && props.topSubDropDownList.map((item, index) => {
                  return (
                    <Picker.Item key={index} label={item} value={item} />
                  )
                })
              }
            </Picker>

            :

            props.showTopSubTextBox ?

              <View style={{ paddingVertical: 5, flexDirection: 'row', marginRight: 15, borderRadius: 5, borderBottomColor: "#888888", borderWidth: 1 }}>

                <TextInput
                  // autoFocus={true}
                  value={props.topSubTextBoxValue}
                  style={[{ width: 50, textAlign: "center" }]}
                  onChangeText={(text) => {
                    if (props.topSubTextBoxKey == "birthWight") {
                      var isnum = numberValidation(text)
                      if (isnum) {
                        props.onChangeSubTextBoxValue(props.topSubTextBoxKey, text)
                      }

                    } else {
                      props.onChangeSubTextBoxValue(props.topSubTextBoxKey, text)
                    }
                  }}
                // onSubmitEditing={() => {
                //   props.onSubmitToAddNewButtonValue(newButtonValue)
                // }}
                />
                <Text style={{ borderLeftWidth: 0.5, borderLeftColor: "#888888", paddingHorizontal: 5 }}>{"kgs"}</Text>


              </View>


              :


              props.yesOrNoButtonKey ?
                <View style={{ flexDirection: 'row', marginRight: 15, }}>
                  <TouchableOpacity
                    onPress={() => { props.onChangeYesOrNoValue(true, props.yesOrNoButtonKey, props.yesTextBoxValue, props.yesTextBoxKey) }}
                    style={[Style.historyYoButtonView, props.isSelected ? Style.historyYesButtonSelectedValue : null
                    ]}>
                    <Text style={{ fontSize: 14, color: props.isSelected ? color.white : color.black }}>{"Yes"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      props.onChangeYesOrNoValue(false, props.yesOrNoButtonKey, "", props.yesTextBoxKey)
                    }}
                    style={[Style.historyNoButtonView, !props.isSelected ? Style.historyNoButtonSelectedValue : null
                    ]}>
                    <Text style={{ fontSize: 14, color: !props.isSelected ? color.white : color.black }}>{"No"}</Text>
                  </TouchableOpacity>
                </View>
                : null
        }
      </View>

      {
        props.selectBtnKey && props.isSelected ?

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              props.buttonList && props.buttonList.length > 0 ?
                props.buttonList.map((item, index) => {
                  return (
                    <View key={index} style={{ marginLeft: 8, marginBottom: 8 }}>
                      <CommonButton
                        item={item}
                        selectedvalue={props.selectedBtnValue}
                        butttonText={item.label}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={props.onPressInfections}
                        buttonKey={props.selectBtnKey} />
                    </View>
                  )
                })
                : null
            }


            <View style={{ marginLeft: 8, marginBottom: 8 }}>
              {
                props.showNewButton ?

                  props.newbuttonFlag ?
                    <CommonButton
                      item={{ label: "New", value: "New" }}
                      selectedValue={{}}
                      butttonText={"New"}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={ImagePath.PlusIcon}
                      rightIcon={true}
                      buttonAction={props.onPressNewButton}
                      buttonKey={props.newButtonKey} />
                    :
                    <TextInput
                      autoFocus={true}
                      style={[{ paddingLeft: 5, width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                      onChangeText={(text) => {
                        updateNewButtonValue(text)
                      }}
                      onSubmitEditing={() => {
                        props.onSubmitToAddNewButtonValue(newButtonValue)
                      }}
                    />

                  : null
              }

            </View>


          </View>

          :



          props.dropDownListKey ?
            <Picker
              selectedValue={props.selectedDropDownlist}
              style={[
                Style.historyYesOrNoSectionShadow,
                Style.allButtonBorderRadius,
                { backgroundColor: color.white, height: 40, width: "90%", alignSelf: 'center', marginTop: 10, padding: 10, marginBottom: 10 }
              ]}
              itemStyle={{ fontSize: 14 }}
              onValueChange={(itemvalue) => {
                props.onChangeDropDownVallue(itemvalue, props.dropDownListKey)
              }}
            >

              {
                props.dropDownList && props.dropDownList.map((item, index) => {
                  return (
                    <Picker.Item key={index} label={item.label} value={item.value} />
                  )
                })

                // == "OBGYN" ? null :
                //   <Picker.Item label="Baby of" value="Baby of" />
              }
            </Picker>


            :




            (props.isSelected && props.yesTextBoxKey) ||
              ((props.yesOrNoButtonKey == "" || props.yesOrNoButtonKey == undefined) && props.yesTextBoxKey) ?

              <View style={{ marginTop: 5, marginBottom: 10 }}>
                <TextInput
                  value={props.yesTextBoxValue}
                  style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { backgroundColor: color.white, height: 60, width: "90%", alignSelf: 'center', marginTop: 10, padding: 10, }]}
                  multiline={true}
                  maxLength={300}
                  editable={props.editable}
                  placeholder={props.yesTextBoxPlaceholder}
                  onChangeText={(text) => {
                    if (props.yesTextBoxKey == "detailsOfAgepuberty" || props.yesTextBoxKey == "detailsOfTimemarriage" || props.yesTextBoxKey == "detailsOfchildrensAge" || props.yesTextBoxKey == "detailsOfpartnesAge" || props.yesTextBoxKey == "detailsOfstartschool") {
                      var isnum = numberValidation(text)

                      if (isnum) {
                        props.onChangeYesOrNoValue(
                          true,
                          props.yesOrNoButtonKey ? props.yesOrNoButtonKey : "",
                          text,
                          props.yesTextBoxKey
                        )
                      }

                    } else {
                      props.onChangeYesOrNoValue(
                        true,
                        props.yesOrNoButtonKey ? props.yesOrNoButtonKey : "",
                        text,
                        props.yesTextBoxKey
                      )
                    }

                  }}
                />
              </View>

              : null
      }


    </View>
  )
}

export const FaceRecognitionRespModal = (props) => {

  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            width: "60%", //"48%",
            height: 150, //"60%",
            borderRadius: 4,
          }}
        >
          <View style={{ margin: 20 }}>
            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
              <Text style={{ marginTop: 10, fontSize: 18 }}>
                Information
              </Text>
              <TouchableOpacity onPress={() => {
                props.closePopup()
              }} style={{ marginTop: 10, fontSize: 30 }}>
                <Icon name="window-close-o" size={25} color={color.themeDark} />
              </TouchableOpacity>

            </View>
            {/* <View style={{ flexDirection: "row", marginTop: 5, marginBottom: 15 }}> */}

            <View style={{ width: "100%", alignSelf: "center", marginTop: 20 }}>
              <Text
                style={{
                  backgroundColor: color.themeShade,
                  color: "black",
                  height: 33
                }}>{InfoMessages.FRNotRecognizeSearchOtherRoutes}</Text>
            </View>


            {/* </View> */}
          </View>
        </View>
      </View>
    </View>
  );
};



export const AllModuleBillingTransactionPopup = (props) => {





  const [cancelBillPress, setCancelBillPress] = useState(false);
  const [selectedReason, setSelectedReason] = useState("")
  const [responseMessage, setresponseMessage] = useState("")
  const [responseType, setresponseType] = useState("")
  const [isAdminAccess, setAdminAccess ] = useState(props.isAdminAccess)

  var billingList = props.AllModuleBillingTransactionPopupList.services
  var patientDetails = props.AllModuleBillingTransactionPopupPatientDertails
  var amountDetails = props.AllModuleBillingTransactionPopupList
  // console.log("type", responseType)

  const showResposeValue = (type, message) => {
    setresponseMessage(message);
    setresponseType(type);
    setTimeout(() => {
      setresponseMessage('');
      setresponseType('');
    }, 3000)
  }

  const onSubmitCancel = (reason) => {
    if (reason) {
      props.printCancel(reason)
    } else {
      showResposeValue('error', 'Please enter the reason for cancellation')
    }
  }

  const errorAlert = () => {

    // let errorList = responseMessage
    return (
      <View style={BillPopUpstyle.ErrorAlrtMainView}>
        <View style={BillPopUpstyle.ErrAlrtContentView}>
          <View style={BillPopUpstyle.ErrIconView}>
            <Icon size={'1.63vw'} name={"ban"} color={"#FFF"} /></View>
          <View style={BillPopUpstyle.ErrAlrtMsgView} >
            <View>
              <Text style={BillPopUpstyle.ErrAlrtMsgTxt}>{"ERROR: "}
                {responseMessage}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { setresponseType('') }} style={BillPopUpstyle.ErrAlrtCloseIconView}>
          <Icon size={'1.63vw'} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }

  var reasonsList = [
    { label: "Double Entry", value: "Double Entry" },
    { label: "Wrongly Billed", value: "Wrongly Billed" },
  ]
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={BillPopUpstyle.MainView}
      >
        <View
          style={BillPopUpstyle.MainWrapper}
        >

          <View style={BillPopUpstyle.Wrapper2}>
            <View style={BillPopUpstyle.TopView}>
              <View style={BillPopUpstyle.PatientDetailsView}>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Name"} value={patientDetails.name} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Mobile Number"} value={patientDetails.mobile_number} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Date"} value={amountDetails.invoice_date ? moment(amountDetails.invoice_date).format("DD-MM-YYYY") : null} />
                </View>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Age"} value={patientDetails.age} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"DOB"} value={patientDetails.dob} />
                  <BillingTxnPopupDetails detailsViewKey={"right"} label={"Bill Number"} value={amountDetails.invoice_number} />
                </View>
              </View>
              <View style={{ flex: 0.04 }}>
                <TouchableOpacity onPress={() => { props.showAlllBillingScreenModulePopup(false, {}, {}, "", "") }} style={BillPopUpstyle.CloseIconWrapper}>
                  {/* <Image source={require('../../../assets/images/Printicon.png')} style={{ height: 30, width: 30 }} /> */}
                  <Icon size={'1.6vw'} name={"close"} color={"#fff"} />
                </TouchableOpacity>
              </View>
            </View>



            <View style={BillPopUpstyle.ListHeaderView}>
              <CommonHistorySectionHeader
                heading1={"Service Name"}
                heading2={"Service Type"}
                heading3={"Amount"}
                noOfColumn={3}
                columnSize={[0.4, 0.4, 0.4]}
              />
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 3 }}>
                {billingList && billingList.map((item, index) => {
                  return (
                    <View style={BillPopUpstyle.ListDataView}>

                      <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.35 }]}>{item.service_name}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.35 }]}>{item.service_type}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.35 }]}>{item.amount_net}</Text>
                    </View>

                  )
                })}
              </ScrollView>
            </View>



            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                  <RenderPaymentTypeRadioButton amountDetails={amountDetails} />
                </View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Sub Amount"} textKey={""} value={""} amount={amountDetails.amount_gross} />
                  <RenderAmountCalculationRow label={"Discount %"} textKey={""} value={""} amount={amountDetails.discount_percent} />
                  <RenderAmountCalculationRow label={"Discount Amount"} textKey={""} value={""} amount={amountDetails.discount} />
                  <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={amountDetails.round_off} />
                </View>
              </View>
            </View>



            <View style={BillPopUpstyle.NetAmountView}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                <RenderAmountCalculationRow label={"Net Amount"} textKey={""} value={""} amount={amountDetails.amount_net} />
              </View>
            </View>

            {
              amountDetails.status !== "canceled" ?
              <View style={BillPopUpstyle.ButtonWrapper}>
             { isAdminAccess || props.UserEditAccess ?  
              <TouchableOpacity
                onPress={() => {
                  props.changeScreen("billingScreen", patientDetails, "", {}, "", {}, "", {}, "", {}, false, false, false, false, amountDetails)
                  props.showAlllBillingScreenModulePopup(false, {}, {}, "", "")
                }}
                disabled = {props.isNoAppointment ? true : false}
                style={[BillPopUpstyle.ButtonStyle,{ backgroundColor: props.isNoAppointment ? color.lightGray : color.themeDark }]} >
                <Text style={BillPopUpstyle.ButtonText}>{"Modify Bill"}</Text>
              </TouchableOpacity> : null }
              <TouchableOpacity
                // onPress={() => { props.printCancel() }}                    // disabled={this.state.saveDisable}
                onPress={() => setCancelBillPress(true)}
                disabled = { props.isNoAppointment ? true : false }
                style={[BillPopUpstyle.ButtonStyle,{backgroundColor: props.isNoAppointment ? color.lightGray : color.themeDark }]} >
                <Text style={BillPopUpstyle.ButtonText}>{"Cancel Bill"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                // onPress={() => { props.showAlllBillingScreenModulePopup(false, {},{},"","") }}                    // disabled={this.state.saveDisable}
                onPress={() => { props.billingTransectionPrint() }}
                style={BillPopUpstyle.ButtonStyle} >
                {/* <Text style={{ color: "#FFF" }}>{"Close"}</Text> */}
                <Text style={BillPopUpstyle.ButtonText}>{"Print"}</Text>
              </TouchableOpacity>
            </View>
            :
            <View>
              <Text style={BillPopUpstyle.CancelledBillText}>Bill Cancelled !</Text>
            </View>
            }

            


          </View>



        </View>
      </View>
      {cancelBillPress ?
        <View
          style={BillPopUpstyle.MainView}
        >
          <View
            style={BillPopUpstyle.CloseBillPopupMainView}
          >
            <View style={BillPopUpstyle.ClosePopUpWrapper}>
              <View style={BillPopUpstyle.TopView}>
                <View style={BillPopUpstyle.PatientDetailsView}>

                </View>
                <View style={{ flex: 0.08 }}>
                  <TouchableOpacity onPress={() => setCancelBillPress(false)} style={BillPopUpstyle.CloseIconWrapper}>
                    <Icon size={'0.91vw'} name={"close"} color={"#fff"} />
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                <Text style={BillPopUpstyle.ClosingReasonText}>Reason:</Text>
                <View style={BillPopUpstyle.ClosingReasonListView}>
                  {
                    reasonsList.map((item, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() => { setSelectedReason(item.value) }}
                          style={BillPopUpstyle.ClosingReasonListRadioBtn}
                          key={index}
                        >
                          <View style={[{
                            backgroundColor: selectedReason == item.value ? color.themeDark : ""}, BillPopUpstyle.PaymentTypeRadioButton]}></View>
                          <Text style={BillPopUpstyle.PatientTypeRadioLabelTxt}>{item.label}</Text>

                        </TouchableOpacity>
                      )
                    })
                  }

                </View>
                <Text style={BillPopUpstyle.ClosePopUpOthersTxt}>Others</Text>
                <View style={BillPopUpstyle.ReasonInputView}>
                  <TextInput
                    style={BillPopUpstyle.ReasonInput}
                    maxLength={20}
                    onChange={(text) => { setSelectedReason(text.target.value) }}
                  />

                </View>
                <TouchableOpacity onPress={() => {
                  // props.printCancel(selectedReason)
                  onSubmitCancel(selectedReason)
                }} style={BillPopUpstyle.ClosePopUpOkBtn}>
                  <Text style={BillPopUpstyle.ClosePopupOkBtnText}>OK</Text>
                </TouchableOpacity>
              </View>
            </View>

          </View>
          <View style={{ position: 'revert' }}>
            {
              responseType == "error" ?
                errorAlert()
                : null
            }
          </View>

        </View>
        : null
      }
    </View>

  )

}

export const RightPanelEyeSelection = (props) => {

  let getAvailableEyeTypesId = props.getAvailableEyeTypesId ? props.getAvailableEyeTypesId : []

  let getAvailableEyeTypes = props.getAvailableEyeTypes ? props.getAvailableEyeTypes : []
  let id = props.id ? props.id : "";
  let isLocked = props.isLocked ? props.isLocked : false;
  let selectedEye = props.selectedEye ? props.selectedEye : null;

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

      {
        getAvailableEyeTypes.length > 0 ?

          Constants.eye_type.map((item, index) => {
            console.log("getAvailableEyeTypes ------> " + JSON.stringify(getAvailableEyeTypes))
            var tempData = false

            // var result = getAvailableEyeTypes && getAvailableEyeTypes.map((eye,index)=>{
            //     if (eye == item.value){
            //       tempData=true
            //       return true
            //     }else{
            //       return false
            //     }
            // })

            // var disable = false

            // var idBaseDisable = false

            if (getAvailableEyeTypes.length > 0) {

              const found = getAvailableEyeTypes.find((eye_type) => eye_type === item.label);

              if (found && (found == "RE" || found == "LE")) {
                tempData = true
              }
            }



            return (
              <View style={{ marginBottom: '0.6vw', marginRight: '0.6vw' }} key={index}>
                <CommonButton
                  disable={(tempData || id || isLocked) ? true : false}
                  // disable={found ? true : false}
                  item={item}
                  selectedvalue={selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={props.onPressButton}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
          : Constants.eye_type.map((item, index) => {
            return (
              <View style={{ marginBottom: '0.6vw', marginRight: '0.6vw' }} key={index}>
                <CommonButton
                  disable={isLocked}
                  item={item}
                  selectedvalue={selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={props.onPressButton}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
      }
    </View>
  )
}


// export const HistoryFilledBYTextBox =(props)=>{
//   return(
//       <TextInput
//         onChangeText={(text)=>{
//           props.onChangeText(text)
//         }}
//         value={props.value}
//         style={{width:"90%",borderRadius:5,borderWidth:1,borderColor:"#888888",paddingLeft:10,height:35}}
//       />
//   )
// }

export const HistoryFilledBy = (props) => {


  return (
    <View>

      {/* <TouchableOpacity
            onPress={() => {

              props.onPressSaveButton()
            }} style={{ backgroundColor: color.themeBackgroud, borderRadius: 4, marginLeft: 10,alignSelf:'flex-end' ,marginBottom:10}}

          >
            <Text>{"Save"}</Text>
            <Image source={require("../../../assets/images/HomeScreenImages/saved_bill.png")} style={{ height: 28, width: 28 }} />

          </TouchableOpacity> */}
      <View style={{ flex: 1, alignSelf: "flex-end", marginBottom: 10 }}>
        <CommonButton
          item={{}}
          selectedvalue={{}}
          butttonText={"Save"}
          buttonType={"theme"}
          buttonIcon={ImagePath.SavedBill}
          rightIcon={true}
          buttonAction={props.onPressSaveButton.bind(this)}
          buttonKey={"saveButton"}
        />
      </View>
      <CommonHistorySectionHeader
        heading1={"Informant"}
        heading2={"Reliability"}
        heading3={"History Filled By"}
        columnSize={[0.3, 0.35, 0.35]}  //total value is == of 1
        noOfColumn={3}
      />

      <View style={{ flexDirection: 'row', marginVertical: 10 }}>
        <View style={{ flex: 0.3, marginHorizontal: 20 }}>
          <TextInput
            onChangeText={(text) => {
              props.onChangeValue("information", text)
            }}
            value={props.information}
            style={{ width: "90%", borderRadius: 5, borderWidth: 1, borderColor: "#888888", paddingLeft: 10, height: 35 }}
          />
        </View>
        <View style={{ flex: 0.35 }}>
          <TextInput
            onChangeText={(text) => {
              props.onChangeValue("reliability", text)
            }}
            value={props.reliability}
            style={{ width: "90%", borderRadius: 5, borderWidth: 1, borderColor: "#888888", paddingLeft: 10, height: 35 }}
          />
        </View>
        <View style={{ flex: 0.35 }}>
          <TextInput
            onChangeText={(text) => {
              props.onChangeValue("historyFilledBy", text)
            }}
            value={props.historyFilledBy}
            style={{ width: "90%", borderRadius: 5, borderWidth: 1, borderColor: "#888888", paddingLeft: 10, height: 35 }}
          />
        </View>
      </View>
    </View>
  )
}



export const PharmacyBillingTransactionPopup = (props) => {
  var columnSize = [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.05, 0.1, 0.1, 0.1, 0.05, 0.1]

  var data = props.billingPharmacyTransactionPopupList

  // var data = {
  //   "id": 19469,
  //   "pharmacy_id": 1,
  //   "invoice_number": "324",
  //   "invoice_date": "2021-05-13",
  //   "invoice_sequence": 324,
  //   "bill_type": "Bill",
  //   "billing_customer_name": "",
  //   "billing_customer_mobile_no": "",
  //   "doctor_name": "",
  //   "patient_account_number": null,
  //   "card_number": "",
  //   "discount_amount": 12.45,
  //   "taxable_amount": 112.05,
  //   "general_discount": 11.21,
  //   "general_discount_percentage": null,
  //   "cgst_amount": 0.0,
  //   "sgst_amount": 0.0,
  //   "igst_amount": 0.0,
  //   "tax_amount": 0.0,
  //   "round_off": 0.16,
  //   "delivery_charge": null,
  //   "grand_total": 101.0,
  //   "card_amount": null,
  //   "cash_amount": 101.0,
  //   "received_amount": 150.0,
  //   "balance_amount": 49.0,
  //   "billing_status": "Payment Received",
  //   "payment_mode": 1,
  //   "print_time": "",
  //   "created_by": "rajeshpharma",
  //   // "bill_line_items": [
  //   //   {
  //   //     "id": 64604,
  //   //     "drug_id": 4103,
  //   //     "quantity": 2,
  //   //     "batch_no": "CC564",
  //   //     "expiry_date": "2026-12-31",
  //   //     "cost_per_quantity": 50.0,
  //   //     "physical_box_no": "",
  //   //     "total_cost": 100.0,
  //   //     "discount_percentage": 10.0,
  //   //     "gst_percentage": 0.0,
  //   //     "net_total_amount": 90.0,
  //   //     "brand_name": "Crixan",
  //   //     "dosage_strength": "NA",
  //   //     "dosage_type_id": 5
  //   //   },
  //   //   {
  //   //     "id": 64605,
  //   //     "drug_id": 7321,
  //   //     "quantity": 5,
  //   //     "batch_no": "ACW124",
  //   //     "expiry_date": "2026-06-30",
  //   //     "cost_per_quantity": 4.0,
  //   //     "physical_box_no": "",
  //   //     "total_cost": 24.5,
  //   //     "discount_percentage": 10.0,
  //   //     "gst_percentage": 0.0,
  //   //     "net_total_amount": 22.05,
  //   //     "brand_name": "CROCIN",
  //   //     "dosage_strength": "100mg",
  //   //     "dosage_type_id": 2
  //   //   }
  //   // ],
  //   "pharmacy_name": "Rajesh Pharmacy",
  //   "pharmacy_city": "COIMBATORE"
  // }
  // var billingList = props.billingLabTransactionPopupList.billing_details
  // var patientDetails = props.billingLabTransactionPopupList.patient_details
  // var amountDetails = props.billingLabTransactionPopupList.bill_summary_details
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: screenHeight,
          width: screenWidth,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            backgroundColor: "white", width: "85%", borderRadius: 5,
          }}
        >

          <View style={{ paddingHorizontal: 20, paddingVertical: 13 }}>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ flex: 0.92, flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Name"} value={data.billing_customer_name} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Mobile Number"} value={data.billing_customer_mobile_no} />
                  <BillingTxnPopupDetails detailsViewKey={"left"} label={"Bill Number"} value={data.invoice_number} />

                </View>
                <View>
                  {/* <BillingTxnPopupDetails detailsViewKey={"right"} label={"Age"} value={"Age"} /> */}
                  {/* <BillingTxnPopupDetails detailsViewKey={"right"} label={"Date"} value={amountDetails.invoice_date ? moment(amountDetails.invoice_date).format("DD-MM-YYYY") : null} /> */}
                </View>
              </View>
              <View style={{ flex: 0.04 }}>
                <TouchableOpacity onPress={() => {
                  // props.pharmacyBillingTransectionPrint() 
                  props.showPharmacyBillingTransactionPopup(false, [])
                }} style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: "center", paddingVertical: 8, backgroundColor: color.themeDark, borderRadius: 50 }}>
                  {/* <Image source={require('../../../assets/images/Printicon.png')} style={{ height: 30, width: 30 }} /> */}
                  <Icon size={26} name={"close"} color={"#fff"} />
                </TouchableOpacity>
              </View>
            </View>



            <View style={{ marginTop: 20 }}>
              <CommonHistorySectionHeader
                heading1={"Brand Name"}
                heading2={"Dosage"}
                heading3={"Dosage Type"}
                heading4={"Batch No"}
                heading5={"HSN Code"}
                heading6={"Expiry Date"}
                heading7={"Box No"}
                heading8={"Cost/Qty"}
                heading9={"Quantity"}
                heading10={"Total Cost"}
                heading11={"Discount %"}
                heading12={"Total"}
                noOfColumn={12}
                columnSize={columnSize}
              />

              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 3 }}>


                {data && data.bill_line_items && data.bill_line_items.length > 0 ?
                  data.bill_line_items.map((billLineItems, index) => {
                    return (
                      <View key={index} style={{ flexDirection: 'row', alignItems: "center" }}>
                        <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSize[0], color: "#91939F", }]}>{billLineItems.brand_name}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[1], color: "#91939F", }]}>{billLineItems.dosage_strength}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[2], color: "#91939F", }]}>{billLineItems.dosage_type}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[3], color: "#91939F", }]}>{billLineItems.batch_no}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[4], color: "#91939F" }]}>{billLineItems.hsn_code}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[5], color: "#91939F" }]}>{billLineItems.expiry_date}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[6], color: "#91939F" }]}>{billLineItems.physical_box_no}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[7], color: "#91939F" }]}>{billLineItems.cost_per_quantity}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[8], color: "#91939F" }]}>{billLineItems.quantity}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[9], color: "#91939F" }]}>{billLineItems.total_cost}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[10], color: "#91939F" }]}>{billLineItems.discount_percentage}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSize[11], color: "#91939F" }]}>{billLineItems.net_total_amount}</Text>
                      </View>
                    )
                  }) : null
                }
              </ScrollView>
            </View>



            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                  <RenderPaymentTypeRadioButton amountDetails={data} />
                </View>
                <View style={{ flex: 0.4 }}>
                  <RenderAmountCalculationRow label={"Sub Amount"} textKey={""} value={""} amount={data.taxable_amount} />
                  {/* <RenderAmountCalculationRow label={"Discount %"} textKey={""} value={""} amount={data} /> */}
                  <RenderAmountCalculationRow label={"Discount Amount"} textKey={""} value={""} amount={data.general_discount} />
                  <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={data.round_off} />
                  {/* <RenderAmountCalculationRow label={"Round Off"} textKey={""} value={""} amount={parseFloat(amountDetails.round_off).toFixed(2)} /> */}
                </View>
              </View>
            </View>



            <View style={{ flexDirection: "row", borderTopColor: "#91939F", borderTopWidth: 1 }}>
              <View style={{ flex: 0.6 }}></View>
              <View style={{ flex: 0.4 }}>
                <RenderAmountCalculationRow label={"Net Amount"} textKey={""} value={""} amount={data.grand_total} />
              </View>
            </View>

            <View>
              <TouchableOpacity
                onPress={() => {
                  props.pharmacyBillingTransectionPrint()
                  // props.showPharmacyBillingTransactionPopup(false, []) 
                }}                    // disabled={this.state.saveDisable}
                style={{
                  width: 100,
                  height: 35, backgroundColor: color.themeDark, borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  alignContent: 'center',
                  alignSelf: 'center'
                }} >
                <Text style={{ color: "#FFF" }}>{"Print"}</Text>
              </TouchableOpacity>
            </View>


          </View>



        </View>
      </View>
    </View>
  )
}

// Change Number Format to short Words, Comma Seperated Values --- Starts

export const  NumberFormat=(value)=>{
  
  const DecimalAmount = (Useramount) => {
    var amount = String(Useramount).split(".")[0];
    var AfterDecimal = String(Useramount).split(".")[1]
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
        return  otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
}
const WholeAmount = (Useramount) => {
    var amount = Useramount;
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
       return  otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
}

const TooltipChange = (value)=>{
  if (value % 1 != 0) {
    return DecimalAmount(value)
}
else if (value % 1 == 0) {
    return WholeAmount(value)
}
}

var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  }
  else if(val <= 99999){
    if (value % 1 != 0) {
       val = DecimalAmount(value)
  }
  else if (value % 1 == 0) {
       val = WholeAmount(value)
  }
  }
  
  return (
    <View>
      <Tooltip placement="top" title={TooltipChange(value)}>{val}</Tooltip>
    </View>
  ) ;
}

// Change Number Format to short Words, Comma Seperated Values --- Ends

// AWS storage limit for clinic
export const getClinicS3Storage= async(clinic_id)=>{
    var totalSize = 0,
        ContinuationToken;
  do {
  var resp = await s3
  .listObjectsV2({
    Bucket: "emedhub",
    Prefix: `emedhub/`+
    clinic_id +
    "/" ,
    ContinuationToken,
  })
  .promise()
  .catch((e) => console.log(e));
resp.Contents.forEach((o) => (totalSize += o.Size));
ContinuationToken = resp.NextContinuationToken;
}while (ContinuationToken);
console.log("S3 BUCKETS::::", totalSize); //your answer
      var bytes = totalSize;
      var decimals = 2;
      var bucketSize = 0;
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      bucketSize = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
        sizes[i]
      }`;
      console.log("S3 BUCKETS::::", bucketSize);
      return totalSize;
    }

const styles = StyleSheet.create({
  Header_Icons: {
    height: "1.5vw", 
    width: "1.5vw"
  },
  Header_Icon_Lables: {
    color: color.white, 
    textAlign: 'center',
    fontSize: "0.7vw", 
    fontWeight: '500'
  },
  Header_Profile_Name: {
    color: color.white, 
    textAlign: 'center', 
    fontSize: '1vw', 
    fontWeight: '500'
  },
  Header_Profile_Type: {
    color: color.white, 
    textAlign: 'center',
    fontSize: "0.8vw", 
    fontWeight: '500',
    marginTop: 5 
  },
  Header_Profile_Icon: {
    height: "2.5vw", 
    width: "2.5vw", 
    marginLeft: 13, 
    borderRadius: 30,
    marginTop:2
  },
  Header_Profile_Popup_Icon: {
    height: "2.2vw",
     width: "2.2vw", 
     borderRadius: 30, 
     marginTop: 10
  },
  Header_Profile_Popup_Name: {
    color: color.black, 
    textAlign: 'center', 
    fontSize: '1vw', 
    fontWeight: '500'
  },
  Header_Profile_Popup_Type: {
    color: color.black, 
    textAlign: 'center',
    fontSize: "0.8vw", 
    fontWeight: '500',
    marginTop: 5 
  },
  Header_PopUp_Menu_Text:{
    marginLeft: 15,
    fontSize:'0.8vw' 
  },
  Header_PopUp_Menu_Icon:{
    height: "1.3vw", 
    width: "1.3vw"
  },
  Header_Selected_DocName :{
    color: "#888888", 
    fontSize: '0.9vw',
    width:'12vw'
  },
  Header_Search_Text:{
    backgroundColor: color.white, 
    color: "black", 
    width:'10vw',
    fontSize:'0.9vw'
  },
  Header_MainBar:{
    flexDirection: "row", 
    height: '4.5vw',
     width: "100%"
  },
  Header_Filter_Box:{

    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center', paddingHorizontal: 10,
    paddingRight: 15,
    height: '2.2vw',
    backgroundColor: color.white,
    // borderRadius: 8, 
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    // borderWidth: 1, 
    // borderColor: color.textBoxBorderColor,
  },
  SearchIcon:{
    fontSize:'1vw'
  },
  CameraIcon:{
    height: "1.5vw", 
    width: "1.5vw"
  },
  DropDownIcon :{
    height: "1.7vw", 
    width: "1.7vw", 
    tintColor: "#888888"
  },
  Header_Patient_Name:{
    color: color.white, 
    fontSize: "1vw", 
    fontWeight: '500'
  },
  Header_Patient_Age:{
    color: color.white, 
    fontSize: "0.8vw", 
    paddingRight: 8
  },
  Header_Patient_DOB:{
    color: color.white, 
    fontSize: "0.8vw", 
    paddingHorizontal: 8, 
    borderLeftColor: color.white, 
    borderLeftWidth: 1
  },
  Header_Patient_Mobile:{
    color: color.white, 
    fontSize: "0.8vw", 
    paddingHorizontal: 8, 
    borderLeftColor: color.white, 
    borderLeftWidth: 1
  },
  Header_Patient_LastVisit_Date:{
    color: color.white, 
    fontSize: "0.8vw", 
    paddingHorizontal: 8, 
    borderLeftColor: color.white, 
    borderLeftWidth: 1 
  },
  Header_Patient_Avatar:{
    backgroundColor: color.white,
    marginHorizontal: 12, 
    height: "2.5vw", 
    width: "2.5vw", 
    borderRadius: 50, 
    borderWidth: 3, 
    borderColor: color.white 
  },
  Header_BackIcon:{
    height: "1.5vw", 
    width: "1.5vw",
  },
  contentModalTitle:{
    fontSize:'1vw',
    fontWeight: 'bold'
  },
  contentTableTitle:{
    fontSize:'1vw'
  },
  CommonDropDownPickerTouch: { 
    flexDirection: "row", 
    alignItems: "center", 
    borderColor: "#888888", 
    borderWidth: 1, 
    borderRadius: 4, 
    height: '2.2vw', 
    justifyContent: 'center' 
  },
  SelectDepartmentText: { 
    color: "black", 
    fontSize: '0.8vw', 
    marginLeft: '0.6vw', 
    marginRight: '0.6vw' 
  },
  SelectDepartmentIcon: { 
    color: "black", 
    fontSize: '0.8vw', 
    marginLeft: '0.6vw', 
    marginRight: '0.6vw' 
  },
  ScrollViewDropDown: { 
    position: 'absolute', 
    zIndex: 2, 
    marginTop: '2.2vw', 
    maxHeight: '18.1vw', 
    maxWidth: '21.8vw', 
    borderRadius: 4, 
    borderWidth: 1, 
    borderColor: "#888888", 
  },
  CommomRightSideHeaderView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: '1vw',
    flex: 1,
    justifyContent: "space-between",
    marginTop: '0.3vw',
  },
  SectionHeaderView: { 
    flexDirection: 'row', 
    height: '2.3vw', 
    alignItems: 'center' ,
    borderRadius:'0.26vw'
  },
  SectionHeaderText: { 
    fontWeight: '500', 
    fontSize: '1vw', 
    marginLeft: '1.2vw',
    // marginTop: '0.5vw'
  },
  NaviTopHeaderScrollView: { 
    flexDirection: 'row', 
    marginHorizontal: '1vw' ,
    overflow:'scroll',
  },
  NaviTopHeaderTouch: { 
    paddingHorizontal: '0.6vw', 
    justifyContent: "center" 
  },
  DocNotesNavTouchView:{
    borderBottomWidth:1, 
    borderColor:"#F0F0F0", 
    borderRadius:"0.26vw",
    justifyContent: 'flex-start', 
    marginLeft:"0.2vw", 
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center'
   },
  

});


const AddButtonStyle = StyleSheet.create({
  Btn:{
    marginTop: '0.65vw', 
    backgroundColor: color.themeDark, 
    borderRadius: '0.52vw', 
    paddingVertical: '0.65vw', 
    alignItems: 'center'
  },
  BtnText:{
    color: color.white, 
    fontWeight: "700", 
    fontSize: '1.04vw'
  }
})

const BillPopUpstyle = StyleSheet.create({
  MainView:{
    position: "absolute",
    zIndex: -1,
    height: '100vh',
    width: '100vw',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  MainWrapper:{
    backgroundColor: "white", 
    width: "60%", 
    borderRadius: '0.32vw',
  },
  Wrapper2:{
    paddingHorizontal: '1.3vw', 
    paddingVertical: '0.85vw'
  },
  TopView:{
    flexDirection: "row", 
    justifyContent: "space-between"
  },
  PatientDetailsView:{
    flex: 0.92, 
    flexDirection: "row", 
    justifyContent: "space-between"
  },
  BillingTxnPopupDetailsMainView:{
    flexDirection: "row", 
    marginVertical: '0.52vw'
  },
  BillingTxnPopupDetailsLabel:{
    fontSize: '0.85vw', 
    fontWeight: '500'
  },
  BillingTxnPopupDetailsColon:{
    fontSize: '0.85vw', 
    fontWeight: "500", 
    marginRight: '0.65vw'
  },
  BillingTxnPopupDetailsData:{
    fontSize: '0.85vw', 
    fontWeight: "500"
  },
  CloseIconWrapper:{
    paddingHorizontal: '0.65vw', 
    justifyContent: "center", 
    alignItems: 'flex-end', 
    alignContent: 'flex-end', 
    alignSelf: "center", 
    paddingVertical: '0.52vw', 
    backgroundColor: color.themeDark, 
    borderRadius: '3.25vw'
  },
  ListHeaderView:{
    marginTop:'1.3vw'
  },
  ListDataView:{
    flexDirection: 'row', 
    borderBottomColor: "#91939F", 
    borderBottomWidth: 1, 
    paddingVertical: '0.85vw'
  },
  PaymentTypeText:{
    fontWeight: "500", 
    fontSize: '0.91vw',
  },
  PaymtRadioButWrapper:{
    flexDirection: 'row', 
    marginVertical: '0.97vw', 
    flexWrap: 'wrap'
  },
  PaymentTypeRadio:{
    marginRight: '1.3vw', 
    flexDirection: "row", 
    alignItems: "center", 
    marginTop: '0.65vw'
  },
  PaymentTypeRadioButton:{
    marginRight: "0.52vw", 
    height: '1vw', 
    width: '1vw', 
    borderRadius: '3.25vw', 
    borderWidth: 1, 
    borderColor: "#91939F"
  },
  PatientTypeRadioLabelTxt:{
    fontWeight: "500", 
    fontSize: '0.85vw'
  },
  AmtCalcWrapper:{
    flexDirection: "row", 
    justifyContent: "flex-end", 
    marginVertical: '0.52vw'
  },
  AmtCalcTextView:{
    flex: 0.4, 
    justifyContent: "center"
  },
  AmtCalcText:{
    color: "#91939F", 
    fontSize: '0.91vw'
  },
  AmtCalcEmptyView:{
    flex: 0.25, 
    alignItems: "center", 
    justifyContent: "center"
  },
  AmtCalcDataView:{
    flex: 0.35, 
    alignItems: "flex-end", 
    marginRight: '0.97vw', 
    justifyContent: "center"
  },
  AmtCalcAmount:{
    fontWeight: "500", 
    fontSize: '0.91vw'
  },
  NetAmountView:{
    flexDirection: "row", 
    borderTopColor: "#91939F", 
    borderTopWidth: 1
  },
  ButtonWrapper:{
    flexDirection: 'row', 
    alignContent: 'center', 
    alignSelf: 'center'
  },
  ButtonStyle:{
    width: '6.51vw',
    height: '2.27vw', 
    backgroundColor: color.themeDark, 
    borderRadius: '0.26vw',
    justifyContent: "center",
    alignItems: "center",
    marginTop: '1.3vw',
    alignContent: 'center',
    alignSelf: 'center',
    marginRight: '0.97vw'
  },
  ButtonText:{
    color: "#FFF",
    fontSize:'0.91vw'
  },
  CloseBillPopupMainView:{
    backgroundColor: "white", 
    width: "30%", 
    borderRadius: "0.32vw",
  },
   ClosePopUpWrapper:{
    paddingHorizontal: '1.3vw', 
    paddingVertical: '0.85vw'
   },
   ClosingReasonText:{
    color: "#00b4ae", 
    fontSize: '1.3vw', 
    fontWeight: 'bold'
   },
   ClosingReasonListView:{
    flexDirection: 'row', 
    paddingTop: '0.65vw', 
    marginLeft: '1.95vw'
   },
   ClosingReasonListRadioBtn:{
    marginRight: '1.3vw', 
    flexDirection: "row", 
    alignItems: "center"
   },
   ClosePopUpOthersTxt:{
    color: "#00b4ae", 
    fontSize: '0.97vw', 
    fontWeight: 'bold', 
    paddingTop: '0.97vw'
   },
   ReasonInputView:{
    paddingTop: '0.65vw', 
    marginLeft: '1.95vw'
   },
   ReasonInput:{
    border: '1px solid #e3e5e6', 
    width: '90%', 
    height: '1.95vw', 
    paddingTop: '0.65vw', 
    padding: '0.32vw',
    fontSize:'0.91vw'
   },
   ClosePopUpOkBtn:{
    alignSelf: "center", 
    backgroundColor: color.themeDark, 
    borderRadius: '0.26vw', 
    marginTop: '1.3vw', 
    width: '15%', 
    height: '1.95vw', 
    justifyContent: 'center'
   },
   ClosePopupOkBtnText:{
    color: 'white', 
    fontSize: '0.91vw', 
    textAlign: 'center', 
    fontWeight: 'bold'
   },
   ErrorAlrtMainView:{
    flex: 1, 
    zIndex: 3, 
    width: "35%", 
    position: 'absolute', 
    left: '0.65vw', 
    bottom: '0.32vw', 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection: "row", 
    backgroundColor: "#CD3A34", 
    borderRadius:  '0.32vw', 
    borderColor: "#A58B8A", 
    borderWidth: 1, 
    minHeight: '4.56vw', 
    marginRight: '0.65vw', 
    marginTop: '0.65vw'
   },
   ErrAlrtContentView:{
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: '0.97vw', 
    marginLeft: '0.65vw', 
    flex: 0.95
   },
   ErrIconView:{
    flex: 0.1, 
    alignSelf: "flex-start", 
    marginBottom: '0.65vw'
   },
   ErrAlrtMsgView:{
    flex: 0.9, 
    flexDirection: "column", 
    justifyContent: "flex-start"
   },
   ErrAlrtMsgTxt:{
    fontWeight: "700", 
    color: "#FFF", 
    fontSize: '0.8vw'
   },
   ErrAlrtCloseIconView:{
    flex: 0.05, 
    marginRight: '0.97vw', 
    alignItems: 'center', 
    alignContent: 'center', 
    alignSelf: 'center', 
    justifyContent: "center"
   },
   CancelledBillText:{
    width:'100%',
    textAlign:'center',
    fontWeight:'500',
    color:color.red,
    fontSize:"1vw",
    paddingVertical:'0.2vw'
   }

})