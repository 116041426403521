import React, { Component } from 'react';
import './AppointmentType.css'
import { TouchableOpacity } from 'react-native';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import { NumberFormat } from '../../../BaseComponent';
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';
import { Tooltip } from 'antd';

class AppointmentType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentType: {},
      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate
    }
  };

  componentDidMount() {
    this.getAppointmentType()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getAppointmentType()
      })
    }
  }

  /* get AppointmentType data API start*/
  getAppointmentType = () => {
    var serviceUrl = Constants.APPOINTMENT_TYPE_CHART + "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getAppointmentTypeSuccess,
      this.getAppointmentTypeFailure
    );
  }

  getAppointmentTypeSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        appointmentType: success.Data
      })
    } else{
      this.props.Response(success.message)
    }
  }
  getAppointmentTypeFailure = (error) => {
    this.props.Response(error.message)
  }
  /* get AppointmentType data API end*/

  /* Export Excel API start*/
  getAppoinTypeExcel = () => {
    var serviceUrl =
      Constants.APPOINTMENT_TYPE_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getAppoinTypeSuccess,
      this.getAppoinTypeFailure,
      "excel"
    );
  }
  getAppoinTypeSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getAppoinTypeFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/


  render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1vw' }}>
          <TouchableOpacity onPress={this.getAppoinTypeExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </div>
        <div className='ChartContainer'>
          <div className='WalkinCircleView'>
            <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #EB3649 , #FA7886 )' }} >
              <h3 id='circleText'>{this.state.appointmentType.Walk_in_Appointment ? NumberFormat(this.state.appointmentType.Walk_in_Appointment) : 0}</h3>
            </div>
            <div className='WalkinTextView'>
              <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #EB3649 , #FA7886 )', marginLeft: '2.5vw' }}>
              </div>
              <p className='TextPara'>Walk-In <br />Patients</p>
            </div>
          </div>
          <div className='WalkinCircleView'>
            <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #2680ED  , #6AA7F0  )' }} >
              <h3 id='circleText'>{this.state.appointmentType.Telephonic_Appointment ? NumberFormat(this.state.appointmentType.Telephonic_Appointment) : 0}</h3>
            </div>
            <div className='WalkinTextView'>
              <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #2680ED  , #6AA7F0  )', "marginLeft": '2.5vw' }}>
              </div>
              <p className='TextPara'>Telephonic <br /> Patients</p>
            </div>
          </div>
          <div className='WalkinCircleView'>
            <div id="circle" style={{ background: 'radial-gradient(circle farthest-side, #52AF20  , #72B76E  )' }} >
              <h3 id='circleText'>{this.state.appointmentType.Online_Appointment ? NumberFormat(this.state.appointmentType.Online_Appointment) : 0}</h3>
            </div>
            <div className='WalkinTextView'>
              <div id="smallcircle" style={{ background: 'radial-gradient(circle farthest-side, #52AF20  , #72B74E  )', "marginLeft": '2.5vw' }}>
              </div>
              <p className='TextPara'>Online <br /> Patients</p>
            </div>
          </div>

        </div>
        <div>
          <p className='AppointmentText'>Appointment Type</p>
        </div>
      </div>
    )
  }
}
export default AppointmentType;