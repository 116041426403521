//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput, TouchableOpacity, Image, Picker } from 'react-native';
const screenHeight = Dimensions.get("window").height;
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";
import BaseComponentStyle from "../BaseComponentStyle";

import Success from "react-native-vector-icons/FontAwesome5";
var Messages = require('../../../utils/InfoMessages')

import { EditiAndDeleteForLineItem, OBGYNHistorySectionHeading, CommonHistorySectionHeader, HistoryYesOrNoSection, LabelWithTextBoxDR, CommonButton, LabelWithCalender, CommonAddButton } from '../BaseComponent'
import { Info } from '@material-ui/icons';

// var companyName = [
//   { label: "Star Health Insurance Company", value: "Star Health Insurance Company" },
//   { label: "Max Life Insurance Co. Ltd.", value: "Max Life Insurance Co. Ltd." },
//   { label: "ICICI Lombard Health Insurance Company", value: "ICICI Lombard Health Insurance Company" },
//   { label: "HDFC Standard Life Insurance Co. Ltd.", value: "HDFC Standard Life Insurance Co. Ltd." },
//   { label: "Religare Health Insurance", value: "Religare Health Insurance" },

// ];

// create a component
export class Insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIpPatientCheckouted:this.props.isIpPatientCheckouted,

      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      scrollPosition: 0,
      responseType: "",
      responseMeaasge: "",
      listOfCompanyName: [],
      selectedCompanyName: {},

      // companyList:companyName,
      selectCompanyList: {},
      selectedInsuranceSettlementStatus: "Initiated",
      selectCompanyNewbuttonFlag: false,
      newButtonValue: "",
      ipNumber: "",
      insurance: {},
      policyNumber: "",
      claimAmount: "",
      transactionId: "",
      status: "",
      selectedIpPatientID: this.props.selectedIpPatientDetails,
      getCompanyName: "",
      getBillAMount: "",
      getPolicyNumber: "",
      getClaimAmount: "",
      getTransactionId: "",
      getBalanceAmount: "",
      listOfCompanysearch: [],

    }
  }
  componentDidMount() {
    // alert(JSON.stringify(this.state.selectedIpPatientID))
    this.getBill();
    this.getCompany();
    this.getInsurance();

  }
  //  start post api for Insurance Settlement post
  saveBillSettleStatus = () => {
    var data = {
      "ip_number": this.state.selectedIpPatientID.id,
      "insurance_status": this.state.selectedInsuranceSettlementStatus ? this.state.selectedInsuranceSettlementStatus : ""
    }
    var service_url = Constants.IP_INSURANCE_INSURANCE_SETTLEMENT_POST
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this.getBillSettleStatusSuccess,
      this.getBillSettleStatusFailure
    );
  };


  getBillSettleStatusSuccess = response => {
    if (response.status === "success") {


    }
  };

  getBillSettleStatusFailure = error => {

  };
  //  end post api for Insurance Settlement post

  getInsurance = () => {

    var service_url = Constants.IP_INSURANCE_POST + "?ip_number=" + this.state.selectedIpPatientID.id
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getInsuranceSuccess,
      this.getInsuranceFailure
    );
  };


  getInsuranceSuccess = response => {
    // alert(JSON.stringify(response.data))

    if (response.status === "success") {

      this.setState({
        getCompanyName: response.data.insurance_name,
        getBillAMount: response.data.bill_amount,
        getPolicyNumber: response.data.policy_number,
        getClaimAmount: response.data.claim_amount,
        getTransactionId: response.data.transaction_id,
        getBalanceAmount: response.data.balance_amount
      });
    }
  };

  getInsuranceFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };



  getBill = () => {

    var service_url = Constants.IP_GET_BILL_AMOUNT + "?ip_number=" + this.state.selectedIpPatientID.id
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getBillSuccess,
      this.getBillFailure
    );
  };

  getBillSuccess = response => {

    if (response.status === "success") {
      var field = this.state;
      // alert(JSON.stringify(response.data.bill_amount))
      field["getBillAMount"] = response.data.bill_amount;
      this.setState({ field });
    }
  };

  getBillFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getCompany = () => {


    var service_url = Constants.IP_GET_INSURANCE_COMPANY+"?search_key=default"

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCompanySuccess,
      this.getCompanyFailure
    );
  };

  getCompanySuccess = response => {
    if (response.status === "success") {
      // alert(JSON.stringify(response.data))
      // console.log(JSON.stringify(response.data))
      var field = this.state;

      field["listOfCompanyName"] = response.data;


      this.setState({ field });
    }
  };

  getCompanyFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getCompanySearch = (searchKey) => {
    var service_url = Constants.IP_GET_INSURANCE_COMPANY + "?search_key=" + searchKey


    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCompanySearchSuccess,
      this.getCompanySearchFailure
    );
  };

  getCompanySearchSuccess = response => {
    if (response.status === "success") {
      // alert(JSON.stringify(response.data))
      // console.log(JSON.stringify(response.data))
      var field = this.state;
      field["listOfCompanysearch"] = response.data;


    }
    this.setState({ field });

  };

  getCompanySearchFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  componentWillReceiveProps(props) {
    // activityLevelList = this.removeDuplicate(activityLevelList)

  }
  // Post Method function
  postInsurance = () => {

    // console.log(" add complaint ");

    let states = this.state;
    var service_url = Constants.IP_INSURANCE_POST;

    let data = {
      // "id": states.id?states.id:null,
      // "appointment_id": this.state.patientAppointment.appointment_id,
      // "complaints": states.selectedEye,
      // "complaint_description": states.complaints,
      // "duration_type": 3,
      // "duration_time": states.selectedDuration,
      // "comments": states.comments,

      "ip_number": this.state.selectedIpPatientID.id,
      "insurance": states.selectedCompanyName.value,
      "policy_number": states.policyNumber,
      "claim_amount":states.claimAmount? parseFloat(states.claimAmount):null,
      "transaction_id": states.transactionId,
      "status": states.selectedInsuranceSettlementStatus ? states.selectedInsuranceSettlementStatus : null
    };


    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.postInsuranceSuccess,
      this.postInsuranceFailure
    );
          
 

  };

  postInsuranceSuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message)
      this.getInsurance();
      this.insuranceClear()

    } else {
      this.props.showResposeValue("error", success.message)
    }
  }
  postInsuranceFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  // End Post method 


  insuranceClear(){
    this.setState({
      selectedCompanyName:{},
      policyNumber:"",
      claimAmount:"",
      transactionId:""
    })
  }


  postUpdateStaus = () => {

    let states = this.state;
    var service_url = Constants.IP_INSURANCE_STATUS_UPDATE;

    let data = {

      "ip_number": this.state.selectedIpPatientID.id,
      "insurance_status": states.selectedInsuranceSettlementStatus ? states.selectedInsuranceSettlementStatus : null
    };
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.postUpdateStausSuccess,
      this.postUpdateStausFailure
    );
  };

  postUpdateStausSuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message)
    } else {
      this.props.showResposeValue("error", success.message)
    }
  }
  postUpdateStausFailure = error => {
    this.props.showResposeValue("error", error.message)
  }


  _onContentSizeChange() {
    let initialYScroll = this.state.scrollPosition;
    this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
  };
  selectedRightSideView(name) {
    states['claimAmount']=this.state.getClaimAmount

    this.setState({
      states
    })
    setTimeout(() => {
      this.editHistory()
    }, 100);
  }
  editHistory() {
    var states = this.state
    var {listOfCompanyName}=this.state

    var getcompanynameList = this.state.getCompanyName;

    for (let i = 0; i < listOfCompanyName.length; i++) {
       
        if (listOfCompanyName[i] == getcompanynameList) {

            var prepareChildren = { label: getcompanynameList, value: getcompanynameList}
            states["selectedCompanyName"] = prepareChildren;
        } else {

            var prepareChildren = { label: getcompanynameList, value: getcompanynameList }
            listOfCompanyName.push(getcompanynameList);
            states['selectedCompanyName'] = prepareChildren;
        }

    }
    listOfCompanyName = this.removeDuplicate(listOfCompanyName)
    // var prepareData = { label: this.state.getCompanyName, value: this.state.getCompanyName }

    states['claimAmount']=this.state.getClaimAmount
    states['policyNumber']=this.state.getPolicyNumber
    states['transactionId']=this.state.getTransactionId
    // states['selectedCompanyName']=prepareData
    // states['']

    this.setState({
      states,listOfCompanyName
    })
  }

  onChangeYesOrNoValue(flag, value, key) {
    if (!flag) {
      this.setState({ activityYesorNo: false })

    } else {
      this.setState({ activityYesorNo: true })

    }
    var states = this.state;
    states["selectedValue"] = flag;
    states[key] = value;
    this.setState({ states }, () => {
      var states = this.state;
      if (!this.state.activityYesorNo) {
        states["exerciseDailyValue"] = {}
        states["selectTypeExerciseValue"] = {}
        states["typeExerciseText"] = ""
        states["selectintesityLevelValue"] = {}
        states["exerciseTime"] = ""
        // states["physical_activity_id"] = ""
      } else {
        states["physicalActivityDetails"] = ""
      }
      this.setState({
        states
      })
    })
  }

  renderListOfData(label, value) {
    return (
      <View style={{ flex: 0.47 }}>

        <EditiAndDeleteForLineItem
          heading={label}
          headerKey={"insurance"}
        />
        <View style={{ marginVertical: 8 }}>
          <Text style={{ fontSize: 15, marginLeft: 9 }} numberOfLines={10}>{value}</Text>
        </View>
      </View>
    )
  }


  renderPicker() {
    return (
      <Picker
        enabled={this.state.isIpPatientCheckouted ? false:true}
        style={[Style.pickerView, {
          marginTop: 5, borderColor: '#CDD1D5'
        }, Style.allButtonBorderRadius]}
        itemStyle={{}}
        selectedValue={this.state.selectedInsuranceSettlementStatus}
        onValueChange={(itemvalue) => {
          var states = this.state;
          states["selectedInsuranceSettlementStatus"] = itemvalue;
          this.setState({ states },()=>{
            this.postUpdateStaus()
          });
        }}

      >
        <Picker.Item label="Select Status" value="" />
        <Picker.Item label="Initiated" value="Initiated" />
        <Picker.Item label="Declined" value="Declined" />
        <Picker.Item label="Approved" value="Approved" />
        {/* {
                      this.state.stateList.map((item, index) => {
                        return <Picker.Item key={index} label={item} value={item} />
                      })
                    } */}
      </Picker>
    )
  }


  renderInsuranceRowLine(leftLabel, leftValue, rightLabel, rightValue) {
    return (
      <View style={{ flexDirection: 'row', justifyContent: "space-between", marginBottom: 20 }}>
        {
          this.renderListOfData(leftLabel, leftValue)
        }
        {
          this.renderListOfData(rightLabel, rightValue)
        }
      </View>
    )
  }
  //   renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData, textinput) {
  //     var data = this.removeDuplicate(setOfData)
  //     return (
  //         <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
  //             <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
  //             <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
  //                 {
  //                     data.map((item, index) => {
  //                         if(item.value)
  //                         {return (
  //                             <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
  //                                 <CommonButton
  //                                     item={item}
  //                                     selectedvalue={selectedValue}
  //                                     butttonText={item.label}
  //                                     buttonType={"outlineNonTheme"}
  //                                     buttonIcon={""}
  //                                     rightIcon={false}
  //                                     buttonAction={this.onPressButton.bind(this)}
  //                                     buttonKey={key} />
  //                             </View>
  //                         )}
  //                     })
  //                 }

  //                 <View>
  // {
  //     newButtonKey ?

  //         newbuttonFlag ?
  //             <CommonButton
  //                 item={{ label: "New", value: "New" }}
  //                 selectedvalue={{}}
  //                 butttonText={"New"}
  //                 buttonType={"outlineNonTheme"}
  //                 buttonIcon={require('../../../../assets/images/PlusIcon.png')}
  //                 rightIcon={true}
  //                 buttonAction={this.onPressButton.bind(this)}
  //                 buttonKey={newButtonKey} />
  //             :
  //             <TextInput
  //                 style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
  //                 onChangeText={(text) => {
  //                     this.setState({ newButtonValue: text })
  //                 }}
  //                 onSubmitEditing={() => {
  //                     this.onSubmitToAddValue()
  //                 }}
  //             />

  //         : null
  // }

  //                 </View>

  //             </View>
  //             <View>
  //                 {textinput ?
  //                     <TextInput
  //                         style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { backgroundColor: color.white, height: 80, width: "95%", marginTop: 10, padding: 10, marginBottom: 20 }]}
  //                         value={this.state.typeExerciseText}
  //                         onChangeText={(text) => {
  //                             this.setState({ typeExerciseText: text })
  //                         }}
  //                         multiline={true}
  //                         maxLength={100}
  //                         placeholder={"Details if any"}

  //                     />
  //                     : null}
  //             </View>
  //         </View>
  //     )
  // }

  onPressButton(key, value) {
    var states = this.state;
    if ("selectCompanyNewbuttonFlag" == key) {
      states["selectCompanyNewbuttonFlag"] = true
    } else {
      states[key] = value;
    }


    this.setState({ states })
  }


  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onSubmitToAddValue() {
    var { listOfCompanyName } = this.state;
    listOfCompanyName.push(this.state.newButtonValue);
    listOfCompanyName = this.removeDuplicate(listOfCompanyName);
    this.setState({
      listOfCompanyName,
      newButtonValue: "",
      selectedCompanyName: { label: this.state.newButtonValue, value: this.state.newButtonValue },
      selectCompanyNewbuttonFlag: false
    })

  }

  renderCompanyListButtons() {
    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 10 }}>
        {
          this.state.listOfCompanyName && this.state.listOfCompanyName.length > 0 && this.state.listOfCompanyName.map((item, index) => {
            var prepareData = { label: item, value: item }
            return (
              <View style={{ marginBottom: 8, marginRight: 8 }}>
                <CommonButton
                disable={this.state.isIpPatientCheckouted}
                  item={prepareData}
                  selectedvalue={this.state.selectedCompanyName}
                  butttonText={prepareData.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={true}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedCompanyName"} />
              </View>
            )
          })
        }
        { !this.state.selectCompanyNewbuttonFlag ?
          <CommonButton
          disable={this.state.isIpPatientCheckouted}
            item={{ label: "New", value: "New" }}
            selectedvalue={{}}
            butttonText={"New"}
            buttonType={"outlineNonTheme"}
            buttonIcon={require('../../../../assets/images/PlusIcon.png')}
            rightIcon={true}
            buttonAction={this.onPressButton.bind(this)}
            buttonKey={"selectCompanyNewbuttonFlag"} />
          :
          <View>
            <TextInput
              style={[{paddingLeft:10, width: "100%", height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
              onChangeText={(text) => {
                this.setState({ newButtonValue: text })
                this.getCompanySearch(text)
              }}
              value={this.state.newButtonValue}
              onSubmitEditing={() => {
                this.onSubmitToAddValue()
              }}
            />
            {
              this.state.listOfCompanysearch && this.state.listOfCompanysearch.length > 0 ?
                <View style={{ position: 'absolute', width: "100%", borderRadius: 5, borderWidth: 1, borderColor: "#888888", backgroundColor: "white", maxHeight: 200, marginTop: 40, }}>
                  <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                    {
                      this.state.listOfCompanysearch.length > 0 ? 
                      this.state.listOfCompanysearch && this.state.listOfCompanysearch.map((item, index) => {
                        if(item.id && item.name){
                        return (
                          <TouchableOpacity
                            onPress={() => {


                              this.setState({
                                newButtonValue: item.name,
                                listOfCompanysearch: [],
                              }, () => { this.onSubmitToAddValue() })
                            }}
                            key={index} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 15 }}>
                            <Text>{item.name}</Text>
                          </TouchableOpacity>
                        )
                          }
                      })

                      :
                      null
                    }
                  </ScrollView>
                </View>
                : null
            }
          </View>


          // : null
        }


      </View>
    )
  }

  onChangeText() { }
  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }
  render() {
    return (
      <View style={{ flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
        {/* {
                this.state.responseType == "success" ?
                  this.successAlert() :
                  this.state.responseType == "error" ?
                    this.errorAlert() : null
              } */}
        <ScrollView
          style={{ width: "65%", height: screenHeight - 65, }}
          bounces={false}
          bouncesZoom={false}
        >

          <View style={Style.doctorNotesSectionView}>
            <View style={{ flexDirection: "row", marginTop: 30, justifyContent: 'space-between', alignItems: 'center' }}>
              <View>
                <OBGYNHistorySectionHeading
                  selectedRightSideView={this.selectedRightSideView.bind(this)}
                  editHistory={this.editHistory.bind(this)}
                  selectedPageName={this.state.selectedPageName}
                  heading={"Insurance"}
                  editImage={this.state.getCompanyName?"pencil":""}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 10 }}>
                <Text style={{ alignContent: "center", alignSelf: "center", fontWeight: 500, marginRight: 10 }}>Insurance Settlement Status</Text>
                {this.renderPicker()}
              </View>
            </View>


            {this.renderInsuranceRowLine("Company name", this.state.getCompanyName, "Bill Amount", this.state.getBillAMount)}
            {this.renderInsuranceRowLine("Policy Number", this.state.getPolicyNumber, "Claim Amount", this.state.getClaimAmount)}
            {this.renderInsuranceRowLine("Transaction ID", this.state.getTransactionId, "Balance Amount", this.state.getBalanceAmount)}


          </View>
          {/* {
                this.renderListOfData("Company name","Jsri Tech Solution " )
              } */}
          {/* <View style={[Style.leftView, { width: "100%", backgroundColor: color.themeShade, }]}>

            
             <View style={{ width: "100%", marginHorizontal: 20 }}  >
              <View style={{ flexDirection: "row", marginTop: 50 }}>
                <View style={{ flex: 0.25 }}>
                  <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    heading={"Medical History"}
                    editImage={"pencil"}
                  />
                </View>
                <View style={{ flex: 0.50, Fontweight: 500, marginTop: 5 }}>
                  <Text style={{ alignContent: "center", alignSelf: "flex-end", fontWeight: 500 }}>Insurance Settlement Status</Text>
                </View>
                <View style={{ flex: 0.25 }}>
                  
                </View>
              </View>
            </View> */}
          {/* <View style={{marginHorizontal:20}}>
              <View style={{ marginBottom: 10, marginTop: 10, }}>
                <CommonHistorySectionHeader
                  heading1={"Company Name"}
                  heading2={"Bill Amount"}
                  noOfColumn={2}

                /></View>
              <View style={{ marginBottom: 10 }}>
                <CommonHistorySectionHeader
                  heading1={"Policy Number"}
                  heading2={"Claim Amount"}
                  noOfColumn={2}

                /></View>
              <View style={{ marginBottom: 10 }}>
                <CommonHistorySectionHeader
                  heading1={"Transaction ID"}
                  heading2={"Balance Amount"}
                  noOfColumn={2}

                /></View>
            </View> 
          </View>*/}
        </ScrollView>


        <ScrollView
          ref={scrollView => this.scrollView = scrollView}
          onContentSizeChange={() => {
            this._onContentSizeChange();
          }}

          showsVerticalScrollIndicator={false}
          style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, }}
        // contentContainerStyle={{ flex: 1 }}
        >
          <View style={{ marginBottom: 10, marginTop: 10, marginHorizontal: 5 }}>
            <CommonHistorySectionHeader
              heading1={"Company Name"}

              columnSize={[1]}  //total value is == of 1
              noOfColumn={1}
            />
            {this.renderCompanyListButtons()}

            {/* {this.renderLabelWithButtonGroup(
                    "",
                    "selectCompanyList",
                    this.state.selectCompanyList,
                    "selectCompanyNewbuttonFlag",
                    this.state.selectCompanyNewbuttonFlag,
                    this.state.companyList)} */}

          </View>
          <View style={{ marginBottom: 10, marginTop: 10, marginHorizontal: 5, zIndex: -1 }}>
            <CommonHistorySectionHeader
              heading1={"Policy Number"}

              columnSize={[1]}  //total value is == of 1
              noOfColumn={1}
            />
            <TextInput
              editable={this.state.isIpPatientCheckouted ? false : true}
              style={[{ marginTop: 10, height: 40, borderRadius: 5, borderColor: 'gray', borderWidth: 1, marginHorizontal: 20, borderColor: "#888888", paddingLeft: 10 },this.state.isIpPatientCheckouted ? {backgroundColor:color.disableComponentColor}:{}]}
              value={this.state.policyNumber}
              onChangeText={(text) => {
                this.setState({
                  policyNumber: text
                })
              }}
              placeholder="Type here...."
            />
          </View>
          <View style={{ marginBottom: 10, marginTop: 10, marginHorizontal: 5, zIndex: -1 }}>
            <CommonHistorySectionHeader
              heading1={"Claim Amount"}

              columnSize={[1]}  //total value is == of 1
              noOfColumn={1}
            />
            <TextInput
            editable={this.state.isIpPatientCheckouted ? false : true}
              style={[{ marginTop: 10, height: 40, borderRadius: 5, borderColor: 'gray', borderWidth: 1, marginHorizontal: 20, borderColor: "#888888", paddingLeft: 10 },this.state.isIpPatientCheckouted? {backgroundColor:color.disableComponentColor}:{}]}
              value={this.state.claimAmount}
              onChangeText={(text) => {
                var isNum = this.numberValidation(text)
                if (isNum) {
                  this.setState({
                    claimAmount: text
                  })
                }

                
              }}
              placeholder="Type here...."
            />
          </View>
          <View style={{ marginBottom: 10, marginTop: 10, marginHorizontal: 5 }}>
            <CommonHistorySectionHeader
              heading1={"Transaction ID"}

              columnSize={[1]}  //total value is == of 1
              noOfColumn={1}
            />
            <TextInput
            editable={this.state.isIpPatientCheckouted ? false : true}
              style={[{ marginTop: 10, height: 40, borderRadius: 5, borderColor: 'gray', borderWidth: 1, marginHorizontal: 20, borderColor: "#888888", paddingLeft: 10 },this.state.isIpPatientCheckouted ?{backgroundColor:color.disableComponentColor}:{}]}
              value={this.state.transactionId}
              onChangeText={(text) => {
                this.setState({
                  transactionId: text
                })
              }}
              placeholder="Type here...."
            />
          </View>

          {/* <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} /> */}
          <View style={{ marginHorizontal: 20 }}>
            <CommonAddButton disable = {this.state.isIpPatientCheckouted} onPressAddButton={this.postInsurance.bind(this)} />
          </View>
        </ScrollView>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2c3e50',
  },
});

//make this component available to the app

