import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
var Messages = require('../../../../utils/InfoMessages')


const data = {
    "id": 2,
    "rating_scale_id": 1,
    "rating_scale_name": "Hamilton Depression Rating Scale",
    "total_score": 5,
    "comments": "No worries",
    "questions": [
        {
            "id": 1,
            "question_label": "Depression Mood – Sad, Hopeless, Helpless, Worthless",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 1,
            "options": [
                {
                    "question_id": 1,
                    "option_label": "Absent",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": true
                },
                {
                    "question_id": 1,
                    "option_label": "Gloomy attitude, pessimisim, hopelessness",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 1,
                    "option_label": "Occasional weeping",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 1,
                    "option_label": "Frequent weeping",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 1,
                    "option_label": "Patient reports virtually only those feeling states in his/her spontaneous verbal and non-verbal communication",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 2,
            "question_label": "Feelings of Guilt",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 2,
            "options": [
                {
                    "question_id": 2,
                    "option_label": "Absent",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": true
                },
                {
                    "question_id": 2,
                    "option_label": "Self approach,feels he/she has left people down",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 2,
                    "option_label": "Ideas of guilt or rumination over past erros or sinful deeds",
                    "option_score": 1,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 2,
                    "option_label": "Present illness is a punishment. Delusions of guilt.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 2,
                    "option_label": "Hears accusatory or denunciatory voices and/or experiences threatening visual hallucinations.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 3,
            "question_label": "Suicide",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 3,
            "options": [
                {
                    "question_id": 3,
                    "option_label": "Absent.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 3,
                    "option_label": "Feels life is not worth living.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 3,
                    "option_label": "Wishes he/she were dead or any thoughts of possible death to self.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": true
                },
                {
                    "question_id": 3,
                    "option_label": "Ideas or gestures of suicide.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 3,
                    "option_label": "Attempts at suicide (any serious attempt rate 4).",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 4,
            "question_label": "Insomnia (Early)",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 4,
            "options": [
                {
                    "question_id": 4,
                    "option_label": "No difficulty falling asleep.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 4,
                    "option_label": "Complains of occasional difficulty falling asleep, i.e. more than 1 ?2 hour.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 4,
                    "option_label": "Complains of nightly difficulty falling asleep.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": true
                }
            ]
        },
        {
            "id": 5,
            "question_label": "Insomnia (Middle)",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 5,
            "options": [
                {
                    "question_id": 5,
                    "option_label": "No difficulty.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 5,
                    "option_label": "Patient complains of being restless and disturbed during the night.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": true
                },
                {
                    "question_id": 5,
                    "option_label": "Waking during the night – any getting out of bed rates 2 (except for purposes of voiding).",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 6,
            "question_label": "Insomnia (Late)",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 6,
            "options": [
                {
                    "question_id": 6,
                    "option_label": "No difficulty.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 6,
                    "option_label": "Waking in early hours of the morning but goes back to sleep.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 6,
                    "option_label": "Unable to fall asleep again if he/she gets out of bed.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 7,
            "question_label": "Work & Activities",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 7,
            "options": [
                {
                    "question_id": 7,
                    "option_label": "No difficulty.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 7,
                    "option_label": "Thoughts and feelings of incapacity, fatigue or weakness related to activities, work or hobbies.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 7,
                    "option_label": "Loss of interest in activity, hobbies or work – either directly reported by the patient or indirect in listlessness, indecision and vacillation (feels he/she has to push self to work or activities).",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 7,
                    "option_label": "Decrease in actual time spent in activities or decrease in productivity. Rate 3 if the patient does not spend at least three hours a day in activities (job or hobbies) excluding routine chores.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 7,
                    "option_label": "Stopped working because of present illness. Rate 4 if patient engages in no activities except routine chores, or if patient fails to perform routine chores unassisted.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 8,
            "question_label": "Retardation",
            "question_description": "Slowness of thought and speech; impaired ability to concentrate, decreased motor activity",
            "question_type": "Multiple Choice",
            "display_order": 8,
            "options": [
                {
                    "question_id": 8,
                    "option_label": "Normal speech and thought.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 8,
                    "option_label": "Slight retardation during the interview.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 8,
                    "option_label": "Obvious retardation during the interview.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 8,
                    "option_label": "Interview difficult.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 8,
                    "option_label": "Complete stupor.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 9,
            "question_label": "Agitation",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 9,
            "options": [
                {
                    "question_id": 9,
                    "option_label": "None",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 9,
                    "option_label": "Fidgetiness.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 9,
                    "option_label": "Playing with hands, hair, etc.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 9,
                    "option_label": "Moving about, can’t sit still.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 9,
                    "option_label": "Hand wringing, nail biting, hair-pulling, biting of lips.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 10,
            "question_label": "Anxiety [Psychic]",
            "question_description": "Demonstrated By : subjective tension and irritability, loss of concentration, worrying about minor matters, apprehension, fears expressed without questioning, feelings of panic, feeling jumpy",
            "question_type": "Multiple Choice",
            "display_order": 10,
            "options": [
                {
                    "question_id": 10,
                    "option_label": "No difficulty.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 10,
                    "option_label": "Subjective tension and irritability.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 10,
                    "option_label": "Worrying about minor matters.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 10,
                    "option_label": "Apprehensive attitude apparent in face or speech.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 10,
                    "option_label": "Fears expressed without questioning.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 11,
            "question_label": "Anxiety [Somatic]",
            "question_description": "Physiological concomitants of anxiety, eg. Dry mouth, wind, indigestion, cramps, belching, palpitations, headaches, hyperventilation, sighing, urinary frequency sweating giddiness, blured vision, tinn",
            "question_type": "Multiple Choice",
            "display_order": 11,
            "options": [
                {
                    "question_id": 11,
                    "option_label": "Absent.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 11,
                    "option_label": "Mild.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 11,
                    "option_label": "Moderate",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 11,
                    "option_label": "Severe",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 11,
                    "option_label": "Incapacitating",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 12,
            "question_label": "Somatic Symptoms[Gastrointestinal]",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 12,
            "options": [
                {
                    "question_id": 12,
                    "option_label": "None",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 12,
                    "option_label": "Loss of appetite but eating without staff encouragement. Heavy feelings in abdomen.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 12,
                    "option_label": "Difficulty eating without staff urging. Requests or requires laxatives or medication for bowels or medication for gastro-intestinal symptoms.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 13,
            "question_label": "Somatic Symptoms[General]",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 13,
            "options": [
                {
                    "question_id": 13,
                    "option_label": "None",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 13,
                    "option_label": "Heaviness in limbs, back or head. Backaches, headaches, muscle aches. Loss of energy and Fatigability.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 13,
                    "option_label": "Any clear-cut symptom rates 2.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 14,
            "question_label": "General Symptoms",
            "question_description": "Symptoms such as loss of libido/menstrual disturbances",
            "question_type": "Multiple Choice",
            "display_order": 14,
            "options": [
                {
                    "question_id": 14,
                    "option_label": "Absent",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 14,
                    "option_label": "Mild.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 14,
                    "option_label": "Severe",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 15,
            "question_label": "Hypochondriasis",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 15,
            "options": [
                {
                    "question_id": 15,
                    "option_label": "Not present.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 15,
                    "option_label": "Self-absorption (bodily).",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 15,
                    "option_label": "Preoccupation with health.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                },
                {
                    "question_id": 15,
                    "option_label": "Frequent complaints, requests for help, etc.",
                    "option_score": 3,
                    "display_order": 4,
                    "is_selected": false
                },
                {
                    "question_id": 15,
                    "option_label": "Hypochondriacal delusions.",
                    "option_score": 4,
                    "display_order": 5,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 16,
            "question_label": "Loss of weight",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 16,
            "options": [
                {
                    "question_id": 16,
                    "option_label": "No weight loss.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 16,
                    "option_label": "Probable weight loss associated with present illness.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 16,
                    "option_label": "Definite (according to patient) weight Loss.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        },
        {
            "id": 17,
            "question_label": "Insight",
            "question_description": "",
            "question_type": "Multiple Choice",
            "display_order": 17,
            "options": [
                {
                    "question_id": 17,
                    "option_label": "Acknowledges being depressed and ill.",
                    "option_score": 0,
                    "display_order": 1,
                    "is_selected": false
                },
                {
                    "question_id": 17,
                    "option_label": "Acknowledges illness but attributes cause to bad food, climate, overwork, virus, need for rest, etc.",
                    "option_score": 1,
                    "display_order": 2,
                    "is_selected": false
                },
                {
                    "question_id": 17,
                    "option_label": "Denies being ill at all.",
                    "option_score": 2,
                    "display_order": 3,
                    "is_selected": false
                }
            ]
        }
    ]
}
    ;

export class HamiltonDepressionRatingScale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofHamiltonDepression: {},
            comments: "",
            totalScore: 0,

            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId
        }
    }

    componentDidMount() {
        this.getHamiltonDepression()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getHamiltonDepression(appointmentId)
        })
    }
    getHamiltonDepression() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL + 
        "?patient_id="+this.state.patientId+
        "&appointment_id="+this.state.appointmentId+
        "&rating_scale_name="+"Hamilton Depression Rating Scale";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHamiltonDepressionSuccess,
            this.getHamiltonDepressionFailure
        );
    }

    getHamiltonDepressionSuccess = (success) => {
        if (success.status == "success") {
            this.setState({
                listofHamiltonDepression: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score
            })
        }
    }
    getHamiltonDepressionFailure = error => { }


    validateTotalScores() {
        var { listofHamiltonDepression } = this.state;
        var getListOfQuestions = listofHamiltonDepression.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            getTotalScore = getTotalScore + getOptions[j].option_score
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore: getTotalScore
        })
    }


    saveHamiltonDepression() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var data = {
            "id": this.state.listofHamiltonDepression.id ? this.state.listofHamiltonDepression.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofHamiltonDepression.rating_scale_id,
            "rating_scale_name": this.state.listofHamiltonDepression.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": this.state.listofHamiltonDepression.questions
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveHamiltonDepressionSuccess,
            this.saveHamiltonDepressionFailure
        );
    }else{
        this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
    }
    
    }

    saveHamiltonDepressionSuccess = success => {
        if (success.status == "success") {
            this.getHamiltonDepression()
            this.props.showResposeValue("success", success.message);
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveHamiltonDepressionFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',  paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
            </View>
        )
    }

    onselectOptions(indexOfQuestion, indexOfOptions) {
        var { listofHamiltonDepression } = this.state;
        var getListOfOptions = listofHamiltonDepression.questions[indexOfQuestion].options;
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        listofHamiltonDepression.questions[indexOfQuestion]["options"] = prepareData
        this.setState({ listofHamiltonDepression }, () => {
            this.validateTotalScores()
        })

    }

    renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
        return (
            <View key={indexOfOptions} >
                <TouchableOpacity
                    onPress={() => {
                        this.onselectOptions(indexOfQuestion, indexOfOptions)
                    }}
                    style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 0.05 }} >
                        <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                    </View>
                    <View style={{ flex: 0.95 }} >
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    renderListOfDatas(questions, indexOfQuestion) {
        var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
        return (
            <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
                <CommonHistorySectionHeader
                    heading1={getQuestion}
                    // question={questions.question_label}
                    headerKey={"RatingScale"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                {
                    questions.question_description ?
                        <Text style={[Style.historyHeaderFontStyleStartText, { fontSize: 14, marginVertical: 5 }]}>{questions.question_description}</Text>
                        : null
                }

                {
                    questions.options.length > 0 ?
                        questions.options.map((item, index) => {
                            return (
                                this.renderListOfOptions(item, index, indexOfQuestion)
                            )
                        })
                        : null
                }
            </View>
        )
    }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                    {/* <Text style={{color:color.white}}>{this.state.}</Text> */}
                </View>
                {/* <Text>{this.state.totalScore}</Text>
                <Text>{this.state.comments}</Text> */}
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("Hamilton Depression Rating Scale")}
                        {
                            this.state.listofHamiltonDepression && this.state.listofHamiltonDepression.questions && this.state.listofHamiltonDepression.questions.length > 0 ?
                                this.state.listofHamiltonDepression.questions.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        }

                        <View style={{ backgroundColor: color.applicationBackgroundColor,height:60,justifyContent:'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveHamiltonDepression.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})