import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { NumberFormat } from '../../../BaseComponent'
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';

class TotalRevenue extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,

      revenueLabels: [],
      revenueDatas: [],
      TotalRevenue: 0,
    }
  };
  componentDidMount() {
    this.getRevenueData()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getRevenueData()
      })
    }
  }
  /* Revenue data API start*/
  getRevenueData() {
    var serviceUrl =
      Constants.TOTAL_REVENUE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRevenueSuccess,
      this.getRevenueFailure
    );

  }

  getRevenueSuccess = (success) => {
    if (success.status === "success") {
      var getRevenueDatas = success.data;
      this.setState({
        TotalRevenue: getRevenueDatas.total_revenue
      })
      var revenueLabels = [];
      var revenueDatas = [];

      Object.keys(getRevenueDatas).forEach((key) => {
        if (key == "in_patient") {
          revenueLabels.push("In Patient");
          revenueDatas.push(getRevenueDatas[key]);
        } else if (key == "laboratory") {
          revenueLabels.push("Laboratory");
          revenueDatas.push(getRevenueDatas[key]);
        } else if (key == "out_patient") {
          revenueLabels.push("Out Patient");
          revenueDatas.push(getRevenueDatas[key]);
        } else if (key == "pharmacy") {
          revenueLabels.push("Pharmacy");
          revenueDatas.push(getRevenueDatas[key]);
        }
      });

      this.setState({
        revenueLabels: revenueLabels,
        revenueDatas: revenueDatas,
      });
    }else{
      this.props.Response(success.message)
    }
  };

  getRevenueFailure = (error) => {
    this.props.Response(error.message)
  };
  /* Revenue data API end*/

  /* Export Excel API start*/
  getTotalRevenueExcel = () => {
    var serviceUrl =
      Constants.TOTAL_REVENUE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getTotalRevenueSuccess,
      this.getTotalRevenueFailure,
      "excel"
    );
  }
  getTotalRevenueSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getTotalRevenueFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/
  render() {
    const data = {
      datasets: [
        {
          data: this.state.revenueDatas,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(204, 255, 153)',
            'rgb(97, 207, 126)',
            'rgb(168, 50, 155)'
          ],
        },
      ],
      labels: this.state.revenueLabels,
    };


    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>


        {this.state.TotalRevenue > 0 ?
          <View style={{ flex: 1, paddingTop: '1.5vw' }}>
            <Pie
              width={'20vw'}
              height={'15vw'}
              data={data}
              options={
                {
                  title: {
                    position: "bottom",
                    display: true,
                    text: ['Total Revenue'],
                    fontColor: ['rgb(4, 183, 177)']
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right',
                      labels: {
                        font: {
                          size: 5
                        }
                      },
                    }
                  },
                }}

            />
            <View style={styles.TotalAmountView}>
              <Text style={styles.TotalAmountText}>Total : ₹ </Text>
              <Text style={styles.TotalAmountValue}>{NumberFormat(this.state.TotalRevenue)}</Text>
            </View>
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Total Revenue</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

        <View style={styles.IconView}>
          <TouchableOpacity onPress={this.getTotalRevenueExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </View>
      </View>

    )
  }
}

export default TotalRevenue;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '9vw',
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PieChart: {
    width: '30vw',
    height: '40vh'
  },
  IconView: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '1vw'
  }
})