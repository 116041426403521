import React, { Component } from "react";
import {
    View, Text, Platform, TouchableOpacity, TextInput, CheckBox
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";


const platform = Platform.OS;
const pageTitle = "Posterior Segment";
const pageName = "PosteriorSegment";
const isPageName = "isPosteriorSegment";
import OpthamologyService from "../../../network/OpthamologyService"
import Service from "../../../network/Service"
import { Constants } from "../../../utils/Constants"
import Styles from "../../../styles/Style";

export default class PosteriorSegment extends BaseComponentStyle {
    constructor(props) {
        super(props);

        this.state = {
            isLocked:this.props.patientAppointment.is_locked,
            isPosteriorSegment: isPageName === this.props.selectedView ? true : false,
            patientAppointment: this.props.patientAppointment,

            rightEye: false,
            lefytEye: false,
            normal: false,


            discRightEye: "",
            retinaRightEye: "",
            maculaRightEye: "",
            fundusRightEye: "",
            mediaRightEye: "",
            vesselsRightEye: "",
            peripheryRightEye: "",

            discLeftEye: "",
            retinaLeftEye: "",
            maculaLeftEye: "",
            fundusLeftEye: "",
            mediaLeftEye: "",
            vesselsLeftEye: "",
            peripheryLeftEye: "",

        };
    }

    triggerNewItem = (key) => {
        this.props.triggerNewItem(key);
    }

    componentWillReceiveProps(props) {
        if(props && props.patientAppointment && props.patientAppointment.patient_id){
            //   this.setState({  isLocked:props.patientAppointment.is_locked })
            this.state.isLocked=props.patientAppointment.is_locked
          }
        
        let updateFontColor = isPageName === props.selectedView ? true : false;
        if (updateFontColor !== this.props.isPosteriorSegment) {
            this.setState({ isPosteriorSegment: updateFontColor });
        }
    }

    updateState = (value, key) => {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    };

    componentDidMount(){
        setTimeout(() => {
            this.getPosteriorSegmentDatas()
        }, 4000);
        
    }

    getPosteriorSegmentDatas(){
        var service_url = Constants.POSTERIOR_SEGMENT_POST_API+"?appointment_id="+this.state.patientAppointment.appointment_id+"&patient_id="+this.state.patientAppointment.patient_id;
       setTimeout(() => {
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPosteriorSegmentDatasSuccess,
            this.getPosteriorSegmentDatasFailure
          );
       }, 4000);
     
    }
    getPosteriorSegmentDatasSuccess = success =>{
        if(success.data.length > 0){
        for (let i = 0; i < success.data.length; i++) {
            if(success.data[i].eye_type == "RE"){
                var states = this.state
                states["discRightEye"]=success.data[i].disc;
                // states["retinaRightEye"]=success.data[i].retina;
                states["maculaRightEye"]=success.data[i].macula;
                // states["fundusRightEye"]=success.data[i].fundus;
                states["mediaRightEye"]=success.data[i].media;
                states["vesselsRightEye"]=success.data[i].vessels;
                states["peripheryRightEye"]=success.data[i].periphery;
                this.setState({ states })
            }else if(success.data[i].eye_type == "LE"){
                var states = this.state
            
                states["discLeftEye"]=success.data[i].disc;
                // states["retinaLeftEye"]=success.data[i].retina;
                states["maculaLeftEye"]=success.data[i].macula;
                // states["fundusLeftEye"]=success.data[i].fundus;
                states["mediaLeftEye"]=success.data[i].media;
                states["vesselsLeftEye"]=success.data[i].vessels;
                states["peripheryLeftEye"]=success.data[i].periphery;
                this.setState({ states })
            }
            
        }
    }
    }
    getPosteriorSegmentDatasFailure = error =>{
       // alert("error----------> ");
    }



    renderTextField = (placeholder, key, value) => {
        return (
            <View
                style={{
                    marginLeft: 5,
                }}
            >
                <TextInput
                    editable={ !this.state.isLocked}
                    placeholder={placeholder}
                    autoCapitalize="none"
                    value={value}
                    onChangeText={text => {
                        this.updateState(text, key);
                    }}
                    style={[{
                        width: "80%",
                        height: 37,
                        fontSize: 12,
                        backgroundColor: this.state.isLocked ? color.disableComponentColor : color.white,
                        borderWidth: 0.5,
                        // borderRadius: 10,
                        borderColor: color.rightPanelInputBorder,
                        paddingLeft: 15
                        //  textAlign:"center"
                    },Styles.allButtonBorderRadius]}
                />
            </View>
        );
    };


    copyValues(type){


        if(type == "leftEye"){
            if(this.state.lefytEye){
                var states = this.state
                states["discLeftEye"]="Normal";
                // states["retinaLeftEye"]="Normal Color & Pattern ";
                states["maculaLeftEye"]="Normal";
                // states["fundusLeftEye"]="Normal";
                states["mediaLeftEye"]="Clear";
                states["vesselsLeftEye"]="Normal";
                states["peripheryLeftEye"]="Normal";
                this.setState({ states })
            } else {
                var states = this.state
                states["discLeftEye"]="";
                // states["retinaLeftEye"]="";
                states["maculaLeftEye"]="";
                // states["fundusLeftEye"]="";
                states["mediaLeftEye"]="";
                states["vesselsLeftEye"]="";
                states["peripheryLeftEye"]="";

                this.setState({ states })
            }
        }

        else if(type == "rightEye")
        {
        if(this.state.rightEye){
            var states = this.state
            states["discRightEye"]="Normal";
            // states["retinaRightEye"]="Normal Color & Pattern ";
            states["maculaRightEye"]="Normal";
            // states["fundusRightEye"]="Normal";
            states["mediaRightEye"]="Clear";
            states["vesselsRightEye"]="Normal";
            states["peripheryRightEye"]="Normal";
            this.setState({ states })
        }else{
            var states = this.state
            states["discRightEye"]="";
            // states["retinaRightEye"]="";
            states["maculaRightEye"]="";
            // states["fundusRightEye"]="";
            states["mediaRightEye"]="";
            states["vesselsRightEye"]="";
            states["peripheryRightEye"]="";
            this.setState({ states })
        }
    }else if(type == "normal"){
        if(this.state.normal){
            var states = this.state;

            states["discRightEye"]="Normal";
            // states["retinaRightEye"]="Normal Color & Pattern ";
            states["maculaRightEye"]="Normal";
            // states["fundusRightEye"]="Normal";
            states["mediaRightEye"]="Clear";
            states["vesselsRightEye"]="Normal";
            states["peripheryRightEye"]="Normal";

            states["discLeftEye"]="Normal";
            // states["retinaLeftEye"]="Normal Color & Pattern ";
            states["maculaLeftEye"]="Normal";
            // states["fundusLeftEye"]="Normal";
            states["mediaLeftEye"]="Clear";
            states["vesselsLeftEye"]="Normal";
            states["peripheryLeftEye"]="Normal";

            this.setState({ states })
        }else{
            var states = this.state;
            states["discRightEye"]="";
            // states["retinaRightEye"]="";
            states["maculaRightEye"]="";
            states["fundusRightEye"]="";
            states["mediaRightEye"]="";
            states["vesselsRightEye"]="";
            states["peripheryRightEye"]="";

            states["discLeftEye"]="";
            // states["retinaLeftEye"]="";
            states["maculaLeftEye"]="";
            // states["fundusLeftEye"]="";
            states["mediaLeftEye"]="";
            states["vesselsLeftEye"]="";
            states["peripheryLeftEye"]="";

            this.setState({ states })
        }
    }

    }


    posteriorSegmentTextBoxRow(header, placeholderRight, keyRight, valueRight, placeholderLeft, keyLeft, valueLeft,) {
        return (
            <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
                <View style={{ flex: 0.2, backgroundColor: "transparant", justifyContent: 'center' }}>
                    <Text style={{ fontWeight: "700" }}>{header}</Text>
                </View>
                <View style={{ flex: 0.4, backgroundColor: "transparant" }}>
                    {this.renderTextField(placeholderLeft, keyLeft, valueLeft,)}
                </View>
                <View style={{ flex: 0.4, backgroundColor: "transparant" }}>
                    {this.renderTextField(placeholderRight, keyRight, valueRight)}
                </View>
            </View>
        );
    }

    copyCheckBoxValues(key) {
        if (key == "rightEye") {
            if(this.state.lefytEye){
                this.setState({ rightEye: !this.state.rightEye, normal: true },()=>{
                    
                    if(!this.state.rightEye){
                        this.setState({ normal: false },()=>{ this.copyValues("rightEye")})
                    }else{
                        this.copyValues("rightEye")
                    }
                })
            }else{
                this.setState({ rightEye: !this.state.rightEye },()=>{this.copyValues("rightEye")})
            }
        } else if (key == "leftEye") {
            if(this.state.rightEye){
                this.setState({ lefytEye: !this.state.lefytEye, normal: true },()=>{
                    
                    if(!this.state.lefytEye){
                        this.setState({ normal: false },()=>{ this.copyValues("leftEye")})
                    }else{
                        this.copyValues("leftEye")
                    }
                    
                })
            }else{
                this.setState({ lefytEye: !this.state.lefytEye },()=>{this.copyValues("leftEye")})
            }
            
        } else if (key == "normal") {

            if(this.state.rightEye && this.state.lefytEye){
                this.setState({
                    rightEye: false,
                    lefytEye: false,
                    normal: false
                },()=>{this.copyValues("normal")})
            }else{
            this.setState({
                rightEye: true,
                lefytEye: true,
                normal: true
            },()=>{this.copyValues("normal")})
        }
        }
    }


    renderCheckBox(label, value, key) {
        return (
            <View style={{ flexDirection: 'row', marginRight: 30 }}>
                <CheckBox disabled={this.state.isLocked} value={value}
                    onValueChange={() => {
                        this.copyCheckBoxValues(key)
                    }}
                />
                <Text style={{ marginLeft: 10, fontSize: 12, color: "#888888" }}>{label}</Text>
            </View>
        );
    }



    savePosteriorSegment() {

        // console.log("anterior------------->  "+JSON.stringify(this.state.patientAppointment))

        var service_url = Constants.POSTERIOR_SEGMENT_POST_API;
        const data = new FormData();

        var states = this.state

        data.append('appointment_id',states.patientAppointment.appointment_id);
        data.append('patient_id',states.patientAppointment.patient_id);
        
        
        data.append('le_disc',states.discLeftEye);
        data.append('le_retina',states.retinaLeftEye);
        data.append('le_macula',states.maculaLeftEye);
        data.append('le_fundus',states.fundusLeftEye);
        data.append('le_media',states.mediaLeftEye);
        data.append('le_vessels',states.vesselsLeftEye);
        data.append('le_periphery',states.peripheryLeftEye);

        data.append('le_anterior_image_key', "");
        data.append('le_image_json', "");

        
        data.append('re_disc',states.discRightEye);
        data.append('re_retina',states.retinaRightEye);
        data.append('re_macula',states.maculaRightEye);
        data.append('re_fundus',states.fundusRightEye);
        data.append('re_media',states.mediaRightEye);
        data.append('re_vessels',states.vesselsRightEye);
        data.append('re_periphery',states.peripheryRightEye);

        data.append('re_anterior_image_key', "");
        data.append('re_image_json', "");

        OpthamologyService.getInstance().documentUploadPost(
            service_url,
            data,
            this,
            this.savePosteriorSegmentSuccess,
            this.savePosteriorSegmentFailure,
            'multipart/form-data'
        );

    }

    savePosteriorSegmentSuccess = (success)=>{
        // console.log(JSON.stringify(success))
        if (success.data.status == "success") {
            // console.log(JSON.stringify(success))
            this.props.showResposeValue("success", success.data.message)
        } else {
            this.props.showResposeValue("error", success.data.message)
        }
    }
    savePosteriorSegmentFailure = (error)=>{
        this.props.showResposeValue("error", error.data.message)
    }

    render() {

        return (
            <View>
                {this.renderTitleBorder(this.state.isPosteriorSegment, pageTitle, isPageName, this.triggerNewItem)}

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {this.renderCheckBox("Right Eye", this.state.rightEye, "rightEye")}
                        {this.renderCheckBox("Left Eye", this.state.lefytEye, "leftEye")}
                        {this.renderCheckBox("Normal", this.state.normal, "normal")}
                    </View>
                    <View style={{flexDirection:'row'}}>

                    {/* <TouchableOpacity disabled={this.state.isLocked} onPress={()=>{
                        this.setState({
                            rightEye:false,lefytEye:false,normal:false
                        },()=>{this.copyValues("normal")})
                        }} disabled={this.state.isLocked}  style={{ marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
                            <Text style={{ color: color.white }}>{"Clear"}</Text>
                        </TouchableOpacity> */}



                        <TouchableOpacity disabled={this.state.isLocked}
                         onPress={()=>{
                            this.setState({
                                rightEye:false,lefytEye:false,normal:false
                            },()=>{this.copyValues("normal")})
                            }}
                        style={[{
                            marginRight: 30, paddingTop: 8, paddingLeft: 18,
                            paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
                        }, Styles.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Clear"}</Text>
                        </TouchableOpacity>




                        <TouchableOpacity disabled={this.state.isLocked}
                         onPress={()=>{
                            this.savePosteriorSegment()
                            }}
                        style={[{
                            marginRight: 30, paddingTop: 8, paddingLeft: 18,
                            paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
                        }, Styles.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Save"}</Text>
                        </TouchableOpacity>



                        {/* <TouchableOpacity disabled={this.state.isLocked} onPress={()=>{
                            this.savePosteriorSegment()
                        }}disabled={this.state.isLocked}  style={{ marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
                            <Text style={{ color: color.white }}>{"Save"}</Text>
                        </TouchableOpacity> */}
                    </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
                    <View style={{ flex: 0.2, backgroundColor: "transparant", justifyContent: 'center' }}>
                       
                    </View>
                    <View style={{ flex: 0.4, backgroundColor: "transparant",paddingLeft:30 }}>
                        <Text style={{ fontWeight: "700" }}>{"Right Eye"}</Text>
                    </View>
                    <View style={{ flex: 0.4, backgroundColor: "transparant",paddingLeft:30 }}>
                        <Text style={{ fontWeight: "700" }}>{"Left Eye"}</Text>
                    </View>
                </View>


                {/* {this.posteriorSegmentTextBoxRow("Retina",  "Type Remarks", "retinaLeftEye", this.state.retinaLeftEye,"Type Remarks", "retinaRightEye", this.state.retinaRightEye)} */}
                {this.posteriorSegmentTextBoxRow("Disc", "Type Remarks", "discLeftEye", this.state.discLeftEye, "Type Remarks", "discRightEye", this.state.discRightEye)}
                {this.posteriorSegmentTextBoxRow("Macula", "Type Remarks", "maculaLeftEye", this.state.maculaLeftEye ,"Type Remarks", "maculaRightEye", this.state.maculaRightEye)}
                {/* {this.posteriorSegmentTextBoxRow("Fundus", "Type Remarks", "fundusLeftEye", this.state.fundusLeftEye ,"Type Remarks", "fundusRightEye", this.state.fundusRightEye)} */}
                {this.posteriorSegmentTextBoxRow("Media", "Type Remarks", "mediaLeftEye", this.state.mediaLeftEye ,"Type Remarks", "mediaRightEye", this.state.mediaRightEye)}
                {this.posteriorSegmentTextBoxRow("Vessels", "Type Remarks", "vesselsLeftEye", this.state.vesselsLeftEye ,"Type Remarks", "vesselsRightEye", this.state.vesselsRightEye)}
                {this.posteriorSegmentTextBoxRow("Periphery", "Type Remarks", "peripheryLeftEye", this.state.peripheryLeftEye ,"Type Remarks", "peripheryRightEye", this.state.peripheryRightEye)}
                {/* <AnteriorSegmentDraw patientAppointment={this.props.patientAppointment} 
                imageKey={this.state.anterior_image_key} returnImage={this.updateImageParam.bind(this)} /> */}

            </View>
        );
    }

    updateImageParam = (obj) => {

    }
}


// const styles = StyleSheet.create({
//     container: {
//       flex: 1,
//       alignItems: "center",
//       justifyContent: "center",
//     },
//     checkboxContainer: {
//       flexDirection: "row",
//       marginBottom: 20,
//     },
//     checkbox: {
//       alignSelf: "center",
//     },
//     label: {
//       margin: 8,
//     },
//   });
