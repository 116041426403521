import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView, TextInput } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import Pagination from '../../common/Pagination';
import { Tooltip } from 'antd';
import Icons from "react-native-vector-icons/Feather";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import { Checkbox } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import {DatePicker} from 'antd';
import moment from "moment";
import ImagePath from './../../../../utils/ImagePaths';

const navigationHeader = [
  { label: "Stock Transfer", value: "stockTransfer" },
  { label: "InBound", value: "inBound" },
  { label: "OutBound", value: "outBound" },
  { label: "Stock Transfer Report", value: "stockTransferReport" }
]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class OutBound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockTransferList: [],
      stockTransferAfterPagination: [],
      current_page: 1,
      records_per_page: 10,
      isUpdateClick: false,
      outBoundPopupList: [],
      isEditDetails: false,
      selectedIndex: "",
      isSelfTransfer: false,
      isCourierTransfer: false,
      selectedData: {},
      headerDetails: {},
      popupBatchNo: [],
      stockDetails: [],
      isOnFocus: false,
      typedIndex: "",
      selectedID: "",
      selectedStatus: "",
      stockDate: converted_date,
      expcDate: converted_date,
      trackingNo: "",
      trackingLink: ""
    };
  }
  componentDidMount() {
    this.getOutboundList()
  }
  getOutboundList = () => {
    var url = Constants.STOCK_TRANSFER_OUTBOUND + "?summary_list=true"
    this.setState({
      stockTransferList: [],
      stockTransferAfterPagination: [],
      current_page: 1,
      records_per_page: 10,
    })
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getOutboundListSuccess,
        this.getOutboundListFailure
    );
}

getOutboundListSuccess = response => {
    if (response && response.status == "success") {
        var data  = response.data
        this.setState({
          stockTransferList: data
        })
    } else {
        this.props.showResposeValue("error", response.message)
    }
}
getOutboundListFailure = error => {
    this.props.showResposeValue("error", error.message)
}
  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
      </View>)
  }
  renderHeaderView = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.08, "0.95vw")}
        {this.getBillTableTitle('No Of Product', 0.1, "0.95vw")}
        {this.getBillTableTitle('To Branch', 0.12, "0.95vw")}
        {this.getBillTableTitle('Total Value', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST No', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST Date', 0.1, "0.95vw")}
        {this.getBillTableTitle('Tracking Details', 0.13, "0.95vw")}
        {this.getBillTableTitle('Notes', 0.2, "0.95vw")}
        {this.getBillTableTitle('Action', 0.07, "0.95vw")}

      </View>
      // </View>
    )
  }
  paginationChangedValues(data) {
    this.setState({
      stockTransferAfterPagination: data
    })
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
      current_page: current_page,
      records_per_page: records_per_page
    })
  }
 
  renderSearchTextBox = (placehold, stateKey, value ) => {
    return(
      <View style={{ marginTop: "0.8vw" }}>
         <Text style={styles.searchBoxText}>{placehold}</Text>
          <TextInput
            editable = { this.state.isCourierTransfer ? true : false }
            value={value}
            onChangeText={(text) => { 
              var states = this.state
              states[stateKey] = text
              this.setState({ states })
             }}
            style={styles.textBox}
          />
      </View>
    )
  }
  getPopupData = () => {
    var { selectedData } = this.state
    var url = Constants.STOCK_TRANSFER_OUTBOUND + "?summary_list=" + "&id=" + selectedData.id
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getPopupDataSuccess,
        this.getPopupDataFailure
    );
  }
  getPopupDataSuccess = response => {
    if (response && response.status == "success") {
        this.setState({
          headerDetails: response.header_details,
          outBoundPopupList: response.line_items
        },() => {
          this.state.outBoundPopupList.forEach(data => { data.batch_no = [], data.total_amount = null, data.trans_qty = null, data.selectedStockDetail = [], data.exp_on = [], data.selected_qty  = []});

        })
    } else {
        // this.props.showResposeValue("error", response.message)
    }
}
getPopupDataFailure = error => {
    this.props.showResposeValue("error", error.message)
}

updateStockDetails = () => {
  var { outBoundPopupList, stockDetails, selectedID, typedIndex } = this.state
  var service_url = ""
  var total = 0
  var total_value = 0
  let current_row = outBoundPopupList.length;
  let current_stock_row = stockDetails.length
 
  for (let i = 0; i < current_row; i++) { 
    total = total + outBoundPopupList[i].total_amount
    var line_item = []
    var stock = []
    var selectedIndex = i
    var selectedStockDetail = outBoundPopupList[i].stock_details
       for(  let i = 0; i<selectedStockDetail.length ; i++){
        if(typedIndex === selectedIndex && selectedStockDetail[i].is_selected === true) { 
          total_value = total_value + (selectedStockDetail[i].selected_quantity * selectedStockDetail[i].mrp_per_quantity)
         stock.push({"stock_list_id": selectedStockDetail[i].stock_list_id, "batch_no":  selectedStockDetail[i].batch_no, "expiry_date":  selectedStockDetail[i].expiry_date, "quantity":  selectedStockDetail[i].selected_quantity})
        }
       }
    line_item.push({"id": outBoundPopupList[i].id, "total_amount":  parseFloat(total_value).toFixed(2), "stock_details": stock})
    var net_amount = parseFloat(outBoundPopupList[i].total_amount).toFixed(2)
    var data = {
      "delivery_date": this.state.stockDate,
      "st_date": this.state.expcDate,
      "exchange_summary_id": this.state.headerDetails.exchange_Summary_id,
      "net_amount": parseFloat(net_amount),
      "stock_line_item": line_item
    }
   }
  
  service_url = Constants.STOCK_TRANSFER_OUTBOUND
  OpthamologyService.getInstance().putMethod(
    service_url,
    data,
    this,
    this.updateStatusSuccess,
    this.updateStatusFailure
  );
}
updateStatusSuccess = response => {
  if (response && response.status == "success") {
    this.props.showResposeValue("success", response.message)
    this.setState({
      isUpdateClick: false,
    },() => {
      this.getOutboundList()
    })
  } else {
    this.props.showResposeValue("error", response.message)
  }
}
updateStatusFailure = (error) => {
  this.props.showResposeValue("error", error.message)
}
updateTransferDetails = (isCouier = false) => {
  var service_url = ""
  var data = {
    "status": isCouier ? "" : "Transfer" ,
    "id": this.state.selectedID,
    "corrier_type": this.state.isCourierTransfer ? "Courier" : this.state.isSelfTransfer ? "Self" : "",
    "track_detail": this.state.courierName,
    "track_no": this.state.trackingNo,
    "track_link": this.state.trackingLink
  }
  if( this.state.isCourierTransfer || this.state.isSelfTransfer ) { 
  service_url = Constants.STOCK_TRANSFER_OUTBOUND
  OpthamologyService.getInstance().patchMethod(
    service_url,
    data,
    this,
    this.updateTransferSuccess,
    this.updateTransferFailure
  );
  } else {
    this.props.showResposeValue("error", "Please enter the stock transfer details")
  }
}
updateTransferSuccess = response => {
  if (response && response.status == "success") {
    this.props.showResposeValue("success", response.message)
    this.setState({
      isEditDetails: false
    })
    this.getOutboundList()
  } else {
    this.props.showResposeValue("error", response.message)
  }
}
updateTransferFailure = (error) => {
  this.props.showResposeValue("error", error.message)
}
  renderTableDataView = () => {
    var { current_page, records_per_page } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ zIndex: -1, height: "68vh" }}>
        {this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.length > 0 ?
          this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.map((item, index) => {
            let sno = (current_page - 1) * records_per_page + index + 1;
            let zIndex = (index + 1) * -10;
            let date = item.st_date ? moment(item.st_date).format("DD-MM-YYYY") : "-"
            return (
              <View style={[styles.dataContainer, { marginLeft: "0.5vw", zIndex: zIndex }]}>
                <View style={{ flex: 0.08 }}><Text style={styles.TableDataText}>{sno}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.no_of_product}</Text></View>
                <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.from_branch}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.total_amount ? item.total_amount : 0}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.st_no ? item.st_no : "-"}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{date ? date : "-"}</Text></View>

                <View style={{ flex: 0.13, flexWrap: "wrap", justifyContent: "flex-start" }}>
                  { item.notes && item.notes == "Awaiting to initiate transfer" || item.notes === "Stock moved out from your branch" ?
                    <TouchableOpacity style={[styles.addButton, { backgroundColor: color.white, justifyContent: "flex-start", alignItems: "flex-start" }]} onPress={() => {
                      this.setState({
                        isEditDetails: true,
                        selectedIndex: index,
                        selectedID: item.id,
                        selectedStatus: item.status
                      })
                    }}>
                      <View style={{ flexDirection: "row" }}>
                        <Icons name="edit" size={"1.2vw"} color={color.themeDark} />
                        <View style={{ marginLeft: "0.5vw" }}>
                          <Text style={{ fontSize: "0.7vw" }}>{item.corrier_type ? item.corrier_type : "" }</Text>
                          <Text style={{ fontSize: "0.7vw" }}>{item.track_detail ? JSON.parse(item.track_detail) : ""}</Text>
                        </View>
                      </View>
                    </TouchableOpacity> : 
                    <Text style={styles.TableDataText}>{item.tracking_details}</Text> 

                  }
                   {this.state.isEditDetails && this.state.selectedIndex === index ?
                    <View key={index} style={styles.suggestionMain}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Checkbox checked = {this.state.isSelfTransfer} value={this.state.isSelfTransfer} style={{ alignSelf: "center", marginLeft: "1vw" }} onChange={() => {
                            this.setState({
                              isSelfTransfer: !this.state.isSelfTransfer,
                              isCourierTransfer: false,
                            })
                        }} />
                        <Text style={{ fontSize: "0.8vw", marginLeft: "1vw" }}>{"Self"}</Text>
                        <Checkbox checked = {this.state.isCourierTransfer} value={this.state.isCourierTransfer} style={{ alignSelf: "center", marginLeft: "1vw" }} onChange={() => {
                            this.setState({
                              isCourierTransfer: !this.state.isCourierTransfer,
                              isSelfTransfer: false,
                            })
                        }} />
                       <Text style={{ fontSize: "0.8vw", marginLeft: "1vw" }}>{"Courier"}</Text>
                      </View>
                      {/* { this.state.isCourierTransfer } */}
                      <View>
                        {this.renderSearchTextBox("Courier Name", "courierName", this.state.courierName)}
                        {this.renderSearchTextBox("Tranking No", "trackingNo", this.state.trackingNo)}
                        {this.renderSearchTextBox("Tranking Link", "trackingLink", this.state.trackingLink)}
                      </View>
                      <TouchableOpacity style={[styles.addButton, { justifyContent: "center", alignItems: "center", alignSelf: "center", width: "50%", marginTop: "0.5vw" }]} onPress={() => {
                        this.setState({
                          selectedStatus: item.status
                        },() => {
                          this.updateTransferDetails(true)
                        })
                      }}>
                        <Text style={styles.addText}>Done</Text>
                      </TouchableOpacity>

                    </View> : null
                  }

                </View>
                <View style={{ flex: 0.2 }}>
                  <Tooltip placement="topLeft" title={item.notes ? item.notes : ""}>
                    <Text style={styles.TableDataText}>{item.notes.length > 50 ? item.notes.slice(0, 50) + ".." : item.notes}</Text>
                  </Tooltip>
                </View>
                <TouchableOpacity style={[styles.addButton, { flex: 0.07, width: "60%" }]} onPress={() => {
                 if( item.status === "Ordered" ) 
                  this.setState({
                    isUpdateClick: true,
                    selectedData: item,
                    selectedID: item.id,
                  }, () => {
                    this.getPopupData()
                  })
                  if(item.status === "Admin approved") {
                    this.setState({
                      isUpdateClick: false,
                      selectedStatus: item.status

                    },() => {
                      this.updateTransferDetails(false)
                    })
                  }
                }}>
                  <View style={{ flexDirection: "row" }}>
                    <Image source={item.status === "Ordered" ? ImagePath.Pharmacy : item.status === "Admin approval pending" ? ImagePath.Pending : item.status === "Admin Approve" ?  ImagePath.Approve : ImagePath.InTransmit } style={styles.PrintIconImg} />
                    <Text style={styles.addText}>{item.status === "Ordered" ? "Update" : item.status === "Admin approval pending" ? "Pending" : item.status === "Admin approved" ? "Transfer": "In-Transist"}</Text>
                  </View>
                </TouchableOpacity>

              </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>
        }

      </ScrollView>
    )
  }
  renderPaginationView = () => {
    return (
      <View>
        {this.state.stockTransferList && this.state.stockTransferList.length > 0 ?
          <Pagination
            paginationChangedValues={this.paginationChangedValues.bind(this)}
            totalItems={this.state.stockTransferList}
            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
          /> : null
        }
      </View>
    )
  }
  renderPopupTextView = (label, value, key) => {
    return (

      <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 0.25, margin: "0.5vw", height: "2vw" }}>
        <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{ label }</Text>
        { label === "Stock Trans Date" || label === "Expec Deliv Date" ?
         <DatePicker
            // disabledDate={this.disabledDate}
            value={moment(value)}
            onChange={(date) => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              var states = this.state
              states[key] = converted_date
              this.setState({ stockDate: converted_date });
            }
            }
            clearIcon={false}
            format={"DD-MM-YYYY"}
            placeholder={"YYYY-MM-DD"}
            defaultValue={moment(value, "YYYY-MM-DD")}
            style={{
              zIndex: -1, width: '65%',
              borderColor: '#CDD1D5',
              height: "58%", borderRadius: 4, borderWidth: 1,
              flex: 0.5,
              marginTop: "-0.22vw",
              justifyContent: "center",
              alignItems: 'center',
              alignContent: "center",
              padding: "0.8vw"
            }}
          /> : 
          <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{ ": " + value ? value : "-"}</Text>
        }
      </View>
    )
  }
  renderHeaderViews = () => {
    var { headerDetails } = this.state
    return (
      <View>
        <View style={{ flexDirection: "row", flex: 1 }}>
          {this.renderPopupTextView("From Branch", headerDetails.from_branch)}
          {this.renderPopupTextView("To Branch", headerDetails.to_branch)}
          {this.renderPopupTextView("Stock Transfer Value", headerDetails.stock_transfer_value)}
          {this.renderPopupTextView("Stock Trans Date", this.state.stockDate, "stockDate")}
        </View>
        <View style={{ flexDirection: "row" }}>
          {this.renderPopupTextView("Contact Details", headerDetails.from_branch_contact)}
          {this.renderPopupTextView("Contact Details", headerDetails.to_branch_contact)}
          {this.renderPopupTextView("Expec Deliv Date", this.state.expcDate, "expcDate")}
          {this.renderPopupTextView("Stock Transfer No", headerDetails.stock_transfer_no)}
        </View>
      </View>
    )
  }
  renderPopupHeader = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.05, "0.8vw")}
        {this.getBillTableTitle('Brand', 0.15, "0.8vw")}
        {this.getBillTableTitle('Generic / Combo', 0.15, "0.8vw")}
        {this.getBillTableTitle('Dosage Type / Strength', 0.12, "0.8vw")}
        {this.getBillTableTitle('Batch No', 0.13, "0.8vw")}
        {this.getBillTableTitle('Exp On', 0.1, "0.8vw")}
        {this.getBillTableTitle('Selected Quan', 0.1, "0.8vw")}
        {this.getBillTableTitle('Transfer Quantity', 0.1, "0.8vw")}
        {this.getBillTableTitle('Total', 0.1, "0.8vw")}

        {/* </View> */}
      </View>
    )
  }
  renderInputForStock = (list, index) => {
    var { outBoundPopupList, typedIndex } = this.state
    var stockDetails = outBoundPopupList[typedIndex].stock_details
    return (
        <View>
            {list && list.is_selected ?
                <TextInput
                    value={stockDetails[index].actual_stock}
                    editable = {true}
                    style={{ fontSize: '0.8vw', backgroundColor: 'EFEFEF', width: '100%', height: '1.5vw', margin: '0.3vw', borderColor: color.themeDark, borderWidth: 1, borderRadius: '0.3vw', paddingLeft: "0.5vw", color: 'black' }}
                    onChangeText={(text) => {
                        // var isValid = this.numberValidation(text)
                        var text = parseInt(text)
                        // if ((text) <= list.quantity) {
                          stockDetails[index]["actual_stock"] = text
                          outBoundPopupList[typedIndex].stock_details = stockDetails

                        // }
                        this.setState({ outBoundPopupList },() => {
                          
                          for ( let i=0 ; i<outBoundPopupList.length ; i++ ) {
                            var stock_details = outBoundPopupList[i].stock_details
                            for(let i = 0; i< stock_details.length; i++){
                              var total = 0 
                              var total_amount = 0
                              if (stock_details[i].actual_stock && stock_details[i].actual_stock != null ) {
                                total = total + stock_details[i].actual_stock
                                total_amount = total * stock_details[i].mrp_per_quantity
  
                              }
                            }
                          }
                          outBoundPopupList[typedIndex]["selected_qty"] = total
                          outBoundPopupList[typedIndex]["total_amount"] = total_amount
                          this.setState({
                            outBoundPopupList
                          })

                        })
                    }}
                /> : null}
        </View>
    )
}
onCheckClick = (id, selectedIndex, list) => {
  let { popupBatchNo, typedIndex, outBoundPopupList } = this.state;
  var stockDetails = outBoundPopupList[typedIndex].stock_details ?  outBoundPopupList[typedIndex].stock_details : []
  if (id === stockDetails[selectedIndex]["stock_list_id"]) {
     stockDetails[selectedIndex]["is_selected"] = !stockDetails[selectedIndex]["is_selected"]
     outBoundPopupList[typedIndex].stock_details = stockDetails
  }
  
  this.setState({ 
      outBoundPopupList,
      stockDetails
   }, () => {
      var batch_no = []
      var exp_on = []
      if(stockDetails[selectedIndex]["is_selected"]) {
        outBoundPopupList[typedIndex].batch_no.push(list.batch_no)
        outBoundPopupList[typedIndex].exp_on.push(list.expiry_date)
          this.setState({
              outBoundPopupList,
              popupBatchNo
          })
      } else {

          batch_no = outBoundPopupList[typedIndex].batch_no  
          exp_on =  outBoundPopupList[typedIndex].exp_on

          stockDetails = outBoundPopupList[typedIndex].stock_details
                 
          var Index = batch_no.findIndex((element) => element === list.batch_no )
          var ind = exp_on.findIndex((element) => element === list.exp_on )

          var trans_qty = outBoundPopupList[typedIndex].trans_qty
          trans_qty = trans_qty - outBoundPopupList[typedIndex]["selected_quantity"]
          exp_on.splice(ind, 1)
          batch_no.splice(Index, 1)
          // stockDetails.splice(Index, 1)
          outBoundPopupList[typedIndex]["batch_no"] = batch_no
          outBoundPopupList[typedIndex]['trans_qty'] = trans_qty
          outBoundPopupList[typedIndex]["selected_quantity"] = 0
      }
      this.setState({ 
        outBoundPopupList,
        stockDetails
       })
   })
}
  batchNoPopUp = (stock_details) => {
    return (
        <View style = {{ zIndex: 10 }}>
                <View style={[styles.BatchSelectPopUP, { }]}>
                    <View style={styles.BatchSelectPopUPWrapper}>
                        <Text style={{ fontSize: '0.7vw', width: '5%', textAlign: 'center' }}></Text>
                        <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center' }}>Batch No</Text>
                        <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center', marginLeft: '0.2vw' }}>Avail.Qty</Text>
                        <TouchableOpacity style={{ width: '30%', display: 'flex', flexDirection: 'row-reverse' }} onPress={() => { this.setState({ isOnFocus: false }) }}>
                            <Icon name="window-close-o" size={'0.97vw'} color={color.themeDark} />
                        </TouchableOpacity>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                    {(stock_details) ?
                      stock_details.length > 0 ?
                        (stock_details).map((list, index1) => (
                                <View style={styles.BatchScrollView}>
                                    <Checkbox
                                        checked = {list.is_selected}
                                        value={list.is_selected}
                                        onChange={() => { this.onCheckClick(list.stock_list_id, index1, list) }} 
                                        />
                                    <Text style={{ fontSize: '0.8vw', width: '30%', textAlign: 'center' }}>{list.batch_no}</Text>
                                    <Text style={{ width: '30%', fontSize: '0.8vw', textAlign: 'center' }}>{list.quantity}</Text>
                                    <View style= {{ width: "30%" }}>{this.renderInputForStock(list, index1)}</View>

                                </View>))
                        : <Text style={styles.NoListBatch}>No Stock Available</Text>
                    : null
                }
            </ScrollView>

                </View> 

        </View>
    )

}
  renderTableData = () => {
    var { current_page, records_per_page, stockDetails, outBoundPopupList, typedIndex } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ height: "15vw" }}>
        {this.state.outBoundPopupList && this.state.outBoundPopupList.length > 0 ?
          this.state.outBoundPopupList && this.state.outBoundPopupList.map((item, index) => {
            let sno = (current_page - 1) * records_per_page + index + 1;
            let zIndex = (index + 1) * -10;
            var stock_details = item.stock_details ? item.stock_details : []            
          
            var batch_no = []
            var exp_on = []
            var total = 0
            var quantity = 0
            for ( let i =0 ; i < stock_details.length ; i++ ) {
            //  if(stock_details[i].isSelected) {
              total = total + (stock_details[i].quantity * stock_details[i].mrp_per_quantity)
              batch_no.push(stock_details[i].batch_no)
              exp_on.push(stock_details[i].expiry_date)
              quantity = quantity + item.request_quantity
              // stockDetails.forEach( data => data.actual_stock = 0 )
              // outBoundPopupList[typedIndex].stock_details = stockDetails
            //  }
            }
            return (
              <View style={[styles.dataContainer, { marginLeft: "0.2vw", zIndex: zIndex }]}>
                <View style={{ flex: 0.05 }}><Text style={styles.TableDataText}>{sno}</Text></View>
                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.drug_name}</Text></View>
                <View style={{ flex: 0.15 }}>
                 <Tooltip placement="topLeft" title={item.generic_name ? item.generic_name : ""}>
                    <Text style={styles.TableDataText}>{item.generic_name.length > 14 ? item.generic_name.slice(0, 14) + ".." : item.generic_name}</Text>
                 </Tooltip>
                </View>  
                <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.dosage_type + " / " + item.drug_strength}</Text></View>
                <View style={{ flex: 0.13 }}>
                  <TextInput
                    value={batch_no}
                    onFocus = { () => {  
                      this.setState({
                        isOnFocus: true,
                        stockDetails: item.stock_details,
                        typedIndex: index
                      },() => {
                        this.state.stockDetails.forEach(data => { data.isSelected = false, data.SelectedBatches = null, data.actual_stock  = null});
                      })
                    }}
                    onBlur = { () => {
                      this.setState({
                        // isOnFocus: false,
                        // stockDetails: []
                      })
                    }}
                    onChangeText={(text) => { 
                      this.setState({
                         popupBatchNo: text,
                      })}}
                    style={[styles.textBox, {width: "95%", height: "4vh"}]}
                  />
                    { stockDetails.length > 0 && this.state.typedIndex === index && this.state.isOnFocus ? 
                        <View style={{ zIndex: 10 }}>{this.batchNoPopUp(this.state.outBoundPopupList[typedIndex].stock_details)}</View> : null
                    }
                  <Text style={styles.TableDataText}>{(item.total)}</Text>
                </View>
                <View style={{ flex: 0.1 }}> 
                <TextInput
                    value={exp_on }
                    onChangeText={(text) => { 
                      
                    }}
                    style={[styles.textBox, {width: "95%", height: "4vh"}]}
                /></View>
               <View style={{ flex: 0.1 }}> 
                  <TextInput
                    value={ outBoundPopupList[index].selected_qty}
                    onChangeText={(text) => { 
                      outBoundPopupList[index].selected_qty
                      this.setState({
                        outBoundPopupList
                      })
                    }}
                    style={[styles.textBox, {width: "95%", height: "4vh"}]}
                  /></View>
                <View style={{ flex: 0.1 }}>
                <TextInput
                    value={outBoundPopupList[index].request_quantity}
                    onChangeText={(text) => { 
                      outBoundPopupList[index].request_quantity = text
                      this.setState({
                        outBoundPopupList
                      })
                    }}
                    style={[styles.textBox, {width: "50%", height: "4vh"}]}
                />
                </View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{total? parseFloat(total).toFixed(2) : 0}</Text></View>

              </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>}
        
      </ScrollView>
    )
  }
  renderBottomView = () => {
    return (
      <View style={{ justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "row", marginTop: "0.5vw" }}>
        <TouchableOpacity style={styles.addButton} onPress={() => {
           this.updateStockDetails()
        }}>
          <Text style={[styles.addText]}>Transfer Stock</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.addButton, { marginLeft: "0.5vw" }]} onPress={() => {
          this.setState({
            isUpdateClick: false
          })
        }}>
          <Text style={styles.addText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    )
  }
  render() {
    console.log(this.state.outBoundPopupList,"stockDetails")
    return (
      <View style={styles.container}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"outBound"}
          isNavigationBorder={true}
        />
        <View style={styles.mainContainer}>
          {this.renderHeaderView()}
          {this.renderTableDataView()}
          {this.renderPaginationView()}
        </View>
        {this.state.isUpdateClick ?
          <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "75%", height: "85%", borderRadius: 8, borderWidth: 1, }}>
              <View>
                {this.renderHeaderViews()}
                <View style={{ height: "45vh", }}>
                  {this.renderPopupHeader()}
                  {this.renderTableData()}
                </View>
                {this.renderBottomView()}
              </View>
            </View>
          </View> : null}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: { height: "81vh" },
  drugHeaderContainer: {
    flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center", height: '2vh'
  },
  subContainer: {
    flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: "0.4vw", borderRadius: 4, marginTop: '0.5vw'
  },
  mainContainer: { margin: "0.5vw", zIndex: 0 },
  addText: {
    color: color.white,
    fontSize: "0.8vw",
    marginLeft: "0.4vw"
  },
  addButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.4vw",
    justifyContent: "center",
    alignItems: "center"
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingVertical: "0.6vw",
    justifyContent: 'center',
    flex: 1,
    justifyContent: "flex-start",
    alignContent: "center"
  },
  TableDataText: {
    fontSize: '1vw'
  },
  PrintIconImg: {
    height: '1.2vw',
    width: '1.2vw',
    alignSelf: "center"
  },
  BatchSelectPopUP: {
    borderRadius: 10,
    width: '18vw',
    justifyContent: 'center',
    alignContent: 'center',
    padding: "0.65vw",
    position: 'absolute',
    backgroundColor: "white",
    maxHeight: '15vw',
    borderColor: 'black',
    borderWidth: 1,
},
BatchSelectPopUPWrapper: {
    display: 'flex', flexDirection: 'row', backgroundColor: '#E1F7F4', height: '1.5vw', borderRadius: '0.3vw', alignItems: 'center'
},
BatchScrollView: {
    width: '100%', display: 'flex', flexDirection: 'row', height: '2vw', borderBottomWidth: 1, borderBottomColor: '#E2E2E2', borderRadius: '0.3vw', alignItems: 'center',
},
  suggestionMain: { padding: "0.5vw", position: "absolute", width: "13vw", marginTop: "3vw", minHeight: "25vh", zIndex: 12, backgroundColor: color.white, borderWidth: 1, borderColor: color.lightGray, marginTop: "2.3vw", borderRadius: "0.3vw" },
  HeaderText: { position: 'absolute', marginLeft: "0.5vw", backgroundColor: "white", fontSize: "0.65vw", paddingHorizontal: "0.5vw", color: "#888888", marginTop: '-0.5vw'  },
  textBox: {borderColor: color.lightGray, borderWidth: 1, borderRadius: "0.3vw" , height: "2vw", padding: "0.3vw"},
  searchBoxText: { position: 'absolute', backgroundColor: color.aliceBlue, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize:"0.7vw", marginTop: "-0.5vw" }
})
