import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { NavigationTopHeader } from "../../BaseComponent";
import { color } from "../../../../styles/Color";
import { Tabs } from "antd";
import Biothesiometry from "./Biothesiometry";
import BrachialIndex from "./BrachialIndex";

export class FootClinicHome extends Component {
  constructor(props) {
    super(props);
  }

  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  render() {
    return <View>{this.changeTabSection("brachialIndex")}</View>;
  }
}

export default FootClinicHome;

const styles = StyleSheet.create({
  TabView: {
    marginLeft: "1vw",
  },
});
