import React, { Component } from 'react'
import { TouchableOpacity, Text, View, StyleSheet, ScrollView } from 'react-native';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import moment from "moment";
import { color } from "../../../../styles/Color";


export default class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Historydata: [],
      patientAppointment: this.props.patientAppointment,
      ListOfGeneralHistory: [],
      ListOfDBHistory: [],
      listOfNutritionHistory: [],
      listOfBgHistory: [],
      ListOfOBGYNHistory: [],
      ListOfPSYHistory: [],
      ListOfPDHistory: {},
      ListOfOPTHistory: {},
      OtherHistotyCount: 0
    }
  }

  componentDidMount() {
    this.GetHistoryData()
  }
  GetHistoryData = () => {
    var serviceUrl = Constants.COLLAB_REPORT_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDataSuccess,
      this.getDataFailure
    );
  }

  getDataSuccess = (success) => {
    this.setState({
      Historydata: success.data
    }, () => {
      if (this.state.Historydata.general_history && this.state.Historydata.general_history.length > 0) {
        this.setState({
          ListOfGeneralHistory: this.state.Historydata.general_history
        })
      }
      if (this.state.Historydata.diabetic_history && Object.keys(this.state.Historydata.diabetic_history).length > 0) {
        this.renderDBHistoryData(this.state.Historydata.diabetic_history)
      }
      if (this.state.Historydata.nutrition_history && Object.keys(this.state.Historydata.nutrition_history).length > 0) {
        this.renderNutritionHistoryData(this.state.Historydata.nutrition_history)
      }
      if (this.state.Historydata.bg_history && Object.keys(this.state.Historydata.bg_history).length > 0) {
        this.renderBGHistoryData(this.state.Historydata.bg_history)
      }
      if (this.state.Historydata.obgyn_history && this.state.Historydata.obgyn_history.length > 0) {
        this.setState({
          ListOfOBGYNHistory: this.state.Historydata.obgyn_history
        })
      }
      if (this.state.Historydata.psy_history && this.state.Historydata.psy_history.length > 0) {
        this.setState({
          ListOfPSYHistory: this.state.Historydata.psy_history
        })
      }
      if (this.state.Historydata.pediatric_history) {
        this.setState({
          ListOfPDHistory: this.state.Historydata.pediatric_history
        })
      }
      if (this.state.Historydata.opt_history) {
        this.setState({
          ListOfOPTHistory: this.state.Historydata.opt_history
        })
      }

    })
  }
  getDataFailure = (error) => {
    console.log(error.message)
  }

  removeEmptyObject(obj) {
    if (obj.length > 0) {
      var data = []
      for (let i = 0; i < obj.length; i++) {
        if (Object.keys(obj[i]).length > 0) {
          data.push(obj[i])
        }
      }
      return data
    }
  }

  renderDBHistoryData = (DBdata) => {
    var data = DBdata

    var booleanValues = []
    var stringVaues = []

    var hideEditIcon = true

    Object.keys(data).forEach((key) => {

      if (data[key]) {
        hideEditIcon = false
      }


      if (typeof data[key] == "boolean") {
        let prepareData = {}
        if (key == "new_diabetes_diagnosed") {
          prepareData = {
            key: "New to diagnosis?",
            value: data[key] ? "Yes" : "No",
          }
        } else if (key == "blood_glucose_check") {
          prepareData = {
            key: "Do you check your blood glucose?",
            value: data[key] ? "Yes" : "No",
            details: data.bg_check_frequency
          }
        } else if (key == "low_blood_glucose") {
          prepareData = {
            key: "Do you get low blood glucose?",
            value: data[key] ? "Yes" : "No",
            details: data.low_bg_date_time
          }
        }

        booleanValues.push(prepareData)
      } else if (key !== "low_bg_date_time" || key !== "bg_check_frequency") {
        let prepareData = {}
        var year = ""
        if (data[key] == 1) {
          year = " year"
        } else {
          year = " years"
        }
        if (key == "diabetes_duration") {

          prepareData = {
            key: "How many years have you had diabetes?",
            value: data[key] ? data[key] + year : ''
          }
        } else if (key == "diabetes_family_history") {
          prepareData = {
            key: "Who in your family has/had diabetes?",
            value: data[key],
          }
        } else if (key == "diabetes_type") {
          var type = ""
          if (data[key] == 1)
            type = "Type 1";
          else if (data[key] == 2)
            type = "Type 2";
          else if (data[key] == 0)
            type = "Not Sure";

          prepareData = {
            key: "What type of diabetes do you have?",
            value: type,
          }
        } else if (key == "treatment_method") {
          prepareData = {
            key: "How is your diabetes treated?",
            value: data[key],
          }
        } else if (key == "bg_check_meter") {
          prepareData = {
            key: "Name of the blood glucose meter",
            value: data[key],
          }
        } else if (key == "bg_usual_result") {
          prepareData = {
            key: "Usual Result",
            value: data[key] ? data[key] + " mg/dl " : "",
          }
        } else if (key == "last_a1c_result") {
          prepareData = {
            key: "Last A1c Result, Date?",
            value: data[key] ? data[key] + " % " : "",
            details: data.last_ac1_date ? moment(data.last_ac1_date).format('DD-MM-YYYY') : null
          }
        }
        stringVaues.push(prepareData)
      }
    })



    var concatBothList = booleanValues.concat(stringVaues)

    var removeEmptyObj = this.removeEmptyObject(concatBothList)

    //  console.log("success _________> " + JSON.stringify(removeEmptyObj))
    this.setState({
      ListOfDBHistory: removeEmptyObj
    })

  }

  renderNutritionHistoryData = (NutritionData) => {
    var data = NutritionData

    var booleanValues = []
    var stringVaues = []
    var hideEditIcon = true
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        hideEditIcon = false
      }
      if (typeof data[key] == "boolean") {
        let prepareData = {}
        if (key == "nutritional_counselling") {
          prepareData = {
            key: "Have you ever had Nutritional counselling?",
            value: data[key] ? "Yes" : "No",
            details: data.nutrition_counselling_date
          }
        }
        // else if (key == "blood_glucose_check") {
        //     prepareData = {
        //         key: "Do you check your blood glucose?",
        //         value: data[key] ? "Yes" : "NO",
        //         details: data.bg_check_frequency
        //     }
        // } else if (key == "low_blood_glucose") {
        //     prepareData = {
        //         key: "Do you get low blood glucoes?",
        //         value: data[key] ? "Yes" : "NO",
        //         details: data.low_bg_date_time
        //     }
        // }

        booleanValues.push(prepareData)
      } else {
        let prepareData = {}
        var dataName = ""
        if (key == "nutrition_type") {
          if (data[key] == 1) {
            dataName = "Vegetarian"
          } else if (data[key] == 2) {
            dataName = "Vegan"
          } else if (data[key] == 3) {
            dataName = "Eggetarian"
          } else if (data[key] == 4) {
            dataName = "Non Vegetarian"
          } else if (data[key] == 5) {
            dataName = "Mixed"
          }
          prepareData = {
            key: "Type of Nutrition",
            value: dataName,
          }
        } else if (key == "food_early_morning_amount") {
          prepareData = {
            key: "Early Morning",
            value: data[key],
          }
        } else if (key == "food_breakfast_amount") {
          prepareData = {
            key: "Breakfast",
            value: data[key],
          }
        } else if (key == "food_mid_morning_amount") {
          prepareData = {
            key: "Mid Morning",
            value: data[key],
          }
        } else if (key == "food_lunch_amount") {
          prepareData = {
            key: "Lunch",
            value: data[key],
          }
        } else if (key == "food_evening_amount") {
          prepareData = {
            key: "Evening",
            value: data[key],
          }
        } else if (key == "food_dinner_amount") {
          prepareData = {
            key: "Dinner",
            value: data[key],
          }
        } else if (key == "food_late_night_amount") {
          prepareData = {
            key: "Late Night",
            value: data[key],
          }
        } else if (key == "water_consumption") {
          prepareData = {
            key: "Water Consumption",
            value: data[key] ? data[key] + " Litres" : "",
          }
        }
        stringVaues.push(prepareData)
      }
    })



    var concatBothList = booleanValues.concat(stringVaues)

    var removeEmptyObj = this.removeEmptyObject(concatBothList)

    //  console.log("success _________> " + JSON.stringify(removeEmptyObj))
    this.setState({
      listOfNutritionHistory: removeEmptyObj
    })

  }

  renderBGHistoryData = (BGHistoryData) => {
    var data = BGHistoryData

    var booleanValues = []
    var stringVaues = []
    var hideEditIcon = true
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        hideEditIcon = false
      }
      if (typeof data[key] == "boolean") {
        let prepareData = {}
        if (key == "bg_above_300") {
          prepareData = {
            key: "Any recent blood glucose above 300?",
            value: data[key] ? "Yes" : "No",
          }
        }
        else if (key == "bg_below_70") {
          prepareData = {
            key: "Any recent blood glucose below 80?",
            value: data[key] ? "Yes" : "No",
            // details: data.bg_check_frequency
          }
        }
        else if (key == "check_your_feet") {
          prepareData = {
            key: "Do you check your feet?",
            value: data[key] ? "Yes" : "No",
            details: data.how_freq
          }
        }
        else if (key == "vision_problem") {
          prepareData = {
            key: "Do you have any problem in your vision?",
            value: data[key] ? "Yes" : "No",
            details: data.vision_problem_desc
          }
        }

        booleanValues.push(prepareData)
      } else {
        let prepareData = {}
        if (key == "bg_check_frequency") {
          var times = ""
          if (data[key] == 1) {
            times = " time"
          } else {
            times = " times"
          }
          prepareData = {
            key: "How often do you test your blood glucose per month?",
            value: data[key] ? data[key] + times : "",
          }
        } else if (key == "high_blood_glucose") {
          prepareData = {
            key: "Have you had any of the following signs of High Blood glucose in the past 3 months?",
            value: data[key],
          }
        } else if (key == "low_blood_glucose") {
          prepareData = {
            key: "Have you had any of the following signs of Low Blood glucose in the past 3 months?",
            value: data[key],
          }
        }
        stringVaues.push(prepareData)
      }
    })



    var concatBothList = booleanValues.concat(stringVaues)

    var removeEmptyObj = this.removeEmptyObject(concatBothList)

    // console.log("success _________> " + JSON.stringify(removeEmptyObj))
    this.setState({
      listOfBgHistory: removeEmptyObj,
    })
  }

  checkAllHistoryValue = () => {
    if (
      this.state.ListOfGeneralHistory.length == 0 &&
      this.state.ListOfDBHistory.length == 0 &&
      this.state.listOfNutritionHistory.length == 0 &&
      this.state.listOfBgHistory.length == 0 &&
      this.state.OtherHistotyCount == 0
    ) {
      return (
        <View style={styles.NoRecordsView}>
          <Text style={styles.NoRecordsText}>No Records to show...</Text>
        </View>
      )
    }
  }


  renderKeyValueRows = (object) => {
    try {
      const ConvertedArray = []
      Object.keys(object).forEach((key) => {
        if (key != "appointment_id") {
          ConvertedArray.push({
            "key": key.split('_').join(' '),
            "value": typeof (object[key]) == "boolean" ? object[key] ? "Yes" : 'No' :
              object[key] == "" || object[key] == null ? "-" :
                key == "created_date" ? object[key].split("T")[0] : object[key]
          })
        }
      })
      if (ConvertedArray.length > 0 && this.state.OtherHistotyCount == 0) {
        this.setState({
          OtherHistotyCount: 1
        })
      }
      return (
        ConvertedArray.map((list) => (
          <View style={styles.TableDataWrapper}>
            <View style={[styles.TableLeftCol, { paddingRight: '1vw' }]}>
              <Text style={styles.LeftText}>{list.key}</Text>
            </View>
            <View style={styles.TableRightCol}>
              <Text style={styles.RightText}>{list.value}</Text>
            </View>
          </View>
        ))
      )
    }
    catch (error) {
      console.log(error)
    }
  }
  renderSmallTableKeyValueRows = (object) => {
    try {
      const ConvertedArray = []
      Object.keys(object).forEach((key) => {
        if (key != "appointment_id") {
          ConvertedArray.push({
            "key": key.split('_').join(' '),
            "value": typeof (object[key]) == "boolean" ? object[key] ? "Yes" : 'No' :
              object[key] == "" || object[key] == null ? "-" :
                key == "created_date" ? object[key].split("T")[0] : object[key]
          })
        }
      })
      if (ConvertedArray.length > 0 && this.state.OtherHistotyCount == 0) {
        this.setState({
          OtherHistotyCount: 1
        })
      }
      return (
        ConvertedArray.map((list) => (
          <View style={[styles.TableDataWrapper,{width: '33vw',}]}>
            <View style={[styles.TableLeftCol, { paddingRight: '1vw' , width: '40%'}]}>
              <Text style={styles.LeftText}>{list.key}</Text>
            </View>
            <View style={[styles.TableRightCol, {width: '60%'}]}>
              <Text style={[styles.RightText, {width:'17vw'}]}>{list.value}</Text>
            </View>
          </View>
        ))
      )
    }
    catch (error) {
      console.log(error)
    }
  }
  render() {
    return (

      <View style={{ width: '92vw' }}>

        {this.checkAllHistoryValue()}
        <ScrollView horizontal showsHorizontalScrollIndicator={true}>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.ListOfGeneralHistory && this.state.ListOfGeneralHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>General History</Text>
                        </View>
                      </View>
                    </View>

                    <ScrollView style={{ height: '64vh' }} showsVerticalScrollIndicator={false}>
                      <View style={[styles.leftView]}>
                        {
                          this.state.ListOfGeneralHistory.map((list) => (
                            <View style={styles.TableContainer}>

                              <View style={{ width: "39vw" }}>
                                <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                                  <View style={{ alignItems: "center", width: '100%' }} >
                                    <Text style={[styles.TableHeadText]}>Appointment Date : {list.appointment_date}</Text>
                                  </View>
                                </View>
                              </View>

                              <ScrollView style={{ width: '40vw' }}>
                                {
                                  list.allergy && list.allergy.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>History of Allergies</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {list.allergy.map((item, index) => (
                                              <Text style={[{
                                                fontSize: '0.9vw',
                                                width: '100%',
                                                textAlign: 'left', paddingVertical: '0.3vw', paddingLeft: '2.5vw', display: 'flex', flexDirection: 'column'
                                              }]}>
                                                {` ${index + 1}. ${item.name} - ${item.allergy_type == 1 ? "Medication Allergy" : "Non-Medication Allergy"} - Diagnosed On : ${item.diagnosed_date},`}
                                              </Text>))}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}


                                {
                                  list.medical_history && list.medical_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Medical History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableLeftCol, { width: '35%' }]}>
                                            <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Problems</Text>
                                          </View>
                                          <View style={[styles.TableRightCol, { width: '65%' }]}>
                                            <Text style={[styles.RightText, { paddingVertical: '0.3vw', width: '21vw', }]}>{list.medical_history.map((item) => (`${item.name} ,`))}</Text>
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.social_history && list.social_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Social History</Text>
                                      </View>
                                      {
                                        list.social_history.map((list) => (
                                          <View style={styles.ColWrapper}>
                                            <View style={styles.TableDataWrapper}>
                                              <View style={[styles.TableLeftCol, { width: '35%' }]}>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Alcohol</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Smoking</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Drugs</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Daily activity</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Is married</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Is exercise done daily</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Occupation</Text>
                                              </View>
                                              <View style={[styles.TableRightCol, { width: '65%' }]}>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.alcohol}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.smoking}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.drugs}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.daily_activity}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.is_married ? "Yes" : "No"}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.is_exercise_done_daily ? "Yes" : "No"}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.occupation}</Text>
                                              </View>
                                            </View>
                                          </View>
                                        ))
                                      }
                                    </View> : null}

                                {
                                  list.family_history && list.family_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Family History</Text>
                                      </View>
                                      {
                                        list.family_history.map((list) => (
                                          <View style={styles.ColWrapper}>
                                            <View style={styles.TableDataWrapper}>
                                              <View style={[styles.TableLeftCol, { width: '35%' }]}>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Family Member</Text>
                                                <Text style={[styles.LeftText, { paddingVertical: '0.3vw', paddingLeft: '2vw' }]}>Disease Names</Text>
                                              </View>
                                              <View style={[styles.TableRightCol, { width: '65%' }]}>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw' }]}>{list.family_member}</Text>
                                                <Text style={[styles.RightText, { paddingVertical: '0.3vw', width: '21vw', }]}>{list.disease_names.map((item) => (` ${item} ,`))}</Text>
                                              </View>
                                            </View>
                                          </View>
                                        ))
                                      }
                                    </View> : null}

                              </ScrollView>

                            </View>
                          ))
                        }

                      </View>
                    </ScrollView>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.ListOfDBHistory && this.state.ListOfDBHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Diabetic History</Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.leftView}>
                      <View style={styles.TableContainer}>

                        {/* <View style={{ width: "39vw" }}>
                          <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '3vw' }]}>Title</Text>
                            </View>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '10vw' }]}>Values</Text>
                            </View>
                          </View>
                        </View> */}

                        <ScrollView style={styles.MainTable}>
                          <View style={styles.ColWrapper}>
                            {
                              this.state.ListOfDBHistory.map((list) => (
                                <View style={styles.TableDataWrapper}>
                                  <View style={styles.TableLeftCol}>
                                    <Text style={styles.LeftText}>{list.key}</Text>
                                  </View>
                                  <View style={styles.TableRightCol}>
                                    <Text style={styles.RightText}>{list.value != "" && list.value != null ? list.value : "-"}</Text>
                                  </View>
                                </View>
                              ))
                            }
                          </View>
                        </ScrollView>
                      </View>

                    </View>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.listOfNutritionHistory && this.state.listOfNutritionHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Nuritional History</Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.leftView}>
                      <View style={styles.TableContainer}>

                        {/* <View style={{ width: "39vw" }}>
                          <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '3vw' }]}>Title</Text>
                            </View>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '10vw' }]}>Values</Text>
                            </View>
                          </View>
                        </View> */}

                        <ScrollView style={styles.MainTable}>
                          <View style={styles.ColWrapper}>
                            {
                              this.state.listOfNutritionHistory.map((list) => (
                                <View style={styles.TableDataWrapper}>
                                  <View style={styles.TableLeftCol}>
                                    <Text style={styles.LeftText}>{list.key}</Text>
                                  </View>
                                  <View style={styles.TableRightCol}>
                                    <Text style={styles.RightText}>{list.value != "" && list.value != null ? list.value : "-"}</Text>
                                  </View>
                                </View>
                              ))
                            }
                          </View>
                        </ScrollView>
                      </View>

                    </View>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.listOfBgHistory && this.state.listOfBgHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Blood Glucose History</Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.leftView}>
                      <View style={styles.TableContainer}>

                        {/* <View style={{ width: "39vw" }}>
                          <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '3vw' }]}>Title</Text>
                            </View>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '10vw' }]}>Values</Text>
                            </View>
                          </View>
                        </View> */}

                        <ScrollView style={styles.MainTable}>
                          <View style={styles.ColWrapper}>
                            {
                              this.state.listOfBgHistory.map((list) => (
                                <View style={styles.TableDataWrapper}>
                                  <View style={[styles.TableLeftCol, { paddingRight: '1vw' }]}>
                                    <Text style={styles.LeftText}>{list.key}</Text>
                                  </View>
                                  <View style={styles.TableRightCol}>
                                    <Text style={styles.RightText}>{list.value != "" && list.value != null ? list.value : "-"}</Text>
                                  </View>
                                </View>
                              ))
                            }
                          </View>
                        </ScrollView>
                      </View>

                    </View>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.ListOfOBGYNHistory && this.state.ListOfOBGYNHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Obstetrics and Gynecology History</Text>
                        </View>
                      </View>
                    </View>

                    <ScrollView style={{ height: '64vh' }} showsVerticalScrollIndicator={false}>
                      <View style={[styles.leftView]}>
                        {
                          this.state.ListOfOBGYNHistory.map((list, index) => (
                            <View style={styles.TableContainer}>

                              {/* <View style={{ width: "39vw" }}>
                                <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                                  <View style={{ alignItems: "center", width: '100%' }} >
                                    <Text style={[styles.TableHeadText]}>Appointment Date : {list.appointment_date}</Text>
                                  </View>
                                </View>
                              </View> */}

                              <ScrollView style={{ width: '40vw' }}>
                                {
                                  list.obgyn_gynac_history && list.obgyn_gynac_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Gynaecological History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.obgyn_gynac_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.obgyn_history_notes && list.obgyn_history_notes.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Birth Control Methods</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.obgyn_history_notes[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}
                                {
                                  list.obgyn_mamogram_history && list.obgyn_mamogram_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Pap Smear/Mammogram History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.obgyn_mamogram_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}
                                {
                                  list.obgyn_menstrual_history && list.obgyn_menstrual_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Menstrual History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.obgyn_menstrual_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}
                                {
                                  list.obgyn_obstetrical_history && list.obgyn_obstetrical_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Obstetrical History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.obgyn_obstetrical_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}


                              </ScrollView>

                            </View>
                          ))
                        }

                      </View>
                    </ScrollView>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.ListOfPSYHistory && this.state.ListOfPSYHistory.length > 0 ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Psychiatric History</Text>
                        </View>
                      </View>
                    </View>

                    <ScrollView style={{ height: '64vh' }} showsVerticalScrollIndicator={false}>
                      <View style={[styles.leftView]}>
                        {
                          this.state.ListOfPSYHistory.map((list, index) => (
                            <View style={styles.TableContainer}>

                              {/* <View style={{ width: "39vw" }}>
                                <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                                  <View style={{ alignItems: "center", width: '100%' }} >
                                    <Text style={[styles.TableHeadText]}>Appointment Date : {list.appointment_date}</Text>
                                  </View>
                                </View>
                              </View> */}

                              <ScrollView style={{ width: '40vw' }}>
                                {
                                  list.psy_education_history && list.psy_education_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Educational History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_education_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.psy_family_history && list.psy_family_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Family History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_family_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.psy_marital_history && list.psy_marital_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Sexual & Marital History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_marital_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.psy_occupation_history && list.psy_occupation_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Occupation History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_occupation_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}

                                {
                                  list.psy_past_history && list.psy_past_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Past History</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_past_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}
                                {
                                  list.psy_premorbid_history && list.psy_premorbid_history.length > 0 ?
                                    <View>
                                      <View style={{ alignItems: "center", width: '100%' }} >
                                        <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Pre-Morbid Personality</Text>
                                      </View>
                                      <View style={styles.ColWrapper}>
                                        <View style={styles.TableDataWrapper}>
                                          <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                            {this.renderKeyValueRows(list.psy_premorbid_history[0])}
                                          </View>
                                        </View>
                                      </View>
                                    </View> : null}


                              </ScrollView>

                            </View>
                          ))
                        }

                      </View>
                    </ScrollView>


                  </ScrollView>
                </View>
                :
                null
            }
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.ListOfPDHistory.pd_birth_history &&
                this.state.ListOfPDHistory.pd_medical_history &&
                (Object.keys(this.state.ListOfPDHistory.pd_birth_history).length > 0 ||
                  Object.keys(this.state.ListOfPDHistory.pd_medical_history).length > 0) ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Pediatric History</Text>
                        </View>
                      </View>
                    </View>

                    <ScrollView style={{ height: '64vh' }} showsVerticalScrollIndicator={false}>
                      <View style={[styles.leftView]}>
                        {
                          <View style={styles.TableContainer}>
                            <ScrollView style={{ width: '40vw' }}>
                              {
                                this.state.ListOfPDHistory.pd_birth_history && Object.keys(this.state.ListOfPDHistory.pd_birth_history).length > 0 ?
                                  <View>
                                    <View style={{ alignItems: "center", width: '100%' }} >
                                      <Text style={[styles.TableHeadText, { fontSize: '1vw' }]}>Birth History</Text>
                                    </View>
                                    <View style={styles.ColWrapper}>
                                      <View style={styles.TableDataWrapper}>
                                        <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                          {this.renderKeyValueRows(this.state.ListOfPDHistory.pd_birth_history)}
                                        </View>
                                      </View>
                                    </View>
                                  </View> : null}

                              {
                                this.state.ListOfPDHistory.pd_medical_history && Object.keys(this.state.ListOfPDHistory.pd_medical_history).length > 0 ?
                                  <View>
                                    <View style={{ alignItems: "center", width: '100%' }} >
                                      <Text style={[styles.TableHeadText, { paddingVertical: '0.5vw', fontSize: '1vw' }]}>Medical History</Text>
                                    </View>
                                    <View style={styles.ColWrapper}>
                                      <View style={styles.TableDataWrapper}>
                                        <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                          {this.renderKeyValueRows(this.state.ListOfPDHistory.pd_medical_history)}
                                        </View>
                                      </View>
                                    </View>
                                  </View> : null}
                            </ScrollView>
                          </View>
                        }
                      </View>
                    </ScrollView>
                  </ScrollView>
                </View>
                :
                null
            }
          </View>


          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              (this.state.ListOfOPTHistory.ocular_history &&  this.state.ListOfOPTHistory.ocular_history.length > 0 )||
              (this.state.ListOfOPTHistory.surgery_history &&  this.state.ListOfOPTHistory.surgery_history.length > 0 ) ?

                <View style={{ width: '40vw', marginRight: '2vw' }}>
                  <ScrollView style={styles.mainContainer}>

                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Opthamology History</Text>
                        </View>
                      </View>
                    </View>

                    <ScrollView style={{ height: '64vh' }} showsVerticalScrollIndicator={false}>
                      <View style={[styles.leftView]}>
                        {
                          <View style={styles.TableContainer}>
                            <ScrollView style={{ width: '40vw' }}>
                              {
                                this.state.ListOfOPTHistory.ocular_history && this.state.ListOfOPTHistory.ocular_history.length > 0 ?
                                  <View>
                                    <View style={{ alignItems: "center", width: '100%' }} >
                                      <Text style={[styles.TableHeadText, { fontSize: '1vw' }]}>Ocular History</Text>
                                    </View>
                                    <View style={styles.ColWrapper}>
                                      <View style={styles.TableDataWrapper}>
                                        <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                          {
                                            this.state.ListOfOPTHistory.ocular_history && this.state.ListOfOPTHistory.ocular_history.length > 0 ?
                                              this.state.ListOfOPTHistory.ocular_history.map((list, index) => (
                                                <View style={styles.smalltable}>{this.renderSmallTableKeyValueRows(list)}</View>
                                              )) : null
                                          }
                                        </View>
                                      </View>
                                    </View>
                                  </View> : null}

                                  {
                                this.state.ListOfOPTHistory.surgery_history && this.state.ListOfOPTHistory.surgery_history.length > 0 ?
                                  <View>
                                    <View style={{ alignItems: "center", width: '100%' }} >
                                      <Text style={[styles.TableHeadText, { fontSize: '1vw' }]}>Surgery History</Text>
                                    </View>
                                    <View style={styles.ColWrapper}>
                                      <View style={styles.TableDataWrapper}>
                                        <View style={[styles.TableRightCol, { alignItems: 'flex-start', width: "100%", }]}>
                                          {
                                            this.state.ListOfOPTHistory.surgery_history && this.state.ListOfOPTHistory.surgery_history.length > 0 ?
                                              this.state.ListOfOPTHistory.surgery_history.map((list, index) => (
                                                <View style={styles.smalltable}>{this.renderSmallTableKeyValueRows(list)}</View>
                                              )) : null
                                          }
                                        </View>
                                      </View>
                                    </View>
                                  </View> : null}
                            </ScrollView>
                          </View>
                        }
                      </View>
                    </ScrollView>
                  </ScrollView>
                </View>
                :
                null
            }
          </View>


        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  LeftText: {
    fontSize: '0.9vw',
    fontWeight: '500'
  },
  RightText: {
    fontSize: '0.9vw',
    // paddingRight:'0.5vw',
    width: '13vw',
    textAlign: 'left'
  },
  mainContainer: {
    height: '70vh',
    borderWidth: 1,
    borderRadius: '0.2vw',
    marginRight: '2vw',
    display: 'flex',
  },
  leftView: {
    // width: '32vw',
    height: '58vh',
    marginLeft: '0.3vw'
  },
  TableContainer: {
    marginTop: '0.5vw',
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderTopLeftRadius: '0.26vw',
    borderTopRightRadius: '0.26vw',
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '37vw',
    borderRadius: 4,
    borderBottomWidth: 1,
    borderColor: color.sectionHeadingLiteColor,
    // height: "3vw",
    alignItems: "center",
    paddingTop: '1vw',
    paddingBottom: '1vw'
  },
  MainTable: {
    height: '64vh',
    width: '40vw'
  },
  TableLeftCol: {
    alignItems: "left", width: '60%', marginLeft: '1vw'
  },
  TableRightCol: {
    alignItems: "left", width: '40%',
  },
  ColWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw',
    width: '53vw'
  },
  NoRecordsText: {
    fontSize: '1vw',
    marginLeft: "40vw"
  },
  smalltable : {
    borderWidth : 1,
    padding :'1vw',
    width:'95%',
    margin:'1vw',
    borderRadius:'0.26vw'
  }
})
