import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import {DoctorNotesSectionHeading,CommonHistorySectionHeader} from '../BaseComponent'
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
// import Loader from 'react-loader-spinner'

const platform = Platform.OS;
const pageTitle = "Symptoms";
const pageName = "symptoms";
const isPageName = "isSymptoms";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      userType:this.props.userType,
      selectedPageName:this.props.selectedView,

      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      symptomsData: [],
      isSymptoms: isPageName===this.props.selectedView?true:false,
      paediatricSymptoms:{}
      // isLoading : true

    };
  }

  componentDidMount() {
    this.getSymptoms();
  }

  componentWillReceiveProps(props) {

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getSymptoms();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isSymptoms){

      this.setState({ isSymptoms: updateFontColor });
    }
  }

  getSymptoms = () => {
  
    var service_url = Constants.SYMPTOMS_GET+ '?appointment_id='+this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id;
  

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSymptomsSuccess,
      this.getSymptomsFailure
    );
  };

  getSymptomsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["symptomsData"] = response.data; 
      field["paediatricSymptoms"] = response.paediatric_symptoms; 
      // alert(JSON.stringify(response.data))     
      this.setState({ field
      // isLoading: false
    });
    }
  };

  getSymptomsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  
  renderSymptomsItem = (item, index) => {
    // [0.34, 0.17, 0.37, 0.12]
    return (
      
        <View style={{ flexDirection: 'row',alignItems:'center',paddingVertical:'0.5vw',borderBottomColor:color.sectionItemLine,borderBottomWidth:1 }}>
          <Text style={[Style.contentTableDataLead, { flex: 0.32 ,marginHorizontal: '1.2vw'}]}>{item.symptom}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.17 }]}>{item.duration != "100"? item.duration : "Chronic"}  {item.duration_mode === "1"? item.duration == "1"? "day": "days" : item.duration_mode === "2"? item.duration == "1"? "week":"weeks" : item.duration_mode === "3"?item.duration == "1"? "month":"months":item.duration_mode === "4" && item.duration !="100"? item.duration == "1"? "year":"years":""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.36 }]}>{item.symptom_desc}</Text>
          <View style={{ flex: 0.15,flexDirection: 'row',justifyContent:'space-between' }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
              {this.renderIdButton("pencil")}
            </TouchableOpacity>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
              {this.renderIdButton("trash")}
            </TouchableOpacity>
          </View>
        </View>
  // <View>
  //       <View style={{ paddingTop: 5 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             // height: 50,
  //             alignItems: "center"
  //             // paddingBottom: 10
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={ Style.contentTableDataLead }>
  //               {item.symptom}
  //             </Text>
  //           </View>

  //           <View
  //             style={{
  //               width: "20%"
  //               // alignItems: "center"
  //             }}
  //           >
  //             <Text style={ Style.contentTableData }>
  //               {item.duration}  days
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={ Style.contentTableData }>
  //               {item.symptom_desc}
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "20%",
  //               alignItems: "center",
  //               height: 60,
  //               flexDirection: "row"
  //             }}
  //           >
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 paddingTop: 5,
  //                 paddingBottom: 5,
  //                 backgroundColor: color.white,
  //                 height: 50
  //               }}
  //             >
  //               <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
  //                 {this.renderIdButton("pencil")}
  //               </TouchableOpacity>

  //               <TouchableOpacity  disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
  //                 {this.renderIdButton("trash")}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
        // <View style={ Style.sectionItemLine } /> 
  //      </View>
    );
  };

  // getComplaintsType (type) {

  //   let itemType = "";

  //   let temp = Constants.eye_values.map((item,index)=>{

  //     if(type === item.value) { itemType = item.label; }
  //     return (
  //       null
  //     )
  //   })
  //   return itemType;
  // }

  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);

    var data = item;
    data["presenting_complaints"]=this.state.paediatricSymptoms.presenting_complaints;
    data["presenting_complaints_history"]=this.state.paediatricSymptoms.presenting_complaints_history;

    setTimeout(() => {
      this.props.editItem(pageName, data);
    }, 500);
    

  }
  
  deleteItem = (deleteItem, event) => {
    



    //this.props.editItem(pageName, item);
    var service_url = Constants.SYMPTOMS_GP_DELETE ;
    let data = {

      "appointment_id": this.state.patientAppointment.appointment_id,
      "symptoms_id":deleteItem.id
    
    };
     
     

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  getDeleteSuccess = response => {
    if (response.status === "success") {
      
      this.getSymptoms();
    }
  };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  selectedRightSideView(name) {
    this.props.triggerNewItem(name);
    // this.props.selectedRightSideView(name);
    // setTimeout(() => {
    //   this.editHistory()
    // }, 100);
  }
  editHistory() {
    // this.props.selectedRightSideView(pageName);
    // setTimeout(() => {
    //   this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
    // }, 100);
  }

  render() {
    return (
        <View style={{}} >

        {/* <DoctorNotesSectionHeading
          selectedRightSideView={this.selectedRightSideView.bind(this)}
          editHistory={this.editHistory.bind(this)}
          selectedPageName={this.state.selectedPageName}
          pageName={isPageName} 
          heading={"Symptoms"}
          // editImage={"pencil"}
        /> */}

        {this.renderTitleBorder(this.state.isSymptoms, pageTitle, isPageName, this.triggerNewItem)}

        <View style={styles.TableHeadView1}>
        <CommonHistorySectionHeader
                    heading1={"Symptom's Name"}
                    heading2={"Duration"}
                    heading3={"Description"}
                    heading4={"Action"}
                    columnSize={[0.32, 0.17, 0.36, 0.15]}  //total value is == of 1
                    noOfColumn={4}
                    />
</View>

        {/* <View style={{}}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Symptom's Name</Text>
            </View>

            <View
              style={{
                width: "20%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Duration</Text>
            </View>
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Description</Text>
            </View>

          </View>
        </View> */}

        <FlatList
          data={this.state.symptomsData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderSymptomsItem(item, index)
          }
        />

          {
            this.state.userType=="PD" ?
          
            this.renderPaediatricSymptomsTextField()
            : null

          }
        

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }

  renderPaediatricSymptomsTextField(){
    var states= this.state;
    return(
      <View style={{flexDirection:'row',justifyContent:''}}>
        <View style={{flex:0.5}}>
          <View style={styles.PresendingCompView}>
            <Text style={styles.PresentingCompText}>{"Presenting Complaints"}</Text>
          </View>
          <TextInput style={styles.PresentingCompTextInput} 
          multiline={true}
          numberOfLines={10}
          value={states.paediatricSymptoms && states.paediatricSymptoms.presenting_complaints ? states.paediatricSymptoms.presenting_complaints :""}/>
        </View>
        <View style={{flex:0.5}}>
          <View style={styles.PresendingCompView}>
            <Text style={styles.TableHeadView1}>{"History of Presenting Complaints"}</Text>
          </View>
          <TextInput style={styles.PresentingCompTextInput} 
          multiline={true}
          numberOfLines={10}
          value={states.paediatricSymptoms && states.paediatricSymptoms.presenting_complaints_history ? states.paediatricSymptoms.presenting_complaints_history :""}/>

        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
      TableHeadView1: {
        marginTop:'0.6vw'
      },
      PresendingCompView: {
        height:'2.3vw',
        justifyContent:'center'
      },
      PresentingCompText: {
        marginLeft:'1.2vw',
        marginTop:'0.6vw'
      },
      PresentingCompTextInput: {
        width:"90%",
        marginTop:'0.6vw',
        paddingLeft:'0.6vw',
        paddingTop:'0.6vw' ,
        borderColor:'#888888',
        borderWidth:1,
        borderRadius:5,
        height:'5vw'
      }
})