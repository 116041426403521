//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";
import {
  HistoryYesOrNoSection,
  CommonButton,
  CommonAddButton,
  DoctorNotesCommonRightSideHeader
} from "./../../BaseComponent";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";

// create a component
const pageName = "isForWomen";

var birthControl = [
  // { label: "None", value: "None" },
  { label: "Condoms", value: "Condoms" },
  { label: "Birth control pills", value: "Birth control pills" },
  { label: "implants", value: "implants" },
  { label: "IUD", value: "IUD" },
  { label: "Vasectomy", value: "Vasectomy" },
  { label: "Timing", value: "Timing" },
  { label: "Diaphragm", value: "Diaphragm" },
  { label: "Patch", value: "Patch" },
  { label: "Depo Provera", value: "Depo Provera" },
  { label: "Tubal Ligation", value: "Tubal Ligation" },
];

var howManyChildrens = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
];

export class ForWomenNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // "patient_id": [],
      // "currently_pregnant": false,
      // "baby_weight_4kgs_more": false,
      // "gestational_diabetes": false,
      // "sexual_problems": false,
      // "menopause_reached": false,
      // "birth_control_method": "normal",
      // "number_of_kids": [],
      // selectedValue: true,
      // details: '',

      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,

      newToDiagnosis: false,
      newToDiagnosisDetails: "",

      currentlyPragent: false,
      currentlyPragentDetails: "",

      babyWeight: false,
      babyWeightDetails: "",

      gestationalDiabetes: false,
      gestationalDiabetesDetails: "",

      sexualProblems: false,
      sexualProblemsDetails: "",

      menopause: false,
      menopauseDetails: "",

      newButtonValue: "",

      birthControlList: birthControl,
      selectedbirthControl: [],
      showbirthControlNewButtonFlag: true,

      howManyChildrens: howManyChildrens,
      showSelectedChildrenNewButtonFlag: true,
      selectedChildren: {},
    };
  }

  componentWillReceiveProps(props) {
    if (
      props &&
      props.editHistoryItem &&
      props.editHistoryItem.pageName == pageName
    ) {
      if (
        props &&
        props.editHistoryItem &&
        props.editHistoryItem.editItem &&
        Object.keys(props.editHistoryItem.editItem).length > 0
      ) {

        var data = props.editHistoryItem.editItem;
     //   console.log("data(((((((((((" + JSON.stringify(data))
        var states = this.state;
        var { birthControlList ,howManyChildrens} = this.state;
        states["currentlyPragent"] = data.currently_pregnant;
        states["babyWeight"] = data.baby_weight_4kgs_more;
        states["gestationalDiabetes"] = data.gestational_diabetes;
        states["sexualProblems"] = data.sexual_problems;
        states["menopause"] = data.menopause_reached;
        // states["selectedbirthControl"] = data.birth_control_method ? { label: data.birth_control_method, value: data.birth_control_method } : null
        // states["selectedChildren"] = data.number_of_kids
        //   ? { label: data.number_of_kids, value: data.number_of_kids }
        //   : null;

        // if(this.state.selectedbirthControl.length >0){
        //     for (let i = 0; i < this.state.selectedbirthControl.length; i++) {
        //         if(this.state.selectedbirthControl[i].value == data.birth_control_method){

        //         }

        //     }
        // }

        var getBirthControl = data.birth_control_method.split(",");
        if (getBirthControl.length > 0) {
          let data = [];
          for (let i = 0; i < getBirthControl.length; i++) {

            var preparData = {
              label: getBirthControl[i],
              value: getBirthControl[i],
            };

            // alert(JSON.stringify(birthControlList))

            var getValueIsAvailable = birthControlList.includes(preparData)



            if (getValueIsAvailable) {
              data.push(preparData);
            } else {
              birthControlList.push(preparData)
              data.push(preparData);
            }


          }
          states["selectedbirthControl"] = data;
          // states["birthControlList"] = data;
        }
        birthControlList = this.removeDuplicate(birthControlList)

        //how many childrens

        var getchildren =data && data.number_of_kids ? data.number_of_kids :null;
        if(getchildren){
        for (let i = 0; i < howManyChildrens.length; i++) {
          // const element = array[i];
          if(howManyChildrens[i].value == getchildren){
            var prepareChildren={label:getchildren.toString(),value:getchildren.toString()}
            states["selectedChildren"] = prepareChildren;
          }else{
            var prepareChildren={label:getchildren.toString(),value:getchildren.toString()}
            howManyChildrens.push(prepareChildren);
            states["selectedChildren"] = prepareChildren;
          }
          
        }
      }

        // if (getchildren.length > 0) {
        //   let data = [];
        //   for (let i = 0; i < getchildren.length; i++) {

        //     var preparData = {
        //       label: getchildren[i],
        //       value: getchildren[i],
        //     };

        //     // alert(JSON.stringify(birthControlList))

        //     var getValueIsAvailable = getchildren.includes(preparData)



        //     if (getValueIsAvailable) {
        //       data.push(preparData);
        //     } else {
        //       howManyChildrens.push(preparData)
        //       data.push(preparData);
        //     }


        //   }
        //   states["selectedChildren"] = data;
        //   // states["birthControlList"] = data;
        // }
        howManyChildrens = this.removeDuplicate(howManyChildrens)
        this.setState({ birthControlList, states ,howManyChildrens}, () => {
          this.props.editHistory({}, pageName);
        });
      }
    }
  }

  // comparePropsValueIsThere(value){

  // }

  onChangeYesOrNoValue(flag, value, key) {
    var states = this.state;
    if (key == "currentlyPragentDetails") {
      states["currentlyPragent"] = flag;
      states[key] = value;
    } else if (key == "babyWeightDetails") {
      states["babyWeight"] = flag;
      states[key] = value;
    } else if (key == "gestationalDiabetesDetails") {
      states["gestationalDiabetes"] = flag;
      states[key] = value;
    } else if (key == "sexualProblemsDetails") {
      states["sexualProblems"] = flag;
      states[key] = value;
    } else if (key == "menopauseDetails") {
      states["menopause"] = flag;
      states[key] = value;
    }
    this.setState({ states });
  }

  onPressButton(key, selectedValue) {
    var states = this.state;
    if (key == "showbirthControlNewButtonFlag") {
      states[key] = false;
      states["showFamilyDiabeticListNewButtonFlag"] = true;
    } else if (key == "showFamilyDiabeticListNewButtonFlag") {
      states[key] = false;
      states["showbirthControlNewButtonFlag"] = true;
    } else if (key == "showSelectedChildrenNewButtonFlag") {
      states[key] = false;
      states["showbirthControlNewButtonFlag"] = true;
    } else if (key == "selectedbirthControl") {
      var { selectedbirthControl } = this.state;

      var getSelectedValue;
      var spliceData;

      if (selectedbirthControl.length > 0) {
        for (var i = 0; i < selectedbirthControl.length; i++) {
          if (selectedbirthControl[i].value == selectedValue.value) {
            spliceData = selectedbirthControl[i];
            selectedbirthControl.splice(i, 1);
            var data = this.removeDuplicate(selectedbirthControl);
            this.setState({ selectedbirthControl: data });
            return;
          } else {
            if (spliceData && spliceData.value !== selectedValue.value) {
            } else {
              getSelectedValue = selectedValue;
            }
          }
        }

        if (getSelectedValue) {
          selectedbirthControl.push(selectedValue);
        }
      } else {
        selectedbirthControl.push(selectedValue);
      }

      var data = this.removeDuplicate(selectedbirthControl);
      this.setState({ selectedbirthControl: data });
    } else {
      states[key] = selectedValue;
    }

    this.setState({ states });
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onSubmitToAddValue() {
    var { birthControlList, howManyChildrens } = this.state;
    var states = this.state;
    var preparData = {};
    // if (!this.state.showDiabeticYearsNewButtonFlag) {
    //     preparData = { label: this.state.newButtonValue + " year", value: this.state.newButtonValue + " year" }
    // } else {
    preparData = {
      label: this.state.newButtonValue,
      value: this.state.newButtonValue,
    };
    // }

    if (!this.state.showbirthControlNewButtonFlag) {
      birthControlList.push(preparData);
      birthControlList = this.removeDuplicate(birthControlList);
      states["newButtonValue"] = "";
      states["showbirthControlNewButtonFlag"] = true;
      states["showSelectedChildrenNewButtonFlag"] = true;
      // states["selectedbirthControl"] = preparData;
    }

    if (!this.state.showSelectedChildrenNewButtonFlag) {
      howManyChildrens.push(preparData);
      howManyChildrens = this.removeDuplicate(howManyChildrens);
      states["newButtonValue"] = "";
      states["showSelectedChildrenNewButtonFlag"] = true;
      states["showbirthControlNewButtonFlag"] = true;
      states["selectedChildren"] = preparData;
    }

    this.setState({
      birthControlList,
      states,
    });
  }

  renderLabelWithButtonGroup(
    question,
    key,
    selectedValue,
    newButtonKey,
    newbuttonFlag,
    setOfData
  ) {
    var data = this.removeDuplicate(setOfData)
    return (
      <View
        style={[
          Style.historyYesOrNoSectionShadow,
          Style.allButtonBorderRadius,
          { marginTop: 10, paddingLeft: 20 },
        ]}
      >
        <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>
          {question}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 15 }}>
          {data.map((item, index) => {
            if(item.value)
            {return (
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                <CommonButton
                  item={item}
                  selectedvalue={selectedValue}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={key}
                />
              </View>
            );}
          })}

          <View style={{marginBottom: 10}}>
            {newbuttonFlag ? (
              <CommonButton
                item={{ label: "New", value: "New" }}
                selectedvalue={{}}
                butttonText={"New"}
                buttonType={"outlineNonTheme"}
                buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                rightIcon={true}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={newButtonKey}
              />
            ) : (
                <TextInput
                  style={[
                    {
                      width: 80,
                      height: 30,
                      borderColor: color.black,
                      borderWidth: 1,
                      backgroundColor: color.themeShadeBackground,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                  onChangeText={(text) => {
                    this.setState({ newButtonValue: text });
                  }}
                  onSubmitEditing={() => {
                    this.onSubmitToAddValue();
                  }}
                />
              )}
          </View>
        </View>
      </View>
    );
  }

  onPressAddButton() {
    // console.log(
    //   "patientAppointment --- > " +
    //   JSON.stringify(this.state.patientAppointment)
    // );
    var states = this.state;
    var getData = [];
    if (states.selectedbirthControl.length > 0) {
      for (let i = 0; i < states.selectedbirthControl.length; i++) {
        getData.push(states.selectedbirthControl[i].value)

      }
    }

    var data = {
      patient_id: this.state.patientAppointment.patient_id,
      currently_pregnant: states.currentlyPragent,
      baby_weight_4kgs_more: states.babyWeight,
      gestational_diabetes: states.gestationalDiabetes,
      sexual_problems: states.sexualProblems,
      menopause_reached: states.menopause,
      birth_control_method: getData.toString(),
      number_of_kids: states.selectedChildren ? states.selectedChildren.value : 0,
    };
    var service_url = Constants.FOR_WOMEN_POST;

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.forWomenSaveSuccess,
      this.forWomenSaveFailure
    );
  }
  forWomenSaveSuccess = (success) => {
    // alert(JSON.stringify(success));
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message)
      this.props.refreshRighSideComponent(pageName)
    } else {
      this.props.showResposeValue("error", success.message)
    }
    this.clearAllData()
  };
  forWomenSaveFailure = (error) => {
    alert(JSON.stringify(error));
    this.props.showResposeValue("error", error.message)
  };


  clearAllData() {

    


    var states = this.state;
    states["currentlyPragent"] = false
    states["babyWeight"] = false
    states["gestationalDiabetes"] = false
    states["sexualProblems"] = false
    states["menopause"] = false
    states["selectedbirthControl"] = []
    states["selectedChildren"] = {}
    this.setState({
        states
    })
}

  render() {
    return (
      <View>
        <DoctorNotesCommonRightSideHeader title={"For Women"} clearAllData={this.clearAllData.bind(this)} />

        <View style={styles.container}>
          <HistoryYesOrNoSection
            selectedValue={this.state.currentlyPragent}
            label={" Are you currently pregnant?"}
            detailsOfYesValue={this.state.currentlyPragentDetails}
            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
            sectionKey={"currentlyPragentDetails"}
            showDetailsText={false}
          />
        </View>
        <View style={styles.container}>
          <HistoryYesOrNoSection
            selectedValue={this.state.babyWeight}
            label={"Have you ever had a baby who weighed more than 4 kgs?"}
            detailsOfYesValue={this.state.babyWeightDetails}
            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
            sectionKey={"babyWeightDetails"}
            showDetailsText={false}
          />
        </View>

        <View style={styles.container}>
          <HistoryYesOrNoSection
            selectedValue={this.state.gestationalDiabetes}
            label={" Have you ever had gestational diabetes in the past?"}
            detailsOfYesValue={this.state.gestationalDiabetesDetails}
            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
            sectionKey={"gestationalDiabetesDetails"}
            showDetailsText={false}
          />
        </View>
        <View style={styles.container}>
          <HistoryYesOrNoSection
            selectedValue={this.state.sexualProblems}
            label={" Do you experience sexual problems?"}
            detailsOfYesValue={this.state.sexualProblemsDetails}
            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
            sectionKey={"sexualProblemsDetails"}
            showDetailsText={false}
          />
        </View>
        <View style={styles.container}>
          <HistoryYesOrNoSection
            selectedValue={this.state.menopause}
            label={" Have you reached menopause?"}
            detailsOfYesValue={this.state.menopauseDetails}
            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
            sectionKey={"menopauseDetails"}
            showDetailsText={false}
          />
        </View>

        {this.renderLabelWithButtonGroup(
          "Birth Control Method",
          "selectedbirthControl",
          this.state.selectedbirthControl,
          "showbirthControlNewButtonFlag",
          this.state.showbirthControlNewButtonFlag,
          this.state.birthControlList
        )}

        {this.renderLabelWithButtonGroup(
          " How many children do you have?",
          "selectedChildren",
          this.state.selectedChildren,
          "showSelectedChildrenNewButtonFlag",
          this.state.showSelectedChildrenNewButtonFlag,
          this.state.howManyChildrens
        )}
        <View>
          <CommonAddButton
            onPressAddButton={this.onPressAddButton.bind(this)}
          />
        </View>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    marginBottom: 10,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  add: {
    alignSelf: "center",
    marginTop: 20,
  },
});
