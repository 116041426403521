import React, { Component } from "react";
import { View, Text } from "react-native";
import { CommonButton } from "../../../../BaseComponent";
import IolStockList from "./IolStockList";
import AddNewIOL from "./AddNewIOL";
import NewIolRequest from "./NewIolRequest";
import IolPoList from "./IolPoList";
import IolAddPo from "./IolAddPo";
import IolDirectPO from "./IolAddDirectPo";

export default class IOLIndex extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            selectedpage: "iolStockList"
        }
    }

    changePage = (data, poNumber = null) => {
        this.setState({
            selectedpage: data,
            poNumber: poNumber
        })
    }

    showResposeValue = (title, message) => {
        this.props.showResposeValue(title, message)
    }

    render(){
        return(
            <View style={{height: "100%"}}>
            {this.state.selectedpage === "iolStockList" ? 
             <IolStockList 
             changePage = {this.changePage} 
             />  
             : this.state.selectedpage === "addNewIOL" ?
             <AddNewIOL 
             changePage = {this.changePage} 
             showResposeValue={this.showResposeValue.bind(this)}
             />
             : this.state.selectedpage === "newIolRequest" ? 
             <NewIolRequest 
             changePage = {this.changePage} 
             showResposeValue={this.showResposeValue.bind(this)}
             />
             : this.state.selectedpage === "iolPoList" ? 
             <IolPoList 
             changePage = {this.changePage} 
             showResposeValue={this.showResposeValue.bind(this)}
             />
             : this.state.selectedpage === "IolAddPo" ? 
             <IolAddPo 
             changePage = {this.changePage} 
             showResposeValue={this.showResposeValue.bind(this)}
             />
             : this.state.selectedpage === "addIolDirectPO" ? 
             <IolDirectPO 
             poNumber = {this.state.poNumber}
             changePage = {this.changePage} 
             showResposeValue={this.showResposeValue.bind(this)}
             />
             : null
            }
            </View>
        )
    }
}
