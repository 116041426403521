import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const platform = Platform.OS;
const pageTitle = "Intraocular Pressure";
const pageName = "iop";
const isPageName = "isIOP";

export default class IOP extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,

      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      comments: "",
      isComplaint: isPageName === this.props.selectedView ? true : false,

      itemList:[],
      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[]
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getCommonComments();
      this.getComplaints();
    }, 3000);
  
    
  }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  

    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      this.getCommonComments()
      // refresh false
      // this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isComplaint) {

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {

    var service_url = Constants.OPTHALMOLOGY_IOP + "?appointment_id=" + this.state.patientAppointment.appointment_id;
setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getComplaintsSuccess,
    this.getComplaintsFailure
  );
}, 3000);
  
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["itemList"] = response.data.ophthalmology_iop ? response.data.ophthalmology_iop : [];
      this.setState({ field });

      var data =  response.data.ophthalmology_iop?response.data.ophthalmology_iop:[];
      var availableEye=[]
      var availableEyeId=[]

      for(var i=0; i<data.length; i++){
          availableEye.push(data[i].eye_type);
          var prepareData={
            "eye_type":data[i].eye_type,
            "id":data[i].id
          }
          availableEyeId.push(prepareData);
      }

      this.setState({
        getAvailableEyeTypes:availableEye,
        getAvailableEyeTypesId:availableEyeId
      })
      // this.getCommonComments()
    }
  };

  getComplaintsFailure = error => {
  //  console.log("opthamology complaints GET error response");
    console.log(error);
  };


  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item, "", this.state.comments,this.state.itemList);
  }


  getCommonComments = () => {

    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=" + "Iop"+
    "&patient_id="+this.state.patientAppointment.patient_id  ;
setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getCommentSuccess,
    this.getComentsFailure
  );
}, 3000);
    
  };

  getCommentSuccess = response => {
    if (response.status === "success") {

      // console.log(">>>>>"+JSON.stringify(response.data.comments))

      if (response.data) {
        var data = response.data.comments;
        this.setState({ comments: data });

        // var states = this.state;
        // states['comments'] = data;

      //  alert(this.state.comments)
        // alert(JSON.stringify(response.data))
        /*
        var temp = JSON.parse(JSON.stringify(states)); 
        this.setState({ temp })
        */
        //this.setState({ comments: JSON.stringify(data) });

      }

    }
  };

  getComentsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderItem = (item, index) => {
    return (
      <View>

        <View
          style={[styles.ItemView, {width: platform === "web" ? "100%" : "75%"}]}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <View style={{ flex: 1, minWidth: '8.5vw' }}>
              <Text style={{ fontSize: '1vw', color: color.notesTableTitle }}>
                {item.eye_type === "RE" ? `Right Eye (${item.eye_type})` : null}
                {item.eye_type === "LE" ? `Left Eye (${item.eye_type})` : null}
                {item.eye_type === "BE" ? `Both Eyes (${item.eye_type})` : null}
                {item.eye_type === "NA" ? `Not Applicable (${item.eye_type})` : null}
              </Text>
            </View>
            <View style={{ flex: 2, marginLeft: '1.2vw' }}>
              <TouchableOpacity   disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                {this.renderIdButton("pencil")}
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={[styles.ItemTextView, {width: platform === "web" ? "100%" : "75%"}]}
          >

            {this.renderText('NCT', item.NCT)}
            {this.renderText('Applanation Tonometry', item.APPLANATION_TONOMETRY)}
            {this.renderText('CCT (Pachymetry)', item.CCT)}
            {/* {this.renderText('Correction Factor', item.CORRECTION_FACTOR)} */}
            {this.renderText('CIOP', item.CIOP)}
            {/* {this.renderText('Comments', this.state.comments)} */}
            <View>

              <Text
                style={[styles.CommentText, {color: color.notesTableTitle}]}
              >
                {"Comments"}
              </Text>
              <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: 2 }}>
                {this.state.comments || "-"}
                </Text>
            </View>
          </View>
        </View>

        {/* <View style={{ height: 0.5, backgroundColor: "#E0E0E0" }} /> */}
      </View>
    );
  };

  triggerNewItem = (key) => {

    this.props.triggerNewItem(key);
    // this.editComplaints()
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)
  }

  render() {

    let { itemList } = this.state;
    let itemCount = itemList ? itemList.length : 0;

    if (!itemCount) { itemCount = 0; }

    return (
      <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

        {itemCount > 0 ?
          <FlatList
            data={this.state.itemList}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            renderItem={({ item, index }) =>
              this.renderItem(item, index)
            }
          /> :
          // <View style={{ marginTop: 20 }}>
          // <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          //   {itemCount === 0 ? this.renderIconBtn("plus", "New", false, isPageName) : null}
          //   </TouchableOpacity>
          // </View>
          null
        }

      </View>
    );
  }
}
const styles = StyleSheet.create({
      ItemView: {
        marginLeft: '0.3vw',
        paddingTop: '0.6vw',
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
      },
      ItemTextView: {
        marginLeft: '0.3vw',
        paddingTop: '1.2vw',
        flexDirection: "row",
        justifyContent: "flex-start",
        // height: 50,
        alignItems: "center"
        // paddingBottom: 10
      },
      CommentText:{
        fontSize: '0.9vw',
        paddingLeft: 0,
        paddingRight: '2vw',
        marginRight: '1vw' //isIndividual ? 30 : 0
      }
})