import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  CheckBox,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import Icon from "react-native-vector-icons/FontAwesome";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import moment from "moment";
// // import GmailInput from "react-native-gmailtype-textinput";
// import SideMenu from "../../SideMenu";
import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageTitle = "Vision Assessment";
const pageName = "visionassessment";
const isPageName = "isVisionAssessment";

export default class VisionAssessment extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      itemList: [],
      isComplaint: isPageName===this.props.selectedView?true:false,

      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],

      copyRightToLeft: false,
      copyLeftToRight: false,

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null
    };

    this._saveLeftEyeData = this._saveLeftEyeData.bind(this)
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    },()=>{
      setTimeout(() => {
        this.getItemList();
      }, 2000);
    })
  }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getItemList();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getItemList = () => {

    let { userType, patientAppointment, selectedDoctorId } = this.state;
    let service_url = Constants.OPTHALMOLOGY_VISIONASSESSMENT + "?appointment_id=" + patientAppointment.appointment_id;

    if( userType == Constants.ROLE_OPTOMETRY ) {

      service_url = service_url + "&doctor_id=" + selectedDoctorId
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }, 2000);
  }

  getComplaintsSuccess = response => {
    
    if (response.status === "success") {
      
      let { getAvailableEyeTypes } = this.state;

      var availableEyeId=[]
      let itemList = [];
      let tempList = response.data && response.data.vision_assessment?response.data.vision_assessment:[];
      
      // data format for getting RE as first and LE as second in list
      if( tempList.length == 2 ) 
      {  
        if(tempList[0].eye_type=="RE") 
        {
          itemList = tempList
        } else {
          itemList.push(tempList[1]) 
          itemList.push(tempList[0])
        }
      } else if(tempList.length == 1){
        itemList.push(tempList[0])
      }
      
      for (let i = 0; i < itemList.length; i++) 
      {
        getAvailableEyeTypes.push(itemList[i].eye_type);
        // Id'd added
        var prepareData={
          "eye_type":itemList[i].eye_type,
          "id":itemList[i].id
        }
        availableEyeId.push(prepareData);
      }

      this.setState({ 
        itemList: itemList,
        getAvailableEyeTypes: getAvailableEyeTypes, 
        getAvailableEyeTypesId: availableEyeId
       });

    }
  };

  getComplaintsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, eye_type, event) => {

    let { itemList } = this.state

    let tempItemList = itemList.map((item)=>{
      item['selected'] = item.eye_type === eye_type?true:false;
      return item;
    })

    this.props.triggerNewItem(isPageName)
    this.props.editItem(pageName, tempItemList);

    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes, this.state.getAvailableEyeTypesId)
  }
  
  renderItem = (item, index) => {
    
    return (
      <View>
        
          <View
            style={[styles.RenderItemMainView, {width: platform === "web" ? "100%" : "75%",}]}
          >
            <View
              style={styles.RenderItemWrapper}
            >
              <View style={styles.renderEyeType}>
                <Text style={styles.renderEyeTypeText}>
                  {item.eye_type==="RE"?`Right Eye (${item.eye_type})`:null}
                  {item.eye_type==="LE"?`Left Eye (${item.eye_type})`:null}
                  {item.eye_type==="BE"?`Both Eyes (${item.eye_type})`:null}
                  {item.eye_type==="NA"?`Not Applicable (${item.eye_type})`:null}
                </Text>
              </View>
              <View style={styles.pencilButtonView}>
                <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item, item.eye_type)}>
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
              </View>
            </View>
            <View
              style={[{
                width: platform === "web" ? "100%" : "75%",
              },styles.RenderTextView]}
            >
              
              {this.renderText('UCVA', item.UCVA || "-")}
              {this.renderText('PH', item.PH || "-")}
              {this.renderText('PG/CL', item.PGCL || "-")}
              {this.renderText('NV', item.NV || "-")}
              {this.renderText('CV', item.CV || "-")}
              
            </View>
            
            {/* <View
              style={{
                width: "20%",
                alignItems: "center",
                height: 60,
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50
                }}
              >
                <TouchableOpacity onPress={this.editComplaints.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View> */}
          
        </View>
        {/* <View style={{ height: 0.5, backgroundColor: "#E0E0E0", marginTop: 15 }} /> */}
      </View>
    );
  };

  triggerNewItem= (key) => {
    
    console.log(" trigger getAvailableEyeTypesId " + JSON.stringify(this.state.getAvailableEyeTypesId)) 

    this.props.triggerNewItem(key);
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes, this.state.getAvailableEyeTypesId)
  }


  render() {

    let { itemList } = this.state;
    let itemCount = itemList?itemList.length:0;
    
    if(!itemCount){ itemCount = 0; }

    return (
        <View>
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}  
            {/* {itemCount>0 && (itemList && itemList[0].eye_type=='RE')?this.renderCheckBox("Copy right to left", this.state.copyRightToLeft, "copyRightToLeft"):null} 
            {itemCount>0 && (itemList && itemList[0].eye_type=='LE')?this.renderCheckBox("Copy left to right", this.state.copyLeftToRight, "copyLeftToRight"):null} */}
          </View>

        <FlatList
          data={this.state.itemList}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderItem(item, index)
          }
          numColumns={2}

        />
        
      </View>
    );
  }

  renderCheckBox(label, value, key) {
    return (
        <View style={styles.RenderCheckBoxView}>
            <CheckBox   disabled={this.state.isLocked} value={value}
                onValueChange={() => {
                    this.copyCheckBoxValues(key)
                }}
                disabled={this.state.isLocked} 
            />
            <Text style={styles.RenderCheckBoxText}>{label}</Text>
        </View>
    );
  }

  copyCheckBoxValues(key){

    var states = this.state;
    
    if(key == "copyRightToLeft"){
      states["copyRightToLeft"] = !this.state.copyRightToLeft;
      states["copyLeftToRight"] = false;
    }else{
      states["copyRightToLeft"] = false;
      states["copyLeftToRight"] = !this.state.copyLeftToRight;
    }
    
    this.setState({states},()=>{

      let {itemList}= this.state
      let leftEye = {};
      let rightEye = {};

      if(this.state.copyRightToLeft){

        for (let i = 0; i < itemList.length; i++) {
          
          if(itemList[i].eye_type == "RE"){
            
            for (let j = 0; j < itemList.length; j++) {
              
              if(itemList[j].eye_type == "LE"){
                
                leftEye = itemList[j];
                rightEye = itemList[i];

                leftEye["UCVA"]= rightEye.UCVA;
                leftEye["PH"]= rightEye.PH;
                leftEye["PGCL"]= rightEye.PGCL;
                leftEye["NV"]= rightEye.NV;
                leftEye["CV"]= rightEye.CV;

                // assign
                itemList[j] = leftEye;
              }
            }
          } 
        }
        
        if(Object.keys(leftEye).length==0 && itemList.length==1) {

          leftEye["eye_type"]= "LE";
          leftEye["UCVA"]= itemList[0].UCVA;
          leftEye["PH"]= itemList[0].PH;
          leftEye["PGCL"]= itemList[0].PGCL;
          leftEye["NV"]= itemList[0].NV;
          leftEye["CV"]= itemList[0].CV;

          // assign
          itemList.push(leftEye);
        }

        // save call
        this._saveLeftEyeData(itemList)

      } else if (this.state.copyLeftToRight) {

        for (let i = 0; i < itemList.length; i++) {
          
          if(itemList[i].eye_type == "LE"){
            
            for (let j = 0; j < itemList.length; j++) {
              
              if(itemList[j].eye_type == "RE"){
                
                // target
                rightEye = itemList[j];
                // origin
                leftEye = itemList[i];

                rightEye["UCVA"]= leftEye.UCVA;
                rightEye["PH"]= leftEye.PH;
                rightEye["PGCL"]= leftEye.PGCL;
                rightEye["NV"]= leftEye.NV;
                rightEye["CV"]= leftEye.CV;

                // assign
                itemList[j] = rightEye;
              }
            }
          } 
        }
        
        if(Object.keys(rightEye).length==0 && itemList.length==1) {

          rightEye["eye_type"]= "RE";
          rightEye["UCVA"]= itemList[0].UCVA;
          rightEye["PH"]= itemList[0].PH;
          rightEye["PGCL"]= itemList[0].PGCL;
          rightEye["NV"]= itemList[0].NV;
          rightEye["CV"]= itemList[0].CV;

          // assign
          itemList.push(rightEye);
        }

        //save call
        this._saveLeftEyeData(itemList)
      }      

      /*
        // set
        this.setState({
          itemList
        }, ()=>{

          this._saveLeftEyeData.bind(this)
        })
        */

    })
  }

  _saveLeftEyeData (itemList) {

    // let states = this.state;
    // let { itemList } = this.state;

    let { copyRightToLeft, copyLeftToRight } = this.state;
    let saveItem = {};

    if(itemList.length == 2)
    {
      for ( let i=0; i<2; i++ ) {

        if(copyRightToLeft) {
          if( itemList[i].eye_type=="LE" ) {
            saveItem = itemList[i]
          }
        } else if(copyLeftToRight) {
          if( itemList[i].eye_type=="RE" ) {
            saveItem = itemList[i]
          }
        }
      }

      console.log(" itemList " + JSON.stringify(itemList))

      console.log(" save Item " + JSON.stringify(saveItem))

      console.log(" item count " + itemList.length )

      if( Object.keys(saveItem).length>0 )
      {

        var service_url = Constants.OPTHALMOLOGY_VISIONASSESSMENT;
      
        let data = {
          "appointment_id": this.state.patientAppointment.appointment_id,
          "eye_type": saveItem.eye_type,
          "UCVA": saveItem.UCVA,
          "PH": saveItem.PH,
          "PGCL": saveItem.PGCL,
          "NV": saveItem.NV,
          "CV": saveItem.CV
        };

        if (saveItem.id) {
          data["id"] = saveItem.id;
        }

        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.postSuccess,
          this.postFailure
        );
      }
    }
  }

  postSuccess = response => {


    if (response.status === "success") {
     
      this.props.showResposeValue("success", response.message)

      this.getItemList()

    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  postFailure = error => {
    console.log(error);
    this.props.showResposeValue("error", error.message)

  };
}

const styles = StyleSheet.create({
  RenderItemMainView:{
    marginLeft: '0.32vw',
    paddingTop: '0.65vw',
    paddingRight: '0.65vw',
    flexDirection: "column",
    justifyContent: "flex-start",
    // height: 50,
    alignItems: "flex-start"
    // paddingBottom: 10
  },
  RenderItemWrapper:{
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  renderEyeType:{
    flex: 1, 
    minWidth: '7.81vw' 
  },
  renderEyeTypeText:{
    fontSize: '1vw', 
    color: color.notesTableTitle
  },
  pencilButtonView:{
    flex: 2, 
    marginLeft: '1.3vw'
  },
  RenderTextView:{
    paddingTop: '1.3vw',
    flexDirection: "row",
    justifyContent: "flex-start",
    // height: 50,
    alignItems: "center"
    // paddingBottom: 10
  },
  RenderCheckBoxView:{
    flexDirection: 'row',
     marginRight: '1.95vw'
  },
  RenderCheckBoxText:{
    marginLeft: '0.65vw', 
    fontSize: '0.8vw', 
    color: "#888888"
  }


})