import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
var Messages = require('../../../../utils/InfoMessages')
const screenName = "M-CHAT-R TM"

export class MChatRtm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofMChatRtm: {},
            comments: "",
            totalScore: "",
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId
        }
    }

    componentDidMount() {
        this.getMChatRtm()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getMChatRtm()
        })
    }

    getMChatRtm() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL + 
        "?patient_id="+this.state.patientId+
        "&appointment_id="+this.state.appointmentId+
        "&rating_scale_name="+screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMChatRtmSuccess,
            this.getMChatRtmFailure
        );
    }

    getMChatRtmSuccess = (success) => {
        if (success.status == "success") {
            this.setState({
                listofMChatRtm: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score,
                getTotalScore:success.data.total_score,
            })
        }
    }
    getMChatRtmFailure = error => { }


    validateTotalScores() {
        var { listofMChatRtm } = this.state;
        var getListOfQuestions = listofMChatRtm.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            getTotalScore = Math.abs(getTotalScore)+Math.abs(getOptions[j].option_score)
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore: this.state.getTotalScore -  getTotalScore
        })
    }


    saveMChatRtm() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var data = {
            "id": this.state.listofMChatRtm.id ? this.state.listofMChatRtm.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofMChatRtm.rating_scale_id,
            "rating_scale_name": this.state.listofMChatRtm.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": this.state.listofMChatRtm.questions
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveMChatRtmSuccess,
            this.saveMChatRtmFailure
        );
    }else{
        this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
    }
    }

    saveMChatRtmSuccess = success => {
        if (success.status == "success") {
            this.getMChatRtm()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveMChatRtmFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(indexOfQuestion, indexOfOptions) {
        var { listofMChatRtm } = this.state;
        var getListOfOptions = listofMChatRtm.questions[indexOfQuestion].options;
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        listofMChatRtm.questions[indexOfQuestion]["options"] = prepareData
        this.setState({ listofMChatRtm }, () => {
            this.validateTotalScores()
        })

    }

    // renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
    //     return (
    //         <View key={indexOfOptions} >
    //             <TouchableOpacity
    //                 onPress={() => {
    //                     this.onselectOptions(indexOfQuestion, indexOfOptions)
    //                 }}
    //                 style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
    //                 <View style={{ flex: 0.05 }} >
    //                     <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={24} />
    //                 </View>
    //                 <View style={{ flex: 0.95 }} >
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    renderListOfDatas(questions, indexOfQuestion) {
        var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
        return (
            <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
                <View key={indexOfQuestion} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.8 }}>
                    <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{getQuestion}</Text>
                </View>
                <View style={{ flex: 0.2, flexDirection: 'row' }}>
                    <View style={{ flex: 0.5 ,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,0)
                        }}>
                            <CheckBoxIcon name={questions.options[0].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.5 ,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,1)
                        }}>
                            <CheckBoxIcon name={questions.options[1].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            </View>
        )
    }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                </View>
                </View>
        )
    }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',  borderBottomColor: "#888888", borderBottomWidth: 1,  paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 0.8 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                <View style={{ flex: 0.2, flexDirection: 'row' }}>
                    <View style={{ flex: 0.5,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Mostly"}</Text>
                    </View>
                    <View style={{ flex: 0.5 ,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Always"}</Text>
                    </View>
                </View>
            </View>
        )
    }

    renderFooterComments(){
        return(
            <View style={{justifyContent:'center',alignItems:'center'}}>
                <Text style={{fontWeight:'bold',fontSize:20,color:color.themeDark,marginVertical:15}}>
                {
                    this.state.totalScore >= 90 ?
                    "No problem" :
                    this.state.totalScore <= 89 && this.state.totalScore >= 60  ?
                    "Needs follow up" :
                    this.state.totalScore <= 59 && this.state.totalScore >= 1  ?
                    "High Risk"
                    : null

                }
                </Text>
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("M-CHART-R TM")}
                        {
                            this.state.listofMChatRtm && this.state.listofMChatRtm.questions && this.state.listofMChatRtm.questions.length > 0 ?
                                this.state.listofMChatRtm.questions.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        }

                        {this.renderFooterComments()}

                        <View style={{ backgroundColor: color.applicationBackgroundColor,height:60,justifyContent:'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveMChatRtm.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})