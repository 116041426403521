//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton ,HistoryYesOrNoSection} from '../../BaseComponent'
import { color } from '../../../../styles/Color';
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
var Messages = require('../../../../utils/InfoMessages')

// create a component
const pageName = "isBirthControlMethods"
class BirthControlMethodNew extends Component {

    constructor(props) {
        super(props);
        this.state = {

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            birth_control_id: "",


            detailsOfBirthControlInPast: [],
            depressionOfPregnancy: "",
            previousInfertilityTherapy: "",
            depressionOfPregnancyFlag:false
        }
    }


    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }

    componentWillReceiveProps(props) {


        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {

                var states = this.state;
                var data = props.editHistoryItem.editItem


                states["depressionOfPregnancy"] = this.checResponceIsFlag(data.is_depression_before_after_pregnancy)
                states["previousInfertilityTherapy"] = data.previous_infertility_therapy_desc;
                states["birth_control_id"] = data.id;

                var birthcontrolArray = data && data.past_birth_control_methods ? data.past_birth_control_methods.split(',') : ""

                var getHistory = []

                for (let i = 0; i < birthcontrolArray.length; i++) {
                    var data = { label: birthcontrolArray[i], value: birthcontrolArray[i] }
                    getHistory.push(data)

                }

                states["detailsOfBirthControlInPast"] = getHistory

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }

    }


    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onPressButton(key, selectedValue) {
        if (key == "detailsOfBirthControlInPast") {
            var { detailsOfBirthControlInPast } = this.state;

            var getSelectedValue;
            var spliceData;

            if (detailsOfBirthControlInPast.length > 0) {
                for (var i = 0; i < detailsOfBirthControlInPast.length; i++) {
                    if (detailsOfBirthControlInPast[i].value == selectedValue.value) {
                        spliceData = detailsOfBirthControlInPast[i];
                        detailsOfBirthControlInPast.splice(i, 1)
                        var data = this.removeDuplicate(detailsOfBirthControlInPast);
                        this.setState({ detailsOfBirthControlInPast: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { detailsOfBirthControlInPast.push(selectedValue); }
            } else {
                detailsOfBirthControlInPast.push(selectedValue)
            }

            var data = this.removeDuplicate(detailsOfBirthControlInPast);
            this.setState({ detailsOfBirthControlInPast: data })


        } else if (key == "depressionOfPregnancy") {
            this.setState({ depressionOfPregnancy: selectedValue })
        }
    }

    checkMenstrualHistory(label, key, selectedValue) {
        var data = []

        if (key == "detailsOfBirthControlInPast") {
            data = [
                // { label: "None", value: "None" },
                { label: "Condoms", value: "Condoms" },
                { label: "Birth control pils", value: "Birth control pils   " },
                { label: "Implants", value: "Implants" },
                { label: "IUD", value: "IUD" },
                { label: "Vasectomy", value: "Vasectomy" },
                { label: "Timing", value: "Timing" },
                { label: "Diaphragm", value: "Diaphragm" },
                { label: "Patch", value: "Patch" },
                { label: "Depo Provera", value: "Depo Provera" },
                { label: "Tubal Ligation", value: "Tubal Ligation" },
                { label: "Ring", value: "Ring" },

            ]
        } else {
            data = [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ]
        }


        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    renderRichTextBox(key, values, label = "") {
        return (
            <View>
                {
                    label ?
                        <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text> :
                        <Text style={{ color: "#BCBCBC", fontSize: 12, fontWeight: '400' }}>{"Details if any"}</Text>
                }

                <TextInput
                    value={values}
                    placeholder={""}
                    multiline={true}
                    textAlignVertical="top"
                    maxLength={100}
                    style={{ marginTop: 10, marginBottom: 20, textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 65, shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
        
                    elevation: 5, }}
                    
                    onChangeText={(text) => {
                        var statets = this.state;
                        statets[key] = text
                        this.setState({ statets })
                    }}
                />
            </View>
        )
    }



    onPressAddButton() {
        var toStringData = []

        for (var i = 0; i < this.state.detailsOfBirthControlInPast.length; i++) {
            toStringData.push(this.state.detailsOfBirthControlInPast[i].value)
        }

        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "appointment_id": this.state.patientAppointment.appointment_id,
            "is_depression_before_after_pregnancy":this.state.depressionOfPregnancyFlag,
            "past_birth_control_methods": toStringData.toString(),
            "previous_infertility_therapy_desc": this.state.previousInfertilityTherapy
        }

        if (this.state.birth_control_id) {
            data["birth_control_id"] = this.state.birth_control_id
        } else {
            // data["birth_control_id"] = 34
        }

        // if (this.state.depressionOfPregnancyFlag) {
            var service_url = Constants.BIRTH_CONTROL
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.birthControlSaveSuccess,
                this.birthControlSaveFailure
            );
        // } else {
        //     this.props.showResposeValue('error', Messages.BirthControlMethodsEmptyField)
        // }
    }

    clearAllValues() {

        var states = this.state;
        states["detailsOfBirthControlInPast"] = [];
        states["depressionOfPregnancy"] = {};
        states["previousInfertilityTherapy"] = "";
        states["birth_control_id"] = "";

        this.setState({ states })
    }

    birthControlSaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    birthControlSaveFailure = error => { }


    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
       if (key == "depressionOfPregnancy") {
            states["depressionOfPregnancyFlag"] = flag;
            states[key] = value;
        } 
        this.setState({ states })
    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true} heading={"Birth Control Methods"} />
                {/* <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}> */}

                    {/* {this.checkMenstrualHistory("Did you experience depression before or after pregnancy?", "depressionOfPregnancy", this.state.depressionOfPregnancy)} */}
                   <View style={{marginHorizontal:9,marginTop:10}}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.depressionOfPregnancyFlag}
                        label={"Did you experience depression before or after pregnancy?"}
                        detailsOfYesValue={this.state.depressionOfPregnancy}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"depressionOfPregnancy"}
                        showDetailsText={false}
                        placeholder={"Description"}
                    />
                    </View>
                {/* </View> */}
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                    {this.checkMenstrualHistory("Have you used any birth control in the past?", "detailsOfBirthControlInPast", this.state.detailsOfBirthControlInPast)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                    {this.renderRichTextBox("previousInfertilityTherapy", this.state.previousInfertilityTherapy, "Have you used any Infertility Therapy previously?")}
                </View>

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});

//make this component available to the app
export default BirthControlMethodNew;
