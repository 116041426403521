import React, { Component } from "react";
import {
  View,
  Text,
  // Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  // TouchableWithoutFeedback
} from "react-native";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
// import SideMenu from "../../../SideMenu";
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import { Calendar } from 'react-native-calendars';
// import RNCalendarEvents from 'react-native-calendar-events';
// import DatePicker from 'react-native-datepicker'
import Pagination from '../../common/Pagination';
import {  CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton, NavigationTopHeader } from '../../BaseComponent'
import  CommonDateFilter  from '../../BaseComponent'


import AsyncStorage from "../../../../AsyncStorage";
// import TimerLog from "../../../TimerLog";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
// import ShowPatientBilling from './ShowPatientBilling'
import OpthamologyService from "../../../../network/OpthamologyService";
import Success from "react-native-vector-icons/FontAwesome5";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const navigationHeaderList = [
  { label: "Billing Transaction", value: "ipallTransactions" },
]

const navigationHeaderListIfIPCreateBill = [
  { label: "Create Bill", value: "ipPatientCreateBill" },
  { label: "Pending Bill", value: "ipPatientSavedBill" },
  { label: "Billing Transaction", value: "ipallTransactions" },
  { label: "OT Bills", value: "otbills" },
  // { label: "Summary Bill", value: "summaryBill" },
  { label: "Estimation Bill", value: "estimationBill" },
]


export default class IPPatientSavedBill extends Component {
  constructor(props) {
    super(props);

    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");

    this.state = {

      
      // currentTime: "",
      // filterData: {
      //   fromDate: "",
      //   toDate: "",
      //   patientName: "",
      //   billNo: "",
      //   amount: "",
      //   paymentType: ""
      // },
      // totalCashAmt: "",
      // totalCardAmt: "",
      // filterArray: [],
      // date: "2016-05-15",

      // getSavedBillingList: {},
      // appendUrl:Constants.DOCTOR_BILLING + "?clinic_id=" + 10,
      // appendUrl: "",
      // totalAmountInCash: 0,
      // totalAmountInCard: 0,
      // totalAmountCashAndCard: 0,

      // showCalenderModal: false,
      // showCalenderModalFrom: "",
      // filterFromDate: "",
      // filterToDate: "",

      // invoiceNo: "",
      // patientId: "",
      // patientDetails: {},
      // getAllBillingList: {},
      // showBillinListPopUpFlag: false,
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,

      // clinicDetails: this.props.selectedClinicOrDoctor,
      isCurrentPageIsIPPatientAppointment: this.props.isCurrentPageIsIPPatientAppointment,
      userType: this.props.userType,
      // responseType: "",
      // responseMeaasge: "",
      // isClear: false,

      allBillingTransactionList: [],
      billingListAfterPagination: [],
      // fromDate: converted_date,
      // toDate: converted_date,
      fromDate: "",
      toDate: "",
      patientName: "",
      billNumber: "",
      billAmount: "",
      paymentType: "",

      totalAmount: "",
      totalCashAmount: "",
      totalCardAmount: "",

      billingIPnumber: "",
      indudvalIPAdminID: "",
      refundAmount: "",
      invoiceID: "",
      filterDate: {},

      selectedBillType: "",
      
      isDelete: false,
      deletedIDs: "",
      isAdminAccess: false,

      FO_admin_setting :{}
    };
  }

 async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getSavedBillingList();

    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            FO_admin_setting : FO_admin_setting
        })

  }
  onPressSavedBillDelete = (key) => {
      
    let { appendUrl } = this.state;  
    appendUrl = Constants.IP_SAVED_BILL_DELETE + "?delete_id=" +this.state.deletedIDs 

      Service.getInstance().getDeleteList(
        appendUrl,
        this,
        this.deleteSavedBillSuccess,
        this.deleteSavedBillFailure
      );
    
  }

  deleteSavedBillSuccess = response => {
      if (response && response.status == "success") {
          this.setState({
              isDelete: false
          },()=>{
              this.getSavedBillingList()
          })
          this.props.showResposeValue("success", response.message)
      }
    }     
  deleteSavedBillFailure = error => {
      this.props.showResposeValue("error", error.message)

  }
  getPatientBillIndudvalBillingTransection = () => {


    var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + this.state.invoiceID
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPatientBillIndudvalBillingTransectionSuccess,
      this.getPatientBillIndudvalBillingTransectionFailure
    );
  };

  getPatientBillIndudvalBillingTransectionSuccess = response => {
    if (response.status === "success") {
     this.props.changeScreen("ipPatientCreateBill", {}, "", {}, "", {}, "", {}, this.state.invoiceID, response.data)

      // this.props.showBillingScreenPopup(true, response.data, this.state.invoiceID)

    }
  };

  getPatientBillIndudvalBillingTransectionFailure = error => { }





  renderAmountValueInCard(amount, bill = "", billingType) {
    return (
      <View style={{
        justifyContent: 'center',
        alignSelf: 'center',
        // alignItems: 'center',
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5,
        backgroundColor: color.white, height: 50,
        borderRadius: 4,
        paddingHorizontal: 15,
        minWidth: 100,
        marginRight: 20,
        marginTop:10,
        flexWrap:"wrap"
      }}>
        <Text style={{ fontSize: 14, fontWeight: '500' }}>{amount ? "₹ " : null}{amount}</Text>
        <Text style={{ fontSize: 12, fontWeight: '400', textAlign: 'right', marginTop: 10 }}>{billingType}</Text>

      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    states["filterDate"] = date
    states["fromDate"] = date.fromDate;
    states["toDate"] = date.toDate;
    this.setState({ states })

  }

  renderOverAllBillStatus() {
    var billingtypeTotal = ""
    var billingtypePaid = ""
    var billingtypeBalance = ""
    var billingTypeUPI = ""
    var billingTypeBank = ""
    if (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) {
      billingtypeTotal = "Total Bill"
      billingtypePaid = "Total Paid"
      billingtypeBalance = "Balance"



    } else {
      billingtypeTotal = "Total"
      billingtypePaid = "Cash"
      billingtypeBalance = "Card"
      billingTypeUPI = "UPI"
      billingTypeBank = "Bank Transfer"

    }
    return (
      <View>
        <Text style={{ fontSize: 16, fontWeight: '500', marginBottom: 15, marginTop: 20 }}>{"Overall Bill Status"}</Text>
        <View style={{ flexDirection: 'row',flexWrap:"wrap" }}>


          {this.renderAmountValueInCard(this.state.totalAmount, "totalAmount", billingtypeTotal)}
          
        </View>
      </View>
    )
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  renderTextBox(label, key, value) {
    return (
      <View>
        <Text style={{ marginBottom: 8 }}>{label}</Text>
        <TextInput
          onChangeText={(text) => {
            var states = this.state;

            if (key == "refundAmount") {
              if (this.numberValidation(text))
                states[key] = text;

            }
            else {
              states[key] = text;

            }
            this.setState({
              states
            })
          }}
          value={value} style={{ height: 35, width: 150, borderWidth: 0.1, borderColor: "#888888", borderRadius: 4, paddingLeft: 10 }} />
      </View>
    )
  }

  renderPaymentIcons(image, label, key) {
    return (
      <View style={{}}>
        <TouchableOpacity onPress={() => { this.setState({ paymentType: key }) }} style={{ alignItems: "center" }}>
          <Image source={image} style={{ height: 25, width: 25, marginBottom: 5, tintColor: this.state.paymentType == key ? color.themeDark : "#888888" }} />
          <Text style={{ color: this.state.paymentType == key ? color.themeDark : "black" }}>{label}</Text>
        </TouchableOpacity>
      </View>
    )
  }


  clearSearchData() {
    var states = this.state
    states["billAmount"] = ""
    states["billNumber"] = ""
    states["patientName"] = ""
    states["paymentType"] = ""
    states["fromDate"] = ""
    states["toDate"] = "",
    states["selectedBillType"] =""
      this.setState({
        states
      })
  }

  onPressButton(key, value) {
    if (key == "search") {
      this.getSavedBillingList()
    }
    else if (key == "clear") {
      this.clearSearchData()
    }
    else if (key == "print") {
      this.printBillingList()
    }
  }

  renderFooterAction() {
    var actions = []
    if (this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber) {
      actions = [
        { label: "Search", value: "search" },
        { label: "Clear", value: "clear" },
      ]
    } else {
      actions = [
        { label: "Search", value: "search" },
        { label: "Print", value: "print" },
        { label: "Clear", value: "clear" },
      ]
    }
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex:-1}}>
        {
          actions.map((item, index) => {
            return (
              <CommonButton
                // disable={this.state.isLocked}
                item={{}}
                selectedvalue={{}}
                butttonText={item.label}
                buttonType={item.value == "search" ? "theme" : "outlineTheme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={item.value} />
            )
          })
        }
      </View>
    )
  }

  render() {
    var listOfBillType = [
      { label: "Corporate", value: "Corporate" },
      { label: "Credit", value: "Credit" }
    ]
    return (

      <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
        <View style={{ width: "65%", height: screenHeight - 65, }}>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={

              this.props.isIpPatientCheckouted ?

              navigationHeaderList:

              this.state.isCurrentPageIsIPPatientAppointment ? 
              navigationHeaderListIfIPCreateBill : 
              navigationHeaderList}
            selectedTab={"ipPatientSavedBill"} />
          <ScrollView
            style={{ width: "100%", height: screenHeight - 65, }}
            bounces={false}
            bouncesZoom={false}
          >

            <View style={[Style.leftView, { width: "100%", backgroundColor: color.applicationBackgroundColor }]}>
              <View style={{ marginTop: 20, width: "100%", height: screenHeight-65, backgroundColor: color.white, paddingVertical: 20, paddingHorizontal: 10, marginHorizontal: 10 }}  >

              
              {this.state.billingIPnumber ?
                  <View >
                    <Text style={{ fontSize: 16, color: color.themeDark, margin: 13 }}>IP Number : {this.state.billingIPnumber}</Text>
                    <CommonHistorySectionHeader
                      heading1={"Date & Bill Number"}
                      heading2={"Patient Name"}
                      // heading3={"IP Number"}
                      heading3={"Amount"}
                      heading4={"Action"}

                      noOfColumn={4}
                      columnSize={[0.3, 0.3, 0.2, 0.2]}
                    />
                  </View>
                  :

                  this.state.isCurrentPageIsIPPatientAppointment ?
                    <CommonHistorySectionHeader
                      heading1={"Adminssion Date"}
                      heading2={"Ip Number"}
                      // heading3={"IP Number"}
                      heading3={"Amount"}
                      noOfColumn={3}
                      columnSize={[0.4, 0.4, 0.2]}
                    />
                    :
                    <CommonHistorySectionHeader
                      heading1={"Date & Bill Number"}
                      heading2={"Name"}
                      heading3={"IP Number"}
                      heading4={"Amount"}
                      noOfColumn={4}
                      columnSize={[0.3, 0.35, 0.32, 0.23]}
                    />
              }

                {
                  this.state.isCurrentPageIsIPPatientAppointment && this.state.allBillingTransactionList.length > 0 ?
                     this.showBillingList() :
                     <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                        <Text style={{ color: color.grayShade, marginTop: 50 }}>{"No records to be shown"}</Text>
                     </View>
                }

                {
                  this.state.allBillingTransactionList.length > 10 ?
                    <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.allBillingTransactionList} />
                    : null
                }
              </View>


            </View>

          </ScrollView>
        </View>



        <ScrollView showsVerticalScrollIndicator={false}
          style={{ width: "35%", height: screenHeight - 65, }} >
          <View style={[Style.rightView, { padding: 20, width: "100%"  }]}>
            {/* {this.renderOverAllBillStatus()} */}
            <Text style={[styles.textFontStyle]}>{"Filters"}</Text>
            {this.state.billingIPnumber ?
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: 200, justifyContent: "space-between" }}>
                <Text style={{ marginBottom: 8 }}>{"Date"}</Text>
                <CommonDateFilter   
                // removeObject={""}
                startDate={this.state.fromDate} 
                endDate={this.state.toDate} 
                filterDate={this.state.filterDate}
                defaultSelectedDateFilter={"all"} 
                showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
              </View>
              <View>
                <Text>Bill Type</Text>
                <View style={[styles.emed_content_container]}>
          {
            listOfBillType.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                  this.setState({
                    selectedBillType: item.value,
                  }, () => {
                    var { selectedBillType } = this.state;
                    var states = this.state;
                    if (selectedBillType == "Corporate") {
                      this.setState({
                        selectedBillType: "Corporate"
                      })
                    } else if (selectedBillType == "Credit") {
                      this.setState({
                        selectedBillType: "Credit"
                      })
                    }
                  })
                }} style={[styles.emed_radio_btn_touchablearea]} key={index}>
                  <View style={[{
                    backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                  }, styles.emed_radio_btn]}></View>
                  <Text style={[styles.emed_radio_btn_txt]}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
              </View>
            </View>:
             <View style={[Style.rightView],{flexDirection:"row",justifyContent:"space-around"}}>
                 {this.renderTextBox("IP Number", "billNumber", this.state.billNumber)}
                 {this.renderTextBox("Amount", "billAmount", this.state.billAmount)}
             </View>}
          
            {this.renderFooterAction()}
          </View> 


        </ScrollView>
        { this.state.isDelete ?
              <View style={{ justifyContent: "center", alignItems: "center", height: screenHeight, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
                           <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 20 }}>
                              <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to delete the Pending bill ?"}</Text>
                                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", height:"20%", flexWrap: "wrap-reverse" }}>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                                        onPress={() => { 

                                           this.onPressSavedBillDelete()
                                        }}>
                                        <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                                        onPress={() => { this.setState({ isDelete : false }) }}>
                                        <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>: null
                   }  

      </View>
    );
  }


  refundamountsave() {

    var data = {
      "patient_id": this.state.selectedIpPatientDetails.patient_id,
      "ip_admission_id": this.state.indudvalIPAdminID,
      "refund_amount": this.state.refundAmount
    }


    var service_url = Constants.REFUND_AMOUNT;

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.refundamountsaveSuccess,
      this.refundamountsaveFailure
    );

  }

  refundamountsaveSuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);
      this.setState({
        refundAmount: ""
      })

    } else {
      this.props.showResposeValue('error', success.message);
    }
  }
  refundamountsaveFailure = error => {
    this.props.showResposeValue('error', error.message);
  }

  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  printBillingList = () => {
    let states = this.state;
    if (this.state.billingIPnumber) {
      serviceUrl = Constants.IP_PATIENT_BILLING_TRANSECTION_LIST +
        "?admission_id=" + this.state.indudvalIPAdminID +
        "&export=PDF" +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + states.patientName +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        "&payment_type=" + states.paymentType +
        "&status=partial"
    } else if (this.state.billingIPnumber) {
      serviceUrl = Constants.IP_PATIENT_BILLING_TRANSECTION_LIST +
        "?admission_id=" + this.state.indudvalIPAdminID +
        "&export=PDF" +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + states.patientName +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        "&payment_type=" + states.paymentType +
        "&status=partial"
    } else {
      var serviceUrl = Constants.IP_ALLBILLING_TRANSACTION + "?export=PDF" +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + states.patientName +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        "&payment_type=" + states.paymentType +
        "&status=partial"
    }

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.successprintBillingList,
      this.failureprintBillingList,
      "pdf"
    );
  }

  successprintBillingList = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  failureprintBillingList = (error) => {

    console.log(error);
  }



  getSavedBillingList = async () => {
    let states = this.state;
    // IP_ADMISSION_ALLBILLING_TRANSACTION

    var serviceUrl = "";
    if (this.state.billingIPnumber) { //For showing appointment list
      serviceUrl = Constants.IP_PATIENT_BILLING_TRANSECTION_LIST +
        "?admission_id=" + this.state.indudvalIPAdminID +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + states.patientName +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        "&payment_type=" + states.paymentType+
        "&bill_type=" + states.selectedBillType+
        "&status=partial";
    }
    else if (this.state.isCurrentPageIsIPPatientAppointment) { // For clicking appointment list
      let patient_id = states.selectedIpPatientDetails.patient_id  ? states.selectedIpPatientDetails.patient_id : states.selectedIpPatientDetails.id
      let admit_id = states.selectedIpPatientDetails.id  ? states.selectedIpPatientDetails.id : states.selectedIpPatientDetails.admit_id

      serviceUrl = Constants.IP_PATIENT_ALLBILLING_TRANSACTION +
        "?patient_id=" + patient_id +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&ip_number=" + states.billNumber +
        "&amount=" + states.billAmount+
        "&admission_id=" + admit_id +
        "&status=partial";
    }




    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getBillingListSuccess,
      this.getBillingListFailure
    );
  }

  getBillingListSuccess = success => {
    if (success.status == "success") {
      if (this.state.billingIPnumber) {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_bill_amount ? success.data.total_bill_amount : 0,
          totalCashAmount: success.data.total_paid_amount ? success.data.total_paid_amount : 0,
          totalCardAmount: success.data.total_balance ? success.data.total_balance : 0,
          refundAmount: success.data.total_balance ? success.data.total_balance : 0,
          totalBankTransferAmount:success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount:success.data.upi ? success.data.upi : 0
        })
      }
      else if (this.state.isCurrentPageIsIPPatientAppointment) {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_bill_amount ? success.data.total_bill_amount : 0,
          totalCashAmount: success.data.total_paid_amount ? success.data.total_paid_amount : 0,
          totalCardAmount: success.data.total_balance ? success.data.total_balance : 0,
          totalBankTransferAmount:success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount:success.data.upi ? success.data.upi : 0
        })
      }
      else {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_amount ? success.data.total_amount : 0,
          totalCashAmount: success.data.cash_amount ? success.data.cash_amount : 0,
          totalCardAmount: success.data.card_amount ? success.data.card_amount : 0,
          totalBankTransferAmount:success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount:success.data.upi ? success.data.upi : 0
        })
      }
    }else{
      this.props.showResposeValue('error', success.message);
    }
    // console.log("suss getBillingListSuccess" + JSON.stringify(success))
  }
  getBillingListFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }

  showBillingList() {
    if (this.state.allBillingTransactionList && this.state.allBillingTransactionList.length > 0 && this.state.allBillingTransactionList.length < 11) {
      if(this.state.billingIPnumber||this.state.isCurrentPageIsIPPatientAppointment) {
        return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {
      
          if (this.state.billingIPnumber) {
  
            return this.showIPPatientIndudvalBills(bill, index);
          }
  
          else if (this.state.isCurrentPageIsIPPatientAppointment) {
  
            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }
  
        })
      }else{
      return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {
      
        if (this.state.billingIPnumber) {

          return this.showIPPatientIndudvalBills(bill, index);
        }

        else if (this.state.isCurrentPageIsIPPatientAppointment) {

          return this.showPatientBills(bill, index);
        } else {
          return this.showBills(bill, index);
        }

      })
     } 
    } 

   else if (this.state.allBillingTransactionList && this.state.allBillingTransactionList.length > 0) {
      if(this.state.billingIPnumber||this.state.isCurrentPageIsIPPatientAppointment && this.state.allBillingTransactionList.length > 0 ){
        return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {
      
          if (this.state.billingIPnumber) {
  
            return this.showIPPatientIndudvalBills(bill, index);
          }
  
          else if (this.state.isCurrentPageIsIPPatientAppointment) {
  
            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }
  
        })
       }else{
       return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {
      
        if (this.state.billingIPnumber) {

          return this.showIPPatientIndudvalBills(bill, index);
        }

        else if (this.state.isCurrentPageIsIPPatientAppointment) {

          return this.showPatientBills(bill, index);
        } else {
          return this.showBills(bill, index);
        }

       })
       }
     } else {
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
        <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
      </View>
    }
  }

  showBills(bill, index) {
    // alert("krish1")

    // console.log("******************************> "+JSON.stringify(bill))
    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({
            invoiceID: bill.billing_id
          }, () => {
            this.getPatientBillIndudvalBillingTransection()
          })
        }}
      >
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>

          <View style={{ flex: 0.3, marginHorizontal: 20 }}>
            <Text style={{ marginBottom: 5, fontSize: 15 }}>{bill.invoice_date}</Text>
            <Text style={{ fontSize: 15 }}>{"#" + bill.invoice_number}</Text>
          </View>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.35 }]}>{bill.patient_name}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.32 }]}>{bill.ip_number}</Text>
          <View style={{ flex: 0.23 }}>
            <Text style={{ marginBottom: 5, fontSize: 15 }}>{bill.amount}</Text>
            <Text style={{ fontSize: 15 }}>{bill.payment_type}</Text>
          </View>
        </View>
      </TouchableOpacity>

    );
  }

  showPatientBills(bill, index) {

    console.log('Check 5 ->', bill.ip_number )  
    return (
      <TouchableOpacity onPress={() => {
        this.setState({
          billingIPnumber: bill.ip_number,
          indudvalIPAdminID: bill.admission_id,
          allBillingTransactionList:[]

        }, () => {
          this.clearSearchData()
          this.getSavedBillingList()
        })
      }}>
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>


          <Text style={[Style.historyHeaderFontStyle, { flex: 0.4, marginHorizontal: 20 }]}>{bill.admission_date ? moment(bill.admission_date).format("DD-MM-YYYY") : null}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.4 }]}>{bill.ip_number}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.2 }]}>{bill.amount}</Text>
        </View>
      </TouchableOpacity>

    );
  }

  renderIdButton = image => {
    return (
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 20,
          shadowOpacity: 0.2,
          shadowRadius: 4,
          shadowOffset: {
            height: 2,
            width: 2
          },
          elevation: 4,
          backgroundColor: "#FCFEFF",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20
        }}
      >
        <Icon name={image} color={"#DD1717"} size={15} />
      </View>
    );
  };

  showIPPatientIndudvalBills(bill, index) {

    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({
            invoiceID: bill.billing_id
          }, () => {
            this.getPatientBillIndudvalBillingTransection()
          })
        }}
      >
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>

          <View style={{ flex: 0.3, marginHorizontal: 20 }}>
            <Text style={[Style.historyHeaderFontStyle, {}]}>{bill.invoice_date ? moment(bill.invoice_date).format("DD-MM-YYYY") : null}</Text>

            <Text style={[Style.historyHeaderFontStyle, { marginTop: 10 }]}>{bill.invoice_number}</Text>

          </View>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.3 }]}>{bill.patient_name}</Text>
          <View style={{ flex: 0.2 }}>
            <Text style={[Style.historyHeaderFontStyle]}>{bill.amount ? bill.amount : null}</Text>
            <Text style={[Style.historyHeaderFontStyle, { marginTop: 10 }]}>{bill.payment_type ? bill.payment_type : null}</Text>

          </View>
          {  (this.state.isAdminAccess || (this.state.FO_admin_setting && this.state.FO_admin_setting.allow_saved_bill_change)) ?
          <TouchableOpacity onPress={() => {
            this.setState({
              isDelete: true,
              deletedIDs: bill.billing_id
            })
          }}
            style={{ flex: 0.2 }}

          >
            {this.renderIdButton("trash")}
          </TouchableOpacity> : null}
        </View>
      </TouchableOpacity>

    );
  }
}







const styles = StyleSheet.create({
  calendarView: {
    // borderColor: "#525252",
    borderColor: color.lightGray,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  calendarText: {
    color: color.lightGray,
    width: "90%"
  },
  headingBackGround: {
    backgroundColor: color.appointmentTableHead,
    paddingHorizontal: 25,
    paddingVertical: 10
  },
  dasboardcardcontentstyle: {
    flexDirection: "row",
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: "white",
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 4,
    height: 60,
    minWidth: 120,
    paddingVertical: 7.5,
    paddingHorizontal: 10
  },
  textFontStyle:{
    fontSize: 20, 
    fontWeight: '500',
    marginBottom: 15, 
    marginTop: 20
  },
  emed_content_container: {
    flexDirection: "row", marginVertical: 15
  },
  emed_radio_btn_txt: {
    fontWeight: "500", fontSize: 13
  },
  emed_radio_btn: {
    marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
  },
  emed_radio_btn_touchablearea: {
    marginRight: 20, flexDirection: "row", alignItems: "center"
  },
})