//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton ,HistorySectionRightSideUI} from '../../BaseComponent'
// import { color } from '../../../../styles/Color';
// import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";

// create a component
const pageName = "isPapSmearMammogramHistoryNew"
class PapSmearMammogramHistory extends Component {


    constructor(props) {
        super(props);
        this.state = {

            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,


            checkTreatmentForAbnormalPapSmear: {},
            checkTreatmentForAbnormalPapSmearCommentBox: "",
            checkCryotherapy: {},
            checkCryotherapyCommentBox: "",

            checkLaser: {},
            checkLaserCommentBox: "",
            checkConeBiopsy: {},
            checkConeBiopsyCommentBox: "",

            checkLoopExcision: {},
            checkLoopExcisionCommentBox: "",

            checkAbnormalMammogram: {},
            checkAbnormalMammogramCommentBox: "",

            lastmammogramDate: "",
            lastPapSmearDate: "",

            papSmearHistory: {},

            hideEditIcon:true
        }
    }

    renderContent(heading, value,details) {
        return (
            <View>
                                                <HistorySectionRightSideUI heading={heading} value={value} details={details} />

                {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}{value && details ?", ":""}{details}</Text> */}
            </View>
        );
    }

    componentDidMount() {
        this.getPapSmearHistory()
    }

    getPapSmearHistory() {
        var service_url = Constants.PAP_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPapSmearHistorySuccess,
            this.getPapSmearHistoryFailure
        );
    }

    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }

    getPapSmearHistorySuccess = success => {


        if (success.status == "success") {
            // alert(JSON.stringify(success))
            // console.log(" -----------------------? "+JSON.stringify(success))
            var states = this.state;
            var notApplicable = ""
            var data = success.data.pap_mammogram_history;

            if (Object.keys(data).length > 0) {

                states["checkTreatmentForAbnormalPapSmear"] = this.checResponceIsFlag(data.had_abnormal_pap_smears_treatment)
                states["checkTreatmentForAbnormalPapSmearCommentBox"] =  data.abnormal_treatment_desc    // need to get 
                states["checkCryotherapy"] = this.checResponceIsFlag(data.treatment_cryotherapy)
                states["checkCryotherapyCommentBox"] = data.cryotherapy_desc

                states["checkLaser"] = this.checResponceIsFlag(data.treatment_laser)
                states["checkLaserCommentBox"] = data.laser_desc

                states["checkConeBiopsy"] = this.checResponceIsFlag(data.treatment_cone_biopsy)
                states["checkConeBiopsyCommentBox"] = data.cone_biopsy_desc


                states["checkLoopExcision"] = this.checResponceIsFlag(data.treatment_leep)
                states["checkLoopExcisionCommentBox"] = data.leep_desc
                states["checkAbnormalMammogram"] = this.checResponceIsFlag(data.had_abnormal_mammogram)
                states["checkAbnormalMammogramCommentBox"] = data.abnormal_mammogram_desc
                states["lastmammogramDate"] = data.last_mammogram_date
                states["lastPapSmearDate"] = data.last_pap_smear

                states["papSmearHistory"] = data

            } else {
                states["checkTreatmentForAbnormalPapSmear"] = notApplicable
                states["checkTreatmentForAbnormalPapSmearCommentBox"] = notApplicable
                states["checkCryotherapy"] = notApplicable
                states["checkCryotherapyCommentBox"] = notApplicable

                states["checkLaser"] = notApplicable
                states["checkLaserCommentBox"] = notApplicable

                states["checkConeBiopsy"] = notApplicable
                states["checkConeBiopsyCommentBox"] = notApplicable


                states["checkLoopExcision"] = notApplicable
                states["checkLoopExcisionCommentBox"] = notApplicable
                states["checkAbnormalMammogram"] = notApplicable
                states["checkAbnormalMammogramCommentBox"] = notApplicable
                states["lastmammogramDate"] = notApplicable
                states["lastPapSmearDate"] = notApplicable

                states["papSmearHistory"] = data
            }


            var hideEditIcon=true
            Object.keys(data).forEach((keys)=>{
                if(data[keys]){
                    hideEditIcon=false
                }


            })

            this.setState({ states,hideEditIcon:hideEditIcon })
        }

        // console.log("*************** success  +++>&&& " + JSON.stringify(success))
    }
    getPapSmearHistoryFailure = error => { }


    renderHistorySectionFields(rightHeader, rightValue,rightDetailsValue, leftHeader, leftValue,leftDetailsValue) {
        return (
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.5
                 }}>
                    {this.renderContent(rightHeader, rightValue,rightDetailsValue)}
                </View>
                <View style={{ flex: 0.5 }}>
                    {this.renderContent(leftHeader, leftValue,leftDetailsValue)}
                </View>
            </View>
        )
    }

    renderHistorySection() {
        var states = this.state;
        // console.log("states+++++>< "+JSON.stringify(states))

        return (
            <View style={{marginTop:15}}>
                {this.renderHistorySectionFields(
                    "Date of last pap smear",
                    this.state.lastPapSmearDate?moment(this.state.lastPapSmearDate).format("DD-MM-YYYY"):"",
                    "",
                    "Have you ever had any treatment for an abnormal pap smear?",
                    this.state.checkTreatmentForAbnormalPapSmear.value,
                    this.state.checkTreatmentForAbnormalPapSmearCommentBox)}
                {this.renderHistorySectionFields(
                    "Cryotherapy",
                    this.state.checkCryotherapy.value 
                    ,this.state.checkCryotherapyCommentBox,
                    "Laser",
                    this.state.checkLaser.value 
                    ,this.state.checkLaserCommentBox
                    )}
                {this.renderHistorySectionFields(
                    "Cone Biopsy",
                    this.state.checkConeBiopsy.value ,
                    this.state.checkConeBiopsyCommentBox,
                    "Loop Excision(LEEP)",
                    this.state.checkLoopExcision.value ,
                    this.state.checkLoopExcisionCommentBox)}
                {this.renderHistorySectionFields(
                    "Date of last mammogram",
                    this.state.lastmammogramDate?moment(this.state.lastmammogramDate).format("DD-MM-YYYY"):"",
                    "",
                    "Have you had an abnormal mammogram?",
                    this.state.checkAbnormalMammogram.value 
                    ,this.state.checkAbnormalMammogramCommentBox)}
            </View>
        )
    }

    selectedRightSideView(pageName) {
        this.props.selectedRightSideView(pageName);
    }

    componentWillReceiveProps(props) {

        if(props.refreshRighSideComponentName == pageName ){
            this.getPapSmearHistory();
            this.props.refreshRighSideComponent("");
        }
        this.state.selectedPageName = props.selectedPageName

    }
    editHistory() {
        this.props.editHistory(this.state.papSmearHistory, pageName)
    }
    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                    heading={"Pap Smear / Mammogram History"}
                    editImage={this.state.hideEditIcon ?  "":"pencil"}
                // deleteImage={"trash"} 
                />
                {this.renderHistorySection()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
    
    },
});

//make this component available to the app
export default PapSmearMammogramHistory;
