import React, { Component } from "react";
import {
    View,
    Text,
    Button,
    Dimensions,
    FlatList,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    TextInput
} from "react-native";
import { SearchBar } from 'react-native-elements';
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
// import SideMenu from "../../SideMenu";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import TimerLog from "../../TimerLog";
import BaseComponentStyle from "../BaseComponentStyle";

// import DatePicker from 'react-native-datepicker'

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const dummyBillData = [

    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "Marie",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "reggie",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "Monish",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "Marie Winterrrr",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "krish",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "vinith",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "Marie Winter",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },
    {
        image: require("../../../../assets/images/sample_profile.png"),
        name: "Marie Winter",
        Messages: "Have pushed the appointment to 22nd May 2020"
    },

];

export default class Billing extends BaseComponentStyle {
    constructor(props) {
        super(props);

        this.state = {
            patientAppointment: this.props.patientAppointment,
            currentTime: "",
            search: '',
            filterData: {
                fromDate: null,
                toDate: null,
                patientName: "",
                billNo: "",
                amount: "",
                paymentType: "",

            },
            messages: {
                data: [],
                listData: []
            },
            data: [],
            selectedMessage: {
                phone_number: "",
                message: "",
                sent_date: "",
                name: "",
                photo_url: "",
            },
            selectedChatIndex: 0,
            selected: {
                name: "Marie Winter",
                message: "Have pushed the appointment to 22nd May 2020",
            }

        };
    }

    componentDidMount() {
        var timesRun = 0;

        // setInterval(() => { 
        //     this.getMessage();
        //     timesRun += 1;
        //     if(timesRun === 2){
        //         clearInterval(this.getMessage())
        //     }
        //     this.setState({
        //         currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
        //     });
        // }, 1000);
        this.createFilterTag();

        // setTimeout(() => {
        //     this.getMessage();
        // }, 3000);

        // for (var i = 1; i <= 3; ++i) {
           
        // }
        
    }

    getMessage = event => {
       // console.log("add message")

        var service_url = Constants.OPTHALMOLOGY_MEESAGE

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMessageSuccess,
            this.getMessageFailure
        );
    };

    getMessageSuccess = response => {
        var { selectedMessage, messages } = this.state;
        if (response.status === "success") {

            if (response.data.length !== 0) {
                selectedMessage["message"] = response.data[0].message;
                selectedMessage["phone_number"] = response.data[0].phone_number;
                selectedMessage["name"] = response.data[0].name;
                selectedMessage["photo_url"] = response.data[0].photo_url;

                messages["data"] = response.data;
                messages['sentMessagesList'] = response.data
            }

            this.setState({
                messages,
                selectedMessage
            });

            
        }
    };

    getMessageFailure = error => {
      //  console.log("opthamology complaints GET error response");
        console.log(error);
    };

    onPress = (index) => {
     
        var stateValue = this.state;
        var data = this.state.messages.data;
        
        // console.log("_____________")
        // console.log(index)
        // console.log(data)

        stateValue["selectedMessage"]["message"] = data[index].message;
        stateValue["selectedMessage"]["phone_number"] = data[index].phone_number;
        stateValue["selectedMessage"]["name"] = data[index].name;
        stateValue["selectedMessage"]["photo_url"] = data[index].photo_url;
        stateValue["selectedChatIndex"] = index;
    
        this.setState({
            stateValue
        });
    }


    rendermessagelist(item, index) {
      
        let isSelected = index === this.state.selectedChatIndex ? true:false

        return (

            <View style={{ 
                width: "100%",
                paddingHorizontal: 15, 
                backgroundColor: isSelected ? color.themeDark : null, 
                borderRadius: 20 }}>
                <TouchableOpacity onPress={() => this.onPress(index)}>
                    <View
                        style={{
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",

                            // marginLeft: 0,
                            // marginRight: 30,
                            marginVertical: 10,
                            borderRadius: 20,
                            alignItems: "center",
                            // backgroundColor: color.white
                            height: 60
                        }}
                    >
                        <View
                            style={{
                                width: "20%",
                                // marginVertical: 15
                            }}
                        >
                            <View>

                                <Image source={item.photo_url?item.photo_url:require("../../../../assets/images/sample_profile.png")}
                                    style={{ height: 40, width: 40, borderRadius: 20 }}
                                    resizeMode='stretch' />

                            </View>
                        </View>

                        <View
                            style={{
                                width: "80%",
                                alignItems: "flex-start",
                                justifyContent: "center"
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    color: isSelected? color.white: color.themeBlue
                                }}
                            >
                                {item.name}
                            </Text>
                            <Text
                                style={{
                                    fontSize: 12,
                                    color: isSelected? color.themeShadeBackground: color.lightGray
                                }}
                            >
                                {item.message}
                            </Text>
                        </View>


                    </View>
                    {/* <View
                        style={{

                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: index === this.state.selectedChatIndex ? 0 : 1,
                            marginBottom: 20,
                            marginLeft: 30


                        }}
                    /> */}
                </TouchableOpacity>
            </View>

        );           
    }

    calculateTotalAmt = () => {
        var fields = this.state;
        var cardTotal = 0;
        var cashTotal = 0;

        dummyBillData &&
            dummyBillData.length > 0 &&
            dummyBillData.map(item => {
                if (item.paymentType === "card") {
                    cardTotal = cardTotal + parseInt(item.billAmount);
                } else if (item.paymentType === "cash") {
                    cashTotal = cashTotal + parseInt(item.billAmount);
                }
            });

        fields["totalCardAmt"] = cardTotal;
        fields["totalCashAmt"] = cashTotal;

        this.setState({ fields });
    };

    createFilterTag = () => {
        var temp = [];
        var fields = this.state;

        if (this.state.filterData.fromDate && this.state.filterData.toDate) {
            temp.push({
                key: "bothDate",
                value:
                    this.state.filterData.fromDate + " - " + this.state.filterData.toDate
            });
        }
        if (this.state.filterData.fromDate || this.state.filterData.toDate) {
            if (this.state.filterData.fromDate) {
                temp.push({ key: "fromDate", value: this.state.filterData.fromDate });
            } else {
                temp.push({ key: "toDate", value: this.state.filterData.toDate });
            }
        }
        if (this.state.filterData.patientName) {
            temp.push({
                key: "patientName",
                value: this.state.filterData.patientName
            });
        }
        if (this.state.filterData.billNo) {
            temp.push({ key: "billNo", value: this.state.filterData.billNo });
        }
        if (this.state.filterData.amount) {
            temp.push({ key: "amount", value: this.state.filterData.amount });
        }
        if (this.state.filterData.paymentType) {
            temp.push({
                key: "paymentType",
                value: this.state.filterData.paymentType
            });
        }

        fields["filterArray"] = temp;

        this.setState({ fields });
    };



    renderIconBtn = (text, key) => {
        return (
            <View
                style={{
                    height: 35,
                    width: 130,
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 20,
                    flexDirection: "row",
                    backgroundColor: color.white,
                    borderWidth: 1,
                    borderColor: color.themePink,
                    marginRight: 5,
                    marginBottom: 5
                }}
            >
                <Icon
                    onPress={() => {
                        this.clearFilter(key);
                    }}
                    name="times"
                    size={platform === "web" ? 20 : 20}
                    color={color.themePink}
                />
                <Text
                    style={{
                        fontSize: 10,
                        color: color.themePink,
                        fontWeight: "700",
                        paddingLeft: 5
                    }}
                >
                    {text}
                </Text>
            </View>
        );
    };

    clearFilter = key => {
        var fields = this.state;

        if (key === "bothDate") {
            fields["filterData"]["fromDate"] = null;
            fields["filterData"]["toDate"] = null;
        } else if (key === "fromDate" || key === "toDate") {
            fields["filterData"][key] = null;
        } else {
            fields["filterData"][key] = "";
        }

        this.setState({ fields });

        this.createFilterTag();
    };

    renderRightBtn = (name, outline) => {
        return (
            <View
                style={{
                    height: 35,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    flexDirection: "row",
                    borderColor: outline ? color.themePink : color.themeBlue,
                    borderWidth: 1,
                    backgroundColor: outline ? color.themeBlue : color.themePink,
                    padding: 5,
                    justifyContent: "space-evenly",
                    marginBottom: 10
                }}
            >
                <Text
                    style={{
                        fontSize: 13,
                        textAlign: "center",
                        color: outline ? color.themePink : color.white,
                        fontWeight: "700",
                        paddingLeft: 25,
                        paddingRight: 25
                    }}
                >
                    {name}
                </Text>
            </View>
        );
    };

    renderTextField = (title, key, value, halfSize) => {
        return (
            <View
                style={{
                    borderColor: "#525252",
                    borderWidth: 1,
                    marginTop: 5,
                    height: 35,
                    borderWidth: 1,
                    borderColor: color.white,
                    borderRadius: 10,
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: halfSize ? "50%" : "80%",
                    marginRight: halfSize ? 10 : 0
                }}
            >
                <TextInput
                    placeholder={title}
                    underlineColorAndroid="transparent"
                    style={{ color: color.white, width: "90%" }}
                    placeholderTextColor={color.white}
                    autoCapitalize="none"
                    value={value}
                    onChangeText={text => {
                        this.updateState(key, text);
                    }}
                />
            </View>
        );
    };

    renderPaymentBtn = (img, title) => {
        return (
            <TouchableOpacity
                onPress={() => {
                    this.updateState("paymentType", title.toLowerCase());
                }}
            >
                <View style={{ alignItems: "center", justifyContent: "center" }}>
                    <Image
                        source={img}
                        style={{ width: 20, height: 20 }}
                        resizeMode="contain"
                    />
                    <Text style={{ color: color.white, marginTop: 5, fontSize: 10 }}>
                        {title}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    updateState = (key, value) => {
        var fields = this.state;
        fields["filterData"][key] = value;
        this.setState({ fields });

        this.createFilterTag();
    };

    filterAddressBook = (search) => {
        let messages = this.state
        let sentMessagesList = this.state.messages.sentMessagesList
        let filteredList = sentMessagesList.filter(item => item.name.toLowerCase().startsWith(search.toLowerCase()))
        messages.messages.data=filteredList

    }

    render() {
        return (
            <View style={{height: screenHeight, flex: 1, flexDirection: "row"}}>
                {/* side menu */}
                {/* <View style={[Style.leftBarView]}>
                    <SideMenu navigation={this.props.navigation} />
                </View> */}
                {/* first view */}
                <View style={[Style.leftView,{width:"65%"}]}>
                    <View
                        style={{
                            marginTop: platform === "web" ? 80 : 20,
                            width: "100%",
                            marginLeft: 50,
                            paddingRight: 10,

                        }}
                    >
                        <View style={{ width: '90%' }}>

                            <Text style={{ fontSize: 20 }}>{this.state.selectedMessage.name}</Text>
                        </View>
                        <View style={{ marginTop: 50, width: '80%' }}>
                            <ScrollView style={{ height: 400 }}>
                                {/* <Text style={{fontSize:platform==='web'?14:12}}>
            The doctor carries a social message. 
        Many people see doctors as being Semites, and by performing the medical message they are able to perform other messages in society such as reform among people.
        And remove the differences and greed among them.
        who heal people from the ills of their bodies may have been able to heal the community of his problems and problems with a vision capable of diagnosing problems and solutions.And to every doctor be eligible for this profession, 
        do not make medicine a humiliating profession, and abide by its ethics.And do not make your goal purely physical, you work in the highest professions, and the lives of people and rest in your hands.
        The doctor carries a social message. 
        Many people see doctors as being Semites, and by performing the medical message they are able to perform other messages in society such as reform among people.
        And remove the differences and greed among them.
        who heal people from the ills of their bodies may have been able to heal the community of his problems and problems with a vision capable of diagnosing problems and solutions.And to every doctor be eligible for this profession, 
        do not make medicine a humiliating profession, and abide by its ethics.And do not make your goal purely physical, you work in the highest professions, and the lives of people and rest in your hands.
        The doctor carries a social message. 
       
        </Text> */}
                                <Text>{this.state.selectedMessage.message}</Text>

                            </ScrollView>
                        </View>
                        {/* <View style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <TouchableOpacity>
                                {this.renderIconsBtn("", "Delete", false, "")}</TouchableOpacity>
                        </View> */}
                    </View>


                </View>
                {/* second view */}
                <View style={[Style.rightView,{width:"35%"}]}>
                    {/* <View style={{ width: "100%" }}> */}
                        {/* <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 13, color: "#ffffff" }}>
                                {this.state.currentTime}
                            </Text>
                        </View> */}
                        {/* <TimerLog /> */}

                        <View
                            style={{
                                height: 'auto', //65,
                                margin: 10,
                                marginBottom: 5,
                                width: "100%"
                            }}
                        >
                            <SearchBar
                                placeholder="Type Here..."
                                round={true}
                                containerStyle={ Style.SearchBarContainer }
                                inputContainerStyle={ Style.SearchBarInnerContainer }
                                inputStyle={ Style.SearchBarInput } 
                                onChangeText={search => {this.setState({search})
                                this.filterAddressBook(search)}}
                                value={this.state.search}
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                // justifyContent: "space-evenly",
                                alignItems: "center",
                                bottom: 0,
                                // marginTop: 20,
                                paddingHorizontal: 15,
                                marginBottom: 0,
                                width: "100%",
                                // flex: 1
                                height: 60
                            }}
                        >
                            <View style={{ 
                                    //width: "85%" 
                                    flex: 0.8
                                }}>
                                <Text
                                    style={{
                                        color: color.themeBlue,
                                        fontSize: 25
                                    }}
                                >
                                    Messages
                                </Text>
                            </View>
                            <View style={{ 
                                // width: "20%" 
                                flex: 0.2
                                }}>
                                <TouchableOpacity onPress={() => 
                                    {this.props.changeScreen("sendmessage") }
                                    // this.props.navigation.navigate("sendmessage")
                                    } >
                                    <Image
                                        source={require("../../../../assets/images/adding.svg")}
                                        style={{ width: 30, height: 30, 
                                            // tintColor: color.themeDark 
                                        }}
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>

                        
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 440, width: "100%" }}>

                            <FlatList
                                style={{ marginTop: 20, }}
                                data={this.state.messages.data}
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                renderItem={({ item, index }) => this.rendermessagelist(item, index)}
                            />
                        </ScrollView>
                        



                    </View>
                {/* </View> */}
            </View>
        );
    }
}