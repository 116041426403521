//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton,CommonAddButton ,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component
const pageName="isBloodGlucose"

export class BloodGlucose extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {},
            hideEditIcon:true
        }
    }

    componentDidMount() {
        this.getBloodGlucose()
    }

    getBloodGlucose(){
        var service_url = Constants.GET_BLOOD_GLUCOSE + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getBloodGlucoseSuccess,
            this.getBloodGlucoseFailure
        );
    }


    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    getBloodGlucoseSuccess = success => {
        //console.log("success" + JSON.stringify(success))
        if (success.status == "success") {

            var data = success.data

            var booleanValues = []
            var stringVaues = []
            var hideEditIcon = true
            Object.keys(data).forEach((key) => {
                if(data[key]){
                    hideEditIcon = false
                }
                if (typeof data[key] == "boolean") {
                    let prepareData = {}
                    if (key == "bg_above_300") {
                        prepareData = {
                            key: "Any recent blood glucose above 300?",
                            value: data[key] ? "Yes" : "No",
                        }
                    } 
                    else if (key == "bg_below_70") {
                        prepareData = {
                            key: "Any recent blood glucose below 80?",
                            value: data[key] ? "Yes" : "No",
                            // details: data.bg_check_frequency
                        }
                    } 
                    else if (key == "check_your_feet") {
                        prepareData = {
                            key: "Do you check your feet?",
                            value: data[key] ? "Yes" : "No",
                            details: data.how_freq
                        }
                    }
                    else if (key == "vision_problem") {
                        prepareData = {
                            key: "Do you have any problem in your vision?",
                            value: data[key] ? "Yes" : "No",
                            details: data.vision_problem_desc
                        }
                    }

                    booleanValues.push(prepareData)
                } else {
                    let prepareData = {}
                    if (key == "bg_check_frequency") {
                        var times=""
                        if(data[key]==1){
                            times=" time"
                        }else{
                            times=" times"
                        }
                        prepareData = {
                            key: "How often do you test your blood glucose per month?",
                            value:data[key]? data[key]+times:"",                        }
                    } else if (key == "high_blood_glucose") {
                        prepareData = {
                            key: "Have you had any of the following signs of High Blood glucose in the past 3 months?",
                            value: data[key],
                        }
                    } else if (key == "low_blood_glucose") {
                        prepareData = {
                            key: "Have you had any of the following signs of Low Blood glucose in the past 3 months?",
                            value: data[key],
                        }
                    }   
                    stringVaues.push(prepareData)
                }
            })



            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

           // console.log("success _________> " + JSON.stringify(removeEmptyObj))
            this.setState({
                getListofDiabeticHistory: data,
                listOfDatas: removeEmptyObj,
                hideEditIcon:hideEditIcon,
            })


        }
    }
    getBloodGlucoseFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getBloodGlucose();
            this.props.refreshRighSideComponent("");
        }
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={1}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // setTimeout(() => {
        //     this.editHistory()
        // }, 100);
    }
    editHistory() {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
        }, 100);

    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Blood Glucose Monitoring"}
                    editImage={this.state.hideEditIcon ? "":"pencil"}                    
                />

                {this.renderContent()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});


//make this component available to the app
