//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI,EditiAndDeleteForLineItem } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "isMedicalProblemsNew"

// create a component
class MedicalProblems extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,


            listOfDatas: [],
            getListofBirthHistory: {},
            hideEditIcon: true,

            medicalProblemsList:[]

        }
    }

    componentDidMount(){
        this.getMedicalProblemsDetails()  
    }

    getMedicalProblemsDetails(){
        var service_url = Constants.MEDICAL_PROBLEMS+"?patient_id="+this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMedicalProblemsDetailsSuccess,
            this.getMedicalProblemsDetailsFailure
        );
    }

    getMedicalProblemsDetailsSuccess= success=>{
        if(success.status == "success"){
            // console.log("success --------------> "+JSON.stringify(success))
            
            this.setState({
                medicalProblemsList:success.data
            })
        }
    }
    getMedicalProblemsDetailsFailure= error=>{

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getMedicalProblemsDetails();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
    }

    editHistory(item) {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(item, pageName)
        }, 100);
    }

    deleteHistory(item){
        var states = this.state;
        var service_url = Constants.MEDICAL_PROBLEMS_DELETE;
        var data ={
            "patient_id":states.patientAppointment.patient_id,
            "problem_id":item.id,
        }

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.deleteHistorySuccess,
            this.deleteHistoryFailure
        );
    }

    deleteHistorySuccess = success =>{
        if(success.status == "success"){
            this.props.showResposeValue('success', success.message);
            this.getMedicalProblemsDetails() 
        }else{
            this.props.showResposeValue('error', success.message);
        }
    }

    deleteHistoryFailure = success =>{
        this.props.showResposeValue('error', error.message);
    }

    renderListOfData(item){
        return(
            <View style={{flex:0.49 ,marginRight:10}}>

            <EditiAndDeleteForLineItem
                editHistory={this.editHistory.bind(this,item)}
                deleteHistory={this.deleteHistory.bind(this,item)}

                heading={item.surgery}
                editImage={"pencil"}
                deleteImage={"trash"}
                />
                <View style={{marginVertical:8}}>
                <Text style={{fontSize:13,marginLeft:9}} numberOfLines={10}>{item.description}</Text>
                </View>
            </View>
        )
    }


    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} 
                    heading={"Medical Problems"}
                    // editImage={"pencil"}
                    // deleteImage={"trash"} 
                />

                <View style={{flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15}}>
                <FlatList
                    data={this.state.medicalProblemsList}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />
                </View>

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default MedicalProblems;
