import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  CheckBox,
  Picker,
} from "react-native";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import { Calendar } from 'react-native-calendars';
import Pagination from './../../../doctor/common/Pagination';
import { CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton, NavigationTopHeader, paymentReturnType, returnPaymentMode } from '../../BaseComponent'
import CommonDateFilter from '../../BaseComponent'
import AsyncStorage from "../../../../AsyncStorage";
import 'antd/dist/antd.css';
import { DatePicker, Radio } from 'antd';
import OpthamologyService from "../../../../network/OpthamologyService";
import Success from "react-native-vector-icons/FontAwesome5";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;

const navigationHeaderList = [
  { label: "Billing Transaction", value: "ipallTransactions" },
]

const navigationHeaderListIfIPCreateBill = [
  { label: "Create Bill", value: "ipPatientCreateBill" },
  { label: "Pending Bill", value: "ipPatientSavedBill" },
  { label: "Billing Transaction", value: "ipallTransactions" },
  { label: "OT Bills", value: "otbills" },
  // { label: "Summary Bill", value: "summaryBill" },
  { label: "Estimation Bill", value: "estimationBill" },
]


export default class IPAllTransactions extends Component {
  constructor(props) {
    super(props);

    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");

    this.state = {
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      isCurrentPageIsIPPatientAppointment: this.props.isCurrentPageIsIPPatientAppointment,
      userType: this.props.userType,
      allBillingTransactionList: [],
      billingListAfterPagination: [],
      fromDate: Object.keys(this.props.selectedIpPatientDetails).length > 0 ? "" : converted_date,
      toDate: Object.keys(this.props.selectedIpPatientDetails).length > 0 ? "" : converted_date,
      patientName: "",
      billNumber: "",
      billAmount: "",
      payment_type_list: [],

      selectedBillType: "",

      totalAmount: "",
      totalCashAmount: "",
      totalCardAmount: "",
      totalUPIAmount: "",
      totalChequeAmount:"",

      billingIPnumber: "",
      indudvalIPAdminID: "",
      refundAmount: "",
      invoiceID: "",
      filterDate: {},
      isAcNumClicked: false,
      isclear: false,
      isclearInt: 0,
      isBillingListSuccess: false,
      isAdminAccess: false,

      doctorData:[],
      doctor_id:"",
      filterType: "",
      isDisable : true
    };
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getBillingList();

    if (Constants.ROLE_FO == "FO"){
      var service_url = Constants.HOME_GET_CLINIC_DOCTORS;
  
    fetch(service_url,{
      method:"GET",
      headers:{
        Authorization:"Token " + (localStorage.getItem("token")).toString(),
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        doctorData:data.data
      })
    });
  
  }
  }

  componentWillReceiveProps(props) {
    if (props.checkIpNumber == "isCheckIpNumber") {
      if (this.state.billingIPnumber) {
        this.setState({
          billingIPnumber: ""
        }, () => {
          this.props.changePageOrNavigateTOAnotherPage("ipallTransactions", false, "");
          this.getBillingList();
        })
      } else {
        this.props.changePageOrNavigateTOAnotherPage("ipPatientCreateBill", false, "")
      }

    }
    if (props.refreshAllBills) {
      this.getBillingList();
      this.props.stopRefreshAllBills()
    }
  }

  getPatientBillIndudvalBillingTransection = () => {
    var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + this.state.invoiceID
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPatientBillIndudvalBillingTransectionSuccess,
      this.getPatientBillIndudvalBillingTransectionFailure
    );
  };

  getPatientBillIndudvalBillingTransectionSuccess = response => {
    if (response.status === "success") {
      this.props.showBillingScreenPopup(true, response.data, this.state.invoiceID, false, this.state.BillStatus)
    }
  };

  getPatientBillIndudvalBillingTransectionFailure = error => { }

  renderAmountValueInCard(amount, bill = "", billingType) {
    return (
      <View style={{
        justifyContent: 'center',
        alignSelf: 'center',
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5,
        backgroundColor: color.white, height: 50,
        borderRadius: 4,
        paddingHorizontal: 15,
        minWidth: 100,
        marginRight: 20,
        marginTop: 10,
        flexWrap: "wrap"
      }}>
        <Text style={{ fontSize: 14, fontWeight: '500' }}>{amount ? "₹ " : null}{amount}</Text>
        <Text style={{ fontSize: 12, fontWeight: '400', textAlign: 'right', marginTop: 10 }}>{billingType}</Text>
      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    states["filterDate"] = date
    states["fromDate"] = date.fromDate;
    states["toDate"] = date.toDate;
    this.setState({ states })
  }

  renderOverAllBillStatus() {
    var billingtypeTotal = ""
    var billingtypePaid = ""
    var billingtypeBalance = ""
    var billingTypeUPI = ""
    var billingTypeBank = ""
    var billingTypeInsurance = ""
    var billingTypeCheque = ""
    if (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) {
      billingtypeTotal = "Total Bill"
      billingtypePaid = "Total Paid"
      billingtypeBalance = "Balance"
    } else {
      billingtypeTotal = "Total"
      billingtypePaid = "Cash"
      billingtypeBalance = "Card"
      billingTypeUPI = "UPI"
      billingTypeBank = "Bank Transfer"
      billingTypeInsurance = "Insurance"
      billingTypeCheque = "Cheque"
    }
    return (
      <View>
        <View style={{marginBottom: 15, marginTop: 20, backgroundColor: "rgb(238, 238, 238)", paddingVertical:".5vw" }}>
          <Text style={{ fontSize: 16, fontWeight: '500', paddingLeft:".5vw"}}>{"Overall Bill Status"}</Text>
        </View>
        <View style={{paddingLeft:".5vw"}}>
          {this.renderAmountValueInCard(this.state.totalAmount, "totalAmount", billingtypeTotal)}
        </View>
        <View style={{ flexDirection: 'row', flexWrap: "wrap", paddingLeft:".5vw", justifyContent:"center" }}>
          {this.renderAmountValueInCard(this.state.totalCashAmount, "totalCashAmount", billingtypePaid)}
          {this.renderAmountValueInCard(this.state.totalCardAmount, "totalCardAmount", billingtypeBalance)}
          {
          (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) ? null :
          this.renderAmountValueInCard(this.state.totalUPIAmount, "totalUPIAmount", billingTypeUPI)
         } 
        {
          (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) ? null :
          this.renderAmountValueInCard(this.state.totalBankTransferAmount, "totalBankTransferAmount",billingTypeBank)
         }
         {
          (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) ? null :
          this.renderAmountValueInCard(this.state.totalInsuranceAmount, "totalInsuranceAmount",billingTypeInsurance)
         }
         {/* {
          (this.state.isCurrentPageIsIPPatientAppointment || this.state.billingIPnumber) ? null :
          this.renderAmountValueInCard(this.state.totalChequeAmount, "totalChequeAmount",billingTypeCheque)
         } */}
        </View>
      </View>
    )
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  renderTextBox(label, key, value, placehold) {
    return (
      <View>
        <Text style={{ marginBottom: 8 }}>{label}</Text>
        <TextInput
          disabled = { key === "refundAmount" ? true : false }
          onChangeText={(text) => {
            var states = this.state;

            if (key == "refundAmount") {
              if (this.numberValidation(text))
                states[key] = text;
            }
            else {
              states[key] = text;

            }
            this.setState({
              states
            })
          }}
         placeholder = { label == "Search" ? placehold : label } placeholderTextColor = {color.lightGray} value={value} style={{ height: 35, width: 175, borderWidth: 0.1, borderColor: "#888888", borderRadius: 4, paddingLeft: 10 }} />
      </View>
    )
  }

  renderBillTypeView() {
    var listOfBillType = [
      { label: "Corporate", value: "Corporate" },
      { label: "Credit", value: "Credit" }
    ]
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <View style={{marginBottom: 15, marginTop: 20, backgroundColor: "rgb(238, 238, 238)", paddingVertical:".5vw" }}>
          <Text style={[styles.emed_IPbill_sideheader]}>{"Bill Type"}</Text>
        </View>
        <View style={[styles.emed_content_container]}>
          {
            listOfBillType.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                  this.setState({
                    selectedBillType: item.value,
                  }, () => {
                    var { selectedBillType } = this.state;
                    var states = this.state;
                    if (selectedBillType == "Corporate") {
                      this.setState({
                        selectedBillType: "Corporate"
                      })
                    } else if (selectedBillType == "Credit") {
                      this.setState({
                        selectedBillType: "Credit"
                      })
                    }
                  })
                }} style={[styles.emed_radio_btn_touchablearea]} key={index}>
                  <View style={[{
                    backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                  }, styles.emed_radio_btn]}></View>
                  <Text style={[styles.emed_radio_btn_txt]}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
      </View>
    )
  }
  renderPaymentIcons(image, label, key) {
    return (
      <View style={{}}>
        <TouchableOpacity onPress={() => {
          var fields = this.state;
          var tempList = fields.payment_type_list;
          if (tempList.indexOf(key) > -1) {
            tempList.splice(tempList.indexOf(key), 1)
          } else {
            if (tempList.length > 1)
              tempList.splice(0, 1)
            tempList.push(key)
          }
          if(tempList.length == 1){
            this.setState({filterType : true, isDisable : true})
          }else if(tempList.length > 1){
            this.setState({filterType : this.state.filterType, isDisable : false})
          }else{
            this.setState({filterType : "", isDisable : true})
          }

          fields["payment_type_list"] = tempList;
          this.setState({ fields })
        }} style={{ alignItems: "center" }}>
          <Image source={image} style={{ height: 25, width: 25, marginBottom: 5, tintColor: (this.state.payment_type_list.indexOf(key) > -1) ? color.themeDark : "#888888" }} />
          <Text style={{ color: (this.state.payment_type_list.indexOf(key) > -1) ? color.themeDark : "black" }}>{label}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderPaymentType() {
    return (
      <View style={{ zIndex: -1 }}>
        <View style={{marginBottom: 15, marginTop: 15, backgroundColor: "rgb(238, 238, 238)", paddingVertical:".5vw" }}>
        <Text style={[styles.emed_IPbill_sideheader]}>{"Payment Type"}</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
          {this.renderPaymentIcons(
            require('../../../../../assets/images/IPActions/Cash.png'),
            "Cash",
            "Cash",
          )}
          {this.renderPaymentIcons(
            require('../../../../../assets/images/IPActions/Card.png'),
            "Card",
            "Card"
          )}
          {/* {this.renderPaymentIcons(
            require('../../../../../assets/images/IPActions/Cash&Card .png'),
            "Cash & Card",
            "Cash and Card"
          )} */}
          {this.renderPaymentIcons(
            require("../../../../../assets/images/IPActions/upi.png"),
            "UPI", "UPI"
          )}
          {this.renderPaymentIcons(
            require("../../../../../assets/images/IPActions/bank-transfer.png"),
            "Bank Transfer", "Bank Transfer"
          )}
          {this.renderPaymentIcons(
            require("../../../../../assets/images/IPActions/Insurance.png"),
            "Insurance", "Insurance"
          )}
          {/* {this.renderPaymentIcons(
            require("../../../../../assets/cheque.png"),
            "Cheque", "Cheque"
          )} */}
        </View>
      </View>
    )
  }

  renderFilterType() {
    return (
      <View style={{marginTop:"1vw"}}>
      <Radio.Group disabled={this.state.isDisable ? true : false}
        onChange={(e) => {
          this.setState({ filterType: e.target.value });
        }}
        value={this.state.filterType}
      >
        <Radio value={true}>Selected Payment only</Radio>
        <Radio value={false}>Combine Selected Payment</Radio>
      </Radio.Group>
      </View>
    )
  }

  clearSearchData() {
    let fulldate = new Date();
    let today = moment(fulldate).format("YYYY-MM-DD");
    var states = this.state
    states["billAmount"] = ""
    states["billNumber"] = ""
    states["patientName"] = ""
    states['payment_type_list'] = [];
    states["fromDate"] = Object.keys(this.state.selectedIpPatientDetails).length > 0 ? "" : today
    states["toDate"] = Object.keys(this.state.selectedIpPatientDetails).length > 0 ? "" : today,
    states["isclear"] = Object.keys(this.state.selectedIpPatientDetails).length > 0 ? false : true,
    states["selectedBillType"] = "",
    states["filterType"] = "";
    states["isDisable"] = true
      this.setState({
        states
      }, () => {
        this.setState({
          isclearInt: 1
        })
        this.OverallCommonFilter()
      })
    
  }

  onPressButton(key, value) {
    if (key == "search") {
      this.getBillingList()
    }
    else if (key == "clear") {
      this.clearSearchData()
    }
    else if (key == "print") {
      this.printBillingList()
    }
  }

  renderFooterAction() {
    var actions = []
    if (this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber) {
      actions = [
        { label: "Search", value: "search" },
        { label: "Clear", value: "clear" },
      ]
    } else {
      actions = [
        { label: "Search", value: "search" },
        { label: "Print", value: "print" },
        { label: "Clear", value: "clear" },
      ]
    }
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -1 }}>
        {
          actions.map((item, index) => {
            return (
              <CommonButton
                // disable={this.state.isLocked}
                item={{}}
                selectedvalue={{}}
                butttonText={item.label}
                buttonType={item.value == "search" ? "theme" : "outlineTheme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={item.value} />
            )
          })
        }
      </View>
    )
  }

  OverallCommonFilter = () => {
    return(
      <View>
      { this.state.isclear && this.state.isclearInt === 0?
        null 
        : 
        this.state.isclear && this.state.isclearInt === 1?
        <CommonDateFilter
          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all" }
          startDate={this.state.fromDate}
          clearPress={this.state.isclear}
          endDate={this.state.toDate}
          filterDate={this.state.filterDate}
          defaultSelectedDateFilter={"Today"}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
        : 
        <CommonDateFilter
          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all" }
          startDate={this.state.fromDate}
          clearPress={this.state.isclear}
          endDate={this.state.toDate}
          filterDate={this.state.filterDate}
          defaultSelectedDateFilter={"Today"}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
        }
      </View>
      
    )
  }

  render() {
    return (

      <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
        <View style={{ width: "65%", height: screenHeight - 65, }}>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={

              this.props.isIpPatientCheckouted ?

                navigationHeaderList :

                this.state.isCurrentPageIsIPPatientAppointment ?
                  navigationHeaderListIfIPCreateBill :
                  navigationHeaderList}
            selectedTab={"ipallTransactions"} />
          <ScrollView
            style={{ width: "100%", height: screenHeight - 65, }}
            bounces={false}
            bouncesZoom={false}
          >

            <View style={[Style.leftView, { width: "100%", backgroundColor: color.applicationBackgroundColor }]}>
              <View style={{ marginTop: 20, width: "100%", backgroundColor: color.white, paddingVertical: 20, paddingHorizontal: 10, marginHorizontal: 10 }}  >

                {this.state.billingIPnumber ?
                  <View >
                    <Text style={{ fontSize: 16, color: color.themeDark, margin: 13 }}>IP Number : {this.state.billingIPnumber}</Text>
                    <CommonHistorySectionHeader
                      heading1={"Date & Bill Number"}
                      heading2={"Patient Name"}
                      // heading3={"IP Number"}
                      heading3={"Paid Amount"}
                      noOfColumn={3}
                      columnSize={[0.4, 0.4, 0.2]}
                    />
                  </View>
                  :

                  this.state.isCurrentPageIsIPPatientAppointment ?
                    <CommonHistorySectionHeader
                      heading1={"Adminssion Date"}
                      heading2={"Ip Number"}
                      // heading3={"IP Number"}
                      heading3={"Paid Amount"}
                      noOfColumn={3}
                      columnSize={[0.4, 0.4, 0.2]}
                    />
                    :
                    <CommonHistorySectionHeader
                      heading1={"Date & Bill Number"}
                      heading2={"Name"}
                      heading3={"IP Number"}
                      heading4={"Doctor Name"}
                      heading5={"Paid Amount"}
                      noOfColumn={5}
                      columnSize={[0.24, 0.3, 0.23, 0.25, 0.15]}
                    />
                }

                {
                  this.state.isCurrentPageIsIPPatientAppointment ?
                    this.showBillingList() :
                    this.showBillingList()
                }

                {
                  this.state.allBillingTransactionList.length > 10 ?
                    <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.allBillingTransactionList} />
                    : null
                }
              </View>
            </View>
          </ScrollView>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}
          style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade }} >
          <View style={[Style.rightView, { padding: 20, width: "100%" }]}>
            {this.renderOverAllBillStatus()}
            <View style={{marginBottom: 15, marginTop: 20, backgroundColor: "rgb(238, 238, 238)", paddingVertical:".5vw" }}>
              <Text style={{ paddingLeft:".5vw", fontSize: 16, fontWeight: '500'}}>{"Filters"}</Text>
            </View>
            {!this.state.billingIPnumber && this.state.isCurrentPageIsIPPatientAppointment ? null :
              <View style={{ width: 200, justifyContent: "space-between", zIndex: 1, paddingLeft:".5vw" }}>
                <Text style={{ marginBottom: 8 }}>{"Date"}</Text>
                {
                  Object.keys(this.state.selectedIpPatientDetails).length > 0 ? 
                  <CommonDateFilter
                  // removeObject={""}
                  startDate={this.state.fromDate}
                  endDate={this.state.toDate}
                  filterDate={this.state.filterDate}
                  defaultSelectedDateFilter={"All"}
                  showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />
                  : 

                  this.OverallCommonFilter()
                  
                }
              </View>
            }
            <View style={{ flexDirection: "row", marginTop:"1vw", paddingLeft:".5vw" }}>
              {this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber ?
                  null : <View style={{ width: 200, zIndex: -1 }}>
                    {this.renderTextBox("Bill Number", "billNumber", this.state.billNumber)}
              </View>}

              {this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber ?
                  null : <View style={{ width: 200, marginLeft: 20, zIndex: -1, marginTop:".10vw" }}>
                    <Text>Select Doctor</Text>
                    <Picker
                  style={{
                    borderColor: "#888888",
                    borderWidth: 1,
                    marginTop: '0.45vw',
                    height: 35,
                    borderWidth: 1,
                    borderRadius: '0.2vw',
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: '0.65vw',
                    paddingLeft: '0.65vw',
                    paddingRight: '0.65vw',
                    width: 175,
                    marginRight: 0 ,
                    zIndex: -1,
                    fontSize:'0.91vw',
                    backgroundColor: "rgb(248, 252, 255)"
                  }}
          selectedValue={this.state.doctor_id}
          onValueChange={(itemValue) => {
            let states = this.state
            states.doctor_id = itemValue;
            this.setState({
              doctor_id: JSON.parse(JSON.stringify(states.doctor_id))
              })
        }}
        >
          <Picker.Item label={"All Doctors"}  value={""} />
          {this.state.doctorData.map((doc) => {
                    return(
          <Picker.Item label={doc.first_name + " " + doc.initial}  value={doc.doctor_id} />
          )})}
        </Picker>
              </View>}
              
            </View>
            <View style={{ flexDirection: "row", marginTop: 20, zIndex: -1, paddingLeft:".5vw" }}>
              <View>
              {this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber ?
                <View style={{ width: 200 }}>
                  {this.renderTextBox("IP Number", "billNumber", this.state.billNumber)}
                </View> : !this.state.billingIPnumber ? <View style={{ width: 200, flexDirection:"column" }}>
                  {this.renderTextBox("Search", "patientName", this.state.patientName, "Pt Name/Acc.no/Mob no")}
                  <View style={{marginTop:5, flexDirection: "row", alignItems:"center"}}>
                  <CheckBox 
                  style={{ width:13, height:13 }}
                  value={this.state.isAcNumClicked}
                  onValueChange={() => {
                      this.setState({
                      isAcNumClicked: !this.state.isAcNumClicked,
                      patientName: ""
                      })
                  }}
                  />
                  <Text style={{ fontSize:12, color: color.grayShade, marginLeft: 5 }}>{"Acc.no search"}</Text>
                  </View>
                </View> : null }
                </View>
                <View style={{marginLeft: 20}}>
                {this.renderTextBox("Paid Amount", "billAmount", this.state.billAmount)}
                </View>
            </View>
            
            {this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber ?
              null :
              <View>
              {this.renderPaymentType()}
              {this.renderFilterType()}
              </View>}
            {/* <View style={{ flex: 1, flexDirection: "column" }}> */}
              <View>
                {this.state.isCurrentPageIsIPPatientAppointment && !this.state.billingIPnumber ?
                null :
                this.renderBillTypeView()}
              </View>
              <View>
                {this.state.billingIPnumber ?
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    {this.renderTextBox("Refund Amount", "refundAmount", this.state.refundAmount)}
                    <View style={{ flex: 0.5 }}>
                      <TouchableOpacity
                        onPress={() => this.refundamountsave()}
                        disabled={this.props.isIpPatientCheckouted}
                        style={{
                          height: 35, backgroundColor: color.themeDark, borderRadius: 4,
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20
                        }} >
                        <Text style={{ color: "#FFF" }}>{"Refund"}</Text>
                      </TouchableOpacity>
                    </View>
                  </View> : null}
              </View>
              <View>
                {this.renderFooterAction()}
              </View>
            {/* </View> */}
          </View>
        </ScrollView>
      </View>
    );
  }


  refundamountsave() {
    var data = {
      "patient_id": this.state.selectedIpPatientDetails.patient_id,
      "ip_admission_id": this.state.indudvalIPAdminID,
      "refund_amount": this.state.refundAmount
    }
    var service_url = Constants.REFUND_AMOUNT;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.refundamountsaveSuccess,
      this.refundamountsaveFailure
    );
  }

  refundamountsaveSuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);
      this.setState({
        refundAmount: "",
        isRefundEditable: false
      })
      this.getBillingList()
    } else {
      this.props.showResposeValue('error', success.message);
    }
  }
  refundamountsaveFailure = error => {
    this.props.showResposeValue('error', error.message);
  }

  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  printBillingList = () => {
    this.setState({
      isclear: false,
      isclearInt: 0
    })
    let states = this.state;
    var payment_type_value = "";
    var patient_name = "";
    var mobile_number = "";
    var account_number = "";
    for (var i = 0; i < states.payment_type_list.length; i++) {
      if (i == 0)
        payment_type_value += "&payment_type=" + states.payment_type_list[i];
      else if (i == 1)
        payment_type_value += "&payment_type1=" + states.payment_type_list[i];
      else
        payment_type_value += "&payment_type2=" + states.payment_type_list[i];
    }

    if (states.patientName) {
      if(this.state.isAcNumClicked){
        account_number = states.patientName
      }else if(!Constants.REG_EX_MOBILENO.test(states.patientName)){
        mobile_number = states.patientName
      }else{
        patient_name = states.patientName
      }
    }

    if (this.state.billingIPnumber) {
      serviceUrl = Constants.IP_PATIENT_BILLING_TRANSECTION_LIST +
        "?admission_id=" + this.state.indudvalIPAdminID +
        "&export=PDF" +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + patient_name +
        "&mobile_number=" + mobile_number +
        "&patient_account_number=" + account_number +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        payment_type_value +
        "&bill_type=" + this.state.selectedBillType +
        "&status=saved";
      } else {
      var serviceUrl = Constants.IP_ALLBILLING_TRANSACTION + "?export=PDF" +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + patient_name +
        "&mobile_number=" + mobile_number +
        "&patient_account_number=" + account_number +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        payment_type_value +
        "&bill_type=" + this.state.selectedBillType +
        "&status=saved";
      }

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.successprintBillingList,
      this.failureprintBillingList,
      "pdf"
    );
  }

  successprintBillingList = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  failureprintBillingList = (error) => {

    console.log(error);
  }

  getBillingList = async () => {

    // IP_ADMISSION_ALLBILLING_TRANSACTION
    this.setState({
      isclear: false,
      isclearInt: 0
    })

    var serviceUrl = "";
    let states = this.state;
    var payment_type_value = "";
    var patient_name = "";
    var mobile_number = "";
    var account_number = "";
    for (var i = 0; i < states.payment_type_list.length; i++) {
      var paymentType = returnPaymentMode(states.payment_type_list[i])
      if (i == 0) {
        if (i == 0 && this.state.filterType == false && this.state.isDisable == false) {
          var paymentTypes = returnPaymentMode(states.payment_type_list[0] + states.payment_type_list[1])
          payment_type_value += "&payment_type=" + parseInt(paymentTypes.paymentMode);
        } else {
          payment_type_value += "&payment_type=" + parseInt(paymentType.paymentMode);
        }
      }
      else if (i == 1 && this.state.filterType == true)
        payment_type_value += "&payment_type1=" + parseInt(paymentType.paymentMode);
    }

    if (states.patientName) {
      if(this.state.isAcNumClicked){
        account_number = states.patientName
      }else if(!Constants.REG_EX_MOBILENO.test(states.patientName)){
        mobile_number = states.patientName
      }else{
        patient_name = states.patientName
      }
    }

    if (this.state.billingIPnumber) {
      serviceUrl = Constants.IP_PATIENT_BILLING_TRANSECTION_LIST +
        "?admission_id=" + this.state.indudvalIPAdminID +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + patient_name +
        "&mobile_number=" + mobile_number +
        "&patient_account_number=" + account_number +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        payment_type_value +
        "&bill_type=" + this.state.selectedBillType +
        "&status=saved" + 
        "&selected_paymenttype_only=" + this.state.filterType;
    }
    else if (this.state.isCurrentPageIsIPPatientAppointment) {
      let patient_id = states.selectedIpPatientDetails.patient_id  ? states.selectedIpPatientDetails.patient_id : states.selectedIpPatientDetails.id
      let admit_id = states.selectedIpPatientDetails.id  ? states.selectedIpPatientDetails.id : states.selectedIpPatientDetails.admit_id
      serviceUrl = Constants.IP_PATIENT_ALLBILLING_TRANSACTION +
        "?patient_id=" + patient_id +
        "&from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&ip_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        "&admission_id=" + admit_id +
        "&status=saved";
    } else {
      serviceUrl = Constants.IP_ALLBILLING_TRANSACTION +
        "?from_date=" + states.fromDate +
        "&to_date=" + states.toDate +
        "&patient_name=" + patient_name +
        "&mobile_number=" + mobile_number +
        "&patient_account_number=" + account_number +
        "&bill_number=" + states.billNumber +
        "&amount=" + states.billAmount +
        payment_type_value +
        "&bill_type=" + this.state.selectedBillType +
        "&status=saved" + "&doctor_id=" + states.doctor_id +
        "&selected_paymenttype_only=" + this.state.filterType;
    }


    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getBillingListSuccess,
      this.getBillingListFailure
    );
  }

  getBillingListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        allBillingTransactionList:[],
        isBillingListSuccess: true
      })
      if (this.state.billingIPnumber) {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_bill_amount ? success.data.total_bill_amount : 0,
          totalCashAmount: success.data.total_paid_amount ? success.data.total_paid_amount : 0,
          totalCardAmount: success.data.total_balance ? success.data.total_balance : 0,
          refundAmount: success.data.total_balance ? success.data.total_balance : 0,
          totalBankTransferAmount: success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount: success.data.upi ? success.data.upi : 0,
          totalInsuranceAmount: success.data.total_insurance_amount ? success.data.total_insurance_amount : 0,
          totalChequeAmount: success.data.total_cheque_amount ? success.data.total_cheque_amount : 0
        })
      }
      else if (this.state.isCurrentPageIsIPPatientAppointment) {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_bill_amount ? success.data.total_bill_amount : 0,
          totalCashAmount: success.data.total_paid_amount ? success.data.total_paid_amount : 0,
          totalCardAmount: success.data.total_balance ? success.data.total_balance : 0,
          totalBankTransferAmount: success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount: success.data.upi ? success.data.upi : 0,
          totalInsuranceAmount: success.data.total_insurance_amount ? success.data.total_insurance_amount : 0,
          totalChequeAmount: success.data.total_cheque_amount ? success.data.total_cheque_amount : 0
        })
      }
      else {
        this.setState({
          allBillingTransactionList: success.data.result ? success.data.result : [],
          totalAmount: success.data.total_amount ? success.data.total_amount : 0,
          totalCashAmount: success.data.cash_amount ? success.data.cash_amount : 0,
          totalCardAmount: success.data.card_amount ? success.data.card_amount : 0,
          totalBankTransferAmount: success.data.bank_transfer ? success.data.bank_transfer : 0,
          totalUPIAmount: success.data.upi ? success.data.upi : 0,
          totalInsuranceAmount: success.data.total_insurance_amount ? success.data.total_insurance_amount : 0,
          totalChequeAmount: success.data.total_cheque_amount ? success.data.total_cheque_amount : 0
        })
      }
    }else{
      this.props.showResposeValue('error', success.message);
    }
    // console.log("suss getBillingListSuccess" + JSON.stringify(success))
  }
  getBillingListFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }

  showBillingList() {
    if (this.state.allBillingTransactionList && this.state.allBillingTransactionList.length < 11) {
      if (this.state.billingIPnumber || this.state.isCurrentPageIsIPPatientAppointment) {
        return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {

          if (this.state.billingIPnumber) {

            return this.showIPPatientIndudvalBills(bill, index);
          }

          else if (this.state.isCurrentPageIsIPPatientAppointment) {

            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }

        })
      } else {
        return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {

          if (this.state.billingIPnumber) {

            return this.showIPPatientIndudvalBills(bill, index);
          }

          else if (this.state.isCurrentPageIsIPPatientAppointment) {

            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }

        })
      }
    }

    else if (this.state.allBillingTransactionList && this.state.allBillingTransactionList.length > 0) {
      if (this.state.billingIPnumber || this.state.isCurrentPageIsIPPatientAppointment) {
        return this.state.allBillingTransactionList && this.state.allBillingTransactionList.map((bill, index) => {

          if (this.state.billingIPnumber) {

            return this.showIPPatientIndudvalBills(bill, index);
          }

          else if (this.state.isCurrentPageIsIPPatientAppointment) {

            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }

        })
      } else {
        return this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((bill, index) => {

          if (this.state.billingIPnumber) {

            return this.showIPPatientIndudvalBills(bill, index);
          }

          else if (this.state.isCurrentPageIsIPPatientAppointment) {

            return this.showPatientBills(bill, index);
          } else {
            return this.showBills(bill, index);
          }

        })
      }
    } else if( this.state.isBillingListSuccess && this.state.allBillingTransactionList.length > 0 ){
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
        <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
      </View>
    }
  }

  showBills(bill, index) {
    // alert("krish1")

    // console.log("******************************> "+JSON.stringify(bill))
    var payment_type = paymentReturnType(bill.payment_type)
    return (
      <TouchableOpacity
        // disabled={bill.status != "canceled" ? false : true}
        onPress={() => {
          this.setState({
            invoiceID: bill.billing_id,
            BillStatus : bill.status
          }, () => {
            this.getPatientBillIndudvalBillingTransection()
          })
        }}
      >
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>

          <View style={{ flex: 0.24, marginHorizontal: 20 }}>
            <Text style={{ marginBottom: 5, fontSize: 15 }}>{bill.invoice_date}</Text>
            <Text style={{ fontSize: 15 }}>{"#" + bill.invoice_number}</Text>
          </View>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.3 }]}>{bill.patient_name}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.23 }]}>{bill.ip_number}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.25 }]}>{bill.doctor_name ? bill.doctor_name : bill.nurse_id ? "Nursing" :  ""}</Text>
          {bill.status && bill.status != 'canceled' ?
            <View style={{ flex: 0.15, justifyContent: "center" }}>
              <Text style={{ marginBottom: 5, fontSize: 14 }}>{bill.amount}</Text>
              <Text style={{ fontSize: 12 }}>{payment_type.paymentType2 ? payment_type.paymentType1 + " and " + payment_type.paymentType2 : payment_type.paymentType1}</Text>
            </View>
            : (bill.status && bill.status == 'canceled') ?
              <View style={{ flex: 0.15, justifyContent: "center" }}>
                <Text style={{ marginBottom: 5, fontSize: 14 }}>{bill.amount}</Text>
                <Text style={{ fontSize: 12, color: '#FF0000' }}>{"Cancelled"}</Text>
                <Text style={{ fontSize: 12, color: '#FF0000' }}>{bill.cancellation_reason}</Text>
              </View>
              : null}
        </View>
      </TouchableOpacity>

    );
  }

  showPatientBills(bill, index) {

    return (
      <TouchableOpacity
        // disabled={bill.status != "canceled" ? false : true}
        onPress={() => {
          this.setState({
            billingIPnumber: bill.ip_number,
            indudvalIPAdminID: bill.admission_id,

          }, () => {
            this.clearSearchData()
            this.getBillingList()
          })
        }}>
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>


          <Text style={[Style.historyHeaderFontStyle, { flex: 0.4, marginHorizontal: 20 }]}>{bill.admission_date ? moment(bill.admission_date).format("DD-MM-YYYY") : null}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.4 }]}>{bill.ip_number}</Text>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.2 }]}>{bill.amount}</Text>
        </View>
      </TouchableOpacity>

    );
  }


  showIPPatientIndudvalBills(bill, index) {
    var payment_type = paymentReturnType(bill.payment_type)
    var advance_payment = bill.advance_payment ? bill.advance_payment : false
    return (
      <TouchableOpacity
        // disabled={bill.status != "canceled" ? false : true}
        onPress={() => {
          this.setState({
            invoiceID: bill.billing_id,
            BillStatus : bill.status
          }, () => {
            this.getPatientBillIndudvalBillingTransection()
          })
        }}
      >
        <View key={index} style={{ alignItems: 'center', flexDirection: 'row', paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 0.5 }}>

          <View style={{ flex: 0.4, marginHorizontal: 20 }}>
            <Text style={[Style.historyHeaderFontStyle, {}]}>{bill.invoice_date ? moment(bill.invoice_date).format("DD-MM-YYYY") : null}</Text>

            <Text style={[Style.historyHeaderFontStyle, { marginTop: 10 }]}>{bill.invoice_number}</Text>

          </View>
          <Text style={[Style.historyHeaderFontStyle, { flex: 0.4 }]}>{bill.patient_name}</Text>
          {bill.status && bill.status != 'canceled' ?
            <View style={{ flex: 0.2, justifyContent: "center" }}>
              <Text style={{ marginBottom: 5, fontSize: 14 }}>{bill.amount}</Text>
              <Text style={{ fontSize: 12 }}>{payment_type.paymentType2 ? advance_payment ? payment_type.paymentType1 + " & " + payment_type.paymentType2 + " - " + "Advance Payment" : payment_type.paymentType1 + " & " + payment_type.paymentType2  : advance_payment ? payment_type.paymentType1 + " - " + "Advance Payment"  : payment_type.paymentType1}</Text>
            </View>
            : (bill.status && bill.status.toLowerCase() == 'canceled') ?
              <View style={{ flex: 0.2, justifyContent: "center" }}>
                <Text style={{ marginBottom: 5, fontSize: 14 }}>{bill.amount}</Text>
                <Text style={{ fontSize: 12, color: '#FF0000' }}>{"Cancelled"}</Text>
                <Text style={{ fontSize: 12, color: '#FF0000' }}>{bill.cancellation_reason}</Text>
              </View>
              :null}
        </View>
      </TouchableOpacity>

    );
  }
}

const styles = StyleSheet.create({
  calendarView: {
    borderColor: color.lightGray,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  calendarText: {
    color: color.lightGray,
    width: "90%"
  },
  headingBackGround: {
    backgroundColor: color.appointmentTableHead,
    paddingHorizontal: 25,
    paddingVertical: 10
  },
  dasboardcardcontentstyle: {
    flexDirection: "row",
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: "white",
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 4,
    height: 60,
    minWidth: 120,
    paddingVertical: 7.5,
    paddingHorizontal: 10
  },
  emed_IPbill_sideheader: {
    fontWeight: "500", fontSize: 16, paddingLeft:".5vw"
  },
  emed_content_container: {
    flexDirection: "row", marginVertical: 15, paddingLeft:".8vw"
  },
  emed_radio_btn_txt: {
    fontWeight: "500", fontSize: 13
  },
  emed_radio_btn: {
    marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
  },
  emed_radio_btn_touchablearea: {
    marginRight: 20, flexDirection: "row", alignItems: "center"
  },
})