import React from "react";
import {
  View,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  Text,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import Icon from "react-native-vector-icons/FontAwesome";
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'

const platform = Platform.OS;
const pageName = "ocular"
export default class OcularHistoryNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
        isLocked:this.props.patientAppointment.is_locked,
        patientAppointment: this.props.patientAppointment,
        favoriteDrugs: [],
        selectedEye: {},
        ocularHistorySelectedItems:[],

        id: null,
        drug_name: "",
        years: "",
        months: "",
        days: '',
        complaints: "",
        comments: "",

        isEdit: false,
        newItem: '',
        successOrErrorMessage:false,
        successMessageFlag:false,
        errorMessageFlag:false,
        isDisable:false
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getFavoriteDrugs();
  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;


    if(props.editItem) {
      
      if(props.editItem.title === pageName) {
       
        let item = props.editItem.item;
        
        if(id !== item.id) {
        // console.log("edited item----------> "+JSON.stringify(props.editItem))
          
          // selectedEye have to set up here
          let selectedEye = "";

          favoriteDrugs.map((favItem)=>{
            if(favItem.label===item.drug_name){
              // selectedEye = favItem.value;
              selectedEye = favItem;
            }
          })

          this.setState({
            id: item.id,
            selectedEye: selectedEye,
            drug_name: item.drug_name,
            years: item.years,
            months: item.months,
            days: item.days,
            comments: item.comments
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
      
    return (
        <SelectedButton
        //   {...otherProps}
            item={item} 
            fill={fill} 
            borderNeeded={borderNeeded}
            onPressItem={this.onPressAction.bind(this, selectedKey)}
            selected={selectedValue} 
        />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;
    
    this.setState({
        states
    })
  }

  getFavoriteDrugs = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_DRUG;

    OpthamologyService.getInstance().getComplaints(
      service_url, 
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
      );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];

      response.data.map((item) => {

        drugs.push({ value: item, label: item });
      });

      var field = this.state;
      field["favoriteDrugs"] = drugs;
      this.setState({ field });

    }
  }

  addComplaint = event => {

    let errorMessage = "";

    this.setState({
      isDisable:true
    })
    
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_OCULAR_MEDICATION_GET;
    
    let drug_name = "";

    states.favoriteDrugs.map((item)=>{

      if( item.value === states.selectedEye.value ) {

        drug_name = item.label;
      }
    });

    // alert(states.id)

    let data = {
        "id": states.id?states.id:null,
        "appointment_id": this.state.patientAppointment.appointment_id,
        // "drug_name": drug_name,
        "years": states.years || 0,
        "months": states.months || 0,
        "days": states.days,
        "comments": states.comments
    };


    if(states.id){
      data["medications"]=[drug_name];
    }else{
      var datas=[]
      for(var i=0;i<states.ocularHistorySelectedItems.length;i++){
        datas.push(states.ocularHistorySelectedItems[i].value)
      }
      data["medications"]=datas;
    }
    
    // validate
    if(!data["medications"] || data["medications"].length===0) {    
      errorMessage = "Please choose medication";
    } 
    else if((!states.years || parseInt(states.years)===0) && (!states.months || parseInt(states.months)===0) && (!states.days || parseInt(states.days) === 0)){    
      errorMessage = "Please enter month or year or days";
    } 
    
    // throw warning
    if(errorMessage)
    {
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error", errorMessage);
    }else{
        
        OpthamologyService.getInstance().postComplaints(
        service_url, 
        data,
        this, 
        this.getMedicationSuccess,
        this.getMedicationFailure
        );
    }
  };

  showSuccessMessage = () => {
   
    this.setState({ successOrErrorMessage: true,successMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true,errorMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , errorMessageFlag: false });
    }, 2000);
    
  };


  getMedicationSuccess = response => {
    //console.log("success ---->"+(JSON.stringify(response)))
    //alert("complaint created successfully")
   
    if (response.status === "success") {
      this.setState({
        isDisable:false
      })
      this.props.clearEditedItem(pageName,{})

      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });

      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
      this.props.showResposeValue("success",response.message)
    }else {
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error",response.message)
    }
  };

  getMedicationFailure = error => {
    this.setState({
      isDisable:false
    })
  this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error",error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states["ocularHistorySelectedItems"]=[];
    states['selectedEye']={};
    states['drug_name']="";
    states['years']="";
    states['months']="";
    states['days']="";
    states['comments']="";
    states["id"] = "";

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  // renderIconBtn = (icon, text, createBtn) => {
  //   return (
  //     <TouchableOpacity onPress={this.triggerNewItemAdd.bind(this)}>
  //     <View
  //       style={{
  //         height: 35,
  //         width: text === "New" ? 100 : 103,
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: createBtn || text === "New" ? 20 : 8,
  //         flexDirection: "row",
  //         backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themePink,
  //         justifyContent: "space-evenly",
  //         borderWidth: createBtn || text === "New" ? 1 : 0,
  //         borderColor: text === "New" ? "#848484" : color.white
  //       }}
  //     >
  //       <Icon name={icon} size={platform === "web" ? 15 : 20} color= {text === "New" ? "#848484" :"#ffffff"} />
  //       <Text
  //         style={{
  //           fontSize: platform === "web" ? 10 : 13,
  //           color: text === "New" ? "#848484" : color.white,
  //           fontWeight: "700"
  //         }}
  //       >
  //         {text}
  //       </Text>
  //     </View>
  //     </TouchableOpacity>
  //   );
  // };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  addMedicationToList(){

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if(newItem) {
      
      favoriteDrugs.push({ value: newItem, label: newItem });
      
      field["favoriteDrugs"] = favoriteDrugs;
      field["selectedEye"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;
      
      this.setState({ field });
    }
  }


  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" || key == "ocularHistorySelectedItems") {

      if (this.state.id) {
        var { selectedEye } = this.state;
        selectedEye = selectedvalue;
        this.setState({ selectedEye: selectedvalue })
        // alert(JSON.stringify(selectedEye))
      } else {
        var { ocularHistorySelectedItems } = this.state;

        var getSelectedValue;
        var spliceData;

        if (ocularHistorySelectedItems.length > 0) {
          for (var i = 0; i < ocularHistorySelectedItems.length; i++) {
            if (ocularHistorySelectedItems[i].value == selectedvalue.value) {
              spliceData = ocularHistorySelectedItems[i];
              ocularHistorySelectedItems.splice(i, 1)
              var data = this.removeDuplicate(ocularHistorySelectedItems);
              this.setState({ ocularHistorySelectedItems: data })
              return
            } else {
              if (spliceData && spliceData.value !== selectedvalue.value) {
              } else {
                getSelectedValue = selectedvalue
              }
            }
          }

          if (getSelectedValue) { ocularHistorySelectedItems.push(selectedvalue); }
        } else {
          ocularHistorySelectedItems.push(selectedvalue)
        }

        var data = this.removeDuplicate(ocularHistorySelectedItems);
        this.setState({ ocularHistorySelectedItems: data })

      }
    }
  }


  render() {

    // console.log("this.state.isEdit --- " + this.state.isEdit)

    return (
      <TouchableWithoutFeedback onPress={this.addMedicationToList.bind(this)}>
        <View>
       {/* {this.renderSubHeadingBorder("Ocular History", false, true)} */}
      
       <DoctorNotesCommonRightSideHeader title={"Medication"} clearAllData={this.clearComplaint.bind(this)}/>

       {/* <CommonSectionHeader heading={"Medication"}  /> */}
       {this.renderSubHeadingBorder("", false)}

        {/* {this.renderSubHeadingBorder("Medication")} */}

        {/* <FlatList
          data={this.state.favoriteDrugs}
          numColumns={3}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)} 
        //   extraData={this.state.selectedEye}
        /> */}

        {/* {this.renderTitleWithMultipleBtn("Medication", this.state.favoriteDrugs, 3, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), true, true)} */}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.favoriteDrugs.map((item, index) => {
            // var data = {
            //     label: item.speciality_name,
            //     value: item.id
            // }
            var data
            if(this.state.id){
              data = this.state.selectedEye
            }else{
              data = this.state.ocularHistorySelectedItems
            }

            // console.log("json item----> "+JSON.stringify(item))
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={data}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={this.state.id ? "selectedEye" :"ocularHistorySelectedItems"} />
              </View>
            )
          })
        }
        </View>
        {this.state.isEdit?
        <View
        style={styles.MedicationView}
      ><TextInput
        underlineColorAndroid="transparent"
        placeholder="type here"
        placeholderTextColor={color.black}
        style={[styles.MedicationTextInput, {
          color: color.black,
          borderColor: color.black,},Style.allButtonBorderRadius]}
        ref={text => (this.nameInput = text)}
        // defaultValue=""
        autoCapitalize="none"
        value={this.state.newItem}
        onChangeText={text => this.setState({ selectedEye:'',newItem: text })}          
       onSubmitEditing={()=>{this.addMedicationToList()}}
      /></View>:
      // this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this))
      <TouchableOpacity style={{alignSelf:'flex-start'}}  onPress={() => {this.triggerNewItemAdd()}} disabled={this.state.isLocked}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
      }

         <View style={{ flexDirection: "row", marginTop: '1.2vw' }}>
           <View style={styles.YearView}>
             <Text style={{fontSize:'0.9vw'}}>Year</Text>
           </View>  
           <View style={styles.MonthView}>
             <Text style={{fontSize:'0.9vw'}}>Months</Text>
           </View>  
           <View style={styles.MonthView}>
             <Text style={{fontSize:'0.9vw'}}>Days</Text>
           </View>  
          </View>
        <View
          style={styles.YearandMonthView}
        >

          {this.renderNumberTxtWithHeader( "", "years", this.state.years, this.state.isLocked)}    

          {this.renderNumberTxtWithHeader( "", "months", this.state.months, this.state.isLocked )}

          {this.renderNumberTxtWithHeader( "", "days", this.state.days, this.state.isLocked )}

          {/* {this.renderNumberTxtWithHeader( "Days", "days", this.state.days || 0 )} */}
          
        </View>

        {/* {this.renderTextFieldView("Comments")} */}
        <CommonSectionHeader heading={"Comments"}  />
       <View style={{marginTop:'1.2vw'}}>
        {this.renderTextFieldView("", "comments", this.state.comments, true, 4,'',{},"",false,this.state.isLocked)}
       </View> 
        
        <View style={{ marginTop: '1.2vw' ,alignSelf:"center" }}>
          <TouchableOpacity disabled={(this.state.isDisable || this.state.isLocked) ? true : false} onPress={this.addComplaint.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
        <View>
        {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                        <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Oscular History Created Successfully' : 
                    this.state.errorMessageFlag ?'Oscular History Failed' : null}</Text>
                </View>
              ) :null} */}
        </View >
      </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw'
      },
      MedicationView: {
        height: '2vw',
        marginTop: '0.6vw',
        // backgroundColor: color.white,
        width: '10.6vw'
      },
      MedicationTextInput: {
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        // borderRadius: 20,
        borderWidth: 1,
        alignSelf:'flex-start',
        fontSize: '0.9vw'
      },
      YearView: {
        backgroundColor:"#DCDCDC",
        width:'5.5vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center"
      },
      MonthView: {
        backgroundColor:"#DCDCDC",
        width:'7vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center",
        marginLeft:'0.6vw'
      },
      YearandMonthView: {
        flexDirection: "row",
        marginTop: '0.6vw',
        marginBottom: '2vw'
        // , justifyContent: "space-evenly"
      }
})