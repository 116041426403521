import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "educationHistory"


export class EducationHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            detailsOfstartschool: "",
            detailsOfcurrentEducation: "",
            detailsOfSchool: "",
            acadamicPerfomance: "",
            selectedsyllabus: "",
            detailsOfCurricular: "",
            detailsOfpeerRelation: "",
            speciallschoolFlag: false,
            speciallschoolDetails: "",

        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states['id'] = data.id,
                    states['detailsOfstartschool'] = data.age_school_started
                states['detailsOfcurrentEducation'] = data.current_level_education
                states['detailsOfSchool'] = data.school_name_details
                states['acadamicPerfomance'] = data.quality_academic_performance
                states['selectedsyllabus'] = data.syllabus
                states['detailsOfCurricular'] = data.extra_curricular
                states['detailsOfpeerRelation'] = data.peer_relationship
                states['speciallschoolFlag'] = data.attend_special_school
                states['speciallschoolDetails'] = data.attend_special_school_details
                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })


            }
        }
    }

    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        dropDownList, selectedDropDownlistKey, selectedDropDownlist,
    ) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // drop down list 
                dropDownList={
                    dropDownList
                }
                selectedDropDownlist={selectedDropDownlist}
                dropDownListKey={selectedDropDownlistKey}
                onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // label with button select 


            />
        )
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    onChangeDropDownVallue(selectedDropDownValue, dropDownKey) {
        var states = this.state;
        states[dropDownKey] = selectedDropDownValue;
        this.setState({ states })

    }


    clearAllData() {
        var states = this.state;

        states['id'] = ""
        states['detailsOfstartschool'] = ""
        states['detailsOfcurrentEducation'] = ""
        states['detailsOfSchool'] = ""
        states['selectedsyllabus'] = ""
        states['detailsOfCurricular'] = ""
        states['acadamicPerfomance'] = ""
        states['detailsOfpeerRelation'] = ""
        states['speciallschoolFlag'] = false
        states['speciallschoolDetails'] = ""
        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "age_school_started": states.detailsOfstartschool,
                "current_level_education": states.detailsOfcurrentEducation,
                "school_name_details": states.detailsOfSchool,
                "quality_academic_performance": states.acadamicPerfomance,
                // "education_qualification": states.detailsOfstartschool,
                "syllabus": states.selectedsyllabus ? states.selectedsyllabus : 0,
                // "syllabus_others": states.detailsOfstartschool,
                "extra_curricular": states.detailsOfCurricular,
                "peer_relationship": states.detailsOfpeerRelation,
                "attend_special_school": states.speciallschoolFlag,
                "attend_special_school_details": states.speciallschoolDetails
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_EDUCATION_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePastHistorySuccess,
                this.savePastHistoryFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Education History already added please click on edit icon to update the values")

        }
    }

    savePastHistorySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePastHistoryFailure = error => { }

    render() {


        const historyDropDownList = [
            { label: "Select", value: 0 },
            { label: "State Board", value: 1 },
            { label: "Matriculation", value: 2 },
            { label: "CBSE", value: 3 },
            { label: "ICSC", value: 4 },
            { label: "Angloindian", value: 5 }
        ]

        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Education"} clearAllData={this.clearAllData.bind(this)} />

                {this.renderHistoryComponent(
                    "Age when started school", "Enter details",
                    "", false,
                    "detailsOfstartschool", this.state.detailsOfstartschool
                )}
                {this.renderHistoryComponent(
                    "Current level of education", "Enter details",
                    "", false,
                    "detailsOfcurrentEducation", this.state.detailsOfcurrentEducation
                )}
                {this.renderHistoryComponent(
                    "School Name & Details", "Enter details",
                    "", false,
                    "detailsOfSchool", this.state.detailsOfSchool
                )}
                {this.renderHistoryComponent(
                    "Quality of Academic performance", "Enter details",
                    "", false,
                    "acadamicPerfomance", this.state.acadamicPerfomance
                )}
                {this.renderHistoryComponent(
                    "Syllabus", "Enter details",
                    "", false,
                    "", "",
                    historyDropDownList, "selectedsyllabus", this.state.selectedsyllabus

                )}
                {this.renderHistoryComponent(
                    "Extra Curricular", "Enter details",
                    "", false,
                    "detailsOfCurricular", this.state.detailsOfCurricular
                )}
                {this.renderHistoryComponent(
                    "Peer relationship", "Enter details",
                    "", false,
                    "detailsOfpeerRelation", this.state.detailsOfpeerRelation
                )}



                {this.renderHistoryComponent(
                    "Attending special school", "Enter details",
                    "speciallschoolFlag", this.state.speciallschoolFlag,
                    "speciallschoolDetails", this.state.speciallschoolDetails
                )}



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


            </View>
        )
    }
}
