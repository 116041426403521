import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "EffectsOnOccupation"


export class EffectsOnOccupation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofEffectsOnOccupation: {},

            hideEditIcon: false,
            isheading: false
        }
    }

    componentDidMount() {
        this.getEffectsOnOccupation()
    }

    getEffectsOnOccupation() {
        var service_url = Constants.PSY_FAMILY_OCCUPATIONAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getEffectsOnOccupationSuccess,
            this.getEffectsOnOccupationFailure
        );
    }

    getEffectsOnOccupationSuccess = success => {

        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    var prepareData = {};
                    if (key == "drinking_while_work") {
                        prepareData = {
                            key: "Drinking While on work",
                            value: data[key] ? "Yes" : "No",
                            details: data.drinking_while_work_detail
                        }
                    } else if (key == "absenteism") {
                        prepareData = {
                            key: "Absenteeism",
                            value: data[key] ? "Yes" : "No",
                            details: data.absenteism_detail
                        }
                    } else if (key == "others") {
                        prepareData = {
                            key: "Others",
                            value: data[key] ? "Yes" : "No",
                            details: data.others_detail
                        }
                        // }else if (key == "time_spent_family") {
                        //     prepareData = {
                        //         key: "Time spent with the family",
                        //         value: data[key]? "Yes" : "No",
                        //         details: data.time_spent_family_desc
                        //     }
                    } else if (key == "loss_of_job") {
                        prepareData = {
                            key: "Loss of job",
                            value: data[key] ? "Yes" : "No",
                            details: data.loss_of_job_detail
                        }
                    }

                    booleanValues.push(prepareData)

                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading: Object.keys(data).length > 0 ? true : false,
                getListofEffectsOnOccupation: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getEffectsOnOccupationFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getEffectsOnOccupation();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name, this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName, this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofEffectsOnOccupation, pageName)
        }, 100);

    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Effects on occupation"}
                editImage={this.state.hideEditIcon || Object.keys(this.state.getListofEffectsOnOccupation).length == 0 ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if (item.value) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
