import React, { Component } from 'react'
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet, Picker } from "react-native";
import { Tooltip } from 'antd';
import { color } from "../../../../../styles/Color";
import moment from "moment";
import CommonDateFilter from '../../../BaseComponent';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import { Select } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import ImagePath from '../../../../../utils/ImagePaths';

const GroupByList = [
    { lable: "Corporate", value: "Corporate" },
    { lable: "Bill Date", value: "Bill_date" }
]

export default class EmpBillReport extends Component {
    constructor(props) {
        super(props);
        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");

        this.state = {
            groupBy: 'corporate',
            SelectedList: [],
            filterDate: {},
            filterData: {
                fromDate: converted_date,
                toDate: converted_date
            },
            disableSelect: false,
        };
    }

    componentDidMount() {
        this.getCorporateList()
    }

    handleChange = (value) => {
        this.setState({
            SelectedList: value
        })
    };

    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterData } = this.state;
        states["filterData"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate
        this.setState({ states, filterData })
    }

    getCorporateList = () => {
        var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getCorporateListSuccess,
            this.getCorporateListFailure
        );

    }

    getCorporateListSuccess = (success) => {
        this.setState({
            List: success.data.emp_details
        })
    }
    getCorporateListFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }

    /* Export Excel API start*/
    ExportExcel = () => {
        try {
            var serviceUrl = Constants.CORPORATE_BILLING_POST +
                "?export=excel" +
                "&employer=" + JSON.stringify(this.state.SelectedList) +
                "&from_date=" + this.state.filterData.fromDate +
                "&to_date=" + this.state.filterData.toDate;
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                this.getExcelSuccess,
                this.getExcelFailure,
                "excel"
            );
        }
        catch (error) {
            console.log(error)
        }
    }
    getExcelSuccess = (success) => {
        if (success.statusText === "OK") {
            var pom = document.createElement('a');
            var csvContent = success.data; //here we load our csv data 

            let filename = success.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }
    getExcelFailure = (error) => {
            this.props.ErrorAlert("No Data Available !")
    }
    /* Export Excel API end*/

    Printpdf = () => {
        try {
            var serviceUrl = Constants.CORPORATE_BILLING_POST +
                "?export=pdf" +
                "&employer=" + JSON.stringify(this.state.SelectedList) +
                "&from_date=" + this.state.filterData.fromDate +
                "&to_date=" + this.state.filterData.toDate;
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                this,
                this.getPrintSuccess,
                this.getPrintFailure,
                "pdf"
            );
        }
        catch (error) {
            console.log(error)
        }

    }

    getPrintSuccess = (response) => {
        if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    };


    getPrintFailure = error => {
        this.props.ErrorAlert("Something Went Wrong.")
    };

    render() {
        return (
            <View style={styles.TabContentMain}>

                <View style={styles.TabInnerView}>

                    <View style={styles.PickerWrapper}>
                        <Text style={styles.LabelText}>Group By</Text>
                        <View>
                            <Picker
                                selectedValue={this.state.groupBy}
                                style={styles.PickerDropDownView}
                                itemStyle={{ fontSize: '0.9vw' }}
                                onValueChange={(itemvalue) => {
                                    this.setState({
                                        groupBy: itemvalue
                                    }, () => {
                                        if (this.state.groupBy == 'Corporate') {
                                            this.setState({
                                                disableSelect: false
                                            }, () => { this.getCorporateList() })
                                        }
                                        else if (this.state.groupBy == 'Bill_date') {
                                            this.setState({
                                                disableSelect: true,
                                                List: [],
                                                SelectedList: [],
                                            })
                                        }
                                    })
                                }}

                            >
                                {
                                    GroupByList.map((list) => (
                                        <Picker.Item label={list.lable} value={list.value} />
                                    ))
                                }
                            </Picker>
                        </View>
                    </View>
                    <View style={styles.PickerWrapper}>
                        <Text style={styles.LabelText}>Select</Text>
                        <View>
                            <Select
                                disabled={this.state.disableSelect}
                                mode="multiple"
                                allowClear
                                placeholder="Select Corporates"
                                value={this.state.SelectedList}
                                maxTagTextLength={10}
                                maxTagCount={5}
                                onChange={this.handleChange}
                                style={{
                                    marginTop: '0.4vw',
                                    borderRadius: 4,
                                    borderColor: "#CDD1D5",
                                    // height: '2.1vw',
                                    width: "15vw",
                                    zIndex: -1,
                                    fontSize: '0.9vw',
                                    marginLeft: '1.5vw'
                                }}
                            >
                                {this.state.List && this.state.List.map((list) => (
                                    <Option value={list.id} >{list.company_name}</Option>
                                ))}
                            </Select>
                        </View>
                    </View>
                    <View style={styles.DatePickerStyle}>
                        <CommonDateFilter
                            defaultSelectedDateFilter={"Today"}
                            startDate={this.state.filterDate.fromDate}
                            endDate={this.state.filterDate.toDate}
                            clearPress={this.state.isclear}
                            showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                            filterDate={this.state.filterDate}
                            futureDateDisable={true} />
                    </View>

                </View>
                <View style={styles.ButtonMainView}>
                    <TouchableOpacity
                        onPress={() => {
                            this.ExportExcel()
                        }}
                        style={styles.BtnTouch}>
                        {/* <Icon name="file-excel-o" size={"1vw"} color={color.white}/> */}
                        <Image
                            source={ImagePath.Export}
                            style={styles.IconImageStyle}
                            resizeMode="contain"
                        />
                        <Text style={styles.ButtonText}>{"Export As Excel"}</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                        onPress={() => {
                            this.Printpdf()
                        }}
                        style={styles.BtnTouch}>
                        <Text style={styles.ButtonText}>{"Print As PDF"}</Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        )
    }
}

const styles = StyleSheet.create({
    IconImageStyle: {
        height: '2vw',
        width: '1.5vw',
        color:"white"
      },
    TabContentMain: {
        width: '100%',
        height: '20vw',
    },
    TabInnerView: {
        height: '10vw',
        display: 'flex',
        flexDirection: 'row',
        width: '50vw',
        marginTop: '-1vw',
        alignItems: 'center'
    },
    PickerDropDownView: {
        marginTop: '0.4vw',
        borderRadius: 4,
        borderColor: "#CDD1D5",
        height: '2.1vw',
        width: "15vw",
        zIndex: -1,
        fontSize: '0.9vw',
        marginLeft: '1.5vw'
    },
    PickerWrapper: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '3vw'
    },
    LabelText: {
        fontSize: '0.9vw',
        fontWeight: '500'
    },
    BtnTouch: {
        paddingTop: '0.2vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.2vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '11vw',
        marginRight: '2vw',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw",
        fontWeight: '500',
        marginLeft:'0.5vw'
    },
    ButtonMainView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        zIndex: -2
    }
})

