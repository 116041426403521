//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
const screenHeight = Dimensions.get("window").height;

import { DiabeticHistory } from './DiabeticHistory'
import { DiabeticHistoryNew } from './DiabeticHistoryNew'
import { ForWomen } from './ForWomen'
import { ForWomenNew } from './ForWomenNew'
import { BloodGlucose } from './BloodGlucose'
import { BloodGlucoseNew } from './BloodGlucoseNew'
import { NutritionHistory } from './NutritionHistory';
import { NutritionHistoryNew } from './NutritionHistoryNew';
import { PhysicalActivity } from './PhysicalActivity';
import { PhysicalActivityNew } from './PhysicalActivityNew';



export class DBHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            selectedSection: "isDiabeticHistory",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            scrollPosition: 0,
        }
    }
    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    showResposeValue(type, meaasge) {

        this.props.showResposeValue(type, meaasge)

        // this.setState({
        //     responseType: type,
        //     responseMeaasge: meaasge
        // }, () => {
        //     setTimeout(() => {
        //         this.setState({ responseType: "", responseMeaasge: "" });
        //     }, 3000);
        // })
    }

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            isDiabeticHistory: <DiabeticHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />,
            isForWomen: <ForWomenNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />,
            isBloodGlucose: <BloodGlucoseNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isNuturitionHistory: <NutritionHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />,
            isPhysicalActivity: <PhysicalActivityNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />

        }
        return (<View>
            {componentLookup[selectedComponent]}

        </View>);
    }






    // successAlert() {
    //     return (
    //         <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
    //             <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
    //                 <Success size={25} name={"check"} color={"green"} />
    //                 <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
    //                     <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
    //                 </Text>
    //             </View>
    //             <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
    //                 <Icon size={25} name={"close"} color={"#B2C6AA"} />
    //             </TouchableOpacity>
    //         </View>
    //     );
    // }

    // errorAlert() {
    //     return (
    //         <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
    //             <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
    //                 <Icon size={25} name={"ban"} color={"#D26560"} />
    //                 <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
    //                     <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
    //                 </Text>
    //             </View>
    //             <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
    //                 <Icon size={25} name={"close"} color={"#C69A99"} />
    //             </TouchableOpacity>
    //         </View>
    //     );
    // }



    render() {
        return (
            <View style={{ height: "50vw", flex: 1, flexDirection: "row", }}>
                <View style={[Style.leftView, { width: "65%", paddingHorizontal: 10, backgroundColor: color.applicationBackgroundColor }]}>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%",height:"10vw" }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        {/* {this.renderRightSideContent()} */}
                        <View style={Style.doctorNotesSectionView}>
                            <DiabeticHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            /></View>
                        <View style={Style.doctorNotesSectionView}>
                            <NutritionHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            />
                        </View>
                        <View style={Style.doctorNotesSectionView}>
                            <PhysicalActivity
                                selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            />
                        </View>

                            {
                                this.state.patientInfo.gender == "Female" ?
                                <View style={Style.doctorNotesSectionView}>

                                    <ForWomen selectedPageName={this.state.selectedSection}
                                        patientAppointment={this.state.patientAppointment}
                                        patientInfo={this.state.patientInfo}
                                        selectedRightSideView={this.selectedRightSideView.bind(this)}
                                        editHistory={this.editHistory.bind(this)}
                                        refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                        refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                    /></View> : null
                            }
                        <View style={Style.doctorNotesSectionView}>
                            <BloodGlucose selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            />
                        </View>
                    </ScrollView>
                </View>
                <View style={{
                    padding: 20, width: "35%", height: "44vw", backgroundColor: color.themeShade,
                    shadowColor: "#090F36",
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.6, shadowRadius: 3,
                    elevation: 5
                }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%"}}
                        contentContainerStyle={{ flex: 1 }}
                    >
                        {this.renderComponent()}
                    </ScrollView>
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
    },
});

//make this component available to the app

