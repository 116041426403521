import React, { useState, useEffect, useRef, Component, createRef } from 'react';
import { ActivityIndicator, Text, View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
// import { color } from 'react-native-reanimated';
import { color } from "../../styles/Color";
import Arrow from "react-native-vector-icons/MaterialCommunityIcons";
import * as Permissions from 'expo-permissions';

// import { render } from 'react-dom';
import BaseComponentStyle from "../doctor/BaseComponentStyle";

export default class TakePicture extends BaseComponentStyle {

    constructor(props) {
        super(props);

        this.cameraRef = React.createRef();
        this.state = {
            hasPermission: true,
            cameraRef: null,
            type: Camera.Constants.Type.back,
            patientPhoto: [],
            profilePhoto: null,
            cameraFor: this.props.cameraFor
        };
    }

    async componentDidMount() {

        // const { status } = await Camera.requestPermissionsAsync();
        let { status } = await Permissions.askAsync(Permissions.CAMERA);
        
        this.setState({
            hasPermission: status === 'granted' ? true : false
        })
    }

    updateProfilePhoto(profilePhoto, facialPhotos, flag) {

        let {cameraFor}=this.state;
     //   console.log("cameraFor --- " + cameraFor)
        if(cameraFor==="profile"){
            this.props.updateProfilePhoto(profilePhoto, [], flag,true);
        }else{
            
            this.props.updateProfilePhoto(null, facialPhotos, flag);
        }
    }

    recognizePatientPhoto(profilePhoto){
        if(profilePhoto){
            this.setState({ showLoader: true }, ()=>
                this.props.recognizePatientPhoto(profilePhoto) 
            )
        }
    }

    renderWarningMessage(msg='No response') {
        return(
            <View style={styles.WarningMsgView}>
                <TouchableOpacity onPress={() => { this.props.closeCameraModal() }}>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                        <Arrow
                            name="arrow-left"
                            size={'1.5vw'}
                            color={color.black}
                            ></Arrow>
                        <Text style={styles.BackBtn}>Back</Text>
                    </View>
                </TouchableOpacity>
                <Text style={[styles.WarningMsgText, {color: color.red}]}>{msg}</Text>
            </View>
        )
    }

    render() {
        let { profilePhoto, patientPhoto, hasPermission, type, cameraFor } = this.state;
        return (
            <View>
                {hasPermission === null ? this.renderWarningMessage("No Permission")                    
                    : hasPermission === false ? 
                    this.renderWarningMessage("No access to camera")
                    : (profilePhoto && (cameraFor==="profile" || cameraFor==="detect")) ?
                            <View style={styles.CameraView}>
                                
                                <View style={styles.OpenCameraView}>
                                    <Arrow
                                    name="arrow-left"
                                    size={'1.5vw'}
                                    color={color.black}
                                    onPress={() => {
                                        this.props.closeCameraModal()
                                    }}></Arrow>
                                    <Text style={[styles.CameraText, { color:color.themeBackgroud }]}>{cameraFor==="detect"?"Face Recognition":"Profile Picture"}</Text>
                                </View>

                                <Image source={{ uri: profilePhoto }}
                                    style={styles.CameraImage}
                                    resizeMode="contain" ></Image>
                                
                                {this.state.showLoader && cameraFor!=="profile"?
                                <ActivityIndicator />:null}

                                <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                                    {cameraFor==="profile"?
                                    <TouchableOpacity
                                        // onPress={() => async () => {                            
                                        //     updateProfilePhoto(profilePhoto, false)                            
                                        // }}
                                        onPress={() => this.updateProfilePhoto(profilePhoto,[], false)}
                                    >
                                        <Text style={[styles.SetProfileText, {backgroundColor: color.themeBackgroud}]}>{"Set Profile"}</Text>
                                    </TouchableOpacity>
                                    :<TouchableOpacity onPress={() => {
                                        if(!this.state.showLoader)
                                            this.recognizePatientPhoto(profilePhoto)
                                    }} >
                                        <Text style={[styles.SetProfileText, {backgroundColor: color.themeBackgroud}]}>{"Process"}</Text>
                                    </TouchableOpacity>}

                                    <TouchableOpacity
                                        onPress={() => {
                                            //setProfilePhoto(null) 
                                            this.setState({ profilePhoto: null })
                                        }}>
                                        <Text style={[styles.RetakeBtnText, {color: color.themeBackgroud, borderColor: color.themeBackgroud}]}>{"Retake"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View> :
                            <View style={{ flex: 1, minHeight: '32.6vw' }}>

                                <View style={styles.OpenCameraView}>
                                    <Arrow
                                    name="arrow-left"
                                    size={'1.5vw'}
                                    color={color.black}
                                    onPress={() => {
                                        this.props.closeCameraModal()
                                    }}></Arrow>
                                    <Text style={[styles.CameraText, { color:color.themeBackgroud }]}>
                                        {this.state.cameraFor==="profile"?"Profile Picture":"Face Recognition"}</Text>
                                </View>
                                
                                <Camera style={styles.CameraViewContainer} type={type}
                                    // ref={ref => {
                                    //     // setCameraRef(ref);
                                    //     this.setState({ cameraRef:ref })
                                    // }}
                                    ref={this.cameraRef}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            backgroundColor: 'transparent',
                                            justifyContent: 'flex-end'
                                        }}>
                                        <TouchableOpacity
                                            style={{
                                                flex: 0.1,
                                                alignSelf: 'flex-end'
                                            }}
                                            onPress={() => {
                                                // setType(
                                                this.setState({
                                                    type: type === Camera.Constants.Type.back
                                                        ? Camera.Constants.Type.front
                                                        : Camera.Constants.Type.back
                                                })
                                                // );
                                            }}>
                                            {/* <Text style={{ fontSize: 18, marginBottom: 10, color: 'white' }}> Flip </Text> */}
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ alignSelf: 'center' }} onPress={async () => {
                                            if (this.cameraRef) {

                                                if(this.state.cameraFor==="profile" || this.state.cameraFor==="detect"){

                                                    let photoResponse = await this.cameraRef.current.takePictureAsync();
                                                    
                                                    let obj = photoResponse; //JSON.parse(photoResponse)
                                                    if (obj.base64) {

                                                        // setProfilePhoto(obj.base64)                                                                                                      
                                                        this.setState({ profilePhoto: obj.base64 })
                                                    }
                                                    
                                                // } else if(){

                                                } else{
                                                    // patientPhoto=[];
                                                    for(var j=0;j<5;j++){
                                                        if(patientPhoto.length<5){

                                                            let obj = await this.cameraRef.current.takePictureAsync();
                                                            if (obj.base64) {
                                                                patientPhoto.push(obj.base64);
                                                            }                                                            
                                                        }
                                                    }
                                                    this.updateProfilePhoto(null, patientPhoto, true);
                                                }
                                            }
                                        }}>
                                            <View style={{
                                                borderWidth: 2,
                                                borderRadius: "50%",
                                                borderColor: 'white',
                                                height: '3.4vw',
                                                width: '3.4vw',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                            >
                                                <View style={{
                                                    borderWidth: 2,
                                                    borderRadius: "50%",
                                                    borderColor: 'white',
                                                    height: '2.6vw',
                                                    width: '2.6vw',
                                                    backgroundColor: 'white'
                                                }} >
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </Camera>
                            </View>}
            </View>
        )
    }

}
const styles = StyleSheet.create({
        WarningMsgView: { 
            flexDirection: "column", 
            justifyContent: "flex-start", 
            alignContent: "flex-start", 
            marginVertical: '1vw' 
        },
        BackBtn: { marginTop: '0.2vw' },
        WarningMsgText: { 
            flex: 1, 
            marginVertical: '1vw', 
            alignSelf: "center" 
        },
        CameraView: { 
            flex: 1, 
            minHeight: '32.6vw', 
            flexDirection: "column", 
            justifyContent: "flex-start", 
            alignContent: "center" 
        },
        OpenCameraView: { 
            flexDirection: "row", 
            justifyContent: "flex-start", 
            alignContent: "center", 
            marginVertical: '1vw' 
        },
        CameraText: {
            marginLeft: '1vw',
            fontSize: '1vw'
        },
        CameraImage: { 
            height: '25.3vw', 
            width: '29vw', 
            alignSelf: "center" 
        },
        SetProfileText: {
            fontSize: '1.1vw', 
            marginBottom: '0.6vw', 
            color: 'white', 
            paddingHorizontal: '1vw', 
            paddingVertical: '0.5vw',
            borderRadius: 4, 
            marginRight: '0.1vw'
        },
        RetakeBtnText: {
            fontSize: '1.1vw', 
            marginBottom: '0.8vw',
            paddingHorizontal: '1vw', 
            paddingVertical: '0.4vw',
            borderRadius: 4, 
            borderWidth: 1, 
            marginLeft: '0.1vw',
            height: '2.5vw',
        },
        CameraViewContainer: { 
            flex: 1, 
            height: '25.3vw', 
            padding: '1vw' 
        }
})