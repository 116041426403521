import React, { Component } from 'react';
import { View, Text, StyleSheet, Picker, Dimensions, TouchableOpacity, TextInput } from 'react-native';
import { color } from '../../../../../../styles/Color';
import Pagination from '../../../../common/Pagination';
import { NavigationTopHeader, CommonButton, CommonHistorySectionHeader } from '../../../../BaseComponent';
import OpthamologyService from '../../../../../../network/OpthamologyService';
import { Constants } from '../../../../../../utils/Constants';
import Style from '../../../../../../styles/Style';
import AsyncStorage from '../../../../../../AsyncStorage';
import CommonDateFilter  from '../../../../BaseComponent';
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';

const screenHeight = Dimensions.get("window").height
const navigationHeader = [
  { label: "Drug Listing", value: "drugList" },
  { label: "Purchase Indent Listing", value: "purchaseIndent" }
]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export class PurchaseIndentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        durgList: [],
        drugListAfterPagination: [],
        current_page: 1,
        records_per_page: 10,
        selectedStatus: "",
        isAdminAccess: false,
        searchIndent: "",
        filterDate: {
          fromDate: converted_date,
          toDate: converted_date
        }
    };
  }
  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getPurchaseIndentList()
  }
  getPurchaseIndentList = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      drugListAfterPagination: [],
      durgList: []
    })
    var url = Constants.OT_PURCHASE_INDENT_LIST + "?status=" + this.state.selectedStatus + "&indent_number=" + this.state.searchIndent + "&from_date=" + this.state.filterDate.fromDate + "&to_date=" + this.state.filterDate.toDate
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getPurchaseIndentSuccess,
        this.getPurchaseIndentFailure
      );
  }

  getPurchaseIndentSuccess = response => {
      if (response && response.status == "Success") {
          this.setState({
            durgList: response.data
          })
      } else {
        this.props.showResponseValue("error", response.message)
      }
  }
  getPurchaseIndentFailure = error => {
    this.props.showResponseValue("error", error.message)

  }
  renderDurgListHeader = () => {
    return(
      <CommonHistorySectionHeader
      heading1={"S.No"}
      heading2={"Date"}
      heading3={"Intent Number"}
      heading4={"No of items"}
      heading5={"Status"}
      columnSize={[0.1,0.2, 0.2, 0.25, 0.25]}  //total value is == of 1
      noOfColumn={5}
    />
    )
  }
  renderDrugList = () => {
    var { current_page, records_per_page } = this.state
    return(
      <View style={{ zIndex: -1 }}>
      { this.state.drugListAfterPagination && this.state.drugListAfterPagination.length > 0 ?
        this.state.drugListAfterPagination && this.state.drugListAfterPagination.map((item, index) => {
          let sno = (current_page-1)*records_per_page + index + 1;
          return (
 
            <TouchableOpacity style={styles.dataContainer} 
            onPress = { () => {
                this.props.renderSelectedTab("purchaseIndentDetail", item.indent_number)
            }}
            >   
              <View style={{ flex: 0.1, marginHorizontal: "1.5vw"}}><Text style={styles.TableDataText}>{sno}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.indent_date__date}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.indent_number}</Text></View>
              <View style={{ flex: 0.25 }}><Text style={styles.TableDataText}>{item.no_of_line_items}</Text></View>
              <View style={{ flex: 0.25 }}>
              <Tooltip placement="topLeft" title={item.comments ? item.comments : ""}>
               <Text style={[styles.TableDataText, { color: item.status === "Declined" ? color.red : item.status === "Approved" ? "#86DC3D" : color.black }]}>{item.status}</Text>
              </Tooltip> 
              </View> 
            </TouchableOpacity>

          )
        }):
         <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
          <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
         </View>
        }
    </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
  }

renderPaginationView() {
 return (
   <View style={styles.paginationContainer}>
     {
       this.state.durgList && this.state.durgList.length > 0 ?
         <Pagination
           paginationChangedValues={this.paginationChangedValues.bind(this)}
           totalItems={this.state.durgList}
           paginationCurrentPage={this.paginationCurrentPage.bind(this)}
         /> : null
     }
   </View>
 )
}
paginationChangedValues(data) {
 this.setState({
    drugListAfterPagination: data
 })
}
changeTabSection = (data) =>{
  return(
    this.props.renderSelectedTab(data)
  )
}
showSelectedFilteredDate(date) {
  var states = this.state.filterDate;
  states["filterDate"] = date
  states["fromDate"] = date.fromDate;
  states["toDate"] = date.toDate;
  this.setState({ states }, () => {
      this.getPurchaseIndentList()
  })
}
render() {
    return (
      <View style={ styles.container }>
       <View  style={styles.navigationContainer}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"purchaseIndent"}
        />
          <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end", alignItems: "flex-end", margin: "0.6vw" }}>
          <View style = {{ marginRight: "0.5vw", zIndex: 1 }}> 
            <CommonDateFilter
              startDate={this.state.filterDate.fromDate}
              endDate={this.state.filterDate.toDate}
              filterDate={this.state.filterDate}
              defaultSelectedDateFilter={"Today"}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              removeObject={"all"}
            />
            </View>
            <View style={{ flexDirection: "row", zIndex: 0, marginRight: "1vw" }}>
              <TextInput
                value={this.state.searchIol}
                onChangeText={(text) => {
                  this.setState({
                    searchIndent: text
                  })
                }}
                style={{ borderWidth: 1, fontSize: "0.9vw", height: "5vh", width: "11vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray }}
                placeholder="Search Intent Number..."
              />
              <TouchableOpacity
                style={{ height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                  this.getPurchaseIndentList()
                }}>
                <Icon name='search' size={"1.2vw"} color={color.white}></Icon>
              </TouchableOpacity>
            </View>
            
            <Picker
              selectedValue={this.state.selectedPharmacy}
              style={[
                Style.historyYesOrNoSectionShadow,
                Style.allButtonBorderRadius,
                { backgroundColor: color.white, height: "2.4vw", marginRight: "0.8vw", width: "8vw", fontSize: "1vw" }
              ]}
              itemStyle={{ fontSize: "0.8vw" }}
              onValueChange={(itemvalue) => {
                this.setState({
                  selectedStatus: itemvalue
                },() => {
                  this.getPurchaseIndentList()
                })
              }}
            >
              <Picker.Item label={"All"} value={""} />
              <Picker.Item label={"Indent Placed"} value={"Indent Placed"} />
              <Picker.Item label={"Approved"} value={"Approved"} />
              <Picker.Item label={"Declined"} value={"Declined"} />
            </Picker>
            { this.state.isAdminAccess ? 
             <TouchableOpacity
             style={styles.addVendor}
             onPress={() => {
              this.props.renderSelectedTab("drugIndent")
            }}
             >
             <Text style={styles.addVendorText}>+ Indent</Text>
           </TouchableOpacity>
           : null }
          </View>       
          </View>       
          {this.renderDurgListHeader()}
          {this.renderDrugList()}
          {this.renderPaginationView()}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
      margin: "0.4vw"
  },
  dataContainer: {
      flexDirection: 'row',
      borderBottomColor: color.lightGray,
      borderBottomWidth: 1,
      paddingVertical: "0.8vw",
      justifyContent: 'center',
      flex: 1
    },
    TableDataText:{
      fontSize:'1vw'
    },
    navigationContainer: {
      flexDirection: "row", flex: 1, justifyContent: "space-between"
    },
    paginationContainer: {
      marginHorizontal: "1.2vw"
    },
    addVendor: {
      backgroundColor: color.themeDark,
      borderRadius: 4,
      padding: "0.5vw",
    },
    addVendorText: {
      color: color.white,
      fontSize: "1vw"
    }
})
