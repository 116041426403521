//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton,CommonAddButton,HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
// create a component
const pageName="isForWomen"

export class ForWomen extends Component {

    constructor(props){
        super(props);
        this.state={
            selectedPageName:this.props.selectedPageName,

            patientAppointment:this.props.patientAppointment,
            patientInfo:this.props.patientInfo,
            listOfDatas: [],
            getListofForWomen: {},
            hideEditIcon :true
        }
    }

    componentDidMount(){
        this.getForWomen()
    }

    getForWomen(){
        var service_url = Constants.FOR_WOMEN_POST + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
          service_url,
          this,
          this.getForWomenHistorySuccess,
          this.getForWomenFailure
        );
    }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    getForWomenHistorySuccess = success =>{
       // console.log("success" + JSON.stringify(success))
        if(success.status == "success"){
            var data= success.data
            var booleanValues = []
            var stringVaues = []
            var hideEditIcon = true
            Object.keys(data).forEach((key) => {
                if(data){
                    hideEditIcon = false
                }
                if (typeof data[key] == "boolean") {
                    let prepareData = {}
                    if (key == "currently_pregnant") {
                        prepareData = {
                            key: "Are you currently pregnant?",
                            value: data[key] ? "Yes" : "No",
                        }
                    } else if (key == "baby_weight_4kgs_more") {
                        prepareData = {
                            key: "Have you ever had a baby who weighed more than 4 kgs?",
                            value: data[key] ? "Yes" : "No",
                            details: data.bg_check_frequency
                        }
                    } else if (key == "gestational_diabetes") {
                        prepareData = {
                            key: "Have you ever had gestational diabetes in the past?",
                            value: data[key] ? "Yes" : "No",
                            details: data.low_bg_date_time
                        }
                    }else if (key == "sexual_problems") {
                        prepareData = {
                            key: "Do you experience sexual problems?",
                            value: data[key] ? "Yes" : "No",
                            details: data.low_bg_date_time
                        }
                    }else if (key == "menopause_reached") {
                        prepareData = {
                            key: "Have you reached menopause?",
                            value: data[key] ? "Yes" : "No",
                            details: data.low_bg_date_time
                        }
                    }

                    
                    booleanValues.push(prepareData)
                } else{
                    let prepareData = {}
                    if (key == "birth_control_method") {
                        prepareData = {
                            key: "Birth Control Method",
                            value: data[key],
                        }
                    } else if (key == "number_of_kids") {
                        prepareData = {
                            key: "How many children do you have?",
                            value: data[key],
                        }
                    }  
                    stringVaues.push(prepareData)
                }
            })
            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

       //     console.log("success _________> " + JSON.stringify(removeEmptyObj))
            this.setState({
                hideEditIcon:hideEditIcon,
                getListofForWomen: data,
                listOfDatas: removeEmptyObj
            })

        }
    }
    getForWomenFailure = error =>{
        console.log("success _________> error"+JSON.stringify(error))

    }

    componentWillReceiveProps(props){
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getForWomen();
            this.props.refreshRighSideComponent("");
        }
    }
    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={3}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }

    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // setTimeout(() => {
        //     this.editHistory()
        // }, 100);
    }
    editHistory() {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofForWomen, pageName)
        }, 100);
       
    }
    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                     heading={"For Women"}
                     editImage={this.state.hideEditIcon ? "" :"pencil"}
                />
                    {this.renderContent()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
       
    },
});

//make this component available to the app
