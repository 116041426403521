import React, { Component } from 'react';
import { View, Text, ScrollView, TextInput, StyleSheet, TouchableOpacity, Image, Dimensions, Picker } from 'react-native';
import { CommonHistorySectionHeader } from '../../../../BaseComponent';
import { color } from './../../../../../../styles/Color';
import ImagePath from '../../../../../../utils/ImagePaths';
import moment from "moment";
import { Constants } from '../../../../../../utils/Constants';
import OpthamologyService from '../../../../../../network/OpthamologyService';
import Style from '../../../../../../styles/Style';
import Icon from "react-native-vector-icons/FontAwesome";
const screenHeight = Dimensions.get("window").height;

const tempRows = {
  "brand_name": "",
  "generic_name": "",
  "dosage_strength": "",
  "dosage_type": "",
  "quantity": "",
  "id": ""
}
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export class DrugIndent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indentDrugRow: [],
      indentDate: converted_date,
      pharmacyList: [],
      selectedPharmacy: "",
      brandNameSuggession: [],
      genericNameSuggession: [],
      selectedIndex: "",
      typeIndex: null
    };
  }
  componentDidMount() {
    this.getPharmacyList()
    this.getBrandNameSuggession()
  }
  getPharmacyList = () => {
   
    var url = Constants.OT_PHARMACY_LIST
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getPharmacyListSuccess,
        this.getPharmacyListFailure
      );
  }

  getPharmacyListSuccess = response => {
      if (response && response.status == "success") {
          this.setState({
            pharmacyList: response.data
          },()=>{
            this.setState({
              selectedPharmacy: this.state.pharmacyList[0].pharmacy_id
            })
          })
      }
  } 
  getPharmacyListFailure = (error) => {

  }
  getBrandNameSuggession = (brand_name) =>{
    var url = Constants.OT_PURCHASE_INDENT_SUGGEST + "?brand_name=" + brand_name + "&pharmacy_id=" + this.state.selectedPharmacy
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getBrandNameSuccess,
        this.getBrandNameFailure
      );
  }
  
  getBrandNameSuccess = response => {
    if (response && response.status == "success") {
        this.setState({
          brandNameSuggession: response.data
        })
    }
  } 

  getBrandNameFailure = (error) => {

  }
  getGenericNameSuggest = (brand_name) =>{
    var url = Constants.OT_GENERIC_NAME_SUGGEST + "?brand_name=" + brand_name + "&pharmacy_id=" + this.state.selectedPharmacy
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getGenericNameSuggessionSuccess,
        this.getGenericNameSuggessionFailure
      );
  }
  
  getGenericNameSuggessionSuccess = response => {
    if (response && response.status == "success") {
       var data = response.data
       var { indentDrugRow, typeIndex } = this.state
       if(data && data.length === 1) {
        indentDrugRow[typeIndex]["brand_name"] = data[0].drug_name;
        indentDrugRow[typeIndex]["generic_name"] = data[0].generic_name;
        indentDrugRow[typeIndex]["dosage_type"] = data[0].dosagetype__dosage_type;
        indentDrugRow[typeIndex]["dosage_strength"] = data[0].drug_strength;
        indentDrugRow[typeIndex]["id"] = data[0].id;
        this.setState({
          typeIndex: null,
          indentDrugRow
        })
       } else {
        this.setState({
          genericNameSuggession: response.data,
          
        })
       }
    }
  } 
  
  getGenericNameSuggessionFailure = (error) => {

  }
  renderIndentHeader = () => {
    return (
      <CommonHistorySectionHeader
        heading1={"S.No"}
        heading2={"Brand Name"}
        heading3={"Generic Name"}
        heading4={"Dosage Type"}
        heading5={"Dosage Strength"}  
        heading6={"Quantity"}
        heading7={""}
        columnSize={[0.05, 0.2, 0.2, 0.2, 0.16, 0.16, 0.03]}  //total value is == of 1
        noOfColumn={7}
      />
    )
  }
  renderTextInput = (stateKey, value, flex, index) => {
    var { indentDrugRow, typeIndex } = this.state
    return (
      <View style={{ flex: flex, height: "3.5vw"}}>
        <TextInput
          style={styles.textInput}
          value={value}
          keyboardType={ stateKey === "quantity" ? "numeric" : null  }
          editable= { stateKey === "brand_name" || stateKey === "quantity" ? true  : false}
          onChangeText={(text) => {
            var currentIndex = null
            if(stateKey === "brand_name"){
                currentIndex = index
                indentDrugRow[index]['id'] = '' 
                indentDrugRow[index]['generic_name'] = '' 
                indentDrugRow[index]['dosage_type'] = '' 
                indentDrugRow[index]['dosage_strength'] = '' 
                indentDrugRow[index]["quantity"] = ""
            }
            indentDrugRow[index][stateKey] = text;
            this.setState({
              indentDrugRow,
              typeIndex: currentIndex
            },()=>{
              this.getBrandNameSuggession(text)
            });

          }}
        />
        { index == typeIndex && indentDrugRow[index]["brand_name"] != "" && stateKey == "brand_name" && this.state.brandNameSuggession && this.state.brandNameSuggession.length > 0 ?
          <View style={{position: "absolute", width: "16vw", marginTop: "2.5vw", maxHeight: "18vw", zIndex: 1}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
          style={{
            flex: 1,
            backgroundColor: color.black, 
            borderRadius: 4, 
            paddingHorizontal: '1vw',
          }}
          >
            {this.state.brandNameSuggession && (this.state.brandNameSuggession).map((item) => ( 
            <View>
              <TouchableOpacity
              key={index}
              style={{marginTop: "0.5vw", marginBottom: "0.2vw"}}
              onPress={() => {
              indentDrugRow[typeIndex]["brand_name"] = item
                 this.setState({
                   indentDrugRow,
                   brandNameSuggession: []
                 },() => {
                  this.getGenericNameSuggest(item)
                 })
              
              }}
              >
                <Text style={{color: color.white, fontWeight: "bold", fontSize: "1vw"}}>{item}</Text>
              </TouchableOpacity>
            </View> 

            ))}

             </ScrollView>

           </View> : null }
           { index === typeIndex && stateKey == "generic_name" && indentDrugRow[index]["brand_name"] != "" && this.state.genericNameSuggession && this.state.genericNameSuggession.length > 1 ?

             <View style={{position: "absolute", width: "16vw", marginTop: "2.5vw", maxHeight: "25vw", zIndex: 1}}>
               <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                 style={{
                   flex: 1,
                   backgroundColor: color.black,
                   borderRadius: 4,
                   paddingHorizontal: "1vw",
                 }}
               >
                 { this.state.genericNameSuggession && (this.state.genericNameSuggession).map((item) => { 
                  return( 
                   <View>
                     <TouchableOpacity
                       key={typeIndex}
                       style={{ marginTop: 5, marginBottom: 2 }}
                       onPress={() => {
                                indentDrugRow[typeIndex]["brand_name"] = item.drug_name;
                                indentDrugRow[typeIndex]["generic_name"] = item.generic_name; 
                                indentDrugRow[typeIndex]["dosage_type"] = item.dosagetype__dosage_type; 
                                indentDrugRow[typeIndex]["dosage_strength"] = item.drug_strength;
                                indentDrugRow[typeIndex]["id"] = item.id;
                                this.setState({
                                  indentDrugRow,
                                  genericNameSuggession: [],
                                  typeIndex: null
                                })
                       }}
                     >
                       <Text style={{ color: color.white, fontWeight: "bold", fontSize: "1vw" }}>{item.display_name}</Text>
                     </TouchableOpacity>
                   </View>

                  ) })}

               </ScrollView>
       </View>
        : null }


      </View>
    )
  }
  _handleItemRemove = (i = null) => {
    let { indentDrugRow } = this.state;

    let indentDrugRows = indentDrugRow.slice(0, i).concat(indentDrugRow.slice(i + 1, indentDrugRow.length))
    this.setState({
      indentDrugRow: indentDrugRows
    })
}
  renderIndentTableView = () => {
    var { indentDrugRow } = this.state
    if (indentDrugRow.length < 10) {
      for (let i = indentDrugRow.length; i < 10; i++) {
        indentDrugRow.push(JSON.parse(JSON.stringify(tempRows)))
      }
    }

    return (
      <View>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '480px' }}>
          <View>
            {indentDrugRow && indentDrugRow.map((item, index) => {
              let zIndex = (index + 1) * -10;
              let sno = index + 1
              return (
                  <View style={{ flexDirection: "row", flex: 1, zIndex: zIndex, paddingHorizontal:'1.5vw', marginTop: "1vw" }} >
                    <View style={{ flex: 0.05 }}><Text style={{ fontSize: "1vw" }}>{sno}</Text></View>
                    {this.renderTextInput('brand_name', item.brand_name, 0.2, index)}
                    {this.renderTextInput('generic_name', item.generic_name, 0.2, index)}
                    {this.renderTextInput('dosage_type', item.dosage_type, 0.2, index)}
                    {this.renderTextInput('dosage_strength', item.dosage_strength, 0.16, index)}
                    {this.renderTextInput('quantity', item.quantity, 0.16, index)}
                    <TouchableOpacity onPress={() => { indentDrugRow[index]['brand_name'] ? this._handleItemRemove(index) : null }} style={{ flex: 0.03, justifyContent: "center", height: "2.5vw" }}>
                        <Icon name="close" size={"1.5vw"} color={"#000000"} />
                    </TouchableOpacity>
                  </View>
              )
            })}
          </View>
          <View style={styles.addItemButton}>
          <TouchableOpacity
            style={styles.addVendor}
            onPress={() => {
              this._addNewItem();
            }}
          >
            <Text style={styles.addVendorText}>Add Items</Text>
          </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    )

  }
  _addNewItem = () => {
    let { indentDrugRow } = this.state

    let current_row_drug = indentDrugRow.length;
    let bill_line_items_drug = [];
    let drugRows_no_manitory_drug = [];
    let add_flag = false;
    for (let i = 0; i < current_row_drug; i++) {

      if (indentDrugRow[i].brand_name && indentDrugRow[i].generic_name && indentDrugRow[i].dosage_strength && indentDrugRow[i].dosage_type && indentDrugRow[i].quantity) {
        bill_line_items_drug.push(indentDrugRow[i]);
      }
      else if (!indentDrugRow[i].brand_name || !indentDrugRow[i].generic_name || !indentDrugRow[i].dosage_strength || !indentDrugRow[i].dosage_type || indentDrugRow[i].quantity) {
        drugRows_no_manitory_drug.push(indentDrugRow[i])
      }
    }

    if ((current_row_drug - bill_line_items_drug.length) === 0) {
      add_flag = true;
    }

    if (add_flag) {

      let new_row = indentDrugRow.length + 5

      for (let i = current_row_drug; i < new_row; i++) {
        indentDrugRow.push(JSON.parse(JSON.stringify(tempRows)));
      }

      this.setState({
        indentDrugRow,
      })
    }
    else if (drugRows_no_manitory_drug.length > 0) {
      console.log("error")
    }

  }
  onPressSave = () => {
    var { indentDrugRow } = this.state
    var service_url = ""
    let current_row = indentDrugRow.length;
    let bill_line_items = [];
    var isManitory = ""
    var isDuplicate = false

    for (let i = 0; i < current_row; i++) {

      if (indentDrugRow[i].brand_name) {

        bill_line_items.push(indentDrugRow[i]);

      } else if(!indentDrugRow[i].brand_name && indentDrugRow[i].quantity) {
        isManitory = "Please enter the details to proceed"

      }
    }

      service_url = Constants.OT_PURCHASE_INDENT_POST
      var data = {
        "pharmacy_id": this.state.selectedPharmacy,
        "indent_date": this.state.indentDate,
        "ot_indent": bill_line_items
      }

   
    var check_id = bill_line_items && bill_line_items.length > 0 && bill_line_items.map(item => { return item.id })
    isDuplicate = check_id && check_id.length > 0 && check_id.some((item, index) => (
      check_id.indexOf(item) != index
    ))

    for (let i = 0; i < bill_line_items.length; i++) {
      if (!bill_line_items[i].brand_name) {
        isManitory = "Please enter the drug name";
      } else if (!bill_line_items[i].generic_name) {
        isManitory = "Please enter the generic name"
      } else if (!bill_line_items[i].dosage_type) {
        isManitory = "Please enter the dosage type"
      } else if (!bill_line_items[i].dosage_strength) {
        isManitory = "Please enter the dosage strength"
      } else if (!bill_line_items[i].quantity) {
        isManitory = "Please enter the quantity"
      } else if(bill_line_items[i].quantity <= 0){
        isManitory = "Invalid quantity"
      }
    }

    if (isDuplicate) {
      this.props.showResposeValue("error", "Same drug repeated multiple times")
    } else if (isManitory) {
      this.props.showResposeValue("error", isManitory)
    } else if (bill_line_items && bill_line_items.length == 0) {
      this.props.showResposeValue("error", "Please enter the details to proceed")
    } else {
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.onPressSaveIndentSuccess,
      this.onPressSaveIndentFailure
    );
   }
  }
  onPressSaveIndentSuccess = (response) => {
    if (response.status === "Success") {
      this.props.showResposeValue("success", response.message)
      this.setState({
        indentDrugRow: []
      })
    }
   }
   onPressSaveIndentFailure = (error) => {
    this.props.showResposeValue("error", error.message)

   }

  renderSubHeaderView = () => {
    return (
      <View style={styles.SubContainer}>
        <View style={styles.subContainerView}>
          <TouchableOpacity
            style={styles.addVendor}
            onPress={() => {
              this.props.renderSelectedTab("purchaseIndent");
            }}
          >
            <View style={styles.subContainerView}>
              <Image
                source={ImagePath.BackArrow}
                style={styles.Header_BackIcon}
              />
              <Text style={styles.addVendorText}>Back</Text>
            </View>
          </TouchableOpacity>
          <Text style={styles.mainHeaderTexr}>Add Purchase Indent</Text>
          <View style={styles.dateMainContainer}>
            <Text style={styles.HeaderText}>{"Date"}</Text>
            <TextInput
              placeholder={""}
              editable={false}
              value={moment(this.state.indentDate).format("DD-MM-YYYY")}
              // onChangeText={{}}
              style={styles.DateTextInput}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Picker
            selectedValue={this.state.selectedPharmacy}
            style={[
              Style.pickerView,
              Style.allButtonBorderRadius,
              {
                backgroundColor: color.white,
                height: "2vw",
                marginRight: "1vw",
                margin: "0.5vw",
              },
            ]}
            itemStyle={{ fontSize: "1vw" }}
            onValueChange={(itemvalue) => {
              this.setState({
                selectedPharmacy: itemvalue,
              });
            }}
          >
            {this.state.pharmacyList && this.state.pharmacyList.length == 0 ? (
              <Picker.Item label={"Select Pharmacy"} value={""} />
            ) : null}
            {this.state.pharmacyList &&
              this.state.pharmacyList.length > 0 &&
              this.state.pharmacyList.map((item, index) => {
                return (
                  <Picker.Item
                    key={index}
                    label={item.pharmacy__pharmacy_name}
                    value={item.pharmacy_id}
                  />
                );
              })}
          </Picker>
        </View>
      </View>
    );
  }
  renderBottomView = () => {
    return(
      <View style= {{ flexDirection: "row", flex: 1, justifyContent: "center", alignSelf: "center" }}>
         <TouchableOpacity
            style={styles.addVendor}
            onPress={() => {
              this.onPressSave();
            }}
          >
            <Text style={styles.addVendorText}>Save</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.addVendor, {marginLeft: "0.5vw"}]}
            onPress={() => {
              this.setState({
                indentDrugRow: []
              })
            }}
          >
            <Text style={styles.addVendorText}>Clear</Text>
          </TouchableOpacity>
      </View>
    )
  }
  render() {
    return (
      <View style={styles.container}>
        {this.renderSubHeaderView()}
        {this.renderIndentHeader()}
        {this.renderIndentTableView()}
        <View style={{ zIndex: -112 }}>{this.renderBottomView()}</View>

      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    margin: "0.4vw",
    height: screenHeight-200
  },
  textInput: {
    padding: "0.5vw",
    backgroundColor: color.white,
    borderColor: "#CDD1D5",
    borderRadius: "0.3vw",
    borderWidth: 1,
    fontSize: "1vw",
    width: "90%"
  },
  addVendor: {
    backgroundColor: color.themeDark,
    borderRadius: "0.4vw",
    // padding: "0.3vw",
    paddingHorizontal: "0.8vw",
    justifyContent: "center",
    alignSelf: "center",
    height: "2vw"
  },
  addVendorText: {
    color: color.white,
    fontSize: "0.87vw"
  },
  Header_BackIcon: {
    height: "1.5vw",
    width: "1.5vw",
  },
  HeaderText: { position: 'absolute', backgroundColor: color.white, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize: "0.85vw", zIndex: 10, marginTop: "0.2vw" },
  DateTextInput: { zIndex: 1, paddingLeft: "1vw", borderColor: color.lightGray, borderWidth: 1, marginTop: "0.8vw", borderRadius: "0.4vw", paddingVertical: "0.2vw", fontSize: "1vw" },
  SubContainer: { flexDirection: "row", justifyContent: "space-between", margin: "0.5vw"},
  addItemButton: { flex: 1, alignItems: "flex-end", alignSelf: "flex-end", alignContent: "flex-end", margin: "1.2VW" },
  dateMainContainer: { marginLeft: "1vw" },
  subContainerView: { flexDirection: "row" },
  mainHeaderTexr: { fontWeight: 'bold', fontSize: '1.2vw', color: color.themeDark, justifyContent: "center", alignSelf: "center", marginLeft: "0.5vw"}

})
