import { StylesContext } from '@material-ui/styles';
import React, { Component } from 'react';
import { TouchableOpacity, Text, View, StyleSheet, ScrollView } from 'react-native';
import { color } from "../../../../styles/Color";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import AsyncStorage from '../../../../AsyncStorage';


export default class DischargeSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DischargeSummeryData: [],
      patientAppointment: this.props.patientAppointment,
      DrType: ""
    }
  }
  async componentDidMount() {
    const userType = await AsyncStorage.getItem("service_type");
    this.GetDischargeSummeryData()
    this.setState({
      DrType : userType
    })
  }
  GetDischargeSummeryData = () => {
    var serviceUrl = Constants.COLLAB_REPORT_DISCHARGE_SUMMARY + "?patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDataSuccess,
      this.getDataFailure
    );
  }

  getDataSuccess = (success) => {
    this.setState({
      DischargeSummeryData: success.data
    })
  }
  getDataFailure = (error) => {
    console.log(error.message)
  }

  renderDiagnosisData = (data) => {
    try {
      return (
        <View style={styles.TableRightCol}>
          {data && JSON.parse(data).length > 0 ?
            <View style={styles.MapView}>
              {JSON.parse(data).map((list, index) => (
                <Text style={[styles.TableDataText]}>{` ${index + 1}. ${list.icd_short_desc} ( ICD : ${list.icd_code}) , `}</Text>
              ))}
            </View>
            : <Text style={[styles.TableDataText]}>-</Text>}
        </View>
      )
    }
    catch {
      return (
        <View style={styles.TableRightCol}>
             <Text style={styles.RightText}>{data && data != null ? data : "-"}</Text>
        </View>
      )
    }

  }
  render() {
    return (
      <View style={{ width: '92vw' }}>
        <ScrollView horizontal showsHorizontalScrollIndicator={true}>

          <View style={{ display: 'flex', flexDirection: 'row', }}>
            {
              this.state.DischargeSummeryData && this.state.DischargeSummeryData.length > 0 ?
                this.state.DischargeSummeryData.map((item) => (
                  <View style={{ width: '40vw', marginRight: '2vw' }}>
                    <ScrollView style={styles.mainContainer}>

                      <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                        <View style={[styles.TableTitleContainer]}>
                          <View style={{ alignItems: "center", width: '50%', }} >
                            <Text style={styles.TableHeadText}>Admitted Date : {item.admitted_on}</Text>
                          </View>
                          <View style={{ alignItems: "center", width: '50%', }} >
                            <Text style={[styles.TableHeadText]}>Discharged Date : {item.discharged_on}</Text>
                          </View>
                        </View>
                      </View>

                      <View style={styles.leftView}>
                        <View style={styles.TableContainer}>

                          <View style={{ width: "39vw" }}>
                            <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw', width: '37.2vw' }]}>
                              <View style={{ alignItems: "left", width: '40%' }} >
                                <Text style={[styles.TableHeadText, { marginLeft: '3vw' }]}>Title</Text>
                              </View>
                              <View style={{ alignItems: "left", width: '60%' }} >
                                <Text style={[styles.TableHeadText, { marginLeft: '10vw' }]}>Values</Text>
                              </View>
                            </View>
                          </View>

                          <ScrollView showsVerticalScrollIndicator={false} style={styles.MainTable}>
                            <View style={styles.ColWrapper}>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Blood Group</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.blood_group && item.blood_group != null ? item.blood_group : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Date of Surgery</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.date_of_surgery && item.date_of_surgery != null ? item.date_of_surgery : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Reason For Admission</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.reason_for_admission && item.reason_for_admission != null ? item.reason_for_admission : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Diagnosis</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  {this.renderDiagnosisData(item.diagnosis)}
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Provisional Diagnosis</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.provisional_diagnosis && item.provisional_diagnosis != null ? item.provisional_diagnosis : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Procedure</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.procedure && item.procedure != null ? item.procedure : "-"}</Text>
                                </View>
                              </View>
                              {this.state.DrType == "OPT" ? null :
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>History of Present Illness</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.history_of_presenting_illness && item.history_of_presenting_illness != null ? item.history_of_presenting_illness : "-"}</Text>
                                </View>
                              </View>}
                              {this.state.DrType == "OPT" ? null :<View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>On Examination</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.on_examination && item.on_examination != null ? item.on_examination : "-"}</Text>
                                </View>
                              </View>}
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Investigation</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.investigation && item.investigation != null ? item.investigation : "-"}</Text>
                                </View>
                              </View>
                              {this.state.DrType == "OPT" ? null :<View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Treatment Given</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.treatment_given && item.treatment_given != null ? item.treatment_given : "-"}</Text>
                                </View>
                              </View>}
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Operation Notes</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.operation_notes && item.operation_notes != null ? item.operation_notes : "-"}</Text>
                                </View>
                              </View>
                              {this.state.DrType == "OPT" ? null :<View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Coures in Hospital</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.course_in_hospital && item.course_in_hospital != null ? item.course_in_hospital : "-"}</Text>
                                </View>
                              </View>}
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Condition on Discharge</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.condition_on_discharge && item.condition_on_discharge != null ? item.condition_on_discharge : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Advise on Discharge</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.advice_on_discharge && item.advice_on_discharge != null ? item.advice_on_discharge : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>{this.state.DrType === "OPT" ? "Medication" : "Prescription"}</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.medication && item.medication != null ? `Route - ${ JSON.parse(item.medication)[0] && JSON.parse(item.medication)[0].route ? JSON.parse(item.medication)[0].route : "-"}` : "-"}</Text>
                                  <Text style={[styles.RightText,{marginTop:".3vw"}]}>{item.medication && item.medication != null ? `Medicine Name - ${JSON.parse(item.medication)[0] && JSON.parse(item.medication)[0].medicine_name ? JSON.parse(item.medication)[0].medicine_name : "-"}` : "-"}</Text>
                                  <Text style={[styles.RightText,{marginTop:".3vw"}]}>{item.medication && item.medication != null ? `Dosage - ${JSON.parse(item.medication)[0] && JSON.parse(item.medication)[0].dosage ? JSON.parse(item.medication)[0].dosage : "-"}` : "-"}</Text>
                                  <Text style={[styles.RightText,{marginTop:".3vw"}]}>{item.medication && item.medication != null ? `Timings - ${JSON.parse(item.medication)[0] && JSON.parse(item.medication)[0].timings ? JSON.parse(item.medication)[0].timings : "-"}` : "-"}</Text>
                                  <Text style={[styles.RightText,{marginTop:".3vw"}]}>{item.medication && item.medication != null ? `Instructions - ${JSON.parse(item.medication)[0] && JSON.parse(item.medication)[0].instructions ? JSON.parse(item.medication)[0].instructions : "-"}` : "-"}</Text>
                                </View>
                              </View>
                              {this.state.DrType == "OPT" ? null :<View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Laboratory</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.laboratory_findings && item.laboratory_findings != null ? item.laboratory_findings : "-"}</Text>
                                </View>
                              </View>}
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Review On</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.review_on && item.review_on != null ? item.review_on : "-"}</Text>
                                </View>
                              </View>
                              <View style={styles.TableDataWrapper}>
                                <View style={styles.TableLeftCol}>
                                  <Text style={styles.LeftText}>Admitting Consultant</Text>
                                </View>
                                <View style={styles.TableRightCol}>
                                  <Text style={styles.RightText}>{item.admitting_consultant && item.admitting_consultant != null ? item.admitting_consultant : "-"}</Text>
                                </View>
                              </View>
                            </View>
                          </ScrollView>
                        </View>

                      </View>


                    </ScrollView>
                  </View>
                ))
                :
                <View style={styles.NoRecordsView}>
                  <Text style={styles.NoRecordsText}>No Records to show...</Text>
                </View>
            }
          </View>

        </ScrollView></View>
    )
  }
}

const styles = StyleSheet.create({
  LeftText: {
    fontSize: '0.9vw',
    fontWeight: '500'
  },
  RightText: {
    fontSize: '0.9vw',
    // paddingRight:'0.5vw',
    width: '21vw',
    textAlign: 'left'
  },
  mainContainer: {
    height: '70vh',
    borderWidth: 1,
    borderRadius: '0.2vw',
    marginRight: '2vw',
    display: 'flex',
  },
  leftView: {
    // width: '32vw',
    height: '58vh',
    marginLeft: '0.3vw'
  },
  TableContainer: {
    marginTop: '0.5vw',
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderTopLeftRadius: '0.26vw',
    borderTopRightRadius: '0.26vw',
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '37vw',
    borderRadius: 4,
    borderBottomWidth: 1,
    borderColor: color.sectionHeadingLiteColor,
    // height: "3vw",
    alignItems: "center",
    paddingTop: '1vw',
    paddingBottom: '1vw'
  },
  MainTable: {
    height: '59vh',
    width: '40vw'
  },
  TableLeftCol: {
    alignItems: "left", width: '40%', marginLeft: '1vw'
  },
  TableRightCol: {
    alignItems: "left", width: '58%',
  },
  ColWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw',
    width: '100%'
  },
  NoRecordsText: {
    fontSize: '1vw',
    marginLeft: "40vw"
  },
})
