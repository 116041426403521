//import liraries
import React, { Component } from 'react';
import { View, Text, Dimensions, StyleSheet, TouchableOpacity, ScrollView, Platform,Image } from 'react-native';
// const screenHeight = Dimensions.get("window").height;
// const screenWidth = Dimensions.get("window").width;
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import { color } from "../../../styles/Color";
// import Style from "../../../styles/Style";

const dateInstance = new Date();
const today = moment(dateInstance).format("YYYY-MM-DD");

const platform = Platform.OS;


export class Calender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTime: "",
            dropDownViewFlag: false,
            getMonth: "",
            getYear: "",
            getDate: 0,
            dropDownView: "",
            day1: 10,
            day2: 11,
            day3: 12,
            day4: 13,
            day5: 14,
            totalMonths: [
                { "month": "January", "totalDays": 31 },
                { "month": "February", "totalDays": 28 },
                { "month": "March", "totalDays": 31 },
                { "month": "April", "totalDays": 30 },
                { "month": "May", "totalDays": 31 },
                { "month": "June", "totalDays": 30 },
                { "month": "July", "totalDays": 31 },
                { "month": "August", "totalDays": 31 },
                { "month": "September", "totalDays": 30 },
                { "month": "October", "totalDays": 31 },
                { "month": "November", "totalDays": 30 },
                { "month": "December", "totalDays": 31 },
            ],
            changedMonthDays: 0,
            chengedMonthInNumber: 0,
            changedDay: 0,
            showNextAppoinmentTimer: false,
            hour12Formet: 0,
            minutes: 0,
            findAmPm: '',

        };

        var today = new Date();
        var date = today.getDate();
        var month = today.getMonth();
        var year = today.getFullYear();
        for (let i = 0; i < this.state.totalMonths.length; i++) {
            if (month === i) {
                this.state.chengedMonthInNumber = i + 1;
                this.state.changedMonthDays = this.state.totalMonths[i].totalDays;
                this.state.getMonth = this.state.totalMonths[i].month;
                this.state.getYear = year;
                this.state.getDate = date;

                if(date == 2 || date == 1){
                    this.state.day1 = 1;
                    this.state.day2 = 2;
                    this.state.day3 = 3;
                    this.state.day4 = 4;
                    this.state.day5 = 5;
                // }else if(){
                //     this.state.day1 = date - 2;
                //     this.state.day2 = date - 1;
                //     this.state.day3 = date;
                //     this.state.day4 = date + 1;
                //     this.state.day5 = date + 2;
                }else{
                    this.state.day1 = date - 2;
                    this.state.day2 = date - 1;
                    this.state.day3 = date;
                    this.state.day4 = date + 1;
                    this.state.day5 = date + 2;
                }

                
                this.state.changedDay = date;
            };
        }
    }

    // chegeAppoinmentModalTime() {
    //     if (!this.state.showNextAppoinmentTimer) {
    //         var today = new Date();
    //         var hours = parseInt(today.getHours());
    //         var minutes = today.getMinutes()
    //         if (hours > 12) {
    //             this.state.hour12Formet = hours - 12;
    //             this.state.hour12Formet = this.state.hour12Formet < 10 ? '0' + this.state.hour12Formet : this.state.hour12Formet;
    //         } else {
    //             this.state.hour12Formet = hours;
    //         }
    //         minutes = minutes < 10 ? '0' + minutes : minutes;
    //         this.state.minutes = minutes;

    //         this.state.findAmPm = hours >= 12 ? 'pm' : 'am';
    //     }
    // }


    showDateDropDownView = () => {
        var getdate = [];
        if (this.state.dropDownView === "month") {
            getdate = this.state.totalMonths;
        } else {
            var today = new Date();
            var currentYear = parseInt(today.getFullYear());
            for (var i = currentYear; i > 2000; i--) {
                getdate.push(i);
            };
        }
        return (
            <View style={{ alignSelf: 'center', alignItems: 'center', width: '25%', backgroundColor: 'black', zIndex: 1, position: 'absolute', }}>
                {
                    this.state.dropDownViewFlag ?
                        <ScrollView style={styles.timeAndDateScroll} showsVerticalScrollIndicator={false}>
                            <View style={{ backgroundColor: 'black' }}>
                                {
                                    getdate.map((value, index) => {
                                        return (
                                            <TouchableOpacity
                                                key={index}
                                                style={{ padding: 5 }}
                                                onPress={() => {
                                                    this.state.dropDownView === "month" ?
                                                        this.setState({ chengedMonthInNumber: index + 1, getMonth: value.month, dropDownViewFlag: !this.state.dropDownViewFlag, changedMonthDays: value.totalDays })
                                                        :
                                                        this.setState({ getYear: value, dropDownViewFlag: !this.state.dropDownViewFlag });
                                                }}>
                                                <Text style={{ color: "white" }}>{this.state.dropDownView === "month" ? value.month : value}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                        :
                        null
                }
            </View>
        )
    }
    
    currentDate() {
        var today = new Date();
        var date = today.getDate();
        var month = today.getMonth();
        var year = today.getFullYear();
        for (let i = 0; i < this.state.totalMonths.length; i++) {
            if (month === i) {
                this.setState({
                    changedMonthDays: this.state.totalMonths[i].totalDays,
                    getMonth: this.state.totalMonths[i].month,
                    getYear: year,
                    day1: date - 2,
                    day2: date - 1,
                    day3: date,
                    day4: date + 1,
                    day5: date + 2,
                })
            }
        }
    }
    showDropdown(data) {
        this.setState({
            dropDownViewFlag: !this.state.dropDownViewFlag,
            dropDownView: data
        })
    }
    onClickToday(){
        var today= new Date()
        var date= today.getDate()
        var month=today.getMonth()
        var year=today.getFullYear()
        var states=this.state
        for(let i=0;i<this.state.totalMonths.length;i++){
          if(month===i){
            states["chengedMonthInNumber"] = i + 1;
            states["changedMonthDays"] = this.state.totalMonths[i].totalDays;
            states["getMonth"] = this.state.totalMonths[i].month;
            states["getYear"] = year;
            states["getDate"] = date;
            states["day1"] = date - 2;
            states["day2"] = date - 1;
            states["day3"] = date;
            states["day4"] = date + 1;
            states["day5"] = date + 2;
            states["changedDay"] = date;
          } 
       }
       this.setState({
        states
      },()=>{
        this.props.getSelectedDate()
      })
    }

    showCalendar() {
        return (
            <View style={{ maxWidth: '35%', backgroundColor: "white", flexDirection: 'row', marginLeft: 10, flex: 1 }}>
                <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <TouchableOpacity
                            style={{ marginRight: 5 }}
                            onPress={() => {
                                this.showDropdown("month")
                            }}>
                            <Text>{(this.state.getMonth).substr(0, 3)}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ marginRight: 5 }}
                            onPress={() => {
                                this.showDropdown("year");
                            }}>
                            <Text>{this.state.getYear}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=>{this.onClickToday()}}>
                            <Image source={require("../../../../assets/images/calendar_icon.png")} style={{height:20,width:20,tintColor:color.themeDark}}></Image>
                        </TouchableOpacity>

                        <View style={{ height: 15, width: 15 }}>
                            {/* <Image
                  // require("../../assets/images/Reports/Group 1558.svg")calendar_icon.png
                  source={require("../../../../assets/images/calendar (2).svg")}
                  style={{resizeMode:'center',height:15,width:15}}
                  resizeMode="cover"
                /> */}
                        </View>
                    </View>
                    <View>
                        {this.showDateDropDownView()}
                    </View>
                    <View style={{ zIndex: -1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                        <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.changedDay - 1); }} style={{ padding: 5, justifyContent: 'center' }}>
                            <Icon
                                size={platform === "web" ? 25 : 30}
                                name={"angle-left"}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.changeDay("sub", 2, this.state.day1); }}
                            style={[this.state.changedDay === this.state.day1 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 5 } : { padding: 5 }, { justifyContent: 'center' }]}
                        >
                            <Text style={[this.state.changedDay === this.state.day1 ? { color: "white" } : null]}>{this.state.day1 >9 ? this.state.day1: "0"+this.state.day1}<Text style={{ fontSize: 7, }}></Text></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.day2); }}
                            style={[this.state.changedDay === this.state.day2 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 5 } : { padding: 5 }, { justifyContent: 'center' }]}
                        >
                            <Text style={[this.state.changedDay === this.state.day2 ? { color: "white" } : null]}>{this.state.day2 > 9 ? this.state.day2: "0"+this.state.day2}<Text style={{ fontSize: 7, }}></Text></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.changeDay("add", 0, this.state.day3) }}
                            style={[this.state.changedDay === this.state.day3 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 5 } : { padding: 5 }, { justifyContent: 'center' }]}
                        >
                            <Text style={[this.state.changedDay === this.state.day3 ? { color: "white" } : null]}>{this.state.day3 >9 ? this.state.day3: "0"+this.state.day3}<Text style={{ fontSize: 7, }}></Text></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.day4); }}
                            style={[this.state.changedDay === this.state.day4 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 5 } : { padding: 5 }, { justifyContent: 'center' }]}
                        >
                            <Text style={[this.state.changedDay === this.state.day4 ? { color: "white" } : null]}>{this.state.day4 >9 ? this.state.day4: "0"+this.state.day4}<Text style={{ fontSize: 7, }}></Text></Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.changeDay("add", 2, this.state.day5); }}
                            style={[this.state.changedDay === this.state.day5 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 5 } : { padding: 5 }, { justifyContent: 'center' }]}
                        >
                            <Text style={[this.state.changedDay === this.state.day5 ? { color: "white" } : null]}>{this.state.day5 >9 ? this.state.day5: "0"+this.state.day5}<Text style={{ fontSize: 7 }}></Text></Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.changedDay + 1); }} style={{ padding: 5, justifyContent: 'center' }}>
                            <Icon
                                size={platform === "web" ? 25 : 30}
                                name={"angle-right"}
                            // color={"#c7c4c3"}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                {/* <View style={{justifyContent:'flex-end',marginBottom:10,marginLeft:5}}>
              <Image
                  source={require("../../../../assets/images/clock_icon.png")}
                  style={{resizeMode:'center',height:30,width:30}}
                  resizeMode="cover"
                />
                <Text style={{fontSize:8,marginTop:6}}>{"Today"}</Text>
              </View> */}
            </View>
        )
    }

    checkValidateTheDays() {
        if (this.state.day5 === this.state.changedMonthDays) {
            //  do nothing
        } else {
            if (this.state.day5 > this.state.changedMonthDays) {
                this.setState({
                    day1: this.state.day1 - 1,
                    day2: this.state.day2 - 1,
                    day3: this.state.day3 - 1,
                    day4: this.state.day4 - 1,
                    day5: this.state.day5 - 1,
                });
            }
        }

        if (this.state.day3 === 1) {
            this.setState({
                day1: this.state.day1 + 2,
                day2: this.state.day2 + 2,
                day3: this.state.day3 + 2,
                day4: this.state.day4 + 3,
                day5: this.state.day5 + 4,
            });
        }
        if (this.state.day3 === 2) {
            this.setState({
                day1: this.state.day1 + 1,
                day2: this.state.day2 + 1,
                day3: this.state.day3 + 1,
                day4: this.state.day4 + 1,
                day5: this.state.day5 + 1,
            });
        }

    }

    changeDay(method, value, selectedDay) {
        this.state.getDate = selectedDay; // get selected day

        if (this.state.changedMonthDays < selectedDay || selectedDay < 1) {
            // do nothing
        } else {
            this.setState({ changedDay: selectedDay })
        }


        if (method === "" && value === 0) {
            // do nothing
        } else {
            if (method === "sub") {
                if (this.state.day3 === 3) {
                    // do nothing
                } else if (this.state.day1 === 2) {
                    this.setState({
                        day1: this.state.day1 - 1,
                        day2: this.state.day2 - 1,
                        day3: this.state.day3 - 1,
                        day4: this.state.day4 - 1,
                        day5: this.state.day5 - 1,
                    });
                } else {
                    this.setState({
                        day1: this.state.day1 - value,
                        day2: this.state.day2 - value,
                        day3: this.state.day3 - value,
                        day4: this.state.day4 - value,
                        day5: this.state.day5 - value,
                    });
                }
            } else {
                if (this.state.changedMonthDays === this.state.day5) {
                    // do nothing
                } else if (this.state.changedMonthDays - 2 === this.state.day4) {
                    this.setState({
                        day1: this.state.day1 + 1,
                        day2: this.state.day2 + 1,
                        day3: this.state.day3 + 1,
                        day4: this.state.day4 + 1,
                        day5: this.state.day5 + 1,
                    });
                } else {
                    this.setState({
                        day1: this.state.day1 + value,
                        day2: this.state.day2 + value,
                        day3: this.state.day3 + value,
                        day4: this.state.day4 + value,
                        day5: this.state.day5 + value,
                    });
                }
            }
        }

        if ((this.state.changedMonthDays >= this.state.getDate && method === "add") || (this.state.getDate >= 1 && method === "sub")) {
            var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; // selected date month and year (need to pass getAppointments function)
            this.props.getSelectedDate(selectedDateAndTime); // send selected date to Appointments
        } else {
            // do nothing
        }
    }

    render() {
        return (
            <View>
                {this.showCalendar()}
            </View>
        );
    }
}


const styles = StyleSheet.create({
    timeAndDateScroll: {
        height: 200, width: 100
      },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

// export default Calender;
