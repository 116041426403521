import React, { Component } from 'react';
import { View, Switch, Text, StyleSheet, ScrollView, TextInput, TouchableOpacity, Dimensions } from 'react-native';
import { NavigationTopHeader } from '../../../BaseComponent';
import { CommonHistorySectionHeader, CommonButton } from '../../../BaseComponent';
import { color } from '../../../../../styles/Color';
import OpthamologyService from '../../../../../network/OpthamologyService';
import { Constants } from '../../../../../utils/Constants';
import moment from "moment";
import {DatePicker} from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import AsyncStorage from '../../../../../AsyncStorage';


let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
const screenHeight = Dimensions.get("window").height;
const navigationHeader = [
  { label: "OT Stock Usage", value: "stockUsage" },
  { label: "OT Stock Usage Transactions", value: "stockUsageTrans" }
]
const tempRows = {
  "drug_name": "",
  "generic_name": "",
  "dosage_strength": "",
  "dosage_type": "",
  "qty": "",
  "avail_qty": "",
  "id": "",
  "batch_no": ""
}
const tempIOL = {
  "drug_name": "",
  "model_name": "",
  "IOL_type": "",
  "IOL_power": "",
  "qty": "",
  "avail_qty": "",
  "id": "",
  "batch_no": ""
}
 export default class StockUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSwitchFlag: false,
      drugEnteryList: [],
      IOLEntryList:[],
      stockDate: converted_date,
      brandNameSuggession: [],
      selectedIndex: "",
      genericNameSuggest: [],
      isGenericSuggession: true,
      selectedID: "",
      iolBrandNameSuggession: [],
      iolModelSuggession: [],
      typedIndex: null,
      IOLTypedIndex: null,
      is_OPT: ""      
    };
  }
  async componentDidMount() {
    this.getBrandNameSuggest()
    this.getIOLBrandNameSuggest()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        is_OPT : loggedInDataObj.is_OPT
    },() => {
        this.setState({
          toggleSwitchFlag : this.state.is_OPT ? false : true
        })
    })
}
  getIOLBrandNameSuggest = (brand_name) =>{
    var brand_name = brand_name ? brand_name : ""
    var url = Constants.IOL_DRUG_SUGGEST + "?brand_name=" + brand_name;
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getIOLBrandNameSuggestSuccess,
        this.getIOLBrandNameSuggestFailure
      );
  }

  getIOLBrandNameSuggestSuccess = response => {
      if (response && response.status == "success") {
          this.setState({
            iolBrandNameSuggession: response.data
          })
      }
  }
  getIOLBrandNameSuggestFailure = response => {
  
  }
  getBrandNameSuggest = (brand_name) =>{
    var url = Constants.STOCK_USAGE_DRUG_SUGGEST + "?brand_name=" + brand_name;
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getBrandNameSuggestSuccess,
        this.getBrandNameSuggestFailure
      );
  }

  getBrandNameSuggestSuccess = response => {
      if (response && response.status == "success") {
          this.setState({
            brandNameSuggession: response.drug_data
          })
      }
  }
  getBrandNameSuggestFailure = response => {
  
  }
  getGenericNameSuggest = (brand_name) =>{
    var url = Constants.STOCK_USAGE_GENERIC_SUGGEST + "?brand_name=" + brand_name;
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getGenericNameSuggestSuccess,
        this.getGenericNameSuggestFailure
      );
  }

  getGenericNameSuggestSuccess = response => {
      if (response && response.status == "success") {
        var data = response.data
        var { typedIndex, drugEnteryList } = this.state
        if(data.length === 1) {
          drugEnteryList[typedIndex]["drug_name"] = data[0].brand_name
          drugEnteryList[typedIndex]["generic_name"] = data[0].generic_name
          drugEnteryList[typedIndex]["dosage_type"] = data[0].dosage_type
          drugEnteryList[typedIndex]["dosage_strength"] = data[0].dosage_strength
          drugEnteryList[typedIndex]["avail_qty"] = data[0].avaliable_quantity
          drugEnteryList[typedIndex]["id"] = data[0].id
          drugEnteryList[typedIndex]["batch_no"] = data[0].batch_no
          this.setState({
             drugEnteryList,
             typedIndex: null      
           })
        } else {
          this.setState({
            genericNameSuggest: response.data

          }) 
        }
      }
  }
  getGenericNameSuggestFailure = response => {
  
  }
  getIOLModelName = (brand_name) =>{
    var url = Constants.IOL_MODEL_SUGGEST + "?brand_name=" + brand_name;
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getIOLModelNameSuccess,
        this.getIOLModelNameFailure
      );
  }

  getIOLModelNameSuccess = response => {
      if (response && response.status == "success") {
        var data = response.data
        var { IOLTypedIndex, IOLEntryList } = this.state
        if(data.length === 1) {
          IOLEntryList[IOLTypedIndex]["drug_name"] = data[0].brand_name
          IOLEntryList[IOLTypedIndex]["model_name"] = data[0].model_name
          IOLEntryList[IOLTypedIndex]["IOL_type"] = data[0].iol_type
          IOLEntryList[IOLTypedIndex]["IOL_power"] = data[0].iol_power
          IOLEntryList[IOLTypedIndex]["avail_qty"] = data[0].avaliable_quantity
          IOLEntryList[IOLTypedIndex]["batch_no"] = data[0].batch_number
          IOLEntryList[IOLTypedIndex]["id"] = data[0].id
          this.setState({ IOLEntryList })
        } else {
          this.setState({
            iolModelSuggession: response.data
          }) 
        }
      }
  }
  getIOLModelNameFailure = response => {
  
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  onPressSaveDrug = () => {
    
     var { drugEnteryList } = this.state
     var service_url = ""
     let current_row = drugEnteryList.length;
     let bill_line_items = [];

     for (let i = 0; i < current_row; i++) {

       if (drugEnteryList[i].drug_name) {

         bill_line_items.push({ "id": drugEnteryList[i].id, "usage_quantity": parseInt(drugEnteryList[i].qty) });

       }
    }

    service_url = Constants.STOCK_DRUG_PUT
    var data = {
      "ot_stock_usage_date": this.state.stockDate,
      "ot_stock_usage": bill_line_items
    }
    var isManitory = ""
    var isDuplicate = false
    let line_items = []
     for (let i = 0; i < current_row; i++) {

      if (drugEnteryList[i].drug_name) {
        line_items.push(drugEnteryList[i]);

      }
    }

     var check_id = line_items && line_items.length > 0 && line_items.map(item => { return item.id })
     isDuplicate = check_id && check_id.length > 0 && check_id.some((item, index) => (
      check_id.indexOf(item) != index
     ))

     for (let i = 0; i < line_items.length; i++) {
       if (!line_items[i].drug_name) {
         isManitory = "Please enter the drug name";
       } else if (!line_items[i].qty) {
         isManitory = "Please enter the quantity"
       } 
     }

     if (isDuplicate) {
       this.props.showResposeValue("error", "Same drug repeated multiple times")
     } else if (isManitory) {
       this.props.showResposeValue("error", isManitory)
     } else if (line_items && line_items.length == 0) {
       this.props.showResposeValue("error", "Please enter the details to proceed")
     }
     OpthamologyService.getInstance().putMethod(
       service_url,
       data,
       this,
       this.onPressSaveDrugAndIOLSuccess,
       this.onPressSaveDrugAndIOLFailure
     );

   }
   onPressSaveDrugAndIOLSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)
      this.setState({
        drugEnteryList: []
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
   }
   onPressSaveDrugAndIOLFailure = (error) => {
    this.props.showResposeValue("error", error.message)

   }
   onPressSaveIOL = () => {
    var { IOLEntryList } = this.state
    var service_url = ""
    let current_row = IOLEntryList.length;
    let bill_line_items = [];
    let prepareData = []

    for (let i = 0; i < current_row; i++) {

      if (IOLEntryList[i].drug_name) {

        prepareData.push({ "id": IOLEntryList[i].id, "usage_quantity": parseInt(IOLEntryList[i].qty) });
        bill_line_items.push(IOLEntryList[i])

      }
    }

    service_url = Constants.STOCK_USAGE_IOL_PUT
    var data = {
      "ot_iol_stock_usage_date": this.state.stockDate,
      "ot_iol_stock_usage": prepareData
    }

    var isManitory = ""
    var isDuplicate = false
    var line_items = []
    for (let i = 0; i < current_row; i++) {
      if (IOLEntryList[i].drug_name) {
        line_items.push(IOLEntryList[i])
      }
    }
    var check_id = line_items && line_items.length > 0 && line_items.map(item => { return item.id })
    isDuplicate = check_id && check_id.length > 0 && check_id.some((item, index) => (
      check_id.indexOf(item) != index 
    ))

    for (let i = 0; i < line_items.length; i++) {
      if (!line_items[i].drug_name) {
        isManitory = "Please enter the drug name";
      } else if (!line_items[i].qty) {
        isManitory = "Please enter the quantity"
      }
    }

    if (isDuplicate) {
      this.props.showResposeValue("error", "Same drug repeated multiple times")
    } else if (isManitory) {
      this.props.showResposeValue("error", isManitory)
    } else if (line_items && line_items.length == 0) {
      this.props.showResposeValue("error", "Please enter the details to proceed")
    } else { 
    OpthamologyService.getInstance().putMethod(
      service_url,
      data,
      this,
      this.onPressSaveIOLSuccess,
      this.onPressSaveIOLFailure
    );
    }

  }
  onPressSaveIOLSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)
      this.setState({
        IOLEntryList: []
      })
    } else {
      this.props.showResposeValue("error", response.message)

    }
  }
  onPressSaveIOLFailure = (error) => {
    this.props.showResposeValue("error", error.message)

  }

  renderToggleView = () => {
    return (
      <View
        style={styles.subHeaderContainer}
      >
        <View style={styles.dateMainContainer}>
          <Text style={styles.HeaderText}>{"Date"}</Text>
          <DatePicker
            // disabledDate={this.disabledDate}
            value={moment(this.state.stockDate)}
            onChange={(date) => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              this.setState({ stockDate: converted_date });
            }
            }
            clearIcon={false}
            format={"DD-MM-YYYY"}
            placeholder={"YYYY-MM-DD"}
            defaultValue={moment(this.state.stockDate, "YYYY-MM-DD")}
            style={{
              zIndex: -1, width: '85%',
              borderColor: '#CDD1D5',
              height: "80%", borderRadius: 4, borderWidth: 1,
              marginTop: "0.5vw"
            }}
          />
        </View>
        {this.state.is_OPT ?
        <View style={{ flexDirection: "row", flex: 1, justifyContent: "center", alignItems: "center", alignSelf: "center", marginRight: "1.2vw"}}>
          <Text style={[ styles.toggleText, {color: !this.state.toggleSwitchFlag ? color.themeDark : color.black }]}>{"IOL"}</Text> 
          <Switch
            style={{
              width: "1vw",
              height: "1.2vw",
              margin: "0.5vw",
            }}
            thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
            onValueChange={() => {
              this.setState({
                toggleSwitchFlag: this.state.is_OPT ? !this.state.toggleSwitchFlag : true,
                drugEnteryList: [],
                IOLEntryList: []
              });
            }}
            value={this.state.toggleSwitchFlag}
          />
          <Text style={[ styles.toggleText, {color: this.state.toggleSwitchFlag ? color.themeDark : color.black }]}>{"Drug"}</Text>
        </View> : null}
      </View>
    );
  };
  getBillTableTitle(title, flexWidth = 0.05, fontsize="0.7vw") {
    return (
        <View style={{ flex: flexWidth}}>
            <Text style={{ fontSize: fontsize }}>{title}</Text>
        </View>)
  }
  renderHeaderView = () => {
    return (
      <View>
        {this.state.toggleSwitchFlag ? (
          <View style={styles.drugHeaderContainer}>
          <View style={styles.subContainer}>
             {this.getBillTableTitle('S.No', 0.05,"1vw")}
             {this.getBillTableTitle('Brand Name', 0.18,"1vw")}
             {this.getBillTableTitle('Generic Name', 0.17,"1vw")}
             {this.getBillTableTitle('Dosage Type', 0.15,"1vw")}
             {this.getBillTableTitle('Dosage Strength', 0.15,"1vw")}
             {this.getBillTableTitle('Batch No', 0.1,"1vw")}
             {this.getBillTableTitle('Quantity', 0.09,"1vw")}
             {this.getBillTableTitle('Avail Quantity', 0.08,"1vw")}
             {this.getBillTableTitle('', 0.03,"1vw")}

          </View>
         </View> 
        ) : (
          <View style={styles.drugHeaderContainer}>
          <View style={styles.subContainer}>
             {this.getBillTableTitle('S.No', 0.05,"1vw")}
             {this.getBillTableTitle('Brand Name', 0.18,"1vw")}
             {this.getBillTableTitle('Model Name', 0.17,"1vw")}
             {this.getBillTableTitle('IOL Type', 0.15,"1vw")}
             {this.getBillTableTitle('IOL Power', 0.15,"1vw")}
             {this.getBillTableTitle('Batch No', 0.1,"1vw")}
             {this.getBillTableTitle('Quantity', 0.09,"1vw")}
             {this.getBillTableTitle('Avail Quantity', 0.08,"1vw")}
             {this.getBillTableTitle('', 0.03,"1vw")}
          </View>
         </View> 
        )}
      </View>
    );
  };
  avoidOnlySplCharValidation = (num) => {
    return !/[^A-Z a-z 0-9]+/.test(num)
  }
  renderTextInput = (stateKey, value, flex, index) => {
    let { drugEnteryList, IOLEntryList, typedIndex, IOLTypedIndex } = this.state
    return (
      <View style ={{flex: flex, height: "3.5vw", }}>
        <TextInput
          style={styles.textInput}
          value={value}
          editable = { stateKey === "qty" || stateKey === "drug_name" ? true : false }
          keyboardType = { stateKey === "qty" ? "numeric" : null }
          onChangeText={(text) => {
            var isAlphaNumeric = this.avoidOnlySplCharValidation(text)
            var states = this.state
           if(this.state.toggleSwitchFlag ) { 
           if( stateKey === "drug_name" && isAlphaNumeric) {
            var currentIndex = null
                currentIndex = index
                drugEnteryList[index]['id'] = '' 
                drugEnteryList[index]['generic_name'] = '' 
                drugEnteryList[index]['dosage_type'] = '' 
                drugEnteryList[index]['dosage_strength'] = '' 
                drugEnteryList[index]['qty'] = '' 
                drugEnteryList[index]['avail_qty'] = '' 
                drugEnteryList[index]['batch_no'] = '' 
                states["genericNameSuggest"] = []
                drugEnteryList[index][stateKey] = text;
            this.getBrandNameSuggest(text)
           } else {
             
            drugEnteryList[index][stateKey] = text;
           }      
            this.setState({
              drugEnteryList,
              typedIndex: currentIndex,
              states
            });
           } else {
            if(stateKey === "drug_name" && isAlphaNumeric) {
              var currentIndex = null
              currentIndex = index
              IOLEntryList[index]['id'] = '' 
              IOLEntryList[index]['model_name'] = '' 
              IOLEntryList[index]['IOL_type'] = '' 
              IOLEntryList[index]['IOL_power'] = '' 
              IOLEntryList[index]["avail_qty"] = '';
              IOLEntryList[index]["qty"] = '';
              IOLEntryList[index]["batch_no"] = '';
              states["iolModelSuggession"] = []
              IOLEntryList[index][stateKey] = text;
              this.getIOLBrandNameSuggest(text)
            }  else {
              IOLEntryList[index][stateKey] = text;
            }
            this.setState({
              IOLEntryList,
              IOLTypedIndex: currentIndex,
              states
            });
           }
           
          }}
        />
      
        { index === typedIndex && this.state.drugEnteryList[index]["drug_name"] != "" && stateKey == "drug_name" && this.state.brandNameSuggession && this.state.brandNameSuggession.length > 0 && this.state.toggleSwitchFlag ?
          <View style={styles.suggestionMain}>
          <ScrollView showsVerticalScrollIndicator = {false} showsHorizontalScrollIndicator = {false}
          style={{
            flex: 1,
            backgroundColor: color.black, 
            borderColor: color.lightGray, 
            borderWidth: 1, 
            borderRadius: 4, 
            paddingHorizontal: 10,
          }}
          >
            {this.state.brandNameSuggession && (this.state.brandNameSuggession).map((item) => ( 
            <View>
              <TouchableOpacity
              key={index}
              style={{marginTop: 5, marginBottom: 2}}
              onPress={() => {
               if(this.state.toggleSwitchFlag) {
                 var { drugEnteryList } = this.state
                 drugEnteryList[typedIndex]["drug_name"] = item
                 this.setState({
                   drugEnteryList,
                   brandNameSuggession: []
                 })
                 this.getGenericNameSuggest(item)
               } 
              }}
              >
                <Text style={{color: color.white, fontWeight: "bold"}}>{item}</Text>
              </TouchableOpacity>
            </View> 

            ))}

             </ScrollView>

           </View> : null} 
           { index === typedIndex && stateKey == "generic_name" && this.state.drugEnteryList[index]["drug_name"]!= "" && this.state.genericNameSuggest && this.state.genericNameSuggest.length > 1 && this.state.toggleSwitchFlag ?

             <View style={styles.suggestionMain}>
               <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} 
                 style={{
                   flex: 1,
                   backgroundColor: color.black,
                   borderColor: color.lightGray,
                   borderWidth: 1,
                   borderRadius: 4,
                   paddingHorizontal: 10,
                 }}
               >
                 { this.state.genericNameSuggest && (this.state.genericNameSuggest).map((item) => { 
                  return( 
                   <View>
                     <TouchableOpacity
                       key={this.state.typedIndex}
                       style={{ marginTop: 5, marginBottom: 2 }}
                       onPress={() => {
                         if (this.state.toggleSwitchFlag) {
                                var { drugEnteryList, typedIndex } = this.state
                                drugEnteryList[typedIndex]["drug_name"] = item.brand_name
                                drugEnteryList[typedIndex]["generic_name"] = item.generic_name; 
                                drugEnteryList[typedIndex]["dosage_type"] = item.dosage_type; 
                                drugEnteryList[typedIndex]["dosage_strength"] = item.dosage_strength;
                                drugEnteryList[typedIndex]["avail_qty"] = item.avaliable_quantity;
                                drugEnteryList[typedIndex]["id"] = item.id;
                                drugEnteryList[typedIndex]["batch_no"] = item.batch_no;
                                this.setState({
                                  drugEnteryList,
                                  genericNameSuggest:[],
                                  typedIndex: null      
                                })
                         }
                       }}
                     >
                       <Text style={{ color: color.white, fontWeight: "bold" }}>{item.display_name}</Text>
                     </TouchableOpacity>
                   </View>

                  ) })}

               </ScrollView>
       </View> : null }

       { index === IOLTypedIndex && stateKey == "drug_name" && IOLEntryList[index]["drug_name"] != "" && this.state.iolBrandNameSuggession && this.state.iolBrandNameSuggession.length > 0 && !this.state.toggleSwitchFlag ?
         <View style={styles.suggestionMain}>
         <ScrollView
         showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} 
         style={styles.suggessionContainer}
         >
           {this.state.iolBrandNameSuggession && (this.state.iolBrandNameSuggession).map((item) => ( 
           <View>
             <TouchableOpacity
             key={index}
             style={{marginTop: 5, marginBottom: 2}}
             onPress={() => {
                var { IOLEntryList } = this.state
                IOLEntryList[IOLTypedIndex]["drug_name"] = item
                this.setState({
                  IOLEntryList,
                  iolBrandNameSuggession: []
                })
                this.getIOLModelName(item)
             }}
             >
               <Text style={{color: color.white, fontWeight: "bold"}}>{item}</Text>
             </TouchableOpacity>
           </View> 

           ))}

            </ScrollView>

          </View> : null }

          { index === IOLTypedIndex && stateKey == "model_name" && IOLEntryList[index]["drug_name"] != "" && this.state.iolModelSuggession && this.state.iolModelSuggession.length > 1 && !this.state.toggleSwitchFlag ?

            <View style={styles.suggestionMain}>
              <ScrollView 
                showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} 
                style={styles.suggessionContainer}
              >
                { this.state.iolModelSuggession && (this.state.iolModelSuggession).map((item) => { 
                 return( 
                  <View>
                    <TouchableOpacity
                      key={IOLTypedIndex}
                      style={{ marginTop: 5, marginBottom: 2 }}
                      onPress={() => {
                               var { IOLEntryList } = this.state
                               IOLEntryList[IOLTypedIndex]["drug_name"] = item.brand_name; 
                               IOLEntryList[IOLTypedIndex]["model_name"] = item.model_name; 
                               IOLEntryList[IOLTypedIndex]["IOL_type"] = item.iol_type; 
                               IOLEntryList[IOLTypedIndex]["IOL_power"] = item.iol_power; 
                               IOLEntryList[IOLTypedIndex]["avail_qty"] = item.avaliable_quantity;
                               IOLEntryList[IOLTypedIndex]["id"] = item.id;
                               IOLEntryList[IOLTypedIndex]["batch_no"] = item.batch_number;
                               this.setState({
                                 IOLEntryList,
                                 iolModelSuggession:[],
                                 typedIndex: null      
                               })
                      }}
                    >
                      <Text style={{ color: color.white, fontWeight: "bold" }}>{item.diplay_name}</Text>
                    </TouchableOpacity>
                  </View>

                 ) })}

              </ScrollView>

            </View> : null }
       </View>
     );
   };
   _handleItemRemoveDrug = (i = null) => {
     let { drugEnteryList } = this.state;
     let dataRemovedList = drugEnteryList.slice(0, i).concat(drugEnteryList.slice(i + 1, drugEnteryList.length))
     this.setState({
       drugEnteryList: dataRemovedList
     })
   }
   _handleItemRemoveIOL = (i = null) => {
    let { IOLEntryList } = this.state;
    let dataRemovedList = IOLEntryList.slice(0, i).concat(IOLEntryList.slice(i + 1, IOLEntryList.length))
    this.setState({
      IOLEntryList: dataRemovedList
    })
  }
   renderTableView = () => {
     let { drugEnteryList, IOLEntryList } = this.state
    if (drugEnteryList.length < 10) {
        for (let i = drugEnteryList.length; i < 10; i++) {
          drugEnteryList.push(JSON.parse(JSON.stringify(tempRows)))
        }
    }
    if (IOLEntryList.length < 10) {
      for (let i = IOLEntryList.length; i < 10; i++) {
        IOLEntryList.push(JSON.parse(JSON.stringify(tempIOL)))
      }
  }  
    return(
      <View>
         <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '480px' }}>
                    <View>
                      { this.state.toggleSwitchFlag ?
                       <View>
                        {drugEnteryList && drugEnteryList.map((item, index) => {
                            var sno = index + 1
                            let zIndex = (index + 1) * -10;
                            return ( 
                                    <View style={{ flexDirection: "row", flex: 1, zIndex: zIndex }} >
                                        <View  style={{ flex: 0.04, alignItems: "center", alignSelf: "center" }}><Text style={{ fontSize: "1vw"}}>{sno}</Text></View>
                                        {this.renderTextInput('drug_name', item.drug_name, 0.18, index)}
                                        {this.renderTextInput('generic_name', item.generic_name, 0.17, index)}
                                        {this.renderTextInput('dosage_strength', item.dosage_strength, 0.15, index)}
                                        {this.renderTextInput('dosage_type', item.dosage_type, 0.15, index)}
                                        {this.renderTextInput('batch_no', item.batch_no, 0.1, index)}
                                        {this.renderTextInput('qty', item.qty, 0.09, index)}
                                        {this.renderTextInput('avail_qty', item.avail_qty, 0.08, index)}
                                        <TouchableOpacity onPress={() => { drugEnteryList[index]['drug_name'] ? this._handleItemRemoveDrug(index) : null }} style={{ flex: 0.03, justifyContent: "center", alignItems: "flex-end" }}>
                                            <Icon name="close" size={"1.5vw"} color={"#000000"} />
                                        </TouchableOpacity>
                                    </View>
                            )
                        })
                       }
                        </View>:
                        <View>
                        {IOLEntryList && IOLEntryList.map((item, index) => {
                            var sno = index + 1
                            let zIndex = (index + 1) * -10;
                            return (
                                    <View style={{ flexDirection: "row", flex: 1, zIndex: zIndex }} >
                                        <View  style={{ flex: 0.04, alignItems: "center", alignSelf: "center" }}><Text style= {{ fontSize: "1vw" }}>{sno}</Text></View>
                                        {this.renderTextInput('drug_name', item.drug_name, 0.18, index)}
                                        {this.renderTextInput('model_name', item.model_name, 0.17, index)}
                                        {this.renderTextInput('IOL_type', item.IOL_type, 0.15, index)}
                                        {this.renderTextInput('IOL_power', item.IOL_power, 0.15, index)}
                                        {this.renderTextInput('batch_no', item.batch_no, 0.1, index)}
                                        {this.renderTextInput('qty', item.qty, 0.09, index)}
                                        {this.renderTextInput('avail_qty', item.avail_qty, 0.08, index)}
                                        <TouchableOpacity onPress={() => { IOLEntryList[index]['drug_name'] ? this._handleItemRemoveIOL(index) : null }} style={{ flex: 0.03, justifyContent: "center", alignItems: "flex-end" }}>
                                            <Icon name="close" size={"1.5vw"} color={"#000000"} />
                                        </TouchableOpacity>
                                    </View>
                            )
                        })

                        }
               </View>
             }
           </View>
           <View style={styles.buttonContainer}>
             <TouchableOpacity style={styles.AddButton} onPress={() => { this._addNextRows() }}>
               <Text style={styles.addText}> Add Item</Text>
             </TouchableOpacity>
           </View>
         </ScrollView>
       </View>
     )
   }
  _addNextRows = () => {

    let { drugEnteryList, IOLEntryList } = this.state

    
    if( this.state.toggleSwitchFlag ) { 

      let current_row_drug = drugEnteryList.length;
      let bill_line_items_drug = [];
      let drugRows_no_manitory_drug = [];
      let add_flag = false;
     for (let i = 0; i < current_row_drug; i++) {

        if (drugEnteryList[i].drug_name && drugEnteryList[i].generic_name && drugEnteryList[i].dosage_strength && drugEnteryList[i].dosage_type && drugEnteryList[i].qty) {
          bill_line_items_drug.push(drugEnteryList[i]);
        }
        else if (!drugEnteryList[i].drug_name || !drugEnteryList[i].generic_name || !drugEnteryList[i].dosage_strength || !drugEnteryList[i].dosage_type || drugEnteryList[i].qty) {
          drugRows_no_manitory_drug.push(drugEnteryList[i])
        }
     }

     if ((current_row_drug - bill_line_items_drug.length) === 0) {
        add_flag = true;
     }

     if (add_flag) {

        let new_row = drugEnteryList.length + 5

        for (let i = current_row_drug; i < new_row; i++) {
          drugEnteryList.push(JSON.parse(JSON.stringify(tempRows)));
        }

        this.setState({
            drugEnteryList,
            drugLineItems: bill_line_items_drug
        })
     }
     else if (drugRows_no_manitory_drug.length > 0) {
        console.log("error")
     }
    } else {
      let current_row_IOL = IOLEntryList.length;
      let bill_line_items_IOL = [];
      let drugRows_no_manitory_IOL = [];
      let add_flags = false;

      for (let i = 0; i < current_row_IOL; i++) {

        if (IOLEntryList[i].drug_name && IOLEntryList[i].model_name && IOLEntryList[i].IOL_type && IOLEntryList[i].IOL_power && IOLEntryList[i].qty) {
          bill_line_items_IOL.push(IOLEntryList[i]);
        }
        else if (!IOLEntryList[i].drug_name || !IOLEntryList[i].model_name || !IOLEntryList[i].IOL_type || !IOLEntryList[i].IOL_power || IOLEntryList[i].qty) {
          drugRows_no_manitory_IOL.push(IOLEntryList[i])
        }
     }

     if ((current_row_IOL - bill_line_items_IOL.length) === 0) {
        add_flags = true;
     }

     if (add_flags) {

        let new_row = IOLEntryList.length + 5

        for (let i = current_row_IOL; i < new_row; i++) {
          IOLEntryList.push(JSON.parse(JSON.stringify(tempIOL)));
        }

        this.setState({
          IOLEntryList,
        })
     }
     else if (drugRows_no_manitory_IOL.length > 0) {
        console.log("error")

     }
    }
}
  render() {
    return (
      <View style={ styles.mainContainer }>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"stockUsage"}
          isNavigationBorder={true}
        />
        <View style={styles.container}>
          {this.renderToggleView()}
          {this.renderHeaderView()}
          <View>{this.renderTableView()}</View>
          {this.renderBottonView()}

        </View>
      </View>
    );
  }
  renderBottonView = () => {
    return(
      <View style={styles.saveContainer}>
      <TouchableOpacity style={[styles.addButton, { paddingHorizontal: "0.8vw" }]} onPress={() => {
       if (this.state.toggleSwitchFlag) {
         this.onPressSaveDrug()
       } else {
         this.onPressSaveIOL()
       }
      }}>
       <Text style={styles.addText}>Save</Text>
     </TouchableOpacity> 
     <TouchableOpacity style={[styles.addButton, { paddingHorizontal: "0.8vw", marginLeft: "0.5vw" }]} onPress={() => {
       if (this.state.toggleSwitchFlag) {
         this.setState({
           drugEnteryList: []
         })
       } else {
        this.setState({
          IOLEntryList: []
        })       }
      }}>
       <Text style={styles.addText}>Clear</Text>
     </TouchableOpacity> 
   </View>
    )
  }
}
const styles = StyleSheet.create({
   container : {
     margin: "0.5vw",
   },
   mainContainer: {
    height: screenHeight-85
   },
   textInput: {
    paddingVertical: "0.5vw",
    paddingLeft: "0.2vw",
    marginVertical: "1vw",
    marginHorizontal: "0.2vw",
    backgroundColor: color.white,
    borderColor: "#CDD1D5",
    borderRadius: "0.4vw",
    borderWidth: 1,
    zIndex: -2,
    fontSize: "1vw"
   },
   addText: {
    color: color.white,
    fontSize: "1vw"
   },
   AddButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.5vw"
   },
   buttonContainer: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    margin: "0.5vw",
   },
   HeaderText: { position: 'absolute', marginLeft: "0.5vw", backgroundColor: "white", fontSize: "0.8vw", paddingHorizontal: "0.5vw", color: "#888888" },
   DateTextInput: { zIndex: 1, paddingLeft: 10, borderColor: color.lightGray, borderWidth: 1, marginTop: 8, borderRadius: 4, paddingVertical: "0.2vw" },
   dateMainContainer: { marginRight: "0.5vw"},
   subHeaderContainer: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "flex-end",
    justifyContent: "center",
    margin: "0.5vw"
  },
  dateContainer: {
    zIndex: -1, width: '85%',
    borderColor: '#CDD1D5',
    height: "30%", borderRadius: 4, borderWidth: 1, marginTop: "0.5vw"
  },
  toggleSubContainer: { flexDirection: "row", flex: 1, marginRight: 20, marginBottom: "0.5vw"},
  toggleText: {fontSize: "1.2vw"},
  toggleStyle: {
    width: "1vw",
    height: "1.2vw",
    marginTop: "0.3vw",
    marginHorizontal: "0.5vw",
  },
  suggestionMain:{position: "absolute", width: "15vw", marginTop: "3vw", maxHeight: "18vh", zIndex: 1},
  suggessionContainer: {
    flex: 1,
    backgroundColor: color.black,
    borderColor: color.lightGray,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 10,
  },
   addText: {
    color: color.white,
    fontSize: "1vw"
   },
   addButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.3vw"
   },
   saveContainer: {
     alignItems: "center", 
     alignSelf: "center",
     justifyContent: "center",
     flexDirection: "row",
     flex: 1,
     zIndex: -110
   },
   drugHeaderContainer: {
    flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center"
  },
  subContainer: {
    flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: 8, borderRadius: 4, marginTop:'0.5vw'
  },
})
