import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, Picker } from 'react-native';
import { color } from '../../../styles/Color'
import EditIcon from '../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../assets/images/BillConfig/delete.svg'
import { CommonButton, CommonHistorySectionHeader, NavigationTopHeader } from '../BaseComponent';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';
import { Tooltip } from 'antd';

const screenHeight = Dimensions.get("window").height;

export class LabReferredDoctors extends Component {
    constructor(props) {
        super(props);
        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {} : JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
        this.state = {
            referredDocList: [],
            doc_name: "",
            mobile_no: "",
            doc_reg_no: "",
            doc_qualification: "",
            doc_specialization: "",
            doc_clinic_name: "",
            edit_doc_id: null,
            isDeleteClick: false,
            deletedId: null,
            addClicked: false,
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ? false : true,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
            userType: this.props.userType,
        }
    }

    componentDidMount(){
        this.getReferredDocList()
    }

    getReferredDocList = () => {
        var states = this.state
        if (states.userType === Constants.ROLE_FO){
            var url = Constants.FO_REFERRED_DOCTOR
        }else if(states.userType === "LAB"){
            var url = Constants.LAB_REFERRED_DOCTOR
        }
        else{
            var url = Constants.FO_REFERRED_DOCTOR + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }
        

        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getReferredDocListSuccess,
            this.getReferredDocListFailure
        );
    }

    getReferredDocListSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                referredDocList: response.data,
            })
        }
    }

    renderLabTableHeader = () => {
        return(
            <View style={{ zIndex: -1, marginTop: 5, backgroundColor: color.appointmentTableHead, height: 50, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.17, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Doctor Name"}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.headerFontStyle}>{"Reg No."}</Text></View>
                <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Qualification"}</Text></View>
                <View style={{ flex: 0.21 }}><Text style={styles.headerFontStyle}>{"Specialization"}</Text></View>
                <View style={{ flex: 0.21 }}><Text style={styles.headerFontStyle}>{"Hospital Name"}</Text></View>
                <View style={{ flex: 0.12}}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
            </View>
        )
    }

    renderTableHeader = () => {
        return(
            <View style={{ zIndex: -1, backgroundColor: color.appointmentTableHead, height: '3.3vw', flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.15, paddingLeft: '1vw' }}><Text style={styles.headerFontStyle}>{"Doctor Name"}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.headerFontStyle}>{"Mobile No."}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.headerFontStyle}>{"Reg No."}</Text></View>
                <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Qualification"}</Text></View>
                <View style={{ flex: 0.16 }}><Text style={styles.headerFontStyle}>{"Specialization"}</Text></View>
                <View style={{ flex: 0.16 }}><Text style={styles.headerFontStyle}>{"Hospital Name"}</Text></View>
                <View style={{ flex: 0.10 }}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
            </View>
        )
    }

    renderTableData = () => {
        return (
            <View style={{ height: "90%"}}>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{ height: "100%" }}>
                    {this.state.referredDocList.length > 0 && this.state.referredDocList.map((item, index) => (
                        <View style={{ height: '4vw', flexDirection: 'row', flex: 1, alignItems: "center", minHeight: '4vw', backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, }}>
                            <View style={{ flex: 0.15, paddingLeft: '1vw' }}>
                                <Tooltip title={item.name}>
                                    <Text style={styles.docListFontStyle}>
                                        {item.name.length > 10 ? item.name.slice(0, 10) + ".." : item.name}
                                    </Text>
                                </Tooltip>
                            </View>
                            <View style={{ flex: 0.14 }}>
                                <Text style={styles.docListFontStyle}>{item.mobile_no}</Text>
                            </View>
                            <View style={{ flex: 0.14 }}>
                                <Text style={styles.docListFontStyle}>{item.reg_no}</Text>
                            </View>
                            <View style={{ flex: 0.15 }}>
                                <Tooltip title={item.qualification}>
                                    <Text style={styles.docListFontStyle}>
                                        {item.qualification.length > 10 ? item.qualification.slice(0, 10) + ".." : item.qualification}
                                    </Text>
                                </Tooltip>
                            </View>
                            <View style={{ flex: 0.16 }}>
                                <Tooltip title={item.specialization}>
                                    <Text style={styles.docListFontStyle}>
                                        {item.specialization.length > 10 ? item.specialization.slice(0, 10) + ".." : item.specialization}
                                    </Text>
                                </Tooltip>
                            </View>
                            <View style={{ flex: 0.16 }}>
                                <Tooltip title={item.clinic_name}>
                                    <Text style={styles.docListFontStyle}>
                                        {item.clinic_name.length > 10 ? item.clinic_name.slice(0, 10) + ".." : item.clinic_name}
                                    </Text>
                                </Tooltip>
                            </View>
                            <View style={{ flex: 0.10, flexDirection: "row" }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.editReferredDoc(item)
                                    }}
                                >
                                    <Image
                                        source={EditIcon}
                                        style={{ height: '1.8vw', width: '1.8vw' }}
                                        resizeMode="contain"
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ marginLeft: '1.5vw', width: "fit-content" }}
                                    onPress={() => {
                                        this.deleteReferredDoc(item.id)
                                    }}
                                >
                                    <Image
                                        source={DeleteIcon}
                                        style={{ height: '1.8vw', width: '1.8vw' }}
                                        resizeMode="contain"
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                    ))}
                </ScrollView>
                {
                    this.state.referredDocList.length == 0 ?
                        <View style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ fontSize: "1.5vw" }}>No Data Found</Text>
                        </View>
                        : null
                }
            </View>

        )
    }

    renderLabTableData = () => {
        return(
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {this.state.referredDocList.length > 0 && this.state.referredDocList.map((item, index) => (
                   <View style={{height: 60, flexDirection: 'row', flex: 1, alignItems: "center", minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead,}}>
                    <View style={{flex: 0.17, paddingLeft: 15}}>
                    <Text style={styles.docListFontStyle}>{item.name}</Text>
                    </View>
                    <View style={{ flex: 0.14 }}>
                    <Text style={styles.docListFontStyle}>{item.reg_no}</Text>
                    </View>
                    <View style={{flex: 0.15}}>
                    <Text style={styles.docListFontStyle}>{item.qualification}</Text>
                    </View>
                    <View style={{flex: 0.21}}>
                    <Text style={styles.docListFontStyle}>{item.specialization}</Text>
                    </View>
                    <View style={{flex: 0.21}}>
                    <Text style={styles.docListFontStyle}>{item.hospital_name}</Text>
                    </View>
                    <View style={{flex: 0.12, flexDirection: "row"}}>
                    <TouchableOpacity
                    onPress={() => {
                        this.editLabReferredDoc(item)
                    }}
                    >
                        <Image
                            source={EditIcon}
                            style={{ height: 25, width: 25 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                    style={{marginLeft: '1.5vw', width: "fit-content"}}
                    onPress={() => {
                        this.deleteReferredDoc(item.id)
                    }}
                    >
                        <Image
                            source={DeleteIcon}
                            style={{ height: 25, width: 30 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    </View>
                   </View> 
                ))}
                {
                this.state.referredDocList.length == 0 ? 
                <View style={{height: "100%", alignItems: "center", justifyContent: "center"}}>
                    <Text style={{fontWeight: "bold"}}>No Data Found</Text>
                </View>  
                : null
                }
            </ScrollView>

        )
    }


    editReferredDoc = (item) => {
        this.setState({
            edit_doc_id: item.id,
            doc_name: item.name,
            mobile_no: item.mobile_no,
            doc_reg_no: item.reg_no,
            doc_qualification: item.qualification,
            doc_specialization: item.specialization,
            doc_clinic_name: item.clinic_name,
        })
    }

    editLabReferredDoc = (item) => {
        this.setState({
            edit_doc_id: item.id,
            doc_name: item.name,
            doc_reg_no: item.reg_no,
            doc_qualification: item.qualification,
            doc_specialization: item.specialization,
            doc_clinic_name: item.hospital_name,
        })
    }

    deleteReferredDoc = (id) => {
        this.setState({
            isDeleteClick: true,
            deletedId: id
        })
    }

    alphabetvalidationWithMajorSplChar(num) {
        return /^[a-z A-Z.,_-]+$/.test(num);
    }

    alphabetvalidation(num) {
        return /^[a-z A-Z]*$/.test(num);
    }

    numberValidation(num) {
        return /^[0-9+-]+$/.test(num);
    }

    renderTextBox = (stateKey, label) => {
        var states = this.state
        return (
            <View style={{ marginTop: "2vh" }}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.5vw', backgroundColor: "white", paddingLeft: '0.3', paddingRight: '0.3', color: color.black }}>{label}</Text>
                <TextInput
                    value={states[stateKey]}
                    maxLength={stateKey === "mobile_no" ? 14 : stateKey === "doc_reg_no" ? 7 : '' }
                    style={{ fontSize: '1vw',  paddingHorizontal: '0.5vw', borderColor: (states.addClicked && ((states[stateKey]).trim() == "")) ? color.brightRed : color.boxColor, borderWidth: 1, borderRadius: 4, height: '5vh', marginTop: '0.5vw', }}
                    onChangeText={(text) => {
                        if (stateKey === "doc_name" || stateKey === "doc_specialization") {
                            var isNum = this.alphabetvalidation(text)
                        } else if (stateKey === "doc_qualification") {
                            var isNum = this.alphabetvalidationWithMajorSplChar(text)
                        } else if (stateKey === "doc_reg_no" || stateKey === "mobile_no") {
                            var isNum = this.numberValidation(text)
                        } else {
                            var isNum = true
                        }

                        if (isNum) {
                            states[stateKey] = text
                            this.setState({
                                states
                            })
                        } else if (text == "") {
                            states[stateKey] = text
                            this.setState({
                                states
                            })
                        }

                    }}
                />
            </View>
        )
    }

    postReferredDoc = () => {
        var states = this.state
        this.setState({
            addClicked: false
        })
        if ((states.doc_name).trim() && (states.mobile_no).trim() && (states.doc_reg_no).trim() && (states.doc_qualification).trim() && (states.doc_specialization).trim() && (states.doc_clinic_name).trim() && states.mobile_no.length > 9) {
            var data = {
                name: (states.doc_name).trim(),
                mobile_no: (states.mobile_no).trim(),
                reg_no: (states.doc_reg_no).trim(),
                qualification: (states.doc_qualification).trim(),
                specialization: (states.doc_specialization).trim(),
                clinic_name: (states.doc_clinic_name).trim()
            }

            if (states.edit_doc_id) {
                data["id"] = states.edit_doc_id
            }
            if (this.state.userType !== Constants.ROLE_FO){
                data["clinic_id"] = states.selectedClinicOrDoctor.id
            }
            var states = this.state
            
            var url = Constants.FO_REFERRED_DOCTOR 

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.postReferredDocSuccess,
                this.postReferredDocFailure
            );

        }
        
        else if((states.doc_name).trim() && (states.doc_reg_no).trim() && (states.doc_qualification).trim() && (states.doc_specialization).trim() && (states.doc_clinic_name).trim()){
            var data = {
                name: (states.doc_name).trim(),
                reg_no: (states.doc_reg_no).trim(),
                qualification: (states.doc_qualification).trim(),
                specialization: (states.doc_specialization).trim(),
                hospital_name: (states.doc_clinic_name).trim()
            }
    
            if(states.edit_doc_id){
                data["id"] = states.edit_doc_id
            }

            var url = Constants.LAB_REFERRED_DOCTOR
            
            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.postReferredDocSuccess,
                this.postReferredDocFailure
            );

        } else {
            this.setState({
                addClicked: true
            })
                this.props.showResposeValue("error", "Please Enter the All Valid Details")
        
        }

    }

    postReferredDocSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getReferredDocList()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    onPressClear = () => {
        this.setState({
            doc_name: "",
            mobile_no: "",
            doc_reg_no: "",
            doc_qualification: "",
            doc_specialization: "",
            doc_clinic_name: "",
            edit_doc_id: null,
            addClicked: false,
        })
    }

    deleteReferredDocPost = () => {
        var states = this.state
        if (this.state.userType === Constants.ROLE_FO){
            var url = Constants.FO_REFERRED_DOCTOR + "?id=" + this.state.deletedId
        }else if(states.userType === "LAB"){
            var url = Constants.LAB_REFERRED_DOCTOR + "?id=" + this.state.deletedId
        }else{
            var url = Constants.FO_REFERRED_DOCTOR + "?id=" + this.state.deletedId + "&clinic_id=" + states.selectedClinicOrDoctor.id
        }

        this.setState({
            deletedId: null,
            isDeleteClick: false
        })

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.postReferredDocSuccess,
            this.deleteReferredDocFailure
        )

    }
    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }

    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : this.state.userType === "LAB" ? [
                            { label: "Referred Doctors", value: "labRefferedDoctors"}
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                    selectedTab={"labRefferedDoctors"}
                    isNavigationBorder={true} />
                <View style={{ flex: 1, flexDirection: "row" }}>
                    <View style={styles.labRefDoc_LeftSide}>
                        <View style={{
                            paddingHorizontal: '1vw',
                            paddingTop: '1.2vw', 
                            paddingBottom: '0.5vw', 
                            flex: 1, height: "100%", backgroundColor: color.white, zIndex: -1
                        }}>
                            { this.state.userType === "LAB" ? this.renderLabTableHeader() : this.renderTableHeader()}
                            { this.state.userType === "LAB" ? this.renderLabTableData() : this.renderTableData()}
                        </View>
                    </View>
                    <View style={styles.labRefDoc_RightSide} >
                        <View style={{ paddingHorizontal: '0.8vw', paddingVertical: '0.8vw', height: "100%" }}>
                            <CommonHistorySectionHeader
                                heading1={"Doctor Details"}
                                heading2={""}
                                columnSize={[0.7, 0.3]}  //total value is == of 1
                                noOfColumn={2}
                            />
                            {this.state.userType !== "LAB" ?
                            <View>
                            {this.renderTextBox("doc_name", "Doctor Name")}
                            {this.renderTextBox("mobile_no", "Mobile No")}
                            {this.renderTextBox("doc_reg_no", "Registration Number")}
                            {this.renderTextBox("doc_qualification", "Qualification")}
                            {this.renderTextBox("doc_specialization", "Specialization")}
                            {this.renderTextBox("doc_clinic_name", "Hospital Name")}
                            </View>
                            : 
                            <View>
                                {this.renderTextBox("doc_name", "Doctor Name")}
                                {this.renderTextBox("doc_reg_no", "Registration Number")}
                                {this.renderTextBox("doc_qualification", "Qualification")}
                                {this.renderTextBox("doc_specialization", "Specialization")}
                                {this.renderTextBox("doc_clinic_name", "Hospital Name")}
                            </View>}
                            <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: "6vh" }}>
                                <View style={{ marginRight: '1vw' }}>
                                    <CommonButton
                                        item={{}}
                                        selectedvalue={{}}
                                        butttonText={"Add"}
                                        buttonType={"theme"}
                                        buttonIcon={""}
                                        rightIcon={true}
                                        buttonAction={() => { this.postReferredDoc() }}
                                        buttonKey={"add"} />
                                </View>
                                <View style={{ marginLeft: "1vw" }}>
                                    <CommonButton
                                        item={{}}
                                        selectedvalue={{}}
                                        butttonText={"Clear"}
                                        buttonType={"outlineTheme"}
                                        buttonIcon={""}
                                        rightIcon={true}
                                        buttonAction={() => { this.onPressClear() }}
                                        buttonKey={"clear"} />
                                </View>
                            </View>
                        </View>
                    </View>
                    {this.state.isDeleteClick ?
                        <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
                            <View style={{ paddingHorizontal: '2vw', paddingVertical: '3vh', backgroundColor: color.white, width: '22vw', height: '22vh', borderRadius: 8, alignItems: "center", borderWidth: 1, }}>
                                <Text style={{ fontSize: '1.1vw', fontWeight: "bold", marginBottom: '3vh', }}>{"Are you sure ?"}</Text>
                                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", height: '5vh' }}
                                        onPress={() => {
                                            this.deleteReferredDocPost()
                                        }}
                                    >
                                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"Yes"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", height: '5vh' }}
                                        onPress={() => {
                                            this.setState({
                                                deletedId: null,
                                                isDeleteClick: false
                                            })
                                        }}>
                                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"No"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                        : null
                    }
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: '1vw',
        color: "black",
        fontWeight: '500'
    },
    docListFontStyle: {
        fontSize: '1vw',
        color: "black"
    },
    labRefDoc_LeftSide: {
        flex: 0.65,
        height: '80vh',
        paddingVertical: '0.8vw',
        borderRadius: 4,
        margin: '0.5vw',
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2
    },
    labRefDoc_RightSide: {
        flex: 0.35,
        height: '80vh',
        backgroundColor: color.themeShade,
        borderRadius: 4,
        shadowColor: color.grayShade,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 2,
        margin: '0.5vw'
    }
});  