import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
// import { TouchableOpacity } from 'react-native-gesture-handler';

// import { connect } from "react-redux";
// import { updateName } from '../../../../Redux/PsyAction';

const pageName = "educationHistory"


export class EducationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofEducationHistory: {},

            hideEditIcon: false,
            isheading:false,
        }
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getEducationHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount() {
        this.getEducationHistory()
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofEducationHistory, pageName)
        }, 100);

    }

    getEducationHistory() {
        var service_url = Constants.PSY_EDUCATION_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getEducationHistorySuccess,
            this.getEducationHistoryFailure
        );
    }

    getEducationHistorySuccess = success => {
        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "attend_special_school" ) {
                        prepareData = {
                            key: "Attending special school",
                            value: data[key] ? "Yes" : "No",
                            details: data.attend_special_school_details
                        }
                    } 
                    booleanValues.push(prepareData)
                } else if (key !== "attend_special_school" ) {
                    var prepareData = {};
                    if (key == "age_school_started") {
                        prepareData = {
                            key: "Age when started school",
                            value: data[key],
                        }
                    } else if (key == "current_level_education") {
                        prepareData = {
                            key: "Current level of education",
                            value: data[key],
                        }
                    } else if (key == "school_name_details") {
                        prepareData = {
                            key: "School Name & Details",
                            value: data[key],
                        }
                    } else if (key == "quality_academic_performance") {
                        prepareData = {
                            key: "Quality of Academic performance",
                            value: data[key],
                        }
                    } else if (key == "family_interact_pattern") {
                        prepareData = {
                            key: "Family Interaction Pattern",
                            value: data[key],
                        }
                    } else if (key == "syllabus") {
                        prepareData = {
                            key: "Syllabus",
                            value: data[key]&&data[key]=="1"?"State":
                            data[key]&&data[key]=="2"?"Matriculation":
                            data[key]&&data[key]=="3"?"CBSE":
                            data[key]&&data[key]=="4"?"ICSC":
                            data[key]&&data[key]=="5"?"Angloindian":""
                         
                        }
                    } else if (key == "extra_curricular") {
                        prepareData = {
                            key: "Extra Curricular",
                            value: data[key],
                        }
                    } else if (key == "peer_relationship") {
                        prepareData = {
                            key: "Peer relationship",
                            value: data[key],
                        }
                    } 
                    stringVaues.push(prepareData)
                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofEducationHistory: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getEducationHistoryFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Education"}
                editImage={this.state.hideEditIcon || Object.keys(this.state.getListofEducationHistory).length == 0  ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }

    renderListOfData(item) {
        if (item.value ) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }

    render() {
        return (
            <View style={styles.container}>
                {/* {this.sampleButtons()} */}
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }

    // sampleButtons() {

    //     return (
    //         <View style={{ flexDirection: 'row' }}>


    //             <TouchableOpacity

    //                 style={{ marginHorizontal: 30, paddingHorizontal: 15, paddingVertical: 5, backgroundColor: "pink", borderRadius: 5 }}>
    //                 <Text>{this.props.historyName}</Text>
    //             </TouchableOpacity>

    //             <TouchableOpacity
    //                 onPress={() => { this.props.updateName("sri") }}
    //                 style={{ paddingHorizontal: 15, paddingVertical: 5, backgroundColor: "#888888", borderRadius: 5 }}>
    //                 <Text>{"change name as a SRI"}</Text>
    //             </TouchableOpacity>
    //             <TouchableOpacity
    //                 onPress={() => { this.props.updateName("Gokul")}}
    //                 style={{ marginHorizontal: 30, paddingHorizontal: 15, paddingVertical: 5, backgroundColor: "pink", borderRadius: 5 }}>
    //                 <Text>{"change name as a GOKUL"}</Text>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});


// const mapStateToProps = (state) => {
//     return{
//     historyName: state.changeHistoryName.historyName
//     }
    
// };

// const mapDispatchToProps = (dispatch) => {
//     return{
//         updateName: (data) => dispatch(updateName(data))}
//             };
// export default connect(mapStateToProps, mapDispatchToProps)(EducationHistory);

