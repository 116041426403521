import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
var Messages = require('../../../../utils/InfoMessages')


const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;

const screenName = "Hamilton Anxiety Rating Scale"

export class HamiltonAnxietyRatingScale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofHamiltonAnxietyRatingScale: {},
            comments: "",
            totalScore: 0,
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId
        }
    }

    componentDidMount() {
        this.getHamiltonAnxietyRatingScale()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getHamiltonAnxietyRatingScale()
        })
    }

    getHamiltonAnxietyRatingScale() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL + 
        "?patient_id="+this.state.patientId+
        "&appointment_id="+this.state.appointmentId+
        "&rating_scale_name="+screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHamiltonAnxietyRatingScaleSuccess,
            this.getHamiltonAnxietyRatingScaleFailure
        );
    }

    getHamiltonAnxietyRatingScaleSuccess = (success) => {
        if (success.status == "success") {
            this.setState({
                listofHamiltonAnxietyRatingScale: success.data,
                comments: success.data.comments? success.data.comments:"",
                totalScore: success.data.total_score,
                getTotalScore:success.data.total_score,
            })
        }
    }
    getHamiltonAnxietyRatingScaleFailure = error => { }


    validateTotalScores() {
        var { listofHamiltonAnxietyRatingScale } = this.state;
        var getListOfQuestions = listofHamiltonAnxietyRatingScale.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            getTotalScore = getTotalScore+getOptions[j].option_score
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore:  getTotalScore
        })
    }


    saveHamiltonAnxietyRatingScale() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL;
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var data = {
            "id": this.state.listofHamiltonAnxietyRatingScale.id ? this.state.listofHamiltonAnxietyRatingScale.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofHamiltonAnxietyRatingScale.rating_scale_id,
            "rating_scale_name": this.state.listofHamiltonAnxietyRatingScale.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": this.state.listofHamiltonAnxietyRatingScale.questions
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveHamiltonAnxietyRatingScaleSuccess,
            this.saveHamiltonAnxietyRatingScaleFailure
        );
        }else{
            this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
        }
    }

    saveHamiltonAnxietyRatingScaleSuccess = success => {
        if (success.status == "success") {
            this.getHamiltonAnxietyRatingScale()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveHamiltonAnxietyRatingScaleFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(indexOfQuestion, indexOfOptions) {
        var { listofHamiltonAnxietyRatingScale } = this.state;
        var getListOfOptions = listofHamiltonAnxietyRatingScale.questions[indexOfQuestion].options;
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        listofHamiltonAnxietyRatingScale.questions[indexOfQuestion]["options"] = prepareData
        this.setState({ listofHamiltonAnxietyRatingScale }, () => {
            this.validateTotalScores()
        })

    }

    // renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
    //     return (
    //         <View key={indexOfOptions} >
    //             <TouchableOpacity
    //                 onPress={() => {
    //                     this.onselectOptions(indexOfQuestion, indexOfOptions)
    //                 }}
    //                 style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
    //                 <View style={{ flex: 0.05 }} >
    //                     <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={24} />
    //                 </View>
    //                 <View style={{ flex: 0.95 }} >
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    // renderListOfDatas(questions, indexOfQuestion) {
    //     var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
    //     return (
    //         <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
    //             <View key={indexOfQuestion} style={{ flexDirection: 'row' }}>
    //             <View style={{ flex: 0.8 }}>
    //                 <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{getQuestion}</Text>
    //             </View>
    //             <View style={{ flex: 0.2, flexDirection: 'row' }}>
    //                 <View style={{ flex: 0.5 ,alignItems:'center' }}>
    //                     <TouchableOpacity onPress={()=>{
    //                         this.onselectOptions(indexOfQuestion,0)
    //                     }}>
    //                         <CheckBoxIcon name={questions.options[0].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
    //                     </TouchableOpacity>
    //                 </View>
    //                 <View style={{ flex: 0.5 ,alignItems:'center' }}>
    //                     <TouchableOpacity onPress={()=>{
    //                         this.onselectOptions(indexOfQuestion,1)
    //                     }}>
    //                         <CheckBoxIcon name={questions.options[1].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
    //                     </TouchableOpacity>
    //                 </View>
    //             </View>
    //         </View>
    //         </View>
    //     )
    // }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                </View>
                </View>
        )
    }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',  paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                {/* <View style={{ flex: 0.2, flexDirection: 'row' }}>
                    <View style={{ flex: 0.5,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Mostly"}</Text>
                    </View>
                    <View style={{ flex: 0.5 ,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Always"}</Text>
                    </View>
                </View> */}
            </View>
        )
    }


    renderListOfRows(item,index){
        var questionHeading= (index+1)+". "+item.question_label;
        return(
            <View key={index}>
                <View style={{flexDirection:'row',backgroundColor:color.sectionHeadingDarkColor,minHeight:35}}>
                <Text style={{marginHorizontal: 20,flex:0.52,alignSelf:"center",fontSize:15}}>{questionHeading}</Text>
                <Text style={{flex:0.1,textAlign:'center',alignSelf:"center",fontSize:13}}>{index==0? "Not Present":""}</Text>
                <Text style={{flex:0.08,textAlign:'center',alignSelf:"center",fontSize:13}}>{index==0? "Mild":""}</Text>
                <Text style={{flex:0.1,textAlign:'center',alignSelf:"center",fontSize:13}}>{index==0? "Moderate":""}</Text>
                <Text style={{flex:0.1,textAlign:'center',alignSelf:"center",fontSize:13}}>{index==0? "Severe":""}</Text>
                <Text style={{flex:0.1,textAlign:'center',alignSelf:"center",fontSize:13}}>{index==0? "Very Severe":""}</Text>
                </View>
                <View style={{flexDirection:'row',minHeight:38}}>
                    {/* <View style={{}}> */}
                        <Text style={{marginHorizontal: 20,flex:0.52,alignSelf:'center',marginVertical:5}}>{item.question_description}</Text>
                    {/* </View> */}
                    <View style={{flex:0.1,borderLeftColor:"#888888",borderLeftWidth:0.5,alignItems:'center',justifyContent:'center'}}>
                        <TouchableOpacity onPress={()=>{
                                this.onselectOptions(index,0)
                            }}>
                                <CheckBoxIcon name={ item.options[0].is_selected ? "check-circle-outline": "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:0.08,borderLeftColor:"#888888",borderLeftWidth:0.5,alignItems:'center',justifyContent:'center'}}>
                        <TouchableOpacity onPress={()=>{
                                this.onselectOptions(index,1)
                            }}>
                                <CheckBoxIcon name={item.options[1].is_selected ? "check-circle-outline": "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:0.1,borderLeftColor:"#888888",borderLeftWidth:0.5,alignItems:'center',justifyContent:'center'}}>
                        <TouchableOpacity onPress={()=>{
                                this.onselectOptions(index,2)
                            }}>
                                <CheckBoxIcon name={ item.options[2].is_selected ? "check-circle-outline":"checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:0.1,borderLeftColor:"#888888",borderLeftWidth:0.5,alignItems:'center',justifyContent:'center'}}>
                        <TouchableOpacity onPress={()=>{
                                this.onselectOptions(index,3)
                            }}>
                                <CheckBoxIcon name={ item.options[3].is_selected ? "check-circle-outline":"checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:0.1,borderLeftColor:"#888888",borderLeftWidth:0.5,alignItems:'center',justifyContent:'center'}}>
                        <TouchableOpacity onPress={()=>{
                                this.onselectOptions(index,4)
                            }}>
                                <CheckBoxIcon name={ item.options[4].is_selected ? "check-circle-outline":"checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("Hamilton Anxiety Rating Scale")}
                        {/* {this.renderListOfRows()} */}
                        {
                            this.state.listofHamiltonAnxietyRatingScale && this.state.listofHamiltonAnxietyRatingScale.questions && this.state.listofHamiltonAnxietyRatingScale.questions.length > 0 ?
                                this.state.listofHamiltonAnxietyRatingScale.questions.map((item, index) => {
                                    return (
                                        this.renderListOfRows(item, index)
                                    )
                                })
                                : null
                        }

                        <View style={{ backgroundColor: color.applicationBackgroundColor,height:60,justifyContent:'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveHamiltonAnxietyRatingScale.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})