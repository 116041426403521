import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonSectionHeader } from '../BaseComponent'
import Styles from "../../../styles/Style";
import { ServerlessApplicationRepository } from "aws-sdk";

const platform = Platform.OS;
const pageName = "illness";

export default class SystemicIllnessNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      favoriteDrugs: [],
      selectedEye: {},
      systamicIllnessSelectedItems: [],
      id: null,
      name: "",
      years: "",
      month: "",
      day: 0,
      complaints: "",
      comments: "",
      treatment: "",

      isEdit: false,
      newItem: '',
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      isDisable: false
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getFavoriteDrugs();
  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;

    if (props.editItem) {

      if (props.editItem.title === "illness") {

        let item = props.editItem.item;

        if (id !== item.id) {

          var selectedEye = { label: item.illness_name, value: item.illness_name }

          // selectedEye have to set up here
          // let selectedEye = "";

          // favoriteDrugs.map((favItem) => {
          //   if (favItem.label === item.illness_name) {
          //     // selectedEye = favItem.value;
          //     selectedEye = favItem;
          //   }
          // })

          this.setState({
            id: item.id,
            selectedEye: selectedEye,
            illness_name: item.illness_name,
            years: item.year,
            month: item.month,
            day: item.day,
            treatment: item.treatment
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  getFavoriteDrugs = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_ILLNESS;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
    );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];
      let data = response.data;

      var getDefaultDrugs = Constants.DEFAULT_DRUGS_IN_SYSTEMIC_ILLNESS

      getDefaultDrugs.map((item) => {
        drugs.push(item);
      });


      if (data && data.map) {

        data.map((item) => {
          drugs.push({ value: item, label: item });
        });
      }

      //if(drugs.length === 0){ drugs = [{ "value": "Illness One", "label": "Illness One" }]; }

      var field = this.state;
      field["favoriteDrugs"] = this.removeDuplicate(drugs);

      this.setState({ field });

    }
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }


  addComplaint = event => {

    this.setState({
      isDisable: true
    })

    let errorMessage = "";
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_ILLNESS;

    let illness_name = "";

    states.favoriteDrugs.map((item) => {

      if (item.value === states.selectedEye.value) {

        illness_name = item.label;
      }
    });

    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "patient_id": this.state.patientAppointment.patient_id,
      // "illness_name": illness_name,
      "year": states.years || 0,
      "month": states.month || 0,
      "day": states.day,
      "treatment": states.treatment
    };

    if (states.id) {
      data['id'] = states.id;
    }

    if (states.id) {
      data["illness"] = [illness_name];
    } else {
      var datas = []
      for (var i = 0; i < states.systamicIllnessSelectedItems.length; i++) {
        datas.push(states.systamicIllnessSelectedItems[i].value)
      }
      data["illness"] = datas;
    }

    if (data['illness'].length === 0) {
      errorMessage = errorMessage ? errorMessage + ",Please choose illness" : "Please choose illness";
    }

    if (errorMessage) {
      this.props.showResposeValue("error", errorMessage);
    } else {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getMedicationSuccess,
        this.getMedicationFailure
      );
    }
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };


  getMedicationSuccess = response => {
    //console.log("success ---->"+(JSON.stringify(response)))
    //alert(JSON.stringify(response))


    if (response.status === "success") {
      this.setState({
        isDisable: false
      })
      this.props.clearEditedItem(pageName, {})
      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });

      // clear data
      this.clearComplaint();

      // this.showSuccessMessage();
      // reload data
      this.props.refreshData("systemic");
      this.props.showResposeValue("success", response.message)
    } else {
      this.setState({
        isDisable: false
      })
      this.props.showResposeValue("error", response.message)
    }
  };

  getMedicationFailure = error => {
    this.setState({
      isDisable: false
    })
    //alert("complaint  created unsuccessfully")
    this.showSuccessMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = {};
    states['illness_name'] = "";
    states['years'] = "";
    states['month'] = "";
    states['day'] = "0";
    states['treatment'] = "";
    states["systamicIllnessSelectedItems"] = []
    states["id"] = ""
    this.setState({ states }, () => {
      // this.props.clearEditedItem(pageName,{})
    });

  }

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  addToSuggestionList = (event) => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if (newItem) {

      favoriteDrugs.push({ value: newItem, label: newItem });

      field["favoriteDrugs"] = this.removeDuplicate(favoriteDrugs);
      field["selectedEye"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }


  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" || key == "systamicIllnessSelectedItems") {

      if (this.state.id) {
        var { selectedEye } = this.state;
        selectedEye = selectedvalue;
        this.setState({ selectedEye: selectedvalue })
        // alert(JSON.stringify(selectedEye))
      } else {
        var { systamicIllnessSelectedItems } = this.state;

        var getSelectedValue;
        var spliceData;

        if (systamicIllnessSelectedItems.length > 0) {
          for (var i = 0; i < systamicIllnessSelectedItems.length; i++) {
            if (systamicIllnessSelectedItems[i].value == selectedvalue.value) {
              spliceData = systamicIllnessSelectedItems[i];
              systamicIllnessSelectedItems.splice(i, 1)
              var data = this.removeDuplicate(systamicIllnessSelectedItems);
              this.setState({ systamicIllnessSelectedItems: data })
              return
            } else {
              if (spliceData && spliceData.value !== selectedvalue.value) {
              } else {
                getSelectedValue = selectedvalue
              }
            }
          }

          if (getSelectedValue) { systamicIllnessSelectedItems.push(selectedvalue); }
        } else {
          systamicIllnessSelectedItems.push(selectedvalue)
        }

        var data = this.removeDuplicate(systamicIllnessSelectedItems);
        this.setState({ systamicIllnessSelectedItems: data })

      }

    }
  }

  render() {

    return (
      <TouchableWithoutFeedback onPress={this.addToSuggestionList.bind(this)}>
        <View>
          {/* {this.renderSubHeadingBorder("Systemic Illness", false, true)} */}
          <DoctorNotesCommonRightSideHeader title={"Systemic Illness"} clearAllData={this.clearComplaint.bind(this)} />
          <CommonSectionHeader heading={"Illness"} />
          {this.renderSubHeadingBorder("", false)}
          {/* {this.renderTitleWithMultipleBtn("Illness", this.state.favoriteDrugs, 3, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true)} */}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              this.state.favoriteDrugs.map((item, index) => {
                // var data = {
                //     label: item.speciality_name,
                //     value: item.id
                // }
                var data
                if (this.state.id) {
                  data = this.state.selectedEye
                } else {
                  data = this.state.systamicIllnessSelectedItems
                }

                // console.log("json item----> "+JSON.stringify(item))
                return (
                  <View style={styles.CommonBtnView} key={index}>
                    <CommonButton
                      disable={this.state.isLocked}
                      item={item}
                      selectedvalue={data}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={this.state.id ? "selectedEye" : "systamicIllnessSelectedItems"} />
                  </View>
                )
              })
            }
          </View>


          {/* {this.renderIconBtn("plus", "New", true)} */}
          {this.state.isEdit ?
            <View
              style={styles.IllnessTextBoxview}
            ><TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.IllnessTextInput, {borderColor: color.black, color: color.black,}, Styles.allButtonBorderRadius]}
                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newItem}
                onChangeText={text => this.setState({ selectedEye: '', newItem: text })}
                onSubmitEditing={this.addToSuggestionList.bind(this)}
              /></View> :
            <TouchableOpacity style={{ alignSelf: 'flex-start' }} onPress={() => { this.triggerNewItemAdd() }} disabled={this.state.isLocked}>
              {this.renderIconBtn("plus", "New", false, "key")}
            </TouchableOpacity>
            // this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this))
          }
          <View style={{ flexDirection: "row", marginTop: '1.2vw' }}>
           <View style={styles.YearView}>
             <Text style={{fontSize: '0.9vw'}}>Year</Text>
           </View>  
           <View style={styles.MonthView}>
             <Text style={{fontSize: '0.9vw'}}>Months</Text>
           </View>  
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: '0.6vw',
              marginBottom: '2vw'
              // , justifyContent: "space-evenly"
            }}
          >

            {this.renderNumberTxtWithHeader("", "years", this.state.years, this.state.isLocked)}

            {this.renderNumberTxtWithHeader("", "month", this.state.month, this.state.isLocked)}

            {/*{this.renderNumberTxtWithHeader("Days", "day", this.state.day || 0)} */}

          </View>

          {/* {this.renderTextFieldView("Comments")} */}
          <CommonSectionHeader heading={"Treatment"} />
          <View style={{ marginTop: '1.2vw' }}>
            {this.renderTextFieldView("", "treatment", this.state.treatment, true, 4, '', {}, "", false, this.state.isLocked)}
          </View>
          <View style={{ marginTop: '1.2vw', alignSelf: "center" }}>
            <TouchableOpacity disabled={(this.state.isDisable || this.state.isLocked) ? true : false} onPress={this.addComplaint.bind(this)}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View>
            {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Systemic illness Created Successfully' : 
                    this.state.errorMessageFlag ?'Systemicllness Failed' : null}</Text>
                </View>
              ) :null} */}
          </View >
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
const styles = StyleSheet.create({
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      IllnessTextBoxview: {
        height: '2vw',
        marginTop: '0.6vw',
        // backgroundColor: color.white,
        width: '10.6vw'
      },
      IllnessTextInput: {
        // borderRadius: 20,
        borderWidth: 1,
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        alignSelf: 'flex-start',
        fontSize: '0.9vw'
      },
      YearView: {
        backgroundColor:"#DCDCDC",
        width:'5.5vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center"
      },
      MonthView: {
        backgroundColor:"#DCDCDC",
        width:'7vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center",
        marginLeft:'0.6vw'
      }
})