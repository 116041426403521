import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
// import Arrow from "react-native-vector-icons/FontAwesome5";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import BirthHistory from './BirthHistory';
import BirthHistoryNew from './BirthHistoryNew';

import MedicalProblems from './MedicalProblems';
import MedicalProblemsNew from './MedicalProblemsNew';

import { NavigationTopHeader } from '../../BaseComponent'

const screenHeight = Dimensions.get("window").height;

const navigationHeaderList=[
    {label:"Birth History",value:"isBirthHistoryNew"},
    {label:"Medical Problems",value:"isMedicalProblemsNew"}

]

export class PDHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            scrollPosition: 0,
            selectedSection: "isBirthHistoryNew",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
        }
    }

    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            isBirthHistoryNew: <BirthHistoryNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
            isMedicalProblemsNew: <MedicalProblemsNew refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />,
     
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }



    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Icon size={25} name={"ban"} color={"#D26560"} />
                    <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                        <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }


    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }
    changeTabSection(selectedTab){
        this.setState({
            selectedSection:selectedTab
        })
      }

    render() {
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>
                    <View style={{ width: "100%"}}>
                        <NavigationTopHeader
                            changeTabSection={this.changeTabSection.bind(this)}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={this.state.selectedSection} />
                    </View>    
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 50 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >

                        <View style={Style.doctorNotesSectionView}>
                            <BirthHistory selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            />
                        </View>

                        <View style={Style.doctorNotesSectionView}>
                            <MedicalProblems selectedPageName={this.state.selectedSection}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                selectedRightSideView={this.selectedRightSideView.bind(this)}
                                editHistory={this.editHistory.bind(this)}
                                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                showResposeValue={this.showResposeValue.bind(this)}
                            />
                        </View>

                    </ScrollView>
                </View>


                <View style={{ paddingVertical:20,paddingLeft:20,  width: "35%", backgroundColor: color.themeShade, }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%",paddingRight: 10, }}
                        contentContainerStyle={{ height:screenHeight-65 }}
                    >
                        {this.renderComponent()}
                    </ScrollView>
                </View>


            </View>
        )
    }

}