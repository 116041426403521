import React, { Component } from 'react';
import { View, StyleSheet, Text, Dimensions, Image, Platform, Picker, ScrollView } from 'react-native';
import { Tooltip } from 'antd';
import ImagePath from "../../../utils/ImagePaths";
import Style from '../../../styles/Style';
import { CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import { color } from "../../../styles/Color";
import Pagination from '../common/Pagination';
import AsyncStorage from '../../../AsyncStorage';


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;


class AppointmentTypeReport extends Component {
  constructor(props) {
    super(props);
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      AppointmentList: {},
      patientType: [],
      totalAppointments: '',
      walkinAppointments: '',
      telephonicAppointments: '',
      onlineAppointments: '',
      valueData: [],
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      PageName: '',
      Selecteddate: converted_date,
      isClear: false,
      isClearInt: 0,
      isAdminAccess: false
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getAppointmentList()
  }

  getAppointmentList = () => {
    var serviceUrl = Constants.AppointmentReport + `?from_date=${this.state.filterData.fromDate}&to_date=${this.state.filterData.toDate}&appointment_type=${this.state.patientType}`;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getAppointmentListSuccess,
      this.getAppointmentListFailure
    );
  }

  getAppointmentListSuccess = response => {
    if (response.status == "success") {
      this.setState({
        AppointmentList: response.data.type_list,
        totalAppointments: response.data.total_appointment,
        walkinAppointments: response.data.walk_in_appointment,
        onlineAppointments: response.data.online_Appointment,
        telephonicAppointments: response.data.telephonic_appointment
      })
    }
  }

  getAppointmentListFailure = error => {
    console.log("err getAppointmentListSuccess" + JSON.stringify(error))
  }

  PrintPatienToken = (key) => {

    console.log(key, 'key')
    let states = this.state;
    var Date1 = states.Selecteddate.toString()
    var serviceUrl = ''

    serviceUrl = Constants.AppointmentReport + `?from_date=${this.state.filterData.fromDate}&to_date=${this.state.filterData.toDate}&appointment_type=${this.state.patientType}&export_type=pdf`;

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );
  };

  getPrintSuccess = response => {

    if (response) {
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
    console.log(error);
  };

  onPressButton(key, value) {
    if (key == "save") {
      this.getAppointmentList();
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      this.setState({
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        patientType:'',
        AppointmentList: "",
        Selecteddate: converted_date,
        isClear: true,
        isClearInt: 1,
      }, () => {
        this.getAppointmentList()
        this.setState({
          isClear: false
        }, () => {
          this.renderDateFilter()
        })
      })
    }
    else if (key == "print") {
      this.PrintPatienToken(this.state.PageName)
    }
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }

  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }

  renderPickerPatientType() {
    return (
      <View>
        <Picker
          selectedValue={this.state.patientType}
          style={[Style.pickerView, {
            marginTop: "0.32vw", width: "14.64vw", fontSize: '1vw', borderColor:
              this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5'
          }, Style.allButtonBorderRadius]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["patientType"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Appointment Type' value='' />
          <Picker.Item label={"Walk-in Appointment"} value="Walk-in Appointment" />,
          <Picker.Item label={"Telephonic Appointment"} value="Telephonic Appointment" />,
          <Picker.Item label={"Online Appointment"} value="Online Appointment" />
        </Picker>
      </View>
    )
  }

  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    return (
      <View style={styles.bodyContainer}>

        <View style={styles.leftContainer}>
          <View style={styles.leftWrapper}>

            <View>
              <View style={styles.tableHeader}>
                <Text style={styles.Header1}>Patient's Details</Text>
                <Text style={styles.Header2}>Mobile Number</Text>
                <Text style={styles.Header3}>Doctor Name</Text>
                <Text style={styles.Header4}>Appointment Type</Text>
                <Text style={styles.Header5}>Appointment Date</Text>
              </View>

              <ScrollView showsVerticalScrollIndicator={false} style={{ height: '73.5vh' }}>
                {this.state.AppointmentList.length > 0 ? this.state.valueData.map(data => {
                  return (
                    <View style={styles.tableBody}>
                      <View style={styles.Body1}>
                        <View>
                          <Image
                            source={ImagePath.DefaultProfile}
                            style={styles.appointmentTableRowImg}
                          />
                        </View>
                        <View style={{ marginLeft: '.5vw' }}>
                          <View>
                            <Tooltip placement="topLeft" title={data.patient_name}>
                              <Text style={styles.patientName}>
                                {data.patient_name.length > 12 ? data.patient_name.slice(0, 12) + ".." : data.patient_name}
                              </Text>
                            </Tooltip>
                          </View>
                          <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text style={styles.accNo}>
                              {data.account_num}
                            </Text>
                            <Text style={styles.age}>
                              {"(" + data.age + "/" + data.gender + ")"}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.Body2}>
                        <Text style={styles.bodyText}>{data.mob_number}</Text>
                      </View>
                      <View style={styles.Body3}>
                        <Text style={styles.bodyText}>{data.doctor_name === null ? "Nursing" : data.doctor_name}</Text>
                      </View>
                      <View style={styles.Body4}>
                        <Text style={styles.bodyText}>{data.appointment_patient_type}</Text>
                      </View>
                      <View style={styles.Body5}>
                        <Text style={styles.bodyText}>{data.appointment_date}</Text>
                      </View>
                    </View>)
                }) :
                  <View style={styles.NorecordDiv}>
                    <Text style={{ color: color.placeholder, marginTop: "3.25vw" }}>{"No records to be shown"}</Text>
                  </View>
                }
              </ScrollView>
            </View>
            <View style={styles.PaginationDiv}>
              {this.state.AppointmentList && this.state.AppointmentList.length > 0 ?
                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.AppointmentList)} totalItems={this.state.AppointmentList} />
                : null
              }
            </View>
          </View>
        </View>

        <View style={[styles.rightContainer, { height: screenHeight - 65 }]}>
          <View style={styles.rightWrapper}>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>
            <View style={styles.statValContainer}>
              <View style={[styles.statValBox,{width:'8.5vw'}]}>
                <Text style={styles.statAmount}>{this.state.totalAppointments > 0 ? this.state.totalAppointments : "0"}</Text>
                <Text style={styles.statName}>Total Appointments</Text>
              </View>
              <View style={[styles.statValBox,{width:'4.5vw'}]}>
                <Text style={styles.statAmount}>{this.state.walkinAppointments > 0 ? this.state.walkinAppointments : "0"}</Text>
                <Text style={styles.statName}>Walk-In</Text>
              </View>
              <View style={[styles.statValBox,{width:'4.5vw'}]}>
                <Text style={styles.statAmount}>{this.state.telephonicAppointments > 0 ? this.state.telephonicAppointments : "0"}</Text>
                <Text style={styles.statName}>Telephonic</Text>
              </View>
              <View style={[styles.statValBox,{width:'8.5vw'}]}>
                <Text style={styles.statAmount}>{this.state.onlineAppointments > 0 ? this.state.onlineAppointments : "0"}</Text>
                <Text style={styles.statName}>Online Appointments</Text>
              </View>
            </View>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Filter</Text>
            </View>
            <View style={styles.filterContainer}>
              <View style={styles.dropBoxContainer}>
                <View>
                  {this.renderDateFilter()}
                </View>
                <View style={styles.dropBox}>
                  {this.renderPickerPatientType()}
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <View style={styles.buttonWrapper}>
                  {
                    actions.map((item, index) => {
                      return (
                        <CommonButton
                          item={{}}
                          selectedvalue={{}}
                          butttonText={item.label}
                          buttonType={"theme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={item.value} />
                      )
                    })
                  }
                </View>
              </View>
            </View>

          </View>
        </View>

      </View>
    )
  }
}

const styles = StyleSheet.create({
  bodyContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  leftContainer: {
    width: "65%"
  },
  leftWrapper: {
    paddingHorizontal: '.5vw'
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)",
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingVertical: '.5vw',
    paddingHorizontal: '1vw',
    marginTop: '1vw',
    marginBottom: '.2vw'
  },
  Header1: { color: 'black', fontSize: '1vw', fontWeight: '600', width: '25%', display: 'flex', paddingLeft: '1.5vw' },
  Header2: { color: 'black', fontSize: '1vw', fontWeight: '600', width: '17%', display: 'flex' },
  Header3: { color: 'black', fontSize: '1vw', fontWeight: '600', width: '17%', display: 'flex' },
  Header4: { color: 'black', fontSize: '1vw', fontWeight: '600', width: '20%', display: 'flex' },
  Header5: { color: 'black', fontSize: '1vw', fontWeight: '600', width: '20%', display: 'flex' },
  tableBody: {
    paddingVertical: '.3vw',
    paddingHorizontal: '1vw',
    borderBottomWidth: '.1vw',
    borderBottomColor: 'rgb(136, 136, 136)',
    width: "100%",
    display: 'flex',
    flexDirection: 'row'
  },
  Body1: { display: 'flex', flexDirection: 'row', width: '25%' },
  appointmentTableRowImg: {
    height: platform === "web" ? "2.4vw" : "3vw",
    width: platform === "web" ? "2.4vw" : "3vw",
    borderRadius: "20vw",
    borderWidth: ".1vw",
    borderColor: 'red'
  },
  patientName: {
    fontSize: '.95vw',
    fontWeight: "500"
  },
  accNo: {
    fontSize: ".7vw",
    marginTop: '.2vw',
    marginBottom: '.3vw'
  },
  age: {
    fontSize: '.7vw',
    fontWeight: "500",
    marginLeft: '.5vw',
    marginTop: '-0.1vw'
  },
  Body2: { width: "17%", justifyContent: 'center' },
  Body3: { width: "17%", justifyContent: 'center' },
  Body4: { width: "20%", justifyContent: 'center' },
  Body5: { width: "20%", justifyContent: 'center' },
  bodyText: {
    fontSize: '.9vw'
  },
  rightContainer: {
    width: "35%",
    backgroundColor: "rgb(248, 252, 255)",
    padding: ".5vw"
  },
  rightWrapper: {
    marginTop: '.5vw'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  },
  statValContainer: {
    paddingHorizontal: ".8vw",
    paddingVertical: '2vw',
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between'
  },
  statValBox: {
    justifyContent: 'center',
    alignSelf: 'center',
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: color.white,
    height: '4vw',
    borderRadius: 4,
    paddingHorizontal: "1vw",
    marginRight: "1.30vw",
    alignItems: 'center'
  },
  statAmount: {
    fontSize: '.9vw',
    fontWeight: '500',
    padding: ".1vw",
  },
  statName: {
    fontSize: '.65vw',
    padding: ".1vw",
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dropBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '.5vw',
    marginTop: '1vw',
    zIndex: 2
  },
  dropBox: {
    width: "15vw"
  },
  buttonContainer: {
    width: "100%",
    marginTop: '2vw',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonWrapper: {
    width: "70%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  primaryButton: {
    backgroundColor: 'rgb(4, 183, 177)',
    borderRadius: '.2vw',
    width: '5.5vw',
    height: "2.4vw", display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryButton: {
    borderWidth: '1px',
    borderColor: 'rgb(4, 183, 177)',
    borderRadius: '.2vw',
    width: '5.5vw',
    height: "2.4vw", display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  PaginationDiv: { marginLeft: "1.30vw" },
  NorecordDiv: { flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "12vw" }
});

export default AppointmentTypeReport;