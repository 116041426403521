import React,{Component} from 'react';
import {View,Text,StyleSheet,FlatList} from 'react-native';
import {OBGYNHistorySectionHeading,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "perinatalFactors"


export class PerinatalFactors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofPerinatalFactors: {},
            hideEditIcon:false,
            isheading:false

        }
    }

    componentDidMount() {
        this.getPerinatalFactors()
    }

    getPerinatalFactors(){
        var service_url = Constants.PSY_PERINATAL_FACTORSH + "?patient_id=" + this.state.patientAppointment.patient_id+
        "&appointment_id="+this.state.patientAppointment.appointment_id;
        
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPerinatalFactorsSuccess,
            this.getPerinatalFactorsFailure
        );
    }

    getPerinatalFactorsSuccess = success =>{
        if(success.status == "success"){
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "prolonged_labour") {
                        prepareData = {
                            key: "Prolonged Labour",
                            value: data[key] ? "Yes" : "No",
                            details: data.prolonged_labour_detail
                        }
                    }else if (key == "assisted_labour" ) {
                        prepareData = {
                            key: "Assisted Labour",
                            value: data[key] ? "Yes" : "No",
                            details: data.assisted_labour_detail
                        }
                    }else if (key == "induced_labour") {
                        prepareData = {
                            key: "Induced Labour",
                            value: data[key] ? "Yes" : "No",
                            details: data.induced_labour_detail
                        }
                    }else if (key == "fetal_distress") {
                        prepareData = {
                            key: "Fetal Distress",
                            value: data[key] ? "Yes" : "No",
                            details: data.fetal_distress_detail
                        }
                    }else if (key == "abnormal_presentation") {
                        prepareData = {
                            key: "Abnormal Presentation",
                            value: data[key] ? "Yes" : "No",
                            details: data.abnormal_presentation_detail
                        }
                    }else if (key == "cord_around_the_neck"  ) {
                        prepareData = {
                            key: "Cord Around the neck",
                            value: data[key] ? "Yes" : "No",
                            details: data.cord_around_the_neck_detail
                        }
                    }else if (key == "placenta_previa" ) {
                        prepareData = {
                            key: "Placenta Previa",
                            value: data[key] ? "Yes" : "No",
                            details: data.placenta_previa_detail
                        }
                    }
                    booleanValues.push(prepareData)
                // }else if (key !== "neurotic_details" || key !== "psychiatic_details") {
                }else{

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues,...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofPerinatalFactors: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getPerinatalFactorsFailure = error =>{}

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getPerinatalFactors();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name){
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofPerinatalFactors, pageName)
        }, 100);
        
    }

    renderHeader(){
        return(
            <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Perinatal Factors"}
                    editImage={this.state.hideEditIcon || Object.keys(this.state.getListofPerinatalFactors).length == 0 ? "" :"pencil"}
                />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if(item.value ){
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        }else{
            return (<View></View>)
        }
        
    }
    

    render(){
        return(
            <View style={styles.container}>
            {this.renderHeader()}

            {this.renderContent()}
        </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
