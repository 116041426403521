import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, StyleSheet, Image, ScrollView } from "react-native";
import OpthamologyService from "../../../../network/OpthamologyService";
import { color } from "../../../../styles/Color";
import { Constants } from "../../../../utils/Constants";
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../../assets/images/BillConfig/delete.svg'
import DropDownIcon from '../../../../../assets/images/BillConfig/Down_Arrow.svg'

const screenHeight = Dimensions.get("window").height;

export default class OpServiceConfig extends Component {
    constructor(props) {
      super(props);

      this.state = {
        userType: this.props.userType,
        opServiceList: [],
        selectedDropDownIndex: null,
        selectedTypeList: [],
        selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        isDeleteClick: false,
        selectedDeletedId: null,
        isAllDeleteClick: false,
        selectedAllDeletedId: [],
        searchKey: ""
      }
    }

    componentDidMount() {
        this.getOpServiceConfig()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.refreshOpData){
            this.getOpServiceConfig()
        }
        if(nextProps.confirmDeletedOpId != null && nextProps.confirmDeletedOpId != this.state.selectedDeletedId){
            this.setState({
                selectedDeletedId: nextProps.confirmDeletedOpId
            })
            this.onPressDeleteType(nextProps.confirmDeletedOpId)
        }else if(nextProps.confirmDeletedOpAllId.length != 0 && nextProps.confirmDeletedOpAllId != this.state.selectedAllDeletedId){
            this.setState({
                selectedAllDeletedId: nextProps.confirmDeletedOpAllId
            })
            this.onPressDeleteType(nextProps.confirmDeletedOpAllId, "all")
        } 
        if(nextProps.searchKey != this.state.searchKey){
            this.setState({
                searchKey: nextProps.searchKey
            }, () => {
                this.getOpServiceConfig()
            })
        }
    }

    getOpServiceConfig = () => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_OP_SERVICE + "?service_name=" + this.state.searchKey
        }else{
            url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&service_name=" + this.state.searchKey
        }
        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getOpServiceConfigSuccess,
            this.getOpServiceConfigFailure
        );
    }

    getOpServiceConfigSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                opServiceList: response.data,
                selectedTypeList: [],
                selectedDropDownIndex: null,
            })
        }
    }

    onPressDeleteType = (datas, key="") => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_OP_SERVICE
        }else{
            url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
        }

        var data = []
        if(key === "all"){
            for(let i = 0; i < datas.length; i++){
                data.push(datas[i].id)
            }
        }else{
            data.push(datas)
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {id: data},
            this,
            this.onPressDeleteTypeSuccess,
            this.onPressDeleteTypeFailure
        )
    }

    onPressDeleteTypeSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.props.isDeleteClick(null, "op", "revert")
            this.getOpServiceConfig()
        }else if(response.status === "fail") {
            this.props.showResposeValue("error", response.message)
            this.props.isDeleteClick(null, "op", "revert")
        }else{
            this.props.showResposeValue("error", "Something went wrong")
            this.props.isDeleteClick(null, "op", "revert")
        }
    }

    render(){
        var states = this.state
        return(
            <View style={{maxHeight: "90%", height: '-webkit-fill-available' }}>
            <View style={{marginTop: 5, backgroundColor: color.sectionHeadingDarkColor, minHeight: 40, maxHeight: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.7}}>
                    <Text style={styles.headerFontStyle}>{"Service Name"}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
            </View>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>

            {states.opServiceList.length > 0 && states.opServiceList.map((item, index) => (
            <View style={{maxHeight: states.selectedDropDownIndex === index ? 480 : 60, marginHorizontal: 5, paddingHorizontal: 15, minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center"}}>
            <TouchableOpacity
            onPress={() => {
                var {opServiceList, selectedDropDownIndex} = this.state
                var Index = null
                if(selectedDropDownIndex === index){
                    Index = null
                }else {
                    Index = index
                }
                this.setState({
                    selectedDropDownIndex: Index,
                    selectedTypeList: opServiceList[index].data
                })
            }}
            style={{minHeight: 60}}
            >
            <View style={{ flexDirection: 'row', flex: 1, alignItems: "center"}}>
                <View style={{flex: 0.7}}>
                    <Text style={{fontSize: 14,}}>{item.service_name}</Text>
                </View>
                <View style={{flex: 0.3, flexDirection: "row", justifyContent: "space-between"}}>
                    <View style={{flexDirection: "row",}}>
                    <TouchableOpacity
                    onPress={() => {
                        this.getOpServiceConfig()
                        this.setState({

                        },() => {
                            this.props.editOPItem(item)
                        })
                    }}
                    >
                        <Image
                            source={EditIcon}
                            style={{ height: 25, width: 25 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                    style={{marginLeft: 30, width: "fit-content"}}
                    onPress={() => {
                        this.props.isDeleteClick(item.data, "op", "service")
                    }}
                    >
                        <Image
                            source={DeleteIcon}
                            style={{ height: 25, width: 30}}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                    onPress={() => {
                        var {opServiceList, selectedDropDownIndex} = this.state
                        var Index = null
                        if(selectedDropDownIndex === index){
                            Index = null
                        }else {
                            Index = index
                        }
                        this.setState({
                            selectedDropDownIndex: Index,
                            selectedTypeList: opServiceList[index].data
                        })
                    }}
                    >
                        <Image
                            source={DropDownIcon}
                            style={{ height: 25, width: 25 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                </View>
            </View>
            </TouchableOpacity>
            {states.selectedDropDownIndex === index ? 
            <View style={{marginVertical: 10, flex: 1, width: "100%", backgroundColor: color.white}}>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: 400 }}>
                <View style={{ borderWidth: 1, borderColor: color.lightGray}}>
                <View style={{marginVertical: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.4}}>
                    <Text style={styles.headerFontStyle}>{"Service Type"}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={styles.headerFontStyle}>{"Amount"}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
                </View>
                {states.selectedTypeList.length > 0 && states.selectedTypeList.map((item, index) => (
                    <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 15, paddingVertical: 10, }}>
                    <View style={{flex: 0.4}}>
                        <Text style={{fontSize: 14}}>{item.type}</Text>
                    </View>
                    <View style={{flex: 0.3}}>
                        <Text style={{fontSize: 14}}>{item.amount}</Text>
                    </View>
                    <View style={{flex: 0.3,}}>
                    <TouchableOpacity
                    style={{width: "fit-content"}}
                    onPress={() => {
                        this.props.isDeleteClick(item.id, "op", "type")
                    }}
                    >
                        <Image
                            source={DeleteIcon}
                            style={{ height: 30, width: 30 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    </View>
                    </View>
                ))}
                </View>
                </ScrollView>
            </View> 
            : null
            }
            </View>
            ))}
            </ScrollView>
            {
              states.opServiceList.length == 0 ? 
              <View style={{height: "100%", alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontWeight: "bold"}}>No Data Found</Text>
              </View>  
              : null
            }
            </View>
        )
    }

}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 14,
        color: "black",
        fontWeight: '500'
    },
});