import React, { Component } from 'react'
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet, Picker } from "react-native";
import { color } from "../../../../styles/Color";
import Pagination from '../../../doctor/common/Pagination';
import { DatePicker, Tooltip } from 'antd';
import moment from "moment";
import CommonDateFilter from '../../BaseComponent';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import Icon from "react-native-vector-icons/FontAwesome";
import { Checkbox, Radio, Badge } from 'antd';
import ImagePath from '../../../../utils/ImagePaths';


const BillTypeList = [
  { label: "In Patient", value: "IP" },
  { label: "Out Patient", value: "OP" },
  { label: "Laboratory", value: "LAB" },
]

let BillList = []


export default class PatientDetails extends Component {
  constructor(props) {
    super(props);

    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");

    this.state = {
      PatientBillList: [],
      BillList: [],
      SelectedBillList: [],
      corpCompanyList: [],
      PatientBillListAfterPagination: [],

      date: {},
      dateValue: [],
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date,
        label: "Today"
      },
      CorporateSort: "asc",
      BillDateSort: "asc",
      corpSortClicked: true,
      DateSortClicked: false,
      isAllBillsSelected: false,

      FilterPopUpOpen: false,
      isFilterclear: false,
      CompanyNameFilter: false,
      DateFilter: false,
      EmpNameFilter: false,
      BillTypeFilter: false,
      CompanyNameInput: "",
      EmpNameInput: "",
      BilltypeInput: "",
      SelectedCorp: "",
      GenerateBillType: 'consolidate',
      GeneratePopupErrorText: '',
      GenarateBillDescription: '',

      CompanyFiltered: '',
      DateFiltered: {},
      EmployeeFiltered: "",
      BillTypeFiltered: "",
      SelectedCompanyCount: 0,
      DateLabelFiltered: "",
      DateFromDateFiltered: converted_date,
      DateToDateFiltered: converted_date,
      SelectedCorporateId: "",
      SelectedCorporateName: "",
    };
  }

  componentDidMount() {
    this.getPatientDetails()
    this.getCorporateList()
  }


  getPatientDetails = () => {

    if (this.state.corpSortClicked) {
      var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
        "?search_key=" + this.state.CompanyNameInput +
        "&from_date=" + this.state.filterData.fromDate +
        "&to_date=" + this.state.filterData.toDate +
        "&bill_type=" + this.state.BilltypeInput +
        "&name=" + this.state.EmpNameInput +
        "&sort=" + this.state.CorporateSort;
    } else {
      var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
        "?search_key=" + this.state.CompanyNameInput +
        "&from_date=" + this.state.filterData.fromDate +
        "&to_date=" + this.state.filterData.toDate +
        "&bill_type=" + this.state.BilltypeInput +
        "&name=" + this.state.EmpNameInput +
        "&d_sort=" + this.state.BillDateSort;
    }


    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPatientBillListSuccess,
      this.getPatientBillListFailure
    );
  }

  getPatientBillListSuccess = (success) => {
    this.setState({
      FilterPopUpOpen: false,
      PatientBillList: success.data,
      CompanyFiltered: this.state.CompanyNameInput,
      DateFiltered: this.state.filterData,
      EmployeeFiltered: this.state.EmpNameInput,
      BillTypeFiltered: this.state.BilltypeInput,
      SelectedBillList: [],
      isAllBillsSelected: false,
      DateLabelFiltered: this.state.filterData.label,
      DateFromDateFiltered: this.state.filterData.fromDate,
      DateToDateFiltered: this.state.filterData.toDate
    }, () => {
      BillList = this.state.PatientBillList;

      BillList.forEach(object => {
        object.isSelected = false;
      })
      this.setState({
        BillList: BillList
      }, () => { this.storeSelectedBills() })
    })

  }
  getPatientBillListFailure = (error) => {
    this.props.ErrorAlt(error.message)
  }

  getCorporateList = () => {
    var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION + "?search_key=" + "&sort=asc";

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getCorporateListSuccess,
      this.getCorporateListFailure
    );

  }

  getCorporateListSuccess = (success) => {
    this.setState({
      corpCompanyList: success.data.emp_details
    })
  }
  getCorporateListFailure = (error) => {
    this.props.ErrorAlt(error.message)
  }

  paginationChangedValues(data) {
    this.setState({
      PatientBillListAfterPagination: data
    })
  }

  /* Export Excel API start*/
  ExportExcel = () => {
    try {
      if (this.state.corpSortClicked) {
        var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
          "?search_key=" + this.state.CompanyFiltered +
          "&from_date=" + this.state.DateFromDateFiltered +
          "&to_date=" + this.state.DateToDateFiltered +
          "&bill_type=" + this.state.BillTypeFiltered +
          "&name=" + this.state.EmployeeFiltered +
          "&sort=" + this.state.CorporateSort + "&export=excel";
      } else {
        var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
          "?search_key=" + this.state.CompanyFiltered +
          "&from_date=" + this.state.DateFromDateFiltered +
          "&to_date=" + this.state.DateToDateFiltered +
          "&bill_type=" + this.state.BillTypeFiltered +
          "&name=" + this.state.EmployeeFiltered +
          "&d_sort=" + this.state.BillDateSort + "&export=excel";
      }
      OpthamologyService.getInstance().documentUploadGet(
        serviceUrl,
        this.getExcelSuccess,
        this.getExcelFailure,
        "excel"
      );
    }
    catch (error) {
      console.log(error)
    }
  }
  getExcelSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getExcelFailure = (error) => {
    this.props.ErrorAlert("No Data Available !")
  }
  /* Export Excel API end*/


  renderRangePicker() {
    return (

      this.state.isFilterclear ?
        <CommonDateFilter
          defaultSelectedDateFilter={"Today"}
          startDate={this.state.filterDate.fromDate}
          endDate={this.state.filterDate.toDate}
          clearPress={this.state.isFilterclear}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
          filterDate={this.state.filterDate}
          futureDateDisable={true} /> :
        <CommonDateFilter
          defaultSelectedDateFilter={this.state.filterData.label}
          startDate={this.state.filterDate.fromDate}
          endDate={this.state.filterDate.toDate}
          clearPress={this.state.isFilterclear}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
          filterDate={this.state.filterDate}
          futureDateDisable={true} />
    )
  }
  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    states["isFilterclear"] = false
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    filterData["label"] = date.lable
    this.setState({ states, filterData })
  }

  returnSortIcon = (order) => {
    if (order == "") {
      return <Icon name="sort" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
    }
    else if (order == "asc") {
      return <Icon name="sort-up" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
    }
    else if (order == "desc") {
      return <Icon name="sort-down" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
    }
  }

  ClearFilters() {
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.setState({
      CompanyNameFilter: false,
      DateFilter: false,
      EmpNameFilter: false,
      BillTypeFilter: false,
      CompanyNameInput: "",
      EmpNameInput: "",
      BilltypeInput: "",
      isFilterclear: true,
      DateLabelFiltered: "",
      filterData: {
        fromDate: converted_date,
        toDate: converted_date,
        label: "Today"
      },
    }, () => { this.getPatientDetails() })
  }

  FilterPopUp = () => {
    return (
      <View style={styles.FilterPopUpView}>
        <View style={styles.FiterTop}>
          <Text style={styles.FilterHeadTxt}>Choose Filters</Text>
          <TouchableOpacity onPress={() => {
            this.setState({
              FilterPopUpOpen: false
            })
          }}>
            <Icon name="close" size={"1.2vw"} color={color.red} />
          </TouchableOpacity>
        </View>
        <View>
          <View style={styles.FilterRows}>
            <Checkbox
              checked={this.state.CompanyNameFilter}
              onChange={() => {
                this.setState({ CompanyNameFilter: !this.state.CompanyNameFilter }, () => {
                  if (!this.state.CompanyNameFilter) {
                    this.setState({
                      CompanyNameInput: "",
                      SelectedCorp: ""
                    })
                  }
                  else {
                    this.setState({
                      CompanyNameInput: this.state.corpCompanyList.length > 0 ? this.state.corpCompanyList[0].company_name : ""
                    })
                  }
                })
              }} >Company Name </Checkbox>
            {
              this.state.CompanyNameFilter ?
                <Picker

                  selectedValue={this.state.CompanyNameInput}
                  onValueChange={(itemvalue) => {
                    this.setState({
                      CompanyNameInput: itemvalue
                    })
                  }}
                  style={{ borderRadius: '0.26vw', width: "12vw", backgroundColor: color.themeShade, height: "2vw", fontSize: '0.9vw', borderColor: color.themeDark, marginLeft: '0.7vw' }}
                >
                  {this.state.corpCompanyList.map((item, index) => (
                    <Picker.Item key={index} label={item.company_name} value={item.company_name} />
                  ))}
                </Picker>
                : null}
          </View>
          <View style={styles.FilterRows}>
            <Checkbox
              checked={this.state.EmpNameFilter}
              onChange={() => {
                this.setState({ EmpNameFilter: !this.state.EmpNameFilter }, () => {
                  this.setState({
                    EmpNameInput: ""
                  })
                })
              }} >Employee Name </Checkbox>
            {
              this.state.EmpNameFilter ?
                <TextInput
                  style={styles.SearchInput}
                  placeholder="Enter Employee Name"
                  value={this.state.EmpNameInput}
                  onChangeText={(text) => {
                    this.setState({
                      EmpNameInput: text
                    })
                  }}
                />
                : null}
          </View>
          <View style={styles.FilterRows}>
            <Checkbox
              checked={this.state.BillTypeFilter}
              onChange={() => {
                this.setState({ BillTypeFilter: !this.state.BillTypeFilter, BilltypeInput: 'IP' }, () => {
                  if (!this.state.BillTypeFilter) {
                    this.setState({
                      BilltypeInput: ""
                    })
                  }
                })
              }} >Bill Type</Checkbox>
            {
              this.state.BillTypeFilter ?
                <Picker
                  selectedValue={this.state.BilltypeInput}
                  onValueChange={(itemvalue) => {
                    this.setState({
                      BilltypeInput: itemvalue
                    })
                  }}
                  style={{ borderRadius: '0.26vw', width: "12vw", backgroundColor: color.themeShade, height: "2vw", fontSize: '0.9vw', borderColor: color.themeDark, marginLeft: '3.8vw' }}
                >
                  {BillTypeList.map((item, index) => (
                    <Picker.Item key={index} label={item.label} value={item.value} />
                  ))}
                </Picker>
                : null}
          </View>
          <View style={styles.FilterRows}>
            <Checkbox checked={true} >Date Filter</Checkbox>
            <View style={{ marginLeft: '3vw', zIndex: 2 }}>{this.renderRangePicker()}</View>
          </View>
        </View>
        <View style={styles.FilterBtnView}>
          <TouchableOpacity
            onPress={() => { this.setState({ BillList: [], PatientBillList: [] }, () => { this.getPatientDetails() }) }}
            style={styles.BtnTouch}>
            <Text style={styles.ButtonText}>{"Filter"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { this.ClearFilters() }}
            style={[styles.BtnTouch, { backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1 }]}>
            <Text style={[styles.ButtonText, { color: color.themeDark }]}>{"Clear"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  checkBoxChecked = (ID) => {
    this.state.BillList.filter(list => list.id === ID).map(list => (list.isSelected = !list.isSelected))
    this.setState({
      BillList: this.state.BillList,
      SelectedBillList: [],
    }, () => { this.storeSelectedBills() })
  }


  storeSelectedBills = () => {
    this.state.SelectedBillList= this.state.BillList.filter((item)=>item.isSelected == true)
    this.setState({
      SelectedBillList: this.state.SelectedBillList
    }, () => {
      if (this.state.BillList && this.state.BillList.length > 0) {
        const PendingBillsCount = this.state.BillList.filter(list => list.corporate_bill_status === null)
        this.setState({
          isAllBillsSelected: PendingBillsCount.length == this.state.SelectedBillList.length && this.state.SelectedBillList.length > 0 ? true : false
        })
      }
      const SelectedBillList = this.state.SelectedBillList
      let CompanyCount = [... new Set(SelectedBillList.map(x => x.company_name))];
      this.setState({
        SelectedCompanyCount: CompanyCount.length,
        SelectedBillList: this.state.SelectedBillList.sort((a, b) => { return a["invoice_date"].toLowerCase() > b["invoice_date"] ? 1 : -1 })
      }, () => {
        if (this.state.SelectedCompanyCount === 1) {
          this.setState({
            SelectedCorporateId: this.state.corpCompanyList.filter(x => x.company_name.toLowerCase() === CompanyCount[0].toLowerCase()).map(x => x.id)[0],
            SelectedCorporateName: CompanyCount[0]
          })
        }
        else {
          this.setState({
            SelectedCorporateId: "",
            SelectedCorporateName: "",
          })
        }
      })
    })
  }

  SelectAllBills = () => {
    BillList = this.state.PatientBillList;
    for (let i = 0; i < BillList.length; i++) {
      if (BillList[i].corporate_bill_status === null) {
        BillList[i].isSelected = !this.state.isAllBillsSelected;
      }
    }
    this.setState({
      BillList: BillList,
      isAllBillsSelected: !this.state.isAllBillsSelected,
      SelectedBillList: []
    }, () => { this.storeSelectedBills() })
  }


  GenerateBillPopUp = () => {
    return (
      <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "flex-start", alignItems: "center" }}>
        <View style={{ paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "35vw", height: '20vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, marginTop: '2vw' }}>
          <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '1vw', textAlign: 'center' }}>{"Generate Bill to -  "} <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: '1.2vw' }}>{this.state.SelectedCorporateName}</Text></Text>
          {/* <Radio.Group
            onChange={(e) => {
              this.setState({ GenerateBillType: e.target.value });
            }}
            value={this.state.GenerateBillType}
          >
            <Radio value={'consolidate'}>Consolidated</Radio>
            <Radio value={'detailed'}>Detailed</Radio>
          </Radio.Group> */}
          <Text style={{ textAlign: 'center', paddingVertical: '0.5vw', fontWeight: "500", fontSize: '0.9vw' }}> Total Selected Bills : <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: '1.2vw' }}>{this.state.SelectedBillList.length}</Text></Text>
          <Text style={{ textAlign: 'center', paddingVertical: '0.5vw', fontWeight: "500", fontSize: '0.9vw' }}> Total Amount of Selected Bills : <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: '1.2vw' }}>₹ {this.returnSelectedBillsAmount()}</Text></Text>
          <TextInput
            value={this.state.GenarateBillDescription}
            multiline={true}
            maxLength={100}
            style={styles.DescriptionInput}
            placeholder="Description *"
            onChangeText={(text) => { this.setState({ GenarateBillDescription: text }) }}
          />
          <Text style={{ color: color.red, textAlign: 'center', paddingVertical: '0.5vw', fontWeight: "500",fontSize:'0.9vw' }}>{this.state.GeneratePopupErrorText}</Text>
          <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

            <TouchableOpacity
              style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw' }}
              onPress={() => {
                if (this.state.GenarateBillDescription != "") {
                  var service_url = Constants.CORPORATE_BILLING_POST;
                  var data = {
                    "invoice_from_date": this.state.SelectedBillList[0].invoice_date,
                    "invoice_to_date": this.state.SelectedBillList[this.state.SelectedBillList.length - 1].invoice_date,
                    "igst_percent": 0,
                    "sgst_percent": 0,
                    "cgst_percent": 0,
                    "employer_id": this.state.SelectedCorporateId,
                    "amount": this.returnSelectedBillsAmount(),
                    "description": this.state.GenarateBillDescription,
                    "line_items": this.state.SelectedBillList
                  }

                  OpthamologyService.getInstance().postComplaints(
                    service_url,
                    data,
                    this,
                    this.generateBillSuccess,
                    this.generateBillFailure
                  );
                }
                else {
                  this.setState({
                    GeneratePopupErrorText: "Enter Description"
                  })
                }
              }}
            >
              <Text style={{ color: 'white', fontSize: '0.78vw', fontWeight: '500' }}>{"Generate Bill"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.white, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw', borderColor: color.themeDark, borderWidth: 1 }}
              onPress={() => {
                this.setState({
                  isGenerateBillsClicked: false,
                  GeneratePopupErrorText: "",
                  GenarateBillDescription: "",
                })
              }}
            >
              <Text style={{ color: color.themeDark, fontSize: '0.78vw', fontWeight: '500' }}>{"Cancel"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  generateBillSuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        GeneratePopupErrorText: "",
        isGenerateBillsClicked: false,
        PatientBillList:[],
        BillList:[],
        SelectedBillList:[],
        SelectedCorporateId: "",
        SelectedCorporateName: "",
        GenarateBillDescription: "",
      },()=>{this.getPatientDetails()})
    }
    else {
      this.props.ErrorAlert(response.message)
    }

  }
  generateBillFailure = (error) => {
    this.props.ErrorAlert(error.message)
  }

  returnSelectedBillsAmount = () => {
    try{
      return this.state.SelectedBillList.reduce((a, b) => a + (b["discount_amount"] || 0), 0)
    }
    catch (e){
      return 0
    }
  }

  CompleteBillPopUp = () => {
    return (
      <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "flex-start", alignItems: "center" }}>
        <View style={{ paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "30vw", height: '14vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, marginTop: '2vw' }}>
          <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '1vw', textAlign: 'center', color: color.red }}>{"Confirmation"}</Text>
          <Text style={{ textAlign: 'center', paddingVertical: '0.5vw', fontWeight: "500", fontSize: '0.9vw' }}> Total Selected Bills : <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: '1.2vw' }}>{this.state.SelectedBillList.length}</Text></Text>
          <Text style={{ textAlign: 'center', paddingVertical: '0.5vw', fontWeight: "500", fontSize: '0.9vw' }}> Total Amount of Selected Bills : <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: '1.2vw' }}>₹ {this.returnSelectedBillsAmount()}</Text></Text>
          <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>
            <TouchableOpacity
              style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw' }}
              onPress={() => {
                if (this.state.GenarateBillDescription != "") {
                  this.setState({
                    GeneratePopupErrorText: ""
                  })
                }
                else {
                  this.setState({
                    GeneratePopupErrorText: "Enter Description"
                  })
                }
              }}
            >
              <Text style={{ color: 'white', fontSize: '0.78vw', fontWeight: '500' }}>{"Complete Bills"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', height: '2vw' }}
              onPress={() => {
                this.setState({
                  isallCompletedBillsClicked: false,
                })
              }}
            >
              <Text style={{ color: 'white', fontSize: '0.78vw', fontWeight: '500' }}>{"Cancel"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  render() {
    return (
      <View>
        <View style={styles.PageHeaderView}>
          <View style={{ marginLeft: '1.5vw' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox disabled={this.state.PatientBillList.length == 0} checked={this.state.isAllBillsSelected} onChange={() => { this.SelectAllBills() }}> Select All Bills </Checkbox>
              {/* <Tooltip placement='right' title={this.state.SelectedBillList.length > 0 ? "" : "Select Any Bills"}>
                <Badge count={this.state.SelectedBillList.length}>
                  <TouchableOpacity
                  disabled={this.state.SelectedBillList.length > 0 ? false : true}
                  onPress={() => {
                    this.setState({
                      isallCompletedBillsClicked: true
                    })
                  }}
                  style={[styles.BtnTouch, { width: '10vw', backgroundColor: this.state.SelectedBillList.length > 0 ? color.themeDark : color.disableLabelColor }]}>
                  <Text style={styles.ButtonText}>{"Complete Selected Bills"}</Text>
                </TouchableOpacity>
                </Badge>
              </Tooltip> */}
            </View>

          </View>
          <View style={{ display: 'flex', flexDirection: 'row', paddingRight: '2vw' }}>
            <Tooltip title={"Filter Options"}>
              <Badge dot={this.state.CompanyFiltered != "" || this.state.EmployeeFiltered != "" || this.state.BillTypeFiltered != "" || this.state.DateLabelFiltered != "Today"}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      FilterPopUpOpen: !this.state.FilterPopUpOpen
                    })
                  }}
                  style={styles.FilterBTnTouch}>
                  <Icon name="filter" size={"1vw"} color={color.white} />
                </TouchableOpacity></Badge></Tooltip>

            <Tooltip title={"Export As Excel"}>
              <TouchableOpacity
                onPress={() => {
                  this.ExportExcel()
                }}
                style={styles.FilterBTnTouch}>
                {/* <Icon name="file-export" size={"1vw"} color={color.white} /> */}
                <Image
                  source={ImagePath.Export}
                  style={styles.IconImageStyle}
                  resizeMode="contain"
                />
              </TouchableOpacity></Tooltip>
            <Tooltip placement='top' title={this.state.SelectedBillList.length == 0 ? "Select Bills" : this.state.SelectedCompanyCount == 1 ? "" : "Select Only Same Company Bills"}>
              <Badge count={this.state.SelectedCompanyCount == 1 ? this.state.SelectedBillList.length : 0}>
                <TouchableOpacity
                  disabled={this.state.SelectedCompanyCount == 1 ? false : true}
                  onPress={() => {
                    this.setState({
                      isGenerateBillsClicked: true
                    })
                  }}
                  style={[styles.BtnTouch, { width: '10vw', backgroundColor: this.state.SelectedCompanyCount == 1 ? color.themeDark : color.disableLabelColor }]}>
                  <Text style={styles.ButtonText}>{"Generate Bill"}</Text>
                </TouchableOpacity>
              </Badge>
            </Tooltip>
          </View>

        </View>

        <View style={styles.TableContainer}>
          <View>
            <View style={{ width: "100%" }}>
              <View style={[styles.TableTitleContainer]}>
                <View style={{ alignItems: "left", width: '14%' }} >
                  <Text style={styles.TableHeadText}>Employee Name</Text>
                </View>
                <TouchableOpacity
                  style={{ alignItems: "left", width: '12%', display: 'flex', flexDirection: 'row' }}
                  onPress={() => {
                    if (this.state.CorporateSort == "asc") {
                      this.setState({
                        CorporateSort: "desc",
                        BillDateSort: "desc",
                        corpSortClicked: true,
                        DateSortClicked: false,
                        PatientBillList: [],
                        BillList: []
                      }, () => { this.getPatientDetails() })
                    }
                    else if (this.state.CorporateSort == "desc") {
                      this.setState({
                        CorporateSort: "asc",
                        BillDateSort: "desc",
                        corpSortClicked: true,
                        DateSortClicked: false,
                        PatientBillList: [],
                        BillList: []
                      }, () => { this.getPatientDetails() })
                    }
                  }}
                >
                  <Text style={[styles.TableHeadText,]}>Company Name</Text>
                  {this.state.corpSortClicked ? this.returnSortIcon(this.state.CorporateSort) : this.returnSortIcon('')}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ alignItems: "center", width: '8%', display: 'flex', flexDirection: 'row' }}
                  onPress={() => {
                    if (this.state.BillDateSort == "asc") {
                      this.setState({
                        BillDateSort: "desc",
                        CorporateSort: "desc",
                        PatientBillList: [],
                        BillList: [],
                        DateSortClicked: true,
                        corpSortClicked: false,
                      }, () => { this.getPatientDetails() })
                    }
                    else if (this.state.BillDateSort == "desc") {
                      this.setState({
                        BillDateSort: "asc",
                        CorporateSort: "desc",
                        PatientBillList: [],
                        BillList: [],
                        DateSortClicked: true,
                        corpSortClicked: false,
                      }, () => { this.getPatientDetails() })
                    }
                  }}>
                  <Text style={styles.TableHeadText}>Bill Date</Text>
                  {this.state.DateSortClicked ? this.returnSortIcon(this.state.BillDateSort) : this.returnSortIcon('')}
                </TouchableOpacity>
                <View style={{ alignItems: "center", width: '11%' }} >
                  <Text style={styles.TableHeadText}>Bill No</Text>
                </View>
                <View style={{ alignItems: "center", width: '9%' }} >
                  <Text style={styles.TableHeadText}>Bill Type</Text>
                </View>
                <View style={{ alignItems: "center", width: '9%' }} >
                  <Text style={styles.TableHeadText}>Gross Bill Value (₹)</Text>
                </View>
                <View style={{ alignItems: "center", width: '7%' }} >
                  <Text style={styles.TableHeadText}>Discount %</Text>
                </View>
                <View style={{ alignItems: "center", width: '9%' }} >
                  <Text style={styles.TableHeadText}>Discount Amount</Text>
                </View>
                <View style={{ alignItems: "center", width: '11%' }} >
                  <Text style={styles.TableHeadText}>Net Billing Value (₹)</Text>
                </View>
                <View style={{ alignItems: "center", width: '10%' }} >
                  <Text style={styles.TableHeadText}>Corporate Bill Status</Text>
                </View>
              </View>
            </View>
          </View>

          <ScrollView style={styles.MainTable}>
            {this.state.PatientBillList && this.state.PatientBillList.length > 0 && this.state.PatientBillListAfterPagination && this.state.PatientBillListAfterPagination.length > 0 ? this.state.PatientBillListAfterPagination.map((item, index) => {
              return (
                <View style={[styles.TableDataWrapper]} >
                  <View style={{ width: '14%', alignItems: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox
                      disabled={item.corporate_bill_status == 'Bill_Generated' || item.corporate_bill_status == 'Bill_Paid' ? true : false}
                      checked={item.isSelected}
                      onChange={() => { this.checkBoxChecked(item.id) }}
                    />
                    <TouchableOpacity disabled={item.corporate_bill_status == 'Bill_Generated' || item.corporate_bill_status == 'Bill_Paid' ? true : false} onPress={() => { this.checkBoxChecked(item.id) }} >
                      {item.name.length > 22 ?
                        <Tooltip placement="top" title={item.name}>
                          <Text style={[styles.TableDataText, { marginLeft: '0.5vw' }]}> {item.name.slice(0, 22) + ".."}</Text>
                        </Tooltip> :
                        <Text style={[styles.TableDataText, { marginLeft: '0.5vw' }]}> {item.name}</Text>}
                    </TouchableOpacity>

                  </View>
                  <View style={{ width: this.state.PatientBillListAfterPagination.length > 9 ? '12.5%' : '12%', alignItems: 'left', }}>
                    {item.company_name.length > 22 ?
                      <Tooltip placement="top" title={item.company_name}>
                        <Text style={[styles.TableDataText]}> {item.company_name.slice(0, 22) + ".."}</Text>
                      </Tooltip> :
                      <Text style={[styles.TableDataText,]}> {item.company_name}</Text>}
                  </View>
                  <View style={{ width: '8%', alignItems: 'left' }}><Text style={[styles.TableDataText]}>{item.invoice_date}</Text></View>
                  <View style={{ width: '11%', alignItems: 'center'}}><Text style={styles.TableDataText}>{item.invoice_number}</Text></View>
                  <View style={{ width: '9%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.bill}</Text></View>
                  <View style={{ width: '9%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.gross_amount}</Text></View>
                  <View style={{ width: '7%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.discount_percentage}</Text></View>
                  <View style={{ width: '9%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.discount_amount}</Text></View>
                  <View style={{ width: '11%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.net_amount}</Text></View>
                  <View style={{ width: '10%', alignItems: 'center' }}>
                    {
                      item.corporate_bill_status === null ?
                        <Text style={[styles.TableDataText, { color: color.cardOrange, marginLeft: '0.3vw', fontWeight: '500' }]}>Pending !</Text>
                        :
                        item.corporate_bill_status === "Bill_Paid" ?
                       
                        <Text style={[styles.TableDataText, { color: color.cardGreen, marginLeft: '0.3vw', fontWeight: '500' }]}>Bill Paid</Text> :

                        <Text style={[styles.TableDataText, { color: "#4de057", marginLeft: '0.3vw', fontWeight: '500' }]}>Bill Generated</Text>

                    }

                  </View>

                </View>
              )
            }) :
              <View style={styles.NoRecordsView}>
                <Text style={styles.NoRecordsText}>No Records to show...</Text>
              </View>}

          </ScrollView>
          {
            <View style={styles.PaginationView}>
              {

                this.state.BillList && this.state.BillList.length > 0 ?

                  <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.BillList} />
                  : null
              }
            </View>
          }
          {
            this.state.FilterPopUpOpen ? this.FilterPopUp() : null
          }

        </View>
        {
          this.state.isGenerateBillsClicked ? this.GenerateBillPopUp() : null
        }
        {
          this.state.isallCompletedBillsClicked ? this.CompleteBillPopUp() : null
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableContainer: {
    marginLeft: '1vw',
    marginRight: '1vw',
    marginTop: '0.3vw',
    zIndex: -1
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableDataView: {
    justifyContent: 'center',
    maxHeight: '2.9vw',
    minHeight: '2.9vw',
    width: "100%",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderRadius: '0.4vw'
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw"
  },
  MainTable: {
    height: '58vh'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw'
  },
  NoRecordsText: {
    fontSize: '1vw'
  },
  PageHeaderView: {
    height: "1.5vw",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '2vw',
    alignItems: 'center',
    marginTop: '0.2vw',
    marginBottom: "0.5vw"
  },
  SearchInput: {
    height: '2vw',
    borderWidth: '0.065vw',
    borderColor: color.themeDark,
    borderRadius: '0.26vw',
    width: "12vw",
    marginLeft: '0.65vw',
    paddingHorizontal: '0.52vw',
    color: color.black,
    fontSize: '0.91vw',
    zIndex: -2
  },
  DescriptionInput: {
    borderColor: "#CDD1D5",
    borderRadius: '0.26vw',
    borderWidth: '0.065vw',
    width: "80%",
    height: "4vw",
    fontSize: ".9vw",
    paddingLeft: '1vw',
    marginTop: '1vw'
  },
  BtnTouch: {
    paddingTop: '0.3vw',
    paddingLeft: '0.5vw',
    paddingRight: '0.5vw',
    paddingBottom: '0.3vw',
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: '5vw',
    height: '2vw',
    marginLeft: '2vw'
  },
  ButtonText: {
    color: color.white,
    textAlign: 'center',
    fontSize: ".85vw",
    fontWeight: '500'
  },
  FilterBTnTouch: {
    paddingTop: '0.3vw',
    paddingLeft: '0.5vw',
    paddingRight: '0.5vw',
    paddingBottom: '0.3vw',
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: '3vw',
    height: '2vw',
    marginLeft: '2vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  FilterBtnText: {
    color: color.white,
    textAlign: 'center',
    fontSize: "1vw",
    fontWeight: '500',
    marginLeft: '0.5vw'
  },
  FilterPopUpView: {
    borderRadius: '0.5vw',
    width: '28%',
    height: '43vh',
    // justifyContent: 'center',
    paddingHorizontal: "0.65vw",
    position: 'absolute',
    backgroundColor: "white",
    borderColor: color.themeDark,
    borderWidth: 1,
    right: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  FilterRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: '1vw',
    height: '1.5vw'
  },
  FilterHeadTxt: {
    fontSize: '1vw',
    width: '90%',
    textAlign: 'center',
    marginTop: '0.2vw',
    fontWeight: '500'
  },
  FilterBtnView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1.5vw',
    zIndex: -2
  },
  FiterTop: {
    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '2vw'
  },
  IconImageStyle: {
    height: '2vw',
    width: '1.5vw',
    color:"white"
  },
})