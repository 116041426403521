import React, { Component } from 'react';
import { View, Text, Switch, TextInput, TouchableWithoutFeedback, FlatList, Image, TouchableOpacity, Dimensions, StyleSheet, Platform, Picker, ScrollView } from 'react-native';
import BaseComponentStyle from "../../BaseComponentStyle";
import { color } from '../../../../styles/Color';
import 'antd/dist/antd.css';
import { Constants } from "../../../../utils/Constants"
import moment from "moment";
import { colors } from 'react-native-elements';
import OpthamologyService from '../../../../network/OpthamologyService';
import Icon from "react-native-vector-icons/FontAwesome";
const screenHeight = Dimensions.get("window").height;
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);


export default class ReferalNotes extends BaseComponentStyle {
  constructor(props) {
    super(props);
    this.state = {
      patientInfo: props.patientInfo,
      patientId: props.patientId,
      selectedProblem: "----",
      selectedProblemId: null,
      selectedEyeType: "----",
      selectedEyeTypeId: null,
      selectedSystemicIllness: "----",
      selectedSystemicIllnessId: null,
      selectedSurgery: "----",
      selectedSurgeryId: null,
      selectedSolution: "",
      selectedSolutionId: null,
      referredByDoctor: props.doctorDetails,
      referredToDoctor: "",
      referredToDoctorAddress: "",
      doctor_content: "",
      currentDate: moment(new Date()).format("DD-MM-YYYY"),
      doctor_list: [],
      eye_type_list: [{ eye_type: "Left", id: 1 }, { eye_type: "Right", id: 2 }, { eye_type: "Both Eyes", id: 3 }],
      eye_problem_list: [],
      systemic_illness_list: [],
      surgery_list: [],
      solution_list: [],
      isNewDoctorFormVisible: false,
      isNewEyeProblemFormVisible: false,
      isNewSystemicIllnessFormVisible: false,
      isNewSurgeryFormVisible: false,
      referralNotesId: null,
      newDoctorDetails: {
        "id": "",
        "doctor_name": "",
        "qualifications": "",
        "designation": "",
        "clinic_name": "",
        "city_name": "",
        "country": "",
        "mobile_number": "",
        "email": ""
      },
      newNotesItems: {
        "solution_name": "",
        "systemic_illness_name": "",
        "eye_problem_name": "",
        "surgery_name": ""
      },
      doctorReferralNotes: {
        "doctor_id": "",
        "eye_problem_id": "",
        "systemic_illness_id": "",
        "solution_id": "",
        "surgery_id": "",
        "eye_type": "",
        "patient_id": "",
        "referral_doctor_id": "",
        "doctor_notes": ""
      },
      userType: this.props.userType,
      docContent:'',
      selectedValue: "",
    }
  }
  componentDidMount(props) {
    this.getFormData();
    this.getDoctorDetailsList();
    this.setState({
      doctor_content: "Dear Doctor,\n\nThis is with reference To " + this.props.patientInfo.name + ", (" + this.props.patientInfo.patient_account_number + ") " + this.props.patientInfo.age + " has diagnosed with " + this.state.selectedProblem + " in " + this.state.selectedEyeType + ". He / She is a known cases of " + this.state.selectedSystemicIllness + " under your care. We noticed that He / She needs " + this.state.selectedSurgery + " surgey to be undergone. Kindly evaluate Him / Her regarding the same.\n\nRegards,\n" + this.props.doctorDetails,
      docContent: "Dear Doctor,\n\nThis is with reference To " + this.props.patientInfo.name + ", (" + this.props.patientInfo.patient_account_number + ") " + this.props.patientInfo.age + " has diagnosed with " + this.state.selectedProblem + ". We noticed that He / She needs " + this.state.selectedSurgery + " to be undergone. Kindly evaluate Him / Her regarding the same.\n\nRegards,\n" + this.props.doctorDetails,
    })
  }
  getFormData() {
    try {
      var url = Constants.OPTHALMOLOGY_REFERRAL_DETAILS;
      OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getFormDataSuccess,
        this.getFormDataFailure
      )
    } catch (error) {

    }

  }
  getFormDataSuccess = response => {
    try {
      if (response.status === "success") {
        this.setState({
          eye_problem_list: response.referral_note_items.eye_problem_data,
          systemic_illness_list: response.referral_note_items.systemic_illness_data,
          surgery_list: response.referral_note_items.surgery_data,
          solution_list: response.referral_note_items.solution_data
        })
      }
    } catch (error) {

    }
  }
  getFormDataFailure = response => {
    try {

    } catch (error) {

    }
  }
  getDoctorDetailsList() {
    try {
      var url = Constants.OPTHALMOLOGY_REFERRAL_DOCTOR_DETAILS;
      OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getDoctorDetailsListSuccess,
        this.getDoctorDetailsListFailure
      )
    } catch (error) {

    }
  }
  getDoctorDetailsListSuccess = response => {
    try {
      if (response.status == "success") {
        this.setState({
          doctor_list: response.doctor_profile
        })
      }
    } catch (error) {

    }
  }
  getDoctorDetailsListFailure = response => {
    try {

    } catch (error) {

    }
  }
  EditDoctorDetails(item) {
    try {
      let states = this.state.newDoctorDetails;
      states["id"] = item.id;
      states["doctor_name"] = item.doctor_name;
      states["qualifications"] = item.qualifications;
      states["designation"] = item.designation;
      states["clinic_name"] = item.clinic_name;
      states["city_name"] = item.city_name;
      states["country"] = item.country;
      states["mobile_number"] = item.mobile_number;
      states["email"] = item.email;
      this.setState({ states, isNewDoctorFormVisible: true })
    } catch (error) {

    }
  }
  DeleteDoctorDetails(item) {
    try {
      var url = Constants.OPTHALMOLOGY_REFERRAL_DOCTOR_DETAILS + "?doctor_id=" + `${item.id}`;
      OpthamologyService.getInstance().deleteComplaints(
        url,
        {},
        this,
        this.deleteDetailsListSuccess,
        this.deleteDetailsListFailure
      )
    } catch (error) {

    }
  }
  deleteDetailsListSuccess = response => {
    try {
      if (response.status == "success") {
        this.setState({
          doctor_list: response.doctor_profile,
          isNewDoctorFormVisible: false,
          newDoctorDetails: {
            "id": "",
            "doctor_name": "",
            "qualifications": "",
            "designation": "",
            "clinic_name": "",
            "city_name": "",
            "country": "",
            "mobile_number": "",
            "email": ""
          },
        }, this.forceUpdate())
      }
    } catch (error) {

    }
  }
  deleteDetailsListFailure = response => {

  }
  getDoctorsList(item) {
    return (
      <View style={{ flexDirection: 'row', margin: '0.5vw' }}>
        <TouchableOpacity onPress={() => { this.setSelectedDoctorDetails(item) }} key={item.id} style={[styles.emed_doctor_tiles,
        {
          backgroundColor: this.state.referredToDoctor === item.doctor_name ? color.themeDark : null,
          borderColor: this.state.referredToDoctor === item.doctor_name ? color.white : color.black
        }]}>
          <Text style={{ color: this.state.referredToDoctor === item.doctor_name ? color.white : color.black, fontSize: '1vw' }} id={item.id}>{item.doctor_name}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.emed_refnotes_tile_actionBtn]} onPress={() => { this.EditDoctorDetails(item) }}>
          <Image style={{ width: '1.2vw', height: '1.2vw' }} source={require("../../../../../assets/images/edit_color.png")} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.DeleteDoctorDetails(item) }} style={[styles.emed_refnotes_tile_actionBtn]}>
          <Image style={{ width: '1.2vw', height: '1.2vw' }} source={require("../../../../../assets/images/trash_color.png")} />
        </TouchableOpacity>
      </View>
    )
  }
  getEyeProblemList(item) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity onPress={() => { this.setSelectedEyeProblemDetails(item) }} key={item.eye_problem_id} style={[styles.emed_service_tiles,
        {
          backgroundColor: this.state.selectedProblem === item.eye_problem_name ? color.themeDark : null,
          borderColor: this.state.selectedProblem === item.eye_problem_name ? color.white : color.black
        }]}>
          <Text style={{ color: this.state.selectedProblem === item.eye_problem_name ? color.white : color.black, fontSize: '1vw' }} id={item.eye_problem_id}>{item.eye_problem_name}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  getEyeTypeList(item) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity onPress={() => { this.setSelectedEyeTypeDetails(item) }} key={item.id} style={[styles.emed_service_tiles,
        {
          backgroundColor: this.state.selectedEyeType === item.eye_type ? color.themeDark : null,
          borderColor: this.state.selectedEyeType === item.eye_type ? color.white : color.black
        }]}>
          <Text style={{ color: this.state.selectedEyeType === item.eye_type ? color.white : color.black, fontSize: '1vw' }} id={item.id}>{item.eye_type}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  getSystemicIllnessListView(item) {
    try {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => { this.setSelectedSystemicIllnessDetails(item) }} key={item.systemic_illness_id} style={[styles.emed_service_tiles,
          {
            backgroundColor: this.state.selectedSystemicIllness === item.systemic_illness_name ? color.themeDark : null,
            borderColor: this.state.selectedSystemicIllness === item.systemic_illness_name ? color.white : color.black
          }]}>
            <Text style={{ color: this.state.selectedSystemicIllness === item.systemic_illness_name ? color.white : color.black, fontSize: '1vw' }} id={item.systemic_illness_id}>{item.systemic_illness_name}</Text>
          </TouchableOpacity>
        </View>
      )
    } catch (error) {

    }
  }
  getSurgeryListView(item) {
    try {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => { this.setSelectedSurgeryDetails(item) }} key={item.surgery_id} style={[styles.emed_service_tiles,
          {
            backgroundColor: this.state.selectedSurgery === item.surgery_name ? color.themeDark : null,
            borderColor: this.state.selectedSurgery === item.surgery_name ? color.white : color.black
          }]}>
            <Text style={{ color: this.state.selectedSurgery === item.surgery_name ? color.white : color.black, fontSize: '1vw' }} id={item.surgery_id}>{item.surgery_name}</Text>
          </TouchableOpacity>
        </View>
      )
    } catch (error) {

    }
  }
  getSolutionListView(item) {
    try {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => { this.setSelectedSolutionDetails(item) }} key={item.solution_id} style={[styles.emed_service_tiles,
          {
            backgroundColor: this.state.selectedSolution === item.solution_name ? color.themeDark : null,
            borderColor: this.state.selectedSolution === item.solution_name ? color.white : color.black
          }]}>
            <Text style={{ color: this.state.selectedSolution === item.solution_name ? color.white : color.black, fontSize: '1vw' }} id={item.solution_id}>{item.solution_name}</Text>
          </TouchableOpacity>
        </View>
      )
    } catch (error) {

    }
  }
  setSelectedDoctorDetails(selectedDoctorInfo) {
    let states = this.state.newDoctorDetails;
    states["id"] = selectedDoctorInfo.id;
    states["doctor_name"] = selectedDoctorInfo.doctor_name;
    states["qualifications"] = selectedDoctorInfo.qualifications;
    states["designation"] = selectedDoctorInfo.designation;
    states["clinic_name"] = selectedDoctorInfo.clinic_name;
    states["city_name"] = selectedDoctorInfo.city_name;
    states["country"] = selectedDoctorInfo.country;
    states["mobile_number"] = selectedDoctorInfo.mobile_number;
    states["email"] = selectedDoctorInfo.email;
    this.setState({
      states,
      referredToDoctor: selectedDoctorInfo.doctor_name,
      refqualifications: selectedDoctorInfo.qualifications,
      refdesignation:selectedDoctorInfo.designation,
      refclinic_name:selectedDoctorInfo.clinic_name,
      refcity_name:selectedDoctorInfo.city_name,
      refcountry:selectedDoctorInfo.country


    })
    // this.setState({
    //   referredToDoctor: selectedDoctorInfo.doctor_name,
    // })
  }
  setSelectedEyeProblemDetails = (selectedEyeProblem) => {
    this.setState({
      selectedProblem: selectedEyeProblem.eye_problem_name,
      selectedProblemId: selectedEyeProblem.eye_problem_id
    }, () => this.updateContent())
  }
  setSelectedEyeTypeDetails(selectedEyeType) {
    this.setState({
      selectedEyeType: selectedEyeType.eye_type,
      selectedEyeTypeId: selectedEyeType.id
    }, () => this.updateContent())
  }
  setSelectedSystemicIllnessDetails(slectedSystemicIllnessType) {
    this.setState({
      selectedSystemicIllness: slectedSystemicIllnessType.systemic_illness_name,
      selectedSystemicIllnessId: slectedSystemicIllnessType.systemic_illness_id
    }, () => this.updateContent())
  }
  setSelectedSurgeryDetails(selectedSurgeryDetails) {
    this.setState({
      selectedSurgery: selectedSurgeryDetails.surgery_name,
      selectedSurgeryId: selectedSurgeryDetails.surgery_id
    }, () => this.updateContent())
  }
  setSelectedSolutionDetails(selectedSolutionDetails) {
    this.setState({
      selectedSolution: selectedSolutionDetails.solution_name,
      selectedSolutionId: selectedSolutionDetails.solution_id
    }, () => this.updateContent())
  }
  updateContent() {
    this.setState({
      doctor_content: "Dear Doctor,\n\nThis is with reference To " + this.props.patientInfo.name + ", (" + this.props.patientInfo.patient_account_number + ") " + this.props.patientInfo.age + " who presented with " + this.state.selectedProblem + " in " + this.state.selectedEyeType + ". He / She is a known cases of " + this.state.selectedSystemicIllness + " under your care. He / She needs " + this.state.selectedSurgery + " for the " + this.state.selectedEyeType + ". Kindly evaluate her regarding fitness for the same.\n\nRegards,\n" + this.props.doctorDetails,
      docContent: "Dear Doctor,\n\nThis is with reference To " + this.props.patientInfo.name + ", (" + this.props.patientInfo.patient_account_number + ") " + this.props.patientInfo.age + " has diagnosed with " + this.state.selectedProblem + ". We noticed that He / She needs " + this.state.selectedSurgery + " to be undergone .Kindly Him / Her  evaluate regarding the same.\n\nRegards,\n" + this.props.doctorDetails,
    })
  }
  AddNew(value) {
    
    try {
      if (value === "NewDoctor") {
        let states = this.state.newDoctorDetails;
        states["id"] = "";
        states["doctor_name"] = "";
        states["qualifications"] = "";
        states["designation"] = "";
        states["clinic_name"] = "";
        states["city_name"] = "";
        states["country"] = "";
        states["mobile_number"] = "";
        states["email"] = "";
        this.setState({
          isNewDoctorFormVisible: true,
          states,
          referredToDoctor: '',
          refdesignation: '',
          refqualifications: '',
          refcity_name: '',
          refclinic_name: ''

        })
      } else if (value === "NewEyeProblem") {
        let states = this.state.newNotesItems
        states["eye_problem_name"] ="",
        this.setState({
          isNewEyeProblemFormVisible: true,
          states,
          selectedProblem: "----",
          selectedProblemId: null,
        }, () => {this.updateContent()})
      } else if (value === "NewSystemicIllness") {
        let states = this.state.newNotesItems
        states["systemic_illness_name"] = ""
        this.setState({
          isNewSystemicIllnessFormVisible: true,
          states,
          selectedSystemicIllness: "----",
          selectedSystemicIllnessId: null,
        }, ()=> this.updateContent())
      } else if (value === "NewSurgery") {
        let states = this.state.newNotesItems
        states["surgery_name"] = "",
        this.setState({
          isNewSurgeryFormVisible: true,
          states,
          selectedSurgery: "----",
          selectedSurgeryId: null,
        }, ()=> {this.updateContent()})
      }
      else if (value === "NewSolution") {
        let states = this.state.newNotesItems
        states["solution_name"] = "",
        this.setState({
          isNewSolutionFormVisible: true,
          states,
          selectedSolution: "",
          selectedSolutionId: null,
        }, ()=> {this.updateContent()})
      } else {

      }
    } catch (error) {

    }
  }
  SaveDetails(value) {
    try {
      this.setState({
        selectedValue: value

      })
      if (value === "NewDoctor") {
        var states = this.state
        if(states.newDoctorDetails.doctor_name.length > 0 && states.newDoctorDetails.qualifications.length > 0 &&
          states.newDoctorDetails.designation.length > 0 && states.newDoctorDetails.clinic_name.length > 0){
            
        var url = Constants.OPTHALMOLOGY_REFERRAL_DOCTOR_DETAILS;
        let isValid = this.validateData(this.state.newDoctorDetails);
        console.log("Return Valid:", isValid);
        if(states.newDoctorDetails.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(states.newDoctorDetails.email))){
          this.props.showResposeValue('error', "Invalid Email ID") 
        }else if(states.newDoctorDetails.mobile_number && states.newDoctorDetails.mobile_number.length < 10){
          this.props.showResposeValue('error', "Please Enter Valid Monbile No")
        }else{
        if (isValid) {
          OpthamologyService.getInstance().postComplaints(
            url,
            this.state.newDoctorDetails,
            this,
            this.saveDetailsListSuccess,
            this.saveDetailsListFailure
          )
        }
      }
      }else{
        this.props.showResposeValue('error', "Please Enter All Mandatory Fields")
      }
      } else if (value === "NewEyeProblem") {
        if (this.state.newNotesItems.eye_problem_name) {
          var url = Constants.OPTHALMOLOGY_REFERRAL_DETAILS;
          OpthamologyService.getInstance().postComplaints(
            url,
            this.state.newNotesItems,
            this,
            this.saveDetailsListSuccess,
            this.saveDetailsListFailure
          )
          this.setState({
            isNewEyeProblemFormVisible: false,
            newNotesItems: {
              "solution_name": "",
              "systemic_illness_name": "",
              "eye_problem_name": "",
              "surgery_name": ""
            }
          })
        } else {

        }

      } else if (value === "NewSystemicIllness") {
        if (this.state.newNotesItems.systemic_illness_name) {
          var url = Constants.OPTHALMOLOGY_REFERRAL_DETAILS;
          OpthamologyService.getInstance().postComplaints(
            url,
            this.state.newNotesItems,
            this,
            this.saveDetailsListSuccess,
            this.saveDetailsListFailure
          )
          this.setState({
            isNewSystemicIllnessFormVisible: false,
            newNotesItems: {
              "solution_name": "",
              "systemic_illness_name": "",
              "eye_problem_name": "",
              "surgery_name": ""
            }
          })
        } else {

        }
      } else if (value === "NewSurgery") {
        if (this.state.newNotesItems.surgery_name) {
          var url = Constants.OPTHALMOLOGY_REFERRAL_DETAILS;
          OpthamologyService.getInstance().postComplaints(
            url,
            this.state.newNotesItems,
            this,
            this.saveDetailsListSuccess,
            this.saveDetailsListFailure
          )
          this.setState({
            isNewSurgeryFormVisible: false,
            newNotesItems: {
              "solution_name": "",
              "systemic_illness_name": "",
              "eye_problem_name": "",
              "surgery_name": ""
            }
          })
        } else {

        }
      } else if (value === "NewSolution") {
        if (this.state.newNotesItems.solution_name) {
          var url = Constants.OPTHALMOLOGY_REFERRAL_DETAILS;
          OpthamologyService.getInstance().postComplaints(
            url,
            this.state.newNotesItems,
            this,
            this.saveDetailsListSuccess,
            this.saveDetailsListFailure
          )
          this.setState({
            isNewSolutionFormVisible: false,
            newNotesItems: {
              "solution_name": "",
              "systemic_illness_name": "",
              "eye_problem_name": "",
              "surgery_name": ""
            }
          })
        } else {

        }
      } else {

      }
    } catch (error) {

    }
  }
  saveDetailsListSuccess = response => {
      if (response.status == "success") {
        if (response.message == "Referral Data added successfully") {
          var eyeLen = (response.referral_note_items.eye_problem_data).length
          var systemicLen = (response.referral_note_items.systemic_illness_data).length
          var surgeryLen = (response.referral_note_items.surgery_data).length

          if(this.state.selectedValue === "NewEyeProblem"){
            this.setSelectedEyeProblemDetails((response.referral_note_items.eye_problem_data)[eyeLen - 1])
          }else if(this.state.selectedValue === "NewSurgery"){
            this.setSelectedSurgeryDetails((response.referral_note_items.surgery_data)[surgeryLen - 1])
          }else if(this.state.selectedValue === "NewSystemicIllness"){
            this.setSelectedSystemicIllnessDetails((response.referral_note_items.systemic_illness_data)[systemicLen - 1])
          }


          this.setState({
            eye_problem_list: response.referral_note_items.eye_problem_data,
            systemic_illness_list: response.referral_note_items.systemic_illness_data,
            surgery_list: response.referral_note_items.surgery_data,
            solution_list: response.referral_note_items.solution_data,
            selectedValue: "",
          }, this.forceUpdate())
        } else {
          var data = response.doctor_profile
          var len = data.length
          let states = this.state.newDoctorDetails;
          if(len > 0){
          states["id"] = data[len -1].id;
          states["doctor_name"] = data[len -1].doctor_name;
          states["qualifications"] = data[len -1].qualifications;
          states["designation"] = data[len -1].designation;
          states["clinic_name"] = data[len -1].clinic_name;
          states["city_name"] = data[len -1].city_name;
          states["country"] = data[len -1].country;
          states["mobile_number"] = data[len -1].mobile_number;
          states["email"] = data[len -1].email;
          }
          
          this.setState({
            states,
            doctor_list: data,
            referredToDoctor: (data).length > 0  ? data[len -1].doctor_name : "",
            refqualifications: (data).length > 0  ? data[len -1].qualifications : "",
            refdesignation: (data).length > 0  ? data[len -1].designation : "",
            refclinic_name: (data).length > 0  ? data[len -1].clinic_name : "",
            refcity_name: (data).length > 0  ? data[len -1].city_name : "",
            refcountry: (data).length > 0  ? data[len -1].country : "",
            isNewDoctorFormVisible: false,
          }, this.forceUpdate())
        }
      }

  }
  saveDetailsListFailure = response => {

  }
  CancelDetails(value) {
    try {
      if (value === "NewDoctor") {
        this.setState({
          isNewDoctorFormVisible: false
        })
      } else if (value === "NewEyeProblem") {
        this.setState({
          isNewEyeProblemFormVisible: false
        })
      } else if (value === "NewSystemicIllness") {
        this.setState({
          isNewSystemicIllnessFormVisible: false
        })
      } else if (value === "NewSurgery") {
        this.setState({
          isNewSurgeryFormVisible: false
        })
      } else {

      }
    } catch (error) {

    }
  }
  validateData(data) {
    try {
      let isValid = false;
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          if(key === ("doctor_name") ||( key === "qualifications") || (key === "designation") || (key === "clinic_name")){
          if (!data[key]) {
            isValid = false;
          } else {
            isValid = true;
          }
        }
        }
      }
      return isValid;
    } catch (error) {

    }
  }
  SaveDoctorContent() {
    try {
      
      let eye_problem_id = this.state.selectedProblemId;
      let systemic_illness_id = this.state.selectedSystemicIllnessId;
      let solution_id = this.state.selectedSolutionId;
      let surgery_id = this.state.selectedSurgeryId;
      let eye_type = this.state.selectedEyeType;
      let patient_id = this.state.patientId;
      let referral_doctor_id = this.state.newDoctorDetails.id;
      let doctor_notes = this.state.userType === "OPT" ? this.state.doctor_content : this.state.docContent;
      let states = this.state.doctorReferralNotes;
      states["doctor_id"] = "";
      states["eye_problem_id"] = eye_problem_id;
      states["systemic_illness_id"] = systemic_illness_id;
      states["solution_id"] = solution_id;
      states["surgery_id"] = surgery_id;
      states["eye_type"] = eye_type;
      states["patient_id"] = patient_id;
      states["referral_doctor_id"] = referral_doctor_id;
      states["doctor_notes"] = doctor_notes;
      
      this.setState({states});
      this.referralNotesPost()
    } catch (error) {

    }
  }
  PrintReferralNotes(){
    var url = Constants.OPTHALMOLOGY_REFERRAL_NOTES_POST + "?referral_id="+ this.state.referralNotesId + "&export_type=pdf";
    OpthamologyService.getInstance().documentUploadGet(
      url,
      this.referralNotesPrintSuccess,
      this.referralNotesPrintFailure,
      "pdf"
    )
  }
  referralNotesPrintSuccess = response => {
    if (response) {
        const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }
  };
  referralNotesPrintFailure(){

  }
  referralNotesPost = () => {
  
    
    
    var url = Constants.OPTHALMOLOGY_REFERRAL_NOTES_POST

    var states = this.state.doctorReferralNotes;
    var data = {
      "doctor_id":states.doctor_id,
     "eye_problem_id":states.eye_problem_id,
     "solution_id":states.solution_id,
     "surgery_id": states.surgery_id,
     "patient_id":states.patient_id,
     "referral_doctor_id":states.referral_doctor_id,
     "doctor_notes":states.doctor_notes
    }
    if( this.state.userType === "OPT") {
      data["systemic_illness_id"] = states.systemic_illness_id,
      data["eye_type"] = states.eye_type
    }
    if((!this.state.doctorReferralNotes.eye_problem_id || !this.state.doctorReferralNotes.eye_type || this.state.doctorReferralNotes.eye_type == "----" ||  !this.state.doctorReferralNotes.surgery_id || !this.state.doctorReferralNotes.systemic_illness_id) && this.state.userType === "OPT" ){
      this.props.showResposeValue("error", "Please select all fields!");
      return;
    } else if((!this.state.doctorReferralNotes.eye_problem_id || !this.state.doctorReferralNotes.surgery_id) && this.state.userType != "OPT"){
      this.props.showResposeValue("error", "Please select all fields!");
      return;
    } else { 
    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.referralNotesPostSuccess,
      this.referralNotesPostFailure
    )
   }
    
  }

  referralNotesPostSuccess = (response) => {
    if(response.status == "success"){
      this.props.showResposeValue("success", response.message)
      this.setState({
        referralNotesId: response.referral_data[response.referral_data.length-1].referral_id
      })
    }else{
      this.props.showResposeValue("error", response.message)
    }
  }
  referralNotesPostFailure (){
    
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
}
  render() {
      console.log(this.state.userType, "this is usertype for you")
    return (
      <View style={[styles.container]}>
        <View style={[styles.emed_refnotes_leftPane]}>
          <View style={[styles.emed_refnotes_leftPane_container]}>
            <View style={[styles.emed_refnotes_leftPane_container1]}>
              <View style={[styles.emed_refnotes_leftPane_doctorDetails]}>
                <Text style={styles.emed_refnotes_To_address}>To</Text>
                <Text style={styles.emed_refnotes_To_DocName}>{this.state.referredToDoctor}&nbsp;{this.state.refqualifications}</Text>
                <Text style={styles.emed_refnotes_To_address}>{this.state.refdesignation}</Text>
                <Text style={styles.emed_refnotes_To_address}>{this.state.refclinic_name}</Text>
                <Text style={styles.emed_refnotes_To_address}>{this.state.refcity_name}</Text>
              </View>
              <View style={[styles.emed_refnotes_leftPane_place]}>
                <Text style={styles.emed_refnotes_To_address}>{this.state.refcity_name}{"\n"}{this.state.currentDate}</Text>
              </View>
            </View>
            <View style={[styles.emed_refnotes_leftPane_container1]}>
              <Text style={[styles.emed_refnotes_leftPane_header]}>Greetings from {this.props.selectedClinicOrDoctor.value}</Text>
            </View>
            <View style={[styles.emed_refnotes_leftPane_container1]}>
              <TextInput onChangeText={content => { this.setState({ 
                doctor_content: content,
                docContent: content }) }} numberOfLines={10} style={[styles.emed_refnotes_leftPane_content]} multiline={true} value={this.state.userType === "OPT" ? this.state.doctor_content: this.state.docContent}></TextInput>
            </View>
            <View style={[styles.emed_refnotes_newbtn_container, {flexDirection: 'row', justifyContent: 'center'}]}>
              <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.SaveDoctorContent() }}>
                <Text style={[styles.emed_refnotes_newbtn_text]}>Save</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.emed_refnotes_newbtn,{marginLeft: 10}]} onPress={() => { this.PrintReferralNotes() }}>
                <Text style={[styles.emed_refnotes_newbtn_text]}>Print</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <ScrollView style={{backgroundColor: color.themeShade}}>
          <View style={[styles.emed_refnotes_rightPane]}>
            <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text style={styles.emed_refnotes_To_address}>Doctor</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.doctor_list.length > 0 ?
                  this.state.doctor_list.map((items, index) => {
                    return this.getDoctorsList(items)
                  }) : <View><Text style={styles.emed_refnotes_To_address}>No Records Found</Text></View>}
              </View>
              <View style={[styles.emed_refnotes_newbtn_container]}>
                <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.AddNew("NewDoctor") }}>
                  <Text style={[styles.emed_refnotes_newbtn_text]}>New</Text>
                  <Icon style={{marginTop: '0.5vw'}} name="plus" size={'1vw'} color={color.white} />
                </TouchableOpacity>
              </View>
              {this.state.isNewDoctorFormVisible ?
                <View style={[styles.emed_refnotes_newDoctor_contaier]}>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Doctor Name <span style={{}}>*</span> </Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.doctor_name}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["doctor_name"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Name"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Qualification <span style={{}}>*</span> </Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.qualifications}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["qualifications"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Qualification"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Designation <span style={{}}>*</span> </Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.designation}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["designation"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Designation"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Hospital / Clinic <span style={{}}>*</span> </Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.clinic_name}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["clinic_name"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Hospital/Clinic Name"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>City</Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.city_name}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["city_name"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's City"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Country</Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.country}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["country"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Country"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Mobile Number</Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.mobile_number}
                    maxLength={13}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        var isValid = this.numberValidation(content)
                            if (isValid || content === "") {
                                states["mobile_number"] = content
                                this.setState({ states })
                            }
                      }} placeholder="Enter Doctor's Mobile Number"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Email</Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]} value={this.state.newDoctorDetails.email}
                      onChangeText={content => {
                        let states = this.state.newDoctorDetails;
                        states["email"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Doctor's Email"></TextInput>
                  </View>
                  <View style={{ flexDirection: 'row'}}>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.SaveDetails("NewDoctor") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Save</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.CancelDetails("NewDoctor") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View> : null}
            </View>
            <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text style={styles.emed_refnotes_To_address}>{this.state.userType === "OPT" ? "Eye Problems" : "Problems"}</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.eye_problem_list.length > 0 ?
                  this.state.eye_problem_list.map((items, index) => {
                    return this.getEyeProblemList(items)
                  }) : <View><Text>No Records Found</Text></View>}
              </View>
              <View style={[styles.emed_refnotes_newbtn_container]}>
                <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.AddNew("NewEyeProblem") }}>
                  <Text style={[styles.emed_refnotes_newbtn_text]}>New</Text>
                  <Icon style={{marginTop: '0.5vw'}} name="plus" size={'1vw'} color={color.white} />
                </TouchableOpacity>
              </View>
              {this.state.isNewEyeProblemFormVisible ?
                <View style={[styles.emed_refnotes_Details_contaier]}>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>{this.state.userType === "OPT" ? "Eye Problem Name" : "Problem Name"}</Text>
                    <TextInput onChangeText={content => {
                      let states = this.state.newNotesItems;
                      states["eye_problem_name"] = content;
                      this.setState({ states })
                    }} style={[styles.emed_Details_txtinputbox]} placeholder="Enter Your Problem"></TextInput>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.SaveDetails("NewEyeProblem") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Save</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.CancelDetails("NewEyeProblem") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View> : null}
            </View>
            {this.state.userType === "OPT" ? 
            <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text style={styles.emed_refnotes_To_address}>Eye Type</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.eye_type_list.length > 0 ?
                  this.state.eye_type_list.map((items, index) => {
                    return this.getEyeTypeList(items)
                  }) : <View><Text style={styles.emed_refnotes_To_address}>No Records Found</Text></View>}
              </View>
            </View> : null}
            {this.state.userType === "OPT" ?
            <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text style={styles.emed_refnotes_To_address}>Systemic Illness</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.systemic_illness_list.length > 0 ?
                  this.state.systemic_illness_list.map((items, index) => {
                    return this.getSystemicIllnessListView(items)
                  }) : <View><Text style={styles.emed_refnotes_To_address}>No Records Found</Text></View>}
              </View>
              <View style={[styles.emed_refnotes_newbtn_container]}>
                <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.AddNew("NewSystemicIllness") }}>
                  <Text style={[styles.emed_refnotes_newbtn_text]}>New</Text>
                  <Icon style={{marginTop: '0.5vw'}} name="plus" size={'1vw'} color={color.white} />
                </TouchableOpacity>
                {this.state.isNewSystemicIllnessFormVisible ?
                  <View style={[styles.emed_refnotes_Details_contaier]}>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <Text style={[styles.emed_refnotes_lbl_text]}>Systemic Illness Name</Text>
                      <TextInput style={[styles.emed_Details_txtinputbox]}
                        onChangeText={content => {
                          let states = this.state.newNotesItems;
                          states["systemic_illness_name"] = content;
                          this.setState({ states })
                        }} placeholder="Enter Systemic Illness"></TextInput>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <View style={[styles.emed_refnotes_newDoctor_fields]}>
                        <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.SaveDetails("NewSystemicIllness") }}>
                          <Text style={[styles.emed_refnotes_savebtn_text]}>Save</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={[styles.emed_refnotes_newDoctor_fields]}>
                        <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.CancelDetails("NewSystemicIllness") }}>
                          <Text style={[styles.emed_refnotes_savebtn_text]}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View> : null}
              </View>
            </View> : null}
            <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text style={styles.emed_refnotes_To_address}>Surgery</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.surgery_list.length > 0 ?
                  this.state.surgery_list.map((items, index) => {
                    return this.getSurgeryListView(items)
                  }) : <View><Text style={styles.emed_refnotes_To_address}>No Records Found</Text></View>}
              </View>
              <View style={[styles.emed_refnotes_newbtn_container]}>
                <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.AddNew("NewSurgery") }}>
                  <Text style={[styles.emed_refnotes_newbtn_text]}>New</Text>
                  <Icon style={{marginTop: '0.5vw'}} name="plus" size={'1vw'} color={color.white} />
                </TouchableOpacity>
              </View>
              {this.state.isNewSurgeryFormVisible ?
                <View style={[styles.emed_refnotes_Details_contaier]}>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Surgery Name</Text>
                    <TextInput style={[styles.emed_Details_txtinputbox]}
                      onChangeText={content => {
                        let states = this.state.newNotesItems;
                        states["surgery_name"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Surgery"></TextInput>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.SaveDetails("NewSurgery") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Save</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.emed_refnotes_newDoctor_fields]}>
                      <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.CancelDetails("NewSurgery") }}>
                        <Text style={[styles.emed_refnotes_savebtn_text]}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View> : null}
            </View>
            {/* <View style={[styles.emed_refnotes_rightPane_container]}>
              <View style={[styles.emed_rightPane_headingBackGround]}>
                <Text>Solution</Text>
              </View>
              <View style={[styles.emed_refnotes_tiles_container]}>
                {this.state.solution_list.length > 0 ?
                  this.state.solution_list.map((items, index) => {
                    return this.getSolutionListView(items)
                  }) : <View><Text>No Records Found</Text></View>}
              </View>
              <View style={[styles.emed_refnotes_newbtn_container]}>
                <TouchableOpacity style={[styles.emed_refnotes_newbtn]} onPress={() => { this.AddNew("NewSolution") }}>
                  <Text style={[styles.emed_refnotes_newbtn_text]}>New</Text>
                  <Icon style={{marginTop: 10}} name="plus" size={14} color={color.white} />
                </TouchableOpacity>
              </View>
              {this.state.isNewSolutionFormVisible ?
                <View style={[styles.emed_refnotes_newDoctor_contaier]}>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <Text style={[styles.emed_refnotes_lbl_text]}>Solution Name</Text>
                    <TextInput style={[styles.emed_refnotes_txtinputbox]}
                      onChangeText={content => {
                        let states = this.state.newNotesItems;
                        states["solution_name"] = content;
                        this.setState({ states })
                      }} placeholder="Enter Solution"></TextInput>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.SaveDetails("NewSolution") }}>
                      <Text style={[styles.emed_refnotes_savebtn_text]}>Save</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.emed_refnotes_newDoctor_fields]}>
                    <TouchableOpacity style={[styles.emed_refnotes_savebtn]} onPress={() => { this.CancelDetails("NewSolution") }}>
                      <Text style={[styles.emed_refnotes_savebtn_text]}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View> : null}
            </View> */}
            {/* <View style={[styles.emed_refnotes_rightPane_container]}>
              <TouchableOpacity style={[styles.emed_rightPane_headingBackGround]}>
                <Text>Add</Text>
              </TouchableOpacity>
            </View> */}
          </View>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  emed_refnotes_leftPane: {
    width: "65%",
    height: screenHeight - 65,
    padding: '0.5vw'
  },
  emed_refnotes_leftPane_container: {
    width: "100%",
    flexDirection: 'column'
  },
  emed_refnotes_leftPane_container1: {
    width: "100%",
    flexDirection: 'row'
  },
  emed_refnotes_leftPane_doctorDetails: {
    flex: 1,
    alignItems: 'flex-start'
  },
  emed_refnotes_leftPane_place: {
    flex: 1,
    alignItems: 'flex-end'
  },
  emed_refnotes_leftPane_header: {
    flex: 1,
    textAlign: 'center',
    fontSize: '1.3vw',
    fontWeight: 'bold'
  },
  emed_refnotes_leftPane_content: {
    width: "100%",
    fontSize: '1vw'
  },
  emed_refnotes_rightPane: {
    width: "100%",
    height: '90vh',
    backgroundColor: color.themeShade
  },
  emed_refnotes_rightPane_container: {
    width: "100%",
    flexDirection: "column"
  },
  emed_rightPane_headingBackGround: {
    backgroundColor: color.appointmentTableHead,
    paddingHorizontal: '1.5vw',
    paddingVertical: '0.5vw'
  },
  emed_doctor_tiles: {
    //marginBottom: 15,
    borderWidth: 1,
    paddingLeft: '1.2vw',
    paddingRight: '1.2vw',
    paddingTop: '0.4vw',
    paddingBottom: '0.4vw',
    //marginLeft: 10,
    borderRadius: 4
  },
  emed_service_tiles: {
    //marginBottom: 15,
    borderWidth: 1,
    paddingLeft: '1.2vw',
    paddingRight: '1.2vw',
    paddingTop: '0.4vw',
    paddingBottom: '0.4vw',
    //marginLeft: 10,
    borderRadius: 4,
    margin: '0.5vw'
  },
  emed_refnotes_tile_actionBtn: {
    //marginBottom: 15,
    borderWidth: 1,
    paddingLeft: '0.2vw',
    paddingRight: '0.2vw',
    paddingTop: '0.4vw',
    paddingBottom: '0.4vw',
    borderRadius: 4
  },
  emed_refnotes_tiles_container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0.5vw'
  },
  emed_refnotes_newbtn_container: {
    margin: '0.5vw'
  },
  emed_refnotes_newbtn: {
    maxWidth: '6.3vw',
    backgroundColor: color.themeDark,
    borderRadius: 4,
    paddingHorizontal: '0.5vw',
    paddingVertical: '0.2vw',
    flexDirection: 'row'
  },
  emed_refnotes_newbtn_text: {
    fontSize: '1vw',
    color: color.white,
    padding: '0.2vw'
  },
  emed_refnotes_savebtn: {
    maxWidth: '6.3vw',
    backgroundColor: color.themeDark,
    borderRadius: 4,
    paddingHorizontal: '0.5vw',
    paddingVertical: '0.4vw'
  },
  emed_refnotes_savebtn_text: {
    fontSize: '1vw',
    color: color.white
  },
  emed_refnotes_newDoctor_contaier: {
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'stretch',
    flexDirection: 'row',
    margin: '0.5vw',
    backgroundColor: color.themeShade
  },
  emed_refnotes_Details_contaier: {
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'stretch',
    margin: '0.5vw',

  },
  emed_refnotes_newDoctor_fields: {
    margin: '0.5vw',
    //borderWidth: 1,
    //borderColor: colors.white
  },
  emed_refnotes_lbl_text: {
    fontWeight: "bold",
    fontSize: '1vw'
  },
  emed_refnotes_txtinputbox: {
    borderColor: "#CDD1D5",
    borderRadius: 4,
    borderWidth: 1,
    width: "100%",
    height: '2.3vw',
    padding: '0.4vw',
    marginTop: '0.4vw',
    fontSize: '1vw'
  },
  emed_Details_txtinputbox: {
    borderColor: "#CDD1D5",
    borderRadius: 4,
    borderWidth: 1,
    width: "50%",
    height: '2.3vw',
    padding: '0.4vw',
    marginTop: '0.4vw',
    fontSize: '1vw'
  },
  emed_refnotes_To_address: {
    fontSize: '1vw'
  },
  emed_refnotes_To_DocName: {
    fontSize: '1vw',
    fontWeight: '600'
  }
})