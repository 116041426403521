import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import Icon from "react-native-vector-icons/FontAwesome";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import moment from "moment";
// import GmailInput from "react-native-gmailtype-textinput";
// import SideMenu from "../../SideMenu";

const platform = Platform.OS;
const IS_OCULAR = "isOcular";
const IS_OCULAR_SURGERY = "isOcularSurgery";
const pageName ="ocular";

export default class OscularHistory extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      oscularHistorySelectedView:"",
      patientAppointment: this.props.patientAppointment,
      medicationData: [],
      isComplaint: (IS_OCULAR===this.props.selectedView || IS_OCULAR_SURGERY===this.props.selectedView)?true:false,
      selectedClinicOrDoctor:this.props.selectedClinicOrDoctor,
      userType:this.props.userType
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getOcularHistory();
      this.getSurgeryHistory();
    }, 1000);
  }

  componentWillReceiveProps(props) {
    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
    if (props.reloadData && props.reloadData === "ocular") {
      // call once
      this.getOcularHistory();
      // refresh false
      this.props.refreshData("");
    }

    if (props.reloadData && props.reloadData === "ocularsurgery") {
      // call once
      this.getSurgeryHistory();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = (IS_OCULAR===props.selectedView || IS_OCULAR_SURGERY===props.selectedView)?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getOcularHistory = () => {

    var service_url = Constants.OPTHALMOLOGY_OCULAR_MEDICATION_GET + "?patient_id=" + this.state.patientAppointment.patient_id + '&appointment_id=' + this.state.patientAppointment?.appointment_id;


    if(this.state.userType){
      service_url=service_url+"&doctor_id="+this.state.selectedClinicOrDoctor.id
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getOcularHistorySuccess,
        this.getOcularHistoryFailure
      );
    }, 1000);
  };

  getOcularHistorySuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["medicationData"] = response.data;
      this.setState({ field });
    }
  };

  
  getSurgeryHistory = () => {
    
    var service_url = Constants.OPTHALMOLOGY_SURGERY + "?patient_id=" + this.state.patientAppointment.patient_id + '&appointment_id=' + this.state.patientAppointment?.appointment_id;

    if(this.state.userType){
      service_url=service_url+"&doctor_id="+this.state.selectedClinicOrDoctor.id
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getOcularSurgerySuccess,
        this.getOcularHistoryFailure
      );
    }, 1000);
    
  };

  getOcularSurgerySuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["surgeryData"] = response.data.length>0 || (response.data).count>0?response.data:[];
      this.setState({ field });
    }
  };
  
  
  getOcularHistoryFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editItem = (item, editPage, rightView, title,key,event) => {
    this.setState({
      oscularHistorySelectedView:title,
    },()=>{
      this.props.triggerNewItem(key)
      setTimeout(()=>{
      this.props.editItem(editPage, item, rightView);
      },1500)
    })
    

  }

  deleteMedicationItem = (item, event) => {
    
    //this.props.editItem(pageName, item);
    var service_url = Constants.OPTHALMOLOGY_OCULAR_MEDICATION_DELETE + `${item.id}/`;


    var data ={};
    if(this.state.userType == Constants.ROLE_OPTOMETRY){
      data["doctor_id"]=this.state.selectedClinicOrDoctor.id
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteMedicatioSuccess,
      this.getOcularHistoryFailure
    );
  };

  getDeleteMedicatioSuccess = response => {
    if (response.status === "success") {
      
      this.getOcularHistory();
    }
  };

  deleteSurgeryItem = (item, event) => {
    
    //this.props.editItem(pageName, item);
    var service_url = Constants.OPTHAMOLOGY_DELETE_SURGERY + `${item.id}/`;

    var data ={};
    if(this.state.userType == Constants.ROLE_OPTOMETRY){
      data["doctor_id"]=this.state.selectedClinicOrDoctor.id
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getOcularHistoryFailure
    );
  };

  getDeleteSuccess = response => {
    if (response.status === "success") {
      
      this.getSurgeryHistory();
    }
  };

  renderItem = (item, index,title,key) => {
    return (
      <View>
        <View style={{ paddingTop: '0.6vw' }}>
          <View
            style={styles.ItemView}
          >
            <View style={{ flex: 0.25, paddingRight: '0.6vw' }}>
              <Text style={ Style.contentTableDataLead }>
                {item.drug_name}
              </Text>
              <Text style={ Style.contentTableData }>
                {item.years || 0}Y {item.months || 0}M 
                {item.days || 0}D 
              </Text>
            </View>

            <View style={{ flex: 0.5, paddingRight: '0.6vw' }}>
              <Text style={[ Style.contentTableData, {  paddingTop: '0.3vw' }]}>{item.comments}</Text>
            </View>

            <View
              style={styles.ItemActionContainer}
            >
              <View
                style={styles.ItemActionView}
              >
                <TouchableOpacity  disabled={this.state.isLocked}  onPress={
                    this.editItem.bind(this, item, 'ocular', IS_OCULAR,title,key)

                  } >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity  disabled={this.state.isLocked}  onPress={this.deleteMedicationItem.bind(this, item)} >
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{ height: '0.03vw', backgroundColor: "#E0E0E0", width: "90%" }}
        />
      </View>
    );
  };

  renderOcular = (title, type, data, key, triggerNewItem) => {
    return (
      <View style={{ width: "50%" }}>
        <View style={{ marginTop: 0 }}>
          <View
            style={styles.OcularView}
          >
            <View
              style={{
                // width: "30%"
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <TouchableOpacity onPress={() => {
                
                this.setState({oscularHistorySelectedView:title},()=>{triggerNewItem(key)})
                }}>
                <Text style={ [{fontSize:'1vw',color:color.drNotesUnSelectedTitle,fontWeight:"bold"},((IS_OCULAR === this.props.selectedView && (this.state.oscularHistorySelectedView == title || this.state.oscularHistorySelectedView == "")) ) ?{color: color.themeDark,fontWeight:"bold"}: null] }>{title}</Text>
              </TouchableOpacity>
              {/* <Text style={ Style.contentTableTitle }>{title}</Text> */}
              {/* <TouchableOpacity onPress={this.editItem.bind(this, "item", 'ocular', IS_OCULAR)} >
                  {this.renderIdButton("plus")}
                </TouchableOpacity> */}
            </View>
          </View>
        </View>

        <FlatList
          data={data}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) => this.renderItem(item, index,title,key)}
        />

        {/* <View>
          <TouchableOpacity onPress={() => triggerNewItem(key)}>
            {this.renderIconBtn("plus", "New", false, key)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  renderSurgeryItem = (item, index,title,key) => {

    return (
      <View>
        <View style={{ paddingTop: '0.6vw' }}>
          <View
            style={styles.SurgeryItemView}
          >
            <View style={{ flex: 0.25, paddingRight: '0.6vw' }}>
              <Text style={ Style.contentTableDataLead }>
                {item.surgery_name}
              </Text>
              <Text style={[ Style.contentTableData, {  paddingTop: '0.3vw' }]}>
                {item.no_of_years || 0}Y 
                {/* {item.no_of_months || 0}M {item.no_of_days || 0}D {item.reason?`(${item.reason})`:null} */}
              </Text>
            </View>
            <View style={{ flex: 0.5, paddingRight: '0.6vw' }}>
              <Text style={[ Style.contentTableData, {  paddingTop: '0.3vw' }]}>{item.reason}</Text>
            </View>
            <View
              style={styles.SurgeryItemActionContainer}
            >
              <View
                style={styles.SurgeryItemActionView}
              >
                <TouchableOpacity disabled={this.state.isLocked} onPress={this.editItem.bind(this, item, 'ocularsurgery', IS_OCULAR_SURGERY,title,key)} >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteSurgeryItem.bind(this, item)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View
          style={ Style.sectionItemLine }
        />
      </View>
    );
  };

  renderOcularSurgery = (title, type, data, key, triggerNewItem) => {
    return (
      <View style={{ width: "50%" }}>
        <View style={{ marginTop: 0 }}>
          <View
            style={styles.OcularView}
          >
            <View
              style={{
                width: "30%",
                flexDirection:'row',
                alignItems:'center'
              }}
            >
            <TouchableOpacity onPress={() =>{ 
              this.setState({oscularHistorySelectedView:title},()=>{triggerNewItem(key)})}}>
              <Text style={ [{fontSize:'1vw',color:color.drNotesUnSelectedTitle,fontWeight:"bold"},(this.state.oscularHistorySelectedView == title && "isOcularSurgery" == this.props.selectedView) ?{color: color.themeDark,fontWeight:"bold"}: null] }>{title}</Text>
          </TouchableOpacity>
             
              {/* <TouchableOpacity onPress={this.editItem.bind(this, "item", 'ocular', IS_OCULAR)} >
                  {this.renderIdButton("plus")}
                </TouchableOpacity> */}
            </View>
            <View
              style={[styles.OcularSurgeryView, {backgroundColor: color.white}]}
            />
          </View>
        </View>

        <FlatList
          data={data}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) => this.renderSurgeryItem(item, index,title,key)}
        />

        {/* <View> 
          <TouchableOpacity onPress={() => triggerNewItem(key)}>
            {this.renderIconBtn("plus", "New", false, key)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  render() {
    return (
      <View>
        {this.renderTitleBorder(this.state.isComplaint, "Ocular History", "isOcular", this.props.triggerNewItem)}

        <View
          style={{
            flexDirection: "row",
            width: platform === "web" ? "100%" : "75%",
            justifyContent: "space-between"
          }}
        >
          {this.renderOcular("Medication", "", this.state.medicationData, "isOcular", this.props.triggerNewItem )}
          {this.renderOcularSurgery("Surgery", "", this.state.surgeryData, "isOcularSurgery", this.props.triggerNewItem )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
      OcularView: {
        marginLeft: '0.3vw',
        width: "75%",
        flexDirection: "row",
        justifyContent: "space-between",
        height: '3.3vw',
        alignItems: "center"
      },
      ItemView: {
        marginLeft: '0.3vw',
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        // height: 50,
        alignItems: "center"
        // paddingBottom: 10
      },
      ItemActionContainer: {
        alignItems: "center",
        height: '4.1vw',
        flexDirection: "row",
        flex: 0.25, 
        paddingRight: '0.6vw'
      },
      ItemActionView: {
        flexDirection: "row",
        paddingTop: '0.3vw',
        paddingBottom: '0.3vw',
        backgroundColor: color.white,
        height: '3.3vw'
      },
      SurgeryItemView: {
        marginLeft: '0.3vw',
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        // height: 50,
        alignItems: "center"
        // paddingBottom: 10
      },
      SurgeryItemActionContainer: {
        flexDirection: "row",
        flex: 0.25,
        alignItems: "center",
        height: '4.1vw', 
        paddingRight: '0.6vw'
      },
      SurgeryItemActionView: {
        flexDirection: "row",
        paddingTop: '0.3vw',
        paddingBottom: '0.3vw',
        backgroundColor: color.white,
        height: '3.3vw'
      },
      OcularSurgeryView: {
        width: '11.5vw',
        alignItems: "center",
        height: '3.3vw'
      }
})