import React, { Component,useState } from "react";
import {
  View,
  ScrollView,
  Dimensions,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import { NavigationTopHeader } from "../../BaseComponent";
import { color } from "../../../../styles/Color";
const screenHeight = Dimensions.get("window").height;
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

import {
  VisiteTimePlanner,
  VisiteType,
  ReviewPatientAttendance,
  Revenue,
  LocationAnalysis,
  SpecializationVisit,
  DoctorVisit,
  DeliveryDue,
} from "./BICharts";

// const navigationHeaderList = [
//   { label: "Business Intelligence", value: "businessIntelligence" },
//   { label: "Registry", value: "registry" },
// ];


const AdjustLabel = ({
  fontSize, text, style, numberOfLines
}) => {
  const [currentFont, setCurrentFont] = useState(fontSize);

  return (
    <Text
      numberOfLines={ numberOfLines }
      adjustsFontSizeToFit
      style={ [styles.fontStyleInCount, { fontSize: currentFont }] }
      onTextLayout={ (e) => {
        const { lines } = e.nativeEvent;
        if (lines.length > numberOfLines) {
          setCurrentFont(currentFont - 1);
        }
      } }
    >
      { text }
    </Text>
  );
};


export class BusinessIntelligence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateFilter: "",
      startDate: "",
      endDate: "",

      visiteTimePlannerLabels: [],
      visiteTimePlannerDatas: [],

      visiteTypeLabels: [],
      visiteTypeDatas: [],

      ReviewPatientAttendanceLabels: [],
      ReviewPatientAttendanceData: [],

      revenueLabels: [],
      revenueDatas: [],

      locationAnalysisLabels: [],
      locationAnalysisDatas: [],

      specializationVisitLabels: [],
      specializationVisitDatas: [],

      doctorVisitLabels: [],
      doctorVisitDatas: [],

      deliveryDuesLabels: [],
      deliveryDuesData: [],

      patientAndRevenueSummaryCount: {},

      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      doctorID: this.props.doctorID,

      showCustomDatePicker: false,

      isPrimaryDoctor:false,
      navigationHeaderList : [
        { label: "Business Intelligence", value: "businessIntelligence" },
        { label: "Registry", value: "registry" },
      ],

      userType:this.props.userType
    };
  }

  componentDidMount() {
    this.dateFilterCalculation("today");
  }

  callAllGetApis() {
    this.getPatientAndRevenueSummaryCount();

    this.getVisiteTimePlanner();
    this.getVisiteType();
    this.getReviewPatientAttendance();
    this.getRevenue();
    this.getLocationAnalysis();
    this.getSpecializationVisit(); // need to send the clinic id
    this.getDoctorVisit(); // need to send the clinic id
    if(this.state.userType == "OBGYN"){
      this.getDeliveryDues();
    }
    
  }

  changeTabSection(screen) {
    // alert(screen)
    this.props.biAndRegistry(screen,this.state.isPrimaryDoctor);
  }

  renderTopNavigation() {
    return (
      <View style={{ marginBottom: 20 }}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={this.state.navigationHeaderList}
          selectedTab={"businessIntelligence"}
        />
      </View>
    );
  }

  dateFilterCalculation(getKey) {
    // var getKey = item.key;
    var today = new Date();
    var filterDate = {};
    var dateFormet = "YYYY-MM-DD";

    var states = this.state;

    if (getKey == "today") {
      var date =
        today.getFullYear() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getDate();
      //   filterDate = { fromDate: date, toDate: date }
      states["startDate"] = date;
      states["endDate"] = date;
      // } else if (getKey == "yesterday") {

      //   var yesterday = new Date();
      //   yesterday.setDate(yesterday.getDate() - 1);
      //   var date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-' + yesterday.getDate();
      // //   filterDate = { fromDate: date, toDate: date }
      //   states["startDate"] = date;
      //   states["endDate"] = date;
    } else if (getKey == "last7days") {
      var todaydate =
        today.getFullYear() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getDate();

      var lastDate = new Date();
      lastDate.setDate(lastDate.getDate() - 7);
      var finalDate =
        lastDate.getFullYear() +
        "-" +
        (lastDate.getMonth() + 1) +
        "-" +
        lastDate.getDate();
      //   filterDate = { fromDate: finalDate, toDate: todaydate }
      states["startDate"] = finalDate;
      states["endDate"] = todaydate;
    } else if (getKey == "last30days") {
      var todaydate =
        today.getFullYear() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getDate();
      var getReviewDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
      var reviewDate = moment(getReviewDate).format(dateFormet);
      //   filterDate = { fromDate: reviewDate, toDate: todaydate }
      states["startDate"] = reviewDate;
      states["endDate"] = todaydate;

      // } else if (getKey == "last90days") {
      //   var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      //   var getReviewDate = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
      //   var reviewDate = moment(getReviewDate).format(dateFormet)
      // //   filterDate = { fromDate: reviewDate, toDate: todaydate }
      // states["startDate"] = reviewDate;
      //   states["endDate"] = todaydate;

      // } else if (getKey == "last12Months") {
      //   var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      //   var getReviewDate = new Date(today.getTime() - (365 * 24 * 60 * 60 * 1000));
      //   var reviewDate = moment(getReviewDate).format(dateFormet)
      // //   filterDate = { fromDate: reviewDate, toDate: todaydate }
      // states["startDate"] = reviewDate;
      //   states["endDate"] = todaydate;
      // } else if (getKey == "all") {
      //     states["startDate"] = "";
      //   states["endDate"] = "";
      // //   filterDate = { fromDate: "", toDate: "" }
    }

    if (getKey == "custom") {
    }

    // changeDD(false);
    // changeSelectedDateFilter(item.label);

    states["selectedDateFilter"] = getKey;

    this.setState(
      {
        states,
      },
      () => {
        this.callAllGetApis();
        // alert(this.state.selectedDateFilter);
        // alert(this.state.startDate);
        // alert(this.state.endDate);
      }
    );

    // if (getKey == "custom") {
    //   changeDD(false);
    // } else {
    //   props.showSelectedFilteredDate(filterDate)
    // }
  }

  dateFilter() {
    const listOfFilters = [
      // { label: "All", key: "all" },
      { label: "Today", key: "today" },
      // { label: "Yesterday", key: "yesterday" },
      { label: "Last 7 days", key: "last7days" },
      { label: "Last 30 days", key: "last30days" },
      // { label: "Last 90 days", key: "last90days" },
      // { label: "Last 12 Months", key: "last12Months" },
      { label: "Custom", key: "custom" },
    ];

    var me = this;

    function onChangeRangePicker(value, dateString) {
      var dateFormet = "YYYY-MM-DD";
      var a = dateString[0].substring(0, 10);
      var b = dateString[1].substring(0, 10);
      // var { selecteDateValue } = this.state
      var a_format = moment(a).format(dateFormet);
      var b_format = moment(b).format(dateFormet);

      // var selectedCustom = "(" + a_format + ")" + "-" + "(" + b_format + ")"
      // changeDD(false);
      // changeSelectedDateFilter(selectedCustom);

      // alert(a_format+"-----"+b_format)
      // var filterDate = { fromDate: a_format, toDate: b_format }
      // props.showSelectedFilteredDate(filterDate)

      // var states  = this.state;
      // states["startDate"] = a_format;
      // states["endDate"] = b_format;
      // var me= this;
      me.setStateDate(a_format, b_format);
      // me.setState({
      //   showCustomDatePicker:false,
      //   startDate:a_format,
      //   endDate:b_format,
      //   // states
      // },()=>{
      //   me.callAllGetApis();
      // })
    }

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginVertical: 10,
          }}
        >
          {listOfFilters.map((item, index) => {
            return (
              <View style={{ marginHorizontal: 20 }}>
                {this.state.selectedDateFilter == "custom" &&
                item.key == "custom" ? (
                  <RangePicker
                    open={this.state.showCustomDatePicker}
                    onChange={onChangeRangePicker}
                  />
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      if (item.key == "custom") {
                        this.setState({
                          selectedDateFilter: item.key,
                          showCustomDatePicker: !this.state
                            .showCustomDatePicker,
                        });
                      } else {
                        this.setState({
                          showCustomDatePicker: false,
                        });
                        this.dateFilterCalculation(item.key);
                      }
                    }}
                    style={[
                      this.state.selectedDateFilter == item.key
                        ? {
                            borderRadius: 6,
                            backgroundColor: color.themeDark,
                            paddingHorizontal: 7,
                            paddingVertical: 5,
                          }
                        : {},
                    ]}
                  >
                    <Text
                      style={[
                        this.state.selectedDateFilter == item.key
                          ? {
                              color: color.white,
                            }
                          : {},
                      ]}
                    >
                      {item.label}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            );
          })}

          {/* {
            this.state.selectedDateFilter == "custom" ?

              <RangePicker
                open={this.state.showCustomDatePicker}
                onChange={this.onChangeRangePicker}


              />
              : null
          } */}
        </View>

        {/* <TouchableOpacity 
            style={{}}
            >
                <Text>{"Today"}</Text>
            </TouchableOpacity> */}
      </View>
    );
  }

  customFilterDate(date) {
    // var states = this.state;
    this.setState(
      {
        showCustomDatePicker: false,
        startDate: date.fromDate,
        endDate: date.toDate,
        // states
      },
      () => {
        this.callAllGetApis();
      }
    );
  }

  setStateDate(startDate, endDate) {
    this.setState(
      {
        showCustomDatePicker: false,
        startDate: startDate,
        endDate: endDate,
        // states
      },
      () => {
        this.callAllGetApis();
      }
    );
  }

  onChangeRangePicker(value, dateString) {
    var dateFormet = "YYYY-MM-DD";
    var a = dateString[0].substring(0, 10);
    var b = dateString[1].substring(0, 10);
    // var { selecteDateValue } = this.state
    var a_format = moment(a).format(dateFormet);
    var b_format = moment(b).format(dateFormet);

    // var selectedCustom = "(" + a_format + ")" + "-" + "(" + b_format + ")"
    // changeDD(false);
    // changeSelectedDateFilter(selectedCustom);

    // alert(a_format+"-----"+b_format)
    // var filterDate = { fromDate: a_format, toDate: b_format }
    // props.showSelectedFilteredDate(filterDate)

    // var states  = this.state;
    // states["startDate"] = a_format;
    // states["endDate"] = b_format;
    var me = this;
    me.setStateDate();
    // me.setState({
    //   showCustomDatePicker:false,
    //   startDate:a_format,
    //   endDate:b_format,
    //   // states
    // },()=>{
    //   me.callAllGetApis();
    // })
  }

  renderPatientAndRevenueCountsView(label, count, image, bgColor) {
    var fontSize = 50;
    return (
      <View
        style={[styles.patientAndRevenueCounts, { backgroundColor: bgColor }]}
      >
        <View style={{ 
          // flex: 0.5 , 
          justifyContent: "center" }}>
          <Text adjustsFontSizeToFit 
          
          // onTextLayout={ (e) => {
          //   const { lines } = e.nativeEvent;
          //   if (lines.length > numberOfLines) {
          //     fontSize=fontSize-1
          //     // setCurrentFont(currentFont - 1);
          //   }
          // } }
          
          style={[styles.fontStyleInCount,label == "Total Revenue"?{fontSize:30} :{}]}>{count}</Text>
          {/* <AdjustLabel fontSize={50} text={count}  numberOfLines={2}/> */}
        </View>
        <View
          style={{
            alignItems:"flex-end",
            // flex: 0.5,
            // alignItems: "flex-end",
            // justifyContent: "flex-end",
            paddingBottom: 10,
            paddingRight: 10,
          }}
        >
          {/* {image} */}
          {/* <Image source={require("../../../../../assets/images/BI_images/Out_Patients.png")} style={styles.patientAndRevenueImage}/> */}
          <Image source={image} style={styles.patientAndRevenueImage} />

          <Text style={styles.fontStyleInText}>{label}</Text>
        </View>
      </View>
    );
  }
  renderPatientAndRevenueCounts() {

    function thousands_separators(num)
      {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
      }

    var { patientAndRevenueSummaryCount } = this.state;
    return (
      <View style={{ flexDirection: "row", marginLeft: 10 }}>
        {this.renderPatientAndRevenueCountsView(
          "Out Patient's",
          patientAndRevenueSummaryCount.out_patient
            ? thousands_separators(patientAndRevenueSummaryCount.out_patient)
            : 0,
          require("../../../../../assets/images/BI_images/Out_Patients.png"),
          "#F17171"
        )}
        {this.renderPatientAndRevenueCountsView(
          "In Patient's",
          patientAndRevenueSummaryCount.in_patient
            ? thousands_separators(patientAndRevenueSummaryCount.in_patient)
            : 0,
          require("../../../../../assets/images/BI_images/In_Patients.png"),
          "#FFA071"
        )}
        {this.renderPatientAndRevenueCountsView(
          "Total Patient's",
          patientAndRevenueSummaryCount.total_patient
            ? thousands_separators(patientAndRevenueSummaryCount.total_patient)
            : 0,
          require("../../../../../assets/images/BI_images/Total_Patients.png"),
          "#71D0FF"
        )}
        {this.renderPatientAndRevenueCountsView(
          "Total Revenue",
          patientAndRevenueSummaryCount.total_revenue
            ? thousands_separators(patientAndRevenueSummaryCount.total_revenue)
            : 0,
          // "107836.1",
          require("../../../../../assets/images/BI_images/Total_Revenue.png"),
          "#758BC9"
        )}
      </View>
    );
  }

  render() {
    return (
      <View>
        {
          this.state.isPrimaryDoctor ? 
      <View>
        <View style={{ backgroundColor: color.applicationBackgroundColor }}>
          {this.renderTopNavigation()}
        </View>
        <ScrollView style={{ height: screenHeight - 130 }}>
          <View style={{ backgroundColor: color.white }}>
            <View style={{}} >
              {this.dateFilter()}
              {this.renderPatientAndRevenueCounts()}
            </View>
            <View style={styles.chartViewStyle} >
              <View style={{ flex: 0.48 }}>
                <VisiteTimePlanner
                  visiteTimePlannerLabels={this.state.visiteTimePlannerLabels}
                  visiteTimePlannerDatas={this.state.visiteTimePlannerDatas}
                />
              </View>
              <View style={{ flex: 0.48 }}>
                <VisiteType
                  visiteTypeLabels={this.state.visiteTypeLabels}
                  visiteTypeDatas={this.state.visiteTypeDatas}
                />
              </View>
            </View>

            <View
              style={styles.chartViewStyle}
            >
              <View style={{ flex: 0.48 }}>
                <ReviewPatientAttendance
                  ReviewPatientAttendanceLabels={ this.state.ReviewPatientAttendanceLabels }
                  ReviewPatientAttendanceData={ this.state.ReviewPatientAttendanceData }
                />
              </View>
              <View style={{ flex: 0.48 }}>
                <Revenue
                  revenueLabels={this.state.revenueLabels}
                  revenueDatas={this.state.revenueDatas}
                />
              </View>
            </View>

            <View
              style={styles.chartViewStyle}
            >
              <View style={{ flex: 0.48 }}>
                <LocationAnalysis
                  locationAnalysisLabels={this.state.locationAnalysisLabels}
                  locationAnalysisDatas={this.state.locationAnalysisDatas}
                />
              </View>
              <View style={{ flex: 0.48 }}>

              {
                this.state.userType == "OBGYN" ?
                <DeliveryDue
                  deliveryDuesLabels={this.state.deliveryDuesLabels}
                  deliveryDuesData={this.state.deliveryDuesData}
                /> :
                <SpecializationVisit
                  specializationVisitLabels={ this.state.specializationVisitLabels }
                  specializationVisitDatas={this.state.specializationVisitDatas}
                />
              }

                {/* <DeliveryDue
                  deliveryDuesLabels={this.state.deliveryDuesLabels}
                  deliveryDuesData={this.state.deliveryDuesData}
                /> */}
              </View>
            </View>

            <View
              style={styles.chartViewStyle}
            >
              <View style={{ flex: 0.48 }}>

              {
                this.state.userType == "OBGYN" ?
                <SpecializationVisit
                  specializationVisitLabels={ this.state.specializationVisitLabels }
                  specializationVisitDatas={this.state.specializationVisitDatas}
                /> :
                <DoctorVisit
                  doctorVisitLabels={this.state.doctorVisitLabels}
                  doctorVisitDatas={this.state.doctorVisitDatas}
                />
              }

              </View>
              <View style={{ flex: 0.48 }}>

              {
                this.state.userType == "OBGYN" ?
                <DoctorVisit
                  doctorVisitLabels={this.state.doctorVisitLabels}
                  doctorVisitDatas={this.state.doctorVisitDatas}
                /> :
                null
              }
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
        : null
        }
      </View>
      
    );
  }

  getPatientAndRevenueSummaryCount() {
    var serviceUrl =
      Constants.BI_PATIENT_REVENUE_SUMMARY_COUNT +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
      // "&clinic_id=" +
      // this.state.selectedClinicOrDoctor.id;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPatientAndRevenueSummaryCountSuccess,
      this.getPatientAndRevenueSummaryCountFailure
    );
  }

  getPatientAndRevenueSummaryCountSuccess = (success) => {
    if (success.status == "success") {
      this.setState({
        patientAndRevenueSummaryCount: success.data,
        isPrimaryDoctor:true,
          navigationHeaderList:[
            { label: "Business Intelligence", value: "businessIntelligence" },
            { label: "Registry", value: "registry" },
          ]
      });
    }else{

      if(success.message == Constants.VALIDATE_PRIMARY_DR_MESSAGE){
        this.props.biAndRegistry("registry",false);

        this.setState({
          isPrimaryDoctor:false,
          navigationHeaderList:[
            { label: "Registry", value: "registry" },
          ]
        })
      }

      
    }
  };
  getPatientAndRevenueSummaryCountFailure = (error) => {};

  getVisiteTimePlanner() {
    var serviceUrl =
      Constants.BI_VISITE_TIME_PLANNER +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getVisiteTimePlannerSuccess,
      this.getVisiteTimePlannerFailure
    );
  }

  getVisiteTimePlannerSuccess = (success) => {
    if (success.status == "success") {
      var getVisiteTimePlannerDatas = success.data;

      var visiteTimePlannerLabels = [];
      var visiteTimePlannerDatas = [];

      if (getVisiteTimePlannerDatas.length > 0) {
        for (const item of getVisiteTimePlannerDatas) {
          visiteTimePlannerLabels.push(item.timing);
          visiteTimePlannerDatas.push(item.count);
        }
      }
      this.setState({
        visiteTimePlannerLabels: visiteTimePlannerLabels,
        visiteTimePlannerDatas: visiteTimePlannerDatas,
      });
      console.log(JSON.stringify(success));
    }
  };
  getVisiteTimePlannerFailure = (error) => {
    // alert(JSON.stringify(error))
    console.log(JSON.stringify(error));
  };

  getVisiteType() {
    var serviceUrl =
      Constants.BI_VISITE_TYPE +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getVisiteTypeSuccess,
      this.getVisiteTypeFailure
    );
  }

  getVisiteTypeSuccess = (success) => {
    var getVisiteTypeDatas = success.visit_type;

    var visiteTypeLabels = [];
    var visiteTypeDatas = [];

    Object.keys(getVisiteTypeDatas).forEach((key) => {
      // if (key == "total_appointment_count") {
      //   visiteTypeLabels.push("Total Appointment Count");
      //   visiteTypeDatas.push(getVisiteTypeDatas[key]);
      // } else
      if (key == "new_appointment_count") {
        visiteTypeLabels.push("New Appointment Count");
        visiteTypeDatas.push(getVisiteTypeDatas[key]);
      } else if (key == "old_appointment_count") {
        visiteTypeLabels.push("Old Appointment Count");
        visiteTypeDatas.push(getVisiteTypeDatas[key]);
      }
    });

    this.setState({
      visiteTypeLabels: visiteTypeLabels,
      visiteTypeDatas: visiteTypeDatas,
    });
    console.log(JSON.stringify(success));
  };
  getVisiteTypeFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getReviewPatientAttendance() {
    var serviceUrl =
      Constants.BI_REVIEW_PATIENT_ATTENDNCE +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getReviewPatientAttendanceSuccess,
      this.getReviewPatientAttendanceFailure
    );
  }

  getReviewPatientAttendanceSuccess = (success) => {
    var getVisiteTypeDatas = success.patient_attendance;

    var ReviewPatientAttendanceLabels = [];
    var ReviewPatientAttendanceData = [];

    Object.keys(getVisiteTypeDatas).forEach((key) => {
      // if (key == "total_patient") {
      //   ReviewPatientAttendanceLabels.push("Total Patient");
      //   ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
      // } else
      if (key == "turned_up") {
        ReviewPatientAttendanceLabels.push("Turned Up");
        ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
      } else if (key == "not_turned_up") {
        ReviewPatientAttendanceLabels.push("Not Turned Up");
        ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
      }
    });

    this.setState({
      ReviewPatientAttendanceLabels: ReviewPatientAttendanceLabels,
      ReviewPatientAttendanceData: ReviewPatientAttendanceData,
    });
    console.log(JSON.stringify(success));
  };
  getReviewPatientAttendanceFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getLocationAnalysis() {
    var serviceUrl =
      Constants.BI_LOCATION_ANALYSIS +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getLocationAnalysisSuccess,
      this.getLocationAnalysisFailure
    );
  }

  getLocationAnalysisSuccess = (success) => {
    if (success.status == "success") {
      var getLocationAnalysis = success.data;

      getLocationAnalysis.push({ area_name: "", count: 0 });

      var locationAnalysisLabels = [];
      var locationAnalysisDatas = [];

      if (getLocationAnalysis.length > 0) {
        for (const item of getLocationAnalysis) {
          if(item.area_name == null ||item.area_name == undefined  || item.area_name == "" ){
            // do nothing 
          }else{
            locationAnalysisLabels.push(item.area_name);
            locationAnalysisDatas.push(item.count);
          }
        }
      }

      this.setState({
        locationAnalysisLabels: locationAnalysisLabels,
        locationAnalysisDatas: locationAnalysisDatas,
      });
      console.log(JSON.stringify(success));
    }
  };
  getLocationAnalysisFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getSpecializationVisit() {
    var serviceUrl =
      Constants.BI_SPECIALIZATION_VISIT +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
      // "&clinic_id=" +
      // this.state.selectedClinicOrDoctor.id;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getSpecializationVisitSuccess,
      this.getSpecializationVisitFailure
    );
  }

  getSpecializationVisitSuccess = (success) => {
    if (success.status == "success") {
      var specializationVisit = success.data;

      //   var  specializationVisit =  [
      //     {
      //       "total_count": 0,
      //       "speciality_name": ""
      //     },
      //       {
      //           "speciality_id": 1,
      //           "total_count": 2,
      //           "speciality_name": "GP"
      //       },
      //       {
      //           "speciality_id": 2,
      //           "total_count": 5,
      //           "speciality_name": "PD"
      //       },
      //       {
      //         "speciality_id": 1,
      //         "total_count": 2,
      //         "speciality_name": "GP"
      //     },
      //     {
      //         "speciality_id": 2,
      //         "total_count": 5,
      //         "speciality_name": "PD"
      //     },
      //     {
      //       "speciality_id": 1,
      //       "total_count": 2,
      //       "speciality_name": "GP"
      //   },
      //   {
      //       "speciality_id": 2,
      //       "total_count": 5,
      //       "speciality_name": "PD"
      //   },
      //   {
      //     "speciality_id": 1,
      //     "total_count": 2,
      //     "speciality_name": "GP"
      // },
      // {
      //     "speciality_id": 2,
      //     "total_count": 5,
      //     "speciality_name": "PD"
      // },
      //       {
      //           "speciality_id": 3,
      //           "total_count": 10,
      //           "speciality_name": "DB"
      //       }
      //   ]

      var specializationVisitLabels = [];
      var specializationVisitDatas = [];

      if (specializationVisit.length > 0) {
        specializationVisitLabels.push("")
        specializationVisitDatas.push(0);
        for (const item of specializationVisit) {
          specializationVisitLabels.push(item.speciality_name);
          specializationVisitDatas.push(item.total_count);
        }
      }

      this.setState({
        specializationVisitLabels: specializationVisitLabels,
        specializationVisitDatas: specializationVisitDatas,
      });
      console.log(JSON.stringify(success));
    }
  };
  getSpecializationVisitFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getDoctorVisit() {
    var serviceUrl =
      Constants.BI_DOCTOR_VISIT +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
      // "&clinic_id=" +
      // this.state.selectedClinicOrDoctor.id;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDoctorVisitSuccess,
      this.getDoctorVisitFailure
    );
  }

  getDoctorVisitSuccess = (success) => {
    if (success.status == "success") {
      var doctorVisit = success.data;

      //   var  doctorVisit =  [
      //     {
      //       "total_count": 0,
      //       "full_name": ""
      //     },
      //       {
      //           "speciality_id": 1,
      //           "total_count": 2,
      //           "full_name": "GP"
      //       },
      //       {
      //           "speciality_id": 2,
      //           "total_count": 5,
      //           "full_name": "PD"
      //       },
      //       {
      //         "speciality_id": 1,
      //         "total_count": 2,
      //         "full_name": "GP"
      //     },
      //     {
      //         "speciality_id": 2,
      //         "total_count": 5,
      //         "full_name": "PD"
      //     },
      //     {
      //       "speciality_id": 1,
      //       "total_count": 2,
      //       "full_name": "GP"
      //   },
      //   {
      //       "speciality_id": 2,
      //       "total_count": 5,
      //       "full_name": "PD"
      //   },
      //   {
      //     "speciality_id": 1,
      //     "total_count": 2,
      //     "full_name": "GP"
      // },
      // {
      //     "speciality_id": 2,
      //     "total_count": 5,
      //     "full_name": "PD"
      // },
      //       {
      //           "speciality_id": 3,
      //           "total_count": 10,
      //           "full_name": "DB"
      //       }
      //   ]

      var doctorVisitLabels = [];
      var doctorVisitDatas = [];

      if (doctorVisit.length > 0) {
        doctorVisitLabels.push("");
        doctorVisitDatas.push(0);
        for (const item of doctorVisit) {
          doctorVisitLabels.push(item.full_name);
          doctorVisitDatas.push(item.total_count);
        }
      }

      // alert(JSON.stringify(doctorVisitLabels))
      // alert(JSON.stringify(doctorVisitDatas))

      this.setState({
        doctorVisitLabels: doctorVisitLabels,
        doctorVisitDatas: doctorVisitDatas,
      });
      console.log(JSON.stringify(success));
    }
  };
  getDoctorVisitFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getDeliveryDues() {
    var serviceUrl =
      Constants.BI_DELIVERY_DUE +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDeliveryDuesSuccess,
      this.getDeliveryDuesFailure
    );
  }

  monthValidation(monthInNumber) {
    var totalMonths = [
        { month: "January", totalDays: 31 },
        { month: "February", totalDays: 28 },
        { month: "March", totalDays: 31 },
        { month: "April", totalDays: 30 },
        { month: "May", totalDays: 31 },
        { month: "June", totalDays: 30 },
        { month: "July", totalDays: 31 },
        { month: "August", totalDays: 31 },
        { month: "September", totalDays: 30 },
        { month: "October", totalDays: 31 },
        { month: "November", totalDays: 30 },
        { month: "December", totalDays: 31 },
      ],
      index = parseInt(monthInNumber) - 1;
    return totalMonths[index].month;
  }

  getDeliveryDuesSuccess = (success) => {
    var getDeliveryDuesDatas = success.data
    // alert("success --- >")

    // var getDeliveryDuesDatas = [
    //       {
    //           "month": 1,
    //           "patient": 1
    //       },
    //       {
    //           "month": 2,
    //           "patient": 2
    //       },
    //       {
    //           "month": 3,
    //           "patient": 1
    //       },
    //       {
    //         "month": 4,
    //         "patient": 1
    //     },
    //     {
    //       "month": 5,
    //       "patient": 1
    //   },
    //   {
    //       "month": 6,
    //       "patient": 2
    //   },
    //   {
    //       "month": 7,
    //       "patient": 1
    //   },
    //   {
    //     "month": 8,
    //     "patient": 1
    // },
    //     {
    //         "month": 9,
    //         "patient": 2
    //     },
    //     {
    //         "month": 10,
    //         "patient": 1
    //     }
    //   ]

    var deliveryDuesLabels = [];
    var deliveryDuesData = [];

    if (getDeliveryDuesDatas.length > 0) {
      // alert(JSON.stringify(getDeliveryDuesDatas))
      for (const item of getDeliveryDuesDatas) {
        deliveryDuesLabels.push(this.monthValidation(item.month));
        deliveryDuesData.push(item.patient);
      }
    }

    // Object.keys(getDeliveryDuesDatas).forEach((key) => {

    //   // alert()

    //   // if (key == "total_patient") {
    //     ReviewPatientAttendanceLabels.push(month);
    //     ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
    //   // } else
    //   // if (key == "turned_up") {
    //   //   ReviewPatientAttendanceLabels.push("Turned Up");
    //   //   ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
    //   // } else if (key == "not_turned_up") {
    //   //   ReviewPatientAttendanceLabels.push("Not Turned Up");
    //   //   ReviewPatientAttendanceData.push(getVisiteTypeDatas[key]);
    //   // }
    // });
    // alert("success --- > "+JSON.stringify(deliveryDuesLabels))
    // alert("success --- > "+JSON.stringify(deliveryDuesData))
    console.log("success --- > " + JSON.stringify(success));
    this.setState({
      deliveryDuesLabels: deliveryDuesLabels,
      deliveryDuesData: deliveryDuesData,
    });
    console.log(JSON.stringify(success));
  };
  getDeliveryDuesFailure = (error) => {
    console.log(JSON.stringify(error));
  };

  getRevenue() {
    var serviceUrl =
      Constants.BI_REVENUE +
      "?start_date=" +
      this.state.startDate +
      "&end_date=" +
      this.state.endDate 
      +
      // "&clinic_id=" +
      // this.state.selectedClinicOrDoctor.id;
      "&doctor_id=" +
      this.state.doctorID
      ;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRevenueSuccess,
      this.getRevenueFailure
    );
  }

  getRevenueSuccess = (success) => {
    var getRevenueDatas = success.data;

    //   var getRevenueDatas= {

    //     "service_revenue": 3600.0,
    //     "pharmacy_revenue": 1050.0,
    //     "laboratory_revenue": 1000.0,
    //     "test":0,
    // }
    // alert(JSON.stringify(success.data))

    getRevenueDatas["test"] = 0;

    var revenueLabels = [];
    var revenueDatas = [];

    Object.keys(getRevenueDatas).forEach((key) => {
      if (key == "service_revenue") {
        revenueLabels.push("Service Revenue");
        revenueDatas.push(getRevenueDatas[key]);
      } else if (key == "pharmacy_revenue") {
        revenueLabels.push("Pharmacy Revenue");
        revenueDatas.push(getRevenueDatas[key]);
      } else if (key == "laboratory_revenue") {
        revenueLabels.push("Laboratory Revenue");
        revenueDatas.push(getRevenueDatas[key]);
      } else if (key == "test") {
        revenueLabels.push("");
        revenueDatas.push(getRevenueDatas[key]);
      }
    });

    this.setState({
      revenueLabels: revenueLabels,
      revenueDatas: revenueDatas,
    });
    console.log(JSON.stringify(success));
  };

  getRevenueFailure = (error) => {
    console.log(JSON.stringify(error));
  };
}

const styles = StyleSheet.create({
  patientAndRevenueCounts: {
    justifyContent:'space-between',
    flexDirection: "row",
    backgroundColor: "pink",
    borderRadius: 5,
    marginVertical: 10,
    height: 100,
    // width: 320,
    minWidth:180,
    marginRight: 20,
  },
  fontStyleInCount: {
    marginLeft: 10,
    fontSize: 30,
    color: color.white,
    fontWeight: "bold",
  },
  fontStyleInText: {
    fontSize: 18,
    color: color.white,
    fontWeight: "bold",
  },

  chartViewStyle:{
    flexDirection: "row", justifyContent: "space-evenly"
  },

  patientAndRevenueImage: { height: 60, width: 60 },
  // ChartCardView: {
  //   width: "80%",
  //   height: 400,
  //   marginVertical: 10,
  //   backgroundColor: color.applicationBackgroundColor,
  // },
  // ChartHeaderView: {
  //   paddingVertical: 8,
  //   borderBottomColor: "#888888",
  //   borderBottomWidth: 1,
  //   alignItems: "center",
  // },
  // ChartHeaderStyle: { fontSize: 20, fontWeight: "600" },
  // ChartBodyView: {
  //   paddingHorizontal: 10,
  //   paddingVertical: 10,
  //   justifyContent: "center",
  // },
});
