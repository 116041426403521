import React, { Component, useContext } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import { CommonDatePicker, } from './registryCommonComponents'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService"
import { Constants } from "../../../../utils/Constants";

import { RegistryContext } from "./RegistryContext";

// const demographicsDetails = useContext(RegistryContext);



const listOfGender = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
    { label: "Trans", value: "t" },
]

const listOfAreaa = [
    { label: "Singanallure", value: "Singanallure" },
    { label: "Hopes", value: "Hopes" },
    { label: "LakshmiMills", value: "LakshmiMills" },
]


export class RegistryDemographic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dobFromDate: "",
            dobToDate: "",
            AppointmentFromDate: "",
            AppointmentToDate: "",
            listOfGender: listOfGender,
            selectedGender: [],
            showLabSetNewButtonFlag: false,
            newButtonValue: "",
            areaName: "",
            cityName: "",
            listOfAreaa: [],
            listOfCity: [],
            selectedArea: [],
            selectedCity: [],
            ageFrom: '',
            ageTo: '',
            billingListAfterPagination:[]



        };
    }

    // componentWillReceiveProps(props) {
    //     if(props && props.demographicsDatas)
    //     this.setState({
    //         ageFrom:props.demographicsDatas.ageFrom,
    //         ageTo:props.demographicsDatas.ageTo

    //     })

    // }

    getDatePickerDate(getContext, date, key) {
        var states = this.state;
        states[key] = date;
        this.setState({ states }, () => {
            getContext.demographicsDatas(key, date)

        })

    }


    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    getGender(key, selectedGender) {
        changeGender(selectedGender)
        // demographicsFilter["genderObj"] = selectedGender;
        demographicsFilter["gender"] = selectedGender.value;
        changeDemographicsFilter(demographicsFilter)
    }


    getDemographicsAreaAndCity = (query, type) => {

        var searchType = ""
        if (type == "Area") {
            searchType = "area"
        } else if (type == "City") {
            searchType = "city"

        }
        var serviceUrl = Constants.REGISTRY_COMMON_SEARCH + "?search_key=" + query + "&search_type=" + searchType;




        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getDemographicsAreaAndCitySuccess,
            this.getDemographicsAreaAndCityFailure,
            type
        );

    }
    getDemographicsAreaAndCitySuccess = (response, type) => {

        if (response.status == "success") {
            var states = this.state
            if (type == "Area") {
                states['listOfAreaa'] = response.data
            } else if (type == "City") {
                states["listOfCity"] = response.data
            }

            this.setState({
                states
            })
        }


    };

    getDemographicsAreaAndCityFailure = error => {
        // console.log("search patient error response");
        console.log(error);
    }



    renderGender(demographics,contect) {
        return (
            <View style={{}}>
                <CommonHistorySectionHeader
                    heading1={"Gender"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View>
                    {this.descriptionMultiButtons(
                        "",
                        "selectedGender",
                        demographics.selectedGender,
                        "",
                        this.state.showLabSetNewButtonFlag,
                        this.state.listOfGender,
                        contect
                    )}
                </View>
            </View>
        )
    }

    onPressselectedArea(item, contextCallBack) {
        // var billList=[];
        var { selectedArea } = this.state;

        selectedArea.push(item);
        selectedArea = this.removeDuplicate(selectedArea)
        this.setState({ selectedArea, areaName: "", listOfAreaa: [], listOfCity: [] }, () => {
            contextCallBack("selectedArea", this.state.selectedArea)

        })

    }



    onPressselectedCity(item, contextCallBack) {
        var { selectedCity } = this.state;
        selectedCity.push(item);
        selectedCity = this.removeDuplicate(selectedCity)
        this.setState({ selectedCity, cityName: "", listOfAreaa: [], listOfCity: [] }, () => {
            contextCallBack("selectedCity", this.state.selectedCity)
        })
    }

    renderAddress(demographics,contextCallBack) {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Address"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    {
                       demographics.selectedArea.map((item, index) => {
                            return (
                                <View>

                                    <View style={[{
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingTop: 5,
                                        marginLeft: 15,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        marginTop: 10,
                                        paddingBottom: 5,
                                        flexDirection: 'row',
                                        // justifyContent:'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        borderColor: "#888888"
                                    },
                                    ]}

                                    >
                                        <Text style={{ color: "black" }}>
                                            {item}
                                        </Text>
                                        <View style={{ height: 15, width: 1, backgroundColor: color.black, marginHorizontal: 10 }} />
                                        <TouchableOpacity onPress={() => {
                                            let { selectedArea } = this.state;
                                            selectedArea.splice(index, 1)
                                            this.setState({
                                                selectedArea,
                                                areaName: "",
                                                cityName: "",


                                            }, () => {
                                                contextCallBack("selectedArea", this.state.selectedArea)
                                            })

                                        }}>
                                            <Text style={{ color: "black" }}>{"X"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            )
                        })
                    }

                    {
                       demographics.selectedCity.map((item, index) => {
                            return (
                                <View>

                                    <View style={[{
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingTop: 5,
                                        marginLeft: 15,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        marginTop: 10,
                                        paddingBottom: 5,
                                        flexDirection: 'row',
                                        // justifyContent:'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        borderColor: "#888888"
                                    },
                                    ]}

                                    >
                                        <Text style={{ color: "black" }}>
                                            {item}
                                        </Text>
                                        <View style={{ height: 15, width: 1, backgroundColor: color.black, marginHorizontal: 10 }} />
                                        <TouchableOpacity onPress={() => {
                                            let { selectedCity } = this.state;
                                            selectedCity.splice(index, 1)
                                            this.setState({
                                                selectedCity,
                                            }, () => {
                                                contextCallBack("selectedCity", this.state.selectedCity)

                                            })

                                        }}>
                                            <Text style={{ color: "black" }}>{"X"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ flex: 0.5 }}>
                        {this.renderCommonTextBox("Area", this.state.areaName, "areaName")}
                        {this.state.listOfAreaa.length > 0 ?
                            <View style={{
                                backgroundColor: color.white,
                                maxHeight: 200,
                                borderRadius: 10,
                                width: '90%',
                                justifyContent: 'center',
                                alignContent: 'center',
                                padding: 10,
                                marginLeft: 10,
                                marginTop: 40,
                                position: 'absolute',
                            }}>
                                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                    <FlatList
                                        data={this.state.listOfAreaa}
                                        renderItem={({ item }) => (
                                            <View style={{
                                                flexDirection: "row",
                                                bottom: 10,
                                                marginBottom: 10,
                                                justifyContent: "space-evenly",
                                                width: "100%",
                                                alignItems: "center",
                                                marginTop: 10,
                                                zIndex: 10,
                                            }}
                                            >
                                                <View style={{ width: '100%' }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.onPressselectedArea(item, contextCallBack);

                                                            // 
                                                        }
                                                        }>
                                                        <Text>{item}</Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>
                                        )}
                                        enableEmptySections={true}


                                    //keyExtractor={(item, index) => index.toString()}
                                    />
                                </ScrollView>
                            </View>
                            : null}
                    </View>
                    <View style={{ flex: 0.5 }}>
                        <View>
                            {this.renderCommonTextBox("City", this.state.cityName, "cityName")}

                        </View>
                        {this.state.listOfCity.length > 0 ?
                            <View style={{
                                backgroundColor: color.white,
                                maxHeight: 200,
                                borderRadius: 10,
                                width: '90%',
                                justifyContent: 'center',
                                alignContent: 'center',
                                padding: 10,
                                marginLeft: 10,
                                marginTop: 40,
                                position: 'absolute',
                            }}>
                                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                    <FlatList
                                        data={this.state.listOfCity}
                                        renderItem={({ item }) => (
                                            <View style={{
                                                flexDirection: "row",
                                                bottom: 10,
                                                marginBottom: 10,
                                                justifyContent: "space-evenly",
                                                width: "100%",
                                                alignItems: "center",
                                                marginTop: 10,
                                                zIndex: 10,
                                            }}
                                            >
                                                <View style={{ width: '100%' }}>
                                                    <TouchableOpacity
                                                        onPress={() => {

                                                            this.onPressselectedCity(item, contextCallBack);
                                                        }
                                                        }>
                                                        <Text>{item}</Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>
                                        )}
                                        enableEmptySections={true}


                                    //keyExtractor={(item, index) => index.toString()}
                                    />
                                </ScrollView>
                            </View>

                            : null}
                    </View>

                </View>

            </View>
        )
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onPressButton(contectCallBack = "", checkIsCallBack = false, key, selectedValue) {
        if (checkIsCallBack) {
            // alert(key)
            // if(key=="selectedGender"){

            // }
            contectCallBack(key, selectedValue);
        }


        var states = this.state;

        if (key == "selectedGender") {
            states[key] = selectedValue;


        } else if (key == "clear") {

            this.setState({
                dobFromDate: "",
                dobToDate: "",
                AppointmentFromDate: "",
                AppointmentToDate: "",
                selectedGender: {},
                areaName: "",
                cityName: "",
                selectedArea: [],
                selectedCity: [],
                ageFrom: '',
                ageTo: '',
            })

        }
        this.setState({ states })

    }


    renderCommonTextBox(placeholder, value, textBoxkey, contect) {
        return (
            <View style={[{height: '2.81vw', marginRight: 15 }]}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>


                <TextInput
                    style={{
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: '2vw',
                        paddingLeft: 10,
                        marginTop: 8,
                        fontSize:'0.9vw'
                    }}
                    value={value}
                    onChangeText={(text) => {
                        if (textBoxkey == "ageFrom" || textBoxkey == "ageTo") {
                            var isNum = this.numberValidation(text)
                            if (isNum) {
                                var states = this.state;
                                states[textBoxkey] = text;
                            }

                        } else {
                            var states = this.state;
                            states[textBoxkey] = text;
                        }

                        this.setState({ states }, () => {
                            if (contect) {
                                contect(textBoxkey, text);

                            }
                            this.getDemographicsAreaAndCity(text, placeholder)
                        })
                    }}
                />




            </View>
        )
    }

    descriptionMultiButtons(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData, contectCallBack) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                {
                    data.map((item, index) => {
                        var listData = {}
                        if (key == "selectedDescription") {
                            listData = { value: item.service_name, label: item.rate_per_unit }
                        } else {
                            listData = item
                        }

                        return (
                            <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                <CommonButton
                                    item={listData}
                                    selectedvalue={selectedValue}
                                    butttonText={listData.label}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={key} />
                            </View>
                        )
                    })
                }

                <View style={{ marginBottom: 10 }}>
                    {
                        newButtonKey ?

                            newbuttonFlag ?
                                <CommonButton
                                    item={{ label: "New", value: "New" }}
                                    selectedValue={{}}
                                    butttonText={"New"}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                    rightIcon={true}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, false)}
                                    buttonKey={newButtonKey} />
                                :
                                <TextInput
                                    style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                    onChangeText={(text) => {
                                        this.setState({ newButtonValue: text })
                                    }}
                                    onSubmitEditing={() => {
                                        this.onSubmitToAddValue()
                                    }}
                                />
                            : null
                    }
                </View>
            </View>
        )
    }


    renderFooterAction(contectCallBack) {
        var actions = []

        actions = [
            { label: "Apply", value: "Apply" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15 }}>
                {
                    actions.map((item, index) => {
                        return (
                            <View style={{ marginRight: 15 }}>
                                <CommonButton
                                    // disable={this.state.isLocked}
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={item.label}
                                    buttonType={item.value == "Apply" ? "theme" : "outlineTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={item.value} />
                            </View>
                        )
                    })
                }

            </View>
        )
    }

    renderAge(demographics,contect) {
        return (
            <View style={{ marginBottom: '1vw' }}>
                <CommonHistorySectionHeader
                    heading1={"Age"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: 'row', flexDirection:'row',  alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ width:'50%' }}>

                        {this.renderCommonTextBox("From", demographics.ageFrom, "ageFrom", contect)}
                    </View>
                    <View style={{ width:"50%" }}>
                        {this.renderCommonTextBox("To", demographics.ageTo, "ageTo", contect)}
                    </View>
                </View>
            </View>
        )
    }


    render() {

        return (
            <RegistryContext.Consumer>
                {(getContext) => {

                        var demographics = getContext.demographicsDetails;
                   


                    return (
                        <>
                            <View>
                                <View>
                                    <CommonHistorySectionHeader
                                        heading1={"Date Of Birth"}
                                        columnSize={[1]}
                                        noOfColumn={1}
                                    />
                                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', paddingLeft: 15 }}>
                                        <View style={{ flex: 0.5 }}>

                                            <CommonDatePicker
                                                label={"From"} selectedDate={demographics.dobFromDate} datePickerKey={"dobFromDate"} callBackValue={this.getDatePickerDate.bind(this, getContext)} />
                                        </View>
                                        <View style={{ flex: 0.5 }}>

                                            <CommonDatePicker label={"To"} selectedDate={demographics.dobToDate} datePickerKey={"dobToDate"} callBackValue={this.getDatePickerDate.bind(this, getContext)} />
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    {this.renderAge(demographics,getContext.demographicsDatas.bind(this))}
                                </View>
                                <View>
                                    <CommonHistorySectionHeader
                                        heading1={"Appointment Date"}
                                        columnSize={[1]}
                                        noOfColumn={1}
                                    />
                                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', paddingLeft: 15 }}>
                                        <View style={{ flex: 0.5 }}>
                                            <CommonDatePicker label={"From"} selectedDate={demographics.AppointmentFromDate} datePickerKey={"AppointmentFromDate"} callBackValue={this.getDatePickerDate.bind(this, getContext)} />
                                        </View>
                                        <View style={{ flex: 0.5 }}>
                                        <CommonDatePicker label={"To"} selectedDate={demographics.AppointmentToDate} datePickerKey={"AppointmentToDate"} callBackValue={this.getDatePickerDate.bind(this, getContext)} />
                                        </View>
                                    </View>
                                </View>
                                <View>
                                    {this.renderGender(demographics,getContext.demographicsDatas.bind(this))}
                                </View>

                                <View>
                                    {this.renderAddress(demographics,getContext.demographicsDatas.bind(this))}
                                </View>

                                <View style={{ zIndex: -1 }}>
                                    {this.renderFooterAction(getContext.demographicsDatas.bind(this))}
                                </View>

                            </View>
                        </>
                    )

                }

                }
            </RegistryContext.Consumer>
        );
    }
}
