import React, { Component } from "react";
import {
  View,
  Text,
  Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  Picker,
  TextInput,


} from "react-native";
import { DatePicker } from "antd";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import SideMenu from "../../../SideMenu";
import ReportTab from "../../common/Reporttab"
import DoctorVisitReport from './DoctorVisitReport'
import TestReport from './TestReport'
import ScanReport from './ScanReport'
import { ShowDocumentImage } from '../../../../ui/doctor/opthamology/ShowDocumentImage'
import Arrow from "react-native-vector-icons/FontAwesome5";
import PrintOrder from './PrintOrder'
import { Constants } from "../../../../utils/Constants";
import Success from "react-native-vector-icons/FontAwesome5";
import { CommonButton }  from '../../BaseComponent'
import CommonDateFilter from '../../BaseComponent'
import Service from "../../../../network/Service";
import { NavigationTopHeader } from "../../BaseComponent";
import AsyncStorage from "../../../../AsyncStorage";


const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;
var fulldate = new Date();
var converted_date = moment(fulldate).format("YYYY-MM-DD");
const navigationHeader = [
  { label: "Vitals", value: "oTVitals" },
  { label: "Reports", value: "reportsScreen" },
  { label: "Surgery Notes", value: "oTSurgeryNotes" },
  { label: "Billing", value: "oTBilling" }
]
export default class Reports extends Component {

  constructor(props) {
    super(props);


    this.state = {

      isIpPatientCheckouted:this.props.isIpPatientCheckouted,

      selectedScreen: this.props.selectedScreen,
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,

      userType: this.props.userType,
      selectedTab: props.reportsViewType == "printOrder" ? "isPrintOrder" : "isDoctorReport",
      // selectedTab: this.props.selectedSubScreen ?   this.props.selectedSubScreen :  "isPrintOrder",
      patient_id: this.props.patientAppointment.patient_id ? this.props.patientAppointment.patient_id : this.props.selectedIpPatientDetails ? this.props.selectedIpPatientDetails.patient_id : this.props.modificationBillDetails.patient_id,
      appointment_id: this.props.patientAppointment.appointment_id ? this.props.patientAppointment.appointment_id : this.props.selectedIpPatientDetails ? this.props.selectedIpPatientDetails.appointment_id  : this.props.modificationBillDetails.appointment_id,
      patientDetails: this.props.patientAppointment,
      reportsViewType: this.props.reportsViewType,
      selectedIpPatientDetails:props.selectedIpPatientDetails,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      modificationBillDetails: this.props.modificationBillDetails,
      responseType: "",
      responseMeaasge: "",
      selectedSearch: {},
      selectedClinic: this.props.selectedClinicOrDoctor,

      // userType:this.props.userType,

      // date start and End 
      startDate: "",
      endDate: "",
      printOrderDate: "",

      searchType: "",
      printOrderList: ["Prescription", "Labtest", "Investigation", "Glass Prescription"],
      filterDate: {
          fromDate:  "",
          toDate: ""
      },
      isClear: false,
      isclearInt: 0,
      selectedOrderType: "All",
      selectedDoctor: "",
      doctorSuggestionData: [],
      onChangeTab: false,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      otPatientDetails: this.props.PatientDetail,
      oTClinicId: '',
      isAdminAccess: false
    }

  };

 async componentDidMount() {
      var loggedInData = await (AsyncStorage.getItem("loggedInData"));
      var Selected_ClinicIDFor_Doctor = await (AsyncStorage.getItem("Selected_ClinicIDFor_Doctor"));
      var OTclinicID = await(AsyncStorage.getItem("Ot_Clinic_Id"));
      var loggedInDataObj = JSON.parse(loggedInData)
      this.setState({
        isAdminAccess: loggedInDataObj.is_admin_access,
        oTClinicId: OTclinicID,
        doctor_id: Selected_ClinicIDFor_Doctor
      })
    this.getDoctor()
  }
  getDoctor = () => {
    var serviceUrl = ""
    if(this.state.userType == "OT"){
      serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.oTClinicId
    } else if(this.state.userType == Constants.ROLE_OPTOMETRY){
      serviceUrl = Constants.HOME_GET_OPTOMETRY_DOCTORS
    }else{
      serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.doctor_id
    }

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorSuccess,
      this.getDoctorFailure
    );
};

getDoctorSuccess = (response) => {
    if(response.status == "success"){
        this.setState({
            doctorSuggestionData : response.data
        })
    }
}
getDoctorFailure = (error) => {

}
  successAlert() {
    return (
      <View style={styles.SuccessAlertMainView}>
        <View style={styles.SuccessAlrtContent}>
          <Success size={'1.62vw'} name={"check"} color={"green"} />
          <Text style={styles.SuccessAlertText1}>{"SUCCESS: "}
            <Text style={styles.SuccessAlrtText2}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertCloseIcon}>
          <Icon size={'1.62vw'} name={"close"} color={"green"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {

    let errorList = (this.state?.responseMeaasge)?.split(",")
    return (
      <View style={styles.ErrorAlertMainView}>
        <View style={styles.ErrorAlrtContent}>
          <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: '0.65vw' }}>
            <Icon size={'1.62vw'} name={"ban"} color={"#FFF"} /></View>
          <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
            <View>
              <Text style={{ fontWeight: "700", color: "#FFF", fontSize: '0.8vw' }}>{"ERROR: "}
                {errorList && errorList?.map((item, index) => {
                  return <View key={index}><Text style={styles.ErrorText}>{item}</Text></View>
                })}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertCloseIcon}>
          <Icon size={'1.62vw'} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }

  showResposeValue(type, meaasge) {
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }



  componentWillReceiveProps(props) {
    // if(props && props.selectedSubScreen && props.selectedSubScreen !== this.state.selectedTab){

    //   this.setState({
    //     selectedTab:props.selectedSubScreen
    //   })
    // }

    // console.log(">>>>>>>>>>>>>>>>>"+JSON.stringify(props))

    if (props && props.reportsViewType && props.reportsViewType !== this.state.reportsViewType) {

      this.setState({
        reportsViewType: props.reportsViewType,
        selectedTab: props.reportsViewType == "printOrder" ? "isPrintOrder" : "isDoctorReport"
      })
    }

  }

  clearSearchType(type) {
    this.setState({
      searchType: type
    })
  }

  renderComponent() {

    var selectedComponent = this.state.selectedTab
  
    var component = {
      isDoctorReport: (<DoctorVisitReport
        startDate={this.state.filterDate.fromDate}
        searchType={this.state.searchType}
        endDate={this.state.filterDate.toDate}
        clearSearchType={this.clearSearchType.bind(this)}
        showResposeValue={this.showResposeValue.bind(this)}
        userType={this.props.userType}
        patientDetails={this.props.patientAppointment}
        patient_id={this.state.patient_id}
        appointment_id={this.state.appointment_id}
        selectedIpPatientDetails={this.state.selectedIpPatientDetails}
        isCurrentPageIsIP={this.state.isCurrentPageIsIP}
        modificationBillDetails = {this.state.modificationBillDetails}
        selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor}
        changeComponent={this.changeComponent.bind(this)}
        selectedDoctor = {this.state.selectedDoctor}
        otPatientDetails = {this.state.otPatientDetails}
         />),
      isTesteport: (<TestReport
        isIpPatientCheckouted={this.state.isIpPatientCheckouted}
        clearSearchType={this.clearSearchType.bind(this)}
        startDate={this.state.startDate}
        patient_id={this.state.patient_id}
        appointment_id={this.state.appointment_id}
        searchType={this.state.searchType}
        endDate={this.state.endDate}
        selectedIpPatientDetails={this.state.selectedIpPatientDetails}
        isCurrentPageIsIP={this.state.isCurrentPageIsIP}
        selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor}
        showResposeValue={this.showResposeValue.bind(this)} patientDetails={this.props.patientAppointment} userType={this.props.userType} patient_id={this.state.patient_id} appointment_id={this.state.appointment_id} showDocumentImage={this.showImage.bind(this)} modificationBillDetails = {this.state.modificationBillDetails}
        otPatientDetails = {this.state.otPatientDetails}
        />),
      isScanReport: (<ScanReport
          isIpPatientCheckouted={this.state.isIpPatientCheckouted}
          startDate={this.state.startDate}
          searchType={this.state.searchType}
          endDate={this.state.endDate}
          selectedIpPatientDetails={this.state.selectedIpPatientDetails}
          isCurrentPageIsIP={this.state.isCurrentPageIsIP}
          clearSearchType={this.clearSearchType.bind(this)}
          // selectedIpPatientDetails={this.state.selectedIpPatientDetails}
          // isCurrentPageIsIP={this.state.isCurrentPageIsIP}
          showResposeValue={this.showResposeValue.bind(this)} userType={this.props.userType} changeComponent={this.changeComponent.bind(this)} patient_id={this.state.patient_id} appointment_id={this.state.appointment_id} modificationBillDetails = {this.state.modificationBillDetails}
          otPatientDetails = {this.state.otPatientDetails} 
          />),
      isPrintOrder: (<PrintOrder
        startDate={this.state.filterDate.fromDate}
        endDate={this.state.filterDate.toDate}
        searchType={this.state.searchType}
        clearSearchType={this.clearSearchType.bind(this)}
        selectedOrderType = {this.state.selectedOrderType}
        selectedClinic = { this.state.selectedClinic }

        showResposeValue={this.showResposeValue.bind(this)} patientDetails={this.props.patientAppointment} patient_id={this.state.patient_id} appointment_id={this.state.appointment_id} modificationBillDetails = {this.state.modificationBillDetails} userType={this.props.userType}
        otPatientDetails = {this.state.otPatientDetails}
        />),
    }
    return (
      <View>
        {component[selectedComponent]}
      </View>
    );
  }

  changeComponent(screenName, item={}, userType="", selectedClinicOrDoctor= "", report_id="") {
    this.props.changeScreen(screenName, item, userType, selectedClinicOrDoctor,"","","","",report_id)
  }

  onPressButton(key, selectedvalue) {
    if (key == "Search") {
      this.setState({
        searchType: this.state.selectedTab,
        isClear: false,
        isClearInt: 0
      })
    } else if (key == "clear") {
      var states = this.state.filterDate
      states['fromDate'] = "";
      states['toDate'] = "";
      this.setState({
        states,
        startDate: "",
        endDate: "", 
        selectedOrderType: "All",
        searchType: this.state.selectedTab,
        selectedDoctor: "",
        isClear: true,
      },()=>{
        this.setState({
          isClearInt: 1,
        },()=>{
          {this.renderCommonDateFilter()}
        })
      })
    }else if (key == "ultra") {
      this.props.changeScreen("ultraSoundAbdomenReport")
    } else if (key == "obstetricsonography") {
      this.props.changeScreen("obstetricSonographyReport");
    } 

  }

  changeTabView(key) {
    var filterDate = this.state.filterDate
    filterDate['fromDate']  = "";
    filterDate['toDate']  = "";
    this.setState({
      selectedTab: key,
      startDate: "",
      endDate: "",
      selectedOrderType: "All",
      selectedDoctor: "",
      filterDate,
      onChangeTab: true,
    },() => {
      this.props.onChangeReportTab(this.state.onChangeTab)
    })
  }


  renderHeaderView = () => {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View>

            <Arrow
              name="arrow-left"
              size={20}
              color={color.black}
              onPress={() => {
                this.props.changeScreen("optDoctorNotes");
              }}
            />
          </View>
          <View style={{ marginLeft: 20 }}>
            <Text style={{ fontSize: 16 }}>{this.state.patientInfo.name || ""}

              <Text
                style={{
                  fontSize: 10,
                  color: "#ADB4C1",
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                  alignSelf: "center"
                }}
              >
                {this.state.patientInfo.dob ? this.state.patientInfo.dob || "" : ""}
                {this.state.patientInfo.dob ? "  |  " : ''}
                {this.state.patientAppointment.appointment_date ? this.state.patientAppointment.appointment_date || "" : ""}
                {this.state.patientAppointment.appointment_date ? "  |  " : ''}
                {this.state.patientAppointment.appointment_start_time ? this.state.patientAppointment.appointment_start_time || "" : ""}
                {this.state.patientAppointment.appointment_start_time ? "  |  " : ''}
                {this.state.patientInfo.mobile_number ? this.state.patientInfo.mobile_number || "" : ""}


              </Text>

            </Text>

          </View>
        </View>
      </View>
    );
  };


  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var {filterData} = this.state.filterDate;
    states["filterDate"] = date
    states["isClear"] = false
    states["isClearInt"] = 0
    this.setState({ states,filterData })
  }
  renderCommonDateFilter = () => {
    return(
      <View>
           {this.state.isClear && this.state.isClearInt === 0 ? null :
                     this.state.isClear && this.state.isClearInt === 1 ?
                    <CommonDateFilter
                      defaultSelectedDateFilter={"All"}
                      // removeObject={""} 
                      startDate={this.state.filterDate.fromDate}
                      endDate={this.state.filterDate.toDate}
                      clearPress={this.state.isclear}
                      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                      filterDate={this.state.filterDate} /> : 
                    <CommonDateFilter
                      defaultSelectedDateFilter={"All"}
                      // removeObject={""} 
                      startDate={this.state.filterDate.fromDate}
                      endDate={this.state.filterDate.toDate}
                      clearPress={this.state.isclear}
                      showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                      filterDate={this.state.filterDate} />
                   }
      </View>
    )
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  render() {
    return (

      <View style={styles.MainView}>
        {
          this.state.responseType == "success" ?
            this.successAlert() :
            this.state.responseType == "error" ?
              this.errorAlert() : null
        }
        <View style={styles.MainWrapperView}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.MainScrollView} bounces={false} bouncesZoom={false} >
            {/*  */}
           { this.state.userType == "OT" ?
            <NavigationTopHeader
             changeTabSection={this.changeTabSection.bind(this)}
             navigationHeaderList={navigationHeader}
             selectedTab={"reportsScreen"}
             isNavigationBorder={true}
            /> : null
           }
            {/* <View>
              <Text style={{ color: color.themeDark, fontSize: 19, fontWeight: '700', marginLeft: 30, marginTop: 20 }}>
                {
                  this.state.reportsViewType == "reportsScreen" && this.state.userType != "OT" ?
                    "Reports" :
                       null
                } </Text>
            </View> */}

            {
              this.state.reportsViewType == "reportsScreen" ?
                <View style={styles.TabView}>
                  <TouchableOpacity onPress={() => {
                    this.changeTabView("isDoctorReport")
                  }} style={[styles.TabItem, this.state.selectedTab == "isDoctorReport" ? { borderBottomColor: color.themeDark, borderBottomWidth: '0.26vw' } : null]}>
                    <Text style={styles.TabText}>{"Doctor Visit Report"}</Text>
                  </TouchableOpacity>
                  {this.state.userType === Constants.ROLE_OPTOMETRY ? null :
                  <TouchableOpacity onPress={() => {
                    this.changeTabView("isTesteport")
                  }}  style={[styles.TabItem, this.state.selectedTab == "isTesteport" ? { borderBottomColor: color.themeDark, borderBottomWidth: '0.26vw' } : null]}>
                    <Text style={styles.TabText}>{"Test Report"}</Text>
                  </TouchableOpacity> }
                  {(this.state.userType === Constants.ROLE_FO || this.state.userType === "OT" && this.state.userType != Constants.ROLE_OPTOMETRY)?
                    <TouchableOpacity onPress={() => {
                      this.changeTabView("isScanReport")
                    }} style={[styles.TabItem, this.state.selectedTab == "isScanReport" ? { borderBottomColor: color.themeDark, borderBottomWidth: '0.26vw' } : null]}>
                      <Text style={styles.TabText}>{"Scan Report"}</Text>
                    </TouchableOpacity>
                    : null}
                  { this.state.userType != "OT" && this.state.userType != Constants.ROLE_OPTOMETRY ?  
                  <TouchableOpacity onPress={() => {
                      this.changeTabView("isPrintOrder")
                    }} style={[styles.TabItem, this.state.selectedTab == "isPrintOrder" ? { borderBottomColor: color.themeDark, borderBottomWidth: '0.26vw' } : null]}>
                      <Text style={styles.TabText}>{"Print Order"}</Text>
                    </TouchableOpacity>: null
            }
                </View> : null

            }


            <View>
              {this.renderComponent()}
            </View>
          </ScrollView>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}
              style={styles.RightScrollView} >
              <View style={styles.RightMainView}>
                {/*  */}
                <View>
                  <Text style={styles.RightSideHeaderText}>
                    {
                      this.state.selectedTab == "isDoctorReport" ? "Doctor Visit Report" :
                        this.state.selectedTab == "isTesteport" ? "Test Report" :
                          this.state.selectedTab == "isScanReport" ? "Scan Report" :
                          this.state.selectedTab == "isPrintOrder" ? "Print Orders" :

                            null

                    }
                  </Text>
                </View>
            <View style={{ height: '50vh' }}>
              {this.state.selectedTab === "isPrintOrder" || this.state.selectedTab === "isDoctorReport" ?
                <View style={{  flexDirection: "column", flex: 1,}}>
                  <View style={styles.HightLightView}>
                    <Text style={styles.HighLightText}> Filter</Text>
                  </View>
                  <View style={styles.DateFilterView}>
                   <View style={{ flex: 0.55 }}> 
                     {this.renderCommonDateFilter()}
                   </View>
                   { this.state.selectedTab === "isPrintOrder" ?
                    <Picker
                      selectedValue={this.state.selectedOrderType}
                      disabled={false}
                      style={styles.PickerStyle}
                      itemStyle={{ fontSize: '0.8vw' }}
                      onValueChange={(itemvalue) => {

                        this.setState({
                          selectedOrderType: itemvalue
                        })
                      }}
                      enabled={true}
                    >
                        <Picker.Item label={"Select order type"} value={"All"} />
                      {this.state.printOrderList.map((i, index) => (
                        <Picker.Item key={index} label={i} value={i} />
                      ))}
                    </Picker> : null
                  }
                   { this.state.selectedTab === "isDoctorReport" ?
                    <Picker
                      selectedValue={this.state.selectedDoctor}
                      disabled={false}
                      style={styles.PickerStyle}
                      itemStyle={{ fontSize: '0.8vw' }}
                      onValueChange={(itemvalue) => {

                        this.setState({
                          selectedDoctor: itemvalue
                        })
                      }}
                      enabled={true}
                    >
                        <Picker.Item label={"All"} value={""} />
                      {this.state.doctorSuggestionData.map((i, index) => (
                        <Picker.Item key={index} label={i.first_name} value={i.doctor_id} />
                      ))}
                    </Picker> : null
                  }
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: "center", flex: 0.45 , zIndex:-1}}>
                      <CommonButton
                        item={{ label: "Search", value: "Search" }}
                        selectedvalue={{ label: "Search", value: "Search" }}
                        butttonText={"Search"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"Search"} />
                    <View style={{ marginLeft: '1.3vw' }}>
                      <CommonButton
                        item={{ label: "Clear", value: "Clear" }}
                        selectedvalue={{ label: "Clear", value: "Clear" }}
                        butttonText={"Clear"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"clear"} />
                    </View>
                    {/* <View style={{ marginLeft: 20 }}>
                      <CommonButton
                        item={{ label: "Print", value: "Print" }}
                        selectedvalue={{ label: "Print", value: "Print" }}
                        butttonText={"Print"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"print"} />
                    </View> */}
                  </View>
                
                </View> : 
                <View>
                <View style={{ paddingBottom: '1.3vw', flexDirection: 'column' }}>
                  <View style={styles.HightLightView}>
                    <Text style={styles.HighLightText}> Filter</Text>

                  </View>

                </View>
                <View style={[styles.HightLightView, { width: "20%"}]}>
                  <Text style={styles.HighLightText}> Date</Text>

                </View>
                <View style={{ flexDirection: 'row', marginLeft: '0.65vw' }}>
                  <View style={{ width: '50%', padding: '1.3vw' }}>
                    <Text
                      style={styles.DatePickerLable}
                    >
                      {"From"}
                    </Text>

                    <DatePicker
                      disabledDate={current => {
                        return current && current > moment().add(0, "days");
                      }}
                      clearIcon={false}
                      onChange={(date) => {
                        // alert(date)
                        this.setState({ startDate: date });
                      }}
                      defaultValue={""}
                      placeholder={""}
                      format="DD/MM/YYYY"
                      value={this.state.startDate?moment(this.state.startDate,"DD-MM-YYYY"):null}
                      style={{
                        zIndex: -1,
                        width: "85%",
                        borderColor: "#CDD1D5",
                        height: '2.27vw',
                        borderRadius: '0.65vw',
                        borderWidth: 1,
                        marginTop: '0.32vw',
                        fontSize:'1vw'
                      }}
                    />
                  </View>


                  <View style={{ width: '50%', padding: '1.3vw' }}>
                    <Text
                      style={styles.DatePickerLable}
                    >
                      {"To"}
                    </Text>

                    <DatePicker
                      disabledDate={current => {
                        return current && current > moment().add(0, "days");
                      }}
                      clearIcon={false}
                      onChange={(date) => {
                        // alert(date)
                        this.setState({ endDate: date });
                      }}
                      defaultValue={""}
                      placeholder={""}
                      format="DD/MM/YYYY"
                      value={this.state.endDate?moment(this.state.endDate,"DD-MM-YYYY"):null}
                      style={{
                        zIndex: -1,
                        width: "85%",
                        borderColor: "#CDD1D5",
                        height: '2.27vw',
                        borderRadius: '0.65vw',
                        borderWidth: 1,
                        marginTop: '0.32vw',
                        fontSize:'1vw'
                      }}
                    />
                  </View>
                </View>
                <View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>

                    <View >

                      <CommonButton
                        item={{ label: "Search", value: "Search" }}
                        selectedvalue={{ label: "Search", value: "Search" }}
                        butttonText={"Search"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"Search"} />
                    </View>
                    <View style={{ marginLeft: '1.3vw' }}>
                      <CommonButton
                        item={{ label: "Clear", value: "Clear" }}
                        selectedvalue={{ label: "Clear", value: "Clear" }}
                        butttonText={"Clear"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"clear"} />
                    </View></View>
                 </View>

                 {this.state.selectedTab == "isScanReport" ?
                  <View style={{ paddingTop: '1.3vw', flexDirection: 'column' }}>
                    <View style={styles.HightLightView}>
                      <Text style={styles.HighLightText}> Create Forms</Text>

                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <View style={styles.ButtonView} >
                        <CommonButton
                          disable={this.state.isIpPatientCheckouted || !this.state.appointment_id ? true :false}
                          item={{ label: "Search", value: "Search" }}
                          selectedvalue={{}}
                          butttonText={"Ultra sound Abdomen Report"}
                          buttonType={"outlineNonTheme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={"ultra"} />
                      </View>

                      <View style={styles.ButtonView} >
                        <CommonButton
                          disable={this.state.isIpPatientCheckouted || !this.state.appointment_id ? true :false }
                          item={{ label: "Search", value: "Search" }}
                          selectedvalue={{}}
                          butttonText={"Obstetric Sonography Report"}
                          buttonType={"outlineNonTheme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={"obstetricsonography"} />
                      </View>
                    </View>
                  </View>
                  : null}
               </View>}
               </View> 
              </View>
            </ScrollView>
      </View>

    );
  }

  showImage(flag, image = "", listImage) {
    this.props.showDocumentImage(flag, image, listImage)
  }

}

const styles = StyleSheet.create({
  MainView:{
    flex: 1, 
    flexDirection: "row", 
    backgroundColor: color.applicationBackgroundColor
  },
  MainWrapperView:{
    width: "65%", 
    height: '90vh', 
    backgroundColor: color.applicationBackgroundColor
  },
  ReportsHeading:{
    color: color.themeDark, 
    fontSize: '1.23vw', 
    fontWeight: '700', 
    marginLeft: '1.95vw', 
    marginTop: '1.3vw'
  },
  MainScrollView:{
    width: "100%", 
    height: '90vh', 
    backgroundColor: color.white, 
    zIndex: -1
  },
  TabView:{
    flexDirection: "row", 
    width: "65%", 
    borderBottomColor: "#888888", 
    marginLeft: '1.95vw'
  },
  TabItem:{
    paddingHorizontal: "3.25vw", 
    paddingVertical: '1.3vw'
  },
  TabText:{
    fontSize: '1vw', 
    fontWeight: "500"
  },
  RightScrollView:{
    width: "35%",
    height: '90vh', 
    backgroundColor: color.themeShade
  },
  RightMainView:{
    height: '80vh', 
    padding: '1.3vw', 
    width: "100%"
  },
  RightSideHeaderText:{
    color: color.themeDark, 
    fontSize: '1.23vw', 
    fontWeight: '700', 
    marginLeft: '1.95vw', 
    marginTop: "2.7vw",
    marginBottom:'1.5vw'
  },
  PickerStyle:{
    height: "2.27vw",
    marginLeft: '0.65vw', 
    margin: '0.32vw', 
    backgroundColor: "transparent", 
    borderRadius: '0.26vw', 
    flex: 0.5, 
    borderColor: "#CDD1D5",
    width:'5vw',
    fontSize:'1vw'
  },
  DatePickerLable:{
    position: "absolute",
    marginRight: '14.97vw',
    backgroundColor: "white",
    fontSize: '0.8vw',
    paddingLeft: '0.32vw',
    paddingRight: '0.32vw',
    color: "#888888",
  },
  HightLightView:{
    backgroundColor: color.sectionHeadingLiteColor, 
    alignContent: 'flex-start', 
    borderRadius:'0.4vw'   
  },
  HighLightText:{
    marginLeft: '1.3vw', 
    margin: '0.5vw', 
    fontSize:'1vw', 
    fontWeight:'500'
  },
  DateFilterView:{
    flexDirection: "row", 
    flex: 0.4, 
    padding: '1.3vw'
  },
  ButtonView:{
    marginTop: '1.3vw', 
    marginBottom: '0.65vw', 
    marginRight: '0.65vw'
  },
  SuccessAlertMainView:{
    flex: 1, 
    zIndex: 3, 
    width: "40%", 
    position: 'absolute', 
    left: '0.65vw', 
    bottom: '0.32vw', 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection: "row", 
    backgroundColor: "#DEF2D6", 
    borderRadius: '0.32vw', 
    borderColor: "#B8C7B2", 
    borderWidth: 1, 
    height: '4.55vw', 
    marginRight: '0.65vw', 
    marginTop: '0.65vw'
  },
  SuccessAlrtContent:{
    flexDirection: 'row', 
    alignItems: 'center', 
    marginLeft: '0.65vw', 
    flex: 0.95
  },
  SuccessAlertText1:{
    fontWeight: "700", 
    color: "green", 
    fontSize: '0.8vw', 
    marginLeft: 15
  },
  SuccessAlrtText2:{
    fontWeight: "400", 
    color: "green", 
    fontSize: '0.8vw'
  },
  ErrorAlertMainView:{
    flex: 1, 
    zIndex: 3, 
    width: "35%", 
    position: 'absolute', 
    left: '0.65vw', 
    bottom: '0.32vw', 
    right: 0, 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    flexDirection: "row", 
    backgroundColor: "#CD3A34",
     borderRadius: '0.32vw', 
     borderColor: "#A58B8A", 
     borderWidth: 1, 
     minHeight: '4.55vw', 
     marginRight: '0.65vw', 
     marginTop: '0.65vw'
  },
  ErrorAlrtContent:{
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: '0.97vw', 
    marginLeft: '0.65vw', 
    flex: 0.95 
  },
  ErrorText:{
    fontWeight: "400", 
    color: "#FFF", 
    fontSize: '0.8vw', 
    marginBottom: '0.52vw'
  },
  AlertCloseIcon:{
    flex: 0.05, 
    marginRight: '1vw', 
    alignItems: 'center', 
    alignContent: 'center', 
    alignSelf: 'center', 
    justifyContent: "center"
  }
})