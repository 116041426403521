import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "HistoryOfAbstinenceAndDeAddictionTreatment"


export class HistoryOfAbstinenceAndDeAddictionTreatmentNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            nameofTreatmentDetails: "",
            durationOfAdstienceDetails: "",
            reasonForRelapsesDetails: "",
            additionalNotesDetails: ""

        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["nameofTreatmentDetails"] = data.nature_treatment;
                states["durationOfAdstienceDetails"] = data.duration_abstinence;

                states["reasonForRelapsesDetails"] = data.reason_relapses;
                states["additionalNotesDetails"] = data.additional_notes;

                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["nameofTreatmentDetails"] = "";
        states["durationOfAdstienceDetails"] = "";
        states["reasonForRelapsesDetails"] = "";
        states["additionalNotesDetails"] = "";
        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "nature_treatment": states.nameofTreatmentDetails,
                "duration_abstinence": states.durationOfAdstienceDetails,
                "reason_relapses": states.reasonForRelapsesDetails,
                "additional_notes": states.additionalNotesDetails,
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_ABSTINENCE_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveHistoryOfAbstinenceAndDeAddictionTreatmentSuccess,
                this.saveHistoryOfAbstinenceAndDeAddictionTreatmentFailure
            );
        }
        else {
            this.props.showResposeValue("error", "History of abstinence already added please click on edit icon to update the values")
        }
    }

    saveHistoryOfAbstinenceAndDeAddictionTreatmentSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)

        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveHistoryOfAbstinenceAndDeAddictionTreatmentFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"History of Abstinence & De-Addiction Treatment"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Name of the Treatment", "Enter details",
                    "", false,
                    "nameofTreatmentDetails", this.state.nameofTreatmentDetails
                )}

                {this.renderHistoryComponent(
                    "Duration of Abstinence", "Enter details",
                    "", false,
                    "durationOfAdstienceDetails", this.state.durationOfAdstienceDetails
                )}

                {this.renderHistoryComponent(
                    "Reason for relapses", "Enter details",
                    "", false,
                    "reasonForRelapsesDetails", this.state.reasonForRelapsesDetails
                )}

                {this.renderHistoryComponent(
                    "Additional Notes", "Enter details",
                    "", false,
                    "additionalNotesDetails", this.state.additionalNotesDetails
                )}


                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
