import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationTopHeader } from '../BaseComponent';
const navigationHeader = [
  { label: "Statistics", value: "superAdminStatistics" },
  { label: "Billing Transaction", value: "superAdminBillingTransaction" }

]
export default class BillingTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"superAdminBillingTransaction"}
          isNavigationBorder={true}
        />
        <Text> BillingTransaction </Text>
      </View>
    );
  }
}
