import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { OvulationChartSectionHeading, CommonPrintButton,CommonSectionHeader,CommonHistorySectionHeader } from "../../BaseComponent";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";
import { color } from "../../../../styles/Color";
import BaseComponentStyle from "../../BaseComponentStyle";
import Icon from "react-native-vector-icons/FontAwesome";
import {NavigationTopHeader} from "../../BaseComponent"
// const pageName="isOvulationChart"

const pageName = "ovulationChart";
const isPageName = "isOvulationChart";


export class OvulationChart extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      getDataByDateValues: {},
      isLocked: this.props.patientAppointment.is_locked,
      selectedPageName: this.props.selectedPageName,
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      lastMenstrualDate: "",
      therapy: "",
      procedure: "",
      OvulationData: {},
      nilValue: "-",
      hideRemoveIcon: false
    };
  }

  componentDidMount() {
    this.getOvulation();
  }
  visitReport = () => {

    let states = this.state;

    var serviceUrl =
      Constants.GET_OVULATION_VISITREPORT + "?patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );
  };

  getPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
    //  console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
   // console.log("Get clinic list error response");
    console.log(error);
  };

  getOvulation() {
    var service_url =
      Constants.GET_OVULATION +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getOvulationSuccess,
      this.getOvulationFailure
    );
  }

  getOvulationFailure = (error) => { };
  getOvulationSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["OvulationData"] = response.data;
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  componentWillReceiveProps(props) {
    this.state.hideRemoveIcon = props.hideRemoveIcon
    this.state.selectedPageName = props.selectedPageName;
    if (props && Object.keys(props.getUpdatedChartValue).length > 0) {
      this.setState({
        OvulationData: props.getUpdatedChartValue,
      });
      this.props.getUpdatedChartValueChange({},false);
    }

    if (props.refreshRighSideComponentName == isPageName) {
      this.getOvulation();
      this.threeHeading();
      this.props.refreshRighSideComponent("");
    }
  }

  selectedRightSideView(name) {
    this.props.selectedRightSideView(name);
  }

  ovulationChart() {
    return (
      <View>
        {this.ovulationChartHeader()}
        {this.ovulationChartList()}
      </View>
    );
  }

  deleteItem = (item) => {
    // alert(JSON.stringify(item))
    //this.props.editItem(pageName, item);
    var service_url = Constants.DELETE_OVULATION;
    let data = {
      ovulation_induction_id: item.id,
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  getDeleteSuccess = (response) => {
    if (response.status === "success") {
      this.getOvulation();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  renderRichTextBox(label, values) {
    return (
      <View>
        <Text style={{ fontSize: 15, fontWeight: "500" }}>{label}</Text>
        <TextInput
          disabled={true}
          value={values}
          placeholder={""}
          multiline={true}
          textAlignVertical="top"
          style={{
            marginTop: 5,
            marginBottom: 20,
            textAlignVertical: "top",
            paddingLeft: 10,
            backgroundColor: color.white,
            borderRadius: 8,
            borderColor: color.lightGray,
            borderWidth: 1,
            height: 65,
          }}
        />
      </View>
    );
  }

  fourPhase() {
    // return this.state.OvulationData &&
    //   this.state.OvulationData.ovulation_induction_header ? (
    //     this.state.OvulationData.ovulation_induction_header.map((item) => {
    return (
      <View
        style={{
          padding: 30,
          marginTop: 30,
          borderRadius: 4,
          borderColor: color.lightGray,
          borderWidth: 1,
        }}
      >
        {/* <View style={{alignItems: 'flex-end'}}>
                {this.state.OvulationData.ovulation_induction_values ?
                this.state.OvulationData.ovulation_induction_values.map((item)=> {
                    return(
                
                <TouchableOpacity disabled={this.state.isLocked} onPress={()=>this.editHistory( this.state.OvulationData.ovulation_induction_header,"",item.date_of_visit

                )}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>        
                    )
                }) : "" }
                </View> */}
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
          <CommonSectionHeader heading={"Follicular Phase"}  />
          <View style={{marginRight:10}}>
            {this.renderRichTextBox(
              "",
              this.state.OvulationData &&
                this.state.OvulationData.ovulation_induction_header ? this.state.OvulationData.ovulation_induction_header.follicular_phase : ''
            )}</View>
          </View>

          <View style={{ width: "50%" }}>
          <CommonSectionHeader heading={"Ovulatory Phase"}  />
            {this.renderRichTextBox(
              "",
              this.state.OvulationData &&
                this.state.OvulationData.ovulation_induction_header ? this.state.OvulationData.ovulation_induction_header.ovulatory_phase : ''
            )}
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
          <CommonSectionHeader heading={"Luteal Phase"}  />
          <View style={{marginRight:10}}>
            {this.renderRichTextBox(
              "",
              this.state.OvulationData &&
                this.state.OvulationData.ovulation_induction_header ? this.state.OvulationData.ovulation_induction_header.luteal_phase : ''
            )}</View>
          </View>

          <View style={{ width: "51.5%" }}>
          <CommonSectionHeader heading={"Comments"}  />
          <View style={{marginRight:10}}>
            {this.renderRichTextBox(
              "",
              this.state.OvulationData &&
                this.state.OvulationData.ovulation_induction_header ? this.state.OvulationData.ovulation_induction_header.comments : ''
            )}</View>
          </View>
        </View>
      </View>
    )
    // ) : //     )
    // // })
    // null;
  }

  ovulationChartHeader() {
    return (
      <View
        style={{
          flexDirection: "row",
          height: 50,
          borderWidth: 0.5,
          borderColor: "#888888",
          alignContent: "center",
          backgroundColor:'#F0F0F0',
          color:"black"
          
        }}
      >
        <View style={[styles.headerStyle, { flex: 0.1 }]}>
          {this.tabelHeader("Date")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.11 }]}>
          {this.tabelHeader("Days")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.19 }]}>
          {this.tabelHeader("Right Ovary")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.19 }]}>
          {this.tabelHeader("Left Ovary")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.15 }]}>
          {this.tabelHeader("Endometrium")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.15 }]}>
          {this.tabelHeader("Pouch of Douglas")}
        </View>
        <View style={[styles.headerStyle, { flex: 0.13 }]}>
          {this.tabelHeader("Action")}
        </View>
      </View>
    );
  }

  ovulationChartList() {

    var today = new Date();
    var getTodayDate = moment(today).format("DD-MM-YYYY")


    return this.state.OvulationData &&
      this.state.OvulationData.ovulation_induction_values
      ? this.state.OvulationData.ovulation_induction_values.map((item) => {
        var getItemDate = moment(item.date_of_visit).format("DD-MM-YYYY");
        var hideDeleteButton = true
        if (getItemDate == getTodayDate) {

          hideDeleteButton = false
        }

        return (
          <View
            style={{
              flexDirection: "row",
              height: 50, // borderWidth: 0.5,
              borderLeftColor: "#888888",
              borderRightColor: "#888888",
              borderBottomColor: "#888888",
              borderLeftWidth: 0.5,
              borderRightWidth: 0.5,
              borderBottomWidth: 0.5,
              alignContent: "center",
            }}
          >
            <View style={[styles.headerStyle, { flex: 0.1 }]}>
              {this.tabelHeader(
                moment(item.date_of_visit).format("DD-MM-YYYY"),
                item
              )}
            </View>
            <View style={[styles.headerStyle, { flex: 0.11 }]}>
              {this.tabelHeader(item.day + " Days", item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.19 }]}>
              {this.tabelHeader(item.right_ovary, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.19 }]}>
              {this.tabelHeader(item.left_ovary, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.15 }]}>
              {this.tabelHeader(item.endometrium, item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.15 }]}>
              {this.tabelHeader(item.pouch_of_douglas ? item.pouch_of_douglas + " ml" : "", item)}
            </View>
            <View style={[styles.headerStyle, { flex: 0.13 }]}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginLeft: 10, width: "50%" }}>
                  <TouchableOpacity
                    disabled={this.state.isLocked}
                    onPress={() =>
                      this.editHistory(
                        item,
                        this.state.OvulationData.ovulation_induction_header,
                        this.state.OvulationData.ovulation_induction_header
                      )
                    }
                  >
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
                </View>
                {
                  this.state.hideRemoveIcon || hideDeleteButton ?


                    null :
                    <View style={{ width: "50%", marginRight: 10 }}>
                      <TouchableOpacity
                        disabled={this.state.isLocked}
                        onPress={() => {
                          this.deleteItem(item);
                        }}
                      >
                        {this.renderIdButton("trash")}
                      </TouchableOpacity>
                    </View>


                }

              </View>
            </View>
          </View>
        );
      })
      : null;
  }

  tabelHeader(title, black) {
    return (
      <View>
        <Text
          numberOfLines={10}
          style={{
            fontSize: 13,
            color: "#000000",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            textAlignVertical: "center",
            textAlign: "center",
          }}
        >
          {title}
        </Text>
      </View>
    );
  }

  threeHeading() {
    var converted_date = moment(
      this.state.OvulationData &&
        this.state.OvulationData.ovulation_induction_header
        ? this.state.OvulationData.ovulation_induction_header
          .last_menstrual_period
        : null
    ).format("DD-MM-YYYY");

    return (
      <View> 
          <View style={{marginTop:10}}>
            <CommonHistorySectionHeader
              heading1={"Last Menstrual Period"}
              heading2={"Therapy"}
              heading3={"Procedure"}
              columnSize={[0.25,0.40,0.35]}  //total value is == of 1
              noOfColumn={3}
            />
            </View>
        {this.renderThreeHeadingFields(
          "",
          this.state.OvulationData &&
            this.state.OvulationData.ovulation_induction_header
            ? converted_date
            : "",
          "",
          this.state.OvulationData &&
            this.state.OvulationData.ovulation_induction_header
            ? this.state.OvulationData.ovulation_induction_header.therapy
            : "",
          "",
          this.state.OvulationData &&
            this.state.OvulationData.ovulation_induction_header
            ? this.state.OvulationData.ovulation_induction_header.procedure
            : ""
        )}
      </View>
    );
  }

  renderThreeHeadingFields(
    lastMenstrualPeriodTitle,
    lastMenstrualPeriodValue,
    therapyTitle,
    therapyValue,
    procedureTitle,
    procedureValue
  ) {
    return (
      <View style={{ flexDirection: "row", marginTop: 10, marginBottom: 20, marginHorizontal:20 }}>
        <View style={{flex:0.25}}>
          {this.renderContent(
            lastMenstrualPeriodTitle,
            lastMenstrualPeriodValue
          )}
        </View>
        <View style={{flex:0.40}}>
          {this.renderContent(therapyTitle, therapyValue)}
        </View>
        <View style={{flex:0.35}}>
          {this.renderContent(procedureTitle, procedureValue)}
        </View>
      </View>
    );
  }

  renderContent(heading, value) {
    return (
      <View>
        <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>
          {heading}
        </Text>
        <Text style={{ fontSize: 14 }}>{value}</Text>
      </View>
    );
  }

  editHistory(item, lmd, dov) {

    this.props.editHistory(item, isPageName, lmd, dov);
  }

  render() {

    return (
     
  
      <ScrollView>
        <View style={styles.container}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "45%" }}>
              <OvulationChartSectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName}
                heading={"Ovulation Chart"}
              />
            </View>
            <View style={{ width: "45%", alignItems: "flex-end" ,marginRight:11}}>
              <TouchableOpacity
                onPress={() =>
                  this.setState({
                    OvulationData: {},
                  },()=>{
                     this.props.renderNewItemClear(true)
                  })
                }
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    flex: 0.2,
                    backgroundColor: color.themeDark,
                    borderRadius: 5,
                    minHeight: 30,
                    paddingHorizontal: 10,
                    paddingLeft: 30,
                    paddingRight: 30,

                  }}
                >
                  <Icon name="plus" size={16} color={color.white} />
                  <Text
                    style={{
                      fontSize: 11,
                      color: color.white,
                      fontWeight: "600",
                      marginLeft: 5,
                    }}
                  >
                    {"New Ovulation Chart"}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ width: "4%", alignItems: "flex-end", marginLeft: 25 }}>
              <CommonPrintButton onPress={() => { this.visitReport() }} /></View>
          </View>
          {this.threeHeading()}
          {this.ovulationChart()}
          {this.fourPhase()}
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    flex: 1,
  },
  headerStyle: {
    borderRightColor: "#888888",
    borderRightWidth: 0.5,
    justifyContent: "center",
  },
});
