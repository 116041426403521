import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Picker, StyleSheet } from "react-native";
import { Tooltip } from 'antd';
import { color } from "../../../../styles/Color";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import Pagination from '../../../doctor/common/Pagination';
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import { DatePicker } from 'antd';
import CommonDateFilter from '../../BaseComponent';


const PaymentTypeList = [
    { label: "Cash", value: "Cash" },
    { label: "Card", value: "Card" },
    { label: "UPI", value: "UPI" },
    { label: "Bank Transfer", value: "Bank Transfer" },
    { label: "Cheque", value: "Cheque" },
]
export default class ExpenseBilling extends Component {

    constructor(props) {
        super(props);

        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");

        this.state = {
            ExpenseBillList: [],
            ExpenseBillList2: [],
            ExpenseBillList3: [],
            ErrorText: "",
            ExpenseListAferPagination: [],

            filterDate: {},
            filterData: {
                fromDate: converted_date,
                toDate: converted_date
            },
            current_page: 1,
            records_per_page: 10,

            billingDate: moment(new Date()).format("YYYY-MM-DD"),
            ToInput: "",
            AmountInput: "",
            PaymentType: 'Cash',
            Description: "",
            BillNo: "",

            DateSort: '',
            AmountSort: '',
        };
    }
    componentDidMount() {
        this.getExpenseBillList()
    }

    getExpenseBillList = () => {
        var serviceUrl = Constants.EXPENSE_BILL_REPORT + "?from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate;
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getBillListSuccess,
            this.getBillListFailure
        );

    }

    getBillListSuccess = (response) => {
        this.setState({
            ExpenseBillList: response.data,
            ExpenseBillList2: response.data,
        })
    }
    getBillListFailure = (error) => {
        // this.props.ErrorAlt(error.message)
    }

    ExportData = () => {
        var serviceUrl = Constants.EXPENSE_BILL_REPORT + "?from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate + "&export=excel";
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.getExcelSuccess,
            this.getExcelFailure,
            "excel"
        );
    }

    getExcelSuccess = (success) => {
        if (success.statusText === "OK") {
            var pom = document.createElement('a');
            var csvContent = success.data; //here we load our csv data 

            let filename = success.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }
    getExcelFailure = (error) => {
        this.props.ErrorAlert("No Data Available !")
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    OnlyTextNumbersValidation(num) {
        return /^[A-Za-z0-9 ]*$/.test(num);
    }

    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterData } = this.state;
        states["filterData"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate
        this.setState({ states, filterData, ExpenseBillList: [] }, () => { this.getExpenseBillList() })
    }

    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    AddNewExpenseBill = () => {
        var states = this.state
        if (
            states.billingDate != "" &&
            states.ToInput != "" &&
            states.AmountInput != "" &&
            states.PaymentType != "" &&
            states.Description != ""
        ) {
            this.setState({
                ErrorText: ""
            })

            var service_url = Constants.EXPENSE_BILL_REPORT;
            var data = {
                "bill_date": states.billingDate,
                "name": states.ToInput,
                "amount": states.AmountInput,
                "pay_type": states.PaymentType,
                "desc": states.Description,
            }

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.AddBillSuccess,
                this.AddBillFailure
            );

        }
        else {
            this.setState({
                ErrorText: "Enter All Mandatory * Fields"
            })
        }

    }

    AddBillSuccess = (response) => {
        if (response.status == "success") {
            this.getExpenseBillList()
            this.resetClicked()
            this.props.SuccessAlt(response.message)
        }
        else {
            this.props.ErrorAlert(response.message)
        }
    }
    AddBillFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }


    resetClicked = () => {
        this.setState({
            billingDate: moment(new Date()).format("YYYY-MM-DD"),
            ToInput: "",
            AmountInput: "",
            PaymentType: "Cash",
            Description: "",
            BillNo: "",
        })
    }

    paginationChangedValues(data) {
        this.setState({
            ExpenseListAferPagination: data
        })
    }

    returnSortIcon = (order) => {
        if (order == "") {
            return <Icon name="sort" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "asc") {
            return <Icon name="sort-up" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "desc") {
            return <Icon name="sort-down" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
    }

    renderSorting = (key, order, ApiData) => {
        if (order == "asc") {
            const Sorted = ApiData.sort((a, b) => {
                if (typeof (a[key]) == "string") {
                    if (key == "amount") {
                        return parseInt(a[key]) > parseInt(b[key]) ? 1 : -1
                    }
                    else {
                        return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1
                    }
                }
                else if (typeof (a[key]) == "number") {
                    return a[key] > b[key] ? 1 : -1
                }
            })
            this.setState({
                ExpenseBillList: Sorted
            })
        }
        else if (order == "desc") {
            const Sorted = ApiData.sort((a, b) => {
                if (typeof (a[key]) == "string") {
                    if (key == "amount") {
                        return parseInt(a[key]) < parseInt(b[key]) ? 1 : -1
                    }
                    else {
                        return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1
                    }
                }
                else if (typeof (a[key]) == "number") {
                    return a[key] < b[key] ? 1 : -1
                }
            })
            this.setState({
                ExpenseBillList: Sorted
            })
        }
        else if (order == "") {
            this.setState({
                ExpenseBillList: []
            }, () => { this.getExpenseBillList() })
        }
    }


    renderCorporateLeft = () => {
        return (
            <View>
                <View style={styles.SearchBoxView}>
                    <CommonDateFilter
                        defaultSelectedDateFilter={"Today"}
                        startDate={this.state.filterDate.fromDate}
                        endDate={this.state.filterDate.toDate}
                        clearPress={this.state.isclear}
                        showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                        filterDate={this.state.filterDate}
                        futureDateDisable={true} />

                    <TouchableOpacity
                        onPress={() => { this.ExportData() }}
                        style={styles.ExportBtnTouch}>
                        <Text style={styles.ButtonText}>{"Export"}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (this.state.DateSort == "") {
                                            this.setState({
                                                DateSort: "asc",
                                                AmountSort: "",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("bill_date", "asc", this.state.ExpenseBillList2) })
                                        }
                                        else if (this.state.DateSort == "asc") {
                                            this.setState({
                                                DateSort: "desc",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("bill_date", "desc", this.state.ExpenseBillList2) })
                                        }
                                        else if (this.state.DateSort == "desc") {
                                            this.setState({
                                                DateSort: "",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("bill_date", "", this.state.ExpenseBillList2) })
                                        }
                                    }}
                                    style={{ alignItems: "left", width: '16%', display: 'flex', flexDirection: 'row' }} >
                                    <Text style={styles.TableHeadText}>Date</Text>
                                    {this.returnSortIcon(this.state.DateSort)}
                                </TouchableOpacity>
                                <View style={{ alignItems: "left", width: '12%' }} >
                                    <Text style={styles.TableHeadText}>Bill No</Text>
                                </View>
                                <View style={{ alignItems: "left", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>To</Text>
                                </View>
                                <View style={{ alignItems: "left", width: '13%' }} >
                                    <Text style={styles.TableHeadText}>Payment Type</Text>
                                </View>
                                <View style={{ alignItems: "left", width: '26%' }} >
                                    <Text style={[styles.TableHeadText, { marginLeft: '0.5vw' }]}>Description</Text>
                                </View>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (this.state.AmountSort == "") {
                                            this.setState({
                                                AmountSort: "asc",
                                                DateSort: "",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("amount", "asc", this.state.ExpenseBillList2) })
                                        }
                                        else if (this.state.AmountSort == "asc") {
                                            this.setState({
                                                AmountSort: "desc",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("amount", "desc", this.state.ExpenseBillList2) })
                                        }
                                        else if (this.state.AmountSort == "desc") {
                                            this.setState({
                                                AmountSort: "",
                                                ExpenseBillList: []
                                            }, () => { this.renderSorting("amount", "", this.state.ExpenseBillList2) })
                                        }
                                    }}
                                    style={{ alignItems: "left", width: '10%', display: 'flex', flexDirection: 'row' }} >
                                    <Text style={styles.TableHeadText}>Amount</Text>
                                    {this.returnSortIcon(this.state.AmountSort)}
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={true} style={styles.MainTable}>
                        {this.state.ExpenseBillList && this.state.ExpenseBillList.length > 0 && this.state.ExpenseListAferPagination && this.state.ExpenseListAferPagination.length > 0 ? this.state.ExpenseListAferPagination.map((item, index) => {
                            return (
                                <View key={index} style={[styles.TableDataWrapper]} >
                                    <View style={{ width: "16%", alignItems: 'left' }}><Text style={styles.TableDataText}>{item.bill_date}</Text></View>
                                    <View style={{ width: '12%', alignItems: 'left' }}><Text style={styles.TableDataText}>{item.bill_no}</Text></View>
                                    <View style={{ width: '19%', alignItems: 'left' }}>
                                        {item.receiver_name.length > 25 ?
                                            <Tooltip placement="top" title={item.receiver_name}>
                                                <Text style={[styles.TableDataText]}> {item.receiver_name.slice(0, 25) + ".."}</Text>
                                            </Tooltip> :
                                            <Text style={[styles.TableDataText]}> {item.receiver_name}</Text>}</View>
                                    <View style={{ width: this.state.ExpenseListAferPagination.length > 8 ? '13%' : '11%', alignItems: 'left' }}><Text style={styles.TableDataText}>{item.payment_type}</Text></View>
                                    <View style={{ width: '26%', alignItems: 'left' }}>
                                        {item.description.length > 25 ?
                                            <Tooltip placement="top" title={item.description}>
                                                <Text style={[styles.TableDataText]}> {item.description.slice(0, 25) + ".."}</Text>
                                            </Tooltip> :
                                            <Text style={[styles.TableDataText]}> {item.description}</Text>}
                                    </View>
                                    <View style={{ width: '10%', alignItems: 'left' }}><Text style={styles.TableDataText}>{item.amount}</Text></View>
                                </View>
                            )
                        }) :
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NoRecordsText}>No Records to show...</Text>
                            </View>}

                    </ScrollView>
                    {
                        <View style={styles.PaginationView}>
                            {
                                this.state.ExpenseBillList && this.state.ExpenseBillList.length > 0 ?

                                    <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.ExpenseBillList} paginationCurrentPage={this.paginationCurrentPage.bind(this)} />
                                    : null
                            }
                        </View>
                    }
                </View>

            </View>
        )
    }

    renderTextInputBox(placehold, key) {
        var states = this.state
        return (
            <View>
                <View style={{ marginTop: '1.3vw', marginLeft: '3vw', width: '30vw' }}>
                    <Text style={{ position: 'absolute', backgroundColor: color.themeShade, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', marginTop: '-0.5vw', color: key == color.black, fontSize: '0.8vw' }}>{placehold}</Text>
                    {key != "PaymentType" ?
                        <TextInput
                            value={states[key]}
                            onChangeText={(text) => {
                                if ((key == "AmountInput")) {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        states[key] = text
                                        this.setState({ states })
                                    }
                                }
                                else if ((key == "ToInput")) {
                                    var isValid = this.OnlyTextNumbersValidation(text)
                                    if (isValid || text === "") {
                                        states[key] = text
                                        this.setState({ states })
                                    }
                                }
                                else {
                                    states[key] = text
                                    this.setState({
                                        states
                                    })
                                }
                            }}
                            multiline={key == "Description" ? true : false}
                            maxLength={(key == "AmountInput") ? 7 : null}
                            style={[styles.TextInputStyles, { height: (key == "Description") ? "6vw" : '1.5vw', padding: "1vw" }]}
                        />
                        :
                        <Picker
                            selectedValue={this.state.PaymentType}
                            onValueChange={(itemvalue) => {
                                this.setState({
                                    PaymentType: itemvalue
                                })
                            }}
                            style={{ borderRadius: '0.26vw', width: "80%", backgroundColor: color.themeShade, height: "2.5vw", fontSize: '0.9vw', borderColor: "#CDD1D5", }}
                        >
                            {PaymentTypeList.map((item, index) => (
                                <Picker.Item key={index} label={item.label} value={item.value} />
                            ))}
                        </Picker>}
                </View>

            </View>

        );
    }


    renderCorporateRight = () => {
        return (
            <View style={{ height: '70vh' }}>
                <ScrollView style={styles.RightScrollView} showsVerticalScrollIndicator={false}>
                    <Text style={styles.RightHeadTitle}>Expense Bill</Text>

                    <View style={styles.HightLightView}>
                        <Text style={styles.HighLightText}>Expense Transaction</Text>
                    </View>
                    <View style={styles.InputView}>
                        {this.renderTextInputBox("To *", "ToInput")}
                    </View>
                    <View style={styles.InputView}>
                        {this.renderTextInputBox("Amount *", "AmountInput")}
                    </View>
                    <View style={styles.InputView}>
                        {this.renderTextInputBox("Payment Type*", "PaymentType")}
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Date*</Text>
                        <View style={{ width: '20vw', marginRight: '3vw' }}>
                            <DatePicker
                                disabledDate={current => {
                                    return current && current < moment().add(-3, "days") ||
                                        current && current > moment().add(0, "days");
                                    ;
                                }}
                                onChange={date => {
                                    var converted_date = moment(date).format("YYYY-MM-DD");
                                    this.setState({ billingDate: converted_date })
                                }}
                                value={moment(this.state.billingDate)}
                                format={"DD-MM-YYYY"}
                                placeholder={"Billing Date"}
                                clearIcon={false}
                                style={{
                                    marginTop: '1vw', width: '20.5vw', marginRight: '2vw', marginLeft: '1vw', backgroundColor: color.themeShade
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        {this.renderTextInputBox("Description *", "Description")}
                    </View>

                </ScrollView>
                <View>
                    <View style={{ height: '1.5vw', marginTop: '0.3vw' }}>
                        <Text style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '.9vw', fontWeight: '500', justifyContent: 'center' }}>
                            {this.state.ErrorText}
                        </Text>
                    </View>
                    <View>
                        <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'space-around' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.AddNewExpenseBill()
                                }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{"Save"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => { this.resetClicked() }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{"Clear"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        )
    }


    render() {
        return (
            <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={styles.LeftMainView}>{this.renderCorporateLeft()}</View>
                    <View style={styles.RightMainView}>{this.renderCorporateRight()}</View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    LeftMainView: {
        width: '65%',
        height: '70vh',
        margin: '0.2vw',
        marginLeft: '0.7vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw',
    },

    RightMainView: {
        width: '33%',
        height: '70vh',
        backgroundColor: color.themeShade,
        margin: '0.2vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },
    RightScrollView: {

    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw',
        zIndex: -2
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    MainTable: {
        height: '50vh'
    },
    RightHeadTitle: {
        marginLeft: '1vw',
        marginTop: '0.3vw',
        fontSize: '1vw',
        fontWeight: 'bold',
        color: color.themeDark
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "80%",
        height: "2.5vw",
        fontSize: ".9vw",
        paddingLeft: '1vw'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '3vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '30vw'
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    ExportBtnTouch: {
        paddingTop: '0.3vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.3vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '5vw',
        height: '2vw',
        marginLeft: '2vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw",
        fontWeight: '500'
    },
    MandatoryText: {
        color: 'red',
        fontWeight: '500',
        marginLeft: '0.2vw',
        fontSize: '1vw'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.lightGray,
        borderRadius: '0.26vw',
        width: "25%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    TabView: {
        marginLeft: '2vw'
    },
    SearchBoxView: {
        margin: '0.5vw', marginLeft: '2vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '2vw', alignItems: 'center'
    }


})