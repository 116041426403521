import React from "react";
import {
  View,
  Text,
  // FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  CheckBox,
  Platform,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import AsyncStorage from "../../../AsyncStorage";

import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from "moment";

import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader, RightSectionSPH,RightPanelEyeSelection } from '../BaseComponent'


const platform = Platform.OS;
const pageName = "previousglasspower";
const isPageName = "isPreviousGlassPower";
// const defaultResponse = [
//   {
//     id: "",
//     eye_type: { label: "RE", value: "RE"  },
//     SPH: {},
//     CYL: {},
//     AXIS: "",
//     BCDVA: "",
//     ADD: "",
//     BCNVA: "",
//     FrameType: "",
//     Aided_DV: {},
//     Aided_NV: {},
//     UnAided_DV: {},
//     UnAided_NV: {},
//     Vision: {},
//   },
//   {
//     id: "",
//     eye_type: { label: "LE", value: "LE"  },
//     SPH: {},
//     CYL: {},
//     AXIS: "",
//     BCDVA: "",
//     ADD: "",
//     BCNVA: "",
//     FrameType: "",
//     Aided_DV: {},
//     Aided_NV: {},
//     UnAided_DV: {},
//     UnAided_NV: {},
//     Vision: {},
//   }
// ];

export default class PreviousGlassPowerNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.inputSPH = React.createRef();
    this.inputCYL = React.createRef();
    this.inputAXIS = React.createRef();
    this.inputVISION = React.createRef();
    this.inputAidedDV = React.createRef();
    this.inputAidedNV = React.createRef();
    this.inputUnAidedDV = React.createRef();
    this.inputUnAidedNV = React.createRef();
    this.inputBCDVA = React.createRef();
    this.inputADD = React.createRef();
    this.inputBCNVA = React.createRef();

    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    
    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      selectedCYL: "",
      complaints: "",
      selectedDuration: "",
      comments: "",
      selectedType: "",
      selectedVision: "",
      selectedFrameType: "",
      selectedAXIS: "",
      selectedBCDVA: "",
      selectedADD:"",
      selectedBCNVA: "",
      selectedAidedNV: "",
      selectedAidedDV: "",
      selectedUnAidedNV: "",
      selectedUnAidedDV: "",

      flaggedEye: "",
      // response:[...defaultResponse],
      response:[
        {
          id: "",
          eye_type: { label: "RE", value: "RE"  },
          SPH: {},
          CYL: {},
          AXIS: "",
          BCDVA: "",
          ADD: "",
          BCNVA: "",
          FrameType: "",
          Aided_DV: {},
          Aided_NV: {},
          UnAided_DV: {},
          UnAided_NV: {},
          Vision: {},
        },
        {
          id: "",
          eye_type: { label: "LE", value: "LE"  },
          SPH: {},
          CYL: {},
          AXIS: "",
          BCDVA: "",
          ADD: "",
          BCNVA: "",
          FrameType: "",
          Aided_DV: {},
          Aided_NV: {},
          UnAided_DV: {},
          UnAided_NV: {},
          Vision: {},
        },
      ],

      // options list
      NV_TYPE_options: Constants.NV_TYPE_OPTIONS,
      DV_TYPE_options: Constants.DV_TYPE_OPTIONS,
      SPH_options: Constants.SPH_OPTIONS,
      CYL_options: Constants.CYL_OPTIONS,
      AXIS_options: Constants.AXIS_OPTIONS,
      VISION_options: Constants.VISION_OPTION,

      BCDVA_options: Constants.BCDVA_OPTIONS,
      ADD_options:Constants.ADD,
      BCNVA_options: Constants.BCNVA_OPTIONS,

      // flags
      isNewAidedNV: false,
      isNewAidedDV: false,
      isNewUnAidedNV: false,
      isNewUnAidedDV: false,
      isNewSPH: false,
      isNewCYL: false,
      isNewAXIS: false,
      isNewBCDVA: false,
      isNewADD:false,
      isNewBCNVA: false,

      isNewVISION: false,

      copyRightToLeft:false,
      copyLeftToRight:false,

      getAvailableEyeTypes: this.props.getAvailableEyeTypes || [],
      getAvailableEyeTypesId: this.props.getAvailableEyeTypesId || [],
      date: converted_date,

      selectedSPHSign: { value: "+", label: "+" },
      selectedCYLSign: { value: "+", label: "+" },

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null,
      itemListLoaded: false,
      newADD: "",
      isNewADD: false,
      newBCDVA: "",
      newBCNVA: ""

    };
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    })
  }

  renderDatePicker(){
    return(
      <View style={{flexDirection:"row",justifyContent:'flex-start',alignItems:'center',marginVertical:'0.6vw'}} >
        <Text style={{fontSize: '0.9vw'}}>{"Date : "}</Text>
        <DatePicker
                  disabledDate={current => {
                    return current && current > moment().add(0, "days");
                  }}
                  clearIcon={false}
                  onChange={date => {
                    // alert(date)
                    var converted_date = null;
                    var states = this.state;
                    if (date) {
                      var fulldate = new Date(date);
                      converted_date = moment(fulldate).format("YYYY-MM-DD");
                    }
                    states["date"] = converted_date;
                    this.setState({ states })
                    // this.setState({filterFromDate :converted_date},()=>{this.updateState("fromDate", this.state.filterFromDate)})
                  }}
                  value={
                    this.state.date
                    ? moment(this.state.date)
                    : this.state.date
                  } 
                  defaultValue={this.state.date ? this.state.date : null}
                  format={"DD-MM-YYYY"}
                  placeholder={"DD-MM-YYYY"}
                  style={{
                    backgroundColor: "transparent",
                    borderColor: color.lightGray,
                    borderWidth: 1,
                    marginTop: '0.3vw',
                    height: '2.3vw',
                    borderWidth: 1,
                    borderRadius: 4,
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: '0.6vw',
                    paddingLeft: '0.6vw',
                    paddingRight: '0.6vw',
                    width: "50%",
                    marginLeft: '1vw'
                  }}
                />
      </View>
    )
  }

  componentWillReceiveProps(props) {

    // this.state.getAvailableEyeTypes = props.getAvailableEyeTypes;
    // this.state.getAvailableEyeTypesId = props.getAvailableEyeTypesId;

    let { response,id, itemListLoaded } = this.state;
    // let response = {...this.state.response}

    // console.log("response",response)

    // if(props.getAvailableEyeTypes && ( props.getAvailableEyeTypes.length != (this.state.getAvailableEyeTypes).length)) {

    //   this.setState({
    //     getAvailableEyeTypes: props.getAvailableEyeTypes,
    //     getAvailableEyeTypesId: props.getAvailableEyeTypesId
    //   })
    // }
    
    // if (props.editItem) {

    //   if (props.editItem.title === pageName) {

    //     let item = props.editItem.item;

    //     if (id !== item.id) {

    //       var type = (item.Aided_NV || item.Aided_DV)?"Aided":(item.UnAided_NV || item.UnAided_DV)?"UnAided":"";
    //       let { BCNVA_options,BCDVA_options,NV_TYPE_options, DV_TYPE_options, SPH_options, CYL_options, AXIS_options, VISION_options,ADD_options } = this.state;

    //       /* add item if not in list */
    //       let NV_list = [];
    //       let DV_list = [];
    //       let SPH_list = [];
    //       let CYL_list = [];
    //       let AXIS_list = [];
    //       let VISION_list = [];
    //       let BCDVA_list = [];
    //       let BCNVA_list = [];
    //       let ADD_list=[];

    //       let positiveSign = { value: "+", label: "+" };
    //       let negativeSign = { value: "-", label: "-" };

    //       // BCDVA
    //       // BCNVA

    //       // if data not exist with default list, the data will add to list
    //       NV_list = this.addItemInArray(NV_TYPE_options, item.Aided_NV);
    //       DV_list = this.addItemInArray(DV_TYPE_options, item.Aided_DV);
          
    //       NV_list = this.addItemInArray(NV_list, item.UnAided_NV);
    //       DV_list = this.addItemInArray(DV_list, item.UnAided_DV);

    //       SPH_list = this.addItemInArray(SPH_options, item.SPH);
    //       CYL_list = this.addItemInArray(CYL_options, item.CYL);
    //       AXIS_list = this.addItemInArray(AXIS_options, item.AXIS);
    //       VISION_list = this.addItemInArray(VISION_options, item.UC);

    //       if(item.BCDVA){
    //         BCDVA_list = this.addItemInArray(BCDVA_options, item.BCDVA);
    //       }else{
    //         BCDVA_list = BCDVA_options
    //       }
    //       if(item.BCNVA){
    //         BCNVA_list = this.addItemInArray(BCNVA_options, item.BCNVA);
    //       }else{
    //         BCNVA_list = BCNVA_options
    //       }
    //       if(item.add){
    //         ADD_list = this.addItemInArray(ADD_options, item.add);
    //       }else{
    //         ADD_list = ADD_options
    //       }


    //       this.setState({
    //         id: item.id,
    //         selectedEye: item.eye_type,
    //         selectedSPH: item.SPH?{ value: item.SPH, label: item.SPH }:null,
    //         selectedCYL: item.CYL?{ value: item.CYL, label: item.CYL }:null,
    //         selectedAidedNV: item.Aided_NV,
    //         selectedAidedDV: item.Aided_DV,
    //         selectedUnAidedNV: item.UnAided_NV,
    //         selectedUnAidedDV: item.UnAided_DV,
    //         selectedType: type,
    //         selectedVision: item.VISION,
    //         selectedFrameType: item.frame_type,
    //         selectedAXIS: item.AXIS,
    //         comments: item.comment,
    //         date:item.date,

    //         selectedBCDVA: item.BCDVA,
    //         selectedADD:item.add,
    //         selectedBCNVA: item.BCNVA,

    //         NV_TYPE_options: JSON.parse(JSON.stringify(NV_list)),
    //         DV_TYPE_options: JSON.parse(JSON.stringify(DV_list)),
    //         SPH_options: JSON.parse(JSON.stringify(SPH_list)),
    //         CYL_options: JSON.parse(JSON.stringify(CYL_list)),
    //         AXIS_options: JSON.parse(JSON.stringify(AXIS_list)),
    //         VISION_options: JSON.parse(JSON.stringify(VISION_list)),
    //         BCDVA_options: JSON.parse(JSON.stringify(BCDVA_list)),
    //         BCNVA_options: JSON.parse(JSON.stringify(BCNVA_list)),
    //         ADD_options: JSON.parse(JSON.stringify(ADD_list)),


    //         // update sign
    //         selectedSPHSign: item.SPH?(item.SPH).charAt(0)=="-"?negativeSign:positiveSign:positiveSign,
    //         selectedCYLSign: item.CYL?(item.CYL).charAt(0)=="-"?negativeSign:positiveSign:positiveSign
    //       });
    //     }
    //   }
    // }
    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let itemList = props.editItem.item?props.editItem.item:[];
        //  alert("itemList"+JSON.stringify(itemList))
        // console.log("itemList",itemList)

        if (itemList.length>0) {

          var type = (itemList.Aided_NV || itemList.Aided_DV)?"Aided":(itemList.UnAided_NV || itemList.UnAided_DV)?"UnAided":"";
          let { BCNVA_options,BCDVA_options,NV_TYPE_options, DV_TYPE_options, SPH_options, CYL_options, AXIS_options, VISION_options,ADD_options} = this.state;
          let states=this.state

          /* add item if not in list */
          let NV_list = [];
          let DV_list = [];
          let SPH_list = [];
          let CYL_list = [];
          let AXIS_list = [];
          let VISION_list = [];
          let BCDVA_list = [];
          let BCNVA_list = [];
          let ADD_list=[];
         
          let positiveSign = { value: "+", label: "+" };
          let negativeSign = { value: "-", label: "-" };

          // DV_list = DV_TYPE_options;
          SPH_list = SPH_options;
          // PGCL_list = PGCL_options;
          // NV_list = NV_TYPE_options;
          CYL_list = CYL_options;
          AXIS_list = AXIS_options;
          VISION_list = VISION_options;
          BCDVA_list = BCDVA_options;
          BCNVA_list = BCNVA_options;
          ADD_list = ADD_options;

          var selectedEye = {}
          var selectedSPH = {}
          var selectedCYL = {}
          var selectedAXIS = {}
          var selectedBCDVA = {}
          var selectedBCNVA = {}
          var selectedADD = {}
          var selectedFrameType = ''
          var flaggedEye = ""

          // if data not exist with default list, the data will add to list
          for( let i=0; i<itemList.length; i++ ) {

            SPH_list = this.addItemInArray(SPH_list, itemList[i].SPH);
            CYL_list = this.addItemInArray(CYL_list, itemList[i].CYL);
            AXIS_list = this.addItemInArray(AXIS_list, itemList[i].AXIS);
            BCDVA_list = this.addItemInArray(BCDVA_list, itemList[i].BCDVA);
            ADD_list = this.addItemInArray(ADD_list, itemList[i].ADD);
            BCNVA_list = this.addItemInArray(BCNVA_list, itemList[i].BCNVA);

            response[i] = {...response[i], ...{id:itemList[i].id}}

            response[i]['eye_type'] = { label: itemList[i].eye_type, value:itemList[i].eye_type  }
            response[i]['SPH'] = {label: itemList[i].SPH, value: itemList[i].SPH}
            response[i]['CYL'] = {label: itemList[i].CYL, value: itemList[i].CYL}
            response[i]['AXIS'] =  itemList[i].AXIS
            response[i]['BCDVA'] =  itemList[i].BCDVA
            response[i]['ADD'] =  itemList[i].add
            response[i]['BCNVA'] =  itemList[i].BCNVA 
            response[i]['FrameType'] = itemList[i].frame_type
            states['comments']=itemList[i].comment
            states['date']=itemList[i].date

            // set default
            if( itemList[i].selected==true )
            {
              flaggedEye = itemList[i].eye_type;
              selectedEye = response[i]['eye_type']
              selectedSPH = response[i]['SPH']
              selectedCYL = response[i]['CYL']
              selectedAXIS = response[i]['AXIS']
              selectedBCDVA = response[i]['BCDVA']
              selectedADD = response[i]['ADD']
              selectedBCNVA = response[i]['BCNVA']
              selectedFrameType = response[i]['FrameType']
            }
            // else 
            // {
            //   selectedEye = response[i]['eye_type'].value
            //   selectedSPH = response[i]['SPH']
            //   selectedCYL = response[i]['CYL']
            //   selectedAXIS = response[i]['AXIS']
            //   selectedBCDVA = response[i]['BCDVA']
            //   selectedADD = response[i]['ADD']
            //   selectedBCNVA = response[i]['BCNVA']
            //   selectedFrameType = response[i]['FrameType']
            // }
          }
          
          this.setState({
            // id: item.id,
            flaggedEye: flaggedEye,
            response: response,
            itemListLoaded: true,

            selectedEye: selectedEye,
            selectedSPH: selectedSPH,
            selectedCYL: selectedCYL,
            selectedAXIS: selectedAXIS,
            selectedBCDVA: selectedBCDVA,
            selectedADD: selectedADD,
            selectedBCNVA: selectedBCNVA,
            selectedFrameType: selectedFrameType,
            BCNVA_options: JSON.parse(JSON.stringify(BCNVA_list)),
            BCDVA_options: JSON.parse(JSON.stringify(BCDVA_list)),
            NV_TYPE_options: JSON.parse(JSON.stringify(NV_list)),
            DV_TYPE_options: JSON.parse(JSON.stringify(DV_list)),
            SPH_options: JSON.parse(JSON.stringify(SPH_list)),
            CYL_options: JSON.parse(JSON.stringify(CYL_list)),
            AXIS_options: JSON.parse(JSON.stringify(AXIS_list)),
            VISION_options: JSON.parse(JSON.stringify(VISION_list)),
            ADD_options: JSON.parse(JSON.stringify(ADD_list )),
            states
          }, () => {
            this.props.clearEditedItem(pageName,{})
          });

        }
      }
    }

  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  // onPressAction = (key, selectedvalue) => {

  //   // let states = this.state;
  //   // states[key] = value;

  //   // this.setState({
  //   //   states
  //   // })
  //   let { response, flaggedEye, selectedSPH, selectedCYL, selectedAXIS, selectedBCDVA, selectedADD, selectedBCNVA } = this.state;

  //   if(flaggedEye || key == "selectedEye")
  //   {
  //       if (key == "selectedEye" ) {

  //         // swap data,
  //         if(selectedvalue.value == "RE")
  //         {
  //           selectedSPH = response[0].SPH;
  //           selectedCYL = response[0].CYL;
  //           selectedAXIS = response[0].AXIS;
  //           selectedBCDVA = response[0].BCDVA;
  //           selectedADD = response[0].ADD;
  //           selectedBCNVA = response[0].BCNVA;
  //         } else {
  //           selectedSPH = response[1].SPH;
  //           selectedCYL = response[1].CYL;
  //           selectedAXIS = response[1].AXIS;
  //           selectedBCDVA = response[1].BCDVA;
  //           selectedADD = response[1].ADD;
  //           selectedBCNVA = response[1].BCNVA;
  //         }

  //         this.setState({
  //           selectedEye:selectedvalue,
  //           flaggedEye: selectedvalue.value,

  //           selectedSPH: selectedSPH,
  //           selectedCYL: selectedCYL,
  //           selectedAXIS: selectedAXIS,
  //           selectedBCDVA: selectedBCDVA,
  //           selectedADD: selectedADD,
  //           selectedBCNVA: selectedBCNVA

  //         })
        
  //       }else if(key =="selectedSPH"){


  //         flaggedEye == "RE"?response[0].SPH = selectedvalue:response[1].SPH = selectedvalue

  //         this.setState({
  //           response,
  //           selectedSPH: selectedvalue
  //         })

  //       }else if(key == "selectedCYL"){

  //         flaggedEye == "RE"?response[0].CYL = selectedvalue:response[1].CYL = selectedvalue

  //         this.setState({
  //           response,
  //           selectedCYL: selectedvalue
  //         })

  //       }else if(key == "selectedAXIS"){

  //         flaggedEye == "RE"?response[0].AXIS = selectedvalue:response[1].AXIS = selectedvalue

  //         this.setState({
  //           response,
  //           selectedAXIS: selectedvalue
  //         })
  //       }else if(key == "selectedBCDVA"){

  //         flaggedEye == "RE"?response[0].BCDVA = selectedvalue:response[1].BCDVA = selectedvalue

  //         this.setState({
  //           response,
  //           selectedBCDVA: selectedvalue
  //         })
  //       }else if(key == "selectedADD"){

  //         flaggedEye == "RE"?response[0].ADD = selectedvalue:response[1].ADD = selectedvalue
          
  //         this.setState({ 
  //           response,
  //           selectedADD: selectedvalue
  //         })
  //       }else if(key == "selectedBCNVA"){

  //         flaggedEye == "RE"?response[0].BCNVA = selectedvalue:response[1].BCNVA = selectedvalue
          
  //         this.setState({ 
  //           response,
  //           selectedBCNVA: selectedvalue
  //         })
  //       }

  //     }else{
  //       this.props.showResposeValue("error", "Select eye type first")
  //     }

  // }

  addComplaint=(eyeType, id)=> {
    // console.log("response-Add",this.state.response)

    let errorMessage = "";
    // let states = this.state;
    var service_url = Constants.PREVIOUS_GLASS_POWER;

    var currentDate = new Date();
    var cDate = currentDate.getDate();
    var cMonth = currentDate.getMonth() + 1;
    // console.log("Cmonth",cMonth)
    cMonth = cMonth < 10 ? '0' + cMonth : cMonth;
    var cYear = currentDate.getFullYear();
    var date = cYear + "-" + cMonth + "-" + cDate
    let data = {};
    let isValid = false; 
    let { response, patientAppointment } = this.state;

    let IsHaveRightEyeValues = false; 
    let IsHaveLeftEyeValues = false; 

    // console.log("Cmonth",cMonth)
    
    // data = {
    //     "appointment_id": this.state.patientAppointment.appointment_id,
    //     "eye_type": eyeType,
    //     "date": date,
    //     "SPH": states.selectedSPH?states.selectedSPH.value:"",
    //     "CYL": states.selectedCYL?states.selectedCYL.value:"",
    //     "AXIS": states.selectedAXIS,
    //     "VISION": states.selectedVision,
    //     "Aided_DV" : states.selectedAidedDV,
    //     "Aided_NV" : states.selectedAidedNV,
    //     "UnAided_DV" : states.selectedUnAidedDV,
    //     "UnAided_NV" : states.selectedUnAidedNV,
    //     "frame_type": states.selectedFrameType,
    //     "comment": states.comments,

    //     "BCDVA": states.selectedBCDVA,
    //     "add":states.selectedADD,
    //     "BCNVA": states.selectedBCNVA,

    //     "date":states.date ? moment(states.date).format("YYYY-MM-DD"):''
    // }

    // if (id) {
    //   data["id"] = id;
    // }
    
    // if( 
    //   (response[0].SPH || response[0].CYL || response[0].AXIS || response[0].BCDVA || response[0].ADD || response[0].BCNVA || response[0].FrameType) 
    //   && (response[1].SPH || response[1].CYL || response[1].AXIS || response[1].BCDVA || response[1].ADD || response[1].BCNVA || response[1].FrameType) ) {
    //     isValid = true
    //   }

    if(
      ( this.validateObjData(response[0].eye_type) && ( this.validateObjData(response[0].SPH) || this.validateObjData(response[0].CYL) || this.validateObjData(response[0].AXIS) || this.validateObjData(response[0].BCDVA) || this.validateObjData(response[0].ADD) || this.validateObjData(response[0].BCNVA) || this.validateObjData(response[0].FrameType))) &&
      ( this.validateObjData(response[1].eye_type) && ( this.validateObjData(response[1].SPH) || this.validateObjData(response[1].CYL) || this.validateObjData(response[1].AXIS) || this.validateObjData(response[1].BCDVA) || this.validateObjData(response[1].ADD) || this.validateObjData(response[1].BCNVA) || this.validateObjData(response[1].FrameType)))

    ){
      IsHaveLeftEyeValues=true
      IsHaveRightEyeValues=true

      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[0].eye_type?(response[0].eye_type).value:"",
          date: this.state.date?this.state.date:date,
          SPH: response[0].SPH?(response[0].SPH).value:"",
          CYL: response[0].CYL?(response[0].CYL).value:"",
          AXIS: response[0].AXIS?(response[0].AXIS):"",
          VISION: response[0].Vision?(response[0].Vision).value:"",
          Aided_DV: response[0].Aided_DV?(response[0].Aided_DV).value:"",
          Aided_NV: response[0].Aided_NV?(response[0].Aided_NV).value:"",
          UnAided_DV: response[0].UnAided_DV?(response[0].UnAided_DV).value:"",
          UnAided_NV: response[0].UnAided_NV?(response[0].UnAided_NV).value:"",
          frame_type: response[0].FrameType?(response[0].FrameType):"",
          BCDVA: response[0].BCDVA?(response[0].BCDVA):"",
          add: response[0].ADD?(response[0].ADD):"",
          BCNVA: response[0].BCNVA?(response[0].BCNVA):"",
          comment:this.state.comments
        },
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[1].eye_type?(response[1].eye_type).value:"",
          date: this.state.date?this.state.date:date,
          SPH: response[1].SPH?(response[1].SPH).value:"",
          CYL: response[1].CYL?(response[1].CYL).value:"",
          AXIS: response[1].AXIS?(response[1].AXIS):"",
          VISION: response[1].Vision?(response[1].Vision).value:"",
          Aided_DV: response[1].Aided_DV?(response[1].Aided_DV).value:"",
          Aided_NV: response[1].Aided_NV?(response[1].Aided_NV).value:"",
          UnAided_DV: response[1].UnAided_DV?(response[1].UnAided_DV).value:"",
          UnAided_NV: response[0].UnAided_NV?(response[1].UnAided_NV).value:"",
          frame_type: response[1].FrameType?(response[1].FrameType):"",
          BCDVA: response[1].BCDVA?(response[1].BCDVA):"",
          add: response[1].ADD?(response[1].ADD):"",
          BCNVA: response[1].BCNVA?(response[1].BCNVA):"",
          comment:this.state.comments
        }
      ]

      if( response[0].id){ data[0]['id'] = response[0].id }
      if( response[1].id){ data[1]['id'] = response[1].id }
    }
    else if(this.validateObjData(response[0].eye_type) && ( this.validateObjData(response[0].SPH) || this.validateObjData(response[0].CYL) || this.validateObjData(response[0].AXIS) || this.validateObjData(response[0].BCDVA) || this.validateObjData(response[0].ADD) || this.validateObjData(response[0].BCNVA) || this.validateObjData(response[0].FrameType))){
      IsHaveRightEyeValues=true
      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[0].eye_type?(response[0].eye_type).value:"",
          date: this.state.date?this.state.date:date,
          SPH: response[0].SPH?(response[0].SPH).value:"",
          CYL: response[0].CYL?(response[0].CYL).value:"",
          AXIS: response[0].AXIS?(response[0].AXIS):"",
          VISION: response[0].Vision?(response[0].Vision).value:"",
          Aided_DV: response[0].Aided_DV?(response[0].Aided_DV).value:"",
          Aided_NV: response[0].Aided_NV?(response[0].Aided_NV).value:"",
          UnAided_DV: response[0].UnAided_DV?(response[0].UnAided_DV).value:"",
          UnAided_NV: response[0].UnAided_NV?(response[0].UnAided_NV).value:"",
          frame_type: response[0].FrameType?(response[0].FrameType):"",
          BCDVA: response[0].BCDVA?(response[0].BCDVA):"",
          add: response[0].ADD?(response[0].ADD):"",
          BCNVA: response[0].BCNVA?(response[0].BCNVA):"",
          comment:this.state.comments
        }
      ]

      if( response[0].id){ data[0]['id'] = response[0].id }
    }
    else if ( ( this.validateObjData(response[1].eye_type) && ( this.validateObjData(response[1].SPH) || this.validateObjData(response[1].CYL) || this.validateObjData(response[1].AXIS) || this.validateObjData(response[1].BCDVA) || this.validateObjData(response[1].ADD) || this.validateObjData(response[1].BCNVA) || this.validateObjData(response[1].FrameType)))){
      IsHaveLeftEyeValues=true
      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[1].eye_type?(response[1].eye_type).value:"",
          date: this.state.date?this.state.date:date,
          SPH: response[1].SPH?(response[1].SPH).value:"",
          CYL: response[1].CYL?(response[1].CYL).value:"",
          AXIS: response[1].AXIS?(response[1].AXIS):"",
          VISION: response[1].Vision?(response[1].Vision).value:"",
          Aided_DV: response[1].Aided_DV?(response[1].Aided_DV).value:"",
          Aided_NV: response[1].Aided_NV?(response[1].Aided_NV).value:"",
          UnAided_DV: response[1].UnAided_DV?(response[1].UnAided_DV).value:"",
          UnAided_NV: response[0].UnAided_NV?(response[1].UnAided_NV).value:"",
          frame_type: response[0].FrameType?(response[0].FrameType):"",
          BCDVA: response[1].BCDVA?(response[1].BCDVA):"",
          add: response[1].ADD?(response[1].ADD):"",
          BCNVA: response[1].BCNVA?(response[1].BCNVA):"",
          comment:this.state.comments
        }
      ]

      if( response[1].id){ data[1]['id'] = response[1].id }
    }

    // console.log("isValid:",isValid)


    // if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
    // {
    //   data['doctor_id'] = this.state.selectedDoctorId;
    // }

    // if(!states.selectedEye)
    // {
    //   errorMessage = errorMessage?errorMessage+",Select Eye Type":"Select Eye Type";
    // } else if(
    //   !states.selectedSPH && !states.selectedCYL 
    //   && !states.selectedAidedDV && !states.selectedAidedNV && !states.selectedUnAidedDV && !states.selectedUnAidedNV 
    //   && !states.selectedAXIS && !states.selectedVision 
    //   && !states.selectedFrameType && !states.comments
    //   ) {
    //   errorMessage = errorMessage?errorMessage+",Select any values":"Select any values";
    // }
    
    // if(errorMessage){

    //   this.props.showResposeValue("error", errorMessage)
    // } else {

    //   OpthamologyService.getInstance().postComplaints(
    //     service_url,
    //     data,
    //     this,
    //     this.getComplaintsSuccess,
    //     this.getComplaintsFailure
    //   );
    // }
    if (IsHaveRightEyeValues || IsHaveLeftEyeValues) {

      // if (states.selectedEye) {
          

  
        if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
        {
          data['doctor_id'] = this.state.selectedDoctorId;
        }

        // console.log("postData",data)
  
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.getComplaintsSuccess,
          this.getComplaintsFailure
        );
      // }
      } else {
        this.props.showResposeValue("error", "Select Any eye values")
      }
  
  };

  /*
  addComments = () => {
    let {comments, patientAppointment} = this.state;
    var service_url = Constants.OPTHALMOLOGT_COMMENTS;
    var data = {
      "patient_id": patientAppointment.patient_id,
      "appointment_id": patientAppointment.appointment_id,
      "section": "Auto Refractometry",
      "comments": comments
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addCommentsSuccess,
      this.addCommentsFailure
    );
  };
  addCommentsSuccess = success => {
    // alert(JSON.stringify("PGP comments added succes--->"))
  }
  addCommentsFailure = error => { }
  */

  getComplaintsSuccess = response => {

    if (response.status === "success") {

        this.props.clearEditedItem(pageName, {})
        
        this.props.refreshData(pageName);
        this.props.showResposeValue("success", response.message)

        // clear data
        this.clearComplaint();

    } else {
      this.props.showResposeValue("error", response.message)
    }
  }

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
    this.props.showResposeValue("error", error.message)

  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = "";
    states['selectedSPH'] = "";
    states['selectedCYL'] = "";
    states['selectedAXIS'] = "";
    states['selectedVision'] = "";      // my
    states['selectedFrameType'] = "";
    states['selectedType'] = ""
    states['comments'] = "";
    states['selectedAidedNV'] = "";
    states['selectedAidedDV'] = "";
    states['selectedUnAidedNV'] = "";
    states['selectedUnAidedDV'] = "";
    states['selectedBCDVA'] = "";
    states['selectedADD'] = "";
    states['selectedBCNVA'] = "";

    states['selectedSPHSign'] = { label: "+", value: "+"};
    states['selectedCYLSign'] = { label: "+", value: "+"};

    states['itemListLoaded'] = false;
    states['copyRightToLeft'] = false;
    states['copyLeftToRight'] = false;
    states['flaggedEye'] = "";
    states['response'] = [
      {
        id: "",
        eye_type: { label: "RE", value: "RE"  },
        SPH: {},
        CYL: {},
        AXIS: "",
        BCDVA: "",
        ADD: "",
        BCNVA: "",
        FrameType: "",
        Aided_DV: {},
        Aided_NV: {},
        UnAided_DV: {},
        UnAided_NV: {},
        Vision: {},
      },
      {
        id: "",
        eye_type: { label: "LE", value: "LE"  },
        SPH: {},
        CYL: {},
        AXIS: "",
        BCDVA: "",
        ADD: "",
        BCNVA: "",
        FrameType: "",
        Aided_DV: {},
        Aided_NV: {},
        UnAided_DV: {},
        UnAided_NV: {},
        Vision: {},
      },
    ];
    states['date']=""
    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }


  renderCheckBox = (label, value, key) => {
    return (
      <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
        <CheckBox value={value}
          style={{ marginRight: '0.8vw', height: '1.2vw', width: '1.2vw' }}
          onValueChange={() => {

            var states = this.state;

            if( key == "copyRightToLeft" && !value)
                  {
                    states["copyLeftToRight"] = false
                  }
                  if( key == "copyLeftToRight" && !value)
                  {
                    states["copyRightToLeft"] = false
                  }


            states[key] = !value

            // this.setState({
            //   states
            // })
            this.setState({
              states
            },() => {

                if(!value)
                {
                  this.copyData()
                }else{
                  this.clearData(key == "copyRightToLeft"?"LE":"RE")
                }
            })

          }}

        />
        <Text style={{ fontSize: '0.8vw', color: "#888888" }}>{label}</Text>
      </View>
    );
  }

  copyData=()=>{
    
    let { response, flaggedEye } = this.state;

    let originData= null;
    
    originData = response && response.filter((item)=>{
        return item.eye_type.value == flaggedEye
      })

      // console.log(" originData ", originData)

    if( originData && originData.length>0 ) {

      for( let i=0; i<response.length; i++ ) {
        
        if( response[i].eye_type.value != flaggedEye ) {

          // assign
          response[i]["SPH"] = originData[0]['SPH'];
          response[i]["CYL"] = originData[0]['CYL'];
          response[i]["AXIS"] = originData[0]['AXIS'];
          response[i]["BCDVA"] = originData[0]['BCDVA']
          response[i]["BCNVA"] = originData[0]['BCNVA']
          response[i]["ADD"] = originData[0]['ADD']
          response[i]["FrameType"] = originData[0]['FrameType']
          

          // update
          this.setState({ 
            response: response
          })

        }
      }
    }

  }
  clearData(ClearableEyeType) {
    let { response } = this.state;
    if (ClearableEyeType == "RE") {
      response[0]["SPH"] = {};
      response[0]["CYL"] = {};
      response[0]["AXIS"] = "";
      response[0]["BCDVA"] = ""
      response[0]["BCNVA"] =""
      response[0]["ADD"] = ""
      response[0]["FrameType"] = ""
    }
    else if (ClearableEyeType == "LE") {
      response[1]["SPH"] = {};
      response[1]["CYL"] = {};
      response[1]["AXIS"] = "";
      response[1]["BCDVA"] = ""
      response[1]["BCNVA"] =""
      response[1]["ADD"] = ""
      response[1]["FrameType"] = ""
    }
    this.setState({
      response: response
    })
  }


  renderCheckBoxView(){
    return(
     
      <View>
    {
      this.state.flaggedEye == "RE" ?
        this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
        : this.state.flaggedEye == "LE" ?
          this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
          : null
    }
    </View>
    )
  }

  updateState = (key,selectedvalue) => {
    console.log("selected sign value :",selectedvalue,key)

    let { response, flaggedEye, selectedFrameType, selectedSPH, selectedCYL, selectedAXIS, selectedBCDVA, selectedADD, selectedBCNVA,comments,date,selectedSPHSign} = this.state;

    if(flaggedEye || key == "selectedEye")
    {
        if (key == "selectedEye" ) {
          // swap data,
          if(selectedvalue.value == "RE")
          {
            selectedSPH = response[0].SPH;
            selectedCYL = response[0].CYL;
            selectedAXIS = response[0].AXIS;
            selectedBCDVA = response[0].BCDVA;
            selectedADD = response[0].ADD;
            selectedBCNVA = response[0].BCNVA;
            selectedFrameType = response[0].FrameType;
            comments=this.state.comments;
            date=this.state.date;


          } else {
            selectedSPH = response[1].SPH;
            selectedCYL = response[1].CYL;
            selectedAXIS = response[1].AXIS;
            selectedBCDVA = response[1].BCDVA;
            selectedADD = response[1].ADD;
            selectedBCNVA = response[1].BCNVA;
            selectedFrameType = response[0].FrameType;
            comments=this.state.comments;
            date=this.state.date;
          }

          this.setState({
            selectedEye:selectedvalue,
            flaggedEye: selectedvalue.value,

            selectedSPH: selectedSPH,
            selectedCYL: selectedCYL,
            selectedAXIS: selectedAXIS,
            selectedBCDVA: selectedBCDVA,
            selectedADD: selectedADD,
            selectedBCNVA: selectedBCNVA,
            selectedFrameType:selectedFrameType,
            comments:comments,
            date:date

          })
        
        }else if(key =="selectedSPH"){


          flaggedEye == "RE"?response[0].SPH = selectedvalue:response[1].SPH = selectedvalue

          this.setState({
            response,
            selectedSPH: selectedvalue
          })

        }else if(key == "selectedCYL"){

          flaggedEye == "RE"?response[0].CYL = selectedvalue:response[1].CYL = selectedvalue

          this.setState({
            response,
            selectedCYL: selectedvalue
          })

        }else if(key == "selectedAXIS"){

          flaggedEye == "RE"?response[0].AXIS = selectedvalue:response[1].AXIS = selectedvalue

          this.setState({
            response,
            selectedAXIS: selectedvalue
          })
        }else if(key == "selectedBCDVA"){

          flaggedEye == "RE"?response[0].BCDVA = selectedvalue:response[1].BCDVA = selectedvalue

          this.setState({
            response,
            selectedBCDVA: selectedvalue
          })
        }else if(key == "selectedADD"){

          flaggedEye == "RE"?response[0].ADD = selectedvalue:response[1].ADD = selectedvalue
          
          this.setState({ 
            response,
            selectedADD: selectedvalue
          })
        }else if(key == "selectedBCNVA"){

          flaggedEye == "RE"?response[0].BCNVA = selectedvalue:response[1].BCNVA = selectedvalue
          
          this.setState({ 
            response,
            selectedBCNVA: selectedvalue
          })
        }else if(key == "selectedFrameType"){

          response[0].FrameType = selectedvalue
          response[1].FrameType = selectedvalue
          this.setState({ 
            response,
            selectedFrameType: selectedvalue
          })
        }else if (key =="selectedSPHSign"){
         
          // flaggedEye == "RE"?response[0].FrameType = selectedvalue:response[1].FrameType = selectedvalue
          this.setState({
            
            selectedSPHSign: selectedvalue
          })
        }else if (key =="selectedCYLSign"){
          this.setState({            
            selectedCYLSign: selectedvalue
          })
        }

      }else{
        // this.props.showResposeValue("error", "Select eye type first")
      }


  }

  render() {
    // console.log("Date", this.state.date)
    // console.log("selectedADD", this.state.selectedCYL)
    return (
      <View>

        <DoctorNotesCommonRightSideHeader title={"Previous Glass Power"} clearAllData={this.clearComplaint.bind(this)} />

        {/* {this.renderSubHeadingBorder("Previous Glass Power", false, true)} */}
        <CommonSectionHeader heading={"Eye Type"}  />
        {this.renderSubHeadingBorder("", false, true)}

        <RightPanelEyeSelection 
          getAvailableEyeTypes={null} //this.props.getAvailableEyeTypes 
          id={this.state.id} 
          isLocked={this.state.isLocked} 
          selectedEye={this.state.selectedEye} 
          onPressButton={this.updateState.bind(this)} />
          
        {/* {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false,
          'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true,"",this.state.getAvailableEyeTypesId,false,this.state.isLocked, this.state.id ? true: false )} */}
        {/* <View style={{ height: 15 }}></View> */}
        {/*
        {this.renderTitleWithMultipleBtn("Type", Constants.TYPE_AID_NONAID, 2, false,
          'selectedType', this.state.selectedType, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        <View style={{ height: 15 }}></View>

        {this.state.selectedType === "Aided"?
        this.renderTitleWithMultipleBtn("DV", this.state.DV_TYPE_options, 5, false, 'selectedAidedDV', this.state.selectedAidedDV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):
        this.state.selectedType === "UnAided"?this.renderTitleWithMultipleBtn("DV", this.state.DV_TYPE_options, 5, false, 'selectedUnAidedDV', this.state.selectedUnAidedDV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):null
        }
        {this.state.selectedType === "Aided"?
        this.renderNewButton(this.inputAidedDV, 'DV value', 'newDV', this.state.newDV, 'isNewDV', this.state.isNewDV, 'selectedAidedDV', 'DV_TYPE_options'):
        this.state.selectedType === "UnAided"?this.renderNewButton(this.inputUnAidedDV, 'DV value', 'newDV', this.state.newDV, 'isNewDV', this.state.isNewDV, 'selectedUnAidedDV', 'DV_TYPE_options'):null
        } 
        {this.state.selectedType?<View style={{ height: 15 }}></View>:null}

        {this.state.selectedType === "Aided"?
        this.renderTitleWithMultipleBtn("NV", this.state.NV_TYPE_options, 5, false, 'selectedAidedNV', this.state.selectedAidedNV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):
        this.state.selectedType === "UnAided"?this.renderTitleWithMultipleBtn("NV", this.state.NV_TYPE_options, 5, false, 'selectedUnAidedNV', this.state.selectedUnAidedNV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):null
        }
        {this.state.selectedType === "Aided"?
        this.renderNewButton(this.inputAidedNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedAidedNV', 'NV_TYPE_options'):
        this.state.selectedType === "UnAided"?this.renderNewButton(this.inputUnAidedNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedUnAidedNV', 'NV_TYPE_options'):null
        } 
        {this.state.selectedType?<View style={{ height: 15 }}></View>:null}
        */}
        
        {/*         
        {this.renderTitleWithMultipleBtn("SPH", this.state.SPH_options, 4, false,
          'selectedSPH', this.state.selectedSPH, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} 
        {this.renderNewButton(this.inputSPH, 'SPH value', 'newSPH', this.state.newSPH, 'isNewSPH', this.state.isNewSPH, 'selectedSPH', 'SPH_options')}
         */}
        
        <RightSectionSPH heading={"SPH"} stateSignKey={"selectedSPHSign"} selectedSign={this.state.selectedSPHSign} disable={this.state.isLocked} 
          stateOptionKey={"selectedSPH"} options={this.state.SPH_options} selectedvalue={this.state.selectedSPH} onPressButton={this.updateState.bind(this)} />

        <View style={{ height: '1vw' }}></View>

        <RightSectionSPH heading={"CYL"} stateSignKey={"selectedCYLSign"} selectedSign={this.state.selectedCYLSign} disable={this.state.isLocked} 
          stateOptionKey={"selectedCYL"} options={this.state.CYL_options} selectedvalue={this.state.selectedCYL} onPressButton={this.updateState.bind(this)} />
        {/*           
        {this.renderTitleWithMultipleBtn("CYL", this.state.CYL_options, 4, false,
          'selectedCYL', this.state.selectedCYL, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        {this.renderNewButton(this.inputCYL, 'CYL value', 'newCYL', this.state.newCYL, 'isNewCYL', this.state.isNewCYL, 'selectedCYL', 'CYL_options')}
         */}
        <View style={{ height: '1vw' }}></View>

        {this.renderTitleWithMultipleBtn("AXIS", this.state.AXIS_options, 4, false,
          'selectedAXIS', this.state.selectedAXIS, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        {/* {this.renderNewButton(this.inputAXIS, 'AXIS value', 'newAXIS', this.state.newAXIS, 'isNewAXIS', this.state.isNewAXIS, 'selectedAXIS', 'AXIS_options')} */}
        <View style={{ height: '1vw' }}></View>


        {/* {this.renderTitleWithMultipleBtn("BCDVA", this.state.BCDVA_options, 4, false,
          'selectedBCDVA', this.state.selectedBCDVA, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        {this.renderNewButton(this.inputBCDVA, 'BCDVA value', 'newBCDVA', this.state.newBCDVA, 'isNewBCDVA', this.state.isNewBCDVA, 'selectedBCDVA', 'BCDVA_options')}
        <View style={{ height: 15 }}></View> */}


        {this.renderTitleWithMultipleBtn("BCDVA", this.state.BCDVA_options, 5, false, 'selectedBCDVA', this.state.selectedBCDVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputBCDVA, 'BCDVA value', 'newBCDVA', this.state.newBCDVA, 'isNewBCDVA', this.state.isNewBCDVA, 'selectedBCDVA', 'BCDVA_options')} 
          <View style={{ height: '1vw' }}></View>
          {this.renderTitleWithMultipleBtn("ADD", this.state.ADD_options, 5, false, 'selectedADD', this.state.selectedADD, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputADD, 'ADD value', 'newADD', this.state.newADD, 'isNewADD', this.state.isNewADD, 'selectedADD', 'ADD_options')} 
          <View style={{ height: '1vw' }}></View>
          {this.renderTitleWithMultipleBtn("BCNVA", this.state.BCNVA_options, 5, false, 'selectedBCNVA', this.state.selectedBCNVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputBCNVA, 'BCNVA value', 'newBCNVA', this.state.newBCNVA, 'isNewBCNVA', this.state.isNewBCNVA, 'selectedBCNVA', 'BCNVA_options')} 
          <View style={{ height: '1vw' }}></View>

        {/* {this.renderTitleWithMultipleBtn("Vision", this.state.VISION_options, 4, false,
          'selectedVision', this.state.selectedVision, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        {this.renderNewButton(this.inputVISION, 'Vision value', 'newVISION', this.state.newVISION, 'isNewVISION', this.state.isNewVISION, 'selectedVision', 'VISION_options')}
        <View style={{ height: 15 }}></View> */}
        
       
        <View style={{ height: '1vw' }}></View>

        {this.renderTitleWithMultipleBtn("Frame Type", Constants.frame_type, 2, false,
          'selectedFrameType', this.state.selectedFrameType, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
        <CommonSectionHeader heading={"Comments"}  />
        <View style={{marginTop:20}}>
          {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4,"",{},"",false,this.state.isLocked)}
        </View>
        {this.renderDatePicker()}

        {this.renderCheckBoxView()}
        
        <View style={{ marginTop: '1.2vw' ,alignSelf:"center"}}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this,this.state.selectedEye, this.state.id)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  renderNewButton=(refInput, placeholder, stateName, stateValue, flagName, flagValue, updateSelectState = "", updateToList)=>{
    var { states } = this.state
    return (
      <View>
        {/* new block */}
        { flagValue && states[flagName] ?
          <View style={{ marginTop: '0.6vw' }}> 
          {this.renderTextFieldViewDrug(placeholder, stateName, stateValue, false, 1, refInput,
            { flag: flagName, newState: stateName, forState: updateSelectState, stateList: updateToList})}
          </View>: null
        }
        <TouchableOpacity onPress={() => {
          if(this.state.selectedEye && Object.keys(this.state.selectedEye).length > 0) { 
           this.triggerNewItemAdd(flagName, refInput) 
          } else {
           this.props.showResposeValue("error", "Select eye type first")
          }
        }} style={{width:'7.8vw'}}>
            {this.renderIconBtn("plus", "New", false, "key")}
        </TouchableOpacity>
        {/* new block end */}
      </View>
    )
  }

  validateObjData=(data)=>{

    return data && (typeof data === "object") && (Object.keys(data).length>0) && (data.value!=null && data.value!==undefined )?true:false
  }


}