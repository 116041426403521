const intialState = {
    historyName : "HIstory"
}

function changeHistoryName(state =  intialState,action) {
    switch(action.type) {
        case "CHANGE_NAME" :
            return {...state,historyName:action.payload};
            default:
                return state;
    }
    

}

export default changeHistoryName