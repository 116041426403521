import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;


export const SubHeading = (props) => {
    return (
        <Text style={[styles.SubHeadingText,{ color: props.showBold ? "#000000" : "#707070" }]}>{props.subHeading}</Text>
    )
}

export const Heading = (props) => {
    return (
        <Text style={styles.HeadingText}>{props.heading}</Text>
    )
}

export const LabelWithTextBox = (props) => {
    return (
        <View style={styles.LabelWithTextBoxView}>
            {
                props.label ?
                    <Text style={styles.LabelWithTextBoxLable}>{props.label}</Text>
                    : null
            }

            <TextInput
               onChangeText={(text)=>{
                props.onChangeGenralTextValue(props.stateName,text);
            }}
                value={props.textBoxValue}
                style={styles.LabelWithTextBoxInput}
            />
        </View>
    )
}

export const TextBoxonly = (props) => {
    return (
        <View style={styles.TextBoxonlyView}>
            

            <TextInput
               onChangeText={(text)=>{
                props.onChangeGenralTextValue(props.stateName,text);
            }}
                value={props.textBoxValue}
                style={styles.TextBoxonlyInput}
            />
        </View>
    )
}

export const LabelBoldWithTextBox = (props) => {
    return (
        <View style={styles.LabelBoldWithTextBoxView}>
            <Text style={styles.LabelBoldWithTextBoxText}>{props.label}</Text>
            <TextInput
                onChangeText={(text)=>{
                    props.onChangeGenralTextValue(props.stateName,text);
                }}
                value={props.textBoxValue}
                style={styles.LabelBoldWithTextBoxInput}
            />
        </View>
    )
}



export const ParagraphContent = (props) => {
    return (
        <Text style={styles.ParagraphContentText}>{props.content}</Text>
    )
}

export const ParagraphContentWithHighlight = (props) => {
    const highlight = string =>
        string.split(' ').map((word, i) => (
            <Text key={i}>
                <Text style={styles.ParagraphContentWithHighlight_HighLightText}>{word} </Text>
            </Text>
        ));

    return (
        <Text style={styles.ParagraphContentWithHighlight_ContentText}>
            {props.content1}
            {highlight(props.highlight1)}
            {props.content2}
            {highlight(props.highlight2)}
            {props.content3}
            {highlight(props.highlight3)}
            {props.content4}
        </Text>
    )
}


export const ParagraphContentWithTextBox = (props) => {
    const TextInput = string =>
        string.split(' ').map((word, i) => (
            <TextInput
            onChangeText={(text)=>{
                props.onChangeGenralTextValue(props.stateName,text);
            }}
            value={props.textBoxValue}
            style={styles.ParagraphContentWithTextBoxInput}
        />
        ));

    return (
        <Text style={styles.ParagraphContentWithTextBoxText}>
            {props.content1}
            {highlight(props.highlight1)}
            {props.content2}
            {highlight(props.highlight2)}
            {props.content3}
            {highlight(props.highlight3)}
            {props.content4}
        </Text>
    )
}





export const Genral = (props) => {
   
    const data=props.generalFormDatas
    //console.log("clinicdetails --------------> "+JSON.stringify(props.clinicdetails))
    // console.log("generalFormDatas --------------> "+JSON.stringify(props.generalFormDatas.relationshipWithPatient))
    
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={styles.MainScrollView}
        >
            <View style={styles.MainViewWrapper}>


                <View style={styles.MainViewCommon}>
                    <LabelWithTextBox label={"Hopital Name:"} textBoxValue={props.clinicdetails.clinic_name} />
                    <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                    <LabelWithTextBox label={"Name:"} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={props.selectedValueClinic_Droctor.value} stateName={"drName"} />
                    <LabelWithTextBox label={"Qualification:"} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={data.drQualification} stateName={"drQualification"}/>
                    <LabelWithTextBox label={"Address:"} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={data.drAddress} stateName={"drAddress"}/>
                    <LabelWithTextBox label={"Phone:"} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={data.drPhone} stateName={"drPhone"}/>
                    <LabelWithTextBox label={"Email:"} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={data.drEmail} stateName={"drEmail"}/>

                    <Heading heading={"Consent Form"} />


                    <SubHeading subHeading={"Information about the patient:"} />

                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={props.clinicdetails.patient_name} />
                    <LabelWithTextBox label={"Age:"} textBoxValue={props.clinicdetails.patient_age} />
                    <LabelWithTextBox label={"Address:"} textBoxValue={props.clinicdetails.patient_address} />

                    <SubHeading subHeading={"Information about the patient’s guardian (proxy consent) :"} />

                    <ParagraphContent content={"(This clause should be filled and the guardian should sign this consent only in case of incompetent patients i.e. minors, old aged, unconscious, mentally unfit, disoriented patients)"} />
                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={data.guardianName}  stateName={"guardianName"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>
                    <LabelWithTextBox label={"Address:"} textBoxValue={data.guardianAge}  stateName={"guardianAge"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>
                    <LabelWithTextBox label={"Phone:"}  textBoxValue={data.guardianPhoneNo}  stateName={"guardianPhoneNo"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                    <LabelWithTextBox label={"Email:"}  textBoxValue={data.guardianEmail}  stateName={"guardianEmail"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                    <LabelWithTextBox label={"Relationship with the patient, if any:"}  textBoxValue={data.relationshipWithPatient}  stateName={"relationshipWithPatient"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>


                    <ParagraphContentWithHighlight
                        content1={"[A person accompanying an unrelated patient should write "}
                        content2={" and when consent is given by higher authorities of a hospital, designation such as "}
                        content3={" or "}
                        content4={" must be written.]"}
                        highlight1={"‘Unrelated-accompanying’"}
                        highlight2={"‘Medical Superintendent’"}
                        highlight3={"‘Medical Director’"}
                    />

                    <SubHeading subHeading={"Scheduled date for the proposed intervention / procedure / surgery:"} />
                    <LabelWithTextBox label={""} onChangeGenralTextValue={props.onChangeGenralTextValue} textBoxValue={data.scheduledSurgeryDate} stateName={"scheduledSurgeryDate"}/>

                    <View style={styles.PatientSigantureView}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.MainViewCommon}>
                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                <LabelWithTextBox label={"Name:"} textBoxValue={data.drInChargeName}  stateName={"drInChargeName"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>
                <LabelWithTextBox label={"Qualification:"} textBoxValue={data.drInChargeQualification}  stateName={"drInChargeQualification"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>

                <SubHeading subHeading={"Name/s of the proposed treatment / intervention / procedure / surgery:"} />
                <LabelWithTextBox label={"a. "} textBoxValue={data.nameOfTheProposedTreatmentA}  stateName={"nameOfTheProposedTreatmentA"} onChangeGenralTextValue={props.onChangeGenralTextValue}/>
                <LabelWithTextBox label={"b. "} textBoxValue={data.nameOfTheProposedTreatmentB}  stateName={"nameOfTheProposedTreatmentB"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                <LabelWithTextBox label={"c. "} textBoxValue={data.nameOfTheProposedTreatmentC}  stateName={"nameOfTheProposedTreatmentC"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                <LabelWithTextBox label={"d. "} textBoxValue={data.nameOfTheProposedTreatmentD}  stateName={"nameOfTheProposedTreatmentD"} onChangeGenralTextValue={props.onChangeGenralTextValue} />


                <SubHeading subHeading={"I, the undersigned, do hereby state and confirm as follows:"} />
                <ParagraphContent content={""} />

                <Text style={styles.NormalTextView}>
                    
                <Text style={styles.NormalText}>I have been explained the following in terms and language that I understand. I have been explained the following in
                <TextBoxonly label={""} textBoxValue={data.language}  stateName={"language"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
               (name of the language or dialect) that is spoken and understood by me.</Text></Text>
                <ParagraphContent content={"2. I have been explained; I have been prov</Text>ided with the requisite information; I have understood; and thereafter I consent, authorize and direct the above named doctor-in-charge / principal surgeon / principal interventionist and his / her team with associates or assistants of his / her choice to perform the proposed treatment / intervention / procedure / surgery mentioned hereinabove."} />
                <ParagraphContent content={"3. Anticipated alternatives / additional - treatment / intervention / procedure / surgery that may have to be performed or attempted during the course of the proposed treatment / intervention / procedure / surgery: I have been explained and have understood that due to unforeseen circumstances duringthecourse of the proposed treatment / intervention / procedure / surgery something more or different than what has been originally planned and for which I am giving this consent may have to be performed or attempted. In all such eventualities, I authorize and give my consent to the medical / surgical team to perform such other and further acts that they may deem fit and proper using their professional judgment."} />
                <ParagraphContent content={"4. Alternatives to the proposed treatment / intervention / procedure / surgery: I have been explained and have understood the alternative methods and therapies of the proposed treatment / intervention / procedure / surgery, their respective benefits, material risks and disadvantages."} />
                <ParagraphContent content={"5. Material risk/s of the proposed treatment / intervention / procedure / surgery: I have been explained and have understood that the proposed treatment / intervention / procedure / surgery has certain material risks / complications and I have been provided with the requisite information about the same. I have also been explained and have understood that there are other undefined, unanticipated, unexplainable risks / complications that may occur during or after the proposed treatment / intervention / procedure / surgery."} />

                <View style={styles.PatientSigantureView}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.MainViewCommon}>

                <ParagraphContent content={"6. I state that the doctor-in-charge / principal surgeon / principal interventionist has answered all my questions to my satisfaction regarding the proposed treatment / intervention / procedure / surgery."} />
                <ParagraphContent content={"7. I have been explained and have understood that despite the best efforts there can be no assurance about the result of the proposed treatment / intervention / procedure / surgery. I further state and confirm that I have not been given any guarantee or warranty about the results of the proposed treatment / intervention / procedure / surgery."} />
                <ParagraphContent content={"8. I have been explained and have understood that despite all precautions complications may occurthatmay even result in death or serious disability."} />
                <ParagraphContent content={"9. I have been advised of the option to take a second opinion from another doctor regarding the proposed treatment / procedure / surgery."} />
                <ParagraphContent content={"10. I state that after explaining, counseling and disclosures I had been given enough time to take decision for giving consent."} />
                <ParagraphContent content={"11. I have signed this consent voluntarily out of my free will and without any kind of pressure or coercion. "} />
               <br/>


                    <LabelBoldWithTextBox label={"Date & Time of giving consent:"}  textBoxValue={data.dateAndTimeGivenConsent}  stateName={"dateAndTimeGivenConsent"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />
                    <SubHeading subHeading={"Patient’s / Guardian’s Signature / Thumb"} />
                    <LabelBoldWithTextBox label={"impression:"}  textBoxValue={data.impression}  stateName={"impression"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />
                    <LabelBoldWithTextBox label={"Patient’s / Guardian’s Name:"}  textBoxValue={data.patientOrGuardianName}  stateName={"patientOrGuardianName"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />

                    <View style={styles.PatientSigantureView}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"}  textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.MainViewCommon}>

                <SubHeading subHeading={"Witnesses:"} showBold={true} />
                <ParagraphContent content={"(Not compulsory. This part should be filled only in high risk cases; or when the patient / patient’s guardian is illiterate or not conversant with English; or when the patient has been unable to personally sign this consent for any reason.)"} />
                <ParagraphContent content={"We confirm that the aforesaid has been explained to the patient / patient’s guardian in the terms and language that the patient / patient’s guardian understand in our presence. We further confirm that the patient / patient’s guardian has put his / her signature / thumb impression on this consent in our presence."} />


                <LabelWithTextBox label={"Witnesses No. 1’s Signature:"} textBoxValue={data.witnessName1}  stateName={"witnessName1"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 1’s Name:"} textBoxValue={data.witnessSignature1}  stateName={"witnessSignature1"} onChangeGenralTextValue={props.onChangeGenralTextValue} />


                <LabelWithTextBox label={"Witnesses No. 2’s Signature:"} textBoxValue={data.witnessName2}  stateName={"witnessName2"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 2’s Name:"} textBoxValue={data.witnessSignature2}  stateName={"witnessSignature2"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />



                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist’s Signature:"} showBold={true} />
                <LabelWithTextBox label={""} textBoxValue={data.drInChargeSignature}  stateName={"drInChargeSignature"} onChangeGenralTextValue={props.onChangeGenralTextValue}  />


                <View style={styles.PatientSigantureView}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeGenralTextValue={props.onChangeGenralTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.ButtonView}>

                    <TouchableOpacity
                        onPress={() => props.creategenralForm()} 
                        style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? '0.9vw' : '1.3vw'} color={color.white} style={styles.Icon}/>
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity disabled={props.formId ? false : true} onPress={()=>{props.genralpreviewAndPrint()}} style={[Style.certificateButton1,{backgroundColor:props.formId ? color.themeDark : color.disableComponentColor}]} >
                        <Icon name={"print"} size={platform === "web" ? '0.9vw' : '1.3vw'} color={color.white} style={styles.Icon}/>
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    MainScrollView:{
        maxHeight: '70vh'
    },
    MainViewWrapper:{
        paddingLeft: '0.97vw', 
        paddingRight: '0.97vw'
    },
    MainViewCommon:{
        marginBottom:'2.6vw', 
        backgroundColor: "#F9FDFD", 
        paddingLeft: '1.95vw', 
        paddingRight: '1.95vw', 
        paddingTop: '1.3vw', 
        paddingBottom: '1.3vw'
    },
    LabelWithTextBoxView:{
        flexDirection: 'row', 
        alignItems: 'center', 
        paddingVertical: '0.32vw'
    },
    LabelWithTextBoxLable:{
        color: "#707070", 
        fontSize: '0.8vw'
    },
    LabelWithTextBoxInput:{
        marginLeft: '0.97vw',
        height: '1.3vw',
        alignself: '#4E4E4E',
        color: 'grey',
        borderStyle: 'dotted',
        borderBottomWidth: 1,
        fontSize:'1vw'
    },
    HeadingText:{
        color: "#000000", 
        alignSelf: 'center', 
        fontSize: '1.17vw', 
        fontWeight: '600', 
        paddingVertical: '0.32vw'
    },
    SubHeadingText:{
        fontSize: '0.91vw', 
        fontWeight: '600', 
        paddingVertical: '0.32vw'
    },
    ParagraphContentText:{
        lineHeight: '1.3vw', 
        color: "#707070", 
        fontSize: '0.8vw', 
        paddingVertical: '0.32vw'
    },
    ParagraphContentWithHighlight_ContentText:{
        lineHeight: '1.3vw', 
        color: "#707070", 
        fontSize: '0.8vw', 
        paddingVertical: '0.32vw'        
    },
    ParagraphContentWithHighlight_HighLightText:{
        color: "#707070", 
        fontSize: '0.9vw', 
        fontWeight: '600'
    },
    TextBoxonlyView:{
        flexDirection: 'row', 
        alignItems: 'center', 
        paddingVertical: '0.32vw'
    },
    TextBoxonlyInput:{
        marginLeft: 0,
        height: '1.3vw',
        alignself: '#4E4E4E',
        color: 'grey',
        borderStyle: 'dotted',
        borderBottomWidth: 1,
    },
    NormalText:{
        fontSize:'0.8vw'
    },
    NormalTextView:{
        flexDirection:'row',
        lineHeight: '0.65vw', 
        color: "#707070", 
        fontSize: '0.8vw', 
        paddingVertical: '0.32vw'
    },
    PatientSigantureView:{
        alignItems: 'flex-end', 
        marginTop: '3.58vw', 
        marginRight: '1.30vw'
    },
    LabelBoldWithTextBoxView:{
        flexDirection: 'row', 
        alignItems: 'center', 
        paddingVertical: '0.32vw'
    },
    LabelBoldWithTextBoxText:{
        color: "#707070", 
        fontSize: '0.9vw', 
        fontWeight: '600'
    },
    LabelBoldWithTextBoxInput:{
        marginLeft: '0.97vw',
        height: '1.3vw',
        alignself: '#4E4E4E',
        color: 'grey',
        borderStyle: 'dotted',
        borderBottomWidth: 1, 
        fontSize:'1vw'     
    },
    ParagraphContentWithTextBoxInput:{
        marginLeft: '0.97vw',
        height: '1.3vw',
        alignself: '#4E4E4E',
        color: 'grey',
        borderStyle: 'dotted',
        borderBottomWidth: 1,
        fontSize:'1vw'
    },
    ParagraphContentWithTextBoxText:{
        lineHeight: '1.3vw', 
        color: "#707070", 
        fontSize: '0.8vw', 
        paddingVertical: '0.32vw'
    },
    ButtonView:{
        flexDirection: 'row', 
        alignSelf: 'center', 
        alignContent: 'center', 
        alignItems: 'center', 
        marginTop: '4.55vw'
    },
    Icon:{
        marginRight:'1vw'
    }

})