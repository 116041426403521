import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { NumberFormat } from '../../../BaseComponent'
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';


class DocPayments extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,

      PaymentDatas: [],
      TotalPayment: 0,

      SpecList: [],
      SelectedSpec: ""

    }

    if (window.innerWidth <= 768) {
      Chart.defaults.global.defaultFontSize = 8
    }
    else if (window.innerWidth <= 1024) {
      Chart.defaults.global.defaultFontSize = 10
    }
    else if (window.innerWidth <= 1440) {
      Chart.defaults.global.defaultFontSize = 12
    }
    else if (window.innerWidth <= 2560) {
      Chart.defaults.global.defaultFontSize = 13
    }
  };
  componentDidMount() {
    this.getSpecialisationList()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getPaymentData()
      })
    }
  }

  /* get Specialization List API start */
  getSpecialisationList() {
    var serviceUrl =
      Constants.Specialisation_List

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getSpecListSuccess,
      this.getSpecListFailure
    );
  }
  getSpecListSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        SpecList: success.data,
        SelectedSpec: success.data[0].role_name
      }, () => { this.getPaymentData() })
    }else{
      this.props.Response(success.message)
    }
  }
  getSpecListFailure = (error) => {
    this.props.Response(error.message)
  }
  /* get Specialization List API end */

  /* get Doc Payment data API start */
  getPaymentData() {
    var serviceUrl =
      Constants.DOCTOR_PAYMENTS +
      "?specialisation=" +
      this.state.SelectedSpec +
      "&start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getPaymentSuccess,
      this.getPaymentFailure
    );

  }

  getPaymentSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        PaymentDatas: success.data.doctor,
        TotalPayment: success.data.total
      });
    }else{
      this.props.Response(success.message)
    }
  };

  getPaymentFailure = (error) => {
    this.props.Response(error.message)
  };
  /* get Doc Payment data API end */

  /* Export Excel API start*/
  getDoctorPaymentsExcel = () => {
    var serviceUrl =
      Constants.DOCTOR_PAYMENT_EXPORT +
      "?specialisation=" +
      this.state.SelectedSpec +
      "&start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getDoctorPaymentsSuccess,
      this.getDoctorPaymentsFailure,
      "excel"
    );
  }
  getDoctorPaymentsSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getDoctorPaymentsFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/

  render() {
    const data = {
      datasets: [
        {
          data: this.state.PaymentDatas.length > 0 ? this.state.PaymentDatas.map((list) => (list.amount)) : [],
          backgroundColor: [
            'rgb(168, 50, 155)',
            'rgb(97, 207, 126)',
            'rgb(255, 99, 132)',
            'rgb(204, 255, 153)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(204, 255, 153)',
          ],
        },
      ],
      labels: this.state.PaymentDatas.length > 0 ? this.state.PaymentDatas.map((list) => (list.name)) : [],
    };

    const Specialisation = (spec) => {
      if (spec == "DOCTOR") {
        return "General Physician"
      }
      else if (spec == "DB") {
        return "Diabetology"
      }
      else if (spec == "PD") {
        return "Pediatric"
      }
      else if (spec == "OBGYN") {
        return "Gynaecology"
      }
      else if (spec == "PSY") {
        return "Psychiatric"
      }
      else if (spec == "OPT") {
        return "Ophthalmology"
      }
      else {
        return spec
      }
    }


    return (
      <View>

        <View style={styles.TopView}>
          <View style={styles.RoleNameView}>
            <Text style={styles.RoleNameText}>{Specialisation(this.state.SelectedSpec)}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Picker
              selectedValue={this.state.SelectedSpec}
              style={styles.PickerStyle}
              onValueChange={(itemvalue) => {
                this.setState({
                  SelectedSpec: itemvalue
                }, () => { this.getPaymentData() })
              }}
            >
              {this.state.SpecList.length > 0 ? this.state.SpecList.map((list) => (
                <Picker.Item label={Specialisation(list.role_name)} value={list.role_name} />
              )) : null}
            </Picker>
            <View style={{ marginTop: '1vw' }}>
              <TouchableOpacity onPress={this.getDoctorPaymentsExcel}>
                <Tooltip title={'Export to Excel'}>
                  <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
                </Tooltip>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {this.state.TotalPayment > 0 ?
          <View >
            <Doughnut
              width={'15vw'}
              height={'9vw'}
              data={data}
              options={
                {
                  title: {
                    position: "bottom",
                    display: true,
                    text: ['Doctor Payments'],
                    fontColor: ['rgb(4, 183, 177)']
                  },
                  responsive: true
                }}

            />
            <View style={styles.TotalAmountView}>
              <Text style={styles.TotalAmountText}>Total : ₹ </Text>
              <Text style={styles.TotalAmountValue}>{NumberFormat(this.state.TotalPayment)}</Text>
            </View>
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Doctor Payments</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

      </View>

    )
  }
}

export default DocPayments;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '7vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PickerStyle: {
    height: "2.27vw",
    marginRight: '2vw',
    margin: '0.32vw',
    backgroundColor: "transparent",
    borderRadius: '0.26vw',
    flex: 0.5,
    borderColor: 'rgb(4, 183, 177)',
    width: '10vw',
    fontSize: '0.91vw',
    zIndex: 10,
  },
  RoleNameView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10vw'
  },
  RoleNameText: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold',
  },
  TopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5vw'
  }
})