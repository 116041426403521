import React, { Component } from 'react';
import {
    View, Text, StyleSheet,
    TextInput, CheckBox,
    Image, TouchableOpacity
} from 'react-native';
import { CommonHistorySectionHeader } from '../../BaseComponent';
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg';
import { Constants } from '../../../../utils/Constants';
import Service from '../../../../network/OpthamologyService';
import { Tooltip } from 'antd';
import { color } from '../../../../styles/Color';
import Pagination from '../Pagination';


export default class DoctorQmsConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qmsDoctorData: [],
            paginationData: [],
            isEdit: false,
            selectedIndex: "",
            doctor_config_id: "",
            userType: this.props.userType,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
            current_page: 1,
            records_per_page: 10
        }
    }
    componentDidMount() {
        this.getQmsDoctorConfig()
    }
    getQmsDoctorConfig = () => {
        this.setState({
            qmsDoctorData: [],
            paginationData: [],
            current_page: 1,
            records_per_page: 10
        })
        try {
            let url = "";
            let states = this.state
            if (this.state.userType === Constants.ROLE_FO) {
                url = Constants.QMS_DOCTOR_CONFIGURATION_GET;
            } else {
                url = Constants.QMS_DOCTOR_CONFIGURATION_GET + "?clinic_id=" + states.selectedClinicOrDoctor.id
            }

            Service.getInstance().getComplaints(
                url,
                this,
                this.doctorConfigSuccess,
                this.doctorConfigFailure
            )
        } catch (e) {
            let message = "Cannot Able to get Data"
            this.props.showResposeValue("error", message)
        }
    }
    doctorConfigSuccess = (response) => {
        if (response.status === 'success') {
            this.setState({ qmsDoctorData: response.data })
        }
    }
    doctorConfigFailure = () => {
        this.props.showResposeValue("error", response.message)
    }
    postQmsDoctorConfig = (index) => {
        try {
            let url = ""
            let data = {}
            if (this.state.userType === Constants.ROLE_FO) {
                url = Constants.QMS_DOCTOR_CONFIGURATION_POST;
                let qmsDoctorData = this.state.qmsDoctorData;
                data = {
                    "doctor_id": qmsDoctorData[index].doctor_id,
                    "token_config": qmsDoctorData[index].token_config,
                    "room_config": qmsDoctorData[index].room_config,
                    "notes": qmsDoctorData[index].notes,
                    "availability": qmsDoctorData[index].availability
                }
            } else {
                url = Constants.QMS_DOCTOR_CONFIGURATION_POST;
                let qmsDoctorData = this.state.qmsDoctorData;
                data = {
                    "clinic_id": this.state.selectedClinicOrDoctor.id,
                    "doctor_id": qmsDoctorData[index].doctor_id,
                    "token_config": qmsDoctorData[index].token_config,
                    "room_config": qmsDoctorData[index].room_config,
                    "notes": qmsDoctorData[index].notes,
                    "availability": qmsDoctorData[index].availability
                }
            }
            Service.getInstance().postComplaints(
                url,
                data,
                this,
                this.qmsDoctorDataSuccess,
                this.qmsDoctorDataFailure
            )
        } catch (e) {
            let message = "Doctor Configuration Not Created"
            this.props.showResposeValue("error", message)
        }
    }
    qmsDoctorDataSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.getQmsDoctorConfig()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    qmsDoctorDataFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }
    putQmsDoctorConfig = (index) => {
        try {
            let url = "";
            let data = {}
            if (this.state.userType === Constants.ROLE_FO) {
                url = Constants.QMS_DOCTOR_CONFIGURATION_PUT;
                let qmsDoctorData = this.state.qmsDoctorData;
                data = {
                    "doctor_config_id": qmsDoctorData[index].doctor_config_id,
                    "token_config": qmsDoctorData[index].token_config,
                    "room_config": qmsDoctorData[index].room_config,
                    "notes": qmsDoctorData[index].notes,
                    "availability": qmsDoctorData[index].availability
                }
            } else {
                url = Constants.QMS_DOCTOR_CONFIGURATION_PUT;
                let qmsDoctorData = this.state.qmsDoctorData;
                data = {
                    "clinic_id": this.state.selectedClinicOrDoctor.id,
                    "doctor_config_id": qmsDoctorData[index].doctor_config_id,
                    "token_config": qmsDoctorData[index].token_config,
                    "room_config": qmsDoctorData[index].room_config,
                    "notes": qmsDoctorData[index].notes,
                    "availability": qmsDoctorData[index].availability
                }
            }
            Service.getInstance().putMethod(
                url,
                data,
                this,
                this.putQmsDoctorConfigSuccess,
                this.putQmsDoctorConfigFailure
            )

        } catch (e) {
            let message = "Doctor Configuration Not Updated"
            this.props.showResposeValue("error", message)
        }
    }
    putQmsDoctorConfigSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.setState({ isEdit: false })
            this.getQmsDoctorConfig()

        } else {
            this.props.showResposeValue("success", response.message)
        }
    }
    putQmsDoctorConfigFailure = (response) => {
        this.props.showResposeValue("success", response.message)
    }
    onChangeConfig = (text, index, key, type) => {
        try {
            let { qmsDoctorData, current_page, records_per_page } = this.state;
            let prev_index = (current_page - 1) * records_per_page;

            switch (type) {
                case "Text":
                    qmsDoctorData[prev_index + index][key] = text;
                    this.setState({ qmsDoctorData })
                    break;
                case "CheckBox":
                    qmsDoctorData[prev_index + index][key] = !qmsDoctorData[prev_index + index][key];
                    this.setState({ qmsDoctorData })
                    break;
                default:
                    break;
            }
        } catch (err) {
            let message = `Error occured on ${type}`
            this.props.showResposeValue("error", message)
        }
    }
    validateSpecialCharacter(num) {
        var rgx = /^(([a-z\d]+\s)?[a-z\d]+)?$/i;
        return num.match(rgx);
    }
    paginationChangedValues(data) {
        this.setState({
            paginationData: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    render() {
        let { paginationData } = this.state;
        return (
            <View style={Styles.DoctorConfigCard}>
                <CommonHistorySectionHeader
                    heading1={"Specialization"}
                    heading2={"Doctor Name"}
                    heading3={"Token Config"}
                    heading4={"Room Config"}
                    heading5={"Notes"}
                    heading6={"Avalibility"}
                    heading7={"Action"}
                    noOfColumn={7}
                    columnSize={[0.12, 0.16, 0.12, 0.103, 0.257, 0.104, 0.1]}
                />
                {
                    paginationData && paginationData.length > 0 ? (
                        paginationData.map((item, index) => (
                            <View key={item.doctor_id} style={[Styles.TableBody, { backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead }]} >
                                <View style={Styles.FlexOne}>
                                    <Tooltip placement='topLeft' title={item.specialization} >
                                        <Text style={Styles.Font}>
                                            {(item.specialization && item.specialization.length > 10) ? (item.specialization.slice(0, 10) + "...")
                                                : (item.specialization)
                                            }
                                        </Text>
                                    </Tooltip>
                                </View>
                                <View style={Styles.FlexTwo}>
                                    <Tooltip placement='top' title={item.doctor_name}>
                                        <Text style={{ fontSize: "1vw", color: "gray", position: "relative", top: "0.3vw" }}>
                                            {(item.doctor_name && item.doctor_name.length > 15)? (item.doctor_name.slice(0, 15) + "...")
                                                : (item.doctor_name)
                                            }</Text>
                                    </Tooltip>
                                </View>
                                <View style={Styles.FlexThree}>
                                    <TextInput
                                        maxLength={4}
                                        style={{
                                            width: "8vw", height: "2vw", borderRadius: "5px", paddingHorizontal: "0.3vw",
                                            marginLeft: "0.5vw", fontSize: "1vw",
                                            border: (this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "")
                                                ? "1px solid gray" : "none"
                                        }}
                                        value={item.token_config}
                                        onChangeText={(text) => {
                                            let isValid = this.validateSpecialCharacter(text)
                                            if (isValid) {
                                                this.setState({
                                                    selectedIndex: index
                                                })
                                                this.onChangeConfig(text, index, "token_config", "Text")
                                            }
                                        }}
                                        editable={(this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "") ? true : false}
                                    />
                                </View>
                                <View style={Styles.FlexFour}>

                                    <TextInput
                                        maxLength={4}
                                        style={{
                                            width: "8vw", height: "2vw", borderRadius: "5px", paddingHorizontal: "0.3vw",
                                            marginLeft: "0.3vw", fontSize: "1vw",
                                            border: (this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "")
                                                ? "1px solid gray" : "none"
                                        }}
                                        value={item.room_config}
                                        onChangeText={(text) => {
                                            let isValid = this.validateSpecialCharacter(text)
                                            if (isValid) {
                                                this.setState({
                                                    selectedIndex: index
                                                })
                                                this.onChangeConfig(text, index, "room_config", "Text")
                                            }
                                        }}
                                        editable={(this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "") ? true : false}
                                    />
                                </View>

                                <View style={Styles.FlexFive}>
                                    {(this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "") ?
                                        <View>
                                            <TextInput
                                                multiline={true}
                                                style={{
                                                    width: "19vw", height: "2vw", paddingHorizontal: "0.3vw",paddingTop:"0.3vw",
                                                    borderRadius: "5px", marginLeft: "0.3vw", fontSize: "1vw",
                                                    border: "1px solid gray"
                                                }}
                                                value={item.notes}
                                                onChangeText={(text) => {
                                                    this.setState({ selectedIndex: index })
                                                    this.onChangeConfig(text, index, "notes", "Text")

                                                }}
                                                editable={(this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "") ? true : false}
                                            />
                                        </View> : <View>
                                            <Tooltip placement='topLeft' title={item.notes}>
                                                <Text style={{ color: "gray", position: "relative", top: "0.3vw", paddingLeft: "0.3vw", fontSize: "1vw" }}>
                                                    {item.notes.length > 35 ? (item.notes.slice(0, 35) + "...") : (item.notes)}
                                                </Text>
                                            </Tooltip>
                                        </View>
                                    }

                                </View>
                                <View style={Styles.FlexSix}>
                                    <CheckBox
                                        style={Styles.CheckBoxStyle}
                                        value={item.availability}
                                        checked={item.availability}
                                        onChange={(text) => { this.onChangeConfig("", index, "availability", "CheckBox") }}
                                        disabled={(this.state.isEdit && item.doctor_config_id != "" && this.state.selectedIndex === index) || (item.doctor_config_id === "") ? false : true}
                                    />
                                </View>
                                <View style={[item.doctor_config_id ? Styles.FlexSeven : Styles.EditActive]}>
                                    <TouchableOpacity
                                        style={{}}
                                        onPress={() => {
                                            this.setState({
                                                isEdit: true,
                                                selectedIndex: index
                                            })
                                        }}
                                    >
                                        {item.doctor_config_id ? <Image
                                            source={EditIcon}
                                            style={Styles.ImageStyle}
                                            resizeMode="contain"
                                        /> : null}

                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={Styles.ButtonStyle}
                                        onPress={() => {
                                            this.state.isEdit !== true ? this.postQmsDoctorConfig(index) : this.putQmsDoctorConfig(index)
                                        }}
                                    >
                                        <Text style={Styles.ButtonText}>Save</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={Styles.ResetStyle}
                                        onPress={() => {
                                            if (item.doctor_config_id) {
                                                this.getQmsDoctorConfig()
                                                this.setState({ isEdit: false })
                                            } else {
                                                let { qmsDoctorData, current_page, records_per_page } = this.state;
                                                let prev_index = (current_page - 1) * records_per_page;
                                                qmsDoctorData[prev_index + index].notes = ""
                                                qmsDoctorData[prev_index + index].token_config = ""
                                                qmsDoctorData[prev_index + index].room_config = ""
                                                this.setState({
                                                    qmsDoctorData
                                                })
                                            }

                                        }}>
                                        <Text style={Styles.ResetText}>Reset</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        ))
                    ) : (
                        <View style={Styles.NoDataStyle}>
                            <Text style={Styles.NoDataText}>{"No Records To Be Shown"}</Text>
                        </View>
                    )
                }
                <View>
                    {
                        this.state.qmsDoctorData && this.state.qmsDoctorData.length > 0 ?
                            <Pagination
                                paginationChangedValues={this.paginationChangedValues.bind(this)}
                                totalItems={this.state.qmsDoctorData}
                                paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                            /> : null
                    }
                </View>
            </View>
        )
    }
}

const Styles = StyleSheet.create({
    DoctorConfigCard: {
        height: "32vw",
        fontSize: "1vw"
    },
    TableHeader: {
        textAlign: "center"
    },
    TableBody: {
        flexDirection: 'row',
        paddingVertical: 8,
        fontSize: "1vw"
    },
    CheckBoxStyle: {
        position: "relative",
        top: "0.7vw",
        width: "1vw",
        height: "1vw"
    },
    ImageStyle: {
        height: "2vw",
        width: "2vw",
        position: "relative",
        top: "0.2vw"
    },
    ButtonStyle: {
        borderRadius: "5px",
        height: "1.8vw",
        width: "3.5vw",
        backgroundColor: "#04b7b1",
        marginLeft: "1.5vw",
        position: "relative",
        top: "0.3vw"
    },
    ButtonText: {
        textAlign: "center",
        position: "relative",
        top: "0.2vw",
        color: "#FFFFFF",
        fontSize: "1vw"
    },
    ResetStyle: {
        borderRadius: "5px",
        height: "1.8vw",
        width: "3.5vw",
        backgroundColor: "#04b7b1",
        marginLeft: "1.5vw",
        position: "relative",
        top: "0.3vw"
    },
    ResetText: {
        textAlign: "center",
        position: "relative",
        top: "0.2vw",
        color: "#FFFFFF",
        fontSize: "1vw"
    },
    FlexOne: {
        flex: 0.13
    },
    FlexTwo: {
        flex: 0.15,
        paddingLeft: "0.2vw"
    },
    FlexThree: {
        flex: 0.12
    },
    FlexFour: {
        flex: 0.1
    },
    FlexFive: {
        flex: 0.25
    },
    FlexSix: {
        flex: 0.1,
        paddingLeft: "0.3vw"
    },
    FlexSeven: {
        flexDirection: "row",
        flex: 0.15
    },
    Font: {
        fontSize: "1vw",
        paddingLeft: "1.5vw",
        color: "gray",
        position: "relative",
        top: "0.3vw"
    },
    EditActive: {
        flex: 0.15,
        position: "relative",
        flexDirection: "row",
        right: "1.3vw"
    },
    NoDataStyle: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        minHeight: 100
    },
    NoDataText: {
        color: color.themeDark,
        marginTop: 50,
        fontSize: "1.5vw"
    },
    isEditActive: {
        backgroundColor: color.appointmentTableHead
    }
})