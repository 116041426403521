

import React, { Component } from "react";
import {
    View,
    Text,
    // Button,
    Dimensions,
    FlatList,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    TextInput,
} from "react-native";
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import Remove from "react-native-vector-icons/Entypo";
import { DatePicker } from 'antd';
import moment from "moment";
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import { CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton, NavigationTopHeader } from '../../BaseComponent'
import Cancel from "react-native-vector-icons/Entypo";
import 'antd/dist/antd.css';
import OpthamologyService from "../../../../network/OpthamologyService";
import Success from "react-native-vector-icons/FontAwesome5";
import { LaboratoryPatientList } from "../laboratoryPatientList";
import { SearchBar } from 'react-native-elements';
import AsyncStorage from "../../../../AsyncStorage";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;


const navigationHeaderListIfIPCreateBill = [
    { label: "Create Bill", value: "laboratoryCreateBill" },
    { label: "Billing Transaction", value: "laboratoryBillingTransaction" },
]

// const navigationHeaderList = [
//     { label: "Result", value: "laboratoryResult" },
//     { label: "Previous Reports ", value: "laboratoryPreviousResult" },
//     { label: "Create Bill", value: "laboratoryCreateBill" },
//     { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
//   ]

// create a component
export class LaboratoryCreateBill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIpPatientDetails: this.props.selectedIpPatientDetails,
            patientInfo: this.props.patientInfo,
            isCurrentPageIsIPPatientAppointment: this.props.isCurrentPageIsIPPatientAppointment,
            userType: this.props.userType,
            ip_number:this.props.patientInfo.ip_number ? this.props.patientInfo.ip_number : 0,
            selectedLabPatientDetails: this.props.selectedLabPatientDetails,

            // "selectedDescription",
            selectedDescription: {},
            showDescriptionNewButtonFlag: true,
            billingDescriptionList: [],

            newButtonValue: "",

            enterUnits: 1,
            enterRatePerUnits: "",
            enterTotalAmount: "",

            discountPersentage: 0,
            discountAmount: 0.00,
            discount: 0.00,
            roundOff: 0.00,
            selectedPaymentType: "",
            paymentTypeTotalAmount: 0.00,
            paymentTypeCashAmount: 0.00,
            paymentTypeCardAmount: 0.00,
            paymentTypeCardNumber: "",

            billingList: [],
            bedChargeTotalAmount: 0.00,
            billingType: this.props.billingType,

            invoiceDate: moment(new Date()).format("YYYY-MM-DD"),
            selectedLabSet: {},
            selectedLabSetDetails: {},

            showLabSetNewButtonFlag: true,
            listOfLabSet: [],
            cardNumber: "",
            receviedAmount: 0.00,
            balance: 0.00,
            amount: 0.00,
            isDisabled: false,

            totalAmount: 0.00,
            netAmount: 0.00,
            PaymentTaotalAmount: 0.00,

            upiTransactionID:"",

            overallTotalamount: "",
            overallPaidamount: "",
            overallBalance: "",

            billingDescriptionListBackup: [],
            billingType: this.props.billingType,
            testName: "",
            referByName: this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.transmit_id ?this.props.selectedLabPatientDetails.doctor_name:(this.props.selectedLabPatientDetails?this.props.selectedLabPatientDetails.reffered_by:""),
            getLaborTest: [],

            searchListOfTestName: [],
            selectedLabTest: {},
            ispatientHome: this.props.isLabpatientHome,
            selectedLabTestName: {},
            getListOfLabTestName: [],
            transmiteID: this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.transmit_id ? this.props.selectedLabPatientDetails.transmit_id : "",
            bill_summary_id:
                this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.bill_summary_id ? this.props.selectedLabPatientDetails.bill_summary_id : "",
            patientId:
                this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.isSearchedPatient ?
                    this.props.selectedLabPatientDetails.patientDetails.patient_id :
                    this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.patient_id ? this.props.selectedLabPatientDetails.patient_id :
                    this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.patientDetails && this.props.selectedLabPatientDetails.patient_id ? this.props.selectedLabPatientDetails.patientDetails.patient_id : "",

            getTransmitedTestNames: [],
            noUnits: 1,

            is_disc_percent_changed: true,
            billStatus:"",
            insTransactionNumber: "",
            insPolicyNumber: "",
            insCompanyName: "",
            insValidity: "",
            billTypeCompanyName:"",
            billTypeEmployeeID:"",
            selectedBillType:"",
            labSetSearchTxt: "",
            doc_reg_no: "",
            ref_doctor_list: [],
            filtered_ref_doctor_list: [],
            LabId: "",
            // Bill Modification
            isBillModification: false,
            modificationBillDetails: {},
            modifyBillDetails: [],
            modifyBillSummaryDetails: {},
            modifyBillPatientDetails: {},
            isAdmin: false,
            isUpdate: true,
            invoiceNumber: "",

            disableDiscountPercent: false,
            EmployerID:""
        };
    }


    componentWillReceiveProps(nextProps) {
        if(this.state.patientInfo !=  nextProps.patientInfo){
         this.setState({
              patientInfo: nextProps.patientInfo
        })
        if(!this.props.istransmit){
            this.setState({
                ip_number: nextProps.patientInfo.ip_number ? nextProps.patientInfo.ip_number : 0
            })
        }
        }

        if (nextProps.selectedLabPatientDetails.transmit_id !== this.state.selectedLabPatientDetails.transmit_id) {
            if (this.state.selectedLabPatientDetails && this.state.selectedLabPatientDetails.tests && this.state.selectedLabPatientDetails.tests.length > 0) {
                this.setState({ selectedLabPatientDetails: nextProps.selectedLabPatientDetails })
                this.getTestConfigList()
            }
        }
        if (nextProps.bedChargeTotalAmount) {
            let { billingList } = this.state;
            this.state.bedChargeTotalAmount = nextProps.bedChargeTotalAmount;
            billingList.push(
                {
                    Description: "Bed Charge",
                    RatePerUnits: "0",
                    Units: "0",
                    TotalAmount: nextProps.bedChargeTotalAmount
                }
            );
            billingList = this.removeDuplicate(billingList)
            this.setState({ billingList }, () => {
                var amount = this.state.billingList.reduce(function (sum, current) {
                    return parseInt(sum) + parseInt(current.TotalAmount);
                }, 0);


                this.setState({
                    totalAmount: amount
                }, () => {
                    this.netAmountCalculation()
                })

                this.props.sendBedChargeTotalAmount("")
            })

        }
    }




    getTestConfigList() {
        var service_url = Constants.LAB_TEST_CONFIG;
        OpthamologyService.getInstance().getComplaints(
            service_url, this,
            this.getTestConfigListSuccess,
            this.getTestConfigListFailure
        );
    }

    getTestConfigListSuccess = success => {


        if (success.status == "success") {

            var { getListOfLabTestName } = this.state
            // 

            var getTransmitedTestNames = this.state.selectedLabPatientDetails && this.state.selectedLabPatientDetails.tests && this.state.selectedLabPatientDetails.tests.length > 0 ?
                this.state.selectedLabPatientDetails.tests : []
            var listOfLabSets = success.data;
            var listOfTestName = success.lab_test_config

            if (listOfLabSets.length > 0) {

                var getAvailableLabTests = []


                for (let i = 0; i < listOfLabSets.length; i++) {
                    var labSetName = listOfLabSets[i].name;

                    var latTestDetails = listOfTestName[labSetName];

                    if (latTestDetails.length > 0) {


                        for (let j = 0; j < latTestDetails.length; j++) {
                            for (let k = 0; k < getTransmitedTestNames.length; k++) {
                                if (latTestDetails[j].name == getTransmitedTestNames[k].name) {

                                    var preparData = {
                                        "test_name": latTestDetails[j].name,
                                        "amount": latTestDetails[j].amount,
                                        "lab_set_id": latTestDetails[j].lab_set_id,
                                        "lab_test_id": latTestDetails[j].lab_test_id
                                    }

                                    getAvailableLabTests.push(preparData)

                                }

                            }

                        }


                    }

                }



                // getListOfLabTestName.push(preparData);
                getListOfLabTestName = getAvailableLabTests
                this.setState({
                    getListOfLabTestName
                }, () => {
                    var amount = this.state.getListOfLabTestName.reduce(function (sum, current) {
                        return (parseFloat(sum) + parseFloat(current.amount)).toFixed(2);
                    }, 0);

                    this.setState({
                        totalAmount: amount,

                    })

                    this.clearRightSideDetails()

                })



            }

        }
    }

    getTestConfigListFailure = error => {
        //alert(JSON.stringify(error))
    }




    async componentDidMount() {
        const labId = await AsyncStorage.getItem("UserID");
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
      
        this.setState({
            LabId: labId,
            isAdmin: loggedInDataObj.is_admin_access
        })  
        if (this.state.selectedLabPatientDetails && this.state.selectedLabPatientDetails.tests && this.state.selectedLabPatientDetails.tests.length > 0) {

            this.getTestConfigList()
        }

        this.getLabsetDetails();

        if (this.props.istransmit) {
            this.getLabSaveBilling();
        }
        this.getReferrenceDoctorName()
        if(this.props.modificationBillDetails && Object.keys(this.props.modificationBillDetails).length > 0) {
            this.setState({
              bill_summary_id: this.props.modificationBillDetails.bill_summary_id,
              isBillModification: true,
              modificationBillDetails: this.props.modificationBillDetails
            },() => {
                if(!this.props.isModifyBillUpdate){
                    this.getBillMidificationList()
                }
            })
          }
    }

    getLabsetDetails = async () => {
        let states = this.state;
        var serviceUrl = Constants.BILLING_LAB_SET_NAME+"?search_data="+this.state.labSetSearchTxt;
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getLabsetDetailsSuccess,
            this.getLabsetDetailsFailure
        );
    }

    getLabsetDetailsSuccess = success => {
        // alert("success --------> " + JSON.stringify(success))
        //console.log("success --------> " + JSON.stringify(success))
        if (success.status == "success") {
            var data = []
            var getLabset = success.data;

            for (var i = 0; i < getLabset.length; i++) {
                var preparData = {
                    label: getLabset[i].name, value: getLabset[i].name, amount: getLabset[i].amount,
                    lab_set_id: getLabset[i].lab_set_id
                }
                data.push(preparData)
            }
            this.setState({
                // billingDescriptionList: success.data.billing_template,
                // billingDescriptionListBackup: success.data.billing_template,
                listOfLabSet: data
            })
        }
    }
    getLabsetDetailsFailure = error => {
    }
    getReferrenceDoctorName = (doctor_name,key) => {

        var serviceUrl = Constants.LAB_REFRRENCE_DOCTOR_LIST 
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getReferrenceDoctorNameSuccess,
            this.getReferrenceDoctorNameFailure
        );
    }

        getReferrenceDoctorNameSuccess = success => {
            if (success.status == "success") {
                this.setState({
                    ref_doctor_list:success.data,
                    filtered_ref_doctor_list: success.data
                })
                console.log(this.state.ref_doctor_list)
            }
        }
        getReferrenceDoctorNameFailure = error => {
            if (error.status == "error") {
            console.log('error')
        }
    }
    alphabetvalidation(num) {
        return !/[^.[A-Z a-z]]*/.test(num);
      }
    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    ratePerUnitTxtBoxValidation(selectedValue, arrayList, defaultValue) {

        var status = defaultValue;
        if (arrayList.length > 0 && selectedValue && selectedValue.value) {
            for (var i = 0; i < arrayList.length; i++) {
                var name = arrayList[i].service_name;
                // alert(arrayList[i].service_name)
                // alert(selectedValue.value)

                if (name == selectedValue.value) {
                    status = true;
                    // break;
                } else {
                    status = false;
                    // break;
                }
            }
        }

        return status;

    }



    renderTextBox(placeholder, key, value) {
        var { billingDescriptionListBackup, selectedDescription } = this.state;
        var checkBoxIsEditable = (
            (key == "enterTotalAmount" &&
                this.state.selectedDescription.value == "Advance payment"
            ) ||
            (key == "enterRatePerUnits" && this.state.selectedDescription && this.state.selectedDescription.isNew) ||
            (key == "enterUnits" && this.state.selectedDescription && this.state.selectedDescription.value !== "Advance payment")
        ) ? true : false

        if (key == "enterRatePerUnits") {
            checkBoxIsEditable = this.ratePerUnitTxtBoxValidation(selectedDescription, billingDescriptionListBackup, checkBoxIsEditable)
        }




        return (
            <View>
                <TextInput

                    style={{
                        backgroundColor: checkBoxIsEditable ? null : "#F0F0F0",
                        paddingLeft: 10, marginLeft: 15, borderWidth: 1, height: 35, width: 150, marginVertical: 20, borderRadius: 4, borderColor: "#888888",
                    }}
                    value={value}
                    editable={checkBoxIsEditable}
                    placeholder={placeholder}
                    onChangeText={(text) => {
                        if (this.numberValidation(text)) {
                            var states = this.state;
                            states[key] = text
                            this.setState({
                                states
                            }, () => {
                                if (key == "enterUnits" && this.state.selectedDescription.value !== "Advance payment" || key == "enterRatePerUnits") {
                                    this.calculateTotalAmount()
                                }
                            })
                        }
                    }}
                />
            </View>
        )
    }





    changeTabSection(data) {
        // this.props.changeScreen(data, {}, "", {}, "", "")
        this.props.changeScreen(data, {}, this.state.userType, {}, "", {}, "", {}, "", {}, false, true);

    }

    renderAmountValueInCard(amount, amounttype) {
        return (
            <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                // alignItems: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white, height: 50,
                borderRadius: 4,
                paddingHorizontal: 15,
                minWidth: 100,
                marginRight: 20
            }}>
                <Text style={{ fontSize: 14, fontWeight: '500' }}>{amount ? "₹ " : null}{amount}</Text>
                <Text style={{ fontSize: 12, fontWeight: '400', textAlign: 'right', marginTop: 10 }}>{amounttype}</Text>

            </View>
        )
    }



    onPressButton(key, selectedValue) {
        var states = this.state;
        // alert(JSON.stringify(selectedValue))   


        if (key == "showDescriptionNewButtonFlag") {
            states[key] = false;
            states["showLabSetNewButtonFlag"] = true

        } else if (key == "selectedDescription") {
            if (selectedValue.value == "Advance payment" || selectedValue.value == "Bed Charges") {
                states["enterRatePerUnits"] = 0;
                states["enterUnits"] = 0
            } else {
                states["enterRatePerUnits"] = selectedValue.label;
                states["enterUnits"] = this.state.enterUnits
            }
            if (selectedValue.value == "Bed Charges") {
                this.getRoomDetails()
            } else {
                states[key] = selectedValue;
            }
        } else if (key == "showLabSetNewButtonFlag") {
            states[key] = false;

        }

        else if (key == "selectedDescription") {
            states[key] = selectedValue;
        } else if (key == "selectedLabSet") {
            states[key] = selectedValue;
            // alert(JSON.stringify(selectedValue))
            states["selectedLabSetDetails"] = {
                "lab_set_id": selectedValue.lab_set_id,
                "lab_test_id": null,
                "test_name": selectedValue.value,
                "amount": selectedValue.amount
            }
        }


        this.setState({ states }, () => {
            this.calculateTotalAmount()
        })

    }

    calculateTotalAmount() {
        var { enterUnits, enterRatePerUnits } = this.state;
        var totalAmount = enterRatePerUnits * enterUnits;
        this.setState({ enterTotalAmount: totalAmount })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        // value:item.service_name,label:item.rate_per_unit
        var { billingDescriptionList, listOfLabSet } = this.state;
        var states = this.state;
        var preparData = {}
        var selectedData = {}
        if (!this.state.showDescriptionNewButtonFlag) {
            preparData = { service_name: this.state.newButtonValue, rate_per_unit: this.state.enterRatePerUnits }
            selectedData = { value: this.state.newButtonValue, label: "", type: "isNew" }
        } else {
            preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
            selectedData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
        }


        if (!this.state.showDescriptionNewButtonFlag) {
            billingDescriptionList.push(preparData);
            billingDescriptionList = this.removeDuplicate(billingDescriptionList);
            states["newButtonValue"] = "";
            states["showDescriptionNewButtonFlag"] = true;
            states["selectedDescription"] = selectedData;
        }


        if (!this.state.showLabSetNewButtonFlag) {
            listOfLabSet.push(preparData);
            listOfLabSet = this.removeDuplicate(listOfLabSet);
            states["newButtonValue"] = "";
            states["showLabSetNewButtonFlag"] = true;
            states["selectedLabSet"] = selectedData;
        }

        this.setState({
            billingDescriptionList, states, listOfLabSet
        })

    }


    descriptionMultiButtons(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={styles.LabSetSuggView}>
                {
                    data.map((item, index) => {
                        var listData = {}
                        if (key == "selectedDescription") {
                            listData = { value: item.service_name, label: item.rate_per_unit }
                        } else {
                            listData = item
                        }

                        return (
                            <View style={styles.LabSetButton} key={index}>
                                <CommonButton
                                    item={listData}
                                    selectedvalue={selectedValue}
                                    butttonText={listData.value}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this)}
                                    buttonKey={key} />
                            </View>
                        )
                    })
                }

                <View style={styles.NewLabSetBtn}>
                    {
                        newButtonKey ?

                            newbuttonFlag ?
                                <CommonButton
                                    item={{ label: "New", value: "New" }}
                                    selectedValue={{}}
                                    butttonText={"New"}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                    rightIcon={true}
                                    buttonAction={this.onPressButton.bind(this)}
                                    buttonKey={newButtonKey} />
                                :
                                <TextInput
                                    style={styles.NewLabSetBtnInput}
                                    onChangeText={(text) => {
                                        this.setState({ newButtonValue: text })
                                    }}
                                    onSubmitEditing={() => {
                                        this.onSubmitToAddValue()
                                    }}
                                />
                            : null
                    }
                </View>
            </View>
        )
    }

    renderBillingLabSet() {
        return (
            <View style={styles.RgtBillingView}>
                <Text style={styles.RightBillingTxt}>Billing</Text>
                <CommonHistorySectionHeader
                    heading1={"Lab Set"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View style={{ width: "100%" }}>
                    <SearchBar
                    round={true}
                    containerStyle={Style.SearchBarContainer}
                    inputContainerStyle={Style.SearchBarInnerContainer}
                    inputStyle={Style.SearchBarInput}
                    placeholder="Search LabSet..."
                    value={this.state.labSetSearchTxt}
                    ref={text => (this.searchBar = text)}
                    onChangeText={text => this.searchLabSet(text)}
                    />
                </View>
                {this.descriptionMultiButtons(
                    "",
                    "selectedLabSet",
                    this.state.selectedLabSet,
                    "",
                    this.state.showLabSetNewButtonFlag,
                    this.state.listOfLabSet
                )}
            </View>

        )
    }
    searchLabSet(text){
        this.setState({
            labSetSearchTxt: text
        },()=> this.getLabsetDetails())
    }

    renderTestName() {
        return (
            <View style={{ marginVertical: '0.97vw' }}>
                <CommonHistorySectionHeader
                    heading1={"Test Name"}
                    noOfColumn={1}
                    columnSize={[1]}
                />

                <View style={styles.TestNameInputView}>
                    <TextInput
                        style={styles.TestNameInput}
                        value={this.state.testName}
                        onChangeText={(text) => {
                            this.setState({ testName: text }, () => {
                                // if(this.state.selectedLabSet.value){
                                this.getTestName("TestName", this.state.testName)
                                // }
                            })
                        }}

                    />
                </View>

                {
                    this.state.searchListOfTestName && this.state.searchListOfTestName.length > 0 && this.state.testName != "" ?



                        <View style={[{backgroundColor: this.state.testName ? color.white : ""
                                      },styles.TestNameSuggestionView]}>
                            <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: '13vw' }}>
                                <FlatList
                                    data={this.state.searchListOfTestName}
                                    renderItem={({ item }) => (
                                        <View style={styles.TestNameSuggList}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        var prepareData = {
                                                            "test_name": item.name,
                                                            "amount": item.amount,
                                                            "lab_set_id": null,
                                                            "lab_test_id": item.lab_test_id
                                                        }
                                                        this.setState({
                                                            selectedLabTest: item,
                                                            testName: item.name,
                                                            searchListOfTestName: [],
                                                            selectedLabTestName: prepareData
                                                        })
                                                    }
                                                    }>
                                                    <Text style={styles.TestNameListTxt}>{item.name}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}
                                />
                            </ScrollView>
                        </View>
                        : null
                }

            </View>
        )
    }



    renderIdButton = image => {
        return (
            <View
                style={styles.renderIdBtnView}
            >
                <Icon name={image} color={"#DD1717"} size={'0.97vw'} />
                {/* <Image
        source={image}
        style={{ width: 40, height: 40 }}
        resizeMode="contain"
      /> */}
            </View>
        );
    };

    renderListofBills() {
        // [0.3, 0.3, 0.3, 0.1]
        return (
            <View>
                {
                    this.state.getListOfLabTestName && this.state.getListOfLabTestName.length > 0 ?
                        this.state.getListOfLabTestName.map((item, index) => {
                            return (
                                <View key={index} style={styles.TableDataWrapper}>
                                    <Text style={[Style.historyHeaderFontStyleStartText, { color: "#91939F", flex: 0.35, marginHorizontal: '1.5vw' }]}>
                                        {item.test_name ? item.test_name : item.name}</Text>
                                    <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>{item.test_units ? item.test_units : 1}</Text>

                                    <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.2 }]}>
                                        {item.test_rate_per_unit ? item.test_rate_per_unit : item.amount}</Text>

                                    <Text style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.25, marginLeft: '0.19vw' }]}>{item.amount ? item.amount : 0}</Text>



                                    <TouchableOpacity
                                        onPress={() => {
                                            var { getListOfLabTestName } = this.state;
                                            getListOfLabTestName.splice(index, 1)

                                            this.setState({
                                                getListOfLabTestName
                                            }, () => {
                                                var amount = this.state.getListOfLabTestName.reduce(function (sum, current) {
                                                    return (parseFloat(sum) + parseFloat(current.amount)).toFixed(2);
                                                }, 0);

                                                this.setState({
                                                    totalAmount: amount,
                                                    receviedAmount: 0.00,
                                                    balance: 0.00,
                                                }, () => {
                                                    this.netAmountCalculation()
                                                    if (this.state.selectedPaymentType == "Cash") {
                                                        this.calculateCashRoundOffAmount()
                                                    }
                                                })
                                            })
                                        }}
                                        style={[Style.historyHeaderFontStyle, { color: "#91939F", flex: 0.1 }]}>
                                        {this.renderIdButton("trash")}
                                    </TouchableOpacity>
                                </View>
                            )
                        })
                        : null
                }
            </View>

        )
    }
    filterDoctorName = (char) => {
        let filteredDocList = [];
        const serach = this.state.ref_doctor_list.filter(doctor=>{
            if(doctor.name.toLowerCase().includes(char.toLowerCase())){
                filteredDocList.push(doctor)
            }
        })
        this.setState({filtered_ref_doctor_list: filteredDocList})
    }

    renderDatePicker() {
        let {patientInfo} = this.state
        return (
            <View style={styles.PickerWrapper}>
                <View style={styles.DocRefView}>
                  {
                    patientInfo && patientInfo.patient_account_number ?
                  <View>
                    <Text style={styles.DocRefLabelTxt}>{this.state.ip_number ? "UHID / IP.No" : "UHID" }</Text>
                    <View style={styles.DocInputView}>
                        <TextInput
                            style={[styles.DocInputText, Style.allButtonBorderRadius]}
                            value={ patientInfo.patient_account_number && this.state.ip_number ? `${patientInfo.patient_account_number} / ${this.state.ip_number}` : patientInfo.patient_account_number }
                            editable={false}
                        />
                    </View>
                    </View> : null
                 }
                    <View>
                    <Text style={styles.DocRefLabelTxt}>{"Referredby"}</Text>
                    <View style={styles.DocInputView}>
                        <TextInput
                            style={[styles.DocInputText, Style.allButtonBorderRadius]}
                            value={this.state.referByName}
                            onChangeText={(text) => {
                                this.setState({ 
                                    referByName: text,
                                    doc_reg_no: "" 
                                },()=> this.filterDoctorName(text),console.log("Search Text", text) )
                            }}
                            onFocus={()=>{this.setState({isFocused:true})}}
                            onBlur={()=>{this.setState({isFocused:false})}}
                        />
                        { this.state.isFocused ?
                         <View style={styles.DocListWrapper}>
                            <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={false} style={{ maxHeight: '3.25vw' }}>
                                <FlatList
                                    data={this.state.filtered_ref_doctor_list}
                                    
                                    renderItem={({ item }) => (
                                        <View style={styles.DocsuggList}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                   onPress={() => {
                                                    this.setState({
                                                        search_doctor_list:item,
                                                        selected_doctor:item.name,
                                                        doc_reg_no: item.reg_no,
                                                        // ref_doctor_list:[],
                                                         referByName:item.name
                                                    })
                                            }}>
                                                    <Text style={{ color: color.white, fontSize:'0.91vw' }}>{item.name}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    // enableEmptySections={true}
                                />
                            </ScrollView>
                            </View>
                            : null 
                        }
                    </View>
                    </View>
                    <View>
                    <Text style={styles.DocRefLabelTxt}>{"Doctor Reg.No"}</Text>
                    <View style={styles.DocInputView}>
                        <TextInput
                            style={[styles.DocInputText, Style.allButtonBorderRadius]}
                            value={this.state.doc_reg_no}
                            editable={false}
                        />
                    </View>
                    </View>
                </View>
                <View>
                    <DatePicker
                        disabledDate={current => {
                            return current && current > moment().add(0, "days");
                        }}
                        value={
                            this.state.invoiceDate
                                ? moment(this.state.invoiceDate)
                                : this.state.invoiceDate
                        }
                        onChange={(date) => {
                            var fulldate = new Date(date);
                            var converted_date = moment(fulldate).format("YYYY-MM-DD");

                            this.setState({ invoiceDate: converted_date });
                        }
                        }
                        format={"DD-MM-YYYY"}
                        placeholder={"Select"}
                        disabled = {this.state.isAdmin ? false : true}
                        style={{
                            height: '2.28vw',
                            borderWidth: 1,
                            borderColor: color.black,
                            borderRadius: '0.32vw',
                            marginTop: '0.32vw',
                            fontSize:'0.91vw'
                        }}
                    />
                </View>
            </View>
        )
    }

    render() {
        return (

            <View style={styles.MainView}>
                <View style={{ width: "65%", }}>
                    <View style={styles.NavigateView}>
                        <NavigationTopHeader
                            changeTabSection={this.changeTabSection.bind(this)}
                            navigationHeaderList={
                                (this.state.selectedLabPatientDetails && this.state.selectedLabPatientDetails.isSearchedPatient) || (this.state.userType == "FO") ?
                                    navigationHeaderListIfIPCreateBill : this.props.labNavigationHeaderList}
                            selectedTab={"laboratoryCreateBill"} />
                    </View>
                    <ScrollView
                        style={styles.MainScrollView}
                        bounces={false}
                        bouncesZoom={false}
                    >

                        <View style={styles.LeftMainView} onLayout={(event) => {
                            var { x, y, width, height } = event.nativeEvent.layout;
                            console.log("height" + height)
                        }}>

                            <View style={styles.LeftTopView}  >
                                <View style={styles.TopWrapper}>
                                    <View style={{ flex: 0.3, flexDirection:"row" }}>
                                        <Text style={styles.BillingText}>Billing</Text>
                                        { this.state.billStatus === "Partially Saved" || ( this.state.isAdmin && this.state.isBillModification ) ?
                                        <TouchableOpacity
                                            onPress={() => this.savedBillPrint()}
                                            style={styles.PrintIconButton} >
                                            <Icon name={"print"} size={'1.3vw'} style={{ padding: '0.32vw' }} color={color.white} />
                                            {/* <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Print"}</Text> */}
                                        </TouchableOpacity>:null
    }
                                    </View>

                                    <View style={{ flex: 0.7 }}>
                                        {this.renderDatePicker()}
                                    </View>
                                </View>
                                <CommonHistorySectionHeader
                                    heading1={"Test Name"}
                                    heading2={"Units"}
                                    heading3={"Rate Per Unit"}
                                    heading4={"Total Cost"}
                                    heading5={"Action"}
                                    noOfColumn={5}
                                    columnSize={[0.35, 0.1, 0.2, 0.25, 0.1]}
                                />
                                {/* Write your code here  */}
                                <View style={styles.LeftDataMainWrapper}>
                                    {this.renderListofBills()}
                                    {this.renderFooterView()}
                                    {this.renderNetAmountRow()}
                                    {this.renderBillType()}
                                    {this.renderPaymentTypeRadioButton()}
                                    {this.renderButton()}
                                </View>
                            </View>
                        </View>
                        {/* <ScrollView style={{ height: screenHeight - 478,backgroundColor:color.white ,marginLeft:10}} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}> 
                                 {this.renderListofBills()}
                        </ScrollView> */}
                    </ScrollView>
                    {/* <View style={{position:"absolute",bottom:15,backgroundColor:color.white, width:"100%",marginLeft:10}}>
                                {this.renderFooterView()}
                                {this.renderNetAmountRow()}

                                {this.renderButton()}
                    </View> */}

                </View>



                <ScrollView showsVerticalScrollIndicator={false}
                    style={styles.RightScrollView} >
                    <View style={[Style.rightView, { padding: '1.3vw', width: "100%" }]}>
                        {/* Write your code here  */}


                        <View>
                            {this.renderBillingLabSet()}
                        </View>

                        <View>
                            {this.renderTestName()}
                        </View>

                        <View style={{zIndex:-1}}>
                            {this.renderUnits()}
                        </View>


                        <View style={{ zIndex: -1 }}>
                            <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                        </View></View>
                </ScrollView>
                {/* <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 10 }}>
                    <Image source={require("../../../../../assets/images/HomeScreenImages/Copyright.png")} style={{ height: 15, width: 15, color: color.lightGray, tintColor: color.lightGray }} />
                    <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
                </View>  */}
            </View>
        );
    }

    Cancelbilling() {
        var states = this.state
        states["invoiceDate"] = moment(new Date()).format("YYYY-MM-DD"),
            states["totalAmount"] = 0.00,
            states["netAmount"] = 0.00,
            states["selectedPaymentType"] = "",
            states["roundOff"] = 0.00,
            states["paymentTypeCashAmount"] = "",
            states["paymentTypeCardAmount"] = "",
            states["paymentTypeCardNumber"] = "",
            states["receviedAmount"] = "",
            states["balance"] = "",
            states["PaymentTaotalAmount"] = "",
            states["cardNumber"] = "",
            // states["cardNumber"] = "",
            states["discountPersentage"] = 0.00,
            states["discount"] = 0
            states["getListOfLabTestName"] = []
            states["referByName"] = this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.transmit_id ?this.props.selectedLabPatientDetails.doctor_name:""
            states["isDisabled"] = false
            states["bill_summary_id"] = ""
            states["upiTransactionID"] = ""
            states["billTypeCompanyName"]=""
            states["billTypeEmployeeID"]=""
            states["selectedBillType"] = ""
            states["insTransactionNumber"] = ""
            states["insPolicyNumber"] = ""
            states["insCompanyName"] = ""
            states["insValidity"] = ""
        this.setState({
            states
        })


    }

    renderPaymentTypeTextBoxRow(label, key, value) {
        return (
            <View style={styles.PaymntBoxMainView}>
                <View style={styles.PaymntBoxLblView}>
                    <Text style={styles.PaymentBoxlabl}>{label}</Text>
                </View>

                <View style={{}}>
                    {
                        key ?
                            <TextInput
                                style={[{  width: key =="insCompanyName" ? '13.02vw' : '5.86vw'  }, styles.PayTypeTxtInput]}
                                value={value}
                                editable={this.state.selectedPaymentType == "Cash & Card" && key == "paymentTypeCardAmount" ? false : true}
                                maxLength={
                                    key == "upiTransactionID" ?
                                    25
                                    :
                                    key== "cardNumber" ? 4 :null }
                                onChangeText={(text) => {
                                    if(key == "upiTransactionID"){
                                        var state = this.state;
                                        state[key] = text;
                                        this.setState({
                                          state
                                        })
                                      }else if(key === "insCompanyName"){
                                        var isAlpha = this.alphabetvalidation(text)
                                        if(isAlpha){
                                            console.log(isAlpha)
                                            let states = this.state
                                            states["insCompanyName"] = text
                                            this.setState({
                                                states
                                            })
                                        }
                                       } else if (this.numberValidation(text) && this.state.selectedPaymentType != "Insurance") {
                                        var states = this.state;
                                        states[key] = text;
                                        this.setState({ states }, () => {
                                            if (this.state.selectedPaymentType == "Cash" && this.state.receviedAmount) {
                                                var states = this.state.PaymentTaotalAmount - this.state.receviedAmount
                                                this.setState({
                                                    paymentTypeCashAmount: parseFloat(this.state.receviedAmount),
                                                    balance: states
                                                })
                                            } else if (this.state.selectedPaymentType == "Card") {
                                                var states = this.state.PaymentTaotalAmount - this.state.receviedAmount
                                                this.setState({
                                                    paymentTypeCardAmount: parseFloat(this.state.receviedAmount),
                                                    balance: states
                                                })
                                            } else if (this.state.selectedPaymentType == "Cash & Card") {
                                                if (this.state.paymentTypeCashAmount || this.state.paymentTypeCashAmount == "") {
                                                    var states = this.state.PaymentTaotalAmount - this.state.paymentTypeCashAmount
                                                }else if(this.state.discountAmount){
                                                    var states = this.state.PaymentTaotalAmount - this.state.discountAmount
                                                }


                                                this.setState({
                                                    states,
                                                    paymentTypeCashAmount: this.state.paymentTypeCashAmount,
                                                    paymentTypeCardAmount: states,
                                                    // receviedAmount:this.state.PaymentTaotalAmount
                                                })
                                            }else if(this.state.selectedPaymentType == "UPI" && this.state.receviedAmount) {
                                                var states = this.state.PaymentTaotalAmount - this.state.receviedAmount
                                                this.setState({
                                                    // paymentTypeCashAmount: parseFloat(this.state.PaymentTaotalAmount),
                                                    balance: states
                                                })
                                            
                                            }else if(this.state.selectedPaymentType == "Bank Transfer" && this.state.receviedAmount) {
                                                var states = this.state.PaymentTaotalAmount - this.state.receviedAmount
                                                this.setState({
                                                    // paymentTypeCashAmount: parseFloat(this.state.PaymentTaotalAmount),
                                                    balance: states
                                                })
                                            
                                            }
                                        })
                                    }else if(this.state.selectedPaymentType === "Insurance") {
                                        var state=this.state
                                        state[key] = text;
                                        this.setState({
                                          state
                                        })
                                    
                                    }
                                }}
                            />
                            : null
                    }

                </View>
                {/* <View style={{ flex: 0.35 ,alignItems:"flex-end",marginRight:15,justifyContent:"center"}}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>{"₹ "}{amount}</Text>
        </View> */}
            </View>
        )
    }

    renderNetAmountRow() {
        return (
            <View>
                <View onLayout={(event) => {
                    var { x, y, width, height } = event.nativeEvent.layout;
                    console.log("height" + height)
                }} style={styles.NetAntView}>
                    <View style={{ flex: 0.6 }}></View>
                    <View style={{ flex: 0.4 }}>
                        {this.renderAmountCalculationRow("Paid Amount", "", "", this.state.selectedPaymentType == "Insurance" ? 0 : this.state.receviedAmount ? this.state.receviedAmount : 0)}
                    </View>
                </View>
                {this.state.billStatus === "Partially Saved"?
                    <View style={{ flex: 1, flexDirection: "row" }}>
                        <View style={{ flex: 0.6 }}></View>
                        <View style={{ flex: 0.4 }}>
                            {this.renderAmountCalculationRow("Balance Due", "", "", this.state.balance ? this.state.balance : 0)}
                        </View>
                    </View>:null
                }
              
                </View>
                
  
        )
    }

    netAmountCalculation(key='') {
        var states = this.state;
        var netAmount = (states.totalAmount - states.discount) - states.roundOff;
        states["netAmount"] = netAmount;
         states["PaymentTaotalAmount"] = netAmount
        if(key==="discountPersentage"){
            states["paymentTypeCashAmount"]= 0
            states["paymentTypeCardAmount"]= 0
        }
      
        this.setState({ states }, () => {
            var { selectedPaymentType } = this.state;
            var states = this.state;
            if (selectedPaymentType == "Card" || selectedPaymentType == "Cash & Card") {
                states["roundOff"] = 0.00
            } else if (selectedPaymentType == "Cash") {
                this.calculateCashRoundOffAmount()
            }
            this.setState({ states })
        })
    }


    calculateDiscountAmount(key='') {

        var states = this.state;
        let { is_disc_percent_changed, discount, discountPersentage } = this.state;
        
        //if ((states.totalAmount && states.discountPersentage) || states.discountPersentage == 0) {
        if ((states.totalAmount && states.discountPersentage) || (states.totalAmount && discount)) {
            
            var amount = parseFloat(discount);

            if( is_disc_percent_changed )
            {
                let tem_discountPersentage = parseFloat(states.discountPersentage) || 0;
                amount = ((parseFloat(states.totalAmount) / 100) * tem_discountPersentage);
            } else {
                discountPersentage = ((amount / this.state.totalAmount) * 100).toFixed(2);
            }

            this.setState({
                discount: amount.toFixed(2),
                discountPersentage: discountPersentage
            }, () => {

                var { selectedPaymentType } = this.state;
                var states = this.state;
                
                if (selectedPaymentType == "Card" || selectedPaymentType == "Cash & Card") {
                    states["roundOff"] = 0.00
                } else if (selectedPaymentType == "Cash") {
                    this.calculateCashRoundOffAmount()
                }

                this.setState({ states }, () => {
                    this.netAmountCalculation(key)

                })
            })
        } else {
            this.netAmountCalculation()
        }

        // var dischargeAmount = ((amount / 100) * this.state.discountPersentage);
        // this.setState({
        //   discountAmount: dischargeAmount
        // })


    }

    validateAdvancePayment(list) {

        var isAdvance = true;
        for (var i = 0; i < list.length; i++) {
            if (list[i].Description == "Advance payment") {
                isAdvance = false;
                return isAdvance
            }
        }
        return isAdvance
    }

    renderAmountCalculationRow(label, key, value, amount,amountKey) {
        var textBoxDisabele = true
        if (key == "discountPersentage") {
            if(this.state.disableDiscountPercent){
                textBoxDisabele = false
              }
              else{
                textBoxDisabele = this.validateAdvancePayment(this.state.billingList)
              }
        }

        return (
            <View style={styles.AmtRowWrapper}>
                <View style={styles.AmtRowLabelView}>
                    <Text style={styles.AmtRowLblTxt}>{label}</Text>
                </View>

                <View style={styles.AmtRowContentWrapper}>
                    {
                        key ?
                            <TextInput
                                editable={textBoxDisabele}
                                style={[{backgroundColor: !textBoxDisabele ? "#F0F0F0" : "" }, styles.AmtTxtInput]}
                                value={value}
                                onChangeText={(text) => {
                                    if (this.numberValidation(text)) {
                                        var states = this.state;
                                        if (key == "discountPersentage") {

                                            if (text >= 0.00 && text && text <= 100) {
                                                states[key] = parseFloat(text);
                                            } else if (text > 100) {
                                                // states[key] = 0;
                                            } else {
                                                states[key] = 0;
                                            }

                                            states['is_disc_percent_changed'] = true

                                        } else {
                                            states[key] = text;
                                        }

                                        this.setState({ states }, () => {
                                            if (key == "discountPersentage") {
                                                this.calculateDiscountAmount(key)
                                            }

                                            // var dischargeAmount = ((amount / 100) * this.state.discountPersentage);
                                            // amount=dischargeAmount.toFixed(2)
                                        })
                                    }
                                }}
                            />
                            : null
                    }

                </View>
                <View style={styles.AmtData}>
                    {
                        amountKey == "discount" ?
                        <TextInput
                            editable={textBoxDisabele}
                            style={[{backgroundColor: !textBoxDisabele ? "#F0F0F0" : "" },styles.AmtTxtInput]}
                            value={amount}
                            onChangeText={(text) => {
                            if (this.numberValidation(text)) {
                                // alert(text)
                                var states = this.state;
                                states["discount"] = text? text: 0; 
                                states['is_disc_percent_changed'] = false

                                if(text){
                                    states["discount"] = text
                                    states["discountPersentage"] = (text / this.state.totalAmount) * 100;
                                }else{
                                    states["discount"] = 0
                                    states["discountPersentage"] = 0;
                                }

                                this.setState({ 
                                    states                                
                                }, () => {

                                    // discountPersentage
                                    if (amountKey == "discount") {
                                        this.calculateDiscountAmount()
                                    }

                                })
                            }
                            }}
                        />
                        : 
                        <Text style={styles.AmtDataText}>{"₹ "}{amount}</Text>
                    }
                    
                </View>
            </View>
        )
    }

    //   getNetAmount(discountAmt) {

    // }

    calculateCashRoundOffAmount() {
        var states = this.state;

        var roundOffAmount = states.netAmount % 1;
        states["netAmount"] = Math.round(states.netAmount);
        states["PaymentTaotalAmount"] = Math.round(states.netAmount);

        if (parseFloat(roundOffAmount) >= 0.5) {
            // states["netAmount"] = parseInt(states.totalAmount) + 1;;
            // amount_net = parseInt(states.totalAmount) + 1;
            states["roundOff"] = (1 - parseFloat(roundOffAmount))

            // round_off= "+" +(1-parseFloat(round_off))
        } else if (parseFloat(roundOffAmount) < 0.5) {
            // states["netAmount"] = parseInt(states.totalAmount);
            states["roundOff"] = "-" + (roundOffAmount)

        }



        this.setState({ states })



    }

    renderPaymentTypeRadioButton() {
        var listOfPaymentType = [
            { label: "Card", value: "Card" },
            { label: "Cash", value: "Cash" },
            { label: "Cash & Card", value: "Cash & Card" },
            { label: "UPI", value: "UPI" },
            {label:"Bank Transfer",value:"Bank Transfer"},
            {label:"Insurance", value:"Insurance"}

        ]
        return (
            <View>
                <Text style={styles.PayTypeTxt}>{"Payment Type"}</Text>

                <View style={styles.payTypeView}>
                    {
                        listOfPaymentType.map((item, index) => {
                            return (
                                <TouchableOpacity onPress={() => {
                                    this.setState({
                                        selectedPaymentType: item.value,
                                        cardNumber: "",
                                        receviedAmount: "",
                                        balance: "",
                                        paymentTypeCashAmount: "",
                                        paymentTypeCardAmount: "",
                                        insTransactionNumber: "",
                                        insPolicyNumber: "",
                                        insCompanyName: "",
                                        insValidity: "",
                                    }, () => {
                                        var { selectedPaymentType } = this.state;
                                        var states = this.state;
                                        if (selectedPaymentType == "Card" || selectedPaymentType == "Cash & Card") {
                                            states["roundOff"] = 0.00
                                            this.setState({ states }, () => {
                                                this.netAmountCalculation()

                                            })
                                        } else if (selectedPaymentType == "Cash") {
                                            this.calculateCashRoundOffAmount()
                                        }

                                    })
                                }} style={styles.PayTypeBtn} key={index}>
                                    <View style={[{
                                        backgroundColor: this.state.selectedPaymentType == item.value ? color.themeDark : "",     
                                    },styles.PayTypeRadio]}></View>
                                    <Text style={styles.PayTypeRadioText}>{item.label}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
                {this.state.selectedPaymentType == "Card" ?

                    <View style={{ flexDirection: "row" }}>
                        {this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", parseFloat(this.state.PaymentTaotalAmount))}
                        {this.renderPaymentTypeTextBoxRow("Received Amount", "receviedAmount", this.state.receviedAmount)}
                        {this.renderPaymentTypeTextBoxRow("Balance", "balance", this.state.balance)}
                        {this.renderPaymentTypeTextBoxRow("Card Number", "cardNumber", this.state.cardNumber)}

                    </View>
                    : this.state.selectedPaymentType == "Cash" ?
                        <View style={{ flexDirection: "row" }}>
                            {this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", this.state.PaymentTaotalAmount)}
                            {this.renderPaymentTypeTextBoxRow("Received Amount", "receviedAmount", this.state.receviedAmount)}
                            {this.renderPaymentTypeTextBoxRow("Balance", "balance", this.state.balance)}
                        </View>
                        : this.state.selectedPaymentType == "Cash & Card" ?
                            <View style={{ flexDirection: "row" }}>
                                {this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", this.state.PaymentTaotalAmount)}
                                {this.renderPaymentTypeTextBoxRow("Cash", "paymentTypeCashAmount", this.state.paymentTypeCashAmount)}
                                {this.renderPaymentTypeTextBoxRow("Card", "paymentTypeCardAmount", this.state.paymentTypeCardAmount)}
                                {this.renderPaymentTypeTextBoxRow("Card Number", "cardNumber", this.state.cardNumber)}

                            </View> 
                            :
                            this.state.selectedPaymentType == "UPI" || this.state.selectedPaymentType == "Bank Transfer" ?
                                <View style={{ flexDirection: "row" }}>
                                    {this.renderPaymentTypeTextBoxRow("Total", "PaymentTaotalAmount", parseFloat(this.state.PaymentTaotalAmount))}
                                    {this.renderPaymentTypeTextBoxRow("Received Amount", "receviedAmount", this.state.receviedAmount)}
                                    {this.renderPaymentTypeTextBoxRow("Balance", "balance", this.state.balance)}
                                    {this.renderPaymentTypeTextBoxRow("Transaction ID", "upiTransactionID", this.state.upiTransactionID)}

                                </View>
                            :
                            this.state.selectedPaymentType == "Insurance" ?
                                <View style={{ flexDirection: "row" }}>
                                    {this.renderPaymentTypeTextBoxRow("Company Name", "insCompanyName", this.state.insCompanyName)}
                                    {this.renderPaymentTypeTextBoxRow("Policy No", "insPolicyNumber", this.state.insPolicyNumber)}
                                    {this.renderPaymentTypeTextBoxRow("Validity", "insValidity", this.state.insValidity)}
                                    {this.renderPaymentTypeTextBoxRow("Transaction No", "insTransactionNumber", this.state.insTransactionNumber)}
                                </View> : null}
            </View>
        )
    }
    renderBillTypeTextBoxRow(label, key, value) {
        return (
          <View style={styles.textboxContainer}>
            <View style={{ flex: 0.4, justifyContent: "center" }}>
              <Text style={styles.textBoxText}>{label}</Text>
            </View>
            <View style={styles.textInputStyle}>
              {
                key ?
                  <TextInput
                  editable={false}
                    style={[styles.TextInputStyle,{ width:'14vw', textAlign: "center", fontSize:'0.91vw'}]}
                    value={value}
                    onChangeText={(text) => {
                      if (key != "") {
                        var state = this.state;
                        state[key] = text;
                        this.setState({
                          state
                        })
                      }
                    }}
                  />
                  : null
              }
    
            </View>
    
          </View>
        )
      }

      getPatientCorporateDetails (){
          var patient_Id = "";
          if(this.state.selectedLabPatientDetails.patient_id){
            patient_Id = this.state.selectedLabPatientDetails.patient_id;
          }else{
              patient_Id = this.state.selectedLabPatientDetails.patientDetails.patient_id;
          }
        var service_url = Constants.GET_PATIENT_CORPORATE_DETAILS + "?patient_id=" + patient_Id;
    
        OpthamologyService.getInstance().getComplaints(
          service_url,
          this,
          this.getPatientCorporateDetailsSuccess,
          this.getPatientCorporateDetailsFail
        );
        
      }
    
      getPatientCorporateDetailsSuccess = (response) => {
        try{
        if(response.status == "success"){
          if (response.data.company_name && response.data.company_name != "") {
            this.setState({
              selectedBillType: "Corporate",
              billTypeCompanyName: response.data.company_name,
              billTypeEmployeeID: response.data.employee_id,
              discountPersentage: response.data.employer_discount,
              EmployerID:response.data.employer_id,
              disableDiscountPercent: true
            }, ()=>{ this.calculateDiscountAmount()})
          }
          else {
            this.props.showResposeValue("error", 'This Patient is not Linked with Any Corporates. Please Add the Corporate in Patient Demographics')
          }
        }
    }
    catch (error){
      console.log(error)
    }
      }
      getPatientCorporateDetailsFail =  (error)=>{
        this.props.showResposeValue("error", error.message)
      }

      CorporateUnchecked() {
        this.setState({
          billTypeCompanyName: "",
          billTypeEmployeeID: "",
          EmployerID: "",
          discountPersentage: 0,
          disableDiscountPercent: false
        }, () => { this.calculateDiscountAmount() })
      }

    renderBillType = () =>{
     var billTypeList =[
        { label: "Corporate", value: "Corporate" },
        { label: "Credit", value: "Credit" }
     ]
     return(
         <View>
       <View style={{ flex: 1, flexDirection: "column" }}>
        <View>
          <Text style={[styles.billTypeStyle]}>{"Bill Type"}</Text>
        </View>
        <View style={[styles.containerStyles]}>
          {
            billTypeList.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                    if (this.state.selectedBillType == item.value) {
                        this.setState({
                          selectedBillType: ""
                        }, () => { this.CorporateUnchecked() })
                      } else {
                            if (item.value == "Corporate") {
                              this.getPatientCorporateDetails()
                          } else if (item.value == "Credit") {
                            this.setState({
                              selectedBillType: "Credit",
                            }, () => { this.CorporateUnchecked() })
                          }
    
                        // })
                      }
                }} style={[styles.radioButtonStyleContainer]} key={index}>
                  <View style={[{
                    backgroundColor: this.state.selectedBillType == item.value ? color.themeDark : ""
                  }, styles.radioButtonStyle]}></View>
                  <Text style={[styles.radioButtonText]}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
        {this.state.selectedBillType == "Corporate" ?
          <View style={{ flexDirection: "row" }}>
            {this.renderBillTypeTextBoxRow("Company Name", "billTypeCompanyName", this.state.billTypeCompanyName)}
            {this.renderBillTypeTextBoxRow("Employee ID", "billTypeEmployeeID", this.state.billTypeEmployeeID)}
          </View>
          :
          null
        }
      </View>
         </View>
     )
    }
    renderFooterView() {
        // var amount = this.state.billingList.reduce(function (sum, current) {
        //   return sum + current.TotalAmount;
        // }, 0);


        return (
            <View onLayout={(event) => {
                var { x, y, width, height } = event.nativeEvent.layout;
                console.log("height" + height)
            }}>

                <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 0.6, justifyContent: "flex-end" }}>
                        {/* {this.renderPaymentTypeRadioButton()} */}
                    </View>
                    <View style={styles.FooterView}>
                        {this.renderAmountCalculationRow("Sub Amount", "", "", parseFloat(this.state.totalAmount))}
                        {this.renderAmountCalculationRow("Discount %", "discountPersentage", this.state.discountPersentage, parseFloat(this.state.discount),"discount")}
                        {/* {this.renderAmountCalculationRow("Discount Amount", "discountAmount", this.state.discountAmount, amount)} */}
                        {this.renderAmountCalculationRow("Round Off", "", "", parseFloat(this.state.roundOff).toFixed(2))}
                        {this.renderAmountCalculationRow("Net Amount", "", "", parseFloat(this.state.netAmount).toFixed(2))}

                    </View>
                </View>
            </View>
        )
    }

    savedBillPrint(){
        var service =  ""
        if(this.state.billStatus == "Partially Saved") { 
            service = Constants.LAB_SAVED_PRINT +"?bill_summary_id="+ this.state.bill_summary_id + "&patient_id="+ this.state.patientId +"&export_type=PDF";
        } else {
            service = Constants.LAB_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?bill_summary_id=" + this.state.bill_summary_id + "&export_type=PDF";
        }
        OpthamologyService.getInstance().documentUploadGet(
            service,
            this.savedBillPrintSuccess,
            this.savedBillPrintFailure,
            "pdf"
        );
    };

    savedBillPrintSuccess = response => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
           // console.log(file)
            window.open(fileURL);
        }
    };

    savedBillPrintFailure = error => {
        console.log(error);
    };
    renderButton() {
        return (
            <View onLayout={(event) => {
                var { x, y, width, height } = event.nativeEvent.layout;
                console.log("height" + height)
            }} style={styles.buttonrowView}>
                {
                    this.state.userType == "LAB" ?
                        <TouchableOpacity
                            onPress={() => this.CreateBill(false)}
                            disabled={this.state.isDisabled ||( this.state.isAdmin && this.state.isBillModification ) ? true : false}
                            style={[Style.ipcancelButton, { backgroundColor: ( this.state.isAdmin && this.state.isBillModification) ? color.lightGray : color.themeDark }]} >
                            <Icon name={"save"} size={Platform === "web" ? '0.97vw' : '1.3vw'} color={color.white} />
                            <Text style={styles.ButtonText}>{"Save"}</Text>
                        </TouchableOpacity> : null
                }

                <TouchableOpacity
                    onPress={() => this.CreateBill(true)}
                    disabled={this.state.isDisabled ||( this.state.isAdmin && this.state.isBillModification ) ? true : false}
                    style={[Style.ipcancelButton, { backgroundColor: ( this.state.isAdmin && this.state.isBillModification) ? color.lightGray : color.themeDark }]} >
                    <Text style={styles.ButtonText}>{"Payment Received"}</Text>
                </TouchableOpacity>
                {this.state.isAdmin ?
                      <TouchableOpacity
                      onPress={() => { 
                        this.setState({
                            isUpdate: true
                        },() => {
                            this.CreateBill(true)
                        })
                    } }
                      disabled={( this.state.isAdmin && this.state.isBillModification) ? false : true}
                      style={[Style.ipcancelButton, { backgroundColor: ( this.state.isAdmin && this.state.isBillModification) ? color.themeDark : color.lightGray }]} >
                      <Text style={styles.ButtonText}>{"Update"}</Text>
                  </TouchableOpacity> : null
                }
                <TouchableOpacity
                    onPress={() => this.Cancelbilling()}
                    style={Style.ipAdmissonCancelButton} >
                    <Cancel name={"cross"} size={Platform === "web" ? '0.97vw' : '1.3vw'} color={color.themeDark} />
                    <Text style={[styles.ButtonText, { color: color.themeDark }]}>{"Clear"}</Text>
                </TouchableOpacity>
            </View>
        )
    }


    getRoomDetails() {

        if (this.state.selectedIpPatientDetails.id) {
            var service_url = Constants.IP_ROOMS_DETAILS + "?patient_id=" + this.state.selectedIpPatientDetails.patient_id + "&ip_admission_id=" + this.state.selectedIpPatientDetails.id;
        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getRoomDetailsSuccess,
            this.getRoomDetailsFailure
        );
    }

    getRoomDetailsSuccess = success => {
        if (success.status == "success") {

            var data = success.data
            this.props.showRoomDetailsPopup(data, true);
            // var states = this.state
            // states["roomsDetails"] = data
            // this.setState({
            //     states
            // })


        }
    }

    getRoomDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))
    }


    getTestName = (seachtab, key) => {


        var serviceUrl = Constants.LAB_TEST_SEARCH + "?search_tab=" + seachtab + "&search_key=" + key;
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getTestNameSuccess,
            this.getTestNameFailure
        );
    };

    getTestNameSuccess = response => {
        if (response.status === "success") {
            this.setState({
                searchListOfTestName: response.data
            })

        }
    };

    getTestNameFailure = error => { }

    getBillMidificationList = () => {
        var service_url = Constants.LAB_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?bill_summary_id=" + this.state.bill_summary_id ;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getBillMidificationListSuccess,
            this.getBillMidificationListFailure
        );
    }
    getBillMidificationListSuccess = (success) => {
        if (success.status == "success") {
            var getPrepareData = []
            this.setState({
                modifyBillDetails: success.data.billing_details,
                modifyBillSummaryDetails: success.data.bill_summary_details,
                modifyBillPatientDetails: success.data.patient_details
            },() => {
                for (var i = 0; i < this.state.modifyBillDetails.length; i++) {
                    var data = {
                        lab_set_id: parseInt(this.state.modifyBillDetails[i].lab_set_id),
                        lab_test_id: parseInt(this.state.modifyBillDetails[i].lab_test_id),
                        test_name: this.state.modifyBillDetails[i].test_name,
                        amount: parseInt(this.state.modifyBillDetails[i].amount),
                        test_units: parseInt(this.state.modifyBillDetails[i].test_units),
                        test_rate_per_unit: parseInt(this.state.modifyBillDetails[i].test_rate_per_unit),
                    }
    
    
                    getPrepareData.push(data)
                }
                this.setState({
                    patientId: this.state.modifyBillPatientDetails.id,
                    referByName: this.state.modifyBillSummaryDetails.reffered_by,
                    PaymentTaotalAmount: this.state.modifyBillSummaryDetails.net_amount,
                    roundOff: this.state.modifyBillSummaryDetails.round_off,
                    netAmount: this.state.modifyBillSummaryDetails.net_amount,
                    selectedPaymentType: this.state.modifyBillSummaryDetails.payment_type == "Cash and Card" ? "Cash & Card" : this.state.modifyBillSummaryDetails.payment_type,
                    paymentTypeCashAmount: this.state.modifyBillSummaryDetails.cash_amount,
                    paymentTypeCardAmount: this.state.modifyBillSummaryDetails.card_amount,
                    receviedAmount: this.state.modifyBillSummaryDetails.received_amount,
                    balance: this.state.modifyBillSummaryDetails.balance_amount,
                    cardNumber: this.state.modifyBillSummaryDetails.card_number,
                    discountPersentage: this.state.modifyBillSummaryDetails.discount_percentage,
                    discount: this.state.modifyBillSummaryDetails.discount_amount,
                    getListOfLabTestName: getPrepareData,
                    totalAmount: this.state.modifyBillSummaryDetails.total_amount,
                    billStatus:this.state.modifyBillSummaryDetails.status,
                    selectedBillType : this.state.modifyBillSummaryDetails.bill_type,
                    billTypeEmployeeID : this.state.modifyBillSummaryDetails.employee_id,
                    billTypeCompanyName:this.state.modifyBillSummaryDetails.company_name,
                    insTransactionNumber:this.state.modifyBillSummaryDetails.transaction_number,
                    insPolicyNumber:this.state.modifyBillSummaryDetails.policy_number, 
                    insCompanyName: this.state.modifyBillSummaryDetails.insurance_company_name,
                    insValidity: this.state.modifyBillSummaryDetails.validity,
                    invoiceDate: this.state.modifyBillSummaryDetails.invoice_date,
                    bill_summary_id: this.state.modifyBillSummaryDetails.bill_summary_id,
                    invoiceNumber: this.state.modifyBillSummaryDetails.invoice_number
                },()=>{
                    this.renderPaymentTypeRadioButton()
                    if(this.state.selectedBillType ==  "Corporate"){
                        this.getPatientCorporateDetails()
                      }
                })
            })
           
        }

    }
    getLabSaveBilling() {
        var states = this.state;
        var service_url = Constants.LAB_CREATE_BILL + "list/" + "?bill_summary_id=" + this.state.bill_summary_id + "&patient_id=" + this.state.patientId;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getLabSaveBillingSuccess,
            this.getLabSaveBillingFailure
        );
    }
    getLabSaveBillingSuccess = (success) => {
        if (success.status == "success") {
            // alert(JSON.stringify(success.data))
            var getPrepareData = []
            for (var i = 0; i < success.data.bill_line_items.length; i++) {
                var data = {
                    lab_set_id: parseInt(success.data.bill_line_items[i].lab_set_id),
                    lab_test_id: parseInt(success.data.bill_line_items[i].lab_test_id),
                    test_name: success.data.bill_line_items[i].test_name,
                    amount: parseInt(success.data.bill_line_items[i].amount),
                    test_units: parseInt(success.data.bill_line_items[i].test_units),
                    test_rate_per_unit: parseInt(success.data.bill_line_items[i].test_rate_per_unit),
                }


                getPrepareData.push(data)
            }
            this.setState({
                ip_number: success.data.ip_number ? success.data.ip_number : 0,
                referByName: success.data.reffered_by ? success.data.reffered_by : "",
                doc_reg_no: success.data.reffered_reg_no ? success.data.reffered_reg_no : "",
                // invoiceDate: success.data.invoice_date,
                PaymentTaotalAmount: success.data.net_amount,
                roundOff: success.data.round_off,
                netAmount: success.data.net_amount,
                selectedPaymentType: success.data.payment_type == "Cash and Card" ? "Card & Cash" : success.data.payment_type,
                paymentTypeCashAmount:  success.data.payment_type == "Cash and Card" ? success.data.net_amount:success.data.cash_amount,
                paymentTypeCardAmount: success.data.card_amount,
                receviedAmount: success.data.received_amount,
                balance: success.data.balance_amount,
                cardNumber: success.data.card_number,
                discountPersentage: success.data.discount_percentage,
                discount: success.data.discount_amount,
                getListOfLabTestName: getPrepareData,
                totalAmount: success.data.total_amount,
                billStatus:success.data.status,
                selectedBillType : success.data.bill_type,
                billTypeEmployeeID : success.data.employee_id,
                billTypeCompanyName:success.data.company_name,
                insTransactionNumber: success.data.transaction_number,
                insPolicyNumber:success.data.policy_number, 
                insCompanyName: success.data.insurance_company_name,
                insValidity: success.data.validity,

            }, ()=>{
                if(this.state.selectedBillType ==  "Corporate"){
                    this.getPatientCorporateDetails()
                  }
            })
        }

    }
    getLabSaveBillingFailure = error => {
    }



    CreateBill(key) {
        if ((key && (parseFloat(this.state.receviedAmount) >= parseFloat(this.state.netAmount)) || !key || this.state.selectedPaymentType !== "Cash"|| this.state.selectedPaymentType === "Cash & Card")) {
            if(key && (parseFloat(this.state.receviedAmount) != parseFloat(this.state.netAmount)) && this.state.selectedPaymentType != "Insurance" && this.state.selectedPaymentType != "Cash & Card"){
                this.props.showResposeValue('error', "Received amount should be equal to total amount");
                return;
            }
        
            if (key) {
                this.setState({
                    isDisabled: true
                })
            }
            var states = this.state;
            var { getListOfLabTestName } = this.state
            var getPrepareData = []
            for (var i = 0; i < getListOfLabTestName.length; i++) {
                var data = {
                    lab_set_id: parseInt(getListOfLabTestName[i].lab_set_id),
                    lab_test_id: parseInt(getListOfLabTestName[i].lab_test_id),
                    test_name: getListOfLabTestName[i].test_name,
                    amount: parseFloat(getListOfLabTestName[i].amount) || 0,
                    test_units: parseInt(getListOfLabTestName[i].test_units || 1),
                    test_rate_per_unit: parseInt(getListOfLabTestName[i].test_rate_per_unit || getListOfLabTestName[i].amount ),
                }


                getPrepareData.push(data)
            }


            if (states.selectedPaymentType == "Cash & Card") {
                var paymentType = "Cash and Card"
            }
            var data = {
                "patient_id": this.state.patientId ? this.state.patientId : this.state.modifyBillPatientDetails.patient_id,
                "reffered_by": this.state.referByName,
                "reffered_reg_no": this.state.doc_reg_no,
                "invoice_date": this.state.invoiceDate,
                "total_amount": parseFloat(states.totalAmount),
                "round_off": parseFloat(states.roundOff),
                "net_amount": this.state.netAmount,
                "payment_type": states.selectedPaymentType == "Cash & Card" ? paymentType : states.selectedPaymentType,
                "cash_amount":  parseFloat(states.paymentTypeCashAmount) ? states.receviedAmount >= states.PaymentTaotalAmount ? parseFloat(states.PaymentTaotalAmount) :parseFloat(states.paymentTypeCashAmount) :0,
                "card_amount":  parseFloat(states.paymentTypeCardAmount) ? states.receviedAmount >= states.PaymentTaotalAmount ? parseFloat(states.PaymentTaotalAmount) :parseFloat(states.paymentTypeCardAmount) :0,
                // "card_number": states.paymentTypeCardNumber,
                "received_amount": states.selectedPaymentType == "Insurance" || states.selectedPaymentType == "Cash & Card" ? parseFloat(states.netAmount) : states.receviedAmount ? states.receviedAmount >= states.PaymentTaotalAmount ? states.PaymentTaotalAmount :  states.receviedAmount : 0,
                "balance_amount": states.balance ? states.receviedAmount > states.PaymentTaotalAmount ? 0: states.balance : 0,
                // "paid_amount": states.selectedPaymentType ? this.state.PaymentTaotalAmount : 0,
                "bill_line_items": getPrepareData,
                "card_number": this.state.cardNumber ? this.state.cardNumber : "",
                "discount_percentage": parseFloat(states.discountPersentage) || 0,
                "discount_amount": parseFloat(states.discount) || 0,
                "transaction_id":this.state.upiTransactionID,
                "transaction_number": this.state.insTransactionNumber,
                "policy_number":this.state.insPolicyNumber,
                "insurance_company_name": this.state.insCompanyName,
                "validity":this.state.insValidity,
                "bill_type":this.state.selectedBillType,
                "company_name":this.state.billTypeCompanyName,
                "employee_id" :this.state.billTypeEmployeeID,
                "employer_id": this.state.EmployerID,
                "patient_account_number": this.state.patientInfo ? this.state.patientInfo.patient_account_number : "",
                "ip_number": this.state.ip_number ? this.state.ip_number : 0
            }
            if (this.state.bill_summary_id && !this.state.ispatientHome) {
                data["bill_summary_id"] = this.state.bill_summary_id
            }
            if (this.state.bill_summary_id && Object.keys(this.state.modificationBillDetails).length > 0) {
                data["bill_summary_id"] = this.state.bill_summary_id
            }
            if(this.state.invoiceNumber) {
                data["invoice_number"] = this.state.invoiceNumber
            }
            if (this.state.transmiteID) {
                data["test_transmit_summary_id"] = this.state.transmiteID
                data["is_transmitted"] = true
            }
            if(data["patient_id"] == undefined){
                data["patient_id"] = this.props.selectedLabPatientDetails.patientDetails.patient_id
            }

            if (key) {
                var service_url = Constants.LAB_CREATE_BILL_PAYMENT_RECIVED;

            } else {
                var service_url = Constants.LAB_CREATE_BILL;

            }

            OpthamologyService.getInstance().postComplaints(
                service_url,
                JSON.stringify(data),
                this,
                this.CreateBillSuccess,
                this.CreateBillFailure,
                "", "",
                key
            );
        } else if (this.state.receviedAmount == "" || this.state.receviedAmount == 0 || this.state.receviedAmount == null ) {
            this.props.showResposeValue('error', "Please Enter the Received Amount");
        } else {
            this.props.showResposeValue('error', "Please Enter the correct Received Amount");
        }

    }

    CreateBillSuccess = (success, key) => {
        if (success.status == "success") {
            // if(success.message.includes("Credit Limit Exceeded,")){
                // this.props.showResposeValue('error', "Credit Limit Exceeded");
            // }
            // setTimeout(() => {
                this.props.showResposeValue('success', success.message);
            // }, 2000);
            if (this.state.userType == "FO") {
                // do nothing 
            } else {
                if (key == true) {
                    this.props.changeScreen("laboratoryPatientList", {}, "", {}, "", {}, "", {}, "", {}, false, true)
                }
            }

            if (key) {
                this.Cancelbilling()
            }
            if (success && success.data && success.data.bill_summary_id) {
                this.setState({
                    bill_summary_id: success.data.bill_summary_id,
                },()=>{this.getLabSaveBilling()})
                
            }
            if(this.state.isUpdate) {
                this.props.renderModifyClick(true)      
            }

        } else {


            this.props.showResposeValue('error', success.message);
            this.setState({
                isDisabled: false
            })
        }
    }
    CreateBillFailure = error => {

        this.props.showResposeValue('error', error.message);
        this.setState({
            isDisabled: false
        })
    }

    onPressAddButton() {


        var { getListOfLabTestName, selectedLabSetDetails, selectedLabTestName } = this.state;


        var units = this.state.noUnits >= 1 ? this.state.noUnits : 1

        if (Object.keys(selectedLabSetDetails).length > 0) {
            // alert(JSON.stringify(selectedLabSetDetails))

            let data = {
                "lab_set_id": selectedLabSetDetails.lab_set_id,
                "lab_test_id": selectedLabSetDetails.lab_test_id,
                "test_name": selectedLabSetDetails.test_name,
                "test_units": units,
                "test_rate_per_unit": selectedLabSetDetails.amount,
                "amount": parseInt(units) * parseFloat(selectedLabSetDetails.amount)
            }

            getListOfLabTestName.push(data);
        }

        if (Object.keys(selectedLabTestName).length > 0) {
            // alert(JSON.stringify(selectedLabTestName))
            let data = {
                "lab_set_id": selectedLabTestName.lab_set_id,
                "lab_test_id": selectedLabTestName.lab_test_id,
                "test_name": selectedLabTestName.test_name,
                "test_units": units,
                "test_rate_per_unit": selectedLabTestName.amount,
                "amount": parseInt(units) * parseFloat(selectedLabTestName.amount)
            }
            getListOfLabTestName.push(data);
        }

        this.setState({
            getListOfLabTestName
        }, () => {

            console.log(" amount 1" + this.state.totalAmount)

            var amount = this.state.getListOfLabTestName.reduce(function (sum, current) {
                return (parseFloat(sum) + parseFloat(current.amount)).toFixed(2);
            }, 0);

            console.log(" amount 2" + amount)

            this.setState({
                totalAmount: amount,
                noUnits: 1
            }, ()=>{
                // for re-calculate disc amount
                this.calculateDiscountAmount()
            })

            this.clearRightSideDetails()

        })

    }


    clearRightSideDetails() {



        var states = this.state;
        states["selectedLabTestName"] = {}
        states["selectedLabTest"] = {}
        states["testName"] = ""
        states["selectedLabSet"] = {}
        states["selectedLabSetDetails"] = {}

        this.setState({ states }, () => {
            this.netAmountCalculation()
            if (this.state.selectedPaymentType == "Cash") {
                this.calculateCashRoundOffAmount()
            }
        })
    }


    renderUnits() {
        return (
            <View style={{ marginVertical: '0.97vw',zIndex:1 }}>
                <CommonHistorySectionHeader
                    heading1={"Units"}
                    noOfColumn={1}
                    columnSize={[1]}
                />

                <View style={styles.UnitsView}>
                    <TextInput
                        style={styles.UnitsInput}
                        value={this.state.noUnits}
                        onChangeText={(value) => {
                            if (isNaN(value)) {

                            } else {
                                this.setState({ noUnits: value }, () => {
                                    // if(this.state.selectedLabSet.value){
                                    // this.getTestName("TestName", this.state.noUnits)
                                    // }
                                })
                            }

                        }}

                    />
                </View>

            </View>
        )
    }


}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },

    textboxRowView: {
        flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10
    },
    buttonrowView: {
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignContent: "center", 
        marginVertical: '0.65vw'
    },
    billTypeStyle:{
        fontWeight: "500", 
        fontSize: '0.91vw', 
        marginVertical: '0.65vw'
    },
    containerStyles :{
        flexDirection: "row", 
        marginVertical: '0.97vw'
    },
    radioButtonStyleContainer :{
        marginRight: '1.3vw', 
        flexDirection: "row", 
        alignItems: "center"
    },
    radioButtonStyle :{
        marginRight: '0.52vw', 
        height: '1vw', 
        width: '1vw', 
        borderRadius: '3.25vw', 
        borderWidth: 1, 
        borderColor: "#91939F"
    },
    radioButtonText : {
        fontWeight: "500",
        fontSize: '0.85vw'
    },
    textboxContainer :{
        marginVertical: '0.52vw', 
        marginRight: '0.97vw'
    },
    textBoxText:{
        fontSize: '0.8vw', 
        fontWeight: "500", 
        marginBottom: '0.65vw'
    },
    textInputStyle:{
        textAlign: 'center', 
        height: '1.82vw', 
        borderRadius: '0.26vw', 
        borderWidth: 1, 
        borderColor: "#91939F",
        padding:'0.32vw',
    },
    MainView:{
        flex: 1, 
        flexDirection: "row", 
        backgroundColor: color.applicationBackgroundColor
    },
    NavigateView:{
        marginBottom: '0.65vw'
    },
    MainScrollView:{
        width: "100%", 
        height: screenHeight - 150
    },
    LeftMainView:{
        width: platform === "web" ? "62.5%" : "58.5%",
        width: "100%", 
        backgroundColor: color.applicationBackgroundColor
    },
    LeftTopView:{
        marginTop: '1.3vw', 
        marginHorizontal: '0.65vw', 
        width: "100%", 
        backgroundColor: color.white, 
        paddingVertical: '1.3vw', 
        paddingHorizontal: '0.65vw'
    },
    TopWrapper:{
        marginBottom: '0.97vw', 
        flexDirection: 'row', 
        alignItems: "center"
    },
    BillingText:{
        color: color.themeDark, 
        fontSize: '1.17vw',
    },
    PrintIconButton:{
        borderWidth: 1, 
        borderColor: color.themeDark, 
        borderRadius:'0.26vw', 
        backgroundColor: color.themeDark, 
        marginLeft:'0.65vw'
    },
    PickerWrapper:{
        marginRight: '0.65vw', 
        flexDirection: "row"
    },
    DocRefView:{
        marginRight:'0.97vw',
        flexDirection: "row"
    },
    DocRefLabelTxt:{
        fontSize: '0.8vw', 
        position: "absolute", 
        marginLeft: '0.65vw', 
        backgroundColor: "white", 
        paddingLeft: '0.32vw', 
        paddingRight: '0.32vw', 
        color: "#888888"
    },
    DocInputView:{
        marginTop: '0.32vw', 
        marginHorizontal: '0.32vw', 
        zIndex: -1
    },
    DocInputText:{
        width: '13vw', 
        height: '2.27vw', 
        borderColor: color.black, 
        borderWidth: 1, 
        paddingLeft: '0.65vw',
        fontSize:'0.91vw'
    },
    DocListWrapper:{
        borderRadius: '0.65vw',
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '0.65vw',
        // marginLeft: 10,
        marginTop: '1.95vw',
        position: 'absolute',
        backgroundColor: color.themeBlue,
        color:'white'
    },
    DocsuggList:{
        flexDirection: "row",
        bottom: '0.65vw',
        marginVertical: '0.32vw',
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        zIndex: '0.65vw',
    },
    LeftDataMainWrapper:{
        backgroundColor: color.white, 
        marginHorizontal: '0.65vw', 
        marginTop: '0.97vw'
    },
    TableDataWrapper:{
        flexDirection: 'row', 
        borderBottomColor: "#91939F", 
        borderBottomWidth: 1, 
        paddingVertical: '0.52vw', 
        alignItems: 'center'
    },
    renderIdBtnView:{
        width: '1.95vw',
        height: '1.95vw',
        borderRadius: '1.3vw',
        shadowOpacity: 0.2,
        shadowRadius: '0.26vw',
        shadowOffset: {
            height: '0.13vw',
            width: "0.13vw"
        },
        elevation: '0.26vw',
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: '1.3vw'
    },
    FooterView:{
        flex: 0.4, 
        marginRight: '0.65vw'
    },
    AmtRowWrapper:{
        flexDirection: "row", 
        justifyContent: "flex-end", 
        marginVertical: '0.52vw'
    },
    AmtRowLabelView:{
        flex: 0.4, 
        justifyContent: "center" 
    },
    AmtRowLblTxt:{
        color: "#91939F", 
        fontSize: '0.91vw'
    },
    AmtRowContentWrapper:{
        flex: 0.25, 
        alignItems: "center", 
        justifyContent: "center"
    },
    AmtTxtInput:{
        textAlign: 'center', 
        height: '1.82vw', 
        width: '4.23vw', 
        borderRadius: '0.26vw', 
        borderWidth: 1, 
        borderColor: "#888888",
        fontSize:'0.91vw'
    },
    AmtData:{
        flex: 0.35, 
        alignItems: "flex-end", 
        marginRight: '0.97vw', 
        justifyContent: "center"
    },
    AmtDataText:{
        fontWeight: "500", 
        fontSize: '0.91vw'
    },
    NetAntView:{
        flexDirection: "row", 
        borderTopColor: "#91939F", 
        borderTopWidth: 1, 
        borderBottomWidth: 1, 
        borderBottomColor: "#91939F"
    },
    PayTypeTxt:{
        fontWeight: "500", 
        fontSize: '1.17vw', 
        marginVertical: '0.65vw'
    },
    payTypeView:{
        flexDirection: 'row', 
        marginVertical: '0.97vw'
    },
    PayTypeBtn:{
        marginRight: '1.3vw', 
        flexDirection: "row", 
        alignItems: "center"
    },
    PayTypeRadio:{
        marginRight: '0.52vw', 
        height: '1vw', 
        width: '1vw', 
        borderRadius: '3.25vw', 
        borderWidth: 1, 
        borderColor: "#91939F"
    },
    PayTypeRadioText:{
        fontWeight: "500", 
        fontSize: '0.85vw'
    },
    PaymntBoxMainView:{
        marginVertical: '0.52vw', 
        marginRight: '0.97vw'
    },
    PaymntBoxLblView:{
        flex: 0.4, 
        justifyContent: "center"
    },
    PaymentBoxlabl:{
        fontSize: '0.8vw', 
        fontWeight: "500", 
        marginBottom: '0.65vw'
    },
    PayTypeTxtInput:{
        textAlign: 'center', 
        height: '1.82vw',
        borderRadius: '0.26vw', 
        borderWidth: 1, 
        borderColor: "#91939F",
        fontSize:'0.91vw'
    },
    ButtonText:{
        fontSize: '0.84vw',
        color: color.white,
        fontWeight: "700",
        marginLeft: '0.65vw'
    },
    RightScrollView:{
        width: "35%", 
        height: screenHeight - 80,
    },
    RgtBillingView:{
        marginVertical: '0.97vw' 
    },
    RightBillingTxt:{
        color: color.themeDark, 
        fontSize: '1.17vw', 
        marginBottom: '0.65vw'
    },
    LabSetSuggView:{
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        marginTop: '0.97vw' 
    },
    LabSetButton:{
        marginBottom: '0.65vw', 
        marginRight: '0.65vw'
    },
    NewLabSetBtn:{
        marginBottom: '0.65vw'
    },
    NewLabSetBtnInput:{
        width: '5.2vw', 
        height: '1.95vw', 
        borderColor: color.black, 
        borderWidth: 1, 
        backgroundColor: color.themeShadeBackground,
        borderRadius: ".25vw",
        fontSize:'0.91vw'
    },
    TestNameInputView:{
        paddingRight: '0.8vw', 
        paddingLeft: '0.8vw'
    },
    TestNameInput:{
        height: '1.95vw', 
        borderColor: color.black, 
        borderWidth: 1, 
        backgroundColor: color.themeShadeBackground, 
        marginTop: '0.97vw', 
        paddingLeft: '0.65vw',
        borderRadius: ".25vw",
        fontSize:'0.91vw'
    },
    TestNameSuggestionView:{
        maxHeight: '11.72vw',
        borderRadius: '0.26vw',
        borderWidth: 1, 
        borderColor: "#888888",
        width: '90%',
        justifyContent: 'center',
        alignContent: 'center',
        paddingLeft: '0.65vw',
        marginLeft: '0.65vw',
        marginTop: '5.20vw',
        position: 'absolute',
    },
    TestNameSuggList:{
        flexDirection: "row",
        bottom: '0.65vw',
        marginVertical: '0.32vw',
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        zIndex: '0.65vw',
    },
    TestNameListTxt:{
        fontSize:'0.91vw'
    },
    UnitsView:{
        paddingRight: '0.8vw', 
        paddingLeft: '0.8vw'
    },
    UnitsInput:{
        height: '1.95vw', 
        width: '6.51vw', 
        borderColor: color.black, 
        borderWidth: 1, 
        backgroundColor: color.themeShadeBackground, 
        marginTop: '0.97vw', 
        paddingLeft: '0.65vw',
        borderRadius: ".25vw",
        fontSize:'0.91vw'
    }


});


