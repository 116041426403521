import React from "react";
import {
  View,
  Text,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton } from "../BaseComponent";
import Style from "../../../styles/Style";
import { CommonHistorySectionHeader, CommonSectionHeader,DoctorNotesCommonRightSideHeader } from '../BaseComponent'
// import AsyncStorage from "../../../AsyncStorage";
var Messages = require('../../../utils/InfoMessages')
const platform = Platform.OS;
const pageTitle = "Plan";
const pageName = "Plan";
const isPageName = "isPlan";

const toastConfig = {
  success: (internalState) => (
    <View style={{ height: 30, width: "100%", backgroundColor: "green" }}>
      <Text
        style={{
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          marginTop: "9px",
        }}
      >
        Complaint Created Successfully
      </Text>
    </View>
  ),
  error: () => { },
  info: () => { },
  any_custom_type: () => { },
};

const defaultRouteList = [
  { label: "Oral", value: "Oral" },
  { label: "Vein", value: "Vein" },
  { label: "Subcutaneous", value: "Subcutaneous" },
  { label: "Intramuscular", value: "Intramuscular" },
  { label: "Intradermal", value: "Intradermal" },
  { label: "Intranasal", value: "Intranasal" }
]

export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedInhouseTreatment: {},
      selectedProcedure: "",
      favoriteInhouse: [],
      favoriteProcedure: [],
      complaints: "",
      description: "",
      isInhouseTreatment: false,
      isProcedure: false,
      newItem: "",
      route: "",
      dosage: "",
      newProcedure: "",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,

      isInHouseID: "",
      isProcedureID: "",
      errorMessageFlag: false,

      routeList: defaultRouteList,
      selectedRoute: {},
      showRouterNewButtonFlag: true,

      newButtonValue: "",

      intervention: "",
      lineOfManagement: "",
      provisionalDiagnosis: "",
      userType: this.props.userType,
      psyPlanId: ""
    };
  }


  renderRouteView() {
    var { routeList, selectedRoute, showRouterNewButtonFlag } = this.state;
    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {
          routeList.length > 0 && routeList.map((item, index) => {
            return <View style={styles.CommonBtnView}>
              <CommonButton
                item={item}
                selectedvalue={selectedRoute}
                butttonText={item.label}
                buttonType={"outlineNonTheme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={"selectedRoute"} />
            </View>
          })
        }



        <View style={{ marginBottom: '0.6vw' }}>
          {/* {
                               showRouterNewButtonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={"showRouterNewButtonFlag"} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />
                        } */}

          {!showRouterNewButtonFlag ? (
            <View
              style={[{
                height: '2vw',
                marginTop: 0,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,

              }, Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.TransparentTextInput, {color: color.black}]}
                ref={(text) => (this.newButtonValue = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newButtonValue}
                onChangeText={(text) => {
                  this.setState({
                    selectedRoute: "",
                    newButtonValue: text,
                  });
                }}
                onSubmitEditing={() => {
                  this.onSubmitToAddValue()
                }}
              />
            </View>
          ) : <View style={{}}>
              {/* { (
              this.renderIconNewBtn(
                "plus",
                "New",
                true,
                true,
                this.onPressButton.bind(this),
                this.state.isLocked
              )
            )} */}
              <CommonButton
                item={{ label: "New", value: "New" }}
                selectedvalue={{}}
                butttonText={"New "}
                buttonType={"outlineNonTheme"}
                buttonIcon={require('../../../../assets/images/PlusIcon.png')}
                rightIcon={true}
                buttonAction={this.onPressButton.bind(this)}
                buttonKey={"showRouterNewButtonFlag"} />
              {/* </View> */}
            </View>}

        </View>

      </View>
    )
  }
  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onSubmitToAddValue() {
    if (!this.state.showRouterNewButtonFlag) {
      var { routeList, selectedRoute, showRouterNewButtonFlag, newButtonValue } = this.state;
      var prepateDate = { label: newButtonValue, value: newButtonValue }
      routeList.push(prepateDate);
      routeList = this.removeDuplicate(routeList);
      selectedRoute = prepateDate;
      showRouterNewButtonFlag = true;
      newButtonValue = "";

      this.setState({
        routeList, selectedRoute, showRouterNewButtonFlag, newButtonValue
      })

    }
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getInhouseFrequent();
  }

  componentWillReceiveProps(props) {
    let {
      isInHouseID,
      isProcedureID,
      favoriteInhouse,
      favoriteProcedure,
    } = this.state;

    if (props.editItem) {
      if (props.editItem.title === isPageName) {
        let item = props.editItem.item;
        if (
          isInHouseID !== item.isInHouseID ||
          isProcedureID !== item.isProcedureID
        ) {
          let selectedInhouseTreatment = "";

          favoriteInhouse.map((favItem) => {
            if (favItem.label === item.symptom) {
              selectedInhouseTreatment = favItem.value;
            }
          });
          this.setState({
            isInHouseID: item.isInHouseID,
            isProcedureID: item.isProcedureID,
            selectedInhouseTreatment: { value: item.inHouseName, label: item.inHouseName },
            dosage: item.dosage,
            selectedRoute: { label: item.route, value: item.route },
            comments: item.comments,
            selectedProcedure: { value: item.procedure, label: item.procedure },
            description: item.description,
          });
        }
        if (Object.keys(item).length > 0 && this.state.userType == "PSY") {
          var states = this.state;
          if (item.id) {
            states["psyPlanId"] = item.id;
          }
          if (item.intervention) {
            states["intervention"] = item.intervention;
          }

          if (item.line_of_management) {
            states["lineOfManagement"] = item.line_of_management;
          }
          if (item.provisional_diagnosis) {
            states["provisionalDiagnosis"] = item.provisional_diagnosis;
          }

          this.setState({ states }, () => {
            this.props.clearEditedItem(pageName,{})

          });
          // intervention:response.data.intervention,
          // lineOfManagement:response.data.line_of_management,
          // provisionalDiagnosis:response.data.provisional_diagnosis
        }

      }
    }
  }




  getInhouseFrequent = () => {
    var service_url = Constants.INHOUSE_FREQUENT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getInHouseFrequentSuccess,
      this.getInHouseFrequentFailure
    );
  };

  getInHouseFrequentSuccess = (response) => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      var field = this.state;
      field["favoriteInhouse"] = response.data.inhouse_treatments;
      field["favoriteProcedure"] = response.data.procedures;
      this.setState({ field });
    }
  };

  getInHouseFrequentFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {
    let states = this.state;

    states["isEdit"] = true;

    this.setState({ states });
  };

  addInhouseList = (event) => {
    let { newItem, favoriteInhouse } = this.state;
    let field = this.state;

    if (newItem) {
      // favoriteInhouse.push({ value: newItem, label: newItem });

      favoriteInhouse.push(newItem);

      field["favoriteInhouse"] = JSON.parse(JSON.stringify(favoriteInhouse));

      field["selectedInhouseTreatment"] = { value: newItem, label: newItem };
      field["newItem"] = "";
      field["isInhouseTreatment"] = false;

      this.setState({ field });
    }
  };

  addProcedureList = (event) => {
    let { newProcedure, favoriteProcedure } = this.state;
    let field = this.state;

    if (newProcedure) {
      favoriteProcedure.push(newProcedure);

      field["favoriteProcedure"] = favoriteProcedure;
      field["selectedProcedure"] = { value: newProcedure, label: newProcedure };
      field["newProcedure"] = "";
      field["isProcedure"] = false;

      this.setState({ field });
    }
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  };

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states,
    });
  };

  triggerNewInhouseadd = () => {
    let states = this.state;

    states["isInhouseTreatment"] = true;

    this.setState({ states });
  };

  triggerNewProcedureadd = () => {
    let states = this.state;

    states["isProcedure"] = true;

    this.setState({ states });
  };

  addAllergy = (event) => {


    let states = this.state;
    var service_url = Constants.PLAN_POST;

    let data = {
      patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.patientAppointment.appointment_id,
      // inhouse_treatment: {
      //   name: this.state.selectedInhouseTreatment.value ? this.state.selectedInhouseTreatment.value : "",
      //   dosage: this.state.dosage,
      //   route: this.state.selectedRoute && this.state.selectedRoute.value ? this.state.selectedRoute.value : "",
      //   comments: this.state.comments,
      // },

    };

    if ((this.state.selectedInhouseTreatment && this.state.selectedInhouseTreatment.value) ||
      this.state.dosage ||
      (this.state.selectedRoute && this.state.selectedRoute.value) ||
      this.state.comments
    ) {
      data["inhouse_treatment"] = {
        "name": this.state.selectedInhouseTreatment.value ? this.state.selectedInhouseTreatment.value : "",
        "dosage": this.state.dosage,
        "route": this.state.selectedRoute && this.state.selectedRoute.value ? this.state.selectedRoute.value : "",
        "comments": this.state.comments,
      }

    }

    if ((this.state.selectedProcedure && this.state.selectedProcedure.value) || this.state.description) {
      data["procedure"] = {
        "name": this.state.selectedProcedure.value,
        "description": this.state.description,
      }

    }

    // isInHouseID: item.isInHouseID,
    // isProcedureID: item.isProcedureID,

    if (this.state.isInHouseID) {
      data.inhouse_treatment["id"] = this.state.isInHouseID;
    }

    if (this.state.isProcedureID) {
      data.procedure["id"] = this.state.isProcedureID;
    }

    if (states.selectedInhouseTreatment || states.selectedProcedure) {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    } else {
      this.props.showResposeValue("error", Messages.PlanNewEmptyField);
    }
  };

  getSymptomSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  getSymptomFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = (event) => {
    let states = this.state;
    states["selectedInhouseTreatment"] = "";
    states["complaints"] = "";
    (states["dosage"] = ""),
      (states["selectedRoute"] = {}),
      (states["comments"] = ""),
      // "duration_type": 3,
      (states["selectedProcedure"] = "");
    states["description"] = "";

    this.setState({ states });
  };

  onPressButton(key, selectedvalue) {
    if (key == "selectedInhouseTreatment") {
      // alert(JSON.stringify(selectedvalue))
      this.setState({
        selectedInhouseTreatment: selectedvalue,
      });
    } else if (key == "selectedProcedure") {
      this.setState({
        selectedProcedure: selectedvalue,
      });
    } else if (key == "selectedRoute") {
      this.setState({
        selectedRoute: selectedvalue,
      });
    } else if (key == "showRouterNewButtonFlag") {
      this.setState({
        showRouterNewButtonFlag: false,
      });
    }
  }
  renderPsychiatryPlan(header, value, key) {
    return (
      <View>
        <CommonSectionHeader heading={header} />
        <TextInput

          placeholder=""
          multiline={true}
          value={value}
          onChangeText={(text) => {
            var states = this.state
            states[key] = text
            this.setState({
              states
            })
          }}
          style={[styles.PsyTextInput, {borderColor: color.rightPanelInputBorder, backgroundColor: color.white}]}
        />
      </View>
    )
  }

  postPsychiatryPlan() {
    var states = this.state
    let data = {
      "patient_id": states.patientAppointment.patient_id,
      "appointment_id": states.patientAppointment.appointment_id,
      "intervention": states.intervention,
      "line_of_management": states.lineOfManagement,
      "provisional_diagnosis": states.provisionalDiagnosis
    }
    if (states.psyPlanId) {
      data["id"] = states.psyPlanId

    }

    var service_url = Constants.PSY_PLAN_POST
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.postPsychiatryPlanSuccess,
      this.postPsychiatryPlanFailure
    )
  }
  postPsychiatryPlanSuccess = (response) => {
    if (response.status == "success") {
      this.props.showResposeValue("success", success.message)

      this.setState({
        states
      })



    }

  }
  postPsychiatryPlanFailure(error) {
    this.props.showResposeValue("error", error.message)

  }
  render() {
    var InhouseArr = [];
    var ProcedureArr = [];
    var Inhousedata = this.state.favoriteInhouse;
    var Proceduredata = this.state.favoriteProcedure;

    Inhousedata &&
      Inhousedata.length > 0 &&
      Inhousedata.map((item) => {
        var Inhouse = {
          value: item,
          label: item,
        };
        InhouseArr.push(Inhouse);
      });

    Proceduredata &&
      Proceduredata.length > 0 &&
      Proceduredata.map((item) => {
        var Procedure = {
          value: item,
          label: item,
        };
        ProcedureArr.push(Procedure);
      });

    return (
      <TouchableWithoutFeedback onPress={this.addInhouseList.bind(this), this.addProcedureList.bind(this)}>
        <View>
        <DoctorNotesCommonRightSideHeader title={"Plan"} clearAllData={""} hideClearButton={false} />
          <View style={{}}>
            <CommonSectionHeader heading={"In-House Treatment"} />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
          </View>

          {/* <View>
            {this.renderTitleWithMultipleBtn(
              "",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View> */}

          <View style={styles.InhouseTreatmentView}>
            {this.state.favoriteInhouse.map((item, index) => {
              var data = { label: item, value: item };
              return (
                <View style={styles.CommonBtnView} key={index}>
                  <CommonButton
                    disable={this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedInhouseTreatment}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedInhouseTreatment"}
                  />
                </View>
              );
            })}
          </View>

          {/* <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          {InhouseArr &&
            InhouseArr.map((item) => {
              return this.renderSelectEyes(
                item,
                false,
                false,
                "selectedInhouseTreatment",
                this.state.selectedInhouseTreatment
              );
            })}
        </View> */}

          {this.state.isInhouseTreatment ? (
            <View
              style={[{
                height: '2vw',
                marginTop: 0,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,

              }, Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.TransparentTextInput, {color: color.black}]}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newItem}
                onChangeText={(text) => {
                  this.setState({
                    selectedInhouseTreatment: "",
                    newItem: text,
                  });
                }}
                onSubmitEditing={this.addInhouseList.bind(this)}
              />
            </View>
          ) : <View style={{ marginLeft: '1.2vw' }}>{(
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewInhouseadd.bind(this),
              this.state.isLocked
            )
          )}</View>}



          <View
            style={{
              bottom: 0,
              marginBottom: 0,
              marginTop: '0.6vw',
            }}
          >
            <View style={{}}>
              <CommonSectionHeader heading={"Dosage Strength"} />
              {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
            </View>
            <View style={styles.TextFieldView}>
              {this.renderTextFieldView(
                "",
                "dosage",
                this.state.dosage,
                true,
                1,
                '', {}, '', true, this.state.isLocked
              )}
            </View>
          </View>
          <View>
            <View style={{ marginBottom: '0.6vw' }}>
              <CommonSectionHeader heading={"Route"} />
              {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
            </View>
            <View style={styles.RouteView}>
              {this.renderRouteView()}
            </View>

          </View>
          <View style={{}}>
            <CommonSectionHeader heading={"Comments"} />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
          </View>
          <View style={{ marginHorizontal: '1.2vw' }}>
            {this.renderTextFieldView(
              "",
              "comments",
              this.state.comments,
              true,
              4,
              '', {}, '', true, this.state.isLocked
            )}
          </View>
          {/* {this.renderIconBtn("plus", "New", true)} */}
          <View style={{ marginVertical: '1vw' }}>
            <CommonSectionHeader heading={"Procedure"} />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
          </View>
          {/* <View style={{  }}>
            {this.renderTitleWithMultipleBtn(
              "",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View> */}
          {/* <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          {ProcedureArr &&
            ProcedureArr.map((item) => {
              return this.renderSelectEyes(
                item,
                false,
                false,
                "selectedProcedure",
                this.state.selectedProcedure
              );
            })}
        </View> */}
          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.favoriteProcedure.map((item, index) => {
              var data = { label: item, value: item };
              return (
                <View style={styles.ProcedureCommonBtn} key={index}>
                  <CommonButton
                    disable={this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedProcedure}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedProcedure"}
                  />
                </View>
              );
            })}
          </View>
          {this.state.isProcedure ? (
            <View
              style={[{
                height: '2vw',
                marginTop: '0.6vw',
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: this.state.isLocked ? color.disableComponentColor : color.black,
                width: "50%",
                zIndex: 101,
              }, Style.allButtonBorderRadius]}
            >
              <TextInput
                disable={this.state.isLocked}
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.TransparentTextInput, {color: color.black}]}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newProcedure}
                onChangeText={(text) => {
                  this.setState({ newProcedure: text });
                }}
                onSubmitEditing={this.addProcedureList.bind(this)}
              />
            </View>
          ) : <View style={{ marginLeft: '1.2vw' }}>{(
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewProcedureadd.bind(this),
              this.state.isLocked
            )
          )}</View>}

          {/* //   ) */}
          <View style={{ marginTop: '1vw' }}>
            <CommonSectionHeader heading={"Description"} />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
          </View>
          <View style={{ marginHorizontal: '1.2vw' }}>
            {this.renderTextFieldView(
              "",
              "description",
              this.state.description,
              true,
              4,
              '', {}, '', true, this.state.isLocked
            )}
          </View>

          {
            this.state.userType == "PSY" ?
              <View style={{ marginTop: 10 }}>
                {this.renderPsychiatryPlan("Intervention", this.state.intervention, "intervention")}
                {this.renderPsychiatryPlan("Line Of Management", this.state.lineOfManagement, "lineOfManagement")}
                {this.renderPsychiatryPlan("Provisional diagnosis", this.state.provisionalDiagnosis, "provisionalDiagnosis")}
              </View>
              : null
          }
        
          <View style={{ marginTop: '1.2vw', zIndex: -1, alignSelf: "center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
              this.addAllergy()
              if (this.state.userType == "PSY") {
                this.postPsychiatryPlan()
              }
            }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View>
            {this.state.show ? (
              <View
                style={{
                  height: '2.6vw',
                  width: "100%",
                  backgroundColor: "green",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Symptom Created Successfully
                </Text>
              </View>
            ) : null}
          </View>
          <View>
            {this.state.successOrErrorMessage ? (
              <View
                style={{
                  height: '2.6vw',
                  width: "100%",
                  backgroundColor: this.state.successMessageFlag
                    ? "green"
                    : this.state.errorMessageFlag
                      ? "red"
                      : null,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {this.state.successMessageFlag
                    ? "Test Created Successfully"
                    : this.state.errorMessageFlag
                      ? "Test  Failed"
                      : null}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
const styles = StyleSheet.create({
      InhouseTreatmentView: { 
        flexDirection: "row", 
        flexWrap: "wrap", 
        zIndex: -1, 
        marginLeft: '1.2vw', 
        marginTop: '1vw' 
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      TransparentTextInput: {
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        fontSize: '0.9vw'
      },
      TextFieldView: { 
        width: '5.5vw', 
        marginLeft: '1.2vw' 
      },
      RouteView: { 
        marginHorizontal: '1.2vw', 
        marginTop: '0.6vw' 
      },
      ProcedureCommonBtn: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw', 
        marginLeft: '1.2vw' 
      },
      PsyTextInput: { 
        marginTop: '0.6vw', 
        marginBottom: '0.6vw', 
        paddingLeft: '0.6vw', 
        paddingVertical: '0.6vw', 
        borderWidth: 1, 
        borderRadius: 4, 
        height: '4.9vw', 
        marginLeft: '1.2vw', 
        fontSize: '0.9vw'
      }

})