import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import {CommonHistorySectionHeader} from '../BaseComponent'
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
// import Loader from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import moment from "moment";

const platform = Platform.OS;
const pageTitle = "Patient Categories";
const pageName = "Patient Categories";
const isPageName = "isPatientCategories";

export default class PatientCategories extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      allergyData: [],
      isAllergy: isPageName===this.props.selectedView?true:false,
      // isLoading : true
      PatientCategoryList :[]
    };
  }

  componentDidMount() {
    
    this.getAllergy();
  }

  componentWillReceiveProps(props) {

    if(props.reloadData && props.reloadData === pageName) {
      // call once
       this.getAllergy();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isAllergy){

      this.setState({ isAllergy: updateFontColor });
    }
  }

  getAllergy = () => {

    var service_url = Constants.PATIENT_CATAGORIES_POST + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id;
  

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAllergySuccess,
      this.getAllergyFailure
    );
  };

  getAllergySuccess = response => {
    if (response.status === "success") {
      this.setState({
        PatientCategoryList : response?.data
      })
    }
  };

  getAllergyFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  



  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }
  

    



  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  render() {

    return (
        <View style={{}} >
        {this.renderTitleBorder(this.state.isAllergy, pageTitle, isPageName, this.triggerNewItem)}

        <View style={{ display:'flex', flexDirection:'row', marginVertical:"1vw", flexWrap:'wrap', width:"48vw"}}>
          {
            this.state.PatientCategoryList && this.state.PatientCategoryList.length > 0 ?
            this.state.PatientCategoryList.map((item, index)=>(
                <View style={{backgroundColor:"#f7f3f3", paddingHorizontal:"0.8vw", paddingVertical:'0.3vw', borderWidth:1, borderRadius:'0.4vw', marginRight:"1vw", borderColor:'grey', marginBottom:'0.5vw'}}>
                    <Text style={{fontSize:'0.9vw'}}>{item?.category}</Text>
                </View>
            )) : null
          }
        </View>

      </View>
    );
  }
}
const styles = StyleSheet.create({
  TableHead1: { 
    marginTop: '0.6vw' 
  },
  AllergySymptomsView: {
    flex: 0.23 ,
    marginHorizontal: '1.2vw'
  },
  AllergySymptomsText: {
    fontSize:'1vw',
    color:"#888888",
    marginTop:'0.3vw'
  }
})