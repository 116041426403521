//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity ,Image,Linking} from 'react-native';
import { color } from '../../../styles/Color';
import { QMSHeader, NavigationTopHeader } from './../BaseComponent';
import { Token } from './Token/Token'
import { Departments } from './departments'
import { QMSDashboardContent } from './qmsDashboard'
const screenHeight = Dimensions.get("window").height;
// import Service from "../../../network/Service";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import AsyncStorage from "../../../AsyncStorage";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import { ShowToken } from './ShowToken'

const navigationHeaderList = [
    { label: "Dashboard", value: "QMSDashBoard" }
]

// create a component
class QMSDashBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "qmsDashBoard",
            counterID:"",
            clintID:"",
            // success messgae
            responseType: "",
            responseMeaasge: "",
        }
    }

    async componentDidMount(){

        const clintID = await  AsyncStorage.getItem("UserID");
        if(clintID){
            this.setState({
                clintID:clintID
            })
        }

      

        if(this.props.navigation && this.props.navigation.state && this.props.navigation.state.params && this.props.navigation.state.params.selectedCounter && this.props.navigation.state.params.selectedCounter.id){
            var counterID=this.props.navigation.state.params.selectedCounter.id;
            this.setState({
                counterID:counterID
            })
        }
    }


    renderComponent() {

        var selectedComponent = this.state.selectedScreen;

        var componentLookup = {
            token: (<Token showResposeValue={this.showResposeValue.bind(this)} counterID={this.state.counterID}  clintID={this.state.clintID}/>),
            departments: (<Departments showResposeValue={this.showResposeValue.bind(this)} clintID={this.state.clintID} counterID={this.state.counterID}/>),
            qmsDashBoard: (<QMSDashboardContent showResposeValue={this.showResposeValue.bind(this)} counterID={this.state.counterID} clintID={this.state.clintID}/>),
            showToken:(<ShowToken clintID={this.state.clintID}/>)
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }


    renderQMSSideMenu() {
        return (
            <View>
                {this.renderSideMenuItems("Dashboard", "qmsDashBoard",require('../../../../assets/images/QMS_Images/Dashboasrd.png'))}
                {this.renderSideMenuItems("Departments", "departments",require('../../../../assets/images/QMS_Images/Departments.png'))}
                {this.renderSideMenuItems("Token", "token",require('../../../../assets/images/QMS_Images/Token.png'))}
                {/* {this.renderSideMenuItems("Message", "token",require('../../../../assets/images/QMS_Images/Message.png'))} */}
                {this.renderSideMenuItems("Show Tokens", "showToken",require('../../../../assets/images/QMS_Images/Message.png'))}

            </View>
        )
    }

    renderSideMenuItems(label, menuKey,image) {
        return (
            <TouchableOpacity onPress={() => {
                
                    if(menuKey == "showToken"){
                        // old code 
                            // var url  = Constants.QMA_DOMAIN+"tokens/web-socket/"+this.state.clintID+"/";
                            // window.open(url);
                        // old code 

                        // const openURL = (ShowToken) => {
                            // window.open(ShowToken);
                            // window.postMessage("",ShowToken);
                            // window.screen(ShowToken)
                            // Linking.openURL(ShowToken).catch((err) => console.error('An error occurred', err));
                        //   }

                        this.props.navigation.navigate("showToken")
                        
                    }else{
                        this.setState({
                            selectedScreen: menuKey
                        })
                    }

            }} style={{ paddingVertical:10,alignItems:'center',flexDirection:'row' ,paddingHorizontal: 15, borderBottomColor: color.white, borderBottomWidth: 1 }}>
                <Image
          source={image}
          style={{
            height: 30,
            width: 30,
            marginRight:15,
            tintColor: color.white,//color.lightGray
          }}
          resizeMode="contain"
        />
                <Text style={{ fontSize: 17, color: color.white }}>{label}</Text>
            </TouchableOpacity>
        )
    }

    render() {
        return (
            <View>
                                                {
                                    this.state.responseType == "success" ?
                                        this.successAlert() :
                                        this.state.responseType == "error" ?
                                            this.errorAlert() : null
                                }
                <QMSHeader navigation={this.props.navigation} />
                <View style={{ flex: 1, height: screenHeight - 65, flexDirection: 'row', backgroundColor: color.applicationBackgroundColor }}>
                    <View style={{ flex: 0.18, backgroundColor: color.themeDark }} >
                        {this.renderQMSSideMenu()}
                    </View>

                    <View style={{ flex: 0.82 }} >
                        {
                            // this.state.selectedScreen ?
                                this.renderComponent()
                                // :
                                // this.renderDashboardView()
                        }

                    </View>

                </View>
            </View>
        );
    }

    
    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }


    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "40%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"SUCCESS: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"green"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {

        let errorList = (this.state.responseMeaasge).split(",")
        return (
            <View style={{ flex: 1, zIndex: 3, width: "35%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, minHeight: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, marginLeft: 10, flex: 0.95 }}>
                    <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: 10 }}>
                        <Icon size={25} name={"ban"} color={"#FFF"} /></View>
                    <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
                        <View>
                            <Text style={{ fontWeight: "700", color: "#FFF", fontSize: 12 }}>{"ERROR: "}
                                {errorList && errorList.map((item, index) => {
                                    return <View key={index}><Text style={{ fontWeight: "400", color: "#FFF", fontSize: 12, marginBottom: 8 }}>{item}</Text></View>
                                })}
                            </Text>
                        </View>

                    </View>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#fff"} />
                </TouchableOpacity>
            </View>
        );
    }

}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default QMSDashBoard;



// createClientInQMS()
// createCounterInQMS()

// getListfoActiveTokensForQMS()
// validateForShowCallTokenIcon()

// ----------------------

// // base component
// qmsSettingsButton

// root component
// toggle

// home
// and call 




// changeQMSSelectedToken()
