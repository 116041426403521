//import liraries
import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  ScrollView, 
  TouchableOpacity
} from "react-native";
// import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { color } from "../../../../styles/Color";
import {
  OBGYNHistorySectionHeading,
  CommonButton,
  CommonAddButton,
} from "../../BaseComponent";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";

import { DatePicker } from "antd";
import moment from "moment";

const pageName = "isOvulationChart";
const platform = Platform.OS;

// create a component
export class OvulationChartNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      prevOvulation: {},
      therapy: {},
      favTherapy: [],
      follicularPhase: "",
      ovulatoryPhase: "",
      lutealPhase: "",
      comments: "",
      lastMenstrualDate: "",
      presentDate: moment(new Date()).format('YYYY-MM-DD'),
      pouchOfDouglas: "",
      isTherapy: false,
      isProcedure: false,
      isRightOvary: false,
      isLeftOvary: false,
      isEndometrium: false,
      newTherapy: "",
      newProcedure: "",
      newEndometrium: "",
      newLeftOvary: "",
      newRightOvary: "",
      favProcedure: [],
      selectedProcedure: {},
      selectedTherapy: {},
      favRightOvary: [],
      selectedRightOvary: {},
      favLeftOvary: [],
      selectedLeftOvary: {},
      favEndometrium: [],
      selectedEndometrium: {},
      favPrevOvulation: [],
      selectedPrevOvulation: {},
      newPrevOvulation: "",
      id: "",
      ovulation_induction_id: "",
    };
  }

  componentDidMount() {
    this.getPrevOvulation();
    this.getTempPrev();
  }

  componentWillReceiveProps(props) {
    // console.log("****editHistoryItem********>> " + JSON.stringify(props.editHistoryItem))

    if (
      props &&
      props.editHistoryItem &&
      props.editHistoryItem.pageName == pageName
    ) {
      if (
        props &&
        props.editHistoryItem &&
        Object.keys(props.editHistoryItem).length > 0
      ) {
        let item = props.editHistoryItem.editItem;

        this.setState(
          {
            id: item.id,
            selectedTherapy: {
              value: props.editHistoryItem.lmd.therapy,
              label: props.editHistoryItem.lmd.therapy,
            },
            selectedProcedure: {
              value: props.editHistoryItem.lmd.procedure,
              label: props.editHistoryItem.lmd.procedure,
            },
            follicularPhase: props.editHistoryItem.dov.follicular_phase,
            ovulatoryPhase: props.editHistoryItem.dov.ovulatory_phase,
            lutealPhase: props.editHistoryItem.dov.luteal_phase,
            comments: props.editHistoryItem.dov.comments,
            // selectedRightOvary : {value: item.right_ovary, label: item.right_ovary } ,
            lastMenstrualDate: props.editHistoryItem.dov.last_menstrual_period,
            presentDate: item.date_of_visit,
            selectedLeftOvary: {
              value: item.left_ovary,
              label: item.left_ovary,
            },
            selectedRightOvary: {
              value: item.right_ovary,
              label: item.right_ovary,
            },
            selectedEndometrium: {
              value: item.endometrium,
              label: item.endometrium,
            },
            pouchOfDouglas: item.pouch_of_douglas,
          },
          () => {
            this.props.editHistory("");
          }
        );
        // alert("componentWillReceiveProps ++ " + JSON.stringify(props.editHistoryItem.editItem.id))
      }
    }

    if(props.newItemclear){
      var states = this.state
      states["selectedTherapy"] = {};
      states["selectedProcedure"] = {};
      states["lastMenstrualDate"] = "";
      states["selectedPrevOvulation"] = {};

      this.setState({
        states
      })
      this.clearAllValues();
      props.renderNewItemClear(false)
    }
  }

  getHeader() {
    var service_url =
      Constants.GET_OVULATION_HEADER +
      "?patient_id=" +
      this.state.patientAppointment.patient_id +
      "&last_menstrual_period=" +
      this.state.lastMenstrualDate;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getHeaderSuccess,
      this.getHeaderFailure
    );
  }

  getHeaderSuccess = (response) => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      var field = this.state;

      field["selectedTherapy"] = {
        value: response.data.therapy,
        label: response.data.therapy,
      }
      field["selectedProcedure"] = {
        value: response.data.procedure,
        label: response.data.procedure,
      };;
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  getDataByDate(hideDeleteIconFlag) {
    var service_url =
      Constants.GET_OVULATION +
      "?patient_id=" +
      this.state.patientAppointment.patient_id +
      "&ovulation_induction_id=" +
      this.state.ovulation_induction_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getDataByDateSuccess,
      this.getOvulationFailure,
      hideDeleteIconFlag
    );
  }

  getDataByDateSuccess = (response, hideDeleteIconFlag) => {
    if (response.status === "success") {

      this.setState({
        selectedTherapy: {
          value: response.data.ovulation_induction_header.therapy,
          label: response.data.ovulation_induction_header.therapy,
        },
        selectedProcedure: {
          value: response.data.ovulation_induction_header.procedure,
          label: response.data.ovulation_induction_header.procedure,
        }
        
      })
      this.props.getDataByDateValues(response.data, hideDeleteIconFlag);
      // var field = this.state;
      // var getValue = response.data.ovulation_induction_header;
      // var getOvaryValue = response.data.ovulation_induction_values;

      // var index = getOvaryValue.length - 1;

      // // field["id"]=getOvaryValue[0].id;
      // field["selectedRightOvary"] = {
      //   value: getOvaryValue[index].right_ovary,
      //   label: getOvaryValue[index].right_ovary,
      // };
      // field["selectedLeftOvary"] = {
      //   value: getOvaryValue[index].left_ovary,
      //   label: getOvaryValue[index].left_ovary,
      // };
      // field["selectedEndometrium"] = {
      //   value: getOvaryValue[index].endometrium,
      //   label: getOvaryValue[index].endometrium,
      // };
      // field["pouchOfDouglas"] = getOvaryValue[index].pouch_of_douglas;
      // field["selectedTherapy"] = {
      //   value: getValue.therapy,
      //   label: getValue.therapy,
      // };
      // field["selectedProcedure"] = {
      //   value: getValue.procedure,
      //   label: getValue.procedure,
      // };
      // field["lastMenstrualDate"] =
      //   response.data.ovulation_induction_header.last_menstrual_period;
      // field["presentDate"] = getOvaryValue[0].date_of_visit;
      // field["follicularPhase"] =
      //   response.data.ovulation_induction_header.follicular_phase;
      // field["ovulatoryPhase"] =
      //   response.data.ovulation_induction_header.ovulatory_phase;
      // field["lutealPhase"] =
      //   response.data.ovulation_induction_header.luteal_phase;
      // field["comments"] = response.data.ovulation_induction_header.comments;
      // this.setState({
      //   field,
      //   // isLoading: false
      // });
    }
  };

  getTempPrev() {
    var service_url =
      Constants.TEMPLATE_OVULATION +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTempPrevSuccess,
      this.getOvulationFailure
    );
  }

  getTempPrevSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["favTherapy"] = response.data.therapy;
      field["favProcedure"] = response.data.procedure;
      field["favRightOvary"] = response.data.right_ovary;
      field["favLeftOvary"] = response.data.left_ovary;
      field["favEndometrium"] = response.data.endometrium;
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  getPrevOvulation() {
    var service_url =
      Constants.GET_PREV_OVULATION +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrevOvulationSuccess,
      this.getOvulationFailure
    );
  }

  getPrevOvulationSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["favPrevOvulation"] = response.data;
    
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  getDateValue(label, key, selectedValue, listData) {
    return (
      <View>
        <Text style={{ fontSize: 15, fontWeight: "500",marginBottom:12 }}>
          {label}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {listData.length > 0
            ? listData.map((item, index) => {
              var getItem = item;
              if (key == "selectedPrevOvulation") {
                // alert(JSON.stringify(item))
                getItem["value"] = item.id;
              } else {
                getItem = { value: item, label: item };
              }
              var textToDisplay = moment(getItem.last_menstrual_period).format("DD-MM-YYYY")

              return (
                <View
                  style={{ marginBottom: 10, marginRight: 10 }}
                  key={index}
                >
                  <CommonButton
                    item={getItem}
                    selectedvalue={selectedValue}
                    butttonText={textToDisplay}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressDate.bind(this)}
                    buttonKey={key}
                  />
                </View>
              );
            })
            : null}
        </View>
      </View>
    );
  }

  commonOvulation(label, key, selectedValue, listData) {
    // var prevOvulationDate =  [
    //     { label: listData, value: listData },
    // ]

    return (
      <View>
        <Text style={{ fontSize: 15, fontWeight: "500", marginTop: 20 }}>
          {label}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 15 }}>
          {listData.length > 0
            ? listData.map((item, index) => {
              var getItem = item;
              if (key == "selectedPrevOvulation") {
                // alert(JSON.stringify(item))
                getItem["value"] = item.id;
              } else {
                getItem = { value: item, label: item };
              }
              return (
                <View
                  style={{ marginBottom: 10, marginRight: 10 }}
                  key={index}
                >
                  <CommonButton
                    item={getItem}
                    selectedvalue={selectedValue}
                    butttonText={
                      key == "selectedPrevOvulation"
                        ? getItem.last_menstrual_period
                        : getItem.label
                    }
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={key}
                  />
                </View>
              );
            })
            : null}
        </View>
      </View>
    );
  }

  onPressDate(key, selectedValue, getItem) {
    this.setState(
      {
        selectedPrevOvulation: selectedValue,
        ovulation_induction_id: selectedValue.id,
        lastMenstrualDate:selectedValue.last_menstrual_period
      },
      () => this.getDataByDate(true)
    );
  }

  onPressButton(key, selectedValue) {
    if (key == "selectedPrevOvulation") {
      this.setState({
        selectedPrevOvulation: selectedValue,
      });
    } else if (key == "selectedTherapy") {
      this.setState({
        selectedTherapy: selectedValue,
      });
    } else if (key == "selectedProcedure") {
      this.setState({
        selectedProcedure: selectedValue,
      });
    } else if (key == "selectedRightOvary") {
      this.setState({
        selectedRightOvary: selectedValue,
      });
    } else if (key == "selectedLeftOvary") {
      this.setState({
        selectedLeftOvary: selectedValue,
      });
    } else if (key == "selectedEndometrium") {
      this.setState({
        selectedEndometrium: selectedValue,
      });
    }
  }

  renderReriodsDaysTextBox(label, placeholder, key, values) {
    return (
      <View style={{ marginTop: 20, marginBottom: 10, flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
        <Text style={{ fontSize: 15, fontWeight: "500" }}>{label}</Text>
        <View style={{ flexDirection: "row", }}>
          <TextInput
            value={values}
            placeholder={placeholder}
            style={{
              paddingLeft: 10,
              backgroundColor: color.white,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderColor: color.rightPanelInputBorder,
              borderWidth: 1,
              height: 35,

              width: key == "pouchOfDouglas" ? 120 : "100%"
            }}
            onChangeText={(text) => {
              var statets = this.state;
              statets[key] = text;
              this.setState({ statets });
            }}
          />
          <View
            style={{
              paddingHorizontal: 20,
              justifyContent: "center",
              height: 35,
              borderColor: color.rightPanelInputBorder,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderWidth: 1,
            }}
          >
            <Text>{"ml"}</Text>
          </View>
        </View>
      </View>
    );
  }

  renderRichTextBox(label, key, values) {
    return (
      <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius,]}>
        <Text style={{ fontSize: 15, fontWeight: "500", marginTop: 15, marginLeft: 10, marginBottom: 10, }}>{label}</Text>
        <TextInput
          value={values}
          placeholder={""}
          multiline={true}
          maxLength={key == "follicularPhase" || key == "ovulatoryPhase" || key == "lutealPhase" ? 100 : key=="comments" ? 300 :null }
          textAlignVertical="top"
          style={{
            marginTop: 5,
            marginBottom: 20,
            textAlignVertical: "top",
            paddingLeft: 10,
            backgroundColor: color.white,
            borderRadius: 8,
            // borderColor: color.rightPanelInputBorder,
            // borderWidth: 1,
            height: 65,
            marginHorizontal: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
          }}
          onChangeText={(text) => {
            var statets = this.state;
            statets[key] = text;
            this.setState({ statets });
          }}
        />
      </View>
    );
  }

  updateState = (key, value) => {
    var fields = this.state;
    [key] = value;
    this.setState({ fields });

    // this.createFilterTag();
  };


  onPressAddButton() {
    var states = this.state;

    var start = moment(this.state.lastMenstrualDate);
    var end = moment(this.state.presentDate);
    var countDays = end.diff(start, "days");

    var data = {
      patient_id: this.state.patientAppointment.patient_id,
      last_menstrual_period: this.state.lastMenstrualDate,
      therapy: this.state.selectedTherapy.value,
      procedure: this.state.selectedProcedure.value,
      follicular_phase: this.state.follicularPhase,
      ovulatory_phase: this.state.ovulatoryPhase,
      luteal_phase: this.state.lutealPhase,
      comments: this.state.comments,
      ovulation_induction_values: {
        id: this.state.id ? this.state.id : "",
        date_of_visit: this.state.presentDate,
        day: countDays,
        right_ovary: this.state.selectedRightOvary.value,
        left_ovary: this.state.selectedLeftOvary.value,
        endometrium: this.state.selectedEndometrium.value,
        pouch_of_douglas: this.state.pouchOfDouglas,
      },
    };

    // if(this.state.selectedRightOvary.value) {
    var service_url = Constants.OVULATION_CHART;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.saveOvulationChartSuccess,
      this.saveOvulationChartFailure
    );
    // }else{
    //     this.props.showResposeValue('error', "Select required fields to proceed")
    // }
  }

  saveOvulationChartSuccess = (success) => {
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message);
      this.clearAllValues();
      this.getPrevOvulation();
      this.getDataByDate(false)
      this.props.refreshRighSideComponent(pageName);
    } else {
      this.props.showResposeValue("error", success.message);
    }
  };
  saveOvulationChartFailure = (error) => {
    this.props.showResposeValue("error", error.message);
  };

  clearAllValues() {
    var states = this.state;
    states["id"] = "";
    states["selectedPrevOvulation"] = {};
    states["follicularPhase"] = "";
    states["ovulatoryPhase"] = "";
    states["lutealPhase"] = "";
    states["comments"] = "";
    states["presentDate"] = moment(new Date()).format('YYYY-MM-DD');
    states["selectedRightOvary"] = {};
    states["selectedLeftOvary"] = {};
    states["pouchOfDouglas"] = "";
    states["selectedEndometrium"] = {};
    this.setState({ states });
  }

  addProcedureList = (event) => {
    let { newProcedure, favProcedure } = this.state;
    let field = this.state;

    if (newProcedure) {
      favProcedure.push(newProcedure);
      field["favProcedure"] = favProcedure;
      field["selectedProcedure"] = { value: newProcedure, label: newProcedure };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newProcedure"] = "";
      field["isProcedure"] = false;

      this.setState({ field });
    }
  };

  addRightOvaryList = (event) => {
    let { newRightOvary, favRightOvary } = this.state;
    let field = this.state;

    if (newRightOvary) {
      favRightOvary.push(newRightOvary);
      field["favRightOvary"] = favRightOvary;
      field["selectedRightOvary"] = {
        value: newRightOvary,
        label: newRightOvary,
      };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newRightOvary"] = "";
      field["isRightOvary"] = false;

      this.setState({ field });
    }
  };

  addTherapyList = () => {
    let { newTherapy, favTherapy } = this.state;
    let field = this.state;

    if (newTherapy) {
      //    alert(newTherapy)
      favTherapy.push(newTherapy);
      field["favTherapy"] = favTherapy;
      field["selectedTherapy"] = { value: newTherapy, label: newTherapy };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newTherapy"] = "";
      field["isTherapy"] = false;

      this.setState({ field });
    }
  };

  addEndometriumList = (event) => {
    let { newEndometrium, favEndometrium } = this.state;
    let field = this.state;

    if (newEndometrium) {
      favEndometrium.push(newEndometrium);
      field["favEndometrium"] = favEndometrium;
      field["selectedEndometrium"] = {
        value: newEndometrium,
        label: newEndometrium,
      };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newEndometrium"] = "";
      field["isEndometrium"] = false;

      this.setState({ field });
    }
  };

  addLeftOvaryList = (event) => {
    let { newLeftOvary, favLeftOvary } = this.state;
    let field = this.state;

    if (newLeftOvary) {
      favLeftOvary.push(newLeftOvary);
      field["favLeftOvary"] = favLeftOvary;
      field["selectedLeftOvary"] = { value: newLeftOvary, label: newLeftOvary };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newLeftOvary"] = "";
      field["isLeftOvary"] = false;

      this.setState({ field });
    }
  };


  triggerNewHistoryadd = () => {
    let states = this.state;
    states["isTherapy"] = true;
    states["isProcedure"] = false;
    states["isEndometrium"] = false;
    states["isLeftOvary"] = false;
    states["isRightOvary"] = false;
    this.setState({ states });
  };

  triggerNewProcedureadd = () => {
    let states = this.state;
    states["isTherapy"] = false;
    states["isProcedure"] = true;
    states["isEndometrium"] = false;
    states["isLeftOvary"] = false;
    states["isRightOvary"] = false;
    this.setState({ states });
  };

  triggerNewEndometriumadd = () => {
    let states = this.state;
    states["isEndometrium"] = true;
    states["isTherapy"] = false;
    states["isProcedure"] = false;
    states["isLeftOvary"] = false;
    states["isRightOvary"] = false;
    this.setState({ states });
  };

  triggerNewLeftOvaryadd = () => {
    let states = this.state;
    states["isEndometrium"] = false;
    states["isTherapy"] = false;
    states["isProcedure"] = false;
    states["isLeftOvary"] = true;
    states["isRightOvary"] = false;
    this.setState({ states });
  };

  triggerNewRightOvaryadd = () => {
    let states = this.state;
    states["isEndometrium"] = false;
    states["isTherapy"] = false;
    states["isProcedure"] = false;
    states["isLeftOvary"] = false;
    states["isRightOvary"] = true;
    this.setState({ states });
  };

  renderIconNewBtn = (
    icon,
    text,
    createBtn,
    pressEvent = false,
    callBack = null,
    disabled = false
  ) => {
    return (
      <TouchableOpacity
        onPress={pressEvent ? callBack : null}
        disabled={disabled}
      >
        <View
          style={[
            {
              // height: 30,
              // width: text === "New" ? 80 : 103,
              // justifyContent: "center",
              // alignItems: "center",
              // borderRadius: createBtn || text === "New" ? 15 : 8,
              flexDirection: "row",
              backgroundColor: createBtn
                ? color.white
                : text === "New"
                  ? color.white
                  : color.themePink,
              // justifyContent: "space-evenly",
              borderWidth: createBtn || text === "New" ? 1 : 0,
              borderColor: text === "New" ? "#848484" : color.white,
            },
            Style.componentBtnDDPadding,
          ]}
        >
          <Icon
            style={{ marginRight: 15 }}
            name={icon}
            size={platform === "web" ? 15 : 20}
            color={text === "New" ? "#848484" : "#ffffff"}
          />
          <Text
            style={{
              fontSize: platform === "web" ? 13 : 13,
              color: text === "New" ? "#848484" : color.white,
              fontWeight: "700",
            }}
          >
            {text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  //   onClickToAdd(){
  //       if(this.state.isTherapy){
  //           this.addTherapyList.bind(this)
  //       }
  //   }

  render() {

    const dateFormat = "YYYY-MM-DD";
    // alert(this.state.favTherapy)
    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            if (this.state.newTherapy) {
              this.addTherapyList();
            } else if (this.state.newProcedure) {
              this.addProcedureList();
            } else if (this.state.newRightOvary) {
              this.addRightOvaryList();
            } else if (this.state.newLeftOvary) {
              this.addLeftOvaryList();
            } else if (this.state.newEndometrium) {
              this.addEndometriumList();
            }
          }}
        >
          <View style={styles.container}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "80%" }}>
                <Text
                  style={{ fontSize: 20, fontWeight: "500", marginTop: 20 }}
                >
                  Ovulation Chart
                </Text>
              </View>
              <View style={{ width: "20%", marginTop: 20 }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: color.themeDark,
                    borderRadius: 8,
                    paddingRight: 10,
                    paddingLeft: 30,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  onPress={() => {
                    this.clearAllValues();
                  }}
                >
                  <Text style={{ fontSize: 15, color: "white" }}>{"New"}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10, paddingHorizontal: 10 }, styles.shadow]}>

              <View style={[Style.doctorNotesSectionView, { paddingHorizontal: 10 }]}>

                {this.getDateValue(
                  "Previous Ovulation Chart",
                  "selectedPrevOvulation",
                  this.state.selectedPrevOvulation,
                  this.state.favPrevOvulation
                )}
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end', marginBottom: 10 }}>
                <View style={{ width: "65%" }}>
                  <Text
                    style={{ fontSize: 15, fontWeight: "500", marginBottom:6 }}
                  >
                    Last Menstrual Period
                </Text>
                </View>
                <View style={{ width: "35%" }}>
                  <DatePicker
                    disabledDate={current => {
                      return current && current > moment().add(0, "days");
                    }}

                    disabled={this.state.id ? true : false}
                    value={
                      this.state.lastMenstrualDate
                        ? moment(this.state.lastMenstrualDate, dateFormat)
                        : this.state.lastMenstrualDate
                    }
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");

                      this.setState({ lastMenstrualDate: converted_date }, () => {
                        this.updateState(
                          "lastMenstrualDate",
                          this.state.lastMenstrualDate
                        );
                        this.getHeader();
                      });
                    }}
                    // defaultValue={"From"}
                    format={"DD-MM-YYYY"}
                    placeholder={"Select"}
                    style={{
                      borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                      alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, width: '100%'
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              {this.commonOvulation(
                "Therapy",
                "selectedTherapy",
                this.state.selectedTherapy,
                this.state.favTherapy
              )}
              {this.state.isTherapy ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newTherapy}
                    onChangeText={(text) => {
                      this.setState({ newTherapy: text });
                    }}
                    onSubmitEditing={this.addTherapyList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewHistoryadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              {this.commonOvulation(
                "Procedure",
                "selectedProcedure",
                this.state.selectedProcedure,
                this.state.favProcedure
              )}
              {this.state.isProcedure ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newProcedure}
                    onChangeText={(text) => {
                      this.setState({ newProcedure: text });
                    }}
                    onSubmitEditing={this.addProcedureList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewProcedureadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 1 }, styles.shadow]}>

              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end', }}>

                <View style={{ width: "65%" }}>
                  <Text
                    style={{ fontSize: 15, fontWeight: "500" ,marginBottom:8}}
                  >
                    Date
                </Text>
                </View>
                <View style={{ width: "35%" }}>
                  <DatePicker
                    disabledDate={current => {
                      return current && current > moment().add(0, "days");
                    }}

                    value={
                      this.state.presentDate
                        ? moment(this.state.presentDate, dateFormat)
                        : this.state.presentDate
                    }
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");

                      this.setState({ presentDate: converted_date }, () => {
                        this.updateState(
                          "lastMenstrualDate",
                          this.state.lastMenstrualDate
                        );
                      });
                    }}
                    // defaultValue={"From"}
                    format={"DD-MM-YYYY"}
                    placeholder={"Select"}
                    style={{
                      borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                      alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, width: '100%'
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              {this.commonOvulation(
                "Right Ovary",
                "selectedRightOvary",
                this.state.selectedRightOvary,
                this.state.favRightOvary
              )}
              {this.state.isRightOvary ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newRightOvary}
                    onChangeText={(text) => {
                      this.setState({ newRightOvary: text });
                    }}
                    onSubmitEditing={this.addRightOvaryList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewRightOvaryadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              {this.commonOvulation(
                "Left Ovary",
                "selectedLeftOvary",
                this.state.selectedLeftOvary,
                this.state.favLeftOvary
              )}
              {this.state.isLeftOvary ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newLeftOvary}
                    onChangeText={(text) => {
                      this.setState({ newLeftOvary: text });
                    }}
                    onSubmitEditing={this.addLeftOvaryList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewLeftOvaryadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

              {this.commonOvulation(
                "Endometrium",
                "selectedEndometrium",
                this.state.selectedEndometrium,
                this.state.favEndometrium
              )}
              {this.state.isEndometrium ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newEndometrium}
                    onChangeText={(text) => {
                      this.setState({ newEndometrium: text });
                    }}
                    onSubmitEditing={this.addEndometriumList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewEndometriumadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>
              {this.renderReriodsDaysTextBox(
                "Pouch of Douglas",
                "Enter Pouch of Douglas",
                "pouchOfDouglas",
                this.state.pouchOfDouglas
              )}</View>
            <View style={{ marginBottom: 10, marginHorizontal: 5 }}>
              {this.renderRichTextBox(
                "Follicular Phase",
                "follicularPhase",
                this.state.follicularPhase
              )}</View>
            <View style={{ marginBottom: 10, marginHorizontal: 5 }}>
              {this.renderRichTextBox(
                "Ovulatory Phase",
                "ovulatoryPhase",
                this.state.ovulatoryPhase
              )}</View>
            <View style={{ marginBottom: 10, marginHorizontal: 5 }}>
              {this.renderRichTextBox(
                "Luteal Phase",
                "lutealPhase",
                this.state.lutealPhase
              )}
            </View>
            <View style={{ marginBottom: 10, marginHorizontal: 5 }}>
              {this.renderRichTextBox(
                "Comments",
                "comments",
                this.state.comments
              )}</View>
            <CommonAddButton
              onPressAddButton={this.onPressAddButton.bind(this)}
            />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    flex: 1,
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  }
});

//make this component available to the app
