import React, { Component } from 'react';
import { ScrollView, TouchableOpacity, View, Image, Text, StyleSheet } from 'react-native'
import { CommonHistorySectionHeader } from '../../BaseComponent';
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../../assets/images/BillConfig/delete.svg'
import DropDownIcon from '../../../../../assets/images/BillConfig/Down_Arrow.svg'
import { color } from '../../../../styles/Color'
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';


export default class OpTemplateConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropDownIndex: null,
            opServiceList: [],
            opListId: "",
            searchKey: this.props.searchKey,
            isEdit: this.props.isOpTemplateEdit,
            selectedOpList: [],
            opTemplateRefresh: this.props.opTemplateRefresh,
            userType: this.props.userType,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        }
    }

    componentDidMount() {
        this.getOpServiceList()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.opTemplateRefresh != this.state.opTemplateRefresh || nextProps.searchKey !== this.state.searchKey) {
            this.setState({
                searchKey: nextProps.searchKey
            },
                () => this.getOpServiceList()
            )
        }
    }

    getOpServiceList = () => {

        var states = this.state
        var url = ""
        if(states.userType === Constants.ROLE_FO){
             url = Constants.CLINIC_OP_TEMPLATE_GET + "?template_name=" + this.props.searchKey;
        }else{
             url = Constants.CLINIC_OP_TEMPLATE_GET + "?template_name=" + this.props.searchKey + "&clinic_id=" + states.selectedClinicOrDoctor.id;
        }
        

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getSuccess,
            this.getFailure
        )
    }

    getSuccess = (response) => {
        this.setState({
            opServiceList: []
        })
        if (response.status === "success") {
            this.setState({ opServiceList: response.data })
        }
    }

    getFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }

    deleteOpTemplate = () => {
        let url = Constants.CLINIC_OP_TEMPLATE_DELETE + this.state.opListId;

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.deleteSuccess,
            this.deleteFailure
        )
    }

    deleteSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.setState(
                () => this.getOpServiceList())

        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    deleteFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }

    render() {
        let { opServiceList, dropDownIndex } = this.state
        return (
            <View style={{ maxHeight: "90%" }}>
                <View style={Styles.TableHeader}>
                    <CommonHistorySectionHeader
                        heading1={"Template Name"}
                        heading2={"Action"}
                        noOfColumn={2}
                        columnSize={[0.75, 0.25]}
                    />
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={Styles.TableBody}>
                    {opServiceList && opServiceList?.length > 0 ?
                        (opServiceList?.map((item, index) => (
                            <ScrollView
                                key={index}>
                                <TouchableOpacity
                                    style={[Styles.TableBodyView, {
                                        backgroundColor: index % 2 === 0 ?
                                            color.white : color.appointmentTableHead
                                    }]}
                                    onPress={() => {
                                        let {opServiceList} = this.state
                                       
                                        this.setState({
                                            dropDownIndex: index,
                                            selectedOpList: opServiceList[index].service_data
                                        })
                                    }}>
                                    <View style={Styles.ColumnLeft}>
                                        <Text style={Styles.ColumnLeftText}>{item.template_name}</Text>
                                    </View>
                                    <View style={Styles.ColumnRight}>
                                        <View style={Styles.FlexOne}>
                                            <TouchableOpacity
                                                onPress={
                                                    ()=> {
                                                    this.getOpServiceList()
                                                    this.setState({dropDownIndex:null},()=>{
                                                        this.props.editOpTemplate(item)
                                                    })}
                                                }    
                                            >
                                                <Image
                                                    source={EditIcon}
                                                    style={{ height: 25, width: 25 }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                        </View>
                                        <View style={Styles.FlexTwo}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setState({ opListId: item.id },
                                                        () => this.deleteOpTemplate()
                                                    )
                                                }}>
                                                <Image
                                                    source={DeleteIcon}
                                                    style={{ height: 25, width: 30 }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                        </View>
                                        <View style={Styles.FlexThree}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    var {opServiceList, dropDownIndex} = this.state
                                                    let Index = null;
                                                    if (dropDownIndex === index) {
                                                        Index
                                                    } else {
                                                        Index = index
                                                    }
                                                    this.setState({
                                                        dropDownIndex: Index,
                                                        selectedOpList: opServiceList[index].service_data
                                                    })
                                                }}>
                                                <Image
                                                    source={DropDownIcon}
                                                    style={{ height: 25, width: 25 }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                {dropDownIndex === index ? (
                                    <ScrollView>
                                        <View style={[Styles.DropDownCard, { border: "1px solid black" }]}>
                                            <View>
                                                <CommonHistorySectionHeader
                                                    heading1={"service Name"}
                                                    heading2={"Service Type"}
                                                    heading3={"Amount"}
                                                    noOfColumn={3}
                                                    columnSize={[0.35, 0.35, 0.3]}
                                                />
                                            </View>
                                            {this.state?.selectedOpList && this.state?.selectedOpList?.length > 0 ?
                                                this.state?.selectedOpList?.map((item, index) => {
                                                    return (
                                                        <View style={[Styles.DropDownList,
                                                        {
                                                            backgroundColor: index % 2 === 0 ? color.white :
                                                                color.applicationBackgroundColor
                                                        }]}>
                                                            <View style={Styles.DropDowmTextOne}>
                                                                <Text>{item.service_name}</Text>
                                                            </View>
                                                            <View style={Styles.DropDowmTextTwo}>
                                                                <Text>{item.service_type}</Text>
                                                            </View>
                                                            <View style={{ flex: 0.3 }}>
                                                                <Text>{item.amount}</Text>
                                                            </View>
                                                        </View>
                                                    )
                                                }) : null
                                            }
                                        </View>
                                    </ScrollView>
                                ) : (null)
                                }
                            </ScrollView>
                        ))
                        ) : (
                            <View style={Styles.NoDataStyle}>
                                <Text style={Styles.NoDataText}>No Data To Shown</Text>
                            </View>
                        )
                    }
                </ScrollView>
            </View>
        )
    }
}


const Styles = StyleSheet.create({
    TableHeader: {
        marginHorizontal: "0.5vw",
        marginTop: "0.5vw"
    },
    TableBody: {
        marginLeft: "0.5vw",
        marginRight: "0.5vw"
    },
    TableBodyView: {
        flexDirection: "row",
        height: "6vh",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.2vw"
    },
    ColumnLeft: {
        flex: 0.73
    },
    ColumnRight: {
        flexDirection: "row",
        flex: 0.27,
        marginLeft: "0.5vw"
    },
    ColumnLeftText: {
        fontSize: "1vw",
        paddingLeft: "1.45vw"
    },
    FlexOne: {
        flex: 0.33
    },
    FlexTwo: {
        flex: 0.34
    },
    FlexThree: {
        flex: 0.33
    },
    DropDownCard: {
        border: "1px solid black",
        marginHorizontal: "3vw",
        marginVertical: "0.5vw"
    },
    DropDownList: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    DropDowmTextOne: {
        flex: 0.35,
        paddingVertical: "0.7vw",
        paddingLeft: "1.3vw"
    },
    DropDowmTextTwo: {
        flex: 0.35,
        paddingLeft: "1.4vw"
    },
    NoDataStyle: {
        margin: "auto",
        position: "relative",
        top: "25vh"
    },
    NoDataText: {
        color: color.themeDark,
        fontSize: "1.5vw"
    }

})
