import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  // ScrollView,
  CheckBox,
  Image,
  Picker,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";

import AsyncStorage from "../../../AsyncStorage";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Styles from "../../../styles/Style";

import Messages from '../../../utils/InfoMessages'
import { Tooltip } from "antd";

const platform = Platform.OS;
const pageTitle = "Subjective Refraction";
const pageName = "subjectiverefraction";
const isPageName = "isSubjectiveRefraction";

const defaultData = {
  "id": "",
  "appointment_id": "",
  "eye_type": "",
  "Aided_DV": "",
  "Aided_NV": "",
  "UnAided_DV": "",
  "UnAided_NV": "",
  "UC": "",
  "SPH": "",
  "CYL": "",
  "AXIS": "",
  "VA": "",
  "PHVA": "",
  "occupation": "",
  "chart_used": "",
  "muscle": ""
}

const defaultUserData = {
  "occupation": "",
  "chart_used": "",
  "muscle": ""
}

export default class SubjectiveRefraction extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,

      patientAppointment: this.props.patientAppointment,
      itemList: [],
      userData: JSON.parse(JSON.stringify(defaultUserData)),
      isComplaint: isPageName === this.props.selectedView?true:false,

      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],
      comments:"",
      doctorComment:"",

      rightEyeSelectedVision:"Aided",
      leftEyeSelectedVision:"Aided",

      selectedTextKey:"",

      copyRightToLeft:false,
      copyLeftToRight:false,

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null,
      PreviousData: [],
      enableEdit: true
    };

    this.firstEyeSave = false;
    this.secondEyeSave = false;
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    },()=>{
      setTimeout(() => {
        this.getItemList()
        this.getCommonComments()
        this.getPreviousData()
      }, 3000);
    
    })
  }


  getCommonComments = () => {
    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=" + "Subjective Refraction";
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getCommentSuccess,
        this.getComentsFailure
      );
    }, 3000);
  };

  getCommentSuccess = response => {

    this.firstEyeSave = false;
    this.secondEyeSave = false;

    if (response.status === "success") {
      if (response.data) {
        let data = JSON.stringify(response.data);
        // alert(response.data.comments)
        this.setState({comments:response.data.comments });
          

      }

    }
  };

  getComentsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  componentWillReceiveProps(props) {
    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  
  
    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getItemList();
      this.getCommonComments()
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
    
  }

  getPreviousData = () => {
    var service_url = Constants.OPTHALMOLOGY_PRE_SUBJECTIVEREFRACTION + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPreviousItemsSuccess,
      this.getPreviousItemsFailure
      );
  }

  getPreviousItemsSuccess = response => {
    if (response.status === "success") {
      this.setState({
        PreviousData: Object.keys(response.data).length > 0 ? response.data : []
      })
    }
  }

  getPreviousItemsFailure = (error) => {
      // console.log("opthamology Subjective Refraction GET error response");
      console.log(error);
  };
  

  getItemList = () => {

    let { userType, patientAppointment, selectedDoctorId } = this.state;
    var service_url = Constants.OPTHALMOLOGY_SUBJECTIVEREFRACTION + "?appointment_id=" + patientAppointment.appointment_id;

    if( userType == Constants.ROLE_OPTOMETRY ) {

      service_url = service_url + "&doctor_id=" + selectedDoctorId
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getItemsSuccess,
        this.getItemsFailure
      );
    }, 3000);
  };

  getItemsSuccess = response => {

    // console.log(" Sub.Ref" + JSON.stringify(response))

    if (response.status === "success") {
      // var field = this.state;
      // field["itemList"] = response.data?response.data.subrefraction:[];
      // this.setState({ field });

      // let itemList = response.data?response.data.subrefraction:[];
      let itemList = [];
      let data = []
      var availableEye=[]
      var availableEyeId=[]
      // add default
      let userData = JSON.parse(JSON.stringify(defaultUserData));

      data = response.data && Object.keys(response.data).length>0?response.data.subrefraction:[]; //response.data.subrefraction;
      
      // console.log(" Sub.Ref" + JSON.stringify(data))

      for(var i=0; i<data.length; i++){
          availableEye.push(data[i].eye_type);
          var prepareData={
            "eye_type":data[i].eye_type,
            "id":data[i].id
          }
          availableEyeId.push(prepareData);
      }

      // map fixed rows
      if(data.length==0) {

        // for right
        itemList.push(JSON.parse(JSON.stringify(defaultData)));
        // for left
        itemList.push(JSON.parse(JSON.stringify(defaultData)));

        itemList[0]["eye_type"]="RE";
        itemList[1]["eye_type"]="LE";

      } else if( data.length==1 ) {

        if(data[0]['eye_type']=="LE") {
          // default - RE 
          itemList.push(JSON.parse(JSON.stringify(defaultData)));
          itemList[0]["eye_type"]="RE";
          // LE
          itemList.push(data[0])
        } else {

          // RE
          itemList.push(data[0])
          // default - LE
          itemList.push(JSON.parse(JSON.stringify(defaultData)));
          itemList[1]["eye_type"]="LE";
          
        }

      } else {
        itemList = data;       
      }

      if(data.length>0){

        let validData = data[0];

        userData = {
          chart_used: validData.chart_used,
          occupation: validData.occupation,
          muscle: validData.muscle
        }
      }

      this.setState({ 
        itemList: itemList,
        getAvailableEyeTypes:availableEye,
        getAvailableEyeTypesId:availableEyeId,
        userData: userData,
        enableEdit: false
      })
    }
  };

  getItemsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, userData,event) => {
    this.props.triggerNewItem(isPageName);
    // alert(this.state.comments)
    this.props.editItem(pageName, item,"",this.state.comments,"",userData);
    // this is only for after direct touch of edit eye
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)
  }

  renderVisionTab(eyeType){
    return(

      <View style={{flexDirection:'row', justifyContent: "space-evenly", alignItems:'center', flex: 0.25}}>
      <Text>{"Vision - "}</Text>
      <TouchableOpacity onPress={()=>{
        if(eyeType==="RE"){
          this.setState({
            rightEyeSelectedVision:"Aided"
          })
      }else{
        this.setState({
          leftEyeSelectedVision:"Aided"
        })
      }
      
      }}>
        <Text style={
          (eyeType==="RE" && this.state.rightEyeSelectedVision == "Aided")||
          (eyeType==="LE" && this.state.leftEyeSelectedVision == "Aided")
            ?{color:"black"}:{color:color.disableLabelColor}

        }>{"Aided"}</Text>
      </TouchableOpacity>
      <TouchableOpacity
      onPress={()=>{
        var states = this.state;
        if(eyeType==="RE"){
          this.setState({
            rightEyeSelectedVision:"UnAided"
          })
        }else{
          this.setState({
            leftEyeSelectedVision:"UnAided"
          })
        }
        
      }}
      >
        <Text style={
          ((eyeType==="RE" && this.state.rightEyeSelectedVision == "UnAided")||
          (eyeType==="LE" && this.state.leftEyeSelectedVision == "UnAided"))
          ?{color:"black"}:{color:color.disableLabelColor}

        }>{"Un Aided"}</Text>
      </TouchableOpacity>
    </View>

    )
  }


  renderNewTextBoxLabel(heading,value,key,eyeType,flex,index){
    return(
      <View style={[{flex:flex}]}>
        <View style={[{height:'2.3vw',backgroundColor:color.sectionHeadingDarkColor,justifyContent:'center', paddingLeft: '0.6vw'}
      // ,(key == "SPH" || key == "Aided_DV"  || key == "UnAided_DV" )?{paddingLeft:10}:{}
      ]}>
          <Text style={{fontSize: '1vw'}}>{heading}</Text>
        </View>

        {/* {
          (this.state.selectedTextKey == key && eyeType == "RE") ? */}
          <TextInput 
          editable={false}
          onChangeText={(text)=>{
            var {itemList} = this.state;
            // var changeText = itemList[index][key]
            // changeText=text;
            itemList[index][key] = text;
            this.setState({itemList})

          }}
          value={value} style={[styles.NewTextLabelInput
          // ,(key == "SPH" || key == "Aided_DV"  || key == "UnAided_DV" )?{paddingLeft:10}:{}
        ]}/>
          {/* :
          <TouchableOpacity style={[{height:40,justifyContent:'center'}
          ,(key == "SPH" || key == "Aided_DV"  || key == "UnAided_DV" )?{paddingLeft:10}:{}]} onPress={()=>{
            this.setState({
              selectedTextKey:key
            })
          }}>
            <Text>{value}</Text>
          </TouchableOpacity>

        } */}


      </View>
    )
  }
  
  renderItem = (item, index,userData) => {
    return (
      <View key={index}>
        
          <View
            style={{
              
              paddingTop: '0.6vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "column",
              justifyContent: "flex-start",
              // height: 50,
              alignItems: "flex-start"
              // paddingBottom: 10
            }}
          >
            <View style={{width:"100%",alignItems:'center',flexDirection:'row',justifyContent:'space-between',height:'2.3vw',backgroundColor:color.sectionHeadingDarkColor}}>
            <View style={{ flexDirection: "row",alignItems:'center', flex: 1}}>
                <Text style={styles.ItemText}>
                  {item.eye_type==="RE"?`Right Eye (${item.eye_type})`:null}
                  {item.eye_type==="LE"?`Left Eye (${item.eye_type})`:null}
                  {/* {item.eye_type==="BE"?`Both Eyes (${item.eye_type})`:null}
                  {item.eye_type==="NA"?`Not Applicable (${item.eye_type})`:null} */}
                </Text>
                <View style={{ marginLeft: '1.2vw' }}>
                  <TouchableOpacity  disabled={this.state.isLocked || this.state.enableEdit} onPress={this.editComplaints.bind(this, item,userData)}>
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
                </View>
              </View>
                  {/* {this.renderVisionTab(item.eye_type,item)} */}
              </View>

            <View
              style={{
                paddingTop: '0.6vw',
                width: platform === "web" ? "100%" : "75%",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingBottom: '0.5vw'
              }}
            >   
            {/* this.state.itemList             */}
                {/* {this.renderText('Aided DV', item.Aided_DV || "-")}
                {this.renderText('Aided NV', item.Aided_NV || "-")}
                {this.renderText('UnAided DV', item.UnAided_DV || "-")}
                {this.renderText('UnAided NV', item.UnAided_NV || "-")} */}
              <View style={{flexDirection:'row',flex:1}}>
              {this.renderNewTextBoxLabel('UCVA', item.UC ,"UC",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('SPH',item.SPH ,"SPH",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('CYL',item.CYL ,"CYL",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('AXIS',item.AXIS ,"AXIS",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('BCDVA', item.BCDVA,"BCDVA",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('ADD', item.add,"add",item.eye_type,0.14,index)}
              {this.renderNewTextBoxLabel('BCNVA', item.BCNVA,"BCNVA",item.eye_type,0.16,index)}
              {this.renderNewTextBoxLabel('PHVA' , item.PHVA, "PHVA",item.eye_type, 0.12, index )}

              {/* {this.renderNewTextBoxLabel('V/A', item.VA,"V/A",item.eye_type,0.16,index)}
              {this.renderNewTextBoxLabel('PH V/A', item.PHVA,"PH V/A",item.eye_type,0.16,index)} */}
              </View>
              {/* <View style={{flex:0.05}}></View> */}
{/*               
              <View style={{flexDirection:'row',flex:0.25}}>
              
                {this.renderNewTextBoxLabel('DV', 

((item.eye_type==="RE" && this.state.rightEyeSelectedVision == "Aided")||
(item.eye_type==="LE" && this.state.leftEyeSelectedVision == "Aided"))
?item.Aided_DV : item.UnAided_DV,

((item.eye_type==="RE" && this.state.rightEyeSelectedVision == "Aided")||
(item.eye_type==="LE" && this.state.leftEyeSelectedVision == "Aided"))
?"Aided_DV" : "UnAided_DV",

  item.eye_type,0.5,index)}

                {this.renderNewTextBoxLabel('NV', 

((item.eye_type==="RE" && this.state.rightEyeSelectedVision == "Aided")||
(item.eye_type==="LE" && this.state.leftEyeSelectedVision == "Aided"))
?item.Aided_NV : item.UnAided_NV,

((item.eye_type==="RE" && this.state.rightEyeSelectedVision == "Aided")||
(item.eye_type==="LE" && this.state.leftEyeSelectedVision == "Aided"))
?"Aided_NV" : "UnAided_NV",

                item.eye_type,0.5,index)}


              </View> */}


            </View>
            <View
              style={{
                width:"100%" ,
              }}
            >
              {/* {this.renderText('V/A', item.VA)} 
              {this.renderText('PH V/A', item.PHVA)}
              {this.renderText('Occupation', item.occupation)}
              {this.renderText('Chart Used', item.chart_used)}
              {this.renderText('Muscle', item.muscle)} */}
              
              {/* {this.renderDropDownView(item.occupation, item.chart_used, item.muscle, item.eye_type, index)} */}

            </View>
        </View>
        {/* <View style={{ height: 0.5, backgroundColor: "#E0E0E0" }} /> */}
      </View>
    );
  };



  renderDropDown(label, list,value,key,index) {

    return (
      <View>
        <View style={[{height:'2.3vw',backgroundColor:color.sectionHeadingDarkColor,justifyContent:'center'}]}>
          <Text style={{ paddingHorizontal: '0.6vw' }}>{label}</Text>
        </View>
        <View style={{ paddingHorizontal: '0.6vw' }}>
          <Picker
            selectedValue={value}
            style={styles.DropDownPicker}
            itemStyle={{ fontSize:'0.8vw' }}
            onValueChange={(itemvalue) => {

              var {itemList, userData} = this.state;
              
              // itemList[index][key] = itemvalue;
              // this.setState({itemList})

              userData[key] = itemvalue;
              this.setState({ userData })

            }}
          >
            <Picker.Item key={-1} label={""} value={""} />
            {list.map((i, index) => (
              <Picker.Item key={index} label={i.value} value={i.value} />
            ))}
          </Picker>
        </View>
      </View>
    )
  }

  renderDropDownView(occupation,chart_used,muscle,eyeType,index){
    return(
      <View style={{ flexDirection: 'row', marginBottom: '1.2vw' }}>
            <View style={{ flex: 0.35 }}>

              {this.renderDropDown("Occupation", Constants.DEFAULT_OCCUPATIONS_SR,occupation,"occupation",index )}
            </View>
            <View style={{ flex: 0.35 }}>
              {this.renderDropDown("Chart Used", Constants.DEFAULT_CHARTUSED_SR,chart_used,"chart_used",index )}
            </View>
            <View style={{ flex: 0.3 }}>
              {this.renderDropDown("Muscle", Constants.DEFAULT_MUSCLES_SR,muscle,"muscle",index )}
            </View>
          </View>
    )
  }

  fetchPreviousData = () => {
    let states = this.state
    if (Object.keys(states.PreviousData).length > 0 && (this.state.itemList[0].appointment_id == "" && this.state.itemList[1].appointment_id == "")) {
      let itemList = [];
      let data = []
      var availableEye = []
      var availableEyeId = []
      let userData = JSON.parse(JSON.stringify(defaultUserData));

      data = states.PreviousData && Object.keys(states.PreviousData).length > 0 ? states.PreviousData : [];

      for (var i = 0; i < data.length; i++) {
        availableEye.push(data[i].eye_type);
        var prepareData = {
          "eye_type": data[i].eye_type,
          "id": this.state.itemList != "" ? this.state.itemList[i].id : ""
        }
        availableEyeId.push(prepareData);
      }

      if (data.length == 0) {

        // for right
        itemList.push(JSON.parse(JSON.stringify(defaultData)));
        // for left
        itemList.push(JSON.parse(JSON.stringify(defaultData)));

        itemList[0]["eye_type"] = "RE";
        itemList[1]["eye_type"] = "LE";

      } else if (data.length == 1) {

        if (data[0]['eye_type'] == "LE") {
          // default - RE 
          itemList.push(JSON.parse(JSON.stringify(defaultData)));
          itemList[0]["eye_type"] = "RE";
          // LE
          itemList.push(data[0])
        } else {

          // RE
          itemList.push(data[0])
          // default - LE
          itemList.push(JSON.parse(JSON.stringify(defaultData)));
          itemList[1]["eye_type"] = "LE";
        }
        itemList[0]["id"] = "";
        itemList[1]["id"] = "";

      } else {
        itemList = data;
        itemList[0]["id"] = "";
        itemList[1]["id"] = "";
      }

      if (data.length > 0) {
        let validData = data[0];
        userData = {
          chart_used: validData.chart_used,
          occupation: validData.occupation,
          muscle: validData.muscle
        }
      }

      this.setState({
        itemList: itemList,
        getAvailableEyeTypes: availableEye,
        getAvailableEyeTypesId: availableEyeId,
        userData: userData
      },() => {this._doSave()})
    }else{
      this.props.showResposeValue("error", "Subjective Refraction Already Created")
    }
  }


  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)

  }

  copyCheckBoxValues(key){
    var states = this.state;
    if(key == "copyRightToLeft"){
      states["copyRightToLeft"] = !this.state.copyRightToLeft;
      states["copyLeftToRight"] = false;
    }else{
      states["copyRightToLeft"] = false;
      states["copyLeftToRight"] = !this.state.copyLeftToRight;
    }
    this.setState({states},()=>{

      if(this.state.copyRightToLeft){
        var {itemList}= this.state

        // for (const rightEye of itemList) {
        //   for (const leftEye of itemList) {
        //     if(rightEye.eye_type == "RE" && leftEye.eye_type == "LE"){

        //     }
        //   }
        // }


        for (let i = 0; i < itemList.length; i++) {
          if(itemList[i].eye_type == "RE"){
            for (let j = 0; j < itemList.length; j++) {
              if(itemList[j].eye_type == "LE"){
                var leftEye = itemList[j];
                var rightEye = itemList[i];
                leftEye["SPH"] = rightEye.SPH;
                leftEye["CYL"]= rightEye.CYL;
                leftEye["AXIS"]= rightEye.AXIS;
                leftEye["UC"]= rightEye.UC;
                leftEye["VA"]= rightEye.VA;
                leftEye["PHVA"]= rightEye.PHVA;
                leftEye["BCDVA"]= rightEye.BCDVA;
                leftEye["BCNVA"]= rightEye.BCNVA;
                leftEye["add"]= rightEye.add;
                leftEye["Aided_NV"]= rightEye.Aided_NV;
                leftEye["Aided_DV"]= rightEye.Aided_DV;
                leftEye["UnAided_NV"]= rightEye.UnAided_NV;
                leftEye["UnAided_DV"]= rightEye.UnAided_DV;
                leftEye["chart_used"]= rightEye.chart_used;
                leftEye["muscle"]= rightEye.muscle;
                leftEye["occupation"]= rightEye.occupation;

                this.setState({
                  itemList
                })
              }
            }
          }          
        }








// for(let j=0; j<2; j++) {
//       if(itemList[j].eye_type == "RE"){
//         for
//       }
        
      // data = {
      //   "appointment_id": patientAppointment.appointment_id,
      //   "eye_type": itemList[j].eye_type,
      //   "Aided_DV": rightEyeSelectedVision == "Aided"?itemList[j].Aided_DV:"",
      //   "Aided_NV": rightEyeSelectedVision == "Aided"?itemList[j].Aided_NV:"",
      //   "UnAided_DV": rightEyeSelectedVision != "Aided"?itemList[j].UnAided_DV:"",
      //   "UnAided_NV": rightEyeSelectedVision != "Aided"?itemList[j].UnAided_NV:"",
      //   "UC": itemList[j].UC,
      //   "SPH": itemList[j].SPH,
      //   "CYL": itemList[j].CYL,
      //   "AXIS": itemList[j].AXIS,
      //   "VA": itemList[j].VA,
      //   "PHVA": itemList[j].PHVA,
      //   "occupation": itemList[j].occupation,
      //   "chart_used": itemList[j].chartUsed,
      //   "muscle": itemList[j].muscle,

      //   "BCDVA": itemList[j].BCDVA,
      //   "BCNVA": itemList[j].BCNVA,
      // };
    // }

      }


    

    })
  }

  renderCheckBox(label, value, key) {
    return (
        <View style={{ flexDirection: 'row', marginRight: '2vw' }}>
            <CheckBox   disabled={this.state.isLocked} value={value}
                onValueChange={() => {
                    this.copyCheckBoxValues(key)
                }}
                style={styles.CheckBoxView}
            />
            <Text style={styles.CheckboxLabel}>{label}</Text>
        </View>
    );
}

  render() {

    let { itemList, userData, PreviousData } = this.state;
    let itemCount = itemList?itemList.length:0;
    
    if(!itemCount){ itemCount = 0; }

    return (
        <View>
          <View style={{flexDirection:'row',justifyContent:'space-between'}}>
          {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
          
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {/* {this.renderCheckBox("Copy right to left", this.state.copyRightToLeft, "copyRightToLeft")} */}
            
            <Tooltip placement='top' title='Copy Previous Subjective Refraction'>
              <TouchableOpacity disabled={Object.keys(PreviousData).length > 0 ? false : true} onPress={() => {this.setState({enableEdit: true},() => {this.fetchPreviousData()})}} style={[styles.BtnTouch, { backgroundColor: Object.keys(PreviousData).length > 0 ? color.themeDark : color.grayShade, padding: "0.5vw", marginRight: "0.5vw" }, Styles.allButtonBorderRadius]}>
                <Icon size={'1.3vw'} name={"copy"} color={"white"} />
              </TouchableOpacity>
            </Tooltip>

            {/* <TouchableOpacity style={{backgroundColor:color.themeDark,borderRadius:4}} onPress={()=>this._doSave()}>
              <Image style={styles.PrintIconImage} source={require('../../../../assets/images/statusIcon/icon/Save.png')}/>
            </TouchableOpacity> */}
          </View>
         
          </View>

        {
          this.state.itemList && this.state.itemList.length > 0 ?
          this.state.itemList.map((item, index)=>{
            return this.renderItem(item, index,userData)
          }):
          null
        }

        {this.renderDropDownView(userData.occupation, userData.chart_used, userData.muscle, "", 0)}

        <View style={{flexDirection: 'row'}}>
            <Text
                style={{
                  fontSize: '1vw',
                  color: color.notesTableTitle,
                  paddingLeft: 0,
                  paddingRight: '2vw',
                  marginRight: '1vw' //isIndividual ? 30 : 0
                }}
              >
                {"RE-Comments:"}
              </Text>
              <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: '0.1vw' }}>
                {this.state.itemList.length > 0 ? this.state.itemList.map((item)=>
                {
                  return item.eye_type=="RE" ? item.comment : null
                }) : null}
                </Text>

            </View>
        <View style={{flexDirection: 'row'}}>

              <Text
                style={{
                  fontSize: '1vw',
                  color: color.notesTableTitle,
                  paddingLeft: 0,
                  paddingRight: '2vw',
                  marginRight: '1vw' //isIndividual ? 30 : 0
                }}
              >
                {"LE-Comments:"}
              </Text>
              <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: '0.1vw' }}>
              {this.state.itemList.length > 0 ? this.state.itemList.map((item)=>
                {
                  return item.eye_type=="LE" ? item.comment : null
                }) : null}
                </Text>
            </View>
            
        
      </View>
    );
  }

  _doSave(){

    let errorMessage = "";
    let { itemList, userData, patientAppointment, rightEyeSelectedVision } = this.state;

    var service_url = Constants.OPTHALMOLOGY_SUBJECTIVEREFRACTION;



    var checkDetailsIsEmpty = true;

    for(let j=0; j<2; j++) {
    
      let data = {}



      if(itemList[j].Aided_DV || itemList[j].Aided_NV || itemList[j].UnAided_DV || itemList[j].UnAided_NV ||
        itemList[j].UC || itemList[j].SPH || itemList[j].CYL || itemList[j].AXIS || itemList[j].VA || itemList[j].PHVA ||
        userData.occupation || userData.chart_used || userData.muscle || itemList[j].BCDVA || itemList[j].BCNVA || itemList[j].add ){
          checkDetailsIsEmpty = false
  
      data = {
        "appointment_id": patientAppointment.appointment_id,
        "eye_type": itemList[j].eye_type,
        "Aided_DV": rightEyeSelectedVision == "Aided"?itemList[j].Aided_DV:"",
        "Aided_NV": rightEyeSelectedVision == "Aided"?itemList[j].Aided_NV:"",
        "UnAided_DV": rightEyeSelectedVision != "Aided"?itemList[j].UnAided_DV:"",
        "UnAided_NV": rightEyeSelectedVision != "Aided"?itemList[j].UnAided_NV:"",
        "UC": itemList[j].UC,
        "SPH": itemList[j].SPH,
        "CYL": itemList[j].CYL,
        "AXIS": itemList[j].AXIS,
        "VA": itemList[j].VA,
        "PHVA": itemList[j].PHVA,
        "occupation": userData.occupation,  //itemList[j].occupation,
        "chart_used": userData.chart_used,  //itemList[j].chart_used,
        "muscle": userData.muscle, //itemList[j].muscle,
        "comment": this.state.comments,         

        "BCDVA": itemList[j].BCDVA,
        "BCNVA": itemList[j].BCNVA,
        "add": itemList[j].add,
      };

      if (itemList[j].id) {
        data["id"] = itemList[j].id;
      }

      if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
      {
        data['doctor_id'] = this.state.selectedDoctorId;
      }
      
      /*
      if(!states.selectedUC && !states.selectedSPH && !states.selectedCYL 
        && !states.selectedAidedDV && !states.selectedAidedNV && !states.selectedUnAidedDV && !states.selectedUnAidedNV 
        && !states.selectedAXIS && !states.selectedVA && !states.selectedPHVA 
        && !states.occupation && !states.chartUsed && !states.muscle && !states.comment
        ) {
        errorMessage = errorMessage?errorMessage+",Select any values":"Select any values";
      }
      */
      if(errorMessage){

        this.props.showResposeValue("error", errorMessage)
      } else {
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.getComplaintsSuccess,
          this.getComplaintsFailure
        );
      }
    }
  }


  if(checkDetailsIsEmpty){
    this.props.showResposeValue("error", Messages.SubjectiveRefractionSaveErrorMessageWhenNUll)
  }

  }

  getComplaintsSuccess = response => {
    // alert("complaint created successfully")


    if (response.status === "success") {

      this.props.showResposeValue("success", response.message)
      this.setState({
        copyRightToLeft:false
      })

      if(!this.firstEyeSave)
      {
        this.firstEyeSave = true;
        // call get API 
        this.getItemList()
      }
      else if(!this.secondEyeSave)
      {
        this.secondEyeSave = true;

        // call get API 
        this.getItemList()
      }
      
      
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getComplaintsFailure = error => {
    
    this.props.showResposeValue("error", error.message)

  };

  addComments = () => {

    
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGT_COMMENTS;
    var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "section": "Subjective Refraction",
      "comments": states.comments
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addCommentsSuccess,
      this.addCommentsFailure
    );
  };
  addCommentsSuccess = success => {
    this.clearComplaint();
    // alert(JSON.stringify(success))
  }
  addCommentsFailure = error => { }

  saveGlassPrescriptionData(eyeType) {
   
    let data = {}
    var states = this.state;
    var glassPrescriptionData = this.state.glassPrescriptionData;

    if (glassPrescriptionData.length > 0) {
      for (let i = 0; i < glassPrescriptionData.length; i++) {
        if (glassPrescriptionData[i].eye_type == eyeType) {
    
            data = {
              "appointment_id": this.state.patientAppointment.appointment_id,
              "eye_type": eyeType,
              "Aided_DV": states.selectedAidedDV,
              "Aided_NV": states.selectedAidedNV,
              "UnAided_DV": states.selectedUnAidedDV,
              "UnAided_NV": states.selectedUnAidedNV,
              "SPH": states.selectedSPH,
              "CYL": states.selectedCYL,
              "AXIS": states.selectedAXIS,
              "VISION": states.selectedVA,
              "id": glassPrescriptionData[i].id
            };
          // }
        }
      }

      // this.glassPrescriptionPostApi(data)
    }

    
  }

  glassPrescriptionPostApi(data) {

    var service_url = Constants.GLASS_PRESCRIPTION;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.glassPrescriptionPostApiuccess,
      this.glassPrescriptionPosFailure
    );
  }

  glassPrescriptionPostApiuccess = response => {
    if (response.status === "success") {
      // this.getGlassPrescriptionData();
      this.props.refreshData("glassPrescription");
      this.props.showResposeValue("success", response.message)

    } else {
      this.props.showResposeValue("error", response.message)
    }

  };

  glassPrescriptionPosFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
    this.props.showResposeValue("error", error.message)
  };
}

const styles = StyleSheet.create({
      PrintIconImage: {
        height:'2.3vw',
        width:'2.3vw'
      },
      ItemText:{ 
        fontSize: '1vw', 
        marginLeft:'0.6vw' 
      },
      NewTextLabelInput: {
        maxWidth:'3.9vw',
        height:'2.6vw',
        justifyContent:"center",
        textAlignVertical:'center', 
        paddingLeft: '0.6vw',
        fontSize: '0.9vw'
      },
      DropDownPicker: { 
        borderColor:"transparent",
        borderWidth:1, 
        height: '2vw', 
        backgroundColor: 'white', 
        marginTop: '0.6vw',
        borderBottomColor:"#E0E0E0",
        borderBottomWidth:1,
        fontSize: '0.9vw'
      },
      CheckboxLabel: { 
        marginLeft: '0.6vw', 
        fontSize: '0.8vw', 
        color: "#888888" 
      },
      CheckBoxView: {
        height: '1.2vw',
        width: '1.2vw'
      }
})