import React, { Component } from 'react';
import { View, Text, Switch, StyleSheet, TextInput, ScrollView, TouchableOpacity, Picker } from 'react-native';
import Pagination from '../../common/Pagination';
import { NavigationTopHeader, CommonHistorySectionHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import Style from '../../../../styles/Style';
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import { DatePicker } from 'antd';
import moment from "moment";
import AsyncStorage from "../../../../AsyncStorage";
import { Checkbox } from 'antd';
import { IndeterminateCheckBox } from '@material-ui/icons';

const navigationHeader = [
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "InBound", value: "inBound" },
    { label: "OutBound", value: "outBound" },
    { label: "Stock Transfer Report", value: "stockTransferReport" }
]
const navigationHeaderAdmin = [
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "Approval Request", value: "approvalRequest" },
    { label: "Stock Transfer Report", value: "stockTransferReport" }
]
const tempRows = {
    "drug_name": "",
    "generic_name": "",
    "dosage_strength": "",
    "dosage_type": "",
    "dosage_strength_type": "",
    "batch_no": "",
    "exp_on": "",
    "avail_qty": "",
    "trans_qty": "",
    "id": "",
    
}
const outtempRows = {
    "drug_name": "",
    "generic_name": "",
    "dosage_strength": "",
    "dosage_type": "",
    "dosage_Strength_type": "",
    "batch_no": [],
    "exp_on": [],
    "avail_qty": "",
    "trans_qty": "",
    "id": "",
    "stock_details": [],
    "trans_quantity": "",
    "total_amount": null
}
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class StockTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSwitchFlag: this.props.userType === "SUPER_ADMIN" ? true : this.props.userType === "BRANCH_ADMIN" ? false : false,
            inBoundTableList: [],
            outBoundTableList: [],
            userType: this.props.userType,
            selectedBranch: "",
            branchList: [],
            stockDate: converted_date,
            typedIndex: null,
            brandNameSuggessionList: [],
            organizationId: "",
            isFocus: false,
            stockDetails: [],
            selectedBatch: "",
            selectedToBranch: "",
            selectedStockDetail: [],
            profileId: ""

        };
    }
    async componentDidMount() {
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
       if (this.state.userType === "BRANCH_ADMIN") {
        this.setState({
            organizationId : loggedInDataObj ? loggedInDataObj.organization_id : "",
            profileId: loggedInDataObj ? loggedInDataObj.profile_id : null
        },() => {
            this.getBranchName()
        })
       } else {
        this.getBranchName()
       }
       
    }
    getBranchName = () => {
        if (this.state.userType === "BRANCH_ADMIN") {
            var url = Constants.GET_BRANCH_NAME + "?organization_id=" + this.state.organizationId
        } else {
            var url = Constants.GET_BRANCH_NAME + "?organization_id=true"
        }
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getBranchNameSuccess,
            this.getBranchNameFailure
        );
    }

    getBranchNameSuccess = response => {
        if (response && response.status == "success") {
            var data  = response.data
            this.setState({
                branchList: data,
                selectedBranch: data[0].id
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    getBranchNameFailure = error => {
        this.props.showResposeValue("error", error.message)
    }
    getStockSuggession = (name) => {
        var type = this.state.toggleSwitchFlag ? "Outbound" : "Inbound"
        var branch_id = this.state.toggleSwitchFlag && this.state.userType != "SUPER_ADMIN" ? this.state.profileId : this.state.selectedBranch
        var url = Constants.GET_STOCK_SUGGESSTION + "?branch_id=" + branch_id + "&brand_name=" + name + "&type=" + type
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getStockSuggessionSuccess,
            this.getStockSuggessionFailure
        );
    }

    getStockSuggessionSuccess = response => {
        if (response && response.status == "success") {
            this.setState({
                brandNameSuggessionList: response.data
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    getStockSuggessionFailure = error => {
        this.props.showResposeValue("error", error.message)
    }
    changeTabSection(data) {
        // do nothing
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    renderSubHeaderView = () => {
        return (
            <View style={{ flexDirection: "row" }}>
             { this.state.userType === "SUPER_ADMIN" ?  
             <View style = {{ flexDirection: "row" }}> 
             <View>
                <Text style={styles.HeaderText}>{"From Branch"}</Text>
                <Picker
                    selectedValue={this.state.selectedBranch}
                    style={[
                        Style.historyYesOrNoSectionShadow,
                        Style.allButtonBorderRadius,
                        styles.pickerStyle,
                    ]}
                    itemStyle={{ fontSize: "0.8vw" }}
                    onValueChange={(itemvalue) => {
                        this.setState({
                            selectedBranch: itemvalue,
                        }, () => {
                            this.getStockSuggession()
                        });
                    }}
                >
                    { this.state.branchList.map((item, index) => {
                            return <Picker.Item key={index} label={item.name} value={item.id} />
                        })
                    }
                </Picker>
               </View> 
               <View>
                <Text style={styles.HeaderText}>{"To Branch"}</Text>
                <Picker
                    selectedValue={this.state.selectedToBranch}
                    style={[
                        Style.historyYesOrNoSectionShadow,
                        Style.allButtonBorderRadius,
                        styles.pickerStyle,
                    ]}
                    itemStyle={{ fontSize: "0.8vw" }}
                    onValueChange={(itemvalue) => {
                        this.setState({
                            selectedToBranch: itemvalue,
                        }, () => {
                            this.getStockSuggession()
                        });
                    }}
                >
                    { this.state.branchList.map((item, index) => {
                            return <Picker.Item key={index} label={item.name} value={item.id} />
                        })
                    }
                </Picker>
                </View></View> :
                 <View>
                 { this.state.toggleSwitchFlag ? <Text style={styles.HeaderText}>{"To Branch"}</Text> : <Text style={styles.HeaderText}>{"From Branch"}</Text>}    
                 <Picker
                     selectedValue={this.state.selectedBranch}
                     style={[
                         Style.historyYesOrNoSectionShadow,
                         Style.allButtonBorderRadius,
                         styles.pickerStyle,
                     ]}
                     itemStyle={{ fontSize: "0.8vw" }}
                     onValueChange={(itemvalue) => {
                         this.setState({
                             selectedBranch: itemvalue,
                         }, () => {
                             this.getStockSuggession()
                         });
                     }}
                 >
                     { this.state.branchList.map((item, index) => {
                             return <Picker.Item key={index} label={item.name} value={item.id} />
                         })
                     }
                 </Picker>
                </View> }
                <View style={{ flexDirection: "row", flex: 1 }}>
                    <Text
                        style={[
                            styles.toggleText,
                            {
                                color: !this.state.toggleSwitchFlag
                                    ? color.themeDark
                                    : color.black,
                            },
                        ]}
                    >
                        {"Inbound"}
                    </Text>
                    <Switch
                        style={styles.SwitchStyle}
                        thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
                        onValueChange={() => {
                          if( this.state.userType === "BRANCH_ADMIN" ) { 
                            this.setState({
                                toggleSwitchFlag: !this.state.toggleSwitchFlag,
                                outBoundTableList: [],
                                inBoundTableList: [],
                                brandNameSuggessionList: [],
                                stockDetails: []

                            });
                          }
                        }}
                        value={this.state.toggleSwitchFlag}
                    />
                    <Text
                        style={[
                            styles.toggleText,
                            {
                                color: this.state.toggleSwitchFlag
                                    ? color.themeDark
                                    : color.black,
                            },
                        ]}
                    >
                        {"Outbound"}
                    </Text>
                </View>
                <View>
                    <DatePicker
                        // disabledDate={this.disabledDate}
                        value={moment(this.state.stockDate)}
                        onChange={(date) => {
                            var fulldate = new Date(date);
                            var converted_date = moment(fulldate).format("YYYY-MM-DD");
                            this.setState({ stockDate: converted_date });
                        }}
                        clearIcon={false}
                        format={"DD-MM-YYYY"}
                        placeholder={"YYYY-MM-DD"}
                        defaultValue={moment(this.state.stockDate, "YYYY-MM-DD")}
                        style={{
                            zIndex: -1,
                            width: "85%",
                            borderColor: "#CDD1D5",
                            height: "80%",
                            borderRadius: 4,
                            borderWidth: 1,
                            marginTop: "0.5vw",
                        }}
                    />
                </View>
            </View>
        );
    }
    getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
        return (
          <View style={{ flex: flexWidth }}>
            <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
          </View>)
      }
    renderHeaderView = () => {
        return (
           <View>
            { this.state.toggleSwitchFlag ? 
                <View style={styles.subContainer}>
                {this.getBillTableTitle('S.No', 0.05, "0.95vw")}
                {this.getBillTableTitle('Brand Name', 0.12, "0.95vw")}
                {this.getBillTableTitle('Generic Name', 0.12, "0.95vw")}
                {this.getBillTableTitle('Dosage Type', 0.12, "0.95vw")}
                {this.getBillTableTitle('Trans Quantity', 0.1, "0.95vw")}
                {this.getBillTableTitle('Batch No', 0.14, "0.95vw")}
                {this.getBillTableTitle('Selected Quantity', 0.1, "0.95vw")}
                {this.getBillTableTitle('EXP On', 0.15, "0.95vw")}
                {this.getBillTableTitle('Avail Quantity', 0.1, "0.95vw")}
            </View>: 
              <View style={styles.subContainer}>
              {this.getBillTableTitle('S.No', 0.05, "0.95vw")}
              {this.getBillTableTitle('Brand Name', 0.15, "0.95vw")}
              {this.getBillTableTitle('Generic Name', 0.15, "0.95vw")}
              {this.getBillTableTitle('Dosage Type', 0.15, "0.95vw")}
              {this.getBillTableTitle('Batch No', 0.15, "0.95vw")}
              {this.getBillTableTitle('EXP On', 0.15, "0.95vw")}
              {this.getBillTableTitle('Avail Quantity', 0.1, "0.95vw")}
              {this.getBillTableTitle('Requested Quantity', 0.1, "0.95vw")}
            </View>
            }
           </View> 
        )
    }
    avoidOnlySplCharValidation = (num) => {
        return !/[^A-Z a-z 0-9]+/.test(num)
    }
    returnCheckValue = (list, selectedIndex) => {
        let temp = this.state.stockDetails && this.state.stockDetails.length > 0 && this.state.stockDetails.map((item, index) => {
            if (item.stock_list_id === list.stock_list_id && selectedIndex === index) {
                this.state.stockDetails[index]["isSelected"] = !this.state.stockDetails[index]["isSelected"]
            }
        }) 
        return temp   
      
    }
    onCheckClick = (id, selectedIndex, list) => {
        let { outBoundTableList, typedIndex } = this.state;
                var stockDetails = outBoundTableList[typedIndex].stock_details
                 if (id === stockDetails[selectedIndex].stock_list_id ) {
                     stockDetails[selectedIndex]["isSelected"] = !stockDetails[selectedIndex]["isSelected"]
                  }
                  outBoundTableList[typedIndex].stock_details = stockDetails
        
        this.setState({ 
            outBoundTableList, 
         }, () => {
            var batch_no = []
            var exp_on = []
            var date = moment(list.expiry_date).format("DD-MM-YYYY")
            var stockDetails = outBoundTableList[typedIndex].stock_details
            if(stockDetails[selectedIndex]["isSelected"]) {
                
                outBoundTableList[typedIndex].batch_no.push(list.batch_no)
                // outBoundTableList[typedIndex].stock_details.push(list)
                outBoundTableList[typedIndex].exp_on.push(date)
                // outBoundTableList[typedIndex].select_stock.push(this.state.stockDetails)
                this.setState({
                    outBoundTableList
                })
            } else {
                batch_no = outBoundTableList[typedIndex].batch_no
                exp_on = outBoundTableList[typedIndex].exp_on    
                var stock_detail = outBoundTableList[typedIndex].stock_details                   
                var Index = batch_no.findIndex((element) => element === list.batch_no )
                var exp_index = exp_on.findIndex((element) => element === list.exp_on)
                var trans_qty = outBoundTableList[typedIndex].trans_qty
                trans_qty = trans_qty - stock_detail[selectedIndex]["quantity"]
                exp_on.splice(exp_index, 1)
                batch_no.splice(Index, 1)
                outBoundTableList[typedIndex]["batch_no"] = batch_no
                outBoundTableList[typedIndex]['trans_qty'] = trans_qty
                stock_detail[selectedIndex]["quantity"] = 0
            }
            this.setState({ 
                outBoundTableList,
                stockDetails
             })
         })
    }
    renderInputForStock = (list, index) => {
        var { outBoundTableList, typedIndex } = this.state
        var stockDetails = outBoundTableList[typedIndex].stock_details
        return (
            <View>
                {list && list.isSelected ?
                    <TextInput
                        keyboardType="numeric"
                        value={stockDetails[index]["quantity"]}
                        style={{ fontSize: '0.8vw', backgroundColor: 'EFEFEF', width: '100%', height: '1.5vw', margin: '0.3vw', borderColor: color.themeDark, borderWidth: 1, borderRadius: '0.3vw', paddingLeft: "0.5vw", color: 'black' }}
                        onChangeText={(text) => {
                            // var isValid = this.numberValidation(text)
                            var stock_details = outBoundTableList[typedIndex].stock_details
                            var text = parseInt(text)
                          
                            if ((text) <= list.stock_in_quantity) {
                                stock_details[index]["quantity"] = text
                            } else {
                                stock_details[index]["quantity"] = text
                            }
                            outBoundTableList[typedIndex].stock_details = stock_details
                            this.setState({ outBoundTableList },() => {
                                var stockDetails = outBoundTableList[typedIndex].stock_details
                                var total = 0 
                                var total_amount = 0
                                for ( let i=0 ; i<stockDetails.length ; i++ ) {
                                   if (stockDetails[i].quantity && stockDetails[i].quantity != null ) {
                                    total = total + stockDetails[i].quantity
                                    total_amount = total_amount + (stockDetails[i].quantity * stockDetails[i].mrp_per_quantity)
                                   }
                                }
                                outBoundTableList[typedIndex]["trans_qty"] = total,
                                outBoundTableList[typedIndex]["total_amount"] = total_amount
                                this.setState({
                                    stockDetails,
                                    outBoundTableList
                                })

                            })
                        }}
                    /> : null}
            </View>
        )
    }
    batchNoPopUp = (stockDetails) => {
        return (
            <View style = {{ zIndex: 10 }}>
                    <View style={[styles.BatchSelectPopUP, { }]}>
                        <View style={styles.BatchSelectPopUPWrapper}>
                            <Text style={{ fontSize: '0.7vw', width: '5%', textAlign: 'center' }}></Text>
                            <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center' }}>Batch No</Text>
                            <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center', marginLeft: '0.2vw' }}>Avail.Qty</Text>
                            <TouchableOpacity style={{ width: '30%', display: 'flex', flexDirection: 'row-reverse' }} onPress={() => { this.setState({  onFocus: false, typedIndex: null })}}>
                                <Icon name="window-close-o" size={'0.97vw'} color={color.themeDark} />
                            </TouchableOpacity>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                        {(stockDetails) ?
                          stockDetails.length > 0 ?
                            (stockDetails).map((list, index1) => (
                                    <View style={styles.BatchScrollView}>
                                        <Checkbox
                                           checked = { list.isSelected }
                                            value={list.isSelected}
                                            onChange={() => { this.onCheckClick(list.stock_list_id, index1, list) }} 
                                            />
                                        <Text style={{ fontSize: '0.8vw', width: '30%', textAlign: 'center' }}>{list.batch_no}</Text>
                                        <Text style={{ width: '30%', fontSize: '0.8vw', textAlign: 'center' }}>{list.stock_in_quantity}</Text>
                                        <View style= {{ width: "30%" }}>{this.renderInputForStock(list, index1)}</View>

                                    </View>))
                            : <Text style={styles.NoListBatch}>No Stock Available</Text>
                        : null
                    }
                </ScrollView>

                    </View> 

            </View>
        )

    }
    renderTextInput = (stateKey, value, flex, index) => {
        let { inBoundTableList, outBoundTableList, typedIndex, brandNameSuggessionList } = this.state
        return (
            <View style={{ flex: flex, height: "3.5vw", }}>
                <TextInput
                    style={styles.textInput}
                    keyboardType = {stateKey === "trans_qty" || stateKey === "trans_quantity" ? "numeric" : null}
                    value={ 
                     stateKey === "batch_no" ?
                      this.state.outBoundTableList[index].batch_no ? (this.state.outBoundTableList[index].batch_no) : "-" :  
                     stateKey === "exp_on" ? 
                        this.state.outBoundTableList[index].exp_on ? this.state.outBoundTableList[index].exp_on : "-" : value 
                    }
                    editable={(stateKey === "drug_name" || (stateKey === "trans_qty" && !this.state.toggleSwitchFlag) || stateKey === "trans_quantity") || stateKey === "batch_no" ? true : false }
                    onFocus = {() => {
                        if(stateKey === "drug_name") {
                            this.setState({
                                typedIndex: null,
                                stockDetails: []
                            })
                        } 
                        if(stateKey === "batch_no") {
                        
                            this.setState({
                                isFocus: true,
                                isBatchPopUp: true, 
                                typedIndex: index,
                                outBoundTableList,
                            })
                        }

                       
                        this.setState({
                          
                        })
                    }}
                    onChangeText={(text) => {
                        var isAlphaNumeric = this.avoidOnlySplCharValidation(text)
                        var states = this.state
                        if (!this.state.toggleSwitchFlag) {
                            if (stateKey === "drug_name" && isAlphaNumeric) {
                                var currentIndex = null
                                currentIndex = index
                                inBoundTableList[index]['id'] = ''
                                inBoundTableList[index]['generic_name'] = ''
                                inBoundTableList[index]['dosage_type'] = ''
                                inBoundTableList[index]['dosage_strength'] = ''
                                inBoundTableList[index]['qty'] = ''
                                inBoundTableList[index]['avail_qty'] = ''
                                inBoundTableList[index]['batch_no'] = ''
                                inBoundTableList[index]['exp_on'] = ''
                                states["brandNameSuggessionList"] = []
                                inBoundTableList[index][stateKey] = text;
                                if(text && text.length > 0) {
                                    this.getStockSuggession(text)
                                }
                            } else {
                                inBoundTableList[index][stateKey] = text;
                            }
                            this.setState({
                                inBoundTableList,
                                typedIndex: currentIndex,
                                states
                            });
                        } else {
                            if (stateKey === "drug_name" && isAlphaNumeric) {
                                var currentIndex = null
                                currentIndex = index
                                outBoundTableList[index]['id'] = ''
                                outBoundTableList[index]['generic_name'] = ''
                                outBoundTableList[index]['dosage_type'] = ''
                                outBoundTableList[index]['dosage_strength'] = ''
                                outBoundTableList[index]['qty'] = ''
                                outBoundTableList[index]['avail_qty'] = ''
                                outBoundTableList[index]['batch_no'] = []
                                outBoundTableList[index]['exp_on'] = []
                                outBoundTableList[index]['select_stock'] = []
                                states["brandNameSuggessionList"] = []
                                outBoundTableList[index]["trans_quantity"] = ""
                                outBoundTableList[index]["trans_qty"] = ""
                                outBoundTableList[index][stateKey] = text;
                                this.setState({ outBoundTableList })
                                if(text && text.length > 0) {
                                    this.getStockSuggession(text)
                                }    
                            } else {
                                outBoundTableList[index][stateKey] = text;
                            }
                            this.setState({
                                outBoundTableList,
                                typedIndex: currentIndex,
                                states
                            });
                        }

                    }}
                />
                {index === typedIndex && inBoundTableList[index]["drug_name"] != "" && stateKey == "drug_name" && brandNameSuggessionList && brandNameSuggessionList.length > 0 && !this.state.toggleSwitchFlag ?
                    <View style={styles.suggestionMain}>
                        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}
                            style={{
                                flex: 1,
                                backgroundColor: color.black,
                                borderColor: color.lightGray,
                                borderWidth: 1,
                                borderRadius: 4,
                                paddingHorizontal: 10,
                            }}
                        >
                            <View>
                                {brandNameSuggessionList && (brandNameSuggessionList).map((item) => {
                                    return (
                                        <TouchableOpacity
                                            key={index}
                                            style={{ marginTop: 5, marginBottom: 2 }}
                                            onPress={() => {
                                                var stock_details = item.stock_details
                                                inBoundTableList[typedIndex]["drug_name"] = item.drug_name
                                                inBoundTableList[typedIndex]['generic_name'] = item.generic_name
                                                inBoundTableList[typedIndex]["dosage_type"] = item.dosage_type
                                                inBoundTableList[typedIndex]["dosage_strength"] = item.dosage_type + " / " + item.drug_strength
                                                inBoundTableList[typedIndex]["id"] = item.drug_ms
                                                inBoundTableList[typedIndex]["exp_on"] = "-"
                                                inBoundTableList[typedIndex]["batch_no"] = "-"
                                                inBoundTableList[typedIndex]["avail_qty"] = stock_details[0].stock_in_quantity
                                                this.setState({
                                                    inBoundTableList,
                                                    brandNameSuggessionList: [],
                                                    stockDetails: stock_details
                                                })

                                            }}
                                        >
                                            <Text style={{ color: color.white, fontWeight: "bold" }}>{item.display_name}</Text>
                                        </TouchableOpacity>)
                                })}
                            </View>

                        </ScrollView>

                    </View> : null}
                {index === typedIndex && outBoundTableList[index]["drug_name"] != "" && stateKey == "drug_name" && brandNameSuggessionList && brandNameSuggessionList.length > 0 && this.state.toggleSwitchFlag ?
                    <View style={styles.suggestionMain}>
                        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}
                            style={{
                                flex: 1,
                                backgroundColor: color.black,
                                borderColor: color.lightGray,
                                borderWidth: 1,
                                borderRadius: 4,
                                paddingHorizontal: 10,
                            }}
                        >
                            <View>
                                {brandNameSuggessionList && (brandNameSuggessionList).map((item) => {
                                    return (
                                        <TouchableOpacity
                                            key={index}
                                            style={{ marginTop: 5, marginBottom: 2 }}
                                            onPress={() => {
                                                var stock_details = item.stock_details
                                                stock_details.forEach(data => { data.isSelected = false; });
                                                outBoundTableList[typedIndex]["drug_name"] = item.drug_name
                                                outBoundTableList[typedIndex]['generic_name'] = item.generic_name
                                                outBoundTableList[typedIndex]["dosage_type"] = item.dosage_type
                                                outBoundTableList[typedIndex]["dosage_strength"] = item.dosage_type + " / " + item.drug_strength
                                                outBoundTableList[typedIndex]["id"] = item.drug_ms
                                                outBoundTableList[typedIndex]["avail_qty"] = item.stock_in_quantity
                                                outBoundTableList[typedIndex]["stock_details"] = (item.stock_details)

                                                // outBoundTableList[typedIndex]["stock_details"].push(item.stock_details)
                                                outBoundTableList[typedIndex]["stock_details"].forEach(data => { data.quantity = null; });

                                                // if (stock_details && stock_details.length === 1) {
                                                //     outBoundTableList[typedIndex]["stock_details"] = item.stock_details
                                                //     outBoundTableList[typedIndex]["batch_no"] = stock_details[0].batch_no
                                                //     outBoundTableList[typedIndex]['exp_on'] = stock_details[0].expiry_date
                                                //     outBoundTableList[typedIndex]["avail_qty"] = stock_details[0].stock_in_quantity
                                                // } else {
                                                    this.setState({
                                                        isBatchPopUp: true,
                                                        stockDetails: stock_details,
                                                        outBoundTableList,
                                                        brandNameSuggessionList: []
                                                    })
                                                // }
                                              

                                            }}
                                        >
                                            <Text style={{ color: color.white, fontWeight: "bold" }}>{item.display_name}</Text>
                                        </TouchableOpacity>)
                                })}
                              
                            </View>
                          
                        </ScrollView>

                    </View> : null}
                    {index === this.state.typedIndex && this.state.isFocus && stateKey === "batch_no" && this.state.stockDetails && this.state.stockDetails.length > 0 && this.state.toggleSwitchFlag && outBoundTableList[typedIndex].trans_quantity != "" ?
                         <View style ={{ zIndex: 10 }}>{this.batchNoPopUp(outBoundTableList[typedIndex]["stock_details"])}</View> : 
                    null }
            </View>
        )
    }
    _addNextRows = () => {

        let { inBoundTableList, outBoundTableList } = this.state

        if (this.state.toggleSwitchFlag) {

            let current_row_drug = drugEnteryList.length;
            let bill_line_items_drug = [];
            let drugRows_no_manitory_drug = [];
            let add_flag = false;
            for (let i = 0; i < current_row_drug; i++) {

                if (inBoundTableList[i].drug_generic_name && inBoundTableList[i].dosage_Strength_type && inBoundTableList[i].batch_no && inBoundTableList[i].exp_on && inBoundTableList[i].avail_qty && inBoundTableList[i].trans_qty) {
                    bill_line_items_drug.push(drugEnteryList[i]);
                }
                else if (inBoundTableList[i].drug_generic_name && inBoundTableList[i].dosage_Strength_type && inBoundTableList[i].batch_no && inBoundTableList[i].exp_on && inBoundTableList[i].avail_qty && inBoundTableList[i].trans_qty) {
                    drugRows_no_manitory_drug.push(drugEnteryList[i])
                }
            }

            if ((current_row_drug - bill_line_items_drug.length) === 0) {
                add_flag = true;
            }

            if (add_flag) {

                let new_row = inBoundTableList.length + 5

                for (let i = current_row_drug; i < new_row; i++) {
                    inBoundTableList.push(JSON.parse(JSON.stringify(tempRows)));
                }

                this.setState({
                    inBoundTableList,
                })
            }
            else if (drugRows_no_manitory_drug.length > 0) {
                console.log("error")
            }
        } else {
            let current_row_OUT = IOLEntryList.length;
            let bill_line_items_OUT = [];
            let drugRows_no_manitory_OUT = [];
            let add_flags = false;

            for (let i = 0; i < current_row_OUT; i++) {

                if (inBoundTableList[i].drug_name && inBoundTableList[i].generic_name && inBoundTableList[i].dosage_strength && inBoundTableList[i].dosage_type && inBoundTableList[i].qty) {
                    bill_line_items_OUT.push(outBoundTableList[i]);
                }
                else if (outBoundTableList[i].drug_name && outBoundTableList[i].generic_name && outBoundTableList[i].dosage_strength && outBoundTableList[i].dosage_type && inBoundTableList[i].qty) {
                    drugRows_no_manitory_OUT.push(outBoundTableList[i])
                }
            }

            if ((current_row_OUT - bill_line_items_OUT.length) === 0) {
                add_flags = true;
            }

            if (add_flags) {

                let new_row = outBoundTableList.length + 5

                for (let i = current_row_OUT; i < new_row; i++) {
                    outBoundTableList.push(JSON.parse(JSON.stringify(outtempRows)));
                }

                this.setState({
                    outBoundTableList,
                })
            }
            else if (drugRows_no_manitory_OUT.length > 0) {
                console.log("error")

            }
        }
    }
    onPressStovkTransfer = () => {
        var { inBoundTableList, outBoundTableList, typedIndex } = this.state
        var service_url = ""
        let current_row = inBoundTableList.length;
        let outBound_row = outBoundTableList.length
        let bill_line_items = [];
        let bill_line_out = [];
        var stock_details= []
   
        for (let i = 0; i < current_row; i++) {
   
          if (inBoundTableList[i].drug_name) {
   
            bill_line_items.push({ "drug_ms": inBoundTableList[i].id, "request_quantity": parseInt(inBoundTableList[i].trans_qty) });
   
          }
        }
       for (let i = 0; i < outBound_row; i++) {
           if(outBoundTableList[i].drug_name != "") {
              var stock_detail = outBoundTableList[i].stock_details
              var selectedStock = []
              for(  let j = 0; j < stock_detail.length; j++ ) {
                  if(stock_detail[j].isSelected) {
                    selectedStock.push(stock_detail[j])
                  } 
              }
              bill_line_out.push({ "drug_ms": outBoundTableList[i].id, "request_quantity": parseInt(outBoundTableList[i].trans_qty), "stock_details": selectedStock, "trans_quantity": outBoundTableList[i].trans_quantity, "quantity":outBoundTableList[i].trans_qty, "total_amount": outBoundTableList[i].total_amount });
           } 
 
        }
        var isManitory = ""
        for (let i = 0; i < bill_line_out.length; i++) {
          if (bill_line_out[i].request_quantity != bill_line_out[i].trans_quantity ) {
              isManitory = "Please enter the transfer quantity equal to selected quantity"
          } else if(this.state.userType === "SUPER_ADMIN" && this.state.selectedBatch === this.state.selectedToBranch ) {
              isManitory = "Please select the different branch for outbound"
          }
        }
       var service_url = ""
       var data = {}
       if( this.state.userType === "SUPER_ADMIN") {
        service_url = Constants.ADMIN_STOCK_TRANSFER
        data = {
            "to_branch": parseInt(this.state.selectedToBranch),
            "from_branch": this.state.selectedBranch,
            "line_items" : bill_line_out
        } 
       } else { 
        if(this.state.toggleSwitchFlag ) {
            service_url = Constants.STOCK_TRANSFER_OUTBOUND
            data = {
               "to_branch": this.state.selectedBranch,
               "line_items" : bill_line_out
           } 
        } else {
         service_url = Constants.STOCK_TRANSFER_INBOUND
         data = {
            "from_branch": this.state.selectedBranch,
            "line_items" : bill_line_items
        } 
       }
      }
      if( isManitory ) {
          this.props.showResposeValue("error", isManitory)
      } 
      else { 
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.onPressStovkTransferSuccess,
            this.onPressStovkTransferFailure
          );
      }    
    }
    onPressStovkTransferSuccess = (response) => {
        if (response.status === "success") {
          this.props.showResposeValue("success", response.message)

          this.setState({
            inBoundTableList: [],
            outBoundTableList: []
          })
        } else {
          this.props.showResposeValue("error", response.message)
        }
       }
       onPressStovkTransferFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    
       }
       onPressStockTrans = () => {
        var { inBoundTableList, outBoundTableList } = this.state
        var service_url = ""
        let current_row = inBoundTableList.length;
        let outBound_row = outBoundTableList.length
        let bill_line_items = [];
        let bill_line_out = [];

   
        for (let i = 0; i < current_row; i++) {
   
          if (inBoundTableList[i].drug_name) {
   
            bill_line_items.push({ "drug_ms": inBoundTableList[i].id, "request_quantity": parseInt(inBoundTableList[i].trans_qty) });
   
          }
       }
       for (let i = 0; i < outBound_row; i++) {
   
        if (outBoundTableList[i].drug_name) {
 
            bill_line_out.push({ "drug_ms": outBoundTableList[i].id, "request_quantity": parseInt(outBoundTableList[i].trans_qty), "stock_details": this.state.stockDetails });
 
        }
     }
       var service_url = ""
       var data = {}
        if(this.state.toggleSwitchFlag) {
            service_url = Constants.STOCK_TRANSFER_OUTBOUND
            data = {
               "to_branch": this.state.selectedBranch,
               "line_items" : bill_line_out
           } 
        } else {
         data = {
            "from_branch": this.state.selectedBranch,
            "line_items" : bill_line_items
        } 
       }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.onPressStovkTransferSuccess,
            this.onPressStovkTransferFailure
          );
       }
    renderTableView = () => {
        let { inBoundTableList, outBoundTableList } = this.state
        if (inBoundTableList.length < 10) {
            for (let i = inBoundTableList.length; i < 10; i++) {
                inBoundTableList.push(JSON.parse(JSON.stringify(tempRows)))
            }
        }
        if (outBoundTableList.length < 10) {
            for (let i = outBoundTableList.length; i < 10; i++) {
                outBoundTableList.push(JSON.parse(JSON.stringify(outtempRows)))
            }
        }
        return (
            <View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '480px' }}>
                    <View>
                        {!this.state.toggleSwitchFlag ?
                            <View>
                                {inBoundTableList && inBoundTableList.map((item, index) => {
                                    var sno = index + 1
                                    let zIndex = (index + 1) * -10;
                                    return (
                                        <View style={{ flexDirection: "row", flex: 1, zIndex: zIndex }} >
                                            <View style={{ flex: 0.05, alignItems: "center", alignSelf: "center" }}><Text style={{ fontSize: "1vw" }}>{sno}</Text></View>
                                            {this.renderTextInput('drug_name', item.drug_name, 0.15, index)}
                                            {this.renderTextInput('generic_name', item.generic_name, 0.15, index)}
                                            {this.renderTextInput('dosage_Strength', item.dosage_strength, 0.15, index)}
                                            {this.renderTextInput('batch_no', item.batch_no, 0.15, index)}
                                            {this.renderTextInput('exp_on', item.exp_on, 0.15, index)}
                                            {this.renderTextInput('avail_qty', item.avail_qty, 0.1, index)}
                                            {this.renderTextInput('trans_qty', item.trans_qty, 0.1, index)}
                                            {/* <TouchableOpacity onPress={() => { inBoundTableList[index]['drug_name'] ? this._handleItemRemoveDrug(index) : null }} style={{ flex: 0.03, justifyContent: "center", alignItems: "flex-end" }}>
                                                <Icon name="close" size={"1.5vw"} color={"#000000"} />
                                            </TouchableOpacity> */}
                                        </View>
                                    )
                                })
                                }
                            </View> :
                            <View>
                                {outBoundTableList && outBoundTableList.map((item, index) => {
                                    var sno = index + 1
                                    let zIndex = (index + 1) * -10;
                                    return (
                                        <View style={{ flexDirection: "row", flex: 1, zIndex: zIndex }} >
                                            <View style={{ flex: 0.05, alignItems: "center", alignSelf: "center" }}><Text style={{ fontSize: "1vw" }}>{sno}</Text></View>
                                            {this.renderTextInput('drug_name', item.drug_name, 0.12, index)}
                                            {this.renderTextInput('generic_name', item.generic_name, 0.12, index)}
                                            {this.renderTextInput('dosage_strength', item.dosage_strength, 0.12, index)}
                                            {this.renderTextInput('trans_quantity', item.trans_quantity, 0.1, index)}
                                            {this.renderTextInput('batch_no', item.batch_no, 0.14, index)}
                                            {this.renderTextInput('trans_qty', item.trans_qty, 0.1, index)}
                                            {this.renderTextInput('exp_on', item.exp_on, 0.15, index)}
                                            {this.renderTextInput('avail_qty', item.avail_qty, 0.1, index)}
                                            {/* <TouchableOpacity onPress={() => { inBoundTableList[index]['drug_name'] ? this._handleItemRemoveDrug(index) : null }} style={{ flex: 0.03, justifyContent: "center", alignItems: "flex-end" }}>
                                                <Icon name="close" size={"1.5vw"} color={"#000000"} />
                                            </TouchableOpacity> */}
                                        </View>
                                    )
                                })

                                }
                            </View>
                        }
                    </View>
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={styles.AddButton} onPress={() => { this._addNextRows() }}>
                            <Text style={styles.addText}> Add Item</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
                <TouchableOpacity style={[styles.AddButton, { justifyContent: "center", alignItems: "center", alignSelf: "center" }]} 
                  onPress={() => { 
                //    this.state.userType === "SUPER_ADMIN" ?
                    // this.onPressStockTrans() :   
                    this.onPressStovkTransfer() 
                  }} 
                >
                    <Text style={styles.addText}> Stock Transfer</Text>
               </TouchableOpacity>
            </View>
        )
    }
    renderPaginationView = () => {
        return (
            <View>
                {this.state.drugUsageTransaction && this.state.drugUsageTransaction.length > 0 ?
                    <Pagination
                        paginationChangedValues={this.paginationChangedValues.bind(this)}
                        totalItems={this.state.drugUsageTransaction}
                        paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                    /> : null
                }
            </View>
        )
    }
    render() {
        console.log(this.state.stockDetails, "brandNameSuggessionList")
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.userType === "SUPER_ADMIN" ? navigationHeaderAdmin : navigationHeader}
                    selectedTab={"stockTransfer"}
                    isNavigationBorder={true}
                />
                <View style={styles.container}>
                    {this.renderSubHeaderView()}
                    {this.renderHeaderView()}
                    {this.renderTableView()}
                    {this.renderPaginationView()}
                </View>
            </View>
        );
    }
}
const styles = StyleSheet.create({
    container: { margin: "0.5vw" },
    toggleText: { fontSize: "1vw", fontWeight: "500" },
    SwitchStyle: {
        width: "1vw",
        height: "1.2vw",
        marginHorizontal: "0.5vw",
        marginTop: "0.2vw"
    },
    headerTop: { marginTop: "0.5vw" },
    textInput: {
        paddingVertical: "0.5vw",
        paddingLeft: "0.2vw",
        marginVertical: "1vw",
        marginHorizontal: "0.2vw",
        backgroundColor: color.white,
        borderColor: "#CDD1D5",
        borderRadius: "0.4vw",
        borderWidth: 1,
        zIndex: -2,
        fontSize: "1vw"
    },
    AddButton: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        padding: "0.5vw"
    },
    buttonContainer: {
        flex: 1,
        alignSelf: "flex-end",
        alignItems: "flex-end",
        margin: "0.5vw",
    },
    addText: {
        color: color.white,
        fontSize: "1vw"
    },
    pickerStyle: {
        backgroundColor: color.white,
        height: "2vw",
        marginRight: "0.8vw",
        width: "8.5vw",
        fontSize: "1vw",
        // padding: '0.8vw'
    },
    suggestionMain:{position: "absolute", width: "11vw", marginTop: "3vw", maxHeight: "18vh", zIndex: 1},
    subContainer: {
        flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: "0.4vw", borderRadius: 4, marginTop: '0.5vw'
    },
    BatchSelectPopUP: {
        borderRadius: 10,
        width: '18vw',
        justifyContent: 'center',
        alignContent: 'center',
        padding: "0.65vw",
        position: 'absolute',
        backgroundColor: "white",
        maxHeight: '15vw',
        borderColor: 'black',
        borderWidth: 1,
    },
    BatchSelectPopUPWrapper: {
        display: 'flex', flexDirection: 'row', backgroundColor: '#E1F7F4', height: '1.5vw', borderRadius: '0.3vw', alignItems: 'center'
    },
    BatchScrollView: {
        width: '100%', display: 'flex', flexDirection: 'row', height: '2vw', borderBottomWidth: 1, borderBottomColor: '#E2E2E2', borderRadius: '0.3vw', alignItems: 'center',
    },
    NoListBatch: {
        fontSize: '0.7vw', alignSelf: 'center', justifyContent: 'center', margin: '1vw'
    },
    HeaderText: { position: 'absolute', marginLeft: "0.5vw", backgroundColor: "white", fontSize: "0.65vw", paddingHorizontal: "0.5vw", color: "#888888", marginTop: '-0.5vw'  },

})
