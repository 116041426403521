import React, { Component } from 'react'
import { TouchableOpacity, Text, View, StyleSheet, ScrollView } from 'react-native';
import { color } from "../../../../styles/Color";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import { Tooltip } from 'antd';

var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});


export default class TestReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TestReportsData: [],
      patientAppointment: this.props.patientAppointment,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      covertedToImageData: [],
    }
  }

  componentDidMount() {
    this.GetDocNotesData()
  }

  GetDocNotesData = () => {
    var serviceUrl = Constants.TEST_REPORT + "?patient_id=" + this.state.patientAppointment.patient_id + "&clinic_id=1" + this.state.selectedClinicOrDoctor.id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDataSuccess,
      this.getDataFailure
    );
  }

  getDataSuccess = (success) => {
    this.setState({
      TestReportsData: success.data
    })
  }
  getDataFailure = (error) => {
    console.log(error.message)
  }
  getS3Key(doc_key, img_id) {


    return new Promise(resolve => {
        var parms = { 
                      Bucket: Constants.s3BUCKET_NAME, 
                      // Bucket: "emedhub-stage",
                      Key: doc_key, Expires: 300 
                    };
        s3.getSignedUrl("getObject", parms, (err, doc_key) => {
            if (err) {
                // console.log(err);
                resolve(err)
            } else {
                // console.log(url);
    
                var data = {
                    "doc_key": doc_key, 
                    "img_id": img_id 
                } 
                resolve(data)
            }
        });
    });
}
  onPressView  = async (imageData) => {
    var tempArr = []
    for (let i = 0; i < imageData.length; i++) {
        var image_urls = imageData[i].result_img_url
        var img_id = imageData[i].lab_img_billing_id
        var getData = await this.getS3Key(image_urls, img_id)
        tempArr.push(getData)
    }
    this.setState({
        covertedToImageData: tempArr
    },() => {
      console.log(true, this.state.covertedToImageData[0].doc_key, this.state.covertedToImageData)
        this.props.showDocumentImage(true, this.state.covertedToImageData[0].doc_key, this.state.covertedToImageData)
    })
}

getPrintReport = (reportId)=>{
  var service_url = Constants.TEST_REPORT + this.state.patientAppointment.patient_id + "/test-reports/" + "?patient_id=" + this.state.patientAppointment.patient_id + "&clinic_id=" + this.state.selectedClinicOrDoctor.id + "&lab_id=" + reportId + "&export_type=pdf";
  OpthamologyService.getInstance().documentUploadGet(
    service_url,
    this.getTestReportPDFSuccess,
    this.getTestReportPDFFailure,
    "pdf"
);
}

getTestReportPDFSuccess = (response) => {
  if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      // console.log(file)
      window.open(fileURL);
    }
}


getTestReportPDFFailure = (response) => {
  console.log(response.message);
}
  render() {
    return (
      <View>
        <View style={styles.TableContainer}>
          <View>
            <View style={{ width: "100%", borderBottomWidth: 1 }}>
              <View style={[styles.TableTitleContainer]}>
                <View style={{ alignItems: "center", width: '25%', }} >
                  <Text style={styles.TableHeadText}>Date</Text>
                </View>
                <View style={{ alignItems: "center", width: '25%', }} >
                  <Text style={[styles.TableHeadText]}>Report Type</Text>
                </View>
                <View style={{ alignItems: "center", width: '25%', }} >
                  <Text style={styles.TableHeadText}>Test Details</Text>
                </View>
                <View style={{ alignItems: "center", width: '25%', }} >
                  <Text style={styles.TableHeadText}>Action</Text>
                </View>
              </View>
            </View>
          </View>

          <ScrollView style={styles.MainTable}>
            {this.state.TestReportsData && this.state.TestReportsData.length > 0 ? this.state.TestReportsData.map((item, index) => {
              return (
                <View style={[styles.TableDataWrapper]} >
                  <View style={{ width: '25%', alignItems: 'center', }}><Text style={[styles.TableDataText]}>{item.report_date}</Text></View>
                  <View style={{ width: '25%', alignItems: 'center', }}><Text style={[styles.TableDataText]}>{item.report_type}</Text></View>
                  <View style={{ width: '25%', alignItems: 'center', }}>
                    <Tooltip placement="topLeft" title={item.test_name}>
                      <Text style={styles.TableDataText}> {item.test_name && item.test_name.length > 18 ? item.test_name.slice(0, 18) + ".." : item.test_name != "" ? item.test_name : "-"} </Text>
                    </Tooltip>
                  </View>
                  <View style={{ width: '25%', alignItems: 'center' }}>
                    {item.file_name && (item.file_name).length > 0 ?
                      <TouchableOpacity style={{ flex: 0.25 }} onPress={() => {
                        this.onPressView(item.file_name)
                      }}>
                        <Text style={styles.BtnText}>View</Text>
                      </TouchableOpacity> :
                      <TouchableOpacity style={{ flex: 0.25 }} onPress={() => {
                        this.getPrintReport(item.id)
                      }}>
                        <Text style={styles.BtnText}>Print</Text>
                      </TouchableOpacity>
                    }
                  </View>
                </View>
              )
            }) :
              <View style={styles.NoRecordsView}>
                <Text style={styles.NoRecordsText}>No Records to show...</Text>
              </View>}

          </ScrollView>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableContainer: {
    marginRight: '1vw',
    marginTop: '0.5vw',
    borderWidth: 1,
    borderRadius: '0.26vw',
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  BtnText: {
    fontSize: '0.91vw',
    fontWeight: '500',
    color: color.themeDark
  },
  TableDataView: {
    justifyContent: 'center',
    maxHeight: '2.9vw',
    minHeight: '2.9vw',
    width: "100%",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderTopLeftRadius: '0.26vw',
    borderTopRightRadius: '0.26vw',
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw"
  },
  MainTable: {
    height: '67vh'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw'
  },
  NoRecordsText: {
    fontSize: '1vw'
  },
})
