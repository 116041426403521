//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { OBGYNHistorySectionHeading,CommonHistorySectionHeader, CommonButton, CommonAddButton, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";

// create a component
const pageName = "isSocialHistory"

export class SocialHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            listOfDatas: [],
            getListofSocial: {}
        }
    }
    componentDidMount() {
        this.getSocialHistory()
    }
    getSocialHistory(){
        var service_url = Constants.GET_SOCIAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
          service_url,
          this,
          this.getSocialHistorySuccess,
          this.getSocialHistoryFailure
        );
    }
    getSocialHistorySuccess = success => {
        // alert(JSON.stringify(success))

        if(success.status =="success"){

            var data =success.data.patient_social_history[0]

            var prepareData=[]
                Object.keys(data)?.forEach((keys)=>{

                    
                    
                    if(keys =="alcohol"&& data[keys] ){
                        prepareData.push({ label:"Alcohol", value:data[keys]  })
                    }else if(keys =="smoking"){
                        prepareData.push({ label:"Smoking", value:data[keys]  })
                    }else if(keys =="drugs"&& data[keys] ){
                        prepareData.push({ label:"Drugs", value:data[keys]  })
                    }else if(keys =="daily_activity"){
                        prepareData.push({ label:"Daily activity", value:data[keys]  })
                    }else if(keys =="is_married" ){
                        prepareData.push({ label:"Is married", value:data[keys] ?"Yes":"No"  })
                    }else if(keys =="is_exercise_done_daily" ){
                        prepareData.push({ label:"Is exercise done daily", value:data[keys] ? "Yes":"No" })
                    }else if(keys =="occupation" && data[keys] ){
                        prepareData.push({ label:"Occupation", value:data[keys]  })
                    }


                    // return(
                    //     <View>
                    //         {
                    //             keys =="alcohol"?
                    //             <View style={{marginTop:15,flexDirection: 'row', justifyContent: 'space-between',}}>
                    //                 <Text>{"Alcohol"}</Text>
                    //         <Text>{this.state.getListofSocial[keys]}</Text>
                    //             </View> : null
                    //         }
                    //     </View>
                            
                    //     )
                })
            


            this.setState({
                getListofSocial:data,
                listOfDatas:prepareData
            })
        }
      // console.log("success"+JSON.stringify(success))
    }
    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    getSocialHistoryFailure = error =>{
        console.log("success _________> error"+JSON.stringify(error))

    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getSocialHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            this.editHistory()
        }, 100);
    }
    editHistory() {
        this.props.editHistory(this.state.getListofSocial, pageName)
    }

    renderContent(item,indx) {
        return (
            <View key={indx} style={{ marginTop: 15, flexDirection: 'row', justifyContent: 'space-between',marginLeft:10 }}>
                <Text style={[Style.historyHeaderFontStyle,{flex:0.6,marginLeft:10}]}>{item.label}</Text>
                <Text style={[Style.historyHeaderFontStyle,{flex:0.4,marginRight:12}]}>{item.value}</Text>
            </View>
        )
    }

    render() {
        return (
            <View style={styles.container}>
                {/* <View style={{flexDirection:'row',justifyContent:'space-between'}}> */}
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Social History"}
                    editImage={this.state.listOfDatas.length > 0  ? "pencil" : ""}
                />
                {/* </View> */}
                {
                    this.state.listOfDatas.length > 0 ?
<View style={{marginTop:15}}>
                    <CommonHistorySectionHeader
                    heading1={"History"}
                    heading2={"Details"}
                    noOfColumn={2}
                    columnSize={[0.6,0.4]}
                    />
                </View>: null
                }
                

                {
                    this.state.listOfDatas && this.state.listOfDatas.length > 0 &&
                    this.state.listOfDatas.map((item,index)=>{
                       return this.renderContent(item,index)
                    })
                }
                
                        

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop:20
    },
});

//make this component available to the app
// default SocialHistory;
