import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
var Messages = require('../../../../utils/InfoMessages')

const screenName = "Screen For Child Anxiety Related Disorders(Scared) Child Version"

export class ScaredChildVersion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofScaredChildVersion: {},
            comments: "",
            totalScore: "",
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId,

            pnValues :0,
            shValues :0,
            scValues :0,
            spValues :0,
            gdValues: 0
        }
    }

    componentDidMount() {
        this.getScaredChildVersion()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getScaredChildVersion()
        })
    }

    getScaredChildVersion() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL + 
        "?patient_id="+this.state.patientId+
        "&appointment_id="+this.state.appointmentId+
        "&rating_scale_name="+screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getScaredChildVersionSuccess,
            this.getScaredChildVersionFailure
        );
    }

    getScaredChildVersionSuccess = (success) => {
        if (success.status == "success") {
            this.setState({
                listofScaredChildVersion: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score,
                // getTotalScore:success.data.total_score,
                pnValues :success.data.PN_score,
                shValues :success.data.SH_score,
                scValues :success.data.SC_score,
                spValues :success.data.SP_score,
                gdValues: success.data.GD_score


            },()=>{
                this.validateTotalScores()
            })
        }
    }
    getScaredChildVersionFailure = error => { }


    validateTotalScores() {
        var { listofScaredChildVersion } = this.state;
        var getListOfQuestions = listofScaredChildVersion.questions;
        var getTotalScore = 0;
        var pnValues = 0;
        var shValues = 0;
        var scValues = 0;
        var spValues = 0;
        var gdValues = 0;
        if (getListOfQuestions.length > 0) {
            console.log("getListOfQuestions---- > "+JSON.stringify(getListOfQuestions))
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {

                            if(getListOfQuestions[i].question_heading == "PN"){
                                pnValues=pnValues+getOptions[j].option_score
                            }
                            if(getListOfQuestions[i].question_heading == "SH"){
                                shValues=shValues+getOptions[j].option_score
                            }
                            if(getListOfQuestions[i].question_heading == "SC"){
                                scValues=scValues+getOptions[j].option_score
                            }
                            if(getListOfQuestions[i].question_heading == "SP"){
                                spValues=spValues+getOptions[j].option_score
                            }
                            if(getListOfQuestions[i].question_heading == "GD"){
                                gdValues=gdValues+getOptions[j].option_score
                            }
                            
                            getTotalScore = Math.abs(getTotalScore)+Math.abs(getOptions[j].option_score)
                        }
                    }
                }
            }
        }
        this.setState({
            // totalScore: this.state.getTotalScore -  getTotalScore
            totalScore: getTotalScore,


            pnValues :pnValues,
            shValues :shValues,
            scValues :scValues,
            spValues :spValues,
            gdValues: gdValues

        })
    }


    saveScaredChildVersion() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var data = {
            "id": this.state.listofScaredChildVersion.id ? this.state.listofScaredChildVersion.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofScaredChildVersion.rating_scale_id,
            "rating_scale_name": this.state.listofScaredChildVersion.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": this.state.listofScaredChildVersion.questions,

            "PN_score": this.state.pnValues,
            "GD_score": this.state.gdValues,
            "SP_score": this.state.spValues,
            "SC_score": this.state.scValues,
            "SH_score": this.state.shValues,

        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveScaredChildVersionSuccess,
            this.saveScaredChildVersionFailure
        );
    }else{
        this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
    }
    }

    saveScaredChildVersionSuccess = success => {
        if (success.status == "success") {
            this.getScaredChildVersion()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveScaredChildVersionFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(indexOfQuestion, indexOfOptions) {
        var { listofScaredChildVersion } = this.state;
        var getListOfOptions = listofScaredChildVersion.questions[indexOfQuestion].options;
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        listofScaredChildVersion.questions[indexOfQuestion]["options"] = prepareData
        this.setState({ listofScaredChildVersion }, () => {
            this.validateTotalScores()
        })

    }

    // renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
    //     return (
    //         <View key={indexOfOptions} >
    //             <TouchableOpacity
    //                 onPress={() => {
    //                     this.onselectOptions(indexOfQuestion, indexOfOptions)
    //                 }}
    //                 style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
    //                 <View style={{ flex: 0.05 }} >
    //                     <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={24} />
    //                 </View>
    //                 <View style={{ flex: 0.95 }} >
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    renderListOfDatas(questions, indexOfQuestion) {
        var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
        return (
            <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
                <View key={indexOfQuestion} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{getQuestion}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={{ flex: 0.34 ,alignItems:'center',justifyContent:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,0)
                        }}>
                            <CheckBoxIcon name={questions.options[0].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.34 ,alignItems:'center',justifyContent:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,1)
                        }}>
                            <CheckBoxIcon name={questions.options[1].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.32 ,alignItems:'center',flexDirection:'row',justifyContent:"space-evenly" }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,2)
                        }}>
                            <CheckBoxIcon name={questions.options[2].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{questions.question_heading}</Text>

                    </View>
                    {/* <View style={{ flex: 0.06 ,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{questions.question_heading}</Text>
                    </View> */}
                </View>
            </View>
            </View>
        )
    }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                </View>
                </View>
        )
    }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',  borderBottomColor: "#888888", borderBottomWidth: 1,  paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={{ flex: 0.34,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 13, color: "#292929" ,textAlign:'center'}} numberOfLines={5}>{"Not True or Hardly Ever True"}</Text>
                    </View>
                    <View style={{ flex: 0.34 ,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 13, color: "#292929" ,textAlign:'center'}} numberOfLines={5}>{"Somewhat True or Sometimes True"}</Text>
                    </View>
                    <View style={{ flex: 0.32 ,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 13, color: "#292929" ,textAlign:'center'}} numberOfLines={5}>{"Very True or Often True"}</Text>
                    </View>
                    {/* <View style={{ flex: 0.06 ,alignItems:'center' }}/> */}
                </View>
            </View>
        )
    }

    renderListofQuestionsValue(heading,value){
        return(
            <View>
                    <Text style={{fontSize:17,fontWeight:'500',color:"#888888"}}>{heading}</Text>
                    <Text style={{fontSize:17,fontWeight:'500',color:color.themeDark,marginTop:10}}>{value}</Text>
                </View>
        )
    }

    renderSubheadingValues(){
        return(
            <View style={{flexDirection:'row',justifyContent:'space-evenly',borderTopColor:"#888888",borderTopWidth:1,paddingVertical:10}}>
                {this.renderListofQuestionsValue("PN",this.state.pnValues)}
                {this.renderListofQuestionsValue("SH",this.state.shValues)}
                {this.renderListofQuestionsValue("SC",this.state.scValues)}
                {this.renderListofQuestionsValue("SP",this.state.spValues)}
                {this.renderListofQuestionsValue("GD",this.state.gdValues)}
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("Screen For Child Anxiety Related Disorders(Scared) Child Version")}
                        {
                            this.state.listofScaredChildVersion && this.state.listofScaredChildVersion.questions && this.state.listofScaredChildVersion.questions.length > 0 ?
                                this.state.listofScaredChildVersion.questions.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        }

                        {this.renderSubheadingValues()}

                        <View style={{ backgroundColor: color.applicationBackgroundColor,height:60,justifyContent:'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveScaredChildVersion.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})