import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Platform,
  Picker,
  StyleSheet,
  TextInput,
  TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import AsyncStorage from '../../../AsyncStorage';

const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class ServiceOverView extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data: {},
      doctorandservices: {},
      totalAmount: '',
      selectedDoctorAction: '',
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isServiceOverView: false,
      isClear: false,
      isClearInt: 0,
      isPaginated: false,
      pageName: '',
      isTableData: [],
      selectedServiceInput: "",
      ServiceInput: "",
      searchServicesList: [],
      ListOpen: false,
      isAdminAccess: false
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getServiceOverView()
    this.getBillingList()
  }
  getServiceOverView() {
    var states = this.state
    var service_url = Constants.POST_ServiceOverview;
    var data = {
      'doctor_id': states.selectedDoctorAction === '' ? [] : [Number(states.selectedDoctorAction)],
      'service_name': states.selectedServiceInput,
      'from_date': this.state.filterData.fromDate,
      'to_date': this.state.filterData.toDate
    }
    this.setState({
      isClear: false,
      isTableData: [],
      data: [],
    })

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.ChangeStatusSuccess,
      this.ChangeStatusFailure
    );
  }
  ChangeStatusSuccess = success => {

    if (success.status == "success") {
      this.setState({
        data: success.data,
        totalAmount: success.data.total_amount,
        isServiceOverView: true
        // isServiceOverView:success.data.service_overview?true:false
      })

    } else {
      console.log('error', success.message);

    }
  }
  ChangeStatusFailure = error => {

    console.log('error', error.message);
  }

  getBillingList = () => {
    let states = this.state;
    var serviceUrl = Constants.GET_ServiceName;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getBillingListSuccess,
      this.getBillingListFailure
    );
  }

  getBillingListSuccess = success => {
    if (success.status == "success") {
      const newData = { ...success.data }
      this.setState(Object.assign(this.state.doctorandservices, { ...newData }));
      console.log('Suceesdata', this.state.doctorandservices)

    }
  }

  getBillingListFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  renderPickerDoctorName() {
    return (
      <View>
        <Picker

          selectedValue={this.state.selectedDoctorAction}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor:
              this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5'
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedDoctorAction"] = itemvalue;
            this.setState({ states });
            console.log("selected Doctor Action", this.state.selectedDoctorAction)
          }}
        >
          <Picker.Item label='Select Doctor Name' value='' />
          {Object.keys(this.state.doctorandservices).length > 0 ?
            this.state.doctorandservices.doctor.map(name =>
              <Picker.Item label={name.doctor_name} value={name.doctor_id} />

            )

            : null
          }

        </Picker>
      </View>
    )
  }

  renderServiceList = (value) => {
    let { searchServicesList } = this.state;
    let tempDescList = this.state.doctorandservices.services
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].service_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchServicesList = searchListTemp;
    } else {
      searchServicesList = this.state.doctorandservices.services;
    }
    this.setState({
      searchServicesList
    })
  }

  renderServiceAutoSearch = () => {
    return (
      <View style={{ zIndex: 2 }}>
        <TextInput
          placeholder='Select Service Name'
          style={{ marginTop: '5px', height: '2vw', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", borderRadius: "4px", width: '14.64vw', fontSize: '1vw', paddingLeft: '.8vw' }}
          value={this.state.ServiceInput}
          onChangeText={(text) => {
            this.setState({ ServiceInput: text, selectedServiceInput: '' }, () => {
              this.renderServiceList(text);
              this.setState({ ListOpen: this.state.ServiceInput.length > 0 ? true : false })
            })
          }
          }
        />
        {this.state.ServiceInput.length > 0 && this.state.ListOpen && this.state.searchServicesList.length > 0 ?
          <ScrollView showsVerticalScrollIndicator={false} style={{ position: 'absolute', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", marginTop: "2.2vw", maxHeight: '12vw', zIndex: 3, width: '14.64vw', borderRadius: '4px' }}>
            {
              this.state.searchServicesList.map(name =>
                <TouchableOpacity
                  style={{ marginTop: '.5vw', paddingLeft: '.8vw' }}
                  onPress={
                    event => {
                      this.setState({ ServiceInput: name.service_name, selectedServiceInput: name.service_name, ListOpen: false })
                    }
                  }
                >
                  <Text style={{ fontSize: "1vw" }}>
                    {name.service_name}
                  </Text>
                </TouchableOpacity>
              )
            }
          </ScrollView>
          : null}
      </View>
    )
  }


  onPressButton(key, value) {
    if (key == "save") {
      this.getServiceOverView()
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      var states = this.state
      // states['valueData'] = []
      this.setState({
        selectedDoctorAction: "",
        selectedServiceInput: "",
        ServiceInput: '',
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        totalAmount: "",
        isClear: true,
        isClearInt: 1,
        data: {},
        filterDate: {}
      }, () => {
        this.getServiceOverView();
        this.setState({
          isClear: false
        })
      })


    }
    else if (key == "print") {
      //   this.printBillingList()
      this.PrintPatienToken()
    }
  }

  paginationChangedValues = (data) => {
    this.setState({
      isTableData: data
    })
  }

  PrintPatienToken = (key) => {

    console.log(key, 'key')
    let states = this.state;
    let data = {
      'doctor_id': states.selectedDoctorAction === '' ? [] : states.selectedDoctorAction === 'Nursing' ? [states.selectedDoctorAction] : [Number(states.selectedDoctorAction)],
      'service_name': states.selectedServiceInput,
      'from_date': states.filterData.fromDate,
      'to_date': states.filterData.toDate

    }


    var serviceUrl = Constants.PRINT_ServiceOverview + '?export=pdf';
    OpthamologyService.getInstance().documentUploadPost(
      serviceUrl,
      data,
      this,
      this.getPrintSuccess,
      this.getPrintFailure
    )

  };


  getPrintSuccess = response => {

    if (response) {

      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {

    console.log(error);
  };
  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    console.log(this.state.selectedDoctorAction)
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "64%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            <View style={{ width: "100%", marginTop: "1.5vw", paddingHorizontal: "0.35" }} >
              <CommonHistorySectionHeader
                heading1={"Doctor Name"}
                heading2={"Service Name"}
                heading3={"Count"}
                heading4={"Total"}
                heading5={"Discount"}
                heading6={"Amount"}
                noOfColumn={6}
                columnSize={[0.22, 0.22, 0.13, 0.15, 0.13, 0.15]}
              />

              <ScrollView showsVerticalScrollIndicator={false} style={{ height: '70vh', overflowY: 'scroll' }}>
                <View>
                  {this.state.isTableData && this.state.isTableData.length > 0 ?
                    this.state.isTableData.map(name => {
                      var date = moment(name.date).format("DD-MM-YYYY")
                      return (
                        <View style={{ flexDirection: "row", paddingVertical: '0.8vw', width: "100%", borderBottomWidth: 1, borderBottomColor: color.lightGray, marginLeft: 20 }}>
                          <Text style={{ fontSize: '1vw', flex: 0.22 }}>{name.doctor_name == null ? name.nurse_name : name.doctor_name}</Text>
                          <Text style={{ fontSize: '1vw', flex: 0.22 }}>{name.service_name}{"("}{"₹"}{name.amount}{")"}</Text>
                          <Text style={{ fontSize: '1vw', flex: 0.13 }}>{name.count ? name.count : 1}</Text>
                          <Text style={{ fontSize: '1vw', flex: 0.15 }}>{name.total}</Text>
                          <Text style={{ fontSize: '1vw', flex: 0.13 }}>{name.discount_amount}</Text>
                          <Text style={{ fontSize: '1vw', flex: 0.15 }}>{name.total_discount}</Text>
                        </View>)
                    }
                    ) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                      <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                    </View>
                  }</View>
              </ScrollView>
              <View style={{ marginLeft: 20 }}>
                {this.state.data.service_overview && this.state.data.service_overview.length > 0 ?
                  <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.data.service_overview)} totalItems={this.state.data.service_overview} />
                  : null

                }

              </View>
            </View>
          </View>
        </View>

        {/* ============================ */}


        <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, padding: "1vw", }}>
          <View>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: '1vw' }}>
              <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white, height: '4vw',
                borderRadius: 4,
                paddingHorizontal: 15,
                minWidth: '8vw',
                marginRight: 20
              }}>
                <View><Text style={{ fontSize: '1.02vw', fontWeight: '500', marginTop: 10 }}>{this.state.data.total_amount ? "₹ " : null}{this.state.totalAmount ? parseFloat(this.state.totalAmount).toFixed(2) : 0}</Text><Text style={{ fontSize: '0.87vw', fontWeight: '400', textAlign: 'right', paddingVertical: 10 }}>{"Total"}</Text></View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw', marginTop: '1vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                <View style={{ minWidth: '10.98vw' }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    {this.state.isClear && this.state.isClearInt === 0 ? null :
                      this.state.isClear && this.state.isClearInt === 1 ?
                        <CommonDateFilter
                          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                          defaultSelectedDateFilter={"Today"}
                          startDate={this.state.filterData.fromDate}
                          endDate={this.state.filterData.toDate}
                          clearPress={this.state.isClear}
                          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                          filterDate={this.state.filterDate} /> :
                        <CommonDateFilter
                          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                          defaultSelectedDateFilter={"Today"}
                          startDate={this.state.filterData.fromDate}
                          endDate={this.state.filterData.toDate}
                          clearPress={this.state.isClear}
                          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                          filterDate={this.state.filterDate} />
                    }
                  </View>
                </View>
              </View>

              <View style={{
                flexDirection: "row",
                marginBottom: 20,
                marginTop: 20,
                alignItems: "center",
                zIndex: -1
              }}>

                {this.renderPickerDoctorName()}
                {this.renderServiceAutoSearch()}


              </View>

              <View style={{ flexDirection: "row", justifyContent: 'space-evenly', zIndex: -2, marginVertical: 20 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }


              </View>


            </View>
          </View>
        </View >
      </View >
    )
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw",
  },
});