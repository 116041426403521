import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "WithdrawalSymptoms"


export class WithdrawalSymptomsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            insomniaFlag: false,
            detailsOfInsomnia: "",

            anxietyFlag: false,
            detailsOfAnxiety: "",

            tremorsFlag: false,
            detailsOfTremors: "",

            seizuresFlag: false,
            detailsOfSeizures: "",

            tremulousnessFlag: false,
            detailsOfTremulousness: "",

            delirumTremorsFlag: false,
            detailsOfDelirumTremors: "",

            palpitationFlag: false,
            detailsOfPalpitation: "",

            lastDrinkFlag: false,
            detailsOfLastDrink: "",

            sweatingFlag: false,
            detailsOfSweating: "",

            additionalNotesFlag: false,
            detailsOfAdditionalNotes: ""

        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["insomniaFlag"] = data.insomnia;
                states["detailsOfInsomnia"] = data.insomnia_detail;

                states["anxietyFlag"] = data.anxiety;
                states["detailsOfAnxiety"] = data.anxiety_detail;

                states["tremorsFlag"] = data.tremors;
                states["detailsOfTremors"] = data.tremors_detail;

                states["seizuresFlag"] = data.seizures;
                states["detailsOfSeizures"] = data.seizures_detail


                states["tremulousnessFlag"] = data.tremulousness;
                states["detailsOfTremulousness"] = data.tremulousness_detail;

                states["delirumTremorsFlag"] = data.delirum_tremors;
                states["detailsOfDelirumTremors"] = data.delirum_tremors_detail


                states["palpitationFlag"] = data.palpitation;
                states["detailsOfPalpitation"] = data.palpitation_detail

                states["lastDrinkFlag"] = data.last_drink;
                states["detailsOfLastDrink"] = data.last_drink_detail


                states["sweatingFlag"] = data.sweating;
                states["detailsOfSweating"] = data.sweating_detail

                states["additionalNotesFlag"] = data.additional_notes;
                states["detailsOfAdditionalNotes"] = data.additional_notes_detail


                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["insomniaFlag"] = false;
        states["detailsOfInsomnia"] = "";

        states["anxietyFlag"] = false;
        states["detailsOfAnxiety"] = "";

        states["tremorsFlag"] = false;
        states["detailsOfTremors"] = "";

        states["seizuresFlag"] = false;
        states["detailsOfSeizures"] = "";


        states["tremulousnessFlag"] = false;
        states["detailsOfTremulousness"] = "";

        states["delirumTremorsFlag"] = false;
        states["detailsOfDelirumTremors"] = "";


        states["palpitationFlag"] = false;
        states["detailsOfPalpitation"] = "";

        states["lastDrinkFlag"] = false;
        states["detailsOfLastDrink"] = "";


        states["sweatingFlag"] = false;
        states["detailsOfSweating"] = "";

        states["additionalNotesFlag"] = false;
        states["detailsOfAdditionalNotes"] = "";

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,

                "insomnia": states.insomniaFlag,
                "insomnia_detail": states.detailsOfInsomnia,

                "tremors": states.tremorsFlag,
                "tremors_detail": states.detailsOfTremors,

                "tremulousness": states.tremulousnessFlag,
                "tremulousness_detail": states.detailsOfTremulousness,

                "palpitation": states.palpitationFlag,
                "palpitation_detail": states.detailsOfPalpitation,

                "sweating": states.sweatingFlag,
                "sweating_detail": states.detailsOfSweating,

                "anxiety": states.anxietyFlag,
                "anxiety_detail": states.detailsOfAnxiety,

                "seizures": states.seizuresFlag,
                "seizures_detail": states.detailsOfSeizures,

                "delirum_tremors": states.delirumTremorsFlag,
                "delirum_tremors_detail": states.detailsOfDelirumTremors,

                "last_drink": states.lastDrinkFlag,
                "last_drink_detail": states.detailsOfLastDrink,

                "additional_notes": states.additionalNotesFlag,
                "additional_notes_detail": states.detailsOfAdditionalNotes,
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_WITHDRAWL_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveWithdrawalSymptomsSuccess,
                this.saveWithdrawalSymptomsFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Withdrawal symptoms already added please click on edit icon to update the values")
        }
    }

    saveWithdrawalSymptomsSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveWithdrawalSymptomsFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Withdrawal Symptoms"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Insomnia", "Enter details",
                    "insomniaFlag", this.state.insomniaFlag,
                    "detailsOfInsomnia", this.state.detailsOfInsomnia
                )}

                {this.renderHistoryComponent(
                    "Anxiety", "Enter details",
                    "anxietyFlag", this.state.anxietyFlag,
                    "detailsOfAnxiety", this.state.detailsOfAnxiety
                )}

                {this.renderHistoryComponent(
                    "Tremors", "Enter details",
                    "tremorsFlag", this.state.tremorsFlag,
                    "detailsOfTremors", this.state.detailsOfTremors
                )}

                {this.renderHistoryComponent(
                    "Seizures", "Enter details",
                    "seizuresFlag", this.state.seizuresFlag,
                    "detailsOfSeizures", this.state.detailsOfSeizures
                )}

                {this.renderHistoryComponent(
                    "Tremulousness", "Enter details",
                    "tremulousnessFlag", this.state.tremulousnessFlag,
                    "detailsOfTremulousness", this.state.detailsOfTremulousness
                )}
                {this.renderHistoryComponent(
                    "Delirium Tremens", "Enter details",
                    "delirumTremorsFlag", this.state.delirumTremorsFlag,
                    "detailsOfDelirumTremors", this.state.detailsOfDelirumTremors
                )}

                {this.renderHistoryComponent(
                    "Palpitation", "Enter details",
                    "palpitationFlag", this.state.palpitationFlag,
                    "detailsOfPalpitation", this.state.detailsOfPalpitation
                )}


                {this.renderHistoryComponent(
                    "Last Drink", "Enter details",
                    "lastDrinkFlag", this.state.lastDrinkFlag,
                    "detailsOfLastDrink", this.state.detailsOfLastDrink
                )}

                {this.renderHistoryComponent(
                    "Sweating", "Enter details",
                    "sweatingFlag", this.state.sweatingFlag,
                    "detailsOfSweating", this.state.detailsOfSweating
                )}

                {this.renderHistoryComponent(
                    "Additional Notes", "Enter details",
                    "additionalNotesFlag", this.state.additionalNotesFlag,
                    "detailsOfAdditionalNotes", this.state.detailsOfAdditionalNotes
                )}

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
