//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity,Image } from 'react-native';
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from '../../../styles/Color';
import { QMSHeader, NavigationTopHeader } from './../BaseComponent';
const screenHeight = Dimensions.get("window").height;
import AsyncStorage from "../../../AsyncStorage";


// create a component
const navigationHeaderList = [
    { label: "Dashboard", value: "QMSDashBoard" }
]
export class QMSDashboardContent extends Component {

    constructor(props){
        super(props);
        this.state={
            listofDetails:{}
        }
    }

    componentDidMount(){
        this.getCounterDetails()
    }


    async getCounterDetails(){
        // var counterID=""
        // if(this.props.counterID){
        //     counterID=this.props.counterID
        // }

        const clintID = await  AsyncStorage.getItem("UserID");
        if(clintID){

        // var service_url = Constants.QMS_SHIFT_COUNTER_LIST_GET+"?client_id="+this.state.selectedClinicOrDoctor.id;
        // var service_url = Constants.QMS_COUNTER_DETAILS+"?counter_id="+counterID;
        // var service_url = Constants.QMS_COUNTER_DETAILS+"?counter_id="+1;
        var service_url = Constants.QMS_DASHBOARD+clintID;
        
        OpthamologyService.getInstance().getComplaints(
          service_url,
          this,
          this.getCounterDetailsSuccess,
          this.getCounterDetailsFailure,
          "",
          "qms"
        );
    }
      }
      getCounterDetailsSuccess=success=>{
        if(success.status == "success" ){
            this.setState({
                listofDetails:success.data
            })
        }else{
        }

      }
      getCounterDetailsFailure=error=>{
       // alert("error *******************> "+JSON.stringify(error))
    
      }



    changeTabSection(page) {
        //  alert(page) 
        }

    renderDashboardCard(type, value,bgColor,image) {
        return (
            <View style={{
                justifyContent:"space-between",
                backgroundColor: bgColor, borderRadius: 4, height: 110, width: "45%",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6, shadowRadius: 3,
                elevation: 5,paddingVertical:10,paddingHorizontal:15
            }}>


                <View style={{ justifyContent:'space-between',flexDirection:'row' }}>
                    <Text style={{ color: color.white, fontWeight: 'bold', fontSize: 50 }}>{value}</Text>
                    <Image source={image} style={{height:40,width:40,tintColor:color.white}}/>
                </View>


                <View style={{ alignItems: 'flex-end' }}>
                    <Text style={{ color: color.white }}>{type}</Text>
                </View>
            </View>
        )
    }

    renderDashboardFirstRow() {
        var {listofDetails} = this.state;
        // listofDetails && listofDetails.waiting_tokens ? listofDetails.waiting_tokens :0,
        var pendingTokens="";
        if(listofDetails){
            if(listofDetails.waiting_tokens &&  listofDetails.processing_tokens){
                pendingTokens = parseInt(listofDetails.waiting_tokens) + parseInt (listofDetails.processing_tokens);
            }else if(listofDetails.processing_tokens){
                pendingTokens = listofDetails.processing_tokens;
            }else if(listofDetails.waiting_tokens){
                pendingTokens = listofDetails.waiting_tokens;
            }

        }else{
            pendingTokens = 0;  
        }
        return (
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.45 }}>
                    <View style={{ flexDirection: 'row', height: 130, justifyContent: 'space-between' }}>
                        {this.renderDashboardCard("Departments", 
                        listofDetails && listofDetails.number_of_counters ? listofDetails.number_of_counters :0,
                        // 100,
                        "#88B0CE",require('../../../../assets/images/QMS_Images/Departments.png'))}
                        {this.renderDashboardCard("Total Token", 
                        listofDetails && listofDetails.total_tokens ? listofDetails.total_tokens :0,
                        // 100,
                        "#72D0FD",require('../../../../assets/images/QMS_Images/Token.png'))}
                    </View>
                    <View style={{ marginTop:8,flexDirection: 'row', height: 130, justifyContent: 'space-between' }}>
                        {this.renderDashboardCard("Complete Token", 
                        listofDetails && listofDetails.completed_tokens ? listofDetails.completed_tokens :0,
                        // 100,
                        "#7EA9FD",require('../../../../assets/images/QMS_Images/Token.png'))}
                        {this.renderDashboardCard("Pending Token", 
                        pendingTokens,
                        // 100,
                        "#758BC9",require('../../../../assets/images/QMS_Images/Token.png'))}
                    </View>
                </View>
                <View style={{ flex: 0.1 }}></View>
                <View style={{ flex: 0.45 }}></View>
            </View>
        )
    }


    renderDashboardContent() {
        return (
            <View style={{ backgroundColor: color.white, borderRadius: 4, paddingHorizontal: 10, paddingVertical: 15, marginHorizontal: 15, marginVertical: 20 }}>
                {this.renderDashboardFirstRow()}
            </View>
        )
    }
    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={"QMSDashBoard"} />

                {this.renderDashboardContent()}

            </View>
        );
    }
}



// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

//make this component available to the app

