import React, { Component } from 'react';
import { View, ScrollView, Dimensions, StyleSheet, Text } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton,HistoryFilledBy } from '../../BaseComponent'
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import { PerinatalFactors } from './PerinatalFactors'
import { PerinatalFactorsNew } from './PerinatalFactorsNew'

import { PrenatalFactors } from './PrenatalFactors'
import { PrenatalFactorsNew } from './PrenatalFactorsNew'

import { NatureOfDelivery } from './NatureOfDelivery'
import { NatureOfDeliveryNew } from './NatureOfDeliveryNew'

import { DevlopmentalAssessment } from './DevlopmentalAssesment'
import { DevlopmentalAssessmentNew } from './DevlopmentalAssessmentNew'

import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

var Messages = require("../../../../utils/InfoMessages")

import moment from "moment";

const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
    { label: "Prenatal Factors", value: "prenatalFactors" },
    { label: "Perinatal Factors", value: "perinatalFactors" },
    { label: "Nature of Delivery", value: "natureOfDelivery" },
    { label: "Developmental Assessment", value: "devlopmentalAssessment" }

];

export class PeadiatricMentalHealth extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            selectedSection: "prenatalFactors",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            scrollPosition: 0,
            SelectedAge: "",
            personalSocial: [],
            gross_Motor: [],
            language: [],
            fineMotor: [],
            isheading : false,

            information:"",
            reliability:"",
            historyFilledBy:"",
            historyFilledBYID:"",
            userType:props.userType,

            setScrollRef:null,
            dataSourceCords:[],
            scrollToIndex:0
        }
    }

    componentDidMount(){
        this.getHistoryFilledByDetails()
    }


    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    showResposeValue(type, message) {
        this.props.showResposeValue(type, message);
    }

    selectedRightSideView(pageName, isheading) {
        this.setState({
            selectedSection: pageName,
            isheading: isheading
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };


    getdenvarAge(item) {
        this.setState({
            SelectedAge: item
        })
    }

    scrollHandler(){
        var {dataSourceCords,scrollToIndex}=this.state
        if(dataSourceCords.length>scrollToIndex){
            this.state.setScrollRef.scrollTo({
                x: 0,
                y: dataSourceCords[scrollToIndex - 1],
                animated: true,
            })    
        }
    }
    changeTabSection(screen,index) {
        var states = this.state;
        states["selectedSection"] = screen
        states["scrollToIndex"]=parseInt(index+1)
        this.setState({
            states
        },()=>{
            this.scrollHandler()
        })
    }
    renderTopNavigation() {
        return (
            <View style={{ marginBottom: 10 }}>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={this.state.selectedSection} />
            </View>
        )
    }
    renderParentComponent(item,key){
        return(
            <View 
              key={key}
              style={Style.doctorNotesSectionView}
              onLayout={(event)=>{
                const layout=event.nativeEvent.layout
                this.state.dataSourceCords[key]=layout.y
                this.setState({
                    setDataSourceCords:this.state.dataSourceCords
                })
              }}
            >
               {item}
            </View>
        )
    }
   listOfLeftSideComponent(){
    var datas=[
                        //    <View style={Style.doctorNotesSectionView}>
                                <PrenatalFactors selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>
                            // <View style={Style.doctorNotesSectionView}>
                                <PerinatalFactors selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>

                            // <View style={Style.doctorNotesSectionView}>
                                <NatureOfDelivery selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>

                            // <View style={Style.doctorNotesSectionView}>
                                <DevlopmentalAssessment selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    SelectedAge={this.state.SelectedAge}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                    getdenvarAge={this.getdenvarAge.bind(this)}
                                    denvarvalues={this.denvarvalues.bind(this)}
                                    userType={this.state.userType}
                                />,
                            // </View>

                            // <View style={Style.doctorNotesSectionView}>
                            <HistoryFilledBy information={this.state.information}
                                    reliability={this.state.reliability}
                                    historyFilledBy={this.state.historyFilledBy}
                                    onChangeValue={this.onChangeValueHistoryDetails.bind(this)}
                                    onPressSaveButton={this.onPressHistorySaveButton.bind(this)}/>
                            // </View>
    ]
    return datas 
}

    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            prenatalFactors: <PrenatalFactorsNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                // selectedRightSideView={this.selectedRightSideView.bind(this)}
            />,
            perinatalFactors: <PerinatalFactorsNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}
            />,
            natureOfDelivery: <NatureOfDeliveryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}
            />,
            devlopmentalAssessment: <DevlopmentalAssessmentNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                getdenvarAge={this.getdenvarAge.bind(this)}
                personalSocial={this.state.personalSocial}
                gross_Motor={this.state.gross_Motor}
                language={this.state.language}
                fineMotor={this.state.fineMotor}
                denvarvalues={this.denvarvalues.bind(this)}


            />,
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }

    denvarvalues(key, value) {
       
        var states = this.state
        states[key] = value
        this.setState({
            states
        })

    }

    render() {
       var parentComponents=this.listOfLeftSideComponent()
        return (
            <View style={styles.container}>
                <View style={styles.leftSideView}>

                    {this.renderTopNavigation()}
                    <ScrollView
                        ref={(ref)=>{
                            this.state.setScrollRef=ref
                        }}

                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 10, height: screenHeight - 130 }}
                        // contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        <View style={styles.leftSideBody}>
                             {
                                 parentComponents && parentComponents.length>0?
                                 parentComponents.map((item,index)=>{
                                     return this.renderParentComponent(item,index);
                                 })
                                 :null
                             }

                            {/* <View style={Style.doctorNotesSectionView}>
                                <PrenatalFactors selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>
                            <View style={Style.doctorNotesSectionView}>
                                <PerinatalFactors selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                <NatureOfDelivery selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                <DevlopmentalAssesment selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    SelectedAge={this.state.SelectedAge}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                    getdenvarAge={this.getdenvarAge.bind(this)}
                                    denvarvalues={this.denvarvalues.bind(this)}
                                    userType={this.state.userType}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                            <HistoryFilledBy information={this.state.information}
                                    reliability={this.state.reliability}
                                    historyFilledBy={this.state.historyFilledBy}
                                    onChangeValue={this.onChangeValueHistoryDetails.bind(this)}
                                    onPressSaveButton={this.onPressHistorySaveButton.bind(this)}/>
                            </View> */}
                        </View>
                    </ScrollView>
                </View>

                <ScrollView showsVerticalScrollIndicator={false} style={styles.rightSideScrollView} >

                    {this.renderComponent()}
                </ScrollView>

            </View>
        )
    }


    getHistoryFilledByDetails(){

        var service_url = Constants.PSY_MENTAL_HISTORY_FILLED_BY+"?patient_id="+this.state.patientAppointment.patient_id+"&appointment_id="+this.state.patientAppointment.appointment_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHistoryFilledBySuccess,
            this.getHistoryFilledByFailure
        );
    }
    getHistoryFilledBySuccess=success=>{
        if(success.status == "success"){
            if(Object.keys(success.data).length > 0){
                var states = this.state;
                states["historyFilledBYID"]=success.data.id ? success.data.id :"";
                states["historyFilledBy"]=success.data.history_filled_by ? success.data.history_filled_by :"";
                states["information"]=success.data.informant ? success.data.informant :"";
                states["reliability"]=success.data.reliability ? success.data.reliability :"";
                this.setState({ states })
            }   
        }
    }
    getHistoryFilledByFailure=error=>{
        
    }

    onChangeValueHistoryDetails(key,value){
        var states = this.state;
        states[key] =value;
        this.setState({
            states
        })
    }

    onPressHistorySaveButton(){

        var service_url = Constants.PSY_MENTAL_HISTORY_FILLED_BY;
        var data ={
            "appointment_id":this.state.patientAppointment.appointment_id,
            "patient_id": this.state.patientAppointment.patient_id,
            "history_filled_by": this.state.historyFilledBy,
            "informant": this.state.information,
            "reliability": this.state.reliability,
         }

         if(this.state.historyFilledBYID){
             data["id"]=this.state.historyFilledBYID
         }

            
         if(!this.state.historyFilledBy && !this.state.information && !this.state.reliability){    
      
            this.props.showResposeValue("error",Messages.HistoryFieldFilledBy)
          } else { 
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveHistoryFilledBySuccess,
            this.saveHistoryFilledByFailure
        );
        // alert(JSON.stringify(data))
      }
    }
    saveHistoryFilledBySuccess=success=>{
        if(success.status == "success"){
            this.showResposeValue("success",success.message)
        }else{
            this.showResposeValue("error",success.message)
        }
    }
    saveHistoryFilledByFailure=error=>{
        this.showResposeValue("error",error.message)

    }
}


const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    // leftSideScrollView: {
    //     width: "100%", height: screenHeight - 130, backgroundColor: color.white, zIndex: -1
    // },
    leftSideBody: {
        marginHorizontal: 5, marginVertical: 10,
        //  backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})