import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Styles from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";

import {CommonDropDownPicker} from '../BaseComponent'

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const platform = Platform.OS;
const pageTitle = "Medication";
const pageName = "medication";
const isPageName = "isMedication";

export default class Medication extends BaseComponentStyle {
  constructor(props) {
    super(props);

    let { patientAppointment } = this.props;

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      prescriptionIsTransmitted:false,

      selectedClinicOrDoctor:this.props.selectedClinicOrDoctor,
      deletedPrescriptionID:[],
      getResponcePrescriptionList:[],
      prescriptionId:"",
      patientAppointment: patientAppointment,
      complaintsData: {
        "prescription_id": null,
        "appointment_id": patientAppointment.appointment_id,
        "patient_id": patientAppointment.patient_id,
        "followup_date": "",
        "deleted_line_ids": [],
        prescription_lines: []
      },
      isComplaint: isPageName === this.props.selectedView?true:false,
      prescriptionItem: {},
      // selectedPharmacy:"",
      pharmacyList:[],
      languageList:['English', 'Tamil', 'Hindi'],
      isLocked:this.props.patientAppointment.is_locked,

      tempId:0,
      // for template
      isTemplate: false,
      template_name: "",
      selectedMedicationTemplate: null,
      medicationTemplateList: [],
      medicationTemplateData: null,

      backUpMedicationPrintList:[],

      // 
      // prepareCounterArrayList: [],
      selectedPharmacy: {},
      selectedLanguage:'English',
      // selectedDepartmentName: "",
      showPickerDropDownFlag:false,
      pharmacy_id: "",
      favSelectedMediDurg: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getMedication();
      this.getPharmacyList();
      this.getMedicationTemplateList();   
    }, 4000);

  }

  getPharmacyList = () => {

    var service_url = Constants.GET_PHARMACY_LIST+"?clinic_id="+this.state.selectedClinicOrDoctor.id+"&suggest=true"
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getPharmacyListSuccess,
        this.getPharmacyListFailure
      );  
    }, 4000);

  };
  getPharmacyListSuccess=success=>{
    // alert(JSON.stringify(success))
    // console.log("pharmacyList -----"+JSON.stringify(success))

    if(success.status == "success"){
      // console.log("pharmacyList -----"+JSON.stringify(pharmacyList))
      var states = this.state;


    //   {
    //     "pharmacy_id": 1,
    //     "is_default": true,
    //     "pharmacy_name": "Shalini Medicals",
    //     "pharmacy_type": "Inhouse Pharmacy"
    // }

    var pharmacyList = success.data

      if(pharmacyList.length == 1){

        var prepareSelectedData = {
          id:pharmacyList[0].pharmacy_id,
          is_default:pharmacyList[0].is_default,
          name:pharmacyList[0].pharmacy_name,
          pharmacy_type:pharmacyList[0].pharmacy_type
        }

        states["selectedPharmacy"] = prepareSelectedData
      }



      

      var data = []

      if(pharmacyList.length > 0){
        for (const pharmacy of pharmacyList) {
          var prepareData = {
            id:pharmacy.pharmacy_id,
            is_default:pharmacy.is_default,
            name:pharmacy.pharmacy_name,
            pharmacy_type:pharmacy.pharmacy_type
          }
          data.push(prepareData)
        }
      }


      states["pharmacyList"] = data
      this.setState({
       states
      })
    }
  }
  getPharmacyListFailure=error=>{}

  getMedicationTemplateList= () => {

    var service_url = Constants.OPT_MEDICATION_TEMPLATE;
   setTimeout(() => {
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getMedicationTemplateListSuccess,
      this.getMedicationTemplateListFailure
    );
   }, 4000);

  };
  getMedicationTemplateListSuccess=success=>{
    
    if(success.status == "success"){
  
      this.setState({
         medicationTemplateList: success.data
      })
    }
  }

  getMedicationTemplateListFailure=error=>{}

  onChangeMedicationTemplate=(template_id)=>{

    var service_url = Constants.OPT_MEDICATION_TEMPLATE+"?template_id="+template_id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.onChangeMedicationTemplateSuccess,
      this.getMedicationTemplateListFailure
    );
  }

  onChangeMedicationTemplateSuccess=success=>{
    
    if(success.status == "success"){
  
      let { complaintsData } = this.state;
      let prescription_array = [];
      let prescription_lines = [];
      let tempId = 0;

      prescription_lines = success.data;
      
      // add drug toarray in required format
      for(let i=0; i<prescription_lines.length; i++){

        tempId=tempId+1;

        let tempData = {};
        // tempData={
        //   "brand_name": prescription_lines[i].drug_name,
        //   "generic_name": prescription_lines[i].generic_name,
        //   "dosage_strength": prescription_lines[i].dosage_strength,
        //   "dosage_type": prescription_lines[i].dosage_type,
        //   "tempId": tempId,
        // }

        tempData = prescription_lines[i]
        tempData["tempId"]= tempId,
        tempData["id"]= "",
        tempData["brand_name"]= prescription_lines[i].drug_name,

        tempData["morning"]=prescription_lines[i].morning ? prescription_lines[i].morning  : 0;
        tempData["afternoon"]=prescription_lines[i].afternoon ? prescription_lines[i].afternoon : 0;
        tempData["evening"]=prescription_lines[i].evening ? prescription_lines[i].evening : 0;
        tempData["night"]=prescription_lines[i].night ?  prescription_lines[i].night: 0;

        tempData["instructions"]= "",
        tempData["comments"]= "",

        tempData["no_of_drops"]= prescription_lines[i].dosage_type !== "DROPS" ? 0 :
          prescription_lines[i].no_of_drops ? prescription_lines[i].no_of_drops : 1,
        tempData["tapper_meds"] = JSON.parse((prescription_lines[i].tapper_meds)).length > 0 ? JSON.parse(prescription_lines[i].tapper_meds) : [],
        tempData["is_favorite"]= prescription_lines[i].is_favorite,
        prescription_array.push(tempData);
        
 
      }
      complaintsData['prescription_lines'] = prescription_array;
      this.setState({
        complaintsData,
        tempId:tempId,
        pharmacy_id: response.data.pharmacy_id
      })
    }
  }

  componentWillReceiveProps(props) {


    if((props.showPickerDropDownFlag !== this.state.showPickerDropDownFlag )){
      this.setState({
        showPickerDropDownFlag:false
      })
    }

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  
  

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  
  

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getMedication();

      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }

    if (this.props.favSelectedMediDurg?.length > 0 && this.props.favSelectedMediDurg != this.state.favSelectedMediDurg){
      var {complaintsData, tempId} = this.state
      var prescLen = complaintsData["prescription_lines"]?.length
      if(prescLen > 0){
        for(let i = 0; i < this.props.favSelectedMediDurg.length; i++){
          complaintsData["prescription_lines"].push((this.props.favSelectedMediDurg)[i])
          complaintsData["prescription_lines"][i + (prescLen)]['tempId'] = i + (prescLen) + 1
          var newTempId = i + (prescLen) + 1
        }
      }else {
        for(let i = 0; i < this.props.favSelectedMediDurg.length; i++){
          complaintsData["prescription_lines"].push((this.props.favSelectedMediDurg)[i])
          complaintsData["prescription_lines"][i]['tempId'] = i + 1
          var newTempId = i + 1
        }
      }
      this.setState({
        complaintsData: JSON.parse(JSON.stringify(complaintsData)),
        tempId: newTempId,
        favSelectedMediDurg: this.props.favSelectedMediDurg
      })}
    // this.isSectionHightlighted(isPageName, props.selectedView, this.state.isComplaint, 'isComplaint');

    if(props.prescriptionItem && !this.state.prescriptionIsTransmitted) {
      let {complaintsData} = this.state;
      // console.log(" ************> props +" +JSON.stringify(props.prescriptionItem))
      // console.log(" ************> state +" +JSON.stringify(complaintsData.prescription_lines))
      if(props.prescriptionItem.tempId  && complaintsData.prescription_lines.length > 0 ){
        for (let i = 0; i < complaintsData.prescription_lines.length; i++) {
          if( props.prescriptionItem.tempId === complaintsData.prescription_lines[i].tempId){
            complaintsData["prescription_lines"].splice(i, 1);
            complaintsData["prescription_lines"].push(props.prescriptionItem);

            this.setState({ complaintsData: JSON.parse(JSON.stringify(complaintsData)) });
            this.props.clearItem("");
            return
          }
          else  if( props.prescriptionItem.tempId == ""){
            var data=props.prescriptionItem
            let temp = this.state.tempId 
            data["tempId"]= temp + 1;

            complaintsData["prescription_lines"].push(data);
            this.setState({ complaintsData: JSON.parse(JSON.stringify(complaintsData)) });  
            this.props.clearItem("");
            return
          }
          // else{


            // var data=props.prescriptionItem

            // data["tempId"]=this.state.tempId+1;

            // complaintsData["prescription_lines"].push(data);
            // this.setState({ complaintsData: JSON.parse(JSON.stringify(complaintsData)) });  
            // this.props.clearItem("");
            // return
          // }
        }
      } else{


        var data=props.prescriptionItem

        // data["tempId"]=this.state.tempId+1; // Previous fix
         data["tempId"]= ((complaintsData.prescription_lines.length) + 1);

        complaintsData["prescription_lines"].push(data);

        // complaintsData["prescription_lines"].push(props.prescriptionItem);
        this.setState({ complaintsData: JSON.parse(JSON.stringify(complaintsData)) });  
        this.props.clearItem("");
      }



    }

    if(props && props.getTransmitDataFlag) {
      var data = {
        "appointment_id": this.state.patientAppointment.appointment_id,
        "patient_id": this.state.patientAppointment.patient_id,
        "prescription_id": this.state.prescriptionId,
        "pharmacy_id":parseInt(this.state.selectedPharmacy.id)
        }
      this.props.getTransmitDatas(data,false)
    }

  }

  getMedication = () => {

    var service_url = Constants.OPTHALMOLOGY_PRESCRIPTION 
    + "?patient_id=" + this.state.patientAppointment.patient_id 
    + "&appointment_id=" + this.state.patientAppointment.appointment_id 
   // + "&prescription_id=" + "";
setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getMedicationSuccess,
    this.getMedicationFailure
  );
}, 4000);

  };

  getMedicationSuccess = response => {
    if (response.status === "success") {
      // console.log(" Prescription data - " + JSON.stringify(response.data));

      var {patientAppointment} = this.state;
      
      var complaintsData = response.data;

      
      // add again on over wright data
      complaintsData['appointment_id'] = patientAppointment.appointment_id;
      complaintsData['patient_id'] = patientAppointment.patient_id;
      // complaintsData['prescription_lines'] = patientAppointment.patient_id;

      var getListofCompleints=[]

      for (let i = 0; i < complaintsData.prescription_lines.length; i++) {

        var data=complaintsData.prescription_lines[i]

        data["tempId"]=complaintsData.prescription_lines[i].id;
        data["tapper_meds"] = JSON.parse(complaintsData.prescription_lines[i].tapper_meds)
        getListofCompleints.push(data)
        
      }
      complaintsData['prescription_lines'] = getListofCompleints;

      this.setState({ 
        prescriptionIsTransmitted : complaintsData.is_transmitted,
        complaintsData: JSON.parse(JSON.stringify(complaintsData)) ,
        prescriptionId:response.data.prescription_id,

        getResponcePrescriptionList:[...response.data.prescription_lines],
        backUpMedicationPrintList :[...response.data.prescription_lines],
        // pharmacy_id: response.data.pharmacy_id
      },()=>{
      });
    }
  };

  getMedicationFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  saveMedication = () => {

    let { complaintsData ,backUpMedicationPrintList} = this.state;
    let service_url = Constants.OPTHAMOLOGY_MEDICATION_PRESCRIPTION_POST;
    // console.log("complaintsData.prescription_lines -----------------> "+JSON.stringify(complaintsData.prescription_lines))
    // for (let i = 0; i < complaintsData.prescription_lines.length; i++) {
    //   var data = {

    //   }
      
    // }
    var prescription_line = complaintsData.prescription_lines
    
    for (let i = 0; i < prescription_line.length; i++) {
      var pharmacy_id = prescription_line[i].pharmacy_id
    }


    var data = {
    // "prescription_id": 10,
    // "appointment_id": this.state.patientAppointment.appointment_id,
    // "patient_id": this.state.patientAppointment.patient_id ,
    "appointment_id": complaintsData.appointment_id,
    "patient_id": complaintsData.patient_id,
    "followup_date": "",
    // "deleted_line_ids": [],
    "line_items": complaintsData.prescription_lines,
    "pharmacy_id": pharmacy_id
    }

    if(this.state.prescriptionId){
      data["prescription_id"]=this.state.prescriptionId
    }


    var deletedPrescriptionID = []


    function comparer(otherArray){
      return function(current){
        return otherArray.filter(function(other){
          return other.id == current.id
        }).length == 0;
      }
    }

    var getDeletedPrescriptionList = backUpMedicationPrintList.filter(comparer(complaintsData.prescription_lines));
    // var onlyInb = complaintsData.prescription_lines.filter(comparer(backUpMedicationPrintList));

    if(getDeletedPrescriptionList.length > 0 ){
      for (const item of getDeletedPrescriptionList) {
          if(item.id){
            deletedPrescriptionID.push(item.id);
          }
      }
    }else{
      deletedPrescriptionID = []
    }

    if(deletedPrescriptionID.length > 0){
      data["deleted_line_ids"]=deletedPrescriptionID
    }else{
      data["deleted_line_ids"]=[]
    }




    // console.log(" saveMedication ");
    // console.log(JSON.stringify(complaintsData))

    
    // if (data['line_items'].length>0) {

      // complaintsData['line_items'] = complaintsData['prescription_lines'];
      
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveMedicationSuccess,
        this.saveMedicationFailure
      );
    // }
  }

  saveMedicationSuccess = success =>{
    // console.log("success----> "+JSON.stringify(success))
    if(success.status === "success"){

      this.setState({
        selectedMedicationTemplate: 0
      },()=>{
        this.props.showResposeValue("success",success.message)
        this.getMedication();

      })
    }else{
      this.props.showResposeValue("error",success.message)
    }
  }
  saveMedicationFailure = Error =>{
    console.log("Error----> "+JSON.stringify(Error))
    this.props.showResposeValue("error",Error.message)
  }

  saveMedicationTemplate=()=>{

    let errorMessage = "";
    let { template_name, complaintsData } = this.state;
    let prescription_lines = complaintsData?(complaintsData.prescription_lines?complaintsData.prescription_lines:null):null;
    var service_url = Constants.OPT_MEDICATION_TEMPLATE;

    if(!template_name) {
      errorMessage = "Enter template name";
    }
    if(!prescription_lines || prescription_lines.length === 0) {
      errorMessage = errorMessage?errorMessage+",Add drug":"Add drug";
    }

    if(errorMessage){
      this.props.showResposeValue("error", errorMessage)
    } else {

      // alert(JSON.stringify(prescription_lines))
      let prescription_array = [];
      // add drug toarray in required format
      for(let i=0; i<prescription_lines.length; i++){

        let tempData = {};
        tempData={
          "drug_name": prescription_lines[i].brand_name,
          "generic_name": prescription_lines[i].generic_name,
          "dosage_strength": prescription_lines[i].dosage_strength,
          "dosage_type": prescription_lines[i].dosage_type,

            "duration": prescription_lines[i].duration,
            "eye_type":prescription_lines[i].eye_type,
            "frequency":prescription_lines[i].frequency,
            "morning":prescription_lines[i].morning,
            "afternoon":prescription_lines[i].afternoon,
            "night":prescription_lines[i].night,
            "evening":prescription_lines[i].evening,
            "no_of_drops": prescription_lines[i].dosage_type !== "DROPS" ? 0 :
              prescription_lines[i].no_of_drops ? prescription_lines[i].no_of_drops : 1,
            "tapper_meds": (prescription_lines[i].tapper_meds).length > 0 ? prescription_lines[i].tapper_meds : [],
        }
        prescription_array.push(tempData);
        var pharmacy_id = prescription_lines[i].pharmacy_id ? prescription_lines[i].pharmacy_id : this.state.pharmacy_id
      }

      let data = {
        template_name: template_name,
        prescription: prescription_array,
        pharmacy_id: pharmacy_id
      };

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSaveTemplateSuccess,
        this.getSaveTemplateFailure,
      );
    }
  }

  getSaveTemplateSuccess = response => {
    if (response.status === "success") {
      
      this.props.showResposeValue("success", response.message)
      this.setState({ 
        isTemplate: false,
        template_name: ""
      }, ()=>{
        this.getMedicationTemplateList()
      })      
    }else{
      this.props.showResposeValue("error", response.message)
    }
  };

  getSaveTemplateFailure = error => {
    // console.log("OPT medication template error on save");
    console.log(error);
  };

  editItem = (item, event) => {
    // console.log("edit " + pageName);
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }
  
  deleteItem = (index,item) => {

    // if(this.state.complaintsData.prescription_lines > 1){
      this.props.triggerNewItem(isPageName);

    if(item.id){
      this.state.deletedPrescriptionID.push(item.id)
    }

    // console.log("index--------------------> "+JSON.stringify(item))
    
    var {complaintsData}=this.state;
    complaintsData["prescription_lines"].splice(index, 1);
    this.setState({complaintsData})

  // }

  }

  renderComplaintItem = (item, index) => {
    // console.log(" this.state.complaintsData " + JSON.stringify(item))
    return (
      <View>
        <View style={{ paddingTop: '0.3vw' }}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              // height: 50,
              alignItems: "center"
              // paddingBottom: 10
            }}
          >
            <View
              style={{
                width: "10%", display: 'flex', flexDirection: 'row'
              }}
            >
              <TouchableOpacity onPress={(e)=>{
                    var {complaintsData} = this.state
                    complaintsData.prescription_lines[index].is_favorite = !complaintsData.prescription_lines[index].is_favorite
                    this.setState({complaintsData})
                }}>
              <img style={{width: '1.3vw', height: '1.3vw'}} src={this.state.complaintsData.prescription_lines[index].is_favorite ? require("../../../../assets/images/StarFill.svg") : require("../../../../assets/images/StarUnfill.svg")} alt={"star"}/>
            </TouchableOpacity>
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                {item.eye_type}
              </Text>
            </View>
            <View
              style={{
                width: "15%",
                paddingHorizontal: '0.6vw'
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
              {item.brand_name} 
              </Text>
              <Text style={{ fontSize: '0.8vw', color: "#2F2F2F" }}>
                {item.dosage_strength} {item.dosage_type}
              </Text>
            </View>
            { item.tapper_meds && (item.tapper_meds).length > 0 ? 
            <View style={{width: "25%", flex: 1}}>
            {(item.tapper_meds).map((item, index) => (
              <View style={{flexDirection: "row", flex: 1, marginBottom: '0.6vw'}}>
                <View style={{flex: 0.5}}>
                <Text style={{fontSize: '0.9vw'}}>{item.start_date + "   -"}</Text>
                  <Text style={{fontSize: '0.9vw'}}>{item.duration + "-" + item.no_of_dosage}</Text>
                </View>
                <View style={{flex: 0.5}}>
                <Text style={{fontSize: '0.9vw'}}>{item.end_date}</Text>
                <Text style={{fontSize: '0.9vw'}}>{item.frequency}</Text>
                </View>
              </View> 
            ))}
            </View>
            :
            <View
              style={{
                width: "25%"
                // alignItems: "center"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
              {item.duration} 
              </Text>
              
              {
              item.frequency == "M-A-E-N" ?
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                {item.morning? item.morning : 0 }{"-"}{item.afternoon? item.afternoon : 0}{"-"}{item.evening? item.evening : 0}{"-"}{item.night? item.night : 0}
                </Text> :
                <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}> {item.frequency} </Text>
              }

              
            </View>
            }
            <View
              style={{
                width: "10%",
                alignItems: "center"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                {item.quantity}
              </Text>
            </View>
            <View
              style={{
                width: "25%"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                {item.comments}
              </Text>
            </View>
            <View
              style={{
                width: "15%",
                alignItems: "center",
                height: '4.1vw',
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: '0.3vw',
                  paddingBottom: '0.3vw',
                  backgroundColor: color.white,
                  height: '3.3vw'
                }}
              >
                {
                  !this.state.prescriptionIsTransmitted ?
                    <TouchableOpacity disabled={this.state.isLocked} onPress={this.editItem.bind(this, item)}>
                      {this.renderIdButton("pencil")}
                    </TouchableOpacity> : null

                }
                {/* <TouchableOpacity onPress={this.editItem.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity> */}
                {
                  !this.state.prescriptionIsTransmitted ?
                    <TouchableOpacity disabled={this.state.isLocked} onPress={() => this.deleteItem(index, item)}>
                      {this.renderIdButton("trash")}
                    </TouchableOpacity> : null
                }
                {/* <TouchableOpacity onPress={()=>this.deleteItem(index,item)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity> */}
              </View>
            </View>
          </View>
        </View>
        <View style={{ height: '0.03vw', backgroundColor: "#E0E0E0" }} />
      </View>
    );
  };

  triggerNewItem= (key) => {

    this.setState({ isComplaint: true });
    
    this.props.triggerNewItem(key);    
    // this.state.prescriptionIsTransmitted
    this.props.prescriptionIsTransmitted(this.state.prescriptionIsTransmitted)
    // this.doSectionHightlighted(isPageName, this.state.isComplaint, key);
  }


  transmitPrescription = () => {

    let { complaintsData } = this.state;
    let service_url = Constants.MEDICATION_PRESCRIPTION_TRANSMIT;


    var data = {
    "appointment_id": complaintsData.appointment_id,
    "patient_id": complaintsData.patient_id,
    "prescription_id": this.state.complaintsData.prescription_id,
    "pharmacy_id":parseInt(this.state.selectedPharmacy.id)
    }
   
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.transmitPrescriptionSuccess,
        this.transmitPrescriptionFailure
      );
  }

  transmitPrescriptionSuccess= success =>{
    if(success.status=="success"){
      this.getMedication()
      this.props.showResposeValue("success",success.message)
    }else{
      this.props.showResposeValue("error",success.message)
    }
  }
  transmitPrescriptionFailure= error =>{
    this.props.showResposeValue("error",error.message)
  }


  render() {
    // console.log(" render complaintsData --- " + JSON.stringify(this.state.complaintsData))
    // console.log(this.state.complaintsData, "prescription_lines")
    return (
        <View>
        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
        <TouchableOpacity style={{display: 'flex', alignItems: 'center', marginRight: '1vw'}} onPress={()=>{this.props.favouriteMediPopup(true)}}>
              <img style={{width: '1.3vw', height: '1.3vw'}} src={require("../../../../assets/images/StarUnfill.svg")} alt={"star"}/>
              <Text style={{fontSize: '0.9vw'}}>Favourites</Text>
            </TouchableOpacity>
        </View>
        
        <View style={{flexDirection:'row', justifyContent: "flex-start", alignContent: "center", marginTop: '1vw'}}>
          <View style={{ flex:0.3, flexDirection:'row',justifyContent:'flex-start', alignContent:'center'}}>
            <Picker
              selectedValue={""}
              disabled={this.state.isLocked}
              style={[styles.DropDownPicker,Styles.allButtonBorderRadius]}
              itemStyle={{ fontSize: '0.8vw' }}
              onValueChange={(itemvalue) => {
                // this.setState({ selectedMedicationTemplate: itemvalue },()=>{
                  this.onChangeMedicationTemplate(itemvalue);
                // });
              }}
              enabled={!this.state.isLocked}
            >
              <Picker.Item key={-1} label={"Select template"} value={0} />
              {this.state.medicationTemplateList.map((i, index) => (
                <Picker.Item key={index} label={i.template_name} value={i.id} />
              ))}
            </Picker>


          </View>
        {
          (this.state.getResponcePrescriptionList.length > 0 || this.state.complaintsData.prescription_lines.length > 0 ) ?
          <View style={{ flex:0.7, flexDirection:'row',justifyContent:'flex-end',alignContent:'flex-end'}}>
          

          {/* <Picker
            disabled={this.state.isLocked}
            style={[{  height: 30,marginRight:15 ,paddingLeft: 10, backgroundColor: "transparent" },Styles.allButtonBorderRadius]}
            itemStyle={{ fontSize: 12 }}
            onValueChange={(itemvalue) => {
              this.setState({ selectedPharmacy: itemvalue });
            }}
            enabled={!this.state.isLocked}
          >
            <Picker.Item key={-1} label={"pharmacy"} value={0} />
            {this.state.pharmacyList.map((i, index) => (
              <Picker.Item key={index} label={i.pharmacy_name} value={i.pharmacy_id} />
            ))}
          </Picker> */}
              <TouchableOpacity disabled={this.state.isLocked}
                onPress={() => {
                  this.saveMedication()
                }}
                style={[styles.SaveBtnTouch, {backgroundColor: color.themeDark}, Styles.allButtonBorderRadius]}>
                <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Save"}</Text>
              </TouchableOpacity>
            <View style={{marginRight:'1vw',justifyContent:"flex-end"}}>
                <CommonDropDownPicker
                showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                selectedValue={this.state.selectedPharmacy}
                arrayOfData={this.state.pharmacyList}
                // pickerKey="FO"
                />
                </View>

            <Picker
              selectedValue={this.state.selectedLanguage}
              disabled={false}
              style={[styles.CommonDropDownView,Styles.allButtonBorderRadius]}
              itemStyle={{ fontSize: '0.8vw' }}
              onValueChange={(itemvalue) => {

                this.setState({
                  selectedLanguage: itemvalue
                })
              }}
              enabled={true}
            >
              
              {this.state.languageList.map((i, index) => (
                <Picker.Item key={index} label={i} value={i} />
              ))}
            </Picker>

          {/* <TouchableOpacity   disabled={this.state.isLocked} onPress={() => {
            this.transmitPrescription()

          }} disabled={this.state.isLocked} style={{alignContent:'flex-end',alignSelf:"flex-end",justifyContent:'flex-end',alignItems:'flex-end',marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
            <Text style={{ color: color.white }}>{"Transmit"}</Text>
          </TouchableOpacity>

          <TouchableOpacity   disabled={this.state.isLocked} onPress={() => {
            this.saveMedication()
          }} disabled={this.state.isLocked} style={{alignContent:'flex-end',alignSelf:"flex-end",justifyContent:'flex-end',alignItems:'flex-end',marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
            <Text style={{ color: color.white }}>{"Save"}</Text>
          </TouchableOpacity> */}

<TouchableOpacity disabled={this.state.isLocked}
                onPress={() => {
                  this.transmitPrescription()
                }}
                style={[styles.SaveBtnTouch, { backgroundColor: color.themeDark}, Styles.allButtonBorderRadius]}>
                <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Transmit"}</Text>
              </TouchableOpacity>

              


                {this.renderMedicationPrint()}

          
          </View>
          : <View/>
        }
        </View>
        {/* <TouchableOpacity onPress={() => {
          this.saveMedication()
        }} style={{alignContent:'flex-end',alignSelf:"flex-end" ,marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
          <Text style={{ color: color.white }}>{"Save"}</Text>
        </TouchableOpacity> */}

        {
          this.state.complaintsData.prescription_lines.length > 0 ? 
          
        <View style={{ marginTop: '1vw',zIndex:-1 }}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <View
              style={{
                width: "10%"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Eye
              </Text>
            </View>
            <View
              style={{
                width: "15%"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Drug Name
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                // alignItems: "center"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Frequency
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                alignItems: "center"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Quantity
              </Text>
            </View>
            <View
              style={{
                width: "25%"
              }}
            >
              <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Comments
              </Text>
            </View>
            <View
              style={{
                width: "15%",
                alignItems: "center",
                // height: 0,
                flexDirection: "row"
              }}
            >
             <Text style={{ fontSize: '0.9vw', color: "#2F2F2F" }}>
                Action
              </Text>
            </View>
            </View>
        </View>
        : null
      }

      {
        this.state.complaintsData.prescription_lines.length > 0 ?
        // <FlatList
        //   data={this.state.complaintsData.prescription_lines}
        //   showsHorizontalScrollIndicator={false}
        //   showsVerticalScrollIndicator={false}
        //   scrollEnabled={false}
        //   renderItem={({ item, index }) =>
        //     this.renderComplaintItem(item, index)
        //   }
        // />
        this.state.complaintsData.prescription_lines.map((item, index)=>{
          return this.renderComplaintItem(item, index)
        })
        :null
      }

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {this.saveMedication.bind(this)}>
          {this.renderIconBtn("plus", "Save", false)}
          </TouchableOpacity>
        </View> */}

      {(this.state.getResponcePrescriptionList.length > 0 || this.state.complaintsData.prescription_lines.length > 0 ) && !this.state.isTemplate?
        <View style={{flexDirection:'row', justifyContent: "flex-end", alignContent: "center", marginTop: '1vw'}}>
          <TouchableOpacity disabled={this.state.isLocked}
            onPress={() => {
              this.setState({ isTemplate: true })
            }}
            style={[styles.AddtoTemplateTouch, {backgroundColor: color.themeDark}, Styles.allButtonBorderRadius]}>
            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Add to template"}</Text>
          </TouchableOpacity>
          </View>
          :null}

        {(this.state.getResponcePrescriptionList.length > 0 || this.state.complaintsData.prescription_lines.length > 0 ) && this.state.isTemplate ?
        <View style={{flexDirection:'row', justifyContent: "flex-end", alignContent: "center", marginTop: '1vw'}}>
          <TextInput
            placeholder="Template Name"
            style={[styles.TemplateNameTextInput, {borderColor: color.rightPanelInputBorder}]}
            onChangeText={text => this.setState({ template_name: text })}
            value={this.state.template_name}
          />
          <TouchableOpacity disabled={this.state.isLocked}
            onPress={() => {
              this.saveMedicationTemplate()
            }}
            style={[styles.SaveBtnTouch1, {backgroundColor: color.themeDark}, Styles.allButtonBorderRadius]}>
            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Save"}</Text>
          </TouchableOpacity>
          <TouchableOpacity disabled={this.state.isLocked}
            onPress={() => {
              this.setState({ isTemplate: false })
            }}
            style={[{backgroundColor: color.themeDark,paddingHorizontal:'1.1vw',paddingVertical:'0.5vw'
            }, Styles.allButtonBorderRadius]}>
            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Cancel"}</Text>
          </TouchableOpacity>
        </View>
        :null}
      </View>
    );
  }

  renderMedicationPrint(){
    return (
      <TouchableOpacity
        style={{
          marginHorizontal:'0.6vw',
          alignItems: "center",
          backgroundColor: color.themeDark,
          borderRadius: 4,
        }}
        disabled={this.state.isLocked}
        onPress={() => {
           this.medicationPrint()
        }}
      >
        <View
          style={styles.PrintIconView}
        >
          <Icon
            name={"print"}
            size={Platform === "web" ? '1.5vw' : '1.2vw'}
            color={color.white}
          />
        </View>
      </TouchableOpacity>
    );
  }

  
  changePickerDropDownValue(flag, selectedValue){
    this.setState({
      showPickerDropDownFlag:flag,
      selectedPharmacy:Object.keys(selectedValue).length > 0 ? selectedValue :this.state.selectedPharmacy
      // selectedDepartment:Object.keys(selectedValue).length > 0 ? selectedValue :this.state.selectedDepartment,
      // selectedSlugName: selectedValue && selectedValue.id ?  this.props.clintID.toString() + selectedValue.id.toString() : this.state.selectedSlugName,

  },()=>{
    if(flag){
    this.props.changePickerDropDownValueInDRNotes(true)
    }
  })
  }

  medicationPrint(){

    
    

    // alert(this.state.prescriptionId)
    // alert(JSON.stringify(this.state.complaintsData.prescription_id))

    let states = this.state;
    var lang = this.state.selectedLanguage.toLowerCase(); 

    var serviceUrl = Constants.OPT_MEDICATION_PRINT + "?appointment_id=" + this.state.patientAppointment.appointment_id+"&prescription_id="+this.state.prescriptionId+"&language="+lang;

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.medicationPrintSuccess,
      this.medicationPrintFailure,
      "pdf"
    );
  }



  medicationPrintSuccess = response => {
    if (response) {
      
      const file = new Blob([response.data], { type: 'application/pdf' });

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  medicationPrintFailure = error => {
    console.log("Get clinic list error response");
    console.log(error);
  };

}
const styles = StyleSheet.create({
      DropDownPicker: { 
        height: '2vw',
        marginRight:'1vw' ,
        alignSelf:'center', 
        paddingLeft: '0.6vw', 
        backgroundColor: "transparent",
        fontSize: '0.9vw',
        width:'13vw'
      },
      SaveBtnTouch: {
        alignContent:'flex-end',
        alignSelf:"flex-end",
        justifyContent:'flex-end',
        alignItems:'flex-end',
        marginRight: '1vw', 
        paddingHorizontal:'1.1vw',
        paddingVertical:'0.5vw'
      },
      CommonDropDownView: { 
        marginTop:'0.2vw', 
        height: '2vw',
        marginRight:'1vw',
        paddingLeft: '0.6vw', 
        backgroundColor: "transparent",
        fontSize: '0.9vw'
      },
      PrintIconView: { 
        height: '2.3vw', 
        width: '2.3vw', 
        alignItems: "center", 
        padding: '0.3vw' 
      },
      AddtoTemplateTouch: {
        alignContent:'flex-end',
        alignSelf:"flex-end",
        justifyContent:'flex-end',
        alignItems:'flex-end',
        marginRight: 0, 
        paddingHorizontal:'1.1vw',
        paddingVertical:'0.5vw'
      },
      TemplateNameTextInput: { 
        // height: '3.3vw', 
        borderWidth: 1, 
        borderRadius: 4, 
        paddingHorizontal: '0.3vw', 
        textAlign: 'justify', 
        marginRight: '1vw' ,
        fontSize: '0.9vw'
      },
      SaveBtnTouch1: {
        marginRight: '1vw', 
        paddingHorizontal:'1.1vw',
        paddingVertical:'0.5vw'
      }
})