import React, { Component } from "react";
import {
    View,
    Text,
    Dimensions,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    TextInput,
} from "react-native";
import { color } from "../../../../styles/Color";
import moment from "moment";
import { NavigationTopHeader } from "../../BaseComponent";
import "antd/dist/antd.css";
import { DatePicker, Tooltip } from "antd";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import AsyncStorage from "../../../../AsyncStorage"

const screenHeight = Dimensions.get("window").height;


const navigationHeaderList = [
    { label: "Billing Transaction", value: "ipallTransactions" },
];

const navigationHeaderListIfIPCreateBill = [
    { label: "Create Bill", value: "ipPatientCreateBill" },
    { label: "Pending Bill", value: "ipPatientSavedBill" },
    { label: "Billing Transaction", value: "ipallTransactions" },
    { label: "OT Bills", value: "otbills" },
    { label: "Summary Bill", value: "summaryBill" },
    { label: "Estimation Bill", value: "estimationBill" },
];

export default class SummaryBill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCurrentPageIsIPPatientAppointment:
                this.props.isCurrentPageIsIPPatientAppointment,
            patientProfileDetails: this.props.selectedIpPatientDetails,
        };
    }



    changeTabSection(data) {
        // do nothing
        this.props.changeScreen(data, {}, "", {}, "", "");
    }



    render() {
        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: "row",
                    backgroundColor: color.applicationBackgroundColor,
                }}
            >
                <View style={{ width: "100%", height: screenHeight - 65 }}>
                    <NavigationTopHeader
                        changeTabSection={this.changeTabSection.bind(this)}
                        navigationHeaderList={
                            this.props.isIpPatientCheckouted
                                ? navigationHeaderList
                                : this.state.isCurrentPageIsIPPatientAppointment
                                    ? navigationHeaderListIfIPCreateBill
                                    : navigationHeaderList
                        }
                        selectedTab={"summaryBill"}
                    />
                    <ScrollView
                        style={{ width: "100%" }}
                        bounces={false}
                        bouncesZoom={false}
                    >
                        <View style={styles.LeftMainView}>

                        </View>
                    </ScrollView>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    LeftMainView: {
        height: "78vh",
        margin: "0.2vw",
        marginTop: "1vw",
        marginLeft: "0.7vw",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2,
        borderRadius: "0.25vw",
        backgroundColor: "white",
    },
});


