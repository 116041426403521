import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet } from "react-native";
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../../assets/images/BillConfig/delete.svg'
import { Tooltip } from 'antd';
import { color } from "../../../../styles/Color";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import Pagination from '../../../doctor/common/Pagination';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Icon from "react-native-vector-icons/FontAwesome";


export default class CorpEnroll extends Component {

    constructor(props) {
        super(props);
        const DefaultValues = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).default_value : {};

        this.state = {
            corpCompanyList: [],
            isDeleteClick: false,
            isEditClicked: false,
            ErrorText: "",
            SelectedItem: {},
            SearchCompanyKey: "",
            editClickedId: "",
            CorpListAfterPagination: [],

            RegisterName: "",
            gstin: "",
            Address1: "",
            Address2: "",
            city: DefaultValues.default_city,
            pincode: "",
            state: DefaultValues.default_state,
            country: DefaultValues.default_country,
            RangeFrom: "",
            RangeTo: "",
            mailID: "",
            mobile: "",
            Discountpercent: "",
            DiscountNotes: "",
            CorporateSort: "asc",
        };
    }
    componentDidMount() {
        this.getCorporateList()
    }

    getCorporateList = () => {
        var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION + "?search_key=" + this.state.SearchCompanyKey + "&sort=" + this.state.CorporateSort;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getCorporateListSuccess,
            this.getCorporateListFailure
        );

    }

    getCorporateListSuccess = (success) => {
        this.setState({
            corpCompanyList: success.data.emp_details
        })
    }
    getCorporateListFailure = (error) => {
        this.props.ErrorAlt(error.message)
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }
    numberValidationWithDot(num) {
        var rgx = /^(\d{0,3})((\.(\d{0,2})?)?)$/;
        return num.match(rgx);
    }
    gstValidate(num) {
        var rgx = /^(([a-z\d]+\s)?[a-z\d]+)?$/i;
        return num.match(rgx);
    }
    OnlyTextWithSpace(text) {
        return /^[a-z A-Z.,_]+$/.test(text);
    }

    renderTextBox = (key) => {
        var states = this.state
        return (
            <View style={styles.renderTextBoxContainer}>
                <TextInput
                    multiline={(key == "DiscountNotes") ? true : false}
                    maxLength={(key == "gstin") ? 15 : (key == "mobile") ? 10 : (key == "pincode") ? 6 : null}
                    value={states[key]}
                    style={[styles.TextInputStyles, { height: (key == "DiscountNotes") ? "4vw" : '1.5vw', padding: (key == "DiscountNotes") ? "0" : "1vw", }]}
                    onChangeText={(text) => {
                        if ((key == "pincode") || (key == "RangeFrom") || (key == "RangeTo") || (key == "mobile")) {
                            var isValid = this.numberValidation(text)
                            if (isValid || text === "") {
                                states[key] = text
                                this.setState({ states })
                            }
                        }
                        else if ((key == "Discountpercent")) {
                            var isValid = this.numberValidationWithDot(text)
                            if ((isValid && parseFloat(text) <= 100) || text === "") {
                                states[key] = text
                                this.setState({ states })
                            }
                        }
                        else if ((key == "gstin")) {
                            var isValid = this.gstValidate(text)
                            if ((isValid || text === "")) {
                                states[key] = text.toUpperCase()
                                this.setState({ states })
                            }
                        }
                        else if ((key == "city")) {
                            var isValid = this.OnlyTextWithSpace(text)
                            if ((isValid || text === "")) {
                                states[key] = text
                                this.setState({ states })
                            }
                        }
                        else {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </View>
        )
    }

    AddNewCorporate = () => {
        let SameCorpName = []
        SameCorpName = this.state.corpCompanyList.filter(list => list['company_name'].toLowerCase() === this.state.RegisterName.toLowerCase());
        var states = this.state
        if (
            states.RegisterName != "" &&
            states.gstin != "" &&
            states.Address1 != "" &&
            states.city != "" &&
            states.pincode != "" &&
            states.state != "" &&
            states.country != "" &&
            states.mailID != "" &&
            states.mobile != "" &&
            states.Discountpercent != "" &&
            (SameCorpName.length === 0 || (SameCorpName.length == 1 && SameCorpName[0].company_name == this.state.RegisterName))
        ) {
            if (states.gstin.length != 15) {
                this.setState({
                    ErrorText: "Invalid GST Number - (Must Be In 15 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (states.pincode.length != 6) {
                this.setState({
                    ErrorText: "Invalid Pincode - (Must Be In 6 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (states.mobile.length != 10) {
                this.setState({
                    ErrorText: "Invalid Mobile Number - (Must Be In 10 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (parseInt(states.Discountpercent) > 100) {
                this.setState({
                    ErrorText: "Invalid Discount Value - (Must Less Than 100)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeFrom != "") && (states.RangeTo == "")) {
                this.setState({
                    ErrorText: "Employee Range To Value is Empty"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeTo != "") && (states.RangeFrom == "")) {
                this.setState({
                    ErrorText: "Employee Range From Value is Empty"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (parseInt(states.RangeFrom) > parseInt(states.RangeTo)) {
                this.setState({
                    ErrorText: "Invalid Employee Range Values"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeFrom == "0") || (states.RangeTo == "0")) {
                this.setState({
                    ErrorText: "Invalid Employee Range Values"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(states.mailID))) {
                this.setState({
                    ErrorText: "Invalid Mail ID "
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (SameCorpName.length > 0) {
                this.setState({
                    ErrorText: "Corporate Name Already Exists"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else {
                this.setState({
                    ErrorText: ""
                })

                var service_url = Constants.CORPORATE_ADMIN_CONFIGURATION;
                var data = {
                    "company_name": states.RegisterName,
                    "gst_no": states.gstin,
                    "country": states.country,
                    "pincode": states.pincode,
                    "address_line_1": states.Address1,
                    "address_line_2": states.Address2,
                    "email": states.mailID,
                    "mobile": states.mobile,
                    "discount_percent": parseFloat(states.Discountpercent),
                    "discount_notes": states.DiscountNotes,
                    "employee_range_from": states.RangeFrom,
                    "employee_range_to": states.RangeTo,
                    "city_name": states.city,
                    "state_name": states.state,
                }

                OpthamologyService.getInstance().postComplaints(
                    service_url,
                    data,
                    this,
                    this.AddCorporateSuccess,
                    this.AddCorporateFailure
                );
            }

        }
        else if (SameCorpName.length > 0) {
            this.setState({
                ErrorText: "Corporate Name Already Exists"
            })
            setTimeout(()=>this.setState({ErrorText:""}), 2500)
        }
        else {
            this.setState({
                ErrorText: "Enter All Mandatory * Fields"
            })
            setTimeout(()=>this.setState({ErrorText:""}), 2500)
        }

    }

    AddCorporateSuccess = (response) => {
        if (response.status == "success") {
            this.getCorporateList()
            this.resetClicked()
            this.props.SuccessAlt(response.message)
        }
        else {
            this.props.ErrorAlert(response.message)
        }
    }
    AddCorporateFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }

    EditCorporate = () => {
        let SameCorpName = []
        SameCorpName = this.state.corpCompanyList.filter(list => list['company_name'].toLowerCase() === this.state.RegisterName.toLowerCase());
        var states = this.state
        if (
            states.RegisterName != "" &&
            states.gstin != "" &&
            states.Address1 != "" &&
            states.city != "" &&
            states.pincode != "" &&
            states.state != "" &&
            states.country != "" &&
            states.mailID != "" &&
            states.mobile != "" &&
            states.Discountpercent != "" &&
            (SameCorpName.length === 0 || (SameCorpName.length == 1 && SameCorpName[0].company_name == this.state.RegisterName))
        ) {
            if (states.gstin.length != 15) {
                this.setState({
                    ErrorText: "Invalid GST Number - (Must Be In 15 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (states.pincode.length != 6) {
                this.setState({
                    ErrorText: "Invalid Pincode - (Must Be In 6 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (states.mobile.length != 10) {
                this.setState({
                    ErrorText: "Invalid Mobile Number - (Must Be In 10 digits)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (parseInt(states.Discountpercent) > 100) {
                this.setState({
                    ErrorText: "Invalid Discount Value - (Must Less Than 100)"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeFrom != "") && (states.RangeTo == "")) {
                this.setState({
                    ErrorText: "Employee Range To Value is Empty"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeTo != "") && (states.RangeFrom == "")) {
                this.setState({
                    ErrorText: "Employee Range From Value is Empty"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (parseInt(states.RangeFrom) > parseInt(states.RangeTo)) {
                this.setState({
                    ErrorText: "Invalid Employee Range Values"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if ((states.RangeFrom == "0") || (states.RangeTo == "0")) {
                this.setState({
                    ErrorText: "Invalid Employee Range Values"
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(states.mailID))) {
                this.setState({
                    ErrorText: "Invalid Mail ID "
                })
                setTimeout(()=>this.setState({ErrorText:""}), 2500)
            }
            else {
                this.setState({
                    ErrorText: ""
                })

                var service_url = Constants.CORPORATE_ADMIN_CONFIGURATION;
                var data = {
                    "id": this.state.editClickedId,
                    "company_name": states.RegisterName,
                    "gst_no": states.gstin,
                    "country": states.country,
                    "pincode": states.pincode,
                    "address_line_1": states.Address1,
                    "address_line_2": states.Address2,
                    "email": states.mailID,
                    "mobile": states.mobile,
                    "discount_percent": parseFloat(states.Discountpercent),
                    "discount_notes": states.DiscountNotes,
                    "employee_range_from": states.RangeFrom,
                    "employee_range_to": states.RangeTo,
                    "city_name": states.city,
                    "state_name": states.state,
                }

                OpthamologyService.getInstance().postComplaints(
                    service_url,
                    data,
                    this,
                    this.editCorporatePostSuccess,
                    this.editCorporatePostFailure
                );


            }

        }
        else if (SameCorpName.length > 0) {
            this.setState({
                ErrorText: "Corporate Name Already Exists"
            })
            setTimeout(()=>this.setState({ErrorText:""}), 2500)
        }
        else {
            this.setState({
                ErrorText: "Enter All Mandatory * Fields"
            })
            setTimeout(()=>this.setState({ErrorText:""}), 2500)
        }
    }

    editCorporatePostSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                corpCompanyList: []
            }, () => { this.resetClicked(), this.getCorporateList(), this.props.SuccessAlt(response.message) })
        }
        else {
            this.props.ErrorAlert(response.message)
        }

    }
    editCorporatePostFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }


    editClicked = (item) => {
        this.setState({
            ErrorText: "",
            RegisterName: item.company_name,
            gstin: item.gst_no,
            Address1: item.address_line_1,
            Address2: item.address_line_2,
            city: item.city_name,
            pincode: item.pincode,
            state: item.state_name,
            country: item.country,
            RangeFrom: item.employee_range_from,
            RangeTo: item.employee_range_to,
            mailID: item.email,
            mobile: item.mobile,
            Discountpercent: item.discount_percent,
            DiscountNotes: item.discount_notes
        })
    }

    resetClicked = () => {
        const DefaultValues = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).default_value : {};
        this.setState({
            SelectedItem: {},
            RegisterName: "",
            gstin: "",
            Address1: "",
            Address2: "",
            city: DefaultValues.default_city,
            pincode: "",
            state: DefaultValues.default_state,
            country: DefaultValues.default_country,
            RangeFrom: "",
            RangeTo: "",
            mailID: "",
            mobile: "",
            Discountpercent: "",
            DiscountNotes: "",
            ErrorText: '',
            isEditClicked: false,
            editClickedId: '',
        })
    }

    paginationChangedValues(data) {
        this.setState({
            CorpListAfterPagination: data
        })
    }

    returnSortIcon = (order) => {
        if (order == "") {
            return <Icon name="sort" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "asc") {
            return <Icon name="sort-up" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
        else if (order == "desc") {
            return <Icon name="sort-down" size={"1vw"} color={color.black} style={{ marginLeft: '1vw', marginTop: '0.2vw' }} />
        }
    }

    renderCorporateLeft = () => {
        return (
            <View>
                <View style={styles.SearchBoxView}>
                    <TextInput
                        style={styles.SearchInput}
                        placeholder={"Search by Company Name..."}
                        value={this.state.SearchCompanyKey}
                        onChangeText={(text) => {
                            this.setState({
                                SearchCompanyKey: text
                            }, () => {
                                this.getCorporateList()
                            })
                        }}
                    />
                </View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                {/* <View style={{ alignItems: "center", width: '6%' }} >
                                    <Text style={styles.TableHeadText}>S No</Text>
                                </View> */}
                                <TouchableOpacity
                                    onPress={() => {
                                        if (this.state.CorporateSort == "asc") {
                                            this.setState({
                                                CorporateSort: "desc",
                                                corpCompanyList: [],
                                            }, () => {
                                                this.getCorporateList()
                                            })
                                        }
                                        else if (this.state.CorporateSort == "desc") {
                                            this.setState({
                                                CorporateSort: "asc",
                                                corpCompanyList: []
                                            }, () => {
                                                this.getCorporateList()
                                            })
                                        }
                                    }}
                                    style={{ alignItems: "left", width: '26%', display: 'flex', flexDirection: 'row' }} >
                                    <Text style={[styles.TableHeadText, { marginLeft: '2.5vw' }]}>Company</Text>
                                    {this.returnSortIcon(this.state.CorporateSort)}
                                </TouchableOpacity>
                                <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Mobile Number</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '20%' }} >
                                    <Text style={styles.TableHeadText}>GSTIN</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Discount%</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '16%' }} >
                                    <Text style={styles.TableHeadText}>Action</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <ScrollView style={styles.MainTable}>
                        {this.state.corpCompanyList && this.state.corpCompanyList.length > 0 && this.state.CorpListAfterPagination && this.state.CorpListAfterPagination.length > 0 ? this.state.CorpListAfterPagination.map((item, index) => {
                            return (
                                <View key={index} style={[styles.TableDataWrapper]} >
                                    {/* <View style={{ width: this.state.CorpListAfterPagination.length>8?'8%':'6%', alignItems: 'center' }}><Text style={styles.TableDataText}>{index + 1}</Text></View> */}
                                    <View style={{ width: '26%', alignItems: 'left', }}>
                                        {item.company_name.length > 25 ?
                                            <Tooltip placement="top" title={item.company_name}>
                                                <Text style={[styles.TableDataText, { marginLeft: '1.5vw' }]}> {item.company_name.slice(0, 25) + ".."}</Text>
                                            </Tooltip> :
                                            <Text style={[styles.TableDataText, { marginLeft: '1.5vw' }]}> {item.company_name}</Text>}
                                    </View>
                                    <View style={{ width: this.state.CorpListAfterPagination.length > 8 ? '17.5%' : '16%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.mobile}</Text></View>
                                    <View style={{ width: '20%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.gst_no}</Text></View>
                                    <View style={{ width: '16%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.discount_percent}</Text></View>
                                    <View style={{ width: '16%', alignItems: 'center' }}>
                                        <View style={{ flexDirection: "row" }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setState({ isEditClicked: true, editClickedId: item.id }, () => { this.editClicked(item) })
                                                }}
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    width: "fit-content"
                                                }}
                                            >
                                                <Image
                                                    source={EditIcon}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={
                                                    () => {
                                                        this.setState({
                                                            isDeleteClick: true,
                                                            SelectedItem: item,
                                                            RegisterName: "",
                                                            gstin: "",
                                                            Address1: "",
                                                            Address2: "",
                                                            city: "",
                                                            pincode: "",
                                                            state: "",
                                                            country: "",
                                                            RangeFrom: "",
                                                            RangeTo: "",
                                                            mailID: "",
                                                            mobile: "",
                                                            Discountpercent: "",
                                                            DiscountNotes: "",
                                                            ErrorText: '',
                                                            isEditClicked: false,
                                                            editClickedId: ''
                                                        })
                                                    }
                                                }
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    marginLeft: "2vw",
                                                    width: "fit-content"
                                                }}>
                                                <Image
                                                    source={DeleteIcon}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            )
                        }) :
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NoRecordsText}>No Records to show...</Text>
                            </View>}

                    </ScrollView>
                    {
                        <View style={styles.PaginationView}>
                            {

                                this.state.corpCompanyList && this.state.corpCompanyList.length > 0 ?

                                    <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.corpCompanyList} />
                                    : null
                            }
                        </View>
                    }
                </View>

            </View>
        )
    }


    renderCorporateRight = () => {
        return (
            <View style={{ height: '70vh' }}>
                <ScrollView style={styles.RightScrollView} showsVerticalScrollIndicator={false}>
                    <Text style={styles.RightHeadTitle}>Add Corporate Details</Text>

                    <View style={styles.HightLightView}>
                        <Text style={styles.HighLightText}> Company Details</Text>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Registered Name<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("RegisterName")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>GSTIN<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("gstin")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Address Line 1<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("Address1")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Address Line 2</Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("Address2")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>City<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("city")}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Pincode<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox("pincode")}
                        </View>
                    </View>
                    {/* <View style={styles.InputView}>
                        <View style={styles.InputView}>
                            <Text style={styles.InputText}>City<Text style={styles.MandatoryText}>*</Text></Text>
                            <View style={{ width: '9vw', marginRight: '.5vw', marginLeft: '1vw' }}>
                                {this.renderTextBox('city')}
                            </View>
                        </View>
                        <View style={styles.InputView}>
                            <Text style={{ marginLeft: '1vw', fontSize: '0.9vw', }}>Pincode<Text style={styles.MandatoryText}>*</Text></Text>
                            <View style={{ width: '9vw', marginRight: '1.5vw', marginLeft: '1vw' }}>
                                {this.renderTextBox("pincode")}
                            </View>
                        </View>
                    </View> */}
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>State<Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw', marginTop: "0.65vw" }}>
                            <RegionDropdown
                                style={{ height: '2.2vw', fontSize: '1vw', paddingLeft: '0.6vw', borderColor: '#CDD1D5', borderRadius: '0.26vw', backgroundColor: color.themeShade }}
                                country={this.state.country}
                                value={this.state.state}
                                // defaultOptionLabel={""}
                                onChange={(val) => this.setState({ state: val })} />
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Country <Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw', marginTop: "0.65vw", }}>
                            <CountryDropdown
                                style={{ height: '2.2vw', fontSize: '1vw', paddingLeft: '0.6vw', borderColor: '#CDD1D5', borderRadius: '0.26vw', backgroundColor: color.themeShade }}
                                value={this.state.country}
                                onChange={(val) => this.setState({ country: val })}
                                priorityOptions={["IN"]}
                                whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                            />
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <View style={styles.InputView}>
                            <Text style={styles.InputText}>Employee Range</Text>
                            <View style={{ width: '7vw', marginRight: '.5vw', marginLeft: '2.5vw' }}>
                                {this.renderTextBox('RangeFrom')}
                            </View>
                        </View>
                        <View style={styles.InputView}>
                            <Text style={{ marginLeft: '0.5vw', fontSize: '0.9vw', }}>To</Text>
                            <View style={{ width: '7vw', marginRight: '1.5vw', marginLeft: '1.5vw' }}>
                                {this.renderTextBox('RangeTo')}
                            </View>
                        </View>
                    </View>

                    <View style={styles.HightLightView}>
                        <Text style={styles.HighLightText}> Contact Details</Text>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Email ID <Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox('mailID')}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Mobile Number <Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox('mobile')}
                        </View>
                    </View>
                    <View style={styles.HightLightView}>
                        <Text style={styles.HighLightText}> Discount Details</Text>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Discount% <Text style={styles.MandatoryText}>*</Text></Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox('Discountpercent')}
                        </View>
                    </View>
                    <View style={styles.InputView}>
                        <Text style={styles.InputText}>Discount Notes</Text>
                        <View style={{ width: '18vw', marginRight: '1.5vw' }}>
                            {this.renderTextBox('DiscountNotes')}
                        </View>
                    </View>

                </ScrollView>
                <View>
                    <View style={{ height: '1.5vw', marginTop: '0.3vw' }}>
                        <Text style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '.9vw', fontWeight: '500', justifyContent: 'center' }}>
                            {this.state.ErrorText}
                        </Text>
                    </View>
                    <View>
                        <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'space-around' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.state.isEditClicked ? this.EditCorporate() : this.AddNewCorporate()
                                }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{this.state.isEditClicked ? "Update" : "Add"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => { this.resetClicked() }}
                                style={styles.BtnTouch}>
                                <Text style={styles.ButtonText}>{"Clear"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    deletecorporate = (id) => {
        var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION + "?id=" + id;
        var data = {}
        OpthamologyService.getInstance().deleteComplaints(
            serviceUrl,
            data,
            this,
            this.deleteCorporateSuccess,
            this.deleteCorporateFailure
        )
    }

    deleteCorporateSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                isDeleteClick: false,
                SelectedItem: {}
            }, () => { this.getCorporateList(), this.props.SuccessAlt(response.message) })
        }
        else {
            this.props.ErrorAlert(response.message)
        }

    }
    deleteCorporateFailure = (error) => {
        this.props.ErrorAlert(error.message)
    }

    renderDeletePopup = () => {
        return (
            <View style={styles.DeletePopMain}>
                <View>
                    {this.state.SelectedItem.is_payment_completed ?
                        <View style={styles.DeletePopView}>
                            <Text style={styles.DeletePopText}>{"Are you sure to delete company - "}<Text style={{ color: 'red' }}>{` ${this.state.SelectedItem.company_name} `}</Text>?</Text>
                            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>
                                <TouchableOpacity
                                    style={styles.DeletePopBtn}
                                    onPress={() => {
                                        this.deletecorporate(this.state.SelectedItem.id)
                                    }}
                                >
                                    <Text style={{ color: 'white', fontSize: '0.78vw', }}>{"Yes"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={styles.DeletePopBtn}
                                    onPress={() => {
                                        this.setState({
                                            // deletedId : null,
                                            isDeleteClick: false,
                                            SelectedItem: {}
                                        })
                                    }}
                                >
                                    <Text style={{ color: 'white', fontSize: '0.78vw', }}>{"No"}</Text>
                                </TouchableOpacity>
                            </View></View>
                        :
                        <View style={styles.Delete2View}>
                            <View style={{ height: '11vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={styles.Delete2text}>
                                    {"You can't Delete the Company "}<Text style={{ color: 'red' }}>{` ${this.state.SelectedItem.company_name}`}</Text>{"."}
                                </Text>
                                {
                                    this.state.SelectedItem.total_pending && this.state.SelectedItem.total_pending > 0 ?
                                        <Text style={styles.Delete2text}>
                                            {"Because it has Pending Amount of "}<Text style={{ color: 'red' }}>{` ₹ ${this.state.SelectedItem.total_pending}`}</Text>{'.'}
                                        </Text>
                                        : 
                                        <Text style={styles.Delete2text}>
                                            {"Because it has some Pending Bills."}
                                        </Text>
                                }

                                <Text style={styles.Delete2text}>
                                    {"Please Complete those Pending Bills."}
                                </Text>
                            </View>
                            <TouchableOpacity
                                style={styles.DeleteCloseBtn}
                                onPress={() => {
                                    this.setState({
                                        // deletedId : null,
                                        isDeleteClick: false,
                                        SelectedItem: {}
                                    })
                                }}
                            >
                                <Text style={{ color: color.red, fontSize: '0.78vw', fontWeight: '500' }}>{"Close"}</Text>
                            </TouchableOpacity>
                        </View>
                    }

                </View>
            </View >
        )
    }
    render() {
        return (
            <View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={styles.LeftMainView}>{this.renderCorporateLeft()}</View>
                    <View style={styles.RightMainView}>{this.renderCorporateRight()}</View>
                </View>

                {this.state.isDeleteClick ?
                    this.renderDeletePopup()
                    : null}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    LeftMainView: {
        width: '65%',
        height: '70vh',
        margin: '0.2vw',
        marginLeft: '0.7vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },

    RightMainView: {
        width: '33%',
        height: '70vh',
        backgroundColor: color.themeShade,
        margin: '0.2vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },
    RightScrollView: {

    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw'
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    MainTable: {
        height: '50vh'
    },
    RightHeadTitle: {
        marginLeft: '1vw',
        marginTop: '0.3vw',
        fontSize: '1vw',
        fontWeight: 'bold',
        color: color.themeDark
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "100%",
        height: "1.5vw",
        fontSize: ".9vw"
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '2vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw"
    },
    MandatoryText: {
        color: 'red',
        fontWeight: '500',
        marginLeft: '0.2vw',
        fontSize: '1vw'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.lightGray,
        borderRadius: '0.26vw',
        width: "25%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    TabView: {
        marginLeft: '2vw'
    },
    SearchBoxView: {
        margin: '0.5vw', marginLeft: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'
    },
    DeletePopMain: {
        height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"
    },
    DeletePopView: {
        paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "24vw", height: '10vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1,
    },
    DeletePopText: {
        fontSize: '1.17vw', fontWeight: "bold", marginBottom: '3.25vw', textAlign: 'center'
    },
    DeletePopBtn: {
        alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw'
    },
    Delete2View: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "30vw", height: '15vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1
    },
    Delete2text: {
        fontSize: '1vw', fontWeight: "bold", marginBottom: '1vw', textAlign: 'center'
    },
    DeleteCloseBtn: {
        alignItems: 'center', justifyContent: 'center', backgroundColor: color.white, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw', borderColor: color.red, borderWidth: 1
    }


})