import React from "react";
import {
  View,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
  Text,
  KeyboardAvoidingView,
  Dimensions,
  Platform
} from "react-native";
import Icon from "react-native-vector-icons/EvilIcons";
import SelectIcon from "react-native-vector-icons/Ionicons";
import Exclamation from "react-native-vector-icons/AntDesign";
import { color } from "../styles/Color";
import { Constants } from "../utils/Constants";
import Service from "../network/Service";
import AsyncStorage from "../AsyncStorage";
import BaseClass from "./BaseClass";
import Styles from "../styles/Style";
import EyeIcon from "react-native-vector-icons/MaterialCommunityIcons";
var CryptoJS = require("crypto-js");
var Info = require('./../utils/InfoMessages');
import OpthamologyService from './../network/OpthamologyService'
import Icons from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import ImagePath from "../utils/ImagePaths"

const navigation = navigation;
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const platform = Platform.OS;

export default class SignInScreen extends BaseClass {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      errorMessage: "",
      rememberMe: false,
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorMessageForUserName: "",
      errorMessageForPassword: "",
      screenName: "",

      userNameOrMobileNo: "",
      enteredOTP: "",

      newPassword: "",
      confirmPassword: "",

      // success messgae
      responseType: "",
      responseMeaasge: "",
      userNameDisable: false

    };
  }

  forgotPassword() {
    var data = {
      "username": (this.state.userNameOrMobileNo).toString(),
      "otp_type": 2
    }


    var service_url = Constants.SEND_OTP;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.forgotPasswordSuccessRess,
      this.forgotPasswordErrorRess
    );
  }


  forgotPasswordSuccessRess = success => {
    if (success.status == "success") {
      this.showResposeValue("success", success.message);
      this.setState({
        screenName: "OTP",
        errorMessageForPassword: "",
        userNameDisable: true

      })
    } else {
      this.setState({
        errorMessageForPassword: success.message
      })
    }
  }
  forgotPasswordErrorRess = error => {
    this.setState({
      errorMessageForPassword: error.message
    })
  }

  sendOTP() {

    var data = {
      "username": this.state.userNameOrMobileNo,
      "otp_type": 2,
      "otp": this.state.enteredOTP,
    }

    var service_url = Constants.VERIFY_OTP;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.sendOTPSuccessRess,
      this.sendOTPErrorRess
    );
  }


  sendOTPSuccessRess = success => {
    if (success.status == "success") {
      this.showResposeValue("success", success.message);

      this.setState({
        screenName: "CP",
        errorMessageForPassword: "",
        enteredOTP: "",

      })
    } else {
      this.setState({
        errorMessageForPassword: success.message
      })
    }
  }
  sendOTPErrorRess = error => {
    this.setState({
      errorMessageForPassword: error.message
    })
  }


  changePassword() {

    var data = {
      "username": this.state.userNameOrMobileNo,
      "password": this.state.newPassword,
      "confirm_password": this.state.confirmPassword
    }


    var service_url = Constants.PASSWORD_RESET;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.changePasswordSuccessRess,
      this.changePasswordErrorRess
    );
  }


  changePasswordSuccessRess = success => {
    if (success.status == "success") {
      this.showResposeValue("success", success.message);
      this.setState({
        screenName: "",
        errorMessageForPassword: ""
      })
    } else {
      if (this.state.newPassword.length > 0 && this.state.confirmPassword.length <= 0) {
        this.setState({
          errorMessageForPassword: "Please enter confirm password"
        })
      }
      else {
        this.setState({

          errorMessageForPassword: success.message
        })
      }
    }
  }
  changePasswordErrorRess = error => {
    this.setState({
      errorMessageForPassword: error.message
    })
  }

  backToPreviousScreen() {
    var states = this.state;
    if (this.state.screenName == "CP") {
      states["screenName"] = "OTP"
    } else if (this.state.screenName == "FP") {
      states["screenName"] = ""
    }
    else if (this.state.screenName == "OTP") {
      this.setState({
        userNameDisable: false
      })
      states["screenName"] = "FP"
    }

    states["errorMessageForPassword"] = "";
    states["userNameOrMobileNo"] = "";
    states["enteredOTP"] = "";
    states["newPassword"] = "";
    states["confirmPassword"] = "";

    this.setState({ states })
  }

  _validatePassword = () => {

    if (this.state.screenName) {
      if (this.state.screenName == "CP") {
        this.changePassword()
      } else if (this.state.screenName == "FP") {

        this.forgotPassword()
      }
      else if (this.state.screenName == "OTP") {
        this.sendOTP()
      }
    } else {
      var userNameIsEmpty = "Please Enter The User Name..."
      var passwordIsEmpty = "Please Enter The Password..."
      var user = this.state.userName;
      var pass = this.state.password;

      if (!user) {
        this._showAlert("Enter username");
        this.setState({
          errorMessageForUserName: "Enter username"
        })
      } else if (!pass) {
        this.setState({
          errorMessageForPassword: "Enter Password"
        })
        this._showAlert("Enter Password");
      } else {
        if (pass.length < 6) {
          this.setState({
            errorMessageForPassword: "Password length should be greater than of 6"
          })
          this._showAlert("Password length should be greater than of 6");
        } else {
          const params = {
            username: user,
            password: pass
          };

          Service.getInstance().signIn(params, this, this.loginSuccess, this.loginFailed);
        }
      }
    }
  }

  encryptData(data) {
    var cipherText = CryptoJS.AES.encrypt(data, "Remember_Me_Key").toString();
    return cipherText
  }

  decryptData(data) {
    if (data) {
      var bytes = CryptoJS.AES.decrypt(data, 'Remember_Me_Key');
      var decryptedData = JSON.parse(JSON.stringify(bytes.toString(CryptoJS.enc.Utf8)));
      return decryptedData
    }
    else {
      return "null"
    }
  }

  async componentDidMount() {

    var rememberMeFlag = await AsyncStorage.getItem("Remember_Me_Flag");

    if (rememberMeFlag) {
      var getRememberMeName = await AsyncStorage.getItem("Remember_Me_Name");
      var getDecryptedData = this.decryptData(getRememberMeName);
      if (getDecryptedData && getDecryptedData !== null && getDecryptedData !== "null") {
        this.setState({ userName: getDecryptedData, rememberMe: rememberMeFlag })
      }
    }
  }

  loginSuccess = async response => {
    if (response.status == "success") {

      if (this.state.rememberMe) {
        var getEncryptedData = this.encryptData(this.state.userName);
        await AsyncStorage.setItem("Remember_Me_Name", getEncryptedData);
        await AsyncStorage.setItem("Remember_Me_Flag", this.state.rememberMe);
      } else {
        await AsyncStorage.setItem("Remember_Me_Name", "");
        await AsyncStorage.setItem("Remember_Me_Flag", false);
      }
      await AsyncStorage.setItem("loggedIn", "true");
      await AsyncStorage.setItem("token", response.data.token + "");

      await AsyncStorage.setItem("UserID", response.data.profile_id + "");  //this is profile id

      await AsyncStorage.setItem("UserName", response.data.name + "");

      await AsyncStorage.setItem("IsIpAvailable", response.data.is_ip_available + "");

      var loggedInUserData = ""
      loggedInUserData = JSON.stringify(response.data)
      await AsyncStorage.setItem("loggedInData", loggedInUserData)

      if (response.data.is_fo_admin) {
        await AsyncStorage.setItem("isFoAdmin", response.data.is_fo_admin + "");
      } else {
        await AsyncStorage.setItem("isFoAdmin", false + "");
      }

      var getDRType = response.data.service_type
      var drType = ""
      if (getDRType == "OPT") {
        drType = "Ophthalmology"
      } else if (getDRType == "DOCTOR") {
        drType = "General Physician"
      } else if (getDRType == "PD") {
        drType = "Pediatrician"
      }
      else if (getDRType == "DB") {
        drType = "Diabetologist"
      }
      else if (getDRType == "OBGYN") {
        drType = "Obstetrics and Gynaecologist"
      }
      else if (getDRType == "PSY") {
        drType = "Psychiatrist"
      }
      else if (getDRType == "FO") {
        drType = "Front Office"
      }
      else if (getDRType == "LAB") {
        drType = "Laboratory User"
      }
      else if (getDRType == "COUNSELLOR") {
        drType = "Counsellor"
      } else if(getDRType == "OT") {
        drType = "OT"
      } else if(getDRType == "OPTOMETRY"){
        drType = "OPTOMETRY"
      }




      await AsyncStorage.setItem("DR_TYPE", drType + "");

      await AsyncStorage.setItem("service_type", response.data.service_type + "");
      await AsyncStorage.setItem("role_type", response.data.role_type + "");
      await AsyncStorage.setItem("doctorName", response.data.name + "");
      var profilePhoto = ""
      if (response.data.photo_url !== null && response.data.photo_url !== "" && response.data.photo_url !== undefined) {
        profilePhoto = response.data.photo_url
      }
      await AsyncStorage.setItem("doctorProfile", profilePhoto + "");
      await AsyncStorage.setItem("UserName", response.data.name + "");


      if (getDRType == "LAB" && response.data.access) {  
        await AsyncStorage.setItem("Lab_User_Access", response.data.access + "");
        await AsyncStorage.setItem("Is_Lab_Admin", response.data.is_lab_admin + "");

      }

      if(getDRType == "OT"){
        await AsyncStorage.setItem("Ot_Clinic_Id", response.data.clinic_id + "");
      }
      if(getDRType == "OPTOMETRY"){
        await AsyncStorage.setItem("Selected_ClinicIDFor_Doctor", response.data.Selected_ClinicIDFor_Doctor);
      }

      this.props.navigation.navigate("homeNavigator");

    } else {
      this.setState({
        errorMessageForPassword: response.message,
      })
      this._showAlert(response.message, "OK");
    }
    var superAdmindata = response.data
    if (superAdmindata && superAdmindata.is_password_reset){
      await AsyncStorage.setItem("user_name", this.state.userName + "");
    }
  }

  loginFailed = (error) => {
    this.setState({

      errorMessageForPassword: error.message,
    })
    this._showAlert(error.message, "OK");
  }

  validatePassword(value) {
    var isUpper = false;
    var isLower = false;
    var isNum = false;
    var isSpl = false;

    const upper = RegExp(".*[A-Z].*");
    const lower = RegExp(".*[a-z].*");
    const numeral = RegExp(".*[0-9].*");
    const special = RegExp(".*[!@#%^&*()_+-=].*"); //have to check dollar ($) symbol too

    var len = value.length;

    for (let i = 0; i < len; i++) {
      let passChar = value.charAt(i);

      if (upper.test(passChar)) {
        isUpper = true;
      } else if (lower.test(passChar)) {
        isLower = true;
      } else if (numeral.test(passChar)) {
        isNum = true;
      } else if (special.test(passChar)) {
        isSpl = true;
      }
    }

    if (isUpper == true && isLower == true && isNum == true && isSpl == true) {
      return true;
    } else {
      return false;
    }
  }

  onChangeValues(key, value) {
    var states = this.state;
    if (key == "userName") {
      states["userName"] = value;
      states["errorMessageForUserName"] = "";
      states["errorMessageForPassword"] = "";
    }
    else if (key == "password") {
      states["password"] = value
      states["errorMessageForPassword"] = "";
      states["errorMessageForUserName"] = "";
    } else if (key == "userNameOrMobileNo") {
      states["userNameOrMobileNo"] = value;
    } else if (key == "enteredOTP") {

      if (this.numberValidation(value)) {
        states["enteredOTP"] = value;
      }
    } else if (key == "newPassword") {
      states["newPassword"] = value;
    } else if (key == "confirmPassword") {
      states["confirmPassword"] = value;
    }
    this.setState({ states })
  }
  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  renderTextBox(placeholder, key, value, lefticon, rightIcon = "", showEyeFlag, showEyeFlagKey) {
    // alert(rightIcon)
    return (
      <View>
        <View style={styles.loginTextbox}>
          <View style={[Styles.inputPutFieldIcons]}>
            <Icon name={lefticon} size={'3vw'} style={Styles.rememberMe_User_Password_Icon} />
          </View>
          <TextInput
            secureTextEntry={

              key == "password" || key == "newPassword" || key == "confirmPassword" ?

                (showEyeFlag && showEyeFlagKey == "showPassword") ||
                  (showEyeFlag && showEyeFlagKey == "showConfirmPassword") ||
                  (showEyeFlag && showEyeFlagKey == "showNewPassword") ? false : true : false}

            style={[rightIcon ? Styles.loginPageTextBoxRightIcon : Styles.loginPageTextBox, { flex: rightIcon ? 0.75 : 0.85 }]}
            placeholder={placeholder}
            value={value}
            editable={key == "userNameOrMobileNo" && this.state.userNameDisable ? false : true}
            onChangeText={(text) => {
              this.onChangeValues(key, text);
            }}
            onSubmitEditing={() => { this._validatePassword() }}
          />
          {
            rightIcon ?
              <View style={[Styles.inputPutFieldRightIcons, { flex: 0.10, justifyContent: 'center' }]}>
                <TouchableOpacity onPress={() => {
                  var states = this.state;
                  states[showEyeFlagKey] = !showEyeFlag;


                  this.setState({
                    states
                  })
                }}>
                  <EyeIcon name={
                    (showEyeFlag && showEyeFlagKey == "showPassword") ||
                      (showEyeFlag && showEyeFlagKey == "showConfirmPassword") ||
                      (showEyeFlag && showEyeFlagKey == "showNewPassword")


                      ? "eye-outline" : "eye-off-outline"} size={'2vw'} style={Styles.rememberMe_User_Password_Icon} />
                </TouchableOpacity>
              </View> : null
          }
        </View>
      </View>
    );
  }

  showValidationMessage(data) {
    return (
      <View style={{ width: "80%" }}>
        {
          data !== "" ?
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 5
            }}>
              <Exclamation name={"exclamationcircleo"} size={16} style={{
                color: 'red',
                marginLeft: 10
              }} />
              <Text style={{
                color: "red",
                fontSize: 12,
                marginLeft: 15
              }}>{data}</Text>
            </View>
            : null
        }
      </View>
    )
  }


  showResposeValue(type, meaasge) {
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }


  successAlert() {
    return (
      <View style={{ flex: 1, zIndex: 3, width: "40%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
          <Success size={25} name={"check"} color={"green"} />
          <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"SUCCESS: "}
            <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icons size={25} name={"close"} color={"green"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {

    let errorList = (this.state.responseMeaasge).split(",")
    return (
      <View style={{ flex: 1, zIndex: 3, width: "35%", position: 'absolute', left: 10, bottom: 5, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, minHeight: 70, marginRight: 10, marginTop: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, marginLeft: 10, flex: 0.95 }}>
          <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: 10 }}>
            <Icons size={25} name={"ban"} color={"#FFF"} /></View>
          <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
            <View>
              <Text style={{ fontWeight: "700", color: "#FFF", fontSize: 12 }}>{"ERROR: "}
                {errorList && errorList.map((item, index) => {
                  return <View key={index}><Text style={{ fontWeight: "400", color: "#FFF", fontSize: 12, marginBottom: 8 }}>{item}</Text></View>
                })}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icons size={25} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }



  render() {
    return (
      <View style={styles.signinPage}>
        {
          this.state.responseType == "success" ?
            this.successAlert() :
            this.state.responseType == "error" ?
              this.errorAlert() : null
        }
        <Text style={styles.brandName}>{"eMedHub"}</Text>
        <View style={styles.loginpageContainer}>
          <View style={{ flex: 0.50 }}>
            <Image style={styles.loginImage} source={ImagePath.LoginPageImage} />
          </View>
          <View style={styles.loginpageRightsideContent}>
            <View>
              <Text style={styles.loginwelcomeText}>{
                this.state.screenName == "FP" || this.state.screenName == "OTP" ?
                  "Forgot Password" :
                  this.state.screenName == "CP" ?
                    "Change Password"
                    :

                    "Welcome to eMedHub"}
              </Text>
            </View>

            {
              this.state.screenName == "FP" ?
                <View style={{ marginBottom: 20, alignItems: 'center' }}>
                  {this.renderTextBox("Username / Mobile Number", "userNameOrMobileNo", this.state.userNameOrMobileNo, "user")}
                  {this.showValidationMessage(this.state.errorMessageForPassword)}
                </View> :
                this.state.screenName == "OTP" ?
                  <View>
                    <View style={{ marginBottom: 20 }}>
                      {this.renderTextBox("Username / Mobile Number", "userNameOrMobileNo", this.state.userNameOrMobileNo, "user")}
                    </View>
                    <View style={{ marginBottom: 20 }}>
                      {this.renderTextBox("Enter OTP", "enteredOTP", this.state.enteredOTP, "lock")}
                      {this.showValidationMessage(this.state.errorMessageForPassword)}
                    </View>
                  </View>
                  :
                  this.state.screenName == "CP" ?

                    <View>
                      <View style={{ marginBottom: 20 }}>
                        {this.renderTextBox("New Password", "newPassword", this.state.newPassword, "lock", "eye-off-outline", this.state.showNewPassword, "showNewPassword")}
                      </View>

                      <View style={{ marginBottom: 20 }}>
                        {this.renderTextBox("Confirm Password", "confirmPassword", this.state.confirmPassword, "lock", "eye-off-outline", this.state.showConfirmPassword, "showConfirmPassword")}
                        {this.showValidationMessage(this.state.errorMessageForPassword)}
                      </View>
                    </View> :
                    <View>
                      <View style={{ marginBottom: 20 }}>
                        {this.renderTextBox("Username", "userName", this.state.userName, "user")}
                        {this.showValidationMessage(this.state.errorMessageForUserName)}
                      </View>

                      <View style={{ marginBottom: 20 }}>
                        {this.renderTextBox("Password", "password", this.state.password, "lock", "eye-off-outline", this.state.showPassword, "showPassword")}
                        {this.showValidationMessage(this.state.errorMessageForPassword)}
                      </View>
                    </View>
            }


            {
              this.state.screenName ?
                null :
                <View style={Styles.rememberAndForgotPasswordView}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => this.setState({ rememberMe: !this.state.rememberMe })}>
                      <SelectIcon size={22} style={Styles.rememberMe_User_Password_Icon} name={this.state.rememberMe ? "ios-checkmark-circle-outline" : "ios-radio-button-off"} />
                    </TouchableOpacity>
                    <Text style={Styles.rememberMeText}>{"Remember Me"}</Text>
                  </View>
                  <View>
                    <TouchableOpacity onPress={() => { this.setState({ screenName: "FP" }) }}>
                      <Text style={Styles.forgotPasswordText}>{"Forgot Password?"}</Text>
                    </TouchableOpacity>
                  </View>
                </View>

            }

            {
              this.state.screenName ?

                <View style={Styles.signinAndBackButtonView}>

                  <TouchableOpacity style={Styles.singinTouchableView} onPress={() => this.backToPreviousScreen()}>
                    <Text style={Styles.signinText}>{"Back"}</Text>
                  </TouchableOpacity>

                  <TouchableOpacity style={Styles.singinTouchableView} onPress={() => this._validatePassword()}>
                    <Text style={Styles.signinText}>{
                      this.state.screenName == "FP" ?
                        "Send OTP"
                        : (this.state.screenName == "OTP" || this.state.screenName == "CP") ?
                          "Submit"
                          :
                          "Sign in"}</Text>
                  </TouchableOpacity>
                </View>
                :

                <View style={Styles.signinView}>
                  <TouchableOpacity style={Styles.singinTouchableView} onPress={() => this._validatePassword()}>
                    <Text style={Styles.signinText}>{
                      this.state.screenName == "FP" ?
                        "Send OTP"
                        : (this.state.screenName == "OTP" || this.state.screenName == "CP") ?
                          "Submit"
                          :
                          "Sign in"}</Text>
                  </TouchableOpacity>
                </View>

            }


            <View style={{ width: "80%" }}>
              {


                <Text style={{ color: color.themeDark, fontSize: 13, textAlign: 'center', fontWeight: '400' }}>
                  {this.state.screenName == "FP" || this.state.screenName == "OTP" ?
                    Info.Forgot2PasswordHint :
                    this.state.screenName == "CP" ?
                      Info.ChangePasswordHint : null
                  }
                </Text>

              }
            </View>

          </View>
        </View>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: { flex: 1, justifyContent: "center", alignItems: "center" },
  loginContainer: {
    alignItems: "center"
  },

  inputContainer: {
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.red,
    padding: 20,
    borderRadius: 20,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      height: 2,
      width: 2
    },
    elevation: 4,
    backgroundColor: "#FCFEFF"
  },
  inputBox: {
    borderColor: "#525252",
    borderWidth: 1,
    marginTop: 20,
    height: platform === "web" ? 40 : 48,
    borderWidth: 1,
    borderBottomWidth: 1,
    borderRadius: 0,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    width: platform === "web" ? screenWidth / 4 : screenWidth / 3,
    paddingLeft: 10,
    paddingRight: 10
  },
  input: {
    color: "#525252",
    paddingLeft: 5,
    marginRight: 20,
    flex: 1
  },
  brandHeader: {
    width: platform === "web" ? 100 : 150,
    height: platform === "web" ? 100 : 150
  },
  bg: {
    position: "absolute",
    width: "100%",
    marginBottom: 1,
    marginRight: 1,
    marginTop: 1
  },
  signinPage: {
    backgroundColor: 'white'
  },
  brandName: {
    fontSize: '2.5vw',
    color: '#04c6bf',
    fontWeight: 'bold',
    marginLeft: 70,
    position: 'absolute',
    marginTop: 30
  },
  loginImage: {
    width: '50vw',
    height: '100vh',
    // resizeMode: 'cover'
  },
  loginpageContainer: {
    flexDirection: 'row',
    flex: 1,
    // zIndex: -1,
  },
  loginpageRightsideContent: {
    flex: 0.50,
    backgroundColor: "white", //border color,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  loginwelcomeText: {
    fontSize: '2vw',
    marginBottom: 30,
    fontWeight: '700',
    textAlign: 'center'
  },
  loginTextbox: {
    flexDirection: 'row',
    width: '33vw',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
