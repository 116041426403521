//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { color } from '../../../../styles/Color';
import { HistoryYesOrNoSection, CommonButton, LabelWithTextBoxDR, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";

// create a component
const pageName = "isBirthHistoryNew"

class BirthHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            anyComplicationsDuringPergnancy: false,
            anyComplicationsDuringPergnancyDetails: "",

            modeOfDeliveryList: [
                { label: "Normal", value: 1 },
                { label: "C-Section", value: 2 },
                { label: "Assisted", value: 3 },
            ],
            selectedModeOfDelivery: {},


            selectedGestationalAge: {},
            gestationalAgeList: [
                { label: "Pre-Term", value: 1 },
                { label: "Full-Term", value: 2 },
                { label: "Post-Term", value: 3 },
            ],

            selectedBirthWeight: {},
            birthWeightList: [
                { label: 2, value: 2 },
                { label: 2.5, value: 2.5 },
                { label: 3, value: 3 },
                { label: 3.5, value: 3.5 },
            ],
            showSelectedBirthWeightListNewButtonFlag: true,

            complicationsAfterBirthDetails: "",
            complicationsAfterBirth: false,

            neonatalPeriod: false,
            neonatalPeriodDetails: "",

            developmentValue: "",
            feedingHistory: ""
        }
    }


    componentWillReceiveProps(props) {

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var { birthWeightList } = this.state;
                var states = this.state;
                // var { activityLevelList, exerciseDailyList, typeExerciseList } = this.state
                states["anyComplicationsDuringPergnancyDetails"] = data.pregnancy_comp_desc
                states["anyComplicationsDuringPergnancy"] = data.pregnancy_complication


                states["selectedModeOfDelivery"] =
                    data.mode_of_delivery == 1 ?
                        { label: "Normal", value: 1 } :
                        data.mode_of_delivery == 2 ?
                            { label: "C-Section", value: 2 } :
                            data.mode_of_delivery == 3 ?
                                { label: "Assisted", value: 3 } : {}



                states["selectedGestationalAge"] =
                    data.gestational_age == 1 ?
                        { label: "Pre-Term", value: 1 } :
                        data.gestational_age == 2 ?
                            { label: "Full-Term", value: 2 } :
                            data.gestational_age == 3 ?
                                { label: "Post-Term", value: 3 } : {}
                if(data.birth_weight){
                var birthWeight = data.birth_weight
                states["selectedBirthWeight"] = { label: birthWeight, value: birthWeight }
                birthWeightList.push({ label: birthWeight, value: birthWeight });
                birthWeightList = this.removeDuplicate(birthWeightList)

            }


                states["complicationsAfterBirthDetails"] = data.after_birth_comp_desc,
                    states["complicationsAfterBirth"] = data.after_birth_complication;

                states["neonatalPeriod"] = data.neonatal_period,
                    states["neonatalPeriodDetails"] = data.neonatal_period_reason;

                states["feedingHistory"] = data.feeding_history;
                states["developmentValue"] = data.development;

                this.setState({ states, birthWeightList }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }


    clearAllData() {
        var states = this.state;

        states["anyComplicationsDuringPergnancy"] = false;
        states["anyComplicationsDuringPergnancyDetails"] = "";

        states["complicationsAfterBirth"] = false;
        states["complicationsAfterBirthDetails"] = "";

        states["neonatalPeriod"] = false;
        states["neonatalPeriodDetails"] = "";


        states["selectedModeOfDelivery"] = {};
        states["selectedGestationalAge"] = {};
        states["selectedBirthWeight"] = {}


        states["developmentValue"] = "";
        states["feedingHistory"] = "";

        this.setState({
            states
        })

    }

    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
        if (key == "anyComplicationsDuringPergnancyDetails") {
            states["anyComplicationsDuringPergnancy"] = flag;
            states[key] = value;
        } else if (key == "complicationsAfterBirthDetails") {
            states["complicationsAfterBirth"] = flag;
            states[key] = value;
        } else if (key == "neonatalPeriodDetails") {
            states["neonatalPeriod"] = flag;
            states[key] = value;
        }
        this.setState({ states })
    }




    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "treatedOfDiabetic") {

            var { treatedOfDiabetic } = this.state;

            var getSelectedValue;
            var spliceData;

            if (treatedOfDiabetic.length > 0) {
                for (var i = 0; i < treatedOfDiabetic.length; i++) {
                    if (treatedOfDiabetic[i].value == selectedValue.value) {
                        spliceData = treatedOfDiabetic[i];
                        treatedOfDiabetic.splice(i, 1)
                        var data = this.removeDuplicate(treatedOfDiabetic);
                        this.setState({ treatedOfDiabetic: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { treatedOfDiabetic.push(selectedValue); }
            } else {
                treatedOfDiabetic.push(selectedValue)
            }

            var data = this.removeDuplicate(treatedOfDiabetic);
            this.setState({ treatedOfDiabetic: data })

        } else if (key == "showSelectedBirthWeightListNewButtonFlag") {
            states[key] = false;
        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }


    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var states = this.state;
        var { birthWeightList } = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showSelectedBirthWeightListNewButtonFlag) {
            birthWeightList.push(preparData);
            birthWeightList = this.removeDuplicate(birthWeightList);
            states["newButtonValue"] = "";
            states["showSelectedBirthWeightListNewButtonFlag"] = true;
            states["selectedBirthWeight"] = preparData;
        }
        this.setState({
            birthWeightList, states
        })
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var changedItem = {}
                            if (key == "selectedBirthWeight") {
                                changedItem = { label: item.label + " kgs", value: item.value }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        maxLength={30}
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }


    onPressAddButton() {
        var states = this.state;
        // var getTreatment = []
        // if (states.treatedOfDiabetic.length > 0) {
        //     for (var i = 0; i < states.treatedOfDiabetic.length; i++) {
        //         getTreatment.push(states.treatedOfDiabetic[i].value)
        //     }
        // }
        var data = {
            "patient_id": states.patientAppointment.patient_id,
            "pregnancy_complication": states.anyComplicationsDuringPergnancy,
            "pregnancy_comp_desc": states.anyComplicationsDuringPergnancyDetails,
            "mode_of_delivery": states.selectedModeOfDelivery ? states.selectedModeOfDelivery.value : 0,
            "gestational_age": states.selectedGestationalAge ? states.selectedGestationalAge.value : 0,
            "birth_weight": states.selectedBirthWeight ? parseFloat(states.selectedBirthWeight.value) : "",
            "after_birth_complication": states.complicationsAfterBirth,
            "after_birth_comp_desc": states.complicationsAfterBirthDetails,

            // neonatalPeriod: false,
            // neonatalPeriodDetails: "",
            // developmentValue: "",
            // feedingHistory: ""

            "neonatal_period": states.neonatalPeriod,
            "neonatal_period_reason": states.neonatalPeriodDetails,
            "development": states.developmentValue,
            "feeding_history": states.feedingHistory,

            "additional_notes": ""
        }


        var service_url = Constants.BIRTH_History_PD;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDiabeticHistorySuccess,
            this.saveDiabeticHistoryFailure
        );

    }

    saveDiabeticHistorySuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.clearAllData();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message);
        }
    }
    saveDiabeticHistoryFailure = error => {
        this.props.showResposeValue('error', error.message);
    }

    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Birth History"} clearAllData={this.clearAllData.bind(this)} />
                <HistoryYesOrNoSection
                    selectedValue={this.state.anyComplicationsDuringPergnancy}
                    label={"Any complications during pregnancy?"}
                    detailsOfYesValue={this.state.anyComplicationsDuringPergnancyDetails}
                    onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                    sectionKey={"anyComplicationsDuringPergnancyDetails"}
                    showDetailsText={true}
                    placeholder={"Description"}
                />

                {
                    this.renderLabelWithButtonGroup(
                        "Mode of Delivery",
                        "selectedModeOfDelivery",
                        this.state.selectedModeOfDelivery,
                        "",
                        false,
                        this.state.modeOfDeliveryList)
                }

                {
                    this.renderLabelWithButtonGroup(
                        "Gestational Age",
                        "selectedGestationalAge",
                        this.state.selectedGestationalAge,
                        "",
                        false,
                        this.state.gestationalAgeList)
                }

                {
                    this.renderLabelWithButtonGroup(
                        "Birth Weight (kgs)",
                        "selectedBirthWeight",
                        this.state.selectedBirthWeight,
                        "showSelectedBirthWeightListNewButtonFlag",
                        this.state.showSelectedBirthWeightListNewButtonFlag,
                        this.state.birthWeightList)
                }

                <View style={{ marginTop: 10, }}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.complicationsAfterBirth}
                        label={"Complications after birth?"}
                        detailsOfYesValue={this.state.complicationsAfterBirthDetails}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"complicationsAfterBirthDetails"}
                        showDetailsText={true}
                        placeholder={"Description"}
                    />
                </View>
                <View style={{ marginTop: 10, }}>

                    <HistoryYesOrNoSection
                        selectedValue={this.state.neonatalPeriod}
                        label={"Is there any admission during the Neonatal period?"}
                        detailsOfYesValue={this.state.neonatalPeriodDetails}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"neonatalPeriodDetails"}
                        showDetailsText={true}
                        placeholder={"Reason"}
                    />
                </View>

                <LabelWithTextBoxDR label={"Development"} value={this.state.developmentValue} textBoxKey={"developmentValue"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />
                <LabelWithTextBoxDR label={"Feeding History"} value={this.state.feedingHistory} textBoxKey={"feedingHistory"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default BirthHistoryNew;
