import React, { Component } from "react";
import {
  // Alert,
  View,
  Text,
  TextInput,
  // Button,
  Picker,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  // Modal,
  TouchableWithoutFeedback,
  ActivityIndicator,

  // ColorPropType,
} from "react-native";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";


const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

export default class WaitingComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelvisible: this.props.modelvisible,
      ErrorWaitingComment: "",
      WaitingComment: ""
    };
  }

  componentDidMount =()=>{
    if (this.props.resentappointment_id) {
      for (let i = 0; this.props.resentappointment_id.length > i; i++) {

        if(this.props.resentappointment_id[i] == this.props.WaitingComments_item){
          this.setState({
            WaitingComment : typeof(this.props.resentComment[i]) === "object" && this.props.resentComment[i] !== null ? this.props.resentComment[i][0].comments : this.props.resentComment[i]
          })
        }
      }
    } else {
      null
    }
  }


  CloseWaitingCommentsModel = () => {
    this.props.WaitingCommentsVisible(false)
  };

  ShowWaitingCommentsModel = (type, item) => {

    return (
      <View style={styles.commentBoxContainer}>
        <View
          style={styles.commentBoxWrapper}
        >
          <TouchableWithoutFeedback>
            <View
              style={styles.containerBody}
            >
              <View style={{ flex: 1 }}>
                <View
                  style={styles.headerContainer}
                >
                  <Text style={styles.headerTitle}>
                    Waiting Comments
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      this.CloseWaitingCommentsModel();
                    }}
                    style={{ fontSize: "1.95vw" }}
                  >
                    <Icon
                      name="window-close-o"
                      size={"1.10vw"}
                      color={color.themeDark}
                    />
                  </TouchableOpacity>
                </View>
                <TextInput
                  multiline={true}
                  placeholder={"Comments"}
                  underlineColorAndroid="transparent"
                  style={styles.textBoxStyle}
                  placeholderTextColor={"grey"}
                  autoCapitalize="none"
                  value={this.state.WaitingComment}
                  onChangeText={(text) => {
                    const Testvalue = text.match(/^ *$/)? "" : text;
                    this.setState({
                      WaitingComment: Testvalue,
                    })
                  }}
                />
                <Text
                  style={styles.errorTextStyle}
                >
                  {this.state.ErrorWaitingComment}
                </Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={[styles.confirmButtonStyle,{
                      backgroundColor: this.state.isCreateClicked
                        ? "#888888"
                        : color.themeDark
                    }]}
                    onPress={() => {
                      {
                        this.state.WaitingComment ?
                          (
                            this.props.WaitingCommentsVisible(false, type, item, this.state.WaitingComment)
                          )
                          : this.setState({
                            ErrorWaitingComment:
                              "Enter Comments",
                          });
                      }
                    }}
                  >
                    <Text style={styles.buttonTextStyle}>
                      Confirm
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.closeButtonStyle}
                    onPress={() => {
                      this.CloseWaitingCommentsModel();
                    }}
                  >
                    <Text style={[styles.buttonTextStyle,{color:null}]}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  };

  render() {
    
    return (
      <View style={{ position: "absolute", zIndex: 1 }}>
        <TouchableWithoutFeedback
          onPress={() => { this.CloseWaitingCommentsModel() }}
        >
          {this.state.modelvisible ? (this.ShowWaitingCommentsModel(
            this.props.WaitingComments_type,
            this.props.WaitingComments_item
          )) : null}

        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  commentBoxContainer:{ 
    position: "absolute" 
  },
  commentBoxWrapper:{
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  containerBody:{
    backgroundColor: "white",
    width: "40%",
    borderRadius: "0.65vw",
    padding: "1.30vw",
    marginBottom: "1vw"
  },
  headerContainer:{
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTitle:{ 
    color: "black", 
    fontSize: "1.25vw", 
    marginTop: "1vw" 
  },
  textBoxStyle:{
    alignSelf: "left",
    padding: "0.65vw",
    color: color.black,
    width: "100%",
    marginTop: "1.30vw",
    marginBottom: "1vw",
    height: "5.20vw",
    borderRadius: "0.65vw",
    borderColor: "grey",
    borderWidth: 1, fontSize: "1.04vw"
  },
  errorTextStyle:{
    marginBottom: "1vw",
    fontFamily: 13,
    fontWeight: "600",
    color: "red",
    textAlign: "center",
    fontSize:"1.2vw"
  },
  buttonContainer:{
    flexDirection: "row", 
    justifyContent: "right" 
  },
  confirmButtonStyle:{
    padding: "0.45vw",
    borderRadius: 4,
    width: "7.81vw",
    justifyContent: "center",
    marginRight: "0.32vw",
  },
  buttonTextStyle:{ 
    textAlign: "center", 
    color: "white", 
    fontSize:".9vw" 
  },
  closeButtonStyle:{
    backgroundColor: "white",
    padding: "0.45vw",
    borderRadius: 4,
    width: "7.81vw",
    justifyContent: "center",
    borderWidth: 1,
    marginLeft: "0.32vw",
    borderColor: "grey",
  }
});