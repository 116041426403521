import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "preMorbidHistory"


export class PreMorbidHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",
            detailsOfFamily: "",
            detailsOfFriends: "",
            detailsOfClubs: "",
            detailsmutualWork: "",
            detailsOfHobbies: "",
            detailsOfInterests: "",
            detailsOfPlay: "",
            detailsOfTalent: "",
            detailsOfMood: "",
            detailsOfCharacter: "",

            selectedSocialList: [
                { label: "Leader", value: "Leader" },
                { label: "Follower", value: "Follower" },
                { label: "Organiser", value: "Organiser" },
                { label: "Aggressive", value: "Aggressive" },
                { label: "Submissive", value: "Submissive" },
                { label: "Ambitious", value: "Ambitious" },
                { label: "Adjustable", value: "Adjustable" },
                { label: "Independent", value: "Independent" },
            ],
            selectedSocial: []
        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                states['id'] = data.id,
                    states['detailsOfFamily'] = data.family_relation
                states['detailsOfFriends'] = data.friends_relation
                states['detailsOfClubs'] = data.clubs
                states['detailsmutualWork'] = data.work
                states['detailsOfHobbies'] = data.hobbies
                states['detailsOfInterests'] = data.interests
                states['detailsOfPlay'] = data.play
                states['detailsOfTalent'] = data.talent
                states['detailsOfMood'] = data.mood_detail
                states['detailsOfCharacter'] = data.character_detail
                states["selectedSocial"] = this.preparingForList(data.social_relationship);


                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })


            }
        }
    }

    preparingForList(infrction) {
        var data = []
        if (infrction) {
            var getPrepareDatas = []
            var converToArray = infrction.split(",");
            for (let i = 0; i < converToArray.length; i++) {
                var prepareData = { label: converToArray[i], value: converToArray[i] }
                getPrepareDatas.push(prepareData);
            }
            data = getPrepareDatas;
        } else {
            data = []
        }
        return data
    }

    renderHistoryComponent(
        cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        selectBtnKey, selectedBtnValue, buttonList, showNewButton = false, newButtonKey = "", newbuttonFlag = false,
        topSubTextBoxKey = "", topSubTextBoxValue = "", showTopSubTextBox = false, topSubTextBoxLabel = "",
        topSubDropDownKey = "", topSubDropDownValue = "", showTopSubDropDown = false, topSubDropDownList = []
    ) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // // drop down list 
                // dropDownList = {
                //   ["mother","father","son","mom"]
                // }
                // selectedDropDownlist={this.state.selectedDropDownlist}
                // dropDownListKey={"selectedDropDownlist"}
                // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
                // // label with button select 

                selectedBtnValue={selectedBtnValue}
                selectBtnKey={selectBtnKey}
                buttonList={buttonList}

                showNewButton={showNewButton}
                newButtonKey={newButtonKey}
                newbuttonFlag={newbuttonFlag}
                onPressNewButton={this.onPressNewButton.bind(this)}
                onPressInfections={this.onPressButton.bind(this)} // onpress buttons
                onSubmitToAddNewButtonValue={this.onSubmitToAddNewButtonValue.bind(this)}


                // sub textbox in top view
                topSubTextBoxKey={topSubTextBoxKey}
                topSubTextBoxLabel={topSubTextBoxLabel}
                topSubTextBoxValue={topSubTextBoxValue}
                showTopSubTextBox={showTopSubTextBox}
                onChangeSubTextBoxValue={this.onChangeSubTextBoxValue.bind(this)}

                // sub Dropdown in top view
                topSubDropDownKey={topSubDropDownKey}
                topSubDropDownValue={topSubDropDownValue}
                topSubDropDownList={topSubDropDownList}
                showTopSubDropDown={showTopSubDropDown}
                onChangeSubDropDownValue={this.onChangeSubDropDownValue.bind(this)}
            />
        )
    }



    onChangeSubDropDownValue(key, value) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    onChangeSubTextBoxValue(key, value) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    onSubmitToAddNewButtonValue(value) {
        var prepareData = { label: value, value: value };
        var { firstTwoWeeksList, selectedSocial } = this.state;
        selectedSocial.push(prepareData);
        selectedSocial = this.removeDuplicate(selectedSocial);

        firstTwoWeeksList.push(prepareData)
        firstTwoWeeksList = this.removeDuplicate(firstTwoWeeksList);

        this.setState({
            selectedSocial, firstTwoWeeksList,
            firstTwoWeeksNewbuttonFlag: true
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onPressNewButton(key) {
        var states = this.state;
        states[key] = false;
        this.setState({ states })
    }


    onPressButton(key, selectedValue) {

        if (key == "selectedSocial") {

            var { selectedSocial } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedSocial.length > 0) {
                for (var i = 0; i < selectedSocial.length; i++) {
                    if (selectedSocial[i].value == selectedValue.value) {
                        spliceData = selectedSocial[i];
                        selectedSocial.splice(i, 1)
                        var data = this.removeDuplicate(selectedSocial);
                        this.setState({ selectedSocial: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedSocial.push(selectedValue); }
            } else {
                selectedSocial.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedSocial);
            this.setState({ selectedSocial: data })

        } else {
            var states = this.state;
            states[key] = selectedValue
            this.setState({ states })
        }
        this.setState({
            firstTwoWeeksNewbuttonFlag: true
        })
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }


    clearAllData() {
        var states = this.state;

        states['id'] = ""
        states['selectedSocial']=[]
        states['detailsOfFamily'] = ""
        states['detailsOfFriends'] = ""
        states['detailsOfClubs'] = ""
        states['detailsmutualWork'] = ""
        states['detailsOfHobbies'] = ""
        states['detailsOfInterests'] = ""
        states['detailsOfPlay'] = ""
        states['detailsOfTalent'] = ""
        states['detailsOfMood'] = ""
        states['detailsOfCharacter'] = ""
        states['social_relationship'] = []

        this.setState({ states })
    }

    getListOfTrimesterValue(list) {
        var data;
        if (list.length > 0) {
            var getList = []
            for (let i = 0; i < list.length; i++) {
                getList.push(list[i].value)
            }
            data = getList.toString()
        } else {
            data = ""
        }
        return data
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "social_relationship": this.getListOfTrimesterValue(states.selectedSocial),
                "family_relation": states.detailsOfFamily,
                "friends_relation": states.detailsOfFriends,
                "clubs": states.detailsOfClubs,
                "work": states.detailsmutualWork,
                "hobbies": states.detailsOfHobbies,
                "interests": states.detailsOfInterests,
                "play": states.detailsOfPlay,
                "talent": states.detailsOfTalent,
                "mood_detail": states.detailsOfMood,
                "character_detail": states.detailsOfCharacter
            }



            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PRE_MORBID_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savesexualhistorySuccess,
                this.savesexualhistoryFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Pre-morbid History already added please click on edit icon to update the values")

        }
    }

    savesexualhistorySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savesexualhistoryFailure = error => { }

    render() {


        const historyDropDownList = [
            { label: "Select", value: 0 },
            { label: "Menarche", value: 1 },
            { label: "Regularity", value: 2 },
            { label: "Dysmenorechoea", value: 3 },
            { label: "Monorehajinal otigonerchea", value: 4 },
        ]

        const marriageType = [
            { label: "Select", value: 0 },
            { label: "Arranged", value: 1 },
            { label: "Love", value: 2 },

        ]

        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Pre-Morbid Personality"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Social relationship", "Enter details",
                    "", true,
                    "", "",
                    "selectedSocial", this.state.selectedSocial, this.state.selectedSocialList, false,
                    "", ""
                )}



                {this.renderHistoryComponent(
                    "Family", "Enter details",
                    "", false,
                    "detailsOfFamily", this.state.detailsOfFamily
                )}
                {this.renderHistoryComponent(
                    "Friends", "Enter details",
                    "", false,
                    "detailsOfFriends", this.state.detailsOfFriends
                )}
                {this.renderHistoryComponent(
                    "Clubs", "Enter details",
                    "", false,
                    "detailsOfClubs", this.state.detailsOfClubs
                )}
                {this.renderHistoryComponent(
                    "Work", "Enter details",
                    "", false,
                    "detailsmutualWork", this.state.detailsmutualWork
                )}

                {this.renderHistoryComponent(
                    "Hobbies", "Enter details",
                    "", false,
                    "detailsOfHobbies", this.state.detailsOfHobbies
                )}


                {this.renderHistoryComponent(
                    "Interests", "Enter details",
                    "", false,
                    "detailsOfInterests", this.state.detailsOfInterests
                )}

                {this.renderHistoryComponent(
                    "Play", "Enter details",
                    "", false,
                    "detailsOfPlay", this.state.detailsOfPlay
                )}

                {this.renderHistoryComponent(
                    "Talent", "Enter details",
                    "", false,
                    "detailsOfTalent", this.state.detailsOfTalent
                )}


                {this.renderHistoryComponent(
                    "Mood", "Enter details",
                    "", false,
                    "detailsOfMood", this.state.detailsOfMood
                )}

                {this.renderHistoryComponent(
                    "Character", "Enter details",
                    "", false,
                    "detailsOfCharacter", this.state.detailsOfCharacter
                )}




                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


            </View>
        )
    }
}
