import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
import { CommonHistorySectionHeader } from '../BaseComponent'

const platform = Platform.OS;
const pageTitle = "Examination";
const pageName = "examination";
const isPageName = "isExamination";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      Examinationdata: [],
      isExamination: isPageName === this.props.selectedView ? true : false,
      isTemplate: false,
      templateName: "",
      favouriteID: "",
      id: "",
    };
  }

  componentDidMount() {

    this.getExamination();

  }

  componentWillReceiveProps(props) {

    if (props.reloadFavouriteID && props.reloadFavouriteID === pageName) {
      if (props.ExaminationID) {
        this.setState({
          favouriteID: props.ExaminationID
        }, () => {
          this.getExaminationTemplate()
          this.props.clearreloadFavouriteID();


        })

      }
    }

    if (props.reloadData && props.reloadData === pageName) {
      // call once

      this.getExamination();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isExamination) {

      this.setState({ isExamination: updateFontColor });
    }
  }

  getExaminationTemplate = () => {
    var service_url = Constants.EXAMINATION_FAVORITE_NAME + "?favorite_id=" + this.props.ExaminationID;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationTemplateSuccess,
      this.getExaminationTemplateFailure
    );
  };

  getExaminationTemplateSuccess = response => {
    if (response.status === "success") {
      var field = this.state
      field["Examinationdata"] = response.data;
      this.setState({
        field
      }, () => {
        this.addBulkExamination()
      })

    }
  };


  getExaminationTemplateFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };



  saveTemplate = () => {

    var examinationData = this.state.Examinationdata;
    var examinations = []

    for (let i = 0; i < examinationData.length; i++) {
      var prepareData = {
        "examination_id": examinationData[i].examination_id,
        "value": examinationData[i].value,
        "display_order": examinationData[i].display_order
      }
      examinations.push(prepareData)
    }

    let states = this.state;
    var service_url = Constants.EXAMINATION_FAVORITE_NAME;

    let data = {
      "favorite_name": states.templateName,
      "examinations": examinations
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getTemplateSuccess,
      this.getTemplateFailure,

    );

  };

  getTemplateSuccess = response => {
    if (response.status === "success") {

      this.props.reloadTemplate(pageName);
      this.props.showResposeValue("success", response.message)
      this.setState({
        isTemplate: false
      })
    } else {
      this.props.showResposeValue("error", response.message)

    }
  }


  getTemplateFailure = error => {
    this.props.showResposeValue("error", error.message)

  };

  addBulkExamination = () => {

    var examinationData = this.state.Examinationdata;
    var examinations = []

    for (let i = 0; i < examinationData.length; i++) {
      var prepareData = {
        "examination_id": examinationData[i].examination_id,
        "value": examinationData[i].value,
        "display_order": examinationData[i].display_order

      }
      examinations.push(prepareData)
    }



    var service_url = Constants.EXAMINATION_BULK_SAVE;



    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "examinations": examinations
    };
    if (this.state.id) {
      data["id"] = this.state.id
    }



    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addBulkExaminationSuccess,
      this.addBulkExaminationError
    );
    // }
  };

  addBulkExaminationSuccess = response => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)
      this.getExamination();
    } else {
      this.props.showResposeValue("error", response.message)

    }

  }

  addBulkExaminationError = error => {
    this.props.showResposeValue("error", error.message)
  }


  getExamination = () => {

    var service_url = Constants.EXAMINATION_GET_NAME +"?appointment_id="+ this.state.patientAppointment.appointment_id + "&new_system=" + true;


    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationSuccess,
      this.getExaminationFailure
    );
  };

  getExaminationSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["Examinationdata"] = response.data.examinations;
      this.setState({ field });

    }
  };

  getExaminationFailure = error => {
  //  console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderExaminationItem = (item, index) => {
    return (



      <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: '0.5vw', borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>
        <Text style={[Style.contentTableDataLead, { flex: 0.28, marginHorizontal: '1.2vw' }]}>{item.examination_name}</Text>
      <View style={{ flex: 0.60,flexWrap:"wrap" }}>
        <Text style={[Style.contentTableDataLead]} numberOfLines={5}> {item.value} </Text>
      </View>  
        <View style={{ flex: 0.12, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '0.6vw' }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
            {this.renderIdButton("pencil")}
          </TouchableOpacity>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
            {this.renderIdButton("trash")}
          </TouchableOpacity>
        </View>
      </View>



      // <View>
      //   <View style={{ paddingTop: 5 }}>
      //     <View
      //       style={{
      //         marginLeft: 5,
      //         width: platform === "web" ? "100%" : "75%",
      //         flexDirection: "row",
      //         justifyContent: "space-between",
      //         // height: 50,
      //         alignItems: "center"
      //         // paddingBottom: 10
      //       }}
      //     >
      //       <View
      //         style={{
      //           width: "30%"
      //         }}
      //       >
      //         <Text style={Style.contentTableDataLead}>
      //           {item.examination_name}
      //         </Text>
      //       </View>

      //       <View
      //         style={{
      //           width: "20%"
      //           // alignItems: "center"
      //         }}
      //       >
      //         <Text style={Style.contentTableData}>
      //           {item.value}
      //         </Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "30%"
      //         }}
      //       >
      //         <Text style={Style.contentTableData}>

      //         </Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "20%",
      //           alignItems: "center",
      //           height: 60,
      //           flexDirection: "row"
      //         }}
      //       >
      //         <View
      //           style={{
      //             flexDirection: "row",
      //             paddingTop: 5,
      //             paddingBottom: 5,
      //             backgroundColor: color.white,
      //             height: 50
      //           }}
      //         >
      // <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
      //   {this.renderIdButton("pencil")}
      // </TouchableOpacity>

      // <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
      //   {this.renderIdButton("trash")}
      // </TouchableOpacity>
      //         </View>
      //       </View>
      //     </View>
      //   </View>
      //   <View style={Style.sectionItemLine} />
      // </View>
    );
  };

  // getComplaintsType (type) {

  //   let itemType = "";

  //   let temp = Constants.eye_values.map((item,index)=>{

  //     if(type === item.value) { itemType = item.label; }
  //     return (
  //       null
  //     )
  //   })
  //   return itemType;
  // }

  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);

    this.props.editItem(pageName, item, "", "", true);

  }

  deleteItem = (deleteItem, event) => {

    var service_url = Constants.EXAMINATION_DATA_DELETE;
    let data = {

      "appointment_id": this.state.patientAppointment.appointment_id,
      "patient_examination_id": deleteItem.id

    };



    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getDeleteFailure
    );
  };

  getDeleteSuccess = response => {
    if (response.status === "success") {

      this.getExamination();
    }
  };
  getDeleteFailure = error => {

  };


  triggerNewItem = (key) => {

    this.props.triggerNewItem(key);
  }

  render() {
    return (
      <View style={{}} >
        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
          {this.renderTitleBorder(this.state.isExamination, pageTitle, isPageName, this.triggerNewItem)}
          <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'flex-end', alignSelf: 'flex-end', }}>
            <View style={styles.AddTemplateView}>
              {!this.state.isTemplate && this.state.Examinationdata.length > 0 ?

                <View>
                  <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                    this.setState({
                      isTemplate: true
                    })
                  }} style={[styles.AddTemplateTouch, Style.allButtonBorderRadius, {backgroundColor: color.themeDark}]}>
                    <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Add to templates"}</Text>
                  </TouchableOpacity>

                </View>




                : null}
            </View>
            {this.state.isTemplate ?


              <View>


                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.setState({
                    isTemplate: false
                  })
                }} style={[styles.AddTemplateTouch, {backgroundColor: color.themeDark}, Style.allButtonBorderRadius]}>
                  <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Cancel"}</Text>
                </TouchableOpacity>
              </View>

              : null}

            {this.state.isTemplate ?
              <View style={styles.TemplateView}>
                <TextInput
                  placeholder={"Template Name"}
                  mode="flat"
                  underlineColor='white'
                  multiline={true}
                  style={[styles.TemplateTextInput, Style.allButtonBorderRadius]}

                  ref={text => (this.nameInput = text)}
                  // defaultValue=""
                  autoCapitalize="none"
                  value={this.state.templateName}
                  onChangeText={text => this.setState({ templateName: text })}
                />

              </View> : null}
            {this.state.isTemplate && this.state.templateName ?
              <View>

                <View>
                  <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                    this.saveTemplate()
                  }} style={[styles.SaveBtnTouch, {backgroundColor: color.themeDark}]}>
                    <Text style={{ fontSize: '1vw', color: color.white, textAlign: 'center' }}>{"Save"}</Text>
                  </TouchableOpacity>
                </View>

              </View> : null}
          </View>
        </View>

        <View style={styles.TableHeadView1}>
          <CommonHistorySectionHeader
            heading1={"Name"}
            heading2={"Description"}
            heading3={"Action"}
            columnSize={[0.28, 0.60, 0.12]}  //total value is == of 1
            noOfColumn={3}
          />
        </View>

        {/* <View style={{ marginTop: 20 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={Style.contentTableTitle}>Name</Text>
            </View>

            <View
              style={{
                width: "20%"
              }}
            >
              <Text style={Style.contentTableTitle}>Description</Text>
            </View>
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={Style.contentTableTitle}></Text>
            </View>
            <View
              style={{
                width: "20%",
                alignItems: "center",
                backgroundColor: color.white,
                height: 50
              }}
            />
          </View>
        </View> */}

        <FlatList
          data={this.state.Examinationdata}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderExaminationItem(item, index)
          }
        />

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
        {/* <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'flex-end', alignSelf: 'flex-end', marginTop: 20 }}>
          <View style={{ marginRight: 30 }}>
            {!this.state.isTemplate && this.state.Examinationdata.length > 0 ?

              <View>
                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.setState({
                    isTemplate: true
                  })
                }} style={[{
                  marginLeft: 20, paddingTop: 8, paddingLeft: 18,
                  paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
                }, Style.allButtonBorderRadius]}>
                  <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Add to templates"}</Text>
                </TouchableOpacity>

              </View>




              : null}
          </View>
          {this.state.isTemplate ?


            <View>


              <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                this.setState({
                  isTemplate: false
                })
              }} style={[{
                marginLeft: 20, paddingTop: 8, paddingLeft: 18,
                paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
              }, Style.allButtonBorderRadius]}>
                <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Cancel"}</Text>
              </TouchableOpacity>
            </View>

            : null}

          {this.state.isTemplate ?
            <View style={{ marginRight: 30, marginLeft: 30 }}>
              <TextInput
                placeholder={"Template Name"}
                mode="flat"
                underlineColor='white'
                multiline={true}
                style={[{
                  height: 35,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: '#B3D9FE',
                  primary: "white",
                  borderRadius: 10,
                  padding: 5
                }, Style.allButtonBorderRadius]}

                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.templateName}
                onChangeText={text => this.setState({ templateName: text })}
              />

            </View> : null}
          {this.state.isTemplate && this.state.templateName ?
            <View>

              <View>
                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.saveTemplate()
                }} style={{ paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 8, backgroundColor: color.themeDark, }}>
                  <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Save"}</Text>
                </TouchableOpacity>
              </View>

            </View> : null}
        </View> */}
      </View>
    );
  }
}
const styles = StyleSheet.create({
      AddTemplateView: { 
        marginRight: '2vw' 
      },
      AddTemplateTouch: {
        marginLeft: '1.2vw', 
        paddingTop: '0.5vw', 
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw', 
        paddingBottom: '0.5vw', 
      },
      TemplateView: { 
        marginRight: '2vw', 
        marginLeft: '2vw' 
      },
      TemplateTextInput: {
        height: '2.3vw',
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#B3D9FE',
        primary: "white",
        borderRadius: '0.6vw',
        padding: '0.3vw'
      },
      SaveBtnTouch: { 
        paddingBottom: '0.6vw', 
        paddingTop: '0.6vw', 
        width: '5.5vw', 
        justifyContent: 'center', 
        alignContent: 'space-between', 
        borderRadius: '0.5vw', 
      },
      TableHeadView1: { 
        marginTop: '0.6vw' 
      }
})