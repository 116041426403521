import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import Reports from '../../common/reports/index';
const navigationHeader = [
    { label: "Vitals", value: "oTVitals" },
    { label: "Reports", value: "oTReports" },
    { label: "Surgery Notes", value: "oTSurgeryNotes" },
    { label: "Billing", value: "oTBilling" }
]
export default class OTReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"oTReports"}
          isNavigationBorder={true}
        />
        <Reports/>
        <Text> OTReports </Text>
      </View>
    );
  }
}
