import React, { Component } from "react";
import { View, StyleSheet, Picker, TouchableOpacity, Text, Image } from "react-native";
import {AllBillingStatistics} from "./Billing/AllBillingStatistics";
import InPatientBilling from "./Billing/InPatientBilling";
import OutPatientBilling from "./Billing/OutPatientBilling";
import LaboratoryBilling from "./Billing/LaboratoryBilling";
import PharmacyBilling from "./Billing/PharmacyBilling";
import CommonDateFilter, { NavigationTopHeader } from "../BaseComponent";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import moment from "moment";
import {Tooltip} from 'antd'
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
const navigationHeader = [{ label: "Billing", value: "allBillingStatistics" }];
const navigationSubHeader = [
  { label: "All Billing Transaction", value: "allBillingStatistics" },
  { label: "InPatient Billing Transaction", value: "inPatientBilling" },
  { label: "OutPatient Billing Transaction", value: "outPatientBilling" },
  { label: "Pharmacy Billing Transaction", value: "pharmacyBilling" },
  { label: "Laboratory Billing Transaction", value: "laboratoryBilling" },
];
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedTab: this.props.selectedSubScreen ? this.props.selectedSubScreen : "allBillingStatistics",
        userType: this.props.userType,
        filterDate: {
            fromDate: this.props.statisticsFromDate ? this.props.statisticsFromDate : "",
            toDate: this.props.statisticsToDate ? this.props.statisticsToDate : ""
         },
         branchList: "",
         selectedBranch: this.props.statisticsBranchId ? this.props.statisticsBranchId : "all",
         grandTotal: 0,
         inPatientTotal: 0,
         outPatientTotal: 0,
         pharmacyTotal: 0,
         laboratoryTotal: 0,
         dateFilterstatus: this.props.dateFilterstatus ? this.props.dateFilterstatus : "Today",
         totalCash: 0,
         totalCard: 0,
         totalUPI: 0,
         totalBankTransfer: 0,
         totalInsurance: 0,
    };
  }
  componentDidMount() {
    if(this.state.userType === "SUPER_ADMIN"){
      this.getBranchName()
    }
  }
  getBranchName = () => {
    
    var url = Constants.GET_BRANCH_NAME
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getBranchNameSuccess,
      this.getBranchNameFailure
    );
  }

  getBranchNameSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        branchList: response.data
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  getBranchNameFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  showResposeValue = (title, message) => {
    this.props.showResposeValue(title, message)
  }
//   renderSelectedTab = (data, indentNumber) => {
//     this.setState({
//       selectedTab: data,
//     })
//   }
  changeTabSection = (data) =>{
        this.setState({
            selectedTab: data,
        })    
  }
  showSelectedFilteredDate(date) {
    var states = this.state.filterDate;
    states["filterDate"] = date
    states["fromDate"] = date.fromDate;
    states["toDate"] = date.toDate;
    this.setState({ states },() => {
    //   this.ge/tAllBillingTransaction()
    })
  }
  exportAllBilling = () => {
    var billType = this.state.selectedTab === "inPatientBilling" ? "in_patient" : this.state.selectedTab === "outPatientBilling" ? "out_patient" : this.state.selectedTab === "pharmacyBilling" ? "pharmacy" : this.state.selectedTab === "laboratory" ? "laboratory" : "all"
    var serviceUrl = Constants.GET_BILLING_TRANSACTION + "?branch_id=" + this.state.selectedBranch + "&bill_type=" + billType + "&start_date=" + this.state.filterDate.fromDate + "&end_date=" + this.state.filterDate.toDate + "&export_type=excel"
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.exportAllBillingSuccess,
      this.exportAllBillingFailure,
      "excel"
    );
  }
  exportAllBillingSuccess = (success) => {
    if (success) {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  exportAllBillingFailure = (error) => {
    console.log(error)
  }
   // Amount Formet Function start
   DecimalAmount = (Useramount) => {
    var amount = String(Useramount).split(".")[0];
    var AfterDecimal = String(Useramount).split(".")[1]
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
  }
  WholeAmount = (Useramount) => {
    var amount = Useramount;
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  }

  TooltipChange = (value) => {
    if (value % 1 != 0) {
      return this.DecimalAmount(value)
    }
    else if (value % 1 == 0) {
      return this.WholeAmount(value)
    }
  }
  renderTotalAmounts = (color, icon, value, placehold) => {
    return (
      <View style={[styles.CardContainer, { backgroundColor: color }]}>
        <View style={styles.SubContainer}>
          <View style={styles.cardTooltipDiv} >
            {value && (value.toString().length) > 10 ?
              <Tooltip placement="topLeft" title={this.TooltipChange(value)}>
                <Text style={styles.TooltipText}>
                  {value && value.toString().length > 10 ? value && this.TooltipChange((value.toString()).slice(0, 10)) + ".." : ''}
                </Text>
              </Tooltip> :
              <Text style={{ color: "white", fontSize: value && value.toString().length > 8 ? ".8vw" : "1.01vw", fontWeight: "bold" }}>{value ? this.TooltipChange(value) : 0}</Text>
            }
          </View>
          <View style={{ marginRight: ".3vw" }}>
            <Image
              style={styles.ImageStyles}
              source={icon}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
          <Text style={{ color: "white", fontSize: ".6vw" }}>{placehold}</Text>
        </View>
      </View>
    )
  }
  renderFilterView = () => {
    return (
      <View style={styles.filterContainer}>
       <View style = {{ flexDirection: "row" }}> 
        <View>
            { this.state.selectedTab === "allBillingStatistics" ? 
                <View>{this.renderTotalAmounts(color.cardGreen, require("../../../../assets/images/IPActions/Rupee.png"), this.state.grandTotal, "Total Bill")}</View> : this.state.selectedTab === "inPatientBilling" ? 
                <View>{this.renderTotalAmounts(color.cardGreen, require("../../../../assets/images/IPActions/Rupee.png"), this.state.inPatientTotal, "In Patient Total")}</View> : this.state.selectedTab === "outPatientBilling" ? 
                <View>{this.renderTotalAmounts(color.cardGreen, require("../../../../assets/images/IPActions/Rupee.png"), this.state.outPatientTotal, "Out patient Total")}</View> : this.state.selectedTab === "pharmacyBilling" ? 
                <View>{this.renderTotalAmounts(color.cardGreen, require("../../../../assets/images/IPActions/Rupee.png"), this.state.pharmacyTotal, "Pharmacy Total")}</View> : this.state.selectedTab === "laboratoryBilling" ? 
                <View>{this.renderTotalAmounts(color.cardGreen, require("../../../../assets/images/IPActions/Rupee.png"), this.state.laboratoryTotal, "Laboratory Total")}</View> : null
            }
        </View> 
        <View>{this.renderTotalAmounts(color.cardPurple, require("../../../../assets/images/IPActions/Rupee.png"), this.state.totalCash, "Cash")}</View>
        <View>{this.renderTotalAmounts(color.cardOrange, require("../../../../assets/images/IPActions/Rupee.png"), this.state.totalCard, "Card")}</View>
        <View>{this.renderTotalAmounts(color.cardBlue, require("../../../../assets/images/IPActions/Rupee.png"), this.state.totalUPI, "UPI")}</View>
        <View>{this.renderTotalAmounts(color.cardOrangeShade, require("../../../../assets/images/IPActions/Rupee.png"), this.state.totalBankTransfer, "Bank Transfer")}</View>
        <View>{this.renderTotalAmounts(color.grayShade, require("../../../../assets/images/IPActions/Rupee.png"), this.state.totalInsurance, "Insurance")}</View>
       </View> 
       <View  style = {{ flexDirection: "row" }}>
       { this.state.userType === "SUPER_ADMIN" ?
        <Picker
          selectedValue={this.state.selectedBranch}
          style={[
            Style.historyYesOrNoSectionShadow,
            Style.allButtonBorderRadius,
            styles.pickerStyle
          ]}
          itemStyle={{ fontSize: "0.8vw" }}
          onValueChange={(itemvalue) => {
            this.setState({
                selectedBranch: itemvalue,
            },() => {

            });
          }}
        >
           <Picker.Item label={"All"} value={"all"} />
           { this.state.branchList && this.state.branchList.length > 0 && this.state.branchList.map((i, index) => (
              <Picker.Item key={index} label={i.name} value={i.id} />
            ))}          
        </Picker> : null }
        <View style= {{ marginRight: "0.8vw" }}>
        <CommonDateFilter
          // removeObject={"all"}
          defaultSelectedDateFilter={this.state.dateFilterstatus}
          startDate={this.state.filterDate.fromDate}
          endDate={this.state.filterDate.toDate}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
          filterDate={this.state.filterDate} />
        </View>  
        {/* <TouchableOpacity
          style={styles.searchContainer}
          onPress={() => {
            // this.getPurchaseIndentList()
          }}>
          <Icon name='search' size={"1.2vw"} color={color.white}></Icon>
        </TouchableOpacity> */}
        <TouchableOpacity
          style={styles.printIconWrapper}
          onPress={() => {
            this.exportAllBilling()
          }}>
          <Image style={styles.Image} source={require('./../../../../assets/images/Active/Export.png')} />
        </TouchableOpacity>
        </View>
      </View>
    );
  };
  getTotalAmounts = (grand_total, inPatientTotal, outPatientTotal, pharmacyTotal, laboratoryTotal, cashAmount, cardAmount, upiAmount, bank_transfer, insurance) => {
      this.setState({
         grandTotal: grand_total,
         inPatientTotal: inPatientTotal,
         outPatientTotal: outPatientTotal,
         pharmacyTotal: pharmacyTotal,
         laboratoryTotal: laboratoryTotal,
         totalCash: cashAmount,
         totalCard: cardAmount,
         totalUPI: upiAmount,
         totalBankTransfer: bank_transfer,
         totalInsurance: insurance
      })
  }
  render() {
    return (
      <View style={styles.mainContainer}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"allBillingStatistics"}
          isNavigationBorder={true}
        />
        <View style={{ width: "100%" }}>
            <NavigationTopHeader
              changeTabSection={this.changeTabSection.bind(this)}
              navigationHeaderList={navigationSubHeader}
              selectedTab={this.state.selectedTab}
              // isNavigationBorder={true}
            />
          </View>
          <View style = {{ zIndex: 1 }}>{this.renderFilterView()}</View>
        <View style = {{ zIndex: 0 }}>
        {this.state.selectedTab === "allBillingStatistics" ? (
          <AllBillingStatistics
            renderSelectedTab = {this.changeTabSection}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            selectedBranch = {this.state.selectedBranch}
            startDate={this.state.filterDate.fromDate}
            endDate={this.state.filterDate.toDate}
            getTotalAmounts = {this.getTotalAmounts}
          />
        ) : this.state.selectedTab === "inPatientBilling" ? (
          <InPatientBilling
            renderSelectedTab = {this.changeTabSection}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            selectedBranch = {this.state.selectedBranch}
            startDate={this.state.filterDate.fromDate}
            endDate={this.state.filterDate.toDate}
            getTotalAmounts = {this.getTotalAmounts}
          />
        ) : this.state.selectedTab === "outPatientBilling" ? (
          <OutPatientBilling
            renderSelectedTab = {this.changeTabSection}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            selectedBranch = {this.state.selectedBranch}
            startDate={this.state.filterDate.fromDate}
            endDate={this.state.filterDate.toDate}
            getTotalAmounts = {this.getTotalAmounts}
          />
        ) : this.state.selectedTab === "pharmacyBilling" ? (
          <PharmacyBilling
            renderSelectedTab = {this.changeTabSection}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            selectedBranch = {this.state.selectedBranch}
            startDate={this.state.filterDate.fromDate}
            endDate={this.state.filterDate.toDate}
            getTotalAmounts = {this.getTotalAmounts}
          />
        ) : (
          <LaboratoryBilling
            renderSelectedTab = {this.changeTabSection}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            selectedBranch = {this.state.selectedBranch}
            startDate={this.state.filterDate.fromDate}
            endDate={this.state.filterDate.toDate}
            getTotalAmounts = {this.getTotalAmounts}
          />
        )}
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
    CardContainer: { marginRight: ".65vw", borderRadius: ".25vw", paddingVertical: ".25vw", maxHeight: "2.95vw" },
    SubContainer: { flexDirection: "row", justifyContent: "flex-end", minWidth: "7.2vw" },
    cardValue: { color: "white", fontSize: "1.05vw", fontWeight: "bold" },
    ImageStyles:{ 
        width : "1.25vw", 
        height : "1.25vw",
        marginTop: "0.2vw"  
      },
    mainContainer: {
      margin: "0.7vw",
      marginVertical: "0vw",
      height: "81vh",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "center",
      zIndex: 0
    },
    subContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      backgroundColor: color.sectionHeadingDarkColor,
      padding: 8,
      borderRadius: 4,
      marginTop: "0.5vw",
    },
    headersContainer: { marginHorizontal: "0.7vw" },
    filterContainer: { margin: "0.7vw", justifyContent: "space-between", flexDirection: "row", zIndex: 1  },
    pickerStyle:  {
      backgroundColor: color.white,
      height: "2.4vw",
      marginRight: "0.8vw",
      width: "8vw",
      fontSize: "1vw",
    },
    searchContainer: { height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center", borderRadius: 4, marginRight: "0.8vw" },
    exportContainer: { height: "5vh", width: "3vw", justifyContent: "center", alignItems: "center" },
    paginationContainer: {
      marginHorizontal: "1.2vw"
    },
    dataContainer: {
      flexDirection: 'row',
      borderBottomColor: color.lightGray,
      borderBottomWidth: 1,
      paddingVertical: "0.6vw",
      justifyContent: 'center',
      flex: 1
    },
    TableDataText:{
      fontSize:'1vw'
    },
    dataViewContainer: { zIndex: -1, paddingHorizontal: "0.5vw" },
    sortContainer: { paddingLeft: "1vw", width: "20%", alignItems: "flex-start", marginTop: "0.2vw" },
    Image: {
        width: "1.95vw",
        height: "1.95vw",
    },
    printIconWrapper:{ 
        width: "2.60", 
        height: "2.3vw", 
        borderRadius: ".25vw", 
        backgroundColor: color.themeBackgroud, 
        paddingHorizontal: ".3vw", 
        paddingVertical: ".15vw" 
    },
    cardTooltipDiv:{ 
      marginRight: ".3vw", 
      paddingLeft: ".3vw" 
    },
    TooltipText:{ 
      color: "white", 
      fontSize: "1vw", 
      fontWeight: "bold" 
  },
  });