// need to chenge clander and set the calander date ref(getCreatedPatientDetailSuccess)

import React, { Component } from 'react';
import { View, Text, Switch, TextInput, TouchableWithoutFeedback, FlatList, Image, TouchableOpacity, Dimensions, StyleSheet, Platform, Picker, ScrollView } from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from '../../../styles/Color';
import Style from "../../../styles/Style";
// import Arrow from "react-native-vector-icons/FontAwesome5";

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import Icon from "react-native-vector-icons/AntDesign";
import PlusIcon from 'react-native-vector-icons/Fontisto';

import IDProofImage from "react-native-vector-icons/AntDesign";
// import { ScrollView } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants"
import moment from "moment";
import OpthamologyService from "../../../network/OpthamologyService"
import Service from "../../../network/Service"
import * as ImagePicker from 'expo-image-picker';

import AsyncStorage from "../../../AsyncStorage"
import axios from "axios"
import { CommonPicker } from './../BaseComponent'
import { Tooltip } from 'antd';
// import TakePicker from "../../camera/TakePicker";
// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// for camera
import * as Permissions from 'expo-permissions';
import { UsergroupAddOutlined } from '@ant-design/icons';
// import { FormatListNumberedRtl } from '@material-ui/icons';
const screenHeight = Dimensions.get("window").height;

export default class Demographics extends BaseComponentStyle {
  constructor(props) {
    super(props);


    let userType = '';

    try {
      userType = this.props.userType;
    } catch (error) { }

    let selectedClinicOrDoctor = {}
    try {
      selectedClinicOrDoctor = this.props.selectedClinicOrDoctor;
    } catch (error) { }


    this.state = {
      isIpPatientCheckouted: this.props.isIpPatientCheckouted,
      searchPatientData: [],
      isCreatePatientClicked: false,
      errorColor: false,
      toggleSwitchFlag: true,
      checkPatientId: "",    // not using 
      aadharCard: false,
      drivingLicence: false,
      voterId: false,
      passport: false,
      rationCard: false,
      bankId: false,
      visa: false,
      visaValidity: false,
      userType: userType,
      currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss "),
      mandatoryInformation: {
        type: "",
        gender: "",
        firstName: "",
        surName: "",
        // dob: new Date(),
        dob: "",
        age: "",
        approx_dob: "",
        maritalStatus: "",
        occupation: "",
        mobileNo: "",
        receiveSMSFlag: true,
        country: "",
        fatherOrHusband: "",
        state: "",
        profilePhoto: "",
        refferedBy: "",
        mobileNo2: "",
        referralsource: "",
        isAmaPatient: false,
        guardian: ""
      },

      otherInformation: {
        emailID: "",
        address: "",
        address2: "",
        city: "",
        pincode: "",
        employer_id: '',
        employee_ID_No: ""
      },

      careTakerInformation: {
        firstName: "",
        mobileNo: "",
        relationship: "",
        emailID: "",
        address: "",
        city: "",
        pincode: ""
      },

      idProof: {
        aadharCard: "",
        drivingLicence: "",
        voterId: "",
        passport: "",
        rationCard: "",
        bankId: "",
        visa: "",
        visaValidity: ""
      },
      idProofDocs: {
        aadharCard: "",
        drivingLicence: "",
        voterId: "",
        passport: "",
        rationCard: "",
        bankId: "",
        visa: ""
      },
      collectedIdProofs: [],
      selectedDateAndTime: "",
      ShowCreateappointment: false,
      patientId: "",
      patientDetails: {},
      showNextAppoinmentView: false,
      purposeOfVisitsInNextAppoinment: "",
      clinicList: [],
      selectedClinic: {},

      // refferal array
      refferalArray: [],

      // calender
      currentTime: "",
      dropDownViewFlag: false,
      getMonth: "",
      getYear: "",
      getDate: 0,
      dropDownView: "",
      // day1: 10,
      // day2: 11,
      // day3: 12,
      // day4: 13,
      // day5: 14,
      // totalMonths: [
      //   { "month": "January", "totalDays": 31 },
      //   { "month": "February", "totalDays": 28 },
      //   { "month": "March", "totalDays": 31 },
      //   { "month": "April", "totalDays": 30 },
      //   { "month": "May", "totalDays": 31 },
      //   { "month": "June", "totalDays": 30 },
      //   { "month": "July", "totalDays": 31 },
      //   { "month": "August", "totalDays": 31 },
      //   { "month": "September", "totalDays": 30 },
      //   { "month": "October", "totalDays": 31 },
      //   { "month": "November", "totalDays": 30 },
      //   { "month": "December", "totalDays": 31 },
      // ],
      // changedMonthDays: 0,
      // chengedMonthInNumber: 0,
      // changedDay: 0,
      showNextAppoinmentTimer: false,
      hour12Formet: 0,
      minutes: 0,
      findAmPm: '',
      // calender

      isDatePickerVisible: true,
      setDatePickerVisibility: true,

      successOrErrorMessage: "",
      successMessageFlag: false,
      errorMessageFlag: false,

      documentUploadSelectedButton: "",

      patientInfo: {},
      patientAppointment: {},
      alreadyCreatedPatient: false,


      curretlySelectedIdproof: "",
      enteredIdProofValues: [],
      modalVisible: false,
      nextAppoinmentatPientDetails: {},


      stateList: [],
      dropDownEditFlag: false,

      showPickerDropDownFlag: false,
      doctorData: [],
      selectedDoctor: "",

      restrictCreateAppointmentModal: true,
      // for camera
      enableCameraModalFlag: false,
      hasPermission: false,
      isVisaEnable: false,
      countryList: [],
      defaultSelection: {},
      selectedTab: "insurance",
      isInsuranceAddClick: false,
      isCorporateAddClick: false,
      insuranceCompanyName: "",
      corporateCompanyName: "",
      insurancePolicyNumber: "",
      corporateEmployeeId: "",
      insuranceData: [],
      corporateData: {},
      selectedDeleteId: null,
      DeleteConfirmationPopup: false,
      AddingServiceType: "",
      isAddInsurancePopup: false,
      successOrErrorMessageLeft: "",
      successMessageFlagLeft: false,
      errorMessageFlagLeft: false,
      selectedClinicOrDoctor: selectedClinicOrDoctor,
      referredByList: [],
      isRefferedBySuggestionOpen: false,
      isAddNewReferredBy: false,
      referredDocHospital: "",
      referredDocSpecialization: "",
      referredDocQualification: "",
      referredDocName: "",
      referredDocMobNumber: "",
      referredDocRegNumber: "",

      // View Demographics
      isRoleAdmin: true,
      isEditAction: false,

      corpCompanyList: [],
      MandatoryFields:{},
      FO_admin_setting : {},

    };

    // 
    // var today = new Date();
    // var date = today.getDate();
    // var month = today.getMonth();
    // var year = today.getFullYear();
    // for (let i = 0; i < this.state.totalMonths.length; i++) {
    //   if (month === i) {
    //     this.state.chengedMonthInNumber = i + 1;
    //     this.state.changedMonthDays = this.state.totalMonths[i].totalDays;
    //     this.state.getMonth = this.state.totalMonths[i].month;
    //     this.state.getYear = year;
    //     this.state.getDate = date;
    //     this.state.day1 = date - 2;
    //     this.state.day2 = date - 1;
    //     this.state.day3 = date;
    //     this.state.day4 = date + 1;
    //     this.state.day5 = date + 2;
    //     this.state.changedDay = date;
    //   };
    // }
    // 

  }

  triggerNewItem = (key) => {
    // this.props.triggerNewItem(key);
  }



  // getState() {
  //   var url = Constants.GET_STATE;
  //   OpthamologyService.getInstance().getComplaints(
  //     url,
  //     this,
  //     this._getStateSuccessCallBack,
  //     this._getStateErrorCallBack
  //   );
  // }
  // _getStateSuccessCallBack = success => {
  //   if (success.status == "success") {
  //     this.setState({
  //       stateList: success.data
  //     })
  //   }
  // }
  // _getStateErrorCallBack = erroe => {

  // }

  getCreatedPatientDetail(patient_id) {
    var service_url = Constants.DR_CREATE_PATIENT + "?patient_id=" + patient_id ;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCreatedPatientDetailSuccess,
      this.getCreatedPatientDetailFailure
    );
  }
  getCreatedPatientDetailSuccess = success => {
    if (success.status == "success") {
      // console.log("success------->>>>> " + JSON.stringify(success.status))

      if (success.data) {
        var patientInfo = success.data;
        var { mandatoryInformation } = this.state;
        var { otherInformation } = this.state;
        var states = this.state

        var gender = ""
        if (patientInfo.gender == "m") {
          gender = "Male"
        } else if (patientInfo.gender == "f") {
          gender = "Female"
        } else {
          gender = "Trans"
        }
        // alert(patientInfo.age)
        let age = "";
        let approx_dob = "";

        if (patientInfo.dob) {
          age = this.handleAgeCalculation(patientInfo.dob);
        } else if (patientInfo.approx_dob) {
          age = this.handleAgeCalculation(patientInfo.approx_dob);
          // format
          approx_dob = moment(patientInfo.approx_dob, 'YYYY-MM-DD').format("DD-MM-YYYY");
        }

        mandatoryInformation["profilePhoto"] = patientInfo.photo_url;
        mandatoryInformation["type"] = patientInfo.title;
        mandatoryInformation["gender"] = gender;
        mandatoryInformation["firstName"] = patientInfo.first_name;
        mandatoryInformation["surName"] = patientInfo.last_name;
        mandatoryInformation["dob"] = patientInfo.dob;
        mandatoryInformation["age"] = age;
        mandatoryInformation["approx_dob"] = approx_dob;
        mandatoryInformation["maritalStatus"] = patientInfo.marital_status;
        mandatoryInformation["occupation"] = patientInfo.occupation != "undefined" && patientInfo.occupation != "" ? patientInfo.occupation : ""
        mandatoryInformation["mobileNo"] = patientInfo.mobile_number;
        mandatoryInformation["receiveSMSFlag"] = patientInfo.receive_sms;
        mandatoryInformation["refferedBy"] = patientInfo.reffered_by != "undefined" && patientInfo.reffered_by != "" ? patientInfo.reffered_by : "";
        mandatoryInformation["referralsource"] = patientInfo.referral_source != "undefined" && patientInfo.referral_source != "" ? patientInfo.referral_source : "";


        mandatoryInformation["country"] = patientInfo.country ? patientInfo.country : "";
        mandatoryInformation["fatherOrHusband"] = patientInfo.father_husband_name;
        mandatoryInformation["state"] = patientInfo.state_name;
        mandatoryInformation["mobileNo2"] = patientInfo.mobile_number_2;
        mandatoryInformation["isAmaPatient"] = patientInfo.is_ama_patient;
        mandatoryInformation["guardian"] = patientInfo.guardian;

        otherInformation["emailID"] = patientInfo.email;
        otherInformation["address"] = patientInfo.address_line_1 != "undefined" && patientInfo.address_line_1 != "" ? patientInfo.address_line_1 : "";
        otherInformation["address2"] = patientInfo.address_line_2 != "undefined" && patientInfo.address_line_2 != "" ? patientInfo.address_line_2 : "";


        otherInformation["city"] = patientInfo.city_name;
        otherInformation["pincode"] = patientInfo.pincode;
        otherInformation["employer_id"] = patientInfo.employer_id;
        otherInformation["employee_ID_No"] = patientInfo.id_no;

        this.setState({
          mandatoryInformation,
          otherInformation,
          toggleSwitchFlag: patientInfo.receive_sms,
        }, () => {
          // console.log("success------->>>>> " + JSON.stringify(this.state.mandatoryInformation))

        })
      }
    }

  }
  handleAgeCalculation = date => {

    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");

    /*
    var differenceInMilisecond = moment.duration(moment().diff(m));
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
            
    var month_age = Math.floor(day_age/30);
    
    day_age = day_age % 30;
    */

    // var monthString = month_age === 1 ? "month " : "months ";
    // var dayString = day_age === 1 ? "day " : "days ";

    // var a = moment([2007, 0, 29]);
    // var b = moment([2007, 0, 28]);
    // a.diff(b, 'days') // 1

    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age >= 15) {
      age = year_age || 0;
    } else {
      age = year_age + "/" + month_age + "/" + day_age;
    }
    return age;
  }
  getCreatedPatientDetailFailure = error => {
    console.log("error------->>>>> " + JSON.stringify(error))
  }

  getCreatedCaretakerDetails(patient_id) {
    var service_url = Constants.DR_CREATE_CARETAKER + patient_id + "/";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCreatedCaretakerDetailsSuccess,
      this.getCreatedCaretakerDetailsFailure
    );
  }
  getCreatedCaretakerDetailsSuccess = success => {
    if (success.status == "success") {
      let careTakerInfo = success.data;
      let { careTakerInformation } = this.state;
      careTakerInformation["firstName"] = careTakerInfo.first_name;
      careTakerInformation["relationship"] = careTakerInfo.relationship;
      careTakerInformation["address"] = careTakerInfo.address_line_1;
      careTakerInformation["address2"] = careTakerInfo.address_line_2;


      careTakerInformation["city"] = careTakerInfo.city_name;
      careTakerInformation["emailID"] = careTakerInfo.email;
      careTakerInformation["pincode"] = careTakerInfo.pincode;
      careTakerInformation["mobileNo"] = careTakerInfo.mobile_number;
      this.setState({
        careTakerInformation
      })
    }
  }

  getCreatedCaretakerDetailsFailure = error => {
    // console.log("success =========-> " + JSON.stringify(error));
  }


  getIdProofDetails(patient_id) {
    var service_url = Constants.DR_CREATE_IDPROOF + patient_id + "/";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getIdProofDetailsSuccess,
      this.getIdProofDetailsFailure
    );
  }

  getIdProofDetailsSuccess = success => {

    if (success.status == "success") {
      // alert("success ress====> " + JSON.stringify(success.data));
      if (success.data.length > 0) {
        var proof = success.data;
        let { idProof } = this.state;
        let { idProofDocs } = this.state;
        let collectedIdProofs = []
        for (var i = 0; i < proof.length; i++) {
          // var possition = i+1

          // aadharCard: false,
          // drivingLicence: false,
          // voterId: false,
          // passport: false,
          // rationCard: false,
          // bankId: false,

          if (1 === proof[i].doc_type) {

            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Voter ID",
              "imageName": "voterID.jpeg"
            }
            // collectedIdProofs.push(data);
            collectedIdProofs.push(data);
            idProof["aadharCard"] = proof[i].number;
            idProofDocs["aadharCard"] = proof[i].doc_key;
            this.setState({ aadharCard: true });

          } else if (2 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Passport",
              "imageName": "passport.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["passport"] = proof[i].number;
            idProofDocs["passport"] = proof[i].doc_key;
            this.setState({ passport: true });

          } else if (3 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Driving Licence",
              "imageName": "drivingLicence.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["drivingLicence"] = proof[i].number;
            idProofDocs["drivingLicence"] = proof[i].doc_key;
            this.setState({ drivingLicence: true });

          } else if (4 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Voter ID",
              "imageName": "voterId.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["voterId"] = proof[i].number;
            idProofDocs["voterId"] = proof[i].doc_key;
            this.setState({ voterId: true });

          } else if (7 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Ration Card",
              "imageName": "rationCard.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["rationCard"] = proof[i].number;
            idProofDocs["rationCard"] = proof[i].doc_key;
            this.setState({ rationCard: true });

          } else if (8 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Bank ID",
              "imageName": "bankId.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["bankId"] = proof[i].number;
            idProofDocs["bankId"] = proof[i].doc_key;
            this.setState({ bankId: true });

          } else if (10 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Visa",
              "imageName": "visa.jpeg "
            }
            collectedIdProofs.push(data);
            idProof["visa"] = proof[i].number;
            idProofDocs["visa"] = proof[i].doc_key;
            this.setState({ visa: true });

          } else if (11 === proof[i].doc_type) {
            var data = {
              "image": proof[i].doc_key,
              "number": proof[i].number,
              "type": "Visa Validity",
              "imageName": "visa-validity.jpeg"
            }
            collectedIdProofs.push(data);
            idProof["visaValidity"] = proof[i].number;
            idProofDocs["visaValidity"] = proof[i].doc_key;
            this.setState({ visaValidity: true });
          }
        }
        this.setState({ idProof, idProofDocs, collectedIdProofs: collectedIdProofs });
      }
    }
  }
  getIdProofDetailsFailure = error => {
    console.log("error ress===============.> " + JSON.stringify(error));
  }




  async componentDidMount() {

    let status = ""

    try {
      let objStatus = await Permissions.askAsync(Permissions.CAMERA);
      if (objStatus && objStatus.status) {
        status = objStatus.status
      }
      // console.log("camera status " + JSON.stringify(status))
    } catch (error) {
      // console.log("camera warning error " + error)
    }

    const userType = await AsyncStorage.getItem("service_type");
    const clinicId = await AsyncStorage.getItem("UserID");


    this.getRefferedBy(userType)

    let restrictCreateAppointmentModal = this.props.patientAppointment && this.props.patientAppointment.patient_id ? false : true;

    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      defaultSelection: loggedInDataObj.default_value,
      isRoleAdmin: loggedInDataObj.is_admin_access
    }, () => {
      var defaultCountry = this.state.defaultSelection?.default_country ? this.state.defaultSelection.default_country : ""
      var defaultCountries = defaultCountry.charAt(0).toUpperCase() + defaultCountry.slice(1);

      var defaultState = this.state.defaultSelection?.default_state ? this.state.defaultSelection?.default_state : ""
      var defaultStates = defaultState.charAt(0).toUpperCase() + defaultState.slice(1);

      var defaultCity = this.state.defaultSelection?.default_city ? this.state.defaultSelection?.default_city : ""
      var defaultCities = defaultCity.charAt(0).toUpperCase() + defaultCity.slice(1);

      this.selectCountry(defaultCountries)
      this.selectRegion(defaultStates)
      var { otherInformation } = this.state;
      otherInformation["city"] = defaultCities
    })
    this.setState({
      userType: userType,
      clinicId: clinicId,
      hasPermission: status === 'granted' ? true : false,
      restrictCreateAppointmentModal: restrictCreateAppointmentModal,
    })

    // this.getState()
    if (this.props.modificationDetails && Object.keys(this.props.modificationDetails).length > 0) {
      this.getCreatedPatientDetail(this.props.modificationDetails.patient_id);
    }
    if (userType == Constants.ROLE_FO) {
      this.getDoctor()
    }
    // if (this.props.patientAppointment && this.props.patientAppointment.patient_id) {
    //   this.setState({
    //     restrictCreateAppointmentModal: false
    //   })
    // }

    // if (this.props.navigation &&
    //   this.props.navigation.state &&
    //   this.props.navigation.state.params &&
    //   this.props.navigation.state.params.patientAppointment && 
    //   this.props.navigation.state.params.patientAppointment.patient_id) {
    if ((this.props.patientAppointment && this.props.patientAppointment.patient_id) || (this.props.selectedIpPatientDetails && this.props.selectedIpPatientDetails.patient_id) || (this.props.selectedIpPatientDetails && this.props.selectedIpPatientDetails.id) || (this.props.userType == "FO" && Object.keys(this.props.patientAppointment).length >0 && this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.patient_id) || (this.props.userType == "LAB" && this.props.selectedLabPatientDetails && this.props.selectedLabPatientDetails.patient_id)) {
      // alert(JSON.stringify(this.props.selectedIpPatientDetails))
      var patient_id = "";
      if (this.props.patientAppointment.patient_id) {
        patient_id = this.props.patientAppointment.patient_id;
      } else if (this.props.selectedIpPatientDetails.patient_id) {
        patient_id = this.props.selectedIpPatientDetails.patient_id;
      } else if (this.props.selectedLabPatientDetails.patient_id) {
        patient_id = this.props.selectedLabPatientDetails.patient_id;

      } else if (this.props.selectedIpPatientDetails.id) {
        patient_id = this.props.selectedIpPatientDetails.id;
      }

      this.setState({
        checkPatientId: patient_id
      })

      this.getCreatedPatientDetail(patient_id);
      this.getCreatedCaretakerDetails(patient_id);
      this.getIdProofDetails(patient_id);

      this.showNextAppoinmentView(patient_id)
      this.setState({
        alreadyCreatedPatient: true,
        patientId: patient_id,
        patientAppointment: this.props.patientAppointment,
        patientInfo: this.props.patientInfo,
      })
    } else if (this.props.editPatientID && this.props.editPatientID != this.state.patientId) {
      this.setState({
        patientId: this.props.editPatientID,
        isEditAction: true,
        alreadyCreatedPatient: true
      }, () => {
        this.getCreatedPatientDetail(this.state.patientId)
        this.getCreatedCaretakerDetails(this.state.patientId);
        this.getIdProofDetails(this.state.patientId)
      })
    }

    // 
    this.chegeAppoinmentModalTime()
    this.getClinic();
    this.getInsuranceDetails()
    this.searchReferrals();
    this.getCorporateList();
    // 
    setInterval(() => {
      this.setState({
        currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
      });
    }, 1000);

    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
    const FO_admin_setting = JSON.parse(FO_admin);
    this.setState({
        MandatoryFields : FO_admin_setting?.demographics_mandatory_fields,  // Mandatory Fields defined by users in admin settings
        FO_admin_setting: FO_admin_setting
    })
  }

  componentWillReceiveProps(props) {

    let { mandatoryInformation } = this.state;

    if (props.cameraCaptureImage) {
      mandatoryInformation["profilePhoto"] = props.cameraCaptureImage;

      this.setState({ mandatoryInformation })
    }
    if (props.editPatientID && Object.keys(props.editPatientID).length > 0 && props.editPatientID != this.state.editPatientID) {
      this.setState({
        patientId: props.editPatientID
      }, () => {
        this.showNextAppoinmentView(this.state.patientId)
      })
    }
    if (this.props.modificationDetails && Object.keys(this.props.modificationDetails).length > 0) {
      this.getCreatedPatientDetail(this.props.modificationDetails.patient_id);
    }
    if (props.cameraCaptureFaces) {

      // console.log(" props.cameraCaptureFaces " + (props.cameraCaptureFaces).length)

      if ((props.cameraCaptureFaces).length > 0) {

        mandatoryInformation["facialPhotos"] = props.cameraCaptureFaces;
        this.setState({ mandatoryInformation })

        if (props.cameraCaptureFaces.length === 5) {
          // save image 
          this.saveUserImages();
        }

        this.props.clearPatientPhotos();
      }
    }
  }

  getCorporateList = () => {
    var serviceUrl = Constants.CORPORATE_ADMIN_CONFIGURATION;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getCorporateListSuccess,
      this.getCorporateListFailure
    );

  }

  getCorporateListSuccess = (success) => {
    this.setState({
      corpCompanyList: success.data.emp_details
    })
  }
  getCorporateListFailure = (error) => {
    console.log("error ress===============.> " + JSON.stringify(error));
  }

  getRefferedBy = (userType) => {
    if (userType == Constants.ROLE_FO) {
      var serviceUrl = Constants.REFERRED_BY_DEMOGRAPHIC_GET
    } else {
      var serviceUrl = Constants.REFERRED_BY_DEMOGRAPHIC_GET + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
    }

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferedBySuccess,
      this.getRefferedByFailure
    );

  }

  getRefferedBySuccess = response => {
    if (response.status == "success") {
      this.setState({
        referredByList: response.data
      })
    }
  }

  renderSubHeadingBorder = (title, line = false) => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: title == "IDENTITY PROOF" ? null : '2.5vw', marginRight: title == "REFERRED BY" ? '1.2vw' : '2.5vw',
          zIndex: -1
        }}
      ><View>
          <Text style={styles.HeadertitleText}>{title}</Text>
        </View>
        {(line) ?
          <View
            style={{
              marginLeft: '0.6vw',
              width: "80%",
              height: '0.07vw',
              backgroundColor: "grey"
            }}
          />
          : null}
      </View>
    );
  };


  // renderHeaderView = () => {
  //   return (
  //     <View
  //       style={{
  //         padding: 20,
  //         width: "100%",
  //         flexDirection: "row",
  //         justifyContent: "space-between",
  //         alignItems: "center"
  //       }}
  //     >
  //       <View style={{ flexDirection: "row" }}>
  //         <View>
  //           <Icon
  //             name="arrow-left"
  //             size={20}
  //             color={color.black}
  //           // onPress = {() => this.props.navigation.goBack()}
  //           />
  //         </View>
  //         <View style={{ marginLeft: 20 }}>
  //           <Text style={{ fontSize: 20 }}>Roger George</Text>
  //           <Text
  //             style={{
  //               fontSize: 12,
  //               color: "#ADB4C1",
  //               marginTop: 5,
  //               marginBottom: 5
  //             }}
  //           >
  //             27 years | 25 Apr 1992 | +91 9876543212 | 7:15 AM
  //       </Text>
  //           <Text style={{ fontSize: 12, color: "#ADB4C1" }}>
  //             Last Visit Date : 12 Jan 2020
  //       </Text>
  //         </View>
  //       </View>
  //     </View>
  //   );
  // };


  // renderTitleBorder = (title) => {
  //   return (
  //     <TouchableOpacity>
  //       <View style={{ flexDirection: "row", }}>
  //         <Text
  //           style={{
  //             fontSize: 16, //25
  //             color: color.themePink,
  //             fontWeight: "600"
  //           }}
  //           numberOfLines={1}
  //         >
  //           {title}
  //         </Text>
  //         <View
  //           style={{ marginLeft: 10, flex: 1, height: 0.5, backgroundColor: "#E0E0E0" }}
  //         />
  //       </View>
  //     </TouchableOpacity>
  //   );
  // };


  renderTextBox(placeholder, width, center, type, value, error = false, disable = false) {
    return (
      <View>
        <Text style={[styles.TextBoxPlaceholder, { backgroundColor: type == "IDP" ? color.themeShade : "white" }]}>{placeholder}</Text>

        {
          disable || this.state.isIpPatientCheckouted ?
            <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: '0.5vw', color: "#888888", paddingTop: '0.6vw', fontSize: '1vw' }, Style.allButtonBorderRadius,
            this.state.isIpPatientCheckouted ? { backgroundColor: "#F0F0F0" } : {}
            ]} >
              {value}
            </Text> :
            <TextInput

              // placeholder={placeholder}

              editable={((placeholder == "Age *" || placeholder == "Surname" || placeholder == "Surname *" || placeholder == "First Name *" || placeholder == "Mobile No *") && (((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true))) || placeholder === "Doctor Name" ? false : true}
              onFocus={() => {
                if (placeholder === "Doctor Name") {
                  this.setState({
                    isRefferedBySuggestionOpen: true
                  })
                }
              }}
              onBlur={() => {
                if (placeholder === "Doctor Name") {
                  this.setState({
                    isRefferedBySuggestionOpen: false
                  })
                }
              }}
              style={{
                borderColor: error ? "red" : "#CDD1D5",
                borderRadius: 4,
                fontSize: '1vw',
                borderWidth: 1,
                width: placeholder === "Doctor Name" ? '14vw' : "100%",
                height: placeholder === "Doctor Name" ? '2.5vw' : '2.2vw',
                padding: '1vw',
                marginTop: '0.6vw',
                backgroundColor: (((placeholder == "Age *" || placeholder == "Surname" || placeholder == "Surname *" || placeholder == "First Name *" || placeholder == "Mobile No *") && (((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)))) ? "#F0F0F0" : color.white
              }} value={value}
              maxLength={placeholder === "Age *" ? 3 : null}
              onChangeText={(text) => {
                // value = text;
                // props.callBack(text, props.textKey);
                if (placeholder == 'Source Name') {
                  this.createPatientDetails(text, placeholder, type);
                } else {
                  this.createPatientDetails(text, placeholder, type);
                }

              }}
            // onSubmitEditing={() => {
            //     props.enterTosubmit ?
            //         props.callBack(value, props.textKey, true)
            //         : null
            // }
            // }
            />





        }
        {/* {(this.state.isRefferedBySuggestionOpen) && placeholder === "Doctor Name" ?
          <View style={styles.DoctorsNameList}>
            <ScrollView
              style={{
                flex: 1,
                backgroundColor: "#F0F8FF",
                borderColor: color.lightGray,
                borderWidth: 1,
                borderRadius: 4,
                paddingHorizontal: '0.6vw',
                zIndex: 2
              }}
            >
              {(this.state.referredByList).length > 0 && (this.state.referredByList).map((item, index) =>
                <View style={{ borderBottomWidth: 1, borderBottomColor: color.lightGray }}>
                  <TouchableOpacity
                    key={index}
                    style={{ marginTop: '0.3vw', marginBottom: 2 }}
                    onPress={() => {
                      var fields = this.state.mandatoryInformation
                      fields["refferedBy"] = item.name
                      this.setState({
                        isRefferedBySuggestionOpen: false,
                        fields
                      })
                    }}
                  >
                    <Text style={{ color: color.black, fontWeight: "bold", fontSize: '1vw' }}>{item.name} - {item.specialization}</Text>
                  </TouchableOpacity>
                </View>

              )}

            </ScrollView>

          </View>
          : null
        } */}

      </View>
    )
  }

  // renderTextBox(placeholder, width, center, type, value) {
  //   return (
  //     <TextInput
  //       value={value}
  //       // multiline={true}
  //       placeholder={placeholder}
  //       underlineColorAndroid="transparent"
  //       style={[Style.formTextBox, {
  //         alignSelf: center ? 'center' : null,
  //         marginRight: width ? width : 0
  //         // marginLeft: placeholder == "Card" || placeholder == "Address" || placeholder == "Pincode" || placeholder == "Relationship" ? 15 : null, 
  //         // marginRight: placeholder == "Cash" || placeholder == "First Name" ? 15 : null, 
  //       }]}
  //       placeholderTextColor={"grey"}
  //       autoCapitalize="none"
  //       onChangeText={text => {
  //         this.createPatientDetails(text, placeholder, type);
  //       }}
  //     />
  //   );
  // }



  // checkIDproofDataIsNotNull(key, value) {
  //   var states = this.state;

  //   if (states.curretlySelectedIdproof == "") {
  //     states[key] = !value;
  //     states["curretlySelectedIdproof"] = key;
  //     states.enteredIdProofValues.push(key)
  //     this.setState({ states })
  //   } else {
  //     // alert("else")
  //     for (var i = 0; i < this.state.enteredIdProofValues; i++) {
  //       if (states.enteredIdProofValues[i] == "aadharCard") {
  //         // alert("aadharCard")
  //         // states["idProofDocs"]["aadharCard"] 
  //         if (states["idProofDocs"]["aadharCard"] && states["idProof"]["aadharCard"]) {
  //           // alert("!aadharCard")
  //           var state = this.state;
  //           state["curretlySelectedIdproof"] = key
  //           state.enteredIdProofValues.push(key)
  //           this.setState({ states })
  //         }
  //       }
  //     }

  //   }

  // }


  // showIdProofImagesView(text,key,stateValue,selectedImage,unSelectedImage){
  //   return(
  //     <TouchableOpacity style={{ marginBottom:20, marginRight:15,height: 90, width: 90, backgroundColor: this.state.curretlySelectedIdproof == key ?  color.themeDark : "white", alignSelf: 'center', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}
  //     onPress={() => {
  //       // this.checkIDproofDataIsNotNull(key,stateValue)
  //       var states = this.state
  //       states[key]=!stateValue
  //       this.setState({
  //         states
  //         // curretlySelectedIdproof:"aadharCard"
  //       })
  //     }}
  //   >
  //     <Image source={require(
  //       this.state.curretlySelectedIdproof == key ? selectedImage() : unSelectedImage() 
  //     )}
  //       style={{ height: 30, width: 30 }}
  //       resizeMode="contain"
  //     />
  //     <Text style={{ fontSize: 10, textAlign: 'center', color:  this.state.curretlySelectedIdproof == key ? 'white' : null }}>{text}</Text>
  //   </TouchableOpacity>
  //   );
  // }

  renderIDProof() {
    return (
      <View style={styles.IDProofFuncView}>
        <TouchableOpacity style={[styles.ProofImgTouch, {
          backgroundColor:
            // this.state.curretlySelectedIdproof == "aadharCard" ?  color.themeDark : "white", 
            this.state.aadharCard ? color.themeDark : "white",
        }]}
          onPress={() => {
            // this.checkIDproofDataIsNotNull("aadharCard",this.state.aadharCard)
            this.setState({
              aadharCard: !this.state.aadharCard
            })
          }}

          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image source={require(
            this.state.aadharCard ? "../../../../assets/images/Active/Group.svg" : "../../../../assets/images/Inactive/Group.svg"
          )}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.aadharCard ? 'white' : null }]}>{"Aadhaar number"}</Text>
        </TouchableOpacity>

        {/* {this.showIdProofImagesView("Aadhard number","aadharCard",this.state.aadharCard, "../../../../assets/images/Active/Group.svg"  ,"../../../../assets/images/Inactive/Group.svg")} */}

        <TouchableOpacity style={[styles.ProofImgTouch, { backgroundColor: this.state.drivingLicence ? color.themeDark : "white" }]}
          onPress={() => {
            this.setState({
              drivingLicence: !this.state.drivingLicence
            })
            // this.checkIDproofDataIsNotNull("drivingLicence",this.state.drivingLicence)
          }}
          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image
            source={require(
              this.state.drivingLicence ?
                "../../../../assets/images/Active/icons-transport-car.svg" :
                "../../../../assets/images/Inactive/icons-transport-car.svg")}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.drivingLicence ? 'white' : null }]}>{"Driving Licence"}</Text>
        </TouchableOpacity>

        {/* {this.showIdProofImagesView("Driving Licence","drivingLicence",this.state.drivingLicence, "../../../../assets/images/Active/icons-transport-car.svg"  ,"../../../../assets/images/Inactive/icons-transport-car.svg")} */}

        <TouchableOpacity style={[styles.ProofImgTouch, { backgroundColor: this.state.voterId ? color.themeDark : "white" }]}
          onPress={() => {
            this.setState({
              voterId: !this.state.voterId
            })
          }}
          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image
            source={require(
              this.state.voterId ?
                "../../../../assets/images/Active/icons-rideshare.svg" :
                "../../../../assets/images/Inactive/icons-rideshare.svg"
            )}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.voterId ? 'white' : null }]}>{"Voter ID"}</Text>
        </TouchableOpacity>


        <TouchableOpacity style={[styles.ProofImgTouch, { backgroundColor: this.state.passport ? color.themeDark : "white" }]}
          onPress={() => {
            this.setState({
              passport: !this.state.passport
            })
          }}
          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image
            source={require(
              this.state.passport ?
                "../../../../assets/images/Active/airplane.svg" :
                "../../../../assets/images/Inactive/airplane.svg")}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.passport ? 'white' : null }]}>{"Passport"}</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.ProofImgTouch, { backgroundColor: this.state.rationCard ? color.themeDark : "white" }]}
          onPress={() => {
            this.setState({
              rationCard: !this.state.rationCard
            })
          }}
          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image
            source={require(
              this.state.rationCard ?
                "../../../../assets/images/Active/community.svg" :
                "../../../../assets/images/Inactive/community.svg"
            )}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.rationCard ? 'white' : null }]}>{"Ration Card"}</Text>
        </TouchableOpacity>


        <TouchableOpacity style={[styles.ProofImgTouch, { backgroundColor: this.state.bankId ? color.themeDark : "white" }]}
          onPress={() => {
            this.setState({
              bankId: !this.state.bankId
            })
          }}
          disabled={this.state.isIpPatientCheckouted ? true : false}
        >
          <Image
            source={require(
              this.state.bankId ?
                "../../../../assets/images/Active/credit-card.svg" :
                "../../../../assets/images/Inactive/credit-card.svg")}
            style={styles.ProofImg}
            resizeMode="contain"
          />
          <Text style={[styles.ProofText, { color: this.state.bankId ? 'white' : null }]}>{"Bank ID"}</Text>
        </TouchableOpacity>

      </View>
    );
  }


  // renderHeaderView = () => {
  //   let { patientDetails, patientInfo, patientAppointment } = this.state;
  //   return (
  //     <View
  //       style={{
  //         // padding: 20,
  //         // paddingRight:20,
  //         // paddingBottom:20,
  //         paddingLeft: 20,
  //         paddingTop: 20,
  //         width: "100%",
  //         flexDirection: "row",
  //         justifyContent: "space-between",
  //         alignItems: "center"
  //       }}
  //     >
  //       <View style={{ flexDirection: "row" }}>
  //         <View>

  //           <Arrow
  //             name="arrow-left"
  //             size={20}
  //             color={color.black}
  //             onPress={() => {
  //               this.props.changeScreen("optDoctorNotes");
  //               // this.props.navigation.goBack()
  //             }}
  //           />
  //           {/* <Icon
  //             name="arrow-left"
  //             size={20}
  //             color={color.black}
  //             onPress={() => this.props.navigation.goBack()}
  //           /> */}
  //         </View>
  //         <View style={{ marginLeft: 20 }}>
  //           <Text style={{ fontSize: 16 }}>{patientDetails.name || ""}</Text>
  //           <Text
  //             style={{
  //               fontSize: 10,
  //               color: "#ADB4C1",
  //               marginTop: 5,
  //               marginBottom: 5
  //             }}
  //           >
  //             {patientDetails.age || ""} | {patientDetails.dob || ""} | {patientDetails.mobile_number || ""} | {patientAppointment.appointment_start_time || ""}
  //           </Text>
  //           <Text style={{ fontSize: 10, color: "#ADB4C1" }}>
  //             Last Visit Date : {patientDetails.last_visit_date || ""}
  //           </Text>
  //         </View>
  //       </View>
  //       {/* {this.renderIconBtn("eye", "View Report", false)} */}
  //     </View>
  //   );
  // };



  //   calculate_age(dob) { 
  //     var diff_ms = Date.now() - dob.getTime();
  //     var age_dt = new Date(diff_ms); 

  //     return Math.abs(age_dt.getUTCFullYear() - 1970);
  // }

  calculate_age = (birthDate) => {
    var states = this.state.mandatoryInformation;
    var age = Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
    states['age'] = age;
    this.setState({ states })
  }


  pickerView(placeholder, width, key, value) {
    return (
      <View style={styles.PickerContainer}>
        <Text style={styles.PickerTitle}>{"Title"}</Text>
        <Picker
          selectedValue={this.state.mandatoryInformation.type}
          style={[styles.pickerView, { marginTop: '0.3vw' }]}
          itemStyle={{ fontSize: '0.9vw' }}
          onValueChange={(itemvalue) => {
            var states = this.state.mandatoryInformation;
            states["type"] = itemvalue;

            if (itemvalue == "Mr") {
              states["gender"] = "Male";
            } else if (itemvalue == "Ms" || itemvalue == "Mrs") {
              states["gender"] = "Female";
            }

            this.setState({ states });
          }}
        >
          <Picker.Item label="" value="" />
          <Picker.Item label="Mr" value="Mr" />
          <Picker.Item label="Ms" value="Ms" />
          <Picker.Item label="Mrs" value="Mrs" />
        </Picker>
      </View>
    );
  }



  handleFindApproxDob = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    var approximate_date = moment();

    // split data
    var enteredDaySplit = ageEntered.split("/");

    let yearsplit = enteredDaySplit[0] !== undefined ? enteredDaySplit[0] : ""
    // year = yearsplit.length == 0 ? undefined : (parseInt(yearsplit) || 0);
    year = parseInt(yearsplit) || 0;

    let monthsplit = enteredDaySplit[1] !== undefined ? enteredDaySplit[1] : ""
    // month = monthsplit.length == 0 ? undefined : (parseInt(monthsplit) || 0);
    month = parseInt(monthsplit) || 0;

    let daysplit = enteredDaySplit[2] !== undefined ? enteredDaySplit[2] : ""
    // days = daysplit.length == 0 ? undefined : (parseInt(daysplit) || 0); 
    days = parseInt(daysplit) || 0;

    if (!year && !month && !days) {
      tempDate = "";
    } else {

      // reset(year) year with difference year
      approximate_date = moment(approximate_date).subtract(year, 'years');

      // reset(month) month with difference month
      approximate_date = moment(approximate_date).subtract(month, 'months');

      // reset(days) days with difference days
      approximate_date = moment(approximate_date).subtract(days, 'days');

      tempDate = moment(approximate_date).format("DD-MM-YYYY");

    }

    return tempDate;
    /*
    let fields = this.state.mandatoryInformation;    // variable approx_dob
    var ageEntered = value;  // value
    var todayDate = new Date();
  
    var newDate = new Date(todayDate);
  
    let enteredDay = value;   // value
    var enteredDaySplit = enteredDay.split("/");
  
    let daysplit = enteredDaySplit[2] !== undefined ? enteredDaySplit[2] : ""
    let day = daysplit.length == 0 ? undefined : daysplit; 
  
    var year = Math.abs(todayDate.getFullYear() - enteredDaySplit[0]);
    var month = enteredDaySplit[1] == undefined ? todayDate.getMonth()+1 : Math.abs(enteredDaySplit[1] - (todayDate.getMonth()- 1)) ;
    var days =  day == undefined ? todayDate.getDate() : Math.abs(day - todayDate.getDate());
  
    if(month>0 && (month>=(todayDate.getMonth()+1))){
        year = year-1;
        month = 12-(month-(todayDate.getMonth()+1));
    }
    month = month?month:1;
    return year+ "-" +  month + "-" +  days; 
    */
  }

  getDoctor = () => {
    var serviceUrl =
      Constants.HOME_GET_CLINIC_DOCTORS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorSuccess,
      this.getDoctorFailure
    );
  };

  getDoctorSuccess = response => {
    // console.log("Get doctor list success response");
    //console.log(response);

    if (response.detail) {
      this._logoutFunction();
    } else {

      var tempList = [];

      response.data && response.data.length > 0 && response.data.map((item) => {
        tempList.push({ id: item.doctor_id, value: item.first_name + " " + item.initial, speciality_id: item.speciality_id })
      });

      var fields = this.state;
      fields["doctorData"] = tempList;

      // if (response.data.length > 0) {
      //   fields["selectedDoctor"] = tempList[0];
      // }

      this.setState({
        fields
      });

      // this.getAppointments(moment().format("YYYY-MM-DD"));
      // this.getAppointmentStat();
    }
  };

  getDoctorFailure = error => {
    //console.log("Get doctor list error response");
    console.log(error);
  };


  async changePickerDropDownValue(flag, selectedValue, pickerKey) {

   if(pickerKey === "IPDoctor") { 
    var states = this.state;
    states["showPickerDropDownFlag"] = flag
    if ( selectedValue && selectedValue != this.state.selectedDoctor ) {
      await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
      states['selectedDoctor'] = selectedValue

    }
    this.setState({
      states
    }, () => {
      // this.props.changeStateValues({}, {}, "", this.state.selectedClinic)
      // var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
      // this.getAppointments(selectedDateAndTime);
    })
   }

  }
  changeTextBoxValues(value, key) {
    var states = this.state
    states[key] = value
    this.setState({ states })
  }
  renderReferredTextBox(placehold, value, key) {
    return (
      <View style={styles.ReferrefTextBoxView}>
        <Text style={styles.ReferrefTextBoxText}>{placehold}</Text>
        <TextInput
          placeholder={""} value={value}
          onChangeText={(text) => { this.changeTextBoxValues(text, key) }}
          style={[styles.ReferrefTextBoxTextInput, { borderColor: color.lightGray, }]}
        />
      </View>
    )
  }

  searchReferrals = () => {

    var serviceUrl = Constants.REFFERAL_SOURCE_LIST + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.searchReferralsSuccess,
      this.searchReferralsFailure
    );
    var fields = this.state;
    fields["refferalArray"] = [];

    this.setState({
      fields
    });
  };
  searchReferralsSuccess = response => {

    var fields = this.state;
    fields["refferalArray"] = response.data;
  };

  searchReferralsFailure = error => {
    console.log(error);
  };

  selectCountry(val) {
    let states = this.state.mandatoryInformation
    if (val === "India" || val === "") {
      this.setState({
        passport: false,
        isVisaEnable: false
      })
    } else if (val != "India" || val != "") {
      this.setState({
        passport: true,
        isVisaEnable: true
      })
    }
    states["country"] = val
    this.setState({ states });
  }

  selectRegion(val) {
    let states = this.state.mandatoryInformation
    states["state"] = val
    this.setState({ states });
  }

  ipPatientDetailFetch = () => {

    var patientId = this.state.patientId

    this.getCreatedPatientDetail(patientId);
    this.getCreatedCaretakerDetails(patientId);
    this.getIdProofDetails(patientId);
    this.showNextAppoinmentView(patientId);
  }

  getInsuranceDetails = () => {
    var service_url = Constants.DEMOGRAPHICS_INSURANCE_DETAIL + "?patient_id=" + this.state.patientId

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getInsuranceDetailsSuccess,
      this.getInsuranceDetailsFailure
    );
  }

  getInsuranceDetailsSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        insuranceData: response.data.insurance_data,
        corporateData: response.data.corporate_data
      })
    }
  }

  insuranceAddClick = () => {
    if (this.state.AddingServiceType === "insurance") {
      var data = {
        "patient_id": this.state.patientId,
        "type": "Insurance",
        "policy_number": this.state.insurancePolicyNumber,
        "company_name": this.state.insuranceCompanyName,
      }
    } else if (this.state.AddingServiceType === "corporate") {
      var data = {
        "patient_id": this.state.patientId,
        "type": "Corporate",
        "company_name": this.state.corporateCompanyName,
        "employee_id": this.state.corporateEmployeeId
      }
    }

    var service_url = Constants.DEMOGRAPHICS_INSURANCE_DETAIL;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.insuranceAddClickSuccess,
      this.insuranceAddClickFailure,
    );
  }

  insuranceAddClickSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        isInsuranceAddClick: false,
        insuranceCompanyName: "",
        insurancePolicyNumber: "",
        corporateCompanyName: "",
        corporateEmployeeId: "",
        isCorporateAddClick: false,
        AddingServiceType: "",
        isAddInsurancePopup: false
      })
      this.getInsuranceDetails()
      this.setState({ successOrErrorMessageLeft: response.message, successMessageFlagLeft: true, errorMessageFlagLeft: false });
      setTimeout(() => {
        this.setState({ successOrErrorMessageLeft: "", successMessageFlagLeft: false, errorMessageFlagLeft: false });
      }, 2000)
    } else if (response.status === "fail") {
      this.setState({ successOrErrorMessageLeft: response.message, successMessageFlagLeft: false, errorMessageFlagLeft: true, isAddInsurancePopup: false });
      setTimeout(() => {
        this.setState({ successOrErrorMessageLeft: "", successMessageFlagLeft: false, errorMessageFlagLeft: false });
      }, 2000)
    }
  }

  onDeleteInsurance = () => {
    var service_url = Constants.DEMOGRAPHICS_INSURANCE_DETAIL + "?delete_id=" + this.state.selectedDeleteId;
    OpthamologyService.getInstance().deleteComplaints(
      service_url,
      {},
      this,
      this.onDeleteInsuranceSuccess,
      this.onDeleteInsuranceFailure
    )
  }

  onDeleteInsuranceSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        selectedDeleteId: null,
        DeleteConfirmationPopup: false
      })
      this.getInsuranceDetails()
      this.setState({ successOrErrorMessageLeft: response.message, successMessageFlagLeft: true, errorMessageFlagLeft: false });
      setTimeout(() => {
        this.setState({ successOrErrorMessageLeft: "", successMessageFlagLeft: false, errorMessageFlagLeft: false });
      }, 2000)
    } else if (response.status === "fail") {
      this.setState({ successOrErrorMessageLeft: response.message, successMessageFlagLeft: false, errorMessageFlagLeft: true, DeleteConfirmationPopup: false });
      setTimeout(() => {
        this.setState({ successOrErrorMessageLeft: "", successMessageFlagLeft: false, errorMessageFlagLeft: false });
      }, 2000)
    }
  }

  onDeleteInsurancePopup = () => {
    return (
      <View style={[styles.InsurancePopupView, { backgroundColor: color.white, shadowColor: color.grayShade }]}>
        <Text style={styles.InsuranceAlertText} >Are you sure?</Text>
        <View style={styles.AadharcardTextBox}>
          <TouchableOpacity
            style={[styles.InsuranceYesBtnTouch, { backgroundColor: color.themeDark }]}
            onPress={() => {
              this.onDeleteInsurance()
            }}>
            <Text style={[styles.InsuranceYesBtnText, { color: color.white }]}>{"Yes"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.InsuranceYesBtnTouch, { backgroundColor: color.themeDark }]}
            onPress={() => {
              this.setState({
                selectedDeleteId: null,
                DeleteConfirmationPopup: false
              })
            }}>
            <Text style={[styles.InsuranceYesBtnText, { color: color.white }]}>{"No"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  onAddInsurancePopup = () => {
    return (
      <View style={[styles.InsurancePopupView, { backgroundColor: color.white, shadowColor: color.grayShade }]}>
        <Text style={styles.InsuranceAlertText} >Are you sure?</Text>
        <View style={styles.AadharcardTextBox}>
          <TouchableOpacity
            style={[styles.InsuranceYesBtnTouch, { backgroundColor: color.themeDark }]}
            onPress={() => {
              this.insuranceAddClick()
            }}>
            <Text style={[styles.InsuranceYesBtnText, { color: color.white }]}>{"Yes"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.InsuranceYesBtnTouch, { backgroundColor: color.themeDark }]}
            onPress={() => {
              this.setState({
                AddingServiceType: "",
                isAddInsurancePopup: false,
              })
            }}>
            <Text style={[styles.InsuranceYesBtnText, { color: color.white }]}>{"No"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  clearDetails = () => {
    var states = this.state.mandatoryInformation
    var states1 = this.state.otherInformation
    var states2 = this.state.careTakerInformation
    states["type"] = "";
    states["gender"] = "";
    states["firstName"] = "";
    states["surName"] = "";
    states["dob"] = "";
    states["age"] = "";
    states["approx_dob"] = "";
    states["maritalStatus"] = "";
    states["occupation"] = "";
    states["guardian"] = "";
    states["mobileNo"] = "";
    states["receiveSMSFlag"] = true;
    states["fatherOrHusband"] = "";
    states["refferedBy"] = "";
    states["mobileNo2"] = "";
    states["referralsource"] = "";
    states1['emailID'] = "";
    states1["address"] = "";
    states1["address2"] = "";
    states1["pincode"] = "";
    states2["states2"] = "";
    states2["mobileNo"] = "";
    states2["relationship"] = "";
    states2["emailID"] = "";
    states2["address"] = "";
    states2["pincode"] = "";
    states1["employer_id"] = "";
    states1["employee_ID_No"] = "";
    states2['firstName'] = "";
    this.setState({
      states,
      states1,
      states2,
      errorColor:false
    })
  }

  onPressReferredAdd = () => {
    var { userType, referredDocSpecialization, referredDocName, referredDocQualification, referredDocHospital, selectedClinicOrDoctor, referredDocMobNumber,referredDocRegNumber } = this.state
    var serviceUrl = Constants.REFERRED_BY_DEMOGRAPHIC_GET
    var data = {
      name: referredDocName,
      qualification: referredDocQualification,
      specialization: referredDocSpecialization,
      clinic_name: referredDocHospital,
      mobile_no : referredDocMobNumber, 
      reg_no : referredDocRegNumber, 
    }
    if (userType != Constants.ROLE_FO) {
      data["clinic_id"] = selectedClinicOrDoctor.id
    }

    OpthamologyService.getInstance().postComplaints(
      serviceUrl,
      data,
      this,
      this.onPressReferredAddSuccess,
      this.onPressReferredAddFailure,
    );

  }

  onPressReferredAddSuccess = (response) => {
    if (response.status === "success") {
      this.getRefferedBy(this.state.userType)
      this.setState({
        isAddNewReferredBy: false,
        referredDocHospital: "",
        referredDocSpecialization: "",
        referredDocQualification: "",
        referredDocName: "",
        referredDocMobNumber: "",
        referredDocRegNumber: "",
      })
    }
  }

  render() {
    console.log(this.props.selectedIpPatientDetails, "this.props.selectedIpPatientDetails")
    const { country, state } = this.state.mandatoryInformation;
    // this.checkValidateTheDays();
    const dateFormat = 'YYYY-MM-DD';
    const dateFormatDisplay = 'DD-MM-YYYY';
    var { corporateData, userType, MandatoryFields } = this.state
    return (
      // <TouchableWithoutFeedback onPress={() => {
      //   this.setState({
      //     showNextAppoinmentTimer: false,
      //     dropDownViewFlag: false
      //   })
      // }}>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>

        {/* <View style={Style.leftBarView}>
            <SideMenu navigation={this.props.navigation} pageName={this.state.patientId ? "patientPage" :""}/>
          </View> */}
        <View style={{ width: "70%", height: "88vh", backgroundColor: color.white }}>

          {/* <View style={[Style.leftView,
            // {    width: this.state.showNextAppoinmentView ?   platform === "web" ? "62.5%" : "58.5%" : "94%"}
          ], { width: "65%", flex: 1 }}> */}


          {

            (this.props.selectedIpPatientDetails.id || this.props.isCurrentPageIsIP) && !this.props.isCurrentPageIsIPPatientAppointment ?
              <View style={[styles.TopHeaderView, { backgroundColor: color.white }]}>
                <View style={{ paddingLeft: '2.5vw' }}>
                  <Text style={styles.SelecteDoctorLabel}>{"Select Doctor"}</Text>
                  <View style={styles.DoctorSelectView}>
                    <CommonPicker
                      showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                      changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                      selectedValue={this.state.selectedDoctor}
                      arrayOfData={this.state.doctorData}
                      pickerKey="IPDoctor"
                    />
                  </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: "flex-end", backgroundColor: color.white, zIndex: -1 }}>
                  <TouchableOpacity
                    // onPress={() => this.openImageFolder("profilePhoto")} 
                    onPress={() => this.openCameraModal('profile')}
                    style={{ backgroundColor: color.white }}>
                    <Image source={this.state.mandatoryInformation.profilePhoto ? { uri: this.state.mandatoryInformation.profilePhoto } : require("../../../../assets/images/camera.svg")} style={[styles.CameraImage, { tintColor: this.state.mandatoryInformation.profilePhoto ? null : color.themeDark }]} />
                  </TouchableOpacity>
                </View>
              </View>

              :
              <View style={{ flexDirection: 'row', justifyContent: "flex-end" }}>
                <View>
                  {this.state.patientId != "" ? 
                  <TouchableOpacity onPress={() => { this.PrintPatientDetails() }} style={{ backgroundColor: color.white, marginTop: '2.5vw' }}>
                    <Tooltip placement="top" title={"Print Patient Details"}>
                      <Image source={require("../../../../assets/images/statusIcon/icon/print.svg")} style={[styles.CameraModelImg, { tintColor: color.themeDark }]} />
                    </Tooltip>
                  </TouchableOpacity>
                   : null }
                </View>
                <View style={[styles.CameraModelView, { backgroundColor: color.white, backgroundColor: color.white }]}>
                  <TouchableOpacity
                    // onPress={() => this.openImageFolder("profilePhoto")} 
                    onPress={() => this.openCameraModal('profile')}
                    style={{ backgroundColor: color.white }}>
                    <Image source={this.state.mandatoryInformation.profilePhoto ? { uri: this.state.mandatoryInformation.profilePhoto } : require("../../../../assets/images/camera.svg")} style={[styles.CameraImage, { tintColor: this.state.mandatoryInformation.profilePhoto ? null : color.themeDark }]} />
                  </TouchableOpacity>
                </View>
                {this.state.clinicId === "68" && this.state.patientId != "" ?
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => { this.PrintBarcode() }} style={{ backgroundColor: color.white, marginTop: '2.5vw' }}>
                      <Tooltip placement="top" title={"Print Barcode"}>
                        <Image source={require("../../../../assets/images/statusIcon/icon/print.svg")} style={[styles.CameraModelImg, { tintColor: this.state.mandatoryInformation.profilePhoto ? null : color.themeDark }]} />
                      </Tooltip>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.PrintOPCard() }} style={{ backgroundColor: color.white, marginTop: 40 }}>
                      <Tooltip placement="top" title={"Print OP Card"}>
                        <Image source={require("../../../../assets/images/statusIcon/icon/print.svg")} style={[styles.CameraModelImg, { tintColor: this.state.mandatoryInformation.profilePhoto ? null : color.themeDark }]} />
                      </Tooltip>
                    </TouchableOpacity>
                  </View> : null
                }
              </View>
          }



          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "100%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 }} bounces={false} bouncesZoom={false} >

            <View style={[Style.leftView, { width: "100%", backgroundColor: color.white }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={styles.ReferredByView}>
                  <View style={{ flexDirection: "row" }}>
                    {this.renderSubHeadingBorder("Referred By")}
                    <TouchableOpacity
                      onPress={() => {
                        var fields = this.state.mandatoryInformation
                        fields["refferedBy"] = ""
                        this.setState({
                          isAddNewReferredBy: true,
                          fields
                        })
                      }}
                      style={{marginLeft:"-1.5vw"}}
                    >
                      <Image source={require("../../../../assets/AddDoctor.png")} style={{ width:"8vw", height:"2vw", borderRadius:".5vw" }}/>
                    </TouchableOpacity>
                  </View>

                  <View style={styles.DoctorNameView}>
                    {/* {this.renderTextBox("Doctor Name", 10, false, "MI", this.state.mandatoryInformation.refferedBy)} */}
                    <Text style={styles.Genderplacholder}>{"Doctor Name"}</Text>
                    <Picker
                      selectedValue={this.state.mandatoryInformation.refferedBy}
                      style={[{ marginTop: '0.5vw', borderColor: "#CDD1D5", height: '2.5vw', fontSize: '1vw'}, Style.allButtonBorderRadius]}
                      onValueChange={(itemvalue) => {
                        var fields = this.state.mandatoryInformation
                      fields["refferedBy"] = itemvalue
                      this.setState({
                        fields
                      })
                      }}
                    >
                      <Picker.Item label="" value="" />
                      {(this.state.referredByList).length > 0 && (this.state.referredByList).map((item, index) => {
                        return <Picker.Item label={item.name + "-" + item.specialization} value={item.name} />
                      })
                      }
                    </Picker>
                  </View>

                </View>
                <View style={styles.ReferredSource}>
                  {this.renderSubHeadingBorder("Referred Source")}
                  <View style={styles.DoctorNameView}>
                    <Picker
                      selectedValue={this.state.mandatoryInformation.referralsource}
                      style={[{ marginTop: '0.5vw', borderColor: "#CDD1D5", height: '2.5vw', fontSize: '1vw' }, Style.allButtonBorderRadius, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                      onValueChange={(itemvalue) => {
                        var states = this.state.mandatoryInformation;
                        states["referralsource"] = itemvalue;
                        this.setState({ states });
                      }}
                    >
                      <Picker.Item label="Select Source" value="" />
                      {this.state.refferalArray && this.state.refferalArray.length > 0 && this.state.refferalArray.map((item, index) => {
                        return <Picker.Item label={item.source_name} value={item.source_name} />

                      })
                      }
                    </Picker>
                  </View>
                </View>
              </View>

              {this.renderSubHeadingBorder("Patient Information")}

              <View style={{ display: 'flex', flexDirection: 'column', zIndex: -2 }} >
                <View>
                  <View style={[styles.LayouTRow, { marginTop: '1.5vw' }]}>
                    <Text style={[styles.SubHeadingText, { marginLeft: '2vw' }]}>Contact</Text>
                    <View style={styles.MobileTxtBoxView}>
                      <View style={styles.MobileTxtBoxContainer}>
                        {this.renderTextBox("Mobile No *", 10, false, "MI", this.state.mandatoryInformation.mobileNo, this.state.mandatoryInformation.mobileNo ? null : this.state.errorColor)}
                      </View>
                    </View>
                    <View style={[styles.MobileNoTextBoxView, { width: '13vw', }]}>
                      {this.renderTextBox(MandatoryFields?.mobile_number_2 ? "Mobile No2 *" : "Mobile No2", 10, false, "MI", this.state.mandatoryInformation.mobileNo2, MandatoryFields?.mobile_number_2?this.state.mandatoryInformation.mobileNo2?null:this.state.errorColor:null)}
                    </View>
                    <View style={styles.ReceiveMsgView}>
                      <Text style={styles.ReceivePlacholder}>{"Receive SMS"}</Text>

                      <Switch

                        trackColor={{ false: "#767577", true: color.themeDark }}
                        thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        style={{ height: '1.5vw', width: '1vw' }}
                        onValueChange={() => {
                          if (!this.state.isIpPatientCheckouted) {
                            this.setState({
                              toggleSwitchFlag: !this.state.toggleSwitchFlag
                            }, () => {
                              var states = this.state.mandatoryInformation;
                              states["receiveSMSFlag"] = this.state.toggleSwitchFlag;
                              this.setState({ states });
                            })
                          }
                        }}
                        value={this.state.toggleSwitchFlag}
                      />
                    </View>
                  </View>
                </View>
                <View style={[styles.LayouTRow]}>
                  <Text style={[styles.SubHeadingText, { marginLeft: '2vw' }]}>Name</Text>
                  <View style={[styles.TitleTextBoxView, { marginLeft: '1vw' }]}>
                    <Text style={styles.TitlePlaceholder}>{MandatoryFields?.title ?"Title *":"Title"}</Text>
                    <Picker
                      enabled={(((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ? false : true}
                      selectedValue={this.state.mandatoryInformation.type}
                      style={[styles.pickerView, {
                        marginTop: '0.3vw', borderColor: this.state.errorColor && this.state.mandatoryInformation.type == "" && MandatoryFields?.title? "red" : '#CDD1D5',
                        backgroundColor:  (Style.allButtonBorderRadius, (((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ? "#E8E8E8" : color.white)
                        // this.state.errorColor && this.state.mandatoryInformation.type == "" ? "red" : '#CDD1D5'  
                      }]}
                      itemStyle={{ fontSize: '0.9vw' }}
                      onValueChange={(itemvalue) => {
                        var states = this.state.mandatoryInformation;
                        states["type"] = itemvalue;

                        // .dropDownEditFlag

                        if (itemvalue == "Baby of") {
                          states["maritalStatus"] = "Single"
                        } else if (this.state.patientId) {
                          states["type"] = itemvalue;
                          this.setState({ states })
                        } else {
                          if (itemvalue == "Mr") {
                            states["gender"] = "Male";
                          } else if (itemvalue == "Ms" || itemvalue == "Mrs") {
                            if (itemvalue == "Mrs") {
                              states["gender"] = "Female";
                              states["maritalStatus"] = "Married"
                            } else {
                              states["gender"] = "Female";
                            }
                          }
                        }


                        this.setState({ states });
                      }}
                    >
                      <Picker.Item label="" value="" />
                      <Picker.Item label="Dr" value="Dr" />
                      <Picker.Item label="Mr" value="Mr" />
                      <Picker.Item label="Ms" value="Ms" />
                      <Picker.Item label="Mrs" value="Mrs" />
                      {
                        this.state.userType == "OBGYN" ? null :
                          <Picker.Item label="Baby of" value="Baby of" />
                      }

                      <Picker.Item label="Master" value="Master" />
                      <Picker.Item label="Baby" value="Baby" />
                    </Picker>
                  </View>
                  <View style={styles.TitleTextBoxView}>
                    <Text style={styles.Genderplacholder}>{"Gender *"}</Text>
                    {/* {this.state.mandatoryInformation.type ? */}
                    <Picker
                      // disabled
                      // enabled={this.state.isIpPatientCheckouted ? false : true}
                      enabled={
                        this.state.isIpPatientCheckouted ? false :
                          this.state.mandatoryInformation.type == "Baby of" ? true :
                            this.state.patientId && this.state.mandatoryInformation.type !== "Baby of" ? true : true}
                      disabled={(((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) &&(this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ? true : false}
                      selectedValue={this.state.mandatoryInformation.gender}
                      style={[styles.pickerView, {
                        marginTop: '0.3vw', borderColor:
                          this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5',
                        backgroundColor: ((((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) &&(this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true))) ? "#E8E8E8" : color.white
                      }]}
                      itemStyle={{}}
                      onValueChange={(itemvalue) => {
                        var states = this.state.mandatoryInformation;
                        if (states.type != "" && (states.type === "Mr" || states.type === "Ms" || states.type === "Mrs")) {
                          states["gender"] = itemvalue;
                          states["type"] = ""
                          this.setState({ states });
                        } else {
                          states["gender"] = itemvalue;
                          this.setState({ states });
                        }

                      }}
                    >
                      <Picker.Item label="" value="" />
                      <Picker.Item label="Male" value="Male" />
                      <Picker.Item label="Female" value="Female" />
                      <Picker.Item label="Trans" value="Trans" />
                    </Picker>
                    {/* // : <Picker

                    //   selectedValue={this.state.mandatoryInformation.gender}
                    //   style={[Style.pickerView, { marginTop: 5, borderColor: "#CDD1D5" }]}
                    //   itemStyle={{}}
                    //   onValueChange={(itemvalue) => {
                    //     var states = this.state.mandatoryInformation;
                    //     states["gender"] = itemvalue;
                    //     this.setState({ states });
                    //   }}
                    // >
                    //   <Picker.Item label="" value="" />
                    //   <Picker.Item label="Male" value="Male" />
                    //   <Picker.Item label="Female" value="Female" />
                    //   <Picker.Item label="Trans" value="Trans" />
                    // </Picker>} */}
                  </View>
                  <View style={styles.FirstNameView}>
                    {this.state.mandatoryInformation.firstName && this.state.mandatoryInformation.type !== 'Baby of' ?

                      // this.renderTextBox("First Name *", 10, false, "MI", this.state.mandatoryInformation.firstName, this.state.patientId ?  true :false) 
                      // : this.renderTextBox("First Name *", 10, false, "MI", this.state.mandatoryInformation.firstName )}

                      this.renderTextBox("First Name *", 10, false, "MI", this.state.mandatoryInformation.firstName, this.state.mandatoryInformation.firstName ? null : this.state.errorColor,


                        this.state.patientId ? false : false,
                        // this.state.mandatoryInformation.type == "Baby of"? false:
                        //        this.state.patientId  && this.state.mandatoryInformation.type !== "Baby of" ?  true : 
                        //        false




                      ) : this.renderTextBox("First Name *", 10, false, "MI", this.state.mandatoryInformation.firstName, this.state.mandatoryInformation.firstName ? null : this.state.errorColor, this.state.patientId ? false : false,)}
                  </View>
                  <View style={styles.SurnameTextboxView}>
                    {this.renderTextBox(MandatoryFields?.last_name? "Surname *" : "Surname", 10, false, "MI", this.state.mandatoryInformation.surName, MandatoryFields?.last_name? this.state.mandatoryInformation.surName ? null : this.state.errorColor:null, false)}
                  </View>
                </View>
                {/* =========================================== */}
                <View style={styles.LayouTRow}>
                  <Text style={[styles.SubHeadingText, { marginLeft: '2vw' }]}>Age</Text>
                  <View style={styles.DOBTextBoxView}>
                    <Text style={[styles.StatusPlacholder, { marginLeft: '2vw' }]}>{ MandatoryFields?.dob ? "Date of Birth *" : "Date of Birth"}</Text>
                    <DatePicker
                      disabledDate={current => {
                        return current && current > moment().add(0, "days");
                      }}
                      onChange={date => {
                        let converted_date = "";
                        let age = "";
                        var { mandatoryInformation } = this.state;
                        if (date) {
                          var fulldate = new Date(date);
                          // var converted_date = moment(fulldate).format("YYYY-MM-DD");
                          // this.calculate_age(converted_date)
                          converted_date = moment(fulldate).format(dateFormat);
                          age = this.handleAgeCalculation(fulldate);
                        }
                        mandatoryInformation["dob"] = converted_date;
                        mandatoryInformation["approx_dob"] = "";
                        mandatoryInformation["age"] = age;
                        this.setState({ mandatoryInformation })
                      }}
                      defaultValue={""}
                      placeholder={""}
                      format={dateFormatDisplay}
                      disabled={(((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) &&(this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ? true : false}
                      value={
                        this.state.mandatoryInformation.dob ?
                          moment(this.state.mandatoryInformation.dob, dateFormat) :
                          ""
                      }
                      //this.state.mandatoryInformation.dob
                      style={{
                        zIndex: -1, width: '10.6vw',
                        borderColor: this.state.errorColor && this.state.mandatoryInformation.dob == "" && MandatoryFields?.dob? "red" : '#CDD1D5',
                        height: '2.3vw', borderRadius: '0.2vw', fontSize: '1vw', borderWidth: 1, marginTop: '0.3vw', marginLeft: '1.8vw',
                        backgroundColor: (((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ? "#F0F0F0" : color.white
                      }}
                    />

                  </View>
                  <View style={styles.AgeTextBoxView}>

                    {this.renderTextBox("Age *", 10, false, "MI", this.state.mandatoryInformation.age, this.state.mandatoryInformation.age ? null : this.state.errorColor)}
                  </View>
                  <View style={styles.ADOBTextBoxView}>
                    {this.renderTextBox("Approx Date of Birth", 10, false, "MI", this.state.mandatoryInformation.approx_dob, "", true)}
                  </View>
                </View>

                {/* =========================================== */}
                <View>
                  <View style={styles.LayouTRow}>
                    <Text style={[styles.SubHeadingText, { marginLeft: '2vw' }]}>Address</Text>
                    <View style={styles.MobileNoTextBoxView}>
                      {this.renderTextBox( MandatoryFields?.address_line_1 ? "Address 1 *" : "Address 1", 10, false, "OI", this.state.otherInformation.address, MandatoryFields?.address_line_1? this.state.otherInformation.address ? null : this.state.errorColor:null)}
                    </View>

                    <View style={styles.MobileNoTextBoxView}>
                      {this.renderTextBox( MandatoryFields?.address_line_2 ? "Address 2 *" : "Address 2", 10, false, "OI", this.state.otherInformation.address2, MandatoryFields?.address_line_2? this.state.otherInformation.address2 ? null : this.state.errorColor:null)}
                    </View>

                    <View style={styles.CityTextBoxView}>
                      {this.renderTextBox("City *", 10, false, "OI", this.state.otherInformation.city, this.state.otherInformation.city ? null : this.state.errorColor)}
                    </View>

                    <View style={styles.CountryTextBoxView}>
                      <Text style={styles.StatusPlacholder}>{"Country *"}</Text>
                      <CountryDropdown
                        style={{ height: '2.2vw', fontSize: '1vw', marginTop: '0.3vw', paddingLeft: '0.6vw', borderColor: this.state.errorColor && this.state.mandatoryInformation.country == "" ? "red" : '#CDD1D5', borderRadius: 4 }}
                        value={country}
                        onChange={(val) => this.selectCountry(val)}
                        priorityOptions={["IN"]}
                        whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                      />
                    </View>
                    <View style={styles.CountryTextBoxView}>
                      <Text style={styles.StatusPlacholder}>{"State *"}</Text>
                      <RegionDropdown
                        style={{ height: '2.2vw', fontSize: '1vw', marginTop: '0.3vw', paddingLeft: '0.6vw', borderColor: this.state.errorColor && this.state.mandatoryInformation.state == "" ? "red" : '#CDD1D5', borderRadius: 4 }}
                        country={country}
                        value={state}
                        // defaultOptionLabel={""}
                        onChange={(val) => this.selectRegion(val)} />
                    </View>

                    <View style={[styles.MobileNoTextBoxView, { width: '6.2vw' }]}>
                      {this.renderTextBox(MandatoryFields?.pincode ? "Pincode *" : "Pincode", 10, false, "OI", this.state.otherInformation.pincode, MandatoryFields?.pincode? this.state.otherInformation.pincode ? null : this.state.errorColor:null)}
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.OptionalView}>
                <View style={styles.OptionalLine}>
                </View>
                <Text style={styles.OptionalText}>Others</Text>
                <View style={styles.OptionalLine}>
                </View>
              </View>






              <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '2.5vw', marginTop: '1.2vw', zIndex: -1 }}>
                <View style={styles.StatusView}>
                  <Text style={styles.StatusPlacholder}>{MandatoryFields?.marital_status ? "Marital status *": "Marital status"}</Text>

                  <Picker
                    enabled={this.state.isIpPatientCheckouted ? false : true}
                    selectedValue={this.state.mandatoryInformation.maritalStatus}
                    style={[styles.pickerView,
                       { marginTop: '0.3vw',
                     borderColor: this.state.errorColor && this.state.mandatoryInformation.maritalStatus == "" && MandatoryFields?.marital_status? "red" : '#CDD1D5'
                     }, Style.allButtonBorderRadius, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                      var states = this.state.mandatoryInformation;
                      states["maritalStatus"] = itemvalue;
                      this.setState({ states });
                    }}
                  >
                    <Picker.Item label="" value="" />
                    <Picker.Item label="Single" value="Single" />
                    <Picker.Item label="Married" value="Married" />
                  </Picker>
                </View>



                {/* <View style={{ width:100,marginBottom:18,justifyContent: 'center',marginTop:5  }}>
                  <TouchableOpacity style={{ justifyContent: 'center', width: '80%', borderColor: "#888888", height: 35, borderRadius: 10, borderWidth: 1, }}>
                    <Text style={{ paddingLeft: 10,color:"#888888" }}>{this.state.mandatoryInformation.age ? this.state.mandatoryInformation.age : "Age"}</Text>
                  </TouchableOpacity>
                </View> */}





                <View style={styles.FatherTextBoxView}>
                  {this.renderTextBox(MandatoryFields?.father_husband_name? "Father/Husband name *": "Father/Husband name", 10, false, "MI", this.state.mandatoryInformation.fatherOrHusband, MandatoryFields?.father_husband_name? this.state.mandatoryInformation.fatherOrHusband ? null : this.state.errorColor:null)}
                </View>

                {/* <View style={{ width: 150, marginBottom: 18, justifyContent: 'center', marginRight: 15 }}>
                  {this.renderTextBox("Mobile No *", 10, false, "MI", this.state.mandatoryInformation.mobileNo, this.state.mandatoryInformation.mobileNo ? null : this.state.errorColor)}
                </View> */}

                <View style={[styles.MobileNoTextBoxView, { width: '14vw' }]}>
                  {this.renderTextBox(MandatoryFields?.guardian ? "Guardian *" : "Guardian", 10, false, "MI", this.state.mandatoryInformation.guardian, MandatoryFields?.guardian? this.state.mandatoryInformation.guardian ? null : this.state.errorColor:null)}
                </View>


                <View style={[styles.MobileNoTextBoxView, { width: '14vw' }]}>
                  {this.renderTextBox(MandatoryFields?.occupation ? "Occupation *" : "Occupation", 10, false, "MI", this.state.mandatoryInformation.occupation, MandatoryFields?.occupation? this.state.mandatoryInformation.occupation ? null : this.state.errorColor:null)}
                </View>




                <View style={styles.employer_idPicker}>
                  <Text style={styles.StatusPlacholder}>{"Corporate Name"}</Text>

                  <Picker
                    enabled={this.state.isIpPatientCheckouted ? false : true}
                    selectedValue={this.state.otherInformation.employer_id}
                    style={[styles.pickerView, { marginTop: '0.3vw', 
                    borderColor: '#CDD1D5'
                   }, Style.allButtonBorderRadius, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                      var states = this.state.otherInformation;
                      states["employer_id"] = itemvalue;
                      this.setState({ states }, () => {
                        if (states.employer_id == "") {
                          states["employee_ID_No"] = ""
                          this.setState({
                            states
                          })
                        }
                      });
                    }}
                  >
                    <Picker.Item label="None" value="" />
                    {
                      this.state.corpCompanyList && this.state.corpCompanyList.length > 0 ?
                        this.state.corpCompanyList.map((list) => (
                          <Picker.Item label={list.company_name} value={list.id} />
                        ))
                        : null
                    }

                  </Picker>
                </View>

                <View style={[styles.MobileNoTextBoxView, { width: '14vw' }]}>
                  {this.renderTextBox("Employee ID No", 10, false, "OI", this.state.otherInformation.employee_ID_No, (this.state.otherInformation.employer_id != "") && (this.state.otherInformation.employee_ID_No == "") ? this.state.errorColor : null)}
                </View>

                <View style={[styles.MobileNoTextBoxView, { width: '14vw' }]}>
                  {this.renderTextBox(MandatoryFields?.email?"Email Id *":"Email Id", 10, false, "OI", this.state.otherInformation.emailID, MandatoryFields?.email? this.state.otherInformation.emailID ? null : this.state.errorColor:null)}
                </View>


                {/* </View>  */}
              </View>
              {/* <View style={{ flexDirection: 'row', paddingLeft: 40, marginTop: 40 }}> */}
              {/* <View style={{ width:150}}>
                  <DatePicker
                    onChange={date => {
                      var fulldate = new Date(date);1
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");
                      // this.calculate_age(converted_date)
                      var {mandatoryInformation} = this.state;
                      mandatoryInformation["dob"] = converted_date
                      this.setState({ mandatoryInformation })
                    }}
                    defaultValue={moment(this.state.mandatoryInformation.dob, dateFormat)}
                    format={dateFormat}
                    style={{ width: '85%', borderColor: "grey", height: 35, borderRadius: 10, borderWidth: 1, }}
                  />
                </View> */}
              {/* <View style={{ width:150}}>
                  <TouchableOpacity style={{ justifyContent: 'center', width: '80%', borderColor: "#888888", height: 35, borderRadius: 10, borderWidth: 1, }}>
                    <Text style={{ paddingLeft: 10 }}>{this.state.mandatoryInformation.age}</Text>
                  </TouchableOpacity>
                </View> */}
              {/* <View style={{ flex: 0.2 }}>
                  <Picker
                    selectedValue={this.state.mandatoryInformation.maritalStatus}
                    style={Style.pickerView}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                      var states = this.state.mandatoryInformation;
                      states["maritalStatus"] = itemvalue;
                      this.setState({ states });
                    }}
                  >
                    <Picker.Item label="Marital status" value="" />
                    <Picker.Item label="Single" value="Single" />
                    <Picker.Item label="Married" value="Married" />
                  </Picker>
                </View> */}
              {/* <View style={{ flex: 0.35 }}>
                  {this.renderTextBox("Father/Husband name", 10, false, "MI", this.state.mandatoryInformation.fatherOrHusband)}
                </View> */}
              {/* </View> */}

              {/* <View style={{ flexDirection: 'row', paddingLeft: 40, marginTop: 40 }}> */}
              {/* <View style={{ width: '20%' }}>
                  {this.renderTextBox("Mobile No", 10, false, "MI", this.state.mandatoryInformation.mobileNo)}
                </View> */}
              {/* <View style={{ width: '30%' }}>
                  {this.renderTextBox("City", 10, false, "OI", this.state.otherInformation.city)}
                </View> */}
              {/* <View style={{ width: "20%" }}>
                  <Picker
                    selectedValue={this.state.mandatoryInformation.country}
                    style={Style.pickerView}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                      var states = this.state.mandatoryInformation;
                      states["country"] = itemvalue;
                      this.setState({ states });
                    }}
                  >
                    <Picker.Item label="Country" value="" />
                    <Picker.Item label="India" value="India" />
                  </Picker>
                </View> */}

              {/* <View style={{ width: "28%" }}>
                  <Picker
                    selectedValue={this.state.mandatoryInformation.country}
                    style={Style.pickerView}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                      var states = this.state.mandatoryInformation;
                      states["country"] = itemvalue;
                      this.setState({ states });
                    }}
                  >
                    <Picker.Item label="Country" value="" />
                    <Picker.Item label="India" value="India" />
                  </Picker>
                </View> */}


              {/* </View> */}

              {/* <View style={{ paddingLeft: 40, marginTop: 40, }}>
                <View style={{ flex: 0.2, flexDirection: 'row', }}>
                  <Switch
                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                    thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={() => {

                      this.setState({
                        toggleSwitchFlag: !this.state.toggleSwitchFlag
                      }, () => {
                        var states = this.state.mandatoryInformation;
                        states["receiveSMSFlag"] = this.state.toggleSwitchFlag;
                        this.setState({ states });
                      })
                    }}
                    value={this.state.toggleSwitchFlag}
                  />
                  <Text style={{ marginLeft: 10, fontSize: 16 }}>{"Receive SMS"}</Text>
                </View>
              </View> */}

              {/* {this.renderSubHeadingBorder("OTHER INFORMATION")}
              <View style={{ flexDirection: 'row', paddingLeft: 40, marginTop: 40 }}>
                {this.renderTextBox("Email Id", 10, false, "OI", this.state.otherInformation.emailID)}
                {this.renderTextBox("Address", 10, false, "OI", this.state.otherInformation.address)}
              </View>
              <View style={{ flexDirection: 'row', paddingLeft: 40, marginTop: 40 }}>
              {this.renderTextBox("Occupation", 10, false, "MI", this.state.mandatoryInformation.occupation)}
                {this.renderTextBox("Pincode", 10, false, "OI", this.state.otherInformation.pincode)}
              </View> */}


              {/* {this.renderSubHeadingBorder("IDENTITY PROOF")}
              <Text style={{ color: "grey", fontSize: 12, marginLeft: 40, marginTop: 20 }}>{"* Either Aadhar Number or two other goverment id proofs are mandatory"}</Text>
              {this.renderIDProof()}
              {this.renderIDProofField()} */}
              {/* </View> */}


              {
                this.state.userType == "LAB" ? null :
                  <View>
                    {this.renderSubHeadingBorder("Emergency Contact")}
                    <View style={styles.EmeContact}>
                      <View style={styles.EmergencyTextBox}>{this.renderTextBox(MandatoryFields?.emergency_name?"First Name *":"First Name", 10, false, "CTI", this.state.careTakerInformation.firstName, MandatoryFields?.emergency_name? this.state.careTakerInformation.firstName ? null : this.state.errorColor:null)}</View>
                      <View style={styles.EmergencyTextBox}>{this.renderTextBox(MandatoryFields?.emergency_number?"Mobile No *":"Mobile No", 10, false, "CTI", this.state.careTakerInformation.mobileNo, MandatoryFields?.emergency_number? this.state.careTakerInformation.mobileNo ? null : this.state.errorColor:null)}</View>
                      <View style={styles.EmergencyTextBox}>{this.renderTextBox(MandatoryFields?.emergency_relationship?"Relationship *":"Relationship", 10, false, "CTI", this.state.careTakerInformation.relationship,MandatoryFields?.emergency_relationship? this.state.careTakerInformation.relationship ? null : this.state.errorColor:null)}</View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', marginTop: 40, paddingLeft: 40 }}>
                  {this.renderTextBox("Email Id", 10, false, "CTI", this.state.careTakerInformation.emailID)}
                  {this.renderTextBox("Address", 10, false, "CTI", this.state.careTakerInformation.address)}
                </View>
                <View style={{ flexDirection: 'row', marginTop: 40, paddingLeft: 40 }}>
                  {this.renderTextBox("City", 10, false, "CTI", this.state.careTakerInformation.city)}
                  {this.renderTextBox("Pincode", 10, false, "CTI", this.state.careTakerInformation.pincode)}
                </View> */}
                  </View>
              }
              <View style={styles.InformationView}>
                {/* <TouchableOpacity style={{ width: '20%', height: 40, borderRadius: 10, backgroundColor: color.themeDark, justifyContent: 'center' }} onPress={() => { this.createPatient() }}>
                  <Text style={{ textAlign: 'center', color: 'white' }}>{ this.state.alreadyCreatedPatient ?  "Save Patient Details" :"Create Patient"}</Text>
                </TouchableOpacity> */}
                {
                  this.state.mandatoryInformation.isAmaPatient ?
                    <Text style={[styles.AMAPatientsText, { color: color.brightRed }]}>{"This patient is under AMA"}</Text>
                    : null
                }

                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    disabled={(this.state.isIpPatientCheckouted && this.state.isCreatePatientClicked) ? true : false}
                    // this.state.isIpPatientCheckouted
                    onPress={() => {
                      if ((this.state.isRoleAdmin && this.state.isEditAction) || this.state.isEditAction) {
                        this.setState({
                          isUpdateClick: this.state.alreadyCreatedPatient ? true : false
                        })
                      } else {
                        this.state.userType != Constants.ROLE_OPTOMETRY ? this.createPatient() : null
                      }
                    }} style={[{
                      marginLeft: '1.2vw', paddingTop: '0.5vw', paddingLeft: '1.1vw',
                      paddingRight: '1.1vw', paddingBottom: '0.5vw', backgroundColor: this.state.isCreatePatientClicked && !this.state.isEditAction ? "#888888" : color.themeDark,
                    }, Style.allButtonBorderRadius]}>
                    <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{this.state.alreadyCreatedPatient || this.state.isEditAction ? "Save Patient Details" : "Create Patient"}</Text>
                  </TouchableOpacity>
                  {this.state.alreadyCreatedPatient || (this.state.isEditAction) ? null :
                    <TouchableOpacity
                      onPress={() => {
                        this.clearDetails()
                      }} style={[{
                        marginLeft: '1.2vw', paddingTop: '0.5vw', paddingLeft: '1.1vw',
                        paddingRight: '1.1vw', paddingBottom: '0.5vw', backgroundColor: this.state.isCreatePatientClicked ? "#888888" : color.themeDark,
                      }, Style.allButtonBorderRadius]}>
                      <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Clear"}</Text>
                    </TouchableOpacity>
                  }
                </View>
                {this.state.successOrErrorMessage ? (
                  <View style={{ height: '2.5vw', width: '40%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center', marginTop: '0.8vw' }}>
                    <Text style={{
                      color: "white", textAlign: "center", justifyContent: "center", fontSize: '1vw'
                    }}>
                      {this.state.successOrErrorMessage || null}</Text>
                  </View>
                ) : null}

              </View>
              {
                (((this.state.alreadyCreatedPatient) || ( this.state.isEditAction)) && (!this.state.isRoleAdmin) && (this.state.FO_admin_setting?.allow_user_demographics_edit ? !this.state.FO_admin_setting?.allow_user_demographics_edit : true)) ?
                  <Text style={[styles.ContactAdministraorText, { color: color.brightRed }]}>{"Note - To update the disabled fields, kindly contact administrator"}</Text>
                  : null
              }
            </View>

          </ScrollView>
        </View>

        {/* <ScrollView> */}
        <ScrollView
          // ref={scrollView => this.scrollView = scrollView}
          // onContentSizeChange={() => {
          //   this._onContentSizeChange();
          // }}

          showsVerticalScrollIndicator={false}
          style={{ width: "30%", height: "88vh", backgroundColor: color.themeShade, }}
        // contentContainerStyle={{ flex: 1 }}
        >
          {(userType === Constants.ROLE_FO && this.state.alreadyCreatedPatient) || (userType === Constants.ROLE_FO && this.state.isEditAction) ?
            <View style={[Style.rightView, { padding: '1.2vw', width: "100%" }]}>
              <View style={{ flexDirection: "row", height: '2vw', backgroundColor: color.sectionHeadingLiteColor, justifyContent: "space-around", alignItems: "center" }}>
                <TouchableOpacity
                  style={{}}
                  onPress={() => {
                    if (this.state.selectedTab != "insurance") {
                      this.setState({
                        selectedTab: "insurance"
                      })
                    }
                  }}>
                  <Text style={{ fontSize: '1vw', fontWeight: "bold", color: this.state.selectedTab === "insurance" ? color.themeDark : "" }}>{"Insurance"}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{}}
                  onPress={() => {
                    if (this.state.selectedTab != "corporate") {
                      this.setState({
                        selectedTab: "corporate"
                      })
                    }
                  }}>
                  <Text style={{ fontSize: '1vw', fontWeight: "bold", color: this.state.selectedTab === "corporate" ? color.themeDark : "" }}>{"Corporate"}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{}}
                  onPress={() => {
                    if (this.state.selectedTab != "documents") {
                      this.setState({
                        selectedTab: "documents"
                      })
                    }
                  }}>
                  <Text style={{ fontSize: '1vw', fontWeight: "bold", color: this.state.selectedTab === "documents" ? color.themeDark : "" }}>{"Documents"}</Text>
                </TouchableOpacity>
              </View>
              {this.state.selectedTab === "documents" ?
                <View style={{ marginTop: this.state.patientId ? '6.8vw' : '5.5vw', marginLeft: '0.6vw' }}>
                  {this.renderSubHeadingBorder("IDENTITY PROOF")}
                  <Text style={styles.IndentityMsg}>{"* Either Aadhaar Number or two other goverment id proofs are mandatory"}</Text>
                  {this.renderIDProof()}
                  {this.renderIDProofField()}
                  {/* <Text style={{color: "grey", fontSize: 12, marginTop: 20,fontWeight:'700' }}> Che</Text> */}

                  {/* <Image source={{uri: this.state.idProofDocs.aadharCard ? this.state.idProofDocs.aadharCard : "" }} style={{height:50,width:50}}/> */}

                </View>
                : this.state.selectedTab === "corporate" ?
                  <View>
                    {Object.keys(corporateData).length > 0 ?
                      <View>
                        <View style={[styles.LeftSideTextboxView, { backgroundColor: color.white }]}>
                          <View style={{ flexDirection: "row", flex: 1 }}>
                            <Text style={styles.CompanyNameText}>{"Company Name"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.4, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={corporateData.company_name}
                              editable={false}
                            />
                          </View>
                          <View style={styles.EmmployeeIDView}>
                            <Text style={styles.CompanyNameText}>{"Employee ID"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.4, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={corporateData.employee_id}
                              editable={false}
                            />
                          </View>
                          <Icon
                            name="closecircleo"
                            size={15}
                            color={color.white}
                            onPress={() => {
                              this.setState({
                                selectedDeleteId: corporateData.id,
                                DeleteConfirmationPopup: true
                              })
                            }}
                            style={{ position: "absolute", top: 0, right: 0, backgroundColor: color.brightRed, borderRadius: "inherit" }}
                          />
                        </View>
                      </View>
                      : this.state.isCorporateAddClick ?
                        <View>
                          <View style={styles.CompanyAddClickView}>
                            <Text style={styles.CompanyLabelText}>{"Company Name"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.3, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={this.state.corporateCompanyName}
                              onChange={(text) => {
                                var isNum = this.avoidOnlySplCharValidation(text.target.value)
                                if (isNum) {
                                  this.setState({
                                    corporateCompanyName: text.target.value
                                  })
                                }
                              }}
                            />
                          </View>
                          <View style={styles.EmmployeeIDView}>
                            <Text style={styles.CompanyLabelText}>{"Employee ID"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.3, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={this.state.corporateEmployeeId}
                              onChange={(text) => {
                                var isNum = this.avoidOnlySplCharValidation(text.target.value)
                                if (isNum) {
                                  this.setState({
                                    corporateEmployeeId: text.target.value
                                  })
                                }
                              }}
                            />
                          </View>
                          <View style={styles.AddBtnView}>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.4vw', borderRadius: 4, width: "fit-content" }}
                              onPress={() => {
                                this.setState({
                                  AddingServiceType: "corporate",
                                  isAddInsurancePopup: true
                                })
                              }}>
                              <Text style={[styles.AddBtnText, { color: color.white }]}>{"Add"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.4vw', borderRadius: 4, width: "fit-content", marginLeft: '1.2vw' }}
                              onPress={() => {
                                this.setState({
                                  isCorporateAddClick: false,
                                  corporateEmployeeId: "",
                                  corporateCompanyName: "",
                                })
                              }}>
                              <Text style={[styles.AddBtnText, { color: color.white }]}>{"Cancel"}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        :
                        <View>
                          <View style={styles.AddCorporateView}>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.3vw', borderRadius: 4, width: "fit-content", flexDirection: "row" }}
                              onPress={() => {
                                this.setState({
                                  isCorporateAddClick: true,
                                })
                              }}>
                              <PlusIcon name='plus-a' size={'0.8vw'} color={color.white} style={{ alignSelf: "center" }} />
                              <Text style={[styles.AddCorporateBtnText, { color: color.white }]}>{"Add Corporate"}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                    }
                  </View>
                  : this.state.selectedTab === "insurance" ?
                    <View>
                      {(this.state.insuranceData).length > 0 ?
                        <View>
                          {(this.state.insuranceData).map((item) => {
                            return (
                              <View style={[styles.InsuranseTextBoxView, { backgroundColor: color.white }]}>
                                <View style={{ flexDirection: "row", flex: 1 }}>
                                  <Text style={styles.CompanyNameText}>{"Company Name"}</Text>
                                  <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                                  <TextInput
                                    style={{ flex: 0.4, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                                    value={item.company_name}
                                    editable={false}
                                  />
                                </View>
                                <View style={styles.EmmployeeIDView}>
                                  <Text style={styles.CompanyNameText}>{"Policy Number"}</Text>
                                  <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                                  <TextInput
                                    style={{ flex: 0.4, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                                    value={item.policy_number}
                                    editable={false}
                                  />
                                </View>
                                <Icon
                                  name="closecircleo"
                                  size={15}
                                  color={color.white}
                                  onPress={() => {
                                    this.setState({
                                      selectedDeleteId: item.id,
                                      DeleteConfirmationPopup: true
                                    })
                                  }}
                                  style={{ position: "absolute", top: 0, right: 0, backgroundColor: color.brightRed, borderRadius: "inherit" }}
                                />
                              </View>
                            )
                          })}
                        </View>
                        : null
                      }
                      {this.state.isInsuranceAddClick ?
                        <View>
                          <View style={styles.CompanyAddClickView}>
                            <Text style={styles.CompanyLabelText}>{"Company Name"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.3, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={this.state.insuranceCompanyName}
                              onChange={(text) => {
                                var isNum = this.avoidOnlySplCharValidation(text.target.value)
                                if (isNum) {
                                  this.setState({
                                    insuranceCompanyName: text.target.value
                                  })
                                }
                              }}
                            />
                          </View>
                          <View style={styles.EmmployeeIDView}>
                            <Text style={styles.CompanyLabelText}>{"Policy Number"}</Text>
                            <Text style={{ flex: 0.1, fontSize: '1vw' }}> {":"} </Text>
                            <TextInput
                              style={{ flex: 0.3, borderColor: color.lightGray, borderWidth: 1, paddingLeft: '0.3vw', height: '2.5vw', width: '14vw', fontSize: '1vw' }}
                              value={this.state.insurancePolicyNumber}
                              onChange={(text) => {
                                var isNum = this.avoidOnlySplCharValidation(text.target.value)
                                if (isNum) {
                                  this.setState({
                                    insurancePolicyNumber: text.target.value
                                  })
                                }
                              }}
                            />
                          </View>
                          <View style={styles.AddBtnView}>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.4vw', borderRadius: 4, width: "fit-content" }}
                              onPress={() => {
                                this.setState({
                                  AddingServiceType: "insurance",
                                  isAddInsurancePopup: true
                                })
                              }}>
                              <Text style={[styles.AddBtnText, { color: color.white }]}>{"Add"}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.4vw', borderRadius: 4, width: "fit-content", marginLeft: '1.2vw' }}
                              onPress={() => {
                                this.setState({
                                  isInsuranceAddClick: false,
                                  insuranceCompanyName: "",
                                  insurancePolicyNumber: "",
                                })
                              }}>
                              <Text style={[styles.AddBtnText, { color: color.white }]}>{"Cancel"}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        :
                        <View>
                          <View style={styles.AddCorporateView}>
                            <TouchableOpacity
                              style={{ backgroundColor: color.themeDark, padding: '0.4vw', borderRadius: 4, width: "fit-content", flexDirection: "row" }}
                              onPress={() => {
                                this.setState({
                                  isInsuranceAddClick: true
                                })
                              }}>
                              <PlusIcon name='plus-a' size={'0.8vw'} color={color.white} style={{ alignSelf: "center" }} />
                              <Text style={[styles.AddInsuranceText, { color: color.white }]}>{"Add Insurance"}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      }

                    </View>
                    : null}


              {/* {
              this.state.showNextAppoinmentView ?
                <View>
                  <View>
                    <Text style={{ marginTop: 50, color: 'black', fontSize: 18, marginLeft: 40 }}>{this.state.alreadyCreatedPatient ? null : "Create Appointment"}</Text>
                  </View>

                  <View style={{ marginTop: this.state.alreadyCreatedPatient ? 10 : 35, borderRadius: 80, backgroundColor: color.white, alignItems: "center", justifyContent: "center", alignContent: 'center', alignSelf: 'center' }}>
                    <Image
                      source={this.state.patientDetails && this.state.patientDetails.photo_url ? {uri: this.state.patientDetails.photo_url}:  require("../../../../assets/images/sample_profile.png")}
                      style={{ height: 110, width: 110, borderRadius: 50 }}
                    />
                  </View>

                  <View>
                    <Text style={{ color: 'black', fontSize: 18, textAlign: 'center', marginTop: 15 }}>  {this.state.patientDetails && this.state.patientDetails.name ? this.state.patientDetails.name : null}</Text>
                    <Text style={{ color: 'black', fontSize: 18, textAlign: 'center', marginTop: 10 }}>{this.state.patientDetails && this.state.patientDetails.patient_account_number ? this.state.patientDetails.patient_account_number : null}{this.state.patientDetails && this.state.patientDetails.patient_account_number ? " | " : null}</Text>
                    <Text style={{ color: 'black', fontSize: 16, textAlign: 'center', marginTop: 10 }}>{this.state.patientDetails && this.state.patientDetails.dob ?  this.state.patientDetails.dob : null}{" | "}{this.state.patientDetails && this.state.patientDetails.age ? this.state.patientDetails.age : null}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 35, justifyContent: 'center' }}>
                    <View>
                      <PhoneCallIcon name="phone-call" size={23} color={color.themeDark} />
                    </View>

                    <Text style={{ color: "black", fontSize: 16, marginLeft: 20 }}>{this.state.patientDetails && this.state.patientDetails.mobile_number ? this.state.patientDetails.mobile_number : null}</Text>

                  </View>


                  {
                    this.state.alreadyCreatedPatient ?
                      <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 25, marginBottom: 15,marginRight:20,marginLeft:20 }}>
                          <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                            <Location name="location-pin"  style={{marginTop:5,marginRight:5}}  size={23} color={color.themeDark} />
                            <View>
                              <Text style={{ color: "black", fontSize: 16,marginTop:5 }}>{this.state.otherInformation.address}</Text>
                              <Text style={{ color: "black", fontSize: 16, marginTop:5}}>{this.state.otherInformation.city}</Text>
                              <Text style={{ color: "black", fontSize: 16, marginTop:5}}>{this.state.otherInformation.pincode}</Text>
                            </View>
                          </View>
                          {
                            this.state.mandatoryInformation.occupation ?
                              <View style={{ marginRight: 10 }}>
                                <Text style={{ color: "black", fontSize: 16, }}>{this.state.mandatoryInformation.occupation}</Text>
                              </View>
                              : null
                          }
                        </View>


                          <FlatList
                            data={this.state.collectedIdProofs}
                            numColumns={2}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            // scrollEnabled={false}
                            contentContainerStyle={{alignSelf:'center'}}
                            renderItem={({ item }) => this.showIDProofRightSide(item)}
                          />

                        <View>
                          {
                            this.state.careTakerInformation.firstName ?
                              <Text style={{ color: 'black', fontSize: 18, textAlign: 'center', marginTop: 15 }}>{this.state.careTakerInformation.firstName}</Text>
                              : null
                          }
                          {
                            this.state.careTakerInformation.relationship ?
                              <Text style={{ color: 'black', fontSize: 16, textAlign: 'center', marginTop: 15 }}>{this.state.careTakerInformation.relationship + " of " + this.state.patientDetails && this.state.patientDetails.name ?this.state.patientDetails.name : null}</Text>
                              : null
                          }

                        </View>


                        {
                          this.state.careTakerInformation.mobileNo ?
                            <View style={{ flexDirection: 'row', marginTop: 20, justifyContent: 'center' }}>
                              <View>
                                <PhoneCallIcon name="phone-call" size={23} color={color.themeDark} />
                              </View>

                              <Text style={{ color: "black", fontSize: 16, marginLeft: 20 }}>{this.state.careTakerInformation.mobileNo}</Text>
                            </View>
                            : null
                        }



                        {
                          this.state.careTakerInformation.address || this.state.careTakerInformation.city || this.state.careTakerInformation.pincode ?

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 25 ,marginRight:20,marginLeft:20}}>
                              <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                                <Location name="location-pin" style={{marginTop:5,marginRight:5}} size={23} color={color.themeDark} />
                                <View>
                                  <Text style={{ color: "black", fontSize: 16, marginTop:5}}>{this.state.careTakerInformation.address}</Text>
                                  <Text style={{ color: "black", fontSize: 16, marginTop:5}}>{this.state.careTakerInformation.city}</Text>
                                  <Text style={{ color: "black", fontSize: 16, marginTop:5}}>{this.state.careTakerInformation.pincode}</Text>
                                </View>
                              </View>
                            </View> : null
                        }

                      </View>
                      : null
                  }


                  {
                    !this.state.alreadyCreatedPatient ?
                      <View>
                        <View style={{ width: '65%', justifyContent: 'center', marginRight: 20, alignContent: 'center', alignSelf: 'center' }}>
                          <Picker
                            selectedValue={this.state.selectedClinic['id']}
                            style={{ borderRadius: 20, height: 35, paddingLeft: 10 }}
                            itemStyle={{ fontSize: 12 }}
                          >
                            <Picker.Item key={-1} label={"Select Clinic"} value={0} />
                            {this.state.clinicList.map((i, index) => (
                              <Picker.Item key={index} label={i.clinic_name} value={i.clinic_id} />
                            ))}
                          </Picker>
                        </View>



                        <View style={{ marginTop: 30 }}>
                          {this.showCalendar("HC")}
                        </View>

                        {this.timePicker()}

                        <View style={{ alignItems: 'center', zIndex: -1, marginTop: 30 }}>
                          <TextInput
                            multiline={true}
                            placeholder={"Purpose Of visit"}
                            style={{ height: 80, width: '78%', color: "grey", paddingTop: 10, paddingLeft: 20, borderWidth: 1, borderColor:color.lightGray, borderRadius: 10 }}
                            onChangeText={(text) => {
                              this.setState({
                                purposeOfVisitsInNextAppoinment: text
                              })
                            }}
                          />
                        </View>

                        <View style={{ marginTop: 30, alignItems: 'center', zIndex: -1 }}>
                          <TouchableOpacity style={{ height: 40, width: '78%', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 10 }}
                            onPress={() => {
                              this.createNextAppoinment();
                            }}
                          >
                            <Text style={{ color: "white", textAlign: 'center' }}>{"Create Appointment"}</Text>
                          </TouchableOpacity>
                        </View>

                      </View>
                      : null
                  }



                </View>

                : null
            } */}

            </View>
            :
            <View style={{ marginTop: this.state.patientId ? '6.8vw' : '5.5vw', marginLeft: '0.6vw' }}>
              {this.renderSubHeadingBorder("IDENTITY PROOF")}
              <Text style={styles.IndentityMsg}>{"* Either Aadhaar Number or two other goverment id proofs are mandatory"}</Text>
              {this.renderIDProof()}
              {this.renderIDProofField()}
              {/* <Text style={{color: "grey", fontSize: 12, marginTop: 20,fontWeight:'700' }}> Che</Text> */}

              {/* <Image source={{uri: this.state.idProofDocs.aadharCard ? this.state.idProofDocs.aadharCard : "" }} style={{height:50,width:50}}/> */}

            </View>
          }
          {this.state.DeleteConfirmationPopup ?
            <View style={{ zIndex: 1, position: "absolute", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", }}>
              {this.onDeleteInsurancePopup()}
            </View>
            : this.state.isAddInsurancePopup ?
              <View style={{ zIndex: 1, position: "absolute", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", }}>
                {this.onAddInsurancePopup()}
              </View>
              : null
          }
          {this.state.successOrErrorMessageLeft ? (
            <View style={{ height: '2.5vw', width: '80%', backgroundColor: this.state.successMessageFlagLeft ? 'green' : this.state.errorMessageFlagLeft ? 'red' : null, alignItems: 'center', justifyContent: 'center', marginTop: '0.8vw', zIndex: 1, position: "absolute", alignSelf: "center", display: "flex", top: "80%", paddingVertical: '1vw', opacity: 0.7 }}>
              <Text style={styles.SucessMsgText}>
                {this.state.successOrErrorMessageLeft || null}</Text>
            </View>
          ) : null}
        </ScrollView>

        <View style={{ zIndex: 1, position: 'absolute', width: '100%' }}>
          {
            this.state.isAddNewReferredBy ?
              <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: 'flex-start', }}>
                <View
                  style={styles.DoctorsNameView}>
                  <View style={{ flex: 0.5 }}>
                    <TextInput
                    maxLength={30}
                      style={styles.referredDocAddInputs}
                      placeholder={"Doctor's Name"}
                      value={this.state.referredDocName}
                      onChange={(event) => {
                        var isNum = this.alphabetvalidation(event.target.value)
                        if (isNum) {
                          if ((event.target.value).trim() != "") {
                            this.setState({
                              referredDocName: event.target.value
                            })
                          } else {
                            this.setState({
                              referredDocName: ""
                            })
                          }
                        }
                      }}
                    />
                     <TextInput
                      style={styles.referredDocAddInputs}
                      placeholder={"Doctor's Registration Number"}
                      value={this.state.referredDocRegNumber}
                      maxLength={7}
                      onChange={(event) => {
                        var isNum = this.numberValidation(event.target.value)
                        if (isNum || (event.target.value).trim() == "") {
                          if ((event.target.value).trim() != "") {
                            this.setState({
                              referredDocRegNumber: event.target.value
                            })
                          } else {
                            this.setState({
                              referredDocRegNumber: ""
                            })
                          }
                        }
                      }}
                    />
                    <TextInput
                      style={styles.referredDocAddInputs}
                      placeholder={"Doctor's Qualification"}
                      value={this.state.referredDocQualification}
                      onChange={(event) => {
                        var isNum = this.alphabetvalidationWithMajorSplChar(event.target.value)
                        if (isNum) {
                          if ((event.target.value).trim() != "") {
                            this.setState({
                              referredDocQualification: event.target.value
                            })
                          } else {
                            this.setState({
                              referredDocQualification: ""
                            })
                          }
                        }
                      }}
                    />
                    <TouchableOpacity
                      disabled={(this.state.referredDocQualification != "" && this.state.referredDocName != "" && this.state.referredDocHospital != "" && this.state.referredDocSpecialization != "" && this.state.referredDocMobNumber != "" && this.state.referredDocMobNumber.length == 10   && this.state.referredDocRegNumber != "" ) ? false : true}
                      style={[styles.referredDocAddBtn, { marginRight: '1vw', alignSelf: "flex-end", backgroundColor: (this.state.referredDocQualification != "" && this.state.referredDocName != "" && this.state.referredDocHospital != "" && this.state.referredDocSpecialization != "" && this.state.referredDocMobNumber != "" && this.state.referredDocMobNumber.length == 10   && this.state.referredDocRegNumber != "") ? color.themeDark : color.disableLabelColor }]}
                      onPress={() => {
                        this.onPressReferredAdd()
                      }}
                    >
                      <Text style={styles.SaveBtnText}>{"Save"}</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ flex: 0.5 }}>
                    <TextInput
                      style={[styles.referredDocAddInputs, {borderColor : this.state.referredDocMobNumber == "" || this.state.referredDocMobNumber.length == 10 ? "#CDD1D5" : color.red}]}
                      placeholder={"Doctor's Mobile Number"}
                      value={this.state.referredDocMobNumber}
                      maxLength={10}
                      onChange={(event) => {
                        var isNum = this.numberValidation(event.target.value)
                        if (isNum || (event.target.value).trim() == "") {
                          if ((event.target.value).trim() != "") {
                            this.setState({
                              referredDocMobNumber: event.target.value
                            })
                          } else {
                            this.setState({
                              referredDocMobNumber: ""
                            })
                          }
                        }
                      }}
                    />
                    <TextInput
                      style={styles.referredDocAddInputs}
                      placeholder={"Doctor's Specialization"}
                      value={this.state.referredDocSpecialization}
                      maxLength={30}
                      onChange={(event) => {
                        var isNum = this.alphabetvalidation(event.target.value)
                        if (isNum) {
                          if ((event.target.value).trim() != "") {
                            this.setState({
                              referredDocSpecialization: event.target.value
                            })
                          } else {
                            this.setState({
                              referredDocSpecialization: ""
                            })
                          }
                        }
                      }}
                    />
                    <TextInput
                      style={styles.referredDocAddInputs}
                      placeholder={"Hospital/Clinic Name"}
                      value={this.state.referredDocHospital}
                      onChange={(event) => {
                        if ((event.target.value).trim() != "") {
                          this.setState({
                            referredDocHospital: event.target.value
                          })
                        } else {
                          this.setState({
                            referredDocHospital: ""
                          })
                        }
                      }}
                    />
                    <TouchableOpacity style={[styles.referredDocAddBtn, { marginLeft: '1vw', backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1 }]}
                      onPress={() => {
                        this.setState({
                          isAddNewReferredBy: false,
                          referredDocHospital: "",
                          referredDocSpecialization: "",
                          referredDocQualification: "",
                          referredDocName: "",
                          referredDocMobNumber: "",
                          referredDocRegNumber: "",
                        })
                      }}
                    >
                      <Text style={[styles.CancelBtnText, { color: color.themeDark }]}>{"Cancel"}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              : null
          }
        </View>
        {this.state.isEditAction && this.state.alreadyCreatedPatient && this.state.isUpdateClick ?
          <View style={{ justifyContent: "center", alignItems: "center", height: screenHeight, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 2, position: 'absolute' }}>
            <View style={{ width: "100%", height: "100%", alignItems: "center", flex: 1, justifyContent: "center", paddingLeft: '0.6vw' }}>
              <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: '1.2vw' }}>
                <Text style={{ fontSize: '1.1vw', fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to update ? "}</Text>

                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", flexWrap: "wrap-reverse" }}>

                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: '0.5vw' }}
                    onPress={() => {
                      this.state.userType != Constants.ROLE_OPTOMETRY ? this.createPatient() : null
                    }}
                  //  disabled = {this.state.isYesButton}
                  >
                    <Text style={styles.YesBtnText}>{"Yes"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: '0.5vw' }}
                    onPress={() => {
                      this.setState({
                        isUpdateClick: false
                      })
                    }}>
                    <Text style={styles.YesBtnText}>{"No"}</Text>
                  </TouchableOpacity>

                </View>

              </View>
            </View>
          </View> : null
        }
        <View style={{ zIndex: 1, position: 'absolute', width: '100%' }}>
          {
            this.state.searchPatientData && this.state.searchPatientData.length > 0 ?

              <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: 'flex-start', paddingLeft: '0.6vw' }}>

                <View style={styles.CreateAppointmentView}>
                  <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: screenHeight / 2 }}>
                    {
                      this.state.searchPatientData.map((item, index) => {
                        return (
                          <View key={index} style={styles.CreateAppointmentReturnView}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <Image source={item.photo_url ? item.photo_url : require('../../../../assets/images/sample_profile.png')}
                                style={Style.appointmentTableRowImg}
                                resizeMode='stretch' />
                              <Text style={styles.CreateAppoinPatientName} >{item.first_name}</Text>
                              <Text style={styles.CreateAppoinAccountNum}> {item.patient_account_number}{item.patient_account_number ? " | " : null}{item.mobile_number}</Text>
                            </View>


                            {
                            // (item.appointment && Object.keys(item.appointment).length > 0) || (item.admission_date != null) ?
                            //   <Text style={styles.CreateAppoinText}>{this.props.isIpDemographic ? moment(item.admission_date).format('DD-MM-YYYY') : moment(item.appointment.appointment_date).format('DD-MM-YYYY')}</Text> :
                              <TouchableOpacity style={[styles.CreateAppointmentTouch, { backgroundColor: color.themeDark }]}
                                onPress={() => {
                                  // this.props.clearSearchTextBoxValue()
                                  // this.getcurrenDateMonthYear()
                                  this.setState({

                                    patientId: item.patient_id ? item.patient_id : item.id,
                                    searchPatientData: [],

                                    ShowCreateappointment: true,
                                  }, () => {
                                    // alert(this.state.ShowCreateAppointmentPatientId)
                                    if (this.props.isIpDemographic) {
                                      this.ipPatientDetailFetch()
                                    } else {
                                      this.setModalVisible(true)
                                    }
                                  })

                                }}
                              >
                                <Text style={styles.CreateAppointmentText}>{"Create Appointment"}</Text>
                              </TouchableOpacity>
                            }
                          </View>
                        );
                      })

                    }
                    <View style={{ alignItems: 'center' }}>
                      <TouchableOpacity style={styles.SkipBtnTouch}
                        onPress={() => {
                          this.setState({
                            searchPatientData: []
                          })
                        }}
                      >
                        <Text style={styles.SkipBtnText}>{"Skip"}</Text>
                      </TouchableOpacity>
                    </View>
                  </ScrollView>
                </View>

              </View>
              : null
          }
        </View>

        {/* </ScrollView> */}
      </View>
      // </TouchableWithoutFeedback>
    );
  }

  modelClose(flag, status = "") {
    this.setState({
      modalVisible: flag,             // Next AppoinmentModal Close
      // showNextAppoinmentTimer:false   // AppoinmentModal timer drop down close
    }, () => {
      if (status == "success") {
        this.props.changeScreen("home");
        this.props.modelClose(false, "success")
        // this.props.navigation.navigate('home',{appointmentReload:true})
      }

    }
    )
  }

  setModalVisible = status => {

    let { userType } = this.state;

    // var service_url = Constants.VITALS_DETAILS
    let service_url = '';

    if (userType === Constants.ROLE_FO) {

      service_url = Constants.FO_DOCTOR_PATIENT_INFO + this.state.patientId;
    } else {

      service_url = Constants.DOCTOR_PATIENT_INFO +  this.state.patientId;
    }


    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientInfoSuccess,
      this.getPatientInfoFailure
    );
  }

  getPatientInfoSuccess = response => {
    if (response.status === "success") {

      let states = this.state;
      states["modalVisible"] = true;
      this.props.modelClose(true, "", this.state.patientId, response)

      // this.setState({
      //   nextAppoinmentatPientDetails: response,
      //   states,
      //   modalVisible: true
      // })
    }
  };

  getPatientInfoFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  }


  showIDProofRightSide(proof) {
    // "image":proof[i].doc_key,
    // "number":proof[i].number,
    // "type":"Voter Id",
    // "imageName":"voterID.jpeg"
    // console.log("proof -------------------> " + JSON.stringify(this.state.patientDetails));
    return (
      <View style={styles.IDProofView}>
        <View style={{ backgroundColor: color.themeDark, borderRadius: 10, alignContent: 'center', alignSelf: 'center', justifyContent: 'center', padding: '1.2vw' }}>
          <IDProofImage name="jpgfile1" size={'2.5vw'} color={"white"} />
        </View>
        <Text style={styles.IDProoftype}>{proof.type}</Text>
        <Text style={styles.IDProoftype}>{proof.number}</Text>
        <Text style={styles.IDProofImg}>{proof.imageName}</Text>
      </View>
    );
  }

  getClinic = () => {
    var serviceUrl = Constants.HOME_GET_CLINICS;
    // console.log("this.state.patientDetails ---------> new")

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getClinicSuccess,
      this.getClinicFailure
    );
  };

  getClinicSuccess = success => {
    // console.log("this.state.patientDetails ---------> success"+JSON.stringify(success))
    if (success.status == "success") {
      // console.log("this.state.patientDetails ---------> success11"+JSON.stringify(success.data[0]))
      this.setState({
        clinicList: success.data,
        selectedClinic: success.data[0]
      })
    }
  }
  getClinicFailure = error => { }


  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  alphabetvalidation(num) {
    return /^[a-z A-Z]*$/.test(num);
  }
  avoidOnlySplCharValidation = (num) => {
    return !/[^A-Z a-z 0-9]+/.test(num)
  }

  alphabetvalidationWithMajorSplChar(num) {
    return /^[a-z A-Z.,_-]+$/.test(num);
  }



  // return /^[a-z0-9]+$/i.test(num)


  createPatientDetails(text, placeholder, type) {
    if (type == "MI") {
      if (placeholder == "Source Name") {
        var isNum = this.alphabetvalidation(text)
        if (isNum && text.length < 100) {
          let states = this.state.mandatoryInformation
          states["referralsource"] = text
        }
        this.setState({ states })
      } else if (placeholder == "Doctor Name") {
        var isNum = this.alphabetvalidation(text)
        if (isNum) {
          let states = this.state.mandatoryInformation
          states["refferedBy"] = text
        }
        this.setState({ states })
      } else if (placeholder == "First Name *") {
        var isNum = this.alphabetvalidation(text)
        if (isNum) {
          var states = this.state.mandatoryInformation
          states["firstName"] = text
        }
        this.setState({ states })
      } else if (placeholder == "Surname" || placeholder == "Surname *") {
        var isNum = this.alphabetvalidation(text)
        if (isNum) {
          let states = this.state.mandatoryInformation
          states["surName"] = text
        }
        this.setState({ states })
      } else if (placeholder == "Guardian" || placeholder == "Guardian *") {
        var isAlpha = this.alphabetvalidation(text)
        let states = this.state.mandatoryInformation
        if(isAlpha) {
          states["guardian"] = text
        }
        this.setState({ states })
      } else if (placeholder == "Occupation" || placeholder == "Occupation *") {
        let states = this.state.mandatoryInformation
        states["occupation"] = text
        this.setState({ states })
      } else if (placeholder == "Mobile No *") {

        var isNum = this.numberValidation(text)
        if (isNum || text === "") {
          if (text.length <= 10) {
            let states = this.state.mandatoryInformation
            states["mobileNo"] = text
            this.setState({ states })
            if (text.length == 10) {
              // console.log("searchNumber")
              this.searchPatient(text)
            }
          }
        }
      } else if (placeholder == "Mobile No2" || placeholder == "Mobile No2 *") {

        var isNum = this.numberValidation(text)
        if (isNum || text === "") {
          if (text.length <= 10) {
            let states = this.state.mandatoryInformation
            states["mobileNo2"] = text
            this.setState({ states })
          }
        }
      } else if (placeholder == "Father/Husband name" || placeholder == "Father/Husband name *") {
        let states = this.state.mandatoryInformation
        var isNum = this.alphabetvalidation(text)
        if (isNum) {
          states["fatherOrHusband"] = text
          this.setState({ states })
        }
      } else if (placeholder == "state *") {
        let states = this.state.mandatoryInformation
        states["state"] = text
        this.setState({ states })
      } else if (placeholder == "Age *") {
        var isNum = this.numberValidation(text)
        if (isNum || text === "") {
          let states = this.state.mandatoryInformation
          states["age"] = text;
          var approxDob = this.handleFindApproxDob(text);
          states["dob"] = "";
          states["approx_dob"] = approxDob;
          this.setState({ states })
        }


        // let states = this.state.mandatoryInformation
        // states["age"] = text;
        // var approxDob = this.handleFindApproxDob(text);
        // states["dob"] = "";
        // states["approx_dob"] = approxDob;
        // this.setState({ states })
      } else if (placeholder == "Approx DOB") {
        let states = this.state.mandatoryInformation
        states["approx_dob"] = text;
        this.setState({ states })
      }
    } else if (type == "OI") {
      if (placeholder == "Email Id" || placeholder == "Email Id *") {
        let states = this.state.otherInformation
        states["emailID"] = text
        this.setState({ states })
      } else if (placeholder == "Address 1" || placeholder == "Address 1 *") {
        let states = this.state.otherInformation
        states["address"] = text
        this.setState({ states })
      } else if (placeholder == "Address 2" || placeholder == "Address 2 *") {
        let states = this.state.otherInformation
        states["address2"] = text
        this.setState({ states })
      } else if (placeholder == "City *") {
        let states = this.state.otherInformation
        var isNum = this.alphabetvalidation(text)
        if (isNum) {
          states["city"] = text
          this.setState({ states })
        }
      } else if (placeholder == "Pincode" || placeholder == "Pincode *") {
        let states = this.state.otherInformation
        states["pincode"] = text
        this.setState({ states })
      }
      else if (placeholder == "Employee ID No" || placeholder == "Employee ID No *") {
        if (this.state.otherInformation.employer_id != "") { // only any company is selected 
          let states = this.state.otherInformation
          states["employee_ID_No"] = text
          this.setState({ states })
        }
      }
    } else if (type == "CTI") {
      if (placeholder == "Email Id") {
        let states = this.state.careTakerInformation
        states["emailID"] = text
        this.setState({ states })
      } else if (placeholder == "Address") {
        let states = this.state.careTakerInformation
        states["address"] = text
        this.setState({ states })
      } else if (placeholder == "City") {
        let states = this.state.careTakerInformation
        states["city"] = text
        this.setState({ states })
      } else if (placeholder == "Pincode") {
        let states = this.state.careTakerInformation
        states["pincode"] = text
        this.setState({ states })
      } else if (placeholder == "First Name" || placeholder == "First Name *") {
        let states = this.state.careTakerInformation
        states["firstName"] = text
        this.setState({ states })
      } else if (placeholder == "Mobile No" || placeholder == "Mobile No *") {
        var isNum = this.numberValidation(text)
        if (isNum || text === "") {
          if (text.length <= 10) {
            let states = this.state.careTakerInformation
            states["mobileNo"] = text
            this.setState({ states })
          }
        }
      } else if (placeholder == "Relationship" || placeholder == "Relationship *") {
        let states = this.state.careTakerInformation
        states["relationship"] = text
        this.setState({ states })
      }
    } else if (type == "IDP") {
      if (placeholder == "Aadhaar no") {

        var isNum = this.numberValidation(text)
        if (isNum || text === "") {
          if (text.length <= 12) {
            let states = this.state.idProof;
            states["aadharCard"] = text
            this.setState({ states })
          }
        }
      } else if (placeholder == "Driving Lic no") {
        if (text.length <= 16) {
          let states = this.state.idProof;
          states["drivingLicence"] = text
          this.setState({ states })
        }
      } else if (placeholder == "Voter ID no") {
        if (text.length <= 10) {
          let states = this.state.idProof;
          states["voterId"] = text
          this.setState({ states })
        }
      } else if (placeholder == "Passport no") {
        if (text.length <= 12) {
          let states = this.state.idProof;
          states["passport"] = text
          this.setState({ states })
        }
      } else if (placeholder == "VISA no") {
        if (text.length <= 12) {
          let states = this.state.idProof;
          states["visa"] = text
          this.setState({ states })
        }
      } else if (placeholder == "VISA Validity") {
        if (text.length <= 12) {
          let states = this.state.idProof;
          states["visaValidity"] = text
          this.setState({ states })
        }
      } else if (placeholder == "Ration Card no") {
        let states = this.state.idProof;
        states["rationCard"] = text
        this.setState({ states })
      } else if (placeholder == "Bank Id") {
        let states = this.state.idProof;
        states["bankId"] = text
        this.setState({ states })
      }
    }
  }

  searchPatient = (text) => {
    // console.log("searchNumber:",text)
    if (text !== "") {
      if (this.props.isIpDemographic) {
        var serviceUrl = Constants.IP_PATIENT_SEARCH + "?search_key=" + text;
      } else {
        var serviceUrl = Constants.HOME_SEARCH_PATIENT + "?search_key=" + text + "&clinic_id=" + this.state.selectedClinicOrDoctor.id;
      }
      Service.getInstance().appointmentComments(
        serviceUrl,
        this,
        this.searchPatientSuccess,
        this.searchPatientFailure
      );
    }
  }

  searchPatientSuccess = (response) => {
    // console.log("SearchResponse:",response)
    this.setState({
      searchPatientData: response.data.patient ? response.data.patient : response.data
    })
  }

  renderIDProofField() {
    var selected = "File Selected";
    var notSelected = "No Files Selected";
    return (
      <View style={{}}>
        {
          this.state.aadharCard ?
            <View style={styles.AadharcardTextBox}>
              {/* <View style={{width:"50%"}}>  */}
              {this.renderTextBox("Aadhaar no", 10, false, "IDP", this.state.idProof.aadharCard)}
              {/* </View> */}
              <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("Aadhar") }}>
                <Text style={styles.SelectFileText}>{"Select file"}</Text>
              </TouchableOpacity>
              {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.aadharCard ? selected : notSelected}</Text>
              </View> */}
              {
                this.state.idProofDocs.aadharCard ?
                  <Image source={{ uri: this.state.idProofDocs.aadharCard }} style={styles.CardImg} />
                  : null
              }

            </View>
            : null

        }

        {
          this.state.drivingLicence ?
            <View style={styles.AadharcardTextBox}>
              {this.renderTextBox("Driving Lic no", 10, false, "IDP", this.state.idProof.drivingLicence)}
              <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("Driving") }}>
                <Text style={styles.SelectFileText}>{"Select file"}</Text>
              </TouchableOpacity>
              {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.drivingLicence ? selected : notSelected}</Text>
              </View> */}
              {
                this.state.idProofDocs.drivingLicence ?
                  <Image source={{ uri: this.state.idProofDocs.drivingLicence }} style={styles.CardImg} />
                  : null
              }
            </View> : null
        }

        {
          this.state.voterId ?
            <View style={styles.AadharcardTextBox}>
              {this.renderTextBox("Voter ID no", 10, false, "IDP", this.state.idProof.voterId)}
              <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("VoterID") }}>
                <Text style={styles.SelectFileText}>{"Select file"}</Text>
              </TouchableOpacity>
              {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.voterId ? selected : notSelected}</Text>
              </View> */}
              {
                this.state.idProofDocs.voterId ?
                  <Image source={{ uri: this.state.idProofDocs.voterId }} style={styles.CardImg} />
                  : null
              }
            </View> : null
        }

        {
          this.state.passport ?
            <View>
              <View style={styles.AadharcardTextBox}>
                {this.renderTextBox("Passport no", 10, false, "IDP", this.state.idProof.passport)}
                <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("Passport") }}>
                  <Text style={styles.SelectFileText}>{"Select file"}</Text>
                </TouchableOpacity>
                {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.passport ? selected : notSelected}</Text>
              </View> */}
                {
                  this.state.idProofDocs.passport ?
                    <Image source={{ uri: this.state.idProofDocs.passport }} style={styles.CardImg} />
                    : null
                }
              </View>
              {this.state.mandatoryInformation.country != "India" ?
                <View style={styles.AadharcardTextBox}>
                  {this.renderTextBox("VISA no", 10, false, "IDP", this.state.idProof.visa)}
                  <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("VISA") }}>
                    <Text style={styles.SelectFileText}>{"Select file"}</Text>
                  </TouchableOpacity>

                  {
                    this.state.idProofDocs.visa ?
                      <Image source={{ uri: this.state.idProofDocs.visa }} style={styles.CardImg} />
                      : null
                  }
                </View>
                : null
              }
              {this.state.mandatoryInformation.country != "India" ?
                <View style={styles.AadharcardTextBox}>
                  {this.renderTextBox("VISA Validity", 10, false, "IDP", this.state.idProof.visaValidity)}
                </View>
                : null
              }

            </View>
            : null
        }

        {
          this.state.rationCard ?
            <View style={styles.AadharcardTextBox}>
              {this.renderTextBox("Ration Card no", 10, false, "IDP", this.state.idProof.rationCard)}
              <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("RationCard") }}>
                <Text style={styles.SelectFileText}>{"Select file"}</Text>
              </TouchableOpacity>
              {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.rationCard ? selected : notSelected}</Text>
              </View> */}
              {
                this.state.idProofDocs.rationCard ?
                  <Image source={{ uri: this.state.idProofDocs.rationCard }} style={styles.CardImg} />
                  : null
              }
            </View> : null
        }

        {
          this.state.bankId ?
            <View style={styles.AadharcardTextBox}>
              {this.renderTextBox("Bank Id", 10, false, "IDP", this.state.idProof.bankId)}
              <TouchableOpacity disabled={this.state.isIpPatientCheckouted ? true : false} style={[styles.SelectFileTouch, { backgroundColor: color.themeDark }]} onPress={() => { this.openImageFolder("BankID") }}>
                <Text style={styles.SelectFileText}>{"Select file"}</Text>
              </TouchableOpacity>
              {/* <View style={{ justifyContent: 'center' }}>
                <Text style={{ color: color.notesTableTitle, justifyContent: 'center', textAlign: 'center' }}>{this.state.idProofDocs.bankId ? selected : notSelected}</Text>
              </View> */}
              {
                this.state.idProofDocs.bankId ?
                  <Image source={{ uri: this.state.idProofDocs.bankId }} style={styles.CardImg} />
                  : null
              }
            </View> : null
        }

        {
          this.state.mandatoryInformation.facialPhotos ?
            <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: '1.5vw' }}>
              {this.state.mandatoryInformation.facialPhotos.map((item, index) => {
                return (
                  <View style={{ padding: '0.6vw' }} key={index} >
                    <Image source={{ uri: item }} style={styles.CardImg} />
                  </View>
                )
              })}
            </View> : null
        }

      </View>
    );
  }


  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });


  }


  createPatient = async () => {
    var datas = this.state.mandatoryInformation;
    var mandatoryInformationIsEmpty = false;
    var message = ""

    // if(datas.type== "" && !mandatoryInformationIsEmpty) {mandatoryInformationIsEmpty = true; message = "Title is mandatory field"}
    // if(datas.gender== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "Gender is mandatory field"} 
    // if(datas.firstName== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "First Name is mandatory field"} 
    // if(datas.surName== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "Sur Name is mandatory field"} 

    // if(datas.age == "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "age is mandatory field"} 
    // // if(datas.maritalStatus== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "Marital Status is mandatory field"} 
    // if(datas.mobileNo== "" && !mandatoryInformationIsEmpty){

    //   mandatoryInformationIsEmpty = true; message = "Mobile No is mandatory field"

    // }else if (datas.mobileNo.length !== 10){
    //   mandatoryInformationIsEmpty = true; message = "Enter The Valid Mobile No"
    // }


    // if(datas.country== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "country is mandatory field"} 
    // // if(datas.fatherOrHusband== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "Father / Husband is mandatory field"}
    // if(datas.state== "" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "state is mandatory field"} 
    // // if(datas.profilePhoto=="" && !mandatoryInformationIsEmpty){mandatoryInformationIsEmpty = true; message = "Profile Photo"}

    // if(datas.dob== "" && !mandatoryInformationIsEmpty && datas.approx_dob == ""){mandatoryInformationIsEmpty = true; message = "dob is mandatory field"}

    // if(this.state.aadharCard && this.state.idProof.aadharCard.length !== 12){{mandatoryInformationIsEmpty = true; message = "Enter The Vallid Aadhaar Card No"}}

    // console.log("show mandatory data -------------->1 " + JSON.stringify(this.state.mandatoryInformation))
    // console.log("show mandatory data -------------->2 " + JSON.stringify(this.state.otherInformation))
    // console.log("show mandatory data -------------->3 " + JSON.stringify(this.state.careTakerInformation))
    // console.log("show mandatory data -------------->4" + JSON.stringify(this.state.idProof))

    const getClinicID = await AsyncStorage.getItem("Selected_ClinicIDFor_Doctor");
    // DR_CREATE_CARETAKER
    // DR_CREATE_IDPROOF
    // DR_CREATE_PATIENT

    // occupation,email,address,pincode,  not mandatory

    // console.log("getClinicID ================> " + JSON.stringify("getClinicID"))

    // var service_url = Constants.DR_CREATE_PATIENT;

    const data = new FormData();

    var gender;
    if (this.state.mandatoryInformation.gender == "Male") {
      gender = "m"
    } else if (this.state.mandatoryInformation.gender == "Female") {
      gender = "f"
    } else if (this.state.mandatoryInformation.gender == "Trans") {
      gender = "t"
    }

    if (this.state.patientId) {
      data.append('patient_id', this.state.patientId);
    }

    if (this.props.selectedIpPatientDetails.admission_date == null && this.props.isCurrentPageIsIP) {
      data.append('ip', true);
      data.append('doctor_id', this.state.selectedDoctor.id ? this.state.selectedDoctor.id : "");
      data.append('speciality_id', this.state.selectedDoctor ? this.state.selectedDoctor.speciality_id : "")
    }
    data.append('reffered_by', this.state.mandatoryInformation.refferedBy);
    data.append('referral_source', this.state.mandatoryInformation.referralsource);
    data.append('title', this.state.mandatoryInformation.type);
    data.append('first_name', this.state.mandatoryInformation.firstName);
    data.append('last_name', this.state.mandatoryInformation.surName);
    data.append('gender', gender);
    if (this.state.userType === 'LAB') {

    } else {
      data.append('clinic_id', getClinicID);
    }

    data.append('approx_dob', this.state.mandatoryInformation.approx_dob ? moment(this.state.mandatoryInformation.approx_dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : "");
    data.append('dob', this.state.mandatoryInformation.dob ? this.state.mandatoryInformation.dob : moment(this.state.mandatoryInformation.approx_dob, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    data.append('age', this.state.mandatoryInformation.age);
    if (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) {
      data.append('photo', (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) ? this.dataURItoBlob(this.state.mandatoryInformation.profilePhoto) : "")
      data.append('face_data', (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto.length > 500) ? this.dataURItoBlob(this.state.mandatoryInformation.profilePhoto) : "")
    } else if (this.state.mandatoryInformation.profilePhoto && this.state.mandatoryInformation.profilePhoto != "") {
      data.append('photo_url', this.state.mandatoryInformation.profilePhoto)
    }
    data.append('marital_status', this.state.mandatoryInformation.maritalStatus);
    data.append('mobile_number', this.state.mandatoryInformation.mobileNo);
    data.append('occupation', this.state.mandatoryInformation.occupation);
    data.append('guardian', this.state.mandatoryInformation.guardian);
    data.append('receive_sms', this.state.mandatoryInformation.receiveSMSFlag);
    data.append('father_husband_name', this.state.mandatoryInformation.fatherOrHusband);
    data.append('country', this.state.mandatoryInformation.country);
    data.append('mobile_number_2', this.state.mandatoryInformation.mobileNo2);

    data.append('pincode', this.state.otherInformation.pincode);
    data.append('email', this.state.otherInformation.emailID);
    data.append('city_name', this.state.otherInformation.city);
    data.append('address_line_1', this.state.otherInformation.address);
    data.append('address_line_2', this.state.otherInformation.address2);
    data.append('employer_id', this.state.otherInformation.employer_id);
    data.append('id_no', this.state.otherInformation.employee_ID_No);

    // data.append('address_line_2', ""); // not showing
    data.append('state_name', this.state.mandatoryInformation.state);
    data.append('home_phone', "");
    data.append('area_name', "");



    //   for(var checkData of data.entries()) {
    //  }
    if (
      // this.state.mandatoryInformation.type && 
      this.state.mandatoryInformation.gender &&
      this.state.mandatoryInformation.firstName &&
      this.state.mandatoryInformation.age &&
      this.state.mandatoryInformation.mobileNo &&
      this.state.otherInformation.city &&
      this.state.mandatoryInformation.state &&
      this.state.mandatoryInformation.country &&
      !(this.state.otherInformation.employer_id != "" && this.state.otherInformation.employee_ID_No == "") &&
      ((this.state.MandatoryFields?.address_line_1 && this.state.otherInformation.address) || !(this.state.MandatoryFields?.address_line_1) ) &&
      ((this.state.MandatoryFields?.dob && this.state.mandatoryInformation.dob) || !(this.state.MandatoryFields?.dob) ) &&
      ((this.state.MandatoryFields?.mobile_number_2 && this.state.mandatoryInformation.mobileNo2) || !(this.state.MandatoryFields?.mobile_number_2) ) &&
      ((this.state.MandatoryFields?.title && this.state.mandatoryInformation.type) || !(this.state.MandatoryFields?.title) ) &&
      ((this.state.MandatoryFields?.last_name && this.state.mandatoryInformation.surName) || !(this.state.MandatoryFields?.last_name) ) &&
      ((this.state.MandatoryFields?.marital_status && this.state.mandatoryInformation.maritalStatus) || !(this.state.MandatoryFields?.marital_status) ) &&
      ((this.state.MandatoryFields?.address_line_2 && this.state.otherInformation.address2) || !(this.state.MandatoryFields?.address_line_2) ) &&
      ((this.state.MandatoryFields?.pincode && this.state.otherInformation.pincode) || !(this.state.MandatoryFields?.pincode) ) &&
      ((this.state.MandatoryFields?.employer_id && this.state.otherInformation.employer_id) || !(this.state.MandatoryFields?.employer_id) ) &&
      ((this.state.MandatoryFields?.father_husband_name && this.state.mandatoryInformation.fatherOrHusband) || !(this.state.MandatoryFields?.father_husband_name) ) &&
      ((this.state.MandatoryFields?.guardian && this.state.mandatoryInformation.guardian) || !(this.state.MandatoryFields?.guardian)) &&
      ((this.state.MandatoryFields?.occupation && this.state.mandatoryInformation.occupation) || !(this.state.MandatoryFields?.occupation) ) &&
      ((this.state.MandatoryFields?.email && this.state.otherInformation.emailID) || !(this.state.MandatoryFields?.email) ) &&
      ((this.state.MandatoryFields?.emergency_name && this.state.careTakerInformation.firstName) || !(this.state.MandatoryFields?.emergency_name) ) &&
      ((this.state.MandatoryFields?.emergency_number && this.state.careTakerInformation.mobileNo) || !(this.state.MandatoryFields?.emergency_number) ) &&
      ((this.state.MandatoryFields?.emergency_relationship && this.state.careTakerInformation.relationship) || !(this.state.MandatoryFields?.emergency_relationship) )

      
      ) {
      // var service_url = Constants.DR_CREATE_PATIENT+this.state.patientId+
      // ;
      if (this.state.patientId) {
        this.setState({
          isCreatePatientClicked: true
        })

        let { userType } = this.state;

        // var service_url = Constants.VITALS_DETAILS
        let service_url = '';
        // alert("userType"+userType)
        if (userType === Constants.ROLE_FO) {

          service_url = Constants.FO_CREATE_PATIENT + this.state.patientId + "/"
        } else if (this.state.userType === 'LAB') {
          if (userType === "LAB" && this.props.selectedLabPatientDetails.patient_id) {

            service_url = Constants.DR_CREATE_PATIENT + this.props.selectedLabPatientDetails.patient_id + "/";
          } else {

            service_url = Constants.CREATE_LAB_PATIENT + this.state.patientId + "/"
          }

        } else {
          service_url = Constants.DR_CREATE_PATIENT
        }
        if(this.props.selectedIpPatientDetails.admission_date == null && this.props.isCurrentPageIsIP && this.state.selectedDoctor === "") {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Please select the doctor", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Please select the doctor", successMessageFlag: false, errorMessageFlag: false, isCreatePatientClicked: false });
          }, 2000);
        } else { 
        OpthamologyService.getInstance().documentUploadPut(
          service_url,
          data,
          this,
          this.createPatientSuccess,
          this.createPatientFailure,
          'multipart/form-data'
        ); 
        }
      } else {
        let service = ""
        let { userType } = this.state;
        // alert("userType"+userType)

        if (userType === Constants.ROLE_FO) {

          service = Constants.FO_CREATE_PATIENT
        } else if (this.state.userType === 'LAB') {

          service = Constants.CREATE_LAB_PATIENT
        } else {

          service = Constants.DR_CREATE_PATIENT
        }

        if (this.state.mandatoryInformation.country === "India") {
          this.setState({
            isCreatePatientClicked: true
          })
          +-
          OpthamologyService.getInstance().documentUploadPost(
            service,
            data,
            this,
            this.createPatientSuccess,
            this.createPatientFailure,
            'multipart/form-data'
          );
        } else if (
          this.state.mandatoryInformation.country != "India" &&
          this.state.idProof.passport &&
          this.state.idProof.visa &&
          this.state.idProof.visaValidity &&
          this.state.idProofDocs.passport &&
          this.state.idProofDocs.visa
        ) {
          this.setState({
            isCreatePatientClicked: true
          })
          OpthamologyService.getInstance().documentUploadPost(
            service,
            data,
            this,
            this.createPatientSuccess,
            this.createPatientFailure,
            'multipart/form-data'
          );
        } else if (!this.state.idProof.passport) {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Passport Number", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Passport Number", successMessageFlag: false, errorMessageFlag: false });
          }, 2000);
        } else if (!this.state.idProofDocs.passport) {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Upload Passport File", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Upload Passport File", successMessageFlag: false, errorMessageFlag: false });
          }, 2000);
        } else if (!this.state.idProof.visa) {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Visa Number", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Visa Number", successMessageFlag: false, errorMessageFlag: false });
          }, 2000);
        } else if (!this.state.idProofDocs.visa) {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Upload Visa File", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Upload Visa File", successMessageFlag: false, errorMessageFlag: false });
          }, 2000);
        } else if (!this.state.idProof.visaValidity) {
          this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Visa Validity", successMessageFlag: false, errorMessageFlag: true });
          setTimeout(() => {
            this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter Visa Validity", successMessageFlag: false, errorMessageFlag: false });
          }, 2000);
        }
      }
    }
    else {
      this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter all required fields to proceed", successMessageFlag: false, errorMessageFlag: true });
      setTimeout(() => {
        this.setState({ isUpdateClick: false, errorColor: true, successOrErrorMessage: "Enter all required fields to proceed", successMessageFlag: false, errorMessageFlag: false });
      }, 2000);
      // alert(message+" is mandatory field") 
    }

  }

  createPatientSuccess = success => {

    var result = success.data;
    let { careTakerInformation, idProof, idProofDocs } = this.state;

    this.setState({
      isUpdateClick: false
    })

    if (success.data.message === "Patient Profile updated successfully") {
      if (idProof.aadharCard.length > 0 || idProof.passport.length > 0 || idProof.drivingLicence.length > 0 || idProof.voterId.length > 0 || idProof.rationCard.length > 0 || idProof.bankId.length > 0 || idProof.visa.length > 0 || idProof.visaValidity.length > 0) {
        this.proofUpload(this.state.patientId)
      }

      if (careTakerInformation.firstName || careTakerInformation.relationship || careTakerInformation.emailID ||
        careTakerInformation.mobileNo || careTakerInformation.address || careTakerInformation.pincode ||
        careTakerInformation.city
      ) {

        var service_url = Constants.DR_CREATE_CARETAKER;
        var data = {
          "patient_id": this.state.patientId,
          "first_name": careTakerInformation.firstName,
          "last_name": "",
          "relationship": careTakerInformation.relationship,
          "email": careTakerInformation.emailID,
          "mobile_number": careTakerInformation.mobileNo,
          "address_line_1": careTakerInformation.address,
          "address_line_2": "",
          "area_name": "",
          "pincode": careTakerInformation.pincode,
          "city_name": careTakerInformation.city,
          "state_name": "",
          "country": "",
          "reffered_by": ""
        }

        OpthamologyService.getInstance().documentUploadPost(
          service_url,
          data,
          this,
          this.caretakerSuccess,
          this.caretakerFailure,
        );
      } else {
        // call the Next Appointment

      }
    }

    if (result && result.status === "success") {

      this.props.clearPatientPhotos()

      this.setState({ isUpdateClick: false, successOrErrorMessage: result.message, successMessageFlag: true, errorMessageFlag: false });

      setTimeout(() => {
        this.setState({ isUpdateClick: false, successOrErrorMessage: "", successMessageFlag: false, errorMessageFlag: false });
      }, 2000);

      if (result.data && result.data.patient_id) {

        if (this.state.userType == "LAB") {
          this.props.newPatientDetailsCreatedForLab(result.data);
        }
        this.setState({
          patientId: result.data.patient_id
        })

        if (idProof.aadharCard.length > 0 || idProof.passport.length > 0 || idProof.drivingLicence.length > 0 || idProof.voterId.length > 0 || idProof.rationCard.length > 0 || idProof.bankId.length > 0 || idProof.visa.length > 0 || idProof.visaValidity.length > 0) {
          this.proofUpload(result.data.patient_id)
        }

        if (careTakerInformation.firstName || careTakerInformation.relationship || careTakerInformation.emailID ||
          careTakerInformation.mobileNo || careTakerInformation.address || careTakerInformation.pincode ||
          careTakerInformation.city
        ) {

          var service_url = Constants.DR_CREATE_CARETAKER;
          var data = {
            "patient_id": result.data.patient_id,
            "first_name": careTakerInformation.firstName,
            "last_name": "",
            "relationship": careTakerInformation.relationship,
            "email": careTakerInformation.emailID,
            "mobile_number": careTakerInformation.mobileNo,
            "address_line_1": careTakerInformation.address,
            "address_line_2": "",
            "area_name": "",
            "pincode": careTakerInformation.pincode,
            "city_name": careTakerInformation.city,
            "state_name": "",
            "country": "",
            "reffered_by": ""
          }

          OpthamologyService.getInstance().documentUploadPost(
            service_url,
            data,
            this,
            this.caretakerSuccess,
            this.caretakerFailure,
            // 'multipart/form-data'
          );
        } else {
          // call the Next Appointment
          // this.showNextAppoinmentView(result.data.patient_id);

          // this.setState({
          //   patient_id:result.data.patient_id,

          // },()=>{
          //   // show next Appointment view

          // })
        }

        var subDatas = {
          key: "admissionDetails",

          flag: true
        }
        var serviceUrl1 = "";
        serviceUrl1 = Constants.FO_PATIENT_INFO + result.data.patient_id + "/?appointment_id=" + result.data.appointment_id + "&doctor_id=" + this.state.selectedDoctor.id;

        OpthamologyService.getInstance().getComplaints(
          serviceUrl1,
          this,
          this.getComplaintsSuccess,
          this.getComplaintsFailure,
          subDatas
        );

        if ((this.props.selectedIpPatientDetails.id || this.props.isCurrentPageIsIP) && (this.state.patientId) && !this.props.isCurrentPageIsIPPatientAppointment) {
          var doctorPatientID = {
            patient_id: result.data.patient_id,
            appointment_id: result.data.appointment_id,
            doctor_id: this.state.selectedDoctor.id
          }

          this.props.changeScreen("admissionDetails", {}, "", {}, "", doctorPatientID)

          this.props.changeStateValues(response.data, "", "", "")
        }
        else {
          // this.showNextAppoinmentView(result.data.patient_id);

          // console.log(this.state.restrictCreateAppointmentModal)
          // console.log(isChrome)
          // console.log(this.state.alreadyCreatedPatient)
          // console.log(this.state.hasPermission)
          // console.log("IsIP:",this.props.isCurrentPageIsIPPatientAppointment)

          if (this.state.restrictCreateAppointmentModal && this.state.userType != "LAB" && !this.state.isEditAction) {
            if (isChrome && !this.state.alreadyCreatedPatient && this.state.hasPermission) {
              this.setModalVisible();
              //this.openCameraModal('face');
            } else {
              if (!this.props.isCurrentPageIsIPPatientAppointment)
                this.setModalVisible();
            }
          }
          else if (this.state.userType == "LAB") {
            var selectedLabPatientDetails = {
              patient_id: this.state.patientId
            }
            this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", selectedLabPatientDetails)
          }

        }

      }


      /*
      // call next appointment --------------> 
      if ((this.props.selectedIpPatientDetails.id || this.props.isCurrentPageIsIP) && this.state.patientId&& !this.props.isCurrentPageIsIPPatientAppointment) {
        var doctorPatientID = {
          patient_id: result.data.patient_id,
          appointment_id: result.data.appointment_id,
          doctor_id: this.state.selectedDoctor.id
        }

        this.props.changeScreen("admissionDetails", {}, "", {}, "", doctorPatientID)
      }
      else {
        if (this.state.restrictCreateAppointmentModal) {
          if(isChrome && !this.state.alreadyCreatedPatient && this.state.hasPermission) {

            this.openCameraModal('face');
          } else {
            if(!this.props.isCurrentPageIsIPPatientAppointment)
            this.setModalVisible();
          }
        }
      }
      */


    }
  }


  getComplaintsSuccess = (response, subDatas) => {
    console.log("demographics success 0");
    if (response.status === "success") {
      var field = this.state;
      field["patientInfo"] = response.data;
      console.log("demographics success 1");
      // this.props.changeScreen(subDatas.key, {}, "", {}, subDatas.subscreen, subDatas.item, "", subDatas.flag)

      this.props.changeStateValues(response.data, "", "", "")
      this.setState({ field });

    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log("demographics error", error);
  }

  caretakerSuccess = success => {
    if (success.data && success.data.status == "success") {

      let { idProofDocs } = this.state
      if (idProofDocs["aadharCard"])
        this.gettDocumentIdProofKey(idProofDocs["aadharCard"], "aadhar_card");

      if (idProofDocs["drivingLicence"])
        this.gettDocumentIdProofKey(idProofDocs["drivingLicence"], "dl");

      if (idProofDocs["voterId"])
        this.gettDocumentIdProofKey(idProofDocs["aadharCard"], "voter_id");

      if (idProofDocs["passport"])
        this.gettDocumentIdProofKey(idProofDocs["passport"], "passport");

      if (idProofDocs["rationCard"])
        this.gettDocumentIdProofKey(idProofDocs["rationCard"], "ration_card");

      if (idProofDocs["bankId"])
        this.gettDocumentIdProofKey(idProofDocs["bankId"], "bank_id");


    }

    // console.log("caretakerSuccess ------------------------> success" + JSON.stringify(success));
  }

  caretakerFailure = error => {
    this.setState({
      isUpdateClick: false
    })
    console.log("caretakerSuccess ------------------------> error " + JSON.stringify(error));

  }

  proofUpload = (patientId) => {
    // console.log("inside proof upload", patientId)

    var { idProof, idProofDocs } = this.state

    // console.log("inside proof upload 2", idProof.aadharCard)

    var selectedData = []
    if (idProof.aadharCard.length > 0) {
      selectedData.push(
        {
          doc_type: 1,
          doc_key: idProofDocs.aadharCard,
          number: idProof.aadharCard
        },
      )
    }
    if (idProof.passport.length > 0) {
      selectedData.push(
        {
          doc_type: 2,
          doc_key: idProofDocs.passport,
          number: idProof.passport
        },
      )
    }
    if (idProof.drivingLicence.length > 0) {
      selectedData.push(
        {
          doc_type: 3,
          doc_key: idProofDocs.drivingLicence,
          number: idProof.drivingLicence
        },
      )
    }
    if (idProof.voterId.length > 0) {
      selectedData.push(
        {
          doc_type: 4,
          doc_key: idProofDocs.voterId,
          number: idProof.voterId
        },
      )
    }
    if (idProof.rationCard.length > 0) {
      selectedData.push(
        {
          doc_type: 7,
          doc_key: idProofDocs.rationCard,
          number: idProof.rationCard
        },
      )
    }
    if (idProof.bankId.length > 0) {
      selectedData.push(
        {
          doc_type: 8,
          doc_key: idProofDocs.bankId,
          number: idProof.bankId
        },
      )
    }
    if (idProof.visa.length > 0) {
      selectedData.push(
        {
          doc_type: 10,
          doc_key: idProofDocs.visa,
          number: idProof.visa
        },
      )
    }
    if (idProof.visaValidity.length > 0) {
      selectedData.push(
        {
          doc_type: 11,
          doc_key: idProofDocs.visa,
          number: idProof.visaValidity
        },
      )
    }


    // console.log("selectedData", selectedData)
    var data = {
      "patient_id": patientId,
      "proofs": selectedData,
    }

    var service_url = Constants.DR_CREATE_IDPROOF;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.idProofsSaveSuccess,
      this.idProofsSaveFailure,
      // 'multipart/form-data'
    );

  }

  saveIDProofs() {
    // console.log("check the save id proof -------------------------------------->"+this.state.patientId)

    let { idProofDocs } = this.state;
    let { idProof } = this.state;

    // const data = new FormData();
    let data = [];
    if (this.state.aadharCard) {
      if (idProofDocs["aadharCard"] && idProof["aadharCard"]) {
        var prepareData = {
          "doc_type": 1,
          "doc_key": idProofDocs["aadharCard"],
          "number": idProof["aadharCard"]
        }
        data.push(prepareData);
        // data.append('proofs',prepareData);

        // data.append('doc_key',this.dataURItoBlob(idProofDocs["aadharCard"]))
        // data.append('number', idProof["aadharCard"])
        // data.append('doc_type', 1)



      } else {
        // this is both fields are mandatory
      }
    }


    if (this.state.drivingLicence) {
      if (idProofDocs["drivingLicence"] && idProof["drivingLicence"]) {
        var prepareData = {
          "doc_type": 3,
          "doc_key": idProofDocs["drivingLicence"],
          "number": idProof["drivingLicence"]
        }
        data.push(prepareData);

        // data.append('doc_key',this.dataURItoBlob(idProofDocs["drivingLicence"]))
        // data.append('number', idProof["drivingLicence"])
        // data.append('doc_type', 2)
      } else {
        // this is both fields are mandatory
      }
    }

    if (this.state.voterId) {
      if (idProofDocs["voterId"] && idProof["voterId"]) {
        var prepareData = {
          "doc_type": 4,
          "doc_key": idProofDocs["voterId"],
          "number": idProof["voterId"]
        }
        data.push(prepareData);

        // data.append('doc_key',this.dataURItoBlob(idProofDocs["voterId"]))
        // data.append('number', idProof["voterId"])
        // data.append('doc_type', 3)
      } else {
        // this is both fields are mandatory
      }
    }


    if (this.state.passport) {
      if (idProofDocs["passport"] && idProof["passport"]) {
        var prepareData = {
          "doc_type": 2,
          "doc_key": idProofDocs["passport"],
          "number": idProof["passport"]
        }
        data.push(prepareData);
        // data.append('doc_key',this.dataURItoBlob(idProofDocs["passport"]))
        // data.append('number', idProof["passport"])
        // data.append('doc_type', 4)
      } else {
        // this is both fields are mandatory
      }
    }

    if (this.state.rationCard) {
      if (idProofDocs["rationCard"] && idProof["rationCard"]) {
        var prepareData = {
          "doc_type": 7,
          "doc_key": idProofDocs["rationCard"],
          "number": idProof["rationCard"]
        }
        data.push(prepareData);
        // data.append('doc_key',this.dataURItoBlob(idProofDocs["rationCard"]))
        // data.append('number', idProof["rationCard"])
        // data.append('doc_type', 5)
      } else {
        // this is both fields are mandatory
      }
    }


    if (this.state.bankId) {
      if (idProofDocs["bankId"] && idProof["bankId"]) {
        var prepareData = {
          "doc_type": 8,
          "doc_key": idProofDocs["bankId"],
          "number": idProof["bankId"]
        }
        data.push(prepareData);
        // data.append('doc_key',this.dataURItoBlob(idProofDocs["bankId"]))
        // data.append('number', idProof["rationCard"])
        // data.append('doc_type', 6)
      } else {
        // this is both fields are mandatory
      }
    }


    // data.append('patient_id', this.state.patientId);

    var prepareData = {
      "patient_id": this.state.patientId,
      "proofs": data
    }

    var service_url = Constants.DR_CREATE_IDPROOF;
    // console.log("document upload success -----------> "+service_url);
    OpthamologyService.getInstance().documentUploadPost(
      service_url,
      prepareData,
      this,
      this.idProofsSaveSuccess,
      this.idProofsSaveFailure,
      // 'multipart/form-data'
    );
  }

  idProofsSaveSuccess = success => {
    // alert("success --------------------------->>> " + JSON.stringify(success));
  }
  idProofsSaveFailure = error => {
    // alert("error --------------------------->>> " + JSON.stringify(error));
  }

  createPatientFailure = error => {
    this.setState({
      isCreatePatientClicked: false,
      isUpdateClick: false
    })

    let response = JSON.parse(JSON.stringify(error));
    if (response) {

      this.setState({ successOrErrorMessage: response.data.message, successMessageFlag: false, errorMessageFlag: true });
      setTimeout(() => {
        this.setState({ successOrErrorMessage: response.data.message, successMessageFlag: false, errorMessageFlag: false });
      }, 2000);
    }
  }


  showNextAppoinmentView(patientId) {

    // console.log("patientId -- show details--------> " + patientId);
    // this.setState({ patientId: patientId, })

    let { userType } = this.state;

    // var service_url = Constants.VITALS_DETAILS
    let service_url = '';

    if (userType === Constants.ROLE_FO) {

      service_url = Constants.FO_DOCTOR_PATIENT_INFO + patientId ;
    } else if (userType === "LAB" && this.props.selectedLabPatientDetails.patient_id) {

      service_url = Constants.DR_CREATE_PATIENT + patientId + "/";
    } else {

      service_url = Constants.DOCTOR_PATIENT_INFO + patientId + "/";
    }


    //var service_url = Constants.DOCTOR_PATIENT_INFO + patientId + "/";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientDetailsSuccess,
      this.getPatientDetailsFailure
    );
  }

  getPatientDetailsSuccess = success => {
    var states = this.state.mandatoryInformation
    states["refferedBy"] = success.data.reffered_by != "" && success.data.reffered_by != "undefined" ? success.data.reffered_by : ""
    states["referralsource"] = success.data.referral_source != "" && success.data.referral_source != "undefined" ? success.data.referral_source : ""
    // console.log("patientId ----------> success" + success.data);
    // alert(JSON.stringify(success.data))
    this.setState({
      states,
      patientDetails: success.data,
      showNextAppoinmentView: true
    })
  }

  getPatientDetailsFailure = error => {
    // console.log("patientId ----------> error" + JSON.stringify(error));
    this.setState({
      patientDetails: error.data,
      showNextAppoinmentView: true
    })
  }


  createNextAppoinment() {
    // 2019-11-16
    var appointmentTime = this.state.hour12Formet + ":" + this.state.minutes + ":00";
    var appointmentDate = this.state.getYear.toString() + "-" + this.state.chengedMonthInNumber.toString() + "-" + this.state.changedDay.toString();
    var data = {
      "patient_id": parseInt(this.state.patientId),
      "clinic_id": parseInt(this.state.selectedClinic.clinic_id),
      "purpose_of_visit": this.state.purposeOfVisitsInNextAppoinment,
      "appointment_date": appointmentDate.toString(),
      "appointment_start_time": appointmentTime.toString()
    };

    // console.log("data --------------> "+JSON.stringify(data))
    var service_url = Constants.CREATE_APPOINTMENT;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getNextAppointmentSuccess,
      this.getNextAppointmentFailure
    );
  }
  getNextAppointmentSuccess = response => {
    if (response.status === "success") {
      this.setState({
        purposeOfVisitsInNextAppoinment: "",
        // modalVisible:false
      }, () => {
        this.props.navigation.navigate("home", { "callNextAppointment": true });
      })
    }

  }
  getNextAppointmentFailure = error => {
    console.log("response -----------------> error   " + JSON.stringify(error));
  }

  // -------------------------------------------------------------


  // checkValidateTheDays() {
  //   if (this.state.day5 === this.state.changedMonthDays) {
  //   } else {
  //     if (this.state.day5 > this.state.changedMonthDays) {
  //       this.setState({
  //         day1: this.state.day1 - 1,
  //         day2: this.state.day2 - 1,
  //         day3: this.state.day3 - 1,
  //         day4: this.state.day4 - 1,
  //         day5: this.state.day5 - 1,
  //       });
  //     }
  //   }

  //   if (this.state.day3 === 1) {
  //     this.setState({
  //       day1: this.state.day1 + 2,
  //       day2: this.state.day2 + 2,
  //       day3: this.state.day3 + 2,
  //       day4: this.state.day4 + 3,
  //       day5: this.state.day5 + 4,
  //     });
  //   }
  //   if (this.state.day3 === 2) {
  //     this.setState({
  //       day1: this.state.day1 + 1,
  //       day2: this.state.day2 + 1,
  //       day3: this.state.day3 + 1,
  //       day4: this.state.day4 + 1,
  //       day5: this.state.day5 + 1,
  //     });
  //   }

  // }


  // showDateDropDownView = () => {
  //   var getdate = [];
  //   if (this.state.dropDownView === "month") {
  //     getdate = this.state.totalMonths;
  //   } else {
  //     var today = new Date();
  //     var currentYear = parseInt(today.getFullYear());
  //     for (var i = currentYear; i > 2000; i--) {
  //       getdate.push(i);
  //     };
  //   }
  //   return (
  //     <View style={{ alignSelf: 'center', alignItems: 'center', width: '25%', backgroundColor: 'black', zIndex: 1, position: 'absolute', }}>
  //       {
  //         this.state.dropDownViewFlag ?
  //           <ScrollView style={styles.timeAndDateScroll} showsVerticalScrollIndicator={false}>
  //             <View style={{ backgroundColor: 'black' }}>
  //               {
  //                 getdate.map((value, index) => {
  //                   return (
  //                     <TouchableOpacity
  //                       key={index}
  //                       style={{ padding: 5 }}
  //                       onPress={() => {
  //                         this.state.dropDownView === "month" ?
  //                           this.setState({ chengedMonthInNumber: index + 1, getMonth: value.month, dropDownViewFlag: !this.state.dropDownViewFlag, changedMonthDays: value.totalDays })
  //                           :
  //                           this.setState({ getYear: value, dropDownViewFlag: !this.state.dropDownViewFlag });
  //                       }}>
  //                       <Text style={{ color: "white" }}>{this.state.dropDownView === "month" ? value.month : value}</Text>
  //                     </TouchableOpacity>
  //                   )
  //                 })
  //               }
  //             </View>
  //           </ScrollView>
  //           :
  //           null
  //       }
  //     </View>
  //   )
  // }
  // currentDate() {
  //   var today = new Date();
  //   var date = today.getDate();
  //   var month = today.getMonth();
  //   var year = today.getFullYear();
  //   for (let i = 0; i < this.state.totalMonths.length; i++) {
  //     if (month === i) {
  //       this.setState({
  //         changedMonthDays: this.state.totalMonths[i].totalDays,
  //         getMonth: this.state.totalMonths[i].month,
  //         getYear: year,
  //         day1: date - 2,
  //         day2: date - 1,
  //         day3: date,
  //         day4: date + 1,
  //         day5: date + 2,
  //       })
  //     }
  //   }
  // }
  // showDropdown(data) {
  //   this.setState({
  //     dropDownViewFlag: !this.state.dropDownViewFlag,
  //     dropDownView: data
  //   })
  // }
  // showCalendar(calendarType) {
  //   return (
  //     <View style={{ backgroundColor: "transparent", flexDirection: 'row', justifyContent: 'center', flex: 1 }}>
  //       <View>
  //         <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
  //           <TouchableOpacity
  //             style={{ marginRight: 5 }}
  //             onPress={() => {
  //               this.showDropdown("month")
  //             }}>
  //             <Text style={{ color: 'black' }}>{this.state.getMonth}</Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity
  //             style={{ marginRight: 5 }}
  //             onPress={() => {
  //               this.showDropdown("year");
  //             }}>
  //             <Text style={{ color: 'black' }}>{this.state.getYear}</Text>
  //           </TouchableOpacity>
  //           <View style={{ height: 15, width: 15 }}>
  //             {/* <Image
  //             // require("../../assets/images/Reports/Group 1558.svg")calendar_icon.png
  //             source={require("../../../../assets/images/calendar (2).svg")}
  //             style={{resizeMode:'center',height:15,width:15}}
  //             resizeMode="cover"
  //           /> */}
  //           </View>
  //         </View>
  //         <View>
  //           {this.showDateDropDownView()}
  //         </View>
  //         <View style={{ zIndex: -1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
  //           <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.changedDay - 1); }} style={{ padding: 5, justifyContent: 'center' }}>
  //             <Icon
  //               size={platform === "web" ? 25 : 30}
  //               color={"black"}
  //               name={"angle-left"}
  //             />
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.changeDay("sub", 2, this.state.day1); }}
  //             style={[this.state.changedDay === this.state.day1 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
  //           >
  //             <Text style={[this.state.changedDay === this.state.day1 ? { color: "black" } : { color: "black" }]}>{this.state.day1}<Text style={{ fontSize: 7, }}>th</Text></Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.day2); }}
  //             style={[this.state.changedDay === this.state.day2 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
  //           >
  //             <Text style={[this.state.changedDay === this.state.day2 ? { color: "black" } : { color: "black" }]}>{this.state.day2}<Text style={{ fontSize: 7, }}>th</Text></Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.changeDay("add", 0, this.state.day3) }}
  //             style={[this.state.changedDay === this.state.day3 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
  //           >
  //             <Text style={[this.state.changedDay === this.state.day3 ? { color: "black" } : { color: "black" }]}>{this.state.day3}<Text style={{ fontSize: 7, }}>th</Text></Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.day4); }}
  //             style={[this.state.changedDay === this.state.day4 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
  //           >
  //             <Text style={[this.state.changedDay === this.state.day4 ? { color: "black" } : { color: "black" }]}>{this.state.day4}<Text style={{ fontSize: 7, }}>th</Text></Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.changeDay("add", 2, this.state.day5); }}
  //             style={[this.state.changedDay === this.state.day5 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
  //           >
  //             <Text style={[this.state.changedDay === this.state.day5 ? { color: "black" } : { color: "black" }]}>{this.state.day5}<Text style={{ fontSize: 7 }}>th</Text></Text>
  //           </TouchableOpacity>

  //           <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.changedDay + 1); }} style={{ padding: 5, justifyContent: 'center' }}>
  //             <Icon
  //               size={platform === "web" ? 25 : 30}
  //               name={"angle-right"}
  //               color={"black"}
  //             />
  //           </TouchableOpacity>
  //         </View>
  //       </View>
  //       {/* <View style={{justifyContent:'flex-end',marginBottom:10,marginLeft:5}}>
  //         <Image
  //             source={require("../../../../assets/images/clock_icon.png")}
  //             style={{resizeMode:'center',height:30,width:30}}
  //             resizeMode="cover"
  //           />
  //           <Text style={{fontSize:8,marginTop:6}}>{"Today"}</Text>
  //         </View> */}
  //     </View>
  //   )
  // }



  // changeDay(method, value, selectedDay) {
  //   this.state.getDate = selectedDay; // get selected day

  //   if (this.state.changedMonthDays < selectedDay || selectedDay < 1) {
  //     // do nothing
  //   } else {
  //     this.setState({ changedDay: selectedDay })
  //   }


  //   if (method === "" && value === 0) {
  //     // do nothing
  //   } else {
  //     if (method === "sub") {
  //       if (this.state.day3 === 3) {
  //         // do nothing
  //       } else if (this.state.day1 === 2) {
  //         this.setState({
  //           day1: this.state.day1 - 1,
  //           day2: this.state.day2 - 1,
  //           day3: this.state.day3 - 1,
  //           day4: this.state.day4 - 1,
  //           day5: this.state.day5 - 1,
  //         });
  //       } else {
  //         this.setState({
  //           day1: this.state.day1 - value,
  //           day2: this.state.day2 - value,
  //           day3: this.state.day3 - value,
  //           day4: this.state.day4 - value,
  //           day5: this.state.day5 - value,
  //         });
  //       }
  //     } else {
  //       if (this.state.changedMonthDays === this.state.day5) {
  //         // do nothing
  //       } else if (this.state.changedMonthDays - 2 === this.state.day4) {
  //         this.setState({
  //           day1: this.state.day1 + 1,
  //           day2: this.state.day2 + 1,
  //           day3: this.state.day3 + 1,
  //           day4: this.state.day4 + 1,
  //           day5: this.state.day5 + 1,
  //         });
  //       } else {
  //         this.setState({
  //           day1: this.state.day1 + value,
  //           day2: this.state.day2 + value,
  //           day3: this.state.day3 + value,
  //           day4: this.state.day4 + value,
  //           day5: this.state.day5 + value,
  //         });
  //       }
  //     }
  //   }

  //   if ((this.state.changedMonthDays >= this.state.getDate && method === "add") || (this.state.getDate >= 1 && method === "sub")) {
  //     var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; // selected date month and year (need to pass getAppointments function)
  //     // this.getAppointments(selectedDateAndTime); // send selected date to Appointments
  //   } else {
  //     // do nothing
  //   }
  // }



  chegeAppoinmentModalTime() {
    if (!this.state.showNextAppoinmentTimer) {
      var today = new Date();
      var hours = parseInt(today.getHours());
      var minutes = today.getMinutes()
      if (hours > 12) {
        this.state.hour12Formet = hours - 12;
        this.state.hour12Formet = this.state.hour12Formet < 10 ? '0' + this.state.hour12Formet : this.state.hour12Formet;
      } else {
        this.state.hour12Formet = hours;
      }

      // var amPm= hours >= 12 ? 'pm':'am';
      minutes = minutes < 10 ? '0' + minutes : minutes;
      this.state.minutes = minutes;

      this.state.findAmPm = this.state.hours >= 12 ? 'pm' : 'am';
    }
  }

  // timePicker() {
  //   var getHours = []
  //   var getMinutes = [];
  //   for (var i = 1; i <= 12; i++) {
  //     var hour = i < 10 ? '0' + i : i;
  //     getHours.push(hour)
  //   }
  //   for (var j = 0; j <= 59; j++) {
  //     var minutes = j < 10 ? '0' + j : j;
  //     getMinutes.push(minutes)
  //   }

  //   // if (this.state.modalVisible) {
  //   return (
  //     <View style={{ flexDirection: 'row', justifyContent: 'flex-end', flex: 0.3, alignSelf: 'center' }}>
  //       <View style={{ flexDirection: 'column' }}>
  //         <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
  //           <TouchableOpacity onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer }) }} style={{ justifyContent: 'center' }}>
  //             <Text style={{ color: "black" }}>{this.state.hour12Formet}{"  :  "}{this.state.minutes}</Text>
  //           </TouchableOpacity>
  //           <TouchableOpacity onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer }) }} style={{ justifyContent: 'center', marginRight: 5 }}>
  //             <Icon
  //               size={20}
  //               name={"angle-down"}
  //               color={"black"}
  //             />
  //           </TouchableOpacity>
  //         </View>
  //         {
  //           this.state.showNextAppoinmentTimer ?
  //             <TouchableWithoutFeedback onPress={() => { this.setState({ showNextAppoinmentTimer: true }) }}>
  //               <View style={{ marginTop: 30, flexDirection: 'row', backgroundColor: '#F2F2F2', position: 'absolute', zIndex: 1 }}>
  //                 <ScrollView style={{ height: 100 }} showsVerticalScrollIndicator={false}>
  //                   <View style={{ flexDirection: 'column', width: 30, }}>
  //                     {
  //                       getHours.map((hour) => {
  //                         return <TouchableOpacity onPress={() => { this.changeTime(hour, 'hr') }} style={{ padding: 3 }}><Text style={{ color: hour === this.state.hour12Formet ? null : '#d0cece' }}>{hour}</Text></TouchableOpacity>
  //                       })
  //                     }
  //                   </View>
  //                 </ScrollView>
  //                 <ScrollView style={{ height: 100 }} showsVerticalScrollIndicator={false}>
  //                   <View style={{ flexDirection: 'column' }}>
  //                     {
  //                       getMinutes.map((min) => {
  //                         return <TouchableOpacity onPress={() => { this.changeTime(min, 'mm') }} style={{ padding: 3 }}><Text style={{ color: min === this.state.minutes ? null : '#d0cece' }}>{min}</Text></TouchableOpacity>
  //                       })
  //                     }
  //                   </View>
  //                 </ScrollView>
  //               </View>
  //             </TouchableWithoutFeedback>
  //             :
  //             null
  //         }

  //       </View>

  //       {/*   */}
  //       <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
  //         <TouchableOpacity onPress={() => { this.changeTime('am', 'ampm') }}>
  //           <Text style={{ color: this.state.findAmPm === "am" ? color.themeDark : "black", marginRight: 15, marginLeft: 20 }}>{"am"}</Text>
  //         </TouchableOpacity>

  //         <TouchableOpacity onPress={() => { this.changeTime('pm', 'ampm') }}>
  //           <Text style={{ color: this.state.findAmPm === "pm" ? color.themeDark : "black" }}>{"pm"}</Text>
  //         </TouchableOpacity>
  //       </View>
  //     </View>
  //   )

  //   // }
  // }

  // changeTime(time, type) {
  //   // console.log("changeTime ------------->" + time)
  //   // console.log("changeTime ------------->" + type)
  //   if (type === 'hr') {
  //     // this.setState({
  //     //   hour12Formet : time
  //     // })
  //     this.state.hour12Formet = time;
  //   } else if (type === 'mm') {
  //     // this.setState({
  //     //   minutes : time
  //     // })
  //     this.state.minutes = time;
  //   } else if (type === 'ampm') {
  //     // this.setState({
  //     //   findAmPm : time
  //     // })
  //     this.state.findAmPm = time;
  //   }
  // }




  openImageFolder = async (data) => {
    // this.setState({
    //   documentUploadSelectedButton:data
    // })
    try {
      let getImage;
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {

        getImage = result.uri
        // this.setState({ image: result.uri });
        // console.log('photo uri', getImage);

        let idType = ""
        let { idProofDocs } = this.state;
        let { mandatoryInformation } = this.state;
        if (data === "Aadhar")
          idProofDocs["aadharCard"] = getImage;
        else if (data === "Driving")
          idProofDocs["drivingLicence"] = getImage;
        else if (data === "VoterID")
          idProofDocs["voterId"] = getImage;
        else if (data === "Passport")
          idProofDocs["passport"] = getImage;
        else if (data === "RationCard")
          idProofDocs["rationCard"] = getImage;
        else if (data === "BankID")
          idProofDocs["bankId"] = getImage;
        else if (data === "VISA")
          idProofDocs["visa"] = getImage;
        else if (data === "profilePhoto")
          mandatoryInformation["profilePhoto"] = getImage
      }



    } catch (E) {
      console.log(E);
    }
  }

  gettDocumentIdProofKey(image, idType) {
    // console.log("document upload success -----------> ");


    const data = new FormData();
    // file
    // data.append('file',image)
    data.append('file', this.dataURItoBlob(image))
    data.append('user_type', "patient")
    data.append('document_category', idType)
    data.append('patient_id', this.state.patientId);
    // data.append('patient_id',20683);

    let service_url = Constants.DR_CREATE_IDPROOF_KEY;
    OpthamologyService.getInstance().documentUploadPost(
      service_url,
      data,
      this,
      this.documentUploadSuccess,
      this.documentUploadFailure,
      'multipart/form-data',
      idType
    );


  }

  documentUploadSuccess = (success, type) => {
    // console.log("document upload success -----------> "+JSON.stringify(success));
    // console.log("document upload success ----------->type "+JSON.stringify(type));
    if (success.data.status && success.data.status == "success") {
      let file_key = success.data.data.file_key
      // let idType =""
      let { idProofDocs } = this.state
      if (type === "aadhar_card")
        idProofDocs["aadharCard"] = file_key;
      else if (type === "dl")
        idProofDocs["drivingLicence"] = file_key;
      else if (type === "voter_id")
        idProofDocs["voterId"] = file_key;
      else if (type === "passport")
        idProofDocs["passport"] = file_key;
      else if (type === "ration_card")
        idProofDocs["rationCard"] = file_key;
      else if (type === "bank_id")
        idProofDocs["bankId"] = file_key;

      this.setState({ idProofDocs }, () => {
        this.saveIDProofs()
      })
    }



  }
  documentUploadFailure = error => {
    console.log("document upload error -----------> " + JSON.stringify(error));
  }

  renderErrorMessageView(key) {
    // var { personalDetails } = this.state;
    // var data = personalDetails[key];

    return (
      <View>
        {
          this.state.showErrorMessage && data == "" ?
            <View style={Style.errorMessageView}>
              {/* <Exclamation name={"exclamationcircleo"} size={16} style={Style.exclamationIcon} /> */}
              <Text style={[Style.errorMessageText, { marginLeft: 10 }]}>{"This field is Mandatory"}</Text>
            </View>
            : null
        }
      </View>

    );
  }

  openCameraModal(route) {

    this.props.openCameraModal(route);
  }
  PrintBarcode() {
    var doctor_id = this.state.patientAppointment.doctor_id ? this.state.patientAppointment.doctor_id : this.state.selectedClinicOrDoctor.id;
    var serviceUrl = Constants.BARCODE_PRINT_FO + "?patient_id=" + this.state.patientId + "&doctor_id=" + doctor_id + "&export_type=pdf";
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.BarcodePrintSuccess,
      this.BarcodePrintFailure,
      "pdf"
    );
  }
  BarcodePrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }
  PrintPatientDetails() {
    let { userType } = this.state;
    var serviceUrl = userType == "FO" || userType == "LAB" ? Constants.PATIENT_DEMOGRAPHICS_PRINT + "?patient_id=" + this.state.patientId + "&export=pdf" :
    Constants.PATIENT_DEMOGRAPHICS_PRINT +"?patient_id=" +  this.state.patientId + "&clinic_id=" + this.state.selectedClinicOrDoctor.id + "&export=pdf";
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.PatientDetailsPrintSuccess,
      this.PatientDetailsPrintFailure,
      "pdf"
    );
  }
  PatientDetailsPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }

  PrintOPCard() {
    var doctor_id = this.state.patientAppointment.doctor_id ? this.state.patientAppointment.doctor_id : this.state.selectedClinicOrDoctor.id;
    var serviceUrl = Constants.OPCARD_PRINT_FO + "?patient_id=" + this.state.patientId + "&doctor_id=" + doctor_id + "&export_type=pdf";
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.OPCardPrintSuccess,
      this.BarcodePrintFailure,
      "pdf"
    );
  }
  OPCardPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }

  saveUserImages() {

    let { mandatoryInformation } = this.state;
    let url = Constants.FR_SAVE_PHOTO;

    const data = new FormData();

    if (this.state.patientId && (mandatoryInformation.facialPhotos && mandatoryInformation.facialPhotos.length > 0)) {

      data.append('patient_id', this.state.patientId);
      data.append('patient_photo', this.dataURItoBlob(mandatoryInformation.facialPhotos[0]))
      data.append('patient_photo1', this.dataURItoBlob(mandatoryInformation.facialPhotos[1]))
      data.append('patient_photo2', this.dataURItoBlob(mandatoryInformation.facialPhotos[2]))
      data.append('patient_photo3', this.dataURItoBlob(mandatoryInformation.facialPhotos[3]))
      data.append('patient_photo4', this.dataURItoBlob(mandatoryInformation.facialPhotos[4]))

      // for(var i=0;i<mandatoryInformation.facialPhotos.length;i++){

      //   console.log(" mandatoryInformation.facialPhotos[i] --- " + JSON.stringify(mandatoryInformation.facialPhotos[i]))

      //   data.append('patient_photo'+(i===0)?"":(i+1),this.dataURItoBlob(mandatoryInformation.facialPhotos[i]))
      // }


      // on success, trigger next appointment
      axios.post(
        url,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // "Content-Type": contentType,
            Authorization:
              "Token d59ec004ad05db76748d7a16679cf0e9c616b117"
            // + (await AsyncStorage.getItem("token")).toString(),
          }
        }
      )
        .then((response) => {
          // successCallback(response, docType);
          // return response;
          //    console.log(" face photos saved")
        })
        .catch((error) => {
          //  if(error.response){
          //   errorCallback(error.response);
          // }
          // return error;
          console.log(" face photos error" + JSON.stringify(error))
        });

    }
    // call nxt appointment
    // this.showNextAppoinmentView(this.state.patientId);
    this.setModalVisible();
  }


  // setModalVisible = status => {

  //   var service_url = ""
  //   if(this.state.userType == Constants.ROLE_FO){
  //     service_url = Constants.FO_PATIENT_INFO + this.state.patientId;
  //   }else{
  //     service_url = Constants.DOCTOR_PATIENT_INFO + this.state.patientId;
  //   }

  //   OpthamologyService.getInstance().getComplaints(
  //     service_url,
  //     this,
  //     this.getPatientInfoSuccess,
  //     this.getPatientInfoFailure
  //   );
  // }

  // getPatientInfoSuccess = response => {
  //   if (response.status === "success") {

  //     let states = this.state;
  //     states["modalVisible"] = true;

  //     this.props.modelClose(true,"",this.state.patientId,response)
  //   }
  // };

  // getPatientInfoFailure = error => {
  //   console.log("opthamology complaints GET error response");
  //   console.log(error);
  // }
  // 

}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  timeAndDateScroll: {
    height: '14.3vw', width: '7vw'
  },

  rightView: {
    padding: '0.6vw',
    width: "31.5%",
    height: screenHeight,
    // backgroundColor: color.themeShade,
    backgroundColor: color.themeShade,
    // alignItems: "center"
  },

  referredDocAddInputs: {
    borderColor: "#CDD1D5",
    borderRadius: 4,
    borderWidth: 1,
    width: "90%",
    height: '2.3vw',
    padding: '0.6vw',
    color: color.black,
    marginTop: '0.6vw',
    backgroundColor: color.white,
    fontSize: '1vw'
  },

  referredDocAddBtn: {
    padding: '0.3vw',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.6vw',
    marginTop: '1.2vw',
    width: "40%",
  },
  pickerView: {
    backgroundColor: color.white,
    borderRadius: "4px",
    height: "2.2vw",
    fontSize: ".85vw",
    paddingLeft: ".3vw",
    marginRight: "1vw"
  },
  HeadertitleText: {
    fontSize: '1.2vw',
    color: "#000000",
    fontWeight: "bold"
  },
  ReferredByView: {
    width: '18vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
  },
  ReferredSource: {
    width: "22%",
    marginBottom: '1.1vw',
    justifyContent: 'center'
  },
  DoctorNameView: {
    marginLeft: '2.5vw',
    marginTop: '0.6vw'
  },
  TextBoxPlaceholder: {
    zIndex: 2,
    fontSize: '0.8vw',
    position: "absolute",
    marginLeft: '0.6vw',
    paddingLeft: '0.3vw',
    paddingRight: '0.3vw',
    color: "#888888"
  },
  DoctorsNameList: {
    position: "absolute",
    width: '14.4vw',
    marginTop: '2.5vw',
    maxHeight: '14.4vw',
    zIndex: 1
  },
  MobileTxtBoxView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // paddingLeft: '2.5vw',
    // marginTop: '1.2vw',
    zIndex: -1
  },
  MobileTxtBoxContainer: {
    width: '13vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
    marginRight: '1vw'
  },
  TitleTextBoxView: {
    width: '5.5vw',
    marginBottom: '1.1vw',
    marginRight: '0.3vw',
    marginTop: '0.3vw'
  },
  TitlePlaceholder: {
    position: 'absolute',
    marginLeft: '0.6vw',
    backgroundColor: "white",
    fontSize: '0.8vw',
    paddingLeft: '0.3vw',
    paddingRight: '0.3vw',
    color: "#888888",
    zIndex: 10
  },
  Genderplacholder: {
    position: 'absolute',
    zIndex: 100,
    marginLeft: '0.6vw',
    backgroundColor: "#FFFFFF",
    fontSize: '0.8vw',
    paddingLeft: '0.3vw',
    paddingRight: '0.3vw',
    color: "#888888"
  },
  FirstNameView: {
    width: '16vw',
    marginRight: '1vw',
    justifyContent: 'center',
    marginBottom: '1.1vw'
  },
  SurnameTextboxView: {
    width: '16vw',
    marginRight: '1vw',
    justifyContent: 'center',
    marginBottom: '1.1vw'
  },
  StatusView: {
    width: '14.5vw',
    marginRight: '1vw',
    marginBottom: '1.1vw',
    marginTop: '0.3vw'
  },
  employer_idPicker: {
    width: '14.5vw',
    marginRight: '1vw',
    marginBottom: '1.1vw',
    marginTop: '0.3vw'
  },
  StatusPlacholder: {
    position: 'absolute',
    marginLeft: '0.6vw',
    backgroundColor: "white",
    fontSize: '0.8vw',
    paddingLeft: '0.3vw',
    paddingRight: '0.3vw',
    color: "#888888",
    marginTop: '-0.4vw'
  },
  DOBTextBoxView: {
    width: '10.5vw',
    marginBottom: '1.1vw',
    marginTop: '0.3vw'
  },
  AgeTextBoxView: {
    width: '5.5vw',
    marginRight: '1vw',
    justifyContent: 'center',
    marginBottom: '1.1vw',
    marginLeft: "3vw"
  },
  ADOBTextBoxView: {
    width: '14.4vw',
    marginRight: '1vw',
    justifyContent: 'center',
    marginBottom: '1.1vw',
  },
  FatherTextBoxView: {
    width: '14vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
    marginRight: '1vw'
  },
  MobileNoTextBoxView: {
    width: '10.6vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
    marginRight: '1vw'
  },
  CityTextBoxView: {
    width: '10vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
    marginRight: '1vw'
  },
  CountryTextBoxView: {
    width: '7.5vw',
    marginBottom: '1.1vw',
    marginRight: '1vw',
    marginTop: '0.3vw'
  },
  ReceiveMsgView: {
    width: '12.4vw',
    flexDirection: 'row',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"2vw",
    marginTop:"-0.8vw"
  },
  ReceivePlacholder: {
    marginRight: '0.6vw',
    fontSize: '0.9vw',
    color: "#888888"
  },
  EmeContact: {
    flexDirection: 'row',
    marginTop: '1.2vw',
    paddingLeft: '2.5vw'
  },
  EmergencyTextBox: {
    width: '14.4vw',
    marginBottom: '1.1vw',
    justifyContent: 'center',
    marginRight: '1vw'
  },
  CameraImage: {
    height: '4vw',
    width: '4vw',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    marginRight: '2.6vw',
    borderRadius: '3.3vw'
  },
  CompanyNameText: {
    flex: 0.5,
    textAlign: "center",
    fontSize: '1vw'
  },
  CompanyLabelText: {
    flex: 0.4,
    textAlign: "center",
    fontSize: '1vw'
  },
  TopHeaderView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.2vw',
    marginBottom: '1.2vw'
  },
  SelecteDoctorLabel: {
    fontSize: '1vw',
    color: "grey"
  },
  DoctorSelectView: {
    width: '11.5vw',
    marginTop: '0.6vw'
  },
  InformationView: {
    flexDirection: 'column',
    marginTop: '2.5vw',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2.5vw'
  },
  AMAPatientsText: {
    marginLeft: '1.2vw',
    marginBottom: '1.2vw',
    fontWeight: "bold"
  },
  ContactAdministraorText: {
    marginLeft: '1.2vw',
    marginBottom: '1.2vw',
    fontWeight: "bold"
  },
  IndentityMsg: {
    color: "grey",
    fontSize: '0.8vw',
    marginTop: '1.2vw',
    fontWeight: '700'
  },
  LeftSideTextboxView: {
    height: '7vw',
    width: '23.2vw',
    borderRadius: '0.4vw',
    shadowColor: "#00000029",
    shadowOffset: { width: '-0.1vw', height: '0.2vw' },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    marginTop: '2vw',
    alignSelf: "center",
    padding: '0.6vw'
  },
  EmmployeeIDView: {
    flexDirection: "row",
    flex: 1,
    marginTop: '1.2vw'
  },
  CompanyAddClickView: {
    flexDirection: "row",
    flex: 1,
    marginTop: '3.4vw'
  },
  AddBtnView: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: '1.2vw'
  },
  AddBtnText: {
    fontSize: '0.8vw',
    fontWeight: "500"
  },
  AddCorporateView: {
    marginTop: '3.4vw',
    height: '7vw',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  AddCorporateBtnText: {
    fontSize: '0.8vw',
    fontWeight: "500",
    marginLeft: 5
  },
  InsuranseTextBoxView: {
    height: '7vw',
    width: '23.2vw',
    borderRadius: 6,
    shadowColor: "#00000029",
    shadowOffset: { width: '-0.1vw', height: '0.2vw' },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    marginTop: '2vw',
    alignSelf: "center",
    padding: '0.6vw'
  },
  AddInsuranceText: {
    fontSize: '0.8vw',
    fontWeight: "500",
    marginLeft: '0.3vw'
  },
  SucessMsgText: {
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    fontSize: '1vw'
  },
  DoctorsNameView: {
    position: "absolute",
    marginTop: '7vw',
    width: "40%",
    backgroundColor: "#F0F8FF",
    border: "1px solid lightgrey",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    alignContent: "center",
    flex: 1,
    borderRadius: '0.6vw',
    padding: '2vw',
    height: '18vw',
    flexDirection: "row",
    flex: 1
  },
  SaveBtnText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8vw'
  },
  CancelBtnText: {
    fontWeight: 'bold',
    fontSize: '0.8vw'
  },
  YesBtnText: {
    color: 'white',
    fontSize: '0.8vw',
  },
  CreateAppointmentView: {
    position: "absolute",
    marginTop: '7vw',
    // width: "70%",
    // height:"48%",
    backgroundColor: color.white,
    // borderBottomRightRadius: 10, 
    // borderBottomLeftRadius: 10, 
    // paddingBottom: 10, 
    border: "1px solid lightgrey",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    alignContent: "center",
    flex: 1,
    borderRadius: '0.6vw',
    padding: '2vw'
  },
  CreateAppointmentReturnView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: '0.6vw',
    paddingTop: '0.3vw',
    paddingBottom: '0.3vw',
    borderBottomColor: "#E4E8F1",
    borderBottomWidth: 1
  },
  CreateAppoinPatientName: {
    color: 'black',
    marginLeft: '0.6vw',
    fontSize: '0.9vw'
  },
  CreateAppoinAccountNum: {
    color: '#ADB4C1',
    marginLeft: '0.6vw',
    fontSize: '0.9vw'
  },
  CreateAppoinText: {
    textAlign: 'center',
    fontSize: '0.8vw',
    alignSelf: "center",
    marginLeft: '0.6vw'
  },
  CreateAppointmentTouch: {
    paddingLeft: '1.2vw',
    paddingRight: '1.2vw',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.6vw',
    marginLeft: '1.2vw'
  },
  CreateAppointmentText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8vw'
  },
  SkipBtnTouch: {
    paddingLeft: '2vw',
    paddingRight: '2vw',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#313131',
    borderRadius: '0.6vw',
    paddingBottom: '0.6vw',
    paddingTop: '0.3vw',
    marginTop: '0.6vw'
  },
  SkipBtnText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.1vw'
  },
  IDProofView: {
    marginLeft: '1vw',
    marginRight: '1vw'
  },
  IDProoftype: {
    color: 'black',
    fontSize: '0.6vw',
    textAlign: 'center',
    marginTop: 3
  },
  IDProofImg: {
    color: 'grey',
    fontSize: '0.5vw',
    textAlign: 'center',
    marginTop: 3
  },
  SelectFileTouch: {
    marginLeft: '1.2vw',
    marginRight: '2vw',
    justifyContent: 'center',
    paddingLeft: '0.6vw',
    paddingRight: '0.6vw',
    borderRadius: '0.6vw',
    height: '2.3vw',
    marginTop: '0.3vw'
  },
  SelectFileText: {
    color: "white",
    textAlign: 'center',
    fontSize: '0.8vw'
  },
  CardImg: {
    height: '3.4vw',
    width: '3.4vw'
  },
  AadharcardTextBox: {
    flexDirection: 'row',
    marginTop: '1.2vw'
  },
  IDProofFuncView: {
    flexDirection: 'row',
    marginTop: '1.2vw',
    flexWrap: 'wrap'
  },
  ProofImg: {
    height: '2vw',
    width: '2vw'
  },
  ProofImgTouch: {
    marginBottom: '1.2vw',
    marginRight: '1vw',
    height: '6.3vw',
    width: '6.3vw',
    alignSelf: 'center',
    borderRadius: '0.6vw',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ProofText: {
    fontSize: '0.8vw',
    textAlign: 'center'
  },
  PickerContainer: {
    width: '5.5vw',
    marginBottom: '1.1vw',
    marginRight: '0.3vw',
    marginTop: '0.3vw'
  },
  PickerTitle: {
    position: 'absolute',
    marginLeft: '0.6vw',
    backgroundColor: "white",
    fontSize: '0.8vw',
    paddingLeft: '0.3vw',
    paddingRight: '0.3vw',
    color: "#888888"
  },
  ReferrefTextBoxView: {
    marginTop: '0.6vw',
    marginLeft: '2.5vw'
  },
  ReferrefTextBoxText: {
    position: 'absolute',
    backgroundColor: 'white',
    marginLeft: '0.2vw',
    paddingHorizontal: '0.3vw',
    fontSize: '0.8vw',
    color: "#888888"
  },
  ReferrefTextBoxTextInput: {
    paddingLeft: '0.6vw',
    minWidth: "100%",
    height: '2.3vw',
    borderWidth: 1,
    marginTop: '0.5vw',
    borderRadius: '0.2vw'
  },
  InsurancePopupView: {
    width: "70%",
    height: '10vw',
    borderRadius: '1vw',
    shadowOffset: { width: '-0.1vw', height: '0.2vw' },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    justifyContent: "center",
    alignItems: "center"
  },
  InsuranceAlertText: {
    fontSize: '0.9vw',
    fontWeight: "bold"
  },
  InsuranceYesBtnTouch: {
    borderRadius: '1vw',
    width: '4vw',
    height: '1.5vw',
    alignItems: "center",
    justifyContent: "center",
    marginRight: '0.6vw'
  },
  InsuranceYesBtnText: {
    fontSize: '0.8vw',
    fontWeight: "500"
  },
  CameraModelView: {
    marginTop: '1.2vw',
    marginBottom: '1.2vw',
    flexDirection: 'row',
    justifyContent: "flex-end",
    zIndex: -1
  },
  CameraModelImg: {
    height: '1.2vw',
    width: '1.2vw',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    marginRight: '2.5vw'
  },
  SubHeadingText: {
    fontSize: '1vw',
    fontWeight: '500',
    marginRight: '1vw',
    marginBottom: '1vw',
  },
  LayouTRow: {
    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', zIndex: -1
  },
  OptionalView: {
    display: 'flex', flexDirection: 'row', height: '2vw', justifyContent: 'center', alignItems: 'center'
  },
  OptionalLine: {
    borderBottomWidth: 1, width: '40%'
  },
  OptionalText: {
    paddingHorizontal: '1vw', fontSize: '0.9vw', fontWeight: '500'
  }
});

// const styles = StyleSheet.create({
//   headingText: {
//     fontSize: 15,
//     color: color.black
//   },

// });