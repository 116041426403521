import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import { NavigationTopHeader } from "../BaseComponent";
import DoctorQmsConfig from "./AdminQmsConfig/DoctorConfig";
import QmsConfig from "./AdminQmsConfig/QmsCongfig";


export default class AdminQmsConfiq extends Component {
    constructor(props) {
        super(props);
        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {} : JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
        this.state = {
            selectedTab: "doctorQmsConfig",
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ? false : true,
            userType: this.props.userType,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor
        }
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    changeTab(key) {
        this.setState({
            selectedTab: key,
        })
    }
    showResposeValue = (title, message) => {
        this.props.showResposeValue(title, message)
    }
    renderComponent = () => {
        var selectedComponent = this.state.selectedTab

        var component = {
            doctorQmsConfig: (
                <DoctorQmsConfig
                    showResposeValue={this.showResposeValue.bind(this)}
                    userType={this.state.userType}
                    selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                />),
            qmsConfig: (
                <QmsConfig
                    showResposeValue={this.showResposeValue.bind(this)}
                    userType={this.state.userType}
                    selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                />)
        }
        return (
            <View style={Styles.componentContent}>
                {component[selectedComponent]}
            </View>
        )
    }

    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.userType === Constants.ROLE_FO || this.state.isOPT ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                    selectedTab={"adminQmsConfiq"}
                    isNavigationBorder={true}
                />
                <View >
                    <View style={Styles.QmsHeaderTitle}>
                        <View style={[Styles.QmsDoctorConfigTitle, this.state.selectedTab === "doctorQmsConfig"
                            ? Styles.DoctorQmsConfigActive : null]}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.changeTab("doctorQmsConfig")
                                }}>
                                <Text style={Styles.TextFontSize}>{"Doctor Configuration"}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={[Styles.QmsConfigTitle, this.state.selectedTab === "qmsConfig"
                            ? Styles.DoctorQmsConfigActive : null]}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.changeTab("qmsConfig")
                                }}>
                                <Text style={Styles.TextFontSize}>{"Qms Configuration"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View>
                        {this.renderComponent()}
                    </View>
                </View>
            </View>
        )
    }
}

const Styles = StyleSheet.create({
    componentContent: {
        maxHeight: "100vh",
        height: "75vh",
        width: "88vw",
        maxWidth: "88vw",
        marginLeft: "3vw",
        marginTop: "1vw",
        boxShadow: "2px 3px 6px #808080",
        borderRadius: "0.5vw",
        fontSize: "1vw"

    },
    QmsHeaderTitle: {
        flexDirection: "row",
        marginLeft: "3vw",
        fontSize: "1vw"
    },
    QmsDoctorConfigTitle: {
        height: "2vw",
        alignSelf: "auto",
        width: "10vw",
        fontSize: "1vw"
    },
    TextFontSize: {
        fontWeight: "500",
        textAlign: "center",
        fontSize: "1vw"
    },
    DoctorQmsConfigActive: {
        borderBottomColor: color.themeDark,
        borderBottomWidth: "0.18vw"
    },
    QmsConfigTitle: {
        height: "2vw",
        alignSelf: "auto",
        width: "10vw"
    }
})