import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView, Image } from 'react-native';
import { CommonHistorySectionHeader } from '../BaseComponent';
import { color } from '../../../styles/Color';
import Pagination from '../../doctor/common/Pagination';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';
import { Tooltip } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";


export default class PendingBillsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PendingBillList: [],
            afterPaginationPendingBillList: [],
            op_total_pending: '',
            ip_total_pending: '',
            pharmacy_total_pending: '',
            lab_total_pending: '',
            ot_total_pending: "",
            overall_pending: "",
            searchPatientData: "",
            fromDate: "",
            toDate: ""
        };
    }
    componentDidMount() {
        this.getPendingBillList()
    }

    getPendingBillList = () => {
        var url = Constants.PENDING_BILL_REPORT + "?start_date=" + this.state.fromDate + "&end_date=" + this.state.toDate + "&search_key=" + this.state.searchPatientData
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getPendingBillReportSuccess,
            this.getPendingBillReportFailure
        );
    }

    getPendingBillReportSuccess = response => {
        if (response && response.status == "success") {
            this.setState({
                PendingBillList: response.data,
                op_total_pending: response.op_total_pending,
                ip_total_pending: response.ip_total_pending,
                pharmacy_total_pending: response.pharmacy_total_pending,
                lab_total_pending: response.lab_total_pending,
                ot_total_pending: response.ot_total_pending,
                overall_pending: response.overall_pending
            })
        } else {
            this.props.showResponseValue("error", response.message)
        }
    }

    getPendingBillReportFailure = error => {
        this.props.showResponseValue("error", error.message)

    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }

    renderHeader = () => {
        return (
            <View style={styles.HeaderMain}>
                <CommonHistorySectionHeader
                    heading1={"PATIENT'S DETAILS"}
                    heading2={"OUT-PATIENT"}
                    heading3={"IN-PATIENT"}
                    heading4={"PHARMACY"}
                    heading5={"LABORATORY"}
                    heading6={"OPERATION-THEATRE"}
                    heading7={"TOTAL AMOUNT"}
                    noOfColumn={7}
                    columnSize={[0.16, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14]}
                />
            </View>
        )
    }

    renderIPAdvanceDataList = () => {
        return (
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ zIndex: -1, height: "61vh" }}>
                {this.state.PendingBillList && this.state.PendingBillList.length > 0 ?
                    this.state.afterPaginationPendingBillList && this.state.afterPaginationPendingBillList.map((item, index) => {
                        return (
                            <View style={styles.dataContainer} >
                                <View style={{ flex: 0.16, marginHorizontal: "1.5vw" }}>
                                    <Text style={[styles.TableDataText, { fontWeight: "400" }]}>{item.name}</Text>
                                    <Text style={styles.TableSubText}>{item.uhid}</Text>
                                    <Text style={styles.TableSubText}>{item.mobile_number}</Text>
                                </View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.op_pending}</Text></View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.ip_pending}</Text></View>
                                <View style={{ flex: 0.14 }}>
                                    <Text style={styles.TableDataText}>{item.pharmacy_pending == 0 ? 0 : (item.pharmacy_pending).toFixed(2)}</Text>
                                </View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.lab_pending}</Text></View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.ot_pending}</Text></View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{(item.total_pending).toFixed(2)}</Text></View>
                            </View>
                        )
                    }) :
                    <View style={styles.NoRecordContainer}>
                        <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
                    </View>
                }
            </ScrollView>
        )
    }

    renderPaginationView() {
        return (
            <View style={styles.paginationContainer}>
                {
                    this.state.PendingBillList && this.state.PendingBillList.length > 0 ?
                        <Pagination
                            paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalItems={this.state.PendingBillList}
                        /> : null
                }
            </View>
        )
    }
    paginationChangedValues(data) {
        this.setState({
            afterPaginationPendingBillList: data
        })
    }

    DecimalAmount = (Useramount) => {
        var amount = String(Useramount).split(".")[0];
        var AfterDecimal = String(Useramount).split(".")[1]
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
    }
    WholeAmount = (Useramount) => {
        var amount = Useramount;
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
    }
    TooltipChange = (value) => {
        if (value % 1 != 0) {
            return this.DecimalAmount(value)
        }
        else if (value % 1 == 0) {
            return this.WholeAmount(value)
        }
    }

    renderTotalAmounts = (color, value, placehold) => {
        return (
            <View style={[styles.CardContainer, { backgroundColor: color }]}>
                <View style={styles.SubContainer}>
                    <View style={{ marginRight: ".3vw" }}>
                        <Image
                            style={styles.ImageStyles}
                            source={require("../../../../assets/images/IPActions/Rupee.png")}
                        />
                    </View>
                    <View style={styles.cardTooltipDiv} >
                        {value && (value.toString().length) > 10 ?
                            <Tooltip placement="topLeft" title={this.TooltipChange(value)}>
                                <Text style={styles.TooltipText}>
                                    {value && value.toString().length > 10 ? value && this.TooltipChange((value.toString()).slice(0, 10)) + ".." : ''}
                                </Text>
                            </Tooltip> :
                            <Text style={{ fontSize: value && value.toString().length > 8 ? ".8vw" : "1.01vw", fontWeight: "bold" }}>{value ? this.TooltipChange(value) : 0}</Text>
                        }
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
                    <Text style={{ fontSize: ".6vw" }}>{placehold}</Text>
                </View>
            </View>
        )
    }

    renderFilterOPtion = () => {
        return (
            <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <View style={{ margin: "0.4vw", flexDirection: "row", alignItems: "center" }}>
                    <Text style={styles.headerText}>Statistics</Text>
                    {this.renderTotalAmounts(color.cardLightGreen, this.state.op_total_pending, "OP Amount")}
                    {this.renderTotalAmounts(color.cardLightOrange, this.state.ip_total_pending, "IP Amount")}
                    {this.renderTotalAmounts(color.cardLightGrey, this.state.pharmacy_total_pending, "Pharmacy Amount")}
                    {this.renderTotalAmounts(color.cardLightYellow, this.state.lab_total_pending, "Lab Amount")}
                    {this.renderTotalAmounts(color.cardLightRed, this.state.ot_total_pending, "OT Amount")}
                    {this.renderTotalAmounts(color.cardLightPink, this.state.overall_pending, "Total Pending Amount")}
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", marginRight: ".25vw", zIndex: 1 }}>
                    <View style={{ flexDirection: "row", zIndex: 0 }}>
                        <TextInput
                            value={this.state.searchPatientData}
                            onChangeText={(text) => {
                                this.setState({
                                    searchPatientData: text
                                })
                            }}
                            style={{ borderWidth: 1, fontSize: "0.95vw", height: "2.2vw", width: "13.75vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray }}
                            placeholder="Pt Name / Mobile Num / UHID"
                            onKeyPress={(e) => { e.key === "Enter" ? this.getPendingBillList() : null }}
                        />
                        <TouchableOpacity
                            style={{ height: "2.2vw", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                            onPress={() => {
                                this.getPendingBillList()
                            }}>
                            <Icon name='search' size={"1.2vw"} color={color.white}></Icon>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View style={styles.mainContainer}>
                {this.renderFilterOPtion()}
                {this.renderHeader()}
                {this.renderIPAdvanceDataList()}
                {this.renderPaginationView()}
            </View>
        );
    }
}
const styles = StyleSheet.create({
    container: {
        margin: "0.4vw"
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.8vw",
        justifyContent: 'center',
        flex: 1,
        alignItems: "center",
    },
    TableDataText: {
        fontSize: '1vw'
    },
    paginationContainer: {
        marginHorizontal: "1.2vw"
    },
    TableSubText: {
        fontSize: "0.8vw"
    },
    HeaderMain: {
        marginTop: "1vw",
        zIndex: -1
    },
    mainContainer: { width: "98%", height: "100%", backgroundColor: color.white, margin: "1vw" },
    NoRecordText: { color: color.placeholder, marginTop: "10vw" },
    NoRecordContainer: { flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" },
    headerText: { fontSize: 18, fontWeight: "500" },
    CardContainer: {
        marginRight: ".65vw", borderRadius: ".25vw", padding: "1vw", height: "3.5vw", marginLeft: "0.5vw",
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5,
    },
    SubContainer: { flexDirection: "row", justifyContent: "flex-end", minWidth: "7.2vw" },
    cardValue: { color: "white", fontSize: "1.05vw", fontWeight: "bold" },
    ImageStyles: {
        width: "1.25vw",
        height: "1.25vw",
        marginTop: "0.2vw",
        tintColor: color.black
    },
    TooltipText: {
        fontSize: "1vw",
        fontWeight: "bold"
    },
})      
