import React from 'react';
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonSectionHeader, RightPanelEyeSelection } from '../BaseComponent';
import BaseComponentStyle from "../BaseComponentStyle";
import { View, Text, TouchableOpacity, StyleSheet, TextInput, CheckBox } from "react-native";
import { color } from '../../../styles/Color';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';

const pageName = "Gonioscopy";

export default class GonioscopyNew extends BaseComponentStyle {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: this.props.patientAppointment.is_locked,
            patientAppointment: this.props.patientAppointment,
            itemListLoaded: false,
            selectedTab: "BeforeDilation",
            RE_Inferior: "",
            RE_Nasal: "",
            RE_Superior: "",
            RE_Temporal: "",
            LE_Inferior: "",
            LE_Nasal: "",
            LE_Superior: "",
            LE_Temporal: "",
            copyRightToLeft: false,
            copyLeftToRight: false,
            selectedEye: "",
            comments1: "",
            comments2: "",
            getAvailableEyeTypes: [],
            getAvailableEyeTypesId: [],
            isEdit: false,
            selectedEye_id: null,
            selectedEyeLE_id: null
        }
    }

    componentWillReceiveProps(props) {
        if (props.editItem) {
            if (props.editItem.title === pageName) {
                let itemList = props.editItem?.item ? props.editItem.item : [];

                let RE_Inferior = "";
                let RE_Nasal = "";
                let RE_Superior = "";
                let RE_Temporal = "";
                let LE_Inferior = "";
                let LE_Nasal = "";
                let LE_Superior = "";
                let LE_Temporal = "";
                let comments = "";
                let selectedEye_id = null;
                let selectedEyeLE_id = null;

                if (itemList && itemList[0]) {
                    if (Object.keys(itemList[0]).length > 0) {

                        if (itemList[0]?.eye_type == "RE") {
                            RE_Inferior = itemList[0].inferior_grade;
                            RE_Nasal = itemList[0].nasal_grade;
                            RE_Superior = itemList[0].superior_grade;
                            RE_Temporal = itemList[0].temporal_grade;
                            selectedEye_id = itemList[0].id
                            comments = itemList[0].comments
                        }

                        if (itemList[0]?.eye_type == "LE") {
                            LE_Inferior = itemList[0].inferior_grade;
                            LE_Nasal = itemList[0].nasal_grade;
                            LE_Superior = itemList[0].superior_grade;
                            LE_Temporal = itemList[0].temporal_grade;
                            selectedEyeLE_id = itemList[0].id;
                            comments = itemList[0].comments
                        }

                        if ((RE_Inferior != "" || RE_Nasal != "" || RE_Superior != "" || RE_Temporal != "") || (LE_Inferior == "" && LE_Nasal == "" && LE_Superior == "" && LE_Temporal == "")) {
                            this.setState({
                                selectedEye: "RE",
                            })
                        } else {
                            this.setState({
                                selectedEye: "LE",
                            })
                        }

                        this.setState({
                            selectedTab: itemList[0].before_dilation ? "BeforeDilation" : "AfterDilation",
                            RE_Inferior: RE_Inferior,
                            RE_Nasal: RE_Nasal,
                            RE_Superior: RE_Superior,
                            RE_Temporal: RE_Temporal,
                            LE_Inferior: LE_Inferior,
                            LE_Nasal: LE_Nasal,
                            LE_Superior: LE_Superior,
                            LE_Temporal: LE_Temporal,
                            itemListLoaded: true,
                            selectedEye_id: selectedEye_id,
                            selectedEyeLE_id: selectedEyeLE_id,
                            comments1: comments,
                            isEdit: true,
                            copyRightToLeft: false,
                            copyLeftToRight: false,
                        }, () => { this.props.clearEditedItem(pageName, {}) })

                    }
                }
                if (itemList && itemList[1]) {
                    if (Object.keys(itemList[1]).length > 0) {
                        if (itemList[1]?.eye_type == "RE") {
                            RE_Inferior = itemList[1].inferior_grade;
                            RE_Nasal = itemList[1].nasal_grade;
                            RE_Superior = itemList[1].superior_grade;
                            RE_Temporal = itemList[1].temporal_grade;
                            selectedEye_id = itemList[1].id
                            comments = itemList[1].comments
                        }

                        if (itemList[1]?.eye_type == "LE") {
                            LE_Inferior = itemList[1].inferior_grade;
                            LE_Nasal = itemList[1].nasal_grade;
                            LE_Superior = itemList[1].superior_grade;
                            LE_Temporal = itemList[1].temporal_grade;
                            selectedEyeLE_id = itemList[1].id;
                            comments = itemList[1].comments
                        }

                        if ((RE_Inferior != "" || RE_Nasal != "" || RE_Superior != "" || RE_Temporal != "") || (LE_Inferior == "" && LE_Nasal == "" && LE_Superior == "" && LE_Temporal == "")) {
                            this.setState({
                                selectedEye: "RE",
                            })
                        } else {
                            this.setState({
                                selectedEye: "LE",
                            })
                        }

                        this.setState({
                            selectedTab: itemList[1].before_dilation ? "BeforeDilation" : "AfterDilation",
                            RE_Inferior: RE_Inferior,
                            RE_Nasal: RE_Nasal,
                            RE_Superior: RE_Superior,
                            RE_Temporal: RE_Temporal,
                            LE_Inferior: LE_Inferior,
                            LE_Nasal: LE_Nasal,
                            LE_Superior: LE_Superior,
                            LE_Temporal: LE_Temporal,
                            itemListLoaded: true,
                            selectedEye_id: selectedEye_id,
                            selectedEyeLE_id: selectedEyeLE_id,
                            comments2: comments,
                            isEdit: true,
                            copyRightToLeft: false,
                            copyLeftToRight: false,
                        }, () => { this.props.clearEditedItem(pageName, {}) })
                    }
                }
            }
        }
    }

    AddGonioscopy = () => {
        let states = this.state;
        let { RE_Inferior, RE_Nasal, RE_Superior, RE_Temporal, LE_Inferior, LE_Nasal, LE_Superior, LE_Temporal, selectedEye_id, selectedEyeLE_id } = this.state;
        var service_url = Constants.OPTHALMOLOGY_GONIOSCOPY;
        let data = [];
        let IsHaveRightEyeValues = false;
        let IsHaveLeftEyeValues = false;

        if (this.state.copyRightToLeft || this.state.copyLeftToRight) {

            IsHaveRightEyeValues = true;
            IsHaveLeftEyeValues = true;

            data = [
                {
                    "appointment_id": states.patientAppointment.appointment_id,
                    "eye_type": "RE",
                    "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                    "inferior_grade": RE_Inferior,
                    "superior_grade": RE_Superior,
                    "temporal_grade": RE_Temporal,
                    "nasal_grade": RE_Nasal,
                    "comments": states.comments1
                },
                {
                    "appointment_id": states.patientAppointment.appointment_id,
                    "eye_type": "LE",
                    "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                    "inferior_grade": LE_Inferior,
                    "superior_grade": LE_Superior,
                    "temporal_grade": LE_Temporal,
                    "nasal_grade": LE_Nasal,
                    "comments": states.comments2
                }
            ];
            if (selectedEye_id) { data[0]["id"] = selectedEye_id; }
            if (selectedEyeLE_id) { data[1]["id"] = selectedEyeLE_id; }

        }
        else if (this.state.selectedEye == "RE") {
            IsHaveRightEyeValues = true;
            data = [
                {
                    "appointment_id": states.patientAppointment.appointment_id,
                    "eye_type": "RE",
                    "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                    "inferior_grade": RE_Inferior,
                    "superior_grade": RE_Superior,
                    "temporal_grade": RE_Temporal,
                    "nasal_grade": RE_Nasal,
                    "comments": states.comments1

                }
            ];
            if (selectedEye_id) { data[0]["id"] = selectedEye_id; }
        }
        else if (this.state.selectedEye == "LE") {
            IsHaveLeftEyeValues = true;
            data = [
                {
                    "appointment_id": states.patientAppointment.appointment_id,
                    "eye_type": "LE",
                    "before_dilation": states.selectedTab === "BeforeDilation" ? true : false,
                    "inferior_grade": LE_Inferior,
                    "superior_grade": LE_Superior,
                    "temporal_grade": LE_Temporal,
                    "nasal_grade": LE_Nasal,
                    "comments": states.comments2

                }
            ];
            if (selectedEyeLE_id) { data[0]["id"] = selectedEyeLE_id; }
        }

        if (IsHaveLeftEyeValues || IsHaveRightEyeValues) {
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.postGonioscopySuccess,
                this.postGonioscopyFailure
            );
        } else {
            this.props.showResposeValue("error", "Please Select Any Eye Values")
        }
    }

    postGonioscopySuccess = response => {

        if (response.status === "success") {
            this.props.refreshData(pageName);
            this.props.showResposeValue("success", response.message);
            this.clearDetails()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    };

    postGonioscopyFailure = error => {
        this.props.showResposeValue("error", error.message)
    };

    AlphaNumericValidation(num) {
        return /^[0-9 a-z A-Z_]+$/i.test(num);
    }

    clearDetails = (Label) => {
        this.setState({
            itemListLoaded: false,
            selectedTab: Label ? Label : "BeforeDilation",
            RE_Inferior: "",
            RE_Nasal: "",
            RE_Superior: "",
            RE_Temporal: "",
            LE_Inferior: "",
            LE_Nasal: "",
            LE_Superior: "",
            LE_Temporal: "",
            copyRightToLeft: false,
            copyLeftToRight: false,
            selectedEye: "",
            selectedEye_id: undefined,
            selectedEyeLE_id: undefined,
            comments1: "",
            comments2: "",
            isEdit: false
        }, () => {
            this.props.clearEditedItem(pageName, {})
            this.props.refreshData(pageName);
        })
    }

    copyData = () => {
        let { RE_Inferior, RE_Nasal, RE_Superior, RE_Temporal, LE_Inferior, LE_Nasal, LE_Superior, LE_Temporal, selectedEye } = this.state;
        if (selectedEye == "RE") {
            this.setState({
                LE_Inferior: RE_Inferior,
                LE_Nasal: RE_Nasal,
                LE_Superior: RE_Superior,
                LE_Temporal: RE_Temporal
            })
        }
        else if (selectedEye == "LE") {
            this.setState({
                RE_Inferior: LE_Inferior,
                RE_Nasal: LE_Nasal,
                RE_Superior: LE_Superior,
                RE_Temporal: LE_Temporal
            })
        }

    }

    clearData(ClearableEyeType) {
        if (ClearableEyeType == "RE") {
            this.setState({
                RE_Inferior: "",
                RE_Nasal: "",
                RE_Superior: "",
                RE_Temporal: "",
                comments1: ""

            })
        }
        else if (ClearableEyeType == "LE") {
            this.setState({
                LE_Inferior: "",
                LE_Nasal: "",
                LE_Superior: "",
                LE_Temporal: "",
                comments2: ""
            })
        }
    }

    renderTextBox = (stateKey, label, placeholder) => {
        var states = this.state
        return (
            <View style={{ marginTop: "1.2vw" }}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.6vw', backgroundColor: "white", paddingLeft: '0.5vw', paddingRight: '0.5vw', color: color.black }}>{label}</Text>
                <TextInput
                    value={states[stateKey]}
                    placeholder={placeholder}
                    style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '2.65vw', marginTop: '0.5vw', width: "14vw" }}
                    onChangeText={(text) => {
                        if (states.selectedEye == "") {
                            states[stateKey] = ""
                            this.setState({
                                states
                            }, () => { this.props.showResposeValue("error", "Please Select Any Eye Values") })
                        } else if (this.AlphaNumericValidation(text) || text == "") {
                            states[stateKey] = text
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </View>
        )
    }

    renderCheckBox = (label, value, key) => {
        return (
            <View style={{ flexDirection: 'row', alignSelf: 'flex-end', marginTop: '0.6vw' }}>
                <CheckBox value={value}
                    style={{ marginRight: '0.8vw', width: '1.2vw', height: '1.2vw' }}
                    onValueChange={() => {

                        var states = this.state;

                        if (key == "copyRightToLeft" && !value) {
                            states["copyLeftToRight"] = false
                        }
                        if (key == "copyLeftToRight" && !value) {
                            states["copyRightToLeft"] = false
                        }

                        states[key] = !value

                        this.setState({
                            states
                        }, () => {
                            if (!value) {
                                this.copyData()
                            } else {
                                this.clearData(key == "copyRightToLeft" ? "LE" : "RE")
                            }
                        })
                    }}
                />
                <Text style={{ fontSize: '0.8vw', color: "#888888" }}>{label}</Text>
            </View>
        );
    }

    changeTab = (tab) => {
        this.setState({
            selectedTab: tab,
        })
    }

    renderEyeTypeButton = (Title) => {
        return (
            <TouchableOpacity onPress={() => { this.setState({ selectedEye: Title }) }} style={[styles.EyeTypeButtonContainer, { backgroundColor: this.state.selectedEye == Title ? color.themeDark : null }]}>
                <Text style={[styles.EyeTypeButtonText, { color: this.state.selectedEye == Title ? color.white : color.black }]}>{Title == "RE" ? "Right (OD)" : "Left (OS)"}</Text>
            </TouchableOpacity>
        )
    }

    render() {
        const { selectedTab } = this.state;
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Gonioscopy"} />
                <View style={styles.selectableTab}>
                    <TouchableOpacity onPress={() => { this.state.isEdit ? this.clearDetails("BeforeDilation") : this.changeTab("BeforeDilation") }} style={{ flex: 0.35 }}>
                        <Text style={{ color: selectedTab === "BeforeDilation" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }}>Before Dilation</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.state.isEdit ? this.clearDetails("AfterDilation") : this.changeTab("AfterDilation") }} style={{ flex: 0.35 }}>
                        <Text style={{ color: selectedTab === "AfterDilation" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }} >After Dilation</Text>
                    </TouchableOpacity>
                </View>
                <View>
                    <CommonSectionHeader heading={"Eye Type"} />
                    <View style={styles.EyeSelectionView}>
                        {this.renderEyeTypeButton("RE")}
                        <View style={{ marginLeft: "2vw" }}>{this.renderEyeTypeButton("LE")}</View>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", paddingVertical: "1vw" }}>
                        {this.renderTextBox(this.state.selectedEye == "RE" ? "RE_Inferior" : "LE_Inferior", "Inferior Grade", "Enter grade")}
                        {this.renderTextBox(this.state.selectedEye == "RE" ? "RE_Superior" : "LE_Superior", "Superior Grade", "Enter grade")}
                        {this.renderTextBox(this.state.selectedEye == "RE" ? "RE_Temporal" : "LE_Temporal", "Temporal Grade", "Enter Temporal Grade")}
                        {this.renderTextBox(this.state.selectedEye == "RE" ? "RE_Nasal" : "LE_Nasal", "Nasal Grade", "Enter Interpretation")}
                    </View>
                    <View style={{ marginTop: "1.2vw" }}>
                        <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.6vw', backgroundColor: "white", paddingLeft: '0.5vw', paddingRight: '0.5vw', color: color.black }}>Comments</Text>
                        <TextInput
                            value={this.state.selectedEye == "RE" ? this.state.comments1 : this.state.comments2}
                            placeholder='Enter comments with in 250 character'
                            multiline={true}
                            style={{ fontSize: '1vw', paddingHorizontal: '0.5vw', borderColor: color.boxColor, borderWidth: 1, borderRadius: 4, height: '7.75vw', marginTop: '0.75vw', paddingTop: ".6vw" }}
                            onChangeText={(text) => {
                                if (this.state.selectedEye == "RE") {
                                    this.setState({
                                        comments1: text
                                    })
                                } else if (this.state.selectedEye == "LE") {
                                    this.setState({
                                        comments2: text
                                    })
                                } else {
                                    this.props.showResposeValue("error", "Please Select Any Eye Values")
                                }
                            }}
                        />
                    </View>
                    <View style={{ height: "1.5vw", marginTop: "1.35vw" }}>
                        {
                            this.state.selectedEye == "RE" ?
                                this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
                                : this.state.selectedEye == "LE" ?
                                    this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
                                    : null
                        }
                    </View>
                    <View style={{ marginTop: '2.5vw', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <TouchableOpacity onPress={() => { this.AddGonioscopy() }} disabled={this.state.isLocked}>
                            {this.renderTextBtn("Save", true, false)}
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.clearData(this.state.selectedEye) }} disabled={this.state.isLocked} style={{ marginLeft: ".75vw" }}>
                            {this.renderTextBtn("Clear", true, false)}
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    selectableTab: {
        flexDirection: "row",
        flex: 1,
        marginBottom: '1vw',
        marginTop: '0.3vw'
    },
    EyeSelectionView: {
        display: "flex",
        flexDirection: "row",
        marginTop: "1vw"
    },
    EyeTypeButtonContainer: {
        width: "7.5vw",
        height: "2.35vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: ".2vw",
        borderColor: color.lightGray
    },
    EyeTypeButtonText: {
        fontSize: ".9vw",
        fontWeight: 400
    }
})