//import liraries
import React, { Component } from 'react';
import { View, Text, ScrollView, StyleSheet, Picker, Dimensions, TextInput, TouchableOpacity } from 'react-native';
import { CommonButton, NavigationTopHeader, EditiAndDeleteForLineItem, CommonHistorySectionHeader } from './../BaseComponent';
import { color } from './../../../styles/Color';
import Style from './../../../styles/Style';

const screenHeight = Dimensions.get("window").height;
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
const navigationHeaderList = [
    { label: "Departments", value: "departments" },
]


// create a component
export class Departments extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedDepartment: {},
            selectedTokenNumberSequence: "Clinic Based",
            roomNumber: "",
            departmentList: [],
            // prepareCounterArrayList: [],
            selectedDepartmentName: "",
            editCounterDetails: {},
            isEditFlag: false,

            listofDepartments:[],
            prepareCounterArrayList: [],
            departmentName:"",


            // selectedDepartmentName:"",
            selectedDepartmentID:""

        }
    }


    componentDidMount() {
        this.getCliniLinkedUserDetails()
        this.getDepartmentDetails()
    }


    getDepartmentDetails() {
        var clintID = ""
        if (this.props.clintID) {
            clintID = this.props.clintID
        }

        // var service_url = Constants.QMS_DEPARTMENT_DETAILS+"?client_id="+clintID;
        var service_url = Constants.QMS_DEPARTMENT_DETAILS + clintID + "/";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDepartmentDetailsSuccess,
            this.getDepartmentDetailsFailure,
            "",
            "qms"
        );
    }
    getDepartmentDetailsSuccess = success => {
        if (success.status == "success") {
            this.setState({
                departmentList: success.data.counter_details
            })
        }
        // alert("success *******************> "+JSON.stringify(success))

    }
    getDepartmentDetailsFailure = error => {
       // alert("error *******************> " + JSON.stringify(error))

    }



    changeTabSection(tab) { }

    renderTopNavigation() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={"departments"} />
            </View>
        )
    }

    renderDepartmentHeader() {
        return (
            <View style={{ justifyContent: 'center', height: 35, backgroundColor: color.sectionHeadingDarkColor, }}>

                <View style={{ flexDirection: 'row' }}>
                    <Text style={[Style.historyHeaderFontStyleStartText, { flex: 0.4 }]}>{"Department Name"}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: 0.4 }]}>{"Room Number"}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: 0.2, textAlign: 'center' }]}>{"Action"}</Text>
                </View>
            </View>


            // <CommonHistorySectionHeader
            //     heading1={"Department Name"}
            //     heading2={"Room Number"}
            //     heading3={"Action"}

            //     columnSize={[0.4, 0.4, 0.2]}
            //     noOfColumn={3}
            // />
        )
    }

    editHistory(item) {
        var states = this.state;
        states["selectedDepartmentName"] = item.name;
        states["roomNumber"] = item.room_number;

        states["editCounterDetails"] = item;
        states["isEditFlag"] = true
        this.setState({ states })
    }
    deleteHistory(item) {
        alert(JSON.stringify(item))
    }

    renderDepartmentList() {
        return (
            <View>
                {
                    this.state.departmentList.length > 0 ?
                        this.state.departmentList.map((item, index) => {
                            return (
                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 10, alignItems: 'center' }}>
                                    <Text style={{ marginHorizontal: 20, flex: 0.4 }}>{item.name}</Text>
                                    <Text style={{ flex: 0.4 }}>{item.room_number}</Text>
                                    <View style={{ flex: 0.2 }}>
                                        <EditiAndDeleteForLineItem
                                            editHistory={this.editHistory.bind(this, item)}
                                            // deleteHistory={this.deleteHistory.bind(this, item)}

                                            headerKey={"testConfig"}
                                            editImage={"pencil"}
                                            // deleteImage={"trash"}
                                        />
                                    </View>
                                </View>
                            )
                        }) : null
                }
            </View>
        )

    }

    renderContainer() {
        return (
            <View style={{ flex: 1, flexDirection: 'row', marginHorizontal: 15, marginVertical: 10, borderRadius: 4, backgroundColor: color.white }}>
                <View style={{ flex: 0.65, paddingHorizontal: 15, paddingVertical: 10 }}>

                    {this.renderDepartmentHeader()}

                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: "100%" }}>
                        {/* 150 is total space for top contents like 65+45+20+35 = 165  will round 150 */}
                        {this.renderDepartmentList()}



                    </ScrollView>
                </View>
                <View style={{
                    flex: 0.35, backgroundColor: color.themeShade,
                    borderLeftColor: "#E8E8E8", borderLeftWidth: 0.5,
                    paddingHorizontal: 15, paddingVertical: 20
                }}>

                    {this.renderRightSideHeader()}
                    {/* {this.renderDepartmentDropDown("Department Name", "selectedDepartment", this.state.selectedDepartment)} */}
                    {this.renderLabelWithTxtBox("Department Name", "selectedDepartmentName", this.state.selectedDepartmentName)}
                    <View style={{zIndex:-10}}>
                    {this.renderLabelWithTxtBox("Room Number", "roomNumber", this.state.roomNumber)}
                    {this.renderTokenNumberSequence()}
                    {this.renderFilterActionButton()}
                    </View>
                </View>
            </View>
        )
    }

    // renderDepartmentDropDown(label, key, selectedValue) {

    //     // if(selectedValue && Object.keys(selectedValue).length > 0){
    //     //     selectedValue=JSON.parse(selectedValue)
    //     // }
    //     return (
    //         <View style={{ marginTop: 15 }}>
    //             <CommonHistorySectionHeader
    //                 heading1={label}
    //                 columnSize={[1]}
    //                 noOfColumn={1}
    //             // sub
    //             />
    //             <Picker
    //                 disabled={this.state.isEditFlag}

    //                 selectedValue={this.state.selectedDepartmentName}
    //                 style={[Style.pickerView, {
    //                     marginLeft: 20, marginTop: 10, borderColor: '#888888', backgroundColor: color.themeShade
    //                 }, Style.allButtonBorderRadius]}
    //                 itemStyle={{}}
    //                 onValueChange={(itemvalue) => {



    //                     var states = this.state;

    //                     var getSelectedItem = this.state.prepareCounterArrayList[itemvalue]
    //                     // states["selectedDepartment"]=getSelectedItem;
    //                     // states["selectedDepartmentName"]=getSelectedItem.name;

    //                     // alert(JSON.stringify(itemvalue))
    //                     // alert(JSON.stringify(getSelectedItem))
    //                     // alert(JSON.stringify(getSelectedItem.name))

    //                     this.setState({
    //                         selectedDepartment: getSelectedItem,
    //                         selectedDepartmentName: JSON.stringify(getSelectedItem.name)
    //                     }, () => {
    //                     }
    //                     );
    //                 }}
    //             >
    //                 <Picker.Item label="Selecte Counter " value="" />
    //                 {
    //                     this.state.prepareCounterArrayList.length > 0 ?
    //                         this.state.prepareCounterArrayList.map((item, index) => {
    //                             // let temp = JSON.stringify(item)
    //                             return (
    //                                 <Picker.Item label={item.name} value={index} key={index} />
    //                             )
    //                         })
    //                         : null
    //                 }
    //                 {/* <Picker.Item label="Male" value="Male" />
    //                 <Picker.Item label="Female" value="Female" />
    //                 <Picker.Item label="Trans" value="Trans" /> */}
    //             </Picker>
    //         </View>
    //     )
    // }

    renderTokenNumberSequence() {
        const tokenNumber = [
            { label: "Clinic Based", value: "Clinic Based" },
            { label: "Doctor Based", value: "Doctor Based" }
        ]
        return (
            <View style={{ marginTop: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Token Number Sequence"}
                    columnSize={[1]}
                    noOfColumn={1}
                // sub
                />

                <View style={{ flexDirection: "row", paddingLeft: 20, marginTop: 15 }}>
                    {
                        tokenNumber.map((item, index) => {
                            return (
                                <TouchableOpacity
                                    style={{ flexDirection: 'row', alignItems: 'center', marginRight: 15 }}
                                    onPress={() => {

                                        this.setState({
                                            selectedTokenNumberSequence: item.value
                                        })
                                    }}>
                                    <View style={{
                                        borderColor: this.state.selectedTokenNumberSequence == item.value ? color.themeDark : "#888888",
                                        backgroundColor: this.state.selectedTokenNumberSequence == item.value ? color.themeDark : null,
                                        height: 12, width: 12, borderRadius: 50, borderWidth: 1
                                    }} />
                                    <Text
                                        style={{
                                            marginLeft: 10,
                                            fontWeight: this.state.selectedTokenNumberSequence == item.value ? "500" : null,
                                            color: this.state.selectedTokenNumberSequence == item.value ? color.black : "#888888",
                                        }}>{item.label}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>

            </View>
        )
    }

    renderRightSideHeader() {
        return (
            <Text style={{ fontSize: 17, color: color.themeDark, fontWeight: '500' }}>{"Department"}</Text>
        )
    }

    renderLabelWithTxtBox(label, key, value) {
        return (
            <View style={{ marginTop: 15 }}>
                <CommonHistorySectionHeader
                    heading1={label}
                    columnSize={[1]}
                    noOfColumn={1}
                // sub
                />
                <TextInput
                    editable={key == "selectedDepartmentName" && this.state.isEditFlag ? false : true }
                    style={{ marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, paddingLeft: 10, height: 35, width: "90%", marginLeft: 20 }}
                    value={value}
                    onChangeText={(text) => {
                        var states = this.state;
                        states[key] = text;

                        if(key == "selectedDepartmentName"){
                            this.filterDepartmentNames(text);
                            states["selectedDepartmentName"] = text;
                            states["selectedDepartmentID"] = text;
                        }

                        this.setState({ states })

                        
                    }}
                />
                <View>
                    {
                        key =="selectedDepartmentName"?
                        
                            this.state.listofDepartments.length > 0 ?
                                <View style={{zIndex:999, position: 'absolute', width: 200, borderRadius: 5, borderWidth: 1, borderColor: "#888888", backgroundColor: "white", maxHeight: 200,marginLeft: 20 }}>
                                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{zIndex:999,}}>
                                        {
                                            this.state.listofDepartments.map((item, index) => {
                                                return (
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.setState({
                                                                selectedDepartmentName:item.name,
                                                                selectedDepartmentID:item.id,
                                                            },()=>{
                                                                this.setState({
                                                                    listofDepartments:[]
                                                                })
                                                            })
                                                        }}
                                                        key={index} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 15 }}>
                                                        <Text>{item.name}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </View>
                                : null
                        :
                        null
                    }
                    </View>
                    </View>
        )
    }

    onPressClearButton() {
        var states = this.state;
        states["departmentName"] = "";
        states["roomNumber"] = "";
        states["editCounterDetails"] = {};
        states["selectedDepartment"] = {};
        states["selectedDepartmentName"] = "";
        states["selectedTokenNumberSequence"] = "Clinic Based";
        states["isEditFlag"]=false;
        this.setState({ states })
    }
    onPressAddButton() {

        this.createCounterInQMS()
    }

    renderFilterActionButton() {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 20 }}>
                <View style={{}}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Add"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressAddButton.bind(this)}
                        buttonKey={"add"} />
                </View>
                <View style={{ marginLeft: 15 }}>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Clear"}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressClearButton.bind(this)}
                        buttonKey={"clear"} />
                </View>
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTopNavigation()}
                {this.renderContainer()}
            </View>
        );
    }



    getCliniLinkedUserDetails() {
        // var clintID = ""
        // if (this.props.clintID) {
        //     clintID = this.props.clintID
        // }

        // var service_url = Constants.QMS_DEPARTMENT_DETAILS+"?client_id="+clintID;
        var service_url = Constants.QMS_GET_CLINIC_LINKED_USER_FROM_EHR;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getCliniLinkedUserDetailsSuccess,
            this.getCliniLinkedUserDetailsFailure,
            "",
        );
    }
    getCliniLinkedUserDetailsSuccess = success => {
        // console.log("************************>> " + JSON.stringify(success))
        if (success.status == "success") {
            if (success.data && success.data && success.data) {
                this.prepareingCounterList(success.data)
            }
        }
    }
    getCliniLinkedUserDetailsFailure = error => { }

    prepareingCounterList(list) {
        var getDoctorList = list.doctors;
        var getPharmacyList = list.pharmacy;
        var getLaboratoryList = list.laboratory;
        var getUsersList = list.users;

        var prepareCounterArrayList = []
        if (getDoctorList.length > 0) {
            for (let i = 0; i < getDoctorList.length; i++) {
                let prepareDate = getDoctorList[i];
                prepareDate["userType"] = "Doctor";
                prepareDate["id"] = prepareDate.id;
                prepareDate["name"] = prepareDate.name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        if (getPharmacyList.length > 0) {
            for (let i = 0; i < getPharmacyList.length; i++) {
                let prepareDate = getPharmacyList[i];
                prepareDate["userType"] = "Pharmacy";
                prepareDate["id"] = prepareDate.pharmacy_id;
                prepareDate["name"] = prepareDate.pharmacy_name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        if (getLaboratoryList.length > 0) {
            for (let i = 0; i < getLaboratoryList.length; i++) {
                let prepareDate = getLaboratoryList[i];
                prepareDate["userType"] = "Laboratory";
                prepareDate["id"] = prepareDate.laboratory_id;
                prepareDate["name"] = prepareDate.laboratory_name;
                prepareCounterArrayList.push(prepareDate);
            }
        }

        // if (getUsersList.length > 0) {
        //     for (let i = 0; i < getUsersList.length; i++) {
        //         let prepareDate = getUsersList[i];
        //         prepareDate["userType"] = "User";
        //         prepareDate["id"] = prepareDate.id;
        //         prepareDate["name"] = prepareDate.employee_name;
        //         prepareCounterArrayList.push(prepareDate);
        //     }
        // }

        this.setState({
            prepareCounterArrayList: prepareCounterArrayList
        })
        // console.log(prepareCounterArrayList)

    }


    //   Create Counter -------------------------------------start
    createCounterInQMS() {

        var states = this.state;
        // console.log("create client ----------------> ");
        if (this.props.clintID) {

            var service_url = "";
            var data = {};
            if (this.state.isEditFlag) {
                service_url = Constants.QMS_UPDATE_COUNTER + this.props.clintID + "/"+this.state.editCounterDetails.slug+"/";
                // var service_url = Constants.QMS_UPDATE_COUNTER;
                data = {
                    "room_number": states.roomNumber,
                }

                OpthamologyService.getInstance().putMethod(
                    service_url,
                    JSON.stringify(data),
                    this,
                    this.createCounterInQMSSuccess,
                    this.createCounterInQMSFailure,
                    "",
                    "qms"
                );


            } else {
                service_url = Constants.QMS_CREATE_COUNTER + this.props.clintID + "/";
                // var service_url = Constants.QMS_CREATE_COUNTER;
                data = {
                    "name": states.selectedDepartmentName,
                    "slug": this.props.clintID.toString()+states.selectedDepartmentID.toString(),
                    "room_number": states.roomNumber,
                }

                OpthamologyService.getInstance().postComplaints(
                    service_url,
                    JSON.stringify(data),
                    this,
                    this.createCounterInQMSSuccess,
                    this.createCounterInQMSFailure,
                    "",
                    "qms"
                );
            }




        }
    }
    createCounterInQMSSuccess = success => {

        if (success.status == "success") {
            this.props.showResposeValue("success", success.message);
            this.getDepartmentDetails();
            this.onPressClearButton();
        } else {

            

            if (success.message && success.message.slug && success.message.slug[0]) {
                this.props.showResposeValue("error", success.message.slug[0])
            }else if(success.message){
                this.props.showResposeValue("error", success.message)
            }
            // this.props.showResposeValue("error",success.message)
        }
        // alert("create counter ----------------> "+JSON.stringify(success))

    }
    createCounterInQMSFailure = error => {
        if (error.message && error.message.slug && error.message.slug[0]) {
            this.props.showResposeValue("error", error.message.slug[0])
        }

      //  alert("````````error```````" + JSON.stringify(error))
    }
    //   Create Counter -------------------------------------end


    // auto search 

    filterDepartmentNames = (search) => {
        let { prepareCounterArrayList, listofDepartments } = this.state
        listofDepartments = prepareCounterArrayList.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
        this.setState({ listofDepartments })

    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});
