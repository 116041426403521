import moment from "moment";
import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput } from "react-native";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { color } from "../../../../../../styles/Color";
import { Constants } from "../../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader } from "../../../../BaseComponent";
import Icon from "react-native-vector-icons/FontAwesome";

const tempRows = {
    "id": "",
    "brand_name": "",
    "model_name": "",
    "iol_type": "",
    "iol_power": "",
    "quantity": "",
}

var today = new Date()
today = moment(today).format("YYYY-MM-DD")
export default class IolAddPo extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            poRows: [],
            vendorName: "",
            vendor_id: null,
            po_date: today,
            vendorList: [],
            vendorOriginalList: [],
            isVendorSuggestion: false,
            brandSuggestionData: [],
            typedIndex: null,
            modelSuggestionData: []
        }
    }

    componentDidMount(){
        this.getVendorDetails()
    }

    getVendorDetails = () => {
        var url = Constants.ADD_VENDOR
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getVendorDetailsSuccess,
            this.getVendorDetailsFailure
          );
    }

    getVendorDetailsSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
              vendorList: response.data,
              vendorOriginalList: response.data,
            })
        }
    }

    getBrandSuggestions = (value) => {
        var url = Constants.OT_INVENTORY_IOL_PO_IOL_SUGGESTION + "?brand_name=" + value
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getBrandSuggestionsSuccess,
            this.getBrandSuggestionsFailure
        );
    }

    getBrandSuggestionsSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
              brandSuggestionData: response.data,
            })
        }
    }

    getIolModelSuggestion = (value) => {
        var url = Constants.OT_INVENTORY_IOL_PO_IOL_MODEL_SUGGESTION + "?brand_name=" + value
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIolModelSuggestionSuccess,
            this.getIolModelSuggestionFailure
        );
    }

    getIolModelSuggestionSuccess = (response) => {
        if (response.status == "success") {
            var {poRows, typedIndex} = this.state
            var data = response.data
            if(data.length === 1){
                poRows[typedIndex]["id"] = data[0].id
                poRows[typedIndex]["brand_name"] = data[0].brand_name
                poRows[typedIndex]["model_name"] = data[0].model_name
                poRows[typedIndex]["iol_type"] = data[0].iol_type
                poRows[typedIndex]["iol_power"] = data[0].iol_power

                this.setState({
                    poRows,
                    typedIndex: null,
                    modelSuggestionData: []
                })
            }else if (data.length > 1){
                this.setState({
                    modelSuggestionData: data,
                })
            }
            
        }
    }

    _addExtraIolAddRows = () => {
        let { poRows } = this.state

        let current_row = poRows.length;
        let bill_line_items = [];
        let poRows_no_manitory_fields = [];
        let add_flag = false;

        for (let i = 0; i < current_row; i++) {

            if (poRows[i].brand_name && poRows[i].model_name && poRows[i].iol_type && poRows[i].iol_power && poRows[i].quantity) {
                    bill_line_items.push(poRows[i]);
            }
            else if (!poRows[i].brand_name || !poRows[i].model_name || !poRows[i].iol_type || !poRows[i].iol_power || !poRows[i].quantity) {
                poRows_no_manitory_fields.push(poRows[i])
            }
        }

        if ((current_row - bill_line_items.length) === 0) {
            add_flag = true;
        }

        if (add_flag) {

            let new_row = poRows.length + 5

            for (let i = current_row; i < new_row; i++) {
                poRows.push(JSON.parse(JSON.stringify(tempRows)));
            }

            this.setState({
                poRows,
            })
        }else if (poRows_no_manitory_fields.length > 0) {
            this.props.showResposeValue("error", "Invalid action")
        }
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    renderTextInput = (stateKey, value, flex, index) => {
        var { poRows, brandSuggestionData, typedIndex, modelSuggestionData } = this.state
        return (
            <View style={{ flex: flex, height: "8vh" }}>
                <TextInput
                    style={{
                        padding: 6,
                        marginRight: 20,
                        marginTop: 10,
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.9vw'
                    }}

                    value={value}
                    editable = {(stateKey === "brand_name" ||stateKey === "quantity") ? true : false}
                    onChangeText={(text) => {
                        var currentIndex = null
                        if(stateKey === "brand_name"){
                            currentIndex = index
                            poRows[index]['id'] = '' 
                            poRows[index]['model_name'] = '' 
                            poRows[index]['iol_type'] = '' 
                            poRows[index]['iol_power'] = ''
                            poRows[index][stateKey] = text 
                        }else if(stateKey === "quantity"){
                            var isNum = this.numberValidation(text)
                            if (isNum || text === "") {
                                poRows[index][stateKey] = text
                            }
                        }else{
                            poRows[index][stateKey] = text
                        }
                        
                        this.setState({
                            poRows,
                            typedIndex: currentIndex,
                            modelSuggestionData:[],
                        },() => {
                            if(stateKey === "brand_name" && (text).trim() != ""){
                                this.getBrandSuggestions(text)
                            }else{
                                this.setState({
                                    brandSuggestionData: []
                                })
                            }
                        })
                    }}
                />
                {(stateKey === "brand_name" && brandSuggestionData && brandSuggestionData.length > 0  && typedIndex == index)? 
                    <View style={{ zIndex: 1, position: 'absolute', marginTop: "7vh", width: "80%"}}>
                    <ScrollView
                    style={{
                        maxHeight: '25vh', marginBottom: 10, flex: 1,
                        backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                    }}
                    >
                    {brandSuggestionData.map((value, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={ () => {
                            poRows[typedIndex]["brand_name"] = value
                            this.setState({
                                poRows,
                                brandSuggestionData: [],
                            }, () => {
                                this.getIolModelSuggestion(value)
                            })
                        }}
                    >
                        <Text style={{ color: color.white, fontSize: '0.9vw'}}>{value}</Text>

                    </TouchableOpacity>
                    </View>
                    )} 
                    </ScrollView>
                    </View>
                : null }
                {(stateKey === "model_name" && modelSuggestionData && modelSuggestionData.length > 1  && typedIndex == index)? 
                    <View style={{ zIndex: 1, position: 'absolute', marginTop: "7vh", width: "80%"}}>
                    <ScrollView
                    style={{
                        maxHeight: '25vh', marginBottom: 10, flex: 1,
                        backgroundColor: color.black, borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                    }}
                    >
                    {modelSuggestionData.map((item, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={ () => {
                            poRows[typedIndex]["id"] = item.id
                            poRows[typedIndex]["brand_name"] = item.brand_name
                            poRows[typedIndex]["model_name"] = item.model_name
                            poRows[typedIndex]["iol_type"] = item.iol_type
                            poRows[typedIndex]["iol_power"] = item.iol_power
                            this.setState({
                                poRows,
                                modelSuggestionData: [],
                                typedIndex: null
                            })
                        }}
                    >
                        <Text style={{ color: color.white, fontSize: '0.9vw'}}>{item.display_name}</Text>

                    </TouchableOpacity>
                    </View>
                    )} 
                    </ScrollView>
                    </View>
                : null }
            </View>
        )
    }

    clearTableData = () => {
        this.setState({
            poRows: [],
            brandSuggestionData: [],
            typedIndex: null,
            modelSuggestionData: [],
        })
    }

    postPurchaseOrder = () => {
        var { poRows, po_date, vendor_id } = this.state
        var url = Constants.OT_INVENTORY_IOL_PO

        let current_row = poRows.length;
        let bill_line_items = [];
        let Prepared_data = [];
        var isManitory = "";

        for (let i = 0; i < current_row; i++) {
            if (poRows[i].brand_name || poRows[i].quantity) {
                bill_line_items.push({iol_product_id: poRows[i].id, quantity: poRows[i].quantity, brand_name:  poRows[i].brand_name, model_name: poRows[i].model_name });
                Prepared_data.push({iol_product_id: poRows[i].id, quantity: poRows[i].quantity });
            } 
        }

         // repeated drug restriction

        var isDuplicate = false
        var brand_name = bill_line_items && bill_line_items.length > 0 && bill_line_items.map(item => { return item.brand_name })
        isDuplicate = brand_name && brand_name.length > 0 && brand_name.some((item, index)=> (
        brand_name.indexOf(item) != index
        ))

        for ( let i=0; i < bill_line_items.length; i++ ) {
            if(!bill_line_items[i].brand_name) {
                isManitory = "Please enter the brand name";
            } else if(!bill_line_items[i].model_name) {
                isManitory = "Please enter the modal name"
            } else if(!bill_line_items[i].quantity || parseInt(bill_line_items[i].quantity) == 0) {
                isManitory = "Please enter the quantity"
            }
        }

        if (isDuplicate) {
            this.props.showResposeValue("error", "Same drug repeated multiple times")
        } else if(isManitory) {
            this.props.showResposeValue("error", isManitory)
        } else if(bill_line_items && bill_line_items.length == 0 ) {
            this.props.showResposeValue("error", "Please enter the details to proceed")
        }else {

            var data = {
                "line_items": Prepared_data,
                "po_date": po_date,
                "vendor_id": vendor_id,
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.postPurchaseOrderSuccess,
                this.postPurchaseOrderFailure
              );
        }
    }

    postPurchaseOrderSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.setState({
                poRows: [],
                vendorName: "",
                vendor_id: null
            }, () => {
                this.props.changePage("iolPoList")
            })
        }else{
            this.props.showResposeValue("error", response.message)
        }
    }

    renderTableView = () => {
        let { poRows } = this.state
        if (poRows.length < 10) {
            for (let i = poRows.length; i < 10; i++) {
                poRows.push(JSON.parse(JSON.stringify(tempRows)))
            }
        }
        return(
            <View>
                <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Model Name"}
                heading4={"IOL Type"}
                heading5={"IOL Power"}
                heading6={"Quantity"}
                columnSize={[0.05, 0.25, 0.25, 0.15, 0.15, 0.15]}  //total value is == of 1
                noOfColumn={6}
                />
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '55vh' }}>
                {poRows && poRows.map((item, index) => {
                    var sno = index + 1
                    var zIndexData = (index + 1) * (-100);
                    return (
                        <View style={{ flexDirection: "row", flex: 1, zIndex: zIndexData }} >
                            <View  style={{ flex: 0.05, marginTop: 10, alignItems: "flex-start", marginHorizontal: 20}}><Text>{sno}</Text></View>
                            {this.renderTextInput('brand_name', item.brand_name, 0.25, index)}
                            {this.renderTextInput('model_name', item.model_name, 0.25, index)}
                            {this.renderTextInput('iol_type', item.iol_type, 0.15, index)}
                            {this.renderTextInput('iol_power', item.iol_power, 0.15, index)}
                            {this.renderTextInput('quantity', item.quantity, 0.12, index)}
                            <TouchableOpacity 
                            style={{flex: 0.03, alignItems: 'center', justifyContent: 'center'}}
                            onPress={() => {
                                let iolpoRows = poRows.slice(0, index).concat(poRows.slice(index + 1, poRows.length))
                                this.setState({
                                    poRows: iolpoRows
                                })
                            }}
                            >
                            <Icon size={'1.5vw'} name={"close"} color={"#000"} />
                            </TouchableOpacity>
                        </View>
                    )
                })}
                <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 10, marginRight: 20 }}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add Item"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => this._addExtraIolAddRows()}
                buttonKey={"addIolPoRows"} />
                </View>    
                </ScrollView>
                <View style={{ flexDirection: "row", justifyContent: "center", alignSelf: "center", marginTop: '5vh' }}>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Save"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.postPurchaseOrder()} }
                buttonKey={"saveIolPo"} />
                </View>
                <View style={{marginLeft: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.clearTableData()}}
                buttonKey={"clearIolPo"} />
                </View>
                </View>
            </View>
        )
    }

    vendorSuggestionList = () => {
        let {vendorList, vendorName} = this.state
        let tempDescList = this.state.vendorOriginalList
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(vendorName.toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendorList = searchListTemp;
          } else {
            vendorList = this.state.vendorOriginalList;
          }
          this.setState({
            vendorList
          })
    }

    renderTexBox = (label, key) => {
        var states = this.state
        return(
            <View>
            <View style={{width:"12vw"}}>
                <Text style={{ zIndex: 2, fontSize: '0.9vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{label}</Text>
                <TextInput 
                value={key == "po_date" ? moment(states[key]).format("DD-MM-YYYY")  :states[key]}
                style={{
                    borderColor: color.black,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "90%",
                    height: '3vw',
                    padding: '1vw',
                    marginTop: 8,
                    fontSize: '0.9vw'
                }}
                onChangeText = {(text) => {
                    if(key === "vendorName"){
                        if((text).trim() != ""){
                            states[key] = text
                            states["isVendorSuggestion"] = true
                        }else{
                            states[key] = ""
                            states["isVendorSuggestion"] = false
                            states["vendor_id"] = null
                        }
                    }
                    this.setState({
                        states
                    }, () => {
                        if(key === "vendorName" && (text).trim() != ""){
                            this.vendorSuggestionList()
                        }
                    })
                }}
                />
            </View>
            {this.state.isVendorSuggestion && states.vendorList.length > 0 &&  key === "vendorName"?
            <View style={{ zIndex: 1, position: 'absolute', marginTop: 40, width: "90%"}}>
                <ScrollView
                style={{
                    maxHeight: '25vh', marginBottom: 10, flex: 1,
                    backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                }}
                >
                   {this.state.vendorList.map((value, index) =>
                    <View style={{ height: 30 }}>
                    <TouchableOpacity key={index}
                        style={{ marginLeft: 5, marginTop: 5 }}
                        onPress={
                        () => {
                            this.setState({
                                vendorName: value.name,
                                vendor_id: value.vendor_id,
                                isVendorSuggestion: false
                            })
                        }
                        }
                    >
                        <Text>{value.name}</Text>

                    </TouchableOpacity>
                    </View>
                )} 
                </ScrollView>
            </View>
            : null
            }
            </View>
        )
    }
    

    render(){
        return(
            <View>
            <View style={{flexDirection: "row", paddingHorizontal: 20, paddingVertical: 10}}>
                <View style={{flexDirection: "row"}}>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("iolPoList")
                }}
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>Purchase Order List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>Add Purchase Order</Text>
                </TouchableOpacity>
                </View>
                <View style={{flexDirection: "row", marginLeft: "2vw"}}>
                {this.renderTexBox("Vendor Name", "vendorName")}
                {this.renderTexBox("PO Date", "po_date")}
                </View>
            </View>
            <View style={{padding: 20, zIndex: -1}}>
                {this.renderTableView()}
            </View>
            </View>
        )
    }
}