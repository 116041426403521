//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader } from "../../../BaseComponent";
import { color } from "../../../../../styles/Color";
import Pagination from "../../../../doctor/common/Pagination"
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
const navigationHeader = [
    { label: "Vendor", value: "otVendor" },
    { label: "Vendor Payment", value: "vendorPayment"}
  ]
class VendorPayment extends Component {
    constructor(props) {
        super(props);
        this.state={
          vendor_name: "",
          vendor_list_suggest: [],
          vendorDataList: [],
          isSelected: true,
          vendor_id: null,
          selectedScreen: "",
          vendorDataListAfterPagination : [],
          current_page: 0,
          records_per_page: 10,
          vendorNameList: [],
          isVendorSuggestion: false,
          vendor_id: "",
          vendorOriginalList: []
        }
    }
  componentDidMount() {
     this.getPaymentListDetails()
     this.getVendorDetails()
  }

  getVendorDetails = () => {
      var url = Constants.ADD_VENDOR
      OpthamologyService.getInstance().getComplaints(
          url,
          this,
          this.getVendorDetailsSuccess,
          this.getVendorDetailsFailure
        );
  }

  getVendorDetailsSuccess = (response) => {
      if (response.status == "success") {
          this.setState({
            vendorNameList: response.data,
            vendorOriginalList: response.data,
          })
      }
  }
    getPaymentListDetails = () => {
      this.setState({
        current_page: 1,
        records_per_page: 10,
        vendorDataListAfterPagination: [],
        vendorDataList: []
      })
      var url = Constants.VENDOR_PAYMNET_LIST + "?vendor_id=" + this.state.vendor_id
      OpthamologyService.getInstance().getComplaints(
          url,
          this,
          this.getPaymentListDetailsSuccess,
          this.getPaymentListDetailsFailure
        );
    }
  
    getPaymentListDetailsSuccess = response => {
        if (response && response.status == "success") {
            this.setState({
              vendorDataList: response.data
            })
        }
    }
    getPaymentListDetailsFailure = response => {
    }
    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    renderHeader = () => {
      return(
        <CommonHistorySectionHeader
        heading1={"S.No"}
        heading2={"Vendor Name"}
        heading3={"GST Number"}
        heading4={"Total PO"}
        heading5={"Bill Amount"}
        heading6={"Paid Amount"}
        heading7={"OutStanding Payment"}
        columnSize={[0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14]}  //total value is == of 1
        noOfColumn={7}
      />
      )
    }
    renderHeaderData() {
      return (
        <View style={{ zIndex: -10 }}>
          { this.state.vendorDataListAfterPagination && this.state.vendorDataListAfterPagination.length > 0 ?
            this.state.vendorDataListAfterPagination && this.state.vendorDataListAfterPagination.map((item, index) => {
              let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page)
              return (
                <TouchableOpacity style={VendorStyle.dataContainer} onPress={() => {
                  this.props.changeScreen("vendorBilling", {}, "", {}, "", {}, false, "", {}, {}, false, false, false, "", {}, item.vendor_id)
                }}>
                  <View style={VendorStyle.commonFlex}><Text style={[VendorStyle.TableDataText,{ marginLeft: '1.3vw' }]}>{index+1}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.vendor_name}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.vendor_gstin_number}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_purchase_order}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_bill_amount}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_paid_amount}</Text></View>
                  <View style={VendorStyle.commonFlex}><Text style={[VendorStyle.TableDataText,{ color: color.red }]}>{item.total_balance_amount}</Text></View>
                </TouchableOpacity>
              )
            }) : 
             <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "25vw" }}>
             <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
            </View>
          }
        </View>
      )
    }
    paginationChangedValues(data) {
      this.setState({
        vendorDataListAfterPagination: data
      })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
      this.setState({
       current_page: current_page,
       records_per_page: records_per_page
      })
 }
    renderPaginationView() {
      return (
        <View style={{ marginHorizontal: 20 }}>
          {
            this.state.vendorDataList && this.state.vendorDataList.length > 0 ?
              <Pagination
                paginationChangedValues={this.paginationChangedValues.bind(this)}
                totalItems={this.state.vendorDataList}
                paginationCurrentPage={this.paginationCurrentPage.bind(this)}
              /> : null
          }
        </View>
      )
    }
    vendorSuggestionList = () => {
      let {vendorNameList, selectedVendor} = this.state
      let tempDescList = this.state.vendorOriginalList
      if (tempDescList.length > 0) {
          var searchListTemp = [];
          for (let i = 0; i < tempDescList.length; i++) {
    
            if (tempDescList[i].name.toLowerCase().indexOf(selectedVendor.toLowerCase()) > -1) {
              searchListTemp.push(tempDescList[i]);
            }
          }
          vendorNameList = searchListTemp;
        } else {
          vendorList = this.state.vendorOriginalList;
        }
        this.setState({
          vendorNameList
        })
  }
    renderVendorSearch = () => {
      return(
       <View style={VendorStyle.searchContainer}>  
        <TextInput
        value={this.state.selectedVendor}
        placeholder = {"Vendor name search"}
        onChangeText={(text) => {
         if(text != "") {  
          this.setState({
            selectedVendor: text,
            isVendorSuggestion: true
          }, () => {
            this.vendorSuggestionList()
          })
         } 
          if( text == "" ) {
           this.setState({
            selectedVendor: text,
            vendor_id: ""
           },() => {
            this.getPaymentListDetails()
           })
         }
        }}
        style={VendorStyle.vendorTextBox}
      />
        {this.state.isVendorSuggestion && this.state.vendorNameList && this.state.vendorNameList.length > 0  && this.state.selectedVendor.length > 0 ?
         <View style={VendorStyle.suggessionMain}>
               <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                 style={VendorStyle.suggessionScroll}
               >
                 { this.state.vendorNameList && (this.state.vendorNameList).map((item, index) => { 
                  return( 
                   <View>
                     <TouchableOpacity
                       key={index}
                       style={VendorStyle.suggessionButton}
                       onPress={() => {
                                this.setState({
                                  selectedVendor: item.name,
                                  vendor_id: item.vendor_id,
                                  isVendorSuggestion: false
                                },() => {
                                  this.getPaymentListDetails()
                                })
                       }}
                     >
                       <Text style={VendorStyle.suggessionText}>{item.name}</Text>
                     </TouchableOpacity>
                   </View>

                  ) })}

               </ScrollView>
       </View> : null }
      </View>
      )
    }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"vendorPayment"}
          isNavigationBorder={true}
        />
        <View style={VendorStyle.container}>
          <View style= {{ zindex: -10 }}>{this.renderVendorSearch()}</View>
          <View style={{ zIndex: -10 }}>{this.renderHeader()}</View>
          <View style={{ zIndex: -10 }}>{this.renderHeaderData()}</View>
          {/* <View>{this.renderComponent(this.state.selectedScreen)}</View> */}
          {this.renderPaginationView()}
        </View>
      </View>
    );
  }
}

const VendorStyle = StyleSheet.create({
  container: {
    margin: '1.2vw'
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: "#888888",
    borderBottomWidth: 1,
    paddingVertical: '1.30vw',
    justifyContent: 'center'
  },
  commonFlex: {
    flex: 0.14
  },
  topHeaderStyle: {
    padding: '1.30vw',
    flex: 1,
    justifyContent: "flex-end",
  },
  Image: {
    height: '1.95vw',
    width: '1.95vw'
  },
  vendorText: {
    fontWeight: "500", 
    fontSize: '1.30vw'
  },
  searchContainer: {
    flexDirection: "row", 
    flex: 1, 
    justifyContent: "flex-end",
    marginTop: "0.5vw"
  },
  printContainer : { 
    width: '2.60vw', 
    height: '2.27vw', 
    borderRadius: '0.26vw', 
    backgroundColor: color.themeBackgroud, 
    paddingHorizontal: '0.32vw', 
    paddingVertical: '0.16vw',},
  TableHeader:{
    padding:'1.30vw'
  },
  TableContainer:{
    paddingHorizontal: '1.30vw'
  },
  TableDataText:{
    fontSize:'1vw'
  },
  searchContainer: { flex: 1, alignSelf: "flex-end", margin: "0.5vw" },
  vendorTextBox: { borderRadius: "0.3vw", borderWidth: 1, height: "5vh", width: "15vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray, fontSize: "1vw" },
  suggessionMain: {position: "absolute", width: "15vw", marginTop: "2.5vw", maxHeight: "15vw", zIndex: 1 },
  suggessionView: { borderBottomWidth: 1, borderBottomColor: color.grayShade },
  suggessionButton: { marginTop: 5, marginBottom: 2 },
  suggessionText: { color: color.white, fontWeight: "bold", fontSize: "1vw" },
  suggessionScroll: {
    flex: 1,
    backgroundColor: color.black,
    borderColor: color.lightGray,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: "1vw",
  }
});

export default VendorPayment;
