import React, { Component } from 'react';
import { View, Text, TextInput, Picker, TouchableOpacity, StyleSheet, Dimensions, ScrollView, Image } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from "../../../../styles/Color";
import { CommonHistorySectionHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import ImagePath from '../../../../utils/ImagePaths';

const screenHeight = Dimensions.get("window").height;
const navigationHeader = [
  { label: "Vitals", value: "oTVitals" },
  { label: "Reports", value: "reportsScreen" },
  { label: "Surgery Notes", value: "oTSurgeryNotes" },
  { label: "Billing", value: "oTBilling" }
]

// Time Converter Change 24 hours formate into 12 hours formate ---- Starts
const Time_convert = (Time) => {
  var gettime = Time.split("T")[1]
  var splittime = gettime.split(".")[0]
  var slicetime = splittime.slice(0, 5)
  var time = slicetime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [slicetime];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM';
    time[0] = +time[0] % 12 || 12;
  } else {
    return "Wrong Time Formate"
  }
  return time
}
// Time Converter Change 24 hours formate into 12 hours formate ---- ends

export default class OTVitals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientID: this.props.PatientDetail.patient_id,
      admissionID: this.props.PatientDetail.id,
      VitalsDetailsList: "",
      Date: "",
      Time: "",
      height: "",
      weight: "",
      BMI: "",
      systolicbloodpressure: "",
      diatolicbloodpressure: "",
      temperture: "",
      tempertureType: "f",
      pulseOximetry: "",
      pulserate: "",
      RandomBooldsugar: "",
      VitalsDetailError: "",
      isEdit: false
    };
  }

  componentDidMount() {
    this.getVitalsDetails()
  }

  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }


  // Get Vital Details API Start // 

  getVitalsDetails = () => {
    var url = Constants.Patient_Vitals + "?patient_id=" + this.state.patientID + "&ot_admission_id=" + this.state.admissionID;

    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getVitalsDetailsSuccess,
      this.getVitalsDetailsFailure
    );
  }

  getVitalsDetailsSuccess = (response) => {
    if (response.status === "success") {
      this.clearVitalInputs()
      this.setState({
        VitalsDetailsList: response.data,
      })
    } else {
      console.log("error")
    }
  }

  // Get Vital Details API Ends //

  // Post  Vital Details API Start // 

  PostVitalsDetails = () => {
    var states = this.state
    var url = Constants.Patient_Vitals
    if (this.state.height && this.state.weight) {

      var height = this.state.height / 100;
      var bmi = this.state.weight / (height * height);
      states["BMI"] = bmi.toFixed(2);
    }
    var data =
    {
      "patient_id": states.patientID,
      "ot_admission_id": states.admissionID,
      "height_cm": states.height,
      "weight": states.weight,
      "bmi": states.BMI,
      "blood_pressure_systolic": states.systolicbloodpressure,
      "blood_pressure_diastolic": states.diatolicbloodpressure,
      "temperature": states.temperture,
      "temperature_unit": states.tempertureType,
      "pulse_oximetry": states.pulseOximetry,
      "random_blood_glucose": states.RandomBooldsugar,
      "pulse_rate": states.pulserate
    }

    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.VitalsDetailsPostSuccess,
      this.VitalsDetailsPostFailure
    );
  }

  VitalsDetailsPostSuccess = (response) => {
    this.props.showResposeValue("success", response.message)
    if (response.status === "success") {
      this.getVitalsDetails()
      this.clearVitalInputs()
    } else {
      this.props.showResposeValue("error", response.message)
    }
    this.setState({
      VitalsDetailError: ""
    })
  }

  // Post Vital Details API Ends //

  // Update Vital Details API Starts //

  UpdateVitalsDetails = () => {
    var states = this.state
    var url = Constants.Patient_Vitals
    if (this.state.height && this.state.weight) {

      var height = this.state.height / 100;
      var bmi = this.state.weight / (height * height);
      states["BMI"] = bmi.toFixed(2);
    }
    var data =
    {
      "height_cm": states.height,
      "weight": states.weight,
      "bmi": states.BMI,
      "blood_pressure_systolic": states.systolicbloodpressure,
      "blood_pressure_diastolic": states.diatolicbloodpressure,
      "temperature": states.temperture,
      "temperature_unit": states.tempertureType,
      "pulse_oximetry": states.pulseOximetry,
      "random_blood_glucose": states.RandomBooldsugar,
      "pulse_rate": states.pulserate,
      "ot_admission_id": states.admissionID,
      "patient_id": states.patientID
    }
    OpthamologyService.getInstance().putMethod(
      url,
      data,
      this,
      this.VitalsDetailsSuccess,
      this.VitalsDetailsFailure,
    );
  }

  VitalsDetailsSuccess = (response) => {
    this.props.showResposeValue("success", response.message)
    if (response.status == "success") {
      this.getVitalsDetails()
      this.clearVitalInputs()
      this.setState({ isEdit: false })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }

  // Update Vital Details API Ends //

  addVitals = () => {
    if (this.state.height === "" &&
      this.state.weight === "" &&
      this.state.systolicbloodpressure === "" &&
      this.state.diatolicbloodpressure === "" &&
      this.state.temperture === "" &&
      this.state.pulseOximetry === "" &&
      this.state.pulserate === "" &&
      this.state.RandomBooldsugar === ""
    ) {
      this.setState({
        VitalsDetailError: "Fields Should Not Be Empty"
      })
    } else {
      this.state.isEdit ? this.UpdateVitalsDetails() : this.PostVitalsDetails()
    }
  }

  onPressEditVitals = (data) => {
    this.setState({
      height: data.height_cm,
      weight: data.weight_kg,
      systolicbloodpressure: data.blood_pressure_systolic,
      diatolicbloodpressure: data.blood_pressure_diastolic,
      temperture: data.temperature,
      tempertureType: data.temperature_unit,
      pulseOximetry: data.pulse_oximetry,
      pulserate: data.pulse_rate,
      RandomBooldsugar: data.random_blood_glucose
    })
  }

  clearVitalInputs = () => {
    this.setState({
      height: "",
      weight: "",
      systolicbloodpressure: "",
      diatolicbloodpressure: "",
      temperture: "",
      tempertureType: "f",
      pulseOximetry: "",
      pulserate: "",
      RandomBooldsugar: "",
      VitalsDetailError: ""
    })
  }

  renderTextInput(label, key, stateValue, type) {
    return (
      <View style={{ width: "90%" }}>
        <Text style={styles.TextInputTitle}>{label}</Text>
        <View style={styles.renderTextInputContainer}>
          <View style={[{
            backgroundColor: "transparent",
          }, styles.allButtonBorderRadius]}>
            <TextInput
              value={stateValue}
              onChangeText={(text) => {
                var states = this.state;
                if (this.numberValidation(text)) {
                  states[key] = text;
                  this.setState({ states })
                }
              }}
              style={[styles.renderTextInputStyles]}
            />
            <View style={styles.tempertureInputContainer}>
              <Text style={styles.tempertureStyles}>{type}</Text>
              {key == "temperture" ?
                <Picker
                  style={{
                    marginLeft: "0.32vw", height: "3.90vw", backgroundColor: color.white,
                    marginTop: "0.4vw",
                    borderColor: color.white,
                    borderWidth: 1, fontSize: ".9vw"
                  }}
                  selectedValue={this.state.tempertureType}
                  onValueChange={(itemValue) =>
                    this.setState({ tempertureType: itemValue })}
                >
                  <Picker.Item label="F" value="f" />
                  <Picker.Item label="C" value="c" />
                </Picker> : null}
            </View>
          </View>
        </View>
      </View>
    )
  }

  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"oTVitals"}
          isNavigationBorder={true}
        />
        <View style={styles.vitalsContainer}>
          <ScrollView style={styles.leftContainer}>
            {this.state.VitalsDetailsList.length > 0 ? this.state.VitalsDetailsList.map((item) => (
              <View style={styles.headerContainer}>
                <Text style={styles.VitalsHeading}>Vitals</Text>
                <TouchableOpacity onPress={() => { this.onPressEditVitals(item), this.setState({ isEdit: true }) }} >
                  <Image
                    source={ImagePath.Edit}
                    style={{ height: '2vw', width: '2vw' }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>))
              : null}
            <View style={{ marginVertical: "0.65vw" }}>
              <CommonHistorySectionHeader
                heading1={"Date & Time"}
                heading2={"Height"}
                heading3={"Weight"}
                heading4={"BMI"}
                heading5={"Blood Pressure"}
                columnSize={[0.20, 0.18, 0.18, 0.18, 0.26]}  //total value is == of 1
                noOfColumn={5}
              />
            </View>
            {this.state.VitalsDetailsList.length > 0 ? this.state.VitalsDetailsList.map((item, index) => (
              <View style={styles.TableBodyStyle}>
                <View style={[Style.contentTableDataLead, { flex: 0.20, marginHorizontal: "1.30vw" }]}>
                  <Text style={[Style.contentTableDataLead]}>{item.created_date.split("T")[0]}</Text>
                  <Text style={[Style.contentTableDataLead, { fontSize: ".8vw", marginLeft: ".6vw" }]}>{Time_convert(item.created_date)}</Text>
                </View>
                <Text style={[Style.contentTableDataLead, { flex: 0.18 }]}>{item.height_cm ? item.height_cm : ""}{item.height_cm ? " cm" : ""}</Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.18 }]}>{item.weight_kg ? item.weight_kg : ""}{item.weight_kg ? " kg" : ""}</Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.18 }]}>{item.bmi ? item.bmi : ""}</Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.26, color: item.blood_pressure_systolic <= 120 ? "green" : "red" }]}>{item.blood_pressure_systolic ? item.blood_pressure_systolic + "/" : ""}
                  <span style={{ fontSize: "0.85vw", color: item.blood_pressure_diastolic <= 80 ? "green" : "red" }}>{item.blood_pressure_diastolic ? item.blood_pressure_diastolic : ""}</span>
                  <span>{item.blood_pressure_systolic || item.blood_pressure_diastolic ? " mm/hg" : ""}</span>
                </Text>
              </View>)) :
              <View style={styles.noRecordsDiv}>
                <Text style={styles.noRecordsText}>No Records Found</Text>
              </View>}

            <View style={{ marginVertical: "0.65vw" }}>
              <CommonHistorySectionHeader
                heading1={"Temperature"}
                heading2={"Pulse Oximetry"}
                heading3={"Pulse Rate"}
                heading4={"Random Blood Glucose"}
                columnSize={[0.25, 0.25, 0.25, 0.25]}  //total value is == of 1
                noOfColumn={4}
              />
            </View>

            {this.state.VitalsDetailsList.length > 0 ? this.state.VitalsDetailsList.map((item, index) => (
              <View style={styles.TableBodyStyle}>
                <Text style={[Style.contentTableDataLead, { flex: 0.25, marginHorizontal: "1.30vw", color: (item.temperature_unit == "f" && item.temperature >= 97 && item.temperature <= 99) || (item.temperature_unit == "c" && item.temperature >= 36.1 && item.temperature <= 37.2) ? "green" : "red" }]}>
                  {item.temperature ? item.temperature : ""}{item.temperature && item.temperature_unit ? '\u00b0' : ""}{item.temperature ? item.temperature_unit : ""}
                </Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.25, color: item.pulse_oximetry >= 95 && item.pulse_oximetry <= 100 ? "green" : "red" }]}>
                  {item.pulse_oximetry ? item.pulse_oximetry : ""}{item.pulse_oximetry ? " %" : ""}
                </Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.25, color: item.pulse_rate >= 60 && item.pulse_rate <= 100 ? "green" : "red" }]}>
                  {item.pulse_rate ? item.pulse_rate : ""}{item.pulse_rate ? " /min" : ""}
                </Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.25, color: item.random_blood_glucose <= 200 ? "green" : "red" }]}>
                  {item.random_blood_glucose ? item.random_blood_glucose : ""}{item.random_blood_glucose ? " mg/dl" : ""}
                </Text>
              </View>)) :
              <View style={styles.noRecordsDiv}>
                <Text style={styles.noRecordsText}>No Records Found</Text>
              </View>}
          </ScrollView>

          <ScrollView style={styles.rightContainer}>
            <View style={{ marginBottom: ".65vw" }} >
              <CommonHistorySectionHeader
                heading1={"Height"}
                heading2={"Weight"}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
              />
            </View>
            <View style={styles.rightTextInputContainer}>
              <View style={{ width: '50%' }}>
                {this.renderTextInput("Enter Value", "height", this.state.height, "cm")}
              </View>
              <View style={{ width: "50%" }}>
                {this.renderTextInput(" Enter Value", "weight", this.state.weight, "kg")}
              </View>
            </View>
            <View style={{ marginBottom: ".65vw" }}>
              <CommonHistorySectionHeader
                heading1={"Systolic Blood Pressure"}
                heading2={"Diastolic Blood Pressure"}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
              />
            </View>
            <View style={styles.rightTextInputContainer}>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "systolicbloodpressure", this.state.systolicbloodpressure, "mm/hg")}
              </View>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "diatolicbloodpressure", this.state.diatolicbloodpressure, "mm/hg")}
              </View>
            </View>
            <View style={{ marginBottom: 10 }}>
              <CommonHistorySectionHeader
                heading1={"Temperature"}
                heading2={"Pulse Oximetry"}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
              />
            </View>
            <View style={styles.rightTextInputContainer}>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "temperture", this.state.temperture, "")}
              </View>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "pulseOximetry", this.state.pulseOximetry, "%")}
              </View>
            </View>
            <View >
              <CommonHistorySectionHeader
                heading1={"Pulse Rate"}
                heading2={"Random Blood Glucose"}
                columnSize={[0.5, 0.5]}  //total value is == of 1
                noOfColumn={2}
              />
            </View>
            <View style={[styles.rightTextInputContainer, { marginTop: ".65vw" }]}>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "pulserate", this.state.pulserate, "/min")}
              </View>
              <View style={{ width: "50%" }}>
                {this.renderTextInput("Enter Value", "RandomBooldsugar", this.state.RandomBooldsugar, "mg/dl")}
              </View>
            </View>
            <View style={{ marginLeft: "10vw", height: "1.75vw", marginTop: "-.5vw" }}>
              <Text style={styles.errorText}>{this.state.VitalsDetailError}</Text>
            </View>
            <View style={styles.ButtonContainer}>
              <TouchableOpacity style={styles.AddButtonContainer} onPress={() => { this.addVitals() }}>
                <Text style={[styles.TableBodyText, { color: color.white }]}>Add</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.ClearButtonContainer} onPress={() => { this.clearVitalInputs() }}>
                <Text style={[styles.TableBodyText, { color: color.themeDark }]}>Clear</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  vitalsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: color.white,
    height: screenHeight - 125
  },
  leftContainer: {
    width: "59%",
    paddingHorizontal: "1vw",
    backgroundColor: color.white,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 3,
    height: screenHeight - 135,
    marginHorizontal: ".5vw",
    overflow: "scroll"
  },
  TableBodyStyle: {
    flexDirection: 'row',
    height: "2.6vw",
    justifyContent: "center",
    alignItems: "center"
  },
  rightContainer: {
    width: "39%",
    backgroundColor: "rgb(248, 252, 255)",
    paddingHorizontal: "2vw",
    paddingTop: "2vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 3,
    marginRight: ".5vw",
    height: screenHeight - 135,
    overflow: "scroll"
  },
  rightTextInputContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    marginBottom: "1.62vw"
  },
  TextInputTitle: {
    fontSize: "0.78vw",
    position: "absolute",
    marginLeft: "0.65vw",
    backgroundColor: "white",
    paddingHorizontal: ".32vw",
    color: "#888888"
  },
  renderTextInputContainer: {
    marginTop: ".32vw",
    marginRight: ".32vw",
    zIndex: -1
  },
  renderTextInputStyles: {
    height: "2.27vw",
    width: "7.16vw",
    padding: "0.19vw",
    paddingLeft: "1vw",
    fontSize: ".85vw"
  },
  tempertureInputContainer: {
    height: "1.62vw",
    borderLeftColor: "#888888",
    borderLeftWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    paddingRight: "0.65vw"
  },
  tempertureStyles: {
    fontSize: "0.84vw",
    marginLeft: ".32vw",
    alignContent: "center"
  },
  ButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1vw",
    marginLeft: "-.65vw"
  },
  AddButtonContainer: {
    backgroundColor: color.themeDark,
    borderRadius: ".3vw",
    height: "2vw",
    width: "5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "3vw"
  },
  ClearButtonContainer: {
    borderColor: color.themeDark,
    borderWidth: 1,
    borderRadius: 4,
    height: "2vw",
    width: "5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  TableBodyText: { fontSize: ".9vw" },
  allButtonBorderRadius: {
    borderRadius: ".25vw",
    flexDirection: 'row',
    borderWidth: 0.1,
    borderColor: '#CDD1D5'
  },
  errorText: {
    color: "red",
    fontSize: ".9vw",
    fontWeight: 500
  },
  noRecordsDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noRecordsText: {
    fontSize: "1vw",
    fontWeight: 500,
  },
  VitalsHeading: {
    fontSize: "1.2vw",
    color: color.themeDark,
    marginRight: "1vw"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "2vw",
    marginTop: ".5vw",
    alignItems: "center"
  }
});