import React from "react";
import {
  Text,
  View,
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity

} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

import PaintFundus from "../../sketch/paint_fundus"

const styles = StyleSheet.create({
  gpDataView: {
    marginRight: 30
  }
});

const platform = Platform.OS;
const pageTitle = "Fundus";
const pageName = "fundus";
const isPageName = "isFundus";

export default class Fundus extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      isComplaint: isPageName === this.props.selectedView ? true : false
    };
  }

  componentDidMount() {
setTimeout(() => {
  this.getComplaints();
}, 4000);
  
  }

  componentWillReceiveProps(props) {

    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isComplaint) {

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {

    var service_url = Constants.OPTHALMOLOGY_FUNDUS + "?appointment_id=" + this.state.patientAppointment.appointment_id;
setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getComplaintsSuccess,
    this.getComplaintsFailure
  );
}, 4000);

  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {

      var fundus = response.data.fundus ? response.data.fundus : [];
      var field = this.state;

      
      try{
        
        field["complaintsData"] =   JSON.parse(JSON.stringify(fundus));
        // console.log(" fundus 1 - " + JSON.stringify(fundus));
        this.setState({ field });

        var data=  {
          pageName:"fundus",
          item:JSON.parse(JSON.stringify(fundus))
        }
  
        this.props.fundusEyeDetails(data);
  

      }catch(e){

        field["complaintsData"] = [];
        // console.log(" fundus 2 - " + JSON.stringify(fundus));

        this.setState({ field });
      }
    }
  };

  getComplaintsFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, event) => {
   // console.log("edit fundus");
    this.props.editItem(pageName, item);
  }

  renderComplaintItem = (item, index) => {
    let eye_type = item.eye_type === 'RE' ? "Right Eye (OD)" : item.eye_type === 'LE' ? "Left Eye (OS)" : item.eye_type === 'BE' ? "Both Eye (OU)" : "Not Applicable (NA)";

    return (
      <View>
        <View style={{ flexDirection: 'row', marginTop: 15, alignItems: 'center' }}>
          <Text style={{ fontSize: 16, color: color.notesTableTitle }}>{eye_type}</Text>
          <TouchableOpacity onPress={
            this.editComplaints.bind(this, item)
            //() => { this.props.glassPrescriptionModalClose(true, this.state.selectedResponseData) }
          } style={{ marginLeft: 10 }}>
            {this.renderIdButton("pencil")}
          </TouchableOpacity>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <View style={styles.gpDataView}>
            <Text style={{ marginBottom: 10, fontSize: 13, color: color.notesTableTitle }}>{"Media"}</Text>
            <Text>{item.media}</Text>
          </View>
          <View style={styles.gpDataView}>
            <Text style={{ marginBottom: 10, fontSize: 13, color: color.notesTableTitle }}>{"Optic Disk"}</Text>
            <Text>{item.optic_disc}</Text>
          </View>
          <View style={styles.gpDataView}>
            <Text style={{ marginBottom: 10, fontSize: 13, color: color.notesTableTitle }}>{"C/D Ratio"}</Text>
            <Text>{item.cd_ratio}</Text>
          </View>
          <View style={styles.gpDataView}>
            <Text style={{ marginBottom: 10, fontSize: 13, color: color.notesTableTitle }}>{"Macula"}</Text>
            <Text>{item.macula}</Text>
          </View>
        </View>
      </View>
    );
  };

  triggerNewItem = (key) => {

    this.props.triggerNewItem(key);
  }
  showResposeValue(type,meaasge){
    this.props.showResposeValue(type,meaasge)
  }
  render() {
    return (
      <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

        <View style={{ flexDirection: "row", justifyContent: "center", minWidth: 104  }}>
          <PaintFundus patientAppointment={this.props.patientAppointment} showResposeValue={this.showResposeValue.bind(this)} />
        </View>

        <FlatList
          data={this.state.complaintsData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderComplaintItem(item, index)
          }
        />

        {/* {this.renderSubHeader("Right Eye (OD)", Constants.dummyFundus, true)}
        {this.renderSubHeader("Left Eye (OS)", Constants.dummyFundus, true)} */}
        
      </View>
    );
  }
}