//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, ActivityIndicator } from 'react-native';
import { Tooltip } from 'antd';
const screenHeight = Dimensions.get("window").height;
import moment from "moment";

import Style from "../../../styles/Style";
import { color } from '../../../styles/Color'
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../BaseComponent'
import  CommonDateFilter  from '../BaseComponent'

import { Calender } from '../common/calender';
import Pagination from '../../doctor/common/Pagination';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import {  } from 'react-native-gesture-handler';
// create a component
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export class IPPatientCheckoutList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ipPatientCheckoutList: [],
      ipPatientCheckoutListPaginationValue: [],

      name: "",
      age: "",

      searchPatientData: [],

      patientSearchTextValue: "",
      
      filterDate: {
        fromDate: converted_date,
        toDate: converted_date
      },
      dateLabel:"Today",
      pageLoading:true

    }
  }

  componentDidMount() {

    var today = new Date();
    var date = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();

    var state = this.state.filterDate;
    state["fromDate"]= date;
    state["toDate"]= date;
    this.setState({
        state
    })


    this.props.ipCheckoutPatientExportFilters(this.state.patientSearchTextValue,date, date)


    this.searchIPCheckoutPatientList("",date,date);
    // this.getPatientList()
  }
//   onPressButton(key, value) {
//     if (key) {
//       this.props.changeScreen(key, {}, "", {}, "", {})
//     }
//   }

//   renderButton(label, buttonKey) {
//     var item = { label: label, value: label }
//     return (
//       <View style={{ marginLeft: 15 }}>
//         <CommonButton
//           item={item}
//           selectedvalue={{}}
//           butttonText={item.label}
//           buttonType={"theme"}
//           buttonIcon={require("../../../../assets/images/PlusIcon.png")}
//           rightIcon={true}
//           buttonAction={this.onPressButton.bind(this)}
//           buttonKey={buttonKey} />
//       </View>
//     )
//   }

  componentWillReceiveProps(nextProps) {
    if (nextProps && (nextProps.patientSearchTextValue !== this.state.patientSearchTextValue)) {
      this.setState({
        patientSearchTextValue: nextProps.patientSearchTextValue
      }, () => {
        this.searchIPCheckoutPatientList(nextProps.patientSearchTextValue,this.state.filterDate.fromDate,this.state.filterDate.toDate);
        this.props.ipCheckoutPatientExportFilters(this.state.patientSearchTextValue,this.state.filterDate.fromDate, this.state.filterDate.toDate)
      })

    } else {
      // this.state.searchPatientData=[]
    }
  }


  showSelectedFilteredDate(date) {
    // alert(JSON.stringify(date))
    var states = this.state.filterDate;
    states["filterDate"] = date
    states["fromDate"] = date.fromDate;
    states["toDate"] = date.toDate;
    this.setState({ states })
    this.props.ipCheckoutPatientExportFilters(this.state.patientSearchTextValue,date.fromDate, date.toDate)

    this.searchIPCheckoutPatientList(this.state.patientSearchTextValue,date.fromDate, date.toDate)
    // this.getPatientList(date.fromDate, date.toDate)
  }

  renderHeader() {
    return (
      <View style={[styles.titleContainer]}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontWeight: "bold", fontSize: "1.3vw", marginRight: "1.2vw" }}>{"Checked Out Patients List"}</Text>
          {/* {this.renderButton("New Admission", "newAdmission")} */}
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <CommonDateFilter showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} /> */}
          <CommonDateFilter 
                startDate={this.state.filterDate.fromDate} 
                endDate={this.state.filterDate.toDate} 
                filterDate={this.state.filterDate}
                defaultSelectedDateFilter={"Today"} 
                showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
                removeObject={"all"}
                />

          {/* <Calender getSelectedDate={this.getSelectedDate.bind(this)} /> */}
          {/* {this.renderButton("New Patient", "demographics")} */}
        </View>
      </View>
    )
  }

  renderIpPatientList(item, index) {
    return (
      <TouchableOpacity style={{ zIndex: -1 }} onPress={() => {
        this.props.changeScreen("optDoctorNotes", {}, "", {}, "", item,{},false,"",{},false,false,item.bill_settlement === "Completed" ? true : false)
      }}>
        <View style={{paddingLeft: "1.7vw", paddingRight: "1.3vw"}}>
        <View style={{ flexDirection: 'row', alignItems: 'center', borderRadius: 4, paddingVertical: ".52vw", borderBottomColor: "#888888", borderBottomWidth: 1 }}>
          <View style={{ flex: 0.13, paddingLeft: "1.2vw", flexDirection: 'row', alignItems: 'center'}}>
            <Image style={{ height: "2.27vw", width: "2.27vw", paddingRight: ".8vw", borderRadius: "3vw", borderWidth: 1, borderColor: color.lightGray, marginRight:'.4vw' }} source={item.photo_url ? { uri: item.photo_url } : require('../../../../assets/images/sample_profile.png')} />
            <View>
              {
                item && item.patient_name ?
                  <Tooltip placement="topLeft" title={item.patient_name}>
                    <Text style={{ fontSize: "1vw", fontWeight: '500' }}>{item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}</Text>
                  </Tooltip> :null        
              }

              <Text style={{ fontSize: ".8vw" }}>{item.patient_account_number}</Text>
            </View>
          </View>
          <View style={{ flex: 0.06 }}><Text style={styles.patientListFontStyle}>{item.patient_age}</Text></View>
          <View style={{ flex: 0.07 }}><Text style={styles.patientListFontStyle}>{item.patient_gender}</Text></View>
          <View style={{ flex: 0.08 }}><Text style={styles.patientListFontStyle}>{item.patient_mobile_number}</Text></View>
          <View style={{ flex: 0.08 }}><Text style={styles.patientListFontStyle}>{item.patient_tags}</Text></View>
          <View style={{ flex: 0.12 }}><Text style={styles.patientListFontStyle}>{item.visiting_consultant}</Text></View>
          <View style={{ flex: 0.10 }}><Text style={styles.patientListFontStyle}>{item.complaints}</Text></View>
          <View style={{ flex: 0.10 }}><Text style={styles.patientListFontStyle}>{item.admission_date}</Text></View>
          <View style={{ flex: 0.10 }}><Text style={styles.patientListFontStyle}>{item.discharge_date}</Text></View>
          <View style={{ flex: 0.16, flexDirection: 'row' }}>
          {item.bill_settlement != "Completed" ? this.renderActionIcons(require("../../../../assets/images/IPActions/Create_Ip_Billing.png"), "ipPatientCreateBill", "Create Bill", item, "ipallTransactions") :
            this.renderActionIcons(require("../../../../assets/images/IPActions/Create_Ip_Billing.png"), "ipPatientCreateBill", "Billing Transaction", item, "ipallTransactions")}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Room_Change.png"), "admissionDetails", "Room Change", item)}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Insurance.png"), "insurance", "Insurance", item)}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/DischargeSummary .png"), "dischargeSummary", "Notes", item)}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Panient_Check.png"), "patientCheckout", "Patient Checkout", item)}
            {item.bill_settlement != "Completed" ? 
            this.renderActionIcons(require("../../../../assets/images/IPActions/rupees completed.svg"), "completePayment", "Complete Payment", item)
            : null
            }

            {/* <Text style={styles.patientListFontStyle}>{""}</Text> */}
          </View>
        </View>
        </View>
      </TouchableOpacity>
    )
  }

  renderIpPatientCheckoutListHeader() {
    return (
      <View style={{paddingLeft: "1.7vw", paddingRight: "1.3vw"}}>
      <View style={{ zIndex: -1, marginTop: ".8vw", marginBottom: ".5vw", backgroundColor: color.appointmentTableHead, height: "2.6vw", flexDirection: 'row', alignItems: 'center', borderRadius: ".3vw" }}>
        <View style={{ flex: 0.13, paddingLeft: "1.2vw" }}><Text style={[styles.headerFontStyle,{marginLeft:'1vw'}]}>{"Patient's Details"}</Text></View>
        <View style={{ flex: 0.06 }}><Text style={styles.headerFontStyle}>{"Age"}</Text></View>
        <View style={{ flex: 0.07 }}><Text style={styles.headerFontStyle}>{"Gender"}</Text></View>
        <View style={{ flex: 0.08 }}><Text style={styles.headerFontStyle}>{"Mobile No"}</Text></View>
        <View style={{ flex: 0.08 }}><Text style={styles.headerFontStyle}>{"Patient Tag"}</Text></View>
        <View style={{ flex: 0.12 }}><Text style={styles.headerFontStyle}>{"Visiting Consultant"}</Text></View>
        <View style={{ flex: 0.10 }}><Text style={styles.headerFontStyle}>{"Complaints"}</Text></View>
        <View style={{ flex: 0.10 }}><Text style={styles.headerFontStyle}>{"Admission Date"}</Text></View>
        <View style={{ flex: 0.10 }}><Text style={styles.headerFontStyle}>{"Discharge Date"}</Text></View>
        <View style={{ flex: 0.16 }}><Text style={[styles.headerFontStyle, { textAlign: 'center' }]}>{"Action"}</Text></View>
      </View>
      </View>
    )
  }


  getPatientInfo(key, subscreen, item, flag) {
    var subDatas={
      key:key,
      subscreen:subscreen,
      item:item,
      flag:flag
    }
    var serviceUrl = "";

    serviceUrl = Constants.FO_PATIENT_INFO + item.patient_id + "/?appointment_id=" + item.appointment_id + "&doctor_id=" + item.doctor_id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure,
      subDatas
    );
  };

  getComplaintsSuccess = (response,subDatas) => {
    if (response.status === "success") {
      // console.log("Success:", response )
      // console.log("subDatas:", subDatas )
      var field = this.state;
      field["patientInfo"] = response.data;
        this.props.changeScreen(subDatas.key, {}, "", {}, subDatas.subscreen, subDatas.item, "", subDatas.flag,"",{},false,false,subDatas.item.bill_settlement === "Completed" ? true : false)

        this.props.changeScreen("optDoctorNotes", {}, "", {}, "", item,{},false,"",{},false,false,true)

      this.props.changeStateValues(response.data, "", "", "")
      this.setState({ field });

    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  }

  paymentComplete = (item) => {

    var serviceUrl = "";

    serviceUrl = Constants.DOMAIN + "ip/checkout/bill/status/"

    var data = {
      "ip_admission_id": item.id,
      "patient_id": item.patient_id,
      "doctor_id": item.doctor_id,
      "bill_settlement": "Completed"
    }

    OpthamologyService.getInstance().postComplaints(
      serviceUrl,
      data,
      this,
      this.paymentCompleteSuccess,
      this.paymentCompleteFailure,
    );
  }

  paymentCompleteSuccess = (response) => {
    // console.log("payment-success-res:", response)
    this.props.changeScreen("iPPatientList")
    // var today = new Date();
    // var date = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();

    // var state = this.state;
    // state["fromDate"]= "";
    // state["toDate"]= "";
    // state["filterDate"] = {}
    // this.setState({
    //     state
    // })

    // this.props.ipCheckoutPatientExportFilters(this.state.patientSearchTextValue,date, date)

    // this.searchIPCheckoutPatientList("",date,date);

    
  }

  paymentCompleteFailure = (response) => {
    // console.log("payment failed-error:", response)
  }

  renderActionIcons(image, key, tooltip, item, subscreen) {
    return (

      <TouchableOpacity
        onPress={() => {
          if (key != "completePayment") {
            this.getPatientInfo(key, subscreen, item, true)
            // this.props.changeScreen(key, {}, "", {}, subscreen, item, "", true)

          }
          if (key === "completePayment") {
            this.paymentComplete(item)
            // this.getPatientInfo(key, subscreen, item, true)
            // this.props.changeScreen(key, {}, "", {}, subscreen, item, "", true)

          }
        }} style={{
          padding: ".3vw", borderRadius: "3vw", height: "2.25vw", width: "2.25vw", backgroundColor: color.white, shadowOffset: { width: ".1vw", height: ".1vw" }, shadowOpacity: 0.4, shadowRadius: 3,
          elevation: 5,
        }}>
        <Tooltip placement="top" title={tooltip}>
          <Image source={image} style={[
            {
              height: "1.5vw", width: "1.5vw", tintColor: color.black
            }]} />
        </Tooltip>
      </TouchableOpacity>
    )
  }

  paginationChangedValues(data) {
    // alert(JSON.stringify(data))
    this.setState({
      ipPatientCheckoutListPaginationValue: data
    })
  }

//   getPatientList(fromDate = "", toDate = "") {
//     var service_url = Constants.IP_PATIENT_LIST_GET +
//       "?from_date=" + fromDate +
//       "&to_date=" + toDate
//       ;

//     OpthamologyService.getInstance().getComplaints(
//       service_url,
//       this,
//       this.getPatientListSuccess,
//       this.getPatientListFailure
//     );
//   }

//   getPatientListSuccess = success => {
//     // console.log("success -------->" + JSON.stringify(success))
//     if (success.status == "success") {
//       this.setState({
//         ipPatientCheckoutList: success.data
//       })
//     }
//   }
//   getPatientListFailure = error => { }


//   renderIpPatientCheckoutSearchList() {
//     return (

//       <View style={{ zIndex: 2 }}>
//         {
//           this.state.searchPatientData && this.state.searchPatientData.length > 0 ?

//             <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: 'flex-start', paddingLeft: 20, paddingBottom: 30 }}>

//               <View style={{ width: "45%", backgroundColor: 'white', borderBottomRightRadius: 10, borderBottomLeftRadius: 10, paddingBottom: 10 }}>
//                 <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.5 }}>
//                   {
//                     this.state.searchPatientData.map((item, index) => {
//                       return (
//                         <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, borderBottomColor: "#E4E8F1", borderBottomWidth: 1 }}>
//                           <View style={{ flexDirection: 'row', alignItems: 'center' }}>
//                             <Image source={item.photo_url ? item.photo_url : require('../../../../assets/images/sample_profile.png')}
//                               style={Style.appointmentTableRowImg}
//                               resizeMode='stretch' />
//                             <Text style={{ color: 'black', marginLeft: 10 }} >{item.patient_name}</Text>
//                             <Text style={{ color: '#ADB4C1', marginLeft: 10 }}> {item.mobile_number}</Text>
//                           </View>
//                           {
//                             item.admission_date && item.doctor_id ?

//                               <TouchableOpacity style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", }}>
//                                 <Text style={{ color: "black", fontSize: 13, fontWeight: "500" }}>{item.admission_date}</Text>
//                               </TouchableOpacity> :
//                               <TouchableOpacity onPress={() => {
//                                 this.props.changeScreen("demographics", {}, "", {}, "", item)
//                               }} style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", backgroundColor: color.themeDark, height: 25, width: 25, borderRadius: 50 }}>
//                                 <Text style={{ color: "white", fontSize: 20, fontWeight: "500" }}>+</Text>
//                               </TouchableOpacity>
//                           }

//                         </View>
//                       );
//                     })

//                   }
//                 </ScrollView>
//               </View>

//             </View>
//             : null
//         }
//       </View>
//     )
//   }

  searchIPCheckoutPatientList = (query,fromDate,toDate) => {

    // this.setState({ searchBar: query });

    // if (query !== "") {
      var serviceUrl =
        Constants.IP_PATIENT_CHECKOUT_LIST +
        "?search_key=" + query +
        "&from_date=" + fromDate +
        "&to_date="+toDate;

      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.searchIPCheckoutPatientListSuccess,
        this.searchIPCheckoutPatientListFailure
      );
    // } else {
    //   var fields = this.state;
    //   fields["searchPatientData"] = [];

    //   this.setState({
    //     fields
    //   });
    // }
  };

  searchIPCheckoutPatientListSuccess = response => {


    if (response.status == "success") {
        this.setState({
          ipPatientCheckoutList: response.data,
          pageLoading:false
        })
      }

  };

  searchIPCheckoutPatientListFailure = error => {
    // console.log("search patient error response");
    console.log(error);
  };



  render() {
    // console.log("Filterdate",this.state.filterDate)
    return (
      <View style={{ flex: 1 }}>
        {/* {this.renderIpPatientCheckoutSearchList()} */}
        <ScrollView style={{ height: screenHeight - 65 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          {this.renderHeader()}
          {this.renderIpPatientCheckoutListHeader()}
          {
            this.state.ipPatientCheckoutList && this.state.ipPatientCheckoutList.length > 0 && this.state.ipPatientCheckoutListPaginationValue && this.state.ipPatientCheckoutListPaginationValue.length > 0 ?

              this.state.ipPatientCheckoutListPaginationValue.map((item, index) => {
                return this.renderIpPatientList(item, index)
              })

              :
              <View style={[styles.loaderStyle]}>
               { this.state.pageLoading ? 
                 <ActivityIndicator size="large" color={color.themeDark} /> : <Text style={{ fontSize: "1.3vw" }}>{"No records to be shown"}</Text>
               }
            </View>
          }

          {/* {this.renderIpPatientList()} */}
          {
            this.state.ipPatientCheckoutList && this.state.ipPatientCheckoutList.length > 0 ?
              <View style={{paddingLeft: "1.7vw", paddingRight: "1.3vw"}}>
                <Pagination recordsPerPage={30} paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.ipPatientCheckoutList} />
              </View>
              : null
          }

        </ScrollView>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  titleContainer:{
    width: "100%",
    height:"3.5vw",
    boxShadow: "0px 3px 6px #00000029",
    borderBottomEndRadius: "15vw",
    borderBottomLeftRadius: "15vw",
    flexDirection: 'row',
    alignItems:"center",
    justifyContent:'space-between',
    paddingHorizontal:'3vw',
    zIndex: 1
  },
  headerFontStyle: {
    fontSize: "0.8vw",
    color: "black",
    fontWeight: '500'
  },
  patientListFontStyle: {
    fontSize: ".75vw",
    color: "black"
  },
  container: {
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: '#2c3e50',
  },
  loaderStyle:{
    flex: 1, 
    marginTop:"15vw", 
    justifyContent: 'center', 
    alignContent: 'center', 
    alignSelf: 'center' 
  }
});

//make this component available to the app
