//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton,CommonAddButton ,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component
const pageName="isNuturitionHistory"

export class NutritionHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {},
            hideEditIcon:true
        }
    }

    componentDidMount() {
        this.getNutrition()
    }

    getNutrition() {
        var service_url = Constants.GET_NUTRITION + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getNutritionSuccess,
            this.getNutritionFailure
        );
    }


    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    getNutritionSuccess = success => {
       // console.log("success" + JSON.stringify(success))
        if (success.status == "success") {

            var data = success.data

            var booleanValues = []
            var stringVaues = []
            var  hideEditIcon = true
            Object.keys(data).forEach((key) => {
                if(data[key]){
                    hideEditIcon = false
                }
                if (typeof data[key] == "boolean") {
                    let prepareData = {}
                    if (key == "nutritional_counselling") {
                        prepareData = {
                            key: "Have you ever had Nutritional counselling?",
                            value: data[key] ? "Yes" : "No",
                            details: data.nutrition_counselling_date
                        }
                    } 
                    // else if (key == "blood_glucose_check") {
                    //     prepareData = {
                    //         key: "Do you check your blood glucose?",
                    //         value: data[key] ? "Yes" : "NO",
                    //         details: data.bg_check_frequency
                    //     }
                    // } else if (key == "low_blood_glucose") {
                    //     prepareData = {
                    //         key: "Do you get low blood glucoes?",
                    //         value: data[key] ? "Yes" : "NO",
                    //         details: data.low_bg_date_time
                    //     }
                    // }

                    booleanValues.push(prepareData)
                } else {
                    let prepareData = {}
                    var dataName=""
                    if (key == "nutrition_type") {
                        if(data[key]==1){
                            dataName="Vegetarian"  
                        }else if(data[key]==2){
                            dataName="Vegan"  
                        } else if(data[key]==3){
                            dataName="Eggetarian"  
                        }else if(data[key]==4){
                            dataName="Non Vegetarian"  
                        }else if(data[key]==5){
                            dataName="Mixed"  
                        }
                        prepareData = {
                            key: "Type of Nutrition",
                            value: dataName,
                        }
                    } else if (key == "food_early_morning_amount") {
                        prepareData = {
                            key: "Early Morning",
                            value: data[key],
                        }
                    } else if (key == "food_breakfast_amount") {
                        prepareData = {
                            key: "Breakfast",
                            value: data[key],
                        }
                    } else if (key == "food_mid_morning_amount") {
                        prepareData = {
                            key: "Mid Morning",
                            value: data[key],
                        }
                    } else if (key == "food_lunch_amount") {
                        prepareData = {
                            key: "Lunch",
                            value: data[key],
                        }
                    } else if (key == "food_evening_amount") {
                        prepareData = {
                            key: "Evening",
                            value: data[key],
                        }
                    } else if (key == "food_dinner_amount") {
                        prepareData = {
                            key: "Dinner",
                            value: data[key],
                        }
                    } else if (key == "food_late_night_amount") {
                        prepareData = {
                            key: "Late Night",
                            value: data[key],
                        }
                    } else if (key == "water_consumption") {
                        prepareData = {
                            key: "Water Consumption",
                            value:data[key]? data[key]+" Litres":"",
                        }
                    }   
                    stringVaues.push(prepareData)
                }
            })



            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

          //  console.log("success _________> " + JSON.stringify(removeEmptyObj))
            this.setState({
                hideEditIcon:hideEditIcon,
                getListofDiabeticHistory: data,
                listOfDatas: removeEmptyObj
            })


        }
    }
    getNutritionFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getNutrition();
            this.props.refreshRighSideComponent("");
        }
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // setTimeout(() => {
        //     this.editHistory()
        // }, 100);
    }
    editHistory() {
        this.props.selectedRightSideView(pageName);
    setTimeout(() => {
        this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
    }, 100);
    }

 

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Nutrition"}
                    editImage={this.state.hideEditIcon ? "" :"pencil"}
                />

                {this.renderContent()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});


//make this component available to the app
