import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  // ScrollView,
  CheckBox,

  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton, DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'
import Styles from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "iop";

export default class IOPNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      selectedNCT: null,
      selectedApplanation: null,
      selectedCorrection: null,
      selectedCCT: null,
      selectedCIOP: null,
      comments: "",
      show: false,
      errorShow: false,
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,


      measureCIOP: 0,

      copyRightToLeft: false,
      copyLeftToRight: false,
      getAvailableEyeTypes: [],
      getAvailableEyeTypesId: []
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
  }

  componentWillReceiveProps(props) {
    // console.log(JSON.stringify(props.getAvailableEyeTypes))
    this.state.getAvailableEyeTypes = props.getAvailableEyeTypes;
    this.state.getAvailableEyeTypesId = props.getAvailableEyeTypesId
    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
        let comments = props.editItem.comments
        if (id !== item.id) {

          var eye_type = { label: item.eye_type, value: item.eye_type }

          this.setState({
            id: item.id,
            selectedEye: eye_type,
            // selectedNCT: item['NCT'],
            // selectedApplanation: item['APPLANATION_TONOMETRY'],
            // selectedCCT: item['CCT'],
            // selectedCorrection: item['CORRECTION_FACTOR'],
            // selectedCIOP: item['CIOP']

            selectedNCT: item.NCT,
            selectedApplanation: item.APPLANATION_TONOMETRY,
            selectedCCT: item.CCT,
            selectedCorrection: item.CORRECTION_FACTOR,
            selectedCIOP: item.CIOP,
            comments: comments
          }, () => {
            this.CIOPCalculation(item.CCT);
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }


  addComments = (comment) => {

    let { comments, patientAppointment } = this.state;
    var service_url = Constants.OPTHALMOLOGT_COMMENTS;
    var data = {
      "patient_id": patientAppointment.patient_id,
      "appointment_id": patientAppointment.appointment_id,
      "section": "Iop",
      "comments": comments
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addCommentsSuccess,
      this.addCommentsFailure
    );
  };
  addCommentsSuccess = success => {
    this.clearComplaint();
    this.props.clearEditedItem(pageName, {})
    // alert(JSON.stringify(success))
  }
  addCommentsFailure = error => { }

  addComplaint(eyeType, id) {
    
    let errorMessage = "";
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_IOP;
    
    let selectedNCT = parseFloat(states.selectedNCT) 
    let selectedApplanation = parseFloat(states.selectedApplanation)
    let selectedCCT = parseFloat(states.selectedCCT)
    let selectedCIOP= parseFloat(states.selectedCIOP)
    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "eye_type": eyeType,
      "NCT": selectedNCT ? selectedNCT.toFixed() : null,
      "APPLANATION_TONOMETRY":selectedApplanation ? selectedApplanation.toFixed() : null,
      "CCT": selectedCCT ? selectedCCT.toFixed() :null,
      "CORRECTION_FACTOR": (states.selectedCorrection) ? parseFloat(states.selectedCorrection).toFixed() : null,
      "CIOP": !isNaN(selectedCIOP) ? selectedCIOP.toFixed() : null  
    };


    if (id) {
      data["id"] = id;
    }

    if(!eyeType) {    

      // this.props.showResposeValue("error", "Select Eye Type")
      errorMessage = "Select Eye Type";
    } 
    // else if(!states.selectedNCT) {    

    //   errorMessage = "Enter NCT value";
    // }
    // console.log(states.selectedApplanation +"&&"+ states.selectedCorrection)
    // else if(!states.selectedApplanation) {    

    //   errorMessage = "Enter Applanation Tonometry value";
    // }
    // else if(!states.selectedCCT) {    

    //   errorMessage = "Enter CCT value";
    // } 
    if(errorMessage)
    {
      this.props.showResposeValue("error", errorMessage);
    }else if(selectedNCT && selectedNCT<0 && selectedNCT>70){
    //  alert("1")
     this.props.showResposeValue("error","Please enter the value range 0 to 70");
    }else if((selectedApplanation && selectedApplanation <0 && selectedApplanation>80)){
      this.props.showResposeValue("error","Please enter the value range 0 to 80");

    }else if((selectedCCT && selectedCCT <0 && selectedCCT >800)){
      this.props.showResposeValue("error","Please enter the value range 0 to 800");

    }else{

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }
  };

  ShowSuccessMessage = () => {
    // successOrErrorMessage:false,
    // successMessageFlag:false,
    // errorMessageFlag:false
    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };


  getComplaintsSuccess = response => {
    // alert("complaint created successfully")
    ///alert(JSON.stringify(response))
    if (response.status === "success") {
      var getAvailableEyeTypesId = this.state.getAvailableEyeTypesId;

      if (this.state.selectedEye.value == "RE" && this.state.copyRightToLeft) {
        if (getAvailableEyeTypesId.length > 0) {

          for (var i = 0; i < getAvailableEyeTypesId.length; i++) {
            if (getAvailableEyeTypesId[i].eye_type == "LE") {

              this.setState({
                copyRightToLeft: false
              }, () => {
                this.addComplaint("LE", getAvailableEyeTypesId[i].id)
              })
            } else {
              this.setState({
                copyRightToLeft: false
              }, () => {
                this.addComplaint("LE", "")
              })

              // this.setState({
              //   copyRightToLeft: false
              // }, () => {
              //   this.addComplaint("LE", "")
              // })
            }
          }
        } else {
          this.setState({
            copyRightToLeft: false
          }, () => {
            this.addComplaint("LE", "")
          })
        }

      } else if (this.state.selectedEye.value == "LE" && this.state.copyLeftToRight) {

        if (getAvailableEyeTypesId.length > 0) {
          for (var i = 0; i < getAvailableEyeTypesId.length; i++) {
            if (getAvailableEyeTypesId[i].eye_type == "RE") {
              this.setState({
                copyLeftToRight: false
              }, () => {
                this.addComplaint("RE", getAvailableEyeTypesId[i].id)
              })
            } else {
              this.setState({
                copyLeftToRight: false
              }, () => {
                this.addComplaint("RE", "")
              })

            }
          }
        } else {
          this.setState({
            copyLeftToRight: false
          }, () => {
            this.addComplaint("RE", "")
          })
        }

      } else {
        var field = this.state;
        field["complaintsData"] = response.data;
        this.setState({ field });
        this.addComments(this.state.comments)

        // clear data

        // reload data
        this.props.refreshData(pageName);
        this.props.showResposeValue("success", response.message)
      }
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getComplaintsFailure = error => {
    // this.showErrorMessage();
    //alert("complaint created unsuccessfully")
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = {};
    states['selectedNCT'] = null;
    states['selectedApplanation'] = null;
    states['selectedCCT'] = null;
    states['selectedCorrection'] = null;
    states['selectedCIOP'] = null;
    states['id'] = "";
    states['comments'] = "";

    this.setState({ states }, () => {
      this.props.clearEditedItem(pageName, {})
    });

  }

  updateState = (key, value) => {

    let states = this.state;

    states[key] = value;

    this.setState({ states });

  }

  renderTextBox(label, key, value) {
    return (
      <View style={styles.TextBoxView}>
        <View >
          <Text style={{ fontSize: '1.1vw', color: color.black }}>{label}</Text>
        </View>
        <View >
          <TextInput
            underlineColorAndroid="transparent"
            editable={(key == "selectedCIOP" || this.state.isLocked) ? false : true}
            style={[styles.TextInputView, {
              backgroundColor: (key == "selectedCIOP" || this.state.isLocked) ? "#E8E8E8" : "white",
              borderColor: color.rightPanelInputBorder
            },Styles.allButtonBorderRadius]}
            keyboardType="numeric"
            // ref={text => (this.nameInput = text)}
            // ref={reference ? reference : null}
            // defaultValue=""
            // autoFocus={autofocus}
            autoCapitalize="none"
            value={value}
            onChangeText={(text) => {
              this.updateStateValues(text, key)
            }}
          // multiline={isMultipleLineEnabeled}
          // numberOfLines={lineCount}
          />
        </View>
      </View>
    )
  }

  CIOPCalculation() {

    let convertNumber = parseFloat(this.state.selectedCCT) || 0;
    let selectedApplanation = parseFloat(this.state.selectedApplanation) || 0;

    if( convertNumber == 0 || selectedApplanation == 0 ){

      this.setState({
        selectedCIOP: ""
      })
    } else if (convertNumber == 545) {
      this.setState({
        selectedCCT: convertNumber,
        selectedCorrection: 545,
        selectedCIOP: 0
      })
    } else if (convertNumber >0) {

      var getCorrection = ((convertNumber - 545) / 50 * 2.5);
      var getCIOP = parseInt(selectedApplanation - getCorrection)

      this.setState({
        selectedCCT: convertNumber,
        selectedCorrection: getCorrection,
        selectedCIOP: getCIOP
      })
    }
    /*
    } else if (convertNumber > 545) {
      var getCorrection = ((convertNumber - 545) / 50 * 2.5);
      // var getCorrection = (selectedApplanation) - ((convertNumber - 545) / 50 * 2.5);
      var getCIOP = parseInt(selectedApplanation - getCorrection)

      this.setState({
        selectedCCT: convertNumber,
        selectedCorrection: getCorrection,
        selectedCIOP: getCIOP
      })
    } else if (convertNumber > 0 && convertNumber < 545) {
      
      var getCorrection = ((convertNumber - 545) / 50 * 2.5);
      var getCIOP = parseInt(selectedApplanation + getCorrection)
      
      this.setState({
        selectedCCT: convertNumber,
        selectedCorrection: getCorrection,
        selectedCIOP: getCIOP
      })
    }
    */
  }

  updateStateValues(number, key) {
    var states = this.state;

    // var convertNumber = parseInt(number);
    var convertNumber = number;

    if (convertNumber.length < 4) {

      // if(key == "selectedCCT" || key == "selectedApplanation"){
      if (key == "selectedCCT" || key == "selectedApplanation") {

        // if(convertNumber == 545){
        //   this.setState({
        //     selectedCCT:convertNumber,
        //     selectedCorrection:545,
        //     selectedCIOP:0
        //   })
        // }else if(convertNumber > 545){
        //   var getCorrection = (this.state.selectedApplanation) - ((convertNumber - 545) / 50 * 2.5);
        //   var getCIOP = Math.round(this.state.selectedApplanation - getCorrection)

        //   this.setState({
        //     selectedCCT:convertNumber,
        //     selectedCorrection:getCorrection,
        //     selectedCIOP:getCIOP
        //   })
        // }else if(convertNumber < 545){
        //   var getCorrection = ((convertNumber - 545) / 50 * 2.5);
        //   var getCIOP = Math.round(this.state.selectedApplanation + getCorrection)

        //   this.setState({
        //     selectedCCT:convertNumber,
        //     selectedCorrection:getCorrection,
        //     selectedCIOP:getCIOP
        //   })
        // }

        states[key] = convertNumber;
        this.setState({ states }, () => {
          this.CIOPCalculation()
        })

      } else {
        states[key] = convertNumber;
        this.setState({ states })
      }
    }


  }

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye") {
      this.setState({
        selectedEye: selectedvalue
      })

    }
  }

  renderCheckBox(label, value, key) {
    return (
      <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
        <CheckBox value={value}
          style={styles.CheckBoxView}
          onValueChange={() => {

            var states = this.state;

            states[key] = !value

            this.setState({
              states
            })
          }}

        />
        <Text style={styles.CheckBoxText}>{label}</Text>
      </View>
    );
  }

  render() {
    return (
      <View>
        {/* {this.renderSubHeadingBorder("IOP", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Intraocular Pressure"} clearAllData={this.clearComplaint.bind(this)} />

        {/* {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true)} */}
        <CommonSectionHeader heading={"Eye Type"}  />
        <View style={{}}>
          <View style={{ flex: 0.5 }}>
            {this.renderSubHeadingBorder("", false, true)}
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginLeft: '1.2vw', }}>
            {

              this.state.getAvailableEyeTypes.length > 0 ?

                // this.state.getAvailableEyeTypes.map((eyeType,index)=>{

                Constants.eye_type.map((item, index) => {

                  var tempData = false

                  var result = this.state.getAvailableEyeTypes && this.state.getAvailableEyeTypes.map((eye, index) => {
                    if (eye == item.value) {
                      tempData = true
                      return true
                    } else {
                      return false
                    }
                  })

                  // var disabled = false;
                  // if(this.state.getAvailableEyeType.every((item) => item == item.value)){
                  //   disabled = true
                  //  }

                  // if(eyeType == item.value){
                  //   return <View/>
                  // }else{
                  // const found = item.some(el => el.value == eyeType);
                  return (
                    <View style={styles.CommonBtnView} key={index}>
                      <CommonButton
                        disable={(tempData || this.state.isLocked) ? true :false}
                        // disable={found ? true : false}
                        item={item}
                        selectedvalue={this.state.selectedEye}
                        butttonText={item.label}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"selectedEye"} />
                    </View>
                  )
                  // }
                })

                // )
                // })

                :

                Constants.eye_type.map((item, index) => {
                  return (
                    <View style={styles.CommonBtnView} key={index}>
                      <CommonButton
                        disable={this.state.isLocked}
                        item={item}
                        selectedvalue={this.state.selectedEye}
                        butttonText={item.label}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"selectedEye"} />
                    </View>
                  )
                })
            }
          </View>
        </View>

        {/* {this.renderTitleWithMultipleBtn("NCT", Constants.NCT_OPTIONS, 6, false, 'selectedNCT', (this.state.selectedNCT), this.updateState.bind(this), false, true)}
        {this.renderTitleWithMultipleBtn("Applanation Tonometry", Constants.APPLANATION_OPTIONS, 5, false, 'selectedApplanation', (this.state.selectedApplanation), this.updateState.bind(this), false, true)}
        {this.renderTitleWithMultipleBtn("CCT (Pachymetry)", Constants.CCT_OPTIONS, 5, false, 'selectedCCT', (this.state.selectedCCT), this.updateState.bind(this), false, true)}
        {this.renderTitleWithMultipleBtn("Correction Factor", Constants.CORRECTION_FACTOR_OPTIONS, 6, false, 'selectedCorrection', (this.state.selectedCorrection), this.updateState.bind(this), false, true)}
        {this.renderTitleWithMultipleBtn("CIOP", Constants.CIOP_OPTIONS, 6, false, 'selectedCIOP', (this.state.selectedCIOP), this.updateState.bind(this), false, true)} */}
        <CommonSectionHeader heading={"Non- contact tonometry"}  />
        {this.renderTextBox("", "selectedNCT", this.state.selectedNCT)}
        
        <CommonSectionHeader heading={"Applanation Tonometry"}  />
        
         {this.renderTextBox("", "selectedApplanation", this.state.selectedApplanation)}
        
        <CommonSectionHeader heading={"Central Corneal Thickness (Pachymetry)"}  />
       
         {this.renderTextBox("", "selectedCCT", this.state.selectedCCT)}
        
        <CommonSectionHeader heading={"Corrrected Intraocular Pressure"}  />
        
        {this.renderTextBox("", "selectedCIOP", this.state.selectedCIOP)}
      
        {/* {this.renderTextFieldView("Comments")} */}
        <CommonSectionHeader heading={"Comments"}  />
        {this.renderTextFieldView("", "comments", this.state.comments, true, 4, "", "", "", true,this.state.isLocked)}

        {
          this.state.selectedEye.value == "RE" ?
            this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
            : this.state.selectedEye.value == "LE" ?
              this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
              : null
        }

        <View style={{ marginTop: '1.2vw',alignSelf:"center"}}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => this.addComplaint(this.state.selectedEye.value, this.state.id)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>

      </View>
    );
  }
}
const styles = StyleSheet.create({
      TextBoxView: { 
        justifyContent: 'space-between', 
        marginTop: '0.6vw', 
        marginBottom: '0.6vw' 
      },
      TextInputView: {
        color: "#525252",
        width: "50%",
        minHeight: '2vw',
        marginLeft: '1.2vw',
        padding: '0.5vw',
        marginRight: 0,
        flex: 1,
        borderWidth: 0.5,
        fontSize:'0.9vw'
        // borderRadius: 10
      },
      CheckBoxView: { 
        marginRight: '0.8vw',
        height: '1.2vw',
        width: '1.2vw' 
      },
      CheckBoxText: { 
        fontSize: '0.8vw', 
        color: "#888888" 
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      }
})