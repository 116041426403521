import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "pastHistory"


export class PastHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            psyProblemsFlag: false,
            psyProblemsDetails: "",

            behavioursFlag: false,
            behavioursDetails: "",

            detailsOfTreatmentHistory: "",
            detailsOfOthers: "",
        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["psyProblemsFlag"] = data.psychiatic_problems;
                states["psyProblemsDetails"] = data.psychiatic_details;

                states["behavioursFlag"] = data.neurotic_behavior;
                states["behavioursDetails"] = data.neurotic_details;

                states["detailsOfTreatmentHistory"] = data.treatment_history;

                states["detailsOfOthers"] = data.other_details;
                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["psyProblemsFlag"] = false;
        states["psyProblemsDetails"] = "";
        states["behavioursFlag"] = false;
        states["behavioursDetails"] = "";
        states["detailsOfTreatmentHistory"] = "";
        states["detailsOfOthers"] = "";
        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "psychiatic_problems": states.psyProblemsFlag,
                "psychiatic_details": states.psyProblemsDetails,
                "neurotic_behavior": states.behavioursFlag,
                "neurotic_details": states.behavioursDetails,
                "other_details": states.detailsOfOthers,
                "treatment_history": states.detailsOfTreatmentHistory
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PAST_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePastHistorySuccess,
                this.savePastHistoryFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Past History already added please click on edit icon to update the values")

        }
    }

    savePastHistorySuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePastHistoryFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Past History"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Psychiatry Problems", "Enter details",
                    "psyProblemsFlag", this.state.psyProblemsFlag,
                    "psyProblemsDetails", this.state.psyProblemsDetails
                )}

                {this.renderHistoryComponent(
                    "Neurotic traits/odd/deviant behaviours", "Enter details",
                    "behavioursFlag", this.state.behavioursFlag,
                    "behavioursDetails", this.state.behavioursDetails
                )}

                {this.renderHistoryComponent(
                    "Treatment History", "Enter details",
                    "", false,
                    "detailsOfTreatmentHistory", this.state.detailsOfTreatmentHistory
                )}

                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "", false,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
