import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import { CommonDatePicker, } from './registryCommonComponents'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { RegistryContext } from "./RegistryContext";


const listOfGender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Trans", value: "Trans" },
]

const listOfAreaa = [
    { label: "Singanallure", value: "Singanallure" },
    { label: "Hopes", value: "Hopes" },
    { label: "LakshmiMills", value: "LakshmiMills" },
]


export class RegistryVitals extends Component {
    constructor(props) {
        super(props);
        this.state = {


            weightFrom: "",
            weightTo: "",
            heightFrom: "",
            heightTo: "",
            temperatureFrom: "",
            temperatureTo: "",
            bloodPressureFrom: "",
            bloodPressureTo: "",
            pulseRateTo: "",
            pulseRateFrom: "",
            pulseTo: "",
            pulseFrom: "",
            bloodFrom: "",
            bloodTo: "",
            CircumferenceFrom: "",
            CircumferenceTo: "",
            DiastolicFrom: "",
            DiastolicTo: "",
            SystolicFrom: "",
            SystolicTo: "",

        };
    }















    renderHeight(vitalsData,contaxt) {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Height (cm)"}
                    noOfColumn={1}
                    columnSize={[1]}
                />

                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ flex: 0.5 }}>
                        {this.renderCommonTextBox("From", vitalsData.heightFrom, "heightFrom", contaxt)}
                    </View>
                    <View style={{ flex: 0.5 }}>
                        {this.renderCommonTextBox("To", vitalsData.heightTo, "heightTo", contaxt)}
                    </View>
                </View>


                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Weight (kg)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.weightFrom, "weightFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.weightTo, "weightTo", contaxt)}
                        </View>
                    </View>
                </View>



                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Temperature (°f/°c)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.temperatureFrom, "temperatureFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.temperatureTo, "temperatureTo", contaxt)}
                        </View>
                    </View>
                </View>


                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Systolic Blood Pressure (mm/Hg)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.SystolicFrom, "SystolicFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.SystolicTo, "SystolicTo", contaxt)}
                        </View>
                    </View>
                </View>


                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Diastolic Blood Pressure (mm/Hg)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.DiastolicFrom, "DiastolicFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.DiastolicTo, "DiastolicTo", contaxt)}
                        </View>
                    </View>
                </View>



                {/* <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Blood Pressure"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", this.state.bloodPressureFrom, "bloodPressureFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", this.state.bloodPressureTo, "bloodPressureTo", contaxt)}
                        </View>
                    </View>
                </View> */}


                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Pulse Oximetry (%)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.pulseFrom, "pulseFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.pulseTo, "pulseTo", contaxt)}
                        </View>
                    </View>
                </View>

                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Pulse Rate (min)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.pulseRateFrom, "pulseRateFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.pulseRateTo, "pulseRateTo", contaxt)}
                        </View>
                    </View>
                </View>

                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Random Blood Sugar (mg/dl)"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.bloodFrom, "bloodFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.bloodTo, "bloodTo", contaxt)}
                        </View>
                    </View>
                </View>

                {
                   this.props.userType == "PD" ?

                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Head Circumference"}
                        noOfColumn={1}
                        columnSize={[1]}
                    />
                    <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("From", vitalsData.CircumferenceFrom, "CircumferenceFrom", contaxt)}
                        </View>
                        <View style={{ flex: 0.5 }}>
                            {this.renderCommonTextBox("To", vitalsData.CircumferenceTo, "CircumferenceTo", contaxt)}
                        </View>
                    </View>
                </View>
                :
                null
            }


            </View>
        )
    }







    onPressButton(contectCallBack = "", checkIsCallBack = false, key, value) {
        var states = this.state;

        if (checkIsCallBack) {
            contectCallBack(key, value);
        }

        if (key == "clear") {
            this.setState({
                weightFrom: "",
                weightTo: "",
                heightFrom: "",
                heightTo: "",
                temperatureFrom: "",
                temperatureTo: "",
                bloodPressureFrom: "",
                bloodPressureTo: "",
                pulseRateTo: "",
                pulseRateFrom: "",
                pulseTo: "",
                pulseFrom: "",
                bloodFrom: "",
                bloodTo: "",
                CircumferenceFrom: "",
                CircumferenceTo: "",
                DiastolicFrom: "",
                DiastolicTo: "",
                SystolicFrom: "",
                SystolicTo: "",
            })
        }
        this.setState({ states })

    }


    renderCommonTextBox(placeholder, value, textBoxkey, contaxt) {
        return (
            <View style={[{ height: '2.81vw', marginRight: 15 }]}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>


                <TextInput
                    style={{
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: '2vw',
                        fontSize:'0.9vw',
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                    value={value}
                    onChangeText={(text) => {
                        var isNum = this.numberValidation(text)
                        if (isNum) {
                            var states = this.state;
                            states[textBoxkey] = text;
                            this.setState({ states }, () => {
                                if (contaxt) {
                                    contaxt(textBoxkey, text);
                                }
                            })
                        }
                    }
                    }
                />




            </View>
        )
    }

    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }


    renderFooterAction(contectCallBack) {
        var actions = []

        actions = [
            { label: "Apply", value: "Apply" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15 }}>
                {
                    actions.map((item, index) => {
                        return (
                            <View style={{ marginRight: 15 }}>
                                <CommonButton
                                    // disable={this.state.isLocked}
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={item.label}
                                    buttonType={item.value == "Apply" ? "theme" : "outlineTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={item.value} />
                            </View>
                        )
                    })
                }

            </View>
        )
    }


    render() {
        return (
            <RegistryContext.Consumer>
                {(getContext) => {
                    var vitalsData = getContext.vitalsDetials;

                    return (
                        <>            <View>
                            <View>
                                {this.renderHeight(vitalsData,getContext.vitalsData.bind(this))}
                            </View>

                            <View style={{ zIndex: -1 }}>
                                {this.renderFooterAction(getContext.vitalsData.bind(this))}
                            </View>

                        </View>
                        </>
                    )

                }

                }
            </RegistryContext.Consumer>
        );
    }
}
