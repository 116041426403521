import { StylesContext } from '@material-ui/styles';
import React, { Component } from 'react';
import { TouchableOpacity, Text, View, StyleSheet, ScrollView } from 'react-native';
import { color } from "../../../../styles/Color";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';

export default class SurgeryNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SurgeryNotesData: [],
      patientAppointment: this.props.patientAppointment,
    }
  }
  componentDidMount() {
    this.GetSurgeryNotesData()
  }
  GetSurgeryNotesData = () => {
    var serviceUrl = Constants.COLLAB_REPORT_SURGERY_NOTES + "?patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDataSuccess,
      this.getDataFailure
    );
  }

  getDataSuccess = (success) => {
    this.setState({
      SurgeryNotesData: success.data
    })
  }
  getDataFailure = (error) => {
    console.log(error.message)
  }
  render() {
    return (
      <View style={{  width: '92vw' }}>
      <ScrollView horizontal showsHorizontalScrollIndicator={true}>
        
          <View style={{display: 'flex', flexDirection: 'row', }}>
          {
            this.state.SurgeryNotesData && this.state.SurgeryNotesData.length > 0 ?
              this.state.SurgeryNotesData.map((item) => (
               <View style={{width:'40vw', marginRight:'2vw'}}>
                 <ScrollView style={styles.mainContainer}>
                  
                    <View style={{ width: "38vw", borderBottomWidth: 1 }}>
                      <View style={[styles.TableTitleContainer]}>
                        <View style={{ alignItems: "center", width: '100%', }} >
                          <Text style={styles.TableHeadText}>Surgery Date : {item.date}</Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.leftView}>
                      <View style={styles.TableContainer}>

                        <View style={{ width: "39vw" }}>
                          <View style={[styles.TableTitleContainer, { backgroundColor: color.sectionHeadingLiteColor, borderRadius: '0.26vw',width: '37.2vw' }]}>
                            <View style={{ alignItems: "left", width: '40%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '3vw' }]}>Title</Text>
                            </View>
                            <View style={{ alignItems: "left", width: '60%' }} >
                              <Text style={[styles.TableHeadText, { marginLeft: '10vw' }]}>Values</Text>
                            </View>
                          </View>
                        </View>

                        <ScrollView style={styles.MainTable}>
                          <View style={styles.ColWrapper}>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Name</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.patient_name && item.patient_name != "" && item.patient_name != null ? item.patient_name : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Patient Acc.No</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.patient_acc_no && item.patient_acc_no != "" && item.patient_acc_no != null ? item.patient_acc_no : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Ward / Room No</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.ward_name_room_no && item.ward_name_room_no != "" && item.ward_name_room_no != null ? item.ward_name_room_no : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Reason For a Hospital Stay</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.reason_for_hospital_stay && item.reason_for_hospital_stay != "" && item.reason_for_hospital_stay != null ? item.reason_for_hospital_stay : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>The Patient admits to Having</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.patient_admit_having && item.patient_admit_having != "" && item.patient_admit_having != null ? item.patient_admit_having : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Patient Denies Having</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.patient_denies_having && item.patient_denies_having != "" && item.patient_denies_having != null ? item.patient_denies_having : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Procedure Details Preop Diagnosis</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.preop_diagnosis && item.preop_diagnosis != "" && item.preop_diagnosis != null ? item.preop_diagnosis : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Postop Diagnosis</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.postop_diagnosis && item.postop_diagnosis != "" && item.postop_diagnosis != null ? item.postop_diagnosis : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Procedure</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.procedure && item.procedure != "" && item.procedure != null ? item.procedure : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Surgeon</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.surgen && item.surgen != "" && item.surgen != null ? item.surgen : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Staff Nurse</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.staff_name && item.staff_name != "" && item.staff_name != null ? item.staff_name : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Assistant(s)</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.assistent && item.assistent != "" && item.assistent != null ? item.assistent : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Type of Anaesthesia</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.type_of_anesthesia && item.type_of_anesthesia != "" && item.type_of_anesthesia != null ? item.type_of_anesthesia : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Fluids Given</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.fluids && item.fluids != "" && item.fluids != null ? item.fluids : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Urine Output</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.urine_output && item.urine_output != "" && item.urine_output != null ? item.urine_output : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Estimated Blood Loss(EBL)</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.estimated_blood_loss && item.estimated_blood_loss != "" && item.estimated_blood_loss != null ? item.estimated_blood_loss : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Specimen</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.speciman && item.speciman != "" && item.speciman != null ? item.speciman : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Drains</Text>
                              </View>
                              <View style={styles.TableRightCol}>

                                <Text style={styles.RightText}>{item.drains && item.drains != "" && item.drains != null ? item.drains : "-" }</Text>
                              </View>
                            </View>
                            <View style={styles.TableDataWrapper}>
                              <View style={styles.TableLeftCol}>
                                <Text style={styles.LeftText}>Operative Findings</Text>
                              </View>
                              <View style={styles.TableRightCol}>
                                <Text style={styles.RightText}>{item.plan && item.plan != "" && item.plan != null ? item.plan : "-" }</Text>
                              </View>
                            </View>
                          </View>
                          
                        </ScrollView>
                      </View>

                    </View>
                  

                </ScrollView>
               </View>
              ))
              : 
              <View style={styles.NoRecordsView}>
              <Text style={styles.NoRecordsText}>No Records to show...</Text>
            </View>
          }
          </View>
        
      </ScrollView></View>
    )
  }
}

const styles = StyleSheet.create({
  LeftText:{
fontSize:'0.9vw',
fontWeight:'500'
  },
  RightText:{
    fontSize:'0.9vw',
    // paddingRight:'0.5vw',
    width:'21vw',
    textAlign:'left'
  },
  mainContainer: {
    height: '70vh',
    borderWidth: 1,
    borderRadius: '0.2vw',
    marginRight: '2vw',
    display: 'flex',
  },
  leftView: {
    // width: '32vw',
    height: '58vh',
    marginLeft: '0.3vw'
  },
  TableContainer: {
    marginTop: '0.5vw',
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderTopLeftRadius: '0.26vw',
    borderTopRightRadius: '0.26vw',
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '37vw',
    borderRadius: 4,
    borderBottomWidth: 1,
    borderColor: color.sectionHeadingLiteColor,
    // height: "3vw",
    alignItems: "center",
    paddingTop:'1vw',
    paddingBottom:'1vw'
  },
  MainTable: {
    height: '59vh',
    width: '40vw'
  },
  TableLeftCol: {
    alignItems: "left", width: '40%', marginLeft: '1vw'
  },
  TableRightCol: {
    alignItems: "left", width: '60%',
  },
  ColWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw',
    width: '100%'
  },
  NoRecordsText: {
    fontSize: '1vw',
    marginLeft: "40vw"
  },
})

