import React, { Component } from 'react';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  CheckBox,
  Image
} from "react-native";
import AsyncStorage from "../../../AsyncStorage";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import { Select } from 'antd';
import ImagePath from "../../../utils/ImagePaths";

const { Option } = Select;


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;

export default class DayEndOverview extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data3: {},
      CheckBoxValue: false,
      outpatients: false,
      inpatients: false,
      pharmacy: false,
      laboratory: false,
      OT: false,
      imageLab: false,
      corporate: false,
      PageName: '',
      Selecteddate: converted_date,
      checkedPharmacy: "",
      checkedOP: "",
      checkedLab: "",
      checkedOT: "",
      checkedImgLab: "",
      checkedIP: "",
      checkedCorporate: "",
      isOPTclinic: false,
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isClear: false,
      isClearInt: 0,
      isAdminAccess: false
    }
  }
  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    var isOPT = false
    if (loggedInDataObj.clinic_type) {
      isOPT = loggedInDataObj.clinic_type.is_opt_clinic
    } else {
      isOPT = false;
    }
    this.setState({
      isOPTclinic: isOPT,
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.DayendOverViewChangeStatus();
  }

  renderCheckBox(label, value, key) {
    return (
      <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
        <CheckBox
          value={value}
          style={{ marginRight: ".8vw", width: ".85vw", height: ".85vw", marginBottom: "1vw" }}
          onValueChange={() => {

            var states = this.state;

            states[key] = !value

            this.setState({
              states
            }, () => {

              if (key === "outpatients") {
                if (this.state.outpatients) {
                  this.setState({
                    checkedOP: "OP"
                  })
                }
                else {
                  this.setState({
                    checkedOP: ""
                  })
                }

              }
              else if (key === "inpatients") {
                if (this.state.inpatients) {
                  this.setState({
                    checkedIP: "IP"
                  })
                }
                else {
                  this.setState({
                    checkedIP: ""
                  })
                }

              } else if (key === "pharmacy") {
                if (this.state.pharmacy) {
                  this.setState({
                    checkedPharmacy: "Pharmacy"
                  })
                } else {
                  this.setState({
                    checkedPharmacy: ""
                  })
                }

              } else if (key === "laboratory") {
                if (this.state.laboratory) {
                  this.setState({
                    checkedLab: "Lab"
                  })
                } else {
                  this.setState({
                    checkedLab: ""
                  })
                }

              } else if (key === "OT") {
                if (this.state.OT) {
                  this.setState({
                    checkedOT: "OT"
                  })
                } else {
                  this.setState({
                    checkedOT: ""
                  })
                }

              } else if (key === "imageLab") {
                if (this.state.imageLab) {
                  this.setState({
                    checkedImgLab: "Img_Lab"
                  })
                } else {
                  this.setState({
                    checkedImgLab: ""
                  })
                }

              } else if (key === "corporate") {
                if (this.state.corporate) {
                  this.setState({
                    checkedCorporate: "Corporate"
                  })
                } else {
                  this.setState({
                    checkedCorporate: ""
                  })
                }

              }
            })
          }}

        />
        <Text style={{ fontSize: "0.9vw", color: "#888888", marginRight: "0.65vw", marginTop: '-0.3vw' }}>{label}</Text>
      </View>
    );
  }

  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ?Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ?Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }


  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  PrintPatienToken = (key) => {

    console.log(key, 'key')
    let states = this.state;
    let data = {
      // 'doctor_id': states.selectedDoctorAction === '' ? [] : states.selectedDoctorAction === 'Nursing'? [states.selectedDoctorAction]:[Number(states.selectedDoctorAction)],
      // 'service_name': states.selectedServiceAction,      
      'from_date': states.filterData.fromDate,
      'to_date': states.filterData.toDate

    }
    var Date1 = states.Selecteddate.toString()
    var serviceUrl = ''

    var isImageLab = states.isOPTclinic ? "Img_Lab" : ""
    if (this.state.checkedOP || this.state.checkedPharmacy || this.state.checkedLab || this.state.checkedIP || this.state.checkedImgLab || this.state.checkedOT || this.state.checkedCorporate) {
      var serviceUrl = Constants.PRINT_DayendOverview + '?' + '&bill_type=' + this.state.checkedPharmacy + '&bill_type=' + this.state.checkedOP + '&bill_type=' + this.state.checkedLab + '&bill_type=' + this.state.checkedImgLab + '&bill_type=' + this.state.checkedIP + '&bill_type=' + this.state.checkedOT + '&bill_type=' + this.state.checkedCorporate + '&invoice_date=' + states.filterData.fromDate + '&invoice_to_date=' + states.filterData.toDate + "&export_type=pdf";

    } else {
      serviceUrl = Constants.PRINT_DayendOverview + '?&bill_type=Pharmacy&bill_type=OP&bill_type=Lab&bill_type=OT&bill_type=Corporate&bill_type=' + isImageLab + '&bill_type=IP' + '&invoice_date=' + states.filterData.fromDate + '&invoice_to_date=' + states.filterData.toDate + '&export_type=pdf';
    }

    console.log(serviceUrl, 'serviceurl')

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );
  };

  getPrintSuccess = response => {

    if (response) {
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
    console.log(error);
  };

  onPressButton(key, value) {
    if (key == "save") {
      this.DayendOverViewChangeStatus();
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      var states = this.state
      this.setState({
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        data3: "",
        outpatients: "",
        inpatients: "",
        pharmacy: "",
        laboratory: "",
        OT:"",
        corporate:"",
        Selecteddate: converted_date,
        imageLab: "",
        checkedIP: "",
        checkedImgLab: "",
        checkedLab: "",
        checkedOP: "",
        checkedPharmacy: "",
        checkedOT:"",
        checkedCorporate:"",
        isClear: true,
        isClearInt: 1,
      }, () => {
        this.DayendOverViewChangeStatus()
        this.setState({
          isClear: false
        }, () => {
          this.renderDateFilter()
        })
      })
    }
    else if (key == "print") {
      this.PrintPatienToken(this.state.PageName)
    }
  }

  DayendOverViewChangeStatus(key) {
    var states = this.state
    this.setState({
      isClear: false
    })
    var date = states.Selecteddate.toString()

    var service_url
    var invoice_to_date = states.filterData.toDate ? states.filterData.toDate : ""
    var invoice_date = states.filterData.fromDate ? states.filterData.fromDate : ""
    var isImageLab = states.isOPTclinic ? "Img_Lab" : ""
    if (this.state.checkedOP || this.state.checkedPharmacy || this.state.checkedLab || this.state.checkedIP || this.state.checkedImgLab || this.state.checkedOT || this.state.checkedCorporate) {
      var service_url = Constants.DayEndOverView + '?' + '&bill_type=' + this.state.checkedPharmacy + '&bill_type=' + this.state.checkedOP + '&bill_type=' + this.state.checkedLab + '&bill_type=' + this.state.checkedImgLab + '&bill_type=' + this.state.checkedIP + '&bill_type=' + this.state.checkedOT + '&bill_type=' + this.state.checkedCorporate + '&invoice_date=' + states.filterData.fromDate + '&invoice_to_date=' + states.filterData.toDate;

    } else {
      var service_url = Constants.DayEndOverView + '?' + '&bill_type=Pharmacy' + '&bill_type=OP' + '&bill_type=Lab' + '&bill_type=IP' + '&bill_type=OT'+ '&bill_type=Corporate' + '&bill_type=' + isImageLab + '&invoice_date=' + invoice_date + '&invoice_to_date=' + invoice_to_date;
    }
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getLaboratoryBillingTransactionSuccess,
      this.getLaboratoryBillingTransactionFailure
    );
  }
  getLaboratoryBillingTransactionSuccess = response => {

    if (response.status === "success") {
      let newData = { ...response.data }
      console.log(newData)
      this.setState({
        data3: response.data
      })
    }
  }
  getLaboratoryBillingTransactionFailure = error => {
    console.log(error);
  };

  renderStatisticsCards = () => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: "1vw" }}>
        <View style={[styles.MainCardView]}>
          <View>
            <Text style={{ fontSize: "0.9vw", fontWeight: '500' }}>{this.state.data3.total ? "₹ " : null} {this.state.data3.total && this.state.data3.total != "" ? parseInt(this.state.data3.total) : 0}  </Text>
            <Text style={{ fontSize: "0.87vw", fontWeight: '500', textAlign: 'center', paddingVertical: "0.65", marginTop: '.5vw', color:color.themeDark }}>{"Total"}</Text>
          </View>
        </View>
        <View style={{display:'flex', flexDirection:'column', width:'23vw'}}>
          <View style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
            <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.cash : 0}  </Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.Cash} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"Cash (₹)"}</Text>
                </View>
              </View>
            </View>
            <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.card : 0}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.Card} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"Card (₹)"}</Text>
                </View>
              </View>
            </View>
            <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.upi : 0}  </Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.UPI} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"UPI (₹)"}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', marginTop:'1vw'}}>
            <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.bank_transfer : 0}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.BankTransfer} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"Bank (₹)"}</Text>
                </View>
              </View>
            </View>
            <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.insurance : 0}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.Insurance} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"Insurance (₹)"}</Text>
                </View>
              </View>
            </View>
            {/* <View style={styles.CardView}>
              <View>
                <Text style={{ fontSize: "0.8vw", fontWeight: '500', textAlign:'center' }}>{this.state.data3.amount_type ? this.state.data3.amount_type.cheque : 0}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '0.2vw' }}>
                  <Image source={ImagePath.Cheque} style={styles.RenderTotalItemImg} resizeMode="contain" />
                  <Text style={{ fontSize: '0.7vw', fontWeight: '500', marginLeft:"0.2vw" }}>{"Cheque (₹)"}</Text>
                </View>
              </View>
            </View> */}
          </View>
        </View>
      </View>
    )
  }

  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    return (

      <View style={styles.bodyContainer}>
        <View style={{ width: "65%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            {/* <View style={{ justifyContent: "center", height: "1.95vw", marginTop: "1vw" }}>
              <Text style={{ fontSize: "1.4vw", fontWeight: "500" }}>
                {"Day End Overview"}
              </Text>
            </View> */}
            <View style={{ width: "100%", marginTop: "1.5vw", paddingHorizontal: "0.35" }} >
              <CommonHistorySectionHeader
                heading1={"Bill Type"}
                heading2={"Cash"}
                heading3={"Card"}
                heading4={"UPI"}
                heading5={"Bank TFR"}
                heading6={"Insurance"}
                // heading7={"Cheque"}
                heading7={"Total"}
                noOfColumn={7}
                columnSize={[0.13, 0.13, 0.13, 0.13, 0.13, 0.13, 0.13, 0.09]}
              />
            </View>
            <View>
              {this.state.data3.bill_type ?
                <View>
                  <View style={{ paddingVertical: "0.65vw", width: "100%", paddingHorizontal: "0.35" }}>
                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.30vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>Out Patient</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" :  this.state.data3.payment_type.Out_Patient.cash : null
                        }
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.card : null
                        }
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.upi : null
                        }
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.bank_transfer : null
                        }
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.insurance : null
                        }
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.cheque : null
                        }
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {
                          this.state.data3 && this.state.data3 ? Object.keys(this.state.data3.payment_type.Out_Patient).length === 0 ? "-" : this.state.data3.payment_type.Out_Patient.total_amount : null
                        }
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>In Patient</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.cash : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.card : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.upi : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.bank_transfer : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.insurance : null}
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.cheque : null}
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.In_Patient).length === 0 ? "-" : this.state.data3.payment_type.In_Patient.total_amount : null}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>Laboratory</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.cash : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.card : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.upi : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.bank_transfer : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.insurance : null}
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.cheque ? this.state.data3.payment_type.Laboratory.cheque : 0  : null}
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Laboratory.total_amount : null}
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>Pharmacy</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.cash) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.card) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.upi) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.bank_transfer) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.insurance) : null}
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.cheque) ? (this.state.data3.payment_type.Pharmacy.cheque) : 0  : null}
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Pharmacy).length === 0 ? "-" : (this.state.data3.payment_type.Pharmacy.total_amount) : null}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>Operation Theater</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.cash) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.card) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.upi) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.bank_transfer) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.insurance) : null}
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.cheque) : null}
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Operation_Theater).length === 0 ? "-" : (this.state.data3.payment_type.Operation_Theater.total_amount) : null}
                      </Text>
                    </View>
                    {this.state.isOPTclinic ?
                      <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>ImageLab</Text>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.cash : null}
                        </Text>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.card : null}
                        </Text>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.upi : null}
                        </Text>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.bank_transfer : null}
                        </Text>
                        <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.insurance : null}
                        </Text>
                        {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.cheque ? this.state.data3.payment_type.Img_Laboratory.cheque : 0 : null}
                        </Text> */}
                        <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                          {this.state.data3 ? Object.keys(this.state.data3.payment_type.Img_Laboratory).length === 0 ? "-" : this.state.data3.payment_type.Img_Laboratory.total_amount : null}
                        </Text>
                      </View>
                      : null}
                    <View style={{ flexDirection: "row", borderBottomWidth: ".1vw", borderBottomColor: color.lightGray, paddingVertical: "1.3vw", paddingHorizontal: "1.5vw" }}>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>Corporate</Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.cash) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.card) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.upi) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.bank_transfer) : null}
                      </Text>
                      <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                       {"-"}
                        {/* {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.insurance) : null} */}
                      </Text>
                      {/* <Text style={{ flex: 0.13, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : this.state.data3.payment_type.Corporate.cheque ? this.state.data3.payment_type.Corporate.cheque : 0 : null}
                      </Text> */}
                      <Text style={{ flex: 0.09, fontSize: "1vw" }}>
                        {this.state.data3 ? Object.keys(this.state.data3.payment_type.Corporate).length === 0 ? "-" : (this.state.data3.payment_type.Corporate.total_amount) : null}
                      </Text>
                    </View>
                  </View>
                </View> :
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "6.5vw" }}>
                  <Text style={{ color: color.placeholder, marginTop: "3.25vw", fontSize: '1vw' }}>{"No records to be shown"}</Text>
                </View>
              }
            </View>
          </View>
        </View>

        <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade }}>

          <View style={{ padding: '1vw' }}>

            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>

            {this.renderStatisticsCards()}
            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                <View style={{ minWidth: "9.75vw" }}>
                  <View style={{ flexDirection: "row", alignItems: "center", zIndex: 1 }}>
                    {this.renderDateFilter()}
                  </View>
                </View>
              </View>

              <View style={{ zIndex: -1 }}>
                <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                  <Text style={styles.headerText}>Bill Type</Text>
                </View>
                <View style={{ width: "100%", zIndex: -1, flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: "0.85vw", marginVertical: "1.3vw", flexWrap: "wrap" }}>
                  {this.renderCheckBox("Out Patients", this.state.outpatients, "outpatients")}
                  {this.renderCheckBox("In Patients", this.state.inpatients, "inpatients")}
                  {this.renderCheckBox("Pharmacy", this.state.pharmacy, "pharmacy")}
                  {this.renderCheckBox("Laboratory", this.state.laboratory, "laboratory")}
                  {this.renderCheckBox("OT", this.state.OT, "OT")}
                  {this.state.isOPTclinic ? this.renderCheckBox("ImageLab", this.state.imageLab, "imageLab") : null}
                  {this.renderCheckBox("Corporate", this.state.corporate, "corporate")}
                </View>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: "1.3vw", zIndex: -1 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }


              </View>

            </View>
          </View>

        </View>

      </View>
    )
  }
}
const styles = StyleSheet.create({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  },
  CardView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: color.white, height: '4vw',
    borderRadius: '0.26vw',
    // paddingHorizontal: '0.5vw',
    minWidth: '6.8vw',
    marginHorizontal: "0.5vw"
  },
  MainCardView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: color.white,
    borderRadius: '0.26vw',
    // paddingHorizontal: 15,
    minWidth: '7vw',
    // marginRight: 20,
    height:'5vw'
  },
  RenderTotalItemImg: {
    height: '1.5vw',
    width: '1.5vw',
  },
})