import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, StyleSheet, Image, TextInput } from 'react-native';
import { color } from '../../../../styles/Color';
import Pagination from '../../common/Pagination';
import { NavigationTopHeader } from '../../BaseComponent';
import {DatePicker} from 'antd';
import OpthamologyService from '../../../../network/OpthamologyService';
import { Constants } from '../../../../utils/Constants';
import moment from "moment";

const navigationHeaderAdmin = [
    { label: "Stock Transfer", value: "stockTransfer" },
    { label: "Approval Request", value: "approvalRequest"},
    { label: "Stock Transfer Report", value: "stockTransferReport" }
]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class ApprovalRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stockTransferList: [],
        stockTransferAfterPagination: [],
        current_page: 1,
        records_per_page: 10,
        isUpdateClick: false,
        approvalPopupList: [],
        isEditDetails: false,
        selectedIndex: "",
        isSelfTransfer: false,
        isCourierTransfer: false,
        selectedID: "",
        popUpHeaderData: {},
        st_date: converted_date
    };
  }
  componentDidMount() {
    this.getApprovalList()
  }
  getApprovalList = () => {
    var url = Constants.ADMIN_STOCK_TRANSFER + "?summary_list=true"
    OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getApprovalListSuccess,
        this.getApprovalListFailure
    );
}

getApprovalListSuccess = response => {
    if (response && response.status == "success") {
        var data  = response.data
        this.setState({
          stockTransferList: data
        })
    } else {
        this.props.showResposeValue("error", response.message)
    }
}
getApprovalListFailure = error => {
    this.props.showResposeValue("error", error.message)
}
getPopupList = (selectedID) => {
  var url = Constants.STOCK_TRANSFER_INBOUND + "?summary_list=" + "&id=" + selectedID
  OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getPopupListSuccess,
      this.getPopupListFailure
  );
}

getPopupListSuccess = response => {
  if (response && response.status == "success") {
      this.setState({
        approvalPopupList: response.line_items,
        popUpHeaderData: response.header_details
      })
  } else {
      this.props.showResposeValue("error", response.message)
  }
}
getPopupListFailure = error => {
  this.props.showResposeValue("error", error.message)
}
  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
      </View>)
  }
  paginationChangedValues(data) {
    this.setState({
      stockTransferAfterPagination: data
    })
  }  
  onPressStockApproval = () => {
    var service_url = ""
    var approval = []
    var {  approvalPopupList } = this.state
    for( let i = 0; i< approvalPopupList.length ; i++) {
      var line_item=[]
      approval = JSON.parse(approvalPopupList[i].stock_details) ? JSON.parse(approvalPopupList[i].stock_details) : []
      var total = 0
      var stock_details = []
      if(approval && approval.length > 0 ) {
        for ( let i = 0; i < approval.length; i++ ) {
          total = total + approval[i].quantity
          stock_details.push({ "stock_list_id": approval[i].stock_list_id, "batch_no": approval[i].batch_no, "quantity": approval[i].quantity, "expiry_date": approval[i].expiry_date })
       }
      }
      line_item.push({"id": this.state.selectedID, "quantity": total, "stock_details": stock_details})
    }
    var data = {
      "exchange_summary_id": this.state.selectedID,
      "st_date": this.state.st_date,
      "line_item" : line_item
    }
    service_url = Constants.ADMIN_STOCK_TRANSFER
    OpthamologyService.getInstance().putMethod(
      service_url,
      data,
      this,
      this.onPressStovkTransferSuccess,
      this.onPressStovkTransferFailure
    );
  } 
  onPressStovkTransferSuccess = (response) => {
   if(response && response.status === "success") {
    this.props.showResposeValue("success", response.message)
     this.setState({
      isUpdateClick: false
     },()=>{
       this.getApprovalList()
     })
   }
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
      current_page: current_page,
      records_per_page: records_per_page
    })
  }
  renderPopupHeader = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.05, "0.8vw")}
        {this.getBillTableTitle('Brand', 0.13, "0.8vw")}
        {this.getBillTableTitle('Generic / Combo', 0.12, "0.8vw")}
        {this.getBillTableTitle('Dosage Type / Strength', 0.15, "0.8vw")}
        {this.getBillTableTitle('Batch No', 0.15, "0.8vw")}
        {this.getBillTableTitle('Exp On', 0.15, "0.8vw")}
        {this.getBillTableTitle('Transfer Quantity', 0.15, "0.8vw")}
        {this.getBillTableTitle('Total', 0.1, "0.8vw")}

        {/* </View> */}
      </View>
    )
  }
  renderTableData = () => {
    var { current_page, records_per_page } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ height: "20vw" }}>
        {this.state.approvalPopupList && this.state.approvalPopupList.length > 0 ?
          this.state.approvalPopupList && this.state.approvalPopupList.map((item, index) => {
            let sno = (current_page - 1) * records_per_page + index + 1;

            var stock_details = JSON.parse( item.stock_details) ? JSON.parse(item.stock_details) : []
            // var stock_details = JSON.parse(stock) && JSON.parse(stock).length > 0 ? JSON.parse(stock): []
            
          
            var batch_no = []
            var exp_on = []
            var total = 0
            for ( let i =0 ; i < stock_details.length ; i++ ) {
             if(stock_details[i].isSelected) {
              total = total + (stock_details[i].quantity * stock_details[i].mrp_per_quantity)
              batch_no.push(stock_details[i].batch_no)
              exp_on.push(stock_details[i].expiry_date)
              
             }
            }
            return (
              <View style={[styles.dataContainer, { marginLeft: "0.5vw" }]}>
              <View style={{ flex: 0.05 }}><Text style={styles.TableDataText}>{sno}</Text></View>
              <View style={{ flex: 0.13 }}><Text style={styles.TableDataText}>{item.drug_name}</Text></View>
              <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.generic_name}</Text></View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.dosage_type + " / " + item.drug_strength}</Text></View>
              <View style={{ flex: 0.15 }}>
              <TextInput
                    value={batch_no}
                    editable = {false}
                    onChangeText={(text) => { 
                      
                    }}
                    style={[styles.textBox, {width: "90%", height: "4vh"}]}
                />
              </View>
              <View style={{ flex: 0.15 }}>
                <TextInput
                    editable = {false}
                    value={exp_on}
                    onChangeText={(text) => { 
                      
                    }}
                    style={[styles.textBox, {width: "90%", height: "4vh"}]}
                />
              </View>  
              <View style={{ flex: 0.15 }}>
                 <TextInput
                    value={this.state.approvalPopupList[index].request_quantity}
                    onChangeText={(text) => { 
                      var {approvalPopupList} = this.state
                      approvalPopupList[index].request_quantity = text
                      this.setState({
                        approvalPopupList
                      })
                    }}
                    style={[styles.textBox, {width: "50%", height: "4vh"}]}
                />
               </View> 
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{ total ? parseFloat(total).toFixed(2) : 0}</Text></View>
            </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>}
      </ScrollView>
    )
  }
  renderBottomView = () => {
    return (
      <View style={{ justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "row", marginTop: "0.5vw" }}>
        <TouchableOpacity style={styles.addButton} onPress={() => {
          this.setState({
            isUpdateClick: true
          }, () => {
            this.onPressStockApproval()
          })
        }}>
          <Text style={[styles.addText]}>Approve Stock</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.addButton, { marginLeft: "0.5vw" }]} onPress={() => {
          this.setState({
            isUpdateClick: false
          })
        }}>
          <Text style={styles.addText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    )
  }
  renderHeaderView = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.05, "0.95vw")}
        {this.getBillTableTitle('Transfer Type', 0.1, "0.95vw")}
        {this.getBillTableTitle('No Of Product', 0.1, "0.95vw")}
        {this.getBillTableTitle('From Branch', 0.1, "0.95vw")}
        {this.getBillTableTitle('To Branch', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST No', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST Date', 0.1, "0.95vw")}
        {this.getBillTableTitle('Tracking Details', 0.12, "0.95vw")}
        {this.getBillTableTitle('Notes', 0.15, "0.95vw")}
        {this.getBillTableTitle('Action', 0.05, "0.95vw")}

      </View>
      // </View>
    )
  }
  renderTableDataView = () => {
    var { current_page, records_per_page } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ zIndex: -1, height: "68vh" }}>
        {this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.length > 0 ?
          this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.map((item, index) => {
            let sno = (current_page - 1) * records_per_page + index + 1;
            let zIndex = (index + 1) * -10;
            let date = item.st_date ? moment(item.st_date).format("DD-MM-YYYY") : "-"

            return (
              <View style={[styles.dataContainer, { marginLeft: "0.5vw", zIndex: zIndex }]}>
                <View style={{ flex: 0.05 }}><Text style={styles.TableDataText}>{sno}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.exchange_type}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.no_of_product}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.from_name}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.to_branch}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.st_no ? item.st_no : "-"}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{date ? date : "-"}</Text></View>

                <View style={{ flex: 0.12 }}>
                    <Text style={styles.TableDataText}>{item.corrier_type ? item.corrier_type : null}</Text> 
                    <Text style={styles.TableDataText}>{item.track_detail ? JSON.parse(item.track_detail) : "-"}</Text> 
                </View>
                <View style={{ flex: 0.15 }}>
                   <Text style={styles.TableDataText}>{item.notes ? item.notes : "-"}</Text> 
                </View>
                <TouchableOpacity style={[styles.addButton, { flex: 0.05 }]} onPress={() => {
                  this.setState({
                    isUpdateClick: true,
                    selectedID: item.id
                  },() => {
                    this.getPopupList(item.id)
                  })
                }}>
                  <View style={{ flexDirection: "row" }}>
                    <Image source={require("../../../../../assets/images/Active/Print.png")} style={styles.PrintIconImg} />
                    <Text style={styles.addText}>Approve</Text>
                  </View>
                </TouchableOpacity>

              </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>
        }

      </ScrollView>
    )
  }
  renderPaginationView = () => {
    return (
      <View>
        {this.state.stockTransferList && this.state.stockTransferList.length > 0 ?
          <Pagination
            paginationChangedValues={this.paginationChangedValues.bind(this)}
            totalItems={this.state.stockTransferList}
            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
          /> : null
        }
      </View>
    )
  }
  renderPopupTextView = (lable, value) => {
    return (
       <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 0.25 }}>
        <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{lable + " : "}</Text>
        { lable === "Stock Trans Date" ?
         <DatePicker
            value={moment(this.state.st_date)}
            onChange={(date) => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              this.setState({ st_date: converted_date });
            }
            }
            clearIcon={false}
            format={"DD-MM-YYYY"}
            placeholder={"YYYY-MM-DD"}
            defaultValue={moment(this.state.st_date, "DD-MM-YYYY")}
            style={{
              zIndex: -1, width: '65%',
              borderColor: '#CDD1D5',
              height: "53%", borderRadius: 4, borderWidth: 1,
              flex: 0.5,
                marginTop: "0vw",
                margin: "0.5vw",
              justifyContent: "center",
            }}
          /> : 
          <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{value}</Text> 
          }
      </View>   
    )
  }
  renderHeaderViews = () => {
    var { popUpHeaderData } = this.state
    return (
      <View>
      <View style={{ flexDirection: "row", flex: 1 }}>
        {this.renderPopupTextView("From Branch", popUpHeaderData.from_branch)}
        {this.renderPopupTextView("To Branch", popUpHeaderData.to_branch)}
        {this.renderPopupTextView("Stock Transfer Value", popUpHeaderData.stock_transfer_value)}
        {this.renderPopupTextView("Stock Transfer Date", this.state.st_date)}
      </View>
      <View style={{ flexDirection: "row" }}>
        {this.renderPopupTextView("Contact Details", popUpHeaderData.from_branch_contact)}
        {this.renderPopupTextView("Contact Details", popUpHeaderData.to_branch_contact)}
        {this.renderPopupTextView("Expected Delivery Date", popUpHeaderData.delivery_date)}
        {this.renderPopupTextView("Stock Transfer No", popUpHeaderData.stock_transfer_no)}
      </View>
    </View>
    )
  }

  render() {
    return (
        <View>
        <NavigationTopHeader
        changeTabSection={this.changeTabSection.bind(this)}
        navigationHeaderList={navigationHeaderAdmin}
        selectedTab={"approvalRequest"}
        isNavigationBorder={true}
      />
      <View style={styles.mainContainer}>
        {this.renderHeaderView()}
        {this.renderTableDataView()}
        {this.renderPaginationView()}
      </View>
      {this.state.isUpdateClick ?
        <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
          <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "75%", height: "75%", borderRadius: 8, borderWidth: 1, }}>
            <View>
              {this.renderHeaderViews()}
              <View style={{ height: "45vh", }}>
                {this.renderPopupHeader()}
                {this.renderTableData()}
              </View>
              {this.renderBottomView()}
            </View>
          </View>
        </View> : null}
    </View>
  );
}
}
const styles = StyleSheet.create({
container: { height: "81vh" },
drugHeaderContainer: {
  flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center", height: '2vh'
},
subContainer: {
  flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: "0.4vw", borderRadius: 4, marginTop: '0.5vw'
},
mainContainer: { margin: "0.5vw", zIndex: 0 },
addText: {
  color: color.white,
  fontSize: "0.8vw",
  marginLeft: "0.4vw"
},
addButton: {
  backgroundColor: color.themeDark,
  borderRadius: 4,
  padding: "0.4vw",
  justifyContent: "center",
  alignItems: "center"
},
dataContainer: {
  flexDirection: 'row',
  borderBottomColor: color.lightGray,
  borderBottomWidth: 1,
  paddingVertical: "0.6vw",
  justifyContent: 'center',
  flex: 1,
  justifyContent: "flex-start",
  alignContent: "center"
},
TableDataText: {
  fontSize: '1vw',
  flexWrap: "wrap"
},
PrintIconImg: {
  height: '1.2vw',
  width: '1.2vw',
  alignSelf: "center"
},
suggestionMain: { padding: "0.5vw", position: "absolute", width: "13vw", marginTop: "3vw", minHeight: "25vh", zIndex: 12, backgroundColor: color.white, borderWidth: 1, borderColor: color.lightGray, marginTop: "2.3vw", borderRadius: "0.3vw" },
searchBoxText: { position: 'absolute', backgroundColor: color.white, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize:"0.8vw" },
textBox: { borderColor: color.lightGray, borderWidth: 1, borderRadius: "0.3vw" , padding:"0.4vw"},
})


