//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton } from '../../BaseComponent'
import { color } from '../../../../styles/Color';
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from "moment";

const pageName="isPapSmearMammogramHistoryNew"

// create a component
class PapSmearMammogramHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment:this.props.patientAppointment,
            patientInfo:this.props.patientInfo,


            checkTreatmentForAbnormalPapSmear: {},
            checkTreatmentForAbnormalPapSmearCommentBox: "",
            checkCryotherapy: {},
            checkCryotherapyCommentBox: "",

            checkLaser: {},
            checkLaserCommentBox: "",
            checkConeBiopsy: {},
            checkConeBiopsyCommentBox: "",

            checkLoopExcision: {},
            checkLoopExcisionCommentBox: "",

            checkAbnormalMammogram: {},
            checkAbnormalMammogramCommentBox: "",

            lastmammogramDate: "",
            lastPapSmearDate: "",

        }
    }


    clearAllValues(){
        var states = this.state;

        states["checkTreatmentForAbnormalPapSmear"]={}
        states["checkCryotherapy"]={}
        states["checkConeBiopsy"]={}
        states["checkLaser"]={}
        states["checkLoopExcision"]={}
        states["checkAbnormalMammogram"]={}

        states["checkTreatmentForAbnormalPapSmearCommentBox"]=""
        states["checkCryotherapyCommentBox"]=""
        states["checkLaserCommentBox"]=""
        states["checkConeBiopsyCommentBox"]=""
        states["checkLoopExcisionCommentBox"]=""
        states["checkAbnormalMammogramCommentBox"]=""

        states["lastmammogramDate"]=""
        states["lastPapSmearDate"]=""
    }

    checResponceIsFlag(type){
        if(type || type=="yes")
            return {label:"Yes",value:"Yes"}
        else
            return {label:"No",value:"No"}
    }

    componentWillReceiveProps(props){

        // console.log("************>> "+JSON.stringify(props))
        if(props && props.editHistoryItem && props.editHistoryItem.pageName == pageName ){
        if(props && props.editHistoryItem && Object.keys(props.editHistoryItem).length > 0){
            
        //    alert("***test *********>> "+JSON.stringify(props.editHistoryItem))
            var states = this.state;
            var getPapSmearHistory = props.editHistoryItem.editItem




        states["checkTreatmentForAbnormalPapSmear"]=this.checResponceIsFlag(getPapSmearHistory.had_abnormal_pap_smears_treatment)
        states["checkCryotherapy"]=this.checResponceIsFlag(getPapSmearHistory.treatment_cryotherapy)
        states["checkConeBiopsy"]=this.checResponceIsFlag(getPapSmearHistory.treatment_cone_biopsy)
        states["checkLaser"]=this.checResponceIsFlag(getPapSmearHistory.treatment_laser)
        states["checkLoopExcision"]=this.checResponceIsFlag(getPapSmearHistory.treatment_leep)
        states["checkAbnormalMammogram"]=this.checResponceIsFlag(getPapSmearHistory.had_abnormal_mammogram)

        states["checkTreatmentForAbnormalPapSmearCommentBox"]=getPapSmearHistory. abnormal_treatment_desc
        states["checkCryotherapyCommentBox"]=getPapSmearHistory.cryotherapy_desc
        states["checkLaserCommentBox"]=getPapSmearHistory.laser_desc
        states["checkConeBiopsyCommentBox"]=getPapSmearHistory.cone_biopsy_desc
        states["checkLoopExcisionCommentBox"]=getPapSmearHistory.leep_desc
        states["checkAbnormalMammogramCommentBox"]=getPapSmearHistory.abnormal_mammogram_desc

        states["lastmammogramDate"]=getPapSmearHistory.last_mammogram_date
        states["lastPapSmearDate"]=getPapSmearHistory.last_pap_smear


        this.setState({states},()=>{
            this.props.editHistory({})
        })
            
        }
    }
        
    }



    onPressButton(key, selectedValue) {
        if (key == "checkTreatmentForAbnormalPapSmear") {
            this.setState({
                checkTreatmentForAbnormalPapSmear: selectedValue,
            })
        } else if (key == "checkCryotherapy") {
            this.setState({
                checkCryotherapy: selectedValue,
            })
        } else if (key == "checkLaser") {
            this.setState({
                checkLaser: selectedValue,
            })
        } else if (key == "checkConeBiopsy") {
            this.setState({
                checkConeBiopsy: selectedValue,
            })
        } else if (key == "checkLoopExcision") {
            this.setState({
                checkLoopExcision: selectedValue,
            })
        } else if (key == "checkAbnormalMammogram") {
            this.setState({
                checkAbnormalMammogram: selectedValue,
            })
        }
    }

    renderRichTextBox(key, values,label = "") {
        return (
            <View>
            {
                label ?
                    <Text style={{color:"#BCBCBC",fontSize:12,fontWeight:'400'}}>{label}</Text> : 
                    <Text style={{color:"#BCBCBC",fontSize:12,fontWeight:'400'}}>{"Details if any"}</Text>
            }
            <TextInput
                value={values}
                placeholder={""}
                maxLength={100}
                multiline={true}
                textAlignVertical="top"
                style={{ marginTop: 5, marginBottom: 20, textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 65 ,shadowOpacity: 0.25,
                shadowRadius: 3.84,
    
                elevation: 5,        shadowColor: "#000",
            }}
                onChangeText={(text) => {
                    var statets = this.state;
                    statets[key] = text
                    this.setState({ statets })
                }}
            />
            </View>
        )
    }


    renderDatePicker(label, key, value) {
        var states = this.state;

        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 10 }}>{label}</Text>
                <DatePicker
                 disabledDate={current => {
                    return current && current > moment().add(0, "days");
                  }}
                    clearIcon={false}
                    onChange={date => {
                        // var states = this.state;
                        //   var fulldate = new Date(date);
                        //   var converted_date = moment(fulldate).format("YYYY-MM-DD");
                        states[key] = date
                        this.setState({ states })
                    }}
                    // defaultValue={""}
                    placeholder={""}
                    format={"DD-MM-YYYY"}
                    value={value ?
                        moment(value, "YYYY-MM-DD") :
                        value
                        // states[key]
                        //      ?
                        //   moment(states[key]) : 
                        //   this.state.mandatoryInformation.dob 
                    }
                    style={{ backgroundColor: 'transparent', borderColor: color.themeDark ,borderWidth:1}}
                />
            </View>
        )
    }


    checkMenstrualHistory(label, key, selectedValue) {
        var data = [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ]

        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 10 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    onPressAddButton() {

        var states = this.state;

        var data = {
            "patient_id":this.state.patientAppointment.patient_id,
            "appointment_id":this.state.patientAppointment.appointment_id,
            "pap_mammogram_history": {

                "had_abnormal_pap_smears_treatment": states.checkTreatmentForAbnormalPapSmear.value == "Yes" ? true : false,
                "treatment_cryotherapy":  states.checkCryotherapy.value == "Yes" ? true : false,
                "cryotherapy_desc": states.checkCryotherapyCommentBox,
                "treatment_laser": states.checkLaser.value == "Yes" ? true : false,
                "laser_desc": states.checkLaserCommentBox,
                "abnormal_treatment_desc":states.checkTreatmentForAbnormalPapSmearCommentBox,
                "treatment_cone_biopsy": states.checkConeBiopsy.value == "Yes" ? true : false,
                "cone_biopsy_desc": states.checkConeBiopsyCommentBox,
                "treatment_leep":  states.checkLoopExcision.value == "Yes" ? true : false,
                "leep_desc": states.checkLoopExcisionCommentBox,
                "is_last_mammogram": states.checkAbnormalMammogram.value == "Yes" ? true : false,               //
                "last_mammogram_date":  moment(states.lastmammogramDate).format("YYYY-MM-DD"),
                "had_abnormal_mammogram": states.checkAbnormalMammogram.value == "Yes" ? true : false,          //
                "abnormal_mammogram_desc": states.checkAbnormalMammogramCommentBox,
                "last_pap_smear": moment(states.lastPapSmearDate).format("YYYY-MM-DD"),
            }
        }

        //console.log("PapMammogramHistory -************> "+JSON.stringify(this.state.patientAppointment.patient_id));

        var service_url=Constants.PAP_HISTORY
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.savePapMammogramHistorySuccess,
            this.savePapMammogramHistoryFailure
          );
    }

    savePapMammogramHistorySuccess = success =>{
        if(success.status == "success"){
            this.props.showResposeValue('success',success.message);
            this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        }else{
            this.props.showResposeValue('error',success.message)
        }
    }
    savePapMammogramHistoryFailure = error =>{
        this.props.showResposeValue('error',error.message)
    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true} heading={"Pap Smear / Mammogram History"} />
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.renderDatePicker("Date of last pap smear", "lastPapSmearDate", this.state.lastPapSmearDate)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Have you ever had any treatment for an abnormal pap smear?", "checkTreatmentForAbnormalPapSmear", this.state.checkTreatmentForAbnormalPapSmear)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.renderRichTextBox("checkTreatmentForAbnormalPapSmearCommentBox", this.state.checkTreatmentForAbnormalPapSmearCommentBox,"Details")}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Cryotherapy", "checkCryotherapy", this.state.checkCryotherapy)}

                {this.renderRichTextBox("checkCryotherapyCommentBox", this.state.checkCryotherapyCommentBox,"Details")}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Laser", "checkLaser", this.state.checkLaser)}
                {this.renderRichTextBox("checkLaserCommentBox", this.state.checkLaserCommentBox,"Details")}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Cone Biopsy", "checkConeBiopsy", this.state.checkConeBiopsy)}
                {this.renderRichTextBox("checkConeBiopsyCommentBox", this.state.checkConeBiopsyCommentBox,"Details")}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Loop Excision(LEEP)", "checkLoopExcision", this.state.checkLoopExcision)}
                {this.renderRichTextBox("checkLoopExcisionCommentBox", this.state.checkLoopExcisionCommentBox,"Details")}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.renderDatePicker("Date of last mammogram", "lastmammogramDate", this.state.lastmammogramDate)}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>
                {this.checkMenstrualHistory("Have you had an abnormal mammogram?", "checkAbnormalMammogram", this.state.checkAbnormalMammogram)}
                {this.renderRichTextBox("checkAbnormalMammogramCommentBox", this.state.checkAbnormalMammogramCommentBox)}

</View>
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});

//make this component available to the app
export default PapSmearMammogramHistoryNew;
