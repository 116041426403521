import React from "react";
import {
  View,
  Text,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton } from "../BaseComponent";
// import { Style } from "@material-ui/icons";
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "isSocial";

var Messages=require('../../../utils/InfoMessages')


export default class SocialHistory extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedInhouseTreatment: "",
      selectedDrug: [],
      selectedAlcohol: {},
      selecteddailyActivity: {},
      selectedListSmoking: {},
      selectedMarried: {},
      selectedExercise: {},
      selectedoccupation: {},

      favoriteInhouse: [],
      ListAlcohol: [
        "Occassionally",
        "Social only",
        "Frequently"
      ],
      dailyActivity: [
        "Sitting most of the day",
        "Some activity daily",
        "Very active daily"
      ],
      ListSmoking: [ "Occassionally", "1-2 cigarettes per day", "1/2 pack per day", "1 pack per day" ],
      Married: [
        "Yes",
        "No"
      ],
      Exercise : ["Yes", "No"],
      occupation : "",
      ListDrug: [
        "marijuana",
        "Cocaine",
        "LSD",
        "Intravenous drugs"
      ],
      complaints: "",
      description: "",
      isInhouseTreatment: false,
      isProcedure: false,
      newItem: "",
      route: "",
      dosage: "",
      newHistory: "",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,

      isInHouseID: "",
      isProcedureID: "",
      errorMessageFlag: false,

      getSelectedPropsValue:[],
      unSelectedMedicalHistory:[]
    };
  }

  

  componentWillReceiveProps(props) {

    
    let { id } = this.state;

    if(props.editItem &&  props.editItem.item && props.editItem.item.length > 0 ){
      if (props.editItem.title === pageName) {
     
      var newitem = props.editItem.item;
      if (id !== newitem[0].id) {
        
        var drugs = newitem[0].drugs ? newitem[0].drugs.split(','):[];

        var getArrayOfDrugs=[]

        if(drugs.length > 0){
          for (let i = 0; i < drugs.length; i++) {
            var prepareData={label:drugs[i],value:drugs[i]}
            getArrayOfDrugs.push(prepareData);
          }
        }



        // console.log("************>>>>>"+JSON.stringify(newitem))
        var wasMarried = newitem[0].is_married == true ? "Yes" : "No"
        var willExercise = newitem[0].is_exercise_done_daily == true ? "Yes" : "No"
        this.setState({
          id: newitem[0].id,
          selectedAlcohol : {label: newitem[0].alcohol,value: newitem[0].alcohol} ,
          selectedListSmoking: {label: newitem[0].smoking,value: newitem[0].smoking} ,
          // selectedDrug: {label: newitem[0].drugs,value: newitem[0].drugs} ,
          selectedDrug: getArrayOfDrugs ,
          selecteddailyActivity:{label:  newitem[0].daily_activity,value:  newitem[0].daily_activity},
          selectedMarried :{label:wasMarried,value: wasMarried} ,
          selectedExercise:{label: willExercise,value: willExercise},
          occupation : newitem[0].occupation
          
        },()=>{
          this.props.clearEditedItem(pageName,"")
        })
    }}
  }
  }

  getSocialHistory = () => {
    var service_url = Constants.MEDICAL_HISTORY_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSocialHistorySuccess,
      this.getSocialHistoryFailure
    );
  };

  getSocialHistorySuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      // field["favoriteInhouse"] = response.data;
      // field["ListDrug"] = response.data;
      this.setState({ field });
    }
  };

  getSocialHistoryFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {
    let states = this.state;

    states["isEdit"] = true;

    this.setState({ states });
  };

 

  addSocialHistoryList = (event) => {
    let { newHistory, ListDrug } = this.state;
    let field = this.state;

    if (newHistory) {
      ListDrug.push(newHistory);

      field["ListDrug"] = ListDrug;
      field["selectedDrug"] = { value: newHistory, label: newHistory };
      field["newHistory"] = "";
      field["isProcedure"] = false;

      this.setState({ field });
    }
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  };

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states,
    });
  };



  triggerNewHistoryadd = () => {
    let states = this.state;

    states["isProcedure"] = true;

    this.setState({ states });
  };

  addSocial = (event) => {
    let states = this.state;
    var service_url = Constants.POST_SOCIAL_HISTORY;

    var isMarried = this.state.selectedMarried.value == "Yes" ? true : false
    var isExercise = this.state.selectedExercise.value == "Yes" ? true : false
    // selectedDrug

    var selectedDrugValues=[];

    if(this.state.selectedDrug.length > 0){
      for (let i = 0; i < this.state.selectedDrug.length; i++) {
       
        selectedDrugValues.push(this.state.selectedDrug[i].value);
      }
    }

    let data = {
      id : this.state.id,
      patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.patientAppointment.appointment_id,
      alcohol: this.state.selectedAlcohol.value,
      smoking: this.state.selectedListSmoking.value,
      drugs:selectedDrugValues.toString(),
      daily_activity:this.state.selecteddailyActivity.value,
      is_married: isMarried,
      is_exercise_done_daily: isExercise,
      occupation: this.state.occupation,
      // comments: "Normal"
    };

 

    if (this.state.selectedAlcohol.value || this.state.selectedListSmoking.value || this.state.selectedDrug.value || this.state.selecteddailyActivity.value) {
      // console.log(JSON.stringify(data))
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.postSocialSuccess,
        this.postSocialFailure
      );
    }else{
      this.props.showResposeValue("error",Messages.SocialHistoryEmptyField);
    }
  };

  postSocialSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  postSocialFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = (event) => {
    let states = this.state;
    states["selectedAlcohol"] = {},
    states["selectedListSmoking"] ={}
    states["selectedDrug"] = {}
    states["selecteddailyActivity"] = {}
    states["selectedMarried"] = {}
    states["selectedExercise"] = {}
    states["occupation"] = ""
    states["id"] = ""
    this.setState({ states });
  };

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  onPressButton(key, selectedvalue) {
   if (key == "selectedDrug") {
    var { selectedDrug } = this.state;

    var getSelectedValue;
    var spliceData;

    if (selectedDrug.length > 0) {
      for (var i = 0; i < selectedDrug.length; i++) {
        if (selectedDrug[i].value == selectedvalue.value) {
          spliceData = selectedDrug[i];
          selectedDrug.splice(i, 1)
          var data = this.removeDuplicate(selectedDrug);
          this.setState({ selectedDrug: data })
          return
        } else {
          if (spliceData && spliceData.value !== selectedvalue.value) {
          } else {
            getSelectedValue = selectedvalue
          }
        }
      }

      if (getSelectedValue) { selectedDrug.push(selectedvalue); }
    } else {
      selectedDrug.push(selectedvalue)
    }

    var data = this.removeDuplicate(selectedDrug);
    this.setState({ selectedDrug: data })

    }
  
   
   
  //  {
     
  //   this.setState({ selectedDrug: selectedvalue })
  //   }
    
    else if(key == "selectedAlcohol"){
      this.setState({selectedAlcohol : selectedvalue})
    }else if(key == "selectedListSmoking"){
      this.setState({selectedListSmoking : selectedvalue})
    }else if(key == "selecteddailyActivity"){
      this.setState({selecteddailyActivity : selectedvalue})
    }
    else if(key == "selectedMarried"){
      this.setState({selectedMarried: selectedvalue})
    }else if(key == "selectedExercise"){
      this.setState({selectedExercise : selectedvalue})
    }
    
  }


  render() {
   

    return (
      <TouchableWithoutFeedback onPress={this.addSocialHistoryList.bind(this)}>
        <View>
        <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Alcohol",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>
         
          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.ListAlcohol.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedAlcohol}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedAlcohol"}
                  />
                </View>
              );
              }
            })}
          </View>


          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Smoking",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>
         
          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.ListSmoking.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedListSmoking}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedListSmoking"}
                  />
                </View>
              );
              }
            })}
          </View>




          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Drugs",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>
         
          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.ListDrug.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedDrug}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedDrug"}
                  />
                </View>
              );
              }
            })}
          </View>
          {this.state.isProcedure ? (
            <View
              style={[{
                height: 30,
                marginTop: 10,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,
              },Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={{
                  color: color.black,
                  height: 30,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                }}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newHistory}
                onChangeText={(text) => {
                  this.setState({ newHistory: text });
                }}
                onSubmitEditing={this.addSocialHistoryList.bind(this)}
              />
            </View>
          ) : (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewHistoryadd.bind(this),
              this.state.isLocked
            )
          )}

          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Rate your daily activity",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>

          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.dailyActivity.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selecteddailyActivity}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selecteddailyActivity"}
                  />
                </View>
              );
              }
            })}
          </View>


        <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Married",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>

          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.Married.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedMarried}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedMarried"}
                  />
                </View>
              );
              }
            })}
          </View>


          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Do you Excerise daily?",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>

          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.Exercise.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedExercise}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedExercise"}
                  />
                </View>
              );
              }
            })}
          </View>


        <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 20, color: color.black }}>Occupation</Text>
        </View>

          <View style={{ marginTop: 30 }}>
            {this.renderTextFieldView(
              "Occupation",
              "occupation",
              this.state.occupation,
              true,
              1,
              '',{},'',false,this.state.isLocked
            )}
          </View>
          <View style={{ marginTop: 10, zIndex: -1 ,alignSelf:"center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addSocial.bind(this)}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
