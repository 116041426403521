import React from "react";
import {
  View,
  Text,
  // FlatList,
  Image,
  Platform,
  TextInput,
  TouchableOpacity,
  Picker,
  TouchableWithoutFeedback,
  ScrollView,
  StyleSheet
} from "react-native";
// import {  } from 'react-native-paper';
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton ,CommonSectionHeader} from '../BaseComponent'
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "examination";
var Messages=require('../../../utils/InfoMessages')

const toastConfig = {
  'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{ color: "white", textAlign: "center", justifyContent: "center", marginTop: "9px" }}>Complaint Created Successfully</Text>
    </View>
  ),
  'error': () => { },
  'info': () => { },
  'any_custom_type': () => { }
}

const krish = Constants.Examination
export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      disableButton : false,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: "",
      selectedTemplate: "",
      favoriteDrugs: [],
      examinationArr: [],
      isEdit: false,
      newItem: '',
      selectedDuration: "",
      selectedExamination_id: "",
      comments: "",
      editBtn: false,
      isTemplate: false,
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      data: {
        id: "",
        favorite_name: "",
        status: ""
      },
      Templadata: [],
      isDisable:false,
      selectedDisplayOrder: "",
      isEdit: false,
      examinationSuggestion: [],
      groupName: "",
      groupID: "",
      selectesExamination: {},
      selectedGroup : "",
      examinationSearchData: []
    };
  }




  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getExaminationName()
    this.getExaminationTemplate()
    this.getExaminationNewSuggesion()
  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;
        // this.getExaminationTemplate()
   // console.log(JSON.stringify(props.editItem.item))
    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
        
        // console.log("componentWillReceiveProps>>>>>>>>>>"+JSON.stringify(item))


        if (id !== item.id) {
          let selectedEye = "";

          let isDisable = props.editItem.isDisable;

          // favoriteDrugs.map((favItem) => {
          //   if (favItem.label === item.symptom) {
          //     selectedEye = favItem.value;
          //   }
          // })
          this.setState({
            id: item.id,
            selectedEye: item.examination_name,
            comments: item.value,
            selectedExamination_id:item.examination_id,
            selectedDisplayOrder:item.display_order,
            isDisable:isDisable,
            // disableButton: true,

          });
        }
      }
    }
    if (props.reloadTemplate && props.reloadTemplate === pageName) {
      // call once
      this.getExaminationTemplate();
      // refresh false
      this.props.clearReloadTemplate();
    }
  }
  getExaminationNewSuggesion = () => {
    var service_url = Constants.EXAMINATION_NEW_SUGGESION;  
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationNewSuggesionSuccess,
      this.getExaminationNewSuggesionFailure
    );
  };

  getExaminationNewSuggesionSuccess = response => {
    if (response.status == "Success") {
     this.setState({
      examinationSuggestion : response.data
     })
    }
  };
  getExaminationNewSuggesionFailure = error => {
    // console.log("opthamology complaints GET error response");
     console.log(error);
   };
   getExaminationNameSuggestion = () => {

    var service_url = Constants.EXAMINATION_GET_EXAMINATION_NAME + "?name=" + this.state.newItem;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationSuggessionSuccess,
      this.getExaminationSuggessionFailure
    );
  };

  getExaminationSuggessionSuccess = response => {
    if (response.status === "success") {
     
      this.setState({ 
        examinationSearchData : response.data
      })
    } 
  };

  getExaminationSuggessionFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  getExaminationName = () => {

    var service_url = Constants.EXAMINATION_GET_EXAMINATION_NAME 

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationNameSuccess,
      this.getExaminationNameFailure
    );
  };

  getExaminationNameSuccess = response => {
    if (response.status === "success") {
      // alert(JSON.stringify(response.data.examinations))

      var examinationArr = []

      var field = this.state;
      field["favoriteDrugs"] = response.data ? response.data : [];

    //  console.log(")))))))))))))))))))))>> " + JSON.stringify(response))
      
      this.setState({ 
        field,
       }, () => {
        var examinationdata = this.state.favoriteDrugs
        for (var i = 0; i < examinationdata.length; i++) {
          var data = {
            "label": examinationdata[i].examination_name,
            "value": examinationdata[i].examination_name,
            "id": examinationdata[i].examination_id,
            "display_order": examinationdata[i].display_order,
            "examination_id": examinationdata[i].examination_id,
          }
          examinationArr.push(data)
        }
        this.setState({
          examinationArr: examinationArr
        })
      });

    }
  };

  getExaminationNameFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  AddExaminationList = () => {

  }
  addMedicationToList = (index, event) => {


    // this.onchangexaminationLabel(this.state.newItem, index)
    let { newItem, examinationArr } = this.state;
    let field = this.state;

    if (newItem ) {
      var name = this.state.examinationArr.find(item => item.value === newItem);
      if( name )  { 

      field["examinationArr"] = examinationArr;
      field["selectedEye"] = newItem;
      field['id'] = name.id;
      field['selectedExamination_id'] = name.examination_id
      field["newItem"] = ""
      field["isEdit"] = false;

      this.setState({ field },()=>{

      });
     } else {
      examinationArr.push({ value: newItem, label: newItem });
      field["examinationArr"] = examinationArr;
      field["selectedEye"] = newItem;
      field["newItem"] = ""
      field["isEdit"] = false;

      this.setState({ field })
     }
    } 
  }





  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue, index,isDisable) => {
    // console.log("*********************** >>" +JSON.stringify(item))
    return (
      <View>
        {this.state.editBtn && this.state.selectedEye == item.value ?
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ width: "80%" }}>
              <TextInput
                underlineColorAndroid="transparent"
                mode='outlined'
                label={item.label}
                // onSubmitEditing={() => this.textInputRef.blur()}
                selectionColor={item.label}
                theme={{ colors: { placeholder: color.black, text: color.themeBlue, primary: 'black', underlineColor: 'white', background: color.themeShadeBackground, borderColor: color.themeBlue }, roundness: 20 }}
                style={styles.SelectedEyeTextInput}
                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newItem}
                onChangeText={text => {
                  this.setState({ newItem: text })

                }}
                onSubmitEditing={this.addMedicationToList.bind(this, index)}

              />
            </View>
            <View style={{ width: "10%" }}>
              <TouchableOpacity onPress={() => {
                var data =
                {
                  "examination_id": item.id,
                  "alias_name": this.state.newItem
                }

                this.addExaminationAlias(data)
              }} >
                <Image source={require("../../../../assets/images/tickicon (9).svg")} style={styles.SelectedImg} />
              </TouchableOpacity>

            </View>
            <View style={{ width: "10%" }}>
              <TouchableOpacity onPress={() => {

                this.setState({
                  editBtn: false
                })
              }}>
                <Image source={require("../../../../assets/images/Close button.svg")} style={styles.SelectedImg} />
              </TouchableOpacity>

            </View>


          </View> :
          <SelectedButton
            //   {...otherProps}
            isDisable={isDisable}
            item={item}
            fill={fill}
            borderNeeded={borderNeeded}
            onPressItem={this.onPressAction.bind(this, selectedKey)}
            selected={selectedValue}

          />
        }
      </View>

    );
  }

  onchangexaminationLabel = (value, index) => {
    var { examinationArr } = this.state
    examinationArr[index].label = value,
      examinationArr[index].value = value
    this.setState({
      examinationArr
    })
  }

  onPressAction = (key, value, label, id, item) => {

 

    let states = this.state;
    states[key] = value;

    if (key == "selectedEye") {
      this.setState({
        selectedDisplayOrder: item.display_order
      })
    }

    this.setState({
      states,
      selectedExamination_id: id
    })


  }

  getExaminationTemplate = () => {
    var service_url = Constants.EXAMINATION_FAVORITE_NAME;  
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getExaminationTemplateSuccess,
      this.getExaminationTemplateFailure
    );
  };

  getExaminationTemplateSuccess = response => {
    if (response.status === "success") {
      var data = this.state;
      data["Templadata"] = response.data;
      this.setState({ data });


    }
  };


  getExaminationTemplateFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };


  saveTemplate = event => {


    let states = this.state;
    var service_url = Constants.EXAMINATION_FAVORITE_NAME;

    let data = {
      "favorite_name": states.template_name,
      examinations: [
        {
          "examination_id": this.state.selectedExamination_id,
          "value": this.state.selectedEye,
        }
      ]
    };



    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getTemplateSuccess,
      this.getTemplateFailure,

    );

  };

  getTemplateSuccess = response => {
    if (response.status === "success") {
      // var field = this.state;
      // field = response.data;
      // this.setState({
      //   field,
      // });

      // clear data
      this.clearComplaint();
      this.getExaminationTemplate()
      // reload data
      this.props.refreshData(pageName);
    }
  };

  getTemplateFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };



  addExaminationAlias = (data) => {


    var service_url = Constants.EXAMINATION_ALAIS;



    // if (states.selectedEye) {

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addExaminationAliasSuccess,
      // this.getSymptomFailure
    );
    // }
  };

  addExaminationAliasSuccess = response => {

    if (response.status === "success") {


      // this.showSuccessMessage();
      // // clear data
      // this.clearComplaint();

      // // reload data
      // this.props.refreshData(pageName);

    }


  };

  addExaminationAliasSuccessFailure = error => {
    this.showErrorMessage();
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };




  addExamination = event => {


    var service_url = Constants.EXAMINATION_CREATE;



    let data = {
      "id" : this.state.id ? this.state.id : null,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "examination_id": this.state.selectesExamination.id ? this.state.selectesExamination.id : this.state.selectedExamination_id,
      "value": this.state.comments,
      "display_order": this.state.selectedDisplayOrder ? this.state.selectedDisplayOrder : 1
    };
    // if () {
    //   data["patient_examination_id"] = this.state.id
    // }

    // if (states.selectedEye) {
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getExaminationSuccess,
      this.getExaminationFailure
    );
  // }else{
  //   this.props.showResposeValue("error",Messages.SelectExamination)
  // }
    // }
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getExaminationSuccess = response => {

    if (response.status === "success") {


      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
      this.props.showResposeValue("success", response.message)


    }else{
      this.props.showResposeValue("error", response.message)

    }


  };

  getExaminationFailure = error => {
    this.props.showResposeValue("error", error.message)

  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = "";
    states['complaints'] = "";
    states['disableButton'] = false;
    states['selectedDuration'] = "";
    states['comments'] = "";
    states["id"]=""
    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  renderNewTemplateAdd = () => {

    return (
      <View style={styles.NewTemplateView}>
        <TextInput
          placeholder="Template Name"
          underlineColor='white'
          style={styles.NewTemplateTextInput}
          onChangeText={text => this.setState({ template_name: text })}
          value={this.state.template_name}
        />

        <View style={{ marginTop: '3.3vw', flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.saveTemplate()
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Cancel", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </View>

    )

  }

  renderSelectTemplate = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        disable={this.state.isLocked}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressTemplate.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressTemplate = (key, value ,label, id, item) => {
    let states = this.state;
    states[key] = value;

    // let {data} = this.state;
    // data['id'] = value;

    this.setState({
      states
    },()=>{
      this.props.reloadFavouriteID(pageName);
      this.props.examinationFavouriteid(item.value)

    })

    //this.getTreatmentTemplateselect(value);
  }
  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }
  onPressSaveExamination = () => {
    var service_url = Constants.EXAMINATION_NAME_POST;

    let data = {
      "examination_name": this.state.newItem.toUpperCase(),
      "group_id": this.state.selectedGroup,
      "display_order": 1
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.onPressSaveExaminationSuccess,
      this.onPressSaveExaminationFailure,

    );
  }
  onPressSaveExaminationSuccess = (response) => {
       if(response.status == 'Success') {
        this.addMedicationToList()
        this.props.showResposeValue("success", response.message)
        this.setState({
           selectesExamination : response.data
        })
       } else {
        this.props.showResposeValue("error", response.message)

       }
  }
  onPressSaveExaminationFailure = (error) => {
    this.props.showResposeValue("error", error.message)
   
  }
  renderGroupDropDown = () => {
    return(
      <View style= {styles.GroupDropDownView}>
      
                   { this.state.examinationSuggestion && this.state.examinationSuggestion.length > 0 ?
                    <View>
                             <Picker
                               enabled={!this.state.isLocked}
                               selectedValue={this.state.durationMode}
                               style={[Style.pickerRoundCornerView, Style.allButtonBorderRadius, { height: '2.3vw', marginRight: '0.6vw', width: '10.5vw' }]}
                               itemStyle={Style.pickerFont}
                               onValueChange={(itemvalue) => {

                                this.setState({
                                  selectedGroup: itemvalue,
                                })
                               }}
                             >
                                <Picker.Item label="Group Name" value= "" />
                             { this.state.examinationSuggestion && this.state.examinationSuggestion.length > 0 && this.state.examinationSuggestion.map((item, index) => {
                              return <Picker.Item label={item.name} value={item.id} />
                             })}
                             </Picker>
                    </View> : null
                   }
      </View>
    )
  }
  render() {
    var templateArr = [];
    var temp = {}


    var templatedata = this.state.Templadata;

    templatedata && templatedata.length > 0 && templatedata.map(item => {

      temp = {
        value: item.id,

        label: item.favorite_name

      }
      // remove extra white space
      let favorite_name = (item?.favorite_name)?.toString().replace(" ","");
      {
        favorite_name && favorite_name !== null ?

          templateArr.push(temp)
          : null
      }
    });




    return (

      <TouchableWithoutFeedback onPress={()=>{
        this.setState({
          examinationSearchData: []
        })
      }}>
        <View>
          {this.renderSubHeadingBorder("Examination",false,true)}

        {this.state.isTemplate === true ? <View>{this.renderNewTemplateAdd()}</View> :
          <View >
             <CommonSectionHeader heading={"Templates"}  />

            {/* {this.renderSubHeadingBorder("Templates", true, true)} */}



            <View style={styles.SelectTemplateView}>
              {
                templateArr && templateArr.map((item, index) => {
                  return this.renderSelectTemplate(item, false, false, 'selectedTemplate', this.state.selectedTemplate, index)
                })
              }
            </View>

            <View style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <View style={{ width: "100%",marginBottom:'0.6vw' }}>
              <CommonSectionHeader heading={"Examination"}  />
                {/* {this.renderTitleWithMultipleBtn("Examination", false, 1, false, 'selectedApplanation',)} */}
              </View>
              {/* <View style={{ width: "20%", marginBottom: 25,marginTop:10 }}>
                <TouchableOpacity onPress={() => { this.setState({ editBtn: !this.state.editBtn }) }}>
                  <Text>Edit</Text>
                </TouchableOpacity>
              </View> */}
            </View>



            <View style={styles.SelectedEyeView}>
              {
                this.state.examinationArr && this.state.examinationArr.map((item, index) => {



                  return this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye, index,this.state.isLocked || this.state.id)
                })
              }
            </View>


            {this.state.disableButton ? null : this.state.isEdit ?
              <View
                style={[{
                  height: '2vw',
                  zIndex: 1,
                  margin: '0.6vw'
                }, Style.allButtonBorderRadius]}
              >
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <View style={{ flex: 0.5, height: '3.3vw', }}>
                    <TextInput
                      underlineColorAndroid="transparent"
                      placeholder="Examination Name"
                      placeholderTextColor={color.lightGray}
                      style={[styles.SelectedTextInput, {color: color.black, borderColor: color.lightGray}]}
                      autoCapitalize="none"
                      value={this.state.newItem}
                      onChangeText={text => {
                        this.setState({ selectedAllergyName: '', newItem: (text).toUpperCase() }, () => {
                          this.getExaminationNameSuggestion()
                        })
                      }}
                    />
                   { this.state.newItem.length > 0 && this.state.examinationSearchData.length > 0 ?
                    <View style={{ zIndex: 4, position: "absolute" }}>
                      <ScrollView showsVerticalScrollIndicator={false} style={[styles.ScrollViewStyle, {borderColor: color.lightGray, backgroundColor: color.white}]}>
                        { this.state.examinationSearchData && this.state.examinationSearchData.map((item, index) => {
                          return (
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                  newItem : item.examination_name
                                },()=>{
                                  this.setState({
                                    examinationSearchData: []
                                  })
                                  this.addMedicationToList()
                                })
                            }} style={{ marginLeft: '1.2vw', zIndex: 40 }}>

                              <Text style={{ zIndex: 6 , fontSize: '0.9vw'}}>{item.examination_name}</Text>
                            </TouchableOpacity>
                          )
                        })}
                      </ScrollView>
                    </View> : null
                   }
                  </View>
                  <View style={{ flex: 0.4, height: '3.3vw' }}>
                    {this.renderGroupDropDown()}
                  </View>
                  <TouchableOpacity
                    style={[styles.ExaminationTouch, { backgroundColor: color.themeDark, borderColor: color.black}]}
                    onPress={() => {
                      this.onPressSaveExamination()
                    }}

                  >
                    <Text style={{ color: color.white, fontSize: '0.9vw' }}>{"Add"}</Text>
                  </TouchableOpacity>
                </View>
                {(this.state.isAllergySuggestionView && this.state.allergySuggestionList && (this.state.allergySuggestionList).length > 0) ?
                  <View style={[styles.ExaminationItemView, {backgroundColor: color.whiteSmoke, borderColor: color.white}]}>
                    <FlatList
                      data={this.state.allergySuggestionList}
                      renderItem={({ item }) =>
                        <TouchableOpacity
                          style={{ paddingVertical: '0.2vw' }}
                          onPress={() => {
                            this.setState({ newItem: (item).toUpperCase(), allergySuggestionList: [] }, () => {
                              this.addMedicationToList()
                            })
                          }}
                        >
                          <Text style={{fontSize: '0.9vw'}}>{item}</Text>
                        </TouchableOpacity>
                      }
                    />
                  </View>
                  : null
                }
              </View> :
              <View style={{ margin: '0.3vw', }}>
                {this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this), this.state.isLocked)}
              </View>}
            {/* {this.renderTextFieldView("Comments")} */}
            <View
              style={styles.SubHeadView}
            >
              <CommonSectionHeader heading={"Description (Allow only 250 characters)"} />
              {/* <TextInput
                placeholder={"Comments"}
                mode="flat"
                underlineColor='white'
                style={{
                  height: 80,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: '#B3D9FE',
                  primary: "white",
                  borderRadius: 10
                }}

                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.comments}
                onChangeText={text => this.setState({ comments: text })}
              /> */}
              <View style={styles.CommentsView}>
                {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4, '', {}, '', false, this.state.isLocked, 250)}
              </View>


            </View>

            <View style={{ marginTop: '1.2vw', alignSelf: "center" }}>
              <TouchableOpacity disabled={this.state.isLocked} onPress={this.addExamination.bind(this)} >
                {this.renderTextBtn("Add", true, false)}
              </TouchableOpacity>
            </View >
            {/* <View style={{ marginTop: 20, zIndex: -1 }}>
              <TouchableOpacity
                onPress={() => this.setState({ isTemplate: true })}
              >
                {this.renderTestIconBtn("Add to Templates", "", true)}
              </TouchableOpacity>
            </View> */}
            <View>
              {this.state.show ? (
                <View style={{ height: '2.5vw', width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={{
                    color: "white", textAlign: "center", justifyContent: "center", fontSize: '1vw'
                  }}>Symptom Created Successfully</Text>
                </View>
              ) : null}
            </View >
            <View>
              {this.state.successOrErrorMessage ? (
                <View style={{ height: '2.5vw', width: '100%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={{
                    color: "white", textAlign: "center", justifyContent: "center"
                  }}>
                    {
                      this.state.successMessageFlag ? 'Symptom Created Successfully' :
                        this.state.errorMessageFlag ? 'Symptom History Failed' : null}</Text>
                </View>
              ) : null}
            </View >

          </View>
        }
        </View>
      </TouchableWithoutFeedback>
    );
  }
}const styles = StyleSheet.create({
    NewTemplateView: { 
      marginTop: '3.3vw' 
    },
    NewTemplateTextInput: {
      height: '2.5vw',
      backgroundColor: 'white',
      borderWidth: 1,
      borderColor: '#B3D9FE',
      primary: "white",
      borderRadius: '0.6vw',
      fontSize: '1vw'
    },
    SelectTemplateView: { 
      flexDirection: 'row', 
      flexWrap: 'wrap',
      marginTop:'0.6vw',
      marginHorizontal:'1.2vw' 
    },
    SelectedEyeView: { 
      flexDirection: 'row', 
      flexWrap: 'wrap',
      marginHorizontal:'1.2vw' 
    },
    SelectedEyeTextInput: { 
      height: '2vw', 
      opacity: .9, 
      width: "100%", 
      fontSize: '0.6vw' 
    },
    SelectedImg: { 
      height: '1.2vw', 
      width: '1.2vw', 
    },
    SelectedTextInput: {
      paddingLeft: '0.3vw',
      textAlign: "center",
      borderWidth: 1,
      borderRadius: 4,
      width: "80%",
      margin: '0.3vw',
      height: '2vw',
      fontSize: '0.9vw'
    },
    ScrollViewStyle: { 
      marginTop: '2vw', 
      maxHeight: '14.3vw', 
      width: '14.3vw', 
      borderRadius: 5, 
      marginLeft: '0.6vw', 
      borderWidth: 1, 
    },
    ExaminationTouch: {
      flex: 0.1,
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 1,
      borderRadius: 4,
      marginLeft: '0.5vw',
      margin: '0.3vw',
      height: '2vw'
    },
    ExaminationItemView: { 
      position: 'absolute', 
      width: "80%", 
      marginTop: '2vw', 
      padding: '0.6vw', 
      maxHeight: '11.5vw', 
      borderRadius: 4,
      borderWidth: 2 
    },
    SubHeadView: {
      marginTop: '0.6vw',
      marginBottom: '0.6vw'
    },
    CommentsView: { 
      marginTop: '0.6vw', 
      marginHorizontal: '1.2vw' 
    },
    GroupDropDownView: { 
      marginHorizontal : '1vw', 
      marginTop: '0.1vw' 
    }
})