//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput, TouchableOpacity, Platform, FlatList, CheckBox, Picker } from 'react-native';
const screenHeight = Dimensions.get("window").height;
import { color } from "./../../../styles/Color";
import Style from "./../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import Cancel from "react-native-vector-icons/Entypo";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { DatePicker, TimePicker, Tooltip } from "antd";
import moment from "moment";
var Messages = require('../../../utils/InfoMessages')

import Service from "../../../network/Service"

import { CommonSectionHeader, CommonHistorySectionHeader, CommonAddButton, CommonButton } from '../BaseComponent'
import { SatelliteSharp } from '@material-ui/icons';
import { jssPreset } from '@material-ui/core';
import AsyncStorage from '../../../AsyncStorage';
import { object } from 'yup';
// create a component
export class AdmissionDetails extends Component {

    constructor(props) {
        super(props);
        this.reference=React.createRef(),
            this.state = {
            isIpPatientCheckouted:this.props.isIpPatientCheckouted,
                scrollPosition: 0,
                patientAppointment: this.props.patientAppointment,
                patientInfo: this.props.patientInfo,

                admissionDate: moment(new Date()).format("YYYY-MM-DD"),
                actualAdmissionTime: new Date(),
                admissionTime: new Date(),
                patientName: "",
                saveDisable: false,
                patientAccountNo: "",
                hcid: "",
                ipNumber: "",

                wardName: "",
                floorNumber: "",
                blokName: "",

                roomNumber: "",
                originalRoomNumber: "",
                bedNumber: "",
            originalBedNumber:"",
                visitingConsultant1: this.props.patientInfo.surgery_name ? null : "",
                visitingConsultant2: "",
                visitingConsultant3: "",
                visitingConsultant4: "",

                complaints: "",


                selectWard: {},
                ward_details: [],

                selectBlock: {},
                block_details: [],
                selectBlockValue: "",

                selectFloor: {},
                floor_details: [],
                selectFloorValue: "",

                selectRoom: {},
                room_details: [],
            roomid:"",
                selectBed: {},
                bed_details: [],
                selectedBedID: "",
                BedID: "",
                BedName: "",
                PreviousAdmissionDetails: [],
                roomsDetails: [],
                selectedSession: "Ward Name",
                selectedIpPatientDetails: (this.props.patientInfo && this.props.patientInfo.surgery_name) ? {} : this.props.selectedIpPatientDetails,
                wardID: "",
                floorID: "",
                blokID: "",
            roomID:"",
                roomTypes: [],
                selectbedType: "",
                roomName: "",
            roomchangeDate:moment(new Date()).format("YYYY-MM-DD"),
            admittingConsultant:"",
            doctorData:[],
                doctorSuggestionData: [],
                isDoctorSuggestion: false,
            patientTag:"",
                MlNmlComment: "",
                isAdmittingUpdate: false,
                isSaved: false,
                admittingConsultantComment: "",
                originalAdmittingConsultant: "",
                selectedIPPatient: {},
                otPatientDetails: this.props.patientInfo.surgery_name ? this.props.PatientDetail : {},
                OPappointmentID: this.props.OPappointmentID
            }
    }

    async componentDidMount() {
        this.getWardRoomFloorBedDetails()
        this.getPreviousAdmissonDetails()
        this.getRoomDetails()
        this.getPatientDetails()
        this.getDoctor()
        const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            FO_admin_setting : FO_admin_setting
        })
    }



    getRoomTypeBedDetails() {
        var service_url = Constants.GET_ROOMS_DETAILS + "?room_type=" + this.state.selectbedType;


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getRoomTypeBedDetailsSuccess,
            this.getRoomTypeBedDetailsFailure
        );
    }

    getRoomTypeBedDetailsSuccess = success => {
        if (success.status == "success") {

            var data = success.data
            var states = this.state
            states["room_details"] = data
            this.setState({
                states
            })


        }
    }
    getRoomTypeBedDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }



    getPatientDetails() {

        if (this.state.selectedIpPatientDetails.id) {
            var service_url = Constants.IP_ADMISSION_SAVE + "?patient_id=" + this.state.selectedIpPatientDetails.patient_id + "&doctor_id=" + this.state.selectedIpPatientDetails.doctor_id + "&ip_admission_id=" + this.state.selectedIpPatientDetails.id;
        } else {

            let patient_id = this.state.otPatientDetails && Object.keys(this.state.otPatientDetails).length > 0 ? this.state.otPatientDetails.patient_id : this.state.selectedIpPatientDetails ? this.state.selectedIpPatientDetails.patient_id : ""
            let doctor_id =  this.state.otPatientDetails && Object.keys(this.state.otPatientDetails).length > 0 ? this.state.otPatientDetails.doctor_id : this.state.selectedIpPatientDetails ? this.state.selectedIpPatientDetails.doctor_id : ""

            var service_url = Constants.IP_ADMISSION_SAVE + "?patient_id=" + patient_id + "&doctor_id=" + doctor_id;

        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPatientDetailsSuccess,
            this.getPatientDetailsFailure
        );
    }

    getPatientDetailsSuccess = success => {
        if (success.status == "success") {
            var data = success.data
            var states = this.state
            states["admissionDate"] = data.admission_date?data.admission_date:moment(new Date()).format("YYYY-MM-DD")
            states["admissionTime"] = data.admission_time
            states["actualAdmissionTime"] = data.admission_time
            states["originalBedNumber"] = data.bed_number
            states["bedNumber"] = data.bed_number
            states["blokName"] = data.block_name
            states["floorNumber"] = data.floor_number
            states["hcid"] = data.hcid
            states["ipNumber"] = data.ip_number
            states["patientAccountNo"] = data.patient_account_number
            states["patientName"] = data.patient_name
            states["roomID"] = data.room_id
            states["wardID"] = data.ward_id
            states["roomNumber"] = data.room_number
            states["originalRoomNumber"] = data.room_number
            states["admittingConsultant"] = data.admitting_consultant
            states["visitingConsultant1"] = data.visiting_consultant_1
            states["visitingConsultant2"] = data.visiting_consultant_2
            states["visitingConsultant3"] = data.visiting_consultant_3
            states["visitingConsultant4"] = data.visiting_consultant_4
            states["wardName"] = data.ward_name;
            states["complaints"] =data.complaints
            states["MlNmlComment"] = data.ml_nml_comment
            states["patientTag"] = data.patient_tags
            states["originalAdmittingConsultant"] = data.admitting_consultant
            states['admittingConsultantComment'] = data.admitting_consultant_description ? data.admitting_consultant_description : ""
            states['surgenName'] = data.surgeon_name
            states['tentative_discharge_date'] = data.tentative_discharge_date

            this.setState({
                states,
                // otPatientDetails: {},
                // OPappointmentID: "",
                admissionTime: this.props.patientInfo.surgery_name ? new Date() : data.admission_time
                
            })


        }
    }

    getPatientDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }


    getRoomDetails() {

        if (this.state.selectedIpPatientDetails.id) {
            var service_url = Constants.IP_ROOMS_DETAILS + "?patient_id=" + this.state.selectedIpPatientDetails.patient_id + "&ip_admission_id=" + this.state.selectedIpPatientDetails.id;
        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getRoomDetailsSuccess,
            this.getRoomDetailsFailure
        );
    }

    getRoomDetailsSuccess = success => {
        if (success.status == "success") {

            var data = success.data
            var states = this.state
            states["roomsDetails"] = data
            this.setState({
                states
            })


        }
    }

    getRoomDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }


    getPreviousAdmissonDetails() {
        let patient_id = this.state.otPatientDetails && Object.keys(this.state.otPatientDetails).length > 0 ? this.state.otPatientDetails.patient_id : this.state.selectedIpPatientDetails ? this.state.selectedIpPatientDetails.patient_id : ""
        let doctor_id =  this.state.otPatientDetails && Object.keys(this.state.otPatientDetails).length > 0 ? this.state.otPatientDetails.doctor_id : this.state.selectedIpPatientDetails ? this.state.selectedIpPatientDetails.doctor_id : ""

        var service_url = Constants.IP_PREVIOUS_ADMISSION + "?patient_id=" + patient_id + "&doctor_id=" + doctor_id;
        
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPreviousAdmissonDetailsSuccess,
            this.getPreviousAdmissonDetailsFailure
        );
    }

    getPreviousAdmissonDetailsSuccess = success => {
        if (success.status == "success") {
            var data = success.data
            var states = this.state
            states["PreviousAdmissionDetails"] = data
            this.setState({
                states
            })


        }
    }

    getPreviousAdmissonDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }



    getWardRoomFloorBedDetails() {
        var service_url = Constants.IP_WARD_BED_GET;


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getWardRoomFloorBedDetailsSuccess,
            this.getWardRoomFloorBedDetailsFailure
        );
    }

    getWardRoomFloorBedDetailsSuccess = success => {
        if (success.status == "success") {

            var data = success.data
            var states = this.state

            states["ward_details"] = data['ward_details']
            states["block_details"] = data['block_details']
            states["floor_details"] = data['floor_details']
            states["roomTypes"] = data['room_types']

            this.setState({
                states
            })


        }
    }

    getWardRoomFloorBedDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }
    // getDiffBetweenDays(admissionDAte){
    //     const date1 = new Date(admissionDAte);
    //     const date2 = new Date();
    //     const diffTime = Math.abs(date2 - date1);
    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    //     console.log(diffDays + " days");
    //     return diffDays
    // }


    getRoomBedDetails() {


        var service_url = ""
        if (this.state.selectedSession == "Ward Name") {
            service_url = Constants.IP_BED_AVAILABLE + "?ward_id=" + this.state.selectedBedID;
        } else {

            service_url = Constants.IP_BED_AVAILABLE + "?room_id=" + this.state.selectedBedID;

        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getRoomBedDetailsSuccess,
            this.getRoomBedDetailsFailure
        );
    }

    getRoomBedDetailsSuccess = success => {
        if (success.status == "success") {
            // alert(JSON.stringify(data))
            var data = success.data
            var states = this.state
            states["bed_details"] = data
            this.setState({
                states
            })


        }
    }

    getRoomBedDetailsFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    renderCommonTextBox(placeholder, value, textBoxkey, disable, texdisable) {
        return (
            <View style={[{ flex: (textBoxkey == "complaints"  || textBoxkey == "MlNmlComment") ? 0.9 : 0.31 }]}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>

                {
                    (disable && textBoxkey != "MlNmlComment") ?
                        <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: 8, color: "#888888", paddingTop: 10 }]} >
                            {value}
                        </Text> :
                        <View>
                        <Tooltip placement="topLeft" title={ this.state.admittingConsultantComment && this.state.admittingConsultantComment != "" && this.state.admittingConsultantComment != null && this.state.admittingConsultantComment != undefined && placeholder === "Admitting Consultant *" ? "The Admitting consultant changed to " + this.state.admittingConsultant + " for that reason " + this.state.admittingConsultantComment : null}>                   
                                <TextInput
                            maxLength={(textBoxkey == "complaints"  || textBoxkey == "MlNmlComment") ? 100:null}
                                    textAlignVertical={"top"}
                            editable={(!texdisable  || this.state.isIpPatientCheckouted || disable || (this.state.admittingConsultantComment != null && this.state.admittingConsultantComment != "" && this.state.admittingConsultantComment != undefined  && placeholder === "Admitting Consultant *")) ? false: true}
                                    // disabled={(!texdisable  || this.state.isIpPatientCheckouted || disable) ? true:false }
                                    style={{
                                backgroundColor:(!texdisable  || this.state.isIpPatientCheckouted || disable )? color.disableComponentColor :color.white,
                                        textAlignVertical: 'top',
                                        // borderColor: error ? "red" : "#CDD1D5",
                                        borderColor: "#CDD1D5",
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        width: "100%",
                                height: textBoxkey == "complaints" ? 74 : textBoxkey == "MlNmlComment"? 50 : 35,
                                        // padding: 5,
                                        paddingLeft: 10,
                                        marginTop: 8,
                                    }} value={value}
                                    onChangeText={(text) => {
                                        var states = this.state;
                                if(textBoxkey == "admittingConsultant"){
                                    if((text).trim() != ""){
                                                this.setState({
                                                    isDoctorSuggestion: true
                                                })
                                                this.searchDoctorSuggestion(text)
                                    }else{
                                                this.setState({
                                                    isDoctorSuggestion: false
                                                })
                                            }
                                        }
                                        if(textBoxkey == "surgenName"){
                                            if((text).trim() != ""){
                                                        this.setState({
                                                            isSurgenSuggestion: true
                                                        })
                                                        this.searchDoctorSuggestion(text)
                                            }else{
                                                        this.setState({
                                                            isSurgenSuggestion: false
                                                        })
                                                    }
                                                }
                                        states[textBoxkey] = text;
                                        this.setState({ states })
                                    }}
                                />
                            </Tooltip>
                            {(this.state.isDoctorSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 && textBoxkey == "admittingConsultant") ?

                                <View style={{ zIndex: 1, position: 'absolute', marginLeft: "5%", maxHeight: 200, width: "90%", marginTop: 45 }}>
                                    <ScrollView
                                        style={{
                                            height: 195, marginBottom: 10, flex: 1,
                                            backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                                        }}
                                    >
                                {(this.state.doctorSuggestionData).map((value,index) => 
                                            <View>
                                                <TouchableOpacity
                                                    key={index}
                                        style={{marginTop: 10}}
                                                    onPress={() => {
                                                        var doctorFullName = value.first_name + " " + value.initial
                                                        this.setState({
                                                            admittingConsultant: doctorFullName,
                                                            isDoctorSuggestion: false
                                                        })
                                                    }}
                                                >
                                            <Text>{ value.first_name + " " + value.initial }</Text>
                                                </TouchableOpacity>
                                            </View>
                                        )}
                                    </ScrollView>
                                </View>
                                : null

                            }
                            {(this.state.isSurgenSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 && textBoxkey == "surgenName") ?

                                <View style={{ zIndex: 1, position: 'absolute', marginLeft: "5%", maxHeight: 200, width: "90%", marginTop: 45 }}>
                                    <ScrollView
                                        style={{
                                            height: 195, marginBottom: 10, flex: 1,
                                            backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                                        }}
                                    >
                                {(this.state.doctorSuggestionData).map((value,index) => 
                                            <View>
                                                <TouchableOpacity
                                                    key={index}
                                        style={{marginTop: 10}}
                                                    onPress={() => {
                                                        var doctorFullName = value.first_name + " " + value.initial
                                                        this.setState({
                                                            surgenName: doctorFullName,
                                                            isSurgenSuggestion: false
                                                        })
                                                    }}
                                                >
                                            <Text>{ value.first_name + " " + value.initial }</Text>
                                                </TouchableOpacity>
                                            </View>
                                        )}
                                    </ScrollView>
                                </View>
                                : null

                                }
                        </View>


                }

            </View>
        )
    }


    renderButton() {
        let ot_ipnumber = (this.state.otPatientDetails && Object.keys(this.state.otPatientDetails).length > 0 && this.state.otPatientDetails.ip_number != null) ? true : false
        return (
            <View style={styles.buttonrowView}>
                <TouchableOpacity
                    onPress={() => {
                    if( this.state.admittingConsultantComment === null && this.state.admittingConsultant != "" && this.state.originalAdmittingConsultant && ( this.state.admittingConsultant != this.state.originalAdmittingConsultant )) {
                            this.setState({
                                isAdmittingUpdate: true
                            })
                        } else {
                            this.saveAdmissionButton()
                        }
                    }}
                    disabled={(this.state.saveDisable || this.state.isIpPatientCheckouted)|| (ot_ipnumber) ? true : false}
                    style={[Style.ipcancelButton,{backgroundColor: (this.state.saveDisable || this.state.isIpPatientCheckouted || (ot_ipnumber)) ? color.disableLabelColor : color.themeDark,}]} >
                    <Icon name={"save"} size={Platform === "web" ? 15 : 20} color={color.white} />
                    <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Save"}</Text>
                </TouchableOpacity>

                <TouchableOpacity
                    disabled={ this.state.isIpPatientCheckouted ? true: false}
                    onPress={() => this.cancelValues()}
                    style={Style.ipAdmissonCancelButton} >
                    <Cancel name={"cross"} size={Platform === "web" ? 15 : 20} color={color.themeDark} />
                    <Text style={[Style.certificatetext, { marginLeft: 10, color: color.themeDark }]}>{"Cancel"}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    onSubmitToAddValue() {
        var states = this.state;
        var { birthWeightList } = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showSelectedBirthWeightListNewButtonFlag) {
            birthWeightList.push(preparData);
            birthWeightList = this.removeDuplicate(birthWeightList);
            states["newButtonValue"] = "";
            states["showSelectedBirthWeightListNewButtonFlag"] = true;
            states["selectedBirthWeight"] = preparData;
        }
        this.setState({
            birthWeightList, states
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    renderBedType() {

        return (
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                {
                    this.state.roomTypes && this.state.roomTypes.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4, marginRight: 10 }}>
                                <CheckBox
                                    value={this.state.selectbedType == item ? true : false}
                                    onValueChange={() => {
                                        var { selectbedType } = this.state;
                                        selectbedType = item

                                        this.setState({ bed_details: [], selectbedType, roomName: "" }, () => {
                                            this.getRoomTypeBedDetails()
                                        })
                                    }}
                                    size={2}
                                />
                                <Text style={{ marginLeft: 10, fontSize: 12, marginTop: 2 }}>{item}</Text>
                            </View>
                        )
                    })

                }
            </View>
        )

    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "selectWard") {
            states[key] = selectedValue;
            states["selectedBedID"] = selectedValue.value
            states["BedName"] = "",

                this.setState({ states }, () => {
                    this.getRoomBedDetails()
                })
        } else if (key == "selectBlock") {
            states[key] = selectedValue;
            states["selectBlockValue"] = selectedValue.value
            this.setState({ states })
        } else if (key == "selectFloor") {
            states[key] = selectedValue;
            states["selectFloorValue"] = selectedValue.value
            this.setState({ states })
        }
        states[key] = selectedValue;


        this.setState({ states })
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {

        var data = this.removeDuplicate(setOfData)

        // var checkIsAbnormalities = data

        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { paddingLeft: 20, }]}>
                {question ?
                    <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text> : null}
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {


                            var changedItem = {}
                            if (key == "selectWard") {
                                changedItem = { label: item.ward_name, value: item.ward_id }
                            } else if (key == "selectBlock") {
                                changedItem = { label: item.block_name, value: item.block_id }

                            } else if (key == "selectFloor") {
                                changedItem = { label: item.floor_number, value: item.floor_id }

                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    renderPreviousAdmission() {
        return (
            <View>
                <Text style={{ fontWeight: 400, fontSize: 16, marginBottom: 10 }}>Previous Admission Details</Text>
                <CommonHistorySectionHeader
                    heading1={"S.No"}
                    heading2={"IP Number"}
                    heading3={"HCID"}
                    heading4={"Admission Date"}
                    noOfColumn={4}
                    columnSize={[0.3, 0.3, 0.3, 0.3]}

                />

                <FlatList
                    data={this.state.PreviousAdmissionDetails}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.renderPreviouScreenItem(item, index)
                    }
                />
            </View>
        )
    }

    renderRoomDetails() {
        return (
            <View>
                <Text style={{ fontWeight: 400, fontSize: 16, marginBottom: 10 }}>Rooms Details</Text>
                <CommonHistorySectionHeader
                    heading1={"Previous Rooms"}
                    noOfColumn={1}

                />
                <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: "space-between", marginTop: 15, padding: 10 }}>

                    <View style={{ backgroundColor: color.sectionHeadingDarkColor, flex: 0.17, height: 35, justifyContent: 'center' }}>
                        <Text style={[Style.historyHeaderFontStyleStartText]}>{"Room Details"}</Text>

                    </View>
                    <View style={{ backgroundColor: color.sectionHeadingDarkColor, flex: 0.15, height: 35, justifyContent: 'center' }}>
                        <Text style={[Style.historyHeaderFontStyleStartText]}>{"From"}</Text>

                    </View>
                    <View style={{ backgroundColor: color.sectionHeadingDarkColor, flex: 0.15, height: 35, justifyContent: 'center' }}>
                        <Text style={[Style.historyHeaderFontStyleStartText]}>{"To"}</Text>

                    </View>
                    <View style={{ backgroundColor: color.sectionHeadingDarkColor, flex: 0.15, height: 35, justifyContent: 'center' }}>
                        <Text style={[Style.historyHeaderFontStyleStartText]}>{"Duration"}</Text>

                    </View>
                    <View style={{ backgroundColor: color.sectionHeadingDarkColor, flex: 0.15, height: 35, justifyContent: 'center' }}>
                        <Text style={[Style.historyHeaderFontStyleStartText]}>{"Amount"}</Text>

                    </View>

                </View>

                <FlatList
                    data={this.state.roomsDetails}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    renderItem={({ item, index }) =>
                        this.rendeRoomItem(item, index)
                    }
                />


            </View>
        )
    }

    onPressHeader(type) {
        this.setState({
            bed_details: [],
            selectWard: {},
            selectedSession: type,

        })
    }

    renderRooms() {
        return (
            <View>
                <View>
                    <CommonSectionHeader selectedSession={this.state.selectedSession} onPressHeader={this.onPressHeader.bind(this)} heading={"EHR"} type={""} multiSelection={true} multiSelectionValue={["Ward Name", "Rooms"]} />
                </View>

                {this.state.selectedSession == "Rooms" ?

                    <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { paddingLeft: 20, marginTop: 10, marginBottom: 10 }]}>
                        {this.renderBedType()}
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 20 }}>
                            {
                                this.state.room_details && this.state.room_details.map((item, index) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => this.btnClickAction(item)}
                                            disabled={!item.is_available}
                                            style={{
                                                backgroundColor:item.is_available && item.room_number == this.state.roomName ? color.themeDark :item.is_available ? '#A7D385' :"#FF6045", borderRadius: Style.allButtonBorderRadius.borderRadius, alignContent: 'center', alignItems: 'center', alignSelf: "center", padding: 10, width: 50, marginBottom: 10, marginRight: 10,
                                                opacity: (item.room_number == this.state.roomName || this.state.roomName == "") ? "" : 0.5
                                            }}>
                                            <Text style={{ color: '#fff', textAlign: 'center' }}>{item.room_number}</Text>
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                    </View> : null}
            </View>
        )
    }

    renderBlock() {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Block Name"}
                    noOfColumn={1}
                />
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    {
                        this.renderLabelWithButtonGroup(
                            "",
                            "selectBlock",
                            this.state.selectBlock,
                            "",
                            false,
                            this.state.block_details)
                    }</View>
            </View>
        )
    }

    renderFloor() {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Floor Name"}
                    noOfColumn={1}
                />
                <View style={{ marginBottom: 10, marginTop: 10 }}>
                    {
                        this.renderLabelWithButtonGroup(
                            "",
                            "selectFloor",
                            this.state.selectFloor,
                            "",
                            false,
                            this.state.floor_details)
                    }</View>
            </View>
        )
    }

    renderWard() {
        return (
            <View style={{ marginBottom: 10, marginTop: 10 }}>
                {
                    this.renderLabelWithButtonGroup(
                        "",
                        "selectWard",
                        this.state.selectWard,
                        "",
                        false,
                        this.state.ward_details)
                }


            </View>
        )
    }

    btnClickAction(item) {

        this.setState({
            BedName: "",
            selectedBedID: item.room_id,
            roomName: item.room_number,
            roomid:item.room_id
        }, () => {
            this.getRoomBedDetails()
        })
    }

    btnbedClickAction(item) {

        this.setState({
            BedID: item.id,
            BedName: item.bed_number
        })
    }

    renderBedAvailability() {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Bed Availability"}
                    noOfColumn={1}
                />
                <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { paddingLeft: 20, marginTop: 10, marginBottom: 10 }]}>

                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 20 }}>
                        {
                            this.state.bed_details && this.state.bed_details.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        disabled={!item.is_available}
                                        onPress={() => this.btnbedClickAction(item)} style={{
                                            backgroundColor:item.is_available&&item.bed_number == this.state.BedName?color.themeDark: item.is_available ? '#A7D385' : "#FF6045", borderRadius: Style.allButtonBorderRadius.borderRadius, alignContent: 'center', alignItems: 'center', alignSelf: "center", padding: 10, width: 40, height: 35, marginBottom: 10, marginRight: 10,
                                            opacity: (item.bed_number == this.state.BedName || this.state.BedName == "") ? "" : 0.5
                                        }}>
                                        <Text style={{ color: '#fff', textAlign: 'center' }}>{item.bed_number}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#A7D385'

                        }} />

                        <Text style={{
                            width: "20%",
                            marginLeft: 10,
                            color: '#A7D385'
                        }} >Available</Text>

                        <View style={{
                            width: 10,
                            height: 10,
                            backgroundColor: "#FF6045"

                        }} />

                        <Text style={{
                            width: "20%",
                            marginLeft: 10,
                            color: '#FF6045'
                        }} >Booked</Text>
                    </View>
                </View>

            </View>
        )
    }
    clearData = () => {
        var states = this.state
        states['selectbedType'] = "";
        states['bed_details'] = [];
        states["roomName"] = "";
        states["roomid"] = "";
        states['room_details'] = [];
        states['blackName']
        // states["ward_details"] = []
        states["block_details"] = []
        states["floor_details"] = []
        this.setState({
            states
        })
    }

    onPressAddButton() {


        if(this.state.selectedSession == "Rooms" && this.state.roomName == ""){
            this.props.showResposeValue('error', Messages.InvalidRoomName);
        }else if(this.state.selectedSession == "Ward Name" && (this.state.selectWard == {} || this.state.selectWard == "" )){
            this.props.showResposeValue('error', Messages.InvalidWardName);
        }
        else if(this.state.BedName == ""){
            this.props.showResposeValue('error', Messages.InvalidBedName);
        }else{
            var states = this.state
            states["wardName"] = this.state.selectWard.label
            states["wardID"] = this.state.selectWard.value
            states["floorNumber"] = this.state.selectFloor.label
            states["floorID"] = this.state.selectFloor.value
            states["blokName"] = this.state.selectBlock.label
            states["blokID"] = this.state.selectBlock.value
            states["roomNumber"] = this.state.roomName
            states["roomID"] = this.state.roomid
            states["bedNumber"] = this.state.BedName


            this.setState({
                states
        },()=>{
                this.clearData()
            })
        }
    }


    saveAdmissionButton = () => {
        var states = this.state;

        var admissionTime = moment(this.state.admissionTime, ["h:mm A"]).format("HH:mm");
        // console.log("Time", admissionTime, "Actual Time", states['actualAdmissionTime'])
        // if(admissionTime > states['actualAdmissionTime']){
        //     this.props.showResposeValue('error', "Cannot update admission time to Future time");
        //     return;
        // }
        // if(parseFloat(states['actualAdmissionTime']) - parseFloat(admissionTime) > states.FO_admin_setting.allow_ip_time_upto){
        //     this.props.showResposeValue('error', "Allowed time to reduce is " + states.FO_admin_setting.allow_ip_time_upto + "hours");
        //     return;
        // }

        this.setState({
            saveDisable: true,
            isSaved: true

        })



        if (this.state.selectedIpPatientDetails.id) {
            var data = {
                "ip_admission_id": this.state.selectedIpPatientDetails.id,
                "room_change_date": this.state.roomchangeDate,
                "ward_id": states.wardID,
                "floor_id": states.floorID,
                "block_id": states.blokID,
                "room_id": states.roomID,
                "bed_number": states.bedNumber,
                "visiting_consultant": this.state.visitingConsultant1,
                "visiting_consultant_2": this.state.visitingConsultant2,
                "visiting_consultant_3": this.state.visitingConsultant3,
                "visiting_consultant_4": this.state.visitingConsultant4,
                "surgeon_name": this.state.surgenName,
                "tentative_discharge_date" : this.state.tentative_discharge_date

            }
            if(this.state.originalAdmittingConsultant != this.state.admittingConsultant ) {
                data["admitting_consultant"] = this.state.admittingConsultant,
                    data['admitting_consultant_description'] = this.state.admittingConsultantComment
            }
            var service_url = Constants.IP_ADMISSION_UPDATE;
        } else {
            if (this.state.selectedIpPatientDetails.patient_id) {
                var data = {
                    "patient_id": this.state.selectedIpPatientDetails.patient_id,
                    "doctor_id": this.state.selectedIpPatientDetails.doctor_id,
                    "appointment_id": this.state.selectedIpPatientDetails.appointment_id,
                    "admission_date": states.admissionDate,
                    "admission_time": admissionTime,
                    "patient_name": this.state.patientName,
                    "patient_account_number": this.state.patientAccountNo,
                    "ward_id": states.wardID,
                    "floor_id": states.floorID,
                    "block_id": states.blokID,
                    "room_id": states.roomID,
                    "bed_number": states.bedNumber,
                    "admitting_consultant": states.admittingConsultant,
                    "visiting_consultant": states.visitingConsultant1,
                    "visiting_consultant_2": states.visitingConsultant2,
                    "visiting_consultant_3": states.visitingConsultant3,
                    "visiting_consultant_4": states.visitingConsultant4,
                    "complaints": states.complaints,
                    "ml_nml_comment": states.MlNmlComment,
                    "patient_tags": states.patientTag,
                    "surgeon_name": this.state.surgenName,
                    "tentative_discharge_date" : this.state.tentative_discharge_date

                }
            }
            else {
                var data = {
                    "id": this.state.otPatientDetails.id,
                    "patient_id": this.state.otPatientDetails.patient_id,
                    "doctor_id": this.state.otPatientDetails.doctor_id,
                    "appointment_id": this.state.OPappointmentID,
                    "admission_date": states.admissionDate,
                    "admission_time": admissionTime,
                    "patient_name": this.state.otPatientDetails.patient_name,
                    "patient_account_number": this.state.otPatientDetails.uhid,
                    "ward_id": states.wardID,
                    "floor_id": states.floorID,
                    "block_id": states.blokID,
                    "room_id": states.roomID,
                    "bed_number": states.bedNumber,
                    "admitting_consultant": this.state.otPatientDetails.doctor_name,
                    "visiting_consultant": states.visitingConsultant1,
                    "visiting_consultant_2": states.visitingConsultant2,
                    "visiting_consultant_3": states.visitingConsultant3,
                    "visiting_consultant_4": states.visitingConsultant4,
                    "complaints": this.state.otPatientDetails.surgery_name,
                    "ml_nml_comment": states.MlNmlComment,
                    "patient_tags": states.patientTag,
                    "IsotAppointment": true

                }
            }
            var service_url = Constants.IP_ADMISSION_SAVE;

        }




        if(this.state.selectedIpPatientDetails.id !=="" &&(states.admissionDate == "" || states.admissionDate == undefined || states.admissionDate == null)){
            this.props.showResposeValue('error', Messages.InvalidAdmissionDate);
            this.setState({saveDisable : false })
        }else if(this.state.selectedIpPatientDetails.id !=="" &&(admissionTime == "" || admissionTime == undefined || admissionTime == null)){
            this.props.showResposeValue('error', Messages.InvalidAdmissionTime);
            this.setState({saveDisable : false })
        }else if(states.bedNumber == "" || states.bedNumber == undefined || states.bedNumber == null){
            this.props.showResposeValue('error', Messages.InvalidBedNumber);
            this.setState({saveDisable : false })
        } else if(this.state.admittingConsultantComment === null && this.state.admittingConsultant != "" && this.state.originalAdmittingConsultant && ( this.state.admittingConsultant != this.state.originalAdmittingConsultant ) ){
            this.props.showResposeValue('error', "Please enter admitting comsultant comment");
        } else if((this.state.admittingConsultant == this.state.originalAdmittingConsultant) && (states.bedNumber == states.originalBedNumber) && (states.roomNumber == states.originalRoomNumber)){
            this.props.showResposeValue('error', "Please enter bed number");
        }

        else if(
            states.visitingConsultant1 == "" &&
            states.visitingConsultant2 == "" &&
            states.visitingConsultant3 == "" &&
            states.visitingConsultant4 == ""
            ){
            this.props.showResposeValue('error', Messages.InvalidVisitingConsultantName);
            this.setState({ saveDisable: false })
        } else if (this.state.selectedIpPatientDetails.id !== "" && this.state.selectedIpPatientDetails.id !== undefined && (states.complaints == "" || states.complaints == undefined || states.complaints == null)) {
            this.props.showResposeValue('error', Messages.InvalidComplaints);

            this.setState({saveDisable : false })
        } else if( this.state.admittingConsultantComment == "" && this.state.isAdmittingUpdate ) {
            this.props.showResposeValue('error', Messages.InvalidAdmittingComment);
        }
        else{
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveAdmissionButtonSuccess,
                this.saveAdmissionButtonFailure
            );
        }



    }

    saveAdmissionButtonSuccess = success => {
        if (success.status == "success") {
            this.setState({
                isAdmittingUpdate: false,
                otPatientDetails: {},
                OPappointmentID: ""
            })
            this.props.showResposeValue('success', success.message);
            this.getPreviousAdmissonDetails()

            // this.clearAllData();
            // this.props.changeScreen("iPPatientList")
            // this.props.refreshRighSideComponent(pageName);
        } else {
            this.setState({
                saveDisable: false,
                isAdmittingUpdate: false
            })

            this.props.showResposeValue('error', success.message);

        }
    }
    saveAdmissionButtonFailure = error => {
        this.setState({
            saveDisable: false,
            isAdmittingUpdate: false
        })
        this.props.showResposeValue('error', error.message);
    }

    cancelValues() {
        var states = this.state
        // states["admissionDate"] = ""
        // states["admissionTime"] = ""
        // states["patientName"] = ""
        // states["patientAccountNo"] = ""
        // states["patient_account_number"] = ""
        // states["wardName"] = ""
        states["wardID"] = ""
        // states["floorNumber"] = ""
        states["floorID"] = ""
        // states["blokName"] = ""
        states["blokID"] = ""
        states["roomNumber"] = ""
        states["bedNumber"] = ""
        states["visitingConsultant1"] = ""
        states["visitingConsultant2"] = ""
        states["visitingConsultant3"] = ""
        states["visitingConsultant4"] = ""
        states["admitting_consultant"] = ""
        // states["complaints"] = ""
        // states["hcid"] = ""
        // states["ipNumber"] = ""

        this.setState({
            states
        })

    }

    renderDatePicker() {
        return (
            <View style={{ flex: 0.31 }}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
                    {"Admission Date *"}
                </Text>

                <DatePicker
                disabled={this.state.selectedIpPatientDetails&& this.state.selectedIpPatientDetails.id ? true: false}
                    disabledDate={current => {
                       return current && current > moment(this.state.admissionDate).add(0, "days");
                        // return current && current > moment(this.state.admissionDate).add(0, "days") || current < moment(this.state.admissionDate).add(-this.state.FO_admin_setting.allow_ip_days_upto, "days");
                    }}
                    value={
                        this.state.admissionDate
                            ? moment(this.state.admissionDate)
                            : this.state.admissionDate
                    }
                    clearIcon={false}
                    onChange={(date) => {
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                        this.setState({ admissionDate: converted_date });
                    }}
                    placeholder=""
                    format={"DD-MM-YYYY"}
                    style={{
                        textAlignVertical: 'top',
                        // borderColor: error ? "red" : "#CDD1D5",
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: 35,
                        // padding: 5,
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                /></View>
        )
    }


    renderView = (data) => {

        var service_url = Constants.IP_PRINT_ADMISSION_DETAILS + "?ip_number=" +data;

        OpthamologyService.getInstance().documentUploadGet(
            service_url,
            // this,
            this.renderViewList,
            this.failurerenderViewList,
            "pdf"
        );
    }

    renderViewList = (response) => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            //   console.log(file)
            window.open(fileURL);
        }
    }

    failurerenderViewList = (error) => {

        console.log(error);
    }


    renderTimePicker() {
        return (
            <View style={{ flex: 0.31 }}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
                    {"Admission Time *"}
                </Text>
                <TimePicker
                    disabled={this.state.selectedIpPatientDetails && this.state.selectedIpPatientDetails.id || this.props.PatientDetail ? true : false}
                    use12Hours
                    format="h:mm a"
                    onFocus={()=>{
                        var states = this.state;
                        states["admissionTime"] = ""
                        this.setState({states})

                    }
                    }
                    onOk={()=>{
                        this.reference.current.blur()
                    }}
                    onChange={(time, timeString) => {

                        var states = this.state;
                        states["admissionTime"] = timeString
                        this.setState({ states })

                    }}
                    ref={this.reference? this.reference: null}

                    placeholder=""
                    // defaultValue={moment(this.state.admissionTime, "h:mm a")}
                    value={

                        this.state.admissionTime ? moment(this.state.admissionTime, "hh:mm a") : this.state.admissionTime}

                    //value={value} 

                    style={{
                        textAlignVertical: 'top',
                        // borderColor: error ? "red" : "#CDD1D5",
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: 35,
                        // padding: 5,
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                />

            </View>
        )
    }

    renderPreviouScreenItem = (item, index) => {
        return (

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 8, borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>
                <Text style={[Style.contentTableDataLead, { flex: 0.3, marginHorizontal: 20 }]}>{index + 1}</Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.3, color: 'blue' }]}>
                    <TouchableOpacity onPress={() => this.renderView(item.ip_number)}>
                        <Text style={{textDecorationLine:'underline'}}>{item.ip_number}</Text>
                    </TouchableOpacity>
                </Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.3 }]}>{item.hcid}</Text>
                <Text style={[Style.contentTableDataLead, { flex: 0.3 }]}>{item.admission_date}</Text>
            </View>

        );
    };


    rendeRoomItem = (item, index) => {
        return (

            <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: "space-between", padding: 10 }}>
                <View style={{ flex: 0.17, justifyContent: 'center' }}>
                    <Text style={[Style.contentTableDataLead, { marginLeft: 20 }]}>{item.room_details}</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                    <Text style={[Style.contentTableDataLead, { marginLeft: 20 }]}>
                        {moment(item.from).format("DD-MM-YYYY")}
                    </Text></View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                    <Text style={[Style.contentTableDataLead, { marginLeft: 20 }]}>{moment(item.to).format("DD-MM-YYYY")}</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                    <Text style={[Style.contentTableDataLead, { marginLeft: 20 }]}>{item.duration}</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                    <Text style={[Style.contentTableDataLead, { marginLeft: 20 }]}>{item.amount}</Text>
                </View>
            </View>

        );
    };


    renderRoomChangeDatePicker() {
        // alert(this.getDiffBetweenDays(this.state.admissionDate))
        // var getDays = this.getDiffBetweenDays(this.state.admissionDate)

        return (
            <View style={{ flex: 0.31 }}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 0, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
                    {"Room Change Date"}
                </Text>

                <DatePicker
                    value={
                        this.state.roomchangeDate
                            ? moment(this.state.roomchangeDate)
                            : this.state.roomchangeDate
                    }
                    disabledDate={current => {
                        return current && current > moment().add(0, "days");
                    }}
                    clearIcon={false}
                    onChange={(date) => {
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                        this.setState({ roomchangeDate: converted_date });
                    }}
                    placeholder=""
                    format={"DD-MM-YYYY"}
                    style={{
                        textAlignVertical: 'top',
                        // borderColor: error ? "red" : "#CDD1D5",
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        height: 35,
                        // padding: 5,
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                /></View>
        )
    }
    renderTentativeDischargeDatePicker() {
        // alert(this.getDiffBetweenDays(this.state.admissionDate))
        // var getDays = this.getDiffBetweenDays(this.state.admissionDate)

        return (
            <View style={{ flex: 0.31 }}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 0, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
                    {"Tentative Discharge Date"}
                </Text>

                <DatePicker
                    value={
                        this.state.tentative_discharge_date
                            ? moment(this.state.tentative_discharge_date)
                            : this.state.tentative_discharge_date
                    }
                    disabledDate={current => {
                        return current && current < moment().add(0, "days");
                    }}
                    clearIcon={false}
                    onChange={(date) => {
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                        this.setState({ tentative_discharge_date: converted_date });
                    }}
                    placeholder=""
                    format={"DD-MM-YYYY"}
                    style={{
                        textAlignVertical: 'top',
                        // borderColor: error ? "red" : "#CDD1D5",
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        height: 35,
                        // padding: 5,
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                /></View>
        )
    }

    renderPatientTagDropdown = () => {
        return(
            <View>
                <Text style={[{ position: 'absolute', marginLeft: 10, backgroundColor: "white", fontSize: 12, paddingLeft: 5, paddingRight: 5, color: "#888888" }]}>{"Patient Tag"}</Text>
                <Picker
                enabled={this.state.selectedIpPatientDetails&& this.state.selectedIpPatientDetails.id ? false: true}
                    selectedValue={this.state.patientTag}
              style={[Style.pickerView, { marginTop: 7, borderRadius: 4, borderColor: "#CDD1D5", height: 32, width: "100%",zIndex:-1 },this.state.isIpPatientCheckouted?{}:{backgroundColor: this.state.selectedIpPatientDetails&& this.state.selectedIpPatientDetails.id ? color.disableComponentColor : null }]}
                    itemStyle={{ fontSize: 14 }}
                    onValueChange={(itemvalue) => {
                if(itemvalue != "") {
                            var states = this.state;
                            states["patientTag"] = itemvalue
                            this.setState({ states });
                }}}
                >

                    <Picker.Item label="- Select Tag -" value="" />
                    <Picker.Item label="Medicolegal" value="Medicolegal" />
                    <Picker.Item label="Non medicolegal" value="Non medicolegal" />
                </Picker>
            </View>
        )
    }

    getDoctor = () => {
        var serviceUrl =
            Constants.HOME_GET_CLINIC_DOCTORS;

        Service.getInstance().getClinicList(
            serviceUrl,
            this,
            this.getDoctorSuccess,
            this.getDoctorFailure
        );
    };

    getDoctorSuccess = (response) => {
        if(response.status == "success"){
            this.setState({
                doctorData : response.data,
                doctorSuggestionData : response.data
            })
        }
    }

    searchDoctorSuggestion = (value) => {
        let { doctorSuggestionData } = this.state;
        let tempDescList = this.state.doctorData
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {

                if ((tempDescList[i].first_name.toLowerCase() + " " + tempDescList[i].initial.toLowerCase()).indexOf(value.toLowerCase()) > -1) {
                if(tempDescList[i].first_name!= 'Nursing'){
                        searchListTemp.push(tempDescList[i]);
                    }
                }
            }
            doctorSuggestionData = searchListTemp;
        } else {
            doctorSuggestionData = this.state.billingDescriptionList;
        }
        this.setState({
            doctorSuggestionData
        })
    }


    render() {
        console.log(this.props.patientInfo.surgery_name, this.props.selectedIpPatientDetails, this.state.selectedIpPatientDetails)
        var states = this.state;
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>

                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 10 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >

                        <View style={Style.ipAdmissionSectionView}>

                            <CommonHistorySectionHeader
                                heading1={"Admission Details"}
                                noOfColumn={1}
                            />


                            <View style={styles.textboxRowView}>

                                {this.renderDatePicker()}
                                {this.renderTimePicker()}
                                {/* {this.renderCommonTextBox("Admission Date", states.admissionDate, "admissionDate", false)} */}
                                {/* {this.renderCommonTextBox("Admission Time", states.admissionTime, "admissionTime", false)} */}
                                {this.renderCommonTextBox("Patient Name", this.state.otPatientDetails.id > 0 ? this.state.otPatientDetails.patient_name : states.patientName, "patientName", false, false)}
                            </View>

                            <View style={styles.textboxRowView}>
                                {this.renderCommonTextBox("Patient Account Number", this.state.otPatientDetails.id > 0 ? this.state.otPatientDetails.uhid : states.patientAccountNo, "patientAccountNo", false, false)}
                                {this.renderCommonTextBox("HCID", states.hcid, "hcid", false, false)}
                                {this.renderCommonTextBox("IP Number", states.ipNumber, "ipNumber", false, false)}
                            </View>

                            <View style={styles.textboxRowView}>
                                {this.renderCommonTextBox("Ward Name", states.wardName, "wardName", false, false)}
                                {this.renderCommonTextBox("Floor Name", states.floorNumber, "floorNumber", false, false)}
                                {this.renderCommonTextBox("Block Name", states.blokName, "blokName", false, false)}
                            </View>

                            <View style={[styles.textboxRowView, {zIndex:1}]}>
                                {this.renderCommonTextBox("Room Number", states.roomNumber, "roomNumber", false, false)}
                                {this.renderCommonTextBox("Bed Number *", states.bedNumber, "bedNumber", false, false)}
                                {this.renderCommonTextBox("Admitting Consultant *", this.state.otPatientDetails.id > 0 ? this.state.otPatientDetails.doctor_name : states.admittingConsultant, "admittingConsultant", false, true)}
                                {/* this.state.selectedIpPatientDetails 
                                 && this.state.selectedIpPatientDetails.id ? true : false, 
                                 false, true                              
                                  this.state.selectedIpPatientDetails && 
                                 this.state.selectedIpPatientDetails.id ? false : true
                                )} */}

                            </View>

                            <View style={styles.textboxRowView}>
                                {this.renderCommonTextBox("Visiting Consultant 1", states.visitingConsultant1, "visitingConsultant1", false, true)}
                                {this.renderCommonTextBox("Visiting Consultant 2", states.visitingConsultant2, "visitingConsultant2", false, true)}
                                {this.renderCommonTextBox("Visiting Consultant 3", states.visitingConsultant3, "visitingConsultant3", false, true)}
                            </View>
                            <View style={styles.textboxRowView1}>
                                {this.renderCommonTextBox("Visiting Consultant 4", states.visitingConsultant4, "visitingConsultant4", false, true)}
                            {this.state.selectedIpPatientDetails.id?
                            <View style={{flex:0.31}}>
                                        {this.renderRoomChangeDatePicker()}
                            </View>:null}
                            <View style={{flex:0.31}}>
                                    {this.renderPatientTagDropdown()}
                                </View>

                            </View>
                            <View style={styles.textboxRowView1}>
                            {this.renderCommonTextBox("Surgen Name", this.state.otPatientDetails.id > 0 ? this.state.otPatientDetails.doctor_name : states.surgenName, "surgenName", false, true)}
                            <View style={{flex:0.31}}>
                                {this.renderTentativeDischargeDatePicker()}
                            </View>
                            </View>
                            <View style={styles.textboxRowView}>
                                {this.renderCommonTextBox("ML / NML Comments", states.MlNmlComment, "MlNmlComment", this.state.patientTag ? false : true, this.state.selectedIpPatientDetails && this.state.selectedIpPatientDetails.id ? false : true)}
                            </View>
                            <View style={styles.textboxRowView}>
                                {this.renderCommonTextBox("Complaints *", this.state.otPatientDetails.id > 0 ? this.state.otPatientDetails.surgery_name : states.complaints, "complaints", false, true)}
                            </View>

                            {this.renderButton()}
                            <View style={{ marginTop: 10, marginBottom: 20 }}>
                                {this.renderPreviousAdmission()}
                            </View>

                            {this.renderRoomDetails()}


                        </View>

                    </ScrollView>
                </View>





                <View style={{
                    paddingVertical: 20, paddingLeft: 20, width: "35%", backgroundColor: color.themeShade,
                    shadowColor: "#090F36",
                    shadowOffset: { width: 1, height: 1 },
                    shadowOpacity: 0.4, shadowRadius: 3,
                    elevation: 5,

                }}>
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%", paddingRight: 10, }}
                        contentContainerStyle={{ height: screenHeight - 65 }}
                    >
                        <View>
                            {this.renderRooms()}
                        </View>
                        {this.state.selectedSession == "Ward Name" ?
                            <View>
                                {this.renderWard()}
                            </View>
                            : null}
                        <View style={{ marginBottom: 10 }}>
                            {this.renderBlock()}
                        </View>
                        <View style={{ marginBottom: 10 }}>
                            {this.renderFloor()}
                        </View>
                        <View>
                            {this.renderBedAvailability()}
                        </View>


                        <CommonAddButton
                            disable={this.state.isIpPatientCheckouted}
                            onPressAddButton={this.onPressAddButton.bind(this)}
                        />

                    </ScrollView>
                </View>
                { this.state.isAdmittingUpdate ? 
                    <View style={{ justifyContent: "center", alignItems: "center", height: screenHeight, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
                    <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 20,width: "35%" }}>
                            <Text style={{ fontSize: 18, fontWeight: "bold" }}>{"Kindly mention the reason for changing Admitting Consultant ?"}</Text>
                            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", height: "40%", flexWrap: "wrap-reverse" }}>
                                <TextInput
                                    multiline={true}
                                    placeholder={"Admitting Consultant Comment"}
                                    underlineColorAndroid="transparent"
                                    style={{
                                        alignSelf: "left",
                                        padding: 10,
                                        color: color.black,
                                        width: "100%",
                                        height: 80,
                                        borderRadius: 10,
                                        borderColor: "grey",
                                        borderWidth: 1, fontSize: 16,
                                        margin: 10
                                    }}
                                    placeholderTextColor={"grey"}
                                    autoCapitalize="none"
                                    value={this.state.admittingConsultantComment}
                                    onChangeText={(Testvalue) => {
                                        this.setState({
                                            admittingConsultantComment: Testvalue,
                                        });
                                    }}
                                />

                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "center" }}>
                                <TouchableOpacity
                                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "100%", paddingVertical: 8 }}
                                    onPress={() => {
                                        this.saveAdmissionButton()
                                    }}>
                                    <Text style={{ color: 'white', fontSize: 12, }}>{"Submit"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ marginLeft: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "100%", paddingVertical: 8 }}
                                    onPress={() => {
                                        this.setState({
                                            isAdmittingUpdate: false
                                        })
                                    }}>
                                    <Text style={{ color: 'white', fontSize: 12, }}>{"Cancel"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                </View> : null }


            </View >
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },

    textboxRowView: {
        flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10,flexWrap:"wrap", zIndex: -1
    },
    textboxRowView1: {
        flexDirection: 'row',justifyContent:'space-between',flex:1, marginVertical: 10,flexWrap:"wrap"
    },
    buttonrowView: {
        flexDirection: 'row', justifyContent: 'center', alignContent: "center", marginVertical: 10
    }
});

//make this component available to the app
// export default AdmissionDetails;
