//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,FlatList } from 'react-native';
import { OBGYNHistorySectionHeading,HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component
const pageName = "isOBGYNSurgeries"

class OBGYNSurgeries extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            OBGYNSurgeriesHistoryList: {},

            OBGYNSurgeryList:[],

            OBGYNAdditionSurgeryList:[]

        }
    }

    componentWillReceiveProps(props){
        this.state.selectedPageName = props.selectedPageName;

        if(props.refreshRighSideComponentName == pageName ){
            this.getOBGYNSurgery();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount(){
        this.getOBGYNSurgery()
    }
    

    getOBGYNSurgery() {
        var service_url = Constants.OBGYN_SURGERY_GET_POST + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getOBGYNSurgerySuccess,
            this.getOBGYNSurgeryFailure
        );
    }


    getOBGYNSurgerySuccess = success =>{
        if(success.status== "success"){
            // console.log("TO COPY" + JSON.stringify(success))
            this.setState({
                OBGYNSurgeryList :success.data.obstetrical_surgery_history,
                OBGYNAdditionSurgeryList:success.data.obstetrical_additional_surgery_history,
            },()=>{
                this.editHistory()
            })
        }
        // console.log("testdclkkcnowjenc ----------> "+JSON.stringify(success))
    }
    getOBGYNSurgeryFailure = error =>{}

    selectedRightSideView(pageName) {
        this.props.selectedRightSideView(pageName);
        var states = this.state;
        var data ={
            OBGYNSurgeryList:states.OBGYNSurgeryList,
            OBGYNAdditionSurgeryList:states.OBGYNAdditionSurgeryList
        }
        // var data = states.OBGYNSurgeryList.concat(states.OBGYNAdditionSurgeryList)
        setTimeout( () => {
            this.props.editHistory(data, pageName)
         },100);
    }
    editHistory() {
        var states = this.state;
        var data ={
            OBGYNSurgeryList:states.OBGYNSurgeryList,
            OBGYNAdditionSurgeryList:states.OBGYNAdditionSurgeryList
        }

        // var data = states.OBGYNSurgeryList.concat(states.OBGYNAdditionSurgeryList)
        setTimeout(() => {
            this.props.editHistory(data, pageName)
        }, 100);
     
    }

    renderContent(heading, value, year) {
        // console.log(year)
        var getYears = "";
        if(year){
            getYears=" on "+year;
        }else{

        }
        return (
            <View>
                <HistorySectionRightSideUI heading={heading} value={value} details={year ? getYears :null} />

                {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}{year?", on "+year:null}</Text> */}
            </View>
        );
    }

    renderHistorySection() {
        // var states = this.state;
        var { OBGYNAdditionSurgeryList, OBGYNSurgeryList} = this.state;
        // var data = OBGYNSurgeryList.concat(OBGYNAdditionSurgeryList)
        var data = OBGYNSurgeryList;
        return (
            <View style={{marginBottom:10}}>
                <View style={{ flexDirection: 'row',marginTop:25, flexWrap:'wrap',flex:1}}>

                    {data && data.length > 0 &&
                    data.map((item,index)=>{
                        return(
                            <View style={{width:"33%"}} key={index}>
                                {this.renderContent(item.surgery_name, item.is_surgery_done ? "Yes" : "No", item.surgery_year)}
                            </View>
                        )
                    })                    
                    }
                    {OBGYNAdditionSurgeryList && OBGYNAdditionSurgeryList.length > 0 &&
                    OBGYNAdditionSurgeryList.map((item,index)=>{
                        return(
                            <View style={{width:"33%" }} key={index}>
                                {this.renderContent(item.surgery_name, "Yes", item.surgery_year)}
                            </View>
                        )
                    })                    
                    }

                    {/* <FlatList 
                        data={this.state.OBGYNSurgeryList}
                        numColumns={3}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        showsHorizontalScrollIndicator={false}
                        // style={{}}
                        containerStyle={{ justifyContent:'center'}}
                        renderItem={({ item }) => {
                            <View style={{ flex: 0.33}}>
{
                        this.renderContent(item.surgery_name, item.is_surgery_done ? "yes" : "No", item.surgery_year)

}
                            </View>
                        }
                        }
                        // this.renderTextBtnSelectable(item, false, false, selectKey, selectedValue, returnCallBack, keyInObject)}
                    /> */}

                    {/* <View style={{ flex: 0.33 }}>
                        {this.renderContent("Infertility-Surgery","yes","2018")}
                    </View> */}
                    {/* <View style={{ flex: 0.33 }}>
                    {this.renderContent("Ovarian Surgery","yes","test")}
                    </View>
                    <View style={{ flex: 0.35 }}>
                    {this.renderContent("Dilation & Curettage","Yes")}
                    </View>
                </View>
                <View style={{ flexDirection: 'row',marginTop:20}}>
                    <View style={{ flex: 0.32 }}>
                    {this.renderContent("Cystocele Repair","yes","test")}
                    </View>
                    <View style={{ flex: 0.33 }}>
                    {this.renderContent("Tuboplasty","yes","test")}
                    </View>
                    <View style={{ flex: 0.35 }}>
                    {this.renderContent("Pelvic Floo Repair","yes","testr Repair")}
                    </View>
                </View>
                
                <View style={{ flexDirection: 'row',marginTop:20}}>
                    <View style={{ flex: 0.32 }}>
                    {this.renderContent("Tubal Ligation","yes","test")}
                    </View>
                    <View style={{ flex: 0.33 }}>
                    {this.renderContent("Uterine Prolapse Repair","yes","test")}
                    </View>
                    <View style={{ flex: 0.35 }}>
                    {this.renderContent("Laproscopy","yes","test")}
                    </View>
                </View>
                
                <View style={{ flexDirection: 'row',marginTop:20}}>
                    <View style={{ flex: 0.32 }}>
                    {this.renderContent("Caesarean Section","yes","test")}
                    </View>
                    <View style={{ flex: 0.33 }}>
                    {this.renderContent("Hysteroscopy","yes","test")}
                    </View>
                    <View style={{ flex: 0.35 }}>
                    {this.renderContent("Hysterectomy","yes","test")}
                    </View>
                </View>
                

                <View style={{ flexDirection: 'row',marginTop:20}}>
                    <View style={{ flex: 0.32 }}>
                    {this.renderContent("Others","yes","test")}
                    </View>
                    <View style={{ flex: 0.33 }}>
                    </View>
                    <View style={{ flex: 0.35 }}>
                    </View>
                </View> */}
                </View>
            </View>
        )
    }

    
    // renderHistorySection() {
    //     var states = this.state;

    //     // var checkYeastInfection = states.checkYeastInfection.value ? "Yes" : "No";
    //     // var checkOvarianCysts = states.checkOvarianCysts ? "Yes" : "No";
    //     // var checkFibroidsOfTheUterus = states.checkFibroidsOfTheUterus ? "Yes" : "No";
    //     // var checkBacterialVaginosis = states.checkBacterialVaginosis ? "Yes" : "No";
    //     // var checkBleedingSpotting = states.checkBleedingSpotting ? "Yes" : "No";
    //     // var checkUrinaryProblem = states.checkUrinaryProblem ? "Yes" : "No";

    //     var selectedMenopauseAge = states.selectedMenopauseAge;
    //     var checkBleedingSpottingCommentBox = states.checkBleedingSpottingCommentBox;
    //     var checkBacterialVaginosisCommentBox = states.checkBacterialVaginosisCommentBox;
    //     var checkUrinaryProblemCommentBox = states.checkUrinaryProblemCommentBox;
    //     var checkHormoneReplacementCommentBox = states.checkHormoneReplacementCommentBox;
    //     var checkGynaecologicalHistory = states.checkGynaecologicalHistory;

    //     return (
    //         <View>
    //             {this.renderHistorySectionFields(
    //                 "Have you ever had yeast infection?",
    //                 states.checkYeastInfection.value,
    //                 "Have you been told you have fibroids of the uterus?",
    //                 states.checkFibroidsOfTheUterus.value
    //             )}

    //             {this.renderHistorySectionFields(
    //                 "Have you ever had ovarian cysts?",
    //                 states.checkOvarianCysts.value,
    //                 "Have you been treated for a vaginal bacterial infection (bacterial vaginosis)?",
    //                 states.checkBacterialVaginosis.value ? states.checkBacterialVaginosis.value : "" + states.checkBacterialVaginosis.value && checkBacterialVaginosisCommentBox ? "," : "" + checkBacterialVaginosisCommentBox
    //             )}

    //             {this.renderHistorySectionFields(
    //                 "Dose bleeding / spotting occur after intercourse?",
    //                 states.checkBleedingSpotting.value ? states.checkBleedingSpotting.value :"" + states.checkBleedingSpotting.value && checkBleedingSpottingCommentBox?",":"" + checkBleedingSpottingCommentBox,
    //                 "Did you have any urinary problems such as infections, frequency, loss of bladder control, blood in urine, ect.,",
    //                 states.checkUrinaryProblem.value ? states.checkUrinaryProblem.value : "" + states.checkUrinaryProblem.value && checkUrinaryProblemCommentBox ? "," : "" + checkUrinaryProblemCommentBox
    //             )}

    //             {this.renderHistorySectionFields(
    //                 "If in menopause, at what age did it occur?",
    //                 selectedMenopauseAge,
    //                 "Have you used any hormone replacement",
    //                 checkHormoneReplacementCommentBox ? checkHormoneReplacementCommentBox : ""
    //             )}

    //             {this.renderHistorySectionFields("other Gynaecological history", checkGynaecologicalHistory)}

    //         </View>
    //     )
    // }


    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading

                    

                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                    heading={"OBGYN Surgeries"}
                    editImage={"pencil"}
                    editHistory={this.editHistory.bind(this)}
                // deleteImage={"trash"}
                />
                {this.renderHistorySection()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop:20,
        flex: 1,
    },
});

//make this component available to the app
export default OBGYNSurgeries;
