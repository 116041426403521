import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import AsyncStorage from "../../../AsyncStorage";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'
import Styles from "../../../styles/Style";
import { StyleSharp } from "@material-ui/icons";

const platform = Platform.OS;

export default class SystemicIllnessNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      favoriteDrugs: [],
      selectedEye: {},
      systamicAllergySelectedItems:[],
      id: null,
      name: "",
      no_of_years: "",
      no_of_months: "",
      no_of_days: 0,
      complaints: "",
      comments: "",

      isEdit: false,
      newItem: '',
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      isDisable:false
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getFavoriteDrugs();
  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;

    if (props.editItem) {

      if (props.editItem.title === "drugallergy") {

        let item = props.editItem.item;

        if (id !== item.id) {

          // selectedEye have to set up here

          var selectedEye={label:item.name,value:item.name}

          // let selectedEye = "";


          // favoriteDrugs.map((favItem) => {
          //   if (favItem.label === item.name) {
          //     // selectedEye = favItem.value;
          //     selectedEye = favItem;
          //   }
          // })

          this.setState({
            id: item.id,
            selectedEye: selectedEye,
            name: item.name,
            no_of_years: item.no_of_years,
            no_of_months: item.no_of_months,
            no_of_days: item.no_of_days,
            comments: item.comments
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  getFavoriteDrugs = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_DRUGALLERGY;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
    );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];
      let data = response.data;

      var getDefaultDrugs = Constants.DEFAULT_DRUGS_IN_DRUGALLERGY

      getDefaultDrugs.map((item) => {
        drugs.push(item);
      });

      if (data && data.map) {

        data.map((item) => {

          drugs.push({ value: item, label: item });
        });
      }

      //if(drugs.length === 0){ drugs = [{ "value": "Dolo 650", "label": "Dolo 650" }]; }

      var field = this.state;
      field["favoriteDrugs"] = this.removeDuplicate(drugs);
      this.setState({ field });

    }
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }


  addComplaint = event => {
    this.setState({isDisable:true})
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_DRUG_ALLERGY;

    let drug_name = "";

    states.favoriteDrugs.map((item) => {

      if (item.value === states.selectedEye.value) {

        // drug_name = item.label;
        drug_name = item.label;
      }
    });


    // alert

    let data = {


      "name": drug_name,
      "no_of_years": states.no_of_years || 0,
      "no_of_months": states.no_of_months || 0,
      "no_of_days": states.no_of_days,
      "comments": states.comments
    };

    if (states.id) {
      data['id'] = states.id;
    }

    if(states.id){
      data["allergy"]=[drug_name];
    }else{
      var datas=[]
      for(var i=0;i<states.systamicAllergySelectedItems.length;i++){
        datas.push(states.systamicAllergySelectedItems[i].value)
      }
      data["allergy"]=datas;
      data["name"]=datas?datas[0]:"";
    }
    
    // if (drug_name) {
      data = {
        "allergies": [data],
        "appointment_id": this.state.patientAppointment.appointment_id,
        "patient_id": this.state.patientAppointment.patient_id
      }

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getMedicationSuccess,
        this.getMedicationFailure
      );
    // }
  };


  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };


  getMedicationSuccess = response => {
    if (response.status === "success") {
      this.setState({
        isDisable:false
      })
      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });

      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData("drugallergy");
      this.props.showResposeValue("success", response.message)
    } else {
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error", response.message)
    }
  };

  getMedicationFailure = error => {
    this.setState({
      isDisable:false
    })
    // this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)

  };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = {};
    states['name'] = "";
    states['no_of_years'] = "";
    states['no_of_months'] = "";
    states['no_of_days'] = "0";
    states['comments'] = "";
    states["systamicAllergySelectedItems"]=[]
    states['id']=""
    this.setState({ states },()=>{
      this.props.clearEditedItem("drugallergy",{})
    });

  }

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  addToSuggestionList = (event) => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if (newItem) {

      favoriteDrugs.push({ value: newItem, label: newItem });

      field["favoriteDrugs"] = this.removeDuplicate(favoriteDrugs);
      field["selectedEye"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }



  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" || key == "systamicAllergySelectedItems") {

      if (this.state.id) {
        var { selectedEye } = this.state;
        selectedEye = selectedvalue;
        this.setState({ selectedEye: selectedvalue })
        // alert(JSON.stringify(selectedEye))
      } else {
        var { systamicAllergySelectedItems } = this.state;

        var getSelectedValue;
        var spliceData;

        if (systamicAllergySelectedItems.length > 0) {
          for (var i = 0; i < systamicAllergySelectedItems.length; i++) {
            if (systamicAllergySelectedItems[i].value == selectedvalue.value) {
              spliceData = systamicAllergySelectedItems[i];
              systamicAllergySelectedItems.splice(i, 1)
              var data = this.removeDuplicate(systamicAllergySelectedItems);
              this.setState({ systamicAllergySelectedItems: data })
              return
            } else {
              if (spliceData && spliceData.value !== selectedvalue.value) {
              } else {
                getSelectedValue = selectedvalue
              }
            }
          }

          if (getSelectedValue) { systamicAllergySelectedItems.push(selectedvalue); }
        } else {
          systamicAllergySelectedItems.push(selectedvalue)
        }

        var data = this.removeDuplicate(systamicAllergySelectedItems);
        this.setState({ systamicAllergySelectedItems: data })

      }

    }
  }

  render() {

    return (
     <TouchableWithoutFeedback onPress={this.addToSuggestionList.bind(this)}>
      <View>

        {/* {this.renderSubHeadingBorder("Systemic Illness", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Systemic Illness"} clearAllData={this.clearComplaint.bind(this)}/>
        <CommonSectionHeader heading={"Drug Allergy"}  />
        {this.renderSubHeadingBorder("", false)}

        {/* {this.renderTitleWithMultipleBtn("Drug Allergy", this.state.favoriteDrugs, 3, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), true, true)} */}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.favoriteDrugs.map((item, index) => {
            // var data = {
            //     label: item.speciality_name,
            //     value: item.id
            // }
            var data
            if(this.state.id){
              data = this.state.selectedEye
            }else{
              data = this.state.systamicAllergySelectedItems
            }

            // console.log("json item----> "+JSON.stringify(item))
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={data}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={this.state.id ? "selectedEye" :"systamicAllergySelectedItems"} />
              </View>
            )
          })
        }
        </View>
        {/* {this.renderIconBtn("plus", "New", true)} */}
        {this.state.isEdit ?
          <View
            style={styles.DrugAllergyTextBoxView}
          ><TextInput
              underlineColorAndroid="transparent"
              placeholder="type here"
              placeholderTextColor={color.black}
              style={[styles.DrugAllergyTextInput, {
                borderColor: color.black,
                color: color.black},Styles.allButtonBorderRadius]}
              ref={text => (this.nameInput = text)}
              // defaultValue=""
              autoCapitalize="none"
              value={this.state.newItem}
              onChangeText={text => this.setState({ selectedEye: '', newItem: text })}
              onSubmitEditing={this.addToSuggestionList.bind(this)}
            /></View> :
          <TouchableOpacity style={{alignSelf:'flex-start'}} onPress={() => { this.triggerNewItemAdd() }} disabled={this.state.isLocked}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
          // this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this))
        }
        <View style={{ flexDirection: "row", marginTop: '1.2vw' }}>
           <View style={styles.YearView}>
             <Text style={{fontSize: '0.9vw'}}>Year</Text>
           </View>  
           <View style={styles.MonthView}>
             <Text style={{fontSize: '0.9vw'}}>Months</Text>
           </View>  
         </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: '1.2vw',
            marginBottom: '2vw'
            // , justifyContent: "space-evenly"
          }}
        >
          {this.renderNumberTxtWithHeader("", "no_of_years", this.state.no_of_years,this.state.isLocked)}

          {this.renderNumberTxtWithHeader("", "no_of_months", this.state.no_of_months,this.state.isLocked)}

           {/*{this.renderNumberTxtWithHeader("Days", "no_of_days", this.state.no_of_days)} */}

        </View>

        {/* {this.renderTextFieldView("Comments")} */}
        {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4,'',{},"",false,this.state.isLocked)}

        <View style={{ marginTop: '1.2vw' ,alignSelf:"center" }}>
          <TouchableOpacity  disabled={(this.state.isDisable || this.state.isLocked) ? true : false}  onPress={this.addComplaint.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
        <View>
          {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Drug Allergy Created Successfully' : 
                    this.state.errorMessageFlag ?'Drug Allergy Failed' : null}</Text>
                </View>
              ) :null} */}
        </View >
      </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      DrugAllergyTextBoxView: {
        height: '2vw',
        marginTop: '0.6vw',
        // backgroundColor: color.white,
        width: '10.6vw'
      },
      DrugAllergyTextInput: {
        // borderRadius: 20,
        borderWidth: 1,
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        alignSelf:'flex-start'
      },
      YearView: {
        backgroundColor:"#DCDCDC",
        width:'5.6vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center"
      },
      MonthView: {
        backgroundColor:"#DCDCDC",
        width:'7vw',
        height:'2vw',
        justifyContent:"center",
        alignItems:"center",
        marginLeft:'0.6vw'
      }

})