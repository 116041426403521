import React, { Component } from 'react';
import { View, ScrollView, Dimensions, StyleSheet, Text } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton ,HistoryFilledBy} from '../../BaseComponent'
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import { PastHistory } from './PastHistory'
import { PastHistoryNew } from './PastHistoryNew'

import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";


import { FamilyHistory } from './FamilyHistory'
import { FamilyHistoryNew } from './FamilyHistoryNew'
import { EducationHistoryNew } from './EducationHistoryNew'
import { EducationHistory } from './EducationHistory'
import { OccupationalHistoryNew } from './OccupationalHistoryNew'
import { OccupationalHistory } from './OccupationalHistory'
import { SexualAndMaritalHistoryNew } from './SexualAndMaritalHistoryNew'
import { SexualHistory } from './SexualHistory'
import { PreMorbidHistoryNew } from './PreMorbidHistoryNew'
import { PreMorbidHistory } from './PreMorbidHistory'










import moment from "moment";
var Messages = require("../../../../utils/InfoMessages")

const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
    { label: "Past History", value: "pastHistory" },
    { label: "Family History", value: "familyHistory" },
    { label: "Education", value: "educationHistory" },
    { label: "Occupational History", value: "occupationalhistory" },
    { label: "Sexual & Marital History", value: "sexualAndMaritalHistory" },
    { label: "Pre-Morbid Personality", value: "preMorbidHistory" },

];

// const categoryList = [
//     { label: "Hamilton Anxiety Rating Scale", value: "HamiltonAnxietyRatingScale" },
//     { label: "Brief Psychiatric Rating Scale", value: "BriefPsychiatricRatingScale" },
//     { label: "Hamilton Depression Rating Scale", value: "HamiltonDepressionRatingScale" },
//     { label: "Young’s Mania Rating Scale", value: "YoungsManiaRatingScale" },
//     { label: "Yale-Brown Obsessive Compulsive Scale", value: "YaleBrownObsessiveCompulsiveRatingScale" },
//     { label: "Adhd Rating Scale - IV", value: "AdhdRatingScale" },
//     { label: "Indian Scale for assessment of autism", value: "IndianScaleforAssessmentofAutism" },
//     { label: "Childhood Autism Rating Scale", value: "ChildhoodAutismRatingScale" },
//     { label: "M-CHAT-R TM", value: "MChatRtm" },
//     { label: "Screen For Child Anxiety Related Disorders(Scared) Child Version", value: "ScaredChildVersion" },
//     { label: "Children's Yale-Brown Obsessive Compulsive Scale", value: "CyBocs" },
// ]

export class PsychiatryHistory extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            selectedSection: "pastHistory",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            scrollPosition: 0,
            isheading: false,

            information:"",
            reliability:"",
            historyFilledBy:"",
            historyFilledBYID:"",

            scrollToIndex:0, // index of the left side components
            setScrollReff:null,
            dataSourceCords:[]
        }
    }

    componentDidMount(){
        this.getHistoryFilledByDetails()
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    showResposeValue(type, message) {
        this.props.showResposeValue(type, message);
    }

    selectedRightSideView(pageName, isheading) {
        this.setState({
            selectedSection: pageName,
            isheading: isheading
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };


    changeTabSection(screen,index) {
        var states = this.state;
        states["selectedSection"] = screen;
        // states["scrollToIndex"] = parseInt(index+1 != '' ? index+1 : 0);
        states["scrollToIndex"] = parseInt(index+1);

        

        // setScrollToIndex(
        //     parseInt(scrollToIndex != '' ? scrollToIndex : 0)
        //   );
        this.setState({
            states
        },()=>{
            this.scrollHandler()
        })
    }
    renderTopNavigation() {
        return (
            <View style={{ marginBottom: 10 }}>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={this.state.selectedSection} />
            </View>
        )
    }


    listOfLeftSideComponents(){
        var datas = [
                        // <View style={Style.doctorNotesSectionView}>
                                <PastHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,
                            // <View style={Style.doctorNotesSectionView}>
                                <FamilyHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,

                            // <View style={Style.doctorNotesSectionView}>
                                <EducationHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,

                            // <View style={Style.doctorNotesSectionView}>
                                < OccupationalHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,

                            // <View style={Style.doctorNotesSectionView}>
                                < SexualHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,

                            // <View style={Style.doctorNotesSectionView}>
                                < PreMorbidHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />,
                            // </View>,

                            // <View style={Style.doctorNotesSectionView}>
                            
                                <HistoryFilledBy information={this.state.information}
                                reliability={this.state.reliability}
                                historyFilledBy={this.state.historyFilledBy}
                                onChangeValue={this.onChangeValueHistoryDetails.bind(this)}
                                onPressSaveButton={this.onPressHistorySaveButton.bind(this)}/>
                            // </View>
        ]

        return datas
    }

    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            pastHistory: <PastHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
            />,
            familyHistory: <FamilyHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}


            />,
            educationHistory: <EducationHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}


            />,
            occupationalhistory: <OccupationalHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}

            />,

            sexualAndMaritalHistory: <SexualAndMaritalHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}
            />,

            preMorbidHistory: <PreMorbidHistoryNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isheading={this.state.isheading}
                selectedRightSideView={this.selectedRightSideView.bind(this)}
            />,
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }

    editHistory(item, pageName) {
        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }


     scrollHandler () {
         var {dataSourceCords,scrollToIndex}= this.state
        console.log(dataSourceCords.length, scrollToIndex);
        if (dataSourceCords.length > scrollToIndex) {
          this.state.setScrollReff.scrollTo({
            x: 0,
            y: dataSourceCords[scrollToIndex - 1],
            animated: true,
          });
        } else {
          console.log('Out of Max Index');
        }
      };


    renderParentComponents(item,key){
        return(
            <View
        key={key}
        // style={styles.item}
        style={Style.doctorNotesSectionView}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;

        //   dataSourceCords
          this.state.dataSourceCords[key] = layout.y

        //   dataSourceCords[key] = layout.y;
        //   setDataSourceCords(dataSourceCords);
        this.setState({
            setDataSourceCords:this.state.dataSourceCords
        })


          console.log(this.state.dataSourceCords);
          console.log('height:', layout.height);
          console.log('width:', layout.width);
          console.log('x:', layout.x);
          console.log('y:', layout.y);
        }}>
        {/* <Text style={styles.itemStyle} onPress={() => getItem(item)}>
          {item.id}. {item.title}
        </Text> */}
        {item}
        {/* <ItemSeparatorView /> */}
      </View>
        )
    }
    

    render() {
        var parentComponents = this.listOfLeftSideComponents()
        console.log("listOfLeftSideComponents -------> "+parentComponents.length )
        return (
            <View style={styles.container}>
                <View style={styles.leftSideView}>

                    {this.renderTopNavigation()}
                    
                    <ScrollView

                            ref={(ref) => {
                                // this(ref);
                                this.state.setScrollReff = ref
                                // this.setState({
                                //     setScrollReff:ref
                                // })
                            }}

                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 10, height: screenHeight - 130 }}
                        // contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        <View style={styles.leftSideBody}>

                            {
                                parentComponents && parentComponents.length > 0 ?
                                parentComponents.map((item,index)=>{
                                   return this.renderParentComponents(item,index);
                                })
                                : null
                            }
                            {/* <View style={Style.doctorNotesSectionView}>
                                <PastHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>
                            <View style={Style.doctorNotesSectionView}>
                                <FamilyHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                <EducationHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                < OccupationalHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                < SexualHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                                < PreMorbidHistory selectedPageName={this.state.selectedSection}
                                    patientAppointment={this.state.patientAppointment}
                                    patientInfo={this.state.patientInfo}
                                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                                    editHistory={this.editHistory.bind(this)}
                                    refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                                    refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                                />
                            </View>

                            <View style={Style.doctorNotesSectionView}>
                            
                                <HistoryFilledBy information={this.state.information}
                                reliability={this.state.reliability}
                                historyFilledBy={this.state.historyFilledBy}
                                onChangeValue={this.onChangeValueHistoryDetails.bind(this)}
                                onPressSaveButton={this.onPressHistorySaveButton.bind(this)}/>
                            </View> */}
                        </View>
                    </ScrollView>
                </View>

                <ScrollView showsVerticalScrollIndicator={false} style={styles.rightSideScrollView} >
                    {/* {this.renderRightSideHeader()}
                    {this.renderRatingScaleList()}
                    {this.renderPreviousScoreList()} */}
                    {this.renderComponent()}
                </ScrollView>

            </View>
        )
    }
    onPressHistorySaveButton(){

        var service_url = Constants.PSY_GENERAL_HISTORY_FILLED_BY;
        var data ={
            "appointment_id":this.state.patientAppointment.appointment_id,
            "patient_id": this.state.patientAppointment.patient_id,
            "history_filled_by": this.state.historyFilledBy,
            "informant": this.state.information,
            "reliability": this.state.reliability,
         }

         if(this.state.historyFilledBYID){
             data["id"]=this.state.historyFilledBYID
         }
         
          if(!this.state.historyFilledBy && !this.state.information && !this.state.reliability){    
      
            this.props.showResposeValue("error",Messages.HistoryFieldFilledBy)
          } else {
      
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveHistoryFilledBySuccess,
            this.saveHistoryFilledByFailure
        );
       }
        // alert(JSON.stringify(data))
    }
    saveHistoryFilledBySuccess=success=>{
        if(success.status == "success"){
            this.showResposeValue("success",success.message)
        }else{
            this.showResposeValue("error",success.message)
        }
    }
    saveHistoryFilledByFailure=error=>{
        this.showResposeValue("error",error.message)

    }

    getHistoryFilledByDetails(){

        var service_url = Constants.PSY_GENERAL_HISTORY_FILLED_BY+"?patient_id="+this.state.patientAppointment.patient_id+"&appointment_id="+this.state.patientAppointment.appointment_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHistoryFilledBySuccess,
            this.getHistoryFilledByFailure
        );
    }
    getHistoryFilledBySuccess=success=>{
        if(success.status == "success"){
            if(Object.keys(success.data).length > 0){
                var states = this.state;
                states["historyFilledBYID"]=success.data.id ? success.data.id :"";
                states["historyFilledBy"]=success.data.history_filled_by ? success.data.history_filled_by :"";
                states["information"]=success.data.informant ? success.data.informant :"";
                states["reliability"]=success.data.reliability ? success.data.reliability :"";
                this.setState({ states })
            }   
        }
    }
    getHistoryFilledByFailure=error=>{
        
    }


    onChangeValueHistoryDetails(key,value){
        var states = this.state;
        states[key] =value;
        this.setState({
            states
        })
    }

}


const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    // leftSideScrollView: {
    //     width: "100%", height: screenHeight - 130, backgroundColor: color.white, zIndex: -1
    // },
    leftSideBody: {
        marginHorizontal: 5, marginVertical: 10,
        //  backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})

// scroll view page reff 
// https://aboutreact.com/scroll_to_a_specific_item_in_scrollview_list_view/