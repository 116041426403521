import React, { Component } from "react";
import {
    View, Text, Platform, TouchableOpacity, TextInput, CheckBox,ScrollView 
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";

import Styles from "../../../styles/Style";

const platform = Platform.OS;
const pageTitle = "Anterior Segment";
const pageName = "anteriorSegment";
const isPageName = "isAnteriorSegment";
import OpthamologyService from "../../../network/OpthamologyService"
import Service from "../../../network/Service"
import { Constants } from "../../../utils/Constants"

import AnteriorSegmentDraw from "../../sketch/anterior_segment";
// import { ScrollView } from "react-native-gesture-handler";

export default class AnteriorSegment extends BaseComponentStyle {
    constructor(props) {
        super(props);

        this.state = {
            isLocked:this.props.patientAppointment.is_locked,

            isAnteriorSegment: isPageName === this.props.selectedView ? true : false,
            patientAppointment: this.props.patientAppointment,

            rightEye: false,
            lefytEye: false,
            normal: false,


            lidsRightEye: "",
            anteriorChamberRightEye: "",
            sclaraRightEye: "",
            conjuctivaRightEye: "",
            pupilRightEye: "",
            corneaRightEye: "",
            irisRightEye: "",
            lensRightEye: "",

            lidsLeftEye: "",
            anteriorChamberLeftEye: "",
            sclaraLeftEye: "",
            conjuctivaLeftEye: "",
            pupilLeftEye: "",
            corneaLeftEye: "",
            irisLeftEye: "",
            lensLeftEye: "",

            // file key
            anterior_image_key: "",

            autosuggestionValues:[],
            typedTextBox:""
        };
    }

    triggerNewItem = (key) => {
        this.props.triggerNewItem(key);
    }

    componentWillReceiveProps(props) {

        if(props && props.patientAppointment && props.patientAppointment.patient_id){
            //   this.setState({  isLocked:props.patientAppointment.is_locked })
            this.state.isLocked=props.patientAppointment.is_locked
          }

        let updateFontColor = isPageName === props.selectedView ? true : false;
        if (updateFontColor !== this.props.isAnteriorSegment) {
            this.setState({ isAnteriorSegment: updateFontColor });
        }
    }

    updateState = (value, key,fieldName) => {
        var states = this.state;
        states[key] = value;
        states["typedTextBox"]=key
        this.setState({ states },()=>{
            
        })
    };

    componentDidMount(){
        setTimeout(() => {
            this.getAnteriorSegmentDatas()
        }, 3000);
        
    }

    getAnteriorSegmentDatas(){
        var service_url = Constants.ANTERIOR_SEGMENT_POST_API+"?appointment_id="+this.state.patientAppointment.appointment_id+"&patient_id="+this.state.patientAppointment.patient_id;
       setTimeout(() => {
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getAnteriorSegmentDatasSuccess,
            this.getAnteriorSegmentDatasFailure
          );
       }, 3000);
       
    }
    getAnteriorSegmentDatasSuccess = success =>{

        if(success.data.length > 0){
        for (let i = 0; i < success.data.length; i++) {
            if(success.data[i].eye_type == "RE"){
                var states = this.state
                states["lidsRightEye"]=success.data[i].lids;
                states["anteriorChamberRightEye"]=success.data[i].anterior_chamber;
                states["sclaraRightEye"]=success.data[i].sclera;
                states["conjuctivaRightEye"]=success.data[i].conjuctiva;
                states["pupilRightEye"]=success.data[i].pupil;
                states["corneaRightEye"]=success.data[i].cornea;
                states["irisRightEye"]=success.data[i].iris;
                states["lensRightEye"]=success.data[i].lens;
                
                // states["re_anterior_image_key"]=success.data[i].anterior_image_key;
                // states["anterior_image_key"]=success.data[i].anterior_image_key;
                // states["re_image_json"]=success.data[i].image_json;
                // states["image_json"]=success.data[i].image_json;
                this.setState({ states })

            }else if(success.data[i].eye_type == "LE"){
                var states = this.state
                states["lidsLeftEye"]=success.data[i].lids;
                states["anteriorChamberLeftEye"]=success.data[i].anterior_chamber;
                states["sclaraLeftEye"]=success.data[i].sclera;
                states["conjuctivaLeftEye"]=success.data[i].conjuctiva;
                states["pupilLeftEye"]=success.data[i].pupil;
                states["corneaLeftEye"]=success.data[i].cornea;
                states["irisLeftEye"]=success.data[i].iris;
                states["lensLeftEye"]=success.data[i].lens;
                
                // states["le__anterior_image_key"]=success.data[i].anterior_image_key;
                // states["anterior_image_key"]=success.data[i].anterior_image_key;
                // states["le_image_json"]=success.data[i].image_json;
                // states["image_json"]=success.data[i].image_json;

                this.setState({ states })
            }

            // "anterior_image_key": null,
            // "image_json": "null"
            
        }
    }
    }
    getAnteriorSegmentDatasFailure = error =>{
        //alert("error----------> ");
    }



    renderTextField = (placeholder, key, value,fieldName) => {
        return (
            <View
                style={{
                    marginLeft: 5,
                }}
            >
                <TextInput
                    editable={ !this.state.isLocked}
                    placeholder={placeholder}
                    autoCapitalize="none"
                    value={value}
                    onChangeText={text => {
                        this.updateState(text, key,fieldName);
                        this.getAutosuggestionValues(text,fieldName)
                    }}
                    style={[{
                        width: "80%",
                        height: 37,
                        zIndex:-10,
                        fontSize: 12,
                        backgroundColor: this.state.isLocked ? color.disableComponentColor : color.white,
                        borderWidth: 0.5,
                        // borderRadius: 10,
                        borderColor: color.rightPanelInputBorder,
                        paddingLeft: 15
                        //  textAlign:"center"
                    },Styles.allButtonBorderRadius]}
                />
                {
                    this.state.autosuggestionValues.length > 0 && this.state.typedTextBox == key && value.length > 0?
                    <View style={{position:'absolute',width: "80%",marginTop: 40,}}>
                        <ScrollView style={{maxHeight:400,backgroundColor:'black',borderRadius:10,width: "80%",}}>
                        {
                            this.state.autosuggestionValues.map((item,index)=>{
                                return(
                                <TouchableOpacity key={index}  onPress={()=>{
                                    this.updateState(item, key,fieldName);
                                    this.setState({
                                        autosuggestionValues:[]
                                    })
                                }}>
                                        <Text style={{color:"white",padding:10}}>{item}</Text>
                                </TouchableOpacity>
                                )
                            })
                        }
                        </ScrollView>
                     </View>
                    :null
                }
            </View>
        );
    };


    copyValues(type){

        if(type == "leftEye"){
            if(this.state.lefytEye){
                var states = this.state
                states["lidsLeftEye"]="Normal";
                states["anteriorChamberLeftEye"]="Normal Depth";
                states["sclaraLeftEye"]="Normal";
                states["conjuctivaLeftEye"]="Normal";
                states["pupilLeftEye"]="Normal Size reacting to light";
                states["corneaLeftEye"]="Clear";
                states["irisLeftEye"]="Normal Color & Pattern ";
                states["lensLeftEye"]="Clear";
                this.setState({ states })
            } else {
                var states = this.state
                states["lidsLeftEye"]="";
                states["anteriorChamberLeftEye"]="";
                states["sclaraLeftEye"]="";
                states["conjuctivaLeftEye"]="";
                states["pupilLeftEye"]="";
                states["corneaLeftEye"]="";
                states["irisLeftEye"]="";
                states["lensLeftEye"]="";
                this.setState({ states })
            }
        }

        else if(type == "rightEye")
        {
        if(this.state.rightEye){
            var states = this.state
            states["lidsRightEye"]="Normal";
            states["anteriorChamberRightEye"]="Normal Depth"
            states["sclaraRightEye"]="Normal";
            states["conjuctivaRightEye"]="Normal";
            states["pupilRightEye"]="Normal Size reacting to light";
            states["corneaRightEye"]="Clear";
            states["irisRightEye"]="Normal Color & Pattern ";
            states["lensRightEye"]="Clear";
            this.setState({ states })
        }else{
            var states = this.state
            states["lidsRightEye"]="";
            states["anteriorChamberRightEye"]="";
            states["sclaraRightEye"]="";
            states["conjuctivaRightEye"]="";
            states["pupilRightEye"]="";
            states["corneaRightEye"]="";
            states["irisRightEye"]="";
            states["lensRightEye"]="";
            this.setState({ states })
        }
    }else if(type == "normal"){
        if(this.state.normal){
            var states = this.state;

            states["lidsRightEye"]="Normal";
            states["anteriorChamberRightEye"]="Normal Depth"
            states["sclaraRightEye"]="Normal";
            states["conjuctivaRightEye"]="Normal";
            states["pupilRightEye"]="Normal Size reacting to light";
            states["corneaRightEye"]="Clear";
            states["irisRightEye"]="Normal Color & Pattern ";
            states["lensRightEye"]="Clear";

            states["lidsLeftEye"]="Normal";
            states["anteriorChamberLeftEye"]="Normal Depth";
            states["sclaraLeftEye"]="Normal";
            states["conjuctivaLeftEye"]="Normal";
            states["pupilLeftEye"]="Normal Size reacting to light";
            states["corneaLeftEye"]="Clear";
            states["irisLeftEye"]="Normal Color & Pattern ";
            states["lensLeftEye"]="Clear";

            this.setState({ states })
        }else{
            var states = this.state;
            states["lidsRightEye"]="";
            states["anteriorChamberRightEye"]="";
            states["sclaraRightEye"]="";
            states["conjuctivaRightEye"]="";
            states["pupilRightEye"]="";
            states["corneaRightEye"]="";
            states["irisRightEye"]="";
            states["lensRightEye"]="";

            states["lidsLeftEye"]="";
            states["anteriorChamberLeftEye"]="";
            states["sclaraLeftEye"]="";
            states["conjuctivaLeftEye"]="";
            states["pupilLeftEye"]="";
            states["corneaLeftEye"]="";
            states["irisLeftEye"]="";
            states["lensLeftEye"]="";

            this.setState({ states })
        }
    }

    }


    anteriorSegmentTextBoxRow(header, placeholderRight, keyRight, valueRight, placeholderLeft, keyLeft, valueLeft,) {
        return (
            <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 5 ,zIndex:-20}}>
                <View style={{ flex: 0.2, backgroundColor: "transparant", justifyContent: 'center' }}>
                    <Text style={{ fontWeight: "700" }}>{header}</Text>
                </View>
                <View style={{ flex: 0.4, backgroundColor: "transparant" }}>
                    {this.renderTextField(placeholderLeft, keyLeft, valueLeft,header)}
                </View>
                <View style={{ flex: 0.4, backgroundColor: "transparant" }}>
                    {this.renderTextField(placeholderRight, keyRight, valueRight,header)}
                </View>
            </View>
        );
    }

    copyCheckBoxValues(key) {
        this.setState({autosuggestionValues:[]})
        if (key == "rightEye") {
            if(this.state.lefytEye){
                this.setState({ rightEye: !this.state.rightEye, normal: true },()=>{
                    
                    if(!this.state.rightEye){
                        this.setState({ normal: false },()=>{ this.copyValues("rightEye")})
                    }else{
                        this.copyValues("rightEye")
                    }
                })
            }else{
                this.setState({ rightEye: !this.state.rightEye },()=>{this.copyValues("rightEye")})
            }
        } else if (key == "leftEye") {
            if(this.state.rightEye){
                this.setState({ lefytEye: !this.state.lefytEye, normal: true },()=>{
                    
                    if(!this.state.lefytEye){
                        this.setState({ normal: false },()=>{ this.copyValues("leftEye")})
                    }else{
                        this.copyValues("leftEye")
                    }
                    
                })
            }else{
                this.setState({ lefytEye: !this.state.lefytEye },()=>{this.copyValues("leftEye")})
            }
            
        } else if (key == "normal") {

            if(this.state.rightEye && this.state.lefytEye){
                this.setState({
                    rightEye: false,
                    lefytEye: false,
                    normal: false
                },()=>{this.copyValues("normal")})
            }else{
            this.setState({
                rightEye: true,
                lefytEye: true,
                normal: true
            },()=>{this.copyValues("normal")})
        }
        }
    }

    renderCheckBox(label, value, key) {
        return (
            <View style={{ flexDirection: 'row', marginRight: 30 }}>
                <CheckBox   disabled={this.state.isLocked} value={value}
                    onValueChange={() => {
                        this.copyCheckBoxValues(key)
                    }}
                    disabled={this.state.isLocked} 
                />
                <Text style={{ marginLeft: 10, fontSize: 12, color: "#888888" }}>{label}</Text>
            </View>
        );
    }



    saveAnteriorSegment() {

       // console.log("anterior------------->  "+JSON.stringify(this.state.patientAppointment))

        var service_url = Constants.ANTERIOR_SEGMENT_POST_API;
        const data = new FormData();

        var states = this.state

        data.append('appointment_id',states.patientAppointment.appointment_id);
        data.append('patient_id',states.patientAppointment.patient_id);
        data.append('le_lids',states.lidsLeftEye);
        data.append('le_anterior_chamber',states.anteriorChamberLeftEye);
        data.append('le_sclera',states.sclaraLeftEye);
        data.append('le_pupil',states.pupilLeftEye);
        data.append('le_cornea',states.corneaLeftEye);
        data.append('le_iris',states.irisLeftEye);
        data.append('le_lens',states.lensLeftEye);
        data.append('le_conjuctiva',states.conjuctivaLeftEye);
        
        // data.append('le_anterior_image_key', states.anterior_image_key);
        // data.append('le_image_json', states.image_json);

        data.append('re_lids',states.lidsRightEye);
        data.append('re_anterior_chamber',states.anteriorChamberRightEye);
        data.append('re_sclera',states.sclaraRightEye);
        data.append('re_pupil',states.pupilRightEye);
        data.append('re_cornea',states.corneaRightEye);
        data.append('re_iris',states.irisRightEye);
        data.append('re_lens',states.lensRightEye);
        data.append('re_conjuctiva',states.conjuctivaRightEye);

        // data.append('re_anterior_image_key', "");
        // data.append('re_image_json', "");

        OpthamologyService.getInstance().documentUploadPost(
            service_url,
            data,
            this,
            this.saveAnteriorSegmentSuccess,
            this.saveAnteriorSegmentFailure,
            'multipart/form-data'
        );

    }

    saveAnteriorSegmentSuccess = (success) => {
        if (success.data.status == "success") {
          //  console.log(JSON.stringify(success))
        // this.refs.anteriorSegmentDraw._save()
            this.props.showResposeValue("success", success.data.message)
        } else {
            this.props.showResposeValue("error", success.data.message)
        }
    }
    saveAnteriorSegmentFailure = (error) => {
        this.props.showResposeValue("error", error.data.message)
    }


    getAutosuggestionValues(searchKey,fieldName){
        var service_url = Constants.ANTERIOR_SEGMENT_AUTOSUGGESTION_GET+"?search_key="+searchKey+"&field_name="+fieldName;
        if(searchKey){
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.autosuggestionValuesSuccess,
            this.autosuggestionValuesFailure
          );
        }
    }

    autosuggestionValuesSuccess = success =>{
        if(success.status == "success"){
           // console.log("success ---------> "+JSON.stringify(success))
            this.setState({autosuggestionValues : success.data})
        }
    }
    autosuggestionValuesFailure = error =>{
        console.log("error ---------> "+JSON.stringify(error))
    }
    showResposeValue(type,meaasge){
        this.props.showResposeValue(type,meaasge)
      }

    render() {

        return (
            <View>
                {this.renderTitleBorder(this.state.isAnteriorSegment, pageTitle, isPageName, this.triggerNewItem)}

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      {this.renderCheckBox("Right Eye", this.state.rightEye, "rightEye")}
                        {this.renderCheckBox("Left Eye", this.state.lefytEye, "leftEye")}
                        {this.renderCheckBox("Normal", this.state.normal, "normal")}
                    </View>
                    <View style={{flexDirection:'row'}}>

                    {/* <TouchableOpacity  disabled={this.state.isLocked} onPress={()=>{
                        this.setState({
                            rightEye:false,lefytEye:false,normal:false
                        },()=>{this.copyValues("normal")})
                        }}  disabled={this.state.isLocked}  style={{ marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
                            <Text style={{ color: color.white }}>{"Clear"}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity   disabled={this.state.isLocked} onPress={()=>{
                            this.saveAnteriorSegment()
                        }}  disabled={this.state.isLocked}  style={{ marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
                            <Text style={{ color: color.white }}>{"Save"}</Text>
                        </TouchableOpacity> */}



                        <TouchableOpacity disabled={this.state.isLocked}
                            onPress={() => {
                                this.setState({
                                    rightEye: false, lefytEye: false, normal: false
                                }, () => { this.copyValues("normal") })
                            }}
                            style={[{
                                marginRight: 30, paddingTop: 8, paddingLeft: 18,
                                paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
                            }, Styles.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Clear"}</Text>
                        </TouchableOpacity>




                        <TouchableOpacity disabled={this.state.isLocked}
                            onPress={() => {
                                this.saveAnteriorSegment()
                            }}
                            style={[{
                                marginRight: 30, paddingTop: 8, paddingLeft: 18,
                                paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark,
                            }, Styles.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Save"}</Text>
                        </TouchableOpacity>

                    </View>
                </View>


                {/* lidsRightEye: ""
                anteriorChamberRightEye: ""
                sclaraRightEye: ""
                conjuctivaRightEye: ""
                pupilRightEye: ""
                corneaRightEye: ""
                irisRightEye: ""
                lensRightEye: ""

                lidsLeftEye:""
                anteriorChamberLeftEye:""
                sclaraLeftEye:""
                conjuctivaLeftEye:""
                pupilLeftEye:""
                corneaLeftEye:""
                irisLeftEye:""
                lensLeftEye:"" */}

                <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 5 }}>
                    <View style={{ flex: 0.2, backgroundColor: "transparant", justifyContent: 'center' }}>
                       
                    </View>
                    <View style={{ flex: 0.4, backgroundColor: "transparant",paddingLeft:30 }}>
                        <Text style={{ fontWeight: "700" }}>{"Right Eye"}</Text>
                    </View>
                    <View style={{ flex: 0.4, backgroundColor: "transparant",paddingLeft:30 }}>
                        <Text style={{ fontWeight: "700" }}>{"Left Eye"}</Text>
                    </View>
                </View>

                <View style={{zIndex:-1}}>{this.anteriorSegmentTextBoxRow("Lids", "Type Remarks", "lidsLeftEye", this.state.lidsLeftEye,"Type Remarks", "lidsRightEye", this.state.lidsRightEye,)}</View>
                <View style={{zIndex:-4}}>{this.anteriorSegmentTextBoxRow("Conjunctiva", "Type Remarks", "conjuctivaLeftEye", this.state.conjuctivaLeftEye,"Type Remarks", "conjuctivaRightEye", this.state.conjuctivaRightEye)}</View>
                <View style={{zIndex:-3}}>{this.anteriorSegmentTextBoxRow("Sclera","Type Remarks", "sclaraLeftEye", this.state.sclaraLeftEye, "Type Remarks", "sclaraRightEye", this.state.sclaraRightEye,)}</View>
                <View style={{zIndex:-6}}>{this.anteriorSegmentTextBoxRow("Cornea",  "Type Remarks", "corneaLeftEye", this.state.corneaLeftEye,  "Type Remarks", "corneaRightEye", this.state.corneaRightEye)}</View>
                <View style={{zIndex:-2}}>{this.anteriorSegmentTextBoxRow("Anterior Chamber",  "Type Remarks", "anteriorChamberLeftEye", this.state.anteriorChamberLeftEye,"Type Remarks", "anteriorChamberRightEye", this.state.anteriorChamberRightEye)}</View>
                <View style={{zIndex:-5}}>{this.anteriorSegmentTextBoxRow("Pupil","Type Remarks", "pupilLeftEye", this.state.pupilLeftEye , "Type Remarks", "pupilRightEye", this.state.pupilRightEye,)}</View>
                <View style={{zIndex:-7}}>{this.anteriorSegmentTextBoxRow("Iris", "Type Remarks", "irisLeftEye", this.state.irisLeftEye,  "Type Remarks", "irisRightEye", this.state.irisRightEye)}</View>
                <View style={{zIndex:-8}}>{this.anteriorSegmentTextBoxRow("Lens", "Type Remarks", "lensLeftEye", this.state.lensLeftEye , "Type Remarks", "lensRightEye", this.state.lensRightEye)}</View>
                {/* <View style={{zIndex:-10}}> */}
                {/* <ScrollView
  horizontal={true}> */}
                <View style={{ zIndex:-10, flexDirection: "row", justifyContent: "center", minWidth: 44,marginHorizontal:30 }}>
                <AnteriorSegmentDraw patientAppointment={this.props.patientAppointment}  showResposeValue={this.showResposeValue.bind(this)}
                imageKey={this.state.anterior_image_key} returnImage={this.updateImageParam.bind(this)} 
                // onRef={ref => (this.anteriorSegmentDraw = ref)}
                ref="anteriorSegmentDraw" 
                />
                </View>
                {/* </ScrollView> */}
            </View>
        );
    }

    updateImageParam = (obj) => {

        // this.setState({
        //     anterior_image_key : obj.report_photo,
        //     image_json : obj.image_json
        // });

    }
}


// const styles = StyleSheet.create({
//     container: {
//       flex: 1,
//       alignItems: "center",
//       justifyContent: "center",
//     },
//     checkboxContainer: {
//       flexDirection: "row",
//       marginBottom: 20,
//     },
//     checkbox: {
//       alignSelf: "center",
//     },
//     label: {
//       margin: 8,
//     },
//   });
