//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput, TouchableOpacity, Image } from 'react-native';
const screenHeight = Dimensions.get("window").height;
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";
var Messages = require('../../../../utils/InfoMessages')
import moment from 'moment';

import { CommonHistorySectionHeader, HistoryYesOrNoSection, LabelWithTextBoxDR, CommonButton, LabelWithCalender, CommonAddButton, DoctorNotesCommonRightSideHeader } from '../../BaseComponent'
// create a component
import { NavigationTopHeader } from "../../BaseComponent"
const givenDate = "#E8FAF4";
const red = "#F78282";
const green = "#04B7B1";
const orange = "#FFC474";
const disableColor = "#F2F2F2"

const navigationHeaderList = [
    { label: "Immunization", value: "Immunization" }

]
const patientAgeList = [
    { label: "At Birth", value: "Birth" },
    { label: "6th Week", value: "6 weeks" },
    { label: "10th Week", value: "10 weeks" },
    { label: "14th Week", value: "14 weeks" },
    { label: "9 Month(After 270 days)", value: "9 Months" },
    { label: "16 - 24 Months", value: "16-24 Months" },
    { label: "5 - 6 Years", value: "5-6 Years" },
    { label: "10 Years", value: "10th Year" },
    { label: "16 Years", value: "16th Year" },

]

const tabelBorderColor = "#888888";
export class Immunization extends Component {

    constructor(props) {
        super(props);
        this.state = {

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            scrollPosition: 0,
            immunizationDetails: [],

            selectedPatientAge: {},
            patientAgeList: patientAgeList,

            listOfAtBirths: [],
            selectedAtBirth: {},
            selectedAtBirthObj: {},

            comments: "",
            givenDate: "",

            atBirthList: [],
            listOfWeek6: [],
            listOfWeek10: [],
            listOfWeek14: [],
            listOfMonth9: [],
            listOf16_24Months: [],
            listOf5_6Years: [],
            listOf10Years: [],
            listOf16Years: [],

            responseType: "",
            responseMeaasge: "",
        }
    }


    clearAllDatas() {
        var states = this.state;
        // states["responseType"] = "";
        // states["responseMeaasge"] = "";
        states["comments"] = "";
        states["givenDate"] = "";
        states["selectedAtBirth"] = {};
        states["selectedAtBirthObj"] = {};
        states["selectedPatientAge"] = {};
        this.setState({ states }, () => {
            this.getImmunizationTemp()
        })
    }


    componentDidMount() {
        this.getImmunizationDetails();
        this.getImmunizationTemp()
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    getImmunizationTemp = () => {

        if (this.state.selectedPatientAge && this.state.selectedPatientAge.value) {


            var service_url = Constants.IMMUNIZATION_TEMP + "template/?patient_age=" + this.state.selectedPatientAge.value;
            OpthamologyService.getInstance().getComplaints(
                service_url,
                this,
                this.getImmunizationTempSuccess,
                this.getImmunizationTempFailure
            );
        } else {
            this.setState({
                listOfAtBirths: []
            })
        }
    };

    getImmunizationTempSuccess = success => {


        if (success.status == "success") {
            this.setState({
                listOfAtBirths: success.data
            })
        }

    }
    getImmunizationTempFailure = error => {
        // console.log("error _____________________________________> "+JSON.stringify(error))

    }

    getImmunizationDetails = () => {

        var service_url = Constants.IMMUNIZATION_TEMP + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getImmunizationDetailsSuccess,
            this.getImmunizationDetailsFailure
        );
    };

    getImmunizationDetailsSuccess = success => {

        if (success.status == "success") {

            var birth = [];
            var week6 = [];
            var week10 = [];
            var week14 = [];
            var months9 = [];
            var months16_24 = [];
            var years5_6 = [];
            var years10 = [];
            var years16 = [];

            var immunization = success.data;


            var states = this.state;

            if (immunization.length > 0) {
                for (let i = 0; i < immunization.length; i++) {
                    if (immunization[i].patient_age == "Birth") {
                        birth.push(immunization[i])
                    } else if (immunization[i].patient_age == "6 weeks") {
                        week6.push(immunization[i])
                    } else if (immunization[i].patient_age == "10 weeks") {
                        week10.push(immunization[i])
                    } else if (immunization[i].patient_age == "14 weeks") {
                        week14.push(immunization[i])
                    } else if (immunization[i].patient_age == "9 Months") {
                        months9.push(immunization[i])
                    } else if (immunization[i].patient_age == "16-24 Months") {
                        months16_24.push(immunization[i])
                    } else if (immunization[i].patient_age == "5-6 Years") {
                        years5_6.push(immunization[i])
                    } else if (immunization[i].patient_age == "10th Year") {
                        years10.push(immunization[i])
                    } else if (immunization[i].patient_age == "16th Year") {
                        years16.push(immunization[i])
                    }

                }
            }


            states["atBirthList"] = birth
            states["listOfWeek6"] = week6
            states["listOfWeek10"] = week10
            states["listOfWeek14"] = week14
            states["listOfMonth9"] = months9
            states["listOf16_24Months"] = months16_24
            states["listOf5_6Years"] = years5_6
            states["listOf10Years"] = years10
            states["listOf16Years"] = years16

            // console.log("getAllimmunizationDetails ========> "+JSON.stringify(getAllimmunizationDetails))

            this.setState({
                states,
                // immunizationDetails: getAllimmunizationDetails
            })
        }
    }
    getImmunizationDetailsFailure = error => {
        // console.log("error _____________________________________> "+JSON.stringify(error))

    }

    renderTabelCellValues(value, flex, type = "", bgColor = "") {
        return (
            <View style={[styles.tabelCellBorder, {
                flex: flex, backgroundColor:
                    type ?
                        type == "isGivenDate" ? givenDate :
                            (type == "isDueDate" && bgColor == "Upcoming") ? orange :
                                (type == "isDueDate" && bgColor == "Over Due") ? red :
                                    (type == "isDueDate" && bgColor == "Completed") ? green : "transparent" : null
            }]}>
                {
                    type == "action" ?
                        <TouchableOpacity disabled={value.is_frozen} onPress={() => { this.immunizationFreeze(value) }} style={{ backgroundColor: value.is_frozen ? "red" : color.themeDark, borderRadius: 30, height: 30, width: 30, justifyContent: 'center', alignItems: 'center', padding: 5, alignSelf: "center" }}>
                            <Image source={require('../../../../../assets/images/immunizationLock.png')} style={{ height: 20, width: 20 }} />
                        </TouchableOpacity>
                        :
                        <Text style={[styles.textStyle]} numberOfLines={8} >{value}</Text>
                }

            </View>
        )
    }

    renderImmunizationList(ageType, list) {

        var height = (100 / list.length).toString()
        height = height + "%"

        return (
            <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: tabelBorderColor, marginTop: 3 }}>
                <Text style={[styles.textStyle, { flex: 0.07, alignSelf: 'center', textAlign: 'center' }]} numberOfLines={8}>{ageType}</Text>
                <View style={{ flex: 0.93 }}>
                    {
                        list && list.length > 0 && list.map((item, index) => {
                            return (
                                <View style={{ flexDirection: 'row', backgroundColor: item.is_frozen ? disableColor : "transaparent" }}>
                                    {this.renderTabelCellValues(item.vaccine_name, 0.21)}
                                    {this.renderTabelCellValues(item.dose, 0.11)}
                                    {this.renderTabelCellValues(item.route, 0.17)}
                                    {this.renderTabelCellValues(item.due_date, 0.11, "isDueDate", item.color)}
                                    {this.renderTabelCellValues(item.given_date, 0.11, "isGivenDate")}
                                    {this.renderTabelCellValues(item.comments, 0.21)}
                                    {this.renderTabelCellValues(item, 0.08, "action")}
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onPressButton(item, key, selectedValue) {

        if (key == "selectedAtBirth") {
            //    var changedItem = { label: selectedValue.vaccine_name, value: selectedValue.vaccine_name }
            var states = this.state;
            states[key] = selectedValue;
            states["selectedAtBirthObj"] = item
            this.setState({ states })
        } else {

            var states = this.state;
            states[key] = selectedValue;
            this.setState({ states }, () => {
                if (key == "selectedPatientAge") {
                    this.getImmunizationTemp();
                }
            })
        }

    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var changedItem = {}
                            if (key == "selectedAtBirth") {
                                changedItem = { label: item.vaccine_name, value: item.vaccine_name }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this, item)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }


    getPostImmunizationData(list, vaccineName) {

        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].vaccine_name == vaccineName) {
                    return list[i]
                }
            }
        }

    }



    onPressAddButton() {
        var service_url = Constants.IMMUNIZATION_TEMP;
        var states = this.state;
        var selectedImmunizationData = {}

        if (this.state.selectedPatientAge.value) {
            if (states.selectedAtBirthObj && states.selectedAtBirthObj.vaccine_name) {



                var selectedVaccineName = states.selectedAtBirthObj.vaccine_name
                if (states.selectedPatientAge.value == "Birth") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.atBirthList, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "6 weeks") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOfWeek6, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "10 weeks") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOfWeek10, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "14 weeks") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOfWeek14, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "9 Months") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOfMonth9, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "16-24 Months") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOf16_24Months, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "5-6 Years") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOf5_6Years, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "10th Year") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOf10Years, selectedVaccineName)
                } else if (states.selectedPatientAge.value == "16th Year") {
                    selectedImmunizationData = this.getPostImmunizationData(this.state.listOf16Years, selectedVaccineName)
                }

                var due_date = selectedImmunizationData.due_date?moment(selectedImmunizationData.due_date, 'DD-MM-YYYY'):null
                var due_dateFormat = moment(due_date).format("YYYY-MM-DD")


                var data = {
                    "patient_id": states.patientAppointment.patient_id,
                    "patient_age": selectedImmunizationData.patient_age,
                    "vaccine_name": selectedImmunizationData.vaccine_name,
                    "dose": selectedImmunizationData.dose,
                    "route": selectedImmunizationData.route,
                    "immunization_id": selectedImmunizationData.immunization_id,
                    "given_date": states.givenDate ? moment(states.givenDate).format("YYYY-MM-DD") : null,
                    "comments": states.comments,
                    "is_frozen": "",
                    "due_date": due_dateFormat ? due_dateFormat : null,
                }
                if (selectedImmunizationData && selectedImmunizationData.id) {
                    data["id"] = selectedImmunizationData.id;
                }
                OpthamologyService.getInstance().postComplaints(
                    service_url,
                    data,
                    this,
                    this.saveImmunizationDetailsSuccess,
                    this.saveImmunizationDetailsFailure
                );
            } else {
                this.showResposeValue("error", Messages.InvalidVaccinationName);
            }
        } else {
            this.showResposeValue("error", Messages.InvalidPatientAge);
        }
    }



    saveImmunizationDetailsSuccess = success => {
        if (success.status == "success") {
            this.showResposeValue("success", success.message);
            this.getImmunizationDetails();
            this.clearAllDatas()
        } else {
            this.showResposeValue("error", success.message);
        }
    }
    saveImmunizationDetailsFailure = error => {
        this.showResposeValue("error", error.message);
    }

    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }


    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Icon size={25} name={"ban"} color={"#D26560"} />
                    <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                        <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }


    immunizationFreeze(item) {
        var service_url = Constants.IMMUNIZATION_TEMP + "freeze/";
        var data = {
            "id": item.id,
            "immunization_id": item.immunization_id,
            "is_frozen": true,
            "patient_id": this.state.patientAppointment.patient_id
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.immunizationFreezeSuccess,
            this.immunizationFreezeFailure
        );
    }

    immunizationFreezeSuccess = success => {
        if (success.status == "success") {
            this.showResposeValue("success", success.message);
            this.getImmunizationDetails()
        } else {
            this.showResposeValue("error", success.message);
        }
    }
    immunizationFreezeFailure = error => {
        this.showResposeValue("error", error.message);
    }



    render() {
        var states = this.state;
        return (
            <View style={{ height: screenHeight, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>
                    <View>
                        <NavigationTopHeader
                            changeTabSection={"Immunization"}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={"Immunization"} />
                    </View>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >

                        <View style={Style.ipAdmissionSectionView, { marginTop: 10 }}>

                            <CommonHistorySectionHeader
                                heading1={"Age"}
                                heading2={"Vaccination Name"}
                                heading3={"Dose"}
                                heading4={"Route"}
                                heading5={"Due Date"}
                                heading6={"Given Date"}
                                heading7={"Comments"}
                                heading8={"Action"}

                                noOfColumn={8}
                                headerKey={"Immunization"}
                                columnSize={[0.07, 0.2, 0.1, 0.16, 0.1, 0.1, 0.2, 0.07]}
                            />

                            {this.renderImmunizationList("At Birth", states.atBirthList)}
                            {this.renderImmunizationList("6th Week", states.listOfWeek6)}
                            {this.renderImmunizationList("10th Week", states.listOfWeek10)}

                            {this.renderImmunizationList("14th Week", states.listOfWeek14)}
                            {this.renderImmunizationList("9 Month (After 270 days)", states.listOfMonth9)}
                            {this.renderImmunizationList("16 - 24 Months", states.listOf16_24Months)}

                            {this.renderImmunizationList("5 - 6 Years", states.listOf5_6Years)}
                            {this.renderImmunizationList("10th Years", states.listOf10Years)}
                            {this.renderImmunizationList("16 Years", states.listOf16Years)}

                        </View>

                    </ScrollView>
                </View>


                <View style={{
                    paddingVertical: 20, paddingLeft: 20, width: "35%", backgroundColor: color.themeShade,
                    shadowColor: "#090F36",
                    shadowOffset: { width: 1, height: 1 },
                    shadowOpacity: 0.4, shadowRadius: 3,
                    elevation: 5,

                }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%", paddingRight: 10, }}
                        contentContainerStyle={{ height: screenHeight - 65 }}
                    >
                        {
                            this.renderLabelWithButtonGroup(
                                "Select Age",
                                "selectedPatientAge",
                                this.state.selectedPatientAge,
                                "",
                                false,
                                this.state.patientAgeList)
                        }
                        {
                            this.state.listOfAtBirths && this.state.listOfAtBirths.length > 0 ?
                                this.renderLabelWithButtonGroup(
                                    this.state.selectedPatientAge.label,
                                    "selectedAtBirth",
                                    this.state.selectedAtBirth,
                                    "",
                                    false,
                                    this.state.listOfAtBirths)
                                : null
                        }

                        <LabelWithCalender label={"Given Date"} calenderDate={this.state.givenDate} calenderKey={"givenDate"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />

                        <LabelWithTextBoxDR label={"Comments"} value={this.state.comments} maxTextLength={100} textBoxKey={"comments"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />
                        <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

                    </ScrollView>
                </View>


            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },

    textboxRowView: {
        flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10
    },
    textStyle: {
        fontSize: 12,
        // textAlign:"center",textAlignVertical:'center',
        // justifyContent:"center"
        // alignItems:'center',
        // alignSelf:'center',alignContent:"center"
        // borderLeftColor:tabelBorderColor,borderLeftWidth:1,
        // borderBottomColor:tabelBorderColor,borderBottomWidth:1
    },
    tabelCellBorder: {
        justifyContent: "center",
        paddingVertical: 8, paddingHorizontal: 3,
        borderLeftColor: tabelBorderColor, borderLeftWidth: 1,
        borderBottomColor: tabelBorderColor, borderBottomWidth: 1
    }
});

