//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,TextInput } from 'react-native';
import { OBGYNHistorySectionHeading,CommonHistorySectionHeader, CommonButton, CommonAddButton, HistorySectionRightSideUI ,EditiAndDeleteForLineItem} from '../../BaseComponent'
import { Constants } from "../../../../utils/Constants"
import OpthamologyService from "../../../../network/OpthamologyService";
// create a component
import Style from './../../../../styles/Style'
import { color } from '../../../../styles/Color';
const pageName = "isFamilyHistory"

export class FamilyHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editHistory:this.props.editHistory,
            selectedPageName: this.props.selectedPageName,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            listOfData:[],
            getListOfFamily:{},

            comments:""

            

        }
    }
    componentDidMount(){
        this.getFamilyHistory()
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        

        if (props.refreshRighSideComponentName == pageName) {
            this.getFamilyHistory();
            this.props.refreshRighSideComponent("");
        }
        
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            // this.editHistory()
        }, 100);
    }
    editHistory(item) {
        // console.log("item"+JSON.stringify(item))
        var editData={
            item:item,
            comment:this.state.comments
        }
        this.props.editHistory(editData, pageName)
    }
    

    getFamilyHistory(){
        var service_url = Constants.GET_FAMILY_HISTORY+ "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getFamilyHistorySuccess,
            this.getFamilyHistoryFailure
        );
    
    }
    getFamilyHistorySuccess = success => {
        // alert(JSON.stringify(success))
        if(success.status =="success"){
            // alert("success   "+JSON.stringify(success))

            var data =success.data.patient_family_history

            // var prepareData=[]
            //     Object.keys(data).map((keys)=>{

                    
                    
            //         if(keys =="family_member"&& data[keys] ){
            //             prepareData.push({ label:"family_member", value:data[keys]  })
            //         }else if(keys =="disease_names"&& data[keys]){
            //             prepareData.push({ label:"disease_names", value:data[keys]  })
            //         }else if(keys =="description"&& data[keys] ){
            //             prepareData.push({ label:"Drugs", value:data[keys]  })
            //         }else if(keys =="comments"&& data[keys]){
            //             prepareData.push({ label:"comments", value:data[keys]  })
            //         }

            //     })
            


                this.setState({
                    getListOfFamily:data,
                    listOfData:data,
                    comments:success.data.comments

                })
            }    
        //    console.log("success"+JSON.stringify(success))
            

    }
    getFamilyHistoryFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    deleteHistory(item){
        var states = this.state;
        var service_url = Constants.DELETE_FAMILY_HISTORY
        var data ={
            "patient_id":states.patientAppointment.patient_id,
            "id":item.id
        }

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.deleteHistorySuccess,
            this.deleteHistoryFailure
        );
    }

    deleteHistorySuccess = success =>{
        if(success.status == "success"){
            // this.props.showResposeValue('success', success.message);
            this.getFamilyHistory() 
        }else{
            this.props.showResposeValue('error', success.message);
        }
    }

    deleteHistoryFailure = success =>{
        this.props.showResposeValue('error', error.message);
    }
    renderEditandDelete(item){
        return (
        //   <View style={{backgroundColor:color.white}}>
            <EditiAndDeleteForLineItem
                editHistory={this.editHistory.bind(this, item)}
                deleteHistory={this.deleteHistory.bind(this, item)}

                headerKey ={"testConfig"}
                editImage={"pencil"}
                deleteImage={"trash"}
            />
        //   </View>    
        );
    }
    rendercontent(item,familyhistory){
    
        return (
                 <View key={familyhistory} style={{ flexDirection: 'row',paddingVertical:12 ,borderBottomColor:"#888888",borderBottomWidth:1}}>
                  <Text style={[Style.historyHeaderFontStyleStartText, { flex: 0.3 }]}>{item.family_member}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { flex: 0.3 }]}>{item.disease_names.toString()}</Text>
                  <Text style={[Style.historyHeaderFontStyle, { flex: 0.3 }]}>{item.description}</Text>
                  {/* <Text style={[Style.historyHeaderFontStyle, { flex: 0.2 }]}>{item.description}</Text> */}
                  <View style={[Style.historyHeaderFontStyle,{backgroundColor:color.white,flex:0.1}]}>
                      {this.renderEditandDelete(item)}
                      </View>
                </View>

        )
    }
    
    render() {
        return (
            <View style={styles.container}>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Family History"}
                    // editImage={"pencil"}
                   
                />
                
                </View>
           
               
                <View style={{marginTop:15}}>
                    <CommonHistorySectionHeader
                    heading1={"Selected Family Member"}
                    heading2={"Family History"}
                    heading3={"Description"}
                    heading4={"Action"}
                    noOfColumn={4}
                    columnSize={[0.3,0.3,0.3,0.1]}
                    />
                   
                </View>
                {
                    this.state.listOfData&& this.state.listOfData.length > 0 &&
                    this.state.listOfData.map((item,index)=>{
                       return this.rendercontent(item,index)
                    })
                }
           
                 <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Comments"}
                        noOfColumn={1}
                    />
                    <TextInput

                        editable={true}
                        multiline={true}
                        placeholder=""
                        style={{marginTop:15, paddingTop: 10, paddingLeft: 10, backgroundColor: this.state.isLocked ? color.disableComponentColor : null, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5 }}
                        value={this.state.comments}
                    />
                </View>
                {/* <View style={{marginTop:15}}>
                    <CommonHistorySectionHeader
                    heading1={"Comments"}
                    noOfColumn={1}
                    />
                    <TextInput

                        editable={true}
                        multiline={true}
                        placeholder=""
                        style={{ paddingTop: 10, paddingLeft: 10, backgroundColor: this.state.isLocked ? color.disableComponentColor : null, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5 }}
                        value={this.state.comment}
                    />
                </View> */}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop:20
    },
});

