//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, TextInput } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton, HistorySectionRightSideUI, GlucoseLogRadioButton, CommonHistorySectionHeader, EditAndDeleteIcon } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { Radio } from 'antd';
import Style from "../../../../styles/Style";
import moment from "moment";
// import Highlighter from 'react-native-highlight-words';

// create a component
const pageName = "isGulcoseLog"

export class GucoseLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {},
            value: 1,

            selectedDateSpanFilterValue: {},
            selectedDateSpanFiltersingleValue: "",
            lowGulcoseValue: "",
            highGulcoseValue: "",
        }
    }

    componentDidMount() {
        this.getGulcoseLog()
    }


    getGulcoseLog() {

        if (this.state.selectedDateSpanFiltersingleValue !== "") {
            var service_url = Constants.GLUCOSE_LOG_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id + "&search_days=" + this.state.selectedDateSpanFiltersingleValue;

        } else {
            var service_url = Constants.GLUCOSE_LOG_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id;

        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getGulcoseLogSuccess,
            this.getGulcoseLogFailure
        );
    }

    getGulcoseLogSuccess = response => {
        if (response.status === "success") {
            var field = this.state;
            field["listOfDatas"] = response.data;
            this.setState({
                field
            });
        }

    }
    getGulcoseLogFailure = error => {

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getGulcoseLog();
            this.props.refreshRighSideComponent("");
        }
    }

    renderContent(item, indx) {

        var isAvailable =
            this.state.highGulcoseValue || this.state.lowGulcoseValue ? true : false;
        return this.state.listOfDatas && this.state.listOfDatas.length > 0 &&
            this.state.listOfDatas.map((item, index) => {
                var fulldate = new Date();
                var converted_date = moment(fulldate).format("DD-MM-YYYY");

                var getItemDate = moment(item.activity_date).format("DD-MM-YYYY");

                var editIconFlag = false

                if (converted_date == getItemDate) {
                    editIconFlag = true
                }
                return (
                    <View key={index} style={{ marginTop: 15, flexDirection: 'row', alignItems: "center" }}>
                        <View style={{ flex: 0.14 }}>
                            <Text style={[Style.historyHeaderFontStyle]}>{item.activity_date ? moment(item.activity_date).format("DD-MM-YYYY") : null}</Text>
                        </View>
                        <View style={{ flex: 0.1 }}>
                            {/* <Highlighter
                                highlightStyle={{color: parseInt(item.fasting) > parseInt(this.state.highGulcoseValue)||parseInt(item.fasting) < parseInt(this.state.lowGulcoseValue) ? "white" : null,  backgroundColor: isAvailable ? parseInt(item.fasting) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.fasting) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }}
                                searchWords={[item.fasting]}
                                textToHighlight={item.fasting}
                            /> */}
                            <Text style={[Style.historyHeaderFontStyle, {  color: parseInt(item.fasting) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.fasting) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.fasting) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.fasting}</Text>
                        </View>
                        <View style={{ flex: 0.15 }}>
                            {/* <Highlighter
                                highlightStyle={{color: parseInt(item.number_2_hrs_post_bfast) > parseInt(this.state.highGulcoseValue)|| parseInt(item.number_2_hrs_post_bfast) < parseInt(this.state.lowGulcoseValue)? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_bfast) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_bfast) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }}
                                searchWords={[item.number_2_hrs_post_bfast]}
                                textToHighlight={item.number_2_hrs_post_bfast}
                            /> */}

                            <Text style={[Style.historyHeaderFontStyle, { color: parseInt(item.number_2_hrs_post_bfast) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_bfast) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_bfast) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.number_2_hrs_post_bfast}</Text>
                        </View>
                        <View style={{ flex: 0.13 }}>
                        {/* <Highlighter
                                highlightStyle={{color: parseInt(item.lunch) > parseInt(this.state.highGulcoseValue)||parseInt(item.lunch) < parseInt(this.state.lowGulcoseValue)  ? "white" : null, backgroundColor: isAvailable ? parseInt(item.lunch) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.lunch) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null  }}
                                searchWords={[item.lunch]}
                                textToHighlight={item.lunch}
                            /> */}
                            <Text style={[Style.historyHeaderFontStyle, { color: parseInt(item.lunch) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.lunch) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.lunch) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.lunch}</Text>
                        </View>
                        <View style={{ flex: 0.15 }}>
                        {/* <Highlighter
                                highlightStyle={{ color: parseInt(item.number_2_hrs_post_lunch) > parseInt(this.state.highGulcoseValue)||parseInt(item.number_2_hrs_post_lunch) < parseInt(this.state.lowGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_lunch) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_lunch) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null  }}
                                searchWords={[item.number_2_hrs_post_lunch]}
                                textToHighlight={item.number_2_hrs_post_lunch}
                            /> */}
                            <Text style={[Style.historyHeaderFontStyle, { color: parseInt(item.number_2_hrs_post_lunch) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_lunch) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_lunch) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.number_2_hrs_post_lunch}</Text>
                        </View>
                        <View style={{ flex: 0.13 }}>
                        {/* <Highlighter
                                highlightStyle={{color: parseInt(item.pre_dinner) > parseInt(this.state.highGulcoseValue)||parseInt(item.pre_dinner) < parseInt(this.state.lowGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.pre_dinner) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.pre_dinner) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null  }}
                                searchWords={[item.pre_dinner]}
                                textToHighlight={item.pre_dinner}
                            /> */}
                            <Text style={[Style.historyHeaderFontStyle, { color: parseInt(item.pre_dinner) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.pre_dinner) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.pre_dinner) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.pre_dinner}</Text>
                        </View>
                        <View style={{ flex: 0.2, flexDirection: 'row', justifyContent: 'space-between', alignItems: "center" }}>
                        {/* <Highlighter
                                highlightStyle={{color:parseInt(item.number_2_hrs_post_dinner) > parseInt(this.state.highGulcoseValue)|| parseInt(item.number_2_hrs_post_dinner) < parseInt(this.state.lowGulcoseValue)? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_dinner) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_dinner) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null  }}
                                searchWords={[item.number_2_hrs_post_dinner]}
                                textToHighlight={item.number_2_hrs_post_dinner}
                            /> */}
                            <Text style={[Style.historyHeaderFontStyle, { color: parseInt(item.number_2_hrs_post_dinner) > parseInt(this.state.highGulcoseValue) ? "white" : null, backgroundColor: isAvailable ? parseInt(item.number_2_hrs_post_dinner) > parseInt(this.state.highGulcoseValue) ? "#477B9E" : parseInt(item.number_2_hrs_post_dinner) < parseInt(this.state.lowGulcoseValue) ? "#DB6565" : null : null }]}>{item.number_2_hrs_post_dinner}</Text>
                            {
                                editIconFlag ?
                                    <EditAndDeleteIcon iconKey={"edit"} image={this.state.drMobileNoAndMailIdEdit ? "check" : "pencil"} onPressEditOrdelete={this.onPressEditOrdelete.bind(this, item)} />
                                    : null}
                        </View>
                    </View>
                )
            })
    }

    onPressEditOrdelete(item, key) {
        if (key == "edit") {
            this.props.editHistory(item, pageName)
        }
    }


    renderListOfData(item) {
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            this.editHistory()
        }, 100);
    }
    editHistory() {
        this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
    }



    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Glucose Log"}
                    editImage={""}
                />

                <View style={{ flexDirection: 'row', alignContent: 'center', marginLeft: 1, marginTop: 15 }}>
                    <View style={{ width: '60%' }}>
                        <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                            <Text style={{ fontWeight: 500 }}>Date Span</Text>
                            <View style={{ marginLeft: 10 }}>
                                <GlucoseLogRadioButton
                                    selectedDateSpanFilterValue={this.state.selectedDateSpanFilterValue}
                                    selectedDateSpanFilter={this.selectedDateSpanFilter.bind(this)} />
                            </View>
                        </View>

                    </View>
                    <View style={{ width: '40%' }}>
                        <View style={{ flexDirection: 'row', alignContent: 'center',flex:'wrap' }}>
                            <Text style={{ fontWeight: 500 }}>Target Range</Text>
                            <View>
                                <Text style={{ fontSize: 12, position: "absolute", marginLeft: 15, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888", marginTop: -10 }}>{"High"}</Text>
                                <View style={{ zIndex: -1 }}>
                                    <TextInput
                                        // placeholder={"High"}
                                        value={this.state.highGulcoseValue}
                                        style={[

                                            { backgroundColor: "transparent", height: 30, width: 85, fontSize: 13, borderWidth: 1, borderColor: '#CDD1D5', borderRadius: 4, marginLeft: 10, marginTop: -3, padding: 2, }]}
                                        onChangeText={(text) => {
                                            if (this.numberValidation(text)) {
                                                this.setState({ highGulcoseValue: text }, () => {
                                                    this.renderContent()
                                                })
                                            }

                                        }}
                                    /></View>
                            </View>
                            <View>
                                <Text style={{ fontSize: 12, position: "absolute", marginLeft: 25, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888", marginTop: -10 }}>{"Low"}</Text>
                                <View style={{ zIndex: -1 }}>
                                    <TextInput
                                        // placeholder={"Low"}
                                        value={this.state.lowGulcoseValue}
                                        style={[

                                            { backgroundColor: "transparent", height: 30, width: 85, fontSize: 13, borderWidth: 1, borderColor: '#CDD1D5', borderRadius: 4, marginLeft: 20, marginTop: -3, padding: 2, }]}
                                        onChangeText={(text) => {
                                            if (this.numberValidation(text)) {
                                                this.setState({ lowGulcoseValue: text }, () => {
                                                    this.renderContent()
                                                })
                                            }
                                        }}
                                    /></View></View>
                        </View>

                    </View>
                </View>

                <View style={{ marginTop: 15 }}>
                    <CommonHistorySectionHeader
                        heading1={"Date"}
                        heading2={"Fasting"}
                        heading3={"2 hours post Breakfast"}
                        heading4={"Pre Lunch"}
                        heading5={"2 hours post Lunch"}
                        heading6={"Pre Dinner"}
                        heading7={"2 hours post Dinner"}
                        noOfColumn={7}
                        headerKey={"glucoseLog"}
                    />
                </View>

                <View style={{paddingHorizontal:10}}>{this.renderContent()}</View>

            </View>
        );
    }
    selectedDateSpanFilter(item) {

        this.setState({
            selectedDateSpanFilterValue: item,
            selectedDateSpanFiltersingleValue: item.value
        }, () => {
            this.getGulcoseLog()
        })
    }

}


// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});


//make this component available to the app
