// import Pagination from './Pagination'
{/* <Pagination/> */ }
// https://stackoverflow.com/questions/25434813/simple-pagination-in-javascript

//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Picker } from 'react-native';

// create a component
export default class Pagination extends Component {



    constructor(props) {
        super(props);
        this.state = {

            isCustome:this.props.isCustome ? true : false,

            current_page: 1,
            records_per_page: this.props.recordsPerPage?this.props.recordsPerPage:10,

            listing_table: [],
            fromList: "",
            toList: "",

            objJson: [] // Can be obtained from another source, such as your objJson variable

        }
    }


    componentWillReceiveProps(props) {
        var getData = props.totalItems
        if (getData && getData.length !== this.state.objJson.length) {
            this.initialFunction(getData)
        }

        // if (props.recordsPerPage == this.state.records_per_page) {
        //     // do nothing
        // } else {
        //     this.setState({
        //         records_per_page: props.recordsPerPage
        //     }, () => {
        //         this.changePage(this.state.current_page);
        //     })
        // }

    }


    initialFunction(data) {
        var getData = []

        if (data && data.length > 0) {

            for (var i = 0; i < data.length; i++) {
                var prepareData = data[i];
                prepareData["tempPaginationId"] = i + 1
                getData.push(prepareData);
            }

            if (data.length > 0) {
                this.setState({
                    objJson: getData
                }, () => {
                    this.changePage(this.state.current_page);
                })
            }
        }

    }


    componentDidMount() {

        var data = this.props.totalItems
        // alert(data.length)
        this.initialFunction(data)
    }

    prevPage() {
        if (this.state.current_page > 1) {
            this.state.current_page--;
            this.changePage(this.state.current_page);
        }
    }
    nextPage() {
        if (this.state.current_page < this.numPages()) {
            this.state.current_page++;
            this.changePage(this.state.current_page);
        }
    }

    changePage(page) {
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        var getlisting_table = [];

        for (var i = (page - 1) * this.state.records_per_page; i < (page * this.state.records_per_page) && i < this.state.objJson.length; i++) {
            getlisting_table.push(this.state.objJson[i]);
        }
      //  console.log("getlisting_table " + JSON.stringify(getlisting_table))

        this.setState({

            fromList: getlisting_table[0].tempPaginationId,
            toList: getlisting_table[getlisting_table.length - 1].tempPaginationId,

            listing_table: getlisting_table
        }, () => {
            this.props.paginationCurrentPage ? this.props.paginationCurrentPage(this.state.current_page, this.state.records_per_page) : () => {}
        })
        this.props.paginationCurrentPage ? this.props.paginationCurrentPage(this.state.current_page,this.state.records_per_page) : () => {}
        this.props.paginationChangedValues(getlisting_table)
        // if (page == 1) {
        //     btn_prev.style.visibility = "hidden";
        // } else {
        //     btn_prev.style.visibility = "visible";
        // }

        // if (page == numPages()) {
        //     btn_next.style.visibility = "hidden";
        // } else {
        //     btn_next.style.visibility = "visible";
        // }
    }

    numPages() {
        return Math.ceil(this.state.objJson.length / this.state.records_per_page);
    }

    render() {
        return (
            <View style={{ flexDirection: "row", marginVertical: 10 }}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '2vw', width: '2.92vw',
                        backgroundColor: "#CCF0EF",
                        shadowColor: "#090F36",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                        elevation: 5,
                    }} onPress={() => {
                        this.prevPage()
                    }}>
                        <Text style={{fontSize: '1.3vw'}}>{"<"}</Text>
                    </TouchableOpacity>

                    <View style={{
                        minWidth: '8vw', backgroundColor: "#EDFAFA", height: '2vw', flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <Text style={[{ minWidth:"2.5vw" },styles.textStyle]}>{this.state.fromList + " - " + this.state.toList}</Text>
                        {/* <Text style={{ flex: 3 }}>{this.state.current_page}</Text> */}
                        <Text style={[{ minWidth:"3vw", paddingHorizontal:'.1vw'},styles.textStyle]}>{" of  " + this.state.objJson.length}</Text>
                    </View>

                    {/* <View style={{ flex: 3 }}>
                    {
                        this.state.listing_table.length > 0 && this.state.listing_table.map((item, index) => {
                            return (
                                <View>
                                    <Text>{item.adName}</Text>
                                </View>
                            )
                        })
                    }
                </View> */}
                    {/* <Text style={{ flex: 3 }}>{this.state.current_page}</Text> */}


                    <TouchableOpacity style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '2vw', width: '2.92vw',
                        backgroundColor: "#CCF0EF",
                        shadowColor: "#090F36",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                        elevation: 5,

                    }} onPress={() => {
                        this.nextPage()
                    }}>
                        <Text style={{fontSize: '1.3vw'}}>{">"}</Text>
                    </TouchableOpacity>


                    {/* <Text style={{ flex: 3 }}>{"no of page " + this.numPages()}</Text> */}

                </View>
                <View style={{flexDirection:"row"}}>
                    <Picker
                        selectedValue={this.state.records_per_page}
                        style={{justifyContent: 'center',
                        marginLeft:50,
                        alignItems: 'center',
                        height: '2vw', width: '4vw',
                        backgroundColor: "#CCF0EF",
                        shadowColor: "#090F36",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                        borderWidth:0,borderColor:"transparent",
                        elevation: 5,
                        fontSize: '1vw'}}
                        // itemStyle={Style.pickerFont}
                        onValueChange={(itemvalue) => {

                            var states = this.state;
                            states["records_per_page"] = itemvalue;

                            this.setState({ states },()=>{
                                this.changePage(this.state.current_page);
                            });
                        }}
                    >    
                        <Picker.Item label="30" value={30} />
                        <Picker.Item label="25" value={25} />
                        <Picker.Item label="20" value={20} />
                        <Picker.Item label="15" value={15} />
                        <Picker.Item label="10" value={10} />
                        
                        
                    </Picker>
                    <Text style={[styles.textStyle,{marginLeft:15}]}>{"Items Per Page"}</Text>
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    textStyle:{
        color:"black",fontWeight:'500',alignSelf:"center", fontSize: '1vw'
    }
});



























// function 

// function 

// function 

// function 


// export const Pagination = () => {
//     return (
//         <View>
//             <TouchableOpacity onPress={() => {
//                 nextPage()
//             }}>
//                 <Text>{current_page}</Text>
//                 <Text>{"item.adName"}</Text>


//             </TouchableOpacity>

//             {
//                 listing_table.length > 0 && listing_table.map((item, index) => {
//                     return (
//                         <View>
//                             <Text>{"item.adName"}</Text>
//                             <Text>{item.adName}</Text>
//                         </View>
//                     )
//                 })
//             }
//         </View>
//     )
// }

// // window.onload = function() {
// //     changePage(1);
// // };