import React, { Component } from "react";
import {
    View,
    Text,
    // Button,
    Dimensions,
    FlatList,
    // StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    // Keyboard,
    // alert
} from "react-native";
import { TextInput } from 'react-native-paper';
import { SearchBar } from 'react-native-elements';
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
// import SideMenu from "../../SideMenu";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import BaseComponentStyle from "../BaseComponentStyle";
import TimerLog from "../../TimerLog";
import Success from "react-native-vector-icons/FontAwesome5";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;


const dummyTemplateData = [
    {
        Message: " Birthday Wish"
    },
    {
        Message: " Delay in Appointment Time"
    },
    {
        Message: " Doctor Not Available"
    },
    {
        Message: " Birthday Wish"
    },
    {
        Message: " Delay in Appointment Time"
    },

    {
        Message: " Doctor Not Available"
    }
];
let namelist = [];
let idList = [];
let nameString = "";


export default class Billing extends BaseComponentStyle {
    constructor(props) {
        super(props);

        this.state = {
            responseType:"",
            responseMeaasge: "",
            currentTime: "",
            search: '',
            selectedChatIndex: 0,
            selected: {
                message: "Have pushed the appointment to 22nd May 2020",
                name: "Marie Winter"
            },
            messages: {
                data: [],
                listData: []
            },
            data: [],
            selectedMessage: {
                phone_number: "",
                message: "",
                sent_date: "",
                name: "",
                photo_url: "",
            },
            doctorList: [],
            patientListData: [],
            patientList: [],
            selectedPatient: {
                items: [],
                nameList: [],
                idList: []
            },
            selectedDoctorId: "",
            textMessage: "",
            addressBook: {
                patient: [],
                groups: [],
                contact: []
            },
            // selectedPatient: {
            //    patient_list:[]
            // },
            selectedGroup: {
                nameList: [],
                idList: []
            },
            active: false,
            isAllSelected: false,
            errors: {},
            groupName: "",
            groupListData: [],
            groupList: [],
            //userType: userType,
            //doctorName: userName,
            createdGrpId: null,
            isContactlist: false,
            isTemplatelist: false,
            newTemplatelist: false,
            text: ''


        };
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
            });
        }, 1000);
        this.getPatientList()

    }

    addBtnAction = (newContact) => {

      //  console.log(" newContact --- " + JSON.stringify(newContact));

        // let patientList = this.state.patientList;


        var nameString = "";
        var nameTemp = [];
        var idTemp = [];

        let { groupList, selectedGroup, selectedPatient } = this.state;

        var grpNameTemp = [];
        var grpIdTemp = [];

        if (newContact) {
            selectedPatient["items"].push(newContact);
            selectedPatient["nameList"].push(newContact.name);
            selectedPatient["idList"].push(newContact.id);
        }

        var itemsJsonObject =this.state.selectedPatient.items.map(JSON.stringify); 
        var itemsUniqueSet = new Set(itemsJsonObject); 
        var itemsUniqueArray = Array.from(itemsUniqueSet).map(JSON.parse); 
        selectedPatient["items"] = itemsUniqueArray;

        var nameListJsonObject =this.state.selectedPatient.nameList.map(JSON.stringify); 
        var nameListUniqueSet = new Set(nameListJsonObject); 
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse); 
        selectedPatient["nameList"] = nameListUniqueArray;

        var idListJsonObject =this.state.selectedPatient.idList.map(JSON.stringify); 
        var idListUniqueSet = new Set(idListJsonObject); 
        var idListUniqueArray = Array.from(idListUniqueSet).map(JSON.parse); 
        selectedPatient["idList"] = idListUniqueArray

        this.setState({
            selectedPatient
        });

        // console.log(" newContact --- " + JSON.stringify(this.state.selectedPatient));

    }

    sendMessage = () => {



        let states = this.state;
        var service_url = Constants.OPTHALMOLOGY_MEESAGE_SEND;

        let data = {
            "doctor_id": parseInt(this.state.selectedDoctorId),
            "patient_ids": this.state.selectedPatient.idList,
            "group_ids": this.state.selectedGroup.idList,
            "message": this.state.text,
        };


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getSendSuccess,
            this.getSendFailure
        );

    };




    getSendSuccess = response => {
        if (response.status === "success") {
            var field = this.state;
            field["active"] = false;
            this.setState({ field
            });
            this.showResposeValue("success",response.message);
           
            setTimeout(() => {
                this.props.navigation.navigate("message");
            }, 1000);

          
            // clear data
            this.clearComplaint();
            // reload data
            this.props.refreshData("complaints");
        }else{
            this.showResposeValue("error",response.message);
        }
    };

    getSendFailure = error => {
        this.showResposeValue("error",error.message);

      //  console.log("opthamology complaints GET error response");
        console.log(error);
    };


    getPatientList = () => {
        //console.log("add Contact List")

        var service_url = Constants.OPTHALMOLOGY_MEESAGE_CONTACTLIST

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPatientListSuccess,
            this.getPatientListFailure
        );
    }
    getPatientListSuccess = response => {
        var addressBook = this.state;
        var { } = this.state;
        if (response.status === "success") {

            addressBook["patient"] = response.data.patient_list;
            addressBook["groups"] = response.data.groups;
            addressBook["contact"] = response.data.contact_list;

            this.setState({
                addressBook
            });
            this.createPatientList()

        }
    };

    getPatientListFailure = error => {
        //console.log("opthamology complaints GET error response");
        console.log(error);
    };

    createPatientList = () => {

        var fields = this.state;

        fields["patientList"] = tempArray;
        var tempArray = [];
        let patient = this.state.addressBook.patient;
        var sample =
            patient &&
            patient.length > 0 &&
            patient.map(item => {
                tempArray.push({
                    id: item.patient_id,
                    name: item.name,
                    checked: this.state.isAllSelected ? true : false
                });
            });


        fields["patientList"] = tempArray;
        fields["originalPatientList"] = tempArray;

        this.setState({
            fields
        });


    }


    showResposeValue(type,meaasge){
        this.setState({
          responseType:type,
          responseMeaasge:meaasge
        },()=>{
          setTimeout(() => {
            this.setState({ responseType: "" , responseMeaasge: "" });
          }, 3000);
        }) 
      }


      successAlert(){
        return(
          <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70 ,marginRight:10,marginTop:10}}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
              <Success size={25} name={"check"} color={"green"} />
              <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
              </Text>
            </View>
            <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
              <Icon size={25} name={"close"} color={"#B2C6AA"} />
            </TouchableOpacity>
          </View> 
        );
      }
    
      errorAlert(){
        return(
          <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70,marginRight:10,marginTop:10 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
              <Icon size={25} name={"ban"} color={"#D26560"} />
              <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
              </Text>
            </View>
            <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
              <Icon size={25} name={"close"} color={"#C69A99"} />
            </TouchableOpacity>
          </View>
        );
      }

    rendermessagelist(item, index) {

        return (

            <View
            //style={{ backgroundColor: index === this.state.selectedChatIndex ? color.themePink : color.themeBlue, borderRadius: 20, }}
            >
                <TouchableOpacity onPress={() => this.addBtnAction(item)}>
                    <View
                        style={{
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",

                            marginLeft: 0,
                           // marginRight: 30,
                            marginBottom: 10,
                            borderRadius: 20,
                            alignItems: "center",
                            // backgroundColor: color.white
                        }}
                    >
                        <View
                            style={{

                                width: "15%",
                                //backgroundColor:"yellow"
                               
                            }}
                        >
                            <View>

                                <Image source={item.photo_url || require("../../../../assets/images/sample_profile.png")}
                                    style={{ height: 50, width: 50 }}
                                    resizeMode='stretch' /> 
                                    {/* contain */}

                            </View>
                        </View>

                        <View
                            style={{
                                width: "80%",
                                //alignItems: "flex-start",
                                //justifyContent: "center",
                                borderBottomColor:color.themeDark,
                            
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    color: color.themeBlue
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            marginBottom: 20,
                            marginLeft: 30
                        }}
                    />
                </TouchableOpacity>
            </View>

        );
    }

    clearPatientList(index) {

        var { selectedPatient } = this.state;
        var nameless = this.state.selectedPatient.items;
        var data = [];
        for (var i = 0; i < nameless.length; i++) {
            if (index == i) {

            } else {
                var name = nameless[i]
                data.push(name);
            }

        }
        selectedPatient["items"] = data

        this.setState({
            selectedPatient
        })
    }


        renderTemplateItem(item, index) {

        return (

            <View
            //style={{ backgroundColor: index === this.state.selectedChatIndex ? color.themePink : color.themeBlue, borderRadius: 20, }}
            >
                <TouchableOpacity
                //onPress={() => this.onPress(index)}
                >
                    <View
                        style={{
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",

                            marginLeft: 0,
                            marginRight: 30,
                            marginTop: 10,
                            marginBottom: 20,
                            borderRadius: 20,
                            alignItems: "center",
                            // backgroundColor: color.white
                        }}
                    >
                        <View
                            style={{
                                width: "20%",
                            }}
                        >
                        </View>

                        <View
                            style={{
                                width: "80%",
                                alignItems: "flex-start",
                                justifyContent: "center"
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    color:color.subtextcolor
                                }}
                            >
                                {item.Message}
                            </Text>
                        </View>


                    </View>
                    <View
                        style={{

                            justifyContent: "center",
                            borderBottomColor: '#9B9B9B',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            marginLeft: 30


                        }}
                    />
                </TouchableOpacity>
            </View>

        );
    }

    renderIconBtn = (text, key) => {
        return (
            <View
                style={{
                    height: 35,
                    width: 130,
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 20,
                    flexDirection: "row",
                    backgroundColor: color.white,
                    borderWidth: 1,
                    borderColor: color.themePink,
                    marginRight: 5,
                    marginBottom: 5
                }}
            >
                <Icon

                    name="times"
                    size={platform === "web" ? 20 : 20}
                    color={color.themePink}
                />
                <Text
                    style={{
                        fontSize: 10,
                        color: color.themePink,
                        fontWeight: "700",
                        paddingLeft: 5
                    }}
                >
                    {text}
                </Text>
            </View>
        );
    };

    renderRightBtn = (name, outline) => {
        return (
            <View
                style={{
                    height: 35,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    flexDirection: "row",
                    //borderColor: outline ? color.themeDark : color.themeBlue,
                    //borderWidth: 1,
                    backgroundColor: outline ? color.themeBlue : color.themeDark,
                    padding: 5,
                    justifyContent: "space-evenly",
                    marginBottom: 10
                }}
            >
                <Text
                    style={{
                        fontSize: 13,
                        textAlign: "center",
                        color: outline ? color.themeDark : color.white,
                        fontWeight: "700",
                        paddingLeft: 25,
                        paddingRight: 25
                    }}
                >
                    {name}
                </Text>
            </View>
        );
    };

    filterAddressBook = (search) => {
        let patientList1 = this.state
        let patientList = this.state.originalPatientList
        let filteredList = patientList.filter(item => item.name.toLowerCase().startsWith(search.toLowerCase()))
      //  console.log('Filtered List ' + JSON.stringify(filteredList))
        patientList1["patientList"] = filteredList
    }

    // renderIconsBtn = (icon, text, createBtn) => {
    //     return (
    //         <View
    //             style={{
    //                 height: 35,
    //                 width: text === "New" ? 100 : 130,
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 borderRadius: createBtn || text === "New" ? 20 : 8,
    //                 flexDirection: "row",
    //                 backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themePink,
    //                 justifyContent: "space-evenly",
    //                 borderWidth: createBtn || text === "New" ? 1 : 0,
    //                 borderColor: text === "New" ? "#848484" : color.white
    //             }}
    //         >
    //             <Icon name={icon} size={platform === "web" ? 25 : 20} color={text === "New" ? "#848484" : "#ffffff"} />
    //             <Text
    //                 style={{
    //                     fontSize: platform === "web" ? 10 : 13,
    //                     color: text === "New" ? "#848484" : color.white,
    //                     fontWeight: "700"
    //                 }}
    //             >
    //                 {text}
    //             </Text>
    //         </View>
    //     );
    // };

    renderContacIconBtn = (text, icon, createBtn, empty, index) => {
      //  console.log("selected name--------------> " + index)
        return (
            <View
                style={{
                    height: 35,
                    // width: text === "New" ? 100 : 100,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: createBtn || text === "New" ? 20 : 20,
                    flexDirection: "row",
                    backgroundColor: createBtn ? color.themeDark : text === "New" ? color.white : color.white,
                    justifyContent: "space-evenly",
                    borderWidth: createBtn || text === "New" ? 1 : 1,
                    borderColor: color.themeDark,
                    marginRight:10,
                    paddingLeft:10,
                    paddingRight:10
                }}
            >
                <Text
                    style={{
                        fontSize: platform === "web" ? 12 : 14,
                        color: text === "New" ? color.themeDark : color.themeDark,
                        fontWeight: "500"
                    }}
                >
                    {text}
                </Text>
                <TouchableOpacity onPress={this.clearPatientList.bind(this, index)}>
                    <Icon name={icon} size={platform === "web" ? 15 : 15} color={text === "New" ? "#848484" : "#848484"} style={{ justifyContent: 'space-evenly' }} />
                </TouchableOpacity>
            </View>
        );
    };

    deleteRecord = (count) => {
        var { selectedPatient } = this.state

    }

    renderContactlist = () => {

        return (
            <View>

                <View
                    style={{
                        height: 'auto', //65,
                        margin: 10,
                        marginBottom: 5,
                        width: "100%"
                    }}
                >
                    <SearchBar
                        placeholder="Type Here..."
                        round={true}
                        containerStyle={ Style.SearchBarContainer }
                        inputContainerStyle={ Style.SearchBarInnerContainer }
                        inputStyle={ Style.SearchBarInput } 
                        onChangeText={search => {this.setState({search})
                        this.filterAddressBook(search)}}
                        value={this.state.search}
                    />
                </View>
                
                <ScrollView showsHorizontalScrollIndicator={false} style={{ height: 450 }}>
                    {/* <View
                        style={{
                            flexDirection: "row",
                            bottom: 0,
                            marginBottom: 0,
                            justifyContent: "space-evenly",
                            width: "100%",
                            alignItems: "center",
                            marginTop: 20,
                        }}
                    >
                        <View style={{ width: "10%" }}>
                            <Text
                                style={{
                                    color: color.themeBlue,
                                    fontSize: 25
                                }}
                            >
                                A
                            </Text>
                        </View>
                        <View style={{ width: "90%" }}>
                            <View
                                style={{

                                    justifyContent: "center",
                                    borderBottomColor: '#9B9B9B',
                                    borderBottomWidth: 1,
                                    marginBottom: 10
                                }}
                            />
                        </View>
                    </View> */}
                    <View>
                        <FlatList
                            style={{ marginTop: 20, }}
                            data={this.state.patientList.sort(function (a, b) {
                                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                return 0;
                            })}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            renderItem={({ item, index }) => this.rendermessagelist(item, index)}
                        />
                    </View>
                </ScrollView>
            </View>
        )

    }
    renderSubHeadingBorder = title => {
        return (
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 20
                }}
            >
                <Text style={{ fontSize: 25, color: color.subtextcolor }}>{title}</Text>

            </View>
        );
    };

    renderTitleWithMultipleBtn = (title, dataSet, columnNos, isNewBtnNeeded) => {
        return (
            <View style={{ marginBottom: 20 }}>
                {this.renderSubHeadingBorder(title)}

                <FlatList
                    data={dataSet}
                    numColumns={columnNos}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderTextBtn(item, false)}
                />


            </View>
        );
    };

    renderTextBtn = (name, fill, borderNeeded) => {
        return (
            <TouchableOpacity onPress={() => this._showAlert(name)}>
                <View
                    style={{
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        borderRadius: fill || borderNeeded ? 10 : 20,
                        flexDirection: "row",
                        borderColor: fill || borderNeeded ? color.white : color.themeDark,
                        borderWidth: 1,
                        backgroundColor: fill
                            ? borderNeeded
                                ? color.white
                                : color.themeDark
                            : color.white,
                        padding: fill ? 10 : 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 10,
                        marginRight: 1
                    }}
                >
                    <Text
                        style={{
                            fontSize: fill ? 21 : 12,
                            textAlign: "center",
                            color: borderNeeded ? color.themeDark : color.themeDark,
                            fontWeight: "500"
                        }}
                    >
                        {name}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    renderTemplatetlist = () => {

        return (
            <View>
                <View
                    style={{
                        height: 'auto', //65,
                        margin: 10,
                        marginBottom: 5,
                        width: "100%"
                    }}
                >
                    <SearchBar
                        placeholder="Type Here..."
                        round={true}
                        containerStyle={ Style.SearchBarContainer }
                        inputContainerStyle={ Style.SearchBarInnerContainer }
                        inputStyle={ Style.SearchBarInput } 
                        onChangeText={this.updateSearch}
                        value={this.state.search}
                    />


                </View>
                {/* <View style={{ alignItems: 'center',marginRight:60 }}>
                    {this.renderTitleWithMultipleBtn(
                        "Frequently Used",
                        [
                            "Birthday Wish",    
                            "Fever",
                            "Stomach Pain",
                            "Birthday Wish",
                            "Fever",
                            "Stomach Pain",
                        ],
                        3,
                        true
                    )}
                </View> */}


                {/* <ScrollView showsHorizontalScrollIndicator={false} style={{ height:280 }}>
                    <View
                        style={{
                            flexDirection: "row",
                            bottom: 0,
                            marginBottom: 0,
                            justifyContent: "space-evenly",
                            width: "100%",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                    >
                        <View style={{ width: "35%" }}>
                            <Text
                                style={{
                                    color: color.subtextcolor,
                                    fontSize: 25,
                                    marginLeft:20
                                }}
                            >Templates</Text>
                        </View>
                        <View style={{ width: "65%" }}>
                            <View
                                style={{

                                    justifyContent: "center",
                                    borderBottomColor: '#9B9B9B',
                                    borderBottomWidth: 1,
                                    marginBottom: 10
                                }}
                            />
                        </View>
                    </View>

                    <View>
                        <FlatList
                            style={{ marginTop: 20, }}
                            data={dummyTemplateData}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            renderItem={({ item, index }) => this.renderTemplateItem(item, index)}
                        />
                    </View>

                </ScrollView> */}
                {/* <View style={{ alignItems: 'center',paddingTop:10 }}>
                    <TouchableOpacity onPress={() => this.rendernewtemplateset()}>
                        {this.renderIconsBtn("plus", "Add to Templates ", false, "")}
                    </TouchableOpacity>
                </View> */}
            </View>
        )

    }

    onFocus() {
        this.setState({
            backgroundColor: '#FFF'
        })
    }

    onBlur() {
        this.setState({
            backgroundColor: '#FFF'
        })
    }

    renderNewContactAdd = () => {

        return (
            <View style={{ marginTop: 50 }}>
                <TextInput
                    mode='outlined'
                    label='Name'
                    onSubmitEditing={() => this.textInputRef.blur()}
                    selectionColor='Name'
                    theme={{ colors: { placeholder: color.lightGray, text: color.themeBlue, primary: 'white', underlineColor: 'transparent', background: color.themeShadeBackground, borderColor: color.themeBlue }, roundness: 10 }}
                    style={{ height: 40, opacity: .9 }}

                />
                <View style={{ marginTop: 30 }}>
                    <TextInput
                        mode='outlined'
                        onBlur={() => this.onBlur()}
                        onFocus={() => this.onFocus()}
                        label='Mobile No'
                        selectionColor='Mobile No'
                        theme={{ colors: { placeholder: color.lightGray, text: color.themeBlue, primary: 'white', underlineColor: 'transparent', background: color.themeShadeBackground, borderColor: color.themeBlue }, roundness: 10 }}
                        style={{ height: 40 }}

                    />
                </View>
                <View style={{ marginTop: 50, flexDirection: "row", justifyContent: "space-evenly", alignItems: 'center' }}>
                    <View style={{ width: '30%' }}>
                        <TouchableOpacity onPress={() => this.setState({
                            isContactlist: false
                        })}>
                            {this.renderRightBtn("Save", false, "")}
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: '30%' }}>
                        <TouchableOpacity onPress={() => this.setState({
                            isContactlist: false
                        })}>
                            {this.renderRightBtn("Cancel", false, "")}
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        )

    }

    renderNewTemplateAdd = () => {

        return (
            <View style={{ marginTop: 50 }}>
                <TextInput
                    mode='outlined'
                    label='Name of the templates'
                    selectionColor='Name'
                    //theme={{ colors: { placeholder:color.searchBarBackground, text:color.textcolor, primary: 'white', underlineColor: 'transparent', background: '#262941', borderColor: 'white' }, roundness: 10 }}
                    theme={{ colors: { placeholder:color.textcolor, text:color.textcolor, primary: 'white', underlineColor: 'transparent', background:color.searchBarBackground, borderColor:color.themeDark }, roundness: 10, }}
                    style={{ height: 40 }}

                />

                <View style={{ marginTop: 50, flexDirection: "row", justifyContent: "space-evenly", alignItems: 'center' }}>
                    <View style={{ width: '30%' }}>
                        <TouchableOpacity onPress={() => this.setState({
                            isTemplatelist: true
                        })}>
                            {this.renderRightBtn("Save", false, "")}
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: '30%' }}>
                        <TouchableOpacity onPress={() => this.setState({
                           isTemplatelist: true
                        })}>
                            {this.renderRightBtn("Cancel", false, "")}
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        )

    }



    // renderTestIconBtn = (text, icon, createBtn) => {
    //     return (
    //         <View
    //             style={{
    //                 height: 35,
    //                 width: text === "New" ? 70 : "80%",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 borderRadius: createBtn || text === "New" ? 10 : 10,
    //                 flexDirection: "row",
    //                 backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.white,
    //                 justifyContent: "space-evenly",
    //                 borderWidth: createBtn || text === "New" ? 1 : 1,
    //                 borderColor: "#848484"
    //             }}
    //         >
    //             <Text
    //                 style={{
    //                     fontSize: platform === "web" ? 12 : 14,
    //                     color: text === "New" ? "#848484" : "#848484",
    //                     fontWeight: "500"
    //                 }}
    //             >
    //                 {text}
    //             </Text>


    //         </View>
    //     );
    // };

    rendernewcontact = () => {
        this.setState({
            isContactlist: true,
            isTemplatelist: false,

        })
    }

    rendernewcontact1 = () => {
        // this.setState({
        //     isTemplatelist: true,
        //     isContactlist: true,

        // })
    }

    rendernewtemplateset = () => {
        this.setState({
            newTemplatelist: true,
            isTemplatelist: false,
            isContactlist: true,

        })
    }

    render() {

        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row"}}>
                {/* side menu */}
                {/* <View style={[Style.leftBarView, { paddingTop: 30 }]}>
                    <SideMenu navigation={this.props.navigation} />
                </View> */}
                {/* first view */}
                <View style={[Style.leftView,{width:'65%'}]}>
                    <View
                        style={{
                            marginTop: platform === "web" ? 80 : 20,
                            width: "100%",
                            marginLeft: 50,
                            paddingRight: 10,
                            marginTop: 20,

                        }}
                    >
                        <View style={{ width: '90%' }}>
                            <Text style={{ fontSize: 20 }}>New Message</Text>
                        </View>

                        <View style={{ width: '90%', marginTop: 30 }}>
                            <TouchableOpacity onPress={() => this.setState({
                                isContactlist: false,
                                newTemplatelist: false
                            })}>
                                <Text style={{ fontSize: 15 }}>To</Text>
                            </TouchableOpacity>
                            <View style={{ marginTop: 10, maxWidth: '90%' }}>
                                {/* {this.renderContacIconBtn(this.state.selectedPatient.nameString, "close", false, "")} */}

                                <FlatList
                                    style={{ marginTop: 20, }}
                                    numColumns={6}
                                    data={this.state.selectedPatient.items}
                                    showsHorizontalScrollIndicator={false}
                                    showsVerticalScrollIndicator={false}
                                    renderItem={({ item, index }) => this.renderContacIconBtn(item.name, "close", false, "", index)}
                                />

                            </View>
                        </View>
                        <View style={{ marginTop: 50, width: '90%' }}>

                            <TouchableOpacity onPress={() => this.rendernewcontact1()}>
                                <TextInput
                                    ref={text => (this.nameInput = text)}
                                    value={this.state.text}
                                    onChangeText={text => this.setState({ text })}
                                    multiline={true} numberOfLines={13}
                                    maxLength={1000}
                                    style={{ borderWidth: 1, backgroundColor: "white", textAlignVertical: "top", borderColor: "#DBDBDB" }}
                                />
                            </TouchableOpacity>
                        </View>
                        <View style={{
                            flexDirection: "row",
                            justifyContent: 'center',
                            alignItems: "center",
                            marginTop: 50
                        }}>
                            <View style={{
                                // width: "20%"
                                marginHorizontal: 5
                            }}>
                                <TouchableOpacity onPress={this.sendMessage.bind(this)}>
                                    {this.renderIconsBtn("send-o", "Message", false, "")}</TouchableOpacity>
                            </View>
                            <View style={{
                                // width: "20%"
                                marginHorizontal: 5
                            }}>


                                <TouchableOpacity
                                //onPress={() => this.changeSelected("")}
                                onPress={() =>{ 
                                    this.props.changeScreen("message")
                                    // this.props.navigation.navigate("message")
                                } }
                                >
                                    {/* {this.renderTestIconBtn("Cancel", "", false, "")} */}
                                    {this.renderIconsBtn("close", "Cancel", "", false, "")}
                                </TouchableOpacity>

                            </View>
                        </View>
                    </View>


                </View>
                {/* second view */}
                <View style={[Style.rightView, { padding: 20,width:'35%' }]}>
                    {
                    this.state.responseType == "success" ? 
                    this.successAlert() :
                    this.state.responseType == "error" ? 
                    this.errorAlert(): null
                    }
                    <View style={{ width: "100%" }}>
                        
                        {/* <View style={{ justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontSize: 13, color: "#ffffff" }}>
                                {this.state.currentTime}
                            </Text>
                        </View> */}

                        <TimerLog />

                        {this.state.isContactlist === false ?
                            <View>

                                {this.renderContactlist()}


                            </View>
                         :   this.renderNewContactAdd()
                        }
                            {/* // : this.state.isTemplatelist === true ? 
                            //     <View>{this.renderTemplatetlist()}</View> : 
                            //     this.state.newTemplatelist === true ? 
                            //     <View>{this.renderNewTemplateAdd()}</View>  */}
                                {/* : 
                                <View>{this.renderNewContactAdd() */}
                            {/* }</View>} */}
                        <View style={{
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                            marginLeft: 0,
                            paddingRight: 0,

                        }} >
                            {this.state.isContactlist === false ?
                                <TouchableOpacity onPress={() => this.rendernewcontact()}>
                                    {this.renderIconsBtn("plus", "New Contact", false, "")}
                                </TouchableOpacity>
                                : <Text></Text>}
                        </View>

                    </View>

                </View>

            </View>
        );
    }
}
