import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, ScrollView, Image, TouchableWithoutFeedback, Dimensions } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from "../../../../styles/Color";
import { DatePicker } from 'antd';
import ImagePath from '../../../../utils/ImagePaths';
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from '../../../../utils/Constants';
import Icon from "react-native-vector-icons/FontAwesome";
import moment from 'moment';
import Style from '../../../../styles/Style';

const navigationHeader = [
  { label: "Vitals", value: "oTVitals" },
  { label: "Reports", value: "reportsScreen" },
  { label: "Surgery Notes", value: "oTSurgeryNotes" },
  { label: "Billing", value: "oTBilling" }
]
const dateInstance = new Date();
const today = moment(dateInstance).format("YYYY-MM-DD");

export default class OTSurgeryNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SurgeryNotesID: "",
      PatientID: this.props.PatientDetail.patient_id,
      admissionID: this.props.PatientDetail.ip_admission,
      appointmentID: this.props.PatientDetail.id,
      date: this.props.PatientDetail.surgery_date,
      patientName: this.props.PatientDetail.patient_name,
      AccountNumber: this.props.PatientDetail.uhid,
      WardName_Room_BedNumber: this.props.PatientDetail.bed_number == null ? "-" : this.props.PatientDetail.ward_name == null ? this.props.PatientDetail.room_number == null ? null : "-" + " / " + this.props.PatientDetail.room_number + " / " + this.props.PatientDetail.bed_number : this.props.PatientDetail.ward_name + " / " + "-" + " / " + this.props.PatientDetail.bed_number,
      hospitalStay: "",
      errorText: "",
      PatientAdmits: "",
      PatientDenies: "",
      PreopDiagnosis: "",
      PostpoDiagnosis: "",
      Procedure: "",
      Surgeon: "",
      StaffNurse: "",
      Assistant: "",
      anesthesia: "",
      Fluids: "",
      Urineoutput: "",
      BloodLoss: "",
      Spicemen: "",
      Drains: "",
      plan: "",
      DateTime: "",
      DoctorName: "",
      PreviousSurgeryNotesList: "",
      Signature: "",
      selectedTemplateName: "",
      templateSearchText:"",
      templateSuggessionList: [],
      selectedTemplateID: ""
    };
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  letterValidation(num) {
    return /^[a-zA-Z]*$/g.test(num)
  }


  getSurgeryTemplateData = () => {
    var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE + "?template_name=" + this.state.templateSearchText;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSurgeryTemplateDataSuccess,
      this.getSurgeryTemplateDataFailure
    );
  }
  getSurgeryTemplateDataSuccess = (response) => {
    if(response && response.status === "success"){
      this.setState({
        templateSuggessionList: response.data
      })
    }
  }
  getSurgeryTemplateDataFailure = (error) => {
      this.props.showResposeValue("error", error.message)
  }
  getSurgeryDetails = () => {
    var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE + "?template_id=" + this.state.selectedTemplateID;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSurgeryDetailsSuccess,
      this.getSurgeryDetailsFailure
    );
  }
  getSurgeryDetailsSuccess = (response) => {
    if(response && response.status === "success"){
      var data = response.data
      
      this.setState({
        hospitalStay: data.reason_for_hospital_stay,
        PatientAdmits: data.patient_admit_to_having,
        PatientDenies: data.patient_denies_having,
        PreopDiagnosis: data.preop_diagnosis,
        PostpoDiagnosis: data.postop_diagnosis,
        Procedure: data.procedure,
        Surgeon: data.surgen,
        StaffNurse: data.staff_name,
        Assistant: data.assistent,
        anesthesia: data.type_of_anesthesia,
        Fluids: data.fluids,
        Urineoutput: data.urine_output,
        BloodLoss: data.estimated_blood_loss,
        Spicemen: data.speciman,
        Drains: data.drains,
        plan: data.plan
      })
    }
  }
  getSurgeryDetailsFailure = (error) => {
      this.props.showResposeValue("error", error.message)
  }
    // Get Surgery Notes API Start //
  getSurgeryNotesDeatils = () => {
    var url = Constants.GET_SURGERY_RECORDS + "?surgery_notes_id=" + this.state.SurgeryNotesID + "&patient_id=" + this.state.PatientID

    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSurgeryNotesSuccess,
      this.getSurgeryNotesFailure
    );
  }

  getSurgeryNotesSuccess = (response) => {
    var states = response.data
    if (response.status === "success") {
      console.log(states.date)
      this.setState({
        date: states.date,
        patientName: states.patient_name,
        AccountNumber: states.patient_acc_no,
        WardName_Room_BedNumber: states.ward_name_room_no,
        hospitalStay: states.reason_for_hospital_stay,
        PatientAdmits: states.patient_admit_having,
        PatientDenies: states.patient_denies_having,
        PreopDiagnosis: states.preop_diagnosis,
        PostpoDiagnosis: states.postop_diagnosis,
        Procedure: states.procedure,
        Surgeon: states.surgen,
        StaffNurse: states.staff_name,
        Assistant: states.assistent,
        anesthesia: states.type_of_anesthesia,
        Fluids: states.fluids,
        Urineoutput: states.urine_output,
        BloodLoss: states.estimated_blood_loss,
        Spicemen: states.speciman,
        Drains: states.drains,
        plan: states.plan,
        DateTime: states.date_time,
        DoctorName: states.name_of_doctor,
        Signature: states.signature
      })
    } else {
      console.log("error")
    }
  }

  // Get Surgery Notes API Ends //

  // Post Surgery Notes API Start //

  PostSurgeryNotesDetails = () => {
    var states = this.state
    var url = Constants.GET_SURGERY_RECORDS
    var data = {
      "patient_id": states.PatientID,
      "ip_admission_id": states.admissionID,
      "ip_surgery_notes": {
        "date": states.date,
        "patient_name": states.patientName,
        "patient_acc_no": states.AccountNumber,
        "ward_name_room_no": states.WardName_Room_BedNumber,
        "reason_for_hospital_stay": states.hospitalStay,
        "patient_admit_having": states.PatientAdmits,
        "patient_denies_having": states.PatientDenies,
        "preop_diagnosis": states.PreopDiagnosis,
        "postop_diagnosis": states.PostpoDiagnosis,
        "procedure": states.Procedure,
        "surgen": states.Surgeon,
        "staff_name": states.StaffNurse,
        "assistent": states.Assistant,
        "type_of_anesthesia": states.anesthesia,
        "fluids": states.Fluids,
        "urine_output": states.Urineoutput,
        "estimated_blood_loss": states.BloodLoss,
        "speciman": states.Spicemen,
        "drains": states.Drains,
        "plan": states.plan,
        "date_time": states.DateTime,
        "name_of_doctor": states.DoctorName,
        "signature": states.Signature,
        "is_finished": false
      }
    }
    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.SurgeryNotesPostSuccess,
      this.SurgeryNotesPostFailure
    );
  }

  SurgeryNotesPostSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)
      this.ClearForm()
      this.setState({
        errorText: ""
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }

  // Post Surgery Notes API Ends //

  // Get Previous Surgery Notes API Start // 

  getPreviousSurgeryNotes = () => {
    var url = Constants.GET_PREVIOUS_SURGERY_RECORDS + "?patient_id=" + this.state.PatientID

    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getPreviousSurgeryNotesSuccess,
      this.getPreviousSurgeryNotesFailure
    );
  }

  getPreviousSurgeryNotesSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        PreviousSurgeryNotesList: response.data.ip_surgery_notes_list
      })
    } else {
      console.log("error")
    }
  }

  // Get Previous Surgery Notes API Ends // 

  FormValidation = () => {
    if (this.state.date === "" || this.state.WardName_Room_BedNumber === "" || this.state.hospitalStay === "") {
      this.setState({
        errorText: "Please Enter Patient Information"
      })
    } else if (this.state.PatientAdmits === "" || this.state.PatientDenies === "") {
      this.setState({
        errorText: "Please Enter History of Present Illness"
      })
    } else {
      this.PostSurgeryNotesDetails()
    }
  }

  ClearForm = () => {
    this.setState({
      hospitalStay: "",
      errorText: "",
      PatientAdmits: "",
      PatientDenies: "",
      PreopDiagnosis: "",
      PostpoDiagnosis: "",
      Procedure: "",
      Surgeon: "",
      StaffNurse: "",
      Assistant: "",
      anesthesia: "",
      Fluids: "",
      Urineoutput: "",
      BloodLoss: "",
      Spicemen: "",
      Drains: "",
      plan: "",
      DateTime: "",
      DoctorName: "",
      Signature: "",
      PreviousHistory: false,
      PreviousNotes: false,
      selectedTemplateName: ""
    })
  }

  renderTextInput(label, key, stateValue) {
    return (
      <View>
        <Text style={styles.TextInputlabel}>{label}</Text>
        <View style={styles.TextInputWrapper}>
          <View style={styles.TextInputContainer}>
            <TextInput
              editable={this.state.PreviousNotes || key === "AccountNumber" || key === "PatientName" || key === "RoomNumber" ? false : true}
              value={stateValue}
              onChangeText={(text) => {
                var states = this.state;
                if (key === "DoctorName" || key === "Signature") {
                  var isValid = this.letterValidation(text)
                  if (isValid || text === "") {
                    states[key] = text;
                    this.setState({ states })
                  }
                } else {
                  states[key] = text;
                  this.setState({ states })
                }
              }}
              style={styles.TextInputStyle}
            />
          </View>
        </View>
      </View>
    )
  }

  renderResaonInput(label, key, stateValue) {
    return (
      <View>
        <Text style={[styles.TextInputlabel, { marginTop: key === "hospitalStay" || key === "PatientAdmits" || key === "PatientDenies" ? "-.45vw" : "-.25vw" }]}>{label}{key === "hospitalStay" || key === "PatientAdmits" || key === "PatientDenies" ?
          <span style={{ color: "red", fontSize: "1vw", fontWeight: 500 }}>*</span> : null}</Text>
        <View style={styles.TextInputWrapper}>
          <View style={styles.TextInputContainer}>
            <TextInput
              editable={this.state.PreviousNotes ? false : true}
              multiline={true}
              value={stateValue}
              onChangeText={(text) => {
                var states = this.state;
                states[key] = text;
                this.setState({ states })
              }}
              style={styles.TextReasonInputStyle}
            />
          </View>
        </View>
      </View>
    )
  }
  renderTemplateSelection = () => {
    return(
      <View style={styles.templateMain}>         
        <View style={styles.tempSuggMain}>
          <Text style={styles.textBoxLabel}>{"Templates"}</Text>
          <TextInput
            placeholder='Search Templates'
            style={[Style.pickerView, { marginTop: '0.45vw', borderRadius: '0.26vw', borderColor: color.themeDark, height: '2vw', width: "10vw", zIndex: -1, borderWidth: 1, fontSize: '0.91vw' }, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
            value={this.state.templateSearchText}
            onChangeText={(text) => {
              this.setState({
                templateSearchText: text
              }, () =>{
                this.getSurgeryTemplateData()
              })
            }}
          />
        </View>
        <View style={{ marginTop: "1.8vw", marginLeft: '8.5vw' }}>
           {this.state.templateSuggessionList && (this.state.templateSuggessionList).length > 0 && this.state.templateSearchText != "" ? this.renderTemplateSuggesstion() : null}
        </View>
      </View>
    )
  }
  renderTemplateSuggesstion() {
    return (
      <View style={{ zIndex: 1, position: 'absolute', marginLeft: '0.65vw', maxHeight: '9.27vw', minWidth: '10vw', maxWidth: '19.53vw', alignSelf: "flex-end" }}>

        <ScrollView
          style={styles.tempDropDown}
        >
          { this.state.templateSuggessionList && this.state.templateSuggessionList.length > 0 ? this.state.templateSuggessionList.map((value, index) =>
            <View style={{ height: '1.95vw'}}>
              <TouchableOpacity key={index}
                style={{ marginLeft: '0.32vw', marginTop: '0.32vw' }}
                onPress={
                  () => {
                    var states = this.state;
                    states["selectedTemplateID"] = value.id,
                    states["selectedTemplateName"] = value.template_name,
                    states["templateSearchText"] = ""
                    this.setState({ states }, () => {
                      // this.onClearButton("templateChange")
                      this.getSurgeryDetails()
                    });
                  }
                }
              >
                <Text style={{ fontSize: '0.9vw' }}>{value.template_name}</Text>

              </TouchableOpacity>
            </View>
          ) : 
          <View>
            <Text style={{margin:'1vw'}}>No Records Found</Text>
            </View>}

        </ScrollView>

      </View>
    )
  }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"oTSurgeryNotes"}
          isNavigationBorder={true}
        />
        {this.state.PreviousHistory ?
          <View style={{ position: "absolute", zIndex: 10 }}>
            <View style={styles.HistoryPopupContainer}>
              <TouchableWithoutFeedback>
                <View style={styles.PopupHeaderConatiner}>
                  <View style={{ flex: 1 }}>
                    <View style={styles.PopupHeaderWrapper}>
                      <Text style={styles.HeaderTextStyle}>
                        Previous Notes
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ PreviousHistory: false })
                        }}
                        style={{ fontSize: "1.95vw" }}
                      >
                        <Icon
                          name="window-close-o"
                          size={"1.10vw"}
                          color={color.themeDark}
                        />
                      </TouchableOpacity>
                    </View>
                    <View>
                      <View style={[styles.HistoryHeaderContainer]}>
                        <View style={{ alignItems: "center", width: '30%' }} >
                          <Text style={styles.appointmentTableHeadText}> Date </Text>
                        </View>
                        <View style={{ alignItems: "center", width: '40%' }} >
                          <Text style={styles.appointmentTableHeadText}>Surgery Name</Text>
                        </View>
                        <View style={{ alignItems: "center", width: '30%' }} >
                          <Text style={styles.appointmentTableHeadText}>Surgery Status</Text>
                        </View>
                      </View>
                    </View>
                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.scrollViewstyles}>
                      {this.state.PreviousSurgeryNotesList.length > 0 ? this.state.PreviousSurgeryNotesList.map(item => {
                        return (
                          <TouchableOpacity style={styles.TableBodyContainer} onPress={() => { this.setState({ PreviousNotes: true, PreviousHistory: false, SurgeryNotesID: item.surgery_notes_id }, () => { this.getSurgeryNotesDeatils() }) }}>
                            <View style={styles.TableBodyWrapper}>
                              <View style={{ width: "30%", alignItems: "center" }}>
                                <Text style={styles.TableBodyText} >{item.date}</Text>
                              </View>
                              <View style={{ width: "40%", alignItems: "center" }}>
                                <Text style={styles.TableBodyText} >{item.surgery_name}</Text>
                              </View>
                              <View style={{ width: "30%", alignItems: "center" }}>
                                <Text style={styles.TableBodyText} >{item.is_finished == true ? "Completed" : "Not Completed"} </Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        )
                      }) : <View>
                        <Text style={styles.NoRecordsText}>No Records Found</Text>
                      </View>
                      }
                    </ScrollView>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
          : null}
        <ScrollView style={styles.ScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <View style={styles.SurgeryNotesContainer}>
            <View style={styles.PatientInFormationContainer}>
              <View style= {{ width: "50%", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={[styles.headerTextStyle]}>Patient Information</Text>

                {this.state.selectedTemplateName != "" ?
                  <Text style={styles.tempList}>Selected Template :
                    {this.state.selectedTemplateName && this.state.selectedTemplateName.length > 20 ?
                      <Tooltip placement="top" title={this.state.selectedTemplateName}>
                        <Text style={styles.tempDataStyle}> {this.state.selectedTemplateName.slice(0, 18) + ".."}</Text>
                      </Tooltip> :
                      <Text style={styles.tempDataStyle}> {this.state.selectedTemplateName}</Text>}</Text>
                  : null
                }
              </View>

              <View style={styles.HeaderButtonContainer}>
                <View style = {{ width: "10vw" }}>
                  {this.renderTemplateSelection()}
                </View>
                <TouchableOpacity onPress={() => { this.getPreviousSurgeryNotes(), this.setState({ PreviousHistory: true }) }} style={styles.HeaderButton}>
                  <Text style={styles.ButtonText}>Previous Notes</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { this.setState({ PreviousNotes: false }, () => { this.ClearForm() }) }} style={styles.HeaderButton}>
                  <Text style={styles.ButtonText}>New Note</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.HeaderButton}>
                  <Text style={styles.ButtonText}>Print</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.InFormationInputContainer}>
              <View style={styles.InformationFirstrow}>
                <DatePicker
                  disabled={true}
                  clearIcon={false}
                  defaultValue={moment(this.state.date)}
                  value={this.state.date === "" ? null : moment(this.state.date)}
                  format={"YYYY-MM-DD"}
                  style={{ width: "12vw", height: "2.27vw", fontSize: ".9vw", marginTop: ".35vw", borderRadius: 4 }}
                  onChange={(date) => {
                    var fulldate = new Date(date);
                    var converted_date = moment(fulldate).format("YYYY-MM-DD");
                    this.setState({
                      date: converted_date
                    })
                  }}
                />
                {this.renderTextInput("Name", "PatientName", this.state.patientName)}
                {this.renderTextInput("Patient Acc.No", "AccountNumber", this.state.AccountNumber)}
                {this.renderTextInput("Ward/Room/Bed_Number", "RoomNumber", this.state.WardName_Room_BedNumber)}
              </View>
              <View style={styles.InformationSecondrow}>
                {this.renderResaonInput("Reason for hospital stay", "hospitalStay", this.state.hospitalStay)}
              </View>
            </View>
            <View style={styles.illnessContainer}>
              <Text style={[styles.headerTextStyle, { width: "65%" }]}>History of Present illness</Text>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Patient admits to having", "PatientAdmits", this.state.PatientAdmits)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Patient denies having", "PatientDenies", this.state.PatientDenies)}</View>
              </View>
            </View>
            <View style={styles.procedureContainer}>
              <Text style={[styles.headerTextStyle, { width: "65%" }]}>Procedure Details</Text>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Preop Diagnosis", "PreopDiagnosis", this.state.PreopDiagnosis)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Postop Diagnosis", "PostpoDiagnosis", this.state.PostpoDiagnosis)}</View>
              </View>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Procedure", "Procedure", this.state.Procedure)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Surgeon", "Surgeon", this.state.Surgeon)}</View>
              </View>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Staff Nurse", "StaffNurse", this.state.StaffNurse)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Assistant(s)", "Assistant", this.state.Assistant)}</View>
              </View>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Type of anesthesia", "anesthesia", this.state.anesthesia)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Fluids given", "Fluids", this.state.Fluids)}</View>
              </View>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Urine output", "Urineoutput", this.state.Urineoutput)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Estimated Blood Loss(ELB)", "BloodLoss", this.state.BloodLoss)}</View>
              </View>
              <View style={styles.ReasonTextInput}>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Spicemen", "Spicemen", this.state.Spicemen)}</View>
                <View style={{ width: "49.5%" }}>{this.renderResaonInput("Drains", "Drains", this.state.Drains)}</View>
              </View>
            </View>
            <View style={styles.OprativeContainer}>
              <Text style={[styles.headerTextStyle, { width: "65%" }]}>Operative Findings</Text>
              <View style={styles.oprativeInput}>
                <View style={styles.oprativeFirstrow}>
                  {this.renderResaonInput("Plan", "plan", this.state.plan)}
                </View>
                <View style={styles.oprativeSecondrow}>
                  <DatePicker
                    clearIcon={false}
                    // defaultValue={moment(this.state.DateTime)}
                    value={this.state.DateTime === "" ? null : moment(this.state.DateTime)}
                    format={"YYYY-MM-DD"}
                    style={{ width: "12vw", height: "2.27vw", fontSize: ".9vw", marginTop: ".35vw", borderRadius: 4 }}
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");
                      this.setState({
                        DateTime: converted_date
                      })
                    }}
                  />
                  {this.renderTextInput("Name of Doctor", "DoctorName", this.state.DoctorName)}
                  {this.renderTextInput("Signature", "Signature", this.state.Signature)}
                </View>
              </View>
            </View>
            <View style={styles.errorContainer}>{this.state.errorText.length > 0 ?
              <Text style={styles.ErrorText}>{this.state.errorText}</Text> : null
            }</View>
            {this.state.PreviousNotes ? null :
              <View style={styles.BottomButtonContainer}>
                <TouchableOpacity onPress={() => { this.FormValidation() }} style={[styles.HeaderButton, { marginRight: "1.5vw", width: "6.5vw" }]}>
                  <Image source={ImagePath.SavedBill} style={styles.ImageStyle} />
                  <Text style={[styles.ButtonText, { color: color.white }]}>Save</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.HeaderButton, { marginRight: "1.5vw", width: "6.5vw" }]}>
                  <Image source={ImagePath.Flag} style={styles.ImageStyle} />
                  <Text style={[styles.ButtonText, { color: color.white }]}>Finish</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { this.ClearForm() }} style={[styles.HeaderButton, { backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1, borderRadius: 4, width: "6.5vw" }]}>
                  <Image source={ImagePath.close} style={{ height: "1vw", width: "1vw", marginRight: ".5vw", tintColor: color.themeDark }} />
                  <Text style={[styles.ButtonText, { color: color.black }]}>Clear</Text>
                </TouchableOpacity>
              </View>}
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  SurgeryNotesContainer: {
    width: "100%",
    paddingHorizontal: "1.5vw",
    marginTop: ".5vw"
  },
  PatientInFormationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  headerTextStyle: {
    fontSize: "1.5vw",
    fontWeight: 500,
    width: "100%"
  },
  ScrollView: {
    height: "40.3vw",
    overflow: "scroll"
  },
  HeaderButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%"
  },
  HeaderButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    height: "2vw",
    width: "8vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "3vw"
  },
  ButtonText: {
    fontSize: ".85vw",
    color: color.white,
    marginRight: ".5vw"
  },
  InFormationInputContainer: {
    height: "11.5vw",
    width: "100%",
    marginTop: "1vw",
    marginLeft: "1vw",
    zIndex: -1
  },
  InformationFirstrow: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    justifyContent: "space-between"
  },
  oprativeFirstrow: {
    width: "42%",
    marginTop: ".5vw"
  },
  InformationSecondrow: {
    width: "42%",
    marginTop: "1.5vw"
  },
  oprativeSecondrow: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    justifyContent: "space-between",
    marginTop: "1.5vw"
  },
  illnessContainer: {
    width: "100%",
    marginTop: "1vw"
  },
  ReasonTextInput: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    width: "85%",
    marginTop: "1vw",
    marginLeft: "1vw"
  },
  procedureContainer: {
    width: "100%",
    marginTop: "1vw"
  },
  TextInputContainer: {
    borderRadius: ".25vw",
    flexDirection: 'row',
    borderWidth: 0.1,
    borderColor: '#CDD1D5',
    backgroundColor: "transparent",
  },
  TextInputStyle: {
    backgroundColor: "transparent",
    height: "2.27vw",
    padding: ".3vw",
    paddingLeft: "1vw",
    width: "12vw",
    fontSize: ".9vw"
  },
  TextReasonInputStyle: {
    backgroundColor: "transparent",
    height: "5.5vw",
    padding: ".3vw",
    paddingLeft: "1.3vw",
    width: "100%",
    paddingTop: ".5vw",
    fontSize: ".9vw"
  },
  TextInputWrapper: {
    marginTop: ".32vw",
    marginRight: ".32vw",
    zIndex: -1
  },
  TextInputlabel: {
    fontSize: "0.78vw",
    position: "absolute",
    marginLeft: "0.65vw",
    backgroundColor: "white",
    paddingLeft: ".32vw",
    paddingRight: ".32vw",
    color: color.black
  },
  OprativeContainer: {
    width: "100%",
    marginTop: "1vw"
  },
  oprativeInput: {
    marginTop: "1vw",
    marginLeft: "1vw"
  },
  BottomButtonContainer: {
    height: "6vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  ImageStyle: {
    marginRight: ".3vw",
    tintColor: color.white,
    height: "1.4vw",
    width: "1.4vw"
  },
  errorContainer: {
    width: "100%",
    height: "2vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "-1.3vw",
    marginTop: ".5vw"
  },
  ErrorText: {
    fontWeight: "600",
    color: "red",
    fontSize: "1.3vw"
  },
  HistoryPopupContainer: {
    height: "44vw",
    width: "94vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  PopupHeaderConatiner: {
    backgroundColor: "white",
    width: "55%",
    borderRadius: "0.65vw",
    padding: "1.30vw"
  },
  PopupHeaderWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: "3vw",
    alignItems: "center"
  },
  HeaderTextStyle: {
    color: color.themeDark,
    fontSize: "1.23vw",
    marginTop: "1vw",
    fontWeight: '500',
    height: "3vw"
  },
  TableBodyContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    height: '3.3vw',
    paddingHorizontal: ".5vw",
    borderRadius: 4
  },
  TableBodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: "center",
    alignItems: "center"
  },
  TableBodyText: { fontSize: ".9vw" },
  HistoryHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: color.appointmentTableHead,
    height: "3.25vw",
    marginTop: ".32vw",
    paddingHorizontal: ".5vw"
  },
  appointmentTableHeadText: {
    fontSize: ".9vw",
    fontWeight: "500",
    textAlign: "center"
  },
  scrollViewstyles: {
    overflow: "scroll",
    height: "30vw"
  },
  NoRecordsText: {
    width: "100%",
    fontSize: "1.5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10vw"
  },
  templateMain: { height: '2vw', display:'flex', flexDirection:'row', marginRight: "0.5vw", minWidth: "9.8" },
  tempList: { minWidth: '17vw', fontSize: '0.9vw' },
  tempDataStyle: { fontSize: '0.9vw', fontWeight:'500' },
  tempSuggMain: { alignSelf: "flex-end", marginTop: '1.3vw', width: "15%" },
  textBoxLabel: { position: 'absolute', marginLeft: '0.65vw', backgroundColor: "white", fontSize: '0.78vw', paddingLeft: '0.32vw', paddingRight: '0.32vw', borderCsolor: "#888888" },
  tempDropDown: {
    height: '12.69vw', marginBottom: '0.65vw', flex: 1,
    backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: '0.26vw'}
})