import React, { Component } from "react";
import { Text, View, CheckBox, TouchableOpacity, TextInput, StyleSheet } from "react-native";
import BaseComponentStyle from "../../BaseComponentStyle";
import { color } from "../../../../styles/Color";
import { DatePicker } from "antd";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import Arrow from "react-native-vector-icons/FontAwesome5";
import Success from "react-native-vector-icons/FontAwesome5";
import Style from "../../../../styles/Style";
import AsyncStorage from "../../../../AsyncStorage";
var Messages = require('../../../../utils/InfoMessages')


export class ObstetricSonographyReport extends BaseComponentStyle {

  constructor(props) {
    super(props)

    this.state = {
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      refby: "",
      selectDate: "",
      menstrualage: "",
      weeks: "",
      indication: "",
      numberoffetuses: "",
      lie: "",
      presentation: "",
      fetalspine: "",
      fetalheartpulsations: "",
      activefetalmovements: "",
      amnioticfluids: "",
      positions: "",
      maturity: "",
      fetelAnomaly: false,
      checkOthers: false,
      others: "",
      crl: "",
      bpo: "",
      hc: "",
      fl: "",
      ao: "",
      efw: "",
      remarks: "",
      suggested: "",
      is_finished: false,
      enableFinish: false,
      responseType: "",
      responseMeaasge: "",
      isNavigate: "",
      report_id: this.props.reportID,
      selectedIpPatientDetails:this.props.selectedIpPatientDetails,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      otPatienDetils: this.props.otPatientDetail,
      userType: this.props.userType,
      clinicId: ""
    }
  }


 async componentDidMount() {
    this.getPartialValues()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      clinicId: loggedInDataObj.clinic_id
    })
  }

  getPartialValues = () => {

    if(this.state.report_id){
    if(this.state.isCurrentPageIsIP&&this.state.selectedIpPatientDetails){
      var service_url = Constants.POST_OBSTETRIC_SONOGRAPHY + "?appointment_id=" + this.state.selectedIpPatientDetails.appointment_id + "&report_id=" + this.state.report_id;
    }else{
    var service_url = Constants.POST_OBSTETRIC_SONOGRAPHY + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&report_id=" + this.state.report_id;
  }
}
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getReportOrdersSuccess,
      this.getReportOrdersFailure
    );
  }

  getReportOrdersSuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        refby:response.data.obstetric_sonography_report.referred_by_doctor,
        selectDate:response.data.obstetric_sonography_report.LMP,
        menstrualage:response.data.obstetric_sonography_report.menstrual_age,
        weeks:response.data.obstetric_sonography_report.weeks,
        indication:response.data.obstetric_sonography_report.indication,
        numberoffetuses:response.data.obstetric_sonography_report.num_of_fetuses,
        lie:response.data.obstetric_sonography_report.lie,
        presentation:response.data.obstetric_sonography_report.presentation,
        fetalspine:response.data.obstetric_sonography_report.fetal_spine,
        fetalheartpulsations:response.data.obstetric_sonography_report.fetal_heart_pulsations,
        activefetalmovements:response.data.obstetric_sonography_report.active_fetal_movements,
        amnioticfluids:response.data.obstetric_sonography_report.aminotic_fluids,
        positions:response.data.obstetric_sonography_report.positions,
        maturity:response.data.obstetric_sonography_report.maturity,
        fetelAnomaly:response.data.obstetric_sonography_report.is_checked_none,
        checkOthers:response.data.obstetric_sonography_report.is_checked_others,
        others:response.data.obstetric_sonography_report.others_desc,
        crl:response.data.obstetric_sonography_report.CRL,
        bpo:response.data.obstetric_sonography_report.BPD,
        hc:response.data.obstetric_sonography_report.HC,
        fl:response.data.obstetric_sonography_report.FL,
        ao:response.data.obstetric_sonography_report.AC,
        efw:response.data.obstetric_sonography_report.EFW,
        remarks:response.data.obstetric_sonography_report.remarks,
        suggested:response.data.obstetric_sonography_report.suggested,
        is_finished:response.data.obstetric_sonography_report.is_finished
      })
    }
  }

  getReportOrdersFailure = error => { }


  showResposeValue(type, meaasge) {
    // alert(meaasge)
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }



  renderHeaderView = () => {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View>

            <Arrow
              name="arrow-left"
              size={'1.2vw'}
              color={color.black}
              onPress={() => {
                this.props.changeScreen("reportsScreen");
              }}
            />
          </View>
          <View style={styles.PatientDetailsView}>
            <Text style={styles.PatientName}>{this.state.patientInfo.name?this.state.patientInfo.name:this.state.selectedIpPatientDetails.patient_name?this.state.selectedIpPatientDetails.patient_name : ""}

              <Text
                style={styles.PatientDetailsText}
              >
                {this.state.patientInfo.dob ? this.state.patientInfo.dob || "" :this.state.selectedIpPatientDetails.patient_dob?this.state.selectedIpPatientDetails.patient_dob||"" :""}
                {this.state.patientInfo.dob || this.state.selectedIpPatientDetails.patient_dob ? "  |  " : ''}
                {this.state.patientAppointment.appointment_date ? this.state.patientAppointment.appointment_date || "" :this.state.selectedIpPatientDetails.admission_date?this.state.selectedIpPatientDetails.admission_date||"" :""}
                {this.state.patientAppointment.appointment_date || this.state.selectedIpPatientDetails.admission_date ? "  |  " : ''}
                {this.state.patientAppointment.appointment_start_time ? this.state.patientAppointment.appointment_start_time || "" :this.state.selectedIpPatientDetails.admission_time?this.state.selectedIpPatientDetails.admission_time||"" : ""}
                {this.state.patientAppointment.appointment_start_time || this.state.selectedIpPatientDetails.admission_time ? "  |  " : ''}
                {this.state.patientInfo.mobile_number ? this.state.patientInfo.mobile_number || "" :this.state.selectedIpPatientDetails.patient_mobile_number?this.state.selectedIpPatientDetails.patient_mobile_number||"": ""}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  };


  successAlert() {
    return (
      <View style={styles.SuccessMsgContainer}>
        <View style={styles.SuccessMsgView}>
          <Success size={'1.5vw'} name={"check"} color={"green"} />
          <Text style={styles.SucessText}>{"Success: "}
            <Text style={styles.SucessResponseMsg}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icon size={'1.5vw'} name={"close"} color={"#B2C6AA"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {

    let errorList = this.state.responseMeaasge && this.state.responseMeaasge.length > 0 && (this.state.responseMeaasge).split(",")
    return (
      <View style={styles.ErrorMsgContainer}>
        <View style={styles.ErrorMsgView}>
          <View style={{}}><Icon size={'1.5vw'} name={"ban"} color={"#D26560"} /></View>
          <View style={{ flexDirection: "column", justifyContent: "flex-start" }} >
            <Text style={styles.ErrorText}>{"Error: "}
              {errorList && errorList.map((item, index) => {
                return <Text style={styles.ErrorMsgText}>{item}</Text>
              })}
            </Text>
          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
          <Icon size={'1.5vw'} name={"close"} color={"#C69A99"} />
        </TouchableOpacity>
      </View>
    );
  }


  addObstetricSonography(key) {

    this.setState({
      is_finished: key,


    })

    var selectedDate = this.state.selectDate ? moment(this.state.selectDate).format("YYYY-MM-DD") : ""
    let data = {
      clinic_id: this.state.clinicId ? this.state.clinicId : "",
      patient_id:   this.state.otPatienDetils.patient_id ? this.state.otPatienDetils.patient_id: this.state.selectedIpPatientDetails &&this.state.isCurrentPageIsIP ?this.state.selectedIpPatientDetails.patient_id: this.state.patientAppointment.patient_id,
      appointment_id:    this.state.otPatienDetils ? this.state.otPatienDetils.appointment : this.state.selectedIpPatientDetails &&this.state.isCurrentPageIsIP ?this.state.selectedIpPatientDetails.appointment_id: this.state.patientAppointment.appointment_id,
      obstetric_sonography_report: {
        referred_by_doctor: this.state.refby,
        LMP: selectedDate,
        menstrual_age: this.state.menstrualage,
        weeks: this.state.weeks,
        indication: this.state.indication,
        num_of_fetuses: this.state.numberoffetuses,
        lie: this.state.lie,
        presentation: this.state.presentation,
        fetal_spine: this.state.fetalspine,
        fetal_heart_pulsations: this.state.fetalheartpulsations,
        active_fetal_movements: this.state.activefetalmovements,
        aminotic_fluids: this.state.amnioticfluids,
        positions: this.state.positions,
        maturity: this.state.maturity,
        is_checked_none: this.state.fetelAnomaly,
        is_checked_others: this.state.checkOthers,
        others_desc: this.state.others,
        CRL: this.state.crl,
        BPD: this.state.bpo,
        HC: this.state.hc,
        FL: this.state.fl,
        AC: this.state.ao,
        EFW: this.state.efw,
        remarks: this.state.remarks,
        suggested: this.state.suggested,
        is_finished: key
      }
    }

    var service_url = Constants.POST_OBSTETRIC_SONOGRAPHY;
    if (this.state.refby || selectedDate || this.state.menstrualage || this.state.weeks || this.state.indication || this.state.numberoffetuses
      || this.state.lie || this.state.presentation || this.state.fetalspine || this.state.fetalheartpulsations || this.state.activefetalmovements
      || this.state.amnioticfluids || this.state.positions || this.state.maturity || this.state.fetelAnomaly ||
      this.state.crl || this.state.bpo || this.state.hc || this.state.fl ||
      this.state.ao || this.state.efw || this.state.remarks || this.state.suggested
    ) {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.addObstetricSonographySuccess,
        this.addObstetricSonographyFailure
      );
    } else {
      this.showResposeValue('error', Messages.SonographyReport)
    }
  }

  addObstetricSonographySuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        enableFinish: true,
        isNavigate: true
      })
      this.showResposeValue('success', response.message)
    } else {
      this.showResposeValue('error', response.message)
    }

    if (this.state.is_finished == true) {
      this.props.changeScreen("reportsScreen", {}, "", {}, "isScanReport");
    }


  }

  addObstetricSonographyFailure = (response) => {
    if (response.status == "fail") {
      this.showResposeValue('error', response.message)
      //  alert(response.message)
    }
    //  alert("Error")
  }

  clearObstetricSonography = () => {
    let states = this.state;
    states["refby"] = ""
    states["selectDate"] = ""
    states["menstrualage"] = ""
    states["weeks"] = ""
    states["indication"] = ""

    states["numberoffetuses"] = ""
    states["lie"] = ""
    states["presentation"] = ""
    states["fetalspine"] = ""
    states["fetalheartpulsations"] = ""

    states["activefetalmovements"] = ""
    states["amnioticfluids"] = ""
    states["positions"] = ""
    states["maturity"] = ""

    states["fetelAnomaly"] = ""
    states["checkOthers"] = ""
    states["others"] = ""
    states["crl"] = ""
    states["bpo"] = ""
    states["hc"] = ""
    states["fl"] = ""
    states["ao"] = ""
    states["efw"] = ""
    states["remarks"] = ""
    states["suggested"] = ""


    this.setState({ states });
  }

  renderCheckBox(label, value, key) {
    return (
      <View style={styles.CheckBoxContainer}>
        <CheckBox value={value}
        style={{fontSize: '1vw', height: '1.2vw', width: '1.2vw'}}
          onValueChange={() => {
            this.copyCheckBoxValues(key)
          }}

        />
        <Text style={styles.CheckLabel}>{label}</Text>
      </View>
    );
  }
  copyCheckBoxValues(key) {
    var states = this.state;
    states[key] = !states[key]
    this.setState({
      states
    })
  }

  saveObstetricSonoGraphy = () => {

  }

  createPatientDetails(value, key) {
    var states = this.state
    states[key] = value;
    this.setState({ states })
  }

  renderTextBox(placeholder, width, center, state, value, disable = false) {
    return (
      <View>
        <Text style={styles.TextBoxLabel}>{placeholder}</Text>
        {
          disable ?
            <Text style={[Style.commonTextBoxStyles, { marginTop: '0.5vw', color: "#888888", paddingTop: '0.6vw' }]} >
              {value}
            </Text> :
            <TextInput

              style={[Style.commonTextBoxStyles, { marginTop: '0.5vw', fontSize: '1vw' }]} 
              value={value}
              onChangeText={(text) => {
                // value = text;
                // props.callBack(text, props.textKey);
                this.createPatientDetails(text, state);
              }}
            // onSubmitEditing={() => {
            //     props.enterTosubmit ?
            //         props.callBack(value, props.textKey, true)
            //         : null
            // }
            // }
            />

        }

      </View>
    )
  }




  render() {
    var otAppointment = this.state.otPatienDetils.appointment ? this.state.otPatienDetils.appointment : null
    return (
      <View style={styles.SonographyContainer}>

        {this.state.responseType ?
          <View style={{ alignSelf: 'flex-end', width: "35%" }}>
            {
              this.state.responseType == "success" ?
                this.successAlert() :
                this.state.responseType == "error" ?
                  this.errorAlert() : null
            }
          </View> : null}

        <View>
          {
            this.state.patientAppointment.patient_id || this.state.selectedIpPatientDetails.patient_id ? this.renderHeaderView() : null
          }

        </View>
        <View style={styles.HeaderView}>
          <Text style={[styles.HeaderText, {color: color.black }]}>
            Obstetric Sonography Report
          </Text>
        </View>

        <View style={styles.HeaderView}>
          <Text style={[styles.SubHeadText, {color: color.black }]}>
            Basic Details
          </Text>
        </View>
        <View style={{ flexDirection: "row"}}>
          <View style={styles.BasicDetailsRef}>

            {this.state.refby ?
              this.renderTextBox("Ref.by Dr", 10, false, "refby", this.state.refby)
              : this.renderTextBox("Ref.by Dr", 10, false, "refby", this.state.refby)
            }

          </View>
          <View style={styles.DateView}>
            <DatePicker
              clearIcon={false}
              onChange={(date) => {
                // alert(date);
                this.setState({ selectDate: date });
              }}
              defaultValue={""}
              placeholder="Select Date"
              format="DD-MM-YYYY"
              value={this.state.selectDate?moment(this.state.selectDate,"DD-MM-YYYY"):null}
              style={{
                zIndex: -1,
                width: "85%",
                borderColor: "#CDD1D5",
                height: '2.2vw',
                borderRadius: 10,
                borderWidth: 1,
                marginTop: '2vw',
                fontSize: '1vw'
              }}
            />
          </View>
          <View style={styles.BasicDetailsAge}>
            {this.state.menstrualage ?
              this.renderTextBox("Menstrual Age", 10, false, "menstrualage", this.state.menstrualage)
              : this.renderTextBox("Menstrual Age", 10, false, "menstrualage", this.state.menstrualage)
            }


          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.BasicWeeksTextBox}>

            {this.state.weeks ?
              this.renderTextBox("Weeks", 10, false, "weeks", this.state.weeks)
              : this.renderTextBox("Weeks", 10, false, "weeks", this.state.weeks)
            }

          </View>
          <View style={styles.BasicIndication}>

            {this.state.indication ?
              this.renderTextBox("Indication", 10, false, "indication", this.state.indication)
              : this.renderTextBox("Indication", 10, false, "indication", this.state.indication)
            }


          </View>
        </View>
        <View style={styles.HeaderView}>
          <Text style={[styles.SubHeadText, { color: color.black }]}>
            Scan Findings
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.ScanFindTextBox}>

            {this.state.numberoffetuses ?
              this.renderTextBox("Number of Fetuses", 10, false, "numberoffetuses", this.state.numberoffetuses)
              : this.renderTextBox("Number of Fetuses", 10, false, "numberoffetuses", this.state.numberoffetuses)
            }


          </View>
          <View style={styles.ScanFindTextBox}>

            {this.state.lie ?
              this.renderTextBox("Lie", 10, false, "lie", this.state.lie)
              : this.renderTextBox("Lie", 10, false, "lie", this.state.lie)
            }


          </View>
          <View style={styles.ScanFindTextBox}>

            {this.state.presentation ?
              this.renderTextBox("Presentation", 10, false, "presentation", this.state.presentation)
              : this.renderTextBox("Presentation", 10, false, "presentation", this.state.presentation)
            }

          </View>
          <View style={styles.ScanFindTextBox}>

            {this.state.fetalspine ?
              this.renderTextBox("Fetal Spine", 10, false, "fetalspine", this.state.fetalspine)
              : this.renderTextBox("Fetal Spine", 10, false, "fetalspine", this.state.fetalspine)
            }

          </View>
          </View>
        <View style={{ flexDirection: "row" }}>
        <View style={styles.BasicWeeksTextBox}>

          {this.state.fetalheartpulsations ?
            this.renderTextBox("Fetal Heart Pulsations", 10, false, "fetalheartpulsations", this.state.fetalheartpulsations)
            : this.renderTextBox("Fetal Heart Pulsations", 10, false, "fetalheartpulsations", this.state.fetalheartpulsations)
          }

        </View>
          <View style={styles.BasicWeeksTextBox}>

            {this.state.activefetalmovements ?
              this.renderTextBox("Active Fetal Movement", 10, false, "activefetalmovements", this.state.activefetalmovements)
              : this.renderTextBox("Active Fetal Movement", 10, false, "activefetalmovements", this.state.activefetalmovements)
            }

          </View>
          <View style={styles.BasicIndication}>

            {this.state.amnioticfluids ?
              this.renderTextBox("Amniotic Fluids", 10, false, "amnioticfluids", this.state.amnioticfluids)
              : this.renderTextBox("Amniotic Fluids", 10, false, "amnioticfluids", this.state.amnioticfluids)
            }

          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={styles.Placentaview}>
            <Text style={[styles.PatientName, { color: color.black }]}>
              Placenta
          </Text>
          </View>
          <View style={styles.PositionTextBoxView}>

            {this.state.positions ?
              this.renderTextBox("Positions", 10, false, "positions", this.state.positions)
              : this.renderTextBox("Positions", 10, false, "positions", this.state.positions)
            }


          </View>
          <View style={styles.MaturityTextBoxView}>

            {this.state.positionmaturitys ?
              this.renderTextBox("Maturity", 10, false, "maturity", this.state.maturity)
              : this.renderTextBox("Maturity", 10, false, "maturity", this.state.maturity)
            }
          </View>
        </View>


        <View style={{ flexDirection: "row" }}>
          <View style={styles.Placentaview}>
            <Text style={[styles.PatientName, { color: color.black }]}>
              Fetal Anomaly
          </Text>
          </View>
          <View style={styles.CheckBoxView}>
            {this.renderCheckBox("None hat can be detected monographically is seen", this.state.fetelAnomaly, "fetelAnomaly")}
          </View>
          <View style={styles.OtherCheckBox}>
            {this.renderCheckBox("", this.state.checkOthers, "checkOthers")}
          </View>
          <View style={{ width: "20%" }}>


            {this.state.others ?
              this.renderTextBox("Others", 10, false, "others", this.state.others)
              : this.renderTextBox("Others", 10, false, "others", this.state.others)
            }

          </View>
        </View>
        <View style={styles.HeaderView}>
          <Text style={[styles.PatientName, {color: color.black }]}>
            Measurements and Dating
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxCRL}>

            {this.state.crl ?
              this.renderTextBox("CRL", 10, false, "crl", this.state.crl)
              : this.renderTextBox("CRL", 10, false, "crl", this.state.crl)
            }


          </View>
          <View style={styles.TextBoxCRL}>

            {this.state.bpo ?
              this.renderTextBox("BPO", 10, false, "bpo", this.state.bpo)
              : this.renderTextBox("BPO", 10, false, "bpo", this.state.bpo)
            }

          </View>
          <View style={styles.TextBoxHC}>

            {this.state.hc ?
              this.renderTextBox("HC", 10, false, "hc", this.state.hc)
              : this.renderTextBox("HC", 10, false, "hc", this.state.hc)
            }


          </View>
          <View style={styles.TextBoxHC}>

            {this.state.fl ?
              this.renderTextBox("FL", 10, false, "fl", this.state.fl)
              : this.renderTextBox("FL", 10, false, "fl", this.state.fl)
            }

          </View>
          <View style={styles.TextBoxHC}>

            {this.state.ao ?
              this.renderTextBox("Ao", 10, false, "ao", this.state.ao)
              : this.renderTextBox("Ao", 10, false, "ao", this.state.ao)
            }


          </View>
          <View style={styles.TextBoxEFW}>

            {this.state.efw ?
              this.renderTextBox("EFW", 10, false, "efw", this.state.efw)
              : this.renderTextBox("EFW", 10, false, "efw", this.state.efw)
            }


          </View>

        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.TextBoxRemark}>


            {this.state.remarks ?
              this.renderTextBox("Remarks", 10, false, "remarks", this.state.remarks)
              : this.renderTextBox("Remarks", 10, false, "remarks", this.state.remarks)
            }


          </View>
          <View style={styles.TextBoxSuggest}>

            {this.state.suggested ?
              this.renderTextBox("Suggested", 10, false, "suggested", this.state.suggested)
              : this.renderTextBox("Suggested", 10, false, "suggested", this.state.suggested)
            }


          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <View style={styles.BtnContainer}>
            <TouchableOpacity
              style={{ marginLeft: 5 }}
              disabled = {otAppointment === null ? true : false}
              onPress={() => this.addObstetricSonography(false)}
            >
              <View
                style={[styles.BtnView, {backgroundColor: otAppointment === null ? color.disableComponentColor : color.themeDark}]}
              >
                <Icon name="save" size={'1vw'} color={color.white} />
                <Text
                  style={[styles.BtnText, {color: color.white}]}
                >
                  {"Save"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          {this.state.enableFinish == true ?
            <View style={styles.BtnContainer}>
              <TouchableOpacity
                style={{ marginLeft: 5 }}
                onPress={() => this.addObstetricSonography(true)}
              >
                <View
                  style={[styles.BtnView, {backgroundColor: color.themeDark}]}
                >
                  <Icon name="check" size={'1vw'} color={color.white} />
                  <Text
                    style={[styles.BtnText, {color: color.white}]}
                  >
                    {"Finish"}
                  </Text>
                </View>
              </TouchableOpacity>
            </View> : null
          }
          <View style={styles.BtnContainer}>
            <TouchableOpacity
              style={{ marginLeft: 5 }}
              onPress={this.clearObstetricSonography.bind(this)}
            >
              <View
                style={[styles.BtnView, {backgroundColor: color.themeDark}]}
              >
                <Icon name="close" size={'1vw'} color={color.white} />
                <Text
                  style={[styles.BtnText, {color: color.white}]}
                >
                  {"Clear"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: "0.4vw" }}>{  otAppointment === null ? <Text style= {{ fontSize: "0.8vw", fontWeight: "500", color: color.red }}>{"Note: Kindly create IP Admission"}</Text> : null }</View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
        PatientDetailsView: { 
          marginLeft: '1.2vw' 
        },
        SonographyContainer: { 
          margin: '1vw' 
        },
        PatientName: {
           fontSize: '1vw' 
        },
        PatientDetailsText: {
          fontSize: '0.6vw',
          color: "#ADB4C1",
          marginTop: '0.3vw',
          marginBottom: '0.3vw',
          marginLeft: '0.6vw',
          alignSelf: "center"
        },
        HeaderView: { 
          marginTop: '1.2vw' 
        },
        HeaderText: {
          fontSize: '1.2vw'
        },
        SubHeadText: {
          fontSize: '1vw'
        },
        BasicDetailsRef: { 
          marginTop: '2vw', 
          width: "40%", 
          marginRight: '0.6vw' 
        },
        TextBoxLabel: { 
          fontSize: '0.8vw', 
          position: "absolute", 
          marginLeft: '0.6vw', 
          backgroundColor: "white", 
          paddingLeft: '0.3vw', 
          paddingRight: '0.3vw', 
          color: "#888888" 
        },
        BasicDetailsAge: { 
          marginTop: '2vw', 
          marginLeft: '-3.3vw', 
          width: "20%" 
        },
        DateView: { 
          width: "30%", 
          marginTop: '0.5vw' 
        },
        BasicWeeksTextBox: { 
          marginTop: '0.3vw', 
          width: "20%", 
          marginRight: '0.6vw' 
        },
        BasicIndication: { 
          marginTop: '0.3vw', 
          width: "20%" 
        },
        ScanFindTextBox: { 
          marginTop: '1.2vw', 
          width: "20%", 
          marginRight: '0.6vw' 
        },
        ScanFindTextBox1: { 
          marginTop: '1.2vw', 
          width: "18%" 
        },
        Placentaview: { 
          marginTop: '1vw', 
          width: "10%" 
        },
        PositionTextBoxView: { 
          marginTop: '0.6vw', 
          width: "20%", 
          marginRight: '0.6vw' 
        },
        MaturityTextBoxView: { 
          marginTop: '0.6vw', 
          width: "20%" 
        },
        CheckBoxView: { 
          marginTop: '1vw', 
          width: "40%", 
          marginRight: '0.6vw' 
        },
        OtherCheckBox: { 
          marginTop: '1vw', 
          // width: "10%",
          marginRight: '-2vw' 
        },
        TextBoxCRL: {
           marginTop: '1.2vw', 
           width: "12%", 
           marginRight: '0.6vw' 
        },
        TextBoxHC: { 
          marginTop: '1.2vw', 
          width: "10%", 
          marginRight: '0.6vw' 
        },
        TextBoxEFW: { 
          marginTop: '1.2vw', 
          width: "10%" 
        },
        TextBoxRemark: { 
          marginTop: '0.6vw', 
          width: "40%", 
          marginRight: '0.6vw' 
        },
        TextBoxSuggest: { 
          marginTop: '0.6vw', 
          width: "40%" 
        },
        BtnContainer: { 
          marginTop: '1.2vw', 
          width: "7%" 
        },
        BtnView: {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flex: 0.2,
          borderRadius: '0.3vw',
          minHeight: '2.3vw',
        },
        BtnText: {
          fontSize: '1vw',
          fontWeight: "700",
          marginLeft: '0.3vw'
        },
        CheckBoxContainer:{ 
          flexDirection: 'row', 
          marginRight: '2vw' 
        },
        CheckLabel: { 
          marginLeft: '0.6vw', 
          fontSize: '0.8vw', 
          color: "#888888" 
        },
        SuccessMsgContainer: { 
          flex: 1, 
          zIndex: 3, 
          width: "100%", 
          position: 'absolute', 
          left: 0, 
          top: 0, 
          bottom: 0, 
          right: 0, 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          flexDirection: "row", 
          backgroundColor: "#DEF2D6", 
          borderRadius: '0.3vw', 
          borderColor: "#B8C7B2", 
          borderWidth: 1, 
          height: '4.5vw', 
          marginRight: '0.6vw', 
          marginTop: '0.6vw' 
        },
        SuccessMsgView: { 
          flexDirection: 'row', 
          alignItems: 'center', 
          marginLeft: '0.6vw', 
          flex: 0.95 
        },
        SucessText: { 
          fontWeight: "700", 
          color: "green", 
          fontSize: '0.8vw', 
          marginLeft: '1vw' 
        },
        SucessResponseMsg: { 
          fontWeight: "400", 
          color: "green", 
          fontSize: '0.8vw' 
        },
        ErrorMsgContainer: { 
          flex: 1, 
          zIndex: 3, 
          width: "100%", 
          position: 'absolute', 
          left: 0, 
          top: 0, 
          right: 0, 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          flexDirection: "row", 
          backgroundColor: "#EBC8C4", 
          borderRadius: '0.3vw', 
          borderColor: "#A58B8A", 
          borderWidth: 1, 
          minHeight: '4.5vw', 
          marginRight: '0.6vw', 
          marginTop: '0.6vw' 
        },
        ErrorMsgView: { 
          flexDirection: 'row', 
          alignItems: 'center', 
          marginTop: '1vw', 
          marginLeft: '0.6vw', 
          flex: 0.95 
        },
        ErrorText: { 
          fontWeight: "700", 
          color: "#D26560", 
          fontSize: '0.6vw', 
          marginLeft: '1vw', 
          marginBottom: '1vw' 
        },
        ErrorMsgText: { 
          fontWeight: "400", 
          color: "#D26560", 
          fontSize: '0.6vw', 
          marginLeft: '0.3vw',
          marginBottom: '0.5vw' 
        }
})