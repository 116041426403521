import React from "react";
import {
  View,
  Text,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton } from "../BaseComponent";
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageTitle = "History";
const pageName = "History";
const isPageName = "isHistory";
var Messages=require('../../../utils/InfoMessages')


export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedInhouseTreatment: "",
      selectedHistory: [],
      favoriteInhouse: [],
      favoriteHistory: [],
      complaints: "",
      description: "",
      isInhouseTreatment: false,
      isProcedure: false,
      newItem: "",
      route: "",
      dosage: "",
      newHistory: "",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,

      isInHouseID: "",
      isProcedureID: "",
      errorMessageFlag: false,

      getSelectedPropsValue:[],
      unSelectedMedicalHistory:[]
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getMedicalHistory();
  }

  componentWillReceiveProps(props) {
    let { id } = this.state;
    if(props.editItem &&  props.editItem.item && props.editItem.item.length > 0){
      if (props.editItem.title === isPageName) {
        let newitem = props.editItem.item;
        if (id !== newitem.id) {
        // console.log("*************"+JSON.stringify(props.editItem))
        var medicalHistory = props.editItem.item
        var getMedicalHistory=[];
        for (let i = 0; i < medicalHistory.length; i++) {
          var prepareData = {
            value:medicalHistory[i].disease_name,
            label:medicalHistory[i].disease_name,
          }
          getMedicalHistory.push(prepareData)
          
        }

        this.setState({
          getSelectedPropsValue:medicalHistory,
          selectedHistory:getMedicalHistory,
          description: props.editItem.comments
        },()=>{
          this.props.clearEditedItem(isPageName,"")
        })
    }}
  }
  }

  getMedicalHistory = () => {
    var service_url = Constants.MEDICAL_HISTORY_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getMedicalHistorySuccess,
      this.getMedicalHistoryFailure
    );
  };

  getMedicalHistorySuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["favoriteInhouse"] = response.data;
      field["favoriteHistory"] = response.data;
      this.setState({ field });
    }
  };

  getMedicalHistoryFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {
    let states = this.state;

    states["isEdit"] = true;

    this.setState({ states });
  };

 

  addMedicalHistoryList = (event) => {
    let { newHistory, favoriteHistory } = this.state;
    let field = this.state;

    if (newHistory) {

      favoriteHistory.push(newHistory);
      field["favoriteHistory"] = favoriteHistory;
      field["selectedHistory"].push({ value: newHistory, label: newHistory })
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newHistory"] = "";
      field["isProcedure"] = false;

      this.setState({ field },()=>{
        // console.log("selectedHistory++++++" + JSON.stringify(this.state.selectedHistory))
      });
    }
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  };

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states,
    });
  };



  triggerNewHistoryadd = () => {
    let states = this.state;

    states["isProcedure"] = true;

    this.setState({ states });
  };

  addMedical = (event) => {
    let states = this.state;
    var service_url = Constants.POST_MEDICAL_HISTORY;

    var {getSelectedPropsValue} =this.state;


    // for (let j = 0; j < getSelectedPropsValue.length; j++) {
    //   if(getSelectedPropsValue[i].disease_name == selectedvalue.value){
    //     unSelectedMedicalHistory.push(selectedvalue.value)
    //   }
    // }

    // console.log("Check1 +++" + JSON.stringify(this.state.getSelectedPropsValue))
    


    var unSelectedMedicalHistory=[]
    var getSelectedHistory = []
    for(var i=0; i< this.state.selectedHistory.length; i++){
      getSelectedHistory.push(this.state.selectedHistory[i].value)
      for (let j = 0; j < getSelectedPropsValue.length; j++) {
        if(getSelectedPropsValue[j].disease_name !== this.state.selectedHistory[i].value){
          unSelectedMedicalHistory.push(getSelectedPropsValue[j].disease_name)
          unSelectedMedicalHistory = this.removeDuplicate(unSelectedMedicalHistory)
        }
      }
    }

    let data = {
      patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.patientAppointment.appointment_id,
      deleted_disease_names:unSelectedMedicalHistory,
      disease_names:getSelectedHistory,
      comments: this.state.description
    };

 
    if(this.state.description) {
      // alert(JSON.stringify(data.disease_names))
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    }else{
      this.props.showResposeValue("error",Messages.MedicalHistoryEmptyField);

    }
  };

  getSymptomSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  getSymptomFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = (event) => {
    let states = this.state;
   
      (states["comments"] = ""),
     
      (states["selectedHistory"] = "");
    states["description"] = "";

    this.setState({ states });
  };

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  onPressButton(key, selectedvalue) {
   if (key == "selectedHistory") {

    // var {selectedHistory} = this.state;

    // selectedHistory.push(selectedvalue)
    //   this.setState({
    //     selectedHistory,
    //   });



    var { selectedHistory } = this.state;

    var getSelectedValue;
    var spliceData;

    if (selectedHistory.length > 0) {
      for (var i = 0; i < selectedHistory.length; i++) {
        if (selectedHistory[i].value == selectedvalue.value) {
          spliceData = selectedHistory[i];
          selectedHistory.splice(i, 1)
          var data = this.removeDuplicate(selectedHistory);
          this.setState({ selectedHistory: data })
          return
        } else {
          if (spliceData && spliceData.value !== selectedvalue.value) {
          } else {
            getSelectedValue = selectedvalue
          }
        }
      }

      if (getSelectedValue) { selectedHistory.push(selectedvalue); }
    } else {
      selectedHistory.push(selectedvalue)
    }

    var data = this.removeDuplicate(selectedHistory);
    this.setState({ selectedHistory: data })

    }
    
  }

  render() {
   

    return (
      <TouchableWithoutFeedback onPress={this.addMedicalHistoryList.bind(this)}>
        <View>
          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Medical History",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>
         
          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.favoriteHistory.map((item, index) => {
              if(item){
              var data = { label: item, value: item };
              return (
                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                     disable= {this.state.isLocked}
                    item={data}
                    selectedvalue={this.state.selectedHistory}
                    butttonText={data.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedHistory"}
                  />
                </View>
              );
              }
            })}
          </View>
          {this.state.isProcedure ? (
            <View
              style={[{
                height: 30,
                marginTop: 10,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,
              },Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={{
                  color: color.black,
                  height: 30,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                }}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newHistory}
                onChangeText={(text) => {
                  this.setState({ newHistory: text });
                }}
                onSubmitEditing={this.addMedicalHistoryList.bind(this)}
              />
            </View>
          ) : (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewHistoryadd.bind(this),
              this.state.isLocked
            )
          )}

        <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 20, color: color.black }}>Description</Text>
        </View>

          <View style={{ marginTop: 30 }}>
            {this.renderTextFieldView(
              "Comments",
              "description",
              this.state.description,
              true,
              4,
              '',{},'',false,this.state.isLocked
            )}
          </View>
          <View style={{ marginTop: 20, zIndex: -1 ,alignSelf:"center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addMedical.bind(this)}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View>
            {this.state.show ? (
              <View
                style={{
                  height: 40,
                  width: "100%",
                  backgroundColor: "green",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Symptom Created Successfully
                </Text>
              </View>
            ) : null}
          </View>
          <View>
            {this.state.successOrErrorMessage ? (
              <View
                style={{
                  height: 40,
                  width: "100%",
                  backgroundColor: this.state.successMessageFlag
                    ? "green"
                    : this.state.errorMessageFlag
                    ? "red"
                    : null,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {this.state.successMessageFlag
                    ? "Test Created Successfully"
                    : this.state.errorMessageFlag
                    ? "Test  Failed"
                    : null}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
