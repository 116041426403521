//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, ScrollView, Dimensions, TextInput, Button, Image, Picker, Platform, TouchableOpacity } from 'react-native';
const screenHeight = Dimensions.get("window").height;
import { color } from "./../../../styles/Color";
import Style from "./../../../styles/Style";
import 'antd/dist/antd.css';
import { DatePicker, TimePicker } from 'antd';
import moment from "moment";
import { Radio } from "antd";
import Cancel from "react-native-vector-icons/Entypo";
import Icon from "react-native-vector-icons/FontAwesome";
import AddIcon from '../../../../assets/images/DischargeSummary/Add.svg'
import CancelIcon from '../../../../assets/images/DischargeSummary/Cancel.svg'
var Messages = require('../../../utils/InfoMessages')

import { CommonHistorySectionHeader, CommonButton, NavigationTopHeader } from '../BaseComponent'
import { Dropdown } from 'antd';

import { Constants } from "../../../utils/Constants"
import OpthamologyService from "../../../network/OpthamologyService"
import AsyncStorage from '../../../AsyncStorage';
import { Add } from '@material-ui/icons';
import { Tooltip } from 'antd';
import LabReportDisSummery from './LabReportDisSummery';
// import { TouchableOpacity } from 'react-native-gesture-handler';

const screenheight = Dimensions.get("window").height
const screenwidth = Dimensions.get("window").width
const dateFormatDisplay = 'DD-MM-YYYY'
const startDate = moment(new Date()).format('YYYY-MM-DD')

const navigationHeaderList = [
  { label: "Discharge Summary", value: "dischargeSummary" },
  { label: "Surgery Notes", value: "surgeryNotes" },
]

let defaultMedInOp = {
  "route": "",
  "medicine_name": "",
  "dosage": "",
  "timings": "",
  "duration": "",
  "instructions": ""
}
// create a component
export class DischargeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {

      isIpPatientCheckouted: this.props.isIpPatientCheckouted,
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,

      reasonForAdmit: "",
      diagnosis: "",
      procedure: "",
      courseInHospital: "",
      laboratory: "",
      historyOfPresentIllness: "",
      onExamination: "",
      investigation: "",
      treatmentGiven: "",
      conditionOnDischarge: "",
      adviseOnDischarge: "",
      operationNotes: "",
      treatingConsoltantName1: "",
      treatingConsoltantName2: "",
      treatingConsoltantName3: "",
      treatingConsoltantName4: "",
      bloodGroup: "",
      dateOfSurgery: null,
      admittedOn: "",
      admittedTime:"",
      dischargedOn: "",
      reviewOn: null,
      is_finished: false,
      ip_discharge_summary: [],

      ipPatientDischargeDetail: {},
      DateList: [],
      admittingConsultant: "",
      isOpthamalogyClinic: false,
      ageOrGender: this.props.selectedIpPatientDetails.patient_gender,
      patientAccountNo: this.props.selectedIpPatientDetails.patient_account_number,
      patientAge: this.props.selectedIpPatientDetails.patient_age,
      DOA: "",
      DOS: "",
      DOD: "",
      medicationInOp: [
        defaultMedInOp
      ],
      templateSearchText: "",
      templateData: {},
      selectedTemplate: "",
      selectedTemplateName: "",

      FrequentICDdata: [],
      DiagnosisSearchBy: 'icd',
      DiagnosSearchKey: "",
      DiagSugListOpen: false,
      DiagnosSuggList: [],
      SelectedDiagnosList: [],
      DiagnosErrText: "",
      DiagnosSearchNote: "",
      ProvisDiagnos: "",

      FO_admin_setting: {},
      LabTestReports: [],
      pastHistory: "",
      familyHistory: "",
      localExamination: "",
      surgery: "",
      others: "",

      isPrintClick: false,
      selectedIpPatientDetails: {},

      printHeader: true,
      printFooter: true,
      printOrientation: "portrait",
      isAdmin: false
    }
  }
  changeTextBoxValues(value, key) {
    var states = this.state
    states[key] = value
    this.setState({ states })
    console.log(this.state.operationNotes.length, "this.state.operationNotes")
    if (key == "ageOrGender") {
      this.setState({
        ageOrGender: this.state.selectedIpPatientDetails.patient_gender,
        patientAge: this.state.selectedIpPatientDetails.patientAge
      })
    } else if (key == "patientAccountNo") {
      this.setState({
        patientAccountNo: this.state.selectedIpPatientDetails.patient_account_number
      })
    }
  }


  renderTextBoxwithIndex = (placehold, key, displayFlag, index) => {
    var states = this.state.medicationInOp
    return (
      <View style={{ display: displayFlag ? "flex" : "none" }}>
        <View style={{ marginTop: '1.3vw', marginLeft: '0.32vw' }}>
          <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', color: color.black, fontSize: '0.8vw' }}>{placehold}</Text>
          <TextInput
            maxLength={750}
            editable={this.state.isIpPatientCheckouted ? false : true}
            placeholder={""}
            value={states[index][key]}
            onChangeText={(text) => {
              states[index][key] = text;
              this.setState({ states })
            }}
            multiline={true}
            style={[{ fontSize: '0.9vw', paddingLeft: '0.65vw', borderColor: "#00CED1", width: (key === "timings" || key === "instructions" || key === "medicine_name") ? '19vw' : '7.8vw', height: '4vw', borderWidth: 1, marginTop: '0.52vw', borderRadius: '0.26vw', paddingVertical: '0.65vw' }, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
          />
        </View>

      </View>

    );
  }

  renderTextBox(placehold, value, key, displayFlag) {
    return (
      <View style={{ display: displayFlag ? "flex" : "none" }}>
        <View style={{ marginTop: key == "admittingConsultant" || key == "ageOrGender" || key == "patientAccountNo" ? 0 : '1.3vw', marginLeft: key == "admittingConsultant" ? '2.6vw' : '0.32vw' }}>
          <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: 4, paddingHorizontal: 5, color: key == "ageOrGender" || key == "patientAccountNo" ? color.grayShade : color.black, fontSize: '0.8vw' }}>{placehold}</Text>
          <TextInput
            maxLength={key == "procedure" || key == "surgery" || key == "courseInHospital" ? 3000 : (key == "operationNotes" && this.state.clinic_id == 1) ? 6000 : 1000}
            editable={this.state.isIpPatientCheckouted || this.state.is_finished ? false : true}
            disabled={key == "admittingConsultant" || key == "patientAccountNo" || key == "ageOrGender" ? true : false}
            placeholder={""} value={value}
            onChangeText={(text) => { this.changeTextBoxValues(text, key) }}
            multiline={key == "admittingConsultant" || key == "patientAccountNo" || key == "ageOrGender" ? false : true}
            style={[{ fontSize: '0.9vw', paddingLeft: '0.65vw', borderColor: "#00CED1", width: key == "admittingConsultant" ? '19.5vw' : key == "DOA" || key == "DOS" || key == "DOD" ? '24.41vw' : key == "ageOrGender" || key == "patientAccountNo" ? "100%" : "95%", height: key == "admittingConsultant" || key == 'ageOrGender' || key == "patientAccountNo" ? '2vw' : '4vw', borderWidth: 1, marginTop: '0.52vw', borderRadius: '0.26vw', paddingVertical: '0.65vw' }, this.state.isIpPatientCheckouted || key == "admittingConsultant" ? { backgroundColor: color.disableComponentColor } : {}]}
          />
        </View>

      </View>

    );
  }

  getDetails = async () => {

    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.getDischargeSummaryDetails(this.props.selectedIpPatientDetails.id, this.props.selectedIpPatientDetails.patient_id,)
    this.getPreviousDischargeDetails(this.props.selectedIpPatientDetails.patient_id)
    this.setState({
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      isOpthamalogyClinic: loggedInDataObj.clinic_type.is_opt_clinic,
      clinic_id: loggedInDataObj.profile_id
    })
  }

  async componentDidMount() {

    this.getDetails()
    this.getDischargeSummaryTemplate()


    const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
    const FO_admin_setting = JSON.parse(FO_admin);
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      FO_admin_setting: FO_admin_setting,
      isAdmin: loggedInDataObj.is_admin_access
    })

  }

  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }

  getAssessmentFrequent = () => {

    var service_url = Constants.ASSESSMENT_FREQUANT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAssessmentFrequentSuccess,
      this.getAssessmentFrequentFailure
    );
  };

  getAssessmentFrequentSuccess = response => {
    if (response.status === "success") {
      this.setState({
        FrequentICDdata: response.data
      }, () => { console.log(this.state.FrequentICDdata) })

      // var field = this.state;
      // field["ICDCodeFavoriteDrugs"] = response.data;
      // field["shortDescriptionFavoriteDrugs"] = response.data;
      // field["longDescriptionFavoriteDrugs"] = response.data;
      // this.setState({ field });

    }
  };

  getAssessmentFrequentFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getDischargeSummaryTemplate = () => {
    var service_url = Constants.IP_OPT_DISCHARGE_SUMMARY_TEMPLATE_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getDischargeSummaryTemplateSuccess,
      this.getDischargeSummaryTemplateFailure
    );
  }

  getDischargeSummaryTemplateSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        templateData: response.data,
        templateSuggestionData: response.data
      })
    }
  }

  getDischargeSummaryTemplateFailure = (response) => {
    console.log("getDischargeSummaryTemplateFailure", response)
  }


  getPreviousDischargeDetails = (patientID) => {

    var service_url = Constants.IP_GET_DISCHARGE_DETAILS + "?patient_id=" + patientID;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPreviousDischargeDetailsSuccess,
      this.getPreviousDischargeDetailsFailure
    );
  };
  getPreviousDischargeDetailsSuccess = response => {
    if (response.status === "success") {
      this.setState({
        ip_discharge_summary: response.data.ip_discharge_summary
      })
    }
  };

  getPreviousDischargeDetailsFailure = error => {
    console.log(error);
  };

  renderTreatingConsaltantName(placehold1, value, key) {

    return (
      <View style={{ flex: 1, flexDirection: "row", marginLeft: '0.26vw' }}>
        <TextInput

          placeholder={""} value={value} onChangeText={(text) => { this.changeTextBoxValues(text, key) }}

          style={{ paddingLeft: '0.65vw', borderColor: color.themeDark, width: '16.27vw', borderRadius: '0.26vw', height: '1.95vw', borderWidth: 1, marginTop: '0.52vw', backgroundColor: "#D3D3D3", fontSize: '0.9vw' }}
          editable={false}
        />
      </View>
    )
  }

  getDatesDiff = (start_date, end_date, date_format = "YYYY-MM-DD") => {
    const getDateAsArray = date => {
      return moment(date.split(/\D+/), date_format);
    };
    const diff = getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1;
    const dates = [];
    for (let i = 0; i < diff; i++) {
      const nextDate = getDateAsArray(start_date).add(i, "day");

      dates.push(nextDate.format(date_format))
    }
    this.setState({
      DateList: dates
    })
  };






  renderDatePicker(placehold, key, value) {
    return (
      <View style={{}}>
        <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: '0.65vw', backgroundColor: "white", paddingLeft: '0.32vw', paddingRight: '0.32vw', color: color.grayShade }}>
          {placehold}
        </Text>

        <DatePicker
          disabled={
            key == "admittedOn" ? true :
              key == "" || key == "" ? current => {
                this.compareDates(current)
              } : key == "reviewOn" ? this.state.dischargedOn == undefined || this.state.dischargedOn == "" ? true : false
            : false}
          disabledDate={key == "dischargedOn" ? current => {
            return current && current > moment().add(0, "days");
          } : key == "reviewOn" ? current => {
            return current && current < moment(this.state.dischargedOn).add(1, "days");

          } : key == "dateOfSurgery" ? current => {
            let index = this.state.DateList.findIndex(date => date === moment(current).format('YYYY-MM-DD'))
            return index === -1 && true
          } :

            null}
          value={
            value
              ? moment(value)
              : value
          }
          placeholder={""}
          clearIcon={false}
          onChange={(date) => {
            var states = this.state
            var fulldate = new Date(date);
            var converted_date = moment(fulldate).format("YYYY-MM-DD");
            if(key == "dischargedOn" ){
              if(states["admittedOn"] > converted_date || ( states["dateOfSurgery"] > converted_date) ){
                this.props.showResposeValue('error', "Discharge Date Must be After Admitted & Surgery Date");
              }else{
                states[key] = converted_date
                this.setState({ states },()=>{
                  if(states["reviewOn"] != undefined || states["reviewOn"] != ''){
                    this.setState({
                      reviewOn : this.state.dischargedOn < this.state.reviewOn ? this.state.reviewOn : null
                    })
                  }
                });
              }  
            }
            else if(key == "dateOfSurgery" ){
              if(states["dischargedOn"] != "" && states["dischargedOn"] != undefined && states["dischargedOn"] < converted_date){
                this.props.showResposeValue('error', "Surgery Date Must be before Discharge Date");
              }else{
                states[key] = converted_date
                this.setState({ states });
              }  
            }
            else{
              states[key] = converted_date
              this.setState({ states });
            }
          }}

          format={"YYYY-MM-DD"}
          style={{
            zIndex: -1, width: "100%", height: '2vw',
            borderColor: color.themeDark,
            borderRadius: '0.26vw', borderWidth: 1, marginTop: '0.45vw'
          }}
        />
      </View>
    )
  }

  renderTimePicker(placeholder, key, value) {
    return (
        <View style={{ flex: 0.31 }}>
            <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>
                {placeholder}
            </Text>
            <TimePicker
                disabled={key == "admittedTime" ? true : false}
                use12Hours
                format="h:mm a"
                onFocus={()=>{
                    var states = this.state;
                    states[key] = ""
                    this.setState({states})

                }
                }
                onOk={()=>{
                    this.reference.current.blur()
                }}
                onChange={(time, timeString) => {

                    var states = this.state;
                    states[key] = timeString
                    this.setState({ states })

                }}
                value={value ? moment(value, "hh:mm a") : value}

                style={{
                  zIndex: -1, width: "100%", height: '2vw',
                  borderColor: color.themeDark,
                  borderRadius: '0.26vw', borderWidth: 1, marginTop: '0.45vw'
                }}
            />

        </View>
    )
}




  getDischargeSummaryDetails = (admissionID, patientID) => {

    var service_url = Constants.IP_DISCHARGE_DETAILS_GET + "?patient_id=" + patientID + "&ip_admission_id=" + admissionID;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getDischargeSummarySuccess,
      this.getDischargeSummaryFailure
    );
  };

  DiagnosJSONConversion = (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return [];
    }

  }
  getDischargeSummarySuccess = response => {
    if (response.status === "success") {
      this.setState({
        // ipPatientDischargeDetail: response.data,
        admittedOn: response.data.admitted_on,
        admittedTime: response.data.admitted_time,
        dateOfSurgery: response.data.date_of_surgery,
        dischargedOn: response.data.discharged_on,
        DOA: response.data.doa,
        DOS: response.data.dos,
        DOD: response.data.dod,
        reasonForAdmit: response.data.reason_for_admission,
        diagnosis: response.data.diagnosis,
        SelectedDiagnosList: response.data.diagnosis != null ? this.DiagnosJSONConversion(response.data.diagnosis) : [],
        ProvisDiagnos: response.data.pro_diagnosis,
        procedure: response.data.procedure,
        courseInHospital: response.data.course_in_hospital,
        laboratory: response.data.laboratory_findings,
        conditionOnDischarge: response.data.condition_on_discharge,
        adviseOnDischarge: response.data.advice_on_discharge,
        instructions: response.data.instructions,
        reviewOn: response.data.review_on,
        treatingConsoltantName1: response.data.treating_consultant_name_1,
        treatingConsoltantName2: response.data.treating_consultant_name_2,
        treatingConsoltantName3: response.data.treating_consultant_name_3,
        treatingConsoltantName4: response.data.treating_consultant_name_4,
        bloodGroup: response.data.blood_group,
        is_finished: response.data.is_finished,
        historyOfPresentIllness: response.data.history_of_presenting_illness,
        onExamination: response.data.on_examination,
        investigation: response.data.investigation,
        treatmentGiven: response.data.treatment_given,
        operationNotes: response.data.operation_notes,
        admittingConsultant: response.data.admitting_consultant,
        LabTestReports: response.data.test_configuration,
        pastHistory: response.data.past_history,
        familyHistory: response.data.family_history,
        localExamination: response.data.examination,
        surgery: response.data.surgery,
        obstetric_history: response.data.obstetric_history,
        others: response.data.others

      }, () => {
        var enddate = moment(this.state.admittedOn).format('YYYY-MM-DD')

        this.getDatesDiff(enddate, startDate)
      })
      if (this.state.isOpthamalogyClinic){
        this.setState({
          medicationInOp: response.data.medications_in_op ?  JSON.parse(response.data.medications_in_op) : []
        })
      }else{
        this.setState({
          medicationInOp: response.data.medications_in_op ?  JSON.parse(response.data.medications_in_op) : []
        })
      }
      
    }
  };

  getDischargeSummaryFailure = error => {
    console.log(error); Continue
  };

  static getInstance() {
    if (this.instance == undefined) {
      this.instance = new OpthamologyService();
    }
    return this.instance;
  }


  onSaveButton(key, value) {

    var states = this.state
    if (this.state.isOpthamalogyClinic) {
      var data = {
        "ip_admission_id": this.state.selectedIpPatientDetails.id,
        "patient_id": this.state.selectedIpPatientDetails.patient_id,
        "ip_discharge_summary": {
          "blood_group": states.bloodGroup,
          "admitted_on": states.admittedOn,
          "admitted_time": states.admittedTime,
          "date_of_surgery": states.dateOfSurgery,
          "discharged_on": states.dischargedOn,
          "doa": this.state.DOA,
          "dos": this.state.DOS,
          "dod": this.state.DOD,
          "reason_for_admission": states.reasonForAdmit,
          "diagnosis": JSON.stringify(states.SelectedDiagnosList),
          "pro_diagnosis": this.state.ProvisDiagnos,
          "procedure": states.procedure,
          "operation_notes": states.operationNotes,
          "condition_on_discharge": states.conditionOnDischarge,
          "investication": states.instuction,
          "review_on": states.reviewOn,
          "is_finished": key == "saveButton" ? false : key == "finishButton" ? true : null,
          "treating_consultant_name_1": states.treatingConsoltantName1,
          "treating_consultant_name_2": states.treatingConsoltantName2,
          "treating_consultant_name_3": states.treatingConsoltantName3,
          "treating_consultant_name_4": states.treatingConsoltantName4,
          "medications_in_op": states.medicationInOp,
          "instructions": states.instructions,
          "advice_on_discharge": states.adviseOnDischarge,
          "investigation": states.investigation
        }
      }

    } else {
      var data = {
        "patient_id": this.state.selectedIpPatientDetails.patient_id,
        "ip_admission_id": this.state.selectedIpPatientDetails.id,
        "ip_discharge_summary": {
          "admitted_on": states.admittedOn,
          "admitted_time": states.admittedTime,
          "date_of_surgery": states.dateOfSurgery,
          "discharged_on": states.dischargedOn,
          "reason_for_admission": states.reasonForAdmit,
          "diagnosis": JSON.stringify(states.SelectedDiagnosList),
          "pro_diagnosis": this.state.ProvisDiagnos,
          "procedure": states.procedure,
          "course_in_hospital": states.courseInHospital,
          "laboratory_findings": states.laboratory,
          "condition_on_discharge": states.conditionOnDischarge,
          "advice_on_discharge": states.adviseOnDischarge,
          "review_on": states.reviewOn,
          "is_finished": key == "saveButton" ? false : key == "finishButton" ? true : null,
          "blood_group": states.bloodGroup,
          "treating_consultant_name_1": states.treatingConsoltantName1,
          "treating_consultant_name_2": states.treatingConsoltantName2,
          "treating_consultant_name_3": states.treatingConsoltantName3,
          "treating_consultant_name_4": states.treatingConsoltantName4,
          "history_of_presenting_illness": states.historyOfPresentIllness,
          "on_examination": states.onExamination,
          "treatment_given": states.treatmentGiven,
          "operation_notes": states.operationNotes,
          "investigation": states.investigation,
          "medications_in_op": JSON.stringify(states.medicationInOp) === JSON.stringify([defaultMedInOp]) ? [] : states.medicationInOp,
          "past_history" : states.pastHistory,
          "family_history": states.familyHistory,
          "surgery" : states.surgery,
          "examination" : states.localExamination,
          "obstetric_history": states.obstetric_history,
          "others": states.others,
        }
      };

    }

    var checkTestField = this.checkAllDetailsAreAvailable(states.medicationInOp)
    var service_url = Constants.IP_DISCHARGE_DETAILS_POST;

    if (this.state.dischargedOn == "" || this.state.dischargedOn == undefined) {
      this.props.showResposeValue('error', Messages.InvalidDischargeDate);
    } else if (!checkTestField && states.isOpthamalogyClinic) {
      this.props.showResposeValue('error', "Medication fields can't be empty");
    } else if (!checkTestField && !states.isOpthamalogyClinic && JSON.stringify(states.medicationInOp) != JSON.stringify([defaultMedInOp])) { 
      this.props.showResposeValue('error', "Prescription fields can't be empty");
    } else {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveDischargeSummarySuccess,
        this.saveDischargeSummaryFailure
      );
    }
  }

  saveDischargeSummarySuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);

      this.getDetails()
    } else {
      this.props.showResposeValue('error', success.message);

    }
  }
  saveDischargeSummaryFailure = error => {
    this.props.showResposeValue('error', error.message);
  }

  onFinishButton() {

  }

  renderView = (data) => {
    var service_url = ""
    if((this.state.FO_admin_setting.customize_discharge_summary_view) || this.state.isAdmin) { 
      service_url = Constants.IP_DISCHARGE_DETAILS_VIEW + "?summary_id=" + data.discharge_summary_id + "&appointment_id=" + data.appointment_id + "&page_orientation=" + this.state.printOrientation + "&header_display=" + this.state.printHeader + "&footer_display=" + this.state.printFooter;
    } else {
      service_url = Constants.IP_DISCHARGE_DETAILS_VIEW + "?summary_id=" + data.discharge_summary_id + "&appointment_id=" + data.appointment_id 
    }
    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      // this,
      this.renderViewList,
      this.failurerenderViewList,
      "pdf"
    );
  }

  renderViewList = (response) => {
    this.setState({
      isPrintClick: false
    })
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  failurerenderViewList = (error) => {

    console.log(error);
  }




  renderContinue(data) {
    this.getDischargeSummaryDetails(data.ip_admission_id, data.patient_id,)

  }


  renderdischargesummartyType() {

    return (
      <View >
        {
          this.state.ip_discharge_summary && this.state.ip_discharge_summary.map((item, index) => {
            return (


              <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomColor: color.sectionItemLine, borderBottomWidth: 1, paddingVertical: '0.32vw' }}>
                <View style={{ flex: 0.5, alignItems: "center" }}>
                  <Text>{item.discharged_on}</Text>
                </View>
                <View style={{ flex: 0.5, alignItems: "center" }}>
                  {/* {!item.is_finished ? */}
                  <TouchableOpacity onPress={() => {
                   if(this.state.FO_admin_setting.customize_discharge_summary_view || this.state.isAdmin) { 
                    this.setState({
                      selectedPrintDetails: item,
                      isPrintClick: true
                    })
                   } else {
                     this.renderView(item)
                   }
                  }}>
                    <Text style={{ color: 'blue' }}>View</Text>
                  </TouchableOpacity>
                  {!item.is_finished ?
                    <TouchableOpacity
                      onPress={() => this.renderContinue(item)}
                    >
                      <Text style={{ color: 'blue' }}>Continue</Text>
                    </TouchableOpacity>
                    : null
                  }
                </View>
              </View>
            )
          })

        }
      </View>
    )

  }

  onClearButton = (value = "") => {

    var states = this.state

    let defaultMedInOp = {
      "route": "",
      "medicine_name": "",
      "dosage": "",
      "timings": "",
      "duration": "",
      "instructions": ""
    }

    if (value != "templateChange") {
      states["selectedTemplate"] = ""
    }
    states["bloodGroup"] = ""
    states["dateOfSurgery"] = null
    states["dischargedOn"] = ""
    states["reasonForAdmit"] = ""
    states["diagnosis"] = ""
    states["SelectedDiagnosList"] = []
    states["procedure"] = ""
    states["courseInHospital"] = ""
    states["laboratory"] = ""
    states["conditionOnDischarge"] = ""
    states["adviseOnDischarge"] = ""
    states["reviewOn"] = null
    states["historyOfPresentIllness"] = ""
    states["investigation"] = ""
    states["onExamination"] = ""
    states["treatmentGiven"] = ""
    states["operationNotes"] = ""
    states["DOA"] = ""
    states["DOS"] = ""
    states["DOD"] = ""
    states["instructions"] = ""
    states["ProvisDiagnos"] = ""
    states["selectedTemplateName"] =  value == "templateChange" ? states["selectedTemplateName"] : ""
    states["medicationInOp"] = [defaultMedInOp],
    states["obstetric_history"] = ""
    states["others"] = ""
    states["pastHistory"] = ""
    states["familyHistory"] = ""
    states["localExamination"] = ""
    states["surgery"] = ""

    this.setState({
      states
    })


  }

  getDischargeDetailFromTemplate = () => {
    var { selectedTemplate, templateData } = this.state
    let defaultMedInOp = {
      "route": "",
      "medicine_name": "",
      "dosage": "",
      "timings": "",
      "duration": "",
      "instructions": ""
    }
    if(this.state.isOpthamalogyClinic){
      for (let i = 0; i < Object.keys(templateData).length; i++) {
        if (templateData[i].template_id == selectedTemplate) {
          this.setState({
            reasonForAdmit: templateData[i].reason_for_admission ? templateData[i].reason_for_admission : "" ,
            ProvisDiagnos: templateData[i].pro_diagnosis,
            adviseOnDischarge: templateData[i].advice_on_discharge ? templateData[i].advice_on_discharge : "",
            conditionOnDischarge: templateData[i].condition_on_discharge ? templateData[i].condition_on_discharge : "",
            diagnosis: templateData[i].diagnosis ? templateData[i].diagnosis : "",
            investigation: templateData[i].investigations ? templateData[i].investigations : "",
            procedure: templateData[i].procedures ? templateData[i].procedures : "",
            instructions: templateData[i].special_instruction ? templateData[i].special_instruction : templateData[i].instructions  ? templateData[i].instructions : "" ,
            medicationInOp: templateData[i].medication ? JSON.parse(templateData[i].medication) : [defaultMedInOp],
            operationNotes: templateData[i].operation_notes,
          })
        }
      }
    }
    else{
      for (let i = 0; i < Object.keys(templateData).length; i++) {
        if (templateData[i].template_id == selectedTemplate) {

          if (this.state.clinic_id != 121 ) { 
            this.setState({
              reasonForAdmit: templateData[i].reason_for_admission,
              ProvisDiagnos: templateData[i].pro_diagnosis,
              historyOfPresentIllness: templateData[i].history_of_presenting_illness,
              onExamination: templateData[i].on_examination,
              investigation: templateData[i].investigations,
              procedure: templateData[i].procedures,
              courseInHospital: templateData[i].course_in_hospital,
              conditionOnDischarge: templateData[i].condition_on_discharge,
              treatmentGiven: templateData[i].treatment_given,
              medicationInOp:templateData[i].medication ? JSON.parse(templateData[i].medication) : [],
              adviseOnDischarge: templateData[i].advice_on_discharge,
              operationNotes: templateData[i].operation_notes,
            })
          }
          else {                    //    only for Shanthi Hospital (clinic id = 121)
            this.setState({
              reasonForAdmit: templateData[i].reason_for_admission,
              ProvisDiagnos: templateData[i].pro_diagnosis,
              historyOfPresentIllness: templateData[i].history_of_presenting_illness,
              pastHistory: templateData[i].past_history,
              obstetric_history: templateData[i].obstetric_history,
              familyHistory: templateData[i].family_history,
              onExamination: templateData[i].on_examination,
              localExamination: templateData[i].examination,
              investigation: templateData[i].investigations,
              surgery: templateData[i].surgery,
              procedure: templateData[i].procedures,
              courseInHospital: templateData[i].course_in_hospital,
              conditionOnDischarge: templateData[i].condition_on_discharge,
              treatmentGiven: templateData[i].treatment_given,
              medicationInOp:templateData[i].medication ? JSON.parse(templateData[i].medication) : [],
              adviseOnDischarge: templateData[i].advice_on_discharge,
              others: templateData[i].others,
              operationNotes: templateData[i].operation_notes,
            })
          }
        }
        
      }
    }
    
  }

  checkAllDetailsAreAvailable = (newEntry) => {
    var defaultReturn = true
    for (const data of newEntry) {
      if (data && data.route && data.medicine_name && data.dosage && data.timings) {
        // Do Nothing
      } else {
        defaultReturn = false
        return defaultReturn
      }
    }
    return defaultReturn
  }

  onPressNewMedInOP = () => {
    let { medicationInOp } = this.state;
    let defaultMedInOp = {
      "route": "",
      "medicine_name": "",
      "dosage": "",
      "timings": "",
      "duration": "",
      "instructions": ""
    }
    if (medicationInOp.length > 0) {
      var checkTestField = this.checkAllDetailsAreAvailable(medicationInOp)
      if (checkTestField) {
        medicationInOp.push(defaultMedInOp)
      } else {
        // Do nothing
      }

    } else {
      medicationInOp.push(defaultMedInOp)
    }
    this.setState({ medicationInOp })
  }

  onPressClearNewMedInOp = (index) => {
    var showList = this.state.medicationInOp
    showList.splice(index, 1)
    this.setState({
      showList
    })
  }

  renderTemplateSuggesstion() {
    return (
      <View style={{ zIndex: 1, position: 'absolute', marginTop: '7.16vw', marginLeft: '0.65vw', marginRight: "5%", maxHeight: '16.27vw', minWidth: '16.27vw', maxWidth: '19.53vw', alignSelf: "flex-end" }}>

        <ScrollView
          style={{
            height: '12.69vw', marginBottom: '0.65vw', flex: 1,
            backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: '0.26vw'
          }}
        >
          {this.state.templateSuggestionData && this.state.templateSuggestionData.length > 0 ? this.state.templateSuggestionData.map((value, index) =>
            <View style={{ height: '1.95vw' }}>
              <TouchableOpacity key={index}
                style={{ marginLeft: '0.32vw', marginTop: '0.32vw' }}
                onPress={
                  () => {
                    var states = this.state;
                    states["selectedTemplate"] = value.template_id,
                    states["selectedTemplateName"] = value.template_name,
                      states["templateSearchText"] = ""
                    this.setState({ states }, () => {
                      this.onClearButton("templateChange")
                      this.getDischargeDetailFromTemplate()
                    });
                  }
                }
              >
                <Text style={{ fontSize: '0.9vw' }}>{value.template_name}</Text>

              </TouchableOpacity>
            </View>
          ) : 
          <View>
            <Text style={{margin:'1vw'}}>No Records Found</Text>
            </View>}

        </ScrollView>

      </View>
    )
  }

  GetICDList = () => {
    let QueryParams = ""

    if (this.state.DiagnosisSearchBy == 'icd') {
      QueryParams = "?search_icd=" + this.state.DiagnosSearchKey + "&search_key=";
    }
    else {
      QueryParams = "?search_icd=&search_key=" + this.state.DiagnosSearchKey;
    }

    var service_url = Constants.GET_ICD_CODE_LIST_FO + QueryParams
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getICDlistSuccess,
      this.getICDlistFailure
    );
  };



  getICDlistSuccess = response => {
    if (response.status === "success") {

      this.setState({
        DiagnosSuggList: response.data
      }, () => {
        if (this.state.DiagnosSuggList.length > 0) {
          this.setState({
            DiagSugListOpen: true,
            DiagnosErrText: ""
          })
        }
        else {
          this.setState({
            DiagnosErrText: "No Records Found"
          })
        }
      })
    }
  };

  getICDlistFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderSelectedDiagnosis = () => {
    try {
      return (
        this.state.SelectedDiagnosList && this.state.SelectedDiagnosList.length > 0 ?
          <ScrollView style={{ height: '7.5vw' }}>
            {
              this.state.SelectedDiagnosList.map((list, index) => (
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={[styles.DiagListText, { fontWeight: '500', marginLeft: '1vw' }]}>{list.icd_code}</Text>
                  <Text style={[styles.DiagListText, { color: color.themeDark }]}>|</Text>
                  <View style={styles.DiagListText}>
                    {list.icd_short_desc.length > 45 ?
                      <Tooltip placement="top" title={list.icd_short_desc}>
                        <Text style={styles.DiagListText}> {list.icd_short_desc.slice(0, 45) + ".."}</Text>
                      </Tooltip> :
                      <Text style={styles.DiagListText}> {list.icd_short_desc}</Text>}
                  </View>
                  <Text style={[styles.DiagListText, { color: color.themeDark }]}>|</Text>
                  <View style={styles.DiagListText}>
                    {list.icd_desc.length > 45 ?
                      <Tooltip placement="top" title={list.icd_desc}>
                        <Text style={styles.DiagListText}> {list.icd_desc.slice(0, 45) + ".."}</Text>
                      </Tooltip> :
                      <Text style={styles.DiagListText}> {list.icd_desc}</Text>}
                  </View>
                  <TouchableOpacity
                    disabled={this.state.is_finished ? true : false}
                    onPress={() => {
                      this.state.SelectedDiagnosList.splice(index, 1)
                      this.setState({
                        SelectedDiagnosList: this.state.SelectedDiagnosList
                      })

                    }}
                    style={{
                      backgroundColor: color.white,
                      marginLeft: '0.4vw'
                    }}>
                    <Icon name="trash" size={'1.2vw'} color={color.red} />

                  </TouchableOpacity>
                </View>
              ))
            }
          </ScrollView> :
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: '0.91vw' }}>No Records..</Text>
          </View>

      )
    }
    catch {
      this.setState({
        SelectedDiagnosList: [{ icd_code: "-", icd_short_desc: "-", icd_desc: this.state.SelectedDiagnosList }]
      })
    }
  }
  diagnosisSelection = () => {
    return (
      <View>
        <View style={styles.diagnosisSelectMainView}>
          <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', color: color.black, fontSize: '0.78vw', marginTop: '-0.6vw' }}>{this.state.clinic_id !=121 ? "Diagnosis" : "Final Diagnosis"}</Text>
          <View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <View style={{ width: '40%', height: '9vw' }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0.65vw', marginTop: '0.65vw' }}>
                  <Text style={{ fontSize: '0.9vw' }}>Search By</Text>
                  <Picker
                    disabled={this.state.is_finished ? true : false}
                    style={[styles.pickerView, {
                      width: '8vw', fontSize: '1vw', marginLeft: '0.32vw',
                    }]}
                    selectedValue={this.state.DiagnosisSearchBy}
                    onValueChange={(itemvalue) => {
                      this.setState({
                        DiagnosisSearchBy: itemvalue,
                        DiagSugListOpen: false,
                        DiagnosSearchKey: '',
                        DiagnosSearchNote: ""
                      })
                    }}
                  >
                    <Picker.Item label="ICD Code" value='icd' />
                    <Picker.Item label='Description' value='desc' />


                  </Picker>
                  <View>
                    <View style={{ zIndex: 2 }}>
                      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderWidth: 1, borderColor: "rgb(205, 209, 213)", borderRadius: '0.26vw', }}>
                        <TextInput
                          editable={this.state.is_finished ? false : true}
                          value={this.state.DiagnosSearchKey}
                          placeholder={this.state.DiagnosisSearchBy == "icd" ? "Search By ICD Code" : "Search By Description"}
                          style={{ height: '2vw', backgroundColor: 'white', width: '18vw', fontSize: '1vw', paddingLeft: '.8vw' }}
                          onChangeText={(text) => {
                            this.setState({
                              DiagnosSearchKey: text,
                              DiagSugListOpen: false,
                              DiagnosErrText: "",
                              DiagnosSearchNote: ""
                            })

                          }
                          }
                        />
                        <TouchableOpacity
                          onPress={() => {
                            if (this.state.DiagnosisSearchBy == 'icd') {
                              if (this.state.DiagnosSearchKey.length > 2) {
                                this.GetICDList()
                              }
                              else {
                                this.setState({
                                  DiagnosSearchNote: "* Minimum 3 Characters are Required for Search"
                                })
                              }

                            }
                            if (this.state.DiagnosisSearchBy == 'desc') {
                              if (this.state.DiagnosSearchKey.length > 4) {
                                this.GetICDList()
                              }
                              else {
                                this.setState({
                                  DiagnosSearchNote: "* Minimum 5 Characters are Required for Search"
                                })
                              }
                            }

                          }}
                          style={{
                            backgroundColor: color.white,
                            marginRight: '0.4vw'
                          }}>
                          <Icon name="search" size={'1.2vw'} color={color.themeDark} />

                        </TouchableOpacity>
                      </View>
                      {this.state.DiagnosSuggList && this.state.DiagnosSuggList.length > 0 && this.state.DiagSugListOpen ?
                        <ScrollView showsVerticalScrollIndicator={true} style={styles.DiagSuggListScroll}>
                          {
                            this.state.DiagnosSuggList.map((list, index) =>
                              <TouchableOpacity
                                style={[styles.DiagnosSuggListBtn]}
                                onPress={
                                  () => {
                                    this.state.SelectedDiagnosList.push(list)
                                    this.setState({
                                      SelectedDiagnosList: this.state.SelectedDiagnosList,
                                      DiagSugListOpen: false,
                                      DiagnosSearchKey: "",
                                      DiagnosSearchNote: "",
                                      DiagnosErrText: ''
                                    })
                                  }
                                }
                              >
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <Text style={[styles.DiagListText, { fontWeight: '500' }]}>{list.icd_code}</Text>
                                  <Text style={[styles.DiagListText, { color: color.themeDark }]}>|</Text>
                                  <Text style={styles.DiagListText}>{list.icd_short_desc}</Text>
                                  <Text style={[styles.DiagListText, { color: color.themeDark }]}>|</Text>
                                  <Text style={styles.DiagListText}>{list.icd_desc}</Text>
                                </View>
                              </TouchableOpacity>
                            )
                          }
                        </ScrollView>
                        : null}
                    </View>
                  </View>

                </View>
                <Text style={styles.DiagnosMsgText}>
                  {this.state.DiagnosSearchNote}
                </Text>
                <Text style={styles.DiagErrText}>{this.state.DiagnosErrText}</Text>

              </View>
              <View style={{ width: '55%', height: '9vw', marginLeft: '2.27vw', borderColor: color.themeDark, borderWidth: 1, borderRadius: '0.26vw', marginTop: '0.32vw', zIndex: -2 }}>
                <View>
                  <Text style={styles.DiagListHeding}>Selected Diagnosis</Text>
                  <View>
                    {this.renderSelectedDiagnosis()}
                  </View>
                </View>

              </View>
            </View>
            <View style={{ marginTop: "2vh", zIndex: -2, paddingHorizontal: '1vw' }}>
              <Text style={{ fontSize: '0.8vw', position: "absolute", marginLeft: '0.5vw', backgroundColor: "white", paddingLeft: '0.3', paddingRight: '0.3', color: color.black }}>Provisional Diagnosis</Text>
              <TextInput
                editable={this.state.is_finished ? false : true}
                maxLength={299}
                multiline={true}
                value={this.state.ProvisDiagnos}
                style={{ fontSize: '0.9vw', paddingHorizontal: '0.5vw', paddingTop: '0.5vw', borderColor: "#00CED1", borderWidth: 1, borderRadius: 4, height: '4vw', marginTop: '0.5vw', }}
                onChangeText={(text) => {
                  this.setState({
                    ProvisDiagnos: text
                  })
                }}
              />
            </View>
          </View>

        </View>
      </View>
    )
  }

  renderLabReports = () => {
    return (
      <View>
        <View style={[styles.diagnosisSelectMainView, { height: '30vw', }]}>
          <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', color: color.black, fontSize: '0.78vw', marginTop: '-0.6vw' }}>Laboratory Test Reports</Text>
          <LabReportDisSummery LabReportsData={this.state.LabTestReports} />
        </View>
      </View>
    )
  }


  render() {
    console.log(this.state.FO_admin_setting,"this.state.FO_admin_setting")
    var { ipPatientDischargeDetail, templateData, medicationInOp } = this.state
    return (

      <View style={{ flex: 1, justifyContent: "center" }}>
        <View style={{ width: "65%", height: screenHeight - 65, }}>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={navigationHeaderList}
            selectedTab={"dischargeSummary"} />
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: screenheight - 70, width: screenwidth - 108 }}>
            <View style={{ backgroundColor: '#DCDCDC', marginTop: '1.3vw' }}>
              <Text style={{ textAlign: "left", lineHeight: '1.62vw', marginLeft: '0.26vw', fontSize: '0.91vw' }}>Discharge Summary Form</Text>
            </View>
            {/* {this.state.isOpthamalogyClinic ? */}
              <View style={{ width: "94%", display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center' }}>
                
              { this.state.selectedTemplateName != "" ?
               <Text style={{ minWidth: '17vw', paddingTop: '1.5vw', fontSize: '0.9vw' }}>Selected Template :
               {this.state.selectedTemplateName.length > 20 ?
                 <Tooltip placement="top" title={this.state.selectedTemplateName}>
                   <Text style={{ fontSize: '0.9vw', fontWeight:'500' }}> {this.state.selectedTemplateName.slice(0, 18) + ".."}</Text>
                 </Tooltip> :
                 <Text style={{ fontSize: '0.9vw', fontWeight:'500' }}> {this.state.selectedTemplateName}</Text>}</Text>
               : null
              }
                <View style={{ alignSelf: "flex-end", width: '94%', marginTop: '1.3vw', width: "15%" }}>
                  <Text style={[{ position: 'absolute', marginLeft: '0.65vw', backgroundColor: "white", fontSize: '0.78vw', paddingLeft: '0.32vw', paddingRight: '0.32vw', borderCsolor: "#888888" }]}>{"Templates"}</Text>
                  <TextInput
                    placeholder='Search Templates'
                    style={[Style.pickerView, { marginTop: '0.45vw', borderRadius: '0.26vw', borderColor: color.themeDark, height: '2vw', width: "100%", zIndex: -1, borderWidth: 1, fontSize: '0.91vw' }, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                    value={this.state.templateSearchText}
                    onChangeText={(text) => {
                      let { templateSuggestionData } = this.state;
                      let templateData = this.state.templateData
                      if (templateData.length > 0) {
                        var searchListTemp = [];
                        for (let i = 0; i < templateData.length; i++) {

                          if (templateData[i].template_name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                            searchListTemp.push(templateData[i]);
                          }
                        }
                        templateSuggestionData = searchListTemp;
                      } else {
                        templateSuggestionData = this.state.billingDescriptionList;
                      }
                      this.setState({
                        templateSuggestionData,
                        templateSearchText: text
                      })
                    }}
                  />
                </View>
              </View>
              {/* : null} */}
            {this.state.templateSuggestionData && this.state.templateSearchText != "" ? this.renderTemplateSuggesstion() : null}


            <View style={{ marginLeft: '0.32vw', marginTop: '1.3vw', flexDirection: "row", justifyContent: 'space-between', width: '93%' }}>
              <View style={styles.commonStyles}>
                <Text style={[{ position: 'absolute', marginLeft: '0.65vw', backgroundColor: "white", fontSize: '0.8vw', paddingLeft: '0.32vw', paddingRight: '0.32vw', borderColor: "#888888" }]}>{"Blood group"}</Text>
                <Picker
                  enabled={this.state.isIpPatientCheckouted ? false : true}
                  selectedValue={this.state.bloodGroup}
                  style={[Style.pickerView, { marginTop: '0.45vw', borderRadius: '0.26vw', borderColor: color.themeDark, height: "2vw", width: "100%", zIndex: -1 }, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
                  itemStyle={{ fontSize: '0.91vw' }}
                  onValueChange={(itemvalue) => {
                    var states = this.state;
                    states["bloodGroup"] = itemvalue
                    this.setState({ states });
                  }}
                >

                  <Picker.Item label="" value="" />
                  <Picker.Item label="A positive (A+)" value="A positive (A+)" />
                  <Picker.Item label="A negative (A-)" value="A negative (A-)" />
                  <Picker.Item label="B positive (B+)" value="B positive (B+)" />
                  <Picker.Item label="B negative (B-)" value="B negative (B-)" />
                  <Picker.Item label="O positive (O+)" value="O positive (O+)" />
                  <Picker.Item label="O negative (O-)" value="O negative (O-)" />
                  <Picker.Item label="AB positive (AB+)" value="AB positive (AB+)" />
                  <Picker.Item label="AB negative (AB-)" value="AB negative (AB-)" />
                </Picker>
              </View>

              <View style={styles.commonStyles}>
                {this.renderDatePicker("Date of Surgery", "dateOfSurgery", this.state.dateOfSurgery)}
              </View>
              <View style={styles.commonStyles}>
                {this.renderDatePicker("Admitted On", "admittedOn", this.state.admittedOn)}
              </View>
              <View style={styles.commonStyles}>
                {this.renderTimePicker("Admitted Time", "admittedTime", this.state.admittedTime)}
              </View>
              <View style={styles.commonStyles}>
                {this.renderDatePicker("Discharged On *", "dischargedOn", this.state.dischargedOn)}
              </View>
            </View>
            <View style={{display:"flex", flexDirection:"row", marginTop:"1vw"}}>
              <View style={{width:"13.3vw", width:"13.5vw"}}>
                {this.renderTextBox("Age / Gender", this.state.patientAge + " / " + this.state.ageOrGender, "ageOrGender", this.state.isOpthamalogyClinic ? true : false)}
              </View>
              <View style={[styles.commonStyles,{marginLeft:"4.85vw"}]}>
                {this.renderTextBox("Patient AC.NO", this.state.patientAccountNo, "patientAccountNo", this.state.isOpthamalogyClinic ? true : false)}
              </View>
            </View>
            {/* <View style={styles.commonStyle}>
              <View>{this.renderTextBox("DOA", this.state.DOA, "DOA", this.state.isOpthamalogyClinic ? true : false)}</View>
              <View>{this.renderTextBox("DOS", this.state.DOS, "DOS", this.state.isOpthamalogyClinic ? true : false)}</View>
              <View>{this.renderTextBox("DOD", this.state.DOD, "DOD", this.state.isOpthamalogyClinic ? true : false)}</View>
            </View> */}
            {this.state.clinic_id != 121 ? 
            <View>
            {this.renderTextBox("Reason for admission *", this.state.reasonForAdmit, "reasonForAdmit", true)}
            {this.diagnosisSelection()}
            <View style={{ zIndex: -20 }}>
              {this.renderTextBox("Procedure", this.state.procedure, "procedure", true)}
              {this.renderTextBox("History of present illness", this.state.historyOfPresentIllness, "historyOfPresentIllness", this.state.isOpthamalogyClinic ? false : true)}
              {this.renderTextBox("On Examination", this.state.onExamination, "onExamination", this.state.isOpthamalogyClinic ? false : true)}
            </View>
            {this.renderTextBox("Investigation", this.state.investigation, "investigation", true)}
            {this.renderTextBox("Treatment Given", this.state.treatmentGiven, "treatmentGiven", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("Operation Notes", this.state.operationNotes, "operationNotes", true)}
            {this.renderTextBox("Course in hospital", this.state.courseInHospital, "courseInHospital", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("Condition on discharge", this.state.conditionOnDischarge, "conditionOnDischarge", true)}
            {this.renderTextBox("Advise on discharge *", this.state.adviseOnDischarge, "adviseOnDischarge", true)}

            {/* {this.state.isOpthamalogyClinic ?   */}
              <View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: '0.91vw', marginTop: '0.97vw', marginLeft: '1vw' }}>{this.state.isOpthamalogyClinic ? "Medication" : "Prescription"}</Text>
                  <TouchableOpacity
                    style={{ marginLeft: '0.65vw', marginTop: '0.65vw' }}
                    onPress={() => {
                      this.onPressNewMedInOP()
                    }}
                  >
                    <Image
                      source={AddIcon}
                      style={{ height: '1.62vw', width: '1.62vw' }}
                      resizeMode="contain"
                    />

                  </TouchableOpacity>
                </View>
                {medicationInOp && medicationInOp.length > 0 && medicationInOp.map((newEntry, index) => (
                  <View style={styles.commonStyle}>
                    <View>{this.renderTextBoxwithIndex("Route *", "route",  true , index)}</View>
                    <View>{this.renderTextBoxwithIndex("Medicine Name *", "medicine_name",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Dosage *", "dosage",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Timings *", "timings",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Instructions", "instructions",  true, index)}</View>
                    {index == 0 ?
                      <View style={{ width: '1.62vw' }}></View>
                      :
                      <TouchableOpacity
                        onPress={() => this.onPressClearNewMedInOp(index)}
                        style={{ marginTop: '1.3vw', alignSelf: "center" }}>
                        <Image
                          source={CancelIcon}
                          style={{ height: '1.62vw', width: '1.62vw' }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    }
                  </View>
                ))}
              </View> 
              {/* : null} */}
            {this.renderTextBox("Instructions", this.state.instructions, "instructions", this.state.isOpthamalogyClinic ? true : false)}
            {this.renderTextBox("Laboratory", this.state.laboratory, "laboratory", this.state.isOpthamalogyClinic ? false : true)}
            </View>
            : 
            <View>
            {this.renderTextBox("Reason for admission *", this.state.reasonForAdmit, "reasonForAdmit", true)}
            {this.diagnosisSelection()}
            {this.renderTextBox("History Of Present Illness", this.state.historyOfPresentIllness, "historyOfPresentIllness", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("History Of Past Illness", this.state.pastHistory, "pastHistory", true)}
            {this.renderTextBox("Obstetric History", this.state.obstetric_history, "obstetric_history", true)}
            {this.renderTextBox("Family History", this.state.familyHistory, "familyHistory", true)}
            {this.renderTextBox("On Examination", this.state.onExamination, "onExamination", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("Local Examination", this.state.localExamination, "localExamination", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("Investigation", this.state.investigation, "investigation", true)}
            {this.renderTextBox("Surgery", this.state.surgery, "surgery", true)}
            {this.renderTextBox("Procedure", this.state.procedure, "procedure", true)}
            {this.renderTextBox("Course in hospital", this.state.courseInHospital, "courseInHospital", this.state.isOpthamalogyClinic ? false : true)}
            {this.renderTextBox("Condition on discharge", this.state.conditionOnDischarge, "conditionOnDischarge", true)}
            {this.renderTextBox("Treatment Given", this.state.treatmentGiven, "treatmentGiven", this.state.isOpthamalogyClinic ? false : true)}
            <View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontSize: '0.91vw', marginTop: '0.97vw', marginLeft: '1vw' }}>Discharge Medication</Text>
                  <TouchableOpacity
                    style={{ marginLeft: '0.65vw', marginTop: '0.65vw' }}
                    onPress={() => {
                      this.onPressNewMedInOP()
                    }}
                  >
                    <Image
                      source={AddIcon}
                      style={{ height: '1.62vw', width: '1.62vw' }}
                      resizeMode="contain"
                    />

                  </TouchableOpacity>
                </View>
                {medicationInOp && medicationInOp.length > 0 && medicationInOp.map((newEntry, index) => (
                  <View style={styles.commonStyle}>
                    <View>{this.renderTextBoxwithIndex("Route *", "route",  true , index)}</View>
                    <View>{this.renderTextBoxwithIndex("Medicine Name *", "medicine_name",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Dosage *", "dosage",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Timings *", "timings",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Instructions", "instructions",  true, index)}</View>
                    {index == 0 ?
                      <View style={{ width: '1.62vw' }}></View>
                      :
                      <TouchableOpacity
                        onPress={() => this.onPressClearNewMedInOp(index)}
                        style={{ marginTop: '1.3vw', alignSelf: "center" }}>
                        <Image
                          source={CancelIcon}
                          style={{ height: '1.62vw', width: '1.62vw' }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    }
                  </View>
                ))}
              </View> 
            {this.renderTextBox("Advise on discharge *", this.state.adviseOnDischarge, "adviseOnDischarge", true)}
            {this.renderTextBox("Others", this.state.others, "others", true)}
            {this.renderTextBox("Remarks",this.state.operationNotes, "operationNotes", true)}
            </View>}
            {(this.state.isOpthamalogyClinic === false && this.state.FO_admin_setting &&this.state.FO_admin_setting.allow_lab_result_config) ? this.renderLabReports() : null}
            
            <View style={{ marginLeft: '0.32vw', marginTop: '1.3vw', flexDirection: "row" }}>
              {this.renderDatePicker("Follow-up Date", "reviewOn", this.state.reviewOn)}
              {this.renderTextBox("Consultant Name", this.state.admittingConsultant, "admittingConsultant", true)}
            </View>

            <View style={{ backgroundColor: '#DCDCDC', marginTop: '1.3vw' }}>
              <Text style={{ backgroundColor: '#DCDCDC', textAlign: "left", lineHeight: '1.62vw', marginLeft: '2.6vw', fontSize: '0.91vw' }}>Treating Consultant's Name</Text>
            </View>

            <View style={{ flex: 1, flexDirection: "row", marginTop: '1.3vw', marginBottom: '1.3vw' }}>
              {this.renderTreatingConsaltantName("", this.state.treatingConsoltantName1, "treatingConsoltantName1")}
              {this.renderTreatingConsaltantName("", this.state.treatingConsoltantName2, "treatingConsoltantName2")}
              {this.renderTreatingConsaltantName("", this.state.treatingConsoltantName3, "treatingConsoltantName3")}
              {this.renderTreatingConsaltantName("", this.state.treatingConsoltantName4, "treatingConsoltantName4")}
            </View>
            <View style={{ marginTop: '1.3vw', marginLeft: '1.3vw', flexDirection: "row", justifyContent: "center" }}>
              <View style={{ marginRight: '2.6vw' }}>


                <TouchableOpacity
                  disabled={this.state.isIpPatientCheckouted || this.state.is_finished ? true : false}
                  // onPress={() => this.saveAdmissionButton()}
                  onPress={() => this.onSaveButton("saveButton")}
                  style={Style.ipcancelButton} >
                  <Icon name={"save"} size={Platform === "web" ? '0.97vw' : '1.3vw'} color={color.white} />
                  <Text style={[Style.certificatetext, { marginLeft: '0.65vw', fontSize: '0.91vw' }]}>{"Save"}</Text>
                </TouchableOpacity>
              </View>
              <View style={{ marginRight: '1.95vw' }}>

                <TouchableOpacity
                  disabled={this.state.isIpPatientCheckouted || this.state.is_finished ? true : false}
                  onPress={() => this.onSaveButton("finishButton")}
                  style={Style.ipcancelButton} >
                  <Icon name={"flag-o"} size={Platform === "web" ? '0.97vw' : '1.3vw'} color={color.white} />
                  <Text style={[Style.certificatetext, { marginLeft: '0.65vw', fontSize: '0.91vw' }]}>{"Finish"}</Text>
                </TouchableOpacity>


              </View>
              <View style={{ marginLeft: '0.65vw' }}>


                <TouchableOpacity
                  onPress={() => this.onClearButton()}
                  disabled={this.state.isIpPatientCheckouted}
                  style={Style.ipAdmissonCancelButton} >
                  <Cancel name={"cross"} size={Platform === "web" ? '0.97vw' : '1.3vw'} color={color.themeDark} />
                  <Text style={[Style.certificatetext, { marginLeft: '0.65vw', color: color.themeDark, fontSize: '0.91vw' }]}>{"Clear"}</Text>
                </TouchableOpacity>
              </View>

            </View>


            <View style={{ backgroundColor: '#DCDCDC', marginTop: '1.3vw' }}>
              <Text style={{ backgroundColor: '#DCDCDC', textAlign: "left", lineHeight: '1.62vw', marginLeft: '2.6vw', fontSize: '0.91vw' }}>Previous Discharge Summary</Text>
            </View>

            {
              this.state.ip_discharge_summary.length > 0 ?
                <View style={{ marginTop: '1.3vw', borderTopColor: "gray", alignItems: "center", borderBottomColor: "gray", flexDirection: "row", borderTopWidth: 1, borderBottomWidth: 1 }}>
                  <View style={{ flex: 0.5, alignItems: "center" }}>
                    <Text style={{ marginTop: '0.32vw', marginBottom: '0.32vw', fontSize: '0.91vw' }}>Date</Text>
                  </View>
                  <View style={{ flex: 0.5, alignItems: "center" }}>
                    <Text style={{ marginTop: '0.32vw', marginBottom: '0.32vw', fontSize: '0.91vw' }}>Action</Text>
                  </View>
                </View>
                :
                <View style={{ marginTop: '1.3vw', alignItems: "center" }}>
                  <Text style={{ marginTop: '0.32vw', marginBottom: '0.32vw', color: "#888888", fontSize: '0.91vw' }}>{"No records found"}</Text>
                </View>
            }

            {this.renderdischargesummartyType()}

          </ScrollView>
          { this.state.isPrintClick ?
         <View style={styles.popupMain}>
          <View style={styles.popupSubContainer}>
             <View style={styles.popupSubContainer1}>
                 <View style={styles.printConfigMain}>
                    <Text style = {styles.PopUpHeaderText}>{"Print Configuration"}</Text>
                    <View style = {{ flexDirection: "row", marginTop: "2vw", flex: 1, justifyContent: "space-between" }}>
                    <View style = {{ flexDirection: "row", justifyContent: "space-between", flex: 0.45 }}>
                        <Text style = {{ fontSize: "1vw", fontWeight: "500" }}>Header : </Text>
                        <Radio.Group style={{ marginBottom: "0.3vw" }}
                          onChange={(e) => {
                            this.setState({ printHeader: e.target.value });
                          }}
                          value={this.state.printHeader}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </View>
                      <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 0.55 }}>
                      <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Orientation : </Text>
                      <Picker
                        selectedValue={this.state.printOrientation}
                        style={[styles.pickerView,
                        {
                          borderColor: color.grayShade, marginLeft: "1vw"
                        }, Style.allButtonBorderRadius]}
                        itemStyle={{ fontSize: "1vw", fontWeight: "500", color: color.black}}
                        onValueChange={(itemvalue) => {
                          this.setState({ printOrientation: itemvalue });
                        }}
                      >
                        <Picker.Item label="Select Orientation" value="" />
                        <Picker.Item label="A4 Portrait" value="portrait" />
                        <Picker.Item label="A5 Landscape" value="landscape" />
                      </Picker>
                    </View>
                    
                    </View>
                    <View style = {{ flexDirection: "row" }}>
                        <Text style = {{ fontSize: "0.9vw", fontWeight: "500" }}>Footer    :</Text>
                        <Radio.Group style={{ marginLeft: "2vw", marginBottom: "0.3vw" }}
                          onChange={(e) => {
                            this.setState({ printFooter: e.target.value });
                          }}
                          value={this.state.printFooter}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </View>
                      <View style = {{ flexDirection: "row", justifyContent: "center", flex: 1, marginTop: "3vw" }}>
                       <TouchableOpacity
                        onPress={() => {this.setState({ isPrintClick: false, printFooter: false, printHeader: false, printOrientation: "" })}}
                        style={[Style.ipcancelButton, { width: "6vw", height: "2vw"}]} >
                        <Text style={[Style.certificatetext, { fontSize: '0.91vw' }]}>{"Cancel"}</Text>
                       </TouchableOpacity>
                       <TouchableOpacity
                        onPress={() => {this.renderView(this.state.selectedPrintDetails)}}
                        style={[Style.ipcancelButton, { width: "6vw", height: "2vw"}]} >
                        <Text style={[Style.certificatetext, { fontSize: '0.91vw' }]}>{"Print"}</Text>
                       </TouchableOpacity>
                     </View>  
                 </View>
             </View>
          </View>       
          </View> : null}
        </View >
      </View >

    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: '#DCDCDC',
    height: '5.2vw',

  },
  popupMain: { justifyContent: "center", alignItems: "center", height: '100%', width: "93.75vw", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', zIndex: 10 },
  popupSubContainer: { justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: '0.25vw', padding: '1vw', width: "35%", height: "38vh" },
  popupSubContainer1: { backgroundColor: color.white, flex: 1, justifyContent: "space-around", width: "100%", height: "50%", flexWrap: "wrap-reverse", marginTop: "1vw" },
  printConfigMain: { flex: 1 },
  PopUpHeaderText: { fontSize: '1.17vw', fontWeight: "bold", alignSelf: "center" },
  commonStyle: {
    flexDirection: "row",
    flex: 1,
    marginBottom: '1.3vw',
    justifyContent: "space-between",
    width: "95%"
  },
  commonStyles: {
    width: "13vw"
  },
  commonTextBox: {

  },
  diagnosisSelectMainView: {
    width: "94.5%",
    height: '16vw',
    borderWidth: 1,
    borderRadius: '0.26vw',
    borderColor: "#00CED1",
    marginTop: '1.95vw',
    zIndex: -1,
    marginLeft: '0.32vw'
  },
  pickerView: {
    backgroundColor: color.white,
    height: '2vw',
    fontSize: ".85vw",
    paddingLeft: ".3vw",
    marginRight: "1vw",
    borderColor: "#CDD1D5",
    borderRadius: '0.26vw',
    borderWidth: '0.065vw',
  },
  DiagnosMsgText: {
    fontSize: '0.7vw',
    marginLeft: '14vw',
    color: color.red
  },
  DiagnosSuggListBtn: {
    marginTop: '.5vw', paddingLeft: '.8vw'
  },
  DiagSuggListScroll: {
    position: 'absolute', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", marginTop: "2.3vw", maxHeight: '17vw', width: '30vw', borderRadius: '0.26vw'
  },
  DiagListText: {
    fontSize: '0.9vw', margin: '0.3vw'
  },
  DiagListHeding: {
    color: color.themeDark,
    fontSize: '0.9vw',
    fontWeight: '500',
    marginLeft: '1.5vw',
    marginTop: '0.3vw'
  },
  DiagErrText: {
    fontSize: '1vw',
    fontWeight: '500',
    width: '100%',
    marginTop: '0.3vw',
    marginLeft: '10vw',
    color: color.red,
    height: '2vw'
  }
});

``