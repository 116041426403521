import React,{Component} from 'react';
import {View,Text,StyleSheet,FlatList} from 'react-native';
import {OBGYNHistorySectionHeading,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "natureOfDelivery"


export class NatureOfDelivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofNatureOfDelivery: {},

            hideEditIcon:false,
            isheading:false
        }
    }

    componentDidMount() {
        this.getNatureOfDelivery()
    }

    getNatureOfDelivery(){
        var service_url = Constants.PSY_NATURE_DELIVERY + "?search_key=value&patient_id=" + this.state.patientAppointment.patient_id+
        "&appointment_id="+this.state.patientAppointment.appointment_id;
        
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getNatureOfDeliverySuccess,
            this.getNatureOfDeliveryFailure
        );
    }

    getNatureOfDeliverySuccess = success =>{

        console.log("success - - - - ----- -- - > "+JSON.stringify(success))
        if(success.status == "success"){
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "jaundice" ) {
                        prepareData = {
                            key: "Jaundice",
                            value: data[key] ? "Yes" : "No",
                            details: data.jaundice_detail
                        }
                    }else if (key == "congenital_anomalies" ) {
                        prepareData = {
                            key: "Congenital Anomalies",
                            value: data[key] ? "Yes" : "No",
                            details: data.congenital_anomalies_detail
                        }
                    }
                    booleanValues.push(prepareData)
                }else if (key !== "jaundice_detail" || key !== "congenital_anomalies_detail") {
                    let prepareData = {};
                    if (key == "nature_of_delivery_place" && data[key]) {
                        prepareData = {
                            key: "Place of Delivery",
                            value: data[key],
                        }
                    }else if (key == "nature_of_delivery_term" && data[key]) {
                        prepareData = {
                            key: "Gestational Age",
                            value: data[key] ,
                        }
                    }else if (key == "nature_of_delivery_type" && data[key]) {
                        prepareData = {
                            key: "Type of Delivery",
                            value: data[key] ,
                        }
                    }
                    else if (key == "birth_weight" && data[key]) {
                        prepareData = {
                            key: "Birth Weight",
                            value: data[key] +" Kgs",
                        }
                    }else if (key == "apgar_score" && data[key]) {
                        prepareData = {
                            key: "APGAR Score",
                            value: data[key] ,
                            details: data.apgar_score_details
                        }
                    }else if (key == "first_two_weeks" && data[key]) {
                        prepareData = {
                            key: "First two weeks",
                            value: data[key] ,
                        }
                    }
                    else if (key == "others" && data[key]) {
                        prepareData = {
                            key: "Others",
                            value: data[key] ,
                        }
                    }

                    stringVaues.push(prepareData)
                }else{

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues,...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofNatureOfDelivery: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getNatureOfDeliveryFailure = error =>{}

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getNatureOfDelivery();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name){
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofNatureOfDelivery, pageName)
        }, 100);
        
    }

    renderHeader(){
        return(
            <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Nature Of Delivery"}
                    editImage={this.state.hideEditIcon || Object.keys(this.state.getListofNatureOfDelivery).length == 0 ? "" :"pencil"}
                />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if(item.value){
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        }else{
            return (<View></View>)
        }
        
    }
    

    render(){
        return(
            <View style={styles.container}>
            {this.renderHeader()}

            {this.renderContent()}
        </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
