export default class ImagePath {
  //BaseComponent
  static Logo = require("../../assets/emedhub_logo.png");
  static LogoWithText = require("../../assets/images/QMS_Images/Emedhub_logo.png");
  static BackArrow = require("../../assets/images/SidemenuIcons/Back.png");
  static Close = require("../../assets/images/Close.png");
  static UnlockedDoctorNotes = require("../../assets/images/HomeScreenImages/Unlocked_Doctor_Notes.png");
  static AppointmentRequest = require("../../assets/images/HomeScreenImages/ApppointmentRequests.png");
  static PrintActive = require("../../assets/images/Active/Print.png");

  static Print = require("../../assets/images/HomeScreenImages/Print.png");
  static PrintIcon = require("../../assets/images/Printicon.png");
  static TransferIcon = require("../../assets/images/transfer_icon.svg");

  static BIRegistry = require("../../assets/images/HomeScreenImages/BI__Registry_Icon.png");
  static Export = require("../../assets/images/HomeScreenImages/Export.png");
  static TestConfig = require("../../assets/images/HomeScreenImages/Testconfig.png");
  static SavedBill = require("../../assets/images/HomeScreenImages/saved_bill.png");
  static PlusIcon = require("../../assets/images/PlusIcon.png");
  static TransmittedOrders = require("../../assets/images/HomeScreenImages/Transmitted_Orders.png");
  static DefaultProfile = require("../../assets/images/sample_profile.png");
  static Search = require("../../assets/images/HomeScreenImages/Search.png");
  static Camera = require("../../assets/images/HomeScreenImages/Camera.png");
  static ViewProfile = require("../../assets/images/HomeScreenImages/View_Profile.png");
  static Logout = require("../../assets/images/HomeScreenImages/Log_out.png");
  static Down = require("../../assets/images/HomeScreenImages/Down.png");
  static ReferredDocConfig = require("../../assets/images/HomeScreenImages/Lab-Referred-Doc.png");

  static Edit = require('../../assets/images/BillConfig/Edit.svg');
  static Delete = require('../../assets/images/BillConfig/delete.svg');
  static AddIcon = require('../../assets/images/BillConfig/Add.svg');
  static CancelIcon = require('../../assets/images/BillConfig/Cancel.svg');
  static DropDownIcon = require('../../assets/images/BillConfig/Down_Arrow.svg');

  static Reschedule = require("../../assets/images/HomeScreenImages/Reschedule.png");
  static HistoryIcon = require("../../assets/images/HomeScreenImages/History.png");
  static MoveToIP = require("../../assets/images/HomeScreenImages/In Patients.png");
  static Flag = require("../../assets/images/HomeScreenImages/flag.png");
  static close = require("../../assets/images/HomeScreenImages/Vector.png");
  //SignIn
  static LoginPageImage = require("../../assets/images/loginPageImage.svg");

  //Home
  static Message = require("../../assets/images/SidemenuIcons/Message.png");
  static Arrived = require("../../assets/images/statusIcon/NewStatusIcons/Arrived.png");
  static Speaker = require("../../assets/images/statusIcon/NewStatusIcons/LED_Sound.png");
  static Optometry = require("../../assets/images/statusIcon/NewStatusIcons/Optometry.png");
  static WithDoctor = require("../../assets/images/statusIcon/NewStatusIcons/With_Doctor.png");
  static ScanOrSurgery = require("../../assets/images/statusIcon/NewStatusIcons/Scan_or_Surgery.png");
  static Completed = require("../../assets/images/statusIcon/NewStatusIcons/Completed.png");
  static CreateBill = require("../../assets/images/statusIcon/icon/createBill.svg");
  static WithNurse = require("../../assets/images/statusIcon/icon/Nurse_female.png");

  // static PrintIcon = require("../../assets/images/statusIcon/icon/print.svg");  same Image available in PNG format

  static NextAppointment = require("../../assets/images/statusIcon/icon/nextAppointment.svg");
  static ShiftCounter1 = require("../../assets/images/statusIcon/icon/Shift_Counter_1.png");
  static Remove = require("../../assets/images/remove.svg");
  static CalendarIcon = require("../../assets/images/calendar_icon.png");

  //Side Menu
  static Home = require("../../assets/images/SidemenuIcons/home.png");
  static Invoice = require("../../assets/images/SidemenuIcons/invoice.png");
  static IP = require("../../assets/images/SidemenuIcons/IP.png");
  static DoctorNotes = require("../../assets/images/SidemenuIcons/doctorNotes.png");
  static Demogra = require("../../assets/images/SidemenuIcons/demogra.png");
  static Report = require("../../assets/images/SidemenuIcons/Report.png");
  static History = require("../../assets/images/SidemenuIcons/history.png");
  static PsychiatryHistory = require("../../assets/images/SidemenuIcons/PsychiatryHistory.png");
  static PaediatricMentalHealth = require("../../assets/images/SidemenuIcons/PaediatricMentalHealth.png");
  static SubstanceAbuse1 = require("../../assets/images/SidemenuIcons/SubstanceAbuse1.png");
  static Ratingscale = require("../../assets/images/SidemenuIcons/Ratingscale.png");
  static OBGYNHistory = require("../../assets/images/SidemenuIcons/OBGYNHistory.png");
  static OvulationChart = require("../../assets/images/SidemenuIcons/OvulationChart.png");
  static ObgynChart = require("../../assets/images/SidemenuIcons/ObgynChart.png");
  static DevelopmentalAssessment = require("../../assets/images/SidemenuIcons/Developmental_Assessment.svg");
  static Immunization = require("../../assets/images/SidemenuIcons/Immunization.png");
  static GrowthChart = require("../../assets/images/SidemenuIcons/Growth Chart.png");
  static OTlist = require("../../assets/images/SidemenuIcons/OTlist.png");
  static QuickBilling = require("../../assets/images/SidemenuIcons/Quickbilling.svg")
  static Configuration = require("../../assets/images/BillConfig/Configuration.svg")
  static StockUsage = require("../../assets/images/Active/Stock.svg")
  static Inventory = require("../../assets/images/Active/Inventory.png")
  //UI/Doctor/common/Test reports
  static Group1556 = require("../../assets/images/Reports/Group 1556.png");

  //All Transaction

  static Total = require("../../assets/images/IPActions/Total.png");
  static Card = require("../../assets/images/IPActions/Card.png");
  static Cash = require("../../assets/images/IPActions/Cash.png");
  static UPI = require("../../assets/images/IPActions/upi.png");
  static BankTransfer = require("../../assets/images/IPActions/bank-transfer.png");
  static Insurance = require("../../assets/images/IPActions/Insurance.png");
  static Cheque = require("../../assets/cheque.png");
  static CashandCard = require("../../assets/images/IPActions/Cash&Card .png")// need to rename image-name and remove space in orginal file once replcaed in all occurence

  static Vendor = require("../../assets/images/Active/Vendor.png")
  static Reports = require("./../../assets/images/Active/Report.svg")
  static Setting = require("./../../assets/images/Active/Settings.svg")
  static Pharmacy = require("./../../assets/images/Active/Pharmacy.svg")
  static Update = require('../../assets/images/Active/Update.svg')
  static Pending = require('./../../assets/images/Active/pending.svg')
  static InTransmit = require('./../../assets/images/Active/inTransmit.svg')
  static Approve = require('./../../assets/images/Active/Approve.svg')
  static EyeIcon = require('./../../assets/images/Active/Eye.svg')
  //Total Patient Icon 

  static InPatient = require("../../assets/images/InPatients.png");
  static OutPatient = require("../../assets/images/OutPatients.png");
  static TotalPaitent = require("../../assets/images/TotalPatients.png");
  static DischargedPatient = require("../../assets/images/discharged.png");
  static OPNew = require("../../assets/images/opnew.png");
  static CancelledOP = require("../../assets/images/cancelled_op.png");
  static TotalNew = require("../../assets/images/totalpatientnew.png");
  static Bill01 = require("../../assets/images/bill01.png");
  static Bill02 = require("../../assets/images/bill02.png");
  static Bill03 = require("../../assets/images/bill03.png");
  static Bill04 = require("../../assets/images/bill04.png");
  static Bill05 = require("../../assets/images/bill05.png");
  static Bill06 = require("../../assets/images/bill06.png");


  static NutritionChart = require("../../assets/images/Nutrition Chart/Nutrition chart.png");


  //Foot Clinic
  static UpperBody = require("../../assets/images/FootClinic/upperbody.png");
  static LowerBody = require("../../assets/images/FootClinic/lowerbody.png");
  static Foot = require("../../assets/images/FootClinic/foot.png");

  // QMS
  static cancel = require("./../../assets/images/Active/cancel.svg")
  static call = require("./../../assets/images/Active/call.svg")
  static transferApp = require("./../../assets/images/Active/TransferApp.svg")
  static DownArrowIcon = require("./../../assets/images/Active/DownArrowIcon.svg")
  static UpArrowIcon = require("./../../assets/images/Active/UpArrowIcon.svg")
}
