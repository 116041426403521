import React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";

import BaseComponent, {
  CommonHistorySectionHeader,
  CommonSectionHeader,
} from "../BaseComponent";
import { color } from "../../../styles/Color";
// import Styles  from "../../../styles/Style";
import Style from "../../../styles/Style";
import { Constants } from "../../../utils/Constants";
import AsyncStorage from "../../../AsyncStorage";
import AWSFileUpload from "../../../utils/awsfileupload";

const pageTitle = "Patient Documents";
const pageName = "PatientDocuments";
const isPageName = "isPatientDocuments";

class PatientDocuments extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      userType: "",
      advice: "",

      getGPAdviceData: [],

      isGeneralPatientAdvice:
        isPageName === this.props.selectedView ? true : false,
      patientAppointment: this.props.patientAppointment,
      //isLocked:this.props.patientAppointment.is_locked,
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <View style={{height: 200}}>
        <View style={styles.CommonHeaderView}>
          <AWSFileUpload patient={this.props.patientAppointment} showResposeValue={this.props.showResposeValue.bind(this)} />
        </View>
      </View>
    );
  }
}
export default PatientDocuments;

const styles = StyleSheet.create({
  SaveBtnTouch: {
    marginLeft: "1.2vw",
    paddingTop: "0.5vw",
    paddingLeft: "1.1vw",
    paddingRight: "1.1vw",
    paddingBottom: "0.5vw",
  },
  CommonHeaderView: {
    marginTop: "1vw",
    zIndex: -6,
    marginBottom: "0.6vw",
  },
  SectionHeaderTextInput: {
    paddingTop: "0.6vw",
    paddingLeft: "0.6vw",
    height: "5.5vw",
    borderColor: "#D9D9D9",
    borderWidth: 1,
    borderRadius: 5,
    fontSize: "0.9vw",
  },
});
