//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput,Picker } from 'react-native';
import { HistoryYesOrNoSection, CommonHistorySectionHeader, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
// create a component
const pageName = "isSocialHistory"
var Messages = require('../../../../utils/InfoMessages')
const smockingList = [
    { label: "Occasionally", value: "Occasionally" },
    { label: "1-2 cigarettes per day", value: "1-2 cigarettes per day" },
    { label: "1 pack per day", value: "1 pack per day" },

]

const dailyActivityList=[
    { label: "Sitting most of the day", value: "Sitting most of the day" },
    { label:"Some activity daily", value:"Some activity daily"},
    { label:"Very active daily", value:"Very active daily"}
]

const  alchoholList=[
    { label: "Occasionally", value: "Occasionally" },
    { label: "Social Only", value: "social only"},
    { label: "Frequently", value: "Frequently" },

]

const yesOrNO = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
]

const drugsList=[
    { label: "Smokes Marijuana", value: "Smokes Marijuana" },
    { label: "Cocaine", value: "Cocaine" },
    { label: "LSD", value: "LSD" },
    { label: "Intravenous Drugs", value: "Intravenous Drugs" }
]

export class SocialHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            newButtonValue: "",
            id:"",
            // selectedProbloms: {},
            // problomsList: problomsList,
            // showProblomsNewButtonFlag: true,

            selectedAlchohol:{},
            alchoholList:alchoholList,
            // showSurgeriesTypeNewButtonFlag:true,

            smockingList:smockingList,
            selectedSmocking:{},

            dailyActivityList:dailyActivityList,
            selectedDilyActivity:{},


            selectedMarried:{},
            selectedeExcerciesDaily:{},

            selectedDrugs: [],
            drugsList: drugsList,
            showDrugsNewButtonFlag: true,

            commentTextBoxValue: "",
            selectedYear:"",
            occupation:""
        }
    }

    componentWillReceiveProps(props){
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
              //  console.log("data===========> "+JSON.stringify(data))
                var states = this.state;

                states["selectedAlchohol"] = {label:data.alcohol ,value:data.alcohol};
                states["id"] = data.id;
                states["selectedSmocking"] = {label:data.smoking,value:data.smoking};
                states["selectedDilyActivity"] = {label:data.daily_activity,value:data.daily_activity};
              states["selectedMarried"]=data.is_married ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" };
              states["selectedeExcerciesDaily"]=data.is_exercise_done_daily ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" };

              states["occupation"]=data.occupation
              
              var getPreareArrayListOfSelectedDrug=[]
              if(data.drugs){

              
                var getDrugList = data?.drugs[0]?.split(',') // it will split the selected drug(string to ) as a  array 
            //    alert("getDrugList =========== > "+JSON.stringify(getDrugList))
                if(getDrugList?.length >0 ){
                    for (let i = 0; i < getDrugList?.length; i++) {
                        var preparData={
                            label:getDrugList[i],value:getDrugList[i]
                        }
                        getPreareArrayListOfSelectedDrug?.push(preparData)
                        
                    }

                }
               
            }
            console.log(getPreareArrayListOfSelectedDrug,"iu789uyig89uigyft8uyigyft89uihkj");
                states["selectedDrugs"] = getPreareArrayListOfSelectedDrug

                this.setState({states },()=>{
                    this.props.editHistory({},pageName)
                })

            }
        }
    }


    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "showDrugsNewButtonFlag") {
            states[key] = false;
        }else if(key =="selectedDrugs"){

            var { selectedDrugs } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedDrugs.length > 0) {
                for (var i = 0; i < selectedDrugs.length; i++) {
                    if (selectedDrugs[i].value == selectedValue.value) {
                        spliceData = selectedDrugs[i];
                        selectedDrugs.splice(i, 1)
                        var data = this.removeDuplicate(selectedDrugs);
                        this.setState({ selectedDrugs: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedDrugs.push(selectedValue); }
            } else {
                selectedDrugs.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedDrugs);
            this.setState({ selectedDrugs: data })

        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var { drugsList } = this.state;
        var states = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }


        if (!this.state.showDrugsNewButtonFlag) {
            drugsList.push(preparData);
            drugsList = this.removeDuplicate(drugsList);
            states["newButtonValue"] = "";
            states["showDrugsNewButtonFlag"] = true;
        }
        this.setState({
            drugsList, states
        })

    }


    richTextBox() {
        return (
            <View>
                <TextInput
                    multiline={true}
                    style={{paddingTop:10,paddingLeft:10,width:"85%",alignSelf:'center',marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, height: 60 }}
                    value={this.state.occupation}
                    onChangeText={(text) => {
                        this.setState({
                            occupation: text
                        })
                    }}
                />
            </View>
        )
    }


    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        // console.log(JSON.stringify(selectedValue))
        var data = this.removeDuplicate(setOfData)

        return (
            <View style={[{ marginTop: 10, paddingLeft: 20, }]}>

                {/* <View>
                    <CommonHistorySectionHeader heading1={question} noOfColumn={1} />
                </View> */}

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                    {
                        data.map((item, index) => {
                            if(item.value&&item.label){
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                            }
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}

                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ paddingLeft: 15, width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    clearAllData() { 
        var states=this.state
        states["id"]=""
        states["selectedAlchohol"]={}
        states["selectedSmocking"]={}
        states["selectedDilyActivity"]={}
        states["selectedMarried"]={}
        states["selectedDrugs"]=[]
        states["selectedeExcerciesDaily"]={}
        states["occupation"]=""
        this.setState({
            states
        })
        
    }

    onPressAddButton() { 
        
        //   Drugs Array method 
          var states = this.state;
          var getDrugs = [];
          if(states.selectedDrugs.length > 0){
              for (let i = 0; i < states.selectedDrugs.length; i++) {
                  getDrugs.push(states.selectedDrugs[i].value) 
              }
          }
        //   console.log(
        //     "getDrugs --- > " +
        //       JSON.stringify(states.selectedDrugs)
        //   );
          var data = {
            patient_id:this.state.patientAppointment.patient_id,
            appointment_id:this.state.patientAppointment.appointment_id,
            alcohol: this.state.selectedAlchohol.value,
            smoking: this.state.selectedSmocking.value,
            drugs: getDrugs.toString() ? [getDrugs.toString()] : [],
            daily_activity: this.state.selectedDilyActivity.value,
            is_married: this.state.selectedMarried.value == 'Yes' ? true : false,
            is_exercise_done_daily:this.state.selectedeExcerciesDaily.value == 'Yes' ? true : false,
            occupation: this.state.occupation,
            
            // comments: "Normal"
          };

          if(this.state.id){
              data["id"]=this.state.id
          }
          if(Object.keys(this.state.selectedAlchohol).length==0 && Object.keys(this.state.selectedSmocking).length==0 &&
             Object.keys(this.state.selectedDilyActivity).length==0 && Object.keys(this.state.selectedMarried).length==0 && 
             Object.keys(this.state.selectedeExcerciesDaily)==0 && this.state.occupation.length==0){
                this.props.showResposeValue("error",Messages.HistorySectionValitation)

          }else {
          var service_url = Constants.POST_SOCIAL_HISTORY;
          OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.socialHistorySaveSuccess,
            this.socialHistorySaveFailure
          );
        }
    }
    socialHistorySaveSuccess = (success) => {
        // alert(JSON.stringify(success));
        if(success.status == "success"){
          this.props.showResposeValue("success", success.message)
          this.props.refreshRighSideComponent(pageName);
          this.clearAllData()
        }else{
          this.props.showResposeValue("error", success.message)
        }
      };
      socialHistorySaveFailure = (error) => {
        //alert(JSON.stringify(error));
        this.props.showResposeValue("error", error.message)
      };
      

    render() {
        return (
            <View style={styles.container}>


                <DoctorNotesCommonRightSideHeader title={"Social History"} clearAllData={this.clearAllData.bind(this)} />
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Alcohol"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedAlchohol",
                        this.state.selectedAlchohol,
                        "",
                        true,
                        this.state.alchoholList)}
                </View>


                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Smoking"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedSmocking",
                        this.state.selectedSmocking,
                        "",
                        true,
                        this.state.smockingList)}
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Drug"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedDrugs",
                        this.state.selectedDrugs,
                        "showDrugsNewButtonFlag",
                        this.state.showDrugsNewButtonFlag,
                        this.state.drugsList)}
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Rate your daily activity"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedDilyActivity",
                        this.state.selectedDilyActivity,
                        "",
                        true,
                        this.state.dailyActivityList)}
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Married"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedMarried",
                        this.state.selectedMarried,
                        "",
                        true,
                        yesOrNO)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Do you exercise daily?"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedeExcerciesDaily",
                        this.state.selectedeExcerciesDaily,
                        "",
                        true,
                        yesOrNO)}
                </View>

                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Occupation"} noOfColumn={1} />
                    {this.richTextBox()}
                </View>
                <View style={{width:100,alignSelf:"center"}}>
                 <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                </View> 
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
    },
});

// //make this component available to the app
//  default SocialHistory;
