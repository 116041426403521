import React, { Component } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import { CommonHistorySectionHeader } from '../../../../BaseComponent';
import OpthamologyService from '../../../../../../network/OpthamologyService';
import { Constants } from '../../../../../../utils/Constants';
import { color } from '../../../../../../styles/Color';
import Pagination from '../../../../../doctor/common/Pagination';
import ImagePath from '../../../../../../utils/ImagePaths';
export class PurchaseIndentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indentNumber: this.props.indentNumber,
            current_page: 1,
            records_per_page: 10,
            drugListAfterPagination: [],
            durgList: []

        };
    }
    componentDidMount() {
        this.getIndentDetails()
    }
    getIndentDetails = () => {
        this.setState({
            current_page: 1,
            records_per_page: 10,
            drugListAfterPagination: [],
            durgList: []
        })
        var indentNumber = parseInt(this.state.indentNumber)
        var url = Constants.OT_PURCHASE_INDENT_POST + "?indent_number=" + indentNumber
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIndentDetailsSuccess,
            this.getIndentDetailsFailure
        );
    }
    getIndentDetailsSuccess = response => {
        if (response && response.status == "Success") {
            this.setState({
                durgList: response.data
            })
        }
    }
    getIndentDetailsFailure = error => {
    }
    renderHeader = () => {
        return (
            <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Generic Name"}
                heading4={"Dosage Type"}
                heading5={"Dosage Strength"}
                heading6={"Quantity"}
                columnSize={[0.05, 0.2, 0.2, 0.2, 0.2, 0.15]}  //total value is == of 1
                noOfColumn={6}
            />
        )
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    renderPaginationView() {
        return (
            <View style={styles.paginationContainer}>
                {
                    this.state.durgList && this.state.durgList.length > 0 ?
                        <Pagination
                            paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalItems={this.state.durgList}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        /> : null
                }
            </View>
        )
    }
    paginationChangedValues(data) {
        this.setState({
            drugListAfterPagination: data
        })
    }
    renderDrugList = () => {
        return (
            <View>
                {
                    this.state.drugListAfterPagination && this.state.drugListAfterPagination.map((item, index) => {
                        let sno = (parseInt(this.state.current_page) - 1) * parseInt(this.state.records_per_page) + (index + 1)

                        return (
                            <View style={styles.dataContainer}>
                                <View style={{ flex: 0.05, marginHorizontal: "1.5vw" }}><Text style={[styles.TableDataText, {}]}>{sno}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.brand_name}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.generic_name}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.dosage_type}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.dosage_strength}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.quantity}</Text></View>
                            </View>
                        )
                    })}
            </View>
        )
    }
    renderBackButton = () => {
        return(
            <TouchableOpacity style={styles.addVendor} onPress={() => { this.props.renderSelectedTab("drugList") }}>
              <View style={styles.subContainerView}>
                <Image source={ImagePath.BackArrow} style={styles.Header_BackIcon} />
                <Text style={styles.addVendorText}>Back</Text>
              </View>
            </TouchableOpacity>
        )
    }
    render() {
        return (
            <View style={styles.container}>
                {this.renderBackButton()}
                {this.renderHeader()}
                {this.renderDrugList()}
                {this.renderPaginationView()}
            </View>
        );
    }
}
const styles = StyleSheet.create({
    container: {
        margin: "0.5vw",
        // height: screenHeight-68
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "1vw",
        justifyContent: 'center',
        flex: 1
    },
    addVendor: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        padding: "0.3vw",
        width: "5%",
        marginVertical: "0.5vw"
      },
    addVendorText: {
        color: color.white,
        fontSize: "1vw"
    },
    Header_BackIcon: {
        height: "1.5vw",
        width: "1.5vw",
    },
    subContainerView: { flexDirection: "row" },
    TableDataText:{
       fontSize:'1vw'
    }

})    
