//import liraries
import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, Picker } from 'react-native';
import Pagination from '../../doctor/common/Pagination';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { message, Tooltip } from 'antd';
const screenHeight = Dimensions.get("window").height;
import moment from "moment";
import Icon from "react-native-vector-icons/AntDesign";
import Icons from "react-native-vector-icons/FontAwesome";

import Style from "../../../styles/Style";
import { color } from '../../../styles/Color'
import { CommonButton, EditiAndDeleteForLineItem, CommonHistorySectionHeader, CommonAddButton, } from '../BaseComponent'

import { Calender } from '../common/calender';
// create a component

let defaultNewEntry = {
    "name": "",
    // "normal_range": "12",
    "unit": "",
    "group_test": "",
    "amount": '',
    "normal_range": "",
    'remarks': ''
};

export class TestConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfLabSets: [
            ],

            listOfTestName: [
            ],
            labSetName: "",
            labSetAmount: "",
            notes: "",
            showListOfNewEntrys: [
                defaultNewEntry
            ],
            deletedTestIds: [],
            labSetDisableFlag:false,
            textinput: [],

            inputData: [],
            name: [],

            department: "",
            searchTestConfig: ""
        }
    }

    componentDidMount() {
        this.getTestConfigList()
    }

    renderTestConfigHeader() {
        return (
            <View style={{ zIndex: -1, marginTop: 10, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.13, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Lab Set"}</Text></View>
                <View style={{ flex: 0.11 }}><Text style={styles.headerFontStyle}>{"Amount"}</Text></View>
                <View style={{ flex: 0.25 }}><Text style={styles.headerFontStyle}>{"Test Name"}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.headerFontStyle}>{"Normal Range"}</Text></View>
                <View style={{ flex: 0.11 }}><Text style={styles.headerFontStyle}>{"Unit"}</Text></View>
                <View style={{ flex: 0.13, alignItems: "center" }}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
            </View>
        )
    }

    editHistory(labSet, testConfig) {
        var states = this.state;
        const showListOfNewEntrys = this.state.listOfTestName.filter(item => labSet.id == item.lab_set);
        states["showListOfNewEntrys"] = JSON.parse(JSON.stringify(showListOfNewEntrys))
        console.log(states["showListOfNewEntrys"], 'hello')
        states["labSetName"] = labSet.name;
        states["labSetAmount"] = labSet.amount;
        states["department"] = labSet.department
        this.setState({ states,
            labSetDisableFlag: true,
         })
    }
    deleteHistory(item) {

        var service_url = Constants.LAB_TEST_CONFIG_DELETE + item + "/";
        // var data={
        //     "id":item.id
        // }

        OpthamologyService.getInstance().postComplaints(
            service_url,
            {},
            this,
            this.deleteHistorySuccess,
            this.deleteHistoryFailure
        );

    };
    deleteHistorySuccess = (response) => {

        if (response.status === "success") {
            this.getTestConfigList();

            this.props.showResposeValue("success", success.message);
        }
        else {

            this.props.showResposeValue("error", success.message);
        }
    }
    deleteHistoryFailure = error => {


        this.props.showResposeValue("error", message.error);
    };

    groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for given key's value
            acc[key].push(obj);
            return acc;
        }, {});
    }

    renderListOfTest_old(item) {
        const Array = item.map(e => e[1])
        const Group = Array[0].map(e => e)
        return (<View>
            {
                item.map((test) => {
                    var groupedValues = (this.groupBy(test[1], 'group_test'))
                    var groupArrays = Object.entries(groupedValues)
                    return <View style={{ zIndex: -1, margin: 10, flexDirection: 'row', alignItems: 'center' }}>
                        <View >
                            <Text style={styles.headerFontStyle}>{test[0]}</Text>
                        </View>
                        <View style={{ marginHorizontal: 100 }}>
                            {
                                groupArrays.map(values =>
                                    <View style={{ flexDirection: 'column', alignItems: 'center', paddingVertical: 150 }}>
                                        <Text style={{ marginRight: 100 }}> {values[0] !== null ? values[0] : 'N/A'} </Text>
                                        {
                                            values[1].map(item =>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 50 }}>
                                                        <Text >{item.amount ? "₹ " + item.amount : ""}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                        <Text>{item.name}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10, marginLeft: 70 }}>
                                                        <Text>{item.normal_range}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 35, marginLeft: 45 }}>
                                                        <Text>{item.remarks}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
                                                        <Text>{item.unit}</Text>
                                                    </View>
                                                    <View style={{ marginRight: 50 }}>
                                                        {!item.lab_set_id ?
                                                            <View>
                                                                <EditiAndDeleteForLineItem
                                                                    editHistory={this.editHistory.bind(this, item)}
                                                                    deleteHistory={this.deleteHistory.bind(this, item.lab_set)}

                                                                    headerKey={"testConfig"}
                                                                    editImage={"pencil"}
                                                                    deleteImage={"trash"}
                                                                />
                                                            </View>
                                                            : null}
                                                    </View>
                                                </View>
                                            )
                                        }
                                    </View>
                                )
                            }
                        </View>
                    </View>
                })
            }
        </View>)
    }
    renderListOfTest(item) {
        return (
            <View style={{ zIndex: -1, flexDirection: 'row', flex: 1 }}>
                {
                    item.map((test) => {
                        var groupedValues = (this.groupBy(test[1], 'group_test'))
                        var groupArrays = Object.entries(groupedValues)
                        return <View style={{ flexDirection: 'column', flex: 1, paddingTop:10, paddingBottom:10 }}>
                            {
                                groupArrays.map(values =>
                                    <View style={{ flexDirection: 'column', marginTop: 10 }}>
                                        <View style={{ flexDirection: 'row', flex: 1 }}>
                                            <View style={{ flex: 0.24 }}>
                                                <Text >{""}</Text>
                                            </View>
                                            <View style={{ flex: 0.76 }}>
                                                <Text style={styles.headerFontStyle}>{values[0] !== 'null' ? values[0] : 'N/A'}</Text>
                                            </View>
                                        </View>
                                        {
                                            values[1].map(item =>
                                                <View style={{ flexDirection: 'row', flex: 1, marginTop: 10, }}>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.13 }}>
                                                        <Text>{""}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.11 }}>
                                                        <Text >{item.amount ? "₹ " + item.amount : ""}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.25 }}>
                                                        <Text>{item.name}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.15 }}>
                                                        {item.normal_range ? <Tooltip title={item.normal_range}><Text ellipsizeMode="tail" numberOfLines={1} >{item.normal_range}</Text></Tooltip> : null}
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.12 }}>
                                                        {item.remarks ? <Tooltip title={item.remarks}><Text ellipsizeMode="tail" numberOfLines={1}>{item.remarks}</Text></Tooltip> : null}
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.11 }}>
                                                        <Text>{item.unit}</Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.13 }}>
                                                    </View>
                                                </View>
                                            )
                                        }
                                    </View>
                                )
                            }
                        </View>
                    })
                }
            </View>)
    }

    renderListofTestRows(labSet, index) {
        let { listOfLabSets } = this.state;


        return (

            <View>
                {this.renderListOfTest(labSet,everySingleTest)}
                {
                    listOfLabSets && listOfLabSets.length > 0 ?
                        listOfLabSets.map((test, index) => {
                            return renderListOfTest(test)
                        }) : null
                }
            </View>
        )
    }
    renderTestConfigListOfDatas ()  {
        let { listOfTestName } = this.state;
        let { listOfLabSets } = this.state;
        var result = listOfTestName.filter(function (o1) {
            return !listOfLabSets.some(function (o2) {
                return o1.labset == o2.id
            })
        })
        const groupedValues = this.groupBy(result, 'lab_set_name');
        var keyname = Object.entries(groupedValues)
        return (
            <View style={{ flex: 1 }}>
                {
                    listOfLabSets && listOfLabSets.length > 0 ?
                        listOfLabSets.map((item, index) => {
                            let result1 = listOfTestName.filter(function (o1) {
                                return o1.lab_set == item.id
                            })
                            const groupedValues1 = this.groupBy(result1, 'lab_set_name');
                            let keyname1 = Object.entries(groupedValues1)
                            return (
                                <View style={{marginBottom:15,  }}>
                                    <View style={{ flex: 1, flexDirection: 'column'  }}>

                                        <View style={{ height: 50, flexDirection: "row" }}>

                                            <View style={{ flex: 0.13, paddingLeft: 15, flexDirection: 'column' }}>
                                                {item.department ? item.department.length > 15 ?
                                                    < Tooltip placement="top" title={item.department}>
                                                        <Text style={styles.TopheaderFontStyle}>{item.department.slice(0,15)}</Text>
                                                    </Tooltip> : <Text style={styles.TopheaderFontStyle}>{item.department ? item.department : ""}</Text> : null}
                                                    <Text style={styles.headerFontStyle}>{item.name}</Text>
                                            </View>

                                            <View style={{ flex: 0.11 }}><Text style={styles.headerFontStyle}>{"₹ " + item.amount}</Text></View>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.63 }}>
                                                <Text>{""}</Text>
                                            </View>

                                            <View style={{ alignItems: "flex-end", flex: 0.13 }}>
                                                <View>
                                                    <EditiAndDeleteForLineItem
                                                        editHistory={this.editHistory.bind(this, item)}
                                                        deleteHistory={this.deleteHistory.bind(this, item.id)}

                                                        headerKey={"testConfig"}
                                                        editImage={"pencil"}
                                                        deleteImage={"trash"}
                                                    />
                                                </View>
                                            </View>

                                        </View>
      
                                        <View style={{ flex: 0.7, flexDirection: 'row' }}>
                                            {keyname1 ? this.renderListOfTest(keyname1) : null}
                                        </View>
                                    </View>
                                </View>
                            )
                        })
                        :
                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                            <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
                        </View>
                }
            </View>
        )
    }

    renderRightSideHeader() {
        return (
            <Text style={{ marginBottom: 20, fontSize: 15, fontWeight: 'bold', color: color.themeDark }}>
                {"Test Configuration"}
            </Text>
        )
    }


    renderLabsetAndAmountTextBox(value, key, width, marginTop, isNewEntry = false, index = "") {
        return (
            <>
                <TextInput 
                    disabled={(key === "labSetName") ? this.state.labSetDisableFlag : false}
                    style={[{ height: 35, borderWidth: 1, width: width, borderColor: "#888888", paddingLeft: 10, marginVertical: 15 }, Style.allButtonBorderRadius, key == "labSetName" || key == "department" ? { marginLeft: 20 } : {}, marginTop ? { marginTop: marginTop, marginLeft: 0 } : {}]}
                    value={isNewEntry ? value[key] : value}
                    onChangeText={(text) => {
                        if (isNewEntry) {
                             var states = this.state.showListOfNewEntrys;
                            states[index][key] = text;
                            this.setState({ states })
                            
                        } else {
                            var states = this.state;
                            states[key] = text;
                            this.setState({ states })
                        }
                    }}
                />

            </>
        )
    }

    renderLabSetTextBoxRow() {
        let notes = null;
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Department Name"}
                    noOfColumn={1}
                    headerKey={""}
                    columnSize={[1]}
                />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                        {this.renderLabsetAndAmountTextBox(this.state.department, "department", 150)}
                    </View>
                </View>
                <CommonHistorySectionHeader
                    heading1={"Lab Set"}
                    heading2={"Amount"}
                    noOfColumn={2}
                    headerKey={""}
                    columnSize={[0.6, 0.4]}
                />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.6 }}>
                        {this.renderLabsetAndAmountTextBox(this.state.labSetName, "labSetName", 150)}
                    </View>
                    <View style={{ flex: 0.34 }}>
                        {this.renderLabsetAndAmountTextBox(this.state.labSetAmount, "labSetAmount", 120)}
                    </View>
                </View>
                <View style={{display: 'none'}}>
                    <Text style={[styles.textBoxLabel, { marginLeft: 20 }]}>{"Notes"}</Text>
                    <TextInput
                        value={notes}
                        onChangeText={(text) => { this.setState({ notes: text }) }}
                        style={{ height: 35, borderWidth: 1, borderColor: "#888888", marginLeft: 20, marginVertical: 15, borderRadius: 4 }}
                    />
                </View>
            </View>
        )
    }

    renderTextNameNewEntryView(newEntry, index) {
        let remarks = null
        let name = null
        let normal_value = null
        let groupName = null
        let { showListOfNewEntrys } = this.state

        remarks = showListOfNewEntrys[index] ? showListOfNewEntrys[index].remarks : "";
        name = showListOfNewEntrys[index]['name'];
        normal_value = showListOfNewEntrys[index] ? showListOfNewEntrys[index].normal_range : "";
        //showListOfNewEntrys[index]['normal_range'] = null
        groupName = showListOfNewEntrys[index]['group_test'] == "" ? null : showListOfNewEntrys[index]['group_test']
        return (
            <View style={{ width: "95%", marginVertical: 3 }}>
                <View style={[{ flexDirection: "row", marginVertical: 10 }]}>
                    <TextInput
                        value={name}
                        onChangeText={(text) => {
                            var states = this.state.showListOfNewEntrys;
                            states[index]["name"] = text;
                            this.setState({ states })
                        }}
                        style={[{ width: "100%", height: 35, paddingLeft: 10, marginLeft: 20, borderWidth: 1, borderColor: "#888888", borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }]} />
                    <TouchableOpacity
                        onPress={() => { this.removeTextInputinShowList(index) }}
                        style={[{
                            height: 35, width: 40, backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center",
                            borderTopRightRadius: 4, borderBottomRightRadius: 4
                        }]}>
                        <Icon name="closecircleo" size={13} color={color.white} />
                    </TouchableOpacity>
                    <View>

                    </View>
                </View>
                <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>
                    <View>
                        <Text style={styles.textBoxLabel}>{"Group Name"}</Text>
                        <TextInput
                            value={groupName}
                            onChangeText={(text) => {
                                var states = this.state.showListOfNewEntrys;
                                states[index]["group_test"] = text == "" ? null : text;
                                this.setState({ states })
                            }}
                            style={{ height: 35, borderWidth: 1, width: 100, borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                        />
                    </View>
                    <View>
                        <Text style={styles.textBoxLabel}>{"Unit"}</Text>
                        {this.renderLabsetAndAmountTextBox(newEntry, "unit", 100, 8, true, index)}
                    </View>
                    <View>
                        <Text style={styles.textBoxLabel}>{"Amount"}</Text>
                        {this.renderLabsetAndAmountTextBox(newEntry, "amount", 100, 8, true, index)}
                    </View>
                </View>
                <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>
                    <Text style={styles.textBoxLabel}>{"Normal Range"}</Text>
                    <TextInput multiline="true" numberOfLines="4"
                        value={normal_value}
                        onChangeText={(text) => {
                            showListOfNewEntrys[index].normal_range = text;
                            this.setState({ showListOfNewEntrys })
                        }}
                        style={{ borderWidth: 1, width: "100%", borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                        placeholder = {"Example: 10-20 (Enter the value in mentioned format to get High/Low indication)"}
                        placeholderTextColor={"#BBBCB6"}
                    />
                </View>
                <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>
                    <Text style={styles.textBoxLabel}>{"Remarks"}</Text>
                    <TextInput multiline="true" numberOfLines="2"
                        value={remarks}
                        onChangeText={(text) => {
                            var states = this.state.showListOfNewEntrys;
                            states[index].remarks = text;
                            this.setState({ states })
                        }}
                        style={[{ borderWidth: 1, width: "100%", borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }]} />
                </View>
            </View>
        )
    }
    //add values Function 
    addTextInput(index) {
        let textInput = this.state.textinput
        textInput.push(
            <>
                <View style={[{ flexDirection: "row", marginVertical: 10 }]}>
                    <TextInput
                        key={'name'}
                        style={[{ width: "80%", height: 35, paddingLeft: 10, marginLeft: 20, borderWidth: 1, borderColor: "#888888", borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }]}
                        onChangeText={(text) => this.addValues(text, index, 'name')} />
                    <TouchableOpacity
                        onPress={() => { this.removeTextInput(index) }}
                        style={[{
                            height: 35, width: 40, backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center",
                            borderTopRightRadius: 4, borderBottomRightRadius: 4
                        }]}>
                        <Icon name="closecircleo" size={13} color={color.white} />
                    </TouchableOpacity>
                </View>

                <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>
                    <View>
                        <Text style={styles.textBoxLabel}>{"Group Name"}</Text>
                        <TextInput
                            style={{ height: 35, borderWidth: 1, width: 100, borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                            onChangeText={(text) => this.addValues(text, index, "group_test")} />
                    </View>

                    <View>
                        <Text style={styles.textBoxLabel}>{" Unit"}</Text>
                        <TextInput
                            style={{ height: 35, borderWidth: 1, width: 100, borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                            onChangeText={(text) => this.addValues(text, index, 'unit')} />
                    </View>
                    <View>
                        <Text style={styles.textBoxLabel}>{"Amount"}</Text>
                        <TextInput
                            style={{ height: 35, borderWidth: 1, width: 100, borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                            onChangeText={(text) => this.addValues(text, index, 'amount')} />

                    </View>
                </View>

                <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>

                        <Text style={styles.textBoxLabel}>{"Normal Range"}</Text>
                        <TextInput multiline="true" numberOfLines="4" 
                            style={{ borderWidth: 1, width: "100%", borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                            onChangeText={(text) => this.addValues(text, index, 'normal_range')}
                            placeholder={"Example: 10-20"} />
                    </View>
                    <View style={[{ flexDirection: "row", justifyContent: "space-between", marginLeft: 20 }]}>
                        <Text style={styles.textBoxLabel}>{"Remarks"}</Text>
                        <TextInput multiline="true" numberOfLines="2"
                            style={{ borderWidth: 1, width: "100%", borderColor: "#888888", paddingLeft: 10, marginVertical: 15, borderRadius: 4 }}
                            onChangeText={(text) => this.addValues(text, index, 'remarks')} />
                    </View>


            </>
        )
        this.setState({ textInput });
    }
    renderTestNameRow() {

        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Test Name"}
                    noOfColumn={1}
                    headerKey={""}
                    columnSize={[1]}
                />

                {
                    this.state.showListOfNewEntrys.length > 0 && this.state.showListOfNewEntrys.map((newEntry, index) => {
                        return this.renderTextNameNewEntryView(newEntry, index)
                    })
                }
            </View>
        )
    }
    // remove in textinput
    removeTextInputinShowList = (index) => {
        console.log(index)
        let showList = this.state.showListOfNewEntrys;
        this.state.deletedTestIds.push(showList[index].id)
        showList.splice(index,1)
        //delete showList[index]
        this.setState({ showList })
        console.log(showList, 'show list')
    }
    //function to remove TextInput dynamically
    addValues = (text, index, key) => {
        let dataArray = this.state.inputData;

        let checkBool = false;
        if (dataArray.length !== 0) {
            dataArray.forEach(element => {

                if (element.index === index) {
                    if (key === 'normal_range' || key === 'remarks') {
                        element[key] = text
                        checkBool = true;
                    } else {
                        element[key] = text;
                        checkBool = true;
                    }

                }
            });
        }

        if (checkBool) {
            this.setState({
                inputData: dataArray
            });
        }
        else {

            dataArray.push({
                'index': index,
                "name": "",
                // "normal_range": "",
                "unit": '',
                "group_test": "",
                "amount": 0,
                "normal_range": 0,
                'remarks': ''
            }
            );

            this.setState({
                inputData: dataArray
            });
        }
    }


    removeTextInput = (index) => {

        let textInput = this.state.textInput;
        let inputData = this.state.inputData;
        console.log(inputData, 'textinput')
        if (inputData.length > 0) {
            this.state.deletedTestIds.push(textInput[index].id, inputData[index].id)
            delete textInput[index]
            delete inputData[index];
        } else {
            delete textInput[index]
            delete inputData[index];
        }


        this.setState({ textInput, inputData });
    }
    //function to add text from TextInputs into single array
    onPressAdd() {

        let Showlistarray = this.state.showListOfNewEntrys
        let inputDataarray = this.state.inputData


        let array = [...Showlistarray, ...inputDataarray]

        console.log(array, 'array')
        // filtering the undefined
        let filterdArray = array.filter((e) => e !== undefined)
        // removing the index from inputdataarry or new name
        let Value = filterdArray.map((e) => {
            delete e.index
            return e
        })
        this.addTestConfigList(Value)
    }


    checkAllDetailsAreAvailable(newEntry) {
        var defaultReturn = true
        for (const data of newEntry) {
            if (data.name && data.normal_range && data.unit && data.amount) {
                // Do Nothing
            } else {
                defaultReturn = false
                return defaultReturn
            }
        }
        return defaultReturn
    }
    onPressNewEntry() {
        // var checkState = this.state.showListOfNewEntrys
        // console.log(this.state.inputData)

        // this.addTextInput(this.state.textinput.length)

        let { showListOfNewEntrys } = this.state;
        let defaultNewEntry = {
            "name": "",
            // "normal_range": "12",
            "unit": "",
            "group_test": "",
            "amount": '',
            "normal_range": 0,
            'remarks': ''
        };
        if (showListOfNewEntrys.length > 0) {
            var checkTestField = this.checkAllDetailsAreAvailable(showListOfNewEntrys)
            if (checkTestField) {
                showListOfNewEntrys.push(defaultNewEntry)
            } else {
                // Do nothing
            }

        } else {
            showListOfNewEntrys.push(defaultNewEntry)
        }
        this.setState({ showListOfNewEntrys })

    }

    renderFooterButton() {
        return (
            <View style={{ flexDirection: 'row', justifyContent: "space-evenly" }}>
                <View>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"New Entry"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressNewEntry.bind(this)}
                        buttonKey={"New Entry"} />
                </View>
                <View>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Add"}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.onPressAdd.bind(this)}
                        buttonKey={"add"} />
                </View>
                <View>
                    <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={"Clear"}
                        buttonType={"outlineTheme"}
                        buttonIcon={""}
                        rightIcon={true}
                        buttonAction={this.clearAllData.bind(this)}
                        buttonKey={"clear"} />
                </View>
            </View>
        )
    }
    renderSearchData = () => {
        return(
            <View style={styles.searchHeader}>
            <TextInput
              value={this.state.searchTestConfig}
              onChangeText={(text) => {
                this.setState({
                    searchTestConfig: text
                }, () => {
                    if(this.state.searchTestConfig == "") {
                        this.getTestConfigList()
                    }
                })
              }}
              onSubmitEditing = { () => { this.getTestConfigList() }}
              style={styles.searchTextBox}
              placeholder="Search Test Config..."
            />
            <TouchableOpacity
              style={styles.searchButton}
              onPress={() => {
                  this.getTestConfigList()
              }}>
              <Icons name='search' size={"1.2vw"} color={color.white}></Icons>
            </TouchableOpacity>
          </View>
        )
    }
    render() {
        return (
            <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={{ flex: 0.65, height: screenHeight - 65, paddingHorizontal: 15, paddingVertical: 30 }}>
                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{ paddingHorizontal: 15, paddingVertical: 30, flex: 1, height: "100%", backgroundColor: color.white, zIndex: -1 }} bounces={false} bouncesZoom={false} >
                        {this.renderSearchData()}
                        {this.renderTestConfigHeader()}

                        {this.renderTestConfigListOfDatas()}

                    </ScrollView>
                </View>

                <ScrollView showsVerticalScrollIndicator={false}
                    style={{ flex: 0.35, height: screenHeight - 65, backgroundColor: color.themeShade, }} >
                    <View style={[Style.rightView, { padding: 20, flex: 1 }]}>
                        {this.renderRightSideHeader()}



                        {this.renderLabSetTextBoxRow()}
                        {this.renderTestNameRow()}
                        {this.renderFooterButton()}

                    </View>

                </ScrollView>
                {/* <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 10 }}>
                    <Image source={require("../../../../assets/images/HomeScreenImages/Copyright.png")} style={{ height: 15, width: 15, color: color.lightGray, tintColor: color.lightGray }} />
                    <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
                </View>  */}
            </View>
        );
    }


    getTestConfigList() {
        var service_url = Constants.LAB_TEST_CONFIG + "?search_key=" + this.state.searchTestConfig;
        OpthamologyService.getInstance().getComplaints(
            service_url, this,
            this.getTestConfigListSuccess,
            this.getTestConfigListFailure
        );
    }
    getTestConfigListSuccess = success => {
        console.log(success, 'data')

        if (success.status == "success") {

            this.setState({
                listOfLabSets: success.data,
                listOfTestName: success.lab_test_config
            })
        }
    }
    getTestConfigListFailure = error => {
        //alert(JSON.stringify(error))
    }

    addTestConfigList(value) {

        var states = this.state;

        var data = {
            "lab_set_name": states.labSetName,
            "lab_amount": states.labSetAmount,
            "notes": states.notes,
            "delete_test_configuration_ids": states.deletedTestIds,
            "test_configurations": states.showListOfNewEntrys,
            "department" : states.department
        }
        console.log(data, 'data')
        var service_url = Constants.LAB_TEST_CONFIG;
        OpthamologyService.getInstance().postComplaints(
            service_url, data, this,
            this.addTestConfigListSuccess,
            this.addTestConfigListFailure
        );
    }
    addTestConfigListSuccess = success => {

        if (success.status == "success") {
            this.getTestConfigList()
            this.clearAllData()
            this.props.showResposeValue("success", success.message)
            this.setState({
                labSetDisableFlag:false
            })

        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    addTestConfigListFailure = error => {
    }

    clearAllData() {
        var states = this.state;
        states["showListOfNewEntrys"] = [];
        states["deletedTestIds"] = [];
        states["labSetName"] = "";
        states["labSetAmount"] = "";
        states["notes"] = "";
        states["department"] = ""
        states[""],
        
        this.setState({ states }, () => {
            this.setState({
                showListOfNewEntrys: [defaultNewEntry = {
                    "name": "",
                    "unit": "",
                    "amount": 0,
                    "normal_range": 0,
                    'remarks': ''
                }]
            }

            )
            this.setState({
                labSetDisableFlag:false,
                textinput: [],
                inputData: [],
                labSetDisableFlag: false
            })
        })
    }

    // delete  test names 
    deleteTestName(deleteID) {

        var service_url = Constants.LAB_TEST_CONFIG + "delete/" + deleteID + "/";
        OpthamologyService.getInstance().postComplaints(
            service_url, {}, this,
            this.deleteTestNameSuccess,
            this.deleteTestNameFailure
        );
    }
    deleteTestNameSuccess = success => {
        // alert(JSON.stringify(success))
        if (success.status == "success") {
            this.getTestConfigList()
            this.props.showResposeValue("success", success.message)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    deleteTestNameFailure = error => {

        // alert(JSON.stringify(error))
        this.props.showResposeValue("error", error.message)

    }
}

// define your styles
const styles = StyleSheet.create({
    TopheaderFontStyle:{
        fontSize: 13,
        color: "black",
        fontWeight: '500',
        marginBottom: ".3vw" 
    },
    headerFontStyle: {
        fontSize: 13,
        color: "black",
        fontWeight: '500'
    },
    patientListFontStyle: {
        fontSize: 11,
        color: "black"
    },
    textBoxLabel: {
        zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 5, backgroundColor: color.white, paddingLeft: 5, paddingRight: 5, color: "#888888"
    },
    searchHeader: { flexDirection: "row", zIndex: 0, marginRight: "1vw" },
    searchTextBox: { borderWidth: 1, fontSize: "0.9vw", height: "5vh", width: "11vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray },
    searchButton: { height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }
});
