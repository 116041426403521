import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import { Tooltip } from 'antd';
import AsyncStorage from '../../../AsyncStorage';

const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class IPbillStatusReport extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      // data: [],
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isClear: false,
      isClearInt: 0,
      valueData: [],
      isPaginated: false,
      isAdminAccess: false,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,

      IpBillsList: [],
      PatientType: "",
      TotalAmount: 0,
      PaidAmount: 0,
      PendingAmount: 0,
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getIpBillsList()
  }
  getIpBillsList = () => {

    var serviceUrl = Constants.IP_BILLS_STATUS_REPORT  + "?from_date=" + "&to_date=" ;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getIpBillsSuccess,
      this.getIpBillsFailure
    );

  }

  getIpBillsSuccess = response => {
    if (response.status == "success") {
      this.setState({
        IpBillsList: response.data.total_bill_items,
        TotalAmount:response.data.statistics.total,
        PaidAmount: response.data.statistics.total_paid_amount,
        PendingAmount: response.data.statistics.total_balance,
      })
    }
  }
  getIpBillsFailure = error => {
    console.log(error)
  }


  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData }, ()=>{this.getIpBillsList()})

  }



  onPressButton(key, value) {
    if (key == "save") {
      this.getIpBillsList()
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      this.setState({
        selectedAction: "",
        PatientType: "",
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        isClear: true,
        isClearInt: 1,
      }, () => {
        this.getIpBillsList();
        this.setState({
          isClear: false
        }, () => {
          this.renderDateFilter()
        })
      })
    } else if (key == "export") {
      this.ExportIPbills()
    }
  }

  ExportIPbills = () => {
    var service_url = Constants.IP_BILLS_STATUS_REPORT + "?from_date=" + "&to_date="  + '&export=excel'

    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      this.ExportIPbillsSuccess,
      this.ExportIPbillsFailure,
      "excel"
    );
  }

  ExportIPbillsSuccess = (success) => {
    try{
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }}
    catch (e){
      console.log(e)
    }
  }

  ExportIPbillsFailure = error => {
    // this.props.showResposeValue("error", 'wrong')
    console.log(error)
  };
  // Print

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }


  renderDateFilter = () => {
    return (
      <View style={{ zIndex: 10 }}>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }



  render() {
    var actions = [
      // { label: "Search", value: "save" },
      // { label: "Clear", value: "clear" },
      { label: 'Export As Excel', value: 'export'}
    ]
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "100%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            <View style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", width: "70%", marginTop: 0 }}>
                <Text style={styles.PageHeadText}>Statistics : </Text>
                <View style={[styles.CardView, { backgroundColor: "#EDFFEC", }]}>
                  <View><Text style={{ fontSize: '1.2vw', fontWeight: '500', marginTop: 10 }}>{"₹ "}{this.state.PaidAmount!= null ? this.state.PaidAmount : 0}</Text>
                    <Text style={{ fontSize: '0.87vw', fontWeight: '500', textAlign: 'right', paddingVertical: 8 }}>{"Paid Amount"}</Text></View>
                </View>
                <View style={[styles.CardView, { backgroundColor: "#FFF9ED", }]}>
                  <View><Text style={{ fontSize: '1.2vw', fontWeight: '500', marginTop: 10 }}>{"₹ "}{this.state.PendingAmount!= null ? this.state.PendingAmount : 0}</Text>
                    <Text style={{ fontSize: '0.87vw', fontWeight: '500', textAlign: 'right', paddingVertical: 8 }}>{"Pending Amount"}</Text></View>
                </View>
                <View style={[styles.CardView, { backgroundColor: "#F8F8F8", }]}>
                  <View><Text style={{ fontSize: '1.2vw', fontWeight: '500', marginTop: 10 }}>{"₹ "}{this.state.TotalAmount!= null ? this.state.TotalAmount : 0}</Text>
                    <Text style={{ fontSize: '0.87vw', fontWeight: '500', textAlign: 'right', paddingVertical: 8 }}>{"Total Amount"}</Text></View>
                </View>
              </View>
              <View style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {/* <View>
                  {this.renderDateFilter()}
                </View> */}
                <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2, marginLeft:'2vw' }}>
                  {
                    actions.map((item, index) => {
                      return (
                        <CommonButton
                          item={{}}
                          selectedvalue={{}}
                          butttonText={item.label}
                          buttonType={"theme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={item.value} />
                      )
                    })
                  }
                </View>
              </View>
            </View>
            <View style={{ width: "100%", marginTop: "0.5vw", paddingHorizontal: "0.35", zIndex: -2 }} >
              <CommonHistorySectionHeader
                heading1={"Patient Name"}
                heading2={"UHID"}
                // heading3={"Patient Type"}
                heading3={"Total Billing Amount (₹)"}
                heading4={"Paid Amount (₹)"}
                heading5={"Pending Amount (₹)"}
                noOfColumn={5}
                columnSize={[0.23, 0.22, 0.23, 0.20, 0.20]}
              />
              <ScrollView showsVerticalScrollIndicator={false} style={styles.TableBody}>
                <View >
                  {this.state.valueData && this.state.valueData.length > 0 ?
                    this.state.valueData.map(list => {
                      return (
                        <View style={{ flexDirection: "row", paddingVertical: '0.8vw', width: "100%", borderBottomWidth: 1, borderBottomColor: color.lightGray }}>
                          <View style={{ paddingHorizontal: 20, flex: 0.20 }}>
                            <Tooltip placement="topLeft" title={list.patient_name}>
                              <Text style={{ fontSize: "1vw" }}> {list.patient_name && list.patient_name.length > 24 ? list.patient_name.slice(0, 24) + ".." : list.patient_name} </Text>
                            </Tooltip>
                            <View style={{ flexDirection: "row", marginLeft: "0.3vw" }}>
                              <Text style={{ fontSize: "0.75vw" }}>{list.mobile}</Text>
                            </View>
                          </View>
                          <Text style={{ fontSize: "1vw", flex: 0.15 }}>{list.patient_account_number}</Text>
                          {/* <Text style={{ fontSize: "1vw", textAlign: 'center', flex: 0.10 }}>{list.type}</Text> */}
                          <Text style={{ fontSize: "1vw", textAlign: 'center', flex: 0.22 }}>{list.total_bill_amount != null ? list.total_bill_amount :0 }</Text>
                          <Text style={{ fontSize: "1vw", textAlign: 'center', flex: 0.20 }}>{list.total_paid_amount != null ? list.total_paid_amount :0}</Text>
                          <Text style={{ fontSize: "1vw", textAlign: 'center', flex: 0.20 }}>{list.total_balance != null ? list.total_balance : 0}</Text>
                        </View>)
                    }
                    ) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                      <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                    </View>
                  }</View>
              </ScrollView>
            </View>
            <View style={{ marginLeft: 20 }}>
              {this.state.IpBillsList && this.state.IpBillsList.length > 0 ?
                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.IpBillsList)} totalItems={this.state.IpBillsList} />
                : null
              }

            </View>
          </View>
        </View>

        {/* ============================ */}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableBody: {
    height: '65vh', overflow: "scroll"
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)", display: 'flex', width: '100%', flexDirection: 'row', paddingVertical: '1vw', paddingHorizontal: '1vw', marginTop: '2.3vw'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  },
  CardView: {
    justifyContent: 'center',
    alignSelf: 'center',
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: color.white,
    borderRadius: 4,
    paddingHorizontal: 15,
    minWidth: '10vw',
    height: '4vw',
    marginRight: '2vw'
  },
  PageHeadText:{
    fontSize:'1vw',
    fontWeight:'500',
marginRight:'1vw'
  }
});
