import React from "react";
import {
  View,
  Text,
  // FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";

const platform = Platform.OS;
const pageTitle = "Review Plan";
const pageName = "reviewplan";
const isPageName = "isReviewPlan";

export default class ReviewPlan extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,

      patientAppointment: this.props.patientAppointment,
      data: {},
      isComplaint: isPageName === this.props.selectedView?true:false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getComplaints();
    }, 5000);

  }

  componentWillReceiveProps(props) {
    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {
    // console.log("checkinnnnnnnnn")
    var service_url = Constants.OPTHAMOLOGY_MEDICATION_REVIEW_PALN_POST+ "?appointment_id=" + this.state.patientAppointment.appointment_id;
setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getComplaintsSuccess,
    this.getComplaintsFailure
  );
}, 5000);

  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["data"] = response.data;
      this.setState({ field });
      if(response.data.review_plan.has_followup_appointment){
        this.props.editItem(pageName,this.state.data.review_plan);
      }
    }
    // console.log("checkinnnnnnnnn")
    // console.log(response.data)
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, event) => {
    // console.log("editComplaints");
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }

  deleteItem = (item, event) => {
    
    //this.props.editItem(pageName, item);
    var service_url = Constants.OPTHAMOLOGY_DELETE_REVIEW + `${item.id}/`;
    
    OpthamologyService.getInstance().postComplaints(
      service_url,
      {},
      this,
      this.getDeleteSuccess,
      this.getComplaintsFailure
    );
  }

  getDeleteSuccess = response => {
    if (response.status === "success") {
      
      this.getComplaints();
    }
  };
  
  renderComplaintItem = (item, index) => {
    return (
      <View>
        <View >
          <View
            style={styles.ComplainItemView}
          >
            {this.renderTableData(item.duration?item.duration+" "+item.duration_type:"", "15%")}
            {this.renderTableData(item.review_date, "15%")}
            {this.renderTableData(item.appointment_time, "15%")}
            {this.renderTableData(item.comments, "45%")}

            <View
              style={styles.PencilBtnWrapper}
            >
              <View
                style={styles.PencilBtnView}
              >
                <TouchableOpacity   disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                {/* <TouchableOpacity onPress={this.deleteItem.bind(this, item)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity> */}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.EmptyView} />
      </View>
    );
  };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  render() {
    return (
        <View>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

        {
          this.state.data.review_plan && (this.state.data.review_plan.duration || this.state.data.review_plan.review_date || this.state.data.review_plan) ?

            <View >
              <View
                style={styles.MainView}
              >
                {this.renderHeader("Duration", "15%")}
                {this.renderHeader("Review Date", "15%")}
                {this.renderHeader("Appointment", "15%")}
                {this.renderHeader("Comments", "45%")} 
                {this.renderHeader("Action", "10%")}
{/* 
                <View style={{ width: "30%" }} >
                  <Text style={{ fontSize: 14, color: "#848484" }}>Duration</Text>
                </View>

                <View style={{ width: "20%" }} >
                  <Text style={{ fontSize: 14, color: "#848484" }}>Review Date</Text>
                </View>
                <View
                  style={{
                    width: "30%"
                  }}
                >
                  <Text style={{ fontSize: 14, color: "#848484" }}>Appointment</Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    alignItems: "center",
                    backgroundColor: color.white,
                    // height: 50
                  }}
                /> */}
              </View>
            </View>
            : null
        }

        {/* <FlatList
          data={this.state.complaintsData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderComplaintItem(item, index)
          }
        /> */}

{this.state.data.review_plan?this.renderComplaintItem(this.state.data.review_plan):null}


        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }

  renderHeader(title="", width="10%"){
    return(
      <View
        style={{
          width: width
        }}
      >
        <Text style={styles.HeaderText}>{title}</Text>
      </View>
    )
  }

  renderTableData(title="", width="10%"){
    return(
      <View
        style={{
          width: width
        }}
      >
        <Text style={styles.TableDataText}>
          {title}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  MainView:{
    marginTop:'0.97vw',
    marginLeft: '0.32vw',
    width: platform === "web" ? "100%" : "75%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  HeaderText:{
    fontSize: '0.91vw', 
    color: "#848484", 
    paddingRight: '0.97vw'
  },
  TableDataText:{
    fontSize: '0.8vw', 
    color: "#2F2F2F",
    paddingLeft: '0.32vw',
    paddingRight: '0.97vw'
  },
  ComplainItemView:{
    marginLeft: '0.32vw',
    width: platform === "web" ? "100%" : "75%",
    flexDirection: "row",
    justifyContent: "space-between",
    // height: 50,
    alignItems: "center"
    // paddingBottom: 10
  },
  PencilBtnWrapper:{
    width: "10%",
    alignItems: "center",
    // height: 60,
    flexDirection: "row"
  },
  PencilBtnView:{
   flexDirection: "row",
   paddingTop: '0.32vw',
   paddingBottom: '0.32vw',
   backgroundColor: color.white,
   // height: 50
  },
  EmptyView:{
    height: 0.5, 
    backgroundColor: "#E0E0E0" 
  }
})