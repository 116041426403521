//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton } from '../../BaseComponent'
import { color } from '../../../../styles/Color';
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
var Messages=require("../../../../utils/InfoMessages")

// create a component
const pageName = "isGynaecologicalHistory"
class GynaecologicalHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            checkOvarianCysts: {},
            checkFibroidsOfTheUterus: {},
            checkYeastInfection: {},
            checkBacterialVaginosis: {},
            checkBacterialVaginosisCommentBox: "",
            checkBleedingSpotting: {},
            checkBleedingSpottingCommentBox: "",
            checkUrinaryProblem: {},

            selectedMenopauseAge: "",

            checkUrinaryProblemCommentBox: "",
            checkHormoneReplacementCommentBox: "",
            checkGynaecologicalHistory: []
        }
    }


    clearAllValues() {

        var states = this.state;
        states["checkOvarianCysts"] = {}
        states["checkYeastInfection"] = {}
        states["checkFibroidsOfTheUterus"] = {}
        states["checkBacterialVaginosis"] = {}
        states["checkBacterialVaginosisCommentBox"] = ""
        states["checkBleedingSpotting"] = {}
        states["checkBleedingSpottingCommentBox"] = ""
        states["checkUrinaryProblem"] = {}
        states["selectedMenopauseAge"] = ""
        states["checkUrinaryProblemCommentBox"] = ""
        states["checkHormoneReplacementCommentBox"] = ""
        states["checkGynaecologicalHistory"] = []
        this.setState({ states })
    }



    onPressAddButton() {

        var states = this.state;

        var checkGynaecologicalHistoryToStringValue = []

        for (var i = 0; i < this.state.checkGynaecologicalHistory.length; i++) {
            checkGynaecologicalHistoryToStringValue.push(this.state.checkGynaecologicalHistory[i].value)
        }

        var had_ovarian_cysts = states.checkOvarianCysts.value == "Yes" ? true : false
        var had_fibroids_uterus = states.checkFibroidsOfTheUterus.value == "Yes" ? true : false
        var had_yeast_infection = states.checkYeastInfection.value == "Yes" ? true : false
        var had_bacterial_vaginosis = states.checkBacterialVaginosis.value == "Yes" ? true : false


        var is_bleeding_after_intercourse = states.checkBleedingSpotting.value == "Yes" ? true : false
        var had_urinary_problems = states.checkUrinaryProblem.value == "Yes" ? true : false



        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "appointment_id": this.state.patientAppointment.appointment_id,
            "gynaecology_history": {
                "had_ovarian_cysts": had_ovarian_cysts,
                "had_fibroids_uterus": had_fibroids_uterus,
                "had_yeast_infection": had_yeast_infection,
                "had_bacterial_vaginosis": had_bacterial_vaginosis,
                "bacterial_vaginosis_desc": states.checkBacterialVaginosisCommentBox,
                "is_bleeding_after_intercourse": is_bleeding_after_intercourse,
                "bleeding_intercourse_desc": states.checkBleedingSpottingCommentBox,
                "had_urinary_problems": had_urinary_problems,
                "urinary_problems_desc": states.checkUrinaryProblemCommentBox,
                "menopause_occured_age": states.selectedMenopauseAge,
                "used_hormone_replacement": states.checkHormoneReplacementCommentBox,
                "other_gyn_history": checkGynaecologicalHistoryToStringValue.toString(),


                "others_comments": "",
                "is_gynaecology_history_frozen": false,
                "gynaecology_history_frozen_date": null
            },
            "additional_notes": {
                "additional_notes": ""
            }
        }

        // console.log("GYNAECOLOGICAL_HISTORY -************> " + JSON.stringify(data));
        if (this.state.checkOvarianCysts.value || this.state.checkFibroidsOfTheUterus.value || this.state.checkYeastInfection.value || this.state.checkBacterialVaginosis.value ||
            states.checkBacterialVaginosisCommentBox || states.checkBleedingSpotting.value || states.checkBleedingSpottingCommentBox ||
            states.checkUrinaryProblem.value || states.selectedMenopauseAge || states.checkHormoneReplacementCommentBox) {
            var service_url = Constants.GYNAECOLOGICAL_HISTORY
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.gynaecologiSaveSuccess,
                this.gynaecologiSaveFailure
            );
        } else {
            this.props.showResposeValue('error',Messages.GynaecologicalEmptyField)
        }
    }

    gynaecologiSaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    gynaecologiSaveFailure = error => {
        this.props.showResposeValue('error', error.message)
    }

    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }


    componentWillReceiveProps(props) {


        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            // console.log("************>> " + JSON.stringify(props))
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {

                var states = this.state;
                var data = props.editHistoryItem.editItem


                states["checkOvarianCysts"] = this.checResponceIsFlag(data.had_ovarian_cysts)
                states["checkFibroidsOfTheUterus"] = this.checResponceIsFlag(data.had_fibroids_uterus)
                states["checkYeastInfection"] = this.checResponceIsFlag(data.had_yeast_infection)
                states["checkBacterialVaginosis"] = this.checResponceIsFlag(data.had_bacterial_vaginosis)
                states["checkBacterialVaginosisCommentBox"] = data.bacterial_vaginosis_desc
                states["checkBleedingSpottingCommentBox"] = data.bleeding_intercourse_desc
                states["checkBleedingSpotting"] = this.checResponceIsFlag(data.is_bleeding_after_intercourse)
                states["checkUrinaryProblem"] = this.checResponceIsFlag(data.had_urinary_problems)
                states["selectedMenopauseAge"] = data.menopause_occured_age
                states["checkUrinaryProblemCommentBox"] = data.urinary_problems_desc
                states["checkHormoneReplacementCommentBox"] = data.used_hormone_replacement

                // var checkGynaecologicalHistoryArray =Array.from(data.other_gyn_history);
                var checkGynaecologicalHistoryArray = data.other_gyn_history.split(',')
                // console.log("checkGynaecologicalHistoryArray -=-=-=-=-== " + JSON.stringify(checkGynaecologicalHistoryArray))

                var getHistory = []

                for (let i = 0; i < checkGynaecologicalHistoryArray.length; i++) {
                    if (checkGynaecologicalHistoryArray[i]) {
                        var data = { label: checkGynaecologicalHistoryArray[i], value: checkGynaecologicalHistoryArray[i] }
                        getHistory.push(data)
                    }

                }


                states["checkGynaecologicalHistory"] = getHistory




                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }

    }



    onPressButton(key, selectedValue) {
        if (key == "checkOvarianCysts") {
            this.setState({
                checkOvarianCysts: selectedValue,
            })
        } else if (key == "checkFibroidsOfTheUterus") {
            this.setState({
                checkFibroidsOfTheUterus: selectedValue,
            })
        } else if (key == "checkYeastInfection") {
            this.setState({
                checkYeastInfection: selectedValue,
            })
        } else if (key == "checkBacterialVaginosis") {
            this.setState({
                checkBacterialVaginosis: selectedValue,
            })
        } else if (key == "checkBleedingSpotting") {
            this.setState({
                checkBleedingSpotting: selectedValue,
            })
        } else if (key == "checkUrinaryProblem") {
            this.setState({
                checkUrinaryProblem: selectedValue,
            })
        } else if (key == "checkGynaecologicalHistory") {
            // this.setState({
            //     checkGynaecologicalHistory: selectedValue,
            // })


            var { checkGynaecologicalHistory } = this.state;

            var getSelectedValue;
            var spliceData;

            if (checkGynaecologicalHistory.length > 0) {
                for (var i = 0; i < checkGynaecologicalHistory.length; i++) {
                    if (checkGynaecologicalHistory[i].value == selectedValue.value) {
                        spliceData = checkGynaecologicalHistory[i];
                        checkGynaecologicalHistory.splice(i, 1)
                        var data = this.removeDuplicate(checkGynaecologicalHistory);
                        this.setState({ checkGynaecologicalHistory: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { checkGynaecologicalHistory.push(selectedValue); }
            } else {
                checkGynaecologicalHistory.push(selectedValue)
            }

            var data = this.removeDuplicate(checkGynaecologicalHistory);
            this.setState({ checkGynaecologicalHistory: data })


        }
    }



    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    checkMenstrualHistory(label, key, selectedValue) {
        var data = []

        if (key == "checkGynaecologicalHistory") {
            data = [
                // { label: "None", value: "None" },
                { label: "Veneral Warts", value: "Veneral Warts" },
                { label: "Herpes Genital", value: "Herpes Genital" },
                { label: "Syphilis", value: "Syphilis" },
                { label: "Pelvic Inflammatory", value: "Pelvic Inflammatory" },
                { label: "Vaginal Infections", value: "Vaginal Infections" },
                { label: "Endometriosis", value: "Endometriosis" },
                { label: "Chlamydia", value: "Chlamydia" },
                { label: "Gonorrhoea", value: "Gonorrhoea" },
            ]
        } else {
            data = [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ]
        }


        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    renderRichTextBox(key, values, label = "") {
        return (
            <View>
                {
                    label ?
                    <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text> :
                        <Text style={{ color: "#BCBCBC", fontSize: 12, fontWeight: '400' }}>{"Details if any"}</Text>
                }

                <TextInput
                    value={values}
                    placeholder={""}
                    multiline={true}
                    maxLength={100}
                    textAlignVertical="top"
                    style={{ marginTop: 5, marginBottom: 20, textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 65,shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
          
                      elevation: 5, }}
                    onChangeText={(text) => {
                        var statets = this.state;
                        statets[key] = text
                        this.setState({ statets })
                    }}
                />
            </View>
        )
    }



    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true} heading={"Gynaecological History"} />
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>
                {this.checkMenstrualHistory("Have you ever had a yeast infection?", "checkYeastInfection", this.state.checkYeastInfection)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Have you ever been diagnosed with any fibroids in the uterus?", "checkFibroidsOfTheUterus", this.state.checkFibroidsOfTheUterus)}
                </View>      
                          <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Have you ever had an ovarian cyst?", "checkOvarianCysts", this.state.checkOvarianCysts)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Have you ever been treated for vaginal bacterial infection (bacterial vaginosis)?", "checkBacterialVaginosis", this.state.checkBacterialVaginosis)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.renderRichTextBox("checkBacterialVaginosisCommentBox", this.state.checkBacterialVaginosisCommentBox)}
                </View>
                <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Does bleeding / spotting occur after intercourse?", "checkBleedingSpotting", this.state.checkBleedingSpotting)}
</View>         
       <View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.renderRichTextBox("checkBleedingSpottingCommentBox", this.state.checkBleedingSpottingCommentBox)}

</View>

<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Did you have any urinary problems such as UTI(Urinary Tract Infection), Urinary Frequency, Loss of bladder control, blood in urine, etc..", "checkUrinaryProblem", this.state.checkUrinaryProblem)}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

{this.renderRichTextBox("checkUrinaryProblemCommentBox", this.state.checkUrinaryProblemCommentBox)}
</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between',marginBottom:10 }}>
                    
                    
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{"If in menopause, At what age does menopause begin?"}</Text>

                    <Picker
                        selectedValue={this.state.selectedMenopauseAge}
                        style={[Style.pickerViewInOBGYN, { marginTop: 0 }]}
                        itemStyle={{ fontSize: 14, color: color.white, fontWeight: '700' }}
                        onValueChange={(itemvalue) => {
                            var states = this.state;
                            states["selectedMenopauseAge"] = itemvalue
                            this.setState({ states });
                        }}
                    >
                        <Picker.Item label="Select" value="" />
                        {Constants.LAST_MENOPAUSE_AGE && Constants.LAST_MENOPAUSE_AGE.map((item, index) => {
                            return <Picker.Item label={item.label} value={item.value} key={index} />
                        })}
                        {/* <Picker.Item label="1" value="1" />
                        <Picker.Item label="2" value="2" />
                        <Picker.Item label="3" value="3" /> */}
                    </Picker>
                </View>
</View>

<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>


                {this.renderRichTextBox("checkHormoneReplacementCommentBox", this.state.checkHormoneReplacementCommentBox, "Have you used any hormone replacement?")}

</View>
<View style={[Style.doctorNotesSectionView, { marginBottom: 10 }, styles.shadow]}>

                {this.checkMenstrualHistory("Other Gynaecological history (Select if any)", "checkGynaecologicalHistory", this.state.checkGynaecologicalHistory)}

</View>

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}


// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});

//make this component available to the app
export default GynaecologicalHistoryNew;
