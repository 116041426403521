import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
const screenName = "Indian Scale for assessment of autism"
var Messages = require('../../../../utils/InfoMessages')

export class IndianScaleforAssessmentofAutism extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofIndianScaleforAssessmen: {},
            comments: "",
            totalScore: 0,
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId,
            // listofIndianScaleforAssessmenWithOutHeader: [],
            // obsessionsQuestions: [],
            // compulsionQuestions: [],


            relationshipAndReciprocity :[],
            emotionalResponsiveness :[],
            languageAndCommunication :[],
            behaviourPatterns :[],
            sensorAspects :[],
            cognitiveComponent :[],
        }
    }

    componentDidMount() {
        this.getIndianScaleforAssessmen()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getIndianScaleforAssessmen()
        })
    }

    getIndianScaleforAssessmen() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL +
            "?patient_id=" + this.state.patientId +
            "&appointment_id=" + this.state.appointmentId +
            "&rating_scale_name=" + screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getIndianScaleforAssessmenSuccess,
            this.getIndianScaleforAssessmenFailure
        );
    }


    getIndianScaleforAssessmenSuccess = (success) => {
        if (success.status == "success") {

            

            // listofIndianScaleforAssessmenWithOutHeader
            var getRelationshipAndReciprocity = [];
            var getEmotionalResponsiveness = [];
            var getLanguageAndCommunication = [];
            var getBehaviourPatterns = [];
            var getSensorAspects = [];
            var getCognitiveComponent = [];

            if (success.data.questions.length > 0) {
                var questions = success.data.questions;
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].question_heading == "SOCIAL RELATIONSHIP AND RECIPROCITY") {
                        getRelationshipAndReciprocity.push(questions[i]);
                    } else if (questions[i].question_heading == "EMOTIONAL RESPONSIVENESS") {
                        getEmotionalResponsiveness.push(questions[i]);
                    } else if (questions[i].question_heading == "SPPECH LANGUAGE AND COMMUNICATION") {
                        getLanguageAndCommunication.push(questions[i]);
                    } else if (questions[i].question_heading == "BEHAVIOUR PATTERNS") {
                        getBehaviourPatterns.push(questions[i]);
                    } else if (questions[i].question_heading == "SENSORY ASPECTS") {
                        getSensorAspects.push(questions[i]);
                    } else if (questions[i].question_heading == "COGNITIVE COMPONENT") {
                        getCognitiveComponent.push(questions[i]);
                    }
                }
            }


            //  relationshipAndReciprocity 
            //  emotionalResponsiveness 
            //  languageAndCommunication 
            //  behaviourPatterns 
            //  sensorAspects 
            //  cognitiveComponent 

            this.setState({

            //     listofIndianScaleforAssessmenWithOutHeader: getListOfQuestionWithoutHeader,
            //     obsessionsQuestions: getObsessionsQuestions,
            //     compulsionQuestions: getCompulsionQuestions,
                listofIndianScaleforAssessmen: success.data,
                relationshipAndReciprocity :getRelationshipAndReciprocity,
                emotionalResponsiveness :getEmotionalResponsiveness,
                languageAndCommunication :getLanguageAndCommunication,
                behaviourPatterns : getBehaviourPatterns,
                sensorAspects :getSensorAspects,
                cognitiveComponent :getCognitiveComponent,
                comments: success.data.comments,
                totalScore: success.data.total_score
            })
        }
    }
    getIndianScaleforAssessmenFailure = error => { }


    validateTotalScores() {
        var { listofIndianScaleforAssessmen } = this.state;
        var getListOfQuestions = listofIndianScaleforAssessmen.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            getTotalScore = getTotalScore + getOptions[j].option_score
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore: getTotalScore
        })
    }


    saveIndianScaleforAssessmen() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL

        // relationshipAndReciprocity :[],
        //     emotionalResponsiveness :[],
        //     languageAndCommunication :[],
        //     behaviourPatterns :[],
        //     sensorAspects :[],
        //     cognitiveComponent :[],
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){

        var combainedArrays = [];
        combainedArrays.push(...this.state.relationshipAndReciprocity, ...this.state.emotionalResponsiveness, ...this.state.languageAndCommunication, ...this.state.behaviourPatterns , ...this.state.sensorAspects , ...this.state.cognitiveComponent)

        var data = {
            "id": this.state.listofIndianScaleforAssessmen.id ? this.state.listofIndianScaleforAssessmen.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofIndianScaleforAssessmen.rating_scale_id,
            "rating_scale_name": this.state.listofIndianScaleforAssessmen.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": combainedArrays
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveIndianScaleforAssessmenSuccess,
            this.saveIndianScaleforAssessmenFailure
        );
    }else{
        this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
    }
    }

    saveIndianScaleforAssessmenSuccess = success => {
        if (success.status == "success") {
            this.getIndianScaleforAssessmen()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveIndianScaleforAssessmenFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(key,indexOfQuestion, indexOfOptions) {

            
            var states = this.state;
            var getDatas = states[key];

        // var { listofIndianScaleforAssessmen } = this.state;

        // var getListOfOptions = getDatas.questions[indexOfQuestion].options;
        var getListOfOptions = getDatas[indexOfQuestion].options;
        
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        // getDatas.questions[indexOfQuestion]["options"] = prepareData
        getDatas[indexOfQuestion]["options"] = prepareData
        this.setState({ states }, () => {
            this.validateTotalScores()
        })

    }


    renderSubHeading(heading){
        return(
            <View style={{marginVertical:8}}>
                <CommonHistorySectionHeader
                    heading1={heading}
                    // question={questions.question_label}
                    headerKey={"RatingScale"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
            </View>
            
        )
    }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                    {/* <Text style={{color:color.white}}>{this.state.}</Text> */}
                </View>
                {/* <Text>{this.state.totalScore}</Text>
                <Text>{this.state.comments}</Text> */}
            </View>
        )
    }


    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',   paddingTop:8,paddingBottom:12, alignItems: 'center' }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={{ flex: 0.18,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 12, color: "#292929" }} numberOfLines={5}>{"Rarely"}</Text>
                    </View>
                    <View style={{ flex: 0.22,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 12, color: "#292929" }} numberOfLines={5}>{"Sometimes"}</Text>
                    </View>
                    <View style={{ flex: 0.23,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 12, color: "#292929" }} numberOfLines={5}>{"Frequently"}</Text>
                    </View>
                    <View style={{ flex: 0.18,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 12, color: "#292929" }} numberOfLines={5}>{"Mostly"}</Text>
                    </View>
                    <View style={{ flex: 0.19,alignItems:'center' }}>
                        <Text style={{ fontWeight: '500', fontSize: 12, color: "#292929" }} numberOfLines={5}>{"Always"}</Text>
                    </View>
                </View>
            </View>
        )
    }

    onChangeTextSubheading(key,value,indexOfQuestion,indexOfOptions){
        var states = this.state;
        var getDatas=states[key];
        var getListOfOptions = getDatas[indexOfQuestion].options[indexOfOptions].option_value;
        getListOfOptions=value;

        this.setState({states})
    }

    renderListOfObsessionsQuestions(key,item, index) {
        // if(item.question_type == "Textbox" ) {
            
        //         var txtValue=item.options[0].option_value
        //     return(
                
        //         <View key={index} style={{flexDirection:"row",justifyContent:'flex-end',alignItems:'center',marginVertical:5}}>
        //                 <View>
        //                     <Text style={[Style.historyHeaderFontStyleStartText,{fontSize: 15}]}>{item.question_label}</Text>
        //                 </View>
        //                 <TextInput
        //                 // multiline={true}
        //                 // numberOfLines={3}
        //                 style={{paddingLeft:10, color: color.black ,height:50,width:200,borderRadius:4,borderWidth:1,borderColor:"#888888"}}
        //                 value={txtValue}
        //                 onChangeText={(txt) => {
        //                         this.onChangeTextSubheading(key,txt,index, 0)
        //                     // this.setState({ comments: txt })
        //                 }}
        //             />
        //         </View>
                
        //     )
        // }else{
        return (
            <View key={index} style={{ flexDirection: 'row',paddingVertical:6,
            // borderBottomColor:"#D1D1D1",borderBottomWidth:0.5 
            }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ marginHorizontal: 20 ,fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{item.question_label}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={{ flex: 0.18 ,alignItems:'center'}}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 0)
                        }}>
                            <CheckBoxIcon name={item.options[0].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.22,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 1)
                        }}>
                            <CheckBoxIcon name={item.options[1].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.23,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 2)
                        }}>
                            <CheckBoxIcon name={item.options[2].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.18,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 3)
                        }}>
                            <CheckBoxIcon name={item.options[3].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.19,alignItems:'center' }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 4)
                        }}>
                            <CheckBoxIcon name={item.options[4].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
        }
    // }

    renderRelationshipAndReciprocity(header,key,){
        var states = this.state;
        var getDatas = states[key]
        return(
            <View>
                {
                            getDatas && getDatas.length > 0 ?
                                this.renderSubHeading(header)
                                : null
                        }
                        {
                            getDatas && getDatas.length > 0 ?

                            getDatas.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions(key,item, index)
                                })
                                : null
                        }
            </View>
        )
    }


    renderFooterComments(){
        return(
            <View style={{justifyContent:'center',alignItems:'center'}}>
                <Text style={{fontWeight:'bold',fontSize:20,color:color.themeDark,marginVertical:15}}>
                {
                    this.state.totalScore < 70?
                    "No Autism" :
                    this.state.totalScore >= 70 && this.state.totalScore <= 106  ?
                    "Mild Autism" :
                    this.state.totalScore >= 107 && this.state.totalScore <= 153  ?
                    "Moderate Autism"
                    : 
                    this.state.totalScore > 105?
                    "Severe Autism" : null

                }
                </Text>
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("Indian Scale for Assessment of Autism")}
                      
                        {this.renderRelationshipAndReciprocity("SOCIAL RELATIONSHIP AND RECIPROCITY","relationshipAndReciprocity")}
                        {this.renderRelationshipAndReciprocity("EMOTIONAL RESPONSIVENESS","emotionalResponsiveness")}
                        {this.renderRelationshipAndReciprocity("SPEECH LANGUAGE AND COMMUNICATION","languageAndCommunication")}
                        {this.renderRelationshipAndReciprocity("BEHAVIOUR PATTERNS","behaviourPatterns")}
                        {this.renderRelationshipAndReciprocity("SENSORY ASPECTS","sensorAspects")}
                        {this.renderRelationshipAndReciprocity("COGNITIVE COMPONENT","cognitiveComponent")}
                        
                        {this.renderFooterComments()}


                        <View style={{ backgroundColor: color.applicationBackgroundColor, height: 60, justifyContent: 'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveIndianScaleforAssessmen.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})