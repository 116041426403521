import React, { Component } from 'react';
import { Text, View, TextInput, StyleSheet, ScrollView, Dimensions, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { NavigationTopHeader } from '../BaseComponent';
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import Style from "./../../../styles/Style";
import Cancel from "react-native-vector-icons/Entypo";
import { CaretDownFilled, PlusOutlined, PrinterOutlined } from "@ant-design/icons"
import { DatePicker } from 'antd';
import moment from "moment";
import { SatelliteSharp } from '@material-ui/icons';
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";


const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
  { label: "Discharge Summary", value: "dischargeSummary" },
  { label: "Surgery Notes", value: "surgeryNotes" },
]
var fulldate = new Date();
var converted_date = moment(fulldate).format("YYYY-MM-DD");

export class SurgeryNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIpPatientCheckouted : this.props.isIpPatientCheckouted,
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      patientName: this.props.selectedIpPatientDetails.patient_name,
      reason:"",
      accNum: this.props.selectedIpPatientDetails.patient_account_number,
      wardName: this.props.selectedIpPatientDetails['room/ward/block'],
      patientId: this.props.selectedIpPatientDetails.patient_id,
      admissionId: this.props.selectedIpPatientDetails.id,
      appointmentId: this.props.selectedIpPatientDetails.appointment_id,
      staffNurse:"",
      date:converted_date,
      patientAdmits:"",
      patientDenies:"",
      preop:"",
      posTop:"",
      procedure:"",
      surgeon:"",
      assistant:"",
      anesthesia:"",
      fluids:"",
      urineOutput:"",
      eBL:"",
      specimen:"",
      drains:"",
      plan:"",
      dateTime:"",
      nameOfDoctor:"",
      signature:"",
      isFinish:false,
      previousList: [],
      previousDataShow: false,
      surgery_notes_id: null,
      templateSearchText: "",
      templateList: [],
      selectedTemplateName: "",
      selectedTemplateID: "",
      templateSuggessionList: []
    }
  }


  componentDidMount() {
    this.getPreviousList()
    this.getSurgeryTemplateData()
    
  }
  getSurgeryTemplateData = () => {
    var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE + "?template_name=" + this.state.templateSearchText;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSurgeryTemplateDataSuccess,
      this.getSurgeryTemplateDataFailure
    );
  }
  getSurgeryTemplateDataSuccess = (response) => {
    if(response && response.status === "success"){
      this.setState({
        templateSuggessionList: response.data
      })
    }
  }
  getSurgeryTemplateDataFailure = (error) => {
      this.props.showResposeValue("error", error.message)
  }
  getSurgeryDetails = () => {
    var service_url = Constants.IP_SURGERY_NOTES_TEMPLATE + "?template_id=" + this.state.selectedTemplateID;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSurgeryDetailsSuccess,
      this.getSurgeryDetailsFailure
    );
  }
  getSurgeryDetailsSuccess = (response) => {
    if(response && response.status === "success"){
      var data = response.data
      
      this.setState({
        reason: data.reason_for_hospital_stay,
        patientAdmits: data.patient_admit_to_having,
        patientDenies: data.patient_denies_having,
        preop: data.preop_diagnosis,
        posTop: data.postop_diagnosis,
        procedure: data.procedure,
        staffNurse: data.staff_name,
        surgeon: data.surgen,
        assistant: data.assistent,
        anesthesia: data.type_of_anesthesia,
        fluids: data.fluids,
        urineOutput: data.urine_output,
        eBL: data.estimated_blood_loss,
        specimen: data.speciman,
        drains: data.drains,
        plan: data.plan
      })
    }
  }
  getSurgeryDetailsFailure = (error) => {
      this.props.showResposeValue("error", error.message)
  }
  getPreviousList = () => {
    var service_url = Constants.IP_PREVIOUS_SURGERY_NOTES_GET + "?&patient_id=" + this.state.patientId + "&ip_admission_id=" + this.state.admissionId + "&appointment_id=" + this.state.appointmentId

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPreviousSurgeryNotesSuccess,
      this.getPreviousSurgeryNotesFailure
    );
  }

  getPreviousSurgeryNotesSuccess = (response) => {
    if(response.status === "success"){
      this.setState({
        previousList: response.data.ip_surgery_notes_list,
      })
    }
  }

  getPreviousSurgeryNotesFailure = (response) => {
    // console.log("SURGERY PREVIOUS LIST FAIL:",response )
  }

  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  changeTextBoxValues(value, key) {
    var states = this.state
    states[key] = value
    this.setState({ states })
  }

  renderTextBox(placehold, value, key, width, height) {
    return (
      <View>
        <View style={{ marginTop: 20 }}>
          <Text style={{ position: 'absolute', backgroundColor: 'white', marginLeft: 4, paddingHorizontal: 5 }}>{placehold}</Text>
          <TextInput
            disabled = { (placehold == "Name" || placehold == "Patient Acc.no" || placehold == "Ward Name/Room No") ? true : false }
            maxLength={ key == "nameOfDoctor" || key == "signature" || key == "wardName" || key == "accNum" || key == "patientName" ? null : 1000}
            placeholder={""} value={value}
            onChangeText={(text) => { this.changeTextBoxValues(text, key) }}
            // multiline={true}
            style={{ paddingLeft: 10, borderColor: "#00CED1", width: width, height: height, borderWidth: 1, marginTop: 8, borderRadius: 4, paddingVertical: 10 }}
          />
        </View>

      </View>

    );
  }
  onSaveButton = (key) => {
   var states=this.state
   var data= {
      "patient_id": states.patientId,
      "ip_admission_id": states.admissionId,
      "ip_surgery_notes": {
      "date": states.date,
      "patient_name": states.patientName,
      "patient_acc_no": states.accNum,
      "ward_name_room_no": states.wardName,
      "reason_for_hospital_stay":states.reason ,
      "patient_admit_having": states.patientAdmits,
      "patient_denies_having": states.patientDenies,
      "preop_diagnosis": states.preop,
      "postop_diagnosis": states.posTop,
      "procedure": states.procedure,
      "surgen": states.surgeon,
      "staff_name": states.staffNurse,
      "assistent": states.assistant,
      "type_of_anesthesia": states.anesthesia,
      "fluids": states.fluids,
      "urine_output": states.urineOutput,
      "estimated_blood_loss":states.eBL,
      "speciman": states.specimen,
      "drains": states.drains,
      "plan": states.plan,
      "date_time": states.dateTime,
      "name_of_doctor": states.nameOfDoctor,
      "signature": states.signature,
      "is_finished": key ==="saveButton" ? false : key === "finishButton" ? true : null
      }
      }

      if(states.surgery_notes_id){
        // var id = {surgery_notes_id: states.surgery_notes_id}
        data["surgery_notes_id"] = states.surgery_notes_id
      }

      var service_url = Constants.IP_SURGERY_NOTES_POST;
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveSurgeryNotesSuccess,
        this.saveSurgeryNotesFailure
      );

  }
  saveSurgeryNotesSuccess = (success) => {
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);
      this.getPreviousList()
      this.onClearButton()
    } else {
      this.props.showResposeValue('error', success.message);
    }
  }
  saveSurgeryNotesFailure = (error) => {
    this.props.showResposeValue('error', error.message);
  }
  onClearButton = () => {

    this.setState({
      reason: "",
      preop: "",
      posTop: "",
      procedure: "",
      surgeon: "",
      staffNurse:"",
      patientAdmits: "",
      patientDenies: "",
      assistant: "",
      anesthesia: "",
      fluids: "",
      urineOutput: "",
      eBL: "",
      specimen: "",
      drains: "",
      plan: "",
      dateTime: "",
      nameOfDoctor: "",
      signature: "",
      surgery_notes_id: null,
      isFinish: false,
      selectedTemplateID: "",
      patientAdmits: "",
      selectedTemplateName: ""
    })

  }
  renderFooterButton(buttonText, name, onPressButton) {
    return (
      <View>
        <TouchableOpacity
          disabled={this.state.isIpPatientCheckouted}
          onPress={() => onPressButton}
          style={Style.ipcancelButton} >
          <Icon name={name} size={15} color={color.white} />
          <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{buttonText}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  renderDatePicker(placehold, key, value) {
    return (
      <View style={{marginTop:20 }}>
        <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5 }}>
          {placehold}
        </Text>
        <DatePicker
          value={
            value
              ? moment(value)
              : value
          }
          placeholder={""}
          clearIcon={false}
          onChange={(date) => {
            var states = this.state
            var fulldate = new Date(date);
            var converted_date = moment(fulldate).format("YYYY-MM-DD");
            states[key] = converted_date
            this.setState({ states });
          }}

          format={"DD-MM-YYYY"}
          style={{
            zIndex: -1, width: 200, height: 35,
            borderColor: color.themeDark,
            borderRadius: 4, borderWidth: 1, marginTop: 7
          }}
        />
      </View>
    )
  }

  previousSurgeryPress = (data) => {
    this.setState({
      previousDataShow: !data
    })
  }

  onPressSurgeryNotes = (surgeryId) => {

    this.setState({
      surgery_notes_id: surgeryId,
      previousDataShow: false
    })

    var service_url = Constants.IP_SURGERY_NOTES_GET + "?&surgery_notes_id=" + surgeryId

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSurgeryNotesSuccess,
      this.getSurgeryNotesFailure
    );
    
  }

  getSurgeryNotesSuccess = (response) => {

    if(response.status === "success"){
      this.setState({
        date: response.data.date,
        patientName: response.data.patient_name,
        reason:response.data.reason_for_hospital_stay,
        accNum: response.data.patient_acc_no,
        wardName: response.data.ward_name_room_no,
        preop:response.data.preop_diagnosis,
        posTop:response.data.postop_diagnosis,
        procedure:response.data.procedure,
        surgeon:response.data.surgen,
        staffNurse:response.data.staff_name,
        patientAdmits: response.data.patient_admit_having,
        patientDenies: response.data.patient_denies_having,
        assistant: response.data.assistent,
        anesthesia: response.data.type_of_anesthesia,
        fluids: response.data.fluids,
        urineOutput: response.data.urine_output,
        eBL: response.data.estimated_blood_loss,
        specimen: response.data.speciman,
        drains: response.data.drains,
        plan: response.data.plan,
        dateTime: response.data.date_time,
        nameOfDoctor: response.data.name_of_doctor,
        signature: response.data.signature,
        isFinish:response.data.is_finished
      })
    }else {
      this.props.showResposeValue('error', response.message);
    }
  }

  getSurgeryNotesFailure = (response) => {
    // console.log("SurgeryNitesFail:", response )
    this.props.showResposeValue('error', response.message);
  }

  surgeryNotesPrint = () => {
    var service_url = Constants.IP_SURGERY_NOTES_GET + "?&surgery_notes_id=" + this.state.surgery_notes_id + "&export_type=pdf"
    
    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      this.successprintSurgeryNotes,
      this.failureprintSurgeryNotes,
      "pdf"
    );

  }

  successprintSurgeryNotes = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }

  failureprintSurgeryNotes = (error) => {

    // console.log(error);
  }

  previousSurgeryList = () => {
    var states = this.state;
    return(
      <View style={{maxHeight: "250px", minWidth: "200px"}}>
      {states.previousList.length > 0 ? 
      <TableContainer component={Paper}>
      <Table  aria-label="customized table">
        <TableHead >
          <TableRow >
            <TableCell style={{backgroundColor: "#7d7d7d", color: "white", fontWeight: "bold"}} align="center">Date</TableCell>
            <TableCell style={{backgroundColor: "#7d7d7d", color: "white", fontWeight: "bold"}} align="center">Surgery Name</TableCell>
            <TableCell style={{backgroundColor: "#7d7d7d", color: "white", fontWeight: "bold"}} align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {states.previousList.map((row) => (
            <TableRow key={row.surgery_notes_id}>
              <TableCell align="right">{row.date}</TableCell>
              <TableCell align="right">{row.surgery_name}</TableCell>
              <TableCell align="right">
                {row.is_finished ? 
                <View>
                  <TouchableOpacity onPress={() =>this.onPressSurgeryNotes(row.surgery_notes_id)}>
                    <Text style={{color:"#4221e7", fontWeight:"bold"}}>View</Text>
                  </TouchableOpacity>
                </View> :
                <View>
                <TouchableOpacity onPress={() => this.onPressSurgeryNotes(row.surgery_notes_id)}>
                  <Text style={{color:"red"}}>Last modified</Text>
                  <Text>{row.last_modified_date}</Text>
                </TouchableOpacity>
                </View>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    : null }
    </View>
    )
  }
  renderTemplateSuggesstion() {
    return (
      <View style={{ zIndex: 1, position: 'absolute', marginLeft: '0.65vw', maxHeight: '9.27vw', minWidth: '12.8vw', maxWidth: '19.53vw', alignSelf: "flex-end" }}>

        <ScrollView
          style={styles.tempDropDown}
        >
          { this.state.templateSuggessionList && this.state.templateSuggessionList.length > 0 ? this.state.templateSuggessionList.map((value, index) =>
            <View style={{ height: '1.95vw' }}>
              <TouchableOpacity key={index}
                style={{ marginLeft: '0.32vw', marginTop: '0.32vw' }}
                onPress={
                  () => {
                    var states = this.state;
                    states["selectedTemplateID"] = value.id,
                    states["selectedTemplateName"] = value.template_name,
                    states["templateSearchText"] = ""
                    this.setState({ states }, () => {
                      // this.onClearButton("templateChange")
                      this.getSurgeryDetails()
                    });
                  }
                }
              >
                <Text style={{ fontSize: '0.9vw' }}>{value.template_name}</Text>

              </TouchableOpacity>
            </View>
          ) : 
          <View>
            <Text style={{margin:'1vw'}}>No Records Found</Text>
            </View>}

        </ScrollView>

      </View>
    )
  }
  renderTemplateSelection = () => {
    return(
      <View style={styles.templateMain}>         
       { this.state.selectedTemplateName != "" ?
       <Text style={styles.tempList}>Selected Template :
       {this.state.selectedTemplateName && this.state.selectedTemplateName.length > 20 ?
         <Tooltip placement="top" title={this.state.selectedTemplateName}>
           <Text style={styles.tempDataStyle}> {this.state.selectedTemplateName.slice(0, 18) + ".."}</Text>
         </Tooltip> :
         <Text style={styles.tempDataStyle}> {this.state.selectedTemplateName}</Text>}</Text>
       : null
       }
        <View style={styles.tempSuggMain}>
          <Text style={styles.textBoxLabel}>{"Templates"}</Text>
          <TextInput
            placeholder='Search Templates'
            style={[Style.pickerView, { marginTop: '0.45vw', borderRadius: '0.26vw', borderColor: color.themeDark, height: '2vw', width: "100%", zIndex: -1, borderWidth: 1, fontSize: '0.91vw' }, this.state.isIpPatientCheckouted ? { backgroundColor: color.disableComponentColor } : {}]}
            value={this.state.templateSearchText}
            onChangeText={(text) => {
              this.setState({
                templateSearchText: text
              }, () =>{
                this.getSurgeryTemplateData()
              })
            }}
          />
        </View>
        <View style={{ marginTop: "1.8vw" }}>
           {this.state.templateSuggessionList && (this.state.templateSuggessionList).length > 0 && this.state.templateSearchText != "" ? this.renderTemplateSuggesstion() : null}
        </View>
      </View>
    )
  }
  render() {
    return (
      <TouchableWithoutFeedback onPress = { () => {
        this.setState({
          previousDataShow: false
        })
      }
      }>
      <View>
      <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ width: "100%",padding:10, height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 }} bounces={false} bouncesZoom={false} >
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeaderList}
          selectedTab={"surgeryNotes"} />
        <View style={{flexDirection: "row", justifyContent: "flex-end", width: "98%", height: "2%" }}>
          {this.renderTemplateSelection()}
          <TouchableOpacity 
            style={styles.headerBtnStyle} 
            onPress = {() => this.previousSurgeryPress(this.state.previousDataShow)}
          >
            <Text style={{color: "#fff"}}>Previous Notes</Text>
            <CaretDownFilled style={{marginTop: "4px", marginLeft: "2px", fontSize: "15px"}} />
          </TouchableOpacity>
          <TouchableOpacity 
          style={styles.headerBtnStyle}
          onPress = {() => this.onClearButton()}
          >
            <PlusOutlined style={{marginTop: "4px", fontSize: "13px"}} />
            <Text style={{color: "#fff"}}> New Note</Text>
          </TouchableOpacity>
          <TouchableOpacity 
          disabled = {this.state.isFinish ? false : true}
          style={{backgroundColor: this.state.isFinish ? "#04b7b1" : "#e0e0e0", 
          marginRight: "5%", 
          justifyContent: "space-evenly", 
          padding: "5px", 
          borderRadius: "4px",
          flexDirection: "row",
          color: "#fff"}} 
          onPress={() => this.surgeryNotesPrint()}
          >
            <PrinterOutlined style={{fontSize: "23px"}} />
          </TouchableOpacity>
        </View>
        <View style={{marginTop: "1%", zIndex: -1}}>
          <Text style={[styles.headerStyle]}>{"Patient Information"}</Text>
          <View>
            {/* <View>
             {this.renderDatePicker("Date","date",this.state.date)}
            </View> */}
            <View style={{flexDirection:"row", justifyContent: "space-between", width: "70%"}}>
            {this.renderDatePicker("Date","date",this.state.date)}
             {this.renderTextBox("Name", this.state.patientName, "patientName", "100%", "70%")}
             {this.renderTextBox("Patient Acc.no", this.state.accNum, "accNum", "100%", "70%")}
             {this.renderTextBox("Ward Name/Room No", this.state.wardName, "wardName", "100%", "70%")}
            </View>
            <View>
            {this.renderTextBox("Reason for hospital stay*", this.state.reason, "reason", "98%", 45)}
            </View>
          </View>
        </View>
        <View style={{marginTop: "1%"}}>
          <Text style={[styles.headerStyle]}>{"History of present illness "}</Text>
          {this.renderTextBox("Patient admits to having", this.state.patientAdmits, "patientAdmits", "98%", 45)}
          {this.renderTextBox("Patient denies having", this.state.patientDenies, "patientDenies", "98%", 45)}
        </View>
        <View style={{marginTop: "1%"}}>
          <Text style={[styles.headerStyle]}>{"Procedure details "}</Text>
          {this.renderTextBox("Preop Diagnosis", this.state.preop, "preop", "98%", 45)}
          {this.renderTextBox("Postop Diagnosis", this.state.posTop, "posTop", "98%", 45)}
          {this.renderTextBox("Procedure", this.state.procedure, "procedure", "98%", 45)}
          {this.renderTextBox("Surgeon", this.state.surgeon, "surgeon", "98%", 45)}
          {this.renderTextBox("Staff Nurse", this.state.staffNurse, "staffNurse", "98%", 45)}
          {this.renderTextBox("Assistant(s)", this.state.assistant, "assistant", "98%", 45)}
          {this.renderTextBox("Type of anesthesia", this.state.anesthesia, "anesthesia", "98%", 45)}
          {this.renderTextBox("Fluids given", this.state.fluids, "fluids", "98%", 45)}
          {this.renderTextBox("Urine output", this.state.urineOutput, "urineOutput", "98%", 45)}
          {this.renderTextBox("Estimated Blood Loss (EBL)", this.state.eBL, "eBL", "98%", 45)}
          {this.renderTextBox("Specimen", this.state.specimen, "specimen", "98%", 45)}
          {this.renderTextBox("Drains", this.state.drains, "drains", "98%", 45)}
        </View>
        <View style={{marginTop: "1%"}}>
          <Text style={[styles.headerStyle]}>{"Operative Findings"}</Text>
          {this.renderTextBox("Plan", this.state.plan, "plan", "98%", 45)}
          <View style={{ flexDirection: "row", justifyContent: "space-between", width: "60%" }}>
            {this.renderTextBox("Date / Time", this.state.dateTime, "dateTime", 250, "70%")}
            {this.renderTextBox("Name of Doctor", this.state.nameOfDoctor, "nameOfDoctor", 200, "70%")}
            {this.renderTextBox("Signature", this.state.signature, "signature", 200, "70%")}
          </View>
        </View>
        <View style={styles.footerButtonStyle}>
          <View>
            <TouchableOpacity
              disabled={this.state.isIpPatientCheckouted}
              onPress={() => this.onSaveButton("saveButton")}
              style={Style.ipcancelButton} >
              <Icon name={"save"} size={15} color={color.white} />
              <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Save"}</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              disabled={this.state.isIpPatientCheckouted}
              onPress={() => this.onSaveButton("finishButton")}
              style={Style.ipcancelButton} >
              <Icon name={"flag-o"} size={15} color={color.white} />
              <Text style={[Style.certificatetext, { marginLeft: 10 }]}>{"Finish"}</Text>
            </TouchableOpacity>
          </View>
          {/* {this.renderFooterButton("Save", "save", this.onSaveButton("saveButton"))} */}
          {/* {this.renderFooterButton("Finish", "flag-o", this.onSaveButton("finishButton"))} */}
          {/* {this.renderFooterButton("Clear","cross",this.onClearButton.bind(this))} */}
          <TouchableOpacity
            onPress={() => this.onClearButton()}
            style={Style.ipAdmissonCancelButton} >
            <Cancel name={"cross"} size={15} color={color.themeDark} />
            <Text style={[Style.certificatetext, { marginLeft: 10, color: color.themeDark }]}>{"Clear"}</Text>
          </TouchableOpacity>
        </View>
        
      </ScrollView>
      <View style = {{zIndex: 10, position:'absolute', marginLeft: "60%", marginTop: "7%"}}>
          {
            this.state.previousDataShow? 
            this.previousSurgeryList() 
            : null
          }
          </View>
      </View>
      </TouchableWithoutFeedback>


    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2c3e50',
  },
  headerStyle: {
    fontSize: 20,
    fontWeight: "500",
    // marginLeft: 10
  },
  footerButtonStyle: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20
  },
  headerBtnStyle: {
    backgroundColor: "#04b7b1", 
    marginRight: "5%", 
    justifyContent: "space-evenly", 
    padding: "5px", 
    borderRadius: "4px",
    flexDirection: "row",
    color: "#fff"
  },
  table: {
    minWidth: 700,
  },
  templateMain: { width: "94%", display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', marginRight: "0.5vw" },
  tempList: { minWidth: '17vw', fontSize: '0.9vw' },
  tempDataStyle: { fontSize: '0.9vw', fontWeight:'500' },
  tempSuggMain: { alignSelf: "flex-end", marginTop: '1.3vw', width: "15%" },
  textBoxLabel: { position: 'absolute', marginLeft: '0.65vw', backgroundColor: "white", fontSize: '0.78vw', paddingLeft: '0.32vw', paddingRight: '0.32vw', borderCsolor: "#888888" },
  tempDropDown: {
    height: '12.69vw', marginBottom: '0.65vw', flex: 1,
    backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: '0.26vw'}
});