import React,{Component} from 'react';
import {View,Text,StyleSheet,FlatList} from 'react-native';
import {OBGYNHistorySectionHeading,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "prenatalFactors"


export class PrenatalFactors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofPrenatalFactors: {},

            hideEditIcon:false,
            isheading:false
        }
    }

    componentDidMount() {
        this.getPrenatalFactors()
    }

    getPrenatalFactors(){
        var service_url = Constants.PSY_PRENATAL_FACTORSH + "?patient_id=" + this.state.patientAppointment.patient_id+
        "&appointment_id="+this.state.patientAppointment.appointment_id;
        
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPrenatalFactorsSuccess,
            this.getPrenatalFactorsFailure
        );
    }

    getPrenatalFactorsSuccess = success =>{
        if(success.status == "success"){
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "nutritional_deficiency") {
                        prepareData = {
                            key: "Nutritional Deficiency",
                            value: data[key] ? "Yes" : "No",
                            details: data.nutritional_deficiency_detail
                        }
                    }else if (key == "exposure_of_medication") {
                        prepareData = {
                            key: "Exposure of Medication",
                            value: data[key] ? "Yes" : "No",
                            details: data.exposure_of_medication_detail
                        }
                    }else if (key == "bleeding_during_pregnancy" ) {
                        prepareData = {
                            key: "Bleeding during pregnancy",
                            value: data[key] ? "Yes" : "No",
                            details: data.bleeding_during_pregnancy_detail
                        }
                    }else if (key == "abortion") {
                        prepareData = {
                            key: "Abortion",
                            value: data[key] ? "Yes" : "No",
                            details: data.abortion_detail
                        }
                    }else if (key == "rh_incompatability") {
                        prepareData = {
                            key: "Rh Incompatability",
                            value: data[key] ? "Yes" : "No",
                            details: data.rh_incompatability_detail
                        }
                    }else if (key == "decreased_fatal_movements" ) {
                        prepareData = {
                            key: "Decreased fetal movements",
                            value: data[key] ? "Yes" : "No",
                            details: data.decreased_fatal_movements_detail
                        }
                    }else if (key == "hypothyroidism") {
                        prepareData = {
                            key: "Hypothyroidism",
                            value: data[key] ? "Yes" : "No",
                            details: data.hypothyroidism_detail
                        }
                    }else if (key == "premature_placental_separation" ) {
                        prepareData = {
                            key: "Premature Placental separation",
                            value: data[key] ? "Yes" : "No",
                            details: data.premature_placental_separation_detail
                        }
                    }else if (key == "diabetes") {
                        prepareData = {
                            key: "Diabetes",
                            value: data[key] ? "Yes" : "No",
                            details: data.diabetes_detail
                        }
                    }else if (key == "preeclampsia_or_toxaemia" ) {
                        prepareData = {
                            key: "Preeclampsia/Toxaemia",
                            value: data[key] ? "Yes" : "No",
                            details: data.preeclampsia_or_toxaemia_detail
                        }
                    }else if (key == "psychiatric_disorder" ) {
                        prepareData = {
                            key: "Psychiatric disorder",
                            value: data[key] ? "Yes" : "No",
                            details: data.psychiatric_disorder_detail
                        }
                    }else if (key == "other_prenatal_conditions" ) {
                        prepareData = {
                            key: "Other Prenatal Conditions",
                            value: data[key] ? "Yes" : "No",
                            details: data.other_prenatal_conditions_detail
                        }
                    }
                    booleanValues.push(prepareData)
                }else if (key == "infections_1_trimester" || key == "infections_2_trimester" || key == "infections_3_trimester") {
                    let prepareData = {};
                    if(key == "infections_1_trimester"){
                        prepareData = {
                            key: "Infections-1st Trimester",
                            value: data[key] ,
                            // details: data.other_prenatal_conditions_detail
                        } 
                    }else if(key == "infections_2_trimester"){
                        prepareData = {
                            key: "Infections-2nd Trimester",
                            value: data[key] ,
                            // details: data.other_prenatal_conditions_detail
                        } 
                    }else if(key == "infections_3_trimester"){
                        prepareData = {
                            key: "Infections-3rd Trimester",
                            value: data[key] ,
                            // details: data.other_prenatal_conditions_detail
                        } 
                    }
                    stringVaues.push(prepareData)
                }else{

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues,...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofPrenatalFactors: data,
                listOfDatas: removeEmptyObj
            },()=>{
                this.selectedRightSideView(pageName)
            })
        }
    }
    getPrenatalFactorsFailure = error =>{}

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getPrenatalFactors();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name){
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofPrenatalFactors, pageName)
        }, 100);
        
    }

    renderHeader(){
        return(
            <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Prenatal Factors"}
                    editImage={this.state.hideEditIcon  || Object.keys(this.state.getListofPrenatalFactors).length == 0 ? "" :"pencil"}
                />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if(item.value ){
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        }else{
            return (<View></View>)
        }
        
    }
    

    render(){
        return(
            <View style={styles.container}>
            {this.renderHeader()}

            {this.renderContent()}
        </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
