import React, { Component } from 'react';
import { View, Text, Image, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ImageBackground, CheckBox } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI, CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";

const pageName = "devlopmentalAssessment"


export class DevlopmentalAssessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofNatureOfDelivery: {},

            hideEditIcon: false,
            personalSocial: [],
            gross_Motor: [],
            language: [],
            fineMotor: [],
            comments:"",
            selectAgevalue:"",
            abnormalities:[]
        }
    }

    componentDidMount() {
        this.getDenvorAssessment()
    }

    getDenvorAssessment() {
        var service_url = ""
        service_url = Constants.GET_DENVER_ASSESSMENT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&age_range=" + this.state.selectAgevalue;


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDenvorAssessmentSuccess,
            this.getDenvorAssessmentFailure
        );
    }

    getDenvorAssessmentSuccess = success => {
        if (success.status == "success") {

            if (Object.keys(success.data).length !== 0) { }
            else {
                this.setState({
                    ageDisable: true
                })
            }
            if (success.data) {
                var data = success.data.developmental_assessment
                var states = this.state
                //  alert(JSON.stringify(data["abnormalities"]))
                states["comments"] = success.data.comments
                states["personalSocial"] = data['Personal - Social']
                states["gross_Motor"] = data['Gross Motor']
                states["language"] = data['Language']
                states["fineMotor"] = data['Fine Motor - Adaptive']
                states["abnormalities"]= data["abnormalities"]
                states["selectAge"] = success.data.age_range ? { label: success.data.age_range, value: success.data.age_range } : null
                this.setState({
                    states
                })
            }


        }
    }

    getDenvorAssessmentFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getDenvorAssessment()
            this.props.refreshRighSideComponent("");
        }

        if(props.SelectedAge){
            this.setState({
                selectAgevalue:props.SelectedAge
            },()=>{
                this.getDenvorAssessment()
                props.getdenvarAge("")

            })
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofNatureOfDelivery, pageName)
        }, 100);

    }

    renderComments() {
        return (
            <View style={{ marginTop: 20 }}>
                <CommonHistorySectionHeader
                    heading1={"Comments"}
                    noOfColumn={1}
                />
                <Text style={[Style.historyHeaderFontStyle, { margin: 10, marginLeft: 20 }]}>{this.state.comments}</Text>

            </View>
        )
    }
    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Developmental Assessment"}
                editImage={this.state.hideEditIcon ? "" : ""}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>

                <View style={{ height: 600, width: "100%" }}>
                    {this.renderDevelopmentalImage()}
                    {this.renderComments()}

                </View>
            </View>
        )
    }

    renderDevelopmentalImage() {

        return (
            <View>
                <ImageBackground style={{ height: 500, width: "100%" }} source={require('../../../../../assets/images/Developmental Assessment Chart.png')}>
                    <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'space-between', padding: 10 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            {this.renderGrossMotor()}
                            {this.renderLanguage()}

                        </View>
                        <View style={{ position: 'absolute', zIndex: -1, height: 500, width: "100%", alignContent: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <View style={{ justifyContent: "center", marginBottom: 20 }} >
                                <View style={{}}>
                                    <Text style={{ fontSize: 15, fontWeight: 400, marginBottom: 5, textAlign: 'center' }}>Age</Text>
                                    <Text style={{ fontSize: 12, textAlign: 'center' }}>{this.state.selectAge && this.state.selectAge.label ? this.state.selectAge.label : null}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {this.renderPersnolSocial()}
                            {this.renderFinemotor()}
                        </View>
                    </View>
                </ImageBackground>
            </View>
        )
    }


    renderPersnolSocial() {
        return (
            <View>
                {
                    this.state.personalSocial && this.state.personalSocial.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { personalSocial } = this.state;
                                        personalSocial[index].assessment_value = !item.assessment_value

                                        this.setState({ personalSocial },()=>{
                                            this.props.denvarvalues("personalSocial",this.state.personalSocial)

                                        })
                                    }}
                                />
                                <Text style={{ marginLeft: 10, fontSize: 12 }}>{item.assessment}</Text>
                            </View>
                        )
                    })

                }
            </View>
        )

    }


    renderGrossMotor() {
        return (
            <View>
                {
                    this.state.gross_Motor && this.state.gross_Motor.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { gross_Motor } = this.state;
                                        gross_Motor[index].assessment_value = !item.assessment_value

                                        this.setState({ gross_Motor },()=>{
                                            this.props.denvarvalues("gross_Motor",this.state.gross_Motor)
                                        })
                                    }}
                                />
                                <Text style={{ marginLeft: 10, fontSize: 12 }}>{item.assessment}</Text>
                            </View>
                        )
                    })

                }
            </View>
        )

    }

    renderLanguage() {
        return (
            <View>
                {
                    this.state.language && this.state.language.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4, paddingRight: 5 }}>
                                <View style={{ width: '93%', flexWrap: 'nowrap' }}>
                                    <Text style={{ marginRight: 10, fontSize: 12 }}>{item.assessment}</Text>
                                </View>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { language } = this.state;
                                        language[index].assessment_value = !item.assessment_value

                                        this.setState({ language },()=>{
                                            this.props.denvarvalues("language",this.state.language)

                                        })
                                    }}
                                />
                            </View>
                        )
                    })

                }
            </View>
        )

    }

    renderFinemotor() {
        return (
            <View>
                {
                    this.state.fineMotor && this.state.fineMotor.map((item, index) => {
                        return (
                            <View style={{ flexDirection: 'row', marginBottom: 4, paddingRight: 5 }}>
                                <View style={{ width: '93%', flexWrap: 'nowrap' }}>
                                    <Text style={{ marginRight: 10, fontSize: 12, textAlign: 'right' }}>{item.assessment}</Text>

                                </View>
                                <CheckBox
                                    value={item.assessment_value}
                                    onValueChange={() => {
                                        var { fineMotor } = this.state;
                                        fineMotor[index].assessment_value = !item.assessment_value

                                        this.setState({ fineMotor },()=>{
                                            this.props.denvarvalues("fineMotor",this.state.fineMotor)

                                        })
                                    }}
                                />
                            </View>
                        )
                    })

                }
            </View >
        )

    }


    renderListOfData(item) {
        if (item.value && item.value !== "No") {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
