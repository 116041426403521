import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Platform, TouchableWithoutFeedback, Picker,ScrollView, TouchableOpacity } from 'react-native';
// import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { color } from '../../../../styles/Color';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton, LabelWithTextBox } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";

import { DatePicker } from 'antd';
import moment from "moment";

const pageName = "isScanObservation";
const dateFormat = 'YYYY-MM-DD';

export class ScanObservationNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: false,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            selectedPrevOvulation: {},
            lastMenstrualDate: "",
            investigation_date: moment(new Date()).format('YYYY-MM-DD'),
            investigation_Scan_id: "",
            scanFindingsValue: "",
            remarks: "",
            id: "",
            favPrevScan: [],
            islastmenstrual: false,
        };
    }

    componentDidMount() {
        this.getPrevScan()
    }

    componentWillReceiveProps(props) {

        // console.log("************>> "+JSON.stringify(props))
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && Object.keys(props.editHistoryItem).length > 0) {
                let item = props.editHistoryItem.editItem;
               // console.log("************>> " + JSON.stringify(item))
                this.setState({
                    id: item.selectedData.id,
                    investigation_Scan_id: item.selectedData.id,
                    investigation_date: item.selectedData.scan_date ? moment(item.selectedData.scan_date).format("YYYY-MM-DD") : null,
                    lastMenstrualDate: item.last_menstrual_period,
                    remarks: item.remarks,
                    scanFindingsValue: item.selectedData.scan_finding,
                    islastmenstrual: item.islastmenstrual,
                    selectedPrevOvulation: {}
                }
                    , () => {
                        this.props.editHistory("",)
                    }
                )

            }
        }

        if(props.newItemclear){

            var states = this.state

            states["lastMenstrualDate"] = "";
            states["selectedPrevOvulation"] = {};
      
            this.setState({
              states
            })
            this.clearAllValues();
            this.props.renderNewItemClear("ScanClear",false)
          }

    }

    getPrevScan() {
        var service_url = Constants.GET_PREV_SCAN + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPrevScanSuccess,
            this.getPrevScanFailure
        );
    }

    getPrevScanSuccess = response => {
        if (response.status === "success") {
            var field = this.state;
            field["favPrevScan"] = response.data;
            this.setState({
                field
            });
        }

    }
    getPrevScanFailure = error => {

    }

    updateState = (key, value) => {
        var fields = this.state;
        [key] = value;
        this.setState({ fields });

        // this.createFilterTag();
    };

    onPressDate(key, selectedValue, getItem) {
        // alert(JSON.stringify(selectedValue))
        this.setState({
            selectedPrevOvulation: selectedValue,
            investigation_Scan_id: selectedValue.id
        }, () => this.getDataByDate(true))
    }

    getDataByDate(hideDeleteIconFlag) {
        var service_url = Constants.SCAN_OBSERVATIONS_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id + "&investigation_scan_id=" + this.state.investigation_Scan_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDataByDateSuccess,
            this.getOvulationFailure,
            hideDeleteIconFlag
        );
    }

    getDataByDateSuccess = (response, hideDeleteIconFlag) => {
        if (response.status === "success") {
            this.props.getDataByDateValues(response.data, "ScanObservation", hideDeleteIconFlag)
            var field = this.state;
            field["lastMenstrualDate"] = response.data.last_menstrual_period
            // field['id'] = response.data.id
            // field['remarks'] = response.data.remarks

            // if (response.data.scan_observation_values.length > 0) {

            //     // var getIndex = 0; // response.data.lab_observation_values.length-1
            //     var getIndex = 0; // response.data.lab_observation_values.length-1
            //     var data = response.data.scan_observation_values[getIndex]

            //     field['id'] = data.id
            //     field['investigation_date'] = data.scan_date;
            //     field['scanFindingsValue'] = data.scan_finding
            //     field['islastmenstrual'] = false


            // }


            this.setState({
                field
            });
        }

    }

    getDateValue(label, key, selectedValue, listData) {

        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {listData.length > 0 ? listData.map((item, index) => {

                        var getItem = item;
                        if (key == "selectedPrevOvulation") {
                            // alert(JSON.stringify(item))
                            getItem["value"] = item.id
                        } else {
                            getItem = { value: item, label: item }
                        }
                        return (
                            <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                <CommonButton
                                    item={getItem}
                                    selectedvalue={selectedValue}
                                    butttonText={getItem.last_menstrual_period}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressDate.bind(this)}
                                    buttonKey={key} />
                            </View>
                        )
                    }) : null
                    }
                </View>
            </View>
        )
    }

    renderInvestigationRow(title, key, value) {
        return (
            <View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: "65%" }}>
                        {this.rowHeadingText(title)}
                    </View>

                    <View style={{ width: "35%" }}>
                        {this.renderTextBox(key, value, 114)}
                    </View>
                </View>
            </View>
        )
    }

    rowHeadingText(title) {
        return (
            <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{title}</Text>
        )
    }

    onPressButton(key, selectedValue) {
        var states = this.state;

        states[key] = selectedValue

        this.setState({ states })
    }

    // obgynYesNoButton(key, selectedValue) {
    //     var data = [
    //         { label: "Yes", value: "Yes" },
    //         { label: "No", value: "No" }
    //     ]

    //     return (
    //         // <View>
    //         //     <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
    //         <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
    //             {
    //                 data.map((item, index) => {
    //                     return (
    //                         <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
    //                             <CommonButton
    //                                 item={item}
    //                                 selectedvalue={selectedValue}
    //                                 butttonText={item.label}
    //                                 buttonType={"outlineNonTheme"}
    //                                 buttonIcon={""}
    //                                 rightIcon={false}
    //                                 buttonAction={this.onPressButton.bind(this)}
    //                                 buttonKey={key} />
    //                         </View>
    //                     )
    //                 })
    //             }
    //         </View>
    //         // </View>
    //     )
    // }



    renderTextBox(key, value, width, backgroundTheme = false) {
        return (
            <TextInput
                style={[{ marginTop: 10, paddingLeft: backgroundTheme ? null : 10, height: 30, borderWidth: 1, borderColor: backgroundTheme ? color.themeDark : "#888888", width: width }, Style.allButtonBorderRadius,
                // backgroundColor:backgroundTheme ?color.themeDark :null 
                backgroundTheme ? {
                    backgroundColor: color.themeDark,
                    textAlign: 'center', color: color.white
                } : {}
                ]}
                value={value}
                maxLength={key == "scanFindingsValue" ? 30 : null}
                multiline={key == "scanFindingsValue" ? true : false}
                onChangeText={(text) => {
                    var states = this.state;
                    states[key] = text
                    this.setState({
                        states
                    })
                }}
            />

        )
    }

    onPressAddButton() {

        var states = this.state;


        var d = moment(this.state.investigation_date)
        var n = moment(this.state.lastMenstrualDate)
        var res = Math.abs(d - n) / 1000;

        var days = Math.floor(res / 86400);
        var weeks = parseInt(days / 7)
        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "last_menstrual_period": this.state.lastMenstrualDate,
            "remarks": this.state.remarks,

            "investigation_scan_observations":
            {
                "scan_date": this.state.investigation_date,
                "gestational_age": weeks,
                "scan_findings": states.scanFindingsValue,

            }

        }

        if (this.state.id) {
            data["investigation_scan_observations"]["id"] = this.state.id
        }

        var service_url = Constants.SCAN_OBSERVATIONS_SAVE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.investigationHistorySaveSuccess,
            this.investigationHistorySaveFailure
        );

    }



    investigationHistorySaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.clearAllValues();
            this.getPrevScan()
            this.getDataByDate(false)
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    investigationHistorySaveFailure = error => {
        this.props.showResposeValue('error', error.message)
       // console.log("obstetricalHistorySaveFailure <<~~~~~>> " + JSON.stringify(error))
    }

    clearAllValues() {
        var states = this.state;
        states["selectedPrevOvulation"] = {};
        //  states["lastMenstrualDate"] = "";
        states["investigation_date"] = moment(new Date()).format('YYYY-MM-DD');
        states['scanFindingsValue'] = "";
        states['remarks'] = "";
        states['id'] = "";
        states["islastmenstrual"] = false;


        this.setState({ states })
    }

    renderRichTextBox(label, key, values) {
        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: "500" }}>{label}</Text>
                <TextInput
                    value={values}
                    placeholder={""}
                    multiline={true}
                    textAlignVertical="top"
                    maxLength={key == "remarks"?100: key=="scanFindingsValue" ? 32 :null }
                    style={{
                        marginTop: 5,
                        marginBottom: 20,
                        textAlignVertical: "top",
                        paddingLeft: 10,
                        backgroundColor: color.white,
                        borderRadius: 8,
                        borderColor: color.rightPanelInputBorder,
                        borderWidth: 1,
                        height: 65,
                        paddingTop: 5
                    }}
                    onChangeText={(text) => {
                        var statets = this.state;
                        statets[key] = text;
                        this.setState({ statets });
                    }}
                />
            </View>
        );
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    render() {
        var states = this.state;

        return (
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

                <View style={styles.container}>
                    <View style={{ flexDirection: 'row' , marginBottom:10 }} >
                        <View style={{ width: '80%' }}>
                            <Text style={{ fontSize: 20, fontWeight: '500' ,marginLeft:10}}>Scan Observations</Text>
                        </View>
                        <View style={{ width: '20%'}}>
                            <TouchableOpacity style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingRight: 10, paddingLeft: 30, paddingTop: 5, paddingBottom: 5 }}
                                onPress={() => {
                                    this.clearAllValues();
                                }}
                            ><Text style={{ fontSize: 15, color: 'white' }}>{"New"}</Text></TouchableOpacity>
                        </View>
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.getDateValue("Previous Scan Observations", "selectedPrevOvulation", this.state.selectedPrevOvulation, this.state.favPrevScan)}
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', marginBottom: 10 }}>
                        <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row" }]}>

                            <View style={{ width: "65%" }}>
                                <Text style={{ fontSize: 15, fontWeight: '500',marginTop:10 }}>Last Menstrual Period</Text>
                            </View>
                            <View style={{ width: "35%" }}>

                                <DatePicker
                                    disabledDate={current => {
                                        return current && current > moment().add(0, "days");
                                    }}
                                    value={this.state.lastMenstrualDate ? moment(this.state.lastMenstrualDate, dateFormat) : this.state.lastMenstrualDate}
                                    onChange={date => {
                                        var fulldate = new Date(date);
                                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                        this.setState({ lastMenstrualDate: converted_date }, () => { this.updateState("lastMenstrualDate", this.state.lastMenstrualDate) })
                                    }}
                                    disabled={this.state.islastmenstrual ? true : false}
                                    defaultValue={"From"}
                                    format={"DD-MM-YYYY"}
                                    placeholder={"Select"}
                                    style={{
                                        borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                                        alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: '100%'
                                    }}
                                />

                            </View>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row" }]}>

                            <View style={{ width: "65%" }}>
                                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 10 }}>Date</Text>
                            </View>
                            <View style={{ width: "35%" }}>

                                <DatePicker
                                    disabledDate={current => {
                                        return current && current > moment().add(0, "days");
                                    }}
                                    value={this.state.investigation_date ? moment(this.state.investigation_date, dateFormat) : this.state.investigation_date}
                                    onChange={date => {
                                        var fulldate = new Date(date);
                                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                        this.setState({ investigation_date: converted_date }, () => { this.updateState("investigation_date", this.state.investigation_date) })
                                    }}
                                    defaultValue={"From"}
                                    format={"DD-MM-YYYY"}
                                    placeholder={"Select"}
                                    style={{
                                        borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                                        alignItems: "center", flexDirection: "row", paddingRight: 10, marginRight: 10, width: '100%'
                                    }}
                                />

                            </View>
                        </View>
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>

                        {this.renderRichTextBox("Scan Findings", "scanFindingsValue", states.scanFindingsValue)}
                    </View>
                    <View style={{ marginTop: 10 }}>
                        {/* <View> */}
                        {/* <LabelWithTextBox label={"Remarks"} value={this.state.remarks} textBoxKey={"remarks"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} /> */}

                        {/* {this.renderRichTextBox("Remarks", "remarks", this.state.remarks)} */}
                        {/* </View> */}
                        <View style={[Style.doctorNotesSectionView, styles.shadow]}>

                            {this.renderRichTextBox("Remarks", "remarks", this.state.remarks)}
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                    </View>
                </View>
            </ScrollView>
        );
    }
}
const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        flex: 1,
    },
    shadow: {
        width: "100%", shadowColor: "#000",
        marginTop: 5,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5, backgroundColor: "white"
    }
});
