//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Picker, ScrollView, Dimensions, Image, TextInput } from "react-native";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import BaseComponentStyle from "../../BaseComponentStyle";
import moment from "moment";
import { color } from "../../../../styles/Color";
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Tooltip } from 'antd';
import Icon from "react-native-vector-icons/Entypo";
import PrintIcon from "react-native-vector-icons/FontAwesome";
import AsyncStorage from "../../../../AsyncStorage";
import ImagePath from "../../../../utils/ImagePaths";
const screenHeight = Dimensions.get("window").height;

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1"
});

// create a component
class TestReport extends BaseComponentStyle {
    constructor(props) {
        super(props);
        let userType = '';

        try {
            userType = this.props.userType;
        } catch (error) { }

        this.state = {

            isIpPatientCheckouted:this.props.isIpPatientCheckouted,
            startDate: "",
            endDate: "",
            selectedSearch: {},
            // patient_id:this.props.patient_id,
            // patient_id: "20865",
            // appointment_id:"22913",

            patient_id: this.props.patient_id ? this.props.patient_id : this.props.modificationBillDetails.patient_id,
            appointment_id: this.props.appointment_id ? this.props.appointment_id : this.props.modificationBillDetails.appointment_id,
            userType: this.props.userType,
            covertedToImageData: [],
            getListOfTestReports: [
                // {
                //     "report_type": "Laboratory",
                //     "file_name": "patient/25/PTLAB_20191206191451848842_2216335-64.png",
                //     "report_date": "2019-12-06"
                // },
                // {
                //     "report_type": "Laboratory",
                //     "file_name": "patient/25/PTLAB_20191206182842108355_2216335-48.png",
                //     "report_date": "2019-12-06"
                // },
                // {
                //     "report_type": "Laboratory",
                //     "file_name": "patient/25/PTLab_20191206182441740880_2216335-48.png",
                //     "report_date": "2019-12-06"
                // }
            ],

            showUploadTestReportFlag: false,
            selectedTestReportType: '',
            selectedTestReportDate: '',
            selectedTestReportImage: '',
            userType: userType,
            doctor_id: this.props.patientDetails.doctor_id ? this.props.patientDetails.doctor_id : this.props.modificationBillDetails.doctor_id,
            selectedIpPatientDetails: this.props.selectedIpPatientDetails,
            isCurrentPageIsIP: this.props.isCurrentPageIsIP,
            commentEnable : false,
            comment: "",
            isSaveDisable: false,
            modificationBillDetails: this.props.modificationBillDetails,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
            otPatientDetails: this.props.otPatientDetails,
            clinicId: ""
        };
    }


    async componentDidMount() {
        this.getTestReport();
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
        this.setState({
          clinicId: loggedInDataObj.clinic_id
        })
    }

    componentWillReceiveProps(props) {
        if (props && props.searchType == "isTesteport" && (props.startDate !== this.state.startDate || this.state.endDate !== props.endDate)) {
            // if(){
            var { startDate, endDate } = this.state

            //   if(props.startDate)
            startDate = props.startDate;
            //   if(props.endDate)
            endDate = props.endDate;

            this.setState({ startDate, endDate }, () => {
                this.props.clearSearchType("")
                this.getTestReport();
            })
            // }
        }
    }

    getTestReport = () => {

        var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : ""
        var LastDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : ""
        let { userType } = this.state;
        let service_url = ""

        if (userType === Constants.ROLE_FO) {
            if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {

                let patient_id = this.state.selectedIpPatientDetails.patient_id ? (this.state.selectedIpPatientDetails.patient_id) : this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : this.state.modificationBillDetails.patient_id
                let doctor_id = this.state.selectedIpPatientDetails.doctor_id ? this.state.selectedIpPatientDetails.doctor_id : this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : this.state.modificationBillDetails.doctor_id

                service_url = Constants.FO_TEST_REPORT + "?patient_id=" + patient_id + "&doctor_id=" + doctor_id + "&start_date=" + startDate + "&end_date=" + LastDate;

            } else {
                // var doctor_id = this.state.doctor_id ? this.state.doctor_id : this.state.selectedClinicOrDoctor.id
                service_url = Constants.FO_TEST_REPORT + "?patient_id=" + this.state.patient_id +"&start_date=" + startDate + "&end_date=" + LastDate;
            }
        } else if(userType === "OT") {
            
              let patient_id = this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : ""
              let doctor_id = this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : ""
      
              service_url = Constants.OT_TEST_REPORT+ "?patient_id=" + patient_id + "&doctor_id=" + doctor_id + "&start_date=" + startDate + "&end_date=" + LastDate;
        } else {
            // service_url = Constants.TEST_REPORT + "?appointment_id=" + this.props.appointment_id;
            service_url = Constants.TEST_REPORT + "?patient_id=" +  this.state.patient_id  + "&clinic_id=" + this.state.selectedClinicOrDoctor.id;

        }

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getTestReportSuccess,
            this.getTestReportFailure
        );
    }


    getS3Key(doc_key, img_id) {


        return new Promise(resolve => {
            var parms = { 
                        //   Bucket: Constants.s3BUCKET_NAME, 
                          Bucket: "emedhub",
                          Key: doc_key, Expires: 300 
                        };
            s3.getSignedUrl("getObject", parms, (err, doc_key) => {
                if (err) {
                    // console.log(err);
                    resolve(err)
                } else {
                    // console.log(url);
        
                    var data = {
                        "doc_key": doc_key, 
                        "img_id": img_id 
                    } 
                    resolve(data)
                }
            });
        });
    }


    async _renderImage() {
        var showDocuments = this.state.getListOfTestReports
        //alert("_renderImage")
        let tempArr = [];
        var getData;
        for (var i = 0; i < showDocuments.length; i++) {
            if(showDocuments[i].is_transmit){
                getData = {
                    "id": showDocuments[i].id,
                    "is_tranmit": showDocuments[i].is_transmit,
                    "result": showDocuments[i].result
                }
            }else{
                
                var file_name = showDocuments[i].file_name
                for(let i=0; i< file_name.length; i++) {
                    var image_urls = file_name[i].result_img_url
                    var img_id = file_name[i].lab_img_billing_id
                    getData = await this.getS3Key(image_urls, img_id)
                    tempArr.push(getData)
                }
            }
            
            
        }

        this.setState({
            covertedToImageData: tempArr
        })
    }

    getTestReportSuccess = (response) => {
        if (response.status == "success") {
            // alert(JSON.stringify(response.data))
            this.setState({
                getListOfTestReports: response.data
            })
        }
    }


    getTestReportFailure = (response) => {

    }

    onPressButton(key, selectedvalue) {
        if (key == "Search") {
            // this.setState({
            //     selectedSearch: selectedvalue
            // })
            this.getTestReport()
        }

    }


    renderHistoryItem = (item, index) => {
        return (
            <View>
                <View style={{ paddingTop: 0 }}>
                    <View
                        style={{
                            marginLeft: 5,
                            width: platform === "web" ? "100%" : "75%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <View
                            style={{
                                width: "100%",
                            }}
                        >
                            <Text style={Style.contentTableDataLead}>
                                {item.disease_name}
                            </Text>
                        </View>

                        <View
                            style={{
                                width: "10%",
                                alignItems: "center",
                                height: 60,
                                flexDirection: "row",
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    backgroundColor: color.white,
                                    height: 50,
                                }}
                            ></View>
                        </View>
                    </View>
                </View>

                {/* <View style={Style.sectionItemLine} /> */}
                <View
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: "#E0E0E0",
                        width: 350,
                    }}
                />
            </View>
        );
    };

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }




    // openImageFolder = async (data) => {
    //     // this.setState({
    //     //   documentUploadSelectedButton:data
    //     // })
    //     try {
    //         let getImage;
    //         let result = await ImagePicker.launchImageLibraryAsync({
    //             mediaTypes: ImagePicker.MediaTypeOptions.All,
    //             allowsEditing: true,
    //             aspect: [4, 3],
    //             quality: 1,
    //         });
    //         console.log("ResultIMG",result)
    //         if (!result.cancelled) {

    //             getImage = result.uri
    //             this.setState({
    //                 selectedTestReportImage: getImage
    //             })
    //         }

    //     } catch (E) {
    //         console.log(E);
    //     }
    // }

    openDocFolder = async (data) => {
        // this.setState({
        //   documentUploadSelectedButton:data
        // })
        try {
            let getImage;
            let result = await DocumentPicker.getDocumentAsync({
                type: 'image/*',
            });
            // console.log("Result",result)
            if (result.type === "success") {

                getImage = result.uri
                this.setState({
                    selectedTestReportImage: getImage
                })
            }

        } catch (E) {
            console.log(E);
        }
    }


    uploadTestReport() {
        this.setState({
            isSaveDisable:true
        })
        let service_url;
        // alert(this.state.userType)
        if (this.state.userType === Constants.ROLE_FO) {
            service_url = Constants.TEST_REPORT_UPLOAD_API_FO;
        } else {
            service_url = Constants.TEST_REPORT_UPLOAD_API_DR;
        }

        var reportDate = this.state.selectedTestReportDate ? moment(this.state.selectedTestReportDate).format("YYYY-MM-DD") : ""
        // report_type  = Laboratory (or) Radiology (or) Special
        const data = new FormData();
        data.append('report_photo', this.state.selectedTestReportImage ? this.dataURItoBlob(this.state.selectedTestReportImage) : "")
        data.append('report_date', reportDate)
        data.append('report_type', this.state.selectedTestReportType)
        data.append('comment', this.state.comment)

        if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {
            data.append('patient_id', this.state.selectedIpPatientDetails.patient_id);
            data.append('appointment_id', this.state.selectedIpPatientDetails.appointment_id);

        } else {
            data.append('patient_id', this.state.patient_id);
            data.append('appointment_id', this.state.appointment_id);
        }
        // TEST_REPORT_UPLOAD_API_DR

        // alert(JSON.stringify(this.state.doctor_id))
        if (this.state.userType === Constants.ROLE_FO) {
            data.append('doctor_id', this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP ? this.state.selectedIpPatientDetails.doctor_id : this.state.doctor_id);
        }
        OpthamologyService.getInstance().documentUploadPost(
            service_url,
            data,
            this,
            this.uploadTestReportSuccess,
            this.uploadTestReportFailure,
            'multipart/form-data',
        );
    }

    uploadTestReportSuccess = success => {
        if (success && success.data.status && success.data.status == "success") {
            // alert(success.data.message);
            this.setState({
                isSaveDisable:false
            })
            this.setState({
                showUploadTestReportFlag: false,
                selectedTestReportDate: '',
                selectedTestReportImage: '',
                selectedTestReportType: '',
                comment: ''
            }, () => {
                this.getTestReport();
                this.props.showResposeValue("success", success.data.message)
            })
        } else {
            this.setState({
                isSaveDisable:false
            })
            this.props.showResposeValue("error", success.data.message)

            // alert(success.data.message)
        }
       // console.log("success *******> " + JSON.stringify(success))
    }
    uploadTestReportFailure = error => {
        this.setState({
            isSaveDisable:false
        })
        this.props.showResposeValue("error", error.data.message)

        console.log("error *******> " + JSON.stringify(error))
    }

    openTransmittedTestReport(reportId){

      var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : ""
      var LastDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : ""
      let { userType } = this.state;
      let service_url = ""
      
      if(Constants.ROLE_FO == this.state.userType) {

        service_url = Constants.FO_TEST_REPORT + "?patient_id="+ this.state.patient_id + "&lab_id=" + reportId + "&export_type=pdf";

      } else if( userType === "OT" ){
        let patient_id = this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : ""
        let doctor_id = this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : ""

        service_url = Constants.OT_TEST_REPORT+ "?patient_id=" + patient_id + "&doctor_id=" + doctor_id + "&start_date=" + startDate + "&end_date=" + LastDate + "&export_type=pdf";
      }
      else {
        var patient_id = this.state.patient_id ? this.state.patient_id : this.state.otPatientDetails.patient_id
        var clinic_id = this.state.selectedClinicOrDoctor.id ? this.state.selectedClinicOrDoctor.id : this.state.clinicId
        // service_url = Constants.TEST_REPORT + "?patient_id=" + patient_id + "&clinic_id=" + clinic_id + "&lab_id=" + reportId + "&export_type=pdf";
        service_url = Constants.TEST_REPORT_PRINT + "?appointment_id=" + this.props.appointment_id;
      }

        OpthamologyService.getInstance().documentUploadGet(
            service_url,
            this.getTestReportPDFSuccess,
            this.getTestReportPDFFailure,
            "pdf"
        );
    }

    getTestReportPDFSuccess = (response) => {
        if (response) {
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
          }
        // if (response.status == "success") {
        //      console.log(JSON.stringify(response.data))
        // }else{
        //     console.log(response.message);
        // }
    }


    getTestReportPDFFailure = (response) => {
        console.log(response.message);
    }
    renderReportHeader = () => {
        return(
            <View style={{ flex: 1, width: "100%" }}>
              { Constants.ROLE_FO === this.state.userType ?  
                <CommonHistorySectionHeader
                    heading1={'Date'}
                    heading2={'Report Type'}
                    heading3={'Doctor Name'}
                    heading4={'Test Details'}
                    heading5={'Action'}
                    noOfColumn={5}
                    columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}
                /> :
                 <CommonHistorySectionHeader
                    heading1={'Date'}
                    heading2={'Report Type'}
                    heading3={'Test Details'}
                    heading4={'Action'}
                    noOfColumn={4}
                    columnSize={[0.25, 0.25, 0.25, 0.25]}
                /> 
              }
            </View>

        )
    }
     onPressView  = async (imageData) => {
            var tempArr = []
            for (let i = 0; i < imageData.length; i++) {
                var image_urls = imageData[i].result_img_url
                var img_id = imageData[i].lab_img_billing_id
                var getData = await this.getS3Key(image_urls, img_id)
                tempArr.push(getData)
            }
            this.setState({
                covertedToImageData: tempArr
            },() => {
                this.props.showDocumentImage(true, this.state.covertedToImageData[0].doc_key, this.state.covertedToImageData)
            })
    }
    render() {
        return (
            <View style={styles.container}>

                <View style={styles.TestreportContainer}>

                    <View>
                        <Text style={{ color: color.themeDark, fontSize: '1.2vw', fontWeight: '500', margin: '0.6vw' }}>
                            Test Report
                    </Text>
                    </View>
                    {/* <View style={{ flex: 1, justifyContent: 'space-between' }}> */}
                    <View>
                        <View>
                            {this.renderReportHeader()}
                        </View>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.6 }} >
                            {
                                this.state.getListOfTestReports.length > 0 && this.state.getListOfTestReports.map((test, index) => {
                                    return (
                                        <View style={{flex:1}}>
                                            {Constants.ROLE_FO === this.state.userType ?
                                                <View style={[styles.TableCotainerView, { borderBottomColor: color.lightGray }]}>
                                                    <Text style={[styles.TableContentText, { marginHorizontal: '1.2vw' }]}>{moment(test.report_date).format("DD-MM-YYYY")}</Text>
                                                    <Text style={styles.TableContentText}> {test.report_type} </Text>
                                                    <Text style={styles.TableContentText}> {test.doctor_name} </Text>
                                                    <Tooltip placement="topLeft" title={test.test_name}>
                                                        <Text style={styles.TableContentText}> {test.test_name && test.test_name.length > 18 ? test.test_name.slice(0, 18) + ".." : test.test_name} </Text>
                                                    </Tooltip>
                                                    {test.file_name && (test.file_name).length > 0 ?
                                                        <TouchableOpacity style={{ flex: 0.2 }} onPress={() => {
                                                            this.onPressView(test.file_name)

                                                        }}>
                                                            <Text style={{color: color.themeDark, fontSize: '1vw'}}>View</Text>
                                                        </TouchableOpacity> :
                                                        <TouchableOpacity style={{ flex: 0.2, }} onPress={() => {
                                                            this.openTransmittedTestReport(test.id)
                                                        }}>
                                                            <Text style={{color: color.themeDark, fontSize: '1vw'}}>Print</Text>
                                                        </TouchableOpacity>
                                                    }
                                                </View> :
                                                <View style={styles.TableContentView1}>
                                                    <Text style={{ flex: 0.25, marginLeft: '1vw', fontSize: '1vw' }}>{moment(test.report_date).format("DD-MM-YYYY")}</Text>
                                                    <Text style={{ flex: 0.25, marginLeft: '2vw', fontSize: '1vw' }}> {test.report_type} </Text>
                                                    <Tooltip placement="topLeft" title={test.test_name}>
                                                        <Text style={{ flex: 0.25, fontSize: '1vw' }}> {test.test_name && test.test_name.length > 18 ? test.test_name.slice(0, 18) + ".." : test.test_name} </Text>
                                                    </Tooltip>
                                                    {test.file_name && (test.file_name).length > 0 ?
                                                        <TouchableOpacity style={{ flex: 0.25 }} onPress={() => {
                                                            this.onPressView(test.file_name)
                                                        }}>
                                                            <Text style={{color: color.themeDark, fontSize: '1vw'}}>View</Text>
                                                        </TouchableOpacity> :
                                                        (test.status === "Result Completed" || test.status === "Result Delivered") ?
                                                        <TouchableOpacity style={{ flex: 0.25 }} onPress={() => {
                                                            this.openTransmittedTestReport(test.id)
                                                        }}>
                                                            <Text style={{color: color.themeDark, fontSize: '1vw'}}>Print</Text>
                                                        </TouchableOpacity> : 
                                                        <Text style={{flex: 0.25, fontSize: '1vw'}}>Result In Progress</Text>
                                                    }
                                                </View>}
                                        </View>
                                    )
                                })
                            }
                        </ScrollView>
                    </View>
                    { this.state.userType != "OT" && this.state.appointment_id ?
                    <View style={{ alignSelf: 'center', marginTop: 10 }}>

                        {
                            !this.state.showUploadTestReportFlag?
                                <TouchableOpacity disabled={this.state.isIpPatientCheckouted} onPress={() => { this.setState({ showUploadTestReportFlag: true }) }} style={{ borderRadius: 8, backgroundColor: color.themeDark, paddingHorizontal: 10, paddingVertical: 2, justifyContent: "center" }}>
                                    <Text style={styles.AddBtn}>+</Text>
                                </TouchableOpacity> :
                              <View>
                                <View style={styles.AddTblData}>
                                   

                                    <DatePicker
                                        disabledDate={current => {
                                            return current && current > moment().add(0, "days");
                                        }}
                                        clearIcon={false}
                                        onChange={(date) => {
                                            this.setState({ selectedTestReportDate: date });
                                        }}
                                        defaultValue={""}
                                        placeholder={"Select Date"}
                                        format="DD/MM/YYYY"
                                        value={this.state.selectedTestReportDate}
                                        style={{
                                            zIndex: -1, width: "85%", borderColor: "#CDD1D5", height: '2.3vw',
                                            borderRadius: '0.6vw', borderWidth: 1, marginRight: '2vw', fontSize: '1vw'
                                        }}
                                    />

                                    <Picker
                                        style={styles.PickerView}
                                        itemStyle={{}}
                                        onValueChange={(itemvalue) => {
                                            this.setState({ selectedTestReportType: itemvalue });
                                        }}
                                    >
                                        <Picker.Item label={"Report Type"} value={""} />
                                        <Picker.Item label={"Laboratory"} value={"Laboratory"} />
                                        <Picker.Item label={"Radiology"} value={"Radiology"} />
                                        <Picker.Item label={"Special Test"} value={"Special"} />

                                    </Picker>

                                    {/* <TouchableOpacity onPress={() => { this.openImageFolder() }} style={{ marginRight: 30, borderRadius: 8, backgroundColor: color.themeDark, paddingHorizontal: 15, height: 35, justifyContent: 'center' }}>
                                        <Text style={{ fontSize: 12, color: 'white', fontWeight: 'bold' }}>Add Image</Text>
                                    </TouchableOpacity> */}
                                     <TouchableOpacity onPress={() => {
                                         this.setState({
                                             commentEnable: !this.state.commentEnable
                                         })
                                        
                                        }} style={[styles.BtnView, {backgroundColor: color.themeDark}]}>
                                        <Text style={styles.BtnText}>{"+ Comment"}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => { this.openDocFolder() }} style={[styles.BtnView, {backgroundColor: color.themeDark}]}>
                                        <Text style={styles.BtnText}>{"Upload"}</Text>
                                    </TouchableOpacity>
                                    <View>
                                    </View>
                                    {
                                        this.state.selectedTestReportImage ?
                                            <Image source={{ uri: this.state.selectedTestReportImage }} style={styles.TestImage} /> : null
                                    }
                                </View>
                                <View style={styles.CmtTextInputView}>
                                        {this.state.commentEnable ?
                                              <TextInput
                                                  maxLength={250}
                                                  multiline={true}   
                                                  placeholder={""} value={this.state.comment}
                                                  onChangeText={(text) => { this.setState({ comment: text }) }}
                                                  style={styles.CmtText}
                                              /> : null
                                        }

                                       </View> 
                                <View style={styles.SaveandCancelBtn}>
                                      <TouchableOpacity onPress={() => { this.uploadTestReport() }} disabled={this.state.isSaveDisable} style={[styles.SaveandCancelTouchBtn, { backgroundColor: this.state.isSaveDisable ? color.lightGray : color.themeDark }]}>
                                        <Text style={styles.SaveandCancelBtnText}>{"Save"}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => {
                                        this.setState({
                                            showUploadTestReportFlag: false,
                                            selectedTestReportDate: '',
                                            selectedTestReportImage: '',
                                            selectedTestReportType: '',
                                            comment:""
                                        })
                                    }} style={[styles.CancelTouchBtn, { borderColor: color.themeDark}]}>
                                        <Text style={[styles.CancelTextBtn, { color: color.themeDark }]}>{"Cancel"}</Text>
                                    </TouchableOpacity>
                                </View>
                           </View>
                        }




                    </View> : null}
                    {/* {
                        this.state.showUploadTestReportFlag ?
                            <Text style={{ color: "grey", marginTop: 10,textAlign:'center' }}>{"Only image files can be uploaded"}</Text>
                            : null} */}

                </View>
                {/* </View> */}

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flex: 1,
        // marginTop: 20,
        backgroundColor: color.sectionHeadingDarkColor,
        minHeight: '70vh',
        padding: (5, 10, 10, 5)
    },
    TestreportContainer: { 
        flex: 1, 
        // marginTop: '1.5vw', 
        // marginLeft: '1.2vw', 
        backgroundColor: "#fff", 
        padding: '1.2vw' 
    },
    TableCotainerView: {
        flexDirection: 'row', 
        flex: 1, 
        borderBottomWidth: 2, 
        paddingVertical: '1.2vw'
    },
    TableContentText: {
        flex: 0.2,
        fontSize: '1vw'
    },
    TableContentView1: { 
        flexDirection: 'row', 
        flex: 1, 
        borderBottomColor: '#F2F2F2', 
        borderBottomWidth: 2, 
        paddingVertical: '1.2vw' 
    },
    AddBtn: { 
        fontSize: '2vw', 
        color: 'white', 
        fontWeight: 'bold'
    },
    AddTblData: { 
        flexDirection: 'row', 
        marginTop: 10, 
        alignItems: 'center' 
    },
    PickerView: { 
        borderRadius: '1.2vw', 
        height: '2vw', 
        marginRight: '2vw', 
        paddingLeft: '0.6vw', 
        fontSize: '0.8vw', 
        backgroundColor: "transparent" 
    },
    BtnView: { 
        marginRight: '2vw', 
        borderRadius: '0.5vw', 
        paddingHorizontal: '1vw', 
        height: '2.3vw', 
        justifyContent: 'center' 
    },
    BtnText: { 
        fontSize: '0.8vw', 
        color: 'white', 
        fontWeight: 'bold' 
    },
    TestImage: { 
        height: '3.4vw', 
        width: '2.5vw', 
        borderRadius: 4, 
        border:"1px solid grey" 
    },
    CmtTextInputView: {
        paddingVertical: '1.2vw', 
        justifyContent:"center",
        alignItems: "center"
    },
    CmtText: { 
        paddingLeft: '0.6vw', 
        borderColor: "#00CED1", 
        width: "80%", 
        height: '4vw', 
        borderWidth: 2, 
        marginTop: '0.5vw', 
        borderRadius: 4, 
        paddingVertical: '0.6vw',
        fontSize: '1vw'
    },
    SaveandCancelBtn: {
        marginTop:'0.6vw', 
        flexDirection:"row", 
        alignItems:"center", 
        justifyContent: "center"
    },
    SaveandCancelTouchBtn: { 
        marginRight: '2vw', 
        borderRadius: '0.5vw', 
        paddingHorizontal: '2vw', 
        height: '2.3vw', 
        justifyContent: 'center' 
    },
    SaveandCancelBtnText: { 
        fontSize: '0.8vw', 
        color: 'white', 
        fontWeight: 'bold' 
    },
    CancelTouchBtn: { 
        marginRight: '2vw', 
        justifsyContent: 'center', 
        borderRadius: '0.5vw', 
        borderWidth: 1, 
        paddingHorizontal: '1vw', 
        height: '2.3vw' 
    },
    CancelTextBtn: { 
        fontSize: '0.8vw', 
        fontWeight: 'bold' 
    }
});

//make this component available to the app
export default TestReport;
