import React, { Component } from "react";
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet } from "react-native";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';
import ImagePath from "../../../utils/ImagePaths";
import { NavigationTopHeader } from "../BaseComponent";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";


let DefaultSplitupsNewEntry = {
    "description": "",
    "units": "",
    "rate": "",
    "total_amount": ""
}

export default class PackageConfig extends Component {
    constructor(props) {
        super(props);
        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {} : JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};

        this.state = {
            selectedTab: "PackageConfig",
            splitups: false,
            SurgeryID: "",
            SurgeryName: "",
            PackageName: "",
            PackageAmount: "",
            splitupID: "",
            patientSplitupsNewEntry: [DefaultSplitupsNewEntry],
            PackageDetailError: "",
            DeletePopup: false,
            PackageDetailList: [],
            PackageSplitupList: [],
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
            ListOpen: false,
            searchSurgeryList: [],
            selectedDropDownIndex: null,
            selectedSplitupsList: [],
            PackageID: "",
            splitPackageID: "",
            selectedSurgeryID: "",
            PackandSplitID: "",
            isEdit: false,
            searchPackage: "",
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ? false : true,
            userType:this.props.userType
        }
    }

    componentDidMount() {
        this.getPackageConfig()
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }


    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    letterValidation(num) {
        return /^[a-zA-Z]*$/g.test(num)
    }

    alphabetvalidationWithMajorSplChar(num) {
        return /^[0-9 a-z A-Z.,_-]+$/.test(num);
    }

    addTotalAmount = () => {
        const Total_Package_Amount = this.state.patientSplitupsNewEntry.map(item => item.total_amount == "" ? 0 : parseInt(item.total_amount)).reduce((prev, curr) => prev + curr, 0)
        this.setState({
            PackageAmount: Total_Package_Amount
        })
    }

    renderTextBox = (label, key) => {
        var states = this.state
        return (
            <View style={styles.renderTextBoxContainer}>
                <Text style={styles.renderTextBoxText}>{label}<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                <TextInput
                    editable={key === "PackageAmount" ? false : true}
                    maxLength={key == "PackageAmount" ? 6 : ""}
                    value={states[key]}
                    style={styles.TextInputStyles}
                    onChangeText={(text) => {
                        if ((key != "SurgeryName") && (key != "PackageName")) {
                            var isValid = this.numberValidation(text)
                            if (isValid || text === "") {
                                states[key] = text
                                this.setState({ states })
                            }
                        } else {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </View>
        )
    }

    onPressClearNewEntry = (index) => {

        var showList = this.state.patientSplitupsNewEntry;
        showList.splice(index, 1)
        this.setState({
            defaultReturn: true,
            showList
        }, () => {
            this.addTotalAmount()
            setTimeout(() => {
                this.setState({ defaultReturn: false, });
            }, 500);
        })
    }

    checkAllDetailsAreAvailable = (newEntry, key = "") => {
        var defaultReturn = true
        for (const data of newEntry) {

            {
                if (data.description || data.description === "0") {
                    // Do Nothing
                } else {
                    defaultReturn = false
                    return defaultReturn
                }
            }
            {
                if (data.units || data.units === "0") {
                    // Do Nothing
                } else {
                    defaultReturn = false
                    return defaultReturn
                }
            }
            {
                if (data.rate || data.rate === "0") {
                    // Do Nothing
                } else {
                    defaultReturn = false
                    return defaultReturn
                }
            } {
                if (data.total_amount || data.total_amount === "0") {
                    // Do Nothing
                } else {
                    defaultReturn = false
                    return defaultReturn
                }
            }
        }
        return defaultReturn
    }

    // Get Package Details API Start // 

    getPackageConfig = () => {
        var url = Constants.CLINIC_PACKAGE_DETAILS + "?package_name=" + this.state.searchPackage

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getPackageConfigSuccess,
            this.getPackageConfigFailure
        );
    }

    getPackageConfigSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                PackageDetailList: response.data,
                selectedDropDownIndex: null,
                selectedSplitupsList: []
            }, () => {
                this.onPressClear()
            })
        } else {
            console.log("error")
        }
    }

    // Get Package Details API Ends // 

    // Post Package Details API Starts //

    PostPackageDetails = () => {
        var states = this.state
        var url = Constants.CLINIC_PACKAGE_DETAILS
        var checkTestField = this.checkAllDetailsAreAvailable(states.patientSplitupsNewEntry)

        if (states.SurgeryName === "" || states.PackageName === "" || states.PackageAmount === "") {
            this.setState({ PackageDetailError: "Package Details Should Be Mandatory" })
        } else if (!checkTestField) {
            this.setState({ PackageDetailError: "Package Splitups Should Be Mandatory" })
        } else {
            var lineItems = []
            var typedata = states.patientSplitupsNewEntry
            for (let i = 0; i < typedata.length; i++) {
                if (typedata[i].description) {
                    lineItems.push({ description: typedata[i].description, units: parseInt(typedata[i].units), rate: parseInt(typedata[i].rate), total_amount: parseInt(typedata[i].total_amount) })
                } else {
                    console.log("error")
                }
            }

            var data =
            {
                surgery_id: states.selectedSurgeryID === "" ? states.SurgeryName : states.selectedSurgeryID,
                package_name: states.PackageName,
                package_amount: parseInt(states.PackageAmount),
                package_line_items: lineItems
            }
            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.PackageDetailsPostSuccess,
                this.PackageDetailsPostFailure
            );
        }
    }

    PackageDetailsPostSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getPackageConfig()
            this.setState({
                defaultReturn: true
            }, () => {
                setTimeout(() => {
                    this.setState({ defaultReturn: false, });
                }, 500);
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    // Post Package Details API Ends //

    editPackageDeatils = (data) => {

        this.setState({
            SurgeryID: data.id,
            selectedSurgeryID: data.surgery_id,
            SurgeryName: data.surgery_name,
            PackageName: data.package_name,
            PackageAmount: data.package_amount,
            patientSplitupsNewEntry: data.package_line_items,
            PackageDetailError: ""
        }, () => { this.addTotalAmount() })
    }

    // Update Package Details API Starts //

    updatePackageDetails = () => {
        var states = this.state
        var url = Constants.CLINIC_PACKAGE_DETAILS
        var checkTestField = this.checkAllDetailsAreAvailable(states.patientSplitupsNewEntry)

        if (states.SurgeryName === "" || states.PackageName === "" || states.PackageAmount === "") {
            this.setState({ PackageDetailError: "Package Details Should Be Mandatory" })
        } else if (!checkTestField) {
            this.setState({ PackageDetailError: "Package Splitups Should Be Mandatory" })
        } else {
            var lineItems = []
            var typedata = states.patientSplitupsNewEntry
            for (let i = 0; i < typedata.length; i++) {
                console.log(this.state.splitupID[i].id)
                if (typedata[i].description) {
                    lineItems.push({ id: this.state.splitupID[i].id, description: typedata[i].description, units: parseInt(typedata[i].units), rate: parseInt(typedata[i].rate), total_amount: parseInt(typedata[i].total_amount) })
                } else {
                    console.log("error")
                }
            }

            var data =
            {
                id: states.SurgeryID,
                surgery_id: states.selectedSurgeryID,
                package_name: states.PackageName,
                package_amount: parseInt(states.PackageAmount),
                package_line_items: lineItems
            }
            OpthamologyService.getInstance().putMethod(
                url,
                data,
                this,
                this.UpdatePackageSuccess,
                this.UpdatePackageFailure,
            );
        }
    }

    UpdatePackageSuccess = (response) => {
        if (response.status == "success") {
            this.props.showResposeValue("success", response.message)
            this.getPackageConfig()
            this.onPressClear()
            this.setState({ isEdit: false })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    // Update Package Details API Ends //

    //-- Delete API starts --//

    deletePackageDetails = () => {
        var states = this.state
        var url = ""

        if (states.PackageID != "") {
            url = Constants.CLINIC_PACKAGE_DETAILS + "?id=" + this.state.PackageID
        } else {
            url = Constants.CLINIC_PACKAGE_DETAILS + "?line_id=" + this.state.splitPackageID
        }

        var data = {
            id: states.PackandSplitID
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            data,
            this,
            this.deletePackageDetailsSuccess,
            this.deletePackageDetailsFailure
        )
    }

    deletePackageDetailsSuccess = (response) => {
        if (response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getPackageConfig()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }

    // Delete API Ends //

    // -- Auto Suggestion API Starts --//

    getSurgeryList = () => {
        var serviceUrl = Constants.SURGERY_NAME_LIST + "?surgery_key=" + this.state.SurgeryName;
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getSurgeryListSuccess,
            this.getSurgeryListFailure
        );
    }

    getSurgeryListSuccess = success => {
        if (success.status == "success") {
            this.setState({
                searchSurgeryList: success.data.surgery_search
            })
        }
    }

    getSurgeryListFailure = error => {
        console.log("err GetSurgeryListSuccess" + JSON.stringify(error))
    }

    // -- Auto Suggestion API Ends --//

    onPressClear = () => {
        let DefaultSplitupsNewEntry = {
            "description": "",
            "units": "",
            "rate": "",
            "total_amount": ""
        }
        this.setState({
            defaultReturn: true,
            SurgeryName: "",
            PackageName: "",
            PackageAmount: "",
            PackageDetailError: "",
            patientSplitupsNewEntry: [DefaultSplitupsNewEntry],
            selectedSurgeryID: ""
        })
    }

    onPressPackageSplitups = () => {
        let { patientSplitupsNewEntry } = this.state
        let defaultPackageSplitup = {
            "description": "",
            "units": "",
            "rate": "",
            "total_amount": "",
        }
        if (patientSplitupsNewEntry.length > 0) {
            var checkTestField = this.checkAllDetailsAreAvailable(patientSplitupsNewEntry)
            if (checkTestField) {
                patientSplitupsNewEntry.push(defaultPackageSplitup)
            } else {
                // Do nothing
            }
        } else {
            patientSplitupsNewEntry.push(defaultPackageSplitup)
        }
        this.setState({ patientSplitupsNewEntry })
    }

    renderSplitupsNewEntryView = (newEntry, index) => {
        let description = null
        let Unit = null
        let Rate_Units = null
        let amount = null
        let id = null
        let { patientSplitupsNewEntry } = this.state

        description = patientSplitupsNewEntry[index] ? patientSplitupsNewEntry[index].description : ""
        Unit = patientSplitupsNewEntry[index] ? patientSplitupsNewEntry[index].units : ""
        Rate_Units = patientSplitupsNewEntry[index] ? patientSplitupsNewEntry[index].rate : ""
        amount = patientSplitupsNewEntry[index] ? patientSplitupsNewEntry[index].total_amount : ""
        id = patientSplitupsNewEntry[index] && patientSplitupsNewEntry[index].id ? patientSplitupsNewEntry[index].id : null

        return (
            <View style={styles.splitupsContainer}>
                <View style={[styles.splitupsWrapper, {
                    backgroundColor: index != 0 && id === null ? color.white : "", borderRadius: index != 0 && id === null ? 5 : "", shadowOffset: index != 0 && id === null ? { width: 0, height: 1 } : "",
                    shadowColor: index != 0 && id === null ? color.grayShade : "", shadowOpacity: index != 0 && id === null ? 1 : "", shadowRadius: index != 0 && id === null ? 2 : ""
                }]}>
                    <View style={[styles.splitupsTextContainer, { marginRight: "2vw" }]}>
                        <View style={styles.renderTextBoxContainer}>
                            <Text style={styles.renderTextBoxText}>Description<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                            <TextInput
                                value={description}
                                style={styles.TextInputStyles}
                                onChangeText={(text) => {
                                    var isValid = this.alphabetvalidationWithMajorSplChar(text)
                                    if (isValid || text === "") {
                                        patientSplitupsNewEntry[index]["description"] = text
                                        this.setState({ patientSplitupsNewEntry })
                                    }
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.splitupsTextContainer}>
                        <View style={styles.renderTextBoxContainer}>
                            <Text style={styles.renderTextBoxText}>Units<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                            <TextInput
                                value={Unit}
                                maxLength={6}
                                style={styles.TextInputStyles}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        patientSplitupsNewEntry[index]["units"] = text
                                        this.setState({ patientSplitupsNewEntry })
                                    }
                                }}
                            />
                        </View>
                    </View>
                    <View style={[styles.splitupsTextContainer, { marginRight: "2vw" }]}>
                        <View style={styles.renderTextBoxContainer}>
                            <Text style={styles.renderTextBoxText}>Rate/Units<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                            <TextInput
                                value={Rate_Units}
                                maxLength={6}
                                style={styles.TextInputStyles}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        patientSplitupsNewEntry[index]["rate"] = text
                                        this.setState({ patientSplitupsNewEntry })
                                    }
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.splitupsTextContainer}>
                        <View style={styles.renderTextBoxContainer}>
                            <Text style={styles.renderTextBoxText}>Total Amount<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                            <TextInput
                                maxLength={6}
                                value={amount}
                                style={styles.TextInputStyles}
                                onChangeText={(text) => {
                                    var isValid = this.numberValidation(text)
                                    if (isValid || text === "") {
                                        patientSplitupsNewEntry[index]["total_amount"] = text
                                        this.setState({ patientSplitupsNewEntry })
                                        this.addTotalAmount()
                                    }
                                }}
                            />
                        </View>
                    </View>
                    {index != 0 && id === null ?
                        <View style={styles.NewSplitUps}>
                            <TouchableOpacity onPress={() => { this.onPressClearNewEntry(index) }} >
                                <Image
                                    source={ImagePath.CancelIcon}
                                    style={{ height: "1.4vw", width: "1.4vw" }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                        :
                        null
                    }
                </View>
            </View>
        )
    }

    renderSurgeryAutoSearch = () => {
        return (

            <View style={{ zIndex: 2 }}>
                <Text style={styles.renderTextBoxText}>{"Surgery"}<span style={{ color: "red", fontSize: "1vw" }}>*</span></Text>
                <TextInput
                    value={this.state.SurgeryName}
                    style={styles.TextInputStyles}
                    onChangeText={(text) => {
                        var isValid = this.letterValidation(text)
                        if (isValid) {
                            this.setState({ SurgeryName: text }, () => {
                                this.getSurgeryList();
                                this.setState({ ListOpen: this.state.SurgeryName.length > 0 ? true : false, selectedServiceType: "" })
                            })
                        }
                    }}
                />
                {this.state.SurgeryName.length > 0 && this.state.ListOpen && this.state.searchSurgeryList.length > 0 ?
                    <ScrollView showsVerticalScrollIndicator={false} style={styles.AutoSuggestionStyle}>
                        {
                            this.state.searchSurgeryList.map(name =>
                                <TouchableOpacity
                                    style={{ marginTop: '.5vw', paddingLeft: '.8vw' }}
                                    onPress={event => {
                                        this.setState({ SurgeryName: name.surgery_name, ListOpen: false, selectedSurgeryID: name.id }, () => {
                                        })
                                    }
                                    }>
                                    <Text style={{ fontSize: '1vw' }}>
                                        {name.surgery_name}
                                    </Text>
                                </TouchableOpacity>
                            )
                        }
                    </ScrollView>
                    : null}
            </View>
        )
    }

    DeleteDialogBox = () => {
        return (
            <View>
                <View style={styles.CancelPopupContainer}>
                    <View style={styles.PopupHeaderConatiner}>
                        <View style={styles.PopupHeaderWrapper}>
                            <Text style={styles.HeaderTextStyle}>
                                Are You Sure ?
                            </Text>

                            <View style={styles.ButtonContainer}>
                                <TouchableOpacity style={[styles.AddButtonContainer, { marginRight: "1.5vw" }]} onPress={() => { this.deletePackageDetails(), this.setState({ DeletePopup: false }) }}>
                                    <Text style={[styles.TableBodyText, { color: color.white }]}>Yes</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.ClearButtonContainer, { backgroundColor: color.themeDark }]} onPress={() => { this.setState({ DeletePopup: false }) }}>
                                    <Text style={[styles.TableBodyText, { color: color.white }]}>No</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                    selectedTab={"packageConfig"}
                    isNavigationBorder={true} />
                <View>
                    <View style={styles.searchBoxContainer}>
                        <View style={styles.searchBoxWrapper}>
                            <TextInput
                                placeholder="Search Here..."
                                style={styles.searchBoxInput}
                                onChangeText={(text) => {
                                    var isValid = this.alphabetvalidationWithMajorSplChar(text)
                                    if (isValid || text === "") {
                                        this.setState({ searchPackage: text }, () => {
                                            this.getPackageConfig()
                                        })
                                    }
                                }}
                            />
                            <TouchableWithoutFeedback
                                style={styles.iconStyles}>
                                <Icon name="search" style={{ fontSize: "1.2vw" }} color={color.themeDark} />
                            </TouchableWithoutFeedback>
                        </View>
                    </View>
                    <View style={styles.MainBodyContainer}>
                        <View style={styles.leftContainer}>
                            <View style={{ width: "100%" }}>
                                <View style={[styles.TableTitleContainer]}>
                                    <View style={{ alignItems: "flex-start", width: '20%' }} >
                                        <Text style={styles.appointmentTableHeadText}>Surgery</Text>
                                    </View>
                                    <View style={{ alignItems: "flex-start", width: '35%' }} >
                                        <Text style={styles.appointmentTableHeadText}>Package Name</Text>
                                    </View>
                                    <View style={{ alignItems: "flex-end", width: '20%' }} >
                                        <Text style={styles.appointmentTableHeadText}>Package Amount</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '25%' }} >
                                        <Text style={styles.appointmentTableHeadText}>Action</Text>
                                    </View>
                                </View>
                            </View>

                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}>
                                {this.state.PackageDetailList.length > 0 ? this.state.PackageDetailList.map((item, index) => (
                                    <View>
                                        <View>
                                            <TouchableOpacity onPress={() => {
                                                var { PackageDetailList, selectedDropDownIndex } = this.state
                                                var Index = null
                                                if (selectedDropDownIndex === index) {
                                                    Index = null
                                                } else {
                                                    Index = index
                                                }
                                                this.setState({
                                                    selectedDropDownIndex: Index,
                                                    selectedSplitupsList: PackageDetailList[index].package_line_items
                                                })
                                            }} style={styles.TableBodyContainer}>
                                                <View style={{ width: "20%", alignItems: "flex-start" }}>
                                                    <Text style={styles.TableBodyText} >
                                                        {item.surgery_name}
                                                    </Text>
                                                </View>
                                                <View style={{ width: "35%", alignItems: "flex-start" }}>
                                                    <Text style={styles.TableBodyText} >
                                                        {item.package_name}
                                                    </Text>
                                                </View>
                                                <View style={{ width: "20%", alignItems: "flex-end" }}>
                                                    <Text style={styles.TableBodyText} >
                                                        {item.package_amount}
                                                    </Text>
                                                </View>
                                                <View style={[styles.actionButtonView]}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            var { PackageDetailList, selectedDropDownIndex } = this.state
                                                            var Index = null
                                                            if (selectedDropDownIndex === index) {
                                                                Index = null
                                                            } else {
                                                                Index = index
                                                            }
                                                            this.editPackageDeatils(item)
                                                            this.setState({
                                                                splitupID: PackageDetailList[index].package_line_items,
                                                                isEdit: true,
                                                                selectedDropDownIndex: null
                                                            })
                                                        }}
                                                        style={[styles.EditButton, { marginLeft: ".5vw" }]}>
                                                        <Tooltip placement="top" title={"Edit"}>
                                                            <Image
                                                                source={ImagePath.Edit}
                                                                style={{ height: '1.75vw', width: '1.75vw' }} />
                                                        </Tooltip>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        onPress={() => { this.setState({ DeletePopup: true, PackageID: item.id, PackandSplitID: item.id }) }}
                                                        style={[styles.DeleteButton, { marginLeft: "1vw" }]}>
                                                        <Tooltip placement="top" title={"Delete"}>
                                                            <Image
                                                                source={ImagePath.Delete}
                                                                style={{ height: '1.75vw', width: '1.95vw' }} />
                                                        </Tooltip>
                                                    </TouchableOpacity>
                                                </View>
                                                <TouchableOpacity style={styles.DropDownIcon} onPress={() => {
                                                    var { PackageDetailList, selectedDropDownIndex } = this.state
                                                    var Index = null
                                                    if (selectedDropDownIndex === index) {
                                                        Index = null
                                                    } else {
                                                        Index = index
                                                    }
                                                    this.setState({
                                                        selectedDropDownIndex: Index,
                                                        selectedSplitupsList: PackageDetailList[index].package_line_items
                                                    })
                                                }}>
                                                    <Image
                                                        source={ImagePath.DropDownIcon}
                                                        style={{ height: '1.8vw', width: '1.8vw' }}
                                                        resizeMode="contain"
                                                    />
                                                </TouchableOpacity>
                                            </TouchableOpacity>
                                        </View>

                                        {this.state.selectedDropDownIndex === index ?
                                            <View style={styles.splitupsContainerleft}>
                                                <View style={styles.splitupsWrapperleft}>
                                                    <View style={[styles.TableTitleContainer]}>
                                                        <View style={{ alignItems: "flex-start", width: '30%' }} >
                                                            <Text style={styles.appointmentTableHeadText}>Description</Text>
                                                        </View>
                                                        <View style={{ alignItems: "center", width: '20%' }} >
                                                            <Text style={styles.appointmentTableHeadText}>Units</Text>
                                                        </View>
                                                        <View style={{ alignItems: "flex-end", width: '20%' }} >
                                                            <Text style={styles.appointmentTableHeadText}>Rate/Units</Text>
                                                        </View>
                                                        <View style={{ alignItems: "flex-end", width: '30%' }} >
                                                            <Text style={styles.appointmentTableHeadText}>Total Amount</Text>
                                                        </View>
                                                    </View>
                                                    {this.state.selectedSplitupsList.length > 0 ? this.state.selectedSplitupsList.map((item) => (
                                                        <View style={styles.splitupsTableBody}>
                                                            <View style={{ alignItems: "flex-start", width: '30%' }}>
                                                                <Text style={styles.TableBodyText}>{item.description}</Text>
                                                            </View>
                                                            <View style={{ alignItems: "center", width: '20%' }}>
                                                                <Text style={styles.TableBodyText}>{item.units}</Text>
                                                            </View>
                                                            <View style={{ alignItems: "flex-end", width: '20%' }}>
                                                                <Text style={styles.TableBodyText}>{item.rate}</Text>
                                                            </View>
                                                            <View style={{ alignItems: "flex-end", width: '30%' }}>
                                                                <Text style={styles.TableBodyText}>{item.total_amount}</Text>
                                                            </View>
                                                        </View>
                                                    )) : null}
                                                </View>
                                            </View> : null}
                                    </View>
                                )) : <View>
                                    <Text style={styles.NoRecordsText}>No Records Found</Text>
                                </View>}
                            </ScrollView>

                        </View>
                        <View style={styles.rightContainer}>
                            <View style={{ height: "70vh" }} >
                                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}>
                                    <View style={styles.PackageDetailHeader}>
                                        <Text style={styles.PackageDetailText}>Package Details</Text>
                                    </View>
                                    <View style={styles.PackageDetailTextInput}>
                                        <View style={styles.PackageDetailTextInputWrapper}>
                                            <View style={styles.renderSurgeryAutoSearch}>
                                                {this.renderSurgeryAutoSearch()}
                                            </View>
                                            <View style={{ width: "14vw" }}>
                                                {this.renderTextBox("Package Name", "PackageName")}
                                            </View>
                                            <View style={{ width: "14vw" }}>
                                                {this.renderTextBox("Package Amount", "PackageAmount")}
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.PackageSplitupsHeader}>
                                        <Text style={styles.PackageDetailText}>Package Split-ups</Text>
                                        <TouchableOpacity onPress={() => { this.onPressPackageSplitups() }} >
                                            <Image
                                                source={ImagePath.AddIcon}
                                                style={styles.AddIconStyle}
                                                resizeMode="contain"
                                            />
                                        </TouchableOpacity>
                                    </View>
                                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={styles.SplitupScroll}>
                                        {this.state.patientSplitupsNewEntry.length > 0 && this.state.patientSplitupsNewEntry.map((newEntry, index) => {
                                            return this.renderSplitupsNewEntryView(newEntry, index)
                                        })}
                                    </ScrollView>
                                </ScrollView>
                                <View>
                                    <View style={styles.errorContainer}>
                                        {this.state.PackageDetailError.length > 0 ? <Text style={styles.errorText}>{this.state.PackageDetailError}</Text> : null}
                                    </View>
                                    <View style={styles.ButtonContainer}>
                                        <TouchableOpacity style={styles.AddButtonContainer} onPress={() => { this.state.isEdit == false ? this.PostPackageDetails() : this.updatePackageDetails() }}>
                                            <Text style={[styles.TableBodyText, { color: color.white }]}>Add</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.ClearButtonContainer} onPress={() => { this.onPressClear() }}>
                                            <Text style={[styles.TableBodyText, { color: color.themeDark }]}>Clear</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    {this.state.DeletePopup === true ?
                        <View style={{ position: "absolute", zIndex: 10 }}>
                            {this.DeleteDialogBox()}
                        </View>
                        : null}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    searchBoxContainer: {
        marginLeft: "1vw",
        width: "95%",
        height: "3.5vw",
        display: "flex",
        justifyContent: "center",
    },
    searchBoxWrapper: {
        flexDirection: "row",
        width: "14vw",
        height: "2.5vw",
        backgroundColor: color.white,
        borderColor: "rgb(204, 204, 179)",
        borderRadius: ".25vw",
        borderWidth: 1,
        paddingHorizontal: ".7vw"
    },
    searchBoxInput: {
        backgroundColor: color.white,
        color: "black",
        width: '11vw',
        fontSize: '.95vw',
        borderTopLeftRadius: ".25vw",
        borderBottomLeftRadius: ".25vw",
    },
    iconStyles: {
        display: "flex",
        backgroundColor: color.white,
        width: "2vw",
        borderTopRightRadius: ".25vw",
        borderBottomRightRadius: ".25vw",
        height: "2.3vw",
        alignItems: "center",
        justifyContent: "center"
    },
    MainBodyContainer: {
        width: "100%",
        padding: ".5vw",
        display: "flex",
        flexDirection: "row"
    },
    leftContainer: {
        width: "59.5%",
        backgroundColor: color.white,
        marginRight: ".5vw",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2,
        padding: ".5vw",
        height: "72vh"
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: color.appointmentTableHead,
        height: "3.25vw",
        paddingHorizontal: ".5vw"
    },
    appointmentTableHeadText: {
        fontSize: ".9vw",
        fontWeight: "500",
        textAlign: "center"
    },
    TableBodyContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3.7vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    TableBodyText: { fontSize: ".9vw" },
    actionButtonView: {
        display: "flex",
        flexDirection: "row",
        width: "25%",
        alignItems: "center",
        justifyContent: "center"
    },
    rightContainer: {
        width: "40%",
        backgroundColor: "rgb(248, 252, 255)",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 2,
        padding: ".5vw",
        height: "72vh"
    },
    ButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: ".2vw"
    },
    AddButtonContainer: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        height: "2vw",
        width: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "3vw"
    },
    ClearButtonContainer: {
        borderColor: color.themeDark,
        borderWidth: 1,
        borderRadius: 4,
        height: "2vw",
        width: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    CancelPopupContainer: {
        height: "41.1vw",
        width: "94vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    PopupHeaderConatiner: {
        backgroundColor: "white",
        width: "30%",
        borderRadius: "0.65vw",
        padding: "1.30vw",
        marginBottom: "6.83vw"
    },
    PopupHeaderWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    HeaderTextStyle: {
        color: "#505050",
        fontSize: "1.23vw",
        marginTop: "1vw",
        marginBottom: "1.5vw",
        fontWeight: '500'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw"
    },
    renderTextBoxText: {
        zIndex: 2,
        fontSize: "0.78vw",
        position: "absolute",
        marginLeft: "0.65vw",
        backgroundColor: "white",
        paddingHorizontal: ".32vw",
        color: color.black
    },
    TextInputStyles: {
        borderColor: color.black,
        borderRadius: 4,
        borderWidth: 1,
        width: "100%",
        height: "2.27vw",
        padding: "1vw",
        marginTop: "0.52vw",
        fontSize: ".9vw"
    },
    splitupsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: ".5vw"
    },
    splitupsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "31vw",
        height: "8vw",
        paddingHorizontal: ".5vw"
    },
    splitupsTextContainer: {
        width: "14vw"
    },
    NewSplitUps: {
        height: "1.4vw",
        width: "1.4vw",
        position: "absolute",
        marginLeft: "29.8vw",
        marginTop: "-.5vw"
    },
    PackageDetailHeader: {
        backgroundColor: "rgb(226, 226, 226)",
        paddingHorizontal: "2vw",
        height: "2.6vw",
        display: "flex",
        justifyContent: "center"
    },
    PackageDetailText: {
        fontSize: "1vw",
        fontWeight: 500
    },
    PackageDetailTextInput: {
        paddingVertical: "1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "1vw"
    },
    PackageDetailTextInputWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "95%",
        height: "8vw",
        paddingLeft: "1vw"
    },
    PackageSplitupsHeader: {
        backgroundColor: "rgb(226, 226, 226)",
        paddingHorizontal: "2vw",
        height: "2.6vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    AddIconStyle: {
        height: "2vw",
        width: "2vw",
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        shadowOpacity: 1,
        shadowRadius: 1,
    },
    errorContainer: {
        height: "1.75vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    errorText: {
        color: "red",
        fontSize: ".9vw",
        fontWeight: 500
    },
    SplitupScroll: {
        height: "14vw",
        overflow: "scroll"
    },
    NoRecordsText: {
        width: "100%",
        fontSize: "1.5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "13vw"
    },
    splitupsTableBody: {
        flexDirection: 'row',
        width: "100%",
        paddingHorizontal: ".5vw",
        paddingVertical: "0.5vw",
        height: "3vw"
    },
    AutoSuggestionStyle: {
        position: 'absolute',
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: "rgb(205, 209, 213)",
        marginTop: "2.8vw",
        maxHeight: '12vw',
        zIndex: 5,
        width: '14vw',
        borderRadius: 4
    },
    DropDownIcon: {
        marginLeft: "-2vw",
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    splitupsContainerleft: {
        width: "100%",
        paddingHorizontal: "1.5vw",
        paddingVertical: ".5vw"
    },
    splitupsWrapperleft: {
        width: "100%",
        borderBottomColor: "rgb(204, 204, 179)",
        borderWidth: 1,
        borderStyle: "solid",
        padding: ".5vw"
    },
    renderSurgeryAutoSearch: {
        marginRight: "2vw",
        width: "14vw",
        zIndex: 10,
        marginTop: ".7vw"
    }
});