import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  TouchableWithoutFeedback, Picker,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton, CommonSectionHeader } from '../BaseComponent'

import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "Patient Categories";

var Messages = require('../../../utils/InfoMessages')

const toastConfig = {
  'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{ color: "white", textAlign: "center", justifyContent: "center", marginTop: "9px" }}>Complaint Created Successfully</Text>
    </View>
  ),
  'error': () => { },
  'info': () => { },
  'any_custom_type': () => { }
}


export default class PatientCategoriesNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      userType: this.props.userType,
      isLocked: this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedCategory: "",
      favoriteSymptom: [],
      selectedDurationButton: {},
      complaints: "",
      isEdit: false,
      isEditDuration: false,
      newItem: '',
      newDuration: '',
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      durationMode: "1",
      presentingComplients: "",
      historyofpresent: "",
      symtomSuggestionList: [],
      isSymtomSuggestionView: false,
      SelectedValue: [],
      Category:{}
    };
  }




  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getSymptomsFrequent()
  }

  componentWillReceiveProps(props) {

    let { id, favoriteSymptom, selectedCategory } = this.state;
    var states = this.state

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {
          //  let selectedCategory = "";

          var selectDuration = "1";
          var selectedDurationButton = {};
         

          if(item.duration_mode){
            if(item.duration_mode === '1'){
              selectDuration = '1';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else if(item.duration_mode === '2'){
              selectDuration = '2';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else if(item.duration_mode === '3'){
              selectDuration = '3';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }else {
              selectDuration = '4';
              selectedDurationButton = { label: item.duration, value: item.duration };
            }
          }

          favoriteSymptom.map((favItem) => {
            if (favItem.label === item.symptom) {
              selectedCategory = favItem.value;            }
          })

          var symptomsName = item && item.symptom ? item.symptom : "";

          for (let i = 0; i < favoriteSymptom.length; i++) {
            if (favoriteSymptom[i].value == symptomsName) {
              var prepareChildren = { value: symptomsName, label: symptomsName }
              selectedCategory = symptomsName;
            } else {
              var prepareChildren = { value: symptomsName, label: symptomsName }
              favoriteSymptom.push(prepareChildren);
              selectedCategory = symptomsName;
            }

          }
          favoriteSymptom = this.removeDuplicate(favoriteSymptom)

          this.setState({
            selectedDurationButton: selectedDurationButton,
            durationMode: selectDuration,
            id: item.id,
            selectedCategory,
            favoriteSymptom,
            selectedDuration: item.duration,
            comments: item.symptom_desc,
            presentingComplients: item.presenting_complaints ? item.presenting_complaints : "",
            historyofpresent: item.presenting_complaints_history ? item.presenting_complaints_history : ""
          }, () => {
            // this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
    if(props.Deletecorporate){
      this.setState({
        ConfirmDelete : props.Deletecorporate
      })
    }else{
      this.setState({
        ConfirmDelete : false
      })
    }
  }

  // removeDuplicate(data) {
  //   var nameListJsonObject = data.map(JSON.stringify);
  //   var nameListUniqueSet = new Set(nameListJsonObject);
  //   var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
  //   return nameListUniqueArray;
  // }


  getSymptomsFrequent = () => {

    var service_url = Constants.PATIENT_CATAGORIES_DATA;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSymptomsFrequentSuccess,
      this.getSymptomsFrequentFailure
    );
  };

  getSymptomsFrequentSuccess = response => {
    if (response.status === "success") {

      let drugs = [];

      response.data.map((item) => {

        (item) ? drugs.push({ value: item.id, label: item.category }) : null;
      });

      var field = this.state;
      field["favoriteSymptom"] = drugs;
      this.setState({ field });

    }
  };

  getSymptomsFrequentFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;
    this.setState({ states });
  }

  triggerDurationAdd = () => {
    let states = this.state;
    states['isEditDuration'] = true;
    this.setState({ states });
  }

  addMedicationToList = () => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteSymptom } = this.state;
    let field = this.state;


      // favoriteSymptom.push({ value: newItem.trim(), label: newItem.trim() });

      // field["favoriteSymptom"] = favoriteSymptom;
      // field["selectedCategory"] = newItem.trim();
      // field["newItem"] = "";
      // field["isEdit"] = false;

      // this.setState({ field });
      let data = {
        "doctor_category" : newItem.replace(/\s+/g, ' ').trim()
        
      };
  
      if (newItem) {
        var service_url = Constants.PATIENT_CATAGORIES_DATA;
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.getCategoryDataSuccess,
          this.getCategoryDataFailure
        );
      } else {
        this.props.showResposeValue("error", "Enter New category")
      }

  }

  getCategoryDataSuccess = response => {

    if (response.status === "success") {
      this.getSymptomsFrequent()
      this.props.showResposeValue("success", response.message)

     

      this.setState({ 
        isEdit : false,
        newItem : ""
       });

      // // reload data
      // this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message)

    }

  };

  getCategoryDataFailure = error => {
    this.props.showResposeValue("error", error.message)

    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  addDurationToList = (event) => {

    // console.log(" addMedicationToList " + this.state.newDuration)

    let { newDuration, selectedDuration } = this.state;
    let field = this.state;

    if (newDuration) {

      selectedDuration.push({ value: newDuration, label: newDuration });

      // field["duration"] = duration;
      field["selectedDuration"] = newDuration;
      field["newDuration"] = "";
      field["isEditDuration"] = false;
      field["durationMode"] = '1'

      this.setState({ field });
    }
  }





  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue, index) => {
    if (item.value) {
      return (
        <SelectedButton
          //   {...otherProps}
          disable={this.state.isLocked}
          item={item}
          fill={fill}
          borderNeeded={borderNeeded}
          onPressItem={this.onPressAction.bind(this, selectedKey)}
          selected={selectedValue}
          closeable={selectedKey === "selectedCategory" ? true : false}
          onPressClose={this.onPressClose.bind(this)}
        />
      );
    }
  }

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states
    })
  }

  onPressClose = (value) => {
    
    this.setState({
      SelectedValue : value
    })
    this.props.DeletePopup(this.state.favoriteSymptom.find((item)=>(item.value === value))?.label, "PatientCategoryNew")
  }

  addSymptom = event => {

    // console.log(" add complaint ");
    // console.log(" add complaint " + this.state.selectedCategory);
    let states = this.state;
    var service_url = Constants.PATIENT_CATAGORIES_POST ;


    let data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "patient_category":this.state.favoriteSymptom.find((item)=>(item.value === this.state.selectedCategory))?.label,
      "category_id":this.state.selectedCategory,
      "appointment_id": this.state.patientAppointment.appointment_id,
    };

    if (this.state.selectedCategory !== "") {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    } else {
      this.props.showResposeValue("error", "Select the Category")
    }
  };


  getSymptomSuccess = response => {

    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)

      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message)

    }

  };

  getSymptomFailure = error => {
    this.props.showResposeValue("error", error.message)

    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  clearComplaint = event => {

    let states = this.state;
    states['selectedCategory'] = "";
    states['complaints'] = "";
    // "duration_type": 3,
    states['selectedDuration'] = "";
    states['comments'] = "";
    states["durationMode"] = "1"
    states["selectedDurationButton"] = {}
    states["id"] = ""

    this.setState({ states }, () => {
      this.props.clearEditedItem(pageName, {})
    });

  }

  // calculateDays() {
  //   // let getDurationType = parseInt(this.state.durationMode)
  //   let getSelectedDurationButton = parseInt(this.state.selectedDurationButton.value)

  //   // let getSelectedDuration = getDurationType * getSelectedDurationButton

  //   this.setState({
  //     // selectedDuration: getSelectedDuration
  //     selectedDuration:getSelectedDurationButton
  //   })

  // }


  onPressButton(key, selectedvalue) {
    
    if (key == "selectedDurationButton") {
      this.setState({
        selectedDurationButton: selectedvalue
      })
    }
  }

  symptomSuggestion = (search) => {

    if ((search).trim() !== "") {
      var service_url = Constants.SYMPTOMS_FREQUANT_GET + "?symptom=" + search;

      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.symptomSuggestionSuccess,
        this.symptomSuggestionFailure
      );
    }else{
      var fields = this.state;
      fields["symtomSuggestionList"] = [];
      fields["isSymtomSuggestionView"] = false;

      this.setState({
        fields
      });
    }
  }

  symptomSuggestionSuccess = (response) => {
    if(response.status == "success"){
      var fields = this.state;
      fields["symtomSuggestionList"] = response.data;
      fields["isSymtomSuggestionView"] = true

      this.setState({
        fields,
      });
    }
  }

  deleteSymptoms = () => {
    var serviceUrl = Constants.PATIENT_CATAGORIES_DATA + "?id=" + this.state.SelectedValue;
    var data = {}
    OpthamologyService.getInstance().deleteComplaints(
        serviceUrl,
        data,
        this,
        this.deleteSymptomsSuccess,
        this.deleteSymptomsFailure
    )
}

deleteSymptomsSuccess = (response) => {
    if (response.status == "success") {
        this.setState({
          selectedCategory: "",
          ConfirmDelete:false
        }, () => { this.getSymptomsFrequent(), this.props.ClosePopup() ,this.props.showResposeValue("success", response.message) })
    }
    else {
      this.props.showResposeValue("error", response.message)
    }

}
deleteSymptomsFailure = (error) => {
  this.props.showResposeValue("error", error.message)
}



  render() {
    var durationButton = []
    durationButton = this.state.durationMode ==='1'? Constants.DURATION_DAY : this.state.durationMode ==='2'? Constants.DURATION_WEEKS : this.state.durationMode === "3" ? Constants.DURATION_MONTHS : this.state.durationMode === "4" ? Constants.DURATION_YEAR : []

    return (

      <TouchableWithoutFeedback>

        <View>
          {this.renderSubHeadingBorder("Patient Categories", false, true)}
          <View style={styles.TableHead1}>
            <CommonSectionHeader heading={"Categories"} />
            {/* {this.renderTitleWithMultipleBtn("Symptom's Name", false, 1, false, 'selectedApplanation',)} */}
          </View>
          {/* <FlatList
          data={this.state.favoriteSymptom}
          numColumns={4}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedCategory', this.state.selectedCategory )}
        //   extraData={this.state.selectedCategory}
        /> */}

          <View style={{flexWrap:'wrap'}}>

          <View style={styles.selectedCategorys}>
            {
              this.state.favoriteSymptom && this.state.favoriteSymptom.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedCategory', this.state.selectedCategory)
              })
            }
          </View>

          {this.state.isEdit ?
            <View
              style={[{
                marginLeft: '1.2vw',
                height: '2vw',
                marginTop: '0.6vw',
              }, Style.allButtonBorderRadius]}
            >
              <View style={{flexDirection: "row", flex: 1}}>
              <View style={{flex: 0.8}}>
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.SelectSymptomsTextInput, {color: color.black, borderColor: color.black }]}
                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newItem}
                onChangeText={text =>{
                  this.setState({ selectedCategory: '', newItem: text })}}
              />
              </View>
              <TouchableOpacity 
            style={[styles.SelectSymptomsSaveBtn, {backgroundColor: color.themeDark, borderColor: color.black }]}
            onPress={() => this.addMedicationToList()}
            >
              <Text style={styles.SelectSymptomsSaveBtnText}>{"Save"}</Text>
            </TouchableOpacity>
              </View>
              </View> :
            <View style={styles.AddNewBtnView}>{
              this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this), this.state.isLocked)
            }</View>
          }
            </View>



          <View style={{ marginTop: '1.2vw', alignSelf: "center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addSymptom.bind(this)} >
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View >
          {this.state.ConfirmDelete ? this.deleteSymptoms() : null}
          {/* <View>
              {this.state.show ? (
                <View style={{ height:40, width: '100%', backgroundColor: 'green',alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>Symptom Created Successfully</Text>
                </View>
              ) :null}
        </View > */}
          {/* <View>
        {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Symptom Created Successfully' : 
                    this.state.errorMessageFlag ?'Symptom History Failed' : null}</Text>
                </View>
              ) :null}
        </View > */}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
const styles = StyleSheet.create({
      TableHead1: { 
        marginBottom: '0.6vw' 
      },
      selectedCategorys: { 
        flex: 1, 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        paddingHorizontal: '0.6vw' 
      },
      SelectSymptomsTextInput: {
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        borderWidth: 1,
        borderRadius: 4,
        fontSize: '1vw'
      },
      SelectSymptomsSaveBtn:{
        flex:0.2,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderRadius: 4,
        marginLeft: '0.5vw'
      },
      SelectSymptomsSaveBtnText: {
        fontSize: '1vw'
      },
      AddSymptoms: { 
        position:'absolute', 
        width: "80%", 
        marginTop: '2vw', 
        padding: '0.6vw', 
        maxHeight: '11.5vw', 
        borderRadius: 4,
        borderWidth: 2
      },
      AddNewBtnView: { 
        marginLeft: '1.2vw' 
      },
      DurationView: { 
        flexDirection: "row", 
        marginTop: '0.6vw', 
        marginBottom: '1.2vw', 
        justifyContent: "space-between", 
        zIndex: -1, 
        backgroundColor: "#E2E2E2" 
      },
      DurationContainer: { 
        width: "30%", 
        borderWidth: 1, 
        borderRadius: 5, 
        margin: '0.6vw' 
      },
      DurationBtnView: { 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        zIndex: -1, 
        marginLeft: '1.2vw'
      },
      DurationBtnreturnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw'
      },
      DescriptionView: { 
        marginTop: '0.6vw', 
        marginHorizontal: '1.2vw', 
      }
})