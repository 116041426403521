import React, { Component } from "react";
import { View, StyleSheet, Text, Picker, TouchableOpacity, ScrollView  } from "react-native";
import CommonDateFilter, { NavigationTopHeader } from "../../BaseComponent";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import Export from "react-native-vector-icons/FontAwesome";
import Pagination from "../../common/Pagination";
import Icons from "react-native-vector-icons/FontAwesome";
import { Constants } from './../../../../utils/Constants';
import OpthamologyService from './../../../../network/OpthamologyService';
export class AllBillingStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranch: "all",
      allBillingList: [],
      allBillListAfterPagination: [],
      current_page: 1,
      records_per_page: 10,
      billTypeSort: "",
      billNoSort: "",
      branchList: [],
      originalData: [],
      userType: this.props.userType,
      fromDate: this.props.startDate,
      toDate: this.props.endDate
    };
  }
  componentDidMount(){
    this.getAllBillingTransaction()
  }
  componentDidUpdate(prevProps, prevState) {
    if( prevProps.startDate != this.props.startDate ||  prevProps.endDate != this.props.endDate || prevProps.selectedBranch != this.props.selectedBranch ){
      this.setState({
        fromDate: this.props.startDate,
        toDate: this.props.endDate,
        selectedBranch: this.props.selectedBranch
      },() => {
        this.getAllBillingTransaction()
      })
    }
  }
  getAllBillingTransaction = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      allBillListAfterPagination: [],
      allBillingList: [],
      originalData: []
    })
    if(this.state.userType === "SUPER_ADMIN"){
      var url = Constants.GET_BILLING_TRANSACTION + "?branch_id=" + this.state.selectedBranch + "&bill_type=all"+ "&start_date=" + this.state.fromDate + "&end_date=" + this.state.toDate  
    } else{
      var url = Constants.GET_BILLING_TRANSACTION + "?bill_type=all"+ "&start_date=" + this.state.fromDate + "&end_date=" + this.state.toDate  
    }
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getAllBillingTransactionSuccess,
      this.getAllBillingTransactionFailure
    );
  }

  getAllBillingTransactionSuccess = response => {
    if (response && response.status == "success") {
      var data = response.data
      var grand_total = data.total
      this.props.getTotalAmounts(grand_total, 0, 0, 0, 0, data.cash, data.card, data.upi, data.bank_transfer, data.insurance)
      this.setState({
        allBillingList: data.billing_list,
        originalData:  data.billing_list,
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  getAllBillingTransactionFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  changeTabSection = (data) =>{
    return(
      this.props.renderSelectedTab(data)
    )
  }
  dessendingSorting = (col) => {
    if(this.state.billTypeSort === "des" || this.state.billNoSort === "des"){
      var original = this.state.originalData
      this.setState({
        current_page: 1,
        records_per_page: 10,
        allBillListAfterPagination: [],
        allBillingList: []
      },() => {
        const Sorted = original.sort((a, b) => {
              return a[col] < b[col] ? 1 : -1
        })
        var value = this.state
        value["allBillingList"] = Sorted
        this.setState({
          value
        })
      })
    }
  }
  assendingSorting = (col) => {
    if (this.state.billTypeSort === "asc" || this.state.billNoSort === "asc") {
      var original = this.state.originalData
      this.setState({
        current_page: 1,
        records_per_page: 10,
        allBillListAfterPagination: [],
        allBillingList: []
      },() => {
        const Sorted = original.sort((a, b) => {
              return a[col] > b[col] ? 1 : -1
        })
        var value = this.state
        value["allBillingList"] = Sorted
        this.setState({
          value
        })
      })
      
    }  
   
  }
  renderSortView = (title) => {
    return(
      <View style={styles.sortContainer} >
      {(this.state.billTypeSort === "" && title === "Bill Date") || (this.state.billNoSort === "" && title === "Bill No") ? 
        <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
         if(title === "Bill Date") {
          this.setState({
            billTypeSort: "asc",
          },() => {
            this.assendingSorting("bill_date")
          })
         } else {
          this.setState({
            billNoSort: "asc",
          },()=> {
            this.assendingSorting("bill_no")
          })
         }
        }}>
        <Icons name="sort" size={"1vw"} color={color.black} />
        </TouchableOpacity> : (this.state.billTypeSort === "asc" && title === "Bill Date") || (this.state.billNoSort === "asc" && title === "Bill No") ? 
        <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
          if(title === "Bill Date") {
            this.setState({
              billTypeSort: "des",
            },() => {
              this.dessendingSorting("bill_date")
            })
           } else {
            this.setState({
              billNoSort: "des",
            },() => {
              this.dessendingSorting("bill_no")

            })
           }
        }}>
        <Icons name="sort-up" size={"1vw"} color={color.black} />
        </TouchableOpacity> : (this.state.billTypeSort === "des" && title === "Bill Date") || (this.state.billNoSort === "des" && title === "Bill No") ? 
        <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
          if(title === "Bill Date" || title === "Bill No") {
            this.setState({
              billTypeSort: "",
              billNoSort: "",
            }, () => {
              this.getAllBillingTransaction()
            })
           }
        }}>
        <Icons name="sort-down" size={"1vw"} color={color.black} />
        </TouchableOpacity> : null
      }
      </View>
    )
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth, flexDirection: "row" }}>
        <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
        { title === "Bill Date" || title === "Bill No" ? <View>{this.renderSortView(title)}</View> : null }
      </View>
    );
  }
  renderTableHeader = () => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.subContainer}>
          {this.getBillTableTitle("Bill Type", 0.12, "0.87vw")}
          {this.getBillTableTitle("Branch Name", 0.15, "0.87vw")}
          {this.getBillTableTitle("Patient Information", 0.15, "0.87vw")}
          {this.getBillTableTitle("Bill Date", 0.1, "0.87vw")}
          {this.getBillTableTitle("Physician Name", 0.15, "0.87vw")}
          {this.getBillTableTitle("Bill No", 0.13, "0.87vw")}
          {this.getBillTableTitle("Payment Method", 0.1, "0.87vw")}
          {this.getBillTableTitle("Bill Value", 0.1, "0.87vw")}
        </View>
      </View>
    );
  };
  paginationChangedValues(data) {
    this.setState({
       allBillListAfterPagination: data
    })
   }
   paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
  }
  renderPaginationView() {
    return (
      <View style={styles.paginationContainer}>
        {
          this.state.allBillingList && this.state.allBillingList.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalItems={this.state.allBillingList}
              paginationCurrentPage={this.paginationCurrentPage.bind(this)}
            /> : null
        }
      </View>
    )
   }
   renderDataListView = () => {
    return(
     <View style={styles.dataViewContainer}>
      <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '55vh' }}>  
      { this.state.allBillListAfterPagination && this.state.allBillListAfterPagination.length > 0 ?
        this.state.allBillListAfterPagination && this.state.allBillListAfterPagination.map((item, index) => {
          let date = moment(item.bill_date).format("DD-MM-YYYY")
          return (
            <View style={styles.dataContainer}>   
              <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.payment_mode}</Text></View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.branch_name}</Text></View>
              <View style={{ flex: 0.15 }}>
                <Text style={styles.TableDataText}>{item.name ? item.name + " ( " + ( item.gender === "f" ? "F" : item.gender === "m" ? "M" : "T" )  + " ) " + (item.age ? " | " : "") + (item.age ? item.age : "") : "-"}</Text>
                <Text style={[styles.TableDataText,{ fontSize: "0.8vw", color: color.lightGray, fontWeight: "500" }]}>{item.UHID}</Text>
              </View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{date}</Text></View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.physician_name}</Text></View>
              <View style={{ flex: 0.13 }}><Text style={styles.TableDataText}>{item.bill_no}</Text></View> 
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.payment_method ? item.payment_method : "-"}</Text></View> 
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.bill_value}</Text></View> 
            </View>
          )
        }):
         <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
          <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
         </View>
        }
      </ScrollView>   
    </View>
    )
  }
  render() {
    return (
      <View>
        <View style={styles.mainContainer}>
          <View style={styles.headersContainer}>
            {this.renderTableHeader()}
            {this.renderDataListView()}
            {this.renderPaginationView()}
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: "0vw",
    backgroundColor: color.themeShade,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowColor: "#171717",
    shadowOffset: { width: "0.07vw", height: "0.7vh" },
    height: "68vh",
  },
  headerContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "center",
    zIndex: 0
  },
  subContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    backgroundColor: color.sectionHeadingDarkColor,
    padding: "0.5vw",
    borderRadius: 4,
    marginTop: "0.5vw",
  },
  headersContainer: { marginHorizontal: "0.7vw" },
  filterContainer: { justifyContent: "flex-end", alignSelf: "flex-end", alignItems: "flex-end", flexDirection: "row", zIndex: 1 },
  pickerStyle:  {
    backgroundColor: color.white,
    height: "2.4vw",
    marginRight: "1vw",
    width: "8vw",
    fontSize: "1vw",
  },
  searchContainer: { height: "5vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center", borderRadius: 4, marginRight: "0.8vw" },
  exportContainer: { height: "5vh", width: "3vw", justifyContent: "center", alignItems: "center" },
  paginationContainer: {
    marginHorizontal: "1.2vw"
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingVertical: "0.6vw",
    justifyContent: 'center',
    flex: 1
  },
  TableDataText:{
    fontSize:'1vw'
  },
  dataViewContainer: { zIndex: -1, paddingHorizontal: "0.5vw" },
  sortContainer: { paddingLeft: "1vw", width: "20%", alignItems: "flex-start", marginTop: "0.2vw" }
});
