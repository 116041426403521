//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, FlatList, Image, ScrollView } from 'react-native';
import { color } from '../../../../styles/Color';
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

import OpthamologyService from "./../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import ImagePath from '../../../../utils/ImagePaths';

// create a component
export default class ShowPatientBilling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedClinicOrDoctor:this.props.selectedClinicOrDoctor,
            patientInfo: this.props.patientInfo,
            billingDetails: this.props.billingDetails,
            patientId: this.props.patientId,
            invoiceNo: this.props.invoiceNo,
            userType:this.props.userType,
        }
    }


    printCancel() {
        // var serviceUrl="";
        // if(this.state.userType == Constants.ROLE_FO){
            var serviceUrl = Constants.CANCLE_BILL_FO + this.state.invoiceNo + "/cancel/";
        // }else{
        //     serviceUrl = Constants.CANCLE_BILL_DR + this.state.invoiceNo + "/cancel/";
        // }

        var data = {}
        OpthamologyService.getInstance().postComplaints(
            serviceUrl,
            data,
            this,
            this.printCancelSuccess,
            this.printCancelFailure,
        );
    };

    printCancelSuccess = (success) => {
        //alert(JSON.stringify(success))
        // this.props.showResposeValue()
        if(success.status == "success"){
            this.props.closeBillingModal(false,"","",{},{},true,'success',success.message)    
        }else{
            this.props.closeBillingModal(false,"","",{},{},true,'error',success.message)       
        }
        
    }
    printCancelFailure=(error)=>{
        this.props.closeBillingModal(false,"","",{},{},true,'error',error.message)       

    }

    printBill = () => {

         var serviceUrl="";
         if(this.state.userType == Constants.ROLE_FO){
            serviceUrl = Constants.FO_PRINT_BILL + "?invoice_id=" + this.state.invoiceNo + "&patient_id=" + this.state.patientId;
         }else{
            serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + this.state.invoiceNo + "&patient_id=" + this.state.patientId+"&clinic_id="+this.state.selectedClinicOrDoctor.id;;
         }

        // var serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + this.state.invoiceNo + "&patient_id=" + this.state.patientId;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.printBillSuccess,
            this.printBillFailure,
            "pdf"
        );
    };

    printBillSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
           // console.log(file)
            window.open(fileURL);
        }
    }
    printBillFailure = error => {
        // alert(JSON.stringify(error))
    }

    componentDidMount() {
        // console.log("**********< patientInfo " + JSON.stringify(this.state.patientInfo))
        // console.log("**********< billingDetails " + JSON.stringify(this.state.billingDetails))
    }

    render() {
        return (
            <View style={{ position: 'absolute' }}>
                <View style={{ position: 'absolute', zIndex: -1, height: screenHeight, width: screenWidth, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{
                        width: '90%', backgroundColor: 'white', borderRadius: 20,
                    }}>
                        <TouchableOpacity onPress={()=>{this.props.closeBillingModal(false)}} style={{zIndex:2,backgroundColor:color.themeDark,borderRadius:50,height:35,width:35,justifyContent:"center",alignSelf:'flex-end',position:'absolute',marginRight:15,marginTop:15}}>
                            <Text style={{color:"white",fontWeight:'600',textAlign:'center'}} >X</Text>
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', paddingHorizontal: 40, flex: 1 }}>
                            {this.renderLeftSideView()}
                            {this.renderRightSideView()}
                        </View>
                    </View>
                </View>
            </View>

        );
    }

    renderLeftSideView() {
        var { patientInfo } = this.state;
        var { billingDetails } = this.state;
        return (
            <View style={{
                marginVertical: 60,
                flex: 0.68,
                borderRightColor: "#888888", borderRightWidth: 1

            }}>
                <View style={{ flex: 1, justifyContent: 'space-between' }}>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingRight: 20 }}>
                            <View style={{ alignItems: 'center' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Image source={patientInfo.photo_url ?{ uri: patientInfo.photo_url } : ImagePath.DefaultProfile} style={{ height: 50, width: 50, borderRadius: 40, alignSelf: 'center', marginRight: 15 }} />
                                    <View>
                                        <Text style={[styles.rightAndLeftTitleValueTextView, { fontSize: 17, marginBottom: 7 }]}>{patientInfo.name}</Text>
                                        <Text style={styles.rightAndLeftTitleTextView}>{"Patient A/C Number"}</Text>
                                        <Text style={styles.rightAndLeftTitleValueTextView}>{patientInfo.patient_account_number}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Text style={styles.rightAndLeftTitleTextView}>{"DOB"}</Text>
                                <Text style={styles.rightAndLeftTitleValueTextView}>{patientInfo.dob}</Text>
                            </View>
                            <View style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Text style={styles.rightAndLeftTitleTextView}>{"Age"}</Text>
                                <Text style={styles.rightAndLeftTitleValueTextView}>{patientInfo.age}</Text>
                            </View>
                            <View style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Text style={styles.rightAndLeftTitleTextView}>{"Mobile No"}</Text>
                                <Text style={styles.rightAndLeftTitleValueTextView}>{patientInfo.mobile_number}</Text>
                            </View>
                        </View>

                        <View style={{ marginTop: 20 }}>
                            <ScrollView style={{ height: 300 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                {
                                    billingDetails && billingDetails.services && billingDetails.services.length > 0 ?
                                        billingDetails.services.map((service, index) => {
                                            return this.showServiceList(service, index)
                                        })
                                        : <View />
                                }
                            </ScrollView>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <TouchableOpacity onPress={() => {
                            this.printBill()
                        }} style={{ paddingHorizontal: 25, paddingVertical: 10, backgroundColor: color.themeDark, borderRadius: 8 }}>
                            <Text style={{ color: color.white, fontWeight: '500', fontSize: 15 }}>{"Print"}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => this.printCancel()} style={{ paddingHorizontal: 25, paddingVertical: 10, backgroundColor: color.white, borderColor: color.themeDark, borderRadius: 8, borderWidth: 1 }}>
                            <Text style={{ color: color.themeDark, fontWeight: '500', fontSize: 15 }}>{"Cancel Bill"}</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>

        )
    }

    showServiceList(service, index) {
        return (
            <View style={{ flexDirection: 'row', marginTop: 7, marginBottom: 7 }} key={index}>
                <View style={{ flex: 0.4 }}>
                    <Text style={{ fontSize: 16, fontWeight: '500' }}>{service.service_name}</Text>
                </View>
                <View style={{ flex: 0.4 }}>
                    <Text style={{ fontSize: 16, fontWeight: '500' }}>{service.service_type}</Text>
                </View>
                <View style={{ flex: 0.1 }}>
                    <Text style={{ fontSize: 16, fontWeight: '500', color: color.themeDark }}>{service.amount_net}</Text>
                </View>
            </View>
        )
    }

    renderRightSideView() {
        var { billingDetails } = this.state
        return (

            <View style={{ flex: 0.32, paddingHorizontal: 25, paddingVertical: 30, marginTop: 60 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Invoice Date"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.invoice_date}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Invoice Number"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.invoice_number}</Text>
                    </View>
                </View>

              

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 25 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Discount %"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.discount_percent}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Discount Amount"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.discount}</Text>
                    </View>
                </View>


                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 25 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Round Off"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.round_off}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Net Amount"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.amount_net}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 25 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Total Amount"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.amount_gross}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 25 }}>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Payment Type"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.payment_type}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Cash"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.amount_cash}</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text style={styles.rightAndLeftTitleTextView}>{"Card"}</Text>
                        <Text style={styles.rightAndLeftTitleValueTextView}>{billingDetails.amount_card}</Text>
                    </View>
                </View>


            </View>

        )
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
    rightAndLeftTitleTextView: {
        fontSize: 16,
        fontWeight: '600',
        color: color.themeDark
    },
    rightAndLeftTitleValueTextView: {
        marginTop: 7,
        fontWeight: '500'
    }

});

//make this component available to the app
