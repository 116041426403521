//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, ActivityIndicator } from 'react-native';
import { Tooltip } from 'antd';
const screenHeight = Dimensions.get("window").height;
import moment from "moment";

import Style from "../../../styles/Style";
import { color } from '../../../styles/Color'
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../BaseComponent'
import  CommonDateFilter  from '../BaseComponent'

import { Calender } from '../common/calender';
import Pagination from '../../doctor/common/Pagination';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";
// import {  } from 'react-native-gesture-handler';
// create a component

export class IPPatientList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ipPatientList: [],
      ipPatientListPaginationValue: [],

      name: "",
      age: "",

      searchPatientData: [],

      patientSearchTextValue: "",

      fromDate: "",
      toDate: "",
      filterDate: {},
      pageLoading:true
    }
  }

  componentDidMount() {
    this.getPatientList()
  }
  onPressButton(key, value) {
    if (key) {
      this.props.changeScreen("demographics", {}, "", {}, "", {}, {}, false, "", {}, false, false, true)
    }
  }

  renderButton(label, buttonKey) {
    var item = { label: label, value: label }
    return (
      <Tooltip placement="top" title={item.label}>
        <TouchableOpacity onPress={this.onPressButton.bind(this)}>
          <View style={[styles.IconButton,{backgroundColor: color.white}]}>
        {/* <CommonButton
          item={item}
          selectedvalue={{}}
          butttonText={item.label}
          buttonType={"theme"}
          buttonIcon={require("../../../../assets/images/PlusIcon.png")}
          rightIcon={true}
          buttonAction={this.onPressButton.bind(this)}
          buttonKey={buttonKey} /> */}
            <Icon name="user-plus" size="1.4vw" color={color.themeDark} />
          </View>
        </TouchableOpacity>
      </Tooltip>
    )
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps && nextProps.patientSearchTextValue){
      this.setState({
        searchBar: nextProps.patientSearchTextValue
      })
    }else if(nextProps && nextProps.patientSearchTextValue === ""){
      this.setState({
        searchBar: nextProps.patientSearchTextValue
      })
    }
    if(nextProps && nextProps.isSearchClicked){
      this.searchIPPatient();
    }
  }


  showSelectedFilteredDate(date) {

    var states = this.state;
    states["filterDate"] = date
    states["fromDate"] = date.fromDate;
    states["toDate"] = date.toDate;
    this.setState({ states })

    this.getPatientList(date.fromDate, date.toDate)
    this.props.getDateFilterDate(date.fromDate, date.toDate)
  }

  renderHeader() {
    return (
      <View style={styles.HeaderTitle}>
        <View style={styles.HeaderTitleDiv}>
          <Text style={styles.HeaderTitleName}>{"In Patient List"}</Text>
          {this.renderButton("Create Patient", "demographics")}
          {/* {this.renderButton("New Admission", "newAdmission")} */}
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 2 }}>
          {/* <CommonDateFilter showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} /> */}
          <CommonDateFilter 
                startDate={this.state.fromDate} 
                endDate={this.state.toDate} 
                filterDate={this.state.filterDate}
                defaultSelectedDateFilter={"All"} 
                showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} />

          {/* <Calender getSelectedDate={this.getSelectedDate.bind(this)} /> */}
          
        </View>
      </View>
    )
  }

  renderIpPatientList(item, index) {
    var gender = item.patient_gender == "Male" ? "M" : item.patient_gender == "Female" ? "F" : "T"
    return (
      <View style={{paddingLeft: 15, paddingRight: 15}}>
      <TouchableOpacity style={{ zIndex: -1 }} onPress={() => {
        this.props.changeScreen("optDoctorNotes",{}, "",  {}, '', item)
      }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', borderRadius: 4, paddingVertical: ".5vw", borderBottomColor: "#888888", borderBottomWidth: 1 }}>
          <View style={{ flex: 0.20, paddingLeft: "1vw", flexDirection: 'row', alignItems: 'center', }}>
            <Image style={{ height: "2.6vw", width: "2.6vw", paddingRight: 8, borderRadius: "20vw", borderWidth: 1, borderColor: color.lightGray, paddingHorizontal: "0.65vw" }} source={item.photo_url ? { uri: item.photo_url } : require('../../../../assets/images/sample_profile.png')} />
            <View style= {{ marginLeft: "0.65vw", marginTop: "-.7vw" }}>
             {
                item && item.patient_name ?
                  <View style={{flexDirection: "row"}}>
                    <Tooltip placement="topLeft" title={item.patient_name}>
                      <Text style={{ fontSize: ".9vw", fontWeight: '500' }}>
                        {item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}</Text>
                        <Text style={{ fontSize: ".8vw", fontWeight: "500" }}>{ " ( " + item.patient_age + " / " + gender + " )"}</Text>
                    </Tooltip> 
                    {item.is_ama_patient?
                    <Tooltip placement="topLeft" title={item.ama_comment}>
                      <Icon
                        size={".9vw"}
                        name={"crosshairs"}
                        color= {color.brightRed}
                        style={{marginLeft: "0.75vw"}}
                      />
                    </Tooltip>
                    :null }
                  
                  </View>
                  : null
              }

              <Text style={{ fontSize: ".7vw" }}>{item.patient_account_number} </Text>
            </View>
          </View>
          {/* <View style={{ flex: 0.04 }}><Text style={styles.patientListFontStyle}>{item.patient_age}</Text></View>
          <View style={{ flex: 0.07 }}><Text style={styles.patientListFontStyle}>{item.patient_gender}</Text></View> */}
          <View style={{ flex: 0.08 }}><Text style={styles.patientListFontStyle}>{item.patient_mobile_number}</Text></View>
          <View style={{ flex: 0.09 }}><Text style={styles.patientListFontStyle}>{item.patient_tags}</Text></View>
          <View style={{ flex: 0.11 }}><Text style={styles.patientListFontStyle}>{item.visiting_consultant}</Text></View>
          <View style={{ flex: 0.12 }}><Text style={styles.patientListFontStyle}>{item.complaints}</Text></View>
          <View style={{ flex: 0.08 }}><Text style={styles.patientListFontStyle}>{moment(item.admission_date).format("DD-MM-YYYY")}</Text></View>
          <View style={{ flex: 0.11 }}><Text style={styles.patientListFontStyle}>{item['room/ward/block']}</Text></View>
          <View style={{ flex: 0.06 }}><Text style={styles.patientListFontStyle}>{item.pending_amount}</Text></View>
          <View style={{ flex: 0.15, flexDirection: 'row' }}>
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Create_Ip_Billing.png"), "ipPatientCreateBill", "Create Bill", item, "ipallTransactions")}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Room_Change.png"), "admissionDetails", "Room Change", item)}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Insurance.png"), "insurance", "Insurance", item)}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/DischargeSummary .png"), "dischargeSummary", "Notes", item, "surgeryNotes")}
            {this.renderActionIcons(require("../../../../assets/images/IPActions/Panient_Check.png"), "patientCheckout", "Patient Checkout", item)}

            {/* <Text style={styles.patientListFontStyle}>{""}</Text> */}
          </View>
        </View>
      </TouchableOpacity>
      </View>
    )
  }

  renderIpPatientListHeader() {
    return (
      <View style={{paddingLeft: 15, paddingRight: 15}}>
      <View style={{ zIndex: -1, marginTop: ".8vw", marginBottom: ".5vw", backgroundColor: color.appointmentTableHead, height: "2.60vw", flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
        <View style={{ flex: 0.20, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Patient's Details"}</Text></View>
        {/* <View style={{ flex: 0.04 }}><Text style={styles.headerFontStyle}>{"Age"}</Text></View>
        <View style={{ flex: 0.07 }}><Text style={styles.headerFontStyle}>{"Gender"}</Text></View> */}
        <View style={{ flex: 0.08 }}><Text style={styles.headerFontStyle}>{"Mobile No"}</Text></View>
        <View style={{ flex: 0.09 }}><Text style={styles.headerFontStyle}>{"Patient Tag"}</Text></View>
        <View style={{ flex: 0.11 }}><Text style={styles.headerFontStyle}>{"Visiting Consultant"}</Text></View>
        <View style={{ flex: 0.12 }}><Text style={styles.headerFontStyle}>{"Complaints"}</Text></View>
        <View style={{ flex: 0.08 }}><Text style={styles.headerFontStyle}>{"Admission Date"}</Text></View>
        <View style={{ flex: 0.11 }}><Text style={styles.headerFontStyle}>{"Room/Ward/Block"}</Text></View>
        <View style={{ flex: 0.06 }}><Text style={styles.headerFontStyle}>{"Pending Amount (₹)"}</Text></View>
        <View style={{ flex: 0.15 }}><Text style={[styles.headerFontStyle, { textAlign: 'center' }]}>{"Action"}</Text></View>
      </View>
      </View>
    )
  }


  getPatientInfo(key, subscreen, item, flag) {
    var subDatas={
      key:key,
      subscreen:subscreen,
      item:item,
      flag:flag
    }
    var serviceUrl = "";

    serviceUrl = Constants.FO_PATIENT_INFO + item.patient_id + "/?appointment_id=" + item.appointment_id + "&doctor_id=" + item.doctor_id;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure,
      subDatas
    );
  };

  getComplaintsSuccess = (response,subDatas) => {
     if (response.status === "success") {
      var field = this.state;
      field["patientInfo"] = response.data;
      this.props.changeScreen(subDatas.key, {}, "", {}, subDatas.subscreen, subDatas.item, "", subDatas.flag)

      this.props.changeStateValues(response.data, "", "", "")
      this.setState({ field });

    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  }

  renderActionIcons(image, key, tooltip, item, subscreen) {
    
    return (

      <TouchableOpacity
        onPress={() => {
          if (key) {
            console.log("get patient url key:",key,subscreen);
            this.getPatientInfo(key, subscreen, item, true)
            // this.props.changeScreen(key, {}, "", {}, subscreen, item, "", true)

          }
        }} style={{
          padding: ".3vw", borderRadius: "20vw", height: "2.2vw", width: "2.2vw", backgroundColor: key == "ipPatientCreateBill" && !item.is_payment_complete ? color.red : color.white, shadowOffset: { width: ".1vw", height: ".1vw" }, shadowOpacity: 0.4, shadowRadius: 3,
          elevation: 5, marginLeft:".2vw"
        }}>
        <Tooltip placement="top" title={tooltip}>
          <Image source={image} style={[
            {
              height: "1.5vw", width: "1.5vw", tintColor: key == "ipPatientCreateBill"&& !item.is_payment_complete ? color.white :color.black
            }]} />
        </Tooltip>
      </TouchableOpacity>
    )
  }

  paginationChangedValues(data) {
    // alert(JSON.stringify(data))
    this.setState({
      ipPatientListPaginationValue: data
    })
  }

  getPatientList(fromDate = "", toDate = "") {
    var service_url = Constants.IP_PATIENT_LIST_GET +
      "?from_date=" + fromDate +
      "&to_date=" + toDate
      ;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientListSuccess,
      this.getPatientListFailure
    );
  }

  getPatientListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        ipPatientList: success.data,
        pageLoading:false
      })
    }
  }
  getPatientListFailure = error => { }


  renderIpPatientSearchList() {
    return (

      <View style={{ zIndex: 2 }}>
        {
          this.state.searchPatientData && this.state.searchPatientData.length > 0 ?

            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 70, alignItems: 'flex-start', paddingLeft: "1.30vw", paddingBottom: "1.95vw" }}>

              <View style={{ width: "45%", backgroundColor: 'white', borderBottomRightRadius: "0.65vw", borderBottomLeftRadius: "0.65vw", paddingBottom: "0.65vw" }}>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.5 }}>
                  {
                    this.state.searchPatientData.map((item, index) => {
                      return (
                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: "1.30vw", paddingRight: "1.30vw", paddingTop: "0.32vw", paddingBottom: "0.32vw", borderBottomColor: "#E4E8F1", borderBottomWidth: 1 }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Image source={item.photo_url ? item.photo_url : require('../../../../assets/images/sample_profile.png')}
                              style={Style.appointmentTableRowImg}
                              resizeMode='stretch' />
                            <Text style={{ color: 'black', marginLeft: "0.65vw", fontSize:"1vw" }} >{item.patient_name}</Text>
                            <Text style={{ color: '#ADB4C1', marginLeft: "0.65vw", fontSize:"1vw" }}> {item.mobile_number}</Text>
                            <Text style={{ color: '#ADB4C1', marginLeft: "0.65vw", fontSize:"1vw" }}> {item.patient_account_number}</Text>
                          </View>
                          {
                            item.admission_date && item.doctor_id ?

                              <TouchableOpacity style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", }}>
                                <Text style={{ color: "black", fontSize: ".85vw", fontWeight: "500" }}>{item.admission_date}</Text>
                              </TouchableOpacity> :
                              <TouchableOpacity onPress={() => {
                                this.props.changeScreen("demographics", {}, "", {}, "", item, false, "", {}, false, false, true)
                              }} style={{  marginLeft: "1.30vw", paddingTop: "0.52vw", paddingLeft: "1.17vw",
                                paddingRight: "1.17vw", paddingBottom: "0.52vw", backgroundColor: color.themeDark, borderRadius: "0.52vw" }}>
                                <Text style={[{ color: color.white, textAlign: 'center', fontWeight: 500 }, Style.fontSizeMedium]}>Create Admission</Text>
                              </TouchableOpacity>
                          }

                        </View>
                      );
                    })

                  }
                </ScrollView>
              </View>

            </View>
            : null
        }
      </View>
    )
  }

  searchIPPatient = () => {


    var query = this.state.searchBar

    if (query !== "") {
      var serviceUrl =
        Constants.IP_PATIENT_SEARCH +
        "?search_key=" + query +
        "&home_page=" + true;

      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.searchIPPatientSuccess,
        this.searchIPPatientFailure
      );
    } else {
      var fields = this.state;
      fields["searchPatientData"] = [];

      this.setState({
        fields
      });
    }
  };

  searchIPPatientSuccess = response => {

    this.setState({
      //   fields
      searchPatientData: response.data,
    });
  };

  searchIPPatientFailure = error => {
    // console.log("search patient error response");
    console.log(error);
  };



  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.renderIpPatientSearchList()}
        <ScrollView style={{ height: screenHeight - 70 }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          {this.renderHeader()}
          {this.renderIpPatientListHeader()}
          {
            this.state.ipPatientList && this.state.ipPatientList.length > 0 && this.state.ipPatientListPaginationValue && this.state.ipPatientListPaginationValue.length > 0 ?

              this.state.ipPatientListPaginationValue.map((item, index) => {
                return this.renderIpPatientList(item, index)
              })

              :
                <View style={[styles.loaderStyle]}>
                 { this.state.pageLoading ? <ActivityIndicator size="large" color={color.themeDark} /> 
                  : <Text style={{ color: color.appointmentTableHead, fontSize: "2vw"}}>{"No records to be shown"}</Text>
                 }
                </View> 
          }

          {/* {this.renderIpPatientList()} */}
          {
            this.state.ipPatientList && this.state.ipPatientList.length > 0 ?
              <View style={{paddingLeft: 15, paddingRight: 15}}><Pagination recordsPerPage={30} paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.ipPatientList} /></View>
              : null
          }

        </ScrollView>
      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  HeaderTitle:{
    width: "100%",
    height:"3.5vw",
    boxShadow: "0px 3px 6px #00000029",
    borderBottomEndRadius: "15vw",
    borderBottomLeftRadius: "15vw",
    flexDirection: 'row',
    alignItems:"center",
    justifyContent:'space-between',
    paddingHorizontal:'3vw',
    zIndex: 1
  },
  HeaderTitleDiv:{
    flexDirection: 'row',
    alignItems: 'center'
  },
  HeaderTitleName:{
    fontWeight: "bold",
    fontSize: "1.3vw"
  },
  IconButton:{ 
    marginLeft: "2.5vw",
    boxShadow: '0px 3px 6px #00000049',
    opacity: 1,
    borderRadius: "50vw",
    alignItems: 'center',
    justifyContent: 'center',
    width: "2.7vw",
    height: "2.7vw"
},
  headerFontStyle: {
    fontSize: "0.84vw",
    color: "black",
    fontWeight: '500'
  },
  patientListFontStyle: {
    fontSize: ".85vw",
    color: "black"
  },
  container: {
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: '#2c3e50',
  },
  loaderStyle:{
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    minHeight: 100, 
    marginTop: 180
  }
});

//make this component available to the app
