import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import {DrugList} from './DrugList';
import {PurchaseIndentList} from './PurchaseIndentList';
import { DrugIndent } from './DrugIndent';
import {PurchaseIndentDetails}  from './PurchaseIndentDetails';
export class DrugIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "drugList",
      indentNumber: ""
    };
  }
  changeTabSection(data) {
    this.setState({ selectedTab: data })
  }
  showResposeValue = (title, message) => {
    this.props.showResposeValue(title, message)
  }
  renderSelectedTab = (data, indentNumber) => {
    this.setState({
      selectedTab: data,
      indentNumber: indentNumber
    })
  }
  
  render() {
    return (
      <View>
      { this.state.selectedTab != "drugIndent" ?
       <View style={styles.drugContainer}>
           {/* <View style={{marginRight: '2vw'}}>     
            <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"+ Direct PO"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    // this.props.changePage("directPO")
                }}
                buttonKey={"directPO"} />
              </View> */}

        </View> : null
       }
       <View>
       </View>    
        { this.state.selectedTab === "drugList" ? 
         <DrugList           
          showResposeValue={this.showResposeValue.bind(this)}
          renderSelectedTab = {this.renderSelectedTab}
         /> : this.state.selectedTab === "purchaseIndent" ? 
         <PurchaseIndentList
          showResposeValue={this.showResposeValue.bind(this)}
          renderSelectedTab = {this.renderSelectedTab}
         />: this.state.selectedTab === "purchaseIndentDetail" ? 
         <PurchaseIndentDetails
          showResposeValue={this.showResposeValue.bind(this)}
          renderSelectedTab = {this.renderSelectedTab}
          indentNumber = {this.state.indentNumber}
         />: this.state.selectedTab === "drugIndent" ? 
         <DrugIndent
          showResposeValue={this.showResposeValue.bind(this)}
          renderSelectedTab = {this.renderSelectedTab}
         /> :null 
        }
       
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    margin: "1vw"
  },
  drugContainer: { flex: 1, justifyContent: "flex-end", alignItems: "flex-end", alignSelf:"flex-end"}
})  
