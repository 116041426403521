import { DatePicker, TimePicker } from 'antd';
import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, TouchableWithoutFeedback } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { color } from "../../../../styles/Color";
import moment from "moment";

export default class OTMReschedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SurgerySuggest: this.props.PatientData.surgery_name,
            SurgerySuggesterror: "",
            SurgeonName: this.props.PatientData.surgeon_name,
            SurgeonNameError: "",
            PackageName: this.props.PatientData.package_name,
            PackageNameError: "",
            powerValue: "",
            powerValueError: "",
            secondPowerValue: this.props.PatientData.iol_power.slice(1),
            DrugName: this.props.PatientData.anaesthetist,
            DrugNameError: "",
            counsellorName: this.props.PatientData.counsellor_name,
            counsellorNameError: "",
            eyeTypeError: "",
            rightEye: this.props.PatientData.eye_type === "Right Eye (OD)" ? true : false,
            leftEye: this.props.PatientData.eye_type === "Left Eye (OS)" ? true : false,
            bothEye: this.props.PatientData.eye_type === "Both Eye (OU)" ? true : false,
            PlusButton: this.props.PatientData.iol_power.slice(0, 1) === "+" ? true : false,
            MinusButton: this.props.PatientData.iol_power.slice(0, 1) === "-" ? true : false,
            patientDetails: this.props.PatientData,
            SurgeryDate: "",
            SurgeryDateError: "",
            SurgeryTime: "",
            SurgeryTimeError: "",
            Comments: "",
            CommentsError: "",
            ServiceType: this.props.ServiceType,
            eyeType: "",
            IOLPower: ""
        }
    }

    defaultValue = () => {
        var today = new Date();
        var value = this.props.PatientData.surgery_date === null || this.props.PatientData.surgery_date === "" ? today : this.props.PatientData.surgery_date
        return moment(value)
    }

    disabledDate = (current) => {
        var today = new Date();
        var disableDate = current && current < moment(this.props.PatientData.surgery_date === null || this.props.PatientData.surgery_date === "" ? today : this.props.PatientData.surgery_date).add(1, "days");
        return disableDate
    }

    FormValidationOne = () => {
        if (this.state.SurgeryDate === "") {
            this.setState({
                SurgeryDateError: ("Please Select Surgery Date"),
            })
        } else {
            this.setState({
                SurgeryDateError: "",
            })
        }
        if (this.state.SurgeryTime === "") {
            this.setState({
                SurgeryTimeError: ("Please Select Surgery Time"),
            })
        } else {
            this.setState({
                SurgeryTimeError: "",
            })
        }
        if (this.state.SurgeryTime !== "" && this.state.SurgeryDate !== "" && this.state.Comments === "") {
            this.setState({
                CommentsError: ("Please Enter Comments"),
            })
        } else {
            this.setState({
                CommentsError: "",
            })
        }
    }

    FormValidationTwo = () => {
        if (this.state.SurgerySuggest === "") {
            this.setState({
                SurgerySuggesterror: ("Surgery Suggest Must Not Be Empty"),
            })
        } else {
            this.setState({
                SurgerySuggesterror: "",
            })
        }
        if (this.state.SurgeonName === "") {
            this.setState({
                SurgeonNameError: ("Please Enter Surgery Name"),
            })
        } else {
            this.setState({
                SurgeonNameError: "",
            })
        }
        if (this.state.PackageName === "") {
            this.setState({
                PackageNameError: ("Please Enter Package Name"),
            })
        } else {
            this.setState({
                PackageNameError: "",
            })
        }
        if (this.state.DrugName === "") {
            this.setState({
                DrugNameError: ("Please Enter Drug Name"),
            })
        } else {
            this.setState({
                DrugNameError: "",
            })
        }
        if (this.state.counsellorName === "") {
            this.setState({
                counsellorNameError: ("Please Enter Counsellor Name"),
            })
        } else {
            this.setState({
                counsellorNameError: "",
            })
        }
        if (this.state.secondPowerValue === "") {
            this.setState({
                powerValueError: ("Please Enter Power Value"),
            })
        } else if (this.state.PlusButton != true && this.state.MinusButton != true) {
            this.setState({
                powerValueError: ("Please Select + or -"),
            })
        }
        else {
            this.setState({
                powerValueError: "",
            })
        }
        if (this.state.rightEye == true || this.state.leftEye == true || this.state.bothEye == true) {
            this.setState({
                eyeTypeError: "",
            })
        } else {
            this.setState({
                eyeTypeError: ("Eye Type Not Selected"),
            })
        }
        if (this.state.SurgeryDate === "") {
            this.setState({
                SurgeryDateError: ("Please Select Surgery Date"),
            })
        } else {
            this.setState({
                SurgeryDateError: "",
            })
        }
        if (this.state.SurgeryTime === "") {
            this.setState({
                SurgeryTimeError: ("Please Select Surgery Time"),
            })
        } else {
            this.setState({
                SurgeryTimeError: "",
            })
        }
        if (this.state.SurgeryTime !== "" && this.state.SurgeryDate !== "" && this.state.Comments === "") {
            this.setState({
                CommentsError: ("Please Enter Comments"),
            })
        } else {
            this.setState({
                CommentsError: "",
            })
        }
    }

    SubmitOne = () => {
        if (this.state.SurgeryDate !== "" &&
            this.state.SurgeryTime !== "" &&
            this.state.Comments !== "") {
            this.setState({
                SurgeryDateError: "",
                SurgeryTimeError: "",
                CommentsError: "",
            }, () => {
                this.props.PopupClose(), this.props.ChangedDate(this.state.SurgeryDate),
                    this.props.ChangedTime(this.state.SurgeryTime),
                    this.props.IsReschedule(this.state.patientDetails, this.state.Comments)
            })
        } else {
            this.FormValidationOne()
        }
    }

    SubmitTwo = () => {
        if (this.state.SurgerySuggest !== "" &&
            this.state.SurgeonName !== "" &&
            this.state.PackageName !== "" &&
            this.state.DrugName !== "" &&
            this.state.counsellorName !== "" &&
            (this.state.rightEye == true || this.state.leftEye == true || this.state.bothEye == true) &&
            this.state.secondPowerValue !== "" &&
            (this.state.PlusButton == true || this.state.MinusButton == true) &&
            this.state.SurgeryDate !== "" && this.state.SurgeryTime !== "" && this.state.Comments !== ""
        ) {
            this.setState({
                SurgerySuggesterror: "",
                SurgeonNameError: "",
                PackageNameError: "",
                DrugNameError: "",
                counsellorNameError: "",
                eyeTypeError: "",
                powerValueError: "",
                eyeType: this.state.rightEye == true ? "Right Eye (OD)" : this.state.leftEye == true ? "Left Eye (OS)" : this.state.bothEye == true ? "Both Eye (OU)" : null,
                IOLPower: this.state.powerValue + this.state.secondPowerValue
            }, () => {
                this.props.PopupClose(), this.props.ChangedDate(this.state.SurgeryDate),
                    this.props.ChangedTime(this.state.SurgeryTime),
                    this.props.IsReschedule(this.state.patientDetails, this.state.Comments, this.state.PackageName, this.state.eyeType, this.state.IOLPower)
            })
        } else {
            this.FormValidationTwo()
        }
    }


    render() {
        return (
            <View>
                <View style={styles.CancelPopupContainer}>
                    <TouchableWithoutFeedback>
                        <View style={styles.PopupHeaderConatiner}>
                            <View style={{ flex: 1 }}>
                                <View style={styles.PopupHeaderWrapper}>
                                    <Text style={styles.HeaderTextStyle}>
                                        Reschedule Surgery - <Text style={styles.patientNameStyle}>{this.state.patientDetails.patient_name}</Text>
                                    </Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.PopupClose()
                                        }}
                                        style={{ fontSize: "1.95vw" }}
                                    >
                                        <Icon
                                            name="window-close-o"
                                            size={"1.10vw"}
                                            color={color.themeDark}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.BodyContainer}>
                                    <View style={styles.BodyWrapper}>
                                        <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Surgery Suggest<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.SurgerySuggest}
                                                onChangeText={(text) => {
                                                    const Testvalue = text.match(/^ *$/) ? "" : text;
                                                    this.setState({
                                                        SurgerySuggest: Testvalue,
                                                        SurgerySuggesterror: ""
                                                    })
                                                }} style={[styles.textInputStyle, {
                                                    borderColor: this.state.SurgerySuggesterror === "Surgery Suggest Must Not Be Empty" ? color.brightRed : color.themeDark
                                                }]} />
                                            <Text style={styles.ErrorText}>{this.state.SurgerySuggesterror}</Text>
                                        </View>
                                        <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Surgeon Name<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.SurgeonName}
                                                onChangeText={(text) => {
                                                    const Testvalue = text.match(/^ *$/) ? "" : text;
                                                    this.setState({
                                                        SurgeonName: Testvalue,
                                                        SurgeonNameError: ""
                                                    })
                                                }} style={
                                                    [styles.textInputStyle, {
                                                        borderColor: this.state.SurgeonNameError === "Please Enter Surgery Name" ? color.brightRed : color.themeDark
                                                    }]} />
                                            <Text style={styles.ErrorText}>{this.state.SurgeonNameError}</Text>
                                        </View>
                                        <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Package Name<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.PackageName}
                                                onChangeText={(text) => {
                                                    const Testvalue = text.match(/^ *$/) ? "" : text;
                                                    this.setState({
                                                        PackageName: Testvalue,
                                                        PackageNameError: ""
                                                    })
                                                }} style={[styles.textInputStyle, {
                                                    borderColor: this.state.PackageNameError === "Please Enter Package Name" ? color.brightRed : color.themeDark
                                                }]} />
                                            <Text style={styles.ErrorText}>{this.state.PackageNameError}</Text>
                                        </View>
                                        <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Anaesthesiatist<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.DrugName}
                                                onChangeText={(text) => {
                                                    const Testvalue = text.match(/^ *$/) ? "" : text;
                                                    this.setState({
                                                        DrugName: Testvalue,
                                                        DrugNameError: ""
                                                    })
                                                }} style={[styles.textInputStyle, {
                                                    borderColor: this.state.DrugNameError === "Please Enter Drug Name" ? color.brightRed : color.themeDark
                                                }]} />
                                            <Text style={styles.ErrorText}>{this.state.DrugNameError}</Text>
                                        </View>
                                        {this.props.isOPT || this.props.drType ? <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Power<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <View style={styles.powerInputContainer}>
                                                <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.secondPowerValue}
                                                    onChangeText={(text) => {
                                                        const Testvalue = text.replace(/[^A-Za-z^ *$]/g, '') ? "" : text;
                                                        this.setState({
                                                            secondPowerValue: Testvalue,
                                                            powerValueError: ""
                                                        })
                                                    }} style={[styles.powerTextInputStyle, {
                                                        borderColor: this.state.powerValueError !== "" ? color.brightRed : color.themeDark
                                                    }]} />
                                                <TouchableOpacity disabled={/* this.state.ServiceType === "COUNSELLOR" ? false : */ true} onPress={() => { this.setState({ powerValue: "+", PlusButton: true, MinusButton: false }) }}>
                                                    <View style={[styles.PowerButtonContainer, { backgroundColor: this.state.PlusButton ? color.themeDark : null }]}>
                                                        <Text style={[styles.powerButtontextStyle, { color: this.state.PlusButton ? color.white : null }]}>+</Text>
                                                    </View>
                                                </TouchableOpacity>
                                                <TouchableOpacity disabled={/* this.state.ServiceType === "COUNSELLOR" ? false : */ true} onPress={() => { this.setState({ powerValue: "-", MinusButton: true, PlusButton: false }) }}>
                                                    <View style={[styles.PowerButtonContainer, { backgroundColor: this.state.MinusButton ? color.themeDark : null }]}>
                                                        <Text style={[styles.powerButtontextStyle, { color: this.state.MinusButton ? color.white : null }]}>-</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={styles.ErrorText}>{this.state.powerValueError}</Text>
                                        </View> : null}
                                        <View style={styles.InputContainerView}>
                                            <Text style={styles.BodyTextStyle}>Counsellor<Text style={styles.asteriskStyle}>*</Text></Text>
                                            <TextInput editable={/* this.state.ServiceType === "COUNSELLOR" ? true : */ false} value={this.state.counsellorName}
                                                onChangeText={(text) => {
                                                    const Testvalue = text.match(/^ *$/) ? "" : text;
                                                    this.setState({
                                                        counsellorName: Testvalue,
                                                        counsellorNameError: ""
                                                    })
                                                }} style={[styles.textInputStyle, {
                                                    borderColor: this.state.counsellorNameError === "Please Enter Counsellor Name" ? color.brightRed : color.themeDark
                                                }]} />
                                            <Text style={styles.ErrorText}>{this.state.counsellorNameError}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.eyeTypeContainer}>
                                        <View style={styles.InputContainerView}>
                                            {this.props.isOPT || this.props.drType ?<View style={{ marginLeft: "-.3vw" }}>
                                                <Text style={[styles.BodyTextStyle, { color: this.state.eyeTypeError === "Eye Type Not Selected" ? color.brightRed : null }]}> Selection Eye type<Text style={styles.asteriskStyle}>*</Text></Text>
                                                <View style={styles.eyeTypeButtonContainer}>
                                                    <TouchableOpacity disabled={/* this.state.ServiceType === "COUNSELLOR" ? false : */ true} onPress={() => { this.setState({ rightEye: true, leftEye: false, bothEye: false, eyeTypeError: "" }) }}>
                                                        <View style={[styles.eyeTypeButtonStyle, {
                                                            borderColor: this.state.rightEye ? color.themeDark : color.grayShade,
                                                            backgroundColor: this.state.rightEye ? color.themeDark : null
                                                        }]}>
                                                            <Text style={[styles.eyeTypeTextStyle, { color: this.state.rightEye ? color.white : null }]}>Right Eye (OD)</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity disabled={/* this.state.ServiceType === "COUNSELLOR" ? false : */ true} onPress={() => { this.setState({ leftEye: true, rightEye: false, bothEye: false, eyeTypeError: "" }) }}>
                                                        <View style={[styles.eyeTypeButtonStyle, {
                                                            borderColor: this.state.leftEye ? color.themeDark : color.grayShade,
                                                            backgroundColor: this.state.leftEye ? color.themeDark : null
                                                        }]}>
                                                            <Text style={[styles.eyeTypeTextStyle, { color: this.state.leftEye ? color.white : null }]}>Left Eye (OS)</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity disabled={/* this.state.ServiceType === "COUNSELLOR" ? false : */ true} onPress={() => { this.setState({ bothEye: true, rightEye: false, leftEye: false, eyeTypeError: "" }) }}>
                                                        <View style={[styles.eyeTypeButtonStyle, {
                                                            borderColor: this.state.bothEye ? color.themeDark : color.grayShade,
                                                            backgroundColor: this.state.bothEye ? color.themeDark : null
                                                        }]}>
                                                            <Text style={[styles.eyeTypeTextStyle, { color: this.state.bothEye ? color.white : null }]}>Both Eye (OU)</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                                <Text style={[styles.ErrorText, { marginLeft: ".5vw" }]}>{this.state.eyeTypeError}</Text>
                                            </View> : null }
                                            <View style={styles.SurgeryDateTimeContainer}>
                                                <View style={styles.SurgeryDateTimeWrapper}>
                                                    <Text style={styles.BodyTextStyle}>Surgery Date<Text style={styles.asteriskStyle}>*</Text></Text>
                                                    <DatePicker
                                                        // defaultValue={this.defaultValue()}
                                                        disabledDate={this.disabledDate}
                                                        clearIcon={false}
                                                        format={"YYYY-MM-DD"}
                                                        minDate={this.props.PatientData.surgery_date}
                                                        style={{ height: '2vw', fontSize: '.9vw', marginTop: '.3vw', borderRadius: 4, width: '9.5vw' }}
                                                        onChange={(date) => {
                                                            var fulldate = new Date(date);
                                                            var converted_date = moment(fulldate).format("YYYY-MM-DD");
                                                            this.setState({
                                                                SurgeryDate: converted_date,
                                                                SurgeryDateError: ""
                                                            })
                                                        }}
                                                    />
                                                    <Text style={styles.ErrorText}>{this.state.SurgeryDateError}</Text>
                                                </View>
                                                <View style={styles.SurgeryDateTimeWrapper}>
                                                    <Text style={styles.BodyTextStyle}>Surgery Time<Text style={styles.asteriskStyle}>*</Text></Text>
                                                    <TimePicker
                                                        clearIcon={false}
                                                        format="HH:mm"
                                                        style={{ height: '2vw', fontSize: '.9vw', marginTop: '.3vw', borderRadius: 4, width: '9.5vw' }}
                                                        onChange={(time, timeString) => {
                                                            this.setState({
                                                                SurgeryTime: timeString + ":00",
                                                                SurgeryTimeError: ""
                                                            })
                                                        }}
                                                    />
                                                    <Text style={styles.ErrorText}>{this.state.SurgeryTimeError}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <View style={styles.BriefInputContainerView}>
                                                <Text style={styles.BodyTextStyle}>Notes</Text>
                                                <View style={{ marginTop: ".3vw" }}>
                                                    <TextInput multiline={true} style={[styles.BriefBoxStyle, { borderColor: color.themeDark }]} />
                                                </View>
                                            </View>
                                            <View style={styles.BriefInputContainerView}>
                                                <Text style={[styles.BodyTextStyle, { color: this.state.SurgeryDate !== "" && this.state.SurgeryTime !== "" ? "" : color.grayShade }]}>Comments{this.state.SurgeryDate !== "" && this.state.SurgeryTime !== "" ? <Text style={styles.asteriskStyle}>*</Text> : null}</Text>
                                                <View style={{ marginTop: ".3vw" }}>
                                                    <TextInput multiline={true} value={this.state.Comments}
                                                        onChangeText={(text) => {
                                                            const Testvalue = text.match(/^ *$/) ? "" : text;
                                                            this.setState({
                                                                Comments: Testvalue,
                                                                CommentsError: ""
                                                            })
                                                        }} editable={this.state.SurgeryDate !== "" && this.state.SurgeryTime !== "" ? true : false}
                                                        style={[styles.BriefBoxStyle, { borderColor: this.state.CommentsError === "Please Enter Comments" ? color.brightRed : this.state.SurgeryDate !== "" && this.state.SurgeryTime !== "" ? color.themeDark : color.grayShade }]} />
                                                    <Text style={styles.ErrorText}>{this.state.CommentsError}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.PopupBottomContainer}>
                                    <TouchableOpacity
                                        onPress={() => { /* this.state.ServiceType === "COUNSELLOR" ? this.SubmitTwo() :  */this.SubmitOne() }}
                                        style={styles.ConfirmButtonView}
                                    >
                                        <Text style={styles.ConfirmButtonText}>
                                            Confirm
                                        </Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={styles.ClearButtonView}
                                        onPress={() => { this.props.PopupClose() }}
                                    >
                                        <Text style={styles.ClearbuttonText}>Cancel</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    CancelPopupContainer: {
        height: "48.15vw",
        width: "92.95vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    PopupHeaderConatiner: {
        backgroundColor: "white",
        width: "55%",
        borderRadius: "0.65vw",
        padding: "1.30vw",
        marginBottom: "6.83vw"
    },
    PopupHeaderWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    HeaderTextStyle: {
        color: color.themeDark,
        fontSize: "1.23vw",
        marginTop: "1vw",
        fontWeight: '500'
    },
    PopupBottomContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "1vw"
    },
    ConfirmButtonView: {
        backgroundColor: color.themeDark,
        padding: "0.45vw",
        width: "7.81vw",
        justifyContent: "center",
        marginRight: ".32vw",
        borderRadius: "0.25vw",
    },
    ConfirmButtonText: {
        textAlign: "center",
        color: "white",
        fontSize: ".91vw"
    },
    ClearButtonView: {
        backgroundColor: "white",
        padding: "0.45vw",
        width: "7.81vw",
        justifyContent: "center",
        borderWidth: 1,
        marginLeft: ".32vw",
        borderColor: "grey",
        borderRadius: "0.25vw",
    },
    ClearbuttonText: {
        textAlign: "center",
        fontSize: ".91vw"
    },
    BodyContainer: {
        width: "100%",
        paddingHorizontal: "2vw",
        paddingVertical: "1vw"
    },
    BodyWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    InputContainerView: {
        height: '5vw'
    },
    BodyTextStyle: {
        fontSize: "1vw",
        fontWeight: 500
    },
    textInputStyle: {
        width: '20vw',
        height: '2vw',
        borderWidth: 2,
        fontSize: ".9vw",
        marginTop: ".3vw",
        paddingHorizontal: '.2vw',
        borderRadius: 4
    },
    ErrorText: {
        fontSize: '.8vw',
        color: color.brightRed
    },
    powerInputContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    powerTextInputStyle: {
        width: '10vw',
        height: '2vw',
        borderRadius: 4,
        borderWidth: 2,
        fontSize: ".9vw",
        marginTop: ".3vw",
        paddingHorizontal: '.2vw'
    },
    PowerButtonContainer: {
        width: '3vw',
        height: '2vw',
        borderColor: color.themeDark, marginTop: ".3vw", marginLeft: '.3vw', borderRadius: 4,
        borderWidth: 2, display: "flex", alignItems: "center", justifyContent: "center"
    },
    eyeTypeContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    powerButtontextStyle: {
        fontSize: "1.3vw",
        fontWeight: 500,
        marginTop: "-5px"
    },
    eyeTypeButtonContainer: {
        display: 'flex',
        flexDirection: "row",
        width: "21vw",
        justifyContent: "space-evenly"
    },
    eyeTypeButtonStyle: {
        width: '6.5vw',
        height: "2vw",
        borderWidth: 1,
        alignItems: 'center', justifyContent: 'center',
        marginTop: ".3vw", borderRadius: 4
    },
    eyeTypeTextStyle: {
        fontSize: ".85vw"
    },
    SurgeryDateTimeContainer: {
        marginTop: '1vw',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        height: "5.5vw"
    },
    SurgeryDateTimeWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    BriefBoxStyle: {
        width: "20vw", height: "3.5vw", paddingHorizontal: ".35vw",
        paddingVertical: ".3vw", borderWidth: 2, borderRadius: 4, fontSize: ".9vw"
    },
    BriefInputContainerView: {
        height: '6.5vw'
    },
    asteriskStyle: {
        color: color.brightRed,
        fontSize: "1.1vw"
    },
    patientNameStyle: {
        color: color.black,
        fontSize: "1.3vw",
        fontWeight: 700
    }
});