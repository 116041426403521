import React, { Component, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, Image, Linking, ScrollView } from 'react-native';
// import io from 'socket.io-client';
import AsyncStorage from "../../../AsyncStorage";
import { color } from '../../../styles/Color'
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
const screenHeight = Dimensions.get("window").height;
import * as Speech from 'expo-speech';

// import SocketIOClient from 'socket.io-client'
// import openSocket from 'socket.io-client';
// const socket = openSocket('https://dev.qms.emedhub.in/tokens/web-socket/9168/');

// var socketConfig = { path: '/9168/' };
// var socket = new SocketIO('https://dev.qms.emedhub.in//tokens/web-socket/', socketConfig);


// export const ShowToken = () => {

//     useEffect(() => {

//         var ws = new WebSocket('ws://dev.qms.emedhub.in/ws/tokens/web-socket/9168/');

//         ws.onopen = (test) => {
//             console.log("onopen");
//             console.log(test);
//             // connection opened
//         };

//         ws.onmessage = (e) => {
//             // a message was received
//             console.log("onmessage",e.data);
//         };

//         ws.onerror = (e) => {
//             // an error occurred
//             console.log(e.message);
//         };

//         ws.onclose = (e) => {
//             // connection closed
//             console.log(e.code, e.reason);
//         };

//         // // const socket = io('https://dev.qms.emedhub.in', {transports: ['ws/tokens/web-socket/9168/'], secure: true});
//         // const socket = io('https://dev.qms.emedhub.in', {transports: ['websocket'], secure: true});
//         // // const socket = io('ws/tokens/web-socket/9168/', {transports: ['websocket'], secure: true});
//         //   socket.connect();
//         // console.log(socket.connected);
//       })

//     return (
//         <View>

//             <Text>{"ShowToken"}</Text>

//             <View><TouchableOpacity onPress={()=>{}}><Text>{"click "}</Text></TouchableOpacity></View>
//         </View>
//     )
// }

export class ShowToken extends Component {



    async getActiveTokensList() {

        const clintID = await AsyncStorage.getItem("UserID");
        if (clintID) {

            // wss://dev.qms.emedhub.in/
            var service_url = Constants.QMS_SHOW_LED_DISPLAY_TOKENS + clintID + "/";

            OpthamologyService.getInstance().getComplaints(
                service_url,
                this,
                this.getActiveTokensListSuccess,
                this.getActiveTokensListFailure,
                "",
                "qms"
            );
        }

    }

    getActiveTokensListSuccess = success => {
        // alert(JSON.stringify(success))
        console.log(JSON.stringify(success))
        if (success.status == "success") {


            var data = success.data

            var getRoomDetails = []

            if(data.length > 0){

                for (let i = 0; i < data.length; i++) {
                    if(data[i].list_of_tokens.length > 0){
                        var waitingTokens = [];
                        var processToken = []
                        for (let j = 0; j < data[i].list_of_tokens.length; j++) {
                            if(data[i].list_of_tokens[j].token_status =="wait" && waitingTokens.length <2){
                                waitingTokens.push(data[i].list_of_tokens[j])

                            }if(data[i].list_of_tokens[j].token_status =="process" && processToken.length < 1 ){
                                processToken.push(data[i].list_of_tokens[j])
                                // alert(JSON.stringify(processToken.length))
                            }
                            
                        }

                        // processToken = processToken.sort(function (x, y) {
                        //     return x.token_digit - y.token_digit;
                        // });

                        // waitingTokens = waitingTokens.sort(function (x, y) {
                        //     return x.token_digit - y.token_digit;
                        // });

                        var getOrderDatas = [...processToken,...waitingTokens]
                        
                        data[i]["list_of_tokens"] = getOrderDatas;
                    }
                    
                }
            }

            


            this.setState({
                listOfTokenList: data
                // listOfTokenList: success.data
            })
        }
    }
    getActiveTokensListFailure = error => {
        // alert(JSON.stringify(success))

    }


    componentDidMount() {
        this.getActiveTokensList();
        this.getTokenUpdate()
    }


    speak = (tokenNumber,roomNumber) => {
        const thingToSay = "Room Number "+roomNumber+" Token Number "+ tokenNumber;
        Speech.speak(thingToSay);
      };


    async getTokenUpdate () {
        console.log("getTokenUpdate");
        // var socketID = "53";
        const socketID = await  AsyncStorage.getItem("UserID");

        var socket_ur = Constants.QMS_SOCKET_CONNECTION_URL+socketID+"/";



        // const socket_ur = new WebSocket(
        //     'wss://'
        //     + "dev.qms.emedhub.in"
        //     + '/ws/tokens/web-socket/'
        //     + socketID
        //     + '/'
        // );

        console.log("getTokenUpdate socket_ur" + socket_ur);

        const chatSocket = new WebSocket(socket_ur);

        var me = this;



        chatSocket.onmessage = function (e) {
            console.log("ON MESSAGE");

            var data = JSON.parse(e.data);

            console.log("ON MESSAGE data"+JSON.stringify(data));


            if(data && data.type == "queue"){
                me.getActiveTokensList();
                if(data.message && data.message.token_status == "process"){
                    me.speak(data.message.token_digit, data.message.room_number)
                }
                // me.getActiveTokensList();

                // 
                // if(data.message.length > 0 ){
                //     var getMessages = data.message
                //     for (let i = 0; i < getMessages.length; i++) {
                //         if(getMessages[i].list_of_tokens.length > 0){
                //             var getTokens = getMessages[i].list_of_tokens
                //             for (let j = 0; j < getTokens.length; j++) {
                //                 if(getTokens[j].token_status == "process"){
                //                     me.speak(getTokens[j].token_digit, getMessages[i].room_number)
                //                     return
                //                 }
                //             }
                //         }
                        
                //     }
                // }
                // 
            }

        };

        chatSocket.onclose = function (e) {
            console.error("Token socket closed unexpectedly");
        };
    };




    constructor(props) {
        super(props);

        this.getActiveTokensList = this.getActiveTokensList.bind(this)

        this.state = {
            listOfTokenList: []
        }


        // 	this.socket = SocketIOClient("https://dev.qms.emedhub.in//tokens/web-socket/9168/"); // replace 'environment.serverUrl' with your server url
        // 	this.socket.emit('channel1', 'Hi server'); // emits 'hi server' to your server

        // 	// Listens to channel2 and display the data recieved
        // this.socket.on('channel2', (data) => {
        //     console.log('Data recieved from server', data); //this will console 'channel 2'
        //   });


    }


    // 	clicked () {
    //         const socket = io("http://192.168.43.69:3000")
    //     }

    // //         var socket = io.connect('https://dev.qms.emedhub.in//tokens/web-socket/9168/', {
    //             'force new connection': true,
    //             'resource': 'path/to/socket.io'});
    // socket.on('connect_failed', function(data)
    // {
    //     console.log('connect_failed');
    // });
    // socket.on('connecting', function(data)
    // {
    //     console.log('connecting');
    // });
    // socket.on('disconnect', function(data)
    // {
    //     console.log('disconnect');
    // });
    // socket.on('error', function(reason)
    // {
    //     console.log('error');
    // });
    // socket.on('reconnect_failed', function(data)
    // {
    //     console.log('reconnect_failed');
    // });
    // socket.on('reconnect', function(data)
    // {
    //     console.log('reconnect');
    // });
    // socket.on('reconnecting', function(data)
    // {
    //     console.log('reconnecting');
    // });
    // socket.socket.disconnect();
    // }

    render() {
        return (
            <View style={{ flexDirection: 'row', backgroundColor: color.white }}>

                <View style={{ flex: 0.4 }}>
                    <View style={{ flexDirection: "row", backgroundColor: color.themeDark, height: 45 }}>
                        <View style={{ flex: 0.33 ,justifyContent:'center',alignItems:"center"}}>
                            <Text style={styles.headerFontStyle}>{"Room"}</Text>
                        </View>
                        <View style={{ flex: 0.33, borderLeftColor: color.white, borderLeftWidth: 2,justifyContent:'center',alignItems:"center" }}>
                            <Text style={styles.headerFontStyle}>{"Token"}</Text>

                        </View>
                        <View style={{ flex: 0.34, borderLeftColor: color.white, borderLeftWidth: 2,justifyContent:'center',alignItems:"center" }}>
                            <Text style={styles.headerFontStyle}>{"Next"}</Text>

                        </View>
                    </View>
                    <ScrollView style={{ height: screenHeight - 45 ,backgroundColor:"#F0FFFF"}}>
                        {
                            this.state.listOfTokenList && this.state.listOfTokenList.length > 0 ?
                                this.state.listOfTokenList.map((item, index) => {
                                    return this.showListofTokensView(item, index)
                                })
                                : null
                        }
                    </ScrollView>
                </View>


                <View style={{ flex: 0.6 }}>
                        <Image source={require('../../../../assets/images/DisplayTokenBGImage.jpg')} style={{height:screenHeight}}/>
                </View>

                {/* <Text>{"ShowToken"}</Text>

                <View><TouchableOpacity onPress={() => {this.getTokenUpdate()}}><Text>{"click "}</Text></TouchableOpacity></View> */}
            </View>
        )
    }

    showListofTokensView(item, index) {
        var isAvailable = true
        return (
            <View key={index} style={{ flexDirection: "row", backgroundColor: "#e2e2e2", height: 45 }}>
                <View style={{ flex: 0.33 ,justifyContent:'center',alignItems:"center"}}>
                    <Text style={styles.tabelFontStyle}>{item.room_number}</Text>
                </View>
                <View style={{ flex: 0.33, borderLeftColor: color.white, borderLeftWidth: 2,justifyContent:'center',alignItems:"center" }}>
                    {
                        item.list_of_tokens.length > 0 ?
                        item.list_of_tokens.map((token,index)=>{

                            

                            if(token.token_status == "process" && isAvailable){
                                // isAvailable = false
                                return(
                                    <Text key={index} style={styles.tabelFontStyle}>{token.token_digit}</Text>
                                )
                            }
                        })
                        // <Text style={styles.tabelFontStyle}>{item.list_of_tokens[0].token_digit}</Text>
                        : 
                        null
                    }
                </View>
                <View style={{ flex: 0.34, borderLeftColor: color.white, borderLeftWidth: 2,justifyContent:'center',alignItems:"center"}}>
                    {/* {
                        item.list_of_tokens.length >2 ?
                        <Text style={styles.tabelFontStyle}>{
                            item.list_of_tokens[1].token_digit + " , "+
                            item.list_of_tokens[2].token_digit 
                            
                            }</Text>
                        : 
                        item.list_of_tokens.length > 1 ?
                        <Text style={styles.tabelFontStyle}>{
                            item.list_of_tokens[1].token_digit
                            
                            }</Text>

                        : null
                    } */}
                    <View style={{flexDirection:'row'}}>

                    
                    {
                        item.list_of_tokens.length > 0 ?
                        item.list_of_tokens.map((token,index)=>{
                            if(token.token_status == "wait"){
                                return(
                                    <Text key={index} style={styles.tabelFontStyle}>{token.token_digit}{" , "}</Text>
                                )
                            }
                        })
                        : null
                    }
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 20,
        color: color.white,
        fontWeight:'bold',
    },
    tabelFontStyle:{
        fontSize: 20,
        fontWeight:'bold',
        color: color.black,
    }
});
