import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;

export const MedicalCertificate = (props) => {
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={styles.MainScrollView}
        >
            <View style={styles.MainWrapperView}>
                <View style={styles.TopView}>
                    <Text style={styles.HeaderText}>Medical Certificate</Text>

                    <View style={styles.SignatureView}>
                        <TextInput
                            style={styles.SignatureInput}

                            underlineColorAndroid="transparent"
                        />

                        <Text style={styles.SignatureText}>Signature of the Applicant</Text>

                    </View>
                </View>
                <View style={{ padding: '0.65vw', marginTop: '1.95vw' }}>
                    <p style={{ lineHeight: 3, color: 'black', fontSize:'0.9vw' }}>
                        I,
                     <TextInput
                            value={props.selectedValueClinic_Droctor.value}
                            style={styles.DoctorNameInput}
                            placeholder={"Doctor Name"}
                            onChangeText={(text) => {
                                props.onChangedDatas("medicalDoctorName", text)
                            }}
                            underlineColorAndroid="transparent"
                        /> after careful personal examination of the case hereby certify that
                 <TextInput
                            style={styles.PatientNameInput}
                            value={props.clinicdetails.patient_name}
                            underlineColorAndroid="transparent"
                        />    of the Office of 
                     <TextInput
                            placeholder="Office's Name "
                            style={styles.OfficeNameInput}
                            onChangeText={(text) => {
                                props.onChangedDatas("medicalOffice", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
                     whose signature is given above is suffering from
                     <TextInput
                            placeholder="Illness name"
                            style={styles.IllnessInput}
                            onChangeText={(text) => {
                                props.onChangedDatas("medicalIllness", text)
                            }}
                            underlineColorAndroid="transparent"
                        />
                         and, therefore, I consider, that a period of absence from duty from
                         <DatePicker
                            //   value={this.state.filterFromDate ? moment(this.state.filterFromDate)
                            //     : null}
                            disabledDate={current => {
                                return current && current > moment().add(0, "days");
                            }}
                            format={"DD-MM-YYYY"}
                            onChange={date => {
                                var key = "medicalfromDate";
                                var value = date
                                props.onChangedDatas(key, value)
                            }}
                            placeholder={"Date"}
                            style={{
                                fontSize: '1.17vw',
                                color: 'grey',
                                borderBottomColor: 'grey',
                                borderBottomWidth: 1,
                                marginRight: '0.65vw',
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                marginLeft: '0.65vw',
                                marginTop: '0.65vw',
                                width: "10vw"

                            }}
                        />
                            to
                            <DatePicker
                            //   value={this.state.filterFromDate ? moment(this.state.filterFromDate)
                            //     : null}
                            // disabledDate={current => {
                            //     return current && current > moment().add(0, "days");
                            // }}
                            disabledDate={current => {
                                return current && current < moment(props.medicalfromDate ? props.medicalfromDate : null)
                                .add(0, 'days')
                                ||
                                  current && current > moment().add(0, "days");
                              }}
                            format={"DD-MM-YYYY"}
                            onChange={date => {
                                var key = "medicaltoDate";
                                var value = date
                                props.onChangedDatas(key, value)
                            }}
                            placeholder={"Date"}
                            style={{
                                fontSize: '1.17vw',
                                color: 'grey',
                                borderBottomColor: 'grey',
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                marginLeft: '0.65vw',
                                width: '10vw'

                            }}
                        />
                    with effect from    <DatePicker
                            //   value={this.state.filterFromD format={"YYYY-MM-DD"}ate ? moment(this.state.filterFromDate)
                            //     : null}
                            disabledDate={current => {
                                return current && current > moment().add(0, "days");
                            }}
                            format={"DD-MM-YYYY"}
                            onChange={date => {
                                var key = "medicalactiveDate";
                                var value = date
                                props.onChangedDatas(key, value)
                            }}
                            placeholder={"Date"}
                            style={{
                                fontSize: '1.17vw',
                                color: 'grey',
                                borderBottomColor: 'grey',
                                borderBottomWidth: 1,
                                marginRight: '0.65vw',
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                width:'10vw'
                            }}
                        />
                    is absolutely necessary for the restoration of his/her health.

            </p>
                </View>

                <View style={styles.BottomInputMainView}>
                    <View style={styles.BottomInputWrapper}>
                        <View style={{ width: "30%" }}>
                            <TextInput
                                placeholder="Place"
                                style={styles.PlaceInput}
                                value={props.clinicdetails.clinic_place}

                                underlineColorAndroid="transparent"
                            />
                        </View>
                        <View style={{ width: "30%" }}>

                            <DatePicker
                            disabledDate={current => {
                                return current && current > moment().add(0, "days");
                            }}
                                value={props.medicalDate ? moment(props.medicalDate)
                                    : null}
                                format={"DD-MM-YYYY"}
                                onChange={date => {
                                    var key = "medicalDate";
                                    var value = date
                                    props.onChangedDatas(key, value)
                                }}
                                placeholder={"Date"}
                                style={{
                                    fontSize: '1.17vw',
                                    color: 'black',
                                    borderBottomColor: 'grey',
                                    borderBottomWidth: 1,
                                    marginRight: '0.65vw',
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,


                                }}
                            />
                        </View>
                        <View style={{ width: "30%" }}>

                            <TextInput
                                placeholder="Registration Number"
                                value={props.medicalRegistrationNo}
                                style={styles.RegNumberInput}
                                onChangeText={(text) => {
                                    props.onChangedDatas("medicalRegistrationNo", text)
                                }}
                                underlineColorAndroid="transparent"
                            />
                        </View>
                    </View>

                </View>
                <View style={styles.StaffSignatureInputView}>
                    <TextInput
                        style={styles.StaffSignatureInputText}

                        underlineColorAndroid="transparent"
                    />
                </View>
                <View style={styles.SignatureButtomView}>
                    <Text style={styles.SignatureBottomText}>
                        Signature of Government Medical Officer /Civil Surgeon / Staff Surgeon/</Text>
                    <Text style={styles.SignatureBottomText}>
                        Authorised Medical Attendant/Registered Medical Practitioner along with official seal
                        </Text>
                </View>
                <View style={styles.ButtonWrapper}>

                    <TouchableOpacity
                        onPress={() => props.createMedicalCertificate()}
                        style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? '0.9vw' : '1.3vw'} color={color.white}  style={{marginRight:'1vw'}}/>
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => props.medicalPreviewAndPrint()}
                        disabled={props.formId ? false : true}
                        style={[Style.certificateButton1,{backgroundColor:props.formId ? color.themeDark : color.disableComponentColor}]} >
                        <Icon name={"print"} size={platform === "web" ? '0.9vw' : '1.3vw'} color={color.white}  style={{marginRight:'1vw'}}/>
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>


            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    MainScrollView: {
        maxHeight: '70vh',
    },
    MainWrapperView:{
        padding: '0.45vw'
    },
    TopView:{
        alignSelf: 'center',
        alignContent: 'center'
    },
    HeaderText:{
        fontSize: '1.17vw', 
        fontWeight: '400', 
        textAlign: 'center'
    },
    SignatureView:{
        marginTop: '3.25vw'
    },
    SignatureInput:{
        fontSize: '1.17vw',
        color: 'grey',
        marginBottom: '1.30vw',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,      
    },
    SignatureText:{
        color: "#727272", 
        fontSize: '1vw', 
        fontWeight: '400', 
        textAlign: 'center'
    },
    DoctorNameInput:{
        fontSize: '1.17vw',
        color: 'black',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        textAlign: 'center',
        margin: '0.65vw',
    },
    PatientNameInput:{
        fontSize: '1.17vw',
        color: 'black',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        textAlign: 'center',
        marginRight: '0.65vw'
    },
    OfficeNameInput:{
        fontSize: '1.17vw',
        color: 'grey',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        width: '9.76vw',
        textAlign: 'center',
        marginLeft: '0.65vw'
    },
    IllnessInput:{
        fontSize: '1.17vw',
        color: 'grey',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        width: '13.6vw',
        textAlign: 'center',
        marginLeft: '0.65vw'
    },
    BottomInputMainView:{
        paddingRight: '3.25vw', 
        paddingLeft: '3.25vw', 
        marginTop: '1.95vw'
    },
    BottomInputWrapper:{
        flexDirection: 'row', 
        justifyContent: 'space-evenly', 
        alignContent: 'center', 
        alignItems: 'flex-end'
    },
    PlaceInput:{
        fontSize: '1vw',
        color: 'black',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        marginRight: '0.65vw',
        paddingBottom: '0.32vw',
        paddingLeft: '0.32vw'
    },
    RegNumberInput:{
        fontSize: '1vw',
        color: 'grey',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
        marginRight: '0.65vw',
        paddingBottom: '0.32vw',
        paddingLeft: '0.32vw'
    },
    StaffSignatureInputView:{
        alignSelf: 'center', 
        alignContent: 'center', 
        marginTop: '3.25vw', 
        width: "40%"
    },
    StaffSignatureInputText:{
        fontSize: '1.17vw',
        color: 'grey',
        marginBottom: '1.3vw',
        borderBottomColor: 'grey',
        borderBottomWidth: 1,       
    },
    SignatureButtomView:{
        alignSelf: 'center', 
        alignContent: 'center' 
    },
    SignatureBottomText:{
        color: "#727272", 
        fontSize: '0.9vw', 
        fontWeight: '400', 
        textAlign: 'center'
    },
    ButtonWrapper:{
        flexDirection: 'row', 
        alignSelf: 'center', 
        alignContent: 'center', 
        alignItems: 'center', 
        marginTop: '4.55vw'
    }
  });
