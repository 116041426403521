import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  // Image,
  Picker,
  StyleSheet,
  Platform,
  Modal
} from "react-native";
// import {Picker} from '@react-native-community/picker';
// import Style from "../../../styles/Style";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import Icon from "react-native-vector-icons/FontAwesome";
import {
  Calendar
  // , CalendarList, Agenda 
} from 'react-native-calendars';
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import { CommonButton, DoctorNotesCommonRightSideHeader,CommonSectionHeader,CommonHistorySectionHeader } from '../BaseComponent'
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageTitle = "Review Plan";
const pageName = "reviewplan";

export default class ReviewPlanNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      data: {
        id: "",
        template_name: "",
        duration_type: "Days",
        duration: "",
        review_date: "",
        appointment_time: ""
      },
      // selectedEye: "",
      // selectedButton: "", // observation
      // selectedButtonText: "", // comments
      // complaints: "",
      selectedDuration: "",
      comments: "",
      language: "",
      filterFromDate: "",
      showNextAppoinmentTimer: false,
      hour12Formet: 0,
      minutes: 0,
      findAmPm: '',
      duration_type: "",
      isTemplate: false,
      templateData: [],

      userType : this.props.userType,
      confirmPopupVisible: false,
      has_followup_appointment: false

    };

    this.state.duration_type = this.state.data.duration_type;
    this.state.selectedDuration = this.state.data.duration;

  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.chegeAppoinmentModalTime()
    this.getCommentsTemplate()
  }

  componentWillReceiveProps(props) {

    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {
          this.setState({
            id: item.id,
            duration_type: item.duration_type,
            selectedDuration: item.duration,
            filterFromDate: item.review_date,
            hour12Formet: item.appointment_time ? item.appointment_time.split(':')[0].trim() : 0,
            minutes: item.appointment_time ? item.appointment_time.split(':')[1].trim().replace(/[A-Z]/g, '') : 0,
            findAmPm: item.appointment_time ? item.appointment_time.split(':')[0].trim() : 0,
            findAmPm: item.appointment_time ? item.appointment_time.slice(-2) :'',
            comments: item.comments,
            has_followup_appointment: item.has_followup_appointment
          });

          // console.log(this.state)
        }

      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        disable={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    },()=>{
      if(key == "selectedDuration"){
        this.changeReviewDate()
      } 
    })
  }


  // renderTestIconBtn = (text, icon, createBtn) => {
  //   return (
  //     <View
  //       style={{
  //         height: 40,
  //         width: text === "New" ? 70 : "100%",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: createBtn || text === "New" ? 10 : 10,
  //         flexDirection: "row",
  //         backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themeBlue,
  //         justifyContent: "space-evenly",
  //         borderWidth: createBtn || text === "New" ? 1 : 1,
  //         borderColor: "#F25364"
  //       }}
  //     >
  //       <Text
  //         style={{
  //           fontSize: platform === "web" ? 17 : 14,
  //           color: text === "New" ? "#848484" : "#F25364",
  //           fontWeight: "500"
  //         }}
  //       >
  //         {text}
  //       </Text>


  //     </View>
  //   );
  // };

  addComplaint = event => {
    if(!this.state.has_followup_appointment){
      this.setState({confirmPopupVisible: true})
    }else{
      this.addComplaintSave(true)
    }
    
  };

  addComplaintSave = (val)=>{
    
    let states = this.state;
    var service_url = Constants.OPTHAMOLOGY_MEDICATION_REVIEW_PALN_POST;
    this.setState({confirmPopupVisible: false})
    let appTime = this.state.hour12Formet
    if (this.state.findAmPm == 'PM')
      appTime = parseInt(this.state.hour12Formet) + 12
    appTime = appTime + ":" + this.state.minutes + ":00"
    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "duration_type": this.state.has_followup_appointment? null : this.state.duration_type,
      "duration": this.state.has_followup_appointment? null : this.state.selectedDuration,
      "review_date": this.state.has_followup_appointment? null : this.state.filterFromDate,
      "appointment_time": this.state.has_followup_appointment? null : appTime,
      "comments": states.comments,
      "has_followup_appointment": val
    };


    // if(Constants.ROLE_FO == this.state.userType){
    //   data["doctor_id"] =this.state.patientAppointment.doctor_id
    // }

    if (states.id) {
      data["id"] = states.id;
    }


    // if (states.selectedEye) {

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
    //}am
  }

  showSuccessMessage = () => {
    // successOrErrorMessage:false,
    // successMessageFlag:false,
    // errorMessageFlag:false
    this.setState({ successOrErrorMessage: true, successMessageFlag: true, confirmPopupVisible: false });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false, confirmPopupVisible: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true, confirmPopupVisible: false });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false, confirmPopupVisible: false });
    }, 2000);

  };


  getComplaintsSuccess = response => {
    //alert("complaint  created successfully")
    // console.log(JSON.stringify(response))
    if (response.status === "success") {

      this.props.clearEditedItem(pageName, {})

      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });
      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData("reviewplan");
      this.chegeAppoinmentModalTime()
      this.props.showResposeValue("success", response.message)
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getComplaintsFailure = error => {
    this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)

  };

  clearComplaint = event => {

    let states = this.state;
    states['filterFromDate'] = "";
    states['duration_type'] = "";
    states['id'] = "";
    // "duration_type": 3,
    states['selectedDuration'] = "";
    // states['hour12Formet'] = ;
    // states['minutes'] = 0;
    states['findAmPm'] = '';



    states['comments'] = "";

    this.setState({ states }, () => {
      this.props.clearEditedItem(pageName, {})
    });

  }

  getCommentsTemplate = () => {
    var service_url = Constants.OPT_REVIEW_PLAN_COMMENTS_TEMPLATE;
    
    if(Constants.ROLE_FO == this.state.userType){
      service_url= service_url +"?doctor_id="+this.state.patientAppointment.doctor_id
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCommentsTemplateSuccess,
      this.getCommentsTemplateFailure
    );
  };

  getCommentsTemplateSuccess = response => {
    if (response.status === "success") {
      var data = this.state;
      data["templateData"] = response.data;
      data["template_name"] = "";
      data["selectedTemplate"] = "";
      this.setState({ data });
    }
  };

  getCommentsTemplateFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  saveTemplate = event => {

    let errorMessage = "";
    let states = this.state;
    var service_url = Constants.OPT_REVIEW_PLAN_COMMENTS_TEMPLATE;

    let data = {
      "template_name": states.template_name,
      "comments": states.comments
    };

    if(Constants.ROLE_FO == this.state.userType){
      data["doctor_id"] =this.state.patientAppointment.doctor_id
    }

    if(!states.template_name) {
      errorMessage = errorMessage?errorMessage+",Enter template name":"Enter template name";
    }
    if(!states.comments) {
      errorMessage = errorMessage?errorMessage+",Enter comments":"Enter comments";
    }

    if(errorMessage){

      this.props.showResposeValue("error", errorMessage)
    } else {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSaveTemplateSuccess,
        this.getSaveTemplateFailure,
      );
    }

  };

  getSaveTemplateSuccess = response => {
    if (response.status === "success") {
      
      // clear data
      // this.clearComplaint();
      this.props.showResposeValue("success", response.message)
      this.setState({ 
        isTemplate: false,
        template_name: ""
      }, ()=>{
        this.getCommentsTemplate()
      })
      
      // reload data
      // this.props.refreshData("reviewplan");
    }
  };

  getSaveTemplateFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };



  getTreatmentTemplateselect = (template_id) => {
    var service_url = Constants.OPTHAMOLOGY_MEDICATION_REVIEW_PALN_TEMPLATE_POST + "?template_id=" + template_id; //this.state.data.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTreatmentTemplateselectSuccess,
      this.getTreatmentTemplateselectFailure
    );
  };

  getTreatmentTemplateselectSuccess = response => {
    if (response.status === "success") {

      // var { data } = this.state;
      let data = response.data;

      // comments
      // selectedButton

      this.setState({
        data: data,
        duration_type: data.duration_type,
        selectedDuration: data.duration,


      });
    }


  };


  getTreatmentTemplateselectFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };



  renderNewTemplateAdd = () => {

    return (
      <View style={{ marginTop: 50 }}>
        <TextInput
          placeholder="Template Name"
          style={{ height: 40, borderColor: color.rightPanelInputBorder, borderWidth: 1, color: 'white', borderRadius: 10, paddingLeft: 2, textAlign: 'justify' }}
          onChangeText={text => this.setState({ template_name: text })}
          value={this.state.template_name}
        />

        <View style={{ marginTop: 50, flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.saveTemplate()
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Cancel", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </View>

    )

  }

  renderSelectTemplate = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressTemplate.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressTemplate = (key, value) => {


    //this.setState({ selectedButtonText: value, selectedButton: key })
    let states = this.state;
    states[key] = value;

    // let {data} = this.state;
    // data['id'] = value;

    this.setState({
      states
    })

    this.getTreatmentTemplateselect(value);
  }

  changeReviewDate(){

    var currentDate = new Date();
    var reviewDay = null;
    var getReviewDate = null;
    var reviewDate = null;
    var selectedDuration = parseInt(this.state.selectedDuration) || 0;

    if(selectedDuration && selectedDuration>0) {

      let isDateCalculate = false;
      if(this.state.duration_type == "Days"){
        reviewDay = selectedDuration;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if(this.state.duration_type == "Weeks"){
        reviewDay = selectedDuration * 7;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if(this.state.duration_type == "Months"){
        reviewDay = selectedDuration * 30;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if(this.state.duration_type == "Years"){
        reviewDay = selectedDuration * 365;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }
      if(isDateCalculate){
        reviewDate = moment(getReviewDate).format("YYYY-MM-DD")
        this.setState({ filterFromDate:reviewDate })
      }
    }else{
      if(this.state.filterFromDate){
        this.setState({ filterFromDate:"" })
      }
    }
  }

  ConfirmReviewAppointmentModal(){
    if(this.state.confirmPopupVisible){
      return(
        <View style={styles.ConfirmModalMainView}>
        <Modal
        style={{border:'none'}}
        animationType="slide"
        transparent={false}
        visible = {this.state.confirmPopupVisible}
        >
            
            <View
                style={styles.ModalWrapper}>
                  <View style={{flexDirection:'row'}}>
                    <View style={{marginBottom: '1.3vw'}}>
                      <Text style={styles.ModalText}>Do you want to create an appointments for follow-up? </Text>
                    </View>
                    <View>
                      <TouchableOpacity style={{}} onPress={() => this.setState({confirmPopupVisible: false})}>
                        <Text style={styles.ModalCloseBtn}>X</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
              <View style={{flexDirection:'row'}}>
                <View>
                  <TouchableOpacity style={styles.ModalButton} onPress={() => this.addComplaintSave(true)}>
                        <Text style={{ color: color.white, fontSize:'0.91vw' }}>Yes</Text>
                      </TouchableOpacity>
                  </View>
                  <View>
                    <TouchableOpacity style={styles.ModalButton} onPress={() => this.addComplaintSave(false)}>
                      <Text style={{ color: color.white, fontSize:'0.91vw' }}>No</Text>
                    </TouchableOpacity>
                  </View>
              </View>
            </View>
        </Modal>
        </View>
      )
    }else{

    }
  }

  render() {
    var templateArr = [];
    var temp = {}

    var templatedata = this.state.templateData;

    templatedata && templatedata.length > 0 && templatedata.map(item => {
      temp = {
        value: item.id,
        label: item.template_name
      }
      templateArr.push(temp)
    });

    return (
      <TouchableWithoutFeedback onPress={() => {
        this.setState({ showCalenderModal: false, showNextAppoinmentTimer: false })
      }}>
          <View>

            {/* {this.renderSubHeadingBorder("Review Plan", false, true)} */}
            <DoctorNotesCommonRightSideHeader title={"Review Plan"} clearAllData={this.clearComplaint.bind(this)} />


            {/* {this.renderTitleWithMultipleBtn("Templates", templateArr, 3, false, 
            'selectedEye', this.state.selectedEye, this.updateState.bind(this), true, true)} */}

            {/* {this.renderSubHeadingBorder("Templates", true, true)} */}

            {/* <FlatList
              data={templateArr}
              numColumns={4}
              scrollEnabled={false}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              containerStyle={{ alignItems: "center", alignContent: "center" }}
              renderItem={({ item }) => this.renderSelectTemplate(item, false, false, 'selectedEye', this.state.selectedEye)}
            //   extraData={this.state.selectedEye}
            /> */}

            <View style={styles.PickerMainView}>
              <View style={{ width: "70%" }}>
                {/* <Text style={{ fontSize: 20, color: color.white }}>Duration</Text> */}
                <CommonSectionHeader heading={"Duration"}  />
                {this.renderSubHeadingBorder("", false, true)}
              </View>
              <View style={{ width: "30%" }}>
                <Picker
                  enabled={(this.state.isLocked&&this.state.has_followup_appointment)? false : (this.state.isLocked&&!this.state.has_followup_appointment)? false : (!this.state.isLocked&&this.state.has_followup_appointment)? false : true}
                  selectedValue={this.state.duration_type}
                  style={[{height: '2.28vw', fontSize: '0.8vw', paddingLeft: '0.65vw', color: 'Black',backgroundColor:"#E2E2E2" },Style.allButtonBorderRadius]}
                  itemStyle={{ fontSize: '0.65vw' }}
                  onValueChange={(itemValue, itemIndex) => {
                    this.setState({
                      duration_type: itemValue
                    },()=>{
                      this.changeReviewDate()
                    })
                  }}
                >
                  <Picker.Item label="Days" value="Days" />
                  <Picker.Item label="Weeks" value="Weeks" />
                  <Picker.Item label="Months" value="Months" />
                  <Picker.Item label="Years" value="Years" />
                </Picker>
              </View>
            </View>


            <View style={{ flexDirection: "column" }}>
              <View
                style={styles.PickerMainView}
              >
                <TouchableOpacity>
                  {/* renderTextBtn */}
                  {this.renderSelectEyes({ value: 1, label: "01" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 2, label: "02" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 3, label: "03" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 4, label: "04" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 5, label: "05" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 6, label: "06" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
              </View>

              <View
                style={styles.PickerMainView}
              >
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 7, label: "07" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 8, label: "08" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 9, label: "09" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 10, label: "10" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 11, label: "11" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
                <TouchableOpacity>
                  {this.renderSelectEyes({ value: 12, label: "12" }, false, false, 'selectedDuration', this.state.selectedDuration)}
                </TouchableOpacity>
              </View>
            </View>

            <View>
              <CommonHistorySectionHeader
                  heading1={"Review Date"}
                  heading2={"Appointment"}
                  
                  //total value is == of 1
                  noOfColumn={2}
                />
              <View style={{ marginTop: '0.97vw', width: "40%" }}>
                  {/* <Text style={{ color: 'white', fontSize: 20 }}>Review Date</Text> */}
                 
                  {this.renderSubHeadingBorder("", false, true)}
              </View>

             <View style={{   flexDirection: "row", marginBottom: '1.3vw'}}>  
              <View
                style={{
               
                  // marginTop: 20,
                  alignItems: "center",
                  width: '50%'
                }}>
                

                {/* <View style={{ width: "60%" }}>
                  <TouchableOpacity style={styles.calendarView} onPress={() => { this.showCalender("From") }}>
                    <Text style={[styles.calendarText,{ borderColor: color.rightPanelInputBorder, color: color.themeBlue }]}>{this.state.filterFromDate === "" ? "Date" : this.state.filterFromDate}</Text>
                  </TouchableOpacity>
                </View>
                {this.popUpCalender()} */}


                <DatePicker clearIcon={false}
                  disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false}
                  value={this.state.filterFromDate ? moment(this.state.filterFromDate)
                    : null}
                  format={"DD-MM-YYYY"}
                  onChange={date => {
                    var fulldate = new Date(date);
                    var converted_date = moment(fulldate).format("YYYY-MM-DD");
                    // this.setState({ filterToDate: converted_date })
                    this.setState({ filterFromDate: converted_date}, () => { this.updateState("filterFromDate", this.state.filterFromDate) })
                    this.setState({selectedDuration: null, duration_type:"Days"})

                  }}
                  disabledDate={current => {
                    return current && current < moment().endOf('day');
                  }}                 
                  
                  // format={"YYYY-MM-DD"}
                  placeholder={"Date"}
                  style={{
                    backgroundColor: "transparent", 
                    borderColor: color.lightGray,
                    borderWidth: 1,
                    marginTop: '0.32vw',
                    height: '2.28vw',
                    borderWidth: 1,
                    borderRadius: '0.26vw',
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: '0.65vw',
                    paddingLeft: '0.65vw',
                    paddingRight: '0.65vw',
                    // width: "30%",
                    marginRight: '0.65vw',
                    fontSize:'0.91vw'
                  }}
                />

              </View>

              <View
                style={styles.TimePickerView}>
                {/* <View style={{ width: "40%", marginTop: 20 }}>
                  {this.renderSubHeadingBorder("", false, true)}
                </View>
                <View style={{ width: "60%" }}> */}
                  {this.timePicker()}
                  {/* </View>
              </View> */}
              
            </View>
            </View>
          </View> 
            {/* {this.renderReviewTemplate()} */}
            
            <View style={{zIndex:-1}}>
              <CommonSectionHeader heading={"Comments"}  />
              {this.renderSubHeadingBorder("", false, true)}
            </View>
            
            <View style={styles.CommentsPickerView}>
              
              {/* <View style={{ width: "4%" }}>
                {this.renderSubHeadingBorder("", false, true)}
              </View> */}
              <View style={{ width: "100%" }}>
                <Picker
                  enabled={!this.state.isLocked}
                  selectedValue={this.state.selectedTemplate}
                  style={[{height: '2.28vw', fontSize: '0.8vw', paddingLeft: '0.8vw', backgroundColor: "white", color: 'Black', },Style.allButtonBorderRadius]}
                  itemStyle={{ fontSize: '0.65vw' }}
                  onValueChange={(itemValue, itemIndex) => {
                    this.setState({
                      selectedTemplate: itemValue
                    },()=>{
                      this.getTemplateDescription(itemValue)
                    })
                  }}
                >
                  <Picker.Item label="Select comments" value="" />
                  {this.state.templateData && this.state.templateData.length>0 && this.state.templateData.map((item, index)=>{
                    return (item.template_name?<Picker.Item label={item.template_name} value={item.id} />:null)
                  })}
                </Picker>
              </View>
            </View>


            {/* {this.renderTextFieldView("Comments")} */}

            {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4)}

            {this.state.isTemplate?
            <View style={{ marginHorizontal: '1.95' }}>
              <TextInput
                placeholder={"Template Name"}
                mode="flat"
                underlineColor='white'
                multiline={true}
                style={styles.CommentTxtInput}
                ref={text => (this.nameInput = text)}
                autoCapitalize="none"
                value={this.state.template_name}
                onChangeText={text => this.setState({ template_name: text })}
              />
              <View style={styles.BtnWrapper}>
                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.saveTemplate()
                }} style={styles.BtnView}>
                  <Text style={styles.BtnText}>{"Save"}</Text>
                </TouchableOpacity>
                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.setState({ isTemplate: false })
                }} style={[styles.BtnView, { marginLeft: '1.3vw' }]}>
                  <Text style={styles.BtnText}>{"Cancel"}</Text>
                </TouchableOpacity>
              </View>
            </View>:null}
            {this.ConfirmReviewAppointmentModal()}
            {!this.state.isTemplate?
            <View style={{ marginTop: '1.3vw', zIndex: -10, flexDirection: "row", justifyContent: "center", alignContent:"center"  }}>
              
                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                  this.setState({
                    isTemplate: true
                  })
                }} >
                  {this.renderTextBtn("Add to templates", true, false)}
                </TouchableOpacity>
              
              <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this)}>
                {this.renderTextBtn("Add", true, false)}
              </TouchableOpacity>
            </View>:null}

            <View style={{ marginTop: '1.3vw', zIndex: -1 }}>

              {/* <TouchableOpacity
                onPress={() => this.setState({ isTemplate: true })}
              >
                {this.renderTestIconBtn("Add to Templates", "", true)}
              </TouchableOpacity> */}

            </View>
              
          </View>
        
      </TouchableWithoutFeedback>

    );
  }

  // renderTestIconBtn = (text, icon, createBtn) => {
  //   return (
  //     <View
  //       style={{
  //         height: 40,
  //         width: text === "New" ? 70 : "100%",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: createBtn || text === "New" ? 10 : 10,
  //         flexDirection: "row",
  //         backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themeBlue,
  //         justifyContent: "space-evenly",
  //         borderWidth: createBtn || text === "New" ? 1 : 1,
  //         borderColor: "#F25364"
  //       }}
  //     >
  //       <Text
  //         style={{
  //           fontSize: platform === "web" ? 17 : 14,
  //           color: text === "New" ? "#848484" : "#F25364",
  //           fontWeight: "500"
  //         }}
  //       >
  //         {text}
  //       </Text>


  //     </View>
  //   );
  // };

  showCalender(date) {
    this.setState({ showCalenderModal: !this.state.showCalenderModal, showCalenderModalFrom: date });
  }

  popUpCalender() {
    if (this.state.showCalenderModal) {

      return (
        <View style={{ width: '80%', zIndex: 1, position: 'absolute', marginTop: 60, marginLeft: 40 }}>
          <Calendar
            style={{
              borderWidth: 1,
              borderColor: 'gray',
            }}
            theme={{
              backgroundColor: '#ffffff',
              calendarBackground: 'grey',
              // textSectionTitleColor: 'red',
              selectedDayBackgroundColor: 'yellow',
              selectedDayTextColor: 'yellow',
              todayTextColor: '#099ef9',

              dayTextColor: 'black',
              // textDisabledColor: '#d9e1e8',
              // dotColor: '#00adf5',
              // selectedDotColor: '#ffffff',
              // arrowColor: 'red',
              monthTextColor: 'black',
              textDayFontFamily: 'monospace',
              textMonthFontFamily: 'monospace',
              textDayHeaderFontFamily: 'monospace',
              textMonthFontWeight: 'bold',
              textDayFontSize: 13,
              textMonthFontSize: 13,
              textDayHeaderFontSize: 13
            }}
            // current={'2020-05-23'}
            minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
            maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate}
            onDayPress={(day) => {
              var getFilterDate = day.dateString
              this.setState({ showCalenderModal: false });
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: getFilterDate }, () => { this.updateState("fromDate", this.state.filterFromDate) })
              } else {
                this.setState({ filterToDate: getFilterDate }, () => { this.updateState("toDate", this.state.filterToDate) })
              }
            }}
            onDayLongPress={(day) => { console.log('selected day is', day) }}
            // monthFormat={'yyyy MM'}
            onMonthChange={(month) => { }}
            hideArrows={false}
            renderArrow={(direction) => {
              if (direction === "left") {
                return (
                  <Icon
                    size={30}
                    name={"angle-left"}
                  />
                )
              } else if (direction === "right") {
                return (
                  <Icon
                    size={30}
                    name={"angle-right"}
                  />
                )
              }
            }}
            hideExtraDays={false}
            disableMonthChange={false}
            // firstDay={1}
            // hideDayNames={true}
            // showWeekNumbers={true}
            onPressArrowLeft={substractMonth => substractMonth()}
            onPressArrowRight={addMonth => addMonth()}

          />
          <View style={{ justifyContent: 'space-evenly', backgroundColor: 'grey', borderTopWidth: 1, borderTopColor: '#b6b6b6', padding: 10, flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => {
              this.showTodayDateInFilter()
            }}>
              <Text>{"Today"}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: "", showCalenderModal: false })
              } else {
                this.setState({ filterToDate: "", showCalenderModal: false })
              }
            }}>
              <Text>{"Clear"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View />
      )
    }
  }

  showTodayDateInFilter() {
    if (this.state.showCalenderModalFrom === "From") {
      var toYear = parseInt(this.state.filterToDate.substring(0, 4));
      var toMonth = parseInt(this.state.filterToDate.substring(5, 7));
      var toDate = parseInt(this.state.filterToDate.substring(8, 10));

      var fromYear = parseInt(moment().format("YYYY"));
      var fromMonth = parseInt(moment().format("MM"));
      var fromDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      } else {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterToDate: "" },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      }
    } else {
      var fromYear = parseInt(this.state.filterFromDate.substring(0, 4));
      var fromMonth = parseInt(this.state.filterFromDate.substring(5, 7));
      var fromDate = parseInt(this.state.filterFromDate.substring(8, 10));

      var toYear = parseInt(moment().format("YYYY"));
      var toMonth = parseInt(moment().format("MM"));
      var toDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      } else {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterFromDate: "" },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      }
    }
  }

  chegeAppoinmentModalTime() {
    if (!this.state.modalVisible) {
      var today = new Date();
      var hours = parseInt(today.getHours());
      var minutes = '0'; //today.getMinutes()
      if (hours > 12) {
        this.state.hour12Formet = hours - 12;
        this.state.hour12Formet = this.state.hour12Formet < 10 ? '0' + this.state.hour12Formet : this.state.hour12Formet;
      } else {
        this.state.hour12Formet = hours;
      }
      // var amPm= hours >= 12 ? 'pm':'am';
      minutes = minutes < 10 ? '0' + minutes : minutes;
      this.state.minutes = minutes;
      // this.state.findAmPm = this.state.hours >= 12 ? 'PM' : 'AM';
      var changeAmPm = hours >= 12 ? 'PM' : 'AM';
      this.setState({ findAmPm: changeAmPm })

    }
  }

  timePicker() {
    var getHours = []
    var getMinutes = ['00','15','30','45'];
    for (var i = 1; i <= 12; i++) {
      var hour = i < 10 ? '0' + i : i;
      getHours.push(hour)
    }
    // for (var j = 0; j <= 59; j++) {
    //   var minutes = j < 10 ? '0' + j : j;
    //   getMinutes.push(minutes)
    // }
    return (
      // <View style={{ flexDirection: 'row', justifyContent: 'flex-end', flex: 0.3, alignSelf: 'center', }}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignContent: "center" }}>
        <View style={styles.TimePickerView}>
          <View style={styles.TimePickerContentView}>
            <TouchableOpacity disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false} onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer }) }} style={{ justifyContent: 'center' }}>
              <Text style={{ color: color.themeBlue, fontSize:'0.91vw' }}>{this.state.hour12Formet}{"  :  "}{this.state.minutes}</Text>
            </TouchableOpacity>
            <TouchableOpacity disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false} onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer }) }} style={{ justifyContent: 'center', marginLeft: '0.19vw' }}>
              <Icon
                size={'1.3vw'}
                name={"angle-down"}
                style={{ color: color.themeBlue }}
              />
            </TouchableOpacity>
          </View>
          {
            this.state.showNextAppoinmentTimer ?
              <TouchableWithoutFeedback onPress={() => { this.setState({ showNextAppoinmentTimer: true }) }}>
                <View style={styles.TimerListView}>
                  <ScrollView style={{ height: '7.16vw' }} showsVerticalScrollIndicator={false}>
                    <View style={{ flexDirection: 'column', width: '1.95vw', }}>
                      {
                        getHours.map((hour) => {
                          return <TouchableOpacity onPress={() => { this.changeTime(hour, 'hr') }} style={{ padding: '0.19vw' }}><Text style={{ color: hour === this.state.hour12Formet ? color.themeDark : color.white, fontSize:'0.91vw' }}>{hour}</Text></TouchableOpacity>
                        })
                      }
                    </View>
                  </ScrollView>
                  <ScrollView style={{ height: '6.51vw' }} showsVerticalScrollIndicator={false}>
                    <View style={{ flexDirection: 'column' }}>
                      {
                        getMinutes.map((min) => {
                          return <TouchableOpacity onPress={() => { this.changeTime(min, 'mm') }} style={{ padding: '0.19vw' }}><Text style={{ color: min === this.state.minutes ? color.themeDark : color.white , fontSize:'0.91vw'}}>{min}</Text></TouchableOpacity>
                        })
                      }
                    </View>
                  </ScrollView>
                </View>
              </TouchableWithoutFeedback>
              :
              null
          }
        </View>

        {/*   */}
        <View style={styles.AmPmBtnView}>
          <TouchableOpacity disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false} onPress={() => { this.changeTime('AM', 'ampm') }} style={[{ backgroundColor: this.state.findAmPm === "AM" ? color.themeDark : null},styles.AmpPmBtn]}>
            <Text style={[{ color: this.state.findAmPm === "AM" ? 'white' : color.black }, styles.AmPmText]}>{"AM"}</Text>
          </TouchableOpacity>
          <TouchableOpacity disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false} onPress={() => { this.changeTime('PM', 'ampm') }} style={[{ backgroundColor: this.state.findAmPm === "PM" ? color.themeDark : null,marginLeft: '1.3vw' }, styles.AmpPmBtn]}>
            <Text style={[{ color: this.state.findAmPm === "PM" ? 'white' : color.black }, styles.AmPmText]}>{"PM"}</Text>
          </TouchableOpacity>
          <TouchableOpacity disabled={(this.state.isLocked&&this.state.has_followup_appointment)? true : (this.state.isLocked&&!this.state.has_followup_appointment)? true : (!this.state.isLocked&&this.state.has_followup_appointment)? true : false} onPress={() => { this.clearComplaint() }} style={[{ backgroundColor: this.state.has_followup_appointment? color.grayShade: color.themeDark, marginLeft: '1.3vw' }, styles.AmpPmBtn]}>
            <Text style={styles.CloseBtnText}>{"  X"}</Text>
          </TouchableOpacity>
        </View>


      </View>
    )
  }
  changeTime(time, type) {
    if (type === 'hr') {
      this.setState({ hour12Formet: time });
    } else if (type === 'mm') {
      this.setState({ minutes: time });
    } else if (type === 'ampm') {
      this.setState({ findAmPm: time });
    }
  } 
  getTemplateDescription=(template_id)=>{

    var service_url = Constants.OPT_REVIEW_PLAN_COMMENTS_TEMPLATE + "?template_id=" + template_id; //this.state.data.id;

    if(Constants.ROLE_FO == this.state.userType){
      service_url= service_url +"&doctor_id="+this.state.patientAppointment.doctor_id
    }


    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTemplateDescriptionSuccess,
      this.getTemplateDescriptionFailure
    );
  };

  getTemplateDescriptionSuccess = response => {

    if (response.status === "success") {

      let data = response.data;
      let template_comments = "";

      if(data){
        template_comments = data.comments;
      }

      this.setState({
        comments: template_comments
      });
      
    }
  };

  getTemplateDescriptionFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  }

  // renderReviewTemplate(){
  //   let { templateData } = this.state;
    
  //   return(
  //     <View style={{
  //       flexDirection: "row",
  //       justifyContent: "flex-start",
  //       alignContent: "center",
  //       marginBottom: 20, 
  //       zIndex: -1
  //     }}>
  //       <View style={{ width: "40%" }}>
  //         {this.renderSubHeadingBorder("Comments", false, true)}
  //       </View>
  //       <View style={{ width: "60%" }}>
  //         <Picker
  //           enabled={!this.state.isLocked}
  //           selectedValue={this.state.selectedTemplate}
  //           style={[{height: 35, fontSize: 12, paddingLeft: 10, backgroundColor: "white", color: 'Black', },Style.allButtonBorderRadius]}
  //           itemStyle={{ fontSize: 10 }}
  //           onValueChange={(itemValue, itemIndex) => {
  //             this.setState({
  //               selectedTemplate: itemValue
  //             },()=>{
  //               this.changeReviewDate()
  //             })
  //           }}
  //         >
  //           <Picker.Item label="Select comments" value="" />
  //           {templateData && templateData.length>0 && templateData.each((item, index)=>{
  //             return (<Picker.Item label="{{item.template_name}}" value="{{item.id}}" />)
  //           })}
  //         </Picker>
  //       </View>
  //     </View>
  //   )
  // }

}
const styles = StyleSheet.create({
  calendarView: {
    borderColor: "#525252",
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    marginRight: 10
  },
  calendarText: {
    color: color.white,
    width: "90%"
  },
  PickerMainView:{
    flexDirection: "row",
    marginTop: '1.3vw',
    justifyContent: "space-between"
  },
  TimePickerView:{
    flexDirection: "row",
    alignItems: "center",
    width: '50%',
    marginTop: '0.32vw',
    // marginBottom: 20,
    zIndex: -1
  },
  TimePickerView:{
    flexDirection: 'column', 
    marginTop: '0.32vw',
  },
  TimePickerContentView:{
    flexDirection: 'row', 
    justifyContent: 'center', 
    borderColor: color.rightPanelInputBorder, 
    borderWidth: 1, 
    borderRadius: '0.32vw', 
    paddingVertical: '0.26vw', 
    paddingHorizontal: '0.52vw'
  },
  TimerListView:{
    flexDirection: 'row', 
    backgroundColor: '#262941', 
    position: 'absolute', 
    zIndex: 10, 
    marginTop: '1.82vw', 
    paddingHorizontal: '0.52vw', 
    borderRadius: '0.32vw'
  },
  AmPmBtnView:{
    flexDirection: 'row', 
    justifyContent: 'center', 
    alignContent: "center", 
    marginTop: '0.32vw', 
    marginLeft: '1.3vw'
  },
  AmpPmBtn:{
    borderWidth: 1, 
    borderColor: "#888888", 
    borderRadius: '3.25vw', 
    width: '1.95vw', 
    height: '1.95vw' 
  },
  AmPmText:{
    fontSize: '0.91vw', 
    marginTop: '0.32vw', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginLeft: '0.13vw'
  },
  CloseBtnText:{
    color: 'white', 
    fontSize: '0.91vw', 
    marginTop: '0.32vw', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginLeft: '0.13vw'
  },
  CommentsPickerView:{
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    marginBottom: '1.3vw', 
    zIndex: -1
  },
  CommentTxtInput:{
    height: '2.28vw',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#B3D9FE',
    primary: "white",
    borderRadius: '0.65vw',
    padding: '0.32vw' ,
    borderRadius: ".25vw",
    fontSize:'0.91vw'
  },
  BtnWrapper:{
    marginTop: '0.97vw', 
    flexDirection: "row", 
    justifyContent: 'flex-end', 
    alignContent: 'center'
  },
  BtnView:{
    alignSelf: "center", 
    paddingVertical: '0.52vw', 
    paddingHorizontal: '0.97vw', 
    borderRadius: '0.26vw', 
    backgroundColor: color.themeDark,
  },
  BtnText:{
    fontSize: '0.97vw', 
    color: color.white, 
    textAlign: 'center'
  },
  ConfirmModalMainView:{
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.43vw'
  },
  ModalWrapper:{
    margin: '1.3vw',
    backgroundColor: 'white',
    borderRadius: '1.3vw',
    padding: '2.28vw',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: '0.13vw',
    },
    shadowOpacity: 0.25,
    shadowRadius: '0.26vw',
    elevation: '0.32vw',
  },
  ModalButton:{
    justifyContent: "center",
    alignItems: "center",
    borderRadius:  '1.3vw',
    flexDirection: "row",
    borderColor: color.white,
    borderWidth: 1,
    backgroundColor: color.themeDark,
    padding: '1vw',
    paddingTop: '0.26vw',
    paddingBottom: '0.26vw',
    minWidth: '5.86vw',
    marginBottom: '0.65vw',
    // marginRight: 2
    marginLeft: '0.97vw',
    borderRadius: ".25vw"
  },
  ModalText:{
    fontSize:'0.91vw'
  },
  ModalCloseBtn:{
    paddingLeft:'0.97vw', 
    fontsize:'9vw',
    color:'red'
  }
})