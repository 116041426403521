import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  TextInput,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import AsyncStorage from "../../../AsyncStorage";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
import {CommonHistorySectionHeader,CommonSectionHeader} from '../BaseComponent'

import { CheckBox } from 'react-native-elements'
import {CommonButton} from '../BaseComponent'
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const platform = Platform.OS;
const pageTitle = "Diagnosis";
const pageName = "assessment";
const isPageName = "isAssessment";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      isAssessment: isPageName === this.props.selectedView ? true : false,
      data:{},

      provisionalDiagnosis:"",
    
      getAssessmentData:[],

      tempId:0,

      userType: "",

      clinicPsychiatrySyndrome:"",
      specificDelaysOfDevelopment:"",
      generalIntellectualFunctioning:"",
      medicalProblem:"",
      abnormalFamily:"",
      id:"",
      psyMultiAxialData:{},

        
      psyMotivationData:[],
   

       poorMotivation :[],
       shallowMotivation :[],
       goodMotivation :[],

       selectedMotivation:[],
       psyMotivationId:[],
       uncheckedID:[],

       backUpSelectedMotivation:[],
       showDiagnosisSave:false,
      //  dropDownClose:this.props.dropDownClose,
     
    };
    this.getPsychiatryMotivation("template");
  }

  async componentDidMount() {
setTimeout(() => {
  this.getAssessmentDetails();
  this.getPsychiatryDetails();  //---------- text box
  this.getPsychiatryMotivation("value");
}, 5000);
  

    const userType = await AsyncStorage.getItem("service_type");

    if(userType) {
      this.setState({ userType: userType });
    }


  }

  removeDuplicates(data) {

    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  componentWillReceiveProps(props) {

    if (props.reloadData && props.reloadData === pageName) {
     if (this.state.userType === "PSY") {
      this.getPsychiatryDetails();
     }
      this.props.refreshData("");
    }

    if (props.getAssessmentData && props.getAssessmentData.assessment && (props.getAssessmentData.assessment.icd_code || props.getAssessmentData.provisional_diagnosis)) {
      
      
      var {getAssessmentData} = this.state;
      if(props.getAssessmentData.assessment.tempId  && getAssessmentData.length > 0 ){
        for (let i = 0; i < getAssessmentData.length; i++) {

          if(props.getAssessmentData.assessment.tempId == getAssessmentData[i].tempId){
            getAssessmentData.splice(i, 1);
            getAssessmentData.push(props.getAssessmentData.assessment);
            // alert(props.getAssessmentData.provisional_diagnosis)
            this.setState({ 
              provisionalDiagnosis:props.getAssessmentData.provisional_diagnosis,
              getAssessmentData: JSON.parse(JSON.stringify(getAssessmentData)) });
            this.props.clearAssessmentData({})
            return
          }
          else  if( props.getAssessmentData.assessment.tempId == ""){
            var data=props.getAssessmentData.assessment

            // const checIce10Code = getAssessmentData.includes(data.icd_code);

            // if(!checIce10Code){

              data["tempId"]=this.state.tempId+1;
              getAssessmentData.push(data);
  
            // alert(props.getAssessmentData.provisional_diagnosis)
              this.setState({ 
                provisionalDiagnosis:props.getAssessmentData.provisional_diagnosis,
                getAssessmentData: JSON.parse(JSON.stringify(getAssessmentData)) });  
              this.props.clearAssessmentData({})
              return
            // }else{
            //   alert("icd code already is there ")
            // }

          }
        }
      } else{
        var data=props.getAssessmentData.assessment

        data["tempId"]=this.state.tempId+1;
        if(data.icd_code){

        getAssessmentData.push(data);
        }
        // alert(props.getAssessmentData.provisional_diagnosis)
        this.setState({
          provisionalDiagnosis:props.getAssessmentData.provisional_diagnosis,
          getAssessmentData: getAssessmentData });  
        this.props.clearAssessmentData({})
      }
    }
    // else if(props.getAssessmentData){
      // alert(props.getAssessmentData.provisional_diagnosis)
      // this.setState({ 
      //   provisionalDiagnosis:props.getAssessmentData.provisional_diagnosis,
      //  });  
    // }

    


    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isSymptoms) {

      this.setState({ isSymptoms: updateFontColor });
    }
  }

  getAssessmentDetails = () => {
    
    var service_url = Constants.ASSESSMENT_GET +"?appointment_id=" + this.state.patientAppointment.appointment_id  + "&patient_id=" + this.state.patientAppointment.patient_id;

setTimeout(() => {
  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getAssessmentDetailsSuccess,
    this.getAssessmentDetailsFailure
  );
}, 5000);

  };

  getAssessmentDetailsSuccess = response => {
    if (response.status === "success") {
      var {getAssessmentData} =this.state;

      var getIcd_codes =response.data.icd_codes

      var getAssessment = []

      for (let i = 0; i < getIcd_codes.length; i++) {
        var data=getIcd_codes[i]

        // data["tempId"]=getIcd_codes[i].id;
        data["tempId"]=this.state.tempId+1;
        getAssessment.push(data)
        
      }

      getAssessmentData = getAssessment
      // alert(response.data.provisional_diagnosis)


      this.setState({ 
        provisionalDiagnosis:response.data.provisional_diagnosis,
        getAssessmentData
      });  
    }
  };

  getAssessmentDetailsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderAssessmentItem = (item, index) => {
    return (
      // 0.15, 0.33, 0.35, 0.17
      <View key={index}
            style={{borderBottomColor : color.sectionItemLine,borderBottomWidth:1, width:  "100%" , flexDirection: "row", 
              paddingVertical:'0.6vw'
            }}
          >
            <View style={styles.TableDataView} >
              <Text style={[Style.contentTableTitle,{color:color.textcolor}]}>{item.icd_code}</Text>
            </View>

            <View style={{flex:0.33}}>
              <Text style={[Style.contentTableTitle,{color:color.textcolor}]}>{item.short_desc}</Text>
            </View>
            <View style={{flex:0.35}}>
              <Text style={[Style.contentTableTitle,{color:color.textcolor}]}>{item.desc}</Text>
            </View>
            <View style={{flex:0.17}}>
              
            <View
                style={{
                  flexDirection: "row",
                  paddingTop: '0.3vw',
                  paddingBottom: '0.3vw',
                  backgroundColor: color.white,
                  height: '3.3vw'
                }}
              >
                <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, index)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>

    );
  };

  // getComplaintsType (type) {

  //   let itemType = "";

  //   let temp = Constants.eye_values.map((item,index)=>{

  //     if(type === item.value) { itemType = item.label; }
  //     return (
  //       null
  //     )
  //   })
  //   return itemType;
  // }

  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    var data = item;

    data["provisionalDiagnosis"]= this.state.provisionalDiagnosis

    this.props.editItem(pageName, data);
  }

  deleteItem = (index, event) => {
    this.props.triggerNewItem(isPageName);
    var {getAssessmentData} = this.state;
    getAssessmentData.splice(index,1);
    this.setState({ getAssessmentData })
  };

  getDeleteSuccess = response => {
    if (response.status === "success") {

      this.getSymptoms();
    }
  };

  triggerNewItem = (key) => {
    // alert(key)
    this.props.triggerNewItem(key);
    setTimeout(()=>{
       this.props.editItem(pageName,this.state.psyMultiAxialData)
    },500)
  }

  saveAssessment(){

   var {getAssessmentData } = this.state;

    var icd_codes = []
    if (getAssessmentData.length > 0) {
      for (let i = 0; i < getAssessmentData.length; i++) {
        icd_codes.push(getAssessmentData[i].icd_code)
      }
    }
    // alert(this.state.provisionalDiagnosis)
    var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "icd_codes": icd_codes,
      "provisional_diagnosis":this.state.provisionalDiagnosis
  }

  var service_url = Constants.ASSESSMENT_POST
  OpthamologyService.getInstance().postComplaints(
    service_url,
    data,
    this,
    this.saveAssessmentDetailsSuccess,
    this.saveAssessmentDetailsError
  );

  }

  saveAssessmentDetailsSuccess = success =>{
    if(success.status == "success"){
      this.getAssessmentDetails();
      this.props.showResposeValue("success",success.message)
    }else{
      this.props.showResposeValue("error",success.message)
    }
  }
  saveAssessmentDetailsError = error =>{
    this.props.showResposeValue("error",error.message)
  }
  getPsychiatryDetails(){

    var service_url=Constants.PSY_DIAGNOSIS_MULTI_AXIAL_GET + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id
   setTimeout(() => {
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPsychiatryMultiAxialSuccess,
      this.getPaychiatryMultiAxialFailure
     );
   }, 5000);
  
  } 

  getPsychiatryMultiAxialSuccess=(response)=>{
        if(response.status=="success"){
          this.setState({
            
            psyMultiAxialData:response.data,
            psyId: response.data.id,

            clinicPsychiatrySyndrome:response.data.axis_one_clinical_psy_syndrome,
            specificDelaysOfDevelopment:response.data.axis_two_specific_delays_of_development,
            generalIntellectualFunctioning:response.data.axis_three_general_intelluctual_functioning,
            medicalProblem:response.data.axis_four_medical_problem,
            abnormalFamily:response.data.axis_five_abnormal_family_or_psy_socail_situations
          })
        }
   }
   getPaychiatryMultiAxialFailure=(error)=>{
    this.props.showResposeValue("error", error.message);


   }

  //  getMultiAxialAutoSuggest(){
  //     var service_url=Constants.PSY_MULTI_AXIAL_AUTO_SUGGEST_GET + "?search_key=" +this.state.searchKey + "&search_tab=" +this.state.searchTab
  //     OpthamologyService.getInstance().getComplaints(
  //       service_url,
  //       this,
  //       this.getMultiAxialAutoSuggestSuccess,
  //       this.getMultiAxialAutoSuggestFailure
  //     )
  //  }
  //  getMultiAxialAutoSuggestSuccess(response){
  //      if(response.status=="success"){
  //          this.setState({
  //           searchTab:success.data
  //          })

  //     }
  //  }
  //  getMultiAxialAutoSuggestFailure(){

  //  }
  renderMultiAxialDiagnosis(header1,header2,value,key,value2,key2){
    return(
          <View style={{marginBottom:'0.6vw',marginTop:'0.6vw'}}>
               <CommonHistorySectionHeader
                 heading1={header1}
                 heading2={header2}
                 noOfColumn={2}
                 columnSize={[0.50,0.50]}
           
               />
              <View style={{flexDirection:"row"}}>
               <TextInput 
                  editable={false}
                   placeholder=""
                   multiline={true} 
                   value={value} 
                   onChangeText={(text)=>{
                     var states=this.state
                     states[key]=text
                     this.setState({
                       states
                     })
                   }} 
                   style={[styles.MultiAxialDataTextInput, {borderColor:color.lightGray}]}
                /> 
                 <TextInput 
                  editable={false}
                  placeholder="" 
                  multiline={true} 
                  value={value2} 
                  onChangeText={(text)=>{
                    var states=this.state
                    states[key2]=text
                    this.setState({
                      states
                    })
                  }} 
                  style={[styles.MultiAxialDataTextInput1, {borderColor:color.lightGray}]}
               /> 
             </View>     
             
              </View>
           )
         }
         clearMultiAxialData() {
          var states = this.state
          states["clinicPsychiatrySyndrome"] = ""
          states["specificDelaysOfDevelopment"] = ""
          states["generalIntellectualFunctioning"] = ""
          states["medicalProblem"]=""
      
          this.setState({
            states
          })
        }
   getPsychiatryMotivation(searchKey){
     var service_url=Constants.PSY_MOTIVATION_GET + "?search_key="+searchKey+"&patient_id="+this.state.patientAppointment.patient_id+ "&appointment_id=" + this.state.patientAppointment.appointment_id
    
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getPsychiatryMotivationSuccess,
        this.getPsychiatryMotivationFailure,
        searchKey
       )
    }, 5000);
    
   }
   getPsychiatryMotivationSuccess=(response,searchKey)=>{

     if(response.status=='success'){
       if(searchKey == "template"){

        let poorMotivation =[];
        let shallowMotivation =[];
        let goodMotivation =[];

        var motivation=response.data.result;
        
        for(var i=0; i<motivation.length;i++){
            if(motivation[i].motivation_type == "Poor Motivation"){
              poorMotivation.push(motivation[i])
            }else if(motivation[i].motivation_type == "Superficial/Shallow Motivation"){
              shallowMotivation.push(motivation[i])
            }else if(motivation[i].motivation_type == "Fair/Good Motivation"){
              goodMotivation.push(motivation[i])
            }
        }
       
        // alert(JSON.stringify(motivation))
       
         this.setState({
          poorMotivation:poorMotivation,
          shallowMotivation:shallowMotivation,
          goodMotivation:goodMotivation,
          psyMotivationId:response.data.id,

          psyMotivationData:response.data.result          
         })
        }else if(searchKey == "value"){

        // alert(JSON.stringify(response.data.result))
        var motivation=response.data.result;
        var getSelectedMotivation = []
        for(var i=0; i<motivation.length > 0;i++){
          var prepareDate ={"motivation_id":motivation[i].motivation_id,"_id":motivation[i].id};
          getSelectedMotivation.push(prepareDate)
        }

        // this.setState({

        //   backUpSelectedMotivation:getSelectedMotivation
          
        // })
        var states = this.state;
        states["backUpSelectedMotivation"]=getSelectedMotivation;
        states["selectedMotivation"]=getSelectedMotivation;

          // this.setState({

          //   selectedMotivation:getSelectedMotivation,            
          // })
        }
    }
  }
   getPsychiatryMotivationFailure=()=>{
     console.log("error")
   }
   

   selectMotivation(template){

    // alert(JSON.stringify(this.state.backUpSelectedMotivation))

    var prepareDate ={"motivation_id":template.id };

    var {selectedMotivation,backUpSelectedMotivation} = this.state;

    for (let i = 0; i < backUpSelectedMotivation.length; i++) {
     if(backUpSelectedMotivation[i].motivation_id == template.id){
      // "id": 189,
      prepareDate["_id"] =backUpSelectedMotivation[i].id

     }
      
    }
      // if(selectedMotivation.length > 0){


        // var { selectedMotivation } = this.state;

        var getSelectedValue;
        var spliceData;

        if (this.state.selectedMotivation.length > 0) {
            for (var i = 0; i < this.state.selectedMotivation.length; i++) {
                if (this.state.selectedMotivation[i].motivation_id == prepareDate.motivation_id) {
                    spliceData = this.state.selectedMotivation[i];
                    var temp =JSON.parse(JSON.stringify(this.state.selectedMotivation))
                    temp.splice(i, 1)
                    
                    var data = this.removeDuplicate(temp);
                    this.setState({ selectedMotivation: data },()=>{
                      
                    })
                    return
                } else {
                    if (spliceData && spliceData.motivation_id !== prepareDate.motivation_id) {
                    } else {
                        getSelectedValue = prepareDate
                        this.setState({
                          selectedMotivation
                        })
                    
                    }
                }
            }

            if (getSelectedValue) { selectedMotivation.push(prepareDate); }
        } else {


          selectedMotivation.push(prepareDate)
        }

        var selectedMotivation = this.removeDuplicate(selectedMotivation);

        this.setState({ selectedMotivation },()=>{
        })

        // var data = this.removeDuplicate(selectedMotivation);
        // this.setState({ selectedMotivation: data },()=>{
        //   alert("back up -------------->1  "+JSON.stringify(this.state.backUpSelectedMotivation))
        // })
   }

   checkSelectedMotivation(tempId){


    var {selectedMotivation} = this.state;
    var returnValue;
    if(selectedMotivation.length > 0){

      for (let i = 0; i < selectedMotivation.length; i++) {
        if(selectedMotivation[i].motivation_id == tempId.id){
          returnValue = true;
          return returnValue
        }
        
      }

    }else{
      returnValue=false
    }

     return returnValue
   }

   renderPsychiatryMotivation(template,index){
     return(
       <View key={index} >
          <CheckBox title={template.motivation_type_desc}
                   checked={this.checkSelectedMotivation(template)}   
                    
                   onPress={()=>{
                    this.selectMotivation(template);
                  }}
                     
                    containerStyle={{
                    borderWidth:0,
                    backgroundColor:color.white,
                    checkedColor:color.themeDark
                  }}
                  textStyle={{fontSize:13,fontWeight:"unbold"}}
                  size={{size:10}}
                
         />
       </View>
     )
   }   
  onPressMotivationSave() {


    // alert("SelectedMotivation ----->"+JSON.stringify(this.state.selectedMotivation))
        var getUnselectedIds = this.state.backUpSelectedMotivation.filter(entry1 => !this.state.selectedMotivation.some(entry2 => entry1.motivation_id === entry2.motivation_id));
  
  //  alert("getUnselectedIds ----->"+JSON.stringify(getUnselectedIds))


    // alert(JSON.stringify(getUnselectedIds))

    var unselectedIds=[]
    if(getUnselectedIds.length > 0){
    for(var i=0;i<getUnselectedIds.length>0;i++){
      unselectedIds.push(getUnselectedIds[i]._id)  
    }
  }
  // alert(JSON.stringify(unselectedIds))
    var req={
      "patient_id":this.state.patientAppointment.patient_id,
      "appointment_id":this.state.patientAppointment.appointment_id,
      "results":this.state.selectedMotivation,
      "unchecked_ids":unselectedIds,

  }
  if (this.state.psyMotivationId) {
    req["motivation_id"] = this.state.psyMotivationId

  }
//   var {selectedMotivation}=this.state
//   var uncheckedID=this.state
//    if(selectedMotivation.length>0){
//     for(var i=0;i<selectedMotivation;i++){
//      selectedMotivation.shift(i,1)
//     }
//     uncheckedID.push(selectedMotivation)

  
//  }
//  alert(JSON.stringify(uncheckedID))


 
    var service_url = Constants.PSY_MOTIVATION_POST
    OpthamologyService.getInstance().postComplaints(
      service_url,
      req,
      this,
      this.onPressMotivationSuccess,
      this.onPressMotivationFailure
    );

  }
    onPressMotivationSuccess=(response)=>{
      if(response.status=="success"){
        this.props.showResposeValue("success",response.message)
      }
    }
    onPressMotivationFailure=(error)=>{
      this.props.showResposeValue("error",error.message)
 
    }
  
  render() {
    return (
      <View style={{ flex: 1 }} >
        <View style={{flexDirection:"row",justifyContent:'space-between',alignItems:'center',alignContent:'center'}}>
        {this.renderTitleBorder(
          this.state.isSymptoms, 
          this.state.userType === Constants.ROLE_OPT? 'Diagnosis':pageTitle, 
          isPageName,
          // this.state.isAssessment,
          // pageTitle,
          // pageName, 
          this.triggerNewItem)}

          { this.state.getAssessmentData.length>0 || this.state.provisionalDiagnosis ?
            <View>
              <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                this.saveAssessment()
              }} style={[styles.SaveBtnTouch, { backgroundColor: color.themeDark }, Style.allButtonBorderRadius]}>
                <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Save"}</Text>
              </TouchableOpacity>
            </View>:null 
           } 


        {/* <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
          this. ()
        }} style={{alignContent:'flex-end',alignSelf:"flex-end",justifyContent:'flex-end',alignItems:'flex-end',marginRight: 30, backgroundColor: color.themeDark, paddingLeft: 20, paddingRight: 20, paddingTop: 7, paddingBottom: 7, borderRadius: 10 }}>
          <Text style={{ color: color.white }}>{"Save"}</Text>
        </TouchableOpacity> */}
        </View> 

        <View style={{ marginTop: '1.2vw' }}>

          <View
            style={{
              width: "100%"
            }}
          >
            {/* <Text style={{marginLeft:5, marginBottom:15, fontSize:14,paddingLeft:5,paddingRight:5,color:"#888888"}}>{"Provisional Diagnosis"}</Text> */}
            <View style={{ marginBottom: '0.6vw' }}>
          <CommonHistorySectionHeader
            heading1={"Provisional Diagnosis"}
           
            columnSize={[1]}  //total value is == of 1
            noOfColumn={1}
          />
        </View>
            <TextInput
              
              editable={this.state.isLocked}
              multiline={true}
              placeholder=""
              style={[styles.provisionalDiagnosisTextInput,{backgroundColor: this.state.isLocked ? color.disableComponentColor : null}]}
              value={this.state.provisionalDiagnosis}
            />
          </View>
        </View>

        {/* <View style={{ marginTop: 20 }}> */}
          <View style={{marginTop:'0.6vw'}}>
               <CommonHistorySectionHeader
            heading1={"ICD 10 Code"}
            heading2={"Short Description"}
            heading3={"Long Description"}
            heading4={"Action"}
            columnSize={[0.15, 0.33, 0.35, 0.17]}  //total value is == of 1
            noOfColumn={4}
          />
            {/* <View style={{flex:0.15}} >
            <CommonSectionHeader heading={"ICD 10 Code"}  />
            </View>

            <View style={{flex:0.33}}>
            <CommonSectionHeader heading={"Short Description"}  />
            </View>
            <View style={{flex:0.35}}>
            <CommonSectionHeader heading={"Long Description"}  />
             
            </View>
            <View style={{flex:0.17}}>
            <CommonSectionHeader heading={"Action"}  />
   
            </View> */}
          </View>
        
        {
          this.state.getAssessmentData.map((assessment,index)=>{
            return(
              this.renderAssessmentItem(assessment, index)
            )
          })
        }

        <View style={{ marginTop: '1.2vw' }}>

          {
            this.state.userType==="PSY"?
          <View style={{marginTop:'0.6vw'}}>
                {this.renderTitleBorder(
                  this.state.isSymptoms, 
                  this.state.userType === Constants.ROLE_OPT? "Multi-Axial Diagnosis":"Multi-Axial Diagnosis", 
                  isPageName,
                  // this.state.isAssessment,
                  // pageTitle,
                  // pageName, 
              this.triggerNewItem)}
             <View style={{marginBottom:'0.6vw',marginTop:'0.6vw'}}>

               {this.renderMultiAxialDiagnosis("AXIS I Clinical Psychiatric Syndrome","AXIS II Specific delays of development",this.state.clinicPsychiatrySyndrome,"clinicPsychiatrySyndrome",this.state.specificDelaysOfDevelopment,"specificDelaysOfDevelopment")}
               {this.renderMultiAxialDiagnosis("AXIS III General intellectual functioning","AXIS IV Medical Problem",this.state.generalIntellectualFunctioning,"generalIntellectualFunctioning",this.state.medicalProblem,"medicalProblem")}
               <CommonHistorySectionHeader
                 heading1={"AXIS V Abnormal family/ Psycho-social situations"}
                 noOfColumn={1}
               />
                <TextInput 
                    editable={false}
                   placeholder=""
                   multiline={true} 
                   value={this.state.abnormalFamily} 
                   onChangeText={(text)=>{this.setState({abnormalFamily:text})}}
                   style={[styles.MultiAxialDataTextInput2, {borderColor:color.lightGray}]}
                  />
               {/* <CommonHistorySectionHeader
                 heading1={"AXIS I Clinical Psychiatric Syndrome"}
                 heading2={"AXIS II Specific delays of development"}
                 noOfColumn={2}
                 columnSize={[0.50,0.50]}
               />
               <View style={{flexDirection:"row",paddingVertical:10}}>
                 <TextInput 
                  editable
                   placeholder=""
                   multiline={true} 
                   value={this.state.clinicPsychiatrySyndrome} 
                   onChangeText={(text)=>{this.setState({clinicPsychiatrySyndrome:text}); 
                  //  this.getMultiAxialAutoSuggest(text) 
                }}
                   style={{flex:0.50,borderWidth:1,borderColor:color.lightGray,borderRadius:4,height:50}}
                  />
                   <TextInput 
                   placeholder=""
                   multiline={true} 
                   value={this.state.specificDelaysOfDevelopment} 
                   onChangeText={(text)=>{this.setState({specificDelaysOfDevelopment:text})}}
                   style={{flex:0.50,borderWidth:1,borderColor:color.lightGray,borderRadius:4,height:50,marginLeft:20}}
                  />
               </View>
               <CommonHistorySectionHeader
                 heading1={"AXIS III General intellectual functioning"}
                 heading2={"AXIS IV Medical Problem"}
                 noOfColumn={2}
                 columnSize={[0.50,0.50]}
               />
               <View style={{flexDirection:"row",marginTop:10,marginBottom:10}}>
                 <TextInput 
                   placeholder=""
                   multiline={true} 
                   value={this.state.generalIntellectualFunctioning} 
                   onChangeText={(text)=>{this.setState({generalIntellectualFunctioning:text})}}
                   style={{flex:0.50,borderWidth:1,borderColor:color.lightGray,borderRadius:4,height:50}}
                  />
                   <TextInput 
                   placeholder=""
                   multiline={true} 
                   value={this.state.medicalProblem} 
                   onChangeText={(text)=>{this.setState({medicalProblem:text})}}
                   style={{flex:0.50,borderWidth:1,borderColor:color.lightGray,borderRadius:4,height:50,marginLeft:20}}
                  />
               </View>
               <CommonHistorySectionHeader
                 heading1={"AXIS V Abnormal family/ Psycho-social situations"}
                 noOfColumn={1}
               />
                <TextInput 
                   placeholder=""
                   multiline={true} 
                   value={this.state.abnormalFamily} 
                   onChangeText={(text)=>{this.setState({abnormalFamily:text})}}
                   style={{borderWidth:1,borderColor:color.lightGray,borderRadius:4,height:50,width:"50%",marginTop:10,marginBottom:10}}
                  />
                */}
             </View>
           </View>:null
        } 

        </View>
        { 
          this.state.userType=="PSY"?
       <View>
        <View style={{marginBottom:'0.6vw'}}>
        {this.renderTitleBorder(
                  this.state.isSymptoms, 
                  this.state.userType === Constants.ROLE_OPT? "Motivation":"Motivation", 
                  isPageName,
                  // this.state.isAssessment,
                  // pageTitle,
                  // pageName, 
              this.triggerNewItem)}
        </View>

        {this.renderMotivations("Poor Motivation",this.state.poorMotivation)}
        {this.renderMotivations("Superficial/Shallow Motivation",this.state.shallowMotivation)}
        {this.renderMotivations("Fair/Good Motivation",this.state.goodMotivation)}

        
        
          <View style={{  }}>
            <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Save"}
                buttonType={"theme"}
                buttonIcon={require("../../../../assets/images/Active/save.png")}
                rightIcon={true}
                buttonAction={this.onPressMotivationSave.bind(this)}
                buttonKey={"motivationSaveButton"}
              />

          </View>
        </View>:null
       }
        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }


  renderMotivations(header,dataList){
    if(dataList.length > 0){
    return(
      <>
      <CommonHistorySectionHeader heading1={header} noOfColumn={1}/>
        {
          dataList.map((template,index)=>{
            return(
              this.renderPsychiatryMotivation(template, index)
            );
          })
        }
      </>
    )
      }else{
        return <View/>
      }
  }

}
const styles = StyleSheet.create({
  SaveBtnTouch: {
    marginLeft: '1.2vw', 
    paddingTop: '0.5vw', 
    paddingLeft: '1.1vw',
    paddingRight: '1.1vw', 
    paddingBottom: '0.5vw'
  },
  provisionalDiagnosisTextInput: {
    paddingTop:'0.6vw',
    paddingLeft:'0.6vw',
    height: '5.6vw', 
    borderColor: '#D9D9D9', 
    borderWidth: 1, 
    borderRadius: 5,
    fontSize: '0.9vw'
  },
  TableDataView: {
    flex:0.15,
    marginLeft:'1.2vw',
    marginHorizontal: '1.2vw'
  },
  MultiAxialDataTextInput: {
    marginTop:'0.6vw',
    marginBottom:'0.6vw',
    borderWidth:1,
    borderRadius:4,
    flex:0.50, 
    height:'3.3vw',
    flex:0.50
  },
  MultiAxialDataTextInput1: {
    marginTop:'0.6vw',
    marginBottom:'0.6vw',
    borderWidth:1,
    borderRadius:4,
    flex:0.50, 
    height:'3.3vw',
    marginLeft:'1.2vw'
  },
  MultiAxialDataTextInput2: {
    borderWidth:1,
    borderRadius:4,
    height:'3.3vw',
    width:"50%",
    marginTop:'0.6vw',
    marginBottom:'0.6vw'
  }
})