//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { HistoryYesOrNoSection } from '../../BaseComponent'
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";

import { CommonButton, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../../../ui/doctor/BaseComponent'
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";


// create a component
const pageName = "isPhysicalActivity"

var activityLevel = [
    { label: "Sitting Most of the day", value: "Sitting Most of the day" },
    { label: "Some activity daily", value: "Some activity daily" },
    { label: "Very active daily", value: "Very active daily" },

];

var exerciseDaily = [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },


];

var typeExercise = [
    { label: "Stretching", value: "Stretching" },
    { label: "Aerobic", value: "Aerobic" },
    { label: "Resistance", value: "Resistance" },

];

var intesityLevel = [
    { label: "Low Intensity: you can talk easily while exercising", value: "01" },
    { label: "Moderate Intensity: You can breathe a little harder, but you can still talk", value: "02" },
    { label: "High Intensity: You breathe hard, and its difficult to talk while exercising", value: "03" },

];


export class PhysicalActivityNew extends Component {
    constructor(props) {
        super(props);
        this.state = {

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            selectedValue: true,
            physicalActivityDetails: '',


            newButtonValue: "",

            activityLevelList: activityLevel,
            selectActivityValue: {},
            selectActivityNewbuttonFlag: true,

            exerciseDailyList: exerciseDaily,
            exerciseDailyValue: {},
            selectexerciseDailyNewbuttonFlag: true,

            typeExerciseList: typeExercise,
            selectTypeExerciseValue: {},
            selecttypeExerciseNewbuttonFlag: true,
            typeExerciseText: "",

            intesityLevelList: intesityLevel,
            selectintesityLevelValue: {},

            activityYesorNo: true,
            exerciseTime: "",
            physical_activity_id: ""
        }
    }

    componentWillReceiveProps(props) {

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;
                var { activityLevelList, exerciseDailyList, typeExerciseList } = this.state
                states["physical_activity_id"] = data.physical_activity_id
                // states["selectActivityValue"] = data.current_activity_level ? { label: data.current_activity_level, value: data.current_activity_level } : null
                states["selectedValue"] = data.exercise_regularly
                // states["exerciseDailyValue"] = data.exercise_frequency_weeks ? { label: data.exercise_frequency_weeks, value: data.exercise_frequency_weeks } : null
                states["exerciseTime"] = data.exercise_duration_minutes
                // states["selectTypeExerciseValue"] = data.exercise_types ? { label: data.exercise_types, value: data.exercise_types } : null
                states["typeExerciseText"] = data.exercise_types_desc
                states["selectintesityLevelValue"] = data.exercise_intensity ? { label: data.exercise_intensity, value: data.exercise_intensity } : null
                states["physicalActivityDetails"] = data.exercise_skip_reason

                //current daily activity level

                var activityList = data && data.current_activity_level ? data.current_activity_level : "";

                for (let i = 0; i < activityLevelList.length; i++) {
                    // const element = array[i];
                    if (activityLevelList[i].value == activityList) {
                        var prepareChildren = { label: activityList.toString(), value: activityList.toString() }
                        states["selectActivityValue"] = prepareChildren;
                    } else {
                        var prepareChildren = { label: activityList.toString(), value: activityList.toString() }
                        activityLevelList.push(prepareChildren);
                        states["selectActivityValue"] = prepareChildren;
                    }

                }
                activityLevelList = this.removeDuplicate(activityLevelList)


                //days a week?

                var exerciseDailyListValue = data && data.exercise_frequency_weeks ? data.exercise_frequency_weeks : "";

                for (let i = 0; i < exerciseDailyList.length; i++) {
                    // const element = array[i];

                    // var value =exerciseDailyList[i].value

                    var getexerciseDailyListValueStr = exerciseDailyListValue ? exerciseDailyListValue.length >1 ? exerciseDailyListValue : "0"+exerciseDailyListValue:"";
                    if(getexerciseDailyListValueStr){
                    if (exerciseDailyList[i].value == getexerciseDailyListValueStr) {
                        
                        var prepareChildren = { label: getexerciseDailyListValueStr.toString(), value: getexerciseDailyListValueStr.toString() }
                        states["exerciseDailyValue"] = prepareChildren;
                    } else {
                        var prepareChildren = { label: getexerciseDailyListValueStr.toString(), value: getexerciseDailyListValueStr.toString() }
                        exerciseDailyList.push(prepareChildren);
                        states["exerciseDailyValue"] = prepareChildren;
                    }
                }

                }
                exerciseDailyList = this.removeDuplicate(exerciseDailyList)

                //Type of exercise
                var exerciseList = data && data.exercise_types ? data.exercise_types : "";

                for (let i = 0; i < typeExerciseList.length; i++) {
                    // const element = array[i];
                    if (typeExerciseList[i].value == exerciseList) {
                        var prepareChildren = { label: exerciseList.toString(), value: exerciseList.toString() }
                        states["selectTypeExerciseValue"] = prepareChildren;
                    } else {
                        var prepareChildren = { label: exerciseList.toString(), value: exerciseList.toString() }
                        typeExerciseList.push(prepareChildren);
                        states["selectTypeExerciseValue"] = prepareChildren;
                    }

                }
                typeExerciseList = this.removeDuplicate(typeExerciseList)

                this.setState({ states, activityLevelList, exerciseDailyList,typeExerciseList }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }

    onChangeYesOrNoValue(flag, value, key) {
        if (!flag) {
            this.setState({ activityYesorNo: false })

        } else {
            this.setState({ activityYesorNo: true })

        }
        var states = this.state;
        states["selectedValue"] = flag;
        states[key] = value;
        this.setState({ states }, () => {
            var states = this.state;
            if (!this.state.activityYesorNo) {
                states["exerciseDailyValue"] = {}
                states["selectTypeExerciseValue"] = {}
                states["typeExerciseText"] = ""
                states["selectintesityLevelValue"] = {}
                states["exerciseTime"] = ""
                // states["physical_activity_id"] = ""
            }else{
                states["physicalActivityDetails"] = ""
            }
            this.setState({
                states
            })
        })
    }

    onPressButton(key, selectedvalue) {

        var states = this.state
        if (key == "selectActivityNewbuttonFlag") {
            states[key] = false;
            states["selectexerciseDailyNewbuttonFlag"] = true;
            states["selecttypeExerciseNewbuttonFlag"] = true;
        } else if (key == "selectexerciseDailyNewbuttonFlag") {
            states[key] = false;
            states["selectActivityNewbuttonFlag"] = true;
            states["selecttypeExerciseNewbuttonFlag"] = true;

        } else if (key == "selecttypeExerciseNewbuttonFlag") {
            states[key] = false;
            states["selectActivityNewbuttonFlag"] = true;
            states["selectexerciseDailyNewbuttonFlag"] = true;

        } else {
            states[key] = selectedvalue;

        }
        this.setState({ states })
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var { activityLevelList, exerciseDailyList, typeExerciseList } = this.state;
        var states = this.state;
        var preparData = {};
        // if(!this.state.showDiabeticYearsNewButtonFlag){
        //     preparData={label:this.state.newButtonValue+" year",value:this.state.newButtonValue+" year"}
        // }else{
        preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
        // }

        if (!this.state.selectActivityNewbuttonFlag) {
            activityLevelList.push(preparData);
            activityLevelList = this.removeDuplicate(activityLevelList);
            states["newButtonValue"] = "";
            states["selectActivityNewbuttonFlag"] = true;
            states["selectexerciseDailyNewbuttonFlag"] = true
            states["selecttypeExerciseNewbuttonFlag"] = true
            states["selectActivityValue"] = preparData
        } else if (!this.state.selectexerciseDailyNewbuttonFlag) {
            exerciseDailyList.push(preparData);
            exerciseDailyList = this.removeDuplicate(exerciseDailyList);
            states["newButtonValue"] = "";
            states["selectexerciseDailyNewbuttonFlag"] = true
            states["selectActivityNewbuttonFlag"] = true;
            states["selecttypeExerciseNewbuttonFlag"] = true
            states["exerciseDailyValue"] = preparData
        } else if (!this.state.selecttypeExerciseNewbuttonFlag) {
            typeExerciseList.push(preparData);
            typeExerciseList = this.removeDuplicate(typeExerciseList);
            states["newButtonValue"] = "";
            states["selectexerciseDailyNewbuttonFlag"] = true
            states["selectActivityNewbuttonFlag"] = true;
            states["selecttypeExerciseNewbuttonFlag"] = true
            states["selectTypeExerciseValue"] = preparData

        }



        this.setState({
            activityLevelList, states, exerciseDailyList, typeExerciseList
        })

    }

    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData, textinput) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            if(item.value)
                            {return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )}
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedvalue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
                <View>
                    {textinput ?
                        <TextInput
                            style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { backgroundColor: color.white, height: 80, width: "95%", marginTop: 10, padding: 10, marginBottom: 20 }]}
                            value={this.state.typeExerciseText}
                            onChangeText={(text) => {
                                this.setState({ typeExerciseText: text })
                            }}
                            multiline={true}
                            maxLength={100}
                            placeholder={"Details if any"}

                        />
                        : null}
                </View>
            </View>
        )
    }

    renderexerciseMinute() {
        return (
            <View style={[Style.historyYesOrNOSectionRowView, Style.allButtonBorderRadius]}>
                <Text style={{ marginLeft: 20, fontSize: 14, flex: 0.7 }}>{"How many minutes per session?"}</Text>
                <View style={[{
                    flexDirection: 'row', borderWidth: 0.1, flex: 0.3, borderColor: 'white', shadowColor: "#090F36",
                    shadowOffset: { width: 1, height: 1 },
                    shadowOpacity: 0.4, shadowRadius: 3,
                    elevation: 5,
                }, Style.allButtonBorderRadius]}>
                    <TextInput
                        value={this.state.exerciseTime}
                        placeholder={"Enter Values"}
                        onChangeText={(text) => {
                            if (this.numberValidation(text)) {
                                this.setState({ exerciseTime: text })
                            }
                        }}
                        style={[
                            // Style.historyYesOrNoSectionShadow,
                            // Style.allButtonBorderRadius, 
                            {
                                backgroundColor: "transparent", height: 25,
                                width: 50, fontSize: this.state.exerciseTime.length > 0 ? 12 : 8
                            }]}

                    />
                    <View style={{ height: 21, borderLeftColor: "#888888", borderLeftWidth: 1, justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center' }}>
                        <Text style={{ fontSize: 13, marginLeft: 10 }}>{"minutes"}</Text>
                    </View>



                </View>
            </View>
        )
    }


    addPhyscicalActivity() {

        var states = this.state;

        let data = {
            "patient_id": states.patientAppointment.patient_id,
            "current_activity_level": states.selectActivityValue ? states.selectActivityValue.value : "",
            "exercise_regularly": states.selectedValue,
            "exercise_frequency_weeks": states.exerciseDailyValue ? states.exerciseDailyValue.value : "",
            "exercise_duration_minutes": states.exerciseTime,
            "exercise_types": states.selectTypeExerciseValue ? states.selectTypeExerciseValue.value : "",
            "exercise_types_desc": states.typeExerciseText,
            "exercise_intensity": states.selectintesityLevelValue ? states.selectintesityLevelValue.value : "",
            "exercise_skip_reason": states.physicalActivityDetails
        }
        if (this.state.physical_activity_id) {
            data["physical_activity_id"] = this.state.physical_activity_id
        }
        // alert(states.physical_activity_id)

        var service_url = Constants.PHYSICAL_ACTIVITY_HISTORY
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.PhyscicalActivitySaveSuccess,
            this.PhyscicalActivitySaveFailure
        );

    }




    PhyscicalActivitySaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            // this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
        this.clearAllData()
    }
    PhyscicalActivitySaveFailure = error => {
        this.props.showResposeValue('error', error.message)
        console.log("obstetricalHistorySaveFailure <<~~~~~>> " + JSON.stringify(error))
    }

    clearAllData() {
        var states = this.state;
        states["selectActivityValue"] = {}
        states["exerciseDailyValue"] = {}
        states["selectTypeExerciseValue"] = {}
        states["typeExerciseText"] = ""
        states["selectintesityLevelValue"] = {}
        states["exerciseTime"] = ""
        states["physical_activity_id"] = ""
        states["physicalActivityDetails"] = ""
        this.setState({
            states
        })
    }

    render() {
        return (
            <View style={styles.container}>
                <DoctorNotesCommonRightSideHeader title={"Physical Activity"} clearAllData={this.clearAllData.bind(this)} />

                {this.renderLabelWithButtonGroup(
                    "Please rate your current daily activity level",
                    "selectActivityValue",
                    this.state.selectActivityValue,
                    "selectActivityNewbuttonFlag",
                    this.state.selectActivityNewbuttonFlag,
                    this.state.activityLevelList)}

                <View style={{ marginTop: 15 }}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.selectedValue}
                        label={"Do you exercise regularly?"}
                        detailsOfYesValue={this.state.physicalActivityDetails}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"physicalActivityDetails"}
                        // placeholder={"How long ago"}
                        showDetailsText={false}
                        showNoTextBox={true}
                        noTextBoxLabel={"List the reason or problems that prevent you from exercising?"}
                        maxLength={100}

                    />
                </View>
                {this.state.selectedValue ?
                    <View >
                        {this.renderLabelWithButtonGroup(
                            "How many days a week?",
                            "exerciseDailyValue",
                            this.state.exerciseDailyValue,
                            "selectexerciseDailyNewbuttonFlag",
                            this.state.selectexerciseDailyNewbuttonFlag,
                            this.state.exerciseDailyList)}

                        <View style={{ marginTop: 15 }}>
                            {this.renderexerciseMinute()}
                        </View>
                        <View style={{ marginBottom: 15 }}>

                            {this.renderLabelWithButtonGroup(
                                "Type of exercise",
                                "selectTypeExerciseValue",
                                this.state.selectTypeExerciseValue,
                                "selecttypeExerciseNewbuttonFlag",
                                this.state.selecttypeExerciseNewbuttonFlag,
                                this.state.typeExerciseList,
                                "typeExerciseText")}
                        </View>
                        <View style={{ marginBottom: 15 }}>
                            {this.renderLabelWithButtonGroup(
                                "Intensity Level",
                                "selectintesityLevelValue",
                                this.state.selectintesityLevelValue,
                                "",
                                "",
                                this.state.intesityLevelList,
                            )}
                        </View>
                    </View>


                    : null}

                <View>
                    <CommonAddButton
                        onPressAddButton={this.addPhyscicalActivity.bind(this)}
                    />

                </View>

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
});
