import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "perinatalFactors"


export class PerinatalFactorsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            prolongedLabourFlag: false,
            prolongedLabourDetails: "",

            assistedLabourFlag: false,
            assistedLabourDetails: "",

            inducedLabourFlag: false,
            inducedLabourDetails: "",


            fetalDistressFlag: false,
            fetalDistressDetails: "",

            abnormalPresentationFlag: false,
            abnormalPresentationDetails: "",


            cordAroundtheNeckFlag: false,
            cordAroundtheNeckFlagDetails: "",

            placentaPreviaFlag: false,
            placentaPreviaDetails: ""
        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["prolongedLabourFlag"] = data.prolonged_labour;
                states["prolongedLabourDetails"] = data.prolonged_labour_detail;

                states["assistedLabourFlag"] = data.assisted_labour;
                states["assistedLabourDetails"] = data.assisted_labour_detail;

                states["inducedLabourFlag"] = data.induced_labour;
                states["inducedLabourDetails"] = data.induced_labour_detail;

                states["fetalDistressFlag"] = data.fetal_distress;
                states["fetalDistressDetails"] = data.fetal_distress_detail;


                states["abnormalPresentationFlag"] = data.abnormal_presentation;
                states["abnormalPresentationDetails"] = data.abnormal_presentation_detail;

                states["cordAroundtheNeckFlag"] = data.cord_around_the_neck;
                states["cordAroundtheNeckFlagDetails"] = data.cord_around_the_neck_detail;

                states["placentaPreviaFlag"] = data.placenta_previa;
                states["placentaPreviaDetails"] = data.placenta_previa_detail;


                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["prolongedLabourFlag"] = false;
        states["prolongedLabourDetails"] = "";

        states["assistedLabourFlag"] = false;
        states["assistedLabourDetails"] = "";

        states["inducedLabourFlag"] = false;
        states["inducedLabourDetails"] = "";

        states["fetalDistressFlag"] = false;
        states["fetalDistressDetails"] = "";

        states["abnormalPresentationFlag"] = false;
        states["abnormalPresentationDetails"] = "";

        states["cordAroundtheNeckFlag"] = false;
        states["cordAroundtheNeckFlagDetails"] = "";

        states["placentaPreviaFlag"] = false;
        states["placentaPreviaDetails"] = "";

        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "prolonged_labour": states.prolongedLabourFlag,
                "prolonged_labour_detail": states.prolongedLabourDetails,
                "assisted_labour": states.assistedLabourFlag,
                "assisted_labour_detail": states.assistedLabourDetails,
                "induced_labour": states.inducedLabourFlag,
                "induced_labour_detail": states.inducedLabourDetails,
                "fetal_distress": states.fetalDistressFlag,
                "fetal_distress_detail": states.fetalDistressDetails,
                "abnormal_presentation": states.abnormalPresentationFlag,
                "abnormal_presentation_detail": states.abnormalPresentationDetails,
                "cord_around_the_neck": states.cordAroundtheNeckFlag,
                "cord_around_the_neck_detail": states.cordAroundtheNeckFlagDetails,
                "placenta_previa": states.placentaPreviaFlag,
                "placenta_previa_detail": states.placentaPreviaDetails
            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PERINATAL_FACTORSH;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePerinatalFactorsSuccess,
                this.savePerinatalFactorsFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Perinatal Factors already added please click on edit icon to update the values")
        }
    }

    savePerinatalFactorsSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)

        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePerinatalFactorsFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Perinatal Factors"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Prolonged Labour", "Enter details",
                    "prolongedLabourFlag", this.state.prolongedLabourFlag,
                    "prolongedLabourDetails", this.state.prolongedLabourDetails
                )}

                {this.renderHistoryComponent(
                    "Assisted Labour", "Enter details",
                    "assistedLabourFlag", this.state.assistedLabourFlag,
                    "assistedLabourDetails", this.state.assistedLabourDetails
                )}

                {this.renderHistoryComponent(
                    "Induced Labour", "Enter details",
                    "inducedLabourFlag", this.state.inducedLabourFlag,
                    "inducedLabourDetails", this.state.inducedLabourDetails
                )}

                {this.renderHistoryComponent(
                    "Fetal Distress", "Enter details",
                    "fetalDistressFlag", this.state.fetalDistressFlag,
                    "fetalDistressDetails", this.state.fetalDistressDetails
                )}

                {this.renderHistoryComponent(
                    "Abnormal Presentation", "Enter details",
                    "abnormalPresentationFlag", this.state.abnormalPresentationFlag,
                    "abnormalPresentationDetails", this.state.abnormalPresentationDetails
                )}
                {this.renderHistoryComponent(
                    "Cord Around the neck", "Enter details",
                    "cordAroundtheNeckFlag", this.state.cordAroundtheNeckFlag,
                    "cordAroundtheNeckFlagDetails", this.state.cordAroundtheNeckFlagDetails
                )}

                {this.renderHistoryComponent(
                    "Placenta Previa", "Enter details",
                    "placentaPreviaFlag", this.state.placentaPreviaFlag,
                    "placentaPreviaDetails", this.state.placentaPreviaDetails
                )}

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
