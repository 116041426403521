//import liraries
import React, { Component } from "react";
import { View, Text, StyleSheet, ScrollView, Dimensions, TouchableOpacity} from "react-native";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { CommonButton,CommonHistorySectionHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import BaseComponentStyle from "../../BaseComponentStyle";
import moment from "moment";
import { color } from "../../../../styles/Color";
import AsyncStorage from "../../../../AsyncStorage";
const screenHeight = Dimensions.get("window").height;
function disabledDate(current) {
  
  var today = new Date()
var customDate = new Date().setDate(today.getDate()-30)
// console.log("customDate++++++" + customDate)

 // let customDate = "2020-10-18";
  return current && current < moment(customDate, "YYYY-MM-DD");
}
// create a component
class DoctorVisitReport extends BaseComponentStyle {
  constructor(props) {
    super(props);
    let userType = '';

    try {
      userType = this.props.userType;
    } catch (error) { }

    this.state = {
      startDate: "",
      endDate: "",
      selectedSearch : {},


      patient_id: this.props.patient_id ? this.props.patient_id : this.props.modificationBillDetails.patient_id ,
      appointment_id:this.props.appointment_id,

      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,

      // patient_id: "14218",
      allData: [],
      userType: userType,
      isOptClinic: false,
      doctor_id: this.props.patientDetails.doctor_id ? this.props.patientDetails.doctor_id : this.props.modificationBillDetails.doctor_id ? this.props.modificationBillDetails.doctor_id : this.props.otPatientDetails.doctor_id,
      selectedIpPatientDetails:this.props.selectedIpPatientDetails,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      modificationBillDetails: this.props.modificationBillDetails,
      selectedDoctor: null,
      otPatientDetails: this.props.otPatientDetails,
      current_doctor_id:"",
      clinicId: ""

      // minDate : moment('2020-10-18 +0800', 'YYYY-MM-DD Z').utcOffset(8)
    };
  }

  async componentDidMount(){
      var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var role_type = await (AsyncStorage.getItem("role_type"))
      var UserID = await (AsyncStorage.getItem("UserID"))
      var loggedInDataObj = JSON.parse(loggedInData)
      var isOPT = false
      var current_doctor_id =''
      if(loggedInDataObj.clinic_type){
        isOPT = loggedInDataObj.clinic_type.is_opt_clinic
      }else{
        isOPT = false;
      }

      if(loggedInDataObj?.clinic_id){
        this.setState({
          clinicId : loggedInDataObj?.clinic_id
        })
      }

      if(role_type === "DOCTOR" && UserID ){
        current_doctor_id = UserID
      }

      this.setState({
        isOptClinic: isOPT,
        current_doctor_id: current_doctor_id
      })
      this.DoctorVistReport();
  }

  componentWillReceiveProps(props){
    if((props && props.searchType == "isDoctorReport" &&( props.startDate !== this.state.startDate ||  this.state.endDate !== props.endDate)) || ((props && props.searchType == "isDoctorReport" && this.state.selectedDoctor != props.selectedDoctor))) {
      // if(){
      var { startDate,endDate } = this.state

      // if(props.startDate)
      startDate=props.startDate;
      // if(props.endDate)
      endDate=props.endDate;

      this.setState({ 
        startDate,
        endDate,
        selectedDoctor: props.selectedDoctor
      },()=>{
        this.props.clearSearchType("")
        this.DoctorVistReport();
      })
    // }
  }
  }


  DoctorVistReport = () => {

   
    var startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : ""
    var LastDate =  this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : ""
    let { userType } = this.state;    

    let service_url = ""
    var docID = this.state.selectedDoctor ? this.state.selectedDoctor : this.state.selectedClinicOrDoctor.id

    if(userType === Constants.ROLE_FO) {
      if((this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) || ( this.state.modificationBillDetails && this.state.isCurrentPageIsIP )){

        let patinet_id = Object.keys(this.state.selectedIpPatientDetails).length > 0 ? this.state.selectedIpPatientDetails.patient_id : this.state.modificationBillDetails.patient_id
        let doctor_id = Object.keys(this.state.selectedIpPatientDetails).length > 0 ? this.state.selectedIpPatientDetails.doctor_id : this.state.modificationBillDetails.doctor_id

        service_url = Constants.FO_DOCTOR_VIST_REPORT+ "?patient_id="+ patinet_id + "&doctor_id="+ docID +  "&start_date="+startDate +"&end_date="+LastDate;
        
      }else{
      service_url = Constants.FO_DOCTOR_VIST_REPORT+ "?patient_id="+this.state.patient_id+ "&doctor_id="+ docID +  "&start_date="+startDate +"&end_date="+LastDate;
      }
    } else if(userType === "OT") {
      
      let patient_id = this.state.otPatientDetails.patient_id ? this.state.otPatientDetails.patient_id : ""
      // let doctor_id = this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : ""

      service_url = Constants.OT_DOCTOR_VIST_REPORT+ "?patient_id=" + patient_id + "&doctor_id=" + docID + "&start_date=" + startDate + "&end_date=" + LastDate;

    } else {

      service_url = Constants.DOCTOR_VIST_REPORT+"?patient_id="+this.state.patient_id+"&start_date="+startDate +"&end_date="+LastDate + "&clinic_id=" + this.state?.clinicId;
    }

    
    OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getDoctorVistReport,
    this.DoctorVistReportFailure
  );
  }

  getDoctorVistReport = (response) => {
    if(response.status == "success"){
        this.setState({
          allData : response.data
        })
    }
  }

  onPressButton(key, selectedvalue) {
    if (key == "Search" ) {
        this.DoctorVistReport();
    }
    
  }


  openView(item){
    // alert(JSON.stringify(item))

    //alert("Clicked")
    
    let { userType, isOptClinic } = this.state;    

    let service_url = ""

    if(userType === Constants.ROLE_FO) {
      if(isOptClinic){
        service_url = Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT +"?appointment_id=" + item.id + "&doctor_id="+item.doctor_id  + "&doctor_notes=" + "All";
      }else{
        service_url = Constants.OPEN_SCAN_PDF +"?appointment_id=" + item.id + "&doctor_id=" + item.doctor_id;
      }
    } else if(userType === "OT") {
      
      let doctor_id = this.state.otPatientDetails.doctor_id ? this.state.otPatientDetails.doctor_id : ""
      // let appointment_id = this.state.otPatientDetails.appointment ? this.state.otPatientDetails.appointment : ""

      service_url = Constants.OPEN_SCAN_PDF +"?appointment_id=" + item.id + "&doctor_id="+ doctor_id;

    }
    else {
      // if(userType === Constants.ROLE_OPT){
      //   service_url = Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT +"?appointment_id=" + item.id + "&doctor_notes=" + "All" ;
      // }else{
        if(item?.is_opt){
          service_url = Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT +"?appointment_id=" + item.id + "&doctor_id="+item.doctor_id + "&doctor_notes=" + "All" ;
        }else{
        service_url = Constants.OPEN_DOCTOR_SCAN_PDF +"?appointment_id=" + item.id + "&clinic_id="+ this.state?.clinicId + "&doctor_id="+item.doctor_id + "&doctor_notes=" + "all";
        }
      // }
    }

    //var service_url = Constants.OPEN_SCAN_PDF +"?appointment_id=" + item.id + "&doctor_id="+this.state.doctor_id;

    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      // this,
      this.openViewSuccess,
      this.openViewFailure,
      "pdf"
    );
  }

  openViewSuccess = (response) => {
    // alert("Clicked")
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  openViewFailure = (error) =>{
    // console.log("PDF error response");
    console.log(error);
  }

  renderHistoryItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_name}
              </Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>

        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };

  // disabledDate(current) {
  //   let customDate = "2020-10-18";
  //   return current && current < moment(customDate, "DD/MM/YYYY");
  // }

  render() {

    // alert(JSON.stringify(this.state.doctor_id))
    return (
      <View style={styles.container}>


          <View style={styles.DoctorVisitReportContainer}>

          <View>
            <Text style={{color:color.themeDark, fontSize:'1.2vw',fontWeight:'500', margin:'0.6vw'}}>
              Doctor Visit Report
            </Text>
          </View>
          {/* {Constants.ROLE_FO == this.state.userType || this.state.userType === "OT" ? */}
            <CommonHistorySectionHeader
              heading1={" Date Visted"}
              heading2={"Time"}
              heading3={"Doctor Name"}
              heading4={"Medical Report"}
              columnSize={[0.25, 0.25, 0.25, 0.25]}  //total value is == of 1
              noOfColumn={4}
            /> 
            {/* :
            <CommonHistorySectionHeader
              heading1={" Date Visted"}
              heading2={"Time"}
              heading3={"Medical Report"}
              columnSize={[0.30, 0.35, 0.35]}  //total value is == of 1
              noOfColumn={3} />
          } */}
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 1.5 }}>

            {this.state.allData.map((item) => {
              return (
                <View>
                 { Constants.ROLE_FO == this.state.userType || this.state.userType === "OT" ?
                  <View style={styles.TableContentView}>
                       <Text style={[styles.TableContentText, {marginLeft: '1.2vw'}]}>{item.visit_date}</Text>
                       <Text style={styles.TableContentText}>{item.visit_time}</Text>
                       <Text style={styles.TableContentText}>{item.doctor_name}</Text>
                       <TouchableOpacity onPress={() => { 
                          if(item.visit_note == "View") {
                            this.openView(item)
                          }
                        }} style={{flex: 0.25}}>
                        <Text style={{  color: item.visit_note == "View" ? color.themeDark : null, fontSize: '1vw'}}>{item.visit_note}</Text> 
                       </TouchableOpacity>
                  </View>: 
                  <TouchableOpacity onPress={() => {
                    this.state.current_doctor_id == item.doctor_id ? this.props.changeComponent("optDoctorNotes",item,this.state.userType,this.state.selectedClinicOrDoctor) : null
                  }}>
                  <View style={styles.TableContentView}>
                    <Text style={[styles.TableContentText,{marginLeft:"1.2vw"}]}>{item.visit_date}</Text>
                    <Text style={styles.TableContentText}>{item.visit_time}</Text>
                    <Text style={styles.TableContentText}>{item.doctor_name}</Text>
                    <TouchableOpacity onPress={() => { 
                          if (this.state.current_doctor_id == item.doctor_id) {
                            this.props.changeComponent("optDoctorNotes", item, this.state.userType, this.state.selectedClinicOrDoctor)
                          } else if (item.visit_note == "View") {
                            this.openView(item)
                          }
                      }} style={{ flex: 0.25}}>
                       <Text style={{ color: item.visit_note == "View" ? color.themeDark : null, fontSize: '1vw' }}>{item.visit_note}</Text> 
                    </TouchableOpacity>  
                  </View>
                  </TouchableOpacity>   
                  }
              
                </View>
              )
            })}
                </ScrollView>
          </View>          
      

      </View>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    // marginTop: 20,
    backgroundColor: color.sectionHeadingDarkColor,
    minHeight : '70vh',
    padding: (5,10,10,5)
  },
  DoctorVisitReportContainer: { 
    flex: 1, 
    // marginTop: '1.6vw', 
    // marginLeft: '1.2vw', 
    backgroundColor: "#fff", 
    padding: '1.2vw' 
  }, 
  TableContentView: { 
    flexDirection: 'row', 
    borderBottomWidth: 0.5, 
    borderBottomColor: "#EFEFEF", 
    paddingVertical: '1.2vw' 
  },
  TableContentText: { 
    flex: 0.25, 
    fontSize: '1vw' 
  }
  
});


//make this component available to the app
export default DoctorVisitReport;

