//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, FlatList } from 'react-native';
import BaseComponentStyle, { BaseComponent } from '../BaseComponentStyle'
import { CommonHistorySectionHeader } from '../BaseComponent'

import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";


import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";



const pageTitle = "Mental Status Examination";
const isPageName = "isMentalStatusExamination";
const pageName = "MentalStatusExamination";

export default class MentalStatusExamination extends BaseComponentStyle {

    constructor(props) {
        super(props);
        this.state = {
            isMentalStatusExamination: isPageName === this.props.selectedView ? true : false,
            isLocked: this.props.patientAppointment.is_locked,
            patientAppointment: this.props.patientAppointment,
            mentalStatusExaminationData: [],
            istemplate: false,
            templateName: "",

            mentalStatusData: [],

            showAddToTemplate: false,
        }
    }
    componentDidMount() {
        this.getPsyMentalStatus()
    }

    componentWillReceiveProps(props) {
        if (props.reloadData && props.reloadData === pageName) {
            this.getPsyMentalStatus()
            this.props.refreshData("");
        }

        let updateFontColor = isPageName === props.selectedView ? true : false;

        if (updateFontColor !== this.props.isMentalStatusExamination) {

            this.setState({ isMentalStatusExamination: updateFontColor });
        }
    }

    triggerNewItem = (key) => {

        this.props.triggerNewItem(key);
    }


    editMentalStatusExamination = (item, event) => {
        // alert(JSON.stringify(item) )
        this.props.triggerNewItem(isPageName);

        this.props.editItem(pageName, item, "", "", true);

    }

    deleteItem = (deleteItem, event) => {

        var service_url = Constants.PSY_MENTAL_STATUS_EXAMINATION_DELETE;
        let data = {

            "appointment_id": this.state.patientAppointment.appointment_id,
            "patient_examination_id": deleteItem.id

        };



        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getDeleteSuccess,
            this.getDeleteFailure
        );
    };
    getDeleteSuccess = success => {
        if (success.status == "success") {
            this.getPsyMentalStatus()
            this.props.showResposeValue("success", success.message)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    getDeleteFailure = err => {
        this.props.showResposeValue("error", err.message)
    }

    saveTemplate() {

        var prepareExaminationData = []
        if (this.state.mentalStatusData.length > 0) {
            for (let i = 0; i < this.state.mentalStatusData.length; i++) {
                var prepareData = {
                    "mental_status_examination_id": this.state.mentalStatusData[i].examination_id,
                    "value": this.state.mentalStatusData[i].examination_desc
                }
                prepareExaminationData.push(prepareData)
            }
        }


        var data = {
            "favorite_name": this.state.templateName,
            "examinations": prepareExaminationData
        }

        var service_url = Constants.PSY_MENTAL_STATUS_EXAMINATION_TEMPLATE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveTemplateSuccess,
            this.saveTemplateFailure
        )

    }
    saveTemplateSuccess = (response) => {
        if (response.status == "success") {
            var states = this.state;
            states["templateName"] = "";
            states["showAddToTemplate"] = false;
            this.setState({ states })
            this.props.reloadTemplate(pageName);
            this.props.showResposeValue("success", response.message)
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    saveTemplateFailure = (err) => {
        this.props.showResposeValue("error", err.message)
    }

    getPsyMentalStatus() {
        var service_url = Constants.PSY_MENTAL_STATUS_EXAMINATION_GET + "?patient_id=" + this.props.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPsyMentalStatusSuccess,
            this.getPsyMentalStatusFailure
        )
    }
    getPsyMentalStatusSuccess = (response) => {

        if (response.status == "success") {
            // console.log("success----->")
            this.setState({
                mentalStatusData: response.data.mental_status_examination
            })

        }
    }
    getPsyMentalStatusFailure = (error) => {
        console.log(error)
    }


    renderMantalStatus = (item, index) => {
        return (



            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 8, borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>
                <Text style={[Style.contentTableDataLead, { flex: 0.28, marginHorizontal: 20 }]}>{item.examination_name}</Text>

                <View style={{ flex: 0.60,flexWrap:"wrap"}}>
                     <Text style={[Style.contentTableDataLead]} numberOfLines={5}> {item.examination_desc} </Text>
                </View>
                <View style={{ flex: 0.12, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }}>
                    <TouchableOpacity disabled={this.state.isLocked} onPress={this.editMentalStatusExamination.bind(this, item)}>
                        {this.renderIdButton("pencil")}
                    </TouchableOpacity>
                    <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
                        {this.renderIdButton("trash")}
                    </TouchableOpacity>
                </View>
            </View>
        );
    };


    render() {
        return (
            <View style={{}}>
                {this.renderTitleBorder(this.state.isMentalStatusExamination, pageTitle, isPageName, this.triggerNewItem)}
                <View style={{ flexDirection: "row", alignItems: "flex-end", alignContent: "flex-end", alignSelf: "flex-end" }}>

                    <View style={{ alignItems: "flex-end", alignContent: "flex-end" }}>
                        {
                            !this.state.showAddToTemplate && this.state.mentalStatusData.length>0?
                                <TouchableOpacity onPress={() => {
                                    this.setState({
                                        showAddToTemplate: true
                                    })
                                }} style={{
                                    paddingTop: 8, paddingLeft: 18,
                                    paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark, paddingHorizontal: 10, borderRadius: 4, marginRight: 10
                                }}>
                                    <Text style={{ fontSize: 13, textAlign: "center", color: color.white }}>{"Add to templates"}</Text>
                                </TouchableOpacity>
                                :null}
                                
                                {  this.state.showAddToTemplate?
                                <View style={{ flexDirection: 'row' }}>

                                    <View>


                                        <TouchableOpacity onPress={() => {
                                            this.setState({
                                                showAddToTemplate: true
                                            })
                                        }} style={{ paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 4, backgroundColor: color.themeDark, }}>
                                            <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Cancel"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                    <View style={{ marginRight: 30, marginLeft: 30 }}>
                                        <TextInput
                                            placeholder={"Template Name"}
                                            mode="flat"
                                            underlineColor='white'
                                            multiline={true}
                                            style={{
                                                height: 35,
                                                backgroundColor: 'white',
                                                borderWidth: 1,
                                                borderColor: '#B3D9FE',
                                                primary: "white",
                                                borderRadius: 4,
                                                padding: 5
                                            }}
                                            autoCapitalize="none"
                                            value={this.state.templateName}
                                            onChangeText={text => this.setState({ templateName: text })}
                                        />

                                    </View>

                                   { this.state.showAddToTemplate && this.state.templateName?
                                    <View>
                                        <TouchableOpacity onPress={() => {
                                            this.saveTemplate()
                                        }} style={{ paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 8, backgroundColor: color.themeDark, }}>
                                            <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Save"}</Text>
                                        </TouchableOpacity>
                                    </View>:null}

                                </View>:null
                             }

                    </View>



                </View>


                <View style={{ marginTop: 10 }}>
                    <CommonHistorySectionHeader
                        heading1={"Name"}
                        heading2={"Description"}
                        heading3={"Action"}
                        columnSize={[0.28, 0.60, 0.12]}
                        noOfColumn={3}
                    />
                    <View>


                        {/* <FlatList
                        data={this.state.mentalStatusData}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        renderItem={({ item, index }) =>
                            this.renderMantalStatus(item, index)
                        }
                        /> */}
                        {
                            this.state.mentalStatusData.length > 0 ?

                                this.state.mentalStatusData && this.state.mentalStatusData.map((item, index) => {
                                    return (
                                        this.renderMantalStatus(item, index)
                                    )
                                })
                                :null
                                // <View>
                                //     <Text>{"no data "}</Text>
                                // </View>
                        }

                    </View>
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

