
import React from "react";
import {
  View,Text,
  //  Text,
  // FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  CheckBox,
  Platform,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
// import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader, RightPanelEyeSelection } from '../BaseComponent'
import { color } from "../../../styles/Color";

import AsyncStorage from "../../../AsyncStorage";
import { StyleSheet } from "react-native";

const platform = Platform.OS;
const pageName = "visionassessment";
const isPageName = "isVisionAssessment";

export default class VisionAssessment extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.inputUCVA = React.createRef();
    this.inputPH = React.createRef();
    this.inputPGCL = React.createRef();
    this.inputNV = React.createRef();

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      selectedUCVA: {},
      selectedPH: {},
      selectedPGCL: {},
      selectedNV: {},
      selectedCV: {},

      flaggedEye: "",
      response: [
        {
          id: "",
          eye_type: { label: "RE", value: "RE"  },
          UCVA: {},
          PH: {},
          PGCL: {},
          NV: {},
          CV: {},
          DV: {}
        },
        {
          eye_type: { label: "LE", value: "LE"  },
          UCVA: {},
          PH: {},
          PGCL: {},
          NV: {},
          CV: {},
          DV: {}
        }
      ],

      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      // for copy values
      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],
      copyRightToLeft: false,
      copyLeftToRight: false,

      getPreviousVissionAssessmentValue:[],
      copyFrom:"RE",

      UCVA_options: Constants.UCVA_OPTION,
      PH_options: Constants.PH_OPTIONS,
      PGCL_options: Constants.PGCL_OPTIONS,
      NV_options: Constants.NV_OPTIONS,

      // flags
      isNewUCVA: false,
      isNewPH: false,
      isNewPGCL: false,
      isNewNV: false,
      // new values
      newUCVA: "",
      newPH: "",
      newPGCL: "",
      newNV: "",

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null,

      // for avoid re-render
      itemListLoaded: false,
      isedited: false
    };
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    })
  }

  componentWillReceiveProps(props) {

    let { id, response, itemListLoaded } = this.state;
    this.setState({
      itemListLoaded: false,
    })
    if (props.editItem ) {

      if (props.editItem.title === pageName) {

        let itemList = props.editItem.item?props.editItem.item:[];
         
        if (itemList.length>0) {

          let { UCVA_options, PH_options, PGCL_options, NV_options } = this.state;

          /* add item if not in list */
          let UCVA_list = [];
          let PH_list = [];
          let PGCL_list = [];
          let NV_list = [];

          UCVA_list = UCVA_options;
          PH_list = PH_options;
          PGCL_list = PGCL_options;
          NV_list = NV_options;

          var selectedEye = {}
          var selectedUCVA = {}
          var selectedPH = {}
          var selectedPGCL = {}
          var selectedNV = {}
          var selectedCV = {}
          var flaggedEye = ""

          // if data not exist with default list, the data will add to list
          for( let i=0; i<itemList.length; i++ ) {

            UCVA_list = this.addItemInArray(UCVA_list, itemList[i].UCVA);
            PH_list = this.addItemInArray(PH_list, itemList[i].PH);
            PGCL_list = this.addItemInArray(PGCL_list, itemList[i].PGCL);
            NV_list = this.addItemInArray(NV_list, itemList[i].NV);

            response[i]['id'] = itemList[i].id
            response[i]['eye_type'] = { label: itemList[i].eye_type, value: itemList[i].eye_type } 
            response[i]['UCVA'] = {label: itemList[i].UCVA, value: itemList[i].UCVA}
            response[i]['PH'] = {label: itemList[i].PH, value: itemList[i].PH}
            response[i]['PGCL'] = {label: itemList[i].PGCL, value: itemList[i].PGCL}
            response[i]['NV'] = {label: itemList[i].NV, value: itemList[i].NV}
            response[i]['CV'] = {label: itemList[i].CV, value: itemList[i].CV} 

            // set default
            if(itemList[i].selected == true)
            {
            
              flaggedEye = itemList[i].eye_type;
            
            // if( itemList[i].eye_type == "RE" )
            // {
              selectedEye = response[i]['eye_type']
              selectedUCVA = response[i]['UCVA']
              selectedPH = response[i]['PH']
              selectedPGCL = response[i]['PGCL']
              selectedNV = response[i]['NV']
              selectedCV = response[i]['CV']
            }
          }
          
          this.setState({
            // id: item.id,
            flaggedEye: flaggedEye,
            response: response,
            itemListLoaded: true,

            selectedEye: selectedEye,
            selectedUCVA: selectedUCVA,
            selectedPH: selectedPH,
            selectedPGCL: selectedPGCL,
            selectedNV: selectedNV,
            selectedCV: selectedCV,

            UCVA_options: JSON.parse(JSON.stringify(UCVA_list)),
            PH_options: JSON.parse(JSON.stringify(PH_list)),
            PGCL_options: JSON.parse(JSON.stringify(PGCL_list)),
            NV_options: JSON.parse(JSON.stringify(NV_list))
          });

        }
      }
      
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  addComplaint = () => {

    let { response, patientAppointment } = this.state;
    
    let IsHaveRightEyeValues = false; 
    let IsHaveLeftEyeValues = false; 
    let data = null;
    let service_url = Constants.OPTHALMOLOGY_VISIONASSESSMENT;

    if(
      (this.validateObjData(response[0].eye_type) && ( this.validateObjData(response[0].UCVA) || this.validateObjData(response[0].PH) || this.validateObjData(response[0].PGCL) || this.validateObjData(response[0].NV) || this.validateObjData(response[0].CV))
      && (this.validateObjData(response[1].eye_type) && this.validateObjData(response[1].UCVA) || this.validateObjData(response[1].PH) || this.validateObjData(response[1].PGCL) || this.validateObjData(response[1].NV) || this.validateObjData(response[1].CV) ) )
    ){
      IsHaveLeftEyeValues=true
      IsHaveRightEyeValues=true
      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[0].eye_type?(response[0].eye_type).value:"",
          UCVA: response[0].UCVA?(response[0].UCVA).value:"",
          PH: response[0].PH?(response[0].PH).value:"",
          PGCL: response[0].PGCL?(response[0].PGCL).value:"",
          NV: response[0].NV?(response[0].NV).value:"",
          CV: response[0].CV?(response[0].CV).value:"",
          DV: ""
        },
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[1].eye_type?(response[1].eye_type).value:"",
          UCVA: response[1].UCVA?(response[1].UCVA).value:"",
          PH: response[1].PH?(response[1].PH).value:"",
          PGCL: response[1].PGCL?(response[1].PGCL).value:"",
          NV: response[1].NV?(response[1].NV).value:"",
          CV: response[1].CV?(response[1].CV).value:"",
          DV: ""
        }
      ];
      if( response[0].id )
      { 
        data[0]['id'] = response[0].id;
      }
      if( response[1].id ){
         data[1]['id'] = response[1].id; 
      }
    }
    else if (this.validateObjData(response[0].eye_type) && ( this.validateObjData(response[0].UCVA) || this.validateObjData(response[0].PH) || this.validateObjData(response[0].PGCL) || this.validateObjData(response[0].NV) || this.validateObjData(response[0].CV))){
      IsHaveRightEyeValues= true
      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[0].eye_type?(response[0].eye_type).value:"",
          UCVA: response[0].UCVA?(response[0].UCVA).value:"",
          PH: response[0].PH?(response[0].PH).value:"",
          PGCL: response[0].PGCL?(response[0].PGCL).value:"",
          NV: response[0].NV?(response[0].NV).value:"",
          CV: response[0].CV?(response[0].CV).value:"",
          DV: ""
        }
      ];
      if( response[0].id )
      { 
        data[0]['id'] = response[0].id;
      }
    }
    else if (this.validateObjData(response[1].eye_type) && this.validateObjData(response[1].UCVA) || this.validateObjData(response[1].PH) || this.validateObjData(response[1].PGCL) || this.validateObjData(response[1].NV) || this.validateObjData(response[1].CV) ){
      IsHaveLeftEyeValues = true
      data = [
        {
          appointment_id: patientAppointment.appointment_id,
          eye_type: response[1].eye_type?(response[1].eye_type).value:"",
          UCVA: response[1].UCVA?(response[1].UCVA).value:"",
          PH: response[1].PH?(response[1].PH).value:"",
          PGCL: response[1].PGCL?(response[1].PGCL).value:"",
          NV: response[1].NV?(response[1].NV).value:"",
          CV: response[1].CV?(response[1].CV).value:"",
          DV: ""
        }
      ]; 
      if( response[1].id ){
        data[1]['id'] = response[1].id; 
     }
    }

    if (IsHaveLeftEyeValues || IsHaveRightEyeValues) {

      if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
      {
        data['doctor_id'] = this.state.selectedDoctorId;
      }
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    } else {
      this.props.showResposeValue("error", "Select Any eye values")
    }
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getComplaintsSuccess = response => {

    if (response.status === "success") {

      
        this.props.clearEditedItem(pageName, {})
        
        // clear data
        this.clearComplaint();

        // reload data
        this.props.refreshData(pageName);
        this.props.showResposeValue("success", response.message)

    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getComplaintsFailure = error => {
    
    this.props.showResposeValue("error", error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states['copyFrom'] = {};
    states['selectedEye'] = {};
    states['selectedUCVA'] = {};
    states['selectedPH'] = {};
    states['selectedPGCL'] = {};
    states['selectedNV'] = {};
    states['selectedCV'] = {};
    states['copyRightToLeft'] = false;
    states['copyLeftToRight'] = false;
    states['itemListLoaded'] = false;
    states['flaggedEye'] = "";
    states['response'] = [
      {
        id: "",
        eye_type: { label: "RE", value: "RE"  },
        UCVA: {},
        PH: {},
        PGCL: {},
        NV: {},
        CV: {},
        DV: {}
      },
      {
        eye_type: { label: "LE", value: "LE"  },
        UCVA: {},
        PH: {},
        PGCL: {},
        NV: {},
        CV: {},
        DV: {}
      }
    ],
    // states["id"]="";

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  onPressButton=(key, selectedvalue)=>{
    
    let { response, flaggedEye, selectedUCVA, selectedPH, selectedPGCL, selectedNV, selectedCV } = this.state;

    if(flaggedEye || key == "selectedEye")
    {
        if (key == "selectedEye" ) {

          // swap data,
          if(selectedvalue.value == "RE")
          {
            selectedUCVA = response[0].UCVA;
            selectedPH = response[0].PH;
            selectedPGCL = response[0].PGCL;
            selectedNV = response[0].NV;
            selectedCV = response[0].CV;
          } else {
            selectedUCVA = response[1].UCVA;
            selectedPH = response[1].PH;
            selectedPGCL = response[1].PGCL;
            selectedNV = response[1].NV;
            selectedCV = response[1].CV;
          }

          this.setState({
            selectedEye:selectedvalue,
            flaggedEye: selectedvalue.value,

            selectedUCVA: selectedUCVA,
            selectedPH: selectedPH,
            selectedPGCL: selectedPGCL,
            selectedNV: selectedNV,
            selectedCV: selectedCV,

          })
        
        }else if(key =="selectedUCVA"){


          flaggedEye == "RE"?response[0].UCVA = selectedvalue:response[1].UCVA = selectedvalue

          this.setState({
            response,
            selectedUCVA: selectedvalue
          })

        }else if(key == "selectedPH"){

          flaggedEye == "RE"?response[0].PH = selectedvalue:response[1].PH = selectedvalue

          this.setState({
            response,
            selectedPH: selectedvalue
          })

        }else if(key == "selectedNV"){

          flaggedEye == "RE"?response[0].NV = selectedvalue:response[1].NV = selectedvalue

          this.setState({
            response,
            selectedNV: selectedvalue
          })
        }else if(key == "selectedPGCL"){

          flaggedEye == "RE"?response[0].PGCL = selectedvalue:response[1].PGCL = selectedvalue

          this.setState({
            response,
            selectedPGCL: selectedvalue
          })
        }else if(key == "selectedCV"){

          flaggedEye == "RE"?response[0].CV = selectedvalue:response[1].CV = selectedvalue
          
          this.setState({ 
            response,
            selectedCV: selectedvalue
          })
        }

        this.props.clearEditedItem(pageName,{})

      }else{
        this.props.showResposeValue("error", "Select eye type first")
      }

  }

  copyPreviousValue(){
   
    var service_url = Constants.GET_PREVIOUS_VISSION_ASSESSMENT+"?patient_id="+this.state.patientAppointment.patient_id;

    if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
    {
      service_url = service_url + "&doctor_id=" + this.state.selectedDoctorId;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.copyPreviousValueSuccess,
      this.copyPreviousValueFailure
    );
  }

  copyPreviousValueSuccess= success =>{

    // alert(JSON.stringify(success))

    if(success.status == "success"){
      var data = success.data

      this.setState({
        getPreviousVissionAssessmentValue:data
      },()=>{

        if(data.length>0){
          if(data.length > 1){
            this.changePreviousValue(data,"RE")
          }else{
            this.changePreviousValue(data,"")
          }
        }


      })
    }
  }
  copyPreviousValueFailure= error =>{}

  changePreviousValue(data,eyeType){
    var states = this.state
    for(var i=0; i<data.length; i++){
      if(data[i].eye_type == eyeType){
        var eye={label : data[i].eye_type , value:data[i].eye_type}
        var UCVA={label : data[i].UCVA , value:data[i].UCVA}
        var PH={label : data[i].PH , value:data[i].PH}
        var PGCL={label : data[i].PGCL , value:data[i].PGCL}
        var NV={label : data[i].NV , value:data[i].NV}
        var CV={label : data[i].CV , value:data[i].CV}

        states["selectedEye"] = eye;
        states["selectedUCVA"] = UCVA;
        states["selectedPH"] = PH;
        states["selectedPGCL"] = PGCL;
        states["selectedNV"] = NV;
        states["selectedCV"] = CV;

        states["flaggedEye"] = eyeType

        this.setState({ states })

      }else if(eyeType == ""){

        var eye={label : data[0].eye_type , value:data[0].eye_type}
        var UCVA={label : data[0].UCVA , value:data[0].UCVA}
        var PH={label : data[0].PH , value:data[0].PH}
        var PGCL={label : data[0].PGCL , value:data[0].PGCL}
        var NV={label : data[0].NV , value:data[0].NV}
        var CV={label : data[0].CV , value:data[0].CV}

        states["selectedEye"] = eye;
        states["selectedUCVA"] = UCVA;
        states["selectedPH"] = PH;
        states["selectedPGCL"] = PGCL;
        states["selectedNV"] = NV;
        states["selectedCV"] = CV;
        
        this.setState({ states })
      }
    }
  }

  copyCheckBoxValues(key){
    this.setState({
      copyFrom:key
    },()=>{
      this.changePreviousValue(this.state.getPreviousVissionAssessmentValue,key)
    })
  }

  renderCheckBoxPrev(label, value, key) {
    return (
        <View style={{ flexDirection: 'row', marginRight: '1.95vw' }}>
            <CheckBox value={value== key ? true : false} style={{height:"1.2vw", width:'1.2vw'}}
                onValueChange={() => {
                    this.copyCheckBoxValues(key)
                }}

            />
            <Text style={styles.RenderCheckBoxPrevTxt}>{label}</Text>
        </View>
    );
  }

  renderCheckBox=(label, value, key)=>{

    return (
        <View style={styles.renderChkBox}>
            <CheckBox value={value}
                style={{marginRight: '0.8vw', height:'1.2vw', width:'1.2vw'}}
                onValueChange={() => {

                  var states =this.state;

                  if( key == "copyRightToLeft" && !value)
                  {
                    states["copyLeftToRight"] = false
                  }
                  if( key == "copyLeftToRight" && !value)
                  {
                    states["copyRightToLeft"] = false
                  }

                  states[key]=!value

                  this.setState({
                    states
                  },() => {

                      if(!value)
                      {
                        this.copyData()
                      }else{
                        this.clearData( key == "copyRightToLeft"?"LE":"RE")
                      }
                  })
                }}

            />
            <Text style={styles.renderChkBoxText}>{label}</Text>
        </View>
    );
  }

  copyData=()=>{
    
    let { response, flaggedEye } = this.state;

    let originData= null;
    console.log("copy", flaggedEye);
    originData = response && response.filter((item)=>{
        return item.eye_type.value == flaggedEye
      })

      console.log(" originData ", originData)

    if( originData && originData.length>0 ) {

      for( let i=0; i<response.length; i++ ) {
        
        if( response[i].eye_type.value != flaggedEye ) {

          // assign
          response[i]['UCVA'] = originData[0]['UCVA'];
          response[i]['PH'] = originData[0]['PH'];
          response[i]['PGCL'] = originData[0]['PGCL'];
          response[i]['NV'] = originData[0]['NV']
          response[i]['CV'] = originData[0]['CV']

          // update
          this.setState({ 
            response: response
          })

        }
      }
    }

  }

  clearData(ClearableEyeType){
    let { response, flaggedEye } = this.state;
    if(ClearableEyeType=="RE"){
      response[0]['UCVA'] = {};
      response[0]['PH'] = {};
      response[0]['PGCL'] = {};
      response[0]['NV'] = {}
      response[0]['CV'] = {}
    }
    else if(ClearableEyeType=="LE"){
      response[1]['UCVA'] = {};
      response[1]['PH'] = {};
      response[1]['PGCL'] = {};
      response[1]['NV'] = {}
      response[1]['CV'] = {}
    }
    this.setState({ 
      response: response
    })
  }

  render() {
    
    let { id } = this.state;

    return (
      <View>

        {/* {this.renderSubHeadingBorder("Vision Assessment", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Vision Assessment"} clearAllData={this.clearComplaint.bind(this)}/>

        <View style={styles.CopyView}>
        <Text style={styles.CopyText}>{"Copy From Previous Vision Assessment"}</Text>
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.copyPreviousValue() }} style={styles.CopyButton}>
            <Text style={styles.CopyBtnText}>{"Copy"}</Text>
          </TouchableOpacity>
        </View>
        {
          this.state.getPreviousVissionAssessmentValue.length > 1 ?
            <View style={styles.RenderCheckBoxView}>
          
              <View style={{ flexDirection: 'row' }}>
                {this.renderCheckBoxPrev("Right Eye", this.state.copyFrom, "RE")}
                {this.renderCheckBoxPrev("Left Eye", this.state.copyFrom, "LE")}
              </View>
            </View>
            : null
        }
           <CommonSectionHeader heading={"Eye Type"}  />
        {this.renderSubHeadingBorder("", false, true)}

        <RightPanelEyeSelection 
          getAvailableEyeTypes={null} //this.props.getAvailableEyeTypes 
          id={this.state.id} 
          isLocked={this.state.isLocked} 
          selectedEye={this.state.selectedEye} 
          onPressButton={this.onPressButton.bind(this)} />
          
        {/*
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>

        {
          this.state.getAvailableEyeTypes.length > 0 ?
          
          Constants.eye_type.map((item, index) => {

            var tempData=false

            var result = this.state.getAvailableEyeTypes && this.state.getAvailableEyeTypes.map((eye,index)=>{
                if (eye == item.value){
                  tempData=true
                  return true
                }else{
                  return false
                }
            })

            return(
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                  <CommonButton
                    disable={(tempData || id || this.state.isLocked) ? true :false}
                    // disable={found ? true : false}
                    item={item}
                    selectedvalue={this.state.selectedEye}
                    butttonText={item.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedEye"} />
                </View>
            )
          })
        :Constants.eye_type.map((item, index) => {
            return (
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
        }
        </View>
        */}

        {/* {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false,
          'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} */}
        <View style={styles.EmptyView}></View>
        
        {/* {this.renderTitleWithMultipleBtn("UCVA", Constants.UCVA_OPTIONS, 4, false, 'selectedUCVA', this.state.selectedUCVA, this.updateState.bind(this), false, true)} */}
        <CommonSectionHeader heading={"Uncorrected visual acuity"}  />      
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          Constants.UCVA_OPTION.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedUCVA}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedUCVA"} />
              </View>
            )
          })
        }
        </View>
        {/* {this.renderNewButton(this.inputUCVA, 'Uncorrected visual acuity  value', 'newUCVA', this.state.newUCVA, 'isNewUCVA', this.state.isNewUCVA, 'selectedUCVA', 'UCVA_options')} */}
        <View style={styles.EmptyView}></View>

        {/* {this.renderTitleWithMultipleBtn("UCVA", this.state.UCVA_options, 4, false,
          'selectedUCVA', this.state.selectedUCVA, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} 
        {this.renderNewButton(this.inputUCVA, 'UCVA value', 'newUCVA', this.state.newUCVA, 'isNewUCVA', this.state.isNewUCVA, 'selectedUCVA', 'UCVA_options')}
        <View style={{ height: 15 }}></View> */}

        {/* {this.renderTitleWithMultipleBtn("PH", this.state.PH_options, 4, false,
          'selectedPH', this.state.selectedPH, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} 
        {this.renderNewButton(this.inputPH, 'PH value', 'newPH', this.state.newPH, 'isNewPH', this.state.isNewPH, 'selectedPH', 'PH_options')}
        <View style={{ height: 15 }}></View> */}
        
        <CommonSectionHeader heading={"Pin Hole visual acuity"}  />
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          Constants.UCVA_OPTION.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedPH}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedPH"} />
              </View>
            )
          })
        }
        </View>
        {/* {this.renderNewButton(this.inputPH, 'Pin Hole visual acuity value', 'newPH', this.state.newPH, 'isNewPH', this.state.isNewPH, 'selectedPH', 'PH_options')} */}
        <View style={styles.EmptyView}></View>

        {/* {this.renderTitleWithMultipleBtn("PG/CL", this.state.PGCL_options, 4, false,
          'selectedPGCL', this.state.selectedPGCL, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} 
        {this.renderNewButton(this.inputPGCL, 'PG/CL value', 'newPGCL', this.state.newPGCL, 'isNewPGCL', this.state.isNewPGCL, 'selectedPGCL', 'PGCL_options')}
        <View style={{ height: 15 }}></View> */}
        <CommonSectionHeader heading={"PG/CL"}  />
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          Constants.UCVA_OPTION.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedPGCL}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedPGCL"} />
              </View>
            )
          })
        }
        </View>
        {/* {this.renderNewButton(this.inputPGCL, 'PG/CL value', 'newPGCL', this.state.newPGCL, 'isNewPGCL', this.state.isNewPGCL, 'selectedPGCL', 'PGCL_options')} */}
        <View style={styles.EmptyView}></View>

        <CommonSectionHeader heading={"NV"}  />
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          Constants.NV_TYPE_OPTIONS.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedNV}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedNV"} />
              </View>
            )
          })
        }
        </View>
       
        {/* {this.renderNewButton(this.inputNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedNV', 'NV_options')} */}
        <View style={styles.EmptyView}></View>

        {/* {this.renderTitleWithMultipleBtn("NV", this.state.NV_options, 4, false,
          'selectedNV', this.state.selectedNV, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)} 
        {this.renderNewButton(this.inputNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedNV', 'NV_options')}
        <View style={{ height: 15 }}></View> */}
           <CommonSectionHeader heading={"CV"}  />
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap', marginBottom: '0.65vw'}}>
        {
          Constants.CV_OPTIONS.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedCV}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedCV"} />
              </View>
            )
          })
        }
        </View>

        {
          this.state.flaggedEye == "RE" ?
          this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
          : this.state.flaggedEye == "LE" ?
          this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
          :null
        }

        <View style={styles.AddBtn}>
          <TouchableOpacity 
            onPress={()=>this.addComplaint()} 
            disabled={this.state.isLocked}
            >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
        <View>
          <View>
            {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Vision Assessment Created Successfully' : 
                    this.state.errorMessageFlag ?'Vision Assessment Failed' : null}</Text>
                </View>
              ) :null} */}
          </View >
        </View >
      </View>
    );
  }

  renderNewButton=(refInput, placeholder, stateName, stateValue, flagName, flagValue, updateSelectState, updateToList)=>{

    return (
      <View>
        {/* new block */}
        {flagValue ?
          <View style={{ marginTop: '0.65vw' }}> 
          {this.renderTextFieldUpdateObj(placeholder, stateName, stateValue, false, 1, refInput,
            { flag: flagName, newState: stateName, forState: updateSelectState, stateList: updateToList })}
          </View>: null
        }
        <TouchableOpacity onPress={() => { this.triggerNewItemAdd(flagName, refInput) }} style={{width:'7.16vw'}}>
            {this.renderIconBtn("plus", "New", false, "key")}
        </TouchableOpacity>
        {/* new block end */}
      </View>
    )
  }

  validateObjData=(data)=>{

    return data && (typeof data === "object") && (Object.keys(data).length>0) && (data.value=="0" || data.value )?true:false
  }
}

const styles = StyleSheet.create({
  CopyView:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:"space-between",
    marginBottom: '0.97vw'
  },
  CopyText:{
    fontSize:'0.91vw'
  },
  CopyButton:{
    backgroundColor:color.themeDark ,
    borderRadius:'0.26vw',
    paddingVertical:'0.52vw',
    paddingHorizontal:'0.84vw',
    justifyContent:"center"
  },
  CopyBtnText:{
    color:color.white,
    fontSize:'0.8vw'
  },
  RenderCheckBoxView:{
    marginTop:'0.97vw',
    marginBottom:'1.3vw'
  },
  RenderCheckBoxPrevTxt:{
    marginLeft: '0.65vw', 
    fontSize: '0.8vw', 
    color: "#888888"
  },
  CommonBtnView:{
    marginBottom: '0.65vw', 
    marginRight: '0.65vw'
  },
  renderChkBox:{
    flexDirection: 'row', 
    alignSelf:'flex-end', 
    marginTop: '0.65vw'
  },
  renderChkBoxText:{
    fontSize: '0.85vw', 
    color: "#888888"
  },
  AddBtn:{
    marginTop: '1.3vw' ,
    alignSelf:"center"
  },
  EmptyView:{
    height: '0.97vw'
  }
})