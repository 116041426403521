//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
import { HistoryYesOrNoSection, CommonHistorySectionHeader, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
// create a component
const pageName = "isHospitalizationHistory";
var Messages = require('../../../../utils/InfoMessages')

const hospitalisedSurgeries = [
    { label: "Hospitalised", value: "Hospitalised" },

    { label: "Surgery", value: "Surgery" }
]


const hospitalisedFor = [
    { label: "Joint Pain", value: "Joint Pain" },
    { label: "Asthma", value: "Asthma" },
    { label: "Seizures", value: "Seizures" },
    { label: "Stroke", value: "Stroke" },
    { label: "Blood Problems", value: "Blood Problems" },
    { label: "Cancer", value: "Cancer" },
    { label: "Heart Disease", value: "Heart Disease" },
    { label: "Thyroid Disorder", value: "Thyroid Disorder" },
    { label: "High BP", value: "High BP" },
    { label: "Diabetes / Blood Sugar", value: "Diabetes / Blood Sugar" },
    { label: "Mental Illness", value: "Mental Illness" }
]

export class HospitalizationHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            newButtonValue: "",

            // selectedProbloms: {},
            // problomsList: problomsList,
            // showProblomsNewButtonFlag: true,

            selectedSurgeriesType: {},
            surgeriesTypeList: hospitalisedFor,
            showSurgeriesTypeNewButtonFlag: true,

            selectedHospitalised: {},
            hospitalisedList: hospitalisedSurgeries,
            showHospitalisedNewButtonFlag: true,

            commentTextBoxValue: "",
            hospitalNameDescription:"",
            comment:"",
            selectedYear: "",
            // diseaseName:{}
            id:"",
            disableButton:false
           
        }
    }



    onPressButton(key, selectedValue) {
        

        var states = this.state;
        if (key == "showSurgeriesTypeNewButtonFlag") {
            states[key] = false;
        }else if(key=="selectedSurgeriesType"){
            this.setState({
                selectedSurgeriesType:selectedValue
            })
        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    componentWillReceiveProps(props){
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            // props.editHistoryItem=[]
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && props.editHistoryItem.editItem.item && Object.keys(props.editHistoryItem.editItem.item).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                var {surgeriesTypeList} = this.state

                console.log(JSON.stringify(data.item.id));
                // states["diseaseName"]={}
                states["hospitalNameDescription"]=data.item.hospital_name_or_description
                states["selectedYear"]=data.item.hospitalized_year
                states["comment"]=data.comment
        
                var preparedataSurgeryType={
                    value:data.item.disease_name,value:data.item.disease_name
                }

                surgeriesTypeList.push(preparedataSurgeryType);
                
                surgeriesTypeList=this.removeDuplicate(surgeriesTypeList);
                states["selectedSurgeriesType"]=preparedataSurgeryType;

                states["selectedHospitalised"]=data.item.is_surgery ?
                { label: "Surgery", value: "Surgery" }:
                { label: "Hospitalised", value: "Hospitalised" }

                states["id"]=data.item.id;

                this.setState({
                    states,surgeriesTypeList,
                    disableButton: true,

                },()=>{
                    this.props.editHistory({},pageName)
                })
            }
        }
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var { surgeriesTypeList } = this.state;
        var states = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }


        if (!this.state.showSurgeriesTypeNewButtonFlag) {
            surgeriesTypeList.push(preparData);
            surgeriesTypeList = this.removeDuplicate(surgeriesTypeList);
            states["newButtonValue"] = "";
            states["showSurgeriesTypeNewButtonFlag"] = true;
            states["selectedSurgeriesType"] = preparData;
        }
        this.setState({
            surgeriesTypeList, states
        })

    }


    richTextBox(key,value) {
        return (
            <View>
                <TextInput
                    multiline={true}
                    style={{ paddingTop: 10, paddingLeft: 10, width: "85%", alignSelf: 'center', marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, height: 60 }}
                    value={value}
                    onChangeText={(text) => {
                        var states=this.state
                        states[key]=text
                        this.setState({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                           states
                        })
                    }}
                />
            </View>
        )
    }


    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData,disableButton) {
        // console.log(JSON.stringify(selectedValue))
        var data = this.removeDuplicate(setOfData)

        return (
            <View style={[{ marginTop: 10, paddingLeft: 20, }]}>

                {/* <View>
                    <CommonHistorySectionHeader heading1={question} noOfColumn={1} />
                </View> */}

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                    {
                        data.map((item, index) => {
                            if(item.value&&item.label){
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        disable= {disableButton? true : false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                            }
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        disable= {disableButton? true : false}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ paddingLeft: 15, width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    clearAllData() {
        var states=this.state
        // states["diseaseName"]={}
        states["hospitalNameDescription"]=""
        states["selectedYear"]=""
        states["comment"]=""

        states["selectedSurgeriesType"]={}
        states["selectedHospitalised"]={}
        states["disableButton"]=false
        

        this.setState({
            states
        })

     }


    onPressAddButton() {
        // console.log(
        //     "patientAppointment --- > " +
        //     JSON.stringify(this.state.patientAppointment)
        // );
        var states=this.state
        var disease={}
        if(states.selectedSurgeriesType.length > 0){
            for (let i = 0; i < states.selectedSurgeriesType.length>0;i++) {
               disease.push(states.surgeriesTypeList.value) 
            }
        }else if(states.selectedHospitalizedType){
                this.setState({
                   selectedHospitalised:hospitalisedList
                })
            }   
        
        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "appointment_id": this.state.patientAppointment.appointment_id, 
            "disease_name":this.state.selectedSurgeriesType.value,
            "hospital_name_or_description":this.state.hospitalNameDescription,
            "hospitalized_year": this.state.selectedYear?  this.state.selectedYear: null,
            "comments": this.state.comment,
            "is_surgery":this.state.selectedHospitalised&&this.state.selectedHospitalised.value=="Surgery"?true:false
            
        };
        if(this.state.id){
            data["id"]=this.state.id
        }

    // "patient_id": 1,
    // "appointment_id":1,
    // "disease_name":"Illness",
    // "hospital_name_or_description": "Saratha clinic",
    // "hospitalized_year": 2015,
    // "comments": "Cccc",
    // "is_surgery": true

    if(Object.keys(this.state.selectedSurgeriesType).length==0 && this.state.hospitalNameDescription.length==0 && Object.keys(this.state.selectedHospitalised).length==0){
        this.props.showResposeValue("error",Messages.HistorySectionValitation)
    }else if(Object.keys(this.state.selectedSurgeriesType).length==0){
        this.props.showResposeValue("error",Messages.HospitalizationSelectIllness)
    }else if(Object.keys(this.state.selectedHospitalised).length==0){
        this.props.showResposeValue("error",Messages.HospitalizationSelectType)
    }else if(Object.keys(this.state.selectedYear).length==0){
        this.props.showResposeValue("error",Messages.HospitalizationSelectYear)
    }else if(Object.keys(this.state.hospitalNameDescription).length==0){
        this.props.showResposeValue("error",Messages.HospitalNameDescription)
    }
    else{
        var service_url = Constants.POST_HOSPITALISATION_HISTORY;
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.HospitalizationHistorySaveSuccess,
            this.HospitalizationHistorySaveFailure
        );
     }    
    }
    HospitalizationHistorySaveSuccess = (success) => {
        // alert(JSON.stringify(success));
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message);
            this.props.refreshRighSideComponent(pageName);
            this.clearAllData();

        } else {
            this.props.showResposeValue("error", success.message)
        }
    };
    HospitalizationHistorySaveFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    };

     

    render() {
        var getdate = [];
        var today = new Date();
        var year = today.getFullYear();
        for (var i = year; i > 1979; i--) {
          getdate.push(i);
        };
        return (
            <View style={styles.container}>

                <DoctorNotesCommonRightSideHeader title={"Hospitalization History"} clearAllData={this.clearAllData.bind(this)} />
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Hospitalised for"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedHospitalised",
                        this.state.selectedHospitalised,
                        "",
                        false,
                        this.state.hospitalisedList,
                        this.state.disableButton)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Have you been hospitalised or undergone any surgeries ? "} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedSurgeriesType",
                        this.state.selectedSurgeriesType,
                        "showSurgeriesTypeNewButtonFlag",
                        this.state.showSurgeriesTypeNewButtonFlag,
                        this.state.surgeriesTypeList,
                        this.state.disableButton)}
                </View>


                <View style={{ marginBottom: 20, height: 35, flexDirection: 'row', justifyContent: 'space-between', backgroundColor: color.sectionHeadingDarkColor }}>
                    <CommonHistorySectionHeader
                        heading1={"Year"}
                        noOfColumn={1}
                    />
                    <View>
                        <Picker
                            selectedValue={this.state.selectedYear}
                            style={{ height: 35, backgroundColor: color.sectionHeadingDarkColor, borderColor: color.sectionHeadingDarkColor }}
                            itemStyle={{ fontSize: 14 }}
                            onValueChange={(itemvalue) => {
                                var states = this.state;
                                states["selectedYear"] = itemvalue
                                this.setState({ states });
                            }}
                        >
                           <Picker.Item label={"Select"} value={"Select"} />
                            {
                                getdate.map((value, index) => {
                                    return <Picker.Item label={value} value={value} />
                                })
                            }

                        </Picker>
                    </View>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Hospital Name/Description"} noOfColumn={1} />
                    {this.richTextBox("hospitalNameDescription",this.state.hospitalNameDescription)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Comments"} noOfColumn={1} />
                    {this.richTextBox("comment",this.state.comment)}
                </View>
                <View style={{width:100,alignSelf:"center"}}>
                  <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                </View>  
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
