import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "prenatalFactors"

const infectionList = [
    { label: "1st Trimester", value: "1" },
    { label: "2nd Trimester", value: "2" },
    { label: "3rd Trimester", value: "3" },
]

export class PrenatalFactorsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",
            nutritionalDeficiencyFlag: false,
            nutritionalDeficiencyDetails: "",

            exposureofMedicationFlag: false,
            exposureofMedicationDetails: "",

            bleedingDuringPregnancyFlag: false,
            bleedingDuringPregnancyDetails: "",

            abortionFlag: false,
            abortionDetails: "",

            rhIncompatabilityFlag: false,
            rhIncompatabilityDetails: "",

            decreasedFetalMovementsFlag: false,
            decreasedFetalMovementsDetails: "",

            hypothyroidismFlag: false,
            hypothyroidismDetails: "",

            prematurePlacentalSeparationFlag: false,
            prematurePlacentalSeparationDetails: "",

            diabetesFlag: false,
            diabetesDetails: "",

            preeclampsiaToxaemiaFlag: false,
            preeclampsiaToxaemiaDetails: "",

            psychiatricDisorderFlag: false,
            psychiatricDisorderDetails: "",


            otherPrenatalConditionsFlag: false,
            otherPrenatalConditionsDetails: "",


            selectInfectionButtonFlag: false,
            selectedInfection: {},

            selectedTrimester1: [],
            selectedTrimester2: [],
            selectedTrimester3: [],

            trimesterNewbuttonFlag: true,
            getListofTrimesterInfections: []
        }
    }



    componentDidMount() {
        this.getTrimesterInfections()
    }
    getTrimesterInfections() {
        var service_url = Constants.PSY_TRIMESTER_INFECTION + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getTrimesterInfectionsSuccess,
            this.getTrimesterInfectionsFailure
        );
    }

    getTrimesterInfectionsSuccess = success => {
        // alert(JSON.stringify(success))
        if (success.status == "success") {
            var infection = success.data;
            var { getListofTrimesterInfections } = this.state;
            if (infection.length > 0) {
                for (let i = 0; i < infection.length; i++) {
                    var prepareData = { label: infection[i], value: infection[i] }
                    getListofTrimesterInfections.push(prepareData)
                }
            } else {
                getListofTrimesterInfections = []
            }
            this.setState({
                getListofTrimesterInfections
            })

        }
    }
    getTrimesterInfectionsFailure = error => { }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["nutritionalDeficiencyFlag"] = data.nutritional_deficiency;
                states["nutritionalDeficiencyDetails"] = data.nutritional_deficiency_detail;

                states["exposureofMedicationFlag"] = data.exposure_of_medication;
                states["exposureofMedicationDetails"] = data.exposure_of_medication_detail;

                states["bleedingDuringPregnancyFlag"] = data.bleeding_during_pregnancy;
                states["bleedingDuringPregnancyDetails"] = data.bleeding_during_pregnancy_detail;

                states["abortionFlag"] = data.abortion;
                states["abortionDetails"] = data.abortion_detail;

                states["rhIncompatabilityFlag"] = data.rh_incompatability;
                states["rhIncompatabilityDetails"] = data.rh_incompatability_detail;

                states["decreasedFetalMovementsFlag"] = data.decreased_fatal_movements;
                states["decreasedFetalMovementsDetails"] = data.decreased_fatal_movements_detail;

                states["hypothyroidismFlag"] = data.hypothyroidism;
                states["hypothyroidismDetails"] = data.hypothyroidism_detail;

                states["prematurePlacentalSeparationFlag"] = data.premature_placental_separation;
                states["prematurePlacentalSeparationDetails"] = data.premature_placental_separation_detail;

                states["diabetesFlag"] = data.diabetes;
                states["diabetesDetails"] = data.diabetes_detail;

                states["preeclampsiaToxaemiaFlag"] = data.preeclampsia_or_toxaemia;
                states["preeclampsiaToxaemiaDetails"] = data.preeclampsia_or_toxaemia_detail;

                states["psychiatricDisorderFlag"] = data.psychiatric_disorder;
                states["psychiatricDisorderDetails"] = data.psychiatric_disorder_detail;

                states["otherPrenatalConditionsFlag"] = data.other_prenatal_conditions;
                states["otherPrenatalConditionsDetails"] = data.other_prenatal_conditions_detail;

                var trimester1 = this.preparingForList(data.infections_1_trimester)
                var trimester2 = this.preparingForList(data.infections_2_trimester)
                var trimester3 = this.preparingForList(data.infections_3_trimester)

                states["selectedTrimester1"] = trimester1;
                states["selectedTrimester2"] = trimester2;
                states["selectedTrimester3"] = trimester3;

                // states["selectedInfection"] = {};
                states["id"] = data.id;

                if (trimester1.length > 0) {
                    states["selectInfectionButtonFlag"] = true
                    states["selectedInfection"] = { label: "1st Trimester", value: "1" };
                } else if (trimester2.length > 0) {
                    states["selectInfectionButtonFlag"] = true
                    states["selectedInfection"] = { label: "2st Trimester", value: "2" };
                } else if (trimester3.length > 0) {
                    states["selectInfectionButtonFlag"] = true
                    states["selectedInfection"] = { label: "3st Trimester", value: "3" };
                }


                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    preparingForList(infrction) {
        var data = []
        if (infrction) {
            var getPrepareDatas = []
            var converToArray = infrction.split(",");
            for (let i = 0; i < converToArray.length; i++) {
                var prepareData = { label: converToArray[i], value: converToArray[i] }
                getPrepareDatas.push(prepareData);
            }
            data = getPrepareDatas;
        } else {
            data = []
        }
        return data
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue,
        selectBtnKey, selectedBtnValue, buttonList, showNewButton = false, newButtonKey = "", newbuttonFlag = false) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


                // // drop down list 
                // dropDownList = {
                //   ["mother","father","son","mom"]
                // }
                // selectedDropDownlist={this.state.selectedDropDownlist}
                // dropDownListKey={"selectedDropDownlist"}
                // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
                // // label with button select 

                selectedBtnValue={selectedBtnValue}
                selectBtnKey={selectBtnKey}
                buttonList={buttonList}

                showNewButton={showNewButton}
                newButtonKey={newButtonKey}
                newbuttonFlag={newbuttonFlag}
                onPressNewButton={this.onPressNewButton.bind(this)}
                onPressInfections={this.onPressInfections.bind(this)}
                onSubmitToAddNewButtonValue={this.onSubmitToAddNewButtonValue.bind(this)}
            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onSubmitToAddNewButtonValue(value) {
        var prepareData = { label: value, value: value }
        var { selectedTrimester1, selectedTrimester2, selectedTrimester3, getListofTrimesterInfections } = this.state;

        if (this.state.selectedInfection.value == 1) {
            selectedTrimester1.push(prepareData);
            selectedTrimester1 = this.removeDuplicate(selectedTrimester1);
        } else if (this.state.selectedInfection.value == 2) {
            selectedTrimester2.push(prepareData);
            selectedTrimester2 = this.removeDuplicate(selectedTrimester2);
        } else if (this.state.selectedInfection.value == 3) {
            selectedTrimester3.push(prepareData);
            selectedTrimester3 = this.removeDuplicate(selectedTrimester3);
        }

        getListofTrimesterInfections.push(prepareData)
        getListofTrimesterInfections = this.removeDuplicate(getListofTrimesterInfections);

        this.setState({
            selectedTrimester1, selectedTrimester2, selectedTrimester3, trimesterNewbuttonFlag: true, getListofTrimesterInfections
        })
    }

    onPressNewButton(key) {
        var states = this.state;
        states[key] = false;
        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onPressInfections(key, selectedValue) {
        if (key == "selectedTrimester1") {

            var { selectedTrimester1 } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedTrimester1.length > 0) {
                for (var i = 0; i < selectedTrimester1.length; i++) {
                    if (selectedTrimester1[i].value == selectedValue.value) {
                        spliceData = selectedTrimester1[i];
                        selectedTrimester1.splice(i, 1)
                        var data = this.removeDuplicate(selectedTrimester1);
                        this.setState({ selectedTrimester1: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedTrimester1.push(selectedValue); }
            } else {
                selectedTrimester1.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedTrimester1);
            this.setState({ selectedTrimester1: data })

        } else if (key == "selectedTrimester2") {

            var { selectedTrimester2 } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedTrimester2.length > 0) {
                for (var i = 0; i < selectedTrimester2.length; i++) {
                    if (selectedTrimester2[i].value == selectedValue.value) {
                        spliceData = selectedTrimester2[i];
                        selectedTrimester2.splice(i, 1)
                        var data = this.removeDuplicate(selectedTrimester2);
                        this.setState({ selectedTrimester2: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedTrimester2.push(selectedValue); }
            } else {
                selectedTrimester2.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedTrimester2);
            this.setState({ selectedTrimester2: data })

        } else if (key == "selectedTrimester3") {

            var { selectedTrimester3 } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedTrimester3.length > 0) {
                for (var i = 0; i < selectedTrimester3.length; i++) {
                    if (selectedTrimester3[i].value == selectedValue.value) {
                        spliceData = selectedTrimester3[i];
                        selectedTrimester3.splice(i, 1)
                        var data = this.removeDuplicate(selectedTrimester3);
                        this.setState({ selectedTrimester3: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedTrimester3.push(selectedValue); }
            } else {
                selectedTrimester3.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedTrimester3);
            this.setState({ selectedTrimester3: data })

        } else {
            var states = this.state;
            states[key] = selectedValue
            this.setState({ states })
        }

        // its for ,,when new txt box is showing at the time click the another button txt box will be hide 
        this.setState({
            trimesterNewbuttonFlag: true
        })
    }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["nutritionalDeficiencyFlag"] = false;
        states["nutritionalDeficiencyDetails"] = "";

        states["exposureofMedicationFlag"] = false;
        states["exposureofMedicationDetails"] = "";

        states["bleedingDuringPregnancyFlag"] = false;
        states["bleedingDuringPregnancyDetails"] = "";

        states["abortionFlag"] = false;
        states["abortionDetails"] = "";

        states["rhIncompatabilityFlag"] = false;
        states["rhIncompatabilityDetails"] = "";

        states["decreasedFetalMovementsFlag"] = false;
        states["decreasedFetalMovementsDetails"] = "";

        states["hypothyroidismFlag"] = false;
        states["hypothyroidismDetails"] = "";

        states["prematurePlacentalSeparationFlag"] = false;
        states["prematurePlacentalSeparationDetails"] = "";

        states["diabetesFlag"] = false;
        states["diabetesDetails"] = "";

        states["preeclampsiaToxaemiaFlag"] = false;
        states["preeclampsiaToxaemiaDetails"] = "";

        states["psychiatricDisorderFlag"] = false;
        states["psychiatricDisorderDetails"] = "";

        states["otherPrenatalConditionsFlag"] = false;
        states["otherPrenatalConditionsDetails"] = "";

        states["selectedTrimester1"] = [];
        states["selectedTrimester2"] = [];
        states["selectedTrimester3"] = [];

        states["selectInfectionButtonFlag"] = false;
        states["selectedInfection"] = {};




        this.setState({ states })
    }

    getListOfTrimesterValue(list) {
        var data;
        if (list.length > 0) {
            var getList = []
            for (let i = 0; i < list.length; i++) {
                getList.push(list[i].value)
            }
            data = getList.toString()
        } else {
            data = ""
        }
        return data
    }

    onPressAddButton() {
        var states = this.state;



        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {

            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,
                "nutritional_deficiency": states.nutritionalDeficiencyFlag,
                "nutritional_deficiency_detail": states.nutritionalDeficiencyDetails,
                "exposure_of_medication": states.exposureofMedicationFlag,
                "exposure_of_medication_detail": states.exposureofMedicationDetails,
                "infections_1_trimester": this.getListOfTrimesterValue(this.state.selectedTrimester1),
                "infections_2_trimester": this.getListOfTrimesterValue(this.state.selectedTrimester2),
                "infections_3_trimester": this.getListOfTrimesterValue(this.state.selectedTrimester3),
                "bleeding_during_pregnancy": states.bleedingDuringPregnancyFlag,
                "bleeding_during_pregnancy_detail": states.bleedingDuringPregnancyDetails,
                "abortion": states.abortionFlag,
                "abortion_detail": states.abortionDetails,
                "rh_incompatability": states.rhIncompatabilityFlag,
                "rh_incompatability_detail": states.rhIncompatabilityDetails,
                "decreased_fatal_movements": states.decreasedFetalMovementsFlag,
                "decreased_fatal_movements_detail": states.decreasedFetalMovementsDetails,
                "hypothyroidism": states.hypothyroidismFlag,
                "hypothyroidism_detail": states.hypothyroidismDetails,
                "premature_placental_separation": states.prematurePlacentalSeparationFlag,
                "premature_placental_separation_detail": states.prematurePlacentalSeparationDetails,
                "diabetes": states.diabetesFlag,
                "diabetes_detail": states.diabetesDetails,
                "preeclampsia_or_toxaemia": states.preeclampsiaToxaemiaFlag,
                "preeclampsia_or_toxaemia_detail": states.preeclampsiaToxaemiaDetails,
                "psychiatric_disorder": states.psychiatricDisorderFlag,
                "psychiatric_disorder_detail": states.psychiatricDisorderDetails,
                "other_prenatal_conditions": states.otherPrenatalConditionsFlag,
                "other_prenatal_conditions_detail": states.otherPrenatalConditionsDetails

            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PRENATAL_FACTORSH;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePerinatalFactorsSuccess,
                this.savePerinatalFactorsFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Prenatal Factors already added please click on edit icon to update the values")
        }
    }

    savePerinatalFactorsSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePerinatalFactorsFailure = error => { }


    validateInfectionTypes(key) {
        var data;
        if (key == "buttonKey") {
            if (this.state.selectedInfection.value == 1) {
                data = "selectedTrimester1"
            } else if (this.state.selectedInfection.value == 2) {
                data = "selectedTrimester2"
            } else if (this.state.selectedInfection.value == 3) {
                data = "selectedTrimester3"
            }
        } else if (key == "buttonValue") {
            if (this.state.selectedInfection.value == 1) {
                data = this.state.selectedTrimester1
            } else if (this.state.selectedInfection.value == 2) {
                data = this.state.selectedTrimester2
            } else if (this.state.selectedInfection.value == 3) {
                data = this.state.selectedTrimester3
            }
        }

        return data
    }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Prenatal Factors"} clearAllData={this.clearAllData.bind(this)} />









                {this.renderHistoryComponent(
                    "Nutritional Deficiency", "Enter details",
                    "nutritionalDeficiencyFlag", this.state.nutritionalDeficiencyFlag,
                    "nutritionalDeficiencyDetails", this.state.nutritionalDeficiencyDetails
                )}

                {this.renderHistoryComponent(
                    "Exposure of Medication", "Enter details",
                    "exposureofMedicationFlag", this.state.exposureofMedicationFlag,
                    "exposureofMedicationDetails", this.state.exposureofMedicationDetails
                )}

                {this.renderHistoryComponent(
                    "Bleeding during pregnancy", "Enter details",
                    "bleedingDuringPregnancyFlag", this.state.bleedingDuringPregnancyFlag,
                    "bleedingDuringPregnancyDetails", this.state.bleedingDuringPregnancyDetails
                )}

                {this.renderHistoryComponent(
                    "Infection", "",
                    "selectInfectionButtonFlag", this.state.selectInfectionButtonFlag,
                    "", "",
                    "selectedInfection", this.state.selectedInfection, infectionList

                )}

                {

                    this.state.selectedInfection && this.state.selectedInfection.value ?
                        this.renderHistoryComponent(
                            this.state.selectedInfection.label, "",
                            "", true,
                            "", "",
                            this.validateInfectionTypes("buttonKey"), this.validateInfectionTypes("buttonValue"), this.state.getListofTrimesterInfections, true,
                            "trimesterNewbuttonFlag", this.state.trimesterNewbuttonFlag
                        ) : null

                }

                {this.renderHistoryComponent(
                    "Abortion", "Enter details",
                    "abortionFlag", this.state.abortionFlag,
                    "abortionDetails", this.state.abortionDetails
                )}


                {this.renderHistoryComponent(
                    "Rh Incompatability", "Enter details",
                    "rhIncompatabilityFlag", this.state.rhIncompatabilityFlag,
                    "rhIncompatabilityDetails", this.state.rhIncompatabilityDetails
                )}

                {this.renderHistoryComponent(
                    "Decreased fetal movements", "Enter details",
                    "decreasedFetalMovementsFlag", this.state.decreasedFetalMovementsFlag,
                    "decreasedFetalMovementsDetails", this.state.decreasedFetalMovementsDetails
                )}

                {this.renderHistoryComponent(
                    "Hypothyroidism", "Enter details",
                    "hypothyroidismFlag", this.state.hypothyroidismFlag,
                    "hypothyroidismDetails", this.state.hypothyroidismDetails
                )}


                {this.renderHistoryComponent(
                    "Premature Placental separation", "Enter details",
                    "prematurePlacentalSeparationFlag", this.state.prematurePlacentalSeparationFlag,
                    "prematurePlacentalSeparationDetails", this.state.prematurePlacentalSeparationDetails
                )}

                {this.renderHistoryComponent(
                    "Diabetes", "Enter details",
                    "diabetesFlag", this.state.diabetesFlag,
                    "diabetesDetails", this.state.diabetesDetails
                )}

                {this.renderHistoryComponent(
                    "Preeclampsia/Toxaemia", "Enter details",
                    "preeclampsiaToxaemiaFlag", this.state.preeclampsiaToxaemiaFlag,
                    "preeclampsiaToxaemiaDetails", this.state.preeclampsiaToxaemiaDetails
                )}

                {this.renderHistoryComponent(
                    "Psychiatric disorder", "Enter details",
                    "psychiatricDisorderFlag", this.state.psychiatricDisorderFlag,
                    "psychiatricDisorderDetails", this.state.psychiatricDisorderDetails
                )}

                {this.renderHistoryComponent(
                    "Other Prenatal Conditions", "Enter details",
                    "otherPrenatalConditionsFlag", this.state.otherPrenatalConditionsFlag,
                    "otherPrenatalConditionsDetails", this.state.otherPrenatalConditionsDetails
                )}



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
