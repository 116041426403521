import React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";

import BaseComponent, {CommonHistorySectionHeader,CommonSectionHeader} from '../BaseComponent'
import { color } from "../../../styles/Color";
// import Styles  from "../../../styles/Style";
import Style from "../../../styles/Style";
import { Constants } from "../../../utils/Constants";
import AsyncStorage from "../../../AsyncStorage";



const pageTitle = "General Patient Advice";
const pageName = "GeneralPatientAdvice";
const isPageName = "isGeneralPatientAdvice";

 class GeneralPatientAdvice extends BaseComponentStyle {
  constructor(props) {
    super(props)

    this.state={
      userType: "",
      advice:'',

      getGPAdviceData:[],

      isGeneralPatientAdvice: isPageName === this.props.selectedView ? true : false,
      patientAppointment: this.props.patientAppointment,
      isLocked:this.props.patientAppointment.is_locked,

    }
  }

  async componentDidMount() {

    this.getAssessmentDetails();

    const userType = await AsyncStorage.getItem("service_type");

    if(userType) {
      this.setState({ userType: userType });
    }

    //this.getPsychiatryDetails();  //---------- text box
    //this.getPsychiatryMotivation("value");
  }

 
  componentWillReceiveProps(props) {

    this.setState({ 
      
      advice:props.getGPAdviceData.advice_given,
    }
      
    )
    let updateFontColor = isPageName === props.selectedView ? true : false;
    if (updateFontColor !== this.props.isGeneralPatientAdvice) {

      this.setState({ isGeneralPatientAdvice: updateFontColor });
    }
  }
  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
  }


  saveAssessment(){
     var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      // "icd_codes": ["A0102"],
      "advice_given": this.state.advice,
      "appointment_id": this.state.patientAppointment.appointment_id
   }

   var service_url = Constants.ASSESSMENT_POST
   OpthamologyService.getInstance().postComplaints(
     service_url,
     data,
     this,
     this.saveAssessmentDetailsSuccess,
     this.saveAssessmentDetailsError,
   )}
 
   saveAssessmentDetailsSuccess = success =>{
     if(success.status == "success"){
      this.getAssessmentDetails();
       this.props.showResposeValue("success",success.message)
     }else{
       this.props.showResposeValue("error",success.message)
     }
   }
   saveAssessmentDetailsError = error =>{
     this.props.showResposeValue("error",error.message)
   }


   getAssessmentDetails = () => {
    
    var service_url = Constants.ASSESSMENT_GET + "?patient_id=" + this.state.patientAppointment.patient_id + '&appointment_id=' + this.state.patientAppointment.appointment_id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAssessmentDetailsSuccess,
      this.getAssessmentDetailsFailure
    );
  };

  getAssessmentDetailsSuccess = response => {
    if (response.status === "success") {
      this.setState({ 
        advice:response.data.advice_given,
      });  
    }
  };

  getAssessmentDetailsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };
 
  render() {
    return(

    <View>
      <View style={{flexDirection:"row",justifyContent:'space-between',alignItems:'center',alignContent:'center'}}>
      {this.renderTitleBorder(
          this.state.isGeneralPatientAdvice,  
          pageTitle,
          isPageName,
          this.triggerNewItem
          )}
          
            { this.props.getGPAdviceData.length>0 || this.state.advice ?
            <View>
              <TouchableOpacity 
              disabled={this.state.isLocked} 
              onPress={() => {this.saveAssessment()}} 
              style={[{backgroundColor: color.themeDark}, Style.allButtonBorderRadius]}>
                <Text 
                style={[styles.SaveBtnTouch, { color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Save"}
                </Text>
              </TouchableOpacity>
            </View>
             :null}
      </View>
      <View style={styles.CommonHeaderView}>
        <CommonSectionHeader heading={"Advice (General Patient Instruction)"} />
        <TextInput
              editable={this.state.isLocked}
              // multiline
              multiline={true}
              placeholder=""
              style={[{backgroundColor: this.state.isLocked ? color.disableComponentColor : null}, styles.SectionHeaderTextInput]}
              value={this.state.advice}
            />
      </View>
    </View>)
  }
}
export default GeneralPatientAdvice;

const styles = StyleSheet.create({
      SaveBtnTouch: {
        marginLeft: '1.2vw', 
        paddingTop: '0.5vw', 
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw', 
        paddingBottom: '0.5vw', 
      },
      CommonHeaderView:{ 
        marginTop: "1vw", 
        zIndex: -6, 
        marginBottom: '0.6vw' 
      },
      SectionHeaderTextInput: {
        paddingTop:'0.6vw',
        paddingLeft:'0.6vw',
        height: '5.5vw', 
        borderColor: '#D9D9D9', 
        borderWidth: 1, 
        borderRadius: 5,
        fontSize: '0.9vw'
      }
})
