import React from "react";
import { View, Text, StyleSheet, Platform, Image, Picker, TouchableOpacity } from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent'
const platform = Platform.OS;
const pageTitle = "Test";
const pageName = "Test";
const isPageName = "isTest";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      laboratory_tests: [],
      radiology_tests: [],
      special_tests: [],
      isTest: isPageName === this.props.selectedView ? true : false,
      testCategory: "",
      selectLaboratory: "",
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      laboratoryList: [],
      //Lab revamp 03/03/2023
      groupTestData: [],
      getLaboratoryDetails: []
    };
  }

  componentDidMount() {

    this.getTest();
    // this.getLaboratoryList();
    this.getLaboratoryData()
  }
  getLaboratoryData = () => {
    var clinicID = this.state.selectedClinicOrDoctor
    var serviceUrl = Constants.LAB_DETAILS_GET + "?clinic_id=" + clinicID.id
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getLaboratoryDataSuccess,
      this.getLaboratoryDataFailure
    )
  }
  getLaboratoryDataSuccess = (response) => {
    if (response.status == "success") {
      var Data = response.data
      this.setState({
        getLaboratoryDetails: Data.laboratory_data

      })
    }
  }
  getLaboratoryDataFailure = (error) => {
    this.props.showResposeValue('error', error.message);
  }
  transmitionData() {
    var states = this.state
    var id = [];
    for (let i = 0; i < states.groupTestData.length; i++) {
      id.push(states.groupTestData[i].id)
    }
    let data = {
      "line_item_ids": id,
      "patient_id": states.patientAppointment.patient_id,
      "laboratory_id": +states.selectLaboratory,
      "appointment_id": states.patientAppointment.appointment_id

    };
    //alert(JSON.stringify(id))
    var service_url = Constants.LABORATORY_TRANSMIT;


    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.transmitionDataSuccess,
      this.transmitionDataFailure
    );


  }

  transmitionDataSuccess = success => {
    if (success.status == "success") {
      this.props.showResposeValue('success', success.message);
      // this.clearAllData();
      // this.props.changeScreen("iPPatientList")
      // this.props.refreshRighSideComponent(pageName);
    } else {
      this.props.showResposeValue('error', success.message);

    }

  }
  transmitionDataFailure = error => {
    this.props.showResposeValue('error', error.message);
  }

  renderSymptomsItem = (item, index, name) => {
    return (
      <View key={index} style={styles.typesOfReportView}>
        <View style={{ flex: 0.34, marginLeft: '1vw' }}>
          <Text style={Style.contentTableDataLead}>{index + 1}</Text>
        </View>
        <View style={{ flex: 0.33, marginLeft: '2vw' }}>
          <Text style={Style.contentTableDataLead}>{name}</Text>
        </View>

        <View style={{ flex: 0.33, }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
            {this.renderIdButton("trash")}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  // renderSymptomsItem2 = (item, index) => {
  //   return (
  //     <View>
  //       <View style={{ paddingTop: 0 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //             }}
  //           >
  //             <Text style={Style.contentTableDataLead}>
  //               {item.radiology_test}
  //             </Text>
  //           </View>

  //           <View
  //             style={{
  //               width: "10%",
  //               alignItems: "center",
  //               height: 60,
  //               flexDirection: "row",
  //             }}
  //           >
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 paddingTop: 5,
  //                 paddingBottom: 5,
  //                 backgroundColor: color.white,
  //                 height: 50,
  //               }}
  //             >
  //               <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
  //                 {this.renderIdButton("trash")}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //       <View
  //         style={{
  //           borderBottomWidth: 1,
  //           borderBottomColor: "#E0E0E0",
  //           width: 260,
  //         }}
  //       />
  //     </View>
  //   );
  // };

  // renderSymptomsItem3 = (item, index) => {
  //   return (
  //     <View>
  //       <View style={{ paddingTop: 0 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //             }}
  //           >
  //             <Text style={Style.contentTableDataLead}>
  //               {item.special_test}
  //             </Text>
  //           </View>

  //           <View
  //             style={{
  //               width: "10%",
  //               alignItems: "center",
  //               height: 60,
  //               flexDirection: "row",
  //             }}
  //           >
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 paddingTop: 5,
  //                 paddingBottom: 5,
  //                 backgroundColor: color.white,
  //                 height: 50,
  //               }}
  //             >
  //               <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
  //                 {this.renderIdButton("trash")}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //       <View
  //         style={{
  //           borderBottomWidth: 1,
  //           borderBottomColor: "#E0E0E0",
  //           width: 240,
  //         }}
  //       />
  //     </View>
  //   );
  // };

  // renderTestIconBtn = (text, icon, createBtn, flag, borderColor) => {
  //   return (
  //     <View
  //       style={{
  //         height: 35,
  //         width: text === "New" ? 100 : "100%",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: createBtn || text === "New" ? 20 : 20,
  //         flexDirection: "row",
  //         backgroundColor: createBtn
  //           ? color.themeBlue
  //           : text === "New"
  //           ? color.white
  //           : color.white,
  //         justifyContent: "space-evenly",
  //         borderWidth: createBtn || text === "New" ? 1 : 1,
  //         borderColor:
  //           borderColor == "lab"
  //             ? "#36BA31"
  //             : borderColor == "radiology"
  //             ? "#0280FF"
  //             : borderColor == "spl"
  //             ? "#E5BF1F"
  //             : null,
  //       }}
  //     >
  //       <Text
  //         style={{
  //           fontSize: platform === "web" ? 12 : 14,
  //           color: text === "New" ? "#848484" : "#848484",
  //           fontWeight: "500",
  //         }}
  //       >
  //         {text}
  //       </Text>
  //       <Icon
  //         name={icon}
  //         size={platform === "web" ? 15 : 20}
  //         color={text === "New" ? "#848484" : "#848484"}
  //       />
  //     </View>
  //   );
  // };

  componentWillReceiveProps(props) {
    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getTest();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isTest) {
      this.setState({ isTest: updateFontColor });
    }
  }

  /*Comment for lab revamp- 06/02/2023 Start */
  // getLaboratoryList = () => {
  //   var service_url =
  //     Constants.LABORATORY_LIST + "?clinic_id=" + this.state.selectedClinicOrDoctor.id +"&test_category=" +this.state.testCategory

  //   OpthamologyService.getInstance().getComplaints(
  //     service_url,
  //     this,
  //     this.getLaboratoryListSuccess,
  //     this.getLaboratoryListFailure
  //   );
  // };

  // getLaboratoryListSuccess = (response) => {
  //   if (response.status === "success") {
  //     this.setState({
  //       laboratoryList:response.data
  //     })

  //   }
  // };

  // getLaboratoryListFailure = (error) => {
  //   // console.log("opthamology complaints GET error response");
  //   console.log(error);
  // };
  /*Comment for lab revamp- 06/02/2023 End */

  getTest = () => {
    var service_url =
      Constants.LAB_TEST_GET_DATA +
      this.state.patientAppointment.patient_id + "/" +
      "?appointment_id=" +
      this.state.patientAppointment.appointment_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTestSuccess,
      this.getTestFailure
    );
  };

  getTestSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        groupTestData: response.data
      }, () => {
        var data = []
        var { groupTestData } = this.state
        for (let i = 0; i < groupTestData.length; i++) {
          if (groupTestData[i].lab_set_ids) {
            data.push({ "test_name": groupTestData[i].lab_set_names, "id": groupTestData[i].id })
            this.setState({
              groupTestData: data
            })
          }
          if (groupTestData[i].lab_test_ids) {
            data.push({ "test_name": groupTestData[i].lab_test_names, "id": groupTestData[i].id })
            this.setState({
              groupTestData: data
            })
          }
          if (groupTestData[i].special_test_id) {
            data.push({ "test_name": groupTestData[i].special_test_name, "id": groupTestData[i].id })
            this.setState({
              groupTestData: data
            })
          }
        }

      });
    }
  };

  getTestFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  deleteItem = (item, event) => {
    // alert(JSON.stringify(item))
    //this.props.editItem(pageName, item);
    var service_url = Constants.TEST_DELETE;
    let data = {
      appointment_id: this.state.patientAppointment.appointment_id,
      tests_id: item.id,
    };
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  getDeleteSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      this.getTest();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  getSymptomsFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  // renderTestItem = (item, index, borderColor) => {
  //   return (
  //     <View style={{ marginRight: 10 }}>
  //       <View style={{ paddingTop: 0 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //             }}
  //           >
  //             <TouchableOpacity>
  //               {this.renderTestIconBtn(
  //                 item.radiology_test
  //                   ? item.radiology_test
  //                   : item.special_test
  //                   ? item.special_test
  //                   : item.lab_test
  //                   ? item.lab_test
  //                   : null,
  //                 "close",
  //                 false,
  //                 "",
  //                 borderColor
  //               )}
  //             </TouchableOpacity>
  //           </View>
  //         </View>
  //       </View>
  //     </View>
  //   );
  // };

  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
  };
  // renderTransmit(imgpath,text){
  //   return(
  //     <View style={{paddingHorizontal:10,paddingVertical:10,flex:1,alignContent:"center",alignItems:"center",justifyContent:"center"}}>
  //         <TouchableOpacity
  //             onPress={() => {
  //              this.onPressButton() 

  //             }} style={{backgroundColor:color.themeBackgroud,borderRadius:4}}

  //           >
  //             <Image source={imgpath}style={{height:20,width:20}}/>
  //             <Text>{text}</Text>

  //           </TouchableOpacity>




  //     </View>
  //   )
  // // }
  // onPressButton() {

  // }
  // onPrint() {

  // }
  onPressPrint() {
    let states = this.state;
    var serviceUrl;
    serviceUrl = Constants.LABORATORY_TEST_PRINT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.printTestSuccess,
      this.printTestFailure,
      "pdf"
    );
  };

  printTestSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      // console.log(file)
      window.open(fileURL);
    }
  };

  printTestFailure = error => {
    // console.log("Get clinic list error response");
    console.log(error);
  };



  render() {
    var states = this.state
    return (

      <View>

        <View
          style={{
            marginLeft: '0.3vw',
            width: platform === "web" ? "100%" : "75%",
            flexDirection: "row",
            //justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "30%",
            }}
          >
            {this.renderTitleBorder(
              this.state.isTest,
              pageTitle,
              isPageName,
              this.triggerNewItem
            )}
          </View>
        </View>



        <View style={{ marginTop: '0.6vw' }}>
          <CommonHistorySectionHeader
            heading1={"S.No"}
            heading2={"Service Description"}
            heading3={"Action"}
            columnSize={[0.34, 0.33, 0.33]}  //total value is == of 1
            noOfColumn={3}
          />
        </View>

        {/* <View
          style={{
            marginTop: 20,
            // marginLeft: 5,
            width: platform === "web" ? "100%" : "45%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: "25%",
              
            }}
          >
            <Text
              style={
                (Style.contentTableTitle, { marginLeft: 20, color: "#848484" })
              }
            >
              Laboratory Test
            </Text>
          </View>

          <View
            style={{
              width: "27%",
              marginLeft:20
            }}
          >
            <Text style={Style.contentTableTitle}>Radiology Test</Text>
          </View>

          <View
            style={{
              width: "27%",
              marginRight: 10,
            }}
          >
            <Text
              style={
                (Style.contentTableTitle,
                {  color: "#848484" })
              }
            >
              Special Test
            </Text>
          </View>
        </View> */}


        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }} >
            {states.groupTestData && states.groupTestData.length > 0 ? states.groupTestData.map((item, index) => {
              return this.renderSymptomsItem(item, index, item.test_name);
            }) : null}
          </View>
          {/*Comment for lab revamp 03/02/2023 -- start */}
          {/* <View style={{ flex:0 }}>
            {this.state.radiology_tests.map((item, index) => {
              return this.renderSymptomsItem(item, index, item.radiology_test);
            })}
          </View> */}
          {/* <View style={{ flex: 0.33 }}>
            {this.state.special_tests.map((item, index) => {
          <View style={{ flex: 0.34 }}>
            {this.state.laboratory_tests && this.state.laboratory_tests.map((item, index) => {
              return this.renderSymptomsItem(item, index, item.lab_test);
            })}
          </View>

          <View style={{ flex: 0.33 }}>
            {this.state.radiology_tests && this.state.radiology_tests.map((item, index) => {
              return this.renderSymptomsItem(item, index, item.radiology_test);
            })}
          </View>
          <View style={{ flex: 0.33 }}>
            {this.state.special_tests && this.state.special_tests.map((item, index) => {
              return this.renderSymptomsItem(item, index, item.special_test);
            })}
          </View> */}
          {/*Comment for lab revamp 03/02/2023 -- Emd */}
        </View>

        {this.state.groupTestData && this.state.groupTestData.length > 0 ?
          <View style={{ alignSelf: "flex-end", flexDirection: "row", alignItems: "center", marginTop: '1vw' }}>
            {/*Comment for lab revamp 03/02/2023 -- start */}
            {/* <Picker
            // enabled={!this.state.isLocked}
            selectedValue={this.state.testCategory}
            style={[styles.PickerView, {backgroundColor:color.white }]}
            itemStyle={{}}
            onValueChange={(itemvalue) => {
              // var states = this.state.mandatoryInformation;
              // states["state"] = itemvalue;
              this.setState({ testCategory: itemvalue },()=>{
                this.getLaboratoryList()
              });
            }}
          > */}
            {/* <Picker label={"Test Category"} value={0} /> */}
            {/* <Picker.Item label="Test Category" value={0} />
            <Picker.Item label="Laboratory Test" value="Laboratory Test" />
            <Picker.Item label="Radiology Test" value="Radiology Test" />
            <Picker.Item label="Special Test" value="Special Test" /> */}
            {/* <Picker label={"Radiology Test"} value={"Laboratory Test"}/>
     
            <Picker label={"Radiology Test"} value={"Radiology Test"}/>
            <Picker label={"Special Test"} value={"Special Test"}/> */}
            {/* </Picker> */}
            {/*Comment for lab revamp 03/02/2023 -- End */}
            <Picker
              // enabled={!this.state.isLocked}
              // selectedValue={this.state.selectedPharmacy['id']}
              style={[styles.PickerView, { backgroundColor: color.white }]}
              itemStyle={{}}
              onValueChange={(itemvalue) => {
                // var states = this.state.mandatoryInformation;
                // states["state"] = itemvalue;
                this.setState({ selectLaboratory: itemvalue });
              }}
            >
              <Picker.Item label={"Select"} value={""} />
              {
                this.state.getLaboratoryDetails && this.state.getLaboratoryDetails.length > 0 && this.state.getLaboratoryDetails.map((item, index) => {
                  return <Picker.Item key={index} label={item.laboratory__laboratory_name} value={item.laboratory_id} />
                })
              }
            </Picker>
            {/* <View style={{paddingHorizontal:10,flexDirection:"row",paddingVertical:10}}> */}
            {/* <View style={{flexDirection:"row",paddingHorizontal:10, alignItems:'center'}}> */}
            <CommonButton
              item={{}}
              selectedvalue={{}}
              butttonText={"Transmit"}
              buttonType={"theme"}
              rightIcon={true}
              buttonAction={this.transmitionData.bind(this)}
              buttonKey={"testCategory"}
            />
            <TouchableOpacity
              onPress={() => {
                this.onPressPrint()

              }} style={[styles.BtnTouch, { backgroundColor: color.themeBackgroud }]}

            >
              <Image source={require("../../../../assets/images/Active/Print.png")} style={styles.BtnImg} />

            </TouchableOpacity>

            {/* </View>  
               
            </View>   */}
          </View> : null
        }
        {/* <View style={{ marginTop: 20, padding: 10 }}>
          <View
            style={{
              // marginLeft: 5,
              width: platform === "web" ? "100%" : "45%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "25%" }}>
              {this.state.laboratory_tests.map((item, index) => {
                return this.renderSymptomsItem(item, index, "lab_test");
              })}
            </View>
            <View style={{ width: "25%" }}>
              {this.state.radiology_tests.map((item, index) => {
                return this.renderSymptomsItem2(item, index, "radiology");
              })}
            </View>
            <View style={{ width: "25%", marginRight: 30 }}>
              {this.state.special_tests.map((item, index) => {
                return this.renderSymptomsItem3(item, index, "special");
              })}
            </View>
          </View>
        </View> */}


      </View>

    );
  }
}
const styles = StyleSheet.create({
  typesOfReportView: {
    borderBottomWidth: 1,
    paddingVertical: '0.4vw',
    borderBottomColor: "#E0E0E0",
    // marginLeft: '2vw',
    // marginRight: '2vw',
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-between'
  },
  PickerView: {
    height: '2vw',
    width: '10.6vw',
    marginRight: '0.6vw',
    borderRadius: 4,
    fontSize: '0.9vw'
  },
  BtnTouch: {
    borderRadius: 4,
    marginLeft: '0.6vw',
    height: '1.6vw',
    width: '1.6vw',
    justifyContent: "center"
  },
  BtnImg: {
    height: '1.2vw',
    width: '1.2vw',
    alignSelf: "center"
  }
});
