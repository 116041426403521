import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color,sectionHeadingLiteColor } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton,CommonSectionHeader } from '../BaseComponent'
import Style from "../../../styles/Style";
import ImagePath from "../../../utils/ImagePaths";


// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "Allergy";

const toastConfig = {
 'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{color:"white",textAlign:"center",justifyContent:"center",marginTop:"9px"}}>Complaint Created Successfully</Text>
    </View>  
  ),
  'error': () => {},
  'info': () => {},
  'any_custom_type': () => {} 
}



export default class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedAllergyName: {},
      allergyType:{},
      allergyActive:{},
      AllergyName: [],
      complaints: "",
      isEdit:false,
      // activeStatus: "true",
      newItem: '',
      allergySuggestionList: [],
      isAllergySuggestionView: false,
      selectedDuration: "",
      comments: "",
      successOrErrorMessage:false,
        successMessageFlag:false,
        errorMessageFlag:false,
        disableButton: false
    };
  }



  
  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getAllergiesFrequent()
  }

  componentWillReceiveProps(props) {

    let { id, AllergyName,selectedAllergyName } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
       // alert(JSON.stringify(item))
        if (id !== item.id && item.id && item) {
          // let selectedAllergyName = "";

          // AllergyName.map((favItem)=>{
          //   if(favItem.label===item.symptom){
          //     selectedAllergyName = favItem.value;
          //   }
          // })

        var allergyType
          if(item.allergy_type == 1){
            allergyType = { value: "1", label: "Medication Allergy" }
          }else{
            allergyType=  { value: "2", label: "Non-Medication Allergy" }
          }
          var ActiveAllergy
          if(item.is_active == true) {
            ActiveAllergy = { value: "Active", label: "Active" }
          }else{
            ActiveAllergy = { value: "Inactive", label: "Inactive" }
          }

          // var selectName 
          // if(item.name){
          //   selectName = { value: item.name, label: item.name }
          // }


          var allergeyNameGet = item && item.name ? item.name : "";
          if(allergeyNameGet){
            var prepareChildren = { value: allergeyNameGet, label: allergeyNameGet }
            AllergyName.push(prepareChildren);
            AllergyName = this.removeDuplicate(AllergyName);
            selectedAllergyName = prepareChildren;
          }
          // for (let i = 0; i < AllergyName.length; i++) {
          //   // const element = array[i];
          //   if (AllergyName[i].value == allergeyNameGet) {
          //     var prepareChildren = { value: allergeyNameGet, label: allergeyNameGet }
          //     selectedAllergyName = prepareChildren;


          //   } else {

          //     var prepareChildren = { value: allergeyNameGet, label: allergeyNameGet }
          //     AllergyName.push(prepareChildren);
          //     selectedAllergyName = prepareChildren;
          //   }

          // }



          

          


          this.setState({
            disableButton: true,
            id: item.id,
            allergyType: allergyType ,
            selectedAllergyName,
            allergyActive: ActiveAllergy,
            AllergyName,
            // selectedDuration:isNaN(parseInt(item.duration))?0:parseInt(item.duration),
            comments: item.comments
          },()=>{
            this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
    if(props.Deletecorporate){
      this.setState({
        ConfirmDelete : props.Deletecorporate
      })
    }else{
      this.setState({
        ConfirmDelete : false
      })
    }
  }


  getAllergiesFrequent = () => {
  
    var service_url = Constants.ALLERGY_FREQUANT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAllergiesFrequentSuccess,
      this. getAllergiesFrequentFailure
    );
  };

  getAllergiesFrequentSuccess = response => {
    if (response.status === "success") {

      let drugs = [];

      response.data.map((item) => {

        (item)?drugs.push({ value: item, label: item }):null;
      });

      var field = this.state;
      field["AllergyName"] = drugs;
      this.setState({ field });

    }
  };

  getAllergiesFrequentFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }


  addMedicationToList = () => {

  

    let { newItem, AllergyName } = this.state;
    let field = this.state;

    if(newItem) {
      var prepareData={ value: newItem, label: newItem }

      AllergyName.push(prepareData);
      
      field["AllergyName"] = AllergyName;
      field["selectedAllergyName"] = prepareData;
      field["newItem"] = "";
      field["isEdit"] = false;
      
      this.setState({ field });
    }
  }
 

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
       
      />
    );
  }

  onPressAction = (key, value,label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states
    })
  }


  addAllergy = event => {

    let states = this.state;
    var service_url = Constants.ALLERGY_POST;

    let allergy_name = "";

    states.AllergyName.map((item)=>{

      if( item.value === states.selectedAllergyName ) {

        allergy_name = item.label;
      }
    });


      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if(dd<10) 
      {
          dd='0'+dd;
      } 

      if(mm<10) 
      {
          mm='0'+mm;
      } 
      today = yyyy+'-'+mm+'-'+dd;

   
    let data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      allergies: {
        "id": states.id ? states.id : null,
        "name": (states.selectedAllergyName.value).trim(),
        "allergy_type": states.allergyType.value,
        "comments": states.comments,
        "is_active": states.allergyActive.value === "Active" ? true : false,
        "diagnosed_date": today,
      }
    };

    if (states.selectedAllergyName) {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess ,
        this.getSymptomFailure
      );
    }
  };

  // showSuccessMessage = () => {
   
  //   this.setState({ successOrErrorMessage: true,successMessageFlag:true });
  //   setTimeout(() => {
  //     this.setState({ successOrErrorMessage: false , successMessageFlag: false });
  //   }, 2000);
  // };

  // showErrorMessage = () => {
  //   this.setState({ successOrErrorMessage: true,errorMessageFlag:true });
  //   setTimeout(() => {
  //     this.setState({ successOrErrorMessage: false , errorMessageFlag: false });
  //   }, 2000);
    
  // };

  getSymptomSuccess = response => {
   
    if (response.status === "success") {
    
      this.props.showResposeValue("success", response.message)


      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);

    }else {
      this.props.showResposeValue("error", response.message);
    }


  };

  getSymptomFailure = error => {
    this.props.showResposeValue("error", response.message)
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = event => {

    let states = this.state;
    states['allergyType'] ={},
    states['allergyActive'] = {},
    states['selectedAllergyName'] = "";
    states['complaints'] = "";
    states['id'] = "";
    // "duration_type": 3,
    states['selectedDuration'] = "";
    states['comments'] = "";
    states['disableButton']=false
    states.allergyActive.value
    this.setState({ states });

  }

  allergySuggestion = (search) => {

    if ((search).trim() !== "") {
      var service_url = Constants.ALLERGY_FREQUANT_GET + "?name=" + search;

      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.allergySuggestionSuccess,
        this.allergySuggestionFailure
      );
    }else{
      var fields = this.state;
      fields["allergySuggestionList"] = [];
      fields["isAllergySuggestionView"] = false;

      this.setState({
        fields
      });
    }
  }

  allergySuggestionSuccess = (response) => {
    if(response.status == "success"){
      var fields = this.state;
      fields["allergySuggestionList"] = response.data;
      fields["isAllergySuggestionView"] = true

      this.setState({
        fields,
      });
    }
  }

  deleteAllergy = () => {
    var serviceUrl = Constants.ALLERGY_DELETE + "?allergy=" + this.state.selectedAllergyName.value;
    var data = {}
    OpthamologyService.getInstance().deleteComplaints(
        serviceUrl,
        data,
        this,
        this.deleteAllergySuccess,
        this.deleteAllergyFailure
    )
}

deleteAllergySuccess = (response) => {
    if (response.status == "success") {
        this.setState({
          selectedAllergyName: {}
        }, () => { this.getAllergiesFrequent(), this.props.ClosePopup() ,this.props.showResposeValue("success", response.message) })
    }
    else {
      this.props.showResposeValue("error", response.message)
    }

}
deleteAllergyFailure = (error) => {
  this.props.showResposeValue("error", error.message)
}

  render() {
    return (
      <TouchableWithoutFeedback>
      <View>
      {this.renderSubHeadingBorder("Allergy",false,true)}
        <View style={styles.TableHeadView1}>
        <CommonSectionHeader heading={"Allergy Type"}  />
        {/* {this.renderTitleWithMultipleBtn("Allergy Type", false, 1, false,'')} */}
        </View>
        {/* <FlatList
          data={Constants.AllergyType}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'allergyType', this.state.allergyType )}
        /> */}

<View style={styles.CommonBtnContainer}>
        {
          Constants.AllergyType.map((item, index) => {
            // var data
            // if(this.state.id){
            //   data = this.state.selectedEye
            // }else{
            //   data = this.state.systamicAllergySelectedItems
            // }
            return (
              
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable= {(this.state.disableButton || this.state.isLocked )? true : false}
                  item={item}
                  selectedvalue={this.state.allergyType}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"allergyType"} />
              </View>
            )
          })
        }
        </View>

        <View>
        <CommonSectionHeader heading={"Allergy Name"}  />
        {/* {this.renderTitleWithMultipleBtn("Allergy's Name", false, 1, false, 'selectedApplanation',)} */}
        </View>


        <View style={styles.CommonBtnContainer}>
        {
          this.state.AllergyName && this.state.AllergyName.map((item, index) => {
            // var data
            // if(this.state.id){
            //   data = this.state.selectedEye
            // }else{
            //   data = this.state.systamicAllergySelectedItems
            // }
            return (
              
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable= {(this.state.disableButton || this.state.isLocked) ? true : false}
                  item={item}
                  selectedvalue={this.state.selectedAllergyName}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={ImagePath.close}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedAllergyName"} 
                  IconAction={this.onPressIcon.bind(this)}/>
              </View>
            )
          })
        }
        </View>
        
        {/* <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
              {
                this.state.AllergyName && this.state.AllergyName.map((item, index) => {
                  return this.renderSelectEyes(item, false, false, 'selectedAllergyName', this.state.selectedAllergyName, index)
                })
              }
        </View> */}


{
  this.state.disableButton ?
  null:
 this.state.isEdit?
     

        <View
        style={[{
          height: '2vw',
          zIndex:1,
          marginTop: '0.6vw',
        },Style.allButtonBorderRadius]}
      >
        <View style={{flexDirection: "row", flex: 1}}>
        <View style={{flex: 0.8}}>
        <TextInput
        underlineColorAndroid="transparent"
        placeholder="type here"
        placeholderTextColor={color.black}
        style={[styles.AddBtnTxetInput, {color: color.black, borderColor: color.black,}]}
        ref={text => (this.nameInput = text)}
        // defaultValue=""
        autoCapitalize="none"
        value={this.state.newItem}
        onChangeText={text => {
          this.allergySuggestion(text)          
          this.setState({ selectedAllergyName:'',newItem: (text).toUpperCase() })}}          
      />
      </View>
      <TouchableOpacity 
        style={{
          flex:0.2,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: color.themeDark,
          borderColor: color.black,
          borderWidth: 1,
          borderRadius: 4,
          marginLeft: '0.5vw'
        }}
        onPress={() => this.addMedicationToList()}
        >
          <Text style={styles.AddBtnText}>{"Save"}</Text>
      </TouchableOpacity>
      </View>
      {(this.state.isAllergySuggestionView && this.state.allergySuggestionList && (this.state.allergySuggestionList).length > 0) ? 
        <View style={[styles.FlatListView, {backgroundColor: color.whiteSmoke, borderColor: color.white}]}>
          <FlatList
        data={this.state.allergySuggestionList}
        renderItem={({item}) => 
        <TouchableOpacity 
        style={{paddingVertical: '0.2vw'}}
        onPress={() =>{
          this.setState({newItem: (item).toUpperCase(), allergySuggestionList: [] },() => {
            this.addMedicationToList()
          })
        } }
        >
          <Text style={styles.AddBtnText}>{item}</Text>
        </TouchableOpacity>
        }
      />
        </View>
        : null
      }
      </View>:
      <View style={styles.TableHeadView1}>{this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this),this.state.isLocked)}
</View>}
        {/* {this.renderIconBtn("plus", "New", true)} */}

        

<View style={styles.TableHeadView1}>
<CommonSectionHeader heading={"Status"}  />
        {/* {this.renderTitleWithMultipleBtn("Status", false, 1, false,'')} */}
        </View>
        {/* <FlatList
          data={Constants.AllergyActive}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'allergyActive', this.state.allergyActive )}
        />
        */}

<View style={styles.CommonBtnContainer}>
        {
          Constants.AllergyActive.map((item, index) => {
            // var data
            // if(this.state.id){
            //   data = this.state.selectedEye
            // }else{
            //   data = this.state.systamicAllergySelectedItems
            // }
            return (
              
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable= {this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.allergyActive}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"allergyActive"} />
              </View>
            )
          })
        }
        </View>
        <View>
        <CommonSectionHeader heading={"Comments (Allow only 50 characters)"}  />
        </View>
        {/* <View style={{backgroundColor:color.sectionHeadingDarkColor,paddingHorizontal:10}}>
        {this.renderTitleWithMultipleBtn("comments", false, 1, false, 'selectedApplanation',)}
        </View> */}
        <View
          style={styles.CommentsView}
        >
          {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4,'',{},'',false,this.state.isLocked,50)}
        </View>

        <View style={{ marginTop: '1.2vw' ,alignSelf:"center" }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addAllergy.bind(this)} >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View >
        <View>
              {this.state.show ? (
                <View style={styles.SymptomCreatedView}>
                    <Text style={styles.SymptomCreatedViewText}>Symptom Created Successfully</Text>
                </View>
              ) :null}
        </View >
        {this.state.ConfirmDelete ? this.deleteAllergy() : null}
        <View>
        {this.state.successOrErrorMessage ? (
                <View style={{ height:'2.6vw', width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={styles.SymptomCreatedViewText}>
                    {
                    this.state.successMessageFlag ?'Symptom Created Successfully' : 
                    this.state.errorMessageFlag ?'Symptom History Failed' : null}</Text>
                </View>
              ) :null}
        </View >

      </View>
      </TouchableWithoutFeedback>
    );
  }

  onPressButton(key, selectedvalue) {
    if (key == "allergyType" ) {
      console.log(selectedvalue)
        this.setState({
          allergyType:selectedvalue
        })
    }else if(key == "allergyActive"){
      this.setState({
        allergyActive: selectedvalue
      })
    }else if(key == "selectedAllergyName"){
      this.setState({
        selectedAllergyName : selectedvalue
      })
    }
  }

  onPressIcon(key, selectedvalue) {
    if (key == "selectedAllergyName" ) {
      this.setState({
        selectedAllergyName : selectedvalue
      },() =>{
        this.props.DeletePopup(this.state.selectedAllergyName)
      })
    }
  }
}
const styles = StyleSheet.create({
      TableHeadView1: {
        marginTop:'1vw',
        paddingLeft:"1.3vw"
      },
      CommonBtnContainer: {
        flexDirection:'row',
        flexWrap:'wrap',
        marginTop:'0.6vw',
        marginLeft:'1.2vw'
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      AddBtnTxetInput: {
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        borderWidth: 1,
        borderRadius: 4,
        fontSize: '1vw'
      },
      AddBtnText: {
        fontSize:'1vw'
      },
      FlatListView: { 
        position:'absolute', 
        width: "80%", 
        marginTop: '2vw', 
        padding: '0.6vw',
        maxHeight:'11.5vw', 
        borderRadius: 4,
        borderWidth: 2
      },
      CommentsView: {
        marginTop: '0.6vw',
        marginHorizontal:'1.2vw'
      },
      SymptomCreatedView: { 
        height:'2.6vw', 
        width: '100%', 
        backgroundColor: 'green',
        alignItems:'center',
        justifyContent:'center' 
      },
      SymptomCreatedViewText: {
        color:"white",
        textAlign:"center",
        justifyContent:"center", 
        fontSize: '1vw'
    }
})