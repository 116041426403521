import { Constants } from "../utils/Constants";
// import { AsyncStorage } from "react-native";
import AsyncStorage from "../AsyncStorage";
import axios from "axios";

export default class OpthamologyService {
  static instance;

  getComplaints(url, baseClass, successCallback, errorCallback,hideDeleteIconFlag,isQMS="") {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      null,
      hideDeleteIconFlag,
      isQMS
    );
  }

  putMethod(url, data, baseClass, successCallback, errorCallback, contentType='application/json',isQMS=""){
    this.callAPI(
      url,
      "PUT",
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      "",
      isQMS
    );
  }
  patchMethod(url, data, baseClass, successCallback, errorCallback, contentType='application/json',isQMS=""){
    this.callAPI(
      url,
      "PATCH",
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      "",
      isQMS
    );
  }

  postComplaints(url, data, baseClass, successCallback, errorCallback, contentType='application/json',isQMS="",qmsSlugID="") {
    this.callAPI(
      url,
      "POST",
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      qmsSlugID,
      isQMS
    );
  }

  deleteComplaints(url, data, baseClass, successCallback, errorCallback, contentType='application/json',isQMS="",qmsSlugID="") {
    this.callAPI(
      url,
      "DELETE",
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      qmsSlugID,
      isQMS
    );
  }
  log(response) {
    if (response){}
    // console.log(JSON.stringify(response));
  }

  static getInstance() {
    if (this.instance == undefined) {
      this.instance = new OpthamologyService();
    }
    return this.instance;
  }


  documentUploadGet(url,  successCallback, errorCallback,type="") {
    this.documentUploadGetAPI(
      url,
      successCallback,
      errorCallback,
      type
    );
  }

  
  documentUploadPostAPI = async (
    url,
    data,
    successCallback,
    errorCallback,
    baseClass,
    contentType='application/json',
    docType
  )=>{
    axios
      .post(
        url,
        data,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": contentType,
            Authorization:
              "Token " + (await AsyncStorage.getItem("token")).toString(),
          }
        }
      )
      .then((response) =>{ successCallback(response, docType);
        return response;
      })
      .catch((error)=> {
         if(error.response){
          errorCallback(error.response);
        }
        return error;
      });
  }


  documentUploadPutAPI = async (
    url,
    data,
    successCallback,
    errorCallback,
    baseClass,
    contentType='application/json',
    docType
  )=>{

    axios
    .put(
      url, 
      data, 
        {headers: {
        "Content-Type":contentType,
        Authorization:
        "Token " + (await AsyncStorage.getItem("token")).toString(),}}
    )
    .then(r =>{ console.log(r.status)
      successCallback(r, docType)
    })
    .catch(e => {
      console.log(e)
      if(e.response){
        errorCallback(e.response);
      }
    });


  }


  documentUploadPut(url,data, baseClass,successCallback, errorCallback, 
    contentType='application/json', docType='') {
    this.documentUploadPutAPI(
      url,
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      docType
    );
  }



  documentUploadPost(url,data, baseClass,successCallback, errorCallback, 
    contentType='application/json', docType='') {
    this.documentUploadPostAPI(
      url,
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      docType
    );
  }

  documentUploadGetAPI = async (
    url,
    successCallback,
    errorCallback,
    type
  )=>{
    axios.get(
        url,
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": type == "pdf" ? "application/pdf"  : type == "xlsx" ? "application/xlsx" : type == "csv" ? "text/csv" : type == "excel" ? "application/x-www-form-urlencoded; charset=UTF-8" : "application/json",
            Authorization: "Token " + (await AsyncStorage.getItem("token")).toString(),
          },
          responseType: 'blob', //important
        }
      )
      .then((response) =>{
        successCallback(response);
        // return response;
      })
      .catch((error)=> {
        errorCallback(error.response);
        // return error;
      });
  }

  //New API Call method for Optical CRM Starts here
  postRecord(url, data, baseClass, successCallback, errorCallback, contentType='application/json',isQMS="",qmsSlugID="") {
    this.makeAPIAction(
      url,
      "POST",
      data,
      successCallback,
      errorCallback,
      baseClass,
      contentType,
      qmsSlugID,
      isQMS
    );
  }

  makeAPIAction = async (
    url,
    methodType,
    data,
    successCallback,
    errorCallback,
    baseClass,
    contentType='application/json',
    hideDeleteIconFlag,
    isQMS=""
  ) => {

    var token = await AsyncStorage.getItem("OPTICAL_CRM_API_TOKEN");


    if(token){ token.toString()}else {token=""}
    if(contentType == 'application/json')
      data = JSON.stringify(data)
    fetch(url, {
      method: methodType,
      headers: 
          {"Content-Type": contentType ? contentType : 'application/json' ,
          Token: token,
            // "Token " + token,
            // 'x-client': {'device_info': 'ipad', 'user_role': userType}
          },
      body: data
    })
      .then(response => {
        //console.log(response);
        return response.json();
      })
      .then(responseData => {
        successCallback(responseData,hideDeleteIconFlag)
        this.log("success response returned");
        return responseData;
      })
      .catch(error => {
        console.log(error);
        // errorCallback(error);
        this.log({ "Service Error": "" + error });
      });
  };

//New API Call method for Optical CRM Ends here


  callAPI = async (
    url,
    methodType,
    data,
    successCallback,
    errorCallback,
    baseClass,
    contentType='application/json',
    hideDeleteIconFlag,
    isQMS=""
  ) => {

    var token = await AsyncStorage.getItem("token")

    if(token){ token.toString()}else {token=""}
    // baseClass._showProgress();
    // this.log(
    //   "URL : " + url + " \nMethod : " + methodType + " \nData : " + data
    // );

    // let userType = (await AsyncStorage.getItem("service_type")).toString();

    if(contentType == 'application/json')
      data = JSON.stringify(data)
    fetch(url, {
      method: methodType,
      headers: 
          {"Content-Type": contentType ? contentType : 'application/json' ,
          Authorization:isQMS ? "" :"Token " + token,
            // "Token " + token,
            // 'x-client': {'device_info': 'ipad', 'user_role': userType}
          },
      body: data
    })
      .then(response => {
        //console.log(response);
        return response.json();
        // if (response.ok) {
        //   return response.json();
        // } else {
        //   throw new Error(
        //     `Unable to retrieve events.\nInvalid response received - (${response.status}).`
        //   );
        // }
      })
      .then(responseData => {
        // this.log(responseData);
        // baseClass._hideProgress();
    
        successCallback(responseData,hideDeleteIconFlag)
        // errorCallback(responseData);  need to check create patient   
      

        this.log("success response returned");
        return responseData;
      })
      .catch(error => {
        console.log(error);
        // errorCallback(error);
        this.log({ "Service Error": "" + error });
        // if (error)
        //   baseClass._showAlert(
        //     "" + error + ", Please try again after sometime.",
        //     "Ok"
        //   );
        // else
        //   baseClass._showAlert(
        //     "Something went wrong, Please try again after sometime."
        //   );
      });
  };
}
