import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import AsyncStorage from "../../../AsyncStorage";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";

const platform = Platform.OS;
const pageTitle = "Previous Glass Power";
const pageName = "previousglasspower";
const isPageName = "isPreviousGlassPower";

export default class PreviousGlassPower extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      itemList: [],
      isComplaint: isPageName === this.props.selectedView?true:false,
      comments:"",

      getAvailableEyeTypes:[],
      getAvailableEyeTypesId:[],

      date: "",
      frame_type: "",

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null
    };
  }

  async componentDidMount() {
    
    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    },()=>{
      setTimeout(() => {
        this.getItemList() 
      }, 2000);
     
    })
  }


  getCommonComments = () => {
    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=" + "Auto Refractometry";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCommentSuccess,
      this.getComentsFailure
    );
  };

  getCommentSuccess = response => {
    if (response.status === "success") {
      if (response.data) {
        let data = JSON.stringify(response.data);
        // alert(data)
        this.setState({comments:data });

      }

    }
  };

  getComentsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }

    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getItemList();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }    
  }

  getItemList = () => {

    let { patientAppointment, userType, selectedDoctorId } = this.state;
    let service_url =Constants.PREVIOUS_GLASS_POWER+ "?appointment_id=" + patientAppointment.appointment_id;
    
    if( userType == Constants.ROLE_OPTOMETRY ) {

      service_url = service_url + "&doctor_id=" + selectedDoctorId
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }, 2000);
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {

      let { date, comments, frame_type } = this.state;
      var field = this.state;
      let data = []
      var availableEye=[]
      var availableEyeId=[]
      
      data = response.data.previous_glass_power?response.data.previous_glass_power:[]
      
      let temp_date = "";
      let temp_comment = "";
      let temp_frame_type = "";

      // if (data.length > 0) {
      //   let validData = data;

      //   if(data.length == 1){

      //     temp_date = validData[0].date
      //     temp_comment = validData[0].comment
      //     temp_frame_type = validData[0].frame_type

      //   }else{

      //     temp_date = validData[0].date ? validData[0].date : validData[1].date
      //     temp_comment = validData[0].comment ? validData[0].comment : validData[1].comment
      //     temp_frame_type = validData[0].frame_type ? validData[0].frame_type : validData[1].frame_type

      //   }
      // }






      for(var i=0; i<data.length; i++){
        
        availableEye.push(data[i].eye_type);
        
        var prepareData={
          "eye_type":data[i].eye_type,
          "id":data[i].id
        }
        
        availableEyeId.push(prepareData);

        if(i==0) {
          temp_date = data[i].date
          temp_comment = data[i].comment
          temp_frame_type = data[i].frame_type
        } else {

          data[i].date = temp_date
          data[i].comment = temp_comment
          data[i].frame_type = temp_frame_type
        }
        /*
        if(data[i].date) {
          temp_date = data[i].date;
        }

        if(data[i].comment) {
          temp_comment = data[i].comment;
        }

        if(data[i].frame_type) {
          temp_frame_type = data[i].frame_type;
        }
        */
      }

      // field["itemList"] = response.data.previous_glass_power;
      field["itemList"] = data;

      this.setState({ 
        itemList:data,
        getAvailableEyeTypes:availableEye,
        getAvailableEyeTypesId:availableEyeId, 
        date: temp_date,
        comments: temp_comment,
        frame_type: temp_frame_type
      },()=>{
        this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)
      });
    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editComplaints = (item, eyeType) => {

    let { itemList } = this.state

    let tempItemList = itemList.map((item)=>{
      item['selected'] = item.eye_type === eyeType?true:false;
      return item;
    })
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, tempItemList);
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)
    // this.props.editItem(pageName, tempItemList,"",this.state.comments);
  }
  
  renderItem = (item, index) => {
    return (
      <View key={index}>
        <View style={{ paddingTop: '0.6vw' }}>
          <View style={{marginRight:'0.6vw'}}>
            <View style={{padding:'0.3vw',flexDirection:'row',alignItems:'center'}}>
              <Text style={ Style.contentSectionSubTitle }>
                { item.eye_type === "RE" ? "Right Eye (RE)" :
                  item.eye_type === "LE" ? "Left Eye (LE)" :
                  item.eye_type === "BE" ? "Both Eye (BE)" :
                  item.eye_type === "NA" ? "NA (NA)" :
                  null
                }
              </Text>
              <TouchableOpacity   disabled={this.state.isLocked} onPress={this.editComplaints.bind(this,item,item.eye_type)} style={{marginLeft:'0.6vw'}}>
                {this.renderIdButton("pencil")}
              </TouchableOpacity>
            </View>
            <View style={{flexDirection:'row'}}>

              {/* {this.renderColumn("Type", "1")} */}
              {/* {this.renderColumn("Aided NV", item.Aided_NV || "-")}
              {this.renderColumn("Aided DV", item.Aided_DV || "-")}
              {this.renderColumn("UnAided NV", item.UnAided_NV || "-")}
              {this.renderColumn("UnAided DV", item.UnAided_DV || "-")} */}
              {/* <View style={{ flex: 0.13 }}> */}
                {this.renderColumn("SPH", item.SPH || "-")}
              {/* </View>
              <View style={{ flex: 0.13 }}> */}
                {this.renderColumn("CYL", item.CYL || "-")}
              {/* </View>
              <View style={{ flex: 0.13 }}> */}
                {this.renderColumn("AXIS", item.AXIS || "-")}
              {/* </View>
              <View style={{ flex: 0.13 }}> */}
                {/* {this.renderColumn("Vision", item.VISION || "-")} */}
                {this.renderColumn("BCDVA", item.BCDVA || "-")}
                {this.renderColumn("ADD", item.add|| "-")}
              {/* </View>
              <View style={{ flex: 0.13 }}> */}
                {this.renderColumn("BCNVA", item.BCNVA || "-")}
              {/* </View> */}
              
            </View>
          </View>
        </View>
        {/* <View style={{ height: 0.5, backgroundColor: "#E0E0E0" ,width:'100%'}} /> */}
      </View>
    );
  };

  renderColumn = (label, value) => {
    return(
      <View style={ Style.contentTableDataCell }>
        <Text style={ Style.contentTableTitle }>{label}</Text>
        <Text style={ Style.contentTableData }>{value}</Text>
      </View>
    )
  }

  triggerNewItem= (key) => {
    this.props.triggerNewItem(key);
    this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes,this.state.getAvailableEyeTypesId)


  }

  render() {

    let { itemList, date, frame_type, comments } = this.state;
    let itemCount = itemList?itemList.length:0;
    let dateFormatted = ""
    
    if(!itemCount){ itemCount = 0; }
    if(date){ dateFormatted = moment(date).format("DD-MM-YYYY")  }

    return (
        <View>
          <View style={{flexDirection:'row',justifyContent:"space-between",alignItems:'center'}}>
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
        {
          this.state.itemList.length > 0 ?
            this.renderPreviousGlassPowerPrint()
          : null
        }
        </View>
        <View style={{flexDirection:'row',justifyContent:'space-between'}}>

        {
          this.state.itemList.length > 0 ?
          this.state.itemList.map((item, index )=>{
            return this.renderItem(item, index)
          })
          : null
        }

        {/* <FlatList
          data={this.state.itemList}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderItem(item, index)
          }
          // numColumns={2}
        /> */}
        </View>

        <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center",flexWrap:"wrap" }}>
          <View style={{flex:1.2}}>
          {this.renderColumn("Date", dateFormatted || "-")} 
          </View>
          <View style={{flex:1.5}}>
          {this.renderColumn("Frame Type", frame_type || "-")} 
          </View>
          <View style={{flex:6}}>
          {this.renderColumn("Comments", comments?comments:"-")} 
          </View>
        </View>
        
        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {itemCount === 0 ? this.renderIconBtn("plus", "New", false, isPageName) : null}
          </TouchableOpacity>
        </View> */}
        
      </View>
    );
  }

  renderPreviousGlassPowerPrint(){
    return (
      <TouchableOpacity
        style={{
          // marginHorizontal:10,
          alignItems: "center",
          backgroundColor: color.themeDark,
          borderRadius: 4,
        }}
        disabled={this.state.isLocked}
        onPress={() => {
           this.previousGlassPowerPrint()
        }}
      >
        <View
          style={styles.PrintIconView}
        >
          <Icon
            name={"print"}
            size={Platform === "web" ? '1.5vw' : '1.2vw'}
            color={color.white}
          />
        </View>
      </TouchableOpacity>
    );
  }

  previousGlassPowerPrint(){

    var serviceUrl = Constants.PREVIOUS_GLASS_POWER + "?appointment_id=" + this.state.patientAppointment.appointment_id+"&export_type=pdf"

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.previousGlassPowerPrintSuccess,
      this.previousGlassPowerPrintFailure,
      "pdf"
    );
  }


  previousGlassPowerPrintSuccess = response => {
    if (response) {
      
      const file = new Blob([response.data], { type: 'application/pdf' });

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  previousGlassPowerPrintFailure = error => {
    console.log("Get clinic list error response");
    console.log(error);
  };

}

const styles = StyleSheet.create({
      PrintIconView: { 
        height: '2.3vw', 
        width: '2.3vw', 
        alignItems: "center", 
        padding: '0.5vw' 
      }
})