import React, { Component } from "react";
import { View, Text, TextInput, Dimensions, StyleSheet, TouchableOpacity, Image, Picker } from "react-native";
import { color } from "../../../../../styles/Color"
import Style from "../../../../../styles/Style";
import ValCon from './ValidationConstant';
const screenHeight = Dimensions.get("window").height;
import { Formik } from 'formik'
import * as yup from 'yup';
import ImagePath from '../../../../../utils/ImagePaths';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Constants } from "../../../../../utils/Constants";
import OpthamologyService from "../../../../../network/OpthamologyService";

const Vendor_Type = [
    {label: "Consumable", value: 1},
    {label: "Non Consumable", value: 2},
]
export default class VendorDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedScreen: this.props.selectedScreen,
            vendor_id: this.props.vendorId ? this.props.vendorId : null,
            data: {
                "name": "",
                "gstin_number": "",
                "dl_no": "",
                "dl_no_2": "",
                "dl_no_3": "",
                "dl_no_4": "",
                "dl_no_5": "",
                "dl_no_6": "",
                "address_line_1": "",
                "address_line_2": "",
                "area_name": "",
                "pincode": "",
                "city": "",
                "mobile_number": "",
                "phone_number": "",
                "email": "",
                "vendor_id": null,
            },
            vendor_type: 1,
            country: "India",
            state: "Tamil Nadu",
            name: "",
            isSaveDisable: false,
            isTitle: ""
        }
        
    }

    componentDidMount() {
        if (this.state.vendor_id) {
            this._doLoadVendor()
            this.setState({
                isTitle: "Update Vendor"
            })
        } else {
            this.setState({
                isTitle: "Add Vendor"
            })
        }
    }

    _doLoadVendor = () => {

        let { vendor_id } = this.state;
        let url = null;

        if (vendor_id) {
            url = Constants.ADD_VENDOR + "?vendor_id=" + vendor_id;

            OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getVendorDetailsSuccess,
            this.getVendorDetailsFailure
          );
        }
    }

    getVendorDetailsSuccess = response => {

        if (response.status === "success") {

            this.setState({
                data: response.data[0],
                vendor_type :  response.data[0].vendor_type,
                country :  response.data[0].country,
                state :  response.data[0].state
            })

        } else {
            this.setState({
                responseMessage: response.message
            })
        }
    }
    getVendorDetailsFailure = error => {

    }
   
    handleChange = (key) => {
        console.log(key)
    }
    selectCountry = (val) => {
        var states = this.state
        states.country = val
        this.setState({
            states
        })
    }
    selectRegion = (val) => {
        var states = this.state
        states.state = val
        this.setState({ states })
    }
    selectCatogeryType = (val) => {
        var states = this.state
        states.vendor_type = val
        this.setState({
            states
        })
    }
    renderTextBox(placeholder, width, center, type, stateKey, value, error = false, disable = false, handleChange, errors) {

        return (
            <View style={{
                width: width,
                justifyContent: 'center',
                marginRight: '1vw',
                marginTop: '1vw'
            }}>
                <Text style={[styles.TextBoxPlaceholder, { backgroundColor: type == "IDP" ? color.themeShade : "white", marginTop: stateKey === "vendor_type" ? "-4.2vw" : '-2.5vw' }]}>{stateKey === "state" || stateKey === "country" ? "" : placeholder}</Text>
                {
                    disable ?
                        <View>
                            <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: '.7vw', color: color.labelColor, paddingTop: '.4vw', fontSize: '1vw' }]} >
                                {value}
                            </Text>
                            <View style={{ height: '1vw' }}></View>
                        </View>

                        :
                        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                            {stateKey === "state" ?
                                <View style={{ width: "26vw" }}>
                                    <Text style={{ position: 'absolute', marginLeft: "0.5vw", backgroundColor: "white", fontSize: "0.8vw", paddingLeft: "0.5vw", paddingRight: ".5vw", color: "#888888" }}>{"State"}</Text>
                                    <RegionDropdown
                                        style={{ height: "2.3vw", fontSize: "1vw", marginTop: "0.5vw", paddingLeft: "1vw", borderColor: '#CDD1D5', borderRadius: "0.3vw" }}
                                        country={"India"}
                                        value={this.state.state}
                                        onChange={(val) => this.selectRegion(val)} />
                                </View> : stateKey === "country" ?
                                    <View style={{ width: "26vw" }}>
                                        <Text style={{ position: 'absolute', marginLeft: "1vw", backgroundColor: "white", fontSize: "0.8vw", paddingLeft: "0.5vw", paddingRight: "0.5vw", color: "#888888" }}>{"Country"}</Text>
                                        <CountryDropdown
                                            style={{ height: "2.3vw", fontSize: "1vw", marginTop: "0.5vw", paddingLeft: "0.5vw", borderColor: '#CDD1D5', borderRadius: "0.3vw" }}
                                            value={this.state.country}
                                            onChange={(val) => this.selectCountry(val)}
                                            whitelist={["IN"]}
                                        />
                                    </View> :
                                    stateKey === "vendor_type" ? 
                                    <Picker
                                        selectedValue={this.state.vendor_type}
                                        style={{
                                            borderColor: "#CDD1D5",
                                            borderRadius: '.26vw',
                                            borderWidth: 1,
                                            width: "100%",
                                            height: '2.3vw',
                                            fontSize: '1vw',
                                            paddingLeft: '0.65vw',
                                            marginTop:"-1vw"
                                        }}
                                        onValueChange={(itemvalue) => this.selectCatogeryType(itemvalue)}
                                    >
                                        {Vendor_Type.map((list) => (
                                            <Picker.Item label={list.label} value={list.value} />
                                        ))}
                                    </Picker> :

                                    <TextInput
                                        style={[styles.TextBoxTextInput, { borderColor: error ? "red" : "#CDD1D5" }]}
                                        keyboardType={stateKey === "mobile_number" || stateKey === "pincode" || stateKey === "landLine" ? "numeric" : null}
                                        value={value}
                                        onChangeText={ handleChange(stateKey) }
                                    />}
                            <View style={{ height: '1vw' }}>
                                {errors[stateKey] &&
                                    <Text style={styles.TextBoxerror}>{errors[stateKey]}</Text>
                                }
                            </View>
                        </View>
                }
            </View>
        )
    }

    _updateState = (stateKey, text, type) => {

        let { data } = this.state;
        data[stateKey] = text;

        this.setState({
            data
        })
    }

    _doSaveVendor = (data) => {


        let { vendor_id } = this.state;
        let url = Constants.ADD_VENDOR;

        // add save function   

            if(vendor_id) {
              data['vendor_id'] = parseInt(vendor_id);
              data['vendor_type'] = this.state.vendor_type
              data['country'] = this.state.country
              data['state'] = this.state.state
              OpthamologyService.getInstance().putMethod(
                url,
                data,
                this,
                this.addVendorSuccess,
                this.addVendorFailure,
              );
            } else { 

              this.setState({ isSaveDisable: true })
              data['vendor_type'] = this.state.vendor_type
              data['country'] = this.state.country
              data['state'] = this.state.state
              OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.addVendorSuccess,
                this.addVendorFailure,
              );
            }  
    }

    addVendorSuccess = (response) => {
        if (response.status == "success") {
            this.setState({ isSaveDisable: true })
            this.props.changeScreen("otVendor")
            this.props.showResposeValue("success", response.message)
        } else {
            this.setState({ isSaveDisable: false })
            this.props.showResposeValue("error", response.message)
        }
    }

    addVendorFailure = (error) => {
        if (error) {
            this.props.showResposeValue("error", error.message)
            this.setState({ isSaveDisable: false })
        }
    }
    renderHeaderActions = () => {
        return (
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity style={styles.addVendor} onPress={() => { this.props.changeScreen("otVendor") }}>
                        <View style={{ flexDirection: "row" }}>
                            <Image source={ImagePath.BackArrow} style={styles.Header_BackIcon} />
                            <Text style={styles.addVendorText}>Back</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{ marginLeft: "1vw" }}>
                        <Text style={[styles.commonText, { color: color.themeDark }]}>Add Vendor</Text>
                    </View>
                </View>
                <TouchableOpacity style={styles.saveButton} onPress={() => { this.handleSubmit() }}>
                    <Text style={styles.addVendorText}>Save</Text>
                </TouchableOpacity>
            </View>
        )
    }
    render() {

        let { data } = this.state;
        let isDisable = data && data.vendor_id != null ? true : false
        return (
            <View style={{ maxHeight: screenHeight - 65, margin: 15 }}>

                <Formik
                    enableReinitialize
                    validationSchema={
                        yup.object().shape({
                            name: yup.string().required(ValCon.MSG_VENDOR_NAME),
                            gstin_number: yup.string().required(ValCon.MSG_GSTIN_NO).matches(ValCon.GSTIN_NO, ValCon.MSG_FOR_SPECIAL_CHAR),
                            area_name: yup.string().required(ValCon.MSG_AREA_NAME).matches(ValCon.ALPHA_NUMERIC, ValCon.MSG_FOR_SPECIAL_CHAR),
                            dl_no: this.state.vendor_type == 1 ? yup.string().required(ValCon.MSG_DL_NO).matches(ValCon.RG_DL_NO, ValCon.MSG_INVALID_DL_NO).nullable() : yup.string().matches(ValCon.RG_DL_NO, ValCon.MSG_INVALID_DL_NO).nullable(),
                            pincode: yup.string(),
                            mobile_number: yup.string().max(10).required(ValCon.MSG_MOBILE_NUMBER),
                            address_line_1: yup.string().required(ValCon.MSG_ADDRESS).matches(ValCon.ADDRESS, ValCon.MSG_FOR_SPECIAL_CHAR),
                            address_line_2: yup.string().matches(ValCon.ADDRESS, ValCon.MSG_FOR_SPECIAL_CHAR),
                            city : yup.string().required(ValCon.MSG_CITY).matches(ValCon.ALPHA_NUMERIC, ValCon.MSG_FOR_SPECIAL_CHAR),
                            pincode: yup.string().required(ValCon.MSG_PINCODE)
                        })
                    }
                    initialValues={data}
                    onSubmit={values => {
                        this._doSaveVendor(values)
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, isValid }) => (
                        <>
                            <View>
                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <View style={{ flexDirection: "row" }}>
                                        <TouchableOpacity onPress={() => {
                                            this.props.changeScreen("otVendor")
                                        }} style={[styles.TopheaderOpacity, { backgroundColor: color.themeBackgroud }]}>
                                            <View style={styles.ImageContainer}>
                                                <Image
                                                    style={styles.ImageStyles}
                                                    source={require('../../../../../../assets/images/IPActions/Return.png')}
                                                />
                                                <Text style={{ color: "#ffffff", fontSize: '1vw' }}>{"Back"}</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{ marginLeft: "1vw", marginTop: "0.2vw" }}>
                                            <Text style={[styles.commonText, { color: color.themeDark }]}>{this.state.isTitle}</Text>
                                        </View>
                                    </View>
                                    <View style={{display:"flex", flexDirection:"row"}}>
                                        <View>{this.renderTextBox("Vendor Category", "15vw", false, "MI", "vendor_type", this.state.vendor_type, "", false, handleChange, errors)}</View>
                                        <TouchableOpacity style={styles.saveButton} onPress={handleSubmit}>
                                            <Text style={styles.addVendorText}>Save</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.TextBoxItemView}>

                                {this.renderTextBox("Vendor Name", "26vw", false, "MI", "name", values.name, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("GSTIN", "26vw", false, "MI", "gstin_number", values.gstin_number, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no", values.dl_no, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_2", values.dl_no_2, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_3", values.dl_no_3, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_4", values.dl_no_4, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_5", values.dl_no_5, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_6", values.dl_no_6, "", false, handleChange, errors)}
                            </View>
                            <View style={styles.SubTitleTextView}><Text style={styles.SubTitleText}>{"Address"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Address Line 1", "26vw", false, "MI", "address_line_1", values.address_line_1, "", false, handleChange, errors)}
                                {this.renderTextBox("Address Line 2", "26vw", false, "MI", "address_line_2", values.address_line_2, "", false, handleChange, errors)}
                                {this.renderTextBox("Area Name", "26vw", false, "MI", "area_name", values.area_name, "", false, handleChange, errors)}
                                {this.renderTextBox("City", "26vw", false, "MI", "city", values.city, "", false, handleChange, errors)}
                                {this.renderTextBox("State", "26vw", false, "MI", "state", this.state.state, "", false, handleChange, errors)}
                                {this.renderTextBox("Country", "26vw", false, "MI", "country", this.state.country, "", false, handleChange, errors)}
                                {this.renderTextBox("Pincode", "26vw", false, "MI", "pincode", values.pincode, "", false, handleChange, errors)}
                            </View>
                            <View style={styles.SubTitleTextView}><Text style={styles.SubTitleText}>{"Contact Details"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Mobile Number", "26vw", false, "MI", "mobile_number", values.mobile_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Landline", "26vw", false, "MI", "phone_number", values.phone_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Email Id", "26vw", false, "MI", "email", values.email, "", false, handleChange, errors)}
                            </View>

                        </>
                    )}
                </Formik>

            </View>
        )
    }
}
const styles = StyleSheet.create({
    TextBoxPlaceholder: {
        zIndex: 2,
        fontSize: '0.78vw',
        position: "absolute",
        marginTop: '-2.5vw',
        marginLeft: '.6vw',
        paddingHorizontal: '.2vw',
        color: "#888888"
    },
    TextBoxTextInput: {
        borderRadius: 4,
        borderWidth: 1,
        width: "26vw",
        height: '2.3vw',
        padding: '1vw',
        marginTop: '.7vw',
        fontSize: '1vw'
    },
    TextBoxerror: {
        fontSize: '1vw',
        color: 'red',
        marginTop: '.3vw'
    },
    TextBoxItemView: {
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        marginLeft: '1vw'
    },
    SubTitleTextView: { marginLeft: '1vw', marginTop: "1.3vw" },
    SubTitleText: { fontSize: '1vw' },
    addVendor: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        padding: "0.3vw"
    },
    addVendorText: {
        color: color.white,
        fontSize: "1vw",
        height: "2vw",
        paddingTop:".35vw"
    },
    ButtonContainer: {
        margin: "0.8vw",

    },
    Header_BackIcon: {
        height: "1.5vw",
        width: "1.5vw",
    },
    commonText: {
        fontSize: "1.2vw",
        fontWeight: "500",
    },
    saveButton: {
        backgroundColor: color.themeDark, borderRadius: 4, justifyContent: "center", alignContent: "center", alignItems: "center",
        marginRight: "7vw",
        width:"3vw",
        height:"2vw"
    },
    TopheaderOpacity:{ 
        width: "4.55vw", 
        height: "1.95vw", 
        borderRadius: ".3vw"
      },
      ImageContainer:{ 
        flexDirection: "row", 
        justifyContent: "center", 
        marginTop: ".3vw" 
      },
      ImageStyles:{ 
        width : "1.25vw", 
        height : "1.25vw",
        marginTop: "0.2vw" 
      },

})
