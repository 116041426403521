import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";

const platform = Platform.OS;
const pageTitle = "Complaints";
const pageName = "complaints";
const isPageName = "isComplaint";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      isComplaint: isPageName===this.props.selectedView?true:false,
      selectedClinicOrDoctor:this.props.selectedClinicOrDoctor,
      userType:this.props.userType
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getComplaints();
    }, 1000);
  }

  componentWillReceiveProps(props) {
    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
    if(props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isComplaint){

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {

    var service_url = Constants.OPTHAMOLOGY_COMPLAINTS_GET + "?appointment_id=" + this.state.patientAppointment.appointment_id;
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }, 1000);
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });
    }
  };

  getComplaintsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  
  renderComplaintItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: '0.3vw' }}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              // height: 50,
              alignItems: "center"
              // paddingBottom: 10
            }}
          >
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={ Style.contentTableDataLead }>
                {this.getComplaintsType(item.complaints)}
              </Text>
              <Text style={[ Style.contentTableData, { paddingTop: '0.3vw' }]}>
                {item.complaint_description}
              </Text>
            </View>

            <View
              style={{
                width: "20%"
                // alignItems: "center"
              }}
            >
              <Text style={ Style.contentTableData }>
                {item.duration_time != 100 ?item.duration_time : "Chronic"}  {item.duration_type === 1 ?item.duration_time === 1 ?"day":"days" : item.duration_type === 2 ? item.duration_time === 1 ?"week": "weeks": item.duration_type === 3 ? item.duration_time === 1 ?"month":"months": item.duration_type === 4 && item.duration_time != 100?  item.duration_time === 1 ?"year":"years": ""}
                {/* {this.getDurationTypeFromNos(item.duration_time)} */}
              </Text>
            </View>
            <View
              style={{
                width: "30%"
              }}
            >
              <Text style={ Style.contentTableData }>
                {item.comments}
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                alignItems: "center",
                height: '4.1vw',
                flexDirection: "row"
              }}
            >
              <View
                style={[styles.ActionBtnView, {backgroundColor: color.white}]}
              >
                <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity disabled={this.state.isLocked} onPress={this.deleteItem.bind(this, item)}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={ Style.sectionItemLine } />
      </View>
    );
  };

  getComplaintsType (type) {

    let itemType = "";

    let temp = Constants.eye_values.map((item,index)=>{

      if(type === item.value) { 
        itemType = item.label; 
      // } else if(type == 4){
      //   // itemType = "Not Applicable (NA)"; 
      //   itemType = ""; 
      }
      
      // if(type === item.value) { itemType = item.label; }
      return (
        null
      )
    })
    return itemType;
  }

  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }
  
  deleteItem = (item, event) => {
    
    //this.props.editItem(pageName, item);
    var service_url = Constants.OPTHAMOLOGY_DELETE_COMPLAINT + `${item.id}/`;

    var data ={};
    if(this.state.userType == Constants.ROLE_OPTOMETRY){
      data["doctor_id"]=this.state.selectedClinicOrDoctor.id
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getComplaintsFailure
    );
  };

  getDeleteSuccess = response => {
    if (response.status === "success") {
      
      this.getComplaints();
    }
  };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  render() {
    return (
        <View style={{ flex:1 }} >
        {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

        <View style={{ marginTop: '1vw' }}>
         { this.state.complaintsData.length > 0 ?  <View
            style={{
              marginLeft: '0.3vw',
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <View style={{ width: "30%" }} >
              <Text style={ Style.contentTableTitle }>Complaints</Text>
            </View>

            <View style={{ width: "20%" }} >
              <Text style={ Style.contentTableTitle }>Duration</Text>
            </View>
            <View style={{ width: "30%" }} >
              <Text style={ Style.contentTableTitle }>Comments</Text>
            </View>
            
            <View
              style={{
                width: "20%",
                alignItems: "center",
                // backgroundColor: color.white,
                // height: 50
              }}
            />
          </View> : null}
        </View>

        <FlatList
          data={this.state.complaintsData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderComplaintItem(item, index)
          }
        />

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
      ActionBtnView: {
        flexDirection: "row",
        paddingTop: '0.3vw',
        paddingBottom: '0.3vw',
        height: '3.3vw'
      }
})