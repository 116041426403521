import React, { Component } from "react";
import {
  View,
  Text,
  Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,

} from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import SideMenu from './../../../ui/SideMenu';
import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
import SelectedButton from "../../../components/SelectedButton";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const platform = Platform.OS;

const dummyRegistryData = [
  {
    profilePhoto: "",
    name: "Alexa",
    gender: "female",
    age: "23Y 8M 6D",
    dob: "22-07-1996",
    id: "#SVN09876543",
    mobileNo: "+91 9876543210",
    mail: "mail@mail.com",
    address: "Address line",
    city: "City",
    pincode: "000000"
  },
];

const RegistryHeading = [
  "Demographics",
  "Assessment",
  "Vitals",
  "Drug Name",
  "Allergies",
  "Immunization",
  "Unlocked Doctor Notes"
];

const DrugName = [
  "Drosyn",
  "sapin",
  "pencilin",
  "Aspirin",
  "Tetra Cyclin"
];

const VaccinationName = [
  "Drosyn",
  "salpa",
  "Tetra Cyclin",
  "Cipro Floxin"

];

const MedicalAllergy = [
  "Drosyn",
  "salpa",
  "Tetra Cyclin",
  "Cipro Floxin"

];

const NonMedicalAllergy = [
  "Drosyn",
  "salpa",
  "Tetra Cyclin",
  "Cipro Floxin"

];

const keyList = [
  "isDemographics",
  "isAssessment",
  "isVitals",
  "isDrugName",
  "isAllergies",
  "isImmunization",
  "isUnlockedDoctorNotes",

];

const Gender = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
  { value: "o", label: "Others" },
 
];

export default class Registry extends Component {
  constructor(props) {
    super(props);

    this.state = {

      filterData: {
        fromDate: "",
        toDate: "",
        patientName: "",
        billNo: "",
        amount: "",
        paymentType: ""
      },

      currentTime: "",
      fromDob: null,
      toDob: null,
      fromAge: "",
      toAge: "",
      fromAppointment: null,
      toAppointment: null,
      gender: "",
      area: "",
      city: "",
      state: "",
      pinCode: "",


      //button selection
      isDemographics: true,
      isAssessment: false,
      isDrugName: false,
      isVitals: false,
      isAllergies: false,
      isImmunization: false,
      isUnlockedDoctorNotes: false,
      selectedView: "isDemographics",
      boolList: [],
      
    

      //vitals
      fromheight: "",
      toheight: "",
      fromweight: "",
      toweight: "",
      frombmi: "",
      tobmi: "",
      frombpDiastolic: "",
      tobpDiastolic: "",
      frombpSystoli: "",
      tobpSystoli: "",

      //ImmuniZation
      fromdueDate: "",
      todueDate: "",
      togiveData: "",
      fromgiveData: "",
      fromAge: "",
      toAge: "",

      filterFromDate: "",
      filterToDate: "",
      showCalenderModal: false,
      showCalenderModalFrom: "",
      fromDate: "",

      demographicsDOBFrom:"",
      demographicsDOBTo:"",
      demographicsFromAge:"",
      demographicsToAge:"",
      demographicsAppointmentFrom:"",
      demographicsAppointmentTo:"",
      demographicsGender:"",
      demographicsArea:"",
      demographicsCity:"",
      demographicsState:"",
      demographicsPinCode:"",

      assessmentICDCode:"",
      assessmentShort:"",
      assessmentDescription:"",


      showListofRegistryDetails:[],

      vitalsHeightFrom:"",
      vitalsHeightTo:"",

      vitalsWeightFrom:"",
      vitalsWeightTo:"",

      vitalsBMIFrom:"",
      vitalsBMITO:"",

      vitalsBPSytoliFrom:"",
      vitalsBPSytoliTO:"",

      vitalsBPDiastoilFrom:"",
      vitalsBPDiastoilTO:"",

      selectedfilterValues:{
        dob:"",
        appointment:"",
        age:"",
        gender:"",
        area:"",
        city:"",
        state:"",
        pincode:""
      }
    };
  }

  componentWillMount() {
    // this.setState({
    //   demographicsAppointmentFrom:moment().format("YYYY-MM-DD"),
    //   demographicsAppointmentTo:moment().format("YYYY-MM-DD"),
    // },()=>{
    //   this.searchPatient()
    // })
    
    this.createBoolList();
    setInterval(() => {
      this.setState({
        currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
      });
    }, 1000);
  }

  // check() {
  //   console.log(this.state.toheight, this.state.frombpDiastolic, this.state.frombpSystoli, this.state.fromheight, this.state.fromweight, this.state.tobpDiastolic, this.state.tobmi)
  // }

  renderSelectEyes = (item, fill, borderNeeded, genderKey, genderValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, genderKey)}
        selected={genderValue}
      />
    );
  }

  onPressAction = (key, value) => {
    let states = this.state;
    states[key] = value;

    if(key == "demographicsGender"){
      var {selectedfilterValues} =this.state;
      var gender = ""
      if(value == "m")
        gender="Male";
      else if(value == "f")
        gender="Female";
      else if(value == "o")
        gender="Others";
      selectedfilterValues["gender"] = gender
      this.setState({
        states,
        selectedfilterValues
      })
    }else{
      this.setState({
        states
      })
    }

  }

   changeSelection = selected => {
    var demographics = false;
    var assessment = false;
    var drugName = false;
    var vitals = false;
    var allergies = false;
    var immunization = false;
    var unlockedDoctorNotes = false;
    var fields = this.state;

    switch (selected) {
      case "isDemographics":
        demographics = true;
        break;

      case "isAssessment":
        assessment = true;
        break;

      case "isDrugName":
        drugName = true;
        break;

      case "isVitals":
        vitals = true;
        break;

      case "isAllergies":
        allergies = true;
        break;

      case "isImmunization":
        immunization = true;
        break;

      case "isUnlockedDoctorNotes":
        unlockedDoctorNotes = true;
        break;
    }

    fields["selectedView"] = selected;
    fields["isDemographics"] = demographics;
    fields["isAssessment"] = assessment;
    fields["isDrugName"] = drugName;
    fields["isVitals"] = vitals;
    fields["isAllergies"] = allergies;
    fields["isImmunization"] = immunization;
    fields["isUnlockedDoctorNotes"] = unlockedDoctorNotes;


    this.createBoolList();
  };

  showCalender(date) {
    this.setState({ showCalenderModal: !this.state.showCalenderModal, showCalenderModalFrom: date });
  }
  popUpCalender() {
    if (this.state.showCalenderModal) {

      return (
        <View style={{ width: '80%', position: 'absolute', alignSelf: "center" }}>
          <Calendar
            style={{
              borderWidth: 1,
              borderColor: 'gray',
            }}
            theme={{
              backgroundColor: '#ffffff',
              calendarBackground: 'grey',
              // textSectionTitleColor: 'red',
              selectedDayBackgroundColor: 'yellow',
              selectedDayTextColor: 'yellow',
              todayTextColor: '#099ef9',

              dayTextColor: 'black',
              // textDisabledColor: '#d9e1e8',
              // dotColor: '#00adf5',
              // selectedDotColor: '#ffffff',
              // arrowColor: 'red',
              monthTextColor: 'black',
              textDayFontFamily: 'monospace',
              textMonthFontFamily: 'monospace',
              textDayHeaderFontFamily: 'monospace',
              textMonthFontWeight: 'bold',
              textDayFontSize: 13,
              textMonthFontSize: 13,
              textDayHeaderFontSize: 13
            }}
            // current={'2020-05-23'}
            // minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
            // maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate}
            onDayPress={(day) => {
              var getFilterDate = day.dateString
              this.setState({ showCalenderModal: false });
              if(this.state.showCalenderModalFrom === "demographicsDOBFrom"){
                this.setState({ demographicsDOBFrom: getFilterDate }, () => { this.updateState("demographicsDOBFrom", this.state.demographicsDOBFrom) })
              }else if(this.state.showCalenderModalFrom === "demographicsDOBTo"){
                this.setState({ demographicsDOBTo: getFilterDate }, () => { this.updateState("demographicsDOBTo", this.state.demographicsDOBTo) })
              } else if(this.state.showCalenderModalFrom === "demographicsAppointmentFrom"){
                this.setState({ demographicsAppointmentFrom: getFilterDate }, () => { this.updateState("demographicsAppointmentFrom", this.state.demographicsAppointmentFrom) })
              } else if(this.state.showCalenderModalFrom === "demographicsAppointmentTo"){
                this.setState({ demographicsAppointmentTo: getFilterDate }, () => { this.updateState("demographicsAppointmentTo", this.state.demographicsAppointmentTo) })
              } else if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: getFilterDate }, () => { this.updateState("fromDate", this.state.filterFromDate) })
              } else if (this.state.showCalenderModalFrom === "To") {
                this.setState({ filterToDate: getFilterDate }, () => { this.updateState("toDate", this.state.filterToDate) })
              }
            }}
            onDayLongPress={(day) => { console.log('selected day is', day) }}
            // monthFormat={'yyyy MM'}
            onMonthChange={(month) => { }}
            hideArrows={false}
            renderArrow={(direction) => {
              if (direction === "left") {
                return (
                  <Icon
                    size={30}
                    name={"angle-left"}
                  />
                )
              } else if (direction === "right") {
                return (
                  <Icon
                    size={30}
                    name={"angle-right"}
                  />
                )
              }
            }}
            hideExtraDays={false}
            disableMonthChange={false}
            // firstDay={1}
            // hideDayNames={true}
            // showWeekNumbers={true}
            onPressArrowLeft={substractMonth => substractMonth()}
            onPressArrowRight={addMonth => addMonth()}

          />
          <View style={{ justifyContent: 'space-evenly', backgroundColor: 'grey', borderTopWidth: 1, borderTopColor: '#b6b6b6', padding: 10, flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => {
              this.showTodayDateInFilter()
            }}>
              <Text>{"Today"}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: "", showCalenderModal: false })
              } else {
                this.setState({ filterToDate: "", showCalenderModal: false })
              }
            }}>
              <Text>{"Clear"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View />
      )
    }
  }

  showTodayDateInFilter() {
    if (this.state.showCalenderModalFrom === "From") {
      var toYear = parseInt(this.state.filterToDate.substring(0, 4));
      var toMonth = parseInt(this.state.filterToDate.substring(5, 7));
      var toDate = parseInt(this.state.filterToDate.substring(8, 10));

      var fromYear = parseInt(moment().format("YYYY"));
      var fromMonth = parseInt(moment().format("MM"));
      var fromDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      } else {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterToDate: "" },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      }
    } else {
      var fromYear = parseInt(this.state.filterFromDate.substring(0, 4));
      var fromMonth = parseInt(this.state.filterFromDate.substring(5, 7));
      var fromDate = parseInt(this.state.filterFromDate.substring(8, 10));

      var toYear = parseInt(moment().format("YYYY"));
      var toMonth = parseInt(moment().format("MM"));
      var toDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      } else {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterFromDate: "" },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      }
    }
  }







  renderRegistryItem(item) {
    var image;
    if(item.photo_url){
      image =  {uri: item.photo_url}
    }else{
      image = require("../../../../assets/images/sample_profile.png")
    }
    return (
      <View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: 0,
            marginTop: 10,
            marginRight: 30,
            marginBottom: 10,
            height: 60,
            borderRadius: 20,
            paddingLeft: 10,
            paddingRight: 10,
            alignItems: "center",
            backgroundColor: color.white,
            paddingRight: 30
          }}
        >
          <View
            style={{
              width: "30%",
              flexDirection: "row"
            }}
          >
            <View>
              <Image
                source={image}
                style={{ width: 50, height: 50,}}
              />
            </View>

            <View style={{ marginLeft: 15 }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "500",
                  color: color.black
                }}
              >
                {item.name}
              </Text>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#717171",
                    marginTop: 5,
                    marginBottom: 5,
                    paddingRight:7,
                    borderRightColor:"#717171",
                    borderRightWidth:1
                  }}
                >
                  {item.gender}
                </Text>
                {/* <Text>{" | "}</Text> */}
               
                <Text
                  style={{
                    fontSize: 12,
                    color: "#717171",
                    marginTop: 5,
                    marginBottom: 5,paddingRight:7,paddingLeft:7,
                    borderRightColor:"#717171",
                    borderRightWidth:1
                  }}
                >
                  {item.age}
                </Text>
                {/* <Text>{" | "}</Text> */}
                <Text
                  style={{
                    fontSize: 12,
                    color: "#717171",
                    marginTop: 5,
                    marginBottom: 5,paddingLeft:7,
                  }}
                >
                  {item.dob}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "30%",
              alignItems: "flex-end"
            }}
          >
            <Text
              style={{
                fontSize: platform === "web" ? 12 : 20,
                fontWeight: "500",
                color: color.black,
                marginTop: 5,
              }}
            >
              {item.patient_id}
            </Text>

            <Text
              style={{
                fontSize: platform === "web" ? 12 : 20,
                fontWeight: "500",
                color: color.black,
                marginTop: 5,
                marginBottom:5
              }}
            >
              {item.mobile_number}{item.email ? " | " : " "}{item.email}
            </Text>

            <Text>
              {item.patient_address }
            </Text>
          </View>
        </View>
        <View
          style={{
            height: 1,
            backgroundColor: "#ADADAD",
            paddingLeft: 30,
            width: "93%"
          }}
        />
      </View>
    );
  }

  createBoolList = () => {
    var fields = this.state;

    var temp = [this.state.isDemographics, this.state.isAssessment, this.state.isVitals, this.state.isDrugName, this.state.isAllergies, this.state.isImmunization, this.state.isUnlockedDoctorNotes]

    fields["boolList"] = temp;

    this.setState({ fields });
  }

  creategenderList = () => {
    var creategender = this.state;

    var temp = [this.state.genderMale, this.state.genderFemale, this.state.genderOthers]

    creategender["genderList"] = temp;

    this.setState({ creategender });
  }

  renderTextBtn = (selected, name, key, isBtn, isGender) => {
    return (
      <TouchableOpacity onPress={() => isGender ? this.changeGender(key) : this.changeSelection(key)}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            flexDirection: "row",
            borderColor: selected ? color.themeDark : color.textBoxBorderColor,
            borderWidth: 1,
            backgroundColor: selected ? color.themeDark : null,
            padding: 5,
            paddingRight: 8,
            paddingLeft: 8,
            marginBottom: 10,
            marginRight: 2,
            marginLeft:5,
            marginRight:5,
            zIndex: -1,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              color: selected ? color.white : color.darkBlack,
              // fontWeight: "500"
            }}
          >
            {name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderRightBtn = (name, outline) => {
    return (
      <View
        style={{
          height: 35,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          flexDirection: "row",
          borderColor: outline ? color.themeDark : color.themeDark,
          borderWidth: 1,
          backgroundColor: outline ? null : color.themeDark,
          padding: 5,
          justifyContent: "space-evenly",
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            color: outline ? color.themeDark : color.white,
            fontWeight: "700",
            paddingLeft: 25,
            paddingRight: 25
          }}
        >
          {name}
        </Text>
      </View>
    );
  };

  renderTextField = (title,key, value) => {
    return (
      <View
        style={{
          // borderColor: "#525252",
          borderColor:color.textBoxBorderColor,
          borderColor:value ? color.themeDark: color.textBoxBorderColor,
          // {borderColor:this.state.demographicsDOBFrom ? color.themeDark: color.textBoxBorderColor}
          borderWidth: 1,
          marginTop: 5,
          height: 35,
          borderWidth: 1,
          // borderColor: color.white,
          borderRadius: 10,
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 10,
          paddingLeft: 10,
          paddingRight: 10,
          width: "50%",
          marginRight: 10
        }}
      >
        <TextInput
          placeholder={title}
          underlineColorAndroid="transparent"
          style={{ color: color.textBoxBorderColor, width: "90%" }}
          placeholderTextColor={color.textBoxBorderColor}
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            this.updateState(text, title, value, key);
          }}
        />
      </View>
    );
  };

  updateState = (text, title, value, key) => {
    // var fields = this.state;
    // fields["filterData"][key] = value;
    if (key === "area") {
      this.setState({ area: text })
    } else if (key === "city") {
      this.setState({ city: text })
    } else if (key === "State") {
      this.setState({ state: text })
    } else if (key === "pincode") {
      this.setState({ pinCode: text })
    } else if (key === "fromAge") {
      this.setState({ fromAge: text })
    } else if (key === "toAge") {
      this.setState({ toAge: text })
    } else if (key === "fromheight") {
      this.setState({ fromheight: text })
    } else if (key === "toheight") {
      this.setState({ toheight: text })
    } else if (key === "fromweight") {
      this.setState({ fromweight: text })
    } else if (key === "toweight") {
      this.setState({ toweight: text })
    } else if (key === "frombmi") {
      this.setState({ frombmi: text })
    } else if (key === "tobmi") {
      this.setState({ tobmi: text })
    } else if (key === "frombpDiastolic") {
      this.setState({ frombpDiastolic: text })
    } else if (key === "tobpDiastolic") {
      this.setState({ tobpDiastolic: text })
    } else if (key === "frombpSystoli") {
      this.setState({ frombpSystoli: text })
    } else if (key === "tobpSystoli") {
      this.setState({ tobpSystoli: text })
    }else if (key === "demographicsDOBFrom"){

    }else if (key === "demographicsDOBTo"){

    }else if (key === "demographicsToAge"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["age"]=this.state.demographicsFromAge+"  "+text;
      this.setState({ demographicsToAge: text, selectedfilterValues })
    }else if (key === "demographicsFromAge"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["age"]=text+"  "+this.state.demographicsToAge;
      this.setState({ demographicsFromAge: text,selectedfilterValues })
    }else if (key === "demographicsAppointmentTo"){
      this.setState({ demographicsAppointmentTo: text })
    }else if (key === "demographicsAppointmentFrom"){
      this.setState({ demographicsAppointmentFrom: text })
    }

    else if (key === "demographicsArea"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["area"]=text;
      this.setState({ demographicsArea: text ,selectedfilterValues})
    }else if (key === "demographicsCity"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["city"]=text;
      this.setState({ demographicsCity: text ,selectedfilterValues})
    }else if (key === "demographicsState"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["state"]=text;
      this.setState({ demographicsState: text,selectedfilterValues })
    }else if (key === "demographicsPinCode"){
      var {selectedfilterValues}=this.state;
      selectedfilterValues["pincode"]=text;
      this.setState({ demographicsPinCode: text,selectedfilterValues })
    }

    else if (key === "vitalsHeightFrom"){
      this.setState({ vitalsHeightFrom: text })
    }else if (key === "vitalsHeightTo"){
      this.setState({ vitalsHeightTo: text })
    }else if (key === "vitalsWeightFrom"){
      this.setState({ vitalsWeightFrom: text })
    }else if (key === "vitalsWeightTo"){
      this.setState({ vitalsWeightTo: text })
    }else if (key === "vitalsBMIFrom"){
      this.setState({ vitalsBMIFrom: text })
    }else if (key === "vitalsBMITO"){
      this.setState({ vitalsBMITO: text })
    }else if (key === "vitalsBPSytoliFrom"){
      this.setState({ vitalsBPSytoliFrom: text })
    }else if (key === "vitalsBPSytoliTO"){
      this.setState({ vitalsBPSytoliTO: text })
    }else if (key === "vitalsBPDiastoilFrom"){
      this.setState({ vitalsBPDiastoilFrom: text })
    }else if (key === "vitalsBPDiastoilTO"){
      this.setState({ vitalsBPDiastoilTO: text })
    }



  };

  renderSubHeadingBorder = title => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
          marginTop:10
        }}
      >
        <Text style={{ fontSize: 20, color: color.darkBlack }}>{title}</Text>
        <View
          style={{
            marginLeft: 10,
            width: "100%",
            height: 1,
            backgroundColor: color.textBoxBorderColor
          }}
        />
      </View>
    );
  };

  renderDateRow = (placeHolder1, key1, value1, placeHolder2, key2, value2) => {
    return (
      <View
        style={{
          flexDirection: "row",
          marginBottom: 10,
          marginTop: 0,
          alignItems: "center"
        }}
      >
        {this.renderTextField(placeHolder1, key1, value1)}
        {this.renderTextField(placeHolder2, key2, value2)}
      </View>
    );
  };

  renderDemographicsFilter = () => {
    return (

      <View style={{ width: "90%" }}>

        {this.renderSubHeadingBorder("DOB")}
        <View style={{ flexDirection: "row" }}>


          <DatePicker
            onChange={date => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              var {selectedfilterValues} = this.state;
              this.setState({ demographicsDOBFrom: converted_date }, () => { 
                this.updateState("demographicsDOBFrom", this.state.demographicsDOBFrom) 
                selectedfilterValues["dob"] =this.state.demographicsDOBFrom+"  "+this.state.demographicsDOBTo
                this.setState({ selectedfilterValues })
              })
              
            }}
            // defaultValue={"From"}
            format={"YYYY-MM-DD"}
            placeholder={"From"}
            style={{
              borderColor: color.textBoxBorderColor, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderRadius: 10, alignItems: "center", flexDirection: "row",
              backgroundColor:"transparent",paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10,
              borderColor: this.state.demographicsDOBFrom ? color.themeDark : color.textBoxBorderColor
            }}
          />



          <DatePicker
            onChange={date => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              var {selectedfilterValues} = this.state;
              this.setState({ demographicsDOBTo: converted_date }, () => { 
                this.updateState("demographicsDOBTo", this.state.demographicsDOBTo) 
                selectedfilterValues["dob"] =this.state.demographicsDOBFrom+"  "+this.state.demographicsDOBTo
                this.setState({ selectedfilterValues })
              })
            }}
            // defaultValue={"From"}
            format={"YYYY-MM-DD"}
            placeholder={"To"}
            style={{
              borderColor: color.textBoxBorderColor, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderRadius: 10, alignItems: "center",
              backgroundColor:"transparent",flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10,
              borderColor: this.state.demographicsDOBTo ? color.themeDark : color.textBoxBorderColor
            }}
          />

          {/* <TouchableOpacity
            style={[styles.dobcalendarView,{borderColor:this.state.demographicsDOBFrom ? color.themeDark: color.textBoxBorderColor}]}
            onPress={() => { this.showCalender("demographicsDOBFrom") }}><Text
              style={styles.dobcalendarText}
            >{this.state.demographicsDOBFrom === "" ? "From" : this.state.demographicsDOBFrom}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.textBoxBorderColor}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.dobcalendarView,{borderColor:this.state.demographicsDOBTo ? color.themeDark: color.textBoxBorderColor}]}
            onPress={() => { this.showCalender("demographicsDOBTo") }}><Text
              style={styles.dobcalendarText}
            >{this.state.demographicsDOBTo === "" ? "To" : this.state.demographicsDOBTo}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.textBoxBorderColor}
            />
          </TouchableOpacity> */}

        </View>

        {this.renderSubHeadingBorder("Age")}
        {this.renderDateRow(
          "From",
          "demographicsFromAge",
          this.state.demographicsFromAge,
          "To",
          "demographicsToAge",
          this.state.demographicsToAge
        )}
        {this.renderSubHeadingBorder("Appointment")}
        <View style={{ flexDirection: "row" }}>


        <DatePicker
            onChange={date => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              var {selectedfilterValues} = this.state;
              this.setState({ demographicsAppointmentFrom: converted_date }, () => { 
                this.updateState("demographicsAppointmentFrom", this.state.demographicsAppointmentFrom) 
                selectedfilterValues["appointment"] =this.state.demographicsAppointmentFrom+"  "+this.state.demographicsAppointmentTo
                this.setState({ selectedfilterValues })
              })
            }}
            format={"YYYY-MM-DD"}
            placeholder={"From"}
            style={{
              borderColor: color.textBoxBorderColor, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderRadius: 10, alignItems: "center",
              backgroundColor:"transparent",flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10,
              borderColor:this.state.demographicsAppointmentFrom ? color.themeDark: color.textBoxBorderColor
            }}
          />

          <DatePicker
            onChange={date => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              var {selectedfilterValues} = this.state;
              this.setState({ demographicsAppointmentTo: converted_date }, () => { 
                this.updateState("demographicsAppointmentTo", this.state.demographicsAppointmentTo) 
                selectedfilterValues["appointment"] =this.state.demographicsAppointmentFrom+"  "+this.state.demographicsAppointmentTo
                this.setState({ selectedfilterValues })
              })
            }}
            format={"YYYY-MM-DD"}
            placeholder={"To"}
            style={{
              borderColor: color.textBoxBorderColor, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderRadius: 10, alignItems: "center",
              backgroundColor:"transparent",flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10,
              borderColor:this.state.demographicsAppointmentTo ? color.themeDark: color.textBoxBorderColor
            }}
          />

          {/* <TouchableOpacity
            style={[styles.calendarView,{borderColor:this.state.demographicsAppointmentFrom ? color.themeDark: color.textBoxBorderColor}]}
            onPress={() => { this.showCalender("demographicsAppointmentFrom") }}><Text
              style={styles.calendarText}
            >{this.state.demographicsAppointmentFrom === "" ? "From" : this.state.demographicsAppointmentFrom}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.textBoxBorderColor}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.calendarView,{borderColor:this.state.demographicsAppointmentTo ? color.themeDark: color.textBoxBorderColor}]}
            onPress={() => { this.showCalender("demographicsAppointmentTo") }}><Text
              style={styles.calendarText}
            >{this.state.demographicsAppointmentTo === "" ? "To" : this.state.demographicsAppointmentTo}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.textBoxBorderColor}
            />
          </TouchableOpacity>
          {this.popUpCalender()} */}
        </View>
        {/* {this.renderSubHeadingBorder("Gender")} */}
        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20, borderColor: color.themePink, color: this.state.gender ? color.themePink : color.white, zIndex: -1 }}>
          <Text style={{ fontSize: 25, color: color.darkBlack, marginTop: 6 }}>Gender</Text>
          {/* {this.renderTextBtn(this.state.gender ? this.state.gender === "male" ? true : false : false, "Male", "genderMale", true, true)}
          {this.renderTextBtn(this.state.gender ? this.state.gender === "female" ? true : false : false, "Female", "genderFemale", true, true)}
          {this.renderTextBtn(this.state.gender ? this.state.gender === "others" ? true : false : false, "Others", "genderOthers", true, true)} */}
          <FlatList
            style={{ marginTop: 10, marginBottom: 10, marginLeft: 20 }}
            data={Gender}
            numColumns={3}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            containerStyle={{ alignItems: "center", alignContent: "center" }}
            // renderItem={({ item, index }) =>
            //   this.renderTextBtn(this.state.genderList[index], item, this.state.gender, false, true)
            // }
            renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'demographicsGender', this.state.demographicsGender)}
          />
        </View>
        <View style={{ zIndex: -1 }}>
          {this.renderDateRow(
            "Area",
            "demographicsArea",
            this.state.demographicsArea,
            "City",
            "demographicsCity",
            this.state.demographicsCity
          )}
          {this.renderDateRow(
            "State",
            "demographicsState",
            this.state.demographicsState,
            "Pincode",
            "demographicsPinCode",
            this.state.demographicsPinCode
          )}
        </View>
      </View>
    );
  };




  renderAssessmentFilter = () => {
    // assessmentICDCode:"",
    // assessmentShort:"",
    // assessmentDescription:""
    return (
      <View style={{ width: "100%" }}>
        <View style={{
          height: 40,
          marginTop: 20,
          backgroundColor: color.white,
          borderRadius: 10
        }}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={"ICD Code"}
            style={{
              // color: "#525252",
              color:color.textBoxBorderColor,
              height: 40,
              paddingLeft: 5,
              marginRight: 0,
              flex: 1
            }}
            autoCapitalize="none"
            onChangeText={text => {
              this.setState({
                assessmentICDCode:text
              })
              // this.updateState(text, title, value, key);
            }}
          />
        </View>
        <View style={{
          height: 40,
          marginTop: 20,
          backgroundColor: color.white,
          borderRadius: 10
        }}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={"ShortDescription"}
            style={{
              // color: "#525252",
              color:color.textBoxBorderColor,
              height: 40,
              paddingLeft: 5,
              marginRight: 0,
              flex: 1
            }}
            autoCapitalize="none"
            onChangeText={text => {
              this.setState({
                assessmentDescription:text
              })
            }}

          />
        </View>
        <View style={{
          height: 90,
          marginTop: 20,
          backgroundColor: color.white,
          borderRadius: 10
        }}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={"Description"}
            style={{
              color:color.textBoxBorderColor,
              // color: "#525252",
              height: 90,
              paddingLeft: 5,
              marginRight: 0,
              flex: 1
            }}
            autoCapitalize="none"
            onChangeText={text => {
              this.setState({
                assessmentShort:text
              })
            }}
          />
        </View>
      </View>

    );
  }

  renderDrugNameFilter = () => {
    return (
      <View style={{ width: "90%" }}>
        {this.renderSubHeadingBorder("DrugName")}
        <FlatList
          style={{ marginTop: 10, marginBottom: 10 }}
          data={DrugName}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item, index }) =>
            this.renderTextBtn(this.state.boolList[index], item, keyList[index], true, false)
          }
        />

      </View>
    );
  }

  renderVitalFilter = () => {
    return (
      <View style={{ width: "90%" }}>
        {this.renderSubHeadingBorder("Height")}
        {this.renderDateRow(
          "From",
          "vitalsHeightFrom",
          this.state.vitalsHeightFrom,
          "To",
          "vitalsHeightTo",
          this.state.vitalsHeightTo
        )}
        {this.renderSubHeadingBorder("weight")}
        {this.renderDateRow(
          "From",
          "vitalsWeightFrom",
          this.state.vitalsWeightFrom,
          "To",
          "vitalsWeightTo",
          this.state.vitalsWeightTo
        )}
        {this.renderSubHeadingBorder("BMI")}
        {this.renderDateRow(
          "From",
          "vitalsBMIFrom",
          this.state.vitalsBMIFrom,
          "To",
          "vitalsBMITO",
          this.state.vitalsBMITO
        )}
        {this.renderSubHeadingBorder("BPSystoli")}
        {this.renderDateRow(
          "From",
          "vitalsBPSytoliFrom",
          this.state.vitalsBPSytoliFrom,
          "To",
          "vitalsBPSytoliTO",
          this.state.vitalsBPSytoliTO
        )}
        {this.renderSubHeadingBorder("BPDiastolic")}
        {this.renderDateRow(
          "From",
          "vitalsBPDiastoilFrom",
          this.state.vitalsBPDiastoilFrom,
          "To",
          "vitalsBPDiastoilTO",
          this.state.vitalsBPDiastoilTO
        )}
      </View>
    );
  }

  renderAllergyFilter = () => {
    return (
      <View>
        {this.renderSubHeadingBorder("MedicalAllergy")}
        <FlatList
          style={{ marginTop: 10, marginBottom: 10 }}
          data={MedicalAllergy}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item, index }) =>
            this.renderTextBtn(this.state.boolList[index], item, keyList[index], true, false)
          }
        />

        {this.renderSubHeadingBorder("NonMedicalAllergy")}
        <FlatList
          style={{ marginTop: 10, marginBottom: 10 }}
          data={NonMedicalAllergy}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item, index }) =>
            this.renderTextBtn(this.state.boolList[index], item, keyList[index], true, false)
          }
        />
      </View>
    );
  }

  renderImmunizationFilter = () => {
    return (

      <View style={{ width: "90%", zIndex: -1 }}>

        {this.renderSubHeadingBorder("VaccinationName")}
        <FlatList
          //style={{ marginTop: 10, marginBottom: 10 }}
          data={VaccinationName}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          //containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item, index }) =>
            this.renderTextBtn(this.state.boolList[index], item, keyList[index], true, false)
          }
        />

        {this.renderSubHeadingBorder("DueData")}
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            style={styles.immunizationcalendarView}
            onPress={() => { this.showCalender("From") }}><Text
              style={styles.calendarText}
            >{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.calendarView}
            onPress={() => { this.showCalender("To") }}><Text
              style={styles.calendarText}
            >{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>

        </View>
        {this.renderSubHeadingBorder("DOB")}
        <View style={{ flexDirection: "row", zIndex: -1 }}>
          <TouchableOpacity
            style={styles.DOBcalendarView}
            onPress={() => { this.showCalender("From") }}><Text
              style={styles.DOBcalendarText}
            >{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.DOBcalendarView}
            onPress={() => { this.showCalender("To") }}><Text
              style={styles.DOBcalendarText}
            >{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>

        </View>

        {this.renderSubHeadingBorder("GivenData")}
        <View style={{ flexDirection: "row", zIndex: -1 }}>
          <TouchableOpacity
            style={styles.DOBcalendarView}
            onPress={() => { this.showCalender("From") }}><Text
              style={styles.DOBcalendarText}
            >{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.DOBcalendarView}
            onPress={() => { this.showCalender("To") }}><Text
              style={styles.DOBcalendarText}
            >{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text>
            <Icon
              size={10}
              name={"calendar"}
              color={color.white}
            />
          </TouchableOpacity>
          {this.popUpCalender()}
        </View>
        {this.renderSubHeadingBorder("Age")}
        {this.renderDateRow(
          "From",
          "fromage",
          this.state.fromAge,
          "To",
          "toage",
          this.state.toAge
        )}

      </View>

    );
  }

  renderUnlockedDoctorNotesFilter = () => {
    return (
      <View>

      </View>
    );
  }

  previewSelected = selected => {
    switch (selected) {
      case "isDemographics":
        return this.renderDemographicsFilter();
      case "isAssessment":
        return this.renderAssessmentFilter();
      case "isDrugName":
        return this.renderDrugNameFilter();
      case "isVitals":
        return this.renderVitalFilter();
      case "isAllergies":
        return this.renderAllergyFilter();
      case "isImmunization":
        return this.renderImmunizationFilter();
      case "isUnlockedDoctorNotes":
        return this.renderUnlockedDoctorNotesFilter();
      case "isUnlockedDoctorNotes":
        return this.renderUnlockedDoctorNotesFilter();

    }
  };

  clearSearch(type){
    var {selectedfilterValues} = this.state;
    // selectedfilterValues[type]="";
    // this.setState({
    //   selectedfilterValues
    // })
    var states =this.state;
    if(type == "dob"){
      states["demographicsDOBFrom"] ="";
      states["demographicsDOBTo"] ="";
    }else if(type == "appointment"){
      states["demographicsAppointmentFrom"] ="";
      states["demographicsAppointmentTo"] ="";
    }else if(type == "age"){
      states["demographicsFromAge"] ="";
      states["demographicsToAge"] ="";
    }else if(type == "gender"){
      states["demographicsGender"] ="";
    }else if(type == "area"){
      states["demographicsArea"] ="";
    }else if(type == "city"){
      states["demographicsCity"] ="";
    }else if(type == "state"){
      states["demographicsState"] ="";
    }else if(type == "pincode"){
      states["demographicsPinCode"] ="";
    }

    selectedfilterValues[type]="";
    this.setState({
      states,
      selectedfilterValues
    })
  }

  renderFilterButton(data,type){
    return(
      <TouchableOpacity
        onPress={()=>{
          this.clearSearch(type);
        }}
        style={{justifyContent:'center',flexDirection:'row',alignSelf:'center',borderRadius:30,borderColor:color.textBoxBorderColor,borderWidth:1,paddingLeft:10,paddingRight:10,paddingTop:7,paddingBottom:7}}>
        <Text style={{alignSelf:'center',marginRight:10}}>{data}</Text>
        <Icon
          name="remove"
          size={20}
          color={color.themeDark}
          onPress={() => { }}
        />
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <View style={Style.rootView}>
        <View style={[Style.leftBarView, { paddingTop: 30 }]}>
          <SideMenu navigation={this.props.navigation} />
        </View>
        {/* first view */}
        {/* <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}> */}
        <View style={Style.leftView}>

          <View
            style={{
              marginTop: platform === "web" ? 0 : 20,
              width: "100%",
              marginLeft: 30
            }}
          >
            <View
              style={{ justifyContent: "center", height: 30, marginTop: 50 }}
            >
              <Text style={{ fontSize: 22, fontWeight: "500",color:color.darkBlack }}>
                {/* Registry */}
                {
                  this.state.selectedView == "isDemographics" ? "Demographies" : 
                  this.state.selectedView == "isAssessment" ? "Assessment" :
                  this.state.selectedView == "isDrugName" ? "DrugName" :
                  this.state.selectedView == "isVitals" ? "Vitals" :
                  this.state.selectedView == "isAllergies" ? "Allergies" :
                  this.state.selectedView == "isImmunization" ? "Immunization" :
                  this.state.selectedView == "isUnlockedDoctorNotes" ? "UnlockedDoctorNotes" : null
                }
              </Text>
            </View>
            <View>
              <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                {
                  this.state.selectedfilterValues.dob ?
                    this.renderFilterButton(this.state.selectedfilterValues.dob,"dob")
                    : null
                }
                {
                  this.state.selectedfilterValues.appointment ?
                    this.renderFilterButton(this.state.selectedfilterValues.appointment,"appointment")
                    : null
                }
                {
                  this.state.selectedfilterValues.age ?
                    this.renderFilterButton(this.state.selectedfilterValues.age,"age")
                    : null
                }
                {
                  this.state.selectedfilterValues.gender ?
                    this.renderFilterButton(this.state.selectedfilterValues.gender,"gender")
                    : null
                }
                {
                  this.state.selectedfilterValues.area ?
                    this.renderFilterButton(this.state.selectedfilterValues.area,"area")
                    : null
                }
                {
                  this.state.selectedfilterValues.city ?
                    this.renderFilterButton(this.state.selectedfilterValues.city,"city")
                    : null
                }
                {
                  this.state.selectedfilterValues.state ?
                    this.renderFilterButton(this.state.selectedfilterValues.state,"state")
                    : null
                }
                {
                  this.state.selectedfilterValues.pincode ?
                    this.renderFilterButton(this.state.selectedfilterValues.pincode,"pincode")
                    : null
                }

              </View>
            </View>
            <ScrollView style={{height:screenHeight - 160}} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <FlatList
              style={{ marginTop: 20 }}
              // data={dummyRegistryData}
              data={this.state.showListofRegistryDetails}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => this.renderRegistryItem(item)}
            />
            </ScrollView>

            {
              this.state.showListofRegistryDetails.length > 0 ?
                <View
                  style={{
                    margin: 20,
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "90%"
                  }}
                >
                  <TouchableOpacity style={{marginRight:50}}>
                    {this.renderRightBtn("Export", false)}
                  </TouchableOpacity>
                  <TouchableOpacity style={{marginLeft:50}}>
                    {this.renderRightBtn("Message", false)}
                  </TouchableOpacity>
                </View>
                : null
            }


          </View>
        </View>
        {/* </ScrollView> */}
        {/* second view */}
        <View style={[Style.rightView, { padding: 20 }]}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ width: "100%" }}
            contentContainerStyle={{ flex: 1 }}>
            <View style={{ width: "100%" }}>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text style={{  fontSize: 15, color: color.lightGray, marginTop: 20,marginBottom:20 }}>
                  {this.state.currentTime}
                </Text>
              </View>

              <FlatList
                style={{ marginTop: 10, marginBottom: 10 }}
                data={RegistryHeading}
                numColumns={3}
                showsVerticalScrollIndicator={false}
                scrollEnabled={false}
                showsHorizontalScrollIndicator={false}
                containerStyle={{ alignItems: "center", alignContent: "center" }}
                renderItem={({ item, index }) =>
                  this.renderTextBtn(this.state.boolList[index], item, keyList[index], true, false)
                }
              />

              {this.previewSelected(this.state.selectedView)}
            </View>
          </ScrollView>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "90%",
              // position: "absolute",
              // bottom: 10,ScrollView

            }}
          >
            <TouchableOpacity
            onPress={() => this.searchPatient()}
            >
              {this.renderRightBtn("Search", false)}
            </TouchableOpacity>
            <TouchableOpacity
              // onPress={() => this.changeAppointmentStatus("Cancelled")}
              onPress={() => this.clearPatient()}
            >
              {this.renderRightBtn("Clear", true)}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  searchPatient(){
    // console.log("demographicsDOBFrom <===> "+this.state.demographicsDOBFrom);
    // console.log("demographicsDOBTO <===> "+this.state.demographicsDOBTo);
    // console.log("demographicsFromAge <===> "+this.state.demographicsFromAge);
    // console.log("demographicsToAge <===> "+this.state.demographicsToAge);
    // console.log("demographicsAppointmentFrom <===> "+this.state.demographicsAppointmentFrom);
    // console.log("demographicsAppointmentTO <===> "+this.state.demographicsAppointmentTo);



    // console.log("demographicsGender <===> "+this.state.demographicsGender);
    // console.log("demographicsArea <===> "+this.state.demographicsArea);
    // console.log("demographicsCity <===> "+this.state.demographicsCity);
    // console.log("demographicsState <===> "+this.state.demographicsState);
    // console.log("demographicsPinCode <===> "+this.state.demographicsPinCode);


    var serviceUrl = Constants.REGISTRY + "?appointment_from="+this.state.demographicsAppointmentFrom+
    "&appointment_to="+this.state.demographicsAppointmentTo+
    "&dob_start_date="+this.state.demographicsDOBFrom+
    "&dob_end_date="+this.state.demographicsDOBTo+
    "&gender="+this.state.demographicsGender+
    "&icd_code="+this.state.assessmentICDCode;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRegistryDetailsSuccess,
      this.getRegistryDetailsFailure
    );
  }

  getRegistryDetailsFailure = error =>{
    console.log("getRegistryDetailsFailure ------------------------------------> "+JSON.stringify(error));
  }
  getRegistryDetailsSuccess = success =>{
   // console.log("getRegistryDetailsSuccess ------------------------------------> "+JSON.stringify(success));
    if(success.status == "success"){
      this.setState({
        showListofRegistryDetails:success.data
      })
    }
  }

  clearPatient(){
   var demographics = this.state;
   demographics["demographicsDOBFrom"]="";
   demographics["demographicsDOBTo"]="";
   demographics["demographicsFromAge"]="";
   demographics["demographicsToAge"]="";
   demographics["demographicsAppointmentFrom"]="";
   demographics["demographicsAppointmentTo"]="";

   demographics["demographicsGender"]="";
   demographics["demographicsArea"]="";
   demographics["demographicsCity"]="";
   demographics["demographicsState"]="";
   demographics["demographicsPinCode"]="";
   demographics["demographicsDOBFrom"]="";
   this.setState({
     demographics
   })
  }
  

}
const styles = StyleSheet.create({
  calendarView: {
    // borderColor: "#525252",
    borderColor:color.textBoxBorderColor,
    borderWidth: 1,
    marginTop: 10,
    height: 35,
    borderWidth: 1,
    // borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  calendarText: {
    // color: color.white,
    color: color.textBoxBorderColor,
    width: "90%"
  },
  dobcalendarView: {
    // borderColor: "#525252",
    borderColor:color.textBoxBorderColor,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    // borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  dobcalendarText: {
    // color: color.white,
    color: color.textBoxBorderColor,
    width: "90%"
  },
  DOBcalendarView: {
    // borderColor: "#525252",
    borderColor:color.textBoxBorderColor,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    // borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  DOBcalendarText: {
    // color: color.white,
    color:color.textBoxBorderColor,
    width: "90%"
  },
  immunizationcalendarView: {
    // borderColor: "#525252",
    borderColor:color.textBoxBorderColor,
    borderWidth: 1,
    marginTop: 10,
    height: 35,
    borderWidth: 1,
    // borderColor: color.white,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  }
})