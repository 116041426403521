import React, { Component } from 'react';
import { View, Text, StyleSheet, Picker, TouchableWithoutFeedback } from 'react-native';
import { color } from '../../../styles/Color';
import SearchIcon from "react-native-vector-icons/FontAwesome";
import CommonDateFilter from '../BaseComponent';
import Style from '../../../styles/Style';
import moment from "moment";
import { Tooltip } from 'antd';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';
import { NavigationTopHeader } from '../BaseComponent';

const navigationHeader = [
  { label: "Statistics", value: "superAdminStatistics" }
]
export default class Statistics extends Component {
  constructor(props) {
    super(props);

    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");

    this.state = {
      selectedBranchName: '',
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      filterDate: {},
      isclear: true,
      branchList: [],
      statisticBillData: {},
      totalResult: {},
      inPatientItem: {},
      outPatientItem: {},
      pharmacyItem: {},
      labItem: {},
      userType: this.props.userType,
      dateFilterstatus: "Today",

    };
  }

  componentDidMount() {
    this.getStatisticData()
    if(this.state.userType === "SUPER_ADMIN") { 
      this.getBranchName()
    }
    
  }

  // Get BranchList Data API start
  getBranchName = () => {
    var url = Constants.GET_BRANCH_NAME
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getBranchNameSuccess,
      this.getBranchNameFailure
    );
  }

  getBranchNameSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        branchList: response.data,
      })
    } else {
      this.props.showResposeValue('error', response.message)
    }
  }
  getBranchNameFailure = (error) => {
    this.props.showResposeValue('error', error.message)
  }
  // Get BranchList Data API End

  // Get Statistic Data API start
  getStatisticData = () => {

   if(this.state.userType === "SUPER_ADMIN") {
    var serviceurl = Constants.SPUERADMIN_STATISTIC + "?branch_id=" + this.state.selectedBranchName + "&from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate
   } else {
    var serviceurl = Constants.SPUERADMIN_STATISTIC  + "?from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate
   }

    OpthamologyService.getInstance().getComplaints(
      serviceurl,
      this,
      this.getStatisticDataSuccess,
      this.getStatisticDataFailure
    );
  }
  getStatisticDataSuccess = (response) => {
    if (response && response.status == "success") {
      var data = response.data
      this.setState({
        totalResult: data.total_result,
        inPatientItem: data.in_patient_items,
        outPatientItem: data.invoice_items,
        pharmacyItem: data.pharmacy_bill_items,
        labItem: data.laboratory_items
      })
    }
    else {
      this.props.showResposeValue('error', response.message)
    }
  }
  getStatisticDataFailure = (error) => {
    this.props.showResposeValue('error', error.message)
  }
  // Get Statistic Data API end

  // Date Picker Function start
  getLabelStatus = (status) => {
    this.setState({
      dateFilterstatus: status
    })
 }
  renderDateFilter = () => {
    return (
      <View style={{ marginRight: '1vw' }}>
        <CommonDateFilter
          // removeObject={"all"}
          defaultSelectedDateFilter={"Today"}
          startDate={this.state.filterData.fromDate}
          endDate={this.state.filterData.toDate}
          clearPress={this.state.isclear}
          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
          getLabelStatus = {this.getLabelStatus.bind(this)}
          filterDate={this.state.filterDate} />
      </View>
    )
  }
  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData }, () =>{ 
     this.getStatisticData()
     this.props.getSuperAdminStatistics(this.state.selectedBranchName, this.state.filterData.fromDate, this.state.filterData.toDate, this.state.dateFilterstatus)
    })

  }
  // Date Picker Function end

  // Amount Formet Function start
  DecimalAmount = (Useramount) => {
    var amount = String(Useramount).split(".")[0];
    var AfterDecimal = String(Useramount).split(".")[1]
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
  }
  WholeAmount = (Useramount) => {
    var amount = Useramount;
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  }

  TooltipChange = (value) => {
    if (value % 1 != 0) {
      return this.DecimalAmount(value)
    }
    else if (value % 1 == 0) {
      return this.WholeAmount(value)
    }
  }
  // Amount Formet Function end

  // Card Function start
  renderCard = (title, value, amount) => {
    return (
      <View>
        <View style={styles.InPatientCardView}>
          <View style={styles.CardViewFirst}>
            <Text style={styles.CardSubLabelText}>{title}</Text>
            <Text style={styles.HorizandleLine}></Text>
            <Text style={[styles.CardSubValueText, { color: color.themeDark }]}>{value ? value : 0}</Text>
          </View>
        </View>
        <View style={[styles.InPatientSubCardView, { alignItems: 'center' }]}>
          <Text style={[styles.CardSubValueText2, { fontSize: (amount && (amount + "").length > 7) ? "1.5vw" : '2vw' }]}>₹ {amount ? this.TooltipChange(amount) : 0}</Text>
        </View>
      </View>
    )
  }
  // Card Function end
  renderGrandTotalView = () => {
    var { totalResult } = this.state
    return(
      <View style={styles.CardTotalBillView}>
      <View style={styles.CardViewFirst}>
        <Text style={styles.CardLabelText}>Total Bill Count</Text>
        <Text style={styles.CardValueText}>{totalResult.total_bill ? totalResult.total_bill : 0}</Text>
      </View>
      <View style={styles.CardViewSecond}>
        <Text style={styles.CardLabelText}>Total Revenue</Text>
        <Text style={[styles.CardValueText, { fontSize: (totalResult.total_net_amount + '').length > 7 ? "1.5vw" : '2vw' }]}>₹ {totalResult.total_net_amount ? this.TooltipChange(totalResult.total_net_amount) : 0}</Text>
      </View>
    </View>
    )
  }

  changeTabSection(data) {
    this.setState({
      selectedTab: data
    })
  }


  render() {
    var { totalResult, inPatientItem, outPatientItem, pharmacyItem, labItem } = this.state
    
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"superAdminStatistics"}
          isNavigationBorder={true}
        />
        {/* <View style={styles.StaticHeadView}>
          <Text style={styles.StaticHeadText}> Statistics </Text>
        </View> */}
        <View style={styles.StaticContainerView}>
          <View style={styles.StaticFilter}>
           { this.state.userType === "SUPER_ADMIN" ?
            <Picker
              selectedValue={this.state.billType}
              style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, styles.Pickerselect]}
              itemStyle={{ fontSize: "0.8vw" }}
              onValueChange={(itemvalue) => {
                this.setState({
                  selectedBranchName: itemvalue
                }, () => { 
                this.getStatisticData()
                this.props.getSuperAdminStatistics(this.state.selectedBranchName, this.state.filterData.fromDate, this.state.filterData.toDate, this.state.dateFilterstatus)
              })
              }}
            >
              <Picker.Item label={"All"} value={""} />
              {this.state.branchList && this.state.branchList.length > 0 ? this.state.branchList.map((list) => (
                <Picker.Item label={list.name} value={list.id} />
              )) : null}
            </Picker> : null
           }
            {this.renderDateFilter()}
          </View>
          <View style={styles.StaticCardView}>
            <TouchableWithoutFeedback onPress={() => {
              this.props.changeScreen("superAdminMain", {}, "", {}, "allBillingStatistics")
            }}>
              {this.renderGrandTotalView()}
            </TouchableWithoutFeedback>   
            <TouchableWithoutFeedback onPress={() => {
              this.props.changeScreen("superAdminMain", {}, "", {}, "inPatientBilling")
            }}>
              {this.renderCard("InPatient Billings", inPatientItem.total_bill, inPatientItem.total_net_amount)}
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => {
              this.props.changeScreen("superAdminMain", {}, "", {}, "outPatientBilling")
            }}> 
              {this.renderCard("OutPatient Billings", outPatientItem.total_bill, outPatientItem.total_net_amount)}
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => {
              this.props.changeScreen("superAdminMain", {}, "", {}, "pharmacyBilling")
            }}> 
              {this.renderCard("Pharmacy Billings", pharmacyItem.total_bill, pharmacyItem.total_net_amount)}
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => {
              this.props.changeScreen("superAdminMain", {}, "", {}, "laboratoryBilling")
            }}> 
              {this.renderCard("Laboratory Billings", labItem.total_bill, labItem.total_net_amount)}
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  StaticHeadView: {
    margin: '1vw'
  },
  StaticHeadText: {
    fontSize: '1.5vw',
    fontWeight: '500',
    color: color.themeDark
  },
  StaticContainerView: {
    backgroundColor: color.themeShade,
    height: '80vh',
    width: '91vw',
    margin: '1vw',
    marginTop: '0vw',
    borderRadius: 4,
    shadowColor: "#171717",
    shadowOffset: { width: '0.06vw', height: '0.7vh' },
    shadowOpacity: 0.2,
    shadowRadius: 3
  },
  StaticFilter: {
    flexDirection: 'row',
    width: '90vw',
    margin: '1vw',
    justifyContent: 'flex-end',
    zIndex: 1
  },
  Pickerselect: {
    backgroundColor: color.white,
    height: "2.4vw",
    marginRight: "1vw",
    width: "10vw",
    fontSize: "1vw"
  },
  SearchTouchView: {
    width: '3vw',
    height: '2.8vw',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.themeDark,
    borderRadius: 4,
    margin: '1vw',
    marginTop: '0.2vw'
  },
  StaticCardView: {
    flexDirection: 'row',
    zIndex: 0
  },
  CardTotalBillView: {
    backgroundColor: color.white,
    width: '16vw',
    height: '23vh',
    borderRadius: 4,
    marginLeft: '2vw',
    shadowColor: '#171717',
    shadowOffset: { width: '0.06vw', height: '0.7vh' },
    shadowOpacity: 0.4,
    shadowRadius: 5
  },
  CardLabelText: {
    fontSize: '0.8vw',
    fontWeight: '700'
  },
  CardValueText: {
    fontSize: '2vw',
    fontWeight: '500'
  },
  CardViewFirst: {
    margin: '1vw'
  },
  CardViewSecond: {
    margin: '1vw',
    marginTop: '0vw'
  },
  InPatientCardView: {
    backgroundColor: color.white,
    width: '16vw',
    height: '15vh',
    borderRadius: 4,
    marginLeft: '2vw',
    shadowColor: '#171717',
    shadowOffset: { width: '0.06vw', height: '0.7vh' },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  InPatientSubCardView: {
    backgroundColor: color.white,
    width: '16vw',
    height: '7.2vh',
    borderRadius: 4,
    marginLeft: '2vw',
    marginTop: '0.3vw',
    shadowColor: '#171717',
    shadowOffset: { width: '0.06vw', height: '0.7vh' },
    shadowOpacity: 0.4,
    shadowRadius: 5
  },
  CardSubLabelText: {
    fontSize: '0.8vw',
    fontWeight: '700',
  },
  CardSubValueText: {
    fontSize: '2vw',
    fontWeight: '500',
    alignSelf: 'flex-end',
    alignContent: 'center'
  },
  CardSubValueText2: {
    fontSize: '2vw',
    fontWeight: '500',
    marginTop: '0.5vw'
  },
  HorizandleLine: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: '0.5vw'
  }
})
