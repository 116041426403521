import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  TextInput,
  ScrollView,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { CommonButton, DoctorNotesCommonRightSideHeader,CommonSectionHeader,CommonHistorySectionHeader } from '../BaseComponent'
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import AsyncStorage from "../../../AsyncStorage";

const pageName = "Counseling";

export default class CounselingNew extends BaseComponentStyle {
    constructor(props) {
      super(props);
  
      this.state = {
        isLocked:this.props.isLocked,
        patientDetails: this.props.patientAppointment,
        selectedClinic: this.props.selectedClinic,
        selectedEye: {},
        selectedSurgerySuggest: "",
        selectedPackage: "",
        lensPower: "",
        iolSign: true,
        surgeionName: "",
        anaesthetist: "",
        counsellor: "",
        notes: "",
        suggestionSurgeryList: [],
        isSurgerySuggestion: false,
        isPakageSuggestion: false,
        pakageList: [],
        selectedSurgeryId: null,
        selectedPackageId: null,
        doctorData:[],
        doctorSuggestionData: [],
        isSurgieonDoctorSuggestion: false,
        isAnaesthetistDoctorSuggestion: false,
        drType: ""
      }
    }

    async componentDidMount(){
        this.getDoctorList()
        const userType = await AsyncStorage.getItem("DR_TYPE");
        this.setState({drType: userType})
    }

    getDoctorList = () => {
        var service_url = Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.selectedClinic.id;
        OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getDoctorListSuccess,
        this.getDoctorListFailure
        );
    }

    getDoctorListSuccess = (response) => {
        if(response.status == "success"){
            this.setState({
                doctorData : response.data,
                doctorSuggestionData : response.data
            })
        }
    }

    renderBoxHeader = (header) => {
        return(
            <View>
            {header === "IOL Power" ? 
            <View style={{
                flexDirection: "row",
                justifyContent: "space-between"
              }}>
            <View style={{ width: "80%" }}>
            <CommonHistorySectionHeader
                heading1={header}

                noOfColumn={1}
            />
            </View>
            <View style={{ width: "20%", backgroundColor:"#E2E2E2", justifyContent: "space-evenly", flexDirection: "row", alignItems: 'center', height: "2.27vw" }}>
              <TouchableOpacity 
              onPress={() => {
                var sign = this.state.iolSign
                if(!sign){
                    this.setState({
                        iolSign: true
                    })
                }
              }}
              style={{height: "1.7vw", width:'1.7vw', borderWidth: 1, borderColor: color.themeDark, borderRadius: 3, backgroundColor: (this.state.iolSign) ? color.themeDark : color.white, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: '1.3vw', fontWeight: "bold", color:  (this.state.iolSign) ? color.white : color.themeDark}}>{"+"}</Text>
              </TouchableOpacity>
              <TouchableOpacity 
              onPress={() => {
                var sign = this.state.iolSign
                if(sign){
                    this.setState({
                        iolSign: false
                    })
                }
              }}
              style={{height: "1.7vw", width:'1.7vw', borderWidth: 1, borderColor: color.themeDark, borderRadius: 3, backgroundColor: (this.state.iolSign) ? color.white : color.themeDark, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: '1.3vw', fontWeight: "bold", color:  (this.state.iolSign) ? color.themeDark : color.white}}>{"-"}</Text>
              </TouchableOpacity>
            </View>
            </View>
            :
            <CommonHistorySectionHeader
                heading1={header}

                noOfColumn={1}
            />
            }
            </View>
        )
    }

    searchDoctorSuggestion = (value) => {
        let { doctorSuggestionData } = this.state;
        let tempDescList = this.state.doctorData
        if (tempDescList.length > 0) {
        var searchListTemp = [];
        for (let i = 0; i < tempDescList.length; i++) {

            if ((tempDescList[i].first_name.toLowerCase() + " " + tempDescList[i]?.initial?.toLowerCase()).indexOf(value.toLowerCase()) > -1) {
            searchListTemp.push(tempDescList[i]);
            }
        }
        doctorSuggestionData = searchListTemp;
        } else {
            doctorSuggestionData = this.state.billingDescriptionList;
        }
        this.setState({
            doctorSuggestionData
        })
    }

    renderTextInput = (key) => {
        return(
        <View style={{width: "100%", zIndex: key === "selectedSurgerySuggest" ? 4 : key === "selectedPackage" ? 3 : key === "surgeionName" ? 2 : key === "anaesthetist" ? 1 : 0}}>
            <TextInput 
            style = {[styles.TextFieldTextInput, {backgroundColor: color.white}]}
            editable = {key === "selectedPackage" ? false : true}
            value = {this.state[key]}
            disabled = {this.state.isLocked}
            onFocus={() => {
                if(key === "selectedPackage"){
                    this.setState({
                        isPakageSuggestion: true
                    })
                }
            }}
            onBlur={() => {
                if(key ==="surgeionName"){
                    this.setState({
                        isSurgieonDoctorSuggestion: false
                    }) 
                }else if(key ==="anaesthetist"){
                    this.setState({
                        isAnaesthetistDoctorSuggestion: false
                    }) 
                }else if(key === "selectedPackage"){
                    this.setState({
                        isPakageSuggestion: false
                    })
                }
            }}
            onChangeText = {(text) => {
                if(key == "selectedSurgerySuggest"){
                    this.setState({
                        selectedPackage: "",
                        selectedPackageId: null,
                    })
                    this.surgerySuggestion(text)
                }else if(key === "surgeionName"){
                    if((text).trim() != ""){
                        this.setState({
                            isSurgieonDoctorSuggestion: true
                        })
                        this.searchDoctorSuggestion(text)
                    }else{
                        this.setState({
                            isSurgieonDoctorSuggestion: false
                        })
                    }
                }else if(key === "anaesthetist"){
                    if((text).trim() != ""){
                        this.setState({
                            isAnaesthetistDoctorSuggestion: true
                        })
                        this.searchDoctorSuggestion(text)
                    }else{
                        this.setState({
                            isAnaesthetistDoctorSuggestion: false
                        })
                    }
                }
                var states = this.state
                states[key] = text
                this.setState({
                    states
                })
            }}
            />
            {(this.state.isSurgerySuggestion && this.state.suggestionSurgeryList && (this.state.suggestionSurgeryList).length > 0 && key === "selectedSurgerySuggest") ? 
                <View style={styles.SurgeryView}>
                    <ScrollView
                    style={{
                        flex: 1,
                        backgroundColor: 'white', 
                        borderColor: color.lightGray, 
                        borderWidth: 1, 
                        borderRadius: 4, 
                        paddingHorizontal: '0.6vw',
                    }}
                    >
                        {(this.state.suggestionSurgeryList).map((value,index) => 
                            <View>
                                <TouchableOpacity 
                                key={index}
                                style={{marginTop: '0.6vw'}}
                                onPress={() => {
                                    // this.packageSuggestion(value.id)

                                    this.setState({
                                        selectedSurgerySuggest: "",
                                        selectedPackage: "",
                                        selectedPackageId: null,
                                        selectedSurgeryId: null,
                                    }, () => {
                                        this.setState({
                                            selectedSurgerySuggest: value.surgery_names,
                                            selectedPackage: value.package_name,
                                            selectedSurgeryId: value.id,
                                            selectedPackageId: value.id,
                                            isSurgerySuggestion:false,
                                        })
                                    })
                                }}
                                >
                                    <Text style={{fontSize: '0.9vw'}}>{ value.surgery_names }</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </ScrollView>
                </View>
            : null
            }
            {(this.state.isPakageSuggestion && this.state.pakageList && (this.state.pakageList).length > 0 && key === "selectedPackage") ? 
                <View style={styles.SurgeryView}>
                    <ScrollView
                    style={{
                        flex: 1,
                        backgroundColor: 'white', 
                        borderColor: color.lightGray, 
                        borderWidth: 1, 
                        borderRadius: 4, 
                        paddingHorizontal: '0.6vw',
                    }}
                    >
                        {(this.state.pakageList).map((value,index) => 
                            <View>
                                <TouchableOpacity 
                                key={index}
                                style={{marginTop: '0.6vw'}}
                                onPress={() => {
                                    this.setState({
                                        selectedPackage: "",
                                        selectedPackageId: null,

                                    }, () => {
                                        this.setState({
                                            selectedPackage: value.package_name,
                                            selectedPackageId: value.id,
                                            isPakageSuggestion:false,
                                        })
                                    })
                                    
                                }}
                                >
                                    <Text style={{fontSize: '0.9vw'}}>{ value.package_name }</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </ScrollView>
                </View>
            : null
            }
            {(this.state.isSurgieonDoctorSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 && key === "surgeionName" ) ? 
                        
                        <View style={styles.DoctorSurgeryView}>
                            <ScrollView
                            style={{
                                height: '14vw', marginBottom: '0.6vw', flex: 1,
                                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                            }}
                            >
                                {(this.state.doctorSuggestionData).map((value,index) => 
                                    <View>
                                        <TouchableOpacity 
                                        key={index}
                                        style={{marginTop: '0.6vw'}}
                                        onPress={() => {
                                            var doctorFullName = value.first_name + " " + (value.initial ? value.initial : "")
                                            this.setState({
                                                surgeionName: doctorFullName,
                                                isSurgieonDoctorSuggestion: false
                                            })
                                        }}
                                        >
                                            <Text style={{fontSize: '0.9vw'}}>{ value.first_name + " " + (value.initial ? value.initial : "") }</Text>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </ScrollView>
                        </View>
                        : null
                    
                        }
            {(this.state.isAnaesthetistDoctorSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 && key === "anaesthetist") ? 
                        
                        <View style={styles.DoctorSurgeryView}>
                            <ScrollView
                            style={{
                                height: '14vw', marginBottom: '0.6vw', flex: 1,
                                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                            }}
                            >
                                {(this.state.doctorSuggestionData).map((value,index) => 
                                    <View>
                                        <TouchableOpacity 
                                        key={index}
                                        style={{marginTop: '0.6vw'}}
                                        onPress={() => {
                                            var doctorFullName = value.first_name + " " + (value.initial ? value.initial : "")
                                            this.setState({
                                                anaesthetist: doctorFullName,
                                                isAnaesthetistDoctorSuggestion: false
                                            })
                                        }}
                                        >
                                            <Text style={{fontSize: '0.9vw'}}>{ value.first_name + " " + (value.initial ? value.initial : "") }</Text>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </ScrollView>
                        </View>
                        : null
                    
                        }
        </View>
        )
    }

    onPressButton = (key, selectedvalue) => {
        if(this.state.selectedEye  && this.state.selectedEye.value && selectedvalue.value == this.state.selectedEye.value){
            this.setState({
              selectedEye:{}
            })
          }else{
            this.setState({
              selectedEye:selectedvalue
            })
          }
    }

    surgerySuggestion = (text) => {
        var service_url = Constants.OPT_COUNSELLING_SURGERY_PACKAGE_GET + "?surgery_key=" + text + "&clinic_id=" + this.state.selectedClinic.id;
        OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.surgerySuggestionSuccess,
        this.surgerySuggestionFailure
        );
    }

    surgerySuggestionSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                suggestionSurgeryList: response.data,
                isSurgerySuggestion: true,
            })
        }
    }

    packageSuggestion = (surgeryId) => {
        var service_url = Constants.OPT_COUNSELLING_SURGERY_PACKAGE_GET + "?surgery_id=" + surgeryId + "&clinic_id=" + this.state.selectedClinic.id;
        OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.packageSuggestionSuccess,
        this.packageSuggestionFailure
        );
    }

    packageSuggestionSuccess = (response) => {
        if(response.status === "success") {
            this.setState({
                pakageList: response.data.package_search,
                // isPakageSuggestion: true
            })
        }
    }

    Validation = () => {
        let { lensPower } = this.state
        var surgeion = this.state.doctorData.find((list) => ((list?.first_name ? list.first_name : "") + " " + (list?.initial ? list?.initial : "")) === this.state.surgeionName)
        var Anaesthetist = this.state.doctorData.find((list) => ((list?.first_name ? list.first_name : "") + " " + (list?.initial ? list?.initial : "")) === this.state.anaesthetist)
        // if ((lensPower === "" || lensPower == null) && (this.state.drType === "Ophthalmology")) {
        //     this.props.showResposeValue("error", "Enter IOL Power")
        // } else if (surgeion === "" || surgeion == undefined) {
        //     if (this.state.surgeionName === "") {
        //         this.props.showResposeValue("error", "Please Select the Surgeon")
        //     } else if (surgeion == undefined) {
        //         this.props.showResposeValue("error", "Surgeon Name not found")
        //     }
        // } else if (Anaesthetist === "" || Anaesthetist == undefined) {
        //     if (this.state.anaesthetist === "") {
        //         this.props.showResposeValue("error", "Please select the Anaesthetist")
        //     } else if (Anaesthetist == undefined) {
        //         this.props.showResposeValue("error", "Anaesthetist Name not found")
        //     }
        // } else {
        //     this.addSurgeryCounsellingDetails()
        // }
        if(this.state?.selectedSurgeryId){ // Surgery Name is only Mandatory
            this.addSurgeryCounsellingDetails()
        }else{
            this.props.showResposeValue("error", "Please Select the Surgery")
        }
    }

    addSurgeryCounsellingDetails = () => {
        var states = this.state
        var patientDetails = this.state.patientDetails
        var sign = states.iolSign ? "+" : "-"
        var data = {
            patient_id: patientDetails.patient_id,
            appointment_id: patientDetails.appointment_id,
            clinic_id: states.selectedClinic.id,
            surgery_id: states.selectedSurgeryId,
            package_id: states.selectedPackageId,
            eye_type: states.selectedEye.label,
            surgeon_name: states.surgeionName,
            anaesthetist: states.anaesthetist,
            counsellor_name: states.counsellor,
            notes: states.notes,
            iol_power: (states.lensPower).trim() ? sign + states.lensPower : '',
        }

        var service_url = Constants.OPT_COUNSELLING_DETAIL_POST;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.addSurgeryCounsellingDetailsSuccess,
            this.addSurgeryCounsellingDetailsFailure
        );
    }

    addSurgeryCounsellingDetailsSuccess = (response) => {
        if (response.status === "success") {
            this.props.clearEditedItem(pageName, {})
            this.clearComplaint();
      
            // reload data
            this.props.refreshData("Counseling");
            this.props.showResposeValue("success",response.message)
          }else{
            this.props.showResposeValue("error",response.message)
          }
    }

    clearComplaint = () => {
        this.setState({
            selectedSurgeryId: null,
            selectedSurgerySuggest: "",
            selectedPackageId: null,
            selectedPackage: "",
            selectedEye: {},
            surgeionName: "",
            anaesthetist: "",
            counsellor: "",
            notes: "",
            lensPower: "",
            isPakageSuggestion: false,
            isSurgerySuggestion: false,
        })
    }


    render() {
        return(
                <View>
                <DoctorNotesCommonRightSideHeader title={"Types of Surgery"} />
                {this.renderBoxHeader("Surgery suggested")}
                {this.renderTextInput("selectedSurgerySuggest")}
                {this.renderBoxHeader("Package Details")}
                {this.renderTextInput("selectedPackage")}
                {this.state.drType == "Ophthalmology" ?
                this.renderBoxHeader("Selection eye type"): null }
                {this.state.drType == "Ophthalmology" ?
                <View style={{flexDirection:'row',flexWrap:'wrap',marginVertical:'1vw', justifyContent: "space-around"}}>
                {
                Constants.eye_values.map((item, index) => {
                    return (
                    <View style={{ marginRight: '0.6vw' }} key={index}>
                        <CommonButton
                        item={item}
                        disable={this.state.isLocked}
                        selectedvalue={this.state.selectedEye}
                        butttonText={item.label}
                        buttonType={"outlineNonTheme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={"selectedEye"}
                        />
                    </View>
                    )
                })
                }
                </View> : null
                }
                {this.state.drType == "Ophthalmology" ?
                this.renderBoxHeader("IOL Power") : null }
                {this.state.drType == "Ophthalmology" ?
                this.renderTextInput("lensPower") : null }
                {this.renderBoxHeader("Surgeon name")}
                {this.renderTextInput("surgeionName")}
                {this.renderBoxHeader("Anaesthetist")}
                {this.renderTextInput("anaesthetist")}
                {this.renderBoxHeader("Counsellor")}
                {this.renderTextInput("counsellor")}
                {this.renderBoxHeader("Notes")}
                {this.renderTextInput("notes")}
                <View style={{ marginVertical: 20,alignSelf:"center"}}>
                    <TouchableOpacity disabled = {this.state.isLocked} onPress={() => {this.Validation()}} >
                        {this.renderTextBtn("Add", true, false)}
                    </TouchableOpacity>
                </View >
                </View>
        )
    }
}

const styles = StyleSheet.create({
    TextFieldTextInput: { 
        borderRadius: 4, 
        borderWidth: 1, 
        height: '2.3vw', 
        marginVertical: '1vw', 
        paddingHorizontal: '0.3vw', 
        fontSize: '1vw' 
    },
    SurgeryView: { 
        position: 'absolute', 
        marginLeft: "5%", 
        maxHeight: '14.3vw', 
        width: "90%", 
        marginTop: '3vw' 
    },
    DoctorSurgeryView: { 
        zIndex: 1, 
        position: 'absolute', 
        marginLeft: "5%", 
        maxHeight: '14.3vw', 
        width: "90%", 
        marginTop: '3vw' 
    }
})