import React, { Component } from "react";
import {
  View,
  Text,
  Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  
} from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import SideMenu from "../../SideMenu";
import ReportTab from "../common/Reporttab"


const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;


export default class Reports extends Component {
  
  constructor(props) {
    super(props);
    

    this.state = {
      currentTime: "",
      filterData: {
        fromDate: null,
        toDate: null,
     
      },
     
    };
    
  }



  renderHeaderView = () => {
    let { patientInfo, patientAppointment } = this.state;
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottomColor: "#E4E8F1",
          borderBottomWidth: 1,
          paddingLeft: 10, paddingRight: 10,
          paddingBottom: 10,
          paddingTop: 10
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ alignSelf: "center" }}>
            <Arrow
              name="arrow-left"
              size={24}
              color={color.black}
              onPress={() => this.props.navigation.goBack()}
            />
          </View>
          <View style={{ marginLeft: 20, flexDirection: 'row' }}>
            <Text style={{ fontSize: 16, alignSelf: "center" }}>{patientInfo.name || ""}</Text>
            <Text
              style={{
                fontSize: 10,
                color: "#ADB4C1",
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 10,
                alignSelf: "center"
              }}
            >
              {patientInfo.age || ""} {patientInfo.age ? "|" : null} {patientInfo.dob || ""} {patientInfo.dob ? "|" : null}
              {patientInfo.mobile_number || ""} {patientInfo.mobile_number ? "|" : null} {patientInfo.name ? patientAppointment.appointment_start_time || "" : null}
            </Text>
            <Text style={{ fontSize: 10, color: "#ADB4C1", marginLeft: 10, alignSelf: "center" }}>
              {patientInfo.last_visit_date ? "Last Visit Date :" : null} {patientInfo.last_visit_date || ""}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  renderComponent(){

    var selectedComponent = this.state.

    return(
      <View>

      </View>
    );
  }





  render() {
    
    return (
      <View style={Style.rootView}>
        {/* side menu */}
        <View style={[Style.leftBarView]}>
          <SideMenu navigation={this.props.navigation}  pageName={"patientPage"}/>
        </View>
        <View style={{width: platform === "90%",flex:1}}>
          <View>
            <Text style={{color:color.themeDark,fontSize:19,fontWeight:'700'}}> {"Reports"}  </Text>
          </View>
        </View>
      </View>
    );
  }
}
