import React, { Component } from 'react';
import { View, Text, CheckBox, TextInput } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton, CommonButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";

import Messages from './../../../../utils/InfoMessages' 

const pageName = "HistoryOfUsage"

const categoryList = [
    { label: "Alcohol", value: 1 },
    { label: "Nicotine", value: 2 },
    { label: "Smoke Cannabis", value: 3 },
    { label: "Cocaine", value: 4 },
    { label: "LSD", value: 5 },
    { label: "Intravenous Drugs", value: 6 }

]

const defaultSubstanceData = {
    "id": "",
    "substance_id": "",
    "value":
        [
            {
                "Age at First Use": null,
                "Circumstances of First Use": null,
                "Frequency of Use": null,
                "Quantity of Use": null,
                "History of consumption to current state": null,
                "Timing": null,
                "Brand": null,
                "Craving": null,
                "Tolerance": null,
                "Blackouts": null,
                "Last Usage": null,
                "Additional Notes": null

            }
        ]

};

export class HistoryOfUsageNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            selectedCategorys: [],


            postDataForSelectedCategory: [],


            selectedCategorysInAgeAtFirstUse: [],
            selectedCategorysInCircumstancesofFirstUse: [],
            selectedCategorysInAFrequencyofUse: [],
            selectedCategorysInQuantityofUse: [],
            selectedCategorysInHistoryofconsumption: [],
            selectedCategorysInTiming: [],

            selectedCategorysInBrand: [],
            selectedCategorysInCraving: [],
            selectedCategorysInTolerance: [],
            selectedCategorysInBlackouts: [],
            selectedCategorysInLastUsage: [],
            selectedCategorysInAdditionalNotes: [],

            historyOfUsageResponceFlag:false

        }
    }

    getSelectedHistoryTypesAllSection(data, object) {

        var { selectedCategorysInAgeAtFirstUse, selectedCategorysInCircumstancesofFirstUse, selectedCategorysInAFrequencyofUse, selectedCategorysInQuantityofUse,
            selectedCategorysInHistoryofconsumption, selectedCategorysInTiming, selectedCategorysInBrand, selectedCategorysInCraving, selectedCategorysInTolerance,
            selectedCategorysInBlackouts, selectedCategorysInLastUsage, selectedCategorysInAdditionalNotes } = this.state

        // var selectedCategorysInAgeAtFirstUse = [];
        // var selectedCategorysInCircumstancesofFirstUse = [];
        // var selectedCategorysInAFrequencyofUse = [];
        // var selectedCategorysInQuantityofUse = [];
        // var selectedCategorysInHistoryofconsumption = [];
        // var selectedCategorysInTiming = [];

        // var selectedCategorysInBrand = [];
        // var selectedCategorysInCraving = [];
        // var selectedCategorysInTolerance = [];
        // var selectedCategorysInBlackouts = [];
        // var selectedCategorysInLastUsage = [];
        // var selectedCategorysInAdditionalNotes = [];

        Object.keys(data).forEach((keys) => {
            if (keys == "Age at First Use" && data[keys]) {
                selectedCategorysInAgeAtFirstUse.push(object)
            } else if (keys == "Circumstances of First Use" && data[keys]) {
                selectedCategorysInCircumstancesofFirstUse.push(object)
            } else if (keys == "Frequency of Use" && data[keys]) {
                selectedCategorysInAFrequencyofUse.push(object)
            } else if (keys == "Quantity of Use" && data[keys]) {
                selectedCategorysInQuantityofUse.push(object)
            } else if (keys == "History of consumption to current state" && data[keys]) {
                selectedCategorysInHistoryofconsumption.push(object)
            } else if (keys == "Timing" && data[keys]) {
                selectedCategorysInTiming.push(object)
            }


            else if (keys == "Brand" && data[keys]) {
                selectedCategorysInBrand.push(object)
            } else if (keys == "Craving" && data[keys]) {
                selectedCategorysInCraving.push(object)
            } else if (keys == "Tolerance" && data[keys]) {
                selectedCategorysInTolerance.push(object)
            } else if (keys == "Blackouts" && data[keys]) {
                selectedCategorysInBlackouts.push(object)
            } else if (keys == "Last Usage" && data[keys]) {
                selectedCategorysInLastUsage.push(object)
            } else if (keys == "Additional Notes" && data[keys]) {
                selectedCategorysInAdditionalNotes.push(object)
            }
        })

        this.setState({
            // states,
            // selectedCategorys: selectedDatas,
            // selectedCategorysInAgeAtFirstUse
            selectedCategorysInAgeAtFirstUse, selectedCategorysInCircumstancesofFirstUse, selectedCategorysInAFrequencyofUse, selectedCategorysInQuantityofUse,
            selectedCategorysInHistoryofconsumption, selectedCategorysInTiming, selectedCategorysInBrand, selectedCategorysInCraving, selectedCategorysInTolerance,
            selectedCategorysInBlackouts, selectedCategorysInLastUsage, selectedCategorysInAdditionalNotes
        })
    }

    componentWillReceiveProps(props) {

        if(props.historyOfUsageResponceFlag !== this.state.historyOfUsageResponceFlag ){
            this.setState({
                historyOfUsageResponceFlag:props.historyOfUsageResponceFlag
            })
        }
        

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && props.editHistoryItem.editItem.length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;

                console.log("test  --------> " + JSON.stringify(props.editHistoryItem.editItem))

                // states["id"] = data.id;
                states["postDataForSelectedCategory"] = data;

                // selectedCategorys
                var selectedDatas = [];

                var { selectedCategorysInAgeAtFirstUse, selectedCategorysInCircumstancesofFirstUse, selectedCategorysInAFrequencyofUse, selectedCategorysInQuantityofUse,
                    selectedCategorysInHistoryofconsumption, selectedCategorysInTiming, selectedCategorysInBrand, selectedCategorysInCraving, selectedCategorysInTolerance,
                    selectedCategorysInBlackouts, selectedCategorysInLastUsage, selectedCategorysInAdditionalNotes } = this.state

                // var selectedCategorysInAgeAtFirstUse = [];
                // var selectedCategorysInCircumstancesofFirstUse = [];
                // var selectedCategorysInAFrequencyofUse = [];
                // var selectedCategorysInQuantityofUse = [];
                // var selectedCategorysInHistoryofconsumption = [];
                // var selectedCategorysInTiming = [];

                // var selectedCategorysInBrand = [];
                // var selectedCategorysInCraving = [];
                // var selectedCategorysInTolerance = [];
                // var selectedCategorysInBlackouts = [];
                // var selectedCategorysInLastUsage = [];
                // var selectedCategorysInAdditionalNotes = [];


                this.setState({
                    selectedCategorysInAgeAtFirstUse: [],
                    selectedCategorysInCircumstancesofFirstUse: [],
                    selectedCategorysInAFrequencyofUse: [],
                    selectedCategorysInQuantityofUse: [],
                    selectedCategorysInHistoryofconsumption: [],
                    selectedCategorysInTiming: [],

                    selectedCategorysInBrand: [],
                    selectedCategorysInCraving: [],
                    selectedCategorysInTolerance: [],
                    selectedCategorysInBlackouts: [],
                    selectedCategorysInLastUsage: [],
                    selectedCategorysInAdditionalNotes: [],
                }, () => {
                    for (let i = 0; i < data.length; i++) {
                        var getValues = data[i].value[0];

                        if (data[i].substance_id == 1) {
                            selectedDatas.push({ label: "Alcohol", value: 1 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "Alcohol", value: 1 })


                        } else if (data[i].substance_id == 2) {
                            selectedDatas.push({ label: "Nicotine", value: 2 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "Nicotine", value: 2 })
                        } else if (data[i].substance_id == 3) {
                            selectedDatas.push({ label: "Smoke Cannabis", value: 3 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "Smoke Cannabis", value: 3 })
                        } else if (data[i].substance_id == 4) {
                            selectedDatas.push({ label: "Cocaine", value: 4 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "Cocaine", value: 4 })

                        } else if (data[i].substance_id == 5) {
                            selectedDatas.push({ label: "LSD", value: 5 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "LSD", value: 5 })

                        } else if (data[i].substance_id == 6) {
                            selectedDatas.push({ label: "Intravenous Drugs", value: 6 })
                            this.getSelectedHistoryTypesAllSection(getValues, { label: "Intravenous Drugs", value: 6 })

                        }

                    }
                })





                // postDataForSelectedCategory

                this.setState({
                    states,
                    selectedCategorys: selectedDatas,
                    // selectedCategorysInAgeAtFirstUse:selectedCategorysInAgeAtFirstUse
                }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }

    clearAllData() {
        var states = this.state;
        // states["id"] = ""
        this.setState({
            selectedCategorys: [],


            postDataForSelectedCategory: [],


            selectedCategorysInAgeAtFirstUse: [],
            selectedCategorysInCircumstancesofFirstUse: [],
            selectedCategorysInAFrequencyofUse: [],
            selectedCategorysInQuantityofUse: [],
            selectedCategorysInHistoryofconsumption: [],
            selectedCategorysInTiming: [],

            selectedCategorysInBrand: [],
            selectedCategorysInCraving: [],
            selectedCategorysInTolerance: [],
            selectedCategorysInBlackouts: [],
            selectedCategorysInLastUsage: [],
            selectedCategorysInAdditionalNotes: [],
        })
    }

    onPressAddButton() {
        var states = this.state;

        // alert(this.state.historyOfUsageResponceFlag)

        // alert("selectedCategorys ----------" + JSON.stringify(this.state.postDataForSelectedCategory.length))
        // alert("selectedCategorys ----------" + JSON.stringify(this.state.postDataForSelectedCategory))


        

        var service_url = Constants.PSY_PHYSICAL_HISTORY_OF_USAGE

        var data = {

            "appointment_id": this.state.patientAppointment.appointment_id,
            "patient_id": this.state.patientAppointment.patient_id,
            "substance_data": this.state.postDataForSelectedCategory
        }

        if(this.state.historyOfUsageResponceFlag){
            this.props.showResposeValue("error", Messages.HistoryOfUsageEditErrorMessage) 
        }else{
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveHistoryOfUsageSuccess,
            this.saveHistoryOfUsageFailure
        );
        }

        

    }

    saveHistoryOfUsageSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveHistoryOfUsageFailure = error => {
        this.props.showResposeValue("error", success.message)
    }


    renderCategorys() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {
                    categoryList.map((item, index) => {
                        return this.renderCheckBox(item, index)
                    })
                }
            </View>
        )
    }



    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    multiselectButtonFunction(key, selectedValue, showTextBox = false) {
        //  selectedCategorys 

        var getStates = this.state;

        var states = getStates[key]

        var getSelectedValue;
        var spliceData;

        if (states.length > 0) {
            for (var i = 0; i < states.length; i++) {
                if (states[i].value == selectedValue.value) {
                    spliceData = states[i];
                    states.splice(i, 1)
                    if (key == "selectedCategorys") {
                        getStates["postDataForSelectedCategory"].splice(i, 1)
                        // this.addNewSubstanceAbuse()
                    }
                    var data = this.removeDuplicate(states);


                    // // alert(JSON.stringify(states))
                    // getStates = states

                    //  states =  data.sort((a, b) => {
                    //     return a.value - b.value;
                    // });

                    states = data;


                    this.setState({ getStates })
                    return
                } else {
                    if (spliceData && spliceData.value !== selectedValue.value) {
                    } else {
                        getSelectedValue = selectedValue
                    }
                }
            }

            if (getSelectedValue) {
                states.push(selectedValue);

            }
        } else {
            states.push(selectedValue)
        }

        var data = this.removeDuplicate(states);
        // states =  data.sort((a, b) => {
        //     return a.value - b.value;
        // });
        // // alert(JSON.stringify(states))

        // getStates = states


        states = data;
        this.setState({ getStates }, () => {

            if (key == "selectedCategorys") {
                var datas = this.state.selectedCategorys.sort((a, b) => {
                    return a.value - b.value;
                });
                this.setState({
                    selectedCategorys: datas
                }, () => {
                    this.addNewSubstanceAbuse()
                })


            }

        })

    }


    addNewSubstanceAbuse() {


        var preparePostDataFormet = [];

        if (this.state.selectedCategorys.length > 0) {
            for (let i = 0; i < this.state.selectedCategorys.length; i++) {

                var prepareData = {
                    "id": "",
                    "substance_id": "",
                    "value":
                        [
                            {
                                "Age at First Use": null,
                                "Circumstances of First Use": null,
                                "Frequency of Use": null,
                                "Quantity of Use": null,
                                "History of consumption to current state": null,
                                "Timing": null,
                                "Brand": null,
                                "Craving": null,
                                "Tolerance": null,
                                "Blackouts": null,
                                "Last Usage": null,
                                "Additional Notes": null

                            }
                        ]

                };
                prepareData["substance_id"] = this.state.selectedCategorys[i].value;
                prepareData["id"] = this.state.selectedCategorys[i].substance_data;

                preparePostDataFormet.push(prepareData)
            }


        }

        var datas = preparePostDataFormet.sort((a, b) => {
            return a.substance_id - b.substance_id;
        });

        // alert(JSON.stringify(datas))

        this.setState({
            postDataForSelectedCategory: datas
        }, () => {

        })

    }

    validateSelectedCategory(item) {
        var { selectedCategorys } = this.state;
        var returnFlag = false;
        if (selectedCategorys.length > 0) {
            for (let i = 0; i < selectedCategorys.length; i++) {

                if (selectedCategorys[i].value == item.value) {
                    returnFlag = true
                    return returnFlag
                }

            }
        } else {
            returnFlag = false
            return returnFlag
        }
        return returnFlag
    }
    // selectedCategorys

    renderCheckBox(item, index) {
        return (
            <View key={index} style={{ flexDirection: 'row', marginRight: 30, marginBottom: 15 }}>
                <CheckBox value={this.validateSelectedCategory(item)}
                    onValueChange={() => {
                        this.multiselectButtonFunction("selectedCategorys", item)
                    }}
                />
                <Text style={{ marginLeft: 10, fontSize: 12, color: "#888888" }}>{item.label}</Text>
            </View>
        );
    }

    onPressCategoryButton(key, value) {
        this.multiselectButtonFunction(key, value, true)
    }


    getTextBoxValues(item, textBoxkey) {
        var { postDataForSelectedCategory } = this.state;
        var textBoxvalue = ""
        if (postDataForSelectedCategory.length > 0) {
            for (let i = 0; i < postDataForSelectedCategory.length; i++) {
                if (postDataForSelectedCategory[i].substance_id == item.value) {
                    textBoxvalue = postDataForSelectedCategory[i].value[0][textBoxkey]
                    return textBoxvalue
                }

            }
        }
        return textBoxvalue
    }

    renderCardView(cardLabel, selectBtnKey, selectedValues, textBoxKey) {
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginVertical: 5 }]}>

                <View style={[Style.psyHistoryYesOrNOSectionRowView, Style.allButtonBorderRadius]}>
                    <Text style={{ marginLeft: 15, fontSize: 14 }}>{cardLabel}</Text>
                </View>

                {
                    selectBtnKey ?

                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                this.state.selectedCategorys && this.state.selectedCategorys.length > 0 ?
                                    this.state.selectedCategorys.map((item, index) => {
                                        return (
                                            <View key={index} style={{ marginLeft: 15, marginBottom: 8 }}>
                                                <CommonButton
                                                    item={item}
                                                    selectedvalue={selectedValues}
                                                    butttonText={item.label}
                                                    buttonType={"outlineNonTheme"}
                                                    buttonIcon={""}
                                                    rightIcon={false}
                                                    buttonAction={this.onPressCategoryButton.bind(this)}
                                                    buttonKey={selectBtnKey} />
                                            </View>
                                        )
                                    })
                                    : null
                            }

                        </View>

                        :
                        <View />
                }

                {
                    selectedValues && selectedValues.length > 0 ?
                    selectedValues&&selectedValues.map((item, index) => {
                            return (
                                <View key={index} style={{ marginVertical: 5, marginHorizontal: 15 }}>
                                    <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{item.label}</Text>
                                    {
                                        <TextInput


                                            // textBoxKey

                                            style={{
                                                // alignContent:'center',
                                                // alignItems:'center',
                                                // alignSelf:'center',
                                                // justifyContent:'center',
                                                borderColor: "#CDD1D5",
                                                borderRadius: 4,
                                                borderWidth: 1,
                                                width: "100%",
                                                height: 35,
                                                padding: 15,
                                                marginTop: 8
                                            }}
                                            maxLength={100}
                                            value={this.getTextBoxValues(item, textBoxKey)}
                                            onChangeText={(text) => {
                                                var { postDataForSelectedCategory } = this.state;

                                               
                                                    if (postDataForSelectedCategory.length > 0) {
                                                        for (let i = 0; i < postDataForSelectedCategory.length; i++) {
                                                            if (postDataForSelectedCategory[i].substance_id == item.value) {
                                                                postDataForSelectedCategory[i].value[0][textBoxKey] = textBoxKey == "Age at First Use"?text.replace(/[^.[0-9]]*/g , ''):text
                                                            }

                                                        }
                                                    }
                                                    this.setState({
                                                        postDataForSelectedCategory
                                                    })
                                               

                                               

                                            }}
                                        />
                                    }

                                </View>
                            )
                        })
                        : null
                }

            </View>
        )
    }
    


    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"History Of Usage"} clearAllData={this.clearAllData.bind(this)} />

                {this.renderCategorys()}


                {this.renderCardView("Age at First Use", "selectedCategorysInAgeAtFirstUse", this.state.selectedCategorysInAgeAtFirstUse, "Age at First Use")}
                {this.renderCardView("Circumstances of First Use", "selectedCategorysInCircumstancesofFirstUse", this.state.selectedCategorysInCircumstancesofFirstUse, "Circumstances of First Use")}
                {this.renderCardView("Frequency of Use", "selectedCategorysInAFrequencyofUse", this.state.selectedCategorysInAFrequencyofUse, "Frequency of Use")}
                {this.renderCardView("Quantity of Use", "selectedCategorysInQuantityofUse", this.state.selectedCategorysInQuantityofUse, "Quantity of Use")}
                {this.renderCardView("History of consumption to current state", "selectedCategorysInHistoryofconsumption", this.state.selectedCategorysInHistoryofconsumption, "History of consumption to current state")}
                {this.renderCardView("Timing", "selectedCategorysInTiming", this.state.selectedCategorysInTiming, "Timing")}

                {this.renderCardView("Brand", "selectedCategorysInBrand", this.state.selectedCategorysInBrand, "Brand")}
                {this.renderCardView("Craving", "selectedCategorysInCraving", this.state.selectedCategorysInCraving, "Craving")}
                {this.renderCardView("Tolerance", "selectedCategorysInTolerance", this.state.selectedCategorysInTolerance, "Tolerance")}
                {this.renderCardView("Blackouts", "selectedCategorysInBlackouts", this.state.selectedCategorysInBlackouts, "Blackouts")}
                {this.renderCardView("Last Usage", "selectedCategorysInLastUsage", this.state.selectedCategorysInLastUsage, "Last Usage")}
                {this.renderCardView("Additional Notes", "selectedCategorysInAdditionalNotes", this.state.selectedCategorysInAdditionalNotes, "Additional Notes")}
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
