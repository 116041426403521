import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import {CommonHistorySectionHeader,CommonSectionHeader} from '../BaseComponent'
import { CommonButton, DoctorNotesCommonRightSideHeader } from '../BaseComponent'
import Styles  from "../../../styles/Style";
import { connect } from "react-redux";
import {updateMultiAxialDropDownFlag} from '../../../Redux/appDropdownActions';
// import SelectedButton from "../../../components/SelectedButton";


// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "assessment";
var Messages=require('../../../utils/InfoMessages')
const toastConfig = {
  'success': (internalState) => (
    <View style={{ height: 30, width: '100%', backgroundColor: 'green' }}>
      <Text style={{ color: "white", textAlign: "center", justifyContent: "center", marginTop: "9px" }}>Complaint Created Successfully</Text>
    </View>
  ),
  'error': () => { },
  'info': () => { },
  'any_custom_type': () => { }
}



 class ComplaintsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: "",
      patientAppointment: this.props.patientAppointment,
      selectedEye: "",
      // ShortDescriptionText: "",
      // selectedLongDescriptionText: "",
      // isShortDesc: false,
      // isLongDesc: false,
      // newItemDesc: '',
      // newItemLongDesc: '',
      // isICDDescView: false,
      // isICDLongDescView: false,
      complaints: "",
      selectedDuration: "",
      comments: "",
      ProvisionalComment: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,


      ICDCodeNewText: '',
      ICDCodeFavoriteDrugs: [],
      ICDCodeIsEdit: false,
      ICDCodeSearchList: [],
      ICDCodeViewFlag: false,
      selectedICDCode: "",

      longDescriptionNewText: '',
      longDescriptionFavoriteDrugs: [],
      longDescriptionIsEdit: false,
      longDescriptionSearchList: [],
      longDescriptionViewFlag: false,
      selectedLongDescription: "",

      shortDescriptionNewText: '',
      shortDescriptionFavoriteDrugs: [],
      shortDescriptionIsEdit: false,
      shortDescriptionSearchList: [],
      shortDescriptionViewFlag: false,
      selectedShortDescription: "",

      tempId:"",

      clinicPsychiatrySyndrome:"",
      specificDelaysOfDevelopment:"",
      generalIntellectualFunctioning:"",
      medicalProblem:"",
      abnormalFamily:"",
      // psyId:"",

      userType:this.props.userType,

      // multiAxialAutoSuggestList:[],
      validateTextBox:"",

      multiAxialID:"",

    };
  }




  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getAssessmentFrequent()
  }

  componentWillReceiveProps(props) {
    //  alert(JSON.stringify(props.editItem))
    if (props.editItem) {
      let { id,tempId,multiAxialID } = this.state;
      if (props.editItem.title === pageName) {

        var item =props.editItem.item
        if(tempId !== item.tempId   && item.tempId){

          var states = this.state;

          states["ProvisionalComment"]=item.provisionalDiagnosis
          states["comments"]=item.advice

          states["selectedICDCode"]=item.icd_code
          states["selectedLongDescription"]=item.desc
          states["selectedShortDescription"]=item.short_desc
          states["tempId"]=item.tempId;
          states["id"]=item.id;
          this.setState({ states },()=>{
            this.props.clearEditedItem(pageName,{})
          })
        }



        if(item.id && item){
          var states = this.state;
          // clinicPsychiatrySyndrome:"",
          // specificDelaysOfDevelopment:"",
          // generalIntellectualFunctioning:"",
          // medicalProblem:"",
          // abnormalFamily:"",
          states["multiAxialID"]=item.id;
          states["clinicPsychiatrySyndrome"]=item.axis_one_clinical_psy_syndrome;
          states["specificDelaysOfDevelopment"]=item.axis_two_specific_delays_of_development;
          states["generalIntellectualFunctioning"]=item.axis_three_general_intelluctual_functioning;
          states["medicalProblem"]=item.axis_four_medical_problem;
          states["abnormalFamily"]=item.axis_five_abnormal_family_or_psy_socail_situations;
          this.setState({
            states
          },()=>{
            this.props.clearEditedItem(pageName,{})
          })
        } 
        // alert(JSON.stringify(props.editItem))
        
      }
    }
  }

  getAssessmentFrequent = () => {

    var service_url = Constants.ASSESSMENT_FREQUANT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAssessmentFrequentSuccess,
      this.getAssessmentFrequentFailure
    );
  };

  getAssessmentFrequentSuccess = response => {
    if (response.status === "success") {

      var field = this.state;
      field["ICDCodeFavoriteDrugs"] = response.data;
      field["shortDescriptionFavoriteDrugs"] = response.data;
      field["longDescriptionFavoriteDrugs"] = response.data;
      this.setState({ field });

    }
  };

  getAssessmentFrequentFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };


  triggerNewItemAdd = (type, event) => {

    let states = this.state;
    if (type == "icdCode")
      states['ICDCodeIsEdit'] = true;
    else if (type == "longDescription")
      states['longDescriptionIsEdit'] = true;
    else if (type == "shortDescription")
      states['shortDescriptionIsEdit'] = true;

    this.setState({ states });
  }

  addMedicationToList = (type, event) => {



    // let { ICDCodeNewText, ICDCodeFavoriteDrugs, newItemDesc } = this.state;
    let field = this.state;

    if (type == "icdCode") {
      field["ICDCodeNewText"] = "";
      field["ICDCodeIsEdit"] = false;
    } else if (type == "longDescription") {
      field["longDescriptionNewText"] = "";
      field["longDescriptionIsEdit"] = false;
    } else if (type == "shortDescription") {
      field["shortDescriptionNewText"] = "";
      field["shortDescriptionIsEdit"] = false;
    }

    this.setState({ field });
  }



  icdCodeSearch = (query,type) => {

    if (query !== "") {
      var serviceUrl =""

      if(type=="ICDCode")
       serviceUrl = Constants.ASSESSMENT_ICD_CODE_GET+ "?icd_code=" + query;
      else if(type=="ShortDescription")
       serviceUrl = Constants.ASSESSMENT_ICD_CODE_GET+ "?desc=" + query;
      //  else if(type=="LongDescription")
      //  serviceUrl = Constants.ASSESSMENT_ICD_CODE_GET+ "?desc=" + query;


      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.icdCodeSearchSuccess,
        this.icdCodeSearchFailure
      );
    } else {


      var fields = this.state;

      if (fields.ICDCodeIsEdit)
        fields["ICDCodeSearchList"] = [];
      else if (fields.longDescriptionIsEdit)
        fields["longDescriptionSearchList"] = []
      else if (fields.shortDescriptionIsEdit)
        fields["shortDescriptionSearchList"] = []


      this.setState({
        fields,
        ICDCodeViewFlag: false,
        longDescriptionViewFlag: false,
        shortDescriptionViewFlag: false
      });
    }
  };

  icdCodeSearchSuccess = response => {

    var fields = this.state;

    if (fields.ICDCodeIsEdit)
      fields["ICDCodeSearchList"] = response.data;
    else if (fields.longDescriptionIsEdit)
      fields["longDescriptionSearchList"] = response.data;
    else if (fields.shortDescriptionIsEdit)
      fields["shortDescriptionSearchList"] = response.data;

    this.setState({
      fields,
    });
  };

  icdCodeSearchFailure = error => {
   // console.log("search patient error response");
    console.log(error);
  }






  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <TouchableOpacity onPress={() => {      
        this.onPressItem(selectedKey,
          selectedKey == "selectedICDCode" ? item.icd_code :
            selectedKey == "selectedLongDescription" ? item.icd_desc :
              selectedKey == "selectedShortDescription" ? item.icd_short_desc : null,

          item
        )
      }
      } disabled={this.state.isLocked}>
        <View
          style={[{
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: 30,
            flexDirection: "row",
            borderColor: (this.state.selectedICDCode === item.icd_code) && (this.state.selectedLongDescription === item.icd_desc) && (this.state.selectedShortDescription === item.icd_short_desc) ? color.themeDark : "#888888",
            borderWidth: 1,
            backgroundColor: 

              this.state.isLocked ? color.disableComponentColor
              :
              (this.state.selectedICDCode === item.icd_code) && 
              (this.state.selectedLongDescription === item.icd_desc) && 
              (this.state.selectedShortDescription === item.icd_short_desc) ? color.themeDark : color.themeShadeBackground,
            paddingTop: '0.5vw',
            paddingLeft: '1.1vw',
            paddingRight: '1.1vw',
            paddingBottom: '0.5vw',
            marginBottom: '0.6vw',
            marginRight: '0.3vw'
          },Styles.allButtonBorderRadius]}
        >
          <Text
            style={{
              fontSize: fill ? '1.3vw' : '0.8vw', //12
              textAlign: "center",
              color: (this.state.selectedICDCode === item.icd_code) && (this.state.selectedLongDescription === item.icd_desc) && (this.state.selectedShortDescription === item.icd_short_desc) ? color.white : color.themeBlue,
              fontWeight: "400" //500
            }}
          >
            {selectedKey == "selectedICDCode" ? item.icd_code :
              selectedKey == "selectedLongDescription" ? item.icd_desc :
                selectedKey == "selectedShortDescription" ? item.icd_short_desc : null
            }


          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  onPressItem = (key, value, item) => {

    let states = this.state;
    // states[key] = value;
    // if(key == "selectedICDCode")
    states["selectedICDCode"] = item.icd_code;
    // if(key == "selectedLongDescription")
    states["selectedLongDescription"] = item.icd_desc;
    // if(key == "selectedShortDescription")
    states["selectedShortDescription"] = item.icd_short_desc;

    this.setState({
      states
    })
  }

  addAssessment(){
    // alert(this.state.userType)
    if(this.state.userType === "PSY"){ 
    this.addPsychiatryMultiAxialDiagnosis()
   }
    var data = {
      assessment:{
        "icd_code": this.state.selectedICDCode,
        "short_desc": this.state.selectedShortDescription,
        "desc": this.state.selectedLongDescription,
      },
      // "advice_given": this.state.comments,
      "provisional_diagnosis": this.state.ProvisionalComment
    }

    if (this.state.id) {
      data.assessment['id'] = this.state.id;
    }

    if (this.state.tempId) {
      data.assessment['tempId'] = this.state.tempId;
    }else{
      data.assessment['tempId'] = "";
    }
    //this.props.getAssessmentData(data);


    if(this.state.selectedICDCode || this.state.ProvisionalComment){
      this.props.getAssessmentData(data);
    }else{


      if(this.state.userType === "PSY"){ 


      }else{
        this.props.showResposeValue("error",Messages.AssessmentICDCode)
      }

      

    }



    this.clearAssessment();

  };

  clearAssessment = event => {
    let states = this.state;
    states['selectedICDCode'] = "";
    states['selectedShortDescription'] = "";

    states['selectedLongDescription'] = "";
    states['comments'] = "";
    states['ProvisionalComment'] = "";

    states['tempId'] = "";

    this.setState({ states },()=>{

    });

  }

  removeDuplicates(data) {

    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  addFavoriteDrugsList(item, type) {
    this.state.ICDCodeFavoriteDrugs.push(item);
    this.state.longDescriptionFavoriteDrugs.push(item);
    this.state.shortDescriptionFavoriteDrugs.push(item);

    var icdCode = this.state.ICDCodeFavoriteDrugs;
    var shortDescription = this.state.shortDescriptionFavoriteDrugs;
    var longDescription = this.state.longDescriptionFavoriteDrugs;
    let selectedValues = this.state;

    selectedValues["selectedICDCode"] = item.icd_code;
    selectedValues["selectedShortDescription"] = item.icd_short_desc;
    selectedValues["selectedLongDescription"] = item.icd_desc;

    let resetValues = this.state;

    if (type == "shortDescription") {
      resetValues["shortDescriptionNewText"] = "";
      resetValues["shortDescriptionSearchList"] = [];
      resetValues["shortDescriptionViewFlag"] = true;
      resetValues["shortDescriptionIsEdit"] = false;

      resetValues["ICDCodeFavoriteDrugs"] = this.removeDuplicates(icdCode);
      resetValues["shortDescriptionFavoriteDrugs"] = this.removeDuplicates(shortDescription);
      resetValues["longDescriptionFavoriteDrugs"] = this.removeDuplicates(longDescription);

    } else if (type == "longDescription") {
      resetValues["longDescriptionNewText"] = "";
      resetValues["longDescriptionSearchList"] = [];
      resetValues["longDescriptionViewFlag"] = true;
      resetValues["longDescriptionIsEdit"] = false;

      resetValues["ICDCodeFavoriteDrugs"] = this.removeDuplicates(icdCode);
      resetValues["shortDescriptionFavoriteDrugs"] = this.removeDuplicates(shortDescription);
      resetValues["longDescriptionFavoriteDrugs"] = this.removeDuplicates(longDescription);

    } else if (type == "icdCode") {
      resetValues["ICDCodeNewText"] = "";
      resetValues["ICDCodeSearchList"] = [];
      resetValues["ICDCodeViewFlag"] = true;
      resetValues["ICDCodeIsEdit"] = false;

      resetValues["ICDCodeFavoriteDrugs"] = this.removeDuplicates(icdCode);
      resetValues["shortDescriptionFavoriteDrugs"] = this.removeDuplicates(shortDescription);
      resetValues["longDescriptionFavoriteDrugs"] = this.removeDuplicates(longDescription);

    }

    this.setState({

      selectedValues,
      resetValues
    });





  }
  addPsychiatryMultiAxialDiagnosis=()=>{
      var states= this.state
    var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "axis_one_clinical_psy_syndrome":states.clinicPsychiatrySyndrome,
      "axis_two_specific_delays_of_development": states.specificDelaysOfDevelopment,
      "axis_three_general_intelluctual_functioning":states.generalIntellectualFunctioning,
      "axis_four_medical_problem": states.medicalProblem,
      "axis_five_abnormal_family_or_psy_socail_situations": states.abnormalFamily,
      "motivation_type": 0,
      "motivation_type_desc": 0,
      "description": 0,
      "advice": 0
    }
    if(states.multiAxialID){
      data["id"]=states.multiAxialID
    }


    var service_url=Constants.PSY_DIAGNOSIS_MULTI_AXIAL_POST 
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addPsychiatryMultiAxialDiagnosisSuccess,
      this.addPsychiatryMultiAxialDiagnosisFailure
    );
  }
  addPsychiatryMultiAxialDiagnosisSuccess=(success)=>{
      if(success.status=="success"){
        // this.setState({
        //   states
        // })
        this.getPsychiatryDetails()
        this.props.refreshData(pageName);
        this.props.showResposeValue('success',success.message)
      }
  }
  addPsychiatryMultiAxialDiagnosisFailure=(error)=>{
    this.props.showResposeValue('error',error.message)

  }

  render() {
    return (
 
    //  <TouchableWithoutFeedback
    //    onPress={()=>{
    //    this.props.updateMultiAxialDropDownFlag(false)

    //     }}>
      <View> 
        <DoctorNotesCommonRightSideHeader title={"Diagnosis"} clearAllData={this.clearAssessment.bind(this)} />


        <View style={{ zIndex: 1 }}>
          <View style={styles.ShortDescriptionView}>
          <CommonSectionHeader heading={"Short Description"}  />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Short Description</Text> */}
          </View>

          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' ,marginHorizontal:'1.2vw'}}>
            {
              this.state.shortDescriptionFavoriteDrugs && this.state.shortDescriptionFavoriteDrugs.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedShortDescription', this.state.selectedShortDescription, index)
              })
            }
          </View>

          {this.state.shortDescriptionIsEdit ?
            <View style={{ zIndex: 10, height: '2vw', }} >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={"black"}
                style={[{
                  backgroundColor: color.white,
                  borderWidth: 1,
                  // borderRadius: 30,
                  width: '10.5vw',
                  color: "black",
                  height: '2vw',
                  paddingLeft: '0.3vw',
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                  fontSize: '0.9vw',
                  borderColor: color.rightPanelInputBorder
                },Styles.allButtonBorderRadius]}
                autoCapitalize="none"
                value={this.state.shortDescriptionNewText}
                onChangeText={text => {
                  this.setState({ shortDescriptionNewText: text })
                  this.icdCodeSearch(text,"ShortDescription")
                }}
                onSubmitEditing={this.addMedicationToList.bind(this, 'shortDescription')}
              />
              {this.state.shortDescriptionViewFlag === false ?
                <View style={[styles.shortDescriptionViewFlagView, {backgroundColor: this.state.shortDescriptionNewText ? color.white : "",
                  maxHeight: this.state.shortDescriptionNewText ? "" : 0,}]}>
                  <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.4vw' }}>
                    <FlatList
                      data={this.state.shortDescriptionSearchList}
                      renderItem={({ item }) => (
                        <View style={styles.shortDescriptionViewFlagView1}
                        >
                          <View style={{ width: '100%' }}>
                            <TouchableOpacity
                              onPress={() => {
                                this.addFavoriteDrugsList(item, "shortDescription");
                              }
                              }>
                              <Text style={styles.shortDescriptionViewFlagText}>{item.icd_short_desc}</Text>
                            </TouchableOpacity>
                          </View>

                        </View>
                      )}
                      enableEmptySections={true}
                    />
                  </ScrollView>
                </View>
                : null}
            </View>
            : <View style={{marginLeft: '1.2vw'}}>{ ( this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this, "shortDescription"),this.state.isLocked))}</View>}
        </View>


        <View style={{ zIndex: -2 }}>
          <View style={styles.longDescriptionView}>
          <CommonSectionHeader heading={"Long Description"}  />
            {/* <Text style={{ fontSize: 15, color: "black" }}>Long Description</Text> */}
          </View>
          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' ,marginLeft:'1.2vw'}}>
            {
              this.state.longDescriptionFavoriteDrugs && this.state.longDescriptionFavoriteDrugs.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedLongDescription', this.state.selectedLongDescription, index)
              })
            }
          </View>
          {/* {this.state.longDescriptionIsEdit ?
            <View style={{ height: 30, zIndex: 10 }} >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={"black"}
                style={[{
                  backgroundColor: color.white,
                  borderWidth: 1,
                  // borderRadius: 30,
                  width: 150,
                  color: "black",
                  height: 30,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                  borderColor: color.rightPanelInputBorder
                },Styles.allButtonBorderRadius]}
                autoCapitalize="none"
                value={this.state.longDescriptionNewText}
                onChangeText={text => {
                  this.setState({ longDescriptionNewText: text })
                  this.icdCodeSearch(text,"LongDescription")
                }}
                onSubmitEditing={this.addMedicationToList.bind(this, 'longDescription')}
              />
              {this.state.longDescriptionViewFlag === false ?
                <View style={{
                  backgroundColor: this.state.longDescriptionNewText ? color.white : "",
                  maxHeight: this.state.longDescriptionNewText ? "" : 0,
                  borderRadius: 10,
                  width: '90%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  padding: 10,
                  marginLeft: 10,
                  marginTop: 30,
                  position: 'absolute'
                }}>
                  <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                    <FlatList
                      data={this.state.longDescriptionSearchList}
                      renderItem={({ item }) => (
                        <View style={{
                          flexDirection: "row",
                          bottom: 10,
                          marginBottom: 10,
                          justifyContent: "space-evenly",
                          width: "100%",
                          alignItems: "center",
                          marginTop: 10,
                          zIndex: 10,
                        }}
                        >
                          <View style={{ width: '100%' }}>
                            <TouchableOpacity
                              onPress={() => {
                                this.addFavoriteDrugsList(item, "longDescription");
                              }
                              }>
                              <Text>{item.icd_desc}</Text>
                            </TouchableOpacity>
                          </View>

                        </View>
                      )}
                      enableEmptySections={true}
                    />
                  </ScrollView>
                </View>
                : null}
            </View>
            : this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this, "longDescription"),this.state.isLocked)} */}
        </View>


        <View style={{ zIndex: -3 }}>
          <View style={styles.longDescriptionView}>
          <CommonSectionHeader heading={"ICD 10 Codes"}  />
            {/* <Text style={{ fontSize: 15, color: "black" }}>ICD 10 Codes</Text> */}
          </View>
          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap',marginLeft:'1.2vw' }}>
            {
              this.state.ICDCodeFavoriteDrugs && this.state.ICDCodeFavoriteDrugs.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedICDCode', this.state.selectedICDCode, index)
              })
            }
          </View>

          {this.state.ICDCodeIsEdit ?
            <View style={{ height: '2vw', zIndex: 10 }} >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={"black"}
                style={[{
                  backgroundColor: color.white,
                  borderWidth: 1,
                  // borderRadius: 30,
                  width: '10.5vw',
                  color: "black",
                  height: '2vw',
                  paddingLeft: '0.3vw',
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                  fontSize: '0.9vw',
                  borderColor: color.rightPanelInputBorder
                },Styles.allButtonBorderRadius]}
                // autoFocus={this.state.ICDCode===false?true:''}
                // ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.ICDCodeNewText}
                onChangeText={text => {
                  this.setState({ ICDCodeNewText: text })
                  this.icdCodeSearch(text,"ICDCode")
                }}
                onSubmitEditing={this.addMedicationToList.bind(this, 'icdCode')}
              />
              {this.state.ICDCodeViewFlag === false ?
                <View style={[styles.shortDescriptionViewFlagView, {backgroundColor: this.state.ICDCodeNewText ? color.white : "",
                  maxHeight: this.state.ICDCodeNewText ? "" : 0,}]}>
                  <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                    <FlatList
                      data={this.state.ICDCodeSearchList}
                      // ItemSeparatorComponent={this.ListViewItemSeparator}
                      //Item Separator View
                      renderItem={({ item }) => (
                        <View style={styles.shortDescriptionViewFlagView1}
                        >
                          <View style={{ width: '100%' }}>
                            <TouchableOpacity
                              onPress={() => {
                                this.addFavoriteDrugsList(item, "icdCode");
                              }
                              }>
                              <Text>{item.icd_code}</Text>
                            </TouchableOpacity>
                          </View>

                        </View>
                      )}
                      enableEmptySections={true}


                    //keyExtractor={(item, index) => index.toString()}
                    />
                  </ScrollView>
                </View>
                : null}
            </View>
            :  <View style={{marginLeft: '1.2vw'}}>{ (this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this, "icdCode"),this.state.isLocked))}</View>}
        </View>









        {/* style={{zIndex:-1}} */}

        <View style={{ marginTop: '1.2vw', zIndex: -4, marginBottom: '0.6vw' }}>
        <CommonSectionHeader heading={"Provisional Diagnosis"}  />
          {/* <Text style={{ fontSize: 15, color: "black" }}>Provisional Diagnosis</Text> */}
        </View>

        <View style={{ borderRadius: '0.6vw', zIndex: -5,marginHorizontal:'1.2vw' }} >
          {this.renderTextFieldView("", "ProvisionalComment", this.state.ProvisionalComment, true, 4,'',{},"",false ,this.state.isLocked)}
        </View>
        
        {/* <View style={{ marginTop: 15, zIndex: -6, marginBottom: 10 }}>
          <CommonSectionHeader heading = {"Advice (General Patient Instruction)"}/> */}
          {/* <Text style={{ fontSize: 15, color: "black" }}>Advice</Text> */}
        {/* </View> */}

        {/* <View style={{ borderRadius: 10, zIndex: -7 ,marginHorizontal:20}} >
          {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4,'',{},"",false ,this.state.isLocked)}
        </View> */}

        {
          this.state.userType ==="PSY"?
      
        <View>
        <DoctorNotesCommonRightSideHeader title={"Multi-Axial Diagnosis"} clearAllData={""} hideClearButton={false} />

          {this.renderMultiAxialDiagonsis("AXIS I Clinical Psychiatric Syndrome","clinicPsychiatrySyndrome",this.state.clinicPsychiatrySyndrome,"one", -2)}
          {this.renderMultiAxialDiagonsis("AXIS II Specific delays of development","specificDelaysOfDevelopment",this.state.specificDelaysOfDevelopment,"two",-3)}
          {this.renderMultiAxialDiagonsis("AXIS III General intellectual functioning","generalIntellectualFunctioning",this.state.generalIntellectualFunctioning,"three",-4)}
          {this.renderMultiAxialDiagonsis("AXIS IV Medical Problem","medicalProblem",this.state.medicalProblem,"four",-5)}
          {this.renderMultiAxialDiagonsis("AXIS V Abnormal family/ Psycho-social situations","abnormalFamily",this.state.abnormalFamily,"five",-6)}

      
          </View>
          :null
        }
        <View style={{ marginTop: '1.2vw', zIndex: -10, zIndex: -8, alignSelf: "center" }}>
          <TouchableOpacity
            onPress={() => {
               this.addAssessment()
            }

            }
            disabled={this.state.isLocked} >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View >
        {/* <View style={{ zIndex: -9 }} >
          {this.state.show ? (
            <View style={{ height: 40, width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{
                color: "white", textAlign: "center", justifyContent: "center"
              }}>Symptom Created Successfully</Text>
            </View>
          ) : null}
        </View >
        <View style={{ zIndex: -10 }} >
          {this.state.successOrErrorMessage ? (
            <View style={{ height: 40, width: '100%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{
                color: "white", textAlign: "center", justifyContent: "center"
              }}>
                {
                  this.state.successMessageFlag ? 'Symptom Created Successfully' :
                    this.state.errorMessageFlag ? 'Symptom History Failed' : null}</Text>
            </View>
          ) : null}
        </View > */}
      </View>
      // </TouchableWithoutFeedback>
    );
  }


  renderMultiAxialDiagonsis(header, key, value,text,zIndex) {
   
    return (
      <View style={{marginBottom:'0.6vw', zIndex: zIndex }}>
      <View style={{zIndex: zIndex }}>
        <CommonHistorySectionHeader
          heading1={header}
          noOfColumn={1}
        />
        <TextInput
          style={styles.MultiAxialDiagnosisTextInput}
          value={value}
          onChangeText={(text) => {
            var states = this.state;
            states[key] = text;
            states["validateTextBox"]=key;
            this.setState({ states},()=>{
              this.getMultiAxialAutoSuggest(text)
            })
            
          }}

        />
      </View>    
        <View style={{ }}>
          {
            this.props.multiAxialDropDownList.length > 0 && this.state.validateTextBox == key ?
              <View style={{zIndex:40,position:"absolute"}}>
               
                <ScrollView showsVerticalScrollIndicator={false}  style={[styles.ScrollViewContainer, {backgroundColor: color.white}]}>
                  {
                    this.props.multiAxialDropDownList.length > 0 ?
                      this.props.multiAxialDropDownList.map((item, index) => {
                        return (
                          <TouchableOpacity onPress={() => {
                            var states = this.state;
                            states[key] = item.icd_desc;
                              this.setState({
                               states,
                              //  multiAxialAutoSuggestList: []
                              },()=>{
                                this.props.updateMultiAxialDropDownFlag([])
                              })
                           }} style={{ marginLeft: '1.2vw',zIndex:40 }}>

                            <Text style={{zIndex:60, fontSize: '0.9vw'}}>{item.icd_desc}</Text>
                          </TouchableOpacity>
                        //  {/* </TouchableOpacity> */}
                       )
                      })
                    : null
                  }
                </ScrollView>
              </View>
            : null
          }
        </View>
      </View>
    )
  }

   getMultiAxialAutoSuggest(text){
      // var service_url=Constants.PSY_MULTI_AXIAL_AUTO_SUGGEST_GET + "?search_key=" +text + "&search_tab=" +tabKey
      var service_url=Constants.ASSESSMENT_ICD_CODE_GET+ "?desc=" + text;
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getMultiAxialAutoSuggestSuccess,
        this.getMultiAxialAutoSuggestFailure
      )
   }
   getMultiAxialAutoSuggestSuccess=(response)=>{
       if(response.status=="success"){
         this.props.updateMultiAxialDropDownFlag(response.data)
          // this.setState({
          //   // multiAxialAutoSuggestList:success.data
          //   multiAxialAutoSuggestList:response.data
          //  })

      }
   }
   getMultiAxialAutoSuggestFailure(){

   }

   getPsychiatryDetails(){

    var service_url=Constants.PSY_DIAGNOSIS_MULTI_AXIAL_GET + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id
    OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.getPsychiatryMultiAxialSuccess,
    this.getPaychiatryMultiAxialFailure
   );
  } 

  getPsychiatryMultiAxialSuccess=(response)=>{
        if(response.status=="success"){
          var states = this.state;

          states["multiAxialID"]=response.data.id;
          states["clinicPsychiatrySyndrome"]=response.data.axis_one_clinical_psy_syndrome;
          states["specificDelaysOfDevelopment"]=response.data.axis_two_specific_delays_of_development;
          states["generalIntellectualFunctioning"]=response.data.axis_three_general_intelluctual_functioning;
          states["medicalProblem"]=response.data.axis_four_medical_problem;
          states["abnormalFamily"]=response.data.axis_five_abnormal_family_or_psy_socail_situations
          this.setState({ states })
        }
   }
   getPaychiatryMultiAxialFailure=(error)=>{
    this.props.showResposeValue("error", error.message);


   }

  
}



const mapStateToProps = (state) => {
  return{
    multiAxialDropDownList: state.updateDropDown.multiAxialDropDownList

  }
  
};

const mapDispatchToProps = (dispatch) => {
  return{
    updateMultiAxialDropDownFlag:(data) => dispatch(updateMultiAxialDropDownFlag(data))
  }
          };
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsNew);

const styles = StyleSheet.create({
      ShortDescriptionView: { 
        marginTop: '1vw', 
        marginBottom: '0.6vw' 
      },
      shortDescriptionViewFlagView: {
        borderRadius: '0.6vw',
        width: '90%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '0.6vw',
        marginLeft: '0.6w',
        marginTop: '2vw',
        position: 'absolute'
      },
      shortDescriptionViewFlagView1: {
        flexDirection: "row",
        bottom: '0.6vw',
        marginBottom: '0.6vw',
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        marginTop: '0.6vw',
        zIndex: 10,
      },
      shortDescriptionViewFlagText: {
        fontSize: '0.9vw'
      },
      longDescriptionView: { 
        marginTop: '1.2vw', 
        marginBottom: '0.6vw' 
      },
      MultiAxialDiagnosisTextInput: {
        paddingLeft:'0.6vw',
        marginTop:'0.6vw', 
        marginLeft: '1.2vw', 
        borderRadius: 5, 
        borderColor: "#888888", 
        borderWidth: 1, 
        height: '4vw', 
        width: "80%" 
      },
      ScrollViewContainer: { 
        maxHeight: "14.3vw", 
        width: "80%", 
        marginLeft: '1.2vw', 
        borderRadius: 5, 
        borderWidth: 1, 
      }
})