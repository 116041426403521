import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  Platform,
  TouchableWithoutFeedback,Picker,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton } from '../BaseComponent'

import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "isHospital";
var Messages=require('../../../utils/InfoMessages')

const DURATION_YEARS = [
  { value: "2009", label: "2009" },
  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018",  label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  // { value: "14", label: "14" }
];

export default class Hospitalization extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedHospitalise: {},
      favoriteHospitalise: [],
      selectedHospitalOrSurgery:{},
      complaints: "",
      isEdit:false,
      isEditDuration: false,
      newItem: '',
      newDuration: '',
      selectedDuration: "",
      comments: "",
      is_surgery: "",
      hospital_name_or_description: "",
      successOrErrorMessage:false,
        successMessageFlag:false,
        errorMessageFlag:false,
        hospitalized_year: "",

        hospitalizationYear:[],
        disableButton : false
    };
  }



  
  componentDidMount() {

    var years = [];

    var today = new Date();
      var currentYear = parseInt(today.getFullYear());
      for (var i = currentYear; i > 1900; i--) {
        years.push(i);
      };

      this.setState({
        hospitalizationYear:years
      })


    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getHospitalFrequent()
  }

  componentWillReceiveProps(props) {

    let { id, favoriteHospitalise } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
      
       
        if (item.id &&( id !== item.id )) {
          var selectedHisOrSur =  {}
          if(item.hospitalisedOrsurgeries){
            selectedHisOrSur = { value: "True", label: "Surgery" }
          }else{
            selectedHisOrSur =  { value: "False", label: "Hospitalised" }
          }

          var favHos = {}
          if(item.hospitalisedFor){
            favHos = {value :item.hospitalisedFor, label: item.hospitalisedFor}
          }
      //    console.log("favoriteHospitalise ****************>"+JSON.stringify(selectedHisOrSur))
          this.setState({
            id: item.id,
            disableButton: true,
            selectedHospitalOrSurgery : selectedHisOrSur,
            selectedHospitalise: favHos,
            hospitalized_year: item.year,
            hospital_name_or_description: item.hospitalName,
            comments: item.comment
          },()=>{
            // this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
  }


  getHospitalFrequent = () => {
  
    var service_url = Constants.MEDICAL_HISTORY_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getHospitalFrequentSuccess,
      this.getHospitalFrequentFailure
    );
  };

  getHospitalFrequentSuccess = response => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      let favHospital = [];

      response.data.map((item) => {

        (item)?favHospital.push({ value: item, label: item }):null;
      });

      var field = this.state;
      field["favoriteHospitalise"] = favHospital;
      this.setState({ field });

    }
  };

  getHospitalFrequentFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;
    this.setState({ states });
  }

  triggerDurationAdd = () =>{
    let states = this.state;
    states['isEditDuration'] = true;
    this.setState({ states });
  }

  addHospitaliseToList = (event) => {

   // console.log(" addHospitaliseToList " + this.state.newItem)

    let { newItem, favoriteHospitalise } = this.state;
    let field = this.state;

    if(newItem) {
      
      favoriteHospitalise.push({ value: newItem, label: newItem });
      
      field["favoriteHospitalise"] = favoriteHospitalise;
      field["selectedHospitalise"] = { value: newItem, label: newItem };
      field["newItem"] = "";
      field["isEdit"] = false;
      
      this.setState({ field });
    }
  }


  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        disable= {this.state.isLocked || this.state.disableButton}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      
      />
    );
  }

  onPressAction = (key, value,label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states
    })
  }


  addAll = event => {

    // console.log(" add complaint ");
    // console.log(" add complaint " + this.state.selectedHospitalise);
    let states = this.state;
    var service_url = Constants.ADD_HOSPITALISE;

    let symptoms_name = "";

    states.favoriteHospitalise.map((item)=>{

      if( item.value === states.selectedHospitalise ) {

        symptoms_name = item.label;
      }
    });

    var is_surgery = this.state.selectedHospitalOrSurgery.value == "True" ? true : false

    let data = {
      "id": states.id ? states.id : null,
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "disease_name":this.state.selectedHospitalise.value,
      "hospital_name_or_description": this.state.hospital_name_or_description,
      "hospitalized_year": this.state.hospitalized_year,
      "comments": this.state.comments,
      "is_surgery" : is_surgery
    };

    if (this.state.hospitalized_year) {
      // alert(JSON.stringify(data))
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.addHospitaliseSuccess ,
        this.addHospitaliseFailure
      );
    }else{
      this.props.showResposeValue("error",Messages.SelectHospitalizationYear)
    }
  };


  addHospitaliseSuccess = response => {
  
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message)

      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    }else{
      this.props.showResposeValue("error", response.message)

    }

  };

  addHospitaliseFailure = error => {
    this.props.showResposeValue("error", error.message)

    //console.log("opthamology complaints GET error response");
    console.log(error);
  };


  clearComplaint = event => {

    let states = this.state;
    states['disableButton'] = false;
    states['selectedHospitalise'] = {};
    states['hospital_name_or_description'] = "";
    states['is_surgery']= "";
    // "duration_type": 3,
    states['selectedDuration'] = "";
    states['comments'] = "";
    states["hospitalized_year"]=""
    states["selectedHospitalOrSurgery"]= {}
    states["is_surgery"] = ""
    states["id"] = ""
    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

 

  onPressButton(key, selectedvalue) {
    if(key == "selectedHospitalOrSurgery"){
      this.setState({
        selectedHospitalOrSurgery:selectedvalue
      })
    }else
    if(key =="selectedHospitalise"){
      this.setState({
        selectedHospitalise: selectedvalue
      })
    }
  }



  render() {
    return (
      <TouchableWithoutFeedback onPress={this.addHospitaliseToList.bind(this)}>

      <View>
        <View style={{flexDirection: 'row'}}>  
        <View style={{ width: '80%' }}>

        </View>
      <View style={{ width: '20%' }}>
                <TouchableOpacity style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingRight: 10, paddingLeft: 30, paddingTop: 5, paddingBottom: 5 }}
                  onPress={() => {
                    this.clearComplaint();
                  }}
                ><Text style={{ fontSize: 15, color: 'white' }}>{"New"}</Text></TouchableOpacity>
      </View>
      </View>
      <View>
        {this.renderTitleWithMultipleBtn("Have you been hospitalised or under gone any surgeries?", false, 1, false, 'selectedApplanation',)}
        </View>

        <View style={{flexDirection:'row',flexWrap:'wrap',zIndex:-1,}}>
        {
          Constants.HOSPITALISE_FAV.map((item, index) => {
         
            return (
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                <CommonButton
                  disable= {this.state.isLocked || this.state.disableButton}
                  item={item}
                  selectedvalue={this.state.selectedHospitalOrSurgery}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedHospitalOrSurgery"} />
              </View>
            )
          })
        }
        </View>
      
      

        <View>
        {this.renderTitleWithMultipleBtn("Hospitalised for", false, 1, false, 'selectedApplanation',)}
        </View>

        
      
          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
            {/* {
             this.state.favoriteHospitalise && this.state.favoriteHospitalise.map((item, index) => {
                return this.renderSelectEyes(item, false, false, 'selectedHospitalise', this.state.selectedHospitalise)
              })
            } */}


{
          this.state.favoriteHospitalise && this.state.favoriteHospitalise.map((item, index) => {
         
            return (
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                <CommonButton
                  disable= {this.state.isLocked || this.state.disableButton}
                  item={item}
                  selectedvalue={this.state.selectedHospitalise}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedHospitalise"} />
              </View>
            )
          })
        }
          </View>

{this.state.isEdit?
        <View
        style={[{
          height: 30,
          marginTop: 10,
          // backgroundColor: color.white,
          // borderRadius: 20,
          borderWidth: 1,
          borderColor: "#B3D9FE",
          width: 150
        },Style.allButtonBorderRadius]}
      ><TextInput
        underlineColorAndroid="transparent"
        placeholder="type here"
        placeholderTextColor={color.black}
        style={{
          color: color.black,
          height: 30,
          paddingLeft: 5,
          marginRight: 0,
          flex: 1,
          textAlign: "center"
        }}
        ref={text => (this.nameInput = text)}
        // defaultValue=""
        autoCapitalize="none"
        value={this.state.newItem}
        onChangeText={text => this.setState({ selectedHospitalise:'',newItem: text })}          
        onSubmitEditing={this.addHospitaliseToList.bind(this)}
      /></View>:this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this),this.state.isLocked)}

        {/* {this.renderIconBtn("plus", "New", true)} */}

       


       
        <View style={{flexDirection: "row", marginBottom: 20, justifyContent: "space-between" ,zIndex:-1}}>
          <View style={{ marginTop: 10 }}>
            <Text style={{ fontSize: 20, color: color.themeBlue }}>Year</Text>
          </View>
          <View style={{ width: "30%", marginTop: 10 }}>
       
            <Picker
              enabled={!this.state.isLocked}
              selectedValue={this.state.hospitalized_year}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
              
                var states = this.state;
                states["hospitalized_year"] = itemvalue;

                this.setState({ states });
              }}
            >
              <Picker.Item label="select" />


              {/*  */}
              {this.state.hospitalizationYear && this.state.hospitalizationYear.map((i, index) => (
                <Picker.Item key={index} label={i} value={i} />
              ))}

              {/* <Picker.Item label="2020" value="2020" />
              <Picker.Item label="2019" value="2019" />
              <Picker.Item label="2018" value="2018" /> */}
             
            </Picker>
             
          </View>
        </View> 


       
        <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 20, color: color.black }}>Hospital Name/ Description</Text>
        </View>
        <View style={{ marginTop: 20 }}>
        {this.renderTextFieldView("Hospital Name", "hospital_name_or_description", this.state.hospital_name_or_description, true, 4,'',{},'',false,this.state.isLocked)}
        </View>

        <View style={{ marginTop: 10 }}>
          <Text style={{ fontSize: 20, color: color.black }}>Comments</Text>
        </View>
        <View style={{ marginTop: 20 }}>
        {this.renderTextFieldView("Comments", "comments", this.state.comments, true, 4,'',{},'',false,this.state.isLocked)}
        </View>

        <View style={{ marginTop: 20 }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addAll.bind(this)} >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View >
  
      </View>
      </TouchableWithoutFeedback>
    );
  }
}