//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,TextInput } from 'react-native';
import { OBGYNHistorySectionHeading,CommonHistorySectionHeader, EditiAndDeleteForLineItem,CommonButton, CommonAddButton, HistorySectionRightSideUI } from '../../BaseComponent'
import { Constants } from "../../../../utils/Constants"
import OpthamologyService from "../../../../network/OpthamologyService";
import {color} from '../../../../styles/Color'
// create a component
import Style from './../../../../styles/Style'
const pageName = "isHospitalizationHistory"
export class HospitalizationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            listOfData:[],
            // getListOfHospitalization:{},
            comments:""

        }
    }
    
    componentDidMount(){
        this.getHospitalizationHistory()
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getHospitalizationHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        setTimeout(() => {
            this.editHistory()
        }, 100);
    }
    editHistory(item) {
        var data={
            item:item,
            comment:this.state.comments
        }
        this.props.editHistory(data, pageName)
    }

    deleteHistory(item){
        //this.props.editItem(pageName, item);
        var service_url = Constants.MEDICAL_HISTORY_DELETE;
        let data = {
          patient_id: this.state.patientAppointment.patient_id,
          id: item.id,
        };
    
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.getDeleteSuccess,
          this.getSymptomsFailure
        );
      };
    
      getDeleteSuccess = (response) => {
        if (response.status === "success") {
          this.getHospitalizationHistory();
          this.props.showResposeValue("success", response.message);
        } else {
          this.props.showResposeValue("error", response.message);
        }
      };


  getHospitalizationHistory(){
        var service_url = Constants.GET_HOSPITALISATION_HISTORY_ + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHospitalizationHistorySuccess,
            this.getHospitalizationHistoryFailure
        );
    }
    getHospitalizationHistorySuccess=(success)=>{
        if(success.status =="success"){
            var data =success.data.patient_hospitalization_history
        }
        this.setState({
            comments:success.data.comments,
            // getListOfHospitalization:data,
            listOfData:data,
        })        
    }
    getHospitalizationHistoryFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }
    renderEditandDelete(item){
        return (
          <View style={{backgroundColor:color.white}}>
            <EditiAndDeleteForLineItem
                editHistory={this.editHistory.bind(this, item)}
                deleteHistory={this.deleteHistory.bind(this, item)}

                headerKey ={"testConfig"}
                editImage={"pencil"}
                deleteImage={"trash"}
            />
          </View>    
        );
    }
    renderContent(item,index){
        return (
            <View key={index} style={{ flexDirection: 'row',paddingVertical:12 ,borderBottomColor:"#888888",borderBottomWidth:1,alignItems:'center'}}>
             <Text style={[Style.historyHeaderFontStyleStartText, { flex: 0.2 }]}>{item.is_surgery ? "Surgery" : "Hospitalised"}</Text>
             <Text style={[Style.historyHeaderFontStyleStartText, { flex: 0.2 }]}>{item.disease_name}</Text>
             <Text style={[Style.historyHeaderFontStyle, { flex: 0.2 }]}>{item.hospitalized_year}</Text>
             <Text style={[Style.historyHeaderFontStyle, { flex: 0.2,marginLeft:20}]}>{item.hospital_name_or_description}</Text>
             {/* <Text style={[Style.historyHeaderFontStyle, {}]}>{""}</Text> */}
             <View style={{backgroundColor:color.white,flex:0.2,marginRight:50}}>{this.renderEditandDelete(item)}</View>

           </View>

   )
    }
    render() {
        return (
            <View style={styles.container}>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Hospitalization History"}
                    // editImage={"pencil"}
                />
                </View>
                <View style={{marginTop:15}}>
                    <CommonHistorySectionHeader
                    heading1={"Hospitalised for"}
                    heading2={"Problems"}
                    heading3={"Year"}
                    heading4={"Hospital Name"}
                    heading5={"Action"}
                    noOfColumn={5}
                    columnSize={0.2,0.2,0.2,0.2,0.1}
                    />
                </View>
                {
                    this.state.listOfData&& this.state.listOfData.length > 0 &&
                    this.state.listOfData.map((item,index)=>{
                       return this.renderContent(item,index)
                    })
                }
                <View style={{marginTop:15}}>
                    <CommonHistorySectionHeader
                    heading1={"Comments"}
                    noOfColumn={1}
                    />
                    <TextInput

                        editable={true}
                        multiline={true}
                        placeholder=""
                        style={{ marginTop: 15, paddingTop: 10, paddingLeft: 10, backgroundColor: this.state.isLocked ? color.disableComponentColor : null, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 5 }}
                        value={this.state.comments}
                    />
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginTop:20
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
