import React, { Component } from 'react';
import {
  View,
  Text,
  // Platform,
  // Dimensions
} from "react-native";
//import Style from "../styles/Style";
import { color } from "../styles/Color";
import moment from "moment";

// const platform = Platform.OS;
// const screenWidth = Dimensions.get("window").width;

export default class TimerLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: "",
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ currentTime: moment(new Date()).format('Do MMMM YYYY, HH:mm:ss ') });
    }, 1000)
  }

  render() {
   
    return (
      <View style={{ 
        flexDirection: "row", justifyContent: "center", alignContent: "center", 
        //alignItems: "center",
        minHeight: 0
        }}>
            <Text style={{ fontSize: 15, color: color.lightGray, marginTop: 0 }}>
              {this.state.currentTime}
            </Text>
      </View>
    );
  }
}
