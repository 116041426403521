import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet, Picker } from "react-native";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader, NavigationTopHeader } from "../BaseComponent";
import AddIcon from '../../../../assets/images/BillConfig/Add.svg'
import CancelIcon from '../../../../assets/images/BillConfig/Cancel.svg'
import EditIcon from '../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../assets/images/BillConfig/delete.svg'
import DropDownIcon from '../../../../assets/images/BillConfig/Down_Arrow.svg'

const screenHeight = Dimensions.get("window").height;
let defaultRoomBedEntry = {
    "bed_number": "",
}
let defaultWardBedEntry = {
    "bed_number": "",
}

export default class IPRoomConfiguration extends Component {
    constructor(props) {
      super(props);
      const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {}: JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};

      this.state = {
        selectedTab: "roomConfig",
        userType: this.props.userType,
        selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        roomSearchKey: "",
        wardSearchKey: "",
        selectedEditId: null,

        roomName: "",
        roomType: "Single Bedded",
        roomRent: "",
        roomBedNewEntry: [
            defaultRoomBedEntry
        ],
        roomDetailsList: [],

        wardName: "",
        wardRent: "",
        wardBedNewEntry: [
            defaultWardBedEntry
        ],
        wardDetailsList: [],

        selectedList: [],
        selectedDropDownIndex: null,
        deletedId : null,
        confirmDeletedId: null,
        isDeleteClick: false,
        popupKey : "",
        popupType: "",
        isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ?  false  : true
      }
    }

    componentDidMount(){
        this.getRoomDetails()
    }

    getRoomDetails = () => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_ROOM + "?room_number=" + this.state.roomSearchKey
        }else{
            url = Constants.CLINIC_IP_ROOM + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&room_number=" + this.state.roomSearchKey
        }
        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getRoomDetailsSuccess,
            this.getRoomDetailsFailure
        );
    }

    getRoomDetailsSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                roomDetailsList: response.data,
                selectedList: [],
                selectedDropDownIndex: null,
            })
        }
    }

    getWardDetails = () => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_WARD + "?ward_name=" + this.state.wardSearchKey
        }else{
            url = Constants.CLINIC_IP_WARD + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&ward_name=" + this.state.wardSearchKey
        }
        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getWardDetailsSuccess,
            this.getWardDetailsFailure
        );
    }

    getWardDetailsSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                wardDetailsList: response.data,
                selectedList: [],
                selectedDropDownIndex: null,
            })
        }
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }

    changeTabView(key) {
        this.setState({
          selectedTab: key,
          roomSearchKey: "",
          wardSearchKey: "",
        },() => {
            this.onPressClear()
        })
        if(key === "roomConfig"){
            this.getRoomDetails()
        }else{
            this.getWardDetails()
        }
    }

    onPressClear = () => {
        let defaultRoomBedEntry = {
            "bed_number": "",
        }
        let defaultWardBedEntry = {
            "bed_number": "",
        }
        this.setState({
            roomBedNewEntry: [
                defaultRoomBedEntry
            ],
            wardBedNewEntry: [
                defaultWardBedEntry
            ],
            roomName: "",
            roomType: "Single Bedded",
            roomRent: "",
            wardName: "",
            wardRent: "",
            selectedList: [],
            selectedDropDownIndex: null,
            deletedId : null,
            selectedEditId: null,
            confirmDeletedId: null,
            isDeleteClick: false,
            popupKey : "",
            popupType: ""
        })
    }

    editRoomDetails = (data) => {
        this.setState({
            roomName: data.room_number,
            roomType: data.room_type,
            selectedEditId: data.id,
            roomRent: data.amount,
            roomBedNewEntry: data.bed_details
        })
    }

    editWardDetails = (data) => {
        this.setState({
            wardName: data.ward_number,
            wardRent: data.amount,
            selectedEditId: data.id,
            wardBedNewEntry: data.bed_details
        })
    }

    renderRoomConfigLeft = () => {
        var states = this.state
        return(
            <View style={{maxHeight: "90%", height: '-webkit-fill-available' }}>
                <View style={{marginTop: 5, backgroundColor: color.sectionHeadingDarkColor, minHeight: 40, maxHeight: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Room Name"}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Room Type"}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Room Rent"}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {states.roomDetailsList.length > 0 && states.roomDetailsList.map((item, index) => (
                    <View style={{maxHeight: states.selectedDropDownIndex === index ? 480 : 60, marginHorizontal: 5, paddingHorizontal: 15, minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center"}}>
                        <TouchableOpacity
                        onPress={() => {
                            var {roomDetailsList, selectedDropDownIndex} = this.state
                            var Index = null
                            if(selectedDropDownIndex === index){
                                Index = null
                            }else {
                                Index = index
                            }
                            this.setState({
                                selectedDropDownIndex: Index,
                                selectedList: roomDetailsList[index].bed_details
                            })
                        }}
                        style={{minHeight: 60}}
                        >
                            <View style={{ flexDirection: 'row', flex: 1, alignItems: "center"}}>
                            <View style={{flex: 0.25}}>
                                <Text style={{fontSize: 14,}}>{item.room_number}</Text>
                            </View>
                            <View style={{flex: 0.25}}>
                                <Text style={{fontSize: 14,}}>{item.room_type}</Text>
                            </View>
                            <View style={{flex: 0.25}}>
                                <Text style={{fontSize: 14,}}>{item.amount}</Text>
                            </View>
                            <View style={{flex: 0.25, flexDirection: "row", justifyContent: "space-between"}}>
                            <View style={{flexDirection: "row",}}>
                            <TouchableOpacity
                            onPress={() => {
                                this.editRoomDetails(item)
                            }}
                            >
                                <Image
                                    source={EditIcon}
                                    style={{ height: 25, width: 25 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                            style={{marginLeft: 30, width: "fit-content"}}
                            onPress={() => {
                                this.detailDelete(item.id, "room", "room")
                            }}
                            >
                                <Image
                                    source={DeleteIcon}
                                    style={{ height: 25, width: 30}}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            </View>
                            <TouchableOpacity
                            onPress={() => {
                                var {roomDetailsList, selectedDropDownIndex} = this.state
                                var Index = null
                                if(selectedDropDownIndex === index){
                                    Index = null
                                }else {
                                    Index = index
                                }
                                this.setState({
                                    selectedDropDownIndex: Index,
                                    selectedList: roomDetailsList[index].bed_details
                                })
                            }}
                            >
                                <Image
                                    source={DropDownIcon}
                                    style={{ height: 25, width: 25 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                        </View>
                        </TouchableOpacity>
                        {( states.selectedDropDownIndex === index && !this.state.selectedEditId )? 
                        <View style={{marginVertical: 10, flex: 1, width: "100%", backgroundColor: color.white}}>
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: 400 }}>
                            <View style={{ borderWidth: 1, borderColor: color.lightGray}}>
                            <View style={{marginVertical: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                            <View style={{flex: 0.5}}>
                                <Text style={styles.headerFontStyle}>{"Bed Number"}</Text>
                            </View>
                            <View style={{flex: 0.5}}>
                                <Text style={styles.headerFontStyle}>{"Action"}</Text>
                            </View>
                            </View>
                            {states.selectedList.length > 0 && states.selectedList.map((item, index) => (
                                <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 15, paddingVertical: 10, }}>
                                    <View style={{flex: 0.5}}>
                                        <Text style={{fontSize: 14}}>{item.bed_number}</Text>
                                    </View>
                                    <View style={{flex: 0.5,}}>
                                    <TouchableOpacity
                                    style={{width: "fit-content"}}
                                    onPress={() => {
                                        this.detailDelete(item.id, "bed", "room")
                                    }}
                                    >
                                        <Image
                                            source={DeleteIcon}
                                            style={{ height: 30, width: 30 }}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                    </View>
                                </View>
                            ))}
                            </View>
                            </ScrollView>
                        </View>
                        :null }
                    </View>
                ))}
                </ScrollView>
            </View>
        )
    }

    renderWardConfigLeft = () => {
        var states = this.state
        return(
            <View style={{maxHeight: "90%", height: '-webkit-fill-available' }}>
                <View style={{marginTop: 5, backgroundColor: color.sectionHeadingDarkColor, minHeight: 40, maxHeight: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.4}}>
                    <Text style={styles.headerFontStyle}>{"Ward No."}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={styles.headerFontStyle}>{"Rent"}</Text>
                </View>
                <View style={{flex: 0.3}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {states.wardDetailsList.length > 0 && states.wardDetailsList.map((item, index) => (
                    <View style={{maxHeight: states.selectedDropDownIndex === index ? 480 : 60, marginHorizontal: 5, paddingHorizontal: 15, minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center"}}>
                        <TouchableOpacity
                        onPress={() => {
                            var {wardDetailsList, selectedDropDownIndex} = this.state
                            var Index = null
                            if(selectedDropDownIndex === index){
                                Index = null
                            }else {
                                Index = index
                            }
                            this.setState({
                                selectedDropDownIndex: Index,
                                selectedList: wardDetailsList[index].bed_details
                            })
                        }}
                        style={{minHeight: 60}}
                        >
                            <View style={{ flexDirection: 'row', flex: 1, alignItems: "center"}}>
                            <View style={{flex: 0.4}}>
                                <Text style={{fontSize: 14,}}>{item.ward_number}</Text>
                            </View>
                            <View style={{flex: 0.3}}>
                                <Text style={{fontSize: 14,}}>{item.amount}</Text>
                            </View>
                            <View style={{flex: 0.3, flexDirection: "row", justifyContent: "space-between"}}>
                            <View style={{flexDirection: "row",}}>
                            <TouchableOpacity
                            onPress={() => {
                                this.editWardDetails(item)
                            }}
                            >
                                <Image
                                    source={EditIcon}
                                    style={{ height: 25, width: 25 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                            style={{marginLeft: 30, width: "fit-content"}}
                            onPress={() => {
                                this.detailDelete(item.id, "ward", "ward")
                            }}
                            >
                                <Image
                                    source={DeleteIcon}
                                    style={{ height: 25, width: 30}}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            </View>
                            <TouchableOpacity
                            onPress={() => {
                                var {wardDetailsList, selectedDropDownIndex} = this.state
                                var Index = null
                                if(selectedDropDownIndex === index){
                                    Index = null
                                }else {
                                    Index = index
                                }
                                this.setState({
                                    selectedDropDownIndex: Index,
                                    selectedList: wardDetailsList[index].bed_details
                                })
                            }}
                            >
                                <Image
                                    source={DropDownIcon}
                                    style={{ height: 25, width: 25 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                        </View>
                        </TouchableOpacity>
                        { (states.selectedDropDownIndex === index && !this.state.selectedEditId) ? 
                        <View style={{marginVertical: 10, flex: 1, width: "100%", backgroundColor: color.white}}>
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: 400 }}>
                            <View style={{ borderWidth: 1, borderColor: color.lightGray}}>
                            <View style={{marginVertical: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                            <View style={{flex: 0.5}}>
                                <Text style={styles.headerFontStyle}>{"Bed Number"}</Text>
                            </View>
                            <View style={{flex: 0.5}}>
                                <Text style={styles.headerFontStyle}>{"Action"}</Text>
                            </View>
                            </View>
                            {states.selectedList.length > 0 && states.selectedList.map((item, index) => (
                                <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 15, paddingVertical: 10, }}>
                                    <View style={{flex: 0.5}}>
                                        <Text style={{fontSize: 14}}>{item.bed_number}</Text>
                                    </View>
                                    <View style={{flex: 0.5,}}>
                                    <TouchableOpacity
                                    style={{width: "fit-content"}}
                                    onPress={() => {
                                        this.detailDelete(item.id, "bed", "ward")
                                    }}
                                    >
                                        <Image
                                            source={DeleteIcon}
                                            style={{ height: 30, width: 30 }}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                    </View>
                                </View>
                            ))}
                            </View>
                            </ScrollView>
                        </View>
                        :null }
                    </View>
                ))}
                </ScrollView>
            </View>
        )
    }

    renderRoomConfigRight = () => {
        return(
            <View style={{paddingHorizontal: 10, paddingVertical: 20,}}>
                <CommonHistorySectionHeader
                heading1={"Room Details"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                <View style={{flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between"}}>
                    {this.renderTexBox("Room Number", "roomName")}
                    <View style={{width:"45%", marginTop: 10}}>
                    <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{"Room Type"}</Text>
                    <Picker
                    style={{
                        borderColor: color.black,
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: 35,
                        // padding: 15,
                        marginTop: 8, 
                    }}
                    // itemStyle={{ fontSize: 14, color: color.black }}
                    selectedValue={this.state.roomType}
                    onValueChange={(itemvalue) => {
                        this.setState({
                            roomType: itemvalue
                        })
                    }}
                    >
                    <Picker.Item label="Single Bed" value="Single Bedded" />
                    <Picker.Item label="Double Bed" value="Double Bedded" />
                    <Picker.Item label="Triple Bed" value="Triple Bedded" />
                    </Picker>
                    </View>
                    {this.renderTexBox("Room Rent", "roomRent")}
                </View>
                <View style={{flexDirection: "row", marginTop: 25}}>
                <View style={{ width: "90%"}}>
                <CommonHistorySectionHeader
                heading1={"Bed Details"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                </View>
                <View style={{height: 35,width: "10%", backgroundColor: color.sectionHeadingDarkColor, justifyContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => {this.onPressNewRoomBed()}} >
                    <Image
                        source={AddIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: screenHeight - 420,}} >
                {
                    this.state.roomBedNewEntry.length > 0 && this.state.roomBedNewEntry.map((newEntry, index) => {
                        return this.renderRoomBedNewEntryView(newEntry, index)
                    })
                }
                </ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 20 }}>
                <View style={{marginRight: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.postRoomDetails()}}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.onPressClear()}}
                buttonKey={"clear"} />
                </View>
                </View>
            </View>  
        )
    }

    postRoomDetails = () => {
        var states = this.state

        var url = ""
        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_ROOM
        }else{
            url = Constants.CLINIC_IP_ROOM + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        var checkTestField = this.checkAllDetailsAreAvailable(states.roomBedNewEntry)

        if(states.roomName=== "" || states.roomRent === ""){
            this.props.showResposeValue("error", "Room Details Should be Mandatory")
        }else if (!checkTestField){
            this.props.showResposeValue("error", "Bed Details Can't be Empty")
        }else{
            var lineItems = []
            var typedata = states.roomBedNewEntry
            for(let i = 0; i < typedata.length; i++){
                if(typedata[i].id){
                    lineItems.push({id: typedata[i].id, bed_number: parseInt(typedata[i].bed_number) })
                }else{
                    lineItems.push({ bed_number: parseInt(typedata[i].bed_number) })
                }
            }
            var data = {
                room_number: parseInt(states.roomName),
                amount: states.roomRent,
                room_type: states.roomType,
                room_bed: lineItems
            }

            if(states.selectedEditId){
                data["id"] = states.selectedEditId
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.roomPostSuccess,
                this.roomPostFailure
            );
        }
    }

    postWardDetails = () => {
        var states = this.state

        var url = ""
        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_WARD
        }else{
            url = Constants.CLINIC_IP_WARD + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        var checkTestField = this.checkAllDetailsAreAvailable(states.wardBedNewEntry)

        if(states.wardName === "" || states.wardRent === ""){
            this.props.showResposeValue("error", "Room Details Should be Mandatory")
        }else if (!checkTestField){
            this.props.showResposeValue("error", "Bed Details Can't be Empty")
        }else{
            var lineItems = []
            var typedata = states.wardBedNewEntry
            for(let i = 0; i < typedata.length; i++){
                if(typedata[i].id){
                    lineItems.push({id: typedata[i].id, bed_number: typedata[i].bed_number })
                }else{
                    lineItems.push({ bed_number: typedata[i].bed_number })
                }
            }
            var data = {
                ward_name: states.wardName,
                amount: states.wardRent,
                ward_bed: lineItems
            }

            if(states.selectedEditId){
                data["id"] = states.selectedEditId
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.wardPostSuccess,
                this.wardPostFailure
            );
        }
    }
    
    roomPostSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getRoomDetails()
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    wardPostSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getWardDetails()
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    detailDelete = (id, key, type) => {
        this.setState({
            deletedId : id,
            isDeleteClick: true,
            popupKey : key,
            popupType: type
        })
    }

    deleteRoomDetails = () => {
        var states = this.state
        var url = ""
        var query = states.popupKey === "room" ? "room_id=" : "bed_id="

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_ROOM + "?" + query + states.confirmDeletedId
        }else{
            url = Constants.CLINIC_IP_ROOM + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&" + query + states.confirmDeletedId
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.onPressDeleteSuccess,
            this.onPressDeleteFailure
        )
    }

    onPressDeleteSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.getRoomDetails()
            this.getWardDetails()
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    deleteWardDetails = () => {
        var states = this.state
        var url = ""
        var query = states.popupKey === "ward" ? "ward_id=" : "bed_id="

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_WARD + "?" + query + states.confirmDeletedId
        }else{
            url = Constants.CLINIC_IP_WARD + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&" + query + states.confirmDeletedId
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.onPressDeleteSuccess,
            this.onPressDeleteFailure
        )
    }

    checkAllDetailsAreAvailable = (newEntry, key = "") => {
        var defaultReturn = true
        for (const data of newEntry) {
            if (data.bed_number || data.bed_number === "0") {
                // Do Nothing
            } else {
                defaultReturn = false
                return defaultReturn
            }
        }
        return defaultReturn
    }

    onPressNewRoomBed = () => {
        let {roomBedNewEntry} = this.state
        let defaultBedEntry = {
            "bed_number": "",
        }
        if(roomBedNewEntry.length > 0){
            var checkTestField = this.checkAllDetailsAreAvailable(roomBedNewEntry)
            if (checkTestField) {
                roomBedNewEntry.push(defaultBedEntry)
            } else {
                // Do nothing
            }
        } else {
            roomBedNewEntry.push(defaultBedEntry)
        }
        this.setState({ roomBedNewEntry })
    }

    onPressNewWardBed = () => {
        let {wardBedNewEntry} = this.state
        let defaultBedEntry = {
            "bed_number": "",
        }
        if(wardBedNewEntry.length > 0){
            var checkTestField = this.checkAllDetailsAreAvailable(wardBedNewEntry)
            if (checkTestField) {
                wardBedNewEntry.push(defaultBedEntry)
            } else {
                // Do nothing
            }
        } else {
            wardBedNewEntry.push(defaultBedEntry)
        }
        this.setState({ wardBedNewEntry })
    }

    onPressNewClear =(key, index) => {
        if(key === "ward"){
            var showList = this.state.wardBedNewEntry;
        }else{
            var showList = this.state.roomBedNewEntry;
        }

        showList.splice(index,1)
        this.setState({
            showList
        })
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    renderWardConfigRight = () => {
        return(
            <View style={{paddingHorizontal: 10, paddingVertical: 20,}}>
                <CommonHistorySectionHeader
                heading1={"Ward Details"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                <View style={{flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between"}}>
                    {this.renderTexBox("Ward Name", "wardName")}
                    {this.renderTexBox("Ward Rent", "wardRent")}
                </View>
                <View style={{flexDirection: "row", marginTop: 25}}>
                <View style={{ width: "90%"}}>
                <CommonHistorySectionHeader
                heading1={"Bed Details"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                </View>
                <View style={{height: 35,width: "10%", backgroundColor: color.sectionHeadingDarkColor, justifyContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => {this.onPressNewWardBed()}} >
                    <Image
                        source={AddIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: screenHeight - 380,}} >
                {
                    this.state.wardBedNewEntry.length > 0 && this.state.wardBedNewEntry.map((newEntry, index) => {
                        return this.renderWardBedNewEntryView(newEntry, index)
                    })
                }
                </ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 20 }}>
                <View style={{marginRight: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.postWardDetails()}}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.onPressClear()}}
                buttonKey={"clear"} />
                </View>
                </View>
            </View>  
        )
    }

    renderWardBedNewEntryView = (newEntry, index) => {
        let bedNumber = null
        let id = null
        let {wardBedNewEntry} = this.state

        bedNumber = wardBedNewEntry[index] ? wardBedNewEntry[index].bed_number : ""
        id = wardBedNewEntry[index] && wardBedNewEntry[index].id ? wardBedNewEntry[index].id : null
        return(
            <View style={{flexDirection: "row", marginVertical: 10}}>
                <View style={{width: "90%"}}>
                <View style={{width:"50%"}}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{"Bed Number"}</Text>
                <TextInput 
                value={bedNumber}
                style={{
                    borderColor: color.black,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "100%",
                    height: 35,
                    padding: 15,
                    marginTop: 8,
                }}
                onChangeText = {(text) => {
                    var isValid = this.numberValidation(text)
                    if(isValid || text === ""){
                        wardBedNewEntry[index]["bed_number"] = text
                    this.setState({ wardBedNewEntry })
                    }
                }}
                />
                </View>
                </View>
                {index != 0 && id === null ?
                <View style={{height: 35,width: "10%", justifyContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => {this.onPressNewClear("ward", index)}} >
                    <Image
                        source={CancelIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                </View>
                : 
                <View style={{height: 35, width: "10%", }}></View>
                }
            </View>
        )

    }

    renderRoomBedNewEntryView = (newEntry, index) => {
        let bedNumber = null
        let id = null
        let {roomBedNewEntry} = this.state

        bedNumber = roomBedNewEntry[index] ? roomBedNewEntry[index].bed_number : ""
        id = roomBedNewEntry[index] && roomBedNewEntry[index].id ? roomBedNewEntry[index].id : null

        return(
            <View style={{flexDirection: "row", marginVertical: 10}}>
                <View style={{width: "90%"}}>
                <View style={{width:"50%"}}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{"Bed Number"}</Text>
                <TextInput 
                value={bedNumber}
                style={{
                    borderColor: color.black,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "100%",
                    height: 35,
                    padding: 15,
                    marginTop: 8,
                }}
                onChangeText = {(text) => {
                    var isValid = this.numberValidation(text)
                    if(isValid || text === ""){
                    roomBedNewEntry[index]["bed_number"] = text
                    this.setState({ roomBedNewEntry })
                    }
                }}
                />
                </View>
                </View>
                {index != 0 && id === null ?
                <View style={{height: 35,width: "10%", justifyContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => {this.onPressNewClear("room", index)}} >
                    <Image
                        source={CancelIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                </View>
                : 
                <View style={{height: 35, width: "10%", }}></View>
                }
            </View>
        )
    }

    renderTexBox = (label, key) => {
        var states = this.state
        return(
            <View style={{width:"45%", marginTop: 10}}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{label}</Text>
                <TextInput 
                value={states[key]}
                style={{
                    borderColor: color.black,
                    borderRadius: 4,
                    borderWidth: 1,
                    width: "100%",
                    height: 35,
                    padding: 15,
                    marginTop: 8,
                }}
                onChangeText = {(text) => {
                    if(key != "wardName"){
                    var isValid = this.numberValidation(text)
                    if(isValid || text === ""){
                    states[key] = text
                    this.setState({ states })
                    }
                    }else{
                        states[key] = text
                        this.setState({
                            states
                        })
                    }
                }}
                />
            </View>
        )
    }

    render(){
        return(
            <View style={{height: screenHeight-65,}}>
                <NavigationTopHeader
                changeTabSection={this.changeTabSection.bind(this)}
                navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                    { label: "Service Configuration", value: "adminConfig" },
                    { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                    { label: "Tag Configuration", value: "tagConfig" },
                    { label: "Package Configuration", value: "packageConfig" },
                    { label: "User Configuration", value: "userConfig" },
                    { label: "Corporate Details", value: "corpConfig" },
                    { label: "QMS", value: "adminQmsConfiq" },
                    { label: "Referred Doctors", value: "labRefferedDoctors"},
                    { label: "Expense", value: "expenseconfig" },
                    { label: "Settings", value: "settingConfig" },
                    { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                    { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                    ] : [
                    { label: "Service Configuration", value: "adminConfig" },
                    { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                    { label: "Tag Configuration", value: "tagConfig" },
                    { label: "User Configuration", value: "userConfig" },
                    { label: "Corporate Details", value: "corpConfig" },
                    { label: "Referred Doctors", value: "labRefferedDoctors"},
                    { label: "Expense", value: "expenseconfig" },
                    { label: "Settings", value: "settingConfig" }
                    ]}
                selectedTab={"ipRoomConfig"}
                isNavigationBorder={true}
                />
                <View style={{ flex: 1, flexDirection: "row", height: screenHeight-120,}}>
                <View style={{ flex: 0.60, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                <View style={{alignItems: "flex-end", height: 60, paddingVertical: 10, width: "100%"}}>
                <TextInput 
                style={{height: 40, borderWidth: 1, borderColor: color.lightGray, borderRadius: 4, width: "30%", marginRight: 10, paddingHorizontal: 8, color: color.black}} 
                placeholder={this.state.selectedTab == "roomConfig" ? "Search IP Room..." : "Search IP Ward..."}
                value={this.state.selectedTab == "roomConfig" ? this.state.roomSearchKey : this.state.wardSearchKey}
                onChangeText={(text) => {
                    var {selectedTab} = this.state
                    if(selectedTab === "roomConfig"){
                        this.setState({
                            roomSearchKey: text,
                            wardSearchKey: ""
                        },() => {
                            this.getRoomDetails()
                        })
                    }else{
                        this.setState({
                            roomSearchKey: "",
                            wardSearchKey: text
                        },() => {
                            this.getWardDetails()
                        })
                    }
                }}
                />
                </View>
                <View style={{flexDirection: "row",  }}>
                <TouchableOpacity onPress={() => {
                    this.changeTabView("roomConfig")
                }} style={{ width: "50%", borderBottomColor: this.state.selectedTab == "roomConfig" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "roomConfig" ? 2 : null, height: 40, padding: 5, justifyContent: "center" }}>
                    <Text style={{alignSelf: "center", fontSize: 18 }}>{"Room Configuration"}</Text>
                </TouchableOpacity> 
                <TouchableOpacity onPress={() => {
                    this.changeTabView("wardConfig")
                }} style={{ width: "50%", borderBottomColor: this.state.selectedTab == "wardConfig" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "wardConfig" ? 2 : null,  height: 40, padding: 5, justifyContent: "center" }}>
                    <Text style={{alignSelf: "center", fontSize: 18 }}>{"Ward Configuration"}</Text>
                </TouchableOpacity>
                </View>
                <View style={{ height: screenHeight - 220}}>
                {this.state.selectedTab == "roomConfig" ?
                <View style={{height: "100%"}}>
                    {this.renderRoomConfigLeft()}
                </View>
                : this.state.selectedTab == "wardConfig" ?
                <View style={{height: "100%"}}>
                    {this.renderWardConfigLeft()}
                </View>
                : null
                }
                </View>
                </View>
                <View style={{flex: 0.40, backgroundColor: color.themeShade, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                {this.state.selectedTab == "roomConfig" ?
                <View>
                    {this.renderRoomConfigRight()}
                </View>
                : this.state.selectedTab == "wardConfig" ?
                <View>
                    {this.renderWardConfigRight()}
                </View>
                : null
                }
                </View>
                </View>
                {this.state.isDeleteClick ?
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white,  width: 250, height: 150, borderRadius: 8, alignItems: "center", borderWidth: 1, }}>
                    <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 50, }}>{"Are you sure ?"}</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => {
                            var states = this.state
                            this.setState({
                                confirmDeletedId: states.deletedId
                            }, () => {
                                if(states.popupType === "room"){
                                    this.deleteRoomDetails()
                                }else{
                                    this.deleteWardDetails()
                                }
                            })
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => { 
                            this.setState({
                                deletedId : null,
                                isDeleteClick: false,
                                popupKey: "",
                                popupType: ""
                            })
                         }}>
                        <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                    </TouchableOpacity>
                    </View>
                    </View>
                </View>
                : null }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 14,
        color: "black",
        fontWeight: '500'
    },
});