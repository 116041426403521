import React, { Component } from 'react';
import { View, Text, TouchableOpacity, TextInput, Image, ScrollView, Picker, platform,TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { color } from '../../../styles/Color';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Icon from "react-native-vector-icons/Feather";
import NewButtonIcon from "react-native-vector-icons/FontAwesome";

import Style from "../../../styles/Style";
import { CommonButton, DoctorNotesCommonRightSideHeader, CommonHistorySectionHeader } from '../BaseComponent'
import BaseComponentStyle from "../BaseComponentStyle";
import AsyncStorage from '../../../AsyncStorage';
var Messages = require('../../../utils/InfoMessages')
const BrandName = [
  { "label": "CLOFERT", "value": "CLOFERT" },
  { "label": "CROCIN", "value": "CROCIN" },
];

const tabletPerDay = [
  { "label": "0", "value": "0" },
  { "label": "0.5", "value": "0.5" },
  { "label": "1", "value": "1" },
  { "label": "1.5", "value": "1.5" },
  { "label": "2", "value": "2" },
  { "label": "2.5", "value": "2.5" },
  { "label": "3", "value": "3" },
  { "label": "3.5", "value": "3.5" },
]

const durationList = [
  { "label": "1", "value": "1" },
  { "label": "2", "value": "2" },
  { "label": "3", "value": "3" },
  { "label": "4", "value": "4" },
  { "label": "5", "value": "5" },
  { "label": "6", "value": "6" },
  { "label": "7", "value": "7" },

  { "label": "8", "value": "8" },
  { "label": "9", "value": "9" },
  { "label": "10", "value": "10" },
  { "label": "11", "value": "11" },
  { "label": "12", "value": "12" },
  { "label": "13", "value": "13" },
  { "label": "14", "value": "14" },

];

// const prescription = [
//   {

//     "id": 14,
//     "brand_name": "Crocin",
//     "generic_name": "Crocin",
//     "dosage_strength": "100mg",
//     "dosage_type": "Tablet",
//     "morning": 0.5,
//     "afternoon": 1,
//     "evening": 0,
//     "night": 1,
//     "duration": 2,
//     "instructions": "AF",
//     "comments": "After Food"
//   },
//   {

//     "id": 14,
//     "brand_name": "Crocin",
//     "generic_name": "Crocin",
//     "dosage_strength": "100mg",
//     "dosage_type": "Tablet",
//     "morning": 0.5,
//     "afternoon": 1,
//     "evening": 0,
//     "night": 1,
//     "duration": 2,
//     "instructions": "AF",
//     "comments": "After Food"
//   },
// ];

export default class PrescriptionNew extends BaseComponentStyle {
  constructor(props) {
    super(props);
    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      selectedClinic:this.props.selectedClinicOrDoctor,

      brandNameList: [],
      tabletPerDay: tabletPerDay,
      durationList: durationList,
      dosageType: "",
      dosageStrength: "",
      genericName: "",
      instructions: "",
      comments: "",
      drug_ID:"",

      brandName: "",

      type: "M",
      morning: "0",
      afternoon: "0",
      evening: "0",
      night: "0",
      duration: "0",

      selectedDatePrescription: [],
      prescriptionDate: "",
      showNewPrescription: false,
      editPrescriptionData: {},
      prescription_id: "",


      showNewButton: true,
      responceDrugNameList: [],
      dropDownDrugNameListFlag: false,

      responceDrugDetails: [],
      dropDownDrugDetailsFlag: false,


      durationMode: "1",
      selectedDurationButton: {},
      selectedDuration: 0,

      tempId: "",

      showMAENnewButton:true,
      maenNewButtonValue:"",
      responseGenericNameList:[],
      dropDownGenericDetailsFlag:false,
      selectedClinicId:0,
      genericNameDetailsbyDrugName: [],
      drugDetailsbyGenericName:[],

      pharmacyList: [],
      selectedPharmacy: "",

      isNewdrug: false,
      durationText:"",
      isFav: false
    };
  }
  async componentDidMount() {
   var clinicId= await AsyncStorage.getItem("Selected_ClinicIDFor_Doctor");
    this.setState({selectedClinicId:clinicId})

    this.getPreviousPrescription()
    this.getPharmacyList()
  }

  getPharmacyList = async () => {
  
    var service_url = Constants.PHARMACY_LIST + "?clinic_id=" + this.state.selectedClinic.id + "&suggest=" +true;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPharmacyListSuccess,
      this.getPharmacyListFailure
    );
  }
  getPharmacyListSuccess = (success) => {
    if (success.status == "success") {

      this.setState({
        pharmacyList: success.data
      },() => {
        this.setState({
          selectedPharmacy: this.state.pharmacyList[0].pharmacy_id
        },() =>{
          this.getFrequentDrugName()
          this.props.PharmacySelection(this.state.selectedPharmacy)
        })
      })
    }
  }

  getPharmacyListFailure = (error) => {
  }
  getFrequentDrugName() {

    var service_url = Constants.OPTHALMOLOGY_PRESCRIPTION_FREQUENT + "?pharmacy_id=" + this.state.selectedPharmacy


    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFrequentDrugNameSuccess,
      this.getFrequentDrugNameFailure,

    );
  }

  getFrequentDrugNameSuccess = success => {
    if (success.status == "success") {
      var data = success.data;

      var getDrugName = [];

      for (let i = 0; i < data.length; i++) {
        var prepareData = { label: data[i].name, value: data[i].name }
        getDrugName.push(prepareData)
      }

      this.setState({
        brandNameList: getDrugName
      })

    }
  }
  getFrequentDrugNameFailure = error => { }


  // calculateDurationDays(getDuration) {
  //   // var getDuration = "10";




  //   let durationMode = 1;
  //   let duration = parseInt(getDuration) || 0;

  //   // alert("duration " + duration);

  //   if (duration % 7 === 0 && duration > 14) {

  //     duration = duration / 7;
  //     durationMode = 7;

  //     // alert("modul0 7 " + duration);
  //     var prepareData = {
  //       "days": duration,
  //       "type": 7
  //     }
  //     return prepareData
  //   } else if (duration % 30 === 0) {

  //     duration = duration / 30;
  //     durationMode = 30;

  //     // alert("modulo 30 " + duration);

  //     var prepareData = {
  //       "days": duration,
  //       "type": 30
  //     }
  //     return prepareData
  //   } else {
  //     var prepareData = {
  //       "days": duration,
  //       "type": 1
  //     }
  //     return prepareData
  //   }

  // }

  componentWillReceiveProps(props) {


    if (props.showNewPrescription && ((this.state.prescription_id !== props.editPrescriptionData.id) || props.editPrescriptionData.Template_line_id ||(this.state.brandName !== props.editPrescriptionData.brand_name) ||(this.state.duration !== props.editPrescriptionData.duration))) {

      if(props.editPrescriptionData.Template_line_id){
        this.setState({
          Template_line_id: props.editPrescriptionData.Template_line_id
        })
      }

 // New start with new key Durationmode (duration_mode)

      var duration = props.editPrescriptionData.duration;
      var durationmode = props.editPrescriptionData.duration_mode;
      var selectDuration = '1';
      var selectedDurationButton = {};

      if(durationmode){
        if(durationmode === '1'){
          selectDuration = '1';
          selectedDurationButton = { label: duration, value: duration };
        }else if(durationmode === '2'){
          selectDuration = '2';
          selectedDurationButton = { label: duration, value: duration };
        }else if(durationmode=== '3'){
          selectDuration = '3';
          selectedDurationButton = { label: duration, value: duration };
        }
      }

      // old start
      // var selectDuration = "";
      // var selectedDurationButton = {};
      // var selectedDuration = "";
      // if(parseInt(duration) < 14){
      //   selectDuration = "1";
      //   selectedDurationButton = {label:duration,value:duration};
      //   selectedDuration = duration;
      // }else if(parseInt(duration) >= 14 && parseInt(duration) <= 91 ){
      //   selectDuration = "7";
      //   var getVal = duration / 7
      //   selectedDurationButton = {label:getVal,value:getVal};
      //   selectedDuration =duration;
      // }else if(parseInt(duration) > 91 ){
      //   selectDuration = "30";
      //   var getVal = duration / 30
      //   selectedDurationButton = {label:getVal,value:getVal};
      //   selectedDuration = duration;
      // }
      // old ends


      // var calculateDurationDays = this.calculateDurationDays(duration);

      
      //   var selectDuration = calculateDurationDays.type;
      //   var selectedDurationButton = { label: calculateDurationDays.days, value: calculateDurationDays.days };
      //   // var selectedDuration = duration;
      
      
      var {tabletPerDay} =this.state;

      if(props.editPrescriptionData.morning){
        let data ={"label":props.editPrescriptionData.morning,"value":props.editPrescriptionData.morning}
        tabletPerDay.push(data);
      }
      if(props.editPrescriptionData.afternoon){
        let data ={"label":props.editPrescriptionData.afternoon,"value":props.editPrescriptionData.afternoon}
        tabletPerDay.push(data);
      }
      if(props.editPrescriptionData.evening){
        let data ={"label":props.editPrescriptionData.evening,"value":props.editPrescriptionData.evening}
        tabletPerDay.push(data);
      }
      if(props.editPrescriptionData.night){
        let data ={"label":props.editPrescriptionData.night,"value":props.editPrescriptionData.night}
        tabletPerDay.push(data);
      }

      var state = this.state;
      state["prescription_id"] = props.editPrescriptionData.id;
      state["dosageType"] = props.editPrescriptionData.dosage_type;
      state["dosageStrength"] = props.editPrescriptionData.dosage_strength;
      state["genericName"] = props.editPrescriptionData.generic_name;
      state["instructions"] = props.editPrescriptionData.instructions;
      state["comments"] = props.editPrescriptionData.comments;
      
      state["morning"] = props.editPrescriptionData.morning;
      state["afternoon"] = props.editPrescriptionData.afternoon;
      state["evening"] = props.editPrescriptionData.evening;
      state["night"] = props.editPrescriptionData.night;
      
      state["duration"] = props.editPrescriptionData.duration;

      state["brandName"] = props.editPrescriptionData.brand_name;

      state["selectedDurationButton"] = selectedDurationButton;
      state["durationMode"] = selectDuration;
      state["selectedDuration"] = duration;

      state["tempId"] = props.editPrescriptionData.tempId ? props.editPrescriptionData.tempId : "";
      state["drug_ID"] = props.editPrescriptionData.drug_id ? props.editPrescriptionData.drug_id : "";
      state["isFav"] = props.editPrescriptionData.is_favorite

      tabletPerDay = this.removeDuplicate(tabletPerDay)

      this.setState({ state,tabletPerDay }, () => {
        this.props.clearSelectedPrescription(false, {})
      })
    }




    // this.setState({ showNewPrescription: props.showNewPrescription })

    // if (props.prescriptionList) {
    //   this.setState({
    //     selectedDatePrescription: props.prescriptionList.prescription_lines,
    //     prescriptionDate: props.prescriptionList.prescription_date
    //   })
    // }
  }

  title(Title, date, borderNeeded) {
    return (
      <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between', borderBottomColor: borderNeeded ? color.textBoxBorderColor : null, borderBottomWidth: borderNeeded ? 1 : null, paddingBottom: borderNeeded ? 35 : null }}>
        <Text style={{ color: color.darkBlackm, fontSize: 18 }}>{Title}</Text>
        {
          date ?
            <Text style={{ color: color.darkBlackm, fontSize: 18, textAlign: 'right', marginTop: 5 }}>{date}</Text>
            : null
        }
      </View>
    );
  }

  onchangeButtonValue(key, value) {
    this.setState({ brandName: value })
    this.checkPrescriptionDrugDetails(value,"");
  }

  renderPriscriptionButtons = (item, index, key, selectedValue) => {
    return (<TouchableOpacity key={index}
      disabled={this.state.isLocked}
      onPress={() => { this.setState({isNewdrug : false}, () => {this.onchangeButtonValue(key, item.value)}) }}
      style={[{ backgroundColor: this.state.isLocked ? color.disableComponentColor : selectedValue == item.value ? color.themeDark : null, borderWidth: 1, borderColor: selectedValue == item.value ? color.themeDark : color.textBoxBorderColor, padding: '0.5vw', paddingRight: '1vw', paddingLeft: '1vw', marginRight: '0.6vw', marginTop: '0.6vw'}, Style.allButtonBorderRadius]}>
      <Text style={{ color: selectedValue == item.value ? color.white : null, fontSize: '0.8vw' }}>{item.label}</Text>
    </TouchableOpacity>)
  }


  checkPrescriptionDrugNameList(drugName,genericName) {
    // let service_url = Constants.OPTHAMOLOGY_MEDICATION_DRUG_DETAILS + "?brand_name=" + drugName;
    let service_url = Constants.OPTHAMOLOGY_MEDICATION_DRUG_LIST + "?brand_name=" + drugName + "&generic_name=" +genericName + "&clinic_id=" + parseInt(this.state.selectedClinicId) + "&pharmacy_id=" + parseInt(this.state.selectedPharmacy);

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this._getDrugNameListSuccess,
      this._getDrugNameListFailure
    );
  }
  _getDrugNameListSuccess = success => {
    if (success.status == "success") {
      if(success.message === "Brand name list"){
        this.setState({
          responceDrugNameList: success.data,
          dropDownDrugNameListFlag: true,
          isNewdrug: success.data.length == 0 ? true : false
        })
      }else if(success.message === "Generic name list"){
        this.setState({
          responseGenericNameList: success.data,
          dropDownGenericDetailsFlag: true
        })
      }
    }
  }

  _getDrugNameListFailure = error => {

  }


  renderTextBox(placeholder, key, value, width) {
    return (
      <View style={{ marginTop: key == "brandName" ? 0 : '0.5vw' }}>
        <Text style={{ marginBottom: key == "brandName" ? 0 : '0.4vw', backgroundColor: "transparent", fontSize: '0.8vw', paddingLeft: '0.3vw', paddingRight: '0.3vw', color: "#888888" }}>{placeholder}</Text>
        <TextInput
          editable={!this.state.isLocked}
          multiline={key == "comments" ? true : false}
          // placeholder={placeholder}
          underlineColorAndroid="transparent"
          style={[{
            // marginTop:5,
            backgroundColor: this.state.isLocked ? color.disableComponentColor : "white",
            marginRight: '1.2vw', fontSize: '0.9vw',
            // borderRadius: 10, 
            borderColor: color.rightPanelInputBorder, borderWidth: 1, width: width, height: key == "comments" ? '6.3vw' : '2.6vw', paddingTop: key == "comments" ? '0.6vw' : null, paddingLeft: "comments" ? '0.6vw' : null
          }, Style.allButtonBorderRadius]}
          placeholderTextColor={color.textBoxBorderColor}
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            this.onChangeText(text, key)
          }
          }
          onSubmitEditing={() => {
            if (key == "brandName"){
              this.setState({ showNewButton: true }, () => {
                if (this.state.brandName) {
                  this.setDrugnameAndChangeFlags(this.state.brandName,"")
                }
              })
            }else if(key == "genericName"){
              if (this.state.genericName) {
                this.checkPrescriptionDrugNameList("",this.state.genericName)
              }
            }  
          }}
        />
         {
           this.state.dropDownGenericDetailsFlag  && key == "genericName"?
             <View style={styles.DropDowngenericList}>
                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>
                  {
                     this.state.responseGenericNameList.length > 0 && this.state.responseGenericNameList.map((drugDetails, index) => {
                       return(
                         <TouchableOpacity onPress={()=>{
                          this.checkPrescriptionDrugDetails("",drugDetails,index)
                          var states = this.state 
                          // states["genericName"] = drugDetails
                          states["dropDownGenericDetailsFlag"] = false
                          this.setState({states})
                         }}>
                           <Text style={{color:color.white, fontSize: '0.9vw'}}>{drugDetails}</Text>
                         </TouchableOpacity>
                       )
                    })
                  }           
                </ScrollView>
              </View>
            : null
          }
      </View>
    );
  }
  onChangeText(value, key) {
    var dosage = this.state;
    if (key == "dosageType") {
      dosage["dosageType"] = value;
    } else if (key == "dosageStrength") {
      dosage["dosageStrength"] = value;
    } else if (key == "drug_ID") {
      dosage["drug_ID"] = value;
    } else if (key == "genericName") {
      dosage["genericName"] = value;
      this.checkPrescriptionDrugNameList("",value);
    } else if (key == "comments") {
      dosage["comments"] = value;
    } else if (key == "brandName") {
      dosage["brandName"] = value;
      this.checkPrescriptionDrugNameList(value,"");
    }

    this.setState({
      dosage
    })
  }

  renderTablets(item, index, type) {
    return (
      <TouchableOpacity onPress={() => {
        if (type == "tablet") {
          this.tabletPerDay(item.value);
        }

      }} key={index} style={[{
        marginBottom: '1vw', marginRight: '1.3vw', justifyContent: 'center', height: '2vw', minWidth:'2vw',maxWidth:'10.6vw', borderColor: color.textBoxBorderColor, borderWidth: 1,
        backgroundColor:

          this.state.isLocked ? color.disableComponentColor :

            ((this.state.type == "M" && item.value == this.state.morning) ||
              (this.state.type == "N" && item.value == this.state.night) ||
              (this.state.type == "E" && item.value == this.state.evening) ||
              (this.state.type == "A" && item.value == this.state.afternoon))
              ? color.themeDark : null
        ,

      }, Style.allButtonBorderRadius]}
        disabled={this.state.isLocked}
      >
        <Text style={{
          fontSize: '0.8vw', textAlign: 'center',
          color:
            ((this.state.type == "M" && item.value == this.state.morning) ||
              (this.state.type == "N" && item.value == this.state.night) ||
              (this.state.type == "E" && item.value == this.state.evening) ||
              (this.state.type == "A" && item.value == this.state.afternoon)) ?
              color.white : null

        }}>{item.label}</Text>
      </TouchableOpacity>
    )
  }

  selectDuration(item, index, type, from) {
    return (
      <TouchableOpacity onPress={() => {
        if (type == "duration") {
          this.setState({ duration: item.value })
        }

      }} key={index} style={[styles.SelectDurationTouch, {
         borderColor: color.textBoxBorderColor, 
        backgroundColor: (type == "duration" && this.state.duration == item.value) ? color.themeDark : null
      }]}>
        <Text style={{
          fontSize: '0.8vw', textAlign: 'center',
          color: (type == "duration" && this.state.duration == item.value) ? color.white : null

        }}>{item.label}{type == "duration" ? "D" : null}</Text>
      </TouchableOpacity>
    )
  }

  tabletPerDay(value) {
    if (this.state.type == "M")
      this.setState({ morning: value });
    else if (this.state.type == "A")
      this.setState({ afternoon: value });
    else if (this.state.type == "E")
      this.setState({ evening: value });
    else if (this.state.type == "N")
      this.setState({ night: value });

  }

  renderTabletsDays(days, index, type) {
    return (
      <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
        this.setState({ type: days ,maenNewButtonValue:"",showMAENnewButton:true})
      }} key={index} style={styles.TabletDaysTouch}>
        <Text style={{ fontSize: '0.8vw', textAlign: 'center', color: type == days ? color.themeDark : color.darkBlack, fontWeight: type == days ? "bold" : null }}>{days}</Text>
      </TouchableOpacity>
    )
  }

  renderinstructions(image, name, key, selected) {
    return (
     <View style={{marginTop:'0.6vw'}}>
      <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.setState({ instructions: key }) }} style={styles.InstructionsTouch}>
        <Image
          source={image}
          style={{ width: "50%", height: name=="Empty Stomach"?"30%":"50%", tintColor: this.state.instructions == key ? color.themeDark : "#888888",marginBottom:'0.6vw'  }}
        />
        <Text style={{ fontSize: '0.8vw', color: this.state.instructions == key ? color.themeDark : "#a9a9a9",textAlign:"center"}}>{name}</Text>
      </TouchableOpacity>
     </View>  
    );
  }

  clearAllData() {
    var states = this.state;

    states["morning"] = "0";
    states["afternoon"] = "0";
    states["evening"] = "0";
    states["night"] = "0";
    states["selectedDuration"] = 0;
    states["instructions"] = "";
    states["comments"] = "";
    states["brandName"] = "";
    states["genericName"] = "";
    states["dosageStrength"] = "";
    states["dosageType"] = "";
    states["selectedDurationButton"] = {};
    states["durationMode"]="1",
    states["prescription_id"] = "",
      states["type"] = "M"
    states["showNewButton"] = true
    states["dropDownDrugDetailsFlag"]=false
    states["tempId"] = ""
    states["isNewdrug"] = false
    states['isFav'] = false
    this.setState({ states })

  }


  addPrescriptionDatas() {
    let states = this.state;
    let data = {
      "pharmacy_id": this.state.selectedPharmacy,
      "morning": this.state.morning ? this.state.morning : "0",
      "afternoon": this.state.afternoon ? this.state.afternoon : "0",
      "evening": this.state.evening ? this.state.evening : "0",
      "night": this.state.night ? this.state.night : "0",
      "duration": states.durationText ? states.durationText : states.selectedDurationButton.value,
      "duration_mode": this.state.durationMode,
      "instructions": this.state.instructions,
      "comments": this.state.comments,
      "brand_name": this.state.brandName,
      "generic_name": this.state.genericName,
      "dosage_strength": this.state.dosageStrength,
      "dosage_type": this.state.dosageType,
      "drug_id": this.state.drug_ID,
      "is_favorite": this.state.isFav
    };

    if(this.state.Template_line_id) {
      data["Template_line_id"] = this.state.Template_line_id
    }

    if (this.state.prescription_id) {
      data["id"] = this.state.prescription_id
    }
    // if(this.state.selectedPharmacy != "") {
    //   data["Pharmacy_id"]= this.state.selectedPharmacy

    // }

    if (this.state.tempId) {
      data['tempId'] = this.state.tempId;
    }
    else {
      data['tempId'] = "";
    }


    // console.log("data ~~~~~~~~~~~~~~~~~~~~~~~~> " + JSON.stringify(data))

    if ((this.state.selectedDurationButton || this.state.brandName || this.state.genericName || this.state.dosageStrength || this.state.dosageType) &&
       Object.keys(this.state.selectedDurationButton).length > 0  && this.state.brandName && this.state.genericName && (parseFloat(states.morning) != 0 || parseFloat(states.afternoon) != 0 || parseFloat(states.evening) != 0 || parseFloat(states.night) != 0)) {
      this.props.addNewPrescription(data);
      this.clearAllData();
    } else {
      if (this.state.brandName == 0) {
        this.props.showResposeValue("error", Messages.InvalidBrand);
      }else if (states.morning == "0" && states.afternoon == "0" && states.evening == "0" && states.night == "0"){
        this.props.showResposeValue("error", Messages.InvalidMAEN);
      }
      else if (Object.keys(this.state.selectedDurationButton).length === 0) {
        this.props.showResposeValue("error", Messages.InvalidDuration);
      }else if (this.state.instructions == "") {
        this.props.showResposeValue("error", Messages.InvalidFoodType);
      }else if (this.state.genericName == 0) {
        this.props.showResposeValue("error", Messages.InvalidGeneric);
      } else {
        this.props.showResposeValue("error", Messages.PrescriptionNewEmptyField);
      }
    }


  }


  renderAddButton = (btnName, type) => {
    return (
      <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
        if (type == "add") {
          this.addPrescriptionDatas();
        }
        // else if (type == "addAll") {
        //   var selectedDatePrescription = this.state;
        //   if (selectedDatePrescription.length > 0) {
        //     for (var i = 0; i < selectedDatePrescription.length; i++) {
        //       this.props.addNewPrescription(selectedDatePrescription[i])
        //     }
        //   }
        //   // this.props.addNewPrescription(this.state.selectedDatePrescription);
        // }
      }} >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: '0.6vw',
            flexDirection: "row",
            borderColor: color.themeDark,
            borderWidth: 1,
            backgroundColor: color.themeDark,
            // width:"85%",
            // padding: 20,
            paddingTop: '0.3vw',
            // paddingBottom: 10,
            paddingBottom: '0.3vw',
            // marginRight: 2,
            // marginTop: 20
          }}
        >
          <Text
            style={{
              fontSize: '1.2vw',
              textAlign: "center",
              color: color.white,
              fontWeight: "500"
            }}
          >
            {btnName}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderSeletedDatePrescription(prescription, index) {
    var instructions
    if (prescription.instructions == "WF")
      instructions = "With Food";
    else if (prescription.instructions == "BF")
      instructions = "Before Food";
    else if (prescription.instructions == "ES")
      instructions = "Empty Stomach";
    else if (prescription.instructions == "AF")
      instructions = "After Food";
    else if (prescription.instructions == "EU")
      instructions = "External Use";
    return (
      <View style={{ paddingBottom: 20, paddingTop: 20, flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: color.textBoxBorderColor }} key={index}>
        <View style={{ width: "15%" }}>
          <Image
            source={require("../../../../assets/images/Prescription_Icons/pill.svg")}
            style={{ width: 30, height: 30, }}
          />
        </View>
        <View style={{ width: "85%" }}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '42%', }}>
              <Text style={{ fontSize: 17 }}>{prescription.brand_name}</Text>
              <View style={{ flexDirection: 'row', marginTop: 8 }}>
                <Text style={{ fontSize: 12, borderRightColor: color.textBoxBorderColor, borderRightWidth: 1, paddingRight: 8 }}>{prescription.dosage_strength}</Text>
                <Text style={{ fontSize: 12, paddingLeft: 8 }}>{prescription.generic_name}</Text>
              </View>
            </View>

            <View style={{ width: "28%" }}>
              <Text style={{ fontSize: 14 }}>{prescription.morning}{"-"}{prescription.afternoon}{"-"}{prescription.evening}{"-"}{prescription.night}</Text>
              <Text style={{ marginTop: 10, fontSize: 12, marginLeft: 2 }}>{prescription.duration}{" Days"}</Text>
            </View>

            <View style={{ justifyContent: 'center', width: '30%', marginTop: 8 }}>
              <TouchableOpacity onPress={() => { this.props.addNewPrescription(prescription) }} style={{ width: "80%", borderColor: color.textBoxBorderColor, borderWidth: 1, borderRadius: 30, paddingTop: 5, paddingBottom: 5 }}>
                <Text style={{ textAlign: 'center' }}>{"Add"}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ marginTop: 16 }}>
            <Text style={{ fontSize: 13 }}>{instructions}</Text>
            <Text style={{ marginTop: 8, fontSize: 12 }}>{prescription.comments}</Text>
          </View>
        </View>
      </View>
    );
  }

  getPreviousPrescription() {

    let { userType } = this.state;

    let service_url = "";

    if (Constants.ROLE_FO == userType) {

      service_url = Constants.FO_PREVIOUS_PRESCRIPTIONS +
        "?patient_id=" + this.state.patientAppointment.patient_id +
        "&appointment_id=" + this.state.patientAppointment.appointment_id
      // "&prescription_id="+date.prescription_id;
    } else {

      service_url = Constants.DOCTOR_PREVIOUS_PRESCRIPTIONS +
        "?patient_id=" + this.state.patientAppointment.patient_id +
        "&appointment_id=" + this.state.patientAppointment.appointment_id
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPreviousPrescriptionSuccess,
      this.getPreviousPrescriptionFailure
    );
  }

  getPreviousPrescriptionSuccess = success => {
    if (success.status == "success") {
      this.setState({
        selectedDatePrescription: success.data.prescription_lines,
        prescriptionDate: success.data.prescription_date
      })
    }
  }
  getPreviousPrescriptionFailure = error => { }

  checkPrescriptionDrugDetails(drugName, genericName, index) {
    if(this.state.brandName!="" && genericName!=""&& (index!="" || index==0)){
      var brandNameList =this.state.brandNameList
       var state = this.state;
      var data = { "label": this.state.genericNameDetailsbyDrugName[index].drug_name == null ? "" : this.state.genericNameDetailsbyDrugName[index].drug_name, "value": this.state.genericNameDetailsbyDrugName[index].drug_name == null ? "" : this.state.genericNameDetailsbyDrugName[index].drug_name }
      brandNameList.push(data)
      var brandNameJsonObject = brandNameList.map(JSON.stringify);
      var brandNameUniqueSet = new Set(brandNameJsonObject);
      var brandNameUniqueArray = Array.from(brandNameUniqueSet).map(JSON.parse)

      state["dosageType"] = this.state.genericNameDetailsbyDrugName[index].dosage_type == null ? "" : this.state.genericNameDetailsbyDrugName[index].dosage_type
      state["dosageStrength"] = this.state.genericNameDetailsbyDrugName[index].dosage_strength == null ? "" : this.state.genericNameDetailsbyDrugName[index].dosage_strength
      state["genericName"] = this.state.genericNameDetailsbyDrugName[index].generic_name == null ? "" : this.state.genericNameDetailsbyDrugName[index].generic_name
      state["brandName"] = this.state.genericNameDetailsbyDrugName[index].drug_name == null ? "" : this.state.genericNameDetailsbyDrugName[index].drug_name
      state["brandNameList"] = brandNameUniqueArray
      state["drug_ID"] = this.state.genericNameDetailsbyDrugName[index].drug_id == null ? "" : this.state.genericNameDetailsbyDrugName[index].drug_id
      state["isFav"] = this.state.genericNameDetailsbyDrugName[index].is_favorite
      this.setState({ state })
      return;
    }else if(drugName!="" && this.state.genericName!=""&& index!= undefined && (index!="" && index==0)){
      var brandNameList =this.state.brandNameList
      var state = this.state;
      var data = { "label": this.state.drugDetailsbyGenericName[index].drug_name == null || this.state.drugDetailsbyGenericName[index].drug_name == undefined ? "" : this.state.drugDetailsbyGenericName[index].drug_name, "value": this.state.drugDetailsbyGenericName[index].drug_name == null || this.state.drugDetailsbyGenericName[index].drug_name == undefined ? "" : this.state.drugDetailsbyGenericName[index].drug_name }
      brandNameList.push(data);
      var brandNameJsonObject = brandNameList.map(JSON.stringify);
      var brandNameUniqueSet = new Set(brandNameJsonObject);
      var brandNameUniqueArray = Array.from(brandNameUniqueSet).map(JSON.parse)
      state["dosageType"] = this.state.drugDetailsbyGenericName[index].dosage_type == null ? "" : this.state.drugDetailsbyGenericName[index].dosage_type
      state["dosageStrength"] = this.state.drugDetailsbyGenericName[index].dosage_strength == null ? "" : this.state.drugDetailsbyGenericName[index].dosage_strength
      state["genericName"] = this.state.drugDetailsbyGenericName[index].generic_name == null ? "" : this.state.drugDetailsbyGenericName[index].generic_name
      state["brandName"] = this.state.drugDetailsbyGenericName[index].drug_name == null ? "" : this.state.drugDetailsbyGenericName[index].drug_name
      state["brandNameList"] = brandNameUniqueArray
      state["drug_ID"] = this.state.drugDetailsbyGenericName[index].drug_id == null ? "" : this.state.drugDetailsbyGenericName[index].drug_id
      state["isFav"] = this.state.genericNameDetailsbyDrugName[index].is_favorite
      this.setState({ state })
      return;
    }
    let service_url = Constants.OPTHAMOLOGY_MEDICATION_GENERIC_DRUG_DETAILS + "?brand_name=" + drugName + "&generic_name=" + genericName + "&clinic_id=" + parseInt(this.state.selectedClinicId) + "&pharmacy_id=" + this.state.selectedPharmacy;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this._getDrugDetailsSuccess(drugName, genericName),
      this._getDrugFailure
    );
  }
  _getDrugDetailsSuccess=(dn,gn) => (success) => {
    if (success.status == "success" && success.data.length > 0) {
      if(gn!=""){
        // if(this.state.brandName!=""){
        //   this.setState({
        //     responceDrugNameList: [],
        //   //responseGenericNameList: success.data,
        //   dropDownDrugNameListFlag: false,
        //   //brandName:"",
        //   showNewButton: true
        //   })
        // }else{
        console.log("Generic Name search:::", this.state.brandName);
        var drugNameList = [];
        success.data.forEach(element => {
        drugNameList.push(element.drug_name);
      });
      this.setState({
        responceDrugNameList: drugNameList,
        //responseGenericNameList: success.data,
        dropDownDrugNameListFlag: true,
        brandName:" ",
        showNewButton: false,
        drugDetailsbyGenericName: success.data
        //dropDownGenericDetailsFlag: true
      })
    //}
      }else{
        var genNameList = [];
        success.data.forEach((element)=>{
          genNameList.push(element.generic_name)
        })
        this.setState({
          genericNameDetailsbyDrugName: success.data,
          responseGenericNameList: genNameList,
          dropDownGenericDetailsFlag: true
        })
    // var brandNameList =this.state.brandNameList
    // var state = this.state;
    // var data = { "label": success.data[0].drug_name == null ? "" : success.data[0].drug_name, "value": success.data[0].drug_name == null ? "" : success.data[0].drug_name }
    // brandNameList.push(data)
    // var brandNameJsonObject = brandNameList.map(JSON.stringify);
    // var brandNameUniqueSet = new Set(brandNameJsonObject);
    // var brandNameUniqueArray = Array.from(brandNameUniqueSet).map(JSON.parse)

    //   state["dosageType"] = success.data[0].dosage_type == null ? "" : success.data[0].dosage_type
    //   state["dosageStrength"] = success.data[0].dosage_strength == null ? "" : success.data[0].dosage_strength
    //   state["genericName"] = success.data[0].generic_name == null ? "" : success.data[0].generic_name
    //   state["brandName"] = success.data[0].drug_name == null ? "" : success.data[0].drug_name
    //   state["brandNameList"] = brandNameUniqueArray
    //   this.setState({ state })
    }
    } else if (success.status == "success" && success.data.length == 0) {

      var state = this.state;

      state["dosageType"] = ""
      state["dosageStrength"] = ""
      state["genericName"] = ""
      state["drug_ID"] = ""
      state["isFav"] = false
      this.setState({ state })

    } else {
      this.setState({
        responceDrugDetails: success.data,
        dropDownDrugDetailsFlag: true
      })
    }



  }
  _getDrugFailure = (error) => { }

  setDrugnameAndChangeFlags(brandName, index) {
    var brandNameList = this.state.brandNameList;


    var data = { "label": brandName, "value": brandName }
    brandNameList.push(data)

    var brandNameJsonObject = brandNameList.map(JSON.stringify);
    var brandNameUniqueSet = new Set(brandNameJsonObject);
    var brandNameUniqueArray = Array.from(brandNameUniqueSet).map(JSON.parse);
    // selectedPatient["brandName"] = brandNameUniqueArray


    this.setState({
      brandNameList: brandNameUniqueArray,
      brandName: brandName,
      dropDownDrugNameListFlag: false,
      showNewButton: true
    }, () => {
      this.checkPrescriptionDrugDetails(brandName,"",index);
    });


  }

  showDropDownDrugNameList(brandName, index) {
    return (
      <TouchableOpacity key={index} style={styles.DropDownNameListTouch} onPress={() => { this.setDrugnameAndChangeFlags(brandName,index) }}>
        <Text style={{ color: color.white, fontSize: '0.8vw' }}>{brandName}</Text>
      </TouchableOpacity>
    )
  }

  showDropDownDrugDetails(drugDetails, index) {
    return (
      <TouchableOpacity key={index} style={styles.DruglistDropDown} onPress={() => {

        var state = this.state;

        state["dosageType"] = drugDetails.dosage_type
        state["dosageStrength"] = drugDetails.dosage_strength
        state["genericName"] = drugDetails.generic_name
        state["drug_ID"] = drugDetails.drug_id

        this.setState({ state, dropDownDrugDetailsFlag: false })

      }}>
        <Text style={{ color: color.white, fontSize: '0.8vw' }}>{drugDetails.generic_name}{" "}{drugDetails.dosage_strength}</Text>
      </TouchableOpacity>
    )
  }

  // calculateDays() {
  //   let getDurationType = parseInt(this.state.durationMode)
  //   let getSelectedDurationButton = parseInt(this.state.selectedDurationButton.value)

  //   let getSelectedDuration = getDurationType * getSelectedDurationButton

  //   this.setState({
  //     selectedDuration: getSelectedDuration
  //   })

  // }

  onPressButton(key, selectedvalue) {
    if (key == "selectedDurationButton") {
      this.setState({
        selectedDurationButton: selectedvalue
      })
         //, () => { 
      //   this.calculateDays() 
      // }
    }
  }


  showDurationView() {
    var durationButton = []
    durationButton = this.state.durationMode ==='1'? Constants.DURATION_DAY : this.state.durationMode ==='2'? Constants.DURATION_WEEKS : this.state.durationMode === "3" ? Constants.DURATION_MONTHS : []

    return (
      <View style={{ marginBottom: '0.6vw', zIndex: -1 }}>

        <View style={[styles.DurationView, {backgroundColor: color.sectionHeadingDarkColor}]}>
          <View>
            <CommonHistorySectionHeader
              heading1={"Duration"}
              noOfColumn={1}
            />
          </View>
          <View>
            <Picker
              enabled={!this.state.isLocked}
              selectedValue={this.state.durationMode}
              style={[Style.pickerRoundCornerView, Style.allButtonBorderRadius, { height: '1.5vw', marginRight: '0.6vw' }]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["durationMode"] = itemvalue;
                states["selectedDurationButton"] = {};
                this.setState({ states }); //, () => { this.calculateDays() }
              }}
            >
              <Picker.Item label="Days" value="1" />
              <Picker.Item label="Weeks" value="2" />
              <Picker.Item label="Months" value="3" />
            </Picker>
          </View>
        </View>


        <View style={{ flexDirection: 'row', flexWrap: 'wrap', zIndex: -1, }}>
          {
            durationButton.map((item, index) => {
              // var data
              // if(this.state.id){
              //   data = this.state.selectedEye
              // }else{
              //   data = this.state.systamicAllergySelectedItems
              // }
              return (
                <View style={{ marginBottom: '0.6vw', marginRight: '0.6vw' }} key={index}>
                  <CommonButton
                    disable={this.state.isLocked}
                    item={item}
                    selectedvalue={this.state.selectedDurationButton}
                    butttonText={item.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedDurationButton"} />
                </View>
              )
            })
          }
        </View>

      </View>
    )
  }

  triggerNewLabTestadd() {
    this.setState({ showNewButton: false })
  }

  render() {
    const { selectedDatePrescription } = this.state;
    return (

      <TouchableWithoutFeedback onPress={() => {
      this.setState({dropDownDrugDetailsFlag:false})
      }}>
      <View>
       <View style={{  flexDirection: "row", justifyContent: "space-between"  }}>
         <DoctorNotesCommonRightSideHeader title={"Prescription"} clearAllData={this.clearAllData.bind(this)} />
         <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
          <Picker
            enabled={!this.state.isLocked}
            selectedValue={this.state.selectedPharmacy}
            style={styles.PrescriptionDropDown}
            itemStyle={{}}
            onValueChange={(itemvalue) => {
              this.setState({ selectedPharmacy: itemvalue },()=>{
                this.props.PharmacySelection(this.state.selectedPharmacy)
                this.getFrequentDrugName()
              });
            }}
          >
            {this.state.pharmacyList.map((i, index) => (
              <Picker.Item key={index} label={i.pharmacy_name} value={i.pharmacy_id} />
            ))}
          </Picker>
        </View>
       </View> 

        {
          // !this.state.showNewPrescription ?
          //   <View>
          //     {this.title("Previous Prescription", this.state.prescriptionDate, true)}

          //     <View>
          //       {
          //         selectedDatePrescription && selectedDatePrescription.map((prescription, index) => {
          //           return this.renderSeletedDatePrescription(prescription, index);
          //         })
          //       }
          //     </View>

          //     {
          //       selectedDatePrescription && selectedDatePrescription.length > 0 ?
          //         <View>
          //           {this.renderAddButton("Add All", "addAll")}
          //         </View>
          //         : null
          //     }

          //   </View>

          //   :



          <View>
            <CommonHistorySectionHeader
              heading1={"Brand Name"}
              noOfColumn={1}
            />
            {this.title("", "", false)}
            <View style={{
              // justifyContent: 'space-evenly' 
              flexDirection: 'row', flexWrap: 'wrap'
            }}>

              {
                this.state.brandNameList.map((item, index) => {
                  return this.renderPriscriptionButtons(item, index, "brandName", this.state.brandName)
                })
              }
            </View>

            <View style={{ marginTop: '0.6vw' }}>


              {
                this.state.showNewButton ?

                  <View style={{}}>{(
                    this.renderIconNewBtn(
                      "plus",
                      "New",
                      true,
                      true,
                      this.triggerNewLabTestadd.bind(this),
                      this.state.isLocked
                    )
                  )}</View>

                  // <TouchableOpacity disabled={this.state.isLocked} onPress={() => this.setState({ showNewButton: false })} >
                  //   <View
                  //     style={[{
                  //       flexDirection: "row",
                  //       backgroundColor: color.white,
                  //       // justifyContent: "space-evenly",
                  //       borderWidth: 1,
                  //       borderColor: "#848484"
                  //     }, Style.componentBtnDDPadding]}
                  //   >
                  //     <Icon style={{ marginRight: 15 }} name={"plus"} size={platform === "web" ? 15 : 20} color={"#848484"} />
                  //     <Text
                  //       style={{
                  //         fontSize: platform === "web" ? 13 : 13,
                  //         color: "#848484",
                  //         fontWeight: "700"
                  //       }}
                  //     >{"New"}</Text>
                  //   </View>
                  // </TouchableOpacity>
                  :

                  <View style={{ width: "100%" }}>

                    {this.renderTextBox("", "brandName", this.state.brandName, "90%")}
                    {
                      this.state.dropDownDrugNameListFlag && this.state.brandName !== "" ?
                        <View style={styles.DrugNameDropName}>
                          <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>
                            {
                              this.state.responceDrugNameList.length > 0 && this.state.responceDrugNameList.map((drugName, index) => {
                                return this.showDropDownDrugNameList(drugName, index);
                              })
                            }
                          </ScrollView>
                        </View>

                        : null
                    }

                  </View>
              }
              <View style={{ marginTop: '0.6vw', zIndex: -1 }}>
                <CommonHistorySectionHeader
                  heading1={"Generic Name"}
                  heading2={"Dosage Strength"}
                  heading3={"Dosage Type"}
                  columnSize={[0.3,0.37,0.3]}
                  //total value is == of 1
                  noOfColumn={3}
                />
              </View>

              <View style={{ flexDirection: "row", zIndex: -1 }}>
                <View style={{ width: this.state.showNewButton ? "35%" : "35%" }}>
                  {this.renderTextBox("", "genericName", this.state.genericName, "80%")}
                  {
                    this.state.dropDownDrugDetailsFlag ?
                      <View style={{ backgroundColor: "black", borderRadius: '0.6vw', position: 'absolute', zIndex: 1, }}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>
                          {
                            this.state.responceDrugDetails?.length > 1 && this.state.responceDrugDetails.map((drugDetails, index) => {
                              return this.showDropDownDrugDetails(drugDetails, index);
                            })
                          }           
                        </ScrollView>
                      </View>

                      : null
                  }
                </View>


                <View style={{ width: " 35%",zIndex:-1 }}>
                  {this.renderTextBox("", "dosageStrength", this.state.dosageStrength)}
                  {/* {this.renderTextBox("Dosage Type", "dosageType", this.state.dosageType, "50%")} */}
                </View>

                <View style={{ width: " 35%",zIndex:-1 }}>
                  {/* {this.renderTextBox("Dosage Strength", "dosageStrength", this.state.dosageStrength, "50%")} */}
                  {this.renderTextBox("", "dosageType", this.state.dosageType)}
                </View>
              </View>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: '1.2vw', marginBottom: '0.6vw', zIndex: -1, backgroundColor: color.sectionHeadingDarkColor }}>
              {["M", "A", "E", "N"].map((days, index) => {
                return this.renderTabletsDays(days, index, this.state.type);
              })}
            </View>

            <View style={{ marginBottom: '0.6vw', flex: 1, flexDirection: 'row', flexWrap: 'wrap', zIndex: -1, }}>
              {
                this.state.tabletPerDay.map((item, index) => {
                  return this.renderTablets(item, index, "tablet")
                })
              }



              {
                this.state.showMAENnewButton ? 
<TouchableOpacity onPress={() => { 
                  this.setState({
                    showMAENnewButton:false
                  })
                }}
                  style={[{
                    marginBottom: '1vw', marginRight: '1.5vw',alignItems:'center',justifyContent: 'space-evenly',flexDirection:'row', height: '2vw', width: '5.6vw', borderColor: color.textBoxBorderColor, borderWidth: 1,
                    backgroundColor: null }, Style.allButtonBorderRadius]}
                  disabled={this.state.isLocked}
                >
                  <NewButtonIcon style={{}} name={"plus"} size={'1vw'} color={"#848484"} />

                  <Text style={{ fontSize: '0.8vw',color: "#848484" }}>{"NEW"}</Text>
                </TouchableOpacity> :

                <TextInput
                value={this.state.maenNewButtonValue}
                maxLength={30}
                autoFocus={true}
                style={[{
                  marginBottom: '1vw', marginRight: '1.5vw',alignItems:'center',paddingLeft:'0.6vw',height: '2vw', width: '7vw', borderColor: color.textBoxBorderColor, borderWidth: 1,
                  backgroundColor: null, fontSize: '0.8vw' }, Style.allButtonBorderRadius]}

                  onChangeText={(text)=>{
                    this.setState({
                      maenNewButtonValue:text
                    })
                  }}
                  onSubmitEditing={()=>{
                    this.addNewItemInMAENList()
                  }}
                />
                

              }
                



            </View>
            {/* {this.title("Duration", "", false)}

              <View style={{ marginBottom: 20, marginTop: 10, flex: 1, flexDirection: 'row', flexWrap: 'wrap', zIndex: -1, }}>
                {
                  this.state.durationList.map((item, index) => {
                    return this.selectDuration(item, index, "duration", this.state.duration)
                  })
                }
              </View> */}

            {this.showDurationView()}

            <View style={{ flexDirection: 'row', zIndex: -1,backgroundColor:"#ededed",marginBottom:'0.6vw'}}>

              {this.renderinstructions(
                require("../../../../assets/images/Prescription_Icons/EmptyStomach.png"),
                "Empty Stomach",
                "ES",
                this.state.instructions
              )}
              {this.renderinstructions(
                require("../../../../assets/images/Prescription_Icons/icons/BF.svg"),
                "Before Food",
                "BF",
                this.state.instructions
              )}
              {this.renderinstructions(
                require("../../../../assets/images/Prescription_Icons/icons/WF.svg"),
                "With Food",
                "WF",
                this.state.instructions
              )}
              {this.renderinstructions(
                require("../../../../assets/images/Prescription_Icons/icons/AF.svg"),
                "After Food",
                "AF",
                this.state.instructions
              )}
              {this.renderinstructions(
                require("../../../../assets/images/Prescription_Icons/icons/AF.svg"),
                "External Use",
                "EU",
                this.state.instructions
              )}
            </View>

            <View>
              <CommonHistorySectionHeader
                heading1={"Comments"}
                noOfColumn={1}
              />
              {this.renderTextBox("", "comments", this.state.comments, "100%")}
            </View>

            {/* <View style={{marginTop:20}}>
              {this.renderAddButton("Add", "add")}
            </View> */}

            {this.state.isNewdrug ?<View style={{height:"3vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Text style={{color: "red", fontSize:".9vw", fontWeight: "500", marginTop:".5vw"}}>Note : This Drug is not available in our pharmacy</Text>
            </View> : null }



            <View style={{ marginTop: '.5vw', alignSelf: "center" }}>
              <TouchableOpacity onPress={() => {
                this.addPrescriptionDatas();
                // this.addVital()} disabled={this.state.isLocked}
              }} >
                {this.renderTextBtn("Add", true, false)}
              </TouchableOpacity>
            </View>




            {/* <View style={{ marginTop: 20, zIndex: -10, zIndex: -8 }}>
                <TouchableOpacity onPress={this.addPrescriptionDatas()} >
                  {this.renderTextBtn("Add", true, false)}
                </TouchableOpacity>
              </View > */}

          </View>
        }
      </View>
      </TouchableWithoutFeedback>
    );
  }

  addNewItemInMAENList(){

    var data ={ "label": this.state.maenNewButtonValue, "value": this.state.maenNewButtonValue }

    var {tabletPerDay} = this.state
    var states =  this.state;

    tabletPerDay.push(data);

    tabletPerDay= this.removeDuplicate(tabletPerDay);

    if(this.state.type == "M"){
      states["morning"] = this.state.maenNewButtonValue
    }else if(this.state.type == "N"){
      states["night"] = this.state.maenNewButtonValue
    }else if(this.state.type == "E"){
      states["evening"] = this.state.maenNewButtonValue
    }else if(this.state.type == "A"){
      states["afternoon"] = this.state.maenNewButtonValue
    }

    this.setState({
      tabletPerDay,states,maenNewButtonValue:"",showMAENnewButton:true
    })

  }

}
const styles = StyleSheet.create({
    PrescriptionDropDown: { 
      borderRadius: '1.2vw', 
      height: '2.3vw', 
      fontSize: '0.8vw', 
      backgroundColor: "transparent", 
      borderRadius: 4,
    },
    DrugNameDropName: { 
      backgroundColor: "black", 
      borderRadius: '0.6vw', 
      zIndex: 1, 
      position: 'absolute', 
      marginTop: '3vw' 
    },
    DropDowngenericList: { 
      backgroundColor: "black", 
      borderRadius: 10, 
      position: 'absolute', 
      zIndex: 1,
      minWidth:'14.3vw' ,
      marginTop:'3vw'
    },
    DruglistDropDown: { 
      paddingLeft: '0.5vw', 
      paddingTop: '0.5vw', 
      paddingRight: '0.6vw' 
    },
    SelectDurationTouch: {
      marginBottom: '1vw', 
      marginRight: '1.3vw', 
      justifyContent: 'center', 
      height: '2vw', 
      width: '2vw',
      borderRadius: '3.3vw', 
      borderWidth: 1
    },
    TabletDaysTouch: { 
      marginRight: '1.3vw', 
      justifyContent: 'center', 
      height: '2vw', 
      width: '2vw'
    },
    InstructionsTouch: { 
      alignItems: 'center', 
      height: '5vw', 
      width: '5vw',
      marginLeft:'0.6vw'
     },
     DropDownNameListTouch: { 
      paddingLeft: '0.5vw', 
      paddingTop: '0.5vw', 
      zIndex: 1 
    },
    DurationView: { 
      flexDirection: "row", 
      alignItems: "center", 
      height: '2.3vw', 
      marginBottom: '1.2vw', 
      justifyContent: "space-between", 
      zIndex: -1 
    }
})