import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Platform, TouchableWithoutFeedback, Picker,ScrollView, TouchableOpacity  } from 'react-native';
// import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { color } from '../../../../styles/Color';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";

import { DatePicker } from 'antd';
import moment from "moment";

const pageName = "isInvestigationChart";
const dateFormat = 'YYYY-MM-DD';

export class InvestigationChartNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: false,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            prevOvulation: {},
            favPrevOvulation: [],
            selectedPrevOvulation: {},
            lastMenstrualDate: "",
            investigation_date: moment(new Date()).format('YYYY-MM-DD'),
            investigation_id: "",
            haemoglobinValue: "",
            fastingbloodValue: "",
            postPrandialToleranceTestValue: "",
            glucodToleranceTestValue: "",
            uricAcidValue: "",
            thyroidStimulatingHormonValue: "",
            CreatineValue: "",
            bleedingTimeValue: "",
            serologyValue: "",
            id: "",
            islastmenstrual: false,
        };
    }

    componentDidMount() {
        this.getPrevInvestigation()
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && Object.keys(props.editHistoryItem).length > 0) {
                let item = props.editHistoryItem.editItem;

                this.setState({
                    id: item.selectedData.id,
                    investigation_id: item.selectedData.id,
                    investigation_date: item.selectedData.investigation_date ? moment(item.selectedData.investigation_date).format("YYYY-MM-DD") : null,
                    lastMenstrualDate: item.last_menstrual_period,
                    haemoglobinValue: item.selectedData.haemoglobin,
                    fastingbloodValue: item.selectedData.fasting_blood_sugar,
                    postPrandialToleranceTestValue: item.selectedData.post_prandial_bs,
                    glucodToleranceTestValue: item.selectedData.glucose_tolerant_test,
                    uricAcidValue: item.selectedData.uric_acid,
                    thyroidStimulatingHormonValue: item.selectedData.thyroid_stimulating_hormone,
                    CreatineValue: item.selectedData.creatinine,
                    bleedingTimeValue: item.selectedData.bleeding_clotting_time,
                    serologyValue: item.selectedData.serology,
                    islastmenstrual: item.islastmenstrual,

                }
                    , () => {
                        this.props.editHistory("",)
                    }
                )

            }

        }
        if(props.newItemclear){
            var states = this.state

            states["lastMenstrualDate"] = "";
            states["selectedPrevOvulation"] = {};
      
            this.setState({
              states
            })
            this.clearAllValues();
            this.props.renderNewItemClear("InvestigationClear",false)
          }

    }

    getPrevInvestigation() {
        var service_url = Constants.GET_PREV_INVESTIGATION + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPrevInvestigationSuccess,
            this.getPrevInvestigationFailure
        );
    }

    getPrevInvestigationSuccess = response => {
        if (response.status === "success") {
            //  alert("Ok" + JSON.stringify(response.data))
           // console.log("getPrevOvulationSuccess ++ " + JSON.stringify(response.data))
            var field = this.state;
            field["favPrevOvulation"] = response.data;
            this.setState({
                field
            });
        }

    }
    getPrevInvestigationFailure = error => {

    }

    updateState = (key, value) => {
        var fields = this.state;
        [key] = value;
        this.setState({ fields });

        // this.createFilterTag();
    };

    onPressDate(key, selectedValue, getItem) {
        this.setState({
            selectedPrevOvulation: selectedValue,
            investigation_id: selectedValue.id
        }, () => this.getDataByDate(true))
    }

    getDataByDate(hideDeleteIconFlag) {
        var service_url = Constants.INVESTIGATION_CHART_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id + "&investigation_id=" + this.state.investigation_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDataByDateSuccess,
            this.getOvulationFailure,
            hideDeleteIconFlag
        );
    }

    getDataByDateSuccess = (response, hideDeleteIconFlag) => {
        if (response.status === "success") {

            var field = this.state;
            field["lastMenstrualDate"] = response.data.last_menstrual_period
            // field['id'] = response.data.id


            // investigation_date:"",

            // infertilitySurgerySelectedValue: {},
            // infertilitySurgeryYear: "",
            // investigation_id: "",


            // haemoglobinValue: "",
            // fastingbloodValue: "",
            // postPrandialToleranceTestValue: "",
            // : "",
            // : "",
            // : "",
            // : "",
            // : "",
            // : "",
            // id:"", 

            // if (response.data.lab_observation_values.length > 0) {

            //     var getIndex = 0; // response.data.lab_observation_values.length-1
            //     var data = response.data.lab_observation_values[getIndex]

            //     field['id']=data.id
            //     field['investigation_date'] = data.investigation_date;
            //     field['haemoglobinValue'] = data.haemoglobin
            //     field['fastingbloodValue'] = data.fasting_blood_sugar
            //     field['postPrandialToleranceTestValue'] = data.post_prandial_bs
            //     field['glucodToleranceTestValue'] = data.glucose_tolerant_test
            //     field['uricAcidValue'] = data.uric_acid
            //     field['thyroidStimulatingHormonValue'] = data.thyroid_stimulating_hormone
            //     field['CreatineValue'] = data.creatinine
            //     field['bleedingTimeValue'] = data.bleeding_clotting_time
            //     field['serologyValue'] = data.serology
            //     field['islastmenstrual']=false


            // }


            this.setState({
                field
            })

            // this.props.getDataByDateValues(response.data,true);
            this.props.getDataByDateValues(response.data, "InvestigationChart", hideDeleteIconFlag)
            // clear
            this.props.getDataByDateValues([], "InvestigationChart", hideDeleteIconFlag)
        }

    }

    getDateValue(label, key, selectedValue, listData) {

        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {listData.length > 0 ? listData.map((item, index) => {

                        var getItem = item;
                        if (key == "selectedPrevOvulation") {
                            // alert(JSON.stringify(item))
                            getItem["value"] = item.id
                        } else {
                            getItem = { value: item, label: item }
                        }
                        return (
                            <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                <CommonButton
                                    item={getItem}
                                    selectedvalue={selectedValue}
                                    butttonText={getItem.last_menstrual_period}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressDate.bind(this)}
                                    buttonKey={key} />
                            </View>
                        )
                    }) : null
                    }
                </View>
            </View>
        )
    }

    renderInvestigationRow(title, key, value) {
        return (
            <View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: "70%" ,paddingBottom:10}}>
                        {this.rowHeadingText(title)}
                    </View>

                    <View style={{ width: "30%" }}>
                        {this.renderTextBox(key, value, 115)}
                    </View>
                </View>
            </View>
        )
    }

    rowHeadingText(title) {
        return (
            <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{title}</Text>
        )
    }

    onPressButton(key, selectedValue) {
        var states = this.state;

        states[key] = selectedValue

        this.setState({ states })
    }

    obgynYesNoButton(key, selectedValue) {
        var data = [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ]

        return (
            // <View>
            //     <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                {
                    data.map((item, index) => {
                        return (
                            <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                <CommonButton
                                    item={item}
                                    selectedvalue={selectedValue}
                                    butttonText={item.label}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this)}
                                    buttonKey={key} />
                            </View>
                        )
                    })
                }
            </View>
            // </View>
        )
    }



    renderTextBox(key, value, width, backgroundTheme = false) {
        return (
            <TextInput
                style={[{ marginTop: 10, paddingLeft: backgroundTheme ? null : 10, height: 30, borderWidth: 1, borderColor: backgroundTheme ? color.themeDark : "#888888", width: width }, Style.allButtonBorderRadius,
                // backgroundColor:backgroundTheme ?color.themeDark :null 
                backgroundTheme ? {
                    backgroundColor: color.themeDark,
                    textAlign: 'center', color: color.white
                } : {}
                ]}
                value={value}
                onChangeText={(text) => {
                    var states = this.state;
                    states[key] = text
                    this.setState({
                        states
                    })
                }}
            />

        )
    }

    onPressAddButton() {

        var states = this.state;

        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "last_menstrual_period": this.state.lastMenstrualDate,


            "investigation_lab_observations":
            {
                "investigation_date": this.state.investigation_date,
                "haemoglobin": states.haemoglobinValue,
                "fasting_blood_sugar": states.fastingbloodValue,
                "post_prandial_bs": states.postPrandialToleranceTestValue,
                "glucose_tolerant_test": states.glucodToleranceTestValue,
                "uric_acid": states.uricAcidValue,
                "thyroid_stimulating_hormone": states.thyroidStimulatingHormonValue,
                "creatinine": states.CreatineValue,
                "bleeding_clotting_time": states.bleedingTimeValue,
                "serology": states.serologyValue
            }

        }

        if (this.state.id) {
            data["investigation_lab_observations"]["id"] = this.state.id
        }

        var service_url = Constants.INVESTIGATION_CHART_SAVE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.investigationHistorySaveSuccess,
            this.investigationHistorySaveFailure
        );

    }



    investigationHistorySaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.getDataByDate(false)
            this.clearAllValues();
            this.getPrevInvestigation()
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    investigationHistorySaveFailure = error => {
        this.props.showResposeValue('error', error.message)
       // console.log("obstetricalHistorySaveFailure <<~~~~~>> " + JSON.stringify(error))
    }

    clearAllValues() {
        var states = this.state;
        states["selectedPrevOvulation"] = {};
        // states["lastMenstrualDate"] = "";
        states["investigation_date"] = moment(new Date()).format('YYYY-MM-DD');
        states["haemoglobinValue"] = "";
        states["fastingbloodValue"] = "";
        states["postPrandialToleranceTestValue"] = "";
        states["glucodToleranceTestValue"] = "";
        states["uricAcidValue"] = "";
        states["thyroidStimulatingHormonValue"] = "";
        states["CreatineValue"] = "";
        states["bleedingTimeValue"] = "";
        states["serologyValue"] = "";
        states["islastmenstrual"] = false;
        states["id"] = ""

        this.setState({ states })
    }

    render() {
        var states = this.state;

        return (
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

                <View style={styles.container}>
                    <View style={{ flexDirection: 'row',marginBottom:10 ,}} >
                        <View style={{ width: '80%' }}>
                            <Text style={{ fontSize: 20, fontWeight: '500',marginLeft:10}}>Investigation Chart</Text>
                        </View>
                        <View style={{ width: '20%', }}>
                            <TouchableOpacity style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingRight: 10, paddingLeft: 30, paddingTop: 5, paddingBottom: 5 }}
                                onPress={() => {
                                    this.clearAllValues();
                                }}
                            ><Text style={{ fontSize: 15, color: 'white' }}>{"New"}</Text></TouchableOpacity>
                        </View>
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.getDateValue("Previous Investigation chart", "selectedPrevOvulation", this.state.selectedPrevOvulation, this.state.favPrevOvulation)}
                    </View>
                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end', marginBottom: 10 }}> */}
                    <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row" }]}>
                        <View style={{ width: "65%" }}>
                            <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 10,paddingBottom:10 }}>Last Menstrual Period</Text>
                        </View>
                        <View style={{ width: "35%",marginLeft:15}}>

                            <DatePicker
                                disabledDate={current => {
                                    return current && current > moment().add(0, "days");
                                }}
                                value={this.state.lastMenstrualDate ? moment(this.state.lastMenstrualDate, dateFormat) : this.state.lastMenstrualDate}
                                onChange={date => {
                                    var fulldate = new Date(date);
                                    var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                    this.setState({ lastMenstrualDate: converted_date }, () => { this.updateState("lastMenstrualDate", this.state.lastMenstrualDate) })
                                }}
                                disabled={this.state.islastmenstrual ? true : false}
                                defaultValue={"From"}
                                format={"DD-MM-YYYY"}
                                placeholder={"Select"}
                                style={{
                                    borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                                    alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, width: '100%'
                                }}
                            />

                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row" }]}>

                            <View style={{ width:"65%" }}>
                                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 10,paddingBottom:10 }}>Date</Text>
                            </View>
                            <View style={{ width:"35%",marginLeft:15}}>

                                <DatePicker
                                    disabledDate={current => {
                                        return current && current > moment().add(0, "days");
                                    }}
                                    value={this.state.investigation_date ? moment(this.state.investigation_date, dateFormat) : this.state.investigation_date}
                                    onChange={date => {
                                        var fulldate = new Date(date);
                                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                        this.setState({ investigation_date: converted_date }, () => { this.updateState("investigation_date", this.state.investigation_date) })
                                    }}
                                    defaultValue={"From"}
                                    format={"DD-MM-YYYY"}
                                    placeholder={"Select"}
                                    style={{
                                        borderColor: "white", borderWidth: 0, marginTop: 5, height: 30, borderWidth: 1, borderColor: color.themeDark, borderRadius: 5,
                                        alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, width: '100%'
                                    }}
                                />

                            </View>
                        </View>
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Haemoglobin in g/dl", "haemoglobinValue", states.haemoglobinValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Fasting Blood Sugar in mg/dl", "fastingbloodValue", states.fastingbloodValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>

                        {this.renderInvestigationRow("Post Prandial Tolerance Test in mg/dl", "postPrandialToleranceTestValue", states.postPrandialToleranceTestValue)}
                    </View>
                    
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Glucose Tolerance Test in mg/dl", "glucodToleranceTestValue", states.glucodToleranceTestValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Uric Acid in mg/dl", "uricAcidValue", states.uricAcidValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>

                        {this.renderInvestigationRow("Thyroid Stimulating Hormone in mU/L", "thyroidStimulatingHormonValue", states.thyroidStimulatingHormonValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Creatine in U/L", "CreatineValue", states.CreatineValue)}
                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Bleeding Time / Clotting Time in Min", "bleedingTimeValue", states.bleedingTimeValue)}

                    </View>
                    <View style={[Style.doctorNotesSectionView, styles.shadow]}>
                        {this.renderInvestigationRow("Serology", "serologyValue", states.serologyValue)}
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                    </View>
                </View>
            </ScrollView>
        );
    }
}
const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        flex: 1,
    },
    shadow: {
        width: "100%", shadowColor: "#000",
        marginTop: 5,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    
        elevation: 5, backgroundColor: "white"
    }
});
