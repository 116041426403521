import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import { Tooltip } from 'antd';
import AsyncStorage from '../../../AsyncStorage';


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class RefferenceReport extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data: {},
      doctorandservices: {},
      totalAmount: '',
      selectedDoctorAction: '',
      selectedServiceAction: '',
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isServiceDetailed: false,
      isClear: false,
      isClearInt: 0,
      valueData: [],
      isPaginated: false,
      selectedServiceInput: "",
      ServiceInput: "",
      searchServicesList: [],
      ListOpen: false, 
      selectedServiceType: "",
      serviceTypeList: [],
      isAdminAccess: false,
      referredSourceList: [],
      selectedRefferedAction: "",
      total_amount: 0
    }
  }
  
  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getRefferenceList()
    this.getRefferedBy()
  }
 
  getRefferedBy = () => {
   
    var serviceUrl = Constants.REFFERAL_SOURCE_LIST 
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferedBySuccess,
      this.getRefferedByFailure
    );

  }

  getRefferedBySuccess = response => {
    if (response.status == "success") {
      var data = response.data
      this.setState({
        referredSourceList : data
      })
    }
  }
  getRefferenceList = () => {
    this.setState({
      valueData: [],
      data: [],

    })
    var serviceUrl = Constants.FO_REFFERENCE_REPORT+ "?refferal_source=" + this.state.selectedRefferedAction +"&from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferenceListSuccess,
      this.getRefferenceListFailure
    );
  }

  getRefferenceListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        data: success.patient_details,
        total_amount: success.total_amount
      })
    }
  }

  getRefferenceListFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  renderPickerDoctorName() {
    return (
      <View>
        <Picker

          selectedValue={this.state.selectedRefferedAction}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor: '#CDD1D5', backgroundColor:color.themeShade
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedRefferedAction"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Source Type' value='' />
          {(this.state.referredSourceList && this.state.referredSourceList.length > 0) ?
            this.state.referredSourceList.map(list =>
              <Picker.Item label={list.source_name } value={list.source_name} />
            )
           : null
          }

        </Picker>
      </View>
    )
  }

  onPressButton(key, value) {
    if (key == "save") {
      this.getRefferenceList()
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      var states = this.state
      // states['valueData'] = []
      this.setState({
        selectedDoctorAction: "",
        selectedServiceInput: "",
        ServiceInput: "",
        selectedServiceType: "",
        serviceTypeList: [],
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        selectedRefferedAction: "",
        isClear: true,
        isClearInt: 1
      }, () => {
        this.getRefferenceList();
        this.setState({
          isClear: false
        }, () => {
          this.renderDateFilter()
        })
      })
    }
    else if (key == "export") {
      this.ExportData()
    }
  }

  ExportData = () => { 
    var service_url = Constants.FO_REFFERENCE_REPORT + "?refferal_source=" + this.state.selectedRefferedAction +"&from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate + '&export_type=excel'
  OpthamologyService.getInstance().documentUploadGet(
    service_url,
    this.getExcelSuccess,
    this.getExcelFailure,
    "excel"
  );
}



getExcelSuccess = (success) => {
  if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
  }
}
getExcelFailure = (error) => {
  this.props.showResposeValue('error', "No Data Available !")
}

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }


  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }



  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: "Export", value: "export" },
    ]
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "65%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            <View style={{ width: "100%", marginTop: "1.5vw", paddingHorizontal: "0.35" }} >
              <CommonHistorySectionHeader
                heading1={"Source Type"}
                heading2={"Total Patient Count"}
                heading3={"Total Bill Amount (₹)"}
                noOfColumn={3}
                columnSize={[0.35, 0.30, 0.35]}
              />
              <ScrollView showsVerticalScrollIndicator={false} style={styles.TableBody}>
                <View >
                  {this.state.valueData && this.state.valueData.length > 0 ?
                    this.state.valueData.map(list => {
                      return (
                        <View style={styles.DataRowWrapper}>
                          <Text style={{ paddingHorizontal: '1vw',fontSize: "1vw", flex: 0.40, fontWeight:"500"}}>{list.source}</Text>
                          <Text style={{ fontSize: "1vw", flex: 0.30}}>{list.patient_count}</Text>
                          <Text style={{ fontSize: "1vw", flex: 0.35}}>{list.total_amount}</Text>
                        </View>)
                    }
                    ) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                      <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                    </View>
                  }</View>
              </ScrollView>
            </View>
            <View style={{ marginLeft: 20 }}>
              {this.state.data && this.state.data.length > 0 ?
                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.data)} totalItems={this.state.data} />
                : null
              }

            </View>
          </View>
        </View>

        {/* ============================ */}


        <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, padding: '1vw' }}>
          <View>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 15 }}>
              <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white,
                borderRadius: 4,
                paddingHorizontal: 15,
                minWidth: '13vw',
                height: '4vw',
                marginRight: 20
              }}>
                <View><Text style={{ fontSize: '1.4vw', fontWeight: '500', marginTop: 10 }}>₹ {this.state.total_amount ? this.state.total_amount : 0}</Text><Text style={{ fontSize: '0.87vw', fontWeight: '400', textAlign: 'right', paddingVertical: 8 }}>{"Total Amount"}</Text></View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", minWidth: "10.98vw",  flexDirection: "row", alignItems: "center", flex:1, justifyContent: "space-between" }}>
                    {this.renderDateFilter()}
                    {this.renderPickerDoctorName()}
              </View>

              <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }
              </View>
            </View>
          </View>
        </View >
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableBody: {
    height: '70vh', overflow: "scroll"
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)", display: 'flex', width: '100%', flexDirection: 'row', paddingVertical: '1vw', paddingHorizontal: '1vw', marginTop: '2.3vw'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw',
    borderRadius:'0.26vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  },
  DataRowWrapper:{
    flexDirection: "row", 
    paddingVertical: '0.8vw', 
    width: "100%", 
    borderBottomWidth: 1, 
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4, 
    height:'4vw',
    alignItems:'center'
  }
});
