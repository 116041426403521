import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput, StyleSheet } from "react-native";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { color } from "../../../../../../styles/Color";
import { Constants } from "../../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader } from "../../../../BaseComponent";
import Pagination from "../../../../common/Pagination";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";

export default class IolPoList extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            current_page: 1,
            records_per_page: 10,
            poListAfterPagination: [],
            poList: [],
            searchPo: '',
        }
    }

    componentDidMount(){
        this.getPODetails()
    }

    getPODetails = () => {
        this.setState({
            current_page: 1,
            records_per_page: 10,
            poListAfterPagination: [],
            poList: []
        })
        var url = Constants.OT_INVENTORY_IOL_PO + '?vendor_name=' + this.state.searchPo
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getPODetailsSuccess,
            this.getPODetailsFailure
        );
    }

    getPODetailsSuccess = (response) => {
        if (response && response.status == "success") {
            this.setState({
              poList: response.data
            })
        }
    }

    renderTableHeader = () => {
        return (
            <CommonHistorySectionHeader
              heading1={"GRN No"}
              heading2={"Date"}
              heading3={"Vendor Name"}
              heading4={"No of Items"}
              heading5={"Delivery Date"}
              heading6={"Status"}
              columnSize={[0.05, 0.2, 0.2, 0.15, 0.2, 0.2]}  //total value is == of 1
              noOfColumn={6}
            />
          )
    }

    renderTableData = () => {
        return(
            <View>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '55vh' }}>
            {
              this.state.poListAfterPagination && this.state.poListAfterPagination.map((item, index) => {
      
                return (
                  <TouchableOpacity style={styles.dataContainer} onPress={() => {
                    this.props.changePage("addIolDirectPO", item.po_number)
                  }}>
                    <View style={{ flex: 0.05, marginHorizontal: 20}}><Text style={[styles.TableDataText,{}]}>{item.po_number}</Text></View>
                    <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.created_date__date ? moment(item.created_date__date).format("DD-MM-YYYY") : null}</Text></View>
                    <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.vendor__name}</Text></View>
                    <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.total_line_item}</Text></View>
                    <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.delivery_date ? moment(item.delivery_date).format("DD-MM-YYYY") : null}</Text></View>
                    <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.po_status}</Text></View>
                  </TouchableOpacity>
                )
            })}
            </ScrollView>
            </View>
        )
    }

    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
         current_page: current_page,
         records_per_page: records_per_page
        })
    }

    paginationChangedValues(data) {
        this.setState({
        poListAfterPagination: data
        })
    }

    renderPaginationView() {
        return (
            <View style={{ marginHorizontal: 20 }}>
            {
                this.state.poList && this.state.poList.length > 0 ?
                <Pagination
                    paginationChangedValues={this.paginationChangedValues.bind(this)}
                    totalItems={this.state.poList}
                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                /> : null
            }
            </View>
        )
    }
    

    render(){
        return(
            <View>
            <View style={{flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 20, paddingVertical: 10}}>
                <View style={{flexDirection: "row"}}>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("iolStockList")
                }}
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>IOL Stock List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>Purchase Order List</Text>
                </TouchableOpacity>
                </View>
                <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: "row", zIndex: 0, marginRight: 20}}>
                <TextInput
                value = {this.state.searchPo}
                onChangeText={(text) => {
                    this.setState({
                        searchPo: text
                    })
                }}
                style={{borderWidth: 1, height: '5vh', width: '13vw', paddingHorizontal: 3, borderColor: color.lightGray, fontSize: '1vw' }}
                placeholder="Search vendor..."
                />
                <TouchableOpacity 
                style={{height: "5vh", width: "5vh", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                    this.getPODetails()
                }}>
                    <Icon name='search' size={'1.2vw'} color={color.white}></Icon>
                </TouchableOpacity>
                </View>
                <View>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"+ Purchase Order"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.props.changePage("IolAddPo")
                }}
                buttonKey={"addPo"} />
                </View>
                </View>
            </View>
            <View style={{padding: 20}}>
            {this.renderTableHeader()}
            {this.renderTableData()}
            {this.renderPaginationView()}
            </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableDataText:{
        fontSize:'1vw'
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.75vw",
        justifyContent: 'center',
        flex: 1
        
    },
})