//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, Picker,TouchableWithoutFeedback, ActivityIndicator ,CheckBox,TextInput} from 'react-native';
import { color } from '../../../styles/Color'
import ImagePath from '../../../utils/ImagePaths';
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

// create a component
export  class SpecimenCollectedModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      getSelectedDate: new Date(),
      paginationCurrentPage: 1,
      paginationSize: 100,
      selectedAction: "",
      showStatus: false,

      // item:this.props.item,
      labPatientList: [],
      searchPatientData: [],
      patientSearchTextValue: "",
      pageLoading:true,

      blood:false,
      water:false,
      urine:false,

      otherSamples:'',
      specimen_collected_by:'',
      isSpecimenModalVisible:'',

      specimen_tooltip:this.props.specimen_tooltip,
      specimen_key:this.props.specimen_key,
      specimen_item:this.props.specimen_item,

      error:false,
      specimen:[],
      showErrorResponce:false
      
    }
  }


  renderCheckBox(label, value, key) {
    return (
      <View style={{ flexDirection: 'row',justifyContent: 'space-between',width:"150%",}}>
        <CheckBox 
          value={value}
          style={{ width:17  ,height:17}}
          onValueChange={() => {
            let specimens_collected_array =this.state.specimen;

            var states = this.state; 
           
            states[key]=!value
            {(states[key]=!value)?specimens_collected_array.push(key):specimens_collected_array.pop(key)}
            this.setState({
              states,
              specimen:specimens_collected_array})
            }
        }/>
        <Text style={{ fontSize: 15,width:'80%'}}>{label}</Text>
      </View>
    );
  }
  closeSpecimenModal=()=>{
    this.props.closeSpecimenModal(false)
  }
  SpecimenModal=()=>{
    this.props.closeSpecimenModal(true)
  }

  showSpecimen=()=>{
    let states = this.state
    if(states.otherSamples && states.specimen){
      (states.specimen.push(states.otherSamples))
    }
    let data = {     
      specimen:this.state.specimen.toString(),
      other_specimen :this.state.otherSamples,
      specimen_collected_by:this.state.specimen_collected_by
    }
     {this.state.specimen_collected_by.length >0 && this.state.specimen.length >0
      ? (this.props.showSpecimen(false, this.state.specimen_tooltip,
        this.state.specimen_item,
        this.state.specimen_key,
        data),this.clear())
      : this.setState({showErrorResponce:true})}
  }

  clear=()=>{
    this.setState({
      blood:false,
      water:false,
      urine:false,
      otherSamples:'',
      specimen_collected_by:''
    })
  }

  displayModal=()=>{
    return(
        <View style={{ position: 'absolute', zIndex: -1, height: screenHeight, width: screenWidth, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
            <TouchableWithoutFeedback>
            <View style={{ backgroundColor: "white", position: 'absolute', zIndex: 2, width: '35%', borderRadius: 15, paddingTop: 20, paddingBottom: 20, paddingLeft: 40, paddingRight: 40 }}
            >
                
                <View style={{ flexDirection: "row", justifyContent: 'space-between',alignItems:'center'}}>
                  <Text style={{ color: 'black', fontSize: 19, }}>{"Specimens Collected"}</Text>
                  <TouchableOpacity 
                   onPress={this.closeSpecimenModal} style={{ marginTop: 10, fontSize: 30 }}>
                    <Image
                    source={ImagePath.Close}
                     style={{ height: 25, width: 25, tintColor: color.themeDark }} />
                  </TouchableOpacity>
                </View>

                <View style={{ marginTop: 5 ,display:'flex',flexDirection:"column",maxWidth: "100%"}}>
                    
                    <View style={{ marginBottom: 15,flexDirection: "row",justifyContent:'space-between',maxWidth:'50%'}}>
                      <Text> {this.renderCheckBox('Blood Sample',this.state.blood,'blood')}</Text> 
                    </View>

                    <View style={{ marginBottom: 15,flexDirection: "row",justifyContent:'space-between',maxWidth:'50%'}}>
                       <Text>{this.renderCheckBox('Urine Sample',this.state.urine,'urine')}</Text>
                    </View>

                    <View style={{ marginBottom: 15,flexDirection: "row",justifyContent:'space-between',maxWidth:'50%'}}>
                      <Text>{this.renderCheckBox('Stool Sample',this.state.water,'water')}</Text>
                    </View>
                  
                </View>


                <View style={{ zIndex: -1 }}>
                <Text style={{ color: 'black', fontSize: 19, }}>{"Other Samples"}</Text>
                  <TextInput
                    multiline={true}
                    placeholder={"Other Samples"}
                    underlineColorAndroid="transparent"
                    style={{ alignSelf: 'center', padding: 10, color: "grey", width: "100%", marginTop: 10, marginBottom: 15, height: 60, borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                    placeholderTextColor={"grey"}
                    autoCapitalize="none"
                    value={this.state.otherSamples}
                    onChangeText={text => {
                      const re = /^[a-zA-Z][a-zA-Z ]*$/
                        if (text === "" || re.test(text))
                          this.setState({ otherSamples : text });
                    }}
                  />
                </View>

                <View style={{ zIndex: -1 }}>
                <Text style={{ color: 'black', fontSize: 19, }}>{"Collected By"}</Text>
                  <TextInput
                    multiline={true}
                    placeholder={"Collected by"}
                    underlineColorAndroid="transparent"
                    style={{ alignSelf: 'center', padding: 10, color: "grey", width: "100%",marginTop: 10, marginBottom: 15, height: 60, borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                    placeholderTextColor={"grey"}
                    autoCapitalize="none"
                    value={this.state.specimen_collected_by}
                    onChangeText={text => {
                        const re = /^[a-zA-Z][a-zA-Z ]*$/
                        if (text === "" || re.test(text))
                          this.setState({ specimen_collected_by : text });                      
                    }
                      }
                  />
                  <Text style={{ marginBottom: 15, fontFamily: 13, fontWeight: '600', color: "red", textAlign: 'left' }}>{this.state.showErrorResponce ? '*Enter Samples and Sample Collected By Field':null}</Text>
                </View>
                  <View style={{ flexDirection: "row", alignSelf: "flex-end" ,zIndex:-10,justifyContent:'space-between'}}>
                      <TouchableOpacity onPress={this.showSpecimen} style={{ backgroundColor: this.state.isCreateClicked ? "#888888" : color.themeDark, padding: 7, borderRadius: 4, width: 120, justifyContent: 'center', marginRight: 5 }}>
                        <Text style={{ textAlign: 'center', color: 'white' }}>Submit</Text>
                      </TouchableOpacity>

                      <TouchableOpacity 
                        onPress={this.closeSpecimenModal} 
                        style={{ backgroundColor: "white", padding: 7, borderRadius: 4, width: 120, justifyContent: 'center', borderWidth: 1, marginLeft: 5, borderColor: "grey" }}>
                          <Text style={{ textAlign: 'center' }}>Close</Text>
                      </TouchableOpacity>
                   </View>
            </View>
            </TouchableWithoutFeedback>
          </View>

    )
    
  }




  render() {
    return (
      <View style={{ position: 'absolute', zIndex: 1 }} >
      <TouchableWithoutFeedback  onPress={this.closeSpecimenModal} >
       {this.displayModal()}
      </TouchableWithoutFeedback>
    </View>
    );
  }
}
export default SpecimenCollectedModal;