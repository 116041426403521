import React, { Component } from 'react';
// import * as React from 'react';

import { View, Text, StyleSheet, Dimensions, TouchableOpacity, 
  // FlatList, Image, ScrollView 
} from 'react-native';
// import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { Constants } from "../../../utils/Constants"
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
// import SelectedButton from "../../../components/SelectedButton";
import { color } from '../../../styles/Color'
// import {AsyncStorage} from '../../../AsyncStorage'
import OpthamologyService from "../../../network/OpthamologyService";
const pageName = "glassPrescription";

import GlassPrescriptionModalDatas from './GlassPrescriptionModalDatas'

export default class GlassPrescriptionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: this.props.changeIndex,
      routes: [
        { key: 'first', title: 'Right Eye' },
        { key: 'second', title: 'Left Eye' },
      ],

      leftEyeID: "",
      rightEyeID: "",

      copyFrom: "",

      copyRightValueToLeft: true,
      closeModal: false,

      PRISMValueRight: "",
      BCNVAValueRight: "",
      BCDVAValueRight: "",
      ADDValueRight: "",
      AXISValueRight: "",
      CYLValueRight: "",
      SPHValueRight: "",
      UCVAValueRight: "",
      visionValueRight: "",
      baseValueRight: "",

      PRISMValueLeft: "",
      BCNVAValueLeft: "",
      BCDVAValueLeft: "",
      ADDValueLeft: "",
      AXISValueLeft: "",
      CYLValueLeft: "",
      SPHValueLeft: "",
      UCVAValueLeft: "",
      visionValueLeft: "",
      baseValueLeft: "",

      rightEyeData: {},
      leftEyeData: {},

      PRISM: Constants.PRISM_OPTIONS,
      BCNVA: Constants.BCNVA_OPTIONS,
      BCDVA: Constants.BCDVA_OPTIONS,
      ADD: Constants.ADD_OPTIONS,
      AXIS: Constants.AXIS_OPTIONS,
      CYL: Constants.CYL_OPTIONS,
      SPH: Constants.SPH_OPTIONS,
      UCVA: Constants.UCVA_OPTIONS,
      vision: Constants.VISION_OPTION,
      base: Constants.BASE_OPTION,

      eyeTypeRight: "DV",
      eyeTypeLeft: "DV",

      selectedGlassprescriptionData: this.props.selectedGlassprescriptionData

    };

    this.changeData = this.changeData.bind(this);
    this.setSignedOptions = this.setSignedOptions.bind(this);

    this.firstEyeSave = false;
    this.secondEyeSave = false;
  }

  componentDidMount() {

    // if (this.props.changeIndex === 1) {
    //   this.setState({ index: 1, })
    // } else {
    //   this.setState({ index: 0, })
    // }

    if (this.state.selectedGlassprescriptionData.length > 0) {
      
      for (var i = 0; i < this.state.selectedGlassprescriptionData.length; i++) {

        if (this.state.selectedGlassprescriptionData[i].eye_type == "LE") {
          // alert("eye type  ====  LE")

          /*
          
          */
          var leftEyeType = "DV"
          // var dataL = {}

          if (leftEyeType == "DV") {
            // alert("leftEyeType DV")

            var dataL = {
              PRISMValueLeft: this.state.selectedGlassprescriptionData[i].DV_PRISM,
              BCNVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_BCNVA,
              BCDVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_BCDVA,
              ADDValueLeft: this.state.selectedGlassprescriptionData[i].add,
              AXISValueLeft: this.state.selectedGlassprescriptionData[i].DV_AXIS,
              CYLValueLeft: this.state.selectedGlassprescriptionData[i].DV_CYL,
              SPHValueLeft: this.state.selectedGlassprescriptionData[i].DV_SPH,
              UCVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_UCVA,
              visionValueLeft: this.state.selectedGlassprescriptionData[i].DV_VISION,
              baseValueLeft: this.state.selectedGlassprescriptionData[i].DV_BASE,
              // add: this.state.selectedGlassprescriptionData[i].add,
              eyeTypeLeft: "DV"
            }
            this.setState({
              leftEyeData: dataL,
              PRISMValueLeft: this.state.selectedGlassprescriptionData[i].DV_PRISM,
              BCNVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_BCNVA,
              BCDVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_BCDVA,
              ADDValueLeft: this.state.selectedGlassprescriptionData[i].add,
              AXISValueLeft: this.state.selectedGlassprescriptionData[i].DV_AXIS,
              CYLValueLeft: this.state.selectedGlassprescriptionData[i].DV_CYL,
              SPHValueLeft: this.state.selectedGlassprescriptionData[i].DV_SPH,
              UCVAValueLeft: this.state.selectedGlassprescriptionData[i].DV_UCVA,
              visionValueLeft: this.state.selectedGlassprescriptionData[i].DV_VISION,
              baseValueLeft: this.state.selectedGlassprescriptionData[i].DV_BASE,
              // add: this.state.selectedGlassprescriptionData[i].add,


              leftEyeID: this.state.selectedGlassprescriptionData[i].id,
            }, ()=>{

              this.setSignedOptions();
            })
        
          }

        } else if (this.state.selectedGlassprescriptionData[i].eye_type == "RE") {
          

          var rightEyeType = "DV"
          if(rightEyeType == "DV"){
            // alert("rightEyeType DV")
             var dataR = {
              PRISMValueRight: this.state.selectedGlassprescriptionData[i].DV_PRISM,
              BCNVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCNVA,
              BCDVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCDVA,
              ADDValueRight: this.state.selectedGlassprescriptionData[i].add,
              AXISValueRight: this.state.selectedGlassprescriptionData[i].DV_AXIS,
              CYLValueRight: this.state.selectedGlassprescriptionData[i].DV_CYL,
              SPHValueRight: this.state.selectedGlassprescriptionData[i].DV_SPH,
              UCVAValueRight: this.state.selectedGlassprescriptionData[i].DV_UCVA,
              visionValueRight: this.state.selectedGlassprescriptionData[i].DV_VISION,
              baseValueRight: this.state.selectedGlassprescriptionData[i].DV_BASE,
              eyeTypeRight: "DV",
            }
  
            this.setState({
              rightEyeData: dataR,
              PRISMValueRight: this.state.selectedGlassprescriptionData[i].DV_PRISM,
              BCNVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCNVA,
              BCDVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCDVA,
              ADDValueRight: this.state.selectedGlassprescriptionData[i].add,
              AXISValueRight: this.state.selectedGlassprescriptionData[i].DV_AXIS,
              CYLValueRight: this.state.selectedGlassprescriptionData[i].DV_CYL,
              SPHValueRight: this.state.selectedGlassprescriptionData[i].DV_SPH,
              UCVAValueRight: this.state.selectedGlassprescriptionData[i].DV_UCVA,
              visionValueRight: this.state.selectedGlassprescriptionData[i].DV_VISION,
              baseValueRight: this.state.selectedGlassprescriptionData[i].DV_BASE,
              rightEyeID: this.state.selectedGlassprescriptionData[i].id,
            },()=>{
              // alert(this.state.SPHValueRight)
              this.setSignedOptions();
            })
          
          }


         

        }
      }
    } else {

      this.setSignedOptions();
    }

    
  }

  _parseSign(value) {

    let extract_sign = value?value.substring(0, 1):"+";
    let sign = extract_sign?(extract_sign=="-"?"-":"+"):"+"
    return sign
  }

  setSignedOptions () {

    let SPH = [];
    let SPH_len = Constants.SPH_OPTIONS.length;

    let CYL = [];
    let CYL_len = Constants.CYL_OPTIONS.length;

    if (this.state.index === 0) {
      
      let SPHValueRight = this.state.SPHValueRight;
      // let sign = SPHValueRight?SPHValueRight.substring(0, 1):"+";
      // let filterRightSign = sign?(sign=="-"?"-":"+"):"+"
      let filterRightSign = this._parseSign(SPHValueRight)

      for (var i = 0; i < SPH_len; i++) {
        var getData = Constants.SPH_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        
        if( getData.value === "0"){
          SPH.push(getData);
        }else{
          if (filterData === filterRightSign ) {
            SPH.push(getData);
          // } else { 
          //   SPH.push(getData);
          }
        }
        
      }
      
      let CYLValueRight = this.state.CYLValueRight;
      // let sign1 = CYLValueRight?CYLValueRight.substring(0, 1):"+";
      // let filterRightSign1=sign1?(sign1=="-"?"-":"+"):"+"
      let filterRightSign1 = this._parseSign(CYLValueRight)

      for (var i = 0; i < CYL_len; i++) {
        var getData = Constants.CYL_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (filterData === filterRightSign1 || getData.value === '0') {
          CYL.push(getData);
        // } else { 
        //   CYL.push(getData);
        }
      }

    } else {
      
      let SPHValueLeft = this.state.SPHValueLeft;
      // let sign = SPHValueLeft?SPHValueLeft.substring(0, 1):"+";
      // let filterRightSign = sign?(sign=="-"?"-":"+"):"+"
      let filterRightSign = this._parseSign(SPHValueLeft)

      for (var i = 0; i < SPH_len; i++) {
        var getData = Constants.SPH_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (filterData === filterRightSign || getData.value === '0') {
          SPH.push(getData);
        // } else { 
        //   SPH.push(getData);
        }
      }

      let CYLValueLeft = this.state.CYLValueLeft;
      // let sign1 = CYLValueLeft?CYLValueLeft.substring(0, 1):"+";
      // let filterRightSign1=sign1?(sign1=="-"?"-":"+"):"+"
      let filterRightSign1 = this._parseSign(CYLValueLeft)

      for (var i = 0; i < CYL_len; i++) {
        var getData = Constants.CYL_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (filterData === filterRightSign1 || getData.value === '0') {
          CYL.push(getData);
        // } else { 
        //   CYL.push(getData);
        }



      }

    }

    this.setState({ SPH: SPH, CYL: CYL });

  }

  changeSelectedData = (from, value) => {
    if (from === "UCVA") {
      if (this.state.index === 0) {
        // this.setState({UCVAValueRight:value })
        this.state.UCVAValueRight = value;
      } else {
        // this.setState({UCVAValueLeft:value })
        this.state.UCVAValueLeft = value;
      }
    } else if (from === "SPH") {
      if (this.state.index === 0) {
        // this.setState({SPHValueRight:value });
        this.state.SPHValueRight = value;
      } else {
        // this.setState({SPHValueLeft:value })
        this.state.SPHValueLeft = value;
      }
    } else if (from === "CYL") {
      if (this.state.index === 0) {
        // this.setState({CYLValueRight:value })
        this.state.CYLValueRight = value;
      } else {
        // this.setState({CYLValueLeft:value })
        this.state.CYLValueLeft = value;
      }
    } else if (from === "AXIS") {
      if (this.state.index === 0) {
        // this.setState({AXISValueRight:value })
        this.state.AXISValueRight = value;
      } else {
        // this.setState({AXISValueLeft:value })
        this.state.AXISValueLeft = value;
      }
    } else if (from === "BCNVA") {
      if (this.state.index === 0) {
        // this.setState({BCNVAValueRight:value })
        this.state.BCNVAValueRight = value;
      } else {
        // this.setState({BCNVAValueLeft:value })
        this.state.BCNVAValueLeft = value;
      }
    } else if (from === "BCDVA") {
      if (this.state.index === 0) {
        // this.setState({BCDVAValueRight:value })
        this.state.BCDVAValueRight = value;
      } else {
        // this.setState({BCDVAValueLeft:value })
        this.state.BCDVAValueLeft = value;
      }
    } else if (from === "ADD") {
      if (this.state.index === 0) {
        this.state.ADDValueRight = value;
        // this.setState({ADDValueRight:value })
      } else {
        this.state.ADDValueLeft = value;
        // this.setState({ADDValueLeft:value })
      }
    } else if (from === "Prism") {
      if (this.state.index === 0) {
        this.state.PRISMValueRight = value;
        // this.setState({PRISMValueRight:value })
      } else {
        this.state.PRISMValueLeft = value;
        // this.setState({PRISMValueLeft:value })
      }
    } else if (from === "Base") {
      if (this.state.index === 0) {
        this.state.baseValueRight = value;
        // this.setState({PRISMValueRight:value })
      } else {
        this.state.baseValueLeft = value;
        // this.setState({PRISMValueLeft:value })
      }
    } else if (from === "Vision") {
      if (this.state.index === 0) {
        this.state.visionValueRight = value;
        // this.setState({PRISMValueRight:value })
      } else {
        this.state.visionValueLeft = value;
        // this.setState({PRISMValueLeft:value })
      }
    } else if (from === "Eye Type") {
      if (this.state.index === 0) {
        this.setState({
          eyeTypeRight: value
        })
        this.state.eyeTypeRight = value;
      } else {
        this.setState({
          eyeTypeRight: value
        })
        this.state.eyeTypeLeft = value;
      }
    }



    var dataR = {
      PRISMValueRight: this.state.PRISMValueRight,
      BCNVAValueRight: this.state.BCNVAValueRight,
      BCDVAValueRight: this.state.BCDVAValueRight,
      ADDValueRight: this.state.ADDValueRight,
      AXISValueRight: this.state.AXISValueRight,
      CYLValueRight: this.state.CYLValueRight,
      SPHValueRight: this.state.SPHValueRight,
      UCVAValueRight: this.state.UCVAValueRight,
      visionValueRight: this.state.visionValueRight,
      baseValueRight: this.state.baseValueRight,
      eyeTypeRight: this.state.eyeTypeRight
      
    }
    var dataL = {
      PRISMValueLeft: this.state.PRISMValueLeft,
      BCNVAValueLeft: this.state.BCNVAValueLeft,
      BCDVAValueLeft: this.state.BCDVAValueLeft,
      ADDValueLeft: this.state.ADDValueLeft,
      AXISValueLeft: this.state.AXISValueLeft,
      CYLValueLeft: this.state.CYLValueLeft,
      SPHValueLeft: this.state.SPHValueLeft,
      UCVAValueLeft: this.state.UCVAValueLeft,
      visionValueLeft: this.state.visionValueLeft,
      baseValueLeft: this.state.baseValueLeft,
      eyeTypeLeft: this.state.eyeTypeLeft
    }

    
    this.setState({ rightEyeData: dataR, leftEyeData: dataL })
  }

  _handleIndexChange = index => this.setState({ index });

  eyeDatas() {
    return new Promise(resolve => {

      var datas = {
        "right": this.state.rightEyeData,
        "left": this.state.leftEyeData
      }

      resolve(datas)

      // if (type == "Right") {
      //   resolve(this.state.rightEyeData)
      // }else {
      //   resolve(this.state.leftEyeData)
      // }
    });
  }
  
  renderLabel = ({ route, focused, color }) => {
    return (
      <View>
        <Text style={[focused ? styles.activeTabTextColor : styles.tabTextColor]} >
          {route.title}
        </Text>
      </View>
    )
  }

  coptRightValueToLeft(checkEyeFlag) {
    if (checkEyeFlag) {
      this.setState({
        PRISMValueLeft: this.state.PRISMValueRight,
        BCNVAValueLeft: this.state.BCNVAValueRight,
        BCDVAValueLeft: this.state.BCDVAValueRight,
        ADDValueLeft: this.state.ADDValueRight,
        AXISValueLeft: this.state.AXISValueRight,
        CYLValueLeft: this.state.CYLValueRight,
        SPHValueLeft: this.state.SPHValueRight,
        UCVAValueLeft: this.state.UCVAValueRight,
        visionValueLeft: this.state.visionValueRight,
        baseValueLeft: this.state.baseValueRight,
        eyeTypeLeft: this.state.eyeTypeRight,
        copyFrom: "rightToLeft"
      },()=>{
        // this.saveGlassPrescriptionData(checkEyeFlag ? "rightToLeft" : "leftToRight")
      })
    } else {
      this.setState({
        PRISMValueRight: this.state.PRISMValueLeft,
        BCNVAValueRight: this.state.BCNVAValueLeft,
        BCDVAValueRight: this.state.BCDVAValueLeft,
        ADDValueRight: this.state.ADDValueLeft,
        AXISValueRight: this.state.AXISValueLeft,
        CYLValueRight: this.state.CYLValueLeft,
        SPHValueRight: this.state.SPHValueLeft,
        UCVAValueRight: this.state.UCVAValueLeft,
        visionValueRight: this.state.visionValueLeft,
        baseValueRight: this.state.baseValueLeft,
        eyeTypeRight: this.state.eyeTypeLeft,
        copyFrom: "leftToRight"
      },()=>{
        // this.saveGlassPrescriptionData(checkEyeFlag ? "rightToLeft" : "leftToRight")
      })
    }
    // this.saveGlassPrescriptionData(checkEyeFlag ? "rightToLeft" : "leftToRight")
  }


/*  
  saveGlassPrescriptionData(copyType = "") {
    
    var type = ""

    if (copyType !== "")
      type = copyType
    else if (this.state.index === 0)
      type = "RE"
    else if (this.state.index === 1)
      type = "LE"


    var data = {}
    if (type == "RE" || type == "rightToLeft") {
      // if (this.state.eyeTypeRight === "DV") {
        var states = this.state;
        data = {
          "appointment_id": this.props.patientAppointment.appointment_id,
          "eye_type": "RE",
          "DV_SPH": states.SPHValueRight,
          "DV_CYL": states.CYLValueRight,
          "DV_AXIS": states.AXISValueRight,
          "DV_VISION": states.visionValueRight,

          "DV_BCDVA": states.BCDVAValueRight,
          "DV_BCNVA": states.BCNVAValueRight,
          "DV_PRISM": states.PRISMValueRight,
          "DV_BASE": states.baseValueRight,

          "add": states.ADDValueRight,
          "DV_UCVA": states.UCVAValueRight,

        }
      

      if (this.state.rightEyeID) {
        data["id"] = this.state.rightEyeID
      }
    } else if (type == "LE" || type == "leftToRight") {
      // if (this.state.eyeTypeLeft === "DV") {
        data = {
          "appointment_id": this.props.patientAppointment.appointment_id,
          "eye_type": "LE",
          "DV_SPH": this.state.SPHValueLeft,
          "DV_CYL": this.state.CYLValueLeft,
          "DV_AXIS": this.state.AXISValueLeft,
          "DV_VISION": this.state.visionValueLeft,
          "DV_BCDVA": this.state.BCDVAValueLeft,
          "DV_BCNVA": this.state.BCNVAValueLeft,
          "DV_PRISM": this.state.PRISMValueLeft,
          "DV_BASE": this.state.baseValueLeft,
          "add": this.state.ADDValueLeft,
          "DV_UCVA": this.state.UCVAValueLeft,
        }
      


      if (this.state.leftEyeID) {
        data["id"] = this.state.leftEyeID
      }
    }

    this.glassPrescriptionPostApi(data, false)
  }

  glassPrescriptionPostApi(data, flag) {
    // alert(JSON.stringify(data))
    if (flag) {
      this.setState({
        copyFrom: ""
      })
    }

    var service_url = Constants.GLASS_PRESCRIPTION;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
  }



  getComplaintsSuccess = response => {

    if (response.status === "success") {

      if (this.state.copyFrom !== "") {
        
        var data = {}
        
        if (this.state.copyFrom == "rightToLeft") {
          // if (this.state.eyeTypeRight === "DV") {
            data = {
              "appointment_id": this.props.patientAppointment.appointment_id,
              "eye_type": "LE",

              "DV_SPH": states.SPHValueRight,
              "DV_CYL": states.CYLValueRight,
              "DV_AXIS": states.AXISValueRight,
              "DV_VISION": states.visionValueRight,

              "DV_BCDVA": states.BCDVAValueRight,
              "DV_BCNVA": states.BCNVAValueRight,
              "DV_PRISM": states.PRISMValueRight,
              "DV_BASE": states.baseValueRight,

              "DV_ADD": states.ADDValueRight,
              "DV_UCVA": states.UCVAValueRight,


            }
          
          // }

          if (this.state.leftEyeID) {
            data["id"] = this.state.leftEyeID
          }

        } else if (this.state.copyFrom == "leftToRight") {
          // if (this.state.eyeTypeLeft === "DV") {
            data = {
              "appointment_id": this.props.patientAppointment.appointment_id,
              "eye_type": "RE",

              "DV_SPH": this.state.SPHValueLeft,
              "DV_CYL": this.state.CYLValueLeft,
              "DV_AXIS": this.state.AXISValueLeft,
              "DV_VISION": this.state.visionValueLeft,
              "DV_BCDVA": this.state.BCDVAValueLeft,
              "DV_BCNVA": this.state.BCNVAValueLeft,
              "DV_PRISM": this.state.PRISMValueLeft,
              "DV_BASE": this.state.baseValueLeft,
              "DV_ADD": this.state.ADDValueLeft,
              "DV_UCVA": this.state.UCVAValueLeft,

            }
          
          // }

          if (this.state.rightEyeID) {
            data["id"] = this.state.rightEyeID
          }
        }


        this.glassPrescriptionPostApi(data, true)

      } else {

        this.props.refreshData(pageName);
        this.glassPrescriptionModalClose(false, "");
        this.props.showResposeValue("success", response.message)

      }

    } else {
      this.props.showResposeValue("error", response.message)
    }
  };
*/

saveGlassPrescriptionData() {
    
  var type = "" 
  let secondCall = this.firstEyeSave && !this.secondEyeSave?true:false


  if (this.state.index === 0)
    type = secondCall?"LE":"RE"
  else if (this.state.index === 1)
    type = secondCall?"RE":"LE"


  var data = {}
  if (type == "RE") {
    
      var states = this.state;
      data = {
        "appointment_id": this.props.patientAppointment.appointment_id,
        "eye_type": "RE",
        "DV_SPH": states.SPHValueRight,
        "DV_CYL": states.CYLValueRight,
        "DV_AXIS": states.AXISValueRight,
        "DV_VISION": states.visionValueRight,

        "DV_BCDVA": states.BCDVAValueRight,
        "DV_BCNVA": states.BCNVAValueRight,
        "DV_PRISM": states.PRISMValueRight,
        "DV_BASE": states.baseValueRight,

        "add": states.ADDValueRight,
        "DV_UCVA": states.UCVAValueRight,

      }
    

    if (this.state.rightEyeID) {
      data["id"] = this.state.rightEyeID
    }
  } else if (type == "LE") {
    
      data = {
        "appointment_id": this.props.patientAppointment.appointment_id,
        "eye_type": "LE",
        "DV_SPH": this.state.SPHValueLeft,
        "DV_CYL": this.state.CYLValueLeft,
        "DV_AXIS": this.state.AXISValueLeft,
        "DV_VISION": this.state.visionValueLeft,
        "DV_BCDVA": this.state.BCDVAValueLeft,
        "DV_BCNVA": this.state.BCNVAValueLeft,
        "DV_PRISM": this.state.PRISMValueLeft,
        "DV_BASE": this.state.baseValueLeft,
        "add": this.state.ADDValueLeft,
        "DV_UCVA": this.state.UCVAValueLeft,
      }
    
    if (this.state.leftEyeID) {
      data["id"] = this.state.leftEyeID
    }
  }

  this.glassPrescriptionPostApi(data, false)
}

glassPrescriptionPostApi(data, flag) {
  console.log("Data"+JSON.stringify(data))
  if (flag) {
    this.setState({
      copyFrom: ""
    })
  }
if(Object.keys(data).length>0){
  var service_url = Constants.GLASS_PRESCRIPTION;
  OpthamologyService.getInstance().postComplaints(
    service_url,
    data,
    this,
    this.getComplaintsSuccess,
    this.getComplaintsFailure
  );
}else{
  this.props.showResposeValue("error","Please Enter the Glass Prescription values")
}

}



getComplaintsSuccess = response => {

  if (response.status === "success") {

    if(!this.firstEyeSave) {

      this.firstEyeSave = true;
      this.secondEyeSave = false;

      this.saveGlassPrescriptionData()
    
    } else {

      this.secondEyeSave = true;

      this.props.refreshData(pageName);
      this.glassPrescriptionModalClose(false, "");
      this.props.showResposeValue("success", response.message)

    }

  } else {

    this.firstEyeSave = false;
    this.secondEyeSave = false;

    this.props.showResposeValue("error", response.message)
  }
};

  getComplaintsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
    this.props.showResposeValue("error", error.message)
  };

  changeEyeType(key){
    this.setState({index: key }, ()=>{
      this.setSignedOptions();
    })
  }

  renderTabBar = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity onPress={()=>{ this.changeEyeType(0) }}  style={[styles.TableBarHeadTab, {borderBottomColor:this.state.index == 0 ? color.themeDark : "#ccc", borderBottomWidth: this.state.index == 0 ? 3 : 1, }]}>
          <Text style={styles.TableBarHeadTabText}>{"Right Eye"}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>{this.changeEyeType(1)}}  style={[styles.TableBarHeadTab,{borderBottomColor:this.state.index == 1 ? color.themeDark : "#ccc", borderBottomWidth:  this.state.index == 1 ? 3 : 1, }]}>
          <Text style={styles.TableBarHeadTabText}>{"Left Eye"}</Text>
        </TouchableOpacity>
        <View style={styles.TableBarHeadBtnView}>
          <View>
            <TouchableOpacity style={styles.CopyBtnTop} onPress={() => {
              this.coptRightValueToLeft(this.state.index === 0 ? true : false);
            }}>
              <Text style={styles.BtnTopText}>{this.state.index === 0 ? "Copy right values to left" : "Copy left values to right"}</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity style={styles.SaveBtnView}
              onPress={() => { this.saveGlassPrescriptionData() }}>
              <Text style={styles.BtnTopText}>Save</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity style={styles.CancelBtnView}
              onPress={() => { this.glassPrescriptionModalClose(false, "") }}>
              <Text style={styles.cancelbtnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>

      </View>
    );
  }

  render() {

    // this.changeData();
    return (
      <View style={{ position: 'absolute' }}>
        <View style={styles.MainViewWrapper}>
          <View style={styles.Wrapper2}>

          <View>
            {this.renderTabBar()}
          </View>

          <View>
            {
              this.state.index == 0 ?
              
              <GlassPrescriptionModalDatas 
                key={0} 
                eyeDatas={this.eyeDatas.bind(this)}
                // UCVAValueRight={this.state.UCVAValueRight}
                // SPHValueRight={this.state.SPHValueRight}
                leftEyeData={this.state.leftEyeData}
                rightEyeData={this.state.rightEyeData}
                changeSelectedData={this.changeSelectedData}
                componentFrom={"Right"}
                UCVA={this.state.UCVA}
                SPH={this.state.SPH}
                CYL={this.state.CYL}
                AXIS={this.state.AXIS}
                PRISM={this.state.PRISM}
                BCNVA={this.state.BCNVA}
                BCDVA={this.state.BCDVA}
                ADD={this.state.ADD}
                base={this.state.base}
                vision={this.state.vision}
                changeData={this.changeData}
                eyeTypeRight={this.state.eyeTypeRight}

                // selectedEyeTypeData={this.state.selectedEyeTypeData}
              />


              :this.state.index == 1 ?
              <GlassPrescriptionModalDatas 
              key={1} 
                eyeDatas={this.eyeDatas.bind(this)}
                leftEyeData={this.state.leftEyeData}
                rightEyeData={this.state.rightEyeData}

                // SPHValueLeft={this.state.SPHValueLeft}

                changeSelectedData={this.changeSelectedData}
                componentFrom={"Left"}
                UCVA={this.state.UCVA}
                SPH={this.state.SPH}
                CYL={this.state.CYL}
                AXIS={this.state.AXIS}
                PRISM={this.state.PRISM}
                BCNVA={this.state.BCNVA}
                BCDVA={this.state.BCDVA}
                ADD={this.state.ADD}
                base={this.state.base}
                vision={this.state.vision}
                changeData={this.changeData}
                eyeTypeLeft={this.state.eyeTypeLeft}

                // selectedEyeTypeData={this.state.selectedEyeTypeData}
              /> : null
            }
          </View>
            
          {this.renderCard()}

          </View>
        </View>
      </View>

    );
  }

  renderCard() {
    return (

      <View style={styles.RenderCardmainView}>

        <View>
          <Text style={styles.CardHeadText}>{"Right Eye"}</Text>
          <View style={{ flexDirection: 'row' }}>
            {this.renderCardValues("UCVA", this.state.UCVAValueRight)}
            {this.renderCardValues("SPH", this.state.SPHValueRight)}
            {this.renderCardValues("CYL", this.state.CYLValueRight)}
            {this.renderCardValues("AXIS", this.state.AXISValueRight)}
            {this.renderCardValues("ADD", this.state.ADDValueRight)}
            {this.renderCardValues("BCDVA", this.state.BCDVAValueRight)}
            {this.renderCardValues("BCNVA", this.state.BCNVAValueRight)}
            {this.renderCardValues("Prism", this.state.PRISMValueRight)}
            {this.renderCardValues("Vision", this.state.visionValueRight)}
            {this.renderCardValues("Base", this.state.baseValueRight)}
          </View>
        </View>

        <View>
          <Text style={styles.CardHeadText}>{"Left Eye"}</Text>
          <View style={{ flexDirection: 'row' }}>
            {this.renderCardValues("UCVA", this.state.UCVAValueLeft)}
            {this.renderCardValues("SPH", this.state.SPHValueLeft)}
            {this.renderCardValues("CYL", this.state.CYLValueLeft)}
            {this.renderCardValues("AXIS", this.state.AXISValueLeft)}
            {this.renderCardValues("ADD", this.state.ADDValueLeft)}
            {this.renderCardValues("BCDVA", this.state.BCDVAValueLeft)}
            {this.renderCardValues("BCNVA", this.state.BCNVAValueLeft)}
            {this.renderCardValues("Prism", this.state.PRISMValueLeft)}
            {this.renderCardValues("Vision", this.state.visionValueLeft)}
            {this.renderCardValues("Base", this.state.baseValueLeft)}
          </View>
        </View>
      </View>
    );
  }


  changeData(filterDataFrom, flag) {

    if (filterDataFrom === "UCVA") {
      let UCVA = []; //this.state.UCVA = [];
      let len = Constants.UCVA_OPTIONS.length;
      for (var i = 0; i < len; i++) {
        var getData = Constants.UCVA_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (!flag && filterData === "-") {
          UCVA.push(getData);
        } else if (flag && parseInt(getData.value) >= 0.00 && filterData !== "-") {
          UCVA.push(getData);
        }
      }
      this.setState({ UCVA: UCVA });
    } else if (filterDataFrom === "SPH") {
      let SPH = [];
      let len = Constants.SPH_OPTIONS.length;
      for (var i = 0; i < len; i++) {
        var getData = Constants.SPH_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (!flag && filterData === "-") {
          SPH.push(getData);
        } else if (flag && filterData !== "-") { // && parseInt(getData.value) >= 0.00
          SPH.push(getData);
        }
      }
      this.setState({ SPH: SPH });
    } else if (filterDataFrom === "CYL") {
      let CYL = [];
      let len = Constants.CYL_OPTIONS.length;
      for (var i = 0; i < len; i++) {
        var getData = Constants.CYL_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (!flag && filterData === "-") {
          CYL.push(getData);
        } else if (flag && parseInt(getData.value) >= 0.00 && filterData !== "-") {
          CYL.push(getData);
        }
      }
      this.setState({ CYL: CYL });
    } else if (filterDataFrom === "ADD") {
      let ADD = [];
      let len = Constants.ADD_OPTIONS.length;
      for (var i = 0; i < len; i++) {
        var getData = Constants.ADD_OPTIONS[i];
        var filterData = getData.value.substring(0, 1);
        if (!flag && filterData === "-") {
          ADD.push(getData);
        } else if (flag && parseInt(getData.value) >= 0.00 && filterData !== "-") {
          ADD.push(getData);
        }
      }
      this.setState({ ADD: ADD })
    } else if (filterDataFrom === "AXIS") {

    } else if (filterDataFrom === "BCNVA") {

    } else if (filterDataFrom === "BCDVA") {

    } else if (filterDataFrom === "Prism") {

    } else if (filterDataFrom === "Base") {

    } else if (filterDataFrom === "Vision") {

    }
  }


  renderCardValues(title, value) {
    return (
      <View style={styles.card}>
        <Text style={styles.cardTitle}>{title}</Text><Text style={styles.CardValueText}>{value}</Text>
      </View>
    );
  }

  glassPrescriptionModalClose(modalFlag, data) {
    this.props.glassPrescriptionModalClose(modalFlag);
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  activeTabTextColor: {
    color: 'Black',
    fontSize: '1vw',

  },
  tabTextColor: {
    color: '#ccc',
    fontSize: '1vw'

  },
  card: {
    marginRight: '1.3vw',
    marginTop: '0.97vw',
    marginBottom: '0.65vw'
  },
  cardTitle: {
    color: "#797979",
    marginBottom: '0.32vw',
    fontSize: '0.85vw'
  },
  CardValueText:{
    fontSize:'0.78vw'
  },
  MainViewWrapper:{
    position: 'absolute', 
    zIndex: -1, 
    height: '90.7vh', 
    width: '92.8vw', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  Wrapper2:{
    width: '95%', 
    maxHeight: "100%", 
    backgroundColor: 'white',
    borderRadius: '2.28vw', 
    padding: '1.3vw', 
    paddingHorizontal: '3.25vw'
  },
  TableBarHeadTab:{
    paddingLeft:'1.3vw',
    paddingTop:'0.65vw',
    paddingRight:'1.3vw',
  },
  TableBarHeadTabText:{
    fontSize:'1vw'
  },
  TableBarHeadBtnView:{
    borderBottomColor: "#ccc", 
    borderBottomWidth: '0.19vw', 
    width: '65vw', 
    // width: screenWidth-(90+350), 
    flexDirection: 'row', 
    justifyContent: 'flex-end', 
    alignContent: "center"
  },
  CopyBtnTop:{
    height: '1.95vw', 
    width: '16.28vw', 
    marginVertical:'0.65vw' , 
    marginRight: '0.65vw', 
    justifyContent: 'center', 
    alignSelf: 'center', 
    backgroundColor: color.themeDark, 
    borderRadius: '1.95vw'
  },
  BtnTopText:{
    color: 'white', 
    textAlign: 'center', 
    fontSize: '0.91vw'
  },
  SaveBtnView:{
    height: '1.95vw', 
    width: '6.51vw', 
    marginVertical: '0.65vw', 
    marginRight: '0.65vw', 
    justifyContent: 'center', 
    alignSelf: 'center', 
    backgroundColor: color.themeDark, 
    borderRadius: '1.95vw'
  },
  CancelBtnView:{
    height: '1.95vw', 
    width: '6.51vw', 
    marginVertical: '0.65vw', 
    justifyContent: 'center', 
    alignSelf: 'center', 
    backgroundColor: color.white, 
    borderRadius: '1.95vw', 
    borderColor: color.themeDark, 
    borderWidth: '0.06vw'
  },
  cancelbtnText:{
    color: color.themeDark, 
    textAlign: 'center', 
    fontSize: '0.91vw'
  },
  RenderCardmainView:{
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    backgroundColor: 'white', 
    borderRadius: '0.65vw', 
    padding: '0.65vw', 
    borderColor: '#D1D1D1', 
    borderWidth: '0.06vw'
  },
  CardHeadText:{
    color: 'black', 
    fontWeight: "bold", 
    fontSize: '1vw'
  }
});

{/*
Dead code

will receive property
          
          var leftEyeType = ""
          if ((this.state.selectedGlassprescriptionData[i].DV_SPH || this.state.selectedGlassprescriptionData[i].DV_CYL ||
            this.state.selectedGlassprescriptionData[i].DV_AXIS || this.state.selectedGlassprescriptionData[i].DV_VISION || this.state.selectedGlassprescriptionData[i].DV_BCDVA ||
            this.state.selectedGlassprescriptionData[i].DV_BCNVA || this.state.selectedGlassprescriptionData[i].DV_PRISM || this.state.selectedGlassprescriptionData[i].DV_BASE)) {
              leftEyeType = "DV"
          } else if ((this.state.selectedGlassprescriptionData[i].NV_SPH || this.state.selectedGlassprescriptionData[i].NV_CYL ||
            this.state.selectedGlassprescriptionData[i].NV_AXIS || this.state.selectedGlassprescriptionData[i].NV_VISION || this.state.selectedGlassprescriptionData[i].NV_BCDVA ||
            this.state.selectedGlassprescriptionData[i].NV_BCNVA || this.state.selectedGlassprescriptionData[i].NV_PRISM || this.state.selectedGlassprescriptionData[i].NV_BASE)) {
              leftEyeType = "NV"
          }

          assign value


          }else if(rightEyeType == "NV"){
            // alert("rightEyeType NV")
            var dataR = {
              PRISMValueRight: this.state.selectedGlassprescriptionData[i].NV_PRISM,
              BCNVAValueRight: this.state.selectedGlassprescriptionData[i].NV_BCNVA,
              BCDVAValueRight: this.state.selectedGlassprescriptionData[i].NV_BCDVA,
              ADDValueRight: this.state.selectedGlassprescriptionData[i].NV_ADD,
              AXISValueRight: this.state.selectedGlassprescriptionData[i].NV_AXIS,
              CYLValueRight: this.state.selectedGlassprescriptionData[i].NV_CYL,
              SPHValueRight: this.state.selectedGlassprescriptionData[i].NV_SPH,
              UCVAValueRight: this.state.selectedGlassprescriptionData[i].NV_UCVA,
              visionValueRight: this.state.selectedGlassprescriptionData[i].NV_VISION,
              baseValueRight: this.state.selectedGlassprescriptionData[i].NV_BASE,
              eyeTypeRight: "NV",
            }
  
            this.setState({
              rightEyeData: dataR,
              PRISMValueRight: this.state.selectedGlassprescriptionData[i].NV_PRISM,
              BCNVAValueRight: this.state.selectedGlassprescriptionData[i].NV_BCNVA,
              BCDVAValueRight: this.state.selectedGlassprescriptionData[i].NV_BCDVA,
              ADDValueRight: this.state.selectedGlassprescriptionData[i].NV_ADD,
              AXISValueRight: this.state.selectedGlassprescriptionData[i].NV_AXIS,
              CYLValueRight: this.state.selectedGlassprescriptionData[i].NV_CYL,
              SPHValueRight: this.state.selectedGlassprescriptionData[i].NV_SPH,
              UCVAValueRight: this.state.selectedGlassprescriptionData[i].NV_UCVA,
              visionValueRight: this.state.selectedGlassprescriptionData[i].NV_VISION,
              baseValueRight: this.state.selectedGlassprescriptionData[i].NV_BASE,
              rightEyeID: this.state.selectedGlassprescriptionData[i].id,
            })
          

            } else if (leftEyeType == "NV") {
            // alert("leftEyeType NV")

            var dataL = {
              PRISMValueLeft: this.state.selectedGlassprescriptionData[i].NV_PRISM,
              BCNVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_BCNVA,
              BCDVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_BCDVA,
              ADDValueLeft: this.state.selectedGlassprescriptionData[i].NV_ADD,
              AXISValueLeft: this.state.selectedGlassprescriptionData[i].NV_AXIS,
              CYLValueLeft: this.state.selectedGlassprescriptionData[i].NV_CYL,
              SPHValueLeft: this.state.selectedGlassprescriptionData[i].NV_SPH,
              UCVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_UCVA,
              visionValueLeft: this.state.selectedGlassprescriptionData[i].NV_VISION,
              baseValueLeft: this.state.selectedGlassprescriptionData[i].NV_BASE,
              eyeTypeLeft: "NV"
            }
            this.setState({
              leftEyeData: dataL,
              PRISMValueLeft: this.state.selectedGlassprescriptionData[i].NV_PRISM,
              BCNVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_BCNVA,
              BCDVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_BCDVA,
              ADDValueLeft: this.state.selectedGlassprescriptionData[i].NV_ADD,
              AXISValueLeft: this.state.selectedGlassprescriptionData[i].NV_AXIS,
              CYLValueLeft: this.state.selectedGlassprescriptionData[i].NV_CYL,
              SPHValueLeft: this.state.selectedGlassprescriptionData[i].NV_SPH,
              UCVAValueLeft: this.state.selectedGlassprescriptionData[i].NV_UCVA,
              visionValueLeft: this.state.selectedGlassprescriptionData[i].NV_VISION,
              baseValueLeft: this.state.selectedGlassprescriptionData[i].NV_BASE,


              leftEyeID: this.state.selectedGlassprescriptionData[i].id,
            })

            // alert("eye type  ====  RE")

          // var rightEyeType = ""
          // if ((this.state.selectedGlassprescriptionData[i].DV_SPH || this.state.selectedGlassprescriptionData[i].DV_CYL ||
          //   this.state.selectedGlassprescriptionData[i].DV_AXIS || this.state.selectedGlassprescriptionData[i].DV_VISION || this.state.selectedGlassprescriptionData[i].DV_BCDVA ||
          //   this.state.selectedGlassprescriptionData[i].DV_BCNVA || this.state.selectedGlassprescriptionData[i].DV_PRISM || this.state.selectedGlassprescriptionData[i].DV_BASE)) {
          //   rightEyeType = "DV"
          // } else if ((this.state.selectedGlassprescriptionData[i].NV_SPH || this.state.selectedGlassprescriptionData[i].NV_CYL ||
          //   this.state.selectedGlassprescriptionData[i].NV_AXIS || this.state.selectedGlassprescriptionData[i].NV_VISION || this.state.selectedGlassprescriptionData[i].NV_BCDVA ||
          //   this.state.selectedGlassprescriptionData[i].NV_BCNVA || this.state.selectedGlassprescriptionData[i].NV_PRISM || this.state.selectedGlassprescriptionData[i].NV_BASE)) {
          //   rightEyeType = "NV"
          // }



          // {PRISMValueRight:this.state.PRISMValueRight},
      // {BCNVAValueRight:this.state.BCNVAValueRight},
      // {BCDVAValueRight:this.state.BCDVAValueRight},
      // {ADDValueRight:this.state.ADDValueRight},
      // {AXISValueRight:this.state.AXISValueRight},
      // {CYLValueRight:this.state.CYLValueRight},
      // {SPHValueRight:this.state.SPHValueRight},
      // {UCVAValueRight:this.state.UCVAValueRight},
      
    //   {PRISMValueLeft:this.state.PRISMValueLeft},
    //   {BCNVAValueLeft:this.state.BCNVAValueLeft},
    //   {BCDVAValueLeft:this.state.BCDVAValueLeft},
    //   {ADDValueLeft:this.state.ADDValueLeft},
    //   {AXISValueLeft:this.state.AXISValueLeft},
    //   {CYLValueLeft:this.state.CYLValueLeft},
    //   {SPHValueLeft:this.state.SPHValueLeft},
    //   {UCVAValueLeft:this.state.UCVAValueLeft},
    // ]



    // } else if (this.state.eyeTypeRight === "NV") {
      //   data = {
      //     "appointment_id": this.props.patientAppointment.appointment_id,
      //     "eye_type": "RE",
      //     "NV_SPH": this.state.SPHValueRight,
      //     "NV_CYL": this.state.CYLValueRight,
      //     "NV_AXIS": this.state.AXISValueRight,
      //     "NV_VISION": this.state.visionValueRight,

      //     "NV_BCDVA": this.state.BCDVAValueRight,
      //     "NV_BCNVA": this.state.BCNVAValueRight,
      //     "NV_PRISM": this.state.PRISMValueRight,
      //     "NV_BASE": this.state.baseValueRight,

      //     "NV_ADD": this.state.ADDValueRight,
      //     "NV_UCVA": this.state.UCVAValueRight,
      //   }
      // }


      // } else if (this.state.eyeTypeLeft === "NV") {
      //   data = {
      //     "appointment_id": this.props.patientAppointment.appointment_id,
      //     "eye_type": "LE",
      //     "NV_SPH": this.state.SPHValueLeft,
      //     "NV_CYL": this.state.CYLValueLeft,
      //     "NV_AXIS": this.state.AXISValueLeft,
      //     "NV_VISION": this.state.visionValueLeft,

      //     "NV_BCDVA": this.state.BCDVAValueLeft,
      //     "NV_BCNVA": this.state.BCNVAValueLeft,
      //     "NV_PRISM": this.state.PRISMValueLeft,
      //     "NV_BASE": this.state.baseValueLeft,

      //     "NV_ADD": this.state.ADDValueLeft ? this.state.ADDValueLeft : "",
      //     "NV_UCVA": this.state.UCVAValueLeft,
      //   }
      // }


       // var dataR = {
          //   PRISMValueRight: this.state.selectedGlassprescriptionData[i].DV_PRISM,
          //   BCNVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCNVA,
          //   BCDVAValueRight: this.state.selectedGlassprescriptionData[i].DV_BCDVA,
          //   ADDValueRight: this.state.selectedGlassprescriptionData[i].DV_SDD,
          //   AXISValueRight: this.state.selectedGlassprescriptionData[i].DV_AXIS,
          //   CYLValueRight: this.state.selectedGlassprescriptionData[i].DV_CYL,
          //   SPHValueRight: this.state.selectedGlassprescriptionData[i].DV_SPH,
          //   UCVAValueRight: this.state.selectedGlassprescriptionData[i].DV_UCVA,
          //   visionValueRight: this.state.selectedGlassprescriptionData[i].DV_VISION,
          //   baseValueRight: this.state.selectedGlassprescriptionData[i].DV_BASE,
          //   eyeTypeRight: this.state.eyeTypeRight,
          // }

          // this.setState({
          //   rightEyeData: dataR,
          //   AXISValueRight: this.state.selectedGlassprescriptionData[i].DV_AXIS,
          //   CYLValueRight: this.state.selectedGlassprescriptionData[i].DV_CYL,
          //   visionValueRight: this.state.selectedGlassprescriptionData[i].DV_VISION,
          //   SPHValueRight: this.state.selectedGlassprescriptionData[i].DV_SPH,
          //   rightEyeID: this.state.selectedGlassprescriptionData[i].id,
          // })
*/}