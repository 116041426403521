import React, { Component } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from "../BaseComponent";
import CommonDateFilter from "../BaseComponent";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from "../../../ui/doctor/common/Pagination";
import { Tooltip } from "antd";
import AsyncStorage from "../../../AsyncStorage";
import Service from "../../../network/Service";

const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class IPDoctorReport extends Component {
  constructor(props) {
    super(props);
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data: {},
      totalAmount: "",
      selectedServiceAction: "",
      isServiceDetailed: false,
      isClear: false,
      isClearInt: 0,
      filterDate : {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      valueData: [],
      isPaginated: false,
      ListOpen: false,
      isAdminAccess: false,
      referredSourceList: [],
      selectedRefferedAction: "",
      total_patient: 0,
      admitting_consultant: "",
      surgery_doctor: ""
    };
  }

  async componentDidMount() {
    var loggedInData = await AsyncStorage.getItem("loggedInData");
    var loggedInDataObj = JSON.parse(loggedInData);
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access,
    });
    this.getIPDoctorList();
    //this.getRefferedBy()
    this.getDoctor();
  }

  getDoctor = () => {
    var serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorSuccess,
      this.getDoctorFailure
    );
  };

  getDoctorSuccess = (response) => {
    if (response.status == "success") {
      this.setState({
        doctorData: response.data.slice(0, -1),
        doctorSuggestionData: response.data.slice(0, -1),
      });
    }
  };
  getIPDoctorList = () => {
    this.setState
    ({isClear : false})
    var serviceUrl = Constants.GET_IP_DOCTOR_REPORT +
      "?ad_search=" +this.state.admitting_consultant   +
      "&doc_search=" +this.state.surgery_doctor + '&from_date='+ this.state.filterData.fromDate +
      '&to_date='+ this.state.filterData.toDate
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferenceListSuccess,
      this.getRefferenceListFailure
    );
  };

  getRefferenceListSuccess = (success) => {
    if (success.status == "success") {
      this.setState({
        data: success.data,
        valueData: success.data,
        total_patient: success.total_patient,
      });
    }
  };

  getRefferenceListFailure = (error) => {
    console.log("err getBillingListSuccess" + JSON.stringify(error));
  };

  renderPickerDoctorName(key) {
    return (
      <View>
        <Picker
          selectedValue={key == "admitting_consultant" ? this.state.admitting_consultant : key == "surgery_doctor" ? this.state.surgery_doctor : ""}
          style={[
            Style.pickerView,
            {
              marginTop: 5,
              width: "14.64vw",
              fontSize: "1vw",
              borderColor:
                this.state.errorColor &&
                this.state.mandatoryInformation.gender == ""
                  ? "red"
                  : "#CDD1D5",
            },
          ]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states[key] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label="Select Doctor Name" value="" />
          {this.state.doctorData && this.state.doctorData.length > 0
            ? this.state.doctorData.map((list) => (
                <Picker.Item
                  label={list.first_name + " " + list.initial}
                  value={list.first_name + " " + list.initial}
                />
              ))
            : null}
        </Picker>
      </View>
    );
  }

  onPressClear() {
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.setState({
      admitting_consultant: "", 
      surgery_doctor: "", 
      isClear: true, 
      isClearInt: 1,
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      }
    }, () => {
      this.getIPDoctorList();
      this.setState({
        isClear : false
      },() => {this.renderDateFilter()})
    })
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }

  printReport = () => {
    var service_url =
    Constants.GET_IP_DOCTOR_REPORT +
    "?ad_search=" +
    this.state.admitting_consultant +
    "&doc_search=" +
    this.state.surgery_doctor +
    "&export=excel" + '&from_date='+ this.state.filterData.fromDate +
    '&to_date='+ this.state.filterData.toDate;
    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      this.PrintPatienTokenSuccess,
      this.PrintPatienTokenFailure,
      "excel"
    );
  };

  PrintPatienTokenSuccess = (success) => {
    if (success) {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'IPDoctorReport.xlsx');
      pom.click();
    }
  };

  PrintPatienTokenFailure = (error) => {
    console.log(error);
  };

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data,
    });
  };

  render() {
    return (
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{
            width: "100%",
            height: screenHeight - 70,
            backgroundColor: "white",
          }}
        >
          <View style={styles.filterPanel}>
            <View style={styles.filterPanelBlocks}>
              <Text style={styles.filterHeader}>Filter:</Text>
            </View>
            <View style={styles.filterPanelBlocks}>
              <Text>Admitting Consultant: </Text>
              {this.renderPickerDoctorName("admitting_consultant")}
            </View>
            <View style={styles.filterPanelBlocks}>
              <Text>Surgery Doctor</Text>
              {this.renderPickerDoctorName("surgery_doctor")}
            </View>
            <View style ={{display:"flex", alignItems:"center", justifyContent:"center", marginLeft:"2vw", zIndex : 10}}>
              {this.renderDateFilter()}
            </View>
            <View style={styles.filterPanelBlocks}>
              <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "100%", paddingVertical: 8, paddingHorizontal: 8 }} onPress={()=> this.getIPDoctorList()}>
              <Text style={{ color: 'white', fontSize: 12, }}>{"Search"}</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.filterPanelBlocks}>
              <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "100%", paddingVertical: 8, paddingHorizontal: 8 }} onPress={()=> {this.onPressClear()} }>
              <Text style={{ color: 'white', fontSize: 12, }}>{"Clear"}</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.filterPanelBlocks}>
              <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "100%", paddingVertical: 8, paddingHorizontal: 8 }} onPress={()=> this.printReport()}>
              <Text style={{ color: 'white', fontSize: 12, }}>{"Export as Excel"}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ padding: ".8vw", zIndex : -5 }}>
            <View
              style={{
                width: "100%",
                marginTop: "1.5vw",
                paddingHorizontal: "0.35",
              }}
            >
              <CommonHistorySectionHeader
                heading1={"Patient Name"}
                heading2={"Admission Date"}
                heading3={"Surgery Doctor"}
                heading4={"Admitting Consultant"}
                heading5={"Visiting Consultant"}
                heading6={"Billing Amount"}
                noOfColumn={6}
                columnSize={[0.16, 0.16, 0.16, 0.16, 0.16, 0.16]}
              />
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.TableBody}
              >
                <View>
                  {this.state.valueData && this.state.valueData.length > 0 ? (
                    this.state.valueData.map((list) => {
                      var date = moment(list.ad_date).format(
                        "DD-MM-YYYY"
                      );
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            paddingVertical: "0.8vw",
                            width: "100%",
                            borderBottomWidth: 1,
                            borderBottomColor: color.lightGray,
                            height: "3.5vw",
                          }}
                        >
                          <View style={{ paddingHorizontal: 20, flex: 0.16, marginTop:"-.5vw" }}>
                            <Tooltip
                              placement="topLeft"
                              title={list.patient_name}
                            >
                              <Text style={{ fontSize: "1vw" }}>
                                {" "}
                                {list.patient_name &&
                                list.patient_name.length > 14
                                  ? list.patient_name.slice(0, 14) + ".."
                                  : list.patient_name}{" "}
                              </Text>
                            </Tooltip>
                            <View
                              style={{
                                flexDirection: "row",
                                marginLeft: "0.3vw",
                              }}
                            >
                              <Text style={{ fontSize: "0.75vw" }}>
                                {list.patient_account_number}
                              </Text>
                              <Text style={{ fontSize: "0.75vw" }}>
                                {list.patient_age && list.patient_age != ""
                                  ? " (" + list.patient_age + ")"
                                  : ""}
                              </Text>
                            </View>
                          </View>

                          <Text style={{ fontSize: "1vw", flex: 0.16 }}>
                            {date}
                          </Text>

                          <Text style={{ fontSize: "1vw", flex: 0.16 }}>
                            {list.surgery_doc ? list.surgery_doc : "-"}
                          </Text>
                          <Text style={{ fontSize: "1vw", flex: 0.16 }}>
                            {list.ad_consult ? list.ad_consult : "-"}
                          </Text>
                          <Text style={{ fontSize: "1vw", flex: 0.16 }}>
                            {list.vi_consult ? list.vi_consult : "-"}
                          </Text>
                          <Text style={{ fontSize: "1vw", flex: 0.16 }}>
                            {list.net_amount}
                          </Text>
                        </View>
                      );
                    })
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        minHeight: 100,
                      }}
                    >
                      <Text
                        style={{
                          color: color.placeholder,
                          marginTop: 50,
                          fontSize: "1vw",
                        }}
                      >
                        {"No records to be shown"}
                      </Text>
                    </View>
                  )}
                </View>
              </ScrollView>
            </View>
            <View style={{ marginLeft: 20 }}>
              {this.state.data && this.state.data.length > 0 ? (
                <Pagination
                  paginationChangedValues={this.paginationChangedValues.bind(
                    this.state.data
                  )}
                  totalItems={this.state.data}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  TableBody: {
    height: "63.5vh",
    overflow: "scroll",
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingVertical: "1vw",
    paddingHorizontal: "1vw",
    marginTop: "2.3vw",
  },
  headerContainer: {
    padding: ".5vw",
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: "1.5vw",
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw",
  },
  filterPanel: {
    padding: "0.5vw",
    flexDirection: "row",
  },
  filterPanelBlocks: {
    marginLeft: "3.5vw",
    justifyContent: "center",
  },
  filterHeader: {
    fontSize: "1.2vw",
    fontWeight: "bold",
  },
});
