import React, { Component } from 'react';
import { View, Text, Image, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ImageBackground, CheckBox } from 'react-native';
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
// import Arrow from "react-native-vector-icons/FontAwesome5";
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { HistoryYesOrNoSection, CommonButton, LabelWithTextBoxDR, CommonAddButton, DoctorNotesCommonRightSideHeader, CommonHistorySectionHeader, OBGYNHistorySectionHeading } from '../../BaseComponent'

import {NavigationTopHeader} from "../../BaseComponent"

import { Line } from 'react-chartjs-2';

const screenHeight = Dimensions.get("window").height;
const pageName = "isDevelopMentalAssessment"

const navigationHeaderList=[
    {label:"Growth Chart",value:"Growth Chart"}
]
const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};
var selectChart = [
    { label: "Height", value: "height" },
    { label: "Weight", value: "weight" },
    { label: "BMI", value: "bmi" },


];




  const AGE_DIGITS_UPTO_FIVE = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,0.10,0.11,
    1.0,1.1,1.2,1.3,1.4,1.5,1.6,1.7,1.8,1.9,1.10,1.11,
    2.0,2.1,2.2,2.3,2.4,2.5,2.6,2.7,2.8,2.9,2.10,2.11,
    3.0,3.1,3.2,3.3,3.4,3.5,3.6,3.7,3.8,3.9,3.10,3.11,
    4.0,4.1,4.2,4.3,4.4,4.5,4.6,4.7,4.8,4.9,4.10,4.11,
    5.0
  ];

  const AGE_DIGITS_FROM_FIVE = [5.0,5.3,5.6,5.9,6.0,6.3,6.6,6.9,
    7.0,7.3,7.6,7.9,8.0,8.3,8.6,8.9,
    9.0,9.3,9.6,9.9,10.0,10.3,10.6,10.9,
    11.0,11.3,11.6,11.9,12.0,12.3,12.6,12.9,
    13.0,13.3,13.6,13.9,14.0,14.3,14.6,14.9,
    15.0,15.3,15.6,15.9,16.0,16.3,16.6,16.9,
    17.0,17.3,17.6,17.9,18.0,18.3,18.6,18.9,19.0
  ];


  const GROWTH_CHART_XAXIS_AGE_UPTO_FIVE = ["Birth","","","3","","","6","","","9","","","1 year","","","3","","","6","","","9","","","2 year","","","3","","","6","","","9","","","3 year","","","3","","","6","","","9","","","4 year","","","3","","","6","","","9","","","5 year"];

  const GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL = [
    "5","","","","","","6months","","","","","","6","","","","","","6months","","","","","",
    "7","","","","","","6months","","","","","","8","","","","","","6months","","","","","",
    "9","","","","","","6months","","","","","","10","","","","","","6months","","","","","",
    "11","","","","","","6months","","","","","","12","","","","","","6months","","","","","",
    "13","","","","","","6months","","","","","","14","","","","","","6months","","","","","",
    "15","","","","","","6months","","","","","","16","","","","","","6months","","","","","",
    "17","","","","","","6months","","","","","","18","","","","","","6months","","","","","","19"];

    const AGE_DIGITS_FROM_FIVE_EXTRA_POINTS = [
      5.0,5.1,5.2,5.3,5.4,5.5,5.6,5.7,5.8,5.9,5.10,5.11,
      6.0,6.1,6.2,6.3,6.4,6.5,6.6,6.7,6.8,6.9,6.10,6.11,
      7.0,7.1,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,7.10,7.11,
      8.0,8.1,8.2,8.3,8.4,8.5,8.6,8.7,8.8,8.9,8.10,8.11,
      9.0,9.1,9.2,9.3,9.4,9.5,9.6,9.7,9.8,9.9,9.10,9.11,
      10.0,10.1,10.2,10.3,10.4,10.5,10.6,10.7,10.8,10.9,10.10,10.11,
      11.0,11.1,11.2,11.3,11.4,11.5,11.6,11.7,11.8,11.9,11.10,11.11,
      12.0,12.1,12.2,12.3,12.4,12.5,12.6,12.7,12.8,12.9,12.10,12.11,
      13.0,13.1,13.2,13.3,13.4,13.5,13.6,13.7,13.8,13.9,13.10,13.11,
      14.0,14.1,14.2,14.3,14.4,14.5,14.6,14.7,14.8,14.9,14.10,14.11,
      15.0,15.1,15.2,15.3,15.4,15.5,15.6,15.7,15.8,15.9,15.10,15.11,
      16.0,16.1,16.2,16.3,16.4,16.5,16.6,16.7,16.8,16.9,16.10,16.11,
      17.0,17.1,17.2,17.3,17.4,17.5,17.6,17.7,17.8,17.9,17.10,17.11,
      18.0,18.1,18.2,18.3,18.4,18.5,18.6,18.7,18.8,18.9,18.10,18.11,19.0
    ];

    var context;
var sections;
var xScale;
var yScale;


export class GrowthChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            scrollPosition: 0,
            selectedSection: "",
            refreshRighSideComponentName: "",
            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            selectedPageName: "",
            selectchartValue: { label: "Height", value: "height" },
            selectChart: selectChart,


            groupData: { 
                patient: {},
                patientGrowth: {}
            },
            commonString: "",
            ageString: "",
            data: { 
                labels: [],
                datasets: [] 
                },
            heightData: [],
            chartBGColor: "#0099cc",
            getSuccessResponce:{}
        }
    }


    componentDidMount() {

    this.prepareDataForGrowthChart()

    // this.onPressButton('selectchartValue', { label: "BMI", value: "bmi" });

        //   var patientAge=this.state.patientInfo.age
        //   var agesplit=patientAge.slice(0, 7)
        //   var convert=parseInt(agesplit)
        //    if(convert<=5){

        //     this.setState({
        //         defaultAge:agesplit
        //     },()=>{
        //         if(defaultAge == )
        //     })

        //     //   var babyage=agesplit

        //   }


    }

  

    prepareDataForGrowthChart = () => {
  
    var service_url = Constants.GROWTH_CHART_GET+"?patient_id="+this.state.patientAppointment.patient_id
  

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.prepareDataForGrowthChartSuccess,
      this.prepareDataForGrowthChartFailure
    );
  };

  prepareDataForGrowthChartSuccess = response => {
    if (response.status === "success") {
     
      // this.setPlotHeightChart(response,this.state.selectchartValue.value);
     
      this.setState({  getSuccessResponce:response  },()=>{
        this.setPlotHeightChart(response,this.state.selectchartValue.value);
      })

     console.log(response);

    // let groupData = {
    //     patient: response.data.patient,
    //     patientGrowth: response.data.patient_growth

    // }

    // this.setState({groupData: groupData, heightData: response.data.standard_growth_chart.height});



    //  this.canvasDraw();
    //  this.plotUserData_heightBoy0_5(this.state.groupData.patientGrowth.age, this.state.groupData.patientGrowth.height,'');
    

    }
  };

  prepareDataForGrowthChartFailure = error => {
    console.log("opthamology complaints GET error response");
    console.log(error);
  };

  convertIntoMonth(floatValue){

    // let { patient } = this.state.groupData;
    // let age = this.getPatientAge(patient.age);
    let months = 0
    let data = parseFloat(floatValue);
    let arr = data.toString().split(".");

    // make it 2 digits
    if(arr.length == 1){
      
      data = parseFloat(floatValue).toFixed(2);
      arr = data.toString().split(".");
    }

    if(arr.length == 2) {

      months = arr[0]*12;
      months = months + parseInt(arr[1]);
    }

    console.log("floatValue --- " + floatValue + " --- " + months)
    return months;
  }

  getPatientAge (stringAge) {

    let age = 0;
    let dataArr = (stringAge).split(",")
    let lenArr = dataArr.length;

    if (lenArr > 0) {

      age = parseInt(dataArr[0]);

      let m = parseInt(dataArr[1]);
      let d = parseInt(dataArr[2]);

      if (m > 0 || d > 0) {
        age = age + 1;
      }
    }

    return age;
  }


  canvasDraw(){

    // patient condition setup
    let {patient} = this.state.groupData;
    let age = this.getPatientAge(patient.age);

    // set these values for your data 
    var sections1 = patient.gender==="m"?(age<=5?64:60):(age<=5?64:60);
    sections = patient.gender==="m"?(age<=5?61:57):(age<=5?61:57);;		// 5 * 12 + 1 = 61
    var Val_max = patient.gender==="m"?(age<=5?125:190):(age<=5?125:180);
    var Val_min = patient.gender==="m"?(age<=5?40:100):(age<=5?40:80);;
    var stepSize = age<=5?5:10;
    var columnSize = 50; // 12.5 15
    var rowSize = age<=5?40:50;
    var margin = 10;

    var line5TopAdjustment = age<=5?7:1;
    var line4TopAdjustment = age<=5?11:2.5;
    var line3TopAdjustment = age<=5?16:5.5;
    var line2TopAdjustment = age<=5?21:8.5;
    var line1TopAdjustment = age<=5?26:10.5;
  

    // Values for the Data Plot, they can also be obtained from a external file
    // 57 columns
    // Default datas
    var firstLine = this.state.heightData[0].value;
    
    var fifteenth = this.state.heightData[1].value;
    
    var fifteeth = this.state.heightData[2].value;
    
    var eightyFifth = this.state.heightData[3].value;
    
    var nintySeventh = this.state.heightData[4].value;

    var xAxis = age>5?["", "5", "", "", "", "6", "", "", "", "7", "", "", "", "8", "", "", "", 
    "9", "", "", "", "10", "", "", "", "11", "", "", "", "12", "", "", "", 
    "13", "", "", "", "14", "", "", "", "15", "", "", "", "16", "", "", "", 
    "17", "", "", "", "18", "", "", "", "19"]:
    ["", "Birth", "", "", "", "", "", "", "", "", "", "", "",
      "1 year", "", "", "", "", "", "", "", "", "", "", "",
      "2 year", "", "", "", "", "", "", "", "", "", "", "",
      "3 year", "", "", "", "", "", "", "", "", "", "", "",
      "4 year", "", "", "", "", "", "", "", "", "", "", "", "5 year"];

    //Constants.GROWTH_CHART_XAXIS_AGE_FROM_FIVE:Constants.GROWTH_CHART_XAXIS_AGE_UPTO_FIVE;


    let canvas = this.refs.canvas
    context = canvas.getContext("2d");

    context.fillStyle = "#FFF";
    context.font = "14px sans-Serif"; //20 pt Verdana
    context.lineWidth = 1.5;
    context.textAlign = 'center';// text alignment

    yScale = (canvas.height - rowSize - margin) / (Val_max - Val_min);
    xScale = (canvas.width - 3 * columnSize) / sections; // changed from 2* to 3* for extra empty column

    context.strokeStyle = "#757575"; //009933 color of grid lines

    // start add inner color												 
    context.rect(columnSize + margin, margin, canvas.width - 2 * columnSize - margin, canvas.height - rowSize - margin);
    // create a gradient drawing object and assign it to a variable
    var linear = context.createLinearGradient(0, 0, 750, 400);
    // color at the starting point
    linear.addColorStop(0, '#FFF');
    // color at the end point
    linear.addColorStop(1, '#FFF');
    context.fillStyle = linear;
    context.fill();
    // end inner color


    // print Parameters on X axis, and grid lines on the graph
    var titleSet = age<=5?12:4;
    for (var i = 1; i <= sections; i++) { // i++

      var x = i * xScale + columnSize;
      var index = i;
          
      // set title for 1st and every 4th fourth y axis 
      var returnModulur = titleSet % (age<=5?12:4);
      if (returnModulur === 0) {

        context.font = "12px sans-Serif";
        context.beginPath();
        context.lineWidth = 1.5;
        context.fillText(xAxis[index], x, canvas.height - margin); // titlePosition y = columnSize - margin	
      } else {

        if(age<=5) {
          // default line width for else case
          context.lineWidth = 0.5;
          
          if (returnModulur % 2 === 0) {
            
            var subTitle = returnModulur; // for print even numbers

            context.font = "8px sans-Serif";
            context.beginPath();
            context.fillText(subTitle, x, canvas.height - 3 * margin); // titlePosition y = columnSize - margin
          }
        }else{

          var subTitle = returnModulur * 3;
			
          context.font = "8px sans-Serif";
          context.beginPath();
          context.lineWidth= 0.5;
          context.fillText(subTitle, x,canvas.height - 3*margin); // titlePosition y = columnSize - margin
        }
      }

      titleSet = titleSet + 1;

      context.moveTo(x, margin);	// columnSize
      context.lineTo(x, canvas.height - rowSize);

      context.stroke();
    }

    // print row header and draw horizontal grid lines
    context.lineWidth = 1.5;
    context.beginPath();
    var count = 0;
    for (var scale = Val_max; scale >= Val_min; scale = scale - stepSize) {

      // from top design
      var y = margin + (yScale * count * stepSize);
      // left side scale
      context.fillText(scale, margin, y);
      // draw harizontal line
      context.moveTo(columnSize + margin, y);				//  + margin extra
      context.lineTo(canvas.width - columnSize, y)
      // right side scale
      context.fillText(scale, canvas.width - 2 * margin, y);
      count++;
    }
    context.stroke();

    // draw y axis quarter row line
    context.lineWidth = 0.5;
    context.beginPath();
    var count = 0;
    for (scale = Val_max; scale >= Val_min; scale = scale - stepSize) {

      var y = margin + (yScale * count * stepSize);

      // quarter row
      var qy = 0;

      if (count > 0) {

        var previousY = margin + (yScale * (count - 1) * stepSize);
        var currentY = margin + (yScale * count * stepSize);

        if(age<=5) {

          // get quarter section value from previous and current row.
          qy = (currentY - previousY) / 5;

          // 1st
          context.moveTo(columnSize + margin, previousY + qy);
          context.lineTo(canvas.width - 2 * columnSize, previousY + qy);

          // 2nd
          context.moveTo(columnSize + margin, previousY + 2 * qy);
          context.lineTo(canvas.width - 2 * columnSize, previousY + 2 * qy);

          // 3rd
          context.moveTo(columnSize + margin, previousY + 3 * qy);
          context.lineTo(canvas.width - 2 * columnSize, previousY + 3 * qy);

          // 4th
          context.moveTo(columnSize + margin, previousY + 4 * qy);
          context.lineTo(canvas.width - 2 * columnSize, previousY + 4 * qy);
        
        }else{

          // get quarter section value from previous and current row.
          qy = (currentY-previousY)/2;
        
          // 1st
          context.moveTo(columnSize+ margin,previousY+qy)
          context.lineTo(canvas.width-2*columnSize,previousY+qy)
        }
      }
      count++;
    }
    context.stroke();

    // default line - label name start
    context.beginPath();
    context.font = 'bold 11px sans-Serif';
    context.lineWidth = 2;
    context.fillStyle = "#ad5511";
    context.fillText("97th", canvas.width - 1.5 * columnSize, margin + line5TopAdjustment * stepSize);

    context.fillStyle = "#ed8f47";
    context.fillText("85th", canvas.width - 1.5 * columnSize, margin + line4TopAdjustment * stepSize);

    context.fillStyle = "#228B22";
    context.fillText("50th", canvas.width - 1.5 * columnSize, margin + line3TopAdjustment * stepSize);

    context.fillStyle = "#ed8f47";
    context.fillText("15th", canvas.width - 1.5 * columnSize, margin + line2TopAdjustment * stepSize);

    context.fillStyle = "#ad5511";
    context.fillText("3rd", canvas.width - 1.5 * columnSize, margin + line1TopAdjustment * stepSize);
    context.stroke();
    // label name end

    // origin translated
    // x-axis, y-axis
    // context.translate(columnSize+margin,canvas.height + Val_min * yScale);
    context.translate(columnSize + margin, canvas.height + (Val_min - 5.5) * yScale - margin); // canvas.height + Val_min * yScale
    context.scale(1, -1 * yScale);

    // Color of each dataplot items and draw function launched
    context.strokeStyle = "#ad5511";
    this.plotData_height(firstLine, "3rd", patient.gender==="m"?81.4:79.6);

    context.strokeStyle = "#ed8f47";
    this.plotData_height(fifteenth, "15th", patient.gender==="m"?83.9:82.4);

    context.strokeStyle = "#228B22";
    this.plotData_height(fifteeth, "50th", patient.gender==="m"?87.1:85.7);

    context.strokeStyle = "#ed8f47";
    this.plotData_height(eightyFifth, "85th", patient.gender==="m"?90.3:89.1);

    context.strokeStyle = "#ad5511";
    this.plotData_height(nintySeventh, "97th", patient.gender==="m"?92.9:91.8);
  

    var stringOne = "Length / height for age";
    var stringTwo = patient.gender === "m" ? " Boys " : " Girls ";

    var stringThree = age <= 5 ? "0-5 years" : "5-19 years";
    var stringFour = " (Percentile)";

    this.setState({
      commonString: stringOne + stringTwo,
      ageString: stringThree + stringFour
    });
    
  }

  // default data plot done here
  plotData_height(dataSet, title, cutPoint) {

    let { patient } = this.state.groupData;

    //	context.textAlign = 'right';// text alignment
    context.lineWidth = 0.5;
    context.beginPath();
    context.moveTo(0, dataSet[0]);

    for (var i = 1; i < sections; i++) {

      context.lineTo(i * xScale, dataSet[i]);	// xScale

      if(this.getPatientAge(patient.age) <= 5)
      {
        if (i === 24) {
          context.lineTo(i * xScale, cutPoint);
        }
      }
    }
    context.stroke();
  }


  plotUserData_heightBoy0_5(monthData, dataSet, title='') {

    let { patient } = this.state.groupData;
    let age = this.getPatientAge(patient.age);

    var len = dataSet.length;
    //var newxScale = xScale*sections/len;
    var getColumnIndex = 0;

    context.strokeStyle="#ff0000";
    context.lineWidth = age<=5?0.5:0.3;
    context.beginPath();

    if (this.convertIntoMonth(monthData[0]) > 0) {

      getColumnIndex = this.convertIntoMonth(monthData[0]);

      if(age>5){

        if(this.convertIntoMonth(monthData[0])>=60){
          getColumnIndex = getColumnIndex-60;
        }
        
        if(getColumnIndex>=3){
          
          getColumnIndex = Math.floor(getColumnIndex / 3);
        }
      }
    }

    // set cross mark length
    var fwdX0 = (getColumnIndex + 0.5) * xScale;
    var bwdX0 = (getColumnIndex - 0.5) * xScale;

    // draw cross mark

    // from down left to right top line
    context.moveTo(bwdX0, dataSet[0] - 1);
    // to right up
    context.lineTo(fwdX0, dataSet[0] + 1);
    // move to right down
    context.moveTo(fwdX0, dataSet[0] - 1);
    // to left up													
    context.lineTo(bwdX0, dataSet[0] + 1);
    // back to normal position											

    // reset point to origin
    context.moveTo(getColumnIndex * xScale, dataSet[0]);

    for (let i = 1; i < len; i++) {	//sections

      getColumnIndex = this.convertIntoMonth(monthData[i]);

      if(age>5){

        getColumnIndex = 0;

        if(this.convertIntoMonth(monthData[i])>=60){
          getColumnIndex = getColumnIndex-60;
        }
        
        if(getColumnIndex>=3){
          
          getColumnIndex = Math.floor(getColumnIndex / 3);
        }
      }

      //alert(getColumnIndex + '-' + getColumnIndex * xScale + '-' + dataSet[i]);
      context.lineTo(getColumnIndex * xScale, dataSet[i]);	// xScale

      // set cross mark length
      var fwdX = (getColumnIndex + 0.5) * xScale;
      var bwdX = (getColumnIndex - 0.5) * xScale;

      // draw cross mark

      // from down left to right top line
      context.moveTo(bwdX, dataSet[i] - 1);
      // to right up
      context.lineTo(fwdX, dataSet[i] + 1);
      // move to right down
      context.moveTo(fwdX, dataSet[i] - 1);
      // to left up													
      context.lineTo(bwdX, dataSet[i] + 1);
      // back to normal position
      context.moveTo(getColumnIndex * xScale, dataSet[i]);

    }
    context.stroke();
  }



  setPlotHeightChart(response,type) {

    console.log("BOYS HEIGHT CHART");
    console.log("setPlotHeightChart");

    let patientGrowth = response.data.patient_growth;
    let patient = response.data.patient;

    console.log("=======PATIENT======");
    console.log(patient);
    console.log("=======PATIENT GROWTH======");
    console.log(patientGrowth);

    let patientData;
    let standardData;

    var standard_growth_chart = Object.keys(response.data).length > 0 && Object.keys(response.data.standard_growth_chart).length >0 ?
    
    response.data.standard_growth_chart : {}

    if(type === "height"){
      patientData =  patientGrowth && patientGrowth.height ? patientGrowth.height :[];
      standardData  = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.height ? standard_growth_chart.height : 0;
    }
    else if(type === "weight"){
      patientData = patientGrowth && patientGrowth.weight ? patientGrowth.weight :[];
      standardData  = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.weight ? standard_growth_chart.weight : 0;
    }
    else if(type === "bmi"){
      patientData = patientGrowth && patientGrowth.bmi ? patientGrowth.bmi :[];
      standardData  = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.bmi ? standard_growth_chart.bmi : 0;
    }

    //var fields = this.state.groupData;
    var dataFields = this.state.data;

    var heightList = standardData;

    let age = "";

    if (patient) {

      let dataArr = (patient.age).split(",")
      let lenArr = dataArr.length;

      if (lenArr > 0) {

        age = parseInt(dataArr[0]);

        let m = parseInt(dataArr[1]);
        let d = parseInt(dataArr[2]);

        if (m > 0 || d > 0) {
          age = age + 1;
        }
      }
    }

    let patientAge = age;
    //let digitsAge = parseInt(patientAge) <= 5 ? Constants.AGE_DIGITS_UPTO_FIVE : Constants.AGE_DIGITS_FROM_FIVE_EXTRA_POINTS;

    dataFields.labels = parseInt(patientAge) <= 5 ? GROWTH_CHART_XAXIS_AGE_UPTO_FIVE : GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL;

    var colorCode = [
      "rgba(255, 0, 0, 0.75)",
      "rgba(0, 255, 0, 0.75)",
      "rgba(0, 0, 255, 0.75)"
    ];

    
    if(parseInt(patientAge) > 5) {

      
      let len = heightList.length;
      console.log(" len - " + len);
      console.log(JSON.stringify(heightList));

      for(var outer=0;outer<len;outer++){

        let newHeights = [];
        let item = heightList[outer];
        let innerLen = item.value.length;

        console.log(" innerLen - " + innerLen);
        console.log(JSON.stringify(item));

        for(var n=0;n<innerLen;n++){

          if(n===0 || n===(innerLen-1)) {

            newHeights.push(heightList[outer]["value"][n]);
          }else{

            let diff = heightList[outer]["value"][n] - heightList[outer]["value"][n-1];
            let colDiff = diff/6;

            for(let nC = 1; nC <= 5; nC++) {

              newHeights.push( heightList[outer]["value"][n-1] + colDiff*nC )
            }

            newHeights.push( heightList[outer]["value"][n]);
          }

        }

        heightList[outer]["value"] = newHeights;

      }

    }

    var sample =
      heightList &&
      heightList.length > 0 &&
      heightList.map((item, i) => {

        let color = "";
        if (i === 0 || i === 4) { color = "#cc33ff"; }
        if (i === 1 || i === 3) { color = "#00ccff"; }
        if (i === 2) { color = "#00ff99"; }
        if (i === 6) { color = "#ff0066"; }


        return {
          label: item.label,
          data: item.value,
          backgroundColor: "rgba(255, 255, 255, 0)",
          borderColor: color,  // colorCode[i % 3]
          fill: false,
          lineTension: 0.5,
          //highlightEnabled: false,
          axisY: {
            stripLines: {}
          },
          pointRadius: 0,
        };
      });

    if (sample) {
      sample.pop();


      var stringOne = "Length / height for age";
      var stringTwo = patient.gender === "m" ? " Boys " : " Girls ";

      var stringThree = patientAge <= 5 ? "0-5 years" : "5-19 years";
      var stringFour = " (Percentile)";
      dataFields.datasets = sample;

      var patient_record_count = patientGrowth && patientGrowth.age ? patientGrowth.age.length : 0;
      var patient_rec = [];


      // var lables_count = dataFields.labels ? dataFields.labels.length : 0;

      var extra_digits = parseInt(patientAge) <= 5 ? AGE_DIGITS_UPTO_FIVE : AGE_DIGITS_FROM_FIVE_EXTRA_POINTS
      var lables_count = extra_digits.length;

      for (var j = 0; j < lables_count; j++) {

        var is_captured = false;
        for (var i = 0; i < patient_record_count; i++) {

          console.log("chart value check - " + extra_digits[j] + " - " + dataFields.labels[j] + ' - ' +  patientGrowth.age[i])

          //if(dataFields.labels[j] == patientGrowth.age[i]) {

          if (extra_digits[j] == patientGrowth.age[i]) {

            patient_rec.push(patientData[i]);
            is_captured = true;
          }
        }

        if (!is_captured) {
          patient_rec.push('');
        }
      }

      var patient_height_dataset = {
        label: "Patient Rcord",
        data: patient_rec,
        backgroundColor: "rgba(255, 255, 255, 0)",
        type: 'bar',
        backgroundColor: '#FF0000',
        borderColor: '#FF0000',  //'#71B37C',
        fill: false,
        lineTension: 0.5
      };

      dataFields.datasets.push(patient_height_dataset);


      console.log(" dataFields.datasets - " + JSON.stringify(dataFields.datasets));

      this.setState({
        //      fields,
        dataFields,
        commonString: stringOne + stringTwo,
        ageString: stringThree + stringFour
      });
    }
  }





    onSubmitToAddValue() {
        var states = this.state;
        var { birthWeightList } = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showSelectedBirthWeightListNewButtonFlag) {
            birthWeightList.push(preparData);
            birthWeightList = this.removeDuplicate(birthWeightList);
            states["newButtonValue"] = "";
            states["showSelectedBirthWeightListNewButtonFlag"] = true;
            states["selectedBirthWeight"] = preparData;
        }
        this.setState({
            birthWeightList, states
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var changedItem = {}
                            if (key == "selectedBirthWeight") {
                                changedItem = { label: item.label + " kgs", value: item.value }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        disable={this.state.ageDisable}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }
    renderComponent() {


        return (
            <View>
                <Text style={{ fontSize: 18, color: color.themeDark, fontWeight: "600", marginBottom: 10 }}>{"Growth Chart"}</Text>
                {
                    this.renderLabelWithButtonGroup(
                        "Filter",
                        "selectchartValue",
                        this.state.selectchartValue,
                        "",
                        false,
                        this.state.selectChart)
                }


            </View>
        );
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    editHistory(item, pageName) {

        var data = {
            pageName: pageName,
            editItem: item
        }

        this.setState({
            editHistoryItem: data
        })
    }



    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Icon size={25} name={"ban"} color={"#D26560"} />
                    <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                        <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }


    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "selectAge") {
            states[key] = selectedValue
            this.setState({
                states,

            })

        } else {
            states[key] = selectedValue;
            this.setState({ states },()=>{
              this.prepareDataForGrowthChart()
            })
        }

    }

    renderComments() {
        return (
            <View style={{ marginTop: 20 }}>
                <CommonHistorySectionHeader
                    heading1={"Comments"}
                    noOfColumn={1}
                />
                <Text style={[Style.historyHeaderFontStyle, { margin: 10, marginLeft: 20 }]}>{this.state.comments}</Text>

            </View>
        )
    }







    render() {
        let data = this.state.data; 
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={[Style.leftView, { width: "65%", backgroundColor: color.applicationBackgroundColor }]}>
                    <View>
                        <NavigationTopHeader
                            changeTabSection={"Growth Chart"}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={"Growth Chart"} />
                    </View>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginBottom: 50 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >

                        <View style={{
                            marginTop: 10,
                            backgroundColor: color.sectionBackgroundColor, marginHorizontal: 8, borderRadius: 4, padding: 10, paddingVertical: 10, paddingLeft: 20
                        }}>
                            <TouchableOpacity style={{ marginBottom: 20 }} >
                                <Text style={{ fontSize: 18, color: color.themeDark, fontWeight: "600" }}>{"Growth Chart"}</Text>
                            </TouchableOpacity>
                            {/* <View style={{ backgroundColor: "red" }}>
                                <canvas ref="canvas" width={750} height={500} />
                            </View> */}
                            {/* <Line ref="chart" data={data} /> */}


                            
          
          {/* <View style={{ backgroundColor: this.state.chartBGColor }}>
            <canvas ref="canvas" width={750} height={500} />
          </View> */}
        
                            

                            

                            <Line
                                options={{
                                    // see all defaults / options below!
                                    lineHeightAnnotation: {
                                        color: "#000",
                                        shadow: false
                                    }
                                }}

                                data={data}
                            /> 


                        </View>

                    </ScrollView>
                </View>


                <View style={{ padding: 20, paddingRight: 10, width: "35%", backgroundColor: color.themeShade, }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    {this.renderComponent()}
                </View>


            </View>
        )
    }

}