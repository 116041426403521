import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet } from "react-native";
import { color } from "../../../../styles/Color";
import { Tabs } from 'antd';
import CorporateBillReport from '../CorpTabs/ReportTabs/CorporateBillReport';
import EnrollListReport from '../CorpTabs/ReportTabs/EnrollListReport';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      CorpEnrollCount: 0,
      EmployeeBillsCount: 0,
      CorporateBillsCount: 0,
    };
  }

  componentDidMount() {
    this.GetCardCounts()
  }

  GetCardCounts = () => {
    var serviceUrl = Constants.GET_CORP_REPORTS_COUNTS;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getCorporateListSuccess,
      this.getCorporateListFailure
    );

  }

  getCorporateListSuccess = (response) => {
    try {
      this.setState({
        CorpEnrollCount: response.data.corporate_enrolment,
        EmployeeBillsCount: response.data.employee_billings,
        CorporateBillsCount: response.data.corporate_bills,
      })
    }
    catch (err) {
      console.log(err)
    }
  }
  getCorporateListFailure = (error) => {
    console.log(error.message)
  }

  handleChange = activeKey => {
    this.setState({ activeKey });
  };

 
ErroralertValue = (msg) => {
    this.props.ErrorAlert(msg)
}

  render() {
    return (
      <View style={styles.MainView}>
        <View style={styles.CardMainView}>
          <View style={styles.singleCard}>
            <Text style={styles.CardHeadText}>Corporate Enrollment</Text>
            <Text style={styles.CardCountText}>{this.state.CorpEnrollCount}</Text>
          </View>
          <View style={styles.singleCard}>
            <Text style={styles.CardHeadText}>Employee Bills</Text>
            <Text style={styles.CardCountText}>{this.state.EmployeeBillsCount}</Text>
          </View>
          <View style={styles.singleCard}>
            <Text style={styles.CardHeadText}>Corporate Bills</Text>
            <Text style={styles.CardCountText}>{this.state.CorporateBillsCount}</Text>
          </View>
        </View>
        <View style={styles.ReportsMainView}>

          <View>
            <Tabs onChange={this.handleChange} activeKey={this.state.activeKey} tabPosition="left">
              <Tabs.TabPane tab="Corporate Bill Report" key="1">
                {this.state.activeKey === "1" ? <CorporateBillReport ErrorAlert={this.ErroralertValue.bind(this)}/> : null}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Corporate Enrollment List Report" key="2">
                {this.state.activeKey === "2" ? <EnrollListReport ErrorAlert={this.ErroralertValue.bind(this)}/> : null}
              </Tabs.TabPane>
            </Tabs>
          </View>

        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  MainView: {
    backgroundColor: color.themeShade, height: '72vh', width: '100%'
  },
  CardMainView: {
    // shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
    height: '11vw',
    width: '95%',
    // backgroundColor: color.white,
    marginTop: '1vw',
    marginLeft: '2vw',
    marginRight: '2vw',
    borderRadius: '0.26vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '3vw'
  },
  singleCard: {
    width: '20vw',
    height: '8vw',
    display: 'flex',
    flexDirection: 'column',
    shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
    borderRadius: '0.26vw',
    marginRight: '3vw',
    backgroundColor: color.white,
  },
  CardHeadText: {
    height: '2.5vw',
    fontSize: '1.2vw',
    fontWeight: '500',
    marginLeft: "1vw",
    marginTop: "0.5vw",
    paddingBottom: "0.3vw",
    borderBottomWidth: 2,
    borderColor: "#CDD1D5",
    marginRight: '1vw'
  },
  CardCountText: {
    height: '3vw',
    marginTop: '0.5vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '3vw',
    fontWeight: '500',
    marginRight: '1vw',
    color: color.themeDark
  },
  ReportsMainView: {
    height: '20vw',
    backgroundColor: color.white,
    shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
    width: '97%',
    marginHorizontal: '1vw',
    marginTop: '2vw'
  },
})
