import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "PsychologicalSymptoms"


export class PsychologicalSymptomsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            hallucinationsFlag: false,
            detailsOfHallucinations: "",

            suspicionsFlag: false,
            detailsOfSuspicions: "",

            anxietyFlag: false,
            detailsOfAnxiety: "",

            depressiveFlag: false,
            detailsOfDepressive: "",

            sexualProblemsFlag: false,
            detailsOfSexualProblems: "",

            additionalNotesFlag: false,
            detailsOfAdditionalNotes: ""

        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["hallucinationsFlag"] = data.hallucinations;
                states["detailsOfHallucinations"] = data.hallucinations_detail;

                states["suspicionsFlag"] = data.suspicions;
                states["detailsOfSuspicions"] = data.suspicions_detail;

                states["anxietyFlag"] = data.anxiety;
                states["detailsOfAnxiety"] = data.anxiety_detail;

                states["depressiveFlag"] = data.depressive;
                states["detailsOfDepressive"] = data.depressive_detail;


                states["sexualProblemsFlag"] = data.sexual_problem;
                states["detailsOfSexualProblems"] = data.sexual_problem_detail;

                states["additionalNotesFlag"] = data.additional_notes;
                states["detailsOfAdditionalNotes"] = data.additional_notes_detail;


                states["id"] = data.id;

                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["hallucinationsFlag"] = false;
        states["detailsOfHallucinations"] = "";

        states["suspicionsFlag"] = false;
        states["detailsOfSuspicions"] = "";

        states["anxietyFlag"] = false;
        states["detailsOfAnxiety"] = "";

        states["depressiveFlag"] = false;
        states["detailsOfDepressive"] = "";


        states["sexualProblemsFlag"] = false;
        states["detailsOfSexualProblems"] = "";

        states["additionalNotesFlag"] = false;
        states["detailsOfAdditionalNotes"] = "";


        this.setState({ states })
    }

    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {
            var data = {
                "appointment_id": this.state.patientAppointment.appointment_id,
                "patient_id": this.state.patientAppointment.patient_id,

                "hallucinations": states.hallucinationsFlag,
                "hallucinations_detail": states.detailsOfHallucinations,

                "suspicions": states.suspicionsFlag,
                "suspicions_detail": states.detailsOfSuspicions,

                "anxiety": states.anxietyFlag,
                "anxiety_detail": states.detailsOfAnxiety,

                "depressive": states.depressiveFlag,
                "depressive_detail": states.detailsOfDepressive,

                "sexual_problem": states.sexualProblemsFlag,
                "sexual_problem_detail": states.detailsOfSexualProblems,

                "additional_notes": states.additionalNotesFlag,
                "additional_notes_detail": states.detailsOfAdditionalNotes,

            }

            if (this.state.id) {
                data["id"] = this.state.id;
            }

            var service_url = Constants.PSY_PSYCHOLOGICAL_HISTORY;

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.savePsychologicalSymptomsSuccess,
                this.savePsychologicalSymptomsFailure
            );
        }
        else {
            this.props.showResposeValue("error", "Psychological symptoms already added please click on edit icon to update the values")
        }
    }

    savePsychologicalSymptomsSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName, true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    savePsychologicalSymptomsFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Psychological Symptoms"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Hallucinations", "Enter details",
                    "hallucinationsFlag", this.state.hallucinationsFlag,
                    "detailsOfHallucinations", this.state.detailsOfHallucinations
                )}

                {this.renderHistoryComponent(
                    "Suspicions", "Enter details",
                    "suspicionsFlag", this.state.suspicionsFlag,
                    "detailsOfSuspicions", this.state.detailsOfSuspicions
                )}

                {this.renderHistoryComponent(
                    "Anxiety", "Enter details",
                    "anxietyFlag", this.state.anxietyFlag,
                    "detailsOfAnxiety", this.state.detailsOfAnxiety
                )}

                {this.renderHistoryComponent(
                    "Depressive", "Enter details",
                    "depressiveFlag", this.state.depressiveFlag,
                    "detailsOfDepressive", this.state.detailsOfDepressive
                )}

                {this.renderHistoryComponent(
                    "Sexual Problems", "Enter details",
                    "sexualProblemsFlag", this.state.sexualProblemsFlag,
                    "detailsOfSexualProblems", this.state.detailsOfSexualProblems
                )}
                {this.renderHistoryComponent(
                    "Additional Notes", "Enter details",
                    "additionalNotesFlag", this.state.additionalNotesFlag,
                    "detailsOfAdditionalNotes", this.state.detailsOfAdditionalNotes
                )}

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
