import React, { Component } from 'react';
import { View, StyleSheet } from "react-native";
import { NavigationTopHeader } from "../BaseComponent";
import { color } from "../../../styles/Color";
import { Tabs } from 'antd';
import ExpenseBilling from '../common/ExpenseTabs/ExpenseBilling';
import ConsolidateExpense from '../common/ExpenseTabs/ConsolidateExpense';
import { Constants } from "../../../utils/Constants";

export class ExpenseConfig extends Component {
    
    constructor(props) {
        super(props);

        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {}: JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
        this.state = {
            activeKey: '1',
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic == false ?  false  : true,
            userType:this.props.userType
        };
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    SuccessalertValue = (msg) => {
        this.props.showResposeValue("success", msg)
    }
    ErroralertValue = (msg) => {
        this.props.showResposeValue("error", msg)
    }

    handleChange = activeKey => {
        this.setState({ activeKey });
    };

    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.isOPT || this.state.userType === Constants.ROLE_FO ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                    selectedTab={"expenseconfig"}
                    isNavigationBorder={true} />

                <View style={styles.TabView}>
                    <Tabs onChange={this.handleChange} activeKey={this.state.activeKey}>
                        <Tabs.TabPane  tab="Expense Bill" key="1">
                            {this.state.activeKey === "1" ? <ExpenseBilling SuccessAlt={this.SuccessalertValue.bind(this)}  ErrorAlert={this.ErroralertValue.bind(this)}/> : null}
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane  tab="Consolidate Report" key="2">
                            {this.state.activeKey === "2" ? <ConsolidateExpense SuccessAlt={this.SuccessalertValue.bind(this)}  ErrorAlert={this.ErroralertValue.bind(this)}/> : null}
                        </Tabs.TabPane> */}
                    </Tabs>
                </View>

            </View>

        )
    }
}

export default ExpenseConfig

const styles = StyleSheet.create({
    TabView: {
        marginLeft: '1vw'
    }
})