import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Image, TextInput } from 'react-native';
import { NavigationTopHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import Pagination from '../../common/Pagination';
import { Tooltip } from 'antd';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import moment from "moment";
import ImagePath from '../../../../utils/ImagePaths';
const navigationHeader = [
  { label: "Stock Transfer", value: "stockTransfer" },
  { label: "InBound", value: "inBound" },
  { label: "OutBound", value: "outBound" },
  { label: "Stock Transfer Report", value: "stockTransferReport" }
]
export default class Inbound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockTransferList: [],
      stockTransferAfterPagination: [],
      current_page: 1,
      records_per_page: 10,
      isUpdateClick: false,
      outBoundPopupList: [],
      isEditDetails: false,
      selectedIndex: "",
      isSelfTransfer: false,
      isCourierTransfer: false,
      popUpHeaderData: {},
      selectedID: ""
    };
  }
  componentDidMount() {
    this.getInboundList()
  }
  getInboundList = () => {
    var url = Constants.STOCK_TRANSFER_INBOUND + "?summary_list=true"
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getInboundListSuccess,
      this.getInboundListFailure
    );
  }

  getInboundListSuccess = response => {
    if (response && response.status == "success") {
      var data = response.data
      this.setState({
        stockTransferList: data
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  getInboundListFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  changeTabSection(data) {
    // do nothing
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.7vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
      </View>)
  }
  getPopupDataList = (item) => {
    var url = Constants.STOCK_TRANSFER_INBOUND + "?summary_list=" + "&id=" + item.id
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getPopupDataListSuccess,
      this.getPopupDataListFailure
    );
  }

  getPopupDataListSuccess = response => {
    if (response && response.status == "success") {
      this.setState({
        popUpHeaderData: response.header_details,
        outBoundPopupList: response.line_items
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  getPopupDataListFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  updateStatus = () => {
    var service_url = ""
    var data = {
      "exchange_summary_id" : this.state.selectedID
    }
    service_url = Constants.STOCK_TRANSFER_INBOUND
    OpthamologyService.getInstance().putMethod(
      service_url,
      data,
      this,
      this.updateStatusSuccess,
      this.updateStatusFailure
    );
  }
  updateStatusSuccess = response => {
    if (response && response.status == "success") {
      this.props.showResposeValue("success", response.message)
      this.setState({
        isUpdateClick: false
      }, () => {
        this.getInboundList()
      })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  updateStatusFailure = (error) => {
    this.props.showResposeValue("error", error.message)
  }
  paginationChangedValues(data) {
    this.setState({
      stockTransferAfterPagination: data
    })
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
      current_page: current_page,
      records_per_page: records_per_page
    })
  }
  renderPopupHeader = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.05, "0.8vw")}
        {this.getBillTableTitle('Brand', 0.15, "0.8vw")}
        {this.getBillTableTitle('Generic / Combo', 0.15, "0.8vw")}
        {this.getBillTableTitle('Dosage Type / Strength', 0.15, "0.8vw")}
        {this.getBillTableTitle('Batch No', 0.15, "0.8vw")}
        {this.getBillTableTitle('Exp On', 0.13, "0.8vw")}
        {this.getBillTableTitle('Request Quantity', 0.12, "0.8vw")}
        {this.getBillTableTitle('Total', 0.1, "0.8vw")}

        {/* </View> */}
      </View>
    )
  }
  renderTableData = () => {
    var { current_page, records_per_page, outBoundPopupList } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ height: "20vw" }}>
        { outBoundPopupList && outBoundPopupList.length > 0 ?
          outBoundPopupList && outBoundPopupList.map((item, index) => {
            var stock_detail = JSON.parse(item.stock_details) && JSON.parse(item.stock_details).length > 0 ? JSON.parse(item.stock_details) : []
            var total = 0
            var batch_no = []
            var exp_on = []
            var drug_amount = 0
            if(stock_detail && stock_detail.length > 0) {
             for ( let i = 0; i < stock_detail.length > 0 ; i++ ){
              batch_no.push(stock_detail[i].batch_no)
              exp_on.push(stock_detail[i].expiry_date)
              drug_amount = stock_detail[i].quantity * stock_detail[i].mrp_per_quantity
              total = total + drug_amount 

             }
            } 
            let sno = (current_page - 1) * records_per_page + index + 1;
            return (
              <View style={[styles.dataContainer, { marginLeft: "0.5vw" }]}>
                <View style={{ flex: 0.05 }}><Text style={styles.TableDataText}>{sno}</Text></View>
                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.drug_name}</Text></View>
                <View style={{ flex: 0.15 }}>
                  <Tooltip placement="topLeft" title={item.generic_name ? item.generic_name : ""}>
                    <Text style={styles.TableDataText}>{item.generic_name.length > 12 ? item.generic_name.slice(0, 12) + ".." : item.generic_name}</Text>
                  </Tooltip>
                </View>
                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.dosage_type + " / " + item.drug_strength}</Text></View>
                <View style={{ flex: 0.15 }}>
                <TextInput
                    value={batch_no}
                    onChangeText={(text) => { 
                      
                    }}
                    style={[styles.textBox, {width: "90%", height: "4vh"}]}
                />
                  {/* <Text style={styles.TableDataText}>{batch_no ? batch_no : "-"}</Text> */}
                </View>
                <View style={{ flex: 0.13 }}>
                <TextInput
                    value={exp_on}
                    onChangeText={(text) => { 
                      
                    }}
                    style={[styles.textBox, {width: "90%", height: "4vh"}]}
                />
                  </View>
                 <View style={{ flex: 0.12 }}>
                  <TextInput
                    value={ outBoundPopupList[index].request_quantity}
                    onChangeText={(text) => { 
                      // outBoundPopupList[index].request_quantity = text
                      // this.setState({
                      //   outBoundPopupList
                      // })
                    }}
                    style={[styles.textBox, {width: "90%", height: "4vh"}]}
                  />
                 </View>   
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.total_amount ? parseFloat(item.total_amount).toFixed(2) : 0}</Text></View>
              </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>}
      </ScrollView>
    )
  }
  renderBottomView = () => {
    return (
      <View style={{ justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "row", marginTop: "0.5vw" }}>
        <TouchableOpacity style={styles.addButton} onPress={() => {
          this.setState({
            isUpdateClick: true
          }, () => this.updateStatus())
        }}>
          <Text style={[styles.addText]}>Receive</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.addButton, { marginLeft: "0.5vw" }]} onPress={() => {
          this.setState({
            isUpdateClick: false
          })
        }}>
          <Text style={styles.addText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    )
  }
  renderHeaderView = () => {
    return (
      // <View style={styles.drugHeaderContainer}>
      <View style={styles.subContainer}>
        {this.getBillTableTitle('S.No', 0.08, "0.95vw")}
        {this.getBillTableTitle('No Of Product', 0.1, "0.95vw")}
        {this.getBillTableTitle('To Branch', 0.12, "0.95vw")}
        {this.getBillTableTitle('Total Value', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST No', 0.1, "0.95vw")}
        {this.getBillTableTitle('ST Date', 0.1, "0.95vw")}
        {this.getBillTableTitle('Tracking Details', 0.13, "0.95vw")}
        {this.getBillTableTitle('Notes', 0.2, "0.95vw")}
        {this.getBillTableTitle('Action', 0.07, "0.95vw")}

      </View>
      // </View>
    )
  }
  renderTableDataView = () => {
    var { current_page, records_per_page } = this.state
    return (
      <ScrollView showsVerticalScrollIndicator={false} style={{ zIndex: -1, height: "68vh" }}>
        {this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.length > 0 ?
          this.state.stockTransferAfterPagination && this.state.stockTransferAfterPagination.map((item, index) => {
            let sno = (current_page - 1) * records_per_page + index + 1;
            let zIndex = (index + 1) * -10;
            let date = item.st_date ? moment(item.st_date).format("DD-MM-YYYY") : ""
            return (
              <View style={[styles.dataContainer, { zIndex: zIndex, marginLeft: "0.5vw" }]}>
                <View style={{ flex: 0.08 }}><Text style={[styles.TableDataText, {  }]}>{sno}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.no_of_product}</Text></View>
                <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.to_branch}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.total_amount}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.st_no}</Text></View>
                <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{date ? date : "-"}</Text></View>
                <View style={{ flex: 0.13, flexWrap: "wrap", justifyContent: "flex-start" }}>
                  <Text style={styles.TableDataText}>{item.corrier_type ? item.corrier_type : "-"}</Text>
                </View>
                <View style={{ flex: 0.2 }}>
                  <Tooltip placement="topLeft" title={item.notes ? item.notes : ""}>
                    <Text style={styles.TableDataText}>{item.notes.length > 15 ? item.notes.slice(0, 15) + ".." : item.notes}</Text>
                  </Tooltip>
                </View>
                <TouchableOpacity style={[styles.addButton, { flex: 0.07 }]} 
                 disabled = {item.notes === "Stock moved out from your branch" ? false : true}
                 onPress={() => {
                  this.setState({
                    isUpdateClick: true,
                    selectedID: item.id
                  }, () => {
                    this.getPopupDataList(item)
                  })
                }}>
                  <View style={{ flexDirection: "row" }}>
                    <Image source={ item.notes === "Stock moved out from your branch" ? ImagePath.InTransmit : ImagePath.Pending} style={styles.PrintIconImg} />
                    <Text style={styles.addText}> {item.notes === "Stock moved out from your branch" ? "Receive" : "Pending" }</Text>
                  </View>
                </TouchableOpacity>

              </View>
            )
          }) :
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
            <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
          </View>
        }

      </ScrollView>
    )
  }
  renderPaginationView = () => {
    return (
      <View>
        {this.state.stockTransferList && this.state.stockTransferList.length > 0 ?
          <Pagination
            paginationChangedValues={this.paginationChangedValues.bind(this)}
            totalItems={this.state.stockTransferList}
            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
          /> : null
        }
      </View>
    )
  }
  renderPopupTextView = (lable, value) => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 0.25, margin: "0.5vw" }}>
        <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{lable }</Text>
        <Text style={{ flex: 0.5, fontSize: "0.85vw" }}>{ ": " + (value ? value : "-")}</Text>
      </View>
    )
  }
  renderHeaderViews = () => {
    var { popUpHeaderData } = this.state
    return (
      <View>
        <View style={{ flexDirection: "row", flex: 1 }}>
          {this.renderPopupTextView("From Branch", popUpHeaderData.from_branch)}
          {this.renderPopupTextView("To Branch", popUpHeaderData.to_branch)}
          {this.renderPopupTextView("Stock Transfer Value", popUpHeaderData.stock_transfer_value)}
          {this.renderPopupTextView("Stock Transfer Date", popUpHeaderData.stock_transfer_date)}
        </View>
        <View style={{ flexDirection: "row" }}>
          {this.renderPopupTextView("Contact Details", popUpHeaderData.from_branch_contact)}
          {this.renderPopupTextView("Contact Details", popUpHeaderData.to_branch_contact)}
          {this.renderPopupTextView("Expected Delivery Date", popUpHeaderData.delivery_date)}
          {this.renderPopupTextView("Stock Transfer No", popUpHeaderData.stock_transfer_no)}
        </View>
      </View>
    )
  }
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"inBound"}
          isNavigationBorder={true}
        />
        <View style={styles.mainContainer}>
          {this.renderHeaderView()}
          {this.renderTableDataView()}
          {this.renderPaginationView()}
        </View>
        {this.state.isUpdateClick ?
          <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "75%", height: "75%", borderRadius: 8, borderWidth: 1, }}>
              <View>
                {this.renderHeaderViews()}
                <View style={{ height: "45vh", }}>
                  {this.renderPopupHeader()}
                  {this.renderTableData()}
                </View>
                {this.renderBottomView()}
              </View>
            </View>
          </View> : null}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: { height: "81vh" },
  drugHeaderContainer: {
    flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center", height: '2vh'
  },
  subContainer: {
    flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: "0.4vw", borderRadius: 4, marginTop: '0.5vw'
  },
  mainContainer: { margin: "0.5vw", zIndex: 0 },
  addText: {
    color: color.white,
    fontSize: "0.8vw",
    marginLeft: "0.4vw"
  },
  addButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.5vw",
    justifyContent: "center",
    alignItems: "center", width: "10%"
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingVertical: "0.6vw",
    justifyContent: 'center',
    flex: 1,
    justifyContent: "flex-start",
    alignContent: "center"
  },
  TableDataText: {
    fontSize: '1vw'
  },
  PrintIconImg: {
    height: '0.87vw',
    width: '0.87vw',
    alignSelf: "center"
  },
  suggestionMain: { padding: "0.5vw", position: "absolute", width: "13vw", marginTop: "3vw", minHeight: "25vh", zIndex: 12, backgroundColor: color.white, borderWidth: 1, borderColor: color.lightGray, marginTop: "2.3vw", borderRadius: "0.3vw" },
  searchBoxText: { position: 'absolute', backgroundColor: color.white, marginLeft: "0.4vw", paddingHorizontal: "0.5vw", color: color.black, fontSize: "0.8vw" },
  textBox: { borderColor: color.lightGray, borderWidth: 1, borderRadius: "0.3vw", padding: "0.4vw" },
})

