import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions, Picker } from 'react-native';
import { NavigationTopHeader } from "../BaseComponent";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import EditIcon from '../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../assets/images/BillConfig/delete.svg';
import { Tooltip } from 'antd';
import OpthamologyService from "../../../network/OpthamologyService";
import AddIcon from '../../../../assets/images/DischargeSummary/Add.svg';
import CancelIcon from '../../../../assets/images/DischargeSummary/Cancel.svg';

let defaultMedInOp = {
    "route": "",
    "medicine_name": "",
    "dosage": "",
    "timings": "",
    "duration": "",
    "instructions": ""
  }
export class DischargeSummaryTemplate extends Component {

    constructor(props) {
        super(props);

        const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {} : JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
        const ClinicID = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).profile_id == undefined ? '' : JSON.parse(localStorage.getItem('loggedInData')).profile_id : '';
        this.state = {
            isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic == false ? false : true,
            userType: this.props.userType,
            TemplateList: [],
            TemplateName: "",
            Reason: "",
            Procedure: "",
            Investigation: "",
            OpNotes: "",
            ConditionOnDischage: "",
            AdviceOnDischage: "",
            Instructions: "",
            ErrorText: "",
            isEditClicked: false,
            isDeleteClicked: false,
            selectedTemplate: {},
            clinic_id : ClinicID,

            ProvDiagnosis:"",
            HisOfPresentIllness:"",
            HisOfPastIllness:"",
            ObstetricHistory:"",
            familyHistory:"",
            OnExamination:"",
            LocalExamination:"",
            Surgery:"",
            CourseInHospital:"",
            TreatmentGiven:"",
            Others:"",
            medicationInOp:[{
                "route": "",
                "medicine_name": "",
                "dosage": "",
                "timings": "",
                "duration": "",
                "instructions": ""
              }]
        };
    }

    componentDidMount() {
        this.getDischargeSummaryTemplate()
    }


    getDischargeSummaryTemplate = () => {
        var service_url = Constants.IP_OPT_DISCHARGE_SUMMARY_TEMPLATE_GET + "?is_config=true";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDischargeSummaryTemplateSuccess,
            this.getDischargeSummaryTemplateFailure
        );
    }

    getDischargeSummaryTemplateSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                TemplateList: response.data,
            })
        }
    }

    getDischargeSummaryTemplateFailure = (response) => {
        console.log("getDischargeSummaryTemplateFailure", response)
    }


    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    SuccessalertValue = (msg) => {
        this.props.showResposeValue("success", msg)
    }
    ErroralertValue = (msg) => {
        this.props.showResposeValue("error", msg)
    }

    handleChange = activeKey => {
        this.setState({ activeKey });
    };

    renderTextInputBox(placehold, key) {
        var states = this.state
        return (
            <View>
                <View style={{ marginTop: '1.3vw', marginLeft: '3vw', width: '70vw' }}>
                    <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', marginTop: '-0.5vw', color: key == color.black, fontSize: '0.8vw' }}>{placehold}</Text>
                    <TextInput
                        value={states[key]}
                        onChangeText={(text) => {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }}
                        maxLength={key == "TemplateName" ? 250 : (key == "procedure" || key == "surgery" || key == "courseInHospital") ? 3000 : (key == "OpNotes" && this.state.clinic_id == 1) ? 6000 : 1000}
                        multiline={key == "TemplateName" ? false : true}
                        style={[styles.TextInputStyles, { height: (key == "TemplateName") ? "1.5vw" : '4vw', padding: "1vw" }]}
                    />
                </View>

            </View>

        );
    }

    PostApiCall = (data)=>{
        var service_url = Constants.IP_OPT_DISCHARGE_SUMMARY_TEMPLATE_GET;
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.AddTemplateSuccess,
                this.AddTemplateFailure
            );
    }

    AddTemplates = () => {
        var checkTestField = this.checkAllDetailsAreAvailable(this.state.medicationInOp)
        let SameTemplateName = []
        SameTemplateName = this.state.TemplateList.filter(list => list['template_name'].toLowerCase() === this.state.TemplateName.toLowerCase());
        if (
            this.state.TemplateName != "" &&
            this.state.Reason != "" &&
            this.state.AdviceOnDischage != "" &&
            (SameTemplateName.length === 0 || (SameTemplateName.length == 1 && SameTemplateName[0].template_id == this.state.selectedTemplate.template_id)) &&
            ( (this.state.isOPT && checkTestField) || (!this.state.isOPT && (checkTestField || (JSON.stringify(this.state.medicationInOp) === JSON.stringify([defaultMedInOp]))))))
         {
            this.setState({
                ErrorText: ""
            })
            if (this.state.isOPT) {
                var data = {
                    'template_id': this.state.isEditClicked ? this.state.selectedTemplate.template_id : "",
                    'reason_for_admission': this.state.Reason,
                    'pro_diagnosis': this.state.ProvDiagnosis,
                    'procedures': this.state.Procedure,
                    'investigations': this.state.Investigation,
                    'operation_notes': this.state.OpNotes,
                    'condition_on_discharge': this.state.ConditionOnDischage,
                    'advice_on_discharge': this.state.AdviceOnDischage,
                    'medication': JSON.stringify(this.state.medicationInOp) === JSON.stringify([defaultMedInOp]) ? [] : this.state.medicationInOp,
                    'instructions': this.state.Instructions,
                    'template_name': this.state.TemplateName
                }
            }
            else {
                if (this.state.clinic_id != 121) {
                    var data = {
                        'template_id': this.state.isEditClicked ? this.state.selectedTemplate.template_id : "",
                        'reason_for_admission': this.state.Reason,
                        'pro_diagnosis': this.state.ProvDiagnosis,
                        'procedures': this.state.Procedure,
                        'history_of_presenting_illness': this.state.HisOfPresentIllness,
                        'on_examination': this.state.OnExamination,
                        'investigations': this.state.Investigation,
                        'treatment_given': this.state.TreatmentGiven,
                        'operation_notes': this.state.OpNotes,
                        'course_in_hospital': this.state.CourseInHospital,
                        'condition_on_discharge': this.state.ConditionOnDischage,
                        'advice_on_discharge': this.state.AdviceOnDischage,
                        'medication': JSON.stringify(this.state.medicationInOp) === JSON.stringify([defaultMedInOp]) ? [] : this.state.medicationInOp,
                        'template_name': this.state.TemplateName
                    }
                }
                else {
                    var data = {
                        'template_id': this.state.isEditClicked ? this.state.selectedTemplate.template_id : "",
                        'reason_for_admission': this.state.Reason,
                        'operation_notes': this.state.OpNotes,
                        'procedures': this.state.Procedure,
                        'investigations': this.state.Investigation,
                        'condition_on_discharge': this.state.ConditionOnDischage,
                        'advice_on_discharge': this.state.AdviceOnDischage,
                        'template_name': this.state.TemplateName,
                        'pro_diagnosis': this.state.ProvDiagnosis,
                        'history_of_presenting_illness': this.state.HisOfPresentIllness,
                        'past_history': this.state.HisOfPastIllness,
                        'obstetric_history': this.state.ObstetricHistory,
                        'family_history': this.state.familyHistory,
                        'on_examination': this.state.OnExamination,
                        'examination': this.state.LocalExamination,
                        'surgery': this.state.Surgery,
                        'course_in_hospital': this.state.CourseInHospital,
                        'treatment_given': this.state.TreatmentGiven,
                        'others': this.state.Others,
                        'medication': JSON.stringify(this.state.medicationInOp) === JSON.stringify([defaultMedInOp]) ? [] : this.state.medicationInOp,
                    }
                }
            }
            
             this.PostApiCall(data)
        }
        else {
            if (this.state.TemplateName == "") {
                this.setState({
                    ErrorText: "Enter Template Name"
                })
            }
            else if (this.state.Reason == "") {
                this.setState({
                    ErrorText: "Enter Reason For Admission"
                })
            }
            else if (this.state.AdviceOnDischage == "") {
                this.setState({
                    ErrorText: "Enter Advice On Discharge"
                })
            }
            else if (SameTemplateName.length > 0 && (SameTemplateName.length == 1 && SameTemplateName[0].template_id != this.state.selectedTemplate.template_id)) {
                this.setState({
                    ErrorText: "Template Name Already Exists"
                })
            }
            else if (!this.state.isOPT && !checkTestField && (JSON.stringify(this.state.medicationInOp) != JSON.stringify([defaultMedInOp]))) {
                this.setState({
                    ErrorText:  (this.state.clinic_id != 121) ? "Enter All Prescriction Values" : "Enter All Discharge Medication Values"
                })
            }
            else if (this.state.isOPT && !checkTestField ) {
                this.setState({
                    ErrorText: "Enter Medication Values"
                })
            }
            setTimeout(() => {
                this.setState({
                    ErrorText: ""
                })
            }, 3000);
        }

    }

    AddTemplateSuccess = (response) => {
        if (response.status == "success") {
            this.props.showResposeValue("success", response.message)
            this.resetClicked()
            this.getDischargeSummaryTemplate()
        }
        else {
            this.props.showResposeValue("error", response.message)
        }

    }
    AddTemplateFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }

    resetClicked = () => {
        this.setState({
            TemplateName: "",
            Reason: "",
            Procedure: "",
            Investigation: "",
            OpNotes: "",
            ConditionOnDischage: "",
            AdviceOnDischage: "",
            // Medication: "",
            Instructions: "",
            ErrorText: "",
            isEditClicked: false,
            isDeleteClicked: false,
            selectedTemplate: {},
            ProvDiagnosis:"",
            HisOfPresentIllness:"",
            HisOfPastIllness:"",
            ObstetricHistory:"",
            familyHistory:"",
            OnExamination:"",
            LocalExamination:"",
            Surgery:"",
            CourseInHospital:"",
            TreatmentGiven:"",
            Others:"",
            medicationInOp : [{
                "route": "",
                "medicine_name": "",
                "dosage": "",
                "timings": "",
                "duration": "",
                "instructions": ""
              }]

              
        })
    }

    SetEditData = (item) => {
        let defaultMedInOp = {
            "route": "",
            "medicine_name": "",
            "dosage": "",
            "timings": "",
            "duration": "",
            "instructions": ""
          }
        try{
            this.setState({
                TemplateName: item.template_name,
                Reason: item.reason_for_admission,
                Procedure: item.procedures,
                Investigation: item.investigations,
                OpNotes: item.operation_notes,
                ConditionOnDischage: item.condition_on_discharge,
                AdviceOnDischage: item.advice_on_discharge,
                medicationInOp: item.medication == null || item.medication == "" ? [defaultMedInOp] : JSON.parse(item.medication),
                ProvDiagnosis: item.pro_diagnosis,
                HisOfPresentIllness:item.history_of_presenting_illness,
                HisOfPastIllness:item.past_history,
                ObstetricHistory:item.obstetric_history,
                familyHistory:item.family_history,
                OnExamination:item.on_examination,
                LocalExamination:item.examination,
                Surgery:item.surgery,
                CourseInHospital:item.course_in_hospital,
                TreatmentGiven:item.treatment_given,
                Others:item.others,
                Instructions:item.instructions
            })
        }
        catch(e){
            this.props.showResposeValue("error", "Something went wrong !")
        }
    }



    renderTemplateList = () => {
        return (
            <View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                <View style={{ alignItems: "left", width: '75%' }} >
                                    <Text style={styles.TableHeadText}>Template Name</Text>
                                </View>
                                <View style={{ alignItems: "left", width: '25%' }} >
                                    <Text style={[styles.TableHeadText, { paddingLeft: '1.2vw' }]}>Actions</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={true} style={styles.MainTable}>
                        {this.state.TemplateList && this.state.TemplateList.length > 0 ? this.state.TemplateList.map((item, index) => {
                            return (
                                <View key={index} style={[styles.TableDataWrapper]} >
                                    <View style={{ width: "75%", alignItems: 'left' }}>
                                        {item.template_name.length > 45 ?
                                            <Tooltip placement="top" title={item.template_name}>
                                                <Text style={[styles.TableDataText]}> {item.template_name.slice(0, 45) + ".."}</Text>
                                            </Tooltip> :
                                            <Text style={[styles.TableDataText]}> {item.template_name}</Text>}
                                    </View>
                                    <View style={{ flexDirection: "row", width: '25%' }}>
                                        <Tooltip placement="top" title={"Edit Template"}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setState({ isEditClicked: true }, () => { this.getEditData(item) })
                                                }}
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    width: "fit-content"
                                                }}
                                            >
                                                <Image
                                                    source={EditIcon}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity></Tooltip>
                                        <Tooltip placement="top" title={"Delete Template"}>
                                            <TouchableOpacity
                                                onPress={
                                                    () => {
                                                        this.setState({
                                                            isDeleteClicked: true,
                                                            selectedTemplate: item
                                                        })
                                                    }
                                                }
                                                style={{
                                                    boxShadow: '0px 3px 6px #00000049',
                                                    opacity: 1,
                                                    borderRadius: "50vw",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: color.white,
                                                    marginLeft: "2vw",
                                                    width: "fit-content"
                                                }}>
                                                <Image
                                                    source={DeleteIcon}
                                                    style={{ height: "1.62vw", width: "1.62vw" }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity></Tooltip>
                                    </View>
                                </View>
                            )
                        }) :
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NoRecordsText}>No Records to show...</Text>
                            </View>}
                    </ScrollView>
                </View>

            </View>
        )
    }

    DeleteTemplate = (id) => {
        var serviceUrl = Constants.IP_OPT_DISCHARGE_SUMMARY_TEMPLATE_GET + "?template_id=" + id;
        var data = {}
        OpthamologyService.getInstance().deleteComplaints(
            serviceUrl,
            data,
            this,
            this.deleteCorporateSuccess,
            this.deleteCorporateFailure
        )
    }

    deleteCorporateSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                isDeleteClicked: false,
                selectedTemplate: {},
                TemplateName: "",
                Reason: "",
                Procedure: "",
                Investigation: "",
                OpNotes: "",
                ConditionOnDischage: "",
                AdviceOnDischage: "",
            }, () => { this.getDischargeSummaryTemplate(), this.props.showResposeValue("success", response.message) })
        }
        else {
            this.props.ErrorAlert(response.message)
        }

    }
    deleteCorporateFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }

    renderDeletePopup = () => {
        return (
            <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
                <View style={{ paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "25vw", height: '11vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, }}>
                    <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '3.25vw', textAlign: 'center' }}>{"Are you sure to delete the Template "}<Text style={{ color: 'red' }}>{` ${this.state.selectedTemplate.template_name} `}</Text>?</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                        <TouchableOpacity
                            style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.red, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                            onPress={() => {
                                this.DeleteTemplate(this.state.selectedTemplate.template_id)
                            }}
                        >
                            <Text style={{ color: 'white', fontSize: '0.78vw',fontWeight:'500' }}>{"Yes"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.white,borderColor:color.red,borderWidth:1, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                            onPress={() => {
                                this.setState({
                                    isDeleteClicked: false,
                                    selectedTemplate: {}
                                })
                            }}
                        >
                            <Text style={{ color:color.red, fontSize: '0.78vw',fontWeight:'500' }}>{"No"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    checkAllDetailsAreAvailable = (newEntry) => {
        var defaultReturn = true
        for (const data of newEntry) {
          if (data.route && data.medicine_name && data.dosage && data.timings) {
            // Do Nothing
          } else {
            defaultReturn = false
            return defaultReturn
          }
        }
        return defaultReturn
      }

    onPressNewMedInOP = () => {
        let { medicationInOp } = this.state;
        let defaultMedInOp = {
          "route": "",
          "medicine_name": "",
          "dosage": "",
          "timings": "",
          "duration": "",
          "instructions": ""
        }
        if (medicationInOp.length > 0) {
          var checkTestField = this.checkAllDetailsAreAvailable(medicationInOp)
          if (checkTestField) {
            medicationInOp.push(defaultMedInOp)
          } else {
            this.setState({ErrorText : "Enter All Medication values * "})
          }
    
        } else {
          medicationInOp.push(defaultMedInOp)
        }
        this.setState({ medicationInOp })
      }

      onPressClearNewMedInOp = (index) => {
        var showList = this.state.medicationInOp
        showList.splice(index, 1)
        this.setState({
          showList
        })
      }
      renderTextBoxwithIndex = (placehold, key, displayFlag, index) => {
        var states = this.state.medicationInOp
        return (
          <View style={{ display: displayFlag ? "flex" : "none" }}>
            <View style={{ marginTop: '1.3vw', marginLeft: '0.32vw' }}>
              <Text style={{ position: 'absolute', backgroundColor: color.white, marginLeft: '0.26vw', paddingHorizontal: '0.32vw', color: color.black, fontSize: '0.8vw' }}>{placehold}</Text>
              <TextInput
                maxLength={750}
                placeholder={""}
                value={states[index][key]}
                onChangeText={(text) => {
                  states[index][key] = text;
                  this.setState({ states })
                }}
                multiline={true}
                style={[{ fontSize: '0.9vw', paddingLeft: '0.65vw', borderColor: "#CDD1D5", width: '10vw', height: '4vw', borderWidth: 1, marginTop: '0.52vw', borderRadius: '0.26vw', paddingVertical: '0.65vw' }]}
              />
            </View>
    
          </View>
    
        );
      }


    renderMedicationFields = (label) => {
        var {  medicationInOp } = this.state
        try{
        return (
            <View>
                <View style={{ flexDirection: "row" ,}}>
                  <Text style={{ fontSize: '0.91vw', marginTop: '0.97vw', marginLeft: '3vw' }}>{label}</Text>
                  <TouchableOpacity
                    style={{ marginLeft: '0.65vw', marginTop: '0.65vw' }}
                    onPress={() => {
                      this.onPressNewMedInOP()
                    }}
                  >
                    <Tooltip title="Add Medication List">
                    <Image
                      source={AddIcon}
                      style={{ height: '1.62vw', width: '1.62vw' }}
                      resizeMode="contain"
                    /></Tooltip>

                  </TouchableOpacity>
                </View>
                {medicationInOp.length > 0 && medicationInOp.map((newEntry, index) => (
                  <View style={styles.commonStyle}>
                    <View>{this.renderTextBoxwithIndex("Route *", "route",  true , index)}</View>
                    <View>{this.renderTextBoxwithIndex("Medicine Name *", "medicine_name",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Dosage *", "dosage",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Timings *", "timings",  true, index)}</View>
                    <View>{this.renderTextBoxwithIndex("Instructions", "instructions",  true, index)}</View>
                    {index == 0 ?
                      <View style={{ width: '1.62vw' }}></View>
                      :
                      <TouchableOpacity
                        onPress={() => this.onPressClearNewMedInOp(index)}
                        style={{ marginTop: '1.3vw', alignSelf: "center" }}>
                        <Image
                          source={CancelIcon}
                          style={{ height: '1.62vw', width: '1.62vw' }}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    }
                  </View>
                ))}
            </View>
        )}
        catch(e){
            this.props.showResposeValue("error", "Error In Medication")
        }
    }


    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.state.userType === Constants.ROLE_FO || this.state.isOPT ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors" },
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                    ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors" },
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                    ]}
                    selectedTab={"DisSumTemplate"}
                    isNavigationBorder={true} />

                <View style={{ flex: 1, flexDirection: "row" }}>
                    <View style={styles.labRefDoc_LeftSide}>
                        {this.renderTemplateList()}
                    </View>
                    <View style={styles.labRefDoc_RightSide} >
                        <ScrollView style={styles.RightScrollView} showsVerticalScrollIndicator={false}>
                            <View style={[styles.HightLightView, { marginTop: "0.8vw", marginBottom: 0 }]}>
                                <Text style={styles.HighLightText}>Template Name</Text>
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Template Name *", "TemplateName")}
                            </View>
                            <View style={styles.HightLightView}>
                                <Text style={styles.HighLightText}>Discharge Summary Details</Text>
                            </View>
                            {this.state.clinic_id !=121 ?  // Condition for Shanthi Hospital (clinic id = 121)
                                <ScrollView style={styles.SummaryDetailsInputView}>
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Reason For Admission *", "Reason")}
                                    </View>
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Provisional Diagnosis", "ProvDiagnosis")}
                                    </View>
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Procedure", "Procedure")}
                                    </View>
                                    {!this.state.isOPT ?
                                        <View style={styles.InputView}>
                                            {this.renderTextInputBox("History Of Present Illness", "HisOfPresentIllness")}
                                        </View>
                                        : null}
                                    {!this.state.isOPT ?
                                        <View style={styles.InputView}>
                                            {this.renderTextInputBox("On Examination", "OnExamination")}
                                        </View>
                                        : null}
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Investigation", "Investigation")}
                                    </View>
                                    {!this.state.isOPT ?
                                        <View style={styles.InputView}>
                                            {this.renderTextInputBox("Treatment Given", "TreatmentGiven")}
                                        </View>
                                        : null}
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Operation Notes", "OpNotes")}
                                    </View>
                                    {!this.state.isOPT ?
                                        <View style={styles.InputView}>
                                            {this.renderTextInputBox("Course In Hospital", "CourseInHospital")}
                                        </View>
                                        : null}
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Conditions On Discharge", "ConditionOnDischage")}
                                    </View>
                                    <View style={styles.InputView}>
                                        {this.renderTextInputBox("Advise On Discharge *", "AdviceOnDischage")}
                                    </View>

                                    {
                                        this.renderMedicationFields(this.state.isOPT ? "Medication *" : "Prescription")
                                    }

                                    {this.state.isOPT ?
                                        <View style={styles.InputView}>
                                            {this.renderTextInputBox("Instructions", "Instructions")}
                                        </View>
                                        : null}

                                </ScrollView>

                             :   //    only for Shanthi Hospital (clinic id = 121)


                            <ScrollView style={styles.SummaryDetailsInputView}> 
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Reason For Admission *", "Reason")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Provisional Diagnosis", "ProvDiagnosis")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("History Of Present Illness", "HisOfPresentIllness")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("History Of Past Illness", "HisOfPastIllness")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Obstetric History", "ObstetricHistory")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Family History", "familyHistory")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("On Examination", "OnExamination")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Local Examination", "LocalExamination")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Investigation", "Investigation")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Surgery", "Surgery")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Procedure", "Procedure")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Course In Hospital", "CourseInHospital")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Condition On Discharge", "ConditionOnDischage")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Treatment Given", "TreatmentGiven")}
                            </View>
                            {this.renderMedicationFields("Discharge Medication")}
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Advise On Discharge *", "AdviceOnDischage")}
                            </View> 
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Others", "Others")}
                            </View>
                            <View style={styles.InputView}>
                                {this.renderTextInputBox("Remarks", "OpNotes")}
                            </View>
                        </ScrollView> }
                        </ScrollView>
                        <View>
                            <View style={{ height: '1.5vw', marginTop: '0.3vw' }}>
                                <Text style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '.9vw', fontWeight: '500', justifyContent: 'center' }}>
                                    {this.state.ErrorText}
                                </Text>
                            </View>
                            <View>
                                <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'center' }}>
                                    <TouchableOpacity
                                        onPress={() => { this.AddTemplates() }}
                                        style={styles.BtnTouch}>
                                        <Text style={styles.ButtonText}>{this.state.isEditClicked ? "Update Template" : "Add Template"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => { this.resetClicked() }}
                                        style={[styles.BtnTouch, { marginLeft: "2vw", backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1 }]}>
                                        <Text style={[styles.ButtonText, { color: color.themeDark }]}>{"Clear"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                    {this.state.isDeleteClicked ? this.renderDeletePopup() : null}
                </View>
            </View>

        )
    }
}

export default DischargeSummaryTemplate

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: '1vw',
        color: "black",
        fontWeight: '500'
    },
    docListFontStyle: {
        fontSize: '1vw',
        color: "black"
    },
    labRefDoc_LeftSide: {
        width: '30%',
        height: '38vw',
        paddingVertical: '0.8vw',
        borderRadius: 4,
        margin: '0.5vw',
        shadowOffset: { width: 0, height: 1 },
        shadowColor: color.grayShade,
        backgroundColor: color.themeShade,
        shadowOpacity: 1,
        shadowRadius: 2
    },
    labRefDoc_RightSide: {
        width: '67%',
        height: '38vw',
        borderRadius: 4,
        shadowColor: color.grayShade,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 2,
        margin: '0.5vw'
    },
    commonStyles: {
        width: "13vw"
      },
      commonStyle: {
        flexDirection: "row",
        flex: 1,
        marginBottom: '1.3vw',
        justifyContent: "space-between",
        width: "95%",
        marginLeft:'2.5vw'
      },
    SummaryDetailsInputView: {
        height: '22vw'
    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        zIndex: -2
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    MainTable: {
        height: '34vw'
    },
    RightHeadTitle: {
        marginLeft: '1vw',
        marginTop: '0.3vw',
        fontSize: '1vw',
        fontWeight: 'bold',
        color: color.themeDark
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "80%",
        height: "2.5vw",
        fontSize: ".9vw",
        paddingLeft: '1vw'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '3vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '30vw'
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    ExportBtnTouch: {
        paddingTop: '0.3vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.3vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '5vw',
        height: '2vw',
        marginLeft: '2vw'
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw",
        fontWeight: '500'
    },
    MandatoryText: {
        color: 'red',
        fontWeight: '500',
        marginLeft: '0.2vw',
        fontSize: '1vw'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.lightGray,
        borderRadius: '0.26vw',
        width: "25%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    TabView: {
        marginLeft: '2vw'
    },
    SearchBoxView: {
        margin: '0.5vw', marginLeft: '2vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '2vw', alignItems: 'center'
    },
    commonStyle: {
        flexDirection: "row",
        flex: 1,
        marginBottom: '1.3vw',
        justifyContent: "space-between",
        width: "95%",
        marginLeft: '2.5vw'
    },

})
