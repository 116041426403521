//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TextInput,TouchableOpacity } from 'react-native';
import Style from "../../../../styles/Style";
import { color } from '../../../../styles/Color';
const screenHeight = Dimensions.get("window").height;
import { CommonButton, EditiAndDeleteForLineItem, CommonHistorySectionHeader, NavigationTopHeader } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
// import { TouchableOpacity } from 'react-native-gesture-handler';
import  CommonDateFilter  from '../../BaseComponent';
import AsyncStorage from '../../../../AsyncStorage';

// create a component

// const navigationHeaderList = [
//     { label: "Result", value: "laboratoryResult" },
//     { label: "Previous Reports ", value: "laboratoryPreviousResult" },
//     { label: "Create Bill", value: "laboratoryCreateBill" },
//     { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
// ]

export class LaboratoryPreviousResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testName: "",
            previousResult: [],
            fromDate: "",
            toDate: "",
            filterDate: {},
            selectedLabPatientDetails:this.props.selectedLabPatientDetails,
            selectedIpPatientDetails: this.props.selectedIpPatientDetails,
            isAdminAccess: false
        }
    }



   async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        isAdminAccess: loggedInDataObj.is_admin_access
      })
        this.getPreviousDetails()
    }

    getPreviousDetails = async () => {
        let states = this.state;
        let patient_id = Object.keys(states.selectedLabPatientDetails).length > 0 ? states.selectedLabPatientDetails.patient_id : states.selectedIpPatientDetails.patient_id
        var serviceUrl = Constants.GET_PREVIOUS_RESULT_DATA + "?from_date=" + states.fromDate +
            "&to_date=" + states.toDate +
            "&patient_id="+ patient_id;
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getPreviousDetailsSuccess,
            this.getPreviousDetailsFailure
        );
    }

    getPreviousDetailsSuccess = success => {

        if (success.status == "success") {

            this.setState({
                previousResult: success.data
            })
        }
    }
    getPreviousDetailsFailure = error => {
    }


    renderResultList() {
        if (this.state.previousResult.length > 0) {
            return (
                this.state.previousResult && this.state.previousResult.map((item, index) => {
                    return (
                        <View style={{ zIndex: -1, paddingVertical: 10, borderBottomColor: "#888888", borderBottomWidth: 1, flexDirection: 'row', alignItems: 'center', }}>
                            <View style={{ flex: 0.8, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{item.invoice_date}</Text></View>
                            <View style={{ flex: 0.2 }}>
                                <TouchableOpacity
                                   onPress={() => this.printPreviousListToken(item)}
                                    >
                                    <Text style={[styles.headerFontStyle, { color: 'blue' }]}>{"View"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                })
            )
        }
        else {
            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                <Text style={{ color: color.appointmentTableHead, marginTop: 50 }}>{"No records to be shown"}</Text>
            </View>

        }

    }

    renderResultHeader() {
        return (
            <View style={{ zIndex: -1, marginTop: 5, marginBottom: 5, backgroundColor: color.appointmentTableHead, height: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, justifyContent: "space-around" }}>
                <View style={{ flex: 0.8, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Date"}</Text></View>
                <View style={{ flex: 0.2, }}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
            </View>
        )
    }

    renderRightSideHeader() {
        return (
            <Text style={{ marginBottom: 20, fontSize: 17, fontWeight: 'bold', color: color.themeDark }}>
                {"Filter"}
            </Text>
        )
    }

    showSelectedFilteredDate = (date) => {
        var states = this.state;
        states["filterDate"] = date
        states["fromDate"] = date.fromDate;
        states["toDate"] = date.toDate;
        this.setState({ states })

    }

    onPressClear() {
        var states = this.state;
        states["filterDate"] = {}
        states["fromDate"] = "";
        states["toDate"] = "";
        this.setState({ states }, () => {
            this.getPreviousDetails()
        })
    }
    onPressSearch() {
        this.getPreviousDetails()
    }

    renderCommonButton(label, key) {
        return (
            <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={label}
                buttonType={key == "clear" ? "outlineTheme" : "theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={key == "clear" ? this.onPressClear.bind(this) : this.onPressSearch.bind(this)}
                buttonKey={key} />
        )
    }

    changeTabSection(subtab) {
        // this.props.changeScreen(subtab);
        this.props.changeScreen(subtab, {}, "LAB", {}, "", {}, "", {}, "", {},false,true);

    }


    printPreviousListToken = (item) => {
        var service = ""
            service = Constants.LAB_RESULTS +"?patient_id=" + item.patient_id +"&bill_summary_id="+item.id+"&export_type=pdf";
        


        OpthamologyService.getInstance().documentUploadGet(
            service,
            this.printPreviousListTokenSuccess,
            this.printPreviousListTokenFailure,
            "pdf"
        );
    };

    printPreviousListTokenSuccess = response => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
           // console.log(file)
            window.open(fileURL);
        }
    };

    printPreviousListTokenFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };

    render() {
        console.log(this.state.selectedIpPatientDetails,"this.props.selectedLabPatientDetails--->")
        return (
            <View style={{ backgroundColor: color.applicationBackgroundColor}}>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={this.props.labNavigationHeaderList}
                    selectedTab={"laboratoryPreviousResult"}
                />

                <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor ,marginTop:20}}>
                    <View style={{ width: "65%", height: screenHeight - 130, }}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ paddingHorizontal: 15, marginVertical: 15, width: "100%", maxHeight: screenHeight - 65, zIndex: -1 }} bounces={false} bouncesZoom={false} >
                            <View style={{ borderRadius: 7, backgroundColor: color.white, marginHorizontal: 10, marginVertical: 15, paddingHorizontal: 15, paddingVertical: 15 }}>
                                {this.renderResultHeader()}
                                {this.renderResultList()}

                            </View>

                        </ScrollView>
                    </View>

                    <ScrollView showsVerticalScrollIndicator={false}
                        style={{ width: "35%", height: screenHeight - 130, backgroundColor: color.themeShade, }} >
                        <View style={[Style.rightView, { padding: 20, width: "100%" }]}>
                            {this.renderRightSideHeader()}

                            <View style={{ flexDirection: 'row', alignItems: "center" }}>
                                <View style={{ marginRight: 50 }}>
                                    <Text style={{ fontSize: 12, color: "#888888", marginBottom: 7 }}>{"Date"}</Text>
                                    <CommonDateFilter 
                                    // removeObject= {""} 
                                    startDate={this.state.fromDate} 
                                    endDate={this.state.toDate} 
                                    showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
                                    filterDate={this.state.filterDate} />
                                </View>

                            </View>



                            <View style={{ marginTop: 30, flexDirection: 'row', alignItems: "center", justifyContent: "center", zIndex: -1 }}>
                                <View style={{ marginRight: 50 }}>
                                    {/* <Text style={{fontSize:12,color:"#888888",marginBottom:5}}>{"Test Name"}</Text>
                        <CommonDateFilter showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}/> */}
                                    {this.renderCommonButton("Search", "search")}
                                </View>
                                {this.renderCommonButton("Clear", "clear")}
                                {/* <View>
                            <Text style={{fontSize:12,color:"#888888",marginBottom:5}}>{"Test Name"}</Text>
                            <TextInput
                            value={this.state.testName}
                            onChangeText={(text)=>{
                                this.setState({
                                    testName:text
                                })
                            }}
                            style={{borderRadius:4,borderWidth:1,paddingLeft:10,width:120,height:30}}
                            />
                        </View> */}
                            </View>
                        </View>
                    </ScrollView>
                </View>
                {/* <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 10 }}>
                    <Image source={require("../../../../../assets/images/HomeScreenImages/Copyright.png")} style={{ height: 15, width: 15, color: color.lightGray, tintColor: color.lightGray }} />
                    <Text style={{ color: color.lightGray }}>2021 eMedHub. All Rights Reserved.</Text>
                </View>  */}
            </View>

        );
    }
}

// define your styles
const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 13,
        color: "black",
        fontWeight: '500'
    },
    patientListFontStyle: {
        fontSize: 11,
        color: "black"
    },
});