import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { color } from '../../../styles/Color';
import { Switch, Tooltip } from 'antd';
import { Constants } from '../../../utils/Constants';
import OpthamologyService from '../../../network/OpthamologyService';
import { NavigationTopHeader } from '../BaseComponent';

const navigationHeader = [
  { label: "User Settings", value: "superAdminSettings" }
]
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTabledata: {},
      isLock: false,
      selectUserid: "",
      selectislock: false,
      selectedIndex: '',
      selectedId: '',
      onetimePassword: ''
    };
  }

  componentDidMount() {
    this.getUserAccessData()
  }

  // get user Details Data API start
  getUserAccessData = () => {
    var serviceurl = Constants.USER_ACCESS_LIST
    OpthamologyService.getInstance().getComplaints(
      serviceurl,
      this,
      this.getUserAccessDataSuccess,
      this.getUserAccessDataFailure
    )
  }

  getUserAccessDataSuccess = (response) => {
    if (response && response.status == "success") {
      this.setState({
        userTabledata: response.data
      })
      console.log(response.data)
    } else {
      this.props.showResposeValue('error', response.message)
    }
  }
  getUserAccessDataFailure = (error) => {
    this.props.showResposeValue('error', response.message)
  }
  // get user Details Data API end

  // update user Details Lock API start
  updateUserDetails = (id, isLock) => {
    var serviceurl = Constants.USER_ACCESS_LIST
    var data = {
      "user_id": id,
      "is_locked": !isLock
    }
    OpthamologyService.getInstance().putMethod(
      serviceurl,
      data,
      this,
      this.updateUserDetailsSuccess,
      this.updateUserDetailsFailure
    )
  }
  updateUserDetailsSuccess = (response) => {
    if (response && response.status == "success") {
      var { userTabledata, selectedIndex } = this.state
      userTabledata[selectedIndex]["is_locked"] = !userTabledata[selectedIndex]["is_locked"]
      this.props.showResposeValue("success", response.message)
      this.setState({
        userTabledata,
        selectedIndex: "",
      })
      this.getUserAccessData()
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  updateUserDetailsFailure = (error) => {
    this.props.showResposeValue("error", error.message)
  }
  // update user Details Lock API end

  // Post user Details Lock API start
  postOnetimepassword = (id) => {
    var serviceurl = Constants.USER_ACCESS_LIST
    var data = {
      "user_id": id
    }
    OpthamologyService.getInstance().postComplaints(
      serviceurl,
      data,
      this,
      this.postOnetimepasswordSuccess,
      this.postOnetimepasswordFailure
    )
  }
  postOnetimepasswordSuccess = (response) => {
    if (response && response.status == "success") {
      this.props.showResposeValue("success", response.mesage)
      this.setState({
        onetimePassword: response.one_time_password
      })
    } else {
      this.props.showResposeValue("error", response.mesage)
    }
  }
  postOnetimepasswordFailure = (error) => {
    this.props.showResposeValue("error", error.mesage)
  }
  // Post user Details Lock API end

  changeTabSection(data) {
    this.setState({
      selectedTab: data
    })
  }

  // Table Header Function start
  settingsTableHeader = () => {
    return (
      <View style={styles.TableHeadContainerView}>
        <View style={{ flex: 0.10 }}>
          <Text style={styles.TableHeadText}>S.No</Text>
        </View>
        <View style={{ flex: 0.11 }}>
          <Text style={styles.TableHeadText}>Role</Text>
        </View>
        <View style={{ flex: 0.20 }}>
          <Text style={styles.TableHeadText}>Mail ID</Text>
        </View>
        <View style={{ flex: 0.17 }}>
          <Text style={styles.TableHeadText}>Mobile Number</Text>
        </View>
        <View style={{ flex: 0.15 }}>
          <Text style={styles.TableHeadText}>Username</Text>
        </View>
        <View style={{ flex: 0.17 }}>
          <Text style={styles.TableHeadText}>Reset Password</Text>
        </View>
        <View style={{ flex: 0.10 }}>
          <Text style={styles.TableHeadText}>UnLock/Lock</Text>
        </View>

      </View>
    )
  }
  // Table Header Function end

  // Lock User Function start
  lockUser = (id, index, islocked) => {
    // var {userTabledata} = this.state
    // if(userTabledata[index].user_id === id){
    //     userTabledata[index]["is_locked"] = !userTabledata[index]["is_locked"]
    // }
    this.setState({
      selectedIndex: index
    }, () => { this.updateUserDetails(id, islocked) })
  }
  // Lock User Function end

  // Table Data Function start
  settingsTableData = () => {
    return (
      <View>
        <ScrollView style={styles.ScrollViewContainer}>
          {this.state.userTabledata && this.state.userTabledata.length > 0 ?
            this.state.userTabledata && this.state.userTabledata.map((item, index) => {
              return (
                <View style={styles.TableDataContentView}>

                  <View style={{ flex: 0.10 }}>
                    <Text style={styles.TableDataContentText}>{index + 1}</Text>
                  </View>
                  <View style={{ flex: 0.11 }}>
                    <Text style={styles.TableDataContentText}>{item.role_name ? item.role_name : null}</Text>
                  </View>
                  <View style={{ flex: 0.20 }}>
                    <Text style={styles.TableDataContentText}>{item.email_id ? item.email_id : null}</Text>
                  </View>
                  <View style={{ flex: 0.17 }}>
                    <Text style={[styles.TableDataContentText]}>{item.mobile_number ? item.mobile_number : null}</Text>
                  </View>
                  <View style={{ flex: 0.15 }}>
                    <Text style={styles.TableDataContentText}>{item.user_name ? item.user_name : null}</Text>
                  </View>
                  <View style={{ flex: 0.17 }}>
                    {this.state.selectedId == item.user_id && this.state.onetimePassword && this.state.onetimePassword && item.is_locked != true ?
                      <View style={styles.PasswordChangedView}><Text style={styles.TableDataContentText}>{this.state.onetimePassword}</Text></View> :
                      
                        <Tooltip title={item.is_locked == true ? 'Please Unlock the User' : "" }>
                      <TouchableOpacity
                      disabled={item.is_locked == true ? true: false}
                        style={styles.ChangePasswordTouch} onPress={() => {
                          this.setState({
                            selectedId: item.user_id
                          })
                          this.postOnetimepassword(item.user_id)
                        }}>
                        <Text style={styles.ChangePasswordText}>Generate Onetime Password</Text>
                      </TouchableOpacity>
                      </Tooltip>
                    }
                  </View>
                  <View style={{ flex: 0.10 }}>
                    <Switch style={{ width: '2vw' }} checked={item.is_locked ? true : false} onChange={() => { this.lockUser(item.user_id, index, item.is_locked) }} />
                  </View>
                </View>
              )
            })
            :
            <View style={styles.NoRecordView}>
              <Text style={styles.NoRecordText}>{"No Records To Be Shown"}</Text>
            </View>
          }
        </ScrollView>
      </View>
    )
  }
  // Table Data Function end
  render() {
    return (
      <View>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"superAdminSettings"}
          isNavigationBorder={true}
        />
        {/* <View style={styles.HeaderTextView}>
          <Text style={styles.HeaderText}> User Settings </Text>
        </View> */}
        <View style={styles.settingsMainView}>
          {this.settingsTableHeader()}
          {this.settingsTableData()}

        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  HeaderTextView: {
    margin: '1vw'
  },
  HeaderText: {
    fontSize: '1.5vw',
    fontWeight: '600',
    color: color.themeDark
  },
  settingsMainView: {
    backgroundColor: color.themeShade,
    height: '80vh',
    width: '91vw',
    margin: '1vw',
    marginTop: '0vw',
    borderRadius: 4,
    shadowColor: "#171717",
    shadowOffset: { width: '0.06vw', height: '0.7vh' },
    shadowOpacity: 0.2,
    shadowRadius: 3
  },
  TableHeadContainerView: {
    width: "89vw",
    backgroundColor: '#E2E2E2',
    height: '4vh',
    flexDirection: 'row',
    borderRadius: 3,
    margin: '1vw',
    alignItems: 'center',
    paddingHorizontal: '1vw'
  },
  TableHeadText: {
    fontSize: "0.91vw",
    color: "black",
    fontWeight: "500",
  },
  TableDataContentView: {
    width: '89vw',
    height: '5vh',
    borderRadius: 3,
    flexDirection: 'row',
    margin: '1vw',
    marginTop: '0vw',
    paddingHorizontal: '1vw',
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
  },
  TableDataContentText: {
    fontSize: "0.91vw",
    color: "black",
  },
  ScrollViewContainer: {
    height: '68vh',
    marginTop: '0vw'
  },
  ChangePasswordTouch: {
    width: '11vw',
    height: '1.6vw',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.themeDark,
    borderRadius: 4,
  },
  ChangePasswordText: {
    fontSize: '0.7vw',
    color: color.white
  },
  NoRecordView: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  NoRecordText: {
    fontSize: '1vw',
    color: '#888888'
  },
  PasswordChangedView: {
    width: '11vw',
    height: '1.6vw',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#888888',
    borderRadius: 4,
  }


})