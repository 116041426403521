import React from "react";
import {
  View,
  Text,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton } from "../BaseComponent";
import Style from "../../../styles/Style";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "isFamily";
var Messages=require('../../../utils/InfoMessages')
export default class FamilyHistory extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedHistory: [],
      selectedMembers: [],
      selectedHospitalise: {},

      favoriteInhouse: [],
      favoriteHistory: [],
      // familyMembers : [
      //   { value: "Father", label: "Father" },
      //   { value: "Mother", label: "Mother" },
      //   { value: "Brother", label: "Brother" },
      //   { value: "Sister", label: "Sister" }
      // ],
      disableButton : false,

      familyMembers: ["Father", "Mother", "Brother", "Sister"],
      complaints: "",
      description: "",
      isInhouseTreatment: false,
      isProcedure: false,
      isFamilyEdit: false,
      newItem: "",
      route: "",
      dosage: "",
      newHistory: "",
      newFamily: "",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      isInHouseID: "",
      isProcedureID: "",
      errorMessageFlag: false,

      getSelectedPropsValue: [],
      unSelectedFamilyHistory: [],
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getFamilyHistory();
  }

  componentWillReceiveProps(props) {
    let { id } = this.state;
   // console.log("FAMILY HISTORY*************" + JSON.stringify(props.editItem));
    if (props.editItem && props.editItem.item) {
      if (props.editItem.title === pageName) {
        let newitem = props.editItem.item;
        if (id !== newitem.id) {
          // console.log(
          //   "FAMILY HISTORY*************" + JSON.stringify(props.editItem)
          // );
          var famHistory = props.editItem.item.disease_names;
          var getFamilyHistory = [];
          for (let i = 0; i < famHistory.length; i++) {
            var prepareData = {
              value: famHistory[i],
              label: famHistory[i],
            };
            getFamilyHistory.push(prepareData);
          }

          this.setState(
            {
              getSelectedPropsValue: famHistory,
              id: props.editItem.item.id,
              selectedHospitalise:{label: newitem.family_member,value: newitem.family_member},
              selectedHistory: getFamilyHistory,
              description: props.editItem.item.description,
              comments: props.editItem.item.comments,
              disableButton: true,

            },
            () => {
              this.props.clearEditedItem("History","")
            }
          );
        }
      }
    }
  }

  getFamilyHistory = () => {
    var service_url = Constants.MEDICAL_HISTORY_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFamilyHistorySuccess,
      this.getFamilyHistoryFailure
    );
  };

  getFamilyHistorySuccess = (response) => {
    if (response.status === "success") {
      //  alert(JSON.stringify(response))
      var field = this.state;
      field["favoriteInhouse"] = response.data;
      field["favoriteHistory"] = response.data;
      this.setState({ field });
    }
  };

  getFamilyHistoryFailure = (error) => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {
    let states = this.state;

    states["isEdit"] = true;

    this.setState({ states });
  };

  addMedicalHistoryList = (event) => {
    let { newHistory, favoriteHistory } = this.state;
    let field = this.state;

    if (newHistory) {
      favoriteHistory.push(newHistory);

      field["favoriteHistory"] = favoriteHistory;
      field["selectedHistory"].push({ value: newHistory, label: newHistory })
      field["newHistory"] = "";
      field["isProcedure"] = false;

      this.setState({ field });
    }
  };

  addFamilyList = (event) => {
    let { newFamily, familyMembers } = this.state;
    let field = this.state;

    if (newFamily) {
      familyMembers.push(newFamily);

      field["familyMembers"] = familyMembers;
      field["selectedHospitalise"] = { label: newFamily, value: newFamily };
      field["newFamily"] = "";
      field["isFamily"] = false;

      this.setState({ field });
    }
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  };

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states,
    });
  };

  triggerNewHistoryadd = () => {
    let states = this.state;

    states["isProcedure"] = true;

    this.setState({ states });
  };

  triggerNewFamilyadd = () => {
    let states = this.state;

    states["isFamilyEdit"] = true;

    this.setState({ states });
  };

  addMedical = (event) => {
    let states = this.state;
    var service_url = Constants.POST_FAMILY_HISTORY;

    var { getSelectedPropsValue } = this.state;

    var unSelectedFamilyHistory = [];
    var getSelectedHistory = [];
    for (var i = 0; i < this.state.selectedHistory.length; i++) {
      getSelectedHistory.push(this.state.selectedHistory[i].value);
      for (let j = 0; j < getSelectedPropsValue.length; j++) {
        if (getSelectedPropsValue[j] !== this.state.selectedHistory[i].value) {
          unSelectedFamilyHistory.push(getSelectedPropsValue[j]);
          unSelectedFamilyHistory = this.removeDuplicate(
            unSelectedFamilyHistory
          );
        }
      }
    }

    let data = {
      id: states.id ? states.id : null,
      patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.patientAppointment.appointment_id,
      family_member: this.state.selectedHospitalise.value,
      deleted_disease_names: unSelectedFamilyHistory,
      disease_names: getSelectedHistory,
      description: this.state.description,
      comments: this.state.comments,
    };

    if (this.state.description || this.state.selectedHospitalise.value ) {
      //alert(JSON.stringify(data))
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    }else{
      this.props.showResposeValue("error", Messages.InvalidFamilyDetails);
    }
  };

  getSymptomSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  getSymptomFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = (event) => {
    let states = this.state;

    (states["comments"] = ""), (states["selectedHistory"] = []);
    states["description"] = "";
    states["selectedHospitalise"] = [];
    states['disableButton'] = false;
    states["id"] = "";
    this.setState({ states });
  };

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  onPressButton(key, selectedvalue) {
    if (key == "selectedHistory") {
      var { selectedHistory } = this.state;
      var getSelectedValue;
      var spliceData;
      if (selectedHistory.length > 0) {
        for (var i = 0; i < selectedHistory.length; i++) {
          if (selectedHistory[i].value == selectedvalue.value) {
            spliceData = selectedHistory[i];
            selectedHistory.splice(i, 1);
            var data = this.removeDuplicate(selectedHistory);
            this.setState({ selectedHistory: data });
            return;
          } else {
            if (spliceData && spliceData.value !== selectedvalue.value) {
            } else {
              getSelectedValue = selectedvalue;
            }
          }
        }

        if (getSelectedValue) {
          selectedHistory.push(selectedvalue);
        }
      } else {
        selectedHistory.push(selectedvalue);
      }

      var data = this.removeDuplicate(selectedHistory);
      this.setState({ selectedHistory: data });
    } else if (key == "selectedHospitalise") {
      this.setState({ selectedHospitalise: selectedvalue });
    }
  }

  render() {
    return (
      <TouchableWithoutFeedback
        onPress={
          (this.addMedicalHistoryList.bind(this), this.addFamilyList.bind(this))
        }
      >
        <View>
          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Select Family Member",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>

          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.familyMembers.map((item, index) => {
              if (item) {
                var data = { label: item, value: item };
                return (
                  <View
                    style={{ marginBottom: 10, marginRight: 10 }}
                    key={index}
                  >
                    <CommonButton
                      disable= {this.state.isLocked || this.state.disableButton}
                      item={data}
                      selectedvalue={this.state.selectedHospitalise}
                      butttonText={data.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={"selectedHospitalise"}
                    />
                  </View>
                );
              }
            })}
          </View>
          {this.state.isFamilyEdit ? (
            <View
              style={[{
                height: 30,
                marginTop: 10,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,
              },Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={{
                  color: color.black,
                  height: 30,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                }}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newFamily}
                onChangeText={(text) => {
                  this.setState({ newFamily: text });
                }}
                onSubmitEditing={this.addFamilyList.bind(this)}
              />
            </View>
          ) : (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewFamilyadd.bind(this),
              this.state.isLocked
            )
          )}
          <View style={{ marginTop: 20 }}>
            {this.renderTitleWithMultipleBtn(
              "Family History",
              false,
              1,
              false,
              "selectedApplanation"
            )}
          </View>

          <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1 }}>
            {this.state.favoriteHistory.map((item, index) => {
              if (item) {
                var data = { label: item, value: item };
                return (
                  <View
                    style={{ marginBottom: 10, marginRight: 10 }}
                    key={index}
                  >
                    <CommonButton
                      disable= {this.state.isLocked}
                      item={data}
                      selectedvalue={this.state.selectedHistory}
                      butttonText={data.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={"selectedHistory"}
                    />
                  </View>
                );
              }
            })}
          </View>
          {this.state.isProcedure ? (
            <View
              style={[{
                height: 30,
                marginTop: 10,
                // backgroundColor: color.white,
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                width: "50%",
                zIndex: 101,
              },Style.allButtonBorderRadius]}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={{
                  color: color.black,
                  height: 30,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  textAlign: "center",
                }}
                ref={(text) => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newHistory}
                onChangeText={(text) => {
                  this.setState({ newHistory: text });
                }}
                onSubmitEditing={this.addMedicalHistoryList.bind(this)}
              />
            </View>
          ) : (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewHistoryadd.bind(this),
              this.state.isLocked
            )
          )}

          <View style={{ marginTop: 20 }}>
            <Text style={{ fontSize: 20, color: color.black }}>
              Description
            </Text>
          </View>

          <View style={{ marginTop: 30 }}>
            {this.renderTextFieldView(
              "Description",
              "description",
              this.state.description,
              true,
              4,
              '',{},'',false,this.state.isLocked
            )}
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={{ fontSize: 20, color: color.black }}>Comments</Text>
          </View>

          <View style={{ marginTop: 30 }}>
            {this.renderTextFieldView(
              "Comments",
              "comments",
              this.state.comments,
              true,
              4,
              '',{},'',false,this.state.isLocked
            )}
          </View>

          <View style={{ marginTop: 20, zIndex: -1 ,alignSelf:"center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addMedical.bind(this)}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
