import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Picker
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonHistorySectionHeader, CommonSectionHeader, DoctorNotesCommonRightSideHeader } from '../BaseComponent'
import { CommonButton } from "../BaseComponent";
import Styles from "../../../styles/Style";
import Style from "../../../styles/Style";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

// import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "Test";

var Messages = require('../../../utils/InfoMessages')

const toastConfig = {
  success: (internalState) => (
    <View style={{ height: 30, width: "100%", backgroundColor: "green" }}>
      <Text
        style={{
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          marginTop: "9px",
        }}
      >
        Complaint Created Successfully
      </Text>
    </View>
  ),
  error: () => { },
  info: () => { },
  any_custom_type: () => { },
};

export default class TestNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedLabTest: {},
      selectedRadiologyTest: {},
      selectedSpecialTest: {},
      laboratoryTests: [],
      radiologyTests: [],
      specialTests: [],
      labSearchList: [],
      radiologySearchList: [],
      specialSearchList: [],
      isLabTestSearchView: false,
      isRadiologyTestSearchView: false,
      isSpecialTestSearchView: false,
      allergyActive: "",
      favoriteDrugs: [],
      radiologyTest: [],
      specialTest: [],
      complaints: "",
      isLabTest: false,
      isRadiologyTest: false,
      isSpecialTest: false,
      newItem: "",
      radiologyNewItem: "",
      specialNewItem: "",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      //lab revamp 03/02/2023
      getTestNameData: [],
      getLaboratoryDetails: [],
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      selectLaboratory: '',
      testName: '',
      testNameData: [],
      selectedTestName: '',
      searchListOfTestName: '',
      getTestGroupData: [],
      testGroup: '',
      testGroupData: [],
      selectedTestGroup: '',
      searchListOfTestGroup: '',
      selectedTestNameID: '',
      selectedTestGroupID: '',
      selectedGroupName: ''
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getLaboratoryData();
  }

  componentWillReceiveProps(props) {
    let { id, favoriteDrugs } = this.state;

    if (props.editItem) {
      if (props.editItem.title === pageName) {
        let item = props.editItem.item;

        if (id !== item.id) {
          let selectedLabTest = "";

          favoriteDrugs.map((favItem) => {
            if (favItem.label === item.symptom) {
              selectedLabTest = favItem.value;
            }
          });
          this.setState({
            id: item.id,
            selectedLabTest: item.symptom,
            selectedDuration: isNaN(parseInt(item.duration))
              ? 0
              : parseInt(item.duration),
            comments: item.symptom_desc,
          });
        }
      }
    }
  }

  getLaboratoryData = () => {
    var clinicID = this.state.selectedClinicOrDoctor
    var serviceUrl = Constants.LAB_DETAILS_GET + "?clinic_id=" + clinicID?.id
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getLaboratoryDataSuccess,
      this.getLaboratoryDataFailure
    )
  }
  getLaboratoryDataSuccess = (response) => {
    if (response.status == "success") {
      var Data = response.data
      this.setState({
        getLaboratoryDetails: Data.laboratory_data,
      }, () => {
        this.setState({
          selectLaboratory: this.state.getLaboratoryDetails[0].laboratory_id
        }, () => {
          this.getTestNameData()
          this.getTestGroupData()
        })
      })
    }
  }
  getLaboratoryDataFailure = (error) => {
    this.props.showResposeValue('error', error.message);
  }
  /* comment for New lab revamp - 02/02/23 -- start */
  // labTestSearch = (query) => {
  //   //this.setState({ newItem: query });

  //   if (query !== "") {
  //     var serviceUrl = Constants.TEST_LAB_TEST_GET + query;

  //     OpthamologyService.getInstance().getComplaints(
  //       serviceUrl,
  //       this,
  //       this.labTestSearchSuccess,
  //       this.labTestSearchFailure
  //     );
  //   } else {
  //     var fields = this.state;
  //     fields["labSearchList"] = [];

  //     this.setState({
  //       fields,
  //       isLabTestSearchView: false,
  //     });
  //   }
  // };

  // labTestSearchSuccess = (response) => {
  //   var fields = this.state;
  //   fields["labSearchList"] = response.data;

  //   this.setState({
  //     fields,
  //   });
  // };

  // labTestSearchFailure = (error) => {
  //   // console.log("search patient error response");
  //   console.log(error);
  // };

  // radiologyTestSearch = (query) => {
  //   //this.setState({ newItem: query });

  //   if (query !== "") {
  //     var serviceUrl = Constants.TEST_RADILOLOGY_TEST_GET + query;

  //     OpthamologyService.getInstance().getComplaints(
  //       serviceUrl,
  //       this,
  //       this.radiologyTestSearchSuccess,
  //       this.radiologyTestSearchFailure
  //     );
  //   } else {
  //     var fields = this.state;
  //     fields["radiologySearchList"] = [];

  //     this.setState({
  //       fields,
  //       isRadiologyTestSearchView: false,
  //     });
  //   }
  // };

  // radiologyTestSearchSuccess = (response) => {
  //   var fields = this.state;
  //   fields["radiologySearchList"] = response.data;
  //   this.setState({
  //     fields,
  //   });
  // };

  // radiologyTestSearchFailure = (error) => {
  //   // console.log("search patient error response");
  //   console.log(error);
  // };
  /* comment for New lab revamp - 02/02/23 -- End */
  specialTestSearch = (query) => {
    //this.setState({ newItem: query });

    if (query !== "") {
      var serviceUrl = Constants.TEST_SPECIAL_TEST_GET + query;

      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.specialTestSearchSuccess,
        this.specialTestSearchFailure
      );
    } else {
      var fields = this.state;
      fields["specialSearchList"] = [];

      this.setState({
        fields,
        isSpecialTestSearchView: false,
      });
    }
  };

  specialTestSearchSuccess = (response) => {
    var fields = this.state;
    fields["specialSearchList"] = response.data;
    this.setState({
      fields,
    });
  };

  specialTestSearchFailure = (error) => {
    // console.log("search patient error response");
    console.log(error);
  };


  getTestNameData = () => {
    var service_url = Constants.LAB_TEST_CONFIG_GET + '?laboratory_id=' + this.state.selectLaboratory;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTestNameDataSuccess,
      this.getTestNameDataFailure
    );
  };

  getTestNameDataSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        getTestNameData: response.data
      }, () => {
        const size = 10
        const frequentEyeTest = this.state.getTestNameData && this.state.getTestNameData.length > 0 && this.state.getTestNameData.slice(0, size)
        this.setState({
          testNameData: frequentEyeTest
        })
      });
    }
  };

  getTestNameDataFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
    this.props.showResposeValue('error', error.message);
  };

  getTestGroupData = () => {
    var service_url = Constants.LAB_TEST_GROUP_GET + '?laboratory_id=' + this.state.selectLaboratory;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTestGroupDataSuccess,
      this.getTestGroupDataFailure
    );
  };

  getTestGroupDataSuccess = (response) => {
    if (response.status === "success") {

      this.setState({ getTestGroupData: response.data }, () => {
        const size = 10
        const frequentEyeTest = this.state.getTestGroupData && this.state.getTestGroupData.length > 0 && this.state.getTestGroupData.slice(0, size)
        this.setState({
          testGroupData: frequentEyeTest
        })
      });
    }
  };
  getTestGroupDataFailure = (error) => {
    this.props.showResposeValue('error', error.message);
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getSpecialTestFrequent = () => {
    // console.log;
    var service_url = Constants.TEST_SPECIAL_FREQUENT_GET;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSpecialTestSuccess,
      this.getLabTestFrequentFailure
    );
  };

  getSpecialTestSuccess = (response) => {
    if (response.status === "success") {
      let drugs = [];

      response.data.map((item) => {
        item ? drugs.push({ value: item, label: item }) : null;
      });

      var field = this.state;
      field["specialTest"] = drugs;
      this.setState({ field });
    }
  };

  triggerNewItemAdd = () => {
    let states = this.state;

    states["isEdit"] = true;

    this.setState({ states });
  };

  addMedicationToList = (event) => {
    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if (newItem) {
      favoriteDrugs.push({ value: newItem, label: newItem });

      var filterfavoriteDrugs = this.removeDuplicates(favoriteDrugs);

      field["favoriteDrugs"] = filterfavoriteDrugs;
      // field["selectedLabTest"] = newItem;
      field["newItem"] = "";
      field["isLabTest"] = false;

      this.setState({ field });
    }
  };

  addRadiologyToList = (event) => {
    let { radiologyNewItem, radiologyTest } = this.state;
    let field = this.state;

    if (radiologyNewItem) {
      radiologyTest.push({ value: radiologyNewItem, label: radiologyNewItem });

      var filterradiologyTest = this.removeDuplicates(radiologyTest);
      field["radiologyTest"] = filterradiologyTest;
      // field["selectedRadiologyTest"] = radiologyNewItem;
      field["radiologyNewItem"] = "";
      field["isRadiologyTest"] = false;

      this.setState({ field });
    }
  };

  addSpecialToList = (event) => {
    let { specialNewItem, specialTest } = this.state;
    let field = this.state;

    if (specialNewItem) {
      specialTest.push({ value: specialNewItem, label: specialNewItem });
      var filterspecialTest = this.removeDuplicates(specialTest);
      field["specialTest"] = filterspecialTest;
      // field["selectedSpecialTest"] = specialNewItem;
      field["specialNewItem"] = "";
      field["isSpecialTest"] = false;

      this.setState({ field });
    }
  };

  removeDuplicates(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);

    return nameListUniqueArray;
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {
    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  };

  onPressAction = (key, value, label) => {
    let states = this.state;
    states[key] = value;
    this.setState({
      states,
    });
  };

  triggerNewLabTestadd = () => {
    let states = this.state;

    states["isLabTest"] = true;
    states["isLabTestSearchView"] = false;

    this.setState({ states });
  };

  triggerNewRadiologyTestadd = () => {
    let states = this.state;

    states["isRadiologyTest"] = true;
    states["isRadiologyTestSearchView"] = false;

    this.setState({ states });
  };

  triggerNewSpecialTestadd = () => {
    let states = this.state;

    states["isSpecialTest"] = true;
    states["isSpecialTestSearchView"] = false;
    this.setState({ states });
  };

  addAllergy = (event) => {
    let states = this.state;
    var service_url = Constants.TEST_LAB_POST;

    let allergy_name = "";

    states.favoriteDrugs.map((item) => {
      if (item.value === states.selectedLabTest) {
        allergy_name = item.label;
      }
    });

    var clinicID = this.state.selectedClinicOrDoctor
    let specialTest = null
    specialTest = states.selectedSpecialTest.value != 'N/A' ? states.selectedSpecialTest.value : null
    let data = {
      patient_id: this.state.patientAppointment.patient_id,
      appointment_id: this.state.patientAppointment.appointment_id,
      clinic_id: clinicID.id,
      lab_set_id: states.selectedTestGroupID ? states.selectedTestGroupID : null,
      lab_set_name: states.selectedTestGroup ? states.selectedTestGroup : '',
      lab_tests_id: states.selectedTestNameID ? states.selectedTestNameID : null,
      lab_test_name: states.selectedTestName ? states.selectedTestName : '',
      lab_grp_name: states.selectedGroupName ? states.selectedGroupName : '',
      special_tests: specialTest,
      special_test_name: states.selectedSpecialTest ? states.selectedSpecialTest.label : '',
    };
    {/*Comment for lab revamp - 10/02/20203 Start */ }
    // if(states.selectedTestName){
    //   data["lab_tests_id"] = states.selectedTestNameID
    //   data["lab_test_name"] = states.selectedTestName
    // }

    // if(states.selectedTestGroup){
    //   data["lab_set_name"] = states.selectedTestGroup
    //   data["lab_set_id"] = states.selectedTestGroupID
    // }

    // if(states.selectedSpecialTest.value != "N/A"){
    //   data["special_tests"] = states.selectedSpecialTest.value
    // }else{
    //   data["special_test_name"] = states.selectedSpecialTest.label
    // }
    {/*Comment for lab revamp - 10/02/20203 End */ }

    if (states.selectedTestName || states.selectedTestGroup || states.selectedSpecialTest) {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getSymptomSuccess,
        this.getSymptomFailure
      );
    } else {
      this.props.showResposeValue("error", Messages.TestEmptyField);
    }
  };

  showSuccessMessage = () => {
    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({
        successOrErrorMessage: false,
        successMessageFlag: false,
      });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);
  };

  getSymptomSuccess = (response) => {
    if (response.status === "success") {
      this.props.showResposeValue("success", response.message);
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
      this.setState({
        selectedTestName: '',
        selectedTestNameID: '',
        selectedTestGroup: '',
        selectedTestGroupID: '',
        selectedGroupName: ''
      })
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  getSymptomFailure = (error) => {
    this.props.showResposeValue("error", error.message);
    // console.log("opthamology complaints GET error response");
    // console.log(error);
  };

  clearComplaint = (event) => {
    let states = this.state;
    states["selectedLabTest"] = "";
    states["selected"] = "";
    // "duration_type": 3,
    states["selectedRadiologyTest"] = "";
    states["selectedSpecialTest"] = "";

    this.setState({ states });
  };

  onPressButton(key, selectedvalue) {
    if (key == "selectedLabTest") {
      this.setState({
        selectedLabTest: selectedvalue,
      });
    } else if (key == "selectedRadiologyTest") {
      this.setState({
        selectedRadiologyTest: selectedvalue,
      });
    } else if (key == "selectedSpecialTest") {
      this.setState({
        selectedSpecialTest: selectedvalue,
      });
    }
  }
  searchDescriptionMapping = (value) => {

    let { searchListOfTestName, getTestNameData } = this.state;
    let tempDescList = getTestNameData
    if (tempDescList.length > 0 && value) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {
        if (tempDescList[i].test_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchListOfTestName = searchListTemp;
    } else {
      searchListOfTestName = this.state.getTestNameData;
    }
    this.setState({
      searchListOfTestName
    })
  }
  renderTestName() {
    return (
      <View style={{ marginVertical: '0.97vw' }}>
        <CommonHistorySectionHeader
          heading1={"Test Name"}
          noOfColumn={1}
          columnSize={[1]}
        />

        <View style={styles.TestNameInputView}>
          <TextInput
            style={styles.TestNameInput}
            value={this.state.testName}
            onChangeText={(text) => {
              this.setState({ testName: text }, () => {
                this.searchDescriptionMapping(this.state.testName)
              })
            }}

          />
        </View>

        {
          this.state.searchListOfTestName && this.state.searchListOfTestName.length > 0 && this.state.testName != "" ?
            <View style={[{
              backgroundColor: this.state.testName ? color.white : ""
            }, styles.TestNameSuggestionView]}>
              <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: '13vw' }}>
                <FlatList
                  data={this.state.searchListOfTestName}
                  renderItem={({ item }) => (
                    <View style={styles.TestNameSuggList}
                    >
                      <View style={{ width: '100%' }}>
                        <TouchableOpacity
                          onPress={() => {
                            var {testNameData} = this.state;
                            var data = { "test_name": item.test_name, "id": item.id }
                            testNameData.push(data)
                            // find duplicate data
                            var testNameJsonObject = testNameData.map(JSON.stringify);
                            var testNameUniqueSet = new Set(testNameJsonObject);
                            var testNameUniqueArray = Array.from(testNameUniqueSet).map(JSON.parse);
                            //remove additinal data
                            if(testNameUniqueArray?.length > 10){
                              testNameUniqueArray.shift()
                            }
                            this.setState({
                              selectedTestName: item.name ? item.name : item.test_name,
                              selectedTestNameID: item.id,
                              searchListOfTestName: [],
                              testNameData: testNameUniqueArray,
                              selectedGroupName: item.grp_name ? item.grp_name : ''
                            })
                          }
                          }>
                          <Text style={styles.TestNameListTxt}>{item.name ? item?.name : item?.grp_name ? (item?.test_name + "- [" + item?.grp_name + "]") : item?.test_name}</Text>
                        </TouchableOpacity>
                      </View>

                    </View>
                  )}
                  enableEmptySections={true}
                />
              </ScrollView>
            </View>
            : null
        }
        <View style={styles.EyeTestBtnMainView}>
          {
            this.state.testNameData && this.state.testNameData.map((item, index) => {
              return (
                <View style={styles.EyeTestBtnView} key={index}>
                  <TouchableOpacity key={index}
                    style={[{
                      backgroundColor: this.state.selectedTestName ===  item?.test_name ? color.themeDark : null,
                      borderColor: this.state.selectedTestName ==  item?.test_name ? color.themeDark : color.textBoxBorderColor
                    }, Style.allButtonBorderRadius, styles.ServiceButtons]}
                    onPress={() => {
                      this.setState({
                        selectedTestName:  item?.test_name,
                        selectedTestNameID: item.id,
                        selectedGroupName: item?.grp_name
                      })
                    }}
                  >
                    <Text style={[{
                      color: this.state.selectedTestName === item?.test_name ? color.white : color.black
                    }, styles.ServiceButtonsText]}>{item.name ? item?.name : item?.grp_name ? (item?.test_name + "- [" + item?.grp_name + "]") : item?.test_name}</Text>
                  </TouchableOpacity>
                </View>
              )
            })
          }
        </View>

      </View>
    )
  }
  searchDescriptionGroup = (value) => {

    let { searchListOfTestGroup, getTestGroupData } = this.state;
    let tempDescList = getTestGroupData
    if (tempDescList.length > 0 && value) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {
        if (tempDescList[i].name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchListOfTestGroup = searchListTemp;
    } else {
      searchListOfTestGroup = this.state.getTestGroupData;
    }
    this.setState({
      searchListOfTestGroup
    })
  }
  renderGroupName() {
    return (
      <View style={{ marginVertical: '0.97vw' }}>
        <CommonHistorySectionHeader
          heading1={"Test Group"}
          noOfColumn={1}
          columnSize={[1]}
        />

        <View style={styles.TestNameInputView}>
          <TextInput
            style={styles.TestNameInput}
            value={this.state.testGroup}
            onChangeText={(text) => {
              this.setState({ testGroup: text }, () => {
                this.searchDescriptionGroup(this.state.testGroup)
              })
            }}

          />
        </View>

        {
          this.state.searchListOfTestGroup && this.state.searchListOfTestGroup.length > 0 && this.state.testGroup != "" ?
            <View style={[{
              backgroundColor: this.state.testGroup ? color.white : ""
            }, styles.TestNameSuggestionView]}>
              <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: '13vw' }}>
                <FlatList
                  data={this.state.searchListOfTestGroup}
                  renderItem={({ item }) => (
                    <View style={styles.TestNameSuggList}
                    >
                      <View style={{ width: '100%' }}>
                        <TouchableOpacity
                          onPress={() => {
                            var {testGroupData} = this.state;
                            var data = { "name": item.name, "id": item.id }
                            testGroupData.push(data)
                            // find duplicate data
                            var testGroupJsonObject = testGroupData.map(JSON.stringify);
                            var testGroupUniqueSet = new Set(testGroupJsonObject);
                            var testGroupUniqueArray = Array.from(testGroupUniqueSet).map(JSON.parse);
                            //remove additinal data
                            if(testGroupUniqueArray?.length > 10){
                              testGroupUniqueArray.shift()
                            }
                            this.setState({
                              selectedTestGroup: item.name,
                              selectedTestGroupID: item.id,
                              searchListOfTestGroup: [],
                              testGroupData: testGroupUniqueArray,
                            })
                          }
                          }>
                          <Text style={styles.TestNameListTxt}>{item.name}</Text>
                        </TouchableOpacity>
                      </View>

                    </View>
                  )}
                  enableEmptySections={true}
                />
              </ScrollView>
            </View>
            : null
        }
        <View style={styles.EyeTestBtnMainView}>
          {
            this.state.testGroupData && this.state.testGroupData.map((item, index) => {
              return (
                <View style={styles.EyeTestBtnView} key={index}>
                  <TouchableOpacity key={index}
                    style={[{
                      backgroundColor: this.state.selectedTestGroup === item.name ? color.themeDark : null,
                      borderColor: this.state.selectedTestGroup == item.name ? color.themeDark : color.textBoxBorderColor
                    }, Style.allButtonBorderRadius, styles.ServiceButtons]}
                    onPress={() => {
                      this.setState({
                        selectedTestGroup: item.name,
                        selectedTestGroupID: item.id,
                      })
                    }}
                  >
                    <Text style={[{
                      color: this.state.selectedTestGroup === item.name ? color.white : color.black
                    }, styles.ServiceButtonsText]}>{item.name}</Text>
                  </TouchableOpacity>
                </View>
              )
            })
          }
        </View>

      </View>
    )
  }
  onPressClear = () => {
    this.setState({
      selectedTestGroup: '',
      selectedTestGroupID: '',
      selectedTestName: '',
      selectedTestNameID: '',
      selectedSpecialTest: '',
      testGroup: '',
      testName: '',
      selectedGroupName: ''
    })
  }

  render() {
    return (
      <TouchableWithoutFeedback onClick={() => {
        this.setState({
          searchListOfTestGroup: [],
          searchListOfTestName: []
        })
      }}>
        <View style={styles.HeaderDropdown}>
          <DoctorNotesCommonRightSideHeader title={"Test"} clearAllData={""} hideClearButton={false} />
          <Picker
            // enabled={!this.state.isLocked}
            selectedValue={this.state.selectLaboratory}
            style={[styles.PickerView, { backgroundColor: color.white }]}
            itemStyle={{}}
            onValueChange={(itemvalue) => {
              // var states = this.state.mandatoryInformation;
              // states["state"] = itemvalue;
              this.setState({ selectLaboratory: itemvalue }, () => {
                this.getTestNameData()
                this.getTestGroupData()
              });

            }}
          >
            <Picker.Item label={"Select"} value={""} />
            {
              this.state.getLaboratoryDetails && this.state.getLaboratoryDetails.length > 0 && this.state.getLaboratoryDetails.map((item, index) => {
                return <Picker.Item key={index} label={item.laboratory__laboratory_name} value={item.laboratory_id} />
              })
            }
          </Picker>
        </View>
        <View style={{ marginVertical: '1vw' }}>
          {this.renderTestName()}
        </View>
        {/* <View style={{}}>
          {this.renderTitleWithMultipleBtn(

            "",
            false,
            1,
            false,
            "selectedApplanation",
            "",
          )}
        </View> */}
        {/* <FlatList
          data={this.state.favoriteDrugs}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedLabTest', this.state.selectedLabTest)}
        //   extraData={this.state.selectedEye}
        /> */}

        {/* <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {
            this.state.favoriteDrugs && this.state.favoriteDrugs.map((item, index) => {
              return this.renderSelectEyes(item, false, false, 'selectedLabTest', this.state.selectedLabTest, index)
            })
          }
        </View> */}
        {/* comments for New lab revamp - 02/02/23 * -- start/}
        {/* <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1, marginLeft: '1.2vw' }}>
          {this.state.laboratoryTests.map((item, index) => {
            var data = { label: item.name, value: item.id };
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={data}
                  selectedvalue={this.state.selectedLabTest}
                  butttonText={data.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedLabTest"}
                />
              </View>
            );
          })}
        </View> */}

        {/* {this.state.isLabTest ? (
          <View
            style={[{
              height: '2vw',
              marginTop: '0.6vw',
              width: "100%",
              zIndex: 101,
            }, Styles.allButtonBorderRadius]}
          >
            <View style={{flexDirection: "row", flex: 1}}>
              <View style={{flex: 0.8}}>
            <TextInput
              underlineColorAndroid="transparent"
              placeholder="type here"
              placeholderTextColor={color.black}
              style={[styles.LabTextInput, { color: color.black, borderColor: color.black}]}
              ref={(text) => (this.nameInput = text)}
              // defaultValue=""
              autoCapitalize="none"
              value={this.state.newItem}
              onChangeText={(text) => {
                this.setState({ selectedLabTest: "", newItem: text });
                this.labTestSearch(text);
              }}
            />
            </View>
            <TouchableOpacity 
            style={{
              flex:0.2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.themeDark,
              borderColor: color.black,
              borderWidth: 1,
              borderRadius: 4,
              marginLeft: '0.5vw'
            }}
            onPress={() => {
              var { laboratoryTests, newItem } = this.state;
              if(newItem){

                var data = {value: "N/A", label: newItem }
                var item = { id: "N/A", name: newItem}

                for(let i=0; i<laboratoryTests.length; i++){
                  if (laboratoryTests[i].id == "N/A"){
                    laboratoryTests.splice(i,1)
                  }
                }

                laboratoryTests.push(item);
                this.setState({
                  laboratoryTests,
                  selectedLabTest: data,

                  newItem: "",
                  labSearchList: [],
                  isLabTestSearchView: true,
                }),
                this.addMedicationToList(this);
              }
            }}
            >
              <Text style={{fontSize: '0.9vw'}}>{"Save"}</Text>
            </TouchableOpacity>
            </View>
            {this.state.isLabTestSearchView === false ? (
              <View
                style={[styles.TextBoxSearch, {
                  backgroundColor: this.state.newItem ? color.white : "",
                  maxHeight: this.state.newItem ? "" : 0,}]}
              >
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  style={{ maxHeight: '14.4vw' }}
                >
                  <FlatList
                    data={this.state.labSearchList}
                    // ItemSeparatorComponent={this.ListViewItemSeparator}
                    //Item Separator View
                    renderItem={({ item }) => (
                      <View
                        style={styles.ItemView}
                      >
                        <View style={{ width: "100%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              var { laboratoryTests } = this.state;

                              var data = { value: item.id, label: item.name };
                              laboratoryTests.push(item);
                              this.setState({
                                laboratoryTests,
                                selectedLabTest: data,

                                newItem: "",
                                labSearchList: [],
                                isLabTestSearchView: true,
                              }),
                                this.addMedicationToList(this);
                            }}
                          >
                            <Text style={{fontSize: '1vw'}}>{item.name}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                    enableEmptySections={true}

                  //keyExtractor={(item, index) => index.toString()}
                  />
                </ScrollView>
              </View>
            ) : null}
          </View>
        ) : <View style={{ marginLeft: '1.2vw' }}>{(
          this.renderIconNewBtn(
            "plus",
            "New",
            true,
            true,
            this.triggerNewLabTestadd.bind(this),
            this.state.isLocked
          )
        )}</View>} */}
        {/* comment for New lab revamp - 02/02/23 -- End */}
        {/* 
        // <View style={{marginLeft: 20}}>{ (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewInhouseadd.bind(this),
              this.state.isLocked
            )
          )}</View>} */}

        {/* {this.renderIconBtn("plus", "New", true)} */}
        <View style={{ marginVertical: '1vw' }}>
          {this.renderGroupName()}
          {/* <CommonSectionHeader heading={"Test Group"} /> */}
        </View>
        {/* <View style={{}}>
          {this.renderTitleWithMultipleBtn(
            "",
            false,
            1,
            false,
            ""
          )}
        </View> */}

        {/* comment for New lab revamp - 02/02/23 -- start */}
        {/* <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1, marginLeft: '1.2vw' }}>
          {this.state.radiologyTests.map((item, index) => {
            var data = { label: item.name, value: item.id };
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={data}
                  selectedvalue={this.state.selectedRadiologyTest}
                  butttonText={data.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedRadiologyTest"}
                />
              </View>
            );
          })}
        </View> */}
        {/* {this.state.isRadiologyTest ? (
          <View
            style={[{
              height: '2vw',
              marginTop: '0.6vw',
              width: "100%",
              zIndex: 10,
            }, Styles.allButtonBorderRadius]}
          >
            <View style={{flexDirection: "row", flex: 1}}>
              <View style={{flex: 0.8}}>
            <TextInput
              underlineColorAndroid="transparent"
              placeholder="type here"
              placeholderTextColor={color.black}
              style={[styles.LabTextInput, {
                color: color.black,
                borderColor: color.black}]}
              ref={(text) => (this.nameInput = text)}
              // defaultValue=""
              autoCapitalize="none"
              value={this.state.radiologyNewItem}
              onChangeText={(text) => {
                this.setState({
                  selectedRadiologyTest: "",
                  radiologyNewItem: text,
                });
                this.radiologyTestSearch(text);
              }}
            />
            </View>
            <TouchableOpacity 
            style={{
              flex:0.2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.themeDark,
              borderColor: color.black,
              borderWidth: 1,
              borderRadius: 4,
              marginLeft: '0.5vw'
            }}
            onPress={() => {
              var { radiologyTests, radiologyNewItem } = this.state;
              if(radiologyNewItem){

                var data = {value: "N/A", label: radiologyNewItem }
                var item = { id: "N/A", name: radiologyNewItem}
                for(let i=0; i<radiologyTests.length; i++){
                  if (radiologyTests[i].id == "N/A"){
                    radiologyTests.splice(i,1)
                  }
                }

                radiologyTests.push(item);
                this.setState({
                  radiologyTests,
                  selectedRadiologyTest: data,
                  radiologyNewItem: "",
                  radiologySearchList: [],
                  isRadiologyTestSearchView: true,
                }),
                  this.addRadiologyToList(this);
              }
              }}
            >
              <Text style={{fontSize: '0.9vw'}}>{"Save"}</Text>
            </TouchableOpacity>
            </View>
            {this.state.isRadiologyTestSearchView === false ? (
              <View
                style={[styles.TextBoxSearch, {
                  backgroundColor: this.state.radiologyNewItem
                    ? color.white
                    : "",
                  maxHeight: this.state.radiologyNewItem ? "" : 0,
                }]}
              >
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  style={{ maxHeight: '14.4vw' }}
                >
                  <FlatList
                    data={this.state.radiologySearchList}
                    // ItemSeparatorComponent={this.ListViewItemSeparator}
                    //Item Separator View
                    renderItem={({ item }) => (
                      <View
                        style={styles.ItemView}
                      >
                        <View style={{ width: "100%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              var { radiologyTests } = this.state;

                              var data = { value: item.id, label: item.name };
                              radiologyTests.push(item);
                              this.setState({
                                radiologyTests,
                                selectedRadiologyTest: data,
                                radiologyNewItem: "",
                                radiologySearchList: [],
                                isRadiologyTestSearchView: true,
                              }),
                                this.addRadiologyToList(this);
                            }}
                          >
                            <Text style={{fontSize: '0.9vw'}}>{item.name}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                    enableEmptySections={true}

                  //keyExtractor={(item, index) => index.toString()}
                  />
                </ScrollView>
              </View>
            ) : null}
          </View>
        ) : <View style={{ marginLeft: '1.2vw' }}>{(
          this.renderIconNewBtn(
            "plus",
            "New",
            true,
            true,
            this.triggerNewRadiologyTestadd.bind(this),
            this.state.isLocked
          )
        )}</View>} */}
        {/* comment for New lab revamp - 02/02/23 -- End */}
        {/* 
        // <View style={{marginLeft: 20}}>{ (
            this.renderIconNewBtn(
              "plus",
              "New",
              true,
              true,
              this.triggerNewInhouseadd.bind(this),
              this.state.isLocked
            )
          )}</View>} */}
        <View style={{ marginVertical: '1vw' }}>
          <CommonSectionHeader heading={"Special Test"} />
        </View>
        {/* <View style={{}}>
          {this.renderTitleWithMultipleBtn("", false, 1, false, "")}
        </View> */}
        {/* <FlatList
          data={this.state.specialTest}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedSpecialTest', this.state.selectedSpecialTest)}
        //   extraData={this.state.selectedEye}
        /> */}

        <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1, marginLeft: '1.2vw' }}>
          {this.state.specialTests.map((item, index) => {
            var data = { label: item.name, value: item.id };
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={data}
                  selectedvalue={this.state.selectedSpecialTest}
                  butttonText={data.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedSpecialTest"}
                />
              </View>
            );
          })}
        </View>

        {this.state.isSpecialTest ? (
          <View
            style={[{
              height: '2vw',
              marginTop: '0.6vw',
              width: "100%",
              zIndex: 100,
            }, Styles.allButtonBorderRadius]}
          >
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={{ flex: 0.8 }}>
                <TextInput
                  underlineColorAndroid="transparent"
                  placeholder="type here"
                  placeholderTextColor={color.black}
                  style={[styles.LabTextInput, {
                    color: color.black,
                    borderColor: color.black,
                  }]}
                  ref={(text) => (this.nameInput = text)}
                  // defaultValue=""
                  autoCapitalize="none"
                  value={this.state.specialNewItem}
                  onChangeText={(text) => {
                    this.setState({
                      selectedSpecialTest: "",
                      specialNewItem: text,
                    });
                    this.specialTestSearch(text);
                  }}
                />
              </View>
              <TouchableOpacity
                style={{
                  flex: 0.2,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: color.themeDark,
                  borderColor: color.black,
                  borderWidth: 1,
                  borderRadius: 4,
                  marginLeft: '0.5vw'
                }}
                onPress={() => {
                  var { specialTests, specialNewItem } = this.state;
                  if (specialNewItem) {

                    var data = { value: "N/A", label: specialNewItem }
                    var item = { id: "N/A", name: specialNewItem }
                    for (let i = 0; i < specialTests.length; i++) {
                      if (specialTests[i].id == "N/A") {
                        specialTests.splice(i, 1)
                      }
                    }

                    specialTests.push(item);
                    this.setState({
                      specialTests,
                      selectedSpecialTest: data,
                      specialNewItem: "",
                      specialSearchList: [],
                      isSpecialTestSearchView: true,
                    }),
                      this.addSpecialToList(this);
                  }
                }}
              >
                <Text style={{ fontSize: '0.9vw' }}>{"Save"}</Text>
              </TouchableOpacity>
            </View>
            {this.state.isSpecialTestSearchView === false ? (
              <View
                style={[styles.TextBoxSearch, {
                  backgroundColor: this.state.specialNewItem ? color.white : "",
                  maxHeight: this.state.specialNewItem ? "" : 0,
                }]}
              >
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  style={{ maxHeight: '14.4vw' }}
                >
                  <FlatList
                    data={this.state.specialSearchList}
                    // ItemSeparatorComponent={this.ListViewItemSeparator}
                    //Item Separator View
                    renderItem={({ item }) => (
                      <View
                        style={styles.ItemView}
                      >
                        <View style={{ width: "100%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              var { specialTests } = this.state;
                              var data = { value: item.id, label: item.name };
                              specialTests.push(item);
                              this.setState({
                                specialTests,
                                selectedSpecialTest: data,
                                specialNewItem: "",
                                specialSearchList: [],
                                isSpecialTestSearchView: true,
                              }),
                                this.addSpecialToList(this);
                            }}
                          >
                            <Text style={{ fontSize: '0.9vw' }}>{item.name}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                    enableEmptySections={true}

                  //keyExtractor={(item, index) => index.toString()}
                  />
                </ScrollView>
              </View>
            ) : null}
          </View>
        ) : <View style={{ marginLeft: '1.2vw' }}>{(
          this.renderIconNewBtn(
            "plus",
            "New",
            true,
            true,
            this.triggerNewSpecialTestadd.bind(this),
            this.state.isLocked
          )
        )}</View>}

        <View style={{ marginTop: '1.2vw', zIndex: -1, alignSelf: "center", flexDirection: 'row' }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addAllergy.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
          <TouchableOpacity style={[styles.clearBtnView, Style.allButtonBorderRadius]} onPress={() => this.onPressClear()}>
            <Text style={styles.clearBtnText}>Clear</Text>
          </TouchableOpacity>
        </View>
        <View>
          {this.state.show ? (
            <View
              style={{
                height: '2.6vw',
                width: "100%",
                backgroundColor: "green",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                Symptom Created Successfully
              </Text>
            </View>
          ) : null}
        </View>
        <View>
          {this.state.successOrErrorMessage ? (
            <View
              style={{
                height: '2.6vw',
                width: "100%",
                backgroundColor: this.state.successMessageFlag
                  ? "green"
                  : this.state.errorMessageFlag
                    ? "red"
                    : null,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.successMessageFlag
                  ? "Test Created Successfully"
                  : this.state.errorMessageFlag
                    ? "Test  Failed"
                    : null}
              </Text>
            </View>
          ) : null}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
const styles = StyleSheet.create({
  CommonBtnView: {
    marginBottom: '0.6vw',
    marginRight: '0.6vw'
  },
  LabTextInput: {
    height: '2vw',
    paddingLeft: '0.3vw',
    marginRight: 0,
    flex: 1,
    textAlign: "center",
    borderWidth: 1,
    borderRadius: 4,
    fontSize: '0.9vw'
  },
  TextBoxSearch: {
    borderRadius: 10,
    width: "90%",
    justifyContent: "center",
    alignContent: "center",
    padding: '0.6vw',
    marginLeft: '0.6vw',
    marginTop: '2vw',
    position: "absolute"
  },
  ItemView: {
    flexDirection: "row",
    bottom: '0.6vw',
    marginBottom: '0.6vw',
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
    marginTop: '0.6vw',
    zIndex: 10,
  },
  PickerView: {
    height: '2vw',
    width: '10.6vw',
    marginRight: '0.6vw',
    borderRadius: 4,
    fontSize: '0.9vw'
  },
  HeaderDropdown: {
    flexDirection: 'row'
  },
  TestNameInputView: {
    paddingRight: '0.8vw',
    paddingLeft: '0.8vw'
  },
  TestNameInput: {
    height: '1.95vw',
    borderColor: color.black,
    borderWidth: 1,
    backgroundColor: color.themeShadeBackground,
    marginTop: '0.97vw',
    paddingLeft: '0.65vw',
    borderRadius: ".25vw",
    fontSize: '0.91vw'
  },
  TestNameSuggestionView: {
    maxHeight: '11.72vw',
    borderRadius: '0.26vw',
    borderWidth: 1,
    borderColor: "#888888",
    width: '90%',
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: '0.65vw',
    marginLeft: '0.65vw',
    marginTop: '5.20vw',
    position: 'absolute',
  },
  TestNameSuggList: {
    flexDirection: "row",
    bottom: '0.65vw',
    margin: '0.32vw',
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
    zIndex: '0.65vw',
  },
  TestNameListTxt: {
    fontSize: '0.91vw'
  },
  EyeTestBtnMainView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    zIndex: '-1',
    marginTop: '1vw',
    flex: 1
  },
  EyeTestBtnView: {
    marginBottom: '0.65vw',
    marginRight: '0.65vw',
    flexDirection: "row",
    flexWrap: "wrap"
  },
  ServiceButtonsText: {
    fontSize: "0.72vw"
  },
  ServiceButtons: {
    marginBottom: '0.97vw',
    borderWidth: 1,
    paddingLeft: '1.17vw',
    paddingRight: '1.17vw',
    paddingTop: '0.52vw',
    paddingBottom: '0.52vw',
    marginLeft: '0.65vw',
    // borderRadius: 30,
  },
  clearBtnView: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '1.2vw',
    flexDirection: "row",
    borderColor: color.themeDark,
    borderWidth: 1,
    backgroundColor: color.white,
    padding: '1vw',
    paddingTop: '0.3vw',
    paddingBottom: '0.3vw',
    minWidth: '6.3vw',
    marginBottom: '0.6vw',
    marginLeft: '1vw'
  },
  clearBtnText: {
    fontSize: '0.8vw',
    textAlign: "center",
    color: color.themeDark,
    fontWeight: "400"
  }
})
