import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, Dimensions, StyleSheet, ScrollView, Picker, TextInput } from 'react-native';
import { color } from '../../../../styles/Color';
import CommonDateFilter, { NavigationTopHeader, CommonButton, CommonHistorySectionHeader } from '../../BaseComponent';
import Pagination from '../../common/Pagination';
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import Style from '../../../../styles/Style';
import AsyncStorage from '../../../../AsyncStorage';
import OpthamologyService from '../../../../network/OpthamologyService';
import { Select, Tooltip } from 'antd';

const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
  { label: "Surgery Report", value: "oTSurgeryNotesReport" }
]
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class SurgeryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surgeryReportList: [],
      surgeryReportAfterPagination: [],
      isClearInt: 0,
      isClear: false,
      isAdminAccess: false,
      filterDate: {
        fromDate: converted_date,
        toDate: converted_date
      },
      selectedSurgeon: "",
      selectedNewPatient: "",
      approvalStatus: "",
      surgeryStatus: "",
      current_page: 1,
      records_per_page: 10,
      surgeonDoctorList: [],
      selectApprovedStatus: "",
      selectedSurgeryStatus: "",
      clinicID: "",
      isOPT : ""

    };
  }
  async componentDidMount() {

    this.getSurgeryNotesData()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access,
      clinicID: loggedInDataObj.clinic_id,
      isOPT : loggedInDataObj.is_OPT
    }, () => {
      this.getSurgeryDoctorList()
    })
  }
  getSurgeryDoctorList = () => {
    var url = Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.clinicID
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSurgeryDoctorListSuccess,
      this.getSurgeryDoctorListFailure
    );
  }
  getSurgeryDoctorListSuccess = (response) => {
    if (response && response.status == "success") {
      this.setState({
        surgeonDoctorList: response.data
      })
    } else {
      this.props.showResponseValue("error", response.message)
    }
  }
  getSurgeryDoctorListFailure = (error) => {
    this.props.showResponseValue("error", error.message)

  }
  getSurgeryNotesData = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      surgeryReportAfterPagination: [],
      surgeryReportList: []
    })
    var url = Constants.OT_SURGERY_REPORT + "?from_date=" + this.state.filterDate.fromDate + "&to_date=" + this.state.filterDate.toDate + "&approved_status=" + this.state.selectApprovedStatus + "&patient_search=" + this.state.selectedNewPatient + "&surgeon_doctor_name=" + this.state.selectedSurgeon + "&surgery_status=" + this.state.selectedSurgeryStatus
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getSurgeryNotesSuccess,
      this.getSurgeryNotesFailure
    );
  }
  getSurgeryNotesSuccess = (response) => {
    if (response && response.status == "success") {
      this.setState({
        surgeryReportList: response.data
      })
    } else {
      this.props.showResponseValue("error", response.message)
    }
  }
  getSurgeryNotesFailure = (error) => {
    this.props.showResponseValue("error", error.message)
  }
  exportSurgeryReport = () => {
    var url = Constants.OT_SURGERY_REPORT + "?from_date=" + this.state.filterDate.fromDate + "&to_date=" + this.state.filterDate.toDate + "&approved_status=" + this.state.selectApprovedStatus + "&patient_search=" + this.state.selectedNewPatient + "&surgeon_doctor_name=" + this.state.selectedSurgeon + "&surgery_status=" + this.state.selectedSurgeryStatus + "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      url,
      this.exportSurgeryReportSuccess,
      this.exportSurgeryReportFailure,
      "excel"
    );
  };

  exportSurgeryReportSuccess = (success) => {
    if (success) {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'IPDoctorReport.xlsx');
      pom.click();
    }
  };

  exportSurgeryReportFailure = (error) => {
    console.log(error);
  };
  renderScrollData = () => {
    return (
        <View style={styles.TableBody}>
          {this.state.surgeryReportAfterPagination && this.state.surgeryReportAfterPagination.length > 0 ?
            this.state.surgeryReportAfterPagination.map(list => {
              if (list.is_fo_approved) {
                var foApproval = "FO"
              }
              if (list.is_doctor_approved) {
                var doctorApproval = "DOCTOR"
              }
              if (list.is_ot_approved) {
                var otApproval = "OT"
              }
              return (
                <View style={styles.tableViewMain}>
                  <View style={{ flex: this.state.isOPT ? 0.3 : 0.38 }}>
                    <Tooltip placement="topLeft" title={list.surgery_name}>
                      <Text style={{ fontSize: ".87vw", marginLeft:"1vw"}}>{list.surgery_name.length > 15 ? list.surgery_name.slice(0, 15) + "..." : list.surgery_name}</Text>
                    </Tooltip>
                  </View>   
                  <View style={{ flex: this.state.isOPT ? 0.4 : 0.48 }}>
                    <Tooltip placement="topLeft" title={list.package_name}>
                      <Text style={{ fontSize: ".87vw", marginLeft:"1vw"}}>{list.package_name.length > 15 ? list.package_name.slice(0, 15) + "..." : list.package_name}</Text>
                    </Tooltip>
                  </View>  
                  {this.state.isOPT ? <Text style={{ fontSize: "0.87vw", flex: 0.3 }}>{list.iol_power}</Text> : null}
                  <Text style={{ fontSize: "0.87vw", flex: this.state.isOPT ? 0.5 : 0.55}}>{moment(list.surgery_date).format("DD-MM-YYYY")}</Text>
                  <View style={{ flex: 0.5, marginLeft:"-1vw" }}>
                    <Tooltip placement="topLeft" title={list.anaesthetist}>
                      <Text style={{ fontSize: ".87vw"}}>{list.anaesthetist &&  list.anaesthetist.length > 15 ? list.anaesthetist.slice(0, 15) + "..." : list.anaesthetist}</Text>
                    </Tooltip>
                  </View>
                  <View style={{ flex: this.state.isOPT ? 0.4 : 0.45, display:"flex", flexWrap:"wrap", marginLeft:"-1vw"}}>
                    <Text style={{fontSize: "0.87vw", marginLeft: this.state.isOPT ? "0.5" : "1vw"}}>COUNSELLOR</Text>
                    <Text style={{fontSize: "0.87vw"}}>{(foApproval ? foApproval + ", " : "") + (doctorApproval ? doctorApproval + ", " : "") + (otApproval ? otApproval : "")}</Text>
                  </View>
                  <Text style={{ fontSize: "0.87vw", flex: this.state.isOPT ? 0.3 : 0.35 }}>{list.appointment_status == null ? list.status : list.appointment_status}</Text>
                </View>
              )
            }) :
            <View style={styles.noRecordText}>
              <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
            </View>}
        </View>
    )
  }
  renderSurgeryReportList = () => {
    return (
        <View style={styles.TableBody}>
          {this.state.surgeryReportAfterPagination && this.state.surgeryReportAfterPagination.length > 0 &&
            this.state.surgeryReportAfterPagination.map(list => {
              return (
                <View style={styles.tableViewMain}>  
                  <View style={{ flex: 0.5, marginLeft: "0.8vw" }}>
                    <Tooltip placement="topLeft" title={list.patient_name}>
                    <Text style={{ fontSize: "1vw"}}>{list.patient_name.length > 15 ? list.patient_name.slice(0, 15) + "..." : list.patient_name}</Text>
                    </Tooltip> 
                    <Text style={styles.textSize}>{list.patient_mobile_no}</Text>
                  </View>
                  <Text style={styles.tableText}>{list.surgeon_name}</Text>

                </View>
              )
            })

          }
        </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
      current_page: current_page,
      records_per_page: records_per_page
    })
  }
  paginationChangedValues = (data) => {
    this.setState({
      surgeryReportAfterPagination: data
    })
  }
  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterDate } = this.state;
    states["filterDate"] = date
    filterDate["fromDate"] = date.fromDate;
    filterDate["toDate"] = date.toDate
    this.setState({ states, filterDate })

  }

  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterDate.fromDate}
              endDate={this.state.filterDate.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterDate.fromDate}
              endDate={this.state.filterDate.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }
  renderPickerNewPatient() {
    return (
      <View>
        <TextInput
          placeholder={"Pt Name / Mobile Num / UHID"}
          underlineColorAndroid="transparent"
          style={styles.RenderTextFieldInput}
          placeholderTextColor={color.lightGray}
          autoCapitalize="none"
          value={this.state.selectedNewPatient}
          onChangeText={(text) => {
            this.setState({
              selectedNewPatient: text
            })
          }}
        ></TextInput>
      </View>
    )
  }
  renderPickerSurgeon() {
    return (
      <View>
        <Picker

          selectedValue={this.state.selectedSurgeon}
          style={[Style.pickerView, styles.pickerStyle]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedSurgeon"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Surgeon Name' value='' />
          {(this.state.surgeonDoctorList && this.state.surgeonDoctorList).length > 0 ?
            this.state.surgeonDoctorList.map(name =>
              <Picker.Item label={name.first_name + " "+name.initial} value={name.first_name + " "+name.initial} />
            )
            : null
          }
        </Picker>
      </View>
    )
  }
  renderPickerSurgeryStatus = () => {
    return (
      <View>
        <Picker

          selectedValue={this.state.selectedSurgeryStatus}
          style={[Style.pickerView, styles.pickerStyle]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedSurgeryStatus"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Surgery Status' value='' />
          <Picker.Item label='Booked' value='Booked' />
          <Picker.Item label='Approved' value='Approved' />
          <Picker.Item label='Reschedule' value='Reschedule' />
          <Picker.Item label='No Show' value='No Show' />
          <Picker.Item label='Cancelled' value='Cancelled' />
          <Picker.Item label='Arrived' value='Arrived' />
          <Picker.Item label='In Surgery' value='In Surgery' />
          <Picker.Item label='Completed' value='Completed' />

        </Picker>
      </View>
    )
  }
  renderPickerApprovedStatus = () => {
    return (
        <Select
          mode="multiple"
          allowClear
          placeholder="Select Approved Status"
          value={this.state.selectApprovedStatus}
          maxTagTextLength={10}
          maxTagCount={5}
          onChange={(itemvalue) => {
            this.setState({
              selectApprovedStatus: itemvalue
            })
          }}
          style={{
            marginTop:".2vw",
            borderRadius: 4,
            borderColor: "#CDD1D5",
            height: '2vw',
            width: "14.5vw",
            zIndex: -1,
            fontSize: '0.9vw',
          }}
        >
            <Option value={"COUNSELLOR"} >{"COUNSELLOR"}</Option>
            <Option value={"FO"} >{"FO"}</Option>
            <Option value={"DOCTOR"} >{"DOCTOR"}</Option>
            <Option value={"OT"} >{"OT"}</Option>

        </Select>
    )
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize = "0.9vw") {
    return (
      <View style={{ flex: flexWidth }}>
        <Text style={{ fontSize: fontsize, flexWrap: "wrap", fontWeight: "600" }}>{title}</Text>
      </View>)
  }
  clearFilter = () => {
    var states = this.state
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    states["selectedNewPatient"] = ""
    states["selectApprovedStatus"] = []
    states["selectedSurgeon"] = ""
    states["selectedSurgeryStatus"] = ""
    states["filterDate"] = {
      fromDate: converted_date,
      toDate: converted_date
    }
    states["isClear"] = true
    states["isClearInt"] = 1
    this.setState({ states }, ()=>{
      this.getSurgeryNotesData()
        this.setState({
          isClear: false
        },() =>{
          this.renderDateFilter(); 
        })
      })
  }
  onPressAction = (title) => {
    if(title == "Search") {
      this.getSurgeryNotesData()
    } else if(title == "Clear"){
      this.clearFilter()
    } else if( title == "Export As Excel") {
      this.exportSurgeryReport()
    }

  }
  renderButtomView = () => {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Export As Excel', value: 'print' }
    ]
    return (
      <View style={styles.ButtonHeader}>
        {
          actions.map((item, index) => {
            return (
              <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={item.label}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={() => {this.onPressAction(item.label)}}
                buttonKey={item.value} />
            )
          })
        }
      </View>
    )
  }
  renderHeader = () => {
    return (
      <View style={{ width: "60vw", flex: 1, }}>
        <CommonHistorySectionHeader
          heading1={"Patient Details"}
          heading2={"Surgeon Doctor"}
          noOfColumn={2}
          columnSize={[0.2, 0.2]}
        />
      </View>
    )
  }
  renderScrollHeader = () => {
    return (
      <View style={{ width: "75vw" }}>
       {this.state.isOPT ? <CommonHistorySectionHeader
          heading1={"Surgery"}
          heading2={"Package Details"}
          heading3={"Power"}
          heading4={"Surgery Date/Time"}
          heading5={"Anaesthesiatist"}
          heading6={"Approved By"}
          heading7={"Surgery Status"}
          noOfColumn={7}
          columnSize={[0.3, 0.4, 0.3, 0.5, 0.5, 0.4, 0.3]}
        /> : <CommonHistorySectionHeader
        heading1={"Surgery"}
        heading2={"Package Details"}
        heading3={"Surgery Date/Time"}
        heading4={"Anaesthesiatist"}
        heading5={"Approved By"}
        heading6={"Surgery Status"}
        noOfColumn={6}
        columnSize={[0.35, 0.45, 0.55, 0.55, 0.45, 0.35]}
      />}
      </View>
    )
  }
  render() {
    return (
      <View style={styles.mainView}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeaderList}
          selectedTab={"oTSurgeryNotesReport"}
          isNavigationBorder={true}
        />
        <View style={styles.mainContainer}>
         <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ height: "70vh", width: "100%", zIndex: 1 }}>
          <View style={styles.leftSideContainer}> 
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <View style={{ flexDirection: "column", width: "100%" }}>
                {this.renderHeader()}
                {this.renderSurgeryReportList()}
              </View>

            </ScrollView>
            <ScrollView showsHorizontalScrollIndicator={this.state.surgeonDoctorList && this.state.surgeryReportList.length > 0 ? true : false} showsVerticalScrollIndicator={true} horizontal={true}>
              <View style={{ flexDirection: "column", width: "100%" }}>
                {this.renderScrollHeader()}
                {this.renderScrollData()}
              </View>
            </ScrollView>
          </View>
          </ScrollView>
          <View style={styles.rightSideContainer}>
            {this.renderDateFilter()}
            <View style={{ flexDirection: "row", marginTop: "2vw", zIndex: -1 }}>
              <View>
                <View>
                  <Text style={styles.filterHeader}>Search By </Text>
                  {this.renderPickerNewPatient()}
                </View>
                <View>
                  <Text style={styles.filterHeader}>Surgeon Name </Text>
                  {this.renderPickerSurgeon()}
                </View>
              </View>
              <View>
                <View>
                  <Text style={styles.filterHeader}>Patient Status </Text>
                  {this.renderPickerSurgeryStatus()}
                </View>
                <View>
                  <Text style={styles.filterHeader}>Approved By </Text>
                  {this.renderPickerApprovedStatus()}
                </View>
              </View>
            </View>
            {this.renderButtomView()}
          </View>
        </View>
        <View style={{ marginTop: "1vw" }}>
        {this.state.surgeryReportList && this.state.surgeryReportList.length > 0 ?
          <Pagination
            paginationChangedValues={this.paginationChangedValues.bind(this)}
            totalItems={this.state.surgeryReportList}
            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
          /> : null
        }
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  headerStyle: { flexDirection: "row", paddingVertical: "0.8vw", backgroundColor: color.applicationBackgroundColor, paddingHorizontal: "0.5vw" },
  mainView: { margin: "0.5vw" },
  mainContainer: { display: 'flex', flexDirection: 'row', flex: 1, width: "100%", height: "70.2vh" },
  leftSideContainer: { width: "100%", height: "100%", backgroundColor: color.white, flexDirection: "row" },
  rightSideContainer: { width: "35%", backgroundColor: color.themeShade, padding: '1vw', zIndex: -1 },
  TableBody: {
    // height: '80vh'
  },
  tableViewMain: { flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, minHeight: 50, display:"flex", alignItems:"center"},
  noRecordText: { flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 },
  textSize: { fontSize: "0.87vw" },
  pickerStyle: { marginTop:".2vw", width: '14.64vw', fontSize: '1vw', borderColor: '#CDD1D5' },
  ButtonMainView: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "90%",
    zIndex: -1
  },
  ButtonHeader: { flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2, marginTop: "5vw" },
  RenderTextFieldInput: {
    color: '#000',
    width: "90%",
    height: "2vw",
    fontSize: '0.91vw',
    marginTop: ".2vw",
    borderWidth: "0.1vw",
    borderColor: color.lightGray,
    borderRadius: "0.3vw",
    paddingLeft: "0.5vw"
  },
  tableText: { fontSize: "0.87vw", flex: 0.5, alignSelf: "center" },
  filterHeader:{ fontSize: "1vw", marginTop: "1.5vw"}
})  
