import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, StyleSheet, Image, ScrollView } from "react-native";
import OpthamologyService from "../../../../network/OpthamologyService";
import { color } from "../../../../styles/Color";
import { Constants } from "../../../../utils/Constants";
import EditIcon from '../../../../../assets/images/BillConfig/Edit.svg'
import DeleteIcon from '../../../../../assets/images/BillConfig/delete.svg'

export default class IpServiceConfig extends Component {
    constructor(props) {
      super(props);

      this.state = {
        userType: this.props.userType,
        ipServiceList: [],
        selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        selectedDeletedId: null,
        searchKey: "",
      }
    }

    componentDidMount() {
        this.getIpServiceConfig()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.refreshOpData){
            this.getIpServiceConfig()
        }
        if(nextProps.confirmDeletedIpId != null && nextProps.confirmDeletedIpId != this.state.selectedDeletedId){
            this.setState({
                selectedDeletedId: nextProps.confirmDeletedIpId
            })
            this.onPressDeleteType(nextProps.confirmDeletedIpId)
        }
        if(nextProps.searchKey != this.state.searchKey){
            this.setState({
                searchKey: nextProps.searchKey
            }, () => {
                this.getIpServiceConfig()
            })
        }
    }

    getIpServiceConfig = () => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_SERVICE + "?service_name=" + this.state.searchKey
        }else{
            url = Constants.CLINIC_IP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&service_name=" + this.state.searchKey
        }
        OpthamologyService.getInstance().getComplaints(
            url, 
            this,
            this.getIpServiceConfigSuccess,
            this.getIpServiceConfigFailure
        );
    }

    getIpServiceConfigSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                ipServiceList: response.data,
            })
        }
    }

    onPressDeleteType = (id) => {
        var url = ""

        if(this.state.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_SERVICE + "?id=" + id
        }else{
            url = Constants.CLINIC_IP_SERVICE + "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&id=" + id
        }

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.onPressDeleteTypeSuccess,
            this.onPressDeleteTypeFailure
        )
    }

    onPressDeleteTypeSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.getIpServiceConfig()
            this.props.isDeleteClick(null, "ip", "revert")
        }else if(response.status === "fail") {
            this.props.showResposeValue("error", response.message)
            this.props.isDeleteClick(null, "ip", "revert")
        }else{
            this.props.showResposeValue("error", "Something went wrong")
            this.props.isDeleteClick(null, "ip", "revert")
        }
    }

    render(){
        var states = this.state
        return(
            <View style={{maxHeight: "90%", height: '-webkit-fill-available' }}>
                <View style={{marginTop: 5, backgroundColor: color.sectionHeadingDarkColor, minHeight: 40, maxHeight: 40, flexDirection: 'row', alignItems: 'center', borderRadius: 4, flex: 1, marginHorizontal: 5, paddingHorizontal: 15, paddingVertical: 10  }}>
                <View style={{flex: 0.5}}>
                    <Text style={styles.headerFontStyle}>{"Service Type"}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Amount"}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={styles.headerFontStyle}>{"Action"}</Text>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {states.ipServiceList.length > 0 && states.ipServiceList.map((item, index) => (
                <View style={{height: 60, marginHorizontal: 5, paddingHorizontal: 15, minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center"}}>
                <View style={{ flexDirection: 'row', flex: 1, alignItems: "center"}}>
                <View style={{flex: 0.5}}>
                    <Text style={{fontSize: 14,}}>{item.service_name}</Text>
                </View>
                <View style={{flex: 0.25}}>
                    <Text style={{fontSize: 14,}}>{item.rate_per_unit}</Text>
                </View>
                <View style={{flex: 0.25, flexDirection: "row",}}>
                    <TouchableOpacity
                    onPress={() => {
                        this.getIpServiceConfig()
                        this.setState({

                        },() => {
                            this.props.editIPItem(item)
                        })
                    }}
                    >
                        <Image
                            source={EditIcon}
                            style={{ height: 25, width: 25 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                    style={{marginLeft: 30, width: "fit-content"}}
                    onPress={() => {
                        this.props.isDeleteClick(item.id, "ip", "")
                    }}
                    >
                        <Image
                            source={DeleteIcon}
                            style={{ height: 25, width: 30 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                </View>
                </View>
                </View>
                ))}
               
                </ScrollView>
                {
              states.ipServiceList.length == 0 ? 
              <View style={{height: "100%", alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontWeight: "bold"}}>No Data Found</Text>
              </View>  
              : null
            }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 14,
        color: "black",
        fontWeight: '500'
    },
});