import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';

class DocBasePatientVisit extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
      TotalPatients: [],
      SpecList: [],
      SelectedSpec: ""
    }
  };
  componentDidMount() {
    this.getSpecialisationList()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getDocBasePatientVisit()
      })
    }
  }

  /* Doctor Base Patient Visit Count API Start*/
  getDocBasePatientVisit() {
    var serviceUrl =
      Constants.DOC_BASE_PATIENT_VISIT +
      "?specialisation=" +
      this.state.SelectedSpec +
      "&start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDocBaseSuccess,
      this.getDocBaseFailure
    );

  }

  getDocBaseSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        TotalPatients: success.data.doctor
      })
    } else{
      this.props.Response(success.message)
    }
  };

  getDocBaseFailure = (error) => {
    this.props.Response(error.message)
  };
  /* Doctor Base Patient Visit Count API end*/

  /* Doctor Specialization API Start*/
  getSpecialisationList() {
    var serviceUrl =
      Constants.Specialisation_List

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getSpecListSuccess,
      this.getSpecListFailure
    );
  }
  getSpecListSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        SpecList: success.data,
        SelectedSpec: success.data[0].role_name
      }, () => { this.getDocBasePatientVisit() })
    }else{
      this.props.Response(success.message)
    }
  }
  getSpecListFailure = (error) => {
    this.props.Response(error.message)
  }
  /* Doctor Specialization API end*/

  /* Export Excel API start*/
  getDocBasePatientVisitExcel = () => {
    var serviceUrl =
      Constants.DOC_BASE_PATIENT_VISIT +
      "?specialisation=" +
      this.state.SelectedSpec +
      "&start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getDocBasePatientsvisitSuccess,
      this.getDocBasePatientsvisitFailure,
      "excel"
    );
  }
  getDocBasePatientsvisitSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getDocBasePatientsvisitFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/
  render() {
    const data = {
      datasets: [
        {
          data: this.state.TotalPatients.length > 0 ? this.state.TotalPatients.map((list) => (list.count)) : [],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(204, 255, 153)',
            'rgb(97, 207, 126)',
            'rgb(168, 50, 155)'
          ],
        },
      ],
      labels: this.state.TotalPatients.length > 0 ? this.state.TotalPatients.map((list) => (list.name).substring(0, 20)) : [],
    };
    const Specialisation = (spec) => {
      if (spec == "DOCTOR") {
        return "General Physician"
      }
      else if (spec == "DB") {
        return "Diabetology"
      }
      else if (spec == "PD") {
        return "Pediatric"
      }
      else if (spec == "OBGYN") {
        return "Gynaecology"
      }
      else if (spec == "PSY") {
        return "Psychiatric"
      }
      else if (spec == "OPT") {
        return "Ophthalmology"
      }
      else {
        return spec
      }
    }

    return (
      <View>
        <View style={styles.TopView}>
          <View style={styles.RoleNameView}>
            <Text style={styles.RoleNameText}>{Specialisation(this.state.SelectedSpec)}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Picker
              selectedValue={this.state.SelectedSpec}
              style={styles.PickerStyle}
              onValueChange={(itemvalue) => {
                this.setState({
                  SelectedSpec: itemvalue
                }, () => { this.getDocBasePatientVisit() }
                )
              }}
            >
              {this.state.SpecList.length > 0 ? this.state.SpecList.map((list) => (
                <Picker.Item label={Specialisation(list.role_name)} value={list.role_name} />
              )) : null}
            </Picker>
            <View style={{ margin: '1vw' }}>
              <TouchableOpacity onPress={this.getDocBasePatientVisitExcel}>
                <Tooltip title={'Export to Excel'}>
                  <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
                </Tooltip>
              </TouchableOpacity>
            </View>
          </View>

        </View>

        {this.state.TotalPatients.length > 0 ?
          <View style={{ padding: '1.5vw' }} >
            <HorizontalBar
              width={'13vw'}
              height={'7vw'}
              data={data}
              options={

                {
                  legend: {
                    display: false,
                  },
                  title: {
                    position: "bottom",
                    display: true,
                    text: ['Doctor Based Patient Visit'],
                    fontColor: ['rgb(4, 183, 177)']
                  },
                  responsive: true,

                }}

            />
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Doctor Based Patient Visit</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

      </View>

    )
  }
}

export default DocBasePatientVisit;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '9vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PieChart: {
    width: '30vw',
    height: '40vh'
  },
  TopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5vw'
  },
  RoleNameView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10vw',
  },
  RoleNameText: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    width: "10vw"
  },
  PickerStyle: {
    height: "2.27vw",
    marginRight: '1vw',
    margin: '0.32vw',
    backgroundColor: "transparent",
    borderRadius: '0.26vw',
    flex: 0.5,
    borderColor: 'rgb(4, 183, 177)',
    width: '10vw',
    fontSize: '0.91vw'
  },
})

