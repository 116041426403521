//import liraries
import React, { Component } from "react";
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    ScrollView,
    TouchableOpacity,
} from "react-native";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
const screenHeight = Dimensions.get("window").height;

import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import { AntenatalChart } from './AntenatalChart';
import { InvestigationChart } from './InvestigationChart';
import { InvestigationChartNew } from './InvestigationChartNew';
import { AntenatalChartNew } from './AntenatalChartNew';

import { ScanObservation } from './ScanObservation';
import { ScanObservationNew } from './ScanObservationNew';
import Arrow from "react-native-vector-icons/FontAwesome5";
import {NavigationTopHeader} from "../../BaseComponent"

const navigationHeaderList=[
    {label:"Antenatal Chart",value:"isAntenatalChart"},
    {label:"Investigation Chart",value:"isInvestigationChart"},
    {label:"Scan Observations",value:"isScanObservation"},
   
]

// create a component
export class ObgynChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            scrollPosition: 0,
            selectedSection: "isAntenatalChart",
            refreshRighSideComponentName: "",

            editHistoryItem: {},
            responseType: "",
            responseMeaasge: "",
            getDataByDateValues: {},

            getAntenatalChartValues: {},
            getInvestigationChartValues: {},
            getScanValues: {},
            hideRemoveIcon: false,
            hideRemoveIconInvestigation: false,
            hideRemoveIconScan: false,
            newItemclear:false


        }
    }



    getDataByDateValues(data, type, hideRemoveIcon = false) {

        var states = this.state;
        if (type == "AntenatalChart") {

            this.setState({
                getAntenatalChartValues: data,
                hideRemoveIcon: hideRemoveIcon

            })
        } else if (type == "InvestigationChart") {
            // states["getInvestigationChartValues"] = data
            this.setState({
                getInvestigationChartValues: data,
                hideRemoveIconInvestigation: hideRemoveIcon

            })
        } else if (type == "ScanObservation") {
            // states["getScanValues"] = data
            this.setState({
                getScanValues: data,
                hideRemoveIconScan: hideRemoveIcon

            })
        }

        // this.setState({
        //     states,
        // //   getDataByDateValues: data,
        // });
    }


    renderComponent() {

        var selectedComponent = this.state.selectedSection;

        var componentLookup = {
            isInvestigationChart: <InvestigationChartNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                renderNewItemClear={this.renderNewItemClear.bind(this)}
                newItemclear={this.state.newItemclear}
                getDataByDateValues={this.getDataByDateValues.bind(this)}
            />,

            isAntenatalChart: <AntenatalChartNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                renderNewItemClear={this.renderNewItemClear.bind(this)}
                newItemclear={this.state.newItemclear}
                getDataByDateValues={this.getDataByDateValues.bind(this)} />,

            isScanObservation: <ScanObservationNew
                refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                editHistory={this.editHistory.bind(this)}
                editHistoryItem={this.state.editHistoryItem}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                renderNewItemClear={this.renderNewItemClear.bind(this)}
                getDataByDateValues={this.getDataByDateValues.bind(this)}
                newItemclear={this.state.newItemclear}

            />
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }


    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    selectedRightSideView(pageName) {
        this.setState({
            selectedSection: pageName
        })
    }

    refreshRighSideComponent(name) {
        this.setState({
            refreshRighSideComponentName: name
        })
    }

    editHistory(item, pageName, lmd, updateHead) {



        var data = {
            pageName: pageName,
            editItem: item,
            lmd: lmd,
            updateHead: updateHead
        }
        // alert(JSON.stringify(data))
        this.setState({
            editHistoryItem: data
        }, () => console.log("INDEX ++" + JSON.stringify(data))
        )


    }


    renderRightSideContent() {
        return (
            <View>
                <View style={{}}>
                    <View style={Style.doctorNotesSectionView}>
                        <AntenatalChart
                            selectedPageName={this.state.selectedSection}
                            patientAppointment={this.state.patientAppointment}
                            patientInfo={this.state.patientInfo}
                            selectedRightSideView={this.selectedRightSideView.bind(this)}
                            editHistory={this.editHistory.bind(this)}
                            refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                            refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                            getUpdatedChartValue={this.state.getAntenatalChartValues}
                            hideRemoveIcon={this.state.hideRemoveIcon}
                            renderNewItemClear={this.renderNewItemClear.bind(this)}

                        />
                    </View>
                </View>
                <View style={Style.doctorNotesSectionView}>
                    <InvestigationChart
                        selectedPageName={this.state.selectedSection}
                        patientAppointment={this.state.patientAppointment}
                        patientInfo={this.state.patientInfo}
                        selectedRightSideView={this.selectedRightSideView.bind(this)}
                        editHistory={this.editHistory.bind(this)}
                        refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                        refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                        showResposeValue={this.showResposeValue.bind(this)}
                        getUpdatedChartValue={this.state.getInvestigationChartValues}
                        hideRemoveIcon={this.state.hideRemoveIconInvestigation}
                        renderNewItemClear={this.renderNewItemClear.bind(this)}


                    />
                </View>
                <View style={Style.doctorNotesSectionView}>
                    <ScanObservation
                        selectedPageName={this.state.selectedSection}
                        patientAppointment={this.state.patientAppointment}
                        patientInfo={this.state.patientInfo}
                        selectedRightSideView={this.selectedRightSideView.bind(this)}
                        editHistory={this.editHistory.bind(this)}
                        refreshRighSideComponent={this.refreshRighSideComponent.bind(this)}
                        refreshRighSideComponentName={this.state.refreshRighSideComponentName}
                        showResposeValue={this.showResposeValue.bind(this)}
                        getUpdatedChartValue={this.state.getScanValues}
                        getUpdatedChartValueChange={this.getDataByDateValues.bind(this)}
                        hideRemoveIcon={this.state.hideRemoveIconScan}
                        renderNewItemClear={this.renderNewItemClear.bind(this)}

                    />
                </View>

            </View>
        )
    }


    successAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: 5, borderColor: "#B8C7B2", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Success size={25} name={"check"} color={"green"} />
                    <Text style={{ fontWeight: "700", color: "green", fontSize: 12, marginLeft: 15 }}>{"Success: "}
                        <Text style={{ fontWeight: "400", color: "green", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
                    <Icon size={25} name={"ban"} color={"#D26560"} />
                    <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
                        <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={25} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }


    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }

    // renderHeaderView = () => {
    //     let { patientInfo, patientAppointment } = this.state;
    //     return (
    //       <View
    //         style={{
    //           width: "100%",
    //           flexDirection: "row",
    //           justifyContent: "space-between",
    //           borderBottomColor: "#E4E8F1",
    //           borderBottomWidth: 1,
    //           paddingHorizontal: 10,
    //           paddingVertical: 15
    //         }}
    //       >
    //         <View style={{ flexDirection: "row" }}>
    //           <View style={{ alignSelf: "center", marginLeft: 5 }}>
    //             <Arrow
    //               name="arrow-left"
    //               size={21}
    //               color={color.black}
    //               onPress={() => {
    //                 this.props.changeScreen('optDoctorNotes')
    //               }}
    //             />
    //           </View>
    //           <View style={{ marginLeft: 20, flexDirection: 'row' }}>

    //             <Text style={{ fontSize: 16, alignSelf: "center" }}>{patientInfo.name || ""}</Text>
    //             {/* <View style={{marginLeft:10}}> */}
    //             <Text
    //           style={{
    //             fontSize: 10,
    //             color: "#ADB4C1",
    //             marginTop: 5,
    //             marginBottom: 5,
    //             marginLeft: 10,
    //             alignSelf: "center"

    //           }}
    //         >
    //           {patientInfo.age || ""} {patientInfo.age ? "|" : null} {patientInfo.dob || "" } {patientInfo.dob ? "|"  : null}
    //           &nbsp;{ patientInfo.mobile_number || "" } { patientInfo.mobile_number ? "|" : null}{patientInfo.name ?patientAppointment.appointment_start_time || "" : null}
    //         </Text>
    //             <Text style={{ fontSize: 10, color: "#ADB4C1", marginLeft: 10, alignSelf: "center" }}>
    //               {patientInfo.last_visit_date ? "Last Visit Date :" : null} {patientInfo.last_visit_date || ""}
    //             </Text>
    //           </View>
    //         </View>
    //       </View>
    //     );
    // }
    changeTabSection(selectedTab){
        // alert(selectedTab)
        this.setState({
            selectedSection:selectedTab
        })
      }



      renderNewItemClear(key,flag) {
          var {newItemclear}=this.state
          if(key=="AntenentalClear"){
            newItemclear=flag

          }else if(key == "InvestigationClear"){
            newItemclear=flag
          }else if(key == "ScanClear"){
            newItemclear=flag
          } 
        this.setState({
          newItemclear
        });
      }

    render() {
        // console.log("######" + JSON.stringify(this.props.patientAppointment))
        // console.log("****" + JSON.stringify(this.props.patientInfo))
        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row" ,backgroundColor:color.applicationBackgroundColor}}>

                <View style={[Style.leftView, { width: "65%",backgroundColor:color.applicationBackgroundColor,height: screenHeight-65 }]}>

                    {/* {this.renderHeaderView()} */}
                    <View style={{ }}> 
                        <NavigationTopHeader
                            changeTabSection={this.changeTabSection.bind(this)}
                            navigationHeaderList={navigationHeaderList}
                            selectedTab={this.state.selectedSection} />
                    </View>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", paddingHorizontal: 2 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        {this.renderRightSideContent()}
                    </ScrollView>
                </View>
                <View style={{ padding: 20, width: "35%", height: screenHeight-65 , backgroundColor: color.themeShade, }}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                    >
                        {this.renderComponent()}
                    </ScrollView>
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
});

//make this component available to the app
