import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
var Messages = require('../../../../utils/InfoMessages')
const screenName = "Adhd Rating Scale - IV"
const ratingScaleRadioButtonSize = 20;
export class AdhdRatingScale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofAdhdRatingScale: {},
            comments: "",
            totalScore: 0,
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId,

            totalScoreOfIA:0,
            totalScoreOfHI:0
        }
    }

    componentDidMount() {
        this.getAdhdRatingScale()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getAdhdRatingScale()
        })
    }

    renderIAandHIScores(){
        return(
            <View style={{flexDirection:'row',justifyContent:'space-evenly',paddingVertical:10,borderTopColor:"#888888",borderTopWidth:1}}>
                <View style={{flexDirection:'row'}}>
                <Text style={{flex:0.9}}>{"IA sub-scale raw score"}</Text>
                <Text style={{flex:0.1}}>{this.state.totalScoreOfIA}</Text>
                </View>

                <View style={{flexDirection:'row'}}>
                <Text style={{flex:0.9}}>{"HI sub-scale raw score"}</Text>
                <Text style={{flex:0.1}}>{this.state.totalScoreOfHI}</Text>
                </View>
            </View>
        )
    }

    getAdhdRatingScale() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL + 
        "?patient_id="+this.state.patientId+
        "&appointment_id="+this.state.appointmentId+
        "&rating_scale_name="+screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getAdhdRatingScaleSuccess,
            this.getAdhdRatingScaleFailure
        );
    }

    getAdhdRatingScaleSuccess = (success) => {
        if (success.status == "success") {
            console.log(JSON.stringify(success))
            this.setState({
                listofAdhdRatingScale: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score,
                getTotalScore:success.data.total_score,
                totalScoreOfHI:success.data.HI_score,
                totalScoreOfIA:success.data.IA_score

            })
        }
    }
    getAdhdRatingScaleFailure = error => { }


    validateTotalScores() {
        var { listofAdhdRatingScale } = this.state;
        var getListOfQuestions = listofAdhdRatingScale.questions;
        var getTotalScore = 0;
        var getTotalScoreOfIA=0;
        var getTotalScoreOfHI=0;
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        

                        if (getOptions[j].is_selected) {
                            var number = i+1
                            if(number % 2 == 0) {
                                getTotalScoreOfHI=getTotalScoreOfHI+getOptions[j].option_score;
                            }
                            else {
                                getTotalScoreOfIA=getTotalScoreOfIA+getOptions[j].option_score;
                            }
                            getTotalScore = getTotalScore+getOptions[j].option_score
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore: getTotalScore,
            totalScoreOfIA:getTotalScoreOfIA,
            totalScoreOfHI:getTotalScoreOfHI,
        })
    }


    saveAdhdRatingScale() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var data = {
            "id": this.state.listofAdhdRatingScale.id ? this.state.listofAdhdRatingScale.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofAdhdRatingScale.rating_scale_id,
            "rating_scale_name": this.state.listofAdhdRatingScale.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "questions": this.state.listofAdhdRatingScale.questions,
            "HI_score": this.state.totalScoreOfHI,
            "IA_score": this.state.totalScoreOfIA
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveAdhdRatingScaleSuccess,
            this.saveAdhdRatingScaleFailure
        );
    }else{
        this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
    }
    }

    saveAdhdRatingScaleSuccess = success => {
        if (success.status == "success") {
            this.getAdhdRatingScale()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveAdhdRatingScaleFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(indexOfQuestion, indexOfOptions) {
        var { listofAdhdRatingScale } = this.state;
        var getListOfOptions = listofAdhdRatingScale.questions[indexOfQuestion].options;
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        listofAdhdRatingScale.questions[indexOfQuestion]["options"] = prepareData
        this.setState({ listofAdhdRatingScale }, () => {
            this.validateTotalScores()
        })

    }

    // renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
    //     return (
    //         <View key={indexOfOptions} >
    //             <TouchableOpacity
    //                 onPress={() => {
    //                     this.onselectOptions(indexOfQuestion, indexOfOptions)
    //                 }}
    //                 style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
    //                 <View style={{ flex: 0.05 }} >
    //                     <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={24} />
    //                 </View>
    //                 <View style={{ flex: 0.95 }} >
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    renderListOfDatas(questions, indexOfQuestion) {
        var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
        return (
            <View style={{ marginVertical: 8 }} key={indexOfQuestion}>
                <View key={indexOfQuestion} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{getQuestion}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,0)
                        }}>
                            <CheckBoxIcon name={questions.options[0].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,1)
                        }}>
                            <CheckBoxIcon name={questions.options[1].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,2)
                        }}>
                            <CheckBoxIcon name={questions.options[2].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(indexOfQuestion,3)
                        }}>
                            <CheckBoxIcon name={questions.options[3].is_selected ? "check-circle-outline" :  "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            </View>
        )
    }


    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                </View>            
                </View>
        )
    }

    // renderTotalScoreAndComments() {
    //     return (
    //         <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
    //             <View style={{ flex: 0.2, backgroundColor: color.themeDark, borderRadius: 5,alignItems: 'center', justifyContent: "center" }}>
    //                 <Text style={{ color: color.white ,textAlign:'center'}}>{"Total sub-scale raw score"}</Text>
    //                 {/* <View style={{ height: 40, width: 1, backgroundColor: color.white }} /> */}
    //                 <Text style={{ color: color.white,fontSize:15 }}>{"90"}</Text>
    //             </View>
    //             <View style={{ flex: 0.02 }}></View>
    //             <View style={{ flex: 0.2, backgroundColor: color.themeDark, borderRadius: 5,alignItems: 'center', justifyContent: "center" }}>
    //                 <Text style={{ color: color.white ,textAlign:'center'}}>{"Total percentile raw score"}</Text>
    //                 {/* <View style={{ height: 40, width: 1, backgroundColor: color.white }} /> */}
    //                 <Text style={{ color: color.white,fontSize:15 }}>{"100"}</Text>
    //             </View>
                
    //             <View style={{ flex: 0.02 }}></View>

    //             <View style={{ flex: 0.56, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
    //                 <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
    //                 <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
    //                 <TextInput
    //                     multiline={true}
    //                     // numberOfLines={3}
    //                     style={{ color: color.white }}
    //                     value={this.state.comments}
    //                     onChangeText={(txt) => {
    //                         this.setState({ comments: txt })
    //                     }}
    //                 />
    //             </View>
    //             </View>
    //     )
    // }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row',  borderBottomColor: "#888888", borderBottomWidth: 1,  paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 0.6 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={[styles.radioButtonOptions5,{flex: 0.3}]}>
                        <Text style={styles.alignOptions} numberOfLines={5}>{"Never or rarely"}</Text>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <Text style={styles.alignOptions} numberOfLines={5}>{"Sometimes"}</Text>
                    </View>
                    <View style={[styles.radioButtonOptions5,{flex: 0.20}]}>
                        <Text style={styles.alignOptions} numberOfLines={5}>{"Often"}</Text>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <Text style={styles.alignOptions} numberOfLines={5}>{"Very Often"}</Text>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("ADHD Rating Scale - IV")}
                        {
                            this.state.listofAdhdRatingScale && this.state.listofAdhdRatingScale.questions && this.state.listofAdhdRatingScale.questions.length > 0 ?
                                this.state.listofAdhdRatingScale.questions.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        }

                        {this.renderIAandHIScores()}
                        <View style={{ backgroundColor: color.applicationBackgroundColor,height:60,justifyContent:'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveAdhdRatingScale.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    },
    radioButtonOptions5:{
        flex: 0.25,alignItems: "center",justifyContent:"center" 
    },
    alignOptions:{
        fontWeight: '500', fontSize: 14, color: "#292929",alignItems:'center',textAlign:'center'
    },

})