import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
var Messages = require('../../../../utils/InfoMessages')
const screenName = "Yale-Brown Obsessive Compulsive Scale"

export class YaleBrownObsessiveCompulsiveRatingScale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofYaleBrownObsessive: {},
            comments: "",
            totalScore: 0,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            listofYaleBrownObsessiveWithOutHeader: [],
            obsessionsQuestions: [],
            compulsionQuestions: [],
        }
    }

    componentDidMount() {
        this.getYaleBrownObsessive()
    }

    refreshComponent(appointmentId) {
        this.setState({
            appointmentId: appointmentId
        }, () => {
            this.getYaleBrownObsessive()
        })
    }

    getYaleBrownObsessive() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL +
            "?patient_id=" + this.state.patientId +
            "&appointment_id=" + this.state.appointmentId +
            "&rating_scale_name=" + screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getYaleBrownObsessiveSuccess,
            this.getYaleBrownObsessiveFailure
        );
    }


    getYaleBrownObsessiveSuccess = (success) => {
        if (success.status == "success") {

            // listofYaleBrownObsessiveWithOutHeader
            var getListOfQuestionWithoutHeader = [];
            var getObsessionsQuestions = [];
            var getCompulsionQuestions = [];
            if (success.data.questions.length > 0) {
                var questions = success.data.questions;
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].question_heading == "") {
                        getListOfQuestionWithoutHeader.push(questions[i]);
                    } else if (questions[i].question_heading == "CY-BOCS OBSESSIONS CHECKLIST") {
                        getObsessionsQuestions.push(questions[i]);
                        console.log("getObsessionsQuestions **  " + JSON.stringify(questions[i]))

                    } else if (questions[i].question_heading == "CY-BOCS COMPULSIONS CHECKLIST") {
                        getCompulsionQuestions.push(questions[i]);
                    }
                }
            }



            this.setState({
                listofYaleBrownObsessiveWithOutHeader: getListOfQuestionWithoutHeader,
                obsessionsQuestions: getObsessionsQuestions,
                compulsionQuestions: getCompulsionQuestions,
                listofYaleBrownObsessive: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score
            })
        }
    }
    getYaleBrownObsessiveFailure = error => { }


    validateTotalScores() {
        var { listofYaleBrownObsessive } = this.state;
        var getListOfQuestions = listofYaleBrownObsessive.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            getTotalScore = getTotalScore + getOptions[j].option_score
                        }
                    }
                }
            }
        }
        this.setState({
            totalScore: getTotalScore
        })
    }


    saveYaleBrownObsessive() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if (this.state.totalScore >= 0 && this.state.totalScore !== null) {

            var combainedArrays = [];
            combainedArrays.push(...this.state.listofYaleBrownObsessiveWithOutHeader, ...this.state.obsessionsQuestions, ...this.state.compulsionQuestions)

            var data = {
                "id": this.state.listofYaleBrownObsessive.id ? this.state.listofYaleBrownObsessive.id : "",
                "patient_id": this.state.patientId,
                "appointment_id": this.state.appointmentId,
                "rating_scale_id": this.state.listofYaleBrownObsessive.rating_scale_id,
                "rating_scale_name": this.state.listofYaleBrownObsessive.rating_scale_name,
                "total_score": this.state.totalScore,
                "comments": this.state.comments,
                "questions": combainedArrays
            }
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.saveYaleBrownObsessiveSuccess,
                this.saveYaleBrownObsessiveFailure
            );
        } else {
            this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
        }

    }

    saveYaleBrownObsessiveSuccess = success => {
        if (success.status == "success") {
            this.getYaleBrownObsessive()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveYaleBrownObsessiveFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(key, indexOfQuestion, indexOfOptions) {


        var states = this.state;
        var getDatas = states[key];

        // var { listofYaleBrownObsessive } = this.state;

        // var getListOfOptions = getDatas.questions[indexOfQuestion].options;
        var getListOfOptions = getDatas[indexOfQuestion].options;

        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        // getDatas.questions[indexOfQuestion]["options"] = prepareData
        getDatas[indexOfQuestion]["options"] = prepareData
        this.setState({ states }, () => {
            this.validateTotalScores()
        })

    }

    renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
        return (
            <View key={indexOfOptions} >
                <TouchableOpacity
                    onPress={() => {
                        this.onselectOptions("listofYaleBrownObsessiveWithOutHeader", indexOfQuestion, indexOfOptions)
                    }}
                    style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 0.05 }} >
                        <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                    </View>
                    <View style={{ flex: 0.95 }} >
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    renderListOfDatas(questions, indexOfQuestion) {
        var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
        return (
            <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
                <CommonHistorySectionHeader
                    heading1={getQuestion}
                    // question={questions.question_label}
                    headerKey={"RatingScale"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                {
                    questions.question_description ?
                        <Text style={[Style.historyHeaderFontStyleStartText, { fontSize: 14, marginVertical: 5 }]}>{questions.question_description}</Text>
                        : null
                }

                {
                    questions.options.length > 0 ?
                        questions.options.map((item, index) => {
                            return (
                                this.renderListOfOptions(item, index, indexOfQuestion)
                            )
                        })
                        : null
                }
            </View>
        )
    }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white, width: "90%" }}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                    {/* <Text style={{color:color.white}}>{this.state.}</Text> */}
                </View>
                {/* <Text>{this.state.totalScore}</Text>
                <Text>{this.state.comments}</Text> */}
            </View>
        )
    }

    renderNextHeader(header) {
        return (
            <View style={{ flexDirection: 'row', paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
            </View>
        )
    }
    // renderNextHeader(header) {
    //     return (
    //         <View style={{ flexDirection: 'row', borderTopColor: "#888888", borderBottomColor: "#888888", borderBottomWidth: 1, borderTopWidth: 1, paddingVertical: 12, alignItems: 'center' }}>
    //             <View style={{ flex: 0.7 }}>
    //                 <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
    //             </View>
    //             <View style={{ flex: 0.3, flexDirection: 'row' }}>
    //                 <View style={{ flex: 0.5 }}>
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Current"}</Text>
    //                 </View>
    //                 <View style={{ flex: 0.5 }}>
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{"Past"}</Text>
    //                 </View>
    //             </View>
    //         </View>
    //     )
    // }

    onChangeTextSubheading(key, value, indexOfQuestion, indexOfOptions) {
        var states = this.state;
        var getDatas = states[key];
        var getListOfOptions = getDatas[indexOfQuestion].options[indexOfOptions];

        getListOfOptions["option_value"] = value

        // getListOfOptions=value;

        this.setState({ states })
    }

    renderListOfObsessionsQuestions(key, item, index) {
        if (item.question_type == "Textbox") {

            // "TARGET SYMPTOM LIST FOR OBSESSIONS" 
            // "TARGET SYMPTOM LIST FOR COMPULSION"
            var txtValue = item.options[0].option_value;
            console.log("yesy -----------> " + txtValue);
            return (

                <View key={index} style={{ flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center', marginVertical: 5 }}>
                    <View>
                        <Text style={[Style.historyHeaderFontStyleStartText, { fontSize: 15 }]}>{item.question_label}</Text>
                    </View>
                    <TextInput
                        // multiline={true}
                        // numberOfLines={3}
                        style={{ paddingLeft: 10, color: color.black, height: 50, width: 200, borderRadius: 4, borderWidth: 1, borderColor: "#888888" }}
                        value={txtValue}
                        onChangeText={(txt) => {
                            this.onChangeTextSubheading(key, txt, index, 0)
                            // this.setState({ comments: txt })
                        }}
                    />
                </View>

            )
        } else {
            return (
                <View key={index} style={{ flexDirection: 'row', paddingVertical: 6, borderBottomColor: "#D1D1D1", borderBottomWidth: 0.5 }}>
                    <View style={{ flex: 0.7 }}>
                        <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{item.question_label}</Text>
                    </View>
                    <View style={{ flex: 0.3, flexDirection: 'row' }}>
                        <View style={{ flex: 0.5 }}>
                            <TouchableOpacity onPress={() => {

                                this.onselectOptions(key, index, 0)
                            }}>
                                <CheckBoxIcon name={item.options[0].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                            </TouchableOpacity>
                        </View>
                        <View style={{ flex: 0.5 }}>
                            <TouchableOpacity onPress={() => {
                                this.onselectOptions(key, index, 1)
                            }}>
                                <CheckBoxIcon name={item.options[1].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            )
        }
    }

    render() {
        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {this.renderNextHeader("Yale-Brown Obsessive Compulsive Scale")}

                        {/* listofYaleBrownObsessiveWithOutHeader:[],
                        obsessionsQuestions:[],
                        compulsionQuestions:[] */}
                        {
                            this.state.listofYaleBrownObsessiveWithOutHeader && this.state.listofYaleBrownObsessiveWithOutHeader.length > 0 ?
                                this.state.listofYaleBrownObsessiveWithOutHeader.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        }


                        {
                            this.state.obsessionsQuestions && this.state.obsessionsQuestions.length > 0 ?
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 0.69 }}>
                                        {this.renderNextHeader("CY-BOCS OBSESSIONS CHECKLIST")}
                                    </View>
                                    <View style={{ flex: 0.15 }}>
                                        {this.renderNextHeader("Current")}
                                    </View>
                                    <View style={{ flex: 0.15 }}>
                                        {this.renderNextHeader("Past")}
                                    </View>
                                </View>
                                : null
                        }
                        {
                            this.state.obsessionsQuestions && this.state.obsessionsQuestions.length > 0 ?

                                this.state.obsessionsQuestions.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions("obsessionsQuestions", item, index)
                                })
                                : null
                        }


                        {
                            this.state.compulsionQuestions && this.state.compulsionQuestions.length > 0 ?
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 0.69 }}>
                                        {this.renderNextHeader("CY-BOCS COMPULSIONS CHECKLIST")}
                                    </View>
                                    <View style={{ flex: 0.15 }}>
                                        {this.renderNextHeader("Current")}
                                    </View>
                                    <View style={{ flex: 0.15 }}>
                                        {this.renderNextHeader("Past")}
                                    </View>
                                </View>
                                : null
                        }
                        {
                            this.state.compulsionQuestions && this.state.compulsionQuestions.length > 0 ?

                                this.state.compulsionQuestions.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions("compulsionQuestions", item, index)
                                })
                                : null

                        }


                        <View style={{ backgroundColor: color.applicationBackgroundColor, height: 60, justifyContent: 'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveYaleBrownObsessive.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})