//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import moment from "moment";

// create a component
const pageName = "isDiabeticHistory"

export class DiabeticHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofDiabeticHistory: {},

            hideEditIcon:true
        }
    }

    componentDidMount() {
        this.getDiabeticHistory()



    }

    getDiabeticHistory() {
        var service_url = Constants.DIABETIC_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDiabeticHistorySuccess,
            this.getDiabeticHistoryFailure
        );
    }


    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    getDiabeticHistorySuccess = success => {
        if (success.status == "success") {

            var data = success.data

            var booleanValues = []
            var stringVaues = []

            var hideEditIcon = true

            Object.keys(data).forEach((key) => {

                if(data[key]){
                    hideEditIcon=false
                }


                if (typeof data[key] == "boolean") {
                    let prepareData = {}
                    if (key == "new_diabetes_diagnosed") {
                        prepareData = {
                            key: "New to diagnosis?",
                            value: data[key] ? "Yes" : "No",
                        }
                    } else if (key == "blood_glucose_check") {
                        prepareData = {
                            key: "Do you check your blood glucose?",
                            value: data[key] ? "Yes" : "No",
                            details: data.bg_check_frequency
                        }
                    } else if (key == "low_blood_glucose") {
                        prepareData = {
                            key: "Do you get low blood glucose?",
                            value: data[key] ? "Yes" : "No",
                             details: data.low_bg_date_time
                        }
                    }

                    booleanValues.push(prepareData)
                } else if (key !== "low_bg_date_time" || key !== "bg_check_frequency") {
                    let prepareData = {}
                    var year=""
                    if (data[key]==1){
                        year=" year"
                    }else{
                        year=" years"
                    }
                    if (key == "diabetes_duration") {
                        
                        prepareData = {
                            key: "How many years have you had diabetes?",
                            value:data[key]? data[key]+year:''
                        }
                    } else if (key == "diabetes_family_history") {
                        prepareData = {
                            key: "Who in your family has/had diabetes?",
                            value: data[key],
                        }
                    } else if (key == "diabetes_type") {
                        var type =""
                        if(data[key] == 1)
                            type = "Type 1";
                        else if(data[key] == 2)
                            type = "Type 2";
                        else if(data[key] == 0)
                            type = "Not Sure";

                        prepareData = {
                            key: "What type of diabetes do you have?",
                            value: type,
                        }
                    } else if (key == "treatment_method") {
                        prepareData = {
                            key: "How is your diabetes treated?",
                            value: data[key],
                        }
                    } else if (key == "bg_check_meter") {
                        prepareData = {
                            key: "Name of the blood glucose meter",
                            value: data[key],
                        }
                    } else if (key == "bg_usual_result") {
                        prepareData = {
                            key: "Usual Result",
                            value: data[key]?data[key]+ " mg/dl ":"",
                        }
                    } else if (key == "last_a1c_result") {
                        prepareData = {
                            key: "Last A1c Result, Date?",
                            value: data[key]? data[key]+ " % ":"",
                             details:data.last_ac1_date?moment( data.last_ac1_date).format('DD-MM-YYYY'):null
                        }
                    }
                    stringVaues.push(prepareData)
                }
            })



            var concatBothList = booleanValues.concat(stringVaues)

            var removeEmptyObj = this.removeEmptyObject(concatBothList)

          //  console.log("success _________> " + JSON.stringify(removeEmptyObj))
            this.setState({
                hideEditIcon:hideEditIcon,
                getListofDiabeticHistory: data,
                listOfDatas: removeEmptyObj
            })


        }
    }
    getDiabeticHistoryFailure = error => {
        console.log("success _________> error" + JSON.stringify(error))

    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getDiabeticHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={3}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        // alert(JSON.stringify(item))
        return (
            <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
        )
    }
    

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
        // setTimeout(() => {
        //     this.editHistory()
        // }, 100);
    }
    editHistory() {
        this.props.selectedRightSideView(pageName);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofDiabeticHistory, pageName)
        }, 100);
        
    }

    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading
                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    editHistory={this.editHistory.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName} heading={"Diabetic History"}
                    editImage={this.state.hideEditIcon ? "" :"pencil"}
                />

                {this.renderContent()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
