import React, { Component } from 'react';
// import * as React from 'react';

import { View, Text, StyleSheet, Dimensions, TouchableOpacity, FlatList, Image, ScrollView } from 'react-native';
// import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
// import { Constants } from "../../../utils/Constants"
// const screenHeight = Dimensions.get("window").height;
// const screenWidth = Dimensions.get("window").width;
// import SelectedButton from "../../../components/SelectedButton";
// import { color } from '../../../styles/Color'
// import {AsyncStorage} from '../../../AsyncStorage'
// import OpthamologyService from "../../../network/OpthamologyService";
// const pageName = "glassPrescription";

// import GlassPrescriptionModal from './GlassPrescriptionModal'


export default class GlassPrescriptionModalDatas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PRISM: this.props.PRISM,
      BCNVA: this.props.BCNVA,
      BCDVA: this.props.BCDVA,
      ADD: this.props.ADD,
      AXIS: this.props.AXIS,
      CYL: this.props.CYL,
      SPH: this.props.SPH,
      UCVA: this.props.UCVA,
      vision: this.props.vision,
      base: this.props.base,


      PRISMValueRight: "",
      BCNVAValueRight: "",
      BCDVAValueRight: "",
      ADDValueRight: "",
      AXISValueRight: "",
      CYLValueRight: "",
      SPHValueRight: "",
      UCVAValueRight: "",
      baseValueRight: "",
      visionValueRight: "",

      PRISMValueLeft: "",
      BCNVAValueLeft: "",
      BCDVAValueLeft: "",
      ADDValueLeft: "",
      AXISValueLeft: "",
      CYLValueLeft: "",
      SPHValueLeft: "",
      UCVAValueLeft: "",
      baseValueLeft: "",
      visionValueLeft: "",

      eyeTypeLeft: this.props.eyeTypeLeft,
      eyeTypeRight: this.props.eyeTypeRight,
      scrollPosition:200,

      // signs
      selectedSPHSign: "+",
      selectedCYLSign: "+"
    };
    
  }

  async componentDidMount() {
    var eyeData = await this.props.eyeDatas(); 

        // if(rightEyeData && leftEyeData){ 
        // alert("test"+JSON.stringify(eyeData.right))
        // alert("test"+JSON.stringify(eyeData.left))
        this.highlightSelectedData(this.props.rightEyeData,this.props.leftEyeData)


        // if (eyeData.right && eyeData.right.SPHValueRight) {
        //     // alert("4! sph"+eyeData.right.SPHValueRight)

        //     this.setState({
        //         SPHValueRight:eyeData.right.SPHValueRight
        //     })
        // //   this.state.SPHValueRight = this.props.rightEyeData.SPHValueRight;
        // }

    // }
    // this.highlightSelectedData(rightEyeData,leftEyeData)
}



highlightSelectedData(rightEyeData,leftEyeData) {
    // alert("1")
    // if (this.props.componentFrom === "Right") {
    // alert("2")

        // console.log("right--->"+JSON.stringify(this.props.rightEyeData))
      if (rightEyeData) {
        // alert("3"+JSON.stringify(rightEyeData))

        let UCVAValueRight = ""
        let CYLValueRight = "" 
        let SPHValueRight = "" 
        let AXISValueRight = "" 
        let ADDValueRight = "" 
        let BCDVAValueRight = ""
        let BCNVAValueRight = "" 
        let PRISMValueRight = ""
        let visionValueRight = "" 
        let baseValueRight = ""


        if (rightEyeData.UCVAValueRight) {
            // alert("4!")
            // this.setState({
            //     UCVAValueRight:rightEyeData.UCVAValueRight
            // })
            UCVAValueRight = rightEyeData.UCVAValueRight
        //   this.state.UCVAValueRight = this.props.rightEyeData.UCVAValueRight;
        }
        if (rightEyeData.CYLValueRight) {
            // this.setState({
            //     CYLValueRight:rightEyeData.CYLValueRight
            // })
            CYLValueRight = rightEyeData.CYLValueRight
        //   this.state.CYLValueRight = this.props.rightEyeData.CYLValueRight;
        }
        if (rightEyeData.AXISValueRight) {
            // this.setState({
            //     AXISValueRight:rightEyeData.AXISValueRight
            // })
            AXISValueRight = rightEyeData.AXISValueRight
        //   this.state.AXISValueRight = this.props.rightEyeData.AXISValueRight;
        }
        if (rightEyeData.ADDValueRight) {
            // this.setState({
            //     ADDValueRight:rightEyeData.ADDValueRight
            // })
            ADDValueRight = rightEyeData.ADDValueRight
        //   this.state.ADDValueRight = this.props.rightEyeData.ADDValueRight;
        }
        if (rightEyeData.BCDVAValueRight) {
            // this.setState({
            //     BCDVAValueRight:rightEyeData.BCDVAValueRight
            // })
            BCDVAValueRight = rightEyeData.BCDVAValueRight
        //   this.state.BCDVAValueRight = this.props.rightEyeData.BCDVAValueRight;
        }
        if (rightEyeData.BCNVAValueRight) {
            // this.setState({
            //     BCNVAValueRight:rightEyeData.BCNVAValueRight
            // })
            BCNVAValueRight = rightEyeData.BCNVAValueRight
        //   this.state.BCNVAValueRight = this.props.rightEyeData.BCNVAValueRight;
        }
        if (rightEyeData.PRISMValueRight) {
            // this.setState({
            //     PRISMValueRight:rightEyeData.PRISMValueRight
            // })
            PRISMValueRight = rightEyeData.PRISMValueRight
        //   this.state.PRISMValueRight = this.props.rightEyeData.PRISMValueRight;
        }
        if (rightEyeData.SPHValueRight) {
            // alert("4! sph"+rightEyeData.SPHValueRight)

            // this.setState({
            //     SPHValueRight:rightEyeData.SPHValueRight
            // }) 
            SPHValueRight = rightEyeData.SPHValueRight
        //   this.state.SPHValueRight = this.props.rightEyeData.SPHValueRight;
        }
        if (rightEyeData.visionValueRight) {
            // this.setState({
            //     visionValueRight:rightEyeData.visionValueRight
            // })
            visionValueRight  = rightEyeData.visionValueRight
        //   this.state.visionValueRight = this.props.rightEyeData.visionValueRight;
        }
        if (rightEyeData.baseValueRight) {
          // this.state.eyeTypeLeft
          // this.state.eyeTypeRight
            // this.setState({
            //     baseValueRight:rightEyeData.baseValueRight
            // })
            baseValueRight = rightEyeData.baseValueRight
        //   this.state.baseValueRight = this.props.rightEyeData.baseValueRight;
        }

        this.setState({
          eyeTypeRight:rightEyeData.eyeTypeRight, 
          UCVAValueRight: UCVAValueRight, 
          CYLValueRight: CYLValueRight, 
          SPHValueRight: SPHValueRight, 
          AXISValueRight: AXISValueRight, 
          ADDValueRight: ADDValueRight, 
          BCDVAValueRight: BCDVAValueRight, 
          BCNVAValueRight: BCNVAValueRight, 
          PRISMValueRight: PRISMValueRight, 
          visionValueRight: visionValueRight,
          baseValueRight: baseValueRight
        })
      }
    // }
    // if (this.props.componentFrom === "Left") {
      // console.log("BCDVA ------------> "+JSON.stringify(leftEyeData.BCDVAValueLeft))
      if (leftEyeData) {

        let UCVAValueLeft = "";
        let SPHValueLeft = ""
        let CYLValueLeft = ""
        let AXISValueLeft =""
        let ADDValueLeft ="" 
        let BCDVAValueLeft =""
        let BCNVAValueLeft = ""
        let PRISMValueLeft =""
        let visionValueLeft = "" 
        let baseValueLeft = ""


        if (leftEyeData.UCVAValueLeft) {
          UCVAValueLeft = leftEyeData.UCVAValueLeft;
        }
        if (leftEyeData.CYLValueLeft) {
          CYLValueLeft = leftEyeData.CYLValueLeft;
        }
        if (leftEyeData.AXISValueLeft) {
          AXISValueLeft = leftEyeData.AXISValueLeft;
        }
        if (leftEyeData.ADDValueLeft) {
          ADDValueLeft = leftEyeData.ADDValueLeft;
        }
        if (leftEyeData.BCDVAValueLeft) {
          BCDVAValueLeft = leftEyeData.BCDVAValueLeft;
        }
        if (leftEyeData.BCNVAValueLeft) {
          BCNVAValueLeft = leftEyeData.BCNVAValueLeft;
        }
        if (leftEyeData.PRISMValueLeft) {
          PRISMValueLeft = leftEyeData.PRISMValueLeft;
        }
        if (leftEyeData.SPHValueLeft) {
          SPHValueLeft = leftEyeData.SPHValueLeft;
        }
        if (leftEyeData.visionValueLeft) {
          visionValueLeft = leftEyeData.visionValueLeft;
        }
        if (leftEyeData.baseValueLeft) {
          baseValueLeft = leftEyeData.baseValueLeft;
        }
        this.setState({
          eyeTypeLeft: rightEyeData.eyeTypeLeft,
          UCVAValueLeft : UCVAValueLeft,
          SPHValueLeft: SPHValueLeft,
          CYLValueLeft: CYLValueLeft,
          AXISValueLeft: AXISValueLeft,
          ADDValueLeft: ADDValueLeft,
          BCDVAValueLeft: BCDVAValueLeft,
          BCNVAValueLeft: BCNVAValueLeft,
          PRISMValueLeft: PRISMValueLeft,
          visionValueLeft: visionValueLeft,
          baseValueLeft: baseValueLeft
        })
      }
    // }
  }

  _onContentSizeChange() {
    let initialYScroll = this.state.scrollPosition;
      this.scrollView.scrollTo({x: 0, y: initialYScroll, animated: true});
   };

  render() {
    // this.highlightSelectedData();
    return (
      <View>
        {/* <View style={{ flexDirection: 'row', marginTop: 20, marginLeft: 20 }}>
          {
            Constants.type.map((type, inex) => {
              return (
                <TouchableOpacity key={inex} style={{ padding: 8, backgroundColor: (this.state.eyeTypeRight === type.value) || (this.state.eyeTypeLeft === type.value) ? color.themeDark : "white", borderRadius: 30, width: 45, marginRight: 20 ,borderColor:"#888888",borderWidth:1}}
                  onPress={() => { 
                    this.setState({
                      eyeTypeRight:type.value
                    })
                    this.props.changeSelectedData("Eye Type", type.value) 
                  }
                    }>
                  <Text style={{ fontSize: 11, textAlign: 'center', color: (this.state.eyeTypeRight === type.value) || (this.state.eyeTypeLeft === type.value) ? color.white : color.black }}>{type.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View> */}

        <ScrollView style={{ height: '26.04vw' }} showsVerticalScrollIndicator={false}
                //    ref={scrollView => this.scrollView = scrollView}
                //    onContentSizeChange={() => {
                //        this._onContentSizeChange();
                //    }}
                onMomentumScrollEnd
        
        >
          {/* UCVA */}
          <View style={styles.MainViewWrapper}>
            <View style={styles.Wrapper2}>
              {this._renderTitle("UCVA", false)}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.UCVA}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, true, "UCVA")}
                /> */}
               {
                  this.state.UCVA.map((item) => {
                    return this._renderData(item, true, "UCVA")
                  })
                }

              </View>
            </View>

            {/* SPH */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("SPH", true, 'selectedSPHSign')}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.SPH}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, true, "SPH")}
                /> */}
                {
                  this.state.SPH.map((item) => {

                    return this._renderData(item, true, "SPH")
                    // let data = "";
                    // if(this.props.componentFrom === "Right")
                    // {
                    //   data = this.state.selectedSPHSign?this.state.selectedSPHSign:this.state.SPHValueRight?this.state.SPHValueRight.value:"+";
                    // }else{
                    //   data = this.state.selectedSPHSign?this.state.selectedSPHSign:this.state.SPHValueLeft?this.state.SPHValueLeft.value:"+";
                    // }
                    
                    // let firstChar = data?(data).toString().substring(0,1):"+";
                    // let loopthru = (item.value).charAt(0)==firstChar?true:false

                    // return loopthru?this._renderData(item, true, "SPH"):null
                  })
                }

              </View>
            </View>

            {/* CYL */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("CYL", true, 'selectedCYLSign')}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.CYL}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, true, "CYL")}
                /> */}
                {
                  this.state.CYL.map((item) => {
                    return this._renderData(item, true, "CYL") 

                    // let data = "";
                    // if(this.props.componentFrom === "Right")
                    // {
                    //   data = this.state.selectedCYLSign?this.state.selectedCYLSign:this.state.CYLValueRight?this.state.CYLValueRight.value:"+";
                    // }else{
                    //   data = this.state.selectedCYLSign?this.state.selectedCYLSign:this.state.CYLValueLeft?this.state.CYLValueLeft.value:"+";
                    // }
                    
                    // let firstChar = data?(data).toString().substring(0,1):"+";
                    // let loopthru = (item.value).charAt(0)==firstChar?true:false

                    // return loopthru?this._renderData(item, true, "CYL"):null
                  })
                }

              </View>
            </View>


            {/* AXIS */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("AXIS", false)}
              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.AXIS}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "AXIS")}
                /> */}
                {
                 this.state.AXIS.map((item) => {
                    return this._renderData(item, false, "AXIS")
                  })
                }

              </View>
            </View>

            {/* ADD */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("ADD", false)}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.ADD}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, true, "ADD")}
                /> */}

                {
                  this.state.ADD.map((item) => {
                    return this._renderData(item, true, "ADD")
                  })
                }
              </View>
            </View>

            {/* BCDVA */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("BCDVA", false)}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.BCDVA}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "BCDVA")}
                /> */}
                {
                  this.state.BCDVA.map((item) => {
                    return this._renderData(item, false, "BCDVA")
                  })
                }
              </View>
            </View>


            {/* BCNVA */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("BCNVA", false)}

              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.BCNVA}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "BCNVA")}
                />
                 */}
                {
                 this.state.BCNVA.map((item) => {
                    return this._renderData(item, false, "BCNVA")
                  })
                }
              </View>
            </View>

            {/* PRISM */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("Prism", false)}
              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.PRISM}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "Prism")}
                /> */}
                {
                  this.state.PRISM.map((item) => {
                    return this._renderData(item, false, "Prism")
                  })
                }
              </View>
            </View>

            {/* VISION */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("Vision", false)}
              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.vision}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "Vision")}
                /> */}
                {
                 this.state.vision.map((item) => {
                    return this._renderData(item, false, "Vision")
                  })
                }
              </View>
            </View>

            {/* Base */}
            <View style={styles.ValueDataWrapper}>
              {this._renderTitle("Base", false)}
              <View style={styles.ValueDataView}>
                {/* <FlatList
                  data={this.state.base}
                  numColumns={18}
                  scrollEnabled={false}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  containerStyle={{ alignItems: "center", alignContent: "center" }}
                  renderItem={({ item }) => this._renderData(item, false, "Base")}
                /> */}
                {
                  this.state.base.map((item) => {
                    return this._renderData(item, false, "Base")
                  })
                }
              </View>
            </View>

          </View>

        </ScrollView>
        
      </View>
    );
  }


  // renderCardValues(title, value){
  //   return(
  //     <View style={styles.card}>
  //       <Text style={styles.cardTitle}>{title}</Text><Text>{value}</Text>
  //     </View>
  //   );
  // }


  _renderTitle(title, checkflag, keyName="") {
    // this.changeData();

    let states = this.state;

    return (
      <View style={styles.RenderTitle}>
        <View style={{ flex: checkflag?0.5:1 }}>
          <Text style={styles.RenderTitleText}>{title}</Text>
        </View>

        {
          checkflag ?
            <View style={{ flex: 0.5, flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => {

                // if(keyName) {
                //   states[keyName]="+"
                // }
                // this.setState({
                //   states
                // })
                this.props.changeData(title, true);
                // this.setState({flag:true,filterDataFrom:title})
              }}
              >
                <Image
                  source={require("../../../../assets/images/adding.svg")}
                  style={styles.AddBtnImg}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                // this.setState({flag:false,filterDataFrom:title})

                // if(keyName) {
                //   states[keyName]="-"
                // }
                // this.setState({
                //   states
                // })

                this.props.changeData(title, false);
              }}>
                <Image
                  source={require("../../../../assets/images/subtraction.svg")}
                  style={styles.SubtractBtnImg}
                />
              </TouchableOpacity>

            </View>
            :
            null
        }
      </View>
    );
  }


  componentWillReceiveProps(props){
      // alert("props"+JSON.stringify(props.selectedEyeTypeData))
      // console.log(JSON.stringify(props))

      this.setState({
        CYL: props.CYL,
        SPH: props.SPH,
      })
  }

  changeSelectedData = async (from, value) => {
    if (from === "UCVA") {
      if (this.props.componentFrom === "Right") {
        this.setState({ UCVAValueRight: value })
      } else {
        this.setState({ UCVAValueLeft: value })
      }
    } else if (from === "SPH") {
      if (this.props.componentFrom === "Right") {
        this.setState({ SPHValueRight: value })
      } else {
        this.setState({ SPHValueLeft: value })
      }
    } else if (from === "CYL") {
      if (this.props.componentFrom === "Right") {
        this.setState({ CYLValueRight: value })
      } else {
        this.setState({ CYLValueLeft: value })
      }
    } else if (from === "AXIS") {
      if (this.props.componentFrom === "Right") {
        this.setState({ AXISValueRight: value })
      } else {
        this.setState({ AXISValueLeft: value })
      }
    } else if (from === "BCNVA") {
      if (this.props.componentFrom === "Right") {
        this.setState({ BCNVAValueRight: value })
      } else {
        this.setState({ BCNVAValueLeft: value })
      }
    } else if (from === "BCDVA") {
      if (this.props.componentFrom === "Right") {
        this.setState({ BCDVAValueRight: value })
      } else {
        this.setState({ BCDVAValueLeft: value })
      }
    } else if (from === "ADD") {
      if (this.props.componentFrom === "Right") {
        this.setState({ ADDValueRight: value })
      } else {
        this.setState({ ADDValueLeft: value })
      }
    } else if (from === "Prism") {
      if (this.props.componentFrom === "Right") {
        this.setState({ PRISMValueRight: value })
      } else {
        this.setState({ PRISMValueLeft: value })
      }
    } else if (from === "Base") {
      if (this.props.componentFrom === "Right") {
        this.setState({ baseValueRight: value })
      } else {
        this.setState({ baseValueLeft: value })
      }
    } else if (from === "Vision") {
      if (this.props.componentFrom === "Right") {
        this.setState({ visionValueRight: value })
      } else {
        this.setState({ visionValueLeft: value })
      }
    }
  }

  _renderData(data, filter, from) {

    if (from === "UCVA") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          if(this.props.componentFrom === "Right"){
            this.setState({
              UCVAValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                  UCVAValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        }}>
          <Text style={[styles.RenderDataBtnTextDefault ,(this.state.UCVAValueRight == data.value && this.props.componentFrom === "Right") || (this.state.UCVAValueLeft == data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected : ""]}>{data.label}</Text>
          
        </TouchableOpacity>
      );
    } else if (from === "SPH") {

      let SPHValueRight = this.state.SPHValueRight?(parseFloat(this.state.SPHValueRight) || this.state.SPHValueRight):"";
      let SPHValueLeft = this.state.SPHValueLeft?(parseFloat(this.state.SPHValueLeft) || this.state.SPHValueLeft):"";
      let dataValue = data.value?(parseFloat(data.value) || data.value):"";

      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
          if(this.props.componentFrom === "Right"){
            this.setState({
                SPHValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                SPHValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        //   this.props.changeSelectedData(from, data.value)


          // this.props.componentFrom === "Right" ? 
          // this.setState({SPHValueRight:data.value}) : 
          // this.setState({SPHValueLeft:data.value})
        }}>
          {/* rightEyeData */}
          <Text style={[styles.RenderDataBtnTextDefault , (SPHValueRight === dataValue && this.props.componentFrom === "Right") || (SPHValueLeft === dataValue && this.props.componentFrom === "Left") ?  styles.RenderDataBtnTextSelected: ""]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.SPHValueRight!== undefined && this.props.rightEyeData.SPHValueRight === data.value && this.props.componentFrom === "Right") || (this.props.leftEyeData.data.SPHValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "CYL") {

      let CYLValueRight = this.state.CYLValueRight?(parseFloat(this.state.CYLValueRight) || this.state.CYLValueRight):"";
      let CYLValueLeft = this.state.CYLValueLeft?(parseFloat(this.state.CYLValueLeft) || this.state.CYLValueLeft):"";
      let dataValue = data.value?(parseFloat(data.value) || data.value):"";

      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
          if(this.props.componentFrom === "Right"){
            this.setState({
                CYLValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                CYLValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        }}>
          <Text style={[styles.RenderDataBtnTextDefault , (CYLValueRight !== undefined && CYLValueRight === dataValue && this.props.componentFrom === "Right") || (CYLValueLeft === dataValue && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.CYLValueRight!== undefined && this.props.rightEyeData.CYLValueRight === data.value && this.props.componentFrom === "Right") || (this.state.CYLValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "AXIS") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
          if(this.props.componentFrom === "Right"){
            this.setState({
                AXISValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                AXISValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        //   this.props.changeSelectedData(from, data.value)

          // this.props.componentFrom === "Right" ? 
          // this.setState({AXISValueRight:data.value}) :
          // this.setState({AXISValueLeft:data.value})
        }}>
          <Text style={[styles.RenderDataBtnTextDefault  ,(this.state.AXISValueRight !== undefined && this.state.AXISValueRight === data.value && this.props.componentFrom === "Right") || (this.state.AXISValueLeft === data.value && this.props.componentFrom === "Left") ?styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.AXISValueRight!== undefined && this.props.rightEyeData.AXISValueRight === data.value && this.props.componentFrom === "Right") || (this.state.AXISValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "BCNVA") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
          if(this.props.componentFrom === "Right"){
            this.setState({
                BCNVAValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                BCNVAValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        //   this.props.changeSelectedData(from, data.value)

          // this.props.componentFrom === "Right" ? 
          // this.setState({BCNVAValueRight:data.value}) : 
          // this.setState({BCNVAValueLeft:data.value})
        }}>
          <Text style={[styles.RenderDataBtnTextDefault , (this.state.BCNVAValueRight !== undefined && this.state.BCNVAValueRight === data.value && this.props.componentFrom === "Right") || (this.state.BCNVAValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.BCNVAValueRight!== undefined && this.props.rightEyeData.BCNVAValueRight === data.value && this.props.componentFrom === "Right") || (this.state.BCNVAValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "BCDVA") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
          if(this.props.componentFrom === "Right"){
            this.setState({
                BCDVAValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                BCDVAValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
        //   this.props.changeSelectedData(from, data.value)

          // this.props.componentFrom === "Right" ? this.setState({BCDVAValueRight:data.value}) :this.setState({BCDVAValueLeft:data.value});
        }}>
          <Text style={[styles.RenderDataBtnTextDefault ,(this.state.BCDVAValueRight !== undefined && this.state.BCDVAValueRight === data.value && this.props.componentFrom === "Right") || (this.state.BCDVAValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.BCDVAValueRight!== undefined && this.props.rightEyeData.BCDVAValueRight === data.value && this.props.componentFrom === "Right") || (this.state.BCDVAValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "ADD") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
        //   this.props.changeSelectedData(from, data.value)
        if(this.props.componentFrom === "Right"){
            this.setState({
                ADDValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                ADDValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
          // this.props.componentFrom === "Right" ? this.setState({ADDValueRight:data.value}) :this.setState({ADDValueLeft:data.value});
        }}>
          <Text style={[styles.RenderDataBtnTextDefault , (this.state.ADDValueRight !== undefined && this.state.ADDValueRight === data.value && this.props.componentFrom === "Right") || (this.state.ADDValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.ADDValueRight!== undefined && this.props.rightEyeData.ADDValueRight === data.value && this.props.componentFrom === "Right") || (this.state.ADDValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
        </TouchableOpacity>
      );
    } else if (from === "Prism") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
        //   this.props.changeSelectedData(from, data.value)
        if(this.props.componentFrom === "Right"){
            this.setState({
                PRISMValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                PRISMValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
          // this.props.componentFrom === "Right" ? this.setState({PRISMValueRight:data.value}) :this.setState({PRISMValueLeft:data.value})
        }}>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.PRISMValueRight!== undefined && this.props.rightEyeData.PRISMValueRight === data.value && this.props.componentFrom === "Right") || (this.state.PRISMValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
          <Text style={[styles.RenderDataBtnTextDefault ,(this.state.PRISMValueRight !== undefined && this.state.PRISMValueRight === data.value && this.props.componentFrom === "Right") || (this.state.PRISMValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: "" ]}>{data.label}</Text>
        </TouchableOpacity>
      );
    } else if (from === "Base") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
        //   this.props.changeSelectedData(from, data.value)
        if(this.props.componentFrom === "Right"){
            this.setState({
                baseValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                baseValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
          // this.props.componentFrom === "Right" ? this.setState({PRISMValueRight:data.value}) :this.setState({PRISMValueLeft:data.value})
        }}>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.PRISMValueRight!== undefined && this.props.rightEyeData.PRISMValueRight === data.value && this.props.componentFrom === "Right") || (this.state.PRISMValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
          <Text style={[styles.RenderDataBtnTextDefault , (this.state.baseValueRight !== undefined && this.state.baseValueRight === data.value && this.props.componentFrom === "Right") || (this.state.baseValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: ""  ]}>{data.label}</Text>
        </TouchableOpacity>
      );
    } else if (from === "Vision") {
      return (
        <TouchableOpacity style={styles.RenderDataBtn} onPress={() => {
          // this.changeSelectedData(from,data.value)
        //   this.props.changeSelectedData(from, data.value)
        if(this.props.componentFrom === "Right"){
            this.setState({
                visionValueRight:data.value
            },()=>{this.props.changeSelectedData(from, data.value)})
          }else if(this.props.componentFrom === "Left"){
              this.setState({
                visionValueLeft:data.value
                },()=>{this.props.changeSelectedData(from, data.value)})
          }
          // this.props.componentFrom === "Right" ? this.setState({PRISMValueRight:data.value}) :this.setState({PRISMValueLeft:data.value})
        }}>
          {/* <Text style={{fontSize:10,color:(this.props.rightEyeData.PRISMValueRight!== undefined && this.props.rightEyeData.PRISMValueRight === data.value && this.props.componentFrom === "Right") || (this.state.PRISMValueLeft === data.value && this.props.componentFrom === "Left") ? "black" :"#797979",}}>{data.label}</Text> */}
          <Text style={[styles.RenderDataBtnTextDefault ,(this.state.visionValueRight !== undefined && this.state.visionValueRight === data.value && this.props.componentFrom === "Right") || (this.state.visionValueLeft === data.value && this.props.componentFrom === "Left") ? styles.RenderDataBtnTextSelected: ""  ]}>{data.label}</Text>
        </TouchableOpacity>
      );
    }
  }
}


{/* <View style={{flexDirection:'row',justifyContent:'space-between',backgroundColor:'white',borderRadius:10,padding: 10,borderColor:'#D1D1D1',borderWidth:1}}>

            <View>
              <Text style={{color:'black',fontWeight:"bold",fontSize:16}}>{"Right Eye"}</Text>
              <View style={{flexDirection:'row'}}>
                {this.renderCardValues("UCVA",this.state.UCVAValueRight)}
                {this.renderCardValues("SPH",this.state.SPHValueRight)}
                {this.renderCardValues("CYL",this.state.CYLValueRight)}
                {this.renderCardValues("AXIS",this.state.AXISValueRight)}
                {this.renderCardValues("ADD",this.state.ADDValueRight)}
                {this.renderCardValues("BCDVA",this.state.BCDVAValueRight)}
                {this.renderCardValues("BCNVA",this.state.BCNVAValueRight)}
                {this.renderCardValues("Prism",this.state.PRISMValueRight)}
                {this.renderCardValues("Base","1")}
              </View>
            </View>
   
            <View>
              <Text style={{color:'black',fontWeight:"bold",fontSize:16}}>{"Left Eye"}</Text>
              <View style={{flexDirection:'row'}}>
                {this.renderCardValues("UCVA",this.state.UCVAValueLeft)}
                {this.renderCardValues("SPH",this.state.SPHValueLeft)}
                {this.renderCardValues("CYL",this.state.CYLValueLeft)}
                {this.renderCardValues("AXIS",this.state.AXISValueLeft)}
                {this.renderCardValues("ADD",this.state.ADDValueLeft)}
                {this.renderCardValues("BCDVA",this.state.BCDVAValueLeft)}
                {this.renderCardValues("BCNVA",this.state.BCNVAValueLeft)}
                {this.renderCardValues("Prism",this.state.PRISMValueLeft)}
                {this.renderCardValues("Base","1")}
              </View>
            </View>
          </View> */}


  // changeData(){
  //   if(this.state.filterDataFrom === "UCVA"){
  //       this.state.UCVA = [];
  //       for(var i=0;i<Constants.UCVA_OPTIONS.length; i++){
  //         var getData = Constants.UCVA_OPTIONS[i];
  //         var filterData=getData.value.substring(0, 1);
  //         if(!this.state.flag && filterData ==="-" ){
  //           this.state.UCVA.push(getData);
  //         }else if(this.state.flag && parseInt(getData.value) >= 0.00 && filterData !=="-"){
  //           this.state.UCVA.push(getData);
  //         }
  //       }
  //   }else if(this.state.filterDataFrom === "SPH"){
  //     this.state.SPH = [];
  //     for(var i=0;i<Constants.SPH_OPTIONS.length; i++){
  //       var getData = Constants.SPH_OPTIONS[i];
  //       var filterData=getData.value.substring(0, 1);
  //       if(!this.state.flag && filterData ==="-" ){
  //         this.state.SPH.push(getData);
  //       }else if(this.state.flag && parseInt(getData.value) >= 0.00 && filterData !=="-"){
  //         this.state.SPH.push(getData);
  //       }
  //     }
  //   }else if(this.state.filterDataFrom === "CYL"){
  //     this.state.CYL = [];
  //     for(var i=0;i<Constants.CYL_OPTIONS.length; i++){
  //       var getData = Constants.CYL_OPTIONS[i];
  //       var filterData=getData.value.substring(0, 1);
  //       if(!this.state.flag && filterData ==="-" ){
  //         this.state.CYL.push(getData);
  //       }else if(this.state.flag && parseInt(getData.value) >= 0.00 && filterData !=="-"){
  //         this.state.CYL.push(getData);
  //       }
  //     }
  //   }else if(this.state.filterDataFrom === "ADD"){
  //     this.state.ADD = [];
  //     for(var i=0;i<Constants.ADD_OPTIONS.length; i++){
  //       var getData = Constants.ADD_OPTIONS[i];
  //       var filterData=getData.value.substring(0, 1);
  //       if(!this.state.flag && filterData ==="-" ){
  //         this.state.ADD.push(getData);
  //       }else if(this.state.flag && parseInt(getData.value) >= 0.00 && filterData !=="-"){
  //         this.state.ADD.push(getData);
  //       }
  //     }
  //   }else if(this.state.filterDataFrom === "AXIS"){

  //   }else if(this.state.filterDataFrom === "BCNVA"){

  //   }else if(this.state.filterDataFrom === "BCDVA"){

  //   }else if(this.state.filterDataFrom === "Prism"){

  //   }
  // }

  // static getDerivedStateFromProps(nextProps,nextState){
  //     alert("nextProps"+JSON.stringify(nextProps.selectedEyeTypeData))
  //     console.log(JSON.stringify(nextProps.selectedEyeTypeData))
  // }

  const styles = StyleSheet.create({
    MainViewWrapper:{
      flexDirection: 'column', 
      marginTop: '1.95vw'
    },
    Wrapper2:{
      flexDirection: 'row', 
      borderBottomColor: "#D1D1D1", 
      borderBottomWidth: 1,
    },
    RenderTitle:{
      flexDirection: 'row', 
      paddingLeft: '0.65vw', 
      borderRightColor: '#ccc',
      borderRightWidth: 1, 
      width: '10%'
    },
    RenderTitleText:{
      color: "#B0B0B0", 
      fontWeight: 'bold', 
      marginTop: '0.45vw',
      fontSize:'1vw'
    },
    AddBtnImg:{
      height: '1.17vw',
      width: '1.17vw',
      marginTop: '0.45vw',
      // tintColor: color.themeDark
    },
    SubtractBtnImg:{
      height: '2.28vw',
      width: '2.28vw', 
      marginTop: '0.06vw'
    },
    ValueDataWrapper:{
      flexDirection: 'row', 
      borderBottomColor: "#D1D1D1", 
      borderBottomWidth: 1
    },
    ValueDataView:{
      flex: 1, 
      flexDirection: 'row', 
      flexWrap: "wrap", 
      paddingLeft: '1.63vw'
    },
    RenderDataBtn:{
      width: '3.25vw', 
      height: '2.27vw', 
      justifyContent: 'center'
    },
    RenderDataBtnTextDefault:{
      fontSize: '0.65vw', 
      color: "#797979"
    },
    RenderDataBtnTextSelected:{
      fontSize: '0.71vw', 
      color: "black",
      fontWeight:'800'
    }
  })