import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';
class SpecializationVisit extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
      SpecVisitData: []
    }
  };
  componentDidMount() {
    this.getSpecVisit()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getSpecVisit()
      })
    }
  }

  /* Specializaion Visit API Start */
  getSpecVisit() {
    var serviceUrl =
      Constants.SPECIALIZATION_VISIT_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getSpecVisitSuccess,
      this.getSpecVisitFailure
    );

  }

  getSpecVisitSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        SpecVisitData: success.data
      })
    }else{
      this.props.Response(success.message)
      console.log(success.message,"hello-------------------")
    }
  };

  getSpecVisitFailure = (error) => {
    this.props.Response(error.message)
  };
  /* Specializaion Visit API end */

  /* Export Excel API start*/
  getSpecializationExcel = () => {
    var serviceUrl =
      Constants.SPECIALIZATION_VISIT_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.exportCSVSuccessCallBack,
      this.getSpecializationFailure,
      "excel"
    );
  }
  exportCSVSuccessCallBack = response => {
    if (response.statusText === "OK") {

      var pom = document.createElement('a');
      var csvContent = response.data; //here we load our csv data 

      let filename = response.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getSpecializationFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/
  render() {
    const data = {
      datasets: [
        {
          data: this.state.SpecVisitData.length > 0 ? this.state.SpecVisitData.map((list) => (list.appointment_count)) : [],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(204, 255, 153)',
            'rgb(97, 207, 126)',
            'rgb(168, 50, 155)',
            'rgb(16, 50, 15)'
          ],
        },
      ],
      labels: this.state.SpecVisitData.length > 0 ? this.state.SpecVisitData.map((list) => (list.role_name == "DOCTOR" ? "GP" : list.role_name)) : [],
    };

    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>

        {this.state.SpecVisitData.length > 0 ?
          <View style={{ flex: 1, paddingTop: '1.5vw' }}>
            <HorizontalBar
              width={'15vw'}
              height={'9vw'}
              data={data}
              options={

                {
                  legend: {
                    display: false,
                  },
                  title: {
                    position: "bottom",
                    display: true,
                    text: ['Specialization Visit'],
                    fontColor: ['rgb(4, 183, 177)']
                  },
                  responsive: true,

                }}

            />
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Specialization Visit</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

        <View style={styles.IconView}>
          <TouchableOpacity onPress={this.getSpecializationExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </View>

      </View>

    )
  }
}

export default SpecializationVisit;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '9vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PieChart: {
    width: '30vw',
    height: '40vh'
  },
  TopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.5vw'
  },
  RoleNameText: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    width: "10vw"
  },
  PickerStyle: {
    height: "2.27vw",
    marginRight: '2vw',
    margin: '0.32vw',
    backgroundColor: "transparent",
    borderRadius: '0.26vw',
    flex: 0.5,
    borderColor: 'rgb(4, 183, 177)',
    width: '5vw',
    fontSize: '0.91vw'
  },
  IconView: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '1vw'
  }
})

