import { Constants } from "../utils/Constants";
// import { AsyncStorage } from "react-native";
import AsyncStorage from "../AsyncStorage";

export default class Service {
  static instance;

  signIn(data, baseClass, successCallback, errorCallback) {
    //console.log("********");
   // console.log(data);
   // console.log(this.he);
    this.callAPI(
      Constants.API_SIGN_IN,
      "POST",
      data,
      successCallback,
      errorCallback,
      baseClass,
      "noHeader"
    );
  }

  homeAppointments(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "header"
    );
  }

  billingList(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "header"
    );
  }

  appointmentComments(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "header"
    );
  }

  searchPatient(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "headerX"
    );
  }

  getClinicList(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "header"
    );
  }
  getDeleteList(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "DELETE",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "header"
    );
  }
  changeAppointmentStatus(url, data, baseClass, successCallback, errorCallback, userType='',callBackData="") {
    this.callAPI(
      url,
      "POST",
      data,
      successCallback,
      errorCallback,
      baseClass,
      "header",
      userType,
      callBackData
    );
  }

  log(response) {
    if (response){}
    // console.log(JSON.stringify(response));
  }

  getPrint(url, baseClass, successCallback, errorCallback) {
    this.callAPI(
      url,
      "GET",
      undefined,
      successCallback,
      errorCallback,
      baseClass,
      "pdf"
    );
  }

  static getInstance() {
    if (this.instance == undefined) {
      this.instance = new Service();
    }
    return this.instance;
  }

  callAPI = async (
    url,
    methodType,
    data,
    successCallback,
    errorCallback,
    baseClass,
    token,
    userType='',
    callBackData=""
  ) => {
    // baseClass._showProgress();
    this.log(
      "URL : " + url + " \nMethod : " + methodType + " \nData : " + data
    );

    fetch(url, {
      method: methodType,
      headers: token === "noHeader"
        ? {
          // Accept: "application/json",
          "Content-Type": "application/json"
        }
        : token === "pdf" ? {
          Authorization:
          "Token " + (await AsyncStorage.getItem("token")).toString(),
          "Content-Type": "application/pdf"
        } : token === "header" ? {
          "Content-Type": "application/json",
          "x-channel": JSON.stringify({'user_role': userType}),
          Authorization:
            "Token " + (await AsyncStorage.getItem("token")).toString()
        } : {
              "Content-Type": "application/json",
              Authorization:
                "Token " + (await AsyncStorage.getItem("token")).toString(),
              'x-client': 'ipad',
            },
      body: JSON.stringify(data)
    })
      .then(response => {
        // console.log(response);
        return response.json();
        // if (response.ok) {
        //   return response.json();
        // } else {
        //   throw new Error(
        //     `Unable to retrieve events.\nInvalid response received - (${response.status}).`
        //   );
        // }
      })
      .then(responseData => {
        // this.log(responseData);
        // baseClass._hideProgress();
        successCallback(responseData,callBackData);
        // this.log("success response returned");
        return responseData;
      })
      .catch(error => {
        console.log(error);
        errorCallback(error);
        // this.log({ "Service Error": "" + error });
        // if (error)
        //   baseClass._showAlert(
        //     "" + error + ", Please try again after sometime.",
        //     "Ok"
        //   );
        // else
        //   baseClass._showAlert(
        //     "Something went wrong, Please try again after sometime."
        //   );
      });
  };
}


// axios.get(createPatientUrl, {
//   headers: {
//           //Accept: "pdf",
//           "Content-Type": "application/pdf",
//           "Authorization": localStorage.getItem(
//                   "token"
//           )
//   }
// }).then(response => {
//   //Create a Blob from the PDF Stream
//   const file = new Blob(
//           [response.data],
//           { type: 'application/pdf' });
//   //Build a URL from the file
//   const fileURL = URL.createObjectURL(file);
//   //Open the URL on new Window
//   window.open(fileURL);
// })
//   .catch(error => {
//           console.log(error);
//   });