import React from 'react';
import { View, Text, Platform, TouchableOpacity, ScrollView, StyleSheet, Image, Picker } from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import Icon from "react-native-vector-icons/Feather";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import 'antd/dist/antd.css';
// import { DatePicker } from 'antd';
// import moment from "moment";
import AsyncStorage from "../../../AsyncStorage";
import Styles  from "../../../styles/Style";
import { CommonHistorySectionHeader } from '../BaseComponent'
import 'antd/dist/antd.css';
import { Tooltip } from 'antd';
import moment from 'moment';
import { colors } from 'react-native-elements';
import Icon from "react-native-vector-icons/FontAwesome";

const platform = Platform.OS;
const pageTitle = "Previous Prescription";
const pageName = "prescription";
const isPageName = "isPreviousPrescription";

export default class PreviousPrescription extends BaseComponentStyle {
  constructor(props) {
    super(props);

    let userType = '';

    try {
      userType = this.props.userType;
    } catch (error) { }

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      symptomsData: [],
      isPreviousPrescription: isPageName === this.props.selectedView ? true : false,
      lastPerviousDays: [],
      prescriptionList: [],
      selectedPreviousDay: {},
      prescriptionId: "",
      deletedLineIds: [],
      prescriptionFollowupDate: "",
      pharmacyList: [],
      previousPrescriptionDate:[],
      userType: userType,
      prescriptionIsTransmitted:false,

      selectedPharmacy:"",
      selectedPrescriptionDate:"",
      selectedPrescriptionAptId:"",

      disablePrintButton:false,

      tempId:0,
      selectedClinic:this.props.selectedClinicOrDoctor,
      showFlag:false
      // getPrescriptionListINStart:[],
    };
  }


  componentDidMount() {
    
    this.getPreviouosPrescriptionDate();  // Previous prescription
  }

  componentWillReceiveProps(props) {

    let updateFontColor = isPageName === props.selectedView ? true : false;
    if (updateFontColor !== this.props.isPreviousPrescription) {

      this.setState({ isPreviousPrescription: updateFontColor });
    }
  }

  getPreviouosPrescriptionDate() {

    let { userType, patientAppointment, selectedClinic } = this.state;
    let service_url = '';
    
    
      service_url = Constants.PREVIOUS_PRESCRIPTION_DATE + "?patient_id=" + patientAppointment.patient_id ;
    
    

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrescriptionDateSuccess,
      this.getPrescriptionDateFailure
    );
  }

  getPrescriptionDateSuccess = success => {
    if (success.status == "success") {
      // alert(JSON.stringify( success.data))
      
      this.setState({
        showFlag: success.prescription && success.prescription.length > 0 ? true:false,
        previousPrescriptionDate: success.prescription,
        selectedPrescriptionDate:success.prescription && success.prescription.length > 0 ? success.prescription[0].prescription_date : "",
        selectedPrescriptionAptId: success.prescription && success.prescription.length > 0 ? success.prescription[0].appointment : ""
      },()=>{

        
        this.getPreviouosPrescriptionList(); 
      })
    }
  }

  getPrescriptionDateFailure = error => {
    
    // console.log(" prescription  -----------------> error ===" + JSON.stringify(error))
  }

  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
  }

  getPreviouosPrescriptionList() { 
    
    let { patientAppointment, selectedPrescriptionAptId, selectedPrescriptionDate } = this.state;

    var service_url = "";

    //if( Constants.ROLE_FO == userType ) {
      service_url = Constants.PREVIOUS_PRESCRIPTION_LIST +
      "?patient_id=" + patientAppointment.patient_id +
      "&appointment_id=" + selectedPrescriptionAptId+
      "&date="+selectedPrescriptionDate;
  

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.showPrescriptionListSuccess,
      this.showPrescriptionListFailure
    );
  }

  showPrescriptionListSuccess = (success) => {
    
    if (success.status == "success") {

      var prescription_lines = success.prescription_lines
     
      var getListofPrescription=[]
      for (let i = 0; i < prescription_lines.length; i++) {
        var data=prescription_lines[i]

        data["tempId"]=prescription_lines[i].id;
          getListofPrescription.push(data)
        
      }

      
      this.setState({
        prescriptionList: getListofPrescription,
      })
    }
  }
  showPrescriptionListFailure = (error) => { }

  renderprescription = (prescription, index) => {


    var instructions
    if (prescription.instructions == "WF")
      instructions = "With Food";
    else if (prescription.instructions == "BF")
      instructions = "Before Food";
    else if (prescription.instructions == "ES")
      instructions = "Empty Stomach";
    else if (prescription.instructions == "AF")
      instructions = "After Food";

    let image;

    // var typesOfDosage = [
    //   "Tablet","Tablets","Syrup","syrup",
    //   "Solutions","Solution","solution","solutions",
      
    // ],


    if (prescription.dosage_type == "Tablet" || prescription.dosage_type == "capsule" ||prescription.dosage_type == "capsules" )
          image = require("../../../../assets/images/Prescription_Icons/icons/Tablet_capsule.svg")
    else if(prescription.dosage_type == "DROPS" )
          image = require("../../../../assets/images/Prescription_Icons/icons/drops.png")
    else if(prescription.dosage_type == "Syrup" || prescription.dosage_type == "solution" || prescription.dosage_type == "solutions" || prescription.dosage_type == "suspension" || prescription.dosage_type == "Suspension" )
          image = require("../../../../assets/images/Prescription_Icons/icons/Syrup_solution.png")
    else if(prescription.dosage_type == "injection" || prescription.dosage_type == "Injection" )
          image = require("../../../../assets/images/Prescription_Icons/icons/injection.svg")
    else
          image = require("../../../../assets/images/Prescription_Icons/icons/Others.png")


          
    // if (prescription.dosage_type == "Tablet") {
    //   image = require("../../../../assets/images/Prescription_Icons/pill.svg")
    // } else {
    //   image = require("../../../../assets/images/Prescription_Icons/injection.svg")
    // }
    return (
      <View key={index} style={[styles.TableDataView, {borderBottomColor: color.textBoxBorderColor}]}>
        <View style={{ flexDirection: "row" }}>
          <View style={[{flexDirection:'row',flex:0.32 },Styles.historyHeaderFontStyleStartText]}>
          <View style={{justifyContent:"flex-start"}}>
            <Image
              source={image}
              style={styles.TableImg}
            />
          </View>

          <View>
            <Tooltip placement="topLeft" title={prescription.drug_name}>               
              <Text style={{fontSize: '1vw', fontWeight: "500"}}>
                {prescription.drug_name == null ? null : prescription.drug_name.length > 18 ? prescription.drug_name.slice(0,18) + ".." : prescription.drug_name}
              </Text>
            </Tooltip> 
            <View style={{ flexDirection: 'row', marginTop: '0.4vw',flexWrap:'wrap' }}>
              <Text style={{ fontSize: '0.8vw', borderRightColor: color.textBoxBorderColor, borderRightWidth: 1, paddingRight: '0.3vw',overflow:'hidden' }}numberOfLies={1}>{prescription.drug_strength}</Text>
              <Tooltip placement="topLeft" title={prescription.generic_name}>               
              <Text style={{fontSize: '0.8vw', marginLeft:'0.3vw'}}>
                {prescription.generic_name == null ? null : prescription.generic_name.length > 18 ? prescription.generic_name.slice(0,18) + ".." : prescription.generic_name}
              </Text>
            </Tooltip> 
            </View>
          </View>
          </View>
          <View style={{ flex:0.27  }}>
            <Text style={{ fontSize: '0.9vw' }}>{prescription.morning}{"-"}{prescription.afternoon}{"-"}{prescription.evening}{"-"}{prescription.night}</Text>
            <Text style={{ marginTop: '0.4vw', fontSize: '1vw', marginLeft: '0.1vw' }}>{prescription.duration}{" Days"}</Text>
          </View>

          <View style={{ flex:0.40, }}>
            <Text style={{ fontSize: '1vw' }}>{instructions}</Text>
            <Text style={{ marginTop: '0.4vw', fontSize: '0.8vw' }}>{prescription.comments}</Text>
          </View>


        </View>
      </View>
    )
  }


  convertedDate(date, key) {
    var fulldate = new Date(date);
    var converted_date = moment(fulldate).format("YYYY-MM-DD");
    var states = this.state;
    states[key] = converted_date
    this.setState({ states })
  }

  

  removeDuplicate(data){
    var nameListJsonObject =data.map(JSON.stringify); 
    var nameListUniqueSet = new Set(nameListJsonObject); 
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse); 
    return nameListUniqueArray;
  }


  printPrescription(){
    let { patientAppointment, selectedPrescriptionAptId, selectedPrescriptionDate } = this.state;
    var service_url = ""
    // userType = this.state;

    service_url = Constants.PREVIOUS_PRESCRIPTION_LIST +
      "?patient_id=" + patientAppointment.patient_id +
      "&appointment_id=" + selectedPrescriptionAptId+
      "&date="+selectedPrescriptionDate+
      "&export_type=pdf";
    

   

    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      // this,
      this.openViewSuccess,
      this.openViewFailure,
      "pdf"
    );
  }


  openViewSuccess = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  openViewFailure = (error) =>{
    // console.log("PDF error response");
    console.log(error);
  }


  render() {
    
    return (
      <View style={{ flex: 1 }}>


        <View style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', paddingVertical: '0.5vw', borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>

          {this.renderTitleBorder(this.state.isPreviousPrescription, pageTitle, isPageName, this.triggerNewItem)}
          {this.state.previousPrescriptionDate.length > 0 ?<View style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center',  }}>
          <TouchableOpacity
                // style={{ alignItems: "center" }}
                onPress={() => {
                  this.setState({
                    showFlag: !this.state.showFlag
                  })
                }}
                style={[styles.BtnTouch, {
                  backgroundColor: color.themeDark,
                }, Styles.allButtonBorderRadius]}>
                <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{this.state.showFlag ? "Hide" : "Show"}</Text>
              </TouchableOpacity>
              <View style={styles.PickerView}>
                    <Picker
                      selectedValue={this.state.selectedPrescriptionDate}
                      style={[styles.PickerContainer, Styles.allButtonBorderRadius]}
                      itemStyle={{}}

                      onValueChange={(itemvalue) => {
                        var apt = "";
                        for (var i = 0; i < this.state.previousPrescriptionDate.length; i++) {
                          if (itemvalue === this.state.previousPrescriptionDate[i].prescription_date)
                            apt = this.state.previousPrescriptionDate[i].appointment
                        }
                        this.setState({
                          selectedPrescriptionDate: itemvalue,
                          selectedPrescriptionAptId: apt
                        }, () => {
                          this.getPreviouosPrescriptionList()
                        });
                      }}
                    >
                      {/* <Picker.Item key={-1} label={"Select Date"} value={0} /> */}
                      {this.state.previousPrescriptionDate.map((i, index) => (
                        <Picker.Item key={index} label={i.prescription_date} value={i.prescription_id} />
                      ))}
                    </Picker>
                  </View>
                  <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                    this.printPrescription()
                  }} style={[styles.BtnTouch, { backgroundColor: color.themeDark,
                  }, Styles.allButtonBorderRadius]}>
                    <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Print"}</Text>
                  </TouchableOpacity>
                  <Tooltip placement='top' title='Copy to Prescription'>
                  <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                    this.props.MovePrevToCurrent(this.state.prescriptionList)
                  }} style={[styles.BtnTouch, { backgroundColor: color.themeDark,
                  }, Styles.allButtonBorderRadius]}>
                    <Icon size={'1.3vw'} name={"copy"} color={"white"} />
                  </TouchableOpacity>
                  </Tooltip>
          </View>:null}
          
        </View>
    
          {true ?

            <View>
              
              {this.state.showFlag ?
              <View>
                <View>

                

                 
                </View>
                <View style={{ marginTop: '0.6vw' }}>
                  <CommonHistorySectionHeader
                    heading1={"Prescription"}
                    heading2={"M-A-E-N"}
                    heading3={"Description"}
                    columnSize={[0.3, 0.27, 0.40]}  //total value is == of 1
                    noOfColumn={3}
                  />
                </View>
                <View>
                  { 
                    this.state.prescriptionList.map((prescription, index) => {
                      return this.renderprescription(prescription, index)
                    })
                  }
                </View>
              </View>:null}
              <View>
              </View>
            </View> : null
          }
      </View>
    );
  }
}

const styles = StyleSheet.create({

  previousDatesView: {
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    paddingTop: 7,
    paddingBottom: 7,
    marginRight: 20,
    borderRadius: 20,
  },
  BtnTouch: {
    marginRight: '2vw', 
    paddingTop: '0.5vw', 
    paddingLeft: '1.1vw',
    paddingRight: '1.1vw', 
    paddingBottom: '0.5vw'
  },
  PickerView: { 
    width: '8.4vw', 
    marginRight: '1.2vw',
  },
  PickerContainer: { 
    borderRadius: '1.2vw', 
    height: '2.3vw', 
    paddingLeft: '0.6vw', 
    fontSize: '0.8vw', 
    backgroundColor: "transparent" 
  },
  TableDataView: { 
    marginTop: '1.2vw', 
    marginRight: '2.5vw',
    borderBottomWidth: 1, 
    paddingBottom: '0.6vw' 
  },
  TableImg: { 
    width: '2.5vw', 
    height: '2.5vw',
    marginRight:'0.6vw' 
  }
});