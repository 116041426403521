import React, { Component } from 'react';
import { View, Text, ScrollView, Dimensions, Image, TouchableOpacity, Picker, StyleSheet, TextInput } from 'react-native';
import { color } from '../../../../../styles/Color';
import { CommonHistorySectionHeader } from '../../../BaseComponent'
import Style from '../../../../../styles/Style';
import moment from 'moment';
import Icon from "react-native-vector-icons/FontAwesome";
import { DatePicker } from 'antd';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
var today = new Date();
var date = today.getFullYear()
export default class vendorMonthwise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBill: 0,
      totalPaid: 0,
      outstandingAmount: 0,

      vendor_id: this.props.vendorId,
      payment_mode: null,

      selectedYear: date,
      selectedPaymentType: "",
      comment: "",
      paymentAmount: 0,
      payment_date: moment(new Date()),
      paymentMadeBy: "",
      selectedTotalBill: 0,
      selectedPaidAmount: 0,
      selectedOutstandingAmount: 0,
      selectedID: "",
      selectedTotalPO: 0,
      selectedMonth: "",

      viewID: "",
      viewMonth: "",
      viewTotalBill: 0,
      viewPaidAmount: 0,
      viewOutstandingAmount: 0,
      viewTotalPO: 0,

      transactionId: "",
      cardNumber: "",
      chequeNumber: "",
      vendorPaymentList: [],
      isPaymentHistory: false,
      vendorName : "",
      vendorMonthwiseList: []

    };
  }

  componentDidMount() {
    this.getVendorMonthwise()
  }
  getVendorMonthwise() {
    let url = Constants.VENDOR_PAYMENT_LIST_GET + "?vendor_id=" + this.state.vendor_id + "&bill_date_year=" + this.state.selectedYear;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getVendorMonthwiseSuccess,
      this.getVendorMonthwiseFailure
    );
  }

  getVendorMonthwiseSuccess = response => {

    if (response.status === "success") {

      this.setState({
        vendorMonthwiseList: response.data,
        totalBill: response.total_bill_amount,
        totalPaid: response.total_paid_amount,
        outstandingAmount: response.total_balance_amount,
        vendorName : response.vendor_name
      });
      for( let i = 0 ; i < this.state.vendorMonthwiseList.length ; i++ ) {
        if(response.data[i].id === this.state.viewID){
             this.setState({
               viewMonth :  this.state.vendorMonthwiseList[i].month,
               viewTotalPO :  this.state.vendorMonthwiseList[i].total_po,
               viewTotalBill :  this.state.vendorMonthwiseList[i].total_bill_amount,
               viewPaidAmount :  this.state.vendorMonthwiseList[i].total_paid_amount,
               viewOutstandingAmount :  this.state.vendorMonthwiseList[i].total_balance_amount
             })
        }
      }
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getVendorMonthwiseFailure = response => {
    this.props.showResposeValue("error", response.message)
  }
  getVendorPayment(id) {
    let url = Constants.GET_VENDOR_HISTORY + "?id=" + id + "&vendor_id=" + this.state.vendor_id;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getVendorPaymentSuccess,
      this.getVendorPaymentFailure
    );
  }

  getVendorPaymentSuccess = response => {

    if (response.status === "success") {

      this.setState({
        vendorPaymentList: response.data
      });
    } else {
      this.props.showResposeValue("error", response.message)
    }
  };

  getVendorPaymentFailure = response => {
    this.props.showResposeValue("error",response.message)
  }
  renderHeader() {
    return (
      <View>
       <View style={{ paddingHorizontal : "1.30vw" }}><Text style={styles.headerText}>{this.state.vendorName}</Text></View>
       <View style={styles.headerStyle}>
        <CommonHistorySectionHeader
          heading1={"S.No"}
          heading2={"Month"}
          heading3={"Total PO"}
          heading4={"Bill Amount"}
          heading5={"Paid Amount"}
          heading6={"Outstanding Payment"}
          heading7={"Action"}
          columnSize={[0.1, 0.12, 0.14, 0.14, 0.14, 0.26, 0.1]}
          noOfColumn={7}

        />
       </View>
      </View> 
    )
  }
  renderPaymentHistory = () => {
    return (
      <View>
        {(this.state.vendorPaymentList && this.state.vendorPaymentList.length > 0) ? this.state.vendorPaymentList.map((item, index) => {
          return (
            <View style={styles.dataContainers}>
              <View style={{ flex: 0.16, marginLeft: "1.30vw" }}><Text style={styles.TableBody}>{index + 1}</Text></View>
              <View style={{ flex: 0.16, marginLeft: "1.30vw"  }}><Text style={styles.TableBody}>{item.payment_date}</Text></View>
              <View style={{ flex: 0.16 }}><Text style={styles.TableBody}>{item.paid_amount}</Text></View>
              <View style={{ flex: 0.16 }}><Text style={styles.TableBody}>{item.payment_mode}</Text></View>
              <View style={{ flex: 0.16 }}><Text style={styles.TableBody}>{item.payment_made_by}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableBody}>{item.comments}</Text></View>
            </View>

          )
        }) : null}
      </View>
    )

  }
  onPressPrint = () =>{
    var url = Constants.GET_VENDOR_HISTORY + "?id="+ this.state.viewID + "&vendor_id=" + this.state.vendor_id + "&export_type=pdf" ;

        this.service.getInstance().documentUploadGet(
          url,
          this.printCompletedPaymentSuccess,
          this.printCompletedPaymnetFailure,
          "pdf"
        );
  }
  printCompletedPaymentSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  printCompletedPaymnetFailure = error => {
    console.log(error);
  };

  renderHeaderData() {

    return (
      <View>
        { this.state.vendorMonthwiseList && this.state.vendorMonthwiseList.length > 0 ?
          this.state.vendorMonthwiseList && this.state.vendorMonthwiseList.map((item, index) => {

            return (
              <View style={styles.dataContainer} >
                <View style={{ flex: 0.1 }}><Text style={{ fontSize:'.95vw' }}>{index + 1}</Text></View>
                <View style={{ flex: 0.12 }}><Text style={styles.TableBody}>{item.month}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.TableBody}>{item.total_po}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.TableBody}>{item.total_bill_amount}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={styles.TableBody}>{item.total_paid_amount}</Text></View>
                <View style={{ flex: 0.26 }}><Text style={[styles.TableBody,{ color: color.red }]}>{item.total_balance_amount}</Text></View>
                <View style={{ flex: 0.1 }}>
                  {item.total_balance_amount === 0 ?
                    <TouchableOpacity style={styles.commonFlex}
                      onPress={() => {
                        this.setState({
                          isPaymentHistory: true,
                          viewID: item.id,
                          viewMonth: item.month,
                          viewTotalBill: item.total_bill_amount,
                          viewPaidAmount: item.total_paid_amount,
                          viewOutstandingAmount: item.total_balance_amount,
                          viewTotalPO: item.total_po,
                        },()=>{
                          this.getVendorPayment(item.id)
                        })
                      }}
                    ><Image style={styles.ImageStyle} source={require('../../../../../../assets/images/IPActions/Completed.png')} /></TouchableOpacity> :
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity onPress={() => {
                        if (this.state.outstandingAmount != 0) {
                          this.setState({
                            viewID: item.id,
                            viewMonth: item.month,
                            viewTotalBill: item.total_bill_amount,
                            viewPaidAmount: item.total_paid_amount,
                            viewOutstandingAmount: item.total_balance_amount,
                            viewTotalPO: item.total_po,
                            isPaymentHistory: true,
                            selectedID: item.id,
                            selectedMonth: item.month,
                            selectedTotalBill: item.total_bill_amount,
                            selectedPaidAmount: item.total_paid_amount,
                            selectedOutstandingAmount: item.total_balance_amount,
                            selectedTotalPO: item.total_po,
                          }, () => {
                            this.getVendorPayment(this.state.viewID)
                          })
                        }
                      }}>
                        <Image style={ styles.ViewIcon } source={require('../../../../../../assets/images/IPActions/viewIcon.png')} />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => {
                        if (this.state.outstandingAmount != 0) {
                          this.setState({
                            selectedID: item.id,
                            selectedMonth: item.month,
                            selectedTotalBill: item.total_bill_amount,
                            selectedPaidAmount: item.total_paid_amount,
                            selectedOutstandingAmount: item.total_balance_amount,
                            selectedTotalPO: item.total_po,
                            paymentAmount : 0,
                            paymentMadeBy : "",
                            comment : "",
                            cardNumber : "",
                            transactionId : "",
                            chequeNumber : "",
                            selectedPaymentType : ""

                          })
                        }
                      }} style={{ marginLeft: ".3vw" }} ><Image style={styles.ImageStyle} source={require('../../../../../../assets/images/IPActions/Billing.png')} /></TouchableOpacity>
                    </View>}
                </View>
              </View>
            )
          }): 
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
             <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
            </View>
          }
        {this.state.isPaymentHistory && this.state.vendorPaymentList && this.state.vendorPaymentList.length > 0 ?
          <View style={{ zIndex: 1, position: "absolute", backgroundColor: color.white, width: "100%" }}>
            <View style={styles.dataContainer}>
              <View style={{ flex: 0.1 }}><Text style={styles.TableBody}>{1}</Text></View>
              <View style={{ flex: 0.12 }}><Text style={styles.TableBody}>{this.state.viewMonth}</Text></View>
              <View style={{ flex: 0.14 }}><Text style={styles.TableBody}>{this.state.viewTotalPO}</Text></View>
              <View style={{ flex: 0.14 }}><Text style={styles.TableBody}>{this.state.viewTotalBill}</Text></View>
              <View style={styles.commonFlex}><Text style={styles.TableBody}>{this.state.viewPaidAmount}</Text></View>
              <View style={{ flex: 0.26 }}><Text style={[styles.TableBody,{ color: color.red }]}>{this.state.viewOutstandingAmount}</Text></View>
              {this.state.viewOutstandingAmount === 0 ?
                <TouchableOpacity style={{ flex: 0.1 }}
                    onPress ={()=>{
                     this.setState({
                      isPaymentHistory: !this.state.isPaymentHistory,
                      // vendorMonthwiseList : []
                     })
                    }}
                ><Image style={styles.ImageStyle} source={require('../../../../../../assets/images/IPActions/Completed.png')} /></TouchableOpacity>
                :
                <View style={{ flexDirection: "row", flex: 0.1, }}>
                  <TouchableOpacity onPress={() => {
                    this.setState({
                      isPaymentHistory: !this.state.isPaymentHistory
                    })
                  }} >
                    <Image style={ styles.ViewIcon } source={require('../../../../../../assets/images/IPActions/viewIcon.png')} />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => {
                    if (this.state.outstandingAmount != 0) {
                      this.setState({
                        selectedID: this.state.viewID,
                        selectedMonth: this.state.viewMonth,
                        selectedTotalBill: this.state.viewTotalBill,
                        selectedPaidAmount: this.state.viewPaidAmount,
                        selectedOutstandingAmount: this.state.viewOutstandingAmount,
                        selectedTotalPO: this.state.viewTotalPO,
                        paymentAmount : 0,
                        paymentMadeBy : "",
                        comment : "",
                        cardNumber : "",
                        transactionId : "",
                        chequeNumber : "",
                        selectedPaymentType : ""
                      })
                    }
                  }} style={{ marginLeft: ".3vw" }} ><Image style={styles.ImageStyle} source={require('../../../../../../assets/images/IPActions/Billing.png')} /></TouchableOpacity>
                </View>
              }
            </View>
            <View style={styles.paymentHistoryStyle}>
              <Text style={styles.paymentText}>{"Payment History"}</Text>
              { this.state.viewOutstandingAmount == 0 ?
               <TouchableOpacity style={{ flex : 0.67 }}
                    onPress ={()=>{
                       this.onPressPrint()
                    }}
                ><Image style={styles.ImageStyle} source={require('../../../../../../assets/images/print_icon.png')} /></TouchableOpacity> : <View style = {{flex :0.67}}></View> }
              <TouchableOpacity onPress={() => {
                 this.setState({ 
                    isPaymentHistory: false,
                    selectedID: "",
                    selectedMonth: "",
                    selectedTotalBill: 0,
                    selectedPaidAmount: 0,
                    selectedOutstandingAmount: 0,
                    selectedTotalPO: 0,
                 }) 
                 }} style={{ flex: 0.1 }}>
                <Icon name="close" size={"1.2vw"} color={color.themeDark} />
              </TouchableOpacity>
            </View>
            <View style={{ paddingHorizontal: ".65vw"  }}>
              <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Payment Date"}
                heading3={"Amount"}
                heading4={"Payment Type"}
                heading5={"Payment Made By"}
                heading6={"Comments"}
                columnSize={[0.16, 0.16, 0.16, 0.16, 0.16, 0.2]}
                noOfColumn={6}
              />
              <View>{this.renderPaymentHistory()}</View>
            </View>
          </View>
          :
          null

        }
      </View>
    )
  }
  renderCard = (color, icon, value, placehold) => {
    return (
      <View style={[styles.CardContainer, { backgroundColor: color }]}>
        <View style={styles.SubContainer}>
          <View style={{ paddingHorizontal: ".3vw" }} >
            <Text style={styles.cardValue}>{value}</Text>
          </View>
          <View style={{ marginRight: ".3vw" }}>
            <Image
              style={styles.ImageStyles}
              source={icon}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
          <Text style={{ color: "white", fontSize: ".6vw" }}>{placehold}</Text>
        </View>
      </View>
    )
  }
  printVendorDetails = (type) => {
    var url = Constants.GET_VENDOR_MONTHWISE + "?vendor_id=" + this.state.vendor_id + "&export_type=" + type + "&bill_date_year=" + this.state.selectedYear;

    if (url) {
      if (type == 'pdf') {
        this.service.getInstance().documentUploadGet(
          url,
          this.printVendorDetailsSuccess,
          this.printVendorDetailsFailure,
          "pdf"
        );
      } else {
        this.service.getInstance().documentUploadGet(
          url,
          this.exportCSVSuccessCallBack,
          this.exportCSVSuccessCallBack,
          "excel"
        );
      }
    }
  }

  printVendorDetailsSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  printVendorDetailsFailure = error => {
    console.log(error);
  };

  exportCSVSuccessCallBack = response => {
    if (response) {

      var pom = document.createElement('a');
      var csvContent = response.data; //here we load our csv data 

      let filename = response.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }

  exportCSVErrorCallBack = error => { }
  renderCard(color, icon, value, placehold) {
    return (
      <View style={[styles.CardContainer, { backgroundColor: color }]}>
        <View style={styles.SubContainer}>
          <View style={{ paddingHorizontal: ".3vw" }} >
            <Text style={styles.cardValue}>{value}</Text>
          </View>
          <View style={{ marginRight: ".3vw" }}>
            <Image
              style={styles.ImageStyles}
              source={icon}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
          <Text style={{ color: "white", fontSize: ".6vw" }}>{placehold}</Text>
        </View>
      </View>
    )
  }
  renderTopHeader() {
    var getdate = [];
    var today = new Date();
    var year = today.getFullYear();
    for (var i = year; i > 2009; i--) {
      getdate.push(i);
    };

    return (
      <View style={styles.Topheader}>
       <View style={{ flexDirection: "row", alignContent: "flex-start", alignItems: "flex-start" }}> 
         <TouchableOpacity onPress={() => {
              this.props.changeScreen("vendorPayment")
          }} style={[styles.TopheaderOpacity,{ backgroundColor: color.themeBackgroud}]}>
          <View style={styles.ImageContainer}>
            <Image
                style={styles.ImageStyles}
                source={require('../../../../../../assets/images/IPActions/Return.png')}
            />
            <Text style={{ color: "#ffffff", fontSize:'1vw' }}>{"Back"}</Text>
          </View>
        </TouchableOpacity>
        <View style={ styles.vendorView }><Text style={ styles.vendorText }>{"Vendor Payment"}</Text></View>
       </View> 
        <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end" }}>
          <View style={{ flexDirection: "row" }}>
            {this.renderCard(color.cardBlue, require('../../../../../../assets/images/IPActions/Rupee.png'), this.state.totalBill, "Total Bill")}
            {this.renderCard(color.cardGreen, require('../../../../../../assets/images/IPActions/Rupee.png'), this.state.totalPaid, "Total Paid")}
            {this.renderCard(color.cardOrange, require('../../../../../../assets/images/IPActions/Rupee.png'), this.state.outstandingAmount, "Outstanding Amount")}
          </View>
          <View>
            <Picker
              selectedValue={this.state.selectedYear}
              style={[Style.pickerRoundCornerView, { height: "2.3vw", marginRight: ".65vw", borderRadius: ".26vw" }]}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                var states = this.state;
                states["selectedYear"] = itemvalue;
                states["isclear"] = true
                states["vendorPaymentList"] = []
                states["vendorMonthwiseList"] = []
                this.setState({ states },
                  () => {
                    this.getVendorMonthwise()
                  });
              }}>
              {
                getdate.map((value, index) => {
                  return <Picker.Item label={value} value={value} />
                })
              }
            </Picker>

          </View>
          {/* <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => {
                this.printVendorDetails("pdf")
              }}
              style={styles.PrintStyle}>
              <Image style={styles.Image} source={require('../../../../../../assets/images/print_icon.png')} /></TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.printVendorDetails("excel")
              }}
              style={styles.PrintStyle}>
              <Image style={styles.Image} source={require('../../../../../../assets/images/HomeScreenImages/Export.png')} /></TouchableOpacity>
          </View> */}
        </View>
      </View>
    )
  }
  renderPaymentType = (image, label, payment_mode) => {
    return (
      <View style={{}}>
        <TouchableOpacity disabled = { this.state.selectedID != "" ? false : true } onPress={() => {
          this.setState({
            selectedPaymentType: label,
            payment_mode: payment_mode ? payment_mode : null,
            paymentAmount: 0,
            cardNumber: "",
            transactionId: "",
            chequeNumber: ""
          })
        }} style={{ alignItems: "center" }}>
          <Image source={image} style={{ height: "1.62vw", width: "1.62vw", marginBottom: ".3vw", tintColor: (this.state.selectedPaymentType == label ? color.themeDark : "#888888") }} />
          <Text style={{ color: (this.state.selectedPaymentType == label ? color.themeDark : "black"), fontSize:'1vw' }}>{label}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  _updateState(value) {
    let currentYear = moment().format("YYYY")
    let currentMonth = moment().format("MM")
    let year = moment(value).format("YYYY")
    let month = moment(value).format("MM")
    let values = value.format("DD-MM-YYYY")
    let tomorrow = moment().add(-7, 'day').format('DD-MM-YYYY')
    if(currentYear === year && currentMonth === month){
     if ( tomorrow <= values ) {
        this.setState({
          payment_date: value
        })
     }else {
      this.props.showResposeValue("error", "Please enter with in last 7 days")
    }
    }else {
       this.props.showResposeValue("error", "Please enter with in last 7 days")
    }
 }
  numberValidation(num) {
    return !/[^[0-9]]*/.test(num);
  }
  alphabetvalidation(num) {
    return !/[^.[A-Z a-z]]*/.test(num);
  }
  avoidOnlySplCharValidation = (num) => {
    return !/[^A-Z a-z 0-9]+/.test(num)
  }
  renderPayment(key, text, value) {
    var today = new Date();
    if( key == "payment_date" ){
      var tomorrow = new Date();
      tomorrow = moment(value).add(-7, 'day').format('DD-MM-YYYY')
    }  
    return (
      <View style={styles.paymentStyle}>
        <Text style={{ fontSize: "1vw", flex: 0.3 }}>{text}</Text>
        <Text style={{ flex: 0.2 }}>{":"}</Text>
        {key == "payment_date" ?
          <View style={{ flex: 0.54 }}>
            <DatePicker
              disabledDate={current => {
                return current && current > moment().add(0, "days");
              }}
              clearIcon={false}
              disabled = { this.state.selectedID != "" ? false : true}
              defaultValue={moment(this.state.payment_date)}
              format={"DD-MM-YYYY"}
              style={{borderWidth: 2, width: "10.1vw", fontSize:'1.1vw', height:"2.1vw", borderRadius: 4}}   
              onChange={(date) => {
                this._updateState(date)
              }}          
            />
            </View> :
          <TextInput
            onChangeText={(text) => {
              var states = this.state;
              if (key == "paymentAmount" || key == "cardNumber" || key == "chequeNumber") {
                let isNum = this.numberValidation(text)
                if (isNum) {
                  states[key] = text
                  this.setState({ states })
                }
              } else if(key == 'paymentMadeBy'){
                 let isAlpha = this.alphabetvalidation(text)
                 if (isAlpha) {
                  states[key] = text
                  this.setState({ states })
                }
              } else if( key === "transactionId" && this.state.selectedPaymentType) {
                let isSpl = this.avoidOnlySplCharValidation(text)
                if(isSpl) {
                  states[key] = text
                  this.setState({ states })
                }
              } else {
                states[key] = text;
                this.setState({
                  states
                })
              }
            }}
            multiline={ key == "comment" ? true : null } maxLength={ key == "cardNumber" ? 4 : key == "comment" ? 250 :key == "chequeNumber" ? 6 : null } placeholder={text} placeholderTextColor={color.lightGray}
            value={value} editable={key != 'selectedPaymentType' && ( this.state.selectedID != "" && this.state.selectedOutstandingAmount != 0 ) ? true : false} style={{ height: key == "comment" ? "3.90vw" : "1.95vw", width: "60%", borderWidth: 0.1, borderColor: "#888888", borderRadius: ".25vw", paddingLeft: "0.65vw", flex: 0.3, backgroundColor: key != 'selectedPaymentType' &&( this.state.selectedID != "" && this.state.selectedOutstandingAmount != 0 )  ?  null : color.disableComponentColor, fontSize:'1vw' }} />
        }
      </View>
    )
  }
  renderBillingPayment(text, amount) {
    return (
      <View style={{ paddingHorizontal: "1.30vw", paddingVertical: ".65vw", flexDirection: "row", flex: 1, justifyContent: "center", borderBottomWidth: text == "Payment Amount" || text == "Outstanding Amount" ? 1 : null, borderBottomColor: text == "Outstanding Amount" ? color.gray : null }}>
        <Text style={{ flex: 0.7, fontSize:".95vw", fontWeight: text == "Payment Amount" ? "500" : null }}>{text}</Text>
        <Text style={{ flex: 0.3, fontSize:".95vw", fontWeight: text == "Payment Amount" ? "500" : null }}>{"₹ "}{amount}</Text>
      </View>
    )
  }
  _saveBill() {

    let url = Constants.VENDOR_PAYMENT_POST;
    var data = {
      "id": this.state.selectedID,
      "payment_type": this.state.payment_mode,
      "payment_amount": parseFloat(this.state.paymentAmount),
      "payment_date": moment(this.state.payment_date).format("YYYY-MM-DD"),
      "payment_made_by": this.state.paymentMadeBy,
      "comments": this.state.comment,
      "card_number": this.state.cardNumber,
      "cheque_number": this.state.chequeNumber,
      "transaction_id": this.state.transactionId
    }

    if (this.state.payment_mode === null ) {

      this.responseSuccessErrorMessage("Please enter payment type", false)
      this.props.showResposeValue("error", "Please enter payment type")

    } else if (this.state.paymentAmount == 0) {
      
      this.props.showResposeValue("error", "Please enter payment amount")

    } else if (this.state.paymentAmount > this.state.selectedOutstandingAmount) {

      this.props.showResposeValue("error", "Please enter correct payable amount")

    } else if( this.state.paymentMadeBy == "" ){
      
      this.props.showResposeValue("error", "Please enter payment made by")

    }
    else {
     OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.vendorPaymentSuccess,
      this.vendorPaymentFailure
    );
   }

  }
  vendorPaymentSuccess = (response) => {

    if (response.status == "success") {
      this.getVendorMonthwise()
      this.getVendorPayment(this.state.selectedID)
      this._cancelBill()
      this.props.showResposeValue("success", response.message)

    } else if (response.status == "error") {

      this.props.showResposeValue("error", response.message)

    }
  }
  vendorPaymentFailure = (response) => {
    this.props.showResposeValue("error", response.message)
  }
  _cancelBill = () => {
    var states = this.state

    states["selectedPaymentType"] = "";
    states["comment"] = "";
    states["payment_mode"] = null;
    states["paymentMadeBy"] = "";
    states["selectedTotalBill"] = 0.0;
    states["selectedPaidAmount"] = 0.0;
    states["selectedOutstandingAmount"] = 0.0
    states["cardNumber"] = "";
    states["chequeNumber"] = "";
    states["transactionId"] = "";
    states["paymentAmount"] = 0.0;
    states["payment_date"] = moment(new Date());
    states["selectedID"] = ""
    states["selectedPaidAmount"] = ""
    states["selectedOutstandingAmount"] = ""
    states["selectedTotalPO"] = ""
    states["selectedMonth"] = ""
    this.setState({ states })
  }
  renderFooterButton() {
    return (
      <View style={{ justifyContent: "space-evenly", flexDirection: "row", padding: "1.30vw" }}>
        <TouchableOpacity style={styles.saveButton} onPress={() => { this._saveBill() }}>
          <Text style={styles.addVendorText}>Save</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.saveButton} onPress={() => { this._cancelBill() }}>
          <Text style={styles.addVendorText}>Cancel</Text>
        </TouchableOpacity>

      </View>
    )
  }
  renderBilling() {
    var amountPayable = ((this.state.selectedOutstandingAmount) - (this.state.paymentAmount))
    return (
      <View>
        {this.state.selectedMonth && this.state.selectedOutstandingAmount ?
          <View style={{ flexDirection: "row", marginTop: ".65vw" }}>
            <Text style={{ color: color.gray, fontSize: "1.17vw" }}>{this.state.selectedMonth}</Text>
            <Text style={{ color: color.red, fontSize: "1.17vw" }}>{" - " + "₹ " + this.state.selectedOutstandingAmount + " ( Outstanding Amount )"}</Text>
          </View> : null
        }
        <View style={{ marginTop: "1.30vw" }}>
          <Text style={{ fontSize: "1.2vw", fontWeight: "500" }}>{"Payment Type"}</Text>
          <View style={{ flexDirection: "row", padding: ".65vw", justifyContent: "space-between" }}>
            <View>{this.renderPaymentType(require("../../../../../../assets/images/IPActions/Cash.png"), "Cash", 1)}</View>
            <View>{this.renderPaymentType(require("../../../../../../assets/images/IPActions/Card.png"), "Card", 2)}</View>
            <View>{this.renderPaymentType(require("../../../../../../assets/images/IPActions/cheque.png"), "Cheque", 7)}</View>
            <View>{this.renderPaymentType(require("../../../../../../assets/images/IPActions/upi.png"), "UPI", 4)}</View>
            <View>{this.renderPaymentType(require("../../../../../../assets/images/IPActions/bank-transfer.png"), "Bank Transfer", 5)}</View>
          </View>
          <View>
            <View style={{ marginTop: "1.30vw" }}>
              {this.state.selectedPaymentType === "Card" ?
                <>{this.renderPayment("cardNumber", "Card Number", this.state.cardNumber)}</> :
                this.state.selectedPaymentType === "Cheque" ?
                  <>{this.renderPayment("chequeNumber", "Cheque Number", this.state.chequeNumber)}</> :
                  this.state.selectedPaymentType === "UPI" ?
                    <>{this.renderPayment("transactionId", "Transaction ID", this.state.transactionId)}</> :
                    this.state.selectedPaymentType === "Bank Transfer" ?
                      <>{this.renderPayment("transactionId", "Transaction ID", this.state.transactionId)}</> :
                      null
              }
            </View>
            {this.renderPayment("paymentAmount", "Payment Amount *", this.state.paymentAmount)}
            {this.renderPayment("payment_date", "Payment Date *", this.state.payment_date)}
            {this.renderPayment("selectedPaymentType", "Payment Type", this.state.selectedPaymentType)}
            {this.renderPayment("paymentMadeBy", "Payment Made By *", this.state.paymentMadeBy)}
            {this.renderPayment("comment", "Comment", this.state.comment)}

          </View>
          <View style={{ padding: "1.30vw" }}>
            <Text style={{ fontSize: "1.2vw", fontWeight: "500" }}>{"Billing"}</Text>
            {this.renderBillingPayment("Total Bill Amount", this.state.selectedTotalBill)}
            {this.renderBillingPayment("Total Paid Amount", this.state.selectedPaidAmount ? this.state.selectedPaidAmount : 0)}
            {this.renderBillingPayment("Outstanding Amount", this.state.selectedOutstandingAmount)}
            {this.renderBillingPayment("Payment Amount", this.state.paymentAmount)}
            {this.renderBillingPayment("Balance payable", amountPayable ? amountPayable : 0)}

          </View>
          <View>
            {this.renderFooterButton()}
          </View>

        </View>
      </View>
    )
  }
  render() {
    let { showResponse, showErrorResponse, responseMessage } = this.state
    return (
      <View style={styles.mainContainer}>
        <View style={styles.leftSideView}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.leftScroll} bounces={false} bouncesZoom={false} >
            <View>{this.renderTopHeader()}</View>
            <View>{this.renderHeader()}</View>
            <View>{this.renderHeaderData()}</View>
          </ScrollView>
        
        </View>
        <ScrollView showsVerticalScrollIndicator={false} style={styles.rightSideView} >
          <View style={styles.rightContainer}>
            {this.renderBilling()}
          </View>
        </ScrollView>

      </View>
    );
  }
}
const styles = StyleSheet.create({
  mainContainer: { flex: 1, flexDirection: "row" },
  rightContainer: { width: "100%", padding: ".65vw", height: screenHeight, backgroundColor: color.themeShade, },
  leftScroll: { width: "100%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 },
  rightSideView: { width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, },
  CardContainer: { marginRight: ".65vw", borderRadius: ".25vw", paddingVertical: ".25vw", maxHeight: "2.95vw" },
  SubContainer: { flexDirection: "row", justifyContent: "flex-end", minWidth: "7.2vw" },
  cardValue: { color: "white", fontSize: "1.05vw", fontWeight: "bold" },
  Image: { width: "1.95vw", height: "1.95vw" },
  ViewIcon : {  width: "1.25vw", height: "1.25vw" },
  PrintStyle: { width: "2.60vw", height: "2.27vw", borderRadius: ".25vw", backgroundColor: color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.16vw", marginRight:".65vw" },
  commonFlex: { flex: 0.14 },
  headerStyle: { marginTop: "1.30vw", flex: 1 },
  ImageStyle: { height: "1.25vw", width: "1.25vw", tintColor: color.themeDark },
  ViewStyle: { flex: 1, padding:".65vw", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: color.lightGray },
  paymentHistoryStyle: { flex: 1, flexDirection: "row", padding: "1.30vw", zIndex : 1 },
  paymentText: { flex: 0.18, fontSize: "1.2vw", fontWeight: "500" },
  paymentStyle: { flexDirection: "row", flex: 1, padding:".65vw" },
  TableBody:{fontSize:".95vw"},
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: "#888888",
    borderBottomWidth: 1,
    padding: "1.30vw",
    justifyContent: 'center',
    zIndex : 1,
    flex: 1
  },
  vendorView : {  
    height:'2vw',
    width:'12vw',
    marginLeft: "1vw"
  },
  Topheader: { 
    padding: "1.30vw", 
    flexDirection: "row", 
  },
  TopheaderOpacity:{ 
    width: "4.55vw", 
    height: "1.95vw", 
    borderRadius: ".3vw"
  },
  ImageContainer:{ 
    flexDirection: "row", 
    justifyContent: "center", 
    marginTop: ".3vw" 
  },
  ImageStyles:{ 
    width : "1.25vw", 
    height : "1.25vw",
    marginTop: "0.2vw"  
  },
  vendorText:{
    fontSize: "1.30vw",
    fontWeight: "500",
  },
  addVendorText: {
    color: color.white,
    fontSize: "1vw"
  }, 
  saveButton: {
   width: "20%", backgroundColor: color.themeDark, borderRadius: 4, justifyContent: "center", alignContent: "center", alignItems: "center", marginRight: "2vw", paddingVertical: "0.3vw"
  },
  headerText: {fontSize : "1vw", fontWeight : "500"},
  dataContainers: { flex: 1, paddingVertical: ".65vw", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: color.lightGray },
  leftSideView: { width: "65%", height: screenHeight - 65, backgroundColor: color.white }
});
