import React, {Component} from "react";
// import { TouchableOpacity } from "react-native-gesture-handler";
import {
    View,
    Text,
    TouchableOpacity, Image
  } from "react-native";
import { color } from "../styles/Color";  
// import { Style } from "@material-ui/icons";
import Style from "./../styles/Style";
import ImagePath from "../utils/ImagePaths";

export default class SelectedButton extends Component {

    onPressItem = () => {

        let item = this.props.item;
        // console.log("SelectedButton - " + item)
        if(item.id){
            this.props.onPressItem(item.value,this.props.label,item.id,item);
            console.log(item.id)
        }
        else
        {
            this.props.onPressItem(item.value,this.props.label,"",item);
        }
       
    }

    onPressClose = () => {
        let item = this.props.item;
        this.props.onPressClose(item.value)
    }

    render(){
        //render styles and components conditionally using this.props.selected ? _ : _
        let {item, fill, borderNeeded, selected,isDisable,disable, closeable} = this.props;
        
        //let borderColor = fill || borderNeeded ? color.themeDark : color.themeBlue;
        // let backgroundColor = fill ? borderNeeded ? color.themeBlue : color.themeDark : color.themeBlue;
        //let backgroundColor = fill ? borderNeeded ? color.themeBlue : color.themeDark : color.themeShadeBackground;

        // console.log("selected   " + selected);

        return (
            <TouchableOpacity onPress={this.onPressItem.bind(this)} 
            style={{marginLeft:'0.3vw',marginRight:'0.3vw'}}
            disabled={(isDisable || disable) ? true : false}
            >
            <View
            style={[{
                justifyContent: "center",
                alignItems: "center",
                // borderRadius: fill || borderNeeded ? 10 : 20,
                flexDirection: "row",
                borderColor: selected===item.value?color.themeDark:color.textBoxBorderColor,
                borderWidth: 1,
                backgroundColor: 
                disable ? color.disableComponentColor :
                selected===item.value?color.themeDark:color.themeShadeBackground,
                // padding: fill ? 10 : 16,
                // paddingTop: 8,
                // paddingBottom: 8,
                marginBottom: '0.6vw',
                marginRight: '0.1vw'
            },Style.allButtonBorderRadius,Style.componentBtnDDPadding]}
            >
            <Text
                style={{
                fontSize: fill ? '1.2vw' : '0.7vw', //12
                textAlign: "center",
                color: selected===item.value ? color.white : color.themeBlue,
                fontWeight: "400", //500
                }}
            >
                {item.label}
            </Text>
            {closeable ? 
            <TouchableOpacity onPress={this.onPressClose.bind(this)} style={{marginLeft:".5vw", marginTop:".1vw"}}>
                <Image style={{height:".5vw", width:".5vw"}} source={ImagePath.close}/>
            </TouchableOpacity>
            : null}
            </View>
            </TouchableOpacity>
        )
    }
  }