import React from 'react';
import { View, Text, Platform, TouchableOpacity, ScrollView, StyleSheet, Image, Picker, TextInput } from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import Icon from "react-native-vector-icons/Feather";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import 'antd/dist/antd.css';
// import { DatePicker } from 'antd';
// import moment from "moment";
import AsyncStorage from "../../../AsyncStorage";
import Styles  from "../../../styles/Style";
import { CommonHistorySectionHeader } from '../BaseComponent'
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
import { colors } from 'react-native-elements';
import { Tooltip } from 'antd';
import StartFill from "../../../../assets/images/Prescription_Icons/icons/Tablet_capsule.svg"

const platform = Platform.OS;
const pageTitle = "Prescription";
const pageName = "prescription";
const isPageName = "isPrescription";

export default class Prescription extends BaseComponentStyle {
  constructor(props) {
    super(props);

    let userType = '';

    try {
      userType = this.props.userType;
    } catch (error) { }

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      symptomsData: [],
      isPrescription: isPageName === this.props.selectedView ? true : false,
      lastPerviousDays: [],
      prescriptionList: [],
      selectedPreviousDay: {},
      prescriptionId: "",
      deletedLineIds: [],
      prescriptionFollowupDate: "",
      pharmacyList: [],
      userType: userType,
      prescriptionIsTransmitted:false,

      selectedPharmacy:"",

      disablePrintButton:true,

      tempId:0,
      selectedClinic:this.props.selectedClinicOrDoctor,
      // getPrescriptionListINStart:[],
      languageList : ["Tamil","English","Hindi"],
      selectedLanguage:"English",
      PrescriptionTemplateList: [],
      isTemplate: false,
      template_name: "",
      pharmacy_id: "",

      PrescriptionListFromPrevious:[],
      favSelectedDurg: []
    };

  }

  

  componentWillReceiveProps(props) {

    let updateFontColor = isPageName === props.selectedView ? true : false;
    if (updateFontColor !== this.props.isPrescription) {

      this.setState({ isPrescription: updateFontColor });
    }
    if (this.props.favSelectedDurg?.length > 0 && this.props.favSelectedDurg != this.state.favSelectedDurg){
      var {prescriptionList, tempId} = this.state
      var prescLen = prescriptionList?.length
      if(prescLen > 0){
        for(let i = 0; i < this.props.favSelectedDurg.length; i++){
          prescriptionList.push((this.props.favSelectedDurg)[i])
          prescriptionList[i + (prescLen)]['tempId'] = i + (prescLen) + 1
          var newTempId = i + (prescLen) + 1
        }
      }else {
        for(let i = 0; i < this.props.favSelectedDurg.length; i++){
          prescriptionList.push((this.props.favSelectedDurg)[i])
          prescriptionList[i]['tempId'] = i + 1
          var newTempId = i + 1
        }
      }

      
      
      this.setState({
        prescriptionList,
        tempId: newTempId,
        favSelectedDurg: this.props.favSelectedDurg
      })
    }

    if (!this.state.prescriptionIsTransmitted && props.addNewPrescription && Object.keys(props.addNewPrescription).length > 0 && props.addNewPrescription.brand_name !== "") {
      // alert("test"+JSON.stringify(props.addNewPrescription))
      // console.log("test"+JSON.stringify(props.addNewPrescription))
      var { prescriptionList } = this.state;


      if(prescriptionList.length > 0){

      for (let i = 0; i < prescriptionList.length; i++) {
          if(prescriptionList[i].tempId == props.addNewPrescription.tempId)  {
            prescriptionList.splice(i, 1);
            prescriptionList.push(props.addNewPrescription);
          }else if(props.addNewPrescription.tempId == ""){
            var data=props.addNewPrescription
            data["tempId"]=this.state.tempId+1;
            var newTempId = this.state.tempId+1
            prescriptionList.push(data);
            this.setState({ prescriptionList, tempId:newTempId },()=>{
              this.props.clearNewPrescription({})
            })
            return
          }
      }
    }  else{

      var data=props.addNewPrescription
      data["tempId"]=this.state.tempId+1;
      var newTempId = this.state.tempId+1
      prescriptionList.push(data);
      this.setState({ prescriptionList, tempId:newTempId },()=>{
        this.props.clearNewPrescription({})
      })
    }
    
    
    
    // else{
    //   prescriptionList.push(props.addNewPrescription);
    // }

      this.setState({ prescriptionList },()=>{
        this.props.clearNewPrescription({})
      })

    }

    if(props && props.getTransmitDataFlag) {
      var data = {
        "appointment_id": this.state.patientAppointment.appointment_id,
        "patient_id": this.state.patientAppointment.patient_id,
        "prescription_id": this.state.prescriptionId,
        "pharmacy_id":parseInt(this.state.selectedPharmacy)
        }
      this.props.getTransmitDatas(data,false)
    }
  if(this.state.prescriptionList.length == 0){
    this.setState({
      prescriptionList: props.PrescListFromPreviousPresc
    })
  }else{
    let isExist1 = this.state.prescriptionList.some(list => props.PrescListFromPreviousPresc.includes(list));
    let isExist = props.PrescListFromPreviousPresc.every(list => this.state.prescriptionList.includes(list));
    
    if(!isExist){
    }else{
      console.log("YES ITS INCLUDED",  isExist,isExist1)
    }
  }

  }

  getPrescriptionTemplate = () => {

    var service_url = Constants.GET_PRESCRIPTION_TEMPLATE;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrescriptionTemplateSuccess,
      this.getPrescriptionTemplateFailure
    );
  };
  getPrescriptionTemplateSuccess = success => {
    
    if(success.status == "success"){
  
      this.setState({
         PrescriptionTemplateList: success.data,
         pharmacy_id: success.data.pharmacy_id
      })
    }
  }

  getPrescriptionTemplateFailure = error => {}

  getPharmacyList = async () => {
  
    

    // var clinic_id = +await AsyncStorage.getItem("Clinic_Id")
    
    // alert(JSON.stringify(this.state.selectedClinic.id ))
    var service_url = Constants.PHARMACY_LIST + "?clinic_id=" + this.state.selectedClinic.id + "&suggest=" +true;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPharmacyListSuccess,
      this.getPharmacyListFailure
    );
  }
  getPharmacyListSuccess = (success) => {
    if (success.status == "success") {

      this.setState({
        pharmacyList: success.data
      })
    }
  }

  getPharmacyListFailure = (error) => {
    // console.log("success ============> error" + JSON.stringify(success));
  }


  componentDidMount() {
    this.getPreviouosPrescriptionDate();  // old prescription
    this.showPrescriptionList();
    this.getPrescriptionTemplate()

    let { userType } = this.state;

    if( Constants.ROLE_FO !== userType ) {
      this.getPharmacyList();
    }
  }

  getPreviouosPrescriptionDate() {

    let { userType, patientAppointment } = this.state;
    let service_url = '';

    if(userType === Constants.ROLE_FO) {
      service_url = Constants.FO_PRESCRIPTION_DATE + "?patient_id=" + patientAppointment.patient_id + "&appointment_id=" + patientAppointment.appointment_id;
    } else {
      service_url = Constants.DOCTOR_PRESCRIPTION_DATE + "?patient_id=" + patientAppointment.patient_id + "&appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrescriptionSuccess,
      this.getPrescriptionFailure
    );
  }

  getPrescriptionSuccess = success => {
    if (success.status == "success") {
      // alert(JSON.stringify( success.data))

      this.setState({
        lastPerviousDays: success.data,
      })
    }
  }

  getPrescriptionFailure = error => {
    // console.log(" prescription  -----------------> error ===" + JSON.stringify(error))
  }

  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
  }

  showPrescriptionList() { 
    
    let { patientAppointment, userType } = this.state;

    var service_url = "";

    if( Constants.ROLE_FO == userType ) {

      service_url = Constants.FO_PRESCRIPTIONS +
      "?patient_id=" + patientAppointment.patient_id +
      "&appointment_id=" + patientAppointment.appointment_id;
    } else {

      service_url = Constants.DOCTOR_PRESCRIPTIONS +
      "?patient_id=" + patientAppointment.patient_id +
      "&appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.showPrescriptionListSuccess,
      this.showPrescriptionListFailure
    );
  }

  showPrescriptionListSuccess = (success) => {
    if (success.status == "success") {

      var prescription_lines = success.data.prescription_lines
     
      var getListofPrescription=[]

      var disablePrint = true
      for (let i = 0; i < prescription_lines.length; i++) {
        var data=prescription_lines[i]

        data["tempId"]=prescription_lines[i].id;
          getListofPrescription.push(data)
        
      }
      if(prescription_lines.length > 0){
        disablePrint = false
      }

      this.setState({
        prescriptionIsTransmitted:success.data.is_transmitted,
        prescriptionList: getListofPrescription,
        disablePrintButton: disablePrint,
        // prescriptionList: success.data.prescription_lines,
        // getPrescriptionListINStart:data,
        prescriptionId: success.data.prescription_id,
      })
    }
  }
  showPrescriptionListFailure = (error) => { }


  showPreviousPrescriptionData(date) {

    this.setState({ selectedPreviousDay: date })
    
    let { userType } = this.state;

    let service_url = "";

    if( Constants.ROLE_FO == userType ) {

    service_url = Constants.FO_PRESCRIPTIONS +
      "?patient_id=" + this.state.patientAppointment.patient_id +
      "&appointment_id=" + date.appointment_id +
      "&prescription_id=" + date.prescription_id;
    } else {

      service_url = Constants.DOCTOR_PRESCRIPTIONS +
      "?patient_id=" + this.state.patientAppointment.patient_id +
      "&appointment_id=" + date.appointment_id +
      "&prescription_id=" + date.prescription_id;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrescriptionListSuccess,
      this.getPrescriptionListFailure
    );
  }
  getPrescriptionListSuccess = success => {

    if (success.status == "success") {

      var getLineItems = success.data.prescription_lines;

      var  getListofPrescription = []
    for (let i = 0; i < getLineItems.length; i++) {
      var data=getLineItems[i]
      data["tempId"]=getLineItems[i].id;
        getListofPrescription.push(data)
    }
      this.setState({
        prescriptionList: getListofPrescription,
        // prescriptionList: lineItems,
        // prescriptionId: success.data.prescription_id
      })
    }
  }
  getPrescriptionListFailure = error => {
    console.log("error --------------->  " + JSON.stringify(error))

  }

  renderPreviousDates = (date, index) => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.props.triggerNewItem(isPageName);
          this.showPreviousPrescriptionData(date)
          // this.setState({selectedPreviousDay:date})
        }}
        key={index} style={[styles.previousDatesView, { backgroundColor: this.state.selectedPreviousDay.prescription_date == date.prescription_date ? color.themeDark : color.white, borderColor: this.state.selectedPreviousDay.prescription_date == date.prescription_date ? color.themeDark : color.textBoxBorderColor, },Styles.allButtonBorderRadius]} >
        <Text style={{ fontSize: 15, color: this.state.selectedPreviousDay.prescription_date == date.prescription_date ? color.white : null }}>{date.prescription_date}</Text>
      </TouchableOpacity>
    );
  }

  renderprescription = (prescription, index) => {


    var instructions
    if (prescription.instructions == "WF")
      instructions = "With Food";
    else if (prescription.instructions == "BF")
      instructions = "Before Food";
    else if (prescription.instructions == "ES")
      instructions = "Empty Stomach";
    else if (prescription.instructions == "AF")
      instructions = "After Food";
    else if (prescription.instructions == "EU")
      instructions = "External Use";

    let image;

    // var typesOfDosage = [
    //   "Tablet","Tablets","Syrup","syrup",
    //   "Solutions","Solution","solution","solutions",
      
    // ],


    if (prescription.dosage_type == "Tablet" || prescription.dosage_type == "capsule" ||prescription.dosage_type == "capsules" || prescription.dosage_type == "tablet" )
          image = require("../../../../assets/images/Prescription_Icons/icons/Tablet_capsule.svg")
    else if(prescription.dosage_type == "DROPS" ||  prescription.dosage_type == "drops" || prescription.dosage_type == "Drops")
          image = require("../../../../assets/images/Prescription_Icons/icons/drops.png")
    else if(prescription.dosage_type == "Syrup" || prescription.dosage_type == "solution" || prescription.dosage_type == "solutions" || prescription.dosage_type == "suspension" || prescription.dosage_type == "Suspension" || prescription.dosage_type == "syrup" )
          image = require("../../../../assets/images/Prescription_Icons/icons/Syrup_solution.png")
    else if(prescription.dosage_type == "injection" || prescription.dosage_type == "Injection" )
          image = require("../../../../assets/images/Prescription_Icons/icons/injection.svg")
    else
          image = require("../../../../assets/images/Prescription_Icons/icons/Others.png")


          
    // if (prescription.dosage_type == "Tablet") {
    //   image = require("../../../../assets/images/Prescription_Icons/pill.svg")
    // } else {
    //   image = require("../../../../assets/images/Prescription_Icons/injection.svg")
    // }
    return (
      <View key={index} style={[styles.TableDataView, {borderBottomColor: color.textBoxBorderColor}]}>
        <View style={{ flexDirection: "row" }}>
          <View style={[{flexDirection:'row',flex:0.32 },Styles.historyHeaderFontStyleStartText]}>
            <View style={{display: 'flex', alignSelf: 'center', marginRight: '0.5vw'}}>
              <TouchableOpacity onPress={(e)=>{
                    var {prescriptionList} = this.state
                    prescriptionList[index].is_favorite = !prescriptionList[index].is_favorite
                    this.setState({prescriptionList})
                }}>
              <img src={this.state.prescriptionList[index].is_favorite ? require("../../../../assets/images/StarFill.svg") : require("../../../../assets/images/StarUnfill.svg")} alt={"star"}/>
            </TouchableOpacity>
            </View>
          <View style={{justifyContent:"flex-start"}}>
            <Image
              source={image}
              style={styles.TableDataImg}
            />
          </View>

          <View>
            <Tooltip placement="topLeft" title={prescription.brand_name}>               
              <Text style={{fontSize: '0.9vw', fontWeight: "500"}}>
                { prescription.brand_name == null ? null :prescription.brand_name.length > 19 ? prescription.brand_name.slice(0,19) + ".." : prescription.brand_name}
              </Text>
            </Tooltip> 
            <View style={{ flexDirection: 'row', marginTop: '0.4vw',flexWrap:'wrap' }}>
              <Text style={{ fontSize: '0.9vw', borderRightColor: color.textBoxBorderColor, borderRightWidth: 1, paddingRight: 5,overflow:'hidden' }}numberOfLies={1}>{prescription.dosage_strength}</Text>
              <Tooltip placement="topLeft" title={prescription.generic_name}>               
              <Text style={{fontSize: '0.8vw',marginLeft:'0.3vw'}}>
                {prescription.generic_name == null ? null : prescription.generic_name.length > 15 ? prescription.generic_name.slice(0,1) + ".." : prescription.generic_name}
              </Text>
            </Tooltip> 
            </View>
          </View>
          </View>
          <View style={{ flex:0.15  }}>
            <Text style={{ fontSize: '0.9vw' }}>{prescription.morning}{"-"}{prescription.afternoon}{"-"}{prescription.evening}{"-"}{prescription.night}</Text>
            <Text style={{ marginTop: '0.4vw', fontSize: '1vw', marginLeft: '0.1vw' }}>{prescription.duration}  {prescription.duration_mode === '1'? prescription.duration === '1'? "day" : "days":prescription.duration_mode === '2'? prescription.duration === '1'? "week" : "weeks" :prescription.duration_mode === '3'? prescription.duration === '1'? "month" : "months": " "}</Text>
          </View>

          <View style={{ flex:0.42 }}>
            <Text style={{ fontSize: '1vw' }}>{instructions}</Text>
            <Text style={{ marginTop: '0.4vw', fontSize: '0.8vw' }}>{prescription.comments}</Text>
          </View>

        {
          !this.state.prescriptionIsTransmitted ?
        
          <View style={{ flex:0.12 , flexDirection: 'row'}}>
            <TouchableOpacity disabled={this.state.isLocked}  onPress={this.editPrescription.bind(this, prescription)}>
              {this.renderIdButton("pencil")}
            </TouchableOpacity>

            {/* <TouchableOpacity onPress={this.editPrescription.bind(this, prescription)}>
              {this.renderIdButton("heart-o")}
              {this.renderIdButton("heart")}
            </TouchableOpacity> */}

            {/* {
              this.state.prescriptionList.length > 1 ? */}
            <TouchableOpacity disabled={this.state.isLocked}  style={{marginLeft:'1vw'}} onPress={()=>{this.deletePrescription(prescription, index)}}>
              {this.renderIdButton("trash")}
            </TouchableOpacity>
            {/* : null  
          } */}
          </View>
          : null
          }

        </View>
      </View>
    )
  }

  renderFooterComponent() {
    let { userType, prescriptionId } = this.state;
    const dateFormat = 'YYYY-MM-DD';

    return (
      <View style={styles.FooterComponentView}>
        {/* <View style={{ width: "25%" }}>
          <TouchableOpacity
            onPress={() => {
              this.props.showSelectedPrescription(true, {});
            }}
            style={{ paddingRight: 15, paddingLeft: 15, flexDirection: 'row', width: "40%", borderWidth: 1, justifyContent: 'center', alignContent: 'space-between', borderRadius: 20, borderColor: color.textBoxBorderColor, backgroundColor: color.white, }}>
            <Icon name={"plus"} size={25} color={color.textBoxBorderColor} />
            <Text style={{ fontSize: 18, color: color.textBoxBorderColor, marginLeft: 10, marginTop: 2 }}>{"New"}</Text>
          </TouchableOpacity>
        </View> */}

       { this.state.prescriptionList && this.state.prescriptionList.length>0?
       <View style={{flexDirection:"row"}}>
        <View style={styles.FooterComponentView1}>
          <Text style={styles.FooterComponentText}>{"Follow Up date"}</Text>

          <DatePicker
             disabledDate={current => {
              return current && current < moment().add(0, "days");
            }}
            onChange={date => {
              this.convertedDate(date, "prescriptionFollowupDate")
            }}

            format={"DD/MM/YYYY"}

            // defaultValue={moment(this.state.prescriptionFollowupDate, dateFormat)} format={dateFormat} 
            style={{ 
              width: '85%', 
              borderColor: "transparent", 
              height: '2.3vw', 
              borderRadius: '0.6vw', 
              borderWidth: 0,
              fontSize: '1vw'
            }} 
            />
        </View>



        

        {Constants.ROLE_FO !== userType?
        <View style={styles.PharmacyDropDownView}>
          <Picker
            enabled={!this.state.isLocked}
            // selectedValue={this.state.selectedPharmacy['id']}
            style={[styles.PharmacyDropDown,Styles.allButtonBorderRadius]}
            itemStyle={{}}
            onValueChange={(itemvalue) => {
              // var states = this.state.mandatoryInformation;
              // states["state"] = itemvalue;
              this.setState({ selectedPharmacy: itemvalue });
            }}
          >
            <Picker.Item key={-1} label={"pharmacy"} value={0} />
            {this.state.pharmacyList.map((i, index) => (
              <Picker.Item key={index} label={i.pharmacy_name} value={i.pharmacy_id} />
            ))}
          </Picker>
        </View>:null}

        {/* <View style={{ width: "15%" }}>
          <TouchableOpacity onPress={() => {
            this.savePrescriptionDatas()
          }} style={{ marginLeft: 20, paddingBottom: 10, paddingTop: 10, width: "70%", justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: color.themeDark, }}>
            <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Save"}</Text>
          </TouchableOpacity>
        </View> */}

        {Constants.ROLE_FO !== userType?
        <View style={{flexDirection:'row', alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}>
          {/* <TouchableOpacity disabled={this.state.isLocked}  onPress={() => {
            this.transmitPrescription()
           }} style={{ paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: color.themeDark, }}>
            <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Transmit"}</Text>
          </TouchableOpacity> */}

            <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
              this.transmitPrescription()
            }} style={[styles.TransmitBtnTouch, {backgroundColor: color.themeDark,
            }, Styles.allButtonBorderRadius]}>
              <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Transmit"}</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity disabled={this.state.isLocked || this.state.disablePrintButton ? true : false} onPress={() => {
                      this.printPrescription(this.state.prescriptionId)
                    }} style={[{ marginLeft: 20,paddingTop: 8,paddingLeft: 18,
                      paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark, },Styles.allButtonBorderRadius]}>
                      <Text style={[{ color: this.state.disablePrintButton ? color.black : color.white, textAlign: 'center' },Styles.fontSizeMedium]}>{"Print"}</Text>
                    </TouchableOpacity> */}
            <TouchableOpacity
            disabled={this.state.isLocked || this.state.disablePrintButton ? true : false}
            onPress={() => {
              this.printPrescription(prescriptionId)
            }} style={[styles.PrintIconTouch, { backgroundColor: this.state.disablePrintButton ? colors.grey0:color.themeBackgroud}]}

          >
            <Image source={require("../../../../assets/images/Active/Print.png")} style={styles.PrintIconImg} />

          </TouchableOpacity>
        </View>:null}
      </View>:null
     }  
    </View>  
    );
  }


  convertedDate(date, key) {
    var fulldate = new Date(date);
    var converted_date = moment(fulldate).format("YYYY-MM-DD");
    var states = this.state;
    states[key] = converted_date
    this.setState({ states })
  }

  transmitPrescription = () => {

    let { complaintsData,patientAppointment } = this.state;
    let service_url = Constants.MEDICATION_PRESCRIPTION_TRANSMIT;
    // alert(JSON.stringify(service_url))


    var data = {
    "appointment_id": patientAppointment.appointment_id,
    "patient_id": patientAppointment.patient_id,
    "prescription_id": this.state.prescriptionId,
    "pharmacy_id":parseInt(this.state.selectedPharmacy)
    }

    // alert(JSON.stringify(data))

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.transmitPrescriptionSuccess,
        this.transmitPrescriptionFailure
      );
  }

  transmitPrescriptionSuccess= success =>{
    if(success.status=="success"){
      // this.getMedication()
      this.props.transmitStatus(true)
      this.showPrescriptionList();
      this.props.showResposeValue("success",success.message)
    }else{
      this.props.showResposeValue("error",success.message)
    }
  }
  transmitPrescriptionFailure= error =>{
    this.props.showResposeValue("error",error.message)
  }

  removeDuplicate(data){
    var nameListJsonObject =data.map(JSON.stringify); 
    var nameListUniqueSet = new Set(nameListJsonObject); 
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse); 
    return nameListUniqueArray;
  }


  printPrescription(item){

    var service_url = ""
    // userType = this.state;
    var lang = this.state.selectedLanguage.toLowerCase(); 

    if( Constants.ROLE_FO == this.state.userType ) {

      service_url = Constants.FO_OPEN_PDF_PRESCRIPTION +"?prescription_id=" + item;
      // +"&clinic_id="+this.props.selectedClinicOrDoctor.id;
    } else {
      service_url = Constants.OPEN_PDF_PRESCRIPTION + "?prescription_id=" + item+"&clinic_id="+this.props.selectedClinicOrDoctor.id +"&language=" + lang;
    }

   

    OpthamologyService.getInstance().documentUploadGet(
      service_url,
      // this,
      this.openViewSuccess,
      this.openViewFailure,
      "pdf"
    );
  }


  openViewSuccess = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
      // console.log(file)
      window.open(fileURL);
    }
  }

  openViewFailure = (error) =>{
    // console.log("PDF error response");
    console.log(error);
  }


  savePrescriptionDatas() {
  
    this.props.triggerNewItem(isPageName);
    let { userType,prescriptionList,deletedLineIds } = this.state;

    // console.log("prescriptionList ------------> "+JSON.stringify(prescriptionList))
    let service_url = "";

    var todayDate= moment(new Date()).format("YYYY-MM-DD");


         
    // var getListofPrescription=[]
    // for (let i = 0; i < prescription_lines.length; i++) {
    //   var data=prescription_lines[i]

      // data["tempId"]=prescription_lines[i].id;
      //   getListofPrescription.push(data)
      
    // }


    var lineItems = [];
    var deletedLineItemIds = [];

    if(this.state.selectedPreviousDay &&  (todayDate == this.state.selectedPreviousDay.prescription_date ||  this.state.selectedPreviousDay.prescription_date == "" )){

      for (let i = 0; i < prescriptionList.length; i++){
        for (let j = 0; j < deletedLineIds.length; j++) {
          if(deletedLineIds[j] == prescriptionList[i].id){
            deletedLineItemIds.push(prescriptionList[i].id)
          }
        }
      }
      lineItems = prescriptionList;
    }else{
      for (let i = 0; i < prescriptionList.length; i++) {
        // alert(JSON.stringify(prescriptionList[i]))
           var data ={
            "id":prescriptionList[i].id ? prescriptionList[i].id :"",
            "morning": prescriptionList[i].morning,
            "afternoon": prescriptionList[i].afternoon,
            "evening": prescriptionList[i].evening,
            "night": prescriptionList[i].night,
            "duration": prescriptionList[i].duration,
            "duration_mode":prescriptionList[i].duration_mode,
            "instructions": prescriptionList[i].instructions,
            "comments": prescriptionList[i].comments,
            "brand_name": prescriptionList[i].brand_name,
            "generic_name": prescriptionList[i].generic_name,
            "dosage_strength": prescriptionList[i].dosage_strength,
            "dosage_type_id": prescriptionList[i].dosage_type_id,
            "dosage_type": prescriptionList[i].dosage_type,
            "drug_id": prescriptionList[i].drug_id,
            "is_favorite": prescriptionList[i].is_favorite,
          }
          var pharmacy_id = prescriptionList[i].pharmacy_id ? prescriptionList[i].pharmacy_id : this.state.pharmacy_id
        lineItems.push(data)
      }
    }


    // console.log("getPrescriptionListINStart ***************> "+JSON.stringify(this.state.getPrescriptionListINStart))
    // console.log("lineItems ***************> "+JSON.stringify(lineItems))


    // if()
    // alert(this.state.prescriptionId)
    var data = {
      "prescription_id": this.state.prescriptionId,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "patient_id": this.state.patientAppointment.patient_id,
      "followup_date": this.state.prescriptionFollowupDate ?  moment(this.state.prescriptionFollowupDate).format("YYYY-MM-DD") : null,
      // "deleted_line_ids": this.removeDuplicate(deletedLineItemIds),
      "deleted_line_ids": this.state.deletedLineIds,
      "line_items": lineItems,
      "pharmacy_id": pharmacy_id
    }
    // console.log("data ----------- >> " + JSON.stringify(data))
    
    if( Constants.ROLE_FO == userType ) {

      service_url = Constants.FO_PRESCRIPTIONS;
    } else {
      service_url = Constants.DOCTOR_PRESCRIPTIONS;
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.savePrescriptionDatasSuccess,
      this.savePrescriptionDatasFailure
    );

  }
  savePrescriptionDatasSuccess = (success) => {
    if (success.status == "success") {
      this.setState({
        disablePrintButton:false,
        deletedLineIds: []
      })
      this.props.showResposeValue("success", success.message)
      this.getPreviouosPrescriptionDate(); 
      this.showPrescriptionList();
    } else {
      this.props.showResposeValue("error", success.message)

    }
    // console.log("savePrescriptionDatas ---------------> success" + JSON.stringify(success))
  }
  savePrescriptionDatasFailure = (error) => {
    this.props.showResposeValue("error", error.message)

    // console.log("savePrescriptionDatas ---------------> error" + JSON.stringify(error))

  }

  // savePrescriptionDataApiCall(){}


  editPrescription = (prescription, event) => {
    this.setState({
      disablePrintButton:true
    })
    this.props.triggerNewItem(isPageName);
    this.props.showSelectedPrescription(true, prescription);
  }

  deletePrescription(prescription, index){
    // this.props.triggerNewItem(isPageName);
    // console.log(" delete ***************>  > "+JSON.stringify(this.state.getPrescriptionListINStart.length))


    var { prescriptionList,deletedLineIds } = this.state
    if(prescriptionList[index].id){
      deletedLineIds.push(prescriptionList[index].id)
    }
    
    prescriptionList.splice(index, 1);
    
    this.setState({ prescriptionList,deletedLineIds })

    // var todayDate= moment(new Date()).format("YYYY-MM-DD");

    // if(this.state.selectedPreviousDay &&  (todayDate == this.state.selectedPreviousDay.prescription_date ||  this.state.selectedPreviousDay.prescription_date == "" )){
    //   var { prescriptionList, deletedLineIds } = this.state
    //   // if (prescriptionList.length > 1) {
    //     // deletedLineIds.push(prescription.id);
    //     prescriptionList.splice(index, 1);
    //     this.setState({
    //       prescriptionList
    //       // , deletedLineIds
    //     })
    //   // }
    // }else{
    //   var { prescriptionList, deletedLineIds } = this.state
    //   // if (prescriptionList.length > 1) {
    //     prescriptionList.splice(index, 1);
    //     this.setState({
    //       prescriptionList
    //       // , deletedLineIds:[]
    //     })
    //   // }
    // }
  }

  savePrescriptionTemplate = () => {

    let errorMessage = "";
    let { template_name, prescriptionList } = this.state;

    if(!template_name) {
      errorMessage = "Enter template name";
    }
    if(!prescriptionList || prescriptionList.length === 0) {
      errorMessage = errorMessage?errorMessage+",Add drug":"Add drug";
    }

    if(errorMessage){
      this.props.showResposeValue("error", errorMessage)
    } else {

      let {prescriptionList} = this.state;
      var lineItems = [];
    let service_url = "";

    for (let i = 0; i < prescriptionList.length; i++) {
          var data ={
          "morning": parseFloat(prescriptionList[i].morning),
          "afternoon": parseFloat(prescriptionList[i].afternoon),
          "evening": parseFloat(prescriptionList[i].evening),
          "night": parseFloat(prescriptionList[i].night),
          "instructions": prescriptionList[i].instructions,
          "duration": prescriptionList[i].duration,
          "duration_mode":prescriptionList[i].duration_mode,
          "drug_name": prescriptionList[i].brand_name,
          "generic_name": prescriptionList[i].generic_name,
          "dosage_strength": prescriptionList[i].dosage_strength,
          "dosage_type": prescriptionList[i].dosage_type
        }
      lineItems.push(data)
      var pharmacy_id = prescriptionList[i].pharmacy_id ? prescriptionList[i].pharmacy_id : this.state.pharmacy_id

    }

    var data = {
      prescription: lineItems,
      template_name: template_name,
      pharmacy_id: pharmacy_id
    }    

    service_url = Constants.POST_PRESCRIPTION_TEMPLATE;

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.savePrescriptionTemplateSuccess,
      this.savePrescriptionTemplateFailure
    );
  }

  }
  savePrescriptionTemplateSuccess = (success) => {
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message)
      this.showPrescriptionList();
      this.getPrescriptionTemplate()
      this.setState({
        isTemplate: false,
        template_name: ""
      })
    } else {
      this.props.showResposeValue("error", success.message)

    }
    // console.log("savePrescriptionDatas ---------------> success" + JSON.stringify(success))
  }
  savePrescriptionTemplateFailure = (error) => {
    this.props.showResposeValue("error", error.message)

    // console.log("savePrescriptionDatas ---------------> error" + JSON.stringify(error))

  }

  onChangePrescriptionTemplate = (value) => {
    var service_url = Constants.POST_PRESCRIPTION_TEMPLATE+"?template_id="+value;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.onChangePrescriptionTemplateSuccess,
      this.getMedicationTemplateListFailure
    );
  }

  onChangePrescriptionTemplateSuccess = success => {
    this.setState({
      isTemplate: false,
      template_name: ""
    })
    if (success.status == "success") {

      var prescription_lines = success.data
     
      var getListofPrescription=[]
      for (let i = 0; i < prescription_lines.length; i++) {
        var data= {
          "afternoon" : (prescription_lines[i].afternoon).toString(),
          "dosage_strength" : prescription_lines[i].dosage_strength,
          "dosage_type" : prescription_lines[i].dosage_type,
          "duration" : prescription_lines[i].duration,
          "evening" : (prescription_lines[i].evening).toString(),
          "generic_name" : prescription_lines[i].generic_name,
          "morning" : (prescription_lines[i].morning).toString(),
          "night" : (prescription_lines[i].night).toString(),
          "instructions": prescription_lines[i].instructions,
          "brand_name": prescription_lines[i].brand_name,
          "dosage_type_id": prescription_lines[i].dosagetype_id,
          "drug_id": prescription_lines[i].drug_id,
          "comments": "",
          "Template_line_id": prescription_lines[i].id,
          "is_favorite": prescription_lines[i].is_favorite,
          "duration_mode": prescription_lines[i].duration_mode

        }

        data["tempId"]=prescription_lines[i].id;
        data["pharmacy_id"] = success.pharmacy_id;

          getListofPrescription.push(data)
        
      }

      this.setState({
        prescriptionList: getListofPrescription,
      })
    }
  }


  render() {
    return (
      <View style={{ flex: 1 }}>
         

        <View style={{  flexDirection: "row",justifyContent:'space-between',alignItems: 'center', paddingVertical: '0.5vw', borderBottomColor: color.sectionItemLine, borderBottomWidth: 1 }}>

          {this.renderTitleBorder(this.state.isPrescription, pageTitle, isPageName, this.triggerNewItem)}
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <TouchableOpacity style={{display: 'flex', alignItems: 'center', marginRight: '1vw'}} onPress={()=>{this.props.favouriteModelPopup(true)}}>
              <img style={{width: '1.3vw', height: '1.3vw'}} src={require("../../../../assets/images/StarUnfill.svg")} alt={"star"}/>
              <Text style={{fontSize: '0.9vw'}}>Favourites</Text>
            </TouchableOpacity>
          <Picker
              selectedValue={""}
              disabled={this.state.isLocked}
              style={[styles.SelectTemplateDropDown,Styles.allButtonBorderRadius]}
              itemStyle={{ fontSize: '0.8vw' }}
              onValueChange={(itemvalue) => {
                  this.onChangePrescriptionTemplate(itemvalue);
              }}
              // enabled={!this.state.isLocked}
            >
              <Picker.Item key={-1} label={"Select template"} value={0} />
              {this.state.PrescriptionTemplateList.map((i, index) => (
                <Picker.Item key={index} label={i.template_name} value={i.id} />
              ))}
            </Picker>
          </View>

          { this.state.prescriptionList.length>0?

          <View style={{ flexDirection: 'row' }}>
            {/* <View>
              <TouchableOpacity onPress={() => { }} style={{ marginLeft: 20, paddingBottom: 10, paddingTop: 10, width: 130, justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: color.themeDark, }}>
                <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Old Prescription"}</Text>
              </TouchableOpacity>
            </View> */}

            {/* {this.state.prescriptionList.map((item) => {
              return ( */}
              <View style={{flexDirection:"row"}}>

              <Picker
              selectedValue={this.state.selectedLanguage}
              disabled={false}
              style={[styles.SelectLangDropDown,Styles.allButtonBorderRadius]}
              itemStyle={{ fontSize: '0.8vw' }}
              onValueChange={(itemvalue) => {

                this.setState({
                  selectedLanguage: itemvalue
                })
              }}
              enabled={true}
            >
              
              {this.state.languageList.map((i, index) => (
                <Picker.Item key={index} label={i} value={i} />
              ))}
            </Picker>

                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                          this.savePrescriptionDatas()
                        }} style={[styles.DropDownTouch, { backgroundColor: color.themeDark, },Styles.allButtonBorderRadius]}>
                          <Text style={[{ color: color.white, textAlign: 'center' },Styles.fontSizeMedium]}>{"Save"}</Text>
                        </TouchableOpacity>



                  {/* <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                    this.savePrescriptionDatas()
                  }} style={{ marginLeft: 20, paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 8, backgroundColor: color.themeDark, }}>
                    <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Save"}</Text>
                  </TouchableOpacity> */}
                </View>
                <View>


                {/* <TouchableOpacity disabled={this.state.isLocked || this.state.disablePrintButton ? true : false} onPress={() => {
                      this.printPrescription(this.state.prescriptionId)
                    }} style={[{ marginLeft: 20,paddingTop: 8,paddingLeft: 18,
                      paddingRight: 18, paddingBottom: 8, backgroundColor: color.themeDark, },Styles.allButtonBorderRadius]}>
                      <Text style={[{ color: this.state.disablePrintButton ? color.black : color.white, textAlign: 'center' },Styles.fontSizeMedium]}>{"Print"}</Text>
                    </TouchableOpacity> */}

                  {/* <TouchableOpacity disabled={this.state.disablePrintButton} onPress={() => { this.printPrescription(this.state.prescriptionId) }} style={{ marginLeft: 20, paddingBottom: 10, paddingTop: 10, width: 80, justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: this.state.disablePrintButton ? color.disableComponentColor : color.themeDark, }}>
                    <Text style={{ fontSize: 15, color: this.state.disablePrintButton ? color.black : color.white, textAlign: 'center' }}>{"Print"}</Text>
                  </TouchableOpacity> */}
                </View>
              {/* )
            })} */}

            
          </View>:null
       }
        </View>
        <View style={{ marginTop: '0.6vw' }}>
          <CommonHistorySectionHeader
            heading1={"Prescription"}
            heading2={"M-A-E-N"}
            heading3={"Description"}
            heading4={"Action"}
            columnSize={[0.3,0.15,0.42,0.13]}  //total value is == of 1
            noOfColumn={4}
          />

        </View>
        {/* <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <View style={{ flexDirection: 'row' }}>
            {
              this.state.lastPerviousDays.map((date, index) => {
                return this.renderPreviousDates(date, index)
              })
            }
          </View>
        </ScrollView> */}

        <View>
          {
            this.state.prescriptionList.map((prescription, index) => {
              return this.renderprescription(prescription, index)
            })
          }
        </View>

        <View>
          {this.renderFooterComponent()}
        </View>
        {this.state.prescriptionList.length>0?
        <View>
        { this.state.prescriptionList && this.state.prescriptionList.length > 0 && !this.state.isTemplate ?
        <View style={styles.AddToTemplate}>
        <TouchableOpacity disabled={this.state.isLocked}
          onPress={() => {
            this.setState({ isTemplate: true })
          }}
          style={[{alignContent:'flex-end',alignSelf:"flex-end",justifyContent:'flex-end',alignItems:'flex-end',marginRight: 0, backgroundColor: color.themeDark,paddingHorizontal:'1.1vw',paddingVertical:'0.5vw'
          }, Styles.allButtonBorderRadius]}>
          <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Add to template"}</Text>
        </TouchableOpacity>
        </View>
        : 
        <View style={styles.AddToTemplate}>
          <TextInput
            placeholder="Template Name"
            style={styles.TemplateNameTextInput}
            onChangeText={text => this.setState({ template_name: text })}
            value={this.state.template_name}
          />
          <TouchableOpacity disabled={this.state.isLocked}
            onPress={() => {
              this.savePrescriptionTemplate()
            }}
            style={[{marginRight: '1vw', backgroundColor: color.themeDark,paddingHorizontal:'1.1vw',paddingVertical:'0.5vw'
            }, Styles.allButtonBorderRadius]}>
            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Save"}</Text>
          </TouchableOpacity>
          <TouchableOpacity disabled={this.state.isLocked}
            onPress={() => {
              this.setState({
                isTemplate: false,
                template_name: ""
              })
            }}
            style={[{backgroundColor: color.themeDark,paddingHorizontal:'1.1vw',paddingVertical:'0.5vw'
            }, Styles.allButtonBorderRadius]}>
            <Text style={[{ color: color.white, textAlign: 'center' }, Styles.fontSizeMedium]}>{"Cancel"}</Text>
          </TouchableOpacity>
        </View>
        }
        </View>
        : null }

      </View>
    );
  }
}

const styles = StyleSheet.create({

  previousDatesView: {
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    paddingTop: 7,
    paddingBottom: 7,
    marginRight: 20,
    borderRadius: 20,
  },
  SelectTemplateDropDown: { 
    height: '2vw',
    marginRight:'1vw' ,
    alignSelf:'center', 
    paddingLeft: '0.6vw', 
    backgroundColor: "transparent",
    fontSize: '0.9vw' 
  },
  SelectLangDropDown: { 
    height: '2vw',
    marginRight:'1vw' ,
    paddingLeft: '0.6vw', 
    backgroundColor: "transparent",
    fontSize: '0.9vw'
  },
  DropDownTouch: { 
    marginLeft: '1.2vw',
    paddingTop: '0.5vw',
    paddingLeft: '1.1vw',
    paddingRight: '1.1vw', 
    paddingBottom: '0.5vw'
  },
  TableDataView: { 
    marginTop: '1.2vw', 
    marginRight: '2.6vw', 
    marginLeft: '0.3vw', 
    borderBottomWidth: 1, 
    paddingBottom: '0.6vw' 
  },
  TableDataImg: { 
    width: '2.6vw', 
    height: '2.6vw',
    marginRight:'0.6vw' 
  },
  FooterComponentView: { 
    marginRight: '1.2vw', 
    marginLeft: '1.2vw',
    flexDirection: 'row', 
    justifyContent: 'flex-end',
    alignItems:'center' 
  },
  FooterComponentView1: { 
    width: '10.6vw', 
    marginBottom: '1.1vw', 
    marginTop: '1.2vw' 
  },
  FooterComponentText: { 
    position: 'relative', 
    marginTop: '0.6vw', 
    marginLeft: '0.6vw', 
    backgroundColor: "white", 
    fontSize: '0.8vw', 
    paddingLeft: '0.3vw', 
    paddingRight: '0.3vw', 
    color: "#888888" 
  },
  PharmacyDropDownView: { 
    width: '7vw', 
    marginRight: '1.2vw',
    marginTop:'2vw' 
  },
  PharmacyDropDown: { 
    borderRadius: '1.2vw', 
    height: '2.3vw', 
    paddingLeft: '0.6vw', 
    fontSize: '0.8vw', 
    backgroundColor: "transparent" 
  },
  TransmitBtnTouch: {
    marginLeft: '1.2vw', 
    paddingTop: '0.5vw', 
    paddingLeft: '1.1vw',
    paddingRight: '1.1vw', 
    paddingBottom: '0.5vw'
  },
  PrintIconTouch: {
    borderRadius: 4, 
    marginLeft: '0.6vw',
    height:'1.6vw',
    width:'1.6vw',
    justifyContent:"center"
  },
  PrintIconImg: { 
    height: '1.2vw', 
    width: '1.2vw',
    alignSelf:"center"
  },
  AddToTemplate: {
    flexDirection:'row', 
    justifyContent: "flex-end", 
    alignContent: "center", 
    marginTop: '1vw'
  },
  TemplateNameTextInput: { 
    height: '2.3vw', 
    borderColor: color.rightPanelInputBorder, 
    borderWidth: 1, 
    borderRadius: 4, 
    paddingHorizontal: '0.3vw', 
    textAlign: 'justify', 
    marginRight: '1vw',
    fontSize: '0.9vw' 
  }
});