import React, { Component } from 'react'
import {Text, View, StyleSheet, ScrollView, Picker, Dimensions } from 'react-native';
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import AsyncStorage from '../../../../AsyncStorage';

const screenHeight = Dimensions.get("window").height;
export default class DoctorNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DoctorNotesData: [],
      patientAppointment: this.props.patientAppointment,
      SpecList: [],
      SelectedSpec: "",
    }
  }

  async componentDidMount() {
    const userType = await AsyncStorage.getItem("service_type");
    this.getSpecialisationList()
    this.setState({
      SelectedSpec: userType
    }, () => { this.GetDocNotesData() })
  }

  /* get Specialization List API start */
  getSpecialisationList() {
    var serviceUrl = Constants.Specialisation_List

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getSpecListSuccess,
      this.getSpecListFailure
    );
  }
  getSpecListSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        SpecList: success.data
      })
    } else {
      console.log(success.message)
    }
  }
  getSpecListFailure = (error) => {
    this.props.Response(error.message)
  }
  /* get Specialization List API end */

  GetDocNotesData = () => {
    var serviceUrl = Constants.COLLAB_REPORT_DOC_NOTES + "?patient_id=" + this.state.patientAppointment.patient_id + "&specialization=" + this.state.SelectedSpec;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getDataSuccess,
      this.getDataFailure
    );
  }

  getDataSuccess = (success) => {
    this.setState({
      DoctorNotesData: success.datas
    })
  }
  getDataFailure = (error) => {
    console.log(error.message)
  }

  returnDurationMode = (value) => {
    if (value == "1") {
      return "Days"
    }
    else if (value == "2") {
      return "Weeks"
    }
    else if (value == "3") {
      return "Months"
    }

  }

  renderTableBody = (value, item, Keys) => {
    switch (value) {
      case "appointment_date":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length + 1) + "vw").toString(), alignItems: 'center' }]}>
            <Text style={[styles.TableDataText]}>{item[value]}</Text>
          </View>
        )
      case "vitals":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length + 4) + "vw").toString(), }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list) => (
                  this.renderVitals(list)
                ))}
              </View> : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "symptoms":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${list.symptom} (${list.duration}  ${this.returnDurationMode(list.duration_mode)}),`}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "examination":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${list.examination_name} , `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "assessment":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.short_desc} ( ICD : ${list.icd_code}) , `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "prescription":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.brand_name} (${list.dosage_strength}), ${list.dosage_type} , `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
        case "medication":
          return(
            <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.brand_name} (${list.dosage_strength}), ${list.dosage_type} , `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>
          )
      case "treatment":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value].inhouse && item[value].inhouse.length > 0 || item[value].procedure && item[value].procedure.length > 0 ?
              <View>
                <View>
                  <Text style={{ fontSize: "1vw", fontWeight: "500" }}>In-House Treatment</Text>
                  <View style={styles.MapView}>
                    {item[value].inhouse.map((list, index) => (
                      <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.name} (${list.dosage}), ${list.route}`}</Text>
                    ))}
                  </View>
                </View>
                <View>
                  <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Procedure</Text>
                  <View style={styles.MapView}>
                    {item[value].procedure.map((list, index) => (
                      <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.name}, Description: ${list.description}`}</Text>
                    ))}
                  </View>
                </View>
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "complaint":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={styles.MapView}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{` ${index + 1}. ${list.complaints == 1 ? "Right Eye (OD)" : list.complaints == 2 ? "Left Eye (OS)" : "Both Eye (OU)"} - ${list.complaint_description}, (${list.duration_time} ${list.duration_type == 1 ? "days" : list.duration_type == 2 ? "weeks" : list.duration_type == 3 ? "Months" : "Years"}) `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "ocular":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value].medication && item[value].medication.length > 0 ?
              <View style={styles.MapView}>
                {item[value].medication.map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.drug_name} - (${list.years + "Y"}, ${list.months + "M"}), Reason: ${list.comments} `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "illness":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value].illnesses && item[value].illnesses.length > 0 || item[value].allergie && item[value].allergie.length > 0 ?
              <View>
                <View style={styles.MapView}>
                  <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Illness</Text>
                  {item[value].illnesses.map((list, index) => (
                    <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.illness_name} - (${list.year + "Y"}, ${list.month + "M"}), Treatment: ${list.treatment} `}</Text>
                  ))}
                </View>
                <View style={styles.MapView}>
                  <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Drug Allergie</Text>
                  {item[value].allergie.map((list, index) => (
                    <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.name} , Comments: ${list.comments} `}</Text>
                  ))}
                </View>
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "auto_refractometry":
        var BeforeDilation = []
        var AfterDilation = []
        for (var i = 0; i < item[value].length; i++) {
          if (item[value][i].is_before_dilation == true) {
            BeforeDilation.push(item[value][i])
          } else if (item[value][i].is_before_dilation == false) {
            AfterDilation.push(item[value][i])
          }
        }
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length + 8) + "vw").toString() }]}>
            {BeforeDilation && BeforeDilation.length > 0 || AfterDilation && AfterDilation.length > 0 ?
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={[styles.MapView]}>
                  {BeforeDilation && BeforeDilation.length > 0 ?
                    <Text style={{ fontSize: "1.2vw", fontWeight: "600" }}>Before Dilation</Text> : null}
                  {BeforeDilation.map((list) => (
                    this.BeforeDilation(list)
                  ))}
                </View>
                <View style={[styles.MapView, { marginLeft: ".85vw" }]}>
                  {AfterDilation && AfterDilation.length > 0 ?
                    <Text style={{ fontSize: "1.2vw", fontWeight: "600" }}>After Dilation</Text> : null}
                  {AfterDilation.map((list) => (
                    this.AfterDilation(list)
                  ))}
                </View>
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "pg_power":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length + 2) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.PreviousGlassPower(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "subjective_refraction":
        return (<View style={[styles.RowContentView, { width: ((200 / Keys.length + 3) + "vw").toString() }]}>
          {item[value] && item[value].length > 0 ?
            <View style={[styles.MapView]}>
              {item[value].map((list) => (
                this.subjectiverefraction(list)
              ))}
            </View>
            : <Text style={[styles.TableDataText]}>-</Text>}
        </View>)
      case "retinoscopic_refraction":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.retinoscopic(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "intraocular_pressure":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length + 4) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.intraocular(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "anterior_segment":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.anteriorSegment(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "posterior_segment":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.posteriorSegment(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "fundus":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.fundus(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "glass_prescription":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.glassPrescription(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "investigation":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Eye Test</Text>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.test_name}`}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "allergies":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.allergy_type == 1 ? "Medication Allergy" : "Non-Medication Allergy"} - Allergy Name: ${list.name} `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "test":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.test__type == 1 ? "Laboratory TesT" : list.test__type == 2 ? "Radiology Test" : "Special Test"} - Test Name: ${list.test__name} `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>
        )
      case "advice":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`Advice Given: ${list.advice_given} `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "vision_assessment":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list) => (
                  this.visionAssessment(list)
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>)
      case "treatment_plan":
        return (
          <View style={[styles.RowContentView, { width: ((200 / Keys.length) + "vw").toString() }]}>
            {item[value] && item[value].length > 0 ?
              <View style={[styles.MapView]}>
                {item[value].map((list, index) => (
                  <Text style={[styles.TableDataText, { marginVertical: '0.3vw' }]}>{`${index + 1}. ${list.disease_name} , Comments: ${list.comments} `}</Text>
                ))}
              </View>
              : <Text style={[styles.TableDataText]}>-</Text>}
          </View>
        )
    }
  }

  glassPrescription = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.DV_SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.DV_SPH},`}</Text> : <Text>-</Text>}
            {list.DV_CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.DV_CYL},`}</Text> : <Text>-</Text>}
            {list.DV_AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.DV_AXIS},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.DV_BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.DV_BCDVA},`}</Text> : <Text>-</Text>}
            {list.DV_BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.DV_BCNVA},`}</Text> : <Text>-</Text>}
            {list.DV_PRISM != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PRISM : ${list.DV_PRISM},`}</Text> : <Text>-</Text>}
            {list.DV_BASE != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BASE : ${list.DV_BASE}`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.DV_SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.DV_SPH},`}</Text> : <Text>-</Text>}
            {list.DV_CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.DV_CYL},`}</Text> : <Text>-</Text>}
            {list.DV_AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.DV_AXIS},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.DV_BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.DV_BCDVA},`}</Text> : <Text>-</Text>}
            {list.DV_BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.DV_BCNVA},`}</Text> : <Text>-</Text>}
            {list.DV_PRISM != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PRISM : ${list.DV_PRISM},`}</Text> : <Text>-</Text>}
            {list.DV_BASE != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BASE : ${list.DV_BASE}`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  fundus = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.media != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Media : ${list.media},`}</Text> : <Text>-</Text>}
            {list.optic_disc != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Optic Disc : ${list.optic_disc},`}</Text> : <Text>-</Text>}
            {list.macula != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Macula : ${list.macula},`}</Text> : <Text>-</Text>}
            {list.cd_ratio != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CD Ratio : ${list.cd_ratio}`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.media != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Media : ${list.media},`}</Text> : <Text>-</Text>}
            {list.optic_disc != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Optic Disc : ${list.optic_disc},`}</Text> : <Text>-</Text>}
            {list.macula != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Macula : ${list.macula},`}</Text> : <Text>-</Text>}
            {list.cd_ratio != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CD Ratio : ${list.cd_ratio}`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  posteriorSegment = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.disc != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Disc : ${list.disc},`}</Text> : <Text>-</Text>}
            {list.macula != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Macula : ${list.macula},`}</Text> : <Text>-</Text>}
            {list.media != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Media : ${list.media},`}</Text> : <Text>-</Text>}
            {list.vessels != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Vessels : ${list.vessels},`}</Text> : <Text>-</Text>}
            {list.periphery != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Periphery : ${list.periphery},`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.disc != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Disc : ${list.disc},`}</Text> : <Text>-</Text>}
            {list.macula != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Macula : ${list.macula},`}</Text> : <Text>-</Text>}
            {list.media != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Media : ${list.media},`}</Text> : <Text>-</Text>}
            {list.vessels != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Vessels : ${list.vessels},`}</Text> : <Text>-</Text>}
            {list.periphery != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Periphery : ${list.periphery},`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  anteriorSegment = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.lids != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Lids : ${list.lids},`}</Text> : <Text>-</Text>}
            {list.conjuctiva != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Conjuctiva : ${list.conjuctiva},`}</Text> : <Text>-</Text>}
            {list.sclera != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Sclera : ${list.sclera},`}</Text> : <Text>-</Text>}
            {list.cornea != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Cornea : ${list.cornea},`}</Text> : <Text>-</Text>}
            {list.anterior_chamber != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Anterior Chamber : ${list.anterior_chamber},`}</Text> : <Text>-</Text>}
            {list.pupil != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Pupil : ${list.pupil},`}</Text> : <Text>-</Text>}
            {list.iris != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Iris : ${list.iris},`}</Text> : <Text>-</Text>}
            {list.lens != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Lens : ${list.lens}`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.lids != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Lids : ${list.lids},`}</Text> : <Text>-</Text>}
            {list.conjuctiva != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Conjuctiva : ${list.conjuctiva},`}</Text> : <Text>-</Text>}
            {list.sclera != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Sclera : ${list.sclera},`}</Text> : <Text>-</Text>}
            {list.cornea != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Cornea : ${list.cornea},`}</Text> : <Text>-</Text>}
            {list.anterior_chamber != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Anterior Chamber : ${list.anterior_chamber},`}</Text> : <Text>-</Text>}
            {list.pupil != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Pupil : ${list.pupil},`}</Text> : <Text>-</Text>}
            {list.iris != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Iris : ${list.iris},`}</Text> : <Text>-</Text>}
            {list.lens != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Lens : ${list.lens}`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  intraocular = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.APPLANATION_TONOMETRY != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`APPLANATION_TONOMETRY : ${list.APPLANATION_TONOMETRY},`}</Text> : <Text>-</Text>}
            {list.CCT != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CCT : ${list.CCT},`}</Text> : <Text>-</Text>}
            {list.CIOP != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CIOP : ${list.CIOP},`}</Text> : <Text>-</Text>}
            {list.NCT != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`NCT : ${list.NCT},`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.APPLANATION_TONOMETRY != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`APPLANATION_TONOMETRY : ${list.APPLANATION_TONOMETRY},`}</Text> : <Text>-</Text>}
            {list.CCT != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CCT : ${list.CCT},`}</Text> : <Text>-</Text>}
            {list.CIOP != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CIOP : ${list.CIOP},`}</Text> : <Text>-</Text>}
            {list.NCT != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`NCT : ${list.NCT},`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  retinoscopic = (list) => {
    return (
      <View>
        <View>
          {list?.eye_type == "RE" ?
            <View>
              <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
              {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
              {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
              {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
              {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
              {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
              {list.UCVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UCVA : ${list.UCVA},`}</Text> : <Text>-</Text>}
              {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
              {list.chart_used ? <Text style={[styles.TableDataText]}>{`Chart Used : ${list.chart_used}`}</Text> : <Text> - </Text>}
              {list.occupation ? <Text style={[styles.TableDataText]}>{`Occupation : ${list.occupation},`}</Text> : <Text> - </Text>}
              {list.muscle ? <Text style={[styles.TableDataText]}>{`Muscle : ${list.muscle}`}</Text> : <Text> - </Text>}
            </View>
            : null}
          {list?.eye_type == "LE" ?
            <View style={{ marginTop: ".5vw" }}>
              <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
              {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
              {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
              {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
              {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
              {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
              {list.UCVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UCVA : ${list.UCVA},`}</Text> : <Text>-</Text>}
              {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
              {list.chart_used ? <Text style={[styles.TableDataText]}>{`Chart Used : ${list.chart_used}`}</Text> : <Text> - </Text>}
              {list.occupation ? <Text style={[styles.TableDataText]}>{`Occupation : ${list.occupation},`}</Text> : <Text> - </Text>}
              {list.muscle ? <Text style={[styles.TableDataText]}>{`Muscle : ${list.muscle}`}</Text> : <Text> - </Text>}
            </View>
            : null}
        </View>
      </View>
    )
  }

  subjectiverefraction = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
            {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
            {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
            {list.UC != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UC : ${list.UC},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.occupation ? <Text style={[styles.TableDataText]}>{`Occupation : ${list.occupation},`}</Text> : <Text> - </Text>}
            {list.chart_used ? <Text style={[styles.TableDataText]}>{`Chart Used : ${list.chart_used},`}</Text> : <Text> - </Text>}
            {list.muscle ? <Text style={[styles.TableDataText]}>{`Muscle : ${list.muscle}`}</Text> : <Text> - </Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
            {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
            {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
            {list.UC != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UC : ${list.UC},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.occupation ? <Text style={[styles.TableDataText]}>{`Occupation : ${list.occupation},`}</Text> : <Text> - </Text>}
            {list.chart_used ? <Text style={[styles.TableDataText]}>{`Chart Used : ${list.chart_used},`}</Text> : <Text> - </Text>}
            {list.muscle ? <Text style={[styles.TableDataText]}>{`Muscle : ${list.muscle}`}</Text> : <Text> - </Text>}
          </View>
          : null}
      </View>
    )
  }

  PreviousGlassPower = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
            {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
            {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.frame_type ? <Text style={[styles.TableDataText]}>{`Frame Type : ${list.frame_type},`}</Text> : <Text> - </Text>}
            {list.date ? <Text style={[styles.TableDataText]}>{`Date : ${list.date}`}</Text> : <Text> - </Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
            {list.BCDVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCDVA : ${list.BCDVA},`}</Text> : <Text>-</Text>}
            {list.BCNVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BCNVA : ${list.BCNVA},`}</Text> : <Text>-</Text>}
            {list.add != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`ADD : ${list.add},`}</Text> : <Text>-</Text>}
            {list.frame_type ? <Text style={[styles.TableDataText]}>{`Frame Type : ${list.frame_type},`}</Text> : <Text> - </Text>}
            {list.date ? <Text style={[styles.TableDataText]}>{`Date : ${list.date}`}</Text> : <Text> - </Text>}
          </View>
          : null}
      </View>
    )
  }

  AfterDilation = (list) => {

    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  BeforeDilation = (list) => {

    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.AXIS != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`AXIS : ${list.AXIS},`}</Text> : <Text>-</Text>}
            {list.CYL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CYL : ${list.CYL},`}</Text> : <Text>-</Text>}
            {list.SPH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`SPH : ${list.SPH},`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  visionAssessment = (list) => {
    return (
      <View>
        {list?.eye_type == "RE" ?
          <View>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Right Eye</Text>
            {list.UCVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UCVA : ${list.UCVA},`}</Text> : <Text>-</Text>}
            {list.PH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PH : ${list.PH},`}</Text> : <Text>-</Text>}
            {list.PGCL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PG/CL : ${list.PGCL},`}</Text> : <Text>-</Text>}
            {list.NV != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`NV : ${list.NV},`}</Text> : <Text>-</Text>}
            {list.CV != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CV : ${list.CV},`}</Text> : <Text>-</Text>}
          </View>
          : null}
        {list?.eye_type == "LE" ?
          <View style={{ marginTop: ".5vw" }}>
            <Text style={{ fontSize: "1vw", fontWeight: "500" }}>Left Eye</Text>
            {list.UCVA != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`UCVA : ${list.UCVA},`}</Text> : <Text>-</Text>}
            {list.PH != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PH : ${list.PH},`}</Text> : <Text>-</Text>}
            {list.PGCL != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`PG/CL : ${list.PGCL},`}</Text> : <Text>-</Text>}
            {list.NV != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`NV : ${list.NV},`}</Text> : <Text>-</Text>}
            {list.CV != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`CV : ${list.CV},`}</Text> : <Text>-</Text>}
          </View>
          : null}
      </View>
    )
  }

  renderVitals = (list) => {
    return (
      <View>
        {list.weight_kg != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Weight : ${list.weight_kg} Kg,`}</Text> : null}
        {list.height_cm != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Height : ${list.height_cm} cm,`}</Text> : null}
        {list.bmi != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`BMI : ${list.bmi},`}</Text> : null}
        {list.blood_pressure_systolic != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Systolic Blood Pressure  : ${list.blood_pressure_systolic} mm/Hg,`}</Text> : null}
        {list.blood_pressure_diastolic != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Diastolic Blood Pressure : ${list.blood_pressure_diastolic} Kg,`}</Text> : null}
        {list.temperature != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Temperature : ${list.temperature} ${list.temperature_unit},`}</Text> : null}
        {list.pulse_rate != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Pulse Rate : ${list.pulse_rate} /min,`}</Text> : null}
        {list.pulse_oximetry != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Pulse Oximetry : ${list.pulse_oximetry} %,`}</Text> : null}
        {list.blood_sugar != null ? <Text style={[styles.TableDataText, { marginVertical: '0.2vw' }]}>{`Random Blood Glucose : ${list.blood_sugar} mg/dl,`}</Text> : null}
      </View>
    )
  }
  render() {
    const Specialisation = (spec) => {
      if (spec == "DOCTOR") {
        return "General Physician"
      }
      else if (spec == "DB") {
        return "Diabetology"
      }
      else if (spec == "PD") {
        return "Pediatric"
      }
      else if (spec == "OBGYN") {
        return "Gynaecology"
      }
      else if (spec == "PSY") {
        return "Psychiatric"
      }
      else if (spec == "OPT") {
        return "Ophthalmology"
      }
      else {
        return spec
      }
    }
    const Keys = (this.state?.DoctorNotesData && this.state.DoctorNotesData.length > 0) ? Object.keys(this.state.DoctorNotesData[0]) : []
    return (
      <View>
        <View>
          <Picker
            selectedValue={this.state.SelectedSpec}
            style={styles.PickerStyle}
            onValueChange={(itemvalue) => {
              this.setState({
                SelectedSpec: itemvalue
              }, () => { this.GetDocNotesData() })
            }}
          >
            {this.state.SpecList.length > 0 ? this.state.SpecList.map((list) => (
              <Picker.Item label={Specialisation(list.role_name)} value={list.role_name} />
            )) : null}
          </Picker>
        </View>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={true}>
          <View style={styles.TableContainer}>
            <View>
              <View style={{ width: "100%", borderBottomWidth: 1 }}>
                <View style={[styles.TableTitleContainer]}>

                  {Keys.map((list) => (
                    <View style={{
                      display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                      width: list == "appointment_id" ? "" : list == "vitals" ? ((200 / Keys.length + 4) + "vw").toString() : list == "appointment_date" ? ((200 / Keys.length + 1) + "vw").toString() :
                        list == "pg_power" ? ((200 / Keys.length + 2) + "vw").toString() :
                          list == "subjective_refraction" ? ((200 / Keys.length + 3) + "vw").toString() : list == "intraocular_pressure" ? ((200 / Keys.length + 4) + "vw").toString() : list == "auto_refractometry" ? ((200 / Keys.length + 8) + "vw").toString() :
                            ((200 / Keys.length) + "vw").toString()
                    }}>
                      <Text style={[styles.TableHeadText]}>{list == "appointment_id" ? null : list.replace("_", " ").split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1) + " ")}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            <ScrollView style={styles.MainTable}>
              {this.state.DoctorNotesData && this.state.DoctorNotesData.length > 0 ? this.state.DoctorNotesData.map((item, index) => {
                return (
                  <View style={[styles.TableDataWrapper]}>
                    {
                      Keys.map((value, index) =>
                      (
                        this.renderTableBody(value, item, Keys)
                      )
                      )
                    }
                  </View>
                )
              }) :
                <View style={styles.NoRecordsView}>
                  <Text style={styles.NoRecordsText}>No Records to show...</Text>
                </View>}

            </ScrollView>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableContainer: {
    marginRight: '1vw',
    marginTop: '0.5vw',
    borderWidth: 1,
    borderRadius: '0.26vw'
  },
  TableHeadText: {
    fontSize: '0.91vw',
    color: "black",
    fontWeight: '500'
  },
  TableDataText: {
    fontSize: '0.91vw'
  },
  TableDataView: {
    justifyContent: 'center',
    maxHeight: '2.9vw',
    minHeight: '2.9vw',
    width: "100%",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingLeft: ".5vw",
    borderTopLeftRadius: '0.26vw',
    borderTopRightRadius: '0.26vw'
  },
  TableDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    minHeight: "3vw",
    paddingLeft: ".5vw"
  },
  MainTable: {
    height: screenHeight - 270
  },
  MapView: {
    display: 'flex', flexDirection: 'column', marginVertical: '0.5vw', textAlign: 'left'
  },
  RowContentView: {
    height: '100%', borderRightWidth: 1, alignItems: 'flex-start', display: 'flex', justifyContent: 'center', paddingHorizontal: '0.5vw'
  },
  NoRecordsView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5vw',
    width: "100%"
  },
  NoRecordsText: {
    textAlign: "center",
    fontSize: '1vw',
    width: "80vw"
  },
  PickerStyle: {
    height: "2.27vw",
    marginRight: '2vw',
    margin: '0.32vw',
    backgroundColor: "transparent",
    borderRadius: '0.26vw',
    flex: 0.5,
    borderColor: 'rgb(4, 183, 177)',
    width: '10vw',
    fontSize: '0.91vw',
    zIndex: 10,
  }
})
