import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import { CommonDatePicker, } from './registryCommonComponents'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService"
import { Constants } from "../../../../utils/Constants";

import { RegistryContext } from "./RegistryContext";





export class RegistryDignosis extends Component {
    constructor(props) {
        super(props);
        this.state = {



            ICDCodeSearchList: [],
            longDescriptionSearchList: [],
            shortDescriptionSearchList: [],
            ICDCodeViewFlag: false,
            longDescriptionViewFlag: false,
            shortDescriptionViewFlag: false,

            ICDCodeNewText: "",
            shortDescriptionNewText: "",
            longDescriptionNewText: "",

            selectedICDCode: "",
            selectedICDList: []

        };
    }



    icdCodeSearch = (query, type) => {

        if (query !== "") {
            var serviceUrl = ""

            if (type == "ICDCodeNewText")
                serviceUrl = Constants.ASSESSMENT_ICD_CODE_GET + "?icd_code=" + query;
            else if (type == "shortDescriptionNewText")
                serviceUrl = Constants.ASSESSMENT_ICD_CODE_GET + "?desc=" + query;



            OpthamologyService.getInstance().getComplaints(
                serviceUrl,
                this,
                this.icdCodeSearchSuccess,
                this.icdCodeSearchFailure
            );
        } else {


            var fields = this.state;

            if (fields.ICO)
                fields["ICDCodeSearchList"] = [];
            else if (fields.longDescriptionIsEdit)
                fields["longDescriptionSearchList"] = []
            else if (fields.shortDescriptionIsEdit)
                fields["shortDescriptionSearchList"] = []


            this.setState({
                fields,
                ICDCodeViewFlag: false,
                longDescriptionViewFlag: false,
                shortDescriptionViewFlag: false
            });
        }
    };

    icdCodeSearchSuccess = response => {

        var fields = this.state;

        if (fields.ICDCodeNewText)
            fields["ICDCodeSearchList"] = response.data;
        else if (fields.longDescriptionIsEdit)
            fields["longDescriptionNewText"] = response.data;
        else if (fields.shortDescriptionNewText)
            fields["shortDescriptionSearchList"] = response.data;

        this.setState({
            fields,
        });
    };

    icdCodeSearchFailure = error => {
        // console.log("search patient error response");
        console.log(error);
    }








    onPressselectedArea(item, contaxt) {

        var { selectedICDList } = this.state;

        selectedICDList.push(item)



        selectedICDList = this.removeDuplicate(selectedICDList)
        this.setState({ selectedICDList }, () => {
            contaxt(this.state.selectedICDList)
            this.setState({
                ICDCodeSearchList: [],
                longDescriptionSearchList: [],
                shortDescriptionSearchList: [],
                ICDCodeNewText: "",
                shortDescriptionNewText: "",
                longDescriptionNewText: "",


            })
        })




    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }





    renderCommonTextBox(placeholder, value, textBoxkey) {
        return (
            <View style={[{ height: '2.81vw', marginRight: 15, marginLeft: 15 }]}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>


                <TextInput
                    style={{
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: '2vw',
                        fontSize:'0.9vw',
                        padding: 5,
                        marginTop: 8
                    }}
                    multiline={true}
                    value={value}
                    onChangeText={(text) => {
                        var states = this.state;
                        states[textBoxkey] = text;
                        this.setState({ states }, () => {
                            if (text) {
                                this.icdCodeSearch(text, textBoxkey)
                            } else if (text == "") {
                                this.setState({
                                    ICDCodeSearchList: [],
                                    longDescriptionSearchList: [],
                                    shortDescriptionSearchList: [],
                                })
                            }
                        })
                    }}
                />




            </View>
        )
    }



    shoeSelectedIcdCode(item, index, contaxt) {
        return (
            <View key={index}>

                <View style={[{
                    borderWidth: this.state.selectedICDCode == item.icd_code ? 0 : 1,
                    borderRadius: '0.26vw',
                    paddingTop: '0.32vw',
                    marginLeft: '0.97vw',
                    paddingLeft: '1.3vw',
                    paddingRight: '1.3vw',
                    marginTop: '0.65vw',
                    paddingBottom: "0.35vw",
                    flexDirection: 'row',
                    // justifyContent:'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderColor: "#888888",
                    backgroundColor: this.state.selectedICDCode == item.icd_code ? color.themeDark : ""
                },
                ]}
                >
                    <TouchableOpacity onPress={() => {
                        var states = this.state;
                        states["selectedICDCode"] = item.icd_code
                        states["ICDCodeNewText"] = item.icd_code;
                        states["longDescriptionNewText"] = item.icd_desc;
                        states["shortDescriptionNewText"] = item.icd_short_desc;
                        this.setState({ states }, () => {
                        })
                    }}>
                        <Text style={{ color: this.state.selectedICDCode == item.icd_code ? color.white : "black", fontSize:'0.9vw' }}>
                            {item.icd_code}
                        </Text>
                    </TouchableOpacity>
                    <View style={{ height: ' 0.97vw', width: 1, backgroundColor: this.state.selectedICDCode == item.icd_code ? color.white : color.black, marginHorizontal: '0.65vw' }} />
                    <TouchableOpacity onPress={() => {
                        let { selectedICDList } = this.state;
                        selectedICDList.splice(index, 1)
                        this.setState({
                            selectedICDList,
                            ICDCodeNewText: "",
                            longDescriptionNewText: "",
                            shortDescriptionNewText: ""
                        }, () => {
                            contaxt(this.state.selectedICDList)
                        })

                    }}>
                        <Text style={{ color: this.state.selectedICDCode == item.icd_code ? color.white : "black" , fontSize:'0.9vw'}}>{"X"}</Text>
                    </TouchableOpacity>
                </View>

            </View>
        )
    }

    renderICDCODE(dignosis,contaxt) {
        return (
            <View style={{ marginBottom: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"ICD Code"}
                    columnSize={[1]}
                    noOfColumn={1}
                />

                {
                    dignosis.length > 0 ?
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                               dignosis.map((item, index) => {
                                    return this.shoeSelectedIcdCode(item, index, contaxt)
                                })
                            }
                        </View>
                        : null
                }

                <View>
                    {this.renderCommonTextBox("", this.state.ICDCodeNewText, "ICDCodeNewText")}
                </View>
                {
                    this.state.ICDCodeSearchList.length > 0 ?

                        <View style={{
                            backgroundColor: color.white,
                            maxHeight: "",
                            borderRadius: '0.65vw',
                            width: '90%',
                            justifyContent: 'center',
                            alignContent: 'center',
                            padding: '0.65vw',
                            marginLeft: '1.62vw',
                            marginTop: '5.2vw',
                            position: 'absolute',
                        }}>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '13vw' }}>
                                <FlatList
                                    data={this.state.ICDCodeSearchList}
                                    renderItem={({ item }) => (
                                        <View style={{
                                            flexDirection: "row",
                                            bottom: '0.65vw',
                                            marginBottom: '0.65vw',
                                            justifyContent: "space-evenly",
                                            width: "100%",
                                            alignItems: "center",
                                            marginTop: '0.65vw',
                                            zIndex: 10,
                                        }}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.onPressselectedArea(item, contaxt);

                                                    }
                                                    }>
                                                    <Text style={{fontSize:'0.9vw'}}>{item.icd_code}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}


                                //keyExtractor={(item, index) => index.toString()}
                                />
                            </ScrollView>
                        </View> : null}


            </View>
        )
    }

    renderShortDescription(context) {
        return (
            <View style={{ marginBottom: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Short Description"}
                    columnSize={[1]}
                    noOfColumn={1}
                />


                <View>
                    {this.renderCommonTextBox("", this.state.shortDescriptionNewText, "shortDescriptionNewText")}
                </View>
                {
                    this.state.shortDescriptionSearchList.length > 0 ?
                        <View style={{
                            backgroundColor: color.white,
                            maxHeight: "",
                            borderRadius: '0.65vw',
                            width: '90%',
                            justifyContent: 'center',
                            alignContent: 'center',
                            padding: '0.65vw',
                            marginLeft: '1.62vw',
                            marginTop: '5.2vw',
                            position: 'absolute',
                        }}>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '13vw' }}>
                                <FlatList
                                    data={this.state.shortDescriptionSearchList}
                                    renderItem={({ item }) => (
                                        <View style={{
                                            flexDirection: "row",
                                            bottom: '0.65vw',
                                            marginBottom: '0.65vw',
                                            justifyContent: "space-evenly",
                                            width: "100%",
                                            alignItems: "center",
                                            marginTop: '0.65vw',
                                            zIndex: 10,
                                        }}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.onPressselectedArea(item, context);
                                                    }
                                                    }>
                                                    <Text  style={{fontSize:'0.9vw'}}>{item.icd_short_desc}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}


                                //keyExtractor={(item, index) => index.toString()}
                                />
                            </ScrollView>
                        </View>
                        : null}
            </View>
        )
    }

    renderLongDescription() {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Long Description"}
                    columnSize={[1]}
                    noOfColumn={1}
                />

                <View>
                    {this.renderCommonTextBox("", this.state.longDescriptionNewText, "longDescriptionNewText")}
                </View>

            </View>
        )
    }

    renderFooterAction(contectCallBack) {
        var actions = []

        actions = [
            { label: "Apply", value: "Apply" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15 }}>
                {
                    actions.map((item, index) => {
                        return (
                            <View style={{ marginRight: 15 }}>
                                <CommonButton
                                    // disable={this.state.isLocked}
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={item.label}
                                    buttonType={item.value == "Apply" ? "theme" : "outlineTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={item.value} />
                            </View>
                        )
                    })
                }

            </View>
        )
    }

    onPressButton(contectCallBack = "", checkIsCallBack = false, key, value) {
        if (checkIsCallBack) {
            contectCallBack(key, value);
        }
        if (key == "Apply") {

        }
        else if (key == "clear") {
            this.setState({
                ICDCodeViewFlag: false,
                longDescriptionViewFlag: false,
                shortDescriptionViewFlag: false,

                ICDCodeNewText: "",
                shortDescriptionNewText: "",
                longDescriptionNewText: "",

                selectedICDCode: "",
                selectedICDList: []
            })
        }

    }

    render() {
        return (
            <RegistryContext.Consumer>
                {(getContext) => {
                    var dignosis = getContext.diagnosisDetials;

                    return (
                        <>
                            <View>
                                <View style={{ zIndex: 10 }}>
                                    {this.renderICDCODE(dignosis,getContext.diagnosisdata.bind(this))}
                                </View>
                                <View style={{ zIndex: 1 }}>
                                    {this.renderShortDescription(getContext.diagnosisdata.bind(this))}
                                </View>
                                <View style={{ zIndex: -1 }}>
                                    {this.renderLongDescription()}
                                </View>

                                <View style={{ zIndex: -1 }}>
                                    {this.renderFooterAction(getContext.diagnosisdata.bind(this))}
                                </View>
                            </View>
                        </>
                    )

                }

                }
            </RegistryContext.Consumer>
        );
    }
}
