//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, TouchableWithoutFeedback, Dimensions, ScrollView, PanResponder, TextInput } from 'react-native';
import { color } from "./styles/Color";
import { SideMenuComponents } from '../src/ui/SideMenu'
import AsyncStorage from "./AsyncStorage";
// import Arrow from "react-native-vector-icons/SimpleLineIcons";
import { Checkbox } from "antd";

import { SimpleLineIcons } from '@expo/vector-icons'
import moment from "moment";
const screenHeight = Dimensions.get("window").height;

// import SignInScreen from "../src/ui/SignIn";
import HomeScreen from "../src/ui/doctor/common/Home";
import AllBillingTransactions from "../src/ui/doctor/common/billing/AllTransactions";

import OTlist from "../src/ui/doctor/common/OTlist"
// import ReportsScreen from "../src/ui/doctor/common/Reports";
// import Registry from "../src/ui/doctor/common/Registry";
// import CreatePatient from "../src/ui/doctor/common/CreatePatient";
// import AuthLoadingScreen from "../src/ui/AuthLoading";
import DoctorNotes from "../src/ui/doctor/opthamology/DoctorNotes";
// import SymptomsScreen from "../src/ui/doctor/common/Symptoms";
import MessageScreen from "../src/ui/doctor/common/Message";
import SendMessageScreen from "../src/ui/doctor/common/SendMessage";
import BillingScreen from "../src/ui/doctor/common/billing/BillingScreen";
import Demographics from "../src/ui/doctor/common/Demographics"
import FormsAndCertificate from '../src/Forms&Certificate'
import ShowPatientBilling from '../src/ui/doctor/common/billing/ShowPatientBilling'

import Reports from '../src/ui/doctor/common/reports/index'
import { ShowDocumentImage } from './ui/doctor/opthamology/ShowDocumentImage'
import NextAppointment from "../src/ui/doctor/common/NextAppointment";
import WaitingComments from "../src/ui/doctor/common/WaitingComments";
import CancelAppointment from "../src/ui/doctor/common/CancelAppointment";

import {PharmacyBillingTransactionPopup, MarkOutofOfficeModal, ShowShiftCounterList, ChangePassword, AppointmentRequest, UnlockDoctorNotes, CommonHeader, BedChargeModel, BillingTransactionPopup, TakePickerConst, LabTransmitedChanges, LabBillingTransactionPopup, LabSavedBill, FaceRecognitionRespModal, AllModuleBillingTransactionPopup, ImageLabBillingTxnPopup } from './../src/ui/doctor/BaseComponent'

import Service from "../src/network/Service";

import OpthamologyService from "../src/network/OpthamologyService";
import { Constants } from "../src/utils/Constants";
import { ObstetricSonographyReport } from '../src/ui/doctor/common/reports/ObstetricSonographyReport'
import { UltraSoundAbdomenReport } from '../src/ui/doctor/common/reports/UltraSoundAbdomenReport'

import { OBGYNHistory } from './ui/doctor/OBGYN/History'
import { Ovulation } from './ui/doctor/OBGYN/Ovulation/'
import { ObgynChart } from './ui/doctor/OBGYN/ObgynChart'
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";

import DoctorProfile from './ui/doctor/common/DoctorProfile.js'
import ScheduleScreen from './ui/doctor/common/ScheduleScreen.js'

import { DBHistory } from './ui/doctor/DB/DBHistory'
import { DBHistoryChart } from './ui/doctor/DB/ChartLog'

import { History } from './ui/doctor/DB/History'

import axios from "axios"

import { PDHistory } from './ui/doctor/PD/PDHistory'
import { DevlopmentAssessment } from './ui/doctor/PD/DevelopmentAssessment/DevlopmentAssessment'

// import Result from './ui/doctor/reports/report'
import ReportIndex from './ui/doctor/reports/Index'
import ServiceDetailed from './ui/doctor/reports/ServiceDetailed';
import PatientTypeReport from './ui/doctor/reports/PatientTypeReport';
import ServiceOverview from './ui/doctor/reports/ServiceOverview';
import AppointmentTypeReport from './ui/doctor/reports/AppointmentTypeReport';
import DayEndOverview from './ui/doctor/reports/DayEndOverview';
import RefferalReport from './ui/doctor/reports/RefferalReport';
import RefferenceReport from './ui/doctor/reports/RefferenceReport';
import IPDoctorReport from './ui/doctor/reports/IPDoctorReport';
import { IPPatientList } from './ui/doctor/IP/IPPatientList'
import { IPPatientCheckoutList } from './ui/doctor/IP/IPPatientCheckoutList'
import PendingBillsReport from './ui/doctor/reports/PendingBillsReport';

import IPAllTransactions from './ui/doctor/IP/IPBilling/IPAllTransactions'
import { AdmissionDetails } from './ui/doctor/IP/AdmissionDetails'
import { Immunization } from './ui/doctor/PD/Immunization/Immunization'
import { GrowthChart } from './ui/doctor/PD/GrowthChart/GrowthChart'
import { DischargeSummary } from "./ui/doctor/IP/DischargeSummary"
import { SurgeryNotes } from "./ui/doctor/IP/surgeryNotes"
import IPAdvanceReport from './ui/doctor/IP/IPReport/IPAdvanceReport'

import { Insurance } from './ui/doctor/IP/Insurance'
import { PatientCheckout } from './ui/doctor/IP/PatientCheckout'
import { IPPatientCreateBill } from './ui/doctor/IP/IPBilling/IPPatientCreateBill'
import IPPatientSavedBill from './ui/doctor/IP/IPBilling/IPPatientSavedBill'
import IPOTBill from './ui/doctor/IP/IPBilling/IPOTBills';


// Laboratory Start
import { LaboratoryCreateBill } from './ui/doctor/Laboratory/PatientBillAndResultAndPreviousResult/LabCreateBill'
import { LaboratoryPatientList } from './ui/doctor/Laboratory/laboratoryPatientList';
import { TestConfig } from './ui/doctor/Laboratory/testConfig';
import { LaboratoryResult } from './ui/doctor/Laboratory/PatientBillAndResultAndPreviousResult/result';
import { LaboratoryPreviousResult } from './ui/doctor/Laboratory/PatientBillAndResultAndPreviousResult/previousResult';
import { LaboratoryBillingTransaction } from './ui/doctor/Laboratory/laboratoryBillingTransaction';
import  ReferredReport  from './ui/doctor/Laboratory/RefferedByReport/Report';
var Messages = require('./utils/InfoMessages');

import { BusinessIntelligence } from './ui/doctor/Bi&Registry/BI'
import { Registry } from './ui/doctor/Bi&Registry/Registry'
import { RatingScale } from './ui/doctor/Psychiatry/Rating_Scale'
import { PsychiatryHistory } from './ui/doctor/Psychiatry/PsychiatryHistory'
import { PeadiatricMentalHealth } from './ui/doctor/Psychiatry/PeadiatricMentalHealth'
import { SubstanceAbuse } from './ui/doctor/Psychiatry/SubstanceAbuse'

import { connect } from "react-redux";
import { updateDateFilterFlag,updateMultiAxialDropDownFlag } from './Redux/appDropdownActions';
import  FollowUps  from './ui/doctor/opthamology/FollowUp'
import OPPatientSavedBill from './ui/doctor/common/billing/OPPatientSavedBill'
import ReferalNotes from './ui/doctor/common/ReferalNote/ReferalNotes';
import {SpecimenCollectedModal} from './ui/doctor/Laboratory/specimenCollectedModal'
import QuickBilling from './ui/doctor/common/QuickBilling';
import AdminConfiguration from './ui/doctor/common/adminConfig';
import IPRoomConfiguration from './ui/doctor/common/ipRoomConfig';
import TagConfiguration from './ui/doctor/common/tagConfig';
import PackageConfig from './ui/doctor/common/packageConfig';
import OTVitals from './ui/doctor/OTM/OTM Appointments/OTVitals';
import OTReports from './ui/doctor/OTM/OTM Appointments/OTReports';
import OTSurgeryNotes from './ui/doctor/OTM/OTM Appointments/OTSurgeryNotes';
import OTBilling from './ui/doctor/OTM/OTM Appointments/OTBilling';
import oTSurgeryNotesReport from './ui/doctor/OTM/OT Reports/OTSurgeryReport';


import StockUsage from './ui/doctor/OTM/OTM Inventory/OTM Stock/StockUsage';
import StockUsageTrans from './ui/doctor/OTM/OTM Inventory/OTM Stock/StockUsageTrans';
import OTVendor from './ui/doctor/OTM/OTM Inventory/OT Vendor/vendorList';
import VendorPayment from './ui/doctor/OTM/OTM Inventory/OT Vendor/vendorPayment';
import AddVendor from './ui/doctor/OTM/OTM Inventory/OT Vendor/AddVendor'
import CommingPage from './ui/doctor/OTM/Comming';
import VendorBilling from './ui/doctor/OTM/OTM Inventory/OT Vendor/vendorBilling';
import OTAppointmentList from "./ui/doctor/OTM/OTM Appointments/OTAppointmentList";
import InventoryIndex from './ui/doctor/OTM/OTM Inventory/OT Inventory';
import Vitals from './ui/doctor/opthamology/Vitals';

import BillingTransaction from './ui/doctor/SuperAdmin/BillingTransaction';
import Statistics from './ui/doctor/SuperAdmin/Statistics'
import ResetPassword from './ui/doctor/SuperAdmin/ResetPassword';
import {AllBillingStatistics} from './ui/doctor/SuperAdmin/Billing/AllBillingStatistics';
import InPatientBilling from './ui/doctor/SuperAdmin/Billing/InPatientBilling';
import OutPatientBilling from './ui/doctor/SuperAdmin/Billing/OutPatientBilling';
import RefferalBilling from './ui/doctor/SuperAdmin/Billing/RefferalBilling';
import PharmacyBilling from './ui/doctor/SuperAdmin/Billing/PharmacyBilling';
import LaboratoryBilling from './ui/doctor/SuperAdmin/Billing/LaboratoryBilling';
import Settings from './ui/doctor/SuperAdmin/Settings';
import SuperAdminIndex from './ui/doctor/SuperAdmin/index';
import StockTransfer from './ui/doctor/SuperAdmin/StockExchange/StockTransfer';
import Inbound from './ui/doctor/SuperAdmin/StockExchange/Inbound';
import OutBound from './ui/doctor/SuperAdmin/StockExchange/OutBound';
import StockTransferReport from './ui/doctor/SuperAdmin/StockExchange/StockTransferReport'; 
import { LabReferredDoctors } from './ui/doctor/Laboratory/labRefferedDoctors';
import ApprovalRequest from './ui/doctor/SuperAdmin/StockExchange/ApprovalRequest';

import AdminDashboard from './ui/doctor/common/AdminDashboard/Index'
import CorporateConfig from './ui/doctor/common/CorporateConfig';
import UserConfig from './ui/doctor/common/UserConfig';
import NutritionChart from './ui/doctor/common/NutritionChart/NutritionChart';
import FootClinicHome from './ui/doctor/DB/FootClinic/FootClinicHome';
import BrachialIndex from './ui/doctor/DB/FootClinic/BrachialIndex';
import Biothesiometry from './ui/doctor/DB/FootClinic/Biothesiometry';
import ExpenseConfig from './ui/doctor/common/ExpenseConfig';
import SettingsConfig from './ui/doctor/common/SettingsConfig';
import AdminQmsConfiq from './ui/doctor/common/AdminQmsConfig';
import IPbillStatusReport from './ui/doctor/reports/IPbillStatusReport';

//OBGYN 
import AntenatalChart from './ui/doctor/OBGYN/AnatentalChart/index';
import DischargeSummaryTemplate from './ui/doctor/common/DischargeSummaryTemplate';
import SurgeryNotesTemplate from './ui/doctor/IP/SurgeryNotesTemplate';

import EstimateBill from './ui/doctor/IP/IPBilling/EstimateBill';
import SummaryBill from './ui/doctor/IP/IPBilling/SummaryBill';
import CollabSummary from './ui/doctor/opthamology/CollabSummary';
import CorporatebillsReport from './ui/doctor/reports/CorporatebillsReport';
import OpticalCRMSales from './ui/doctor/reports/OpticalCRMSales';
import SurgeryReport from './ui/doctor/OTM/OT Reports/OTSurgeryReport';
// Opera 8.0+
const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]" 
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
// Laboratory End
// create a component
class RootComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "home",
            checkIpNumber: false,
            // for Doctor Notes Section parameter Start
            patientAppointment: {},
            userType: "",
            selectedClinicOrDoctor: {},
            isOptClinic: false,
            // for Doctor Notes Section parameter Ends

            // ip patient 
            selectedIpPatientDetails: {},
            ipPatientProfileDetails: {},
            // ip patient 

            OtPatienDetils:"",
            OPappointmentID:"",

            // mark out of office clinic multiselecte key start
            multiSelectedClinicDetails: [],
            // mark out of office clinic multiselecte key end

            // show Billing Modal and Parameter start
            showBillinListPopUpFlag: false,
            patientId: "",
            invoiceNo: "",
            patientInfo: {},
            billingDetails: {},
            // show Billing Modal and Parameter end


            // Image Modal 
            showDocumentImageFlag: false,
            showDocumentImage: '',
            // 
            
            refreshAdminData: false,
            // 
            showAppointmentRequestModalFlag: false,
            showUnlockDoctorNotes: false,
            getUnLockDoctor: {},
            appointmentRequest: {},
            showmarkOutofOfficeModal: false,
            getmarkOutofOffice: {},

            // Cancel Appointment Modal
            CancelAppointmentVisible : false,
            CancelAppointment_item :'',
            CancelAppointment_type : '',
            cancelAppointmentReason :'',


            // next Appointment Modal
            modalVisible: false,
            nextAppoinmentatPientDetails: {},
            patientId: "",
            request_id: "",
            receivedAppoinmentDate: "",
            isNextSchedule: false,

            modelCloseStatus: "",
            selectedSubScreen: "",


            showResponceMessage: {},
            doctorProfilePhoto: "",


            // for home screen Drop down start
            showPickerDropDownFlag: false,
            pickerKey: "",
            selectedClinicOrDoctor: {},
            arrayOfClinicData: [],
            // for home screen Drop down end

            // for home screen Search start
            searcBoxTextValue: "",
            // for home screen Search end

            doctorName: "",

            // success messgae
            responseType: "",
            responseMeaasge: "",
            resetResponseType: "",
            resetResponseMeaasge: "",

            changePasswordModalFlag: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            changePasswordValidationMessage: "",

            isShownPicker: false,
            selectectedItems: [],
            selectedSessionValue: [],
            sessionStateDateAndTime: "",
            sessionEndDateAndTime: "",
            markOutOfLocationDetailsTxtBox: "",
            clinicList: [],
            markOutOffLocationErrorMessage: "",


            currentAppointmentsDateValue: "",

            showProfileDropDownFlag: false,

            patientSearchTextValue: "",

            refreshHomeComponentFlag: false,
            isCurrentPageIsIP: false,
            isCurrentPageIsIPPatientAppointment: false,
            // ipPatientAndDoctorIDs:{}

            showRoomDetailsPopupFlag: false,
            roomDetailsPopupData: [],

            bedChargeTotalAmount: "",

            drType: "",
            showBillingTransactionPopup: false,
            billingTransactionPopupList: {},
            invoiceID: "",

            // for camera
            enableCameraModalFlag: false,
            enableCameraFor: "",
            newProfilePhoto: null,
            newFacialPhotos: [],

            showSelectedShiftcounterListFlag: false,
            shiftcounterList: [],
            selectedCounter: {},

            clientID: "",
            userName: "",
            reportID: "",

            selectedQMSTokenID: "",

            isFoAdmin: "false",

            showLabTransmited: false,
            // labBillingPatientTransection: true,
            showLabBillingTransactionPopup: false,
            billingLabTransactionPopupList: {},
            billingLabinvoiceID: "",
            // labBillingPatientTransection:true,

            labUserAccess: [],
            labUserAccessType: "",


            selectedLabPatientDetails: {},

            showLabTransmitedList: [],
            labNavigationHeaderList: [],
            isLabAdmin: false,

            showLabSavedBill: false,
            showLabSavedBillList: [],
            istransmit: false,
            isLabpatientHome: false,
            newPatientDetailsCreatedForLab: {},


            AllModuleBillingTransactionPopup: false,
            AllModuleBillingTransactionPopupList: {},
            AllModuleBillingTransactionPopupPatientDertails: {},
            AllModuleBillingTransactionPopupInvoiceNo: "",
            allbillPatientID: "",
            refreshAllBills: false,

            demographicData: {},
            ICDCodesdata: [],
            DrugsData: [],
            medicalData: [],
            nonMedicalData: [],
            immunizationData: [],
            vitals: {},

            // selectedPatientAppoinmentatDetails:{}  // for qms shift counter
            selectedShiftCounterAppointmentDeatails:{},
            isPrimaryDoctor:false,
            isIpPatientCheckouted:false,

            ipCheckoutPatientExportFiltersData:{},

            showPharmacyBillingTransactionPopup: false,
            billingPharmacyTransactionPopupList: {},
            billingPharmacyinvoiceID: "",

            showImageLabBillingTxnPopup: false,
            billingImageLabPopupList: [],
            billingImageLabInvoiceId: "",

            serviceDetailedFlag:false,
            foReportType:"",
            labSavedBillList:{},
            //Specimen Collected Modal
            isSpecimenModalVisible:false,

            specimen_key:"",
            specimen_tooltip:'',
            specimen_item:'',
            specimen_Data:{
                specimen:'',
                other_specimen:'', 
                specimen_collected_by:''
            },
            
            savedBillInvoiceID: "",
            isIpDemographic: false,
            isSeacrhClicked : false,
            isFilterSearchClicked : false,

            // Waiting Comments Modal
            WaitingCommentsVisible : false,
            WaitingComments_item :'',
            WaitingComments_type : '',
            WaitingComments :'',
            appointment_id :'',
            resentComment:'' ,
            resentappointment_id:'',

            //Print Appointment 
            isArrived: false,
            isWithDoctor: false,
            isAll: false,
            isCompleted: false,
            isCancelled: false,
            isBooked:false,

            isRoleAdmin: false,
            // IP Appointment List

            selectedFromDate: "",
            selctedToDate: "",
            isLabSavedBillDelete: false,

            searchDate: "",
            // bill modify
            modificationBillDetails: {},
            ipModifyBillDetail: {},
            isAdminAccess: false,
            isUserEditAccess: false,
            ipAdmissionID: "",
            isModifyBillUpdate: false,
            editPatientID: "",

            // Super Admin 
            statisticsBranchId: "",
            statisticsFromDate: converted_date,
            statisticsToDate: converted_date,
            dateFilterstatus: "Today",
            //Reset Password
            isResetPassword: false,

            //patientwithout appointment
            isNoAppointment: false,

            isSideMenuClick: false,
            selectedAppointmentStatus: "",
            role_Type: '',
            antenatal_id: "",
            BillStatus:"",
            isCancelBillAccess: false,

            isCreditLimit: false,
            isIPCreditExceedBlock: true,
            ipCreditLimit: null,

            isLabCreditExceedBlock: true,
            labCreditLimit: null,

            isPharmaCreditExceedBlock: true,
            pharmaCreditLimit: null,
        }

        this.setModalVisible = this.setModalVisible.bind(this)
    }

    
    displaySpecimen=(flag, tooltip, item, key,data = {})=>{
        this.setState({
            isSpecimenModalVisible:flag,
            specimen_tooltip :tooltip,
            specimen_item:item,
            specimen_key:key,
            specimen_Data:{
                specimen:data.specimen,
                other_specimen:data.other_specimen,
                specimen_collected_by:data.specimen_collected_by,
            }
        }) 
    }
    closeSpecimenModal=(flag)=>{
        this.setState({isSpecimenModalVisible:flag})
    }
    ipCheckoutPatientExportFilters(searchKey,fromDate,toDate){
        var preparData= {
            searchKey:searchKey,
            fromDate:fromDate,
            toDate:toDate
        }
        this.setState({
            ipCheckoutPatientExportFiltersData:preparData
        })
    }

    async getDRProfilePhoto() {
        const doctorProfile = await AsyncStorage.getItem("doctorProfile");
        this.state.doctorProfilePhoto = doctorProfile
    }

    _panResponder = {};
    timer = 0;
    componentWillMount() {
      this._panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => {
          this.resetTimer()
          return true
        },
        onMoveShouldSetPanResponder: () => true,
        onStartShouldSetPanResponderCapture: () => { this.resetTimer() ; return false},
        onMoveShouldSetPanResponderCapture: () => false,
        onPanResponderTerminationRequest: () => true,
        onShouldBlockNativeResponder: () => false,
      });
      this.timer = setTimeout(()=>this.setState({isIdle:true}), 30 * 60 * 1000)
    }
    
    resetTimer(){
        clearTimeout(this.timer)
        this.setState({isIdle:false })
        this.timer = setTimeout(()=>this.setState({isIdle:true}), 30 * 60 * 1000)
        }
    
    async componentDidMount() {
        // if (selectedScreen === "home" && localGetItem("PurposeOfVisit") === null) {

        //   }
        const isFoAdmin = await AsyncStorage.getItem("isFoAdmin");
        const userName = await AsyncStorage.getItem("UserName");

        const clientID = await AsyncStorage.getItem("UserID");
        const doctorProfile = await AsyncStorage.getItem("doctorProfile");
        const userType = await AsyncStorage.getItem("service_type");
        const drType = await AsyncStorage.getItem("DR_TYPE");
        const role_Type = await AsyncStorage.getItem("role_type")
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
      var isOPT = false 
      if(loggedInDataObj.clinic_type){
        isOPT = loggedInDataObj.clinic_type.is_opt_clinic
      }else{
        isOPT = false;
      }

        const doctorName = await AsyncStorage.getItem("doctorName");
        if (userType == "LAB") {
            var labUserAccess = await AsyncStorage.getItem("Lab_User_Access");
            var isLabAdmin = await AsyncStorage.getItem("Is_Lab_Admin");

        }
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
        if(drType === "OT") {
            var selectedScreen = "otAppointmentList"
        } else if(userType === "SUPER_ADMIN") {
            var selectedScreen = "superAdminStatistics"
        } else if(userType === "BRANCH_ADMIN") {
            var selectedScreen = "superAdminStatistics"
        } else {
            var selectedScreen = "home"
        }

       
        // alert(drType)
        this.setState({
            isAdminAccess: loggedInDataObj.is_admin_access,
            doctorName: doctorName,
            doctorProfilePhoto: doctorProfile,
            userType: userType,
            isOptClinic: isOPT,
            drType: drType,
            currentAppointmentsDateValue: this.convertedDate(),
            clientID: clientID,
            userName: userName,
            isFoAdmin: isFoAdmin,
            labUserAccess: labUserAccess ? labUserAccess : [],
            isLabAdmin: isLabAdmin,
            isRoleAdmin: loggedInDataObj.is_admin_access,
            selectedScreen: selectedScreen,
            isResetPassword: loggedInDataObj.is_password_reset ? loggedInDataObj.is_password_reset : null,
            role_Type: role_Type
            
        }, () => {
            if (userType == "LAB") {
                this.validateLabUserAccess()
            }
            this.getClinic();
            if (isFoAdmin == "true") {
                this.createClientInQMS()        // client create
            }
        });
      
        this.labLogin()
        this.getAdminConfigSettings()
        this.getIPCreditLimit()
        this.getPurposeOfVisitData()

    }



    labLogin() {
        if (this.state.userType === 'LAB')
            this.setState({
                selectedScreen: 'laboratoryPatientList'
                // testConfig
                // laboratoryPatientList
                // laboratoryCreateBill
                // testone

            }, () => {
                this.getTransmittedChangeList()
                this.getLabSavedList()

            })
    }
    getAdminConfigSettings = () => {
        try{
          var service_url = Constants.GET_FO_ADMIN_CONFIG_SETTINGS;
          OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.successResponse,
            this.FailureResponse
          )
        }catch(e){
    
        }
      }
      successResponse = response =>{
        if(response.status == "success"){
          AsyncStorage.setItem("FO_ADMIN_SETTINGS", JSON.stringify(response.data));
          this.setState({
            isUserEditAccess : response.data.allow_saved_bill_change,
            isCancelBillAccess: response.data.allow_user_to_cancel_bill
          })
          this.props.navigation.navigate("homeNavigator");
        }else{
            console.log(response.meaasge);
        }
      }
      FailureResponse = error =>{
        this._showAlert(error.message, "OK");
      }
      getIPCreditLimit = () => {
        var { selectedIpPatientDetails } = this.state
        var service_url = Constants.IP_CREDIT_LIMIT + "?ip_admission_id=" + selectedIpPatientDetails.id
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.ipCreditLimitSuccess,
            this.ipCreditLimittFailure
        )
    }
    ipCreditLimitSuccess = success => {
        if (success.status == "success") {
            var data = success.data
            this.setState({
                isIPCreditExceedBlock: data.allow_ip_credit_overdue,
                ipCreditLimit: data.ip_credit_limit_upto,
                isLabCreditExceedBlock: data.allow_lab_credit_overdue,
                labCreditLimit: data.lab_credit_limit_upto,
                isPharmaCreditExceedBlock: data.allow_pharmacy_credit_overdue,
                pharmaCreditLimit: data.pharmacy_credit_limit_upto,
            })
        } 
    }
    ipCreditLimitFailure = error => {
        this.setState({
        })
    }
    showImage(flag, image = "", listImages = []) {

        var states = this.state;
        states["showDocumentImageFlag"] = flag
        if (image) {
            states["showDocumentImage"] = image
            states["documentImgList"] = listImages
        }
        this.setState({ states })
    }


    closeBillingModal(flag, patientId = "", invoiceNo = "", patientInfo = {}, billingDetails = {},
        showRessponceFlag = false, type = "", message = "") {
        var states = this.state;
        states["patientId"] = patientId;
        states["invoiceNo"] = invoiceNo;
        states["patientInfo"] = patientInfo;
        states["billingDetails"] = billingDetails;
        states["showBillinListPopUpFlag"] = flag;


        states["showResponceMessage"] = {
            showRessponceFlag: showRessponceFlag,
            type: type,
            message: message
        }

        this.setState({
            states
        }, () => {
            // this.setState({
            //     showBillinListPopUpFlag: flag  
            // })
        })
    }

    appointmentRequestModalFlag(flag, data) {
        this.setState({
            showAppointmentRequestModalFlag: flag,
            appointmentRequest: data
        })
    }
    unLockDoctorNotesModalFlag(flag, data) {

        this.setState({
            showUnlockDoctorNotes: flag,
            getUnLockDoctor: data ? data : this.state.getUnLockDoctor
        })
    }


    labTransmittedFlag(flag, data) {

        this.setState({
            showLabTransmited: flag,
            // getUnLockDoctor: data ? data : this.state.getUnLockDoctor
        })
    }


    markOutofOfficeModalFlag(flag, data) {
        var states = this.state;
        if (!flag) {

            states["selectectedItems"] = [];
            states["selectedSessionValue"] = [];
            states["sessionStateDateAndTime"] = "";
            states["sessionEndDateAndTime"] = "";
            states["markOutOfLocationDetailsTxtBox"] = "";
            states["markOutOffLocationErrorMessage"] = "";
        }

        this.setState({
            states,
            showmarkOutofOfficeModal: flag,
            getmarkOutofOffice: data
        })
    }

    changePasswordOpenAndClose(flag) {
        var states = this.state;
        if (!flag) {
            states["oldPassword"] = "";
            states["newPassword"] = "";
            states["confirmPassword"] = "";
            states["changePasswordValidationMessage"] = "";
        }

        this.setState({
            states,
            changePasswordModalFlag: flag
        })
    }
    changePasswordValues(oldPass, newPass, confirmPass) {
        this.setState({
            oldPassword: oldPass,
            newPassword: newPass,
            confirmPassword: confirmPass
        })
    }


    clearResponceMessage(flag, type, message) {
        var { showResponceMessage } = this.state;
        // showResponceMessage['']
        showResponceMessage = {
            showRessponceFlag: flag,
            type: type,
            message: message
        }

        this.setState({ showResponceMessage })

    }

    changeHomeScreenPickerValues(selectedData, pickerFlag, arrayOfClinicData, pickerKey) {
        this.setState({
            showPickerDropDownFlag: pickerFlag,
            pickerKey: pickerKey,
            selectedValue: selectedData,
            arrayOfClinicData: arrayOfClinicData,
        })

    }

    refreshHomeComponent(flag) {
        this.setState({
            refreshHomeComponentFlag: flag
        })
    }
    checkFoReportType=(reportType)=>{
        //    alert("Type:",reportType)
        this.setState({
            foReportType:reportType
        })
    }
    getDateFilterDate = ( fromDate, toDate ) => {
        this.setState({
            selectedFromDate: fromDate,
            selctedToDate: toDate
        })
    }
    renderModifyClick = (isUpdate) => {
      this.setState({
        isModifyBillUpdate: isUpdate
      })
    }
    getAnteneatalID = (id) => {
       this.setState({
        antenatal_id: id
       })
    }
    onChangeReportTab = (flag) => {
        if(flag) {
          this.props.updateDateFilterFlag(false)
        }
      }
    renderComponent() {

        var selectedComponent = this.state.selectedScreen;
         
        var componentLookup = {
            home: (< HomeScreen modelClose={this.modelClose.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                changeStateValues={this.changeStateValues.bind(this)}
                appointmentRequestModalFlag={this.appointmentRequestModalFlag.bind(this)}
                unLockDoctorNotesModalFlag={this.unLockDoctorNotesModalFlag.bind(this)}
                receivedAppoinmentDate={this.state.receivedAppoinmentDate}
                modelCloseStatus={this.state.modelCloseStatus} modelClose={this.modelClose.bind(this)}
                currentAppointmentsDate={this.currentAppointmentsDate.bind(this)}
                clearSearchTextBoxValue={this.clearSearchTextBoxValue.bind(this)}
                userType={this.state.userType}
                // unLockDoctor = {this.unLockDoctor.bind(this)}
                isFoAdmin={this.state.isFoAdmin}
                isSearchClicked = {this.state.isSeacrhClicked}
                isFilterSearchClicked = {this.state.isFilterSearchClicked}
                FilterSearchClose={this.FilterSearchClose.bind(this)}
                changeHomeScreenPickerValues={this.changeHomeScreenPickerValues.bind(this)}

                searcBoxTextValue={this.state.searcBoxTextValue}

                showResposeValue={this.showResposeValue.bind(this)}
                refreshHomeComponentFlag={this.state.refreshHomeComponentFlag}
                refreshHomeComponent={this.refreshHomeComponent.bind(this)}
                currentAppointmentsDateValue={this.state.currentAppointmentsDateValue}
                changeAppointmentStatusbyId={this.state.faceDetectedAppointmentId}
                showSelectedShiftcounterList={this.showSelectedShiftcounterList.bind(this)}
                changeQMSSelectedToken={this.changeQMSSelectedToken.bind(this)}
                currentPage={this.state.selectedScreen}

                WaitingCommentsVisible = {this.WaitingCommentsVisible}
                WaitingComments_item = {this.state.WaitingComments_item}                
                WaitingComments_type = {this.state.WaitingComments_type}
                WaitingComments = {this.state.WaitingComments}
                resentComment = {this.resentComment}

                
                CancelAppointmentVisible = {this.CancelAppointmentVisible}
                CancelAppointment_item = {this.state.CancelAppointment_item}                
                CancelAppointment_type = {this.state.CancelAppointment_type}
                cancelAppointmentReason = {this.state.cancelAppointmentReason}
                getSelectedAppointmentStatus ={this.getSelectedAppointmentStatus}

            />),


            doctorProfile: (< DoctorProfile

                modelClose={this.modelClose.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                // changeStateValues={this.changeStateValues.bind(this)}
                // appointmentRequestModalFlag={this.appointmentRequestModalFlag.bind(this)}
                // modelCloseStatus={this.state.modelCloseStatus} modelClose={this.modelClose.bind(this)}
                // unLockDoctor = {this.unLockDoctor.bind(this)}
                markOutofOfficeModalFlag={this.markOutofOfficeModalFlag.bind(this)} receivedAppoinmentDate={this.state.receivedAppoinmentDate}
                modelCloseStatus={this.state.modelCloseStatus} modelClose={this.modelClose.bind(this)}
                changePasswordOpenAndClose={this.changePasswordOpenAndClose.bind(this)}
            // unLockDoctor = {this.unLockDoctor.bind(this)}
            />),

            scheduleScreen: (<ScheduleScreen
                // showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)} />),


            allBillingTransactions: (<AllBillingTransactions
                showPharmacyBillingTransactionPopup={this.showPharmacyBillingTransactionPopup.bind(this)}
                showImageLabBillingTxnPopup={this.showImageLabBillingTxnPopup.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                showLabBillingTransactionPopup={this.showLabBillingTransactionPopup.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                clearResponceMessage={this.clearResponceMessage.bind(this)}
                closeBillingModal={this.closeBillingModal.bind(this)}
                userType={this.state.userType}
                changeScreen={this.changeScreen.bind(this)}
                changeStateValues={this.changeStateValues.bind(this)}
                patientAppointment={this.state.patientAppointment}
                showAlllBillingScreenModulePopup={this.showAlllBillingScreenModulePopup.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                refreshAllBills={this.state.refreshAllBills}
                stopRefreshAllBills={this.stopRefreshAllBills.bind(this)}
            />),

            adminConfig: ( this.state.isRoleAdmin ?<AdminConfiguration
                changeScreen={this.changeScreen.bind(this)}
                userType={this.state.userType}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResposeValue={this.showResposeValue.bind(this)}
                refreshAdminData={this.state.refreshAdminData}
            /> 
            : null
            ),

            ipRoomConfig: (this.state.isRoleAdmin ? 
            <IPRoomConfiguration 
            changeScreen={this.changeScreen.bind(this)}
            userType={this.state.userType}
            showResposeValue={this.showResposeValue.bind(this)}
            selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
            /> 
            : null
            ), 

            tagConfig: ( this.state.isRoleAdmin ?
            <TagConfiguration 
            changeScreen={this.changeScreen.bind(this)}
            userType={this.state.userType}
            showResposeValue={this.showResposeValue.bind(this)}
            selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
            /> 
            : null
            ),
            corpConfig: ( this.state.isRoleAdmin ?
            <CorporateConfig 
            changeScreen={this.changeScreen.bind(this)}
            showResposeValue={this.showResposeValue.bind(this)}
            userType={this.state.userType}
            /> 
            : null
            ),
            userConfig: ( this.state.isRoleAdmin ?
            <UserConfig 
            changeScreen={this.changeScreen.bind(this)}
            userType={this.state.userType}
            /> 
            : null
            ),
            adminQmsConfiq: (
                this.state.isRoleAdmin ?
                    <AdminQmsConfiq
                        changeScreen={this.changeScreen.bind(this)}
                        showResposeValue={this.showResposeValue.bind(this)}
                        userType={this.state.userType}
                        selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                    />
                    : null
            ),
            packageConfig: ( this.state.isRoleAdmin ?
            <PackageConfig 
            changeScreen={this.changeScreen.bind(this)}
            userType={this.state.userType}
            showResposeValue={this.showResposeValue.bind(this)}
            selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
            /> 
            : null
            ),
            expenseconfig: ( this.state.isRoleAdmin ?
            <ExpenseConfig
            changeScreen={this.changeScreen.bind(this)}
            userType={this.state.userType}
            showResposeValue={this.showResposeValue.bind(this)}
            selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
            /> 
            : null
            ),
            settingConfig: ( this.state.isRoleAdmin ?
            <SettingsConfig 
            userType={this.state.userType}
            changeScreen={this.changeScreen.bind(this)}
            showResposeValue={this.showResposeValue.bind(this)}
            /> 
            : null
            ),
            DisSumTemplate: ( this.state.isRoleAdmin ?
            <DischargeSummaryTemplate 
            userType={this.state.userType}
            changeScreen={this.changeScreen.bind(this)}
            showResposeValue={this.showResposeValue.bind(this)}
            /> 
            : null
            ),
            surgeryNotesTemplate: ( this.state.isRoleAdmin ?
                <SurgeryNotesTemplate 
                userType={this.state.userType}
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                /> 
            : null
            ),
            dbFootClinic:(<FootClinicHome
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}></FootClinicHome>),
            brachialIndex:(<BrachialIndex
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}></BrachialIndex>),
            biothesiometry:(<Biothesiometry
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}></Biothesiometry>),


            followUp: (
                this.state.userType === "OPT" ?
                    <FollowUps
                        showResposeValue={this.showResposeValue.bind(this)}
                        selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}

                    />
                    : null
            ),

            message: (<MessageScreen changeScreen={this.changeScreen.bind(this)} />),
            sendmessage: (<SendMessageScreen changeScreen={this.changeScreen.bind(this)} />),

            reportsScreen: (<Reports
                isIpPatientCheckouted={this.state.isIpPatientCheckouted}
                changeScreen={this.changeScreen.bind(this)}
                showDocumentImage={this.showImage.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                selectedSubScreen={this.state.selectedSubScreen}
                reportsViewType={"reportsScreen"}
                modificationBillDetails = {this.state.modificationBillDetails}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                onChangeReportTab = {this.onChangeReportTab.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
                selectedScreen = {this.state.selectedScreen}
            />),
            optDoctorNotes: (<DoctorNotes
                isIpPatientCheckouted={this.state.isIpPatientCheckouted}
                patientAppointment={this.state.patientAppointment}
                userType={this.state.userType}
                showDocumentImage={this.showImage.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                changeScreen={this.changeScreen.bind(this)}
                changeStateValues={this.changeStateValues.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
            />),

            dbHistory: (<DBHistory
                showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
            />),

            pdHistory: (<PDHistory

                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
            />),
            devlopmentassessment: (<DevlopmentAssessment

                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
            />),
            dbCommonHistory: (<History
                changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />),

            dbChartHistory: (<DBHistoryChart

                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
            />),
            dbNutritionChart: (<NutritionChart

                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            antenatalChart: (<AntenatalChart
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                patientAppointment={this.state.patientAppointment}
                getAnteneatalID = {this.getAnteneatalID.bind(this)}
            />),
            demographics: (<Demographics changeScreen={this.changeScreen.bind(this)}
                isIpPatientCheckouted= {this.state.isIpPatientCheckouted}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                modelClose={this.modelClose.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                clinicDetails={this.state.clinicDetails}
                openCameraModal={this.openCameraModal.bind(this)}
                cameraCaptureImage={this.state.newProfilePhoto}
                cameraCaptureFaces={this.state.newFacialPhotos}
                clearPatientPhotos={this.clearPatientPhotos.bind(this)}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                changeStateValues={this.changeStateValues.bind(this)}
                closeCameraModal={this.closeCameraModal.bind(this)}
                selectedLabPatientDetails={this.state.selectedLabPatientDetails}
                newPatientDetailsCreatedForLab={this.newPatientDetailsCreatedForLab.bind(this)}
                isIpDemographic={this.state.isIpDemographic}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                modificationDetails = {this.state.modificationBillDetails}
                editPatientID = {this.state.editPatientID}
            />),
            billingScreen: (<BillingScreen changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                role_Type={this.state.role_Type}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                changeStateValues={this.changeStateValues.bind(this)}
                invoiceID = {this.state.savedBillInvoiceID}
                modificationBillDetails = {this.state.modificationBillDetails}
                renderModifyClick = {this.renderModifyClick.bind(this)}
                isModifyBillUpdate = {this.state.isModifyBillUpdate}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            opPatientSavedBill: (<OPPatientSavedBill changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                changeStateValues={this.changeStateValues.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}

            />),
            formsAndCertificate: (<FormsAndCertificate changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />),

            obgynHistory: (<OBGYNHistory changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />),




            ovulation: (<Ovulation changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />),

            obgynChart: (<ObgynChart changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo} />),

            obstetricSonographyReport: (<ObstetricSonographyReport
                changeScreen={this.changeScreen.bind(this)}
                reportID={this.state.reportID}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                otPatientDetail={this.state.OtPatienDetils}
                userType = {this.state.userType}
            />),

            ultraSoundAbdomenReport: (<UltraSoundAbdomenReport
                changeScreen={this.changeScreen.bind(this)}
                patientAppointment={this.state.patientAppointment}
                reportID={this.state.reportID}
                patientInfo={this.state.patientInfo}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                otPatientDetail={this.state.OtPatienDetils}
                userType = {this.state.userType}
            />),
            immunization: (<Immunization
                showResponceMessage={this.state.showResponceMessage}
                clearResponceMessage={this.clearResponceMessage.bind(this)}
                closeBillingModal={this.closeBillingModal.bind(this)}
                userType={this.state.userType}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                currentPage={this.state.selectedScreen}
            />),
            growthChart: (<GrowthChart
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                showResponceMessage={this.state.showResponceMessage}
                clearResponceMessage={this.clearResponceMessage.bind(this)}
                closeBillingModal={this.closeBillingModal.bind(this)}
                userType={this.state.userType}
            />),
            printOrder: (<Reports changeScreen={this.changeScreen.bind(this)}
                showDocumentImage={this.showImage.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                selectedSubScreen={this.state.selectedSubScreen}
                reportsViewType={"reportsScreen"}
                selectedScreen={this.state.selectedScreen}
                userType={this.state.userType}
                modificationBillDetails = {this.state.modificationBillDetails}
            />),
            iPPatientList: (<IPPatientList changeScreen={this.changeScreen.bind(this)}
                patientSearchTextValue={this.state.patientSearchTextValue}
                searcBoxTextValue={this.state.searcBoxTextValue}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                changeStateValues={this.changeStateValues.bind(this)}
                isSearchClicked = {this.state.isSeacrhClicked}
                getDateFilterDate = {this.getDateFilterDate.bind(this)}
                getIpPatientProfileDetails={this.getIpPatientProfileDetails.bind(this)}
            />),
            admissionDetails: (<AdmissionDetails changeScreen={this.changeScreen.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                showResponceMessage={this.state.showResponceMessage}
                showResposeValue={this.showResposeValue.bind(this)}
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                PatientDetail={this.state.OtPatienDetils}
                OPappointmentID={this.state.OPappointmentID}
            // ipPatientAndDoctorIDs={this.state.ipPatientAndDoctorIDs}
            />),
            insurance: (<Insurance changeScreen={this.changeScreen.bind(this)}
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                showResposeValue={this.showResposeValue.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),
            ipallTransactions: (<IPAllTransactions
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                checkIpNumber={this.state.checkIpNumber}
                changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                refreshAllBills={this.state.refreshAllBills}
                stopRefreshAllBills={this.stopRefreshAllBills.bind(this)}
            />),
            ipPatientSavedBill: (<IPPatientSavedBill
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                checkIpNumber={this.state.checkIpNumber}
                changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}

            />),
            otbills:(<IPOTBill
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                checkIpNumber={this.state.checkIpNumber}
                changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}/>),

            estimationBill:(<EstimateBill
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                checkIpNumber={this.state.checkIpNumber}
                changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                />),
            summaryBill:(<SummaryBill
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                checkIpNumber={this.state.checkIpNumber}
                changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                showBillingScreenPopup={this.showBillingScreenPopup.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResponceMessage={this.state.showResponceMessage}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                />),

            Result: (
                Constants.ROLE_FO == this.state.userType ?
                    // <Result
                    //     foReportType={this.state.foReportType}
                    //     checkFoReportType={this.checkFoReportType.bind(this)}

                    // />
                    <ReportIndex changeScreen={this.changeScreen.bind(this)}/>
                    : null
            ),
            ServiceDetailed :(
                <ServiceDetailed/>
            ),
            PatientTypeReport :(
                <PatientTypeReport/>
            ),
            ServiceOverview :(
                <ServiceOverview/>
            ),
            AppointmentTypeReport :(
                <AppointmentTypeReport/>
            ),
            RefferalReport: (<RefferalReport
                showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor }
                userType = {this.state.userType} />),
            RefferenceReport: (<RefferenceReport
                showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                userType = {this.state.userType}
                selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor }
            />),  
            IPDoctorReport: (<IPDoctorReport
                showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                userType = {this.state.userType}
                selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor }/>), 
            IPbillStatusReport: (<IPbillStatusReport
                showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                userType = {this.state.userType}
                selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor }/>), 
            DayEndOverview :(
                <DayEndOverview/>
            ),
            CorporatebillsReport :(
                <CorporatebillsReport showResposeValue={this.showResposeValue.bind(this)}/>
            ),
            OpticalCRMSales : (
                <OpticalCRMSales showResposeValue={this.showResposeValue.bind(this)}/>
            ),
            otInventory: (
                <InventoryIndex
                showResposeValue={this.showResposeValue.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                />
            ),


            QuickBilling: (
                Constants.ROLE_FO == this.state.userType ?
                    <QuickBilling
                    changeScreen={this.changeScreen.bind(this)}
                    isIpPatientCheckouted= {this.state.isIpPatientCheckouted}
                    patientAppointment={this.state.patientAppointment}
                    patientInfo={this.state.patientInfo}
                    userType={this.state.userType}
                    modelClose={this.modelClose.bind(this)}
                    selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                    isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                    clinicDetails={this.state.clinicDetails}
                    openCameraModal={this.openCameraModal.bind(this)}
                    cameraCaptureImage={this.state.newProfilePhoto}
                    cameraCaptureFaces={this.state.newFacialPhotos}
                    clearPatientPhotos={this.clearPatientPhotos.bind(this)}
                    isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                    changeStateValues={this.changeStateValues.bind(this)}
                    closeCameraModal={this.closeCameraModal.bind(this)}
                    selectedLabPatientDetails={this.state.selectedLabPatientDetails}
                    newPatientDetailsCreatedForLab={this.newPatientDetailsCreatedForLab.bind(this)}
                    isIpDemographic={this.state.isIpDemographic}
                    />
                    : null
            ),
            ipPatientCreateBill: (<IPPatientCreateBill
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                bedChargeTotalAmount={this.state.bedChargeTotalAmount}
                sendBedChargeTotalAmount={this.sendBedChargeTotalAmount.bind(this)}
                showResponceMessage={this.state.showResponceMessage}
                showResposeValue={this.showResposeValue.bind(this)}
                // clearResponceMessage={this.clearResponceMessage.bind(this)}
                // closeBillingModal={this.closeBillingModal.bind(this)}
                changeScreen={this.changeScreen.bind(this)}
                userType={this.state.userType}
                showRoomDetailsPopup={this.showRoomDetailsPopup.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
                labSavedBillList={this.state.labSavedBillList}
                billID={this.state.reportID}
                ipModifyBillDetail = {this.state.ipModifyBillDetail}
                ipAdmissionID = {this.state.ipAdmissionID}
                invoiceID = {this.state.invoiceID}
                renderModifyClick = {this.renderModifyClick.bind(this)}
                isModifyBillUpdate = {this.state.isModifyBillUpdate}

            />),
            ipAdvanceReport: (<IPAdvanceReport  changeScreen={this.changeScreen.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                showResposeValue={this.showResposeValue.bind(this)}
                isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}
            />),
            dischargeSummary: (<DischargeSummary changeScreen={this.changeScreen.bind(this)}
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                showResposeValue={this.showResposeValue.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),

            surgeryNotes: (<SurgeryNotes  changeScreen={this.changeScreen.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                showResposeValue={this.showResposeValue.bind(this)}
            />),

            patientCheckout: (<PatientCheckout changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
            />),
            laboratoryPatientList: (<LaboratoryPatientList changeScreen={this.changeScreen.bind(this)}
                patientSearchTextValue={this.state.patientSearchTextValue}
                searcBoxTextValue={this.state.searcBoxTextValue}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                isSearchClicked = {this.state.isSeacrhClicked}
                userType={this.state.userType}
                labUserAccessType={this.state.labUserAccessType}
                showResposeValue={this.showResposeValue.bind(this)}
                changeStateValues={this.changeStateValues.bind(this)}

                selectedLabPatientDetails={this.state.selectedLabPatientDetails}
                clearSearchTextBoxValue={this.clearSearchTextBoxValue.bind(this)}
                showSpecimen={this.displaySpecimen}
                specimen_tooltip={this.state.specimen_tooltip}
                specimen_item={this.state.specimen_item}
                specimen_key={this.state.specimen_key}
                specimen_Data ={this.state.specimen_Data}
                isLabSavedBillDelete = {this.state.isLabSavedBillDelete}


            // getIpPatientProfileDetails={this.getIpPatientProfileDetails.bind(this)}
            />),
            labRefferedDoctors: (<LabReferredDoctors 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)} 
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                userType={this.state.userType}
            />),
            testConfig: (<TestConfig changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            // patientSearchTextValue={this.state.patientSearchTextValue}
            // searcBoxTextValue={this.state.searcBoxTextValue}
            // patientAppointment={this.state.patientAppointment}
            // patientInfo={this.state.patientInfo}
            // userType={this.state.userType}
            // getIpPatientProfileDetails={this.getIpPatientProfileDetails.bind(this)}
            />),
            laboratoryResult: (<LaboratoryResult userType={this.state.userType} labNavigationHeaderList={this.state.labNavigationHeaderList} showResposeValue={this.showResposeValue.bind(this)} selectedLabPatientDetails={this.state.selectedLabPatientDetails} changeScreen={this.changeScreen.bind(this)} selectedIpPatientDetails = {this.state.selectedIpPatientDetails} modificationBillDetails = {this.state.modificationBillDetails}/>),
            laboratoryPreviousResult: (<LaboratoryPreviousResult labNavigationHeaderList={this.state.labNavigationHeaderList} selectedLabPatientDetails={this.state.selectedLabPatientDetails} changeScreen={this.changeScreen.bind(this)} selectedIpPatientDetails = {this.state.selectedIpPatientDetails} />),
            laboratoryCreateBill: (<LaboratoryCreateBill userType={this.state.userType} showResposeValue={this.showResposeValue.bind(this)} istransmit={this.state.istransmit} isLabpatientHome={this.state.isLabpatientHome} labNavigationHeaderList={this.state.labNavigationHeaderList} billingType={"laboratory billing"} selectedLabPatientDetails={this.state.selectedLabPatientDetails} changeScreen={this.changeScreen.bind(this)} modificationBillDetails = {this.state.modificationBillDetails} renderModifyClick = {this.renderModifyClick.bind(this)} 
            isModifyBillUpdate = {this.state.isModifyBillUpdate} patientInfo={this.state.patientInfo}/>),
            laboratoryBillingTransaction: (<LaboratoryBillingTransaction
                userType={this.state.userType}
                showLabBillingTransactionPopup={this.showLabBillingTransactionPopup.bind(this)}
                labNavigationHeaderList={this.state.labNavigationHeaderList} changeScreen={this.changeScreen.bind(this)} selectedLabPatientDetails={this.state.selectedLabPatientDetails} selectedIpPatientDetails = {this.state.selectedIpPatientDetails} 
                refreshAllBills={this.state.refreshAllBills}
                stopRefreshAllBills={this.stopRefreshAllBills.bind(this)}/>),

            referredReport: (<ReferredReport
                userType={this.state.userType}
                changeScreen={this.changeScreen.bind(this)}
            />), 
            registry: (<Registry
                isPrimaryDoctor={this.state.isPrimaryDoctor}
                biAndRegistry={this.biAndRegistry.bind(this)}
                userType={this.state.userType}
                exportRegistrydata={this.exportRegistrydata.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),

            // businessIntelligence: (<BusinessIntelligence
            //     doctorID={this.state.clientID}
            //     biAndRegistry={this.biAndRegistry.bind(this)}
            //     userType={this.state.userType}
            //     showResposeValue={this.showResposeValue.bind(this)}
            //     selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
            // />),
            ratingScale: (<RatingScale showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),

            psychiatryHistory: (<PsychiatryHistory showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),
            peadiatricMentalHealth: (<PeadiatricMentalHealth showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),
            substanceAbuse: (<SubstanceAbuse showResposeValue={this.showResposeValue.bind(this)}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
            />),
            ipPatientCheckoutList: (<IPPatientCheckoutList changeScreen={this.changeScreen.bind(this)}
                patientSearchTextValue={this.state.patientSearchTextValue}
                searcBoxTextValue={this.state.searcBoxTextValue}
                patientAppointment={this.state.patientAppointment}
                patientInfo={this.state.patientInfo}
                userType={this.state.userType}
                changeStateValues={this.changeStateValues.bind(this)}
                ipCheckoutPatientExportFilters= {this.ipCheckoutPatientExportFilters.bind(this)}
                getIpPatientProfileDetails={this.getIpPatientProfileDetails.bind(this)}
            />),
            referalNotes:(<ReferalNotes 
                referalNotes={this.referalNotes.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                patientInfo={this.state.patientInfo}
                patientId={this.state.patientAppointment.patient_id}
                doctorDetails = {this.state.doctorName}
                clinicDetails={this.state.clinicDetails}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                userType={this.state.userType} />),
            otList:(<OTlist 
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            otVendor:(<OTVendor 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            commingPage:(<CommingPage 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            vendorPayment:(<VendorPayment
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            addVendor:(<AddVendor
                vendorId = {this.state.vendorId}
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            otAppointmentList:(<OTAppointmentList
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.getPatientDetail.bind(this)}
            />),
            stockUsage:(<StockUsage
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            stockUsageTrans:(<StockUsageTrans
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),
            oTVitals:(<OTVitals
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
                patientInfo={this.state.OtPatienDetils}
            />), 
            oTReports:(<OTReports
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
            />), 
            oTSurgeryNotes:(<OTSurgeryNotes
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
                changeStateValues={this.changeStateValues.bind(this)}
            />), 
            oTBilling: (<OTBilling
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
            />), 
            oTSurgeryNotesReport: (<SurgeryReport
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                PatientDetail={this.state.OtPatienDetils}
            />), 
            vendorBilling:(<VendorBilling
                vendorId = {this.state.vendorId}
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
            />),

            adminDashboard :(<AdminDashboard showResposeValue={this.showResposeValue.bind(this)}/>),
            superAdminBillingTransaction: (<BillingTransaction
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            superAdminMain: (<SuperAdminIndex
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                selectedSubScreen = {this.state.selectedSubScreen}
                statisticsBranchId = {this.state.statisticsBranchId}
                statisticsFromDate = {this.state.statisticsFromDate}
                statisticsToDate = {this.state.statisticsToDate}
                dateFilterstatus = {this.state.dateFilterstatus}
                isSideMenuClick = {this.state.isSideMenuClick}
            />),
            superAdminStatistics:(<Statistics
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
                getSuperAdminStatistics={this.getSuperAdminStatistics}
            />),
            allBillingStatistics:(<AllBillingStatistics
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            inPatientBilling:(<InPatientBilling
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            outPatientBilling:(<OutPatientBilling
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            refferalBilling:(<RefferalBilling 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            pharmacyBilling:(<PharmacyBilling 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            laboratoryBilling:(<LaboratoryBilling 
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            superAdminSettings:(<Settings
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            stockTransfer:(<StockTransfer
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            inBound:(<Inbound
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            outBound:(<OutBound
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            stockTransferReport:(<StockTransferReport
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            approvalRequest:(<ApprovalRequest
                changeScreen={this.changeScreen.bind(this)}
                showResposeValue={this.showResposeValue.bind(this)}
                userType={this.state.userType}
            />),
            CollabSummary:(
                <CollabSummary
                patientAppointment={this.state.patientAppointment} 
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor} 
                backToNotes={this.BackToNotes.bind(this)} 
                showDocumentImage={this.showImage.bind(this)}/>
            // <ApprovalRequest
            //     changeScreen={this.changeScreen.bind(this)}
            //     showResposeValue={this.showResposeValue.bind(this)}
            //     userType={this.state.userType}
            // />
            ),
            PendingBillReport: (
                <PendingBillsReport
                    patientAppointment={this.state.patientAppointment}
                    selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                />
            )
            
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }



    renderImgBtn = (img, title, menuItem, imageFlag = false) => {
        return (
            <View style={{
                // backgroundColor: imageFlag ? color.white : "",
                height: 67,
                width: "100%", alignItems: "center", justifyContent: "center", alignSelf: 'center', alignContent: 'center',
                marginTop: 16,
                borderRadius: 50
            }}>
                <View style={{ paddingHorizontal: 9, paddingVertical: 7, backgroundColor: imageFlag ? color.white : "", borderRadius: 4 }}>
                    <Image
                        source={img}
                        style={{
                            height: 22,
                            width: 22,
                            tintColor: imageFlag ? color.themeDark : color.white,//color.lightGray
                        }}
                        resizeMode="contain"
                    />
                </View>
                <Text style={{ fontSize: 10, fontWeight: "500", color: imageFlag ? color.white : color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={3}>{title}</Text>
            </View>
        );
    };

    getPatientDetail(PatientDetails, screen, OPappointmentID) {
        this.setState({
            OtPatienDetils: PatientDetails,
            selectedScreen: screen,
            patientInfo: PatientDetails,
            OPappointmentID: OPappointmentID,
            selectedIpPatientDetails: PatientDetails
        },() => {
            this.getLabPatientInfo(PatientDetails)
        })
    }

    changeScreen(
        screen,
        patientAppointment = {},
        userType = "",
        selectedClinicOrDoctor = {},
        selectedSubScreen = "",
        selectedIpPatientDetails = {},
        ipPatientAndDoctorIDs = {},
        flag = false,
        reportID = "",
        selectedLabPatientDetails = {}, istransmit = false,
        isLabpatientHome = false,
        isIpDemographic = false,
        isIpPatientCheckouted = this.state.isIpPatientCheckouted,
        modificationDetails = {},
        vendorId = "",
        patientWithOutAppointment = false
    ) {
        if(patientWithOutAppointment){

            this.getLabPatientInfo(patientAppointment);
            this.setState({
                isNoAppointment: !this.state.isNoAppointment
            })
        } 
        if (selectedLabPatientDetails.patient_id) {
            if(screen === "iPPatientList"){
                states["selectedLabPatientDetails"] = {};
                states["patientInfo"] = {};
            }else {
                this.getLabPatientInfo(selectedLabPatientDetails)
            }
        }

        if(screen === "adminConfig"){
            this.setState({
                refreshAdminData: true
            })
        }else{
            this.setState({
                refreshAdminData: false
            })
        }

        if (flag) {
            this.setState({
                isCurrentPageIsIPPatientAppointment: flag
            })
        }
        this.clearPatientPhotos()
        this.props.updateDateFilterFlag(false)
        var states = this.state;
        states["isSideMenuClick"] = flag
        states["vendorId"] = vendorId;
        states['ipModifyBillDetail'] = Object.keys(modificationDetails).length == 0 ? this.state.ipModifyBillDetail : modificationDetails  
        states['modificationBillDetails'] = Object.keys(modificationDetails).length > 0 ? modificationDetails : this.state.modificationBillDetails  
        states["searcBoxTextValue"] = ""
        states["editPatientID"] = reportID
        states["savedBillInvoiceID"] = reportID
        states["isIpPatientCheckouted"] = isIpPatientCheckouted
        states["istransmit"] = istransmit
        // states["isLabpatientHome"] = isLabpatientHome
        states["isLabpatientHome"] = Object.keys(this.state.selectedLabPatientDetails).length === 0 ? isLabpatientHome : true
        states["reportID"] = reportID
        states["selectedIpPatientDetails"] =


            (screen != "iPPatientList" && Object.keys(selectedIpPatientDetails).length) === 0 ?
                this.state.selectedIpPatientDetails :
                selectedIpPatientDetails
        states["patientAppointment"] = Object.keys(patientAppointment).length === 0 ? this.state.patientAppointment : patientAppointment;
        states["userType"] = userType ? userType : this.state.userType;
        states["selectedClinicOrDoctor"] = Object.keys(selectedClinicOrDoctor).length === 0 ? this.state.selectedClinicOrDoctor : selectedClinicOrDoctor;
        // states["ipPatientAndDoctorIDs"] = Object.keys(ipPatientAndDoctorIDs).length === 0 ? this.state.ipPatientAndDoctorIDs : ipPatientAndDoctorIDs;
        if( Object.keys(modificationDetails).length > 0 || Object.keys(this.state.modificationBillDetails).length > 0 ) {
            states['patientInfo'] = Object.keys(patientAppointment).length === 0 ? this.state.patientAppointment : patientAppointment;
        } else if( Object.keys(modificationDetails).length > 0 ) {
            states['isModifyBillUpdate'] = false
        }
        if (screen == "laboratoryPatientList" || screen === "iPPatientList") {
            states["selectedLabPatientDetails"] = {};
            states["patientInfo"] = {};
            states["modificationBillDetails"] = {};
            states["selectedIpPatientDetails"] = {};


        } else {
            states["selectedLabPatientDetails"] = Object.keys(selectedLabPatientDetails).length === 0 ? this.state.selectedLabPatientDetails : selectedLabPatientDetails;
        }

        this.setState({ 
            states
         }, () => {
            let states = this.state
            if(this.state.isSideMenuClick) {
                this.setState({
                  statisticsBranchId: "",
                  statisticsFromDate: converted_date,
                  statisticsToDate: converted_date,
                  dateFilterstatus: "",
                })
            }
           if(screen == "iPPatientList") {
              states['invoiceID'] = "";
              states['ipAdmissionID'] = "";
              states['selectedIpPatientDetails'] = {};
              states['ipModifyBillDetail'] = {};

           } else if( Object.keys(modificationDetails).length > 0 ) {
            states['isModifyBillUpdate'] = false
           } else { 
            states['invoiceID'] = Object.keys(modificationDetails).length > 0 ? modificationDetails.id : this.state.ipModifyBillDetail.id
            states["ipAdmissionID"] = Object.keys(selectedIpPatientDetails).length > 0 ? selectedIpPatientDetails.admit_id : this.state.selectedIpPatientDetails.admit_id 
           }
            if( this.state.ipModifyBillDetail && this.state.selectedIpPatientDetails.patient_id ) {
                this.getLabPatientInfo(this.state.selectedIpPatientDetails)
            } 
            if (screen == "home") {
                states["selectedScreen"] = screen;
                states["patientAppointment"] = {};
                states["selectedSubScreen"] = "";
                states["patientInfo"] = {};
                states["modificationBillDetails"] = {};
                states["isNoAppointment"] = false;
                
                // this.setState({
                //     selectedScreen: screen,
                //     patientAppointment: {},
                //     selectedSubScreen: "",
                //     patientInfo: {},
                // },()=>{
                // this.setState({
                //     refreshHomeComponentFlag:true
                // })
                // })
            } else if (screen) {

                if (screen == "iPPatientList") {
                    states["selectedScreen"] = screen;
                    states["selectedSubScreen"] = selectedSubScreen;
                    states["isCurrentPageIsIP"] = true;
                    states["isCurrentPageIsIPPatientAppointment"] = false;

                    states["isIpPatientCheckouted"] = false;

                } else if (((screen == "ipPatientCreateBill" && this.state.isCurrentPageIsIPPatientAppointment) || (screen == "ipPatientSavedBill" && this.state.isCurrentPageIsIPPatientAppointment) || (screen == "ipallTransactions" && this.state.isCurrentPageIsIPPatientAppointment) || screen == "optDoctorNotes") && this.state.isCurrentPageIsIP ) {
                    states["selectedScreen"] = screen;
                    states["selectedSubScreen"] = selectedSubScreen;
                    states["isCurrentPageIsIP"] = true;
                    states["isCurrentPageIsIPPatientAppointment"] = true;
                    this.setState({
                        labSavedBillList:selectedLabPatientDetails
                    })
                    if(screen == "ipPatientCreateBill" && isIpPatientCheckouted ){
                        states["selectedScreen"] = "ipallTransactions";
                    }

                    if (selectedIpPatientDetails) {

                        if(screen == "ipPatientList"){
                            this.setState({
                                ipPatientProfileDetails: {},
                                patientInfo: {}
                            })
        
                        } else {
                            this.getIpPatientProfileDetails(selectedIpPatientDetails)
                        }
                    }

                } else {
                    states["selectedScreen"] = screen;
                    states["selectedSubScreen"] = selectedSubScreen;
                    states["isIpDemographic"] = isIpDemographic
                }

            }
            this.setState({
                states
                // selectedScreen: screen,
                // selectedSubScreen: selectedSubScreen
            }, () => {
                if (screen == "home") {
                    this.setState({
                        refreshHomeComponentFlag: true,
                        isCurrentPageIsIP: false,
                        selectedIpPatientDetails: "",
                        isNoAppointment:false
                    })
                }
                if (screen == "dischargeSummary" || screen == "insurance" || screen == "patientCheckout") {
                    if (selectedIpPatientDetails) {
                        this.getIpPatientProfileDetails(selectedIpPatientDetails)
                    }
                }
               
            })

        })

    }

    changeStateValues(patientInfo, patientAppointment = {}, userType = {}, selectedClinicOrDoctor = {}, clicni = {}, allClinicList = {}) {

        var states = this.state;
        // if(patientInfo)
        states["clinicList"] = allClinicList
        states["patientInfo"] = Object.keys(patientInfo).length > 0 ? patientInfo : this.state.patientInfo;
        // if(patientAppointment)
        states["patientAppointment"] = patientAppointment;
        // if(userType)
        states["userType"] = userType ? userType : this.state.userType;
        // if(selectedClinicOrDoctor)
        states["selectedClinicOrDoctor"] = selectedClinicOrDoctor;
        states["selectedClinicOrDoctor"] = selectedClinicOrDoctor;
        this.setState({ states })
    }

    async changePickerDropDownValue(flag, selectedValue, pickerKey) {

        var states = this.state;

        states["showPickerDropDownFlag"] = flag
        if (pickerKey == "Doctor") {

            if (selectedValue) {
                await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
                states["selectedClinicOrDoctor"] = selectedValue;
            }
        } else if (pickerKey == "FO") {
            if (selectedValue) {
                await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
                states["selectedClinicOrDoctor"] = selectedValue;
                // this.createCounterInQMS();
            }
        }

        this.setState({
            states,
            modelCloseStatus: selectedValue ? "success" : "",
            selectedClinicOrDoctor: selectedValue ? selectedValue : this.state.selectedClinicOrDoctor
        }, () => {
            if (Constants.ROLE_FO == this.state.userType) {
                // do nothing
            } else {
                this.unLockDoctor()
            }
            //   this.changeStateValues({}, {}, "", this.state.selectedValue)
            //   var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
             
              if (selectedValue) {
                this.getAppointments(this.state.currentAppointmentsDateValue);
            }
        })
    }
    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: ""});
            }, 3000);
        })
    }
    showResetResposeValue(type, message) {
        this.setState({
            resetResponseType: type,
            resetResponseMeaasge: message
        }, () => {
            setTimeout(() => {
                this.setState({ resetResponseType: "",
                resetResponseMeaasge: "" });
            }, 3000);
        })
    }


    successAlert() {
        return (
            <View style={[{  width: "40%", backgroundColor: (this.state.responseMeaasge).includes("Credit Limit Exceeded") ? "#FF7600" : "#DEF2D6",  borderColor: "#B8C7B2", height: '4.55vw' }, styles.AlertWrapper]}>
                <View style={styles.AlertContentView}>
                    <Success size={'1.62vw'} name={"check"} color={(this.state.responseMeaasge).includes("Credit Limit Exceeded,") ? "white" : "green"} />
                    <Text style={[styles.SuccessTitle, {color: (this.state.responseMeaasge).includes("Credit Limit Exceeded,") ? color.white : null}]}>{"SUCCESS: "}
                        <Text style={[styles.SuccessText, {color: (this.state.responseMeaasge).includes("Credit Limit Exceeded,") ? color.white : null}]}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={(this.state.responseMeaasge).includes("Credit Limit Exceeded,")  ? "white" : "green"} />
                </TouchableOpacity>
            </View>
        );
    }
    resetSuccessAlert() {
        return (
            <View style={[{  width: "40%", backgroundColor: "#DEF2D6",  borderColor: "#B8C7B2", height: '4.55vw' }, styles.AlertWrapper]}>
                <View style={styles.AlertContentView}>
                    <Success size={'1.62vw'} name={"check"} color={"green"} />
                    <Text style={styles.SuccessTitle}>{"SUCCESS: "}
                        <Text style={styles.SuccessText}>{this.state.resetResponseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ resetResponseType: '' }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={"green"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
 
        let errorList = (this.state.responseMeaasge) ? (this.state.responseMeaasge).split(",") : []
        return (
            <View style={[{ width: "35%", backgroundColor: "#CD3A34", borderColor: "#A58B8A",  minHeight: '4.55vw'}, styles.AlertWrapper]}>
                <View style={[styles.AlertContentView, {marginTop: '0.97vw' }]}>
                    <View style={styles.ErrorView1}>
                        <Icon size={'1.62vw'} name={"ban"} color={"#FFF"} /></View>
                    <View style={styles.ErrorView2} >
                        <View>
                            <Text style={styles.ErrorTitle}>{"ERROR: "}
                                {errorList && errorList.map((item, index) => {
                                    return <View key={index}><Text style={styles.ErrorText}>{item}</Text></View>
                                })}
                            </Text>
                        </View>

                    </View>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "", resetResponseType: '' }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={"#fff"} />
                </TouchableOpacity>
            </View>
        );
    }
    resetErrorAlert() {
 
        let errorList = (this.state.resetResponseMeaasge) ? (this.state.resetResponseMeaasge).split(",") : []
        return (
            <View style={[{ width: "35%", backgroundColor: "#CD3A34", borderColor: "#A58B8A",  minHeight: '4.55vw'}, styles.AlertWrapper]}>
                <View style={[styles.AlertContentView, {marginTop: '0.97vw' }]}>
                    <View style={styles.ErrorView1}>
                        <Icon size={'1.62vw'} name={"ban"} color={"#FFF"} /></View>
                    <View style={styles.ErrorView2} >
                        <View>
                            <Text style={styles.ErrorTitle}>{"ERROR: "}
                                {errorList && errorList.map((item, index) => {
                                    return <View key={index}><Text style={styles.ErrorText}>{item}</Text></View>
                                })}
                            </Text>
                        </View>

                    </View>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ resetResponseType: '' }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={"#fff"} />
                </TouchableOpacity>
            </View>
        );
    }

    profileDropDownFlagChange(flag) {
        this.setState({
            showProfileDropDownFlag: flag
        })
    }
    labReportHomePage() { }
    labTestConfig() {
        // alert("testConfig")
        this.setState({
            selectedScreen: "testConfig"
        })
    }
    labRefferedDoctors() {
        this.setState({
            selectedScreen: "labRefferedDoctors"
        })
    }
    labSavedBills(flag) {
        this.getLabSavedList()

        this.setState({
            showLabSavedBill: flag,
        })
    }
    labTransmitedChanges(flag) {

        this.getTransmittedChangeList()

        this.setState({
            showLabTransmited: flag,
        })


    }

    
    getAppointmentListsOfLabPatient = (searchkey = "", searchDate) => {
        var serviceUrl = Constants.APPOINTMENT_LIST_FOR_LAB_PATIENT + "?search_date=" + moment(searchDate).format('YYYY-MM-DD') + "&search_key=" + searchkey
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getAppointmentListsOfLabPatientSuccess,
            this.getAppointmentListsOfLabPatientFailure
        );
    }

    getAppointmentListsOfLabPatientSuccess = success => {

        function getUniqueListBy(arr, key) {
            console.log(arr)
            return [...new Map(arr.map(item => [item[key], item])).values()]
        }

        var getConcatArray = []
        var appointments = success.data
        if (this.state.showLabTransmitedList.length > 0) {
            if (appointments.length > 0) {
                var fullList = [...appointments, ...this.state.showLabTransmitedList]
                var datas = getUniqueListBy(fullList, "appointment_id");
                getConcatArray = datas
            } else {
                getConcatArray = this.state.showLabTransmitedList;
            }
        } else {
            getConcatArray = appointments;
        }

        var getSortedDatas = [];

        if (getConcatArray.length > 0) {
            var app = [];
            var transmit = [];

            for (let i = 0; i < getConcatArray.length; i++) {
                if (getConcatArray[i].transmit_date) {
                    transmit.push(getConcatArray[i])
                } else {
                    app.push(getConcatArray[i])
                }

            }
            getSortedDatas = [...transmit, ...app]
        }

        this.setState({
            showLabTransmitedList: getSortedDatas
        })

    }
    getAppointmentListsOfLabPatientFailure = error => { alert(JSON.stringify(error)); }
    obgynCommonPrint = () => {
        var serviceUrl = Constants.OBGYN_ANTENATAL_PRINT+
        "?antenatal_id=" + this.state.antenatal_id

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.obgynCommonPrintSuccess,
            this.obgynCommonPrintFailure,
            "pdf"
        );
    }

    obgynCommonPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    };

    obgynCommonPrintFailure = error => {
        //console.log("Get clinic list error response");
        console.log(error);
    };
    ipCreditLimitPost = () => {
        var service_url = Constants.IP_CREDIT_LIMIT
        var { selectedIpPatientDetails } = this.state
        var data = {
            "ip_credit_limit": this.state.ipCreditLimit,
            "ip_credit_overdue": this.state.isIPCreditExceedBlock,
            "lab_credit_limit": this.state.labCreditLimit,
            "lab_credit_overdue": this.state.isLabCreditExceedBlock,
            "pharmacy_credit_limit": this.state.pharmaCreditLimit,
            "pharmacy_credit_overdue":this.state.isPharmaCreditExceedBlock,
            "ip_admission_id": selectedIpPatientDetails.id,
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.ipCreditLimitPostSuccess,
            this.ipCreditLimitPostFailure
        );
    }
    ipCreditLimitPostSuccess = success => {
        if (success.status == "success") {
           this.getIPCreditLimit()
           this.setState({
            isCreditLimit: false
           })
        }
    }
    ipCreditLimitPostFailure = error => {
        this.setState({
        })
    }
    ipPatientListPrint = () => {
        var service_url = Constants.IP_PATIENT_LIST_GET + "?from_date=" + this.state.selectedFromDate + "&to_date=" + this.state.selctedToDate + "&export_type=pdf";
        OpthamologyService.getInstance().documentUploadGet(
            service_url,
            // this,
            this.ipAppointmentPrintSuccess,
            this.ipAppointmentPrintFailure,
            "pdf"
        );
    }

    ipAppointmentPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    };

    ipAppointmentPrintFailure = error => {
        //console.log("Get clinic list error response");
        console.log(error);
    };

    AutoLogout (){
        this.profileDropDownFlagChange();
        localStorage.clear();
        window.location.reload()
        this.props.navigation.navigate("auth");
    }
    getSuperAdminStatistics = (branch_id, fromDate, toDate, dateFilterstatus) => {
        this.setState({
            statisticsBranchId: branch_id,
            statisticsFromDate: fromDate,
            statisticsToDate: toDate,
            dateFilterstatus: dateFilterstatus
        })
    }
    getSelectedAppointmentStatus = (status) => {
        this.setState({
            selectedAppointmentStatus: status
        })
    } 

    getPurposeOfVisitData = () => {
        try{
            var serviceUrl = Constants.FO_POV_LIST;
      
          OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getPurposeOfVisitSuccess,
            this.getPurposeOfVisitError
          );
        }catch(error){
            console.log(error.message);
        }
    }

    getPurposeOfVisitSuccess = (data) => {
        if(data.status === 'success'){
            AsyncStorage.setItem("PurposeOfVisit",JSON.stringify(data.data))
    }
    }
    getPurposeOfVisitError = () => {

    }
    

    render() {
        this.getDRProfilePhoto()
        var { selectedScreen, patientAppointment, patientInfo } = this.state;
        return (
            <TouchableWithoutFeedback onPress={()=>{
                this.props.updateDateFilterFlag(false)
                this.props.updateMultiAxialDropDownFlag([])
            }} {...this._panResponder.panHandlers}>
                <View style={styles.container}>
                    
                {
                    /* system idle for 30 mins  */
                    this.state.isIdle ? this.AutoLogout() : null
                    
                }

                    {/* <View> */}
                    <CommonHeader
                        isFoAdmin={this.state.isFoAdmin}
                        navigation={this.props.navigation}
                        currentPage={this.state.selectedScreen}
                        isOptClinic={this.state.isOptClinic}

                        patientAppointment={this.state.patientAppointment}
                        patientInfo={this.state.patientInfo}
                        showLabSavedBillList={this.state.showLabSavedBillList}
                        // for home screen Drop down start
                        showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                        pickerKey={this.state.userType == Constants.ROLE_FO ? "FO" : "Doctor"}
                        selectedValue={this.state.selectedClinicOrDoctor}
                        arrayOfData={this.state.arrayOfClinicData}
                        changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                        // for home screen Drop down end
                        drType={this.state.drType}
                        role_Type={this.state.role_Type}
                        // for home screen Search start
                        searchValue={this.state.selectedScreen == "home" ? this.state.searcBoxTextValue : this.state.patientSearchTextValue}
                        searchPatient={this.searchPatientList.bind(this)}
                        searchPatientTextChange={this.searchPatient.bind(this)}
                        FilterSearch={this.FilterSearch.bind(this)}
                        // for home screen Search end

                        unLockDoctorNotes={this.unLockDoctorNotes.bind(this)}

                        apppointmentRequests={this.apppointmentRequests.bind(this)}

                        viewReportDRnotesRequests={this.viewReportDRnotesRequests.bind(this)}
                        printAppointmentListToken={this.printAppointmentListToken.bind(this)}
                        changePageOrNavigateTOAnotherPage={this.changePageOrNavigateTOAnotherPage.bind(this)}
                        // profile 
                        doctorName={this.state.doctorName}
                        doctorProfilePhoto={this.state.doctorProfilePhoto}
                        userType={this.state.userType}
                        openCameraModal={this.openCameraModal.bind(this)}
                        // 

                        // dropDown
                        showProfileDropDownFlag={this.state.showProfileDropDownFlag}
                        profileDropDownFlagChange={this.profileDropDownFlagChange.bind(this)}
                        logoutFunction={this._logoutFunction.bind()}

                        // BI And Registry
                        biAndRegistry={this.biAndRegistry.bind(this)}
                        exportRegistry={this.exportRegistry.bind(this)}
                        // IP Billing txn print
                        ipBillingTxnPrint={this.ipBillingTxnPrint.bind(this)}
                        dischargeSummaryPrint={this.dischargeSummaryPrint.bind(this)}

                        //Referal Notes
                        referalNotes = {this.referalNotes.bind(this)}

                        ipPatientProfileDetails={this.state.ipPatientProfileDetails}
                        isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                        isCurrentPageIsIPPatientAppointment={this.state.isCurrentPageIsIPPatientAppointment}

                        // Laboratory
                        labReportHomePage={this.labReportHomePage.bind(this)}
                        labTestConfig={this.labTestConfig.bind(this)}
                        labSavedBills={this.labSavedBills.bind(this)}
                        labRefferedDoctors={this.labRefferedDoctors.bind(this)}
                        labTransmitedChanges={this.labTransmitedChanges.bind(this)}


                        drType={this.state.drType}

                        labUserAccessType={this.state.labUserAccessType}
                        isIpPatientCheckouted = {this.state.isIpPatientCheckouted}
                        printIpPatientCheckoutList={this.printIpPatientCheckoutList.bind(this)}
                        immunizationPrint={this.immunizationPrint.bind(this)}
                        ipPatientListPrint={this.ipPatientListPrint.bind(this)}
                        modificationBillDetails = {this.state.modificationBillDetails}
                        obgynCommonPrint = { this.obgynCommonPrint.bind(this)}
                        CollabReportScreen= {this.CollabReportScreen.bind(this)}
                        creditBillLimit = {this.creditBillLimit.bind(this)}
                        isAdminAccess = {this.state.isAdminAccess}
                    />
                    <View style={styles.bodyContainer}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "6%", backgroundColor: color.themeBackgroud, height: "100%" }}>
                            <SideMenuComponents
                                changeScreen={this.changeScreen.bind(this)}
                                selectedScreen={this.state.selectedScreen}
                                patientAppointment={this.state.patientAppointment}
                                patientInfo={this.state.patientInfo}
                                userType={this.state.userType}
                                isRoleAdmin={this.state.isRoleAdmin}
                                isCurrentPageIsIP={this.state.isCurrentPageIsIP}
                                selectedIpPatientDetails={this.state.selectedIpPatientDetails}
                                labUserAccessType={this.state.labUserAccessType}
                                selectedLabPatientDetails={this.state.selectedLabPatientDetails}
                                showResposeValue={this.showResposeValue.bind(this)}
                                isNoAppointment={this.state.isNoAppointment}
                            />
                        </ScrollView>

                        <View style={{ width: "94%" }}>
                            <TouchableWithoutFeedback onPress={() => {
                                this.props.updateDateFilterFlag(false)
                                this.props.updateMultiAxialDropDownFlag([])
                                this.clearSearchTextBoxValue()
                                this.profileDropDownFlagChange(false)

                            }}>
                                <View>
                                    {
                                        this.state.responseType == "success" ?
                                            this.successAlert() :
                                            this.state.responseType == "error" ?
                                                this.errorAlert() : null
                                    }
                                    {this.renderComponent()}
                                </View>
                            </TouchableWithoutFeedback>
                        </View>

                    </View>

                    {
                        this.state.showBillinListPopUpFlag ?
                            <ShowPatientBilling patientId={this.state.patientId} invoiceNo={this.state.invoiceNo}
                                closeBillingModal={this.closeBillingModal.bind(this)}
                                changeScreen={this.changeScreen.bind(this)}
                                userType={this.state.userType}
                                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                                patientInfo={this.state.patientInfo} billingDetails={this.state.billingDetails} /> : null
                    }

                    {
                        this.state.showAppointmentRequestModalFlag ?
                            <AppointmentRequest
                                userType={this.state.userType} AcceptRequest={this.setModalVisible.bind(this)}
                                cancelRequest={this.cancelRequest.bind(this)}
                                appointmentRequestModalFlag={this.appointmentRequestModalFlag.bind(this)}
                                appointmentRequest={this.state.appointmentRequest}
                            /> : null
                    }

                    {
                        this.state.showUnlockDoctorNotes ?
                            <UnlockDoctorNotes getAppointments={this.getAppointments.bind(this)} unLockDoctorNotesModalFlag={this.unLockDoctorNotesModalFlag.bind(this)} getUnLockDoctor={this.state.getUnLockDoctor} /> : null
                    }
                    {
                        this.state.showmarkOutofOfficeModal ?
                            <MarkOutofOfficeModal
                                markOutOfOfficeSaveAction={this.markOutOfOfficeSaveAction.bind(this)}
                                sessionStateDateAndTime={this.state.sessionStateDateAndTime}
                                sessionEndDateAndTime={this.state.sessionEndDateAndTime}
                                changeSessionDateAndTime={this.changeSessionDateAndTime.bind(this)}
                                detailsTextBoxValue={this.state.markOutOfLocationDetailsTxtBox}
                                markOutOffLocationErrorMessage={this.state.markOutOffLocationErrorMessage}
                                onPressSessionAction={this.onPressSessionAction.bind(this)}
                                selectedSessionValue={this.state.selectedSessionValue}
                                listOfClinicDetails={this.state.arrayOfClinicData}
                                multiSelecteDropDownShowHide={this.multiSelecteDropDownShowHide.bind(this)}
                                onSelectClinicDatas={this.onSelectClinicDatas.bind(this)}
                                selectectedItems={this.state.selectectedItems}
                                isShownPicker={this.state.isShownPicker}
                                markOutofOfficeModalFlag={this.markOutofOfficeModalFlag.bind(this)}
                            /> : null
                    }

                    {
                        this.state.showSelectedShiftcounterListFlag ?
                            <ShowShiftCounterList hideSelectedShiftcounterListFlag={this.hideSelectedShiftcounterListFlag.bind(this)} saveShiftCounter={this.saveShiftCounter.bind(this)} selectedCounter={this.state.selectedCounter} shiftcounterList={this.state.shiftcounterList} showSelectedShiftcounterList={this.showSelectedShiftcounterList.bind(this)} /> : null
                    }

                    {
                        this.state.changePasswordModalFlag ?
                            <ChangePassword
                                oldPassword={this.state.oldPassword}
                                newPassword={this.state.newPassword}
                                confirmPassword={this.state.confirmPassword}
                                changePasswordSaveAction={this.changePasswordSaveAction.bind(this)}
                                changePasswordValues={this.changePasswordValues.bind(this)}
                                changePasswordValidationMessage={this.state.changePasswordValidationMessage}
                                changePasswordOpenAndClose={this.changePasswordOpenAndClose.bind(this)} /> : null

                    }
                    {
                        this.state.enableCameraModalFlag ?
                            <TakePickerConst
                                closeCameraModal={this.closeCameraModal.bind(this)}
                                updateProfilePhoto={this.updateProfilePhoto.bind(this)}
                                cameraFor={this.state.enableCameraFor}
                                recognizePatientPhoto={this.recognizePatientPhoto.bind(this)}
                            /> : null
                    }
                    {
                        this.state.showDocumentImageFlag ?
                            <ShowDocumentImage showDocumentImage={this.showImage.bind(this)} selectedImage={this.state.showDocumentImage} documentImgList={this.state.documentImgList} /> : null
                    }
                    {this.state.modalVisible ?

                        <TouchableWithoutFeedback
                            onPress={() => { this.modelTimerClose() }}
                        >
                            <NextAppointment
                                request_id={this.state.request_id}
                                modalVisible={this.state.modalVisible}
                                modelClose={this.modelClose.bind(this)}
                                patientId={this.state.patientId}
                                nextAppoinmentatPientDetails={this.state.nextAppoinmentatPientDetails}
                                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                                isNextSchedule={this.state.isNextSchedule}
                                showResposeValue={this.showResposeValue.bind(this)}
                            />
                        </TouchableWithoutFeedback>
                        : null
                    }
                    {this.state.CancelAppointmentVisible ?
                        <TouchableWithoutFeedback> 
                            <CancelAppointment 
                            CancelAppointment_type = {this.state.CancelAppointment_type}
                            CancelAppointment_item = {this.state.CancelAppointment_item}
                            CancelAppointmentVisible = {this.CancelAppointmentVisible} 
                            modelvisible = {this.state.CancelAppointmentVisible} 
                            showResposeValue={this.showResposeValue.bind(this)}
                            userType={this.state.userType}
                            selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                            selectedAppointmentStatus = {this.state.selectedAppointmentStatus}
                            />
                        </TouchableWithoutFeedback>
                        : null
                    }

                   {this.state.WaitingCommentsVisible ?
                        <TouchableWithoutFeedback> 
                            <WaitingComments 
                            WaitingComments_type = {this.state.WaitingComments_type}
                            WaitingComments_item = {this.state.WaitingComments_item}
                            WaitingCommentsVisible = {this.WaitingCommentsVisible} 
                            modelvisible = {this.state.WaitingCommentsVisible}
                            appointment_id ={this.state.appointment_id}
                            resentComment ={this.state.resentComment}  
                            resentappointment_id ={this.state.resentappointment_id}
                            />
                        </TouchableWithoutFeedback>
                        : null
                    }


                        {this.state.isSpecimenModalVisible ?
                        <TouchableWithoutFeedback>
                            <SpecimenCollectedModal
                                showResposeValue={this.showResposeValue.bind(this)}
                                closeSpecimenModal={this.closeSpecimenModal}
                                specimen_tooltip={this.state.specimen_tooltip}
                                specimen_item={this.state.specimen_item}
                                specimen_key={this.state.specimen_key}
                                showSpecimen={this.displaySpecimen}  
                            ></SpecimenCollectedModal>
                        </TouchableWithoutFeedback>
                         :null}
                    {
                        this.state.showRoomDetailsPopupFlag ?
                            <BedChargeModel sendBedChargeTotalAmount={this.sendBedChargeTotalAmount.bind(this)} showRoomDetailsPopup={this.showRoomDetailsPopup.bind(this)} roomDetailsPopupData={this.state.roomDetailsPopupData} />
                            : null
                    }

                {
                    this.state.showBillingTransactionPopup ?
                        <BillingTransactionPopup showBillingScreenPopup={this.showBillingScreenPopup.bind(this)} 
                        billingTransactionPopupList={this.state.billingTransactionPopupList} 
                        billingTransectionPrint={this.billingTransectionPrint.bind(this)} 
                        ipBillCancel={this.ipBillCancel.bind(this)}
                        refreshAllBills={this.state.refreshAllBills}
                        stopRefreshAllBills={this.stopRefreshAllBills.bind(this)}
                        changeScreen={this.changeScreen.bind(this)}
                        isAdminAccess = {this.state.isAdminAccess}
                        UserEditAccess = {this.state.isUserEditAccess}
                        isCancelBillAccess= {this.state.isCancelBillAccess}
                        BillStatus={this.state.BillStatus}/> : null
                }

                {
                    this.state.showLabBillingTransactionPopup ?
                        <LabBillingTransactionPopup 
                        showLabBillingTransactionPopup={this.showLabBillingTransactionPopup.bind(this)} 
                        billingLabTransactionPopupList={this.state.billingLabTransactionPopupList} 
                        labBillingTransectionPrint={this.labBillingTransectionPrint.bind(this)} 
                        labBillCancel={this.labBillCancel.bind(this)}
                        refreshAllBills={this.state.refreshAllBills}
                        stopRefreshAllBills={this.stopRefreshAllBills.bind(this)}
                        changeScreen={this.changeScreen.bind(this)}
                        isAdminAccess = {this.state.isAdminAccess}
                        UserEditAccess = {this.state.isUserEditAccess}
                        isCancelBillAccess= {this.state.isCancelBillAccess}
                        BillStatus={this.state.BillStatus}/> : null
                }

                    {
                        this.state.showLabTransmited ?
                            <LabTransmitedChanges showLabtransmittedPopup={this.showLabtransmittedPopup.bind(this)} showLabTransmitedList={this.state.showLabTransmitedList} getTransmittedChangeList={this.getTransmittedChangeList.bind(this)} changeScreen={this.changeScreen.bind(this)} labTransmitPrint={this.labTransmitPrint.bind(this)} /> : null
                    }

                    {
                        this.state.showLabSavedBill ?
                            <LabSavedBill showLabSavedBillPopup={this.showLabSavedBillPopup.bind(this)} showLabSavedBillList={this.state.showLabSavedBillList} getLabSavedList={this.getLabSavedList.bind(this)} changeScreen={this.changeScreen.bind(this)} deleteSavedBill = { this.deleteSavedBill } /> : null
                    }
                    {
                        this.state.nofaceFound ?
                            <FaceRecognitionRespModal closePopup={this.setNofaceFound.bind(this)} /> : null
                    }

                    {
                        this.state.AllModuleBillingTransactionPopup ?
                            <AllModuleBillingTransactionPopup
                                isNoAppointment = {this.state.isNoAppointment}
                                showAlllBillingScreenModulePopup={this.showAlllBillingScreenModulePopup.bind(this)}
                                AllModuleBillingTransactionPopupList={this.state.AllModuleBillingTransactionPopupList}
                                AllModuleBillingTransactionPopupPatientDertails={this.state.AllModuleBillingTransactionPopupPatientDertails}
                                AllModuleBillingTransactionPopupInvoiceNo={this.state.AllModuleBillingTransactionPopupInvoiceNo}
                                billingTransectionPrint={this.printBill.bind(this)}
                                printCancel={this.printCancel.bind(this)}
                                changeScreen={this.changeScreen.bind(this)} 
                                isAdminAccess = {this.state.isAdminAccess}
                                UserEditAccess = {this.state.isUserEditAccess}
                                isCancelBillAccess= {this.state.isCancelBillAccess}
                                /> : null
                    }
                    {
                        this.state.showPharmacyBillingTransactionPopup ?
                            <PharmacyBillingTransactionPopup
                                showPharmacyBillingTransactionPopup={this.showPharmacyBillingTransactionPopup.bind(this)}
                                billingPharmacyTransactionPopupList={this.state.billingPharmacyTransactionPopupList}
                                pharmacyBillingTransectionPrint={this.pharmacyBillingTransectionPrint.bind(this)}
                            />
                            : null
                    }
                    {
                        this.state.showImageLabBillingTxnPopup ?
                        <ImageLabBillingTxnPopup
                        showImageLabBillingTxnPopup={this.showImageLabBillingTxnPopup.bind(this)}
                        billingImageLabPopupList={this.state.billingImageLabPopupList}
                        imageLabTxnPrint={this.imageLabTxnPrint.bind(this)}
                        />
                        : null
                    }

{
            this.state.isResetPassword ? 
            <View style={{position: 'absolute'}}>
                <View style={{
                    position: "absolute",
                    zIndex: -1,
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}>
                    <ResetPassword 
                    logoutFunction={this._logoutFunction.bind()} 
                    showResetResposeValue={this.showResetResposeValue.bind(this)}/>
                    {
                                        this.state.resetResponseType == "success" ?
                                            this.resetSuccessAlert() :
                                            this.state.resetResponseType == "error" ?
                                                this.resetErrorAlert() : null
                                    }
                </View>
            </View>
            
            : null
        }
        { this.state.isCreditLimit ? 
           <View style = {{ zIndex: 10, position: "absolute", }}>
           <View style={styles.CancelPopupContainer}>
                 <View style={styles.FilterPopUpView}>
                   <View style={styles.FiterTop}>
                    <Text style={styles.FilterHeadTxt}>Patient wise credit limit</Text>
                    <TouchableOpacity onPress={()=>{this.setState({isCreditLimit: false})}}>
                     <Icon name="close" size={"1.2vw"} color={color.red}/>
                    </TouchableOpacity>
                   </View>
                   <View style={styles.ButtonContainer}>
                      {this.renderCreditConfigration()}   
                   </View>
                 
               </View>
               <TouchableOpacity
              onPress={() => {this.ipCreditLimitPost()}}
              style={[styles.BtnTouch, {backgroundColor: color.themeDark, borderColor: color.themeDark, borderWidth:1}]}>
              <Text style={[styles.ButtonText, {color: color.white}]}>{"Save"}</Text>
            </TouchableOpacity>
           </View>
          </View> : null

        }
                </View>
                {/* { this.state.userType=="LAB"?
        //     <View style={{ flexDirection: "row", bottom: 0, position: "absolute", marginTop: 20, marginLeft: 120 }}>
        //          <Text style={{ color: color.lightGray }}>{"\u00A9 2021 eMedHub. All Rights Reserved."}</Text>
        //     </View>:null
        //    } 
        //  </View>    */}

       

            </TouchableWithoutFeedback>
        );
    }
    numberValidation(num) {
        return /^\d+$/.test(num);
    }
    renderCreditConfigration = () => {
        var states = this.state
        return(
            <View style={{ flexDirection: "row" }}>
          <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Text>{"IP Credit Limit"}</Text>
                <TextInput
                  style={styles.SearchInput}
                  placeholder={"Enter credit limit"}
                  value={this.state.ipCreditLimit}
                  keyboardType = {"numeric"}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                            ipCreditLimit: text
                        });
                      }
                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isIPCreditExceedBlock? true : false}
                  onChange={() => {
                    this.setState(
                      { isIPCreditExceedBlock: !this.state.isIPCreditExceedBlock })
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={states.isIPCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isIPCreditExceedBlock: !this.state.isIPCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
            </View>
            <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Text>{"Lab Credit Limit"}</Text>
                <TextInput
                  style={styles.SearchInput}
                  placeholder={"Enter credit limit"}
                  value={this.state.labCreditLimit}
                  keyboardType = {"numeric"}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                            labCreditLimit: text
                        });
                      }
                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isLabCreditExceedBlock? true : false}
                  onChange={() => {
                    this.setState(
                      { isLabCreditExceedBlock: !this.state.isLabCreditExceedBlock })
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={states.isLabCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isLabCreditExceedBlock : !this.state.isLabCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
            </View>
            <View style={styles.FilterRows}>
              <View style={styles.FilterRows}>
                <Text>{"Pharmacy Credit Limit"}</Text>
                <TextInput
                  style={styles.SearchInput}
                  placeholder={"Enter credit limit"}
                  value={this.state.pharmaCreditLimit}
                  keyboardType = {"numeric"}
                  onChangeText={(text) => {
                    var isValid = this.numberValidation(text);
                      if (isValid || text === "") {
                        this.setState({
                            pharmaCreditLimit: text
                        });
                      }
                  }}
                />
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={this.state.isPharmaCreditExceedBlock? true : false}
                  onChange={() => {
                    this.setState(
                      { isPharmaCreditExceedBlock: !this.state.isPharmaCreditExceedBlock })
                  }}
                >
                  If Credit limit exceeds show warning{" "}
                </Checkbox>
              </View>
              <View style={styles.FilterRows}>
                <Checkbox
                  checked={states.isPharmaCreditExceedBlock ? false : true}
                  onChange={() => {
                    this.setState(
                      { isPharmaCreditExceedBlock: !this.state.isPharmaCreditExceedBlock });
                  }}
                >
                  If Credit limit exceeds block billing{" "}
                </Checkbox>
              </View>
            </View>
        </View>
        )

    }

    showSelectedShiftcounterList(flag, list, selectedCounter) {
        this.setState({
            showSelectedShiftcounterListFlag: flag,
            shiftcounterList: list,
            selectedCounter: selectedCounter
        })
    }

    hideSelectedShiftcounterListFlag() {
        this.setState({
            showSelectedShiftcounterListFlag: false,
        })
    }

    saveShiftCounter(key, value) {
        // alert(key)
        // alert(JSON.stringify(value))
        // alert(JSON.stringify(this.state.selectedQMSTokenID))
        // alert(JSON.stringify(this.state.selectedCounter))
        // alert(JSON.stringify(this.state.selectedShiftCounterAppointmentDeatails))
        this.createAppointmentForAnotherDoctor() // qms shift coounter
        // this.changeTokenStatusInQMS(value, this.state.selectedQMSTokenID); // need to check BCOZ -- when this function is call that appointment will be completed !!
        // this.setState({
        //     showSelectedShiftcounterListFlag: false,
        // })
    }

    billingTransectionPrint = () => {

        var serviceUrl = Constants.IP_PATIENT_INDIVIDUAL_TRANSACTION + "?bill_id=" + this.state.invoiceID + "&export_type=pdf";

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.billingTransectionPrintSuccess,
            this.billingTransectionPrintFailure,
            "pdf"
        );
    };

    billingTransectionPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        }
    };

    billingTransectionPrintFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };



    labBillingTransectionPrint = () => {

        var serviceUrl = Constants.LAB_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?bill_summary_id=" + this.state.billingLabinvoiceID + "&export_type=PDF";

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.labBillingTransectionPrintSuccess,
            this.labBillingTransectionPrintFailure,
            "pdf"
        );
    };

    labBillingTransectionPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            //  console.log(file)
            window.open(fileURL);
        }
    };

    labBillingTransectionPrintFailure = error => {
        //  console.log("Get clinic list error response");
        console.log(error);
    };


    showBillingScreenPopup(flag, data,invoiceID = "", refreshData = false, status) {
        this.setState({
            showBillingTransactionPopup: flag,
            billingTransactionPopupList: data,
            invoiceID: invoiceID,
            refreshAllBills: refreshData,
            BillStatus : status
        })
    }

    showAlllBillingScreenModulePopup(flag, data, patienDetils, invoiceID = "", patientID = "", refreshData = false) {

        this.setState({
            AllModuleBillingTransactionPopup: flag,
            AllModuleBillingTransactionPopupList: data,
            AllModuleBillingTransactionPopupPatientDertails: patienDetils,
            AllModuleBillingTransactionPopupInvoiceNo: invoiceID,
            allbillPatientID: patientID,
            refreshAllBills: refreshData
        })
    }

    stopRefreshAllBills() {

        this.setState({
            refreshAllBills: false
        })
    }

    showLabBillingTransactionPopup(flag, data,invoiceID = "",refreshData = false, status) {
        this.setState({
            showLabBillingTransactionPopup: flag,
            billingLabTransactionPopupList: data,
            billingLabinvoiceID: invoiceID,
            refreshAllBills: refreshData,
            BillStatus : status

        })
    }


    showPharmacyBillingTransactionPopup(flag, data, invoiceID = ""){
        // alert(JSON.stringify(data))
        this.setState({
            showPharmacyBillingTransactionPopup: flag,
            billingPharmacyTransactionPopupList: data,
            billingPharmacyinvoiceID: invoiceID
        })
    }

    showImageLabBillingTxnPopup(flag, data, invoiceID = ""){
        this.setState({
            showImageLabBillingTxnPopup: flag,
            billingImageLabPopupList: data,
            billingImageLabInvoiceId: invoiceID
        })
    }

    showLabtransmittedPopup(flag) {
        this.setState({
            showLabTransmited: flag,
            // showLabTransmitedList: data,
        })
    }

    showLabSavedBillPopup(flag) {
        this.setState({
            showLabSavedBill: flag,
        })
    }
    deleteSavedBill = (deleteID) => {
        let { appendUrl } = this.state;  
    appendUrl = Constants.LAB_SAVED_BILL_DELETE + "?delete_id=" + deleteID 

      Service.getInstance().getDeleteList(
        appendUrl,
        this,
        this.deleteSavedBillSuccess,
        this.deleteSavedBillFailure
      );
    
  }

  deleteSavedBillSuccess = response => {
      if (response && response.status == "success") {
        this.showResposeValue("success", response.message)

          this.setState({
            showLabSavedBill: false,
            isLabSavedBillDelete: true
          },()=>{
              this.getLabSavedList()
          })
      }
    }     
  deleteSavedBillFailure = error => {
      this.showResposeValue("error", error.message)

  }

    sendBedChargeTotalAmount(totalAmount) {
        this.setState({
            showRoomDetailsPopupFlag: false,
            bedChargeTotalAmount: totalAmount
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    changeSessionDateAndTime(start, end, detailsTextBoxValue) {
        var states = this.state;
        states["sessionStateDateAndTime"] = start;
        states["sessionEndDateAndTime"] = end;
        states["markOutOfLocationDetailsTxtBox"] = detailsTextBoxValue;
        this.setState({ states })
    }

    markOutOfOfficeSaveAction() {

        var states = this.state;
        var getSelectedClinic = []
        if (states.selectectedItems.length > 0) {
            for (let i = 0; i < states.selectectedItems.length; i++) {
                getSelectedClinic.push(states.selectectedItems[i].value)
            }
        }
        var getSelectedSession = []
        if (states.selectedSessionValue.length > 0) {
            for (let i = 0; i < states.selectedSessionValue.length; i++) {
                getSelectedSession.push(states.selectedSessionValue[i].value)
            }
        }


        var data = {
            "clinic": getSelectedClinic,
            "from_date": states.sessionStateDateAndTime,
            "to_date": states.sessionEndDateAndTime,
            "session": getSelectedSession,
            "out_reason": states.markOutOfLocationDetailsTxtBox
        };

        var service_url = Constants.MARK_OUT_OF_OFFICE;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveMarkOutOfOfficeSuccess,
            this.saveMarkOutOfOfficeFailure
        );
    }

    saveMarkOutOfOfficeSuccess = success => {

        if (success.status == "success") {
            this.setState({
                showmarkOutofOfficeModal: false
            })
        } else {
            this.setState({
                markOutOffLocationErrorMessage: success.message
            })
        }
    }
    saveMarkOutOfOfficeFailure = error => {
        this.setState({
            markOutOffLocationErrorMessage: error.message
        })
    }

    onPressSessionAction(key, selectedValue) {
        const sessionTypes = [
            { label: "Session 1", value: 1 },
            { label: "Session 2", value: 2 },
            { label: "Session 3", value: 3 },
            { label: "All Session", value: "All" },
        ]

        if (selectedValue.value !== "All" || (this.state.selectedSessionValue.length == 4)) {

            var { selectedSessionValue } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedSessionValue.length > 0) {
                for (var i = 0; i < selectedSessionValue.length; i++) {
                    if (selectedSessionValue[i].value == selectedValue.value) {
                        spliceData = selectedSessionValue[i];
                        selectedSessionValue.splice(i, 1)
                        var data = this.removeDuplicate(selectedSessionValue);
                        this.setState({ selectedSessionValue: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedSessionValue.push(selectedValue); }
            } else {
                selectedSessionValue.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedSessionValue);
            this.setState({ selectedSessionValue: data })
        } else {

            this.setState({ selectedSessionValue: sessionTypes })

        }
    }


    multiSelecteDropDownShowHide(flag) {
        this.setState({
            isShownPicker: flag
        })
    }

    onSelectClinicDatas(selectedData) {
        this.setState({
            selectectedItems: selectedData
        })
    }

    getAppointments(date) {
        this.setState({
            showUnlockDoctorNotes: false,
            receivedAppoinmentDate: date
        })
    }

    changePasswordSaveAction() {
        var states = this.state;
        var service_url = Constants.DR_PASSWORD_CHANGE;
        var data = {
            "old_password": states.oldPassword,
            "new_password": states.newPassword,
            "confirm_password": states.confirmPassword
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.changePasswordSaveSuccess,
            this.changePasswordSaveFailure
        );
    }

    changePasswordSaveSuccess = success => {
        //  console.log("success ---------> " + JSON.stringify(success))
        if (success.status == "success") {
            this.showResposeValue("success", success.message)
            var states = this.state;
            states["oldPassword"] = "";
            states["newPassword"] = "";
            states["confirmPassword"] = "";
            states["changePasswordValidationMessage"] = "";
            this.setState({
                states,
                changePasswordModalFlag: false
            })
        } else {
            this.setState({
                changePasswordValidationMessage: success.message
            })
        }
    }
    changePasswordSaveFailure = error => {
        this.showResposeValue("success", error.message)

        console.log("error ---------> " + JSON.stringify(error))

    }


    WaitingCommentsVisible =  (flag ,type, item, Comments)=> {
        this.setState({
            WaitingCommentsVisible: flag,
            WaitingComments_type : type,
            WaitingComments_item : item,
            WaitingComments : Comments
        })  
    }

    resentComment = (appointment_id, WaitingComments)=>{
        this.setState({
            resentappointment_id : appointment_id,
            resentComment : WaitingComments
        })
    }


    modelClose(flag, status = "", patientId = "", nextAppoinmentatPientDetails = {}, changeScreen = "", isNextSchedule = false) {
        this.setState({
            modalVisible: flag,             // Next AppoinmentModal Close
            patientId: patientId,
            nextAppoinmentatPientDetails: nextAppoinmentatPientDetails,
            modelCloseStatus: status,
            isNextSchedule: isNextSchedule

        }, () => {
            if (changeScreen) {
                this.setState({
                    selectedScreen: changeScreen
                }, () => {
                    this.setState({
                        refreshHomeComponentFlag: true
                    })
                    // this.todayAppointment()
                })
            }
        })
        // if (status == "success") {

        //     this.setState({
        //         modelCloseStatus:status
        //     })


        // //   this.todayAppointment();
        // //   this.getAppointmentStat();
        // }
    }
    
    CancelAppointmentVisible =  (flag ,type, item, Reason)=> {
        this.setState({
            CancelAppointmentVisible: flag,
            CancelAppointment_type : type,
            CancelAppointment_item : item,
            cancelAppointmentReason : Reason
        }, () => {
            this.setState({
                refreshHomeComponentFlag: true
            })
        })  
    }

    _logoutFunction = async () => {
        this.profileDropDownFlagChange();
        await AsyncStorage.removeItem("loggedIn");
        await AsyncStorage.removeItem("token");
        await AsyncStorage.removeItem("service_type");
        await AsyncStorage.removeItem("doctorName");
        await AsyncStorage.removeItem("doctorProfile");
        await AsyncStorage.removeItem("Selected_Clinic_Doctor");
        await AsyncStorage.removeItem("isFoAdmin");
        await AsyncStorage.removeItem("Is_Lab_Admin");
        await AsyncStorage.removeItem("Lab_User_Access");
        await AsyncStorage.removeItem("loggedInData");
        await AsyncStorage.clear()
        window.location.reload()

        this.props.navigation.navigate("auth");
    };


    setModalVisible(data, appointmentModalClose) {

        this.setState({
            request_id: data.request_id,
            patientId: data.patient_id,
            showAppointmentRequestModalFlag: appointmentModalClose
        })
        var service_url = "";
        if (this.state.userType == Constants.ROLE_FO) {
            service_url = Constants.FO_DOCTOR_PATIENT_INFO + data.patient_id;
        } else {
            service_url = Constants.DOCTOR_PATIENT_INFO +  data.patient_id;
        }

        // var service_url = Constants.DOCTOR_PATIENT_INFO + data.patient_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPatientInfoSuccess,
            this.getPatientInfoFailure
        );
    }

    getPatientInfoSuccess = response => {
        if (response.status === "success") {

            let states = this.state;
            states["modalVisible"] = true;

            this.setState({
                nextAppoinmentatPientDetails: response,
                states,
                modalVisible: true
            })
        }
    };

    getPatientInfoFailure = error => {
        // console.log("opthamology complaints GET error response");
        console.log(error);
    }


    cancelRequest(item, status) {

        var service_url = "";
        if (this.state.userType == Constants.ROLE_FO) {
            service_url = Constants.REMOVE_APPOINMENT_REQUEST_FO;
        } else {
            service_url = Constants.REMOVE_APPOINMENT_REQUEST;
        }

        // var service_url = Constants.REMOVE_APPOINMENT_REQUEST;

        let data = {
            id: item.id,
            status: 'Cancel'
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.postSocialSuccess,
            this.postSocialFailure
        );
    }

    postSocialSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                showAppointmentRequestModalFlag: false
            })
        }
    }

    openCameraModal(route) {
        this.setState({
            enableCameraFor: route,
            enableCameraModalFlag: true
        })
    }

    closeCameraModal() {
        this.setState({ enableCameraModalFlag: false })
    }


    newPatientDetailsCreatedForLab(labPatientDetails) {
        this.setState({
            newPatientDetailsCreatedForLab: labPatientDetails
        })
    }

    updateProfilePhoto(dataURI, facialPhotos = [], flag, labBill = false) {
        if (dataURI || facialPhotos.length > 0) {
            this.setState({
                enableCameraModalFlag: facialPhotos.length === 5 ? false : flag,
                newProfilePhoto: dataURI,
                newFacialPhotos: facialPhotos
            })
        }
        if (this.state.userType == "LAB") {
            var prepareData = {
                patientDetails: this.state.newPatientDetailsCreatedForLab,
                isSearchedPatient: true
            }
            if (!labBill) {
                this.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData)
            }

        }
    }

    clearPatientPhotos() {
        this.setState({
            newProfilePhoto: null,
            newFacialPhotos: []
        })
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    recognizePatientPhoto = async (camPhoto) => {
        const data = new FormData();

        if (camPhoto) {

            var service_url = "";
            if (this.state.userType == Constants.ROLE_FO) {

                service_url = Constants.FACE_RECOG;

            } else {
                service_url = Constants.FACE_RECOG;
                data.append('clinic_id', this.state.selectedClinicOrDoctor.id ? this.state.selectedClinicOrDoctor.id : null)
            }

            data.append('photo', this.dataURItoBlob(camPhoto));

            OpthamologyService.getInstance().documentUploadPostAPI(
                service_url,
                data,
                this.recognizePatientPhotoSuccess,
                this.recognizePatientPhotoFailure,
                'multipart/form-data'
            );

        }
    }

    recognizePatientPhotoSuccess = async (responseStr) => {
        let responseParsed = JSON.parse(JSON.stringify(responseStr));
        let response = responseParsed.data;
        // if (response.data) {
        if (response.status === 'success') {
            if(response.data){
                let pId = response.data[0].patient_id?response.data[0].patient_id: null;
                let cId = response.data[0].clinic_id?response.data[0].clinic_id: null;
                this.setModalVisible(response.data[0], "");
                this.setState({ enableCameraModalFlag: false })
                // this.setState({
                //     modalVisible: true,
                // })
            }else{
                this.showResposeValue("error", response.message + " Please Register.")
                this.setState({ enableCameraModalFlag: false })
            }
            //this.props.changeScreen("ne", {}, "", {}, "", {}, "", {}, "", prepareData)

            // let pId = null;
            // let aId = null;
            // let dId = null;

            // if (response.data) {
            //     pId = response.data.patient_id ? response.data.patient_id : null;
            //     aId = response.data.appointment_id ? response.data.appointment_id : null;
            //     dId = response.data.doctor_id ? response.data.doctor_id : null;
            // }

            // this.setState({
            //     enableCameraModalFlag: false,
            //     detectedPatientId: pId,
            //     faceDetectedAppointmentId: aId,
            //     detectedDoctorId: dId
            // }, () => {
            //     // alert(aId)
            //     if (!pId) {
            //         if (this.state.userType == "LAB") {
            //             var prepareData = {
            //                 patientDetails: response.data,
            //                 isSearchedPatient: true
            //             }
            //             this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData)

            //         } else {
            //             // this.changeScreen('demographics') 
            //             this.setState({
            //                 nofaceFound: true
            //             })
            //         }

            //     } else if (!aId) {

            //         let obj = {};
            //         obj = { request_id: null, patient_id: pId };
            //         this.setModalVisible(obj, false);
            //     } else {
            //         // update appointment status

            //     }
            // })
        } else if (response.status === 'fail') {
            this.setState({
                enableCameraModalFlag: false,
                detectedPatientId: null,
                faceDetectedAppointmentId: null,
                detectedDoctorId: null
            }, () => {
                if (this.state.userType == "LAB") {
                    var prepareData = {
                        patientDetails: response.data,
                        isSearchedPatient: true
                    }
                    this.props.changeScreen("laboratoryCreateBill", {}, "", {}, "", {}, "", {}, "", prepareData)

                } else {
                    // this.changeScreen('demographics')
                    this.setState({
                        nofaceFound: true
                    })
                }
            })

        }
        // }
    };


    recognizePatientPhotoFailure = async (error) => {
        // console.log("recognize Patient Photo error response");
        this.showResposeValue("error", error.data.message)
        this.setState({ enableCameraModalFlag: false })
        console.log(error);
    };

    // get CLinic Details start

    getClinic = () => {
        var serviceUrl = ""
        // Constants.HOME_GET_DOCTOR_CLINICS;

        if (Constants.ROLE_OPTOMETRY == this.state.userType) {
            serviceUrl = Constants.HOME_OPTOMETRY_CLINIC_DOCTORS;
        } else if (Constants.ROLE_FO == this.state.userType) {
            serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS;
        } else if (Constants.ROLE_OPTOMETRY == this.state.userType) {
            serviceUrl = Constants.HOME_GET_OPTOMETRY_DOCTORS;
        } else {
            serviceUrl = Constants.HOME_GET_DOCTOR_CLINICS;
        }

        if (this.state.userType !== "LAB" && serviceUrl) {
            OpthamologyService.getInstance().getComplaints(
                serviceUrl,
                this,
                this.getClinicSuccess,
                this.getClinicFailure
            );
        }
    };


    getClinicSuccess = async (response) => {
        // if (response.detail) {
        //     //   this._logoutFunction();
        // } else {

        if (response.data) {

            var { userType } = this.state;

            var getListOfClinicOrDoctor = [];
            var fields = this.state;

            if (Constants.ROLE_FO == userType) {
                response.data && response.data.length > 0 && response.data.map((item) => {
                    getListOfClinicOrDoctor.push({ id: item.doctor_id, value: item.first_name + " " + item.initial ,serviceType:item.speciality_name})
                });
            } else if(Constants.ROLE_OPTOMETRY == userType) {
                response.data && response.data.length > 0 && response.data.map((item) => {
                    getListOfClinicOrDoctor.push({ id: item.doctor_id, value: item.first_name + " " + item.initial ,serviceType:item.speciality_name})
                });
            } else {
                response.data && response.data.length > 0 && response.data.map((item) => {
                    getListOfClinicOrDoctor.push({ id: item.clinic_id, value: item.clinic_name })
                });
            }



            fields["arrayOfClinicData"] = getListOfClinicOrDoctor;

            if (response.data.length > 0) {

                var selectedValue = await AsyncStorage.getItem("Selected_Clinic_Doctor");
                if (selectedValue) {
                    fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
                    fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
                } else {
                    await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(getListOfClinicOrDoctor[0]));
                    fields["selectedClinicOrDoctor"] = getListOfClinicOrDoctor[0];
                    fields["selectedClinicOrDoctor"] = getListOfClinicOrDoctor[0];
                }
            }

            this.setState({
                fields
            }, () => {
                this.unLockDoctor();
                // this.createCounterInQMS()
            });

        } else if (response.detail && response.detail == "Token is expired") {

            this._logoutFunction()
        }

        //   this.getAppointments(moment().format("YYYY-MM-DD"));
        //   this.getAppointmentStat();
        // }
    };


    getClinicFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };




    unLockDoctor = () => {
        if (this.state.userType !== Constants.ROLE_FO && this.state.userType !== Constants.ROLE_OPTOMETRY) {

            var service_url =
                Constants.GET_UNLOCK_DOCTOR_NOTES +
                "?clinic_id=" + this.state.selectedClinicOrDoctor.id;

            OpthamologyService.getInstance().getComplaints(
                service_url,
                this,
                this.unLockDoctorSuccess,
                this.unLockDoctorFailure
            );
        }
    }

    unLockDoctorSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                getUnLockDoctor: response.data
            }, () => {
                // this.unLockDoctorNotesModalFlag(true,this.state.getUnLockDoctor) 
            })
        }
    }


    unLockDoctorNotes(flag) {
        if (flag) {

            this.unLockDoctorNotesModalFlag(flag, this.state.getUnLockDoctor)

            this.unLockDoctor()
        }
    }

    apppointmentRequests(flag) {
        if (flag) {
            this.appointmentRequest()
        }
    }
    creditBillLimit = (flag) => {
      this.setState({
          isCreditLimit: flag
      }, () => {
        this.getIPCreditLimit()
      })
    }
    viewReportDRnotesRequests(value = {}) {
        // let states = this.state;
        const visitReportStatus = []
        var status = "";
        if( this.state.userType == 'OPT'){
        if(value.isComplaints){
            visitReportStatus.push("Complaints")
        }
        if(value.isOcularHistory){
            visitReportStatus.push("OcularHistory")
        }
        if(value.isSystemicIllness){
            visitReportStatus.push("SystemicIllness")
        }
        if(value.isVitals){
            visitReportStatus.push("Vitals")
        }
        if(value.isVisionAssesment){
            visitReportStatus.push("VisionAssesment")
        }
        if(value.isAutoRefract){
            visitReportStatus.push("AutoRefract")
        }
        if(value.isPGP){
            visitReportStatus.push("PGP")
        }
        if(value.isSubjectiveRefraction){
            visitReportStatus.push("SubjectiveRefraction")
        }if(value.isASR){
            visitReportStatus.push("ASR")
        }if(value.isIOP){
            visitReportStatus.push("IOP")
        }if(value.isAnteriorSeg){
            visitReportStatus.push("AnteriorSeg")
        }if(value.isPosteriorSeg){
            visitReportStatus.push("PosteriorSeg")
        }if(value.isFundus){
            visitReportStatus.push("Fundus")
        }if(value.isDiagnosis){
            visitReportStatus.push("Diagnosis")
        }if(value.isMedication){
            visitReportStatus.push("Medication")
        }if(value.isGlassPrescription){
            visitReportStatus.push("GlassPrescription")
        }if(value.isReviewPlan){
            visitReportStatus.push("ReviewPlan")
        }if(value.isTreatmentPlan){
            visitReportStatus.push("TreatmentPlan")
        }if(value.isConselling){
            visitReportStatus.push("Conselling")
        }if(value.isInvestigation){
            visitReportStatus.push("Investigation")
        }}
        else if(this.state.userType == 'PSY'){
            if(value.isSymptoms){
                visitReportStatus.push("Symptoms")
            }
            if(value.isAllergy){
                visitReportStatus.push("Allergy")
            }
            if(value.isVitals){
                visitReportStatus.push("Vitals")
            }
            if(value.isHOPI){
                visitReportStatus.push("HOPI")
            }
            if(value.isExamination){
                visitReportStatus.push("Examination")
            }
            if(value.isMentalStatusExamination){
                visitReportStatus.push("MentalStatusExamination")
            }
            if(value.isDiagnosis){
                visitReportStatus.push("Diagnosis")
            }
            if(value.isPrescription){
                visitReportStatus.push("Prescription")
            }
            if(value.isPreviousPrescription){
                visitReportStatus.push("PreviousPrescription")
            }
            if(value.isTest){
                visitReportStatus.push("Test")
            }
            if(value.isPlan){
                visitReportStatus.push("Plan")
            }
            if(value.isGeneralPatientAdvice){
                visitReportStatus.push("GeneralPatientAdvice")
            }
        }
        else {
                if(value.isSymptoms){
                    visitReportStatus.push("Symptoms")
                }
                if(value.isAllergy){
                    visitReportStatus.push("Allergy")
                }
                if(value.isVitals){
                    visitReportStatus.push("Vitals")
                }
                if(value.isExamination){
                    visitReportStatus.push("Examination")
                }
                if(value.isDiagnosis){
                    visitReportStatus.push("Diagnosis")
                }
                if(value.isPrescription){
                    visitReportStatus.push("Prescription")
                }
                if(value.isPreviousPrescription){
                    visitReportStatus.push("PreviousPrescription")
                }
                if(value.isTest){
                    visitReportStatus.push("Test")
                }
                if(value.isPlan){
                    visitReportStatus.push("Plan")
                }
                if(value.isGeneralPatientAdvice){
                    visitReportStatus.push("GeneralPatientAdvice")
                }
                if(value.isConselling){
                    visitReportStatus.push("Conselling")
                }
        }
        status = visitReportStatus.join(",") 

        var serviceUrl = this.state.userType == 'OPT' ?
            Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id + "&doctor_notes=" + status :
            this.state.selectedScreen =="dbCommonHistory" ?
            Constants.GP_HISTORY_REPORT + "?patient_id="+ this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id + "&export=pdf" :
            value.all? 
            Constants.GP_NOTES_VISIT_REPORT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id + "&doctor_notes=all" :
            Constants.GP_NOTES_VISIT_REPORT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id + "&doctor_notes=" + status


        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    }

    getPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // console.log(file)
            window.open(fileURL);
        }
    };

    getPrintFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };

    changePageOrNavigateTOAnotherPage(screenName, homePageRefreshFlag = false, checkIpNumber = "", clearSelectedIppatientDetails = false) {

        if (clearSelectedIppatientDetails) {
            this.setState({
                selectedIpPatientDetails: {},
                patientInfo: {}
            })
        }




        if (screenName == "home" || screenName == "scheduleScreen" || screenName == "doctorProfile") {
            this.setState({
                isCurrentPageIsIP: false,
                selectedIpPatientDetails: {},

                checkIpNumber: checkIpNumber,
                selectedScreen: screenName,
                patientAppointment: {},
                selectedSubScreen: "",
                patientInfo: {},
                showProfileDropDownFlag: false,
                selectedClinicOrDoctor: this.state.selectedClinicOrDoctor,
                isNoAppointment:false,
                modificationBillDetails: {},
            }, () => {
                this.setState({
                    refreshHomeComponentFlag: homePageRefreshFlag
                })
            })
        } else if (screenName == "laboratoryPatientList") {
            this.setState({
                selectedLabPatientDetails: {},
                selectedScreen: screenName,
                checkIpNumber: checkIpNumber,
                showProfileDropDownFlag: false

            })
        } else {
            this.setState({
                checkIpNumber: checkIpNumber,
                showProfileDropDownFlag: false,
                selectedScreen: screenName
            }, () => {
                if(screenName == "iPPatientList" || screenName == "ipPatientCheckoutList"){
                    this.setState({
                        isIpPatientCheckouted:false,
                        ipModifyBillDetail: {}
                    })
                }
                // this.changePickerDropDownValue(false,this.state.selectedClinicOrDoctor,"")
            })
        }
    }



    //   
    searchPatient(searchText) {
        // this.state.
        var states = this.state;

        if (this.state.selectedScreen == "home") {
            states["searcBoxTextValue"] = searchText
            states["isSeacrhClicked"] = false;
        } else {
            states["patientSearchTextValue"] = searchText
            states["isSeacrhClicked"] = false;
        }
        this.setState({ states })

    }
    searchPatientList (){
        var states = this.state;
        states["isSeacrhClicked"] = true;
        this.setState({ states })
    }
    FilterSearch (){
        var states = this.state;
        states["isFilterSearchClicked"] = !states["isFilterSearchClicked"]
        this.setState({ states })
    }
    FilterSearchClose (){
        var states = this.state;
        states["isFilterSearchClicked"] = false
        this.setState({ states })
    }


    appointmentRequest = () => {

        let service_url = ""


        if (this.state.userType === Constants.ROLE_FO) {
            service_url = Constants.FO_GET_APPOINMENT_REQUEST;
        } else {
            service_url = Constants.GET_APPOINMENT_REQUEST;
        }


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.appointmentRequestSuccess,
            this.appointmentRequestFailure
        );
    }
    appointmentRequestSuccess = (response) => {
        if (response.status == "success") {
            //   this.setState({
            //     appointmentRequest : response.data,
            //     pageLoading : false
            //   },()=>{
            this.appointmentRequestModalFlag(true, response.data)
            //   })
        }
    }


    currentAppointmentsDate(date) {
        this.setState({
            currentAppointmentsDateValue: date
        })
    }



    printAppointmentListToken = (value) => {
        var service = ""
        const appointmentStatus = []
        var status = "";
        if(value.Arrived){
            appointmentStatus.push("Arrived")
        }
        if(value.Booked){
            appointmentStatus.push("Booked")
        }
        if(value.WithNurse){
            appointmentStatus.push("With Nurse")
        }
        if(value.WithDoctor){
            appointmentStatus.push("With Doctor")
        }
        if(value.Completed){
            appointmentStatus.push("Completed")
        }
        if(value.Optometry){
            appointmentStatus.push("Optometry")
        }
        if(value.Scan){
            appointmentStatus.push("Scan/Surgery")
        }
        if(value.Cancelled){
            appointmentStatus.push("Cancelled")
        }else {
            
        }
        status = appointmentStatus.join(",") 
        
        if (Constants.ROLE_FO == this.state.userType) {
            service = Constants.HOME_FO_APPOINTMENT_PDF + "?appointment_date=" + this.state.currentAppointmentsDateValue + "&doctor_id=" + this.state.selectedClinicOrDoctor.id + "&appointment_status=" + status + "&export=pdf";
        } else {
            service = Constants.HOME_APPOINTMENT_PDF + "?appointment_date=" + this.state.currentAppointmentsDateValue + "&clinic_id=" + this.state.selectedClinicOrDoctor.id + "&appointment_status=" + status + "&export=pdf";
        }


        OpthamologyService.getInstance().documentUploadGet(
            service,
            // this,
            this.PrintAppointmentListSuccess,
            this.PrintAppointmentListFailure,
            "pdf"
        );
    };

    PrintAppointmentListSuccess = response => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            //   console.log(file)
            window.open(fileURL);
        }
    };

    PrintAppointmentListFailure = error => {
        //  console.log("Get clinic list error response");
        console.log(error);
    };


    convertedDate(date) {
        var fulldate = ""
        if (date) {
            fulldate = new Date(date);
        } else {
            fulldate = new Date();
        }
        // var fulldate = new Date(date);
        var converted_date = moment(fulldate).format("YYYY-MM-DD");
        return converted_date
    }

    clearSearchTextBoxValue() {
        this.setState({
            // searcBoxTextValue: "",
            patientSearchTextValue: "",
            showPickerDropDownFlag: false,
            showProfileDropDownFlag: false,
            isSeacrhClicked : false
        })
    }


    showRoomDetailsPopup(data, flag) {
        this.setState({ roomDetailsPopupData: data }, () => {
            this.setState({ showRoomDetailsPopupFlag: flag })
        })
    }


    ipBillingTxnPrint() {
        alert("ipBillingTxnPrint")
    }
    dischargeSummaryPrint() {

        // alert("dischargeSummaryPrint" + JSON.stringify(this.state.selectedIpPatientDetails))
    }


    biAndRegistry(selectedScreen = "registry",isPrimaryDoctor=false) {

        // registry
        this.setState({
            selectedScreen: selectedScreen,
            isPrimaryDoctor:isPrimaryDoctor
        })
    }
    referalNotes(selectedScreen = "referalNotes"){
        this.setState({
            selectedScreen : selectedScreen
        })
    }
    CollabReportScreen(selectedScreen = "CollabSummary"){
        this.setState({
            selectedScreen : selectedScreen
        })
    }
    BackToNotes(selectedScreen = "optDoctorNotes"){
        this.setState({
            selectedScreen : selectedScreen
        })
    }

    exportRegistrydata(demographicdata = {}, ICDCode = [], Drugs = [], selectedMedical = [], selectedNonMedical = [], selectedImmuniZation = [], vitalsData = {}) {
        this.setState({
            demographicData: demographicdata,
            ICDCodesdata: ICDCode,
            DrugsData: Drugs,
            medicalData: selectedMedical,
            nonMedicalData: selectedNonMedical,
            immunizationData: selectedImmuniZation,
            vitals: vitalsData

        })



    }
    exportRegistry() {

        var { demographicData, vitals } = this.state;

        var getTreatment = []
        if (demographicData.selectedGender) {
            for (var i = 0; i < demographicData.selectedGender.length; i++) {
                getTreatment.push(demographicData.selectedGender[i].value)
            }
        }

        var fromdate = demographicData.dobFromDate ? moment(demographicData.dobFromDate).format('YYYY-MM-DD') : ""
        var toDate = demographicData.dobToDate ? moment(demographicData.dobToDate).format('YYYY-MM-DD') : ""

        var appointmentfromdate = demographicData.AppointmentFromDate ? moment(demographicData.AppointmentFromDate).format('YYYY-MM-DD') : ""
        var appointmenttoDate = demographicData.AppointmentToDate ? moment(demographicData.AppointmentToDate).format('YYYY-MM-DD') : ""

        var gender = getTreatment && getTreatment.length > 0 ? getTreatment.toString() : []

        var selectedCity = demographicData.selectedCity && demographicData.selectedCity.length > 0 ? demographicData.selectedCity.toString() : []
        var selectedArea = demographicData.selectedArea && demographicData.selectedArea.length > 0 ? demographicData.selectedArea.toString() : []
        var ICDCode = this.state.ICDCodesdata && this.state.ICDCodesdata.length > 0 ? this.state.ICDCodesdata.toString() : []

        var Drugs = this.state.DrugsData && this.state.DrugsData.length > 0 ? this.state.DrugsData.toString() : []

        var Medicalallergy = this.state.medicalData && this.state.medicalData.length > 0 ? this.state.medicalData.toString() : []
        var NonMedicalallergy = this.state.nonMedicalData && this.state.nonMedicalData.length > 0 ? this.state.nonMedicalData.toString() : []
        var Immunization = this.state.immunizationData && this.state.immunizationData.length > 0 ? this.state.immunizationData.toString() : []
        var serviceUrl = Constants.REGISTRY_COMMON_LIST +
            "?dob_start_date=" + fromdate +
            "&dob_end_date=" + toDate +
            "&age_from=" + demographicData.ageFrom +
            "&age_to=" + demographicData.ageTo +
            "&appointment_from=" + appointmentfromdate +
            "&appointment_to=" + appointmenttoDate +
            "&gender=" + gender +
            "&area=" + selectedArea +
            "&city=" + selectedCity +
            "&icd_code=" + ICDCode +
            "&drug_name=" + Drugs +
            "&medication_allergy=" + Medicalallergy +
            "&non_medication_allergy=" + NonMedicalallergy +
            "&vaccination_name=" + Immunization +
            "&height_from=" + vitals.heightFrom +
            "&height_to=" + vitals.heightTo +
            "&weight_from=" + vitals.weightFrom +
            "&weight_to=" + vitals.weightTo +
            "&bp_systolic_from=" + vitals.SystolicFrom +
            "&bp_systolic_to=" + vitals.SystolicTo +
            "&bp_diastolic_from=" + vitals.DiastolicFrom +
            "&bp_diastolic_to=" + vitals.DiastolicTo +
            "&temperature_from=" + vitals.temperatureFrom +
            "&temperature_to=" + vitals.temperatureTo +
            "&pulse_oximetry_from=" + vitals.pulseFrom +
            "&pulse_oximetry_to=" + vitals.pulseTo +
            "&pulse_rate_from=" + vitals.pulseRateFrom +
            "&pulse_rate_to=" + vitals.pulseRateTo +
            "&blood_sugar_from=" + vitals.bloodFrom +
            "&blood_sugar_to=" + vitals.bloodTo +
            "&head_circumference_from=" + vitals.CircumferenceFrom +
            "&head_circumference_to=" + vitals.CircumferenceTo + "&export_type=csv"


        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.exportCSVSuccessCallBack,
            this.exportCSVSuccessCallBack,
            "csv"
        );




    }
    exportCSVSuccessCallBack = response => {
        if (response) {

            var pom = document.createElement('a');
            var csvContent=response.data; //here we load our csv data 
            var blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', 'registry.csv');
            pom.click();


            // const file = new Blob(
            //     [response.data],
            //     { type: 'text/csv' });
            //   //Build a URL from the file
            //   const fileURL = URL.createObjectURL(file);
            //   //Open the URL on new Window
            //   window.open(fileURL);

            // // Create a Blob from the PDF Stream
            // // alert(JSON.stringify(response.data))
            // if(response.data.length>0){
            // var data =response.data.replace(/ /g,'')
            // }
            // const file = new Blob(
            //     [data],
            //     { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            // //Build a URL from the file
            // const fileURL = URL.createObjectURL(file);
            // //Open the URL on new Window
            // window.open(fileURL);

        }
    }
    exportCSVErrorCallBack = error => {

    }

    getIpPatientProfileDetails(details) {

        let admit_id = details.admit_id ? details.admit_id : details.id

        var service_url = Constants.IP_DISCHARGE_SUMMARY_PRINT +
            "?patient_id=" + details.patient_id + "&ip_admission_id=" + admit_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getIpPatientProfileDetailsSuccess,
            this.getIpPatientProfileDetailsFailure
        );
    }
    getIpPatientProfileDetailsSuccess = success => {
        if (success.status == "success") {
            this.setState({
                ipPatientProfileDetails: success.data
            })
        }
    }
    getIpPatientProfileDetailsFailure = error => { }


    // QMS Create Clint (clinic)

    createClientInQMS() {
        // alert("create counter ----------------> ")

        var states = this.state;
        var service_url = Constants.QMS_CREATE_CLIENT;
        var data = {
            "name": states.userName,
            "slug": states.clientID,
            "is_subscribed": true
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            JSON.stringify(data),
            this,
            this.createClientInQMSSuccess,
            this.createClientInQMSFailure,
            "",
            "qms"
        );
    }
    createClientInQMSSuccess = success => {
        // this.createCounterInQMS()
        // console.log("create client ----------------> " + JSON.stringify(success))
    }
    createClientInQMSFailure = error => {
        console.log("````````error```````" + JSON.stringify(error))
    }

    // createCounterInQMS() {

    //     var states = this.state;
    //     console.log("create client ----------------> ");

    //     if(states.selectedClinicOrDoctor.id){
    //     var service_url = Constants.QMS_CREATE_COUNTER+states.clientID+"/";
    //     // var service_url = Constants.QMS_CREATE_COUNTER;
    //     var data={
    //         "name" : states.selectedClinicOrDoctor.value,
    //         "slug" : states.selectedClinicOrDoctor.id,
    //     }
    //     OpthamologyService.getInstance().postComplaints(
    //         service_url,
    //         JSON.stringify(data),
    //         this,
    //         this.createCounterInQMSSuccess,
    //         this.createCounterInQMSFailure,
    //         "",
    //         "qms"
    //     );
    //     }
    // }
    // createCounterInQMSSuccess = success => { 
    //     alert("create counter ----------------> "+JSON.stringify(success))
    // }
    // createCounterInQMSFailure = error => { 
    //     alert("````````error```````"+JSON.stringify(error))
    // }


    changeQMSSelectedToken(selectedShiftCounterDeatails) {
        this.setState({
            selectedQMSTokenID: selectedShiftCounterDeatails.qms_token,
            selectedShiftCounterAppointmentDeatails:selectedShiftCounterDeatails
        })
    }

    // shiftTokenIntoAnotherCounterInQMS(item, selectedQMSTokenID) {
    //     var data = {
    //         "token_id": selectedQMSTokenID,
    //         "new_counter_id": item.slug
    //     }
    //     var service_url = Constants.QMS_SHIFT_COUNTER_POST + this.state.selectedClinicOrDoctor.id + "/";
    //     if (selectedQMSTokenID && item.slug) {
    //         OpthamologyService.getInstance().postComplaints(
    //             service_url,
    //             JSON.stringify(data),
    //             this,
    //             this.shiftTokenIntoAnotherCounterInQMSSuccess,
    //             this.shiftTokenIntoAnotherCounterInQMSFailure,
    //             "",
    //             "qms"
    //         );
    //     } else {
    //         if (selectedQMSTokenID == "") {
    //             this.showResposeValue('erroe', Messages.InvalidQMSToken)
    //         } else {
    //             this.showResposeValue('erroe', Messages.InvalidQMSCounter)
    //         }
    //     }

    // }

    // shiftTokenIntoAnotherCounterInQMSSuccess = success => {

    //     this.setState({
    //         showSelectedShiftcounterListFlag: false,
    //     }, () => {
    //         if (success && success.status == "success") {
    //             this.showResposeValue('success', success.message)
    //         } else {
    //             this.showResposeValue('error', success.message)
    //         }
    //     })
    //     // this.props.showResposeValue()
    //     // alert("*********** success * "+JSON.stringify(success))
    // }
    // shiftTokenIntoAnotherCounterInQMSFailure = error => {
    //     alert("*********** success * " + JSON.stringify(success))
    // }



    // changeTokenStatusInQMS(value, selectedQMSTokenID) {
    //     var data = {
    //         "token_id": selectedQMSTokenID,
    //         "status": "complete"
    //     }
    //     var counterID = (this.state.clientID.toString()) + this.state.selectedClinicOrDoctor.id.toString()

    //     var service_url = Constants.QMS_CHANGE_TOKEN_STATUS + counterID + "/";

    //     OpthamologyService.getInstance().postComplaints(
    //         service_url,
    //         JSON.stringify(data),
    //         this,
    //         this.changeTokenStatusInQMSSuccess,
    //         this.changeTokenStatusInQMSFailure,
    //         "",
    //         "qms",
    //         value
    //     );

    // }

    // changeTokenStatusInQMSSuccess = (success, value) => {
    //     if (success && success.status == "success") {
    //         this.shiftTokenIntoAnotherCounterInQMS(value, this.state.selectedQMSTokenID)
    //     } else {
    //         this.showResposeValue('error', success.message)
    //     }
    // }
    changeTokenStatusInQMSFailure = error => {
        // alert("*********** success * "+JSON.stringify(success))
    }

    getTransmittedChangeList = (searchkey, searchDate) => {
        this.setState({
            searchDate : searchDate

        })
        var name = searchkey ? searchkey : ""
        var serviceUrl = Constants.LAB_TRANSMITTED_LIST + "?search_key=" + name + "&transmit_date=" + moment(searchDate).format('YYYY-MM-DD')
        
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getTransmittedChangeListSuccess,
            this.getTransmittedChangeListFailure,
            searchkey,
        );
    };

    getTransmittedChangeListSuccess = (response, searchkey) => {
        if (response.status === "success") {
            this.setState({
                showLabTransmitedList: response.data
            }, () => {
                this.getAppointmentListsOfLabPatient(searchkey, this.state.searchDate)
            })


        }
    };

    getTransmittedChangeListFailure = error => { }

    validateLabUserAccess() {
        var { labUserAccess } = this.state;
        var isBilling = false;
        var isReport = false;

        var getAccess = []
        if (labUserAccess) {
            labUserAccess.split(',');
        }


        if (getAccess.length > 0) {
            for (let i = 0; i < getAccess.length; i++) {
                if (getAccess[i] == "Billing" || getAccess[i] == "Transactions") {
                    isBilling = true;
                } else if (getAccess[i] == "Reports") {
                    isReport = true;
                }
            }
        }

        var labUserAccessType = "";
        var labNavigationHeaderList = [];
        //    alert(this.state.isLabAdmin)
        if (this.state.isLabAdmin) {
            labUserAccessType = "isAdmin";
            labNavigationHeaderList = [
                { label: "Result", value: "laboratoryResult" },
                { label: "Previous Reports ", value: "laboratoryPreviousResult" },
                { label: "Create Bill", value: "laboratoryCreateBill" },
                { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
            ]
        } else if (isBilling && isReport) {
            labUserAccessType = "isBillingAndReport";
            labNavigationHeaderList = [
                { label: "Result", value: "laboratoryResult" },
                { label: "Previous Reports ", value: "laboratoryPreviousResult" },
                { label: "Create Bill", value: "laboratoryCreateBill" },
                { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
            ]


        } else if (isBilling) {
            labUserAccessType = "isBilling";
            labNavigationHeaderList = [
                { label: "Create Bill", value: "laboratoryCreateBill" },
                { label: "Billing Transaction", value: "laboratoryBillingTransaction" }
            ]
        } else if (isReport) {
            labUserAccessType = "isReport";
            labNavigationHeaderList = [
                { label: "Result", value: "laboratoryResult" },
                { label: "Previous Reports ", value: "laboratoryPreviousResult" },
            ]
        }

        this.setState({ labUserAccessType: labUserAccessType, labNavigationHeaderList: labNavigationHeaderList })

        //    alert(JSON.stringify(labUserAccessType))


    }


    getLabSavedList = (searchkey) => {

        if (searchkey) {
            var serviceUrl = Constants.LAB_SAVED_BILL_LIST + "?search_key=" + searchkey

        } else {
            var serviceUrl = Constants.LAB_SAVED_BILL_LIST
        }
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getLabSavedListListSuccess,
            this.getLabSavedListListFailure
        );
    };

    getLabSavedListListSuccess = response => {
        if (response.status === "success") {
            this.setState({
                showLabSavedBillList: response.data
            })


        }
    };

    getLabSavedListListFailure = error => { }

    labTransmitPrint = (key) => {

        var serviceUrl = Constants.LAB_TRANSMIT_LIST_PDF + "?appointment_id=" + key.appointment_id + "&patient_id=" + key.patient_id + "&transmitted_test=true";

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.labTransmitPrintSuccess,
            this.labTransmitPrintFailure,
            "pdf"
        );
    };

    labTransmitPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            console.log(file)
            window.open(fileURL);
        }
    };

    labTransmitPrintFailure = error => {
        console.log("Get clinic list error response");
        console.log(error);
    };

    printBill = () => {

        var serviceUrl = "";
        if (this.state.userType == Constants.ROLE_FO) {
            serviceUrl = Constants.FO_PRINT_BILL + "?invoice_id=" + this.state.AllModuleBillingTransactionPopupInvoiceNo + "&patient_id=" + this.state.allbillPatientID;
        } else {
            // serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + this.state.invoiceNo + "&patient_id=" + this.state.patientId;
            serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + this.state.AllModuleBillingTransactionPopupInvoiceNo + "&patient_id=" + this.state.allbillPatientID+"&clinic_id="+this.state.selectedClinicOrDoctor.id;

        }

        // var serviceUrl = Constants.PRINT_BILL + "?invoice_id=" + this.state.invoiceNo + "&patient_id=" + this.state.patientId;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.printBillSuccess,
            this.printBillFailure,
            "pdf"
        );
    };

    printBillSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    }
    printBillFailure = error => {
        // alert(JSON.stringify(error))
    }


    printCancel(reason) {
        // var serviceUrl="";
        // if(this.state.userType == Constants.ROLE_FO){
        var serviceUrl = Constants.CANCLE_BILL_FO + this.state.AllModuleBillingTransactionPopupInvoiceNo + "/cancel/";
        // }else{
        //     serviceUrl = Constants.CANCLE_BILL_DR + this.state.invoiceNo + "/cancel/";
        // }

        var data = {
            cancel_reason: reason
        }
        OpthamologyService.getInstance().postComplaints(
            serviceUrl,
            data,
            this,
            this.printCancelSuccess,
            this.printCancelFailure,
        );
    };

    printCancelSuccess = (success) => {
        //alert(JSON.stringify(success))
        // this.props.showResposeValue()
        if (success.status == "success") {

            this.showResposeValue('success', success.message)
            this.showAlllBillingScreenModulePopup(false, {}, {}, "", "", true)

            // trigger refresh page 
            this.setState({ refreshAllBills: true, cancelBillMessage: "" })

        } else {
            this.showResposeValue('error', success.message)
            this.showAlllBillingScreenModulePopup(false, {}, {}, "", "", true)
        }

    }
    ipBillCancel(reason) {
        
        var serviceUrl = Constants.IP_CANCEL_BILL + this.state.invoiceID + "/cancel/";
        var data = {
            cancellation_reason: reason
        }
        OpthamologyService.getInstance().postComplaints(
            serviceUrl,
            data,
            this,
            this.ipPrintCancelSuccess,
            this.printCancelFailure,
        );
    };

    ipPrintCancelSuccess = (success) => {
        if (success.status == "success") {

            this.showResposeValue('success', success.message)
            this.showBillingScreenPopup(false, {}, "", true)

            // trigger refresh page 
            this.setState({ refreshAllBills: true })
            
        } else {
            this.showResposeValue('error', success.message)
            this.showBillingScreenPopup(false, {}, "", true)

        }

    }
    labBillCancel(reason) {
        
        var serviceUrl = Constants.LAB_CANCEL_BILL + this.state.billingLabinvoiceID + "/cancel/";
        var data = {
            cancel_reason: reason
        }
        OpthamologyService.getInstance().postComplaints(
            serviceUrl,
            data,
            this,
            this.labCancelSuccess,
            this.labCancelFailure,
        );
    };

    labCancelSuccess = (success) => {
        if (success.status == "success") {

            this.showResposeValue('success', success.message)
            this.showLabBillingTransactionPopup(false, {}, "", true)

            // trigger refresh page 
            this.setState({ refreshAllBills: true })
            
        } else {
            this.showResposeValue('error', success.message)
        }

    }

    setNofaceFound() {

        this.setState({
            nofaceFound: false
        })
    }


    getLabPatientInfo(item) {
        var service_url = Constants.GET_PATIENT_DETAILS + "?patient_id=" + item.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getLabPatientInfoSuccess,
            this.getLabPatientInfoFailure,
            item
        );
    };
    getLabPatientInfoSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                patientInfo: response.data
            })
            //   this.changeStateValues(response.data);
        }
    }
    getLabPatientInfoFailure = error => {
        // console.log(" get Patient Info GET error response");
        console.log(error); Continue
    };

    createAppointmentForAnotherDoctor(){

        var {selectedShiftCounterAppointmentDeatails,selectedCounter,selectedQMSTokenID}= this.state;


        // alert(JSON.stringify(this.state.selectedQMSTokenID))
        // alert(JSON.stringify(this.state.selectedCounter))
        // alert(JSON.stringify(this.state.selectedShiftCounterAppointmentDeatails))
        // alert(JSON.stringify(this.state.currentAppointmentsDateValue))

        var time = moment(new Date()).format("HH:mm:ss");
        // alert(time)

        var data = {};
        var service_url = "";

        // var doctorIDORCLINICID ="";

        // if(this.state.isFoAdmin == "true"){ 
        //     let clientIDLen= this.state.clientID.length;
        //     doctorIDORCLINICID = selectedCounter.slug.substring(clientIDLen )

        // //   counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
        // }else{
        //     // let clientIDLen= this.state.selectedClinicOrDoctor.length;
        //     // alert(doctorIDORCLINICID)
        //     doctorIDORCLINICID = this.state.selectedClinicOrDoctor.id
        //     // doctorID = ""
        // //   counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
        // }





        var doctorID ="";
        var length= this.state.clientID.length;
        doctorID = selectedCounter.slug.substring(length )


        var clinicID ="";
        var length= this.state.clientID.length;
        clinicID = selectedCounter.slug.substring(0 ,  length)


        if (this.state.userType === Constants.ROLE_FO) {

            service_url = Constants.FO_CREATE_APPOINTMENT;
            data = {
                "patient_id": parseInt(selectedShiftCounterAppointmentDeatails.patient_id),

                "doctor_id": parseInt(doctorID),
                "purpose_of_visit": selectedShiftCounterAppointmentDeatails.purpose_of_visit,
                "appointment_date": this.state.currentAppointmentsDateValue.toString(),
                "appointment_start_time": time.toString(),
                "create_without_schedule":true,
                "token_number":null,
                "qms_token_digit": selectedShiftCounterAppointmentDeatails.qms_token_digit,
                "qms_token": selectedShiftCounterAppointmentDeatails.qms_token
            };

        } else {
            service_url = Constants.DOCTOR_CREATE_APPOINTMENT;
            data = {

                "patient_id": parseInt(selectedShiftCounterAppointmentDeatails.patient_id),
                "clinic_id": parseInt(clinicID),

                "doctor_id": parseInt(doctorID),

                "purpose_of_visit": selectedShiftCounterAppointmentDeatails.purpose_of_visit,
                "appointment_date": this.state.currentAppointmentsDateValue.toString(),
                "appointment_start_time": time.toString(),
                "create_without_schedule":true,
                "qms_token_digit": selectedShiftCounterAppointmentDeatails.qms_token_digit,
                "qms_token": selectedShiftCounterAppointmentDeatails.qms_token

            };
        }

        if (this.state.request_id) {
            data["request_id"] = this.state.request_id
        }


        var changeRoomNumberSelectedToken=
        {
            "token_id" : selectedShiftCounterAppointmentDeatails.qms_token,
            "new_counter_id" : selectedCounter.slug
        }




        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.createAppointmentForAnotherDoctorSuccess,
            this.createAppointmentForAnotherDoctorSuccessFailure,
            "application/json",
            "",
            changeRoomNumberSelectedToken

        );
    }

    createAppointmentForAnotherDoctorSuccess = (success,changeRoomNumberSelectedToken)=>{
        //   alert("changeRoomNumberSelectedToken -- >"+JSON.stringify(changeRoomNumberSelectedToken))
        console.log("changeRoomNumberSelectedToken -- >"+JSON.stringify(changeRoomNumberSelectedToken))

        if (success.status == "success"){

            this.setState({
                showSelectedShiftcounterListFlag: false,
            },()=>{
                this.showResposeValue("success", "Appointment shifted to another Doctor")
            })
            // alert(JSON.stringify(success))
            if(success.data &&success.data.appointment_id){
                // alert(JSON.stringify(success.data.appointment_id))
                var appointment_id =success.data.appointment_id;

                if (appointment_id) {
                    // alert(JSON.stringify(success.data.appointment_id))

                    var params = {};

                    params["appointment_id"] = appointment_id,
                        params["appointment_status"] = "Arrived"

                    var service =""

                    if( Constants.ROLE_FO == this.state.userType){
                        service =Constants.HOME_APPOINTMENT_CHANGE_STATUSFO
                    }else{
                        service =Constants.HOME_APPOINTMENT_CHANGE_STATUSDR
                    }

                    Service.getInstance().changeAppointmentStatus(

                        service,
                        params,
                        this,
                        this.changeAppointmentStatusSuccess,
                        this.changeAppointmentStatusFailure,
                        this.state.userType,
                        changeRoomNumberSelectedToken
                    );
                } else {
                    console.log("Please enter valid appointment id");
                }
                //   };
            }

            // data":{"appointment_id

        }else{
            this.setState({
                showSelectedShiftcounterListFlag: false,
            },()=>{
                this.showResposeValue("error", success.message)
            })

        }
    }
    createAppointmentForAnotherDoctorSuccessFailure = error=>{
        this.showResposeValue("error", error.message)

    }


    changeAppointmentStatusSuccess = (response,changeRoomNumberSelectedToken) => {
        //   alert("response "+JSON.stringify(response))

        this.updateRoomNumberForTokenInQMS(changeRoomNumberSelectedToken)
    };

    changeAppointmentStatusFailure = error => {
        // alert("error "+JSON.stringify(error))

    };



    updateRoomNumberForTokenInQMS(data) {
        // alert("create counter ----------------> ")


        var counterID = "";
        if(this.state.isFoAdmin == "true"){
            counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
        }else{
            counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null
        }

        // var counter_id="";

        // if(this.state.userType == Constants.ROLE_FO){

        // }else{

        // }

        var service_url = Constants.QMS_SHIFT_COUNTER_POST + counterID + "/"
        OpthamologyService.getInstance().postComplaints(
            service_url,
            JSON.stringify(data),
            this,
            this.updateRoomNumberForTokenInQMSSuccess,
            this.updateRoomNumberForTokenInQMSFailure,
            "",
            "qms"
        );
    }


    updateRoomNumberForTokenInQMSSuccess=success=>{
        this.changeHomeAppointmentStatus()
        // alert("after room number change --- success ---"+JSON.stringify(success))
    }
    updateRoomNumberForTokenInQMSFailure=error=>{}




    changeHomeAppointmentStatus = () => {


        if (this.state.selectedShiftCounterAppointmentDeatails && this.state.selectedShiftCounterAppointmentDeatails.appointment_id) {
            var params = {};

            params["appointment_id"] = this.state.selectedShiftCounterAppointmentDeatails.appointment_id,
                params["appointment_status"] = "Completed"


            if(Constants.ROLE_OPTOMETRY == this.state.userType){
                params["doctor_id"] = this.state.selectedClinicOrDoctor.id
            }
            var service =""

            if( Constants.ROLE_FO == this.state.userType || Constants.ROLE_OPTOMETRY == this.state.userType ){
                service =Constants.HOME_APPOINTMENT_CHANGE_STATUSFO
            }else{
                service =Constants.HOME_APPOINTMENT_CHANGE_STATUSDR
            }

            Service.getInstance().changeAppointmentStatus(

                service,
                params,
                this,
                this.changeAppointmentStatusCompleteSuccess,
                this.changeAppointmentStatusCompleteFailure,
                this.state.userType
            );
        } else {
            //   alert("Please enter valid appointment id");
            console.log("Please enter valid appointment id");
        }
    };

    changeAppointmentStatusCompleteSuccess = response => {

        // alert(JSON.stringify(response))
        console.log(JSON.stringify(response))
        if (response.status === "success"){

        }
    };

    changeAppointmentStatusCompleteFailure = error => {
        // console.log("change Appointment status error response");
        //    alert(JSON.stringify(response))

        console.log(error);
    };
    
    immunizationPrint() {
        var serviceUrl = Constants.IMMUNIZATION_PRINT+
        "?patient_id=" + this.state.patientAppointment.patient_id+ "&export_type=pdf"

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.immunizationPrintSuccess,
            this.immunizationPrintFailure,
            "pdf"
        );
    }

    immunizationPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    };

    immunizationPrintFailure = error => {
        //console.log("Get clinic list error response");
        console.log(error);
    };

    printIpPatientCheckoutList(){

        var {ipCheckoutPatientExportFiltersData} = this.state
        
    var serviceUrl = Constants.IP_PATIENT_CHECKOUT_LIST+
    "?export=PDF"+
    "&search_key=" + ipCheckoutPatientExportFiltersData.searchKey +
    "&from_date=" + ipCheckoutPatientExportFiltersData.fromDate +
    "&to_date="+ipCheckoutPatientExportFiltersData.toDate;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.printIpPatientCheckoutListSuccess,
            this.printIpPatientCheckoutListFailure,
            "pdf"
        );
    }

    printIpPatientCheckoutListSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
            // console.log(file)
            window.open(fileURL);
        }
    };

    printIpPatientCheckoutListFailure = error => {
        //console.log("Get clinic list error response");
        console.log(error);
    };

    imageLabTxnPrint = () => {
        var serviceUrl = Constants.IMAGE_LAB_INDIVIDUAL_BILL + "?bill_id=" +this.state.billingImageLabInvoiceId + "&export_type=pdf";

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.imageLabTxnPrintSuccess,
            this.imageLabTxnPrintFailure,
            "pdf"
        );
    }

    imageLabTxnPrintSuccess =(response) => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            //  console.log(file)
            window.open(fileURL);
        } 
    }


    pharmacyBillingTransectionPrint = () => {

        var serviceUrl = Constants.PHARMACY_PATIENT_INDIVIDUAL_BILLING_TRANSACTION_LIST + "?id=" +this.state.billingPharmacyinvoiceID + "&export=pdf";

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.pharmacyBillingTransectionPrintSuccess,
            this.pharmacyBillingTransectionPrintFailure,
            "pdf"
        );
    };

    pharmacyBillingTransectionPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            //  console.log(file)
            window.open(fileURL);
        }
    };

    pharmacyBillingTransectionPrintFailure = error => {
        //  console.log("Get clinic list error response");
        console.log(error);
    };

    BillingTransectionPrint


    refreshData = (title) => {
        // console.log("_____________________________> " + title)
        this.setState({
            reloadData: title
        })
    }

}

// define your styles
const styles = StyleSheet.create({
    container: {
        width: "100%",
        flex: 1,
        // flexDirection: 'row',
        backgroundColor: color.white
    },
    bodyContainer: {
        width: "100%",
        height: "100%",
        flex: 1,
        flexDirection: 'row',
        backgroundColor: color.white,
        zIndex: -1
    },
    AlertWrapper:{
        flex: 1, 
        zIndex: 3,
        position: 'absolute', 
        left: '0.65vw', 
        bottom: '0.32vw', 
        right: 0, 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        flexDirection: "row",
        borderRadius: '0.32vw', 
        borderWidth: 1,  
        marginRight: '0.65vw', 
        marginTop: '0.65vw'
    },
    AlertContentView:{
        marginLeft: '0.65vw', 
        flex: 0.95, 
        flexDirection: 'row', 
        alignItems: 'center',
    },
    SuccessTitle:{
        fontWeight: "700", 
        color: "green", 
        fontSize: '0.8vw', 
        marginLeft: '0.97vw'
    },
    SuccessText:{
        fontWeight: "400", 
        color: "green", 
        fontSize: '0.8vw'
    },
    ErrorView1:{
        flex: 0.1, 
        alignSelf: "flex-start", 
        marginBottom: '0.65vw'
    },
    ErrorView2:{
        flex: 0.9, 
        flexDirection: "column", 
        justifyContent: "flex-start"
    },
    ErrorTitle:{
        fontWeight: "700", 
        color: "#FFF", 
        fontSize: '0.8vw'
    },
    ErrorText:{
        fontWeight: "400", 
        color: "#FFF", 
        fontSize: '0.8vw', 
        marginBottom: '0.52vw'
    },
    AlertIcon:{
        flex: 0.05, 
        marginRight: '0.97vw', 
        alignItems: 'center', 
        alignContent: 'center', 
        alignSelf: 'center', 
        justifyContent: "center"
    },
    CancelPopupContainer: {
        height: "46.7vw",
        width: "99.5vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    PopupHeaderConatiner: {
        backgroundColor: "white",
        width: "30%",
        borderRadius: "0.65vw",
        padding: "1.30vw",
        marginBottom: "6.83vw"
    },
    PopupHeaderWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    HeaderTextStyle: {
        color: "#505050",
        fontSize: "1.23vw",
        marginTop: "1vw",
        marginBottom: "1.5vw",
        fontWeight: '500'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw"
    },
    ButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: ".2vw",
    },
    FilterPopUpView: {
        borderRadius: "0.5vw",
        width: "55%",
        height: "50vh",
        // justifyContent: 'center',
        paddingHorizontal: "0.65vw",
        position: "absolute",
        backgroundColor: "white",
        borderColor: color.themeDark,
        borderWidth: 1,
        // right: 10,
        display: "flex",
        flexDirection: "column",
      },
    AddButtonContainer: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        height: "2vw",
        width: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "3vw"
    },
    ClearButtonContainer: {
        borderColor: color.themeDark,
        borderWidth: 1,
        borderRadius: 4,
        height: "2vw",
        width: "5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    FilterHeadTxt: {
        fontSize: "1vw",
        width: "90%",
        textAlign: "center",
        marginTop: "0.2vw",
        fontWeight: "500",
    },
    FiterTop: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "2vw",
    },
    FilterRows: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginVertical: "1.5vw",
        height: "1.5vw",
    },
    SearchInput: {
        height: "2vw",
        borderWidth: "0.065vw",
        borderColor: color.themeDark,
        borderRadius: "0.26vw",
        width: "15%",
        marginRight: "0.65vw",
        width: "12vw",
        marginLeft: "0.65vw",
        paddingHorizontal: "0.52vw",
        color: color.black,
        fontSize: "0.91vw",
        zIndex: -2,
        marginTop: "1vw"
    },
    BtnTouch: {
        marginTop: "15vw",
        paddingTop: "0.2vw",
        paddingLeft: "0.5vw",
        paddingRight: "0.5vw",
        paddingBottom: "0.2vw",
        borderRadius: ".25vw",
        width: "3vw",
        marginRight: "1vw",
        height: "2vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
});

//make this component available to the app
// export default RootComponent;


// const mapStateToProps = (state) => {
//     return{
//       dateFilterFlag: state.updateDateFilter.dateFilterFlag
//     }

//   };

const mapDispatchToProps = (dispatch) => {
    return{
        updateDateFilterFlag: (data) => dispatch(updateDateFilterFlag(data)),
        updateMultiAxialDropDownFlag:(data) => dispatch(updateMultiAxialDropDownFlag(data))
    }
};
export default connect(null, mapDispatchToProps)(RootComponent);
