export const color = {
    
    black: "#1a1917",
    themeBlue: "#262941",
    themePink: "#F25364",
    white: "#ffffff",
    red: "#F1584A",
    brightRed: "#EE4B2B",
    whiteSmoke: "#F5F5F5",
    themeOpthamology: "#262941",
    lightGray: "#ccccb3",
    lightBlue:"#29B6F6",
    darkViolet:"#9400D3",
    unselectedSectionTitle: "#D9D9D9",
    drNotesUnSelectedTitle: "#1a1917",
    notesTableTitle: "#848484",
    notesTableData: "#2F2F2F",
    sectionItemLine: "#E0E0E0",

    appointmentTableHead: "#EEEEEE",
    appointmentTableHeadText: "#4F5A69",


    darkBlack:"#292929",
    textBoxBorderColor: "#CDD1D5",
    lightCyan: "#E0FFFF",
    lightGreenBG: "#F4FDFF",
    // // new screen Section Dark Background
    // sectionHeadingDarkColor:"#E2E2E2",
    // sectionHeadingLiteColor:"#F0F0F0",
    // sectionHeadingTextColor:"#B6BBC0",

    // red theme
    
    // themeDark: "#F25364",
    // themeBackgroud: "#262941",
    // themeShade: "#262941",
    // themeShadeBackground: "#262941",
    // rightPanelLabel: "#ffffff",

    // side menu Unselected Color
    themeUnselectIcon: "#B3D9FE",
    rightPanelInputBorder: "#B3D9FE",

    // blue theme
    //themeDark------ 2F92F5
    themeDark: "#04b7b1",
    themeBackgroud: "#04b7b1",
    // themeShade: "#F3F8FD",
    // themeShade: "#edf4f3", // DR Right side view back ground
    themeShade: "#F8FCFF", // DR Right side view back ground
    themeShadeBackground: "#F3F8FD",        // DR notes  right side button background

    rightPanelLabel: "#04b7b1",

    //search text
    textcolor:"#363636",
    subtextcolor:"#898989",
    placeholder:"#898989",
    boxColor:"#898989",
    themeBackgroudMessage:"B3D9FE",
    searchBarBackground:"#E8ECF1",
    rightPanelLabel: "#04b7b1",

    disableComponentColor:'#EAEAEA',
    disableLabelColor:'#A9A9A9',

    themelite:"#d4f7f5",

    // new screen Section Dark Background
        sectionHeadingDarkColor:"#E2E2E2",
        sectionHeadingLiteColor:"#F0F0F0",
        sectionHeadingTextColor:"#B6BBC0",

    noButtonColor:"#F28D99",
    applicationBackgroundColor:"#EFEFEF",
    sectionBackgroundColor:"#FFFFFF",
    newPencilColor:"#000249",
    newTrashColor:"#DD1717",
    grayShade:"#888888",
    lime: "#00FF00",
    aliceBlue: "#f0f8ff",

    cardBlue:"#0482BE",
    cardPurple:"#9D358C",
    cardOrange:"#E54900",
    cardGreen:"#00B257",
    cardOrangeShade: "#CD7F32",

    cardLightGreen: "#f6fef6",
    cardLightOrange: "#fff8eb",
    cardLightGrey: "#f9f9f9",
    cardLightYellow: "#FFFFE0",
    cardLightPink: "#ffb6c1",
    cardLightRed: "#FFCCCB"
};