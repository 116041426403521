import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import { CommonDatePicker, } from './registryCommonComponents'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService"
import { Constants } from "../../../../utils/Constants";
import { RegistryContext } from "./RegistryContext";



const listOfAreaa = [
    { label: "Singanallure", value: "Singanallure" },
    { label: "Hopes", value: "Hopes" },
    { label: "LakshmiMills", value: "LakshmiMills" },
]


export class RegistryAllergeys extends Component {
    constructor(props) {
        super(props);
        this.state = {



            listOfMedical: [],
            listOfNonMedical: [],
            selectedMedical: [],
            drugName: "",
            medicalallergy: "",
            selectedNonMedical: [],
            nonmedicalallergy: ""

        };
    }


    getAllergy = (query, type) => {

        var searchType = ""
        if (type == "medicalallergy") {
            searchType = "medical allergy"
        } else if (type == "nonmedicalallergy") {
            searchType = "non medical allergy"

        }
        var serviceUrl = Constants.REGISTRY_COMMON_SEARCH + "?search_key=" + query + "&search_type=" + searchType;




        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getAllergySuccess,
            this.getAllergyFailure,
            type
        );

    }
    getAllergySuccess = (response, searchType) => {
        // listOfMedical: [],
        // listOfNonMedical: [],
        if (response.status == "success") {
            var states = this.state


            if (searchType == "medicalallergy") {
                states['listOfMedical'] = response.data
            } else if (searchType == "nonmedicalallergy") {
                states["listOfNonMedical"] = response.data
            }

            this.setState({
                states
            })
        }


    };

    getAllergyFailure = error => {
        // console.log("search patient error response");
        console.log(error);
    }





    onPressselectedMedical(item, contaxt) {
        // var billList=[];
        // selectedMedical
        var { selectedMedical } = this.state;
        selectedMedical.push(item);
        selectedMedical = this.removeDuplicate(selectedMedical)
        this.setState({ selectedMedical, listOfMedical: [] ,medicalallergy:""}, () => {
            contaxt("selectedMedical", this.state.selectedMedical)

        })
    }


    onPressselectedNonMedical(item, contaxt) {

        var { selectedNonMedical } = this.state
        selectedNonMedical.push(item);
        selectedNonMedical = this.removeDuplicate(selectedNonMedical)
        this.setState({ selectedNonMedical, listOfNonMedical: [],nonmedicalallergy:'' }, () => {
            contaxt("selectedNonMedical", this.state.selectedNonMedical)

        })
    }

    renderMedical(allergyData,contaxt) {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Medical Allergy"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    {
                       allergyData.selectedMedical.map((item, index) => {
                            return (
                                <View>

                                    <View style={[{
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingTop: 5,
                                        marginLeft: 15,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        marginTop: 10,
                                        paddingBottom: 5,
                                        flexDirection: 'row',
                                        // justifyContent:'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        borderColor: "#888888"
                                    },
                                    ]}

                                    >

                                        <Text style={{ color: "black" }}>
                                            {item}
                                        </Text>
                                        <View style={{ height: 15, width: 1, backgroundColor: color.black, marginHorizontal: 10 }} />
                                        <TouchableOpacity onPress={() => {
                                            let { selectedMedical } = this.state;
                                            selectedMedical.splice(index, 1)
                                            this.setState({
                                                selectedMedical,
                                                medicalallergy: "",

                                            }, () => {
                                                contaxt("selectedMedical", this.state.selectedMedical)
                                            })

                                        }}>
                                            <Text style={{ color: "black" }}>{"X"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ flex: 1 }}>
                        {this.renderCommonTextBox("", this.state.medicalallergy, "medicalallergy")}
                        {this.state.medicalallergy && this.state.listOfMedical.length > 0 ?
                            <View style={{
                            backgroundColor: color.white,
                            maxHeight: "",
                            borderRadius: '0.65vw',
                            width: '90%',
                            justifyContent: 'center',
                            alignContent: 'center',
                            padding: '0.65vw',
                            marginLeft: '1.62vw',
                            marginTop: '5.2vw',
                            position: 'absolute',
                            }}>
                                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                    <FlatList
                                        data={this.state.listOfMedical}
                                        renderItem={({ item }) => (
                                            <View style={{
                                            flexDirection: "row",
                                            bottom: '0.65vw',
                                            marginBottom: '0.65vw',
                                            justifyContent: "space-evenly",
                                            width: "100%",
                                            alignItems: "center",
                                            marginTop: '0.65vw',
                                            zIndex: 10,
                                            }}
                                            >
                                                <View style={{ width: '100%' }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.onPressselectedMedical(item, contaxt);
                                                        }
                                                        }>
                                                        <Text  style={{fontSize:'0.9vw'}}>{item}</Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>
                                        )}
                                        enableEmptySections={true}


                                    //keyExtractor={(item, index) => index.toString()}
                                    />
                                </ScrollView>
                            </View>
                            : null}
                    </View>

                </View>

            </View>
        )
    }



    renderNonMedical(allergyData,contaxt) {
        return (
            <View style={{ marginTop: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Non - Medical Allergy"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    {
                        allergyData.selectedNonMedical && allergyData.selectedNonMedical.map((item, index) => {
                            return (
                                <View>

                                    <View style={[{
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingTop: 5,
                                        marginLeft: 15,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        marginTop: 10,
                                        paddingBottom: 5,
                                        flexDirection: 'row',
                                        // justifyContent:'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        borderColor: "#888888"
                                    },
                                    ]}

                                    >

                                        <Text style={{ color: "black" }}>
                                            {item}
                                        </Text>
                                        <View style={{ height: 15, width: 1, backgroundColor: color.black, marginHorizontal: 10 }} />
                                        <TouchableOpacity onPress={() => {
                                            let { selectedNonMedical } = this.state;
                                            selectedNonMedical.splice(index, 1)
                                            this.setState({
                                                selectedNonMedical,
                                                nonmedicalallergy: "",

                                            }, () => {
                                                contaxt("selectedNonMedical", this.state.selectedNonMedical)

                                            })

                                        }}>
                                            <Text style={{ color: "black" }}>{"X"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ flex: 1 }}>
                        {this.renderCommonTextBox("", this.state.nonmedicalallergy, "nonmedicalallergy")}
                        {this.state.nonmedicalallergy && this.state.listOfNonMedical.length > 0 ?

                            <View style={{
                                backgroundColor: color.white,
                                maxHeight: 200,
                                borderRadius: 10,
                                width: '90%',
                                justifyContent: 'center',
                                alignContent: 'center',
                                padding: 10,
                                marginLeft: 10,
                                marginTop: 40,
                                position: 'absolute',
                            }}>
                                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                    <FlatList
                                        data={this.state.listOfNonMedical}
                                        renderItem={({ item }) => (
                                            <View style={{
                                                flexDirection: "row",
                                                bottom: 10,
                                                marginBottom: 10,
                                                justifyContent: "space-evenly",
                                                width: "100%",
                                                alignItems: "center",
                                                marginTop: 10,
                                                zIndex: 10,
                                            }}
                                            >
                                                <View style={{ width: '100%' }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.onPressselectedNonMedical(item, contaxt);
                                                        }
                                                        }>
                                                        <Text>{item}</Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>
                                        )}
                                        enableEmptySections={true}


                                    //keyExtractor={(item, index) => index.toString()}
                                    />
                                </ScrollView>
                            </View>
                            : null}
                    </View>

                </View>

            </View>
        )
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onPressButton(contectCallBack = "", checkIsCallBack = false, key, value) {
        var states = this.state;

        if (checkIsCallBack) {
            contectCallBack(key, value);
        }

        if (key == "clear") {
            this.setState({
                selectedMedical: [],
                medicalallergy: "",
                selectedNonMedical: [],
                nonmedicalallergy: ""

            })
        }
        this.setState({ states })

    }


    renderCommonTextBox(placeholder, value, textBoxkey) {
        return (
            <View style={[{ height:'2.81vw', marginRight: 15 }]}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>


                <TextInput
                    style={{
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: '2vw',
                        fontSize:'0.9vw',
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                    value={value}
                    onChangeText={(text) => {
                        var states = this.state;
                        states[textBoxkey] = text;
                        this.setState({ states }, () => {
                            this.getAllergy(text, textBoxkey)
                        })
                    }}
                />




            </View>
        )
    }




    renderFooterAction(contectCallBack) {
        var actions = []

        actions = [
            { label: "Apply", value: "Apply" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15 }}>
                {
                    actions.map((item, index) => {
                        return (
                            <View style={{ marginRight: 15 }}>
                                <CommonButton
                                    // disable={this.state.isLocked}
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={item.label}
                                    buttonType={item.value == "Apply" ? "theme" : "outlineTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={item.value} />
                            </View>
                        )
                    })
                }

            </View>
        )
    }


    render() {
        return (
            <RegistryContext.Consumer>
                {(getContext) => {
                    var allergyData = getContext.allergiesdetails;

                    return (
                        <>
                            <View>




                                <View style={{ zIndex: 10 }}>
                                    {this.renderMedical(allergyData,getContext.allergiesData.bind(this))}
                                </View>

                                <View style={{ zIndex: 1 }}>
                                    {this.renderNonMedical(allergyData,getContext.allergiesData.bind(this))}
                                </View>

                                <View style={{ zIndex: -1 }}>
                                    {this.renderFooterAction(getContext.allergiesData.bind(this))}
                                </View>

                            </View>
                        </>
                    )

                }

                }
            </RegistryContext.Consumer>
        );
    }
}
