import React, { Component } from 'react'
import { Text, View, ScrollView, StyleSheet, TextInput, TouchableOpacity } from 'react-native'
import { OvulationChartSectionHeading, CommonPrintButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";
import { color } from '../../../../styles/Color';
import BaseComponentStyle from "../../BaseComponentStyle";
import Icon from "react-native-vector-icons/FontAwesome";

//const pageName = "isScanObservation"
const isPageName = "isScanObservation"


export class ScanObservation extends BaseComponentStyle {

    constructor(props) {
        super(props)

        this.state = {
            isLocked: this.props.patientAppointment.is_locked,
            selectedPageName: this.props.selectedPageName,
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            lastMenstrualDate: "",
            therapy: "",
            procedure: "",
            scanData: {},
            last_menstrual_period: "",
            nilValue: "-",
            islastmenstrual: true,
            hideRemoveIcon: false,
            scan_id: "",
        }
    }


    componentDidMount() {
        this.getScan()
    }

    getScan() {
        var service_url = Constants.SCAN_OBSERVATIONS_SAVE + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getScanSuccess,
            this.getOvulationFailure
        );
    }

    getScanSuccess = response => {
        if (response.status === "success") {
            //    alert("getOvulationSuccess ++ " + JSON.stringify(response.data))
            var field = this.state;
            field["scanData"] = response.data;
            this.setState({
                field

            });
        }

    }
    visitReport = () => {

        let states = this.state;
        if (this.state.scan_id) {
            var serviceUrl =
                Constants.GET_SCAN_OBSERVATIONS + "?patient_id=" + this.state.patientAppointment.patient_id + "&investigation_scan_id=" + this.state.scan_id;
        } else {
            var serviceUrl =
                Constants.GET_SCAN_OBSERVATIONS + "?patient_id=" + this.state.patientAppointment.patient_id;
        }
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            // this,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    };

    getPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            // alert("111success")
          //  console.log(file)
            window.open(fileURL);
        }
    };

    getPrintFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        this.state.hideRemoveIcon = props.hideRemoveIcon
        if (props && Object.keys(props.getUpdatedChartValue).length > 0) {
            this.setState({
                scanData: props.getUpdatedChartValue,
                scan_id: props.getUpdatedChartValue.id
            });
            this.props.getUpdatedChartValueChange({},"ScanObservation");
        }

        if (props.refreshRighSideComponentName == isPageName) {
            this.getScan();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name);
    }

    ScanReportList() {
        return (
            <View>
                {this.ScanReportListHeader()}
                {this.ScanReportListData()}
            </View>
        )
    }

    deleteItem = (item) => {
        //  alert(JSON.stringify(item))
        //this.props.editItem(pageName, item);
        var service_url = Constants.DELETE_SCAN;
        let data = {
            investigation_scan_id: item.id
        };

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getDeleteSuccess,
            this.getDeleteFailure
        );
    };

    getDeleteSuccess = (response) => {
        if (response.status === "success") {
            this.getScan();
            this.props.showResposeValue("success", response.message);

        } else {
            this.props.showResposeValue("error", response.message);
        }
    };

    getDeleteFailure = (error) => {
        this.props.showResposeValue("error", response.message);

    }

    renderRichTextBox(label, values) {
        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500' }}>{label}</Text>
                <TextInput
                    disabled={true}
                    value={values}
                    placeholder={""}
                    multiline={true}
                    textAlignVertical="top"
                    style={{ marginTop: 5, marginBottom: 20, textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.lightGray, borderWidth: 1, height: 65 }}
                />
            </View>
        )
    }

    // fourPhase(){
    //     return(
    //         this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 

    //     //     this.state.OvulationData.ovulation_induction_header.map((item) => {
    //     //    return(     
    //         <View style={{padding: 30, marginTop: 30, borderRadius: 4, borderColor: color.lightGray, borderWidth: 1}}>
    //             <View style={{alignItems: 'flex-end'}}>
    //             <TouchableOpacity disabled={this.state.isLocked} onPress={this.editHistory.bind(this, this.state.OvulationData.ovulation_induction_header)}>
    //               {this.renderIdButton("pencil")}
    //             </TouchableOpacity>
    //             </View>
    //             <View style={{flexDirection: 'row'}}>
    //                 <View style={{width: "50%", marginRight: 10}}>
    //                     {this.renderRichTextBox("Follicular Phase", this.state.OvulationData.ovulation_induction_header.follicular_phase)}
    //                 </View>

    //                 <View style={{width: "50%"}}>
    //                     {this.renderRichTextBox("Ovulatory Phase", this.state.OvulationData.ovulation_induction_header.ovulatory_phase)}
    //                 </View>
    //             </View>

    //             <View style={{flexDirection: 'row'}}>
    //                 <View style={{width: "50%",marginRight: 10}}>
    //                     {this.renderRichTextBox("Luteal Phase",this.state.OvulationData.ovulation_induction_header.luteal_phase)}
    //                 </View>

    //                 <View style={{width: "50%"}}>
    //                     {this.renderRichTextBox("Comments", this.state.OvulationData.ovulation_induction_header.comments)}
    //                 </View>
    //             </View>
    //            </View> 
    //         //     ) 
    //         // })  
    //         : null
    //     )
    // }

    ScanReportListHeader() {
        return (
            <View style={{ flexDirection: 'row', height: 70, borderWidth: 0.5, borderColor: "#888888", alignContent: "center", width: '100%', backgroundColor: "#F0F0F0" }}>
                <View style={[styles.headerStyle, { flex: 0.21 }]}>
                    {this.tabelHeader("Date")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.32 }]}>
                    {this.tabelHeader("Gestational Age")}
                </View>
                <View style={[styles.headerStyle, { flex: 0.32 }]}>
                    {this.tabelHeader("Scan Findings")}
                </View>

                {/* <View style={[styles.headerStyle, { flex: 0.25 }]}>
                    {this.tabelHeader("Remarks")}
                </View> */}
                <View style={[styles.headerStyle, { flex: 0.15 }]}>
                    {this.tabelHeader("Action")}
                </View>


            </View>
        )
    }

    ScanReportListData() {
        var today = new Date();
        var getTodayDate = moment(today).format("DD-MM-YYYY")
        return (
            this.state.scanData && this.state.scanData.scan_observation_values ?
                this.state.scanData.scan_observation_values.map((item) => {
                    var getItemDate = moment(item.scan_date).format("DD-MM-YYYY");
                    var hideDeleteButton = true

                    if (getItemDate == getTodayDate) {

                        hideDeleteButton = false
                    }
                    var prepareInvestigationData = {
                        "id": this.state.scanData.id,
                        "last_menstrual_period": this.state.scanData.last_menstrual_period,
                        "remarks": this.state.scanData.remarks,
                        "selectedData": item,
                        "islastmenstrual": this.state.islastmenstrual
                    }

                    return (

                        <View style={{
                            flexDirection: 'row',// borderWidth: 0.5, 
                            borderLeftColor: "#888888", borderRightColor: "#888888", borderBottomColor: "#888888",
                            borderLeftWidth: 0.5, borderRightWidth: 0.5, borderBottomWidth: 0.5,
                            alignContent: "center"
                        }}>
                            <View style={[styles.headerStyle, { flex: 0.21 }]}>
                                {this.tabelData(moment(item.scan_date).format("DD-MM-YYYY"))}
                            </View>
                            <View style={[styles.headerStyle, { flex: 0.32 }]}>
                                {this.tabelData(item.gestational_age + " Weeks")}
                            </View>
                            <View style={[styles.headerStyle, { flex: 0.32 }]}>
                                {this.tabelData(item.scan_finding)}
                            </View>
                            {/* <View style={[styles.headerStyle, { flex: 0.25 }]}>
                                {this.tabelData(this.state.scanData.remarks)}
                            </View> */}

                            <View style={[styles.headerStyle, { flex: 0.15 }]}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ marginLeft: 10, width: '50%' }}>
                                        <TouchableOpacity disabled={this.state.isLocked} onPress={this.editHistory.bind(this, prepareInvestigationData)}>
                                            {this.renderIdButton("pencil")}
                                        </TouchableOpacity>
                                    </View>
                                    {
                                        this.state.hideRemoveIcon || hideDeleteButton ?
                                            null
                                            :
                                            <View style={{ width: '50%', marginRight: 10 }}>
                                                <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                                                    this.deleteItem(item);
                                                }}>
                                                    {this.renderIdButton("trash")}
                                                </TouchableOpacity>
                                            </View>
                                    }
                                </View>
                            </View>


                        </View>)
                }) : null
        )
    }

    tabelHeader(title) {
        return (
            <View>
                <Text style={{
                    fontSize: 13,
                    color: "black",
                    alignContent: "center", justifyContent: 'center',
                    alignItems: 'center', alignSelf: 'center',
                    textAlignVertical: 'center', textAlign: 'center'
                }}>{title}</Text>
            </View>
        )
    }

    tabelData(title) {
        return (
            <View style={{ padding: 3 }}>
                <Text
                    numberOfLines={10}
                    style={{
                        fontSize: 13,
                        color: "#000",
                        alignContent: "center", justifyContent: 'center',
                        alignItems: 'center', alignSelf: 'center',
                        textAlignVertical: 'center', textAlign: 'center'
                    }}>{title}</Text>
            </View>
        )
    }

    // threeHeading() {
    //     var converted_date = moment( this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? this.state.OvulationData.ovulation_induction_header.last_menstrual_period : null).format("DD-MM-YYYY");

    //     return (
    //         <View>
    //             {
    //                 this.renderThreeHeadingFields(
    //                     "Gravida",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                     "Para",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                     "Live Birth",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                     "Abortions",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                     "Expected Date of Delivery",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                     "Last Menstrual Period",
    //                     this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ? 
    //                     converted_date : "",
    //                                       )
    //             }
    //         </View>
    //     );
    // }



    renderThreeHeadingFields(lastMenstrualPeriodTitle, lastMenstrualPeriodValue, GravidaTitle, therapyValue, procedureTitle, procedureValue, ParaTitle, LiveBirthTitele, AbortionsTitle, ExpectedDateofDeliveryTitle) {
        return (
            <View style={{ flexDirection: 'row', marginTop: 25, marginBottom: 20 }}>
                <View style={{ flex: 0.32 }}>
                    {this.renderContent(GravidaTitle, lastMenstrualPeriodValue)}
                </View>
                <View style={{ flex: 0.33 }}>
                    {this.renderContent(ParaTitle, therapyValue)}
                </View>
                <View style={{ flex: 0.35 }}>
                    {this.renderContent(LiveBirthTitele, procedureValue)}
                </View>
                <View style={{ flex: 0.32 }}>
                    {this.renderContent(AbortionsTitle, lastMenstrualPeriodValue)}
                </View>
                <View style={{ flex: 0.32 }}>
                    {this.renderContent(ExpectedDateofDeliveryTitle, lastMenstrualPeriodValue)}
                </View>
                <View style={{ flex: 0.32 }}>
                    {this.renderContent(lastMenstrualPeriodTitle, lastMenstrualPeriodValue)}
                </View>
            </View>
        )
    }


    renderContent(heading, value) {
        return (
            <View>
                <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}</Text>
            </View>
        );
    }

    editHistory(item) {
        // alert(JSON.stringify(item))
        //console.log("Ovulation Chart +++ " + JSON.stringify(item))
        this.props.editHistory(item, isPageName)
    }

    newovalutionChart () {
        this.setState({
            scanData:{}
        })
        this.props.renderNewItemClear("ScanClear",true)


    }

    render() {
        // console.log("For check ** " + this.state.OvulationData && this.state.OvulationData.ovulation_induction_header ?  this.state.OvulationData.ovulation_induction_header.obgynovulationinductionvalues : "No value")

        return (

            <View style={styles.container}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: "30%" }}>
                        <OvulationChartSectionHeading
                            selectedRightSideView={this.selectedRightSideView.bind(this)}
                            //   editHistory={this.editHistory.bind(this)}
                            selectedPageName={this.state.selectedPageName}
                            pageName={isPageName} heading={"Scan Observations"}
                        />
                    </View>
                    <View style={{ width: "65%", alignSelf: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', marginLeft: 35 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {/* <View style={{ marginRight: 40 }}>
                                <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>Last Menstrual Period</Text>
                                <Text style={{ textAlign: 'center' }}>{this.state.scanData.last_menstrual_period ? moment(this.state.scanData.last_menstrual_period).format("DD-MM-YYYY") : null}</Text>
                            </View> */}
                            <View>
                                <TouchableOpacity 
                                onPress={() => {
                                    this.newovalutionChart();
                                }}>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            alignSelf: "center",
                                            flex: 0.2,
                                            backgroundColor: color.themeDark,
                                            borderRadius: 5,
                                            minHeight: 30,
                                            paddingHorizontal: 10,
                                            paddingLeft: 30,
                                            paddingRight: 30
                                        }}
                                    >
                                        <Icon name="plus" size={16} color={color.white} />
                                        <Text
                                            style={{
                                                fontSize: 11,
                                                color: color.white,
                                                fontWeight: "600",
                                                marginLeft: 5
                                            }}
                                        >
                                            {"New Scan Observations"}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{ width: "4%", alignItems: "flex-end", marginLeft: 25 }}><CommonPrintButton onPress={() => { this.visitReport() }} /></View>
                        </View>
                    </View>

                </View>
                <View style={{ marginTop: 10 }}>
                    <View style={{ backgroundColor: "#F0F0F0", paddingVertical: 10, flexDirection: "row" }}>
                        <Text style={{ fontSize: 14, color: "black", marginTop: 5, width: "100%", paddingHorizontal: 10 }}>Last Menstrual Period</Text>
                    </View>
                    {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>Last Menstrual Period</Text> */}
                    <Text style={{ alignContent: "flex-start", paddingHorizontal: 20, marginTop: 10 }}>{this.state.scanData.last_menstrual_period ? moment(this.state.scanData.last_menstrual_period).format("DD-MM-YYYY") : null}</Text>
                </View>
                {/* {this.threeHeading()} */}
                <View style={{ flex: 1, marginTop: 20 }}>
                    {this.ScanReportList()}
                    <View style={{ marginTop: 20, width: "100%" }}>
                        <Text style={{ fontSize: 15, backgroundColor: "#F0F0F0", paddingVertical: 10, paddingHorizontal: 10 }}>{"Remarks"}</Text>
                        <TextInput
                            disabled={true}
                            value={this.state.scanData.remarks}
                            placeholder={""}
                            multiline={true}
                            textAlignVertical="top"
                            style={{
                                marginTop: 5,
                                marginBottom: 20,
                                textAlignVertical: "top",
                                paddingLeft: 20,
                                backgroundColor: color.white,
                                // borderRadius: 8,
                                // borderColor: color.lightGray,
                                // borderWidth: 1,
                                border: "none",
                                height: 65,
                            }}
                        />
                    </View>
                </View>
                {/* {this.fourPhase()} */}


            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        flex: 1,
    },
    headerStyle: {
        borderRightColor: "#888888", borderRightWidth: 0.5, justifyContent: 'center'
    }
});

