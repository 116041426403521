//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, Image,TouchableOpacity } from 'react-native';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
const screenHeight = Dimensions.get("window").height;
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";

import 'antd/dist/antd.css';
import { TimePicker } from 'antd';
import moment from 'moment';
import { CommonSectionHeader, CommonButton } from './../BaseComponent'

// create a component
class ScheduleScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0,

            selectedClinic: {},
            selectedEHRSession: {},
            selectedEHRWeeks: [],
            EHREndTime: "",
            EHRStartTime: "",

            getClinicDetails: [],

            session1List: {},
            session2List: {},
            session3List: {},
            setSchedulesDays: {},

            editScheduleID: "",

            selectedTelemedicineSession: {},
            TelemedicineStartTime: "",
            TelemedicineEndTime: "",
            selectedTelemedicineWeeks: [],

            selectedSession: "EHR",

            showNoRecordsImage: false,

            selectedEHRScheduleID: "",
            selectedTelemeditionScheduleID: ""


        }
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };




    getArrayOfSelectedDaysFromGet(getDays) {
        var data = []

        for (let i = 0; i < getDays.length; i++) {
            if (getDays[i] == "1")
                data.push({ label: "Sunday", value: "1" })
            else if (getDays[i] == "2")
                data.push({ label: "Monday", value: "2" })
            else if (getDays[i] == "3")
                data.push({ label: "Tuesday", value: "3" })
            else if (getDays[i] == "4")
                data.push({ label: "Wednesday", value: "4" })
            else if (getDays[i] == "5")
                data.push({ label: "Thursday", value: "5" })
            else if (getDays[i] == "6")
                data.push({ label: "Friday", value: "6" })
            else if (getDays[i] == "7")
                data.push({ label: "Saturday", value: "7" })
        }

        return data
    }


    setEditValues(session, type) {
        var states = this.state;

        // states["editScheduleID"] = session[0].id;

        if (session.length > 0) {
            // if(session[0].appointment_type=="OP"){
            //     this.setState({
            //         selectedSession:"EHR"
            //     })
            // }else{
            //     this.setState({
            //         selectedSession:"Telemedicine"
            //     })
            // }

            for (let i = 0; i < session.length; i++) {
                if (session[i].appointment_type == "OP") {
                    states["EHRStartTime"] = session[i].start_time;
                    states["EHREndTime"] = session[i].end_time;
                    states["selectedEHRScheduleID"] = session[i].id;
                    if (type == "S1")
                        states["selectedEHRSession"] = { label: "Session 1", value: 1 };
                    else if (type == "S2")
                        states["selectedEHRSession"] = { label: "Session 2", value: 2 };
                    else if (type == "S3")
                        states["selectedEHRSession"] = { label: "Session 3", value: 3 };

                    if (this.state.setSchedulesDays[session[i].id].length > 0) {
                        states["selectedEHRWeeks"] = this.getArrayOfSelectedDaysFromGet(this.state.setSchedulesDays[session[i].id]);
                    }

                    for (let j = 0; j < states.getClinicDetails.length; j++) {
                        if (states.getClinicDetails[j].label == session[i].clinic_name) {
                            states["selectedClinic"] = states.getClinicDetails[j]
                        }
                    }

                } else {
                    states["TelemedicineStartTime"] = session[i].start_time;
                    states["TelemedicineEndTime"] = session[i].end_time;
                    states["selectedTelemeditionScheduleID"] = session[i].id;

                    if (type == "S1")
                        states["selectedTelemedicineSession"] = { label: "Session 1", value: 1 };
                    else if (type == "S2")
                        states["selectedTelemedicineSession"] = { label: "Session 2", value: 2 };
                    else if (type == "S3")
                        states["selectedTelemedicineSession"] = { label: "Session 3", value: 3 };
                    // setSchedulesDays

                    if (this.state.setSchedulesDays[session[i].id].length > 0) {
                        states["selectedTelemedicineWeeks"] = this.getArrayOfSelectedDaysFromGet(this.state.setSchedulesDays[session[i].id]);
                    }
                }

            }
        }


        if (session.length == 1) {
            if (session[0].appointment_type == "OP") {

                states["selectedTelemedicineSession"] = {};
                states["TelemedicineStartTime"] = "";
                states["TelemedicineEndTime"] = "";
                states["selectedTelemedicineWeeks"] = [];
                states["selectedTelemeditionScheduleID"] = "";
                states["selectedSession"] = "EHR";
            } else {
                states["selectedEHRScheduleID"] = "";
                states["selectedClinic"] = {};
                states["selectedEHRSession"] = {};
                states["selectedEHRWeeks"] = [];
                states["EHREndTime"] = "";
                states["EHRStartTime"] = "";
                states["selectedSession"] = "Telemedicine";

            }
        }



        this.setState({ states })

    }

    sesssionCardView(session, type) {
        return (
            <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                alignItems: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white, 
                height:120,
                width: "75%",
                borderRadius: 4,
            }}>
                <TouchableOpacity onPress={() => {
                    this.setEditValues(session, type);

                }}>

                    {
                        session.length > 0 && session.map((item, index) => {
                            return (
                                <View key={index} style={{paddingTop:item.clinic_name=="Telemedicine"?0:10 ,paddingBottom:10,flex:'wrap'}}>
                                    <Text style={{ fontSize: 15, fontWeight: '500', textAlign: 'center', }} numberOfLines={2}>{item.clinic_name}</Text>
                                    <Text style={{ fontSize: 10, textAlign: 'center', marginTop: 4, }}>{item.timings}</Text>
                                </View>
                            )

                        })

                    }


                    {/* <View>
                        <Text style={{ fontSize: 15, fontWeight: '500', textAlign: 'center' }}>{"Rajesh Clinic"}</Text>
                        <Text style={{ fontSize: 10, textAlign: 'center' }}>{"9:00 am - 12: 00 am "}</Text>
                    </View>
                    <View style={{ marginTop: 5 }}>
                        <Text style={{ fontSize: 15, fontWeight: '500', textAlign: 'center' }}>{"Rajesh"}</Text>
                        <Text style={{ fontSize: 10, textAlign: 'center' }}>{"9:00 am - 12: 00 am "}</Text>
                    </View> */}
                </TouchableOpacity>
            </View>
        )
    }

    renderSessionHeaderText(label) {
        return (
            <Text style={styles.sessionHeaderText}>{label}</Text>
        )
    }


    componentDidMount() {
        this.getSchedule();
        this.getClinic();
    }

    renderDaysRow(headerLabel, S1, S2, S3) {
        return (
            <View style={[styles.daysStyle, { height: 120,marginTop:15 }]}>

                {
                    headerLabel ?
                        <View style={{ flex: 0.1, backgroundColor: color.themeDark, justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderSessionHeaderText(headerLabel)}
                        </View>
                        : null
                }

                <View style={{ flex: 1, backgroundColor: color.white, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 0.33 }}>
                        {
                            S1.length > 0 ?
                                this.sesssionCardView(S1, "S1")
                                : null
                        }
                    </View>

                    <View style={{ flex: 0.33 }}>
                        {
                            S2.length > 0 ?
                                this.sesssionCardView(S2, "S2")
                                : null
                        }
                    </View>

                    <View style={{ flex: 0.33 }}>
                        {
                            S3.length > 0 ?
                                this.sesssionCardView(S3, "S3")
                                : null
                        }
                    </View>


                </View>
            </View>
        )
    }

    sideHeading(day) {
        return (
            <View style={{ height: 120, backgroundColor: color.themeDark, justifyContent: 'center', alignItems: 'center' }}>
                {this.renderSessionHeaderText(day)}
            </View>
        )
    }

    renderFixedComponent() {
        return (
            <View>
                <View style={styles.headerSection}>
                    <View style={{ flex: 0.1 }}></View>
                    <View style={styles.sessionTextAlignment}>{this.renderSessionHeaderText("Session 1")}</View>
                    <View style={styles.sessionTextAlignment}>{this.renderSessionHeaderText("Session 2")}</View>
                    <View style={styles.sessionTextAlignment}>{this.renderSessionHeaderText("Session 3")}</View>
                </View>

                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.1, }}>
                        {this.sideHeading("Sun")}
                        {this.sideHeading("Mon")}
                        {this.sideHeading("Tue")}
                        {this.sideHeading("Wed")}
                        {this.sideHeading("Thu")}
                        {this.sideHeading("Fri")}
                        {this.sideHeading("Sat")}

                    </View>
                    {
                        this.state.showNoRecordsImage ?
                            <View style={{ flex: 0.9, justifyContent: 'center', alignItems: 'center' }}>
                                <Image style={{ height: 400, width: 400 }} source={require('../../../../assets/images/No_Records_Found.png')} />
                                <Text style={{ fontSize: 40, color: "#E8F8F7", marginTop: 20 }}>{"No Records Found "}</Text>
                            </View>
                            :
                            <View style={{ flex: 0.9, }}>
                                {this.renderDaysRow("",
                                    this.state.session1List.sun ? this.state.session1List.sun : [],
                                    this.state.session2List.sun ? this.state.session2List.sun : [],
                                    this.state.session3List.sun ? this.state.session3List.sun : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.mon ? this.state.session1List.mon : [],
                                    this.state.session2List.mon ? this.state.session2List.mon : [],
                                    this.state.session3List.mon ? this.state.session3List.mon : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.tue ? this.state.session1List.tue : [],
                                    this.state.session2List.tue ? this.state.session2List.tue : [],
                                    this.state.session3List.tue ? this.state.session3List.tue : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.wed ? this.state.session1List.wed : [],
                                    this.state.session2List.wed ? this.state.session2List.wed : [],
                                    this.state.session3List.wed ? this.state.session3List.wed : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.thu ? this.state.session1List.thu : [],
                                    this.state.session2List.thu ? this.state.session2List.thu : [],
                                    this.state.session3List.thu ? this.state.session3List.thu : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.fri ? this.state.session1List.fri : [],
                                    this.state.session2List.fri ? this.state.session2List.fri : [],
                                    this.state.session3List.fri ? this.state.session3List.fri : [])}
                                {this.renderDaysRow("",
                                    this.state.session1List.sat ? this.state.session1List.sat : [],
                                    this.state.session2List.sat ? this.state.session2List.sat : [],
                                    this.state.session3List.sat ? this.state.session3List.sat : [])}

                            </View>}
                </View>


            </View>
        )
    }

    addScheduleView() {
        return (
            <CommonButton
                item={{ label: "Add Schedule", value: "" }}
                selectedvalue={{}}
                butttonText={"Add Schedule"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.addSchedule.bind(this, this.state.selectedSession == "EHR" ? "OP" : "Telemedicine")}
                buttonKey={"addSchedule"} />
        )
    }


    remove() {
        return (
            <CommonButton
                item={{ label: "Remove", value: "" }}
                selectedvalue={{}}
                butttonText={"Remove"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={false}
                buttonAction={this.removeSchedule.bind(this)}
                buttonKey={"remove"} />
        )
    }

    renderTimePicker(key, value, placeholder) {
     //   console.log("value -> " + JSON.stringify(value))
        return (
            <TimePicker use12Hours format="h:mm A" onChange={(time, timeString) => {
                var states = this.state;
                states[key] = timeString
                this.setState({ states })
                //  console.log("time, timeString"+timeString);
            }}
                value={value ? moment(value, "hh:mm a") : ""}
                // defaultValue={value ? moment(value) : ""}
                //value={value} 
                placeholder={placeholder}
                style={{ width: 140, borderRadius: 4 }} />
        )
    }

    renderClinic() {
       // console.log(" this.state.getClinicDetails" + JSON.stringify(this.state.getClinicDetails))
        return (
            <View style={{}}>
                <CommonSectionHeader heading={"Clinic"} type={"sub"} />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 20 }}>
                    {
                        this.state.getClinicDetails.map((item, index) => {
                            return (
                                <View style={{ marginRight: 8, marginBottom: 8 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={this.state.selectedClinic}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={"selectedClinic"} />
                                </View>
                            )
                        }
                        )}
                </View>
            </View>
        )
    }

    renderDuration() {
        return (
            <View style={{ marginTop: 20 }}>
                <View style={{}}>
                    <CommonSectionHeader heading={"Duration"} type={"sub"} />

                    <View style={{ flexDirection: 'row', marginTop: 15, marginLeft: 20 }}>
                        <View>
                            {this.renderTimePicker(
                                this.state.selectedSession == "EHR" ? "EHRStartTime" : "TelemedicineStartTime",
                                this.state.selectedSession == "EHR" ? this.state.EHRStartTime : this.state.TelemedicineStartTime,
                                "Start Time"
                            )}
                        </View>
                        <View style={{ marginLeft: 40 }}>
                            {this.renderTimePicker(
                                this.state.selectedSession == "EHR" ? "EHREndTime" : "TelemedicineEndTime",
                                this.state.selectedSession == "EHR" ? this.state.EHREndTime : this.state.TelemedicineEndTime,
                                "End Time"
                            )}

                        </View>
                        {/* <TimePicker use12Hours format="h:mm:ss A" onChange={(time, timeString)=>{
                         console.log("time, timeString"+timeString);
                         
                    }} style={{ width: 140 }} /> */}

                    </View>
                </View>
            </View>
        )
    }

    onPressHeader(type) {
        this.setState({
            selectedSession: type
        })
    }

    renderRightSideView() {
        return (
            <View>
                <View style={{ marginBottom: 30 }}>
                    <CommonSectionHeader selectedSession={this.state.selectedSession} onPressHeader={this.onPressHeader.bind(this)} heading={"EHR"} type={""} multiSelection={true} multiSelectionValue={["EHR", "Telemedicine"]} />
                </View>

                {
                    this.state.selectedSession == "EHR" ?
                        this.renderClinic()
                        : null
                }

                {this.renderSession()}
                {this.renderDuration()}
                {this.renderWeeks()}

                <View style={{ marginTop: 40, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    {this.addScheduleView()}
                    {this.remove()}
                </View>
            </View>
        )
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onPressButton(key, selectedValue) {
        var states = this.state;

        if (key == "selectedEHRWeeks") {
            var { selectedEHRWeeks } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedEHRWeeks.length > 0) {
                for (var i = 0; i < selectedEHRWeeks.length; i++) {
                    if (selectedEHRWeeks[i].value == selectedValue.value) {
                        spliceData = selectedEHRWeeks[i];
                        selectedEHRWeeks.splice(i, 1)
                        var data = this.removeDuplicate(selectedEHRWeeks);
                        this.setState({ selectedEHRWeeks: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedEHRWeeks.push(selectedValue); }
            } else {
                selectedEHRWeeks.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedEHRWeeks);
            this.setState({ selectedEHRWeeks: data })



        } else if (key == "selectedEHRSession" || key == "selectedTelemedicineSession") {
            states[key] = selectedValue;
        } else if (key == "selectedClinic") {
            states[key] = selectedValue;
        } else if (key == "selectedTelemedicineWeeks") {
            var { selectedTelemedicineWeeks } = this.state;

            var getSelectedValue;
            var spliceData;

            if (selectedTelemedicineWeeks.length > 0) {
                for (var i = 0; i < selectedTelemedicineWeeks.length; i++) {
                    if (selectedTelemedicineWeeks[i].value == selectedValue.value) {
                        spliceData = selectedTelemedicineWeeks[i];
                        selectedTelemedicineWeeks.splice(i, 1)
                        var data = this.removeDuplicate(selectedTelemedicineWeeks);
                        this.setState({ selectedTelemedicineWeeks: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedTelemedicineWeeks.push(selectedValue); }
            } else {
                selectedTelemedicineWeeks.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedTelemedicineWeeks);
            this.setState({ selectedTelemedicineWeeks: data })

        }
        this.setState({ states })

    }


    renderWeeks() {
        var data = [
            { label: "Sunday", value: "1" },
            { label: "Monday", value: "2" },
            { label: "Tuesday", value: "3" },
            { label: "Wednesday", value: "4" },
            { label: "Thursday", value: "5" },
            { label: "Friday", value: "6" },
            { label: "Saturday", value: "7" },
        ]
        return (
            <View style={{ marginTop: 20 }}>
                <CommonSectionHeader heading={"Weekly"} type={"sub"} />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 20 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginRight: 8, marginBottom: 8 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={this.state.selectedSession == "EHR" ? this.state.selectedEHRWeeks : this.state.selectedTelemedicineWeeks}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={this.state.selectedSession == "EHR" ? "selectedEHRWeeks" : "selectedTelemedicineWeeks"} />
                                </View>
                            )
                        }
                        )}
                </View>
            </View>
        )
    }


    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours == '12') {
            hours = '00';
        }

        if (modifier == 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${"00"}`;
    }



    removeSchedule() {

        var id = "";

        if (this.state.selectedSession == "EHR") {
            id = this.state.selectedEHRScheduleID
        } else {
            id = this.state.selectedTelemeditionScheduleID
        }


        // states["editScheduleID"] = session[0].id;



        var service_url = Constants.SCHEDULE_POST_API + id + "/delete/";

        OpthamologyService.getInstance().postComplaints(
            service_url,
            {},
            this,
            this.removeScheduleSuccess,
            this.removeScheduleFailure
        );
    }

    removeScheduleSuccess = success => {
      //  console.log(" data ------------> " + JSON.stringify(success));
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.clearAllData()
            this.getSchedule()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    removeScheduleFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    addSchedule(type) {

        var service_url = Constants.SCHEDULE_POST_API;

        // var service_url = Constants.REMOVE_APPOINMENT_REQUEST;
        var states = this.state;

        var days = [];

        if (this.state.selectedSession == "EHR") {

            for (var i = 0; i < states.selectedEHRWeeks.length > 0; i++) {
                days.push(states.selectedEHRWeeks[i].value)
            }
        } else {
            for (var i = 0; i < states.selectedTelemedicineWeeks.length > 0; i++) {
                days.push(states.selectedTelemedicineWeeks[i].value)
            }
        }


        let data = {
            "session_id": this.state.selectedSession == "EHR" ? states.selectedEHRSession.value : states.selectedTelemedicineSession.value,
            "start_time": this.convertTime12to24(
                this.state.selectedSession == "EHR" ?
                    states.EHRStartTime : states.TelemedicineStartTime),
            "end_time": this.convertTime12to24(this.state.selectedSession == "EHR" ?
                states.EHREndTime : states.TelemedicineEndTime),
            "available_days": days,
            "appointment_type": type
        }

        if (this.state.selectedSession == "EHR") {
            data["clinic_id"] = states.selectedClinic.value
        }




        if (this.state.selectedSession == "EHR") {
            if (this.state.selectedEHRScheduleID) {
                data["schedule_id"] = this.state.selectedEHRScheduleID
            }
        } else {
            if (this.state.selectedTelemeditionScheduleID) {
                data["schedule_id"] = this.state.selectedTelemeditionScheduleID
            }
        }




        if (this.state.editScheduleID) {
            data["schedule_id"] = this.state.editScheduleID
        }

        if (
          data.end_time == ":undefined:00" ||
          data.end_time == "" ||
          data.end_time == null ||
          data.start_time == ":undefined:00" ||
          data.start_time == "" ||
          data.start_time == null
        ) {
          this.props.showResposeValue("error", "Please Select the Duration");
        } else {
          OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.addScheduleSuccess,
            this.addScheduleFailure
          );
        }
    }


    clearAllData() {

        var states = this.state;

        states["selectedTelemedicineSession"] = {};
        states["TelemedicineStartTime"] = "";
        states["TelemedicineEndTime"] = "";
        states["selectedTelemedicineWeeks"] = [];
        states["editScheduleID"] = "";

        states["selectedEHRScheduleID"] = "";
        states["selectedTelemeditionScheduleID"] = "";


        states["selectedClinic"] = {};
        states["selectedEHRSession"] = {};
        states["selectedEHRWeeks"] = [];
        states["EHREndTime"] = "";
        states["EHRStartTime"] = "";

        this.setState({ states })


    }

    addScheduleSuccess = success => {
        if (success.status == "success") {
            this.getSchedule()
            this.props.showResposeValue("success", success.message)
            this.clearAllData()
        } else {
            this.props.showResposeValue("error", success.message)
        }
       // console.log(JSON.stringify(success))
    }
    addScheduleFailure = error => {
        this.props.showResposeValue("error", error.message)

        console.log(JSON.stringify(error))

    }

    renderSession() {
        var data = [
            { label: "Session 1", value: 1 },
            { label: "Session 2", value: 2 },
            { label: "Session 3", value: 3 },
        ]
        return (
            <View style={{ marginTop: 20 }}>
                <CommonSectionHeader heading={"Session"} type={"sub"} />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginLeft: 20 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginRight: 8, marginBottom: 8 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={this.state.selectedSession == "EHR" ? this.state.selectedEHRSession : this.state.selectedTelemedicineSession}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={this.state.selectedSession == "EHR" ? "selectedEHRSession" : "selectedTelemedicineSession"} />
                                </View>
                            )
                        }
                        )}
                </View>
            </View>
        )
    }


    getClinic = () => {
        var serviceUrl =
            Constants.HOME_GET_DOCTOR_CLINICS;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getClinicSuccess,
            this.getClinicFailure
        );
    };

    getClinicSuccess = async (response) => {

        if (response.status == "success") {

            var data = [];

            if (response.data.length > 0) {

                for (var i = 0; i < response.data.length; i++) {
                    var prepareData = { label: response.data[i].clinic_name, value: response.data[i].clinic_id }
                    data.push(prepareData)
                }
            }

            this.setState({
                getClinicDetails: data
            })
        }

        //   response.data && response.data.length > 0 && response.data.map((item) => {
        //     tempList.push({ id: item.clinic_id, value: item.clinic_name })
        //   });
    };

    getClinicFailure = error => {
        console.log(error);
    };



    getSchedule = () => {
        var serviceUrl = Constants.SCHEDULE_GET_API;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getScheduleSuccess,
            this.getScheduleFailure
        );
    };

    getScheduleSuccess = (response) => {

       // console.log("++++++++++++++> " + JSON.stringify(response))

        if (response.status == "success") {



            if (response && response.data) {

                if (Object.keys(response.data.sessions.session_1).length > 0 || Object.keys(response.data.sessions.session_2).length > 0 || Object.keys(response.data.sessions.session_3).length > 0) {
                    this.setState({
                        showNoRecordsImage: false
                    })
                } else {
                    this.setState({
                        showNoRecordsImage: true
                    })
                }

                if (response.data.sessions.session_1 && Object.keys(response.data.sessions.session_1).length > 0) {
                    this.setState({
                        session1List: response.data.sessions.session_1
                    })
                } else {
                    this.setState({
                        session1List: {}
                    })
                }

                if (response.data.sessions.session_2 && Object.keys(response.data.sessions.session_2).length > 0) {
                    this.setState({
                        session2List: response.data.sessions.session_2
                    })
                } else {
                    this.setState({
                        session2List: {}
                    })
                }

                if (response.data.sessions.session_3 && Object.keys(response.data.sessions.session_3).length > 0) {
                    this.setState({
                        session3List: response.data.sessions.session_3
                    })
                } else {
                    this.setState({
                        session3List: {}
                    })
                }


                if (response.data.schedules) {
                    this.setState({
                        setSchedulesDays: response.data.schedules
                    })
                }
            }


            // var data = [];
            // if(response.data.length > 0){
            //     for (var i = 0 ;i<response.data.length; i++){
            //         var prepareData= {label:response.data[i].clinic_name,value:response.data[i].clinic_id}
            //         data.push(prepareData)
            //     }
            // }

            // this.setState({ getClinicDetails:data })
        }
    };

    getScheduleFailure = error => {
        //console.log("++++++++++++++> " + JSON.stringify(response))

        console.log(error);
    };


    render() {
        return (
            <View style={styles.container}>
                <View style={[Style.leftView, styles.bodyOfSchudule]}>
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                    >

                        <View style={{ marginBottom: 30,marginTop: 30, alignItems: 'center', height: 35, flexDirection: 'row', backgroundColor: color.sectionHeadingLiteColor }}>
                            {/* <TouchableOpacity onPress={() => {
                                this.props.changeScreen("doctorProfile")
                                // props.markOutofOfficeModalFlag(false)
                            }} style={{ fontSize: 30, alignContent: 'center', marginLeft: 10 }}>
                                <Icon name="long-arrow-left" size={20} color={color.black} />
                            </TouchableOpacity> */}
                            <CommonSectionHeader heading={"Schedule Slots"} type={"sub"} />
                        </View>

                        {this.renderFixedComponent()}
                    </ScrollView>
                </View>
                <View style={[styles.RighSideView, {
                    // shadowColor: "#090F36",
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.6,
                    shadowRadius: 4,
                    elevation: 5,
                    paddingHorizontal: 20
                }]}>
                    {this.renderRightSideView()}
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        height: screenHeight-65,
        // flex: 1, 
        // flexDirection: "row" 
        // paddingTop:40,
        // flex: 1,
        flexDirection: 'row',
        // width:'100%'
    },
    headerSection: {
        flexDirection: 'row',
        backgroundColor: color.themeDark,
        height: 45,
    },
    bodyOfSchudule: {
        // marginTop:40,
        width: "65%",
        paddingHorizontal: 30
    },
    RighSideView: {
        // padding: 10,
        // height: screenHeight,
        backgroundColor: color.themeShade,
        // alignItems: "center",
        // padding: 20, 
        width: "35%"
    },
    sessionHeaderText: {
        color: color.white,
        fontSize: 17,
        fontWeight: '500'
    },
    sessionTextAlignment: {
        flex: 0.3, alignItems: 'center', justifyContent: 'center'
    },


    daysStyle: {
        flexDirection: 'row'
    }
});

//make this component available to the app
export default ScheduleScreen;
