import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Picker,
  Image,
} from "react-native";
import { NavigationTopHeader } from "../../BaseComponent";
import { color } from "../../../../styles/Color";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
import ImagePath from "../../../../utils/ImagePaths";

export class Biothesiometry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ankle_normal_value: 20,
      wrist_normal_value: 20,
      toe_normal_value: 20,
      wrist_right: "",
      wrist_left: "",
      ankle_left: "",
      ankle_right: "",
      toe_right: "",
      toe_left: "",
      impression: "",
      isEditClicked: false,
      unit: "",
      biothesiometry_Data: [],
    };
  }
  componentDidMount() {
    this.getBiothesiometryLog();
  }

  getBiothesiometryLog = () => {
    try {
      var service_url =
        Constants.FOOT_CLINIC_BIOTHEIOMETRY +
        "?appointment_id=" +
        this.props.patientAppointment.appointment_id;
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getBiothesiometrySuccess,
        this.printBiothesiometryFailure
      );
    } catch (e) {
      console.log(e.message);
    }
  };
  getBiothesiometrySuccess = (response) => {
    try {
      if (
        response &&
        response.status == "success" &&
        response.data.length > 0
      ) {
        this.setState({
          ankle_normal_value: response.data[0].ankle_normal_value,
          wrist_normal_value: response.data[0].wrist_normal_value,
          toe_normal_value: response.data[0].toe_normal_value,
          wrist_right: response.data[0].wrist_right,
          wrist_left: response.data[0].wrist_left,
          ankle_left: response.data[0].ankle_left,
          ankle_right: response.data[0].ankle_right,
          toe_right: response.data[0].toe_right,
          toe_left: response.data[0].toe_left,
          impression: response.data[0].impression,
          isEditClicked: false,
          unit: response.data[0].unit,
          biothesiometry_Data: response.data,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  numberValidationWithDot(num) {
    var rgx = /^(\d{0,3})((\.(\d{0,2})?)?)$/;
    return num.match(rgx);
  }
  //Render Text box dynamic View
  renderTextBox = (key) => {
    var states = this.state;
    return (
      <View style={styles.renderTextBoxContainer}>
        <TextInput
          multiline={key == "impression" ? true : false}
          maxLength={key == "impression" ? 250 : 3}
          disabled={this.state.biothesiometry_Data.length > 0 || key == "wrist_normal_value" || key == "ankle_normal_value" || key == "toe_normal_value" || key == "impression" ? true : false}
          value={states[key]}
          style={[
            styles.TextInputStyles,
            {
              height: key == "impression" ? "4vw" : "1.5vw",
              padding: key == "impression" ? "0" : "1vw",
            },
          ]}
          onChangeText={(text) => {
            var isValid = this.numberValidationWithDot(text);
            if (key == "impression") {
              states[key] = text;
              this.setState({
                states,
              });
            } else if (isValid || text === "") {
              if (parseFloat(text) <= 100) {
                states[key] = text;
                this.setState({
                  states,
                });
                this.calculateValuesforIndex();
              } else {
                states[key] = "";
                this.setState({
                  states,
                });
              }
            }
          }}
        />
      </View>
    );
  };
  //Render Picker view
  renderPickerView = (key) => {
    var states = this.state;
    return (
      <Picker
        style={{
          borderColor: color.black,
          borderRadius: 4,
          borderWidth: 1,
          width: "100%",
          height: "2.2vw",
          marginTop: 8,
        }}
        disabled={this.state.biothesiometry_Data.length > 0 ? true : false}
        selectedValue={this.state.unit}
        onValueChange={(itemvalue) => {
          this.setState({
            unit: itemvalue,
          });
        }}
      >
        <Picker.Item label="Select" value="" />
        <Picker.Item label="Mg" value="mg" />
        <Picker.Item label="Volts" value="Volts" />
      </Picker>
    );
  };
  //parse value to float
  convertToFloat = (num) => {
    return parseFloat(num);
  };

  calculateValuesforIndex = () =>{
    try{
      var states = this.state;
      var rightImpression = "";
      var leftImpression = "";
      console.log(this.convertToFloat(states.ankle_right) + this.convertToFloat(states.ankle_left));

      //Normal Value Section
        // Right Normal
      if((this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right)) /2 < 20){
        rightImpression = "Normal";
      }
      // Left Normal
      if((this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left)) /2 < 20){
        leftImpression = "Normal";
      }
      // Both Normal
      else if((this.convertToFloat(states.ankle_right) + this.convertToFloat(states.ankle_left))/2 < 20 && (this.convertToFloat(states.toe_right) + this.convertToFloat(states.toe_left))/ 2 < 20){
        rightImpression = "Normal";
        leftImpression = "Normal";
        // this.setState({
        //   impression : "Normal"
        // })
      }
        // value 20 - 25
        // Right side
        if(
          ((this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 >= 20 && (this.convertToFloat(states.ankle_right) +this.convertToFloat(states.toe_right))/2 <= 25)
          ){
            rightImpression = "Right Early sensory neuropathy"
            // this.setState({
            //   impression : "Right Early sensory neuropathy"
            // })
        }
        // Left Side
        if(
          ((this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 >= 20 && (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 <= 25)
          )
          {
            leftImpression = "Left Early sensory neuropathy";
            // this.setState({
            //   impression : "Left Early sensory neuropathy"
            // })
        }
        // Both
       if(
        (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 >= 20 && (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 <= 25 &&
        (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/ 2 >= 20 && (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/ 2 <= 25
        ){
          rightImpression = "Bilateral Early sensory neuropathy";
          leftImpression = "Bilateral Early sensory neuropathy";
          // this.setState({
          //   impression : "Bilateral Early sensory neuropathy"
          // })
      }
      // 26 to 40
      // Right
      if((this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 > 25 && (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 <= 40
      ){ 
        rightImpression = "Right sensory neuropathy";
        // this.setState({
        //   impression : "Left sensory neuropathy"
        // })
      }
      // Left
      if((this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 > 25 && (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 <= 40) {
        leftImpression = "Left sensory neuropathy";
        // this.setState({
        //   impression : "Left sensory neuropathy"
        // })
      }
      if(
        ((this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 > 25 && (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 <= 40) &&
        ((this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 > 25 && (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 <= 40) 
        ){
          rightImpression = "Bilateral sensory neuropathy";
          leftImpression = "Bilateral sensory neuropathy";
          // this.setState({
          //   impression : "Bilateral Severe sensory neuropathy"
          // })
      }  
      // Above 40 
      if(
        (this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 > 40
        ){
          leftImpression = "Left Severe sensory neuropathy";
          // this.setState({
          //   impression : "Left Severe sensory neuropathy"
          // })
      } if(
        (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 > 40 
        ){
          rightImpression = "Right Severe sensory neuropathy";
          // this.setState({
          //   impression : "Right Severe sensory neuropathy"
          // })
      } if((this.convertToFloat(states.ankle_left) + this.convertToFloat(states.toe_left))/2 > 40 && 
          (this.convertToFloat(states.ankle_right) + this.convertToFloat(states.toe_right))/2 > 40){
            rightImpression = "Bilateral Severe sensory neuropathy";
            leftImpression = "Bilateral Severe sensory neuropathy";
      }
      // else {
      //   this.setState({
      //     impression : "Right Impression : " + rightImpression + "\n" + "Left Impression : " + leftImpression
      //   })
      // }
      if(rightImpression == "Bilateral Early sensory neuropathy"){
        this.setState({
          impression : "Bilateral Early sensory neuropathy"
        })
      }else if(rightImpression == "Bilateral sensory neuropathy"){
        this.setState({
          impression : "Bilateral sensory neuropathy"
        })
      }else if(rightImpression == "Bilateral Severe sensory neuropathy"){
        this.setState({
          impression : "Bilateral Severe sensory neuropathy"
        })
      }else{
      this.setState({
        impression : "Right Impression : " + rightImpression + "\n" + "Left Impression : " + leftImpression
      })
    }
    }catch(e){

    }
  }

  //Reset state
  resetClicked = () => {
    this.setState({
      ankle_normal_value: "",
      wrist_normal_value: "",
      toe_normal_value: "",
      wrist_right: "",
      wrist_left: "",
      ankle_left: "",
      ankle_right: "",
      toe_right: "",
      toe_left: "",
      impression: "",
      isEditClicked: false,
      unit: "",
      biothesiometry_Data: [],
    });
  };
  //Render right side view
  renderBiothesiometryLog = () => {
    return (
      <View style={{ height: "70vh" }}>
        <ScrollView
          style={styles.RightScrollView}
          showsVerticalScrollIndicator={false}
        >
          <Text style={styles.RightHeadTitle}>
            Diabetic Foot Biothesiometry Logs
          </Text>

          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Normal Values</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Wrist<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("wrist_normal_value")}
            </View>
            <View style={styles.unit_txt_box}>
              {/* {this.renderPickerView("hand_right_unit")} */}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Ankle(Medical Malleous)<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("ankle_normal_value")}
            </View>
            <View style={styles.unit_txt_box}>
              {/* <TouchableOpacity style={styles.PrintBtn}>
                <Image source={ImagePath.Edit} style={styles.PrintImg} />
              </TouchableOpacity> */}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Great Toe<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("toe_normal_value")}
            </View>
            <View style={styles.unit_txt_box}>
              {/* {this.renderPickerView("hand_left_unit")} */}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Wrist</Text>
          </View>

          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("wrist_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_pta_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("wrist_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_pta_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Ankle (Medical Malleous)</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("ankle_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("abindex_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("ankle_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("abindex_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}>Great Toe</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("toe_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("toe_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("toe_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("toe_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Comments</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>Comments</Text>
            <View style={styles.value_comment_box}>
              {this.renderTextBox("impression")}
            </View>
          </View>
        </ScrollView>
        {this.state.biothesiometry_Data.length == 0 ? (
          <View>
            <View style={{ height: "1.5vw", marginTop: "0.3vw" }}>
              <Text
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  fontSize: ".9vw",
                  fontWeight: "500",
                  justifyContent: "center",
                }}
              >
                {this.state.ErrorText}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0.5vw",
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    this.state.isEditClicked
                      ? this.EditBiothesiometry()
                      : this.AddNewBiothesiometry();
                  }}
                  style={styles.BtnTouch}
                >
                  <Text style={styles.ButtonText}>
                    {this.state.isEditClicked ? "Update" : "Add"}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.resetClicked();
                  }}
                  style={styles.BtnTouch}
                >
                  <Text style={styles.ButtonText}>{"Clear"}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  };

  //Data validation
  validateData = () => {
    try {
      var states = this.state;
      if (
        states.wrist_normal_value != "" &&
        states.ankle_normal_value != "" &&
        states.toe_normal_value != "" &&
        states.wrist_right != "" &&
        states.wrist_left != "" &&
        states.ankle_left != "" &&
        states.ankle_right != "" &&
        states.toe_right != "" &&
        states.toe_left != ""
      ) {
        return true;
      } else {
        this.props.showResposeValue("error", "Please enter all value to save");
        return false;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Add new AddNewBiothesiometry
  AddNewBiothesiometry = () => {
    try {
      var states = this.state;
      var isValid = this.validateData();
      var data = {
        appointment_id: this.props.patientAppointment.appointment_id,
        wrist_normal_value:
          states.wrist_normal_value != ""
            ? this.convertToFloat(states.wrist_normal_value)
            : 0,
        ankle_normal_value:
          states.ankle_normal_value != ""
            ? this.convertToFloat(states.ankle_normal_value)
            : 0,
        toe_normal_value:
          states.toe_normal_value != ""
            ? this.convertToFloat(states.toe_normal_value)
            : 0,
        wrist_right:
          states.wrist_right != ""
            ? this.convertToFloat(states.wrist_right)
            : 0,
        wrist_left:
          states.wrist_left != "" ? this.convertToFloat(states.wrist_left) : 0,
        ankle_left:
          states.ankle_left != "" ? this.convertToFloat(states.ankle_left) : 0,
        ankle_right:
          states.ankle_right != ""
            ? this.convertToFloat(states.ankle_right)
            : 0,
        toe_right:
          states.toe_right != "" ? this.convertToFloat(states.toe_right) : 0,
        toe_left:
          states.toe_left != "" ? this.convertToFloat(states.toe_left) : 0,
        impression: states.impression,
        unit: states.unit,
      };
      if (isValid) {
        var service_url =
          Constants.FOOT_CLINIC_BIOTHEIOMETRY +
          "?appointment_id=" +
          this.props.patientAppointment.appointment_id;
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.AddNewBiothesiometrySuccess,
          this.AddNewBiothesiometryFailure
        );
      } else {
        this.props.showResposeValue("error", "Please enter all value to save");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  AddNewBiothesiometrySuccess = (response) => {
    if (response) {
      if (response.status == "success") {
        this.props.showResposeValue("success", response.message);
        this.resetClicked();
        this.getBiothesiometryLog();
      } else {
        this.props.showResposeValue("error", response.message);
      }
    }
  };
  AddNewBiothesiometryFailure = (error) => {
    this.props.showResposeValue("error", error.message);
  };

  //Print Brachial Index
  print = () => {
    try {
      var service_url =
        Constants.FOOT_CLINIC_BIOTHEIOMETRY +
        "?appointment_id=" +
        this.props.patientAppointment.appointment_id +
        "&export=pdf";
      OpthamologyService.getInstance().documentUploadGet(
        service_url,
        this.printBrachialIndexLogSuccess,
        this.printBrachialIndexLogFailure,
        "pdf"
      );
    } catch (e) {
      console.log(e.message);
    }
  };
  printBrachialIndexLogSuccess = (response) => {
    if (response) {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  printBrachialIndexLogFailure = (error) => {
    console.log(error);
  };
  render() {
    return (
      <View>
        <View>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={[
              { label: "Brachial Index", value: "brachialIndex" },
              { label: "Biothesiometry", value: "biothesiometry" },
            ]}
            selectedTab={"biothesiometry"}
            isNavigationBorder={true}
          />
        </View>
        <View style={styles.container}>
          <View style={styles.left_container}>
            <View>
              <View>
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <View style={{ flex: 0.8 }}>
                    <Text style={styles.RightHeadTitle}>
                      Diabetic Foot Biothesiometry Logs
                    </Text>
                  </View>
                  <View style={{ flex: 0.2 }}>
                    <TouchableOpacity
                      style={styles.PrintBtn}
                      onPress={() => {
                        this.print();
                      }}
                    >
                      <Image source={ImagePath.Print} style={styles.PrintImg} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  <View style={styles.TableContainer}>
                    <View>
                      <View style={{ width: "100%" }}>
                        <View style={[styles.TableTitleContainer]}>
                          <View style={{ alignItems: "center", width: "30%" }}>
                            <Text style={styles.TableHeadText}>Site</Text>
                          </View>
                          <View style={{ alignItems: "left", width: "20%" }}>
                            <Text
                              style={[
                                styles.TableHeadText,
                                { marginLeft: "2.5vw" },
                              ]}
                            >
                              Right
                            </Text>
                          </View>
                          <View style={{ alignItems: "center", width: "20%" }}>
                            <Text style={styles.TableHeadText}>Left</Text>
                          </View>
                          <View style={{ alignItems: "center", width: "30%" }}>
                            <Text style={styles.TableHeadText}>
                              Normal Values
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.MainTable}>
                      <View>
                        <View style={{ width: "100%" }}>
                          <View style={[styles.TableDataWrapper]}>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>Wrist</Text>
                            </View>
                            <View style={{ alignItems: "left", width: "20%" }}>
                              <Text
                                style={[
                                  styles.TableHeadText,
                                  { marginLeft: "2.5vw" },
                                ]}
                              >
                                {this.state.wrist_right != ""
                                  ? this.state.wrist_right
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "20%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.wrist_left != ""
                                  ? this.state.wrist_left
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.wrist_normal_value != ""
                                  ? this.state.wrist_normal_value
                                  : "Nil"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View>
                        <View style={{ width: "100%" }}>
                          <View style={[styles.TableDataWrapper]}>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                Ankle (Medical Malleous)
                              </Text>
                            </View>
                            <View style={{ alignItems: "left", width: "20%" }}>
                              <Text
                                style={[
                                  styles.TableHeadText,
                                  { marginLeft: "2.5vw" },
                                ]}
                              >
                                {this.state.ankle_right != ""
                                  ? this.state.ankle_right
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "20%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.ankle_left != ""
                                  ? this.state.ankle_left
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.ankle_normal_value != ""
                                  ? this.state.ankle_normal_value
                                  : "Nil"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View>
                        <View style={{ width: "100%" }}>
                          <View style={[styles.TableDataWrapper]}>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                Great Toe
                              </Text>
                            </View>
                            <View style={{ alignItems: "left", width: "20%" }}>
                              <Text
                                style={[
                                  styles.TableHeadText,
                                  { marginLeft: "2.5vw" },
                                ]}
                              >
                                {this.state.toe_right != ""
                                  ? this.state.toe_right
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "20%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.toe_left != ""
                                  ? this.state.toe_left
                                  : "Nil"}
                              </Text>
                            </View>
                            <View
                              style={{ alignItems: "center", width: "30%" }}
                            >
                              <Text style={styles.TableHeadText}>
                                {this.state.toe_normal_value != ""
                                  ? this.state.toe_normal_value
                                  : "Nil"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={{ marginTop: 30 }}>
                        <label style={{ fontWeight: "bold" }}>
                          Impression:
                        </label>
                        <TextInput
                          multiline={true}
                          disabled={true}
                          value={this.state.impression}
                          style={[styles.MultilineText]}
                        ></TextInput>
                        {/* <Text style={[styles.img_lbl_txt]}>
                            {this.state.impression}
                          </Text> */}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.right_container}>
            {this.renderBiothesiometryLog()}
          </View>
        </View>
      </View>
    );
  }
}

export default Biothesiometry;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: "75vh",
    padding: 20,
  },
  left_container: {
    flex: 0.6,
    height: "75vh",
    margin: 5,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    padding: 5,
  },
  right_container: {
    flex: 0.4,
    backgroundColor: color.themeShade,
    height: "75vh",
    margin: 5,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    padding: 5,
  },
  LeftMainView: {
    width: "65%",
    height: "75vh",
    margin: "0.2vw",
    marginLeft: "0.7vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    borderRadius: "0.25vw",
  },

  RightMainView: {
    width: "33%",
    height: "70vh",
    backgroundColor: color.themeShade,
    margin: "0.2vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    borderRadius: "0.25vw",
  },
  RightScrollView: {},
  TableContainer: {
    marginLeft: "1vw",
    marginRight: "1vw",
    marginTop: "0.5vw",
  },
  TableHeadText: {
    fontSize: "0.91vw",
    color: "black",
    fontWeight: "500",
  },
  TableDataText: {
    fontSize: "0.91vw",
  },
  TableDataView: {
    justifyContent: "center",
    maxHeight: "2.9vw",
    minHeight: "2.9vw",
    width: "100%",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderRadius: "0.4vw",
  },
  TableDataWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw",
  },
  MainTable: {
    height: "50vh",
  },
  RightHeadTitle: {
    marginLeft: "1vw",
    marginTop: "0.3vw",
    fontSize: "1vw",
    fontWeight: "bold",
    color: color.themeDark,
  },
  HightLightView: {
    backgroundColor: color.sectionHeadingLiteColor,
    alignContent: "flex-start",
    borderRadius: "0.4vw",
    margin: "1vw",
  },
  HighLightText: {
    marginLeft: "1.3vw",
    margin: "0.5vw",
    fontSize: "0.8vw",
    fontWeight: "bold",
  },
  TextInputStyles: {
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: "0.065vw",
    width: "100%",
    height: "1.5vw",
    fontSize: ".9vw",
  },
  renderTextBoxContainer: {
    width: "100%",
    marginTop: "0.65vw",
  },
  InputText: {
    fontSize: "0.9vw",
    marginLeft: "2vw",
    marginTop: "0.2vw",
    height: "1.5vw",
    flex: 0.4,
  },
  InputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  BtnTouch: {
    paddingTop: "0.5vw",
    paddingLeft: "1.1vw",
    paddingRight: "1.1vw",
    paddingBottom: "0.5vw",
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: "10vw",
  },
  ButtonText: {
    color: color.white,
    textAlign: "center",
    fontSize: ".85vw",
  },
  MandatoryText: {
    color: "red",
    fontWeight: "500",
    marginLeft: "0.2vw",
    fontSize: "1vw",
  },
  NoRecordsView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5vw",
  },
  NoRecordsText: {
    fontSize: "1vw",
  },
  SearchInput: {
    height: "2vw",
    borderWidth: "0.065vw",
    borderColor: color.lightGray,
    borderRadius: "0.26vw",
    width: "25%",
    marginRight: "0.65vw",
    paddingHorizontal: "0.52vw",
    color: color.black,
    fontSize: "0.91vw",
  },
  TabView: {
    marginLeft: "2vw",
  },
  value_txt_box: {
    width: "18vw",
    marginRight: "1.5vw",
    flex: 0.3,
  },
  value_comment_box: {
    width: "18vw",
    marginRight: "1.5vw",
    flex: 0.6,
  },
  unit_txt_box: {
    width: "8vw",
    marginRight: "1.5vw",
    flex: 0.3,
  },
  Header_BackIcon: {
    height: "0.8vw",
    width: "2vw",
  },
  img_value_box_left: {
    backgroundColor: color.grayShade,
    flexDirection: "row",
  },
  img_value_box_right: {
    backgroundColor: color.grayShade,
    transform: "rotate(180deg)",
  },
  img_lbl_txt: {
    fontSize: "1vw",
    fontWeight: "bold",
  },
  img_lbl: {
    fontSize: "0.7vw",
    fontWeight: "bold",
  },
  PrintImg: {
    height: "1.3vw",
    width: "1.3vw",
    alignSelf: "center",
  },
  PrintBtn: {
    backgroundColor: color.themeBackgroud,
    borderRadius: "0.26vw",
    marginLeft: "0.65vw",
    height: "1.76vw",
    width: "1.76vw",
    justifyContent: "center",
  },
  MultilineText: {
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: "0.065vw",
    width: "40vw",
    height: "5vw",
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
});
