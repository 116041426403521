import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { TimePicker } from 'antd';
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import Service from '../../../../network/OpthamologyService';


export default class QmsConfig extends Component {
    constructor(props) {
        super(props)
        this.reference = React.createRef()
        this.state = {
            waitingTime: "",
            consultingTime: "",
            time: {},
            isEdit: false,
            userType: this.props.userType,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor
        }
    }
    componentDidMount() {
        this.getQmsConfiguration()
    }

    getQmsConfiguration = () => {
        let url = ""
        if (this.state.userType === Constants.ROLE_FO) {
            url = Constants.QMS_CONFIGURATION_GET;
        } else {
            url = Constants.QMS_CONFIGURATION_GET + "?clinic_id=" + this.state.selectedClinicOrDoctor.id
        }
        Service.getInstance().getComplaints(
            url,
            this,
            this.getQmsConfigurationSuccess,
            this.getQmsConfigurationFailure
        )
    }
    getQmsConfigurationSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                waitingTime: response.data.waiting_time,
                consultingTime: response.data.consulting_time
            })
        }
    }
    getQmsConfigurationFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }

    postQmsConfiguration = () => {
        let url = "";

        let time = this.state.time
        if (this.state.userType === Constants.ROLE_FO) {
            url = Constants.QMS_CONFIGURATION_POST;
            time = {
                "waiting_time": this.state.waitingTime,
                "consulting_time": this.state.consultingTime
            }
        } else {
            url = Constants.QMS_CONFIGURATION_POST;
            time = {
                "clinic_id": this.state.selectedClinicOrDoctor.id,
                "waiting_time": this.state.waitingTime,
                "consulting_time": this.state.consultingTime
            }
        }

        Service.getInstance().postComplaints(
            url,
            time,
            this,
            this.qmsConfigutationSuccess,
            this.qmsConfigutationFailure
        )
    }
    qmsConfigutationSuccess = (response) => {
        if (response.status === 'success') {
            this.props.showResposeValue("success", response.message)
            this.setState({ isEdit: false })
            this.getQmsConfiguration()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    qmsConfigutationFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }
    render() {
        return (
            <View style={Styles.QmsConfigContent}>
                <View>
                    <View style={Styles.QmsColLeft}>
                        <Text style={Styles.ColLeftText}>Waiting Time Notification Once Appointment Time Breached More Than</Text>
                        <View style={Styles.QmsColRight}>
                            <View  >
                                <TimePicker
                                    style={{ width: "7vw", height: "2.5vw", marginRight: "1vw", borderRadius: "5px" }}
                                    format="HH:mm"
                                    clearIcon={false}
                                    onFocus={() => {
                                        let states = this.state
                                        states["waitingTime"] = ""
                                        this.setState({ states })
                                    }}
                                    onBlur={() => { if (!this.state.isEdit) { this.getQmsConfiguration() } }}
                                    value={this.state.waitingTime ? moment(this.state.waitingTime, "HH:mm") : ""}
                                    onChange={(time, timeString) => {
                                        this.setState({
                                            waitingTime: timeString + ":00",
                                            isEdit: true
                                        })
                                    }}
                                />
                            </View>
                            <Text style={Styles.ColRightMinutes}>Hours</Text>
                            <TouchableOpacity style={Styles.Button}
                                onPress={() => { this.postQmsConfiguration() }}>
                                <Text style={Styles.ButtonText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={Styles.QmsColLeft}>
                        <Text style={Styles.ColLeftText}>Consultating Time Notification Once With Doctor Time Breached More Than</Text>
                        <View style={Styles.QmsColRight}>
                            <View>
                                <TimePicker
                                    style={{ width: "7vw", height: "2.5vw", marginRight: "1vw", borderRadius: "5px" }}
                                    format="HH:mm"
                                    clearIcon={false}
                                    onFocus={() => {
                                        let states = this.state
                                        states["consultingTime"] = ""
                                        this.setState({ states })
                                    }}
                                    onBlur={() => { if (!this.state.isEdit) { this.getQmsConfiguration() } }}
                                    value={this.state.consultingTime ? moment(this.state.consultingTime, "HH:mm") : ""}
                                    onChange={(time, timeString) => {
                                        this.setState({ consultingTime: timeString + "00", isEdit: true })
                                    }}
                                />
                            </View>
                            <Text style={Styles.ColRightMinutes}>Hours</Text>
                            <TouchableOpacity style={Styles.Button}
                                onPress={() => this.postQmsConfiguration()}>
                                <Text style={Styles.ButtonText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View >
        )
    }
}

const Styles = StyleSheet.create({
    QmsConfigContent: {
        fontSize: "1vw"
    },
    QmsColLeft: {
        flexDirection: "row",
        margin: "1vw",
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
        padding: "0.5vw",
        height: "4vw"
    },
    ColLeftText: {
        flex: 1,
        position: "relative",
        top: "0.5vw",
        fontSize: "1vw"
    },
    QmsColRight: {
        flexDirection: "row",
        fontSize: "1vw"
    },
    ColRightMinutes: {
        marginRight: "1vw",
        position: "relative",
        top: "0.5vw",
        fontSize: "1vw"
    },
    Button: {
        backgroundColor: "#04B7B1",
        width: "4vw",
        height: "1.6vw",
        borderRadius: 5,
        textAlign: "center",
        position: "relative",
        top: "0.5vw"
    },
    ButtonText: {
        position: "relative",
        textAlign: "center",
        color: "white",
        top: "0.1vw",
        fontSize: "1vw"
    }
})