import React, { Component } from "react";
import {
  View,
  ScrollView,
  Dimensions,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import { color } from "../../../../styles/Color";

import { Doughnut, Pie, Bar, HorizontalBar } from "react-chartjs-2";
import { FundusAllFieldsAreEmpty } from "../../../../utils/InfoMessages";
import moment from "moment";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker

const options ={
  plugins: {
    labels: {
      // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
      render: 'label',

      // precision for percentage, default is 0
      precision: 0,

      // identifies whether or not labels of value 0 are displayed, default is false
      showZero: true,

      // font size, default is defaultFontSize
      fontSize: 12,

      // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
      fontColor: '#fff',

      // font style, default is defaultFontStyle
      fontStyle: 'normal',

      // font family, default is defaultFontFamily
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

      // draw text shadows under labels, default is false
      textShadow: true,

      // text shadow intensity, default is 6
      shadowBlur: 10,

      // text shadow X offset, default is 3
      shadowOffsetX: -5,

      // text shadow Y offset, default is 3
      shadowOffsetY: 5,

      // text shadow color, default is 'rgba(0,0,0,0.3)'
      shadowColor: 'rgba(255,0,0,0.75)',

      // draw label in arc, default is false
      // bar chart ignores this
      arc: false,

      // position to draw label, available value is 'default', 'border' and 'outside'
      // bar chart ignores this
      // default is 'default'
      position: 'outside',

      // draw label even it's overlap, default is true
      // bar chart ignores this
      overlap: true,

      // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
      showActualPercentages: true,

      // set images when `render` is 'image'
      images: [
        {
          src: 'image.png',
          width: 16,
          height: 16
        }
      ],

      // add padding when position is `outside`
      // default is 2
      outsidePadding: 4,

      // add margin of text when position is `outside` or `border`
      // default is 2
      textMargin: 4
    }
  }
}

export const VisiteTimePlanner = (props) => {
  var { visiteTimePlannerLabels, visiteTimePlannerDatas } = props;
  const data = {
    datasets: [
      {
        label: "",
        data: visiteTimePlannerDatas,
        backgroundColor: [
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
        ],
      },
    ],
    labels: visiteTimePlannerLabels,
  };
  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Visiting Time Planner"}</Text>
      </View>

      <View style={[styles.ChartBodyView, { height: 350 }]}>
        <Bar
          data={data}
          width={50}
          height={10}
          options={{
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'No of Patients'
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Visiting Hours'
                }
              }],
            
            } ,
            maintainAspectRatio: false,
            legend: {
              // position: "chartArea",
              display: false
          },
            // legend: {
            //     display: true
            //     // position: "right",
            //     // legendCallback: function(chart) {
            //     //   // Return the HTML string here.
            //     //   // console.log(chart);
            //     //   return [
            //     //   ];
            //     // }
            //   }
          }}
        />
      </View>
    </View>
  );
};

// pie
export const VisiteType = (props) => {
  let chartInstance = null;
  var { visiteTypeLabels, visiteTypeDatas } = props;
  const data = {
    datasets: [
      {
       
        

        data: visiteTypeDatas,
        backgroundColor: [
        
          "#ED738D",
          "#34709A",
          // "rgb(255, 205, 86)",
          // "rgb(75, 192, 192)",
          // "rgb(54, 162, 235)",
        ],
      },
    ],
    labels: visiteTypeLabels,
  };

  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Visit Type"}</Text>
      </View>

      <View style={styles.ChartBodyView}>
        <Pie
          data={data}
          options={options}
          ref={(input) => {
            chartInstance = input;
          }}
        />
      </View>
    </View>
  );
};

// pie
export const ReviewPatientAttendance = (props) => {
  let chartInstance = null;
  var { ReviewPatientAttendanceLabels, ReviewPatientAttendanceData } = props;
  const data = {
    datasets: [
      {
        data: ReviewPatientAttendanceData,
        backgroundColor: [
          "#ED738D",
          "#34709A",
          // "rgb(255, 99, 132)",
          // "rgb(255, 159, 64)",
          // "rgb(255, 205, 86)",
          // "rgb(75, 192, 192)",
          // "rgb(54, 162, 235)",
        ],
      },
    ],
    labels: ReviewPatientAttendanceLabels,
  };

  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>
          {"Review Patient Attendance"}
        </Text>
      </View>

      <View style={styles.ChartBodyView}>
        <Pie
          data={data}
          options={options}
          ref={(input) => {
            chartInstance = input;
          }}
        />
      </View>
    </View>
  );
};

export const Revenue = (props) => {
  var { revenueLabels, revenueDatas } = props;
  const data = {
    datasets: [
      {
        data: revenueDatas,
        backgroundColor: [
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
        ],
      },
    ],
    labels: revenueLabels,
  };
  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Revenue"}</Text>
      </View>

      <View style={[styles.ChartBodyView, { height: 350 }]}>
        <Bar
          data={data}
          width={50}
          height={10}
          options={{ 
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
              },
                scaleLabel: {
                  display: true,
                  labelString: 'Amount'
                }
                 
                
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Description'
                }
              }],
            
            } ,
            legend: {
            // position: "chartArea",
            display: false
        },maintainAspectRatio: false }}
        />
      </View>
    </View>
  );
};


export const LocationAnalysis = (props) => {
  var { locationAnalysisLabels, locationAnalysisDatas } = props;
  const data = {
    datasets: [
      {
        data: locationAnalysisDatas,
        backgroundColor: [
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
        ],
      },
    ],
    labels: locationAnalysisLabels,
  };
  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Location Analysis"}</Text>
      </View>

      <View style={[styles.ChartBodyView, { height: 350 }]}>
        <Bar
          data={data}
          width={50}
          height={10}
          options={{ 
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'No of Patients'
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Area Name'
                }
              }],
            
            } ,
              legend: {
                // position: "chartArea",
                display: false
            },
              maintainAspectRatio: false }}
        />
      </View>
    </View>
  );
};

export const SpecializationVisit = (props) => {
  var { specializationVisitLabels, specializationVisitDatas } = props;
  const data = {
    datasets: [
      {
        data: specializationVisitDatas,
        backgroundColor: [
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
          "#ED738D",
          "#34709A",
        ],
      },
    ],
    labels: specializationVisitLabels,
  };
  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Specialization Visit"}</Text>
      </View>

      <View style={[styles.ChartBodyView, { height: 350 }]}>
        <HorizontalBar data={data}  options={{ 
             scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Specializations'
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'No of Patients'
                }
              }],
            
            } ,
              legend: {
                // position: "chartArea",
                display: false
            },
              maintainAspectRatio: false }} />
      </View>
    </View>
  );
};
// pie
export const DeliveryDue = (props) => {
  let chartInstance = null;
  var { deliveryDuesLabels, deliveryDuesData } = props;
  const data = {
    datasets: [
      {
       
        

        data: deliveryDuesData,
        backgroundColor: [
        
          // "#ED738D",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          "#34709A",
          // "rgb(255, 205, 86)",
          // "rgb(75, 192, 192)",
          // "rgb(54, 162, 235)",
        ],
      },
    ],
    labels: deliveryDuesLabels,
  };

  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Delivery Dues"}</Text>
      </View>

      <View style={styles.ChartBodyView}>
        <Pie
          data={data}
          options={options}
          ref={(input) => {
            chartInstance = input;
          }}
        />
      </View>
    </View>
  );
};



export const DoctorVisit = (props) => {

  var { doctorVisitLabels, doctorVisitDatas } = props;
  const data = {
    datasets: [
      {
        data: doctorVisitDatas,
        backgroundColor: [


          "#B3A5EF",
          "#AC92EA",
          "#967ADA",
          "#B3A5EF",
          "#AC92EA",
          "#967ADA",
          "#B3A5EF",
          "#AC92EA",
          "#967ADA",
          "#B3A5EF",
          "#AC92EA",
          "#967ADA",
          "#B3A5EF",
          "#AC92EA",
          "#967ADA",

          // // BLUE
          // "#73B1F4",
          // "#5E9CEA",
          // "#4B898DA",
          // "#73B1F4",
          // "#5E9CEA",
          // "#4B898DA",
          // "#73B1F4",
          // "#5E9CEA",
          // "#4B898DA",
          // "#73B1F4",
          // "#5E9CEA",
          // "#4B898DA",

          // // AQUA
          // "#62DDBD",
          // "#46CEAD",
          // "#35BB9B",
          // "#62DDBD",
          // "#46CEAD",
          // "#35BB9B",
          // "#62DDBD",
          // "#46CEAD",
          // "#35BB9B",
          // "#62DDBD",
          // "#46CEAD",
          // "#35BB9B",



          // "#F76D82",
          // "#EC5564",
          // "#D94452",
          // "#F76D82",
          // "#EC5564",
          // "#D94452",
          // "#F76D82",
          // "#EC5564",
          // "#D94452",
          // "#F76D82",
          // "#EC5564",
          // "#D94452",
          // "#F76D82",
          // "#EC5564",
          // "#D94452",

          // "#5E9CEA",
          // "#EC5564",
          // "#46CEAD",
          // "#5E9CEA",
          // "#EC5564",
          // "#46CEAD",
          // "#5E9CEA",
          // "#EC5564",
          // "#46CEAD",
          // "#5E9CEA",
          // "#EC5564",
          // "#46CEAD",


          // "#T7BATA",
          // "#9956E3",
          // "#FC317B",
          // "#FFBE03",
          // "#44D8BE",
          // "#T7BATA",
          // "#9956E3",
          // "#FC317B",
          // "#FFBE03",
          // "#44D8BE",
          

          
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",
          // "#ED738D",
          // "#34709A",

          // "#EB86BE",
          // "#FDCD56",
          // "#9ED26A",
          // "#AC92EA",
          // "#66D6BA",
          // "#EB86BE",
          // "#FDCD56",
          // "#9ED26A",
          // "#AC92EA",
          // "#66D6BA" 
        ],
      },
    ],
    labels: doctorVisitLabels,
  };
  return (
    <View style={styles.ChartCardView}>
      <View style={styles.ChartHeaderView}>
        <Text style={styles.ChartHeaderStyle}>{"Doctor Visit"}</Text>
      </View>

      <View style={[styles.ChartBodyView, { height: 350 }]}>
        <HorizontalBar data={data} 
        options= {{ 
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Doctor Names'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'No of Patients'
              }
            }],
          
          } ,      
        legend: {
            // position: "chartArea",
            display: false
        },

        // legend: {
        //   display: true
        // },

        // scales: {
        //     yAxes: [{
        //         ticks: {
        //             fontColor: "rgba(0,0,0,0.5)",
        //             fontStyle: "bold",
        //             beginAtZero: true,
        //             maxTicksLimit: 5,
        //             padding: 20
        //         },
        //         gridLines: {
        //             drawTicks: false,
        //             display: false
        //         }

        //     }],
        //     xAxes: [{
        //         gridLines: {
        //             zeroLineColor: "transparent"
        //         },
        //         ticks: {
        //             padding: 20,
        //             fontColor: "rgba(0,0,0,0.5)",
        //             fontStyle: "bold"
        //         }
        //     }]
        // }
    }}
        
        
        />
      </View>
    </View>
  );
};





const styles = StyleSheet.create({
  ChartCardView: {
    width: "90%",
    height: 400,
    marginVertical: 10,
    backgroundColor: color.applicationBackgroundColor,
  },
  ChartHeaderView: {
    paddingVertical: 8,
    borderBottomColor: "#888888",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  ChartHeaderStyle: { fontSize: 20, fontWeight: "600" },
  ChartBodyView: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "center",
  },
});



 // // for border line
        // fillColor : "rgba(250,174,50,0.5)",
        //             strokeColor : "#ff6c23",
        //             pointColor : "#fff",
        //             pointStrokeColor : "#ff6c23",
        //             pointHighlightFill: "#fff",
        //             pointHighlightStroke: "#ff6c23",