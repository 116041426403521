//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from "moment";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
// create a component
const pageName = "isDiabeticHistory";

var yearsOfDiabetic = [
    { label: "1 year", value: "1" },
    { label: "2 years", value: "2" },
    { label: "3 years", value: "3" },
    { label: "4 years", value: "4" },
    { label: "5 years", value: "5" },
    { label: "6 years", value: "6" },
];

var familyDiabeticList = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Brother", value: "Brother" },
];

var typesDiabeticList = [
    { label: "Type 1", value: "1" },
    { label: "Type 2", value: "2" },
    { label: "Not Sure", value: "0" },
];


var treatedOfDiabeticList = [
    { label: "Blood Glucose Pills", value: "Blood Glucose Pills" },
    { label: "Insulin Injections", value: "Insulin Injections" },
    { label: "Diet Exercises", value: "Diet Exercises" },
];

var nameOfBGMeter = [
    { label: "ACCU-CHEK AVIVA NANO", value: "ACCU-CHEK AVIVA NANO" },
    { label: "ACCU-CHEK MOBILE", value: "ACCU-CHEK MOBILE" },
    { label: "ABBOTTI DIABETES CARE", value: "ABBOTTI DIABETES CARE" },
];

export class DiabeticHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedValue: true,
            // details: '',

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            newToDiagnosis: false,
            newToDiagnosisDetails: "",

            newButtonValue: "",


            yearsOfDiabeticList: yearsOfDiabetic,
            selectedYearsOfDiabetic: {},
            showDiabeticYearsNewButtonFlag: true,

            familyDiabeticList: familyDiabeticList,
            selectedfamilyDiabetic: {},
            showFamilyDiabeticListNewButtonFlag: true,

            typesDiabetic: {},
            typesDiabeticList: typesDiabeticList,

            treatedOfDiabetic: [],
            treatedOfDiabeticList: treatedOfDiabeticList,


            doYouCheckBG: false,
            doYouCheckBGDetails: "",

            nameOfBGMeter: nameOfBGMeter,
            selectedfNameOfBGMeter: {},
            showNameOfBGMeterNewButtonFlag: true,

            getLowBGDetails: "",
            getLowBG: false,

            usualResultValue: "",

            lastA1cResultValue: "",
            lastA1cResultDate: ""

        }
    }

    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
        if (key == "newToDiagnosisDetails") {
            states["newToDiagnosis"] = flag;
            states[key] = value;
        } else if (key == "doYouCheckBGDetails") {
            states["doYouCheckBG"] = flag;
            states[key] = value;
        } else if (key == "getLowBGDetails") {
            states["getLowBG"] = flag;
            states[key] = value;
        }
        this.setState({ states })
    }


    componentWillReceiveProps(props) {


        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;
                var {yearsOfDiabeticList,familyDiabeticList,nameOfBGMeter}=this.state
                states["newToDiagnosis"] = data.new_diabetes_diagnosed
                // states["selectedYearsOfDiabetic"] = data.diabetes_duration ? { label: data.diabetes_duration, value: data.diabetes_duration } : null
                 // states["selectedfamilyDiabetic"] = data.diabetes_family_history ? { label: data.diabetes_family_history, value: data.diabetes_family_history } : null
                states["doYouCheckBG"] = data.blood_glucose_check
                states["doYouCheckBGDetails"] = data.bg_check_frequency

                states["usualResultValue"] = data.bg_usual_result
                states["lastA1cResultValue"] = data.last_a1c_result
                states["lastA1cResultDate"] = data.last_ac1_date
                states["getLowBG"] = data.low_blood_glucose
                states["getLowBGDetails"] = data.low_bg_date_time

                // states["selectedfNameOfBGMeter"] = data.bg_check_meter ? { label: data.bg_check_meter, value: data.bg_check_meter } : null

                states["typesDiabetic"] = data.diabetes_type == 1 ?
                    { label: "Type 1", value: data.diabetes_type } :
                    data.diabetes_type == 2 ?
                        { label: "Type 2", value: data.diabetes_type } :
                        { label: "Not Sure", value: "0" }


                var getTreatedOfDiabetic = data.treatment_method[0]?.split(',')
                if (getTreatedOfDiabetic.length > 0) {
                    let data = []
                    
                    for (let i = 0; i < getTreatedOfDiabetic.length; i++) {
                        var preparData = {};
                        if(getTreatedOfDiabetic[i]){
                        if (getTreatedOfDiabetic[i] == "Blood Glucose Pills") {
                            preparData = { label: "Blood Glucose Pills", value: getTreatedOfDiabetic[i] }
                        } else if (getTreatedOfDiabetic[i] == "Insulin Injections") {
                            preparData = { label: "Insulin Injections", value: getTreatedOfDiabetic[i] }
                        } else if (getTreatedOfDiabetic[i] == "Diet Exercises") {
                            preparData = { label: "Diet Exercises", value: getTreatedOfDiabetic[i] }
                        }

                        data.push(preparData)
                    }
                    }
                    states["treatedOfDiabetic"] = data

                } else {
                    states["treatedOfDiabetic"] = []
                }
//year of diabets


                var yearofdiabets =data && data.diabetes_duration ? data.diabetes_duration :"";
                yearofdiabets=(yearofdiabets).toString()
                if(yearofdiabets){
                    var prepareYearsOfDiabetes ={label:yearofdiabets+" years",value:yearofdiabets}
                        states["selectedYearsOfDiabetic"] = prepareYearsOfDiabetes;
                        yearsOfDiabeticList.push(prepareYearsOfDiabetes);
                        yearsOfDiabeticList = this.removeDuplicate(yearsOfDiabeticList)
                }

            //Family of diabets    
            var familyDiabetic =data && data.diabetes_family_history ? data.diabetes_family_history :"";
            familyDiabetic=(familyDiabetic).toString();
            if(familyDiabetic){
                var prepareYearsOfDiabetes ={label:familyDiabetic,value:familyDiabetic}
                    states["selectedfamilyDiabetic"] = prepareYearsOfDiabetes;
                    familyDiabeticList.push(prepareYearsOfDiabetes);
                    familyDiabeticList = this.removeDuplicate(familyDiabeticList)
            }
            // for (let i = 0; i <familyDiabeticList.length; i++) {
            //   // const element = array[i];
            //   if(familyDiabeticList[i].value == familyDiabetic){
            //     var prepareChildren={label:familyDiabetic.toString(),value:familyDiabetic.toString()}
            //     states["selectedfamilyDiabetic"] = prepareChildren;
            //   }else{
            //     var prepareChildren={label:familyDiabetic.toString(),value:familyDiabetic.toString()}
            //     familyDiabeticList.push(prepareChildren);
            //     states["selectedfamilyDiabetic"] = prepareChildren;
            //   }
              
            // }
            // familyDiabeticList = this.removeDuplicate(familyDiabeticList)

            
        //    //blood glucose meter
           var nameOfBGMeterlist =data && data.bg_check_meter ? data.bg_check_meter :"";
           nameOfBGMeterlist=(nameOfBGMeterlist).toString();
           if(nameOfBGMeterlist){
               var prepareYearsOfDiabetes ={label:nameOfBGMeterlist,value:nameOfBGMeterlist}
                   states["selectedfNameOfBGMeter"] = prepareYearsOfDiabetes;
                   nameOfBGMeter.push(prepareYearsOfDiabetes);
                   nameOfBGMeter = this.removeDuplicate(nameOfBGMeter)
           }
        //    for (let i = 0; i <nameOfBGMeter.length; i++) {
        //      // const element = array[i];
        //      if(nameOfBGMeter[i].value == nameOfBGMeterlist){
        //        var prepareChildren={label:nameOfBGMeterlist.toString(),value:nameOfBGMeterlist.toString()}
        //        states["selectedfNameOfBGMeter"] = prepareChildren;
        //      }else{
        //        var prepareChildren={label:nameOfBGMeterlist.toString(),value:nameOfBGMeterlist.toString()}
        //        nameOfBGMeter.push(prepareChildren);
        //        states["selectedfNameOfBGMeter"] = prepareChildren;
        //      }
             
        //    }
        //    nameOfBGMeter = this.removeDuplicate(nameOfBGMeter)
           
                this.setState({ states,yearsOfDiabeticList,familyDiabeticList,nameOfBGMeter }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }


    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "showDiabeticYearsNewButtonFlag") {
            states[key] = false;
            states["showFamilyDiabeticListNewButtonFlag"] = true;
            states["showNameOfBGMeterNewButtonFlag"] = true;

        } else if (key == "showFamilyDiabeticListNewButtonFlag") {
            states[key] = false;
            states["showDiabeticYearsNewButtonFlag"] = true;
            states["showNameOfBGMeterNewButtonFlag"] = true;
        } else if (key == "showNameOfBGMeterNewButtonFlag") {
            states[key] = false;
            states["showDiabeticYearsNewButtonFlag"] = true;
            states["showFamilyDiabeticListNewButtonFlag"] = true;
        } else if (key == "treatedOfDiabetic") {

            var { treatedOfDiabetic } = this.state;

            var getSelectedValue;
            var spliceData;

            if (treatedOfDiabetic.length > 0) {
                for (var i = 0; i < treatedOfDiabetic.length; i++) {
                    if (treatedOfDiabetic[i].value == selectedValue.value) {
                        spliceData = treatedOfDiabetic[i];
                        treatedOfDiabetic.splice(i, 1)
                        var data = this.removeDuplicate(treatedOfDiabetic);
                        this.setState({ treatedOfDiabetic: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { treatedOfDiabetic.push(selectedValue); }
            } else {
                treatedOfDiabetic.push(selectedValue)
            }

            var data = this.removeDuplicate(treatedOfDiabetic);
            this.setState({ treatedOfDiabetic: data })

        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onSubmitToAddValue() {
        var { yearsOfDiabeticList, familyDiabeticList, nameOfBGMeter } = this.state;
        var states = this.state;
        var preparData = {};
        if (!this.state.showDiabeticYearsNewButtonFlag) {
            preparData = { label: this.state.newButtonValue + " years", value: this.state.newButtonValue }
        } else {
            preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }
        }

        if (!this.state.showDiabeticYearsNewButtonFlag) {
            yearsOfDiabeticList.push(preparData);
            yearsOfDiabeticList = this.removeDuplicate(yearsOfDiabeticList);
            states["newButtonValue"] = "";
            states["showDiabeticYearsNewButtonFlag"] = true;
            states["showFamilyDiabeticListNewButtonFlag"] = true;
            states["showNameOfBGMeterNewButtonFlag"] = true;
            states["selectedYearsOfDiabetic"] = preparData;
        }

        if (!this.state.showNameOfBGMeterNewButtonFlag) {
            nameOfBGMeter.push(preparData);
            nameOfBGMeter = this.removeDuplicate(nameOfBGMeter);
            states["newButtonValue"] = "";
            states["showDiabeticYearsNewButtonFlag"] = true;
            states["showFamilyDiabeticListNewButtonFlag"] = true;
            states["showNameOfBGMeterNewButtonFlag"] = true;
            states["selectedfNameOfBGMeter"] = preparData;
        }

        if (!this.state.showFamilyDiabeticListNewButtonFlag) {
            familyDiabeticList.push(preparData);
            familyDiabeticList = this.removeDuplicate(familyDiabeticList);
            states["newButtonValue"] = "";
            states["showFamilyDiabeticListNewButtonFlag"] = true;
            states["showDiabeticYearsNewButtonFlag"] = true;
            states["showNameOfBGMeterNewButtonFlag"] = true;

            states["selectedfamilyDiabetic"] = preparData;
        }

        this.setState({
            yearsOfDiabeticList, familyDiabeticList, nameOfBGMeter, states
        })

    }


    renderLastA1cResult() {
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.historyYesOrNOSectionRowView, Style.allButtonBorderRadius, { marginTop: 10 }]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ marginLeft: 20, fontSize: 14 }}>{"Last A1c Result"}</Text>
                    <View style={[styles.smallViewStyle, Style.allButtonBorderRadius,{marginLeft:10}]}>
                        <TextInput
                            placeholder={"Enter Values"}
                            value={this.state.lastA1cResultValue}
                            style={[
                                { backgroundColor: "transparent", height: 25, width: 60, fontSize:(this.state.lastA1cResultValue && this.state.lastA1cResultValue.length>0)?14: 10,color:"black" }]}
                            onChangeText={(text) => {
                                if(this.numberValidation(text)){
                                this.setState({ lastA1cResultValue: text })
                                }
                            }}
                        />
                        <View style={{ height: 21, borderLeftColor: "#888888", borderLeftWidth: 1, justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center' }}>
                            <Text style={{ fontSize: 13, marginLeft: 10 ,color:"black" }}>{"%"}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ marginLeft: 10, fontSize: 14 }}>{"Date"}</Text>
                    <View style={[styles.smallViewStyle, Style.allButtonBorderRadius]}>
                        <DatePicker
                            
                            onChange={(date) => {
                                var fulldate = new Date(date);
                                var converted_date = moment(fulldate).format("YYYY-MM-DD");

                                this.setState({ lastA1cResultDate: converted_date });
                            }
                            }
                            defaultValue={""}
                            placeholder={""}
                            format={"DD/MM/YYYY"}
                            suffixIcon={null}
                            value={
                                this.state.lastA1cResultDate
                                    ? moment(this.state.lastA1cResultDate)
                                    : this.state.lastA1cResultDate
                            }
                            // value={
                            //     this.state.lastA1cResultDate ?
                            //         moment(this.state.lastA1cResultDate, "DD/MM/YYYY") :
                            //         ""
                            // }
                            //this.state.mandatoryInformation.dob
                            style={{
                                height: 21,
                                width:105,
                                
                            }}
                        />
                    </View>
                </View>
            </View>
        )
    }

    numberValidation(num){
        return !/[^.[0-9]]*/.test(num);
      }


    renderTextBoxWithLabelRow() {
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.historyYesOrNOSectionRowView, Style.allButtonBorderRadius, { marginTop: 10 }]}>
                <Text style={{ marginLeft: 20, fontSize: 14, flex: 0.6 }}>{"Usual Result "}</Text>
                <View style={[{
                    flexDirection: 'row', borderWidth: 0.1, flex: 0.4, borderColor: 'white', shadowColor: "#090F36",
                    shadowOffset: { width: 1, height: 1 },
                    shadowOpacity: 0.4, shadowRadius: 3,
                    elevation: 5,
                }, Style.allButtonBorderRadius]}>
                    <TextInput
                        placeholder={"Enter Values"}
                        value={this.state.usualResultValue}
                        style={[
                            {
                                // paddingLeft:15,
                                backgroundColor: "transparent", height: 25,
                                width: 80, fontSize:(this.state.usualResultValue && this.state.usualResultValue.length > 0) ?14: 10,color:"black"
                            }]}
                        onChangeText={(text) => {
                            if(this.numberValidation(text)){
                            this.setState({
                                usualResultValue: text
                            })}
                        }}

                    />
                    <View style={{ height: 21, borderLeftColor: "#888888", borderLeftWidth: 1, justifyContent: 'center', alignItems: 'center', alignContent: "flex-end", alignSelf: 'flex-end' }}>
                        <Text style={{ fontSize: 13, marginLeft: 10 }}>{"mg/dl"}</Text>
                    </View>
                </View>
            </View>
        )
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        // console.log(JSON.stringify(selectedValue))
        var data= this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    onPressAddButton() {
        var states = this.state;
        var getTreatment = []

        if (states.treatedOfDiabetic.length > 0) {
            for (var i = 0; i < states.treatedOfDiabetic.length; i++) {
                getTreatment.push(states.treatedOfDiabetic[i].value)
            }
        }


        var data={}
        if(this.state.newToDiagnosis){
            data={
                "patient_id": states.patientAppointment.patient_id,
                "new_diabetes_diagnosed": states.newToDiagnosis,
                "diabetes_duration": null,
                "diabetes_family_history":states.selectedfamilyDiabetic && states.selectedfamilyDiabetic.value ? states.selectedfamilyDiabetic.value : "",
                "diabetes_type": states.typesDiabetic  && states.typesDiabetic.value ? states.typesDiabetic.value : "",
                "treatment_method": "",
                "blood_glucose_check": false,
    
                "bg_check_frequency": "",
                "bg_check_meter":"",
                "bg_usual_result": "",
    
                "last_a1c_result": null,
                "last_ac1_date": null ,
                "low_blood_glucose": false,
                "low_bg_date_time": ""
            }
        }else{
            data = {
                "patient_id": states.patientAppointment.patient_id,
                "new_diabetes_diagnosed": states.newToDiagnosis,
                "diabetes_duration": states.selectedYearsOfDiabetic && states.selectedYearsOfDiabetic.value ?  states.selectedYearsOfDiabetic.value :"",
                "diabetes_family_history":states.selectedfamilyDiabetic && states.selectedfamilyDiabetic.value ? states.selectedfamilyDiabetic.value : "",
                "diabetes_type": states.typesDiabetic  && states.typesDiabetic.value ? states.typesDiabetic.value : "",
                "treatment_method": getTreatment.toString() ? [getTreatment.toString()] : [],
                "blood_glucose_check": states.doYouCheckBG,
    
                "bg_check_frequency": states.doYouCheckBGDetails,
                "bg_check_meter":states.selectedfNameOfBGMeter && states.selectedfNameOfBGMeter.value?states.selectedfNameOfBGMeter.value:null,
                "bg_usual_result": states.usualResultValue,
    
                "last_a1c_result": states.lastA1cResultValue ? states.lastA1cResultValue :null,
                "last_ac1_date": states.lastA1cResultDate ? moment(states.lastA1cResultDate).format("YYYY-MM-DD") : null,
                "low_blood_glucose": states.getLowBG,
                "low_bg_date_time": states.getLowBGDetails
            }
        }

        

        var service_url = Constants.DIABETIC_HISTORY;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDiabeticHistorySuccess,
            this.saveDiabeticHistoryFailure
        );

    }

    saveDiabeticHistorySuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.props.refreshRighSideComponent(pageName)
            this.clearAllData()
        } else {
            this.props.showResposeValue("error", success.message)
        }
        // console.log("success ++++++++++++++++>> " + JSON.stringify(success))
    }
    saveDiabeticHistoryFailure = error => {
        this.props.showResposeValue("error", error.message)
    }


    clearAllData() {
        var states = this.state;

        states["newToDiagnosis"] = false
        states["selectedYearsOfDiabetic"] = {}
        states["selectedfamilyDiabetic"] = {}
        states["doYouCheckBG"] = false
        states["doYouCheckBGDetails"] = ""

        states["usualResultValue"] = ""
        states["lastA1cResultValue"] = ""
        states["lastA1cResultDate"] = ""
        states["getLowBG"] = false
        states["getLowBGDetails"] = ""

        states["selectedfNameOfBGMeter"] = {}

        states["typesDiabetic"] = {}
        states["treatedOfDiabetic"] = []
        this.setState({ states })
    }

    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Diabetic History"} clearAllData={this.clearAllData.bind(this)} />
                <HistoryYesOrNoSection
                    selectedValue={this.state.newToDiagnosis}
                    label={"New to diagnosis?"}
                    detailsOfYesValue={this.state.newToDiagnosisDetails}
                    onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                    sectionKey={"newToDiagnosisDetails"}
                    showDetailsText={false}
                />

                {
                    !this.state.newToDiagnosis ?
                        this.renderLabelWithButtonGroup(
                            "How many years have you had diabetes?",
                            "selectedYearsOfDiabetic",
                            this.state.selectedYearsOfDiabetic,
                            "showDiabeticYearsNewButtonFlag",
                            this.state.showDiabeticYearsNewButtonFlag,
                            this.state.yearsOfDiabeticList) : null}


                {this.renderLabelWithButtonGroup(
                    "Who in your family has/had diabetes?",
                    "selectedfamilyDiabetic",
                    this.state.selectedfamilyDiabetic,
                    "showFamilyDiabeticListNewButtonFlag", this.state.showFamilyDiabeticListNewButtonFlag, this.state.familyDiabeticList)}

                {this.renderLabelWithButtonGroup(
                    "What type of diabetes do you have?",
                    "typesDiabetic",
                    this.state.typesDiabetic,
                    "", "", this.state.typesDiabeticList)}

                {
                    !this.state.newToDiagnosis ?
                        this.renderLabelWithButtonGroup(
                            "How is your diabetes treated?",
                            "treatedOfDiabetic",
                            this.state.treatedOfDiabetic,
                            "", "", this.state.treatedOfDiabeticList) : null}

                {
                    !this.state.newToDiagnosis ?

                        <View style={{ marginTop: 10 }}>
                            <HistoryYesOrNoSection
                                selectedValue={this.state.doYouCheckBG}
                                label={"Do you check your blood glucose?"}
                                detailsOfYesValue={this.state.doYouCheckBGDetails}
                                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                                sectionKey={"doYouCheckBGDetails"}
                                showDetailsText={true}
                                placeholder="How Often?"
                                maxLength={100}
                            />
                        </View>
                        : null
                }

                {
                    !this.state.newToDiagnosis ?
                        <View>
                            {this.renderLabelWithButtonGroup(
                                "Name of the blood glucose meter",
                                "selectedfNameOfBGMeter",
                                this.state.selectedfNameOfBGMeter,
                                "showNameOfBGMeterNewButtonFlag", this.state.showNameOfBGMeterNewButtonFlag, this.state.nameOfBGMeter)}

                            {this.renderTextBoxWithLabelRow()}
                            {this.renderLastA1cResult()}

                            <View style={{ marginTop: 10, marginBottom: 20 }}>
                                <HistoryYesOrNoSection
                                    selectedValue={this.state.getLowBG}
                                    label={"Do you get low blood glucose?"}
                                    detailsOfYesValue={this.state.getLowBGDetails}
                                    onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                                    sectionKey={"getLowBGDetails"}
                                    showDetailsText={true}
                                    placeholder="At what time of day or night and details?"
                                    maxLength={100}
                                />
                            </View>
                        </View>
                        : null
                }
                

                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({

    smallViewStyle: {
        flexDirection: 'row', borderWidth: 0.1, borderColor: 'white', shadowColor: "#090F36",
        shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.4, shadowRadius: 3,
        elevation: 5, height: 25
    },

    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
});
