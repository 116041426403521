import React, { Component } from 'react';
import {
  Alert,
  View,
  TouchableOpacity,
  Text,
  Image,
  Platform
} from "react-native";
import Style from "../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import HomeScreen from "../ui/doctor/common/Home";
import CreatePatient from "../ui/doctor/common/CreatePatient";
import DoctorNotes from "../ui/doctor/opthamology/DoctorNotes";
import { color } from "../styles/Color";
import AsyncStorage from "../AsyncStorage";

import Billing from "../../src/ui/doctor/common/billing/AllTransactions";
import Registry from "../../src/ui/doctor/common/Registry";
// import BillingScreen from './doctor/opthamology/billing/BillingScreen';

const platform = Platform.OS;

export default class Base extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHome: true,
      isBilling: false,
      isMessage: false,
      isForms: false,
      isRegistry: false,
      // isBillingScreen:false
    };
  }

  componentDidMount() {}

  _showAlert(message, btnText) {
    Alert.alert("", message, [{ text: btnText, onPress: () => {} }], {
      cancelable: false
    });
  }

  _showAlertWIthCallBack(message, btnText, btnAction) {
    Alert.alert("", message, [{ text: btnText, onPress: () => btnAction() }], {
      cancelable: false
    });
  }

  _showAlertTwoButtons(message, btnText1, btn1Action, btnText2, btn2Action) {
    Alert.alert(
      "",
      message,
      [
        { text: btnText1, onPress: () => btn1Action() },
        { text: btnText2, onPress: () => btn2Action() }
      ],
      { cancelable: false }
    );
  }

  _showProgress() {}
  _hideProgress() {}

  _logoutFunction = async () => {
    await AsyncStorage.removeItem("loggedIn");
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("service_type");
    await AsyncStorage.removeItem("doctorName");
    await AsyncStorage.removeItem("doctorProfile");

    this.props.navigation.navigate("auth");
  };

  btnClickAction = key => {
    var home = false;
    var billing = false;
    var message = false;
    var forms = false;
    var registry = false;
    // var billingScreen = false

    if (key === "home") {
      home = true;
    } else if (key === "bill") {
      billing = true;
    } else if (key === "message") {
      message = true;
    } else if (key === "forms") {
      forms = true;
    // } else if (key === "billingScreen") {
    //   billingScreen = true;
    } else {
      registry = true;
    }

    var fields = this.state;
    fields["isHome"] = home;
    fields["isBilling"] = billing;
    fields["isMessage"] = message;
    fields["isForms"] = forms;
    fields["isRegistry"] = registry;
    // fields["isBillingScreen"] = billingScreen;
    this.setState({ fields });
  };

  renderImgBtn = (img, title) => {
    return (
      <View style={{ width: 100, height: 100, alignItems: "center", paddingRight: 10 }}>
        <Image
          source={img}
          style = {{
            height: 40,
            width: 40
          }}
          resizeMode = "contain"
        />
        <Text style={{ fontSize: 10, color: "#7C7C7C" , paddingLeft: 0, textAlign: "center"}} numberOfLines = {2}>{title}</Text>
      </View>
    );
  };

  render() {
    return (
      <View style={{ flexDirection: "row" }}>
        <View style={[Style.leftBarView, {paddingTop: 30}]}>

        <TouchableOpacity onPress={() => this._logoutFunction()}>
          <View style = {{ width: 75, height: 75, borderRadius: 38, backgroundColor: color.white, alignItems: "center", justifyContent: "center"}}>
            <Image
            source = {require("../../assets/images/sample_profile.png")}
            style = {{ height: 70, width: 70, borderRadius: 35}}
          />
          </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => this.btnClickAction("home")} style = {{ marginTop: 50}}>
          {this.renderImgBtn(this.state.isHome
                    ? require("../../assets/images/opthamology/tab_home_true.png")
                    : require("../../assets/images/opthamology/tab_home_false.png"), "Home")}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.btnClickAction("bill")}> 
          {/* billingScreen */}
          {this.renderImgBtn(
            this.state.isBilling
                    ? require("../../assets/images/opthamology/tab_billing_true.png")
                    : require("../../assets/images/opthamology/tab_billing_false.png"), "Billing"
          )}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.btnClickAction("message")}>
          {this.renderImgBtn(this.state.isMessage
                    ? require("../../assets/images/opthamology/tab_message_true.png")
                    : require("../../assets/images/opthamology/tab_message_false.png"), "Message")}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.btnClickAction("forms")}>
          {this.renderImgBtn(
            this.state.isForms
                    ? require("../../assets/images/opthamology/tab_forms_true.png")
                    : require("../../assets/images/opthamology/tab_forms_false.png"), "Forms & Certificate"
          )}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.btnClickAction("registry")}>
          {this.renderImgBtn(this.state.isRegistry
                    ? require("../../assets/images/opthamology/tab_registry_true.png")
                    : require("../../assets/images/opthamology/tab_registry_false.png"), "Registry")}
          </TouchableOpacity>
        </View>
        {/* <HomeScreen /> */}
        {this.state.isHome ? <HomeScreen /> : 
        this.state.isBilling ? <Billing/> : 
        this.state.isRegistry ? <Registry/> : 
        // this.state.isBillingScreen ? <BillingScreen/> : 
        <DoctorNotes />}
      </View>
    );
  }
}
