import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Platform,
  Picker,
  StyleSheet
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import AsyncStorage from '../../../AsyncStorage';


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class PatientTypeList extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data: {},
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isClear: false,
      isClearInt: 0,
      selectedPatientType: "New",
      patientvalueData: [],
      totalPatientReportData: [],
      valueData: [],
      isAdminAccess: false
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getPatientTypeList()
  }
  getPatientTypeList() {
    var serviceUrl = Constants.PRINT_PATIENT_TYPELIST + '?appointment_to_date=' + this.state.filterData.toDate + '&appointment_from_date=' + this.state.filterData.fromDate + '&patient_type=' + this.state.selectedPatientType
    this.setState({
      isClear: false
    })

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.PrintListSuccess,
      this.ChangeStatusFailure
    );
  }
  PrintListSuccess = (response) => {
    if (response) {
      var totalReport = []
      if (response.data && response.data.new_patient_list) {
        (response.data.new_patient_list).map((item, index) => (
          totalReport.push(item)
        ))
      }
      if (response.data && response.data.review_patient_list) {
        (response.data.review_patient_list).map((item, index) => (
          totalReport.push(item)
        ))
      }
      if (response.data && response.data.established_patient_list) {
        (response.data.established_patient_list).map((item, index) => (
          totalReport.push(item)
        ))
      }
      this.setState({
        patientvalueData: response.data,
        totalPatientReportData: totalReport,
      })
      //   const file = new Blob(
      //     [response.data],
      //     { type: 'application/pdf' });
      //   const fileURL = URL.createObjectURL(file);
      // //  console.log(file)
      //   window.open(fileURL);
    }
  };
  ChangeStatusFailure = error => {

    console.log('error', error.message);
  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  onPressButton(key, value) {
    if (key == "save") {
      this.getPatientTypeList()
    }
    else if (key == "clear") {
      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      var states = this.state
      // states['valueData'] = []
      this.setState({
        data: {},
        filterDate: {},
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        isClear: true,
        isClearInt: 0,
        selectedPatientType: "New",
      }, () => {
        this.getPatientTypeList()
        this.setState({
          isClear: false
        })
      })


    }
    else if (key == "print") {
      //   this.printBillingList()
      this.PrintPatienToken()
    }
  }

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }

  PrintPatienToken = (key) => {

    console.log(key, 'key')
    var serviceUrl = Constants.PRINT_PATIENT_TYPELIST + '?appointment_to_date=' + this.state.filterData.toDate + '&appointment_from_date=' + this.state.filterData.fromDate + '&patient_type=' + this.state.selectedPatientType + '&export=pdf';
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );

  };


  getPrintSuccess = response => {

    if (response) {

      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {

    console.log(error);
  };

  printPatientCategoryReport() {
    this.setState({
      patientvalueData: [],
      totalPatientReportData: [],
    })
    const serviceUrl = Constants.PRINT_PATIENT_TYPELIST + '?appointment_to_date=' + this.state.filterData.toDate + '&appointment_from_date=' + this.state.filterData.fromDate + '&patient_type=' + this.state.selectedPatientType
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.PrintListSuccess,
      this.PrintListFailure,
      //"pdf"
    );
  }

  loadPatientList = (patList) => {
    if (patList && patList.length > 0) {
      return (
        patList.map(name => {
          return (
            <View style={{ flexDirection: "row", paddingVertical: '0.8vw', width: "100%", borderBottomWidth: 1, borderBottomColor: color.lightGray, marginLeft: 20 }}>
              <Text style={{ fontSize: '1vw', flex: 0.25 }}>{name.name}</Text>
              <Text style={{ fontSize: '1vw', flex: 0.25 }}>{name.service_name}{name.account_num}</Text>
              <Text style={{ fontSize: '1vw', flex: 0.25 }}>{name.age ? name.age : "-"}/{name.gender ? name.gender.toUpperCase() : "-"}</Text>
              <Text style={{ fontSize: '1vw', flex: 0.25 }}>{name.mob_number}</Text>
              <Text style={{ fontSize: '1vw', flex: 0.25 }}>{name.appointment_date ? name.appointment_date : "-"}</Text>
            </View>)
        }
        )
      )
    }
  }
  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "64%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>

            <View style={{ width: "100%", marginTop: 10 }} >
              <CommonHistorySectionHeader
                heading1={"Patient Name"}
                heading2={"UHID"}
                heading3={"Age/Gender"}
                heading4={"Mob No"}
                heading5={"Appointment Date"}
                noOfColumn={5}
                columnSize={[0.20, 0.20, 0.20, 0.20, 0.20]}
              />

              <View>
                {(this.state.patientvalueData && this.state.patientvalueData.length || this.state.totalPatientReportData && this.state.totalPatientReportData.length) ?
                  <View>

                    {this.state.totalPatientReportData && this.state.totalPatientReportData && this.state.selectedPatientType === "All" ?
                      <View>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ height: '70vh', overflowY: 'scroll' }}>
                          <View style={{ height: '70vh' }}>
                            {this.state.totalPatientReportData.length > 0 ?
                              this.loadPatientList(this.state.valueData)
                              : null
                            }

                          </View>
                        </ScrollView>
                        <View style={{ marginLeft: 20 }}>
                          {this.state.totalPatientReportData && this.state.totalPatientReportData.length > 0 ?
                            <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.totalPatientReportData} />
                            : null

                          }

                        </View>
                      </View>
                      : null
                    }


                    {this.state.patientvalueData && this.state.patientvalueData.new_patient_list && this.state.selectedPatientType === "New" ?
                      <View>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ height: '70vh', overflowY: 'scroll' }}>
                          <View style={{ height: '70vh' }}>
                            {this.state.patientvalueData.new_patient_list.length > 0 ?
                              this.loadPatientList(this.state.valueData)
                              : null
                            }

                          </View>
                        </ScrollView>
                        <View style={{ marginLeft: 20 }}>
                          {this.state.patientvalueData.new_patient_list && this.state.patientvalueData.new_patient_list.length > 0 ?
                            <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.patientvalueData.new_patient_list} />
                            : null

                          }

                        </View>
                      </View>
                      : null
                    }
                    {this.state.patientvalueData && this.state.patientvalueData.established_patient_list && this.state.selectedPatientType === "Established" ?
                      <View>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ height: '70vh', overflowY: 'scroll' }}>
                          {this.state.patientvalueData.established_patient_list.length > 0 ?
                            this.loadPatientList(this.state.valueData)
                            : null
                          }

                        </ScrollView>
                        <View style={{ marginLeft: 20 }}>
                          {this.state.patientvalueData.established_patient_list && this.state.patientvalueData.established_patient_list.length > 0 ?
                            <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.patientvalueData.established_patient_list} />
                            : null

                          }

                        </View>
                      </View>
                      : null
                    }
                    {this.state.patientvalueData && this.state.patientvalueData.review_patient_list && this.state.selectedPatientType === "Review" ?
                      <View>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ height: '70vh', overflowY: 'scroll' }}>
                          {this.state.patientvalueData.review_patient_list.length > 0 ?
                            this.loadPatientList(this.state.valueData)
                            : null
                          }
                        </ScrollView>
                        <View style={{ marginLeft: 20 }}>
                          {this.state.patientvalueData.review_patient_list && this.state.patientvalueData.review_patient_list.length > 0 ?
                            <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.patientvalueData.review_patient_list} />
                            : null

                          }

                        </View>
                      </View>
                      : null
                    }
                  </View> :
                  <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                    <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                  </View>
                }
              </View>
            </View>
          </View>
        </View>

        {/* ============================ */}


        <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, padding: "1vw", }}>
          <View>
            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", zIndex: 1 }}>
                <View style={{ minWidth: '10.98vw' }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    {this.state.isClear && this.state.isClearInt === 0 ? null :
                      this.state.isClear && this.state.isClearInt === 1 ?
                        <CommonDateFilter
                          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                          defaultSelectedDateFilter={"Today"}
                          startDate={this.state.filterData.fromDate}
                          endDate={this.state.filterData.toDate}
                          clearPress={this.state.isClear}
                          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                          filterDate={this.state.filterDate} /> :
                        <CommonDateFilter
                          removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                          defaultSelectedDateFilter={"Today"}
                          startDate={this.state.filterData.fromDate}
                          endDate={this.state.filterData.toDate}
                          clearPress={this.state.isClear}
                          showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                          filterDate={this.state.filterDate} />
                    }
                  </View>
                </View>
              </View>

              <View>
                <Picker style={[Style.pickerView, { marginTop: 20, marginBottom: 10, width: '14.64vw', fontSize: '1vw' }, Style.allButtonBorderRadius]} itemStyle={{}}
                  value={this.state.selectedPatientType}
                  onValueChange={(itemValue) => {
                    this.setState({
                      selectedPatientType: itemValue
                    }, () => {
                      this.printPatientCategoryReport()
                    })
                  }}
                >
                  <Picker.Item style={{ width: '14.64vw', height: '32vh' }} label="All Patients" value="All" />
                  <Picker.Item style={{ width: '14.64vw', height: '32vh' }} label="New Patients" value="New" />
                  <Picker.Item style={{ width: '14.64vw', height: '32vh' }} label="Review Patients" value="Review" />
                  <Picker.Item style={{ width: '14.64vw', height: '32vh' }} label="Established Patients" value="Established" />
                </Picker>
              </View>

              <View style={{ flexDirection: "row", justifyContent: 'space-evenly', zIndex: -1, marginVertical: 20 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        // disable={this.state.isLocked}
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }


              </View>


            </View>
          </View>
        </View >
      </View >
    )
  }
}
const styles = StyleSheet.create({
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '0.7vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw",
  },
});