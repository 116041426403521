import React, { Component } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity
} from "react-native"
import ImagePath from '../../../../../utils/ImagePaths';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import { NumberFormat } from '../../../BaseComponent'
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';


class TotalBills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      BillsCounts: {},
      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate
    }
  }

  componentWillMount() {
    this.getTotalBillsCount()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getTotalBillsCount()
      })
    }
  }

  /* Get Patient Count API Function start */
  getTotalBillsCount() {
    var serviceUrl = Constants.TOTAL_BILLS_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getTotalBillCountSuccess,
      this.getTotalBillCountFailure
    )
  }
  /* Get Patient Count API Function end */


  /* Get Patient Count API success Function start */
  getTotalBillCountSuccess = (success) => {
    if (success.status === "success") {
      this.setState({
        BillsCounts: success.data
      })
    }else{
      this.props.Response(success.message)
    }
  }
  /* Get Patient Count API success Function end */

  /* Get Patient Count API failure Function start */
  getTotalBillCountFailure = (error) => {
    this.props.Response(error.message)
  }
  /* Get Patient Count API failure Function end */

  /* Card View Function start */
  renderTotalItem = (img, count, colors, largewidth = false) => {
    return (
      <View style={[styles.dasboardcardcontentstyle, { background: colors, minWidth:  largewidth ? "17vw" : "10vw"}]}>
        <View>
          {img ?
            <Image
              source={img}
              style={styles.RenderTotalItemImg}
              resizeMode="contain"
            /> : null}
        </View>
        <View>
          <Text style={styles.PatientCount}>{count}</Text>
        </View>
      </View>
    );
  };
  /* Card View Function end */

  /* Export Excel API start*/
  getTotalBillsExcel = () => {
    var serviceUrl =
      Constants.TOTAL_BILLS_CHART +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getTotalPatientsSuccess,
      this.getTotalPatientsFailure,
      "excel"
    );
  }
  getTotalPatientsSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getTotalPatientsFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API end*/

  render() {
    return (
      <View>
        <View style={styles.IconView}>
        <Text style={styles.TotalPatientText}>Total Bill Counts</Text>
          <TouchableOpacity onPress={this.getTotalBillsExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </View>
        <View style={styles.InOutPaientView}>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total OP Bills: "}{this.state.BillsCounts.op_bills ? NumberFormat(this.state.BillsCounts.op_bills) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill01,
                this.state.BillsCounts.op_bills ? NumberFormat(this.state.BillsCounts.op_bills) : 0,
                'linear-gradient(0deg,#36AFD3, #7ED7F1 )'
              )}
            </View>
          </View>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total IP Bills: "}{this.state.BillsCounts.ip_bills ? NumberFormat(this.state.BillsCounts.ip_bills) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill02,
                this.state.BillsCounts.ip_bills ? NumberFormat(this.state.BillsCounts.ip_bills) : 0,
                'linear-gradient(0deg, #D82CCB, #F496ED)'
              )}
            </View>
          </View>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total OT Bills: "}{this.state.BillsCounts.ot_bills ? NumberFormat(this.state.BillsCounts.ot_bills) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill03,
                this.state.BillsCounts.ot_bills ? NumberFormat(this.state.BillsCounts.ot_bills) : 0,
                'linear-gradient(0deg, #E5D151, #DDC215)'
              )}
            </View>
          </View>

        </View>
        <View style={styles.InOutPaientView}>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total Pharmacy Bills: "}{this.state.BillsCounts.pharma_bill ? NumberFormat(this.state.BillsCounts.pharma_bill) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill04,
                this.state.BillsCounts.pharma_bill ? NumberFormat(this.state.BillsCounts.pharma_bill) : 0,
                'linear-gradient(0deg,#330066, #6600cc )'
              )}
            </View>
          </View>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total Lab Bills: "}{this.state.BillsCounts.lab_bill ? NumberFormat(this.state.BillsCounts.lab_bill) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill04,
                this.state.BillsCounts.lab_bill ? NumberFormat(this.state.BillsCounts.lab_bill) : 0,
                'linear-gradient(0deg,#77BA1D, #ACE55E )'
              )}
            </View>
          </View>
          <View style={styles.StatHeadView}>
            <Text style={styles.StatHeadText}
            >{"Total Image Lab Bills: "}{this.state.BillsCounts.img_lab ? NumberFormat(this.state.BillsCounts.img_lab) : 0}</Text>
            <View>
              {this.renderTotalItem(
                ImagePath.Bill05,
                this.state.BillsCounts.img_lab ? NumberFormat(this.state.BillsCounts.img_lab) : 0,
                'linear-gradient(0deg, #ff3300, #ff8533)'
              )}
            </View>
          </View>

        </View>
        <View style={styles.StatHeadView1}>
        <Text style={styles.StatHeadText}
            >{"Total Bills: "}{this.state.BillsCounts.total_bills ? NumberFormat(this.state.BillsCounts.total_bills) : 0}</Text>
          {this.renderTotalItem(
            ImagePath.Bill06,
            this.state.BillsCounts.total_bills ? NumberFormat(this.state.BillsCounts.total_bills) : 0,
            'linear-gradient(0deg, #E3458B, #F479AF)', true
          )}
        </View>
      </View>
    )
  }
}

export default TotalBills;

const styles = StyleSheet.create({
  dasboardcardcontentstyle: {
    flexDirection: "row",
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    backgroundColor: "orange",
    shadowColor: "#090F36",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: '0.26vw',
    height: '5vw',
    minWidth: '10vw',
    paddingVertical: '0.488vw',
    paddingHorizontal: '0.65vw',
    marginTop:'0.3vw'
  },
  RenderTotalItemImg: {
    height: '3vw',
    width: '3vw',
    color:"white"
  },
  StatHeadView: {
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop:'0.7vw',
    marginLeft:'1.3vw'
  },
  StatHeadText: {
    color: "rgb(4, 183, 177)",
    fontSize: '0.8vw',
    alignSelf: 'center',
    fontWeight: '500'
  },
  StatHeadView1: {
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop:'0.7vw'
  },
  PatientCount: {
    fontSize: '2vw',
    color: "white",
    fontWeight: 'bold'
  },
  InOutPaientView: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0'
  },
  TotalPatientView: {
    marginTop: '0',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  TotalPatientText: {
    color: 'rgb(4, 183, 177)',
    fontSize: "1vw",
    fontWeight: 'bold'
  },
  IconView: {
    display: 'flex',
    flexDirection:'row',
    alignItems: 'center',
    justifyContent:'space-between',
    margin: '1vw',
    marginBottom:0
  }

})