import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, ScrollView } from "react-native";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService'

// Time Converter Change 24 hours formate into 12 hours formate ---- Starts
const Time_convert = (Time) => {
    var gettime = Time.split("T")[1]
    var splittime = gettime.split(".")[0]
    var slicetime = splittime.slice(0, 5)
    var time = slicetime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [slicetime];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    } else {
        return "Wrong Time Formate"
    }
    return time
}
// Time Converter Change 24 hours formate into 12 hours formate ---- ends


export default class OTMHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientDetails: this.props.PatientData,
            historyList: []
        }
    }

    componentDidMount() {
        this.getHistoryDetails()
    }

    // Get History Details API Start //

    getHistoryDetails = () => {
        var url = Constants.GET_HISTORY_LIST + "?surgery_id=" + this.state.patientDetails.surgery_request_id

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getHistoryDetailsSuccess,
            this.getHistoryDetailsFailure
        );
    }

    getHistoryDetailsSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                historyList: response.data
            })
        } else {
            console.log("error")
        }
    }

    // Get History Details API Ends //

    render() {
        return (
            <View>
                <View style={styles.HistoryPopupContainer}>
                    <TouchableWithoutFeedback>
                        <View style={styles.PopupHeaderConatiner}>
                            <View style={{ flex: 1 }}>
                                <View style={styles.PopupHeaderWrapper}>
                                    <Text style={styles.HeaderTextStyle}>
                                        History - <Text style={styles.patientNameStyle}>{this.state.patientDetails.patient_name}</Text>
                                    </Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.PopupClose()
                                        }}
                                        style={{ fontSize: "1.95vw" }}
                                    >
                                        <Icon
                                            name="window-close-o"
                                            size={"1.3vw"}
                                            color={color.themeDark}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <View style={[styles.HistoryHeaderContainer]}>
                                        <View style={{ alignItems: "center", width: '18.5%' }} >
                                            <Text style={styles.appointmentTableHeadText}>Updated Date & Time</Text>
                                        </View>
                                        <View style={{ alignItems: "center", width: '22.5%' }} >
                                            <Text style={styles.appointmentTableHeadText}>Rescheduled Date & Time</Text>
                                        </View>
                                        <View style={{ alignItems: "center", width: '15%' }} >
                                            <Text style={styles.appointmentTableHeadText}>Updated By</Text>
                                        </View>
                                        <View style={{ alignItems: "center", width: '15%' }} >
                                            <Text style={styles.appointmentTableHeadText}>From Status</Text>
                                        </View>
                                        <View style={{ alignItems: "center", width: '10%' }} >
                                            <Text style={styles.appointmentTableHeadText}>To Status</Text>
                                        </View>
                                        <View style={{ alignItems: "center", width: '20%' }} >
                                            <Text style={styles.appointmentTableHeadText}>Comments</Text>
                                        </View>
                                    </View>
                                </View>
                                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: "30vw", overflow: "scroll" }}>
                                    {this.state.historyList.length > 0 ? this.state.historyList.map(item => {
                                        return (
                                            <View style={styles.TableBodyContainer}>
                                                <View style={styles.TableBodyWrapper}>
                                                    <View style={{ width: "18.5%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.surgery_date === null ? "Nill" : item.surgery_date.split("T")[0]}
                                                        </Text>
                                                        <Text style={{ fontSize: ".8vw" }}>
                                                            {item.surgery_date === null ? "" : Time_convert(item.surgery_date)}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "22.5%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.reschedule_date === null ? "Nill" : item.reschedule_date.split("T")[0]}
                                                        </Text>
                                                        <Text style={{ fontSize: ".8vw" }}>
                                                            {item.reschedule_date === null ? "" : Time_convert(item.reschedule_date)}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "15%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.updated_by === null ? "-" : item.updated_by}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "15%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.from_status === null ? "-" : item.from_status}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "10%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.from_status === null ? "-" : item.to_status}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "20%", alignItems: "center" }}>
                                                        <Text style={styles.TableBodyText} >
                                                            {item.comment === null ? "-" : item.comment}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    }) : <View>
                                        <Text style={styles.NoRecordsText}>No Records Found</Text>
                                    </View>}
                                </ScrollView>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    HistoryPopupContainer: {
        height: "48.15vw",
        width: "92.95vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    PopupHeaderConatiner: {
        backgroundColor: "white",
        width: "65%",
        borderRadius: "0.65vw",
        padding: "1.30vw",
        marginTop: "-2.5vw"
    },
    PopupHeaderWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "3vw",
        alignItems: "center"
    },
    HeaderTextStyle: {
        color: color.themeDark,
        fontSize: "1.23vw",
        marginTop: "1vw",
        fontWeight: '500',
        height: "3vw"
    },
    TableBodyContainer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        height: '3.3vw',
        paddingHorizontal: ".5vw",
        borderRadius: 4,
    },
    TableBodyWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: "center",
        alignItems: "center"
    },
    TableBodyText: { fontSize: ".9vw" },
    patientNameStyle: {
        color: color.black,
        fontSize: "1.3vw",
        fontWeight: 700
    },
    HistoryHeaderContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: color.appointmentTableHead,
        height: "3.25vw",
        marginTop: ".32vw",
        paddingHorizontal: ".5vw"
    },
    appointmentTableHeadText: {
        fontSize: ".9vw",
        fontWeight: "500",
        textAlign: "center"
    },
    NoRecordsText: {
        fontSize: "1.5vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30vw",
        width: "100%"
    }
});