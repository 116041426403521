import React, { Component } from 'react';
import {
  Alert,
  View,
  Text,
  Picker,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TouchableWithoutFeedback,
  TextInput,
} from 'react-native';
import OpthamologyService from "../../../network/OpthamologyService";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
// import { TextInput } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import Service from "../../../network/Service";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
import { CommonPicker, CommonButton } from './../BaseComponent'
// docorScheduleTimes
import AsyncStorage from "../../../AsyncStorage";

import { Calendar } from 'react-native-calendars';
import { Tooltip, DatePicker, AutoComplete, Input } from 'antd';
import { grey } from '@material-ui/core/colors';
import { styled } from '@material-ui/core';


const dateInstance = new Date();
const today = moment(dateInstance).format("YYYY-MM-DD");

const platform = Platform.OS;

export default class NextAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientTagdata: [],
      durationTypedata: [
        {
          id: 1,
          value: "Days"
        },
        {
          id: 2,
          value: "Weeks"
        },
        {
          id: 3,
          value: "Months"
        },
        {
          id: 4,
          value: "Years"
        },
      ],
      selectedDurationType: {
        id: 1,
        value: "Days"
      },
      showDurationType: false,
      selectedpatientTag: '',
      showpatientTagdata: false,
      durationValue: 0,

      modalVisible: this.props.modalVisible,
      currentTime: "",
      dropDownViewFlag: false,
      getMonth: "",
      getYear: "",
      getDate: 0,
      dropDownView: "",
      day1: 10,
      day2: 11,
      day3: 12,
      day4: 13,
      day5: 14,
      totalMonths: [
        { "month": "January", "totalDays": 31 },
        { "month": "February", "totalDays": 28 },
        { "month": "March", "totalDays": 31 },
        { "month": "April", "totalDays": 30 },
        { "month": "May", "totalDays": 31 },
        { "month": "June", "totalDays": 30 },
        { "month": "July", "totalDays": 31 },
        { "month": "August", "totalDays": 31 },
        { "month": "September", "totalDays": 30 },
        { "month": "October", "totalDays": 31 },
        { "month": "November", "totalDays": 30 },
        { "month": "December", "totalDays": 31 },
      ],
      changedMonthDays: 0,
      chengedMonthInNumber: 0,
      changedDay: 0,
      showNextAppoinmentTimer: false,
      hour12Formet: 0,
      minutes: 0,
      findAmPm: '',

      patientId: this.props.patientId,
      nextAppoinmentatPientDetails: this.props.nextAppoinmentatPientDetails,
      purposeOfVisitsInNextAppoinment: "",
      isNextSchedule: this.props.isNextSchedule,

      request_id: this.props.request_id,
      clinicData: [],
      selectedClinic: "",

      showErrorResponce: "",

      // doctor data
      doctorData: [],
      selectedDoctor: "",
      userType: "",

      showPickerDropDownFlag: false,
      selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD"),

      selectedDocorScheduleTimes: {},
      docorScheduleTimes: [],
      markedDates: {},
      isCreateClicked: false,
      purposeOfVisitList: [],
      clinicType: "",
      showNursedata: false,
      selectedNurseTag: '',
      staffData: [],
      showPatientAppointmentType: false,
      selectedPatientAppointmentType: "",
      AppointmentTypeData: [],
      selectedDepartment: "",
      showDepartmentType: false,
      departmentList: [],
      originalDoctorList: [],
      isQMSEnable: false,
      showDoctorPickerDropDownFlag: false,
      tokenGenerationType: "",
      popListData:[]
    };

    var today = new Date();
    var date = today.getDate();
    var month = today.getMonth();
    var year = today.getFullYear();
    for (let i = 0; i < this.state.totalMonths.length; i++) {
      if (month === i) {
        this.state.chengedMonthInNumber = i + 1;
        this.state.changedMonthDays = this.state.totalMonths[i].totalDays;
        this.state.getMonth = this.state.totalMonths[i].month;
        this.state.getYear = year;
        this.state.getDate = date;
        this.state.day1 = date - 2;
        this.state.day2 = date - 1;
        this.state.day3 = date;
        this.state.day4 = date + 1;
        this.state.day5 = date + 2;
        this.state.changedDay = date;
      };
    }
  }

  async componentDidMount() {
    this.chegeAppoinmentModalTime()
    const userType = await AsyncStorage.getItem("service_type");
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
      var isOPT = false
      if(loggedInDataObj.clinic_type){
        isOPT = loggedInDataObj.clinic_type.is_opt_clinic
      }else{
        isOPT = false;
      }
      this.setState({
        clinicType: isOPT,
        isQMSEnable: loggedInDataObj.is_qms_enabled,
        tokenGenerationType: loggedInDataObj.token_gen_type
      })

    this.setState({ userType: userType }, () => {

      if (userType == Constants.ROLE_FO) {
        this.getDoctor();
        this.getStaffList();
      } else {
        this.getSchuduleTime()

        this.getClinic()
      }
    });

    // this.getpatientTag();
    let popData = await AsyncStorage.getItem("PurposeOfVisit")
    let popListData = JSON.parse(popData)
    this.setState({
      popListData:popListData
    },()=>console.log(this.state.popListData,"kjh8eufywe"))

  }

  getpatientTag = () => {
    var service_url = ""
    if(this.state.userType === Constants.ROLE_FO){
      service_url = Constants.CLINIC_PATIENT_TAG
    }else{
      service_url = Constants.CLINIC_PATIENT_TAG + "?clinic_id=" + this.state.selectedClinic.id
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getpatientTagSuccess,
      this.getpatientTagFailure
    );

  }

  getAppointmentTypedatas = () => {
    var service_url = Constants.FO_APPOINTMENT_TYPE_LIST + "?clinic_id=" + this.state.selectedClinic.id

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAppointmentTypeSuccess,
      this.getAppointmentTypeFailure
    );

  }

  getAppointmentTypeSuccess = (response) => {

    let tempList = [];
    response.data && response.data.length > 0 && response.data.map((item) => {
      tempList.push({ id: item.id, value: item.appoinment_type })
    });

    this.setState({
      ["AppointmentTypeData"] : tempList
    });
  }
  
  getAppointmentTypeFailure = error => {
    console.log("Get Tag list error response");
  };

  getpatientTagSuccess = (response) => {

    let tempList = [];
    response.data && response.data.length > 0 && response.data.map((item) => {
      tempList.push({ id: item.id, value: item.patient_tag_name })
    });

    this.setState({
      ["patientTagdata"] : tempList
    });
  }
  
  getpatientTagFailure = error => {
    console.log("Get Tag list error response");
  };

  convertedDate(date) {
    var fulldate = ""
    if (date) {
      fulldate = new Date(date);
    } else {
      fulldate = new Date();
    }
    // var fulldate = new Date(date);
    var converted_date = moment(fulldate).format("YYYY-MM-DD");
    return converted_date
  }



  getAppointmentCount(date) {

    var service_url = Constants.PROFILE_APPOINTMENT_COUNT + "?search_date=" + date;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAppointmentCountSuccess,
      this.getAppointmentCountFailure
    );
  }
  getAppointmentCountSuccess = success => {
    // console.log("success &&&& >" + JSON.stringify(success))
    if (success.status == "success") {
      // var data = {
      //     "telemedicine": {
      //         "s1_count": 1,
      //         "s1_start_time": " 7:00 AM",
      //         "s1_end_time": " 8:00 AM",
      //         "s2_count": 1,
      //         "s2_start_time": "10:00 AM",
      //         "s2_end_time": "11:00 AM",
      //         "s3_count": 0,
      //         "s3_start_time": null,
      //         "s3_end_time": null
      //     },
      //     "op": [
      //         {
      //             "clinic_name": "Clinic A",
      //             "appointment_count": 1
      //         },
      //         {
      //             "clinic_name": "Clinic B",
      //             "appointment_count": 1
      //         },
      //         {
      //             "clinic_name": "Clinic C",
      //             "appointment_count": 1
      //         }
      //     ]
      // }
      this.setState({
        getAppointmentCountDetails: success.data
        // getAppointmentCountDetails: data
      })
    }
  }
  getAppointmentCountFailure = error => { }
  chegeAppoinmentModalTime() {
    if (!this.state.showNextAppoinmentTimer) {
      var today = new Date();
      var hours = parseInt(today.getHours());
      var minutes = today.getMinutes()
      if (hours > 12) {
        this.state.hour12Formet = hours - 12;
        this.state.hour12Formet = this.state.hour12Formet < 10 ? '0' + this.state.hour12Formet : this.state.hour12Formet;
      } else {
        this.state.hour12Formet = hours;
      }

      // var amPm= hours >= 12 ? 'pm':'am';
      minutes = minutes < 10 ? '0' + minutes : minutes;
      this.state.minutes = minutes;

      this.state.findAmPm = hours >= 12 ? 'pm' : 'am';
    }
  }

  getClinic = () => {
    var serviceUrl =
      Constants.HOME_GET_DOCTOR_CLINICS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getClinicSuccess,
      this.getClinicFailure
    );
  };

  getClinicSuccess = async response => {
    //console.log("Get clinic list success response");
    //console.log(response);

    if (response.detail) {
      // this._logoutFunction();
    } else {

      var tempList = [];
      let default_state = { id: "", value: "Select Clinic"}
      tempList.push(default_state)
      response.data && response.data.length > 0 && response.data.map((item) => {
        tempList.push({ id: item.clinic_id, value: item.clinic_name })
      });

      var fields = this.state;
      fields["clinicData"] = tempList;

      if (response.data.length > 0 && ((this.state.userType != "FO") || (this.state.userType === "FO" && !this.state.isQMSEnable))) {
        var selectedValue = await AsyncStorage.getItem("Selected_Clinic_Doctor");
        if (selectedValue) {
          fields["selectedClinic"] = JSON.parse(selectedValue);
        } else {
          await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(tempList[0]));
          fields["selectedClinic"] = tempList[0];
          this.setState({ fields })
        }
      }

      this.setState({
        fields
      }, ()=>{ this.getAppointmentTypedatas(); });

      // this.getAppointments(moment().format("YYYY-MM-DD"));
      // this.getAppointmentStat();
    }
  };

  getClinicFailure = error => {
    // console.log("Get clinic list error response");
    console.log(error);
  };

  getDoctor = () => {
    var serviceUrl =
      Constants.HOME_GET_CLINIC_DOCTORS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorSuccess,
      this.getDoctorFailure
    );
  };
  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  getDoctorSuccess = async (response) => {
    // console.log("Get doctor list success response");
    //console.log(response);

    if (response.detail) {
      this._logoutFunction();
    } else {

      var tempList = [];
      var department_list = []
      var speciality_list = []
      var data = response.data
      let default_data = { id: "", value: "Select Specialization" }
      department_list.push(default_data)
      data && data.length > 0 && data.map((item) => {
        if(item.first_name=="Nursing"){
          tempList.push({ id: item.doctor_id, value: item.first_name, speciality_id: ""  })
        }else{
          tempList.push({ id: item.doctor_id, value: item.first_name + " " + item.initial, speciality_id: item.speciality_id })
          department_list.push({ id: item.speciality_id, value: item.speciality_name })
          speciality_list = this.removeDuplicate(department_list)
        }
      });
      var fields = this.state;
      fields["doctorData"] = tempList;
      fields["originalDoctorList"] = tempList
      fields["departmentList"] = speciality_list  

      // if (response.data.length > 0) {
      //   var selectedValue = await AsyncStorage.getItem("Selected_Clinic_Doctor");
      //   var getSelectedValue = JSON.parse(selectedValue)
      //   if (selectedValue && getSelectedValue.id) {
      //     // fields["selectedDoctor"] = JSON.parse(selectedValue);
      //   } else {
      //     await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(tempList[0]));
      //     // fields["selectedDoctor"] = tempList[0];
      //   }
      // }

      this.setState({
        fields
      }, () => {
        this.getSchuduleTime()
      });

      // this.getAppointments(moment().format("YYYY-MM-DD"));
      // this.getAppointmentStat();
    }
  };

  getDoctorFailure = error => {
    //console.log("Get doctor list error response");
    console.log(error);
  };
  getStaffList = () => {
    var serviceUrl =
      Constants.HOME_GET_CLINIC_STAFFS;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getStaffListSuccess,
      this.getStaffListFailure
    );
  };

  getStaffListSuccess = async (response) => {
    let tempList = [];
    response.data && response.data.length > 0 && response.data.map((item) => {
      tempList.push({ id: item.staff_id, value: item.first_name })
    });

    this.setState({
      ["staffData"] : tempList
    });
  };

  getStaffListFailure = error => {
    //console.log("Get doctor list error response");
    console.log(error);
  };



  async changePickerDropDownValue(flag, selectedValue, pickerKey) {
    if (pickerKey == "Doctor" || pickerKey === "FO" || pickerKey === "NotQMSFO") {
      var states = this.state;
      states["showDoctorPickerDropDownFlag"] = flag
      if (selectedValue && pickerKey == "Doctor") {
        await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
        states["selectedClinic"] = selectedValue
      }
      if (selectedValue && (pickerKey === "FO" || pickerKey === "NotQMSFO")) {
        await AsyncStorage.setItem("Selected_Clinic_Doctor", JSON.stringify(selectedValue));
        states['selectedDoctor'] = selectedValue
       for( let i = 0; i < states.departmentList.length; i++ ) {
        if(selectedValue.speciality_id === states.departmentList[i].id) {
          states["selectedDepartment"] = states.departmentList[i]
          this.setState({ states })
        } else {

        }
       } 
      }
    } else if (pickerKey == "durationType"){
      var states = this.state;
      states["showDurationType"] = flag
      if (selectedValue && this.state.selectedpatientTag != selectedValue ) {
        states["selectedDurationType"] = selectedValue
      }
    }
    else if (pickerKey == "PatientTag") {
      var states = this.state;
      states["showpatientTagdata"] = flag

      if (selectedValue && this.state.selectedpatientTag != selectedValue ) {
        states['selectedpatientTag'] = selectedValue
      } else if(states.selectedpatientTag === selectedValue) {
        states['selectedpatientTag'] = selectedValue        
      } 
      if(this.state.showpatientTagdata) {
        this.getpatientTag();
      }
         
    }else if(pickerKey == "Nurse"){
      var states = this.state;
      states["showNursedata"] = flag

      if (selectedValue) {
        states['selectedNurseTag'] = selectedValue
    }
  }else if (pickerKey == "PatientType" || pickerKey === "PatientTypeDoctor" || pickerKey === "NotQMSPatientType"){
  var states = this.state;
  states["showPickerDropDownFlag"] = flag
    var states = this.state;
    states["showPatientAppointmentType"] = flag
    if (selectedValue && this.state.selectedPatientAppointmentType != selectedValue ) {
      states['selectedPatientAppointmentType'] = selectedValue
    } else if(states.selectedPatientAppointmentType === selectedValue) {
      states['selectedPatientAppointmentType'] = selectedValue        
    }  
  } else if(pickerKey == "departmentType"){
    var states = this.state
    states["showDepartmentType"] = flag
    if (selectedValue && this.state.selectedDepartment != selectedValue ) {
      states["selectedDepartment"] = selectedValue
    } 
    var doctor_list = []
    for( let i = 0; i < states.originalDoctorList.length; i++ ) {
      if(selectedValue.id === states.originalDoctorList[i].speciality_id) {
        doctor_list.push(states.originalDoctorList[i])
        states["doctorData"] = doctor_list
        states["selectedDoctor"] = ""
        this.setState({ 
          states,
        }, () => {
          this.renderClinicView()
        })
      } else if(selectedValue.value == "Select Specialization") {
          this.getDoctor()
      }
    } 
  }

    this.setState({
      states
    }, () => {
      if (pickerKey == "durationType"){
      this.changeReviewDate()
      }
      // this.props.changeStateValues({}, {}, "", this.state.selectedClinic)
      // var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
      // this.getAppointments(selectedDateAndTime);
    })
    this.getSchuduleTime()

    console.log(this.state.selectedPatientAppointmentType.value)
  }


  showNextAppoinmentModal() {
    this.changeAppoinmentModalTime()
    return (
      <View style={{ position: 'absolute' }}>
        {this.state.modalVisible && this.state.nextAppoinmentatPientDetails && this.state.nextAppoinmentatPientDetails.status === "success" && this.state.nextAppoinmentatPientDetails.data ?

          <View style={{ position: 'absolute', zIndex: -1, height: screenHeight, width: screenWidth, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <TouchableWithoutFeedback onPress={() => { this.setState({ modalVisible: true, showNextAppoinmentTimer: false, dropDownViewFlag: false }) }}>
              <View style={{ backgroundColor: "white", position: 'absolute', zIndex: 2, width: '60%', borderRadius: 35, paddingTop: 20, paddingBottom: 20, paddingLeft: 40, paddingRight: 40 }}>
                {/* <View> */}
                <View><Text style={{ color: 'black', fontSize: 19, }}>Create Appointment</Text></View>
                <View style={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                  <View style={{ flex: 0.35 }}>
                    {/* { this.state.userType == Constants.ROLE_FO ?
                      <Picker
                      selectedValue={this.state.selectedDoctor?this.state.selectedDoctor['id']:null}
                      style={Style.pickerRoundCornerView}
                      itemStyle={Style.pickerFont}
                      onValueChange={(itemValue)=>{
                        alert(JSON.stringify(itemValue))
                      }}
                    >
                      <Picker.Item key={-1} label={"Select Doctor"} value={0} />
                      {this.state.doctorData && this.state.doctorData.map((i, index) => (
                        <Picker.Item key={index} label={i.value} value={i.id} />
                      ))}
                    </Picker>:
                    <Picker
                      selectedValue={this.state.selectedClinic['id']}
                      style={{ borderRadius: 20, height: 35, paddingLeft: 10 }}
                      itemStyle={{ fontSize: 10 }}
                      onValueChange={(itemValue)=>{
                        alert(JSON.stringify(itemValue))
                      }}
                    >
                      <Picker.Item key={-1} label={"Select Clinic"} value={0} />
                      {this.state.clinicData.map((i, index) => (
                        <Picker.Item key={index} label={i.value} value={i} />
                      ))}
                    </Picker>} */}

                    <View style={{ maxWidth: "80%" }}>
                      {
                        this.state.userType == Constants.ROLE_FO ?
                          <CommonPicker
                            showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                            changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                            selectedValue={this.state.selectedDoctor}
                            arrayOfData={this.state.doctorData}
                            pickerKey="FO"
                          /> :
                          <CommonPicker
                            showPickerDropDownFlag={this.state.showPickerDropDownFlag}
                            changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                            selectedValue={this.state.selectedClinic}
                            arrayOfData={this.state.clinicData}
                            pickerKey="Doctor"
                          />

                      }

                    </View>
                  </View>
                  {/* <View></View> */}
                  {/* <View style={{ flex:0.4, alignSelf: "center" }} >{this.showCalendar("NAC")}</View> */}
                  {/* <View style={{ flex:0.3, justifyContent: "flex-end" }}>{this.timePicker()}</View> */}
                  {this.showCalendar("NAC")}
                  {this.timePicker()}
                </View>

                {/* <View style={{ flexDirection: 'row', width: "90%", alignSelf: 'center', zIndex: -1 }}> */}
                <View style={{ flexDirection: 'row', zIndex: -1 }}>
                  <View style={{ flex: 0.2 }}>
                    <Image
                      source={this.state.nextAppoinmentatPientDetails.data.photo_url ? { uri: this.state.nextAppoinmentatPientDetails.data.photo_url } : require("../../../../assets/images/sample_profile.png")}
                      style={{ height: 100, width: 100, borderRadius: 50 }}
                    />
                  </View>
                  <View style={{ justifyContent: 'center', flex: 0.4 }}>
                    <Text style={{ fontSize: 17 }}>{this.state.nextAppoinmentatPientDetails.data.name}</Text>
                    {/* <View style={{flexDirection:'row'}}><Text>{"Male"}</Text><Text>{"  |  "}</Text><Text>{"23Y 8M 6D"}</Text><Text>{"  |  "}{"22-07-1996"}</Text> </View> */}
                    <View style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5 }}><Text style={{ fontSize: 13, color: 'grey' }}>{this.state.nextAppoinmentatPientDetails.data.gender}{"  |  "}{this.state.nextAppoinmentatPientDetails.data.age}{"  |  "}{this.state.nextAppoinmentatPientDetails.data.dob}</Text></View>
                    <Text style={{ fontSize: 13, color: 'grey' }}>{this.state.nextAppoinmentatPientDetails.data.patient_account_number}</Text>
                  </View>

                  <View style={{ justifyContent: 'center', flex: 0.4 }}>
                    <View><Text style={{ textAlign: "right", fontSize: 13 }}>{this.state.nextAppoinmentatPientDetails.data.mobile_number}{" | "}{this.state.nextAppoinmentatPientDetails.data.email}</Text></View>
                    {
                      this.state.nextAppoinmentatPientDetails.data.last_visit_date ?
                        <Text style={{ textAlign: "right", fontSize: 13 }}>{"Last visited  -  "}{this.state.nextAppoinmentatPientDetails.data.last_visit_date}</Text>
                        : null
                    }
                  </View>
                </View>

                <View style={{ zIndex: -1 }}>
                  <TextInput
                    multiline={true}
                    placeholder={"Purpose of visit"}
                    underlineColorAndroid="transparent"
                    style={{ alignSelf: 'center', padding: 10, color: "grey", width: "100%", marginTop: 30, marginBottom: 15, height: 80, borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                    placeholderTextColor={"grey"}
                    autoCapitalize="none"
                    value={this.state.purposeOfVisitsInNextAppoinment}
                    onChangeText={text => {
                      this.setState({
                        purposeOfVisitsInNextAppoinment: text
                      })
                    }}
                  />
                  <Text style={{ marginBottom: 15, fontFamily: 13, fontWeight: '600', color: "red", textAlign: 'center' }}>{this.state.showErrorResponce}</Text>
                </View>
                <View style={{ flexDirection: "row", alignSelf: 'center' }}>
                  <TouchableOpacity onPress={() => { this.createNextAppoinment() }} disabled={this.state.isCreateClicked} style={{ backgroundColor: this.state.isCreateClicked ? "#888888" : color.themeDark, padding: 7, borderRadius: 4, width: 120, justifyContent: 'center', marginRight: 5 }}>
                    <Text style={{ textAlign: 'center', color: 'white' }}>Create</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => {
                    this.props.modelClose(false),
                    this.closeModalQB(),
                    this.setState({
                      modalVisible: false,
                      showNextAppoinmentTimer: false,

                    })
                  }} style={{ backgroundColor: "white", padding: 7, borderRadius: 4, width: 120, justifyContent: 'center', borderWidth: 1, marginLeft: 5, borderColor: "grey" }}>
                    <Text style={{ textAlign: 'center' }}>Close</Text>
                  </TouchableOpacity>
                </View>

              </View>

              {/* </View> */}
            </TouchableWithoutFeedback>
          </View>
          : null}
      </View>
    )
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier == 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:${"00"}`;
  }

  createNextAppoinment() {

    this.setState({
      isCreateClicked: true
    })
    // 2019-11-16

    var checkAMPM;

    if (this.state.findAmPm == "pm") {
      checkAMPM = "PM"
    } else {
      checkAMPM = "AM"
    }

    var tempTime = (this.state.hour12Formet) + ":" + this.state.minutes + " " + checkAMPM;
    var appointmentTime = this.convertTime12to24(tempTime);
    var appointmentScheduleTime = this.convertTime12to24(this.state.selectedDocorScheduleTimes && this.state.selectedDocorScheduleTimes.value ? this.state.selectedDocorScheduleTimes.value : "");
    // var appointmentTime =( parseInt(this.state.hour12Formet)+12) + ":" + this.state.minutes + ":00";
    var appointmentDate = this.state.getYear.toString() + "-" + this.state.chengedMonthInNumber.toString() + "-" + this.state.changedDay.toString();


    var data = {};
    var service_url = "";
    
    if (this.state.userType === Constants.ROLE_FO) {
      if(this.state.selectedDoctor.id=="Nursing"){
        if(this.state.selectedNurseTag.id==undefined){
          this.setState({
            showErrorResponce: "Please Select Nurse",
            isCreateClicked: false
          });
          return;
        }
      }
      service_url = Constants.FO_CREATE_APPOINTMENT;
      data = {
        "patient_id": parseInt(this.state.patientId),
        "doctor_id": this.state.selectedDoctor.id=="Nursing"? null: parseInt(this.state.selectedDoctor.id),
        "purpose_of_visit": this.state.purposeOfVisitsInNextAppoinment,
        "appointment_patient_type": this.state.selectedPatientAppointmentType?.value,
        "patient_appointment_type": this.state.selectedPatientAppointmentType?.id,
        "appointment_date": this.state.selectedAppointmentDate.toString(),
        "appointment_start_time": this.state.selectedDocorScheduleTimes && this.state.selectedDocorScheduleTimes.value ? appointmentScheduleTime : appointmentTime.toString(),
        "create_without_schedule": true,
        "patient_tag_id": this.state.selectedpatientTag?.id,
        "nurse_id": this.state.selectedNurseTag.id,
        "speciality_id": this.state.selectedDepartment ? this.state.selectedDepartment.id : this.state.selectedDoctor.speciality_id
      };

    } else {
      service_url = Constants.DOCTOR_CREATE_APPOINTMENT;
      data = {
        "patient_id": parseInt(this.state.patientId),
        "clinic_id": parseInt(this.state.selectedClinic.id),
        "purpose_of_visit": this.state.purposeOfVisitsInNextAppoinment,
        "appointment_patient_type": this.state.selectedPatientAppointmentType.value,
        "patient_appointment_type": this.state.selectedPatientAppointmentType?.id,
        "appointment_date": this.state.selectedAppointmentDate,
        "appointment_start_time": this.state.selectedDocorScheduleTimes && this.state.selectedDocorScheduleTimes.value ? appointmentScheduleTime : appointmentTime.toString(),
        "create_without_schedule": true,
        "patient_tag_id": this.state.selectedpatientTag?.id
      };
    }

    if (this.state.request_id) {
      data["request_id"] = this.state.request_id
    }

    //console.log("data--------------> " + JSON.stringify(data));
    if(data.doctor_id){
      delete data.nurse_id
    }
    if(this.state.isQMSEnable && this.state.userType === "FO" && (this.state.tokenGenerationType === "Specialization" || this.state.tokenGenerationType === "Clinic") && this.state.selectedDepartment === "" && this.state.selectedDoctor.value != "Nursing" ){
      this.setState({ 
        isCreateClicked: false,
        showErrorResponce: "Please select the specialization"

      })
    } else if(this.state.isQMSEnable && this.state.userType === "FO" && this.state.tokenGenerationType === "Doctor" && this.state.selectedDoctor === ""){
      this.setState({ 
        isCreateClicked: false,
        showErrorResponce: "Please select the Doctor"

      })
    } else if(!this.state.isQMSEnable && this.state.selectedDoctor === "" && this.state.userType === "FO") {
      this.setState({ 
        isCreateClicked: false,
        showErrorResponce: "Please select the Doctor"
       })
    } else if(!this.state.purposeOfVisitsInNextAppoinment){
      this.setState({
        isCreateClicked: false,
        showErrorResponce: "Please Select the Purpose of Visit"
      })
    }
    else { 
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getNextAppointmentSuccess,
      this.getNextAppointmentFailure
    );
   }
  }

  getNextAppointmentSuccess = response => {
    if (response.status === "success") {
      this.setState({
        purposeOfVisitsInNextAppoinment: "",
        modalVisible: false
      })
      if(!localStorage.getItem('isQuickBilling')){
        this.props.modelClose(false, "success", "", {}, "home")
      }else{
      localStorage.setItem('isQuickBilling', false);
      localStorage.setItem('QuickBillingAppointmentId',response.data.appointment_id)
      }
    } else {
      this.setState({
        showErrorResponce: response.message,
        isCreateClicked: false
      }, () => {
        setTimeout(() => {
          this.setState({
            showErrorResponce: ""
          })
        }, 3000)
      })
    }

  }
  getNextAppointmentFailure = error => {
    console.log("response -----------------> error   " + JSON.stringify(error));
    this.setState({
      isCreateClicked: false
    })
  }
  closeModalQB(){
    if(localStorage.getItem('isQuickBilling')){
      localStorage.setItem('QuickBillingAppointmentClosed', true)
    }
  }

  changeAppoinmentModalTime() {
    if (!this.state.modalVisible) {
      var today = new Date();
      var hours = parseInt(today.getHours());
      var minutes = today.getMinutes()
      if (hours > 12) {
        this.state.hour12Formet = hours - 12;
        this.state.hour12Formet = this.state.hour12Formet < 10 ? '0' + this.state.hour12Formet : this.state.hour12Formet;
      } else {
        this.state.hour12Formet = hours;
      }

      // var amPm= hours >= 12 ? 'pm':'am';
      minutes = minutes < 10 ? '0' + minutes : minutes;
      this.state.minutes = minutes;

      this.state.findAmPm = hours >= 12 ? 'pm' : 'am';
    }
  }

  timePicker() {
    var getHours = []
    var getMinutes = [];
    for (var i = 1; i <= 12; i++) {
      var hour = i < 10 ? '0' + i : i;
      getHours.push(hour)
    }
    for (var j = 0; j <= 59; j++) {
      var minutes = j < 10 ? '0' + j : j;
      getMinutes.push(minutes)
    }

    if (this.state.modalVisible) {
      //   var currentTime = new Date();
      //   var hours = parseInt(currentTime.getHours());
      //   var minutes = currentTime.getMinutes() 
      //   if(hours > 12 ){
      //     this.state.hour12Formet = hours - 12;
      //     this.state.hour12Formet = this.state.hour12Formet < 10 ? '0'+this.state.hour12Formet : this.state.hour12Formet;
      //   }else{
      //     this.state.hour12Formet = hours;
      //   }

      // var amPm= this.state.hours >= 12 ? 'pm':'am';
      //   minutes = minutes < 10 ? '0'+minutes : minutes;
      //   this.state.minutes = minutes;
      // this.setState({minutes :minutes})

      return (
        <View style={styles.preferTime}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <TouchableOpacity onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer, selectedDocorScheduleTimes: {} }) }} style={{ justifyContent: 'center' }}>
                <Text style={{fontSize: '1vw'}}>{this.state.hour12Formet}{"  :  "}{this.state.minutes}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ showNextAppoinmentTimer: !this.state.showNextAppoinmentTimer, selectedDocorScheduleTimes: {} }) }} style={{ justifyContent: 'center', marginLeft: 5 }}>
                <Icon
                  size={'1vw'}
                  name={"angle-down"}
                />
              </TouchableOpacity>
            </View>
            {
              this.state.showNextAppoinmentTimer ?
                <TouchableWithoutFeedback onPress={() => { this.setState({ showNextAppoinmentTimer: true }) }}>
                  <View style={{ marginTop: 30, flexDirection: 'row', backgroundColor: '#F2F2F2', position: 'absolute', zIndex: 1, borderRadius: 5 }}>
                    <ScrollView style={{ height: 120 }} showsVerticalScrollIndicator={false}>
                      <View style={styles.preferTimeHour}>
                        {
                          getHours.map((hour) => {
                            return <TouchableOpacity onPress={() => { this.changeTime(hour, 'hr') }} style={{ padding: 2, width: '2vw' }}><Text style={{ color: hour === this.state.hour12Formet ? null : '#d0cece', fontSize: '1vw' }}>{hour}</Text></TouchableOpacity>
                          })
                        }
                      </View>
                    </ScrollView>
                    <ScrollView style={{ height: 120 }} showsVerticalScrollIndicator={false}>
                      <View style={styles.preferTimeMin}>
                        {
                          getMinutes.map((min) => {
                            return <TouchableOpacity onPress={() => { this.changeTime(min, 'mm') }} style={{ padding: 2, width: '2vw' }}><Text style={{ color: min === this.state.minutes ? null : '#d0cece', fontSize: '1vw' }}>{min}</Text></TouchableOpacity>
                          })
                        }
                      </View>
                    </ScrollView>
                  </View>
                </TouchableWithoutFeedback>
                :
                null
            }

          </View>

          {/*   */}
          <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => { this.changeTime('am', 'ampm') }}>
              <Text style={{ color: this.state.findAmPm === "am" ? color.themeDark : null, marginRight: 15, marginLeft: 20, fontSize: '1vw' }}>{"am"}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { this.changeTime('pm', 'ampm') }}>
              <Text style={{ color: this.state.findAmPm === "pm" ? color.themeDark : null, fontSize: '1vw' }}>{"pm"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      )

    }
  }

  changeTime(time, type) {
    if (type === 'hr') {
      this.state.hour12Formet = time;
      this.setState({
        hour12Formet: time
      })
    } else if (type === 'mm') {
      this.setState({
        showNextAppoinmentTimer: false,
        minutes: time
      })
      this.state.minutes = time;

    } else if (type === 'ampm') {
      this.state.findAmPm = time;
      this.setState({
        findAmPm: time
      })
    }
  }

  modelClose() {
    this.setState({
      // modalVisible:false,             // Next AppoinmentModal Close
      showNextAppoinmentTimer: false   // AppoinmentModal timer drop down close
    })
    this.props.modelClose(false)
  }



  showDateDropDownView = () => {
    var getdate = [];
    if (this.state.dropDownView === "month") {
      getdate = this.state.totalMonths;
    } else {
      var today = new Date();
      var currentYear = parseInt(today.getFullYear());
      for (var i = currentYear; i > 2000; i--) {
        getdate.push(i);
      };
    }
    return (
      <View style={{ alignSelf: 'center', alignItems: 'center', width: '25%', backgroundColor: 'black', zIndex: 1, position: 'absolute', }}>
        {
          this.state.dropDownViewFlag ?
            <ScrollView style={styles.timeAndDateScroll} showsVerticalScrollIndicator={false}>
              <View style={{ backgroundColor: 'black' }}>
                {
                  getdate.map((value, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        style={{ padding: 5 }}
                        onPress={() => {
                          this.state.dropDownView === "month" ?
                            this.setState({ chengedMonthInNumber: index + 1, getMonth: value.month, dropDownViewFlag: !this.state.dropDownViewFlag, changedMonthDays: value.totalDays })
                            :
                            this.setState({ getYear: value, dropDownViewFlag: !this.state.dropDownViewFlag });
                        }}>
                        <Text style={{ color: "white" }}>{this.state.dropDownView === "month" ? value.month : value}</Text>
                      </TouchableOpacity>
                    )
                  })
                }
              </View>
            </ScrollView>
            :
            null
        }
      </View>
    )
  }
  currentDate() {
    var today = new Date();
    var date = today.getDate();
    var month = today.getMonth();
    var year = today.getFullYear();
    for (let i = 0; i < this.state.totalMonths.length; i++) {
      if (month === i) {
        this.setState({
          changedMonthDays: this.state.totalMonths[i].totalDays,
          getMonth: this.state.totalMonths[i].month,
          getYear: year,
          day1: date - 2,
          day2: date - 1,
          day3: date,
          day4: date + 1,
          day5: date + 2,
        })
      }
    }
  }
  showDropdown(data) {
    this.setState({
      dropDownViewFlag: !this.state.dropDownViewFlag,
      dropDownView: data
    })
  }
  showCalendar(calendarType) {
    return (
      <View style={{ maxWidth: '35%', backgroundColor: "white", flexDirection: 'row', marginLeft: 10, flex: 1 }}>
        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <TouchableOpacity
              style={{ marginRight: 5 }}
              onPress={() => {
                this.showDropdown("month")
              }}>
              <Text>{(this.state.getMonth).substr(0, 3)}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginRight: 5 }}
              onPress={() => {
                this.showDropdown("year");
              }}>
              <Text>{this.state.getYear}</Text>
            </TouchableOpacity>
            <View style={{ height: 15, width: 15 }}>
              {/* <Image
              // require("../../assets/images/Reports/Group 1558.svg")calendar_icon.png
              source={require("../../../../assets/images/calendar (2).svg")}
              style={{resizeMode:'center',height:15,width:15}}
              resizeMode="cover"
            /> */}
            </View>
          </View>
          <View>
            {this.showDateDropDownView()}
          </View>
          <View style={{ zIndex: -1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.changedDay - 1); }} style={{ padding: 5, justifyContent: 'center' }}>
              <Icon
                size={platform === "web" ? 25 : 30}
                name={"angle-left"}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 2, this.state.day1); }}
              style={[this.state.changedDay === this.state.day1 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day1 ? { color: "white" } : null]}>{this.state.day1}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.day2); }}
              style={[this.state.changedDay === this.state.day2 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day2 ? { color: "white" } : null]}>{this.state.day2}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 0, this.state.day3) }}
              style={[this.state.changedDay === this.state.day3 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day3 ? { color: "white" } : null]}>{this.state.day3}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.day4); }}
              style={[this.state.changedDay === this.state.day4 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day4 ? { color: "white" } : null]}>{this.state.day4}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 2, this.state.day5); }}
              style={[this.state.changedDay === this.state.day5 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day5 ? { color: "white" } : null]}>{this.state.day5}<Text style={{ fontSize: 7 }}></Text></Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.changedDay + 1); }} style={{ padding: 5, justifyContent: 'center' }}>
              <Icon
                size={platform === "web" ? 25 : 30}
                name={"angle-right"}
              // color={"#c7c4c3"}
              />
            </TouchableOpacity>
          </View>
        </View>
        {/* <View style={{justifyContent:'flex-end',marginBottom:10,marginLeft:5}}>
          <Image
              source={require("../../../../assets/images/clock_icon.png")}
              style={{resizeMode:'center',height:30,width:30}}
              resizeMode="cover"
            />
            <Text style={{fontSize:8,marginTop:6}}>{"Today"}</Text>
          </View> */}
      </View>
    )
  }

  checkValidateTheDays() {
    if (this.state.day5 === this.state.changedMonthDays) {
      //  do nothing
    } else {
      if (this.state.day5 > this.state.changedMonthDays) {
        this.setState({
          day1: this.state.day1 - 1,
          day2: this.state.day2 - 1,
          day3: this.state.day3 - 1,
          day4: this.state.day4 - 1,
          day5: this.state.day5 - 1,
        });
      }
    }

    if (this.state.day3 === 1) {
      this.setState({
        day1: this.state.day1 + 2,
        day2: this.state.day2 + 2,
        day3: this.state.day3 + 2,
        day4: this.state.day4 + 3,
        day5: this.state.day5 + 4,
      });
    }
    if (this.state.day3 === 2) {
      this.setState({
        day1: this.state.day1 + 1,
        day2: this.state.day2 + 1,
        day3: this.state.day3 + 1,
        day4: this.state.day4 + 1,
        day5: this.state.day5 + 1,
      });
    }

  }

  changeDay(method, value, selectedDay) {
    this.state.getDate = selectedDay; // get selected day

    if (this.state.changedMonthDays < selectedDay || selectedDay < 1) {
      // do nothing
    } else {
      this.setState({ changedDay: selectedDay })
    }


    if (method === "" && value === 0) {
      // do nothing
    } else {
      if (method === "sub") {
        if (this.state.day3 === 3) {
          // do nothing
        } else if (this.state.day1 === 2) {
          this.setState({
            day1: this.state.day1 - 1,
            day2: this.state.day2 - 1,
            day3: this.state.day3 - 1,
            day4: this.state.day4 - 1,
            day5: this.state.day5 - 1,
          });
        } else {
          this.setState({
            day1: this.state.day1 - value,
            day2: this.state.day2 - value,
            day3: this.state.day3 - value,
            day4: this.state.day4 - value,
            day5: this.state.day5 - value,
          });
        }
      } else {
        if (this.state.changedMonthDays === this.state.day5) {
          // do nothing
        } else if (this.state.changedMonthDays - 2 === this.state.day4) {
          this.setState({
            day1: this.state.day1 + 1,
            day2: this.state.day2 + 1,
            day3: this.state.day3 + 1,
            day4: this.state.day4 + 1,
            day5: this.state.day5 + 1,
          });
        } else {
          this.setState({
            day1: this.state.day1 + value,
            day2: this.state.day2 + value,
            day3: this.state.day3 + value,
            day4: this.state.day4 + value,
            day5: this.state.day5 + value,
          });
        }
      }
    }

    if ((this.state.changedMonthDays >= this.state.getDate && method === "add") || (this.state.getDate >= 1 && method === "sub")) {
      var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; // selected date month and year (need to pass getAppointments function)
      // this.getAppointments(selectedDateAndTime); // send selected date to Appointments
    } else {
      // do nothing
    }
  }

  //   convertedDate(date) {
  //     var fulldate = new Date(date);
  //     var converted_date = moment(fulldate).format("YYYY-MM-DD");
  //     return converted_date
  // }
  renderPatientTag() {

    return (
      <View style={styles.patientTagfunction}>
        <CommonPicker
          showPickerDropDownFlag={this.state.showpatientTagdata}
          changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
          selectedValue={this.state.selectedpatientTag}
          arrayOfData={this.state.patientTagdata}
          pickerKey="PatientTag"
        />
      </View>
    )
  }
  renderNurseList(){
    return (
      <View style={{ marginTop: 10, zIndex: 1 }}>
        <CommonPicker
          showPickerDropDownFlag={this.state.showNursedata}
          changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
          selectedValue={this.state.selectedNurseTag}
          arrayOfData={this.state.staffData}
          pickerKey="Nurse"
        />
      </View>
    )
  }

  renderClinicView() {
    return (
      <View style={styles.createAppointmentselectDoctor}>
        {
          this.state.userType == Constants.ROLE_FO && this.state.isQMSEnable ?
            <CommonPicker
              showPickerDropDownFlag={this.state.showDoctorPickerDropDownFlag}
              changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
              selectedValue={this.state.selectedDoctor}
              arrayOfData={this.state.doctorData}
              pickerKey="FO"
            /> : this.state.userType === "FO" && !this.state.isQMSEnable ? 
            <CommonPicker
              showPickerDropDownFlag={this.state.showDoctorPickerDropDownFlag}
              changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
              selectedValue={this.state.selectedDoctor}
              arrayOfData={this.state.doctorData}
              pickerKey="NotQMSFO"
            /> :
            <CommonPicker
              showPickerDropDownFlag={this.state.showDoctorPickerDropDownFlag}
              changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
              selectedValue={this.state.selectedClinic}
              arrayOfData={this.state.clinicData}
              pickerKey="Doctor"
            />

        }
      </View>
    )
  }

  renderPatientAppointmentType () {
    return(
      <View style={styles.AppointmentPatientType}>
        {
          this.state.userType == Constants.ROLE_FO && this.state.isQMSEnable ?
            <CommonPicker
              showPickerDropDownFlag={this.state.showPatientAppointmentType}
              changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
              selectedValue={this.state.selectedPatientAppointmentType}
              arrayOfData={this.state.AppointmentTypeData}
              pickerKey="PatientType"
            /> : this.state.userType == Constants.ROLE_FO && !this.state.isQMSEnable ?
              <CommonPicker
                showPickerDropDownFlag={this.state.showPatientAppointmentType}
                changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                selectedValue={this.state.selectedPatientAppointmentType}
                arrayOfData={this.state.AppointmentTypeData}
                pickerKey="NotQMSPatientType"
              /> :
              <CommonPicker
                showPickerDropDownFlag={this.state.showPatientAppointmentType}
                changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
                selectedValue={this.state.selectedPatientAppointmentType}
                arrayOfData={this.state.AppointmentTypeData}
                pickerKey="PatientTypeDoctor"
              />
        }
      </View>
    )
  }
  renderDepartmentType () {
    return(
      <View style={styles.AppointmentPatientType}>
        <CommonPicker
              showPickerDropDownFlag={this.state.showDepartmentType}
              changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
              selectedValue={this.state.selectedDepartment}
              arrayOfData={this.state.departmentList}
              pickerKey="departmentType"
            />
      </View>
    )
  }

  // renameKey ( obj, oldKey, newKey ) {
  //   obj[newKey] = obj[oldKey];
  //   delete obj[oldKey];
  //   return obj[newKey]
  // }

  renderReviewCalenderView = () => {
    var Value = this.state.durationValue
    return(
    <View style={{ marginTop: 20 }}>
      <Text style={{fontSize: 16, }}>{"Duration"}</Text>
      <View style={{display: "flex", flexDirection: "row", marginTop: 15}}>
      <View style={{flexDirection: "row", shadowColor: color.lightGray, shadowOffset:{width: 1, height: 1}, shadowOpacity: 0.8, shadowRadius: 5 }}>
        <TouchableOpacity 
        style = {{shadowColor: color.lightGray, shadowOffset:{width: 1, height: 1}, shadowOpacity: 0.8, shadowRadius: 5,  height: 30, width: 30, alignItems: "center", justifyContent: "center"}}
        onPress = {() => {
          if(Value > 0){
            this.setState({
              durationValue: parseInt(Value) - 1
            }, () => {
              this.changeReviewDate()
            })
          }
        }}
        >
          <Icon name="minus" size={14} color={color.themeDark} />
        </TouchableOpacity>
        <TextInput 
        style={{paddingHorizontal: 8, width: 60, textAlign: "center"}}
        keyboardType="numeric"
        value={Value}
        onChange={(event) => {
          if(parseInt(event.target.value) < 1000){
          this.setState({
            durationValue: parseInt(event.target.value)
          }, () => {
            this.changeReviewDate()
          })
          }else if ((event.target.value) == ""){
            this.setState({
              durationValue: 0
            })
          }
        }}
        />
        <TouchableOpacity 
        style = {{ shadowColor: color.lightGray, shadowOffset:{width: 1, height: 1}, shadowOpacity: 0.8, shadowRadius: 5, height: 30, width: 30, alignItems: "center", justifyContent: "center"}}
        onPress = {() => {
          if(parseInt(Value) < 999){
            this.setState({
              durationValue: parseInt(Value) + 1
            }, () => {
              this.changeReviewDate()
            })
          }
        }}
        >
          <Icon name="plus" size={14} color={color.themeDark} />
        </TouchableOpacity>
      </View>
      <View style={{ marginLeft: 10, zIndex: 1, width: 80, height: 30 }}>
        <CommonPicker
          showPickerDropDownFlag={this.state.showDurationType}
          changePickerDropDownValue={this.changePickerDropDownValue.bind(this)}
          selectedValue={this.state.selectedDurationType}
          arrayOfData={this.state.durationTypedata}
          pickerKey="durationType"
        />
      </View>
      </View>
      <Text style={{fontSize: 16, marginTop: 20}}>{"Review On"}</Text>
      <DatePicker
        clearIcon={false}
        value={moment(this.state.selectedAppointmentDate)}
        format={"YYYY-MM-DD"}  
        style={{borderColor: color.themeDark, borderWidth: 2, width: "45%", marginTop: 15, zIndex: -1}}   
        onChange={(date) => {
          var fulldate = new Date(date);
          var converted_date = moment(fulldate).format("YYYY-MM-DD");
          
          this.setState({
            selectedAppointmentDate: converted_date,
            durationValue: 0,
            showDurationType: false,
            selectedDurationType: {
              id: 1,
              value: "Days"
            }
          })
          
        }}
      />
    </View>
    )
  }

  changeReviewDate = () => {

    var currentDate = new Date();
    var reviewDay = null;
    var getReviewDate = null;
    var reviewDate = null;
    var selectedDuration = parseInt(this.state.durationValue) || 0;

    if(selectedDuration && selectedDuration > 0) {

      let isDateCalculate = false;
      if((this.state.selectedDurationType).value == "Days"){
        reviewDay = selectedDuration;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if((this.state.selectedDurationType).value == "Weeks"){
        reviewDay = selectedDuration * 7;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if((this.state.selectedDurationType).value == "Months"){
        reviewDay = selectedDuration * 30;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }else if((this.state.selectedDurationType).value == "Years"){
        reviewDay = selectedDuration * 365;
        getReviewDate = new Date(currentDate.getTime()+(reviewDay*24*60*60*1000));
        isDateCalculate=true;
      }
      if(isDateCalculate){
        reviewDate = moment(getReviewDate).format("YYYY-MM-DD")
        this.setState({ selectedAppointmentDate:reviewDate })
      }
    }else{
      if(this.state.selectedAppointmentDate){
        this.setState({ selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD")})
      }
    }
  }

  renderCalenderView() {
    // var data ={
    //   '2020-11-24': {
    //     customStyles: {
    //       container: {
    //         backgroundColor: 'green'
    //       },
    //       text: {
    //         color: 'black',
    //         fontWeight: 'bold'
    //       }
    //     }
    //   }
    // }

    // data = this.renameKey( data, '2020-11-24', this.state.selectedAppointmentDate )
    //     arr.forEach( obj => renameKey( obj, '_id', 'id' ) );
    // const updatedJson = JSON.stringify( arr );

    return (
      <View style={{ marginTop: 5, width: '32vw' }}>
        <Calendar minDate={localStorage.getItem('isQuickBilling')?moment(new Date()).format("YYYY-MM-DD"): null} maxDate={localStorage.getItem('isQuickBilling')?moment(new Date()).format("YYYY-MM-DD") : null}
          style={{
            // borderWidth: 1,
            // borderColor: 'gray',
          }}
          // markingType={'custom'}
          // markedDates={data}

          theme={{
            backgroundColor: '#ffffff',
            calendarBackground: color.white,
            textSectionTitleColor: 'black',  // day (sun,mon,tue,ect..)
            // selectedDayBackgroundColor: color.themePink,
            // selectedDayTextColor: color.themeBlue,
            todayTextColor: color.themeDark,

            dayTextColor: 'black',
            // textDisabledColor: '#d9e1e8',
            // dotColor: '#00adf5',
            selectedDotColor: 'pink',
            // arrowColor: 'red',
            monthTextColor: 'black',
            textDayFontFamily: 'monospace',
            textMonthFontFamily: 'monospace',
            textDayHeaderFontFamily: 'monospace',
            textMonthFontWeight: 'bold',
            textDayFontSize: '1vw',
            textMonthFontSize: '1vw',
            textDayHeaderFontSize: '1vw',
            'stylesheet.day.basic':{
              'base':{
                height:'1.5vw',
                width: '1.5vw',
                justifyContent: 'center',
                alignItems: 'center'
              }}
          }}
          // current={'2020-05-23'}
          //   minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
          //   maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate} 
          markedDates={this.state.markedDates}

          onDayPress={(day) => {
            var getFilterDate = day.dateString;
            let markedDates = {};
            getFilterDate = this.convertedDate(getFilterDate)
            markedDates[getFilterDate] = {
              selected: true, disableTouchEvent: true,
              selectedColor: color.themeDark,
              selectedTextColor: color.white,
            };

            this.setState({
              selectedAppointmentDate: getFilterDate, markedDates: markedDates
            }, () => {
              // this.state.userType == Constants.ROLE_FO
              this.getSchuduleTime()
            });
            // if(this.state.showCalenderModalFrom=== "From"){
            // this.getAppointmentCount(this.convertedDate(getFilterDate))
            //   this.setState({filterFromDate :getFilterDate},()=>{this.updateState("fromDate", this.state.filterFromDate)})
            // }else{
            //   this.setState({filterToDate :getFilterDate},()=>{this.updateState("toDate", this.state.filterToDate)})
            // }
          }}
          onDayLongPress={(day) => { console.log('selected day is', day) }}
          // monthFormat={'yyyy MM'}
          onMonthChange={(month) => { }}
          hideArrows={false}
          renderArrow={(direction) => {
            if (direction === "left") {
              return (
                <Icon
                  size={'1.5vw'}
                  name={"angle-left"}
                />
              )
            } else if (direction === "right") {
              return (
                <Icon
                  size={'1.5vw'}
                  name={"angle-right"}
                />
              )
            }
          }}
          hideExtraDays={false}
          disableMonthChange={false}
          // firstDay={1}
          // hideDayNames={true}
          // showWeekNumbers={true}
          onPressArrowLeft={substractMonth => substractMonth()}
          onPressArrowRight={addMonth => addMonth()}

        />
      </View>
    )
  }

  renderSerach = () => {
    let purposeOfVisit = this.state.popListData.map(item => item.reason)

    return purposeOfVisit.map((item) => (
      <Option key={item} value={item}>{item}</Option>
    ))
  }


  nextAppointmentNewModel() {
    return (
      <View style={{ position: 'absolute' }}>
        {this.state.modalVisible && this.state.nextAppoinmentatPientDetails && this.state.nextAppoinmentatPientDetails.status === "success" && this.state.nextAppoinmentatPientDetails.data ?

          <View style={styles.nextAppointmentPopup}>
            <TouchableWithoutFeedback onPress={() => { this.setState({ modalVisible: true, showNextAppoinmentTimer: false, dropDownViewFlag: false, purposeOfVisitList: [] }) }}>
              <View style={styles.nextAppointmentScreen}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View style={{ marginBottom: 10, width: '32vw' }}>
                    <Text style={styles.nextAppointmentHead}>{this.state.isNextSchedule ? "Review Appointment" : "Create Appointment"}</Text>
                   {  this.state.userType === "FO" && this.state.isQMSEnable ? 
                    <View style={{width: '32vw', zIndex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{marginTop: '-0.3vw', width: "10vw"}}>
                      <Text style={{color: color.black, fontSize: '0.7vw', fontWeight: "500"}}>Specialization</Text>
                      {this.renderDepartmentType()}
                    </View>   
                    <View style={{marginTop: '-0.3vw', width: "10vw"}}>
                    <Text style={{color: color.black, fontSize: '0.7vw', fontWeight: "500"}}>Doctor Name</Text>
                    {this.renderClinicView()}
                      </View>
                    <View style={{marginTop: '-0.3vw', width: "10vw"}}>
                      <Text style={{color: color.black, fontSize: '0.7vw', fontWeight: "500"}}>Appointment Type</Text>
                    {this.renderPatientAppointmentType()}
                    </View>                 
                    
                    </View>:  this.state.userType === "FO" && !this.state.isQMSEnable ? 
                    <View style={{width: '29vw', zIndex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{marginTop: '-0.3vw'}}>
                    <Text style={{color: "#888888", fontSize: '0.7vw'}}>Doctor Name</Text>
                    {this.renderClinicView()}
                      </View>
                    <View style={{marginTop: '-0.3vw'}}>
                      <Text style={{color: "#888888", fontSize: '0.7vw'}}>Appointment Type</Text>
                    {this.renderPatientAppointmentType()}
                    </View>
                    </View> :
                     <View style={{width: '29vw', zIndex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                     <View style={{marginTop: '-0.3vw'}}>
                     <Text style={{color: "#888888", fontSize: '0.7vw'}}>Clinic Name</Text>
                     {this.renderClinicView()}
                       </View>
                     <View style={{marginTop: '-0.3vw'}}>
                       <Text style={{color: "#888888", fontSize: '0.7vw'}}>Appointment Type</Text>
                     {this.renderPatientAppointmentType()}
                     </View>
                     
                     </View>
                   }
                    {this.state.isNextSchedule ? this.renderReviewCalenderView() : this.renderCalenderView()}
                  </View>

                  <View style={{ flex: 0.6, marginLeft: 80, width: '68vw'}}>

                    <View style={{ flexDirection: 'row', zIndex: 1 }}>

                          <View style={{ flex: 0.2 }}>
                            <Image
                              source={this.state.nextAppoinmentatPientDetails.data.photo_url ? { uri: this.state.nextAppoinmentatPientDetails.data.photo_url } : require("../../../../assets/images/sample_profile.png")}
                              style={styles.patientImage}
                            />
                          </View>
                          
                      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginLeft: "5vw", width: '30vw'}}>
                            <View style={{ justifyContent: 'center', flex: 0.7, paddingLeft: 10 }}>

                                <View style={{flexDirection: "row"}}>
                                  <Text style={styles.createnextAppointmentPatientname}>{this.state.nextAppoinmentatPientDetails.data.name}</Text>
                                  {this.state.nextAppoinmentatPientDetails.data.is_ama_patient?
                                  <Icon
                                    size={30}
                                    name={"crosshairs"}
                                    color= {color.brightRed}
                                    style={{marginLeft: 12}}
                                  />
                                  : null }
                                </View>

                          {/* <View style={{flexDirection:'row'}}><Text>{"Male"}</Text><Text>{"  |  "}</Text><Text>{"23Y 8M 6D"}</Text><Text>{"  |  "}{"22-07-1996"}</Text> </View> */}
                          <View style={styles.patientDetails}><Text style={{ fontSize: '1vw', color: 'black' }}>{this.state.nextAppoinmentatPientDetails.data.gender}{"  |  "}{this.state.nextAppoinmentatPientDetails.data.age}{"  |  "}{this.state.nextAppoinmentatPientDetails.data.dob}</Text></View>

                          <View style={{ flexDirection: "row" }}>
                            <Text style={styles.patientUniqueDetails}>{this.state.nextAppoinmentatPientDetails.data.patient_account_number} {" | "} {this.state.nextAppoinmentatPientDetails.data.mobile_number}</Text>
                          </View>


                        </View>

                        <View style={{ zIndex:1, alignContent: 'center'}}>
                        <Text style={{ color: "#888888", fontSize: '0.7vw' }}>Patient Tag</Text>
                                    {this.renderPatientTag()}
                            </View>  
                      </View>
                    </View>

                    {this.state.nextAppoinmentatPientDetails.data.address_line_1 || this.state.nextAppoinmentatPientDetails.data.address_line_2 ?
                      <View style={{ width: "10vw", marginLeft: '6.3vw' }}>
                        {/* <Text style={{ color: "#888888" }}>Address</Text> */}
                        <Tooltip placement="topLeft" style={{ width: "5vw", height: "2vw", marginTop: 5 }} title={this.state.nextAppoinmentatPientDetails.data.address_line_1 + "," + this.state.nextAppoinmentatPientDetails.data.address_line_2} >
                          <Text style={styles.patientUniqueDetails}>{this.state.nextAppoinmentatPientDetails.data.address_line_1}{","}</Text>
                          <Text style={styles.patientUniqueDetails} numberOfLines={0.5} ellipsizeMode="tail">{this.state.nextAppoinmentatPientDetails.data.address_line_2} </Text>
                        </Tooltip>
                      </View> : null
                    }

                    <View style={{ flexDirection: "row", marginTop: 10,  marginLeft:10, width: '35vw', justifyContent: 'space-between' }}>
                      
                      <View style={{ flex: 0.6 }}><Text style={{ color: "#888888", fontSize:'1vw' }}>Select Time</Text></View>
                      <Text style={{ flex: 0.3, color: "#888888", fontSize:'1vw' }}>Prefer Time</Text>
                      <View>{this.timePicker()}</View>
                      
                    </View>
                    <View style={{ zIndex: -1, width: '35vw', height: '5vw',  marginLeft:10 }}>
                      {this.renderDocorScheduleTimes()}
                    </View>


                    
                    {this.state.selectedDoctor.id =="Nursing"?
                    <View style={{ flexDirection: "row",width: "20vw" }}>
                      <Text style={{ color: "#888888", flex: 0.4, marginTop: 15, fontSize: '1vw' }}>Select Nurse</Text>
                      <View style={{ flex: 0.6 }} >{this.renderNurseList()}</View>
                    </View>: null
                    }

                    <View style={{ zIndex: -1, marginLeft:10 }}>
                      <Text style={{ color: "#888888", marginTop: 5, fontSize: '1vw' }}>Purpose of visit *</Text>
                      {/* <TextInput
                        maxLength={250}
                        multiline={true}
                        // placeholder={"Purpose of visit"}
                        underlineColorAndroid="transparent"
                        style={{fontSize: '1vw', padding: 10, color: "grey", width: "32vw", marginTop: 10, height: '5.5vw', borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                        placeholderTextColor={"grey"}
                        autoCapitalize="none"
                        value={this.state.purposeOfVisitsInNextAppoinment}
                        onChangeText={text => {
                          this.setState({
                            purposeOfVisitsInNextAppoinment: text
                          }, () => {
                            this.getPurposeOfVisitList()
                          })
                        }}
                      /> */}
                      <AutoComplete
                        style={{ fontSize: '1vw', padding: 10, color: "grey", width: "32vw", marginTop: 10, height: '5.5vw', borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                        value={this.state.purposeOfVisitsInNextAppoinment}
                        onChange={(value) => {
                          this.setState({ purposeOfVisitsInNextAppoinment: value })
                        }}
                        dataSource={this.renderSerach()}
                        filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                      />
                      {
                        this.purposeOfVisitListScrollView()
                      }
                      <Text style={{ marginBottom: 15, fontFamily: 13, fontWeight: '600', color: "red", textAlign: 'center' }}>{this.state.showErrorResponce}</Text>
                    </View>
                    <View style={{ flexDirection: "row", alignSelf: "center" ,zIndex:-10, marginBottom: -10}}>
                      <TouchableOpacity onPress={() => { this.createNextAppoinment() }} disabled={this.state.isCreateClicked} style={{ backgroundColor: this.state.isCreateClicked ? "#888888" : color.themeDark, padding: 7, borderRadius: 4, width: '8vw', justifyContent: 'center', marginRight: 5 }}>
                        <Text style={{ textAlign: 'center', color: 'white', fontSize: '1vw' }}>Create</Text>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => {
                        this.props.modelClose(false),
                        this.closeModalQB()
                        this.setState({
                          modalVisible: false,
                          showNextAppoinmentTimer: false,

                        })
                      }} style={styles.closeButton}>
                        <Text style={{ textAlign: 'center' , fontSize: '1vw'}}>Close</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>

            </TouchableWithoutFeedback>
          </View>
          : null
        }
      </View>
    )
  }

  onPressButton(key, selectedValue) {
    this.setState({
      selectedDocorScheduleTimes: selectedValue
    })
  }

  renderDocorScheduleTimes() {
    // docorScheduleTimes
    return (
      <View>
        <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={false} style={styles.selectTimeShedule}>

          <View style={styles.selectTimeSheduleview}>

            {
              this.state.docorScheduleTimes?.map((data, index) => {
                var item = { label: data, value: data }
                return (
                  <View style={{ marginBottom: 5,marginRight: 10 }} key={index}>
                    <CommonButton
                      item={item}
                      selectedvalue={this.state.selectedDocorScheduleTimes}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={"selectedDocorScheduleTimes"} />
                  </View>
                )
              })
            }

          </View>
        </ScrollView>

      </View>
    )
  }



  render() {
    console.log(this.state.selectedClinic,"selectedClinic")
    this.checkValidateTheDays();
    return (
      <View style={{ position: 'absolute', zIndex: 1, }}>
        <TouchableWithoutFeedback onPress={() => { this.modelClose() }}>
          {/* {this.showNextAppoinmentModal()} */}
          {this.nextAppointmentNewModel()}
        </TouchableWithoutFeedback>
      </View>
    );
  }

  getSchuduleTime() {
    var clicnicID = ""
    if (this.state.userType == Constants.ROLE_FO) {

      clicnicID = this.state.selectedDoctor.id
      var service_url = Constants.NEW_APPOINMENT + "?appointment_date=" + this.state.selectedAppointmentDate + "&doctor_id=" + clicnicID

    } else {
      clicnicID = this.state.selectedClinic.id

      var service_url = Constants.NEW_APPOINMENT + "?appointment_date=" + this.state.selectedAppointmentDate + "&clinic_id=" + clicnicID

    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSchuduleTimeSuccess,
      this.getSchuduleTimeFailure
    );
  }

  getSchuduleTimeSuccess = success => {
    // alert("success----> " + JSON.stringify(success.data))

    if (success.status == "success") {
      var data = success.data
      this.setState({
        docorScheduleTimes: data
      }
      )
    }
  }
  getSchuduleTimeFailure = error => {
    console.log("error----> " + JSON.stringify(error))

  }



  getPurposeOfVisitList() {
    var service_url = "";
    this.setState({
      showpatientTagdata: false
    })
    if (this.state.userType === Constants.ROLE_FO) {

      service_url = Constants.PURPOSE_OF_VISIT_SEARCH +
        "?doctor_id=" + this.state.selectedDoctor.id +
        "&search_key=" + this.state.purposeOfVisitsInNextAppoinment

    } else {
      service_url = Constants.PURPOSE_OF_VISIT_SEARCH +
        "?clinic_id=" + this.state.selectedDoctor.id +
        "&search_key=" + this.state.purposeOfVisitsInNextAppoinment;

    }
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPurposeOfVisitListSuccess,
      this.getPurposeOfVisitListFailure
    );
  }

  getPurposeOfVisitListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        purposeOfVisitList: success.data
      })
    } else {
      this.setState({
        purposeOfVisitList: []
      })
    }
  }
  getPurposeOfVisitListFailure = error => {
    alert(JSON.stringify(error))

  }


  purposeOfVisitListScrollView() {
    return (
      <ScrollView style={{ position: 'absolute', zIndex: 2, marginTop: 120, maxHeight: 250, maxWidth: 500, borderRadius: 4, borderWidth: 1, borderColor: "#888888", backgroundColor: color.white }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>

        {
          this.state.purposeOfVisitList && this.state.purposeOfVisitList.length > 0 ?
            this.state.purposeOfVisitList.map((item, index) => {

              return (
                <TouchableOpacity key={index} style={{ paddingHorizontal: 15 }}
                  onPress={() => {
                    this.setState({
                      purposeOfVisitsInNextAppoinment: item,
                      purposeOfVisitList: []
                    })
                    // props.changePickerDropDownValue(false,item)
                  }}
                >
                  <Text style={
                    { color: color.black, fontSize: 13 }

                  }>{item}</Text>
                </TouchableOpacity>
              )
            })

            : null
        }
      </ScrollView>
    )
  }

}
const styles = StyleSheet.create({
  // headingText: {
  //   fontSize: 15,
  //   color: color.black
  // },
  timeAndDateScroll: {
    height: 200, width: 100
  },
  nextAppointmentHead:{ 
    fontSize: "1.5vw",
    marginBottom: 10 
  },
  nextAppointmentPopup:{ 
    position: 'absolute', 
    zIndex: -1, 
    height: '50vw', 
    width: '100vw', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: 'rgba(0, 0, 0, 0.5)' 
  },
  nextAppointmentScreen:{ 
    backgroundColor: "white", 
    position: 'absolute', 
    // zIndex: 2, 
    width: '76%', 
    height: '35vw', 
    borderRadius: 7, 
    paddingTop: 15, 
    paddingBottom: 20, 
    paddingLeft: 20, 
    paddingRight: 30,
    marginTop: -70

  },
  createAppointmentselectDoctor:{ 
    marginTop: 3, 
    zIndex: 1, 
    width:'14vw' 
  },
  AppointmentPatientType:{
    marginTop: 3,
    zIndex: 3,
    width: '14vw'
  },
  createnextAppointmentPatientname:{ 
    fontSize: '1.5vw' 
  },
  preferTime:{ 
    flexDirection: 'row', 
    justifyContent: 'flex-end',
    width: "12vw", 
    height: '1vw'
  },
  preferTimeHour:{ 
    flexDirection: 'column', 
    width: '2vw', 
  },
  preferTimeMin:{ 
    flexDirection: 'column', 
    width: '2vw', 
  },
  patientTagfunction:{ 
    marginTop: 3, 
    zIndex: -1, 
    width: "13vw" 
  },
  patientImage:{ 
    height: '5vw', 
    width: '5vw', 
    borderRadius: 40 
  },
  patientDetails:{ 
    flexDirection: 'row', 
    marginBottom: 5, 
    marginTop: 5 
  },
  patientUniqueDetails:{ 
    fontSize: '0.8vw', 
    color: "grey" 
  },
  selectTimeShedule:{ 
    maxHeight: '20vw', 
    height: '4vw'
  },
  selectTimeSheduleview:{ 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    marginTop: 5 
  },
  closeButton:{ 
    backgroundColor: "white", 
    padding: 7, 
    borderRadius: 4, 
    width: '8vw', 
    justifyContent: 'center', 
    borderWidth: 1, 
    marginLeft: 5, 
    borderColor: "grey" 
  }
});