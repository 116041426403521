import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import Style from "../styles/Style";
import { color } from "../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const platform = Platform.OS;


export const SubHeading = (props) => {
    return (
        <Text style={[styles.SubHeading,{ color: props.showBold ? "#000000" : "#707070"}]}>{props.subHeading}</Text>
    )
}

export const Heading = (props) => {
    return (
        <Text style={styles.Heading}>{props.heading}</Text>
    )
}

export const LabelWithTextBox = (props) => {
    return (
        <View style={styles.LabelTextBoxView}>
            {
                props.label ?
                    <Text style={styles.LabelTextBoxText}>{props.label}</Text>
                    : null
            }

            <TextInput
               onChangeText={(text)=>{
                props.onChangeHighRiskTextValue(props.stateName,text);
            }}
                value={props.textBoxValue}
                style={styles.LabelTextBoxTextInput}
            />
        </View>
    )
}

export const LabelBoldWithTextBox = (props) => {
    return (
        <View style={styles.LabelTextBoxView}>
            <Text style={[styles.LabelTextBoxText,{fontWeight: '600' }]}>{props.label}</Text>
            <TextInput
                onChangeText={(text)=>{
                    props.onChangeHighRiskTextValue(props.stateName,text);
                }}
                value={props.textBoxValue}
                style={styles.LabelTextBoxTextInput}
            />
        </View>
    )
}

export const ParagraphContent = (props) => {
    return (
        <Text style={styles.PharagraphContent}>{props.content}</Text>
    )
}

export const ParagraphContentWithHighlight = (props) => {
    const highlight = string =>
        string.split(' ').map((word, i) => (
            <Text key={i}>
                <Text style={[styles.LabelTextBoxText,{fontWeight: '600', }]}>{word} </Text>
            </Text>
        ));

    return (
        <Text style={styles.PharagraphContent}>
            {props.content1}
            {highlight(props.highlight1)}
            {props.content2}
            {highlight(props.highlight2)}
            {props.content3}
            {highlight(props.highlight3)}
            {props.content4}
        </Text>
    )
}





export const HighRisk = (props) => {
   
    const data=props.highRiskFormDatas
   // console.log("clinicdetails --------------> "+JSON.stringify(props.clinicdetails))
    // console.log("highRiskFormDatas --------------> "+JSON.stringify(props.highRiskFormDatas.relationshipWithPatient))
    
    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            style={{ maxHeight: "70vh" }}
        >
            <View style={styles.HighRiskContainer}>


                <View style={styles.HighRiskWrapper}>
                    <LabelWithTextBox label={"Hopital Name:"} textBoxValue={props.clinicdetails.clinic_name} />
                    <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                    <LabelWithTextBox label={"Name:"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={props.selectedValueClinic_Droctor.value} stateName={"drName"} />
                    <LabelWithTextBox label={"Qualification:"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={data.drQualification} stateName={"drQualification"}/>
                    <LabelWithTextBox label={"Address:"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={data.drAddress} stateName={"drAddress"}/>
                    <LabelWithTextBox label={"Phone:"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={data.drPhone} stateName={"drPhone"}/>
                    <LabelWithTextBox label={"Email:"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={data.drEmail} stateName={"drEmail"}/>

                    <Heading heading={"High Risk Consent Form"} />

                    <ParagraphContent content={"(This consent must be taken - in case of serious / complicated / risky / new - surgeries / procedures; for removing any organ; in high risk patients; for proceeding with a surgery / procedure inspite of any abnormal parameters of the patient. This list is indicative not exhaustive and in case of a dilemma it is always advisable to take this high-risk consent and not a general consent.)"} />

                    <SubHeading subHeading={"Information about the patient:"} />

                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={props.clinicdetails.patient_name} />
                    <LabelWithTextBox label={"Age:"} textBoxValue={props.clinicdetails.patient_age} />
                    <LabelWithTextBox label={"Address:"} textBoxValue={props.clinicdetails.patient_address} />

                    <SubHeading subHeading={"Information about the patient’s guardian (proxy consent) :"} />

                    <ParagraphContent content={"(This clause should be filled and the guardian should sign this consent only in case of incompetent patients i.e. minors, old aged, unconscious, mentally unfit, disoriented patients)"} />
                    <LabelWithTextBox label={"Name: Mr./Ms./Mrs."} textBoxValue={data.guardianName}  stateName={"guardianName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>
                    <LabelWithTextBox label={"Address:"} textBoxValue={data.guardianAge}  stateName={"guardianAge"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>
                    <LabelWithTextBox label={"Phone:"}  textBoxValue={data.guardianPhoneNo}  stateName={"guardianPhoneNo"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    <LabelWithTextBox label={"Email:"}  textBoxValue={data.guardianEmail}  stateName={"guardianEmail"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    <LabelWithTextBox label={"Relationship with the patient, if any:"}  textBoxValue={data.relationshipWithPatient}  stateName={"relationshipWithPatient"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>


                    <ParagraphContentWithHighlight
                        content1={"[A person accompanying an unrelated patient should write "}
                        content2={" and when consent is given by higher authorities of a hospital, designation such as "}
                        content3={" or "}
                        content4={" must be written.]"}
                        highlight1={"‘Unrelated-accompanying’"}
                        highlight2={"‘Medical Superintendent’"}
                        highlight3={"‘Medical Director’"}
                    />

                    <SubHeading subHeading={"Scheduled date for the proposed intervention / procedure / surgery:"} />
                    <LabelWithTextBox label={""} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} textBoxValue={data.scheduledSurgeryDate} stateName={"scheduledSurgeryDate"}/>

                    <View style={styles.signatureview}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.InchargeView}>
                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist:"} />
                <LabelWithTextBox label={"Name:"} textBoxValue={data.drInChargeName}  stateName={"drInChargeName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>
                <LabelWithTextBox label={"Qualification:"} textBoxValue={data.drInChargeQualification}  stateName={"drInChargeQualification"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>

                <SubHeading subHeading={"Name/s of the proposed treatment / intervention / procedure / surgery:"} />
                <LabelWithTextBox label={"a. "} textBoxValue={data.nameOfTheProposedTreatmentA}  stateName={"nameOfTheProposedTreatmentA"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}/>
                <LabelWithTextBox label={"b. "} textBoxValue={data.nameOfTheProposedTreatmentB}  stateName={"nameOfTheProposedTreatmentB"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                <LabelWithTextBox label={"c. "} textBoxValue={data.nameOfTheProposedTreatmentC}  stateName={"nameOfTheProposedTreatmentC"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                <LabelWithTextBox label={"d. "} textBoxValue={data.nameOfTheProposedTreatmentD}  stateName={"nameOfTheProposedTreatmentD"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />


                <SubHeading subHeading={"I, the undersigned, do hereby state and confirm as follows:"} />
                <ParagraphContent content={""} />



                <ParagraphContent content={"1. I have been explained the following in terms and language that I understand. I have been explained the following in	(name of the language or dialect) that is spoken and understood by me."} />
                <ParagraphContent content={"2. I have been explained; I have been provided with the requisite information; I have understood; and thereafter I consent, authorize and direct the above named doctor-in-charge / principal surgeon / principal interventionist and his / her team with associates or assistants of his / her choice to perform the proposed treatment / intervention / procedure / surgery mentioned hereinabove."} />
                <ParagraphContent content={"3. Anticipated alternatives / additional - treatment / intervention / procedure / surgery that may have to be performed or attempted during the course of the proposed treatment / intervention / procedure / surgery: I have been explained and have understood that due to unforeseen circumstances duringthecourse of the proposed treatment / intervention / procedure / surgery something more or different than what has been originally planned and for which I am giving this consent may have to be performed or attempted. In all such eventualities, I authorize and give my consent to the medical / surgical team to perform such other and further acts that they may deem fit and proper using their professional judgment."} />
                <ParagraphContent content={"4. Alternatives to the proposed treatment / intervention / procedure / surgery: I have been explained and have understood the alternative methods and therapies of the proposed treatment / intervention / procedure / surgery, their respective benefits, material risks and disadvantages."} />
                <ParagraphContent content={"5. Material risk/s of the proposed treatment / intervention / procedure / surgery: I have been explained and have understood that the proposed treatment / intervention / procedure / surgery has certain material risks / complications and I have been provided with the requisite information about the same. I have also been explained and have understood that there are other undefined, unanticipated, unexplainable risks / complications that may occur during or after the proposed treatment / intervention / procedure / surgery."} />

                <View style={styles.signatureview}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.InchargeView}>

                <ParagraphContent content={"6. I state that the doctor-in-charge / principal surgeon / principal interventionist has answered all my questions to my satisfaction regarding the proposed treatment / intervention / procedure / surgery."} />
                <ParagraphContent content={"7. I have been explained and have understood that despite the best efforts there can be no assurance about the result of the proposed treatment / intervention / procedure / surgery. I further state and confirm that I have not been given any guarantee or warranty about the results of the proposed treatment / intervention / procedure / surgery."} />
                <ParagraphContent content={"8. I have been explained and have understood that despite all precautions complications may occurthatmay even result in death or serious disability."} />
                <ParagraphContent content={"9. I have been explained and have understood that the proposed treatment / procedure / surgery is uncommon / complicated / risky."} />
                <ParagraphContent content={"10. I have been explained and have understood that the proposed treatment / procedure / surgery is based on technique / procedure / drug / protocol that is relatively new."} />
                <ParagraphContent content={"11. I have been explained and have understood that the proposed treatment / procedure / surgery has high rate of failure."} />
                <ParagraphContent content={"12. I have been explained and have understood that the proposed treatment / procedure / surgery has high rate of relapse and recurrence."} />
                <ParagraphContent content={"13. I have been explained and have understood that the proposed treatment / procedure / surgery generally require multiple sessions / sittings and I give my consent for the same."} />
                <ParagraphContent content={"14. I have been explained and have understood that the proposed procedure / surgery generally require second intervention and I give my consent for the same."} />
                <ParagraphContent content={"15. I have been explained and have understood that the proposed procedure / surgery generally requires further corrective surgery / procedure to deal with known post-procedure / surgery complication/s and I give my consent for the same."} />
                <ParagraphContent content={"16. I have been explained and have understood that the proposed procedure / surgery generally requires ‘re-exploration’ and I give my consent for the same."} />
                <ParagraphContent content={"17. Multi-stage treatment: I have been explained and have understood that the proposed treatment / procedure / surgery is a multi-stage treatment / procedure / surgery and I do hereby consent for each and every stage of the same."} />

                <ParagraphContent content={"18. I have been explained and have understood that I may need long-term treatment."} />
                <ParagraphContent content={"19. I have been explained and have understood that I may need long-term follow-up care."} />
                <ParagraphContent content={"20. I have been explained and have understood that I may need longer period for recovery."} />

                <View style={styles.signatureview}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.InchargeView}>

                <ParagraphContent content={"22. Patient’s Representative/s"} />
                <ParagraphContent content={"I say that if I am incapacitated to communicate in future, further directions should be taken from the following person/s:"} />


                <View style={styles.PatientProfile}>
                    <View>
                        <LabelWithTextBox label={"Name:"}  textBoxValue={data.inCapacitatedCommunicationPersonName1}  stateName={"inCapacitatedCommunicationPersonName1"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                        <LabelWithTextBox label={"Address:"}  textBoxValue={data.inCapacitatedCommunicationPersonAddress1}  stateName={"inCapacitatedCommunicationPersonAddress1"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                        <LabelWithTextBox label={"Mobile No:"}  textBoxValue={data.inCapacitatedCommunicationPersonMobile1}  stateName={"inCapacitatedCommunicationPersonMobile1"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                    </View>
                    <View>
                        <LabelWithTextBox label={"Name:"}  textBoxValue={data.inCapacitatedCommunicationPersonName2}  stateName={"inCapacitatedCommunicationPersonName2"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                        <LabelWithTextBox label={"Address:"}  textBoxValue={data.inCapacitatedCommunicationPersonAddress2}  stateName={"inCapacitatedCommunicationPersonAddress2"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                        <LabelWithTextBox label={"Mobile No:"}  textBoxValue={data.inCapacitatedCommunicationPersonMobile2}  stateName={"inCapacitatedCommunicationPersonMobile2"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                    </View>
                    <View>
                        <LabelWithTextBox label={"Name:"}  textBoxValue={data.inCapacitatedCommunicationPersonName3}  stateName={"inCapacitatedCommunicationPersonName3"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                        <LabelWithTextBox label={"Address:"}  textBoxValue={data.inCapacitatedCommunicationPersonAddress3}  stateName={"inCapacitatedCommunicationPersonAddress3"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                        <LabelWithTextBox label={"Mobile No:"}  textBoxValue={data.inCapacitatedCommunicationPersonMobile3}  stateName={"inCapacitatedCommunicationPersonMobile3"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    </View>
                </View>




                    <ParagraphContent content={"23. I have been advised of the option to take a second opinion from another doctor regarding the proposed treatment / procedure / surgery."} />
                    <ParagraphContent content={"24. I state that after explaining, counseling and disclosures I had been given enough time to take decision for giving consent."} />
                    <ParagraphContent content={"25. I have signed this consent voluntarily out of my free will and without any kind of pressure or coercion."} />


                    <LabelBoldWithTextBox label={"Date & Time of giving consent:"}  textBoxValue={data.dateAndTimeGivenConsent}  stateName={"dateAndTimeGivenConsent"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                    <SubHeading subHeading={"Patient’s / Guardian’s Signature / Thumb"} />
                    <LabelBoldWithTextBox label={"Impression:"}  textBoxValue={data.impression}  stateName={"impression"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                    <LabelBoldWithTextBox label={"Patient’s / Guardian’s Name:"}  textBoxValue={data.patientOrGuardianName}  stateName={"patientOrGuardianName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />

                    <View style={styles.signatureview}>
                        <LabelWithTextBox label={"Signature of the Patient Name:"}  textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                        <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                    </View>
                </View>

                <View style={styles.InchargeView}>

                <SubHeading subHeading={"Witnesses:"} showBold={true} />
                <ParagraphContent content={"(Not compulsory. This part should be filled only in high risk cases; or when the patient / patient’s guardian is illiterate or not conversant with English; or when the patient has been unable to personally sign this consent for any reason.)"} />
                <ParagraphContent content={"We confirm that the aforesaid has been explained to the patient / patient’s guardian in the terms and language that the patient / patient’s guardian understand in our presence. We further confirm that the patient / patient’s guardian has put his / her signature / thumb impression on this consent in our presence."} />


                <LabelWithTextBox label={"Witnesses No. 1’s Signature:"} textBoxValue={data.witnessName1}  stateName={"witnessName1"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 1’s Name:"} textBoxValue={data.witnessSignature1}  stateName={"witnessSignature1"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />


                <LabelWithTextBox label={"Witnesses No. 2’s Signature:"} textBoxValue={data.witnessName2}  stateName={"witnessName2"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />
                <LabelWithTextBox label={"Witnesses No. 2’s Name:"} textBoxValue={data.witnessSignature2}  stateName={"witnessSignature2"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />



                <SubHeading subHeading={"Doctor-in-charge / Principal Surgeon / Principal Interventionist’s Signature:"} showBold={true} />
                <LabelWithTextBox label={""} textBoxValue={data.drInChargeSignature}  stateName={"drInChargeSignature"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue}  />


                <View style={styles.signatureview}>
                    <LabelWithTextBox label={"Signature of the Patient Name:"} textBoxValue={data.signatureOfThePatientName}  stateName={"signatureOfThePatientName"} onChangeHighRiskTextValue={props.onChangeHighRiskTextValue} />
                    <ParagraphContent content={"Signature of guardian if patient is minor, mentally unsound / unconscious"} />
                </View>

                </View>

                <View style={styles.createHighRiskForm}>

                    <TouchableOpacity
                        onPress={() => props.createHighRiskForm()} 
                        style={Style.certificateButton} >
                        <Icon name={"save"} size={platform === "web" ? "1vw" : "1.30vw"} color={color.white} style={styles.Icon} />
                        <Text style={Style.certificatetext}>{"Save"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity disabled={props.formId ? false : true} onPress={()=>{props.previewAndPrint()}} style={[Style.certificateButton1,{backgroundColor:props.formId ? color.themeDark : color.disableComponentColor}]} >
                        <Icon name={"print"} size={platform === "web" ? "1vw" : "1.30vw"} color={color.white} style={styles.Icon}/>
                        <Text style={Style.certificatetext}>{"Preview and print"}</Text>
                    </TouchableOpacity>

                    {/* <TouchableOpacity style={Style.certificateButton} >
                        <Icon name={"close"} size={platform === "web" ? 15 : 20} color={color.white} />
                        <Text style={Style.certificatetext}>{"Cancel"}</Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    SubHeading:{
        fontSize: "0.9vw", 
        fontWeight: '600', 
        paddingVertical: ".3vw" 
    },
    Heading:{
        fontSize: "1.2vw", 
        fontWeight: '600', 
        paddingVertical: ".3vw",
        color: "#000000", 
        alignSelf: 'center'
    },
    LabelTextBoxView:{ 
        flexDirection: 'row', 
        alignItems: 'center', 
        paddingVertical: ".3vw" 
    },
    LabelTextBoxText:{ 
        color: "#707070", 
        fontSize: "0.8vw" 
    },
    LabelTextBoxTextInput:{
        marginLeft: "1vw",
        height: "1.30vw",
        alignself: '#4E4E4E',
        color: 'grey',
        borderStyle: 'dotted',
        borderBottomWidth: 1,
        fontSize:'.91vw'
    },
    PharagraphContent:{ 
        lineHeight: "1.30vw", 
        color: "#707070", 
        fontSize: "0.8vw", 
        paddingVertical: ".3vw" 
    },
    HighRiskContainer:{ 
        paddingLeft: "1vw", 
        paddingRight: "1vw" 
    },
    HighRiskWrapper:{ 
        marginBottom:"2.60vw", 
        backgroundColor: "#F9FDFD", 
        paddingLeft: "1.95vw", 
        paddingRight: "1.95vw", 
        paddingTop: "1.30vw", 
        paddingBottom: "1.30vw" 
    },
    signatureview:{ 
        alignItems: 'flex-end', 
        marginTop: "3.58vw", 
        marginRight: "1.30vw" 
    },
    InchargeView:{ 
        marginBottom:"2.60vw", 
        backgroundColor: "#F9FDFD", 
        paddingLeft: "1.95vw", 
        paddingRight: "1.95vw", 
        paddingTop: "1.30vw", 
        paddingBottom: "1.30vw" 
    },
    PatientProfile:{ 
        alignItems: 'center', 
        marginTop: "2.60vw", 
        marginRight: "2.60vw" 
    },
    createHighRiskForm:{ 
        flexDirection: 'row', 
        alignSelf: 'center', 
        alignContent: 'center', 
        alignItems: 'center', 
        marginTop: "4.55vw" 
    },
    Icon:{
        marginRight:'1vw'
    }
})