//import liraries
import React, { Component } from 'react';
import { View, StyleSheet, Text, Dimensions, Image } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader } from '../../../BaseComponent';
import { color } from '../../../../../styles/Color'
import { TouchableOpacity } from 'react-native-web';
import { Constants } from '../../../../../utils/Constants';
import OpthamologyService from '../../../../../network/OpthamologyService';
import Pagination from '../../../common/Pagination';
const screenHeight = Dimensions.get("window").height;

const navigationHeader = [
  { label: "Vendor", value: "otVendor" },
  { label: "Vendor Payment", value: "vendorPayment" }
]
// create a component
class OTVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorDetailsList: [],
      vendorDetailsListAfterPagination: [],
      current_page: 1,
      records_per_page: 10
    }  
  }
  componentDidMount(){
     this.getVendorDetails()
  }
  getVendorDetails = () => {
        this.setState({
          current_page: 1,
          records_per_page: 10,
          vendorDetailsListAfterPagination: [],
          vendorDetailsList: []
        })
        var url = Constants.ADD_VENDOR
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getVendorDetailsSuccess,
            this.getVendorDetailsFailure
          );
      }
    
      getVendorDetailsSuccess = response => {
          if (response && response.status == "success") {
              this.setState({
                vendorDetailsList: response.data
              })
    }
  }
      getVendorDetailsFailure = response => {
      }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "")
  }
  renderCommonHeader = () => {
    return (
      <CommonHistorySectionHeader
        heading1={"S.No"}
        heading2={"Registration Date"}
        heading3={"Vendor Name"}
        heading4={"Registration No"}
        heading5={"Phone Number"}
        heading6={"Email ID"}
        columnSize={[0.05, 0.2, 0.2, 0.2, 0.2, 0.15]}  //total value is == of 1
        noOfColumn={6}
      />
    )
  }
  renderSubHeader = () => {
    return (
      <View style={styles.ButtonContainer}>
        <TouchableOpacity
          style={styles.addVendor}
          onPress={() => {
            this.props.changeScreen("addVendor")
          }}
        >
          <Text style={styles.addVendorText}>+ Vendor</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
              onPress={() => {
                this.printVendorDetails("pdf")
              }}
              style={styles.PrintStyle}>
              <Image style={styles.Image} source={require('../../../../../../assets/images/print_icon.png')} />
        </TouchableOpacity>
        <TouchableOpacity
              onPress={() => {
                this.printVendorDetails("pdf")
              }}
              style={styles.PrintStyle}>
              <Image style={styles.Image} source={require('../../../../../../assets/images/HomeScreenImages/Export.png')} />
        </TouchableOpacity> */}
      </View>
    )
  }
  renderVendorList = () => {
    return(
      <View>
      {
        this.state.vendorDetailsListAfterPagination && this.state.vendorDetailsListAfterPagination.map((item, index) => {
          let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page) + (index + 1)
          console.log(item.vendor_id,"vendor_id")
          return (
            <TouchableOpacity style={styles.dataContainer} onPress={() => {
              this.props.changeScreen("addVendor", {}, "", {}, "", {}, false, "", {}, {}, false, false, false, "", {}, item.vendor_id)
            }}>
              <View style={{ flex: 0.05, marginHorizontal: 20}}><Text style={[styles.TableDataText,{}]}>{sno}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.registration_date}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.name}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.dl_no ? item.dl_no : "-"}</Text></View>
              <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.mobile_number}</Text></View>
              <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.email ? item.email : "-"}</Text></View>
            </TouchableOpacity>
          )
        })}
    </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
       this.setState({
        current_page: current_page,
        records_per_page: records_per_page
       })
  }
  renderPaginationView() {
    return (
      <View style={{ marginHorizontal: 20 }}>
        {
          this.state.vendorDetailsList && this.state.vendorDetailsList.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalItems={this.state.vendorDetailsList}
              paginationCurrentPage={this.paginationCurrentPage.bind(this)}
            /> : null
        }
      </View>
    )
  }
  paginationChangedValues(data) {
    this.setState({
      vendorDetailsListAfterPagination: data
    })
  }
  render() {
    return (
      <View style={styles.container}>
        <NavigationTopHeader
          changeTabSection={this.changeTabSection.bind(this)}
          navigationHeaderList={navigationHeader}
          selectedTab={"otVendor"}
          isNavigationBorder={true}
        />
        <View style={styles.subContainer}>
          {this.renderSubHeader()}
          {this.renderCommonHeader()}
          {this.renderVendorList()}
          {this.renderPaginationView()}
        </View>
      </View>
    );
  }
}    

// define your styles
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: screenHeight - 65,
  },
  subContainer: {
    margin: '0.6vw'
  },
  addVendor: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.35vw",
  },
  addVendorText: {
    color: color.white,
    fontSize: "1vw"
  },
  ButtonContainer: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    paddingVertical: "0.5vw"
  },
  PrintStyle: { width: "2.60vw", height: "2.27vw", borderRadius: ".25vw", backgroundColor: color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.16vw", marginRight:".65vw" },
  Image: { width: "1.95vw", height: "1.95vw", tintColor: color.white },
  commonFlex: {
    flex: 0.14
  },
  TableDataText:{
    fontSize:'1vw'
  },
  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: color.lightGray,
    borderBottomWidth: 1,
    paddingVertical: "0.75vw",
    justifyContent: 'center',
    flex: 1
    
  }
});

//make this component available to the app
export default OTVendor;
