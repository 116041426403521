import React, { Component } from 'react'
import {
    View,
    Text,
    Dimensions,
    StyleSheet,
    ScrollView,
    Platform,
    Picker,
    TextInput,
    TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Pagination from '../../../ui/doctor/common/Pagination';
import { Tooltip, Checkbox } from 'antd';
import AsyncStorage from '../../../AsyncStorage';



const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;

const BillTypeList = [
    { label: "All Bill Type", value: "" },
    { label: "In Patient", value: "IP" },
    { label: "Out Patient", value: "OP" },
    { label: "Laboratory", value: "LAB" },
  ]
  
export default class CorporatebillsReport extends Component {
    constructor(props) {
        super(props)
        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");
        this.state = {
            filterDate: {},
            filterData: {
                fromDate: converted_date,
                toDate: converted_date
            },
            CorporateBillListAfterPagination: [],
            isAdminAccess: false,
            selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
            CorporateBillList: [],
            BilltypeInput:""
        }
    }

    async componentDidMount() {
        var loggedInData = await (AsyncStorage.getItem("loggedInData"))
        var loggedInDataObj = JSON.parse(loggedInData)
        var isOPT = false
        if (loggedInDataObj.clinic_type) {
            isOPT = loggedInDataObj.clinic_type.is_opt_clinic
        } else {
            isOPT = false;
        }
        this.setState({
            isOPTclinic: isOPT,
            isAdminAccess: loggedInDataObj.is_admin_access
        })
        this.getCorpBillList()
    }

    getCorpBillList = () => {
        var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
            "?search_key=" +
            "&from_date=" + this.state.filterData.fromDate +
            "&to_date=" + this.state.filterData.toDate +
            "&bill_type=" + this.state.BilltypeInput +
            "&d_sort=desc";
        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getCorpBillListSuccess,
            this.getCorpBillListFailure
        );
    }

    getCorpBillListSuccess = success => {
        if (success.status == "success") {
            this.setState({
                CorporateBillList: success.data,
            })

        }
    }

    getCorpBillListFailure = (error) => {
        this.props.showResposeValue('error', error.message)

    }
    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterData } = this.state;
        states["filterData"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate
        this.setState({ states, filterData })

    }

    onPressButton(key, value) {
        if (key == "save") {
            this.setState({
                CorporateBillList :[],
                CorporateBillListAfterPagination :[]
            }, ()=>this.getCorpBillList()) 
        }
        else if (key == "clear") {
            let fulldate = new Date();
            let converted_date = moment(fulldate).format("YYYY-MM-DD");
            this.setState({
                selectedAction: "",
                filterData: {
                    fromDate: converted_date,
                    toDate: converted_date
                },
                isClear: true,
                isClearInt: 1,
                BilltypeInput : "",
            }, () => {
                this.getCorpBillList()
                this.setState({
                    isClear: false
                }, () => {
                    this.renderDateFilter()
                })
            })
        } else if (key == "print") {
            this.PrintPatienToken()
        }
    }
    PrintPatienToken = () => {
        var serviceUrl = Constants.GET_PATIENT_BILLING_DEATILS +
            "?search_key=" +
            "&from_date=" + this.state.filterData.fromDate +
            "&to_date=" + this.state.filterData.toDate +
            "&bill_type=" + this.state.BilltypeInput +
            "&d_sort=desc" + "&export=excel";
            OpthamologyService.getInstance().documentUploadGet(
                serviceUrl,
                this.getExcelSuccess,
                this.getExcelFailure,
                "excel"
              );
        
    }

    getExcelSuccess = (success) => {
        if (success.statusText === "OK") {
          var pom = document.createElement('a');
          var csvContent = success.data; //here we load our csv data 
    
          let filename = success.headers["content-disposition"].split("filename=")[1]
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
        }
      }
      getExcelFailure = (error) => {
        this.props.showResposeValue("error", "No Data Available !")
      }
    
    // Print

    paginationChangedValues = (data) => {
        this.setState({
            CorporateBillListAfterPagination: data
        })
    }


    renderDateFilter = () => {
        return (
            <View>
                {this.state.isClear && this.state.isClearInt === 0 ? null :
                    this.state.isClear && this.state.isClearInt === 1 ?
                        <CommonDateFilter
                            removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                            defaultSelectedDateFilter={"Today"}
                            startDate={this.state.filterData.fromDate}
                            endDate={this.state.filterData.toDate}
                            clearPress={this.state.isClear}
                            showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                            filterDate={this.state.filterDate} /> :
                        <CommonDateFilter
                            removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
                            defaultSelectedDateFilter={"Today"}
                            startDate={this.state.filterData.fromDate}
                            endDate={this.state.filterData.toDate}
                            clearPress={this.state.isClear}
                            showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                            filterDate={this.state.filterDate} />
                }
            </View>
        )
    }

    returnCardBillValue (key){
        try{
            var Amount = 0 ;
            Amount = this.state.CorporateBillList.reduce((a, b) => a + (b[key] || 0), 0)
            if((Amount - Math.floor(Amount)) !== 0){
                return Amount.toFixed(2)
            }
            else{
                return Amount
            }
            
        }
        catch (e){
            return 0
        }
    }

    render() {
        var actions = [
            { label: "Search", value: "save" },
            { label: "Clear", value: "clear" },
            { label: 'Export', value: 'print' }
        ]
        return (
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: "65%", height: screenHeight - 65, backgroundColor: 'white' }}>
                    <View style={styles.TableContainer}>
                        <View>
                            <View style={{ width: "100%" }}>
                                <View style={[styles.TableTitleContainer]}>
                                    <View style={{ alignItems: "left", width: '18%' }} >
                                        <Text style={styles.TableHeadText}>Employee Name</Text>
                                    </View>
                                    <View style={{ alignItems: "left", width: '16%' }} >
                                        <Text style={styles.TableHeadText}>Company Name</Text>
                                    </View>
                                    <View style={{ alignItems: "left", width: '14%' }} >
                                        <Text style={styles.TableHeadText}>Bill No</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '10%' }} >
                                        <Text style={styles.TableHeadText}>Bill Date</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '14%' }} >
                                        <Text style={styles.TableHeadText}>Bill Type</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '12%' }} >
                                        <Text style={styles.TableHeadText}>Total Value (₹)</Text>
                                    </View>
                                    <View style={{ alignItems: "center", width: '16%' }} >
                                        <Text style={styles.TableHeadText}>Corporate Payable (₹)</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <ScrollView style={styles.MainTable}>
                            {this.state.CorporateBillList && this.state.CorporateBillList.length > 0 && this.state.CorporateBillListAfterPagination && this.state.CorporateBillListAfterPagination.length > 0 ? this.state.CorporateBillListAfterPagination.map((item, index) => {
                                return (
                                    <View key={index} style={[styles.TableDataWrapper]} >
                                        <View style={{ width: '18%', alignItems: 'left' }}>
                                            {item.name.length > 20 ?
                                                <Tooltip placement="top" title={item.name}>
                                                    <Text style={[styles.TableDataText]}> {item.name.slice(0, 20) + ".."}</Text>
                                                </Tooltip> :
                                                <Text style={[styles.TableDataText]}> {item.name}</Text>}
                                        </View>
                                        <View style={{ width: '16%', alignItems: 'left' }}>
                                            {item.company_name.length > 18 ?
                                                <Tooltip placement="top" title={item.company_name}>
                                                    <Text style={[styles.TableDataText]}> {item.company_name.slice(0, 18) + ".."}</Text>
                                                </Tooltip> :
                                                <Text style={[styles.TableDataText]}> {item.company_name}</Text>}
                                        </View>
                                        <View style={{ width: '14%', alignItems: 'left' }}><Text style={[styles.TableDataText]}>{item.invoice_number}</Text></View>
                                        <View style={{ width: '10%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.invoice_date}</Text></View>
                                        <View style={{ width: '14%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.bill}</Text></View>
                                        <View style={{ width: '12%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.gross_amount}</Text></View>
                                        <View style={{ width: '14%', alignItems: 'center' }}><Text style={[styles.TableDataText]}>{item.discount_amount}</Text></View>
                                    </View>
                                )
                            }) :
                                <View style={styles.NoRecordsView}>
                                    <Text style={styles.NoRecordsText}>No Records to show...</Text>
                                </View>}

                        </ScrollView>
                        {
                            <View style={styles.PaginationView}>
                                {

                                    this.state.CorporateBillList && this.state.CorporateBillList.length > 0 ?

                                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.CorporateBillList} />
                                        : null
                                }
                            </View>
                        }
                    </View>
                </View>

                {/* ============================ */}


                <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, padding: '1vw' }}>
                    <View>
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>Statistics</Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", width: "100%", marginTop: 15 }}>
                            <View style={styles.CardView}>
                                <View>
                                    <Text style={{ fontSize: '1.2vw', fontWeight: '500' }}>₹ {this.returnCardBillValue("gross_amount")}</Text>
                                    <Text style={styles.CardText}>{"Total Bill Value"}</Text>
                                </View>
                            </View>
                            <View style={styles.CardView}>
                                <View>
                                    <Text style={{ fontSize: '1.2vw', fontWeight: '500' }}>₹ {this.returnCardBillValue("discount_amount")}</Text>
                                    <Text style={styles.CardText}>{"Corporate Payable"}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ width: "100%" }}>
                            <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                                <Text style={styles.headerText}>Filter</Text>
                            </View>

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", minWidth: "10.98vw", flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "space-between", zIndex: 10 }}>
                                {this.renderDateFilter()}
                            </View>

                            <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                                <Text style={styles.headerText}>Bill Type</Text>
                            </View>
                            <View>
                                <Picker
                                    selectedValue={this.state.BilltypeInput}
                                    onValueChange={(itemvalue) => {
                                        this.setState({
                                            BilltypeInput: itemvalue
                                        })
                                    }}
                                    style={{ borderRadius: '0.26vw', width: "17vw", backgroundColor: color.themeShade, height: "2vw", fontSize: '0.9vw', borderColor: color.lightGray, marginLeft: '1vw' }}
                                >
                                    {BillTypeList.map((item, index) => (
                                        <Picker.Item key={index} label={item.label} value={item.value} />
                                    ))}
                                </Picker>

                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2 , marginTop:'3vw'}}>
                                {
                                    actions.map((item, index) => {
                                        return (
                                            <CommonButton
                                                item={{}}
                                                selectedvalue={{}}
                                                butttonText={item.label}
                                                buttonType={"theme"}
                                                buttonIcon={""}
                                                rightIcon={false}
                                                buttonAction={this.onPressButton.bind(this)}
                                                buttonKey={item.value} />
                                        )
                                    })
                                }
                            </View>
                        </View>
                    </View>
                </View >
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableBody: {
        height: '70vh', overflow: "scroll"
    },
    tableHeader: {
        backgroundColor: "rgb(238, 238, 238)", display: 'flex', width: '100%', flexDirection: 'row', paddingVertical: '1vw', paddingHorizontal: '1vw', marginTop: '2.3vw'
    },
    headerContainer: {
        padding: '.5vw',
        backgroundColor: "rgb(238, 238, 238)",
        marginTop: '1.5vw',
        borderRadius: '0.26vw'
    },
    headerText: {
        fontSize: "1vw",
        fontWeight: 500,
        marginLeft: ".5vw"
    },
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '2.5vw'
    },
    TableHeadText: {
        fontSize: '0.91vw',
        color: "black",
        fontWeight: '500'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2.25vw",
        paddingHorizontal: ".5vw",
        borderRadius: '0.4vw'
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "3.4vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    MainTable: {
        height: '72vh'
    },
    CardView: {
        justifyContent: 'center',
        alignSelf: 'center',
        shadowColor: "#090F36",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3,
        elevation: 5,
        backgroundColor: "#F8F8F8",
        borderRadius: 4,
        paddingHorizontal: 15,
        minWidth: '14.5vw',
        height: '4vw',
        marginRight: 20
    },
    CardText: {
        fontSize: '0.87vw', fontWeight: '500', textAlign: 'center', marginTop: '0.5vw', color: color.themeDark
    },
    CheckBoxView: {
        flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: "0.85vw", marginVertical: "0.5vw", flexWrap: "wrap", alignItems: 'center'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
});

