import React, { Component } from 'react'
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { Tabs } from 'antd';
import DoctorNotes from './Collab Summary Tabs/DoctorNotes';
import History from './Collab Summary Tabs/History'
import TestReport from './Collab Summary Tabs/TestReport'
import SurgeryNotes from './Collab Summary Tabs/SurgeryNotes'
import DischargeSummary from './Collab Summary Tabs/DischargeSummary';
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
export default class CollabSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: '1',
      patientAppointment: this.props.patientAppointment,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
    }
  }

  handleChange = activeKey => {
    this.setState({ activeKey });
  };


  showDocumentImage(flag, image = "", list=[]) {
    this.props.showDocumentImage(flag, image, list)
    // var states = this.state;
    // states["showDocumentImageFlag"] = flag
    // if (image) {
    //   states["showDocumentImage"] = image
    // }
    // this.setState({ states })
  }
  render() {
    return (
      <View>

        <View style={{ padding: '0.5vw', display:'flex', flexDirection:'row',justifyContent:'flex-start', marginRight:'2vw' }}>
          <TouchableOpacity
            onPress={() => { this.props.backToNotes() }}
            style={styles.BtnTouch}>
              <Icon size={'1.62vw'} name={"arrow-circle-left"} color={color.white} />
            <Text style={styles.ButtonText}>Back To Doctor Notes</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.TabView}>
          <Tabs onChange={this.handleChange} activeKey={this.state.activeKey}>
            <Tabs.TabPane tab="Doctor Notes" key="1">
              {this.state.activeKey === "1" ? <DoctorNotes patientAppointment={this.state.patientAppointment} /> : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="History" key="2">
              {this.state.activeKey === "2" ? <History patientAppointment={this.state.patientAppointment}/> : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Test Report" key="3">
              {this.state.activeKey === "3" ? <TestReport patientAppointment={this.state.patientAppointment} selectedClinicOrDoctor={this.state.selectedClinicOrDoctor} showDocumentImage={this.showDocumentImage.bind(this)}/> : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Discharge Summary" key="4">
              {this.state.activeKey === "4" ? <DischargeSummary patientAppointment={this.state.patientAppointment}/> : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Surgery Notes" key="5">
              {this.state.activeKey === "5" ? <SurgeryNotes patientAppointment={this.state.patientAppointment}/> : null}
            </Tabs.TabPane>
          </Tabs>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TabView: {
    marginLeft: '1vw'
  },
  BtnTouch: {
    paddingTop: '0.5vw',
    paddingLeft: '1.1vw',
    paddingRight: '1.1vw',
    paddingBottom: '0.5vw',
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center'
    // width: '6vw'
  },
  ButtonText: {
    color: color.white,
    textAlign: 'center',
    fontSize: ".9vw",
    fontWeight:'500',
    marginLeft:'1vw'
  },
})