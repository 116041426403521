import React from "react";
import {
  View,
  Text,
  CheckBox,
  // FlatList,
  // TextInput,
  ScrollView,
  // Image,
  Picker,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import { CommonButton, DoctorNotesCommonRightSideHeader, RightSectionSPH, RightPanelEyeSelection, CommonSectionHeader } from '../BaseComponent'
import Style from "../../../styles/Style";
import AsyncStorage from "../../../AsyncStorage";

const platform = Platform.OS;
const pageName = "subjectiverefraction";
const isPageName = "isSubjectiveRefraction";

export default class SubectiveRefractionNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.inputAidedDV = React.createRef();
    this.inputAidedNV = React.createRef();
    this.inputUnAidedDV = React.createRef();
    this.inputUnAidedNV = React.createRef();
    this.inputUC = React.createRef();
    this.inputSPH = React.createRef();
    this.inputCYL = React.createRef();
    this.inputAXIS = React.createRef();
    this.inputVA = React.createRef();
    this.inputPHVA = React.createRef();

    // {this.renderTitleWithMultipleBtn("ADD", this.state.ADD_options, 5, false, 'selectedADD', this.state.selectedADD, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
    // {this.renderNewButton(this.inputADD, 'ADD value', 'newADD', this.state.newADD, 'isNewADD', this.state.isNewADD, 'selectedADD', 'ADD_options')} 
    // <View style={{ height: 15 }}></View>
    
    this.inputBCDVA = React.createRef();
    this.inputBCNVA = React.createRef();
    this.inputADD = React.createRef();

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      id: null,
      patientAppointment: this.props.patientAppointment,
      selectedEye: {},
      selectedType: "",
      selectedAidedNV: "",
      selectedAidedDV: "",
      selectedUnAidedNV: "",
      selectedUnAidedDV: "",
      selectedUC: "",
      selectedSPH: "",
      selectedCYL: "",
      selectedAXIS: "",
      selectedVA: "",
      selectedPHVA: "",

      selectedBCDVA: "",
      selectedBCNVA:"",
      selectedADD:"",

      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,

      comment: "",
      occupation: "",
      chartUsed: "",
      muscle: "",

      copyRightToLeft: false,
      copyLeftToRight: false,
      getAvailableEyeTypes: this.props.getAvailableEyeTypes || [],
      getAvailableEyeTypesId: this.props.getAvailableEyeTypesId || [],

      glassPrescriptionData: [],

      // selectedType: {},

      //for new option
      NV_TYPE_options: Constants.NV_TYPE_OPTIONS,
      DV_TYPE_options: Constants.DV_TYPE_OPTIONS,
      SPH_options: Constants.SPH_OPTIONS,
      CYL_options: Constants.CYL_OPTIONS,
      AXIS_options: Constants.AXIS_OPTIONS,
      UC_options: Constants.UCVA_OPTION,
      VA_options: Constants.VA_OPTIONS,
      PHVA_options: Constants.UCVA_OPTION,

      BCDVA_options: Constants.BCDVA_OPTIONS,
      BCNVA_options: Constants.BCNVA,
      ADD_options: Constants.ADD,
      // flags
      isNewAidedNV: false,
      isNewAidedDV: false,
      isNewUnAidedNV: false,
      isNewUnAidedDV: false,
      isNewSPH: false,
      isNewCYL: false,
      isNewAXIS: false,
      isNewUC: false,
      isNewVA: false,
      isNewPHVA: false,

      // signs
      selectedSPHSign: { value: "+", label: "+" },
      selectedCYLSign: { value: "+", label: "+" },

      userType: "",
      selectedClinicOrDoctor: {},
      selectedDoctorId: null
    };
  }

  async componentDidMount() {

    let userType = await AsyncStorage.getItem("service_type");
    let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");

    let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};

    this.setState({
      userType: userType,
      selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(parsed_data).length>0 && parsed_data.id?parsed_data.id:null
    },()=>{
      this.getGlassPrescriptionData() 
    })
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
  }

  getGlassPrescriptionData() {
    var url = Constants.GLASS_PRESCRIPTION + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&doctor_id=" + this.state.patientAppointment.doctor_id;
    OpthamologyService.getInstance().getComplaints(
      // service_url,
      url,
      this,
      this.getGlassPrescriptionDataSuccess,
      this.getGlassPrescriptionDataFailure
    );
  }
  getGlassPrescriptionDataSuccess = success => {
    if (success.status == "success") {
      // alert("1test")
      if (success.data && success.data.glass_prescription.length > 0) {
        var data = success.data.glass_prescription;
        this.setState({
          glassPrescriptionData: data
        }, () => {
          // console.log(JSON.stringify(this.state.glassPrescriptionData))
        })
      }

    }
  }
  getGlassPrescriptionDataFailure = error => {
    console.log("getGlassPrescriptionDataFailure - - - - - - - - - - - - > " + JSON.stringify(error))

  }



  getComments = () => {

    var service_url = Constants.OPTHALMOLOGT_COMMENTS + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&section=Subjective Refraction" + "&patient_id=" + this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getCommentsSuccess,
      this.getCommentsFailure
    );
  };

  getCommentsSuccess = success => {
    if (success.status === "success") {
      //  alert(JSON.stringify(success))
      this.setState({
        comment: success.data.comments
      })
    }
  }
  getCommentsFailure = err => {
  //  alert(JSON.stringify(err))
  }


  componentWillReceiveProps(props) {

    // this.state.getAvailableEyeTypes = props.getAvailableEyeTypes;
    // this.state.getAvailableEyeTypesId = props.getAvailableEyeTypesId

    if(props.getAvailableEyeTypes && ( props.getAvailableEyeTypes.length != (this.state.getAvailableEyeTypes).length)) {

      this.setState({
        getAvailableEyeTypes: props.getAvailableEyeTypes,
        getAvailableEyeTypesId: props.getAvailableEyeTypesId
      })
    }

    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;
        let comments = props.editItem.item.comment;
         let subjectiveRefractionUserData = props.editItem.subjectiveRefractionUserData ? props.editItem.subjectiveRefractionUserData :{};
        
        if (id !== item.id) {

          var eye_type = { label: item.eye_type, value: item.eye_type }
          var states = this.state;
          let {ADD_options,BCNVA_options, BCDVA_options,NV_TYPE_options, DV_TYPE_options, SPH_options, CYL_options, AXIS_options, UC_options, VA_options, PHVA_options } = this.state;

          /* add item if not in list */
          let NV_list = [];
          let DV_list = [];
          let SPH_list = [];
          let CYL_list = [];
          let AXIS_list = [];
          let UC_list = [];
          let VA_list = [];
          let PHVA_list = [];

          let BCDVA_list = [];
          let BCNVA_list = [];
          let ADD_list = [];

          let positiveSign = { value: "+", label: "+" };
          let negativeSign = { value: "-", label: "-" };

          // if data not exist with default list, the data will add to list
          NV_list = this.addItemInArray(NV_TYPE_options, item.Aided_NV);
          DV_list = this.addItemInArray(DV_TYPE_options, item.Aided_DV);
          
          NV_list = this.addItemInArray(NV_list, item.UnAided_NV);
          DV_list = this.addItemInArray(DV_list, item.UnAided_DV);

          SPH_list = this.addItemInArray(SPH_options, item.SPH);
          CYL_list = this.addItemInArray(CYL_options, item.CYL);
          AXIS_list = this.addItemInArray(AXIS_options, item.AXIS);
          UC_list = this.addItemInArray(UC_options, item.UC);
          VA_list = this.addItemInArray(VA_options, item.VA);
          PHVA_list = this.addItemInArray(PHVA_options, item.PHVA);

          if(item.BCDVA){
            BCDVA_list = this.addItemInArray(BCDVA_options, item.BCDVA);
          }else{
            BCDVA_list = BCDVA_options
          }
          if(item.BCNVA){
            BCNVA_list = this.addItemInArray(BCNVA_options, item.BCNVA);
          }else{
            BCNVA_list = BCNVA_options
          }

          if(item.add){
            ADD_list = this.addItemInArray(ADD_options, item.add);
          }else{
            ADD_list = ADD_options
          }


          states["selectedType"] = (item.Aided_NV || item.Aided_DV)?"Aided":(item.UnAided_NV || item.UnAided_DV)?"UnAided":"";
          states["selectedAidedNV"] = item.Aided_NV;
          states["selectedAidedDV"] = item.Aided_DV;
          states["selectedUnAidedNV"] = item.UnAided_NV;
          states["selectedUnAidedDV"] = item.UnAided_DV;
          states["selectedUC"] = item.UC;
          states["selectedSPH"] = item.SPH?{ value: item.SPH, label: item.SPH }:null;
          states["selectedCYL"] = item.CYL?{ value: item.CYL, label: item.CYL }:null;
          states["selectedAXIS"] = item.AXIS;
          states["selectedVA"] = item.VA;
          states["selectedPHVA"] = item.PHVA;
          states["comment"] = comments;
          states["occupation"] = subjectiveRefractionUserData && subjectiveRefractionUserData.occupation ?  subjectiveRefractionUserData.occupation :"";
          states["chartUsed"] = subjectiveRefractionUserData && subjectiveRefractionUserData.chart_used ? subjectiveRefractionUserData.chart_used : "";
          states["muscle"] = subjectiveRefractionUserData && subjectiveRefractionUserData.muscle ? subjectiveRefractionUserData.muscle :"";

          states["selectedBCDVA"] = item.BCDVA ? item.BCDVA :"";
          states["selectedBCNVA"] = item.BCNVA ? item.BCNVA :"";
          states["selectedADD"] = item.add ? item.add :"";

          // update sign
          states["selectedSPHSign"] = item.SPH?(item.SPH).charAt(0)=="-"?negativeSign:positiveSign:positiveSign;
          states["selectedCYLSign"] = item.CYL?(item.CYL).charAt(0)=="-"?negativeSign:positiveSign:positiveSign;

          // var getType = { label: "NV", value: "NV" }
          // states["selectedType"] = "Aided";

          props.editItem


          this.setState({
            id: item.id,
            selectedEye: eye_type,
            states,

            NV_TYPE_options: JSON.parse(JSON.stringify(NV_list)),
            DV_TYPE_options: JSON.parse(JSON.stringify(DV_list)),
            SPH_options: JSON.parse(JSON.stringify(SPH_list)),
            CYL_options: JSON.parse(JSON.stringify(CYL_list)),
            AXIS_options: JSON.parse(JSON.stringify(AXIS_list)),
            UC_options: JSON.parse(JSON.stringify(UC_list)),
            VA_options: JSON.parse(JSON.stringify(VA_list)),
            PHVA_options: JSON.parse(JSON.stringify(PHVA_list)),
            BCDVA_options: JSON.parse(JSON.stringify(BCDVA_list)),
            BCNVA_options: JSON.parse(JSON.stringify(BCNVA_list)),
            ADD_options: JSON.parse(JSON.stringify(ADD_list)),

          }, () => {
            //this.getComments();
          });

        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }


  addComments = () => {

    
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGT_COMMENTS;
    var data = {
      "patient_id": this.state.patientAppointment.patient_id,
      "appointment_id": this.state.patientAppointment.appointment_id,
      "section": "Subjective Refraction",
      "comments": states.comment
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.addCommentsSuccess,
      this.addCommentsFailure
    );
  };
  addCommentsSuccess = success => {
    this.clearComplaint();
    // alert(JSON.stringify(success))
  }
  addCommentsFailure = error => { }


  addComplaint(eyeType, id) {

    let errorMessage = "";
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_SUBJECTIVEREFRACTION;

    let data = {}

    data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "eye_type": eyeType,
      "Aided_DV": states.selectedAidedDV,
      "Aided_NV": states.selectedAidedNV,
      "UnAided_DV": states.selectedUnAidedDV,
      "UnAided_NV": states.selectedUnAidedNV,
      "UC": states.selectedUC,
      // "SPH": parseFloat(states.selectedSPH) || null,
      "SPH": states.selectedSPH?states.selectedSPH.value:"",
      "CYL": states.selectedCYL?states.selectedCYL.value:"",
      "AXIS": states.selectedAXIS,
      // "VA": states.selectedVA,
      "PHVA": states.selectedPHVA,
      "BCDVA": states.selectedBCDVA,
      "BCNVA": states.selectedBCNVA,
      "add":states.selectedADD,
      "occupation": states.occupation,
      "chart_used": states.chartUsed?states.chartUsed:"",
      "muscle": states.muscle,
      "comment": states.comment
      
    };

    if (id) {
      data["id"] = id;
    }

    if( this.state.userType == Constants.ROLE_OPTOMETRY ) 
    {
      data['doctor_id'] = this.state.selectedDoctorId;
    }
    
    if(!eyeType){    
      errorMessage = "Select Eye Type";
    }
    else if(!states.selectedUC && !states.selectedSPH && !states.selectedCYL 
      && !states.selectedAidedDV && !states.selectedAidedNV && !states.selectedUnAidedDV && !states.selectedUnAidedNV 
      && !states.selectedAXIS && !states.selectedVA && !states.selectedPHVA 
      && !states.occupation && !states.chartUsed && !states.muscle && !states.comment
      ) {
      errorMessage = "Select any values";
    }
    
    if(errorMessage){

      this.props.showResposeValue("error", errorMessage)
    } else {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    }
  };

  getComplaintsSuccess = response => {
    // alert("complaint created successfully")


    if (response.status === "success") {

      var getAvailableEyeTypesId = this.state.getAvailableEyeTypesId;

      if (this.state.selectedEye.value == "RE" && this.state.copyRightToLeft) {

        if (getAvailableEyeTypesId.length > 0) {

          let second_eye_saved = false;

          for (var i = 0; i < getAvailableEyeTypesId.length; i++) {

            if (getAvailableEyeTypesId[i].eye_type == "LE") {

              second_eye_saved = true;

              this.setState({
                copyRightToLeft: false
              }, () => { 
                
                this.addComplaint("LE", getAvailableEyeTypesId[i].id)
                this.saveGlassPrescriptionData("LE")

              })
            // } else {
            //   this.setState({
            //     copyRightToLeft: false
            //   }, () => {
            //     this.addComplaint("LE", "")
            //     this.saveGlassPrescriptionData("LE")

            //   })
            }
          }

          if(!second_eye_saved) {

          // } else {
            this.setState({
              copyRightToLeft: false
            }, () => {
              this.addComplaint("LE", "")
              this.saveGlassPrescriptionData("LE")

            })
          // }
          }
        } else {

          this.setState({
            copyRightToLeft: false
          }, () => {
            this.addComplaint("LE", "")
            this.saveGlassPrescriptionData("LE")

          })
        }

      } else if (this.state.selectedEye.value == "LE" && this.state.copyLeftToRight) {

        if (getAvailableEyeTypesId.length > 0) {

          let second_eye_saved = false;

          for (var i = 0; i < getAvailableEyeTypesId.length; i++) {
          
            if (getAvailableEyeTypesId[i].eye_type == "RE") { 

              second_eye_saved = true;

              this.setState({
                copyLeftToRight: false
              }, () => {
                this.addComplaint("RE", getAvailableEyeTypesId[i].id)
                this.saveGlassPrescriptionData("RE")
              })

            // } else {
            //   this.setState({
            //     copyLeftToRight: false
            //   }, () => {
            //     this.addComplaint("RE", "")
            //     this.saveGlassPrescriptionData("RE")
            //   })

            }
          }

          if( !second_eye_saved ) {

          // } else {
            this.setState({
              copyLeftToRight: false
            }, () => {
              this.addComplaint("RE", "")
              this.saveGlassPrescriptionData("RE")
            })

          // }
          }
        } else {
          this.setState({
            copyLeftToRight: false
          }, () => {
            this.addComplaint("RE", "")
            this.saveGlassPrescriptionData("RE")
          })
        }

      } else {


        this.saveGlassPrescriptionData(this.state.selectedEye.value)

        this.props.clearEditedItem(pageName, {})
        this.addComments();
        var field = this.state;
        field["complaintsData"] = response.data;
        this.setState({ field });

        // this.showSuccessMessage();
        // clear data
        // this.clearComplaint();

        // reload data
        this.props.refreshData(pageName);
        this.props.showResposeValue("success", response.message)
      }
    } else {
      this.props.showResposeValue("error", response.message)
    }

  };

  getComplaintsFailure = error => {
    // this.showErrorMessage();
    //alert("complaint created unsuccessfully")
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)

  };

  onPressButton(key, selectedvalue) {
    
    if (key == "selectedEye") {
      this.setState({
        selectedEye: selectedvalue
      })
    
    } else if (key == "selectedType") {
      
      this.setState({
        selectedType: selectedvalue
      })
    }
  }

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = {};
    states['selectedUC'] = "";
    states['selectedSPH'] = "";
    states['selectedCYL'] = "";
    states['selectedAXIS'] = "";
    states['selectedVA'] = "";
    states['selectedPHVA'] = "";
    states["id"] = "";
    states["comment"] = "";
    states["occupation"] = "";
    states["chartUsed"] = "";
    states["muscle"] = "";
    states['selectedType'] = "";
    states['selectedAidedNV'] = "";
    states['selectedAidedDV'] = "";
    states['selectedUnAidedNV'] = "";
    states['selectedUnAidedDV'] = "";

    states['selectedSPHSign'] = { label: "+", value: "+"};
    states['selectedCYLSign'] = { label: "+", value: "+"};

    this.setState({ states }, () => {
      this.props.clearEditedItem(pageName, {})
    });

  }

  updateState = (key, value) => {
    // console.log("selected sign value :",key, value);
    let states = this.state;
    
    states[key] = value;

    this.setState({ states });

  }

  renderDropDown(state, values, text) {
    // var getDefaultValues=Constants.DEFAULT_OCCUPATIONS_SR
    var getDefaultValues = values;
    var states = this.state
    return (
      <View style={{ width: "90%" }}>
        <Picker
          enabled={!this.state.isLocked}
          selectedValue={states[state]}
          style={[styles.DorpDownPicker,Style.allButtonBorderRadius]}
          // itemStyle={{fontSize:10}}
          onValueChange={(itemvalue) => {
            states[state] = itemvalue
            this.setState({
              states
            })
          }}
        >
          <Picker.Item key={-1} label={text} value={""} />
          {getDefaultValues.map((i, index) => (
            <Picker.Item key={index} label={i.value} value={i.value} />
          ))}
        </Picker>
      </View>
    )
  }
  renderCheckBox(label, value, key) {
    return (
      <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
        <CheckBox value={value}
          style={styles.CheckBoxView}
          onValueChange={() => {

            var states = this.state;

            states[key] = !value

            this.setState({
              states
            })
          }}

        />
        <Text style={styles.CheckboxLabel}>{label}</Text>
      </View>
    );
  }
  
  renderNewButton=(refInput, placeholder, stateName, stateValue, flagName, flagValue, updateSelectState, updateToList)=>{

    return (
      <View>
        {/* new block */}
        {flagValue ?
          <View style={{ marginTop: '0.6vw' }}> 
          {this.renderTextFieldViewDrug(placeholder, stateName, stateValue, false, 1, refInput,
            { flag: flagName, newState: stateName, forState: updateSelectState, stateList: updateToList })}
          </View>: null
        }
        <TouchableOpacity onPress={() => { this.triggerNewItemAdd(flagName, refInput) }} style={{width:'7.8vw'}}>
            {this.renderIconBtn("plus", "New", false, "key")}
        </TouchableOpacity>
        {/* new block end */}
      </View>
    )
  }


  // renderNewSubHeader(heading){
  //   return(
  //     <View style={{ paddingLeft: 15,justifyContent:"center",  height: 32, backgroundColor: color.sectionHeadingDarkColor }}>
  //       <Text style={{ fontSize: 14 }}>{heading}</Text>
  //     </View>
  //   )
  // }


  render() {

    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>

          <DoctorNotesCommonRightSideHeader title={"Subjective Refraction"} clearAllData={this.clearComplaint.bind(this)} />


          <View style={{ flexDirection: 'row', marginBottom: '1.2vw' }}>
            <View style={{ flex: 0.35 }}>

              {this.renderDropDown("occupation", Constants.DEFAULT_OCCUPATIONS_SR, "Select Occupation")}
            </View>
            <View style={{ flex: 0.35 }}>
              {this.renderDropDown("chartUsed", Constants.DEFAULT_CHARTUSED_SR, "Select Chart Used")}
            </View>
            <View style={{ flex: 0.3 }}>
              {this.renderDropDown("muscle", Constants.DEFAULT_MUSCLES_SR, "Select Muscle")}
            </View>
          </View>


          <View>
              {this.renderNewSubHeader("Eye Type")}

              <RightPanelEyeSelection 
                getAvailableEyeTypes={this.props.getAvailableEyeTypes} 
                id={this.state.id} 
                isLocked={this.state.isLocked} 
                selectedEye={this.state.selectedEye} 
                onPressButton={this.onPressButton.bind(this)} />
                
              {/*
            <View style={{ flexDirection: 'row', flexWrap: 'wrap'}}>
              {

                this.state.getAvailableEyeTypes.length > 0 ?
                  Constants.eye_type.map((item, index) => {

                    var tempData = false

                    var result = this.state.getAvailableEyeTypes && this.state.getAvailableEyeTypes.map((eye, index) => {
                      if (eye == item.value) {
                        tempData = true
                        return true
                      } else {
                        return false
                      }
                    })
                    return (
                      <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                        <CommonButton
                          disable={(tempData || this.state.isLocked) ? true : false}
                          item={item}
                          selectedvalue={this.state.selectedEye}
                          butttonText={item.label}
                          buttonType={"outlineNonTheme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={"selectedEye"} />
                      </View>
                    )
                  })
                  :
                  Constants.eye_type.map((item, index) => {
                    return (
                      <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                        <CommonButton
                          disable={this.state.isLocked}
                          item={item}
                          selectedvalue={this.state.selectedEye}
                          butttonText={item.label}
                          buttonType={"outlineNonTheme"}
                          buttonIcon={""}
                          rightIcon={false}
                          buttonAction={this.onPressButton.bind(this)}
                          buttonKey={"selectedEye"} />
                      </View>
                    )
                  })
              }
            </View>
            */}
          </View>
{/* 
          {this.renderTitleWithMultipleBtn("Vision", Constants.TYPE_AID_NONAID, 2, false,
          'selectedType', this.state.selectedType, this.updateState.bind(this), false, true,"","",false,this.state.isLocked)}
 */}
        <View style={styles.ViewHeight}></View>

        {/* {this.state.selectedType === "Aided"?
        this.renderTitleWithMultipleBtn("DV", this.state.DV_TYPE_options, 5, false, 'selectedAidedDV', this.state.selectedAidedDV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):
        this.state.selectedType === "UnAided"?this.renderTitleWithMultipleBtn("DV", this.state.DV_TYPE_options, 5, false, 'selectedUnAidedDV', this.state.selectedUnAidedDV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):null
        }
        {this.state.selectedType === "Aided"?
        this.renderNewButton(this.inputAidedDV, 'DV value', 'newDV', this.state.newDV, 'isNewDV', this.state.isNewDV, 'selectedAidedDV', 'DV_TYPE_options'):
        this.state.selectedType === "UnAided"?this.renderNewButton(this.inputUnAidedDV, 'DV value', 'newDV', this.state.newDV, 'isNewDV', this.state.isNewDV, 'selectedUnAidedDV', 'DV_TYPE_options'):null
        } 
        {this.state.selectedType?<View style={{ height: 15 }}></View>:null}
 */}
{/* 
        {this.state.selectedType === "Aided"?
        this.renderTitleWithMultipleBtn("NV", this.state.NV_TYPE_options, 5, false, 'selectedAidedNV', this.state.selectedAidedNV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):
        this.state.selectedType === "UnAided"?this.renderTitleWithMultipleBtn("NV", this.state.NV_TYPE_options, 5, false, 'selectedUnAidedNV', this.state.selectedUnAidedNV, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked):null
        }
        {this.state.selectedType === "Aided"?
        this.renderNewButton(this.inputAidedNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedAidedNV', 'NV_TYPE_options'):
        this.state.selectedType === "UnAided"?this.renderNewButton(this.inputUnAidedNV, 'NV value', 'newNV', this.state.newNV, 'isNewNV', this.state.isNewNV, 'selectedUnAidedNV', 'NV_TYPE_options'):null
        } 
        {this.state.selectedType?<View style={{ height: 15 }}></View>:null}
 */}


          {this.renderTitleWithMultipleBtn("UCVA", this.state.UC_options, 5, false, 'selectedUC', this.state.selectedUC, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {/* {this.renderNewButton(this.inputUC, 'UC value', 'newUC', this.state.newUC,'isNewUC', this.state.isNewUC, 'selectedUC', 'UC_options')}  */}
          <View style={styles.ViewHeight}></View>

          {/* {this.renderTitleWithMultipleBtn("SPH", this.state.SPH_options, 5, false, 'selectedSPH', this.state.selectedSPH, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputSPH, 'SPH value', 'newSPH', this.state.newSPH, 'isNewSPH', this.state.isNewSPH, 'selectedSPH', 'SPH_options')}  */}
          
          <RightSectionSPH heading={"SPH"} stateSignKey={"selectedSPHSign"} selectedSign={this.state.selectedSPHSign} disable={this.state.isLocked} 
          stateOptionKey={"selectedSPH"} options={this.state.SPH_options} selectedvalue={this.state.selectedSPH} onPressButton={this.updateState.bind(this)} />

          <View style={styles.ViewHeight}></View>

          {/* {this.renderTitleWithMultipleBtn("CYL", this.state.CYL_options, 5, false, 'selectedCYL', this.state.selectedCYL, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputCYL, 'CYL value', 'newCYL', this.state.newCYL, 'isNewCYL', this.state.isNewCYL, 'selectedCYL', 'CYL_options')}  */}

          <RightSectionSPH heading={"CYL"} stateSignKey={"selectedCYLSign"} selectedSign={this.state.selectedCYLSign} disable={this.state.isLocked} 
          stateOptionKey={"selectedCYL"} options={this.state.CYL_options} selectedvalue={this.state.selectedCYL} onPressButton={this.updateState.bind(this)} />
          <View style={styles.ViewHeight}></View>

          {this.renderTitleWithMultipleBtn("AXIS", this.state.AXIS_options, 5, false, 'selectedAXIS', this.state.selectedAXIS, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {/* {this.renderNewButton(this.inputAXIS, 'AXIS value', 'newAXIS', this.state.newAXIS, 'isNewAXIS', this.state.isNewAXIS, 'selectedAXIS', 'AXIS_options')}  */}
          <View style={styles.ViewHeight}></View>

          {/* {this.renderTitleWithMultipleBtn("V/A", this.state.VA_options, 5, false, 'selectedVA', this.state.selectedVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputVA, 'V/A value', 'newVA', this.state.newVA, 'isNewVA', this.state.isNewVA, 'selectedVA', 'VA_options')} 
          <View style={{ height: 15 }}></View> */}

          {this.renderTitleWithMultipleBtn("PH V/A", this.state.PHVA_options, 5, false, 'selectedPHVA', this.state.selectedPHVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {/* {this.renderNewButton(this.inputPHVA, 'PH V/A value', 'newPHVA', this.state.newPHVA, 'isNewPHVA', this.state.isNewPHVA, 'selectedPHVA', 'VA_options')}  */}
          <View style={{ height: 15 }}></View>

          {this.renderTitleWithMultipleBtn("BCDVA", this.state.BCDVA_options, 5, false, 'selectedBCDVA', this.state.selectedBCDVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {/* {this.renderNewButton(this.inputBCDVA, 'BCDVA value', 'newBCDVA', this.state.newBCDVA, 'isNewBCDVA', this.state.isNewBCDVA, 'selectedBCDVA', 'BCDVA_options')}  */}
          <View style={styles.ViewHeight}></View>

          {this.renderTitleWithMultipleBtn("ADD", this.state.ADD_options, 5, false, 'selectedADD', this.state.selectedADD, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {this.renderNewButton(this.inputADD, 'ADD value', 'newADD', this.state.newADD, 'isNewADD', this.state.isNewADD, 'selectedADD', 'ADD_options')} 
          <View style={styles.ViewHeight}></View>

          {this.renderTitleWithMultipleBtn("BCNVA", this.state.BCNVA_options, 5, false, 'selectedBCNVA', this.state.selectedBCNVA, this.updateState.bind(this), false, true, "", "", false, this.state.isLocked)}
          {/* {this.renderNewButton(this.inputBCNVA, 'BCNVA value', 'newBCNVA', this.state.newBCNVA, 'isNewBCNVA', this.state.isNewBCNVA, 'selectedBCNVA', 'BCNVA_options')}  */}
          <View style={styles.ViewHeight}></View>
          




          {/* {this.renderSubHeadingBorder("Additional Refraction", false, true)} */}



          <View>
          <CommonSectionHeader heading={"Comments"}  />
          <View style={styles.ViewHeight}></View>
            {this.renderTextFieldView("Comments", "comment", this.state.comment, true, 4, "", {}, "", false, this.state.isLocked)}
          </View>

          {
            this.state.selectedEye.value == "RE" ?
              this.renderCheckBox("Copy Right Value To Left", this.state.copyRightToLeft, "copyRightToLeft")
              : this.state.selectedEye.value == "LE" ?
                this.renderCheckBox("Copy Left Value To Right", this.state.copyLeftToRight, "copyLeftToRight")
                : null
          }

          <View style={{ marginTop: '1.2vw',alignSelf:"center" }}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.addComplaint(this.state.selectedEye.value, this.state.id) }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>

        </View>
      </ScrollView>
    );
  }

  saveGlassPrescriptionData(eyeType) {
   
    // var url = "https://dev.api.emedhub.in/v2/ophthalmology/gp/";
   
    let data = {}
    var states = this.state;
    var glassPrescriptionData = this.state.glassPrescriptionData;

    if (glassPrescriptionData.length > 0) {
      for (let i = 0; i < glassPrescriptionData.length; i++) {
        if (glassPrescriptionData[i].eye_type == eyeType) {
          // if (states.selectedType.value == "DV") {
          //   data = {
          //     "appointment_id": this.state.patientAppointment.appointment_id,
          //     "eye_type": eyeType,
          //     "DV_SPH": parseFloat(states.selectedSPH) || null,
          //     "DV_CYL": states.selectedCYL,
          //     "DV_AXIS": states.selectedAXIS,
          //     "DV_VISION": states.selectedVA,
          //     "id": glassPrescriptionData[i].id
          //   };
          // } else if (states.selectedType.value == "NV") {
            data = {
              "appointment_id": this.state.patientAppointment.appointment_id,
              "eye_type": eyeType,
              "Aided_DV": states.selectedAidedDV,
              "Aided_NV": states.selectedAidedNV,
              "UnAided_DV": states.selectedUnAidedDV,
              "UnAided_NV": states.selectedUnAidedNV,
              "SPH": states.selectedSPH,
              "CYL": states.selectedCYL,
              "AXIS": states.selectedAXIS,
              "VISION": states.selectedVA,
              "id": glassPrescriptionData[i].id
            };
          // }
        }
      }

      // this.glassPrescriptionPostApi(data)
    }

    
  }

  glassPrescriptionPostApi(data) {

    var service_url = Constants.GLASS_PRESCRIPTION;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.glassPrescriptionPostApiuccess,
      this.glassPrescriptionPosFailure
    );
  }

  glassPrescriptionPostApiuccess = response => {
    if (response.status === "success") {
      // this.glassPrescriptionPostApi(data,true)
      this.getGlassPrescriptionData();
      this.props.refreshData("glassPrescription");
      this.props.showResposeValue("success", response.message)

    } else {
      this.props.showResposeValue("error", response.message)
    }

  };

  glassPrescriptionPosFailure = error => {
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };
}

const styles = StyleSheet.create({
      DorpDownPicker: { 
        height: '2vw', 
        backgroundColor: 'white', 
        fontSize: '0.8vw',
        fontSize: '0.9vw'
      },
      CheckBoxView: { 
        marginRight: '0.8vw',
        height: '1.2vw',
        width: '1.2vw'
      },
      CheckboxLabel: { 
        fontSize: '0.8vw', 
        color: "#888888" 
      },
      ViewHeight: { 
        height: '1vw' 
      }
})