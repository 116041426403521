import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "HistoryOfAbstinenceAndDeAddictionTreatment"


export class HistoryOfAbstinenceAndDeAddictionTreatment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofHistoryOfAbstinenceAndDeAddictionTreatment: {},

            hideEditIcon: false,
            isheading:false

        }
    }

    componentDidMount() {
        this.getHistoryOfAbstinenceAndDeAddictionTreatment()
    }

    getHistoryOfAbstinenceAndDeAddictionTreatment() {
        var service_url = Constants.PSY_ABSTINENCE_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getHistoryOfAbstinenceAndDeAddictionTreatmentSuccess,
            this.getHistoryOfAbstinenceAndDeAddictionTreatmentFailure
        );
    }

    getHistoryOfAbstinenceAndDeAddictionTreatmentSuccess = success => {
        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {

                } else if (key == "nature_treatment" || key == "duration_abstinence" || key == "reason_relapses" || key == "additional_notes") {
                    var prepareData = {};
                    if (key == "nature_treatment") {
                        prepareData = {
                            key: "Name of Treatment",
                            value: data[key],
                        }
                    } else if (key == "duration_abstinence") {
                        prepareData = {
                            key: "Duration of Abstinence",
                            value: data[key],
                        }
                    } else if (key == "reason_relapses") {
                        prepareData = {
                            key: "Reason for relapses",
                            value: data[key],
                        }
                    } else if (key == "additional_notes") {
                        prepareData = {
                            key: "Additional Notes",
                            value: data[key],
                        }
                    }
                    stringVaues.push(prepareData)
                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofHistoryOfAbstinenceAndDeAddictionTreatment: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getHistoryOfAbstinenceAndDeAddictionTreatmentFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }
    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;
        if (props.refreshRighSideComponentName == pageName) {
            this.getHistoryOfAbstinenceAndDeAddictionTreatment();
            this.props.refreshRighSideComponent("");
        }
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofHistoryOfAbstinenceAndDeAddictionTreatment, pageName)
        }, 100);

    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"History of Abstinence & De-Addiction Treatment"}
                editImage={this.state.hideEditIcon  || Object.keys(this.state.getListofHistoryOfAbstinenceAndDeAddictionTreatment).length == 0 ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }


    renderListOfData(item) {
        if (item.value) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
