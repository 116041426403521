import React from "react";
import {
  View,
  Text,
  // FlatList,
  TouchableOpacity,
  Platform,
  TextInput
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";

import {CommonButton} from "../BaseComponent"
const platform = Platform.OS;
const pageTitle = "Fundus";
const pageName = "fundus";

import Messages from "../../../utils/InfoMessages"

export default class FundusNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      id: "",
      isEdit: false,
      template_name: "",
      data: {
        id: "",
        "eye_type": "",
        "template_name": "",
        "media": "",
        "optic_disc": "",
        "cd_ratio": "",
        "macula": ""
      },
      selectedTemplate: "",
      selectedEye: "",        // template name
      selectedMedia: "",
      selectedOpticDisk: "",
      selectedCDRatio: "",
      selectedMacula: "",
      selectedButton: "",     // observation
      selectedButtonText: "", // comments
      templateData: [],
      isTemplate: false,

      rightEyeID :"",
      leftEyeID :""
    };
  }

  componentDidMount() {
    this.getTemplate()
  }

  componentWillReceiveProps(props) {

    if(props.fundusEyeAllDetails && props.fundusEyeAllDetails.pageName == pageName){
      var states =this.state;

      if(props.fundusEyeAllDetails.item && props.fundusEyeAllDetails.item.length > 0){
        var fundusEyes= props.fundusEyeAllDetails.item
        for (let i = 0; i < fundusEyes.length; i++) {
          if(fundusEyes[i].eye_type == "RE"){
            states.rightEyeID = fundusEyes[i].id
          }else if(fundusEyes[i].eye_type == "LE"){
            states.leftEyeID = fundusEyes[i].id
          }
        }
      }

      this.setState({
        states
      },()=>{

        this.props.fundusEyeDetails({});
  
      })

    }

    

    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {

          var states = this.state

          states['id'] = item.id;
          states['selectedEye'] = (item.eye_type).toString();
          states['selectedMedia'] = (item.media).toString();
          states['selectedOpticDisk'] = (item.optic_disc).toString();
          states['selectedCDRatio'] = (item.cd_ratio).toString();
          states['selectedMacula'] = (item.macula).toString();

          this.setState({
            states
          });

        }
      }
    }
  }

  getTemplate = () => {
    var service_url = Constants.OPTHALMOLOGY_FUNDUS_TEMPLATE;  //"?template_id=" + this.state.data.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSuccessResponse,
      this.getFailureFailure
    );
  };

  getSuccessResponse = response => {
    if (response.status === "success") {
      var data = this.state;
      data["templateData"] = response.data;
      this.setState({ data });
    }
  };

  getFailureFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getTreatmentTemplateselect = (template_id) => {
    var service_url = Constants.OPTHALMOLOGY_FUNDUS_TEMPLATE + "?template_id=" + template_id; //this.state.data.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTreatmentTemplateselectSuccess,
      this.getTreatmentTemplateselectFailure
    );
  };

  getTreatmentTemplateselectSuccess = response => {
    if (response.status === "success") {
      
      let states = this.state;
      let data = response.data;

      // for disaplay purpose
      states['selectedTemplate'] = data.template_name;
      states['selectedEye'] = data.eye_type;
      states['selectedMedia'] = data.media;
      states['selectedOpticDisk'] = data.optic_disc;
      states['selectedCDRatio'] = data.cd_ratio;
      states['selectedMacula'] = data.macula;

      states['data'] = data;


      if(data.eye_type == "RE"){
        states["id"] = states.rightEyeID;
        states["selectedEye"] = "RE"
      }else if(data.eye_type == "LE"){
        states["selectedEye"] = "LE"
        states["id"] = states.leftEyeID;
      }

      this.setState({ states },()=>{
        this.addComplaint()
      });

    }

    //console.log("checking")
    //console.log(response.data)
  };


  getTreatmentTemplateselectFailure = error => {
  //  console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  renderSelectTemplate = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressTemplate.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  renderTestIconBtn = (text, icon, createBtn,key) => {
    return (
      <View
        style={{
          height: 40,
          width: key=="Addtotemplate"?"50%":text === "New" ? 70 : "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 10 : 10,
          flexDirection: "row",
          backgroundColor: createBtn ? color.themeShadeBackground : text === "New" ? color.white : color.themeShadeBackground,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 1,
          borderColor: color.themeDark,
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: platform === "web" ? 17 : 14,
            color: text === "New" ? "#848484" : color.themeDark,
            fontWeight: "500"
          }}
        >
          {text}
        </Text>


      </View>
    );
  };

  onPressAction = (key, value) => {
   
    let {data} = this.state;
    data[key] = value;

    this.setState({
      data
    })
  }

  onPressTemplate = (key, value) => {
    
    //console.log("key" + key + "values" + value)
    
    //this.setState({ selectedButtonText: value, selectedButton: key })
    let states = this.state;
    states[key] = value;

    // let {data} = this.state;
    // data['id'] = value;

    this.setState({
      states
    })

    this.getTreatmentTemplateselect(value);
  }

  addComplaint = event => {


    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_FUNDUS;

    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "eye_type": states.selectedEye,
      "media": states.selectedMedia,
      "optic_disc": states.selectedOpticDisk,
      "cd_ratio": states.selectedCDRatio,
      "macula": states.selectedMacula
    };

     if (states.id) {
       data["id"] = states.id;
     }
   

    if (states.selectedEye) {
      if(states.selectedMedia || states.selectedOpticDisk || states.selectedCDRatio || states.selectedMacula)
      {
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );}else{
        this.props.showResposeValue("error",Messages.FundusAllFieldsAreEmpty)
      }
    }else{
      this.props.showResposeValue("error",Messages.SelectEyeType)
    }
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      
      // var field = this.state;
      // field["data"] = response.data;
      // this.setState({ field });

      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData("fundus");
      this.props.showResposeValue("success",response.message)
    }else{
      this.props.showResposeValue("error",response.message)

    }
  };

  getComplaintsFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  clearComplaint = event => {

    let states = this.state;
    
    states["selectedTemplate"] = "";
    states["selectedEye"] = "";
    states["selectedMedia"] = "";
    states["selectedOpticDisk"] = "";
    states["selectedCDRatio"] = "";
    states["selectedMacula"] = "";

    this.setState({ states });

  }

  saveTemplate = event => {

   // console.log(" add Template ");

    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_FUNDUS_TEMPLATE;

    let templateData = {
      //"appointment_id": this.state.patientAppointment.appointment_id,
      // "eye_type": states.data.eye_type,
      // "comments": states.data.comments,
      // "template_name": states.template_name

      "eye_type": states.selectedEye,
      "template_name": states.template_name,
      "media": states.selectedMedia,
      "optic_disc": states.selectedOpticDisk,
      "cd_ratio": states.selectedCDRatio,
      "macula": states.selectedMacula
    };



    OpthamologyService.getInstance().postComplaints(
      service_url,
      templateData,
      this,
      this.getTemplateSuccess,
      this.getTemplateFailure,

    );

  };

  getTemplateSuccess = response => {
    if (response.status === "success") {
      // var field = this.state;
      // field = response.data;
      // this.setState({
      //   field,
      // });

      // clear data
      this.clearComplaint();
      this.getTemplate()

      // reload data
      // this.props.refreshData("treatmentandadvice");
    }
  };

  getTemplateFailure = error => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  renderNewTemplateAdd = () => {

    return (
      <View style={{ marginTop: 50 }}>
        <TextInput
          placeholder="Template Name"
          style={{ height: 40, borderColor: color.rightPanelInputBorder, borderWidth: 1, color: 'black', borderRadius: 4, paddingHorizontal: 10, textAlign: 'justify' }}
          onChangeText={text => this.setState({ template_name: text })}
          value={this.state.template_name}
        />

        <View style={{ marginTop: 50, flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
        {this.state.template_name?

          <View style={{ minWidth: 100, }}>
            {/* <TouchableOpacity onPress={() => {
              this.saveTemplate()
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity> */}

            <CommonButton
              disable={this.state.isLocked}
              item={{}}
              selectedvalue={{}}
              butttonText={"Add"}
              buttonType={"theme"}
              buttonIcon={""}
              rightIcon={false}
              // buttonAction={this.saveTemplate.bind(this)} 
              buttonAction={() => { 
                this.setState({ isTemplate: false }) 
                this.saveTemplate()
              }} 
              buttonKey={"addTemplate"}
            />

          </View>:null
         } 
          <View style={{ minWidth: 100 }}>
            {/* <TouchableOpacity onPress={() => {
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTestIconBtn("Cancel", "", false)}
            </TouchableOpacity> */} 
            <CommonButton
              disable={this.state.isLocked}
              item={{}}
              selectedvalue={{}}
              butttonText={"Cancel"}
              buttonType={"theme"}
              buttonIcon={""}
              rightIcon={false}
              buttonAction={() => { 
                this.setState({ isTemplate: false }) 
              }} 
              buttonKey={"cancelTemplate"}
            />

          </View>
        </View>
      </View>

    )
  }

  render() {
    var templateArr = [];
    var temp = {}

    var templatedata = this.state.templateData;
    //console.log("testingg temlatedata")
    //console.log(templatedata)

    templatedata && templatedata.length > 0 && templatedata.map(item => {
      if(item.id && item.template_name){
      temp = {
        value: item.id,
        label: item.template_name
      }
      templateArr.push(temp)
    }
    });

    return (
      <View>

          {this.state.isTemplate === true ?
          <View> 
            {this.renderSubHeadingBorder("Fundus", false, true)}
            {this.renderNewTemplateAdd()}
          </View> :
          <View>            
            
            {this.renderSubHeadingBorder("Fundus", false, true)}

            {this.renderTitleWithMultipleBtn("Templates", templateArr, 4, false, 'selectedTemplate', this.state.selectedTemplate, this.onPressTemplate.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {this.renderTitleWithMultipleBtn("Media", Constants.FUNDUS_MEDIA, 6, false, 'selectedMedia', this.state.selectedMedia, this.updateState.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {this.renderTitleWithMultipleBtn("Optic Disk", Constants.FUNDUS_MEDIA, 6, false, 'selectedOpticDisk', this.state.selectedOpticDisk, this.updateState.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {this.renderTitleWithMultipleBtn("C/D Ratio", Constants.FUNDUS_MEDIA, 6, false, 'selectedCDRatio', this.state.selectedCDRatio, this.updateState.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {this.renderTitleWithMultipleBtn("Macula", Constants.FUNDUS_MEDIA, 6, false, 'selectedMacula', this.state.selectedMacula, this.updateState.bind(this), false, true,'','',false,this.state.isLocked)}
            
            {/* <View style={{ marginTop: 20, zIndex: -1,alignSelf:"center"  }}>
              <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this)}>
                {this.renderTextBtn("Add", true, false)}
              </TouchableOpacity>
            </View>
            
            <View style={{ marginTop: 20, zIndex: -1 }}>
              <TouchableOpacity
                disabled={this.state.isLocked}
                onPress={() => this.setState({ isTemplate: true })}
              >
                {this.renderTestIconBtn("Add to Templates", "", false, "Addtotemplate")}
              </TouchableOpacity>
              
            </View> */}

<View style={{flexDirection:"row",marginTop: 20,zIndex: -4,justifyContent:'center' }}>
            <View style={{  marginRight:20}}>
            {/* <TouchableOpacity
                disabled={this.state.isLocked}
                style={{alignItems:'center',}}
                onPress={() =>{this.saveTemplate()}}
              >
                {this.renderTestIconBtn("Save Template", "", false, "","Addtotemplate")}
              </TouchableOpacity> */}

                  <CommonButton
                    disable={this.state.isLocked}
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Add to Templates"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={false}
                    // buttonAction={this.saveTemplate.bind(this)} 
                    buttonAction={this._validateSaveTemplateData.bind(this)} 
                    buttonKey={"SaveTemplate"}
                  />
             
            </View>
            <View style={{}}>
            {/* <TouchableOpacity disabled={this.state.isLocked} onPress={()=>this.addComplaint()}>
                {this.renderTextBtn("Add", true, false,"Add")}
              </TouchableOpacity> */}
                                <CommonButton
                    disable={this.state.isLocked}
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Add"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.addComplaint.bind(this)}
                    buttonKey={"add"}
                  />
            </View>
          </View>  
          </View>
        }
      </View>
    );
  }

  _validateSaveTemplateData () {

    let { selectedEye, selectedMedia, selectedOpticDisk, selectedCDRatio, selectedMacula } = this.state;

    if(!selectedEye) {

      this.props.showResposeValue("error", "Select eye type")

    } else if( selectedMedia=="" && selectedOpticDisk=="" && selectedCDRatio=="" && selectedMacula=="" ) {

      this.props.showResposeValue("error", "Select other values")

    } else {

      this.setState({ isTemplate: true })
    }
  }


}
