import React, { Component, useState } from 'react';
import { View, ScrollView, Dimensions, StyleSheet, Text } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import moment from "moment";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';


export const CommonDatePicker = (props) => {
    return (
      

        <View style={{flex: 0.48, marginBottom: 18, marginTop: 5,marginRight: 15 }}>
            <Text style={{ position: 'absolute', marginLeft: 10, backgroundColor: "white", fontSize: '0.8vw', paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{props.label}</Text>
            <DatePicker
                // disabledDate={current => {
                //   return current && current > moment().add(0, "days");
                // }}
                placeholder={""}
                value={props.selectedDate ? moment(props.selectedDate)
                    : null}
                onChange={date => {
                    props.callBackValue(date, props.datePickerKey)

                }
                }
                format={"DD-MM-YYYY"}
                style={{
                    zIndex: -1, 
                    width:"100%",
                    borderColor: '#CDD1D5',
                    height: '2.27vw', borderRadius: 4, borderWidth: 1, marginTop: 5
                }}
            />
        </View>

        
    )
}

export const CommonTextBox = (props) => {
    return (
        <View style={{ width: 150, marginBottom: 18, marginTop: 5 }}>
            <Text style={{ position: 'absolute', marginLeft: 10, backgroundColor: "white", fontSize: 12, paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{props.label}</Text>
            <DatePicker
                // disabledDate={current => {
                //   return current && current > moment().add(0, "days");
                // }}
                onChange={date => {
                    props.callBackValue(date, props.datePickerKey)
                }
                }
                style={{
                    zIndex: -1, width: 150,
                    borderColor: '#CDD1D5',
                    height: '2.27vw', borderRadius: 4, borderWidth: 1, marginTop: 5
                }}
            />
        </View>
    )
}

export const Demographics = () => {

    const listOfGender = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Trans", value: "Trans" },
    ]

    const [demographicsFilter, changeDemographicsFilter] = useState({})
    const [selectedGender, changeGender] = useState({})


    function getDatePickerDate(date, key) {
        if (key == "demoDOBFromKey") {
            demographicsFilter["dobFrom"] = date
        }
        else if (key == "demoDOBToKey") {
            demographicsFilter["dobTo"] = date
        } else if (key == "appointmentFromDate") {
            demographicsFilter["appointmentFromDate"] = date
        } else if (key == "appointmentToDate") {
            demographicsFilter["appointmentToDate"] = date
        }
        changeDemographicsFilter(demographicsFilter)
    }

    function getGender(key, selectedGender) {
        changeGender(selectedGender)
        // demographicsFilter["genderObj"] = selectedGender;
        demographicsFilter["gender"] = selectedGender.value;
        changeDemographicsFilter(demographicsFilter)
    }

    return (
        <View>
            <View>
                <CommonHistorySectionHeader
                    heading1={"Date Of Birth"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: 'row', justifyContent: "space-between", marginHorizontal: 20 }}>
                    <CommonDatePicker label={"from"} selectedDate={demographicsFilter.from} datePickerKey={"demoDOBFromKey"} callBackValue={getDatePickerDate.bind(this)} />
                    <CommonDatePicker label={"to"} selectedDate={demographicsFilter.to} datePickerKey={"demoDOBToKey"} callBackValue={getDatePickerDate.bind(this)} />
                </View>
            </View>
            <View>
                <CommonHistorySectionHeader
                    heading1={"Appointment"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: 'row', justifyContent: "space-between", marginHorizontal: 20 }}>
                    <CommonDatePicker label={"from"} selectedDate={demographicsFilter.from} datePickerKey={"appointmentFromDate"} callBackValue={getDatePickerDate.bind(this)} />
                    <CommonDatePicker label={"to"} selectedDate={demographicsFilter.to} datePickerKey={"appointmentToDate"} callBackValue={getDatePickerDate.bind(this)} />
                </View>
            </View>
            <View>
                <CommonHistorySectionHeader
                    heading1={"Gender"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: "row", flexWrap: 'wrap', marginHorizontal: 20 }}>
                    {
                        listOfGender.map((item, index) => {
                            return (
                                <View key={index} style={{ marginTop: 8, marginRight: 8 }}>
                                    <CommonButton
                                        item={item} butttonText={item.label}
                                        selectedvalue={selectedGender}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""} rightIcon={true}
                                        buttonAction={getGender.bind(this)}
                                        buttonKey={"selectedGender"} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        </View>
    );
}