//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, Picker } from 'react-native';
// import Styles from '../../../../styles/Style'
// import { color } from '../../../../styles/Color'

import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton } from '../../BaseComponent'
import { color } from '../../../../styles/Color';
import Styles from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
// import { white } from 'react-native-paper/lib/typescript/src/styles/colors';

// create a component
const pageName = "isObstetricalHistory";
class ObstetricalHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            year: "",
            placeofDelivery: "",

            durationOfPregnancyInWeek: 0,
            hoursOfDelivery: 0,

            typeOfDelivery: {},

            complicationsForMotherOrBaby: "",
            gender: "",
            birthWeight: "",
            presentHealth: "",

            id: "",
        }
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        // if(props.refreshRighSideComponentName == pageName ){
        //     this.getMenstrualHistory();
        //     this.props.refreshRighSideComponent("");
        // }

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var states = this.state;
                var data = props.editHistoryItem.editItem;
                // console.log("states ****************> " + JSON.stringify(props.editHistoryItem.editItem))
                states["year"] = data.year_of_delivery_or_abortion;
                states["id"] = data.id;
                states["placeofDelivery"] = data.place_of_delivery_or_abortion;

                states["durationOfPregnancyInWeek"] = data.duration_of_pregnancy;
                states["hoursOfDelivery"] = data.hours_of_Labor;

                states["typeOfDelivery"] = { label: data.type_of_delivery, value: data.type_of_delivery };

                states["complicationsForMotherOrBaby"] = data.complications_for_mother_or_baby;
                states["gender"] = data.child_gender;
                states["birthWeight"] = data.child_birth_weight;
                states["presentHealth"] = data.child_present_health;

                this.setState({ states }, () => {
                    this.props.editHistory({})
                })

            }
        }
    }


    titleText(title) {
        return (
            <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{title}</Text>
        )
    }
    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }

    renderTextBox(key, value, width, backgroundTheme = false, keyType = false) {
        return (
            <TextInput
                // keyboardType= {keyType == true  ? 'numeric' : ''}
                style={[{ marginTop: 10, paddingLeft: backgroundTheme ? null : 10, height: 30, borderWidth: 1, borderColor: backgroundTheme ? color.themeDark : "#888888", width: width }, Styles.allButtonBorderRadius,
                // backgroundColor:backgroundTheme ?color.themeDark :null 
                backgroundTheme ? {
                    backgroundColor: color.themeDark,
                    textAlign: 'center', color: color.white
                } : {}
                ]}
                maxLength={key == "year" ? 4 : key == "presentHealth" ? 30 : null}
                value={value}
                onChangeText={(text) => {
                    var states = this.state;
                    if (keyType == true) {
                        if (this.numberValidation(text)) {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }

                    } else {
                        states[key] = text
                        this.setState({
                            states
                        })
                    }


                }}
            />

        )
    }


    typeOfDeliveryButtons(label, key, selectedValue) {
        var data = []

        if (key == "typeOfDelivery") {
            data = [
                { label: "Normal", value: "Normal" },
                { label: "C - Section", value: "C - Section" },
                { label: "Vacuum Extraction", value: "Vacuum Extraction" },
                { label: "Forceps", value: "Forceps" },
                { label: "VBAC (Vaginal birth after cesarean)", value: "VBAC" }, // 
            ]
        } else {
            data = [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ]
        }


        return (
            <View>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{label}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }
    onPressButton(key, selectedValue) {
        var states = this.state;

        states[key] = selectedValue;

        this.setState({ states })
    }

    clearAllValues() {
        var states = this.state;

        states["year"] = "";
        states["id"] = "";
        states["placeofDelivery"] = "";

        states["durationOfPregnancyInWeek"] = 0;
        states["hoursOfDelivery"] = 0;

        states["typeOfDelivery"] = {};

        states["complicationsForMotherOrBaby"] = "";
        states["gender"] = "";
        states["birthWeight"] = "";
        states["presentHealth"] = "";

        this.setState({ states })
    }


    onPressAddButton() {

        var states = this.state;

        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "appointment_id": this.state.patientAppointment.appointment_id,

            "year_of_delivery_or_abortion": states.year,
            "place_of_delivery_or_abortion": states.placeofDelivery,
            "duration_of_pregnancy": states.durationOfPregnancyInWeek,
            "hours_of_Labor": states.hoursOfDelivery,
            "type_of_delivery": states.typeOfDelivery.value,
            "complications_for_mother_or_baby": states.complicationsForMotherOrBaby,
            "child_gender": states.gender,
            "child_birth_weight": states.birthWeight,
            "child_present_health": states.presentHealth
        }

        if (this.state.id) {
            data["obstetrical_id"] = this.state.id
        }

        var service_url = Constants.OBSTETRICAL_HISTORY_SAVE
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.obstetricalHistorySaveSuccess,
            this.obstetricalHistorySaveFailure
        );

    }


    obstetricalHistorySaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
    }
    obstetricalHistorySaveFailure = error => {
        this.props.showResposeValue('error', error.message)
        console.log("obstetricalHistorySaveFailure <<~~~~~>> " + JSON.stringify(error))
    }

    renderRichTextBox(key, values, label = "") {
        return (
            <View>
                {
                    label ?
                        <Text style={{ color: "#BCBCBC", fontSize: 12, fontWeight: '400' }}>{label}</Text> :
                        <Text style={{ color: "#BCBCBC", fontSize: 12, fontWeight: '400' }}>{"Details if any"}</Text>
                }

                <TextInput
                    value={values}
                    placeholder={""}
                    multiline={true}
                    maxLength={key == "complicationsForMotherOrBaby" ? 100 : null}
                    textAlignVertical="top"
                    style={{ marginTop: 5, marginBottom: 20, textAlignVertical: 'top', paddingLeft: 10, backgroundColor: color.white, borderRadius: 8, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 65 }}
                    onChangeText={(text) => {
                        var statets = this.state;
                        statets[key] = text
                        this.setState({ statets })
                    }}
                />
            </View>
        )
    }


    renderBirthWeightField(label, placeholder, key, values) {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginLeft: 10 }}>
                <Text style={{ fontSize: 15, fontWeight: '500' }}>{label}</Text>
                <View style={{ flexDirection: 'row', marginLeft: 5 }}>
                    <TextInput
                        // keyboardType='numeric'
                        value={values}
                        placeholder={placeholder}
                        style={{ width: 100, paddingLeft: 10, backgroundColor: color.white, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, borderColor: color.rightPanelInputBorder, borderWidth: 1, height: 30 }}
                        onChangeText={(text) => {
                            var statets = this.state;
                            if (this.numberValidation(text)) {

                                statets[key] = text
                                this.setState({
                                    statets
                                })
                            }
                        }}
                    />
                    <View style={{ paddingHorizontal: 10, justifyContent: 'center', height: 30, borderColor: color.rightPanelInputBorder, borderTopRightRadius: 4, borderBottomRightRadius: 4, borderWidth: 1 }}>
                        <Text>{"Kgs"}</Text>
                    </View>
                </View>
            </View>
        )
    }


    renderDropDownGender(key, selectedValue) {
        return (
            <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{"Gender"}</Text>
                <View>
                    <Picker
                        //   enabled={!this.state.isLocked}
                        selectedValue={selectedValue}
                        style={[Styles.pickerRoundCornerView, Styles.allButtonBorderRadius, { backgroundColor: color.themeDark, marginTop: 14, paddingLeft: 5, marginLeft: 5, height: 30, color: color.white }]}
                        itemStyle={[Styles.pickerFont, { color: color.white }]}
                        onValueChange={(itemvalue) => {
                            var states = this.state;

                            states[key] = itemvalue
                            this.setState({ states })
                        }}
                    >
                        <Picker.Item label="Select" value="" />
                        <Picker.Item label="Male" value="m" />
                        <Picker.Item label="Female" value="f" />
                        {/* <Picker.Item label="Trans" value="t" /> */}
                    </Picker>
                </View>
            </View>
        )
    }



    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true} heading={"Obstetrical History"} />

                <View style={styles.shadow} >
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        {this.titleText("Year")}
                        {this.renderTextBox("year", this.state.year, 60, "", true)}
                        {this.titleText("Place of Delivery / Abortion")}
                        {this.renderTextBox("placeofDelivery", this.state.placeofDelivery, 80)}
                    </View>
                </View>


                <View style={styles.shadow}>
                    <View style={{flexDirection:"row"}}>
                    <View style={{ flex: 0.8, }}>
                        {this.titleText("Duration of Pregnancy in weeks?")}
                    </View>
                    <View style={{ flex: 0.2}}>
                        {this.renderTextBox("durationOfPregnancyInWeek", this.state.durationOfPregnancyInWeek, 50, true, true)}
                    </View>
                    </View>
                </View>


                <View style={styles.shadow}>
                <View style={{flexDirection:"row"}}>
                    <View style={{ flex: 0.8, }}>
                        {this.titleText("Hours of Labor")}
                    </View>
                    <View style={{ flex: 0.2, marginLeft: 20 }}>
                        {this.renderTextBox("hoursOfDelivery", this.state.hoursOfDelivery, 50, true, true)}
                    </View>
                </View>
                </View>
                <View style={{ marginBottom: 15 }}>
                    {this.typeOfDeliveryButtons("Type of Delivery", "typeOfDelivery", this.state.typeOfDelivery)}
                </View>


                {this.renderRichTextBox("complicationsForMotherOrBaby", this.state.complicationsForMotherOrBaby, "Complications For Mother / Baby")}


                <View style={{ flexDirection: 'row', }}>
                    {this.renderDropDownGender("gender", this.state.gender)}
                    {this.renderBirthWeightField("Birth Weight", "", "birthWeight", this.state.birthWeight)}
                </View>
                <View>
                    {this.titleText("Present Health")}
                    {this.renderTextBox("presentHealth", this.state.presentHealth, 250)}
                </View>


                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    shadow: {
        width: "100%", shadowColor: "#000",
        marginTop: 5,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5, backgroundColor: "white"
    }
});

//make this component available to the app
export default ObstetricalHistoryNew;
