import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput, Picker, Image } from "react-native";
import { color } from "../../../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import Edit from "react-native-vector-icons/Entypo";
import CommonDateFilter, { CommonHistorySectionHeader }  from "../../../../BaseComponent";
import moment from "moment";
import Style from "../../../../../../styles/Style";
import Pagination from "../../../../common/Pagination";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { Constants } from "../../../../../../utils/Constants";
import  { Tooltip } from 'antd';
import CompleteImg from "../../../../../../../assets/images/complete.png"
import CancelImg from "../../../../../../../assets/images/cancel.png"

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class NewIolRequest extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            searchIol: "",
            filterDate: {
            fromDate: converted_date,
            toDate: converted_date
            },
            selectedStatus: "Pending",
            newIolRequestList: [],
            newIolRequestListAfterPagination: [],
            current_page: 1,
            records_per_page: 10,
            isEditClick: false,
            isSelectedIndex: "",
            editLineItem: {}
        }
    }

    componentDidMount(){
        this.getIolRequestList()
    }

    getIolRequestList = () => {
        var states = this.state
        this.setState({
            current_page: 1,
            records_per_page: 10,
            newIolRequestList: [],
            newIolRequestListAfterPagination: []
        })

        var url = Constants.OT_INVENTORY_IOL_NEW_IOL + "?brand_name=" + states.searchIol + "&from_date=" + this.state.filterDate.fromDate + "&to_date=" + this.state.filterDate.toDate + "&status=" + states.selectedStatus
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIolRequestListSuccess,
            this.getIolRequestListFailure
        );
    }

    getIolRequestListSuccess = (response) => {
        if (response.status === "Success") {
            this.setState({
                newIolRequestList: response.data,
            })
        }
    }

    showSelectedFilteredDate(date) {
        // alert(JSON.stringify(date))
        var states = this.state.filterDate;
        states["filterDate"] = date
        states["fromDate"] = date.fromDate;
        states["toDate"] = date.toDate;
        this.setState({ states }, () => {
            this.getIolRequestList()
        })
    }

    renderRequestHeader = () => {
        return (
            <View style={{ zIndex: -1 }}>
              { this.state.selectedStatus === "Pending" ?
                <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Model Name"}
                heading4={"IOL Type"}
                heading5={"IOL Power"}
                heading6={"Action"}
                columnSize={[0.05, 0.25, 0.25, 0.18, 0.17, 0.1]}  //total value is == of 1
                noOfColumn={6}
                /> : this.state.selectedStatus === "Declined" ?
                <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Model Name"}
                heading4={"IOL Type"}
                heading5={"IOL Power"}
                heading6={"Comments"}
                heading7={"Status"}
                columnSize={[0.05, 0.20, 0.20, 0.15, 0.15, 0.15, 0.1]}  //total value is == of 1
                noOfColumn={7}
                /> : 
                <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Brand Name"}
                heading3={"Model Name"}
                heading4={"IOL Type"}
                heading5={"IOL Power"}
                heading6={"Status"}
                columnSize={[0.05, 0.25, 0.25, 0.18, 0.17, 0.1]}  //total value is == of 1
                noOfColumn={6}
                /> }
            </View>
        )
    }

    renderTextInput = (value, flex, stateKey, index, item) => {
        var tempData ={
            "index": index,
            "created_date": item.created_date,
            "brand_name" : item.brand_name,
            "model_name" : item.model_name,
            "iol_type_name" : item.iol_type_name,
            "iol_power" : item.iol_power,
        }

        return (
        <View style={{ flex: flex, height: "20%" }}>
            <TextInput
                style={{
                    padding: 6,
                    margin: 5,
                    backgroundColor: color.white,
                    borderColor: "#CDD1D5",
                    borderRadius: 4,
                    borderWidth: 1,
                }}
                editable={this.state.isEditClick && index === this.state.isSelectedIndex ? true : false}
                value={value}
                onChangeText={(text) => {
                        tempData[stateKey] = text
                        this.setState({
                            editLineItem: tempData
                        },()=>{
                            if(index == tempData.index){
                            var {newIolRequestListAfterPagination} = this.state
                            newIolRequestListAfterPagination[index]["brand_name"] = this.state.editLineItem.brand_name
                            newIolRequestListAfterPagination[index]["model_name"] = this.state.editLineItem.model_name
                            newIolRequestListAfterPagination[index]["iol_type_name"] = this.state.editLineItem.iol_type_name
                            newIolRequestListAfterPagination[index]["iol_power"] = this.state.editLineItem.iol_power

                            this.setState({
                            newIolRequestListAfterPagination: newIolRequestListAfterPagination
                            })
                            }
                           
                        })
                        
                    
                }}

            />
        </View>
    )
    } 

    pendingIOlRequestDelete = (id) => {
        var url = Constants.OT_INVENTORY_IOL_NEW_IOL + "?id=" + id;

        OpthamologyService.getInstance().deleteComplaints(
            url,
            {},
            this,
            this.pendingIOlRequestDeleteSuccess,
            this.pendingIOlRequestDeleteFailure
        )
    }

    pendingIOlRequestDeleteSuccess = (response) => {
        if (response.status == 'Success') {
            this.props.showResposeValue("success", response.message)
            this.getIolRequestList()
        }else{
            this.props.showResposeValue("error", response.message)
        }
    }

    newIolRequestEdit = (item) => {
        var url = Constants.OT_INVENTORY_IOL_NEW_IOL

        var editItem = {
            id: item.id,
            brand_name: item.brand_name,
            model_name: item.model_name,
            iol_power: item.iol_power,
            iol_type_id: item.iol_type,
        }

        var data = {
            "products": [
                editItem
            ]
        }

        OpthamologyService.getInstance().postComplaints(
            url,
            data,
            this,
            this.newIolRequestEditSuccess,
            this.newIolRequestEditFailure
        );
    }

    newIolRequestEditSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.getIolRequestList()
        }else{
            this.props.showResposeValue("error", response.message)
        }
    }

    newIolRequestEditFailure = (response) => {

    }

    renderDataView =  () => {
        var { current_page, records_per_page } = this.state
        return (
          <View>
            {this.state.newIolRequestListAfterPagination && this.state.newIolRequestListAfterPagination.length > 0 ?
             <View style={{ zIndex: 0 }}>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '60vh' }}>
                {this.state.newIolRequestListAfterPagination && this.state.newIolRequestListAfterPagination.length > 0 && this.state.newIolRequestListAfterPagination.map((item, index) => {
                    let sno = (parseInt(current_page)-1)*parseInt(records_per_page) + (index + 1)
                    return (
                        <View>
                            {this.state.selectedStatus == "Pending" ?
                                <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0 }}>
                                    <Text style={{ flex: 0.05, alignSelf: "center", marginHorizontal: 20 }}>{sno}</Text>
                                    {/* {this.renderTextInput(item.created_date, 0.25, 'created_date__date', index, item)} */}
                                    {this.renderTextInput(item.brand_name, 0.25, 'brand_name', index, item)}
                                    {this.renderTextInput(item.model_name, 0.25, 'model_name', index, item)}
                                    {this.renderTextInput(item.iol_type_name, 0.18, 'iol_type_name', index, item)}
                                    {this.renderTextInput(item.iol_power, 0.17, 'iol_power', index, item)}
                                    {this.state.isEditClick && (index === this.state.isSelectedIndex) ?
                                        <View key={index} style={{ flex: 0.1, flexDirection: "row", margin: 16 }}>
                                            <TouchableOpacity onPress={() => {
                                                this.newIolRequestEdit(item)
                                            }}>
                                                <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={CompleteImg} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                this.setState({
                                                    isEditClick: false
                                                })
                                                this.getIolRequestList()
                                            }} style={{ marginLeft: 20 }}>
                                                <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={CancelImg} />
                                            </TouchableOpacity>
                                        </View> :
                                        <View style={{ flex: 0.1, flexDirection: "row", margin: 16 }}>
                                            <TouchableOpacity onPress={() => {
                                                this.setState({
                                                    isEditClick: true,
                                                    isSelectedIndex: index,
                                                    editLineItem: item,                                                    
                                                })
                                            }}>
                                               <Edit name='edit' size={20} color={color.themeDark}></Edit>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                this.pendingIOlRequestDelete(item.id)
                                            }} style={{ marginLeft: 20 }}>
                                               <Icon name='trash' size={20} color={color.red}></Icon>
                                            </TouchableOpacity>
                                        </View>
                                    } 
                                </View> : this.state.selectedStatus === "Declined" ? 
                                <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, paddingVertical: 20 }}>
                                    <Text style={{ flex: 0.05, marginHorizontal: 20 }}>{sno}</Text>
                                    {/* <Text style={{ flex: 0.1 }}>{item.created_date}</Text> */}
                                    <Text style={{ flex: 0.20, fontSize: '0.9vw'}}>{item.brand_name}</Text>
                                    <Text style={{ flex: 0.20, fontSize: '0.9vw' }}>{item.model_name}</Text>
                                    <Text style={{ flex: 0.15, fontSize: '0.9vw' }}>{item.iol_type_name}</Text>
                                    <Text style={{ flex: 0.15, fontSize: '0.9vw' }}>{item.iol_power}</Text>
                                     <Tooltip placement="topLeft" title={item.comments}>               
                                      <Text style={{fontSize: 15, flex: 0.15, fontSize: '0.9vw' }}> {item.comments && item.comments.length > 12 ? item.comments.slice(0,12) + ".." :( item.comments != null && item.comments != "") ? item.comments : "-"} </Text> 
                                     </Tooltip>  
                                    
                                    <Text style={{ flex: 0.1, fontSize: '0.9vw' }}>{item.request_status}</Text>
                                </View>: 
                                    <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, paddingVertical: 20 }}>
                                    <Text style={{ flex: 0.05, marginHorizontal: 20, fontSize: '0.9vw'}}>{sno}</Text>
                                    {/* <Text style={{ flex: 0.1 }}>{item.created_date}</Text> */}
                                    <Text style={{ flex: 0.25, fontSize: '0.9vw'}}>{item.brand_name}</Text>
                                    <Text style={{ flex: 0.25, fontSize: '0.9vw' }}>{item.model_name}</Text>
                                    <Text style={{ flex: 0.18, fontSize: '0.9vw' }}>{item.iol_type_name}</Text>
                                    <Text style={{ flex: 0.17, fontSize: '0.9vw' }}>{item.iol_power}</Text>  
                                    <Text style={{ flex: 0.1, fontSize: '0.9vw' }}>{item.request_status}</Text>
                                </View>

                            }
                        </View>

                    )
                })}

                </ScrollView>
            </View> : 
           <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 120, zIndex: 0 }}>
             <Text style={{ marginTop: 60 }}>{"No records to be shown"}</Text>
           </View>
        }
        </View>
        )
    } 

    paginationChangedValues(data) {
        this.setState({
            newIolRequestListAfterPagination: data
        })
    }


    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
         current_page: current_page,
         records_per_page: records_per_page
        })
   }

    render(){
        return(
            <View>
                <View style={{flexDirection: "row", justifyContent: "space-between", paddingVertical: 10, paddingHorizontal: 20}}>
                <View style={{flexDirection: "row", justifyContent: "flex-start"}}>
                <TouchableOpacity 
                onPress={() => {
                    this.props.changePage("iolStockList")
                }}
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>IOL Stock List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity
                onPress={() => {
                    this.props.changePage("addNewIOL")
                }} 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>Add New IOL</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>Request</Text>
                </TouchableOpacity>
                </View>
                <View style={{flexDirection: "row", justifyContent: "flex-end", paddingHorizontal: 20}}>
                <View style={{flexDirection: "row", zIndex: 0, marginRight: 20}}>
                <TextInput
                value = {this.state.searchIol}
                onChangeText={(text) => {
                    this.setState({
                        searchIol: text
                    })
                }}
                style={{borderWidth: 1, height: '5vh', width: '13vw', paddingHorizontal: 3, borderColor: color.lightGray, fontSize: '1vw' }}
                placeholder="Search Request IOL..."
                />
                <TouchableOpacity 
                style={{height: "5vh", width: "5vh", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                    this.getIolRequestList()
                }}>
                    <Icon name='search' size={'1.2vw'} color={color.white}></Icon>
                </TouchableOpacity>
                </View>
                <CommonDateFilter 
                startDate={this.state.filterDate.fromDate} 
                endDate={this.state.filterDate.toDate} 
                filterDate={this.state.filterDate}
                defaultSelectedDateFilter={"Today"} 
                showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} 
                removeObject={"all"}
                />
                <Picker
                    selectedValue={this.state.selectedStatus}
                    style={[Style.pickerView, {
                        marginTop: 5, width: '10vw', fontSize: '1vw', marginLeft: 20 }]}
                    itemStyle={{}}
                    onValueChange={(itemvalue) => {
                        var states = this.state;
                        states["selectedStatus"] = itemvalue;
                        this.setState({ states },() =>{
                            this.getIolRequestList()
                        });
                    }}
                >
                    <Picker.Item label={"Pending"} value={"Pending"} />
                    <Picker.Item label={"Approved"} value={"Accepted"} />
                    <Picker.Item label={"Declined"} value={"Declined"} />
                </Picker>
                </View>
                </View>
                <View style={{ zIndex: -1 }}>
                    {this.renderRequestHeader()}
                    {this.renderDataView()}
                    {
                        this.state.newIolRequestList && this.state.newIolRequestList.length > 0 ?
                            <View style={{ marginLeft: 20, zIndex: 0 }}>
                                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                                    totalItems={this.state.newIolRequestList}
                                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                                />
                            </View> : null
                    }
                </View>
            </View>
        )
    }
}