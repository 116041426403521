import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { OBGYNHistorySectionHeading, HistorySectionRightSideUI } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "familyHistory"


export class FamilyHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            listOfDatas: [],
            getListofFamilyHistory: {},

            hideEditIcon: false,
            isheading:false
        }
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props.refreshRighSideComponentName == pageName) {
            this.getFamilyHistory();
            this.props.refreshRighSideComponent("");
        }
    }

    componentDidMount() {
        this.getFamilyHistory()
    }

    selectedRightSideView(name) {
        this.props.selectedRightSideView(name,this.state.isheading);
    }

    editHistory() {
        this.props.selectedRightSideView(pageName,this.state.isheading);
        setTimeout(() => {
            this.props.editHistory(this.state.getListofFamilyHistory, pageName)
        }, 100);

    }

    getFamilyHistory() {
        var service_url = Constants.PSY_FAMILY_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id +
            "&appointment_id=" + this.state.patientAppointment.appointment_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getFamilyHistorySuccess,
            this.getFamilyHistoryFailure
        );
    }

    getFamilyHistorySuccess = success => {
        if (success.status == "success") {
            var data = success.data;

            var booleanValues = [];
            var stringVaues = [];
            Object.keys(data).forEach((key) => {
                if (typeof data[key] == "boolean") {
                    let prepareData = {};
                    if (key == "mental_disorder") {
                        prepareData = {
                            key: "Mental Disorders",
                            value: data[key] ? "Yes" : "No",
                            details: data.mental_disorder_details
                        }
                    } else if (key == "consanguinty" ) {
                        prepareData = {
                            key: "Consanguinity",
                            value: data[key] ? "Yes" : "No",
                            details: data.consanguinty_details
                        }
                    } else if (key == "cohesiveness") {
                        prepareData = {
                            key: "Cohesiveness",
                            value: data[key] ? "Yes" : "No",
                            details: data.cohesiveness_details
                        }
                    } else if (key == "family_rituals" ) {
                        prepareData = {
                            key: "Familly Rituals",
                            value: data[key] ? "Yes" : "No",
                            details: data.family_rituals_details
                        }
                    } else if (key == "family_support_system" ) {
                        prepareData = {
                            key: "Family Support System",
                            value: data[key] ? "Yes" : "No",
                            details: data.family_support_system_details
                        }
                    }
                    booleanValues.push(prepareData)
                } else if (key !== "mental_disorder" || key !== "consanguinty" || key !== "cohesiveness" || key !== "family_rituals" || key !== "family_support_system") {
                    var prepareData = {};
                    if (key == "father_details") {
                        prepareData = {
                            key: "Father",
                            value: data[key],
                        }
                    } else if (key == "mother_details") {
                        prepareData = {
                            key: "Mother",
                            value: data[key],
                        }
                    } else if (key == "siblings_details") {
                        prepareData = {
                            key: "Siblings",
                            value: data[key],
                        }
                    } else if (key == "others_details") {
                        prepareData = {
                            key: "Others",
                            value: data[key],
                        }
                    } else if (key == "family_interact_pattern") {
                        prepareData = {
                            key: "Family Interaction Pattern",
                            value: data[key],
                        }
                    } else if (key == "communication_style") {
                        prepareData = {
                            key: "Communication Style",
                            value: data[key],
                        }
                    } else if (key == "leadership_pattern") {
                        prepareData = {
                            key: "Leadership Pattern",
                            value: data[key],
                        }
                    } else if (key == "child_rear_practice") {
                        prepareData = {
                            key: "Child Rearing Practices",
                            value: data[key],
                        }
                    } else if (key == "family_type") {
                        prepareData = {
                            key: "Family Type",
                            value: data[key] && data[key] == "1" ? "Joint" : data[key] && data[key] == "2" ? "Extended" : data[key] && data[key] == "3"?"Nuclear":"Nil",
                        }
                    } else if (key == "family_env_relationship") {
                        prepareData = {
                            key: "Family Environment & Relationship",
                            value: data[key],
                        }
                    }
                    stringVaues.push(prepareData)
                } else {

                }
            })
            // var concatBothList = booleanValues.concat(stringVaues)
            var concatBothList = [...booleanValues, ...stringVaues]

            var removeEmptyObj = this.removeEmptyObject(concatBothList)
            this.setState({
                // hideEditIcon:hideEditIcon,
                isheading:Object.keys(data).length > 0?true:false,
                getListofFamilyHistory: data,
                listOfDatas: removeEmptyObj
            })
        }
    }
    getFamilyHistoryFailure = error => { }

    removeEmptyObject(obj) {
        if (obj.length > 0) {
            var data = []
            for (let i = 0; i < obj.length; i++) {
                if (Object.keys(obj[i]).length > 0) {
                    data.push(obj[i])
                }
            }
            return data
        }
    }

    renderHeader() {
        return (
            <OBGYNHistorySectionHeading
                selectedRightSideView={this.selectedRightSideView.bind(this)}
                editHistory={this.editHistory.bind(this)}
                selectedPageName={this.state.selectedPageName}
                pageName={pageName} heading={"Family History"}
                editImage={this.state.hideEditIcon || Object.keys(this.state.getListofFamilyHistory).length == 0   ? "" : "pencil"}
            />
        )
    }

    renderContent() {
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, width: "100%", marginTop: 15 }}>
                <FlatList
                    data={this.state.listOfDatas}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    showsHorizontalScrollIndicator={false}
                    containerStyle={{ alignItems: "center", alignContent: "center" }}
                    renderItem={({ item }) => this.renderListOfData(item)}
                />

            </View>
        )
    }

    renderListOfData(item) {
        if (item.value ) {
            return (
                <HistorySectionRightSideUI heading={item.key} value={item.value} details={item.details} />
            )
        } else {
            return (<View></View>)
        }

    }

    render() {
        return (
            <View style={styles.container}>
                {this.renderHeader()}

                {this.renderContent()}
            </View>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
