import React, { Component } from 'react';
import { View, Text, Image, StyleSheet, Dimensions, ScrollView, TouchableOpacity, ImageBackground, CheckBox } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton, LabelWithTextBoxDR, CommonButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
const pageName = "devlopmentalAssessment"

var selectAges = [
    { label: "2 Months", value: "2 MONTHS" },
    { label: "4 Months", value: "4 MONTHS" },
    { label: "6 Months", value: "6 MONTHS" },
    { label: "9 Months", value: "9 MONTHS" },
    { label: "12 Months", value: "12 MONTHS" },
    { label: "15 Months", value: "15 MONTHS" },
    { label: "16-18 Months", value: "16-18 MONTHS" },
    { label: "18-24 Months", value: "18-24 MONTHS" },
    { label: "2-3 Years", value: "2-3 YEARS" },
    { label: "3-4 Years", value: "3-4 YEARS" },
    { label: "4-5 Years", value: "4-5 YEARS" },

];

export class DevlopmentalAssessmentNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,
            comments: "",
            selectAge: "",
            selectAge: {},
            selectAgeList: selectAges,

            abnormalities: [],
            selectAgeLabel: "",
            selectAgevalue: "",
            ageDisable: false,

            personalSocial: [],
            gross_Motor: [],
            language: [],
            fineMotor: [],
            comments: "",
            selectAgevalue: "",
        }
    }

    componentDidMount() {
        this.getDenvorAssessment()
    }




    getDenvorAssessment() {
        var service_url = ""
        service_url = Constants.GET_DENVER_ASSESSMENT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&age_range=" + this.state.selectAgevalue;


        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getDenvorAssessmentSuccess,
            this.getDenvorAssessmentFailure
        );
    }

    getDenvorAssessmentSuccess = success => {
        if (success.status == "success") {

            if (Object.keys(success.data).length !== 0) { }
            else {
                this.setState({
                    ageDisable: false
                })
            }
            if (success.data) {
                var data = success.data.developmental_assessment
                var states = this.state
                 states["comments"] = success.data.comments
                states["personalSocial"] = data['Personal - Social'] ? data['Personal - Social']: []
                states["gross_Motor"] = data['Gross Motor'] ? data['Gross Motor'] :[]
                states["language"] = data['Language'] ?data['Language'] :[]
                states["fineMotor"] = data['Fine Motor - Adaptive'] ? data['Fine Motor - Adaptive'] : []
                states["abnormalities"] = data["abnormalities"] ? data["abnormalities"] :[]
                states["selectAge"] = success.data.age_range ? { label: success.data.age_range, value: success.data.age_range } : null
                this.setState({
                    states
                })
            }


        }
    }

    getDenvorAssessmentFailure = error => {
        console.log("error -----------> " + JSON.stringify(error))

    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {

                // this.setState({ states }, () => {
                //     this.props.editHistory({}, pageName)
                // })
            }
        }
        // if (props ) {
        //     var states = this.state
        //     var key = ""
        //     if (props.personalSocial) {
        //         key = "personalSocial"
        //         states["personalSocial"] = props.personalSocial
        //         this.setState({ states }, () => {
        //             this.props.denvarvalues(key, "")
        //         })
        //     } if (props.gross_Motor) {
        //         key = "gross_Motor"
        //         states["gross_Motor"] = props.gross_Motor
        //         this.setState({ states }, () => {
        //             this.props.denvarvalues(key, "")
        //         })
        //     } if (props.language) {
        //         key = "language"
        //         states["language"] = props.language
        //         this.setState({ states }, () => {
        //             this.props.denvarvalues(key, "")
        //         })
        //     } if (props.fineMotor) {
        //         key = "fineMotor"
        //         states["fineMotor"] = props.fineMotor
        //         this.setState({ states }, () => {
        //             this.props.denvarvalues(key, "")
        //         })
        //     }

            
        // }
    }
    checkIsAbnormalities(age) {
        var { abnormalities } = this.state;
        if (abnormalities && abnormalities.length > 0) {
            var checkAge = abnormalities.includes(age)
            return checkAge
        } else {
            return false
        }
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {

        var data = this.removeDuplicate(setOfData)

        // var checkIsAbnormalities = data

        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var checkIsAbnormalitiesFlag = false
                            if (key == "selectAge") {
                                checkIsAbnormalitiesFlag = this.checkIsAbnormalities(item.value)
                            }
                            var changedItem = {}
                            if (key == "selectedBirthWeight") {
                                changedItem = { label: item.label + " kgs", value: item.value }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        buttonBorderColor={checkIsAbnormalitiesFlag ? "red" : ""}
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        disable={this.state.ageDisable}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{ marginBottom: 10 }}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "selectAge") {
            states[key] = selectedValue
            this.setState({
                states,
                comments:"",
                selectAgeLabel: selectedValue.label,
                selectAgevalue: selectedValue.value,
                ageDisable: false
            }, () => {
                this.getDenvorAssessment()
                this.props.getdenvarAge(this.state.selectAgevalue)
            })

        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }


    onPressAddButton() {
        var states = this.state;
        var { gross_Motor, fineMotor, personalSocial, language } = this.state



        var Grossmotor=[];
        var Finemotor =[];
        var PersonalSocial =[];
        var Language =[];


         Grossmotor=this.props.gross_Motor.length > 0?this.props.gross_Motor:gross_Motor
         Finemotor = this.props.fineMotor !== undefined && this.props.fineMotor.length > 0?this.props.fineMotor:fineMotor
         PersonalSocial = this.props.personalSocial.length > 0?this.props.personalSocial:personalSocial
         Language = this.props.language.length > 0?this.props.language:language

        //  alert(JSON.stringify(this.props.fineMotor))
        //  alert(JSON.stringify(fineMotor))
        //  alert(JSON.stringify(PersonalSocial))
        //  alert(JSON.stringify(Language))
        var developmental_assessment =[...Grossmotor,...Finemotor,...PersonalSocial,...Language]

        // var developmental_assessment = Grossmotor.concat(Finemotor ? Finemotor : {}, PersonalSocial ? PersonalSocial : {}, Language ? Language : {})
        
        var data = {
            "appointment_id": states.patientAppointment.appointment_id,
            "abnormalies": "",
            "age_range": states.selectAge.value,
            "comments": states.comments,
            "developmental_assessment": developmental_assessment

        }


        var service_url = Constants.POST_DENVER_ASSESSMENT;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDenverSuccess,
            this.saveDenverFailure
        );

    }

    saveDenverSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            this.props.refreshRighSideComponent(pageName);
            this.props.denvarvalues("gross_Motor", "")
            this.props.denvarvalues("fineMotor", "")
            this.props.denvarvalues("personalSocial", "")
            this.props.denvarvalues("language", "")
           this.getDenvorAssessment()

        } else {
            this.props.showResposeValue('error', success.message);

        }
    }
    saveDenverFailure = error => {

    }

    clearDevlopmentalAssessment(){
        this.setState({
            comments:""
        })
    }

    renderComponent() {


        return (
            <View>
                <Text style={{ fontSize: 18, color: color.themeDark, fontWeight: "600", marginBottom: 10 }}>{"Developmental Assessment"}</Text>
                {
                    this.renderLabelWithButtonGroup(
                        "Select Age",
                        "selectAge",
                        this.state.selectAge,
                        "",
                        false,
                        this.state.selectAgeList)
                }
                <LabelWithTextBoxDR label={"Comments"} value={this.state.comments} textBoxKey={"comments"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} placeholder={"Enter Details"}/>
                <CommonAddButton
                    onPressAddButton={this.onPressAddButton.bind(this)}
                />

            </View>
        );
    }

    render() {
        return (
            <View>
                {this.renderComponent()}
            </View>
        )
    }
}
