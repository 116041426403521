import React from "react";
import { View, Button, StyleSheet, Image, Text } from "react-native";
import BaseComponentStyle from "../ui/doctor/BaseComponentStyle";
import { color } from "../styles/Color";
import AsyncStorage from "../AsyncStorage";
import Imagepath from "../utils/ImagePaths";
import { ScrollView } from "react-native-gesture-handler";
import { TouchableOpacity } from "react-native-web";
import Success from "react-native-vector-icons/FontAwesome5";
import Icons from "react-native-vector-icons/FontAwesome";
import {getClinicS3Storage} from "../ui/doctor/BaseComponent";
import {
  CommonSectionHeader,
  CommonHistorySectionHeader,
} from "../ui/doctor/BaseComponent";
import { Tooltip} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

var AWS = require("aws-sdk");
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1",
  params: { Bucket: "emedhub" },
});

export default class AWSFileUpload extends BaseComponentStyle {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      responseMeaasge: "",
      clinic_id: "",
      patient_info: props.patient,
      files: [],
      IsDeletePopup : false,
      DeletedItem : [],
      uploading: false
    };
  }
  async componentDidMount() {
    const clinicId = await AsyncStorage.getItem("Selected_ClinicIDFor_Doctor");
    var S3bucketsize = getClinicS3Storage(clinicId);
    S3bucketsize.then((val)=>{
      this.setState({
        S3bucketsize: val
      })
      return val;
    });
    var login_userData = await AsyncStorage.getItem("loggedInData");
    var loggedInDataObj = JSON.parse(login_userData)
    this.setState({
      clinic_id: clinicId,
      S3limit: loggedInDataObj.s3_storage_limit
    });
    this.getAllFilesfromS3();
  }
  showResposeValue = (type, meaasge) => {
    this.setState({uploading: false})
    this.props.showResposeValue(type, meaasge)
  };
  s3FileUpload = async (item, props) => {
    var files = document.getElementById("fileUpload").files;
    var s3allocatedStorage = this.state.S3limit;
    var duplicate;
    item.forEach(file =>{
      for(var i=0; i<files.length; i++){
        if(file.file_name == files[i].name){
          if(duplicate == undefined){
            duplicate = file.file_name
          }else{
            duplicate+= " "+ file.file_name;
          }
        }
      }
    })
    if (files.length > 0) {
      for(var i =0; i<files.length; i++)
        { 
      var file = files[i];
      var fileName = file.name;
      var filePath =
        "emedhub/" +
        this.state.clinic_id +
        "/" +
        this.props.patient.patient_id +
        "/" +
        fileName;
      var totalSize = 0,
        ContinuationToken;
        totalSize = this.state.S3bucketsize;
      var AllFileName = item.filter(list => list.file_name == fileName)
      if (totalSize >= s3allocatedStorage) {
        alert("Error: Your allocated Storage space has filled.");
      } else if(AllFileName.length ==0) {
        this.setState({uploading: true})
        s3.upload(
          {
            Key: filePath,
            Body: file,
            ACL: "public-read",
          },
          function (err, data) {
            if (err) {
              reject("error");
            }
            document.getElementById("fileUpload").value = "";
            document.getElementById("btn_getall_file").click();
            document.getElementById("btn_upload_success").click();
          }
        ).on("httpUploadProgress", function (progress) {
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          $("progress").attr("value", uploaded);
        });
      }
    }
    if(duplicate!=undefined){
      setTimeout(() => {
        this.showResposeValue("error", "File Name Already Exist :" + duplicate)
      }, 1000);
      
    }
    }else{
      this.showResposeValue("error", "Please choose file to upload")
    }
  };

  //Get all files from S3
  getAllFilesfromS3 = async () => {
    //var filePath = "emedhub-stage/"+ this.state.clinic_id + "/" + this.props.patient.patient_id + fileName;
    this.setState({
      files: [],
    });
    var filepaths = [];
    var totalSize = 0,
     ContinuationToken;
    do {
      var resp = await s3
        .listObjectsV2({
          Bucket: "emedhub",
          Prefix:
            `emedhub/` +
            this.state.clinic_id +
            "/" +
            this.props.patient.patient_id +
            "/",
          ContinuationToken,
        })
        .promise()
        .catch((e) => console.log(e));
      resp?.Contents?.forEach(
        (o) =>
          filepaths.push({
            key: o.Key,
            download_url: s3.getSignedUrl("getObject", { Key: o.Key }),
            file_name: o.Key.split("/")[o.Key.split("/").length - 1],
          })
      );
      this.setState({
        files: filepaths,
      });
      ContinuationToken = resp.NextContinuationToken;
    } while (ContinuationToken);
  };
  deleteObject = async(key)=>{
    try {
      // Delete the object.
      console.log(`\nDeleting object "${key}"} from bucket`);
      await s3.deleteObject({ Bucket: "emedhub", Key: key },
      function(deleteErr, data) {
        if (deleteErr) {
         console.log("Error: " + deleteErr);
        }
        else {
          document.getElementById("fileUpload").value = "";
            document.getElementById("btn_getall_file").click();
            document.getElementById("btn_delete_success").click();
        }
    })
      // .promise()
      // .catch((e) => console.log(e));
    } catch (err) {
      console.log("Error deleting object", err);
    }
    //window.open(item.download_url);
  }

  DeletePopup = (item) => {
    return(
      <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: '1.85vw', paddingVertical: '1.2vw', backgroundColor: color.white, width: "20vw", height: '9.2vw', borderRadius: '0.54vw', alignItems: "center", borderWidth: 1, }}>
                <Text style={{ fontSize: '.85vw', fontWeight: "bold", marginBottom: '2.5vw', textAlign: 'center' }}>{"Are you sure to delete - "}<Text style={{ color: 'red', fontSize:".85vw" }}>{` ${item.file_name} `}</Text>?</Text>
                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "30%", paddingVertical: '0.42vw' }}
                        onPress={() => {this.setState({IsDeletePopup : false}, () => {this.deleteObject(item.key)})}}
                    >
                        <Text style={{ color: 'white', fontSize: '0.65vw', }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "30%", paddingVertical: '0.42vw' }}
                        onPress={() => {
                            this.setState({
                              IsDeletePopup : false
                            })
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: '0.65vw', }}>{"No"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
  }

  render() {
    return (
      <View>
        <CommonSectionHeader heading={"Patient Documents"} />
        <View style={{ flex: 1, height: '100%' }}>
          <ScrollView style={style.MainTable}>
            <View style={{ flex: 0.7, flexDirection: "column" }}>
              {this.state.files.length > 0 ? (
                this.state.files.map((item, index) => (
                  <View
                    style={[
                      style.TableDataWrapper,
                      { flex: 1, flexDirection: "row" },
                    ]}
                  >
                    <View
                      style={{
                        flex: 0.7,
                        height: "2.5vw",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip placement="topLeft" title={item.file_name}>
                      <Text>{item.file_name.length > 15 ? item.file_name.slice(0, 15) + ".." : item.file_name}</Text>
                      </Tooltip>
                    </View>
                    <View
                      style={{
                        flex: 0.1,
                        height: "2.5vw",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          window.open(item.download_url);
                        }}
                      >
                        <DownloadOutlined style={{fontSize: "1.5vw", color : color.themeDark}}/>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flex: 0.1,
                        height: "2.5vw",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({IsDeletePopup : true, DeletedItem : item})
                        }}
                      >
                        <Image
                          style={{
                            width: 25,
                            height: 25,
                            //backgroundColor: color.themeDark,
                          }}
                          source={Imagepath.Delete}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                ))
              ) : (
                <View style={style.NoRecordsView}>
                  <Text style={style.NoRecordsText}>No Records to show...</Text>
                </View>
              )}
            </View>
          </ScrollView>
          <View style={{ flex: 0.3 }}>
            <div>
              <input type="file" id="fileUpload" multiple/>
            </div>
            <div>
              <Button
                title="Upload File"
                color={color.themeDark}
                onPress={() => this.s3FileUpload(this.state.files)}
              ></Button>
              {this.state.uploading?
              <Text>Please wait file upload in-progress...</Text> :
              null}
              <button
                style={{ display: "none" }}
                id="btn_getall_file"
                title="GetAllFile"
                onClick={() => this.getAllFilesfromS3()}
              ></button>
              <button
                style={{ display: "none" }}
                id="btn_upload_success"
                title="UploadSuccess"
                onClick={() =>
                  this.showResposeValue("success", "File Successfully Uploaded")
                }
              ></button>
              <button
                style={{ display: "none" }}
                id="btn_delete_success"
                title="Success"
                onClick={() =>
                  this.showResposeValue("success", "File Deleted Successfully")
                }
              ></button>
            </div>
          </View>
        </View>
        {this.state.IsDeletePopup ? this.DeletePopup(this.state.DeletedItem) : null}
      </View>
    );
  }
}

const style = StyleSheet.create({
  MainTable: {
    height: "30vh",
  },
  TableDataWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw",
  },
  NoRecordsView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5vw",
  },
  NoRecordsText: {
    fontSize: "1vw",
  },
});
