import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { PSYHistorySectionCommonComponent, DoctorNotesCommonRightSideHeader, CommonAddButton } from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

const pageName = "occupationalhistory"


export class OccupationalHistoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            id: "",

            currentlyWorkingDetails: "",
            currentlyWorkingFlag: false,
            detailsOfPreviousWork: "",
            detailsOfWorkSatisfaction: "",
            detailsOfCompetence: "",
            detailsOfAmbition: "",
            detailsOfOthers: "",
        }
    }

    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state;
                states["currentlyWorkingFlag"] = data.currently_working;
                states["currentlyWorkingDetails"] = data.current_work_details;

                states["detailsOfPreviousWork"] = data.previous_work_details;
                states["detailsOfWorkSatisfaction"] = data.work_satisfaction_details;

                states["detailsOfCompetence"] = data.competence_details;
                states["detailsOfAmbition"] = data.future_ambition_details;
                states["detailsOfOthers"] = data.other_details;
                states["id"] = data.id;
              
                this.setState({ states }, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    renderHistoryComponent(cardLabel, placeholder, yesOrNOFlagKey, yesOrNOFlag, yesTextBoxValueKey, yesTextBoxValue) {
        return (
            <PSYHistorySectionCommonComponent
                onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

                cardLabel={cardLabel}

                // text box -------------------
                yesTextBoxPlaceholder={placeholder}
                yesTextBoxValue={yesTextBoxValue}
                yesTextBoxKey={yesTextBoxValueKey}

                // yes or no button -------------------
                yesOrNoButtonKey={yesOrNOFlagKey}     // if we not this key ... we dont show the  yes or no buttons 
                isSelected={yesOrNOFlag}


            // // drop down list 
            // dropDownList = {
            //   ["mother","father","son","mom"]
            // }
            // selectedDropDownlist={this.state.selectedDropDownlist}
            // dropDownListKey={"selectedDropDownlist"}
            // onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}
            // // label with button select 


            />
        )
    }

    //   onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    //     var states = this.state;
    //     states[dropDownKey]=selectedDropDownValue;
    //     this.setState({ states })

    //   }

    onChangeYesOrNoValue(flag, flagKey, detailsValue, detailsValueKey) {

        var states = this.state;
        if (flagKey) { states[flagKey] = flag; }
        if (detailsValueKey) { states[detailsValueKey] = detailsValue; }

        this.setState({ states })
    }

    clearAllData() {
        var states = this.state;
        states["id"] = ""
        states["currentlyWorkingFlag"] = false;
        states["currentlyWorkingDetails"] = "";
        states["detailsOfPreviousWork"] = "";
        states["detailsOfWorkSatisfaction"] = "";
        states["detailsOfCompetence"] = "";
        states["detailsOfOthers"] = "";
        states["detailsOfAmbition"] = "";

        this.setState({ states })
    }
 
  
    onPressAddButton() {
        var states = this.state;
        if ((this.props.isheading && this.state.id) || (!this.props.isheading)) {

        var data = {
            "appointment_id": this.state.patientAppointment.appointment_id,
            "patient_id": this.state.patientAppointment.patient_id,
            "currently_working": states.currentlyWorkingFlag,
            "current_work_details": states.currentlyWorkingDetails,
            "previous_work_details": states.detailsOfPreviousWork,
            "work_satisfaction_details": states.detailsOfWorkSatisfaction,
            "competence_details": states.detailsOfCompetence,
            "future_ambition_details": states.detailsOfAmbition,
            "other_details": states.detailsOfOthers,

        }
        

        if (this.state.id) {
            data["id"] = this.state.id;
        }

        var service_url = Constants.PSY_OCCUPATIONAL_HISTORY;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveoccupationalSuccess,
            this.saveoccupationalFailure
        );
    }
    else{
        this.props.showResposeValue("error", "Occupational History already added please click on edit icon to update the values")

    }
    }

    saveoccupationalSuccess = success => {
        if (success.status == "success") {
            this.clearAllData()
            this.props.refreshRighSideComponent(pageName);
            this.props.showResposeValue("success", success.message)
            this.props.selectedRightSideView(pageName,true)
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveoccupationalFailure = error => { }

    render() {
        return (
            <View>

                <DoctorNotesCommonRightSideHeader title={"Occupational History"} clearAllData={this.clearAllData.bind(this)} />


                {this.renderHistoryComponent(
                    "Currently Working", "Enter details",
                    "currentlyWorkingFlag", this.state.currentlyWorkingFlag,
                    "currentlyWorkingDetails", this.state.currentlyWorkingDetails
                )}



                {this.renderHistoryComponent(
                    "Previous Work", "Enter details",
                    "", false,
                    "detailsOfPreviousWork", this.state.detailsOfPreviousWork
                )}

                {this.renderHistoryComponent(
                    "Work Satisfaction", "Enter details",
                    "", false,
                    "detailsOfWorkSatisfaction", this.state.detailsOfWorkSatisfaction
                )}

                {this.renderHistoryComponent(
                    "Competence", "Enter details",
                    "", false,
                    "detailsOfCompetence", this.state.detailsOfCompetence
                )}

                {this.renderHistoryComponent(
                    "Future Ambition / Goals", "Enter details",
                    "", false,
                    "detailsOfAmbition", this.state.detailsOfAmbition
                )}

                {this.renderHistoryComponent(
                    "Others", "Enter details",
                    "", false,
                    "detailsOfOthers", this.state.detailsOfOthers
                )}


                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
            </View>
        )
    }
}
