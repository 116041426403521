//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet,TextInput } from 'react-native';
import {CommonHistorySectionHeader,CommonButton,EditiAndDeleteForLineItem} from '../BaseComponent'
import {color} from '../../../styles/Color'
import BaseComponentStyle from "../BaseComponentStyle";
import { Constants } from "../../../utils/Constants"
import OpthamologyService from "../../../network/OpthamologyService";

var Messages = require("../../../utils/InfoMessages")

const pageTitle="HOPI";
const isPageName = "isHOPI";
const pageName="HOPI";

// create a component
export default class HOPI extends BaseComponentStyle  {
    
    constructor(props){
        super(props);
        this.state={
            comment:"",
            isHOPI: isPageName===this.props.selectedView?true:false,
            isLocked:this.props.patientAppointment.is_locked,
            patientAppointment: this.props.patientAppointment,
            id:"",
            historyOfPresentIllness:{}

        }
    }

    componentDidMount(){
        this.getHOPI()
    }
    componentWillReceiveProps(props){
        if(props.reloadData && props.reloadData === pageName) {
            this.getHOPI();
            this.state.refreshData("");
          }
          
      
        let updateFontColor = isPageName===props.selectedView?true:false;

        if(updateFontColor !== this.props.isHOPI){
    
          this.setState({ isHOPI: updateFontColor });
        }
    }
    saveHistory(){
      
        let data={
            
              
                "patient_id":this.state.patientAppointment.patient_id,
                "appointment_id": this.state.patientAppointment.appointment_id,
                "history_of_present_illness": {
             
         
                  "comments_of_illness": this.state.comment  
               }
        }
                    
        if(!this.state.comment){    
    
          this.props.showResposeValue("error", Messages.HOPIValidation)
        } else {
    
        var service_url = Constants.POST_PSY_HOPI;
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.saveHistorySuccess,
          this.saveHistoryFailure
        );
      }
  }
  saveHistorySuccess = (success) => {
      // alert(JSON.stringify(success));
      if(success.status == "success"){
       
        this.props.showResposeValue("success", success.message)

        // this.clearData()
        // this.props.refreshData(pageName);
        
      }else{
        this.props.showResposeValue("error", success.message)
      }
    };
    saveHistoryFailure = (error) => {
      //alert(JSON.stringify(error));
      this.props.showResposeValue("error", error.message)
    };
    clearData(){
      this.state.comment=""
        
        this.setState({
            comment
        })
    }




    
    triggerNewItem= (key) => {

        this.props.triggerNewItem(key);
      }


    getHOPI(){

        var service_url = Constants.GET_PSY_HOPI + "?patient_id=" + this.state.patientAppointment.patient_id+ "&appointment_id=" + 
        this.state.patientAppointment.appointment_id;
       
        OpthamologyService.getInstance().getComplaints(
          service_url,
          this,
          this.getHOPISuccess,
          this.getHOPIFailure
        );
    }
     getHOPISuccess = (response) => {
        if (response.status === "success") {
         // alert(JSON.stringify(response))
          this.setState({
              id:response.data.id,
              comment: response.data.comments_of_illness
          })
          
   
        }

      };

      getHOPIFailure = error => {
        // console.log("Discharge Summary GET error response");
        console.log(error); Continue

        console.log("....>error")
      };
    
    

    render() {
        return (
            <View>
                {this.renderTitleBorder(this.state.isHOPI, pageTitle, isPageName, this.triggerNewItem)}
              <View style={{flexDirection:"row",backgroundColor:color.sectionHeadingDarkColor,marginTop:10}}> 
              <View style={{flex:0.9}}>
                <CommonHistorySectionHeader
                    heading1={"HOPI"}
                    noOfColumn={1}
                />
             </View>
             <View  style={{flex:0.1}}>
             <EditiAndDeleteForLineItem
                saveHistory={this.saveHistory.bind(this)}
                saveImage={"save"}
            />
            </View>

              </View>   
      
                <TextInput

                    editable={true}
                    multiline={true}
                    placeholder=""
                    style={{ marginTop: 15, paddingTop: 10, paddingLeft: 10, height: 80, borderColor: '#D9D9D9', borderWidth: 1, borderRadius: 4 }}
                    value={this.state.comment}
                    onChangeText={(text)=>{this.setState({comment:text})}}
                />
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

//make this component available to the app

