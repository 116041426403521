import React, { Component, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, Image,StyleSheet } from "react-native";
import Style from "../styles/Style";
import { color } from "../styles/Color";
import {Constants} from "../utils/Constants";
import AsyncStorage from '../AsyncStorage';
import ImagePath from '../utils/ImagePaths';

// {<ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ width: "6%", backgroundColor: color.themeBackgroud, height: screenHeight - 65 }}>
//                         <View style={{ justifyContent: 'space-between', paddingBottom: 20 }}>
//                             <View>

// {
//     this.state.isCurrentPageIsIPPatientAppointment && this.state.isCurrentPageIsIP ?
//     null :
// <TouchableOpacity
//     // style={{ borderTopLeftRadius:15,borderBottomLeftRadius:15  ,backgroundColor:selectedScreen == "home" ? color.white : ""}}
//     onPress={() => { this.changeScreen('home') }}>
//     {this.renderImgBtn(
//         require("./../assets/images/SidemenuIcons/home.png"),
//         this.state.isCurrentPageIsIP ? "OP" :"Home", 'home', selectedScreen == "home" ? true : false)}
// </TouchableOpacity>
// }



//                                 {/* isCurrentPageIsIPPatientAppointment */}

//                                 {
//                                         (this.state.userType === "FO" && this.state.isCurrentPageIsIPPatientAppointment && this.state.isCurrentPageIsIP) ?
//                                             <TouchableOpacity
//                                                 // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "allBillingTransactions" ? color.white : ""}} 
//                                                 onPress={() => { this.changeScreen('iPPatientList') }}>
//                                                 {this.renderImgBtn(
//                                                     require("./../assets/images/SidemenuIcons/IP.png"),
//                                                     " IP Home", "iPPatientList", selectedScreen == "iPPatientList" ? true : false
//                                                 )}
//                                             </TouchableOpacity> : null
//                                 }



//                                 {
//                                     (this.state.selectedScreen=="home" && this.state.userType === "FO" && !this.state.isCurrentPageIsIPPatientAppointment) ?
//                                         <TouchableOpacity
//                                             // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "allBillingTransactions" ? color.white : ""}} 
//                                             onPress={() => { this.changeScreen('iPPatientList') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/IP.png"),
//                                                 " IP", "iPPatientList", selectedScreen == "iPPatientList" ? true : false
//                                             )}
//                                         </TouchableOpacity> : null
//                                 }



//                                   {
//                                       this.state.userType === "FO" &&
//                                     this.state.isCurrentPageIsIP && 
//                                     (Object.keys(patientAppointment).length === 0 && 
//                                     Object.keys(patientInfo).length === 0) ?
//                                         <TouchableOpacity
//                                             // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "allBillingTransactions" ? color.white : ""}} 
//                                             onPress={() => { this.changeScreen('ipallTransactions') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/invoice.png"),
//                                                 this.state.isCurrentPageIsIPPatientAppointment ? "Billing" : "IP Billing Transaction", "ipallTransactions", selectedScreen == "ipallTransactions" ? true : false
//                                             )}
//                                         </TouchableOpacity> : null
//                                 }


//                                 {
//                                     !this.state.isCurrentPageIsIP && (Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0) ?
//                                         <TouchableOpacity
//                                             // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "allBillingTransactions" ? color.white : ""}} 
//                                             onPress={() => { this.changeScreen('allBillingTransactions') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/invoice.png"),
//                                                 "Billing Transaction", "allBillingTransactions", selectedScreen == "allBillingTransactions" ? true : false
//                                             )}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     (this.state.userType !=="FO" && Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0)
//                                     ||(Object.keys(this.state.selectedIpPatientDetails).length === 0 && this.state.userType =="FO" )

//                                     ?
//                                         null :
//                                         <TouchableOpacity
//                                             // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "optDoctorNotes" ? color.white : ""}} 
//                                             onPress={() => { this.changeScreen('optDoctorNotes') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/doctorNotes.png"),
//                                                 "Doctor Notes", "optDoctorNotes", selectedScreen == "optDoctorNotes" ? true : false
//                                             )}
//                                         </TouchableOpacity>
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "DB" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('dbHistory') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "DB History", 'dbHistory', selectedScreen == "dbHistory" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "PD" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('pdHistory') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "PD History", 'pdHistory', selectedScreen == "pdHistory" ? true : false)}
//                                         </TouchableOpacity> : null

//                                 }
//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "PD" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('devlopmentassessment') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/Developmental_Assessment.svg"),
//                                                 "Developmental Assessment", 'devlopmentassessment', selectedScreen == "devlopmentassessment" ? true : false)}
//                                         </TouchableOpacity> : null

//                                 }
//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "PD" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('immunization') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/Immunization.png"),
//                                                 "Immunization", 'immunization', selectedScreen == "immunization" ? true : false)}
//                                         </TouchableOpacity> : null

//                                 }
//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "PD" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('growthChart') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/Growth Chart.png"),
//                                                 "Growth Chart", 'growthChart', selectedScreen == "growthChart" ? true : false)}
//                                         </TouchableOpacity> : null

//                                 }
//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "DB" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('dbChartHistory') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "Chart & Log", 'dbChartHistory', selectedScreen == "dbChartHistory" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "OBGYN" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('obgynHistory') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "History", 'obgynHistory', selectedScreen == "obgynHistory" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "OBGYN" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('ovulation') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OvulationChart.png"),
//                                                 "Ovulation Chart", 'ovulation', selectedScreen == "ovulation" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "OBGYN" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('obgynChart') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/ObgynChart.png"),
//                                                 "OBGYN Chart", 'obgynChart', selectedScreen == "obgynChart" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {/* {
//                                     Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0 ?
//                                         <TouchableOpacity
//                                             // style={{borderTopLeftRadius:15,borderBottomLeftRadius:15 , backgroundColor:selectedScreen == "message" || selectedScreen == "sendmessage" ? color.white : ""}} 
//                                             onPress={() => { this.changeScreen('message') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/messages.png"),
//                                                 "Message", 'message', selectedScreen == "message" || selectedScreen == "sendmessage" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 } */}


//                                 {/* {
//                             Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0 ?
//                                 <TouchableOpacity onPress={() => { this.changeScreen('registry') }}>
//                                     {this.renderImgBtn(
//                                         require("./../assets/images/SidemenuIcons/regist.png"),
//                                         "Registry", 'registry', selectedScreen == "registry" ? true : false)}
//                                 </TouchableOpacity> : null

//                         } */}



//                                 {

//                                         (Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0)

//                                     // (!this.state.userType =="FO" && Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0 )
//                                     // ||(Object.keys(this.state.selectedIpPatientDetails).length === 0 && this.state.userType =="FO" )
//                                     ?
//                                         null :
//                                         <TouchableOpacity onPress={() => { this.changeScreen('demographics') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/demogra.png"),
//                                                 "Demographics", "demographics", selectedScreen == "demographics" ? true : false)}
//                                         </TouchableOpacity>
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0 ?
//                                         null :
//                                         <TouchableOpacity onPress={() => { this.changeScreen('billingScreen') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/invoice.png"),
//                                                 "Billing", "billingScreen", selectedScreen == "billingScreen" ? true : false
//                                             )}
//                                         </TouchableOpacity>
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === "DB" ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('dbCommonHistory') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "History", 'dbCommonHistory', selectedScreen == "dbCommonHistory" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                                 {
//                                     (!this.state.userType =="FO" && Object.keys(patientAppointment).length === 0 && Object.keys(patientInfo).length === 0) 
//                                     ||(Object.keys(this.state.selectedIpPatientDetails).length === 0 && this.state.userType =="FO" )
//                                     ?
//                                         null :
//                                         <TouchableOpacity onPress={() => { this.changeScreen('reportsScreen') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/invoice.png"),
//                                                 "Reports", "reportsScreen", selectedScreen == "reportsScreen" ? true : false
//                                             )}
//                                         </TouchableOpacity>
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === Constants.ROLE_FO ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen('formsAndCertificate') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/certificate.png"),
//                                                 "Forms & Certificate", "formsAndCertificate", selectedScreen == "formsAndCertificate" ? true : false
//                                             )}
//                                         </TouchableOpacity> :
//                                         null
//                                 }

//                                 {
//                                     Object.keys(patientAppointment).length !== 0 && Object.keys(patientInfo).length !== 0 && this.state.userType === Constants.ROLE_FO ?

//                                         <TouchableOpacity onPress={() => { this.changeScreen("printOrder", {}, "", {}, 'isPrintOrder') }}>
//                                             {this.renderImgBtn(
//                                                 require("./../assets/images/SidemenuIcons/OBGYNHistory.png"),
//                                                 "Print Order", 'printOrder', selectedScreen == "printOrder" ? true : false)}
//                                         </TouchableOpacity> : null
//                                 }

//                             </View>
//                             {/* <TouchableOpacity onPress={() => this._logoutFunction()}>
//                                 <View style={{ alignSelf: "center" }}>
//                                     <SimpleLineIcons
//                                         name="logout"
//                                         size={25}
//                                         color={color.white}
//                                     />
//                                     <Text style={{ fontSize: 10, fontWeight: "500", color: color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={2}>Logout</Text>
//                                 </View>
//                             </TouchableOpacity> */}
//                         </View>
//                     </ScrollView>
//     }

// laboratoryPatientList
// laboratoryBillingTransaction
// laboratoryCreateBill
// laboratoryPreviousResult
// laboratoryResult
// testConfig


const listOfSideMenuItemsInLabhome = [
  { image:ImagePath.Home,
   iconKey: "laboratoryPatientList", label: "Home", type: "common", modual: "" },
  { image: ImagePath.Invoice, 
    iconKey: "laboratoryBillingTransaction", label: "Billing Transactions", type: "common", modual: "" },
  { image: ImagePath.Invoice, 
      iconKey: "referredReport", label: "Report", type: "common", modual: "" },
];
const listOfSideMenuItemsInLabPatientPage = [
  { image:ImagePath.Home,
     iconKey: "laboratoryPatientList", label: "Home", type: "common", modual: "" },
  { image:ImagePath.Demogra,
     iconKey: "demographics", label: "Demographics", type: "common", modual: "" },
  { image:ImagePath.Invoice,
    iconKey: "laboratoryCreateBill", label: "Billing", type: "common", modual: "" },
];
// const listOfSideMenuItemsInFOhome = [
//   { image: require("../../assets/images/SidemenuIcons/home.png"), iconKey: "home", label: "Home", type: "common", modual: "" },
//   { image: require("../../assets/images/SidemenuIcons/invoice.png"), iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
//   // Fo => IP
//   { image: require("../../assets/images/SidemenuIcons/IP.png"), iconKey: "iPPatientList", label: "IP", type: "", modual: "FO", subMobual: "IP" },
//   { image: require("../../assets/images/SidemenuIcons/doctorNotes.png"), iconKey: "Result", label: "Reports", type: "common", modual: "IP" },
// ];
const listOfSideMenuItemsInhome = [
  { image:ImagePath.Home, 
  iconKey: "home", label: "Home", type: "common", modual: "" },
    { image: ImagePath.Invoice, 
    iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
  { image: ImagePath.Invoice, 
  iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
  // Fo => IP
  { image:ImagePath.IP,
   iconKey: "iPPatientList", label: "IP", type: "", modual: "FO", subMobual: "IP" },
  { image: ImagePath.DoctorNotes,
    iconKey: "Result", label: "Reports", type: "", modual: "FO" },
  { image: ImagePath.QuickBilling,
    iconKey: "QuickBilling", label: "Quick Billing", type: "", modual: "FO" },
    { image: ImagePath.NutritionChart,
      iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "", modual: "FO" },
];

const listOfSideMenuItemsInCounsellorHome = [
  { image: ImagePath.Home,
    iconKey: "home", label: "Home", type: "", modual: "COUNSELLOR" },
  // { image: ImagePath.OTlist,
  //   iconKey: "otList", label: "OT List", type: "", modual: "COUNSELLOR" },
  { image: ImagePath.OTlist,
    iconKey: "otAppointmentList", label: "OT List", type: "", modual: "COUNSELLOR" }
]

const listOfSideMenuSuperAdmin = [
  { image: ImagePath.Home,
    iconKey: "superAdminStatistics", label: "Home", type: "", modual: "SUPER_ADMIN" },
  { image: ImagePath.Reports,
    iconKey: "superAdminMain", label: "Report", type: "", modual: "SUPER_ADMIN" },
  { image: ImagePath.Pharmacy,
    iconKey: "stockTransfer", label: "Stock Exchange", type: "", modual: "SUPER_ADMIN" },
  { image: ImagePath.Setting,
    iconKey: "superAdminSettings", label: "Settings", type: "", modual: "SUPER_ADMIN" },

  ]  
  const listOfSideMenuBranchAdmin = [
    { image: ImagePath.Home,
      iconKey: "superAdminStatistics", label: "Home", type: "", modual: "BRANCH_ADMIN" },
    { image: ImagePath.Reports,
      iconKey: "superAdminMain", label: "Report", type: "", modual: "BRANCH_ADMIN" },
    { image: ImagePath.Pharmacy,
      iconKey: "stockTransfer", label: "Stock Exchange", type: "", modual: "BRANCH_ADMIN" },
    { image: ImagePath.Setting,
      iconKey: "superAdminSettings", label: "Settings", type: "", modual: "BRANCH_ADMIN" },
   
    ]   
    
// const listOfSideMenuItemsInhomeWoIP = [
//   { image: ImagePath.Home,
//       iconKey: "home", label: "Home", type: "common", modual: "" },
//   { image:ImagePath.Invoice,
//      iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
//   { image: ImagePath.OTlist,
//       iconKey: "otAppointmentList", label: "OT List", type: "common", modual: "" },
 
//   { image:ImagePath.Invoice,
//       iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
//   { image: ImagePath.NutritionChart,
//       iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
//   { image:ImagePath.BIRegistry,
//       iconKey: "registry", label: "Registry", type: "common", modual: "" },

  

//   // Fo => IP
//   { image: ImagePath.DoctorNotes,
//     iconKey: "Result", label: "Reports", type: "", modual: "FO" },
// ]

const listOfSideMenuItemsInIpHome = [
  // FO => IP Menu
  { image:ImagePath.Home,
     iconKey: "home", label: "OP", type: "common", modual: "IP" },
  { image: ImagePath.IP, 
    iconKey: "iPPatientList", label: "IP Home", type: "common", modual: "IP" },
  { image:ImagePath.OTlist,
    iconKey: "otAppointmentList", label:"OT List", type: "common", module:"IP"},  
  { image:ImagePath.Invoice,
     iconKey: "ipallTransactions", label: "IP Billing Transactions", type: "common", modual: "IP" },
  { image:ImagePath.Invoice,
     iconKey: "ipPatientCheckoutList", label: "Checked out patients", type: "common", modual: "IP" },

  // FO =>IP Menu
]

const listOfSideMenuItemsInIpPatientPage=[
  // FO => IP Menu
  { image:ImagePath.IP,
    iconKey: "iPPatientList", label: "IP Home", type: "common", modual: "IP" },
  { image:ImagePath.DoctorNotes,
     iconKey: "optDoctorNotes", label: "Doctor Notes", type: "common", modual: "" },
  { image: ImagePath.Demogra,
     iconKey: "demographics", label: "Demographics", type: "common", modual: "" },
  { image:ImagePath.Invoice,
    iconKey: "ipPatientCreateBill", label: "IP Billing", type: "common", modual: "" },
  { image:ImagePath.Report,
     iconKey: "reportsScreen", label: "Reports", type: "common", modual: "" },

  // FO =>IP Menu
]



const listOfSideMenuItemsInPatient = [
  { image:ImagePath.Home,
     iconKey: "home", label: "Home", type: "common", modual: "" },
  { image:ImagePath.DoctorNotes,
     iconKey: "optDoctorNotes", label: "Doctor Notes", type: "common", modual: "" },
    // for All modual common History section
  { image: ImagePath.History,
     iconKey: "dbCommonHistory", label: "History", type: "common", modual: "" },
  // for All modual common History section

  { image: ImagePath.Demogra,
     iconKey: "demographics", label: "Demographics", type: "common", modual: "" },
  // for psy  Ratingscale section section
  { image:ImagePath.PsychiatryHistory,
      iconKey: "psychiatryHistory", label: "Psychiatry History", type: "common", modual: "" },
  { image: ImagePath.PaediatricMentalHealth,
    iconKey: "peadiatricMentalHealth", subLabel:"Mental Health",label: "  Pediatric", type: "common", modual: "" },
  { image: ImagePath.SubstanceAbuse1,
     iconKey: "substanceAbuse", label: "Substance Abuse", type: "common", modual: "" },
  { image: ImagePath.Ratingscale,
     iconKey: "ratingScale", label: "Rating Scale", type: "common", modual: "" },

  // for psy  Ratingscale section section

  // Diabetic Menu
  { image:ImagePath.OBGYNHistory,
      iconKey: "dbHistory", label: "DB History", type: "", modual: "DB" },
  { image: ImagePath.OBGYNHistory,
     iconKey: "dbChartHistory", label: "Chart & Log", type: "", modual: "DB" },
     { image: ImagePath.Foot,
      iconKey: "dbFootClinic", label: "Foot Clinic", type: "", modual: "DB" },
  // Diabetic Menu


  // OBGYN Menu
  { image: ImagePath.OBGYNHistory, 
    iconKey: "obgynHistory", label: "Obgyn History", type: "", modual: "OBGYN" },
  { image: ImagePath.OvulationChart,
   iconKey: "ovulation", label: "Ovulation chart", type: "", modual: "OBGYN" },
  { image: ImagePath.ObgynChart,
     iconKey: "antenatalChart", label: "Antenatal Chart", type: "", modual: "OBGYN" },
  // OBGYN Menu


  // Paediatric Menu
  { image:ImagePath.OBGYNHistory, 
    iconKey: "pdHistory", label: "PD History", type: "", modual: "PD" },
  { image: ImagePath.DevelopmentalAssessment,
      iconKey: "devlopmentassessment", label: "Developmental Assessment", type: "", modual: "PD" },
  { image: ImagePath.Immunization,
     iconKey: "immunization", label: "Immunization", type: "", modual: "PD" },
  { image: ImagePath.GrowthChart,
    iconKey: "growthChart", label: "Growth Chart", type: "", modual: "PD" },
  // Paediatric Menu

  // FO Menu
  // { image:ImagePath.OBGYNHistory,
  //    iconKey: "printOrder", label: "Print Order", type: "", modual: "FO" },
  { image:ImagePath.DevelopmentalAssessment,
  iconKey: "formsAndCertificate", label: "Forms & Certificate", type: "", modual: "FO" },
  // FO Menu



  // { image: ImagePath.Invoice,
  //    iconKey: "billingScreen", label: "Billing", type: "common", modual: "" },
  { image:ImagePath.Report, 
    iconKey: "reportsScreen", label: "Reports", type: "common", modual: "" },

];


// For Optimetry
const listOfSideMenuItemsInOptiHome = [
  { image: ImagePath.Home,
    iconKey: "home", label: "Home", type: "common", modual: "" },
];

let listofSideMenuForDoctor = [
  { image: ImagePath.Home,
      iconKey: "home", label: "Home", type: "common", modual: "" },
  { image:ImagePath.Invoice,
     iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
  { image: ImagePath.OTlist,
      iconKey: "otAppointmentList", label: "OT List", type: "common", modual: "" },
 
  { image:ImagePath.Invoice,
      iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
  { image: ImagePath.NutritionChart,
      iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
  { image:ImagePath.BIRegistry,
      iconKey: "registry", label: "Registry", type: "common", modual: "" },
  // Fo => IP
  { image: ImagePath.DoctorNotes,
    iconKey: "Result", label: "Reports", type: "", modual: "FO" },
]

export const SideMenuComponents = (props) => {

  const [adminAccess, setAdminAccess ] = useState(false)

  useEffect(() => {
    setAdminAccess(props.isRoleAdmin)
  },[props.isRoleAdmin])
  var isPatientPage = false;
  if ((Object.keys(props.patientAppointment).length > 0 && Object.keys(props.patientInfo).length > 0) ||
    props.selectedScreen == "optDoctorNotes" || props.isCurrentPageIsIP

  ) {
    isPatientPage = true;
  }
 
  if(props.isRoleAdmin && adminAccess != props.isRoleAdmin) {
    listOfSideMenuItemsInhome.push({
      image: ImagePath.Configuration, iconKey: "adminConfig", label: "Configuration", type: "common", modual: "" 
    })
    listOfSideMenuItemsInhome.push({
      image: ImagePath.Report, iconKey: "adminDashboard", label: "Dashboard", type: "common", modual: "" 
    })

      [{ image: ImagePath.Home,
          iconKey: "home", label: "Home", type: "common", modual: "" },
      { image:ImagePath.Invoice,
         iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
      { image: ImagePath.OTlist,
          iconKey: "otAppointmentList", label: "OT List", type: "common", modual: "" },
     
      { image:ImagePath.Invoice,
          iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
      { image: ImagePath.NutritionChart,
          iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
      { image:ImagePath.BIRegistry,
          iconKey: "registry", label: "Registry", type: "common", modual: "" },
      {image: ImagePath.Configuration, iconKey: "adminConfig", label: "Configuration", type: "common", modual: "" },
      // Fo => IP
      { image: ImagePath.DoctorNotes,
        iconKey: "Result", label: "Reports", type: "", modual: "FO" }]
  }

  const listofSideMenuForDoctor = [
    { image: ImagePath.Home,
        iconKey: "home", label: "Home", type: "common", modual: "" },
    // { image:ImagePath.Invoice,
    //    iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
    // { image: ImagePath.OTlist,
    //     iconKey: "otAppointmentList", label: "OT List", type: "common", modual: "" },
   
    // { image:ImagePath.Invoice,
    //     iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
    { image: ImagePath.NutritionChart,
        iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
    { image:ImagePath.BIRegistry,
        iconKey: "registry", label: "Registry", type: "common", modual: "" },
    // Fo => IP
    { image: ImagePath.DoctorNotes,
      iconKey: "Result", label: "Reports", type: "", modual: "FO" },
  ]
  if(props.isRoleAdmin) {
    listofSideMenuForDoctor.push({
      image: ImagePath.Configuration, iconKey: "adminConfig", label: "Configuration", type: "common", modual: "" 
    })
  }
  var sideMenuList = [];

  // console.log("selectedLabPatientDetails ----------> "+JSON.stringify(props.selectedLabPatientDetails))
  /*
  if(props.userType == Constants.ROLE_OPTOMETRY) {
    if (isPatientPage) {
      sideMenuList = listOfSideMenuItemsInPatient
    } else {
      sideMenuList = listOfSideMenuItemsInOptiHome
    }
  } else 
  */
  if(props.userType == "LAB"){
    if(Object.keys(props.selectedLabPatientDetails).length == 0 ){
      sideMenuList = listOfSideMenuItemsInLabhome
    } else if (Object.keys(props.selectedLabPatientDetails).length > 0) {
      sideMenuList = listOfSideMenuItemsInLabPatientPage
    } else {
      sideMenuList = listOfSideMenuItemsInLabhome
    }
  } else if (props.isCurrentPageIsIP && Object.keys(props.selectedIpPatientDetails).length == 0) {
      var isOPT = false
      AsyncStorage.getItem("loggedInData",(err, response) => {
        if(response){
          var loggedInDataObj = JSON.parse(response)
        if(loggedInDataObj.clinic_type){
          isOPT = loggedInDataObj.clinic_type.is_opt_clinic
        }else{
          isOPT = false;
        }
        }else{
          console.log("Error")
        }
      })
    sideMenuList = listOfSideMenuItemsInIpHome
  } else if (props.isCurrentPageIsIP && Object.keys(props.selectedIpPatientDetails).length > 0) {
    sideMenuList = listOfSideMenuItemsInIpPatientPage
  } else if(props.userType === "SUPER_ADMIN") {
    sideMenuList = listOfSideMenuSuperAdmin
  } else if(props.userType === "BRANCH_ADMIN"){
    sideMenuList = listOfSideMenuBranchAdmin
  } else {
    if (isPatientPage && props.isNoAppointment) {
     sideMenuList = listOfSideMenuItemsInPatient.filter(info => info.iconKey !== "optDoctorNotes")
    }else if (isPatientPage &&  !props.isNoAppointment){
      if(props.userType == "OPTOMETRY"){
        const indexOfObject = listOfSideMenuItemsInPatient.findIndex(object => {
          return object.label==="Demographics";
        })
        const index = listOfSideMenuItemsInPatient.filter(data => {
          return data.label === "Reports"
        })
          listOfSideMenuItemsInPatient.splice(indexOfObject,1);
          listOfSideMenuItemsInPatient.push(index)
      }
      sideMenuList = listOfSideMenuItemsInPatient
    }else {
      AsyncStorage.getItem("IsIpAvailable", (err,result) => {

        if(result == "true"){
          if(localStorage.getItem("UserID")!=="79" && localStorage.getItem("UserID")!=="89" && localStorage.getItem("UserID")!=="10" && localStorage.getItem("UserID")!=="1" && localStorage.getItem("UserID")!=="111" && localStorage.getItem("UserID")!=="82" && localStorage.getItem("UserID")!=="118" && localStorage.getItem("UserID")!=="148"){
            sideMenuList = listOfSideMenuItemsInhome;
            // if((sideMenuList[sideMenuList.length-1].label) ==="Quick Billing"){
            //   sideMenuList.splice(sideMenuList.length-1, 1);
            // }
            // if((sideMenuList[sideMenuList.length-2].label) ==="Quick Billing"){
            //   sideMenuList.splice(sideMenuList.length-2, 1);
            // }
          }
          else{
            sideMenuList = listOfSideMenuItemsInhome
          }
        }else{
          AsyncStorage.getItem("loggedInData", (err,result) => {
            var loggedInDataObj = JSON.parse(result)
            if(loggedInDataObj.service_type == "COUNSELLOR"){
              sideMenuList = listOfSideMenuItemsInCounsellorHome
            } else if(loggedInDataObj.service_type == "OT"){
              if(loggedInDataObj.is_OPT){sideMenuList = [
                { image: ImagePath.Home,
                  iconKey: "otAppointmentList", label: "Home", type: "", modual: "OT" },
                { image: ImagePath.Vendor,
                  iconKey: "otVendor", label: "Vendor Management", type: "", modual: "OT" },
                { image: ImagePath.StockUsage,
                    iconKey: "stockUsage", label: "Stock Usage", type: "", modual: "OT" },
                { image: ImagePath.Inventory,
                    iconKey: "otInventory", label: "Inventory", type: "", modual: "OT" },
                { image: ImagePath.Report,
                    iconKey: "oTSurgeryNotesReport", label: "Reports", type: "", modual: "OT" }
              ]}else{
                sideMenuList = [
                  { image: ImagePath.Home,
                    iconKey: "otAppointmentList", label: "Home", type: "", modual: "OT" },
                  { image: ImagePath.StockUsage,
                      iconKey: "stockUsage", label: "Stock Usage", type: "", modual: "OT" },
                  { image: ImagePath.Inventory,
                      iconKey: "otInventory", label: "Inventory", type: "", modual: "OT" },
                  { image: ImagePath.Report,
                      iconKey: "oTSurgeryNotesReport", label: "Reports", type: "", modual: "OT" }
                ]
              }
            } else{
              if(loggedInDataObj.service_type == "PSY"){
                sideMenuList = [
                  { image: ImagePath.Home,
                      iconKey: "home", label: "Home", type: "common", modual: "" },
                  { image:ImagePath.Invoice,
                     iconKey: "allBillingTransactions", label: "Billing Transactions", type: "common", modual: "" },
                  { image:ImagePath.Invoice,
                      iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
                  { image: ImagePath.NutritionChart,
                      iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
                  { image:ImagePath.BIRegistry,
                      iconKey: "registry", label: "Registry", type: "common", modual: "" },
                  // Fo => IP
                  { image: ImagePath.DoctorNotes,
                    iconKey: "Result", label: "Reports", type: "", modual: "FO" },
                ]
              }else if(loggedInDataObj.service_type == "OPTOMETRY"){
                sideMenuList = [
                  { image: ImagePath.Home,
                      iconKey: "home", label: "Home", type: "common", modual: "" },
                  // { image:ImagePath.Invoice,
                  //     iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
                ]
              }else if(loggedInDataObj.service_type == "OPT"){
                sideMenuList = [
                  { image: ImagePath.Home,
                      iconKey: "home", label: "Home", type: "common", modual: "" },
                  { image:ImagePath.Invoice,
                      iconKey: "followUp", label: "Follow-up", type: "common", modual: "" },
                  { image: ImagePath.NutritionChart,
                      iconKey: "dbNutritionChart", label: "Nutrition Chart", type: "common", modual: "" },
                  { image:ImagePath.BIRegistry,
                      iconKey: "registry", label: "Registry", type: "common", modual: "" },
                ]
              }else{
               sideMenuList = listofSideMenuForDoctor
              }
            }
          }) 
        }
      }) 
    }
  }




  return (
    <View style={{ justifyContent: 'space-between' }}>

      {
        sideMenuList.map((item, index) => {
          if (
            
              item.type == "common" || (item.type == "" && props.userType == item.modual) 
            ) {

            if(
              (
                (props.userType == "OPT" || props.userType == "FO") && item.iconKey == "dbCommonHistory" 
              ) || (
                  // (props.userType == Constants.ROLE_OPTOMETRY || props.userType == "OPT" || props.userType == "FO" || props.userType == "DOCTOR" || props.userType == "PD" || props.userType == "DB" || props.userType == "OBGYN") 
                  props.userType !== "PSY"
                  && 
                  (item.iconKey == "ratingScale" || item.iconKey == "psychiatryHistory" || item.iconKey == "peadiatricMentalHealth" || item.iconKey == "substanceAbuse") 
              ) || (
                props.userType == Constants.ROLE_OPTOMETRY && ( item.iconKey == "billingScreen" || item.iconKey == "allBillingTransactions" || item.iconKey == "dbCommonHistory" || item.iconKey == "billingConfig" )
              )
            )
            {

            }else{
              return (
                <TouchableOpacity key={index}
                  // disabled = {item.iconKey == "ipPatientCreateBill" && !props.selectedIpPatientDetails.id ? true : false}
                  onPress={() => {
                    // alert(item.iconKey)
                    if(item.iconKey == "ipPatientCreateBill" && !props.selectedIpPatientDetails.id && props.selectedScreen == "admissionDetails" ) {
                       props.showResposeValue("error", "Kindly navigate to IP home")
                    } else {
                      props.changeScreen(item.iconKey)
                    }
                  }}>
                  <RenderImgBtn
                    image={item.image}
                    label={item.label}
                    subLabel={item.subLabel ? item.subLabel :""}
                    selectedScreen={props.selectedScreen == item.iconKey ? true :
                      ( props.selectedScreen == ("ServiceDetailed") || props.selectedScreen == ("ServiceOverview") || props.selectedScreen == ("DayEndOverview") || props.selectedScreen == ("AppointmentTypeReport") || props.selectedScreen == ("PatientTypeReport") || props.selectedScreen == ("RefferalReport")  || props.selectedScreen == ("RefferenceReport")  || props.selectedScreen == ("IPDoctorReport") || props.selectedScreen == ("IPbillStatusReport")|| props.selectedScreen == ("CorporatebillsReport") || props.selectedScreen == ("OpticalCRMSales")) ? "Result" == item.iconKey ? true : false 
                      :( props.selectedScreen == ("ipRoomConfig") || props.selectedScreen == ("tagConfig") || props.selectedScreen == ("packageConfig") || props.selectedScreen == ("userConfig") || props.selectedScreen == ("corpConfig") || props.selectedScreen == ("labRefferedDoctors") || props.selectedScreen == ("adminQmsConfiq") || props.selectedScreen == ("expenseconfig") || props.selectedScreen == ("settingConfig") || props.selectedScreen == ("DisSumTemplate")) ? "adminConfig" == item.iconKey ? true : false
                       : ( props.selectedScreen === "inPatientBilling" || props.selectedScreen === "outPatientBilling" || props.selectedScreen === "pharmacyBilling" || props.selectedScreen === "laboratoryBilling" ) ? "allBillingStatistics" == item.iconKey ? true : false
                      :( props.selectedScreen == ("brachialIndex") || props.selectedScreen == ("biothesiometry")) ? "dbFootClinic" == item.iconKey ? true : false
                       : false}
                  />
                </TouchableOpacity>
              )
            }
          } else {
            return <View key={index} />
          }
        })
      }


    </View>
  )
}



export const RenderImgBtn = (props) => {
  return (
    <View style={styles.sideBarMenu}>
      <View style={[{ backgroundColor: props.selectedScreen ? color.white : ""}, styles.SideMenuButton]}>
        <Image
          source={props.image}
          style={{
            height: "1.5vw",
            width: "1.6vw",
            tintColor: props.selectedScreen ? color.themeDark : color.white,//color.lightGray
          }}
          resizeMode="contain"
        />
      </View>
      <Text style={{ fontSize: '0.7vw', fontWeight: "500", color: props.selectedScreen ? color.white : color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={3}>{props.label}</Text>
      {
        props.subLabel ? 
      <Text style={{ fontSize: '0.7vw', fontWeight: "500", color: props.selectedScreen ? color.white : color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={3}>{props.subLabel}</Text>
        : null
      }
    </View>
  );
};


const styles = StyleSheet.create({
  sideBarMenu: {
    // height: '4.5vw',
    width: "100%", 
    alignItems: "center", 
    justifyContent: "center", 
    alignSelf: 'center',
    alignContent: 'center',
    marginTop: "0.5vw",
    marginBottom: "0.4vw",
    borderRadius: '3.25vw'
  },
  SideMenuButton:{
    paddingHorizontal: '0.58vw', 
    paddingVertical: '0.45vw',
    borderRadius: '0.26vw' 
  }
})