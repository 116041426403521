import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import { CommonDatePicker, } from './registryCommonComponents'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService"
import { Constants } from "../../../../utils/Constants";
import { RegistryContext } from "./RegistryContext";


export class RegistryDrugs extends Component {
    constructor(props) {
        super(props);
        this.state = {



            listOfDrug: [],
            selectedDrug: [],
            drugName: ""


        };
    }


    getDrugs = (query, type) => {


        var serviceUrl = Constants.REGISTRY_COMMON_SEARCH + "?search_key=" + query + "&search_type=" + "drug name";




        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getDrugsSuccess,
            this.getDrugsFailure
        );

    }
    getDrugsSuccess = response => {

        if (response.status == "success") {
            var states = this.state
            states["listOfDrug"] = response.data
            this.setState({
                states
            })
        }


    };

    getDrugsFailure = error => {
        console.log(error);
    }



    onPressselectedDrug(item, contaxt) {
        // var billList=[];
        var { selectedDrug } = this.state;



        selectedDrug.push(item);




        selectedDrug = this.removeDuplicate(selectedDrug)
        this.setState({
            selectedDrug,
            drugName: "",
            listOfDrug: [],

        }, () => {
            contaxt("Drugs", this.state.selectedDrug)
        })




    }

    renderAddress(drugdata,contaxt) {
        return (
            <View>
                <CommonHistorySectionHeader
                    heading1={"Brand Name"}
                    noOfColumn={1}
                    columnSize={[1]}
                />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    {
                        drugdata.map((item, index) => {
                            return (
                                <View>

                                    <View style={[{
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingTop: 5,
                                        marginLeft: 15,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        marginTop: 10,
                                        paddingBottom: 5,
                                        flexDirection: 'row',
                                        // justifyContent:'center',
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        borderColor: "#888888"
                                    },
                                    ]}

                                    >

                                        <Text style={{ color: "black" }}>
                                            {item}
                                        </Text>
                                        <View style={{ height: 15, width: 1, backgroundColor: color.black, marginHorizontal: 10 }} />
                                        <TouchableOpacity onPress={() => {
                                            let { selectedDrug } = this.state;
                                            selectedDrug.splice(index, 1)
                                            this.setState({
                                                selectedDrug,
                                                drugName: "",

                                            }, () => {
                                                contaxt("Drugs", this.state.selectedDrug)

                                            })

                                        }}>
                                            <Text style={{ color: "black" }}>{"X"}</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            )
                        })
                    }
                </View>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', marginTop: 10, paddingLeft: 15 }}>
                    <View style={{ flex: 1 }}>
                        {this.renderCommonTextBox("", this.state.drugName, "drugName")}
                        {this.state.drugName && this.state.listOfDrug.length > 0 ?
                            < View style={{
                                backgroundColor: color.white,
                                maxHeight: 200,
                                borderRadius: 10,
                                width: '90%',
                                justifyContent: 'center',
                                alignContent: 'center',
                                padding: 10,
                                marginLeft: 10,
                                marginTop: 40,
                                position: 'absolute',
                            }}>
                                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                    <FlatList
                                        data={this.state.listOfDrug}
                                        renderItem={({ item }) => (
                                            <View style={{
                                                flexDirection: "row",
                                                bottom: 10,
                                                marginBottom: 10,
                                                justifyContent: "space-evenly",
                                                width: "100%",
                                                alignItems: "center",
                                                marginTop: 10,
                                                zIndex: 10,
                                            }}
                                            >
                                                <View style={{ width: '100%' }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            this.onPressselectedDrug(item, contaxt);
                                                        }
                                                        }>
                                                        <Text>{item}</Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>
                                        )}
                                        enableEmptySections={true}


                                    //keyExtractor={(item, index) => index.toString()}
                                    />
                                </ScrollView>
                            </View>
                            : null}
                    </View>

                </View>

            </View >
        )
    }




    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }


    onPressButton(contectCallBack = "", checkIsCallBack = false, key, value) {
        var states = this.state;
        if (checkIsCallBack) {
            contectCallBack(key, value);
        }
        if (key == "clear") {
            this.setState({
                selectedDrug: [],
                drugName: ""
            })

        }
        this.setState({ states })

    }


    renderCommonTextBox(placeholder, value, textBoxkey) {
        return (
            <View style={[{ height:'2.81vw', marginRight: 15 }]}>
                <Text style={{ zIndex: 2, fontSize: '0.8vw', position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: "#888888" }}>{placeholder}</Text>


                <TextInput
                    style={{
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        width: "100%",
                        height: '2vw',
                        fontSize:'0.9vw',
                        paddingLeft: 10,
                        marginTop: 8
                    }}
                    value={value}
                    onChangeText={(text) => {
                        var states = this.state;
                        states[textBoxkey] = text;
                        this.setState({ states }, () => {
                            this.getDrugs(text)
                        })
                    }}
                />




            </View>
        )
    }




    renderFooterAction(contectCallBack) {
        var actions = []

        actions = [
            { label: "Apply", value: "Apply" },
            { label: "Clear", value: "clear" },
        ]

        return (
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15 }}>
                {
                    actions.map((item, index) => {
                        return (
                            <View style={{ marginRight: 15 }}>
                                <CommonButton
                                    // disable={this.state.isLocked}
                                    item={{}}
                                    selectedvalue={{}}
                                    butttonText={item.label}
                                    buttonType={item.value == "Apply" ? "theme" : "outlineTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    buttonAction={this.onPressButton.bind(this, contectCallBack, true)}
                                    buttonKey={item.value} />
                            </View>
                        )
                    })
                }

            </View>
        )
    }


    render() {

        return (
            <RegistryContext.Consumer>
                {(getContext) => {
                    var drugdata = getContext.drugdetails;

                    return (
                        <>

                            <View>
                                <View>
                                    {this.renderAddress(drugdata,getContext.drugsDatas.bind(this))}
                                </View>

                                <View style={{ zIndex: -1 }}>
                                    {this.renderFooterAction(getContext.drugsDatas.bind(this))}
                                </View>

                            </View>
                        </>
                    )

                }

                }
            </RegistryContext.Consumer>
        );
    }
}
