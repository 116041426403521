import React from "react";
import { View, Text, StyleSheet, Platform, TextInput ,TouchableOpacity} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
import Item from "antd/lib/list/Item";

const platform = Platform.OS;
const pageTitle = "History";
const pageName = "History";
const isPageName = "isHistory";
const isHospital = "isHospital";
const isSocial = "isSocial";
const isFamily = "isFamily";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      History: [],
      Hospitalization: [],
      Family: [],
      Social: [],
      Hospitalcomment: "",
      Familycomment: "",
      Socialcomment: "",
      selectedIssurgery: "",
      comment: "",
      data: {},
      isHistory:
        isPageName === this.props.selectedView ||
          isHospital === this.props.selectedView ||
          isSocial === this.props.selectedView ||
          isFamily === this.props.selectedView
          ? true
          : false,

      // isHistory: isPageName === this.props.selectedView ? true : false,
    };
  }

  componentDidMount() {
    this.getHistory();
    this.getHospitalization();
    this.getFamilyHistory();
    this.getSocialHistory();
  }

  renderTestIconBtn = (text, icon, createBtn, flag, borderColor) => {
    return (
      <View
        style={{
          height: 35,
          width: text === "New" ? 100 : "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 20 : 20,
          flexDirection: "row",
          backgroundColor: createBtn
            ? color.themeBlue
            : text === "New"
              ? color.white
              : color.white,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 1,
          borderColor: borderColor == "inhouse" ? "#ED1E86" : "#0280FF",
        }}
      >
        <Text
          style={{
            fontSize: platform === "web" ? 12 : 14,
            color: text === "New" ? "#848484" : "#848484",
            fontWeight: "500",
          }}
        >
          {text}
        </Text>
        <Icon
          name={icon}
          size={platform === "web" ? 15 : 20}
          color={text === "New" ? "#848484" : "#848484"}
        />
      </View>
    );
  };

  componentWillReceiveProps(props) {
    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getHistory();
      // refresh false
      this.props.refreshData("");
    }

    if (props.reloadData && isHospital == props.reloadData) {
      this.getHospitalization();
      this.props.refreshData("");
    }

    if (props.reloadData && isFamily == props.reloadData) {
      this.getFamilyHistory();
      this.props.refreshData("");
    }

   // console.log("*****************>>" + props.reloadData)

    if (props.reloadData && (isSocial == props.reloadData)) {
      this.getSocialHistory();
      // alert(props.reloadData )
      this.props.refreshData("");
    }

    // let updateFontColor = isPageName === props.selectedView ? true : false;
    let updateFontColor =
      isPageName === this.props.selectedView ||
        isHospital === this.props.selectedView ||
        isSocial === this.props.selectedView ||
        isFamily === this.props.selectedView
        ? true
        : false;

    if (updateFontColor !== this.props.isHistory) {
      this.setState({ isHistory: updateFontColor });
    }
  }

  deleteItem = (item, plan) => {
    //this.props.editItem(pageName, item);
    var service_url = Constants.MEDICAL_HISTORY_DELETE;
    let data = {
      patient_id: this.state.patientAppointment.patient_id,
      id: item.id,
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteSuccess,
      this.getSymptomsFailure
    );
  };

  getDeleteSuccess = (response) => {
    if (response.status === "success") {
      this.getHospitalization();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  deleteFamilyItem = (item, plan) => {
    //this.props.editItem(pageName, item);
    var service_url = Constants.DELETE_FAMILY_HISTORY;
    let data = {
      patient_id: this.state.patientAppointment.patient_id,
      id: item.id,
    };

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.deleteFamilyItemSuccess,
      this.deleteFamilyItemFailure
    );
  };

  deleteFamilyItemSuccess = (response) => {
    if (response.status === "success") {
      this.getFamilyHistory();
    } else {
      this.props.showResposeValue("error", response.message);
    }
  };

  deleteFamilyItemFailure = (response) => {
    if (response.status === "error") {
      this.props.showResposeValue("error", response.message);
    }
  };

  renderHistoryItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_name}
              </Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>

        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };

  renderHistoryItem2 = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "20%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_name}
              </Text>
            </View>

            <View
              style={{
                width: "20%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.hospitalized_year}
              </Text>
            </View>

            <View
              style={{
                width: "20%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.hospital_name_or_description}
              </Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              >
                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={this.editComplaints.bind(this, item)}
                >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={() => {
                    this.deleteItem(item);
                  }}
                >
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };

  renderHistoryItem3 = (item, index) => {
    return (
      <View>


      {
        item.alcohol ?
<View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Alcohol</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>{item.alcohol}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>
        : null
      }

        
        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />

{
  item.smoking ?
<View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Smoking</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>{item.smoking}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
          <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />

        </View>
  :null
}
        
        {/* <View style={Style.sectionItemLine} /> */}

{
  item.drugs ? 

  <View style={{ paddingTop: 0 }}>
  <View
    style={{
      width: platform === "web" ? "100%" : "75%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <View
      style={{
        width: "50%",
      }}
    >
      <Text style={Style.contentTableDataLead}>Drugs</Text>
    </View>

    <View
      style={{
        width: "50%",
      }}
    >
      <Text style={Style.contentTableDataLead}>{item.drugs}</Text>
    </View>

    <View
      style={{
        width: "10%",
        alignItems: "center",
        height: 60,
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: color.white,
          height: 50,
        }}
      ></View>
    </View>
  </View>
  <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />

</View>
  :null
}

 

        {/* <View style={Style.sectionItemLine} /> */}

        {
          item.daily_activity ?
       
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Daily Activity</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>{item.daily_activity}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
          <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
        </View>
:null
}
        {/* <View style={Style.sectionItemLine} /> */}



        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Married</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >

              <Text style={Style.contentTableDataLead}>{
                item.is_married == true ? "Yes" : "No"}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>
        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />


        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Exercise Daily</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>{item.is_exercise_done_daily == true ? "Yes" : "No"}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View>
        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />

        {
          item.occupation ?
          <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>Occupation</Text>
            </View>

            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={Style.contentTableDataLead}>{item.occupation}</Text>
            </View>

            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              ></View>
            </View>
          </View>
        </View> : null
        }
        
        {/* <View style={Style.sectionItemLine} /> */}
        
        {
          item.occupation ?
          <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
          :null
        }
        
        

      </View>





    );
  };

  renderHistoryItem4 = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "20%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.family_member}
              </Text>
            </View>

            <View
              style={{
                width: "60%",
              }}
            >
              <Text style={Style.contentTableDataLead}>
                {item.disease_names.join()}
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                alignItems: "center",
                height: 60,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50,
                }}
              >
                <TouchableOpacity disabled={this.state.isLocked} onPress={this.editFamily.bind(this, item)}>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={() => {
                    this.deleteFamilyItem(item);
                  }}
                >
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        {/* <View style={Style.sectionItemLine} /> */}
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#E0E0E0",
            width: 350,
          }}
        />
      </View>
    );
  };

  getHospitalization = () => {
    var service_url =
      Constants.GET_HOSPITALISATION_HISTORY_ +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getHospitalizationSuccess,
      this.getHospitalizationFailure
    );
  };

  getHospitalizationSuccess = (response) => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      var field = this.state;
      field["data"] = response.data;
      this.setState({
        field,
        Hospitalization: response.data.patient_hospitalization_history,
        Hospitalcomment: response.data.comments,
      });
    }
  };

  getHospitalizationFailure = (error) => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getFamilyHistory = () => {
    var service_url =
      Constants.GET_FAMILY_HISTORY +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFamilyHistorySuccess,
      this.getFamilyHistoryFailure
    );
  };

  getFamilyHistorySuccess = (response) => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      var field = this.state;
      field["data"] = response.data;
      this.setState({
        field,
        Family: response.data.patient_family_history,
        Familycomment: response.data.comments,
      });
    }
  };

  getFamilyHistoryFailure = (error) => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getSocialHistory = () => {
    var service_url =
      Constants.GET_SOCIAL_HISTORY +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSocialHistorySuccess,
      this.getSocialHistoryFailure
    );
  };

  getSocialHistorySuccess = (response) => {
    if (response.status === "success") {
     // console.log("Social---------->" + JSON.stringify(response));
      var field = this.state;
      field["data"] = response.data;
      this.setState({
        field,
        Social: response.data.patient_social_history,
        Socialcomment: response.data.comments,
      });
    }
  };

  getSocialHistoryFailure = (error) => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  getHistory = () => {
    var service_url =
      Constants.GET_PATIENT_MEDICAL_HISTORY_ +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getHistorySuccess,
      this.getHistoryFailure
    );
  };

  getHistorySuccess = (response) => {
    if (response.status === "success") {
      // alert(JSON.stringify(response.data))
      var field = this.state;
      field["data"] = response.data;

      this.setState({
        enableEdit: true,
        field,
        History: response.data.patient_medical_history,
        comment: response.data.comments,
      });
    }
  };

  getHistoryFailure = (error) => {
   // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderTestItem = (item, index, borderColor) => {
    return (
      <View style={{ marginRight: 10 }}>
        <View style={{ paddingTop: 0 }}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
              }}
            >
              <TouchableOpacity>
                {this.renderTestIconBtn(
                  item.name,
                  "close",
                  false,
                  "",
                  borderColor
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  };

  editComplaints = (item, type, event) => {
    // alert(JSON.stringify(item));

    var data = {
      id: item.id,
      hospitalisedOrsurgeries: item.is_surgery,
      hospitalisedFor: item.disease_name,
      year: item.hospitalized_year,
      hospitalName: item.hospital_name_or_description,
      comment: this.state.Hospitalcomment,
    };

    this.props.triggerNewItem(isHospital);
    this.props.editItem(isHospital, data);
  };

  // triggerNewItem = (key) => {
  //   this.props.triggerNewItem(key);
  // };

  editFamily = (item, type, event) => {
    // alert(this.state.Familycomment);

    var data = {
      id: item.id,
      family_member: item.family_member,
      disease_names: item.disease_names,
      description: item.description,
       comments: this.state.Familycomment
    };

    this.props.triggerNewItem(isFamily);
    this.props.editItem(isFamily, data);
  };

  triggerNewItem = (key) => {
    this.props.triggerNewItem(key);
  };


  render() {
    return (
      <View>
        <View
          style={{ marginLeft: 5, width: platform === "web" ? "100%" : "75%", flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{  width: "30%", }} >
            {this.renderTitleBorder(
              this.state.isHistory,
              pageTitle,
              "isHistory",
              this.triggerNewItem
            )}
          </View>
        </View>

        <View
          style={{
            marginTop: 20,
            // marginLeft: 5,
            width: platform === "web" ? "100%" : "45%",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <View
            style={{
              width: "50%",
              flexDirection: "row",
              marginLeft: 40,
            }}
          >
            {this.renderSubTitleBorder(
              this.state.isHistory,
              "Medical History",
              "isHistory",
              this.triggerNewItem
            )}
          {this.state.History.length > 0 ? 
            <TouchableOpacity
            disabled={this.state.isLocked}
              onPress={() => {
                this.props.editItem(
                  "isHistory",
                  this.state.History,
                  "isHistory",
                  this.state.comment
                );
              }}
              style={{ marginLeft: 200, marginBottom: 10 }}
            >
              {this.renderIdButton("pencil")}
            </TouchableOpacity> : null
            }
          </View>

          <View
            style={{
              width: "50%",
              marginLeft: 30,
              flexDirection: "row",
            }}
          >
            {this.renderSubTitleBorder(
              this.state.isHistory,
              "Hospitalization",
              "isHospital",
              this.triggerNewItem
            )}
          </View>
        </View>

        <View style={{ marginTop: 10, padding: 10 }}>
          <View
            style={{
              // marginLeft: 5,
              width: platform === "web" ? "90%" : "45%",
              flexDirection: "row",
              justifyContent: "space-between",
              // alignItems:'flex-end'
            }}
          >
            <View style={{ width: "40%" }}>
              {this.state.History.map((item, index) => {
                return this.renderHistoryItem(item, index);
              })}
              {this.state.History.length > 0 ? 
              <View style={{ marginTop: 20 }}>
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    placeholder="Comments"
                    multiline={true}
                    style={{
                      paddingHorizontal: 10,
                      height: 80,
                      borderColor: "#D9D9D9",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                    value={this.state.comment}
                  />
                </View>
              </View> : null }
            </View>

            <View style={{ width: "40%" }}>
              {this.state.Hospitalization.map((item, index) => {
                return this.renderHistoryItem2(item, index);
              })}
              {this.state.Hospitalization.length > 0 ?
              <View style={{ marginTop: 20 }}>
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    multiline={true}
                    placeholder="Comments"
                    style={{
                      paddingHorizontal: 10,
                      textAlign: 'left',
                      height: 80,
                      borderColor: "#D9D9D9",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                    value={this.state.Hospitalcomment}
                  />
                </View>
              </View>
               : null }
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: 20,
            // marginLeft: 5,
            width: platform === "web" ? "100%" : "45%",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <View
            style={{
              width: "50%",
              flexDirection: "row",
              marginLeft: 40,
            }}
          >
            {this.renderSubTitleBorder(
              this.state.isHistory,
              "Social History",
              "isSocial",
              this.triggerNewItem
            )}
          {this.state.Social.length > 0 ? 
            <TouchableOpacity
            disabled={this.state.isLocked}
              onPress={() => {
                this.props.editItem(
                  "isSocial",
                  this.state.Social,
                  "isSocial",
                  this.state.Socialcomment
                );
              }}
              style={{ marginLeft: 200, marginBottom: 10 }}
            >
              {this.renderIdButton("pencil")}
            </TouchableOpacity>
            : null }
          </View>

          <View
            style={{
              width: "50%",
              marginLeft: 30,
            }}
          >
            {this.renderSubTitleBorder(
              this.state.isHistory,
              "Family History",
              "isFamily",
              this.triggerNewItem
            )}
          </View>
        </View>

        <View style={{ marginTop: 10, padding: 10 }}>
          <View
            style={{
              // marginLeft: 5,
              width: platform === "web" ? "90%" : "45%",
              flexDirection: "row",
              justifyContent: "space-between",
              // alignItems:'flex-end'
            }}
          >
            <View style={{ width: "40%" }}>
              {this.state.Social.map((item, index) => {
                return this.renderHistoryItem3(item, index);
              })}

              {/* <View style={{ marginTop: 20 }}>
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    placeholder="Comments"
                    style={{
                      height: 80,
                      borderColor: "#D9D9D9",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                    value={this.state.Socialcomment}
                  />
                </View>
              </View> */}
            </View>

            <View style={{ width: "40%" }}>
              {this.state.Family.map((item, index) => {
                return this.renderHistoryItem4(item, index);
              })}
              {this.state.Family.length > 0 ? 
              <View style={{ marginTop: 20 }}>
                <View
                  style={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    multiline={true}
                    placeholder="Comments"
                    style={{
                      paddingHorizontal: 10,
                      height: 80,
                      borderColor: "#D9D9D9",
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                    value={this.state.Familycomment}
                  />
                </View>
              </View>
              : null }
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Radiotest: {
    marginTop: 10,
    width: 12,
    height: 12,
    backgroundColor: "#0280FF",
  },

  Inhouse: {
    marginTop: 10,
    width: 12,
    height: 12,
    backgroundColor: "#ED1E86",
  },
  textStyle: {
    margin: 24,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  pickerStyle: {
    height: 30,
    borderRadius: 20,

    justifyContent: "center",
    backgroundColor: "white",
    color: "#848484",
    fontSize: platform === "web" ? 14 : 12,
  },
  button: {
    alignItems: "center",
    backgroundColor: color.themePink,
    color: "white",
    padding: 10,
    borderRadius: 10,
  },
});
