import React from "react";
import {
  TouchableOpacity,
  View,
  Text,
  // FlatList,
  TextInput,
  ScrollView,
  // Image,
  Dimensions,
  Platform,
  TouchableWithoutFeedback,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import Arrow from "react-native-vector-icons/MaterialCommunityIcons";

// import Service from "../../../network/Service";
const screenHeight = Dimensions.get("window").height;


import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
// import GmailInput from "react-native-gmailtype-textinput";
// import SideMenu from "../../SideMenu";
import AsyncStorage from "../../../AsyncStorage";
import Success from "react-native-vector-icons/FontAwesome5";

import Complaints from "./Complaints";
import OscularHistory from "./OscularHistory";
import SystemicIllness from "./SystemicIllness";
import VisionAssessment from "./VisionAssessment";
import Gonioscopy from "./Gonioscopy";
import AutoRefractometry from "./AutoRefroctometry";
import SubjectiveRefraction from "./SubjectiveRefraction";
import AdditionalSubjectiveRefraction from "./AdditionalSubjectiveRefraction";
import PreviousGlassPower from "./PreviousGlassPower";
import GlassPrescription from "./GlassPrescription";
import IOP from "./IOP";
import Medication from "./Medication";
import Vitals from "./Vitals";
import ReviewPlan from "./ReviewPlan";
import Fundus from "./Fundus";
import TreatmentAdvice from "./TreatmentAdvice";
import Documents from "./Documents";
import Symptoms from "./Symptoms";
import Assessment from "./Assesment";
import Allergy from "./Allergy";
import Test from "./Test";
import Examination from "./Examination"
import Prescription from "./Prescription";
import PreviousPrescription from "./PreviousPrescription";
import Plan from "./Plan"
import AnteriorSegment from "./AnteriorSegment"
import PosteriorSegment from "./PosteriorSegment"
import OpthalmologyTest from "./OpthalmologyTest"
import GeneralPatientAdvice from "./GeneralPatientAdvice"
import Counseling from "./Counseling"
import CounselingNew from "./CounselingNew"

import { DocNotesVerticalNavigation } from '../BaseComponent'


// import DoctorNotesNewItem from "./DoctorNotesNewItem";
import OpthalmologyTestNew from "./OpthalmologyTestNew"

import ComplaintsNewItem from "./ComplaintsNew";
import OscularHistoryNew from "./OscularHistoryNew";
import OcularSurgeryNew from "./OcularSurgeryNew";
import SystemicIllnessNew from "./SystemicIllnessNew";
import SystemicAllergyNew from "./SystemicAllergyNew";
import VisionAssessmentNew from "./VisionAssessmentNew";
import GonioscopyNew from "./GonioscopyNew"
import AutoRefractometryNew from "./AutoRefroctometryNew";
import SubjectiveRefractionNew from "./SubjectiveRefractionNew";
import AdditionalSubjectiveRefractionNew from "./AdditionalSubjectiveRefractionNew";
import PreviousGlassPowerNew from "./PreviousGlassPowerNew";
import GlassPrescriptionNew from "./GlassPrescriptionNew";
import IOPNew from "./IOPNew";
import MedicationNew from "./MedicationNew";
import ReviewPlanNew from "./ReviewPlanNew";
import TreatmentAdviceNew from "./TreatmentAdviceNew";
import DocumentNew from "./DocumentNew";
import VitalsNew from "./VitalsNew";
import FundusNew from "./FundusNew";
import SymptomsNew from "./SymptomsNew";
import AssessmentNew from "./AssessmentNew";
import AllergyNew from "./AllergyNew";
import TestNew from "./TestNew";
import ExaminationNew from "./ExaminationNew";
import PrescriptionNew from "./PrescriptionNew";
import PlanNew from "./PlanNew";
import { ShowDocumentImage } from './ShowDocumentImage'
import History from './History'
import MedicalHistory from './MedicalHistory'
import Hospitalization from './Hospitalization'
import FamilyHistory from './FamilyHistory'
import SocialHistory from './SocialHistory'
// import AnteriorSegmentNew  from "./AnteriorSegmentNew"
// import PosteriorSegmentNew  from "./PosteriorSegmentNew"
import HOPI from '../Psychiatry/HOPI';
import GeneralPatientAdviceNew from './GeneralPatientAdviceNew'

import MentalStatusExaminationNew from "../opthamology/MentalStatusExaminationNew";
import MentalStatusExamination from "../opthamology/MentalStatusExamination";

import CollabSummary from '../opthamology/CollabSummary';
import PatientDocuments from "../opthamology/PatientDocuments"
import { Checkbox } from "antd";

const today = moment(new Date()).format("DD/MM/YYYY");
const platform = Platform.OS;
var Messages = require('../../../utils/InfoMessages')

const navigationHeaderList = [
  { label: "Symptoms", value: "isSymptoms" },
  { label: "Patient Categories", value: "isPatientCategories" },
  { label: "Allergies ", value: "isAllergy" },
  { label: "Vitals", value: "isVitals" },
  { label: "Examination", value: "isExamination" },
  { label: "Diagnosis", value: "isAssessment" },
  { label: "Prescription", value: "isPrescription" },
  { label: "Previous Prescription", value: "isPreviousPrescription" },
  { label: "Test", value: "isTest" },
  { label: "Plan", value: "isPlan" },
  { label: "General-Patient Advice", value: "isGeneralPatientAdvice" },
 { label: "Counselling", value: "isCounseling" },
  { label: "Patient Documents", value: "isPatientDocuments" },

  
]
const foHeaderList = [
  { label: "Vitals", value: "isVitals" }
]

const optimetryNavHeaderList = [ 
  { label: "Complaints", value: "isComplaint" },
  { label: "Ocular History", value: "isOcular" },
  { label: "Systemic Illness", value: "isSystemic" },
  { label: "Vitals", value: "isVitals" },
  { label: "Vision Assessment", value: "isVisionAssessment" },
  { label: "Gonioscopy", value: "isGonioscopy" },
  { label: "Auto Refractometry", value: "isAutoRefractometry" },
  { label: "Previous Glass Power", value: "isPreviousGlassPower" },
  { label: "Subjective Refraction", value: "isSubjectiveRefraction" },
  { label: "Intraocular Pressure", value: "isIOP" },
  { label: "Anterior Segment", value: "isAnteriorSegment" },
  { label: "Posterior Segment", value: "isPosteriorSegment" },
  { label: "Retinoscopic Refraction", value: "isAdditionalSubjectiveRefraction" },
  { label: "Patient Documents", value: "isPatientDocuments" },
]

const optNavigationHeaderList = [ 
  { label: "Complaints", value: "isComplaint" },
  { label: "Ocular History", value: "isOcular" },
  { label: "Systemic Illness", value: "isSystemic" },
  { label: "Vitals", value: "isVitals" },
  { label: "Vision Assessment", value: "isVisionAssessment" },
  { label: "Gonioscopy", value: "isGonioscopy" },
  { label: "Auto Refractometry", value: "isAutoRefractometry" },
  { label: "Previous Glass Power", value: "isPreviousGlassPower" },
  { label: "Subjective Refraction", value: "isSubjectiveRefraction" },
  { label: "Retinoscopic Refraction", value: "isAdditionalSubjectiveRefraction" },
  { label: "Intraocular Pressure", value: "isIOP" },
  { label: "Anterior Segment", value: "isAnteriorSegment" },
  { label: "Posterior Segment", value: "isPosteriorSegment" },
  { label: "Fundus", value: "isFundus" },
  { label: "Diagnosis", value: "isAssessment" },
  { label: "Medication", value: "isMedication" },
  { label: "Glass Prescription", value: "isGlassPrescription" },
  { label: "Review Plan", value: "isReviewPlan" },
  { label: "Treatment Plan", value: "isTreatmentAndAdvice" },
 { label: "Counselling", value: "isCounseling" },
  { label: "Investigation", value: "isOpthalmologyTest" },
  { label: "Documents", value: "isDocuments" },
  { label: "Patient Documents", value: "isPatientDocuments" },
]
const psychiatryDoctorNotes=[
  { label: "Symptoms", value: "isSymptoms" },
  { label: "Patient Categories", value: "isPatientCategories" },
  { label: "Allergies ", value: "isAllergy" },
  { label: "HOPI", value: "isHOPI"},
  { label: "Vitals", value: "isVitals" },
  { label: "Examination", value: "isExamination" },
  { label: "Mental Status Examination", value: "isMentalStatusExamination"},
  { label: "Diagnosis", value: "isAssessment" },
  { label: "Prescription", value: "isPrescription" },
  { label: "Previous Prescription",value: "isPreviousPrescription"},
  { label: "Test", value: "isTest" },
  { label: "Plan", value: "isPlan" },
  { label: "General-Patient Advice", value: "isGeneralPatientAdvice" },
  { label: "Patient Documents", value: "isPatientDocuments" },
  
]

import GlassPrescriptionModal from "./GlassPrescriptionModal";
import PatientCategoriesNew from "./PatientCategoriesNew";
import PatientCategories from "./PatientCategories";
// import { TouchableWithoutFeedback } from "react-native-gesture-handler";

export default class DoctorNotes extends BaseComponentStyle {
  constructor(props) {
    super(props);

    
    // console.log(" props - " + JSON.stringify(this.props))
    let patientAppointment = null;
    let userType = '';
    // let selectedClinicOrDoctor = this.state.selectedClinicOrDoctor
    try {

      // patientAppointment = this.props.navigation.state.params.patientAppointment;
      // userType = this.props.navigation.state.params.userType;
      // selectedClinicOrDoctor = this.props.navigation.state.params.selectedClinicOrDoctor;

      patientAppointment = this.props.patientAppointment;
      userType = this.props.userType;
      // alert(userType)
      // selectedClinicOrDoctor = this.props.selectedClinicOrDoctor;

    } catch (error) { }

    this.state = {
      isIpPatientCheckouted:this.props.isIpPatientCheckouted,
      prescriptionIsTransmitted: false,
      selectedClinicOrDoctor : this.props.selectedClinicOrDoctor,
      // selectedClinicOrDoctor: selectedClinicOrDoctor,
      isNoAppointment:false,
      complaintsData: [],
      isAnteriorSegment: false,
      isPosteriorSegment: false,
      isComplaint: true,
      isOpthalmologyTest: false,
      isOcular: false,
      isSystemic: false,
      isVisionAssessment: false,
      Gonioscopy: false,
      isAutoRefractometry: false,
      isSubjectiveRefraction: false,
      isAdditionalSubjectiveRefraction: false,
      isPreviousGlassPower: false,
      isGlassPrescription: false,
      isIOP: false,
      isMedication: false,
      isVitals: false,
      isFundus: false,
      isReviewPlan: false,
      isTreatmentAndAdvice: false,
      isDiagnosis: false,
      isDocuments: false,
      isSymptoms: false,
      isExamination: false,
      isAssessment: false,
      isGeneralPatientAdvice:false,
      isAllergy: false,
      isPatientCategories:false,
      isTest: false,
      isPlan: false,
      isPatientDocuments: false,
      // selectedView: userType === 'OPT' ? "isComplaint" : (userType === 'PD' || userType === 'OBGYN') ? "isSymptoms" : "isHistory",
      selectedView: this.props.userType === Constants.ROLE_OPT ?
        "isComplaint" : this.props.userType === Constants.ROLE_OPTOMETRY ? "isComplaint":
        (this.props.userType === 'PD' || this.props.userType === 'OBGYN' || this.props.userType === "DOCTOR" || this.props.userType === "DB"  || this.props.userType === "PSY") ? "isSymptoms" :
          this.props.userType === Constants.ROLE_FO ?
            "isVitals" :
            "isHistory",
      isAllergy: false,
      isTest: false,
      isPlan: false,
      isHistory: false,
      isHospital: false,
      isFamily: false,
      isSocial: false,
      isCounseling: false,
      // selectedView: userType===Constants.ROLE_FO?"isVitals":(userType===Constants.ROLE_OPT?"isComplaint":"isHistory"),
      currentTime: "",
      patientAppointment: patientAppointment,
      reloadData: "",
      patientInfo: {},
      editItem: {
        title: "",
        item: null
      },

      commonComments: "",
      editedvitals: {},
      vitalsflag: false,
      changeIndex: "",
      // for prescrption
      prescriptionItem: null,
      glassPrescriptionModalFlag: false,
      selectedGlassprescriptionData: [],
      selectedVitals: [],

      vitalsData: [],
      userType: userType,

      isPrescription: false,
      isPreviousPrescription: false,

      prescriptionList: {},
      showNewPrescription: false,
      editPrescriptionData: {},
      addNewPrescription: {},

      // selectedContent: "complaints"
      responseType: "",
      responseMeaasge: "",

      getAvailableEyeTypes: [],
      getAvailableEyeTypesId: [],

      showDocumentImageFlag: false,
      showDocumentImage: "",

      scrollPosition: 10,

      getAssessmentData: {},
      getGPAdviceData:{},
      ExaminationID: "",
      reloadFavouriteID: "",

      // showUploadeTestReportsModal:false

      getTransmitDataFlag: false,

      serviceType: '',
      selectedIpPatientDetails: this.props.selectedIpPatientDetails,
      isCurrentPageIsIP: this.props.isCurrentPageIsIP,
      isMentalStatusExamination:false,

      // selectedClinicOrDoctor: {},
      selectedDoctorId: null,
       
      setScrollRef:null,
      dataSourceCords:[],
      scrollToIndex:0,

      fundusEyeDetails:{},

      //GP
      continueWithSameGlass:false,

      showPickerDropDownFlag:false,
      isTransmit:false,
      gpComments:'',
      tabSelection : "Uploaded Images",
      selectedTransmitID : "",

      PrescListFromPrevious : [],
      SelectedPharmacyID:"",
      isDeletePopup: false,
      SelectedItem:{},
      confirmDelete : false,
      Selectedkey : "",
      is_favList: false,
      fav_DrugList: [],
      drugSearch: '',
      selectedDrug: [],
      sendDrug: [],
      is_MedifavList: false,
      sendMediDrug: [],
      selectedMediDrug: [],

    };
  }

  async componentDidMount() {

    const serviceType = await AsyncStorage.getItem("service_type");

    // let Selected_Clinic_Doctor = await AsyncStorage.getItem("Selected_Clinic_Doctor");
    // let parsed_data = JSON.parse(Selected_Clinic_Doctor) || {};
    // alert(parsed_data)
    this.setState({
      serviceType: serviceType,
      // selectedClinicOrDoctor: parsed_data,
      selectedDoctorId: Object.keys(this.state.selectedClinicOrDoctor).length>0 ?this.state.selectedClinicOrDoctor.id:null
    }, ()=>{
      this.getPatientInfo()
      this.getFavDrugList()
    })

    setInterval(() => {
      this.setState({
        currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
      });
    }, 5000);
  }

  getPatientInfo = () => {

    let { patientAppointment, userType ,selectedIpPatientDetails, selectedDoctorId} = this.state;

    var serviceUrl = "";

    if (Constants.ROLE_FO == userType) {
      if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {
        serviceUrl = Constants.FO_PATIENT_INFO + selectedIpPatientDetails.patient_id + "/?appointment_id=" + selectedIpPatientDetails.appointment_id + "&doctor_id=" + selectedIpPatientDetails.doctor_id;
      } else {
        if(patientAppointment.doctor_id){
        serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=" + patientAppointment.doctor_id;
        }else if(patientAppointment.appointment_id && patientAppointment.appointment_status != "On Queue"){
          serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=Nursing";
        }else if(patientAppointment.appointment_status === "On Queue"){
          serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=";
        }else{
          serviceUrl = Constants.FO_PATIENT_INFO + patientAppointment.patient_id;
          this.setState({isNoAppointment:true});
        }
      }
    } else if (Constants.ROLE_OPTOMETRY == userType) 
    {
      serviceUrl = Constants.DOCTOR_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id + "&doctor_id=" + selectedDoctorId;
    } else {

      serviceUrl = Constants.DOCTOR_PATIENT_INFO + patientAppointment.patient_id + "/?appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
      field["patientInfo"] = response.data;
      this.props.changeStateValues(response.data, this.state.patientAppointment, this.state.userType, this.state.selectedClinicOrDoctor)
      this.setState({ field });

    }
  };

  getComplaintsFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  }

  getFavDrugList = () => {
    var serviceUrl = "";
    let doctor_id = this.state.patientAppointment.doctor_id ? this.state.patientAppointment.doctor_id : this.state.selectedIpPatientDetails.doctor_id ? this.state.selectedIpPatientDetails.doctor_id : this.state.selectedDoctorId;
    serviceUrl = Constants.DOC_FAV_LIST + "?doctor_id=" +  doctor_id
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getFavDrugSuccess,
      this.getFavDrugFailure
    );
  };

  getFavDrugSuccess = response => {
    if (response.status === "success") {
      this.setState({ fav_DrugList: response.data });

    }
  };

  getFavDrugFailure = error => {
    console.log(error);
  }

  changeSelected = (value, isNil ) => {
    var complaint = false;
    var ocular = false;
    var ocularSurgery = false;
    var systemic = false;
    var systemicAllergy = false;
    var visionAssessment = false;
    var Gonioscopy = false
    var autoRefractometry = false;
    var subjectiveRefraction = false;
    var additionalSubjectiveRefraction = false;
    var previousGlassPower = false;
    var glassPrescription = false;
    var iOP = false;
    var medication = false;
    var vitals = false;

    var anteriorSegment = false
    var posteriorSegment = false
    var fundus = false;
    var reviewPlan = false;
    var treatmentAndAdvice = false;
    var diagnosis = false;
    var documents = false;
    var symptoms = false;
    var assessment = false;
    var allergy = false
    var PatientCategories = false

    var test = false
    var history = false
    var hospital = false
    var family = false
    var social = false
    var examination = false
    var prescription = false
    var previous_prescription = false
    var plan = false
    var fields = this.state;
    var selected = "";
    var opthalmologyTest = false;
    var HOPI=false;
    var mentalStatusExamination=false;
    var GeneralPatientAdvice = false;
    var PatientDocuments = false;
    var Couseling = false
    switch (value) {
      case "isComplaint":
        complaint = true;
        selected = "isComplaint";
        break;
      case "isOcular":
        ocular = true;
        selected = "isOcular";
        break;
      case "isOcularSurgery":
        ocularSurgery = true;
        selected = "isOcularSurgery";
        break;
      case "isSystemic":
        systemic = true;
        selected = "isSystemic";
        break;
      case "isSystemicAllergy":
        if(isNil){
          systemicAllergy = false;
        selected = "";
        }else{
          systemicAllergy = true;
        selected = "isSystemicAllergy";
        }
        break;
      case "isVisionAssessment":
        visionAssessment = true;
        selected = "isVisionAssessment";
        break;
      case "isGonioscopy":
        Gonioscopy = true;
        selected = "isGonioscopy";
        break;
      case "isAutoRefractometry":
        autoRefractometry = true;
        selected = "isAutoRefractometry";
        break;
      case "isOpthalmologyTest":
        opthalmologyTest = true;
        selected = "isOpthalmologyTest";
        break;
      case "isSubjectiveRefraction":
        subjectiveRefraction = true;
        selected = "isSubjectiveRefraction";
        break;
      case "isAdditionalSubjectiveRefraction":
        additionalSubjectiveRefraction = true;
        selected = "isAdditionalSubjectiveRefraction";
        break;
      case "isPreviousGlassPower":
        previousGlassPower = true;
        selected = "isPreviousGlassPower";
        break;
      case "isGlassPrescription":
        glassPrescription = true;
        selected = "isGlassPrescription";
        break;
      case "isIOP":
        iOP = true;
        selected = "isIOP";
        break;
      case "isMedication":
        medication = true;
        selected = "isMedication";
        break;


      case "isAnteriorSegment":
        selected = "isAnteriorSegment";
        vitals = true;
        break;
      case "isPosteriorSegment":
        selected = "isPosteriorSegment";
        vitals = true;
        break;

      case "isVitals":
        selected = "isVitals";
        vitals = true;
        break;
      case "isExamination":
        selected = "isExamination";
        examination = true;
        break;

      case "isPrescription":
        selected = "isPrescription";
        prescription = true;
        break;
      case "isPreviousPrescription":
        selected = "isPreviousPrescription";
        previous_prescription = true;
        break;  
      case "isFundus":
        fundus = true;
        selected = "isFundus";
        break;
      case "isReviewPlan":
        reviewPlan = true;
        selected = "isReviewPlan";
        break;
      case "isTreatmentAndAdvice":
        treatmentAndAdvice = true;
        selected = "isTreatmentAndAdvice";
        break;
      case "isCounseling":
        Couseling = true;
        selected = "isCounseling";
        break;
      case "isDiagnosis":
        diagnosis = true;
        selected = "isDiagnosis";
        break;
      case "isDocuments":
        documents = true;
        selected = "isDocuments";
        break;
      case "isSymptoms":
        symptoms = true;
        selected = "isSymptoms";
        break;
      case "isAllergy":
        allergy = true;
        selected = "isAllergy";
        break;
      case "isPatientCategories":
        PatientCategories = true;
        selected = "isPatientCategories";
        break;
      case "isAssessment":
        assessment = true;
        selected = "isAssessment";
        break; 
      case "isTest":
        test = true;
        selected = "isTest";
        break;
      case "isPlan":
        plan = true;
        selected = "isPlan";
        break;
      case "isGeneralPatientAdvice":
        GeneralPatientAdvice=true;
        selected="isGeneralPatientAdvice"
        break;
      case "isHistory":
        history = true;
        selected = "isHistory";
        break;
      case "isHospital":
        hospital = true;
        selected = "isHospital"
        break;
      case "isFamily":
        family = true;
        selected = "isFamily"
        break;
      case "isSocial":
        social = true;
        selected = "isSocial"
        break;

      case "isHOPI":
        selected="isHOPI"
        HOPI=true;
        break;

      case "isMentalStatusExamination":
          selected="isMentalStatusExamination"
          mentalStatusExamination=true;
          break;

      case "isPatientDocuments":
          selected = "isPatientDocuments"
          PatientDocuments = true
          break;
    }

    fields["selectedView"] = selected;
    fields["isComplaint"] = complaint;
    fields["isOpthalmologyTest"] = opthalmologyTest;
    fields["isOcular"] = ocular;
    fields["isOcularSurgery"] = ocularSurgery;
    fields["isSystemic"] = systemic;
    fields["isSystemicAllergy"] = systemicAllergy;
    fields["isVisionAssessment"] = visionAssessment;
    fields["isGonioscopy"] = Gonioscopy;
    fields["isAutoRefractometry"] = autoRefractometry;
    fields["isSubjectiveRefraction"] = subjectiveRefraction;
    fields["isAdditionalSubjectiveRefraction"] = additionalSubjectiveRefraction;
    fields["isPreviousGlassPower"] = previousGlassPower;
    fields["isGlassPrescription"] = glassPrescription;
    fields["isIOP"] = iOP;
    fields["isMedication"] = medication;
    fields["isVitals"] = vitals;
    fields["isAnteriorSegment"] = anteriorSegment;
    fields["isPosteriorSegment"] = posteriorSegment;
    fields["isExamination"] = examination;
    fields["isPrescription"] = prescription;
    fields["isPreviousPrescription"] = previous_prescription;
    fields["isFundus"] = fundus;
    fields["isReviewPlan"] = reviewPlan;
    fields["isTreatmentAndAdvice"] = treatmentAndAdvice;
    fields["isCounseling"] = Couseling;
    fields["isDiagnosis"] = diagnosis;
    fields["isDocuments"] = documents;
    fields["isSymptoms"] = symptoms;
    fields["isAllergy"] = allergy;
    fields["isPatientCategories"] = PatientCategories;
    fields["isAssessment"] = assessment;
    fields["isTest"] = test;
    fields["isPlan"] = plan;
    fields["isHistory"] = history;
    fields["isHospital"] = hospital;
    fields["isFamily"] = family;
    fields["isSocial"] = social;
    fields["isHOPI"]=HOPI;
    fields["isMentalStatusExamination"]=mentalStatusExamination;
    fields["isGeneralPatientAdvice"]=GeneralPatientAdvice;
    fields["isPatientDocuments"] = PatientDocuments;

    var { editItem } = this.state;
    editItem["item"] = null;
    editItem["title"] = ""
    this.setState({ fields, editItem });

    if (!this.state.patientAppointment.is_locked)
      this.scrollView.scrollTo({ x: 0, y: 0, animated: true });
  };  

  renderHeaderView = () => {
    let { patientInfo, patientAppointment } = this.state;
    return (
      <View
        style={{
          // padding: 20,
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          // alignItems: "center"
          borderBottomColor: "#E4E8F1",
          borderBottomWidth: 1,
          paddingLeft: 10, paddingRight: 10,
          paddingBottom: 10,
          paddingTop: 10
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ alignSelf: "center" }}>
            <Arrow
              name="arrow-left"
              size={24}
              color={color.black}
              onPress={() => {
                this.props.changeScreen("home", {}, "", this.state.selectedClinicOrDoctor)
                // this.props.navigation.goBack()
              }}
            />
          </View>
          {/* <TouchableOpacity style={{alignSelf:"center"}} onPress={()=>{this.props.navigation.goBack()}}>
            <Image source={require('../../../../assets/images/backIcon.png')} style={{height:30,width:25}}/>
          </TouchableOpacity> */}
          <View style={{ marginLeft: 20, flexDirection: 'row' }}>

            <Text style={{ fontSize: 16, alignSelf: "center" }}>{patientInfo.name || ""}</Text>
            {/* <View style={{marginLeft:10}}> */}
            <Text
              style={{
                fontSize: 10,
                color: "#ADB4C1",
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 10,
                alignSelf: "center"
              }}
            >
              {patientInfo.age || ""} {patientInfo.age ? "|" : null} {patientInfo.dob || ""} {patientInfo.dob ? "|" : null}
              {patientInfo.mobile_number || ""} {patientInfo.mobile_number ? "|" : null} {patientInfo.name ? patientAppointment.appointment_start_time || "" : null}
            </Text>
            <Text style={{ fontSize: 10, color: "#ADB4C1", marginLeft: 10, alignSelf: "center" }}>
              {patientInfo.last_visit_date ? "Last Visit Date :" : null} {patientInfo.last_visit_date || ""}
            </Text>
            {/* </View> */}
            {/* <Text
              style={{
                fontSize: 10,
                color: "#ADB4C1",
                marginTop: 5,
                marginBottom: 5
              }}
            >
              {patientInfo.age || ""} | {patientInfo.dob || ""} | {patientInfo.mobile_number || ""} | {patientAppointment.appointment_start_time || ""}
            </Text>
            <Text style={{ fontSize: 10, color: "#ADB4C1" }}>
              Last Visit Date : {patientInfo.last_visit_date || ""}
            </Text> */}
          </View>
        </View>


        {
          this.state.userType == Constants.ROLE_FO ?
            null :
            <View>
              {/* <TouchableOpacity disabled={this.state.isLocked} onPress={() => {
                this.visitReport()
              }} style={{flexDirection:'row', marginLeft: 20, paddingBottom: 10, paddingTop: 10, width: 130, justifyContent:'space-around', alignContent: 'space-between', borderRadius: 8, backgroundColor: color.themeDark, }}>
                 <Icon name={"eye"} size={platform === "web" ? 15 : 20} color={color.white} />
                <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"View Report"}</Text>
              </TouchableOpacity> */}


              <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.visitReport() }}
                style={[Style.doctorNotesFooterButton, { flexDirection: 'row', width: 130, justifyContent: 'space-around', alignContent: 'space-between', }]} >
                <Icon name={"eye"} size={platform === "web" ? 15 : 20} color={color.white} />
                <Text style={{ color: color.white }}>{"View Report"}</Text>
              </TouchableOpacity>
            </View>
        }



        {/* <TouchableOpacity
          onPress={() => {
            this.visitReport()
          }}
          style={{
            height: 30,
            width: 103,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            flexDirection: "row",
            backgroundColor: color.themeDark,
            justifyContent: "space-evenly",
            borderWidth: 0,
            borderColor: color.white
          }}
        >
          <Icon name={"eye"} size={platform === "web" ? 15 : 20} color={color.white} />
          <Text
            style={{
              fontSize: platform === "web" ? 10 : 13,
              color: color.white,
              fontWeight: "700"
            }}>{"View Report"}</Text>
        </TouchableOpacity> */}
        {/* {this.renderIconBtn("eye", "View Report", false)} */}
      </View>
    );
  };


  //   visitReport(){
  //     var service_url = Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT+"?appointment_id="+this.state.patientAppointment.appointment_id +"&patient_id="+this.state.patientAppointment.patient_id;
  //     // if(searchKey){
  //       Service.getInstance().getPrint(
  //         service_url,
  //         this,
  //         this.visitReportSuccess,
  //         this.visitReportFailure
  //       );
  //     // }
  // }

  // visitReportSuccess = success =>{
  //     if(success.status == "success"){
  //         console.log("success ---------> "+JSON.stringify(success))
  //         // this.setState({autosuggestionValues : success.data})
  //               //Create a Blob from the PDF Stream
  //       const file = new Blob(
  //         [success.data],
  //         { type: 'application/pdf' });
  //       //Build a URL from the file
  //       const fileURL = URL.createObjectURL(file);
  //       //Open the URL on new Window
  //       console.log(file)
  //       window.open(fileURL);
  //     }
  // }
  // visitReportFailure = error =>{
  //     console.log("error ---------> "+JSON.stringify(error))
  // }


  visitReport = () => {

    let states = this.state;

    var serviceUrl = this.props.userType == 'OPT' ?
      Constants.OPTHALMOLOGY_DOCTOR_NOTES_VISIT_REPORT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id :
      Constants.GP_NOTES_VISIT_REPORT + "?appointment_id=" + this.state.patientAppointment.appointment_id + "&patient_id=" + this.state.patientAppointment.patient_id

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.getPrintSuccess,
      this.getPrintFailure,
      "pdf"
    );
  };

  getPrintSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
     // console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
    //console.log("Get clinic list error response");
    console.log(error);
  };

  refreshData = (title) => {
   // console.log("_____________________________> " + title)
    this.setState({
      reloadData: title
    })
  }

  reloadTemplate = (title) => {

    this.setState({
      reloadTemplate: title
    })
  }

  clearReloadTemplate = () => {

    this.setState({ reloadTemplate: null })
  }

  editItem = (title, item, rightView = '', comments = "", isDisable = false,subjectiveRefractionUserData) => {

    let states = this.state;
    // console.log(JSON.stringify(item))
    // console.log(JSON.stringify(title))



    if (comments) {

      states["commonComments"] = comments
      // this.setState({
      //   commonComments:comments
      // })
    }
    let editItem = {
      title: title,
      item: item,
      comments: comments,
      isDisable: isDisable,
      subjectiveRefractionUserData:subjectiveRefractionUserData
    }

    states['editItem'] = editItem;
    // alert(JSON.stringify(editItem) )
    if (rightView) { states['selectedView'] = rightView; }

   // console.log(" edit @ doctor notes ")

   

    this.setState({ states,editItem:editItem ,
    
      
    })
  }

  addPrescriptionItem = (data) => {

   // console.log("addPrescriptionItem --- " + JSON.stringify(data))
    this.setState({ prescriptionItem: data })
  }

  clearPrescriptionState = () => {

    this.setState({ prescriptionItem: null })
  }

  showResposeValue(type, meaasge) {
    this.setState({
      responseType: type,
      responseMeaasge: meaasge
    }, () => {
      setTimeout(() => {
        this.setState({ responseType: "", responseMeaasge: "" });
      }, 3000);
    })
  }


  successAlert() {
    return (
      <View style={styles.SucessAlertContainer}>
        <View style={styles.SucessAlertView}>
          <Success size={'1.5vw'} name={"check"} color={"green"} />
          <Text style={styles.SucessMsgText}>{"SUCCESS: "}
            <Text style={styles.SucessMsgContentText}>{this.state.responseMeaasge}</Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.SucessMsgTouch}>
          <Icon size={'1.5vw'} name={"close"} color={"green"} />
        </TouchableOpacity>
      </View>
    );
  }

  errorAlert() {

    let errorList = this.state.responseMeaasge && this.state.responseMeaasge.length > 0 && (this.state.responseMeaasge).split(",")
    return (
      <View style={styles.ErrorAlertContainerView}>
        <View style={styles.ErrorAlertView}>
          <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: 10 }}>
            <Icon size={'1.5vw'} name={"ban"} color={"#FFF"} /></View>
          <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
            <View>
              <Text style={styles.ErrorMsgText}>{"ERROR: "}
                {errorList && errorList.map((item, index) => {
                  return <View key={index}><Text style={styles.ErrorMsgContentText}>{item}</Text></View>
                })}
              </Text>
            </View>

          </View>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={styles.ErrorMsgTouch}>
          <Icon size={'1.5vw'} name={"close"} color={"#fff"} />
        </TouchableOpacity>
      </View>
    );
  }

  renderDeletePopup = (item, key) => {
    this.setState({
      isDeletePopup : true,
      SelectedItem : item,
      Selectedkey : key
    })
  }

  renderClosePopup = () => {
    this.setState({
      confirmDelete : false,
      isDeletePopup : false
    })
  }

  DeletePopup = () => {
    return(
      <View style={{ height: "100%", zIndex: 1, position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
            <View style={{ paddingHorizontal: '1.95vw', paddingVertical: '1.3vw', backgroundColor: color.white, width: "25vw", height: '9.76vw', borderRadius: '0.52vw', alignItems: "center", borderWidth: 1, }}>
                <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '3.25vw', textAlign: 'center' }}>{this.state.Selectedkey === "SymptomsNew" ? "Are you sure to delete Symptom's Name - " : this.state.Selectedkey === "PatientCategoryNew" ? "Are you sure to delete Category - " :"Are you sure to delete Allergy Name - "}<Text style={{ color: 'red' }}>{` ${(this.state.Selectedkey === "SymptomsNew" || this.state.Selectedkey === "PatientCategoryNew") ? this.state.SelectedItem : this.state.SelectedItem.value} `}</Text>?</Text>
                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                        onPress={() => {this.setState({confirmDelete : true})}}
                    >
                        <Text style={{ color: 'white', fontSize: '0.78vw', }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                        onPress={() => {
                            this.setState({
                              confirmDelete : false
                            }, () => {
                              this.setState({
                                isDeletePopup: false,
                                SelectedItem: {}
                              })
                            })
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: '0.78vw', }}>{"No"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
  }

  getAssessmentData(data) {
    this.setState({
      getAssessmentData: data
    })
  }

  getGPAdviceData =(data)=>{
    this.setState({
      getGPAdviceData:data,
    })
  }

  examinationFavouriteid(data) {
    this.setState({
      ExaminationID: data
    })
  }
  selectedTransmittedImage = (id) => {
    this.setState({
      selectedTransmitID : id
    })
  }

  PrescriptionPharmaChange = (PharmaId) =>{
    this.setState({
      SelectedPharmacyID : PharmaId
    })
  }
  renderComponents() {
    // console.log(this.state.selectedView)

    var componentToRender = this.state.selectedView;
    var componentGroup = {
     
      isGeneralPatientAdvice: (<GeneralPatientAdviceNew isLocked={this.state.patientAppointment.is_locked}patientAppointment={this.state.patientAppointment} getGPAdviceData={this.getGPAdviceData.bind(this)}
        //  getAssessmentData={this.getAssessmentData.bind(this)} 
        //  refreshData={this.refreshData.bind(this)}
        // clearEditedItem={this.editItem.bind(this)}
        // editItem={this.state.editItem} 
        showResposeValue={this.showResposeValue.bind(this)}
        // clearEditedItem={this.editItem.bind(this)} 
        />),

      isAssessment: (<AssessmentNew isLocked={this.state.patientAppointment.is_locked} getAssessmentData={this.getAssessmentData.bind(this)} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} clearEditedItem={this.editItem.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} />),
      isComplaint: (<ComplaintsNewItem isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isOcular: (<OscularHistoryNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isOcularSurgery: (<OcularSurgeryNew 
        selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
        userType={this.state.userType}
        isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isSystemic: (<SystemicIllnessNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isSystemicAllergy: (<SystemicAllergyNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isVisionAssessment: (<VisionAssessmentNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isGonioscopy: (<GonioscopyNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isAutoRefractometry: (<AutoRefractometryNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isSubjectiveRefraction: (<SubjectiveRefractionNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isAdditionalSubjectiveRefraction: (<AdditionalSubjectiveRefractionNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isPreviousGlassPower: (<PreviousGlassPowerNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes}  patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isGlassPrescription: (<GlassPrescriptionNew  isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} continueWithSameGlass={this.state.continueWithSameGlass} />),
      isVitals: (<VitalsNew userType={this.state.userType} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} isNoAppointment ={this.state.isNoAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} vitalsData={this.vitalsData.bind(this)} editedvitals={this.state.editedvitals} vitalsflag={this.state.vitalsflag} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} isIpPatientCheckouted = {this.state.isIpPatientCheckouted}/>),
      isIOP: (<IOPNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} clearEditedItem={this.editItem.bind(this)} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} commonComments={this.state.commonComments} />),
      isMedication: (<MedicationNew isLocked={this.state.patientAppointment.is_locked} prescriptionIsTransmitted={this.state.prescriptionIsTransmitted} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} addLineItem={this.addPrescriptionItem.bind(this)} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor} />),
      isReviewPlan: (<ReviewPlanNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)}  userType={this.state.userType}/>),
      isTreatmentAndAdvice: (<TreatmentAdviceNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isDocuments: (<DocumentNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} tabSelection = {this.state.tabSelection} selectedTransmitID = {this.state.selectedTransmitID}     showDocumentImage={this.showImage.bind(this)}
      />),
      isFundus: (<FundusNew fundusEyeAllDetails={this.state.fundusEyeDetails} fundusEyeDetails={this.fundusEyeDetails.bind(this)} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isOpthalmologyTest: (<OpthalmologyTestNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} selectedClinicOrDoctor={this.state.selectedClinicOrDoctor }/>),
      isSymptoms: (<SymptomsNew isLocked={this.state.patientAppointment.is_locked} userType={this.state.userType} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} DeletePopup={this.renderDeletePopup.bind(this)} Deletecorporate={this.state.confirmDelete} ClosePopup={this.renderClosePopup.bind(this)} />),
      isAllergy: (<AllergyNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} DeletePopup={this.renderDeletePopup.bind(this)} Deletecorporate={this.state.confirmDelete} ClosePopup={this.renderClosePopup.bind(this)} />),
      isPatientCategories: (<PatientCategoriesNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} DeletePopup={this.renderDeletePopup.bind(this)} Deletecorporate={this.state.confirmDelete} ClosePopup={this.renderClosePopup.bind(this)} />),
      isExamination: (<ExaminationNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} 
        reloadTemplate={this.state.reloadTemplate}
        clearReloadTemplate={this.clearReloadTemplate.bind(this)}
        examinationFavouriteid={this.examinationFavouriteid.bind(this)}
        reloadFavouriteID={this.reloadFavouriteID.bind(this)}/>),

      isMentalStatusExamination:(<MentalStatusExaminationNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)}
        reloadTemplate={this.state.reloadTemplate}
        clearReloadTemplate={this.clearReloadTemplate.bind(this)}
        examinationFavouriteid={this.examinationFavouriteid.bind(this)}
        reloadFavouriteID={this.reloadFavouriteID.bind(this)}
      />),
      // isPrescription: (<PrescriptionNew isLocked = {this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),

      isAssessment: (<AssessmentNew userType={this.state.userType} isLocked={this.state.patientAppointment.is_locked} getAssessmentData={this.getAssessmentData.bind(this)} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} clearEditedItem={this.editItem.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} />),
      isGeneralPatientAdvice: (<GeneralPatientAdviceNew showResposeValue={this.showResposeValue.bind(this)} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} getGPAdviceData={this.getGPAdviceData.bind(this)}/>),
      isComplaint: (<ComplaintsNewItem isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isOcular: (<OscularHistoryNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isOcularSurgery: (<OcularSurgeryNew 
        selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
        userType={this.state.userType}
        isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isSystemic: (<SystemicIllnessNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isSystemicAllergy: (<SystemicAllergyNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isVisionAssessment: (<VisionAssessmentNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isGonioscopy: (<GonioscopyNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isAutoRefractometry: (<AutoRefractometryNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      // isSubjectiveRefraction: (<SubjectiveRefractionNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isPreviousGlassPower: (<PreviousGlassPowerNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isGlassPrescription: (<GlassPrescriptionNew continueWithSameGlass={this.state.continueWithSameGlass} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isVitals: (<VitalsNew userType={this.state.userType} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} isNoAppointment ={this.state.isNoAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} vitalsData={this.vitalsData.bind(this)} editedvitals={this.state.editedvitals} vitalsflag={this.state.vitalsflag} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)}
        selectedIpPatientDetails={this.state.selectedIpPatientDetails}
        isCurrentPageIsIP={this.state.isCurrentPageIsIP}
        isIpPatientCheckouted = {this.state.isIpPatientCheckouted} />),
      isIOP: (<IOPNew isLocked={this.state.patientAppointment.is_locked} getAvailableEyeTypesId={this.state.getAvailableEyeTypesId} getAvailableEyeTypes={this.state.getAvailableEyeTypes} patientAppointment={this.state.patientAppointment} clearEditedItem={this.editItem.bind(this)} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} commonComments={this.state.commonComments} />),
      isMedication: (<MedicationNew isLocked={this.state.patientAppointment.is_locked} prescriptionIsTransmitted={this.state.prescriptionIsTransmitted} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} addLineItem={this.addPrescriptionItem.bind(this)} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor} />),
      isReviewPlan: (<ReviewPlanNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} userType={this.state.userType}/>),
      isTreatmentAndAdvice: (<TreatmentAdviceNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} clearEditedItem={this.editItem.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
      isDocuments: (<DocumentNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} tabSelection = {this.state.tabSelection} selectedTransmitID = {this.state.selectedTransmitID}     showDocumentImage={this.showImage.bind(this)}
      />),
      isFundus: (<FundusNew fundusEyeAllDetails={this.state.fundusEyeDetails} fundusEyeDetails={this.fundusEyeDetails.bind(this)} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isOpthalmologyTest: (<OpthalmologyTestNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} selectedClinicOrDoctor={this.state.selectedClinicOrDoctor} />),
      isSymptoms: (<SymptomsNew isLocked={this.state.patientAppointment.is_locked} userType={this.state.userType} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} DeletePopup={this.renderDeletePopup.bind(this)} Deletecorporate={this.state.confirmDelete} ClosePopup={this.renderClosePopup.bind(this)}/>),
      isAllergy: (<AllergyNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} DeletePopup={this.renderDeletePopup.bind(this)} Deletecorporate={this.state.confirmDelete} ClosePopup={this.renderClosePopup.bind(this)}/>),
      isCounseling: (<CounselingNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} selectedClinic={this.state.selectedClinicOrDoctor} clearEditedItem={this.editItem.bind(this)} refreshData={this.refreshData.bind(this)} showResposeValue={this.showResposeValue.bind(this)}/>),


      isPrescription: (<PrescriptionNew isLocked={this.state.patientAppointment.is_locked} clearSelectedPrescription={this.showSelectedPrescription.bind(this)} editPrescriptionData={this.state.editPrescriptionData} showNewPrescription={this.state.showNewPrescription} addNewPrescription={this.addNewPrescription.bind(this)} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} selectedClinicOrDoctor = {this.state.selectedClinicOrDoctor} PharmacySelection={this.PrescriptionPharmaChange.bind(this)}/>),
      isTest: (<TestNew isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}  />),
      isPlan: (<PlanNew userType={this.state.userType} isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),


      isHistory: (<MedicalHistory isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isHospital: (<Hospitalization isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isFamily: (<FamilyHistory isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      isSocial: (<SocialHistory isLocked={this.state.patientAppointment.is_locked} patientAppointment={this.state.patientAppointment} refreshData={this.refreshData.bind(this)} editItem={this.state.editItem} showResposeValue={this.showResposeValue.bind(this)} clearEditedItem={this.editItem.bind(this)} />),
      // isAnteriorSegment
      // isPosteriorSegment
      isPatientDocuments: (<PatientDocuments patientAppointment={this.state.patientAppointment} showResposeValue={this.showResposeValue.bind(this)}></PatientDocuments>),
    }

    return (<View>{componentGroup[componentToRender]}</View>)
  }

  _onContentSizeChange() {
    let initialYScroll = this.state.scrollPosition;
    this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
  };

  reloadFavouriteID = (title) => {

    this.setState({
      reloadFavouriteID: title
    })
  }

  clearreloadFavouriteID = () => {

    this.setState({ reloadFavouriteID: null })
  }

  scrollHandler(){
    var{dataSourceCords,scrollToIndex}=this.state
    if(dataSourceCords.length>=scrollToIndex){
        this.state.setScrollRef.scrollTo({
         x: 0,
         y: dataSourceCords[scrollToIndex - 1],
         animated: true,
        })
    }
    else {
     console.log('Out of Max Index');
    } 
 }
  changeTabSection(selectedTab,index, eyeTypes=[],eyeTypeId=[]) {
    console.log(index)
    this.setState({
      selectedView: selectedTab,
      scrollToIndex:parseInt(index+1)
    }
    ,()=>{
       this.scrollHandler()
       this.setState({
        getAvailableEyeTypes: eyeTypes,
        getAvailableEyeTypesId: eyeTypeId
       })
    })
  }
  renderParentComponent(item,key){
    return (
      <View
        key={key}
        style={Style.doctorNotesSectionView}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout
          this.state.dataSourceCords[key] = layout.y
          this.setState({
            setDataSourceCords: this.state.dataSourceCords
          })
        }}
      >
        {item}
      </View>
    )
  }
  renderTransmit=(flag)=>{
     this.setState({
      isTransmit:flag
     })
  }
  favouriteModelPopup= (flag) => {
    if(flag){
     this.getFavDrugList() 
    }
    this.setState({
      is_favList: flag
    })
  }

  favouriteMediPopup= (flag) => {
    if(flag){
     this.getFavDrugList() 
    }
    this.setState({
      is_MedifavList: flag
    })
  }

  BackToNotes = ()=>{
    this.setState({
      selectedView : "isSymptoms"
    })
  }
  render() {
    var components = []
    if(this.state.userType === 'OPT'){
      components=this.optComponentsView()
    }else if(this.state.userType === "DOCTOR"||this.state.userType === "DB"||this.state.userType === "PD"||this.state.userType === "OBGYN"){
      components=this.commonComponents()
    }else if(this.state.userType === "PSY"){
      components=this.psyComponents()
    }else if(this.state.userType === "FO"){
      components=this.foComponents()
    }else if(this.state.userType === "OPTOMETRY"){
      components=this.optometryComponents()
    }

    let Drugs = this.state.fav_DrugList.length > 0 ? 
    this.state.fav_DrugList.filter(item => { return (item.brand_name === null ? null : item.brand_name.toLowerCase().includes(this.state.drugSearch.toLowerCase())) }) : []

    //alert(JSON.stringify(this.state.selectedClinicOrDoctor))
    // console.log("check lock -------------------> "+JSON.stringify(this.state.patientAppointment))
    return (
      
        <View>
      <TouchableWithoutFeedback onPress={()=>{
        this.changePickerDropDownValue(false)
      }}>
      <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
        {/* side menu */}
        {/* <View style={[Style.leftBarView]}>
          <SideMenu navigation={this.props.navigation} billingScreen={true} patientAppointment={this.state.patientAppointment} patientInfo={this.state.patientInfo} doctorNotesPage={true} pageName={this.state.patientAppointment.patient_id ? "patientPage" : ""} />
        </View> */}
        {/* first view */}
        {/* <View style={Style.leftBarView} /> */}

        {
          this.state.responseType == "success" ?
            this.successAlert() :
            this.state.responseType == "error" ?
              this.errorAlert() : null
        }


        {/* {this.renderHeaderView()} */}
        {/* <View
              style={ Style.contentPageLine }
            /> */}
            <View style={{ width: "10%", height: "44vw", backgroundColor: color.white }}>
            <DocNotesVerticalNavigation
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={this.state.userType == Constants.ROLE_FO ? foHeaderList : this.state.userType == Constants.ROLE_OPT?optNavigationHeaderList:this.state.userType == Constants.ROLE_OPTOMETRY ?optimetryNavHeaderList:this.props.userType==="PSY"?psychiatryDoctorNotes:navigationHeaderList}
            selectedTab={this.state.selectedView} />
            </View>
        <View style={{ width: "55%", height: screenHeight - 65, }}>
          <ScrollView
            ref={(ref)=>{
              this.state.setScrollRef=ref
            }}
            style={{ width: "100%", height: screenHeight - 65, }}

            bounces={false}
            bouncesZoom={false}
          >
            <View style={[Style.leftView, { width: "100%", backgroundColor: color.applicationBackgroundColor }]}>
            
              <View style={{ marginTop: platform === "web" ? 0 : '1.2vw', width: "100%", flex: 1 }}  >

                {
                  components && components.length > 0 ?
                  components.map((item,index)=>{
                    return(
                      <View style={Style.doctorNotesSectionView} key={index}
                      onLayout={(event) => {
                        const layout = event.nativeEvent.layout 
                        this.state.dataSourceCords[index] = layout.y
                        this.setState({
                          setDataSourceCords: this.state.dataSourceCords
                        })
                      }}
                      >
                        {item}
                        </View>
                    )
                  })
                  : null
                }
                {/* {
                  components && components.length>0?
                  components.map((item,index)=>{
                    return this.renderParentComponent(item,index)
                  }):null
                
                } */}
                
                
                {this.renderFooterButtons()}
              </View>
            </View>
          </ScrollView>

        </View>

        {/* second view */}


        {/* {
            !this.props.patientAppointment.is_locked ?  */}

        <ScrollView
          ref={scrollView => this.scrollView = scrollView}
          // onContentSizeChange={() => {
          //   this.state.selectedView!=="isAssessment"? this._onContentSizeChange():"";
          // }}

          showsVerticalScrollIndicator={false}
          style={{ width: "35%", height: screenHeight - 65, backgroundColor:color.themeShade}}
        // contentContainerStyle={{ flex: 1 }}
        >
          <View style={[Style.rightView, { padding: '1.2vw', width: "100%" }]}>

            {this.renderComponents()}
            {/* 
          <DoctorNotesNewItem patientAppointment={this.state.patientAppointment}
           userType={this.state.userType} 
            refreshData={this.refreshData.bind(this)} 
            editItem={this.state.editItem} 
            selectedView={this.state.selectedView} 
            addPrescriptionItem={this.addPrescriptionItem.bind(this)}
            selectedVitals={this.selectedVitals.bind(this)} 
            vitalsData ={this.vitalsData.bind(this)}
            prescriptionList = {this.state.prescriptionList}
            showNewPrescription = {this.state.showNewPrescription}
            editPrescriptionData = {this.state.editPrescriptionData}
            addNewPrescription = {this.addNewPrescription.bind(this)}
            editedvitals={this.state.editedvitals}
            vitalsflag={this.state.vitalsflag}
          /> */}

          </View>
        </ScrollView>

        {/* // : null
        // } */}
        {/* </View> */}
        {
          this.state.glassPrescriptionModalFlag ?
            <GlassPrescriptionModal
              changeIndex={this.state.changeIndex}
              showResposeValue={this.showResposeValue.bind(this)}
              patientAppointment={this.state.patientAppointment}
              glassPrescriptionModalClose={this.glassPrescriptionModalClose.bind(this)}
              selectedGlassprescriptionData={this.state.selectedGlassprescriptionData}
              refreshData={this.refreshData.bind(this)}
            />
            :
            null
        }

        {
          this.state.showDocumentImageFlag ?
            <ShowDocumentImage showDocumentImage={this.showImage.bind(this)} selectedImage={this.state.showDocumentImage} /> : null
        }
{this.state.isDeletePopup ?
        this.DeletePopup()
  : null }
        {/* {
          this.state.showUploadeTestReportsModal ? 
          <UploadeTestReports/>
          : null
        } */}
        {
              this.state.is_favList ?
                <View style={{ position: 'absolute' }}>
                  <View style={styles.FavCon}
                  >
                    <TouchableWithoutFeedback>
                      <View
                        style={styles.FavConHead}
                      >
                        <View style={{ flex: 1 }}>
                          <View
                            style={{ flexDirection: "row", justifyContent: "space-between", border: '' }}
                          >
                            <Text style={{ color: "black", fontSize: "1.16vw" }}>
                              Select From Favourites
                            </Text>
                            <TextInput
                              placeholder={"Search Durg"}
                              style={[{
                                backgroundColor: "white",
                                marginRight: '1.2vw', fontSize: '0.9vw',
                                borderColor: color.rightPanelInputBorder, borderWidth: 1, width: '40%', height: '2vw'
                              }, Style.allButtonBorderRadius]}
                              placeholderTextColor={color.textBoxBorderColor}
                              autoCapitalize="none"
                            onChangeText={(text) => {
                              this.setState({
                                drugSearch: text
                              })
                            }}
                            />
                            <TouchableOpacity
                              onPress={() => {
                                this.setState({
                                  is_favList: false,
                                  drugSearch: '',
                                  sendDrug: [],
                                  selectedDrug:[]
                                })
                              }}
                              style={{ fontSize: "1.95vw" }}
                            >
                              <Icon
                                name="window-close-o"
                                size={"1.10vw"}
                                color={color.themeDark}
                              />
                            </TouchableOpacity>
                          </View>
                          <hr style={{color: '#888888', width: '100%'}}/>
                          <View style={{ height: "40vh", overflow: 'scroll', }}>
                            <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                              {Drugs.length > 0 ? Drugs.map((item, index) => {
                                return(
                                <Checkbox
                                style={{marginLeft: '0.5vw', padding: '0.5vw'}}
                                // checked={index}
                                onChange={(event) => {this.selectDrug(event, Drugs, index)}}
                              >
                                {item.brand_name + " - " + item.generic_name}
                             </Checkbox>
                             )
                               }) : 
                               <Text style={{display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%'}}>{'No Drugs Found'}</Text>}
                            </View>
                          </View>
                          <View style={styles.buttonContainer}>
                            <TouchableOpacity
                            disabled={this.state.selectedDrug.length > 0 ? false : true}
                              style={[styles.confirmButtonStyle, {
                                backgroundColor: this.state.selectedDrug.length > 0 ?  color.themeDark : "#D1D3D5"
                              }]}
                              onPress={() => {this.setState({
                                sendDrug: this.state.selectedDrug,
                                selectedDrug: [],
                                is_favList: false
                              }) }}
                            >
                              <Text style={styles.buttonTextStyle}>
                                Confirm
                              </Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                              style={styles.closeButtonStyle}
                              onPress={() => {
                                this.setState({
                                  is_favList: false,
                                  drugSearch: '',
                                  sendDrug: [],
                                  selectedDrug:[]
                                })
                              }}
                            >
                              <Text style={[styles.buttonTextStyle, { color: null }]}>Close</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </View>
                : null}

            {
              this.state.is_MedifavList ?
                <View style={{ position: 'absolute' }}>
                  <View style={styles.FavCon}
                  >
                    <TouchableWithoutFeedback>
                      <View
                        style={styles.FavConHead}
                      >
                        <View style={{ flex: 1 }}>
                          <View
                            style={{ flexDirection: "row", justifyContent: "space-between", border: '' }}
                          >
                            <Text style={{ color: "black", fontSize: "1.16vw" }}>
                              Select From Favourites
                            </Text>
                            <TextInput
                              placeholder={"Search Durg"}
                              style={[{
                                backgroundColor: "white",
                                marginRight: '1.2vw', fontSize: '0.9vw',
                                borderColor: color.rightPanelInputBorder, borderWidth: 1, width: '40%', height: '2vw'
                              }, Style.allButtonBorderRadius]}
                              placeholderTextColor={color.textBoxBorderColor}
                              autoCapitalize="none"
                              onChangeText={(text) => {
                                this.setState({
                                  drugSearch: text
                                })
                              }}
                            />
                            <TouchableOpacity
                              onPress={() => {
                                this.setState({
                                  is_MedifavList: false,
                                  drugSearch: '',
                                  sendMediDrug: [],
                                  selectedMediDrug:[]

                                })
                              }}
                              style={{ fontSize: "1.95vw" }}
                            >
                              <Icon
                                name="window-close-o"
                                size={"1.10vw"}
                                color={color.themeDark}
                              />
                            </TouchableOpacity>
                          </View>
                          <hr style={{ color: '#888888', width: '100%' }} />
                          <View style={{ height: "40vh", overflow: 'scroll', }}>
                            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                              {Drugs.length > 0 ? Drugs.map((item, index) => {
                                return (
                                  <Checkbox
                                    style={{ marginLeft: '0.5vw', padding: '0.5vw' }}
                                    // checked={index}
                                    onChange={(event) => { this.selectMedication(event, Drugs, index)}}
                                  >
                                    {item.brand_name + " - " + item.generic_name}
                                  </Checkbox>
                                )
                              }) :
                                <Text style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%' }}>{'No Drugs Found'}</Text>}
                            </View>
                          </View>
                          <View style={styles.buttonContainer}>
                            <TouchableOpacity
                              disabled={this.state.selectedMediDrug.length > 0 ? false : true}
                              style={[styles.confirmButtonStyle, {
                                backgroundColor: this.state.selectedMediDrug.length > 0 ? color.themeDark : '#D1D3D5'
                              }]}
                              onPress={() => {
                                this.setState({
                                  sendMediDrug: this.state.selectedMediDrug,
                                  selectedMediDrug: [],
                                  is_MedifavList: false
                                })
                              }}
                            >
                              <Text style={styles.buttonTextStyle}>
                                Confirm
                              </Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                              style={styles.closeButtonStyle}
                              onPress={() => {
                                this.setState({
                                  is_MedifavList: false,
                                  drugSearch: '',
                                  sendMediDrug: [],
                                  selectedMediDrug: [],
                                })
                              }}
                            >
                              <Text style={[styles.buttonTextStyle, { color: null }]}>Close</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </View>
                : null}
      </View>
      </TouchableWithoutFeedback>
      </View>
      
    );
  }

  selectDrug = (event, Drugs, index) => {
    var { selectedDrug } = this.state
    let idToRemove = Drugs[index].drug_id
    let updatedArray = [];

    if (event.target.checked) {
      updatedArray = [...selectedDrug, Drugs[index]];
    } else {
      updatedArray = selectedDrug.filter((item) => item.drug_id !== idToRemove);
    }
    this.setState({
      selectedDrug: updatedArray
    })
  }

  selectMedication = (event, Drugs, index) => {
    var { selectedMediDrug } = this.state
    let idToRemove = Drugs[index].drug_id
    let updatedArray = [];
    if (event.target.checked) {
      updatedArray = [...selectedMediDrug, Drugs[index]];
    } else {
      updatedArray = selectedMediDrug.filter((item) => item.drug_id !== idToRemove);
    }
    this.setState({
      selectedMediDrug: updatedArray
    })
  }

  CloseWaitingCommentsModel = () => {
    this.props.WaitingCommentsVisible(false)
  };

  fundusEyeDetails(fundusEyeDetails){
    // alert(JSON.stringify(fundusEyeDetails))
    this.setState({
      fundusEyeDetails:fundusEyeDetails
    })
  }

  updateContinueWithSameGlassFlag(flag){
    this.setState({
      continueWithSameGlass:flag
    })
  }
  transmittedImage(selectedTab){
     this.setState({
       tabSelection : selectedTab
     })
  }
  
  complaints(){
    return(
      <Complaints patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      userType={this.state.userType}
    />
    )
  }
  oscularHistory(){
    return(
      <OscularHistory patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      userType={this.state.userType}
    />
    )
  }
  systemicIllness(){
    return(
      <SystemicIllness patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      userType={this.state.userType}
    />
    )
  }
  vitals(){
    return(
      <Vitals patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedVitals={this.state.selectedVitals}
      vitalsData={this.state.vitalsData}
      editvitals={this.editvitals.bind(this)}
      userType={this.state.userType}
      selectedIpPatientDetails={this.state.selectedIpPatientDetails}
      isCurrentPageIsIP={this.state.isCurrentPageIsIP}
    />
    )
  }
  visionAssessment(){
    return(
      <VisionAssessment patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)} 
      showResposeValue={this.showResposeValue.bind(this)} 
    />
    )
  }
  Gonioscopy() {
    return (
      <Gonioscopy patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
        showResposeValue={this.showResposeValue.bind(this)}
        getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)} />
    )
  }
  autoRefractometry(){
    return (
      <AutoRefractometry patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
        getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)}
      />
    )
  }
  previousGlassPower(){
    return (
      <PreviousGlassPower patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
        getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)}
      />
    )
  }
  subjectiveRefraction(){
    return(
      <SubjectiveRefraction patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)} 
      showResposeValue={this.showResposeValue.bind(this)}
    />
    )
  }
  additionalSubjectiveRefraction(){
    return(
      <AdditionalSubjectiveRefraction patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)} 
      showResposeValue={this.showResposeValue.bind(this)}
    />
    )
  }
  iop(){
    return(

      <IOP patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      getAvailableEyeTypes={this.getAvailableEyeTypes.bind(this)}
    />
    )
  }
  anteriorSegment() {
    return (
      <AnteriorSegment patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        showResposeValue={this.showResposeValue.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
      />

    )
  }
  posteriorSegment() {
    return (
      <PosteriorSegment patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        showResposeValue={this.showResposeValue.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
      />
    )
  }
  funds(){
    return(
      <Fundus patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}
      fundusEyeDetails={this.fundusEyeDetails.bind(this)}
    />
    )
  }
  medication(){
    return(
      <Medication patientAppointment={this.state.patientAppointment} 
      getTransmitDatas={this.getTransmitDatas.bind(this)}
      getTransmitDataFlag={this.state.getTransmitDataFlag}
      prescriptionIsTransmitted={this.prescriptionIsTransmitted.bind(this)}
      selectedView={this.state.selectedView}  
      reloadData={this.state.reloadData} 
      refreshData={this.refreshData.bind(this)} 
      editItem={this.editItem.bind(this)} 
      triggerNewItem={this.changeSelected.bind(this)} 
      prescriptionItem={this.state.prescriptionItem} 
      clearItem={this.clearPrescriptionState.bind(this)} 
      showResposeValue={this.showResposeValue.bind(this)}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      showPickerDropDownFlag={this.state.showPickerDropDownFlag}
      changePickerDropDownValueInDRNotes={this.changePickerDropDownValue.bind(this)}
      favouriteMediPopup={this.favouriteMediPopup.bind(this)}
      favSelectedMediDurg = {this.state.sendMediDrug}
    />
    )
  }
  glassPrescription(){
    return(
      <GlassPrescription patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      glassPrescriptionModalClose={this.glassPrescriptionModalClose.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}      
      updateContinueWithSameGlassFlag={this.updateContinueWithSameGlassFlag.bind(this)}
    />
    )
  }
  assessment(){
    return(
      <Assessment patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      getAssessmentData={this.state.getAssessmentData}
      clearAssessmentData={this.getAssessmentData.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}
    />
    )
  }
  reviewPlan() {
    return (
      <ReviewPlan patientAppointment={this.state.patientAppointment}
        selectedView={this.state.selectedView}
        reloadData={this.state.reloadData}
        refreshData={this.refreshData.bind(this)}
        editItem={this.editItem.bind(this)}
        triggerNewItem={this.changeSelected.bind(this)}
        userType={this.state.userType}
      />

    )
  }
  treatmentAdvice(){
    return(
      <TreatmentAdvice patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
    />
    )
  }
  counseling = () => {
    return(
      <Counseling
      patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedClinic={this.state.selectedClinicOrDoctor}
      showResposeValue={this.showResposeValue.bind(this)}
      />
    )
  }
  opthalmologyTest(){
    return(
      <OpthalmologyTest
      patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
    />
    )
  }
 
 documents(){
   return(
    <Documents patientAppointment={this.state.patientAppointment}
    selectedView={this.state.selectedView}
    reloadData={this.state.reloadData}
    refreshData={this.refreshData.bind(this)}
    editItem={this.editItem.bind(this)}
    triggerNewItem={this.changeSelected.bind(this)}
    showDocumentImage={this.showImage.bind(this)}
    showResposeValue={this.showResposeValue.bind(this)}
    transmittedImages = {this.transmittedImage.bind(this)}
    selectedTransmittedImage={this.selectedTransmittedImage.bind(this)}  />
   )
 }
symptoms(){
  return (
    <Symptoms patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      userType={this.state.userType}
    />
  )
 }
 allergy(){
   return (
     <Allergy patientAppointment={this.state.patientAppointment}
       selectedView={this.state.selectedView}
       reloadData={this.state.reloadData}
       refreshData={this.refreshData.bind(this)}
       editItem={this.editItem.bind(this)}
       triggerNewItem={this.changeSelected.bind(this)}
     />
   )
 }
 patientCategories(){
   return (
     <PatientCategories patientAppointment={this.state.patientAppointment}
       selectedView={this.state.selectedView}
       reloadData={this.state.reloadData}
       refreshData={this.refreshData.bind(this)}
       editItem={this.editItem.bind(this)}
       triggerNewItem={this.changeSelected.bind(this)}
     />
   )
 }
hopi(){
  return (
    <HOPI patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}

    />
  )
}
examination(){
  return (
    <Examination patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}
      reloadTemplate={this.reloadTemplate.bind(this)}
      ExaminationID={this.state.ExaminationID}

      reloadFavouriteID={this.state.reloadFavouriteID}
      clearreloadFavouriteID={this.clearreloadFavouriteID.bind(this)}
    />
  )
}
mentalStatusExamination(){
  return (
    <MentalStatusExamination patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      showResposeValue={this.showResposeValue.bind(this)}
      reloadTemplate={this.reloadTemplate.bind(this)}
      ExaminationID={this.state.ExaminationID}

      reloadFavouriteID={this.state.reloadFavouriteID}
      clearreloadFavouriteID={this.clearreloadFavouriteID.bind(this)}
    />
  )
}
prescription(){
  return (
    <Prescription patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      getTransmitDatas={this.getTransmitDatas.bind(this)}
      getTransmitDataFlag={this.state.getTransmitDataFlag}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      clearNewPrescription={this.addNewPrescription.bind(this)}
      prescriptionList={this.prescriptionList.bind(this)}
      showSelectedPrescription={this.showSelectedPrescription.bind()}
      showResposeValue={this.showResposeValue.bind(this)}
      addNewPrescription={this.state.addNewPrescription}
      userType={this.state.userType}
      transmitStatus={this.renderTransmit.bind(this)}
      PrescListFromPreviousPresc={this.state.PrescListFromPrevious}
      favouriteModelPopup={this.favouriteModelPopup.bind(this)}
      favSelectedDurg = {this.state.sendDrug}
    />
  )
}
Previous_prescription(){
  return (
    <PreviousPrescription patientAppointment={this.state.patientAppointment}
      selectedView={this.state.selectedView}
      getTransmitDatas={this.getTransmitDatas.bind(this)}
      getTransmitDataFlag={this.state.getTransmitDataFlag}
      selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
      reloadData={this.state.reloadData}
      refreshData={this.refreshData.bind(this)}
      editItem={this.editItem.bind(this)}
      triggerNewItem={this.changeSelected.bind(this)}
      clearNewPrescription={this.addNewPrescription.bind(this)}
      prescriptionList={this.prescriptionList.bind(this)}
      showSelectedPrescription={this.showSelectedPrescription.bind()}
      showResposeValue={this.showResposeValue.bind(this)}
      addNewPrescription={this.state.addNewPrescription}
      userType={this.state.userType}
      transmitStatus={this.renderTransmit.bind(this)}
      MovePrevToCurrent={this.MovePrevPrescriptionToCurrent.bind(this)}
    />
  )
}

test(){
  return(
    <Test patientAppointment={this.state.patientAppointment}
    selectedView={this.state.selectedView}
    reloadData={this.state.reloadData}
    refreshData={this.refreshData.bind(this)}
    editItem={this.editItem.bind(this)}
    triggerNewItem={this.changeSelected.bind(this)}
    selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
    showResposeValue={this.showResposeValue.bind(this)}

  />
  )
}
plan(){
  return(
    <Plan patientAppointment={this.state.patientAppointment}
    selectedView={this.state.selectedView}
    reloadData={this.state.reloadData}
    refreshData={this.refreshData.bind(this)}
    editItem={this.editItem.bind(this)}
    triggerNewItem={this.changeSelected.bind(this)}
    userType={this.state.userType}
    showResposeValue={this.showResposeValue.bind(this)}
  />
  )
  
}
GeneralPatientAdvice(){
  return(
    <GeneralPatientAdvice 
    patientAppointment={this.state.patientAppointment}
    getGPAdviceData={this.state.getGPAdviceData}
    selectedView={this.state.selectedView}
    triggerNewItem={this.changeSelected.bind(this)}
    showResposeValue={this.showResposeValue.bind(this)}
    />
    )}

  prescriptionIsTransmitted(flag) {
    this.setState({
      prescriptionIsTransmitted: flag
    })
  }

  glassPrescriptionModalClose(modalFlag, data, changeIndex) {

    // this.state.glassPrescriptionModalFlag = modalFlag;
    // this.state.selectedGlassprescriptionData = data;

    this.setState({
      glassPrescriptionModalFlag: modalFlag,
      selectedGlassprescriptionData: data,
      changeIndex: changeIndex
    });
  }

  showImage(flag, image = "", list=[]) {
    this.props.showDocumentImage(flag, image, list)
    // var states = this.state;
    // states["showDocumentImageFlag"] = flag
    // if (image) {
    //   states["showDocumentImage"] = image
    // }
    // this.setState({ states })
  }

  getAvailableEyeTypes(eyeTypes, eyeTypeId = []) {
    this.setState({
      getAvailableEyeTypes: eyeTypes,
      getAvailableEyeTypesId: eyeTypeId
    })
  }

  selectedVitals = (data) => {
    //console.log("from DN ------------------> " + JSON.stringify(data));
    this.setState({
      selectedVitals: data
    })
  }

  vitalsData = (data) => {
    this.setState({
      vitalsData: data
    })
  }

  prescriptionList = (prescription, showNewPrescription) => {
    this.setState({
      prescriptionList: prescription,
      showNewPrescription: showNewPrescription
    })
  }

  showSelectedPrescription = (showNewPrescription, editPrescriptionData) => {
    this.setState({
      showNewPrescription: showNewPrescription,
      editPrescriptionData: editPrescriptionData
    })
  }

  addNewPrescription = (newPrescription) => {
    this.setState({
      addNewPrescription: newPrescription
    })
  }

  editvitals = (flag, editvitals) => {
    this.setState({
      editedvitals: editvitals,
      vitalsflag: flag
    })
  }

  MovePrevPrescriptionToCurrent = (value)=>{
    this.changeTabSection("isPrescription", 5)
    setTimeout(() => {
      for(let i = 0; i < value.length; i++){
        value[i].brand_name = value[i]['drug_name'];
        value[i].dosage_strength = value[i]['drug_strength'];
        value[i].dosage_type = value[i]['drug_type'];
        value[i].id = ""  ;
        value[i].pharmacy_id = this.state.SelectedPharmacyID  ;
        value[i].duration_mode === undefined || value[i].duration_mode === null ? value[i].duration_mode = "1" : value[i].duration_mode
    }
      this.setState({
        PrescListFromPrevious : value
      })
    }, 1000);
  }

  renderFooterButtons() {
    return (
      <View style={styles.FooterButtonsView}>
        {this.state.userType !== Constants.ROLE_FO && this.state.userType !== Constants.ROLE_OPTOMETRY ?
          <TouchableOpacity disabled={!this.state.patientAppointment.is_locked} onPress={() => { this.transmitApiCall() }} style={[Style.doctorNotesFooterButton, { marginRight: '1.2vw', backgroundColor: this.state.patientAppointment.is_locked ? color.themeDark :color.disableLabelColor }]} >
            <Text style={[{ color: color.white }, Style.fontSizeMedium]}>{"Transmit Summary"}</Text>
          </TouchableOpacity> : null}
        {this.state.userType !== Constants.ROLE_FO && this.state.userType !== Constants.ROLE_OPTOMETRY?
          <TouchableOpacity disabled={this.state.patientAppointment.is_locked ? true : false} onPress={() => { 
            this.doctorNotesLock() }} style={[Style.doctorNotesFooterButton, { marginLeft: '1.2vw', marginRight: '1.2vw', backgroundColor: this.state.patientAppointment.is_locked ? color.disableLabelColor : color.themeDark }]} >
            <Text style={[{ color: color.white }, Style.fontSizeMedium]}>{"Lock"}</Text>
          </TouchableOpacity> : null}

        {this.state.isCurrentPageIsIP && this.state.selectedIpPatientDetails ?
          null
          : <TouchableOpacity onPress={() => { this.props.changeScreen("home") }} style={[Style.doctorNotesFooterButton, { marginLeft: '1.2vw' }]} >
            <Text style={[{ color: color.white }, Style.fontSizeMedium]}>{"Next"}</Text>
          </TouchableOpacity>}
      </View>
    )
  }

  transmitApiCall() {
    let states = this.state;
    var serviceUrl = Constants.TRANSMIT_API + this.state.patientAppointment.appointment_id + "/";

    OpthamologyService.getInstance().postComplaints(
      serviceUrl,
      "",
      this.transmitApiCallSuccess,
      this.transmitApiCallFailure,
      //  "pdf"
    );
  }

  transmitApiCallSuccess = success => {
    if (success.status == "success") {
      this.showResposeValue("success", success.message)
    } else {
      this.showResposeValue("error", success.message)
    }
  }
  transmitApiCallFailure = error => {
    if (error.status == "success") {
      this.showResposeValue("success", error.message)
    } else {
      this.showResposeValue("error", error.message)
    }

  }

  getTransmitDatas(data, flag) {
    this.setState({
      getTransmitDataFlag: flag
    }, () => {
      if (data && data.appointment_id && data.patient_id) {
        if (data.prescription_id) {
          this.transmitPrescription(data);
          // alert(JSON.stringify(data))
        } else {
          this.showResposeValue('error', Messages.DoctorNotePrescription)
        }
      }
    })
  }



  transmitPrescription = (transmitData) => {
    // alert("transmitPrescription  11")
    let service_url = Constants.MEDICATION_PRESCRIPTION_TRANSMIT;
    var data = {
      "appointment_id": transmitData.appointment_id,
      "patient_id": transmitData.patient_id,
    }

    if (transmitData.prescription_id) {
      data["prescription_id"] = transmitData.prescription_id
    }
    if (transmitData.pharmacy_id) {
      data["pharmacy_id"] = transmitData.pharmacy_id
    }

    // alert("transmitPrescription  22")


    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.transmitPrescriptionSuccess,
      this.transmitPrescriptionFailure
    );
  }

  transmitPrescriptionSuccess = success => {
    if (success.status == "success") {
      this.doctorNotesLockAPI()
      // this.showPrescriptionList();
      this.showResposeValue("success", success.message)
    } else {
      if ("Prescription is already transmitted to the pharmacy" == success.message) {
        this.doctorNotesLockAPI()
      }
      this.showResposeValue("error", success.message)
    }
  }
  transmitPrescriptionFailure = error => {
    this.showResposeValue("error", error.message)
  }



  doctorNotesLock() {
    let states = this.state;

    // if(  this.state.isTransmit === true ){
       this.doctorNotesLockAPI();
  //   }else{
  //  this.setState({
  //    getTransmitDataFlag: true,
  //  }, () => {
  //     })
  //   }
  }

  doctorNotesLockAPI() {
    var serviceUrl = Constants.DOCTOR_NOTES_LOCK + this.state.patientAppointment.appointment_id + "/lock/";
    OpthamologyService.getInstance().postComplaints(
      serviceUrl,
      "",
      this.doctorNotesLockSuccess,
      this.doctorNotesLockFailure,
      "pdf"
    );
  }

  doctorNotesLockSuccess = success => {
    if (success.status == "success") {

      // var patientAppointment = this.state.patientAppointment;
      // patientAppointment["is_locked"]=true;
      // this.setState({
      //   patientAppointment : patientAppointment
      // })
      var patientAppointmentUpdateValue = this.state.patientAppointment;

      patientAppointmentUpdateValue["is_locked"] = true;
      // alert(JSON.stringify(patientAppointmentUpdateValue))
      this.setState({
        patientAppointment: patientAppointmentUpdateValue
      }, () => { })


      this.showResposeValue("success", success.message)
      // setTimeout(() => {
      //   this.props.changeScreen("home")
      // }, 2000)
    } else {
      this.showResposeValue("error", success.message)
    }
  }
  doctorNotesLockFailure = error => {

    if (error.status == "success") {
      var patientAppointmentUpdateValue = this.state.patientAppointment;

      patientAppointmentUpdateValue["is_locked"] = true;
      this.setState({
        patientAppointment: patientAppointmentUpdateValue
      }, () => { })
      // alert("test")
      this.showResposeValue("success", error.message)
      // setTimeout(() => {
      //   this.props.changeScreen("home")
      // }, 2000)
    } else {
      this.showResposeValue("error", error.message)
    }

  }

  optComponentsView(){
    var optComponents =[
      this.complaints(),
      this.oscularHistory(),
      this.systemicIllness(),
      this.vitals(),
      this.visionAssessment(),
      this.Gonioscopy(),
      this.autoRefractometry(),
      this.previousGlassPower(),
      this.subjectiveRefraction(),
      this.additionalSubjectiveRefraction(),
      this.iop(),
      this.anteriorSegment(),
      this.posteriorSegment(),
      this.funds(),
      this.assessment(),
      this.medication(),
      this.glassPrescription(),
      this.reviewPlan(),
      this.treatmentAdvice(),
     this.counseling(),
      this.opthalmologyTest(),
      this.documents()
    ]
    return optComponents
  }

  commonComponents(){
    var components =[
      this.symptoms(),
      this.patientCategories(),
      this.allergy(),
      this.vitals(),
      this.examination(),
      this.assessment(),
      this.prescription(),
      this.Previous_prescription(),
      this.test(),
      this.plan(),
      this.GeneralPatientAdvice(),
     this.counseling()
    ]
    return components
  }
  psyComponents(){
    var components =[
      this.symptoms(),
      this.patientCategories(),
      this.allergy(),
      this.hopi(),
      this.vitals(),
      this.examination(),
      this.mentalStatusExamination(),
      this.assessment(),
      this.prescription(),
      this.Previous_prescription(),
      this.test(),
      this.plan(),
      this.GeneralPatientAdvice()
    ]
    return components

  }
  foComponents(){
    var components =[

      this.vitals(),
    ]

    if(this.state.selectedClinicOrDoctor.serviceType == "Ophtalmology" ||  
    this.state.selectedClinicOrDoctor.serviceType == "OPTHOMOLOGY" || 
    this.state.selectedClinicOrDoctor.serviceType == "OPT"){
      components.push(this.reviewPlan())
    }


    return components
  }
  optometryComponents(){
    var components =[
      this.complaints(),
      this.oscularHistory(),
      this.systemicIllness(),
      this.vitals(),
      this.visionAssessment(),
      this.Gonioscopy(),
      this.autoRefractometry(),
      this.previousGlassPower(),
      this.subjectiveRefraction(),
      this.iop(),
      this.anteriorSegment(),
      this.posteriorSegment(),
      this.additionalSubjectiveRefraction(),
    ]
    return components
  }

  changePickerDropDownValue(flag){
    this.setState({
      showPickerDropDownFlag:flag
    })
  }
}


// errorAlert() {
//   return (
//     <View style={{ flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#EBC8C4", borderRadius: 5, borderColor: "#A58B8A", borderWidth: 1, height: 70, marginRight: 10, marginTop: 10 }}>
//       <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, flex: 0.95 }}>
//         <Icon size={25} name={"ban"} color={"#D26560"} />
//         <Text style={{ fontWeight: "700", color: "#D26560", fontSize: 12, marginLeft: 15 }}>{"Error: "}
//           <Text style={{ fontWeight: "400", color: "#D26560", fontSize: 12 }}>{this.state.responseMeaasge}</Text>
//         </Text>
//       </View>
//       <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
//         <Icon size={25} name={"close"} color={"#C69A99"} />
//       </TouchableOpacity>
//     </View>
//   );
// }

const styles = StyleSheet.create({
    FooterButtonsView: { 
      flexDirection: 'row', 
      alignSelf: 'center', 
      alignContent: 'center', 
      alignItems: 'center', 
      marginVertical: '1.2vw' 
    },
    SucessAlertContainer: { 
      flex: 1, 
      zIndex: 3, 
      width: "40%", 
      position: 'absolute', 
      left: '0.6vw', 
      bottom: 5, 
      right: 0, 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      flexDirection: "row", 
      backgroundColor: "#DEF2D6", 
      borderRadius: 5, 
      borderColor: "#B8C7B2", 
      borderWidth: 1, 
      height: '5vw', 
      marginRight: '0.6vw', 
      marginTop: '0.6vw' 
    },
    SucessAlertView: { 
      flexDirection: 'row', 
      alignItems: 'center', 
      marginLeft: '0.6vw', 
      flex: 0.95 
    },
    SucessMsgText: { 
      fontWeight: "700", 
      color: "green", 
      fontSize: '0.8vw', 
      marginLeft: '1vw' 
    },
    SucessMsgContentText: { 
      fontWeight: "400", 
      color: "green", 
      fontSize: '0.8vw' 
    },
    SucessMsgTouch: { 
      flex: 0.05, 
      marginRight: '1vw', 
      alignItems: 'center', 
      alignContent: 'center', 
      alignSelf: 'center', 
      justifyContent: "center" 
    },
    ErrorAlertView: { 
      flexDirection: 'row', 
      alignItems: 'center', 
      marginTop: '1vw', 
      marginLeft: '0.6vw', 
      flex: 0.95 
    },
    ErrorMsgText: { 
      fontWeight: "700", 
      color: "#FFF", 
      fontSize: '0.8vw' 
    },
    ErrorMsgContentText: { 
      fontWeight: "400", 
      color: "#FFF", 
      fontSize: '0.8vw', 
      marginBottom: '0.5vw' 
    },
    ErrorMsgTouch: { 
      flex: 0.05, 
      marginRight: '1vw', 
      alignItems: 'center', 
      alignContent: 'center', 
      alignSelf: 'center', 
      justifyContent: "center" 
    },
    ErrorAlertContainerView: { 
      flex: 1, 
      zIndex: 3, 
      width: "35%", 
      position: 'absolute', 
      left: '0.6vw', 
      bottom: 5, 
      right: 0, 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      flexDirection: "row", 
      backgroundColor: "#CD3A34", 
      borderRadius: 5, 
      borderColor: "#A58B8A", 
      borderWidth: 1, 
      minHeight: '5vw', 
      marginRight: '0.6vw', 
      marginTop: '0.6vw' 
    },
    buttonContainer:{
      flexDirection: "row", 
      justifyContent: "right" 
    },
    confirmButtonStyle:{
      padding: "0.45vw",
      borderRadius: 4,
      width: "7.81vw",
      justifyContent: "center",
      marginRight: "0.32vw",
    },
    buttonTextStyle:{ 
      textAlign: "center", 
      color: "white", 
      fontSize:".9vw" 
    },
    closeButtonStyle:{
      backgroundColor: "white",
      padding: "0.45vw",
      borderRadius: 4,
      width: "7.81vw",
      justifyContent: "center",
      borderWidth: 1,
      marginLeft: "0.32vw",
      borderColor: "grey",
    },
    FavCon:{
      position: 'absolute', 
      zIndex: -1, 
      height: '90vh',
      width: '93.6vw',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    FavConHead: {
      backgroundColor: "white",
      width: "40%",
      borderRadius: "0.65vw",
      padding: "1.30vw",
      marginBottom: "1vw"
    }

})