import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  Picker,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import AsyncStorage from "../../../AsyncStorage";
import moment from "moment";

const platform = Platform.OS;
const pageTitle = "Counselling";
const pageName = "Counseling";
const isPageName = "isCounseling";

export default class Counseling extends BaseComponentStyle {
    constructor(props) {
      super(props);
  
      this.state = {
        isLocked:this.props.patientAppointment.is_locked,
        patientAppointment: this.props.patientAppointment,
        selectedClinic: this.props.selectedClinic,
        data: [],
        isComplaint: isPageName === this.props.selectedView?true:false,
        selectedCounselling: "surgery",
        TransmitCounsellor: null,
        counsellorList: [],
        lineId: null,
        isTransmitted: false,
        drType: ""
      }
    }

    async componentDidMount() {
        setTimeout(() => {
            this.getSurgeryCounselling();
        }, 5000);
     
        const userType = await AsyncStorage.getItem("DR_TYPE");
        this.setState({drType: userType})
    }

    componentWillReceiveProps(props){

        if(props.reloadData && props.reloadData === pageName) {
            // call once
            this.getSurgeryCounselling();
        }

        let updateFontColor = isPageName === props.selectedView?true:false;

        if(updateFontColor !== this.state.isComplaint){

        this.setState({ isComplaint: updateFontColor });
        }
    }

    getSurgeryCounselling = () => {

        var service_url = Constants.OPT_COUNSELLING_DETAIL_GET + "?patient_id=" + this.state.patientAppointment.patient_id + "&appointment_id=" + this.state.patientAppointment.appointment_id + "&clinic_id=" + this.state.selectedClinic.id ;
        setTimeout(() => {
            OpthamologyService.getInstance().getComplaints(
                service_url,
                this,
                this.getSurgeryCounsellingSuccess,
                this.getSurgeryCounsellingFailure
              );
        }, 5000);

      };
    
      getSurgeryCounsellingSuccess = response => {
        if (response.status === "success") {
          var field = this.state;
          field["data"] = response.data.surgery_details;
          field["counsellorList"] = response.data.counsellor_details;
          field["lineId"] = response.data.surgery_details.id;
          field["isTransmitted"] = (response.data.surgery_details.status === "Transmitted" || response.data.surgery_details.status === "Completed" ) ? true : false;
          if((response.data.surgery_details.status) === "Transmitted" || (response.data.surgery_details.status) === "Completed"){
            field["TransmitCounsellor"] = response.data.surgery_details.counsellor_id;
          }
          this.setState({ field });
        }
      };
    
      getSurgeryCounsellingFailure = error => {
        // console.log("opthamology complaints GET error response");
        console.log(error);
      };

    triggerNewItem= (key) => {

        this.props.triggerNewItem(key);
    }

    renderComplaintItem = (item, index) => {
        var time = '-'
        var Hour24 = (item.surgery_time).split(":")
        if(item.surgery_time){
            if(parseInt(Hour24[0]) > 12){
                var hour =  parseInt(Hour24[0]) - 12
                var amPm = 'PM'
                var minutes = Hour24[1]
                time = hour + ":" + minutes + " " + amPm
            }else{
                var hour =  Hour24[0]
                var amPm = 'AM'
                var minutes = Hour24[1]
                time = hour + ":" + minutes + " " + amPm
            }
        }
    return (
        <View>
        <View style={styles.ComplaintItemView}>
            <View
            style={{
                marginLeft: '0.3vw',
                width: platform === "web" ? "100%" : "75%",
                flexDirection: "row",
                justifyContent: "space-between",
                // height: 50,
                alignItems: "center"
                // paddingBottom: 10
            }}
            >
            <View
                style={{
                width: "13%",
                paddingRight: '0.6vw'
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {item.surgery_name}
                </Text>
            </View>

            <View
                style={{
                width: "17%",
                paddingRight: '0.6vw'
                // alignItems: "center"
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {/* {this.getEyeType(item.disease_name)} */}
                {item.package_name ? item.package_name : "-"}
                </Text>
            </View>
            {this.state.drType == "Ophthalmology" ?<View
                style={{
                width: "12%",
                paddingRight: '0.6vw'
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {item.eye_type}
                </Text>
            </View>: null}
            <View
                style={{
                width: "13%",
                paddingRight: '0.6vw',
                // alignSelf: item.surgeon_name? "flex-start" : "center"
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {item.surgery_date? moment(item.surgery_date).format("DD/MM/YYYY") : "-"}
                </Text>
            </View>
            <View
                style={{
                width: "13%",
                paddingRight: 10,
                // alignSelf: item.surgeon_name? "flex-start" : "center"
                }}
            >
                <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                {time}
                </Text>
            </View>
            <View
                style={{
                width: "16%",
                paddingRight: '0.6vw',
                // alignSelf: item.surgeon_name? "flex-start" : "center"
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {item.surgeon_name? item.surgeon_name : "-"}
                </Text>
            </View>
            <View
                style={{
                width: "16%",
                paddingRight: '0.6vw'
                }}
            >
                <Text style={styles.ComplaintItemText}>
                {item.anaesthetist ? item.anaesthetist : "-"}
                </Text>
            </View>
            </View>
        </View>
        <View style={{ height: '0.03vw', backgroundColor: "#E0E0E0" }} />
        </View>
    );
    };

    renderComplaintItem2 = (item, index) => {
        return (
            <View>
            <View style={styles.ComplaintItemView1}>
                <View
                style={{
                    marginLeft: '0.3vw',
                    width: platform === "web" ? "100%" : "75%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // height: 50,
                    alignItems: "center"
                    // paddingBottom: 10
                }}
                >
                {this.state.drType == "Ophthalmology" ?<View
                    style={{
                    width: "13%",
                    paddingRight: '0.6vw'
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.iol_power ? item.iol_power : "-"}
                    </Text>
                </View> : null}
                <View
                    style={{
                    width: "15%",
                    paddingRight: '0.6vw'
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.counsellor_name ? item.counsellor_name : "-"}
                    </Text>
                </View>
    
                <View
                    style={{
                    width: "15%"
                    // alignItems: "center"
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {/* {this.getEyeType(item.disease_name)} */}
                    {item.net_amount}
                    </Text>
                </View>
                <View
                    style={{
                    width: "12%"
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.discount_amount}
                    </Text>
                </View>
                <View
                    style={{
                    width: "12%",
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.gst_percentage ? item.gst_percentage : "-"}
                    </Text>
                </View>
                <View
                    style={{
                    width: "15%",
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.status ? item.status : "Pending"}
                    </Text>
                </View>
                <View
                    style={{
                    width: "18%",
                    }}
                >
                    <Text style={styles.ComplaintItemText}>
                    {item.notes ? item.notes : "-"}
                    </Text>
                </View>
                </View>
            </View>
            <View style={{ height: '0.03vw', backgroundColor: "#E0E0E0" }} />
            </View>
        );
        };

    renderIconBtn = (label) => {
        return(
            <TouchableOpacity 
            disabled = {this.state.isTransmitted}
            onPress={() => {
                this.onPressBtn(label)
            }}
            style={[styles.TransmitBtnTouch, {backgroundColor: this.state.isTransmitted? color.grayShade : color.themeDark}]}>
                <Text style={[styles.TransmitBtnText, {color: color.white}]}>{label}</Text>
            </TouchableOpacity>
        )
    }

    onPressBtn = (label) => {
        if(label === "Transmit to Counsellor"){
            this.transmitSurgeryToCounsellor()
        }
    }

    transmitSurgeryToCounsellor = () => {
        var states = this.state
        var patientDetails = this.state.patientAppointment
        var data = {
            patient_id: patientDetails.patient_id,
            appointment_id: patientDetails.appointment_id,
            clinic_id: states.selectedClinic.id,
            counsellor_id: parseInt(states.TransmitCounsellor),
            id: states.lineId,
        }

        var service_url = Constants.OPT_COUNSELLING_DETAIL_POST;

        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.transmitSurgeryToCounsellorSuccess,
            this.transmitSurgeryToCounsellorFailure
        );
    }

    transmitSurgeryToCounsellorSuccess = (response) => {
        if (response.status === "success") {      
            // reload data
            this.props.refreshData("Counseling");
            this.props.showResposeValue("success",response.message)
          }else{
            this.props.showResposeValue("error",response.message)
          }
    }

    render() {
        return(
            <View>
                {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
                <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop:"0.3vw"}}>
                <View>
                {/* <Picker
                selectedValue={this.state.selectedCounselling}
                style={styles.PickerDropDownView}
                itemStyle={{ fontSize: '0.9vw' }}
                onValueChange={(itemvalue) => {
                    if(itemvalue != "") {
                        var states = this.state;
                        states["selectedCounselling"] = itemvalue
                        this.setState({ states });
                    }}}
                >
                <Picker.Item label="- Select -" value="" />
                <Picker.Item label="Surgery" value="surgery" />
                </Picker> */}
                <Picker
                    selectedValue={this.state.TransmitCounsellor}
                    style={[styles.PickerDropDownView1,{width:"100%", height:"2vw"}]}
                    itemStyle={{ fontSize: '0.9vw' }}
                    onValueChange={(itemvalue) => {
                        if(itemvalue != "") {
                            var states = this.state;
                            states["TransmitCounsellor"] = itemvalue
                            this.setState({ states });
                        }}}
                    >
                    <Picker.Item label="Select Counsellor" value="" />
                    {this.state.counsellorList && (this.state.counsellorList).map((item, index) => {
                        return(
                            <Picker.Item label={item.counsellor_name} value={item.counsellor_id} />
                        )
                    })}
                    </Picker>
                </View>
                <View>
                    {this.renderIconBtn("Transmit to Counsellor")}
                </View>
                </View>
                {
                    (this.state.data.length > 0) || (this.state.data.surgery_name || this.state.data.eye_type || this.state.data.package_name) ?

                    <View style={[styles.TableHeaderView, {backgroundColor: color.sectionHeadingDarkColor}]}>
                    <View
                        style={{
                        marginLeft: '0.3vw',
                        width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                        }}
                    >
                        <View
                        style={{
                            width: "13%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Surgery</Text>
                        </View>

                        <View
                        style={{
                            width: "17%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Package name</Text>
                        </View>
                        {this.state.drType == "Ophthalmology" ?<View
                        style={{
                            width: "12%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Eye type</Text>
                        </View> : null}
                        <View
                        style={{
                            width: "13%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Surgery date</Text>
                        </View>
                        <View
                        style={{
                            width: "13%"
                        }}
                        >
                        <Text style={{ fontSize: 12, color: color.black, fontWeight: "500" }}>Surgery Time</Text>
                        </View>
                        <View
                        style={{
                            width: "16%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Surgeon name</Text>
                        </View>
                        <View
                        style={{
                            width: "16%"
                        }}
                        >
                        <Text style={[styles.HeaderText, {color: color.black}]}>Anaesthetist</Text>
                        </View>
                        </View>
                        
                    </View>
                    : null
                }
                {(this.state.data.length > 0) || (this.state.data.surgery_name || this.state.data.eye_type || this.state.data.package_name) ? this.renderComplaintItem(this.state.data):null}
                {
                    (this.state.data.length > 0) || (this.state.data.surgery_name || this.state.data.eye_type || this.state.data.package_name) ?

                    <View style={[styles.TableHeaderView, { backgroundColor: color.sectionHeadingDarkColor }]}>
                    <View
                        style={{
                        marginLeft: '0.3vw',
                        width: platform === "web" ? "100%" : "75%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                        }}
                    >
                        {this.state.drType == "Ophthalmology" ?<View
                        style={{
                            width: "13%"
                        }}
                        >
                        <Text style={{ fontSize: 12, color: color.black, fontWeight: "500" }}>IOL Power</Text>
                        </View> :null}
                        <View
                        style={{
                            width: "15%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>Counsellor</Text>
                        </View>

                        <View
                        style={{
                            width: "15%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>Amount(₹)</Text>
                        </View>
                        <View
                        style={{
                            width: "12%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>Discount(₹)</Text>
                        </View>
                        <View
                        style={{
                            width: "12%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>GST(%)</Text>
                        </View>
                        <View
                        style={{
                            width: "15%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>Status</Text>
                        </View>
                        <View
                        style={{
                            width: "18%"
                        }}
                        >
                        <Text style={[styles.HeaderText, { color: color.black}]}>Remarks</Text>
                        </View>
                        </View>
                        
                    </View>
                    : null
                }
                {(this.state.data.length > 0) || (this.state.data.surgery_name || this.state.data.eye_type  || this.state.data.package_name) ?this.renderComplaintItem2(this.state.data):null}
            </View>
        )
    }
}
const styles = StyleSheet.create({
        PickerDropDownView: { 
            marginTop: '0.4vw', 
            borderRadius: 4, 
            borderColor: "#CDD1D5", 
            height: '2.1vw', 
            width: "100%",
            zIndex:-1,
            fontSize: '0.9vw'
        },
        PickerDropDownView1: { 
            borderRadius: 4, 
            borderColor: "#CDD1D5", 
            width: "50%", 
            textAlign:"center",
            fontSize: '0.9vw'
        },
        TransmitBtnTouch: {
            justifyContent: "center", 
            alignItems: "center", 
            paddingVertical: '0.3vw', 
            paddingHorizontal: '0.6vw', 
            borderRadius: 4
        },
        TransmitBtnText: {
            fontSize: '0.9vw'
        },
        TableHeaderView: { 
            marginTop: '0.6vw', 
            padding: '0.3vw', 
        },
        HeaderText: { 
            fontSize: '0.8vw', 
            fontWeight: "500" 
        },
        ComplaintItemView: { 
            paddingTop: '0.6vw',
            paddingBottom:'0.6vw'
        },
        ComplaintItemText: { 
            fontSize: '0.8vw', 
            color: "#2F2F2F" 
        },
        ComplaintItemView1: { 
            paddingTop: '0.6vw' ,
            paddingBottom:'0.6vw'
        } 
})