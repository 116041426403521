import React from 'react';
import { View, Text, Platform, TouchableOpacity, StyleSheet } from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from '../../../styles/Color';
import CloseIcon from "../../../../assets/images/opthamology/close.svg"
import { Image } from 'react-native-elements';
import OpthamologyService from '../../../network/OpthamologyService';
import { Constants } from '../../../utils/Constants';
import { Tooltip } from 'antd';
import ImagePath from '../../../utils/ImagePaths';

const pageTitle = "Gonioscopy";
const pageName = "Gonioscopy";
const isPageName = "isGonioscopy";

class Gonioscopy extends BaseComponentStyle {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: this.props.patientAppointment.is_locked,
            patientAppointment: this.props.patientAppointment,
            isComplaint: isPageName === this.props.selectedView ? true : false,
            getAvailableEyeTypes: [],
            getAvailableEyeTypesId: [],
            B_R_Inferior: "",
            B_R_Nasal: "",
            B_R_Superior: "",
            B_R_Temporal: "",
            B_L_Inferior: "",
            B_L_Nasal: "",
            B_L_Superior: "",
            B_L_Temporal: "",
            A_R_Inferior: "",
            A_R_Nasal: "",
            A_R_Superior: "",
            A_R_Temporal: "",
            A_L_Inferior: "",
            A_L_Nasal: "",
            A_L_Superior: "",
            A_L_Temporal: "",
            itemList: [],
            itemListAfter: [],
            ShowComponent: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.getGonioscopyItemList();
        }, 2000);
    }

    componentWillReceiveProps(props) {

        if (props && props.patientAppointment && props.patientAppointment.patient_id) {
            this.state.isLocked = props.patientAppointment.is_locked
        }

        if (props.reloadData && props.reloadData === pageName) {
            this.getGonioscopyItemList();
            this.props.refreshData("");
        }

        let updateFontColor = isPageName === props.selectedView ? true : false;

        if (updateFontColor !== this.props.isComplaint) {

            this.setState({ isComplaint: updateFontColor });
        }
    }

    triggerNewItem = (key) => {
        this.props.triggerNewItem(key);
        this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes, this.state.getAvailableEyeTypesId)
    }

    getGonioscopyItemList = () => {

        var service_url = Constants.OPTHALMOLOGY_GONIOSCOPY + "?appointment_id=" + this.state.patientAppointment.appointment_id;
        setTimeout(() => {
            OpthamologyService.getInstance().getComplaints(
                service_url,
                this,
                this.getGonioscopyDetailsSuccess,
                this.getGonioscopyDetailsFailure
            );
        }, 2000);
    }

    getGonioscopyDetailsSuccess = response => {

        if (response.status === "success") {
            let { itemList } = this.state;
            itemList = response && response.Gonioscopy_data ? response.Gonioscopy_data : [];
            var data = itemList;
            var availableEye = []
            var availableEyeId = []

            for (var i = 0; i < data.length; i++) {
                availableEye.push(data[i].eye_type);
                var prepareData = {
                    "eye_type": data[i].eye_type,
                    "id": data[i].id
                }
                availableEyeId.push(prepareData);
            }

            var BeforeDilationRE = []
            var AfterDilationRE = []
            var BeforeDilationLE = []
            var AfterDilationLE = []
            for (var i = 0; i < data.length; i++) {
                if (data[i].before_dilation == true && data[i].eye_type == "RE") {
                    BeforeDilationRE.push(data[i])
                } else if (data[i].before_dilation == true && data[i].eye_type == "LE") {
                    BeforeDilationLE.push(data[i])
                } else if (data[i].before_dilation == false && data[i].eye_type == "RE") {
                    AfterDilationRE.push(data[i])
                } else if (data[i].before_dilation == false && data[i].eye_type == "LE") {
                    AfterDilationLE.push(data[i])
                }
            }

            this.setState({
                B_R_Inferior: BeforeDilationRE[0]?.inferior_grade ? BeforeDilationRE[0].inferior_grade : "",
                B_R_Nasal: BeforeDilationRE[0]?.nasal_grade ? BeforeDilationRE[0].nasal_grade : "",
                B_R_Superior: BeforeDilationRE[0]?.superior_grade ? BeforeDilationRE[0].superior_grade : "",
                B_R_Temporal: BeforeDilationRE[0]?.temporal_grade ? BeforeDilationRE[0].temporal_grade : "",
                B_L_Inferior: BeforeDilationLE[0]?.inferior_grade ? BeforeDilationLE[0].inferior_grade : "",
                B_L_Nasal: BeforeDilationLE[0]?.nasal_grade ? BeforeDilationLE[0].nasal_grade : "",
                B_L_Superior: BeforeDilationLE[0]?.superior_grade ? BeforeDilationLE[0].superior_grade : "",
                B_L_Temporal: BeforeDilationLE[0]?.temporal_grade ? BeforeDilationLE[0].temporal_grade : "",
                A_R_Inferior: AfterDilationRE[0]?.inferior_grade ? AfterDilationRE[0].inferior_grade : "",
                A_R_Nasal: AfterDilationRE[0]?.nasal_grade ? AfterDilationRE[0].nasal_grade : "",
                A_R_Superior: AfterDilationRE[0]?.superior_grade ? AfterDilationRE[0].superior_grade : '',
                A_R_Temporal: AfterDilationRE[0]?.temporal_grade ? AfterDilationRE[0].temporal_grade : "",
                A_L_Inferior: AfterDilationLE[0]?.inferior_grade ? AfterDilationLE[0].inferior_grade : "",
                A_L_Nasal: AfterDilationLE[0]?.nasal_grade ? AfterDilationLE[0].nasal_grade : "",
                A_L_Superior: AfterDilationLE[0]?.superior_grade ? AfterDilationLE[0].superior_grade : "",
                A_L_Temporal: AfterDilationLE[0]?.temporal_grade ? AfterDilationLE[0].temporal_grade : "",
                getAvailableEyeTypes: availableEye,
                getAvailableEyeTypesId: availableEyeId,
                itemList: { 0: BeforeDilationRE[0], 1: BeforeDilationLE[0] },
                itemListAfter: { 0: AfterDilationRE[0], 1: AfterDilationLE[0] }
            }, () => {
                this.props.getAvailableEyeTypes(this.state.getAvailableEyeTypes, this.state.getAvailableEyeTypesId)
            })

        }
    }

    getGonioscopyDetailsFailure = response => {
        this.props.showResposeValue("error", response.message)
    }

    editGonioscopyData = (item, event) => {

        // let { itemList, itemListAfter } = this.state

        // let tempItemList = item[0].before_dilation && item[1].before_dilation ? itemList.map((item) => {
        //     item['selected'] = item.eye_type === eye_type ? true : false;
        //     return item;
        // }) : itemListAfter.map((item) => {
        //     item['selected'] = item.eye_type === eye_type ? true : false;
        //     return item;
        // })

        this.props.triggerNewItem(isPageName);
        this.props.editItem(pageName, item, "", "");
    }

    renderGonioscopyImage = (imgType, Inferior, Nasal, Superior, Temporal) => {
        return (
            <View style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                {Object.keys(Superior ? Superior : "").length > 15 ?
                    <Tooltip placement="top" title={Superior}>
                        <Text style={styles.Toptext}>{Superior.slice(0, 15)}</Text>
                    </Tooltip> :
                    <Text style={styles.Toptext}>{Superior}</Text>}
                {Object.keys(Nasal ? Nasal : "").length > 15 ?
                    <Tooltip placement="top" title={Nasal}>
                        <Text style={styles.Lefttext}>{Nasal.slice(0, 15)}</Text>
                    </Tooltip> :
                    <Text style={styles.Lefttext}>{Nasal}</Text>}
                {Object.keys(Temporal ? Temporal : "").length > 15 ?
                    <Tooltip placement="top" title={Temporal}>
                        <Text style={styles.Righttext}>{Temporal.slice(0, 15)}</Text>
                    </Tooltip> :
                    <Text style={styles.Righttext}>{Temporal}</Text>}
                {Object.keys(Inferior ? Inferior : "").length > 15 ?
                    <Tooltip placement="top" title={Inferior}>
                        <Text style={styles.Bottomtext}>{Inferior.slice(0, 15)}</Text>
                    </Tooltip> :
                    <Text style={styles.Bottomtext}>{Inferior}</Text>}
                <View>
                    <Image source={CloseIcon} style={styles.Imagestyle} />
                </View>
                <Text style={styles.Eyetype}>{imgType}</Text>
            </View>
        )
    }

    render() {
        let states = this.state
        return (
            <View>
                <View style={styles.HeaderContainerTiltleView}>
                    {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
                    <TouchableOpacity onPress={() => { this.setState({ ShowComponent: !this.state.ShowComponent }) }}>
                        {
                            this.state?.ShowComponent ?
                                <Image
                                    source={ImagePath.UpArrowIcon}
                                    style={{ height: "1.5vw", width: "1.5vw", tintColor: color.grayShade }} /> :
                                <Image
                                    source={ImagePath.DownArrowIcon}
                                    style={{ height: "1.5vw", width: "1.5vw", tintColor: color.grayShade }} />
                        }

                    </TouchableOpacity>
                </View>


                {
                    this.state?.ShowComponent ?
                        <View>
                            <View>
                                <View style={styles.HeaderContainer}>
                                    <Text style={styles.headerText}>Before Dilation</Text>
                                    <TouchableOpacity onPress={this.editGonioscopyData.bind(this, states.itemList)} disabled={this.state.isLocked}>
                                        {this.renderIdButton("pencil")}
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.BodyContainer}>
                                    {this.renderGonioscopyImage("Right Eye (OD)", states.B_R_Inferior, states.B_R_Nasal, states.B_R_Superior, states.B_R_Temporal)}
                                    {this.renderGonioscopyImage("Left Eye (OS)", states.B_L_Inferior, states.B_L_Nasal, states.B_L_Superior, states.B_L_Temporal)}
                                </View>
                            </View>
                            <View>
                                <View style={[styles.HeaderContainer, { marginTop: ".5vw" }]}>
                                    <Text style={styles.headerText}>After Dilation</Text>
                                    <TouchableOpacity onPress={this.editGonioscopyData.bind(this, states.itemListAfter)} disabled={this.state.isLocked}>
                                        {this.renderIdButton("pencil")}
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.BodyContainer}>
                                    {this.renderGonioscopyImage("Right Eye (OD)", states.A_R_Inferior, states.A_R_Nasal, states.A_R_Superior, states.A_R_Temporal)}
                                    {this.renderGonioscopyImage("Left Eye (OS)", states.A_L_Inferior, states.A_L_Nasal, states.A_L_Superior, states.A_L_Temporal)}
                                </View>
                            </View>
                        </View> : null}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    HeaderContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: ".65vw",
        backgroundColor: color.sectionHeadingDarkColor,
        padding: ".5vw",
        height: "2.5vw",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: color.lightGray,
        borderTopLeftRadius: ".3vw",
        borderTopRightRadius: ".3vw"
    },
    HeaderContainerTiltleView: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    headerText: {
        fontSize: "1.1vw",
        fontWeight: "500"
    },
    BodyContainer: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: color.lightGray,
        height: "20vw",
        borderBottomLeftRadius: ".3vw",
        borderBottomRightRadius: ".3vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: "5vw"
    },
    Imagestyle: {
        width: "13vw", height: "13vw", tintColor: "#808080"
    },
    Eyetype: {
        fontSize: ".95vw",
        fontWeight: "500",
        marginTop: "3vw"
    },
    Toptext: {
        position: "absolute", top: "1.4vw", color: color.grayShade, zIndex: 20
    },
    Lefttext: {
        position: "absolute", left: "-3.5vw", top: "7.3vw", color: color.grayShade, zIndex: 20
    },
    Righttext: {
        position: "absolute", right: "-3.5vw", top: "7.3vw", color: color.grayShade, zIndex: 20
    },
    Bottomtext: {
        position: "absolute", bottom: "5vw", color: color.grayShade, zIndex: 20
    }
})

export default Gonioscopy;