//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { CommonHistorySectionHeader, NavigationTopHeader } from '../../BaseComponent';
import CommonDateFilter from '../../BaseComponent'
import Pagination from '../../common/Pagination';
import { color } from '../../../../styles/Color';
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import AsyncStorage from '../../../../AsyncStorage';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");


const navigationHeader = [
    { label: "Report", value: "referredReport" },
]

class ReferredReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referredDoctorList: [],
            referredDoctorListAfterPagination: [],
            current_page: 1,
            records_per_page: 10,
            filterData: {
                fromDate: converted_date,
                toDate: converted_date
            },
            isAdminAccess: false
        }
    }
   async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
        isAdminAccess: loggedInDataObj.is_admin_access
      })
        this.getReferredDoctorReport()
    }
    getReferredDoctorReport() {
        var { filterData } = this.state
        this.setState({
            referredDoctorList: [],
            referredDoctorListAfterPagination: [],
            current_page: 1,
            records_per_page: 10,
        })
        var serviceUrl = Constants.LAB_REFERRED_REPORT + "?from_date=" + filterData.fromDate + "&to_date=" + filterData.toDate;
    
        OpthamologyService.getInstance().getComplaints(
          serviceUrl,
          this,
          this.referredDoctorReportSuccess,
          this.referredDoctorReportFailure,
        );
      }
    
      referredDoctorReportSuccess = (success) => {
        if (success.status == "success") { 
             this.setState({
                referredDoctorList: success.data
             })
        }
      }
      referredDoctorReportFailure = ( error ) => {

      }      
    renderHeader = () => {
        return (
            <CommonHistorySectionHeader
                heading1={"S.No"}
                heading2={"Doctor Name"}
                heading3={"Doctor Reg No"}
                heading4={"No of Bills"}
                heading5={"Bill Amount"}
                columnSize={[0.1, 0.25, 0.22, 0.22, 0.21]}  //total value is == of 1
                noOfColumn={5}
            />
        )
    }
    renderTableData = () => {
        return (
          <View>
           { this.state.referredDoctorListAfterPagination && this.state.referredDoctorListAfterPagination.length > 0 ?
            <View>
                {this.state.referredDoctorListAfterPagination && this.state.referredDoctorListAfterPagination.length > 0 && this.state.referredDoctorListAfterPagination.map((item, index) => {
                    let sno = (parseInt(this.state.current_page) - 1) * parseInt(this.state.records_per_page) + (index + 1)
                    return (
                        <View style={styles.dataContainer}>
                            <Text style={{ flex: 0.1, marginHorizontal: 20 }}>{sno}</Text>
                            <Text style={{ flex: 0.25}}>{item.reffered_by}</Text>
                            <Text style={{ flex: 0.22 }}>{item.reffered_reg_no}</Text>
                            <Text style={{ flex: 0.22 }}>{item.count_reg_no}</Text>
                            <Text style={{ flex: 0.21 }}>{item.total}</Text>
                        </View>
                    )
                })

                }
            </View>:
              <View style={styles.noRecordContainer}>
                <Text style={styles.noRecordText}>{"No records to be shown"}</Text>
              </View>
             }
           </View> 
        )
    }
    paginationChangedValues(data) {
        this.setState({
            referredDoctorListAfterPagination: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    renderPaginationView() {
        return (
            <View style={{ marginHorizontal: 20 }}>
                {
                    this.state.referredDoctorList && this.state.referredDoctorList.length > 0 ?
                        <Pagination
                            paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalItems={this.state.referredDoctorList}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        /> : null
                }
            </View>
        )
    }
    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var { filterData } = this.state;
        states["filterData"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate;
        this.setState({
             states,
             filterData 
        },() => {
            this.getReferredDoctorReport()
        })
    }

    renderFilterView = () => {
        return (
            <View style={styles.ButtonContainer}>
               
                <CommonDateFilter
                    removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : ""}
                    defaultSelectedDateFilter={"Today"}
                    startDate={this.state.filterData.fromDate}
                    endDate={this.state.filterData.toDate}
                    showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                    filterDate={this.state.filterData} />
                {/* <TouchableOpacity
                    onPress={() => {
                        // this.printVendorDetails("pdf")
                    }}
                    style={styles.PrintStyle}>
                    <Image style={styles.Image} source={require('../../../../../assets/images/print_icon.png')} />
                </TouchableOpacity> */}
            </View>
        )
    }
    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeader}
                    selectedTab={"referredReport"}
                />
                <View style={styles.container}>
                    <View style={{ zIndex: 10 }}>{this.renderFilterView()}</View>
                    <View style={{ zIndex: 1 }}>{this.renderHeader()}</View>
                    <View style={{ zIndex: 1 }}>{this.renderTableData()}</View>
                    {this.renderPaginationView()}
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        marginHorizontal: "1.2vw"
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.75vw",
        justifyContent: 'center',
        flex: 1,
        zIndex: 1

    },
    ButtonContainer: {
        flex: 1,
        alignSelf: "flex-end",
        alignItems: "flex-end",
        flexDirection: "row",
        paddingVertical: "0.5vw",
    },
    PrintStyle: { width: "2.60vw", height: "2.27vw", borderRadius: ".25vw", backgroundColor: color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.16vw", marginHorizontal: "1vw" },
    Image: { width: "1.95vw", height: "1.95vw", tintColor: color.white },
    noRecordContainer: {
        flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "20vw"
    },
    noRecordText: {
        color: color.placeholder, marginTop: "5vw" 
    }
    
});

//make this component available to the app
export default ReferredReport;
