import React, { useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, Modal, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
// import { color } from 'react-native-reanimated';
import { color } from "../../styles/Color"

export default function DocCameraFunction(props) {

    const [hasPermission, setHasPermission] = useState(true);
    const [cameraRef, setCameraRef] = useState(null)
    const [type, setType] = useState(Camera.Constants.Type.back);
    const [patientPhoto, setPatientPhoto] = useState([]);
    const [profilePhoto, setProfilePhoto] = useState(null)

    // useEffect(() => {
    //     (async () => {
    //         const { status } = await Camera.requestPermissionsAsync();
    //         setHasPermission(status === 'granted');
    //     })();
    // }, []); 
    
    // if (hasPermission === null) {
    //     return <View><Text>{"No Permission"}</Text></View>;
    // }
    // if (hasPermission === false) {
    //     return <Text>No access to camera</Text>;
    // }

    if(profilePhoto){
        // console.log("pic:", profilePhoto)
        return(
            <View style={styles.DocumentCamView}>
                <Image source={{uri: profilePhoto }} 
                style={styles.DocumentImgView}
                resizeMode="contain" ></Image>
                <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                    <TouchableOpacity
                        // onPress={() => async () => {                            
                        //     updateProfilePhoto(profilePhoto, false)                            
                        // }}
                        onPress={()=>props.onConfirmPress(profilePhoto)}
                        >
                        <Text style={[styles.ConfirmText, {backgroundColor: color.themeBackgroud}]}>{"Confirm"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => { setProfilePhoto(null) }}>
                        <Text style={[styles.ReatakeText, {color: color.themeBackgroud, borderColor: color.themeBackgroud}]}>{"Retake"}</Text>
                    </TouchableOpacity>
                </View>
            </View>    
        )
    }
    
    return (
        
            <Modal
            animationType="slide"
            transparent={false}
            visible = {props.cameraclick}
            onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
                <View style={styles.ModelView}>
            <Camera style={styles.CameraView} type={type} ref={ref => {
                setCameraRef(ref);
            }}>
                <View
                    style={{
                        flex: 1,
                        backgroundColor: 'transparent',
                        justifyContent: 'flex-end'
                    }}>
                    {/* <TouchableOpacity
                        style={{
                            flex: 0.1,
                            alignSelf: 'flex-end'
                        }}
                        onPress={() => {
                            setType(
                                type === Camera.Constants.Type.back
                                    ? Camera.Constants.Type.front
                                    : Camera.Constants.Type.back
                            );
                        }}>
                        <Text style={{ fontSize: 18, marginBottom: 10, color: 'white' }}> Flip </Text>
                    </TouchableOpacity> */}
                    <TouchableOpacity style={{ alignSelf: 'center' }} onPress={async () => {
                        if (cameraRef) {
                            let photoResponse = await cameraRef.takePictureAsync();
                        //    console.log('photo', photoResponse);
                            let obj=photoResponse; //JSON.parse(photoResponse)
                            if(obj.base64){
                                setProfilePhoto(obj.base64)
                            }
                        }
                    }}>
                        <View style={{
                            borderWidth: 2,
                            borderRadius: "50%",
                            borderColor: 'white',
                            height: '3.3vw',
                            width: '3.3vw',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        >
                            <View style={{
                                borderWidth: 2,
                                borderRadius: "50%",
                                borderColor: 'white',
                                height: '2.6vw',
                                width: '2.6vw',
                                backgroundColor: 'white'
                            }} >
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </Camera>
            {/* <h1>Hello world!</h1> */}
            </View>
            </Modal>

    );
}
const styles = StyleSheet.create({
        DocumentCamView: { 
            flex: 1, 
            minHeight: '32.6vw', 
            flexDirection: "column", 
            justifyContent: "flex-start", 
            alignContent: "center" 
        },
        DocumentImgView: { 
            height: '25.3vw', 
            width: '25.3vw', 
            alignSelf: "center" 
        },
        ConfirmText: { 
            fontSize: '1.1vw', 
            marginBottom: '0.6vw', 
            color: 'white', 
            paddingHorizontal: '1vw', 
            paddingVertical: '0.5vw', 
            borderRadius: 4, 
            marginRight: '0.1vw' 
        },
        ReatakeText: { 
            fontSize: '1.1vw', 
            marginBottom: '0.6vw', 
            paddingHorizontal: '1vw', 
            paddingVertical: '0.4vw', 
            borderRadius: 4, 
            borderWidth: 1, 
            marginLeft: '0.1vw' 
        },
        ModelView: { 
            flex: 1, 
            minHeight: '29vw', 
            minWidth: '29vw' 
        },
        CameraView: { 
            flex: 1, 
            minHeight: '29vw' 
        }
})