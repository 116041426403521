import { Component } from 'react';
import {
  Alert,
  View,
  Platform
} from "react-native";
import AsyncStorage from "../AsyncStorage";

const platform = Platform.OS;

export default class BaseClass extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  _showAlert(message, btnText) {
    Alert.alert("", message, [{ text: btnText, onPress: () => {} }], {
      cancelable: false
    });
  }

  _showAlertWIthCallBack(message, btnText, btnAction) {
    Alert.alert("", message, [{ text: btnText, onPress: () => btnAction() }], {
      cancelable: false
    });
  }

  _showAlertTwoButtons(message, btnText1, btn1Action, btnText2, btn2Action) {
    Alert.alert(
      "",
      message,
      [
        { text: btnText1, onPress: () => btn1Action() },
        { text: btnText2, onPress: () => btn2Action() }
      ],
      { cancelable: false }
    );
  }

  _showProgress() {}
  _hideProgress() {}

  _logoutFunction = async () => {
    await AsyncStorage.removeItem("loggedIn");
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("service_type");
    await AsyncStorage.removeItem("doctorName");
    await AsyncStorage.removeItem("doctorProfile");

    this.props.navigation.navigate("auth");
  };

  render() {
    return (
      <View />
    );
  }
}
