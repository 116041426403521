import React from "react";
import {
  View,
  Text,
  FlatList,
  Platform,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import {CommonHistorySectionHeader} from '../BaseComponent'
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Style from "../../../styles/Style";
// import Loader from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import moment from "moment";

const platform = Platform.OS;
const pageTitle = "Allergies";
const pageName = "Allergy";
const isPageName = "isAllergy";

export default class Complaints extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      allergyData: [],
      isAllergy: isPageName===this.props.selectedView?true:false,
      // isLoading : true
    };
  }

  componentDidMount() {
    
    this.getAllergy();
  }

  componentWillReceiveProps(props) {

    if(props.reloadData && props.reloadData === pageName) {
      // call once
       this.getAllergy();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName===props.selectedView?true:false;

    if(updateFontColor !== this.props.isAllergy){

      this.setState({ isAllergy: updateFontColor });
    }
  }

  getAllergy = () => {

    var service_url = Constants.ALLERGY_GET+ "?patient_id=" + this.state.patientAppointment.patient_id;
  

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAllergySuccess,
      this.getAllergyFailure
    );
  };

  getAllergySuccess = response => {
    if (response.status === "success") {
      // alert(JSON.stringify(response))
      var field = this.state;
      field["allergyData"] = response.data;  
      // console.log(response.data) 
      this.setState({ field
        // isLoading: false
      });
    }
  };

  getAllergyFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  
  renderSymptomsItem = (item, index) => {
    // [0.23, 0.26, 0.13, 0.28, 0.1]
    return (

      <View style={{ flexDirection: 'row',alignItems:'center',paddingVertical:8,borderBottomColor:color.sectionItemLine,borderBottomWidth:1 }}>
                   <View style={styles.AllergySymptomsView}>
                    <Text style={[Style.contentTableDataLead]}>{item.allergy_type == 1 ? "Medication Allergy" : "Non-Medication Allergy"}</Text>
                    <Text style={ styles.AllergySymptomsText}> {item.diagnosed_date?moment(item.diagnosed_date).format("DD-MM-YYYY"):null} </Text>
                   </View>
                   <Text style={[Style.contentTableDataLead, { flex: 0.26 }]}>{item.name}</Text>
                   <Text style={[ item.is_active === true ? Style.contentActive : Style.contentInActive ,{flex: 0.13,fontSize: '0.9vw',}]}> {item.is_active === true ? "Active" : "Inactive"} </Text>
                   <Text style={[Style.contentTableDataLead, { flex: 0.28,flexWrap:"wrap" }]} numberOfLines={5}>{item.comments}</Text>
                   
                   <View style={{ flex: 0.1,flexDirection: 'row',justifyContent:'space-between' }}>
                    <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                        {this.renderIdButton("pencil")}
                      </TouchableOpacity>
                    </View>
                 </View> 
      
      // <View>
      //   <View style={{ paddingTop: 0 }}>
      //     <View
      //       style={{
      //         marginLeft: 5,
      //         width: platform === "web" ? "100%" : "75%",
      //         flexDirection: "row",
      //         justifyContent: "space-between",
      //         alignItems: "center"
      //       }}
      //     >
      //       <View
      //         style={{
      //           width: "20%"
      //         }}
      //       >
      //         <Text style={ Style.contentTableDataLead }>
      //           {item.allergy_type == 1 ? "Medication Allergy" : "Non-Medication Allergy"}
      //         </Text>
              // <Text style={ {fontSize:12}}>
              //   {item.diagnosed_date}
              // </Text>
      //       </View>

      //       <View
      //         style={{
      //           width: "25%"
      //           // alignItems: "center"
      //         }}
      //       >
      //         <Text style={ Style.contentTableData }>
      //           {item.name}
      //         </Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "15%"
      //         }}
      //       >
              // <Text style={ item.is_active === true ? Style.contentActive : Style.contentInActive }>
              //   {item.is_active === true ? "Active" : "InActive"}
              // </Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "20%"
      //         }}
      //       >
      //         <Text style={ Style.contentTableData }>
      //           {item.comments}
      //         </Text>
      //       </View>
      //       <View
      //         style={{
      //           width: "10%",
      //           alignItems: "center",
      //           height: 60,
      //           flexDirection: "row"
      //         }}
      //       >
      //         <View
      //           style={{
      //             flexDirection: "row",
      //             paddingTop: 5,
      //             paddingBottom: 5,
      //             backgroundColor: color.white,
      //             height: 50
      //           }}
      //         >
                // <TouchableOpacity disabled={this.state.isLocked} onPress={this.editComplaints.bind(this, item)}>
                //   {this.renderIdButton("pencil")}
                // </TouchableOpacity>

      //         </View>
      //       </View>
      //     </View>
      //   </View>
      //   <View style={ Style.sectionItemLine } />
      // </View>
    );
  };

  // getComplaintsType (type) {

  //   let itemType = "";

  //   let temp = Constants.eye_values.map((item,index)=>{

  //     if(type === item.value) { itemType = item.label; }
  //     return (
  //       null
  //     )
  //   })
  //   return itemType;
  // }

  editComplaints = (item, event) => {
    this.props.triggerNewItem(isPageName);
    this.props.editItem(pageName, item);
  }
  
  // deleteItem = (deleteItem, event) => {
    



  //   //this.props.editItem(pageName, item);
  //   var service_url = Constants.SYMPTOMS_DATA_DELETE ;
  //   let data = {

  //     "appointment_id": this.state.patientAppointment.appointment_id,
  //     "symptoms_id":deleteItem.id
    
  //   };
     
     

  //   OpthamologyService.getInstance().postComplaints(
  //     service_url,
  //     data,
  //     this,
  //     this.getDeleteSuccess,
  //     this.getSymptomsFailure
  //   );
  // };

  // getDeleteSuccess = response => {
  //   if (response.status === "success") {
      
  //     this.getSymptoms();
  //   }
  // };

  triggerNewItem= (key) => {

    this.props.triggerNewItem(key);
  }

  render() {
    // if (this.state.isLoading) {
    //   return(
    //     <View style={Style.LoaderStyle}>
    //     <Loader
    //      type="Bars"
    //      color="#45B7B1"
    //      height={100}
    //      width={100}
    //     //  timeout={3000} //3 secs
 
    //     />
    //     </View>
        
    //   )
    // }
    return (
        <View style={{}} >
        {this.renderTitleBorder(this.state.isAllergy, pageTitle, isPageName, this.triggerNewItem)}

        <View style={styles.TableHead1}>
          <CommonHistorySectionHeader
            heading1={"Allergy Type"}
            heading2={"Allergy Name"}
            heading3={"Status"}
            heading4={"Comments"}
            heading5={"Action"}
            columnSize={[0.23, 0.26, 0.13, 0.28, 0.1]}  //total value is == of 1
            noOfColumn={5}
          />
        </View>

        {/* <View style={{}}>
          <View
            style={{
              marginLeft: 5,
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <View
              style={{
                width: "20%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Allergy Type</Text>
            </View>

            <View
              style={{
                width: "25%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Allergy Name</Text>
            </View>
            <View
              style={{
                width: "15%"
              }}
            >
              <Text style={ Style.contentTableTitle }>Status</Text>
            </View>
            <View
              style={{
                width: "20%",
                           backgroundColor: color.white,
              }}
            >
               <Text style={ Style.contentTableTitle }>Comments</Text>
              </View>

              <View
              style={{
                width: "10%",
             
                backgroundColor: color.white,
                height: 50
              }}
            >
               <Text style={ Style.contentTableTitle }></Text>
              </View>
             
          </View>
          
          
        </View> */}

        <FlatList
          data={this.state.allergyData}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderSymptomsItem(item, index)
          }
        />

        {/* <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress = {() => this.triggerNewItem(isPageName)}>
          {this.renderIconBtn("plus", "New", false, isPageName)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  TableHead1: { 
    marginTop: '0.6vw' 
  },
  AllergySymptomsView: {
    flex: 0.23 ,
    marginHorizontal: '1.2vw'
  },
  AllergySymptomsText: {
    fontSize:'1vw',
    color:"#888888",
    marginTop:'0.3vw'
  }
})