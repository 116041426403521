//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";

// create a component
const pageName = "isBloodGlucose"
var bloodGlucosetest = [
    // { label: "Nil", value: "Nil" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" }
]
var highBloodGulcose = [
    { label: "Frequent Urination", value: "Frequent Urination" },
    { label: "Feet-Numbness/Tingling", value: "Feet-Numbness/Tingling" },
    { label: "Slow healing Wounds", value: "Slow healing Wounds" },
    { label: "Unexplained weight loss", value: "Unexplained weight loss" },
    { label: "Frequent Thirst", value: "Frequent Thirst" },
    { label: "Pain in Feet while Resting", value: "Pain in Feet while Resting" },
    { label: "Blurred Vision", value: "Blurred Vision" },
    { label: "Increased Hunger", value: "Increased Hunger" },
    { label: "Constantly Tired", value: "Constantly Tired" },
    { label: "Frequent Infections", value: "Frequent Infections" },
]
var BloodGulcosesymptoms = [
    { label: "Headaches", value: "Headaches" },
    { label: "Shaking/Sweating", value: "Shaking/Sweating" },
    { label: "Dizziness", value: "Dizziness" },
    { label: "Poor Leg Circulation", value: "Poor Leg Circulation" },
    { label: "Irritability/Anxiety", value: "Irritability/Anxiety" },
    { label: "Intense Hunger", value: "Intense Hunger" },
    { label: "Weakness", value: "Weakness" }
]
export class BloodGlucoseNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            bloodGlucosetest: bloodGlucosetest,
            selectedbloodGlucosetest: {},
            showbloodGlucosetestNewButtonFlag: true,

            above300: false,
            above300Details: "",
            below80: false,
            below80Details: "",
            checkFeetDetails: "",
            checkFeet: false,
            bg_monitor_id: "",
            highBloodGulcose: highBloodGulcose,
            selectedhighBloodGulcose: [],
            showhighBloodGulcoseNewButtonFlag: true,

            BloodGulcosesymptoms: BloodGulcosesymptoms,
            showSelectedBloodGulcosesymptomsNewButtonFlag: true,
            selectedBloodGulcosesymptoms: [],
            newButtonValue: "",
            checkVisonDetails: "",
            checkVision: false,
        }
    }


    componentWillReceiveProps(props) {

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;

                var states = this.state;
                var { bloodGlucosetest, highBloodGulcose, BloodGulcosesymptoms } = this.state
                states["bg_monitor_id"] = data.bg_monitor_id
                states["above300"] = data.bg_above_300
                states["below80"] = data.bg_below_70
                // states["selectedhighBloodGulcose"] = data.high_blood_glucose ? { label: data.high_blood_glucose, value: data.high_blood_glucose } : null
                // states["selectedBloodGulcosesymptoms"] = data.low_blood_glucose ? { label: data.low_blood_glucose, value: data.low_blood_glucose } : null
                states["checkFeet"] = data.check_your_feet
                states["checkFeetDetails"] = data.how_freq
                states["checkVision"]=data.vision_problem
                states["checkVisonDetails"]=data.vision_problem_desc
                //blood glucose per month
                var bloodGlucosetestList = data && data.bg_check_frequency ? data.bg_check_frequency : "";
                var prepareChildren = { label: bloodGlucosetestList, value: bloodGlucosetestList }

                if(bloodGlucosetestList){
                    bloodGlucosetest.push(prepareChildren);
                    states["selectedbloodGlucosetest"] = prepareChildren;
                }

                bloodGlucosetest = this.removeDuplicate(bloodGlucosetest)

                //High Blood glucose in the past 3 months?

                var getBloodGulcose = data?.high_blood_glucose[0]?.split(",");
                if (getBloodGulcose?.length > 0) {
                    let data = [];
                    for (let i = 0; i < getBloodGulcose.length; i++) {

                        var preparData = {
                            label: getBloodGulcose[i],
                            value: getBloodGulcose[i],
                        };

                        // alert(JSON.stringify(birthControlList))

                        var getValueIsAvailable = highBloodGulcose.includes(preparData)



                        if (getValueIsAvailable) {
                            data.push(preparData);
                        } else {
                            highBloodGulcose.push(preparData)
                            data.push(preparData);
                        }


                    }
                    states["selectedhighBloodGulcose"] = data;
                    // states["birthControlList"] = data;
                }
                highBloodGulcose = this.removeDuplicate(highBloodGulcose)

                // var getBloodGulcose = data.high_blood_glucose ? data.high_blood_glucose.split(',') : []
                // if (getBloodGulcose.length > 0) {
                //     let data = []
                //     for (let i = 0; i < getBloodGulcose.length; i++) {
                //         var preparData = { label: getBloodGulcose[i], value: getBloodGulcose[i] }

                //         data.push(preparData)
                //     }
                //     states["selectedhighBloodGulcose"] = data

                // } else {
                //     states["selectedhighBloodGulcose"] = []
                // }

                // var getPastBloodGulcose = data.low_blood_glucose ? data.low_blood_glucose.split(',') : []
                // if (getPastBloodGulcose.length > 0) {
                //     let data = []
                //     for (let i = 0; i < getPastBloodGulcose.length; i++) {
                //         var preparData = { label: getPastBloodGulcose[i], value: getPastBloodGulcose[i] }

                //         data.push(preparData)
                //     }
                //     states["selectedBloodGulcosesymptoms"] = data

                // } else {
                //     states["selectedBloodGulcosesymptoms"] = []
                // }
                //High Blood glucose in the low 3 months?

                var getBloodlowGulcose = data.low_blood_glucose[0] ? data?.low_blood_glucose[0]?.split(",") : [];
                if (getBloodlowGulcose.length > 0) {
                    let data = [];
                    for (let i = 0; i < getBloodlowGulcose.length; i++) {

                        var preparData = {
                            label: getBloodlowGulcose[i],
                            value: getBloodlowGulcose[i],
                        };

                        // alert(JSON.stringify(birthControlList))

                        var getValueIsAvailable = BloodGulcosesymptoms.includes(preparData)



                        if (getValueIsAvailable) {
                            data.push(preparData);
                        } else {
                            BloodGulcosesymptoms.push(preparData)
                            data.push(preparData);
                        }


                    }
                    states["selectedBloodGulcosesymptoms"] = data;
                    // states["birthControlList"] = data;
                }
                BloodGulcosesymptoms = this.removeDuplicate(BloodGulcosesymptoms)



                this.setState({ states, bloodGlucosetest, highBloodGulcose, BloodGulcosesymptoms }, () => {
                    this.props.editHistory({}, pageName)
                })

            }
        }
    }


    onChangeYesOrNoValue(flag, value, key) {
        var states = this.state;
        if (key == "above300Details") {
            states["above300"] = flag;
            states[key] = value;
        }
        else if (key == "below80Details") {
            states["below80"] = flag;
            states[key] = value;
        }
        else if (key == "checkFeetDetails") {
            states["checkFeet"] = flag;
            states[key] = value;
        } else if (key == "checkVisonDetails") {
            states["checkVision"] = flag;
            states[key] = value;
        }
        this.setState({ states })
    }
    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "showbloodGlucosetestNewButtonFlag") {
            states[key] = false;
        }

        else if (key == "showhighBloodGulcoseNewButtonFlag") {
            states[key] = false;
        }
        else if (key == "showSelectedBloodGulcosesymptomsNewButtonFlag") {
            states[key] = false;
        } else if (key == "selectedhighBloodGulcose") {

            var { selectedhighBloodGulcose } = this.state
            var getSelectedValue;
            var spliceData;
            if (selectedhighBloodGulcose.length > 0) {
                for (var i = 0; i < selectedhighBloodGulcose.length; i++) {
                    if (selectedhighBloodGulcose[i].value == selectedValue.value) {
                        spliceData = selectedhighBloodGulcose[i];
                        selectedhighBloodGulcose.splice(i, 1)
                        var data = this.removeDuplicate(selectedhighBloodGulcose);
                        this.setState({ selectedhighBloodGulcose: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedhighBloodGulcose.push(selectedValue); }
            } else {
                selectedhighBloodGulcose.push(selectedValue)
            }
            var data = this.removeDuplicate(selectedhighBloodGulcose);
            this.setState({ selectedhighBloodGulcose: data })
            // states[key] = false;
        } else if (key == "selectedBloodGulcosesymptoms") {

            var { selectedBloodGulcosesymptoms } = this.state
            var getSelectedValue;
            var spliceData;
            if (selectedBloodGulcosesymptoms.length > 0) {
                for (var i = 0; i < selectedBloodGulcosesymptoms.length; i++) {
                    if (selectedBloodGulcosesymptoms[i].value == selectedValue.value) {
                        spliceData = selectedBloodGulcosesymptoms[i];
                        selectedBloodGulcosesymptoms.splice(i, 1)
                        var data = this.removeDuplicate(selectedBloodGulcosesymptoms);
                        this.setState({ selectedBloodGulcosesymptoms: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedBloodGulcosesymptoms.push(selectedValue); }
            } else {
                selectedBloodGulcosesymptoms.push(selectedValue)
            }
            var data = this.removeDuplicate(selectedBloodGulcosesymptoms);
            this.setState({ selectedBloodGulcosesymptoms: data })
            // states[key] = false;
        }
        else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }
    onSubmitToAddValue() {
        var { bloodGlucosetest, highBloodGulcose, BloodGulcosesymptoms } = this.state;
        var states = this.state;
        var preparData = {};

        preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showbloodGlucosetestNewButtonFlag) {
            bloodGlucosetest.push(preparData);
            bloodGlucosetest = this.removeDuplicate(bloodGlucosetest);
            states["newButtonValue"] = "";
            states["showbloodGlucosetestNewButtonFlag"] = true;
            states["selectedbloodGlucosetest"] = preparData

        }

        if (!this.state.showhighBloodGulcoseNewButtonFlag) {
            highBloodGulcose.push(preparData);
            highBloodGulcose = this.removeDuplicate(highBloodGulcose);
            states["newButtonValue"] = "";
            states["showhighBloodGulcoseNewButtonFlag"] = true;
            states["selectedhighBloodGulcose"].push(preparData)
        }
        if (!this.state.showSelectedBloodGulcosesymptomsNewButtonFlag) {
            BloodGulcosesymptoms.push(preparData);
            BloodGulcosesymptoms = this.removeDuplicate(BloodGulcosesymptoms);
            states["newButtonValue"] = "";
            states["showSelectedBloodGulcosesymptomsNewButtonFlag"] = true;
            states["selectedBloodGulcosesymptoms"].push(preparData)

            // states[""]
        }

        this.setState({
            bloodGlucosetest, highBloodGulcose, BloodGulcosesymptoms, states
        })

    }
    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            if(item.value){
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                            }
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newbuttonFlag ?
                                <View style={{ marginBottom: 10 }}>
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedvalue={{}}
                                        butttonText={"New "}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} /></View>
                                :
                                <TextInput
                                    style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                    onChangeText={(text) => {
                                        this.setState({ newButtonValue: text })
                                    }}
                                    onSubmitEditing={() => {
                                        this.onSubmitToAddValue()
                                    }}
                                />
                        }
                    </View>

                </View>
            </View>
        )
    }
    onPressAddButton() {
     //   console.log("patientAppointment --- > " + JSON.stringify(this.state.patientAppointment))
        var states = this.state;

        var getArray = []
        var getpastGlucose = []

        if (states.selectedhighBloodGulcose.length > 0) {
            for (let i = 0; i < states.selectedhighBloodGulcose.length; i++) {
                // const element = array[i];
                getArray.push(states.selectedhighBloodGulcose[i].value)

            }
        }

        if (states.selectedBloodGulcosesymptoms.length > 0) {
            for (let i = 0; i < states.selectedBloodGulcosesymptoms.length; i++) {
                // const element = array[i];
                getpastGlucose.push(states.selectedBloodGulcosesymptoms[i].value)

            }
        }

        var data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "bg_check_frequency": states.selectedbloodGlucosetest.value ? states.selectedbloodGlucosetest.value :"", 
            "bg_above_300": states.above300,
            "bg_below_70": states.below80,
            "high_blood_glucose": getArray.toString() ? [getArray.toString()] : [],
            "low_blood_glucose": getpastGlucose.toString() ? [getpastGlucose.toString()] : [],
            "check_your_feet": states.checkFeet,
            "how_freq": states.checkFeetDetails ? states.checkFeetDetails :"",
            "vision_problem":states.checkVision ,
            "vision_problem_desc":states.checkVisonDetails ? states.checkVisonDetails :""
        }
        if (this.state.bg_monitor_id) {
            data["bg_monitor_id"] = this.state.bg_monitor_id
        }

        var service_url = Constants.BLOOD_GLUCOSE_MONITORING;


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.bloodGlucoseSaveSuccess,
            this.bloodGlucoseSaveFailure
        );
    }
    bloodGlucoseSaveSuccess = success => {
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            // this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
        this.clearAllData()
    }
    bloodGlucoseSaveFailure = error => {
        this.props.showResposeValue('error', error.message)

    }

    clearAllData() {
        var states = this.state;
        states["selectedbloodGlucosetest"] = {}
        states["above300"] = false
        states["above300Details"] = ""
        states["below80"] = false
        states["below80Details"] = ""
        states["checkFeet"] = false
        states["checkFeetDetails"] = ""
        states["selectedhighBloodGulcose"] = []
        states["bg_monitor_id"] = ""
        states["selectedBloodGulcosesymptoms"] = []
        states["newButtonValue"] = ""
        this.setState({
            states
        })
    }

    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Blood Glucose Monitoring"} clearAllData={this.clearAllData.bind(this)} />
                {/* blood glucose button */}
                {this.renderLabelWithButtonGroup(
                    " How often do you test you blood glucose per month?",
                    "selectedbloodGlucosetest",
                    this.state.selectedbloodGlucosetest,
                    "showbloodGlucosetestNewButtonFlag",
                    this.state.showbloodGlucosetestNewButtonFlag,
                    this.state.bloodGlucosetest)}
                <View style={styles.container}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.above300}
                        label={" Any recent blood glucose above 300?"}
                        detailsOfYesValue={this.state.above300Details}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"above300Details"}
                        showDetailsText={false}
                    /></View>
                <View style={styles.container}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.below80}
                        label={" Any recent blood glucose below 80?"}
                        detailsOfYesValue={this.state.below80Details}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"below80Details"}
                        showDetailsText={false}
                    /></View>
                {/* highBloodGulcose button */}
                {this.renderLabelWithButtonGroup(
                    "Have you had any of the following signs of High Blood glucose in the past 3 months?",
                    "selectedhighBloodGulcose",
                    this.state.selectedhighBloodGulcose,
                    "showhighBloodGulcoseNewButtonFlag",
                    this.state.showhighBloodGulcoseNewButtonFlag,
                    this.state.highBloodGulcose)}
                {/* highBloodGulcose second button */}
                {this.renderLabelWithButtonGroup(
                    "Have you had any of the following signs of Low Blood glucose in the past 3 months?",
                    "selectedBloodGulcosesymptoms",
                    this.state.selectedBloodGulcosesymptoms,
                    "showSelectedBloodGulcosesymptomsNewButtonFlag",
                    this.state.showSelectedBloodGulcosesymptomsNewButtonFlag,
                    this.state.BloodGulcosesymptoms)}
                <View style={styles.container}>
                    <HistoryYesOrNoSection
                        selectedValue={this.state.checkFeet}
                        label={"Do you check your feet ?"}
                        detailsOfYesValue={this.state.checkFeetDetails}
                        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                        sectionKey={"checkFeetDetails"}
                        showDetailsText={true}
                        placeholder="How often and details?"
                        maxLength={100}
                    />
                    <View style={{ marginTop: 10, flexWrap: 'wrap', }}>
                        <HistoryYesOrNoSection
                            selectedValue={this.state.checkVision}
                            label={"Do you have any problem in your vision?"}
                            detailsOfYesValue={this.state.checkVisonDetails}
                            onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}
                            sectionKey={"checkVisonDetails"}
                            showDetailsText={true}
                            placeholder="How often and details?"
                            maxLength={100}
                        />
                    </View>
                </View>
                <View style={{ marginTop: 10 }}>
                    <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                </View>
            </View>
        );
    }
}
// define your styles
const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
        marginTop: 10,
        marginBottom: 10,
    },
    add: {
        alignSelf: "center",
        marginTop: 20
    }
});
