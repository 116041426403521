import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";

import {
  CommonHistorySectionHeader,
  CommonSectionHeader,
} from "../BaseComponent";
import {
  CommonButton,
  DoctorNotesCommonRightSideHeader,
} from "../BaseComponent";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
var Messages=require('../../../utils/InfoMessages')

const pageName = "GeneralPatientAdvice";

class GeneralPatientAdviceNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      comments: "",
      isLocked:this.props.patientAppointment.is_locked,
      NewTemplateAdd:false,
      TemplateName:'',
      Templatecomments:"",
      TemplateData:[],
      SelectedTemplateId:""
    };
  }

  componentDidMount(){
    this.getPatientAdviceTemplates()
  }

  getPatientAdviceTemplates = ()=>{
    var service_url = Constants.GENERAL_PATIENT_ADVICE_TEMPLATE 

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTemplateNameSuccess,
      this.getTemplateNameFailure
    );
  }

  getTemplateNameSuccess = (response)=>{
    this.setState({
      TemplateData: response.data
    })
  }

  addAdvice = () => {
    let data = {"advice_given": this.state.comments}
    if(this.state.comments.length == 0 ){
      this.props.showResposeValue("error",Messages.GeneralPatientAdvice)
    }
      else{
      this.props.getGPAdviceData(data)
      }
    this.clearGpAdvice();
  };
  clearGpAdvice=()=>{
      this.setState({
        comments:'',
        SelectedTemplateId:""
      })
  }


  SaveTemplate = ()=>{
    let SameTemplateName = []
    SameTemplateName = this.state.TemplateData.filter(list => list['template_name'].toLowerCase() === this.state.TemplateName.toLowerCase());
    if(this.state.TemplateName != "" && this.state.Templatecomments != "" && SameTemplateName.length === 0 ){
        var service_url = Constants.GENERAL_PATIENT_ADVICE_TEMPLATE;
        let data = {
          "template_name": this.state.TemplateName,
          "advice": this.state.Templatecomments
        };
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.TemplateAddSuccess,
          this.TemplateAddFailure,
        );

    }else{
      if(this.state.TemplateName == ""){
        this.props.showResposeValue("error", "Enter Template Name !")
      }
      else if(this.state.Templatecomments == ""){
        this.props.showResposeValue("error", "Enter Template Comments !")
      }
      else if(SameTemplateName.length > 0){
        this.props.showResposeValue("error", "Template Already Exists !")
      }
    }

  }

  TemplateAddSuccess = (response) => {
    if (response.status == 'success') {
      this.props.showResposeValue("success", response.message)
      this.setState({
        NewTemplateAdd: false,
        TemplateName: "",
        Templatecomments: ""
      }, () => { this.getPatientAdviceTemplates() })
    } else {
      this.props.showResposeValue("error", response.message)
    }
  }
  TemplateAddFailure = (error) => {
    this.props.showResposeValue("error", error.message)

  }
  
  render() {
    return (
      <View>
        <DoctorNotesCommonRightSideHeader
          title={"General Patient Advice"}
        />

        <View>
          <CommonSectionHeader heading={"Templates"} />
          <View style={styles.SelectTemplateView}>
            {
                this.state.TemplateData && this.state.TemplateData.length > 0 && this.state.TemplateData.map((item, index) => (
                  <TouchableOpacity 
                  disabled={this.state.isLocked}
                  style={[styles.TempBtnTouch,  {
                    borderColor: this.state.SelectedTemplateId === item.id ?color.themeDark:color.textBoxBorderColor,
                    backgroundColor:  this.state.SelectedTemplateId === item.id ?color.themeDark:color.themeShadeBackground,
                    marginBottom: '0.6vw', marginRight: '0.4vw'
                  }]}
                  onPress={()=>{this.setState({NewTemplateAdd: false, SelectedTemplateId: item.id, comments: item.advice})}}
                  >
                  <Text style={[styles.TempButtonText, {
                     color: this.state.SelectedTemplateId === item.id ? color.white : color.themeBlue,
                  }]}>
                  {item.template_name}
                  </Text>
                </TouchableOpacity>
                ))
              }


          </View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginVertical:'1vw', marginTop:'0.5vw' }}>
          { !this.state.NewTemplateAdd ?
            <TouchableOpacity
            disabled={this.state.isLocked}
            onPress={() => { this.setState({ NewTemplateAdd: true }) }}
            style={[styles.TempBtnTouch, {width:'9vw', marginLeft:'1.2vw', backgroundColor: this.state.isLocked ? color.disableLabelColor : color.themeShade }]}>
              <Text style={styles.TempButtonText}>
               + Add New Template
              </Text>
            </TouchableOpacity> : null }
            {
              this.state.NewTemplateAdd ?

                <TextInput
                  maxLength={30}
                  placeholder="Enter Template Name"
                  style={styles.TextInputStyles}
                  onChangeText={(text) => {
                    this.setState({
                      TemplateName: text
                    })
                  }}
                />
                : null}
          </View>
          <View>
            {this.state.NewTemplateAdd ?
              <View>
                <View style={{ borderRadius: 10, zIndex: -7, marginHorizontal: '1.2vw' }}>
                  {this.renderTextFieldView(
                    "Enter Comments (Max 250 characters)", "Templatecomments", this.state.Templatecomments, true, 4, "", {}, "", false, this.state.isLocked, 250)}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <TouchableOpacity style={styles.BtnTouch}><Text style={styles.ButtonText} onPress={() => { this.setState({ NewTemplateAdd: false, TemplateName:"", Templatecomments:"" }) }}>Close</Text></TouchableOpacity>
                  <TouchableOpacity style={styles.BtnTouch}><Text style={styles.ButtonText} onPress={() => { this.SaveTemplate() }}>Save Template</Text></TouchableOpacity>
                </View>
              </View>
              : null}
          </View>
        </View>

        <View style={styles.TableHeadView}>
          <CommonSectionHeader
            heading={"Advice"}
          />
        </View>

        <View style={{ borderRadius: 10, zIndex: -7, marginHorizontal: '1.2vw' }}>
          {this.renderTextFieldView(
            "Comments (Max 250 characters)","comments",this.state.comments,true,4,"",{},"",false,this.state.isLocked,250)}
        </View>

        <View style={{marginTop: '1.2vw',zIndex: -10,zIndex: -8,alignSelf: "center"}}>
          <TouchableOpacity
            onPress={() => {this.addAdvice()}}
            disabled={this.state.isLocked}
          >
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default GeneralPatientAdviceNew;

const styles = StyleSheet.create({
      TableHeadView: { 
        marginTop: '1vw', 
        zIndex: -6, 
        marginBottom: '0.6vw' 
      },
      TextInputStyles: {
        marginLeft:'2.5vw',
        borderColor: color.rightPanelInputBorder,
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "25vw",
        fontSize: ".9vw",
        height:'2vw',
        paddingLeft:'1vw',
        backgroundColor:color.white
    },
    BtnTouch: {
      paddingTop: '0.5vw',
      paddingLeft: '0.5vw',
      paddingRight: '0.5vw',
      paddingBottom: '0.5vw',
      backgroundColor: color.themeDark,
      borderRadius: ".25vw",
      // width: '5vw',
      marginRight:"1vw"
  },
  ButtonText: {
      color: color.white,
      textAlign: 'center',
      fontSize: ".75vw"
  },
    TempBtnTouch: {
      paddingTop: '0.5vw',
      paddingLeft: '0.5vw',
      paddingRight: '0.5vw',
      paddingBottom: '0.5vw',
      backgroundColor: color.themeShade,
      borderRadius: ".25vw",
      // width: '5vw',
      marginRight:"1vw",
      borderWidth:1
  },
  TempButtonText: {
      textAlign: 'center',
      fontSize: ".75vw"
  },
  SelectTemplateView: { 
    flexDirection: 'row', 
    flexWrap: 'wrap',
    marginTop:'0.6vw',
    marginHorizontal:'1.2vw' 
  },
})