import React, { Component } from "react";
import {
  View,
  Text,
  Button,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
// import SideMenu from "../../SideMenu";
import { TabView, SceneMap } from 'react-native-tab-view';
import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
import Service from "../../../network/Service";
import { Constants } from "../../../utils/Constants";
import ADD from "react-native-vector-icons/Ionicons";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const platform = Platform.OS;



const initialLayout = { width: Dimensions.get('window').width };

const dummy = [
  {
    id: 1,
    appointment_id: 1,
    patient_id: 4,
    complaints: 3,
    complaint_description: "complaint description",
    duration_type: 3,
    duration_time: "2 unit",
    comments: "comments"
  }
];

const dummyBillData = [
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
  {
    name: "Radiology",
    date: "03/10/2020",
    View: "View"
  },
];

const heading = [
  ["Duration", "Review Date", "Appointment"],
  ["Date", "Eye", "Comments"],
  ["Eye", "Code", "Diagnosis"]
];

export default class Billing extends Component {

  constructor(props) {
    super(props);


    this.state = {
      currentTime: "",
      filterData: {
        fromDate: "",
        toDate: "",
        patientName: "",
        billNo: "",
        amount: "",
        paymentType: ""
      },
      filterFromDate: "",
      filterToDate: "",
      showCalenderModal: false,
      showCalenderModalFrom: "",
      totalCashAmt: "",
      totalCardAmt: "",
      filterArray: [],
      showTestReports: []
    };

  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        currentTime: moment(new Date()).format("Do MMMM YYYY, HH:mm:ss ")
      });
    }, 1000);

    this.createFilterTag();
    this.getTestReport();
  }





  renderBill(item) {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginLeft: 0,
            marginRight: 30,
            marginBottom: 10,
            height: 60,
            borderRadius: 20,
            paddingLeft: 10,
            paddingRight: 10,
            alignItems: "center",
            backgroundColor: color.white
          }}
        >
          <View
            style={{
              width: "35%",
              flexDirection: "row"
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "500",
                  color: color.black
                }}
              >
                {item.report_type}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: color.black,
                  marginTop: 5,
                  marginBottom: 5
                }}
              >
               { (moment(item.report_date).format("DD/MM/YYYY"))}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "50%",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text
              style={{
                fontSize: 20,
                color: "#9B9B9B"
              }}
            >
              {/* {item.View} */}
              {"View"}
            </Text>
          </View>



        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderBottomColor: '#9B9B9B',
            borderBottomWidth: 1,
            width: "75%",
            marginLeft: 50

          }}
        />
        {/* <View style = {{ height: 1, backgroundColor: color.red, paddingLeft: 30}}/> */}
      </View>
    );
  }
  calculateTotalAmt = () => {
    var fields = this.state;
    var cardTotal = 0;
    var cashTotal = 0;

    dummyBillData &&
      dummyBillData.length > 0 &&
      dummyBillData.map(item => {
        if (item.paymentType === "card") {
          cardTotal = cardTotal + parseInt(item.billAmount);
        } else if (item.paymentType === "cash") {
          cashTotal = cashTotal + parseInt(item.billAmount);
        }
      });

    fields["totalCardAmt"] = renderHeaderView = () => {
      return (
        <View
          style={{
            padding: 20,
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View>
              <Icon
                name="arrow-left"
                size={20}
                color={color.black}
              // onPress = {() => this.props.navigation.goBack()}
              />
            </View>
            <View style={{ marginLeft: 20 }}>
              <Text style={{ fontSize: 20 }}>Roger George</Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "#ADB4C1",
                  marginTop: 5,
                  marginBottom: 5
                }}
              >
                27 years | 25 Apr 1992 | +91 9876543212 | 7:15 AM
                </Text>
              <Text style={{ fontSize: 12, color: "#ADB4C1" }}>
                Last Visit Date : 12 Jan 2020
                </Text>
            </View>
          </View>
          <Text style={{ fontSize: 12, color: "#ADB4C1", marginRight: 50 }}>
            7th February,Friday,17:02:54
             </Text>
        </View>
      );
    }; cardTotal;
    fields["totalCashAmt"] = cashTotal;

    this.setState({ fields });
  };

  createFilterTag = () => {
    var temp = [];
    var fields = this.state;

    if (this.state.filterData.fromDate && this.state.filterData.toDate) {
      temp.push({
        key: "bothDate",
        value:
          this.state.filterData.fromDate + " - " + this.state.filterData.toDate
      });
    }
    if (this.state.filterData.fromDate || this.state.filterData.toDate) {
      if (this.state.filterData.fromDate) {
        temp.push({ key: "fromDate", value: this.state.filterData.fromDate });
      } else {
        temp.push({ key: "toDate", value: this.state.filterData.toDate });
      }
    }
    if (this.state.filterData.patientName) {
      temp.push({
        key: "patientName",
        value: this.state.filterData.patientName
      });
    }
    if (this.state.filterData.billNo) {
      temp.push({ key: "billNo", value: this.state.filterData.billNo });
    }
    if (this.state.filterData.amount) {
      temp.push({ key: "amount", value: this.state.filterData.amount });
    }
    if (this.state.filterData.paymentType) {
      temp.push({
        key: "paymentType",
        value: this.state.filterData.paymentType
      });
    }

    fields["filterArray"] = temp;

    this.setState({ fields });
  };

  renderTotalItem = (img, title, amount) => {
    return (
      <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={img}
            style={{ height: 20, width: 20 }}
            resizeMode="contain"
          />
          <Text style={{ fontSize: 16, color: "#91939F" }}>{title}</Text>
        </View>
        <Text style={{ fontSize: 20 }}>{amount}</Text>
      </View>
    );
  };

  renderIconBtn = (text, key) => {
    return (
      <View
        style={{
          height: 35,
          width: 130,
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 20,
          flexDirection: "row",
          backgroundColor: color.white,
          borderWidth: 1,
          borderColor: color.themePink,
          marginRight: 5,
          marginBottom: 5
        }}
      >
        <Icon
          onPress={() => {
            this.clearFilter(key);
          }}
          name="times"
          size={platform === "web" ? 15 : 20}
          color={color.themePink}
        />
        <Text
          style={{
            fontSize: 10,
            color: color.themePink,
            fontWeight: "700",
            paddingLeft: 5
          }}
        >
          {text}
        </Text>
      </View>
    );
  };

  clearFilter = key => {
    var fields = this.state;

    if (key === "bothDate") {
      fields["filterData"]["fromDate"] = null;
      fields["filterData"]["toDate"] = null;
    } else if (key === "fromDate" || key === "toDate") {
      fields["filterData"][key] = null;
    } else {
      fields["filterData"][key] = "";
    }

    this.setState({ fields });

    this.createFilterTag();
  };

  renderRightBtn = (name, outline) => {
    return (
      <View
        style={{
          height: 35,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          flexDirection: "row",
          borderColor: outline ? color.themePink : color.themeBlue,
          borderWidth: 1,
          backgroundColor: outline ? color.themeBlue : color.themePink,
          padding: 5,
          justifyContent: "space-evenly",
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            color: outline ? color.themePink : color.white,
            fontWeight: "700",
            paddingLeft: 25,
            paddingRight: 25
          }}
        >
          {name}
        </Text>
      </View>
    );
  };

  renderTextField = (title, key, value, halfSize) => {
    return (
      <View
        style={{
          borderColor: "#525252",
          borderWidth: 1,
          marginTop: 5,
          height: 35,
          borderWidth: 1,
          borderColor: color.white,
          borderRadius: 10,
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 10,
          paddingLeft: 10,
          paddingRight: 10,
          width: halfSize ? "50%" : "80%",
          marginRight: halfSize ? 10 : 0
        }}
      >
        <TextInput
          placeholder={title}
          underlineColorAndroid="transparent"
          style={{ color: color.white, width: "90%" }}
          placeholderTextColor={color.white}
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            this.updateState(key, text);
          }}
        />
      </View>
    );
  };

  renderPaymentBtn = (img, title) => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.updateState("paymentType", title.toLowerCase());
        }}
      >
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <Image
            source={img}
            style={{ width: 20, height: 20 }}
            resizeMode="contain"
          />
          <Text style={{ color: color.white, marginTop: 5, fontSize: 10 }}>
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  updateState = (key, value) => {
    var fields = this.state;
    fields["filterData"][key] = value;
    this.setState({ fields });

    this.createFilterTag();
  };
  renderReportHeader = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginLeft: 35,
            marginRight: 30,
            marginBottom: 10,
            borderRadius: 20,
            paddingLeft: 10,
            paddingRight: 10,
            alignItems: "center",
            backgroundColor: color.white
          }}
        >
          <View
            style={{
              width: "30%",
              flexDirection: "row"
            }}
          >
            <View style={{ marginLeft: 5 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: color.black,
                  marginTop: 5,
                  marginBottom: 5,
                  color: "#9B9B9B"
                }}
              >
                Date Visited
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "35%",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 40
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: color.black,
                marginTop: 5,
                marginBottom: 5,

                color: "#9B9B9B"
              }}
            >
              Time
            </Text>
          </View>

          <View
            style={{
              width: "30%",
              alignItems: "center"
            }}
          >
            <Text
              style={{
                fontSize: platform === "web" ? 15 : 20,
                fontWeight: "500",
                color: color.black
              }}
            >
              Medical Reports
            </Text>
          </View>
        </View>
      </View>
    );
  }

  showCalender(date) {
    this.setState({ showCalenderModal: !this.state.showCalenderModal, showCalenderModalFrom: date });
  }
  popUpCalender() {
    if (this.state.showCalenderModal) {

      return (
        <View style={{ width: '80%', position: 'absolute', alignSelf: "center" }}>
          <Calendar
            style={{
              borderWidth: 1,
              borderColor: 'gray',
            }}
            theme={{
              backgroundColor: '#ffffff',
              calendarBackground: 'grey',
              // textSectionTitleColor: 'red',
              selectedDayBackgroundColor: 'yellow',
              selectedDayTextColor: 'yellow',
              todayTextColor: '#099ef9',

              dayTextColor: 'black',
              // textDisabledColor: '#d9e1e8',
              // dotColor: '#00adf5',
              // selectedDotColor: '#ffffff',
              // arrowColor: 'red',
              monthTextColor: 'black',
              textDayFontFamily: 'monospace',
              textMonthFontFamily: 'monospace',
              textDayHeaderFontFamily: 'monospace',
              textMonthFontWeight: 'bold',
              textDayFontSize: 13,
              textMonthFontSize: 13,
              textDayHeaderFontSize: 13
            }}
            // current={'2020-05-23'}
            minDate={this.state.filterFromDate === "" ? "" : this.state.filterFromDate}
            maxDate={this.state.filterToDate === "" ? "" : this.state.filterToDate}
            onDayPress={(day) => {
              var getFilterDate = day.dateString
              this.setState({ showCalenderModal: false });
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: getFilterDate }, () => { this.updateState("fromDate", this.state.filterFromDate) })
              } else {
                this.setState({ filterToDate: getFilterDate }, () => { this.updateState("toDate", this.state.filterToDate) })
              }
            }}
            onDayLongPress={(day) => { console.log('selected day is', day) }}
            // monthFormat={'yyyy MM'}
            onMonthChange={(month) => { }}
            hideArrows={false}
            renderArrow={(direction) => {
              if (direction === "left") {
                return (
                  <Icon
                    size={30}
                    name={"angle-left"}
                  />
                )
              } else if (direction === "right") {
                return (
                  <Icon
                    size={30}
                    name={"angle-right"}
                  />
                )
              }
            }}
            hideExtraDays={false}
            disableMonthChange={false}
            // firstDay={1}
            // hideDayNames={true}
            // showWeekNumbers={true}
            onPressArrowLeft={substractMonth => substractMonth()}
            onPressArrowRight={addMonth => addMonth()}

          />
          <View style={{ justifyContent: 'space-evenly', backgroundColor: 'grey', borderTopWidth: 1, borderTopColor: '#b6b6b6', padding: 10, flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => {
              this.showTodayDateInFilter()
            }}>
              <Text>{"Today"}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
              if (this.state.showCalenderModalFrom === "From") {
                this.setState({ filterFromDate: "", showCalenderModal: false })
              } else {
                this.setState({ filterToDate: "", showCalenderModal: false })
              }
            }}>
              <Text>{"Clear"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View />
      )
    }
  }

  showTodayDateInFilter() {
    if (this.state.showCalenderModalFrom === "From") {
      var toYear = parseInt(this.state.filterToDate.substring(0, 4));
      var toMonth = parseInt(this.state.filterToDate.substring(5, 7));
      var toDate = parseInt(this.state.filterToDate.substring(8, 10));

      var fromYear = parseInt(moment().format("YYYY"));
      var fromMonth = parseInt(moment().format("MM"));
      var fromDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      } else {
        this.setState({ filterFromDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterToDate: "" },
          () => {
            this.updateState("fromDate", this.state.filterFromDate);
          })
      }
    } else {
      var fromYear = parseInt(this.state.filterFromDate.substring(0, 4));
      var fromMonth = parseInt(this.state.filterFromDate.substring(5, 7));
      var fromDate = parseInt(this.state.filterFromDate.substring(8, 10));

      var toYear = parseInt(moment().format("YYYY"));
      var toMonth = parseInt(moment().format("MM"));
      var toDate = parseInt(moment().format("DD"));

      if (fromYear >= toYear && fromMonth >= toMonth && fromDate <= toDate) {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      } else {
        this.setState({ filterToDate: moment().format("YYYY-MM-DD"), showCalenderModal: false, filterFromDate: "" },
          () => {
            this.updateState("toDate", this.state.filterToDate);
          })
      }
    }
  }



  getTestReport = async () => {
    var patientId = "16663"
    var serviceUrl;
    if (this.state.filterFromDate && this.state.filterToDate) {
      serviceUrl = Constants.BASE_DOMAIN + "doctor/patient/" + patientId + "/test-reports/?start_date=" + this.state.filterFromDate + "&end_date=" + this.state.filterToDate
    } else if (this.state.filterFromDate) {
      serviceUrl = Constants.BASE_DOMAIN + "doctor/patient/" + patientId + "/test-reports/?start_date=" + this.state.filterFromDate
    } else if (this.state.filterToDate) {
      serviceUrl = Constants.BASE_DOMAIN + "doctor/patient/" + patientId + "/test-reports/?end_date=" + this.state.filterToDate
    } else {
      serviceUrl = Constants.BASE_DOMAIN + "doctor/patient/" + patientId + "/test-reports/"
    }

    Service.getInstance().billingList(
      serviceUrl,
      //  this.state.appendUrl,
      this,
      this.successBillingList,
      this.failureBillingList
    );
  }

  successBillingList = response => {
    if (response.status === "success") {
      this.setState({
        showTestReports: response.data
      })
    }

  }
  failureBillingList = response => {

  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={() => { this.setState({ showCalenderModal: false }) }}>
        <View style={Style.rootView}>
          {/* side menu */}

          {/* first view */}
          <View style={Style.leftReportView}>
            <View
              style={{
                marginTop: platform === "web" ? 0 : 20,
                width: "100%",
                marginLeft: 30
              }}
            >




              {/* search tag view */}
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ marginTop: 20, marginBottom: 20, width: '25%' }}>
                  <View>


                    <DatePicker
                      onChange={date => {
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");
                        // this.setState({ filterFromDate: converted_date })

                        this.setState({ filterFromDate: converted_date }, () => { this.updateState("fromDate", this.state.filterFromDate) })
                      }}
                      // defaultValue={"From"}
                      format={"YYYY-MM-DD"}
                      placeholder={"From"}
                      style={{
                        borderColor: color.themeDark, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderColor: color.themeDark, borderRadius: 10,
                        alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10
                      }}
                    />


                    <DatePicker
                      onChange={date => {
                        var fulldate = new Date(date);
                        var converted_date = moment(fulldate).format("YYYY-MM-DD");

                        this.setState({ filterToDate: converted_date }, () => { this.updateState("toDate", this.state.filterToDate) })
                      }}
                      // defaultValue={"From"}
                      format={"YYYY-MM-DD"}
                      placeholder={"To"}
                      style={{
                        borderColor: color.themeDark, borderWidth: 1, marginTop: 5, height: 35, borderWidth: 1, borderColor: color.themeDark, borderRadius: 10,
                        alignItems: "center", flexDirection: "row", paddingLeft: 10, paddingLeft: 10, paddingRight: 10, width: "50%", marginRight: 10
                      }}
                    />

                    {/* <TouchableOpacity
                      style={styles.calendarView}
                      onPress={() => { this.showCalender("From") }}><Text
                        style={styles.calendarText}
                      >{this.state.filterFromDate === "" ? "From" : this.state.filterFromDate}</Text>
                      <Icon
                        size={10}
                        name={"calendar"}
                      />
                    </TouchableOpacity>
                    <View style={{ marginTop: 10 }}>
                      <TouchableOpacity
                        style={styles.calendarView}
                        onPress={() => { this.showCalender("To") }}><Text
                          style={styles.calendarText}
                        >{this.state.filterToDate === "" ? "To" : this.state.filterToDate}</Text>
                        <Icon
                          size={10}
                          name={"calendar"}
                        />
                      </TouchableOpacity> */}
                    {/* </View> */}
                  </View>
                  {this.popUpCalender()}
                  <View style={{ marginTop: 20, width: "30%", marginLeft: 35, zIndex: -1 }}>
                    {/* <Button
                      title="Search"
                      color={color.themeDark}
                      onPress={() => { this.getTestReport() }}
                    /> */}

                    <TouchableOpacity
                      style={{
                        height: 40,
                        backgroundColor: color.themeDark,
                        borderRadius: 30,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      onPress={() => { this.getTestReport() }}
                    >
                      <Text style={{ color: color.white }}>Search</Text>
                    </TouchableOpacity>
                  </View>

                </View>
                <View
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: '#9B9B9B',
                  }}
                />

                <View style={{ marginTop: 20, marginBottom: 20, width: '75%', height: '65%' }}>
                  <ScrollView>

                    {/* <FlatList

                      data={dummyBillData}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      renderItem={({ item }) => this.renderBill(item)}
                    /> */}

                    <FlatList

                      data={this.state.showTestReports}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      renderItem={({ item }) => this.renderBill(item)}
                    />

                  </ScrollView>
                  <View>
                    <TouchableOpacity style={{ alignItems: "center" }}>
                      <View
                        style={{
                          height: 100,
                          width: 100,
                          marginRight: 100,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 10
                        }}
                      >
                        {/* <Image
                          source={require("../../../../assets/images/add.svg")}
                          style={{ width: 100, height: 100 }}
                          resizeMode="contain"
                        /> */}
                        <ADD
                          name="md-add"
                          color={"white"}
                          size={20}
                          style={{ backgroundColor: color.themeDark, borderRadius: 8, paddingTop: 8, paddingBottom: 8, paddingRight: 13, paddingLeft: 13 }}
                        />
                      </View>

                    </TouchableOpacity>

                  </View>


                </View>
              </View>


              {/* total amount view */}

            </View>

          </View>


        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  calendarView: {
    borderColor: color.themeDark,
    borderWidth: 1,
    marginTop: 5,
    height: 35,
    borderWidth: 1,
    borderColor: color.themeDark,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: "50%",
    marginRight: 10
  },
  calendarText: {
    color: color.black,
    width: "90%"
  }
})