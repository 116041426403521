import React, { Component } from 'react';
import { View, Text, Dimensions, TouchableOpacity, FlatList, Image,ImageBackground, ScrollView } from 'react-native';
import { color } from '../../../styles/Color';
import BaseComponentStyle from "../BaseComponentStyle";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

export class ShowDocumentImage extends BaseComponentStyle {
    constructor(props) {
        super(props);

        let currentIndex = 0;
        let selectedImage = this.props.selectedImage;
        let documentImgList = this.props.documentImgList?this.props.documentImgList:[];
        let len = documentImgList?documentImgList.length:0;

        for( let i=0; i<len; i++ ) {

            if(selectedImage == documentImgList[i]['doc_key']) 
            {
                currentIndex = i;
            }
        }

        this.state = {
            selectedImage: this.props.selectedImage,
            documentImgList: this.props.documentImgList,
            listLength: len,
            currentIndex: currentIndex
        };

        this._openNextImage = this._openNextImage.bind(this)
        this._openPrevImage = this._openPrevImage.bind(this)
    }

    render() { 

        let { currentIndex } = this.state;
        let imageKeyList = [];

        imageKeyList = this.state.documentImgList && this.state.documentImgList.map((item)=>{
            return item.doc_key
        })

        return (
            <View style={{ position: 'absolute' }}>
                <View style={{ position: 'absolute', zIndex: -1, height: screenHeight, width: screenWidth, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{
                        width: '70%', height: "70%", 
                        borderRadius: 35,  paddingHorizontal: 50
                    }}>
                        
                        <TouchableOpacity onPress={()=>{this.props.showDocumentImage(false)}} style={{backgroundColor:color.themeDark,borderRadius:50,height:40,width:40,justifyContent:"center",alignSelf:'flex-end',position:'absolute'}}>
                            <Text style={{color:"white",fontWeight:'600',textAlign:'center'}} >X</Text>
                        </TouchableOpacity>
                        
                        <Image     
                         source={{uri:this.state.selectedImage}}
                         style={{ width: "100%", height: "100%" ,zIndex:-2}}
                        //  resizeMode="stretch" // image will cover based on widgt and height
                         resizeMode="contain" // image will cover based on widgt and height
                        />
                       
                        <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 15 }} >
                            <TouchableOpacity onPress={()=>this._openPrevImage(currentIndex)} >
                                {this.renderTextBtn("Prev", true, false)}
                            </TouchableOpacity>
                            <TouchableOpacity onPress={()=>this._openNextImage(currentIndex)} >
                                {this.renderTextBtn("Next", true, false)}
                            </TouchableOpacity>
                        </View>

                    </View>
                    
                </View>
            </View>
        );
    }

    _openNextImage=currentIndex=>{

        // alert("_openNextImage");

        let { documentImgList, listLength } = this.state; 

        console.log(" _openNextImage ")

        currentIndex = parseInt(currentIndex) || 0

        console.log(" currentIndex " + currentIndex)
        console.log(" listLength " + listLength)

        if( (currentIndex+1) < listLength ) {

            console.log(" documentImgList[currentIndex+1] " + documentImgList[currentIndex+1])

            this.setState({
                currentIndex: currentIndex+1,
                selectedImage: documentImgList[currentIndex+1]['doc_key']
            })
        }
    }

    _openPrevImage=currentIndex=>{

        let { documentImgList, listLength } = this.state; 

        console.log(" _openNextImage ")

        currentIndex = parseInt(currentIndex) || 0

        console.log(" currentIndex " + currentIndex)
        console.log(" listLength " + listLength)

        if( currentIndex>=1 ) {

            console.log(" documentImgList[currentIndex-1] " + documentImgList[currentIndex-1])

            this.setState({
                currentIndex: currentIndex-1,
                selectedImage: documentImgList[currentIndex-1]['doc_key']
            })
        }
    }
}


// import ImageView from "react-native-image-viewing";
{/* <ImageView
                        images={imageKeyList}
                        imageIndex={0}
                        visible={visible}
                        onRequestClose={() => { 
                            //setIsVisible(false) 
                            this.props.showDocumentImage(false)
                        }}
                        /> */}
                        