import React from "react";
import {
  View,
  // Text,
  // FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import AsyncStorage from "../../../AsyncStorage";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'
import Style from "../../../styles/Style";

const platform = Platform.OS;
const pageName = "ocularsurgery"

export default class OcularSurgeryNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      favoriteDrugs: [],
      selectedEye: {},
      ocularsurgerySelectedItems:[],
      id: null,
      surgery_name: "",
      no_of_years: "",
      no_of_months: "0",
      no_of_days: "0",
      reason: "",
      isEdit: false,
      newItem: '',
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,
      isDisable:false,
      selectedClinicOrDoctor:this.props.selectedClinicOrDoctor,
      userType:this.props.userType
    };
  }

  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getSurgeryList();
  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {


          // selectedEye have to set up here
          let selectedEye = "";

          favoriteDrugs.map((favItem) => {
            if (favItem.label === item.surgery_name) {
              // selectedEye = favItem.value;
              selectedEye = favItem;
            }
          })

          this.setState({
            id: item.id,
            selectedEye: selectedEye,
            surgery_name: item.surgery_name,
            no_of_years: item.no_of_years,
            no_of_months: item.no_of_months,
            no_of_days: item.no_of_days,
            reason: item.reason
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  getSurgeryList = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_SURGERY;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
    );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];
      let data = response.data;

      if (data && response.data.map) {

        data.map((item) => {

          drugs.push({ value: item, label: item });
        });
      }

      // // for temp
      // if(drugs.length === 0) {

      //   drugs = [{ "value": "Surgery One", "label": "Surgery One" }];
      // }

      var field = this.state;
      field["favoriteDrugs"] = drugs;
      this.setState({ field });

    }
  }

  addComplaint = event => {

    let errorMessage = "";

    this.setState({
      isDisable:true
    })
    let states = this.state;
    var service_url = Constants.OPTHALMOLOGY_SURGERY;

    let drug_name = "";

    states.favoriteDrugs.map((item) => {

      if (item.value === states.selectedEye.value) {

        drug_name = item.label;
      }
    });

    let data = {
      "patient_id": this.state.patientAppointment.patient_id,
      'appointment_id': this.state.patientAppointment?.appointment_id,
      // "surgery_name": drug_name,
      "no_of_years": states.no_of_years ? states.no_of_years :0,
      "no_of_months": states.no_of_months ? states.no_of_months :0 ,
      "no_of_days": states.no_of_days ? states.no_of_days : 0,
      "reason": states.reason
    };

    if(this.state.userType == Constants.ROLE_OPTOMETRY){
      data["doctor_id"]=this.state.selectedClinicOrDoctor.id
    }

    if (states.id) {
      data['id'] = states.id;
    }


    if(states.id){
      data["surgery"]=[drug_name];
    }else{
      var datas=[]
      for(var i=0;i<states.ocularsurgerySelectedItems.length;i++){
        datas.push(states.ocularsurgerySelectedItems[i].value)
      }
      data["surgery"]=datas;
    }

    // validate
    if(!data["surgery"] || data["surgery"].length===0) {    
      errorMessage = "Please choose surgery";
    } 
    else if(!states.no_of_years || parseInt(states.no_of_years)===0) {    
      errorMessage = "Please enter year";
    } 
    
    // throw warning
    if(errorMessage)
    {
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error", errorMessage);
    }else{
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getMedicationSuccess,
        this.getMedicationFailure
      );
    }
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getMedicationSuccess = response => {
    if (response.status === "success") {
      this.setState({
        isDisable:false
      })
      this.props.clearEditedItem(pageName, {})

      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });


      // this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData(pageName);
      this.props.showResposeValue("success", response.message)
    } else {
      this.setState({
        isDisable:false
      })
      this.props.showResposeValue("error", response.message)

    }
  };

  getMedicationFailure = error => {
    this.setState({
      isDisable:false
    })
    this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message)
  };

  clearComplaint = event => {

    let states = this.state;
    states['ocularsurgerySelectedItems']= [];
    states['selectedEye'] = {};
    states['surgery_name'] = "";
    states['no_of_years'] = "";
    states['no_of_months'] = "";
    states['no_of_days'] = "0";
    states['reason'] = "";
    states["id"] = ""

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  addToSuggestionList = (event) => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if (newItem) {

      favoriteDrugs.push({ value: newItem, label: newItem });

      field["favoriteDrugs"] = favoriteDrugs;
      field["selectedEye"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}

  onPressButton(key, selectedvalue) {
    if (key == "selectedEye" || key == "ocularsurgerySelectedValue") {

      if (this.state.id) {
        var { selectedEye } = this.state;
        selectedEye = selectedvalue;
        this.setState({ selectedEye: selectedvalue })
        // alert(JSON.stringify(selectedEye))
      } else {
        var { ocularsurgerySelectedItems } = this.state;

        var getSelectedValue;
        var spliceData;

        if (ocularsurgerySelectedItems.length > 0) {
          for (var i = 0; i < ocularsurgerySelectedItems.length; i++) {
            if (ocularsurgerySelectedItems[i].value == selectedvalue.value) {
              spliceData = ocularsurgerySelectedItems[i];
              ocularsurgerySelectedItems.splice(i, 1)
              var data = this.removeDuplicate(ocularsurgerySelectedItems);
              this.setState({ ocularsurgerySelectedItems: data })
              return
            } else {
              if (spliceData && spliceData.value !== selectedvalue.value) {
              } else {
                getSelectedValue = selectedvalue
              }
            }
          }

          if (getSelectedValue) { ocularsurgerySelectedItems.push(selectedvalue); }
        } else {
          ocularsurgerySelectedItems.push(selectedvalue)
        }

        var data = this.removeDuplicate(ocularsurgerySelectedItems);
        this.setState({ ocularsurgerySelectedItems: data })

      }

    }
  }

  render() {

    return (
  <TouchableWithoutFeedback onPress={this.addToSuggestionList.bind(this)}>

      
      <View>

        {/* {this.renderSubHeadingBorder("Ocular History", false, true)} */}
        <DoctorNotesCommonRightSideHeader title={"Surgery"} clearAllData={this.clearComplaint.bind(this)}/>
        {/* <CommonSectionHeader heading={"Surgery"}  /> */}
        {this.renderSubHeadingBorder("", false)}

        {/* {this.renderTitleWithMultipleBtn("Surgery", this.state.favoriteDrugs, 2, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), true, true,"","",true)} */}
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.favoriteDrugs.map((item, index) => {
            // var data = {
            //     label: item.speciality_name,
            //     value: item.id
            // }
            var data
            if(this.state.id){
              data = this.state.selectedEye
            }else{
              data = this.state.ocularsurgerySelectedItems
            }

            // console.log("json item----> "+JSON.stringify(item))
            if(item.label && item.value){
            return (
              <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={data}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={this.state.id ? "selectedEye" :"ocularsurgerySelectedValue"} />
              </View>
            )
            }
          })
        }
        </View>

        {/* {this.renderSubHeadingBorder("Surgery")}

        <FlatList
          data={this.state.favoriteDrugs}
          numColumns={2}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center" }}
          renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)} 
        //   extraData={this.state.selectedEye}
        /> */}

        {/* {this.renderIconBtn("plus", "New", true)} */}
        {this.state.isEdit ?
          <View
            style={{
              height: 30,
              marginTop: 10,
              // backgroundColor: color.white,

              width: 150
            }}
          ><TextInput
              underlineColorAndroid="transparent"
              placeholder="type here"
              placeholderTextColor={color.black}

              style={[{
                // borderRadius: 20,
                borderWidth: 1,
                borderColor: color.black,
                color: color.black,
                height: 30,
                paddingLeft: 5,
                marginRight: 0,
                flex: 1,
                textAlign: "center"
              },Style.allButtonBorderRadius]}
              ref={text => (this.nameInput = text)}
              // defaultValue=""
              autoCapitalize="none"
              value={this.state.newItem}
              onChangeText={text => this.setState({ selectedEye: '', newItem: text })}
              onSubmitEditing={this.addToSuggestionList.bind(this)}
            /></View> :
          <TouchableOpacity style={{alignSelf:'flex-start'}} onPress={() => { this.triggerNewItemAdd() }} disabled={this.state.isLocked}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
          // this.renderIconNewBtn("plus", "New", true, true, this.triggerNewItemAdd.bind(this))
        }

       
        <View
          style={{
          
            marginTop: 30,
            marginBottom: 30
            // , justifyContent: "space-evenly"
          }}
        >
           <CommonSectionHeader heading={"Year"}  />
          {this.renderNumberTxtWithHeader("", "no_of_years", this.state.no_of_years|| "",this.state.isLocked, 'yyyy')}

          {/* {this.renderNumberTxtWithHeader("Months", "no_of_months", this.state.no_of_months || 0)}

          {this.renderNumberTxtWithHeader("Days", "no_of_days", this.state.no_of_days || 0)} */}

        </View>

        {/* {this.renderTextFieldView("reason")} */}
        <CommonSectionHeader heading={"Comments"}  />
        <View style={{marginTop:20}}>
           {this.renderTextFieldView("Reason", "reason", this.state.reason, true, 4,'',{},"",false,this.state.isLocked)}
        </View>
        <View style={{ marginTop: 20 ,alignSelf:"center" }}>
          <TouchableOpacity disabled={(this.state.isDisable || this.state.isLocked) ? true : false} onPress={this.addComplaint.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>

        <View>
          {/* {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Surgery  Created Successfully' : 
                    this.state.errorMessageFlag ?'Surgery  Created Failed' : null}</Text>
                </View>
              ) :null} */}
        </View >
      </View>
      </TouchableWithoutFeedback>

    );
  }
}