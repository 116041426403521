//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { OBGYNHistorySectionHeading ,HistorySectionRightSideUI} from '../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";

// create a component
const pageName = "isGynaecologicalHistory"

class GynaecologicalHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            checkOvarianCysts: {},
            checkFibroidsOfTheUterus: {},
            checkYeastInfection: {},
            checkBacterialVaginosis: {},
            checkBacterialVaginosisCommentBox: "",
            checkBleedingSpotting: {},
            checkBleedingSpottingCommentBox: "",
            checkUrinaryProblem: {},

            selectedMenopauseAge: "",

            checkUrinaryProblemCommentBox: "",
            checkHormoneReplacementCommentBox: "",
            checkGynaecologicalHistory: "",

            gynaecologicalHistoryList: {}
        }
    }

    componentDidMount() {
        this.getGynaecologicalHistory()
    }
    getGynaecologicalHistory() {
        var service_url = Constants.GYNAECOLOGICAL_HISTORY + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getGynaecologicalHistorySuccess,
            this.getGynaecologicalHistoryFailure
        );
    }

    checResponceIsFlag(type) {
        if (type || type == "yes")
            return { label: "Yes", value: "Yes" }
        else
            return { label: "No", value: "No" }
    }

    getGynaecologicalHistorySuccess = success => {

        if (success.status == "success") {
            var data = success.data.gynaecology_history;
            var notApplicable = ""
            var states = this.state;
            if (Object.keys(data).length > 0) {
                states["checkOvarianCysts"] = this.checResponceIsFlag(data.had_ovarian_cysts)
                states["checkFibroidsOfTheUterus"] = this.checResponceIsFlag(data.had_fibroids_uterus)
                states["checkYeastInfection"] = this.checResponceIsFlag(data.had_yeast_infection)
                states["checkBacterialVaginosis"] = this.checResponceIsFlag(data.had_bacterial_vaginosis)
                states["checkBacterialVaginosisCommentBox"] = data.bacterial_vaginosis_desc
                states["checkBleedingSpottingCommentBox"] = data.bleeding_intercourse_desc
                states["checkBleedingSpotting"] = this.checResponceIsFlag(data.is_bleeding_after_intercourse)
                states["checkUrinaryProblem"] = this.checResponceIsFlag(data.had_urinary_problems)
                states["selectedMenopauseAge"] = data.menopause_occured_age
                states["checkUrinaryProblemCommentBox"] = data.urinary_problems_desc
                states["checkHormoneReplacementCommentBox"] = data.used_hormone_replacement
                states["checkGynaecologicalHistory"] = data.other_gyn_history

                states["gynaecologicalHistoryList"] = data
            } else {
                states["checkOvarianCysts"] = notApplicable;
                states["checkFibroidsOfTheUterus"] = notApplicable;
                states["checkYeastInfection"] = notApplicable;
                states["checkBacterialVaginosis"] = notApplicable;
                states["checkBacterialVaginosisCommentBox"] = notApplicable;
                states["checkBleedingSpottingCommentBox"] = notApplicable;
                states["checkBleedingSpotting"] = notApplicable;
                states["checkUrinaryProblem"] = notApplicable;
                states["selectedMenopauseAge"] = notApplicable;
                states["checkUrinaryProblemCommentBox"] = notApplicable;
                states["checkHormoneReplacementCommentBox"] = notApplicable;
                states["checkGynaecologicalHistory"] = data.other_gyn_history

                states["gynaecologicalHistoryList"] = data
            }
            this.setState({ states })

        }

        // console.log("success ++++> " + JSON.stringify(success))
    }
    getGynaecologicalHistoryFailure = error => {
        console.log("error ++++> " + JSON.stringify(error))
    }


    renderContent(heading, value) {
        return (
            <View>
                                <HistorySectionRightSideUI heading={heading} value={value} />

                {/* <Text style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 8 }}>{heading}</Text>
                <Text style={{ fontSize: 14 }}>{value}</Text> */}
            </View>
        );
    }

    renderHistorySectionFields(rightHeader, rightValue, leftHeader, leftValue) {
        return (
            <View style={{ flexDirection: 'row'}}>
                <View style={{ flex: 0.45 }}>
                    {this.renderContent(rightHeader, rightValue)}
                </View>
                <View style={{ flex: 0.55 }}>
                    {this.renderContent(leftHeader, leftValue)}
                </View>
            </View>
        )
    }

    renderHistorySection() {
        var states = this.state;

        // var checkYeastInfection = states.checkYeastInfection.value ? "Yes" : "No";
        // var checkOvarianCysts = states.checkOvarianCysts ? "Yes" : "No";
        // var checkFibroidsOfTheUterus = states.checkFibroidsOfTheUterus ? "Yes" : "No";
        // var checkBacterialVaginosis = states.checkBacterialVaginosis ? "Yes" : "No";
        // var checkBleedingSpotting = states.checkBleedingSpotting ? "Yes" : "No";
        // var checkUrinaryProblem = states.checkUrinaryProblem ? "Yes" : "No";

        var selectedMenopauseAge = states.selectedMenopauseAge;
        var checkBleedingSpottingCommentBox = states.checkBleedingSpottingCommentBox;
        var checkBacterialVaginosisCommentBox = states.checkBacterialVaginosisCommentBox;
        var checkUrinaryProblemCommentBox = states.checkUrinaryProblemCommentBox;
        var checkHormoneReplacementCommentBox = states.checkHormoneReplacementCommentBox;
        var checkGynaecologicalHistory = states.checkGynaecologicalHistory;

        return (
            <View style={{marginTop:10}}>
                {this.renderHistorySectionFields(
                    "Have you ever had a yeast infection?",
                    states.checkYeastInfection.value,
                    "Have you ever been diagnosed with any fibroids in the uterus?",
                    states.checkFibroidsOfTheUterus.value
                )}

                {this.renderHistorySectionFields(
                    "Have you ever had an ovarian cyst?",
                    states.checkOvarianCysts.value,
                    "Have you ever been treated for vaginal bacterial infection   (bacterial vaginosis)?",
                    (states.checkBacterialVaginosis.value ? states.checkBacterialVaginosis.value : "") + (states.checkBacterialVaginosis.value && checkBacterialVaginosisCommentBox ? ", " : "") + checkBacterialVaginosisCommentBox
                )}

                {this.renderHistorySectionFields(
                    "Does bleeding / spotting occur after intercourse?",
                    (states.checkBleedingSpotting.value ? states.checkBleedingSpotting.value : '')
                    + (states.checkBleedingSpotting.value ? ", " : "" )
                    + ("") + (checkBleedingSpottingCommentBox ? checkBleedingSpottingCommentBox : ''),
                    // states.checkBleedingSpotting.value ? states.checkBleedingSpotting.value :"" + states.checkBleedingSpotting.value && checkBleedingSpottingCommentBox?",":"" + checkBleedingSpottingCommentBox,
                    "Did you have any urinary problems such as UTI(Urinary Tract Infection), Urinary Frequency, Loss of bladder control, blood in urine, etc..",
                    (states.checkUrinaryProblem.value ? states.checkUrinaryProblem.value : "") + (states.checkUrinaryProblem.value && checkUrinaryProblemCommentBox ? ", " : "") + checkUrinaryProblemCommentBox
                )}

                {this.renderHistorySectionFields(
                    "If in menopause, at what age did it occur?",
                    selectedMenopauseAge,
                    "Have you used any hormone replacement?",
                    checkHormoneReplacementCommentBox ? checkHormoneReplacementCommentBox : ""
                )}

                {this.renderHistorySectionFields("Other Gynaecological history", checkGynaecologicalHistory)}

            </View>
        )
    }


    selectedRightSideView(pageName) {
        this.props.selectedRightSideView(pageName);
    }

    componentWillReceiveProps(props) {
        // if(props.selectedPageName !=pageName){
        //     this.setState({
        //         selectedPageName:props.selectedPageName
        //     })
        // }
        // console.log("test ************> " + JSON.stringify(props))
        this.state.selectedPageName = props.selectedPageName
        if (props.refreshRighSideComponentName == pageName) {
            this.getGynaecologicalHistory();
            this.props.refreshRighSideComponent("");
        }
    }
    editHistory() {
        this.props.editHistory(this.state.gynaecologicalHistoryList, pageName)

    }
    render() {
        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading

                    editHistory={this.editHistory.bind(this)}

                    selectedRightSideView={this.selectedRightSideView.bind(this)}
                    selectedPageName={this.state.selectedPageName}
                    pageName={pageName}
                    heading={"Gynaecological History"}
                    editImage={this.state.checkOvarianCysts ? "pencil" : ''}
                    // deleteImage={"trash"}
                />
                {this.renderHistorySection()}
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // marginTop: 20
        // flex: 1,
    },
});

//make this component available to the app
export default GynaecologicalHistory;
