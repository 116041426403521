import React, { Component } from "react";
import { Text, View, Dimensions, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet,Picker } from "react-native";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader, NavigationTopHeader } from "../BaseComponent";
import IpServiceConfig from "./adminServiceConfig/IpServiceConfig";
import OpServiceConfig from "./adminServiceConfig/OpServiceConfig";
import AddIcon from '../../../../assets/images/BillConfig/Add.svg'
import CancelIcon from '../../../../assets/images/BillConfig/Cancel.svg'
import OpTemplateConfig from "./adminServiceConfig/OpTemplate";
import IpTemplateConfig from "./adminServiceConfig/IpTemplate";
import { message, Select } from "antd";
import "../../../ui/Home.css";
import AsyncStorage from "../../../AsyncStorage";
import DeleteIcon from '../../../../assets/images/BillConfig/delete.svg'
import EditIcon from '../../../../assets/images/BillConfig/Edit.svg'
import { ListItem } from "react-native-elements";

const screenHeight = Dimensions.get("window").height;

let defaultNewEntry = {
"type": "",
"amount": "",
};

let defaultIpEntry = {
    "service_name": "",
    "rate_per_unit": "",
};

export default class AdminConfiguration extends Component {
    constructor(props) {
      super(props);
      const ClinicType = JSON.parse(localStorage.getItem('loggedInData')) ? JSON.parse(localStorage.getItem('loggedInData')).clinic_type == undefined ? {}: JSON.parse(localStorage.getItem('loggedInData')).clinic_type : {};
      
      this.state = {
        selectedTab: "opServiceConfig",
        opNewEntrys: [
            defaultNewEntry
        ],
        userType: this.props.userType,
        selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
        service_name: "",
        refreshOpData: false,
        ipNewEntrys:[
            defaultIpEntry
        ],
        isDeleteClick: false,   
        deletedIpId: null,
        popupKey: "",
        popupType: "",
        confirmDeletedIpId: null,
        deletedOpId: null,
        deletedOpAllId: [],
        confirmDeletedOpId: null,
        confirmDeletedOpAllId: [],
        OpSearchKey: "",
        IpSearchKey: "",
        refreshAdminData: false,
        isOPT: ClinicType.is_opt_clinic == undefined || ClinicType.is_opt_clinic === false ?  false  : true,

        opServiceNameAndServiceType:[],
        opServiceName:"",
        opTemplateName:"",
        opServiceType:"",
        listOfOpServiceType: [],
        isOpTemplateEdit:false,
        opTemplateList:[],
        opTemplateId:"",
        opTemplateSearchKey:"",
        opTemplateRefresh:false,
        opServiceId:"",

        ipServiceNameAndServiceType:[],
        ipTemplateName:"",
        ipServiceName:"",
        ipServiceType:"",
        listOfIpServiceType:[],
        ipTemplateSearchKey:"",
        isIpTemplateEdit:false,
        ipTemplateId:"",
        ipTemplateRefresh:false,

        opListData:[],
        IpListData:[],
        opIds:[],
        ipIds:[],
        hideOpServiceName:false,
        opListId:0,
        opServiceSelected:{}
      }
    }

    componentDidMount(){
        this.getOpServiceNameAndServcieType()
        this.getIpServiceNameAndServcieType()
        this.getOpTemplateNameAndDetails()

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.refreshAdminData != this.state.refreshAdminData ){
            this.setState({
                refreshAdminData: true
            })
            this.onPressClear()
        }
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }

    changeTabView(key) {
        this.setState({
          selectedTab: key,
          OpSearchKey: "",
          IpSearchKey: "",
          opTemplateSearchKey:"",
          ipTemplateSearchKey:"",
          opTemplateName:"",
          opServiceName:"",
          opServiceType:"",
          opListData:[],
          isOpTemplateEdit:false,
          ipTemplateName:"",
          ipServiceName:"",
          IpListData:[],
          isIpTemplateEdit:false,
          opServiceNameAndServiceType:this.getOpServiceNameAndServcieType(),
          ipServiceNameAndServiceType:this.getIpServiceNameAndServcieType(),
          ipTemplateRefresh:false,
          opTemplateRefresh:false,
        },() => {
            this.onPressClear()
        })
    }

    alphabetvalidationWithMajorSplChar(num) {
        return /^[0-9 a-z A-Z.,_-]+$/.test(num);
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    showResposeValue = (title, message) => {
        this.props.showResposeValue(title, message)
    }

    editOPItem = (data) => {
        if(data.service_name != this.state.service_name ){
            this.setState({
                service_name: data.service_name,
                opNewEntrys: data.data
            })
        }

    }

    editIPItem = (data) => {
        if(data.service_name != this.state.service_name ){
            this.setState({
                ipNewEntrys: [data]
            })
        }
    }

    editOpTemplate = (data) =>{
        if(data.template_name !== this.state.opTemplateName){
        this.setState({
            opTemplateId : data.id,
            opTemplateName : data.template_name,
            opListData : data.service_data,
            isOpTemplateEdit:!this.state.isOpTemplateEdit
         })
        }
    }

    editIpTemplate = (data) =>{
        if(data.template_name !== this.state.ipTemplateName){
            this.setState({
                ipTemplateId: data.id,
                ipTemplateName:data.template_name,
                IpListData:data.service_data,
                isIpTemplateEdit:!this.state.isIpTemplateEdit
            })
        }
    }
   
    renderComponent = () => {
        var selectedComponent = this.state.selectedTab

        var component = {
            opServiceConfig:(
                <OpServiceConfig 
                userType = {this.state.userType}
                showResposeValue = {this.showResposeValue.bind(this)}
                editOPItem = {this.editOPItem}
                refreshOpData = {this.state.refreshOpData}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                isDeleteClick = {this.isDeleteClick}
                confirmDeletedOpId = {this.state.confirmDeletedOpId}
                confirmDeletedOpAllId = {this.state.confirmDeletedOpAllId}
                searchKey={this.state.OpSearchKey}
                />
            ) ,
            ipServiceConfig:(
                <IpServiceConfig
                userType = {this.state.userType}
                showResposeValue = {this.showResposeValue.bind(this)}
                editIPItem = {this.editIPItem}
                refreshOpData = {this.state.refreshOpData}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                isDeleteClick = {this.isDeleteClick}
                confirmDeletedIpId = {this.state.confirmDeletedIpId}
                searchKey={this.state.IpSearchKey}
                />
            ),
            opTemplate:(
                <OpTemplateConfig
                showResposeValue = {this.showResposeValue.bind(this)}
                searchKey={this.state.opTemplateSearchKey}
                editOpTemplate={this.editOpTemplate}
                isOpTemplateEdit={this.state.isOpTemplateEdit}
                opTemplateRefresh = {this.state.opTemplateRefresh}
                userType = {this.state.userType}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                />
            ),
            ipTemplate:(
                <IpTemplateConfig
                showResposeValue = {this.showResposeValue.bind(this)}
                searchKey={this.state.ipTemplateSearchKey}
                editIpTemplate={this.editIpTemplate}
                ipTemplateRefresh = {this.state.ipTemplateRefresh}
                isIpTemplateEdit = {this.state.isIpTemplateEdit}
                userType = {this.state.userType}
                selectedClinicOrDoctor={this.state.selectedClinicOrDoctor}
                />
            )
        }
        return (
            <View style={{height: "100%"}}>
              {component[selectedComponent]}
            </View>
        )
    }

    isDeleteClick = (data, key, type) => {
        if(key === "ip"){
            this.setState({
                deletedIpId : data,
                isDeleteClick: true,
                popupKey : key
            })
            if(type === "revert"){
                this.setState({
                    deletedIpId : null,
                    isDeleteClick: false,
                    popupKey : "",
                    confirmDeletedIpId: null,
                })  
            }
        }else if (key === "op"){
            if(type === "revert"){
                this.setState({
                    deletedOpId : null,
                    isDeleteClick: false,
                    popupKey : "",
                    popupType: "",
                    confirmDeletedOpId: null,
                    deletedOpAllId: [],
                    confirmDeletedOpAllId: [],
                })  
            }else if(type === "service"){
                this.setState({
                    deletedOpAllId : data,
                    isDeleteClick: true,
                    popupType: type,
                    popupKey : key
                })  
            }else{
                this.setState({
                    deletedOpId : data,
                    isDeleteClick: true,
                    popupKey : key
                })
            }
        }
    }

    renderOpTypeNewEntryView = (newEntry, index) => {
        let amount = null
        let type = null
        let id = null
        let { opNewEntrys } = this.state

        type = opNewEntrys[index] ? opNewEntrys[index].type : ""
        amount = opNewEntrys[index] ? opNewEntrys[index].amount : ""
        id = opNewEntrys[index] && opNewEntrys[index].id ? opNewEntrys[index].id : null

        return(
            <View style={{flexDirection: "row", marginVertical: 15,}}>
            <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', flex: 1, width: "90%"}}>
            <View style={{ flex: 0.5 }}>
                <TextInput  
                value={type}
                onChangeText = {(text) => {
                    var isValid = this.alphabetvalidationWithMajorSplChar(text)
                    if(isValid || text === ""){
                    var states = this.state.opNewEntrys;
                    states[index]["type"] = text;
                    this.setState({ states })
                    }
                }}
                style={{height: 35, padding: 3, borderColor: color.boxColor, borderWidth: 1, width: "80%"}}
                />
            </View>
            <View style={{ flex: 0.5 }}>
                <TextInput  
                value={amount}
                onChangeText = {(text) => {
                    var isValid = this.numberValidation(text)
                    if(isValid || text === ""){
                    var states = this.state.opNewEntrys;
                    states[index]["amount"] = text;
                    this.setState({ states })
                    }
                }}
                style={{height: 35, padding: 3, borderColor: color.boxColor, borderWidth: 1, width: "80%", alignSelf: "start"}}
                />
            </View>
            </View>
            {index != 0 && id === null ?
            <View style={{height: 35,width: "10%", justifyContent: "center", alignItems: "center"}}>
                <TouchableOpacity style={{}} onPress={() => {this.onPressClearNewEntry(index)}} >
                <Image
                    source={CancelIcon}
                    style={{ height: 30, width: 30 }}
                    resizeMode="contain"
                />
                </TouchableOpacity>
            </View>
            : 
            <View style={{height: 35, width: "10%", }}></View>
            }
            </View>
        )
    }

    renderIpNewEntryView = (newEntry, index) => {
        let service_name = null
        let rate_per_unit = null
        let id = null
        let { ipNewEntrys } = this.state

        service_name = ipNewEntrys[index] ? ipNewEntrys[index].service_name : ""
        rate_per_unit = ipNewEntrys[index] ? ipNewEntrys[index].rate_per_unit : ""
        id = ipNewEntrys[index] && ipNewEntrys[index].id ? ipNewEntrys[index].id : null

        return(
            <View style={{flexDirection: "row", marginVertical: 15,}}>
            <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', flex: 1, width: "90%"}}>
            <View style={{ flex: 0.5 }}>
                <TextInput  
                value={service_name}
                onChangeText = {(text) => {
                    var isValid = this.alphabetvalidationWithMajorSplChar(text)
                    if(isValid || text === ""){
                    var states = this.state.ipNewEntrys;
                    states[index]["service_name"] = text;
                    this.setState({ states })
                    }
                }}
                style={{height: 35, padding: 3, borderColor: color.boxColor, borderWidth: 1, width: "80%"}}
                />
            </View>
            <View style={{ flex: 0.5 }}>
                <TextInput  
                value={rate_per_unit}
                onChangeText = {(text) => {
                    var isValid = this.numberValidation(text)
                    if(isValid || text === ""){
                    var states = this.state.ipNewEntrys;
                    states[index]["rate_per_unit"] = text;
                    this.setState({ states })
                    }
                }}
                style={{height: 35, padding: 3, borderColor: color.boxColor, borderWidth: 1, width: "80%", alignSelf: "start"}}
                />
            </View>
            </View>
            {index != 0 && id === null ?
            <View style={{height: 35,width: "10%", justifyContent: "center", alignItems: "center"}}>
                <TouchableOpacity style={{}} onPress={() => {this.onPressClearNewEntry(index, "ipClear")}} >
                <Image
                    source={CancelIcon}
                    style={{ height: 30, width: 30 }}
                    resizeMode="contain"
                />
                </TouchableOpacity>
            </View>
            : 
            <View style={{height: 35, width: "10%", }}></View>
            }
            </View>
        )
    }

    onPressClearNewEntry = (index, key = "") => {
        if(key === "ipClear"){
            var showList = this.state.ipNewEntrys;
        }else{
            var showList = this.state.opNewEntrys;
        }
        
        // this.state.deletedTestIds.push(showList[index].id)
        showList.splice(index,1)
        this.setState({
            refreshOpData: true,
            showList
        }, () => {
            setTimeout(() => {
                this.setState({ refreshOpData: false,});
            }, 500);
        })
    }

    checkAllDetailsAreAvailable(newEntry, key = "") {
        var defaultReturn = true
        for (const data of newEntry) {
            if(key === "ipCheck"){
                if (data.service_name && (data.rate_per_unit || data.rate_per_unit == 0)) {
                    // Do Nothing
                } else {
                    defaultReturn = false
                    return defaultReturn
                }
            }else{
            if (data.type && (data.amount || data.amount === 0)) {
                // Do Nothing
            } else {
                defaultReturn = false
                return defaultReturn
            }
            }

        }
        return defaultReturn
    }

    onPressOpNewEntry() {

        let { opNewEntrys } = this.state;
        let defaultNewEntrys = {
            "type": "",
            "amount": ""
        };
        if (opNewEntrys.length > 0) {
            var checkTestField = this.checkAllDetailsAreAvailable(opNewEntrys)
            if (checkTestField) {
                opNewEntrys.push(defaultNewEntrys)
            } else {
                // Do nothing
            }

        } else {
            opNewEntrys.push(defaultNewEntrys)
        }
        this.setState({ opNewEntrys })

    }

    onPressIpNewEntry() {

        let { ipNewEntrys } = this.state;
        let defaultNewEntrys = {
            "service_name": "",
            "rate_per_unit": ""
        };
        if (ipNewEntrys.length > 0) {
            var checkTestField = this.checkAllDetailsAreAvailable(ipNewEntrys, "ipCheck")
            if (checkTestField) {
                ipNewEntrys.push(defaultNewEntrys)
            } else {
                // Do nothing
            }

        } else {
            ipNewEntrys.push(defaultNewEntrys)
        }
        this.setState({ ipNewEntrys })

    }

    ipServicePost = () => {
        var states = this.state

        var url = ""

        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_SERVICE
        }else{
            url = Constants.CLINIC_IP_SERVICE + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        var checkTestField = this.checkAllDetailsAreAvailable(states.ipNewEntrys, "ipCheck")

        if (!checkTestField){
            this.props.showResposeValue("error", "Servive details can't be empty")
        }else{
            var data = {
                ip_service: states.ipNewEntrys
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.opServicePostSuccess,
                this.opServicePostFailure
            );
        }
    }

    opServicePost = () => {

        var states = this.state

        var url = ""

        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_OP_SERVICE
        }else{
            url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        var checkTestField = this.checkAllDetailsAreAvailable(states.opNewEntrys)

        if(states.service_name === ""){
            this.props.showResposeValue("error", "Service name can't be empty")
        }else if (!checkTestField){
            this.props.showResposeValue("error", "Service type details can't be empty")
        }else{
            var lineItems = []
            var typedata = states.opNewEntrys
            for(let i = 0; i < typedata.length; i++){
                if(typedata[i].id){
                    lineItems.push({id: typedata[i].id, name: typedata[i].name, type: typedata[i].type, amount: (typedata[i].amount).toString() })
                }else{
                    lineItems.push({ type: typedata[i].type, amount: typedata[i].amount })
                }
            }
            var data = {
                service_name: states.service_name,
                service_config: lineItems
            }

            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.opServicePostSuccess,
                this.opServicePostFailure
            );
        }

    }

    opServicePostSuccess = (response) => {
        if(response.status === "success"){
            this.props.showResposeValue("success", response.message)
            this.onPressClear()
            this.setState({
                refreshOpData: true
            }, () => {
                setTimeout(() => {
                    this.setState({ refreshOpData: false,});
                }, 500);
            })
        }else {
            this.props.showResposeValue("error", response.message)
        }
    }

    onPressClear = () => {
        let defaultNewEntrys = {
            "type": "",
            "amount": ""
        };
        let defaultIpEntrys = {
            "service_name": "",
            "rate_per_unit": "",
        };
        this.setState({
            opNewEntrys:[ defaultNewEntrys ],
            ipNewEntrys:[ defaultIpEntrys ],
            service_name: "",
            refreshOpData: true
        }, () => {
            setTimeout(() => {
                this.setState({ refreshOpData: false,});
            }, 500);
        })
    }

    renderOPServiceRight = () => {
        return(
            <View style={{paddingHorizontal: 10, paddingVertical: 20,}}>
                <Text style={{fontWeight: "bold", fontSize: 16, marginBottom: 10}}>Out Patients</Text>
                <CommonHistorySectionHeader
                heading1={"Service Name"}
                heading2={""}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-evenly', marginTop: 15, flex: 1, width: "90%", }}>
                <View style={{ flex: 0.5 }}>
                    <TextInput  
                    value={this.state.service_name}
                    onChangeText={(text) => {
                        var isValid = this.alphabetvalidationWithMajorSplChar(text)
                        if(isValid || text === ""){
                            this.setState({
                                service_name: text
                            })
                        }
                    }}
                    style={{height: 35, minHeight: 35,  padding: 3, borderColor: color.boxColor, borderWidth: 1, width: "80%"}}
                    />
                </View>
                <View style={{ flex: 0.5 }}></View>
                </View>
                <View style={{flexDirection: "row", marginTop: 25}}>
                <View style={{ width: "90%"}}>
                <CommonHistorySectionHeader
                heading1={"Service Type"}
                heading2={"Amount"}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                </View>
                <View style={{height: 35,width: "10%", backgroundColor: color.sectionHeadingDarkColor, justifyContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => {this.onPressOpNewEntry()}} >
                    <Image
                        source={AddIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: screenHeight - 380,}} >
                {
                    this.state.opNewEntrys.length > 0 && this.state.opNewEntrys.map((newEntry, index) => {
                        return this.renderOpTypeNewEntryView(newEntry, index)
                    })
                }
                </ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 20 }}>
                <View style={{marginRight: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.opServicePost()}}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.onPressClear()}}
                buttonKey={"clear"} />
                </View>
                </View>

                
            </View>
        )
    }

    getOpTemplateNameAndDetails = () =>{
        var url = ""
        if(this.state.userType === Constants.ROLE_FO){
         url = Constants.CLINIC_OP_TEMPLATE_GET;
        }else{
            url = Constants.CLINIC_OP_TEMPLATE_GET + "?clinic_id=" + this.state.selectedClinicOrDoctor.id;
        }
        

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getSuccess,
            this.getFailure
        )
    }

    getSuccess = (response) =>{
        if(response.status === "success"){
            this.setState({opTemplateList:response.data})
        }
    }
    getFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
    }

    getOpServiceNameAndServcieType = () =>{
        var url = ""
        var states = this.state
        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_OP_SERVICE
        }else{
            url = Constants.CLINIC_OP_SERVICE + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getOpServiceNameAndServcieTypeSuccess,
            this.getOpServiceNameAndServcieTypeFailure
        )
    }

    getOpServiceNameAndServcieTypeSuccess = (response) =>{
        if(response.status === "success"){
            this.setState({
                opServiceNameAndServiceType:response.data
            })
        }
    }

    getOpServiceNameAndServcieTypeFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
    }

    postOpServiceNameAndServiceId = () =>{
        let {opListData,opTemplateName,opIds} = this.state;

        opListData.filter((id)=>( opIds.push(id.service_id)))
        this.setState({opIds})
        var url = ""
        if(this.state.userType === Constants.ROLE_FO){
             url = Constants.CLINIC_OP_TEMPLATE_POST;
        }else{
             url = Constants.CLINIC_OP_TEMPLATE_POST;
        }

        let data = {
            "template_name":opTemplateName,
            "service_data":opIds,
            "clinic_id": this.state.selectedClinicOrDoctor.id ? this.state.selectedClinicOrDoctor.id : null
        }
        let message;
        if((data.template_name === "") && (data.service_data.length === 0)){
            message = "Enter All Fields"
            this.props.showResposeValue("error",message)
            this.setState({
                opListData:[],
                opTemplateName:"",
                opTemplateRefresh: true,
                opIds: []
        })
        } else if(data.template_name === ""){
            message = "Enter Template Name"
            this.props.showResposeValue("error",message)
            this.setState({
                opListData:[],
                opTemplateRefresh: true,
                opIds: []
        })
        } else if(data.service_data.length === 0){
            message = "Add Service Name And Service Type"
            this.props.showResposeValue("error",message)
            this.setState({
                opListData:[],
                opTemplateRefresh: true,
                opIds: []
        })
        } else{
            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.opPostSuccess,
                this.opPostFailure
            )
            console.log("opIds",opIds);
        }   
    }

    opPostSuccess = (response) =>{
        if(response.status === "success"){
            this.props.showResposeValue("success",response.message)
            this.setState({
                opListData:[],
                opTemplateName:"",
                opTemplateRefresh: true,
                opIds: []
        })
        }else {
            this.setState({
                opListData:[],
                opTemplateName:"",
                opTemplateRefresh: true,
                opIds: []
        })
            this.props.showResposeValue("error", response.message)
        }
    }

    opPostFailure = (response) =>{
        this.setState({
            opListData:[],
            opTemplateName:"",
            opTemplateRefresh: true,
            opIds: []
    })
        this.props.showResposeValue("error",response.message)
    }

    onAddOpServiceNameAndOpSericeType = () => {
        let { opAmount, opServiceNameAndServiceType, opListData, id,newserviceType } = this.state;
        opServiceNameAndServiceType.map((item) => {
            item?.service_name === this.state?.opServiceName ? (
                item?.data.map((i) => (
                    i.type === this.state?.opServiceType ? (opAmount = i.amount ,id = i.id,newserviceType = i.type): null
                ))
            ): null
        })

        let newOpTemplateListData = []

        if (!this.state.opServiceName && !this.state.opServiceType) {
            let message = "Enter Service Name and Service Type"
            this.props.showResposeValue("error", message)
        }else if(!this.state.opServiceName){
            let message = "Enter Service Name"
            this.props.showResposeValue("error", message)
        }else if(!this.state.opServiceType){
            let message = "Enter Service Type"
            this.props.showResposeValue("error", message)
        }else if(this.state.hideOpServiceName){
            let TempList = {
                service_id:id,
                service_name:this.state.opServiceName,
                service_type:this.state.opServiceType,
                amount:opAmount
            }
            let ExistingData = opListData.filter(list=>list.service_id=== id)
            opListData.forEach((element,index) =>{
                if(element.service_id === this.state.opServiceSelected.service_id && ExistingData.length == 0 ){
                    opListData.splice(index,1)
                    opListData.push(TempList)
                    this.setState({
                        opListData ,
                        opServiceName:"",
                        opServiceType:""
                    })
                }else{
                if(ExistingData.length !== 0){
                    let message = `Service Name is Already Exist`;
                    this.props.showResposeValue("error",message)
                    this.setState({
                    opServiceName:"",
                    opServiceType:""
                })
                }
                }
                
            })
            console.log(opListData,"kj");
        } 
        else {
          let TempList = {
                service_id:id,
                service_name:this.state.opServiceName,
                service_type:this.state.opServiceType,
                amount:opAmount
            }

            if(opListData.length == 0){
                opListData.push(TempList)
            }
            else{
                let Count = 0;
                let Error = 0;
                for(let i=0; i < opListData.length; i++){
                    if(opListData[i].service_id == id){
                        let message = `${opListData[i].service_name + " and " + opListData[i].service_type} is Already Exist`;
                        this.props.showResposeValue("error",message)
                        Error = 1
                        break
                    }
                    else{
                       Count = Count + 1
                    }
                }
                if(Count != 0 && Error == 0){
                    opListData.push(TempList)
                    Count = 0
                }
                // opListData.forEach(element=>{
                //     console.log(element)
                //     // if(!newOpTemplateListData[element.id || element.service_id]){
                //     // newOpTemplateListData[element.id || element.service_id] = element
                //     // }else{
                //     //     let message = `${element.service_name + " and " + element.service_type} is Already Exist`;
                //     //     this.props.showResposeValue("error",message)
                //     // }
                //     if(element.id !== id){
                //         opListData.push(TempList)
                //     }else{
                        
                //     }
                // })
            }

            
           
            this.setState({opListData : this.state.opListData}, () => {
                this.setState({
                    opServiceName: "",
                    opServiceType:""
                })
            })
        }
        }

    onPressOpCancelButton = () =>{
        this.setState({
            opServiceName:"",
            opServiceType:""
        })
    }

    onPressOpClear = () =>{
        this.setState({
            opServiceName:"",
            opTemplateName:"",
            opServiceType:"",
            opListData:[]
        })
    }

    onPressUpdateButton = () =>{
        
        let {opTemplateId,opTemplateName,opListData} = this.state;

        let opNewListdata = [];

        opListData.map((item)=>{
            return (
                opNewListdata.push(item.service_id)
            )
        })
        var url = ""
        if(this.state.userType === Constants.ROLE_FO){
             url = Constants.CLINIC_OP_TEMPLATE_PUT;
        }else{
             url = Constants.CLINIC_OP_TEMPLATE_PUT;
        }
        
        
        let data = {
            "template_name":opTemplateName,
            "service_data":opNewListdata,
            "id":opTemplateId
        }
        let message;
        if((data.template_name === "") && (data.service_data.length === 0)){
            message = "Enter All Fields"
            this.props.showResposeValue("error",message)
        } else if(data.template_name === ""){
            message = "Enter Template Name"
            this.props.showResposeValue("error",message)
        } else if(data.service_data.length === 0){
            message = "Add Service Name And Service Type"
            this.props.showResposeValue("error",message)
        } else{
            OpthamologyService.getInstance().putMethod(
                url,
                data,
                this,
                this.putSuccess,
                this.putFailure
            )
        }  
    }

    putSuccess = (response)=>{
        if(response.status === "success"){
            this.props.showResposeValue("success",response.message)
            this.setState({
                isOpTemplateEdit:false,
                opTemplateName:"",
                opListData:[],
                opTemplateRefresh: true,
                opServiceName: '',
                opServiceType: '',
                hideOpServiceName:false
            })
        }else{
            this.props.showResposeValue("error",response.message)
        }
    }

    putFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
    }
    

    renderRightOpTemplate = () => {
        let { opListData,opServiceNameAndServiceType } = this.state
        return (
            <View>
                <View>
                    <Text style={Styles.opTemplateTitleCard}>
                        Out Patient Template
                    </Text>
                </View>
                <View>
                    <View>
                        <View style={Styles.optemplateHeader}>
                            <CommonHistorySectionHeader
                                heading1={"Template Name"}
                                columnSize={[1]}
                                noOfColumn={1}
                            />
                        </View>
                        <View>
                            <TextInput
                                style={{
                                    border: "1px solid gray", width: "13vw", marginHorizontal: "0.6vw",
                                    marginTop: "1vw", height: "2vw",paddingLeft:"0.2vw"
                                }}
                                value={this.state.opTemplateName}
                                onChangeText={(text) => {
                                    let isValid = this.alphabetvalidationWithMajorSplChar(text)
                                    if(text === "" || isValid){
                                        this.setState({ opTemplateName: text })
                                    } 
                                }}
                            />
                        </View>
                    </View>
                    <View style={Styles.opSecondHeader}>
                        <CommonHistorySectionHeader
                            heading1={"Service Name "}
                            heading2={"Service Type"}
                            heading3={"Action"}
                            columnSize={[0.33, 0.37, 0.3]}
                            noOfColumn={3}
                        />
                    </View>
                    <View style={Styles.opSecondInputRow}>
                        <View style={{ flex: 0.33 }}>
                            <View style={{ width: "12vw", height: "2vw",marginLeft:"0.5vw", marginVertical: "0.8vw" }}>
                                <Select
                                    value={this.state.opServiceName}
                                    onChange={(item) => this.setState({ opServiceName: item })}
                                    style={{borderWidth:1,borderColor:"black"}}
                                    disabled={this.state.hideOpServiceName ? true : false}
                                >
                                    <Option value={""} >{"Select"}</Option>
                                    {opServiceNameAndServiceType && opServiceNameAndServiceType?.length > 0 ?
                                    opServiceNameAndServiceType?.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.service_name}>{item.service_name}</Option>
                                        )
                                    }):null}
                                </Select>
                            </View>
                        </View>
                        <View style={{ flex: 0.37 }}>
                            <View style={{ width: "11vw", height: "2vw", marginLeft: "2vw", marginVertical: "0.8vw" }}>
                                <Select
                                    value={this.state.opServiceType}
                                    onChange={(item) => this.setState({ opServiceType: item })}
                                >
                                    <Option value={""} >{"Select"}</Option>
                                    {this.state?.opServiceNameAndServiceType && this.state?.opServiceNameAndServiceType.map((item) => {
                                        return item?.service_name === this.state?.opServiceName ?
                                            item?.data.map((i, index) => (
                                                <Option key={index} value={i.type}>{i.type}</Option>
                                            )) : null})
                                    }
                                </Select>
                            </View>
                        </View>
                        <View style={{ flex: 0.3, flexDirection: "row", alignItems: "center", marginLeft: "0.8vw" }}>
                            <TouchableOpacity
                                onPress={() =>{ this.onAddOpServiceNameAndOpSericeType() ,
                                this.setState({hideOpServiceName:false})}}
                            >
                                <Image
                                    source={AddIcon}
                                    style={{ height: 35, width: 35 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                            <TouchableOpacity 
                                 onPress={()=>this.onPressOpCancelButton()}
                            >
                                <Image
                                    source={CancelIcon}
                                    style={{ height: 25, width: 25, marginHorizontal: "0.7vw" }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={Styles.opSecondHeader}>
                        <CommonHistorySectionHeader
                            heading1={"Service Name"}
                            heading2={"Service Type"}
                            heading3={"Amount"}
                            heading4={"Action"}
                            noOfColumn={4}
                            columnSize={[0.3, 0.3, 0.2, 0.2]}
                        />
                    </View>
                    <ScrollView style={{ border: "1px solid lightgray",borderTop:"none",borderTopLeftRadius:0,borderTopRightRadius:0,borderRadius:5, height: "36.5vh", width: "98%", marginLeft: "0.5vw", }}>
                        {opListData && opListData?.length ?
                                opListData?.map((item, index) => {
                                    return (
                                    <View key={item.id}>
                                        <View style={{ height: "2vw", flexDirection: "row", alignItems: "center" }}>
                                            <Text style={{ flex: 0.3 ,paddingLeft:"1.3vw"}}>{item.service_name}</Text>
                                            <Text style={{ flex: 0.3 ,paddingLeft:"1.2vw"}}>{item.service_type}</Text>
                                            <Text style={{ flex: 0.2}}>{item.amount}</Text>
                                            <View style={{flex:0.2,flexDirection:"row"}}>
                                            <TouchableOpacity style={{marginRight:"1vw"}} 
                                            onPress={()=>{
                                                this.setState({
                                                    hideOpServiceName:true,
                                                    opServiceName:item.service_name,
                                                    opServiceType:item.service_type,
                                                    opServiceSelected : item
                                                },()=>console.log("opList",this.state.opServiceSelected))
                                                
                                            }}>
                                            <Image
                                                    source={EditIcon}
                                                    style={{ height: 25, width: 25 }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                            onPress={()=> {
                                                opListData.splice(index,1)
                                                this.setState({
                                                    opListData 
                                                })
                                            }}
                                            >
                                                <Image
                                                    source={DeleteIcon}
                                                    style={{ height: 25, width: 25 }}
                                                    resizeMode="contain"
                                                />
                                            </TouchableOpacity>
                                            </View>
                                        </View>
                                    </View>
                                )
                            }):null
                        }
                    </ScrollView>
                </View>
                <View style={{ flexDirection: "row", position: "absolute", top: "35vw", left: "13vw" }}>
                    <TouchableOpacity
                        onPress={()=>{
                            this.state.isOpTemplateEdit ? 
                            this.onPressUpdateButton() : this.postOpServiceNameAndServiceId()
                        }}
                        style={Styles.ButtonStyle}
                    >
                        {
                            this.state.isOpTemplateEdit ? 
                            <Text style={Styles.ButtonText}>Update</Text> :
                            <Text style={Styles.ButtonText}>Add</Text>
                        }
                        
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[Styles.ClearButton, { border: "1px solid #04b7b1" }]}
                        onPress={()=>{this.onPressOpClear()}}>
                        <Text style={Styles.ClearText}>Clear</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
    
    renderIPServiceRight = () => {
        return(
            <View style={{paddingHorizontal: 10, paddingVertical: 20}}>
                <Text style={{fontWeight: "bold", fontSize: 16, marginBottom: 10}}>In Patients</Text>
                <View style={{flexDirection: "row"}}>
                <View style={{ width: "90%"}}>
                <CommonHistorySectionHeader
                heading1={"Service Type"}
                heading2={"Amount"}
                columnSize={[0.5,0.5]}  //total value is == of 1
                noOfColumn={2}
                />
                </View>
                <View style={{height: 35,width: "10%", backgroundColor: color.sectionHeadingDarkColor, justifyContent: "center", alignItems: "center"}}>
                    {this.state.ipNewEntrys[0].id ? null :
                    <TouchableOpacity style={{}} onPress={() => {this.onPressIpNewEntry()}} >
                    <Image
                        source={AddIcon}
                        style={{ height: 30, width: 30 }}
                        resizeMode="contain"
                    />
                    </TouchableOpacity>
                    }
                </View>
                </View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true} style={{maxHeight: screenHeight - 280,}} >
                {
                    this.state.ipNewEntrys.length > 0 && this.state.ipNewEntrys.map((newEntry, index) => {
                        return this.renderIpNewEntryView(newEntry, index)
                    })
                }
                </ScrollView>
                <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 20 }}>
                <View style={{marginRight: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.ipServicePost()}}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: 10}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {this.onPressClear()}}
                buttonKey={"clear"} />
                </View>
                </View>
            </View>
        )
    }

    getIpServiceNameAndServcieType = () => {
        var url = ""
        var states = this.state
        if(states.userType === Constants.ROLE_FO){
            url = Constants.CLINIC_IP_SERVICE
        }else{
            url = Constants.CLINIC_IP_SERVICE + "?clinic_id=" + states.selectedClinicOrDoctor.id
        }

        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getIpServiceNameAndServcieTypeSuccess,
            this.getIpServiceNameAndServcieTypeFailure
        )
    }

    getIpServiceNameAndServcieTypeSuccess = (response) =>{
        if(response.status === "success"){
            this.setState({
                ipServiceNameAndServiceType:response.data
            })
        }
    }

    getIpServiceNameAndServcieTypeFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
    }

    postIpServiceNameAndServiceId = () => {
        let {IpListData,ipTemplateName,ipIds} = this.state;

        IpListData.filter((id)=>( ipIds.push(id.id)))
        this.setState({ipIds})
        var url =""
        if (this.state.userType === Constants.ROLE_FO){
        url = Constants.CLINIC_IP_TEMPLATE_POST;
    } else{
         url = Constants.CLINIC_IP_TEMPLATE_POST
        }
        let data = {
            "template_name":ipTemplateName,
            "service_data":ipIds,
            "clinic_id" : this.state.selectedClinicOrDoctor.id ? this.state.selectedClinicOrDoctor.id : null
        }

        let message;
         if((data.template_name === "") && ((data.service_data.length === 0))){
            message = "Enter All Fields"
            this.props.showResposeValue("error",message)
            this.setState({
                IpListData:[],
                ipTemplateName:"",
                ipTemplateRefresh : true,
                ipIds:[]
            })
        }else if(data.template_name === ""){
            message = "Enter Template Name"
            this.props.showResposeValue("error",message)
            this.setState({
                IpListData:[],
                ipTemplateRefresh : true,
                ipIds:[]
            })
        } else if(data.service_data.length === 0) {
            message = "Add Service Name"
            this.props.showResposeValue("error",message)
            this.setState({
                IpListData:[],
                ipTemplateRefresh : true,
                ipIds:[]
            })
        } else {
            OpthamologyService.getInstance().postComplaints(
                url,
                data,
                this,
                this.ipPostSuccess,
                this.ipPostFailure
            ) 
        }   
    }

    ipPostSuccess = (response) =>{
        if(response.status === "success"){
            this.props.showResposeValue("success",response.message)
            this.setState({
                IpListData:[],
                ipTemplateName:"",
                ipTemplateRefresh : true,
                ipIds:[]
            })
        }else {
            this.props.showResposeValue("error", response.message)
            this.setState({
                IpListData:[],
                ipTemplateName:"",
                ipTemplateRefresh : true,
                ipIds:[]
            })
        }
    }

    ipPostFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
        this.setState({
            IpListData:[],
            ipTemplateName:"",
            ipTemplateRefresh : true,
            ipIds:[]
        })
    }

    IpTemplateUpdate = () =>{

        let {ipTemplateName,ipTemplateId,IpListData} = this.state;
        let newData = []
        IpListData.map((item)=>{
            return (
                newData.push(item.service_id || item.id  )
            )
        })

        let data = {
            "id":ipTemplateId,
            "template_name":ipTemplateName,
            "service_data":newData
        }
        var url =""
        if(this.state.userType === Constants.ROLE_FO){
         url = Constants.CLINIC_IP_TEMPLATE_PUT;
    } else{
         url = Constants.CLINIC_IP_TEMPLATE_PUT;
        }
    
        let message;

        if((data.template_name === "") && (data.service_data.length === 0)){
            message = "Enter All Fields"
            this.props.showResposeValue("error",message)
        }else if(data.template_name === ""){
            message = "Please Enter Template Name"
            this.props.showResposeValue("error",message)
        } else if(newData.length === 0) {
            message = "Add Service Name"
            this.props.showResposeValue("error",message)
        } else{
            OpthamologyService.getInstance().putMethod(
                url,
                data,
                this,
                this.ipPutSuccess,
                this.ipPutFailure
            )
        }  
    }

    ipPutSuccess = (response) =>{
        if(response.status === "success"){
            this.props.showResposeValue("success",response.message)
            this.setState({
                isIpTemplateEdit:false,
                ipTemplateName:"",
                ipServiceName: '',
                IpListData:[],
                ipTemplateRefresh : true
            })
        }else{
            this.props.showResposeValue("error",response.message)
        }
    }

    ipPutFailure = (response) =>{
        this.props.showResposeValue("error",response.message)
    }

    addIpServiceNameAndAmount = () =>{
        let {ipServiceNameAndServiceType,ipAmount,IpListData,ipAmountId} = this.state;
        
        ipServiceNameAndServiceType.map((item)=>(
            item.service_name === this.state.ipServiceName ? (ipAmount = item.rate_per_unit,ipAmountId = item.id) : null
        ))

        if(!this.state.ipServiceName){
            let message = "Enter Service Name"
            this.props.showResposeValue("error",message)
        }else{
            IpListData.push({
                id:ipAmountId,
                name:this.state.ipServiceName,
                rate_per_unit:ipAmount
            })
            
            let newIpListData = [];
            IpListData.forEach(element =>{
                if(!newIpListData[element.id]){
                    newIpListData[element.id] = element
                }else{
                    let message = `${element.name} is Already Exist`
                    this.props.showResposeValue("error",message)
                }
            })
            this.setState({ IpListData : newIpListData}, () => {
                this.setState({
                    ipServiceName: ""
                })
            })
        }
    }

    onPressIpClear = () =>{
        this.setState({
            ipServiceName:"",
            ipTemplateName:"",
            IpListData:[]
        })
    }

    onPressIpCancel = (index) =>{
        let {IpListData} = this.state;
        IpListData.splice(index,1)
        this.setState({IpListData})
    }

    renderRightIpTemplate = () => {
        let {IpListData,ipServiceNameAndServiceType} = this.state;
        return (
            <View>
                <View>
                    <Text style={Styles.opTemplateTitleCard}>
                        IP Patient Template
                    </Text>
                </View>
                <View>
                    <View>
                        <View style={Styles.optemplateHeader}>
                            <CommonHistorySectionHeader
                                heading1={"Template Name"}
                                columnSize={[1]}
                                noOfColumn={1}
                            />
                        </View>
                        <View>
                            <TextInput
                                style={{
                                    border: "1px solid black", width: "13vw", marginHorizontal: "0.6vw",
                                    marginTop: "1vw", height: "2vw",paddingLeft:"0.2vw"
                                }}
                                value={this.state.ipTemplateName}
                                onChangeText={(text) => {
                                    let isValid = this.alphabetvalidationWithMajorSplChar(text)
                                    if(text === "" || isValid){
                                        this.setState({ ipTemplateName: text })
                                    }
                                    
                                }}
                            />
                        </View>
                    </View>
                    <View style={Styles.opSecondHeader}>
                        <CommonHistorySectionHeader
                            heading1={"Service Name"}
                            heading2={"Action"}
                            columnSize={[0.5,0.5]}
                            noOfColumn={2}
                        />
                    </View>
                    <View style={Styles.opSecondInputRow}>
                        <View style={{ flex: 0.5 }}>
                        <View style={{ width: "13vw", height: "2vw",marginHorizontal:"0.8vw", marginVertical: "0.8vw" }}>
                                <Select
                                value={this.state.ipServiceName}
                                onChange={(item) => this.setState({ ipServiceName: item })}
                                >
                                <Option value={""} >{"Select"}</Option>
                                    {ipServiceNameAndServiceType && ipServiceNameAndServiceType?.length > 0 ?
                                    ipServiceNameAndServiceType.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.service_name}>{item.service_name}</Option>
                                        )
                                    }):null}
                                </Select>
                            </View>
                        </View>

                        <View style={{ flex: 0.5, flexDirection: "row", alignItems: "center"}}>
                            <TouchableOpacity 
                            onPress={()=>{this.addIpServiceNameAndAmount()}}>
                                <Image
                                    source={AddIcon}
                                    style={{ height: 35, width: 35 }}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={Styles.opSecondHeader}>
                        <CommonHistorySectionHeader
                            heading1={"Service Name"}
                            heading2={"Amount"}
                            heading3={"Action"}
                            noOfColumn={3}
                            columnSize={[0.4, 0.4, 0.2]}
                        />
                    </View>
                    <ScrollView style={{ border: "1px solid lightgray",borderTop:"none",borderTopLeftRadius:0,borderTopRightRadius:0,borderRadius:5, height: "36.5vh", width: "98%", marginLeft: "0.5vw" }}>
                        {IpListData && IpListData?.length > 0 ? IpListData?.map((item,index)=>{
                            return (
                                <View key={index} style={{ height: "2vw", flexDirection: "row", alignItems: "center" }}>
                                    <Text style={{flex:0.4,paddingLeft:"1.3vw"}}>{item.name}</Text>
                                    <Text style={{flex:0.4,paddingLeft:"1.4vw"}}>{item.rate_per_unit}</Text>
                                    <TouchableOpacity 
                                    style={{flex:0.2}}
                                    onPress={()=>this.onPressIpCancel(index)}
                                    >
                                        <Image 
                                        source={DeleteIcon}
                                        style={{height:25,width:25}}
                                        resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                </View>
                            )}):null
                        }
                    </ScrollView>
                </View>
                <View style={{ flexDirection: "row", position: "absolute", top: "35vw", left: "13vw" }}>
                    <TouchableOpacity
                        style={Styles.ButtonStyle}
                        onPress={()=>{this.state.isIpTemplateEdit ? this.IpTemplateUpdate() : this.postIpServiceNameAndServiceId()}}>
                            {
                                this.state.isIpTemplateEdit ?
                                <Text style={Styles.ButtonText}>Update</Text> :
                                <Text style={Styles.ButtonText}>Add</Text>
                            }
                        
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[Styles.ClearButton, { border: "1px solid #04b7b1" }]}
                        onPress={()=>{this.onPressIpClear()}}
                        >
                        <Text style={Styles.ClearText}>Clear</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    onChangeSearchHandler = (text) =>{
        let {selectedTab} = this.state
        switch (selectedTab){
            case "opServiceConfig":
                this.setState({
                    OpSearchKey: text,
                    IpSearchKey: "",
                    opTemplateSearchKey:"",
                    ipTemplateSearchKey:""
                })
                 break;
            case "opTemplate":
                this.setState({
                    OpSearchKey:"",
                    IpSearchKey:"",
                    opTemplateSearchKey:text,
                    ipTemplateSearchKey:""
                })
                break;
            case "ipServiceConfig":
                this.setState({
                    OpSearchKey: "",
                    IpSearchKey: text,
                    opTemplateSearchKey:"",
                    ipTemplateSearchKey:""
                })
                break;
            case "ipTemplate":
                this.setState({
                    OpSearchKey:"",
                    IpSearchKey:"",
                    opTemplateSearchKey:"",
                    ipTemplateSearchKey:text
                })
                break;
            default:
                break
        }
    }

    render(){
        return(
            <View style={{height: screenHeight-65,}}>
                <NavigationTopHeader
                changeTabSection={this.changeTabSection.bind(this)}
                navigationHeaderList={this.state.userType === Constants.ROLE_FO || this.state.isOPT ? [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "Package Configuration", value: "packageConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "QMS", value: "adminQmsConfiq" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" },
                        { label: "Discharge Summary Templates", value: "DisSumTemplate" },
                        { label: "Surgery Notes Templates", value: "surgeryNotesTemplate" },
                        ] : [
                        { label: "Service Configuration", value: "adminConfig" },
                        { label: "IP Room/Ward Configuration", value: "ipRoomConfig" },
                        { label: "Tag Configuration", value: "tagConfig" },
                        { label: "User Configuration", value: "userConfig" },
                        { label: "Corporate Details", value: "corpConfig" },
                        { label: "Referred Doctors", value: "labRefferedDoctors"},
                        { label: "Expense", value: "expenseconfig" },
                        { label: "Settings", value: "settingConfig" }
                        ]}
                        
                      
                selectedTab={"adminConfig"}
                isNavigationBorder={true}
                />
                <View style={{ flex: 1, flexDirection: "row", height: screenHeight-120,}}>
                <View style={{ flex: 0.60, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                    <View style={{alignItems: "flex-end", height: 60, paddingVertical: 10, width: "100%"}}>
                        <TextInput 
                        style={{height: 40, borderWidth: 1, borderColor: color.lightGray, borderRadius: 4, width: "30%", marginRight: 10, paddingHorizontal: 8, color: color.black}} 
                        placeholder={this.state.selectedTab == "opServiceConfig" ? "Search OP Services..." : 
                        this.state.selectedTab === "opTemplate" ? "Search OP Template..." :
                        this.state.selectedTab === "ipServiceConfig" ? "Search IP Services..." :"Search IP Template..." }
                        value={this.state.selectedTab == "opServiceConfig" ? this.state.OpSearchKey : 
                        this.state.selectedTab === "opTemplate" ? this.state.opTemplateSearchKey :
                        this.state.selectedTab === "ipServiceConfig" ? this.state.IpSearchKey : this.state.ipTemplateSearchKey}
                        onChangeText={(text) => {this.onChangeSearchHandler(text)}}
                        />
                    </View>
                    <View style={{flexDirection: "row",  }}>
                    <TouchableOpacity onPress={() => {
                        this.changeTabView("opServiceConfig")
                    }} style={{ flex: this.state.isOPT ? 0.3 : 0.25,borderBottomColor: this.state.selectedTab == "opServiceConfig" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "opServiceConfig" ? 2 : null, height: 40, padding: 5, justifyContent: "center" }}>
                        <Text style={{alignSelf: "center", fontSize: 18 }}>{"Out Patients"}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => {
                        this.changeTabView("opTemplate")
                    }} style={{flex:0.3,borderBottomColor: this.state.selectedTab == "opTemplate" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "opTemplate" ? 2 : null, height: 40, padding: 5, justifyContent: "center" }}>
                        <Text style={{alignSelf: "center", fontSize: 18 }}>{"OP Template"}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                        this.changeTabView("ipServiceConfig")
                    }} style={{ flex:0.25,borderBottomColor: this.state.selectedTab == "ipServiceConfig" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "ipServiceConfig" ? 2 : null,  height: 40, padding: 5, justifyContent: "center" }}>
                        <Text style={{alignSelf: "center", fontSize: 18 }}>{"In Patients"}</Text>
                    </TouchableOpacity>
                    {
                        !this.state.isOPT ?
                        <TouchableOpacity onPress={() => {
                            this.changeTabView("ipTemplate")
                        }} style={{flex:0.25,borderBottomColor: this.state.selectedTab == "ipTemplate" ? color.themeDark : null, borderBottomWidth: this.state.selectedTab == "ipTemplate" ? 2 : null, height: 40, padding: 5, justifyContent: "center" }}>
                            <Text style={{alignSelf: "center", fontSize: 18 }}>{"IP Template"}</Text>
                        </TouchableOpacity> :null 
                    }

                    
                    
                    </View>
                    <View style={{ height: screenHeight - 220}}>
                    {this.renderComponent()}
                    </View>
                </View>
                <View style={{flex: 0.40, backgroundColor: color.themeShade, height: screenHeight-120, margin: 5, shadowOffset: {width: 0, height: 1}, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2 }}>
                    {this.state.selectedTab === "opServiceConfig"? 
                    <View>
                        {this.renderOPServiceRight()}
                    </View> :
                    this.state.selectedTab === "ipServiceConfig"?
                    <View>
                        {this.renderIPServiceRight()}
                    </View> :
                    this.state.selectedTab === "opTemplate"?
                    <View>
                        {this.renderRightOpTemplate()}
                    </View> :
                    this.state.selectedTab === "ipTemplate"?
                    <View>
                        {this.renderRightIpTemplate()}
                    </View> :
                    null
                    }

                </View>
                </View>
                {this.state.isDeleteClick ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white,  width: 250, height: 150, borderRadius: 8, alignItems: "center", borderWidth: 1, }}>                    
                    <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 50, }}>{"Are you sure ?"}</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => {
                            var states = this.state
                            if(states.popupKey === "ip"){
                                this.setState({
                                    confirmDeletedIpId: states.deletedIpId
                                })
                            }else if(states.popupKey === "op"){
                                if(states.popupType === "service"){
                                    this.setState({
                                        confirmDeletedOpAllId: states.deletedOpAllId
                                    }) 
                                }else{
                                    this.setState({
                                        confirmDeletedOpId: states.deletedOpId
                                    }) 
                                }
                                
                            }
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                        onPress={() => { 
                            this.setState({
                                deletedIpId : null,
                                isDeleteClick: false,
                                deletedOpId: null,
                                deletedOpAllId: [],
                                popupKey: "",
                                popupType: ""
                            })
                         }}>
                        <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                    </TouchableOpacity>

                    </View>
                </View>
                </View>
                : null }
            </View>
        )
    }
}




const Styles = StyleSheet.create({
    opTemplateTitleCard:{
        fontSize: "1vw", 
        fontWeight: "bold",
         marginTop: "1vw",
          marginHorizontal: "0.5vw" 
    },
    optemplateHeader:{
        width: "98%",
         marginHorizontal: "0.5vw",
         marginTop:"0.5vw" 
    },
    opSecondHeader:{
        width: "98%", 
        marginLeft: "0.5vw",
        marginTop:"0.5vw"
    },
    opSecondInputRow:{
        flexDirection: "row"
    },
    ButtonStyle: {
        borderRadius: "5px",
        height: "1.8vw",
        width: "3.8vw",
        backgroundColor: "#04b7b1",
        marginLeft: "1.5vw",
        position: "relative",
        top: "0.3vw"
    },
    ButtonText: {
        textAlign: "center",
        position: "relative",
        top: "0.2vw",
        color: "#FFFFFF",
        fontSize: "1vw"
    },
    ClearText:{
        textAlign: "center",
        position: "relative",
        top: "0.1vw",
        color: "#04b7b1",
        fontSize: "1vw"
    },
    ClearButton:{
        borderRadius: "5px",
        height: "1.8vw",
        width: "3.5vw",
        marginLeft: "1.5vw",
        position: "relative",
        top: "0.3vw" 
    }
    
})
