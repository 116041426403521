//import liraries
import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Platform,
  TouchableWithoutFeedback,
  ScrollView, 
  TouchableOpacity 
} from "react-native";
// import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { color } from "../../../../styles/Color";
import {
  OBGYNHistorySectionHeading,
  CommonButton,
  CommonAddButton,
} from "../../BaseComponent";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
// import Text from './../CommonText';
import { DatePicker } from "antd";
import moment from "moment";

const pageName = "isAntenatalChart";
const platform = Platform.OS;
var Messages = require('../../../../utils/InfoMessages')
// create a component
export class AntenatalChartNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      patientAppointment: this.props.patientAppointment,
      patientInfo: this.props.patientInfo,
      therapy: {},
      favAntenatal: [],
      selectedfavAntenatal: {},
      gravida: 0,
      para: 0,
      liveBirth: 0,
      abortions: 0,
      lastMenstrualDate: "",
      presentDate: moment(new Date()).format('YYYY-MM-DD'),
      isHeightofuterus: false,
      selectedWeight: {},
      isWeight: false,
      newBloodPressure: "",
      isBloodPressure: false,
      selectedBloodPressure: {},
      favBloodPressure: [],
      favWeight: [],
      newWeight: "",
      newTherapy: "",
      newProcedure: "",
      newEndometrium: "",
      newLeftOvary: "",
      newRightOvary: "",
      newHeightofuterus: "",
      favProcedure: [],
      selectedHeightofuterus: {},
      favHeightofuterus: [],
      favRightOvary: [],
      favLeftOvary: [],
      favEndometrium: [],
      favPrevOvulation: [],
      selectedPrevAnt: {},
      newPrevOvulation: "",
      id: "",
      antenatal_chart_id: "",
      presentation: "",
      remarks: "",
      setWeight: ""
    };
  }

  componentDidMount() {
    this.getPrevOvulation();
  }


  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }
  componentWillReceiveProps(props) {
   // console.log("****editHistoryItem********>> " + JSON.stringify(props.editHistoryItem))
    if (
      props &&
      props.editHistoryItem &&
      props.editHistoryItem.pageName == pageName
    ) {
      if (
        props &&
        props.editHistoryItem &&
        Object.keys(props.editHistoryItem).length > 0
      ) {

        let item = props.editHistoryItem.editItem;
        let item2 = props.editHistoryItem.updateHead
        var newSelectedWeight = {
          value: item.weight,
          label: item.weight,
        }

        var { favWeight } = this.state

        favWeight.push(item.weight);
        favWeight = this.removeDuplicate(favWeight)

        var { favHeightofuterus } = this.state

        favHeightofuterus.push(item.height_of_uterus);
        favHeightofuterus = this.removeDuplicate(favHeightofuterus)


        this.setState(
          {
            setWeight: item.weight,
            id: item.id,
            lastMenstrualDate: props.editHistoryItem.lmd,
            presentDate: item.date_of_visit,
            selectedWeight: newSelectedWeight,
            selectedBloodPressure: {
              value: item.blood_pressure,
              label: item.blood_pressure,
            },
            selectedHeightofuterus: {
              value: item.height_of_uterus,
              label: item.height_of_uterus,
            },
            presentation: item.presentation,
            remarks: item.remarks,
            gravida: item2 && item2.gravida ? item2.gravida : "0",
            para: item2 && item2.para ? item2.para : "0",
            liveBirth: item2 && item2.live_births ? item2.live_births : "0",
            abortions: item2 && item2.abortions ? item2.abortions : "0",
            favWeight, favHeightofuterus
          },
          () => {
            if (this.state.lastMenstrualDate && this.state.presentDate) {
              this.getTempPrev();
            }
            this.props.editHistory("");
          }
        );
      }
    }

    if (props.newItemclear) {
      var states = this.state

      states["lastMenstrualDate"] = "";
      states["para"] = "0";
      states["liveBirth"] = "0";
      states["abortions"] = "0";
      states["gravida"] = "0";

      this.setState({
        states
      })
      this.clearAllValues()
     

      this.props.renderNewItemClear("AntenentalClear", false)
    }
  }

  getDataByDate() {
    var service_url =
      Constants.GET_ANTENATAL +
      "?patient_id=" +
      this.state.patientAppointment.patient_id +
      "&antenatal_chart_id=" +
      this.state.antenatal_chart_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getDataByDateSuccess,
      this.getOvulationFailure
    );
  }

  getDataByDateSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      var getValue = response.data.antenatal_chart_header;
      var getAntValue = response.data.antenatal_chart_values;
      var index = getAntValue.length - 1;

      this.setState(
        {
          gravida: getValue.gravida ? getValue.gravida : "",
          para: getValue.para,
          liveBirth: getValue.live_births,
          abortions: getValue.abortions,
          lastMenstrualDate: getValue.last_menstrual_period,
          presentDate: getAntValue[index].date_of_visit,
          selectedHeightofuterus: {
            value: getAntValue[index].height_of_uterus,
            label: getAntValue[index].height_of_uterus,
          },
          selectedWeight: {
            value: getAntValue[index].weight,
            label: getAntValue[index].weight,
          },
          selectedBloodPressure: {
            value: getAntValue[index].blood_pressure,
            label: getAntValue[index].blood_pressure,
          },
          presentation: getAntValue[index].presentation,
          remarks: getAntValue[index].remarks,
        },
        () => {
          if (this.state.lastMenstrualDate && this.state.presentDate) {
            this.getTempPrev();
          }
        },
        this.props.getDataByDateValues(response.data, "AntenatalChart", true),
        // this.props.refreshRighSideComponent(pageName)
        //clear
        this.props.getDataByDateValues([], "AntenatalChart", true)
      );
    }
  };

  getTempPrev() {
    var service_url =
      Constants.TEMPLATE_ANTENATAL +
      "?patient_id=" +
      this.state.patientAppointment.patient_id +
      "&last_menstrual_period=" +
      this.state.lastMenstrualDate +
      "&chart_date=" +
      this.state.presentDate;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTempPrevSuccess,
      this.getOvulationFailure
    );
  }

  getTempPrevSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      var { favWeight, favHeightofuterus, favBloodPressure } = this.state

      // if(this.state.setWeight){
      //   favWeight.push(this.state.setWeight)
      // }

      favWeight = favWeight.concat(response.data.weight)
      favHeightofuterus = favHeightofuterus.concat(response.data.height_of_the_fetus)

      if (response.data.blood_pressure.length > 0) {
        for (let i = 0; i < response.data.blood_pressure.length; i++) {
          if (response.data.blood_pressure[i]) {
            favBloodPressure.push(response.data.blood_pressure[i])

          }

        }
      }


      favWeight = this.removeDuplicate(favWeight)
      favHeightofuterus = this.removeDuplicate(favHeightofuterus)
      favBloodPressure = this.removeDuplicate(favBloodPressure)

      // field["favBloodPressure"] = response.data.blood_pressure;
      // field["favHeightofuterus"] = response.data.height_of_the_fetus;
      // field["favWeight"] = response.data.weight;
      this.setState({
        field, favHeightofuterus, favWeight, favBloodPressure
        // isLoading: false
      });
    }
  };

  getPrevOvulation() {
    var service_url =
      Constants.GET_PREV_ANTENATAL +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPrevOvulationSuccess,
      this.getOvulationFailure
    );
  }

  getPrevOvulationSuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["favAntenatal"] = response.data;
      this.setState({
        field,
        // isLoading: false
      });
    }
  };

  getDateValue(label, key, selectedValue, listData) {
    return (
      <View>
        <Text style={{ fontSize: 15, fontWeight: "500" }}>
          {label}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 15 }}>
          {listData.length > 0
            ? listData.map((item, index) => {
              var getItem = item;
              if (key == "selectedfavAntenatal") {
                getItem["value"] = item.id;
              } else {
                getItem = { value: item, label: item };
              }
              var textToDisplay = moment(getItem.last_menstrual_period).format("DD-MM-YYYY")
              return (
                <View
                  style={{ marginBottom: 10, marginRight: 10 }}
                  key={index}
                >
                  <CommonButton
                    item={getItem}
                    selectedvalue={selectedValue}
                    butttonText={textToDisplay}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressDate.bind(this)}
                    buttonKey={key}
                  />
                </View>
              );
            })
            : null}
        </View>
      </View>
    );
  }

  commonOvulation(label, key, selectedValue, listData) {
    // var prevOvulationDate =  [
    //     { label: listData, value: listData },
    // ]

    return (
      <View>
        <Text style={{ fontSize: 15, fontWeight: "500", marginTop: 20 }}>
          {label}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 15 }}>
          {listData.length > 0
            ? listData.map((item, index) => {
              var getItem = item;
              if (key == "selectedfavAntenatal") {
                getItem["value"] = item.id;
              } else {
                getItem = { value: item, label: item };
              }
              return (
                <View
                  style={{ marginBottom: 10, marginRight: 10 }}
                  key={index}
                >
                  <CommonButton
                    item={getItem}
                    selectedvalue={selectedValue}
                    butttonText={
                      key == "selectedfavAntenatal"
                        ? getItem.last_menstrual_period
                        : getItem.label
                    }
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={key}
                  />
                </View>
              );
            })
            : null}
        </View>
      </View>
    );
  }

  onPressDate(key, selectedValue, getItem) {
    this.setState(
      {
        selectedfavAntenatal: selectedValue,
        antenatal_chart_id: selectedValue.id,
      },
      () => this.getDataByDate()
    );
  }

  onPressButton(key, selectedValue) {
    if (key == "selectedfavAntenatal") {
      this.setState({
        selectedfavAntenatal: selectedValue,
      });
    } else if (key == "selectedHeightofuterus") {
      this.setState({
        selectedHeightofuterus: selectedValue,
      });
    } else if (key == "selectedWeight") {
      this.setState({
        selectedWeight: selectedValue,
      });
    } else if (key == "selectedBloodPressure") {
      this.setState({
        selectedBloodPressure: selectedValue,
      });
    }
  }

  renderReriodsDaysTextBox(label, placeholder, key, values) {
    return (
      <View style={{ marginTop: 20, marginBottom: 10 }}>
        <Text style={{ fontSize: 15, fontWeight: "500" }}>{label}</Text>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <TextInput
            value={values}
            placeholder={placeholder}
            style={{
              paddingLeft: 10,
              backgroundColor: color.white,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderColor: color.rightPanelInputBorder,
              borderWidth: 1,
              height: 35,
            }}
            onChangeText={(text) => {
              var statets = this.state;
              statets[key] = text;
              this.setState({ statets });
            }}
          />
          <View
            style={{
              paddingHorizontal: 20,
              justifyContent: "center",
              height: 35,
              borderColor: color.rightPanelInputBorder,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderWidth: 1,
            }}
          >
            <Text>{"ml"}</Text>
          </View>
        </View>
      </View>
    );
  }

  renderRichTextBox(label, key, values) {
    return (
      <View>
        <Text style={{ marginTop: 10, fontSize: 15, fontWeight: "500" }}>{label}</Text>
        <TextInput
          value={values}
          placeholder={""}
          multiline={true}
          textAlignVertical="top"
          maxLength={100}
          style={{
            marginTop: 10,
            marginBottom: 20,
            textAlignVertical: "top",
            paddingLeft: 10,
            backgroundColor: color.white,
            borderRadius: 8,
            borderColor: color.rightPanelInputBorder,
            borderWidth: 1,
            height: 65,
          }}
          onChangeText={(text) => {
            var statets = this.state;
            statets[key] = text;
            this.setState({ statets });
          }}
        />
      </View>
    );
  }

  updateState = (key, value) => {
    var fields = this.state;
    [key] = value;
    this.setState({ fields }, () => {
      if (this.state.lastMenstrualDate && this.state.presentDate) {
        this.getTempPrev();
      }
    });

    // this.createFilterTag();
  };

  onPressAddButton() {
    var states = this.state;
    var start = moment(this.state.lastMenstrualDate);
    var end = moment(this.state.presentDate);
    var check = moment(this.state.lastMenstrualDate).subtract(1, 'days').startOf('day').toString()
    var startDate = moment(check).format("YYYY-MM-DD")
    // var diff = moment.duration(birthday.diff(currDay));
    // console.log(diff.months() + " months, " + diff.weeks() + " weeks, " + diff.days()%7 + " days.");
    // var diff =   moment.duration(end.diff(start));
    var countWeeks = end.diff(startDate, "week");
    var countMonths = moment(this.state.lastMenstrualDate).add(40, 'week').format("YYYY-MM-DD");
    // .add(10, "months")
    // .format("YYYY-MM-DD");

    var data = {
      patient_id: this.state.patientAppointment.patient_id,
      last_menstrual_period: this.state.lastMenstrualDate,
      expected_date_of_delivery: countMonths,
      gravida: this.state.gravida,
      para: this.state.para,
      live_births: this.state.liveBirth,
      abortions: this.state.abortions,
      antenatal_chart_values: {
        id: this.state.id ? this.state.id : "",
        date_of_visit: this.state.presentDate,
        weeks: countWeeks,
        weight: this.state.selectedWeight.value,
        blood_pressure: this.state.selectedBloodPressure.value,
        height_of_uterus: this.state.selectedHeightofuterus.value,
        presentation: this.state.presentation,
        remarks: this.state.remarks,
      },
    };


    // alert("To send" + JSON.stringify(data))

    if (this.state.lastMenstrualDate && this.state.presentDate) {
      var service_url = Constants.POST_ANTENATAL;
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveAntentalChartSuccess,
        this.saveAntentalChartFailure
      );
    } else {
      this.props.showResposeValue("error", Messages.AntenatalChartEmptyField);
    }
  }

  saveAntentalChartSuccess = (success) => {
    if (success.status == "success") {
      this.props.showResposeValue("success", success.message);
      this.clearAllValues();
      this.getPrevOvulation();
      this.props.refreshRighSideComponent(pageName);
    } else {
      this.props.showResposeValue("error", success.message);
    }
  };
  saveAntentalChartFailure = (error) => {
    this.props.showResposeValue("error", error.message);
  };

  clearAllValues() {
    var states = this.state;
    states["id"] = "";
    states["selectedHeightofuterus"] = {};
    states["selectedWeight"] = "";
    states["selectedfavAntenatal"] = {};
    states["selectedBloodPressure"] = "";
    states["lastMenstrualDate"]= moment(new Date()).format('YYYY-MM-DD');
    states["presentDate"] = moment(new Date()).format('YYYY-MM-DD');
    states["presentation"] = "";
    states["remarks"] = "";

    this.setState({ states });
  }

  addHeightToList = (event) => {
    let { newHeightofuterus, favHeightofuterus } = this.state;
    let field = this.state;

    if (newHeightofuterus) {
      favHeightofuterus.push(newHeightofuterus);
      field["favHeightofuterus"] = favHeightofuterus;

      field["selectedHeightofuterus"] = {
        value: newHeightofuterus,
        label: newHeightofuterus,
      };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newHeightofuterus"] = "";
      field["isHeightofuterus"] = false;

      this.setState({ field });
    }
  };

  addWeightToList = (event) => {
    let { newWeight, favWeight } = this.state;
    let field = this.state;

    if (newWeight) {
      favWeight.push(newWeight);
      field["favWeight"] = favWeight;
      field["selectedWeight"] = { value: newWeight, label: newWeight };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newWeight"] = "";
      field["isWeight"] = false;

      this.setState({ field });
    }
  };

  addBloodList = (event) => {
    let { newBloodPressure, favBloodPressure } = this.state;
    let field = this.state;

    if (newBloodPressure) {
      favBloodPressure.push(newBloodPressure);
      field["favBloodPressure"] = favBloodPressure;
      field["selectedBloodPressure"] = {
        value: newBloodPressure,
        label: newBloodPressure,
      };
      // field["selectedHistory"] =  { value: newHistory, label: newHistory };
      field["newBloodPressure"] = "";
      field["isBloodPressure"] = false;

      this.setState({ field });
    }
  };

  triggerNewHeightadd = () => {
    let states = this.state;

    states["isHeightofuterus"] = true;
    this.setState({ states });
  };

  triggerNewWeightAdd = () => {
    let states = this.state;

    states["isWeight"] = true;
    this.setState({ states });
  };

  triggerNewBloodadd = () => {
    let states = this.state;
    states["isBloodPressure"] = true;
    this.setState({ states });
  };

  renderIconNewBtn = (
    icon,
    text,
    createBtn,
    pressEvent = false,
    callBack = null,
    disabled = false
  ) => {
    return (
      <TouchableOpacity
        onPress={pressEvent ? callBack : null}
        disabled={disabled}
      >
        <View
          style={[
            {
              // height: 30,
              // width: text === "New" ? 80 : 103,
              // justifyContent: "center",
              // alignItems: "center",
              // borderRadius: createBtn || text === "New" ? 15 : 8,
              flexDirection: "row",
              backgroundColor: createBtn
                ? color.white
                : text === "New"
                  ? color.white
                  : color.themePink,
              // justifyContent: "space-evenly",
              borderWidth: createBtn || text === "New" ? 1 : 0,
              borderColor: text === "New" ? "#848484" : color.white,
            },
            Style.componentBtnDDPadding,
          ]}
        >
          <Icon
            style={{ marginRight: 15 }}
            name={icon}
            size={platform === "web" ? 15 : 20}
            color={text === "New" ? "#848484" : "#ffffff"}
          />
          <Text
            style={{
              fontSize: platform === "web" ? 13 : 13,
              color: text === "New" ? "#848484" : color.white,
              fontWeight: "700",
            }}
          >
            {text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderInvestigationRow(title, key, value, disable) {
    return (
      <View>
        <View style={{ flexDirection: "row", alignItems: 'center' }}>
          <View style={{ flex: 0.6, marginBottom: 10, justifyContent: "center" }}>{this.rowHeadingText(title)}</View>

          <View style={[{ flex: 0.4, borderRadius: 5, marginTop: 55, paddingLeft: 10, paddingBottom: 10, height: 30, justifyContent: "center" }, styles.shadow,]}>
            {this.renderTextBox(key, value, 70, "", disable)}
          </View>
        </View>
      </View>
    );
  }
  rowHeadingText(title) {
    return (
      <Text style={{ fontSize: 15, fontWeight: "500", marginTop: 20 }}>
        {title}
      </Text>
    );
  }

  renderTextBox(key, value, width, backgroundTheme = false, disable) {
    return (
      <TextInput
        disabled={disable == "hide" ? true : false}
        // placeholder={"Enter Values"}
        style={[
          {
            marginTop: 10,
            paddingLeft: backgroundTheme ? null : 10,
            height: 30,
            backgroundColor: "transparent", height: 25,
            width: 50,
            // fontSize: this.state.exerciseTime.length > 0 ? 12 : 8,

            // borderWidth: 1,
            // borderColor: backgroundTheme ? color.themeDark : "#888888",
            // width: width,
          },
          Style.allButtonBorderRadius,
          // backgroundColor:backgroundTheme ?color.themeDark :null
          backgroundTheme
            ? {
              backgroundColor: color.themeDark,
              textAlign: "center",
              color: color.white,
            }
            : {},
        ]}
        value={value}
        onChangeText={(text) => {
          var states = this.state;
          states[key] = text;
          this.setState({
            states,
          });
        }}
      />
    );
  }

  render() {
    // console.log("patientAppointment ++++++++++", this.props.patientAppointment);
    // console.log("patientInfo ++++++++++", this.props.patientInfo);
    const dateFormat = "YYYY-MM-DD";
    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            if (this.state.newHeightofuterus) {
              this.addHeightToList();
            } else if (this.state.newWeight) {
              this.addWeightToList();
            } else if (this.state.newBloodPressure) {
              this.addBloodList();
            }
          }}
        >
          <View style={styles.container}>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View style={{ width: "80%" }}>
                <Text
                  style={{ fontSize: 20, fontWeight: "500", marginTop: 10, marginLeft: 10 }}
                >
                  Antenatal Chart
                </Text>
              </View>
              <View style={{ width: "20%", marginTop: 10 }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: color.themeDark,
                    borderRadius: 8,
                    paddingRight: 10,
                    paddingLeft: 30,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  onPress={() => {
                    this.clearAllValues();
                  }}
                >
                  <Text style={{ fontSize: 15, color: "white" }}>{"New"}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow,]}>
              {this.getDateValue(
                "Previous Antenatal Chart",
                "selectedfavAntenatal",
                this.state.selectedfavAntenatal,
                this.state.favAntenatal
              )}
            </View>

            <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row", alignItems: "flex-start", paddingTop: 5, paddingVertical: 5 }]}>
              <View style={{ flex: 0.5, marginRight: 10 }}>
                {this.renderInvestigationRow(
                  "Gravida",
                  "gravida",
                  this.state.gravida,
                  this.state.id ? "hide" : "no"
                )}
              </View>
              <View style={{ flex: 0.5, }}>
                {this.renderInvestigationRow("Para", "para", this.state.para, this.state.id ? "hide" : "no")}
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow, { flexDirection: "row", alignItems: "flex-start", paddingTop: 5, paddingVertical: 5, flex: 1, }]}>
              <View style={{ flex: 0.50, }}>
                {this.renderInvestigationRow(
                  "Live Births",
                  "liveBirth",
                  this.state.liveBirth,
                  this.state.id ? "hide" : "no"
                )}
              </View>
              <View style={{ flex: 0.50, marginLeft: 10 }}>
                {this.renderInvestigationRow(
                  "Abortions",
                  "abortions",
                  this.state.abortions,
                  this.state.id ? "hide" : "no"
                )}
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>
              {/* <View style={styles.shadow}> */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",

                }}
              >
                <View style={{ width: "60%" }}>
                  <Text
                    style={{ fontSize: 15, fontWeight: "500", marginBottom: 8 }}
                  >
                    Last Menstrual period
                </Text>
                </View>
                <View style={{ width: "40%" }}>
                  <DatePicker
                    disabledDate={current => {
                      return current && current > moment().add(0, "days");
                    }}
                    disabled={this.state.id ? true : false}
                    value={
                      this.state.lastMenstrualDate!==""
                        ? moment(this.state.lastMenstrualDate, dateFormat)
                        : this.state.lastMenstrualDate
                    }
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");

                      this.setState({ lastMenstrualDate: converted_date }, () => {
                        this.updateState(
                          "lastMenstrualDate",
                          this.state.lastMenstrualDate
                        );
                      });
                    }}
                    defaultValue={"From"}
                    format={"DD-MM-YYYY"}
                    placeholder={"Select"}
                    style={{
                      borderColor: color.themeDark,
                      borderWidth: 1,

                      height: 30,
                      // borderWidth: 1,
                      // borderColor: color.themeDark,
                      // borderRadius: 5,
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginRight: 10,
                      width: "100%",
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",
                  marginBottom: 10,
                }}
              >

                <View style={{ width: "60%" }}>
                  <Text
                    style={{ fontSize: 15, fontWeight: "500", marginTop: 10 }}
                  >
                    Date
                </Text>
                </View>
                <View style={{ width: "40%" }}>
                  <DatePicker
                    disabledDate={current => {
                      return current && current > moment().add(0, "days");
                    }}
                    value={
                      this.state.presentDate
                        ? moment(this.state.presentDate, dateFormat)
                        : this.state.presentDate
                    }
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");

                      this.setState({ presentDate: converted_date }, () => {
                        this.updateState("presentDate", this.state.presentDate);
                      });
                    }}
                    // defaultValue={"From"}
                    format={"DD-MM-YYYY"}
                    placeholder={"Select"}
                    style={{
                      borderColor: color.themeDark,
                      borderWidth: 1,

                      height: 30,
                      // borderWidth: 1,
                      // borderColor: color.themeDark,
                      // borderRadius: 5,
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginRight: 10,
                      width: "100%",
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>
              {this.commonOvulation(
                "Height of the Fetus (cm)",
                "selectedHeightofuterus",
                this.state.selectedHeightofuterus,
                this.state.favHeightofuterus
              )}
              {this.state.isHeightofuterus ? (
                // <View style={[Style.doctorNotesSectionView,styles.shadow,{flexDirection:"row"}]}> 

                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newHeightofuterus}
                    onChangeText={(text) => {
                      this.setState({ newHeightofuterus: text });
                    }}
                    onSubmitEditing={this.addHeightToList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewHeightadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>

            <View style={[Style.doctorNotesSectionView, styles.shadow]}>
              {this.commonOvulation(
                "Weight of the Fetus (gm)",
                "selectedWeight",
                this.state.selectedWeight,
                this.state.favWeight
              )}
              {this.state.isWeight ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newWeight}
                    onChangeText={(text) => {
                      this.setState({ newWeight: text });
                    }}
                    onSubmitEditing={this.addWeightToList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewWeightAdd.bind(this),
                    this.state.isLocked
                  )
                )}

            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>


              {this.commonOvulation(
                "Blood Pressure (mm/Hg)",
                "selectedBloodPressure",
                this.state.selectedBloodPressure,
                this.state.favBloodPressure
              )}
              {this.state.isBloodPressure ? (
                <View
                  style={[
                    {
                      height: 30,
                      marginTop: 10,
                      // backgroundColor: color.white,
                      // borderRadius: 20,
                      borderWidth: 1,
                      borderColor: color.black,
                      width: "70%",
                      zIndex: 101,
                    },
                    Style.allButtonBorderRadius,
                  ]}
                >
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    placeholderTextColor={color.black}
                    maxLength={30}
                    style={{
                      color: color.black,
                      height: 30,
                      paddingLeft: 5,
                      marginRight: 0,
                      flex: 1,
                      textAlign: "center",
                    }}
                    ref={(text) => (this.nameInput = text)}
                    // defaultValue=""
                    autoCapitalize="none"
                    value={this.state.newBloodPressure}
                    onChangeText={(text) => {
                      this.setState({ newBloodPressure: text });
                    }}
                    onSubmitEditing={this.addBloodList.bind(this)}
                  />
                </View>
              ) : (
                  this.renderIconNewBtn(
                    "plus",
                    "New",
                    true,
                    true,
                    this.triggerNewBloodadd.bind(this),
                    this.state.isLocked
                  )
                )}
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>

              {this.renderRichTextBox(
                "Presentation",
                "presentation",
                this.state.presentation
              )}
            </View>
            <View style={[Style.doctorNotesSectionView, styles.shadow]}>

              {this.renderRichTextBox("Remarks", "remarks", this.state.remarks)}
            </View>
            <CommonAddButton
              onPressAddButton={this.onPressAddButton.bind(this)}
            />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    flex: 1,
  },
  shadow: {
    width: "100%", shadowColor: "#000",
    marginTop: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5, backgroundColor: "white"
  }
});
