import React, { Component } from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import OpthamologyService from "../../../network/OpthamologyService";
import ImagePath from "../../../utils/ImagePaths";
import { Tooltip } from 'antd';
import  CommonDateFilter  from '../BaseComponent';
import moment from "moment";    

export default class OTlist extends Component {
    constructor(props) {
      super(props);

        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");

        let selectedClinicOrDoctor = {}
        try {
        selectedClinicOrDoctor = this.props.selectedClinicOrDoctor;
        } catch (error) { }

      this.state = {
        selectedClinicOrDoctor: selectedClinicOrDoctor,
        otListData: [],
        filterData: {
            fromDate: converted_date,
            toDate: converted_date,
          },
      }
    }

    componentDidMount(){
        this.otListGet()
    }

    showSelectedFilteredDate = (date) => {
        var states = this.state;
        var {filterData} = this.state;
        states["filterDate"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate;
        this.setState({ states,filterData })
        this.otListGet()
    }

    dateFilter = () => {
        return(
          <CommonDateFilter removeObject={["all", "yesterday", "last7days", "last30days", "last90days", "last12Months"]} defaultSelectedDateFilter={"Today"} startDate={this.state.filterData.fromDate} endDate={this.state.filterData.toDate} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} filterDate={this.state.filterDate} />
        )
    }

    printIcon = () => {
        var disable = false
        if((this.state.otListData).length === 0){
            disable = true
        }else{
            disable = false
        }
        return(
            <TouchableOpacity 
            onPress={() => {this.onPressPrint()}}
            disabled={disable}
            style={{backgroundColor: disable ? color.disableLabelColor : color.themeDark, borderRadius: 10, alignSelf: "center"}}>
                <View style={{paddingVertical: 7, paddingHorizontal: 4}}>
                <Image 
                source={ImagePath.PrintIcon}
                 style={{ height: 35, width: 35, }} />
                </View>
            </TouchableOpacity>
        )
    }

    onPressPrint = () => {
        var serviceUrl = Constants.COUNSELLOR_OT_LIST_GET + 
        "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&from_date=" + 
        this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate + "&export=PDF"
        OpthamologyService.getInstance().documentUploadGet(
        serviceUrl,
        this.onPressPrintSuccess,
        this.onPressPrintFailure,
        "pdf"
        );
    }

    onPressPrintSuccess = (response) => {
        if (response) {
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    otListGet = () => {
        var serviceUrl = Constants.COUNSELLOR_OT_LIST_GET + 
      "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&from_date=" + this.state.filterData.fromDate + "&to_date=" + this.state.filterData.toDate

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.otListGetSuccess,
      this.otListGetFailure
    );
    }

    otListGetSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                otListData: response.data
            })
        }
    }

    renderTopHeader = () => {
        return(
            <View
        style={{
          height: 80,
          flexDirection: "row",
          paddingLeft: 5,
          paddingRight: 5,
          alignItems: "center",
          marginHorizontal: 15,
          zIndex: 1,
          justifyContent: "flex-start" //"space-evenly"
        }}
      >
        <View style={{ justifyContent: 'flex-start',alignItems:'center', height: 30, flex: 0.7,flexDirection:'row' }}>
           
          <Text style={{ fontSize: 17, fontWeight: "500", minWidth:"fit-content"}}>
            {"OT LIST"}
          </Text>
        </View>
        <View style={{flex: 0.3, flexDirection: "row", justifyContent: "space-around"}}>
            {this.dateFilter()}
            {this.printIcon()}

        </View>
        </View>
        )
    }

    renderTableHeader = () => {
        return(
            <View
            style={[
              {  
                alignSelf: 'center',
                width: "97%",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: color.appointmentTableHead,
                height: 50,
                borderRadius: 4,
                marginTop:5
              }]}>
              <View style={{ paddingLeft: 20, width: "15%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>PATIENT'S DETAILS</Text>
              </View>
              <View style={{ paddingLeft: 20, width: "15%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>REFERRED DOCTOR</Text>
              </View>
              <View style={{ paddingLeft: 20, width: "10%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>SURGERY</Text>
              </View>
              <View style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>PACKAGE</Text>
              </View>
              <View style={{ paddingLeft: 20, width: "10%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>SURGERY DATE</Text>
              </View>
              <View style={{ paddingLeft: 20, width: "15%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>SURGEON</Text>
              </View>
              <View style={{ paddingHorizontal: 20, width: "15%", alignItems: "flex-start" }} >
                <Text style={Style.appointmentTableHeadText}>ANAESTHETIST</Text>
              </View>
      
            </View>
        )
    }

    renderTableContent = () => {
        var OtData = this.state.otListData
        return(
            <View>
                {OtData && OtData.length > 0 && OtData.map((item, index) => (
                    <View
                    style={[
                        {  
                          width: "97%",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingVertical: 10,
                          alignSelf: 'center',
                          borderRadius: 4,
                        }]}>
                        <View style={{ paddingLeft: 20, flexDirection: "row", width: "15%", alignItems: "flex-start" }}>
                            <Image
                            source={item.patient_photo_url
                                ? { uri: item.patient_photo_url }
                                : ImagePath.DefaultProfile
                            }
                            style={[Style.appointmentTableRowImg,{borderWidth:2}]}
                            />
                            <View style={{ marginLeft: 5 }}>
                                <View style={{ flexDirection: "row", alignItems: "center"}}>
                                <Tooltip placement="topLeft" title={item.patient_name}>               
                                    <Text style={{fontSize: 14, fontWeight: "500"}}>
                                    {item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}
                                    </Text>
                                </Tooltip> 
                                    <Text
                                    style={{
                                        fontSize: 11,
                                        fontWeight: "500",
                                        marginLeft: 5
                                    }}>
                                        ({item.patient_age ? (item.patient_age) : null})
                                    </Text>

                                </View> 
                                <Text
                                    style={{
                                    fontSize: 11,
                                    marginTop: 5,
                                    marginBottom: 5
                                    }}
                                >
                                    {item.acc_num}
                                </Text>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 20, width: "15%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                textAlign: "center"
                            }} >
                                {item.doctor_name}
                            </Text>
                        </View>
                        <View style={{ paddingLeft: 20, width: "10%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                textAlign: "center"
                            }} >
                                {item.surgerys_name}
                            </Text>
                        </View>
                        <View style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                // textAlign: "center"
                            }} >
                                {item.packages_name}
                            </Text>
                        </View>
                        <View style={{ paddingLeft: 20, width: "10%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                textAlign: "center"
                            }} >
                                {item.surgery_date}
                            </Text>
                        </View>
                        <View style={{ paddingLeft: 20, width: "15%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                textAlign: "center"
                            }} >
                                {item.surgeon_name}
                            </Text>
                        </View>
                        <View style={{ paddingHorizontal: 20, width: "15%", alignItems: "flex-start" }}>
                            <Text 
                            style={{
                                fontSize: 12,
                                textAlign: "center"
                            }} >
                                {item.anesthetist}
                            </Text>
                        </View>
                    </View>
                        
                ))}
            </View>
        )
    }

    render(){
        return(
            <View>
                {this.renderTopHeader()}
                {this.renderTableHeader()}
                {this.renderTableContent()}
            </View>
        )
    }
}