//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { color } from '../../../../styles/Color';
import { HistoryYesOrNoSection, CommonButton, LabelWithTextBoxDR, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import Style from "../../../../styles/Style";

const pageName = "isMedicalProblemsNew"

// create a component

const problemsList = [
    {label:"Obesity", value:"Obesity"},
    {label:"Acid Refux (GERD)", value:"Acid Refux (GERD)"},

]

class MedicalProblemsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            selectedProblems:{},
            problemsList:[],
            showProblemsNewButtonFlag: true,
            id:"",
            newButtonValue: "",

            description:"",
            isProblems:false,
        }
    }

    componentDidMount(){
        this.getMedicalProblemsTemp()  
    }

    componentWillReceiveProps(props) {

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var {problemsList ,isProblems} = this.state;
                var states = this.state;
                var checkSurgery = problemsList.includes(data.surgery)
                var preparData = { label: data.surgery, value: data.surgery }

                if(data.surgery){
                    isProblems=true
                }

                if(checkSurgery){
                    states["selectedProblems"] = preparData;
                }else{
                    problemsList.push(data.surgery);
                    problemsList = this.removeDuplicate(problemsList);
                    states["selectedProblems"] = preparData;
                }
                states["id"] = data.id;
                states["description"] = data.description;

                this.setState({ states ,problemsList,isProblems}, () => {
                    this.props.editHistory({}, pageName)
                })
            }
        }
    }


    getMedicalProblemsTemp(){
        var service_url = Constants.GET_PAEDIATRIC_TEMPLATE;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getMedicalProblemsTempSuccess,
            this.getMedicalProblemsTempFailure
        );
    }

    getMedicalProblemsTempSuccess= success=>{
        if(success.status == "success"){
            this.setState({
                problemsList:success.data
            })
        }
    }
    getMedicalProblemsTempFailure= error=>{

    }

    clearAllData(){
        var states = this.state;
        states["selectedProblems"]={};
        states["id"]="";
        states["description"]="";
        states["isProblems"]=false
        this.setState({ states }) 
     }
    onPressButton(key,selectedValue ) {
        var states = this.state;
        if(key == "showProblemsNewButtonFlag"){
            states[key] = false;
        }else{
            states[key] = selectedValue;
        }
        this.setState({ states })   

    }
    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var states = this.state;
        var { problemsList } = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }

        if (!this.state.showProblemsNewButtonFlag) {
            problemsList.push(this.state.newButtonValue);
            problemsList = this.removeDuplicate(problemsList);
            states["newButtonValue"] = "";
            states["showProblemsNewButtonFlag"] = true;
            states["selectedProblems"] = preparData;
        }
        this.setState({
            problemsList, states
        })
    }

    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        var data = this.removeDuplicate(setOfData)
        return (
            <View style={[Style.historyYesOrNoSectionShadow, Style.allButtonBorderRadius, { marginTop: 10, paddingLeft: 20, }]}>
                <Text style={{ fontSize: 14, marginTop: 10, marginBottom: 5 }}>{question}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                    {
                        data.map((item, index) => {
                            var changedItem = {}
                            if (key == "selectedProblems") {
                                changedItem = { label: item, value: item }
                            } else {
                                changedItem = item
                            }
                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={changedItem}
                                        selectedvalue={selectedValue}
                                        butttonText={changedItem.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        disable={this.state.isProblems}
                                        buttonKey={key} />
                                </View>
                            )
                        })
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground,paddingLeft:10 }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    onChangeTextBoxValue(value, key) {
        var states = this.state;
        states[key] = value;
        this.setState({ states })
    }

    onPressAddButton(){
        var states = this.state;
        var service_url = Constants.MEDICAL_PROBLEMS;
        var data ={
            "patient_id":states.patientAppointment.patient_id,
            "surgery":states.selectedProblems.value,
            "description":states.description
        }
        if(this.state.id){
            data["id"] = this.state.id
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveDiabeticHistorySuccess,
            this.saveDiabeticHistoryFailure
        );
    }

    saveDiabeticHistorySuccess = success=>{
        if(success.status=="success"){
            this.props.showResposeValue('success', success.message);
            this.clearAllData();
            this.props.refreshRighSideComponent(pageName);
        }else{
            this.props.showResposeValue('error', success.message);
        }
    }
    saveDiabeticHistoryFailure = error=>{
        this.props.showResposeValue('error', error.message);
    }



    render() {
        return (
            <View>
                <DoctorNotesCommonRightSideHeader title={"Medical Problems"} clearAllData={this.clearAllData.bind(this)} />

                {
                    this.renderLabelWithButtonGroup(
                        "Problems",
                        "selectedProblems",
                        this.state.selectedProblems,
                        "showProblemsNewButtonFlag",
                        this.state.showProblemsNewButtonFlag,
                        this.state.problemsList)
                }
                <LabelWithTextBoxDR maxTextLength={100} label={"Description"} value={this.state.description} textBoxKey={"description"} onChangeTextBoxValue={this.onChangeTextBoxValue.bind(this)} />
                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />

            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default MedicalProblemsNew;
