import React from "react";
import {
  View,
  Text,
  // FlatList,
  ScrollView,
  Image,
  Platform,
  TouchableOpacity, 
  StyleSheet,
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import CameraIcon from "../../../../assets/images/opthamology/Camera.svg"
import UploadIcon from "../../../../assets/images/opthamology/Upload.svg"
import { TextInput } from 'react-native-paper';
// import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Icon from "react-native-vector-icons/AntDesign";
import Icons from "react-native-vector-icons/Entypo"
// import Constant from 'expo-constants';
// import * as DocumentPicker from 'expo-document-picker';

// import DocumentPicker from 'react-native-document-picker';

// import AsyncStorage from "../../../AsyncStorage";

import { CommonSectionHeader, CommonHistorySectionHeader } from "../BaseComponent"
import DocCameraFunction from "../../camera/documentCamFunction"
import { StylesContext } from "@material-ui/styles";
import { Tooltip } from 'antd';
import AWSFileUpload from "../../../utils/awsfileupload";


const platform = Platform.OS;
var s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});
export default class SystemicIllnessNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      favoriteDrugs: [],
      selectedEye: "",
      id: null,
      name: "",
      year: null,
      month: null,
      day: null,
      complaints: "",
      comments: "",
      treatment: "",

      isEdit: false,
      newItem: '',

      image:null,
      imageName:"",
      imageComments:"",

      buttonDisable:false,
      isCamClick:false,
      tabSelection : this.props.tabSelection,
      commentEnable : false,
      selectedTransmitID : "",
      selectedTransmitImages : [],
      convertedTransmittedImage : [],
      selectedImage : {},
      selectedTestDetails: {},
      selectedIndex : 0,
      doctorCommentUpdate: false,
      doctorComments: "",
      editedDoctorComments: "",
      clearData: false
    };
  }

   getImage =""
  componentDidMount() {
    // const loggedIn = await AsyncStorage.getItem("loggedIn");
    this.getFavoriteDrugs();
    // this.getPermissionAsync();
    this.getTransmittedImages();

  }

  componentWillReceiveProps(props) {

    let { id, favoriteDrugs } = this.state;
    if( this.props && (this.state.selectedTransmitID  != this.props.selectedTransmitID ) ) {
         this.setState({
          selectedTransmitID : this.props.selectedTransmitID
         },()=>{
          this.getTransmittedImages();
         })
    }

    if (props.editItem) {

      if (props.editItem.title === "illness") {

        let item = props.editItem.item;

        if (id !== item.id) {


          // selectedEye have to set up here
          let selectedEye = "";

          favoriteDrugs.map((favItem) => {
            if (favItem.label === item.illness_name) {
              selectedEye = favItem.value;
            }
          })

          this.setState({
            id: item.id,
            selectedEye: selectedEye,
            illness_name: item.illness_name,
            year: item.year,
            month: item.month,
            day: item.day,
            treatment: item.treatment
          });
        }
      }
    }
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    this.setState({
      states
    })
  }

  getTransmittedImages = () => {
   
    let service_url = Constants.OPT_TRANSMITED_IMAGE +"?lab_img_bill_items_id=" + this.state.selectedTransmitID;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTransmittedImagesSuccess,
      this.getTransmittedImagesFailure
    );
  }
  getTransmittedImagesSuccess = (response) => {
     if(response.status === "success") {
        this.setState({
          selectedTransmitImages: response.data.result_image_data,
          selectedTestDetails: response.data.lab_img_bill_details,
          doctorComments: response.data.lab_img_bill_details.doctor_comments,
          editedDoctorComments: response.data.lab_img_bill_details.doctor_comments

        },()=>{
          this.renderTransmitedImage(this.state.selectedTransmitImages);
        })
     }
  }
  async renderTransmitedImage(showTransmittedImage){
    let tempArr = [];

    for(var i=0;i<showTransmittedImage.length; i++){      
      var doc_key = showTransmittedImage[i].result_img_url
      var lab_img_result_id = showTransmittedImage[i].lab_img_result_id
      var getData = await this.getS3Image( doc_key, lab_img_result_id )
      tempArr.push(getData)
    }
    this.setState({ 
      convertedTransmittedImage : tempArr,
      selectedImage: tempArr.length > 0 ? tempArr[0] : {},
      selectedIndex: 0,
  })
  
  } 
  getS3Image(doc_key,id) {


    return new Promise(resolve => {
      var parms = { 
        Bucket: Constants.s3BUCKET_NAME, 
        // Bucket: "emedhub-stage",
        Key: doc_key, Expires: 300 };
      s3.getSignedUrl("getObject", parms, (err, doc_key) => {
        if (err) {
          console.log(err);
          resolve(err)
        } else {
          var data = {
            "doc_key": doc_key,
            "lab_img_result_id": id,
          }
          resolve(data)
        }
      });
    });
  }
  getFavoriteDrugs = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_ILLNESS;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
    );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];
      let data = response.data;

      if (data && data.map) {

        data.map((item) => {

          drugs.push({ value: item, label: item });
        });
      }

      //if(drugs.length === 0){ drugs = [{ "value": "Illness One", "label": "Illness One" }]; }

      var field = this.state;
      field["favoriteDrugs"] = drugs;
      this.setState({ field });

    }
  }

  dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


}

// binEncode(data) {
//   var binArray = []
//   var datEncode = "";

//   for (var i=0; i < data.length; i++) {
//       binArray.push(data[i].charCodeAt(0).toString(2)); 
//   } 
//   for (var j=0; j < binArray.length; j++) {
//       var pad = padding_left(binArray[j], '0', 8);
//       datEncode += pad + ' '; 
//   }
//   function padding_left(s, c, n) { if (! s || ! c || s.length >= n) {
//       return s;
//   }
//   var max = (n - s.length)/c.length;
//   for (var i = 0; i < max; i++) {
//       s = c + s; } return s;
//   }
//   console.log(binArray);
// }

  addComplaint = event => {
    if(this.state.imageComments !== ""){

    this.setState({
      buttonDisable:true
    })
    // console.log("image-----------------> "+this.getImage);
    // console.log("cmd-----------------> "+this.state.imageComments );
    // console.log("appointment_id-----------------> "+ this.state.patientAppointment.appointment_id);

    var service_url = Constants.DOCUMENT_UPLOAD;

    const data = new FormData();

    // var getSeletedImage = this.state.image

    var report_extension =  this.state.imageName.substring(5 )
    data.append('report_photo',this.dataURItoBlob(this.getImage))
    data.append('report_extension',report_extension)

    // data.append('report_photo',this.binEncode(this.getImage))

    data.append('appointment_id', this.state.patientAppointment.appointment_id)
    data.append('doc_name', this.state.imageComments)


  //   for(var checkData of data.entries()) {
  //  }


    // var prepareRequestData ={
    //   "appointment_id":this.state.patientAppointment.appointment_id,
    //   "report_photo":data,
    //   "doc_name":this.state.imageComments
    // }


      OpthamologyService.getInstance().documentUploadPost(
        service_url,
        data,
        this,
        this.documentUploadSuccess,
        this.documentUploadFailure,
        'multipart/form-data'
      );
    }else{
      this.props.showResposeValue("error","Please Enter the Document Name")
    }
  };

  documentUploadSuccess = success =>{
    // console.log("document upload upload success ============> "+JSON.stringify(success));
    
    if(success.data.status == "success"){
      this.setState({
        buttonDisable:false
      })
      this.props.refreshData("documents");
      // alert(JSON.stringify(success))
      this.setState({imageName:""})
      this.props.showResposeValue("success",success.data.message)
    }else{
      this.setState({
        buttonDisable:false
      })
      // alert(JSON.stringify(success))
      this.props.showResposeValue("error",success.data.message)

    }
  }
  documentUploadFailure = error =>{
    // alert(error.message)
    this.setState({
      buttonDisable:false
    })
    // console.log("document upload upload fail  ============> "+JSON.stringify(error));
    this.props.showResposeValue("error",error.data.message)
  }

  // getMedicationSuccess = response => {
  //   if (response.status === "success") {
  //     var field = this.state;
  //     field["complaintsData"] = response.data;
  //     this.setState({ field });

  //     // clear data
  //     this.clearComplaint();

  //     // reload data
  //     this.props.refreshData("systemic");
  //   }
  // };

  // getMedicationFailure = error => {
  //   console.log("opthamology complaints GET error response");
  //   console.log(error);
  // };

  clearComplaint = event => {

    let states = this.state;
    states['selectedEye'] = "";
    states['illness_name'] = "";
    states['year'] = "";
    states['month'] = "";
    states['day'] = "";
    states['treatment'] = "";

    this.setState({ states });

  }

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  addToSuggestionList = (event) => {

    // console.log(" addMedicationToList " + this.state.newItem)

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    if (newItem) {

      favoriteDrugs.push({ value: newItem, label: newItem });

      field["favoriteDrugs"] = favoriteDrugs;
      field["selectedEye"] = newItem;
      field["newItem"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }

  onClickCam = () => {
    let isCamClick = this.state.isCamClick
    this.setState({
      isCamClick: !isCamClick
    })
  }

  onConfirmPress = (profilePhoto) => {
    if (profilePhoto != null) {

      this.getImage = profilePhoto
    }
    if(profilePhoto.substring(11,14) === "png"){
      var imageName=profilePhoto.substring(5,10)+"."+profilePhoto.substring(11,14)
      this.setState({
        imageName:imageName,
        isCamClick: false,
      })
    }else if(profilePhoto.substring(11,15) === "jpeg"){
      var imageName=profilePhoto.substring(5,10)+"."+profilePhoto.substring(11,15)
      this.setState({
        imageName:imageName,
        isCamClick: false,
      })
    }

  }
  renderCommonHeader(title1, title2){
    return(
      <View>
        <CommonHistorySectionHeader
                heading1={title1}
                heading2={title2}
                noOfColumn={2}
                columnSize={[0.5, 0.5]}
        /> 
      </View>
    )
  }
  saveDoctorComment = () =>{
    
    var data = {
        "lab_img_bill_item_id": this.state.selectedTransmitID,
        "doctor_comments": this.state.editedDoctorComments
    }
    var service_url = Constants.OPT_IMG_DOCTOR_COMMENT;
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.saveDoctorCommentSuccess,
        this.saveDoctorCommentFailure
      );
  }
  saveDoctorCommentSuccess = (response) =>{
    if(response.status === "success") {
      this.props.showResposeValue("success", response.messgae)
      this.setState({
        commentEnable : false,
        doctorCommentUpdate: false,
        editedDoctorComments: ""
      })
      this.getTransmittedImages()

    }
  }
  saveDoctorCommentFailure = (error) => {
    this.props.showResposeValue("error", error.message)

  }
  renderTransmittedView = () => {
    return (
      <View>
        <TouchableOpacity  
           style={styles.mainContainer}
           onPress={()=>{
                   this.props.showDocumentImage(true, this.state.selectedImage.doc_key, this.state.convertedTransmittedImage)
           }}
        >
          <Image source={this.state.selectedImage.doc_key} style={styles.TransmittedImgView}></Image>
        </TouchableOpacity>
        <View style={{ flexDirection: "row" }}>
        <ScrollView showsHorizontalScrollIndicator = {true}  showsVerticalScrollIndicator={true}  horizontal={true}  style = {{ flexDirection : "row", borderWidth: 2, borderColor: color.lightGray, marginTop: '1.2vw' }} >
            { this.state.convertedTransmittedImage && this.state.convertedTransmittedImage.length > 0 && this.state.convertedTransmittedImage.map((item, index) => { 
              var count = (index.length - 3)
            return(   
            <View>  
              <TouchableOpacity style={[styles.imageContainer, {backgroundColor : this.state.selectedImage.lab_img_result_id == item.lab_img_result_id? color.themeDark : null, margin: 10}]} 
             onPress= {() => {
                 this.setState({
                   selectedImage : item,
                   selectedIndex : index
                 })
             }}>
               <Image source={ item.doc_key } style={styles.TransmittedImgView1}></Image>
             </TouchableOpacity>
            {/* <View style={styles.countContainer}>
               { index > 4 ? <Text style={{ color: color.themeDark, fontWeight: "500", fontSize: 18 }}>{count}</Text> : null }
            </View> */}
           </View> 
            )}) }
         </ScrollView>  
         
        </View>
        {/* <View style={styles.subContainer}>
          <TouchableOpacity style={styles.imageChange} onClick = { ()=> {
                if(this.state.selectedIndex > 0 ) {
                   this.setState({
                     selectedIndex : this.state.selectedIndex + 1
                   })
                }
          } }>
            <Icon size={18} name={"arrowleft"} color={color.white} />
          </TouchableOpacity>
          <TouchableOpacity style={[styles.imageChange, { marginLeft: 10 }]}onClick = { ()=> {
                if(this.state.selectedIndex > 0 ) {
                   this.setState({
                     selectedIndex : this.state.selectedIndex + 1
                   })
                }
          } }>
            <Icon size={18} name={"arrowright"} color={color.white} />
          </TouchableOpacity>
        </View> */}
       
        <View style={{ marginTop: '3.3vw' }}>
          {this.renderCommonHeader("Date", "Test Name")}
          <View style={styles.HeaderView}>
            <Text style={{ flex: 0.5 }}>{this.state.selectedTestDetails.bill_date}</Text>
            <Text style={{ flex: 0.5 }}>{this.state.selectedTestDetails.test_name}</Text>
          </View>
          {this.renderCommonHeader("Comments", "Uploaded By")}
          <View style={styles.HeaderView}>
            <Text style={{ flex: 0.5 }}>{this.state.selectedTestDetails.comments}</Text>
            <Text style={{ flex: 0.5 }}>{this.state.selectedTestDetails.uploaded_by}</Text>
          </View>
         {  (this.state.doctorComments != "" && this.state.doctorComments != null && this.state.doctorCommentUpdate === false) ? 
         <View style={{ flexDirection: "row" }}>
           <TextInput
              value= {this.state.doctorComments}
              placeholder={"Doctor comments"}
              style={{ width: "80%",height: "30%", borderColor: color.lightGray, borderWidth: 1,backgroundColor: color.white }}
              disabled={ true }
              multiline={ true }
           />
          <TouchableOpacity disabled={this.state.isLocked} style={{ marginLeft: '1.2vw' }}
           onPress={() => {
             this.setState({
               doctorCommentUpdate: true,
               editedDoctorComments: this.state.doctorComments
             })
           }}
          >
                {this.renderIdButton("pencil")}
          </TouchableOpacity>
         </View> : 
         <View>
          { !this.state.commentEnable && !this.state.doctorCommentUpdate ?
          <View>
          <Tooltip placement="topLeft" title={"Doctor Comments"}>               
            <TouchableOpacity onPress={() => {
              this.setState({ commentEnable: !this.state.commentEnable })
            }} style={styles.doctorCommentButton}>
            <Icon size={'1.3vw'} name={"form"} color={color.white} />
            </TouchableOpacity> </Tooltip> 
          </View> : null}
           { this.state.commentEnable || this.state.doctorCommentUpdate ?
            <View style={{ flexDirection: "row " }}>
              <TextInput
                maxLength={250}
                multiline={true}
                placeholder={""} value={this.state.editedDoctorComments}
                onChangeText={(text) => { this.setState({ editedDoctorComments: text }) }}
                style={ styles.doctorComment }
              />
              <View style={ styles.buttonView }>
                <TouchableOpacity 
                onPress={()=>{
                     this.saveDoctorComment()
                }}>
                    <Icon size={'2vw'} name={"checkcircle"} color={color.themeDark} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => { 
                  this.setState({ 
                    editedDoctorComments: "",
                    commentEnable : false,
                    doctorCommentUpdate: false
                  }) }} 
                  style={{  }}>
                   <Icons size={'2.3vw'} name={"circle-with-cross"} color={color.themeDark} />

                </TouchableOpacity>
              </View>
            </View> : null
           } 
          </View> 
         }
        </View>
       
        <TouchableOpacity onPress={() => { 
                  this.setState({ 
                    convertedTransmittedImage : []
                  }) }} 
                  style={{ marginTop: '0.6vw', alignSelf:"center", alignItems :"center", justifyContent: 'center', borderRadius: 8, backgroundColor : color.themeDark, paddingHorizontal: '1vw', height: '2vw' }}>
                  <Text style={styles.buttonText}>{"Cancel"}</Text>
                </TouchableOpacity>

      </View>
    )
  }
  render() {
    return (
     <View> 
       { this.props.tabSelection === "Uploaded Images" ?
      <View>
        {this.state.isCamClick? 
        <DocCameraFunction cameraclick={this.state.isCamClick} onConfirmPress= {this.onConfirmPress}/>
        :null 
  }


{this.renderSubHeadingBorder("Documents", false, true)}
<CommonSectionHeader heading={"Select"}  />
        <View style={{ marginTop: '1.2vw' }}>
         {
           this.state.imageName === ""
          ?
          <View
            style={{
              width: platform === "web" ? "100%" : "75%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              justifyContent: "space-evenly",

            }}
          >
            <View
              style={{
                width: "30%"
              }}
            >
              <TouchableOpacity disabled={this.state.isLocked} onPress={()=>{this.onClickCam()}} >
                <Image source={CameraIcon} style={styles.ImgView} />
                {/* <Text style={{textAlign:'center',marginTop:10,color:"#888888",fontWeight:"800"}}>{"Coming soon..."}</Text> */}
              </TouchableOpacity>
              
            </View>

            <View
              style={{
                width: "30%"
              }}
            >
            <TouchableOpacity disabled={this.state.isLocked} onPress={()=>{this.openImageFolder()}}>
              <Image source={UploadIcon} style={styles.ImgView} />
              </TouchableOpacity>
            </View>



          </View>
          :
          <View style={{flexDirection:'row',width:'90%',marginBottom:'1.2vw',alignContent:'center',alignItems:'center',alignSelf:'center'}}>
            <Image source={this.getImage} style={styles.CaptureImg}/>
            <Text style={{color:'black'}}>{this.state.imageName}</Text>
            <TouchableOpacity onPress={()=>{this.setState({imageName:""})}} style={[styles.CancelIconTouch, {backgroundColor:color.themeDark}]}>
              <Text style={styles.CancelIconText}>{"X"}</Text>
            </TouchableOpacity> 
          </View>
        }


        </View>

        {
          this.state.imageName !== "" ?
        <View>

        <TextInput
          mode='outlined'
          label='Name of the Document'
          selectionColor='Name'
          theme={{ colors: { placeholder: 'black', text: 'black', primary: 'black', underlineColor: 'transparent', background: 'white', borderColor: color.themeDark }, roundness: 10}}
          style={{ height: '2.6vw',outline: "none" }}
          onChangeText={text=>{this.setState({imageComments:text})}}
        />

        <View style={{ marginTop: '1.2vw' }}>
          <TouchableOpacity onPress={this.addComplaint.bind(this)} disabled={this.state.buttonDisable}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
        </View>
      :
      null  
    }

      </View> : 
      
      <View>
        { this.state.convertedTransmittedImage.length > 0 ? 
          <View>{this.renderTransmittedView()}</View> : null
        }
      </View>
      
      }
      {/* <View>
        <AWSFileUpload patient={this.props.patientAppointment}/>
      </View> */}
      </View>
    );

  
  
    // getPermissionAsync = async () => {
    //     const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    //     if (status !== 'granted') {
    //       alert('Sorry, we need camera roll permissions to make this work!');
    //     }
    // };
  
  }


  openImageFolder = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.cancelled) {

        this.getImage = result.uri
        // this.setState({ image: result.uri });
      }

      if(result.uri.substring(11,14) === "png"){
        var imageName=result.uri.substring(5,10)+"."+result.uri.substring(11,14)
        // console.log("result==========> "+JSON.stringify(imageName));
        this.setState({
          imageName:imageName
        })
      }else if(result.uri.substring(11,15) === "jpeg"){
        var imageName=result.uri.substring(5,10)+"."+result.uri.substring(11,15)
        // console.log("result==========> "+JSON.stringify(imageName));
        this.setState({
          imageName:imageName
        })
      }


    } catch (E) {
      console.log(E);
    }
  }

  // openImageFolder = async () => {
  // }
}
const styles = StyleSheet.create({
  mainContainer: { borderWidth : 2, borderColor: color.black,  elevation : 6, padding: '0.6vw', justifyContent :"center", alignSelf : "center", borderRadius : 10 },
  imageContainer : { padding : '0.6vw', borderWidth : 2, borderColor: color.whiteSmoke,  elevation : 6, justifyContent :"flex-start", alignSelf : "flex-start", padding: '0.3vw', borderRadius : 10 },
  countContainer : { justifyContent : "center", alignSelf : "center", margin : '0.6vw'},
  subContainer : { flexDirection : "row", justifyContent : "center", alignSelf : "center", flex : 1 },
  imageChange : { paddingHorizontal: '0.2vw', justifyContent: "center", alignSelf: "center", paddingVertical: '0.1vw', backgroundColor: color.themeDark, borderRadius: 50 },
  doctorCommentButton : { backgroundColor: color.themeDark,  height: '2.3vw', maxWidth : "7%", borderRadius : 4, alignContent : "center", justifyContent : "center",alignItems : "center"},
  textStyle : { fontSize: '0.8vw', color: 'white', fontWeight: 'bold'},
  doctorComment : { paddingLeft: '0.6vw', width: "60%", height: '1.5vw', marginTop: '0.5vw', borderRadius: 4, paddingVertical: '0.6vw', backgroundColor: color.white, borderWidth: 1, borderColor: color.lightGray },
  buttonView : { marginTop: '0.6vw', marginLeft : '0.6vw', flexDirection: "row", alignItems: "center", justifyContent: "center" },
  buttonText : { fontSize: '0.8vw', color: 'white', fontWeight: 'bold' },
  ImgView: {
    tintColor:"#888888", 
    width: '7vw', 
    height: '7vw' 
  },
  CaptureImg: {
    height:'6.3vw',
    width:'6.3vw',
    marginRight:'2vw'
  },
  CancelIconTouch: {
    justifyContent:'center',
    marginLeft:'2vw',
    borderRadius:'3.3vw',
    height:'2vw',
    width:'2vw'
  },
  CancelIconText: {
    color:'white',
    fontWeight:"800",
    textAlign:'center',
    fontSize: '0.9vw'
  },
  TransmittedImgView: { 
    width: '24.5vw', 
    height: '19.2vw' 
  },
  TransmittedImgView1: { 
    width: '8.8vw', 
    height: '6.5vw' 
  },
  HeaderView: { 
    flex: 1, 
    flexDirection: "row", 
    marginLeft : '1.2vw', 
    paddingVertical : '0.6vw' 
  }
});
