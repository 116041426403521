import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Dimensions, ScrollView, StyleSheet, Image, TextInput, Picker } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader } from '../../BaseComponent';
import { color } from '../../../../styles/Color';
import ImagePath from '../../../../utils/ImagePaths';
import { DatePicker } from 'antd';
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';
import Style from '../../../../styles/Style';
const screenHeight = Dimensions.get("window").height;
let patientAppointment = null;
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
const navigationList = [
    { label: "Antenatal Chart", value: "antenatalChart" },
]
const scanObservation = {
    "date": "",
    "week": "",
    "month": "",
    "month_weeks": "",
    "scan_name": "",
    "scan_findings": "",
    "remarks": "",
    "comments": ""
}
const investigation = {
    "date": "",
    "week": "",
    "month": "",
    "month_weeks": "",
    "investigation_test": [],
    "remarks": "",
    "id": ""
}
const vaccination = {
    "date": "",
    "month": "",
    "week": "",
    "month_weeks": "",
    "vaccination_name": "",
    "remarks": ""
}

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,

            // Obstetric data
            PICME: "",
            para: "",
            gravida: "",
            liveBirth: "",
            aborption: "",
            lastMenstrualPeriod: converted_date,
            obstetricDataList: [],
            expectedDateOfDelivery: "",
            obstetricEditID: "",
            previousObstetricData: [],
            isObstetricDataNew: false,
            overAllRemark: "",
            selectedPreviousData: "",
            isPreviousData: false,
            prevSelectedID: "",
            selectedLastMenstrualPeriod: "",
            isObtetricData: false,

            // Obstetric History
            obstetricDate: converted_date,
            antenatalPeriod: "",
            modeOfDelivery: "",
            sex_wt: "",
            outcome: "",
            neonatalPeriod: "",
            birthWeight: "",
            obstetricList: [],
            obstetricID: "",
            isObstitricHistoryDelete: false,
            isObstetricHistory: false,

            // Physical examination
            physicalDate: converted_date,
            weight: "",
            height: "",
            bloodPressure: "",
            complaint: "",
            presentation: "",
            remarks: "",
            antenatal_id: "",
            physicalDataList: [],
            updatedLastMenstrualPeriod: "",
            physicalID: "",
            isPhysicalExamDel: false,
            isPhysicalExam: false,
            

            // Investigation Chart
            investigationDate: converted_date,
            heamoglobin: "",
            fastingOfSugar: "",
            postPrandial: "",
            glucose: "",
            uricAcid: "",
            thyroidStimulatingHormone: "",
            creatine: "",
            Bleed_cloting_time: "",
            serology: "",
            investigationChartList: [],
            remark: '',
            investigationNameList: [],
            selectedInvestigation: "",
            selectedInvestigationList: [],
            selectedInvestigationID: "",
            selectedMonthWeek: "",
            investigationEditID: "",
            invastigationDel: false,
            isInvestigationChart: false,
            // Scan Observation
            scanDate: converted_date,
            scanName: "",
            scanFinding: "",
            scanRemarks: "",
            scanObservationList: [],
            isScanObservationDelete: false,
            deleteComment: "",
            scanNameList: [],
            scanID: "",
            selectedScanMonth: "",
            scanEditID: "",
            isScanEdit: false,

            // vaccination
            vaccinationDate: converted_date,
            vaccinationName: "",
            vaccinationRemark: "",
            vaccinationList: [],
            selectedDateMonth: "",
            isVaccinationDelete: "",
            vaccinationID: "",
            isVaccination: false,
            // Common 
            selectedSubTitle: "Obstetric Data",
            commonRemarks: ""
        };
    }
    componentDidMount() {
      
        this.getObstetricData()
        this.getObstetricHistory()
        this.getInvestigationName()
        this.getScanList()
        var  expectedDateOfDelivery = moment(this.state.lastMenstrualPeriod).add(40, 'week').format("YYYY-MM-DD");
        this.setState({
            expectedDateOfDelivery: expectedDateOfDelivery
        })
    }
    getInvestigationName = () => {
        var service_url = Constants.INVESTIGATION_LIST + "?name=" + this.state.selectedInvestigation;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getInvestigationNameSuccess,
            this.getInvestigationNameFailure
        );
    }

    getInvestigationNameSuccess = response => {
        if (response && response.status === "success") {
            this.setState({
                investigationNameList: response.data
            })
        }

    }
    getInvestigationNameFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    getScanList = () => {
        var service_url = Constants.SCAN_OBSERVATIONS_LIST + "?name=" + this.state.scanName;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getScanListSuccess,
            this.getScanListFailure
        );
    }

    getScanListSuccess = response => {
        if (response && response.status === "success") {
            this.setState({
                scanNameList: response.data
            })
        }

    }
    getScanListFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    getPreviousObstrtricData = () => {
        var service_url = Constants.ANTENATAL_OBSTETRIC_DATA_POST + "?patient_id=" + this.state.patientAppointment.patient_id + "&antenatal_status=Completed";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPreviousObstrtricSuccess,
            this.getPreviousObstrtricFailure
        );
    }

    getPreviousObstrtricSuccess = response => {
        if (response && response.status === "success") {
            var data = response.data
            this.setState({
                previousObstetricData: response.data
            }, () => {
                for (let i = 0; i < data.length; i++) {
                  if( this.state.prevSelectedID == data[i].antenatal_obstetric.id) { 
                    this.setState({
                        antenatal_id: data[i].antenatal_obstetric.id,
                        updatedLastMenstrualPeriod: data[i].antenatal_obstetric.last_menstrual_period
                    }, () => {
                        this.getObstetricHistory()
                        this.getPhysicalExamination()
                        this.getInvestigationChart()
                        this.getScanObservationList()
                        this.gatVaccinationList()
                        this.props.getAnteneatalID(this.state.antenatal_id)
                    })
                }
               }
                })
            }

    }
    getPreviousObstrtricFailure = () => {
        this.props.showResposeValue("error", error.message)
    }
    getObstetricData = () => {
        var service_url = Constants.ANTENATAL_OBSTETRIC_DATA_POST + "?patient_id=" + this.state.patientAppointment.patient_id + "&antenatal_status=Assement";

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getObstetricDataSuccess,
            this.getObstetricDataFailure
        );
    }

    getObstetricDataSuccess = response => {
        if (response && response.status === "success") {
            var data = response.data
            this.setState({
                obstetricDataList: response.data
            }, () => {
                var { obstetricDataList, scanObservationList, vaccinationList, investigationChartList } = this.state

                for (let i = 0; i < obstetricDataList.length; i++) {
                    var last_menstrual_period = obstetricDataList[i].antenatal_obstetric.last_menstrual_period
                    this.setState({
                        selectedLastMenstrualPeriod: last_menstrual_period
                    }, () => {
                       if(this.state.vaccinationDate == converted_date) {
                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                        var dateSecond = new Date(this.state.vaccinationDate);
                        // time difference
                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                        // days difference
                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                        var weeks = Math.round(diffDays / 7)
                        var month = Math.round(weeks / 4)
                        var selectedMonthWeek = weeks + "Week" + " / "+ month + " Month"
                        this.setState({
                            selectedDateMonth: selectedMonthWeek                                      

                        })
                       }
                       if(this.state.investigationDate == converted_date) {
                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                        var dateSecond = new Date(this.state.investigationDate);
                        // time difference
                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                        // days difference
                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                        var weeks = Math.round(diffDays / 7)
                        var month = Math.round(weeks / 4)
                        var selectedMonthWeek = weeks + "Week" + " / "+ month + " Month"
                        this.setState({
                            selectedMonthWeek: selectedMonthWeek                                      

                        })
                       }
                       if(this.state.scanDate == converted_date) {
                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                        var dateSecond = new Date(this.state.scanDate);
                        // time difference
                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                        // days difference
                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                        var weeks = Math.round(diffDays / 7)
                        var month = Math.round(weeks / 4)
                        var selectedMonthWeek = weeks + "Week" + " / "+ month + " Month"
                        this.setState({
                            selectedScanMonth: selectedMonthWeek                                      

                        })
                       }
                      
                    })
                }

                if (last_menstrual_period && this.state.scanObservationList && this.state.scanObservationList.length == 0 && !this.state.isPreviousData) {
        
                    for (let i = scanObservationList.length; i < 5; i++) {
                        scanObservationList.push(JSON.parse(JSON.stringify(scanObservation)))
                    }
                    var sixWeek = moment(last_menstrual_period).add(6, 'week').format("YYYY-MM-DD");
                    scanObservationList[0].date_of_visit = sixWeek
                    scanObservationList[0].week = 6 
                    scanObservationList[0].month = Math.round((scanObservationList[0].week) / 4) 
                    scanObservationList[0].month_weeks =  scanObservationList[0].week  + " Weeks / " + scanObservationList[0].month + "Months"
                    scanObservationList[0].scan_name = "Ultra sound scan / Early Pregnancy scan"
                    var towelWeek = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                    scanObservationList[1].date_of_visit = towelWeek
                    scanObservationList[1].week = 12
                    scanObservationList[1].month = Math.round((scanObservationList[1].week) / 4) 
                    scanObservationList[1].month_weeks =  scanObservationList[1].week  + " Weeks / " + scanObservationList[1].month + "Months"
                    scanObservationList[1].scan_name = "NT Scan"
                    var sixtenWeek = moment(last_menstrual_period).add(20, 'week').format("YYYY-MM-DD");
                    scanObservationList[2].date_of_visit = sixtenWeek
                    scanObservationList[2].week = 20
                    scanObservationList[2].month = Math.round((scanObservationList[2].week) / 4) 
                    scanObservationList[2].month_weeks =  scanObservationList[2].week  + " Weeks / " + scanObservationList[2].month + "Months"
                    scanObservationList[2].scan_name = "Anomaly scan"
                    var twentyWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                    scanObservationList[3].date_of_visit = twentyWeek
                    scanObservationList[3].week = 28
                    scanObservationList[3].month = Math.round((scanObservationList[3].week) / 4)
                    scanObservationList[3].month_weeks =  scanObservationList[3].week  + " Weeks / " + scanObservationList[3].month + "Months"
                    scanObservationList[3].scan_name = "Growth scan"
                    var twentyEightWeek = moment(last_menstrual_period).add(36, 'week').format("YYYY-MM-DD");
                    scanObservationList[4].date_of_visit = twentyEightWeek
                    scanObservationList[4].week = 36
                    scanObservationList[4].month = Math.round((scanObservationList[4].week) / 4)
                    scanObservationList[4].month_weeks =  scanObservationList[4].week  + " Weeks / " + scanObservationList[4].month + "Months"
                    scanObservationList[4].scan_name = "Growth scan"
                    // var theetyTwoWeek = moment(this.state.lastMenstrualPeriod).add(32, 'week').format("YYYY-MM-DD");
                    // scanObservationList[5].date = theetyTwoWeek
                    // scanObservationList[5].week = 32
                    // var theetyFourWeek = moment(this.state.lastMenstrualPeriod).add(34, 'week').format("YYYY-MM-DD");
                    // scanObservationList[6].date = theetyFourWeek
                    // scanObservationList[6].week = 34
                    // var theertySixWeek = moment(this.state.lastMenstrualPeriod).add(36, 'week').format("YYYY-MM-DD");
                    // scanObservationList[7].date = theertySixWeek
                    // scanObservationList[7].week = 36
                   } 
                   if(last_menstrual_period && this.state.investigationChartList && this.state.investigationChartList.length == 0 && !this.state.isPreviousData ) { 
                    for (let i = investigationChartList.length; i < 5; i++) {
                        investigationChartList.push(JSON.parse(JSON.stringify(investigation)))
                    }
                    let sixWeeks = moment(last_menstrual_period).add(6, 'week').format("YYYY-MM-DD");
                    investigationChartList[0].date_of_visit = sixWeeks
                    investigationChartList[0].week = 6,
                    investigationChartList[0].month = Math.round((investigationChartList[0].week) / 4) 
                    investigationChartList[0].month_weeks =  investigationChartList[0].week  + " Weeks / " + investigationChartList[0].month + " Months"
                    investigationChartList[0].investigation_test.push({ "name": "Urine analysis", "value": "" }, { "name": "TSH", "value": "" }, { "name": "RBS", "value": "" }, { "name": "HB", "value": "" })
            
                    let twealthWeeks = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                    investigationChartList[1].date_of_visit = twealthWeeks
                    investigationChartList[1].week = 12
                    investigationChartList[1].month = Math.round((investigationChartList[1].week) / 4)  
                    investigationChartList[1].month_weeks =  investigationChartList[1].week  + " Weeks / " + investigationChartList[1].month + " Months"
                    investigationChartList[1].investigation_test.push({ "name": "Down Syndrome screening", "value": "" }, { "name": "Double marker", "value": "" })
        
                    let twentyWeeks = moment(last_menstrual_period).add(20, 'week').format("YYYY-MM-DD");
                    investigationChartList[2].date_of_visit = twentyWeeks
                    investigationChartList[2].week = 20
                    investigationChartList[2].month = Math.round((investigationChartList[2].week) / 4)
                    investigationChartList[2].month_weeks =  investigationChartList[2].week  + " Weeks / " + investigationChartList[2].month + " Months"
                    investigationChartList[2].investigation_test.push({ "name": "Urine analysis", "value": "" }, { "name": "HB", "value": "" }, { "name": "Blood sugar", "value": "" })
        
                    let twentyEightWeeks = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                    investigationChartList[3].date_of_visit = twentyEightWeeks
                    investigationChartList[3].week = 28
                    investigationChartList[3].month = Math.round((investigationChartList[3].week) / 4) 
                    investigationChartList[3].month_weeks =  investigationChartList[3].week  + " Weeks / " + investigationChartList[3].month + " Months"
                    investigationChartList[3].investigation_test.push({ "name": "GTT", "value": "" }, { "name": "Thyroid", "value": "" }, { "name": "HB", "value": "" })
        
                    var theetySixs= moment(last_menstrual_period).add(36, 'week').format("YYYY-MM-DD");
                    investigationChartList[4].date_of_visit = theetySixs
                    investigationChartList[4].week = 36
                    investigationChartList[4].month = Math.round((investigationChartList[4].week) / 4)  
                    investigationChartList[4].month_weeks =  investigationChartList[4].week  + " Weeks / " + investigationChartList[4].month + " Months"
                    investigationChartList[4].investigation_test.push({ "name": "HB", "value": "" }, { "name": "Urine analysis", "value": "" }, { "name": "Blood sugar", "value": "" })

                    }
                    if(last_menstrual_period && this.state.vaccinationList && this.state.vaccinationList.length == 0 && !this.state.isPreviousData ) { 
                    for (let i = vaccinationList.length; i < 3; i++) {
                        vaccinationList.push(JSON.parse(JSON.stringify(vaccination)))
                    }
                    var twealthWeek = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                    vaccinationList[0].date_of_visit = twealthWeek
                    vaccinationList[0].week = 12
                    vaccinationList[0].month = Math.round((vaccinationList[0].week) / 4)
                    vaccinationList[0].month_weeks =  vaccinationList[0].week  + " Weeks / " + vaccinationList[0].month + " Months"
                    vaccinationList[0].vaccination_name = "TT Injection"
        
                    var twentyEightWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                    vaccinationList[1].date_of_visit = twentyEightWeek
                    vaccinationList[1].week = 28
                    vaccinationList[1].month = Math.round((vaccinationList[1].week) / 4)
                    vaccinationList[1].month_weeks =  vaccinationList[1].week  + " Weeks / " + vaccinationList[1].month + " Months"
                    vaccinationList[1].vaccination_name = "TDap"

                    var twentyNineWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                    vaccinationList[2].date_of_visit = twentyNineWeek
                    vaccinationList[2].week = 28
                    vaccinationList[2].month = Math.round((vaccinationList[2].week) / 4)
                    vaccinationList[2].month_weeks =  vaccinationList[2].week  + " Weeks / " + vaccinationList[2].month + " Months"
                    vaccinationList[2].vaccination_name = "Covid"
                   }
        
                    this.setState({
                        scanObservationList,
                        vaccinationList,
                        investigationChartList,
                    })
                for (let i = 0; i < data.length; i++) {
                    this.setState({
                        antenatal_id: data[i].antenatal_obstetric.id,
                        updatedLastMenstrualPeriod: data[i].antenatal_obstetric.last_menstrual_period
                    }, () => {
                      if( this.state.antenatal_id != "" ) {   
                        this.getObstetricHistory()
                        this.getPhysicalExamination()
                        this.getInvestigationChart()
                        this.getScanObservationList()
                        this.gatVaccinationList()
                        this.props.getAnteneatalID(this.state.antenatal_id)
                      }
                    })
                }
            })
        }

    }
    getObstetricDataFailure = () => {
        this.props.showResposeValue("error", error.message)
    }

    changeTabSection(data) {
        this.props.changeScreen(data, {}, "", {}, "", "")
    }
    obstetricDataClear = () => {

        this.setState({
            PICME: "",
            para: "",
            gravida: "",
            liveBirth: "",
            aborption: "",
            lastMenstrualPeriod: converted_date,
        }, () => {
            var  expectedDateOfDelivery = moment(this.state.lastMenstrualPeriod).add(40, 'week').format("YYYY-MM-DD");
            this.setState({
                expectedDateOfDelivery: expectedDateOfDelivery
            })
        })
    }

    obstetricDataSave = () => {
        var { patientAppointment, obstetricEditID } = this.state
        var service_url = ""
        var last_menstrual_period = moment(this.state.lastMenstrualPeriod).format("YYYY-MM-DD")
        var expectedDateOfDelivery =  moment(this.state.expectedDateOfDelivery).format("YYYY-MM-DD")
        var data = {
            "patient_id": patientAppointment.patient_id,
            "appointment_id": patientAppointment.appointment_id,
            "last_menstrual_period": last_menstrual_period,
            "expected_date_of_delivery": expectedDateOfDelivery,
            "gravida": this.state.gravida,
            "para": this.state.para,
            "live_births": this.state.liveBirth,
            "abortions": this.state.aborption,
            "picme": this.state.PICME
        }
        if (this.state.lastMenstrualPeriod != "" && this.state.expectedDateOfDelivery != "" && this.state.gravida != "" && this.state.para != "" && this.state.liveBirth != "" && this.state.liveBirth != "") {
            service_url = Constants.ANTENATAL_OBSTETRIC_DATA_POST

            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.obstetricDataSaveSuccess,
                this.obstetricDataSaveFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }

    obstetricDataSaveSuccess = (response) => {
        if (response && response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.getObstetricData()
            this.obstetricDataClear()
        } else {
            this.props.showResposeValue("error", response.message)

        }
    }
    obstetricDataSaveFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    obstetricDataUpdate = () => {
        var { patientAppointment, obstetricEditID } = this.state
        var service_url = ""
        if (obstetricEditID != "") {

            var data = {
                "patient_id": patientAppointment.patient_id,
                "appointment_id": patientAppointment.appointment_id,
                "last_menstrual_period": this.state.lastMenstrualPeriod,
                "expected_date_of_delivery": this.state.expectedDateOfDelivery,
                "gravida": this.state.gravida,
                "para": this.state.para,
                "live_births": this.state.liveBirth,
                "abortions": this.state.aborption,
                "picme": this.state.PICME
            }
            data["id"] = this.state.obstetricEditID
            if (this.state.lastMenstrualPeriod != "" && this.state.expectedDateOfDelivery != "" && this.state.gravida != "" && this.state.para != "" && this.state.liveBirth != "" && this.state.liveBirth != "" && this.state.PICME != "") {
                service_url = Constants.ANTENATAL_OBSTETRIC_DATA_POST
                OpthamologyService.getInstance().documentUploadPutAPI(
                    service_url,
                    data,
                    this.obstetricDataUpdateSuccess,
                    this.obstetricDataUpdateFailure
                );
            } else {
                this.props.showResposeValue("error", "Please enter all the details to proceed")
            }
        }
    }
    obstetricDataUpdateSuccess = (response) => {
        if (response.data && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.getObstetricData()
            this.obstetricDataClear()
            this.setState({
                obstetricEditID: "",
                isObtetricData: false
                
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    obstetricDataUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }

    obstetricHistorySave = () => {
        var service_url = ""
        var obstetricDate = moment(this.state.obstetricDate).format("YYYY-MM-DD")
        var data = {
            "antenatal_id": this.state.antenatal_id,
            "date_of_visit": obstetricDate,
            "antenatal_period": this.state.antenatalPeriod,
            "mode_of_delivery": this.state.modeOfDelivery,
            "gender": this.state.sex_wt,
            "outcome": this.state.outcome,
            "baby_weight": this.state.birthWeight,
            "neonatal_period": this.state.neonatalPeriod
        }
        if (this.state.obstetricDate != "" && this.state.antenatalPeriod != "" && this.state.modeOfDelivery != "" && this.state.sex_wt != "" && this.state.outcome != "" && this.state.neonatalPeriod != "" && this.state.birthWeight != "") {
            service_url = Constants.ANTENATAL_OBSTETRIC_HISTORY_POST
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.obstetricHistorySaveSuccess,
                this.obstetricHistorySaveFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    obstetricHistorySaveSuccess = (response) => {
        if (response && response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.obstetricClear()
            this.getObstetricData()
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    obstetricHistorySaveFailure = () => {
        this.props.showResposeValue("error", error.message)

    }
    obstetricClear = () => {
        this.setState({
            obstetricDate: converted_date,
            antenatalPeriod: "",
            modeOfDelivery: "",
            sex_wt: "",
            outcome: "",
            neonatalPeriod: "",
            birthWeight: "",
            obstetricID: "",
        })
    }
    obstetricUpdate = () => {
        var service_url = ""
        var data = {
            "antenatal_id": this.state.antenatal_id,
            "date_of_visit": this.state.obstetricDate,
            "antenatal_period": this.state.antenatalPeriod,
            "mode_of_delivery": this.state.modeOfDelivery,
            "gender": this.state.sex_wt,
            "outcome": this.state.outcome,
            "baby_weight": this.state.birthWeight,
            "neonatal_period": this.state.neonatalPeriod,
            "id": this.state.obstetricID
        }
        if (this.state.obstetricDate != "" && this.state.antenatalPeriod != "" && this.state.modeOfDelivery != "" && this.state.sex_wt != "" && this.state.outcome != "" && this.state.neonatalPeriod != "" && this.state.birthWeight != "" && this.state.obstetricID != "") {
            service_url = Constants.ANTENATAL_OBSTETRIC_HISTORY_POST
            OpthamologyService.getInstance().documentUploadPutAPI(
                service_url,
                data,
                this.obstetricUpdateSuccess,
                this.obstetricUpdateFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    obstetricUpdateSuccess = (response) => {
        if (response.data && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.getObstetricHistory()
            this.obstetricClear()
            this.setState({
                obstetricID: "",
                isObstetricHistory: false
            })
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    obstetricUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    getObstetricHistory = () => {
        var service_url = Constants.ANTENATAL_OBSTETRIC_HISTORY_POST + "?antenatal_id=" + this.state.antenatal_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getObstetricHistorySuccess,
            this.getObstetricHistoryFailure
        );
    }

    getObstetricHistorySuccess = response => {
        if (response && response.status === "success") {
            this.setState({
                obstetricList: response.data
            })
        }

    }
    getObstetricHistoryFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    deleteObstitricHistoryData = () => {
        var service_url = Constants.ANTENATAL_OBSTETRIC_HISTORY_POST + "?id=" + this.state.obstetricID;

        OpthamologyService.getInstance().deleteComplaints(
            service_url,
            {},
            this,
            this.deleteObstitricHistorySuccess,
            this.deleteObstitricHistoryFailure
        );
    }
    deleteObstitricHistorySuccess = (response) => {
        if (response && response.status === "success") {
            this.getObstetricHistory()
            this.setState({
                obstetricID: "",
                isObstitricHistoryDelete: false
            })
            this.props.showResposeValue("success", response.data.message)
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    deleteObstitricHistoryFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    physicalEaminationDelete = () => {
        var service_url = Constants.ANTENATAL_PHYSICAL_EXAMINATION + "?id=" + this.state.physicalID;

        OpthamologyService.getInstance().deleteComplaints(
            service_url,
            {},
            this,
            this.physicalEaminationDeleteSuccess,
            this.physicalEaminationDeleteFailure
        );
    }
    physicalEaminationDeleteSuccess = (response) => {
        if (response && response.status === "success") {
            this.getPhysicalExamination()
            this.setState({
                physicalID: "",
                isPhysicalExamDel: false
            })
            this.props.showResposeValue("success", response.data.message)
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    physicalEaminationDeleteFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    clearPhysicalData = () => {
        this.setState({
            physicalDate: converted_date,
            weight: "",
            height: "",
            bloodPressure: "",
            complaint: "",
            presentation: "",
            remarks: "",
        })
    }
    physicalExaminationSave = () => {
        var service_url = ""

        var dateFirst = new Date(this.state.updatedLastMenstrualPeriod);
        var dateSecond = new Date(this.state.physicalDate);
        // time difference
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        var weeks = Math.round(diffDays / 7)
        var physicalDate =  moment(this.state.physicalDate).format("YYYY-MM-DD")
        var data = {
            "antenatal_id": this.state.antenatal_id,
            "date_of_visit": physicalDate,
            "weeks": weeks ? weeks : 0,
            "weight": this.state.weight,
            "blood_pressure": this.state.bloodPressure,
            "height_of_uterus": this.state.height,
            "presentation": this.state.presentation,
            "remarks": this.state.remarks,
            "complaints": this.state.complaint
        }
        if (physicalDate != "" && this.state.weight != "" && this.state.bloodPressure != "" && this.state.height != "" && this.state.presentation != "" && this.state.remarks != "" && this.state.complaint != "") {
            service_url = Constants.ANTENATAL_PHYSICAL_EXAMINATION
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.physicalExaminationSaveSuccess,
                this.physicalExaminationSaveFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    physicalExaminationSaveSuccess = (response) => {
        if (response && response.status === "success") {
            this.getPhysicalExamination()
            this.clearPhysicalData()
            this.props.showResposeValue("success", response.data.message)
        } else {
            this.props.showResposeValue("error", response.message)
        }
    }
    physicalExaminationSaveFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    physicalExaminationUpdate = () => {
        var dateFirst = new Date(this.state.updatedLastMenstrualPeriod);
        var dateSecond = new Date(this.state.physicalDate);
        // time difference
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        var weeks = diffDays / 7

        var data = {
            "antenatal_id": this.state.antenatal_id,
            "date_of_visit": this.state.physicalDate,
            "weeks": Math.round(weeks),
            "weight": this.state.weight,
            "blood_pressure": this.state.bloodPressure,
            "height_of_uterus": this.state.height,
            "presentation": this.state.presentation,
            "remarks": this.state.remarks,
            "complaints": this.state.complaint,
            "id": this.state.physicalID
        }
        if (this.state.physicalDate != "" && weeks != "" && this.state.weight != "" && this.state.bloodPressure != "" && this.state.height != "" && this.state.presentation != "" && this.state.remarks != "" && this.state.complaint != "") {
            var service_url = Constants.ANTENATAL_PHYSICAL_EXAMINATION
            OpthamologyService.getInstance().documentUploadPutAPI(
                service_url,
                data,
                this.physicalExamUpdateSuccess,
                this.physicalExamUpdateFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    physicalExamUpdateSuccess = (response) => {
        if (response && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.getPhysicalExamination()
            this.clearPhysicalData()
            this.setState({
                isPhysicalExam: false
            })
        } else {
            this.props.showResposeValue("error", response.data.message)

        }
    }
    physicalExamUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.data.message)
    }
    getPhysicalExamination = () => {
        var service_url = Constants.ANTENATAL_PHYSICAL_EXAMINATION + "?antenatal_id=" + this.state.antenatal_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPhysicalExaminationSuccess,
            this.getPhysicalExaminationFailure
        );
    }

    getPhysicalExaminationSuccess = response => {
        if (response && response.status === "success") {
            this.setState({
                physicalDataList: response.data
            })
        }

    }
    getPhysicalExaminationFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    investigationClear = () => {
        this.setState({
            investigationDate: converted_date,
            selectedMonthWeek: "",
            remark: "",
            selectedInvestigationList: [],
            investigationEditID: "",
            selectedInvestigation: ""
        })
    }
    investigationUpdate = () => {
        var { selectedInvestigationList } = this.state
        if (this.state.investigationDate != "" && this.state.remark != "", this.state.selectedMonthWeek != "" && selectedInvestigationList && selectedInvestigationList.length > 0 ) {
            var service_url = Constants.INVESTIGATION_CHART_POST
            var data = {
                "id": this.state.investigationEditID,   
                "date_of_visit": this.state.investigationDate,
                "month_weeks": this.state.selectedMonthWeek,
                "remarks": this.state.remark,
                "investigation_test": this.state.selectedInvestigationList,
         }
        
        OpthamologyService.getInstance().documentUploadPutAPI(
            service_url,
            data,
            this.investigationUpdateSuccess,
            this.investigationUpdateFailure
        );
        }  else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    investigationUpdateSuccess = (response) => {
        if(response && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.investigationClear()
            this.getInvestigationChart()
            this.setState({
                isInvestigationChart: false
            })
        }
    }
    investigationUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    investigationChartPost = () => {
        var { selectedInvestigationList } = this.state
        var investigationDate =  moment(this.state.investigationDate).format("YYYY-MM-DD")
         var data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit":investigationDate,
                "month_weeks": this.state.selectedMonthWeek,
                "remarks": this.state.remark,
                "investigation_test": this.state.selectedInvestigationList,  
        }
        if (this.state.investigationDate != "" && this.state.remark != "" && selectedInvestigationList && selectedInvestigationList.length > 0 ) {
            var service_url = Constants.INVESTIGATION_CHART_POST
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.investigationChartPostSuccess,
                this.investigationChartPostFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    investigationChartPostSuccess = (response) => {
       if(response && response.status === "success") {
        this.props.showResposeValue("success", response.message)
        this.investigationClear()
        this.getInvestigationChart()
       } else {
        this.props.showResposeValue("error", response.message)
       }
    }
    investigationChartPostFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    getInvestigationChart = () => {
       
        var service_url = Constants.INVESTIGATION_CHART_POST + "?antenatal_id=" + this.state.antenatal_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getInvestigationChartSuccess,
            this.getInvestigationChartFailure
        );
    }

    getInvestigationChartSuccess = response => {
        if (response && response.status === "success") {
            var data = []
            data = response.data
            var { investigationChartList } = this.state 
            for( let i = 0; i < data.length; i++ ) {
              for(  let j = 0; j < investigationChartList.length; j++){
                if(data[i].date_of_visit === investigationChartList[j].date_of_visit){
                    investigationChartList[j].investigation_test = data[i].investigation_test
                    investigationChartList[j].remarks = data[i].remarks
                    investigationChartList[j].id = data[i].id
                    this.setState({
                        investigationChartList
                    })
                } else {
                    var investigationChartLists = investigationChartList.push({"date_of_visit":data[i].date_of_visit,"remarks":data[i].remarks, "id":data[i].id, "month_weeks":data[i].month_weeks, "investigation_test":data[i].investigation_test})
                    this.setState({
                        investigationChartList : investigationChartLists
                    }, () => {
                        var investigationData = [...new Map(investigationChartList.map((m) =>  
                             [m.date_of_visit, m] )).values()];
                             var original = investigationData
                             const Sorted = original.sort((a, b) => {
                                 return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                             })
                           var value = this.state
                           value["investigationChartList"] = Sorted
                           this.setState({
                             value
                           })
                    })
                    break;
                }
              } 
                 
                }
        }

    }
    getInvestigationChartFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    deleteInvestigationData = () => {
        var service_url = Constants.INVESTIGATION_CHART_POST + "?id=" + this.state.investigationEditID;
        OpthamologyService.getInstance().deleteComplaints(
            service_url,
            {},
            this,
            this.deleteInvestigationDataSuccess,
            this.deleteInvestigationDataFailure
        );
    }
    deleteInvestigationDataSuccess = (response) => {
        if (response && response.status === "success") {
            this.getInvestigationChart()
            this.props.showResposeValue("success", response.message)
            this.setState({
                investigationEditID: "",
                invastigationDel: false,
                selectedSubTitle: "",
                investigationChartList: []
            }, () => {
                this.getObstetricData()
                this.getObstetricHistory()
                this.getInvestigationName()
                this.getScanList()
            })
        }
    }
    deleteInvestigationDataFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    sacnObservationClear = () => {
        this.setState({
            scanDate: converted_date,
            scanName: "",
            scanFinding: "",
            scanRemarks: ""
        })
    }
    scanObservationPost = () => {
        if (this.state.scanDate != "" && this.state.remark != "", this.state.scanName != "" && this.state.scanFinding != "" && this.state.scanRemarks != "" ) {
            var service_url = Constants.SCAN_OBSERVATIONS_POST
            var scanDate = moment(this.state.scanDate).format("YYYY-MM-DD")
            var data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": scanDate,
                "month_weeks": this.state.selectedScanMonth,
                "remarks": this.state.scanRemarks,
                "scan_name": this.state.scanName,
                "scan_findings": this.state.scanFinding,
                "scan_id": this.state.scanID
            }
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.scanObservationPostSuccess,
                this.scanObservationPostFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    scanObservationPostSuccess = (response) => {
        if(response && response.status === "success") {
         this.props.showResposeValue("success", response.message)
         this.sacnObservationClear()
         this.getScanObservationList()
        } else {
            this.props.showResposeValue("error", response.message)
        }
     }
     scanObservationPostFailure = (error) => {
         this.props.showResposeValue("error", error.message)
     }
     removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }
     getScanObservationList = () => {
        var service_url = Constants.SCAN_OBSERVATIONS_POST + "?antenatal_id=" + this.state.antenatal_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getScanObservationListSuccess,
            this.getScanObservationListFailure
        );
     }
     getScanObservationListSuccess = (response) => {
        if(response && response.status === "success") {
            var { scanObservationList } = this.state
            var data = []
            data = response.data   

            for( let i = 0; i < data.length; i++ ) {
                for(  let j = 0; j < scanObservationList.length; j++){
                  if((data[i].date_of_visit === scanObservationList[j].date_of_visit)){
                    scanObservationList[j].remarks = data[i].remarks
                    scanObservationList[j].id = data[i].id
                    scanObservationList[j].scan_findings = data[i].scan_findings
                    scanObservationList[j].comments = data[i].comments
                    this.setState({
                        scanObservationList
                    })
                  } else {
                        var scanObservationLists = scanObservationList.push({"date_of_visit": data[i].date_of_visit,"scan_findings": data[i].scan_findings,"scan_name": data[i].scan_name, "id": data[i].id,"remarks": data[i].remarks,"month_weeks": data[i].month_weeks, "comments": data[i].comments})
                        this.setState({
                            scanObservationList : scanObservationLists
                        }, () => {
                            var scanData = [...new Map(scanObservationList.map((m) =>  
                                 [m.date_of_visit, m] )).values()];
                                 var original = scanData
                                     const Sorted = original.sort((a, b) => {
                                         return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                                     })
                                   var value = this.state
                                   value["scanObservationList"] = Sorted
                                   this.setState({
                                     value
                                   })
                            this.setState({
                                scanObservationList: Sorted
                            })
                           
                        })
                        break;
                    }
                }  
              }
        }   
     }
     getScanObservationListFailure = (error) => {
        this.props.showResposeValue("error", error.message)
     }
     scanObservationUpdate = () => {
        if (this.state.scanDate != "" && this.state.remark != "", this.state.scanName != "" && this.state.scanFinding != "" && this.state.scanRemarks != "" ) {
            var service_url = Constants.SCAN_OBSERVATIONS_POST
            var data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.scanDate,
                "month_weeks": this.state.selectedScanMonth,
                "remarks": this.state.scanRemarks,
                "scan_name": this.state.scanName,
                "scan_findings": this.state.scanFinding,
                "scan_id": this.state.scanID,
                "id": this.state.scanEditID
            }
        
        OpthamologyService.getInstance().documentUploadPutAPI(
            service_url,
            data,
            this.scanObservationUpdateSuccess,
            this.scanObservationUpdateFailure
        );
        }  else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
     }
     scanObservationUpdateSuccess = (response) => {
        if(response && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.sacnObservationClear()
            this.getScanObservationList()
            this.setState({
                scanEditID: "",
                selectedScanMonth: "",
                isScanEdit: false
                
            })
        } else {
            this.props.showResposeValue("error", response.data.message)
        }
     }
     scanObservationUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.data.message)
     }
     deleteScanObservation = (id) => {
        var service_url = Constants.SCAN_OBSERVATIONS_POST;
        var data = {
            "id": id,
            "comments": this.state.deleteComment
        }
        if( this.state.deleteComment != "") {
        OpthamologyService.getInstance().deleteComplaints(
            service_url,
            data,
            this,
            this.deleteScanObservationSuccess,
            this.deleteScanObservationFailure
        ); 
        } else {
            this.props.showResposeValue("error", "Please enter the delete comment")
        }
     }
     deleteScanObservationSuccess = (response) => {
        if (response && response.status === "success") {
            this.getScanObservationList()
            this.props.showResposeValue("success", response.message)
            this.setState({
                scanEditID: "",
                isScanObservationDelete: false,
                scanObservationList: []
            }, () => {
                this.getObstetricData()
                this.getObstetricHistory()
                this.getInvestigationName()
                this.getScanList()
            })
        }
    }
    deleteScanObservationFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    vaccinationClear = () => {
        this.setState({
            vaccinationDate: converted_date,
            selectedDateMonth: "",
            vaccinationRemark: "",
            vaccinationName: "",
            isVaccination: false
        })
    }
    gatVaccinationList = () => {
        var service_url = Constants.VACCINATION_POST + "?antenatal_id=" + this.state.antenatal_id;
        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.gatVaccinationListSuccess,
            this.gatVaccinationListFailure
        );
    }
    gatVaccinationListSuccess = (response) => {
        if(response && response.status === "success") {
            var { vaccinationList } = this.state
            var data = []
            data = response.data   
            for( let i = 0; i < data.length; i++ ) {
                for(  let j = 0; j < vaccinationList.length; j++){
                  if((data[i].date_of_visit === vaccinationList[j].date_of_visit)){
                    vaccinationList[j].id = data[i].id
                    vaccinationList[j].vaccination_name = data[i].vaccination_name
                    vaccinationList[j].remarks = data[i].remarks
                    this.setState({
                        vaccinationList
                    })
                  } else {
                    var vaccinationLists = vaccinationList.push({"date_of_visit": data[i].date_of_visit,"vaccination_name": data[i].vaccination_name,"remarks": data[i].remarks, "id": data[i].id, "month_weeks": data[i].month_weeks})
                    this.setState({
                        vaccinationList : vaccinationLists
                    }, () => {
                        var vaccineData = [...new Map(vaccinationList.map((m) =>  
                            [m.date_of_visit, m] )).values()];
                             var original = vaccineData
                            const Sorted = original.sort((a, b) => {
                                return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                            })
                          var value = this.state
                          value["vaccinationList"] = Sorted
                          this.setState({
                            value
                          })
                       
                    })
                    break;
                }
                }  
              }
        }   
     }
     gatVaccinationListFailure = (error) => {
        this.props.showResposeValue("error", error.message)
     }
    vaccinationPost = () => {
        if (this.state.vaccinationDate != "" && this.state.vaccinationRemark != "" && this.state.vaccinationName != "" ) {
            var service_url = Constants.VACCINATION_POST
            var vaccinationDate = moment(this.state.vaccinationDate).format("YYYY-MM-DD")
            var data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": vaccinationDate,
                "month_weeks": this.state.selectedDateMonth,
                "remarks": this.state.vaccinationRemark,
                "vaccination_name": this.state.vaccinationName
            }
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.vaccinationPostSuccess,
                this.vaccinationPostFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
    }
    vaccinationPostSuccess = (response) => {
        if(response && response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.vaccinationClear()
            this.gatVaccinationList()
           } else {
            this.props.showResposeValue("error", response.message)
           }
    }
    vaccinationPostFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    vaccinationUpdate = () => {
        if (this.state.vaccinationDate != "" && this.state.vaccinationRemark != "" && this.state.vaccinationName != "" ) {
            var service_url = Constants.VACCINATION_POST
            var data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.vaccinationDate,
                "month_weeks": this.state.selectedDateMonth,
                "remarks": this.state.vaccinationRemark,
                "vaccination_name": this.state.vaccinationName,
                "id": this.state.vaccinationID
            }
        OpthamologyService.getInstance().documentUploadPutAPI(
            service_url,
            data,
            this.vaccinationUpdateSuccess,
            this.vaccinationUpdateFailure
        );
        }  else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }
     }
     vaccinationUpdateSuccess = (response) => {
        if(response && response.data.status === "success") {
            this.props.showResposeValue("success", response.data.message)
            this.vaccinationClear()
            this.gatVaccinationList()
            this.setState({
                vaccinationID: "",
                selectedDateMonth: "", 
                isVaccination: false  
            })
        } else {
            this.props.showResposeValue("error", response.data.message)
        }
     }
     vaccinationUpdateFailure = (error) => {
        this.props.showResposeValue("error", error.data.message)
     }
     deleteVaccination = (id) => {
        var service_url = Constants.VACCINATION_POST+ "?id=" + id;
        OpthamologyService.getInstance().deleteComplaints(
            service_url,
            {},
            this,
            this.deleteVaccinationSuccess,
            this.deleteVaccinationFailure
        );
     }
     deleteVaccinationSuccess = (response) => {
        if (response && response.status === "success") {
            this.gatVaccinationList()
            this.props.showResposeValue("success", response.message)
            this.setState({
                vaccinationID: "",
                isVaccinationDelete: false,
                vaccinationList: []
            }, () => {
                this.getObstetricData()
                this.getObstetricHistory()
                this.getInvestigationName()
                this.getScanList()
            })
        }
    }
    deleteVaccinationFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    anatentalMainHeader = (label) => {
        return (
            <View>
                <TouchableOpacity
                    onPress={() => {
                        this.setState({
                            selectedSubTitle: label,
                            isObtetricData: false,
                            isObstetricHistory: false,
                            isInvestigationChart: false,
                            isScanEdit: false,
                            isVaccination: false,
                            obstetricEditID: "",
                            obstetricID: "",
                            physicalID: "",
                            investigationEditID: "",
                            scanID: "",
                            isPreviousData: false,
                            isObstetricDataNew: false
                        })
                        this.obstetricClear()
                        this.obstetricDataClear()
                        this.clearPhysicalData()
                        this.investigationClear()
                        this.sacnObservationClear()
                        this.vaccinationClear()
                    }}
                >
                    <Text style={{ fontSize: 16, fontWeight: "500", color: label === this.state.selectedSubTitle ? color.themeDark : color.black }}>{label}</Text>
                </TouchableOpacity>
            </View>
        )
    }
    renderPreviousObstetricHeader = () => {
        return (
            <View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={styles.AddButton} onPress={() => { 
                        this.getPreviousObstrtricData() 
                        this.setState({
                            isPreviousData: true,
                            selectedSubTitle: "Previous Antenental",
                            obstetricDataList: []
                        })

                        }}>
                        <Text style={styles.addText}> Previous</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw" }]} 
                        onPress={() => {
                        var  expectedDateOfDelivery = moment(this.state.lastMenstrualPeriod).add(40, 'week').format("YYYY-MM-DD");
                        this.setState({
                            selectedSubTitle: "Obstetric Data",
                            previousObstetricData: [],
                            selectedPreviousData: "",
                            obstetricDataList: []
                         }, () => {
                           if(!this.state.isPreviousData) {
                            this.setState({
                                isObstetricDataNew: true,
                                expectedDateOfDelivery: expectedDateOfDelivery,
                                selectedSubTitle: "Obstetric Data"
                            }, () => {
                                this.getObstetricData()
                            })
                           } 
                           
                        })
                        this.obstetricDataClear()
                     }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", padding: "0.3vw" }]}
                        onPress={() => {
                            var { obstetricDataList } = this.state
                            if (obstetricDataList && obstetricDataList.length > 0) {
                                for (let i = 0; i < obstetricDataList.length; i++) {
                                    this.setState({
                                        PICME: obstetricDataList[i].antenatal_obstetric.picme,
                                        para: obstetricDataList[i].antenatal_obstetric.para,
                                        gravida: obstetricDataList[i].antenatal_obstetric.gravida,
                                        liveBirth: obstetricDataList[i].antenatal_obstetric.live_births,
                                        aborption: obstetricDataList[i].antenatal_obstetric.abortions,
                                        lastMenstrualPeriod: obstetricDataList[i].antenatal_obstetric.last_menstrual_period,
                                        expectedDateOfDelivery: obstetricDataList[i].antenatal_obstetric.expected_date_of_delivery,
                                        obstetricEditID: obstetricDataList[i].antenatal_obstetric.id,
                                        isObtetricData: true,
                                        selectedSubTitle: "Obstetric Data"
                                    })
                                }
                            }
                        }}
                    >

                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Gravida"}
                        heading2={"Para"}
                        heading3={"Live Birth"}
                        heading4={"Abortion"}
                        heading5={"Last Menstrual Period"}
                        heading6={"Expected date of delivery"}
                        heading7={"PICME"}
                        noOfColumn={7}
                        columnSize={[0.15, 0.14, 0.12, 0.12, 0.2, 0.2, 0.07]}
                    />
                </View>
            </View>
        )
    }
    renderObstetricHeader = () => {
        return (
            <View>
                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", alignSelf: "flex-end" }]} onPress={() => {
                          this.setState({
                            selectedSubTitle: "Obstetric History"
                          })
                         this.obstetricClear()
                     }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Date"}
                        heading2={"Antenatal Period"}
                        heading3={"Mode of Delivery"}
                        heading4={"Sex/WT"}
                        heading5={"Outcome"}
                        heading6={"Neonatal Period"}
                        heading7={"Action"}
                        noOfColumn={7}
                        columnSize={[0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.1]}
                    />
                </View>
            </View>
        )
    }
    renderPhysicalHeader = () => {
        return (
            <View style={{ marginTop: "0.5vw" }}>
                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", alignSelf: "flex-end" }]} onPress={() => {
                         this.setState({
                            selectedSubTitle: "Physical Examination"
                          })
                          this.clearPhysicalData()
                     }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Date"}
                        heading2={"Week"}
                        heading3={"Weight"}
                        heading4={"Height"}
                        heading5={"Blood Pressure"}
                        heading6={"Complaints"}
                        heading7={"Presentation"}
                        heading8={"Remarks"}
                        heading9={"Action"}
                        noOfColumn={9}
                        columnSize={[0.1, 0.08, 0.08, 0.08, 0.15, 0.13, 0.13, 0.15, 0.1]}
                    />
                </View>
            </View>
        )
    }

    tabelHeader(title) {
        return (
            <View>
                <Text
                    style={{
                        fontSize: 12,
                        color: "black",
                        alignContent: "center", justifyContent: 'center',
                        alignItems: 'center', alignSelf: 'center',
                        textAlignVertical: 'center', textAlign: 'center'
                    }}>{title}</Text>
            </View>
        )
    }

    tabelData(title) {
        return (
            <View>
                <Text
                    numberOfLines={10}
                    style={{
                        fontSize: 13,
                        color: "#000",
                        alignContent: "center", justifyContent: 'center',
                        alignItems: 'center', alignSelf: 'center',
                        textAlignVertical: 'center', textAlign: 'center'
                    }}>{title}</Text>
            </View>
        )
    }
    investigationChartHeader() {
        return (
            <View>
                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", alignSelf: "flex-end" }]} onPress={() => { 
                        this.setState({
                            selectedSubTitle: "Investigation Chart"
                        })
                        this.investigationClear()
                    }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Month / Week"}
                        heading2={"Investigation Name"}
                        heading3={"Value"}
                        heading4={"Remark"}
                        heading5={"Action"}
                        noOfColumn={5}
                        columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}
                    />
                </View>
            </View>
        )
    }
    renderScanObservationHeader = () => {
        return (
            <View>
                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", alignSelf: "flex-end" }]} onPress={() => { 
                        this.setState({
                            selectedSubTitle: "Scan Observation"
                          })
                          this.sacnObservationClear()
                    }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Month / Week"}
                        heading2={"Scan Name"}
                        heading3={"Scan Findings"}
                        heading4={"Remark"}
                        heading5={"Action"}
                        noOfColumn={5}
                        columnSize={[0.22, 0.23, 0.2, 0.2, 0.15]}
                    />
                </View>
            </View>
        )
    }
    renderVaccinationHeader = () => {
        return (
            <View>
                <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw", alignSelf: "flex-end" }]} onPress={() => {
                        this.setState({
                            selectedSubTitle: "Vaccination"
                        })
                        this.vaccinationClear()
                     }}>
                        <Text style={styles.addText}> New</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginTop: "0.5vw" }}>
                    <CommonHistorySectionHeader
                        heading1={"Month / Week"}
                        heading2={"Vaccination Name"}
                        heading3={"Remark"}
                        heading4={"Action"}
                        noOfColumn={4}
                        columnSize={[0.25, 0.25, 0.25, 0.25]}
                    />
                </View>
            </View>
        )
    }
    previousObstetricTable = () => {
        return (
            <View style={{ zIndex: -2 }}>
                {this.state.obstetricDataList && this.state.obstetricDataList.length > 0 ?
                    this.state.obstetricDataList && this.state.obstetricDataList.map((item, index) => {
                        var last_menstrual_period = moment(item.antenatal_obstetric.last_menstrual_period).format("DD-MM-YYYY")
                        var expected_date_of_delivery = moment(item.antenatal_obstetric.expected_date_of_delivery).format("DD-MM-YYYY")

                        return (
                            <View style={[styles.dataContainer, { paddingHorizontal: '1.5vw' }]} >
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.antenatal_obstetric.gravida}</Text></View>
                                <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.antenatal_obstetric.para}</Text></View>
                                <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.antenatal_obstetric.live_births}</Text></View>
                                <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.antenatal_obstetric.abortions}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{last_menstrual_period}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{expected_date_of_delivery}</Text></View>
                                <View style={{ flex: 0.07 }}><Text style={styles.TableDataText}>{item.antenatal_obstetric.picme}</Text></View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    ObstetricTable = () => {
        return (
            <View style={{ zIndex: -2 }}>
                {this.state.obstetricList && this.state.obstetricList.length > 0 ?
                    this.state.obstetricList && this.state.obstetricList.map((item, index) => {
                        var date = moment(item.date_of_visit).format("DD-MM-YYYY")
                        return (
                            <View style={[styles.dataContainer, { paddingHorizontal: '1.5vw' }]} >
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{date}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.antenatal_period + " (Days)"}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.mode_of_delivery}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={[styles.TableDataText, {fontSize: "1vw"}]}>{(item.gender + " / " + item.baby_weight + " (kg)")}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.outcome}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.neonatal_period + " (Days)"}</Text></View>
                                <View style={{ flex: 0.1, flexDirection: "row" }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                // antenatal_id: item.antenatal_id,
                                                obstetricDate: item.date_of_visit,
                                                antenatalPeriod: item.antenatal_period,
                                                modeOfDelivery: item.mode_of_delivery,
                                                sex_wt: item.gender,
                                                outcome: item.outcome,
                                                birthWeight: item.baby_weight,
                                                neonatalPeriod: item.neonatal_period,
                                                obstetricID: item.id,
                                                selectedSubTitle: "Obstetric History",
                                                isObstetricHistory: true
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                                    </TouchableOpacity>
                                    { item && item.id ?  
                                    <TouchableOpacity style={{ marginLeft: "0.5vw", width: "fit-content" }}
                                        onPress={() => {
                                            this.setState({
                                                isObstitricHistoryDelete: true,
                                                obstetricID: item.id,
                                                selectedSubTitle: "Obstetric History"
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Delete} style={{ height: 25, width: 30 }} resizeMode="contain" />
                                    </TouchableOpacity> : null }
                                </View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    vaccinationTable = () => {
        return (
            <View style={{ zIndex: -2 }}>
                {this.state.vaccinationList && this.state.vaccinationList.length > 0 ?
                    this.state.vaccinationList && this.state.vaccinationList.map((item, index) => {
                        return (
                            <View style={styles.dataContainer} >
                                <View style={{ flex: 0.25, marginHorizontal: 20, flexDirection: "row" }}>
                                    <Text style={styles.TableDataText}>{item.month_weeks}</Text>
                                </View>
                                <View style={{ flex: 0.25 }}><Text style={styles.TableDataText}>{item.vaccination_name}</Text></View>
                                <View style={{ flex: 0.25 }}><Text style={styles.TableDataText}>{item.remarks ? item.remarks : "-"}</Text></View>
                                <View style={{ flex: 0.25, flexDirection: "row" }}>
                                    <TouchableOpacity
                                        // disabled = {index == 0 ? false : (this.state.vaccinationList[index-1].id != "" && this.state.vaccinationList[index-1].id != undefined ? false : true)}
                                        onPress={() => {
                                            this.setState({
                                                vaccinationDate: moment(item.date_of_visit).format("YYYY-MM-DD"),
                                                vaccinationName: item.vaccination_name,
                                                vaccinationRemark: item.remarks,
                                                selectedDateMonth: item.month_weeks,
                                                selectedSubTitle: "Vaccination",
                                                vaccinationID: item.id,
                                                isVaccination: true
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                                    </TouchableOpacity>
                                    { item && item.id ?  
                                    <TouchableOpacity style={{ marginLeft: "0.5vw", width: "fit-content" }}
                                        onPress={() => {
                                            this.setState({
                                                isVaccinationDelete: true,
                                                vaccinationID: item.id,
                                                selectedSubTitle: "Vaccination"
                                            })
                                           
                                        }}
                                    >
                                        <Image source={ImagePath.Delete} style={{ height: 25, width: 30 }} resizeMode="contain" />
                                    </TouchableOpacity> : null }
                                </View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    physicalExaminationTable = () => {
        return (
            <View style={{ zIndex: -2 }}>
                {this.state.physicalDataList && this.state.physicalDataList.length > 0 ?
                    this.state.physicalDataList && this.state.physicalDataList.map((item, index) => {
                        var date_of_visit = moment(item.date_of_visit).format("DD-MM-YYYY")
                        return (
                            <View style={styles.dataContainer} >
                                <View style={{ flex: 0.1, marginHorizontal: 20 }}><Text style={styles.TableDataText}>{date_of_visit}</Text></View>
                                <View style={{ flex: 0.08 }}><Text style={styles.TableDataText}>{item.week}</Text></View>
                                <View style={{ flex: 0.08 }}><Text style={styles.TableDataText}>{item.weight + " (kg)"}</Text></View>
                                <View style={{ flex: 0.08 }}><Text style={styles.TableDataText}>{item.height_of_uterus + " (cm)"}</Text></View>
                                <View style={{ flex: 0.15 }}><Text style={styles.TableDataText}>{item.blood_pressure + " (mmHg)"}</Text></View>
                                <View style={{ flex: 0.13 }}>
                                    <Text style={styles.TableDataText}>{item.complaints}</Text>
                                </View>
                                <View style={{ flex: 0.13 }}>
                                    <Text style={styles.TableDataText}>{item.presentation}</Text>
                                </View>
                                <View style={{ flex: 0.15 }}>
                                    <Text style={styles.TableDataText}>{item.remarks}</Text>
                                 </View>
                                <View style={{ flex: 0.1, flexDirection: "row" }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                physicalDate: item.date_of_visit,
                                                weight: item.weight,
                                                week: item.week,
                                                bloodPressure: item.blood_pressure,
                                                complaint: item.complaints,
                                                presentation: item.presentation,
                                                remarks: item.remarks,
                                                physicalID: item.id,
                                                height: item.height_of_uterus,
                                                selectedSubTitle: "Physical Examination",
                                                isPhysicalExam: true
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                                    </TouchableOpacity>
                                    { item && item.id ?  
                                    <TouchableOpacity style={{ marginLeft: "0.5vw", width: "fit-content" }}
                                        onPress={() => {
                                            this.setState({
                                                isPhysicalExamDel: true,
                                                physicalID: item.id,
                                                selectedSubTitle: "Physical Examination",
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Delete} style={{ height: 25, width: 30 }} resizeMode="contain" />
                                    </TouchableOpacity> : null }
                                </View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    investigationTable = () => {
        return (
            <View style={{ zIndex: -2, minHeight: '25vh' }}>
                {this.state.investigationChartList && this.state.investigationChartList.length > 0 ?
                    this.state.investigationChartList && this.state.investigationChartList.map((item, index) => {
                        return (
                            <View style={styles.dataContainer} >
                                <View style={{ flex: 0.2, marginHorizontal: 20, flexDirection: "row" }}>
                                    <Text style={styles.TableDataText}>{item.month_weeks}</Text>
                                </View>
                                <View style={{ flex: 0.4 }}>

                                    {item.investigation_test && item.investigation_test.length > 0 && (item.investigation_test).map((data, index1) => {
                                        return (
                                            <View style={{ flexDirection: "row", flex: 1, marginVertical: "0.5vw" }}>
                                                <Text style={[styles.TableDataText, { flex: 0.5 }]}>{data.name}</Text>
                                                <Text style={[styles.TableDataText, { flex: 0.5 }]}>{data.value}</Text>
                                            </View>
                                        )
                                    })}
                                </View>


                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.remarks ? item.remarks : "-"}</Text></View>
                                <View style={{ flex: 0.2, flexDirection: "row" }}>
                                    <TouchableOpacity
                                        // disabled = {index == 0 || item.id ? false : (this.state.investigationChartList[index-1].id != "" && this.state.investigationChartList[index-1].id != undefined ? false : true)}
                                        onPress={() => {
                                            this.setState({
                                               selectedMonthWeek: item.month_weeks,
                                               investigationDate: item.date_of_visit,
                                               investigationEditID: item.id,
                                               selectedSubTitle: "Investigation Chart",
                                               remark: item.remarks,
                                               selectedInvestigationList: [],
                                               isInvestigationChart: true
                                            }, () => {
                                            this.renderInvestigationID(this.state.investigationNameList, item.investigation_test, item.id ? item.id : "")
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                                    </TouchableOpacity>
                                    { item && item.id ?  
                                    <TouchableOpacity style={{ marginLeft: "0.5vw", width: "fit-content" }}
                                        onPress={() => {
                                            this.setState({
                                                investigationEditID: item.id,
                                                invastigationDel: true,
                                                selectedSubTitle: "Investigation Chart"
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Delete} style={{ height: 25, width: 30 }} resizeMode="contain" />
                                    </TouchableOpacity> : null }
                                </View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    scanObservationTable = () => {
        return (
            <View style={{ zIndex: -2 }}>
                {this.state.scanObservationList && this.state.scanObservationList.length > 0 ?
                    this.state.scanObservationList && this.state.scanObservationList.map((item, index) => {
                        var date = moment(item.date).format("YYYY-MM-DD")
                        return (
                            <View style={[styles.dataContainer, {backgroundColor: item.comments != "" && item.comments != undefined ? "#FFE4C4" : color.white }]} >
                                <View style={{ flex: 0.22, marginHorizontal: 20 }}>
                                    <Text style={styles.TableDataText}>{item.month_weeks}</Text>
                                    <Text style={[styles.TableDataText, {color: color.red}]}>{item.comments ? item.comments : null}</Text>
                                </View>
                                <View style={{ flex: 0.23 }}><Text style={styles.TableDataText}>{item.scan_name}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.scan_findings}</Text></View>
                                <View style={{ flex: 0.2 }}><Text style={styles.TableDataText}>{item.remarks}</Text></View>
                                <View style={{ flex: 0.15, flexDirection: "row" }}>
                                    <TouchableOpacity
                                        // disabled = {index == 0 ? false : (this.state.scanObservationList[index-1].id != "" && this.state.scanObservationList[index-1].id != undefined ? false : true)}
                                        onPress={() => {
                                            this.state.scanNameList && this.state.scanNameList.length > 0 && this.state.scanNameList.filter((e)=> {
                                               if(e.name == item.scan_name) {
                                                   this.setState({
                                                    scanName: e.name,
                                                    scanID: e.id
                                                })
                                               }
                                            })
                                            this.setState({
                                                scanName: item.scan_name,
                                                scanDate: item.date_of_visit,
                                                scanFinding: item.scan_findings,
                                                scanRemarks: item.remarks,
                                                scanEditID: item.id,
                                                selectedScanMonth: item.month_weeks,
                                                selectedSubTitle: "Scan Observation",
                                                isScanEdit: true,
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Edit} style={{ height: 25, width: 25 }} resizeMode="contain" />
                                    </TouchableOpacity>
                                    { item && item.id ?  
                                    <TouchableOpacity style={{ marginLeft: "0.5vw", width: "fit-content" }}
                                        onPress={() => {
                                            this.setState({
                                                scanEditID: item.id,
                                                isScanObservationDelete: true,
                                                selectedSubTitle: "Scan Observation"
                                            })
                                        }}
                                    >
                                        <Image source={ImagePath.Delete} style={{ height: 25, width: 30 }} resizeMode="contain" />
                                    </TouchableOpacity> : null }
                                </View>
                            </View>
                        )
                    }) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
                        <Text style={{ color: color.placeholder, marginTop: "5vw" }}>{"No records to be shown"}</Text>
                    </View>
                }
            </View>
        )
    }
    renderHeader = (header) => {
        return (
            <View style={{ margin: "0.5vw" }}>
                <Text style={{ fontSize: "1.2vw", fontWeight: "500", color: color.themeDark }}>{header}</Text>
            </View>
        )
    }
    renderRightCommonView = (header, key, value, zIndex) => {
        return (
            <View style= {{ zIndex: key === "scanFinding" ? -11 : null }}>
              <View style= {{ zIndex: zIndex ? zIndex : -100, marginTop: "2.5vw", flexDirection: "row", flex: 1, marginVertical: "0.3vw" }}>
                <View style={{ flex: 0.25, justifyContent: "center" }}>
                    <Text style={{ fontSize: ".87vw" }}>{header}</Text>
                </View>
                <View style={{ flex: 0.65, justifyContent: "center"}}>
                    {key === "lastMenstrualPeriod" || key === "obstetricDate" || key === "physicalDate" || key === "investigationDate" || key === "scanDate" || key === "expectedDateOfDelivery" || key === "vaccinationDate" ?
                        <DatePicker
                            value={value ? moment(value) : ""}
                            disabled = {( key === "lastMenstrualPeriod" || key === "expectedDateOfDelivery") && this.state.isObtetricData ? true : false }
                            disabledDate={current => {
                               if(  key != "expectedDateOfDelivery" && key != "lastMenstrualPeriod" ) {  
                                return current && current > moment(converted_date).add(3, "days") || current < moment().add(-4, "days")
                               } 
                            }}
                            onChange={(date) => {
                                if(key === "lastMenstrualPeriod") {
                                       this.setState({
                                        lastMenstrualPeriod: moment(date).format("YYYY-MM-DD")
                                       }, () => {
                                        var  expectedDateOfDelivery = moment(this.state.lastMenstrualPeriod).add(40, 'week').format("YYYY-MM-DD");
                                        this.setState({
                                            expectedDateOfDelivery: expectedDateOfDelivery
                                        })
                                       })  
                                }
                                var states = this.state
                                if(key != "") {
                                    states[key] = moment(date).format("YYYY-MM-DD")
                                }
                                this.setState({ states }, () => {
                                    var dateFirst = ""
                                    var dateSecond = ""
                                    // time difference
                                    var timeDiff = ""
                                    // days difference
                                    var diffDays =""
                                    var weeks = ""
                                    var month = ""
                                    var selectedMonthWeek = ""

                                    if(key ===  "investigationDate")  {
                                         dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                         dateSecond = new Date(this.state.investigationDate);
                                        // time difference
                                         timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                        // days difference
                                         diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                         weeks = Math.round(diffDays / 7)
                                         month = Math.round(weeks / 4)
                                         selectedMonthWeek = ""
                                         selectedMonthWeek = weeks + "Week" + " / "+ month + " Month"
                                         this.setState({
                                           selectedMonthWeek: selectedMonthWeek,
                                            
                                         })
                                    } else if(key ===  "scanDate")  {
                                        dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                        dateSecond = new Date(this.state.scanDate);
                                       // time difference
                                        timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                       // days difference
                                        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                        weeks = Math.round(diffDays / 7)
                                        month = Math.round(weeks / 4)
                                        selectedMonthWeek = ""
                                        selectedMonthWeek = weeks + " Week " + " / "+ month + " Month"
                                       this.setState({
                                        selectedScanMonth: selectedMonthWeek
                                       })
                                    } else if(key ===  "vaccinationDate")  {
                                        dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                        dateSecond = new Date(this.state.vaccinationDate);
                                       // time difference
                                        timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                       // days difference
                                        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                        weeks = Math.round(diffDays / 7)
                                        month = Math.round(weeks / 4)
                                        selectedMonthWeek = ""
                                        selectedMonthWeek = weeks + " Week " + " / "+ month + " Month"
                                        this.setState({
                                            selectedDateMonth: selectedMonthWeek
                                        })
                                     }
                                    
                                });
                            }
                            }
                            clearIcon={false}
                            format={"YYYY-MM-DD"}
                            style={{
                                zIndex: -1, width: '60%',
                                borderColor: '#CDD1D5',
                                height: "4.5vh", borderRadius: 4, borderWidth: 1,
                                marginTop: "0.5vw",
                                justifyContent: "center",
                                alignItems: 'center',
                                alignContent: "center",
                                padding: "0.8vw",
                            }}
                        /> : key === "modeOfDelivery" ?
                            <Picker
                                selectedValue={this.state.modeOfDelivery}
                                style={[styles.SelectTemplateDropDown, Style.allButtonBorderRadius]}
                                itemStyle={{ fontSize: '0.8vw' }}
                                onValueChange={(itemvalue) => {
                                    this.setState({
                                        modeOfDelivery: itemvalue
                                    })
                                }}
                            >
                                <Picker.Item key={-1} label={"Select Mode of delivery"} value={""} />
                                <Picker.Item label={"Vaginal Birth"} value={"Vaginal Birth"} />
                                <Picker.Item label={"Natural Birth"} value={"Natural Birth"} />
                                <Picker.Item label={"Scheduled Caesarean"} value={"Scheduled Caesarean"} />
                                <Picker.Item label={"Unplanned Caesarean"} value={"Unplanned Caesarean"} />
                                <Picker.Item label={"Vaginal Birth after C-Section (VBAC)"} value={"Vaginal Birth after C-Section (VBAC)"} />
                                <Picker.Item label={"Scheduled Induction"} value={"Scheduled Induction"} />
                            </Picker> : key === "sex_wt" ?
                                <Picker
                                    selectedValue={this.state.sex_wt}
                                    style={[styles.SelectTemplateDropDown, Style.allButtonBorderRadius]}
                                    itemStyle={{ fontSize: '0.8vw' }}
                                    onValueChange={(itemvalue) => {
                                        this.setState({
                                            sex_wt: itemvalue
                                        })
                                    }}
                                >
                                    <Picker.Item  key={-1} label={"Select Gender"} value={""} />
                                    <Picker.Item label={"Male"} value={"Male"} />
                                    <Picker.Item label={"Female"} value={"Female"} />
                                </Picker> :
                                <TextInput value={value} style={{ height: (key === "remarks" || key === "presentation" || key === "scanFinding" || key === "scanRemarks" || key === "complaint" || key === "vaccinationRemark" || key === "commonRemarks" ) ? "12vh" : "4.5vh", borderWidth: 1, borderColor: color.lightGray, padding: "0.5vw", borderRadius: "0.3vw", width: key === "PICME" || key === "remarks" || key === "presentation" || key === "scanFinding" || key === "scanRemarks" || key === "commonRemarks" ? "100%" : "60%", zIndex: -100 }}
                                    keyboardType = { key == "para" || key == "gravida" || key == "antenatalPeriod" || key == "neonatalPeriod" || key == "birthWeight" || key == "weight" || key == "height" ? "numeric" : null}
                                    onChangeText={(text) => {
                                        var states = this.state
                                        states[key] = text
                                        this.setState({
                                            states
                                        }, () => {
                                            if(key === "scanName") {
                                                this.getScanList()
                                            }
                                        })
                                    }}
                                />
                    }
                </View>
                </View>
                { this.state.scanNameList && this.state.scanNameList.length > 0 && key === "scanName" && !this.state.isScanEdit && this.state.scanName.length > 0 ?
                 <View style={[styles.suggestionMain, {  marginTop: "5.5vw", zIndex: 100}]}>
                 <ScrollView showsVerticalScrollIndicator = {false} showsHorizontalScrollIndicator = {false}
                 style={{
                   flex: 1,
                   backgroundColor: color.black, 
                   borderColor: color.lightGray, 
                   borderWidth: 1, 
                   borderRadius: 4, 
                   paddingHorizontal: 10,
                 }}
                 >
                   {this.state.scanNameList && (this.state.scanNameList).map((item) => ( 
                   <View>
                     <TouchableOpacity
                     key={index}
                     style={{marginTop: 5, marginBottom: 2}}
                     onPress={() => {
                         this.setState({
                            scanName: item.name,
                            scanID: item.id,
                            scanNameList: []

                         })
                     }}
                     >
                       <Text style={{color: color.white, fontWeight: "bold"}}>{item.name}</Text>
                     </TouchableOpacity>
                   </View> 
       
                   ))}
       
                    </ScrollView>
       
                  </View> : null

                }
            </View>
        )
    }
    renderSpecialHeader = (header, key, value) => {
        return (
            <View style={{ marginTop: "2.5vw", flexDirection: "row", flex: 0.5 }}>
                <View style={{ flex: 0.5, justifyContent: "center" }}>
                    <Text style={{ fontSize: ".87vw" }}>{header}</Text>
                </View>
                <View style={{ flex: 0.5 }}>
                    <TextInput value={value} style={{ height: "4.5vh", borderWidth: 1, borderColor: color.lightGray, padding: "0.5vw", borderRadius: "0.3vw", width: "60%", backgroundColor: ((key === "para" || key === "gravida" || key === "liveBirth" || key === "aborption") && this.state.isObtetricData) ? "#E6E6E3" : color.white }}
                        editable = { ((key === "para" || key === "gravida" || key === "liveBirth" || key === "aborption" || key == "height" || key == "weight" || key == "bloodPressure" || key == "neonatalPeriod" || key == "antenatalPeriod" || key == "birthWeight")) && this.state.isObtetricData ? false : true }
                        keyboardType = {(key === "para" || key === "gravida" || key === "liveBirth" || key === "aborption")  ? "numeric" : null}
                        onChangeText={(text) => {
                            var states = this.state
                            states[key] = text
                            this.setState({
                                states
                            })
                        }}
                    />
                </View>
            </View>
        )
    }
    renderBottomButtonView = () => {
        var { obstetricID, obstetricEditID } = this.state
        return (
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
                <TouchableOpacity style={styles.AddButton}
                    onPress={() => {
                        this.setState({
                            isObstetricDataNew: false
                        })
                        if (this.state.selectedSubTitle === "Obstetric Data") {
                            if (obstetricEditID) {
                                this.obstetricDataUpdate()
                            } else {
                                this.obstetricDataSave()
                            }
                        } else if (this.state.selectedSubTitle === "Obstetric History") {
                            if (obstetricID) {
                                this.obstetricUpdate()
                            } else {
                                this.obstetricHistorySave()
                            }
                        } else if (this.state.selectedSubTitle === "Physical Examination") {
                            if (this.state.physicalID) {
                                this.physicalExaminationUpdate()
                            } else {
                                this.physicalExaminationSave()
                            }
                        } else if( this.state.selectedSubTitle === "Investigation Chart") {
                            if(this.state.investigationEditID) {
                                this.investigationUpdate()
                            } else {
                                this.investigationChartPost()
                            }
                        } else if( this.state.selectedSubTitle === "Scan Observation") {
                           if(this.state.scanEditID) {
                            this.scanObservationUpdate()
                           } else {
                            this.scanObservationPost()
                           }
                        } else if( this.state.selectedSubTitle === "Vaccination"  ) {
                          if(this.state.vaccinationID) {
                              this.vaccinationUpdate()
                          } else{
                            this.vaccinationPost()
                          }
                        }
                    }}
                >
                    <Text style={styles.addText}>Save</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.AddButton, { marginLeft: "0.5vw" }]}
                    onPress={() => {
                       if(this.state.selectedSubTitle ==="Obstetric Data") {
                           this.obstetricDataClear()
                       } else if(this.state.selectedSubTitle === "Obstetric History"){
                           this.obstetricClear()
                       } else if(this.state.selectedSubTitle === "Physical Examination") {
                           this.clearPhysicalData()
                        } else if( this.state.selectedSubTitle === "Investigation Chart") {
                            this.investigationClear()
                        } else if( this.state.selectedSubTitle === "Scan Observation") {
                            this.sacnObservationClear()
                        } else if( this.state.selectedSubTitle === "Vaccination"  ) {
                            this.vaccinationClear()
                        }   
                        }}
                >
                    <Text style={styles.addText}> Clear </Text>
                </TouchableOpacity>
            </View>
        )
    }
    completeAntenentalChart = () => {
        if (this.state.commonRemarks != "" ) {
            var service_url = Constants.OBTETRIC_COMPLETE
            var data = {
               "antenatal_id" : this.state.antenatal_id,
               "remarks": this.state.commonRemarks
            }
            OpthamologyService.getInstance().postComplaints(
                service_url,
                data,
                this,
                this.completeAntenentalChartSuccess,
                this.completeAntenentalChartFailure
            );
        } else {
            this.props.showResposeValue("error", "Please enter all the details to proceed")
        }   
    }
    completeAntenentalChartSuccess = (response) => {
        if (response && response.status === "success") {
            this.props.showResposeValue("success", response.message)
            this.setState({
                obstetricDataList: [],
                obstetricList: [],
                physicalDataList: [],
                investigationChartList: [],
                scanObservationList: [],
                vaccinationList: [],
                isObstetricDataNew: false
            }, () => {
                this.getObstetricData()
                this.getObstetricHistory()
                this.getPhysicalExamination()
                this.getInvestigationChart()
                this.getScanObservationList()
                this.gatVaccinationList()
            })
        }
    }
    completeAntenentalChartFailure = (error) => {
        this.props.showResposeValue("error", error.message)
    }
    render() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationList}
                    selectedTab={"antenatalChart"}
                    isNavigationBorder={true}
                />
                <View style={{ flexDirection: "row", width: "100%" }}>
                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '78vh', width: "65%" }}>
                        <View style={[styles.subContainer, { marginTop: "0vw" }]}>
                            {this.anatentalMainHeader("Obstetric Data")}
                            {this.renderPreviousObstetricHeader()}
                            {this.previousObstetricTable()}
                        </View>
                        <View style={styles.subContainer}>
                            {this.anatentalMainHeader("Obstetric History")}
                            {this.renderObstetricHeader()}
                            {this.ObstetricTable()}
                        </View>
                        <View style={styles.subContainer}>
                            {this.anatentalMainHeader("Physical Examination")}
                            {this.renderPhysicalHeader()}
                            {this.physicalExaminationTable()}
                        </View>
                        <View style={styles.subContainer}>
                            {this.anatentalMainHeader("Investigation Chart")}
                            {this.investigationChartHeader()}
                            {this.investigationTable()}
                        </View>
                        <View style={styles.subContainer}>
                            {this.anatentalMainHeader("Scan Observation")}
                            {this.renderScanObservationHeader()}
                            {this.scanObservationTable()}
                        </View>
                        <View style={styles.subContainer}>
                            {this.anatentalMainHeader("Vaccination")}
                            {this.renderVaccinationHeader()}
                            {this.vaccinationTable()}
                        </View>
                        <View style={{ marginTop: "2vw", marginLeft: "0.5vw" }}>
                        {this.anatentalMainHeader("Remarks")}
                        <TextInput value={this.state.overAllRemark} style={{ height:  "12vh", borderWidth: 1, borderColor: color.lightGray, padding: "0.5vw", borderRadius: "0.3vw", width: "80%", zIndex: -100, margin: "1vw" }}
                                    onChangeText={(text) => {
                                        this.setState({
                                            overAllRemark: text
                                        })
                                    }}
                                /> 
                         </View>

                    </ScrollView>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ width: "35%", backgroundColor: color.themeShade, zIndex: -2 }}
                    >
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '78vh', zIndex: -11 }}>
                            <View>
                            {this.state.selectedSubTitle === "Obstetric Data" && this.state.previousObstetricData && this.state.previousObstetricData.length == 0  ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Obstetric Data")}
                                    {this.renderRightCommonView("PICME", "PICME", this.state.PICME, true)}
                                    <View style={{ flexDirection: "row" }}>
                                        {this.renderSpecialHeader("Gravida", "gravida", this.state.gravida, true)}
                                        {this.renderSpecialHeader("Para", "para", this.state.para)}
                                    </View>
                                    <View style={{ flexDirection: "row" }}>
                                        {this.renderSpecialHeader("Live Birth", "liveBirth", this.state.liveBirth)}
                                        {this.renderSpecialHeader("Abortion", "aborption", this.state.aborption)}
                                    </View>
                                    {this.renderRightCommonView("Last Menstrual Period", "lastMenstrualPeriod", this.state.lastMenstrualPeriod)}
                                    {this.renderRightCommonView("Expected Date of Delivery", "expectedDateOfDelivery", this.state.expectedDateOfDelivery)}
                                </View> : this.state.selectedSubTitle === "Previous Antenental" ?
                                <View>
                                {this.renderHeader("Previous Antenental")}
                                <Text style={{ fontSize: "0.8vw", color: color.lightGray, marginLeft: "0.5vw" }}>{"Select the previous antenatal date "}</Text>
                                        <View style={{ flexDirection: "row", marginTop: "1vw", flexWrap: "wrap" }}>
                                        { this.state.previousObstetricData && this.state.previousObstetricData.length > 0 && this.state.previousObstetricData.map((item, index) => {
                                          var date = moment(item.last_menstrual_period).format("YYYY-MM-DD")
                                          return(
                                            <TouchableOpacity style={[styles.AddButton, { margin: "0.5vw", alignSelf: "flex-end", backgroundColor: this.state.selectedPreviousData == item.last_menstrual_period ? color.themeDark : color.white, borderWidth: 1, borderColor: color.lightGray  }]} onPress={() => {
                                                var { previousObstetricData, obstetricDataList } = this.state
                                                var obstetricDataLists = []
                                                if ( this.state.selectedPreviousData == item.last_menstrual_period) {
                                                    this.setState({
                                                        selectedPreviousData: item.last_menstrual_period,
                                                        obstetricDataList: [],
                                                        selectedPreviousData: ""
                                                    }, () => {
                                                        for( let i =0; i < obstetricDataList.length; i++){
                                                            if(obstetricDataList[i].antenatal_obstetric.last_menstrual_period === this.state.selectedPreviousData){
                                                                obstetricDataLists.splice(obstetricDataList[i])
                                                                this.setState({
                                                                    obstetricDataList: obstetricDataLists,
                                                                })
                                                            }
        
                                                        } 
                                                    })   
                                                } else {
                                                this.setState({
                                                     selectedPreviousData: item.last_menstrual_period,
                                                     obstetricDataList: []
                                                }, () => {
                                                   for( let i =0; i < previousObstetricData.length; i++){
                                                    if(previousObstetricData[i].antenatal_obstetric.last_menstrual_period === this.state.selectedPreviousData){
                                                        obstetricDataLists.push({"antenatal_obstetric": previousObstetricData[i].antenatal_obstetric})
                                                        var remarks = previousObstetricData[i].antenatal_obstetric.remarks
                                                        this.setState({
                                                            obstetricDataList: obstetricDataLists,
                                                            overAllRemark: remarks,
                                                            prevSelectedID: previousObstetricData[i].antenatal_obstetric.id
                                                        }, () => {
                                                            this.getPreviousObstrtricData()
                                                        })
                                                    }

                                                   } 
                                                })
                                               }
                                            }}>
                                                <Text style={[styles.addText, { color:this.state.selectedPreviousData == item.last_menstrual_period ? color.white : color.black }]}>{ date }</Text>
                                            </TouchableOpacity>
                                             )
                                            })
                                            }
                                        </View>
                                   
                               </View> : null
                            }
                          </View>
                            {this.state.selectedSubTitle === "Obstetric History" ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Obstetric History")}
                                    {this.renderRightCommonView("Date", "obstetricDate", this.state.obstetricDate)}
                                    {this.renderRightCommonView("Antenatal Period (Days)", "antenatalPeriod", this.state.antenatalPeriod)}
                                    {this.renderRightCommonView("Mode of Delivery", "modeOfDelivery", this.state.modeOfDelivery)}
                                    {this.renderRightCommonView("Sex", "sex_wt", this.state.sex_wt)}
                                    {this.renderRightCommonView("Birth Weight (kg)", "birthWeight", this.state.birthWeight)}
                                    {this.renderRightCommonView("Outcome", "outcome", this.state.outcome)}
                                    {this.renderRightCommonView("Neonatal Period (Days)", "neonatalPeriod", this.state.neonatalPeriod)}
                                </View> : null
                            }
                            {this.state.selectedSubTitle === "Physical Examination" ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Physical Examination")}
                                    {this.renderRightCommonView("Date", "physicalDate", this.state.physicalDate)}
                                    {this.renderRightCommonView("Weight (kg) ", "weight", this.state.weight)}
                                    {this.renderRightCommonView("Height (cm)", "height", this.state.height)}
                                    {this.renderRightCommonView("Blood Pressure (mmHg)", "bloodPressure", this.state.bloodPressure)}
                                    {this.renderRightCommonView("Complaints", "complaint", this.state.complaint)}
                                    {this.renderRightCommonView("Presentation", "presentation", this.state.presentation)}
                                    {this.renderRightCommonView("Remarks", "remarks", this.state.remarks)}
                                </View> : null
                            }
                            {this.state.selectedSubTitle === "Investigation Chart" ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Investigation Chart")}
                                    {this.renderRightCommonView("Date", "investigationDate", this.state.investigationDate)}
                                    {this.renderInvestigationView("Investigation Name", "selectedInvestigation", this.state.selectedInvestigation)}
                                    {this.renderRightCommonView("Remarks ", "remark", this.state.remark)}
                                </View> : null
                            }
                            {this.state.selectedSubTitle === "Scan Observation" ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Scan Observation")}
                                    {this.renderRightCommonView("Date", "scanDate", this.state.scanDate, -100)}
                                    {this.renderRightCommonView("Scan Name ", "scanName", this.state.scanName, -200)}
                                    {this.renderRightCommonView("Scan Finding", "scanFinding", this.state.scanFinding, -300)}
                                    {this.renderRightCommonView("Remarks", "scanRemarks", this.state.scanRemarks, -400)}
                                </View> : null
                            }
                             {this.state.selectedSubTitle === "Vaccination" ?
                                <View style={styles.subContainer}>
                                    {this.renderHeader("Vaccination")}
                                    {this.renderRightCommonView("Date", "vaccinationDate", this.state.vaccinationDate)}
                                    {this.renderRightCommonView("Vaccination Name", "vaccinationName", this.state.vaccinationName)}
                                    {this.renderRightCommonView("Remarks ", "vaccinationRemark", this.state.vaccinationRemark)}
                                </View> : null
                            }
                           
                           
                        </ScrollView>
                        {this.state.selectedSubTitle != "" ?
                            <View>
                                {this.renderBottomButtonView()}
                            </View> : null
                        }
                    </ScrollView>
                </View>
                { (this.state.isScanObservationDelete && this.state.selectedSubTitle === "Scan Observation") ?
                    <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft: 10, justifyContent: "center" }}> 
                        <View style={{
                            position: "absolute",
                            backgroundColor: color.white,
                            border: "1px solid lightgrey",
                            borderRadius: 10,
                            padding: 10,
                            height: "28vh",
                            width: "25%",

                        }}>
                            <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '2vw', textAlign: 'center' }}>{"Enter the reason to continue the process ?"}</Text>
                            <TextInput value={this.state.deleteComment} style={{ height: "8vh", borderWidth: 1, borderColor: color.grayShade, padding: "0.5vw", borderRadius: "0.3vw", width: "90%", alignSelf: "center" }}
                                placeholder={"Enter the reason"}
                                onChangeText={(text) => {
                                    this.setState({
                                        deleteComment: text
                                    })
                                }}
                            />
                            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "flex-end", alignContent: "flex-end", marginTop: "1vw" }}>
                                <TouchableOpacity
                                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.white, borderRadius: '0.26vw', height: "1.8vw", width: "15%", marginRight: "1vw", borderWidth: 1, borderColor: color.black }}
                                    onPress={() => {
                                        this.setState({
                                            isScanObservationDelete: false,
                                        })
                                    }}>
                                    <Text style={{ color: 'black', fontSize: "0.78vw", }}>{"Cancel"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.red, borderRadius: '0.26vw', height: "1.8vw", width: "15%" }}
                                    onPress={() => {
                                        if (this.state.selectedSubTitle === "Scan Observation") {
                                            this.deleteScanObservation(this.state.scanEditID)
                                        }
                                    }}
                                >
                                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Delete"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View> : null}
                {(this.state.isPhysicalExamDel || (this.state.selectedSubTitle === "Obstetric History" && this.state.isObstitricHistoryDelete) || ( this.state.invastigationDel && this.state.selectedSubTitle === "Investigation Chart") || (this.state.selectedSubTitle === "Vaccination" && this.state.isVaccinationDelete) ) ?
                    <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft: 10, justifyContent: "center" }}>
                        <View style={{
                            position: "absolute",
                            backgroundColor: color.white,
                            border: "1px solid lightgrey",
                            borderRadius: 10,
                            padding: 10,
                            height: "15vh",
                            width: "20%",

                        }}>
                            <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '2vw', textAlign: 'center' }}>{"Are you sure ?"}</Text>
                            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", alignContent: "center" }}>
                                <TouchableOpacity
                                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%" }}
                                    onPress={() => {
                                        if (this.state.selectedSubTitle === "Obstetric History") {
                                            this.deleteObstitricHistoryData()
                                        } else if(  this.state.selectedSubTitle === "Physical Examination") {
                                            this.physicalEaminationDelete()
                                        } else if( this.state.selectedSubTitle === "Investigation Chart") {
                                            this.deleteInvestigationData()
                                        } else if(this.state.selectedSubTitle === "Vaccination") {
                                            this.deleteVaccination(this.state.vaccinationID)
                                        }
                                    }}>
                                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Yes"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%", marginLeft: "1vw" }}
                                    onPress={() => {
                                        this.setState({
                                            isPhysicalExamDel: false,
                                            isObstitricHistoryDelete: false,
                                            isVaccinationDelete: false,
                                            invastigationDel: false
                                        }, () => {
                                            // this.props.discardDataReset(true)
                                        })
                                    }}
                                >
                                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"No"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View> : null}
                    { this.state.isObstetricDataNew && !this.state.isPreviousData && this.state.obstetricDataList && this.state.obstetricDataList.length > 0 ?
                      <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft: 10, justifyContent: "center" }}>
                      <View style={{
                          position: "absolute",
                          backgroundColor: color.white,
                          border: "1px solid lightgrey",
                          borderRadius: 10,
                          padding: 10,
                          height: "25vh",
                          width: "20%",

                      }}>
                          <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '2vw', textAlign: 'center' }}>{"Are you new obtetric Data ?"}</Text>
                          <TextInput value={this.state.commonRemarks} style={{ height: "8vh", borderWidth: 1, borderColor: color.grayShade, padding: "0.5vw", borderRadius: "0.3vw", width: "90%", alignSelf: "center" }}
                                placeholder={"Enter the reason"}
                                onChangeText={(text) => {
                                    this.setState({
                                        commonRemarks: text
                                    })
                                }}
                            />
                          <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", alignContent: "center", marginTop: "0.5vw" }}>
                              <TouchableOpacity
                                  style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%" }}
                                  onPress={() => {
                                     this.completeAntenentalChart()
                                  }}>
                                  <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Yes"}</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                  style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%", marginLeft: "1vw" }}
                                  onPress={() => {
                                      this.setState({
                                        isObstetricDataNew: false,
                                      }, () => {
                                          // this.props.discardDataReset(true)
                                      })
                                  }}
                              >
                                  <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"No"}</Text>
                              </TouchableOpacity>
                          </View>
                      </View>

                  </View> : null
                    }
            </View>
        );
    }
    renderInvestigationID(array1, array2, id) {
        var { selectedInvestigationList } = this.state;
        return array1.filter(object1 => {
          return array2.some(object2 => {
            if (object1.name === object2.name && id != ""){
                var data = { "name": object2.name, "value": object2.value, "id": object2.id }
                selectedInvestigationList.push(data)
                this.setState({
                    selectedInvestigationList
                })
            } else if( object1.name === object2.name ){
                var data = { "name": object2.name, "value": object2.value, "id": object1.id }
                selectedInvestigationList.push(data)
                this.setState({
                    selectedInvestigationList
                })
            }
          });
        });
      }
    renderInvestigationView = (title, key, value) => {
        return (
            <View>
                <View style={{ marginTop: "2.5vw", flexDirection: "row", flex: 1, marginVertical: "0.3vw" }}>
                    <View style={{ flex: 0.27, justifyContent: "center" }}>
                        <Text style={{ fontSize: ".87vw" }}>{title}</Text>
                    </View>
                    <TextInput value={value} style={{ height: "4.5vh", borderWidth: 1, borderColor: color.lightGray, padding: "0.5vw", borderRadius: "0.3vw", width: "70%", flex: 0.58 }}
                        onChangeText={(text) => {
                            var states = this.state
                            states[key] = text
                            this.setState({
                                states
                            }, () => {
                                this.getInvestigationName()
                            })
                        }}
                    />
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "20%", marginLeft: "1vw", flex: 0.15 }}
                        disabled = {this.state.selectedInvestigation != "" ? false : true}
                        onPress={() => {
                            var { selectedInvestigationList, selectedInvestigation } = this.state
                            var data = {"name": selectedInvestigation, "value": "", "id": this.state.selectedInvestigationID }
                            selectedInvestigationList.push(data)
                            this.setState({
                                selectedInvestigationList,
                                selectedInvestigation: ""
                            })
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"+"}</Text>
                    </TouchableOpacity>
                </View>
                {this.renderInvestigationName()} 
                { key === "selectedInvestigation" && this.state.selectedInvestigation.length > 0 && this.state.investigationNameList && this.state.investigationNameList.length > 0 ?
                     <View style={styles.suggestionMain}>
                     <ScrollView showsVerticalScrollIndicator = {false} showsHorizontalScrollIndicator = {false}
                     style={{
                       flex: 1,
                       backgroundColor: color.black, 
                       borderColor: color.lightGray, 
                       borderWidth: 1, 
                       borderRadius: 4, 
                       paddingHorizontal: 10,
                     }}
                     >
                       {this.state.investigationNameList && (this.state.investigationNameList).map((item) => ( 
                       <View>
                         <TouchableOpacity
                         key={index}
                         style={{marginTop: 5, marginBottom: 2}}
                         onPress={() => {
                             this.setState({
                                selectedInvestigation: item.name,
                                selectedInvestigationID: item.id,
                                investigationNameList: []

                             })
                         }}
                         >
                           <Text style={{color: color.white, fontWeight: "bold"}}>{item.name}</Text>
                         </TouchableOpacity>
                       </View> 
           
                       ))}
           
                        </ScrollView>
           
                      </View> : null
                }
            </View>
        )
    }
    renderInvestigationName = () => {
        let { selectedInvestigationList } = this.state
        return(
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ borderWidth: selectedInvestigationList && selectedInvestigationList.length > 0 ? 1 : null, borderColor:  selectedInvestigationList && selectedInvestigationList.length > 0 ? color.lightGray : null, borderRadius: "0.3vw", maxHeight: "35vh", marginTop: "1vw", padding: "1vw", height: "25vh" }}>
            { selectedInvestigationList && selectedInvestigationList.length > 0 && selectedInvestigationList.map((item, index) => {
                return (
                        <View style={{ flexDirection: "row", flex: 1, marginVertical: "0.2vw" }}>
                            <View style={{ flex: 0.27, justifyContent: "center" }}>
                                <Text style={{ fontSize: ".87vw" }}>{item.name}</Text>
                            </View>
                            <TextInput value={item.value} style={{ height: "4.5vh", borderWidth: 1, borderColor: color.lightGray, padding: "0.5vw", borderRadius: "0.3vw", width: "70%", flex: 0.58 }}
                                onChangeText={(text) => {
                                    var { selectedInvestigationList } = this.state
                                    selectedInvestigationList[index].value = text
                                    this.setState({
                                        selectedInvestigationList
                                    })
                                }}
                            />
                        </View>
                )
            })
            }
        </ScrollView>
        )
    }
}
const styles = StyleSheet.create({
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.8vw",
        justifyContent: 'center',
        flex: 1
    },
    subContainer: {
        marginHorizontal: "0.5vw", marginTop: "2vw", zIndex: -2

    },
    headerStyle: {
        borderRightColor: "#888888", borderRightWidth: 0.5, justifyContent: 'center'
    },
    headerStyles: {
        borderRightColor: "#888888", borderRightWidth: 0.5, justifyContent: 'center', padding: "0.3vw"
    },
    AddButton: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        padding: "0.5vw",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    addText: {
        color: color.white,
        fontSize: "0.87vw"
    },
    SelectTemplateDropDown: {
        height: '2vw',
        backgroundColor: "transparent",
        fontSize: '0.9vw',
        width: "60%"
    },
    suggestionMain:{position: "absolute", width: "15vw", marginTop: "5vw", marginLeft: "8vw", maxHeight: "18vh", zIndex: 1}

})
