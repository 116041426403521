import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import { Tooltip } from 'antd';
import AsyncStorage from '../../../AsyncStorage';


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class ServiceDetailed extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    this.state = {
      data: {},
      doctorandservices: {},
      totalAmount: '',
      selectedDoctorAction: '',
      selectedServiceAction: '',
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isServiceDetailed: false,
      isClear: false,
      isClearInt: 0,
      valueData: [],
      isPaginated: false,
      selectedServiceInput: "",
      ServiceInput: "",
      searchServicesList: [],
      ListOpen: false, 
      selectedServiceType: "",
      serviceTypeList: [],
      isAdminAccess: false
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getServiceDetailed()
    this.getBillingList()
  }
  getServiceDetailed() {
    var states = this.state
    this.setState({ isClear: false, valueData: [], data:[] })
    var service_url = Constants.POST_ServiceDetailed;
    var data = {
      'doctor_id': states.selectedDoctorAction === '' ? [] : states.selectedDoctorAction === 'Nursing' ? ['Nursing'] : [Number(states.selectedDoctorAction)],
      'service_name': states.selectedServiceInput,
      'service_type': states.selectedServiceType,
      'from_date': this.state.filterData.fromDate,
      'to_date': this.state.filterData.toDate
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.ChangeStatusSuccess,
      this.ChangeStatusFailure
    );
  }
  ChangeStatusSuccess = success => {

    if (success.status == "success") {

      this.setState({
        data: success.data,
        totalAmount: success.data.total_amount,
        isServiceDetailed: true
      })

    } else {
      console.log('error', success.message);

    }
  }
  ChangeStatusFailure = error => {

    console.log('error', error.message);
  }

  getBillingList = () => {
    let states = this.state;
    var serviceUrl = Constants.GET_ServiceName;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getBillingListSuccess,
      this.getBillingListFailure
    );
  }

  getBillingListSuccess = success => {
    if (success.status == "success") {
      const newData = { ...success.data }
      this.setState(Object.assign(this.state.doctorandservices, { ...newData }));

    }
  }

  getBillingListFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }
  getServiceTypeList = () => {
    var serviceUrl = Constants.SERVICE_TYPE_LIST + "?service_name=" + this.state.selectedServiceInput;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getServiceTypeSuccess,
      this.getServiceTypeFailure
    );
  }

  getServiceTypeSuccess = success => {
    if (success.status == "success") {
       this.setState({
        serviceTypeList: success.data
       })
    }
  }

  getServiceTypeFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }
  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })

  }

  renderPickerDoctorName() {
    return (
      <View>
        <Picker

          selectedValue={this.state.selectedDoctorAction}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor:
              this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5'
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedDoctorAction"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Doctor Name' value='' />
          {Object.keys(this.state.doctorandservices).length > 0 ?
            this.state.doctorandservices.doctor.map(name =>
              <Picker.Item label={name.doctor_name} value={name.doctor_id} />

            )

            : null
          }

        </Picker>
      </View>
    )
  }

  renderPickerServiceType() {
    return (
      <View>
        <Picker
          selectedValue={this.state.selectedServiceType}
          enabled = {this.state.serviceTypeList && this.state.serviceTypeList.length > 0 ? true : false}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor: '#CDD1D5'
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedServiceType"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Service Type' value='' />
          { this.state.serviceTypeList.length > 0 ?
            this.state.serviceTypeList.map(list =>
              <Picker.Item label={list.type} value={list.type} />

            )

            : null
          }

        </Picker> 
      </View>
    )
  }
  renderServiceList = (value) => {
    let { searchServicesList } = this.state;
    let tempDescList = this.state.doctorandservices.services
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].service_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchServicesList = searchListTemp;
    } else {
      searchServicesList = this.state.doctorandservices.services;
    }
    this.setState({
      searchServicesList
    })
  }

  renderServiceAutoSearch = () => {
    return (

      <View style={{ zIndex: 2 }}>
        <TextInput
          placeholder='Select Service Name'
          style={{ marginTop: '5px', height: '2vw', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", borderRadius: '4px', width: '14vw', fontSize: '1vw', paddingLeft: '.8vw' }}
          value={this.state.ServiceInput}
          onChangeText={(text) => {
            this.setState({ ServiceInput: text, selectedServiceInput: '' }, () => {
              this.renderServiceList(text);
              this.setState({ ListOpen: this.state.ServiceInput.length > 0 ? true : false, selectedServiceType: "", serviceTypeList: [] })
            })
          }
          }
        />
        {this.state.ServiceInput.length > 0 && this.state.ListOpen && this.state.searchServicesList.length > 0 ?
          <ScrollView showsVerticalScrollIndicator={false} style={{ position: 'absolute', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", marginTop: "2.3vw", maxHeight: '12vw', zIndex: 3, width: '14.64vw', borderRadius: '4px' }}>
            {
              this.state.searchServicesList.map(name =>
                <TouchableOpacity
                  style={{ marginTop: '.5vw', paddingLeft: '.8vw' }}
                  onPress={
                    event => {
                      this.setState({ ServiceInput: name.service_name, selectedServiceInput: name.service_name, ListOpen: false }, () => {
                        if(this.state.ServiceInput.length > 0) {
                          this.getServiceTypeList()
                        }
                      })
                    }
                  }
                >
                  <Text style={{ fontSize: '1vw' }}>
                    {name.service_name}
                  </Text>
                </TouchableOpacity>
              )
            }
          </ScrollView>
          : null}
      </View>
    )
  }

  onPressButton(key, value) {
    if (key == "save") {
      this.getServiceDetailed()
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");

      var states = this.state
      // states['valueData'] = []
      this.setState({
        selectedDoctorAction: "",
        selectedServiceInput: "",
        ServiceInput: "",
        selectedServiceType: "",
        serviceTypeList: [],
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        isClear: true,
        isClearInt: 1
      }, () => {
        this.getServiceDetailed();
        this.setState({
          isClear: false
        }, () => {
          this.renderDateFilter()
        })
      })
    }
    else if (key == "print") {
      this.PrintPatienToken()
    }
  }

  PrintPatienToken = (key) => {

    let states = this.state;
    let data = {
      'doctor_id': states.selectedDoctorAction === '' ? [] : states.selectedDoctorAction === 'Nursing' ? [states.selectedDoctorAction] : [Number(states.selectedDoctorAction)],
      'service_name': states.selectedServiceInput,
      'service_type': states.selectedServiceType,
      'from_date': states.filterData.fromDate,
      'to_date': states.filterData.toDate

    }

    var serviceUrl = Constants.PRINT_ServiceDetail + '?export=pdf';
    OpthamologyService.getInstance().documentUploadPost(
      serviceUrl,
      data,
      this,
      this.getPrintSuccess,
      this.getPrintFailure
    );


  };

  getPrintSuccess = response => {

    if (response) {
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {

    console.log(error);
  };
  // Print

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }


  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }



  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "65%", height: screenHeight - 65, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            <View style={{ width: "100%", marginTop: "1.5vw", paddingHorizontal: "0.35" }} >
              <CommonHistorySectionHeader
                heading1={"Date"}
                heading2={"Doctor Name"}
                heading3={"Patient Name"}
                heading4={"Service Name"}
                heading5={"Service Type"}
                heading6={"Amount"}
                noOfColumn={6}
                columnSize={[0.15, 0.15, 0.15, 0.20, 0.20, 0.15]}
              />
              <ScrollView showsVerticalScrollIndicator={false} style={styles.TableBody}>
                <View >
                  {this.state.valueData && this.state.valueData.length > 0 ?
                    this.state.valueData.map(name => {
                      var date = moment(name.date).format("DD-MM-YYYY")
                      return (
                        <View style={{ flexDirection: "row", paddingVertical: '0.8vw', width: "100%", borderBottomWidth: 1, borderBottomColor: color.lightGray }}>
                          <Text style={{ fontSize: "1vw", flex: 0.15, marginHorizontal: 20 }}>{date}</Text>
                          <Text style={{ fontSize: "1vw", flex: 0.15, }}>{name.doctor_name == null ? name.nurse_name : name.doctor_name}</Text>
                          <View style={{ flex: 0.15 }}>
                          <Tooltip placement="topLeft" title={name.patient_name}>
                          <Text style={{ fontSize: "1vw"}}>{name.patient_name.length > 12 ? name.patient_name.slice(0, 12) + "..." : name.patient_name}</Text>
                          </Tooltip>
                          </View>
                          <View style={{ flex: 0.20 }}>
                          <Tooltip placement="topLeft" title={name.service_name}>
                            <Text style={{ fontSize: "1vw"}}>{name.service_name.length > 15 ? name.service_name.slice(0, 15) + "..." : name.service_name }</Text>
                            </Tooltip>
                          </View>
                          <View style={{ flex: 0.20 }}>
                          <Tooltip placement="topLeft" title={name.service_type}>
                          <Text style={{ fontSize: "1vw"}}>{name.service_type.length > 12 ? name.service_type.slice(0, 12) + "..." : name.service_type}</Text>
                          </Tooltip>
                          </View>
                          <Text style={{ fontSize: "1vw", flex: 0.15}}>{name.total}</Text>
                        </View>)
                    }
                    ) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                      <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                    </View>
                  }</View>
              </ScrollView>
            </View>
            <View style={{ marginLeft: 20 }}>
              {this.state.data.service_overview && this.state.data.service_overview.length > 0 ?
                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.data.service_overview)} totalItems={this.state.data.service_overview} />
                : null
              }

            </View>
          </View>
        </View>

        {/* ============================ */}


        <View style={{ width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, padding: '1vw' }}>
          <View>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 15 }}>
              <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white,
                borderRadius: 4,
                paddingHorizontal: 15,
                minWidth: '8vw',
                height: '4vw',
                marginRight: 20
              }}>
                <View><Text style={{ fontSize: '1.02vw', fontWeight: '500', marginTop: 10 }}>{this.state.data.total_amount ? "₹ " : null}{this.state.totalAmount ? parseFloat(this.state.totalAmount).toFixed(2) : 0}</Text><Text style={{ fontSize: '0.87vw', fontWeight: '400', textAlign: 'right', paddingVertical: 8 }}>{"Total"}</Text></View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", minWidth: "10.98vw",  flexDirection: "row", alignItems: "center", flex:1, justifyContent: "space-between" }}>
                    {this.renderDateFilter()}
                    {this.renderPickerDoctorName()}
              </View>

              <View style={{
                flexDirection: "row",
                marginBottom: 20,
                marginTop: 20,
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: -1
              }}>

                {this.renderServiceAutoSearch()}

                {/* { this.state.selectedServiceInput && this.state.selectedServiceInput.length > 0 ? */}
                  { this.renderPickerServiceType() }
                  {/* : null
                } */}
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }
              </View>
            </View>
          </View>
        </View >
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableBody: {
    height: '70vh', overflow: "scroll"
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)", display: 'flex', width: '100%', flexDirection: 'row', paddingVertical: '1vw', paddingHorizontal: '1vw', marginTop: '2.3vw'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  }
});
