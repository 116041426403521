import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  ScrollView,
  // Image,
  Platform,
  Picker,
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
import Icon from "react-native-vector-icons/FontAwesome";

// import AsyncStorage from "../../../AsyncStorage";
import { CommonButton ,DoctorNotesCommonRightSideHeader,CommonSectionHeader} from '../BaseComponent'
import { DatePicker } from "antd";
import moment from "moment";
import zIndex from "@material-ui/core/styles/zIndex";


const platform = Platform.OS;
const pageName = "medication";

// const durationList = [
// { value: 1, label: "1"}, 
// { value: 2, label: "2"}, 
// { value: 3, label: "3"},
// { value: 4, label: "4"},
// { value: 5, label: "5"},
// { value: 6, label: "6"},
// { value: 7, label: "7"},
// { value: 8, label: "8"},
// { value: 9, label: "9"},
// { value: 10, label: "10"},
// { value: 11, label: "11"},
// { value: 12, label: "12"},
// // { value: 14, label: "14"},
// // { value: 15, label: "15"},
// // { value: 18, label: "18"},
// // { value: 20, label: "20"},
// // { value: 21, label: "21"},
// // { value: 28, label: "28"},
// // { value: 30, label: "30"},
// // { value: 60, label: "60"},
// // { value: 90, label: "90"}
// ];

const newDate = new Date()
export default class MedicationNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.drugInput = React.createRef();

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: null,
      isNotFavoriteDrug: true,
      isAddNewClick: false,
      isDosageFieldEnable: false,
      patientAppointment: this.props.patientAppointment,
      favoriteDrugs: [],
      favoriteTaperDrugs: [],
      selectedEye: {},
      selectedDrug: {},

      selectedTab: "Medication",

      selectedDosage: {},
      selectedDosageCount: 1,
      selectedTabletCount: 1,
      selectedSyrupCount: 5,
      
      selectedFrequency: {},
      morning: '',
      afternoon: '',
      evening: '',
      night: '',

      durationMode: "Days",
      selectedDuration: "",
      comments: "",
      successOrErrorMessage: false,
      successMessageFlag: false,
      errorMessageFlag: false,

      isDrugSelected: false,
      drugSuggestionList: [],

      generic_name: "",
      isGenericSelected: false,
      drugDetailsSuggestionList: [],

      dosage_strength: "",
      DrugID:"",
      isNewdrug: false,

      prescriptionIsTransmitted:false,

      taperFromDate: moment(newDate).format("YYYY-MM-DD"),
      taperUnitVal: "",
      taperDuration: "",
      taperReducedBy: "",
      taperDurationMode: "Day(s)",
      taperFrequency: "",
      taperStartTimeMode: "AM",
      showTimerPopup: false,
      hour12Formet: '00',
      minutes: '00',
      taperInterval: "",
      taperAddedDetails: [],
      selectedEditIndex: null,

      tempId:"",
      isEdit: false,
      newFrequency:"",
      medicationFrequency:[],
      quantity: "",
      pharmacyList: [],
      selectedPharmacy: "",
      selectedClinic:this.props.selectedClinicOrDoctor,
      newItem: "",
      is_MefiFav: false,
    };

    this._getDrugSuggestion = this._getDrugSuggestion.bind(this);
    this._getDrugDetails = this._getDrugDetails.bind(this);
  }

  componentDidMount() {

    this.getMedictaionFrequency();
    this.getPharmacyList()
  }

  componentWillReceiveProps(props) {

    // console.log("prescriptionIsTransmitted ----- > "+props.prescriptionIsTransmitted)
    this.state.prescriptionIsTransmitted  == props.prescriptionIsTransmitted
    this.setState({
      prescriptionIsTransmitted:props.prescriptionIsTransmitted
    })
    let { id,tempId } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {
        // console.log(" props.editItem.item " + JSON.stringify(props.editItem.item))
        let item = props.editItem.item;

        if (tempId !== item.tempId   && item.tempId) {

          let duration = item.duration.split(" ")
          // let count = duration[0].slice(" ")

          // let durationType = item.duration.split(" ")[1]
          // if(duration%7 === 0 && duration > 12){

          //   duration = duration/7;
            // durationMode = 7;

            // console.log("modul0 7 " + duration);

          // } else if(duration%30 === 0){

          //   duration = duration/30;
          //   // durationMode = 30;

          //   // console.log("modulo 30 " + duration);
          // }

          if((item.tapper_meds).length > 0){
            var len = (item.tapper_meds).length
            var lastDate = item.tapper_meds[len -1].start_date
            var lastDuration = item.tapper_meds[len -1].duration
            var taperStartDate = new Date(lastDate)
            taperStartDate.setDate(taperStartDate.getDate() + (parseInt(lastDuration) + 1))
            taperStartDate = moment(taperStartDate).format("YYYY-MM-DD")

          }else{
            var taperStartDate = moment(newDate).format("YYYY-MM-DD")
          }

          var dosage_type = { value: item.dosage_type, label: item.dosage_type }

          this.setState({
            tempId: item.tempId ? item.tempId : "",
            id: item.id,

            selectedEye: item.eye_type?{ value: item.eye_type, label: item.eye_type}:{},
            selectedDrug: item.brand_name?{ value: item.brand_name, label: item.brand_name}:{},
            // newItem: item.brand_name?{ value: item.brand_name, label: item.brand_name}:{},
            newItem: item.brand_name?item.brand_name:'',
            isAddNewClick: item.isAddNewClick || item.drug_id == "" ? true : false,
            isNotFavoriteDrug: item.isNotFavoriteDrug || item.drug_id ? true : false,
            generic_name: item.generic_name,
            is_MefiFav: item.is_favorite,
            
            dosage_strength: item.dosage_strength,
            DrugID: item.drug_id,
            selectedFrequency: item.frequency?{ value: item.frequency, label: item.frequency}:{},
            selectedDuration: item.duration === "As on when required Days" ? "As on when required" :
            item.duration === "As on when required Weeks" ? "As on when required" : 
            item.duration === "As on when required Months" ? "As on when required" :
            item.duration ===  "Once In a Week Weeks" ? "Once In a Week" :
            item.duration === "Twice In a Week Weeks" ? "Twice In a Week" :
            item.duration === "Once In a Month Months" ? "Once In a Month" :
            item.duration === "Twice In a Month Months" ? "Twice In a Month" : duration[0],
            durationMode: duration[1] == "Until" ? "Until the Next Visit" : duration[0] === "As" ? "Days" :
            duration[3] === "Week" ? "Weeks" : duration[3] === "Month" ? "Months" : duration[1],
            comments: item.comments,
            morning: item.morning,
            afternoon: item.afternoon,
            evening: item.evening,
            night: item.night,
            quantity: item.quantity,

            selectedDosageCount:  ((item.no_of_dosage == null || item.no_of_dosage == "") && dosage_type.value !== "DROP") ? 1 : item.no_of_dosage,
            selectedTabletCount:  ((item.no_of_dosage == null || item.no_of_dosage == "") && dosage_type.value !== "TABLET") ? 1 : item.no_of_dosage,
            selectedSyrupCount:  ((item.no_of_dosage == null || item.no_of_dosage == "") && dosage_type.value !== "SYRUP") ? 5 : item.no_of_dosage,
            selectedDosage: dosage_type,
            selectedTab: (item.tapper_meds).length > 0 ? "TaperMed" : "Medication",
            taperAddedDetails: (item.tapper_meds).length > 0 ? item.tapper_meds : [],
            taperFromDate: taperStartDate,
          },()=>{
            // this.props.clearEditedItem(pageName,{})
          });
        }
      }
    }
  }
  getPharmacyList = async () => {
  
    var service_url = Constants.PHARMACY_LIST + "?clinic_id=" + this.state.selectedClinic.id + "&suggest=" +true;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPharmacyListSuccess,
      this.getPharmacyListFailure
    );
  }
  getPharmacyListSuccess = (success) => {
    if (success.status == "success") {

      this.setState({
        pharmacyList: success.data
      },() => {
        if(this.state.pharmacyList && (this.state.pharmacyList).length > 0) { 
         this.setState({
          selectedPharmacy: this.state.pharmacyList[0].pharmacy_id
         },() =>{
          this.getFavoriteDrugs()
          this.getFavTaperDrug()
         })
        }
      })
    }
  }

  getPharmacyListFailure = (error) => {
  }

  getFavTaperDrug = () => {
    let service_url = Constants.OPTHALMOLOGY_FREQUENT_MEDICATION + "?pharmacy_id=" + this.state.selectedPharmacy + "&is_tapper_meds=true";

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavTaperDruguccess,
      this.getFavTaperDrugFailure
    );
  }

  getFavTaperDruguccess = (response) => {
    if (response.status === "success") {

      let drugs = [];
      if(response.data.length > 10){
        var FirstTenDrug = (response.data).slice(0,10)
        FirstTenDrug.map((item) => {

          drugs.push({ value: item, label: item });
        });

      }else{
        response.data.map((item) => {

          drugs.push({ value: item, label: item });
        });
      }

      var field = this.state;
      field["favoriteTaperDrugs"] = this.removeDuplicate(drugs);
      this.setState({ field });

    }
  }

  getFavTaperDrugFailure = () => {

  }

  getFavoriteDrugs = () => {

    let service_url = Constants.OPTHALMOLOGY_FREQUENT_MEDICATION + "?pharmacy_id=" + this.state.selectedPharmacy;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getFavoriteDrugSuccess,
      this.getComplaintsFailure
    );
  }

  getFavoriteDrugSuccess = (response) => {

    if (response.status === "success") {

      let drugs = [];
      if(response.data.length > 10){
        var FirstTenDrug = (response.data).slice(0,10)
        FirstTenDrug.map((item) => {

          drugs.push({ value: item, label: item });
        });

      }else{
        response.data.map((item) => {

          drugs.push({ value: item, label: item });
        });
      }

      var field = this.state;
      field["favoriteDrugs"] = this.removeDuplicate(drugs);
      this.setState({ field });

    }
  }

  removeDuplicate(data){
    var nameListJsonObject =data.map(JSON.stringify); 
    var nameListUniqueSet = new Set(nameListJsonObject); 
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse); 
    return nameListUniqueArray;
  }

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue,disable) => {

    return (
      <SelectedButton
        //   {...otherProps}
        disable={this.state.isLocked}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  addDosageCount = (key, selectedValue) => {

    let { selectedDosageCount, selectedTabletCount, selectedSyrupCount } = this.state;

    if(selectedValue.value == "DROP" || selectedValue.value == "CAPSULE"){

    if(key == "add"){
      this.setState({ selectedDosageCount: parseInt(selectedDosageCount) + 1 });
    }else if(key == "sub"){
      if(selectedDosageCount <= 0){
      
      }else{
        this.setState({ selectedDosageCount: parseInt(selectedDosageCount) - 1 });
      }
    }

    } else if (selectedValue.value == "TABLET"){
      if(key == "add"){
        this.setState({ selectedTabletCount: parseFloat(selectedTabletCount) + 0.5 });
      }else if(key == "sub"){
        if(selectedTabletCount <= 0){
        
        }else{
          this.setState({ selectedTabletCount: parseFloat(selectedTabletCount) - 0.5 });
        }
      }
    } else if (selectedValue.value == "SYRUP"){
      if(key == "add"){
        this.setState({ selectedSyrupCount: parseFloat(selectedSyrupCount) + 2.5 });
      }else if(key == "sub"){
        if(selectedSyrupCount <= 0){
        
        }else{
          this.setState({ selectedSyrupCount: parseFloat(selectedSyrupCount) - 2.5 });
        }
      }
    }

    

    // console.log(" add - " + this.state.selectedDosageCount);
  }

  reduceDosageCount = () => {

    let { selectedDosageCount } = this.state;
    this.setState({ selectedDosageCount: selectedDosageCount - 1 });

    // console.log(" minus - " + this.state.selectedDosageCount);
  }

  renderDosageType = (item,index) => {
    // alert(JSON.stringify(selectedValue))
    let borderColor = fill || borderNeeded ? color.themePink : color.themePink;
    let backgroundColor = fill ? borderNeeded ? color.themeBlue : color.themePink : color.themePink;
    // console.log("*****************> "+ JSON.stringify(item))
    // console.log("*****************>  > "+ JSON.stringify(selectedValue))
    return (
      <View>
        {item.value == "DROPS" && selectedValue.value == 'DROPS' ?

          <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", width: '8.9vw' }}>
            <TouchableOpacity onPress={()=>{this.addDosageCount("sub")}} >
              {/* <Text style={[this.state.selectedDosageCount === 0 ? { color: color.white } : null], { flex: 1 }}>{"-"}</Text> */}
              <Text style={[styles.DosageTypeText, {backgroundColor: color.white, color: color.themeBlue}]}
              >{"-"}</Text>
            </TouchableOpacity>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: fill || borderNeeded ? 10 : 20,
                flexDirection: "row",
                borderColor: borderColor,
                borderWidth: 1,
                backgroundColor: selectedValue === item.value ? borderColor : backgroundColor,
                padding: fill ? '0.6vw' : '1vw',
                paddingTop: '0.5vw',
                paddingBottom: '0.5vw',
                marginBottom: '0.6vw',
                // marginRight: -10,
                flex: 2,
                width: '5.2vw',
                zIndex: 1
              }}
            >
              <Text
                style={{
                  fontSize: fill ? '1.2vw' : '0.8vw', //12
                  textAlign: "center",
                  color: color.white,
                  fontWeight: "400" //500
                }}
              >
                {this.state.selectedDosageCount} {item.label}
              </Text>
            </View>
            <TouchableOpacity onPress={()=>{this.addDosageCount("add")}}>
              <Text style={[styles.PlusBtnText, {backgroundColor: color.white}]}>{"+"}</Text>
            </TouchableOpacity>
          </View> : <SelectedButton
            //   {...otherProps}
            item={item}
            fill={fill}
            borderNeeded={borderNeeded}
            onPressItem={this.onPressAction.bind(this, selectedKey)}
            selected={selectedValue}
          />
        }
      </View>
    );
  }

  onPressAction = (key, value) => {

    let states = this.state;
    states[key] = value;

    if( key === "selectedDosage" && value !== "M-A-E-N" ) {

      states['morning'] = '';
      states['afternoon'] = '';
      states['evening'] = '';
      states['night'] = '';
    }

    this.setState({
      states
    })
  }

  addComplaint = event => {

    let errorMessage = "";
    let states = this.state;

    let brand_name = this.state.selectedDrug.value || this.state.newItem;

    //dosageType Corrected as backend 
    let dosage_type = this.state.selectedDosage?this.state.selectedDosage.value:"";

    if (this.state.selectedTab === "Medication"){
      dosage_type = dosage_type? (dosage_type).toLowerCase() : '';
      dosage_type = dosage_type? dosage_type.charAt(0).toUpperCase() +  dosage_type.slice(1) : ''
    }

    let frequency = Object.keys(this.state.selectedFrequency).length > 0 ?this.state.selectedFrequency.value:"";
    let intake_duration = this.state.selectedDuration +" "+ this.state.durationMode;
    let selectedEye = this.state.selectedEye ? this.state.selectedEye.value ? this.state.selectedEye.value : "" : ""

    let data = {
      "eye_type": selectedEye,
      "brand_name": brand_name,
      "generic_name": this.state.generic_name,
      "dosage_strength": this.state.dosage_strength, //.toString() || "",
      "drug_id" : this.state.DrugID,
      "dosage_type": dosage_type === 'Drops' ? 'Drop' : dosage_type,
      "frequency": frequency,
      "no_of_dosage":(this.state.selectedDosage && Object.keys(this.state.selectedDosage).length > 0 )? (((this.state.selectedDosage.value).toUpperCase() == "DROP" || (this.state.selectedDosage.value).toUpperCase() == "CAPSULE" ) ? this.state.selectedDosageCount : (this.state.selectedDosage.value).toUpperCase() == "TABLET" ? this.state.selectedTabletCount : (this.state.selectedDosage.value).toUpperCase() == "SYRUP" ? this.state.selectedSyrupCount  : 0) : 0,
      "duration": intake_duration,
      "instructions": "",
      "comments": this.state.comments,
      "quantity": this.state.quantity,
      "tapper_meds": this.state.taperAddedDetails,
      "pharmacy_id": this.state.selectedPharmacy,
      "isAddNewClick": this.state.isAddNewClick,
      "isNotFavoriteDrug": this.state.isNotFavoriteDrug,
      "is_favorite": this.state.is_MefiFav
    };

    if(frequency == "M-A-E-N"){
      data["morning"]= this.state.morning? this.state.morning : 0;
      data["afternoon"]= this.state.afternoon? this.state.afternoon : 0;
      data["evening"]= this.state.evening? this.state.evening : 0;
      data["night"]= this.state.night? this.state.night: 0;

      // data["morning"]=this.state.morning||0;
      // data["afternoon"]=this.state.afternoon||0;
      // data["evening"]=this.state.evening||0;
      // data["night"]=this.state.night||0;
    }else{
      data["morning"]=0;
      data["afternoon"]=0;
      data["evening"]=0;
      data["night"]=0;
    }

    if (this.state.id) {
      data['id'] = this.state.id;
    }

    if (this.state.tempId) {
      data['tempId'] = this.state.tempId;
    }
    else{
       data['tempId'] = "";
    }

    if(!brand_name) {    
      errorMessage = "Select drug name";
    } 
    else if(!this.state.generic_name){    
       errorMessage = "Enter generic name";
    } 
    else if(!this.state.dosage_strength){    
      // errorMessage = "Enter dosage strength";
    } 
    else if(!dosage_type){
      errorMessage ="Select dosage type";
    }
    else if(!frequency && ((this.state.taperAddedDetails).length === 0)){
      errorMessage = "Select drug frequency";
    }
    else if(!this.state.selectedDuration && this.state.durationMode !== "Until the Next Visit" &&((this.state.taperAddedDetails).length === 0)){
      errorMessage ="Select duration";
    }

    // throw function call or warning call
    if(errorMessage)
    {
      this.props.showResposeValue("error", errorMessage);
    }else{

        this.props.addLineItem(data);
        this.clearComplaint();
        this.props.clearEditedItem(pageName, {});
    }
  };

  showSuccessMessage = () => {

    this.setState({ successOrErrorMessage: true, successMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, successMessageFlag: false });
    }, 2000);
  };

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true, errorMessageFlag: true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false, errorMessageFlag: false });
    }, 2000);

  };

  getComplaintsSuccess = response => {
    
    if (response.status === "success") {
      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });
      this.showSuccessMessage();
      // clear data
      this.clearComplaint();

      this.props.addLineItem(data);
    }
  };

  addToSuggestionList = (event) => {

    // console.log(" newFrequency:" , this.state.newFrequency)
    // console.log(" selectedFrequency:" , this.state.selectedFrequency)

    let { newFrequency, medicationFrequency } = this.state;
    let field = this.state;

    if (newFrequency) {

      medicationFrequency.push({ value: newFrequency, label: newFrequency });

      field["medicationFrequency"] = this.removeDuplicate(medicationFrequency);
      field["selectedFrequency"] = newFrequency;
      field["newFrequency"] = "";
      field["isEdit"] = false;

      this.setState({ field });
    }
  }


  triggerNewFrequencyAdd = () => {
    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }



  triggerNewItemAdd = (key) => {

    let states = this.state;
    if(key === "cancel"){
      states['isNotFavoriteDrug'] = true;
      states["isAddNewClick"] = false;
      states["isNewdrug"] = false;
    }else if(key === "addNew"){
      states['isNotFavoriteDrug'] = false;
      states["isAddNewClick"] = true;
    }

    // clear previous selected data
    states['selectedDrug'] = '';
    states['isGenericSelected'] = false;
    states['drugSuggestionList'] = [];
    states['drugDetailsSuggestionList'] = [];
    states["generic_name"] = "";
    states["is_MefiFav"] = false;
    states["dosage_strength"] = "";
    states["selectedDosage"] = {};

    this.setState({ states });

    // this.drugInput.current.focus();
  }

  addMedicationToList = (value) => {

    let { newItem, favoriteDrugs } = this.state;
    let field = this.state;

    favoriteDrugs.push({ value: value, label: value });

    // if (newItem) {

    //   favoriteDrugs.push({ value: newItem, label: newItem });

      field["favoriteDrugs"] =   this.removeDuplicate(favoriteDrugs);
      field["selectedDrug"] = newItem;
      field["newItem"] = "";
      field["isNotFavoriteDrug"] = true;

    //   this.setState({ field });

    // } else {

      field["newItem"] = "";
      field["isNotFavoriteDrug"] = true;

      this.setState({ field, selectedDrug:value });
    // }
  }

  clearComplaint = event => {
    var states = this.state
    states['selectedEye'] = {};
    states ['selectedDrug'] = {};
    states['newItem'] = "";
    states ['generic_name'] = "";
    states['is_MefiFav'] = false;
    states['dosage_strength'] = "";
    states['selectedDosage'] = {};
    states ['selectedDosageCount'] = 1;
    states ['selectedFrequency'] = {};
    states ['morning'] = "";
    states ['afternoon'] = "";
    states ['evening'] = "";
    states ['night'] = "";
    states ['durationMode'] = "Days";
    states ['selectedDuration'] = "";
    states['comments'] = "";
    states['quantity'] = "";
    states['tempId']="";
    states["isAddNewClick"] = false;
    states["isDosageFieldEnable"] = false;
    states["isNewdrug"] = false;
    states["DrugID"] = "";

    states["drugDetailsSuggestionList"]= []
    states["drugSuggestionList"]= []
    states["taperAddedDetails"] = []
    states["taperFromDate"] = moment(newDate).format("YYYY-MM-DD")
    states['taperDuration'] = "",
    states['taperDurationMode'] = "Day(s)",
    states['taperFrequency'] = "",
    states['taperReducedBy'] = "",
    states['taperUnitVal'] = "",
    states['selectedEye'] = {},
    states['isAddNewClick'] = false
    // states["isNotFavoriteDrug"] = false
    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });
  }

  renderTextFieldViewDrug = (placeholder, stateName = '', value = '', isMultipleLineEnabeled = false, lineCount = 1, reference = '', updateObj = {}, key="" ) => {
    return (
      <View
        style={{
          minHeight: '2.3vw',
          // marginTop: 10,
          marginBottom: '1.2vw',
          backgroundColor: this.state.isLocked ? color.disableComponentColor : color.white,
          borderRadius: 10
        }}
      >
        <TextInput
          editable={!this.state.isLocked}
          underlineColorAndroid="transparent"
          placeholder={placeholder}
          style={[styles.DrugTextInput, {borderColor: color.rightPanelInputBorder}]}
          // ref={text => (this.nameInput = text)}
          ref={reference ? reference : null}
          // defaultValue=""
          autoCapitalize="none"
          value={value ? value : this.state.newItem}
          // onChangeText={text => {
            
          //   let states = this.state;
          //   states[stateName] = text;

          //   this.setState({ states });
          //   this._getDrugSuggestion(text);
          // }} 
          onChange={(event) => this._getDrugSuggestion(event,placeholder, stateName)}
          multiline={isMultipleLineEnabeled}
          numberOfLines={lineCount}
          onSubmitEditing={() => {
            
            if(key =="drugNameKey"){
              this.addMedicationToList(value)
            }

            // Drug name

            
            // let stateList = this.state[updateObj.stateList];

            // stateList.push({ value: value, label: value });

            // console.log(" - stateList - " + JSON.stringify(stateList));

            // this.updateState(updateObj.flag, false)
            // this.updateState(updateObj.newState, "")
            // this.updateState(updateObj.stateList, JSON.parse(JSON.stringify(stateList)))
          } }
        />
      </View>
    );
  };

  renderTextFieldViewGeneric = (placeholder, stateName='', value='', isMultipleLineEnabeled=false, lineCount=1, reference='', updateObj = {}) => {
    return (
      <View
        style={{
          minHeight: '2.3vw',
          // marginTop: 10,
          marginBottom: '1.2vw',
          backgroundColor:  this.state.isLocked ? color.disableComponentColor: color.white,
          borderRadius: 10
        }}
      >
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={placeholder}
          editable={this.state.isDosageFieldEnable ? true : false}
          style={[styles.DrugTextInput, {borderColor: color.rightPanelInputBorder}]}
          // ref={text => (this.nameInput = text)}
            ref={reference?reference:null}
            // defaultValue=""
            autoCapitalize="none"
            value={value}
            // onChangeText={text => this.setState({ comments: text })} 
            onChange={this._getGenericNameChange.bind(this)} 
            multiline={isMultipleLineEnabeled} 
            numberOfLines={lineCount} 
            onSubmitEditing={reference && updateObj?()=>{
              
              //addMedicationToList(value)
              let stateList = this.state[updateObj.stateList];

              stateList.push({ value: value, label: value });

              // console.log(" - stateList - " + JSON.stringify(stateList));

              this.updateState(updateObj.flag, false)
              this.updateState(updateObj.newState, "")
              this.updateState(updateObj.stateList, JSON.parse(JSON.stringify(stateList)))
            }:null}
        />
      </View>
    );
  };

  _getDrugSuggestion = (event, placeholder, stateName) => {

    let self = this;
    let { drugSuggestionList, isDrugSelected, newItem, selectedTab } = this.state;
    let drugName = event.target.value;

    if( isDrugSelected && newItem == drugName ) {
    
    } else {

        // set for view in drug name
        this.setState({ newItem: drugName, isDrugSelected: false, isGenericSelected: false, drugDetailsSuggestionList: [] })

        if (drugName !== "") {

          let service_url = ""

          if(placeholder === "Search Existing Drug.."){
            if(selectedTab === "TaperMed"){
              service_url = Constants.OPTHALMOLOGY_FREQUENT_MEDICATION + "?brand_name=" + drugName + "&pharmacy_id=" +this.state.selectedPharmacy + "&is_tapper_meds=true";
            this.setState({
              isDosageFieldEnable: false
            })
            }else{
            service_url = Constants.OPTHALMOLOGY_FREQUENT_MEDICATION + "?brand_name=" + drugName + "&pharmacy_id=" +this.state.selectedPharmacy;
            this.setState({
              isDosageFieldEnable: false
            })
            }
          }else if(placeholder === "Type New Drug Name"){
            service_url = Constants.OPTHALMOLOGY_OVERALL_MEDICATION + "?brand_name=" + drugName;
            this.setState({
              isDosageFieldEnable: true
            })
          }
            

            OpthamologyService.getInstance().getComplaints(
              service_url,
              this,
              this._getDrugSuccess,
              this._getDrugFailure,
              stateName
            );

        } else {

          this.setState({

            isDrugSelected: false,
            drugSuggestionList: [],
            newItem: "",
            isNewdrug : false
          })

        }

      }
  }

  _getDrugSuccess = (response, stateName) => {

    if (response.status === "success") {
      if(stateName === "newItem"){
        this.setState({ 
          drugSuggestionList: JSON.parse(JSON.stringify(response.data)),
          isNewdrug: response.data.length == 0 ? true : false
        });
      }
      
    }
  };

  _getDrugFailure = error => {

    console.log(error);

  };

  _getDrugDetails = (drugName, isExistDrug) => {

    if(drugName !== ""){

      this.setState({ isDrugSelected: true })

      let service_url = ""

      if(isExistDrug){
        service_url = Constants.OPTHAMOLOGY_MEDICATION_EXISTING_DRUG_DETAILS + "?brand_name=" + drugName + "&pharmacy_id=" + this.state.selectedPharmacy;
      }else {
        service_url = Constants.OPTHAMOLOGY_MEDICATION_DRUG_DETAILS + "?brand_name=" + drugName;
      }
      

      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this._getDrugDetailsSuccess,
        this._getDrugFailure
      );
    }else{

      this.setState({ 
        // newItem: drugName, 
        drugDetailsSuggestionList: [],
        //       isICDView :true
      });
    }
  }

  _getDrugDetailsSuccess = response => {

    if (response.status === "success") {

      var data = this.state;
      
      let list = JSON.parse(JSON.stringify(response.data));
      data['drugDetailsSuggestionList'] = list;

      if( list.length === 1 ){
        var strength = list[0]['dosage_strength'] ? list[0]['dosage_strength'] : '' ;
        var numericAlone = strength.match(/(\d+)/);

        data['generic_name'] = list[0]['generic_name'];
        data['dosage_strength'] = list[0]['dosage_strength'];
        data['selectedDosage'] = {label : list[0]['dosage_type'], value: list[0]['dosage_type'] };
        data['DrugID'] = list[0]['drug_id'];
        data['isNewdrug'] = false
        data["taperUnitVal"] = (list[0]['dosage_type'] ? (list[0]['dosage_type']).toUpperCase() === "STEROID TABLETS" ? numericAlone[0] : (list[0]['dosage_type']) === "Drop" ? "1" : "" : "")
        data["is_MefiFav"] = list[0]['is_favorite']

      }

      this.setState({ data });
    }
  };

  _getGenericNameChange = (event) => {

    let name = event.target.value;

    this.setState({ generic_name: name, isGenericSelected: false })
  }

  _getDrugDetailsbyGeneric = (item, event) => {

    let generic_name = item.generic_name || "";
    let dosage_strength = item.dosage_strength || "";
    let drug_id = item.drug_id
    // let selectedDosage = item.dosage_type || "";
    let selectedDosage = {label : item.dosage_type,value: item.dosage_type}
    let is_Fav = item.is_favorite

    var strength = dosage_strength;
    var numericAlone = strength.match(/(\d+)/);

    this.setState({ 
      isGenericSelected: true, 
      generic_name: generic_name, 
      dosage_strength: dosage_strength, 
      selectedDosage: selectedDosage,
      DrugID : drug_id,
      is_MefiFav: is_Fav,
      taperUnitVal: selectedDosage.value ? (selectedDosage.value).toUpperCase() === "STEROID TABLETS" ? numericAlone[0] : (selectedDosage.value) === "Drop" ? "1" : ""  : "",
    });
  }

  updateState = (key, value, keyInObject=null) => {

    let states = this.state;

    if(keyInObject) {
    
      states[keyInObject][key] = value;
    }else{

      states[key] = value;
    }

    if( key === "selectedDrug") {

      states['isNotFavoriteDrug'] = true;
      states['isGenericSelected'] = false;

      this.setState({ states });

      // get drug details from favorite selection
      this._getDrugDetails(value.value);

    } else {

      this.setState({ states });
    }

  }

  onPressButton(key, selectedvalue,type="") {

    let states = this.state;
    states[key] = selectedvalue;

    if( key === "selectedDrug") {

      states['isNotFavoriteDrug'] = true;
      states["isAddNewClick"] = false;
      states['isGenericSelected'] = false;
      states['isDosageFieldEnable'] = false
      states['taperFromDate'] = moment(newDate).format("YYYY-MM-DD");
      states["taperDuration"] = "";
      states['taperReducedBy'] = "";
      states['taperUnit'] = "";
      states['taperFrequency'] = "";
      states['taperAddedDetails'] = [];
      states['selectedEditIndex'] = null;

    }else if(key == "selectedDosage"){

      if(type == "sub" || type == "add"){
        this.addDosageCount(type, selectedvalue)
        states["selectedDosage"]=selectedvalue
      }else {
        states["selectedDosage"]=selectedvalue
      }
 
    }else if(key == "selectedEye"){
      states["selectedEye"]=selectedvalue
    }



    // if (key == "selectedEye" ) {
        this.setState({
          states
        }, ()=> {
          // after state update
          if( key === "selectedDrug") {
            this._getDrugDetails(selectedvalue.value, this.state.isNotFavoriteDrug);    // get drug details from favorite selection
          }
        })
    // }
  }

numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
}

  onChangeQuantity = (text) => {

    // var isNum = this.numberValidation(text.target.value)

    var Qty = text.target.value

            if (Qty.length < 51) {
              this.setState({
                quantity: text.target.value
              })
            }
  }

  changeTab = (tab) => {
    if(this.state.tempId != ""){

    }else{
    this.setState({
      selectedTab: tab,
      isAddNewClick: false,
      isNotFavoriteDrug: true,
      drugSuggestionList: [],
      newItem: "",
      drugDetailsSuggestionList: [],
      generic_name: "",
      is_MefiFav: false,
      dosage_strength: "",
      selectedDosage: {},
      isDrugSelected: false,
      selectedDrug: {},
      quantity: "",
      comments: "",
      taperAddedDetails: [],
      taperFromDate: moment(newDate).format("YYYY-MM-DD"),
      taperUnitVal: "",
      taperDuration: "",
      taperDurationMode: "Day(s)",
      taperFrequency: "",
      taperStartTimeMode: "AM",
      showTimerPopup: false,
      hour12Formet: '00',
      minutes: '00',
      taperInterval: "",
      selectedEditIndex: null,
    })
    }
  }

  timePicker() {
    var getHours = []
    var getMinutes = ['00','15','30','45'];
    for (var i = 1; i <= 24; i++) {
      var hour = i < 10 ? '0' + i : i;
      getHours.push(hour)
    }
    let len = Object.keys(this.state.selectedDosage).length
    return (
        <View style={{  marginTop: '0.5vw', width: "80%" }}>
          <View style={[styles.TimePickerView, {borderColor: color.lightGray}]}>
            <TouchableOpacity 
            onPress={() => { this.setState({ showTimerPopup: !this.state.showTimerPopup }) }} 
            style={{ justifyContent: 'center' }}
            disabled = {this.state.isLocked || len === 0} 
            >
              <Text style={{ color: color.themeBlue, fontSize: '0.9vw' }}>{this.state.hour12Formet}{"  :  "}{this.state.minutes}</Text>
            </TouchableOpacity>
            <TouchableOpacity  
            disabled = {this.state.isLocked || len === 0}
            onPress={() => { this.setState({ showTimerPopup: !this.state.showTimerPopup }) }} 
            style={{ justifyContent: 'center', marginLeft: '0.2vw' }}>
              <Icon
                size={'1.2vw'}
                name={"angle-down"}
                style={{ color: color.themeBlue }}
              />
            </TouchableOpacity>
          </View>
          {
            this.state.showTimerPopup ?
              <TouchableWithoutFeedback onPress={() => { this.setState({ showTimerPopup: true }) }}>
                <View style={styles.TimePickerView1}>
                  <ScrollView style={{ height: '7.7vw' }} showsVerticalScrollIndicator={false}>
                    <View style={{ flexDirection: 'column', width: '2vw', }}>
                      {
                        getHours.map((hour) => {
                          return <TouchableOpacity onPress={() => { this.changeTime(hour, 'hr') }} style={{ padding: '0.2vw' }}><Text style={{ color: hour === this.state.hour12Formet ? color.themeDark : color.white, fontSize: '0.9vw' }}>{hour}</Text></TouchableOpacity>
                        })
                      }
                    </View>
                  </ScrollView>
                  <ScrollView style={{ height: '7vw' }} showsVerticalScrollIndicator={false}>
                    <View style={{ flexDirection: 'column' }}>
                      {
                        getMinutes.map((min) => {
                          return <TouchableOpacity onPress={() => { this.changeTime(min, 'mm') }} style={{ padding: '0.2vw' }}><Text style={{ color: min === this.state.minutes ? color.themeDark : color.white, fontSize: '0.9vw' }}>{min}</Text></TouchableOpacity>
                        })
                      }
                    </View>
                  </ScrollView>
                </View>
              </TouchableWithoutFeedback>
              :
              null
          }
        </View>


    )
  }
  changeTime(time, type) {
    if (type === 'hr') {
      this.setState({ hour12Formet: time });
    } else if (type === 'mm') {
      this.setState({ minutes: time });
    }
  }

  taperDetailAdd = () => {
    var data = {}
    var states = this.state
    // var timing = []
    // var time = states.hour12Formet
    var validDate = false
    var timeValid = true
    var taperAddedDetail = states.taperAddedDetails
    var frequency = (this.state.selectedDosage.value).toUpperCase() === "STEROID TABLETS" ? "" : states.taperFrequency
    var fromDate = states.taperFromDate
    var reducedBy = (this.state.selectedDosage.value).toUpperCase() === "STEROID TABLETS" ? states.taperReducedBy : ""
    var d = new Date(states.taperFromDate)
    if (d.toString() != "Invalid Date") {
      validDate = true
    }

    // for (let i=0; i < parseInt(states.taperFrequency); i++){
    //   if(i === 0){
    //     timing.push(parseInt(time) + ":" + states.minutes )
    //   }else{
    //     if((parseInt(time) + parseInt(states.taperInterval)) > 23){
    //       timeValid = false
    //     }
    //     timing.push(((parseInt(time) + parseInt(states.taperInterval))) + ":" + states.minutes )
    //     time = (parseInt(time) + parseInt(states.taperInterval))
    //   }
    // }

    if(validDate && ((states.taperUnitVal).trim() != "") &&
     ((states.taperDuration).trim() != "") && 
     (((this.state.selectedDosage.value).toUpperCase() != "STEROID TABLETS" && (states.taperFrequency).trim() != "") || ((this.state.selectedDosage.value).toUpperCase() === "STEROID TABLETS" && (states.taperReducedBy).trim() != "") ) && timeValid
    ){

    // for(let i = 0; i < parseInt(states.taperFrequency); i++ ){


      var duration = states.taperDuration

      if(states.taperDurationMode === "Week(s)"){
        duration = parseInt(states.taperDuration) * 7
      }else if(states.taperDurationMode === "Month(s)"){
        duration = parseInt(states.taperDuration) * 30
      }

      var date = new Date(fromDate);
      date.setDate(date.getDate() + parseInt(duration));

      var dosageCountLabel = (this.state.selectedDosage.value).toUpperCase() === "STEROID TABLETS" ? 'Mg' : 'Drop(s)'

      var frequencyLabel = dosageCountLabel === "Drop(s)" ? (frequency == 1) ? ' time a day' : ' times a day' : 'morning - after food'

      data = {
        start_date: fromDate,
        no_of_dosage: states.taperUnitVal + " " + dosageCountLabel,
        duration: states.taperDuration + " " + states.taperDurationMode,
        // start_time: states.hour12Formet + ":" + states.minutes,
        // Intervals: states.taperInterval,
        end_date: moment(date).format("YYYY-MM-DD"),
        frequency: frequency + frequencyLabel,
        reducedBy: reducedBy
        // timing: timing,
      }

      if((states.selectedEditIndex || states.selectedEditIndex == 0) ){
        taperAddedDetail[states.selectedEditIndex]["start_date"] = states.taperFromDate
        taperAddedDetail[states.selectedEditIndex]["no_of_dosage"] = states.taperUnitVal + " " + dosageCountLabel
        taperAddedDetail[states.selectedEditIndex]["duration"] = states.taperDuration + " " + states.taperDurationMode
        // taperAddedDetail[states.selectedEditIndex]["start_time"] = states.hour12Formet + ":" + states.minutes
        // taperAddedDetail[states.selectedEditIndex]["Intervals"] = states.taperInterval
        taperAddedDetail[states.selectedEditIndex]["end_date"] = moment(date).format("YYYY-MM-DD")
        taperAddedDetail[states.selectedEditIndex]["frequency"] = states.taperFrequency + frequencyLabel
        // taperAddedDetail[states.selectedEditIndex]["timing"] = timing

      }else if(!states.selectedEditIndex && states.selectedEditIndex != 0){
        taperAddedDetail.push(data)
      }

      if(frequency == 1){
        frequency = frequency
      }else if(frequency == 12){
        frequency = 10
      }else if(frequency == 10){
        frequency = 6
      }else{
        frequency = parseInt(frequency) - 1
      }

      var unitVal = ""
      var calc = parseInt(states.taperUnitVal) - parseInt(states.taperReducedBy)
      if(dosageCountLabel === "Mg" && calc > 0){
        unitVal = calc
      }else{
        unitVal = states.taperUnitVal
      }

      var nextStartDate = new Date(date)
      nextStartDate.setDate(nextStartDate.getDate() + 1)
      fromDate = moment(nextStartDate).format("YYYY-MM-DD")


      this.setState({
        taperAddedDetails: taperAddedDetail,
        taperFromDate: moment(fromDate).format("YYYY-MM-DD"),
        taperUnitVal: unitVal + "",
        // taperDuration: "",
        // taperDurationMode: "Day(s)",
        hour12Formet: "00",
        minutes: "00",
        taperInterval: "",
        taperFrequency: frequency + "",
        selectedEditIndex: null,
      })   
  }else{
    if(!timeValid){
      this.props.showResposeValue("error", "Invalid Time Interval");
    }else if(!validDate){
      this.props.showResposeValue("error", "Invalid Date");
    }else{
      this.props.showResposeValue("error", "Invalid Details can't be added");
    }
    
  }
    
  }

  onEditTaperMed = (item, index) => {
    var taperUnit = item.no_of_dosage
    var unit = taperUnit.split(" ")
    var duration = (item.duration).split(" ")
    // var startTime = (item.start_time).split(":")
    var frequency = (this.state.selectedDosage.value).toUpperCase() != "STEROID TABLETS" ? (item.frequency).split(" ") : [""] 
    this.setState({
      taperFromDate: item.start_date,
      taperUnitVal: unit[0],
      taperDuration: duration[0],
      taperDurationMode: duration[1],
      taperReducedBy: item.reducedBy,
      // hour12Formet: startTime[0],
      // minutes: startTime[1],
      // taperInterval: item.Intervals,
      taperFrequency: frequency[0],
      selectedEditIndex: index,
    })
  }
  
  onDeleteTaperMed = (id) => {
  var { taperAddedDetails } = this.state
  taperAddedDetails.splice(id,1);
  this.setState({
    taperAddedDetails,
    selectedEditIndex : null
  })
  }


  render() {
    var durationButton = []
    durationButton = this.state.durationMode ==='Days'? Constants.DURATION_DAY : this.state.durationMode ==='Weeks'? Constants.DURATION_WEEKS : this.state.durationMode === "Months" ? Constants.DURATION_MONTHS :[];
    const { isDrugSelected, drugSuggestionList, isGenericSelected, drugDetailsSuggestionList, selectedTab, selectedDosage } = this.state;

    let len = Object.keys(this.state.selectedDosage).length
    var taperUnit = ""
    if(len > 0){
      if((this.state.selectedDosage.value).toUpperCase() === "STEROID TABLETS"){
        taperUnit = "Mg"
      }else{
        taperUnit = "Drop(s)"
      }
    }

    // let drugListOptions = drugList && drugList.length > 0 && drugList.map((item, i) => {
    //   return (
    //     //alert(item)
    //     <option key={i} value={item}>{item}</option>
    //   )
    // }, this);
    // console.log("isEdit:",this.state.isEdit)
    return (
      <TouchableWithoutFeedback onPress={() => {
        this.setState({
          showTimerPopup: false
        })
      }}>
      <View>
        <View style={styles.MedicationView}>
        <TouchableOpacity 
        style={{flex: 0.35}}
        onPress={() => {
          this.changeTab("Medication")
        }}
        >
          <Text style={{color: selectedTab === "Medication" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }}>Medication</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        style={{flex: 0.35}}
        onPress={() => {
          this.changeTab("TaperMed")
        }}
        >
          <Text style={{color: selectedTab === "TaperMed" ? color.themeDark : color.grayShade, fontSize: '1.2vw' }} >Taper Med</Text>
        </TouchableOpacity>
        <View style={{ justifyContent: "center", alignItems: "center", flex: 0.3 }}>
          <Picker
            enabled={!this.state.isLocked}
            selectedValue={this.state.selectedPharmacy}
            style={styles.MedicationDropDown}
            itemStyle={{}}
            onValueChange={(itemvalue) => {
              this.setState({ selectedPharmacy: itemvalue },()=>{
                this.getFavoriteDrugs()
                this.getFavTaperDrug()
              });
            }}
          >
            {this.state.pharmacyList.map((i, index) => (
              <Picker.Item key={index} label={i.pharmacy_name} value={i.pharmacy_id} />
            ))}
          </Picker>
        </View>
        </View>
      {this.state.selectedTab === "Medication" ? 
      <View>
        {/* {this.renderSubHeadingBorder("Medication", false, true)} */}
        {/* <DoctorNotesCommonRightSideHeader title={"Medication"} clearAllData={this.clearComplaint.bind(this)}/> */}

        {/* {this.renderTitleWithMultipleBtn("Drug Name", this.state.favoriteDrugs, 3, false, 'selectedDrug', this.state.selectedDrug, this.updateState.bind(this), false, true)} */}
        <View style={{
          flexDirection: "row",
          marginBottom: '1.2vw',
          justifyContent: "space-between"
        }}>
          <View style={{ width: "80%", height: '2.3vw' }}>
          <CommonSectionHeader heading={"Drug Name"}  />
          </View>
          <View style={{ width: "20%",height: '2.3vw', backgroundColor: color.sectionHeadingDarkColor  }}>
          {!this.state.isAddNewClick ?
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.triggerNewItemAdd("addNew") }} style={{height: '2.3vw'}}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
           : null }
           {this.state.isAddNewClick ?
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.triggerNewItemAdd("cancel") }} style={{height: '2.3vw'}}>
            {this.renderIconBtn("", "Cancel", false, "key")}
          </TouchableOpacity>
           : null }
          </View>
        {/* {this.renderSubHeadingBorder("", false, true)} */}
        </View>
        
        <View style={{ marginBottom: '0.6vw', zIndex: 100 }}> 
        {this.state.isNotFavoriteDrug ?
            <View> 
            {this.renderTextFieldViewDrug('Search Existing Drug..', 'newItem', this.state.newItem, false, 1, this.drugInput,
              { flag: 'isNotFavoriteDrug', newState: 'newItem', stateList: 'favoriteDrugs' })}
            </View>
            : null
          } 
            
          {this.state.isAddNewClick ?
            <View> 
            {this.renderTextFieldViewDrug('Type New Drug Name', 'newItem', this.state.newItem, false, 1, this.drugInput,
              { flag: 'isAddNewClick', newState: 'newItem', stateList: 'favoriteDrugs' })}
            </View>: null
          }
          {drugSuggestionList.length > 0 && (this.state.isNotFavoriteDrug || this.state.isAddNewClick) && !this.state.isDrugSelected?
            <View style={[styles.NewDrugNameView, { // maxHeight: this.state.newItem ? "" : 0,
             backgroundColor: this.state.newItem ? color.themeBlue : ""
            }]}>
              <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>
                <FlatList
                  data={drugSuggestionList}
                  // ItemSeparatorComponent={this.ListViewItemSeparator}
                  //Item Separator View
                  renderItem={({ item }) => (
                    <View style={styles.ItemView}
                    >
                      <View style={{ width: '100%' }}>
                        <TouchableOpacity
                          onPress={()=> {

                            let drugName = item;
                            let sameInList = false;
                            let favoriteDrugs = JSON.parse(JSON.stringify(this.state.favoriteDrugs));

                            for (var i = 0; i < favoriteDrugs.length; i++) {

                              let value = favoriteDrugs[i].value;
                              // console.log(drugName + " --- " + value);
                              if (drugName == value) {
                                sameInList = true;
                              }
                            }

                            if(!sameInList){ 
                              favoriteDrugs.pop()
                              favoriteDrugs.push({label: drugName, value: drugName});
                            }

                            // this.setState({ newItem: item, isDrugSelected: false });
                            this._getDrugDetails(item, this.state.isNotFavoriteDrug)
                            var data = {"label":drugName,"value":drugName}
                            this.state.selectedDrug
                            this.setState({ 
                              isNotFavoriteDrug: true,
                              isAddNewClick: false,
                              isDosageFieldEnable: false,
                              newItem: "",
                              selectedDrug : data,
                              favoriteDrugs: JSON.parse(JSON.stringify(favoriteDrugs))
                            })
                          }} 

                          disabled={this.state.isLocked}
                        >
                          <Text style={{ color: this.state.newItem ? color.white : "", fontSize: '0.9vw' }}>{item}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                  enableEmptySections={true}
                />
              </ScrollView>
            </View>
            : null}

        </View>
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.favoriteDrugs.map((item, index) => {
            // var data
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedDrug}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedDrug"} />
              </View>
            )
          })
        }
        </View>

        <View style={{ flexDirection: "row", marginBottom: '2.6vw', flex: 1, zIndex:1 }}>
          <View
            style={{
              flex: 0.7,
              flexDirection: "column",
            }}
          >
            <Text style={{ fontSize: '0.8vw', color: color.themeBlue,marginBottom:'0.6vw'}}>Generic Name</Text>
            {/* {drugDetailsSuggestionList.length > 0?
              <View> */}
                
                {this.renderTextFieldViewGeneric('Generic Name', 'generic_name', this.state.generic_name, false) }

                
              {/* </View>
              :this.renderText('', this.state.generic_name || '-', true)
            } */}

          </View>
          <View
            style={{
              flex: 0.3
            }}
          >
            <Text style={{ fontSize: '0.8vw', color: color.themeBlue,marginBottom:'0.6vw' }}>Dosage Strength</Text>
            {this.state.generic_name ?
              this.renderTextFieldView('Strength', 'dosage_strength', this.state.dosage_strength, false, 1, "", {}, "", false, this.state.isDosageFieldEnable ? false : true) :
              this.renderText('', this.state.dosage_strength || '-', true) 
              // : this.renderTextFieldView('Strength', 'dosage_strength', this.state.dosage_strength, false)
            }

          </View>
        </View>

        {drugDetailsSuggestionList.length > 1 && !isGenericSelected?
          <View style={[styles.StrengthView, {backgroundColor: color.themeBlue}]}>

          {drugDetailsSuggestionList.length > 0 && drugDetailsSuggestionList.map((item, index) => { item.generic_name }) }

            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>

            {drugDetailsSuggestionList.length > 0 && drugDetailsSuggestionList.map((item, index) => {
                return <TouchableOpacity key={index} style={{ paddingLeft: '0.5vw', paddingTop: '0.5vw', }} 
                onPress={this._getDrugDetailsbyGeneric.bind(this,item)} >
                <Text style={{ color: color.white, fontSize: '0.8vw' }}>{item.generic_name} - {item.dosage_strength} - {item.dosage_type}</Text>
              </TouchableOpacity>
              })}                     
            </ScrollView>
          </View>
        :null}

        {/* {this.renderTitleWithMultipleBtn("Dosage", Constants.Medication_dosages, 3, false, 
          'selectedDosage', this.state.selectedDosage, this.updateState.bind(this), true, true)} */}

        <View style={{ zIndex: 1, marginBottom: '1.2vw' }}>
          <CommonSectionHeader heading={"Dosage Type"}  />
          {this.renderSubHeadingBorder("", false, true)}

          <View style={{flexDirection:'row',flexWrap:'wrap'}}>
          {
            Constants.Medication_dosages.length > 0 && Constants.Medication_dosages.map((item,index)=>{
              var selectedBtn = Object.keys(this.state.selectedDosage).length > 0 ?  {value : ((this.state.selectedDosage).value).toUpperCase(), label: (this.state.selectedDosage).label } : {}
              // this.renderDosageType(item,index,'selectedDosage', this.state.selectedDosage)
              return (
                <View style={styles.CommonBtnView} key={index}>
                  <CommonButton
                    disable={this.state.isLocked || (selectedBtn.label != item.label && !this.state.isDosageFieldEnable)}
                    item={item}
                    selectedvalue={Object.keys(this.state.selectedDosage).length > 0 ? {value : ((this.state.selectedDosage).value).toUpperCase(), label: (this.state.selectedDosage).label } : {}}
                    butttonText={item.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedDosage"} 
                    selectedDosageCount={ (selectedBtn.value == "DROP" || selectedBtn.value == "CAPSULE") ? this.state.selectedDosageCount : selectedBtn.value == "TABLET" ?  this.state.selectedTabletCount : selectedBtn.value == "SYRUP" ? this.state.selectedSyrupCount : null }
                    />
                </View>
              )
            })
          }
          </View>

        </View>
          
        {/* {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true)} */}

        { this.renderSubHeadingBorder("Eye Type", false, true) }
        {/* {this.state.selectedDosage.value == "Drop" || this.state.selectedDosage.value == "DROP" || this.state.selectedDosage.value == "Ointment"? */}
        <View style={{flexDirection:'row',flexWrap:'wrap', marginBottom: '1.2vw'}}>
        {
          Constants.eye_options.map((item, index) => {
            // var data
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
        }
        </View>
        {/* :null} */}

        {/* {this.renderTitleWithMultipleBtn("Frequency", Constants.Medication_frequency, 3, false, 'selectedFrequency', this.state.selectedFrequency, this.updateState.bind(this), false, true)} */}
        <CommonSectionHeader heading={"Frequency"}  />
        {this.renderSubHeadingBorder("", false, true)}
        <View style={{flexDirection:'row',flexWrap:'wrap', marginBottom: '1.2vw'}}>
        {
          
          // Constants.Medication_frequency.map((item, index) => {
            this.state.medicationFrequency.map((item, index) => {
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedFrequency}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedFrequency"} />
              </View>
            )
          })
        }
        </View>
        {this.state.isEdit ?
            <View
              style={{
                height: '2vw',
                marginTop: '0.6vw',
                // backgroundColor: color.white,

                width: '10.6vw'
              }}
            ><TextInput
                maxLength={45}
                underlineColorAndroid="transparent"
                placeholder="type here"
                placeholderTextColor={color.black}
                style={[styles.TransparentTextInput, {borderColor: color.black,
                  color: color.black,}, Style.allButtonBorderRadius]}
                ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.newFrequency}
                onChangeText={text => this.setState({ selectedFrequency: '', newFrequency: text })}
                onSubmitEditing={this.addToSuggestionList.bind(this)}
              /></View> :
        <TouchableOpacity style={{ alignSelf: 'flex-start' }} onPress={() => { this.triggerNewFrequencyAdd() }} disabled={this.state.isLocked}>
              {this.renderIconBtn("plus", "New", false, "key")}
            </TouchableOpacity>
  }
        
        {this.state.selectedFrequency.value === 'M-A-E-N'?(

          <View style={{ flexDirection: "row", justifyContent: "space-evenly", alignContent: "center", 
            marginTop: '1.2vw', zIndex: 5 }}>
            <View style={{ flexDirection: "column", flex: 0.25 }}>
              {this.renderText("","M")}
              {this.renderTextFieldView("M", "morning", this.state.morning,"","","","",true)}
            </View>
            <View style={{ flexDirection: "column", flex: 0.25 }}>
              {this.renderText("","A")}
              {this.renderTextFieldView("A", "afternoon", this.state.afternoon)}
            </View>
            <View style={{ flexDirection: "column", flex: 0.25 }}>
              {this.renderText("","E")}
              {this.renderTextFieldView("E", "evening", this.state.evening)}
            </View>
            <View style={{ flexDirection: "column", flex: 0.25 }}>
              {this.renderText("","N")}
              {this.renderTextFieldView("N", "night", this.state.night)}
            </View>
          </View>
        ):null}

        <View style={{
          flexDirection: "row",
          marginBottom: '1.2vw',
          justifyContent: "space-between"
        }}>
          <View style={{ width: "70%", marginTop:'0.6vw' }}>
          <CommonSectionHeader heading={"Duration"}  />
          </View>
          <View style={{ width: "30%", marginTop: '0.6vw' }}>
            <Picker
              enabled={!this.state.isLocked}
              selectedValue={this.state.durationMode}
              style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius,{backgroundColor:"#E2E2E2"}]}  
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["durationMode"] = itemvalue;
                states["selectedDuration"] = "";
                this.setState({ states });
              }}
            >
              <Picker.Item label="Days" value="Days" />
              <Picker.Item label="Weeks" value="Weeks" />
              <Picker.Item label="Months" value="Months" />
              <Picker.Item label="Until the next visit" value="Until the Next Visit" />
            </Picker>
          </View>
        </View>

        <View style={{ flexDirection: "column" }}>

          <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', marginBottom: '1.2vw' }}>
          {
             durationButton && durationButton.map((item) => {
              return <TouchableOpacity>
              {this.renderSelectEyes({ value: item.value, label: item.label }, false, false, 'selectedDuration', this.state.selectedDuration,this.state.isLocked)}
            </TouchableOpacity>
            })
          }
          </View>
        </View>
        {/* Duration */}
        <View style={{
          flexDirection: "row",
          marginBottom: '1.2vw',
          justifyContent: "space-between"
        }}>
        <View style={{marginBottom:'1.2vw', width: "70%"}}>
         <CommonSectionHeader heading={"Quantity"}  />
        </View>
        <View style={{marginBottom:'1.2vw', width: "30%"}}>
         <TextInput 
          style={{backgroundColor: "#fff", border: "1px solid black", height: "100%", borderRadius: 4, paddingLeft: '0.6vw', fontSize: '0.9vw' }}
          value = {this.state.quantity}
          // onChange = {event => this.setState({quantity: event.target.value})}
          onChange = {(text)=>this.onChangeQuantity(text)}
         />
        </View>
        </View>

        {/* {this.renderTextFieldView("Comments")} */}
        <View style={{marginBottom:'1.2vw'}}>
         <CommonSectionHeader heading={"Comments"}  />
        </View> 
        {this.renderTextFieldView("Only 250 characters are allowed", "comments", this.state.comments, true, 4,'',{},'',false,this.state.isLocked,250)}
        {this.state.isNewdrug ?<View style={{height:"3vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <Text style={{color: "red", fontSize:".9vw", fontWeight: "500", marginTop:".5vw"}}>Note : This Drug is not available in our pharmacy</Text>
            </View> : null }
          {
            !this.state.prescriptionIsTransmitted ?
          
        <View style={{ marginTop: '.4vw',alignSelf:"center"  }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
          : null
          }
        {/* <View>
          {this.state.successOrErrorMessage ? (
            <View style={{ height: 40, width: '100%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{
                color: "white", textAlign: "center", justifyContent: "center"
              }}>
                {
                  this.state.successMessageFlag ? 'Medication Created Successfully' :
                    this.state.errorMessageFlag ? 'Medication Failed' : null}</Text>
            </View>
          ) : null}
        </View > */}
      </View> 
      : this.state.selectedTab === "TaperMed" ?
      <View>
        <View style={{
          flexDirection: "row",
          marginBottom: '1.2vw',
          justifyContent: "space-between"
        }}>
          <View style={{ width: "80%", height: '2.3vw' }}>
          <CommonSectionHeader heading={"Drug Name"}  />
          </View>
          <View style={{ width: "20%",height: '2.3vw', backgroundColor: color.sectionHeadingDarkColor  }}>
          {/* {!this.state.isAddNewClick ?
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.triggerNewItemAdd("addNew") }} style={{height: '2.3vw'}}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
           : null } */}
           {this.state.isAddNewClick ?
          <TouchableOpacity disabled={this.state.isLocked} onPress={() => { this.triggerNewItemAdd("cancel") }} style={{height: '2.3vw'}}>
            {this.renderIconBtn("", "Cancel", false, "key")}
          </TouchableOpacity>
           : null }
          </View>
        </View>
        
        <View style={{ marginBottom: '0.6vw', zIndex: 100 }}> 
        {this.state.isNotFavoriteDrug ?
            <View style={{ }}> 
            {this.renderTextFieldViewDrug('Search Existing Drug..', 'newItem', this.state.newItem, false, 1, this.drugInput,
              { flag: 'isNotFavoriteDrug', newState: 'newItem', stateList: 'favoriteTaperDrugs' })}
            </View>
            : null
          } 
            
          {this.state.isAddNewClick ?
            <View> 
            {this.renderTextFieldViewDrug('Type New Drug Name', 'newItem', this.state.newItem, false, 1, this.drugInput,
              { flag: 'isAddNewClick', newState: 'newItem', stateList: 'favoriteTaperDrugs' })}
            </View>: null
          }
          {drugSuggestionList.length > 0 && (this.state.isNotFavoriteDrug || this.state.isAddNewClick) && !this.state.isDrugSelected?
            <View style={[styles.NewDrugNameView, { backgroundColor: this.state.newItem ? color.themeBlue : ""
            }]}>
              <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>
                <FlatList
                  data={drugSuggestionList}
                  //Item Separator View
                  renderItem={({ item }) => (
                    <View style={styles.ItemView}
                    >
                      <View style={{ width: '100%' }}>
                        <TouchableOpacity
                          onPress={()=> {

                            let drugName = item;
                            let sameInList = false;
                            let favoriteDrugs = JSON.parse(JSON.stringify(this.state.favoriteTaperDrugs));

                            for (var i = 0; i < favoriteDrugs.length; i++) {

                              let value = favoriteDrugs[i].value;
                              // console.log(drugName + " --- " + value);
                              if (drugName == value) {
                                sameInList = true;
                              }
                            }

                            if(!sameInList){ 
                              favoriteDrugs.pop()
                              favoriteDrugs.push({label: drugName, value: drugName});
                            }

                            this._getDrugDetails(item, this.state.isNotFavoriteDrug)
                            var data = {"label":drugName,"value":drugName}
                            this.setState({ 
                              isNotFavoriteDrug: true,
                              isAddNewClick: false,
                              isDosageFieldEnable: false,
                              newItem: "",
                              selectedDrug : data,
                              favoriteDrugs: JSON.parse(JSON.stringify(favoriteDrugs)),
                              favoriteTaperDrugs: JSON.parse(JSON.stringify(favoriteDrugs)),
                              taperAddedDetails: [],
                              taperFromDate: moment(newDate).format("YYYY-MM-DD"),
                              taperDuration: "",
                              taperReducedBy: "",
                              taperUnit: "",
                              taperFrequency: "",
                              selectedEditIndex: null,
                            })
                          }} 

                          disabled={this.state.isLocked}
                        >
                          <Text style={{ color: this.state.newItem ? color.white : "", fontSize: '0.9vw' }}>{item}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                  enableEmptySections={true}
                />
              </ScrollView>
            </View>
            : null}

        </View>
        <View style={{flexDirection:'row',flexWrap:'wrap'}}>
        {
          this.state.favoriteTaperDrugs.map((item, index) => {
            // var data
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedDrug}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedDrug"} />
              </View>
            )
          })
        }
        </View>

        <View style={{ flexDirection: "row", marginBottom: '2.6vw', flex: 1, zIndex:1 }}>
          <View
            style={{
              flex: 0.7,
              flexDirection: "column",
            }}
          >
            <Text style={{ fontSize: '0.8vw', color: color.themeBlue,marginBottom:'0.6vw'}}>Generic Name</Text>
            {/* {drugDetailsSuggestionList.length > 0?
              <View> */}
                
                {this.renderTextFieldViewGeneric('Generic Name', 'generic_name', this.state.generic_name, false) }

                
              {/* </View>
              :this.renderText('', this.state.generic_name || '-', true)
            } */}

          </View>
          <View
            style={{
              flex: 0.3
            }}
          >
            <Text style={{ fontSize: '0.8vw', color: color.themeBlue,marginBottom:'0.6vw' }}>Dosage Strength</Text>
            {this.state.generic_name ?
              this.renderTextFieldView('Strength', 'dosage_strength', this.state.dosage_strength, false, 1, "", {}, "", false, this.state.isDosageFieldEnable ? false : true) :
              this.renderText('', this.state.dosage_strength || '-', true) 
              // : this.renderTextFieldView('Strength', 'dosage_strength', this.state.dosage_strength, false)
            }

          </View>
        </View>

        {drugDetailsSuggestionList.length > 1 && !isGenericSelected?
          <View style={[styles.StrengthView, {backgroundColor: color.themeBlue}]}>

          {drugDetailsSuggestionList.length > 0 && drugDetailsSuggestionList.map((item, index) => { item.generic_name }) }

            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: '14.3vw' }}>

            {drugDetailsSuggestionList.length > 0 && drugDetailsSuggestionList.map((item, index) => {
                return <TouchableOpacity key={index} style={{ paddingLeft: '0.5vw', paddingTop: '0.5vw', }} 
                onPress={this._getDrugDetailsbyGeneric.bind(this,item)} >
                <Text style={{ color: color.white, fontSize: '0.8vw' }}>{item.generic_name} - {item.dosage_strength} - {item.dosage_type}</Text>
              </TouchableOpacity>
              })}                     
            </ScrollView>
          </View>
        :null}

        {/* {this.renderTitleWithMultipleBtn("Dosage", Constants.Medication_dosages, 3, false, 
          'selectedDosage', this.state.selectedDosage, this.updateState.bind(this), true, true)} */}

        <View style={{ zIndex: 1, marginBottom: '1.2vw' }}>
          <CommonSectionHeader heading={"Dosage Type"}  />
          {this.renderSubHeadingBorder("", false, true)}

          <View style={{flexDirection:'row',flexWrap:'wrap'}}>
          { 
            Constants.Taper_Med_Dosages.length > 0 && Constants.Taper_Med_Dosages.map((item,index)=>{
              var selectedBtn = (Object.keys(this.state.selectedDosage).length > 0 ) ? ((this.state.selectedDosage).value.toUpperCase()) === "STEROID TABLETS" ? {value : "STEROID TABLETS", label: "Steroid Tablets" } : { value : "DROPS", label: "Drop" } : {}
              // this.renderDosageType(item,index,'selectedDosage', this.state.selectedDosage)
              return (
                <View style={styles.CommonBtnView} key={index}>
                  <CommonButton
                    disable={this.state.isLocked || (selectedBtn.label != item.label && !this.state.isDosageFieldEnable)}
                    item={item}
                    selectedvalue={Object.keys(this.state.selectedDosage).length > 0 ? selectedBtn : {}}
                    butttonText={item.label}
                    buttonType={"outlineNonTheme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.onPressButton.bind(this)}
                    buttonKey={"selectedDosage"}
                    prescriptionType={"TaperMed"}
                    // selectedDosageCount={}
                    />
                </View>
              )
            })
          }
          </View>

        </View>
          
        {/* {this.renderTitleWithMultipleBtn("Eye Type", Constants.eye_type, 4, false, 'selectedEye', this.state.selectedEye, this.updateState.bind(this), false, true)} */}

        {taperUnit === "Drop(s)" ?
        this.renderSubHeadingBorder("Eye Type", false, true):null
        }
        {taperUnit === "Drop(s)" ?
        <View style={{flexDirection:'row',flexWrap:'wrap', marginBottom: '1.2vw'}}>
        {
          Constants.eye_options.map((item, index) => {
            // var data
            return (
              <View style={styles.CommonBtnView} key={index}>
                <CommonButton
                  disable={this.state.isLocked}
                  item={item}
                  selectedvalue={this.state.selectedEye}
                  butttonText={item.label}
                  buttonType={"outlineNonTheme"}
                  buttonIcon={""}
                  rightIcon={false}
                  buttonAction={this.onPressButton.bind(this)}
                  buttonKey={"selectedEye"} />
              </View>
            )
          })
        }
        </View>:null
        }

          <CommonSectionHeader heading={"Taper Details"}  />
        <View style={{marginTop: '1.2vw', flexWrap: "wrap", flexDirection: "row", zIndex: 1}}>
            <View style={{ marginBottom: '1.2vw', width: "50%"}}>
            <Text style={[styles.StartDateText, {backgroundColor: color.white}]}>{"Start Date"}</Text>
            <DatePicker 
            disabled={this.state.isLocked || len === 0}
            clearIcon={false}
            value={this.state.taperFromDate ? moment(this.state.taperFromDate) : null}
            onChange={date => {
              var fulldate = new Date(date);
              var converted_date = moment(fulldate).format("YYYY-MM-DD");
              this.setState({ taperFromDate: converted_date})
            }}
            disabledDate={current => {
              return current && current < moment().add(-1,'days');
            }} 
            placeholder={"Select Date"}
            style={ {backgroundColor: "transparent", 
            borderWidth: 1,
            marginTop: '0.5vw',
            height: '2.3vw',
            borderWidth: 1,
            borderRadius: 4,
            paddingLeft: '0.6vw',
            paddingRight: '0.6vw',
            width: "80%", borderColor: color.lightGray}}
            />
          </View>
          
          <View style={{ marginBottom: '1.2vw', width: "50%"}}>
            <Text style={[styles.StartDateText, { backgroundColor: color.white,}]}>{"Duration"}</Text> 
            <View style={{flexDirection: "row"}}>
            <TextInput 
            value={this.state.taperDuration}
            onChangeText={(text) => {
              var isNum = this.numberValidation(text)
              if(isNum){
              this.setState({
                taperDuration: text
              })
              }
            }}
            editable={!this.state.isLocked && len > 0} 
            style={[styles.DurationTextInput, {borderColor: color.lightGray}]}
            />
            <Picker
              enabled={!this.state.isLocked && len > 0}
              selectedValue={this.state.taperDurationMode}
              style={[styles.DurationPicker, {borderColor: color.lightGray}]}  
              itemStyle={{}}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["taperDurationMode"] = itemvalue;

                this.setState({ states });
              }}
            >
              <Picker.Item label="Days" value="Day(s)" />
              <Picker.Item label="Weeks" value="Week(s)" />
              <Picker.Item label="Months" value="Month(s)" />
            </Picker>
            </View>
          </View>

          <View style={{ marginBottom: '1.2vw', width: "50%" }}>
            <Text style={[styles.StartDateText, { backgroundColor: color.white,}]}>{"Unit"}</Text> 
            <View style={{flexDirection: "row"}}>
            <TextInput 
            value={this.state.taperUnitVal}
            onChangeText={(text) => {
              var isNum = this.numberValidation(text)
              if(isNum){
                this.setState({
                  taperUnitVal: text
                })
              }
            }}
            editable={!this.state.isLocked && len > 0} 
            style={[styles.SelectDateTextInput, {borderColor: color.lightGray}]}
            />
            <TextInput 
            value={taperUnit}
            editable={false} 
            style={[styles.SelectDateTextInput, {borderColor: color.lightGray, borderRadius: 0}]}
            />
            </View>
          </View>
          {taperUnit === "Drop(s)" ? 
          <View style={{ marginBottom: '1.2vw', width: "50%"}}>
          <Text style={[styles.StartDateText, {backgroundColor: color.white,}]}>{"Frequency"}</Text> 
          <Picker
              enabled={!this.state.isLocked && len > 0}
              selectedValue={this.state.taperFrequency}
              style={[styles.FrequencyPiker, {borderColor: color.lightGray}]}  
              itemStyle={{}}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["taperFrequency"] = itemvalue;

                this.setState({ states });
              }}
            >
              <Picker.Item label="Select Frequency" value="" />
              <Picker.Item label="1 time a day" value={1} />
              <Picker.Item label="2 times a day" value={2} />
              <Picker.Item label="3 times a day" value={3} />
              <Picker.Item label="4 times a day" value={4} />
              <Picker.Item label="5 times a day" value={5} />
              <Picker.Item label="6 times a day" value= {6} />
              <Picker.Item label="10 times a day" value={10} />
              <Picker.Item label="12 times a day" value= {12} />
            </Picker>
          </View>
          : 
          <View style={{ marginBottom: '1.2vw', width: "50%"}}>
            <Text style={[styles.StartDateText, { backgroundColor: color.white,}]}>{"Reduced By (mg)"}</Text> 
            <View style={{flexDirection: "row"}}>
            <TextInput 
            value={this.state.taperReducedBy}
            onChangeText={(text) => {
              var isNum = this.numberValidation(text)
              if(isNum){
              this.setState({
                taperReducedBy: text
              })
              }
            }}
            editable={!this.state.isLocked && len > 0} 
            style={[styles.FrequencyPiker, {borderColor: color.lightGray, padding: '0.6vw'}]}
            />
            </View>
          </View>
          }
          {/* <View style={{ marginBottom: '1.2vw', width: "50%"}}>
            <Text style={[{backgroundColor: color.white}, styles.StartDateText]}>{"Start Time"}</Text> 
            <View style={{flexDirection: "row"}}> */}
            {/* <TextInput editable={!this.state.isLocked && len > 0} style={{borderColor: color.lightGray, height: 35, borderWidth: 1, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, width: "60%", padding: 10, marginTop: 8}}/> */}
            {/* {this.timePicker()} */}
            {/* <Picker
              enabled={!this.state.isLocked && len > 0}
              selectedValue={this.state.taperStartTimeMode}
              style={{borderColor: color.lightGray, height: 35, borderWidth: 1, borderTopRightRadius: 4, borderBottomRightRadius: 4, width: "20%", marginTop: 8 }}  
              itemStyle={{}}
              onValueChange={(itemvalue) => {

                var states = this.state;
                states["taperStartTimeMode"] = itemvalue;

                this.setState({ states });
              }}
            >
              <Picker.Item label="AM" value="AM" />
              <Picker.Item label="PM" value="PM" />
            </Picker> */}
            {/* </View>
          </View> */}
          {/*<View style={{ marginBottom: '1.2vw', width: "50%"}}>
          <Text style={[styles.StartDateText, {backgroundColor: color.white,}]}>{"Interval (hrs)"}</Text> 
          <TextInput 
          value={this.state.taperInterval}
          onChangeText={(text) => {
            var isNum = this.numberValidation(text)
            if(isNum){
              this.setState({
                taperInterval: text
              })
            }
          }}
          editable={!this.state.isLocked && len > 0} 
          style={[styles.IntravelTextInput, {borderColor: color.lightGray}]}
          />
          </View> */}
          <View style={{ width: "80%", justifyContent: "center", alignItems: "center", zIndex: -1 }}>
            <TouchableOpacity
            onPress={() => {
              this.taperDetailAdd()
            }} 
            style={{width: "20%"}}
            >
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
        </View>
        {(this.state.taperAddedDetails).length > 0 ?
        <View style={{marginBottom: '1.2vw'}}>
        <CommonSectionHeader heading={"Added Details"} />
        <View>
          {(this.state.taperAddedDetails).map((item, index) => (
            <View style={{flexDirection: "row", flex: 1, marginBottom: '0.6vw'}}>
              <View style={{flex: 0.45}}>
                <Text style={{fontSize: '0.9vw'}}>{item.duration}</Text>
                <Text style={{fontSize: '0.9vw'}}>{"(" + item.start_date + " - " + item.end_date + ")"}</Text>
              </View>
              <View style={{flex: 0.40}}>
                <Text style={{fontSize: '0.9vw'}}>{item.frequency}</Text>
                <Text style={{fontSize: '0.9vw'}}>{item.no_of_dosage}</Text>
                {/* <Text style={{fontSize: '0.9vw'}}>{"(" + (item.timing).toString() + ")"}</Text> */}
              </View>
              <View style={{flex: 0.15, flexDirection: 'row',justifyContent:'space-between'}}>
              <TouchableOpacity disabled={this.state.isLocked} onPress={() => {this.onEditTaperMed(item, index)}}>
                {this.renderIdButton("pencil")}
              </TouchableOpacity>
              <TouchableOpacity disabled={this.state.isLocked} onPress={() => {this.onDeleteTaperMed(index)}}>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View> 
          ))}
        </View>
        </View>
        : null
        }

        <View style={{
          flexDirection: "row",
          marginBottom: '1.2vw',
          justifyContent: "space-between",
          zIndex: 0,
        }}>
        <View style={{marginBottom:'1.2vw', width: "70%"}}>
         <CommonSectionHeader heading={"Quantity"}  />
        </View>
        <View style={{marginBottom:'1.2vw', width: "30%"}}>
         <TextInput 
          style={{backgroundColor: "#fff", border: "1px solid black", height: "100%", borderRadius: 4, paddingLeft: '0.6vw' }}
          value = {this.state.quantity}
          // onChange = {event => this.setState({quantity: event.target.value})}
          onChange = {(text)=>this.onChangeQuantity(text)}
         />
        </View>
        </View>

        {/* {this.renderTextFieldView("Comments")} */}
        <View style={{marginBottom:'1.2vw'}}>
         <CommonSectionHeader heading={"Comments"}  />
        </View> 
        {this.renderTextFieldView("Only 250 characters are allowed", "comments", this.state.comments, true, 4,'',{},'',false,this.state.isLocked,250)}
          {
            !this.state.prescriptionIsTransmitted ?
          
        <View style={{ marginTop: '1.2vw',alignSelf:"center"  }}>
          <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this)}>
            {this.renderTextBtn("Add", true, false)}
          </TouchableOpacity>
        </View>
          : null
          }
        {/* <View>
          {this.state.successOrErrorMessage ? (
            <View style={{ height: 40, width: '100%', backgroundColor: this.state.successMessageFlag ? 'green' : this.state.errorMessageFlag ? 'red' : null, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{
                color: "white", textAlign: "center", justifyContent: "center"
              }}>
                {
                  this.state.successMessageFlag ? 'Medication Created Successfully' :
                    this.state.errorMessageFlag ? 'Medication Failed' : null}</Text>
            </View>
          ) : null}
        </View > */}
      </View>
      : null
      }
      </View>
      </TouchableWithoutFeedback>
    );
  }

  getMedictaionFrequency(){
    
    let service_url = Constants.MEDICATION_FREQUENCY;
    // console.log("Frequency-Url:", service_url)
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getMedictaionFrequencySuccess,
      this.getMedictaionFrequencyFailure
    );
  }

  getMedictaionFrequencySuccess= success =>{
    // console.log("Frequency-Success:", success)
    if(success.status == "success"){

      var getMedicationFrequencyList = []
      if(success.data && success.data.length > 0){
        let data = success.data
        for (const item of data) {
          // if(item !=="M-A-E-N"){
            let prepareData = { value: item, label: item}
            getMedicationFrequencyList.push(prepareData);
          // }
        }
      }

      this.setState({
        medicationFrequency:  getMedicationFrequencyList
      })
    }
  }

  
}
const styles = StyleSheet.create({
      DosageTypeText: {
        width: '1.5vw',
        height: '1.5vw',
        paddingHorizontal: '0.6vw',
        paddingVertical: '0.3vw',
        borderRadius: 40,
        justifyContent: 'center',
        flex: 1,
        zIndex: 2
      },
      PlusBtnText: {
        width: '1.5vw',
        height: '1.5vw',
        paddingHorizontal: '0.6vw',
        paddingVertical: '0.3vw',
        borderRadius: 40,
        justifyContent: 'center',
        flex: 3,
        zIndex: 1000
      },
      DrugTextInput: {
        color: "#525252",
        minHeight: '2.3vw',
        padding: '0.5vw',
        marginRight: 0,
        flex: 1,
        borderWidth: 0.5,
        borderRadius: 10,
        fontSize: '0.9vw'
      },
      TimePickerView: { 
        flexDirection: 'row', 
        justifyContent: 'center', 
        borderWidth: 1, 
        borderTopLeftRadius: 4, 
        borderBottomLeftRadius: 4, 
        paddingVertical: '0.2vw', 
        paddingHorizontal: '0.5vw', 
        height: '2.3vw' 
      },
      TimePickerView1: { 
        flexDirection: 'row', 
        backgroundColor: '#262941', 
        position: 'absolute', 
        zIndex: 10, 
        marginTop: '1.8vw', 
        paddingHorizontal: '0.5vw', 
        borderRadius: 5 
      },
      MedicationView: {
        flexDirection: "row", 
        flex: 1, 
        marginBottom: '1vw', 
        marginTop: '0.3vw'
      },
      MedicationDropDown: { 
        borderRadius: 20, 
        height: '2vw', 
        width: "100%", 
        fontSize: '0.8vw', 
        backgroundColor: "transparent", 
        borderRadius: 4
      },
      NewDrugNameView: {
        borderRadius: 10,
        width: '90%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '0.6vw',
        marginLeft: '0.6vw',
        marginTop: '6.3vw',
        position: 'absolute',
      },
      ItemView: {
        flexDirection: "row",
        bottom: 10,
        marginBottom: '0.6vw',
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        marginTop: '0.6vw',
        zIndex: 10,
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      },
      StrengthView: {
        // maxHeight: this.state.newItem ? "" : 0,
        borderRadius: 10,
        width: '90%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '0.6vw',
        marginLeft: '0.6vw',
        marginTop: '23.9vw',
        position: 'absolute',
        zIndex: 10
      },
      TransparentTextInput: { 
        // borderRadius: 20,
        borderWidth: 1,
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        alignSelf: 'flex-start'
      },
      StartDateText: { 
        zIndex: 2, 
        fontSize: '0.8vw', 
        position: "absolute", 
        marginLeft: '0.6vw', 
        paddingLeft: '0.3vw', 
        paddingRight: '0.3vw', 
        color: "#888888"
       },
       SelectDate: {
        backgroundColor: "transparent", 
        borderWidth: 1,
        marginTop: '0.5vw',
        height: '2.3vw',
        borderWidth: 1,
        borderRadius: 4,
        paddingLeft: '0.6vw',
        paddingRight: '0.6vw',
        width: "80%",
      },
      SelectDateTextInput: {
        height: '2.3vw', 
        borderWidth: 1, 
        borderTopLeftRadius: 4, 
        borderBottomLeftRadius: 4, 
        width: "40%", 
        padding: '0.6vw', 
        marginTop: '0.5vw',
        fontSize: '0.9vw'
      },
      DurationTextInput: {
        height: '2.3vw', 
        borderWidth: 1, 
        borderTopLeftRadius: 4, 
        borderBottomLeftRadius: 4, 
        width: "50%", 
        padding: '0.6vw', 
        marginTop: '0.5vw'
      },
      DurationPicker: {
        height: '2.3vw', 
        borderWidth: 1, 
        borderTopRightRadius: 4, 
        borderBottomRightRadius: 4, 
        width: "30%", 
        marginTop: '0.5vw' ,
        fontSize: '0.9vw'
      },
      FrequencyPiker: {
        height: '2.3vw', 
        borderWidth: 1, 
        borderTopRightRadius: 4, 
        borderBottomRightRadius: 4, 
        width: "80%", 
        marginTop: '0.5vw' ,
        fontSize: '0.9vw'
      },
      IntravelTextInput: {
        height: '2.3vw', 
        borderWidth: 1, 
        borderRadius: 4, 
        width: "80%", 
        padding: '0.6vw', 
        marginTop: '0.5vw'
      }
})