import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, StyleSheet, TextInput } from 'react-native'
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton, RatingScaleHeader } from '../../BaseComponent';
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import CheckBoxIcon from "react-native-vector-icons/MaterialCommunityIcons";   // checkbox-blank-circle-outline // check-circle-outline
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
const screenHeight = Dimensions.get("window").height;
const ratingScaleRadioButtonSize = 20;
var Messages = require('../../../../utils/InfoMessages')

const screenName = "Children's Yale-Brown Obsessive Compulsive Scale"

export class CyBocs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listofCyBocs: {},
            comments: "",
            totalScore: 0,
            patientId:this.props.patientId,
            appointmentId:this.props.appointmentId,
            
            CYBOCSObsessionsQuestions: [],
            CYBOCSCompulsionQuestions: [],
            obsessionsQuestions :[],
            compulsionQuestions :[],
            childernsYaleBrownObsessive:[],

            compulsion_sub_total: 0,
            obsession_sub_total: 0,
          
        }
    }

    componentDidMount() {
        this.getCyBocs()
    }

    refreshComponent(appointmentId){
        this.setState({
            appointmentId:appointmentId
        },()=>{
            this.getCyBocs()
        })
    }

    getCyBocs() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL +
            "?patient_id=" + this.state.patientId +
            "&appointment_id=" + this.state.appointmentId +
            "&rating_scale_name=" + screenName;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getCyBocsSuccess,
            this.getCyBocsFailure
        );
    }


    getCyBocsSuccess = (success) => {
        if (success.status == "success") {

            // // listofCyBocsWithOutHeader
            // var getListOfQuestionWithoutHeader = [];
            var getCYBOCSObsessionsQuestions=[]
            var getCYBOCSCompulsionQuestions=[]
            var getObsessionsQuestions = [];
            var getCompulsionQuestions = [];
            var getChildernsYaleBrownObsessive=[];


            if (success.data.questions.length > 0) {
                var questions = success.data.questions;
                for (let i = 0; i < questions.length; i++) {

                    
                    
                    if (questions[i].question_heading == "CY-BOCS OBSESSIONS CHECKLIST") {
                        getCYBOCSObsessionsQuestions.push(questions[i]);

                    } else if (questions[i].question_heading == "CY-BOCS COMPULSIONS CHECKLIST") {
                        getCYBOCSCompulsionQuestions.push(questions[i]);

                    }  else if (questions[i].question_heading == "QUESTIONS ON OBSESSIONS") {
                        getObsessionsQuestions.push(questions[i]);

                    } else if (questions[i].question_heading == "QUESTIONS ON COMPULSIONS") {
                        getCompulsionQuestions.push(questions[i]);

                    } else if (questions[i].question_heading == "CHILDREN’s YALE BROWN OBSESSIVE COMPULSIVE SCALE") {
                        getChildernsYaleBrownObsessive.push(questions[i]);
                    }
                }
            }


            console.log(JSON.stringify(success.data))

            this.setState({
            //     listofCyBocsWithOutHeader: getListOfQuestionWithoutHeader,
            //     obsessionsQuestions: getObsessionsQuestions,
            //     compulsionQuestions: getCompulsionQuestions,
                listofCyBocs: success.data,
                comments: success.data.comments,
                totalScore: success.data.total_score,


                CYBOCSObsessionsQuestions: getCYBOCSObsessionsQuestions,
                CYBOCSCompulsionQuestions: getCYBOCSCompulsionQuestions,
                obsessionsQuestions :getObsessionsQuestions,
                compulsionQuestions :getCompulsionQuestions,
                childernsYaleBrownObsessive:getChildernsYaleBrownObsessive,

                // compulsion_sub_total:success.data.compulsion_sub_total,
                // obsession_sub_total:success.data.obsession_sub_total,
                
            },()=>{
                this.validateYaleBrownObsessiveTotalScores("childernsYaleBrownObsessive" )
            })
        }
    }
    getCyBocsFailure = error => { }

    validateYaleBrownObsessiveTotalScores(key, indexOfQuestion="") {
        var states = this.state;
        var getDatas = states[key];

        var getTotalScoreofObsession = 0
        var getTotalScoreofCompulsion = 0

        // if (indexOfQuestion <= 5) {
            for (let i = 0; i < getDatas.length; i++) {
                console.log("getDatas[i].display_order --------> "+getDatas[i].display_order)
                var getOptions = getDatas[i].options;
                if (getDatas[i].display_order == "1b" || getDatas[i].display_order == "6b") {
                    
                }else{
                    for (let j = 0; j < getOptions.length; j++) {
                        if (getOptions[j].is_selected) {
                            if (i <= 5) {
                                getTotalScoreofObsession = getTotalScoreofObsession + getOptions[j].option_score;
                                // getTotalScoreofCompulsion=getTotalScoreofCompulsion;
                            } 
                            if(i > 6) {
                                getTotalScoreofCompulsion = getTotalScoreofCompulsion + getOptions[j].option_score
                                // getTotalScoreofObsession=getTotalScoreofObsession;
                            }
                        }

                    }
                }

            // }
        }

        this.setState({
            compulsion_sub_total: getTotalScoreofCompulsion,
            obsession_sub_total: getTotalScoreofObsession,
        })
    }

    validateTotalScores() {
        var { listofCyBocs } = this.state;
        var getListOfQuestions = listofCyBocs.questions;
        var getTotalScore = 0
        if (getListOfQuestions.length > 0) {
            for (let i = 0; i < getListOfQuestions.length; i++) {
                var getOptions = getListOfQuestions[i].options
                if (getOptions.length > 0) {
                    for (let j = 0; j < getOptions.length; j++) {
                        // console.log("getOptions[j].display_order -----> "+getOptions[j].display_order)
                        if(getListOfQuestions[i].display_order == "1b" || getListOfQuestions[i].display_order == "6b"){
                            
                        }else{
                            if (getOptions[j].is_selected ) {
                                getTotalScore = getTotalScore + getOptions[j].option_score
                            }
                        }
                        
                    }
                }
            }
        }
        this.setState({
            totalScore: getTotalScore
        })
    }


    saveCyBocs() {
        var service_url = Constants.RATING_SCALE_COMMON_API_FOR_ALL
        if(this.state.totalScore >= 0 && this.state.totalScore !== null){
        var combainedArrays = [];
        combainedArrays.push(...this.state.CYBOCSObsessionsQuestions, ...this.state.CYBOCSCompulsionQuestions, ...this.state.obsessionsQuestions,  ...this.state.compulsionQuestions,  ...this.state.childernsYaleBrownObsessive)

        var data = {
            "id": this.state.listofCyBocs.id ? this.state.listofCyBocs.id : "",
            "patient_id": this.state.patientId,
            "appointment_id": this.state.appointmentId,
            "rating_scale_id": this.state.listofCyBocs.rating_scale_id,
            "rating_scale_name": this.state.listofCyBocs.rating_scale_name,
            "total_score": this.state.totalScore,
            "comments": this.state.comments,
            "compulsion_sub_total": this.state.compulsion_sub_total,
            "obsession_sub_total": this.state.obsession_sub_total,
            "questions": combainedArrays
        }
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.saveCyBocsSuccess,
            this.saveCyBocsFailure
        );
        }else{
            this.props.showResposeValue("error", Messages.RatingScaleInvalidTotalScore)
        }
    }

    saveCyBocsSuccess = success => {
        if (success.status == "success") {
            this.getCyBocs()
            this.props.showResposeValue("success", success.message)
            this.props.getPreviousScore()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    }
    saveCyBocsFailure = error => {
        this.props.showResposeValue("error", error.message)
    }

    onselectOptions(key,indexOfQuestion, indexOfOptions) {

            
            var states = this.state;
            var getDatas = states[key];

        // var { listofCyBocs } = this.state;

        // var getListOfOptions = getDatas.questions[indexOfQuestion].options;
        var getListOfOptions = getDatas[indexOfQuestion].options;
        
        var prepareData = [];
        for (let i = 0; i < getListOfOptions.length; i++) {

            var data = getListOfOptions[i];

            if (i == indexOfOptions) {
                data["is_selected"] = true;
                prepareData.push(data)
            } else {
                data["is_selected"] = false;
                prepareData.push(data)
            }
        }
        // getDatas.questions[indexOfQuestion]["options"] = prepareData
        getDatas[indexOfQuestion]["options"] = prepareData
        this.setState({ states }, () => {
            this.validateTotalScores();
            if(key == "childernsYaleBrownObsessive"){
                this.validateYaleBrownObsessiveTotalScores(key,indexOfQuestion)
            }
        })

    }



    // (renderListOfOptions(options, indexOfOptions, indexOfQuestion) {
    //     return ()
    //         <View key={indexOfOptions} >
    //             <TouchableOpacity
    //                 onPress={() => {
    //                     this.onselectOptions("listofCyBocsWithOutHeader",indexOfQuestion, indexOfOptions)
    //                 }}
    //                 style={{ marginHorizontal: 20, marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
    //                 <View style={{ flex: 0.05 }} >
    //                     <CheckBoxIcon name={options.is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
    //                 </View>
    //                 <View style={{ flex: 0.95 }} >
    //                     <Text style={{ fontWeight: '500', fontSize: 14, color: "#292929" }} numberOfLines={5}>{options.option_label}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    // renderListOfDatas(questions, indexOfQuestion) {
    //     var getQuestion = (indexOfQuestion + 1).toString() + ". " + questions.question_label
    //     return (
    //         <View style={{ marginVertical: 5 }} key={indexOfQuestion}>
    //             <CommonHistorySectionHeader
    //                 heading1={getQuestion}
    //                 // question={questions.question_label}
    //                 headerKey={"RatingScale"}
    //                 columnSize={[1]}
    //                 noOfColumn={1}
    //             />
    //             {
    //                 questions.question_description ?
    //                     <Text style={[Style.historyHeaderFontStyleStartText, { fontSize: 14, marginVertical: 5 }]}>{questions.question_description}</Text>
    //                     : null
    //             }

    //             {
    //                 questions.options.length > 0 ?
    //                     questions.options.map((item, index) => {
    //                         return (
    //                             this.renderListOfOptions(item, index, indexOfQuestion)
    //                         )
    //                     })
    //                     : null
    //             }
    //         </View>
    //     )
    // }

    renderTotalScoreAndComments() {
        return (
            <View style={{ height: 60, flexDirection: 'row', marginBottom: 5 }}>
                <View style={{ flex: 0.25, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center', justifyContent: "space-evenly" }}>
                    <Text style={{ color: color.white }}>{"Total Score"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white }} />
                    <Text style={{ color: color.white }}>{this.state.totalScore}</Text>
                </View>
                <View style={{ flex: 0.01 }}></View>
                <View style={{ flex: 0.74, backgroundColor: color.themeDark, borderRadius: 5, flexDirection: "row", alignItems: 'center' }}>
                    <Text style={{ color: color.white, marginLeft: 15 }}>{"Comments"}</Text>
                    <View style={{ height: 40, width: 1, backgroundColor: color.white, marginHorizontal: 15 }} />
                    <TextInput
                        multiline={true}
                        numberOfLines={3}
                        maxLength={300}
                        style={{ color: color.white ,width:"90%"}}
                        value={this.state.comments}
                        onChangeText={(txt) => {
                            this.setState({ comments: txt })
                        }}
                    />
                    {/* <Text style={{color:color.white}}>{this.state.}</Text> */}
                </View>
                {/* <Text>{this.state.totalScore}</Text>
                <Text>{this.state.comments}</Text> */}
            </View>
        )
    }


    renderNextHeader(header, columnSize = 2,isNumber=false) {
        return (
            <View style={{ flexDirection: 'row', borderTopColor: "#888888", borderBottomColor: "#888888", borderBottomWidth: 1, borderTopWidth: 1, paddingVertical: 12, alignItems: 'center' }}>
                <View style={{ flex: columnSize == 5 ? 0.6 : 0.7 }}>
                    <Text style={{ fontWeight: '500', fontSize: 18, color: color.themeDark }} numberOfLines={5}>{header}</Text>
                </View>
                {
                    columnSize == 5 ?
                        <View style={{ flex:  0.4 , flexDirection: 'row' }}>
                            <View style={ styles.radioButtonOptions5}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{isNumber ? 0: "None"}</Text>
                            </View>
                            <View style={styles.radioButtonOptions5}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{isNumber ? 1:"Mild"}</Text>
                            </View>
                            <View style={styles.radioButtonOptions5}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{isNumber ? 2:"Moderate"}</Text>
                            </View>
                            <View style={styles.radioButtonOptions5}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{isNumber ? 3:"Severe"}</Text>
                            </View>
                            <View style={styles.radioButtonOptions5}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{isNumber ? 4:"Extreme"}</Text>
                            </View>
                        </View>
                        :
                        <View style={{ flex:  0.3, flexDirection: 'row' }}>
                            <View style={styles.radioButtonOptions2}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{"Current"}</Text>
                            </View>
                            <View style={styles.radioButtonOptions2}>
                                <Text style={styles.alignOptions} numberOfLines={5}>{"Past"}</Text>
                            </View>
                        </View>
                }

            </View>
        )
    }

    onChangeTextSubheading(key,value,indexOfQuestion,indexOfOptions){
        var states = this.state;
        var getDatas=states[key];
        var getListOfOptions = getDatas[indexOfQuestion].options[indexOfOptions];

        getListOfOptions["option_value"] = value

        // getListOfOptions=value;

        this.setState({states})
    }

    renderListOfMutipleRadioButtonQuestions(key,item, index) {
        console.log(item.question_label)
        if(item.question_type == "Textbox" || item.question_type == "Textline_yalebrown" || item.question_type == "Textline" ) {
            //  var txtValue=item.options[0].option_value;
               if((item.question_type == "Textline" || item.question_type == "Textbox" )&& key !== "obsessionsQuestions"){

            return(
                
                <View key={index} style={{flexDirection:"row",justifyContent:'flex-end',alignItems:'center',marginVertical:5}}>
                        <View>
                            <Text style={[Style.historyHeaderFontStyleStartText,{fontSize: 15}]}>{item.question_label}</Text>
                        </View>
                        <TextInput
                        editable={(item.question_label =="OBSESSION SUB TOTAL:" || item.question_label =="COMPULSION SUB TOTAL : ")?false:true}
                        // multiline={true}
                        // numberOfLines={3}
                        style={{color: color.black ,width:100,borderBottomWidth:1,borderBottomColor:"#888888"}}
                        
                        value={item.question_label =="OBSESSION SUB TOTAL:" ? this.state.obsession_sub_total
                        :item.question_label =="COMPULSION SUB TOTAL : " ? this.state.compulsion_sub_total :null }

                        // onChangeText={(txt) => {
                        //         this.onChangeTextSubheading(key,txt,index, 0)
                        //     // this.setState({ comments: txt })
                        // }}
                    />
                </View>
                // <View/>
                
            )

        }else{
            <View/>
        }
        }else{
        return (
            <View key={index} style={{ flexDirection: 'row',paddingVertical:6, }}>
                <View style={{ flex: 0.6 }}>

                    <Text style={[styles.alignOptions,item.display_order == "1b" || item.display_order == "6b"?{}:{}]} numberOfLines={5}>{item.question_label}</Text>
                    <Text style={{ fontSize: 12, color: "#292929",marginTop:7 }} numberOfLines={5}>{item.question_description}</Text>
                </View>
                <View style={{ flex: 0.4, flexDirection: 'row' }}>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{

                            this.onselectOptions(key,index, 0);
                            
                        }}>
                            <CheckBoxIcon name={item.options[0].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 1)
                        }}>
                            <CheckBoxIcon name={item.options[1].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{

                            this.onselectOptions(key,index, 2)
                        }}>
                            <CheckBoxIcon name={item.options[2].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 3)
                        }}>
                            <CheckBoxIcon name={item.options[3].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.radioButtonOptions5}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 4)
                        }}>
                            <CheckBoxIcon name={item.options[4].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    
                    
                </View>
            </View>
        )
        }
    }

    renderListOfObsessionsQuestions(key,item, index) {
        if(item.question_type == "Textbox" || item.question_type == "Textline_yalebrown" ) {
            
            // "TARGET SYMPTOM LIST FOR OBSESSIONS" 
            // "TARGET SYMPTOM LIST FOR COMPULSION"
                var txtValue=item.options[0].option_value;
            return(
                
                <View key={index} style={{flexDirection:"row",justifyContent:'flex-end',alignItems:'center',marginVertical:5}}>
                        <View>
                            <Text style={[Style.historyHeaderFontStyleStartText,{fontSize: 15}]}>{item.question_label}</Text>
                        </View>
                        <TextInput
                        // multiline={true}
                        // numberOfLines={3}
                        style={{paddingLeft:10, color: color.black ,height:50,width:200,borderRadius:4,borderWidth:1,borderColor:"#888888"}}
                        value={txtValue}
                        onChangeText={(txt) => {
                                this.onChangeTextSubheading(key,txt,index, 0)
                            // this.setState({ comments: txt })
                        }}
                    />
                </View>
                
            )
        }else{
        return (
            <View key={index} style={{ flexDirection: 'row',paddingVertical:6,borderBottomColor:"#D1D1D1",borderBottomWidth:0.5 }}>
                <View style={{ flex: 0.7 }}>
                    <Text style={styles.alignOptions} numberOfLines={5}>{item.question_label}</Text>
                </View>
                <View style={{ flex: 0.3, flexDirection: 'row' }}>
                    <View style={{ flex: 0.5,alignItems:"center" }}>
                        <TouchableOpacity onPress={()=>{

                            this.onselectOptions(key,index, 0)
                        }}>
                            <CheckBoxIcon name={item.options[0].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.5,alignItems:"center" }}>
                        <TouchableOpacity onPress={()=>{
                            this.onselectOptions(key,index, 1)
                        }}>
                            <CheckBoxIcon name={item.options[1].is_selected ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={color.themeDark} size={ratingScaleRadioButtonSize} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
        }
    }


    renderListOfMultiRadioButtonQuestions(key,header){
        var states=this.state;
        var getDatas = states[key];
        return(
            <View style={{marginVertical:5}}>
                    {
                            getDatas && getDatas.length > 0 ?
                                this.renderNextHeader(header,5,key=="childernsYaleBrownObsessive"? true:false)
                                : null
                        }
                        {
                            getDatas && getDatas.length > 0 ?

                            getDatas.map((item, index) => {
                                    return this.renderListOfMutipleRadioButtonQuestions(key,item, index)
                                })
                                : null
                        }
            </View>
        )
    }
    

    renderListOfQuestions(key,header){
        var states=this.state;
        var getDatas = states[key];
        return(
            <View>
                    {
                            getDatas && getDatas.length > 0 ?
                                this.renderNextHeader(header)
                                : null
                        }
                        {
                            getDatas && getDatas.length > 0 ?

                            getDatas.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions(key,item, index)
                                })
                                : null
                        }
            </View>
        )
    }

    render() {
        console.log("yesy -----------> "+JSON.stringify(this.state.childernsYaleBrownObsessive));

        return (
            <View>
                {this.renderTotalScoreAndComments()}


                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                    style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 15 }}>
                        {/* listofCyBocsWithOutHeader:[],
                        obsessionsQuestions:[],
                        compulsionQuestions:[] */}
                        {/* {
                            this.state.listofCyBocsWithOutHeader && this.state.listofCyBocsWithOutHeader.length > 0 ?
                                this.state.listofCyBocsWithOutHeader.map((item, index) => {
                                    return (
                                        this.renderListOfDatas(item, index)
                                    )
                                })
                                : null
                        } */}


                        {this.renderListOfQuestions("CYBOCSObsessionsQuestions","CY-BOCS OBSESSIONS CHECKLIST")}
                        {this.renderListOfQuestions("CYBOCSCompulsionQuestions","CY-BOCS COMPULSIONS CHECKLIST")}
                        {this.renderListOfMultiRadioButtonQuestions("obsessionsQuestions","QUESTIONS ON OBSESSIONS")}
                        {this.renderListOfMultiRadioButtonQuestions("compulsionQuestions","QUESTIONS ON COMPULSIONS")}
                        {this.renderListOfMultiRadioButtonQuestions("childernsYaleBrownObsessive","CHILDREN’s YALE BROWN OBSESSIVE COMPULSIVE SCALE")}

                        {/* {
                            this.state.CYBOCSObsessionsQuestions && this.state.CYBOCSObsessionsQuestions.length > 0 ?
                                this.renderNextHeader("CY-BOCS OBSESSIONS CHECKLIST")
                                : null
                        }
                        {
                            this.state.CYBOCSObsessionsQuestions && this.state.CYBOCSObsessionsQuestions.length > 0 ?

                                this.state.CYBOCSObsessionsQuestions.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions("CYBOCSObsessionsQuestions",item, index)
                                })
                                : null
                        } */}


                        {/* {
                            this.state.compulsionQuestions && this.state.compulsionQuestions.length > 0 ?
                                this.renderNextHeader("CY-BOCS COMPULSIONS CHECKLIST")
                                : null
                        }
                        {
                            this.state.compulsionQuestions && this.state.compulsionQuestions.length > 0 ?

                                this.state.compulsionQuestions.map((item, index) => {
                                    return this.renderListOfObsessionsQuestions("compulsionQuestions",item, index)
                                })
                                : null

                        } */}


                        <View style={{ backgroundColor: color.applicationBackgroundColor, height: 60, justifyContent: 'center' }}>
                            <CommonButton
                                item={{}} butttonText={"Save"}
                                selectedvalue={{}}
                                buttonType={"theme"}
                                buttonIcon={""} rightIcon={true}
                                buttonAction={this.saveCyBocs.bind(this)}
                                buttonKey={""}
                            />
                        </View>

                    </View>
                </ScrollView>
            </View>
        )
    }
}



const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    leftSideScrollView: {
        width: "100%", height: screenHeight - 200, backgroundColor: color.white, zIndex: -1
    },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10, backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    },
    alignOptions:{
        fontWeight: '500', fontSize: 14, color: "#292929",alignItems:'center'
    },
    radioButtonOptions2:{
        flex: 0.5,alignItems: "center",justifyContent:"center" 
    },
    radioButtonOptions5:{
     flex: 0.25,alignItems: "center",justifyContent:"center" 
    },

})