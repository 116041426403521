import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { color } from '../../../../../../styles/Color';
import Pagination from './../../../../common/Pagination';
import { NavigationTopHeader, CommonButton } from '../../../../BaseComponent';
import { Constants } from '../../../../../../utils/Constants';
import OpthamologyService from '../../../../../../network/OpthamologyService';
import AsyncStorage from '../../../../../../AsyncStorage';
import { Tooltip } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import moment from 'moment';
const navigationHeader = [
  { label: "Drug Listing", value: "drugList" },
  { label: "Purchase Indent Listing", value: "purchaseIndent" }
]
export class DrugList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        durgList: [],
        drugListAfterPagination: [],
        current_page: 1,
        records_per_page: 10,
        isAdminAccess: false,
        searchBrandName: ""
    };
  }
 async componentDidMount() {
    this.getDrugList()
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
  }
  getDrugList = () => {
    this.setState({
      current_page: 1,
      records_per_page: 10,
      drugListAfterPagination: [],
      durgList: []
    })
    var url = Constants.OT_DRUGLIST_GET + "?brand_name=" + this.state.searchBrandName
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getDrugListSuccess,
      this.getDrugListFailure
    );
  }
  getDrugListSuccess = response => {
    if (response && response.status == "Success") {
      this.setState({
        durgList: response.data
      })
    }
  }
  getDrugListFailure = error => {
  }
  getBillTableTitle(title, flexWidth = 0.05, fontsize="0.7vw") {
    return (
        <View style={{ flex: flexWidth}}>
            <Text style={{ fontSize: fontsize, fontWeight: "500" }}>{title}</Text>
        </View>)
  }
  renderDurgListHeader = () => {
    return(
        <View style={styles.drugHeaderContainer}>
         <View style={styles.subContainer}>
            {this.getBillTableTitle('S.No', 0.05,"0.87vw")}
            {this.getBillTableTitle('Brand Name', 0.13,"0.87vw")}
            {this.getBillTableTitle('Generic Name', 0.13,"0.87vw")}
            {this.getBillTableTitle('Dosage Type', 0.1,"0.87vw")}
            {this.getBillTableTitle('Dosage Strength', 0.12,"0.87vw")}
            {this.getBillTableTitle('Batch No', 0.1,"0.87vw")}
            {this.getBillTableTitle('Quantity', 0.1,"0.87vw")}
            {this.getBillTableTitle('Expiry Date', 0.1,"0.87vw")}
            {this.getBillTableTitle('MRP/Quantity', 0.1,"0.87vw")}
            {this.getBillTableTitle('Box No',  0.07,"0.87vw")}
         </View>
        </View> 
    )
  }
  renderDrugList = () => {
    return(
      <View>
      { this.state.drugListAfterPagination && this.state.drugListAfterPagination.length > 0 ?
        this.state.drugListAfterPagination && this.state.drugListAfterPagination.map((item, index) => {
          let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page) + (index + 1)
          let expiry_date = moment(item.expiry_date).format("DD-MM-YYYY")
          return (
            <View style={styles.dataContainer}>
              <View style={{ flex: 0.05, marginHorizontal: "0.5vw"}}><Text style={[styles.TableDataText,{}]}>{sno}</Text></View>
              <View style={{ flex: 0.13 }}>
                <Tooltip placement="topLeft" title={item.brand_name}>
                  <Text style={styles.TableDataText}> {item.brand_name && item.brand_name.length > 12 ? item.brand_name.slice(0, 12) + ".." : item.brand_name} </Text>
                </Tooltip>
               </View>
               <View style={{ flex: 0.13 }}>
                <Tooltip placement="topLeft" title={item.generic_name}>
                  <Text style={styles.TableDataText}> {item.generic_name && item.generic_name.length > 12 ? item.generic_name.slice(0, 12) + ".." : item.generic_name} </Text>
                </Tooltip> 
              </View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.dosage_type}</Text></View>
              <View style={{ flex: 0.12 }}><Text style={styles.TableDataText}>{item.dosage_strength}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.batch_no}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.stock_in_quantity}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{expiry_date}</Text></View>
              <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{parseFloat(item.mrp_per_quantity).toFixed(2)}</Text></View>
              <View style={{ flex: 0.07 }}><Text style={styles.TableDataText}>{item.physical_box_no}</Text></View>

            </View>
          )
        }) : 
         <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
          <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
         </View>
        }
    </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
}
renderPaginationView() {
 return (
   <View style={{ marginHorizontal: "1.2vw" }}>
     {
       this.state.durgList && this.state.durgList.length > 0 ?
         <Pagination
           paginationChangedValues={this.paginationChangedValues.bind(this)}
           totalItems={this.state.durgList}
           paginationCurrentPage={this.paginationCurrentPage.bind(this)}
         /> : null
     }
   </View>
 )
}
paginationChangedValues(data) {
 this.setState({
    drugListAfterPagination: data
 })
}
changeTabSection = (data) =>{
  return(
    this.props.renderSelectedTab(data)
  )
}
  render() {
    return (

      <View style={styles.container}>
      <View style={styles.navigationContainer}>  
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={navigationHeader}
            selectedTab={"drugList"}
          />
        <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end", alignItems: "flex-end", margin: "0.6vw" }}>
         <View style={{ flexDirection: "row", zIndex: 0, marginRight: "1vw" }}>
              <TextInput
                value={this.state.searchIol}
                onChangeText={(text) => {
                  this.setState({
                    searchBrandName: text
                  })
                }}
                style={{ borderWidth: 1, height: "4.3vh", fontSize: "0.8vw", width: "9.5vw", paddingHorizontal: "0.4vw", borderColor: color.lightGray }}
                placeholder="Search Brand name..."
              />
              <TouchableOpacity
                style={{ height: "4.3vh", width: "3vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                  this.getDrugList()
                }}>
                <Icon name='search' size={15} color={color.white}></Icon>
              </TouchableOpacity>
            </View>
        { this.state.isAdminAccess ?
          <TouchableOpacity
          style={styles.addVendor}
          onPress={() => {
           this.props.renderSelectedTab("drugIndent")
         }}
          >
          <Text style={styles.addVendorText}>+ Indent</Text>
        </TouchableOpacity>: null }
        </View> 
       </View>
          {this.renderDurgListHeader()}
          {this.renderDrugList()}
          {this.renderPaginationView()}

      </View>
    );
  }
} 
const styles = StyleSheet.create({
    container: {
        margin: "0.4vw"
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.75vw",
        justifyContent: 'center',
        flex: 1
      },
      TableDataText:{
        fontSize:'1vw'
      },
      navigationContainer: {
        flexDirection: "row", flex: 1, justifyContent: "space-between"
      },
      drugHeaderContainer: {
        flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center"
      },
      subContainer: {
        flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: 8, borderRadius: 4, marginTop:'0.5vw'
      },
      addVendor: {
        backgroundColor: color.themeDark,
        borderRadius: 4,
        alignSelf: "center",
        alignItems: "center",
        padding: "0.4vw"
      },
      addVendorText: {
        color: color.white,
        fontSize: "1vw"
      }

})
