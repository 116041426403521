import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView,TouchableWithoutFeedback, StyleSheet, Image, FlatList } from 'react-native';
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'
import { color } from "../../../styles/Color";
import Styles from "../../../styles/Style";
import ImagePath from '../../../utils/ImagePaths';


const pageName = "OpthalmologyTest"
// const pageName = "isOpthalmologyTest"

export default class OpthalmologyTestNew extends BaseComponentStyle {
  constructor(props) {
    super(props);
    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      id: "",
      patientAppointment: this.props.patientAppointment,
      selectedEyeTest: {},
      frequentEyeTest: [],
      isEdit: false,
      textBoxValue: "",
      textBoxAmount: "",
      getAutoSuggestionApiResponce: [],
      comment:"",
      selectedClinic : this.props.selectedClinicOrDoctor,
      searchTest : "",
      searchTestOpen : false,
      searchSuggestionList : [],
      testData : [],
      specialTests : [],
      selectedSpecialTest: {},
      isSpecialTest: false,
      isSpecialTestSearchView: false,
      specialNewItem: "",
      specialSearchList: [],
      specialTest: []
    };
  }

  componentDidMount() {
    this.getFrequentEyeTest()
    this.getSpecialEyeTest()
  }


  componentWillReceiveProps(props) {
    // this.state.getAvailableEyeTypes = props.getAvailableEyeTypes;
    // this.state.getAvailableEyeTypesId = props.getAvailableEyeTypesId
    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {
        // console.log("test####----" + JSON.stringify(props.editItem.item))
        let item = props.editItem.item;

        if (id !== item.id) {

          // var eye_type={label:item.eye_type,value:item.eye_type}

          this.setState({
            id: item.id,
            selectedEyeTest: item,
            // selectedNCT: item['NCT'],
            // selectedApplanation: item['APPLANATION_TONOMETRY'],
            // selectedCCT: item['CCT'],
            // selectedCorrection: item['CORRECTION_FACTOR'],
            // selectedCIOP: item['CIOP']

            // selectedNCT: item.NCT,
            // selectedApplanation: item.APPLANATION_TONOMETRY,
            // selectedCCT: item.CCT,
            // selectedCorrection: item.CORRECTION_FACTOR,
            // selectedCIOP: item.CIOP,
            // comments: props.commonComments
          });
        }
      }
    }
  }


  getAutoSuggestionApi = (searchKey) => {
    var service_url = Constants.EYE_TESTAUTOSUGGESTION_API + "?search_key=" + searchKey;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getAutoSuggestionApiSuccess,
      this.getAutoSuggestionApiFailure
    );
  };

  getAutoSuggestionApiSuccess = success => {
    if (success.status == "success") {
      this.setState({
        getAutoSuggestionApiResponce: success.data
      })
    }
  }
  getAutoSuggestionApiFailure = success => { }

  frequentEyeTestSuccess = success => {
    if (success.status == "success") {
      this.setState({
        // frequentEyeTest: success.data
      })
      // console.log("success------> " + JSON.stringify(success.data))
    }
  }
  frequentEyeTestFailure = error => { }


  getFrequentEyeTest = () => {
    var service_url = Constants.FREQUENT_EYE_TEST + "?clinic_id=" + this.state.selectedClinic.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.frequentEyeTestSuccess,
      this.frequentEyeTestFailure
    );
  };

  removeDuplicate(data) {
   if(data && data.length > 0 && data != false )  { 
    var nameListJsonObject =  data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
   } 
  }

  frequentEyeTestSuccess = success => {
    if (success.status == "success") {
      var {  frequentEyeTest, searchSuggestionList } = this.state

      var data = success.data;
      for (var i = 0; i < data.length; i++) {
        var prepareData = { value: data[i].id, label: data[i].test_name }
        searchSuggestionList.push(prepareData)
        frequentEyeTest.push(prepareData)
      }


      this.setState({
        searchSuggestionList 
      },()=>{
        const size = 10
        const frequentEyeTest = this.state.frequentEyeTest && this.state.frequentEyeTest.length > 0 && this.state.frequentEyeTest.slice(0, size)
        this.setState({
         testData : frequentEyeTest
        })
      })
      // console.log("success------> " + JSON.stringify(success.data))
    }
  }
  frequentEyeTestFailure = error => { }

  getSpecialEyeTest = () =>{
    var service_url = Constants.SPECIAL_EYE_TEST 
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getSpecialEyeTestSuccess,
      this.getSpecialEyeTestFailure
    );
  }

  getSpecialEyeTestSuccess = success =>{
    if (success.status == "success") {
      this.setState({
        specialTests : success.data
      })
    }
  }

  getSpecialEyeTestFailure = error =>{
    this.props.showResposeValue('error', error.message)
   }

  addSpecialToList = (event) => {
    let { specialNewItem, specialTest } = this.state;
    let field = this.state;

    if (specialNewItem) {
      specialTest.push({ value: specialNewItem, label: specialNewItem });
      var filterspecialTest = this.removeDuplicates(specialTest);
      field["specialTest"] = filterspecialTest;
      field["specialNewItem"] = "";
      field["isSpecialTest"] = false;
      this.setState({ field });
    }
  };

  removeDuplicates(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);

    return nameListUniqueArray;
  }

  specialTestSearch = (query) => {

    if (query !== "") {
      var serviceUrl = Constants.SPECIAL_EYE_TEST + "?search=" + query;

      OpthamologyService.getInstance().getComplaints(
        serviceUrl,
        this,
        this.specialTestSearchSuccess,
        this.specialTestSearchFailure
      );
    } else {
      var fields = this.state;
      fields["specialSearchList"] = [];

      this.setState({
        fields,
        isSpecialTestSearchView: false,
      });
    }
  }

  specialTestSearchSuccess = (response) => {
    var fields = this.state;
    fields["specialSearchList"] = response.data;
    this.setState({
      fields,
    });
  };

  specialTestSearchFailure = (error) => {
    this.props.showResposeValue('error', error.message)
  };

  onPressButton(key, selectedvalue) {
    // alert(JSON.stringify(selectedvalue))
    if (key == "selectedEyeTest") {
      this.setState({
        selectedEyeTest: selectedvalue,
        selectedSpecialTest : ""
      })
    }else if (key == "selectedSpecialTest") {
      this.setState({
        selectedSpecialTest: selectedvalue,
        selectedEyeTest : ""
      });
    }
  }

  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }

  triggerNewItemAdd = () => {

    let states = this.state;

    states['isEdit'] = true;

    this.setState({ states });
  }

  triggerNewSpecialTestadd = () => {
    let states = this.state;

    states["isSpecialTest"] = true;
    states["isSpecialTestSearchView"] = false;
    this.setState({ states });
  }


  showEditButton() {
    return (
      <View>
        {this.state.isEdit ?
          <View style={styles.AddNewEyeTest} >
            <View style={{ position: "relative", left: "100%", marginTop: "-3.2vw" }}>
              <TouchableOpacity onPress={() => { this.clearEditData()}} style={{ backgroundColor: "red", borderRadius: "50%", padding: ".25vw" }}>
                <Image style={{ height: ".55vw", width: ".5vw", tintColor: color.white }} source={ImagePath.close} />
              </TouchableOpacity>
            </View>
            <TextInput
              editable={!this.state.isLocked}
              underlineColorAndroid="transparent"
              placeholder="Test Name"
              placeholderTextColor="#888888"
              style={[{
                backgroundColor:this.state.isLocked ? color.disableComponentColor : null,
                color: color.black,
                height: 30,
                paddingLeft: 5,
                marginRight: 0,
                flex: 1,
                textAlign: "center",
                borderWidth: 1,
                borderColor: color.black,
                marginLeft:"-1vw"
              },Styles.allButtonBorderRadius]}
              // ref={text => (this.nameInput = text)}
              autoCapitalize="none"
              value={this.state.textBoxValue}
              onChangeText={text => {
                this.setState({ textBoxValue: text })
                // this.getAutoSuggestionApi(text)
              }}
              // onSubmitEditing={this.addMedicationToList.bind(this)}
            />
            <TextInput
              editable={!this.state.isLocked}
              underlineColorAndroid="transparent"
              placeholder="Amount"
              placeholderTextColor="#888888"
              style={[{
                backgroundColor: this.state.isLocked ? color.disableComponentColor : null,
                color: color.black,
                height: 30,
                paddingLeft: 5,
                marginRight: 0,
                flex: 1,
                textAlign: "center",
                borderWidth: 1,
                borderColor: color.black,
                marginLeft: ".5vw"
              }, Styles.allButtonBorderRadius]}
              autoCapitalize="none"
              value={this.state.textBoxAmount}
              onChangeText={text => {
                if(this.numberValidation(text)){
                this.setState({ textBoxAmount: text })
                }
              }} />
            <View>
              <TouchableOpacity style={{ marginTop: ".55vw" }} disabled={this.state.isLocked} onPress={() => { this.addMedicationToList() }}>
                {this.renderTextBtn("Add", true, false)}
              </TouchableOpacity>
            </View>
          </View> :
          <TouchableOpacity style={{alignSelf:'flex-start'}} disabled={this.state.isLocked} onPress={() => { this.triggerNewItemAdd() }}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>
        }

      </View>
    );
  }

  addComplaint = event => {
    let { patientAppointment, selectedEyeTest, id } = this.state
    var data = {
      "appointment_id": patientAppointment.appointment_id,
      "patient_id": patientAppointment.patient_id,
      "test_id": selectedEyeTest.value == "" ? null : selectedEyeTest.value,
      "comments":this.state.comment,
      "clinic_id": this.state.selectedClinic.id,
      "is_transmitted": false,
      "special_test_id": this.state.selectedSpecialTest.value ? this.state.selectedSpecialTest.value == "N/A" ? null : this.state.selectedSpecialTest.value : null,
      "special_test_name": this.state.selectedSpecialTest.value ?  this.state.selectedSpecialTest.value == "N/A" ? this.state.selectedSpecialTest.label : null : null,
    }

    if (id) {
      data["id"] = id
    }

    var service_url = Constants.EYE_TEST_POST_API

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
  }

  getComplaintsSuccess = success => {
    if (success.status == "success") {
      this.props.refreshData(pageName);
      this.props.showResposeValue("success", success.message)
      this.clearComplaint()
    } else {
      this.props.showResposeValue("error", success.message)
    }
  }
  getComplaintsFailure = error => {
    this.props.showResposeValue("error", error.message)
  }
  clearComplaint = event => {

    let states = this.state;
   
    // states['frequentEyeTest']= [],
    states["id"]=""
    states["selectedEyeTest"]=""
    states["isEdit"]=false
    states["textBoxValue"]=""
    states["textBoxAmount"]=""
    states["getAutoSuggestionApiResponce"]=[]
    states["comment"]=""
    states["selectedSpecialTest"] = ""

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }
  searchDescriptionMapping(value) {
    
    let { searchSuggestionList } = this.state;
    let tempDescList = this.state.frequentEyeTest
    if (tempDescList.length > 0 && value) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {
        if (tempDescList[i].label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchSuggestionList = searchListTemp;
    } else {
      searchSuggestionList = this.state.frequentEyeTest;
    }
    this.setState({
      searchSuggestionList
    })
  }
  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  renderSuggesionList = () => {
    return(
      <View>
      {this.state.searchTestOpen &&  this.state.searchTest.length > 0 ? <View style={styles.SuggListMainView}>

        <ScrollView
          style={styles.SuggScrollview}
        >
          { this.state.searchTest.length > 0 && this.state.searchSuggestionList && this.state.searchSuggestionList.length > 0 && this.state.searchSuggestionList.map((list, index) =>
            <View style={{ height: '1.95vw' }}>
              <TouchableOpacity key={index}
                style={{ marginLeft: '0.32vw', marginTop: '0.32vw' }}
                onPress={()=>{
                  var testData = []
                  var prepareData = { value: list.value, label: list.label }
                  if(this.state.testData){  
                    this.state.testData.push(prepareData)
                  } 
                  this.setState({
                    selectedEyeTest : prepareData,
                    searchTest : list.label,
                    searchTestOpen : false,
                    selectedSpecialTest : ""
                  },()=>{   
                    this.setState({searchTest: ""})             
                  })

                }}
              >
                <Text style={{fontSize:'0.91vw'}}> { list.label }</Text>

              </TouchableOpacity>
            </View>
          )}

        </ScrollView>

      </View> : null}</View>

    )
  }
  renderSearchTest() {
    var { searchTestOpen, searchTest } = this.state
    return (
      <View>
        <Text style={styles.SearchTestText}>{'Search Description'}</Text>
        <TextInput

          style={styles.SearchTestTextInput} value={
            this.state.searchTest
          }
          onFocus={() => {
            searchTestOpen = true;
           this.setState({
            searchTestOpen
           })

          }}
          onChangeText={(text) => {
            if((text).trim() != ""){
              searchTestOpen = true;
              searchTest = text;
              this.setState({
                searchTest, 
                searchTestOpen
              },()=>{
                this.searchDescriptionMapping(searchTest);

              })

            } else {
              searchTestOpen = false;
              searchTest = text;
              this.setState({
                searchTestOpen,
                searchTest,
              }, () => {
                this.searchDescriptionMapping(searchTest);
              })
            }
          }}
        />
      </View>
    )
  }
  render() {
    var frequentEyeTest = this.removeDuplicate(this.state.testData)

    return (
      <TouchableWithoutFeedback onPress={()=>{
        this.setState({
          getAutoSuggestionApiResponce:[]
        })
      }}>
        <View>
          <DoctorNotesCommonRightSideHeader title={"Investigation"} clearAllData={this.clearComplaint.bind(this)} />
        <CommonSectionHeader heading={"Eye Test"} />
          {this.renderSubHeadingBorder("", false, true)}
          {this.renderSearchTest()}
          {this.renderSuggesionList()}
          <View style={styles.EyeTestBtnMainView}>
            {
             frequentEyeTest && frequentEyeTest.map((item, index) => {
                return (
                  <View style={styles.EyeTestBtnView} key={index}>                   
                    <CommonButton
                      disable={this.state.isLocked}
                      item={item}
                      selectedvalue={this.state.selectedEyeTest}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={"selectedEyeTest"} />

                  </View>
                )
              })
            }
            {/* {this.showEditButton()} */}
          </View>

          <View style={styles.CommtsView}>
            <CommonSectionHeader heading={"Special Test"} />
            <View style={{ flexDirection: "row", flexWrap: "wrap", zIndex: -1, marginTop:"1vw" }}>
              {this.state.specialTests.map((item, index) => {
                var data = { label: item.special_test_name, value: item.special_test_id };
                return (
                  <View style={styles.CommonBtnView} key={index}>
                    <CommonButton
                      disable={this.state.isLocked}
                      item={data}
                      selectedvalue={this.state.selectedSpecialTest}
                      butttonText={data.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.onPressButton.bind(this)}
                      buttonKey={"selectedSpecialTest"}
                    />
                  </View>
                );
              })}
            </View>
          </View>

          {this.state.isSpecialTest ? (
            <View
              style={[{
                height: '2vw',
                marginTop: '0.6vw',
                width: "100%",
                zIndex: 100,
              }, Styles.allButtonBorderRadius]}
            >
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 0.8 }}>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="type here"
                    maxLength={40}
                    placeholderTextColor={color.black}
                    style={[styles.LabTextInput, {
                      color: color.black,
                      borderColor: color.black,
                    }]}
                    ref={(text) => (this.nameInput = text)}
                    autoCapitalize="none"
                    value={this.state.specialNewItem}
                    onChangeText={(text) => {
                      this.setState({
                        selectedSpecialTest: "",
                        specialNewItem: text,
                      });
                      this.specialTestSearch(text);
                    }}
                  />
                </View>
                <TouchableOpacity
                  style={{
                    flex: 0.2,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: color.themeDark,
                    borderColor: color.black,
                    borderWidth: 1,
                    borderRadius: 4,
                    marginLeft: '0.5vw'
                  }}
                  onPress={() => {
                    var { specialTests, specialNewItem } = this.state;
                    if (specialNewItem) {

                      var data = { value: "N/A", label: specialNewItem }
                      var item = { special_test_id: "N/A", special_test_name: specialNewItem }
                      for (let i = 0; i < specialTests.length; i++) {
                        if (specialTests[i].id == "N/A") {
                          specialTests.splice(i, 1)
                        }
                      }

                      specialTests.push(item);
                      this.setState({
                        specialTests,
                        selectedSpecialTest: data,
                        specialNewItem: "",
                        specialSearchList: [],
                        isSpecialTestSearchView: true,
                      }),
                        this.addSpecialToList(this);
                    }
                  }}
                >
                  <Text style={{ fontSize: '0.9vw' }}>{"Save"}</Text>
                </TouchableOpacity>
              </View>
              {this.state.isSpecialTestSearchView === false ? (
                <View
                  style={[styles.TextBoxSearch, {
                    backgroundColor: this.state.specialNewItem ? color.white : "",
                    maxHeight: this.state.specialNewItem ? "" : 0,
                  }]}
                >
                  <ScrollView
                    showsHorizontalScrollIndicator={false}
                    style={{ maxHeight: '14.4vw' }}
                  >
                    <FlatList
                      data={this.state.specialSearchList}
                      renderItem={({ item }) => (
                        <View
                          style={styles.ItemView}
                        >
                          <View style={{ width: "100%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                var { specialTests } = this.state;
                                var data = { value: item.special_test_id, label: item.special_test_name };
                                specialTests.push(item);
                                this.setState({
                                  specialTests,
                                  selectedSpecialTest: data,
                                  specialNewItem: "",
                                  specialSearchList: [],
                                  isSpecialTestSearchView: true,
                                  selectedEyeTest : ""
                                }),
                                  this.addSpecialToList(this);
                              }}
                            >
                              <Text style={{ fontSize: '0.9vw' }}>{item.special_test_name}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                      enableEmptySections={true}
                    />
                  </ScrollView>
                </View>
              ) : null}
            </View>
          ) : <TouchableOpacity style={{ alignSelf: 'flex-start' }} disabled={this.state.isLocked} onPress={() => { this.triggerNewSpecialTestadd() }}>
            {this.renderIconBtn("plus", "New", false, "key")}
          </TouchableOpacity>}

          <View style={styles.CommtsView}>
            <CommonSectionHeader heading={"Comments"} />
            <View style={{ marginTop: '0.75vw' }}>
              {this.renderTextFieldView("Comments", "comment", this.state.comment, true, 4)}
            </View>
          </View>

          <View style={styles.AddComplaintView}>
            <TouchableOpacity disabled={this.state.isLocked} onPress={this.addComplaint.bind(this)}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  SearchTestText:{
    zIndex: 2, 
    fontSize: '0.8vw', 
    position: "absolute", 
    marginLeft: '0.65vw', 
    backgroundColor: color.themeShade, 
    paddingLeft: '0.32vw', 
    paddingRight: '0.32vw', 
    color: "#888888"
  },
  SearchTestTextInput:{
    borderColor: "#CDD1D5",
    borderRadius: '0.26vw',
    borderWidth: 1,
    width: "100%",
    height: '2.28vw',
    padding: '0.97vw',
    marginVertical: '0.52vw',
    fontSize:'0.9vw'
  },
  SuggListMainView:{
    zIndex: 1, 
    position: 'absolute', 
    marginLeft: '0.65vw', 
    maxHeight: '16.28vw', 
    minWidth: '19.53vw', 
    maxWidth: '22.79vw'
  },
  SuggScrollview:{
    height: '12.69vw', 
    marginBottom: '0.65vw', 
    flex: 1,
    backgroundColor: 'white', 
    borderColor: color.lightGray, 
    borderWidth: 1, 
    borderRadius: '0.26vw'
  },
  EyeTestBtnMainView:{
    flexDirection: 'row', flexWrap: 'wrap',zIndex: -1
  },
  EyeTestBtnView:{
    marginBottom: '0.65vw', 
    marginRight: '0.65vw', 
    flexDirection: "row", 
    flexWrap: "wrap"
  },
  CommtsView:{
    paddingVertical: '0.65vw', 
    zIndex: -1
  },
  AddComplaintView:{
    marginTop: '1.3vw', 
    zIndex: -2, 
    alignSelf: "center"
  },
  AddNewEyeTest:{
    height: "3.25vw", 
    width: "100%", 
    backgroundColor : color.white, 
    display:"flex", 
    flexDirection : "row", 
    alignItems:"center", 
    borderWidth: 1, 
    borderColor:color.lightGray, 
    borderStyle: "solid",
    borderRadius:".3vw",
    paddingHorizontal:".3vw",
    zIndex: -5
  },
  CommonBtnView: { 
    marginBottom: '0.6vw', 
    marginRight: '0.6vw' 
  },
  LabTextInput: {
    height: '2vw',
    paddingLeft: '0.3vw',
    marginRight: 0,
    flex: 1,
    textAlign: "center",
    borderWidth: 1,
    borderRadius: 4,
    fontSize: '0.9vw'
  },
  TextBoxSearch: {
    borderRadius: 10,
    width: "90%",
    justifyContent: "center",
    alignContent: "center",
    padding: '0.6vw',
    marginLeft: '0.6vw',
    marginTop: '2vw',
    position: "absolute"
  }
})