import React, { Component } from 'react'
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
  Platform,
  Picker,
  TextInput,
  TouchableOpacity
} from "react-native";
import { color } from "../../../styles/Color";
import { CommonHistorySectionHeader, CommonButton } from '../BaseComponent';
import CommonDateFilter from '../BaseComponent';
import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Style from "../../../styles/Style";
import Pagination from '../../../ui/doctor/common/Pagination';
import { Tooltip } from 'antd';
import AsyncStorage from '../../../AsyncStorage';
import { DatePicker } from 'antd';


const screenHeight = Dimensions.get("window").height;
const platform = Platform.OS;
export default class RefferalReport extends Component {
  constructor(props) {
    super(props)
    let fulldate = new Date();
    let converted_date = moment(fulldate).format("YYYY-MM-DD");
    let month = moment().format("MMM")
    let year = moment().format("YYYY")
    this.state = {
      data: {},
      doctorandservices: {},
      totalAmount: '',
      selectedDoctorAction: '',
      selectedServiceAction: '',
      isClear: false,
      isClearInt: 0,
      filterDate: {},
      filterData: {
        fromDate: converted_date,
        toDate: converted_date
      },
      isServiceDetailed: false,
      valueData: [],
      isPaginated: false,
      selectedServiceInput: "",
      ServiceInput: "",
      searchServicesList: [],
      ListOpen: false, 
      selectedServiceType: "",
      serviceTypeList: [],
      isAdminAccess: false,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      referredByList: [],
      total_Amount: 0,
      yearValue: year,
      monthvalue: month
    }
  }

  async componentDidMount() {
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
    var loggedInDataObj = JSON.parse(loggedInData)
    this.setState({
      isAdminAccess: loggedInDataObj.is_admin_access
    })
    this.getRefferalList()
    this.getRefferedBy()
  }
  getRefferedBy = () => {

      var serviceUrl = Constants.REFERRED_BY_DEMOGRAPHIC_GET + "?clinic_id=" + this.state.selectedClinicOrDoctor.id

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferedBySuccess,
      this.getRefferedByFailure
    );

  }

  getRefferedBySuccess = response => {
    if (response.status == "success") {
      this.setState({
        referredByList : response.data
      })
    }
  }
  getRefferalList = () => {
    this.setState({
      isClear : false,
      valueData: [],
      data: [],
      total_Amount: 0
    })
    // var selectedMonth = (this.state.monthvalue && this.state.yearValue ? (this.state.monthvalue) + ', ' + (this.state.yearValue): '')
    var serviceUrl = Constants.FO_REFERRAL_DOC_REPORT + '?from_date='+ this.state.filterData.fromDate +
    '&to_date='+ this.state.filterData.toDate + "&referral_doctor=" + this.state.selectedDoctorAction;
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefferalListSuccess,
      this.getRefferalListFailure
    );
  }

  getRefferalListSuccess = success => {
    if (success.status == "success") {
      this.setState({
        data: success.data,
        total_Amount: success.total_bill_amount
      })

    }
  }

  getRefferalListFailure = (error) => {
    this.props.showResposeValue('error', error.message)

  }
  getServiceTypeList = () => {
    var serviceUrl = Constants.SERVICE_TYPE_LIST + "?service_name=" + this.state.selectedServiceInput;
    
    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getServiceTypeSuccess,
      this.getServiceTypeFailure
    );
  }

  getServiceTypeSuccess = success => {
    if (success.status == "success") {
       this.setState({
        serviceTypeList: success.data
       })
    }
  }

  getServiceTypeFailure = error => {
    console.log("err getBillingListSuccess" + JSON.stringify(error))

  }

  showSelectedFilteredDate = (date) => {
    var states = this.state;
    var { filterData } = this.state;
    states["filterData"] = date
    filterData["fromDate"] = date.fromDate;
    filterData["toDate"] = date.toDate
    this.setState({ states, filterData })
  }

  renderDateFilter = () => {
    return (
      <View>
        {this.state.isClear && this.state.isClearInt === 0 ? null :
          this.state.isClear && this.state.isClearInt === 1 ?
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} /> :
            <CommonDateFilter
              removeObject={!this.state.isAdminAccess ? Constants.remove_Obj_user : "all"}
              defaultSelectedDateFilter={"Today"}
              startDate={this.state.filterData.fromDate}
              endDate={this.state.filterData.toDate}
              clearPress={this.state.isClear}
              showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
              filterDate={this.state.filterDate} />
        }
      </View>
    )
  }

  renderPickerDoctorName() {
    return (
      <View style={{zIndex: -1 }}>
        <Picker

          selectedValue={this.state.selectedDoctorAction}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor:
              this.state.errorColor && this.state.mandatoryInformation.gender == "" ? "red" : '#CDD1D5'
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedDoctorAction"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Doctor Name' value='' />
          {(this.state.referredByList && this.state.referredByList).length > 0 ?
            this.state.referredByList.map(list =>
              <Picker.Item label={list.name} value={list.id} />

            )

            : null
          }

        </Picker>
      </View>
    )
  }

  renderPickerServiceType() {
    return (
      <View>
        <Picker
          selectedValue={this.state.selectedServiceType}
          enabled = {this.state.serviceTypeList && this.state.serviceTypeList.length > 0 ? true : false}
          style={[Style.pickerView, {
            marginTop: 5, width: '14.64vw', fontSize: '1vw', borderColor: '#CDD1D5'
          }]}
          itemStyle={{}}
          onValueChange={(itemvalue) => {
            var states = this.state;
            states["selectedServiceType"] = itemvalue;
            this.setState({ states });
          }}
        >
          <Picker.Item label='Select Service Type' value='' />
          { this.state.serviceTypeList.length > 0 ?
            this.state.serviceTypeList.map(list =>
              <Picker.Item label={list.type} value={list.type} />

            )

            : null
          }

        </Picker> 
      </View>
    )
  }
  renderServiceList = (value) => {
    let { searchServicesList } = this.state;
    let tempDescList = this.state.doctorandservices.services
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].service_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      searchServicesList = searchListTemp;
    } else {
      searchServicesList = this.state.doctorandservices.services;
    }
    this.setState({
      searchServicesList
    })
  }

  renderServiceAutoSearch = () => {
    return (

      <View style={{ zIndex: 2 }}>
        <TextInput
          placeholder='Select Service Name'
          style={{ marginTop: '5px', height: '2vw', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", borderRadius: '4px', width: '14vw', fontSize: '1vw', paddingLeft: '.8vw' }}
          value={this.state.ServiceInput}
          onChangeText={(text) => {
            this.setState({ ServiceInput: text, selectedServiceInput: '' }, () => {
              this.renderServiceList(text);
              this.setState({ ListOpen: this.state.ServiceInput.length > 0 ? true : false, selectedServiceType: "", serviceTypeList: [] })
            })
          }
          }
        />
        {this.state.ServiceInput.length > 0 && this.state.ListOpen && this.state.searchServicesList.length > 0 ?
          <ScrollView showsVerticalScrollIndicator={false} style={{ position: 'absolute', backgroundColor: 'white', borderWidth: 1, borderColor: "rgb(205, 209, 213)", marginTop: "2.3vw", maxHeight: '12vw', zIndex: 3, width: '14.64vw', borderRadius: '4px' }}>
            {
              this.state.searchServicesList.map(name =>
                <TouchableOpacity
                  style={{ marginTop: '.5vw', paddingLeft: '.8vw' }}
                  onPress={
                    event => {
                      this.setState({ ServiceInput: name.service_name, selectedServiceInput: name.service_name, ListOpen: false }, () => {
                        if(this.state.ServiceInput.length > 0) {
                          this.getServiceTypeList()
                        }
                      })
                    }
                  }
                >
                  <Text style={{ fontSize: '1vw' }}>
                    {name.service_name}
                  </Text>
                </TouchableOpacity>
              )
            }
          </ScrollView>
          : null}
      </View>
    )
  }

  onPressButton(key, value) {
    if (key == "save") {
      this.getRefferalList()
    }
    else if (key == "clear") {

      let fulldate = new Date();
      let converted_date = moment(fulldate).format("YYYY-MM-DD");
      let month = moment().format("MMM")
      let year = moment().format("YYYY")
      var states = this.state
      // states['valueData'] = []
      this.setState({
        selectedAction: "",
        selectedServiceInput: "",
        ServiceInput: "",
        selectedServiceType: "",
        serviceTypeList: [],
        filterData: {
          fromDate: converted_date,
          toDate: converted_date
        },
        isClear: true, 
        isClearInt: 1,
        selectedDoctorAction: "", 
        monthvalue:month,
        yearValue: year
      }, () => {
        this.getRefferalList();
        this.setState({
          isClear : false
        }, () => {this.renderDateFilter()})
      })
    } else if (key == "print") {
      this.PrintPatienToken()
    }
  }
  PrintPatienToken = () => { 
    var selectedMonth = (this.state.monthvalue && this.state.yearValue ? (this.state.monthvalue) + ', ' + (this.state.yearValue): '')
   var service_url = Constants.FO_REFERRAL_DOC_REPORT + '?from_date='+ this.state.filterData.fromDate +
   '&to_date='+ this.state.filterData.toDate +"&referral_doctor=" + this.state.selectedDoctorAction + '&export_type=pdf'
   OpthamologyService.getInstance().documentUploadGet(
    service_url,
    this.PrintPatienTokenSuccess,
    this.PrintPatienTokenFailure,
    "pdf"
   );
}

PrintPatienTokenSuccess = (response) => {
if (response) {
  const file = new Blob(
    [response.data],
    { type: 'application/pdf' });
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}
}

PrintPatienTokenFailure = error => {

    console.log(error);
  };
  // Print

  paginationChangedValues = (data) => {
    this.setState({
      valueData: data
    })
  }


  // renderDateFilter = () => {
  //   return (
  //    <View>
  //     <DatePicker
  //       disabledDate={current => {
  //         return current && current > moment().add(0, "days");
  //       }}
  //       defaultValue={this.state.monthvalue ? moment(this.state.monthvalue, "MMM-YYYY") : moment()}
  //       value={this.state.monthvalue ? moment(this.state.monthvalue, "MMM-YYYY") : moment()}
  //       onChange={(date) => {
  //         this.setState({
  //           yearValue: moment(date).format('YYYY'),
  //           monthvalue: moment(date).format('MMM'),
  //         })
  //       }}
  //       clearIcon={false}
  //       placeholder={""}
  //       format="MMM-YYYY"
  //       picker="month"
  //       style={{
  //         zIndex: -1,
  //         width: "10vw",
  //         borderColor: "#CDD1D5",
  //         height: '2.3vw',
  //         borderRadius: '.7vw',
  //         borderWidth: 1,
  //         marginTop: '.3vw',
  //         fontSize: '1.2vw'
  //       }}
  //     /> 
  //     </View>
  //   )
  // }



  render() {
    var actions = [
      { label: "Search", value: "save" },
      { label: "Clear", value: "clear" },
      { label: 'Print', value: 'print' }
    ]
    console.log(this.state.monthvalue, this.state.yearValue)
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: "65%", height: screenHeight - 70, backgroundColor: 'white' }}>
          <View style={{ padding: '1vw' }}>
            <View style={{ width: "100%", marginTop: "1.5vw", paddingHorizontal: "0.35" }} >
              <CommonHistorySectionHeader
                heading1={"Doctor Name"}
                heading2={"Mobile number"}
                heading3={"Total Patient Count"}
                heading4={"Total Bill Amount"}
                noOfColumn={4}
                columnSize={[0.25, 0.25, 0.25, 0.25]}
              />
              <ScrollView showsVerticalScrollIndicator={false} style={styles.TableBody}>
                <View >
                  {this.state.valueData && this.state.valueData.length > 0 ?
                    this.state.valueData.map(list => {
                      var date = moment(list.created_date__date).format("DD-MM-YYYY")
                      return (
                        <View style={{ flexDirection: "row", paddingVertical: '0.8vw', width: "100%", borderBottomWidth: 1, borderBottomColor: color.lightGray }}>
                          <View style = {{ paddingHorizontal: 20, flex: 0.25 }}> 
                            <Tooltip placement="topLeft" title={list.doctor_name}>
                              <Text style={{ fontSize: "1vw"}}> {list.doctor_name &&list.doctor_name.length > 14 ? list.doctor_name.slice(0, 14) + ".." : list.doctor_name} </Text>
                            </Tooltip>
                          </View>
                          <View style={{flex: 0.25}}><Text style={{ fontSize: "1vw"}}>{list.mobile_no}</Text></View>
                          <View style={{flex: 0.25}}><Text style={{ fontSize: "1vw" }}>{list.patient_count} </Text></View>
                          <View style={{flex: 0.25}}><Text style={{ fontSize: "1vw"}}>{list.total_amount}</Text></View>
                        </View>)
                    }
                    ) :
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
                      <Text style={{ color: color.placeholder, marginTop: 50, fontSize: '1vw' }}>{"No records to be shown"}</Text>
                    </View>
                  }</View>
              </ScrollView>
            </View>
            <View style={{ marginLeft: 20 }}>
              {this.state.data && this.state.data.length > 0 ?
                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this.state.data)} totalItems={this.state.data} />
                : null
              }

            </View>
          </View>
        </View>

        {/* ============================ */}


        <View style={{ width: "35%", height: screenHeight - 70, backgroundColor: color.themeShade, padding: '1vw' }}>
          <View>
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Statistics</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: 15 }}>
              <View style={{
                justifyContent: 'center',
                alignSelf: 'center',
                shadowColor: "#090F36",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.6,
                shadowRadius: 3,
                elevation: 5,
                backgroundColor: color.white,
                borderRadius: 4,
                paddingHorizontal: 15,
                minWidth: '8vw',
                height: '4vw',
                marginRight: 20
              }}>
                <View>
                  <Text style={{ fontSize: '1.4vw', fontWeight: '500' }}>₹ {this.state.total_Amount ? this.state.total_Amount : 0}</Text>
                <Text style={{ fontSize: '0.87vw', fontWeight: '400', textAlign: 'center', marginTop: '0.5vw'}}>{"Total Amount"}</Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={[styles.headerContainer, { marginBottom: '1.5vw' }]}>
                <Text style={styles.headerText}>Filter</Text>
              </View>

              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", minWidth: "10.98vw",  flexDirection: "row", alignItems: "center", flex:1, justifyContent: "space-between", zIndex: 10 }}>
                    {this.renderDateFilter()}
                    {this.renderPickerDoctorName()}
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginVertical: 20, zIndex: -2 }}>
                {
                  actions.map((item, index) => {
                    return (
                      <CommonButton
                        item={{}}
                        selectedvalue={{}}
                        butttonText={item.label}
                        buttonType={"theme"}
                        buttonIcon={""}
                        rightIcon={false}
                        buttonAction={this.onPressButton.bind(this)}
                        buttonKey={item.value} />
                    )
                  })
                }
              </View>
            </View>
          </View>
        </View >
      </View>
    )
  }
}

const styles = StyleSheet.create({
  TableBody: {
    height: '72vh', overflow: "scroll"
  },
  tableHeader: {
    backgroundColor: "rgb(238, 238, 238)", display: 'flex', width: '100%', flexDirection: 'row', paddingVertical: '1vw', paddingHorizontal: '1vw', marginTop: '2.3vw'
  },
  headerContainer: {
    padding: '.5vw',
    backgroundColor: "rgb(238, 238, 238)",
    marginTop: '1.5vw'
  },
  headerText: {
    fontSize: "1vw",
    fontWeight: 500,
    marginLeft: ".5vw"
  }
});
