import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, TextInput, StyleSheet } from "react-native";
import OpthamologyService from "../../../../../../network/OpthamologyService";
import { color } from "../../../../../../styles/Color";
import { Constants } from "../../../../../../utils/Constants";
import { CommonButton, CommonHistorySectionHeader } from "../../../../BaseComponent";
import Pagination from "../../../../common/Pagination";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";

export default class IolStockList extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            iolStockList: [],
            iolStockListAfterPagination: [],
            current_page: 1,
            records_per_page: 10,
            searchIol:'',
        }
    }

    componentDidMount(){
        this.getStockDetails()
    }

    getStockDetails = () => {
        this.setState({
            current_page: 1,
            records_per_page: 10,
            iolStockListAfterPagination: [],
            iolStockList: []
        })
        var url = Constants.OT_INVENTORY_IOL_STOCK_LIST + "?brand_name=" + this.state.searchIol
        OpthamologyService.getInstance().getComplaints(
            url,
            this,
            this.getStockDetailsSuccess,
            this.getStockDetailsFailure
        );
    }

    getStockDetailsSuccess = (response) => {
        if (response && response.status == "success") {
            this.setState({
              iolStockList: response.data
            })
        }
    }

    renderTableHeader = () => {
        return (
            <CommonHistorySectionHeader
              heading1={"S.No"}
              heading2={"Brand Name"}
              heading3={"Model Name"}
              heading4={"IOL Type"}
              heading5={"IOL Power"}
              heading6={"Batch No"}
              heading7={"Quantity"}
              heading8={"Expiry Date"}
              heading9={"MRP/Qty"}
              heading10={"Box No"}
              columnSize={[0.03, 0.14, 0.13, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}  //total value is == of 1
              noOfColumn={10}
            />
        )
    }

    renderTableData = () => {
        return(
            <View>
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '55vh' }}>
            {
              this.state.iolStockListAfterPagination && this.state.iolStockListAfterPagination.map((item, index) => {
                let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page) + (index + 1)
      
                return (
                  <TouchableOpacity style={styles.dataContainer} onPress={() => {
                    // this.props.changePage("")
                  }}>
                    <View style={{ flex: 0.03, marginHorizontal: 20}}><Text style={[styles.TableDataText,{}]}>{sno}</Text></View>
                    <View style={{ flex: 0.14 }}><Text style={styles.TableDataText}>{item.iol_product__brand_name}</Text></View>
                    <View style={{ flex: 0.13 }}><Text style={styles.TableDataText}>{item.iol_product__model_name}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.iol_product__iol_type__iol_type}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.iol_product__iol_power}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.batch_number}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.stock_in_quantity}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.expiry_date ? moment(item.expiry_date).format("MM-YYYY") : ""}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.mrp_price_per_qty}</Text></View>
                    <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.physical_box_number}</Text></View>
                  </TouchableOpacity>
                )
            })}
            </ScrollView>
            </View>
        )
    }

    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
         current_page: current_page,
         records_per_page: records_per_page
        })
    }

    paginationChangedValues(data) {
        this.setState({
        iolStockListAfterPagination: data
        })
    }

    renderPaginationView() {
        return (
            <View style={{ marginHorizontal: 20 }}>
            {
                this.state.iolStockList && this.state.iolStockList.length > 0 ?
                <Pagination
                    paginationChangedValues={this.paginationChangedValues.bind(this)}
                    totalItems={this.state.iolStockList}
                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                /> : null
            }
            </View>
        )
    }
    

    render(){
        return(
            <View>
            <View style={{flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 20, paddingVertical: 10}}>
                <View style={{flexDirection: "row"}}>
                <TouchableOpacity 
                style={{marginRight: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw', color: color.themeDark}}>IOL Stock List</Text>
                </TouchableOpacity>
                <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>{"-"}</Text>
                <TouchableOpacity
                onPress={() => {
                    this.props.changePage("iolPoList")
                }} 
                style={{marginHorizontal: '1vw'}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: '1vw'}}>Purchase Order List</Text>
                </TouchableOpacity>
                </View>
                <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: "row", zIndex: 0, marginRight: 20}}>
                <TextInput
                value = {this.state.searchIol}
                onChangeText={(text) => {
                    this.setState({
                        searchIol: text
                    })
                }}
                style={{borderWidth: 1, height: '5vh', width: '13vw', paddingHorizontal: 3, borderColor: color.lightGray, fontSize: '1vw' }}
                placeholder="Search brand name..."
                />
                <TouchableOpacity 
                style={{height: "5vh", width: "5vh", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                onPress={() => {
                    this.getStockDetails()
                }}>
                    <Icon name='search' size={15} color={color.white}></Icon>
                </TouchableOpacity>
                </View>
                <View>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"+ New IOL"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.props.changePage("addNewIOL")
                }}
                buttonKey={"addIOLpo"} />
                </View>
                <View style={{marginLeft: '2vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"+ Direct PO"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.props.changePage("addIolDirectPO")
                }}
                buttonKey={"IOLdirectPo"} />
                </View>
                </View>
            </View>
            <View style={{padding: 20}}>
            {this.renderTableHeader()}
            {this.renderTableData()}
            {this.renderPaginationView()}
            </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableDataText:{
        fontSize:'1vw'
    },
    dataContainer: {
        flexDirection: 'row',
        borderBottomColor: color.lightGray,
        borderBottomWidth: 1,
        paddingVertical: "0.75vw",
        justifyContent: 'center',
        flex: 1
        
    },
})
