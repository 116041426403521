import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput, ScrollView, Image, StyleSheet, Picker } from "react-native";
import { color } from "../../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Select, TimePicker } from 'antd';
import moment from "moment";
import { Constants } from '../../../../utils/Constants';
import OpthamologyService from '../../../../network/OpthamologyService';


const MealType = [
    {
        value: '',
        label: 'Select meal Type *'
    },
    {
        value: 'early morning',
        label: 'Early morning',
    },
    {
        value: 'breakfast',
        label: 'Breakfast',
    },
    ,
    {
        value: 'mid morning',
        label: 'Mid morning',
    },
    {
        value: 'lunch',
        label: 'Lunch',
    },
    {
        value: 'evening',
        label: 'Evening',
    },
    {
        value: 'dinner',
        label: 'Dinner',
    },
    {
        value: 'bedtime',
        label: 'Bedtime',
    },
]
const FoodType = [
    {
        value: 'Food',
        label: 'Food'
    },
    {
        value: 'Liqiud',
        label: 'Liquid'
    },
]
const UnitList = [
    {
        value: 'Nos',
        label: 'Nos'
    },
    {
        value: 'ml',
        label: 'ml'
    },
    {
        value: 'mg',
        label: 'mg'
    },
    {
        value: 'kg',
        label: 'kg'
    },
    {
        value: 'litre',
        label: 'litre'
    },
    {
        value: 'katori',
        label: 'katori'
    }
]


export default class NutritionChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planName: "",
            planAgeFrom: "",
            planAgeTo: "",
            patientType: "",

            CreateTemplateClicked: false,
            SelectedDayIndex: 0,
            SelectedTimeIndex: 0,
            SelectedSessionIndex: 0,
            TempSelectedTimeIndex: 11,
            SelectedTemplateId: 1,

            planNameErrorText: '',
            isPlanNameExists: false,
            planNameCompleted: false,
            TimeErrorText: '',
            TimeCompleted: false,
            ListErrorText: "",
            ListCompleted: false,
            editClicked: false,

            Time: "",
            MealType: '',
            TimeDataValue: '',

            FoodType: 'Food',
            ProductTextInput: '',
            QuantityTextInput: '',
            UnitTextInput: 'Nos',
            PointsToRemember: '',
            AlwaysAvoid: '',
            isPlanList: 0,

            AllTemplateList: [],

            Datas: [
                {
                    "day_count": "Day 1",
                    "list": [
                        {
                            "session": "morning",
                            "times": []
                        },
                        {
                            "session": "afernoon",
                            "times": []
                        },
                        {
                            "session": "night",
                            "times": []
                        }
                    ]
                }
            ]
        }
    }

    componentDidMount() {
        this.getMealPlanTemplates()
    }

    getMealPlanTemplates = () => {
        var serviceUrl = Constants.MEAL_PLAN_FO;

        OpthamologyService.getInstance().getComplaints(
            serviceUrl,
            this,
            this.getMealPlanSuccess,
            this.getMealPlanFailure
        );
    }

    getMealPlanSuccess = (response) => {
        if (response.status == "success") {
            this.setState({
                AllTemplateList: response.data
            }, () => {
                if (response.data && response.data.length > 0) {
                    this.setState({
                        SelectedTemplateId: response.data[0].id
                    })
                }
            })
        }
        else {
            this.props.showResposeValue("error", response.message)
        }
    }
    getMealPlanFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }

    renderTextBox = (key) => {
        var states = this.state
        return (
            <View style={styles.renderTextBoxContainer}>
                <TextInput
                    value={states[key]}
                    style={[styles.TextInputStyles, { height: '1.5vw', padding: "1vw", }]}
                    onChangeText={(text) => {
                        if ((key == "planAgeFrom") || (key == "planAgeTo")) {
                            var isValid = this.numberValidation(text)
                            if ((isValid || text === "") && text.length < 4) {
                                states[key] = text
                                this.setState({ states })
                            }
                        }
                        else {
                            states[key] = text
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </View>
        )
    }

    PrintTemplate = () => {
        var serviceUrl = Constants.MEAL_PLAN_FO + "?export=pdf&id=" + this.state.SelectedTemplateId;
        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    }

    getPrintSuccess = (response) => {
        if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    };


    getPrintFailure = error => {

        console.log(error);
    };

    renderPageLeft = () => {
        return (
            <View>
                <View style={styles.LeftTopView}>

                    <Text style={styles.SelectTempLabel}>Select Template</Text>

                    <Picker

                        style={[styles.pickerView, {
                            width: '14.64vw', fontSize: '1vw', marginLeft: '0.5vw'
                        }]}
                        place
                        selectedValue={this.state.SelectedTemplateId}
                        onValueChange={(itemvalue) => {
                            this.setState({ SelectedTemplateId: itemvalue })
                        }}
                    >
                        {
                            this.state.AllTemplateList.length > 0 ?
                                this.state.AllTemplateList.map((item) =>
                                    <Picker.Item label={item.data.planName} value={item.id} />

                                ) : null
                        }

                    </Picker>

                    <TouchableOpacity
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: color.themeDark, borderRadius: '0.26vw', paddingLeft: '1vw', paddingRight: '1vw', backgroundColor: color.themeDark }}
                        onPress={() => { this.PrintTemplate() }}
                    >
                        <Icon name={"print"} size={'1.3vw'} color={color.white} style={{ marginRight: '0.6vw' }} />
                        <Text style={{ color: color.white, fontWeight: '500', fontSize: '0.9vw' }}>{"Print"}</Text>
                    </TouchableOpacity>



                </View>
                <View>
                    {
                        this.state.AllTemplateList.length > 0 ?
                            this.state.AllTemplateList.map((item) =>
                                this.state.SelectedTemplateId == item.id ?
                                    <View>
                                        <View  >
                                            <Text style={styles.TemplateNameText}>{item.data.planName}</Text>
                                            <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw' }}>
                                                <Text style={{ marginLeft: '15vw', fontSize: '0.9vw' }}>Morning Before 12 PM</Text>
                                                <Text style={{ marginLeft: '6vw', fontSize: '0.9vw' }}>Noon Before 4 PM </Text>
                                                <Text style={{ marginLeft: '7vw', fontSize: '0.9vw' }}>Night Before 10 PM</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <ScrollView style={styles.MainScrollLeft} showsHorizontalScrollIndicator={false}>
                                                <View style={styles.LeftMealChartMainView}>{

                                                    item.data.Days.map((list, index) => (
                                                        <View style={styles.MainChartView}>
                                                            <View style={styles.DayCountView}>
                                                                <View style={styles.DayCountWrapper}>
                                                                    <Text style={styles.DayCountText}>{list.day_count}</Text>
                                                                </View>
                                                            </View>

                                                            <View style={styles.PlanListBoxesView}>

                                                                <View style={styles.FlexRowView}>


                                                                    {/* ========================================================== */}


                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                                                                        <View >
                                                                            {list.list.length >= 1 ? list.list[0].times.length >= 1 ? list.list[0].times[0].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 2 ? list.list[1].times.length >= 1 ? list.list[1].times[0].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 2 ? list.list[2].times.length >= 1 ? list.list[2].times[0].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>

                                                                    {/* ========================================================== */}


                                                                </View>
                                                                <View style={styles.FlexRowView}>

                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 1 ? list.list[0].times.length >= 2 ? list.list[0].times[1].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 2 ? list.list[1].times.length >= 2 ? list.list[1].times[1].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 3 ? list.list[2].times.length >= 2 ? list.list[2].times[1].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>

                                                                </View>

                                                                {/* ========================================================== */}


                                                                <View style={styles.FlexRowView}>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 1 ? list.list[0].times.length >= 3 ? list.list[0].times[2].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 2 ? list.list[1].times.length >= 3 ? list.list[1].times[2].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                    <ScrollView style={styles.ChartBoxScrollView} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                                                                        <View>
                                                                            {list.list.length >= 3 ? list.list[2].times.length >= 3 ? list.list[2].times[2].plan_List.map((planData) => (
                                                                                <View style={styles.FlexRowView}>
                                                                                    <View style={styles.BoxText1View}>
                                                                                        <Text style={styles.BoxText1Text} >{` ${planData.type}`}</Text>
                                                                                    </View>
                                                                                    <View style={styles.BoxText2View}>
                                                                                        <Text style={styles.BoxText2Text}>{` ${planData.product} ${planData.quantity} ${planData.unit}`}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            )) : null : null}
                                                                        </View>
                                                                    </ScrollView>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    ))
                                                }

                                                </View>
                                            </ScrollView>
                                        </View>
                                    </View>
                                    : null

                            ) : <Text style={{ marginTop: '5vw', marginLeft: '26vw', fontSize: '0.9vw' }}>No Records to show...</Text>}
                </View>
            </View>
        )
    }

    addDays = () => {
        if (this.state.Datas[this.state.Datas.length - 1].list[0].times.length != 0 || this.state.Datas[this.state.Datas.length - 1].list[1].times.length != 0 || this.state.Datas[this.state.Datas.length - 1].list[2].times.length != 0) {
            let DayTemplate = {
                "day_count": `Day ${this.state.Datas.length + 1}`,

                "list": [
                    {
                        "session": "morning",
                        "times": []
                    },
                    {
                        "session": "afternoon",
                        "times": []
                    },
                    {
                        "session": "night",
                        "times": []
                    }
                ]
            }
            // let AddDays = this.state.Datas.concat(DayTemplate)
            this.setState({
                Datas: this.state.Datas.concat(DayTemplate),
                ListErrorText: "",
                ListCompleted: false,
                TimeErrorText: "",
                TimeCompleted: false,
                editClicked: false,
            })
        }
        else {
            this.setState({
                TimeErrorText: `Please Select Values on Day ${this.state.Datas.length}`
            })
        }
    }

    ResetClicked = () => {

        this.setState({
            planName: "",
            planAgeFrom: "",
            planAgeTo: "",
            patientType: "",

            CreateTemplateClicked: false,
            SelectedDayIndex: 0,
            SelectedTimeIndex: 0,
            SelectedSessionIndex: 0,
            TempSelectedTimeIndex: 0,
            SelectedTemplateId: 1,

            planNameErrorText: '',
            isPlanNameExists: false,
            planNameCompleted: false,
            TimeErrorText: '',
            TimeCompleted: false,
            ListErrorText: "",
            ListCompleted: false,
            isPlanList: false,

            Time: "",
            MealType: '',
            TimeDataValue: '',

            FoodType: 'Food',
            ProductTextInput: '',
            QuantityTextInput: '',
            UnitTextInput: 'Nos',
            PointsToRemember: '',
            AlwaysAvoid: '',

            Datas: [
                {
                    "day_count": "Day 1",
                    "list": [
                        {
                            "session": "morning",
                            "times": []
                        },
                        {
                            "session": "afernoon",
                            "times": []
                        },
                        {
                            "session": "night",
                            "times": []
                        }
                    ]
                }
            ]
        })

    }

    PostMealPlan = () => {


        if (this.state.planName != "" && !this.state.isPlanNameExists) {

            if ((this.state.planAgeFrom != "") && (this.state.planAgeTo == "")) {
                this.setState({
                    planNameErrorText: "Age To Value is Empty",
                })
            }
            else if ((this.state.planAgeTo != "") && (this.state.planAgeFrom == "")) {
                this.setState({
                    planNameErrorText: "Age From Value is Empty",
                })
            }
            else if (parseInt(this.state.planAgeFrom) > parseInt(this.state.planAgeTo)) {
                this.setState({
                    planNameErrorText: "Invalid Age Values",
                })
            }
            else {
                var service_url = Constants.MEAL_PLAN_FO;
                var data = {
                    "planName": this.state.planName,
                    "planAgeFrom": this.state.planAgeFrom,
                    "planAgeTo": this.state.planAgeTo,
                    "patientType": this.state.patientType,
                    "pointToRemember": this.state.PointsToRemember,
                    "AlwaysAvoid": this.state.AlwaysAvoid,
                    "Days": this.state.Datas,
                }

                OpthamologyService.getInstance().postComplaints(
                    service_url,
                    data,
                    this,
                    this.PostMealPlanSuccess,
                    this.PostMealPlanSuccessFailure
                );
            }

        }
        else {
            if (this.state.planName == "") {
                this.setState({
                    planNameErrorText: "Please Enter Plan Name",
                })
            }
            else if (this.state.isPlanNameExists) {
                this.setState({
                    planNameErrorText: "Template Name Already Exist",
                })
            }
        }



    }
    PostMealPlanSuccess = (response) => {
        if (response.status == "success") {
            this.props.showResposeValue("success", response.message)
            this.ResetClicked()
            this.getMealPlanTemplates()
        }
        else {
            this.props.showResposeValue("error", response.message)
        }
    }
    PostMealPlanSuccessFailure = (response) => {
        this.props.showResposeValue("error", response.message)
    }
    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    PlanListSelection = () => {
        return (
            <View>
                <View style={styles.HightLightView}>
                    <Text style={styles.HighLightText}>Food / Liquid Products & Quantity</Text>
                </View>
                <View style={styles.FlexRowView}>
                    <View style={{ width: '29vw', marginLeft: '0.3vw' }}>
                        <View style={[styles.TableHeadView, { display: 'flex', flexDirection: 'row' }]}>
                            <Text style={[styles.TableHead, { width: '20%' }]}>Type</Text>
                            <Text style={[styles.TableHead, { width: '50%' }]}>Products</Text>
                            <Text style={[styles.TableHead, { width: '15%' }]}>Quantity</Text>
                            <Text style={[styles.TableHead, { width: '15%' }]}>Unit</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5vw' }}>
                            <View style={{ width: '20%' }}>
                                <Picker
                                    style={[styles.SmallpickerView, {
                                        width: '90%', fontSize: '0.8vw',
                                    }]}
                                    selectedValue={this.state.FoodType}
                                    onValueChange={(itemvalue) => {
                                        this.setState({ FoodType: itemvalue })
                                    }}
                                >
                                    {FoodType.map((item) =>
                                        <Picker.Item label={item.label} value={item.value} />

                                    )}

                                </Picker>
                            </View>
                            <View style={{ width: '45%' }}>
                                <TextInput
                                    value={this.state.ProductTextInput}
                                    style={styles.SmallTextInputStyles}
                                    onChangeText={(text) => { this.setState({ ProductTextInput: text }) }}

                                />
                            </View>
                            <View style={{ width: '15%', marginLeft: '0.5vw' }}>

                                <TextInput
                                    value={this.state.QuantityTextInput}
                                    style={styles.SmallTextInputStyles}
                                    maxLength={5}
                                    onChangeText={(text) => {
                                        var isValid = this.numberValidation(text)
                                        if (isValid || text === "") {
                                            this.setState({ QuantityTextInput: text })
                                        }
                                    }}

                                />
                            </View>
                            <View style={{ width: '15%', marginLeft: '0.5vw' }}><Picker
                                style={[styles.SmallpickerView, {
                                    width: '90%', fontSize: '0.7vw',
                                }]}
                                selectedValue={this.state.UnitTextInput}
                                onValueChange={(itemvalue) => {
                                    this.setState({ UnitTextInput: itemvalue })
                                }}
                            >
                                {UnitList.map((item) =>
                                    <Picker.Item label={item.label} value={item.value} />

                                )}

                            </Picker>

                            </View>

                        </View>
                        <View>
                            {
                                this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times.length > 0 &&
                                    this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex] != undefined &&
                                    this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.length > 0 ?
                                    this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.map((item, index) => (
                                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5vw' }} >
                                            <Text style={[styles.DisableSmallTextInputStyles, { width: '18%', marginLeft: 0 }]}>{item.type}</Text>
                                            <TextInput value={item.product} multiline={true} style={[styles.DisableSmallTextInputStyles, { width: '45%', paddingTop: 0 }]} />
                                            <Text style={[styles.DisableSmallTextInputStyles, { width: '15%' }]}>{item.quantity}</Text>
                                            <Text style={[styles.DisableSmallTextInputStyles, { width: '10%' }]}>{item.unit}</Text>

                                            {!this.state.ListCompleted ? <TouchableOpacity
                                                style={styles.DeletePlanLIstIcon}
                                                onPress={() => {

                                                    this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.splice(index, 1)
                                                    this.setState({
                                                        Datas: this.state.Datas,
                                                        TimeErrorText: ""
                                                    })
                                                }}
                                            >
                                                <Icon
                                                    name="trash"
                                                    size={'1vw'}
                                                    color={color.red}
                                                />
                                            </TouchableOpacity> : null}
                                        </View>
                                    )) : null

                            }
                            <Text></Text>
                        </View>

                    </View>
                    <View>
                        < TouchableOpacity
                            onPress={() => {
                                if (this.state.ProductTextInput != "" && this.state.QuantityTextInput != "" && this.state.UnitTextInput != "" && this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.length < 7) {
                                    var List = []
                                    var PlanList = [{
                                        "type": this.state.FoodType,
                                        "product": this.state.ProductTextInput,
                                        "quantity": this.state.QuantityTextInput,
                                        "unit": this.state.UnitTextInput,
                                    }]

                                    List = this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.concat(PlanList)

                                    this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List = List;

                                    this.setState({
                                        Datas: this.state.Datas,
                                        FoodType: "Food",
                                        ProductTextInput: '',
                                        QuantityTextInput: '',
                                        UnitTextInput: 'Nos',
                                        ListErrorText: '',
                                        ListAdded: true
                                    })
                                }
                                else {
                                    if (this.state.ProductTextInput == "") {
                                        this.setState({
                                            ListErrorText: "Products is Empty"
                                        })
                                    }
                                    else if (this.state.QuantityTextInput == "") {
                                        this.setState({
                                            ListErrorText: "Quantity is Empty"
                                        })
                                    }
                                    else if (this.state.UnitTextInput == "") {
                                        this.setState({
                                            ListErrorText: "Unit is Empty"
                                        })
                                    }
                                    else if (this.state.UnitTextInput != "" && this.state.Datas[this.state.SelectedDayIndex].list[this.state.SelectedSessionIndex].times[this.state.SelectedTimeIndex].plan_List.length >= 7) {
                                        this.setState({
                                            ListErrorText: "Maximum Item Limit is 7"
                                        })
                                    }
                                }
                            }}
                            style={[styles.TableBtnView, { width: '2vw', backgroundColor: color.themeDark, marginTop: '2vw' }]}>
                            <Icon

                                name="plus"
                                size={'0.8vw'}
                                color={color.white}
                            />
                        </TouchableOpacity>

                    </View>
                </View>
                <View style={styles.NxtBtnView}>
                    <View style={styles.ErrorViewCommon}>
                        <Text style={styles.ErrorTextCommon}>
                            {this.state.ListErrorText}
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={() => {
                            outerloop: for (let i = 0; i < this.state.Datas.length; i++) {
                                for (let j = 0; j < this.state.Datas[i].list.length; j++) {
                                    if (this.state.Datas[i].list[j].times.length != 0) {
                                        for (let k = 0; k < this.state.Datas[i].list[j].times.length; k++) {
                                            if (this.state.Datas[i].list[j].times[k].plan_List.length != 0) {
                                                this.setState({
                                                    ListErrorText: "",
                                                    ListCompleted: true
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    ListErrorText: `Meal Plan List is empty for ${this.state.Datas[i].day_count} -- ${this.state.Datas[i].list[j].times[k].time}`,
                                                    ListCompleted: false
                                                })
                                                break outerloop;  // Exits From Entire Loop
                                            }

                                        }
                                    } else {
                                        console.log("times empty", j)
                                    }

                                }
                            }
                        }}
                        style={[styles.NextBtnView]}>
                        <Text style={styles.ButtonText}>{"Next"}</Text>
                    </TouchableOpacity>
                </View>

            </View>
        )
    }

    LastTwoInputs = () => {
        return (
            <View>
                <View style={styles.HightLightView}>
                    <Text style={styles.HighLightText}>Points To Remember</Text>
                </View>
                <View>
                    <TextInput
                        multiline={true}
                        value={this.state.PointsToRemember}
                        style={[styles.SmallTextInputStyles, { height: '5vw', width: '80%', marginLeft: '3vw', paddingTop: '0.5vw' }]}
                        onChangeText={(text) => { this.setState({ PointsToRemember: text }) }}

                    />
                </View>
                <View style={styles.HightLightView}>
                    <Text style={styles.HighLightText}>Always Avoid</Text>
                </View>
                <View>
                    <TextInput
                        multiline={true}
                        value={this.state.AlwaysAvoid}
                        style={[styles.SmallTextInputStyles, { height: '5vw', width: '80%', marginLeft: '3vw', paddingTop: '0.5vw' }]}
                        onChangeText={(text) => { this.setState({ AlwaysAvoid: text }) }}

                    />
                </View>
                <View>
                    <View style={{ display: 'flex', flexDirection: 'row', margin: '0.5vw', justifyContent: 'space-around' }}>
                        <TouchableOpacity
                            onPress={() => {
                                this.PostMealPlan()
                            }}
                            style={styles.BtnTouch}>
                            <Text style={styles.ButtonText}>{"Add All"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            // onPress={() => { this.resetClicked() }}
                            style={styles.BtnTouch}>
                            <Text style={styles.ButtonText}>{"Clear"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }


    TimeValidate = () => {

        if (this.state.MealType != "" && this.state.TimeData != "" && this.state.TimeData != undefined) {
            var p = this.state.TimeData.split(':'),
                s = 0, m = 1;

            while (p.length > 0) {
                s += m * parseInt(p.pop(), 10);
                m *= 60;
            }
            ;
            if (s > 17999 && s <= 43200) {
                var List = []
                if (this.state.Datas[this.state.SelectedDayIndex].list[0].times.length == 0) {
                    if (this.state.editClicked) {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }]

                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[0].times = List;
                        this.setState({
                            TimeErrorText: ""
                        })
                    }
                    else {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": []
                            }]

                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[0].times = List;
                        this.setState({
                            TimeErrorText: ""
                        })
                    }


                    this.setState({
                        Datas: this.state.Datas,
                        MealType: "",
                        TimeDataValue: "",
                        ListErrorText: "",
                        TimeErrorText: "",
                        ListCompleted: false,
                        SelectedSessionIndex: 0,
                        // SelectedTimeIndex: 0,
                        TempSelectedTimeIndex: 11
                    })
                }
                else {
                    var TimeTemplate = [
                        {
                            "time": this.state.Time,
                            "timeTemp": this.state.TimeDataValue,
                            "meal_type": this.state.MealType,
                            "plan_List": []
                        }
                    ]

                    const indexOfObject = this.state.Datas[this.state.SelectedDayIndex].list[0].times.findIndex(object => {
                        return object.time === this.state.Time;
                    });
                    if (indexOfObject < 0 && (this.state.Datas[this.state.SelectedDayIndex].list[0].times.length < 3 || this.state.editClicked)) {
                        if (this.state.editClicked && this.state.editSessionIndex == 0) {


                            this.state.Datas[this.state.SelectedDayIndex].list[0].times[this.state.editTimeIndex] = {
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }
                            this.setState({
                                TimeErrorText: ""
                            })

                        } else {
                            if (this.state.editClicked) {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[0].times.length < 3) {
                                    var TimeTemplate2 = [
                                        {
                                            "time": this.state.Time,
                                            "timeTemp": this.state.TimeDataValue,
                                            "meal_type": this.state.MealType,
                                            "plan_List": this.state.editSelectedPlanList
                                        }
                                    ]
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[0].times.concat(TimeTemplate2)

                                    this.state.Datas[this.state.SelectedDayIndex].list[0].times = List
                                    this.setState({
                                        TimeErrorText: ""
                                    })
                                }
                                else {
                                    this.setState({
                                        TimeErrorText: "Time Slot is Full. Please Delete One"
                                    })
                                }
                            }
                            else {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[0].times.length < 3) {
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[0].times.concat(TimeTemplate)

                                    this.state.Datas[this.state.SelectedDayIndex].list[0].times = List
                                    this.setState({
                                        TimeErrorText: ""
                                    })
                                }
                            }
                        }


                        this.setState({
                            Datas: this.state.Datas,
                            MealType: "",
                            TimeDataValue: "",
                            ListErrorText: "",
                            // TimeErrorText: "",
                            editClicked: false,
                            ListCompleted: false,

                        })

                    }
                    else {
                        if (indexOfObject >= 0) {
                            if (this.state.editClicked && this.state.editSessionIndex == 0) {
                                this.state.Datas[this.state.SelectedDayIndex].list[0].times[this.state.editTimeIndex] = {
                                    "time": this.state.Time,
                                    "timeTemp": this.state.TimeDataValue,
                                    "meal_type": this.state.MealType,
                                    "plan_List": this.state.editSelectedPlanList
                                }
                                this.setState({
                                    Datas: this.state.Datas,
                                    MealType: "",
                                    TimeDataValue: "",
                                    TimeErrorText: "",
                                    editClicked: false
                                })
                            } else {
                                this.setState({
                                    TimeErrorText: `${this.state.Time} Already Exits`,
                                })
                            }
                        }
                        else {
                            this.setState({
                                TimeErrorText: "Maximum 3 Time Slots Only Allowed"
                            })
                        }
                    }




                }
            }
            else if (s > 43200 && s <= 57600) {
                var List = []
                if (this.state.Datas[this.state.SelectedDayIndex].list[1].times.length == 0) {
                    if (this.state.editClicked) {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }]

                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[1].times = List;
                    }
                    else {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": []
                            }]

                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[1].times = List;
                    }

                    this.setState({
                        Datas: this.state.Datas,
                        MealType: "",
                        TimeDataValue: "",
                        ListErrorText: "",
                        TimeErrorText: "",
                        ListCompleted: false,
                        SelectedSessionIndex: 1,
                        // SelectedTimeIndex: 0,
                        TempSelectedTimeIndex: 22
                    })


                }
                else {
                    var TimeTemplate = [
                        {
                            "time": this.state.Time,
                            "timeTemp": this.state.TimeDataValue,
                            "meal_type": this.state.MealType,
                            "plan_List": []
                        }
                    ]

                    const indexOfObject = this.state.Datas[this.state.SelectedDayIndex].list[1].times.findIndex(object => {
                        return object.time === this.state.Time;
                    });
                    if (indexOfObject < 0 && (this.state.Datas[this.state.SelectedDayIndex].list[1].times.length < 3 || this.state.editClicked)) {
                        if (this.state.editClicked && this.state.editSessionIndex == 1) {


                            this.state.Datas[this.state.SelectedDayIndex].list[1].times[this.state.editTimeIndex] = {
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }

                        } else {
                            if (this.state.editClicked) {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[1].times.length < 3) {
                                    var TimeTemplate2 = [
                                        {
                                            "time": this.state.Time,
                                            "timeTemp": this.state.TimeDataValue,
                                            "meal_type": this.state.MealType,
                                            "plan_List": this.state.editSelectedPlanList
                                        }
                                    ]
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[1].times.concat(TimeTemplate2)

                                    this.state.Datas[this.state.SelectedDayIndex].list[1].times = List
                                    this.setState({
                                        TimeErrorText: ""
                                    })
                                }
                                else {
                                    this.setState({
                                        TimeErrorText: "Time Slot is Full. Please Delete One"
                                    })
                                }
                            }
                            else {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[1].times.length < 3) {
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[1].times.concat(TimeTemplate)

                                    this.state.Datas[this.state.SelectedDayIndex].list[1].times = List
                                    this.setState({
                                        TimeErrorText: ""
                                    })
                                }
                            }
                        }

                        this.setState({
                            Datas: this.state.Datas,
                            MealType: "",
                            TimeDataValue: "",
                            ListErrorText: "",
                            // TimeErrorText: "",
                            // editClicked: false,
                            ListCompleted: false
                        })


                    }
                    else {
                        if (indexOfObject >= 0) {
                            if (this.state.editClicked && this.state.editSessionIndex == 1) {
                                this.state.Datas[this.state.SelectedDayIndex].list[1].times[this.state.editTimeIndex] = {
                                    "time": this.state.Time,
                                    "timeTemp": this.state.TimeDataValue,
                                    "meal_type": this.state.MealType,
                                    "plan_List": this.state.editSelectedPlanList
                                }
                                this.setState({
                                    Datas: this.state.Datas,
                                    MealType: "",
                                    TimeDataValue: "",
                                    editClicked: false
                                })
                            } else {
                                this.setState({
                                    TimeErrorText: `${this.state.Time} Already Exits`,
                                })
                            }
                        }
                        else {
                            this.setState({
                                TimeErrorText: "Maximum 3 Time Slots Only Allowed"
                            })
                        }
                    }

                }
            }
            else if (s > 57600 && s <= 79200) {
                var List = []
                if (this.state.Datas[this.state.SelectedDayIndex].list[2].times.length == 0) {
                    if (this.state.editClicked) {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }]


                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[2].times = List;

                    }
                    else {
                        var SessionTemplate =
                            [{
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": []
                            }]

                        List = List.concat(SessionTemplate)

                        this.state.Datas[this.state.SelectedDayIndex].list[2].times = List;
                    }

                    this.setState({
                        Datas: this.state.Datas,
                        MealType: "",
                        TimeDataValue: "",
                        ListErrorText: "",
                        TimeErrorText: "",
                        ListCompleted: false,
                        SelectedSessionIndex: 2,
                        // SelectedTimeIndex: 0,
                        TempSelectedTimeIndex: 33
                    })


                }
                else {
                    var TimeTemplate = [
                        {
                            "time": this.state.Time,
                            "timeTemp": this.state.TimeDataValue,
                            "meal_type": this.state.MealType,
                            "plan_List": []
                        }
                    ]
                    const indexOfObject = this.state.Datas[this.state.SelectedDayIndex].list[2].times.findIndex(object => {
                        return object.time === this.state.Time;
                    });
                    if (indexOfObject < 0 && (this.state.Datas[this.state.SelectedDayIndex].list[2].times.length < 3 || this.state.editClicked)) {
                        if (this.state.editClicked && this.state.editSessionIndex == 2) {


                            this.state.Datas[this.state.SelectedDayIndex].list[2].times[this.state.editTimeIndex] = {
                                "time": this.state.Time,
                                "timeTemp": this.state.TimeDataValue,
                                "meal_type": this.state.MealType,
                                "plan_List": this.state.editSelectedPlanList
                            }
                            this.setState({
                                TimeErrorText: ""
                            })

                        } else {
                            if (this.state.editClicked) {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[2].times.length < 3) {
                                    var TimeTemplate2 = [
                                        {
                                            "time": this.state.Time,
                                            "timeTemp": this.state.TimeDataValue,
                                            "meal_type": this.state.MealType,
                                            "plan_List": this.state.editSelectedPlanList
                                        }
                                    ]
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[2].times.concat(TimeTemplate2)

                                    this.state.Datas[this.state.SelectedDayIndex].list[2].times = List
                                    this.setState({
                                        TimeErrorText: ""
                                    })
                                }
                                else {
                                    this.setState({
                                        TimeErrorText: "Time Slot is Full. Please Delete One"
                                    })
                                }
                            }
                            else {
                                if (this.state.Datas[this.state.SelectedDayIndex].list[2].times.length < 3) {
                                    List = this.state.Datas[this.state.SelectedDayIndex].list[2].times.concat(TimeTemplate)

                                    this.state.Datas[this.state.SelectedDayIndex].list[2].times = List
                                }
                                this.setState({
                                    TimeErrorText: ""
                                })
                            }

                        }

                        this.setState({
                            Datas: this.state.Datas,
                            MealType: "",
                            TimeDataValue: "",
                            ListErrorText: "",
                            // TimeErrorText: "",
                            // editClicked: false,
                            ListCompleted: false,
                        })
                    }
                    else {
                        if (indexOfObject >= 0) {
                            if (this.state.editClicked && this.state.editSessionIndex == 2 && this.state.Datas[this.state.SelectedDayIndex].list[2].times[this.state.editTimeIndex].time == this.state.Time) {
                                this.state.Datas[this.state.SelectedDayIndex].list[2].times[this.state.editTimeIndex] = {
                                    "time": this.state.Time,
                                    "timeTemp": this.state.TimeDataValue,
                                    "meal_type": this.state.MealType,
                                    "plan_List": this.state.editSelectedPlanList
                                }
                                this.setState({
                                    Datas: this.state.Datas,
                                    MealType: "",
                                    TimeDataValue: "",
                                    editClicked: false
                                })
                            } else {
                                this.setState({
                                    TimeErrorText: `${this.state.Time} Already Exits`,
                                })
                            }

                        }
                        else {
                            this.setState({
                                TimeErrorText: "Maximum 3 Time Slots Only Allowed"
                            })
                        }
                    }
                }
            }
            else {
                this.setState({
                    TimeErrorText: (s > 79200 || s <= 17940) ? "Invalid Time - Choose Time Between 5.00 AM - 10.00 PM" : ""
                })
            }


        }
        else {
            this.setState({
                TimeErrorText: "Select Both Time and Meal Type"
            })
        }
    }
    renderPageRight = () => {
        return (
            <View style={{ height: '87vh' }}>
                <ScrollView showsVerticalScrollIndicator={true}>
                    <View style={styles.RightHeadText}>
                        <Text style={styles.RightHeadTitle}>Meal Plan Template Generator</Text>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    CreateTemplateClicked: !this.state.CreateTemplateClicked
                                }, () => {
                                    this.state.CreateTemplateClicked ? null : this.ResetClicked()
                                })
                            }}
                            style={styles.BtnTouch}>
                            <Text style={styles.ButtonText}>{!this.state.CreateTemplateClicked ? "Create Template" : "Reset Template"}</Text>
                        </TouchableOpacity>
                    </View>

                    {
                        this.state.CreateTemplateClicked ?
                            <View>
                                <View style={styles.HightLightView}>
                                    <Text style={styles.HighLightText}>Plan Name / Category</Text>
                                </View>

                                <View style={styles.InputView}>
                                    <Text style={styles.InputText}>Plan Name <Text style={styles.MandatoryText}>*</Text></Text>
                                    <View style={{ width: '22vw', marginRight: '1vw' }}>
                                        {this.renderTextBox('planName')}
                                    </View>
                                </View>
                                <View style={styles.InputView}>
                                    <Text style={styles.InputText}>Age</Text>
                                    <View style={{ width: '4vw', marginRight: '0.3vw' }}>
                                        {this.renderTextBox('planAgeFrom')}
                                    </View>
                                    <Text style={[styles.InputText, { marginLeft: '0.2vw' }]}>To</Text>
                                    <View style={{ width: '4vw', marginRight: '0.2vw' }}>
                                        {this.renderTextBox('planAgeTo')}
                                    </View>
                                    <Text style={[styles.InputText, { marginLeft: '0.5vw' }]}>Patient Type</Text>
                                    <View style={{ width: '8vw', marginRight: '1vw' }}>
                                        {this.renderTextBox('patientType')}
                                    </View>
                                </View>
                                <View style={styles.NxtBtnView}>
                                    <View style={styles.ErrorViewCommon}>
                                        <Text style={styles.ErrorTextCommon}>
                                            {this.state.planNameErrorText}
                                        </Text>
                                    </View>
                                    <TouchableOpacity
                                        onPress={() => {

                                            if (this.state.planName == "") {
                                                this.setState({
                                                    planNameErrorText: "Please Enter Plan Name",
                                                    planNameCompleted: false
                                                })
                                            }
                                            else if ((this.state.planAgeFrom != "") && (this.state.planAgeTo == "")) {
                                                this.setState({
                                                    planNameErrorText: "Age To Value is Empty",
                                                    planNameCompleted: false
                                                })
                                            }
                                            else if ((this.state.planAgeTo != "") && (this.state.planAgeFrom == "")) {
                                                this.setState({
                                                    planNameErrorText: "Age From Value is Empty",
                                                    planNameCompleted: false
                                                })
                                            }
                                            else if (parseInt(this.state.planAgeFrom) > parseInt(this.state.planAgeTo)) {
                                                this.setState({
                                                    planNameErrorText: "Invalid Age Values",
                                                    planNameCompleted: false
                                                })
                                            }
                                            else {

                                                const indexOfObject = this.state.AllTemplateList.findIndex(object => {
                                                    return object.data.planName === this.state.planName;
                                                });

                                                if (indexOfObject >= 0) {
                                                    this.setState({
                                                        planNameErrorText: "Template Name Already Exist",
                                                        planNameCompleted: false,
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        planNameErrorText: "",
                                                        planNameCompleted: true,
                                                    })
                                                }
                                            }

                                        }}
                                        style={[styles.NextBtnView]}>
                                        <Text style={styles.ButtonText}>{"Next"}</Text>
                                    </TouchableOpacity>
                                </View>

                                {
                                    this.state.planNameCompleted ?
                                        <View>
                                            <View style={styles.HightLightView}>
                                                <Text style={styles.HighLightText}> Time & Meal Type</Text>
                                            </View>

                                            <View style={styles.FlexRowView}>

                                                <View style={styles.FlexRowView}>
                                                    {this.state.Datas.map((list, index) => (
                                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    this.setState({
                                                                        SelectedDayIndex: index
                                                                    })
                                                                }}
                                                                style={[styles.DayBtnView, { backgroundColor: this.state.SelectedDayIndex == index ? color.themeDark : color.white }]}>
                                                                <Text style={[styles.DayBtnText, { color: this.state.SelectedDayIndex == index ? color.white : color.themeDark, }]}>{list.day_count}</Text>
                                                            </TouchableOpacity>
                                                            {
                                                                this.state.Datas.length > 1 && (this.state.Datas.length - 1 == index) ?
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {
                                                                            this.state.Datas.splice(index, 1)
                                                                            this.setState({
                                                                                Datas: this.state.Datas,
                                                                                SelectedDayIndex: index - 1,
                                                                                TimeErrorText: "",

                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            size={'1vw'}
                                                                            color={color.red}
                                                                        />
                                                                    </TouchableOpacity> : null
                                                            }
                                                        </View>


                                                    ))}
                                                </View>
                                                < TouchableOpacity
                                                    onPress={() => {

                                                        this.addDays()
                                                    }}
                                                    style={[styles.DayBtnView, { width: '2vw', backgroundColor: color.themeDark }]}>
                                                    <Icon
                                                        name="plus"
                                                        size={'1vw'}
                                                        color={color.white}
                                                    />
                                                </TouchableOpacity>
                                            </View>

                                            <View style={styles.RightSection2View}>
                                                <View style={styles.TimeWrap}>
                                                    <Text style={styles.InputText}>Time  <Text style={styles.MandatoryText}>*</Text></Text>
                                                    <TimePicker format="h:mm A" style={{ width: '8vw', height: '2vw', marginLeft: '0.5vw' }}
                                                        value={this.state.TimeDataValue}
                                                        onChange={(time, timeString) => {
                                                            this.setState({
                                                                TimeDataValue: time,
                                                                Time: timeString,
                                                                TimeData: moment(time).format("HH:mm:ss")
                                                            })
                                                        }} />
                                                </View>



                                                <Picker

                                                    style={[styles.pickerView, {
                                                        width: '14.64vw', fontSize: '1vw', marginLeft: '0.5vw'
                                                    }]}
                                                    place
                                                    selectedValue={this.state.MealType}
                                                    onValueChange={(itemvalue) => {
                                                        this.setState({ MealType: itemvalue })
                                                    }}
                                                >
                                                    {MealType.map((item) =>
                                                        <Picker.Item label={item.label} value={item.value} />

                                                    )}

                                                </Picker>


                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.TimeValidate()
                                                    }
                                                    }
                                                    style={[styles.DayBtnView, { width: '2vw', backgroundColor: color.themeDark, paddingTop: '0.5vw', marginLeft: '0.2vw' }]}>
                                                    <Icon
                                                        name="plus"
                                                        size={'1vw'}
                                                        color={color.white}
                                                    />

                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    style={styles.DeletePlanLIstIcon}
                                                    onPress={() => {
                                                        this.setState({
                                                            TimeDataValue: '',
                                                            MealType: '',
                                                            editClicked: false,
                                                            editSelectedPlanList: [],
                                                            TimeErrorText: ""
                                                        })
                                                    }}
                                                >

                                                    <Icon
                                                        name="close"
                                                        size={'1.5vw'}
                                                        color={color.red}
                                                    />

                                                </TouchableOpacity>

                                            </View>


                                            {
                                                this.state.Datas[this.state.SelectedDayIndex].list.length > 0 ?
                                                    <View style={styles.FlexRowView}>
                                                        {
                                                            this.state.Datas[this.state.SelectedDayIndex].list[0].times.map((list, index) => (
                                                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <TouchableOpacity
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 10 + index + 1,
                                                                                SelectedSessionIndex: 0
                                                                            })
                                                                        }}
                                                                        style={[styles.DayBtnView, { backgroundColor: this.state.TempSelectedTimeIndex == 10 + index + 1 ? color.themeDark : color.white, width: '6vw' }]}>
                                                                        <Text style={[styles.DayBtnText, { color: this.state.TempSelectedTimeIndex == 10 + index + 1 ? color.white : color.themeDark }]}>{list.time}</Text>
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                MealType: this.state.Datas[this.state.SelectedDayIndex].list[0].times[index].meal_type,
                                                                                TimeDataValue: this.state.Datas[this.state.SelectedDayIndex].list[0].times[index].timeTemp,
                                                                                editSelectedPlanList: this.state.Datas[this.state.SelectedDayIndex].list[0].times[index].plan_List,
                                                                                editClicked: true,
                                                                                editTimeIndex: index,
                                                                                editSessionIndex: 0,
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 10 + index + 1,
                                                                                SelectedSessionIndex: 0,
                                                                                Time: this.state.Datas[this.state.SelectedDayIndex].list[0].times[index].time

                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="pencil"
                                                                            size={'1vw'}
                                                                            color={color.cardGreen}
                                                                        />
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {
                                                                            this.state.Datas[this.state.SelectedDayIndex].list[0].times.splice(index, 1)
                                                                            this.setState({
                                                                                Datas: this.state.Datas,
                                                                                TimeErrorText: ""
                                                                            }, () => {
                                                                                if (
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[0].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[1].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[2].times.length == 0
                                                                                ) {
                                                                                    this.setState({
                                                                                        TimeCompleted: false,
                                                                                        ListCompleted: false
                                                                                    })
                                                                                }
                                                                            })

                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            size={'1vw'}
                                                                            color={color.red}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </View>

                                                            ))}
                                                    </View> : null
                                            }
                                            {
                                                this.state.Datas[this.state.SelectedDayIndex].list.length > 0 ?
                                                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5vw' }}>
                                                        {
                                                            this.state.Datas[this.state.SelectedDayIndex].list[1].times.map((list, index) => (
                                                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <TouchableOpacity
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 20 + index + 2,
                                                                                SelectedSessionIndex: 1
                                                                            })
                                                                        }}
                                                                        style={[styles.DayBtnView, { backgroundColor: this.state.TempSelectedTimeIndex == 20 + index + 2 ? color.themeDark : color.white, width: '6vw' }]}>
                                                                        <Text style={[styles.DayBtnText, { color: this.state.TempSelectedTimeIndex == 20 + index + 2 ? color.white : color.themeDark }]}>{list.time}</Text>
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                MealType: this.state.Datas[this.state.SelectedDayIndex].list[1].times[index].meal_type,
                                                                                TimeDataValue: this.state.Datas[this.state.SelectedDayIndex].list[1].times[index].timeTemp,
                                                                                editSelectedPlanList: this.state.Datas[this.state.SelectedDayIndex].list[1].times[index].plan_List,
                                                                                editClicked: true,
                                                                                editTimeIndex: index,
                                                                                editSessionIndex: 1,
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 20 + index + 2,
                                                                                SelectedSessionIndex: 1,
                                                                                Time: this.state.Datas[this.state.SelectedDayIndex].list[1].times[index].time
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="pencil"
                                                                            size={'1vw'}
                                                                            color={color.cardGreen}
                                                                        />
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {

                                                                            this.state.Datas[this.state.SelectedDayIndex].list[1].times.splice(index, 1)
                                                                            this.setState({
                                                                                Datas: this.state.Datas,
                                                                                TimeErrorText: ""
                                                                            }, () => {
                                                                                if (
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[0].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[1].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[2].times.length == 0
                                                                                ) {
                                                                                    this.setState({
                                                                                        TimeCompleted: false,
                                                                                        ListCompleted: false
                                                                                    })
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            size={'1vw'}
                                                                            color={color.red}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </View>

                                                            ))}
                                                    </View> : null
                                            }
                                            {
                                                this.state.Datas[this.state.SelectedDayIndex].list.length > 0 ?
                                                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5vw' }}>
                                                        {
                                                            this.state.Datas[this.state.SelectedDayIndex].list[2].times.map((list, index) => (
                                                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <TouchableOpacity
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 30 + index + 3,
                                                                                SelectedSessionIndex: 2
                                                                            })
                                                                        }}
                                                                        style={[styles.DayBtnView, { backgroundColor: this.state.TempSelectedTimeIndex == 30 + index + 3 ? color.themeDark : color.white, width: '6vw' }]}>
                                                                        <Text style={[styles.DayBtnText, { color: this.state.TempSelectedTimeIndex == 30 + index + 3 ? color.white : color.themeDark }]}>{list.time}</Text>
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {
                                                                            this.setState({
                                                                                MealType: this.state.Datas[this.state.SelectedDayIndex].list[2].times[index].meal_type,
                                                                                TimeDataValue: this.state.Datas[this.state.SelectedDayIndex].list[2].times[index].timeTemp,
                                                                                editSelectedPlanList: this.state.Datas[this.state.SelectedDayIndex].list[2].times[index].plan_List,
                                                                                editClicked: true,
                                                                                editTimeIndex: index,
                                                                                editSessionIndex: 2,
                                                                                SelectedTimeIndex: index,
                                                                                TempSelectedTimeIndex: 30 + index + 3,
                                                                                SelectedSessionIndex: 2,
                                                                                Time: this.state.Datas[this.state.SelectedDayIndex].list[2].times[index].time
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="pencil"
                                                                            size={'1vw'}
                                                                            color={color.cardGreen}
                                                                        />
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={styles.DeletePlanLIstIcon}
                                                                        onPress={() => {

                                                                            this.state.Datas[this.state.SelectedDayIndex].list[2].times.splice(index, 1)
                                                                            this.setState({
                                                                                Datas: this.state.Datas,
                                                                                TimeErrorText: ""
                                                                            }, () => {
                                                                                if (
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[0].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[1].times.length == 0 &&
                                                                                    this.state.Datas[this.state.SelectedDayIndex].list[2].times.length == 0
                                                                                ) {
                                                                                    this.setState({
                                                                                        TimeCompleted: false,
                                                                                        ListCompleted: false
                                                                                    })
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            size={'1vw'}
                                                                            color={color.red}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </View>

                                                            ))}
                                                    </View> : null
                                            }

                                            <View style={styles.NxtBtnView}>
                                                <View style={styles.ErrorViewCommon}>
                                                    <Text style={styles.ErrorTextCommon}>
                                                        {this.state.TimeErrorText}
                                                    </Text>
                                                </View>
                                                <TouchableOpacity
                                                    onPress={() => {

                                                        for (let i = 0; i < this.state.Datas.length; i++) {
                                                            if (
                                                                this.state.Datas[i].list[0].times.length != 0 || this.state.Datas[i].list[1].times.length != 0 || this.state.Datas[i].list[2].times.length != 0
                                                            ) {
                                                                this.setState({
                                                                    TimeErrorText: "",
                                                                    TimeCompleted: true
                                                                })
                                                            }
                                                            else {
                                                                this.setState({
                                                                    TimeErrorText: "Day Values are Empty",
                                                                    TimeCompleted: false
                                                                })
                                                                break
                                                            }
                                                        }
                                                    }}
                                                    style={[styles.NextBtnView]}>
                                                    <Text style={styles.ButtonText}>{"Next"}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                        : null
                                }
                            </View>

                            : null

                    }
                    {
                        this.state.TimeCompleted ?
                            this.PlanListSelection() : null
                    }
                    {
                        this.state.ListCompleted ?
                            this.LastTwoInputs()
                            : null
                    }


                </ScrollView >
            </View >
        )
    }
    render() {
        return (
            <View
                style={{
                    flexDirection: "row",
                }}
            >
                <View style={{ width: "65%", height: "70vh" }}>
                    <View style={styles.PageHeader}>
                        <Text style={styles.PageHeaderText}>Meal Plan</Text>
                    </View>
                    <View style={styles.LeftMainView}>{this.renderPageLeft()}</View>
                </View>
                <View style={styles.RightMainView}>{this.renderPageRight()}</View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    PageHeader: {
        display: "flex", flexDirection: 'row', alignItems: 'flex-start', backgroundColor: color.white, width: '100%', borderBottomLeftRadius: '2.5vw', borderBottomRightRadius: '2.5vw', height: '3vw', marginBottom: '0.6vw', shadowColor: color.grayShade, shadowOffset: { width: '0.06vw', height: '0.06vw' }, shadowOpacity: 1, shadowRadius: 2
    },
    PageHeaderText: {
        marginTop: '0.5vw',
        marginLeft: '2.5vw',
        fontWeight: '500',
        color: color.themeDark,
        fontSize: '1vw'
    },
    LeftMainView: {
        width: '97.5%',
        height: '80vh',
        margin: '0.2vw',
        marginLeft: '0.7vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw',
        backgroundColor: color.themeShade,
    },
    LeftMealChartMainView: {
        width: '98%',
        height: "65vh", marginLeft: '0.5vw', overflow: 'scroll'
    },
    RightMainView: {
        width: '34%',
        height: '87vh',
        backgroundColor: color.themeShade,
        marginLeft: '0.5vw',
        marginTop: '0.5vw',
        shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2,
        borderRadius: '0.25vw'
    },
    NxtBtnView: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1vw', marginRight: '1vw'
    },
    RightHeadTitle: {
        marginLeft: '1vw',
        marginTop: '0.3vw',
        fontSize: '1vw',
        fontWeight: 'bold',
        color: color.themeDark
    },
    HightLightView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        margin: '1vw',
    },
    TableHeadView: {
        backgroundColor: color.sectionHeadingLiteColor,
        alignContent: 'flex-start',
        borderRadius: '0.4vw',
        // padding: '0.5vw',

    },
    HighLightText: {
        marginLeft: '1.3vw',
        margin: '0.5vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TableHead: {
        // marginLeft: '1.3vw',
        margin: '0.2vw',
        fontSize: '0.8vw',
        fontWeight: 'bold'
    },
    TextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "100%",
        height: "1.5vw",
        fontSize: ".9vw"
    },
    SmallTextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "100%",
        height: "1.5vw",
        fontSize: ".7vw",
        backgroundColor: color.white,
        paddingLeft: '0.5vw',
    },
    DisableSmallTextInputStyles: {
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
        width: "100%",
        height: "1.5vw",
        fontSize: ".7vw",
        backgroundColor: color.sectionHeadingLiteColor,
        paddingLeft: '0.5vw',
        marginLeft: '0.5vw',
        paddingTop: '0.3vw'
    },
    renderTextBoxContainer: {
        width: "100%",
        marginTop: "0.65vw",
    },
    InputText: {
        fontSize: '0.9vw',
        marginLeft: '2vw',
        marginTop: '0.2vw',
        height: "1.5vw",
    },
    InputView: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
    },
    BtnTouch: {
        paddingTop: '0.5vw',
        paddingLeft: '1.1vw',
        paddingRight: '1.1vw',
        paddingBottom: '0.5vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '10vw'
    },
    NextBtnView: {
        paddingTop: '0.3vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.3vw',
        backgroundColor: color.themeDark,
        borderRadius: ".25vw",
        width: '5vw'
    },
    DayBtnView: {
        paddingTop: '0.2vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.2vw',
        backgroundColor: color.white,
        borderRadius: ".25vw",
        width: '4vw',
        borderWidth: 1,
        borderColor: color.themeDark,
        marginLeft: '1vw'
    },
    TableBtnView: {
        paddingTop: '0.2vw',
        paddingLeft: '0.5vw',
        paddingRight: '0.5vw',
        paddingBottom: '0.2vw',
        backgroundColor: color.white,
        borderRadius: ".25vw",
        width: '3vw',
        borderWidth: 1,
        borderColor: color.themeDark,
        // marginLeft: '1vw'
    },
    DayBtnText: {
        color: color.themeDark,
        textAlign: 'center',
        fontSize: ".7vw",
        fontWeight: "500"
    },
    ButtonText: {
        color: color.white,
        textAlign: 'center',
        fontSize: ".85vw"
    },
    MandatoryText: {
        color: 'red',
        fontWeight: '500',
        marginLeft: '0.2vw',
        fontSize: '1vw'
    },
    NoRecordsView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vw'
    },
    NoRecordsText: {
        fontSize: '1vw'
    },
    SearchInput: {
        height: '2vw',
        borderWidth: '0.065vw',
        borderColor: color.lightGray,
        borderRadius: '0.26vw',
        width: "25%",
        marginRight: '0.65vw',
        paddingHorizontal: '0.52vw',
        color: color.black,
        fontSize: '0.91vw'
    },
    TabView: {
        marginLeft: '2vw'
    },
    pickerView: {
        backgroundColor: color.white,
        height: "2vw",
        fontSize: ".85vw",
        paddingLeft: ".3vw",
        marginRight: "1vw",
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
    },
    SmallpickerView: {
        backgroundColor: color.white,
        height: "1.5vw",
        fontSize: ".7vw",
        paddingLeft: ".3vw",
        // marginRight: "1vw"
        borderColor: "#CDD1D5",
        borderRadius: '0.26vw',
        borderWidth: '0.065vw',
    },
    FlexRowView: {
        display: 'flex', flexDirection: 'row'
    },
    ChartBoxScrollView: {
        height: '8vw', width: '10vw', borderWidth: 1, borderRadius: '0.26vw', marginLeft: '1vw', marginRight: '1vw', marginBottom: '0.5vw', paddingLeft: '0.3vw', paddingTop: '0.3vw'
    },
    MainChartView: {
        display: 'flex', flexDirection: 'row', height: '90%', width: '95%', marginBottom: '1vw', marginTop: '1vw', marginLeft: '1vw', shadowOffset: { width: 0, height: 1 }, shadowColor: color.grayShade, shadowOpacity: 1, shadowRadius: 2, backgroundColor: color.white, paddingTop: '0.3vw', borderRadius: '0.25vw'
    },
    DayCountView: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%'
    },
    DayCountWrapper: {
        height: '8vw', width: '8vw', borderWidth: 1, borderRadius: '0.26vw', justifyContent: 'center', alignItems: 'center'
    },
    DayCountText: {
        fontSize: '1vw', fontWeight: '500', color: color.themeDark
    },
    PlanListBoxesView: {
        display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '80%'
    },
    BoxText1View: {
        width: '30%'
    },
    BoxText2View: {
        width: '60%'
    },
    BoxText1Text: {
        fontSize: '0.8vw', fontWeight: '500'
    },
    BoxText2Text: {
        fontSize: '0.8vw', marginBottom: '0.3vw', textAlign: 'left'
    },
    MainScrollLeft: {
        height: "65vh", overflow: 'scroll'
    },
    TemplateNameText: {
        margin: '0.5vw', marginLeft: '1vw', color: color.themeDark, fontWeight: "500", fontSize: '1vw'
    },
    LeftTopView: {
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'center', marginRight: '2vw', marginTop: '0.5vw'
    },
    SelectTempLabel: {
        fontSize: '1vw', marginTop: '0.3vw', fontWeight: '500'
    },
    RightHeadText: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw', marginBottom: 0
    },
    ErrorTextCommon: {
        color: 'red', fontSize: '.9vw', fontWeight: '500', marginLeft: '1vw'
    },
    ErrorViewCommon: {
        height: '1vw', marginTop: '0.3vw'
    },
    RightSection2View: {
        margin: '1vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
    },
    TimeWrap: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center'
    },
    DeletePlanLIstIcon: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: '0.3vw'
    }




})
