import React from "react";
import {
  View,
  Text,
  FlatList,
  // TextInput,
  // ScrollView,
  // Image,
  Platform,
  TouchableOpacity,
  CheckBox,
  StyleSheet
} from "react-native";
// import { CheckBox } from "react-native-elements";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import Icon from "react-native-vector-icons/FontAwesome";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
// import moment from "moment";
// import GmailInput from "react-native-gmailtype-textinput";
// import SideMenu from "../../SideMenu";

const platform = Platform.OS;
const isSystemic = "isSystemic";
const isSystemicAllergy = "isSystemicAllergy";

export default class SystemicIllness extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: this.props.patientAppointment.is_locked,
      systemicIllnessSelectedView: "",
      patientAppointment: this.props.patientAppointment,
      illnessData: [],
      allergyData: [],
      isComplaint:
        this.props.selectedView === isSystemic ||
        this.props.selectedView === isSystemicAllergy
          ? true
          : false,
      selectedClinicOrDoctor: this.props.selectedClinicOrDoctor,
      userType: this.props.userType,
      isBoxChecked: false,
      nilData: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getIllnessHistory();
      this.getDrugAllergyHistory();
    }, 1000);
  }

  componentWillReceiveProps(props) {
    if (
      props &&
      props.patientAppointment &&
      props.patientAppointment.patient_id
    ) {
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked = props.patientAppointment.is_locked;
    }
    if (props.reloadData && props.reloadData === "systemic") {
      // call once
      this.getIllnessHistory();
      // refresh false
      this.props.refreshData("");
    }

    if (props.reloadData && props.reloadData === "drugallergy") {
      // call once
      this.getDrugAllergyHistory();
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor =
      isSystemic === props.selectedView ||
      isSystemicAllergy === props.selectedView
        ? true
        : false;

    if (updateFontColor !== this.props.isComplaint) {
      this.setState({ isComplaint: updateFontColor });
    }
  }

  checkBoxPress = (key, title) => {
    let isBoxChecked = this.state.isBoxChecked;
    this.setState(
      {
        isBoxChecked: !isBoxChecked,
        systemicIllnessSelectedView: title,
      },
      () => {
        this.props.triggerNewItem(key, !isBoxChecked), this.addComplaint();
      }
    );
  };

  addComplaint = () => {

    let {allergyData}=this.state;
    
    // if (this.state.isBoxChecked) {
      
      var service_url = Constants.OPTHALMOLOGY_DRUG_ALLERGY;

      let data = {
        appointment_id: this.state.patientAppointment.appointment_id,
        patient_id: this.state.patientAppointment.patient_id,
        allergy: "",
        no_of_years: "",
        no_of_months: "",
        comments: "",
        is_nil: this.state.isBoxChecked,
      };

      // for avoiding empty record, pass last record id to set nil true
      if(allergyData.length > 0)
      {
        let id = allergyData[allergyData.length-1].id
        if(id)
        {
          data['id']=id;
        }
      }

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getMedicationSuccess,
        this.getMedicationFailure
      );
    // }
  };

  getMedicationSuccess = (response) => {
    
    if (response.status === "success") {
      this.setState({
        isDisable: false,
      });
      var field = this.state;
      field["complaintsData"] = response.data;
      this.setState({ field });

      // this.showSuccessMessage();
      // clear data
      this.getDrugAllergyHistory();

      // reload data
      // this.props.refreshData("drugallergy");
      this.props.showResposeValue("success", response.message);
    } else {
      this.setState({
        isDisable: false,
      });
      this.props.showResposeValue("error", response.message);
    }
  };

  getMedicationFailure = (error) => {
    this.setState({
      isDisable: false,
    });
    // this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error", error.message);
  };

  getIllnessHistory = () => {
    var service_url =
      Constants.OPTHALMOLOGY_LIST_ILLNESS +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;
    if (this.state.userType) {
      service_url =
        service_url + "&doctor_id=" + this.state.selectedClinicOrDoctor.id;
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getOcularHistorySuccess,
        this.getOcularHistoryFailure
      );
    }, 1000);
  };

  getOcularHistorySuccess = (response) => {
    if (response.status === "success") {
      var field = this.state;
      field["illnessData"] =
        response.data.length > 0 || response.data.count > 0
          ? response.data
          : [];
      this.setState({ field });

      // console.log("illness data --- " + JSON.stringify(response.data));
    }
  };

  getDrugAllergyHistory = () => {
    console.log("insidegetDrug");

    var service_url =
      Constants.OPTHALMOLOGY_DRUG_ALLERGY +
      "?patient_id=" +
      this.state.patientAppointment.patient_id;
    if (this.state.userType) {
      service_url =
        service_url + "&doctor_id=" + this.state.selectedClinicOrDoctor.id;
    }
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getAllergySuccess,
        this.getOcularHistoryFailure
      );
    }, 1000);
  };

  getAllergySuccess = (response) => {
    
    if (response.status === "success") {
      
      let {allergyData,nilData} = this.state;
      
      nilData = response.data.is_nil;

      allergyData=response.data.data;

      this.setState({ allergyData:allergyData, nilData:nilData, isBoxChecked:nilData });
      
    }
  };

  getOcularHistoryFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  editItem = (item, editPage, rightView, title, event) => {
    // this.props.triggerNewItem("isSystemic");

    this.setState(
      {
        systemicIllnessSelectedView: title,
      },
      () => {
        this.props.editItem(editPage, item, rightView);
      }
    );

    this.props.editItem(editPage, item);
  };

  deleteIllnessItem = (item, event) => {
    var service_url = Constants.OPTHALMOLOGY_DELETE_ILLNESS + `${item.id}/`;

    var data = {};
    if (this.state.userType == Constants.ROLE_OPTOMETRY) {
      data["doctor_id"] = this.state.selectedClinicOrDoctor.id;
    }

    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteIllnessSuccess,
      this.getOcularHistoryFailure
    );
  };

  getDeleteIllnessSuccess = (response) => {
    if (response.status === "success") {
      this.getIllnessHistory();
    }
  };

  deleteDrugAlleryItem = (item, event) => {
    var service_url = Constants.OPTHALMOLOGY_DELETE_DRUGALLERGY + `${item.id}/`;

    var data = {};
    if (this.state.userType == Constants.ROLE_OPTOMETRY) {
      data["doctor_id"] = this.state.selectedClinicOrDoctor.id;
    }
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getDeleteDrugAllergySuccess,
      this.getOcularHistoryFailure
    );
  };

  getDeleteDrugAllergySuccess = (response) => {
    if (response.status === "success") {
      this.getDrugAllergyHistory();
    }
  };

  // renderComplaintItem = (item, index) => {
  //   return (
  //     <View>
  //       <View style={{ paddingTop: 10 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             // height: 50,
  //             alignItems: "center"
  //             // paddingBottom: 10
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
  //               Eyes
  //             </Text>
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.complaint_description}
  //             </Text>
  //           </View>

  //           <View
  //             style={{
  //               width: "20%"
  //               // alignItems: "center"
  //             }}
  //           >
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.duration_time}
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.comments}
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "20%",
  //               alignItems: "center",
  //               height: 60,
  //               flexDirection: "row"
  //             }}
  //           >
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 paddingTop: 5,
  //                 paddingBottom: 5,
  //                 backgroundColor: color.white,
  //                 height: 50
  //               }}
  //             >
  //               <TouchableOpacity onPress={this.editComplaints.bind(this, item)}>
  //                 {this.renderIdButton("pencil")}
  //               </TouchableOpacity>

  //               <TouchableOpacity>
  //                 {this.renderIdButton("trash")}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //       <View style={{ height: 0.5, backgroundColor: "#E0E0E0" }} />
  //     </View>
  //   );
  // };

  renderItem = (item, index, title) => {
    return (
      <View>
        <View style={{ paddingTop: '0.6vw' }}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              // height: 50,
              alignItems: "center",
              // paddingBottom: 10
            }}
          >
            <View style={{ flex: 0.25, paddingRight: '0.6vw' }}>
              <Text style={Style.contentTableDataLead}>
                {item.illness_name || item.drug_name}
              </Text>
              <Text style={Style.contentTableData}>
                {item.year || 0}Y {item.month || 0}M{/* {item.day || 0} */}
                {/* D {item.treatment?`(${item.treatment})`:null} */}
              </Text>
            </View>

            <View style={{ flex: 0.5, paddingRight: '0.6vw' }}>
              <Text style={Style.contentTableDataLead}>{item.treatment}</Text>
            </View>

            <View
              style={styles.ItemActionView}
            >
              <View
                style={[styles.ItemActionContainer, {backgroundColor: color.white,}]}
              >
                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={this.editItem.bind(
                    this,
                    item,
                    "illness",
                    isSystemic,
                    title
                  )}
                >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={this.deleteIllnessItem.bind(this, item)}
                >
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={Style.contentPageLine} />
      </View>
    );
  };

  renderOcular = (title, type, data, key, triggerNewItem) => {
    return (
      <View style={{ width: "50%" }}>
        <View style={{ marginTop: 0 }}>
          <View
            style={styles.OcularView}
          >
            <View
              style={{
                width: "30%",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ systemicIllnessSelectedView: title }, () => {
                    triggerNewItem(key);
                  });
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: '1vw',
                      color: color.themeDdrNotesUnSelectedTitleark,
                      fontWeight: "bold",
                    },
                    isSystemic == this.props.selectedView &&
                    (this.state.systemicIllnessSelectedView == title ||
                      this.state.systemicIllnessSelectedView == "")
                      ? { color: color.themeDark, fontWeight: "bold" }
                      : null,
                  ]}
                >
                  {title}
                </Text>
              </TouchableOpacity>
              {/* <Text style={ Style.contentTableDataLead }>{title}</Text> */}
            </View>
            <View
              style={[styles.OcularFlatListView, {backgroundColor: color.white}]}
            />
          </View>
        </View>

        <FlatList
          data={data}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) => this.renderItem(item, index, title)}
        />

        {/* <View>
          <TouchableOpacity onPress={() => triggerNewItem(key)}>
            {this.renderIconBtn("plus", "New", false, key)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  renderAllergyItem = (item, index, title) => {
    return (
      <View>
        <View style={{ paddingTop: '0.6vw' }}>
          <View
            style={styles.AllergyItemView}
          >
            <View style={{ flex: 0.25, paddingRight: '0.6vw' }}>
              <Text style={Style.contentTableDataLead}>{item.name}</Text>
              <Text style={Style.contentTableData}>
                {item.no_of_years || 0}Y {item.no_of_months || 0}M
                {/* {item.no_of_days || 0} */}
                {/* D {item.comments?`(${item.comments})`:null} */}
              </Text>
            </View>

            <View style={{ flex: 0.5, paddingRight: '0.6vw' }}>
              <Text style={Style.contentTableDataLead}>{item.comments}</Text>
            </View>

            <View
              style={styles.ItemActionView}
            >
              <View
                style={[styles.ItemActionContainer, {backgroundColor: color.white}]}
              >
                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={this.editItem.bind(
                    this,
                    item,
                    "drugallergy",
                    isSystemicAllergy,
                    title
                  )}
                >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={this.state.isLocked}
                  onPress={this.deleteDrugAlleryItem.bind(this, item)}
                >
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={Style.sectionItemLine} />
      </View>
    );
  };

  renderAllergy = (title, type, data, key, triggerNewItem, isNil) => {
    
    return (
      <View style={{ width: "50%" }}>
        <View style={{ marginTop: 0 }}>
          <View
            style={{
              marginLeft: '0.3vw',
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              height: '3.3vw',
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "30%",
              }}
            >
              <TouchableOpacity
                disabled={this.state.isLocked}
                onPress={() => {
                  triggerNewItem(key, isNil),
                    this.setState({ systemicIllnessSelectedView: title });
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: '1vw',
                      color: color.drNotesUnSelectedTitle,
                      fontWeight: "bold",
                    },
                    this.state.systemicIllnessSelectedView == title &&
                    "isSystemicAllergy" == this.props.selectedView
                      ? { color: color.themeDark }
                      : null,
                  ]}
                >
                  {title}
                </Text>
              </TouchableOpacity>

              {/* <Text style={ Style.contentTableDataLead }>{title}</Text> */}
            </View>
            <View
              style={[styles.OcularFlatListView, {backgroundColor: color.white}]}
            />
            {/* {data.length === 0 || data[0].is_nil ? ( */}
              <Text style={{fontWeight:'bold', color:'darkgray', fontSize: '1.1vw', marginLeft: '2vw'}}>Nil</Text>
              <CheckBox
                right
                value={this.state.isBoxChecked}
                style={{width: '1.2vw', height: '1.2vw'}}
                disabled={data.length >0}
                // checkedColor="blue"
                onValueChange={() => {
                  this.checkBoxPress(key, title);
                }}
              />
            {/* ) : null} */}
          </View>
        </View>

        {data.length > 0 ? (
          <FlatList
            data={data}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            renderItem={({ item, index }) =>
              !item.is_nil?this.renderAllergyItem(item, index, title):null
            }
          />
        ) : null}

        {/* <View>
          <TouchableOpacity onPress={() => triggerNewItem(key)}>
            {this.renderIconBtn("plus", "New", false, key)}
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  render() {
    // console.log("allergydata",this.state.allergyData)
    return (
      <View>
        {this.renderTitleBorder(
          this.state.isComplaint,
          "Systemic Illness",
          "isSystemic",
          this.props.triggerNewItem
        )}

        <View
          style={{
            flexDirection: "row",
            width: platform === "web" ? "100%" : "75%",
            justifyContent: "space-between",
          }}
        >
          {this.renderOcular(
            "Illness",
            "",
            this.state.illnessData,
            "isSystemic",
            this.props.triggerNewItem
          )}
          {this.renderAllergy(
            "Drug Allergy",
            "",
            this.state.allergyData,
            "isSystemicAllergy",
            this.props.triggerNewItem,
            this.state.isBoxChecked
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
      ItemActionView: {
        alignItems: "center",
        height: '4.1vw',
        flexDirection: "row",
        flex: 0.25,
        paddingRight: '0.6vw',
      },
      ItemActionContainer: {
        flexDirection: "row",
        paddingTop: '0.3vw',
        paddingBottom: '0.3vw',
        height: '3.3vw',
      },
      OcularView: {
        marginLeft: '0.3vw',
        width: "75%",
        flexDirection: "row",
        justifyContent: "space-between",
        height: '3.3vw',
        alignItems: "center",
      },
      OcularFlatListView: {
        width: '11.5vw',
        alignItems: "center",
        height: '3.3vw',
      },
      AllergyItemView: {
        marginLeft: '0.3vw',
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        // height: 50,
        alignItems: "center",
        marginBottom: '0.3vw',
        // paddingBottom: 10
      }
})