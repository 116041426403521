//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Picker, TextInput,TouchableOpacity } from 'react-native';
import { color } from '../../../../styles/Color';
import Style from "../../../../styles/Style";
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import { OBGYNHistorySectionHeading, CommonButton, CommonAddButton } from '../../BaseComponent'
// import { TouchableOpacity } from 'react-native-gesture-handler';

import Icon from "react-native-vector-icons/Ionicons";


// create a component
const pageName = "isOBGYNSurgeries"
class OBGYNSurgeriesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageName: this.props.selectedPageName,

            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            surgery_name: "",
            showNewSurgeryLineItem: false,

            // OBGYNSurgeriesHistoryList: {},

            // infertilitySurgerySelectedValue: {},
            // infertilitySurgeryYear: "",

            // ovarianSurgerySelectedValue: {},
            // ovarianSurgerYear: "",

            // dilationAndCurettageSelectedValue: {},
            // dilationAndCurettageYear: "",

            // cystoceleRepairSelectedValue: {},
            // cystoceleRepairYear: "",

            // tuboplastySelectedValue: {},
            // tuboplastyTear: "",

            // pelvicFlooRepairSelectedValue: {},
            // pelvicFlooRepairYear: "",

            // tubalLigationSelectedValue: {},
            // tubalLigationYear: "",


            // uterineProlapseRepairSelectedValue: {},
            // uterineProlapseRepairYear: "",


            // laproscopySelectedValue: {},
            // laproscopyYear: "",

            OBGYNSurgeryList: [],

            additionalSurgerynew: [],

            additionalSurgerynewReq: [],
            surgeryYears:[]


        }
        // this.onPressButton = this.onPressButton.bind(this);
    }

    componentWillReceiveProps(props) {
        this.state.selectedPageName = props.selectedPageName;

        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            if (props && props.editHistoryItem && props && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                // console.log("editHistoryItem --------------->> " + JSON.stringify(props.editHistoryItem.editItem))

                // var data = props.editHistoryItem.editItem.OBGYNSurgeryList.concat(props.editHistoryItem.editItem.OBGYNAdditionSurgeryList)
                var data = props.editHistoryItem.editItem.OBGYNSurgeryList;

                var additionalSurgery=props.editHistoryItem.editItem.OBGYNAdditionSurgeryList
                var getAdditionalSurgery=[]
                for(var i = 0; i<additionalSurgery.length; i++){
                    var getData = additionalSurgery[i];
                    getData["is_surgery_done"] =true;
                    getAdditionalSurgery.push(getData)
                }

                this.setState({
                    OBGYNSurgeryList: data,
                    additionalSurgerynewReq:getAdditionalSurgery
                })
            }
        }
    }

    componentDidMount(){
        var years = [];

        var today = new Date();
          var currentYear = parseInt(today.getFullYear());
          for (var i = currentYear; i > 1900; i--) {
            years.push(i);
          };
    
          this.setState({
            surgeryYears:years
          })
    }

    obgynYesNoButton(key, selectedValue, isNew=false) {
        var data = [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ]

        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 }}>
                {
                    data.map((item, index) => {
                        return (
                            <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                <CommonButton
                                    disable={isNew ? true : false}
                                    item={item}
                                    selectedvalue={selectedValue ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" }}
                                    butttonText={item.label}
                                    buttonType={"outlineNonTheme"}
                                    buttonIcon={""}
                                    rightIcon={false}
                                    // buttonAction={this.onPressButton.bind(this, key)}
                                    buttonAction={isNew?this.onPressNewActionData.bind(this, key):this.onPressExistActionData.bind(this, key)}
                                    buttonKey={"yesNoButton"} />
                            </View>
                        )
                    })
                }
            </View>
            // </View>
        )
    }

    onPressExistActionData(index, key, selectedValue){
        this.onPressButton( index, key, selectedValue, false);
    }

    onPressNewActionData(index, key, selectedValue){
        this.onPressButton( index, key, selectedValue, true);
    }

    onPressButton(index, key, selectedValue, isNew) {

        if(isNew===true){
            if ("yesNoButton" == key) {
                var { additionalSurgerynewReq } = this.state;
                additionalSurgerynewReq[index].is_surgery_done = selectedValue.value == "Yes" ? true : false;
                this.setState({
                    additionalSurgerynewReq
                })
    
            } else if ("picker" == key) {
                var { additionalSurgerynewReq } = this.state;
                additionalSurgerynewReq[index].surgery_year = selectedValue;
                this.setState({
                    additionalSurgerynewReq
                })
            }
        }else{
            if ("yesNoButton" == key) {
            var { OBGYNSurgeryList } = this.state;
            OBGYNSurgeryList[index].is_surgery_done = selectedValue.value == "Yes" ? true : false;
            this.setState({
                OBGYNSurgeryList
            })

            } else if ("picker" == key) {
                var { OBGYNSurgeryList } = this.state;
                OBGYNSurgeryList[index].surgery_year = selectedValue;
                this.setState({
                    OBGYNSurgeryList
                })
            }
        }
    }

    rowHeadingText(title) {
        return (
            <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{title}</Text>
        )
    }

    renderDropDownYear(index, selectedValue, isNew=false) {
        return (
            <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 15, fontWeight: '500', marginTop: 20 }}>{"Year"}</Text>
                <View>
                    <Picker
                        disabled = {isNew ? true : false}
                        selectedValue={selectedValue}
                        style={[Style.pickerRoundCornerView, Style.allButtonBorderRadius, { backgroundColor: color.themeDark, marginTop: 14, paddingLeft: 5, marginLeft: 5, height: 30, color: color.white }]}
                        itemStyle={[Style.pickerFont, { color: color.white }]}
                        onValueChange={(itemvalue) => {
                            this.onPressButton(index, "picker", itemvalue, isNew)
                        }}
                    >
                        <Picker.Item label="Select year" value="" />
                        {this.state.surgeryYears && this.state.surgeryYears.map((i, index) => (
                            <Picker.Item key={index} label={i} value={i} />
                        ))}
                    </Picker>
                </View>
            </View>
        )
    }

    renderOBGYNSurgeriesNewRow(title,index,year){
        return (
            <View>
                <View style={{ flexDirection: 'row',alignItems: 'center' }}>
                    <View style={{ flex: 0.34 }}>
                        {this.rowHeadingText(title)}
                    </View>
                    {/* <View style={{ flex: 0.34 }}>
                        {this.obgynYesNoButton(index, selectedButtonValue)}
                    </View> */}
                    <View style={{ flex: 0.32 }}>
                        {this.renderDropDownYear(index, year, true)}
                    </View>
                    <View style={{marginLeft:30,marginTop:10}}>
                    {this.renderIconButton("ios-close-circle-outline", "remove",index)}
                    </View>
                </View>
            </View>
        )
    }

    renderOBGYNSurgeriesRow(title, index, selectedButtonValue, selectedPickerValue,isNew) {
        return (
            <View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.34 }}>
                        {this.rowHeadingText(title)}
                    </View>
                    <View style={{ flex: 0.34 }}>
                        {this.obgynYesNoButton(index, selectedButtonValue, isNew)}
                    </View>
                    <View style={{ flex: 0.32 }}>
                        {this.renderDropDownYear(index, selectedPickerValue, isNew)}
                    </View>
                </View>
            </View>
        )
    }

    // _renderOBGYNSurgeriesRow(title,selectedButtonKey,selectedButtonValue,selectedPickerKey,selectedPickerValue,){
    //     return(
    //         <View>
    //             <View style={{flexDirection:'row'}}>
    //                 <View style={{flex:0.34}}>
    //                     {this.rowHeadingText(title)}
    //                 </View>
    //                 <View style={{flex:0.34}}>
    //                     {this.obgynYesNoButton(selectedButtonKey,selectedButtonValue)}
    //                 </View>
    //                 <View style={{flex:0.32}}>
    //                     {this.renderDropDownYear(selectedPickerKey,selectedPickerValue)}
    //                 </View>
    //             </View>
    //         </View>
    //     )
    // }

    onPressAddButton() {

        var {OBGYNSurgeryList,additionalSurgerynewReq,additionalSurgerynew} = this.state;

        var additionalSurgeryList = additionalSurgerynewReq.concat(additionalSurgerynew)

        var service_url = Constants.OBGYN_SURGERY_GET_POST ;
        var data ={
            "patient_id":this.state.patientAppointment.patient_id,
            "appointment_id":this.state.patientAppointment.appointment_id,
            "deleted_surgeries": [],
            "obstetrical_surgery_history":OBGYNSurgeryList,
            "obstetrical_additional_surgery_history":additionalSurgeryList
        }
       if(this.state.OBGYNSurgeryList.length>0){
        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getOBGYNSurgerySaveSuccess,
            this.getOBGYNSurgerySaveFailure
        );
      }else{
          this.props.showResposeValue("error","Please select the values")
      }
     }

     getOBGYNSurgerySaveSuccess = success =>{
        if (success.status == "success") {
            this.props.showResposeValue('success', success.message);
            // this.clearAllValues();
            this.props.refreshRighSideComponent(pageName);
        } else {
            this.props.showResposeValue('error', success.message)
        }
        //  console.log("test  =======? "+JSON.stringify(success))
     }
     getOBGYNSurgerySaveFailure = error =>{
        this.props.showResposeValue('error', error.message)

        console.log("test  =======? error -=-=-="+JSON.stringify(error))

     }
     

    render() {

        return (
            <View style={styles.container}>
                <OBGYNHistorySectionHeading disabled={true} heading={"OBGYN Surgeries"} />
                {
                    this.state.OBGYNSurgeryList.length > 0 && this.state.OBGYNSurgeryList.map((item, index) => {
                        return (
                            <View>{
                                this.renderOBGYNSurgeriesRow(
                                    item.surgery_name,
                                    index,
                                    item.is_surgery_done,
                                    item.surgery_year,
                                    false)
                            }</View>
                        )
                    })
                }
                {
                    this.state.additionalSurgerynewReq.length > 0 && this.state.additionalSurgerynewReq.map((item, index) => {
                        return (
                            <View>{
                                this.renderOBGYNSurgeriesRow(
                                    item.surgery_name,
                                    index,
                                    item.is_surgery_done,
                                    item.surgery_year,
                                    true)
                            }</View>
                        )
                    })
                }
                {
                    this.state.additionalSurgerynew.length > 0 && this.state.additionalSurgerynew.map((item, index) => {
                        return (
                            <View>{
                                this.renderOBGYNSurgeriesNewRow(
                                    item.surgery_name,
                                    index,
                                    item.surgery_year,
                                    true)
                            }</View>
                        )
                    })
                }
                {

                    !this.state.showNewSurgeryLineItem ?
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ showNewSurgeryLineItem: true })
                            }}

                            style={[{ height: 35, width: 35, marginTop:10,backgroundColor: color.themeDark, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', alignContent: 'center' }, Style.allButtonBorderRadius]}>
                            <Text style={{ fontWeight: 'bold', fontSize: 25, color: color.white }}>+</Text>
                        </TouchableOpacity>
                        :
                        <View style={{ flexDirection: 'row',marginTop:10, alignItems: 'flex-end', justifyContent: 'space-between' }}>
                            {this.commonSurgeryTextBox()}
                            {this.commonSurgeryPicker()}
                            {this.renderIconButton("ios-checkmark-circle", "save")}
                            {this.renderIconButton("ios-close-circle-outline", "cancel")}
                        </View>
                }



                <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />


                {/* {this.renderOBGYNSurgeriesRow("Infertility-Surgery","infertilitySurgerySelectedValue",states.infertilitySurgerySelectedValue,"infertilitySurgeryYear",states.infertilitySurgeryYear)} */}
            </View>
        );
    }

    renderIconButton(icon, type,index="") {
        return (
            <TouchableOpacity onPress={() => {
                if (type == "cancel") {
                    var states = this.state;
                    states["surgery_year"] = "";
                    states["surgery_name"] = "";
                    states["showNewSurgeryLineItem"] = false
                    this.setState({ states })
                } else if(type == "remove") {
                   var {additionalSurgerynew}= this.state;
                   additionalSurgerynew.splice(index,1);
                   this.setState({ additionalSurgerynew })
                }else {
                    var { additionalSurgerynew } = this.state
                    var data = {
                        "surgery_name": this.state.surgery_name,
                        "surgery_year": this.state.surgery_year
                    }
                    additionalSurgerynew.push(data)
                    var states = this.state;
                    states["surgery_year"] = "";
                    states["surgery_name"] = "";
                    states["showNewSurgeryLineItem"] = false

                    this.setState({
                        additionalSurgerynew, states
                    })
                }
            }
            
            }>
                <Icon name={icon} size={30} color={color.themeDark} />
            </TouchableOpacity>
        )
    }

    commonSurgeryPicker() {
        return (
            <View>

                <Text style={{ fontSize: 13, marginBottom: 8, color: "black", fontWeight: '500' }}>{"Surgery Year"}</Text>

                <Picker
                    selectedValue={this.state.surgery_year}
                    style={[Style.pickerRoundCornerView, Style.allButtonBorderRadius, { backgroundColor: color.themeDark, paddingLeft: 5, marginLeft: 5, height: 30, color: color.white, width: 100 }]}
                    itemStyle={[Style.pickerFont, { color: color.white }]}
                    onValueChange={(itemvalue) => {
                        this.setState({
                            surgery_year: itemvalue
                        })
                    }}
                >
                    <Picker.Item label="Select year" value="" />
                    {/* <Picker.Item label="2020" value="2020" />
                    <Picker.Item label="2019" value="2019" />
                    <Picker.Item label="2018" value="2018" />
                    <Picker.Item label="2017" value="2017" /> */}
                    {this.state.surgeryYears && this.state.surgeryYears.map((i, index) => (
                        <Picker.Item key={index} label={i} value={i} />
                    ))}
                </Picker>
            </View>
        )
    }

    commonSurgeryTextBox() {
        return (
            <View>

                <Text style={{ fontSize: 13, marginBottom: 8, color: "black", fontWeight: '500' }}>{"Surgery Name"}</Text>

                <TextInput
                    style={[{ width: 170, height: 33, backgroundColor: color.white, borderWidth: 1, bordercolor: color.themeDark }, Style.allButtonBorderRadius]}
                    value={this.state.surgery_name}
                    onChangeText={(text) => {
                        this.setState({
                            surgery_name: text
                        })
                    }}
                />
            </View>
        )
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

//make this component available to the app
export default OBGYNSurgeriesNew;
