import React from "react";
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  TextInput,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import { TextInput } from "react-native-gesture-handler";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import Icon from "react-native-vector-icons/FontAwesome";
import { CommonHistorySectionHeader } from '../BaseComponent'
import { red } from "@material-ui/core/colors";

const platform = Platform.OS;
const pageTitle = "Vitals";
const pageName = "vitals";
const isPageName = "isVitals";

export default class Vitals extends BaseComponentStyle {
  constructor(props) {
    super(props);

    let userType = '';

    try {
      userType = this.props.userType;
    } catch (error) { }

    this.state = {
      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      isComplaint: isPageName === this.props.selectedView ? true : false,
      vitalsHeight: "",
      vitalsWeight: "",
      vitalsBMI: "",
      vitalsPulseRate: "",
      vitalsPulseOximetry: "",
      vitalsBP: "",
      vitalsTemperature: "",
      vitalsRandomBooldsugar: "",
      vitalssystolicbloodpressure: "",
      vitalsdiatolicbloodpressure: "",
      selectedVitals: [],
      validationfailed: false,
      errorMesssage: "",
      showvitals: true,
      vitalsDetails: {},
      userType: userType,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      selectedIpPatientDetails:this.props.selectedIpPatientDetails
    };
  }

  componentDidMount() {
    // this.getComplaints();
    if (this.state.userType === "FO") {
      setTimeout(()=>{
      this.triggerNewItem("isVitals")
    },200)}
    setTimeout(() => {
      this.getVitalDetails()
    }, 1000);
  }

  componentWillReceiveProps(props) {

    if (props.vitalsData.length > 0) {
      this.setState({
        selectedVitals: props.vitalsData
      })
    }

    if (props.reloadData && props.reloadData === pageName) {

      // call once
      // this.getComplaints();
      this.getVitalDetails()
      // refresh false
      this.props.refreshData("");
    }

    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isComplaint) {

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {

    let { userType, patientAppointment } = this.state;
    let service_url = '';

    if (userType === Constants.ROLE_FO) {
      if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {
        service_url = Constants.FO_PATIENT_VITALS_DETAILS + "?appointment_id=" + this.state.selectedIpPatientDetails.appointment_id;

      } else {
        service_url = Constants.FO_PATIENT_VITALS_DETAILS + "?appointment_id=" + patientAppointment.appointment_id;

      }

    } else {

      service_url = Constants.DOCTOR_PATIENT_VITALS_DETAILS + "?appointment_id=" + patientAppointment.appointment_id;
    }

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getComplaintsSuccess,
      this.getComplaintsFailure
    );
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      var field = this.state;
     // console.log("success----->")
      field["complaintsData"] = response.data;
      this.setState({ field });
    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    // console.log("Failer----->")
    console.log(error);
  };

  editComplaints = () => {


    this.props.editvitals(true, this.state.vitalsDetails)

  }

  // renderComplaintItem = (item, index) => {
  //   return (
  //     <View>
  //       <View style={{ paddingTop: 10 }}>
  //         <View
  //           style={{
  //             marginLeft: 5,
  //             width: platform === "web" ? "100%" : "75%",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             // height: 50,
  //             alignItems: "center"
  //             // paddingBottom: 10
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
  //               Eyes
  //             </Text>
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.complaint_description}
  //             </Text>
  //           </View>

  //           <View
  //             style={{
  //               width: "20%"
  //               // alignItems: "center"
  //             }}
  //           >
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.duration_time}
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "30%"
  //             }}
  //           >
  //             <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
  //               {item.comments}
  //             </Text>
  //           </View>
  //           <View
  //             style={{
  //               width: "20%",
  //               alignItems: "center",
  //               height: 60,
  //               flexDirection: "row"
  //             }}
  //           >
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 paddingTop: 5,
  //                 paddingBottom: 5,
  //                 backgroundColor: color.white,
  //                 height: 50
  //               }}
  //             >
  //               <TouchableOpacity onPress={this.editComplaints.bind(this, item)}>
  //                 {this.renderIdButton("pencil")}
  //               </TouchableOpacity>

  //               <TouchableOpacity>
  //                 {this.renderIdButton("trash")}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //       <View style={{ height: 0.5, backgroundColor: "#E0E0E0" }} />
  //     </View>
  //   );
  // };
  renderTextbox(item) {
    return (
      <View style={{ marginLeft: 60, marginTop: 20 }}>
        <Text style={{ color: color.subtextcolor, fontSize: 14 }}>{item.key}</Text>
        <Text style={{ color: color.textcolor, fontSize: 16 }}>{item.value}{" "}
          {item.key === "Height" ? "cms" :
            item.key === "Weight" ? "Kgs" :
              item.key === "Systolicbloodpressure" || item.key === "Diatolicbloodpressure" ? "mm/Hg" :
                item.key === "Temperture" || item.key === "PulseOximetry" || item.key === "Pulserate" ||
                  item.key === "RandomBooldsugar" ? "C" : null}</Text>
      </View>
    )
  }




  triggerNewItem = (key) => {

    this.props.triggerNewItem(key);
    this.editComplaints()
  }

  receiveData = (key, value) => {
    if (key === "Height") {
      this.setState({
        vitalsHeight: value,
      })
    } else if (key === "Weight") {
      this.setState({
        vitalsWeight: value,
      })
    } else if (key === "Temperature") {
      this.setState({
        vitalsTemperature: value
      })
    } else if (key === "BP") {
      this.setState({
        vitalsBP: value,
      })
    } else if (key === "Pulse Oximetry") {
      this.setState({
        vitalsPulseOximetry: value
      })
    } else if (key === "Pulse Rate") {
      this.setState({
        vitalsPulseRate: value,
      })
    }
  }

  renderSelectedTextbox(item) {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ width: 150 }}>
          {this.renderVitalTextField(item, this.receiveData.bind(this))}
        </View>
      </View>
    );
  }


  renderVitalTextField = (item, callBack) => {
    if (platform === "web") {
      var title = "";
      var value;
      if (item.key === "Height") {
        title = "Height in cms";
        value = this.state.vitalsHeight;
      } else if (item.key === "Weight") {
        title = "Weight in kg";
        value = this.state.vitalsWeight
      } else if (item.key === "Temperature") {
        title = item.key
        value = this.state.vitalsTemperature
      } else if (item.key === "Pulse Oximetry") {
        title = item.key
        value = this.state.vitalsPulseOximetry
      } else if (item.key === "Pulse Rate") {
        title = item.key
        value = this.state.vitalsPulseRate
      } else {
        title = item.key
      }



      return (
        <View style={{ marginBottom: 20 }}>
          <TextInput
            placeholder={title}
            underlineColorAndroid="transparent"
            style={{ padding: 10, color: "grey", height: 35, borderRadius: 20, borderColor: "grey", borderWidth: 1 }}
            placeholderTextColor={"grey"}
            autoCapitalize="none"
            keyboardType='numeric'
            value={value}
            onChangeText={text => {
              callBack(item.key, text);
              this.setState({ validationfailed: false })
            }}
            onFocus={false}
          />
        </View>
      )
    } else { }
  };




  renderSave = (icon, text, createBtn) => {
    return (
      <View
        style={{
          height: 35,
          width: text === "New" ? 100 : 103,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 20 : 8,
          flexDirection: "row",
          backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themePink,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 0,
          borderColor: text === "New" ? "#848484" : color.white
        }}
      >
        <TouchableOpacity onPress={() => { this.saveVitalsData() }}>
          <Icon name={icon} size={platform === "web" ? 15 : 20} color={text === "New" ? "#848484" : "#ffffff"} />
          <Text
            style={{
              fontSize: platform === "web" ? 10 : 13,
              color: text === "New" ? "#848484" : color.white,
              fontWeight: "700"
            }}
          >
            {text}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

    getVitalDetails() {

    let { patientAppointment, userType } = this.state;
    let service_url = '';
    if (userType === Constants.ROLE_FO) {
      if (this.state.selectedIpPatientDetails && this.state.isCurrentPageIsIP) {

        service_url = Constants.FO_PATIENT_VITALS_DETAILS + "?appointment_id=" + this.state.selectedIpPatientDetails.appointment_id;

      } else {
        service_url = Constants.FO_PATIENT_VITALS_DETAILS + "?appointment_id=" + patientAppointment.appointment_id;

      }

    } else {

      service_url = Constants.DOCTOR_PATIENT_VITALS_DETAILS + "?appointment_id=" + patientAppointment.appointment_id;
    }

    // var service_url = Constants.VITALS_DETAILS + "?appointment_id=" + this.props.patientAppointment.appointment_id;
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.vitalsGedSuccess,
        this.vitalsGetFailure
      );
    }, 1000);
  }

  vitalsGedSuccess = success => {
    
    if (success.status == "success") {
      this.setState({
        vitalsDetails: success.data?.length  > 0  ? success.data[success.data.length - 1] : []
      })
    }
    //reload page
    this.props.refreshData("complaints");
  }
  vitalsGetFailure = error => {
    // console.log("vitalsGetFailure ****************> " + JSON.stringify(error));
  }

  saveVitalsData() {

    let { userType } = this.state;
    let service_url = '';

    if (userType === Constants.ROLE_FO) {

      service_url = Constants.FO_PATIENT_VITALS_DETAILS;
    } else {

      service_url = Constants.DOCTOR_PATIENT_VITALS_DETAILS;
    }

    var data = {
      "appointment_id": this.props.patientAppointment.appointment_id?this.props.patientAppointment.appointment_id:this.state.selectedIpPatientDetails.appointment_id? this.state.selectedIpPatientDetails.appointment_id:null,
      "height_cm": this.state.vitalsHeight,
      "weight_kg": this.state.vitalsWeight,
      "bmi": this.state.vitalsBMI,
      "temperature": this.state.vitalsTemperature,
      "pulse_oximetry": this.state.vitalsPulseOximetry,
      // "blood_sugar" : this.state.,
      "pulse_rate": this.state.vitalsPulseRate,
    }

    // var service_url = Constants.VITALS_DETAILS;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.vitalsPostSuccess,
      this.vitalsPostFailure
    );
  }

  vitalsPostSuccess = success => {
    // console.log("vitalsPostSuccess ****************> " + JSON.stringify(success));
    this.setState({ validationfailed: true, errorMesssage: success.message })

  }
  vitalsPostFailure = error => {
    console.log("vitalsPosterror ****************> " + JSON.stringify(error));
  }

  renderText = (label, value, measure, symbol) => {
    return (

      <View style={{ marginRight: 30 }}>
        <Text style={{ color: color.subtextcolor, fontSize: 14, }}>{label}</Text>
        <Text style={{ color: color.textcolor, fontSize: 16, marginTop: 5 }}>{value} {value ? symbol ? symbol + measure : measure : null}</Text>
      </View>
    )
  }

  render() {

    // console.log(" ***************>>>> "+this.state.userType)

    if (this.state.vitalsHeight > 0 && this.state.vitalsWeight) {
      this.state.vitalsBMI = this.state.vitalsWeight / (this.state.vitalsHeight * this.state.vitalsHeight);
    }
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            // paddingTop: 5,
            // paddingBottom: 5,
            // backgroundColor: color.white,
            // height: 50
          }}
        >
          {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}

          {/* <TouchableOpacity  onPress={this.editComplaints.bind(this)} >
                  {this.renderIdButton("pencil")}
                </TouchableOpacity> */}


        </View>
        {/* {
          this.state.selectedVitals.length > 0 ?
            <View
              style={{
                justifyContent: "flex-end",
                width: "90%",
                alignItems: "flex-end"
              }}
            >
              {this.renderSave("save", "Save", false, "")}
            </View>
            :
            null
        } */}

        {/* <FlatList
          data={this.state.selectedVitals}
          numColumns={5}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          containerStyle={{ alignItems: "center", alignContent: "center", marginTop: 20 }}
          renderItem={({ item }) => this.renderSelectedTextbox(item)}
        />
         { this.state.validationfailed ?
        <Text style={{color: color.red, fontSize:12}}>{this.state.errorMesssage}</Text>:
   null } */}

        {/* <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: 150 }}>
            {this.renderGmailTextField("Height in cms","Height",this.receiveData.bind(this))}
          </View>
          <View style={{ width: 150, marginLeft: 10 }}>
            {this.renderGmailTextField("Weight in kg","Weight",this.receiveData.bind(this))}
          </View>
        </View> */}
        {/* <Text
            style={{
              fontSize: 17,
              color: color.themePink,
              paddingTop: 10,
              marginLeft: 30
            }}
          >
          {this.state.vitalsBMI}{"  BMI"}
          </Text> */}

        {/* <View style={{ flexDirection: "row" }}>
          <View style={{ width: 150 }}>
            {this.renderGmailTextField("Blood Pressure")}
          </View>
          <View style={{ width: 130, marginLeft: 10 }}>
            {this.renderGmailTextField("Temperature")}
          </View>
          <View style={{ width: 150, marginLeft: 10 }}>
            {this.renderGmailTextField("Pulse Oximetry")}
          </View>
          <View style={{ width: 130, marginLeft: 10 }}>
            {this.renderGmailTextField("Pulse Rate")}
          </View>
        </View> */}
        {/* <FlatList 
         data={this.state.selectedVitals}
         numColumns={5}
         scrollEnabled={false}
         showsVerticalScrollIndicator={false}
         showsHorizontalScrollIndicator={false}
         containerStyle={{ alignItems: "center", alignContent: "center", marginTop: 20 }}
         renderItem={({ item }) => this.renderTextbox(item)}
        
        /> */}


        <View style={styles.TableHeaderView1}>
          <CommonHistorySectionHeader
            heading1={"Height"}
            heading2={"Weight"}
            heading3={"BMI"}
            heading4={"Systolic Blood Pressure"}
            heading5={"Diastolic Blood Pressure"}
            columnSize={[0.18, 0.18, 0.18, 0.23, 0.23]}  //total value is == of 1
            noOfColumn={5}
          />
        </View>
        <View style={styles.TableContainer1}>
          <Text style={[Style.contentTableDataLead, { flex: 0.18, marginHorizontal: '1.2vw' }]}>{
            this.state.vitalsDetails.height_cm ? this.state.vitalsDetails.height_cm : ""}{this.state.vitalsDetails.height_cm ? " cm" : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.18 }]}>{
            this.state.vitalsDetails.weight_kg ? this.state.vitalsDetails.weight_kg : ""}{this.state.vitalsDetails.weight_kg ? " kg" : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.18,  }]}>{this.state.vitalsDetails.bmi ? this.state.vitalsDetails.bmi : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.23, color: this.state.vitalsDetails.blood_pressure_systolic <= 120 ? "green" : "red" }]}>{this.state.vitalsDetails.blood_pressure_systolic ? this.state.vitalsDetails.blood_pressure_systolic : ""}{this.state.vitalsDetails.blood_pressure_systolic ? " mm/Hg" : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.23, color: this.state.vitalsDetails.blood_pressure_diastolic <= 80 ? "green" : "red" }]}>{this.state.vitalsDetails.blood_pressure_diastolic ? this.state.vitalsDetails.blood_pressure_diastolic : ""}{this.state.vitalsDetails.blood_pressure_diastolic ? " mm/Hg" : ""}</Text>
        </View>










        <View style={styles.TableHeaderView1}>
          <CommonHistorySectionHeader
            heading1={"Temperature"}
            heading2={"Pulse Oximetry"}
            heading3={"Pulse Rate"}
            heading4={"Random Blood Glucose"}
            columnSize={[0.25, 0.25, 0.25, 0.25]}  //total value is == of 1
            noOfColumn={4}
          />
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[Style.contentTableDataLead, { flex: 0.25, marginHorizontal: '1.2vw', color: ( this.state.vitalsDetails.temperature_unit == "f" && this.state.vitalsDetails.temperature >= 97 && this.state.vitalsDetails.temperature <= 99 ) || (this.state.vitalsDetails.temperature_unit == "c" && this.state.vitalsDetails.temperature >= 36.1 && this.state.vitalsDetails.temperature <= 37.2 ) ? "green" : "red"  }]}>{
            this.state.vitalsDetails.temperature ? this.state.vitalsDetails.temperature : ""}{this.state.vitalsDetails.temperature && this.state.vitalsDetails.temperature_unit ? '\u00b0' : ""}{this.state.vitalsDetails.temperature ? this.state.vitalsDetails.temperature_unit : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.25, color: this.state.vitalsDetails.pulse_oximetry >= 95 && this.state.vitalsDetails.pulse_oximetry <= 100 ? "green" : "red" }]}>{this.state.vitalsDetails.pulse_oximetry ? this.state.vitalsDetails.pulse_oximetry : ""}{this.state.vitalsDetails.pulse_oximetry ? " %" : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.25, color: this.state.vitalsDetails.pulse_rate >= 60 && this.state.vitalsDetails.pulse_rate <= 100 ? "green" : "red" }]}>{this.state.vitalsDetails.pulse_rate ? this.state.vitalsDetails.pulse_rate : ""}{this.state.vitalsDetails.pulse_rate ? " /min" : ""}</Text>
          <Text style={[Style.contentTableDataLead, { flex: 0.25, color: this.state.vitalsDetails.blood_sugar <= 200 ? "green" : "red" }]}>{this.state.vitalsDetails.blood_sugar ? this.state.vitalsDetails.blood_sugar : ""}{this.state.vitalsDetails.blood_sugar ? " mg/dl" : ""}</Text>
        </View>




        {
          this.props.userType == "PD" ?
            <View style={styles.TableHeaderView1}>
              <CommonHistorySectionHeader
                heading1={"Head Circumference"}

                columnSize={[1]}  //total value is == of 1
                noOfColumn={1}
              />
            </View>
            : null
        }
        {
          this.props.userType == "PD" ?

            <View style={styles.TableContainer1}>
              <Text style={[Style.contentTableDataLead, { flex: 0.18, marginHorizontal: '1.2vw' }]}>{this.state.vitalsDetails.head_circumference ? this.state.vitalsDetails.head_circumference : ""}{this.state.vitalsDetails.head_circumference ? " cm" : ""}</Text>
            </View>
            : null
        }




      </View>
    );
  }
}
const styles = StyleSheet.create({
      TableHeaderView1: { 
        marginTop: '0.6vw', 
        marginBottom: '0.6vw' 
      },
      TableContainer1: { 
        flexDirection: 'row', 
        marginBottom: '0.6vw' 
      }
})