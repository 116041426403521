//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { HistoryYesOrNoSection, CommonHistorySectionHeader, CommonButton, CommonAddButton, DoctorNotesCommonRightSideHeader } from './../../BaseComponent'
import { color } from "../../../../styles/Color";
import Style from "../../../../styles/Style";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
var Messages = require('../../../../utils/InfoMessages')
// create a component
const pageName = "isMedicalHistory"

const problomsList = [
    { label: "Joint Pain", value: "Joint Pain" },
    { label: "Asthma", value: "Asthma" },
    { label: "Seizures", value: "Seizures" },
    { label: "Stroke", value: "Stroke" },
    { label: "Blood Problems", value: "Blood Problems" },
    { label: "Cancer", value: "Cancer" },
    { label: "Heart Disease", value: "Heart Disease" },
    { label: "Thyroid Disorder", value: "Thyroid Disorder" },
    { label: "High BP", value: "High BP" },
    { label: "Diabetes / Blood Sugar", value: "Diabetes / Blood Sugar" },
    { label: "Mental Illness", value: "Mental Illness" }
];

const bloodGroupList = [
    { label: "O-Ve", value: "O-Ve" },
    { label: "O+Ve", value: "O+Ve" },
    { label: "A-Ve", value: "A-Ve" },
    { label: "A+Ve", value: "A+Ve" },
    { label: "AB-Ve", value: "AB-Ve" },
    { label: "AB+Ve", value: "AB+Ve" },
    { label: "B+Ve", value: "B+Ve" },
    { label: "B-Ve", value: "B-Ve" },
]

export class MedicalHistoryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientAppointment: this.props.patientAppointment,
            patientInfo: this.props.patientInfo,

            newButtonValue: "",

            selectedMedicalHistoryProbloms: [],
            // problomsList: problomsList,
            showProblomsNewButtonFlag: true,

            selectedBloodGroup: {},
            bloodGroupList: bloodGroupList,
            showBloodGroupNewButtonFlag: true,

            commentTextBoxValue: "",

            problomsList: problomsList,
            getPropsDiseaseNames: [],
            showproblomsListNewButtonFlag: true,


            bloodGroupList: bloodGroupList,
            showBloodGroupNewButtonFlag: true,
            selectedDisease: [],

            diseaseNames: [],
            comments: "",
            id:""



        }
    }


    componentWillReceiveProps(props) {
        if (props && props.editHistoryItem && props.editHistoryItem.pageName == pageName) {
            // props.editHistoryItem=[]
            if (props && props.editHistoryItem && props.editHistoryItem.editItem && Object.keys(props.editHistoryItem.editItem).length > 0) {
                var data = props.editHistoryItem.editItem;
                var states = this.state
                // states["selectedMedicalHistoryProbloms"] = { label: data.disease_name, value: data.disease_name };
             states["selectedBloodGroup"] = data.blood_group?{ label:data.blood_group, value:data.blood_group }:null;
                states["comments"] = data.comments

                var {selectedMedicalHistoryProbloms,problomsList,getPropsDiseaseNames} = this.state
                selectedMedicalHistoryProbloms=[]
                var getMedicalListSelect = []
                if (data.patient_medical_history) {


                    var getmedicalList = data.patient_medical_history   // it will split the selected drug(string to ) as a  array 
                    // alert(JSON.stringify(getmedicalList))

                    if (getmedicalList.length > 0) {
                        for (let i = 0; i < getmedicalList.length; i++) {
                            if(getmedicalList[i].disease_name){
                                var preparData = {
                                label:getmedicalList[i].disease_name, value: getmedicalList[i].disease_name
                            }
                            // getMedicalListSelect.push(preparData)
                            selectedMedicalHistoryProbloms.push(preparData)
                            problomsList.push(preparData)
                            getPropsDiseaseNames.push(getmedicalList[i].disease_name)
                        }
                        }

                    }
                }
                // states["selectedMedicalHistoryProbloms"] = getMedicalListSelect
                
                selectedMedicalHistoryProbloms = this.removeDuplicate(selectedMedicalHistoryProbloms)
                problomsList = this.removeDuplicate(problomsList)

                this.setState({ states ,selectedMedicalHistoryProbloms,problomsList,getPropsDiseaseNames},()=>{
                    this.props.editHistory({},pageName)
                })

            }
        }
    }

    onPressButton(key, selectedValue) {
        var states = this.state;
        if (key == "showProblomsNewButtonFlag") {
            states[key] = false;
        } else if (key == "selectedMedicalHistoryProbloms") { // first check the button key (if it is multi select button)

            // start the multi select button function 
            var { selectedMedicalHistoryProbloms } = this.state;
            var getSelectedValue;
            var spliceData;

            if (selectedMedicalHistoryProbloms.length > 0) {
                for (var i = 0; i < selectedMedicalHistoryProbloms.length; i++) {
                    if (selectedMedicalHistoryProbloms[i].value == selectedValue.value) {
                        spliceData = selectedMedicalHistoryProbloms[i];
                        selectedMedicalHistoryProbloms.splice(i, 1)
                        var data = this.removeDuplicate(selectedMedicalHistoryProbloms); // pls add the remove duplicate function in this file 
                        this.setState({ selectedMedicalHistoryProbloms: data })
                        return
                    } else {
                        if (spliceData && spliceData.value !== selectedValue.value) {
                        } else {
                            getSelectedValue = selectedValue
                        }
                    }
                }

                if (getSelectedValue) { selectedMedicalHistoryProbloms.push(selectedValue); }
            } else {
                selectedMedicalHistoryProbloms.push(selectedValue)
            }

            var data = this.removeDuplicate(selectedMedicalHistoryProbloms);
            this.setState({ selectedMedicalHistoryProbloms: data })
            // end the multi select button function

        } else {
            states[key] = selectedValue;
        }

        this.setState({ states })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    onSubmitToAddValue() {
        var { problomsList,selectedMedicalHistoryProbloms } = this.state;
        var states = this.state;
        var preparData = { label: this.state.newButtonValue, value: this.state.newButtonValue }


        if (!this.state.showProblomsNewButtonFlag) {
            problomsList.push(preparData);
            problomsList = this.removeDuplicate(problomsList);
            states["newButtonValue"] = "";
            states["showProblomsNewButtonFlag"] = true;
            // states["selectedMedicalHistoryProbloms"] = preparData;

            selectedMedicalHistoryProbloms.push(preparData);
            selectedMedicalHistoryProbloms = this.removeDuplicate(selectedMedicalHistoryProbloms);
        }
        this.setState({
            problomsList, states,selectedMedicalHistoryProbloms
        })

    }


    richTextBox() {
        return (
            <View>
                <TextInput
                    multiline={true}
                    style={{ paddingTop: 10, paddingLeft: 10, width: "85%", alignSelf: 'center', marginTop: 10, borderColor: "#888888", borderWidth: 1, borderRadius: 4, height: 60 }}
                    value={this.state.comments}
                    onChangeText={(text) => {
                        this.setState({
                            comments: text
                        })
                    }}
                />
            </View>
        )
    }


    renderLabelWithButtonGroup(question, key, selectedValue, newButtonKey, newbuttonFlag, setOfData) {
        // console.log(JSON.stringify(selectedValue))
        var data = this.removeDuplicate(setOfData)

        return (
            <View style={[{ marginTop: 10, paddingLeft: 20, }]}>

                {/* <View>
                    <CommonHistorySectionHeader heading1={question} noOfColumn={1} />
                </View> */}

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                    {
                        data.map((item, index) => {
                            if(item.value&&item.label){

                            return (
                                <View style={{ marginBottom: 10, marginRight: 10 }} key={index}>
                                    <CommonButton
                                        item={item}
                                        selectedvalue={selectedValue}
                                        butttonText={item.label}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""}
                                        rightIcon={false}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={key} />
                                </View>
                            )
                            }
                        })
                    
                    }

                    <View style={{marginBottom: 10}}>
                        {
                            newButtonKey ?

                                newbuttonFlag ?
                                    <CommonButton
                                        item={{ label: "New", value: "New" }}
                                        selectedValue={{}}
                                        butttonText={"New"}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={require('./../../../../../assets/images/PlusIcon.png')}
                                        rightIcon={true}
                                        buttonAction={this.onPressButton.bind(this)}
                                        buttonKey={newButtonKey} />
                                    :
                                    <TextInput
                                        style={[{ paddingLeft: 15, width: 80, height: 30, borderColor: color.black, borderWidth: 1, backgroundColor: color.themeShadeBackground }, Style.allButtonBorderRadius]}
                                        onChangeText={(text) => {
                                            this.setState({ newButtonValue: text })
                                        }}
                                        onSubmitEditing={() => {
                                            this.onSubmitToAddValue()
                                        }}
                                    />

                                : null
                        }

                    </View>

                </View>
            </View>
        )
    }

    clearAllData() { 
        var states = this.state
        states["selectedBloodGroup"]={}
        states["selectedMedicalHistoryProbloms"]=[]
        states["comments"]=""
        this.setState({
            states
        })
    }

    onPressAddButton() {
        // console.log(
        //     "patientAppointment --- > " +
        //     JSON.stringify(this.state.patientAppointment)
        // );
        var getDrugs = [];
        var states = this.state;
        if(states.selectedMedicalHistoryProbloms.length > 0){
            for (let i = 0; i < states.selectedMedicalHistoryProbloms.length; i++) {
                getDrugs.push(states.selectedMedicalHistoryProbloms[i].value) 
            }
        }
        // console.log(
        //   "getDrugs --- > " +
        //     JSON.stringify(getDrugs)
        // );

            var deletedDiseaseNames=[]
        if(this.state.getPropsDiseaseNames.length>0){
            for (let i = 0; i < this.state.getPropsDiseaseNames.length; i++) {
                // const element = this.state.getPropsDiseaseNames[i];
                var checkIsAvailable  = getDrugs.includes(this.state.getPropsDiseaseNames[i]);

                if(checkIsAvailable){
                    // do nothing
                }
                else{
                    deletedDiseaseNames.push(this.state.getPropsDiseaseNames[i])
                }
                
            }
        }

        deletedDiseaseNames=this.removeDuplicate(deletedDiseaseNames)

        // getPropsDiseaseNames

        var data = {
            patient_id: this.state.patientAppointment.patient_id,
            appointment_id: this.state.patientAppointment.appointment_id,
            deleted_disease_names: deletedDiseaseNames,
            disease_names: getDrugs,
            blood_group: this.state.selectedBloodGroup != null ? Object.keys(this.state.selectedBloodGroup).length != 0 ? this.state.selectedBloodGroup.value : this.props.MedicalData.blood_group != ""? this.props.MedicalData.blood_group : "" :"",
            comments: this.state.comments
        };  
        if(this.state.id){
            data["id"]=this.state.id
        }
        if(this.state.selectedMedicalHistoryProbloms.length == 0){
            this.props.showResposeValue("error",Messages.HistorySectionValitation)
        }else{
         var service_url = Constants.POST_MEDICAL_HISTORY;
         OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.MedicalHistorySaveSuccess,
            this.MedicalHistorySaveFailure
         );     
        console.log(data)       
       }
    }   
    MedicalHistorySaveSuccess = (success) => {
        // alert(JSON.stringify(success));
        if (success.status == "success") {
            this.props.showResposeValue("success", success.message)
            this.props.refreshRighSideComponent(pageName);
            this.clearAllData()
        } else {
            this.props.showResposeValue("error", success.message)
        }
    };
    MedicalHistorySaveFailure = (error) => {
       // alert(JSON.stringify(error));
        this.props.showResposeValue("error", error.message)
    };

    render() {
        return (
            <View style={styles.container}>

                <DoctorNotesCommonRightSideHeader title={"Medical History"} clearAllData={this.clearAllData.bind(this)} />
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Problems"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedMedicalHistoryProbloms",
                        this.state.selectedMedicalHistoryProbloms,
                        "showProblomsNewButtonFlag",
                        this.state.showProblomsNewButtonFlag,
                        this.state.problomsList)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Blood Group"} noOfColumn={1} />
                    {this.renderLabelWithButtonGroup(
                        "",
                        "selectedBloodGroup",
                        this.state.selectedBloodGroup,
                        "",
                        false,
                        this.state.bloodGroupList)}
                </View>
                <View style={{ marginBottom: 10 }}>
                    <CommonHistorySectionHeader heading1={"Comments"} noOfColumn={1} />
                    {this.richTextBox()}
                </View>
                <View style={{width:100,alignSelf:"center"}}>
                 <CommonAddButton onPressAddButton={this.onPressAddButton.bind(this)} />
                </View>
            </View>
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: '#2c3e50',
    },
});
