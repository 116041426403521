import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import { FitnesCertificate } from './fitnesCertificate';
import { MedicalCertificate } from './MedicalCertificate';
import { Anaesthesia } from './Anaesthesia';
import { BloodTransfusion } from './BloodTransfusion';
import { HighRisk } from './HighRisk';
import { Genral } from './Genral';
import Icon from "react-native-vector-icons/FontAwesome";
import Success from "react-native-vector-icons/FontAwesome5";
import AsyncStorage from "../AsyncStorage";

import { ASSI } from './ASSI';

import Style from "../styles/Style";
import { color } from "../styles/Color";
// import SideMenu from "../ui/SideMenu";
import OpthamologyService from "../network/OpthamologyService";
import { Constants } from "../utils/Constants";
import { CommonButton, DoctorNotesCommonRightSideHeader } from '../ui/doctor/BaseComponent'
// import BaseComponentStyle from "../ui/doctor/BaseComponentStyle";
import moment from "moment";

const screenHeight = Dimensions.get("window").height;

export default class FormsAndCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFormsAndCertificate: "fitness",
            patientAppointment: this.props.patientAppointment,
            data: {},
            certificateType: { value: "fitness", label: "Fitness" },
            formsType: {},
            fittnessDate: moment(new Date()).format("YYYY-MM-DD"),
            fittnessSignatureApplicant: "",
            fittnessDoctorName: "",
            fittnessOffice: "",
            fittnessRegistrationNo: "",
            fittnessSignatureStaff: "",

            //medical
            medicalDate: moment(new Date()).format("YYYY-MM-DD"),
            medicalDoctorName: "",
            medicalOffice: "",
            medicalIllness: "",
            medicalfromDate: "",
            medicaltoDate: "",
            medicalactiveDate: "",
            medicalRegistrationNo: "",

            //ASSI
            assiFatherHusbandName: "",
            assiage: "",
            assiHomeAddress: "",
            assiHospitalName: "",
            assiDoctorName: "",
            assiOperationProcedure: "",
            assiDocnamePerforming: "",
            assisecondDoctorName: "",
            assiSurgeryname: "",
            assiHospitalNames: "",
            assiRisks: "",

            //Anaesthesia
            anaesthesiaAddress: "",
            anaesthesiaHospitalName: "",
            anaesthesiaDoctorName: "",
            anaesthesiaQualification: "",
            anaesthesiaDoctorAddress: "",
            anaesthesiaphone: "",
            anaesthesiaGurdianName: "",
            anaesthesiaGurdianAddress: "",
            anaesthesiaGurdianPhoneNo: "",
            anaesthesiaGurdianRelationShip: "",
            anaesthesiaPrinciple: "",
            anaesthesiaPrincipleDoctorName: "",
            anaesthesiaPrincipleQualification: "",
            anaesthesiaType1: "",
            anaesthesiaType2: "",
            anaesthesiaLanguage: "",
            anaesthesiaDate: "",
            anaesthesiacurrentDate: "",

            highRiskFormDatas: {
                hopitalName: "",
                drName: "",
                drQualification: "",
                drAddress: "",
                drPhone: "",
                drEmail: "",
                scheduledSurgeryDate: "",
                guardianName: "",
                guardianAge: "",
                guardianPhoneNo: "",
                guardianEmail: "",
                relationshipWithPatient: "",

                drInChargeName: "",
                drInChargeQualification: "",

                nameOfTheProposedTreatmentA: "",
                nameOfTheProposedTreatmentB: "",
                nameOfTheProposedTreatmentC: "",
                nameOfTheProposedTreatmentD: "",

                inCapacitatedCommunicationPersonName1: "",
                inCapacitatedCommunicationPersonAddress1: "",
                inCapacitatedCommunicationPersonMobile1: "",

                inCapacitatedCommunicationPersonName2: "",
                inCapacitatedCommunicationPersonAddress2: "",
                inCapacitatedCommunicationPersonMobile2: "",


                inCapacitatedCommunicationPersonName3: "",
                inCapacitatedCommunicationPersonAddress3: "",
                inCapacitatedCommunicationPersonMobile3: "",

                dateAndTimeGivenConsent: "",
                impression: "",
                patientOrGuardianName: "",

                witnessName1: "",
                witnessSignature1: "",

                witnessName2: "",
                witnessSignature2: "",


                drInChargeSignature: "",

                signatureOfThePatientName: "",

            },               
             high_risk_form_id: "",


            generalFormDatas: {

                hospital_name: "",
                doctor_name: "",
                doctor_qualification: "",
                doctor_address: "",
                doctor_phone: "",
                doctor_email: "",
                patient_name: "",
                patient_age: "",
                patient_address: "",
                guardian_name: "",
                guardian_address: "",
                guardian_phone: "",
                relationship_of_patient: "",
                scheduledSurgeryDate: "",
                incharge_doctor_name: "",
                incharge_doctor_qualification: "",
                procedure_name1: "",
                procedure_name2: "",
                procedure_name3: "",
                procedure_name4: "",
                explained_lang: "",
                date_time: "",
                witness_name1: "",
                witness_name2: "",
                language: "",
                scheduledSurgeryDate: "",
                dateAndTimeGivenConsent: "",

            },

            bloodTransfusionFormDatas: {

                hospital_name: "",
                doctor_name: "",
                doctor_qualification: "",
                doctor_address: "",
                doctor_phone: "",
                doctor_email: "",
                patient_name: "",
                patient_age: "",
                patient_address: "",
                guardian_name: "",
                guardian_address: "",
                guardian_phone: "",
                relationship_of_patient: "",
                secheduled_date: "",
                incharge_doctor_name: "",
                incharge_doctor_qualification: "",
                procedure_name1: "",
                procedure_name2: "",
                procedure_name3: "",
                procedure_name4: "",
                explained_lang: "",
                date_time: "",
                witness_name1: "",
                witness_name2: "",
                language: "",
                scheduledSurgeryDate: "",
                dateAndTimeGivenConsent: "",


            },
            fitness_certificate_id: "",
            assi_form_id: "",
            blood_transfusion_form_id: "",
            genral_form_id: "",
            responseType: "",
            responseMeaasge: "",
            anaesthesia_form_id: "",
            medical_certificate_id: "",

            selectedValueClinic_Droctor: {},
            hospitalName: ""
        };
    }

    async componentDidMount() {

        var selectedValue = await AsyncStorage.getItem("Selected_Clinic_Doctor");
        var selecteUserName = await AsyncStorage.getItem("UserName");
        this.setState({
            selectedValueClinic_Droctor: JSON.parse(selectedValue),
            hospitalName: selecteUserName
        }, () => {

            var { bloodTransfusionFormDatas, highRiskFormDatas, generalFormDatas } = this.state;
            bloodTransfusionFormDatas["drName"] = this.state.selectedValueClinic_Droctor.value;
            highRiskFormDatas["drName"] = this.state.selectedValueClinic_Droctor.value;
            generalFormDatas["drName"] = this.state.selectedValueClinic_Droctor.value;

            this.setState({
                fittnessDoctorName: this.state.selectedValueClinic_Droctor.value,
                medicalDoctorName: this.state.selectedValueClinic_Droctor.value,
                assiDoctorName: this.state.selectedValueClinic_Droctor.value,
                anaesthesiaDoctorName: this.state.selectedValueClinic_Droctor.value,
                bloodTransfusionFormDatas, highRiskFormDatas, generalFormDatas,
                assiHospitalName: this.state.hospitalName,
                anaesthesiaHospitalName: this.state.hospitalName,
                assiHomeAddress: this.state.data.patient_address

            })
        })

        this.getClinicDetails()
    }

    convertedDate(date) {
        var fulldate = new Date(date);
        var converted_date = moment(fulldate).format("YYYY-MM-DD");
        return converted_date

    }



    showResposeValue(type, meaasge) {
        this.setState({
            responseType: type,
            responseMeaasge: meaasge
        }, () => {
            setTimeout(() => {
                this.setState({ responseType: "", responseMeaasge: "" });
            }, 3000);
        })
    }


    successAlert() {
        return (
            <View style={[styles.AlertWrapper,{ backgroundColor: "#DEF2D6",borderColor: "#B8C7B2"}]}>
                <View style={styles.AlertView}>
                    <Success size={'1.62vw'} name={"check"} color={"green"} />
                    <Text style={[styles.AlertTypeText, { color: "green"  }]}>{"Success: "}
                        <Text style={[styles.AlertMsgText, {color: "green"}]} >{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={'1.62vw'} name={"close"} color={"#B2C6AA"} />
                </TouchableOpacity>
            </View>
        );
    }

    errorAlert() {
        return (
            <View style={[styles.AlertWrapper,{ backgroundColor: "#EBC8C4",borderColor: "#A58B8A"}]}>
                <View style={styles.AlertView}>
                    <Icon size={'1.62vw'} name={"ban"} color={"#D26560"} />
                    <Text style={[styles.AlertTypeText, { color: "#D26560"  }]}>{"Error: "}
                        <Text style={[styles.AlertMsgText, {color: "#D26560"}]}>{this.state.responseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ responseType: "" }) }} style={{ flex: 0.05, marginRight: 15, alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
                    <Icon size={'1.62vw'} name={"close"} color={"#C69A99"} />
                </TouchableOpacity>
            </View>
        );
    }

    numberValidation(num) {
        return !/[^.[0-9]]*/.test(num);
    }


    onChangedDatas(key, value) {
        if (key == "fittnessDate" || key == "medicalDate") {
            var date = this.convertedDate(value)
            var states = this.state;
            states[key] = date
            this.setState({
                states
            })
        } else if (key == "fittnessSignatureApplicant") {
            this.setState({
                fittnessSignatureApplicant: value
            })
        } else if (key == "fittnessDoctorName") {
            this.setState({
                fittnessDoctorName: value
            })
        } else if (key == "fittnessOffice") {
            this.setState({
                fittnessOffice: value
            })
        } else if (key == "fittnessRegistrationNo") {

            var isNum = this.numberValidation(value)

            if (isNum) {
                if (value.length < 7) {
                    this.setState({
                        fittnessRegistrationNo: value
                    })
                }
            }
        } else if (key == "fittnessSignatureStaff") {
            this.setState({
                fittnessSignatureStaff: value
            })
        } else if (key == "medicalDoctorName") {
            this.setState({
                medicalDoctorName: value
            })
        } else if (key == "medicalOffice") {
            this.setState({
                medicalOffice: value
            })
        } else if (key == "medicalIllness") {
            this.setState({
                medicalIllness: value
            })
        } else if (key == "medicalfromDate") {
            var date = this.convertedDate(value)
            var states = this.state;
            states[key] = date
            this.setState({
                states
            })
        } else if (key == "medicaltoDate") {
            var date = this.convertedDate(value)
            var states = this.state;
            states[key] = date
            this.setState({
                states
            })
        } else if (key == "medicalactiveDate") {
            var date = this.convertedDate(value)
            var states = this.state;
            states[key] = date
            this.setState({
                states
            })
        } else if (key == "medicalRegistrationNo") {

            var isNum = this.numberValidation(value)
            if (isNum) {
                if (value.length < 7) {
                    this.setState({
                        medicalRegistrationNo: value
                    })
                }
            }
        } else if (key == "assiFatherHusbandName") {
            this.setState({
                assiFatherHusbandName: value
            })
        } else if (key == "assiage") {
            this.setState({
                assiage: value
            })
        } else if (key == "assiHomeAddress") {
            this.setState({
                assiHomeAddress: value
            })
        } else if (key == "assiHospitalName") {
            this.setState({
                assiHospitalName: value
            })
        } else if (key == "assiDoctorName") {
            this.setState({
                assiDoctorName: value
            })
        } else if (key == "assiOperationProcedure") {
            this.setState({
                assiOperationProcedure: value
            })
        } else if (key == "assiDocnamePerforming") {
            this.setState({
                assiDocnamePerforming: value
            })
        } else if (key == "assisecondDoctorName") {
            this.setState({
                assisecondDoctorName: value
            })
        } else if (key == "assiSurgeryname") {
            this.setState({
                assiSurgeryname: value
            })
        } else if (key == "assiHospitalNames") {
            this.setState({
                assiHospitalNames: value
            })
        } else if (key == "assiRisks") {
            this.setState({
                assiRisks: value
            })
        } else if (key == "anaesthesiaHospitalName") {
            this.setState({
                anaesthesiaHospitalName: value
            })
        } else if (key == "anaesthesiaDoctorName") {
            this.setState({
                anaesthesiaDoctorName: value
            })
        } else if (key == "anaesthesiaQualification") {
            this.setState({
                anaesthesiaQualification: value
            })
        } else if (key == "anaesthesiaDoctorAddress") {
            this.setState({
                anaesthesiaDoctorAddress: value
            })
        } else if (key == "anaesthesiaphone") {
            this.setState({
                anaesthesiaphone: value
            })
        } else if (key == "anaesthesiaEmail") {
            this.setState({
                anaesthesiaEmail: value
            })
        } else if (key == "anaesthesiaAddress") {
            this.setState({
                anaesthesiaAddress: value
            })
        } else if (key == "anaesthesiaGurdianName") {
            this.setState({
                anaesthesiaGurdianName: value
            })
        } else if (key == "anaesthesiaGurdianAddress") {
            this.setState({
                anaesthesiaGurdianAddress: value
            })
        } else if (key == "anaesthesiaGurdianPhoneNo") {
            this.setState({
                anaesthesiaGurdianPhoneNo: value
            })
        } else if (key == "anaesthesiaGurdianRelationShip") {
            this.setState({
                anaesthesiaGurdianRelationShip: value
            })
        } else if (key == "anaesthesiaPrinciple") {
            this.setState({
                anaesthesiaPrinciple: value
            })
        } else if (key == "anaesthesiaPrincipleDoctorName") {
            this.setState({
                anaesthesiaPrincipleDoctorName: value
            })
        } else if (key == "anaesthesiaPrincipleQualification") {
            this.setState({
                anaesthesiaPrincipleQualification: value
            })
        } else if (key == "anaesthesiaType1") {
            this.setState({
                anaesthesiaType1: value
            })
        } else if (key == "anaesthesiaType2") {
            this.setState({
                anaesthesiaType2: value
            })
        } else if (key == "anaesthesiaLanguage") {
            this.setState({
                anaesthesiaLanguage: value
            })
        } else if (key == "anaesthesiaDate") {
            this.setState({
                anaesthesiaDate: value
            })
        } else if (key == "anaesthesiacurrentDate") {
            this.setState({
                anaesthesiacurrentDate: value
            })
        }
    }

    createFitnessCertificate = () => {


        let states = this.state.data;
        var service_url = Constants.FO_FITTNESS_CERTIFICATE;

        let data = {
            "patient_id": this.state.patientAppointment.patient_id,
            "doctor_name": this.state.fittnessDoctorName,
            "patient_name": states.patient_name,
            "current_date": this.state.fittnessDate,
            "doctor_registration_number": this.state.fittnessRegistrationNo,
            "office_name": this.state.fittnessOffice
        };


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.getFitnessSuccess,
            this.getFitnessFailure
        );

    };

    getFitnessSuccess = response => {
        if (response.status == "success") {
            this.setState({
                fitness_certificate_id: response.data.fitness_certificate_id
            })
            this.showResposeValue("success", response.message);
        } else {
            this.showResposeValue("error", response.message);
        }
    }
    getFitnessFailure = error => {
        this.showResposeValue("error", error.message);

    }

    fitnessPreviewAndPrint = () => {

        let { fitness_certificate_id } = this.state;
        var serviceUrl = Constants.FO_FITTNESS_CERTIFICATE_PDF + "?fitness_certificate_id=" + fitness_certificate_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.fitnessPreviewAndPrintSuccess,
            this.fitnessPreviewAndPrintFailure,
            "pdf"
        );
    };

    fitnessPreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
           // console.log(file)
            window.open(fileURL);
        }
    };

    fitnessPreviewAndPrintFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };

    createMedicalCertificate = () => {


        let clinicdata = this.state.data;
        let states = this.state;

        var service_url = Constants.FO_MEDICAL_CERTIFICATE;


        let data = {

            "patient_id": states.patientAppointment.patient_id,
            "doctor_name": states.medicalDoctorName,
            "patient_name": clinicdata.patient_name,
            "illness_name": states.medicalIllness,
            "from_date": states.medicalfromDate,
            "to_date": states.medicaltoDate,
            "active_date": states.medicalactiveDate,
            "current_date": states.medicalDate,
            "doctor_registration_number": states.medicalRegistrationNo,
            "office_name": this.state.medicalOffice
        };


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.createMedicalCertificateSuccess,
            this.createMedicalCertificateFailure
        );

    };

    createMedicalCertificateSuccess = response => {
        if (response.status == "success") {
            this.setState({
                medical_certificate_id: response.data.medical_certificate_id
            })
            this.showResposeValue("success", response.message);
        } else {
            this.showResposeValue("error", response.message);
        }

    }
    createMedicalCertificateFailure = error => {
        this.showResposeValue("error", error.message);
    }


    medicalPreviewAndPrint = () => {

        let { medical_certificate_id } = this.state;
        var serviceUrl = Constants.FO_MEDICAL_CERTIFICATE_PDF + "?medical_certificate_id=" + medical_certificate_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.medicalPreviewAndPrintSuccess,
            this.medicalPreviewAndPrintFailure,
            "pdf"
        );
    };

    medicalPreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
          //  console.log(file)
            window.open(fileURL);
        }
    };

    medicalPreviewAndPrintFailure = error => {
      //  console.log("Get clinic list error response");
        console.log(error);
    };


    createASSIForm = () => {



        let clinicdata = this.state.data;
        let states = this.state;
        var service_url = Constants.FO_ASSI_FORM;

        let data = {
            "patient_id": states.patientAppointment.patient_id,
            "patient_name": clinicdata.patient_name,
            "father_husband_name": states.assiFatherHusbandName,
            "age": clinicdata.patient_age,
            "home_address": clinicdata.patient_address,
            "hospital_name": states.assiHospitalName,
            "doctor_name": states.assiDoctorName,
            "procedure_name": states.assiOperationProcedure,
            "performing_surgery_doctor_name": states.assiDocnamePerforming,
            // "anaesthesia": states.assisecondDoctorName,
            "anaesthesia_given_by": states.assisecondDoctorName,
            "surgery_name": states.assiSurgeryname,
            "acknowledgement": states.assiHospitalNames,
            "risks": states.assiRisks,
            // "date_time": "2019-11-16 10:00",
            // "witness_name": "efw"
        };


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.createASSIFormSuccess,
            this.createASSIFormFailure
        );

    };

    createASSIFormSuccess = response => {
        if (response.status == "success") {
            this.setState({
                assi_form_id: response.data.assi_form_id
            })
            this.showResposeValue("success", response.message);
        } else {
            this.showResposeValue("error", response.message);
        }

    }
    createASSIFormFailure = error => {
        this.showResposeValue("error", error.message);

    }


    assipreviewAndPrint = () => {

        let { assi_form_id } = this.state;
        var serviceUrl = Constants.FO_ASSI_PDF + "?assi_form_id=" + assi_form_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.assipreviewAndPrintSuccess,
            this.assipreviewAndPrintFailure,
            "pdf"
        );
    };

    assipreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
          //  console.log(file)
            window.open(fileURL);
        }
    };

    assipreviewAndPrintFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };


    anaesthesiapreviewAndPrint = () => {

        let { anaesthesia_form_id } = this.state;
        var serviceUrl = Constants.FO_ANAESTHESIA_PDF + "?anaesthesia_form_id=" + anaesthesia_form_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.assipreviewAndPrintSuccess,
            this.assipreviewAndPrintFailure,
            "pdf"
        );
    };

    assipreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
           // console.log(file)
            window.open(fileURL);
        }
    };

    assipreviewAndPrintFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };


    createAnaesthesiaForm = () => {


        let clinicdata = this.state.data;
        let states = this.state;
        var service_url = Constants.FO_ANAESTHESIA_FORM;
        var myDate = states.anaesthesiaDate;

        var Date = myDate ? moment(myDate, 'DD-MM-YYYY') : null
        var ChangeDate = Date ? moment(Date).format("YYYY-MM-DD") : null

        var consent_date_time = states.anaesthesiacurrentDate
        var ConstDateTime = consent_date_time ? moment(consent_date_time, 'DD-MM-YYYY h:mm A') : null
        var ConstDateTimeFormat = ConstDateTime ? moment(ConstDateTime).format("YYYY-MM-DD HH:mm") : null

        let data = {
            "patient_id": states.patientAppointment.patient_id,
            "hospital_name": states.anaesthesiaHospitalName,
            "doctor_name": states.anaesthesiaDoctorName,
            "doctor_qualification": states.anaesthesiaQualification,
            "doctor_address": states.anaesthesiaDoctorAddress,
            "doctor_phone": states.anaesthesiaphone,
            "doctor_email": states.anaesthesiaEmail,
            "patient_name": clinicdata.patient_name,
            "patient_age": clinicdata.patient_age,
            "patient_address": states.anaesthesiaAddress,
            "guardian_name": states.anaesthesiaGurdianName,
            "guardian_address": states.anaesthesiaGurdianAddress,
            "guardian_phone": states.anaesthesiaGurdianPhoneNo,
            "relationship_of_patient": states.anaesthesiaGurdianRelationShip,
            "principal_anesthetist": states.anaesthesiaPrinciple,
            "principal_anesthetist_name": states.anaesthesiaPrincipleDoctorName,
            "principal_anesthetist_qualification": states.anaesthesiaPrincipleQualification,
            "types_of_anaesthesia1": states.anaesthesiaType1,
            "types_of_anaesthesia1": states.anaesthesiaType2,
            "language": states.language,
            "consent_date_time": ConstDateTimeFormat,
            "scheduled_date": ChangeDate
        };


        OpthamologyService.getInstance().postComplaints(
            service_url,
            data,
            this,
            this.createAnaesthesiaFormSuccess,
            this.createAnaesthesiaFormFailure
        );

    };

    createAnaesthesiaFormSuccess = response => {
        if (response.status == "success") {
            this.setState({
                anaesthesia_form_id: response.data.anaesthesia_form_id
            })
            this.showResposeValue("success", response.message);
        } else {
            this.showResposeValue("error", response.message);
        }

    }
    createAnaesthesiaFormFailure = error => {
        this.showResposeValue("error", error.message);

    }


    renderComponent() {
        var selectedComponent = this.state.selectedFormsAndCertificate;

        var componentLookup = {
            fitness: (< FitnesCertificate fitness_certificate_id={this.state.fitness_certificate_id} fittnessRegistrationNo={this.state.fittnessRegistrationNo} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} clinicdetails={this.state.data} fitnessDate={this.state.fittnessDate} onChangedDatas={this.onChangedDatas.bind(this)} createFitnessCertificate={this.createFitnessCertificate.bind(this)} fitnessPreviewAndPrint={this.fitnessPreviewAndPrint.bind(this)} />),
            medical: (<MedicalCertificate medicalfromDate={this.state.medicalfromDate}
                medicaltoDate={this.state.medicaltoDate} formId={this.state.medical_certificate_id} medicalRegistrationNo={this.state.medicalRegistrationNo} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} clinicdetails={this.state.data} medicalDate={this.state.medicalDate} onChangedDatas={this.onChangedDatas.bind(this)} createMedicalCertificate={this.createMedicalCertificate.bind(this)} medicalPreviewAndPrint={this.medicalPreviewAndPrint.bind(this)} />),
            assi: (<ASSI formId={this.state.assi_form_id} hospitalName={this.state.hospitalName} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} clinicdetails={this.state.data} onChangedDatas={this.onChangedDatas.bind(this)} createASSIForm={this.createASSIForm.bind(this)} assipreviewAndPrint={this.assipreviewAndPrint.bind(this)} assiFatherHusbandName={this.state.assiFatherHusbandName} />),
            Anaesthesia: (<Anaesthesia formId={this.state.anaesthesia_form_id} hospitalName={this.state.hospitalName} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} clinicdetails={this.state.data} anaesthesiaAddress={this.state.anaesthesiaAddress} onChangedDatas={this.onChangedDatas.bind(this)} createAnaesthesiaForm={this.createAnaesthesiaForm.bind(this)} anaesthesiapreviewAndPrint={this.anaesthesiapreviewAndPrint.bind(this)} />),
            BloodTransfusion: (<BloodTransfusion formId={this.state.blood_transfusion_form_id} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} bloodpreviewAndPrint={this.bloodpreviewAndPrint.bind(this)} createBloodTransfusionForm={this.createBloodTransfusionForm.bind(this)} bloodTransfusionFormDatas={this.state.bloodTransfusionFormDatas} clinicdetails={this.state.data} onChangeBloodTextValue={this.onChangeBloodTextValue.bind(this)} />),
            HighRisk: (<HighRisk formId={this.state.high_risk_form_id} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} previewAndPrint={this.previewAndPrint.bind(this)} createHighRiskForm={this.createHighRiskForm.bind(this)} highRiskFormDatas={this.state.highRiskFormDatas} clinicdetails={this.state.data} onChangeHighRiskTextValue={this.onChangeHighRiskTextValue.bind(this)} />),
            genral: (<Genral formId={this.state.genral_form_id} selectedValueClinic_Droctor={this.state.selectedValueClinic_Droctor} genralpreviewAndPrint={this.genralpreviewAndPrint.bind(this)} creategenralForm={this.creategenralForm.bind(this)} generalFormDatas={this.state.generalFormDatas} clinicdetails={this.state.data} onChangeGenralTextValue={this.onChangeGenralTextValue.bind(this)} />)

        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }


    onChangeHighRiskTextValue(key, value) {
        var { highRiskFormDatas } = this.state;

        highRiskFormDatas[key] = value;

        this.setState({ highRiskFormDatas })

    }

    onChangeGenralTextValue(key, value) {
        var { generalFormDatas } = this.state;

        generalFormDatas[key] = value;

        this.setState({ generalFormDatas })

    }

    onChangeBloodTextValue(key, value) {
        var { bloodTransfusionFormDatas } = this.state;

        bloodTransfusionFormDatas[key] = value;

        this.setState({ bloodTransfusionFormDatas })

    }


    getClinicDetails = () => {

        var service_url = Constants.GET_CLINIC_PATIENT_DETAILS + "?patient_id=" + this.state.patientAppointment.patient_id;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getClinicDetailsSuccess,
            this.getClinicDetailsFailure
        );
    };

    getClinicDetailsSuccess = response => {
        if (response.status === "success") {
            var field = this.state;
            field["data"] = response.data;
            field["assiFatherHusbandName"] = response.data.patient_father_husband_name
            field["anaesthesiaAddress"] = response.data.patient_address
            this.setState({ field });
        }

    };

    getClinicDetailsFailure = error => {
      //  console.log("opthamology complaints GET error response");
        console.log(error);
    };

    render() {




        return (
            <View style={{ height: screenHeight, flex: 1, flexDirection: "row", width: "100%" }}>
                {/* side menu */}
                {/* <View style={[Style.leftBarView, { paddingTop: 10 }]}>
                    <SideMenu navigation={this.props.navigation} />
                </View> */}
                {/* first view */}
                <View style={[Style.leftView, { width: "65%" }]}>
                    <View style={styles.HeaderView}>
                        <Text style={styles.HeaderText}>{"Forms & Certificates"}</Text>
                    </View>

                    <View>
                        {this.renderComponent()}
                    </View>
                </View>
                <View style={[styles.RightView, { width: "35%" }]}>
                    {
                        this.state.responseType == "success" ?
                            this.successAlert() :
                            this.state.responseType == "error" ?
                                this.errorAlert() : null
                    }
                    <View style={styles.CertificateTextView}>
                        <Text style={styles.CertificateText}>Certificates</Text>

                    </View>

                    <View style={styles.ButtonWrapper}>

                        {
                            Constants.Certificate.map((item, index) => {

                                return (

                                    <View style={styles.ButtonView} key={index}>
                                        <CommonButton
                                            item={item}
                                            selectedvalue={this.state.certificateType}
                                            butttonText={item.label}
                                            buttonType={"outlineNonTheme"}
                                            buttonIcon={""}
                                            rightIcon={false}
                                            buttonAction={this.onPressButton.bind(this)}
                                            buttonKey={"allergyType"} />
                                    </View>
                                )
                            })
                        }</View>


                    <View style={styles.ConsentTextView}>
                        <Text style={styles.ConsentText}>Consent Forms</Text>

                    </View>

                    <View style={styles.ButtonWrapper}>

                        {
                            Constants.Forms.map((item, index) => {

                                return (

                                    <View style={styles.ButtonView} key={index}>
                                        <CommonButton
                                            item={item}
                                            selectedvalue={this.state.certificateType}
                                            butttonText={item.label}
                                            buttonType={"outlineNonTheme"}
                                            buttonIcon={""}
                                            rightIcon={false}
                                            buttonAction={this.onPressButton.bind(this)}
                                            buttonKey={"allergyType"} />
                                    </View>
                                )
                            })
                        }</View>



                    {/* <View style={{ flexDirection: 'row', alignContent: "flex-start", alignSelf: 'flex-start', padding: 10, }}>
                        <View>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    selectedFormsAndCertificate:"fitness"
                                })
                            }} style={{ paddingBottom: 8, paddingTop: 8, width: 100, justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: color.themeDark, }}>
                                <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Fitness"}</Text>
                            </TouchableOpacity>
                        </View>

                        <View>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    selectedFormsAndCertificate:"medical"
                                })
                            }} style={{ marginLeft: 20, paddingBottom: 8, paddingTop: 8, width: 100, justifyContent: 'center', alignContent: 'space-between', borderRadius: 10, backgroundColor: color.themeDark, }}>
                                <Text style={{ fontSize: 15, color: color.white, textAlign: 'center' }}>{"Medical"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View> */}

                </View>


            </View>
        );
    }

    onPressButton(key, selectedvalue) {
        if (key == "allergyType") {
            //  alert(JSON.stringify(selectedvalue))
            this.setState({
                certificateType: selectedvalue,
                selectedFormsAndCertificate: selectedvalue.value
            })
        }
    }





    previewAndPrint = () => {

        let { high_risk_form_id } = this.state;
        var serviceUrl = Constants.HIGH_RISK_FORM_PRINT + "?high_risk_form_id=" + high_risk_form_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.getPrintSuccess,
            this.getPrintFailure,
            "pdf"
        );
    };

    getPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
           // console.log(file)
            window.open(fileURL);
        }
    };

    getPrintFailure = error => {
      //  console.log("Get clinic list error response");
        console.log(error);
    };


    genralpreviewAndPrint = () => {

        let { genral_form_id } = this.state;
        var serviceUrl = Constants.GENERAL_FORM_PDF + "?general_form_id=" + genral_form_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.genralpreviewAndPrintSuccess,
            this.genralpreviewAndPrintFailure,
            "pdf"
        );
    };

    genralpreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
           // console.log(file)
            window.open(fileURL);
        }
    };

    genralpreviewAndPrintFailure = error => {
       // console.log("Get clinic list error response");
        console.log(error);
    };


    bloodpreviewAndPrint = () => {

        let { blood_transfusion_form_id } = this.state;
        var serviceUrl = Constants.BLOOD_TRANSFUSION_PDF + "?blood_transfusion_form_id=" + blood_transfusion_form_id;

        OpthamologyService.getInstance().documentUploadGet(
            serviceUrl,
            this.bloodpreviewAndPrintSuccess,
            this.bloodpreviewAndPrintFailure,
            "pdf"
        );
    };

    bloodpreviewAndPrintSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
           // console.log(file)
            window.open(fileURL);
        }
    };

    bloodpreviewAndPrintFailure = error => {
        //console.log("Get clinic list error response");
        console.log(error);
    };






    createHighRiskForm = () => {

        var { patientAppointment, data } = this.state;

        var states = this.state.highRiskFormDatas;

        // mandatory fields
        // scheduled_surgery_date: "2020-10-20"
        // performing_surgery_doctor_name: "ganesh"
        // patient_id: 20865
        // patient_name: "Mr Sri Ganesh"
        // patient_address: "mettupalayam,Coimbatore,Tamil Nadu,641305"
        // patient_age: "22 years"
        // doctor_name: "jsri"
        // date_time: "2020-09-23 23:00"

        var myDate = states.scheduledSurgeryDate;

        var Date = myDate ? moment(myDate, 'DD-MM-YYYY') : null
        var ChangeDate = Date ? moment(Date).format("YYYY-MM-DD") : null

        var consent_date_time = states.dateAndTimeGivenConsent
        var ConstDateTime = consent_date_time ? moment(consent_date_time, 'DD-MM-YYYY h:mm A') : null
        var ConstDateTimeFormat = ConstDateTime ? moment(ConstDateTime).format("YYYY-MM-DD HH:mm") : null



        var prepareData = {
            "patient_id": patientAppointment.patient_id,
            "hospital_name": data.clinic_name,
            "doctor_name": states.drName,
            "doctor_qualification": states.drQualification,
            "doctor_address": states.drAddress,
            "doctor_phone": states.drPhone,
            "doctor_email": states.drEmail,

            "patient_name": data.patient_name,
            "patient_age": data.patient_age,
            "patient_address": data.patient_address,

            "guardian_name": states.guardianName,
            "guardian_address": states.guardianAge,
            "guardian_phone": states.guardianPhoneNo,
            "guardian_relationship_patient": states.relationshipWithPatient,
            "scheduled_surgery_date": ChangeDate,
            "performing_surgery_doctor_name": states.drInChargeName,
            "performing_surgery_doctor_qualification": states.drInChargeQualification,
            "procedure_name1": states.nameOfTheProposedTreatmentA,
            "procedure_name2": states.nameOfTheProposedTreatmentB,
            "procedure_name3": states.nameOfTheProposedTreatmentC,
            "procedure_name4": states.nameOfTheProposedTreatmentD,

            // "explained_lang":states.,
            "representative_name1": states.inCapacitatedCommunicationPersonName1,
            "representative_address1": states.inCapacitatedCommunicationPersonAddress1,
            "representative_mobile1": states.inCapacitatedCommunicationPersonMobile1,
            "representative_name2": states.inCapacitatedCommunicationPersonName2,
            "representative_address2": states.inCapacitatedCommunicationPersonAddress2,
            "representative_mobile2": states.inCapacitatedCommunicationPersonMobile2,
            "representative_name3": states.inCapacitatedCommunicationPersonName3,
            "representative_address3": states.inCapacitatedCommunicationPersonAddress3,
            "representative_mobile3": states.inCapacitatedCommunicationPersonMobile3,
            "date_time": ConstDateTimeFormat,
            "patient_guardian_name": states.patientOrGuardianName,
            "witness_name1": states.witnessName1,
            "witness_name2": states.witnessName2
        }

        var service_url = Constants.HIGH_RISK_FORM_SAVE;


        OpthamologyService.getInstance().postComplaints(
            service_url,
            prepareData,
            this,
            this.createHighRiskFormSuccess,
            this.createHighRiskFormFailure
        );
    };

    createHighRiskFormSuccess = success => {
        // console.log("***********>  data+"+JSON.stringify(data))

        if (success.status == "success") {
            this.setState({
                high_risk_form_id: success.data.high_risk_form_id
            })
            this.showResposeValue("success", success.message);
        } else {
            this.showResposeValue("error", success.message);
        }
        console.log("***********> success +" + JSON.stringify(success))
    }
    createHighRiskFormFailure = error => {
        this.showResposeValue("error", error.message);
    }



    creategenralForm = () => {

        var { patientAppointment, data } = this.state;

        var states = this.state.generalFormDatas;

        var myDate = states.scheduledSurgeryDate;

        var Date = myDate ? moment(myDate, 'DD-MM-YYYY') : null
        var ChangeDate = Date ? moment(Date).format("YYYY-MM-DD") : null

        var consent_date_time = states.dateAndTimeGivenConsent
        var ConstDateTime = consent_date_time ? moment(consent_date_time, 'DD-MM-YYYY h:mm A') : null
        var ConstDateTimeFormat = ConstDateTime ? moment(ConstDateTime).format("YYYY-MM-DD HH:mm") : null



        var prepareData = {
            "patient_id": patientAppointment.patient_id,
            "hospital_name": data.clinic_name,
            "doctor_name": states.drName,
            "doctor_qualification": states.drQualification,
            "doctor_address": states.drAddress,
            "doctor_phone": states.drPhone,
            "doctor_email": states.drEmail,

            "patient_name": data.patient_name,
            "patient_age": data.patient_age,
            "patient_address": data.patient_address,

            "guardian_name": states.guardianName,
            "guardian_address": states.guardianAge,
            "guardian_phone": states.guardianPhoneNo,
            "relationship_of_patient": states.relationshipWithPatient,
            "scheduled_surgery_date": ChangeDate,
            "incharge_doctor_namee": states.drInChargeName,
            "incharge_doctor_qualification": states.drInChargeQualification,
            "procedure_name1": states.nameOfTheProposedTreatmentA,
            "procedure_name2": states.nameOfTheProposedTreatmentB,
            "procedure_name3": states.nameOfTheProposedTreatmentC,
            "procedure_name4": states.nameOfTheProposedTreatmentD,

            "language": states.language,

            "date_time": ConstDateTimeFormat,
            "patient_guardian_name": states.patientOrGuardianName,
            "witness_name1": states.witnessName1,
            "witness_name2": states.witnessName2
        }

        var service_url = Constants.GENERAL_FORM_SAVE;


        OpthamologyService.getInstance().postComplaints(
            service_url,
            prepareData,
            this,
            this.creategenralFormSuccess,
            this.creategenralFormFailure
        );
    };

    creategenralFormSuccess = success => {
        // console.log("***********>  data+"+JSON.stringify(data))

        if (success.status == "success") {
            this.setState({
                genral_form_id: success.data.general_form_id
            })
            this.showResposeValue("success", success.message);
        } else {
            this.showResposeValue("error", success.message);
        }
        console.log("***********> success +" + JSON.stringify(success))
    }
    creategenralFormFailure = error => {
        this.showResposeValue("error", error.message);
    }



    createBloodTransfusionForm = () => {

        var { patientAppointment, data } = this.state;

        var states = this.state.bloodTransfusionFormDatas;

        var myDate = states.scheduledSurgeryDate;

        var Date = myDate ? moment(myDate, 'DD-MM-YYYY') : null
        var ChangeDate = Date ? moment(Date).format("YYYY-MM-DD") : null

        var consent_date_time = states.dateAndTimeGivenConsent
        var ConstDateTime = consent_date_time ? moment(consent_date_time, 'DD-MM-YYYY h:mm A') : null
        var ConstDateTimeFormat = ConstDateTime ? moment(ConstDateTime).format("YYYY-MM-DD HH:mm") : null

        var prepareData = {
            "patient_id": patientAppointment.patient_id,
            "hospital_name": data.clinic_name,
            "doctor_name": states.drName,
            "doctor_qualification": states.drQualification,
            "doctor_address": states.drAddress,
            "doctor_phone": states.drPhone,
            "doctor_email": states.drEmail,

            "patient_name": data.patient_name,
            "patient_age": data.patient_age,
            "patient_address": data.patient_address,

            "guardian_name": states.guardianName,
            "guardian_address": states.guardianAge,
            "guardian_phone": states.guardianPhoneNo,
            "guardian_relationship_patient": states.relationshipWithPatient,
            "scheduled_surgery_date": ChangeDate,
            "performing_surgery_doctor_name": states.drInChargeName,
            "performing_surgery_doctor_qualification": states.drInChargeQualification,
            "procedure_name1": states.nameOfTheProposedTreatmentA,
            "procedure_name2": states.nameOfTheProposedTreatmentB,
            "procedure_name3": states.nameOfTheProposedTreatmentC,
            "procedure_name4": states.nameOfTheProposedTreatmentD,

            "explained_lang": states.language,

            "date_time": ConstDateTimeFormat,
            "patient_guardian_name": states.patientOrGuardianName,
            "witness_name1": states.witnessSignature1,
            "witness_name2": states.witnessName2
        }

        var service_url = Constants.BLOOD_TRANSFUSION_FORM_SAVE;


        OpthamologyService.getInstance().postComplaints(
            service_url,
            prepareData,
            this,
            this.createBloodTransfusionFormSuccess,
            this.createBloodTransfusionFormFailure
        );
    };

    createBloodTransfusionFormSuccess = success => {
        // console.log("***********>  data+"+JSON.stringify(data))

        if (success.status == "success") {
            this.setState({
                blood_transfusion_form_id: success.data.blood_transfusion_form_id
            })
            this.showResposeValue("success", success.message);
        } else {
            this.showResposeValue("error", success.message);
        }
        console.log("***********> success +" + JSON.stringify(success))
    }
    createBloodTransfusionFormFailure = error => {
        this.showResposeValue("error", error.message);
    }

}

const styles = StyleSheet .create({
    HeaderView:{ 
        margin: '1.3vw', marginTop: '3.2vw'
     },
    HeaderText:{
        color: 'black', fontSize: '1.17vw', fontWeight: 500
    },
    CertificateTextView:{
        alignContent: "flex-start", alignSelf: 'flex-start', padding: "0.65vw", marginTop: '5.2vw'
    },
    CertificateText:{
        fontSize: '1.17vw', fontWeight: '400' 
    },
    ConsentTextView:{
        alignContent: "flex-start", alignSelf: 'flex-start', padding: '0.65vw', marginTop: '1.30vw'
    },
    ConsentText:{
        fontSize: '1.17vw', fontWeight: '400' 
    },
    ButtonWrapper:{
        flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start', alignSelf: 'flex-start', paddingLeft: "1.3vw"
    },
    ButtonView:{
        marginBottom: '0.65vw', marginRight: '0.65vw'
    },
    AlertWrapper:{
        flex: 1, zIndex: 3, width: "100%", position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", borderRadius: '0.32vw', borderWidth: 1, height: '4.5vw', marginRight: "0.65vw", marginTop: "0.65vw"
    },
    AlertView:{
        flexDirection: 'row', alignItems: 'center', marginLeft: '0.65vw', flex: 0.95
    },
    AlertTypeText:{
        fontWeight: "700", fontSize: '0.78vw', marginLeft: '0.97vw'
    },
    AlertMsgText:{
        fontWeight: "400",  fontSize: '0.78vw'
    },
    RightView:{
        backgroundColor: color.themeShade,
        height:'85vh',
        padding: '0.65vw'
    }
  });