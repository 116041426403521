import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  TextInput,
  Image,
  Picker,
} from "react-native";
import { NavigationTopHeader } from "../../BaseComponent";
import { color } from "../../../../styles/Color";
import { Constants } from "../../../../utils/Constants";
import OpthamologyService from "../../../../network/OpthamologyService";
import ImagePath from "../../../../utils/ImagePaths";
import { Label } from "@material-ui/icons";
const screenHeight = Dimensions.get("window").height;

export class BrachialIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hand_left: "",
      hand_right: "",
      leg_pta_right: "",
      leg_pta_left: "",
      leg_dpa_right: "",
      leg_dpa_left: "",
      abindex_right: "",
      abindex_left: "",
      toe_right: "",
      toe_left: "",
      tbindex_right: "",
      tbindex_left: "",
      comments: "",
      isEditClicked: false,
      unit: "",
      brachialIndex_Data: [],
    };
  }

  componentDidMount() {
    this.getBrachialIndexLog();
  }
  changeTabSection(data) {
    this.props.changeScreen(data, {}, "", {}, "", "");
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  //Render Text box dynamic View
  renderTextBox = (key) => {
    var states = this.state;
    return (
      <View style={styles.renderTextBoxContainer}>
        <TextInput
          multiline={key == "comments" ? true : false}
          maxLength={key == "comments" ? 250 : 3}
          disabled={this.state.brachialIndex_Data.length > 0 || key == "abindex_right" || key =="abindex_left" || key == "tbindex_right" || key == "tbindex_left" || key == "comments" ? true : false}
          value={states[key]}
          style={[
            styles.TextInputStyles,
            {
              height: key == "comments" ? "4vw" : "1.5vw",
              padding: key == "comments" ? "0" : "1vw",
            },
          ]}
          onChangeText={(text) => {
            var isValid = this.numberValidation(text);
            if (key == "comments") {
              states[key] = text;
              this.setState({
                states,
              });
            } else if (isValid || text === "") {
              if (parseFloat(text) <= 999) {
                states[key] = text;
                this.setState({
                  states,
                });
                this.calculateValuesforIndex()
              } else {
                states[key] = "";
                this.setState({
                  states,
                });
              }
            }
          }}
        />
      </View>
    );
  };

  //Load Comments based on Index

  LoadComments = () => {
    try{
      var states = this.state;
      var ABIndexComments = "";
      var TBIndexComments = "";
      //AB Index 
      if(this.convertToFloat(states.abindex_left)< 0.9 && this.convertToFloat(states.abindex_right) < 0.9){
        // this.setState({
          ABIndexComments += " Bilateral Peripheral arterial disease."
        // })
      } else if(this.convertToFloat(states.abindex_left)>= 0.9 && this.convertToFloat(states.abindex_left) <= 1 && this.convertToFloat(states.abindex_right)>= 0.9 && this.convertToFloat(states.abindex_right) <= 1){
        // this.setState({
          ABIndexComments += "Normal."
        // })
      } else if(this.convertToFloat(states.abindex_left)> 1.3 && this.convertToFloat(states.abindex_right) > 1.3){
        // this.setState({
          ABIndexComments += " Bilateral Calcified Pedal vessels."
        // })
      }else{
        if(this.convertToFloat(states.abindex_left)< 0.9){
          // this.setState({
            ABIndexComments += " Left Peripheral arterial disease."
          // })
        }  if(this.convertToFloat(states.abindex_right) < 0.9){
          // this.setState({
            ABIndexComments += " Right Peripheral arterial disease."
          // })
        }   if(this.convertToFloat(states.abindex_left)> 1.3){
          // this.setState({
            ABIndexComments += " Left Calcified Pedal vessels."
          // })
        }  if(this.convertToFloat(states.abindex_right) >1.3){
          // this.setState({
            ABIndexComments += " Right  Calcified Pedal vessels."
          // })
        } if((this.convertToFloat(states.abindex_left)>= 0.9 && this.convertToFloat(states.abindex_left) <= 1 ) || (this.convertToFloat(states.abindex_right)>= 0.9 && this.convertToFloat(states.abindex_right) <= 1)){
          ABIndexComments += " Normal."
        }
      } 
      //TB Index
      if(this.convertToFloat(states.tbindex_left)< 0.9 && this.convertToFloat(states.tbindex_right) < 0.9){
        // this.setState({
          TBIndexComments += " Bilateral Peripheral arterial disease."
        // })
      } else if(this.convertToFloat(states.tbindex_left)>= 0.9 && this.convertToFloat(states.tbindex_left) <= 1 && this.convertToFloat(states.tbindex_right)>= 0.9 && this.convertToFloat(states.tbindex_right) <= 1){
        // this.setState({
          TBIndexComments += " Normal."
        // })
      }else  if(this.convertToFloat(states.tbindex_left)> 1.3 && this.convertToFloat(states.tbindex_right) > 1.3){
        // this.setState({
          TBIndexComments += " Bilateral Calcified Pedal vessels."
        // })
      }else{
        if(this.convertToFloat(states.tbindex_left)< 0.9){
          // this.setState({
            TBIndexComments += " Left Peripheral arterial disease."
          // })
        }  if(this.convertToFloat(states.tbindex_right) < 0.9){
          // this.setState({
            TBIndexComments += " Right Peripheral arterial disease."
          // })
        }  if(this.convertToFloat(states.tbindex_left)> 1.3){
          // this.setState({
            TBIndexComments += " Left Calcified Pedal vessels."
          // })
        }  if(this.convertToFloat(states.tbindex_right) >1.3){
          // this.setState({
            TBIndexComments += " Right Calcified Pedal vessels."
          // })
        } if((this.convertToFloat(states.tbindex_left)>= 0.9 && this.convertToFloat(states.tbindex_left) <= 1) || (this.convertToFloat(states.tbindex_right)>= 0.9 && this.convertToFloat(states.tbindex_right) <= 1)){
          TBIndexComments += " Normal."
        } 
      }
       
      this.setState({
        comments: "AB Index: " + ABIndexComments + "\n" + "TB Index: " + TBIndexComments
      })
    }catch(e){

    }
  }

  //Formula value calculation
  calculateValuesforIndex = () => {
    var states = this.state;
    try{
      if(states.hand_right != "" && states.hand_right > 0 && states.hand_left != "" && states.hand_left > 0 && states.leg_pta_left != "" && states.leg_pta_left > 0 && states.leg_pta_right != "" && states.leg_pta_right > 0
        && states.leg_dpa_right != "" && states.leg_dpa_right > 0 && states.leg_dpa_left != "" && states.leg_dpa_left > 0){
          var ABIndexRight = 0;
          var ABIndexLeft = 0;
          var TBIndexRight = 0;
          var TBIndexLeft = 0;
          var Comments = "";
          if(this.convertToFloat(states.leg_pta_left) > this.convertToFloat(states.leg_dpa_left)){
            ABIndexLeft = this.convertToFloat(states.leg_pta_left) / this.convertToFloat(states.hand_left);
            this.setState({
              abindex_left : ABIndexLeft.toFixed(1)
            }, ()=> this.LoadComments())
          } else {
            ABIndexLeft = this.convertToFloat(states.leg_dpa_left) / this.convertToFloat(states.hand_left);
            this.setState({
              abindex_left : ABIndexLeft.toFixed(1)
            }, ()=> this.LoadComments())
          }
          if(this.convertToFloat(states.leg_pta_right) > this.convertToFloat(states.leg_dpa_right)){
            ABIndexRight = this.convertToFloat(states.leg_pta_right) / this.convertToFloat(states.hand_right);
            this.setState({
              abindex_right : ABIndexRight.toFixed(1)
            }, ()=> this.LoadComments())
          }else{
            ABIndexRight = this.convertToFloat(states.leg_dpa_right) / this.convertToFloat(states.hand_right);
            this.setState({
              abindex_right : ABIndexRight.toFixed(1)
            }, ()=> this.LoadComments())
          }
          if(states.toe_right != "" && states.toe_left != ""){
          TBIndexRight = this.convertToFloat(states.toe_right) / this.convertToFloat(states.hand_right);
          TBIndexLeft = this.convertToFloat(states.toe_left) / this.convertToFloat(states.hand_left);
          this.setState({
            tbindex_right: TBIndexRight.toFixed(1),
            tbindex_left : TBIndexLeft.toFixed(1)
          }, ()=> this.LoadComments())
        }
      }

    }catch(e){

    }
  }

  //Render Picker view
  renderPickerView = (key) => {
    var states = this.state;
    return (
      <Picker
        style={{
          borderColor: "#CDD1D5",
          borderRadius: 4,
          borderWidth: 1,
          width: "100%",
          height: "2.2vw",
          // padding: 15,
          marginTop: 8,
          fontSize:'0.9vw',
          backgroundColor: color.themeShade
        }}
        disabled={this.state.brachialIndex_Data.length > 0 ? true : false}
        // itemStyle={{ fontSize: 14, color: color.black }}
        selectedValue={this.state.unit}
        onValueChange={(itemvalue) => {
          this.setState({
            unit: itemvalue,
          });
          // selectedValue={states[key]}
          // onValueChange={(itemvalue) => {
          //   states[key] = itemvalue;
          //   this.setState({
          //     states,
          //   });
        }}
      >
        <Picker.Item label="Select" value="" />
        <Picker.Item label="Mg" value="mg" />
        <Picker.Item label="Volts" value="Volts" />
      </Picker>
    );
  };

  //Render right side view
  renderBrachialIndexLog = () => {
    return (
      <View style={{ height: "70vh" }}>
        <ScrollView
          style={styles.RightScrollView}
          showsVerticalScrollIndicator={false}
        >
          <Text style={styles.RightHeadTitle}>Brachial Index Log</Text>

          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Hand</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("hand_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("hand_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("hand_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("hand_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Leg</Text>
          </View>

          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              PTA Right 1<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("leg_pta_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_pta_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              PTA Left 1<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("leg_pta_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_pta_left_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              DPA Right 2<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("leg_dpa_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_dpa_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              DPA Left 2<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("leg_dpa_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("leg_dpa_left_unit")}
            </View>
          </View>

          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> A.B Index</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("abindex_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("abindex_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("abindex_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("abindex_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Toe</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right 1<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("toe_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("toe_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left 1<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("toe_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("toe_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> T.B Index</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Right<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("tbindex_right")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("tbindex_right_unit")}
            </View>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>
              Left<Text style={styles.MandatoryText}>*</Text>
            </Text>
            <View style={styles.value_txt_box}>
              {this.renderTextBox("tbindex_left")}
            </View>
            <View style={styles.unit_txt_box}>
              {this.renderPickerView("tbindex_left_unit")}
            </View>
          </View>
          <View style={styles.HightLightView}>
            <Text style={styles.HighLightText}> Comments</Text>
          </View>
          <View style={styles.InputView}>
            <Text style={styles.InputText}>Comments</Text>
            <View style={styles.value_comment_box}>
              {this.renderTextBox("comments")}
            </View>
          </View>
        </ScrollView>
        {this.state.brachialIndex_Data.length == 0 ? (
          <View>
            <View style={{ height: "1.5vw", marginTop: "0.3vw" }}>
              <Text
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  fontSize: ".9vw",
                  fontWeight: "500",
                  justifyContent: "center",
                }}
              >
                {this.state.ErrorText}
              </Text>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0.5vw",
                  justifyContent: "space-around",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    this.state.isEditClicked
                      ? this.EditBrachialIndex()
                      : this.AddNewBrachialIndex();
                  }}
                  style={styles.BtnTouch}
                >
                  <Text style={styles.ButtonText}>
                    {this.state.isEditClicked ? "Update" : "Add"}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.resetClicked();
                  }}
                  style={styles.BtnTouch}
                >
                  <Text style={styles.ButtonText}>{"Clear"}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  };

  //parse value to float
  convertToFloat = (num) => {
    return parseFloat(num);
  };

  //Validation
  validateData = () =>{
    try{
      var states = this.state;
      if(states.hand_right != "" && states.hand_left != "" && states.leg_pta_left != "" && states.leg_pta_right != ""
        && states.leg_dpa_right != "" && states.leg_dpa_left != "" && states.abindex_left != "" && states.toe_right != "" && states.toe_left != "" && states.tbindex_left != "" && states.tbindex_right != ""){
          return true;
      }else{
        this.props.showResposeValue("error", "Please enter all value to save");
        return false;
      }
    }catch(e){

    }
  }

  //Save Brachial Index function
  AddNewBrachialIndex = () => {
    try {
      var isValid = this.validateData();
      var states = this.state;
      var data = {
        appointment_id: this.props.patientAppointment.appointment_id,
        hand_right:
          states.hand_right != "" ? this.convertToFloat(states.hand_right) : 0,
        hand_left:
          states.hand_left != "" ? this.convertToFloat(states.hand_left) : 0,
        leg_pta_left:
          states.leg_pta_left != ""
            ? this.convertToFloat(states.leg_pta_left)
            : 0,
        leg_pta_right:
          states.leg_pta_right != ""
            ? this.convertToFloat(states.leg_pta_right)
            : 0,
        leg_dpa_right:
          states.leg_dpa_right != ""
            ? this.convertToFloat(states.leg_dpa_right)
            : 0,
        leg_dpa_left:
          states.leg_dpa_left != ""
            ? this.convertToFloat(states.leg_dpa_left)
            : 0,
        abindex_left:
          states.abindex_left != ""
            ? this.convertToFloat(states.abindex_left)
            : 0,
        abindex_right:
          states.abindex_right != ""
            ? this.convertToFloat(states.abindex_right)
            : 0,
        toe_right:
          states.toe_right != "" ? this.convertToFloat(states.toe_right) : 0,
        toe_left:
          states.toe_left != "" ? this.convertToFloat(states.toe_left) : 0,
        tbindex_left:
          states.tbindex_left != ""
            ? this.convertToFloat(states.tbindex_left)
            : 0,
        tbindex_right:
          states.tbindex_right != ""
            ? this.convertToFloat(states.tbindex_right)
            : 0,
        comments: states.comments,
        unit : states.unit
      };
      if(isValid){
      var service_url = Constants.FOOT_CLINIC_BRACHIAL_INDEX;
      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.AddNewBrachialIndexSuccess,
        this.AddNewBrachialIndexFailure
      );
      }else{
        this.props.showResposeValue("error", "Please enter all values to save");
      }
    } catch (e) {
      this.props.showResposeValue(e.message);
    }
  };

  //Get saved brachial index for patient
  getBrachialIndexLog = () => {
    try {
      var service_url =
        Constants.FOOT_CLINIC_BRACHIAL_INDEX +
        "?appointment_id=" +
        this.props.patientAppointment.appointment_id;
      OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getBrachialIndexLogSuccess,
        this.getBrachialIndexLogFailure
      );
    } catch (e) {
      console.log(e.message);
    }
  };
  getBrachialIndexLogSuccess = (response) => {
    if (response) {
      if (response.status == "success") {
        if (response.data.length > 0) {
          this.setState({
            brachialIndex_Data: response.data,
            hand_left: response.data[0].hand_left,
            hand_right: response.data[0].hand_right,
            leg_pta_right: response.data[0].leg_pta_right,
            leg_pta_left: response.data[0].leg_pta_left,
            leg_dpa_right: response.data[0].leg_dpa_right,
            leg_dpa_left: response.data[0].leg_dpa_left,
            abindex_right: response.data[0].abindex_right,
            abindex_left: response.data[0].abindex_left,
            toe_right: response.data[0].toe_right,
            toe_left: response.data[0].toe_left,
            tbindex_right: response.data[0].tbindex_right,
            tbindex_left: response.data[0].tbindex_left,
            comments: response.data[0].comments,
            unit : response.data[0].unit
          });
        }
        console.log(response.data, "BRACHIAL INDEX DATA");
      }
    }
  };

  AddNewBrachialIndexSuccess = (response) => {
    if (response) {
      if (response.status == "success") {
        this.props.showResposeValue("success",response.message);
        this.resetClicked();
        this.getBrachialIndexLog();
      } else {
        this.props.showResposeValue("error",response.message);
      }
    }
  };

  AddNewBrachialIndexFailure = (error) => {
    this.props.showResposeValue("error",error.message);
  };

  //Print Brachial Index
  print = () =>{
    try {
      var service_url =
        Constants.FOOT_CLINIC_BRACHIAL_INDEX +
        "?appointment_id=" +
        this.props.patientAppointment.appointment_id +"&export=pdf";
      OpthamologyService.getInstance().documentUploadGet(
        service_url,
        this.printBrachialIndexLogSuccess,
        this.printBrachialIndexLogFailure,
        "pdf"
      );
    } catch (e) {
      console.log(e.message);
    }
  }
  printBrachialIndexLogSuccess = (response) => {
    if (response) {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }
};


printBrachialIndexLogFailure = error => {

    console.log(error);
};

  //Reset action
  resetClicked = () => {
    this.setState({
      hand_left: "",
      hand_right: "",
      leg_pta_right: "",
      leg_pta_left: "",
      leg_dpa_right: "",
      leg_dpa_left: "",
      abindex_right: "",
      abindex_left: "",
      toe_right: "",
      toe_left: "",
      tbindex_right: "",
      tbindex_left: "",
      comments: "",
      isEditClicked: false,
      unit: "",
      brachialIndex_Data: [],
    });
  };

  render() {
    return (
      <View>
        <View>
          <NavigationTopHeader
            changeTabSection={this.changeTabSection.bind(this)}
            navigationHeaderList={[
              { label: "Brachial Index", value: "brachialIndex" },
              { label: "Biothesiometry", value: "biothesiometry" },
            ]}
            selectedTab={"brachialIndex"}
            isNavigationBorder={true}
          />
        </View>
        <View style={styles.container}>
          <View style={styles.left_container}>
            <View style={{ flexDirection: "column", flex:1 }}>
              <View style={{ flexDirection: "row", flex: 0.2 }}>
                <View style={{ flex: 0.8 }}>
                  <Text style={styles.RightHeadTitle}>Brachial Index Log</Text>
                </View>
                <View style={{ flex: 0.2 }}>
                  <TouchableOpacity
                    style={styles.PrintBtn}
                    onPress={() => {
                      this.print();
                    }}
                  >
                    <Image source={ImagePath.Print} style={styles.PrintImg} />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{ flex: 0.6, flexDirection: "row" }}>
                <View style={{ flex: 0.4, flexDirection: "row" }}>
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <View style={styles.img_lbl_txt}>
                      <label style={{ fontSize: "0.7vw" }}>Left</label>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.hand_left != ""
                          ? this.state.hand_left
                          : "Nil"}
                      </Text>
                    </View>
                    <View style={styles.img_value_box_left}>
                      <Image
                        source={ImagePath.BackArrow}
                        style={styles.Header_BackIcon}
                      />
                    </View>
                  </View>
                  <View>
                    <Image
                      source={ImagePath.UpperBody}
                      style={{ width: "13vw", height: "19.5vw" }}
                    />
                  </View>
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <View style={styles.img_value_box_right}>
                      <Image
                        source={ImagePath.BackArrow}
                        style={styles.Header_BackIcon}
                      />
                    </View>
                    <View style={styles.img_lbl_txt}>
                      <label style={{ fontSize: "0.7vw" }}>Right</label>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.hand_right != ""
                          ? this.state.hand_right
                          : "Nil"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ flex: 0.3, flexDirection: "column" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        alignItems: "flex-end",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={[
                          styles.img_lbl_txt,
                          { flexDirection: "column", alignItems: "flex-end" },
                        ]}
                      >
                        <View style={{ marginBottom: 60 }}>
                          <label style={{ fontSize: "0.7vw" }}>
                            PTA Left 1
                          </label>
                          <Text style={[styles.img_lbl_txt]}>
                            {this.state.leg_pta_left != ""
                              ? this.state.leg_pta_left
                              : "Nil"}
                          </Text>
                        </View>
                        <View>
                          <label style={{ fontSize: "0.7vw" }}>
                            DPA left 2
                          </label>
                          <Text style={[styles.img_lbl_txt]}>
                            {this.state.leg_dpa_left != ""
                              ? this.state.leg_dpa_left
                              : "Nil"}
                          </Text>
                        </View>
                      </View>
                      <View style={[{ flexDirection: "column" }]}>
                        <View
                          style={[
                            styles.img_value_box_left,
                            { marginBottom: 60 },
                          ]}
                        >
                          <Image
                            source={ImagePath.BackArrow}
                            style={styles.Header_BackIcon}
                          />
                        </View>
                        <View style={styles.img_value_box_left}>
                          <Image
                            source={ImagePath.BackArrow}
                            style={styles.Header_BackIcon}
                          />
                        </View>
                      </View>
                    </View>

                    <View>
                      <Image
                        source={ImagePath.LowerBody}
                        style={{ width: "8.1vw", height: "14.6vw" }}
                      />
                    </View>
                    <View
                      style={{
                        alignItems: "flex-end",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View style={[{ flexDirection: "column" }]}>
                        <View
                          style={[
                            styles.img_value_box_right,
                            { marginBottom: 60 },
                          ]}
                        >
                          <Image
                            source={ImagePath.BackArrow}
                            style={styles.Header_BackIcon}
                          />
                        </View>
                        <View style={[styles.img_value_box_right]}>
                          <Image
                            source={ImagePath.BackArrow}
                            style={styles.Header_BackIcon}
                          />
                        </View>
                      </View>
                      <View
                        style={[
                          styles.img_lbl_txt,
                          { flexDirection: "column", alignItems: "flex-end" },
                        ]}
                      >
                        <View style={{ marginBottom: 60 }}>
                          <label style={{ fontSize: "0.7vw" }}>
                            PTA Right 1
                          </label>
                          <Text style={[styles.img_lbl_txt]}>
                            {this.state.leg_pta_right != ""
                              ? this.state.leg_pta_right
                              : "Nil"}
                          </Text>
                        </View>
                        <View>
                          <label style={{ fontSize: "0.7vw" }}>
                            DPA Right 2
                          </label>
                          <Text style={[styles.img_lbl_txt]}>
                            {this.state.leg_dpa_right != ""
                              ? this.state.leg_dpa_right
                              : "Nil"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.abindex_left != ""
                          ? this.state.abindex_left
                          : "Nil"}
                      </Text>
                    </View>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <label style={{ fontSize: "0.7vw" }}>A.B Index</label>
                    </View>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.abindex_right != ""
                          ? this.state.abindex_right
                          : "Nil"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flex: 0.3,
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginTop: 60,
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        alignItems: "end",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View style={styles.img_lbl_txt}>
                        <label style={{ fontSize: "0.7vw" }}>Left 1</label>
                        <Text style={[styles.img_lbl_txt]}>
                          {this.state.toe_left != ""
                            ? this.state.toe_left
                            : "Nil"}
                        </Text>
                      </View>
                      <View style={styles.img_value_box_left}>
                        <Image
                          source={ImagePath.BackArrow}
                          style={styles.Header_BackIcon}
                        />
                      </View>
                    </View>
                    <View>
                      <Image
                        source={ImagePath.LowerBody}
                        style={{ width: "8.1vw", height: "14.6vw" }}
                      />
                    </View>
                    <View
                      style={{
                        alignItems: "end",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View style={styles.img_value_box_right}>
                        <Image
                          source={ImagePath.BackArrow}
                          style={styles.Header_BackIcon}
                        />
                      </View>
                      <View style={styles.img_lbl_txt}>
                        <label style={{ fontSize: "0.7vw" }}>Right 1</label>
                        <Text style={[styles.img_lbl_txt]}>
                          {this.state.toe_right != ""
                            ? this.state.toe_right
                            : "Nil"}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.tbindex_left != ""
                          ? this.state.tbindex_left
                          : "Nil"}
                      </Text>
                    </View>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <label style={{ fontSize: "0.7vw" }}>T.B Index</label>
                    </View>
                    <View style={[styles.img_lbl_txt, { margin: 20 }]}>
                      <Text style={[styles.img_lbl_txt]}>
                        {this.state.tbindex_right != ""
                          ? this.state.tbindex_right
                          : "Nil"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{flex:0.3}}>
                <label style={{fontWeight: 'bold' , fontSize:'0.9vw'}}>
                  Impression: </label>
                  <TextInput multiline={true} disabled={true} value={this.state.comments} style={[styles.MultilineText]}>
                    {/* {this.state.comments} */}
                  </TextInput>
              </View>
            </View>
          </View>
          <View style={styles.right_container}>
            {this.renderBrachialIndexLog()}
          </View>
        </View>
      </View>
    );
  }
}

export default BrachialIndex;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: "75vh",
    padding: 20,
  },
  left_container: {
    flex: 0.6,
    height: "75vh",
    margin: 5,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    padding: 5,
  },
  right_container: {
    flex: 0.4,
    backgroundColor: color.themeShade,
    height: "75vh",
    margin: 5,
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    padding: 5,
  },
  LeftMainView: {
    width: "65%",
    height: "75vh",
    margin: "0.2vw",
    marginLeft: "0.7vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    borderRadius: "0.25vw",
  },

  RightMainView: {
    width: "33%",
    height: "70vh",
    backgroundColor: color.themeShade,
    margin: "0.2vw",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: color.grayShade,
    shadowOpacity: 1,
    shadowRadius: 2,
    borderRadius: "0.25vw",
  },
  RightScrollView: {},
  TableContainer: {
    marginLeft: "1vw",
    marginRight: "1vw",
    marginTop: "0.5vw",
  },
  TableHeadText: {
    fontSize: "0.91vw",
    color: "black",
    fontWeight: "500",
  },
  TableDataText: {
    fontSize: "0.91vw",
  },
  TableDataView: {
    justifyContent: "center",
    maxHeight: "2.9vw",
    minHeight: "2.9vw",
    width: "100%",
  },
  TableTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    height: "2vw",
    paddingHorizontal: ".5vw",
    borderRadius: "0.4vw",
  },
  TableDataWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#888888",
    borderBottomWidth: "1px",
    borderRadius: 4,
    height: "3vw",
    alignItems: "center",
    paddingHorizontal: ".5vw",
  },
  MainTable: {
    height: "50vh",
  },
  RightHeadTitle: {
    marginLeft: "1vw",
    marginTop: "0.3vw",
    fontSize: "1vw",
    fontWeight: "bold",
    color: color.themeDark,
  },
  HightLightView: {
    backgroundColor: color.sectionHeadingLiteColor,
    alignContent: "flex-start",
    borderRadius: "0.4vw",
    margin: "1vw",
  },
  HighLightText: {
    marginLeft: "1.3vw",
    margin: "0.5vw",
    fontSize: "0.8vw",
    fontWeight: "bold",
  },
  TextInputStyles: {
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: "0.065vw",
    width: "100%",
    height: "1.5vw",
    fontSize: ".9vw",
  },
  renderTextBoxContainer: {
    width: "100%",
    marginTop: "0.65vw",
  },
  InputText: {
    fontSize: "0.9vw",
    marginLeft: "2vw",
    marginTop: "0.2vw",
    height: "1.5vw",
    flex: 0.4,
  },
  InputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  BtnTouch: {
    paddingTop: "0.5vw",
    paddingLeft: "1.1vw",
    paddingRight: "1.1vw",
    paddingBottom: "0.5vw",
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: "10vw",
  },
  ButtonText: {
    color: color.white,
    textAlign: "center",
    fontSize: ".85vw",
  },
  MandatoryText: {
    color: "red",
    fontWeight: "500",
    marginLeft: "0.2vw",
    fontSize: "1vw",
  },
  NoRecordsView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5vw",
  },
  NoRecordsText: {
    fontSize: "1vw",
  },
  SearchInput: {
    height: "2vw",
    borderWidth: "0.065vw",
    borderColor: color.lightGray,
    borderRadius: "0.26vw",
    width: "25%",
    marginRight: "0.65vw",
    paddingHorizontal: "0.52vw",
    color: color.black,
    fontSize: "0.91vw",
  },
  TabView: {
    marginLeft: "2vw",
  },
  value_txt_box: {
    width: "18vw",
    marginRight: "1.5vw",
    flex: 0.3,
  },
  value_comment_box: {
    width: "18vw",
    marginRight: "1.5vw",
    flex: 0.6,
  },
  unit_txt_box: {
    width: "8vw",
    marginRight: "1.5vw",
    flex: 0.3,
  },
  Header_BackIcon: {
    height: "0.8vw",
    width: "2vw",
  },
  img_value_box_left: {
    backgroundColor: color.grayShade,
    flexDirection: "row",
  },
  img_value_box_right: {
    backgroundColor: color.grayShade,
    transform: "rotate(180deg)",
  },
  img_lbl_txt: {
    fontSize: "1vw",
    fontWeight: "bold",
  },
  img_lbl: {
    fontSize: "0.7vw",
    fontWeight: "bold",
  },
  PrintImg: {
    height: "1.3vw",
    width: "1.3vw",
    alignSelf: "center",
  },
  PrintBtn: {
    backgroundColor: color.themeBackgroud,
    borderRadius: "0.26vw",
    marginLeft: "0.65vw",
    height: "1.76vw",
    width: "1.76vw",
    justifyContent: "center",
  },
  MultilineText: {
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: "0.065vw",
    width: '40vw',
    height:'5vw',
    fontSize: '0.9vw',
    fontWeight: 'bold'
   },
});
