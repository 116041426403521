import React from "react";
import {
  View,
  Text,
  FlatList,
  TextInput,
  // ScrollView,
  // Image,
  TouchableOpacity,
  StyleSheet,
  Platform
} from "react-native";
import BaseClass from "../BaseClass";
import { color } from "../../styles/Color";
import Style from "../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
// import SelectedButton from "../../components/SelectedButton";
// import { TextInput } from "react-native-gesture-handler";
import Arrow from "react-native-vector-icons/MaterialCommunityIcons";
import { fonts } from "react-native-elements/dist/config";


const platform = Platform.OS;

export default class BaseComponentStyle extends BaseClass {
  constructor(props) {
    super(props);

    this.state = {
      complaintsData: [],
      isComplaint: true,
      isOcular: false,
      isSystemic: false,
      isVisionAssessment: false,
      isAutoRefractometry: false,
      isGonioscopy: false,
      isSubjectiveRefraction: false,
      isPreviousGlassPower: false,
      isGlassPrescription: false,
      isIOP: false,
      isMedication: false,
      isVitals: false,
      isFundus: false,
      isReviewPlan: false,
      isTreatmentAndAdvice: false,
      isDiagnosis: false,
      isDocuments: false,
      selectedView: "isComplaint",
      currentTime: ""
    };

    this.updateState = this.updateState.bind(this);

  }

  componentDidMount() {

   }

  // commonTextBox = (placeholder,type, ) =>{
  //   return(
  //     <View>
  //       <Text style={{fontSize:12, position: "absolute",marginLeft:10,backgroundColor:"white",paddingLeft:5,paddingRight:5,color:"#888888"}}>{placeholder}</Text>

  //       <TextInput
  //         style={[Style.commonTextBoxStyles , 
  //           // props.textKey == "revenueReportFromAmount" || 
  //           // props.textKey == "revenueReportToAmount" || 
  //           // props.textKey == "revenueReportDoctorSpecialization" ||  
  //           // props.textKey == "revenueReportLocation" ? { height: 30 } : 
  //           // props.textKey =="reasonForResignation" ? {height:70}: null,
  //           { marginTop:8,}]}
  //           // placeholder={props.placeholder}
  //           value={props.value}
  //           onChangeText={(text) => {
  //               value = text;
  //               props.callBack(text, props.textKey);
  //           }}
  //           onSubmitEditing={() => {
  //               props.enterTosubmit ?
  //                   props.callBack(value, props.textKey, true)
  //                   : null
  //           }
  //           }
  //       />
  //     </View>
  //   )
  // }


  renderSubHeadingBorder = (title, line = true, isRightSideSection = false) => {
    return (
      <View
        style={styles.SubHeadingBorderView}
      >
        <View style={{ flex: 1 }}>
          <Text style={[styles.SubHeadingBorderText, {color: isRightSideSection ? color.black : color.black }]}>{title}</Text>
        </View>
        {(line) ?
          <View
            style={[styles.SubHeadingBorderContainer, {backgroundColor: color.white,}]}
          />
          : null}
      </View>
    );
  };

  renderGmailTextField = (title, key, callBack) => {
    if (platform === "web") {
      return (
        // <View
        //   style={{
        //     borderColor: "#525252",
        //     borderWidth: 1,
        //     marginTop: 20,
        //     height: 35,
        //     width: "90%",
        //     borderWidth: 1,
        //     borderBottomWidth: 1,
        //     borderRadius: 20,
        //     alignItems: "center",
        //     flexDirection: "row",
        //     // paddingLeft: 10,
        //     // paddingLeft: 10,
        //     // paddingRight: 10
        //   }}
        // >
        //   <TextInput
        //     placeholder={title}
        //     underlineColorAndroid="transparent"
        //     style={{ width: "90%" ,borderWidth:0,borderColor:'transparent'}}
        //     autoCapitalize="none"
        //     // value = ""
        //     // onChangeText = {text => //methodName}
        //   />
        // </View>
        <View style={{ marginBottom: 20 }}>
          <TextInput
            placeholder={title}
            underlineColorAndroid="transparent"
            style={{ padding: 10, color: "grey", height: 35, borderRadius: 20, borderColor: "grey", borderWidth: 1 }}
            placeholderTextColor={"grey"}
            autoCapitalize="none"
            // value={value}
            onChangeText={text => {
              callBack(key, text);
            }}
            onFocus={false}
          />
        </View>
      )
    } else {
      // return (
      //   <GmailInput
      //     label={title}
      //     // onChangeText={text => {
      //     //   this.setState({ email_or_phone: text });
      //     // }}
      //     // hideLabel={this.state.email_or_phone !== ""}
      //   />
      // );
    }
  };

  renderTitleBorder = (selected, title, key, triggerCallBack) => {
    return (
      <TouchableOpacity onPress={() => triggerCallBack(key)}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            // marginTop: 10,
          }}
        ><View style={styles.TitleBorderView}>
            <Text
              style={[Style.contentSectionTitle, {
                color: selected ? color.themeDark : color.drNotesUnSelectedTitle
              }]}
              numberOfLines={1}
            >
              {title}

            </Text>


          </View>

          {selected ?
            <Arrow
              name="hand-pointing-left"
              size={'1.5vw'}
              color={'red'}
            />
            : null}
        </View>
      </TouchableOpacity>
    );
  };




  renderSubTitleBorder = (selected, title, key, triggerCallBack) => {
    return (
      <TouchableOpacity onPress={() => triggerCallBack(key)}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
          }}
        ><View style={{ marginRight: 10 }}>
            <Text
              style={[Style.contentSectionSubTitle, {
                color: selected ? color.themeDark : color.drNotesUnSelectedTitle
              }]}
              numberOfLines={1}
            >
              {title}

            </Text>


          </View>
          {/* 
          {selected ?
            <Arrow
              name="hand-pointing-left"
              size={25}
              color={'red'}
            />
            : null} */}
        </View>
      </TouchableOpacity>
    );
  };



  renderIdButton = image => {
    return (
      <View
        style={styles.IDBtnView}
      >
        <Icon name={image} color={image == "pencil" ? color.newPencilColor : image == "trash" ? color.newTrashColor :color.themeDark} size={'1vw'} />
        {/* <Image
            source={image}
            style={{ width: 40, height: 40 }}
            resizeMode="contain"
          /> */}
      </View>
    );
  };

  renderTextBtn = (name, fill, borderNeeded) => {
    return (
      // <TouchableOpacity onPress={() => this._showAlert(name)}>
      <View
        style={[{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: fill || borderNeeded ? '0.6vw' : '1.2vw',
          flexDirection: "row",
          borderColor: fill || borderNeeded ? color.themeDark : color.white,
          borderWidth: 1,
          backgroundColor: fill
            ? borderNeeded
              ? color.themeBlue
              : color.themeDark
            : color.lightGray,
          padding: fill ? '0.5vw' : '1vw',
          paddingTop: '0.3vw',
          paddingBottom: '0.3vw',
          minWidth: '6.3vw',
          marginBottom: '0.6vw',
          // marginRight: 2
          marginLeft: '1vw'

        }, Style.allButtonBorderRadius]}
      >
        <Text
          style={{
            fontSize: fill ? '1.1vw' : '0.8vw', //12
            textAlign: "center",
            color: borderNeeded ? color.themePink : color.white,
            fontWeight: "400" //500
          }}
        >
          {name}
        </Text>
      </View>
      // </TouchableOpacity>
    );
  };

  renderTextBtnSelectable = (item, fill, borderNeeded, selectKey, selected, setBack, keyInObject, isDisable = false, isMultiSelectButton = false, disable = false,idBaseDisable=false) => {

    //let {item, fill, borderNeeded, selected} = this.props;

    // let borderColor = fill || borderNeeded ? color.themeBlue : color.white;
    // let backgroundColor = fill ? borderNeeded ? color.themeBlue : color.themePink : color.themeBlue;



    if (isMultiSelectButton) {

      selected && selected.map((selectedItem, index) => {
        return (
          <TouchableOpacity key={index} onPress={() => { setBack(selectedItem, item.value, keyInObject), isDisable }}
            disabled={(isDisable || disable) ? true : false}
          >
            <View
              style={[{
                justifyContent: "center",
                alignItems: "center",
                // borderRadius: fill || borderNeeded ? 10 : 20,
                flexDirection: "row",
                borderColor: selectedItem == item.value ? color.themeDark : color.themeBlue,
                borderWidth: 1,
                backgroundColor:
                  disable ? color.disableComponentColor :
                    selectedItem == item.value ? color.themeDark : color.themeShadeBackground, //borderColor:backgroundColor,
                // padding: fill ? 10 : 16,
                paddingHorizontal: '1.1vw',
                paddingTop: '0.5vw',
                paddingBottom: '0.5vw',
                marginBottom: '0.6vw',
                marginRight: '0.1vw'
              }, Style.allButtonBorderRadius]}
            >
              <Text
                style={{
                  fontSize: fill ? '1.3vw' : '0.7vw', //12
                  textAlign: "center",
                  color: selectedItem == item.value ? color.white : color.themeBlue,
                  fontWeight: "400" //500
                }}
              >
                {item.label}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })



    } else {
      return (
        <TouchableOpacity key={item.value} onPress={() => { setBack(selectKey, item.value, keyInObject), isDisable }}
          disabled={(isDisable || disable || idBaseDisable) ? true : false}
        >
          <View
            style={[{
              justifyContent: "center",
              alignItems: "center",
              // borderRadius: fill || borderNeeded ? 10 : 20,
              flexDirection: "row",
              borderColor: selected == item.value ? color.themeDark : color.themeBlue,
              borderWidth: 1,
              backgroundColor:
              (idBaseDisable && selected == item.value)?color.themeDark :
                disable ? color.disableComponentColor :
                  selected == item.value ? color.themeDark : color.themeShadeBackground, //borderColor:backgroundColor,
              // padding: fill ? 10 : 16,
              paddingTop: '0.5vw',
              paddingHorizontal: '1.1vw',
              paddingBottom: '0.5vw',
              marginBottom: '0.6vw',
              marginRight: '0.2vw'
            }, Style.allButtonBorderRadius]}
          >
            <Text
              style={{
                fontSize: fill ? '1.3vw' : '0.7vw', //12
                textAlign: "center",
                //color: borderNeeded ? color.themePink : (selected===item.value?color.themeBlue:color.white),
                color: selected == item.value ? color.white : color.themeBlue,
                fontWeight: "400" //500
              }}
            >
              {item.label}
            </Text>
          </View>
        </TouchableOpacity>

        // <SelectedButton
        //   //   {...otherProps}
        //       item={item} 
        //       fill={fill} 
        //       borderNeeded={borderNeeded}
        //       onPressItem={this.onPressAction.bind(this, selectedKey)}
        //       selected={selectedValue} 
        //   />

      );
    }
  };

  renderTextFieldView = (placeholder, stateName = '', value = '', isMultipleLineEnabeled = false, lineCount = 1,
    reference = '', updateObj = {}, autofocus = "", showLabel = false, disable = false,maxTextLength=500) => {
    return (
      <View>
        {
          showLabel ?
            <Text style={[styles.TextFieldViewText, {marginTop:stateName=="dosage"?0: '1.2vw',}]}>{placeholder}</Text>
            : null
        }

        <View
          style={[{
            minHeight: '2.3vw',
            // marginTop: 10,
            marginBottom: '1.2vw',
            backgroundColor: disable ? color.disableComponentColor : color.white,
            // borderRadius: Style.allButtonBorderRadius.borderRadius,
            zIndex: -3
          }, Style.allButtonBorderRadius]}
        >
          <TextInput
            disabled= {disable}
            // enabled={disable}
            underlineColorAndroid="transparent"
            placeholder={!showLabel ? placeholder : ""}
            maxLength={maxTextLength}
            style={[styles.TextFiedTextInput, {
              // borderRadius: Style.allButtonBorderRadius.borderRadius,
              borderColor: color.rightPanelInputBorder
            }, Style.allButtonBorderRadius]}
            // ref={text => (this.nameInput = text)}
            ref={reference ? reference : null}
            // defaultValue=""
            autoFocus={autofocus}
            autoCapitalize="none"
            value={value}
            // onChangeText={text => {

            //   if(stateName){
            //     this.updateStatebyElement(stateName,text)
            //   }
            // }

            // this.setState({ comments: text })
            // } 
            onChange={stateName ? this.updateStatebyElement.bind(this, stateName) : null}
            multiline={isMultipleLineEnabeled}
            numberOfLines={lineCount}
            onSubmitEditing={reference && updateObj ? () => {

              //addMedicationToList(value)
              let stateList = this.state[updateObj.stateList];

              stateList.push({ value: value, label: value });

            //  console.log(" - stateList - " + JSON.stringify(stateList));

              this.updateState(updateObj.flag, false)
              this.updateState(updateObj.newState, "")
              this.updateState(updateObj.stateList, JSON.parse(JSON.stringify(stateList)))
            } : null}
          />
        </View>
      </View>
    );
  };

  renderItem = (item, index) => {
    return (
      <View>
        <View style={{ paddingTop: 10 }}>
          <View
            style={{
              marginLeft: 5,
              width: "75%",
              flexDirection: "row",
              justifyContent: "space-between",
              // height: 50,
              alignItems: "center"
              // paddingBottom: 10
            }}
          >
            <View style={{}}>
              <Text style={{ fontSize: 14, color: "#2F2F2F" }}>
                {item.name}
              </Text>
              <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                {item.time}
              </Text>
            </View>

            <View>
              <Text style={{ fontSize: 12, color: "#2F2F2F" }}>
                {item.duration_time}
              </Text>
            </View>

            {item.treatment ? (
              <View>
                <Text style={{ fontSize: 15, color: "#2F2F2F" }}>
                  {item.treatment}
                </Text>
              </View>
            ) : (
                <View />
              )}
            <View
              style={{
                width: "20%",
                alignItems: "center",
                height: 60,
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: color.white,
                  height: 50
                }}
              >
                <TouchableOpacity>
                  {this.renderIdButton("pencil")}
                </TouchableOpacity>

                <TouchableOpacity>
                  {this.renderIdButton("trash")}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{ height: 0.5, backgroundColor: "#E0E0E0", width: "90%" }}
        />
      </View>
    );
  };

  renderSubHeader = (title, dataSet, isBtnNeeded) => {
    return (
      <View style={{ marginTop: 20, marginBottom: 10 }}>
        <View style={{ flexDirection: "row", height: 50 }}>
          <Text style={{ fontSize: 18, color: color.notesTableTitle }}>{title}</Text>
          {isBtnNeeded ? (
            <TouchableOpacity style={{ marginLeft: 20 }}>
              {this.renderIdButton("pencil")}
            </TouchableOpacity>
          ) : null}
        </View>

        <FlatList
          style={{ marginTop: 10 }}
          data={dataSet}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          renderItem={({ item, index }) =>
            this.renderText(item.name, item.value, false)
          }
        />
      </View>
    );
  };

  renderText = (name, value, isIndividual) => {
    return (
      <View>
        {name ?
          <Text
            style={[styles.TextBoxView, {color: color.notesTableTitle}]}
          >
            {name}
          </Text> : null}
        <Text style={{ fontSize: '0.9vw', color: color.black, marginTop: '0.3vw' }}>{value ? value : "-"}</Text>
      </View>
    );
  };



  renderOneItem = (heading, value) => {
    return (
      <View>
        <View>
          <View style={{ paddingTop: 10 }}>
            <View
              style={{
                marginLeft: 5,
                width: "100%",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <View
                style={{
                  width: platform === "web" ? "20%" : "15%"
                }}
              >
                <Text style={{ fontSize: 14, color: "#848484" }}>
                  {heading[0]}
                </Text>
              </View>

              <View
                style={{
                  width: platform === "web" ? "20%" : "15%"
                }}
              >
                <Text style={{ fontSize: 12, color: "#848484" }}>
                  {heading[1]}
                </Text>
              </View>
              <View
                style={{
                  width: platform === "web" ? "30%" : "20%"
                }}
              >
                <Text style={{ fontSize: 12, color: "#848484" }}>
                  {heading[2]}
                </Text>
              </View>
              <View
                style={{
                  width: "20%",
                  alignItems: "center",
                  // height: 30,
                  flexDirection: "row"
                }}
              ></View>
            </View>
            <View
              style={{
                marginLeft: 5,
                width: "100%",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <View
                style={{
                  width: platform === "web" ? "20%" : "25%"
                }}
              >
                <Text style={{ fontSize: 16, color: color.black }}>
                  {value[0]}
                </Text>
              </View>

              <View
                style={{
                  width: platform === "web" ? "20%" : "25%"
                }}
              >
                <Text style={{ fontSize: 16, color: color.black }}>
                  {value[1]}
                </Text>
              </View>
              <View
                style={{
                  width: platform === "web" ? "30%" : "30%"
                }}
              >
                <Text style={{ fontSize: 16, color: color.black }}>
                  {value[2]}
                </Text>
              </View>
              <View
                style={{
                  width: "20%",
                  alignItems: "center",
                  height: 60,
                  flexDirection: "row"
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: 5,
                    paddingBottom: 5,
                    backgroundColor: color.white,
                    height: 50
                  }}
                >
                  <TouchableOpacity>
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>

                  <TouchableOpacity>
                    {this.renderIdButton("trash")}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderIconBtn = (icon, text, createBtn) => {
    return (
      <View
        style={[{
          // height: 30,
          // width: text === "New" ? 90 : 103,
          justifyContent: "center",
          alignItems: "center",
          // borderRadius: createBtn || text === "New" ? 20 : 8,
          flexDirection: "row",
          backgroundColor: createBtn ? color.themeBlue : text === "New" || text === "Cancel" ? color.white : color.themeDark,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" || text === "Cancel" ? 1 : 0,
          borderColor: text === "New" || text === "Cancel" ? "#848484" : color.white,
          paddingHorizontal: '1.1vw', paddingVertical: '0.5vw'
        }, Style.allButtonBorderRadius]}
      >
        { text != "Cancel" ? 
        <Icon style={{ marginRight: '1vw' }} name={icon} size={platform === "web" ? '0.6vw' : '1.2vw'} color={text === "New" ? "#848484" : color.white} /> : null }
        <Text
          style={{
            fontSize: platform === "web" ? '0.6vw' : '0.8vw',
            color: text === "New" || text === "Cancel" ? "#848484" : color.white,
            fontWeight: "700"
          }}
        >
          {text}
        </Text>
      </View>
    );
  };


  renderNewSubHeader(title){
    return(
      <View style={[styles.NewSubHeaderView, {backgroundColor: color.sectionHeadingDarkColor }]}>
        <Text style={styles.NewSubHeaderText}>{title}</Text>
      </View>
    )
  }

  renderTitleWithMultipleBtn = (title, dataSet, columnNos, isNewBtnNeeded, selectKey, selectedValue, returnCallBack, isLineNeeded = false, isRightSideSection = false, keyInObject = "", getAvailableEyeTypes = [], isMultiSelectButton = false, disable, idBaseDisable=false) => {



    return (
      <View style={{ marginBottom: '0.6vw' }}>
        {/* {this.renderSubHeadingBorder(title, isLineNeeded, isRightSideSection)} */}
        {this.renderNewSubHeader(title)}


        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
          {

            // getAvailableEyeTypes > 0 ?

            // getAvailableEyeTypes && getAvailableEyeTypes.map((eye,index)=>{
            //   alert("if  1" )
            //   dataSet && dataSet.map((item)=>{
            //   alert("if  2" )

            //     if(eye == item.value){
            //       // alert("if")
            //       // alert(eye,item.value)
            //       return this.renderTextBtnSelectable(item, false, false, selectKey, selectedValue, returnCallBack, keyInObject,true)
            //     }else{
            //       // alert("else")
            //       // alert(eye,item.value)
            //       return this.renderTextBtnSelectable(item, false, false, selectKey, selectedValue, returnCallBack, keyInObject)
            //     }

            //   })
            // })

            // :

            dataSet && dataSet.map((item,index) => {
              // alert("else  1" )

              // var  getAvailableEyeTypes

              // function isCherries(fruit) {
              //   return fruit.name === 'cherries';
              // }


              var disable = false
              
              // var idBaseDisable = false

              if(getAvailableEyeTypes.length > 0 ){
                
                const found = getAvailableEyeTypes.find( ({ eye_type }) => eye_type === item.label );

                if(found && found.eye_type && (found.eye_type=="RE" || found.eye_type=="LE")){
                  disable=true
                  // idBaseDisable=idBaseDisable
                }


                // var data = getAvailableEyeTypes.find(isCherries)
              // const found = getAvailableEyeTypes.find(element => element.eye_type == item.label);
              // console.log(getAvailableEyeTypes)
              // console.log(JSON.stringify(found)+"    -----   ")
              }

              return this.renderTextBtnSelectable(item, false, false, selectKey, selectedValue, returnCallBack, keyInObject, "", isMultiSelectButton, disable,idBaseDisable)
            })
          }
        </View>

        {isNewBtnNeeded ? this.renderIconBtn("plus", "New", true) : null}

      </View>
    );
  };

  renderIconNewBtn = (icon, text, createBtn, pressEvent = false, callBack = null, disabled = false) => {
    return (
      <TouchableOpacity onPress={pressEvent ? callBack : null} disabled={disabled}>
        <View
          style={[{



            // height: 30,
            // width: text === "New" ? 80 : 103,
            // justifyContent: "center",
            // alignItems: "center",
            // borderRadius: createBtn || text === "New" ? 15 : 8,
            flexDirection: "row",
            backgroundColor: createBtn ? color.white : text === "New" ? color.white : color.themePink,
            // justifyContent: "space-evenly",
            borderWidth: createBtn || text === "New" ? 1 : 0,
            borderColor: text === "New" ? "#848484" : color.white,
          }, Style.componentBtnDDPadding]}
        >
          <Icon style={{ marginRight: '1vw' }} name={icon} size={platform === "web" ? '1vw' : '1.2vw'} color={text === "New" ? "#848484" : "#ffffff"} />
          <Text
            style={{
              fontSize: platform === "web" ? '0.9vw' : '0.9vw',
              color: text === "New" ? "#848484" : color.white,
              fontWeight: "700"
            }}
          >
            {text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  numberValidation(num){
    return !/[^.[0-9]]*/.test(num);
  }


  updateState = (key, value, keyInObject) => {

   // console.log(" ---  updateState = " + key + " - - - " + value)
   // console.log(" states --- " + JSON.stringify(this.state))

    let states = this.state;

    if (keyInObject) {

      states[keyInObject][key] = value;
    }else if (key === "years") {
      if(this.numberValidation(value)){
        states[key] = value;

      }
    }else if(key === 'days'){
      if(this.numberValidation(value) && +value <= 30){
        states[key] = value
      }
    }else if (key === "lens_type" || key === "lens.lens_type" ) {

        let keyArr = key.split(".")
        let keyFirst = keyArr.length>0?keyArr[0]:"";
        let keySecond = keyArr.length>1?keyArr[1]:"";

        var items = keySecond?states[keyFirst][keySecond]:(states[keyFirst] || []);

        var getSelectedValue;
        var spliceData;

        if (items && items.length > 0) {
          for (var i = 0; i < items.length; i++) {
            
            if (items[i].value == value.value) {
              
              spliceData = items[i];
              items.splice(i, 1)
              var data = this.removeDuplicate(items);

              if(keySecond){
                states[keyFirst][keySecond] = data;
              }else{
                states[keyFirst] = data;
              }

              this.setState({ states })
              return

            } else {
              if (spliceData && spliceData.value !== value.value) {
              } else {
                getSelectedValue = value
              }
            }
          }

          if (getSelectedValue) { 
            items.push(value); 
          }

        } else {
          items = []
          items.push(value)
        }

        var data = this.removeDuplicate(items);

        if(keySecond){
          states[keyFirst][keySecond] = data;
        }else{
          states[keyFirst] = data;
        }
        this.setState({ states })

    } else {

      if (key === "month" || key === "months" || key === "no_of_months") {

        let parsedVal = parseInt(value);
        // invalid number
        if (isNaN(parsedVal)) {
          value = "";
        } else {
          if (parsedVal > 11) {
            // max.month is 11, else reset to old value
            value = states[key];
          } else {
            // max.month is 11, so update the new value
            value = parsedVal;
          }
        }
      } 

      states[key] = value;
    }

    this.setState({ states });

  }

  updateStatebyElement = (key, event) => {

    let states = this.state;
    let val = event.target.value;

    if(key==="comments" || key==="reason" || key==="treatment" || key==="ProvisionalComment") {

      if(val.length<300)
      {
        states[key] = val;
      }
      
    }else{
      states[key] = val;
    }

    this.setState({ states });

  }

  // doctor notes highlight section
  isSectionHightlighted(currentView, selectedView, currentStatus, stateName) {

    let isToHighLight = currentView === selectedView ? true : false;

    (currentStatus !== isToHighLight) ? this.updateState(stateName, isToHighLight) : null;

  }

  doSectionHightlighted(currentView, currentStatus, stateName) {

    let alreadyHighLighted = currentStatus ? true : false;

    if (!alreadyHighLighted) {

      this.updateState(stateName, true);

      this.props.triggerNewItem(currentView);
    }

  }

  renderNumberTxtWithHeader = (label, key, value, disable = false, placeholderText = null) => {

    return (<View style={{ marginRight: '0.6vw' }}>
      <Text style={[styles.NumTxtWithHeaderText, { color: color.themeBlue }]}>
        {label}
      </Text>
      <View
        style={styles.NumTxtWithHeaderView}
      >
        <TextInput
          editable={!disable}
          underlineColorAndroid="transparent"
          placeholder={placeholderText ? placeholderText : label}
          placeholderTextColor={color.lightGray}
          style={[styles.NumTxtWithHeaderTextInput, {borderColor: color.themeBlue,
            backgroundColor: disable ? color.disableComponentColor : null,
            color: color.themeBlue,marginLeft:key === 'days' ? '2vw' : ''}, Style.allButtonBorderRadius]}
          // ref={text => (this.nameInput = text)}
          // defaultValue=""
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            //this.setState({ years: text })}
            this.updateState(key, text)
          }}
        />
      </View>
    </View>
    )
  }

  renderTestIconBtn = (text, icon, createBtn) => {
    return (
      <View
        style={{
          height: 40,
          width: text === "New" ? 70 : "90%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 4 : 4,
          flexDirection: "row",
          backgroundColor: createBtn ? color.themeShadeBackground : text === "New" ? color.white : color.themeShadeBackground,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 1,
          borderColor: color.themeDark,
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: platform === "web" ? 17 : 14,
            color: text === "New" ? "#848484" : color.themeDark,
            fontWeight: "500"
          }}
        >
          {text}
        </Text>


      </View>
    );
  };

  renderIconsBtn = (icon, text, createBtn) => {
    return (
      <View
        style={{
          height: 35,
          width: text === "New" ? 100 : 130,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: createBtn || text === "New" ? 20 : 8,
          flexDirection: "row",
          backgroundColor: createBtn ? color.themeDark : text === "New" ? color.white : color.themeDark,
          justifyContent: "space-evenly",
          borderWidth: createBtn || text === "New" ? 1 : 0,
          borderColor: text === "New" ? color.themeDark : color.white
        }}
      >
        <Icon name={icon} size={platform === "web" ? 25 : 20} color={text === "New" ? color.themeDark : color.white} />
        <Text
          style={{
            fontSize: platform === "web" ? 10 : 13,
            color: text === "New" ? color.themeDark : color.white,
            fontWeight: "700"
          }}
        >
          {text}
        </Text>
      </View>
    );
  };

  renderTextFieldViewDrug = (placeholder, stateName = '', value = '', isMultipleLineEnabeled = false, lineCount = 1, reference = '', updateObj = {}, key = "") => {
    return (
      <View
        style={[styles.TextFieldDrugView, {backgroundColor: color.white}, Style.allButtonBorderRadius]}
      >
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={placeholder}
          style={[{
            color: "#525252",
            minHeight: '2.3w',
            padding: '0.5vw',
            marginRight: 0,
            flex: 1,
            fontSize: '0.9vw',
            borderWidth: 0.5,
            // borderRadius: 10,
            borderColor: color.rightPanelInputBorder
          }, Style.allButtonBorderRadius]}
          // ref={text => (this.nameInput = text)}
          ref={reference ? reference : null}
          // defaultValue=""
          autoCapitalize="none"
          value={value ? value : ""}
          onChangeText={text => {

            let states = this.state;
            states[stateName] = text;

            this.setState({ states }, () => {
             
              this.setState({
                states
              })

            });
            //   this._getDrugSuggestion(text);
          }}
          // onChange={this._getDrugSuggestion}
          multiline={isMultipleLineEnabeled}
          numberOfLines={lineCount}
          onSubmitEditing={() => {

            if (value) {

              let stateList = this.state[updateObj.stateList];

              stateList.push({ value: value, label: value });

              let states = this.state;
              states[updateObj.stateList] = JSON.parse(JSON.stringify(stateList));

              states[updateObj.flag] = false
              states[updateObj.newState] = ""
              states[updateObj.forState] = value
              states[stateName] = ""
              updateObj.flagValue = false  

              this.setState({ states }, () => {

                this.updateState(updateObj.flag, false)
                this.updateState(updateObj.newState, "")
                this.updateState(updateObj.forState, value)                   
                this.setState({ states })
              })

             console.log(" - stateList - " + updateObj);
            } else {

              if (reference && reference.current) {
                reference.current.focus();
              }
            }


            // this.updateState(updateObj.stateList, JSON.parse(JSON.stringify(stateList)))
            // this.updateState(updateObj.flag, false)
            // this.updateState(updateObj.newState, "")
            // this.updateState(updateObj.forState, value)

          }}
        />
      </View>
    );
  }

  renderTextFieldUpdateObj = (placeholder, stateName = '', value = '', isMultipleLineEnabeled = false, lineCount = 1, reference = '', updateObj = {}, key = "") => {
    return (
      <View
        style={[{
          maxWidth: 100,
          minHeight: 35,
          // marginTop: 10,
          marginBottom: 20,
          backgroundColor: color.white,
          // borderRadius: 10
        }, Style.allButtonBorderRadius]}
      >
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={placeholder}
          style={{
            color: "#525252",
            minHeight: 35,
            padding: 8,
            marginRight: 0,
            flex: 1,
            borderWidth: 0.5,
            borderRadius: 10,
            borderColor: color.rightPanelInputBorder
          }}
          // ref={text => (this.nameInput = text)}
          ref={reference ? reference : null}
          // defaultValue=""
          autoCapitalize="none"
          value={value}
          onChangeText={text => {

            let states = this.state;
            states[stateName] = text;

            this.setState({ states });
            //   this._getDrugSuggestion(text);
          }}
          // onChange={this._getDrugSuggestion}
          multiline={isMultipleLineEnabeled}
          numberOfLines={lineCount}
          onSubmitEditing={() => {

            if (value) {

              let stateList = this.state[updateObj.stateList];
              let newObj = { value: value, label: value }
              stateList.push(newObj);

              let states = this.state;
              states[updateObj.stateList] = JSON.parse(JSON.stringify(stateList));

              this.setState({ states }, () => {

                this.updateState(updateObj.flag, false)
                this.updateState(updateObj.newState, "")
                this.updateState(updateObj.forState, newObj)
              })

           //   console.log(" - stateList - " + JSON.stringify(stateList));
            } else {

              if (reference) {
                reference.current.focus();
              }
            }

          }}
        />
      </View>
    );
  }

  addItemInArray = (list, item) => {

    let flag = false;
    if (item) {

      for (var i = 0; i < list.length; i++) {
        if (list[i].value === item) {
          flag = true;
        }
      }

      if (!flag) {
        list.push({ value: item, label: item });
      }
    }

    return list;
  }

  triggerNewItemAdd = (key, refInput) => {

    let states = this.state;

    states[key] = true;

    this.setState({ states });

    if(refInput && refInput.current) { 

     refInput.current.focus();
     
    }
  }

  getDurationTypeFromNos = (value) => {

    let numberWithType = "-";
    let parsedValue = parseInt(value);

    if (!isNaN(parsedValue) && parsedValue > 0) {

      let divisableReturn;
      if (parsedValue % 30 === 0) {
        divisableReturn = parsedValue / 30;
        numberWithType = divisableReturn === 1 ? divisableReturn + " month" : divisableReturn + " months";

      } else if (parsedValue % 7 === 0) {
        divisableReturn = parsedValue / 7;
        numberWithType = divisableReturn === 1 ? divisableReturn + " week" : divisableReturn + " weeks";
      } else {
        numberWithType = parsedValue === 1 ? parsedValue + " day" : parsedValue + " days";
      }
    }

    return numberWithType;
  }

  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
  }

  render() {
    return (
      <View />
    );
  }
}
const styles = StyleSheet.create({
      NewSubHeaderView:{
        marginBottom:'1vw',
        paddingLeft: '1vw',
        justifyContent:"center",
        height: '2.2vw' 
      },
      NewSubHeaderText: { 
        fontSize: '0.8vw' 
      },
      TextFieldDrugView: {
        maxWidth: '7vw',
        minHeight: '2.3vw',
        // marginTop: 10,
        marginBottom: '1.2vw',
        // borderRadius: 10
      },
    TextBoxView: {
      fontSize: '0.9vw',
      paddingLeft: 0,
      paddingRight: '1.2vw',
      marginRight: '1vw' //isIndividual ? 30 : 0
    },
      NumTxtWithHeaderText: { 
        fontSize: '1.2vw', 
        marginLeft: '0.3vw' 
      },
      NumTxtWithHeaderView: {
        height: '2vw',
        marginTop: '0.6vw',
        // backgroundColor: color.white, 
      },
      NumTxtWithHeaderTextInput: {
        // borderRadius: 10,
        width: '5.5vw',
        borderWidth: 1,
        height: '2vw',
        paddingLeft: '0.3vw',
        marginRight: 0,
        flex: 1,
        textAlign: "center",
        fontSize: '0.9vw'
      },
  TitleBorderView: { 
    marginRight: '0.6vw'
     },
      SubHeadingBorderView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: '1vw',
        flex: 1
      },
      SubHeadingBorderText: { 
        fontSize: '1.2vw'
      },
      SubHeadingBorderContainer: {
        marginLeft: '0.6vw',
        width: "100%",
        height: 1,
        flex: 2
      },
      TextFieldViewText:{ 
        fontSize: '1vw', 
        marginBottom: '0.6vw', 
        paddingLeft: '0.3vw', 
        paddingRight: '0.3vw', 
        color: "black" 
      },
      TextFiedTextInput:{
        paddingHorizontal: '0.6vw',
        color: "#525252",
        minHeight: '2.3vw',
        padding: '0.5vw',
        marginRight: 0,
        flex: 1,
        borderWidth: 0.5,
        fontSize: '1vw'
      },
      IDBtnView: {
        width: '1.6vw',
        height: '1.6vw',
        borderRadius: '1.2vw',
        shadowOpacity: 0.2,
        shadowRadius: 4,
        shadowOffset: {
          height: '0.1vw',
          width: '0.1vw'
        },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: '1.2vw'
      }
      
})