import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { StyleSheet, Text, View, Picker, TouchableOpacity } from 'react-native';
import OpthamologyService from "../../../../../network/OpthamologyService";
import { Constants } from "../../../../../utils/Constants";
import { Tooltip } from 'antd'
import Icon from 'react-native-vector-icons/FontAwesome'
import { color } from '../../../../../styles/Color';

class ReferalSource extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromDate: this.props.FilterDate.fromDate,
      ToDate: this.props.FilterDate.toDate,
      refSourceData: [],
      refSourceCount: '',
      refSourceLabels: '',
      srcTotalCount: 0
    }
  };
  componentDidMount() {
    this.getRefSourceData()
  }

  componentWillReceiveProps(props) {
    if (props.FilterDate.fromDate != this.state.FromDate || props.FilterDate.toDate != this.state.ToDate) {
      this.setState({
        FromDate: props.FilterDate.fromDate,
        ToDate: props.FilterDate.toDate
      }, () => {
        this.getRefSourceData()
      })
    }
  }

  /* Referred by Doctor API Start*/
  getRefSourceData() {
    var serviceUrl =
      Constants.REFERRAL_SOURCE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.getRefSourceSuccess,
      this.getRefSourceFailure
    );

  }

  getRefSourceSuccess = (success) => {
    if (success.status === "success") {
      var referalSource = success.Data
      this.setState({
        refSourceData: referalSource
      })
      var refSourceLabels = [];
      var refSourceDatas = [];

      Object.keys(referalSource).forEach((key) => {
        if (key) {
          refSourceLabels.push(key);
          refSourceDatas.push(referalSource[key]);
        }
      });

      this.setState({
        refSourceLabels: refSourceLabels,
        refSourceCount: refSourceDatas,
        srcTotalCount: 0
      }, () => {
        for (let i = 0; i < this.state.refSourceCount.length; i++) {
          this.state.srcTotalCount = this.state.srcTotalCount + this.state.refSourceCount[i]
        }
        this.setState({
          srcTotalCount: this.state.srcTotalCount
        })

      });
    }else{
      this.props.Response(success.message)
    }
  };

  getRefSourceFailure = (error) => {
    this.props.Response(error.message)
  };
  /* Referred by Doctor API End*/

  /* Export Excel API Start */
  getRefSourceExcel = () => {
    var serviceUrl =
      Constants.REFERRAL_SOURCE +
      "?start_date=" +
      this.state.FromDate +
      "&end_date=" +
      this.state.ToDate +
      "&export_type=excel";

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      this.getRefSourceExcelSuccess,
      this.getRefSourceExcelFailure,
      "excel"
    );
  }
  getRefSourceExcelSuccess = (success) => {
    if (success.statusText === "OK") {
      var pom = document.createElement('a');
      var csvContent = success.data; //here we load our csv data 

      let filename = success.headers["content-disposition"].split("filename=")[1]

      var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
      var url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute('download', filename ? filename : 'registry.xlsx');
      pom.click();
    }
  }
  getRefSourceExcelFailure = (error) => {
    console.log(error)
  }
  /* Export Excel API End */
  render() {
    const data = {
      datasets: [
        {
          data: this.state.refSourceCount,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(204, 255, 153)',
            'rgb(97, 207, 126)',
            'rgb(168, 50, 155)',
            'rgb(16, 50, 15)'
          ],
        },
      ],
      labels: this.state.refSourceLabels,
    };

    return (
      <View style={{ display: 'flex', }}>

        <View style={styles.IconView}>
          <TouchableOpacity onPress={this.getRefSourceExcel}>
            <Tooltip title={'Export to Excel'}>
              <Icon size={'1.3vw'} name={'download'} color={color.themeDark} />
            </Tooltip>
          </TouchableOpacity>
        </View>

        {this.state.srcTotalCount > 0 ?
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={styles.TopView}>
              <Text style={styles.RoleNameText}>{"No of Patients"}</Text>
            </View>
            <View style={styles.ChartView}>

              <View style={{ flex: 1 }}>
                <Bar
                  width={280}
                  height={310}
                  data={data}
                  options={

                    {
                      legend: {
                        display: false,
                      },
                      title: {
                        position: "bottom",
                        display: true,
                        text: ['Referral Source'],
                        fontColor: ['rgb(4, 183, 177)']
                      },
                      responsive: true,

                    }}

                />
              </View>
            </View>
          </View>
          :
          <View style={styles.NoRecView}>
            <Text style={styles.NoRecText1}>Referral Source</Text>
            <Text style={styles.NoRecText2}>No Records To Show</Text>
          </View>
        }

      </View>

    )
  }
}

export default ReferalSource;

const styles = StyleSheet.create({
  TotalAmountView: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  TotalAmountText: {
    fontSize: '0.91vw',
    color: '#662E9B',
    fontWeight: '500'
  },
  TotalAmountValue: {
    fontSize: '1vw',
    color: '#662E9B',
    fontWeight: 'bold'
  },
  NoRecView: {
    marginTop: '9vw',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  NoRecText1: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: 'bold'
  },
  NoRecText2: {
    fontSize: '1vw',
    color: 'rgb(4, 183, 177)',
    fontWeight: '500',
    marginTop: '1vw'
  },
  PieChart: {
    width: '30vw',
    height: '40vh'
  },
  RoleNameText: {
    fontSize: '0.8vw',
    color: 'rgb(4, 183, 177)',
    justifyContent: 'center',
    display: 'flex',
  },
  TopView: {
    transform: [{ rotate: '-90deg' }],
    width: '20vw',
    height: '1vw',
    marginTop: '10vw',
    marginLeft: '-9vw',
  },
  ChartView: {
    marginLeft: '-9.5vw'
  },
  IconView: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '1vw'
  }
})

