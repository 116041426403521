import React from "react";
import { View } from "react-native";
import { StackNavigator } from 'react-navigation';

import Base from "./Base";
import AsyncStorage from "../AsyncStorage";

export default class AuthLoadingScreen extends Base {
  constructor(props) {
    super(props);

    this._checkLogin();
  }

  _checkLogin = async () => {
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    this.props.navigation.navigate(
      loggedIn === "true" ? "homeNavigator" : "loginNavigator"
    );
  };

  render() {
    return (
      <View/>
    );
  }
}
