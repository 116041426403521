import React from "react";
import {
  View,
  Text,
  // FlatList,
  TextInput,
  // ScrollView,
  // Image,
  Platform,
  Dimensions,
  CheckBox,
  Image,
  Picker,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import { CommonButton } from '../BaseComponent'
import { color } from "../../../styles/Color";
import Style from "../../../styles/Style";
// import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";

import OpthamologyService from "../../../network/OpthamologyService";
import { Constants } from "../../../utils/Constants";
import { sha256, sha224 } from 'js-sha256';
import AsyncStorage from "../../../AsyncStorage";
import moment from "moment";

const platform = Platform.OS;
const pageTitle = "Glass Prescription";
const pageName = "glassPrescription";
const isPageName = "isGlassPrescription";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

// for re-use
const temp_lens = {
  "id": "",
  "chart_used": "",
  "muscle": "",
  "occupation": "",
  "lens_type": [],
  "lens_coating": "",
  "coating": "",
  "wearing_instructions": "",
  "comment": "",
  "continue_with_same_lense": "",
  "same_glass": false
};

export default class GlassPrescription extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,
      complaintsData: [],
      isComplaint: isPageName === this.props.selectedView ? true : false,
      selectedResponseData: [],
      rightEyeData: {
        "id": "",
        "eye_type": "RE",
        "DV_SPH": "",
        "DV_CYL": "",
        "DV_AXIS": "",
        "DV_VISION": "",
        "DV_BCDVA": "",
        "DV_BCNVA": "",
        "DV_PRISM": "",
        "DV_BASE": "",
        "add":""
      },
      leftEyeData: {
        "id": "",
        "eye_type": "RE",
        "DV_SPH": "",
        "DV_CYL": "",
        "DV_AXIS": "",
        "DV_VISION": "",
        "DV_BCDVA": "",
        "DV_BCNVA": "",
        "DV_PRISM": "",
        "DV_BASE": "",
        "add":""
      },
      lens: JSON.parse(JSON.stringify(temp_lens)), // for deep clean

      chart_used:"",
      muscle:"",
      occupation:"",
      lens_type:[],
      lens_coating:"",
      coating:"",
      wearing_instructions:"",
      comment:"",
      continue_with_same_lense:"",
      isTransferToCRMdisabled: false,
      // dec update
      continueWithSameGlass: false,
      isCopyFromSR: false,
      // continueWithSame:this.props.continueWithSameGlass
      patient_info_OpticalCRM:{
        "mode": "",
        "customer_id": "",
        "branchID": Constants.OPTICAL_CRM_CUSTOMER_NUMBER + "-1",
        "customerTitle": "",
        "fullName": props.patient_name,
        "referralCode": "",
        "membershipID": props.patient_account_number,
        "mobileNumber": props.patient_mobile_number,
        "mobileNumber1": "",
        "mobileNumber2": "",
        "mobileNumber3": "",
        "mobileNumber4": "",
        "email": "",
        "taxIdentificationNumber": "",
        "customerCategory": "",
        "gender": props.patient_gender == "Male" ? "M" : "F",
        "insuranceSchemeName": "",
        "insuranceSchemeNumber": "",
        "customerGSTNumber": "",
        "companyName": "",
        "line1": "",
        "line2": "",
        "locality": "",
        "country": "",
        "state": "",
        "city": "",
        "pincode": "",
        "dateOfBirth": props.patient_dob ? props.patient_dob : props.patient_approx_dob,
        "dateOfAnniversary": "",
        "customerAge": props.patient_age,
        "notes": ""
    }
    };

    this.firstEyeSave = false;
    this.secondEyeSave = false;

    this.renderColumn = this.renderColumn.bind(this) 
    this._saveGlassPrescriptionData = this._saveGlassPrescriptionData.bind(this)
  }

  componentDidMount() {
    if(localStorage.getItem("service_type") === "OPT"){
      this.getOpticalCRMcustomerDetails();
    }    
    setTimeout(() => {
      this.getComplaints();
    }, 4000);

  }
  getOpticalCRMcustomerDetails = () =>{
    var url = Constants.OPTICAL_EMED_GET_CUSTOMER_DETAILS;
    OpthamologyService.getInstance().getComplaints(
      url,
      this,
      this.getCRMCustomerdetailsSuccess,
      this.getCRMCustomerdetailsFailure
    );
  }

  getCRMCustomerdetailsSuccess = response => {
    if(response){
      if(response.status =="success"){
        if(response.data){
          console.log("CRM CUSTOMER DETAILS::", response.data);
          AsyncStorage.setItem("Optical_CRM_API_KEY", response.data.api_key);
          AsyncStorage.setItem("Optical_CRM_BRANCH_ID", response.data.branch_id);
          AsyncStorage.setItem("Optical_CRM_CUSTOMER_NUMBER", response.data.customer_number);
          AsyncStorage.setItem("OPTICAL_CRM_API_TOKEN", sha256(response.data.customer_number+response.data.api_key));
          console.log("OPTICAL CRM TOKEN:::",sha256(response.data.customer_number+response.data.api_key));
        }else{
          // this.props.showResposeValue("error",response.message)
          // console.log(response.message);
        }
        
      }else if(response.status == "fail"){
        this.props.showResposeValue("error",response.message)
        // console.log(response.message);
      }
    }
  }
  getCRMCustomerdetailsFailure = error =>{
    this.props.showResposeValue("error",error.message)
    console.log(error);
  }

  componentWillReceiveProps(props) {

    if(props && props.patientAppointment && props.patientAppointment.patient_id){
      //   this.setState({  isLocked:props.patientAppointment.is_locked })
      this.state.isLocked=props.patientAppointment.is_locked
    }
  
    if (props.reloadData && props.reloadData === pageName) {
      // call once
      this.getComplaints();
      // refresh false
      this.props.refreshData("");
    }
    /*
    if(props.isCopyFromSR && props.isCopyFromSR!==this.state.isCopyFromSR) {

      // load data from subjective refraction
      this.setState({
        isCopyFromSR: true
      }, ()=>{
        this.getSubjectiveRefractionData()
      })
    }
    */
    let updateFontColor = isPageName === props.selectedView ? true : false;

    if (updateFontColor !== this.props.isComplaint) {

      this.setState({ isComplaint: updateFontColor });
    }
  }

  getComplaints = () => {
    // tAppointment.appointment_id----------------< " + this.state.patientAppointment.appointment_id)
    // var service_url = Constants.OPTHAMOLOGY_COMPLAINTS_GET + "?appointment_id=" + this.state.patientAppointment.appointment_id;
    var url = Constants.GLASS_PRESCRIPTION + "?appointment_id=" + this.state.patientAppointment.appointment_id;
    setTimeout(() => {
      OpthamologyService.getInstance().getComplaints(
        // service_url,
        url,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );  
    }, 4000);

  };

  getComplaintsSuccess = response => {

    this.firstEyeSave = false;
    this.secondEyeSave = false;
    
    if (response.status === "success") {
      // var field = this.state;
      // field["complaintsData"] = response.data;
      // this.setState({ field });
      this.setState({
        selectedResponseData: response.data.glass_prescription
      }, () => {
        for (var i = 0; i < this.state.selectedResponseData.length; i++) {
          if (this.state.selectedResponseData[i].eye_type == "LE") {
            this.setState({
              leftEyeData: this.state.selectedResponseData[i],
            })
          } else if (this.state.selectedResponseData[i].eye_type == "RE") {
            this.setState({
              rightEyeData: this.state.selectedResponseData[i],
            })
          }
        }
      })
      
      if (response.data && response.data.lens && response.data.lens.id) {
        
        console.log(" lens ")
        console.log(" lens --- " + JSON.stringify(response.data.lens) )

        var { lens } = this.state;
        var states = this.state;

        lens["id"]=response.data.lens.id
        lens["chart_used"]=response.data.lens.chart_used
        lens["muscle"]=response.data.lens.muscle
        lens["occupation"]=response.data.lens.occupation
        lens["lens_type"]=response.data.lens.lens_type && response.data.lens.lens_type!="NIL" && (response.data.lens.lens_type).length>0?response.data.lens.lens_type:[]
        lens["lens_coating"]=response.data.lens.lens_coating  // && (response.data.lens.lens_coating).length>0?response.data.lens.lens_coating:[]
        lens["coating"]=response.data.lens.coating
        lens["wearing_instructions"]=response.data.lens.wearing_instructions
        lens["comment"]=response.data.lens.comment
        lens["continue_with_same_lense"]=response.data.lens.continue_with_same_lense
        lens["same_glass"]=response.data.lens.same_glass

        states["continueWithSameGlass"]=response.data.lens.same_glass
        // state["lens"]=response.data.lens
        
        this.setState({
          lens,
          states
          // continueWithSameGlass : response.data.lens.same_glass
          // // lens: JSON.parse(response.data.lens)
          // lens: response.data.lens
        },()=>{
          // alert(JSON.stringify(this.state.lens));
        })
      } else {
      }
    }
  };

  getComplaintsFailure = error => {
  //  console.log("opthamology complaints GET error response");
    console.log(error);
  };

  triggerNewItem = (key) => {

    this.props.triggerNewItem(key);
    this.props.editItem(pageName, this.state.lens);
    this.props.updateContinueWithSameGlassFlag(this.state.continueWithSameGlass)
  }

  renderColumnOld = (label, value) => {
    return (
      <View style={Style.contentTableDataCell}>
        <Text style={Style.contentTableTitle}>{label}</Text>
        <Text style={Style.contentTableData}>{value || "-"}</Text>
      </View>
    )
  }

  renderColumn(label, value, flex=0.1, key, subkey=''){

    let states = this.state;
    let { lens, isLocked } = this.state;

    return(
      <View style={[Style.contentTableDataCell, {flex:flex}]}>
        <Text style={Style.contentTableTitle}>{label}</Text>
        <TextInput 
          disabled={this.state.continueWithSameGlass?true:null}
          onChangeText={(text)=>{

            if(subkey)
            {
              states[subkey][key] = text
            }
            else
            { 
              states[key] = text
            }

            this.setState({ states })

          }}
          value={value} 
          style={styles.ColumnTextInput} 
          // editable={isLocked?false:lens.same_glass?false:true}
          editable={false}

        />
  
      </View>
    )
  }
  transferToOpticalHouse = () =>{
    this.setState({isTransferToCRMdisabled: true});
    if(this.props.patientAppointment.patient_id){
      let patient_id = this.props.patientAppointment.patient_id;
      var url = Constants.OPTICAL_EMED_GET_PATIENT_DETAILS + "?patient_id="+patient_id;
      OpthamologyService.getInstance().getComplaints(
        url,
        this,
        this.getOpticalCRMPatientDetailsSuccess,
        this.getOpticalCRMPatientDetailsFailure
      )
    }
    
  }
  getOpticalCRMPatientDetailsSuccess = async response =>{
    if(response.status == "success"){
      if(response.data){
        if(this.state.rightEyeData.DV_SPH =="" || this.state.rightEyeData.DV_CYL=="" || this.state.rightEyeData.DV_AXIS =="" || this.state.rightEyeData.DV_BCDVA == "" || this.state.rightEyeData.DV_BCNVA =="" || this.state.rightEyeData.DV_PRISM=="" || this.state.rightEyeData.add =="" ||
          this.state.leftEyeData.DV_SPH =="" || this.state.leftEyeData.DV_CYL=="" || this.state.leftEyeData.DV_AXIS =="" || this.state.leftEyeData.DV_BCDVA == "" || this.state.leftEyeData.DV_BCNVA =="" || this.state.leftEyeData.DV_PRISM=="" || this.state.leftEyeData.add =="" ||
         this.state.lens.lens_coating =="" || this.state.lens.lens_type.length == 0){
          this.props.showResposeValue("error", "Please fill Left&Right eye values for all fields");
          this.setState({isTransferToCRMdisabled: false})
          return;
        }
        var prescription_data = {
          "mode": Constants.API_MODE,
          "customerNumber": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER"),
          "branchID": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER") + "-1",
          "cID": response.data.crm_patient_id,
          "prescriptionDate": moment(new Date()).format("DD-MM-YYYY"),
          "prescriptionType": "Eyewear",
          "patientTitle": this.props.patientAppointment.patient_gender == "Male" ? "M" : "F",
          "patientName": this.props.patientAppointment.patient_name,
          "doctorTitle": this.props.patientAppointment.doctor_title,
          "doctorName": this.props.patientAppointment.doctor_name,
          "doctorRegistrationNumber": "",
          "doctorSpecialization": "Optical",
          "rightSPH": this.state.rightEyeData.DV_SPH,
          "rightCYL": this.state.rightEyeData.DV_CYL,
          "rightAxis": this.state.rightEyeData.DV_AXIS,
          "rightPD": "",
          "rightVA": this.state.rightEyeData.DV_BCDVA,
          "rightNear": "",
          "rightADD": this.state.rightEyeData.add,
          "rightPRISM": this.state.rightEyeData.DV_PRISM,
          "leftSPH": this.state.leftEyeData.DV_SPH,
          "leftCYL": this.state.leftEyeData.DV_CYL,
          "leftAxis": this.state.leftEyeData.DV_AXIS,
          "leftPD": "",
          "leftVA": this.state.leftEyeData.DV_BCDVA,
          "leftNear": "",
          "leftADD": this.state.leftEyeData.add,
          "leftPRISM": this.state.leftEyeData.DV_PRISM,
          "lensType": this.lensetypesforCRM(this.state.lens.lens_type),
          "lensCoating": this.state.lens.lens_coating,
          "leftIOP": "",
          "rightIOP": "",
          "dominantEye": "Right,Left",
          "prescriptionNotes": "Wearing Instruction:"+ this.state.lens.wearing_instructions + ". Comment:" + this.state.lens.comment,
          "refractiveCorrection": "",
          "complains": "",
          "symptoms": "",
          "diagnosis": "",
          "prescribedMedication": "",
          "treatment": "",
          "medicalHistory": ""
      }
      var crm_prescription_url = Constants.OPTICAL_CRM_CREATE_PRESCRIPTION;
      OpthamologyService.getInstance().postRecord(
        crm_prescription_url,
        prescription_data,
        this,
        this.CRMTransferPrescriptionSuccess,
        this.CRMTransferPrescriptionFailure
      )
        
      }else{
        this.props.showResposeValue("error",response.message)
        var patient_info_OpticalCRM = {
          "mode": Constants.API_MODE,
          "customerNumber": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER"),
          "branchID": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER") + "-1",
          "customerTitle": "",
          "fullName": this.props.patientAppointment.patient_name,
          "referralCode": "",
          "membershipID": this.props.patientAppointment.patient_account_number,
          "mobileNumber": this.props.patientAppointment.patient_mobile_number,
          "mobileNumber1": "",
          "mobileNumber2": "",
          "mobileNumber3": "",
          "mobileNumber4": "",
          "email": "",
          "taxIdentificationNumber": "",
          "customerCategory": "",
          "gender": this.props.patientAppointment.patient_gender == "Male" ? "M" : "F",
          "insuranceSchemeName": "",
          "insuranceSchemeNumber": "",
          "customerGSTNumber": "",
          "companyName": "",
          "line1": "",
          "line2": "",
          "locality": "",
          "country": "",
          "state": "",
          "city": "",
          "pincode": "",
          "dateOfBirth": this.props.patientAppointment.patient_dob ? moment(this.props.patientAppointment.patient_dob, 'YYYY-MM-DD').format("DD-MM-YYYY") : moment(this.props.patientAppointment.patient_approx_dob, 'YYYY-MM-DD').format("DD-MM-YYYY"),
          "dateOfAnniversary": "",
          "customerAge": parseInt(this.props.patientAppointment.patient_age),
          "notes": ""
      }
        var opticalcrm_url = Constants.OPTICAL_CRM_CREATE_PATIENT;
        OpthamologyService.getInstance().postRecord(
          opticalcrm_url,
          patient_info_OpticalCRM,
          this,
          this.optical_CRM_CreatePatient_Success,
          this.optical_CRM_CreatePatient_Failure
    )
        console.log(response.message)
      }
    }
  }
  getOpticalCRMPatientDetailsFailure = error => {
    if(response.status=="fail"){
      this.props.showResposeValue("error",response.message)
      console.log(response.message);
    }
  }
  optical_CRM_CreatePatient_Success = response =>{
    if(response.status =="Success"){
     var CRMpatientdata = {
        emedhub_patient_id: this.props.patientAppointment.patient_id,
        crm_patient_id:response.data.cID
      }
      var url = Constants.OPTICAL_EMED_CREATE_PATIENT_DETAILS;
      OpthamologyService.getInstance().postComplaints(
        url,
        CRMpatientdata,
        this,
        this.CreateCRMPatientInEmedSuccess,
        this.CreateCRMPatientInEmedFailure
      )
      console.log("Patient Created successfully");
    }
  }
  optical_CRM_CreatePatient_Failure = error =>{
    if(error.error){
      this.props.showResposeValue("error",error.message)
      console.log("Patient Creation failed:", error.error_message);
    }
  }
  CreateCRMPatientInEmedSuccess =async response =>{
    this.props.showResposeValue("success", response.message);
    if(this.state.rightEyeData.DV_SPH =="" || this.state.rightEyeData.DV_CYL=="" || this.state.rightEyeData.DV_AXIS =="" || this.state.rightEyeData.DV_BCDVA == "" || this.state.rightEyeData.DV_BCNVA =="" || this.state.rightEyeData.DV_PRISM=="" || this.state.rightEyeData.add =="" ||
          this.state.leftEyeData.DV_SPH =="" || this.state.leftEyeData.DV_CYL=="" || this.state.leftEyeData.DV_AXIS =="" || this.state.leftEyeData.DV_BCDVA == "" || this.state.leftEyeData.DV_BCNVA =="" || this.state.leftEyeData.DV_PRISM=="" || this.state.leftEyeData.add =="" ||
         this.state.lens.lens_coating =="" || this.state.lens.lens_type.length == 0){
          this.props.showResposeValue("error", "Please fill Left&Right eye values for all fields");
          this.setState({isTransferToCRMdisabled: false})
          return;
        }
    var prescription_data = {
      "mode": Constants.API_MODE,
      "customerNumber": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER"),
      "branchID": await AsyncStorage.getItem("Optical_CRM_CUSTOMER_NUMBER") + "-1",
      "cID": response.data.crm_patient_id,
      "prescriptionDate": moment(new Date()).format("DD-MM-YYYY"),
      "prescriptionType": "Eyewear",
      "patientTitle": this.props.patientAppointment.patient_gender == "Male" ? "M" : "F",
      "patientName": this.props.patientAppointment.patient_name,
      "doctorTitle": this.props.patientAppointment.doctor_title,
      "doctorName": this.props.patientAppointment.doctor_name,
      "doctorRegistrationNumber": "",
      "doctorSpecialization": "Optical",
      "rightSPH": this.state.rightEyeData.DV_SPH,
      "rightCYL": this.state.rightEyeData.DV_CYL,
      "rightAxis": this.state.rightEyeData.DV_AXIS,
      "rightPD": "",
      "rightVA": "",
      "rightNear": "",
      "rightADD": this.state.rightEyeData.add,
      "rightPRISM": this.state.rightEyeData.DV_PRISM,
      "leftSPH": this.state.leftEyeData.DV_SPH,
      "leftCYL": this.state.leftEyeData.DV_CYL,
      "leftAxis": this.state.leftEyeData.DV_AXIS,
      "leftPD": "",
      "leftVA": "",
      "leftNear": "",
      "leftADD": this.state.leftEyeData.add,
      "leftPRISM": this.state.leftEyeData.DV_PRISM,
      "lensType": this.lensetypesforCRM(this.state.lens.lens_type),
      "lensCoating": this.state.lens.lens_coating,
      "leftIOP": "",
      "rightIOP": "",
      "dominantEye": "Right,Left",
      "prescriptionNotes": "Wearing Instruction:"+ this.state.lens.wearing_instructions + ". Comment:" + this.state.lens.comment,
      "refractiveCorrection": "",
      "complains": "",
      "symptoms": "",
      "diagnosis": "",
      "prescribedMedication": "",
      "treatment": "",
      "medicalHistory": ""
  }
  var crm_prescription_url = Constants.OPTICAL_CRM_CREATE_PRESCRIPTION;
  OpthamologyService.getInstance().postRecord(
    crm_prescription_url,
    prescription_data,
    this,
    this.CRMTransferPrescriptionSuccess,
    this.CRMTransferPrescriptionFailure
  )
  }
  CreateCRMPatientInEmedFailure = error =>{
    this.props.showResposeValue("error",error.message)
  }

  CRMTransferPrescriptionSuccess = response =>{
    if(response && response.status == "Success"){
      this.setState({isTransferToCRMdisabled: true})
      this.props.showResposeValue("success", "Prescription Successfully Transfered.")
    }else if(response && response.status =="Fail"){
      this.props.showResposeValue("error", response.error_message);
    }
  }
  CRMTransferPrescriptionFailure = error =>{
    if(error.error){
      this.setState({isTransferToCRMdisabled: false})
      console.log(error.error_message);
    }
  }

  lensetypesforCRM(lenseType){
    if(lenseType.length>0){
      var str ="";
      for(var i=0; i<lenseType.length; i++){
       str  += lenseType[i].value + ", ";
      }
      return str;
    }else{
      this.props.showResposeValue("error", "Please select atleast one lense type");
    }
  }

  render() {

    let { lens, continueWithSameGlass } = this.state;
    var rightEyeType="DV"

    // if (this.state.rightEyeData &&  this.state.rightEyeData.id &&(this.state.rightEyeData.DV_SPH || this.state.rightEyeData.DV_CYL ||
    //   this.state.rightEyeData.DV_AXIS || this.state.rightEyeData.DV_VISION || this.state.rightEyeData.DV_BCDVA ||
    //   this.state.rightEyeData.DV_BCNVA ||this.state.rightEyeData.DV_PRISM ||this.state.rightEyeData.DV_BASE)) {
    //     rightEyeType = "DV"
    // } else if (this.state.rightEyeData &&  this.state.rightEyeData.id && (this.state.rightEyeData.NV_SPH || this.state.rightEyeData.NV_CYL ||
    //   this.state.rightEyeData.NV_AXIS || this.state.rightEyeData.NV_VISION || this.state.rightEyeData.NV_BCDVA || 
    //   this.state.rightEyeData.NV_BCNVA || this.state.rightEyeData.NV_PRISM || this.state.rightEyeData.NV_BASE )) {
    //     rightEyeType = "NV"
    // }

    var leftEyeType="DV"

    // if (this.state.leftEyeData && (this.state.leftEyeData.DV_SPH || this.state.leftEyeData.DV_CYL ||
    //   this.state.leftEyeData.DV_AXIS || this.state.leftEyeData.DV_VISION || this.state.leftEyeData.DV_BCDVA ||
    //   this.state.leftEyeData.DV_BCNVA ||this.state.leftEyeData.DV_PRISM ||this.state.leftEyeData.DV_BASE)) {
    //     leftEyeType = "DV"
    // } else if (this.state.leftEyeData && (this.state.leftEyeData.NV_SPH || this.state.leftEyeData.NV_CYL ||
    //   this.state.leftEyeData.NV_AXIS || this.state.leftEyeData.NV_VISION || this.state.leftEyeData.NV_BCDVA || 
    //   this.state.leftEyeData.NV_BCNVA || this.state.leftEyeData.NV_PRISM || this.state.leftEyeData.NV_BASE )) {
    //     leftEyeType = "NV"
    // }

    return (
      <View>
        <View style={{ flexDirection: "row", justifyContent:'space-between', alignContent: "center", flex: 1 }}>

          {this.renderTitleBorder(this.state.isComplaint, pageTitle, isPageName, this.triggerNewItem)}
          <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center" }}>
            
            <View style={{ alignItems: "center", marginRight: '0.6vw' }}>
                {this.renderCheckBox("Continue with same glass", this.state.continueWithSameGlass, "continueWithSameGlass",this.state.isCopyFromSR?true:false)}
            </View>
            <View style={{ alignItems: "center", marginRight: '0.6vw' }}>
              {this.renderCheckBox("Copy from subjective refraction", this.state.isCopyFromSR, "isCopyFromSR",this.state.continueWithSameGlass?true:null)}
            </View>
            <TouchableOpacity style={{ alignItems: "center", backgroundColor:color.themeDark, borderRadius:4, marginRight: '0.6vw'}} 
            disabled={this.state.isLocked || this.state.continueWithSameGlass?true:false}
               onPress={()=>{
                if(continueWithSameGlass) {
                  if(Object.keys(this.state.rightEyeData).length>0||Object.keys(this.state.leftEyeData).length>0){
                    this.addGlassPrescription()
                  }
                } else {
                  this.firstEyeSave=false;
                  this.secondEyeSave=false;

                  this._saveGlassPrescriptionData('RE')
                }
              } 
              }
              >
              <Image style={styles.SaveImg} source={require('../../../../assets/images/statusIcon/icon/Save.png')}/>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignItems: "center", backgroundColor:color.themeDark, borderRadius:4}} 
              disabled={this.state.continueWithSameGlass?true:false}
              onPress={()=>{ 
                   this._printGlassPrescriptionData()
              } 
              }
              >
              {/* <Image style={{height:35,width:35}} source={require('../../../../assets/images/statusIcon/icon/print.svg')}/> */}
              <View style={styles.PrintIconImg}>
                <Icon name={"print"} size={Platform === "web" ? '1.5vw' : '1.2vw'} color={color.white} />
              </View>
            </TouchableOpacity>

          </View>
        </View>

        <View>
            <View style={[styles.RightEyeView, {backgroundColor:color.sectionHeadingDarkColor}]}>
              <Text style={styles.RightEyeText}>{"Right Eye"}</Text>
              <TouchableOpacity disabled={ this.state.isLocked || this.state.continueWithSameGlass?true:false } onPress={
                //\\\ this.editComplaints.bind(this,item)
                () => { 
                  this.props.triggerNewItem(isPageName)
                  this.props.glassPrescriptionModalClose(true, this.state.selectedResponseData, 0) }
              } style={{ marginLeft: '0.6vw' }}>
                {this.renderIdButton("pencil")}
              </TouchableOpacity>
            </View>
            {
            // this.state.rightEyeData && (this.state.rightEyeData.DV_SPH || this.state.rightEyeData.DV_CYL ||
            //   this.state.rightEyeData.DV_AXIS || this.state.rightEyeData.DV_VISION) 
            rightEyeType == "DV"
              ?
                <View style={{ flexDirection: 'row', marginTop: 0 }}>
                  {/* {this.renderColumn("Type", "1")} */}
                  {this.renderColumn("SPH", this.state.rightEyeData.DV_SPH, 0.125, 'DV_SPH', 'rightEyeData' )}
                  {this.renderColumn("CYL", this.state.rightEyeData.DV_CYL, 0.125, 'DV_CYL', 'rightEyeData' )}
                  {this.renderColumn("AXIS", this.state.rightEyeData.DV_AXIS, 0.125, 'DV_AXIS', 'rightEyeData' )}
                  {/* {this.renderColumn("Vision", this.state.rightEyeData.DV_VISION, 0.125, 'DV_VISION', 'rightEyeData' )} */}
                  {this.renderColumn("ADD", this.state.rightEyeData.add, 0.125, 'add', 'rightEyeData' )}

                  {this.renderColumn("BCDVA", this.state.rightEyeData.DV_BCDVA, 0.125, 'DV_BCDVA', 'rightEyeData' )}
                  {this.renderColumn("BCNVA", this.state.rightEyeData.DV_BCNVA, 0.125, 'DV_BCNVA', 'rightEyeData' )}
                  {this.renderColumn("PRISM", this.state.rightEyeData.DV_PRISM, 0.125, 'DV_PRISM', 'rightEyeData' )}
                  {this.renderColumn("BASE", this.state.rightEyeData.DV_BASE, 0.125, 'DV_BASE', 'rightEyeData' )}

                {/* </View>:
                rightEyeType == "NV"?
                <View style={{ flexDirection: 'row', marginTop: 0 }}>
                  {this.renderColumn("Type", "1")}
                  {this.renderColumn("SPH", this.state.rightEyeData.NV_SPH)}
                  {this.renderColumn("CYL", this.state.rightEyeData.NV_CYL)}
                  {this.renderColumn("AXIS", this.state.rightEyeData.NV_AXIS)}
                  {this.renderColumn("Vision", this.state.rightEyeData.NV_VISION)}

                  {this.renderColumn("BCDVA", this.state.rightEyeData.NV_BCDVA)}
                  {this.renderColumn("BCNVA", this.state.rightEyeData.NV_BCNVA)}
                  {this.renderColumn("PRISM", this.state.rightEyeData.NV_PRISM)}
                  {this.renderColumn("BASE", this.state.rightEyeData.NV_BASE)} */}
                </View>

                :<View />
              }
              <View />
              
              <View>
                <View style={[styles.LeftEyeView, {backgroundColor:color.sectionHeadingDarkColor}]}>
                  <Text style={styles.LeftEyeText}>{"Left Eye"}</Text>
                  <TouchableOpacity disabled={this.state.isLocked || this.state.continueWithSameGlass?true:false} onPress={
                    // this.editComplaints.bind(this,item)
                    () => { 
                      this.props.triggerNewItem(isPageName)
                      this.props.glassPrescriptionModalClose(true, this.state.selectedResponseData,1) }
                  } disabled={this.state.isLocked || this.state.continueWithSameGlass?true:false} style={{ marginLeft: '0.6vw' }}>
                    {this.renderIdButton("pencil")}
                  </TouchableOpacity>
                </View>
                {
            // this.state.leftEyeData && (this.state.leftEyeData.DV_SPH || this.state.leftEyeData.DV_CYL ||
            //   this.state.leftEyeData.DV_AXIS || this.state.leftEyeData.DV_VISION) 
            leftEyeType == "DV"
              ?
                <View style={{ flexDirection: 'row', marginTop: '1vw' }}>
                  {/* {this.renderColumn("UCVA", "1")} */}
                  {this.renderColumn("SPH", this.state.leftEyeData.DV_SPH, 0.125, 'DV_SPH', 'leftEyeData' )}
                  {this.renderColumn("CYL", this.state.leftEyeData.DV_CYL, 0.125, 'DV_CYL', 'leftEyeData' )}
                  {this.renderColumn("AXIS", this.state.leftEyeData.DV_AXIS, 0.125, 'DV_AXIS', 'leftEyeData' )}
                  {/* {this.renderColumn("Vision", this.state.leftEyeData.DV_VISION, 0.125, 'DV_VISION', 'leftEyeData' )} */}
                  {this.renderColumn("ADD", this.state.leftEyeData.add, 0.125, 'add', 'leftEyeData' )}

                  {this.renderColumn("BCDVA", this.state.leftEyeData.DV_BCDVA, 0.125, 'DV_BCDVA', 'leftEyeData' )}
                  {this.renderColumn("BCNVA", this.state.leftEyeData.DV_BCNVA, 0.125, 'DV_BCNVA', 'leftEyeData' )}
                  {this.renderColumn("PRISM", this.state.leftEyeData.DV_PRISM, 0.125, 'DV_PRISM', 'leftEyeData' )}
                  {this.renderColumn("BASE", this.state.leftEyeData.DV_BASE, 0.125, 'DV_BASE', 'leftEyeData' )}
                {/* </View>:
                leftEyeType == "NV" ?
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  {this.renderColumn("UCVA", "1")}
                  {this.renderColumn("SPH", this.state.leftEyeData.NV_SPH)}
                  {this.renderColumn("CYL", this.state.leftEyeData.NV_CYL)}
                  {this.renderColumn("AXIS", this.state.leftEyeData.NV_AXIS)}
                  {this.renderColumn("Vision", this.state.leftEyeData.NV_VISION)}

                  {this.renderColumn("BCDVA", this.state.leftEyeData.NV_BCDVA)}
                  {this.renderColumn("BCNVA", this.state.leftEyeData.NV_BCNVA)}
                  {this.renderColumn("PRISM", this.state.leftEyeData.NV_PRISM)}
                  {this.renderColumn("BASE", this.state.leftEyeData.NV_BASE)} */}
                </View>:null
              // <View />
          }
              </View>
              

 <View>
      <View style={[styles.ChartUsedView, {backgroundColor:color.sectionHeadingDarkColor}]}>
        
        {this.renderSubSectionTitle("Chart Used", 0.33)}
        {/* {this.renderSubSectionTitle("Muscle", 0.33)} => Commented on 19th may for temprory */}
        {this.renderSubSectionTitle("Occupation", 0.34)}

      </View>

      <View style={{ flexDirection: 'row', marginVertical: '0.6vw' }} >

        {this.renderSubSectionSelectData(lens.chart_used, 0.33, 'chart_used', 'lens')}
        {/* {this.renderSubSectionSelectData(lens.muscle, 0.33, 'muscle', 'lens')}=> Commented on 19th may for temprory */}
        {this.renderSubSectionSelectData(lens.occupation, 0.34, 'occupation', 'lens')}

      </View>
      
      {this.renderNewSubHeader("Lens Type")}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              Constants.LENS_TYPE_OPTIONS.map((item, index) => {
                
                var data = lens.lens_type
                
                return (
                  <View style={styles.CommonBtnView} key={index}>
                    <CommonButton
                      disable={this.state.isLocked || this.state.continueWithSameGlass?true:false}
                      item={item}
                      selectedvalue={data}
                      butttonText={item.label}
                      buttonType={"outlineNonTheme"}
                      buttonIcon={""}
                      rightIcon={false}
                      buttonAction={this.updateState.bind(this)}
                      buttonKey={"lens.lens_type"} />
                  </View>
                )
              })
            }
      </View>
      
      <View style={[styles.ChartUsedView, { backgroundColor:color.sectionHeadingDarkColor }]}>
        
        {/* {this.renderSubSectionTitle("Lens Type", 0.33 )} */}
        {this.renderSubSectionTitle("Lens Coating", 0.33 )}
        {/* {this.renderSubSectionTitle("Contact Lens", 0.34)} */}

      </View>
        
      <View style={{ flexDirection: "row", marginVertical: '0.6vw' }} >

        {/* {this.renderSubSectionSelectData(lens.lens_type, 0.33, 'lens_type', 'lens')} */}
        {this.renderSubSectionSelectData(lens.lens_coating, 0.33, 'lens_coating', 'lens')}
        {/* {this.renderSubSectionData(lens.coating, 0.34, 'coating', 'lens')} */}

        {/* <View style={{ flex: 0.22 }}>
          {
          this.state.continue_with_same_lense ? 
          <View style={{ marginTop: 25 }}>
            <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Contact Lens</Text>
            <Text>{this.state.continue_with_same_lense}</Text> 
            
          </View>
          : null
          }
        </View> */}
      </View>
      
      <View>
        
        <View style={[styles.ChartUsedView, { backgroundColor:color.sectionHeadingDarkColor }]}>
          {this.renderSubSectionTitle("Coating Comments", 1)}
        </View>

        <View style={{ marginVertical: '0.6vw' }}>
          {/* <Text style={{ paddingLeft: 10 }}>{lens.coating || "-"}</Text> */}
          {this.renderTextFieldView("Only 50 characters are allowed", "coating", lens.coating, true, 2,"","","",false,(this.state.isLocked || this.state.continueWithSameGlass)?true:false,50)}
        </View>

        <View style={[styles.ChartUsedView, { backgroundColor:color.sectionHeadingDarkColor }]}>
          {this.renderSubSectionTitle("Wearing Instruction", 1)}
        </View>

        <View style={{ marginVertical: '0.6vw' }}>
            <View style={{ flex: 1 }}>
                {/* <Text style={{ paddingLeft: 10 }}>{lens.wearing_instructions || "-" }</Text> */}
                {this.renderTextFieldView("Only 250 characters are allowed", "wearing_instructions", lens.wearing_instructions, false, 2,"","","",true,(this.state.isLocked || this.state.continueWithSameGlass)?true:false,250)}
            </View>
        <View style={{flex:1, flexDirection:"row"}}>
            <View style={{ flex: 1, flexDirection: "row", marginTop: '0.6vw' }}>
              <Text style={styles.SameContactlensText}>{"Continue with same contact lens "}</Text>
              {this.renderSubSectionSelectData(lens.continue_with_same_lense, 0.5, 'continue_with_same_lense', 'lens')} 
            </View>
            <View style={{ flex: 1, flexDirection: "row", marginTop: '0.6vw'}}>
              <TouchableOpacity disabled={this.state.isTransferToCRMdisabled} onPress={this.transferToOpticalHouse} style={{ alignItems: "center", backgroundColor: !this.state.isTransferToCRMdisabled?color.themeDark : color.grayShade, borderRadius:4, marginRight: '0.6vw', width:200}}>
              <Text style={{ fontSize:'1.1vw' , textAlign: "center", color: color.white,fontWeight: "400" }}>Transfer to Optical House</Text>
              </TouchableOpacity>
              
            </View>
            </View>
        </View>
      
      </View>

      {/* {
         this.state.continue_with_same_lense ? 
      <View style={{ marginTop: 35 }}>
        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Contact Lens</Text>
        <Text>{this.state.continue_with_same_lense}</Text> 
        
      </View>

   

      : null
      } */}
    </View>
          


          {/* <View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.22 }}>
                  {
                     this.state.lens && this.state.lens.chart_used ?
                      <View style={{ marginTop: 25 }}>
                        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Chart Used</Text>
                        <Text>{this.state.lens.chart_used}</Text> </View> 
                        : null
                  }
                </View>
                <View style={{ flex: 0.22 }}>
                  {
                    this.state.lens && this.state.lens.muscle ?
                      <View style={{ marginTop: 25 }}>
                        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Muscle</Text>
                        <Text>{this.state.lens.muscle}</Text></View> : null
                  }
                </View>
                <View style={{ flex: 0.25 }}>
                  {
                    this.state.lens && this.state.lens.occupation ?
                      <View style={{ marginTop: 25 }}>
                        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Occupation</Text>
                        <Text>{this.state.lens.occupation}</Text></View> : null
                  }
                </View>
                <View style={{ flex: 0.31 }}>
                  {
                    this.state.lens && this.state.lens.lens_type ?
                      <View style={{ marginTop: 25 }}>
                        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Preferred Lens Type</Text>
                        <Text>{this.state.lens.lens_type}</Text></View> : null
                  }
                </View>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 0.22 }}>

                  {
                    this.state.lens && this.state.lens.lens_coating ?
                      <View style={{ flexDirection: 'row', marginTop: 25 }}>
                        <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Coating</Text>
                        <Text>{this.state.lens.lens_coating}</Text> </View> : null
                  }
                </View>
                {
                  this.state.lens && this.state.lens.coating ?
                    <View style={{ flex: 0.31, justifyContent: 'flex-end', marginTop: 25 }}>
                      <Text>{this.state.lens.coating}</Text>
                    </View> : null
                }
              </View>

              <View style={{ flexDirection: 'row', }}>
                {
                  this.state.lens && this.state.lens.wearing_instructions ?
                    <View style={{ flex: 0.22  ,marginTop: 25}}>
                      <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Wearing Instruction</Text>
                      <Text>{this.state.lens.wearing_instructions}</Text>
                    </View> : null
                }
                {
                  this.state.lens && this.state.lens.comment ?
                    <View style={{ flex: 0.31, justifyContent: 'flex-end',marginTop: 25 }}>
                      <Text>{this.state.lens.comment}</Text>

                    </View> : null
                }
              </View>

              {
                  this.state.lens && this.state.lens.continue_with_same_lense ? 
              <View style={{ marginTop: 35 }}>
                <Text style={{ fontSize: 13, color: color.notesTableTitle, marginBottom: 10 }}>Contact Lens</Text>
                <Text>{this.state.lens.continue_with_same_lense}</Text> 
                
              </View>: null
              }
            </View> */}




        </View>

      </View>
    );
  }

  renderCheckBox(label, value, key,disable) {

    let { lens } = this.state;

    return (
        <View style={{ flexDirection: 'row', alignSelf:'flex-end', marginTop: '0.6vw' }}>
            <CheckBox value={value}
                disabled={disable}
                style={styles.CheckboxView}
                onValueChange={() => {

                var states =this.state;

                states[key]=!value
                if(key==="continueWithSameGlass") {
                  
                  lens['same_glass'] = !value;

                  if(!value){
                    lens.chart_used = "NIL";
                    lens.muscle = "NIL";
                    lens.occupation = "NIL";
                    lens.continue_with_same_lense = "NIL";
                    lens.lens_type = [];
                    lens.lens_coating = [];

                    lens.wearing_instructions = "";
                    lens.coating = "";
                  }
                  {this.addGlassPrescription()}

                }

                this.setState({
                  states, lens,
                  // continueWithSame:true
                }, ()=>{

                  if(key==="isCopyFromSR" && !value) {
                    // get
                    this.getSubjectiveRefractionData()
                  }
                  if(key==="continueWithSameGlass"){
                    this.props.updateContinueWithSameGlassFlag(this.state.continueWithSameGlass)
                    
                  }
                })
              }}

            />
            <Text style={styles.CheckBoxText}>{label}</Text>
        </View>
    );
  }

  renderSubSectionTitle(title, fw) {
    return(
      <View style={{ flex: fw, paddingVertical: '0.6vw' }}>
        <Text style={styles.SubSectionTitleText}>{title}</Text>
      </View>
    )
  }

  renderSubSectionData(value, fw, key, subkey='') {
    
    let states = this.state;
    
    return(
      <View style={{ flex: fw }}>
          {/* <Text style={{ marginLeft: 10 }}>{data || "-"}</Text> */}
          <TextInput 
          editable={this.state.continueWithSameGlass?false:true}
          onChangeText={(text)=>{

            if(subkey)
            {
              states[subkey][key] = text
            }
            else
            { 
              states[key] = text
            }

            this.setState({ states })

          }}
          value={value} 
          style={styles.SubSectionDataTextInput} 
        />
      </View>
    )
  }

  renderSubSectionSelectData(value, fw, key, subkey='') {

    let states = this.state;
    let { lens, isLocked } = this.state;

    return(
      <View style={{ flex: fw }}>
        <View style={{ marginHorizontal: '0.6vw' }}>
        {key==="chart_used"?
          <Picker
          // enabled={!this.state.isLocked}
          enabled={isLocked?false:lens.same_glass?false:true}
          selectedValue={value}
          style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
          itemStyle={[Style.pickerFont]}
          onValueChange={(itemvalue) => {
            if(subkey)
            {
              states[subkey][key] = itemvalue
            }
            else
            { 
              states[key] = itemvalue
            }

            this.setState({ states })
          }}
        >
          <Picker.Item label="Select" value="NIL" />
          {/* <Picker.Item label="SNELLEN" value="SNELLEN" />
          <Picker.Item label="TUMBLING E" value="TUMBLING E" />
          <Picker.Item label="LANDOLT C" value="LANDOLT C" />
          <Picker.Item label="ETDRS" value="ETDRS" /> */}
          {Constants.DEFAULT_CHARTUSED_SR.map((i, index) => (
              <Picker.Item key={index} label={i.value} value={i.value} />
            ))}
        </Picker>
        :key==="muscle"?
          <Picker
          // enabled={!this.state.isLocked}
          enabled={isLocked?false:lens.same_glass?false:true}
          selectedValue={value}
          style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
          itemStyle={Style.pickerFont}
          onValueChange={(itemvalue) => {
            if(subkey)
            {
              states[subkey][key] = itemvalue
            }
            else
            { 
              states[key] = itemvalue
            }

            this.setState({ states })
          }}
        >
          <Picker.Item label="Select" value="NIL" />
          <Picker.Item label="Ciliary" value="Ciliary" />
          <Picker.Item label="Ocular" value="Ocular" />
          <Picker.Item label="Extra-Ocular" value="Extra-Ocular" />
          {/* <Picker.Item label="NIL" value="NIL" /> */}
        </Picker>
        :key==="occupation"?
          <Picker
          // enabled={!this.state.isLocked}
          enabled={isLocked?false:lens.same_glass?false:true}
          selectedValue={value}
          style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
          itemStyle={Style.pickerFont}
          onValueChange={(itemvalue) => {
            if(subkey)
            {
              states[subkey][key] = itemvalue
            }
            else
            { 
              states[key] = itemvalue
            }

            this.setState({ states })
          }}
        >
          <Picker.Item label="Select" value="NIL" />
          <Picker.Item label="Professional" value="Professional" />
          <Picker.Item label="Goldsmith" value="Goldsmith" />
          <Picker.Item label="Farmer" value="Farmer" />
          <Picker.Item label="Self-employed" value="Self-employed" />
          <Picker.Item label="Businessman" value="Businessman" />
          <Picker.Item label="Tailor" value="Tailor" />
          <Picker.Item label="Student" value="Student" />
          <Picker.Item label="Others" value="Others" />
        </Picker>
        :key==="lens_type"?
        <Picker
            // enabled={!this.state.isLocked}
            enabled={isLocked?false:lens.same_glass?false:true}
            selectedValue={value}
            style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
            itemStyle={Style.pickerFont}
            onValueChange={(itemvalue) => {
              if(subkey)
              {
                states[subkey][key] = itemvalue
              }
              else
              { 
                states[key] = itemvalue
              }

              this.setState({ states })
            }}
          >
            <Picker.Item label="Select" value="" />
            {Constants.LENS_TYPE_OPTIONS.map((item, index)=>{
              return <Picker.Item key={index} label={item.label} value={item.label} />
            })}
          </Picker>
        :key==="lens_coating"?
          <Picker
            // enabled={!this.state.isLocked} 
            enabled={isLocked?false:lens.same_glass?false:true}
            selectedValue={value}
            style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
            itemStyle={Style.pickerFont}
            onValueChange={(itemvalue) => {
              if(subkey)
              {
                states[subkey][key] = itemvalue
              }
              else
              { 
                states[key] = itemvalue
              }

              this.setState({ states })
            }}
          >
          <Picker.Item label="Select" value="" />
          {Constants.LENS_COATING_OPTIONS.map((item, index)=>{
            return <Picker.Item key={index} label={item.label} value={item.value} />
          })}
          
        </Picker>
        :key==="continue_with_same_lense"?
        <Picker
            // enabled={!this.state.isLocked}
            enabled={isLocked?false:lens.same_glass?false:true}
            selectedValue={value}
            style={[Style.pickerRoundCornerView,Style.allButtonBorderRadius]}
            itemStyle={Style.pickerFont}
            onValueChange={(itemvalue) => {
              if(subkey)
              {
                states[subkey][key] = itemvalue
              }
              else
              { 
                states[key] = itemvalue
              }

              this.setState({ states })
            }}
          >
          <Picker.Item label="Select" value="" />
          <Picker.Item label="Disposable Soft" value="Disposable Soft" />
          <Picker.Item label="Daily" value="Daily" />
          <Picker.Item label="Extended Wear" value="Extended Wear" />
          <Picker.Item label="Spherical" value="Spherical" />
          <Picker.Item label="Toric" value="Toric" />
          <Picker.Item label="Monovision" value="Monovision" />
          <Picker.Item label="Multifocal / Bifocal" value="Multifocal / Bifocal" />
          <Picker.Item label="Conventional" value="Conventional" />
          <Picker.Item label="Rigid Gas Permeable" value="Rigid Gas Permeable" />
          <Picker.Item label="Hard" value="Hard" />
          
        </Picker>
        :null}
        </View>
      </View>
    )
  }

  getSubjectiveRefractionData() {

    var service_url = Constants.OPTHALMOLOGY_SUBJECTIVEREFRACTION + "?appointment_id=" + this.state.patientAppointment.appointment_id;

    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getItemsSuccess,
      this.getItemsFailure
    );
  };

  getItemsSuccess = response => {

    // console.log(" Sub.Ref" + JSON.stringify(response))

    if (response.status === "success") {
      
      // var field = this.state;
      // field["itemList"] = response.data?response.data.subrefraction:[];
      
      let { rightEyeData, leftEyeData, lens, selectedResponseData } = this.state;
      let data = []

      data = response.data && Object.keys(response.data).length>0?response.data.subrefraction:[]; //response.data.subrefraction;
      
      // console.log(" Sub.Ref" + JSON.stringify(data))

      // for (var i = 0; i < data.length; i++) {
        
      //   let temp ={
          
      //     "DV_SPH": data[i].SPH,
      //     "DV_CYL": data[i].CYL,
      //     "DV_AXIS": data[i].AXIS,
      //     "DV_VISION": "",
      //   }

      //   if (data[i].eye_type == "LE") {

      //     this.setState({
      //       leftEyeData: temp,
      //     })
      //   } else if (data[i].eye_type == "RE") {
      //     this.setState({
      //       rightEyeData: temp,
      //     })
      //   }
      // }

      // map data
      let lensData = {
        // "id": "",
        "chart_used": "",
        "muscle": "",
        "occupation": "",
        "lens_type": "",
        "lens_coating": "",
        "coating": "",
        "wearing_instructions": "",
        "comment": "",
        "continue_with_same_lense": ""
      };

      if(this.state.lens && this.state.lens.id){
        lensData["id"] = this.state.lens.id
      }

      for (var i = 0; i < data.length; i++) {
        
        let temp ={
          "appointment_id": this.props.patientAppointment.appointment_id,
          "eye_type": "LE",
          "DV_SPH": data[i].SPH,
          "DV_CYL": data[i].CYL,
          "DV_AXIS": data[i].AXIS,
          "DV_VISION": "",
          "DV_BCDVA": data[i].BCDVA,
          "DV_BCNVA": data[i].BCNVA,
          "add": data[i].add
        }

        if(data[i].chart_used) {
          lensData['chart_used'] = data[i].chart_used;
        }
        if(data[i].muscle) {
          lensData['muscle'] = data[i].muscle;
        }
        if(data[i].occupation) {
          lensData['occupation'] = data[i].occupation;
        }

        if (data[i].eye_type == "LE") {

          temp['eye_type'] = "LE";
          
          // get ID before update
          let id = leftEyeData['id']

          // update 
          temp['id'] = id;

          // set 
          leftEyeData = temp;

        } else if (data[i].eye_type == "RE") {
          
          temp['eye_type'] = "RE";

          // get ID before update
          let id = rightEyeData['id']

          // update 
          temp['id'] = id;

          // set 
          rightEyeData = temp;
        }
      }

      lens = lensData;
      selectedResponseData = [ rightEyeData, leftEyeData ];
      // map data end

      this.setState({ 
        rightEyeData: rightEyeData,
        leftEyeData: leftEyeData,
        lens: lens,
        selectedResponseData: selectedResponseData
       })
    }
  };

  getItemsFailure = error => {
  //  console.log("opthamology complaints GET error response");
    console.log(error);
  };

  _saveGlassPrescriptionData(type = "RE") {
    
    // var type = ""
    var data = {}
    let { patientAppointment, rightEyeData, leftEyeData, continueWithSameGlass } = this.state;

    if (type == "RE" || type == "rightToLeft") {

        data = {
          "add":rightEyeData.add,
          "appointment_id": patientAppointment.appointment_id,
          "eye_type": "RE",
          "DV_SPH": rightEyeData.DV_SPH,
          "DV_CYL": rightEyeData.DV_CYL,
          "DV_AXIS": rightEyeData.DV_AXIS,
          "DV_VISION": "",

          "DV_BCDVA": rightEyeData.DV_BCDVA,
          "DV_BCNVA": rightEyeData.DV_BCNVA,
          "DV_PRISM": rightEyeData.DV_PRISM,
          "DV_BASE": rightEyeData.DV_BASE,

          "add": rightEyeData.add,
          "DV_UCVA": ""
        }

        if (rightEyeData.id) {
          data["id"] = rightEyeData.id
        }

        this._glassPrescriptionPostApi(data, false)

    } else if (type == "LE" || type == "leftToRight") {
      
        data = {
          "add":leftEyeData.add,
          "appointment_id": patientAppointment.appointment_id,
          "eye_type": "LE",
          "DV_SPH": leftEyeData.DV_SPH,
          "DV_CYL": leftEyeData.DV_CYL,
          "DV_AXIS": leftEyeData.DV_AXIS,
          "DV_VISION": "",

          "DV_BCDVA": leftEyeData.DV_BCDVA,
          "DV_BCNVA": leftEyeData.DV_BCNVA,
          "DV_PRISM": leftEyeData.DV_PRISM,
          "DV_BASE": leftEyeData.DV_BASE,

          "add": leftEyeData.add,
          "DV_UCVA": ""
        }
      

      if (leftEyeData.id) {
        data["id"] = leftEyeData.id
      } 

      this._glassPrescriptionPostApi(data, false)
    }

    // if(data && data !=={}) {
    //   this._glassPrescriptionPostApi(data, false)
    // }
  }

  _glassPrescriptionPostApi(data, flag) {

    // console.log("_glassPrescriptionPostApi --" + JSON.stringify(data))
    if(Object.keys(this.state.rightEyeData).length>0&&Object.keys(this.state.leftEyeData).length>0){
    var service_url = Constants.GLASS_PRESCRIPTION;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getSaveSuccess,
      this.getSaveFailure
    );
    }else{
      this.props.showResposeValue("error","Please Enter the values")
    }
  }

  getSaveSuccess = response => {
    // if (response.status === "success") {
    //   var field = this.state;
    //   field["itemList"] = response.data.previous_glass_power;
    //   this.setState({ field });
    // }
    //console.log("response -------------> " + JSON.stringify(response));


    if (response.status === "success") {

        if(!this.firstEyeSave)
        {
          this.firstEyeSave = true; 

          this._saveGlassPrescriptionData("LE")
        }
        else if(!this.secondEyeSave)
        {
          this.secondEyeSave = true;

          // if (this.state.copyFrom !== "") {
            
            // this.glassPrescriptionPostApi(data, true)
            this._saveGlassPrescriptionData("LE")

            this.addGlassPrescription()

          // } else {
            // this.props.refreshData(pageName);
            // this.glassPrescriptionModalClose(false, "");
            // this.props.showResposeValue("success", response.message)

          // }
        }

    } else {
      // this.props.showResposeValue("error", response.message)
    }
  };

  getSaveFailure = error => {
   // console.log("opthamology GP save error response");
    console.log("getSaveFailure " + error);
    this.props.showResposeValue("error", error.message) 

    this.firstEyeSave = false; 
    this.secondEyeSave = false;
  };

  addGlassPrescription () {
  
    let { patientAppointment, lens,  } = this.state;
    var data = {}

    data = {
      // "id": this.state.id,
      "appointment_id": patientAppointment.appointment_id,
      "chart_used": lens.chart_used?lens.chart_used:"NIL",
      "muscle": lens.muscle?lens.muscle:"NIL",
      "occupation": lens.occupation?lens.occupation:"NIL",
      "wearing_instructions": lens.wearing_instructions,
      "continue_with_same_lense": lens.continue_with_same_lense?lens.continue_with_same_lense:"NIL",
      "lens_type": lens.lens_type?lens.lens_type:[],
      "lens_coating": lens.lens_coating?lens.lens_coating:"NIL",
      "coating": lens.coating,
      "same_glass": lens.same_glass?lens.same_glass:false,
      "comment": ""
    }
    
    if(lens.id) {

      data["id"] = lens.id
    }  
    // console.log(" lens data " + JSON.stringify(lens));
    // console.log(" post glass data " + JSON.stringify(data));
     var service_url = Constants.GLASS_PRESCRIPTION + "lens/";
     OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.getGlassPrescriptionSuccess,
      this.getGlassPrescriptionFailure
     );  
  };

  getGlassPrescriptionSuccess = response => {
    // console.log("Glass Prescription Created Successfully----------->"+ response)
  
     if (response.status === "success") {
       
       // reload data
       this.props.refreshData(pageName);
       this.props.showResposeValue("success",response.message)
     }else{
       this.props.showResposeValue("error",response.message)
     }
   };
 
   getGlassPrescriptionFailure = error => {
     
     // console.log("opthamology complaints GET error response");
      console.log(error);
      this.props.showResposeValue("error",error.message)
 
   };

   renderTextFieldView = (placeholder, stateName = '', value = '', isMultipleLineEnabeled = false, lineCount = 1,
    reference = '', updateObj = {}, autofocus = "", showLabel = false, disable = false,maxLength=300) => {
    return (
      <View>
        <View
          style={[{
            minHeight: '2.3vw',
            // marginBottom: 20,
            backgroundColor: disable ? color.disableComponentColor : color.white,
            zIndex: -3
          }, Style.allButtonBorderRadius]}
        >
          <TextInput
          maxLength={maxLength}
            editable={!disable}
            underlineColorAndroid="transparent"
            placeholder={!showLabel ? placeholder : ""}
            style={[styles.TextFieldViewTextInput, {borderColor: color.rightPanelInputBorder
            }, Style.allButtonBorderRadius]}
            autoFocus={autofocus}
            autoCapitalize="none"
            value={value}
            // onChange={stateName ? this.updateStatebyElement.bind(this, stateName) : null} 
            onChangeText={(text)=>{

              let states = this.state;

              if(stateName==="wearing_instructions" || stateName==="coating") {

                if(text.length<300){
                  
                  states["lens"][stateName] = text
                }
              }else{
                states["lens"][stateName] = text
              }
  
              this.setState({ states })
  
            }}
            multiline={isMultipleLineEnabeled}
            numberOfLines={lineCount}
            
          />
        </View>
      </View>
    );
  };

  _printGlassPrescriptionData () {

    let states = this.state;

    var serviceUrl = Constants.GLASS_PRESCRIPTION_PRINT + "?appointment_id=" + this.state.patientAppointment.appointment_id

    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this._getPrintSuccess,
      this._getPrintFailure,
      "pdf"
    );
  };

  _getPrintSuccess = response => {
    if (response) {
      
      const file = new Blob([response.data], { type: 'application/pdf' });

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      
      //Open the URL on new Window
      window.open(fileURL);
    }
  };

  _getPrintFailure = error => {
    console.log("Get clinic list error response");
    console.log(error);
  };

} 

const styles = StyleSheet.create({
      CheckboxView: {
        marginRight: '0.8vw',
        height: '1.2vw',
        width: '1.2vw'
      },
      CheckBoxText: { 
        fontSize: '0.8vw', 
        color: "#888888" 
      },
      SaveImg: {
        height:'2.3vw',
        width:'2.3vw'
      },
      PrintIconImg: { 
        height:'2.3vw', 
        width:'2.3vw', 
        alignItems: "center", 
        padding: '0.3vw' 
      },
      RightEyeView: { 
        flexDirection: 'row', 
        marginTop: '0.6vw', 
        alignItems: 'center', 
        height:'2.3vw', 
      },
      RightEyeText: { 
        fontSize: '1vw', 
        marginLeft: '0.6vw' 
      },
      ColumnTextInput: { 
        maxWidth:'3.8vw', 
        height:'2.6vw', 
        justifyContent:"center", 
        textAlignVertical:'center',
        fontSize: '0.9vw' 
      },
      LeftEyeView: { 
        flexDirection: 'row', 
        marginTop: '1vw', 
        alignItems: 'center', 
        height:'2.3vw', 
      },
      LeftEyeText: { 
        fontSize: '1vw', 
        marginLeft: '0.6vw' 
      },
      SubSectionTitleText: { 
        fontSize: '0.8vw', 
        marginLeft: '0.6vw' 
      },
      ChartUsedView: { 
        flexDirection: 'row', 
        justifyContent: "flex-start", 
        alignContent: "center", 
        height:'2.3vw', 
      },
      TextFieldViewTextInput: {
        paddingHorizontal: '0.6vw',
        color: "#525252",
        minHeight: '2.3vw',
        padding: '0.5vw',
        marginRight: 0,
        flex: 1,
        borderWidth: 0.5,
        fontSize:'0.9vw'
      },
      SameContactlensText: { 
        paddingLeft: '0.6vw',
        fontSize: '0.9vw' 
      },
      SubSectionDataTextInput: { 
        maxWidth:'3.8vw', 
        height:'2.6vw', 
        borderBottomColor:"#E0E0E0", 
        borderBottomWidth:1, 
        justifyContent:"center", 
        textAlignVertical:'center',
        fontSize: '0.9vw' 
      },
      CommonBtnView: { 
        marginBottom: '0.6vw', 
        marginRight: '0.6vw' 
      }
})