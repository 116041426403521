import React, { Component } from 'react';
import { View, Text, FlatList, TouchableOpacity, Picker,TextInput, StyleSheet } from 'react-native';
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import {  } from 'react-native-paper';
import Style from "../../../styles/Style"
import OpthamologyService from "../../../network/OpthamologyService";
// import { colors } from 'react-native-elements';
import BaseComponentStyle from "../BaseComponentStyle";
import {CommonHistorySectionHeader ,PSYHistorySectionCommonComponent} from '../BaseComponent';

const pageName = "vitals";
var Messages=require('../../../utils/InfoMessages')

export default class VitalsNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    let userType = '';
    let isNoAppointment;

    try {
      userType = this.props.userType;
    } catch (error) { }

    this.state = {

      // isIpPatientCheckouted = {this.state.isIpPatientCheckouted}

      isLocked:this.props.patientAppointment.is_locked,
      selectedVitals: [],
      height: "",
      weight: "",
      systolicbloodpressure: "",
      diatolicbloodpressure: "",
      temperture: "",
      tempertureType: "f",
      pulseOximetry: "",
      pulserate: "",
      RandomBooldsugar: "",
      array: [],
      patientAppointment: this.props.patientAppointment,
      bmi: "",
      successOrErrorMessage:false,
      successMessageFlag:false,
      errorMessageFlag:false,
      vitals:"",
      head_circumference:"",
      changevitalsflag:false,
      userType: userType,
      isCurrentPageIsIP:this.props.isCurrentPageIsIP,
      selectedIpPatientDetails:this.props.selectedIpPatientDetails,
      isNoAppointment:this.props.isNoAppointment,      

      detailsOfYesValue:"",
      isSelected:false,

      selectedDropDownlist:""
    };
  }

  componentDidMount(){
    console.log("**************  "+this.props.isIpPatientCheckouted)
    var states = this.state;
    if(this.props.isIpPatientCheckouted){
      states["isLocked"] = this.props.isIpPatientCheckouted
    }else{
      states["isLocked"] = false
    }
    this.setState({states})
  }


  renderHistoryComponent() {
    return (
      <PSYHistorySectionCommonComponent
        onChangeYesOrNoValue={this.onChangeYesOrNoValue.bind(this)}   // only for text box and yes or no button 

        cardLabel = {"sample label for"}

        // text box -------------------
        yesTextBoxPlaceholder={"details"}
        yesTextBoxValue={this.state.detailsOfYesValue}
        yesTextBoxKey={"detailsOfYesValue"}
        
        // yes or no button -------------------
        yesOrNoButtonKey={"isSelected"}     // if we not this key ... we dont show the  yes or no buttons 
        isSelected={this.state.isSelected}


        // drop down list 
        dropDownList = {
          ["mother","father","son","mom"]
        }
        selectedDropDownlist={this.state.selectedDropDownlist}
        dropDownListKey={"selectedDropDownlist"}
        onChangeDropDownVallue={this.onChangeDropDownVallue.bind(this)}

        // label with button select 


      />
    )
  }

  onChangeDropDownVallue(selectedDropDownValue,dropDownKey){
    var states = this.state;
    states[dropDownKey]=selectedDropDownValue;
    this.setState({ states })

  }

onChangeYesOrNoValue(flag,flagKey,detailsValue,detailsValueKey){

  var states =  this.state;
  if(flagKey){  states[flagKey] = flag; }
  if(detailsValueKey){  states[detailsValueKey] = detailsValue; }

  this.setState({ states })
}


 
  componentWillReceiveProps(props){
//  this.setState({

//    changevitalsflag:
//  })
   if(props.editedvitals  && ( props.vitalsflag && !this.state.changevitalsflag)){
      var states =this.state
      states["height"]=props.editedvitals.height_cm
      states["weight"]=props.editedvitals.weight_kg
      states["bmi"]=props.editedvitals.bmi
      states["systolicbloodpressure"]=props.editedvitals.blood_pressure_systolic
      states["diatolicbloodpressure"]=props.editedvitals.blood_pressure_diastolic
      states["temperture"]=props.editedvitals.temperature
      states["tempertureType"]=props.editedvitals.temperature_unit
      states["head_circumference"]=props.editedvitals.head_circumference
      states["pulseOximetry"]=props.editedvitals.pulse_oximetry
      states["pulserate"]=props.editedvitals.pulse_rate
      states["RandomBooldsugar"]=props.editedvitals.blood_sugar
  
      this.setState({
        states,
        changevitalsflag:true
      })
      
      // clear props

   }

   if(this.props.isNoAppointment != this.state.isNoAppointment){
    this.setState({
      isNoAppointment: true
    })
   }

  }

  renderSubHeadingBorder = (title, line = true) => {
    return (
      <View
        style={styles.SubHeaderView}
      >
        <Text style={[styles.SubHeaderText, { color: color.textcolor }]}>{title}</Text>
        {(line) ?
          <View
            style={[styles.SubHeaderEmptyView, {backgroundColor: color.white}]}
          />
          : null}
      </View>
    );
  };


  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }



  addVital() {

    let errorMessage = "";

    // (this.state.temperture+"height"+this.state.height+"weight"+this.state.weight+"systolicbloodpressure"+this.state.systolicbloodpressure,this.state.diatolicbloodpressure
    // ,this.state. pulseOximetry,this.state.pulserate)
    let states = this.state;
    let { userType } = this.state;    
    
    // var service_url = Constants.VITALS_DETAILS
    let service_url = '';

    if(userType === Constants.ROLE_FO) {

      service_url = Constants.FO_PATIENT_VITALS_DETAILS;
    } else {

      service_url = Constants.DOCTOR_PATIENT_VITALS_DETAILS;
    }

    if(this.state.height && this.state.weight) {

      var height = this.state.height / 100;
      var bmi = this.state.weight / (height * height);
      states["bmi"] = bmi.toFixed(2);
    }

    // let dataVitals = [
    //   { "appointment_id": this.state.patientAppointment.appointment_id },
    //   { "key": "Height", "value": this.state.height },
    //   { "key": "Weight", "value": this.state.weight },
    //   { "key": "BMI", "value": this.state.bmi },
    //   { "key": "Systolicbloodpressure", "value": this.state.systolicbloodpressure, },
    //   { "key": "Diatolicbloodpressure", "value": this.state.diatolicbloodpressure },
    //   { "key": "Temperture", "value": this.state.temperture },
    //   { "key": "PulseOximetry", "value": this.state.pulseOximetry },
    //   { "key": "Pulserate", "value": this.state.pulserate },
    //   { "key": "RandomBooldsugar", "value": this.state.RandomBooldsugar },
    // ]
    // this.setState({
    //   height: "", weight: "", systolicbloodpressure: "", diatolicbloodpressure: "", temperture: "",
    //   pulseOximetry: "", pulserate: "", RandomBooldsugar: "",
    // })

    // if (states.id) {
    //   dataVitals["id"] = states.id;
    // }

    // this.props.vitalsData(dataVitals);

    var data = {
      "appointment_id": this.state.patientAppointment.appointment_id ?  this.state.patientAppointment.appointment_id :this.state.selectedIpPatientDetails.appointment_id? this.state.selectedIpPatientDetails.appointment_id:null,
      "height_cm": this.state.height ? +this.state.height : null,
      "weight_kg": this.state.weight ? +this.state.weight : null,
      "bmi": this.state.bmi ? +this.state.bmi : null,
      "blood_pressure_systolic": this.state.systolicbloodpressure ? +this.state.systolicbloodpressure : null,
      "blood_pressure_diastolic": this.state.diatolicbloodpressure ? +this.state.diatolicbloodpressure : null,
      "temperature": this.state.temperture ? +this.state.temperture : null,
      "temperature_unit": this.state.tempertureType ? this.state.tempertureType :"f",
      "pulse_oximetry": this.state.pulseOximetry ? +this.state.pulseOximetry : null,
      "blood_sugar": this.state.RandomBooldsugar ? +this.state.RandomBooldsugar : null,
      "pulse_rate": this.state.pulserate ? +this.state.pulserate : null,
    }

    if(this.state.userType == "PD"){
      data["head_circumference"] = this.state.head_circumference ? this.state.head_circumference : null
    }

    if (states.id) {
        data["id"] = states.id;
    }

    // if (this.state.height || this.state.weight ||this.state.systolicbloodpressure 
    //   || this.state.diatolicbloodpressure || this.state.temperture || this.state.tempertureType
    //   || this.state.pulseOximetry || this.state.RandomBooldsugar || this.state.pulserat) {
    

    //     if(!this.state.height && !this.state.weight) {    
    //       errorMessage = errorMessage?errorMessage+",Please enter height and weight":"Please enter height and weight";
    //     }else
    //   if(!this.state.height) {    
    //     errorMessage = errorMessage?errorMessage+",Please enter height":"Please enter height";
    //   }else
    //   if(!this.state.weight) {    
    //     errorMessage = errorMessage?errorMessage+",Please enter weight":"Please enter weight";
    //   }

     
      
    //   if(errorMessage) {
    //     this.props.showResposeValue("error", errorMessage);
    //   } else {
        OpthamologyService.getInstance().postComplaints(
          service_url,
          data,
          this,
          this.getComplaintsSuccess,
          this.getComplaintsFailure
        );
      // }
  // }else{
  //   this.props.showResposeValue("error",Messages.VitalsEmptyField)
  // }
  }
  showSuccessMessage = () => {
    this.setState({ successOrErrorMessage: true,successMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , successMessageFlag: false });
    }, 2000);
  }; 

  showErrorMessage = () => {
    this.setState({ successOrErrorMessage: true,errorMessageFlag:true });
    setTimeout(() => {
      this.setState({ successOrErrorMessage: false , errorMessageFlag: false });
    }, 2000);
    
  };
  



  getComplaintsSuccess = response => {

    // console.log(JSON.stringify(response))
    if (response.status === "success") {
      var field = this.state;
      // field["complaintsData"] = response.data;
      // this.setState({ field });
      // this.showSuccessMessage();
      // clear data
      // this.clearFields
      
      // this.setState({
      //     height: "", weight: "", systolicbloodpressure: "", diatolicbloodpressure: "", temperture: "",
      //     pulseOximetry: "", pulserate: "", RandomBooldsugar: "", changevitalsflag: false
      //   })

      // reload data
      this.props.refreshData(pageName);
     
      //this.chegeAppoinmentModalTime()
      this.props.showResposeValue("success",response.message)
    }else{
      this.props.showResposeValue("error",response.message)
    }
  };

  getComplaintsFailure = error => {
    // alert("complaint  failed")
    // this.showErrorMessage();
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error",error.message)
  };



  renderTextField = (title, key, value) => {
    return(
      <View
      style={{
        minHeight: 35,
        alignItems: "center",
        flexDirection: "row",
        marginLeft:5,
        width: "45%",
        marginRight: 30,
        marginTop:20,
        
      }}
    >
      <TextInput
        editable={!this.state.isLocked}
        theme={{ colors: { primary: '#45B7B1',underlineColor:'transparent',}}}

       mode='outlined'
        placeholder={title}
       placeholderTextColor='#000'
        // mode='outlined'
        label={title}
        keyboardType='numeric'
        autoCapitalize="none"
        value={value}
        onChangeText={text => {
          this.updateState(text, title, value, key);
        }}
        theme={{ colors: { primary: '#45B7B1',underlineColor:'transparent',}}}
        style={{
       
        width: key == "temperture" ? "82%" : "95%", 
        height: 37, 
        fontSize: key == "RandomBooldsugar" ? 12 : 12, 
        backgroundColor:this.state.isLocked ? color.disableComponentColor : color.white ,
        borderWidth: 0.5,
        borderRadius: 10,
        borderTopRightRadius: key == "temperture" ?  "0" :null,
        borderBottomRightRadius: key == "temperture" ?  "0" :null,
        borderColor: 'transparent',
       textAlign:"center"
       }}
      />
      {key == "temperture" ? <Picker
        enabled={!this.state.isLocked}
        style={{ height: 37, backgroundColor: color.white,
          marginTop:6,
           borderTopRightRadius:3, 
          borderBottomRightRadius:3 , 
           borderColor: '#212121',}}
        selectedValue={this.state.tempertureType}
        onValueChange={(itemValue) =>
          this.setState({ tempertureType: itemValue})}
      >
        
        <Picker.Item label="F" value="f" />
        <Picker.Item label="C" value="c" />
        {/* <Picker.Item label="%"  /> */}
      </Picker> : null}
    </View>
    );
  }
  

  tempertureType
  // renderTextField = (title, key, value) => {
  //   return (
  //     <View
  //       style={{
  //         minHeight: 35,
  //         // alignItems: "center",
  //         flexDirection: "row",
  //         marginLeft:5,
          
  //         width: "45%",
  //         marginRight: 30,
  //         marginTop: key == "pulserate" ? 32 :  20,
  //       }}
  //     >
  //       {/* color.themeShade  */}
  //        <Text style={{fontSize:12, position: "absolute",marginLeft:10,backgroundColor:"transparent" ,paddingLeft:5,paddingRight:5,color:"#888888"}}>{title}</Text>
  //       <TextInput
  //         // placeholder={title}
  //        // placeholderTextColor={color.black}
         
  //         keyboardType='numeric'
  //         autoCapitalize="none"
  //         value={value}
  //         onChangeText={text => {
  //           this.updateState(text, title, value, key);
  //         }}
  //         style={{ width: key == "temperture" ? "82%" : "100%", 
  //         height: 37, 
  //         marginTop:(key =="RandomBooldsugar") ? 12: 5,
  //         fontSize: 12, 
  //         backgroundColor: color.white ,
  //         borderWidth: 0.5,
  //         borderRadius: 10,
  //         borderTopRightRadius: key == "temperture" ?  "0" :null,
  //         borderBottomRightRadius: key == "temperture" ?  "0" :null,
  //         borderColor: color.rightPanelInputBorder,
  //       //  textAlign:"center"
         
  //        }}
  //       />
  //       {key == "temperture" ? <Picker
  //         style={{ height: 37,marginTop:5, backgroundColor: color.white, borderTopRightRadius:10, borderBottomRightRadius:10 , borderColor: color.rightPanelInputBorder,}}
  //         selectedValue={this.state.language}
  //         onValueChange={(itemValue, itemPosition) =>
  //           this.setState({ language: itemValue, choosenIndex: itemPosition })}
  //       >
  //         <Picker.Item label="C" />
  //         {/* <Picker.Item label="%"  /> */}
  //       </Picker> : null}
  //     </View>
  //   );
  // };




  _renderTextField = (title, key, value) => {
    return (
      <View
        style={{
          minHeight: 35,
          alignItems: "center",
          flexDirection: "row",
          marginLeft:5,
          
          width: "45%",
          marginRight: 30,
          marginTop:20,
        }}
      >
        <TextInput
          placeholder={title}
         // placeholderTextColor={color.black}
          keyboardType='numeric'
          autoCapitalize="none"
          value={value}
          onChangeText={text => {
            this.updateState(text, title, value, key);
          }}
          style={{ width: key == "temperture" ? "82%" : "100%", 
          height: 37, 
          fontSize: 10, 
          backgroundColor: color.white ,
          borderWidth: 0.5,
          borderRadius: 10,
          borderTopRightRadius: key == "temperture" ?  "0" :null,
          borderBottomRightRadius: key == "temperture" ?  "0" :null,
          borderColor: color.rightPanelInputBorder,
         textAlign:"center"
         }}
        />
        {key == "temperture" ? <Picker
          style={{ height: 37, backgroundColor: color.white, borderTopRightRadius:10, borderBottomRightRadius:10 , borderColor: color.rightPanelInputBorder,}}
          selectedValue={this.state.language}
          onValueChange={(itemValue, itemPosition) =>
            this.setState({ language: itemValue, choosenIndex: itemPosition })}
        >
          <Picker.Item label="C" />
          {/* <Picker.Item label="%"  /> */}
        </Picker> : null}
      </View>
    );
  };



  renderDateRow = (placeHolder1, key1, value1, placeHolder2, key2, value2) => {
    return (
      <View
        style={{
          flexDirection: "row",
          marginBottom: 10,
          marginTop: 0,
          alignItems: "center"
        }}
      >
        {this.renderTextField(placeHolder1, key1, value1)}
        {this.renderTextField(placeHolder2, key2, value2)}
      </View>
    );
  };

  updateState = (text, title, value, key) => {
    // var fields = this.state;
    // fields["filterData"][key] = value;
    if (key === "height") {
      this.setState({ height: text })
    } else if (key === "weight") {
      this.setState({ weight: text })
    } else if (key === "systolicbloodpressure") {
      this.setState({ systolicbloodpressure: text })
    } else if (key === "diatolicbloodpressure") {
      this.setState({ diatolicbloodpressure: text })
    }
    else if (key === "temperture") {
      this.setState({ temperture: text })
    }
    else if (key === "pulseOximetry") {
      this.setState({ pulseOximetry: text })
    } else if (key === "pulserate") {
      this.setState({ pulserate: text })
    } else if (key === "RandomBooldsugar") {
      this.setState({ RandomBooldsugar: text })
    }
  };


  onPressAction = (key, value) => {
    var data = {
      key: value
    }
    this.state.selectedVitals.push(data);

    if (this.state.selectedVitals.length > 1) {
      var jsonObject = this.state.selectedVitals.map(JSON.stringify)


      var uniqueSet = new Set(jsonObject);
      var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      this.props.selectedVitals(uniqueArray);
    } else {
      this.props.selectedVitals(this.state.selectedVitals);
    }
  }

  renderTextInput(label, key, stateValue, type) {
    return (
        <View style={{width:"90%"}}>
            <Text style={styles.TextBoxLabel}>{label}</Text>

            <View style={styles.TextBoxView}>

                <View style={[{
                    flexDirection: 'row', borderWidth: 0.1,
                    borderColor: '#CDD1D5',


                    backgroundColor:this.state.isLocked ? color.disableComponentColor : "transparent", 

                }, Style.allButtonBorderRadius,this.state.isLocked ? {backgroundColor:color.disableComponentColor}: {} ]}>
                    <TextInput
                        editable={!this.state.isLocked}
                        value={stateValue}
                        onChangeText={(text) => {
                            var states = this.state;
                            if(this.numberValidation(text)){
                            states[key] = text;
                            this.setState({ states })}
                            // this.setState({ exerciseTime: text })
                        }}
                        style={[
                            // Style.historyYesOrNoSectionShadow,
                            // Style.allButtonBorderRadius, 
                            styles.TextBoxInput,
                            {backgroundColor:this.state.isLocked ? color.disableComponentColor : "transparent"}]}

                    />
                    <View style={styles.TextBoxPickerView}>
                        <Text style={styles.PickerText}>{type}</Text>
                        {key == "temperture"?
                <Picker
                  enabled={!this.state.isLocked}
                  style={{
                    marginLeft: '0.3vw', height: '4vw', backgroundColor: color.white,
                    marginTop: '0.4vw', fontSize: '0.9vw',

                    //  borderTopRightRadius:3, 
                    // borderBottomRightRadius:3 , 
                    borderColor: color.white,
                    borderWidth: 1
                  }}
                  selectedValue={this.state.tempertureType}
                  onValueChange={(itemValue) =>
                    this.setState({ tempertureType: itemValue })}
                >
                        
                        <Picker.Item label="F" value="f" />
                        <Picker.Item label="C" value="c" />
                        {/* <Picker.Item label="%"  /> */}
                      </Picker>:null}
                    </View>



                </View>
            </View></View>
    )
}


  render() {
    // alert(this.state.userType)
    return (
      <View>
        {this.renderSubHeadingBorder("Vitals",false,true)}
        {/* <FlatList
            data={Constants.VITALS_OPTION}
            numColumns={3}
            scrollEnabled={false}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            containerStyle={{ alignItems: "center", alignContent: "center" ,marginTop:20}}
            renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'selectedEye', this.state.selectedEye)} 
          /> */}
          <View style={styles.TableHeadView1} >


          {/* {this.renderHistoryComponent()} */}
          {/* {this.renderHistoryComponent()}
          {this.renderHistoryComponent()} */}

          


          <CommonHistorySectionHeader
            heading1={"Height"}
            heading2={"Weight"}
            columnSize={[0.5,0.5]}  //total value is == of 1
            noOfColumn={2}
          />
          </View>
          <View style={styles.TableDataContainer1}>
                    <View style={{ width: '50%' }}>
                        {this.renderTextInput("Enter Value", "height", this.state.height, "cm")}

                    </View>
                    <View style={{ width:"50%"}}>
                        {this.renderTextInput(" Enter Value", "weight", this.state.weight, "kg")}

                    </View>

                </View>
        {/* {this.renderDateRow(
          "Height in cms",
          "height",
          this.state.height,
          "Weight in Kg",
          "weight",
          this.state.weight
        )} */}
        <View style={styles.TableHeadView1}>
          <CommonHistorySectionHeader
            heading1={"Systolic Blood Pressure"}
            heading2={"Diastolic Blood Pressure"}
            columnSize={[0.5,0.5]}  //total value is == of 1
            noOfColumn={2}
          />
          </View>
          <View style={styles.TableDataContainer1}>
                    <View style={{ width:"50%" }}>
                        {this.renderTextInput("Enter Value", "systolicbloodpressure", this.state.systolicbloodpressure, "mm/Hg")}

                    </View>
                    <View style={{ width:"50%"}}> 
                        {this.renderTextInput("Enter Value", "diatolicbloodpressure", this.state.diatolicbloodpressure, "mm/Hg")}

                    </View>

                </View>
        {/* {this.renderDateRow(
          "Systolic Blood Pressure",
          "systolicbloodpressure",
          this.state.systolicbloodpressure,
          "Diastolic Blood Pressure",
          "diatolicbloodpressure",
          this.state.diatolicbloodpressure
        )} */}
         <View style={styles.TableHeadView1}>
          <CommonHistorySectionHeader
            heading1={"Temperature"}
            heading2={"Pulse Oximetry"}
            columnSize={[0.5,0.5]}  //total value is == of 1
            noOfColumn={2}
          />
          </View>
          <View style={styles.TableDataContainer1}>
                    <View style={{ width:"50%"}}>
                        {this.renderTextInput("Enter Value", "temperture", this.state.temperture, "")}

                    </View>
                    <View style={{ width:"50%"}}>
                        {this.renderTextInput("Enter Value", "pulseOximetry", this.state.pulseOximetry, "%")}

                    </View>

                </View>
        {/* {this.renderDateRow(
          "Temperature",
          "temperture",
          this.state.temperture,
          "Pulse Oximetry in %",
          "pulseOximetry",
          this.state.pulseOximetry
        )} */}
         <View >
          <CommonHistorySectionHeader
            heading1={"Pulse Rate"}
            heading2={"Random Blood Glucose"}
            columnSize={[0.5,0.5]}  //total value is == of 1
            noOfColumn={2}
          />
          </View>
          <View style={styles.TableDataContainer2}>
                    <View style={{ width:"50%"}}>
                        {this.renderTextInput("Enter Value", "pulserate", this.state.pulserate, "/min")}

                    </View>
                    <View style={{ width:"50%"}}>
                        {this.renderTextInput("Enter Value", "RandomBooldsugar", this.state.RandomBooldsugar, "mg/dl")}

                    </View>

                </View>
               {
                 this.state.userType == "PD" ?
                <View>
                <View >
          <CommonHistorySectionHeader
            heading1={"Head Circumference"}
            columnSize={[0.5]} 
            columnSize={[0.5]}  //total value is == of 1
            noOfColumn={2}
          />
          </View>
          <View style={styles.TableDataContainer2}>
                    <View style={{ width:"50%",alignContent:"flex-start"}}>
                        {this.renderTextInput("Enter Value", "head_circumference", this.state.head_circumference, "cm")}

                    </View>
                    <View style={{ width:"50%",alignContent:"flex-start"}}>
                        {/* {this.renderTextInput("Enter Value", "pulserate", this.state.pulserate, "cm")} */}

                    </View>

                </View>
                </View>
                : null
                }
        {/* {this.renderDateRow(
          "Pulse Rate per min",
          "pulserate",
          this.state.pulserate,
          "Random Blood Glucose",
          "RandomBooldsugar",
          this.state.RandomBooldsugar
        )}
                   */}

        <View style={styles.AddBtnView }>
          <TouchableOpacity onPress={()=>this.addVital()} disabled={this.state.isLocked || this.state.isNoAppointment} > 
            {this.renderTextBtn("Add", this.state.isNoAppointment? false : true, false)}
          </TouchableOpacity>
        </View>

        {/* <View style={{ marginTop: 40 }}>
          <TouchableOpacity onPress={() => this.addVital()} disabled={this.state.isLocked}>
            <View style={{
              backgroundColor: color.themeDark, alignItems: 'center',
              justifyContent: 'center', borderRadius: 15, height: 35
            }}
            >
              <Text style={{ color: color.white }}>ADD</Text>
            </View>
          </TouchableOpacity>
        </View> */}
        {/* <View style={{marginTop:10}}>
        {this.state.successOrErrorMessage ? (
                <View style={{ height:40, width: '100%', backgroundColor: this.state.successMessageFlag ?'green' : this.state.errorMessageFlag ?'red' : null ,alignItems:'center',justifyContent:'center' }}>
                    <Text style={{color:"white",textAlign:"center",justifyContent:"center"
                  }}>
                    {
                    this.state.successMessageFlag ?'Vitals Created Successfully' : 
                    this.state.errorMessageFlag ?'Vitals Failed' : null}</Text>
                </View>
              ) :null}
          </View> */}
      </View>


    );
  }
}
const styles = StyleSheet.create({
      SubHeaderView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: '1.2vw',
      },
      SubHeaderText: {
        fontSize: '1.2vw'
      },
      SubHeaderEmptyView: {
        marginLeft: '0.6vw',
        width: "100%",
        height: '0.07vw',
      },
      TableHeadView1: {
        marginBottom:'0.6vw'
      },
      TableDataContainer1: { 
        flexDirection: 'row', 
        alignContent: 'center', 
        justifyContent: 'space-evenly', 
        marginBottom: '1.5vw' 
      },
      TextBoxLabel: { 
        fontSize: '0.8vw', 
        position: "absolute", 
        marginLeft: '0.6vw', 
        backgroundColor: "white", 
        paddingLeft: '0.3vw', 
        paddingRight: '0.3vw', 
        color: "#888888" 
      },
      TextBoxView: { 
        marginTop: '0.3vw', 
        marginRight: '0.3vw', 
        zIndex: -1 
      },
      TextBoxInput: {
        height: '2.3vw',
        width: '7.8vw', 
        padding: '0.2vw',
        paddingLeft: '1vw',
        fontSize: '1vw'
      },
      TextBoxPickerView: { 
        height: '1.5vw', 
        borderLeftColor: "#888888", 
        borderLeftWidth: 1, 
        justifyContent: 'center', 
        alignItems: 'center', 
        alignContent: 'center', 
        alignSelf: 'center', 
        paddingRight: '0.6vw' 
      },
      PickerText: { 
        fontSize: '0.9vw', 
        marginLeft: '0.3vw' ,
        alignContent:"center"
      },
      TableDataContainer2: { 
        flexDirection: 'row', 
        alignContent: 'center', 
        justifyContent: 'space-evenly', 
        marginBottom: '1.5vw',
        marginTop: '0.6vw' 
      },
      AddBtnView:{ 
        marginTop: '2.5vw' ,
        alignSelf:"center" 
      }
})