//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity, TextInput } from 'react-native';
import Style from "../../../../styles/Style";
import { color } from "../../../../styles/Color";
import  {NavigationTopHeader, CommonHistorySectionHeader }  from '../../BaseComponent';
import { Constants } from "../../../../utils/Constants";
import Service from "../../../../network/Service";
import moment from "moment";
import CommonDateFilter from '../../BaseComponent'
import Pagination from './../Pagination';
import Icon from "react-native-vector-icons/FontAwesome";
import AsyncStorage from '../../../../AsyncStorage';

const screenHeight = Dimensions.get("window").height;
const navigationHeader = [
    { label: "Create Bill", value: "billingScreen" },
    { label: "Pending Bill", value:"opPatientSavedBill"},
    { label: "Billing Transaction", value: "allBillingTransactions" },
  ]
  let fulldate = new Date();
  let converted_date = moment(fulldate).format("YYYY-MM-DD");   
  
// create a component
class OPPatientSavedBill extends Component {
    constructor(props){
        super(props);
        this.state={
            filterData: {
                fromDate: converted_date,
                toDate: converted_date,
                patientName: "",
                billNo: "",
                amount: "",
              },
              savedBillList: [],
              invoiceID:"",
              invoiceNumber : "",
              savedListAfterPagination:[],
              isDelete: false,
              deletedIDs: "",
              isAdminAccess: false,

              FO_admin_setting : {}
        }
    }
    async componentDidMount(){
      var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
      this.setState({
        isAdminAccess: loggedInDataObj.is_admin_access
      })
       this.getBillingList()

       const FO_admin = await AsyncStorage.getItem("FO_ADMIN_SETTINGS");
        const FO_admin_setting = JSON.parse(FO_admin);
        this.setState({
            FO_admin_setting : FO_admin_setting
        })
    }
    onPressSavedBillDelete = (key) => {
      
          let { appendUrl } = this.state;  
          appendUrl = Constants.OP_SAVED_BILL_DELETE + "?delete_id=" +this.state.deletedIDs 
  
            Service.getInstance().getDeleteList(
              appendUrl,
              this,
              this.deleteSavedBillSuccess,
              this.deleteSavedBillFailure
            );
          
        }
      
        deleteSavedBillSuccess = response => {
            if (response && response.status == "success") {
                this.setState({
                    isDelete: false
                },()=>{
                    this.getBillingList()
                })
                this.props.showResposeValue("success", response.message)
            }
          }     
        deleteSavedBillFailure = error => {
            this.props.showResposeValue("error", error.message)

        }
    getBillingList = (key) => {
      this.setState({
          savedListAfterPagination:[],
          savedBillList:[]
      })
        let { appendUrl } = this.state;  
        appendUrl = Constants.DOCTOR_BILLING_NEW + "?invoice_date=" +this.state.filterData.fromDate + "&invoice_to_date=" + this.state.filterData.toDate + "&bill_type=OP" + "&status=partial" + "&invoice_number=" + this.state.filterData.billNo + "&amount=" + this.state.filterData.amount + "&patient_id=" + this.props.patientAppointment.patient_id;       

          Service.getInstance().billingList(
            appendUrl,
            this,
            this.successBillingList,
            this.failureBillingList
          );
        
      }
    
      successBillingList = response => {
    
          if (response && response.status == "success") {
             
              if (response.data && response.data.data) {
                  this.setState({
                      savedBillList:response.data.data.op,
                  })
              }
          }
        }     
      failureBillingList = response => {
      }
    changeTabSection(data) {
        // do nothing
        this.props.changeScreen(data, {}, "", {}, "", "")
      }
      renderSavedBillHeader=()=>{
          return(
              <View style={styles.TableHeaderView}>
                 {this.props.patientAppointment.appointment_id ?
                   <CommonHistorySectionHeader
                      heading1={"Date"}
                      heading2={"Bill Number"}
                      heading3={"Amount"}
                      heading4={"Action"}
                      noOfColumn={4}
                      columnSize={[0.2, 0.3, 0.3, 0.2]}
                    />
                    :
                    <CommonHistorySectionHeader
                    heading1={"Date"}
                    heading2={"Bill Number"}
                    heading3={"Amount"}
                    noOfColumn={3}
                    columnSize={[0.33, 0.34, 0.33]}
                  />}
              </View>
          )
      }
      onPressBillingList=() => {
        this.props.changeScreen("billingScreen", {}, "", {}, "", {}, "", {}, this.state.invoiceID, {})

      }
      renderSavedBillList=(item,index)=>{
          return(
             <TouchableOpacity 
               style={styles.savedBillList}
               onPress = {()=>
                this.setState({
                    invoiceID: item.id,
                },()=>{
                    {this.onPressBillingList()}
                })
               }
             
             >
               <Text style={[{ flex:!this.props.patientAppointment.appointment_id ? 0.33 : 0.2}, styles.TableDataText]}>{item.invoice_date}</Text>
               <Text style={[{flex: !this.props.patientAppointment.appointment_id ? 0.34 : 0.3}, styles.TableDataText]}>{item.invoice_number}</Text>
               <Text style={[{flex:!this.props.patientAppointment.appointment_id ? 0.33 : 0.3}, styles.TableDataText]}>{item.amount_invoice}</Text>
               {this.props.patientAppointment.appointment_id && (this.state.isAdminAccess || (this.state.FO_admin_setting && this.state.FO_admin_setting.allow_saved_bill_change)) ?
               <TouchableOpacity onPress={() => {
                     this.setState({
                        isDelete: true,
                        deletedIDs: item.id
                     })
                 }}
                 style={{ flex: 0.2 }}
                 >
                   {this.renderIdButton("trash")}
                 </TouchableOpacity>
                 : null }
             </TouchableOpacity>
          )
      }
      showSelectedFilteredDate = (date) => {
        var states = this.state;
        var {filterData} = this.state;
        states["filterDate"] = date
        filterData["fromDate"] = date.fromDate;
        filterData["toDate"] = date.toDate;
        this.setState({ states,filterData })
    
    }
    dateFilter(){
      return(
          <CommonDateFilter removeObject={"all"} defaultSelectedDateFilter={"Today"} startDate={this.state.filterData.fromDate} endDate={this.state.filterData.toDate} showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)} filterDate={this.state.filterDate} />
      )
    }
    updateState = (key, value) => {
        var fields = this.state;
        fields["filterData"][key] = value;
    
        this.setState({ fields });
    };
    renderIdButton = image => {
        return (
          <View
            style={styles.IconView}
          >
            <Icon name={image} color={"#DD1717"} size={'0.97vw'} />
          </View>
        );
      };

    renderTextField = (title, key, value, halfSize) => {
        return (
            <View
                style={[styles.patientSearch,{ width: halfSize ? "50%" : "80%",marginRight: halfSize ? '0.65vw' : 0,}]}
            >
                <TextInput
                    placeholder={title}
                    underlineColorAndroid="transparent"
                    style={styles.patientTextInput}
                    placeholderTextColor={color.lightGray}
                    autoCapitalize="none"
                    value={value}
                    onChangeText={text => {
                        this.updateState(key, text);
                    }}
                /> 
            </View>    
          )
        }
        renderSearchOptions=()=>{
            return(
                <View
                 style={styles.searchOption}>
                  {this.renderTextField("Bill Nos","billNo", this.state.filterData.billNo, true)}
                  {this.renderTextField("Amount", "amount", this.state.filterData.amount, true)}
                  {this.renderTextField("Action", "", this.state.filterData.amount, true)}
                </View>
            )
        }
        renderSearchButton=()=>{
            return(
                <View>
                    <TouchableOpacity style={[styles.searchButton, Style.allButtonBorderRadius]} 
                      onPress={() => {this.getBillingList("search")}         
                    }>
                       <Text style={styles.searchText}>{"Search"}</Text>
                    </TouchableOpacity>
                </View>
            )
        }
        paginationChangedValues(data) {
            this.setState({
              savedListAfterPagination: data
            })
          }
    render() {
        return (
            <View style={styles.container}>
              <View style={styles.subContainer}>
              <NavigationTopHeader
                changeTabSection={this.changeTabSection.bind(this)}
                navigationHeaderList={navigationHeader}
                selectedTab={"opPatientSavedBill"}
              />

              <View style={styles.renderViewStyle}>
                  {this.renderSavedBillHeader()}

                  {this.state.savedListAfterPagination. length > 0 ? this.state.savedListAfterPagination.map((item,index)=>{
                      return this.renderSavedBillList(item,index)
                  }) :
                   <Text style={styles.NorecordsText}>{"No records to be shown"}</Text>

                  }
              </View>
                    <View style={styles.PaginationView}>
                     { this.state.savedBillList && this.state.savedBillList.length >0 ?
                       <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)} totalItems={this.state.savedBillList} />: null
                     }
                    </View>

              </View>
              <ScrollView showsVerticalScrollIndicator={false} style={styles.subContainer1} >
                <View style={styles.RightView}>
                    <View style={styles.FilterHeadView}><Text style={styles.filterText}>Filter</Text></View>
                   {this.dateFilter()}
                   {this.renderSearchOptions()}
                   {this.renderSearchButton()}
                </View>
              </ScrollView> 
              { this.state.isDelete ?
              <View style={styles.DeletePopUpMainView}>
                           <View style={styles.DeletePopUpWrapper}>
                              <Text style={styles.DeletePopUpText}>{"Are you sure to delete the Pending bill ?"}</Text>
                                <View style={styles.DeletePopUpButtonView}>
                                    <TouchableOpacity
                                        style={styles.DeletePopUpButton}
                                        onPress={() => { 

                                           this.onPressSavedBillDelete()
                                        }}>
                                        <Text style={styles.DeletePopUpButtonText}>{"Yes"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={styles.DeletePopUpButton}
                                        onPress={() => { this.setState({ isDelete : false }) }}>
                                        <Text style={styles.DeletePopUpButtonText}>{"No"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>: null
                   }      
            </View>  
        );
    }
}

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: color.applicationBackgroundColor
    },
    subContainer : {
        width: "65%",
        height: screenHeight - 70,
        backgroundColor:color.white
    },
    subContainer1 : {
        width: "35%", 
        height: screenHeight - 80
    },
    RightView:{
      backgroundColor: color.themeShade,
      height: screenHeight-80,
      padding: "1.3vw",
      width: "100%"
    },
    savedBillList : {
        padding:'1.3vw',
        flexDirection:"row",
        borderBottomWidth:1, 
        borderBottomColor:color.lightGray,
        marginLeft:'0.97vw'
     },
    renderViewStyle : {
        backgroundColor : color.white, 
        marginTop:'0.65vw'
    },
    TableHeaderView:{
      margin:'0.84vw'
    },
    TableDataText:{
      fontSize:'0.91vw',
      marginLeft:10
    },
    NorecordsText:{
      alignSelf: "center",
      fontSize:'0.91vw',
      marginTop:'4vw'
    },
    filterText:{
        color: "black",
        fontSize: '1vw',
        fontWeight:'500'
    },
    IconView:{
      width: '1.95vw',
      height: '1.95vw',
      borderRadius: '1.3vw',
      shadowOpacity: 0.2,
      shadowRadius: '0.26vw',
      shadowOffset: {
        height: 2,
        width: 2
      },
      elevation: 4,
      backgroundColor: "#FCFEFF",
      alignItems: "center",
      justifyContent: "center",
      marginRight: '1.3vw'      
    },
    PaginationView:{
      marginLeft:'1.95vw'
    },
    patientSearch :{
        borderColor: color.lightGray, 
        borderWidth: 1, 
        marginTop: '0.32vw', 
        height: '2.28vw', 
        borderWidth: 1, 
        borderRadius: '0.65vw', 
        alignItems: "center", 
        flexDirection: "row", 
        paddingLeft: '0.65vw', 
        paddingLeft: '0.65vw', 
        paddingRight: '0.65vw', 
        zIndex: -1 },
    patientTextInput:{
        color: '#000', 
        width: "90%",
        fontSize:'0.91vw'
    },
    searchOption : {
        flexDirection: "row",
        marginBottom: '1.3vw',
        marginTop: '1.3vw',
        alignItems: "center",
        zIndex: -1
    },
    searchButton: {
        backgroundColor: color.themeDark, 
        width: "9.76vw", 
        alignSelf: 'center', 
        alignContent: 'center',
        paddingVertical: '0.32vw'
    },
    searchText:{
        fontSize: '1.3vw', 
        textAlign: "center",
        color: color.white, 
        fontWeight: "500"
    },
    DeletePopUpMainView:{
      justifyContent: "center", 
      alignItems: "center", 
      height: '100%', 
      width: "100%", 
      backgroundColor: "rgba(0, 0, 0, 0.5)", 
      position: 'absolute'
    },
    DeletePopUpWrapper:{
      justifyContent: "center", 
      alignItems: "center", 
      backgroundColor: color.white, 
      borderRadius: '0.26vw', 
      padding: '1.3vw'
    },
    DeletePopUpText:{
      fontSize: '1.17vw', 
      fontWeight: "bold", 
      marginBottom: "10%"
    },
    DeletePopUpButtonView:{
      backgroundColor: color.white, 
      flexDirection: "row", 
      flex: 1, 
      justifyContent: "space-around", 
      width: "50%", 
      height:"20%", 
      flexWrap: "wrap-reverse"
    },
    DeletePopUpButton:{
      alignItems: 'center', 
      justifyContent: 'center', 
      backgroundColor: color.themeDark, 
      borderRadius: '0.26vw', 
      width: "35%", 
      paddingVertical: '0.52vw'
    },
    DeletePopUpButtonText:{
      color: 'white', 
      fontSize: '0.8vw'
    },
    FilterHeadView: {
      backgroundColor: color.appointmentTableHead,
      paddingHorizontal: '1.62vw',
      paddingVertical:  '0.65vw',
      flexDirection: 'row', 
      justifyContent: 'flex-start',
      marginBottom: '1vw', 
      marginTop:'3vw',
      zIndex: -1, 
      borderRadius:'0.4vw',
    }
   
});
export default OPPatientSavedBill;
