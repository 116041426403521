import React from "react";
import {
  View,
  // Text,
  // Button,
  // Dimensions,
  // FlatList,
  // StyleSheet,
  Text,
  TouchableOpacity,
  // Image,
  // ScrollView,
  Platform,
  TextInput,
  ScrollView,
  // TouchableWithoutFeedback,
  StyleSheet
} from "react-native";
import BaseComponentStyle from "../BaseComponentStyle";
import OpthamologyService from "../../../network/OpthamologyService";
import { color } from "../../../styles/Color";
import { Constants } from "../../../utils/Constants";
import SelectedButton from "../../../components/SelectedButton";
// import AsyncStorage from "../../../AsyncStorage";
// import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
import Styles from "../../../styles/Style";
// import Icon from "react-native-vector-icons/FontAwesome";
// import moment from "moment";
import { CommonButton,DoctorNotesCommonRightSideHeader,CommonSectionHeader } from '../BaseComponent'

const platform = Platform.OS;
const pageTitle = "Treatment Plan";
const pageName = "treatmentandadvice";
var Messages=require('../../../utils/InfoMessages')

export default class TreatmentAdviceNew extends BaseComponentStyle {
  constructor(props) {
    super(props);

    this.state = {
      isLocked:this.props.patientAppointment.is_locked,
      patientAppointment: this.props.patientAppointment,

      id: "",
     
      data: {
        id: "",
        template_name: "",
        date: "",
        disease_name: "",
        comments: ""
      },
      selectedEye: "", // template name
      selectedButton: "", // observation
      selectedButtonText: "", // comments
      Templadata: [],


      isTemplate: false,
      autosuggestionValues:[],
      
      
      
      templateId:"",
      templateName:"",
      diseaseName:"",
      comments:""
    };
  }

  componentDidMount() {
   this.getTreatmentTemplate()
  }

  componentWillReceiveProps(props) {

    let { id } = this.state;

    if (props.editItem) {

      if (props.editItem.title === pageName) {

        let item = props.editItem.item;

        if (id !== item.id) {
          var states =this.state
          states["id"]=item.id;
          states["comments"]=item.comments;
          states["templateName"]=item.disease_name;
          // data["eye_type"]=(item.eye_type?(item.eye_type).toString():null)
          // data["comments"]=item.comments
          //data["id"]=item.id,
          this.setState({
            states
            // id: item.id,
            // selectedButton: (item.eye_type).toString(),
            // selectedButtonText: item.comments
            // data,
            // id: item.id
          });
        }
      }
    }
  }

  getTreatmentTemplate = () => {
    var service_url = Constants.OPTHAMOLOGY_TREATMENT_TEMPLATE_POST;  //"?template_id=" + this.state.data.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTreatmentTemplateSuccess,
      this.getTreatmentTemplateFailure
    );
  };

  getTreatmentTemplateSuccess = response => {
    if (response.status === "success") {
      var data = this.state;
      data = response.data;
      this.setState({ data });
      // console.log("Checkinggggg")
      // console.log(response.data)


    }
  };


  getTreatmentTemplateFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };


  getTreatmentTemplateselect = (template_id) => {
    var service_url = Constants.OPTHAMOLOGY_TREATMENT_TEMPLATE_POST + "?template_id=" + template_id; //this.state.data.id;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getTreatmentTemplateselectSuccess,
      this.getTreatmentTemplateselectFailure
    );
  };

  getTreatmentTemplateselectSuccess = response => {
    if (response.status === "success") {
      
      var { data } = this.state;
      data = response.data;

      // comments
      // selectedButton

      this.setState({ data });
    }

    // console.log("checking")
    // console.log(response.data)
  };


  getTreatmentTemplateselectFailure = error => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };

  renderSelectEyes = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressAction.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  renderSelectTemplate = (item, fill, borderNeeded, selectedKey, selectedValue) => {

    return (
      <SelectedButton
        //   {...otherProps}
        item={item}
        fill={fill}
        borderNeeded={borderNeeded}
        onPressItem={this.onPressTemplate.bind(this, selectedKey)}
        selected={selectedValue}
      />
    );
  }

  // renderTestIconBtn = (text, icon, createBtn) => {
  //   return (
  //     <View
  //       style={{
  //         height: 40,
  //         width: text === "New" ? 70 : "100%",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: createBtn || text === "New" ? 10 : 10,
  //         flexDirection: "row",
  //         backgroundColor: createBtn ? color.themeBlue : text === "New" ? color.white : color.themeBlue,
  //         justifyContent: "space-evenly",
  //         borderWidth: createBtn || text === "New" ? 1 : 1,
  //         borderColor: "#F25364"
  //       }}
  //     >
  //       <Text
  //         style={{
  //           fontSize: platform === "web" ? 17 : 14,
  //           color: text === "New" ? "#848484" : "#F25364",
  //           fontWeight: "500"
  //         }}
  //       >
  //         {text}
  //       </Text>


  //     </View>
  //   );
  // };

  onPressAction = (key, value) => {
   
    let {data} = this.state;
    data[key] = value;

    this.setState({
      data
    })
  }

  onPressTemplate = (key, value) => {
    
    
    
    //this.setState({ selectedButtonText: value, selectedButton: key })
    let states = this.state;
    states[key] = value;

    // let {data} = this.state;
    // data['id'] = value;

    this.setState({
      states
    })

    this.getTreatmentTemplateselect(value);
  }

  addComplaint () {


    let states = this.state;
    var service_url = Constants.OPTHAMOLOGY_TREATMENT_POST;

    let data = {
      "appointment_id": this.state.patientAppointment.appointment_id,
      "disease_name": states.templateName,
      "comments": states.comments
    };

     if (states.id) {
       data["id"] = states.id;
     }
   

    // if (states.data.eye_type) {

      OpthamologyService.getInstance().postComplaints(
        service_url,
        data,
        this,
        this.getComplaintsSuccess,
        this.getComplaintsFailure
      );
    // }
  };

  getComplaintsSuccess = response => {
    if (response.status === "success") {
      this.props.clearEditedItem(pageName, {})

      // var field = this.state;
      // field["data"] = response.data;
      // this.setState({ field });

      // clear data
      this.clearComplaint();

      // reload data
      this.props.refreshData("treatmentandadvice");
      this.props.showResposeValue("success",response.message)
    }else{
      this.props.showResposeValue("error",response.message)
    }
  };

  getComplaintsFailure = error => {
    // console.log("opthamology complaints GET error response");
    // console.log(error);
    this.props.showResposeValue("error",error.message)

  };

  clearComplaint = event => {

    let states = this.state;
    states["id"] = "";
    states['comments'] = "";
    states["diseaseName"] = "";
    states["templateName"] = "";

    this.setState({ states },()=>{
      this.props.clearEditedItem(pageName,{})
    });

  }

  saveTemplate () {

    let states = this.state;
    var service_url = Constants.OPTHAMOLOGY_TREATMENT_TEMPLATE_POST;

    let Templadata = {
      "comments": states.comments,
      "template_name": states.templateName
    };



    OpthamologyService.getInstance().postComplaints(
      service_url,
      Templadata,
      this,
      this.getTemplateSuccess,
      this.getTemplateFailure,

    );

  };

  getTemplateSuccess = response => {
    if (response.status === "success") {

      this.props.showResposeValue("success",Messages.TreatmentTempSuccess);

    }else{
      this.props.showResposeValue("error",response.message);
    }
  };

  getTemplateFailure = error => {
    console.log(error);
  };



  renderNewTemplateAdd = () => {

    return (
      <View style={{ marginTop: '3.3vw' }}>
        <TextInput
          placeholder="Template Name"
          style={[styles.TemplateTextInput, {borderColor: color.rightPanelInputBorder, color: color.themeBlue}]}
          onChangeText={text => this.setState({ template_name: text })}
          value={this.state.template_name}
        />

        <View style={{ marginTop: '3.3vw', flexDirection: "row", justifyContent: "space-evenly", alignItems: 'center' }}>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.saveTemplate()
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Add", true, false)}
            </TouchableOpacity>
          </View>
          <View style={{ width: '50%' }}>
            <TouchableOpacity onPress={() => {
              this.setState({
                isTemplate: false
              })
            }}>
              {this.renderTextBtn("Cancel", true, false)}
            </TouchableOpacity>
          </View>
        </View>
      </View>

    )

  }

  renderText =(value)=>{

    let {data} =this.state
      data["comments"]=value

      this.setState({
        data
      });
  }

  getTreatmentadvicetemplate(searchKey){
    var service_url = Constants.TREAMENT_ADVICE_TEMPLETE+"?search_key="+searchKey;
    if(searchKey){
    OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.TreatmentadvicetemplateSuccess,
        this.TreatmentadvicetemplateFailure
      );
    }
}

TreatmentadvicetemplateSuccess = success =>{
    if(success.status == "success"){
        // console.log("success ---------> "+JSON.stringify(success))
        this.setState({autosuggestionValues : success.data})
    }
}
TreatmentadvicetemplateFailure = error =>{
    console.log("error ---------> "+JSON.stringify(error))
}

getTemplateData(searchKey){
    var service_url = Constants.TREAMENT_ADVICE_TEMPLETE+"?template_id="+searchKey;
    if(searchKey){
    OpthamologyService.getInstance().getComplaints(
        service_url,
        this,
        this.getTemplateDataSuccess,
        this.getTemplateDataFailure
      );
    }
}

getTemplateDataSuccess = success =>{
  if(success.status == "success"){
    if(success.data){
      var data=success.data
      var states = this.state;
      // states["id"] = data.id;
      states["templateName"] = data.template_name;
      states["diseaseName"] = data.disease_name;
      states["comments"] = data.comments;
      this.setState({ states })
    }
  }
  // console.log("success ---------> "+JSON.stringify(success))
}
getTemplateDataFailure = error =>{
  console.log("error ---------> "+JSON.stringify(error))

}

  renderComplaintsTextField(placeholder, key, value) {
    return (
      <View >
        <Text style={styles.ComplaintText}>{placeholder}</Text>
        <View
          style={{
            // backgroundColor: color.white,
            borderRadius: 10,
            height: key == "comments" ? '15.8vw': '2.3vw',
            marginBottom:'1.2vw'
            
          }}
        >


          <TextInput
            editable={!this.state.isLocked}
            underlineColorAndroid="transparent"
            multiline={key == "comments" ? true: false}
            style={[{
              color: "#525252",
              padding: '0.5vw',
              marginRight: 0,
              flex: 1,
              width:key == "comments" ? "95%" : "70%",
              height: key == "comments" ? '14.3vw': '2.3vw',
              borderWidth: 0.5,
              fontSize: '0.9vw',
              // borderRadius: 10,
              backgroundColor: this.state.isLocked ? color.disableComponentColor : color.white,
              borderColor: color.rightPanelInputBorder
            },Styles.allButtonBorderRadius]}
            autoCapitalize="none"
            value={value}
            onChangeText={text => {
              
              var states = this.state;

              states[key] = text;

              this.setState({ states });

              if(key == "templateName"){
                this.getTreatmentadvicetemplate(text)
              }
              
            }}
          />
        </View>
        {
          this.state.autosuggestionValues.length > 0 && this.state.templateName.length > 0 && key == "templateName" ?
            <View style={{ position: 'absolute', width: "70%", marginTop: '5.5vw',zIndex:8 }}>
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: '14.3vw', backgroundColor: 'black', borderRadius: 10, width: "80%", }}>
                {
                  this.state.autosuggestionValues.map((item, index) => {
                    return (
                      <TouchableOpacity key={index} onPress={() => {
                        // this.updateState(item, key,fieldName);
                        var states = this.state;
                        states[key] = item.template_name;
                        states["templateId"] =item.id;
                        // this.setState({states});
                        this.setState({
                          states,
                          autosuggestionValues: []
                        },()=>{
                          this.getTemplateData(item.id)
                        })
                      }}>
                        <Text style={styles.ComplaintsTemplateText}>{item.template_name}</Text>
                      </TouchableOpacity>
                    )
                  })
                }
              </ScrollView>
            </View>
            : null
        }
      </View>
    );

  }

  updateTextBoxValue(text, key) {
    var states = this.state;
    states[key] = text;
    this.setState({ states })
  }

  render() {
    var templateArr = [];
    var temp = {}

    var templatedata = this.state.Templadata;
    //console.log("testingg temlatedata")
    //console.log(templatedata)

    templatedata && templatedata.length > 0 && templatedata.map(item => {
      temp = {
        value: item.id,
        label: item.template_name
      }
      if(item.template_name!==null)
      {
      templateArr.push(temp)
    }
    });

    return (
      <View>
        {this.state.isTemplate === true ? <View>
          {this.renderNewTemplateAdd()}
          </View> : 
          <View>

            {/* {this.renderTitleWithMultipleBtn("Templates", templateArr, 3, false, 
            'selectedEye', this.state.selectedEye, this.updateState.bind(this), true, true)} */}
           {/* {this.renderSubHeadingBorder("Treatment & Advice", false, true)} */}
           <DoctorNotesCommonRightSideHeader title={"Treatment Plan"} clearAllData={this.clearComplaint.bind(this)}/>


            {/* {this.renderSubHeadingBorder("Templates")} */}

            {/* <FlatList
              data={templateArr}
              numColumns={3}
              scrollEnabled={false}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              containerStyle={{ alignItems: "center", alignContent: "center" }}
              renderItem={({ item }) => this.renderSelectTemplate(item, false, false, 'selectedEye', this.state.selectedEye)}
            //   extraData={this.state.selectedEye}
            /> */}

{/* <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {
           templateArr && templateArr.map((item, index) => {
              return this.renderSelectTemplate(item, false, false, 'selectedEye', this.state.selectedEye,index)
            })
          }
        </View> */}

            {/* {this.renderIconBtn("plus", "New", true)} */}

            <View style={{ marginTop: '1.2vw', zIndex: -1 }}>

              {/* {this.renderTitleWithMultipleBtn("Observation", Constants.Observation_values, 3, false, 
              'eye_type',this.state.data.eye_type?(this.state.data.eye_type).toString():"", this.updateState.bind(this), false, true, 'data')} */}





              <CommonSectionHeader heading={"Observation (Template Name)"}  />
              {this.renderComplaintsTextField("", "templateName", this.state.templateName)}
              <View style={{zIndex: -3}}>
              <CommonSectionHeader heading={"Comments"}  />
                {this.renderComplaintsTextField("", "comments", this.state.comments)}
              </View>
              {/* {this.renderSubHeadingBorder("Observation")}

              <FlatList
                data={Constants.Observation_values}
                numColumns={3}
                scrollEnabled={false}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                containerStyle={{ alignItems: "center", alignContent: "center" }}
                renderItem={({ item }) => this.renderSelectEyes(item, false, false, 'eye_type', this.state.data.eye_type?(this.state.data.eye_type).toString():"")}
              //   extraData={this.state.selectedEye}
              /> */}
              {/* {this.renderIconBtn("plus", "New", true)} */}

            </View>


            {/* {this.renderTextFieldView("Comments")} */}
            {/* {this.renderTextFieldView("Comments", "comments", 
            this.state.selectedButtonText ? this.state.selectedButtonText : this.state.data.comments, 
            true, 4)} */}

            {/* <View
              style={{
              
                height: 80,
                marginTop: 20,
                backgroundColor: color.white,
                borderRadius: 10,
                borderColor:"",
                zIndex: -3
              }}
            >
              <TextInput
                underlineColorAndroid="transparent"
                placeholder={"Comments"}
                style={{
                  color: "#525252",
                  height: 80,
                  paddingLeft: 5,
                  marginRight: 0,
                  flex: 1,
                  borderColor: "#B3D9FE",
                  borderWidth:1,
                  borderRadius: 10,
                }}
                // ref={text => (this.nameInput = text)}
                // defaultValue=""
                autoCapitalize="none"
                value={this.state.selectedButtonText ? this.state.selectedButtonText : this.state.data.comments}
                onChangeText={text => this.renderText(text)}
                
              />
            </View> */}
          <View style={{flexDirection:"row",marginTop: '1.2vw',zIndex: -4,justifyContent:'center' }}>
            <View style={{  marginRight:'1.2vw'}}>
            {/* <TouchableOpacity
                disabled={this.state.isLocked}
                style={{alignItems:'center',}}
                onPress={() =>{this.saveTemplate()}}
              >
                {this.renderTestIconBtn("Save Template", "", false, "","Addtotemplate")}
              </TouchableOpacity> */}

                  <CommonButton
                    disable={this.state.isLocked}
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Save Template"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.saveTemplate.bind(this)}
                    buttonKey={"SaveTemplate"}
                  />
             
            </View>
            <View style={{}}>
            {/* <TouchableOpacity disabled={this.state.isLocked} onPress={()=>this.addComplaint()}>
                {this.renderTextBtn("Add", true, false,"Add")}
              </TouchableOpacity> */}
                                <CommonButton
                    disable={this.state.isLocked}
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Add"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={false}
                    buttonAction={this.addComplaint.bind(this)}
                    buttonKey={"add"}
                  />
            </View>
          </View>  
          </View>
        }
      </View>
    );
  }


}
const styles = StyleSheet.create({
      TemplateTextInput: { 
        height: '2.7vw', 
        borderWidth: 1, 
        borderRadius: '0.6vw', 
        paddingLeft: '0.1vw', 
        textAlign: 'justify' 
      },
      ComplaintText: { 
        fontSize: '1vw', 
        marginTop:'1.1vw',
        marginBottom:'0.5vw' ,
        paddingLeft: '0.3vw', 
        paddingRight: '0.3vw', 
        color: "black" 
      },
      ComplaintsTemplateText: { 
        color: "white", 
        padding: '0.4vw',
        fontSize: '0.9vw'
      }
})