import React, { Component } from 'react';
import { View, ScrollView, Dimensions, StyleSheet, Text } from 'react-native';
import { NavigationTopHeader, CommonHistorySectionHeader, CommonButton } from '../../BaseComponent'
import Style from '../../../../styles/Style';
import { color } from '../../../../styles/Color';
import { HamiltonDepressionRatingScale } from './HamiltonDepressionRatingScale';
import { YoungsManiaRatingScale } from './YoungsManiaRatingScale';
import { ChildhoodAutismRatingScale } from './ChildhoodAutismRatingScale';
import { YaleBrownObsessiveCompulsiveRatingScale } from './YaleBrownObsessiveCompulsiveRatingScale';
import { MChatRtm } from './MChatRtm';
import OpthamologyService from "../../../../network/OpthamologyService";
import { Constants } from "../../../../utils/Constants";
import moment from "moment";
import { AdhdRatingScale } from './AdhdRatingScale-IV'
import {IndianScaleforAssessmentofAutism} from './IndianScaleforAssessmentofAutism'
import {ScaredChildVersion} from './ScaredChildVersion'
import {CyBocs} from './CyBocs'
import {HamiltonAnxietyRatingScale} from './HamiltonAnxietyRatingScale'
import {BriefPsychiatricRatingScale} from './BriefPsychiatricRatingScale'
const screenHeight = Dimensions.get("window").height;

const navigationHeaderList = [
    { label: "Rating Scale", value: "Rating Scale" }
];

const categoryList = [
    { label: "Hamilton Anxiety Rating Scale", value: "HamiltonAnxietyRatingScale" },
    { label: "Brief Psychiatric Rating Scale", value: "BriefPsychiatricRatingScale" },
    { label: "Hamilton Depression Rating Scale", value: "HamiltonDepressionRatingScale" },
    { label: "Young’s Mania Rating Scale", value: "YoungsManiaRatingScale" },
    { label: "Yale-Brown Obsessive Compulsive Scale", value: "YaleBrownObsessiveCompulsiveRatingScale" },
    { label: "Adhd Rating Scale - IV", value: "AdhdRatingScale" },
    { label: "Indian Scale for Assessment of Autism", value: "IndianScaleforAssessmentofAutism" },
    { label: "Childhood Autism Rating Scale", value: "ChildhoodAutismRatingScale" },
    { label: "M-CHAT-R TM", value: "MChatRtm" },
    { label: "Screen For Child Anxiety Related Disorders(Scared) Child Version", value: "ScaredChildVersion" },
    { label: "Children's Yale-Brown Obsessive Compulsive Scale", value: "CyBocs" },
]

export class RatingScale extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            selectedRatingScale: { label: "Hamilton Anxiety Rating Scale", value: "HamiltonAnxietyRatingScale" },

            patientId: this.props.patientAppointment.patient_id,
            appointmentId: this.props.patientAppointment.appointment_id,

            listOfPreviousScore: [],
            selectedPreviousScore: {}
        }
    }

    showResposeValue(type, message) {
        this.props.showResposeValue(type, message);
    }


    componentDidMount() {
        this.getPreviousScore()
    }



    getPreviousScore() {
        var service_url = Constants.RATING_SCALE_PREVIOUS_SCORE +
            "?patient_id=" + this.state.patientId +
            // "&appointment_id="+this.state.appointmentId+
            "&rating_scale_name=" + this.state.selectedRatingScale.label;

        OpthamologyService.getInstance().getComplaints(
            service_url,
            this,
            this.getPreviousScoreSuccess,
            this.getPreviousScoreFailure
        );
    }

    getPreviousScoreSuccess = success => {
        if (success.status == "success") {
            this.setState({
                listOfPreviousScore: success.data
            })

        }
    }
    getPreviousScoreFailure = error => {
        alert(JSON.stringify(error))

    }

    changeTabSection(screen) { alert(screen) }
    renderTopNavigation() {
        return (
            <View>
                <NavigationTopHeader
                    changeTabSection={this.changeTabSection.bind(this)}
                    navigationHeaderList={navigationHeaderList}
                    selectedTab={"Rating Scale"} />
            </View>
        )
    }

    onChangeSelectedCategory(key, selectedValue) {
        var states = this.state;
        states[key] = selectedValue;
        states["selectedPreviousScore"] = {};
        this.setState({ states }, () => {
            this.getPreviousScore()
        });
    }

    onSelectedPreviousScore(key,value) {
        var states=this.state;
        states[key]=value;
        if(value.appointment_id){
            this.child.current.refreshComponent(value.appointment_id);
        }
        this.setState({states});
     }

    renderPreviousScoreList() {
        return (
            <View style={{ marginVertical: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Previous Score"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: "row", flexWrap: 'wrap' }}>
                    {
                        this.state.listOfPreviousScore.length > 0 ?
                            this.state.listOfPreviousScore.map((item, index) => {
                                var data = item;
                                var values = item.created_date+ " / " + item.total_score.toString()
                                data["label"] = values;
                                data["value"] = values;
                                return (
                                    <View key={index} style={{ marginTop: 8, marginRight: 8 }}>
                                        <CommonButton
                                            item={item} butttonText={item.label}
                                            selectedvalue={this.state.selectedPreviousScore}
                                            buttonType={"outlineNonTheme"}
                                            buttonIcon={""} rightIcon={true}
                                            buttonAction={this.onSelectedPreviousScore.bind(this)}
                                            buttonKey={"selectedPreviousScore"} />
                                    </View>
                                )
                            })
                            :
                            null
                    }
                </View>
            </View>
        )
    }

    renderRatingScaleList() {
        return (
            <View style={{ marginVertical: 15 }}>
                <CommonHistorySectionHeader
                    heading1={"Rating Scale"}
                    columnSize={[1]}
                    noOfColumn={1}
                />
                <View style={{ flexDirection: "row", flexWrap: 'wrap' }}>
                    {
                        categoryList.map((item, index) => {
                            return (
                                <View key={index} style={{ marginTop: 8, marginRight: 8 }}>
                                    <CommonButton
                                        item={item} butttonText={item.label}
                                        selectedvalue={this.state.selectedRatingScale}
                                        buttonType={"outlineNonTheme"}
                                        buttonIcon={""} rightIcon={true}
                                        buttonAction={this.onChangeSelectedCategory.bind(this)}
                                        buttonKey={"selectedRatingScale"} />
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    renderRightSideHeader() {
        return (
            <Text style={{ fontSize: 17, color: color.themeDark, fontWeight: '500' }}>{"Rating Scale"}</Text>
        )
    }


    renderComponent() {

        var selectedComponent = this.state.selectedRatingScale.value;

        var componentLookup = {
            HamiltonDepressionRatingScale: (<HamiltonDepressionRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            YoungsManiaRatingScale: (<YoungsManiaRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            ChildhoodAutismRatingScale: (<ChildhoodAutismRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            YaleBrownObsessiveCompulsiveRatingScale: (<YaleBrownObsessiveCompulsiveRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            MChatRtm: (<MChatRtm ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            AdhdRatingScale: (<AdhdRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            IndianScaleforAssessmentofAutism:(<IndianScaleforAssessmentofAutism ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            ScaredChildVersion:(<ScaredChildVersion ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            CyBocs:(<CyBocs ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            HamiltonAnxietyRatingScale:(<HamiltonAnxietyRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
            BriefPsychiatricRatingScale:(<BriefPsychiatricRatingScale ref={this.child}
                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />),
    
        }
        return (<View>
            {componentLookup[selectedComponent]}
        </View>);
    }


    render() {
        return (
            <View style={styles.container}>
                <View style={styles.leftSideView}>

                    {this.renderTopNavigation()}
                    <View style={styles.leftSideBody}>
                        {/* <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                            style={styles.leftSideScrollView} bounces={false} bouncesZoom={false}> */}
                        {/* {
                            this.state.selectedRatingScale.value == "HamiltonDepressionRatingScale" ?
                                <HamiltonDepressionRatingScale
                                    patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                                    getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />
                                :
                                this.state.selectedRatingScale.value == "YoungsManiaRatingScale" ?
                                    <YoungsManiaRatingScale
                                        patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                                        getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />
                                    :
                                    this.state.selectedRatingScale.value == "ChildhoodAutismRatingScale" ?
                                        <ChildhoodAutismRatingScale
                                            patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                                            getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />
                                        : this.state.selectedRatingScale.value == "YaleBrownObsessiveCompulsiveRatingScale" ?
                                            <YaleBrownObsessiveCompulsiveRatingScale
                                                patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                                                getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />
                                            : this.state.selectedRatingScale.value == "MChatRtm" ?
                                                <MChatRtm
                                                    patientId={this.state.patientId} appointmentId={this.state.appointmentId}
                                                    getPreviousScore={this.getPreviousScore.bind(this)} showResposeValue={this.showResposeValue.bind(this)} />
                                                :



                                                null
                        } */}
                        {this.renderComponent()}

                        {/* </ScrollView> */}
                    </View>
                </View>

                <ScrollView showsVerticalScrollIndicator={false} style={styles.rightSideScrollView} >
                    {this.renderRightSideHeader()}
                    {this.renderRatingScaleList()}
                    {this.renderPreviousScoreList()}

                </ScrollView>

            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flexDirection: "row", backgroundColor: color.applicationBackgroundColor
    },
    leftSideView: {
        width: "65%", height: screenHeight - 65, backgroundColor: color.applicationBackgroundColor
    },
    // leftSideScrollView: {
    //     width: "100%", height: screenHeight - 130, backgroundColor: color.white, zIndex: -1
    // },
    leftSideBody: {
        marginHorizontal: 15, marginVertical: 10,
        //  backgroundColor: color.white,
    },
    rightSideScrollView: {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingHorizontal: 15, paddingVertical: 20
    }

})